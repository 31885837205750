import React from 'react';

import ContentTransition from './../contentTransition';
import translate from './../../utils/i18n';
import PasswordChangeForm from './passwordChangeForm';

import type { User } from './../../types';

type Props = {
  user: User,
  workspace: string, 
};

/**
 * A UI component for changing the password of the current user.
 * @class ChangePassword
 * @extends {React.Component<Props>}
 */
class ChangePassword extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const userID: string = this.props.user.get('id');
    return (
      <ContentTransition className="o-scrollable-container" id="change-password" style={{ height: '100vh' }}>
        <h1 data-public className="o-title">{translate('change_password')}</h1>
        {
          userID === 'klinify_admin' ?
            <p>{translate('db_admin_users_cannot_change_password_from_within_app')}</p> :
            <PasswordChangeForm userID={userID} requireCurrentPassword isCurrentUser workspace={this.props.workspace} />
        }
      </ContentTransition>
    );
  }
}

export default ChangePassword;
