import React from 'react';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import moment, { Moment } from 'moment';

import EncounterModel, { EncounterAttributes, StageInfo } from './../../models/encounterModel';
import PatientModel from './../../models/patientModel';
import translate from './../../utils/i18n';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import StatelessModal from './../modals/statelessModal';
import { logMessage, logPatientScheduled, debugPrint } from './../../utils/logging';
import SaveButton from './../buttons/saveButton';
import { fetchModel } from './../../utils/db';
import { compareByAlphabeticalOrder } from './../../utils/comparators';
import { scheduleAppointment, updateAppointment } from './../../utils/api';
import {
  createEncounterAndBillModels,
  getDefaultDoctorAndLocation, isOnDuty,
  getEncounterAttributes, getSalesItemsFromEncounterType, getNextQueueNumber,
  saveEncounterAndRelatedModels,
  printQueueTicketonArrival,
  alertEncounterStage,
} from './../../utils/encounters';
import AppointmentForm from './../patient/appointmentForm';

import { createPermission, hasPermission } from './../../utils/permissions';
import { isKlinifyUser } from './../../utils/auth';
import { getModelMapFromList } from '../../utils/models';

import type BaseModel from './../../models/baseModel';
import type { Config, SaveModels, SaveModel, User, Model } from './../../types';
import type SalesItemModel from './../../models/salesItemModel';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type AppointmentModel from './../../models/appointmentModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import StagesInfoBar from '../unreviewedStages/stagesInfoBar';
import type BillModel from '../../models/billModel';
import type BillItemModel from '../../models/billItemModel';
import { setDebugModeData } from './../../actions';
import { getStore } from './../../utils/redux';

export type Props = {
  config: Config,
  klinifyConfig: Config,
  patient: PatientModel,
  salesItems: List<SalesItemModel>,
  onSuccess: (patientID?: string) => void,
  saveModels: SaveModels,
  saveModel: SaveModel,
  practitioners: List<PractitionerModel>,
  addModelToStore: (model: BaseModel) => void,
  coveragePayors: List<CoveragePayorModel>,
  encounters: List<EncounterModel>,
  isFromPatientList: boolean,
  encounterID?: string,
  encounterLocation?: string | null,
  encounterDoctor?: string | null,
  buttonClass?: string,
  buttonLabel?: string,
  showBusyIndicator?: boolean,
  isBillingValidation?: boolean, // if component is called from current encounter or billing page
  user: User,
  isSchedule: boolean,
  disabled: boolean,
  appointment?: AppointmentModel,
  modalVisible?: boolean,
  onClose?: () => void,
  noButton?: boolean,
  encounterFlows: List<EncounterFlowModel>,
  encounterStagesMap: Map<string, EncounterStageModel>,
  skipBillCreation?: boolean,
  onError?: () => void,
  updateConfig: (config: Config) => void,
};

type State = {
  doctor?: string,
  location?: string,
  isAddingToQueue: boolean,
  isFetchingPatient: boolean,
  modalOpen: boolean,
  currentView: 'ENCOUNTER_FLOW' | 'DOCTOR' | 'LOCATION' | 'SCHEDULE_APPOINTMENT',
  teleEmail: string,
  teleConsultFormErrorMessage?: string,
  consultationMode: 'tele_consult' | 'walk_in',
  isModeSelected: boolean,
  encounterFlow?: EncounterFlowModel,
};

const UNASSIGNED_STATE = 'UNASSIGNED_STATE';

/**
 * A component that renders a button for adding a patient to the queue for a consult. Upon clicking
 * a modal with a list of consult type options will appear. Upon selecting a consult type a second
 * modal with a list of doctors will appear. Once a doctor is selected an Encounter will be created
 * and the user will be redirected to the patient list view (or onSuccess will be run if provided).
 * If at any point the user clicks outside the modal the process is cancelled.
 * @class QueueButton
 * @extends {Component}
 */
class QueueButton extends React.Component<Props, State> {
  static defaultProps = {
    isFromPatientList: false,
  }

  /**
   * Creates an instance of QueueButton.
   * @param {any} props The initial props.
   */
  constructor(props: Props) {
    super(props);
    const initialState = this.getInitialState(props);
    this.state = Object.assign(
      {}, getDefaultDoctorAndLocation(props.config, props.practitioners),
      initialState,
      { teleEmail: props.patient.get('email') || '' },
      { modalOpen: props.modalVisible || false },
    );
  }

  /**
   * Returns initial stathe
   * @param {Props} props props
   * @returns {MapValue}
   */
  getInitialState(props: Props) {
    return ({
      consultType: undefined,
      isAddingToQueue: false,
      currentView: props.isFromPatientList ? '' : 'CONSULT_TYPE',
      consultationMode: '',
      isModeSelected: false,
      isFetchingPatient: false,
    });
  }

  /**
   * Update state of config or practitioners list has been altered.
   * @param {Props} nextProps Next props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.config !== nextProps.config ||
      this.props.practitioners !== nextProps.practitioners) {
      this.setState(getDefaultDoctorAndLocation(nextProps.config, nextProps.practitioners));
    }
  }

  /**
   * Creates the encounter and bill docs
   * @param {List<SalesItemModel>} salesItemInfo Related sales items and its quantity from stage doc
   * @param {Partial<EncounterAttributes>} encounterAttributes attributes to add into encounter doc
   * @returns {Promise}
   */
  createEncounterBillsAndModels(salesItemInfo: List<{salesItem: SalesItemModel, quantity: number}>,
    encounterAttributes: Partial<EncounterAttributes>) {
  /**
   * Function to run after save attempt
   * @param {APIResponse} encounterModel api response data
   * @returns {SaveModels}
   */
    const onSaveModels = (encounterModel: EncounterModel) =>
      ((savedModels: (List<EncounterModel | BillModel | BillItemModel> & any[]) | null) => {
        if (savedModels && savedModels.length) {
          printQueueTicketonArrival(encounterModel, this.props.config, this.props.salesItems);
          logPatientScheduled();
          createSuccessNotification(translate('x_added_to_queue', { x: this.props.patient.get('patient_name') }));
          const firstStageId = (encounterModel.getStages().first() as StageInfo)?.stage_id;
          alertEncounterStage(this.props.encounterStagesMap.get(firstStageId), 'waiting_alert');
          this.setState(Object.assign({}, this.getInitialState(this.props)));
          if (this.props.onSuccess) {
            this.props.onSuccess(this.props.patient.get('_id'));
          } else {
            window.location.hash = '#';
          }
        }
      });
    return createEncounterAndBillModels(
      this.props.patient,
      salesItemInfo,
      this.props.coveragePayors,
      encounterAttributes,
    )
      .then((models: List<Model>) => {
        const encounterModel = models.find(model => model.get('type') === 'encounter');
        if (getStore().getState().debugModeFlags.docValidation) {
          getStore().dispatch(setDebugModeData(
            'docValidation',
            (newModels: List<Model>) => {
              const newEncounterModel = newModels.find(model => model.get('type') === 'encounter');
              return saveEncounterAndRelatedModels(
                newModels.find(model => model.get('type') === 'patient'),
                newEncounterModel,
                newModels.find(model => model.get('type') === 'bill'),
                undefined,
                newModels.filter(model => model.get('type') === 'bill_item').toArray(),
                onSaveModels(newEncounterModel),
              ).then(onSaveModels(newEncounterModel));
            },
            List(models),
          ));
          return Promise.resolve();
        }

        return saveEncounterAndRelatedModels(
          models.find(model => model.get('type') === 'patient'),
          models.find(model => model.get('type') === 'encounter'),
          models.find(model => model.get('type') === 'bill'),
          undefined,
          models.filter(model => model.get('type') === 'bill_item').toArray(),
          onSaveModels(encounterModel),
        ).then(onSaveModels(encounterModel));
      });
  }

  /**
   * Creates an encounter with a consult type and doctor specified.
   * @param {string | void} practitionerId //componentWillReceiveProps will overwride the state, better to pass from response appointment
   * @param {string | void} flowId //componentWillReceiveProps will overwride the state, better to pass from response appointment
   * @param {string | void} consultType consult_type in appointment docs. Will be available for docs created after migration
   * @returns {undefined}
   */
  handleCreateEncounterAndBill(practitionerId?: string, flowId?: string, consultType?: string) {
    this.setState({ isAddingToQueue: true });
    const location = this.props.appointment ? undefined :
      this.state.location === UNASSIGNED_STATE ? undefined : this.state.location;
    const doctor = this.props.appointment ? practitionerId :
      this.state.doctor === UNASSIGNED_STATE ? undefined : this.state.doctor;
    const encounterStatus = this.props.appointment ? 'started' : 'arrived';
    const appointmentId = this.props.appointment ? this.props.appointment.get('_id') : undefined;
    const encounterFlowMap = getModelMapFromList(this.props.encounterFlows);
    const encounterFlow: EncounterFlowModel | undefined = this.props.appointment ?
      encounterFlowMap.get(flowId) : this.state.encounterFlow;
    let encounterAttributes = getEncounterAttributes(
      encounterStatus,
      consultType,
      doctor,
      location,
      appointmentId,
      encounterFlow,
      this.props.encounterStagesMap,
      this.props.appointment,
    );
    const isQueueNumberEnabled = this.props.config.getIn(['patient_queue', 'enable_queue_number'], true);
    if (isQueueNumberEnabled) {
      const queueNumber = getNextQueueNumber(this.props.encounters);
      encounterAttributes = Object.assign(encounterAttributes, { queue: [queueNumber] });
    }
    if (this.props.appointment) {
      this.props.appointment.getSalesItems(this.props.salesItems, encounterFlowMap,
        this.props.encounterStagesMap)
        .then((salesItemInfo) => {
          this.createEncounterBillsAndModels(salesItemInfo, encounterAttributes);
        });
    } else {
      getSalesItemsFromEncounterType(this.props.salesItems,
        encounterFlow,
        this.props.encounterStagesMap)
        .then((salesItemInfo) => {
          this.createEncounterBillsAndModels(salesItemInfo, encounterAttributes);
        });
    }
  }

  /**
   * Update appointment and create encounter and bill
   * @returns {undefined}
   */
  updateAppointmentAndCreateDocs() {
    updateAppointment(this.props.appointment, {
      status: 'fulfilled',
      practitioner_id: this.props.encounterDoctor || this.state.doctor,
      flow_id: this.state.encounterFlow?.get('_id'),
    })
      .then((resp) => {
        if (resp.ok) {
          this.props.addModelToStore(resp.model);
          return resp.model;
        }
        createErrorNotification('Something went wrong in moving to queue.');
        return null;
      })
      .then((appointmentModel) => {
        if (appointmentModel) {
          this.handleCreateEncounterAndBill(appointmentModel.get('practitioner_id'), appointmentModel.get('flow_id'), appointmentModel.get('consult_type'));
        }
      });
  }

  /**
   * This sets doctor and location for the encounter when is not set and is called before billing.
   * @param {EncounterModel} model encounter or appointment model
   * @returns {void}
   */
  updateDoctorAndLocation(model: EncounterModel): void {
    const doctor = this.props.encounterDoctor || this.state.doctor;
    const location = this.props.encounterLocation || this.state.location;
    this.props.saveModel(model.updateCurrentStageOccurrenceAttributes({ doctor, location }))
      .then(() => {
        this.setState(Object.assign({}, this.getInitialState(this.props)));
        if (this.props.onSuccess) {
          this.props.onSuccess(this.props.patient.get('_id'));
        }
      });
  }


  /**
   * Updates the state then checks if it meets the rqeuirements to save the Encounter.
   * @param {State} updatedState The incoming state change.
   * @returns {void}
   */
  handleStateSelection(updatedState: Partial<State>) {
    let mergedState = Object.assign({}, this.state, updatedState);
    const encounter = this.props.encounters.find(e => e.get('_id') === this.props.encounterID);
    const encounterFlow = mergedState.encounterFlow || this.props.encounterFlows.find(flow => flow.get('_id') === encounter?.get(['flow', 'flow_id']));
    const firstStage = encounterFlow?.getStages(this.props.encounterStagesMap).first() as EncounterStageModel;
    const stageLocations = firstStage ? firstStage.get('locations', []) : [];
    if (!mergedState.doctor) {
      mergedState = Object.assign({}, mergedState, { currentView: 'DOCTOR' });
    } else if ((this.props.config.getIn(['clinic', 'locations'], List()).size === 1 || stageLocations.length === 1) && !this.state.consultationMode) {
      mergedState = Object.assign({}, mergedState, {
        location:
          stageLocations.length === 1
            ? stageLocations[0]
            : this.props.config.getIn(['clinic', 'locations'], List()).first(),
      });
    } else if (!mergedState.location && !this.state.consultationMode) {
      mergedState = Object.assign({}, mergedState, { currentView: 'LOCATION' });
    } else if ((mergedState.doctor || mergedState.location) && this.state.consultationMode) {
      mergedState = Object.assign({}, mergedState, { currentView: 'SCHEDULE_APPOINTMENT' });
    }
    this.setState(mergedState, () => {
      if ((this.props.skipBillCreation || this.props.isBillingValidation) && (this.props.encounterDoctor || this.state.doctor) &&
        (this.props.encounterLocation || this.state.location)) {
        this.setState({ isAddingToQueue: true });
        if (encounter) {
          this.updateDoctorAndLocation(encounter);
        }
        return;
      }
      if (this.props.appointment && (this.props.encounterDoctor || this.state.doctor)) {
        this.setState({ isAddingToQueue: true });
        this.updateAppointmentAndCreateDocs();
        return;
      }

      if ((this.state.encounterFlow && !this.props.isSchedule) &&
          this.state.doctor && this.state.location) {
        this.setState({ isAddingToQueue: true });
        this.handleCreateEncounterAndBill();
      }
    });
  }


  /**
   * Renders the modal content for encounter flow selection.
   * @returns {React.Component} The modal content to render.
   */
  renderEncounterFlowButtons() {
    const { encounterFlows } = this.props;
    return (
      <React.Fragment>
        <StagesInfoBar
          saveModel={this.props.saveModel}
          config={this.props.config}
          updateConfig={this.props.updateConfig}
          encounterStageMap={this.props.encounterStagesMap}
          largeDisplay={false}
        />
        <div className="o-button-list">
          {
            !encounterFlows.size &&
            [
              <p style={{ whiteSpace: 'normal' }}>{translate('no_encounter_flows_warning')}</p>,
              <p style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to="/settings/flows" className="o-button">{translate('settings_page')}</Link>
              </p>,
            ]
          }
          {encounterFlows.filter(flow => flow.isVisible()).map(encounterFlow => (
            <SaveButton
              key={`queueButton-${this.props.patient.get('_id')}-${encounterFlow.get('_id')}`}
              label={encounterFlow.get('name')}
              onClick={() => {
                if (!this.props.config.getIn(['patient_queue', 'assign_doctor_on_arrival']) && this.props.practitioners.size === 1) {
                  this.handleStateSelection({
                    encounterFlow,
                    doctor: this.state.doctor,
                  });
                } else if (!this.props.config.getIn(['patient_queue', 'assign_doctor_on_arrival'])) {
                  this.handleStateSelection({
                    encounterFlow,
                    doctor: UNASSIGNED_STATE,
                    location: UNASSIGNED_STATE,
                  });
                } else {
                  this.handleStateSelection({ encounterFlow });
                }
              }
              }
              isSaving={this.state.isAddingToQueue}
              className="o-button--small"
            />
          ))}
        </div>
      </React.Fragment>

    );
  }


  /**
   * Get the total number of patients who are queued to the doctor
   * @param {string} doctorID Doctor ID
   * @returns {string}
   */
  getTotalNumberOfQueuedPatients(doctorID: string) {
    const encounters = this.props.encounters.filter(encounter => encounter.isToday() &&
      !encounter.containsEvent('cancelled') &&
      (encounter.highestEventIs('arrived') || encounter.highestEventIs('started')) &&
      (encounter.getValue('doctor') === doctorID));
    const total = encounters.size;
    return ` - ${total} ${translate(total === 1 ? 'patient' : 'patients')}`;
  }

  /**
   * Renders the modal content for doctor selection.
   * @returns {React.Component} The modal content to render.
   */
  renderDoctorButtons() {
    let practitioners = this.props.practitioners.filter(p => isOnDuty(this.props.config, p.get('_id')));
    if (practitioners.size === 0) {
      ({ practitioners } = this.props); // No on duty doctors so just use full list.
    }
    return (
      <div className="o-button-list">
        {
          practitioners.size === 0 &&
            [
              <p style={{ whiteSpace: 'normal' }}>{translate('no_practitioners_warning')}</p>,
              <p style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to="/settings/doctors" className="o-button">{translate('doctors_page')}</Link>
              </p>,
            ]
        }
        { !this.props.isFromPatientList && !this.props.isBillingValidation &&
          this.props.config.getIn(['patient_queue', 'assign_doctor_on_arrival']) &&
          this.props.practitioners.size > 0 &&
          <SaveButton
            dataPublic
            key={`queueButton-${this.props.patient.get('_id')}-unassigned`}
            label={translate('unassigned')}
            onClick={() => this.handleStateSelection({
              doctor: UNASSIGNED_STATE,
              location: undefined,
            })}
            isSaving={this.state.isAddingToQueue}
            className="o-button--small"
          />
        }
        { practitioners.toArray()
          .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))
          .map(practitioner =>
            <SaveButton
              key={`queueButton-${this.props.patient.get('_id')}-${practitioner.get('_id')}`}
              label={`${practitioner.get('name')} ${this.getTotalNumberOfQueuedPatients(practitioner.get('_id'))}`}
              onClick={() => {
                const encounter = this.props.encounters.find(e => e.get('_id') === this.props.encounterID);
                const currentStageId = encounter?.getValue('stage_id');
                const currentLocation = encounter?.getValue('location');
                const locations = this.props.encounterStagesMap.get(currentStageId)?.get('locations') || [];
                if (this.props.isFromPatientList && this.props.encounterID && encounter) {
                  this.setState({ isAddingToQueue: true, currentView: 'DOCTOR' });
                  encounter.updateCurrentStageOccurrenceAttributes({ doctor: practitioner.get('_id') });
                  if (!encounter.highestEventIs('started')) {
                    encounter.addEvent('started');
                  }
                  const currentStageEventType = encounter.getCurrentEvent()?.type;
                  if (currentLocation && currentStageEventType !== 'started') {
                    encounter.addStageEvent('started');
                  }
                  if (!currentLocation && locations.length === 1) {
                    encounter.updateCurrentStageOccurrenceAttributes({ location: locations[0] });
                  }
                  this.props.saveModel(encounter).then(() => {
                    this.setState({
                      modalOpen: !currentLocation && locations.length !== 1, // Close the modal if location and doctor is set
                      isAddingToQueue: false,
                    });
                    if (this.props.onClose && currentLocation) {
                      this.props.onClose();
                    }
                  });
                } else {
                  this.handleStateSelection({
                    doctor: practitioner.get('_id'),
                    location: locations.length === 1 ? locations[0] : undefined,
                  });
                }
              }
              }
              isSaving={this.state.isAddingToQueue}
              className="o-button--small"
            />)}
      </div>
    );
  }

  /**
   * Renders the modal content for location selection.
   * @returns {React.Component} The modal content to render.
   */
  renderLocationButtons() {
    const encounter = this.props.encounters.find(e => e.get('_id') === this.props.encounterID);
    const currentStageId = encounter ? encounter?.getValue('stage_id') : this.state.encounterFlow && this.state.encounterFlow?.get('stages')[0];
    const locations = this.props.encounterStagesMap.get(currentStageId)?.get('locations').length
      ? this.props.encounterStagesMap.get(currentStageId)?.get('locations')
      : this.props.config.getIn(['clinic', 'locations'], List()).toArray();
    return (
      <div className="o-button-list">
        {
          locations.length === 0 &&
            [
              <p style={{ whiteSpace: 'normal' }}>{translate('no_locations_warning')}</p>,
              <p style={{ textAlign: 'center', marginTop: '10px' }}>
                <Link to="/settings" className="o-button">
                  {translate('go_to_settings')}
                </Link>
              </p>,
            ]
        }
        { !this.props.isFromPatientList && !this.props.isBillingValidation &&
          locations.length > 0 &&
          <SaveButton
            dataPublic
            key={`queueButton-${this.props.patient.get('_id')}-unassigned`}
            label={translate('unassigned')}
            onClick={() => this.handleStateSelection({
              location: UNASSIGNED_STATE,
            })}
            isSaving={this.state.isAddingToQueue}
            className="o-button--small"
          />
        }
        {locations.map((location: string) =>
          <SaveButton
            key={`queueButton-${this.props.patient.get('_id')}-${location}`}
            label={location}
            onClick={() => {
              if (this.props.isFromPatientList && this.props.encounterID) {
                this.setState({ isAddingToQueue: true, currentView: 'LOCATION' });
                const doctor = this.state.doctor === UNASSIGNED_STATE ?
                  undefined : this.state.doctor || this.props.encounterDoctor;
                if (encounter) {
                  const stageEventType = encounter.getCurrentEvent().type;
                  if (!encounter?.highestEventIs('started')) {
                    encounter.addEvent('started');
                  }
                  if (stageEventType !== 'started') {
                    encounter.addStageEvent('started');
                  }
                  this.props.saveModel(
                    encounter?.addStageEvent('started')
                      .updateCurrentStageOccurrenceAttributes({ doctor, location }),
                  ).then(() => {
                    this.setState({
                      modalOpen: false,
                      isAddingToQueue: false,
                    });
                    if (this.props.onClose) {
                      this.props.onClose();
                    }
                  });
                }
              } else {
                this.handleStateSelection({ location });
              }
            }
            }
            isSaving={this.state.isAddingToQueue}
            className="o-button--small"
          />)}
      </div>
    );
  }

  /**
   * Call's when click save on e-consult form.
   * @param {Moment} teleDate date,
   * @param {Moment} teleTime time
   * @param {string} teleEmail email address
   * @returns {void} The modal content to render.
   * @return {Promise<boolean>} true without error else false
   */
  onTeleConsultFormSubmit(teleDate: Moment, teleTime: Moment, teleEmail: String = '') {
    // Todo: Integrate API and change bellow code.
    const appointmentData = {
      patient_id: this.props.patient.get('_id'),
      flow_id: this.state.encounterFlow?.get('_id'),
      consult_mode: this.state.consultationMode,
      start_timestamp: moment(teleDate)
        .hour(teleTime.hour())
        .minute(teleTime.minute())
        .second(0)
        .valueOf(),
      ...(this.state.doctor !== UNASSIGNED_STATE && { practitioner_id: this.state.doctor }),
    };

    return this.props.saveModel(this.props.patient.set('email', teleEmail))
      .then((model) => {
        if (model.ok === false) {
          createErrorNotification('Appointment could not be scheduled. Please try again later');
          return false;
        }
        return scheduleAppointment(appointmentData)
          .then((resp) => {
            if (resp.ok) {
              this.props.addModelToStore(resp.model);
              createSuccessNotification('Appointment successfully scheduled');
              return true;
            }
            createErrorNotification('Appointment could not be scheduled. Please try again later');
            return false;
          })
          .then((withoutError) => {
            if (withoutError) {
              if (this.props.onSuccess) {
                this.props.onSuccess(this.props.patient.get('_id'));
              } else {
                window.location.hash = '#';
              }
            } else if (this.props.onError) {
              this.props.onError();
            }
            return withoutError;
          });
      });
  }

  /**
   * Renders the modal content for schedule Tele consultation.
   * @returns {React.Component} The modal content to render.
   */
  renderScheduleCommunication() {
    const { consultationMode } = this.state;

    return (
      <AppointmentForm
        email={this.state.teleEmail}
        consultationMode={consultationMode}
        emailDescription={translate(`${consultationMode}_email_placeholder`)}
        onSave={(date, time, email) => this.onTeleConsultFormSubmit(date, time, email)}
        patient={this.props.patient}
        encounters={this.props.encounters}
      />
    );
  }

  /**
   * @returns {JSX.Element}
   */
  renderConsultMode() {
    const hasEConsultReadCreatePermission = hasPermission(this.props.user, List([createPermission('e_consultation', 'read'), createPermission('e_consultation', 'create')]));
    const scheduleEnabled = isKlinifyUser(this.props.user) || this.props.klinifyConfig.getIn(['scheduling', 'scheduleAppointments'], false);
    const scheduleEConsultationEnabled = isKlinifyUser(this.props.user) || (scheduleEnabled && this.props.klinifyConfig.getIn(['scheduling', 'scheduleTeleConsultation'], false));
    return (
      <div className="o-button-list">
        {scheduleEConsultationEnabled &&
          <SaveButton
            key="e-consult-mode"
            label={translate('e_consultation')}
            savingLabel={translate('e_consultation')}
            onClick={() => {
              this.setState({ isFetchingPatient: true });
              fetchModel(this.props.patient.get('_id'))
                .then((patient: PatientModel) => {
                  this.props.addModelToStore(patient);
                  this.setState({
                    teleEmail: patient.get('email', ''),
                    isFetchingPatient: false,
                    consultationMode: 'tele_consult',
                    isModeSelected: true,
                  });
                })
                .catch(() => this.setState({ isFetchingPatient: false }));
            }}
            isSaving={this.state.isFetchingPatient}
            disabled={!hasEConsultReadCreatePermission}
            className="o-button--small"
          />
        }
        {scheduleEnabled &&
          <SaveButton
            key="walk-in-mode"
            label={translate('walk_in')}
            savingLabel={translate('walk_in')}
            onClick={() => {
              this.setState({ isFetchingPatient: true });
              fetchModel(this.props.patient.get('_id'))
                .then((patient: PatientModel) => {
                  this.props.addModelToStore(patient);
                  this.setState({
                    teleEmail: patient.get('email', ''),
                    isFetchingPatient: false,
                    consultationMode: 'walk_in',
                    isModeSelected: true,
                  });
                })
                .catch(() => this.setState({ isFetchingPatient: false }));
            }}
            isSaving={this.state.isFetchingPatient}
            className="o-button--small"
          />
        }
      </div>
    );
  }

  /**
   * Returns the appropriate inner component for the schedule mode based on the current state.
   * @returns {React.Component}
   */
  getScheduleInnerComponent() {
    return this.renderConsultMode();
  }

  /**
   * Returns the appropriate inner component for the modal based on the current state.
   * @returns {React.Component}
   */
  getInnerComponent() {
    if (this.state.currentView === 'LOCATION' ||
      ((this.props.isFromPatientList || this.props.isBillingValidation) &&
        !this.props.encounterLocation &&
        this.props.encounterDoctor
      )) {
      return this.renderLocationButtons();
    } else if (this.state.currentView === 'DOCTOR' ||
      ((this.props.isFromPatientList || this.props.isBillingValidation) &&
        (!this.props.encounterLocation || !this.props.encounterDoctor))
    ) {
      return this.renderDoctorButtons();
    } else if (this.state.currentView === 'SCHEDULE_APPOINTMENT') {
      return this.renderScheduleCommunication();
    }
    return this.renderEncounterFlowButtons();
  }

  /**
   * Event handler for legacy scheduling.
   * @returns {void}
   */
  handleLegacyScheduling() {
    this.setState({ isAddingToQueue: true });
    // We need to fetch the proper model as updating the stub won't persist to the DB.
    fetchModel(this.props.patient.get('_id'), undefined, undefined, true)
      .then(patient => this.props.saveModel(patient.set('appt_date', new Date().valueOf())))
      .then(() => {
        logPatientScheduled();
        createSuccessNotification(translate('x_added_to_queue', { x: this.props.patient.get('patient_name') }));
        this.setState(Object.assign({}, this.getInitialState(this.props)));
        if (this.props.onSuccess) {
          this.props.onSuccess(this.props.patient.get('_id'));
        } else {
          location.hash = '#';
        }
      }).catch(() => this.setState({ isAddingToQueue: false }));
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    // Replace the normal encounter button with a legacy scheduling button.
    if (this.props.config.get('use_legacy_scheduling')) {
      return (
        <SaveButton
          dataPublic
          showBusyIndicator={this.props.showBusyIndicator}
          label={translate('schedule_for_today')}
          onClick={() => this.handleLegacyScheduling()}
          isSaving={this.state.isAddingToQueue}
          className="o-button--small"
        />
      );
    }
    const { consultationMode } = this.state;
    let title = 'select_encounter_flow';
    let buttonLabel = translate('queue');
    if (this.state.currentView === 'DOCTOR') {
      title = 'select_doctor';
    } else if (this.state.currentView === 'LOCATION') {
      title = 'select_location';
    }
    if (this.props.isFromPatientList || this.props.isBillingValidation) {
      if (this.props.buttonLabel) {
        buttonLabel = this.props.buttonLabel === 'finalise_bill' ?
          translate(this.props.buttonLabel) : this.props.buttonLabel;
      } else {
        buttonLabel = translate('see_patient');
      }
      if (this.state.currentView === 'LOCATION' ||
        (this.props.encounterDoctor && this.props.encounterDoctor !== UNASSIGNED_STATE && !this.props.encounterLocation)) {
        title = 'select_location';
      } else {
        title = 'select_doctor';
      }
    } else if (this.state.currentView === 'SCHEDULE_APPOINTMENT') {
      title = `schedule_${consultationMode}`;
    }
    let buttonClass = `o-button o-button--small ${location.hash === '#/' || this.props.isBillingValidation ? '' : 'u-width-180'}`;
    if (this.props.isBillingValidation && this.props.buttonLabel !== 'finalise_bill') {
      buttonClass += ' o-button--full-width';
    } else if (this.props.isBillingValidation && this.props.buttonLabel === 'finalise_bill') {
      buttonClass += ' u-margin--standard';
    }
    if (this.props.isSchedule && !this.state.isModeSelected) {
      buttonLabel = translate('schedule');
      title = 'select_consult_mode';
    }
    return (
      <StatelessModal
        id={`queueButtonModal-${this.props.patient.get('_id')}`}
        buttonLabel={this.props.buttonLabel || buttonLabel}
        buttonClass={this.props.buttonClass || buttonClass}
        disableButton={this.props.disabled}
        showBusyIndicator={this.props.showBusyIndicator}
        onClose={() => {
          this.setState(Object.assign({}, { location: undefined, doctor: undefined, teleEmail: '' }, this.getInitialState(this.props)));
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
        title={translate(title, { encounterLabel: this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter') })}
        visible={this.state.modalOpen}
        setVisible={(isVisible: boolean) => this.setState({ modalOpen: isVisible })}
        noButton={this.props.noButton}
      >
        {(this.props.isSchedule && !this.state.isModeSelected) && this.getScheduleInnerComponent()}
        {((this.props.isSchedule && this.state.isModeSelected) ||
          !this.props.isSchedule) &&
          this.getInnerComponent()}
      </StatelessModal>
    );
  }
}

export default QueueButton;
