import React from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import translate from './../../utils/i18n';
import { wsUnit, colours } from './../../utils/css';
import ConsultationHistoryItemContainer from './../../containers/consultationHistoryItemContainer';
import ConsultationHistoryTableContainer from './../../containers/consultationHistoryTableContainer';
import type EncounterModel from './../../models/encounterModel';
import ButtonGroup from './../buttons/buttonGroup';
import Pagination from './../pagination/pagination';
import ConsultationHistorySettings from './consultationHistorySettings';
import type PatientModel from './../../models/patientModel';
import type AllergyModel from './../../models/allergyModel';
import type { Config, User } from './../../types';

type Props = {
  encounters: List<EncounterModel>,
  config: Config,
  patient: PatientModel,
  allergies: List<AllergyModel>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  user: User,
};

type State = {
  isModeMultiple: boolean,
  reloadTable: boolean,
};

const EncountersHeader = glamorous.div({
  backgroundColor: colours.grey3,
  display: 'flex',
  borderBottom: '1px solid #f0f0f0',
  width: '100%',
});

const EncountersTitle = glamorous.h1({
  padding: `calc(${wsUnit} / 2) ${wsUnit}`,
  textTransform: 'uppercase',
  fontFamily: 'robotomedium',
  display: 'flex',
  border: 'none',
  marginRight: 'auto',
});

/**
 * A component displaying the consultation history card of patient including header and its content.
 * @class ConsultationHistoryGrid
 * @extends {React.Component}
 */
class ConsultationHistoryGrid extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of ConsultationHistoryGrid.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModeMultiple: this.props.config
        .getIn(['encounters', 'history_section', 'default_view_selected']) !== 'single',
      reloadTable: false,
    };
  }

  /**
   * Reset values when config value changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.config.getIn(['encounters', 'history_section', 'default_page_size_selected'])
     !== nextProps.config.getIn(['encounters', 'history_section', 'default_page_size_selected'])) {
      if (this.state.isModeMultiple) {
        if (this.state.reloadTable) {
          this.setState({
            reloadTable: !this.state.reloadTable,
          }, () => {
            this.setState({
              reloadTable: true,
            });
          });
        } else {
          this.setState({
            reloadTable: true,
          });
        }
      }
    }
  }

  /**
   * Handles the toggle button click for single and multiple view in encounter history, updates state accordingly
   * @returns {void}
   */
  toggle = (): void => {
    this.setState(prevState => ({ isModeMultiple: !prevState.isModeMultiple }));
  }

  /**
   * Updates settings/config selection status in local state.
   * @param {Config} config the config value.
   * @returns {void}
   */
  updateConfig(config: Config) {
    this.props.updateConfigValue(['encounters', 'history_section'], config.getIn(['encounters', 'history_section'], List()));
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <section className="o-card c-encounter-history-view">
        <EncountersHeader>
          <EncountersTitle>
            {`${this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter')} ${translate('history')}`}
          </EncountersTitle>
          {
                this.props.encounters.size > 0 &&
                <ButtonGroup
                  id="toggle-button"
                  className="c-patient-encounters-buttons"
                  label={`${translate('view')}: `}
                  buttons={[
                    {
                      label: translate('single'),
                      isActive: !this.state.isModeMultiple,
                      onClick: () => (!this.state.isModeMultiple ? undefined : this.toggle()),
                    },
                    {
                      label: translate('multiple'),
                      isActive: this.state.isModeMultiple,
                      onClick: () => (this.state.isModeMultiple ? undefined : this.toggle()),
                    },
                  ]}
                />
              }
          <ConsultationHistorySettings
            config={this.props.config}
            onSave={(config) => {
              this.updateConfig(config);
            }}
            user={this.props.user}
            updateConfig={this.props.updateConfig}
          />
        </EncountersHeader>
        {
              this.props.encounters.size === 0 &&
              <p className="u-padding--1ws">{translate('no_previous_consultations')}</p>
            }
        {
              this.props.encounters.size > 0 && !this.state.isModeMultiple &&
              <Pagination
                items={this.props.encounters.map(encounter =>
                  <ConsultationHistoryItemContainer allergies={this.props.allergies} patient={this.props.patient} encounter={encounter} key={encounter.get('_id')} />)}
              />
            }
        {
              this.props.encounters.size > 0 && this.state.isModeMultiple &&
              !this.state.reloadTable &&
              <ConsultationHistoryTableContainer
                patient={this.props.patient}
                encounters={this.props.encounters}
              />
            }
        {
              this.props.encounters.size > 0 && this.state.isModeMultiple &&
              this.state.reloadTable &&
              <ConsultationHistoryTableContainer
                patient={this.props.patient}
                encounters={this.props.encounters}
              />
            }
      </section>
    );
  }
}

export default ConsultationHistoryGrid;
