import React from 'react';
import type { List } from 'immutable';

import BillNotesForm from './billNotesForm';
import BillSidebarButtons from './billSidebarButtons';
import BillSummary from './billSummary';
import CoPaymentForm from './coPaymentForm';
import CoveragePayorDetails from './coveragePayorDetails';
import translate from './../../utils/i18n';
import { calculatePatientTotal } from './../../utils/billing';
import { getMaxClaimableAmountForBill } from './../../utils/coveragePayors';
import BillPaymentSummary from './billPaymentSummary';

import type { Attributes as BillAttributes } from './../../models/billModel';
import type { User, Config, MapValue, SaveModels, SaveModel } from './../../types';
import type AllergyModel from './../../models/allergyModel';
import type BillItemModel from './../../models/billItemModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DrugModel from './../../models/drugModel';
import type EncounterModel from './../../models/encounterModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PractitionerModel from './../../models/practitionerModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import type ConditionModel from './../../models/conditionModel';
import type PaymentModel from './../../models/paymentModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import type AppointmentModel from './../../models/appointmentModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import InventoryMapModel from './../../models/inventoryMapModel';
import BillDiscountChargeForm from './billDiscountChargeForm';
import DiscountChargeModel from './../../models/discountChargeModel';

type Props = {
  allergies: List<AllergyModel>,
  addNewPayment: () => void,
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  config: Config,
  klinifyConfig: Config,
  coveragePayor?: CoveragePayorModel | null | undefined,
  coveragePayorPolicy?: string,
  coveragePayors: List<CoveragePayorModel>,
  diagnoses: List<ConditionModel>,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  encounterStageMap: Map<string, EncounterStageModel>,
  isEditingAfterFinalisation: boolean,
  isSaving: boolean,
  medicalCertificates: List<MedicalCertificateModel>,
  onBillAttributesChanged: (newAttributes: BillAttributes) => void,
  onBillItemsChanged?: (newItems: List<BillItemModel>) => void,
  onCoveragePayorChanged: (
    coveragePayorID: string | void,
    policyID: string | void,
    shouldPatientUpdate: boolean,
  ) => void,
  onEditClicked?: () => void, // Only needed if expecting a finalised bill to begin with. E.g. AddNewBill won't need this.
  updatePayment: (paymentId: string, changes: { [key: string]: MapValue }) => void,
  onFinaliseClicked: (updatedBillItems: List<BillItemModel>, isChanged?: boolean) => void,
  onVoidClicked?: () => void, // Only needed if expecting a finalised bill to begin with. E.g. AddNewBill won't need this.
  patient: PatientModel | PatientStubModel,
  payments: List<PaymentModel>,
  practitioners: List<PractitionerModel>,
  prescriptions: List<PrescriptionModel>,
  salesItems: List<SalesItemModel>,
  timeChits: List<TimeChitModel>,
  documentTemplates: List<DocumentTemplateModel>,
  user: User,
  saveModels: SaveModels,
  saveModel: SaveModel,
  isFromAddPastBill?: boolean,
  conditions: List<ConditionModel>,
  onCancelClicked?: () => void,
  paymentTypes: List<PaymentTypeModel>,
  isPaymentInvalid: boolean,
  appointment: AppointmentModel | void,
  verifiedDrugs: Map<string, InventoryMapModel>,
  discountsCharges: List<DiscountChargeModel>,
  usedDiscountsCharges: List<DiscountChargeModel>,
  isAddPastBill: boolean,
  setUsedDiscountsCharges: (d: List<DiscountChargeModel>) => void
};


/**
 * The sidebar component of a Bill being edited, added, or viewed.
 * @class BillSidebar
 * @extends {React.PureComponent<Props>}
 */
class BillSidebar extends React.PureComponent<Props> {
  static defaultProps = {
    isEditingAfterFinalisation: false,
  };

  /**
   * If true the bill can be edited. Requires that the bill is not void and is either unfinalised
   * or have the state of editing after finalisation.
   * @returns {boolean}
   */
  canEdit(): boolean {
    return !this.props.billAttributes.is_void &&
      (!this.props.billAttributes.is_finalised || this.props.isEditingAfterFinalisation);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { payments, updatePayment, addNewPayment, billAttributes, billItems } = this.props;
    return (
      <section className="o-sidebar--right o-scrollable-container" style={{ height: '100vh' }}>
        <CoveragePayorDetails
          config={this.props.config}
          coveragePayor={this.props.coveragePayor}
          coveragePayorPolicy={this.props.coveragePayorPolicy}
          patient={this.props.patient}
          coveragePayors={this.props.coveragePayors}
          isEditable={!this.props.patient.isStub() && this.canEdit()}
          style={this.props.coveragePayor ? { marginBottom: 0 } : {}}
          onChange={this.props.onCoveragePayorChanged}
        />
        {
          this.props.billAttributes.coverage_payor_id !== undefined &&
          <CoPaymentForm
            maxClaimableAmount={getMaxClaimableAmountForBill(
              billAttributes,
              this.props.coveragePayors,
            )}
            amount={billAttributes.co_payment}
            onChange={value => this.props.onBillAttributesChanged(
              Object.assign({}, billAttributes, { co_payment: value }),
            )}
            disabled={!this.canEdit()}
          />
        }
        {
          !this.props.isAddPastBill &&
          <div className="o-card o-card--no-shadow" style={{ marginTop: 0 }}>
            <BillDiscountChargeForm
              discountsCharges={this.props.discountsCharges.filter(m => m.isVisible())}
              usedDiscountsCharges={this.props.usedDiscountsCharges}
              setUsedDiscountsCharges={this.props.setUsedDiscountsCharges}
              disabled={!this.canEdit()}
            />
          </div>
        }
        <div className="o-card o-card--no-shadow" style={{ marginTop: 0 }}>
          <BillSummary
            hasCoveragePayor={billAttributes.coverage_payor_id !== undefined}
            items={billItems}
            drugs={this.props.drugs}
            salesItems={this.props.salesItems}
            coPayment={billAttributes.co_payment}
            title={translate('consultation_cost', { encounterLabel: this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter') })}
            maxClaimableAmount={getMaxClaimableAmountForBill(
              billAttributes,
              this.props.coveragePayors,
            )}
            procedureTypes={this.props.procedureTypes}
            providers={this.props.providers}
            isAddPastBill={this.props.isAddPastBill}
            usedDiscountsCharges={this.props.usedDiscountsCharges}
          />
        </div>
        <BillPaymentSummary
          addNewPayment={addNewPayment}
          updatePayment={(paymentId, changes) => updatePayment(paymentId, changes)}
          patientTotal={calculatePatientTotal(billAttributes, billItems, this.props.usedDiscountsCharges)}
          payments={payments}
          disabled={!this.canEdit()}
          paymentTypes={this.props.paymentTypes}
          saveModel={this.props.saveModel}
          isPaymentInvalid={this.props.isPaymentInvalid}
        />
        <BillNotesForm
          onChange={notes => this.props.onBillAttributesChanged(
            Object.assign({}, billAttributes, { notes }),
          )}
          notes={this.props.billAttributes.notes || ''}
          disabled={!this.canEdit()}
        />
        {
          this.props.medicalCertificates.size > 0 &&
          <div className="o-card o-card--no-shadow" style={{ marginTop: 0, fontSize: '12px' }}>
            <h2 data-public className="o-card__title">{translate('medical_leave')}</h2>
            <div className="o-data-list__row">
              <div className="o-data-list__row__item">{translate('no_of_days')}</div>
              <div className="o-data-list__row__item u-text-align-right">
                {
                  this.props.medicalCertificates.first().get('days')
                }
              </div>
            </div>
          </div>
        }
        <BillSidebarButtons
          {...this.props}
          patientTotal={calculatePatientTotal(billAttributes, billItems, this.props.usedDiscountsCharges)}
        />
      </section>
    );
  }
}

export default BillSidebar;
