import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import StatelessModal from './../modals/statelessModal';
import Table from './../table/table';

import type { Permission } from './../../models/userConfigModel';
import type { UserPermissionRecord } from './../../types';

type PermissionGroups = Array<{
  group: string,
  features: Array<Permission>,
}>;

type Props = {
  permissionsGroupFeatures: PermissionGroups,
  permissionsConfig: List<UserPermissionRecord>,
  updatePermissions: (permissions: List<UserPermissionRecord>, access: List<string>) => void,
};

type State = {
  isModalVisible: boolean,
};

const COLUMNS = [
  { accessor: 'input_name', Header: translate('input_name') },
  { accessor: 'section', Header: translate('section') },
  { accessor: 'read', Header: translate('read'), width: 100 },
  { accessor: 'update', Header: translate('update'), width: 100 },
];

/**
 * A component for editing input specific permissions
 * @class InputSpecificPermissionsModal
 * @extends {React.Component<Props, State>}
 */
class InputSpecificPermissionsModal extends React.Component<Props, State> {
  /**
   * Creates an instance of InputSpecificPermissionsModal.
   * @param {Props} props intial props
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isModalVisible: false,
    };
  }

  /**
   * Handles toggling of the checkox
   * @param {UserPermissionRecord} permission  The UserPermissionRecord
   * @param {string} accessLevel the input access level
   * @param {SyntheticInputEvent} event The event
   * @returns {void}
   */
  onToggle(permission: UserPermissionRecord, accessLevel: string, event: SyntheticInputEvent<*>) {
    const isChecked = event.target.checked;
    const newAccessLevels: List<string> = permission ?
      permission.get('access') : List();
    const filteredNewAccessLevels = newAccessLevels.includes(accessLevel) ?
      newAccessLevels.filter(l => (isChecked ? l === accessLevel : l !== accessLevel)) :
      newAccessLevels.push(accessLevel);
    this.props.updatePermissions(permission.get('feature'), filteredNewAccessLevels);
  }

  /**
   * Returns Checkbox component
   * @param {UserPermissionRecord} permission  The UserPermissionRecord
   * @param {string} accessLevel the input access level
   * @returns {React.Component}
   */
  getCheckBox(permission: UserPermissionRecord, accessLevel: string) {
    const permissionsConfig = this.props.permissionsConfig
      .find((f: UserPermissionRecord) => f.get('feature') === permission.get('feature'));
    return (
      <input
        id={`checkbox-group-${permission.get('feature')}`}
        type="checkbox"
        checked={permissionsConfig ? permissionsConfig.get('access').includes(accessLevel) : false}
        onChange={event => this.onToggle(permissionsConfig || permission, accessLevel, event)}
      />
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <StatelessModal
        id="input_specific_permissions"
        buttonLabel={translate('input_specific_permissions')}
        buttonClass="o-button o-button--small u-margin-right--half-ws"
        title={translate('input_specific_permissions')}
        setVisible={(isVisible) => {
          this.setState({ isModalVisible: isVisible });
        }}
        visible={this.state.isModalVisible}
        explicitCloseOnly
        dataPublicHeader
      >
        <section className="o-form" id="input_specific_permissions">
          <Table
            columns={COLUMNS}
            data={
              this.props.permissionsGroupFeatures.map(permission => ({
                input_name: translate(permission.get('feature')),
                section: translate(permission.get('section')),
                read: this.getCheckBox(permission, 'read'),
                update: this.getCheckBox(permission, 'update'),
              }))
            }
            noDataText={translate('no_items')}
            showPagination={false}
          />
        </section>
      </StatelessModal>
    );
  }
}

export default InputSpecificPermissionsModal;
