import React, { useState, useMemo } from 'react';
import Confirm from './../prompts/confirm';
import UnsavedDataModal from './../prompts/unsavedDataModal';
import StatelessModal from './../modals/statelessModal';
import DeleteButton from '../../components/buttons/deleteButton';
import Input from '../../components/inputs/input';
import InventoryItemCard from './inventoryItemCard';
import translate from './../../utils/i18n';
import DrugModel from '../../models/drugModel';

import type InventoryMapModel from '../../models/inventoryMapModel';
import type { DrugMap } from '../../types';
import type { Attributes as DrugSuggestionAttributes } from '../../models/drugSuggestionModel';

type Props = {
  inventoryItem?: InventoryMapModel,
  drugMap?: DrugMap,
  deleteDrug?: (drug: DrugModel) => Promise<void>,
  updateInventoryItem: (
    item: InventoryMapModel | void,
    params: Partial<DrugSuggestionAttributes>,
    event: 'add_non_drug_details') => Promise<void>,
  hideModal: () => void,
  visible: boolean,
  selectedDrug?: DrugModel,
}

const nonDrugMasterIds = {
  cosmetic_drug: 'MSP20200201C',
  non_drug_item: 'MSP20200202O',
  service: 'MSP20200203S',
};

/**
 * Renders a component showing the current dispensing batch and all the batch available for dispensation.
 * @param {Props} props passed props for the component
 * @returns {React.SFC}
*/
const NonDrugMapping = ({
  deleteDrug,
  inventoryItem,
  drugMap,
  updateInventoryItem,
  hideModal,
  visible,
  selectedDrug = new DrugModel(),
}: Props) => {
  const drug = drugMap && inventoryItem ? drugMap.get(inventoryItem.get('drug_id')) || new DrugModel() : selectedDrug;
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [productNameEditorVisible, setProductNameEditorVisible] = useState(false);
  const [name, setName] = useState(drug.get('name'));
  const [showUnsavedDataPrompt, setShowUnsavedDataPrompt] = useState(false);
  const [drugType, setDrugType] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const getAttributes = useMemo(
    () => ({
      dosage: drug.get('default_prescribed_dosage', ''),
      dosageUnit: drug.get('dosage_unit', ''),
      frequency: drug.get('frequency', ''),
      duration: drug.get('default_prescribed_duration', ''),
      defaultQuantity: drug.get('default_quantity', ''),
      dispensationUnit: drug.get('dispensation_unit', ''),
      sellingPrice: drug.getPrice() || '',
      manufacturer: drug.get('manufacturer', ''),
      notes: drug.get('notes', ''),
    }),
    [drug],
  );
  const modalTitle = productNameEditorVisible ? 'ITEM / PRODUCT NAME' : translate('choose_non_drug_type');

  /**
   * @desc Saves selected drug to state and shows confirm modal
   * @param {string} selectedType selected drug type
   * @returns {void}
   */
  const onDrugTypeChange = (selectedType: string) => {
    setDrugType(selectedType);
    setShowSaveModal(true);
  };

  /**
   * @returns {void}
   */
  const onConfirm = () => {
    const params = {
      non_drug_master_id: nonDrugMasterIds[drugType],
      name,
    };
    if (productNameEditorVisible) {
      if (name) {
        setIsSaving(true);
        updateInventoryItem(inventoryItem, params, 'add_non_drug_details').then(() => {
          setIsSaving(false);
          setShowUnsavedDataPrompt(false);
          setProductNameEditorVisible(false);
          hideModal();
        }).catch(() => setIsSaving(false));
      }
    } else {
      setShowSaveModal(false);
      setProductNameEditorVisible(true);
    }
  };

  /**
   * @returns {void}
   */
  const onDelete = () => {
    // $FlowFixMe onDelete will be called if deleteDrug is present
    deleteDrug(drug);
    hideModal();
  };

  /**
   * @param {string} title title for drug type
   * @param {string} subTitle exaample of drug type
   * @param {string} value value for buttonclick
   * @returns {React.Element}
   */
  const renderDrugType = (title: string, subTitle: string, value: string) => (
    <div className="u-margin-top--standard">
      <label className="o-label">{title}</label>
      <p className="u-font-small u-font-italic">{subTitle}</p>
      <button
        onClick={() => onDrugTypeChange(value)}
        className="o-button o-button--padded o-button--small u-full-width u-margin-top--quarter-ws"
      >
        {translate('this_inventory_item_is_a_x', { x: translate(value) })}
      </button>
    </div>
  );

  const confirmationMessage = (
    <>
      <p>{translate('ensuring_information_desc')}</p>
      <p className="u-strong">{translate('non_drug_mapping_warning')}</p>
      <p className="u-margin-top--standard">{translate('are_you_sure_you_wish_to_proceed')}</p>
    </>
  );

  return (
    <StatelessModal
      id="non-drug-mapping"
      noButton
      title={modalTitle}
      setVisible={() => {}}
      visible={visible}
      onClose={() => {
        if (productNameEditorVisible && drug && name !== drug.get('name')) {
          setShowUnsavedDataPrompt(true);
        } else {
          hideModal();
        }
      }}
      explicitCloseOnly
    >
      <Confirm
        show={showSaveModal}
        cancel={() => setShowSaveModal(false)}
        proceed={onConfirm}
        confirmation={confirmationMessage}
        modalTitle={translate('confirmation')}
        footerSaveButtonName={translate('confirm')}
      />
      <UnsavedDataModal
        visible={showUnsavedDataPrompt}
        onSave={onConfirm}
        onDiscard={() => hideModal()}
        onCancel={() => setShowUnsavedDataPrompt(false)}
      />

      {inventoryItem && <InventoryItemCard
        title={`${translate('inventory_item_to_map')} : ${inventoryItem.get('name')}`}
        data={getAttributes}
      />}
      <div className="u-margin--standard">
        {inventoryItem && <hr /> }
        {productNameEditorVisible ? (
          <>
            <Input
              id="add-product-name"
              divClassName="u-full-width u-margin-right--1ws"
              label={modalTitle}
              value={name}
              onValueChanged={newVal => setName(newVal)}
              required
              autoFocus
            />
            <div className="u-text-align-right">
              <button onClick={onConfirm} disabled={isSaving} className="o-button .o-button--padded">{translate('save')}</button>
            </div>
          </>
        ) : (
          <>
            <label className="o-label">{translate('non_drug_mapping_title')}</label>
            {renderDrugType(translate('item_is_a_cosmetic_drug'), translate('cosmetic_drug_examples'), 'cosmetic_drug')}
            {renderDrugType(translate('item_is_a_type_of_service'), translate('services_example'), 'service')}
            {renderDrugType(translate('item_is_a_non_drug_item'), translate('non_drug_item_examples'), 'non_drug_item')}
          </>
        )}
      </div>
      {!productNameEditorVisible && deleteDrug && <DeleteButton className="o-delete-button" onDelete={onDelete} />}
    </StatelessModal>
  );
};

export default NonDrugMapping;
