import * as React from 'react';
import type { HTMLStyle } from './../../types';

type Props = {
  children: React.Node,
  className?: string,
  style?: HTMLStyle,
};

/**
 * The modal footer component
 * @class ModalFooter
 * @extends {React.Component<Props, State>}
 */
const ModalFooter = ({ style = {}, className = '', children }: Props) => (
  <footer style={style} className={`o-card__footer u-padding--half-ws u-sticky-to-bottom ${className}`}>
    {children}
  </footer>
);

export default ModalFooter;
