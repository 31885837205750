import React from 'react';
import { List, Set } from 'immutable';

import type { PrescriptionAttributes, TableFlagType } from '../types';
import type DrugModel from '../models/drugModel';
import { pluralizeWord } from './utils';
import translate from './i18n';
import { getAllergyInteractions } from './api';
import { debugPrint } from './logging';
import AllergyModel from '../models/allergyModel';

/* eslint-disable import/prefer-default-export */

/**
 * Get Prescription Attributes from a drug model
 * @param {DrugModel} drug The Drug Model
 * @param {string} coveragePayorID Coverage payor ID for prescriptions
 * @returns {PrescriptionAttributes} Prescription Attributes
 */
export function drugToPrescriptionAttributes(drug: DrugModel, coveragePayorID?: string) {
  if (drug) {
    const newValues: PrescriptionAttributes = { drug_id: drug.get('_id') };
    if (drug.has('dosage_unit', false)) {
      newValues.prescribed_dosage_unit = drug.get('dosage_unit');
    } else {
      newValues.prescribed_dosage_unit = '';
    }
    if (drug.has('default_prescribed_dosage', false)) {
      newValues.prescribed_dosage = drug.get('default_prescribed_dosage');
    }
    if (drug.has('default_prescribed_duration', false)) {
      const defaultDuration = drug.get('default_prescribed_duration');
      newValues.prescribed_duration = pluralizeWord(defaultDuration[1], defaultDuration[0]);
    }
    if (drug.has('frequency', false)) {
      newValues.prescribed_frequency = drug.get('frequency');
    }
    if (drug.has('price', false)) {
      newValues.sale_price = drug.getPrice(coveragePayorID);
    }
    if (drug.has('cost_price', false)) {
      newValues.cost_price = drug.get('cost_price');
    } else {
      newValues.cost_price = undefined;
    }
    if (drug.has('default_quantity', false)) {
      newValues.sale_quantity = drug.get('default_quantity');
    }
    if (drug.has('indications', false) && drug.get('indications').length === 1) {
      newValues.reason = drug.get('indications')[0];
    }
    if (drug.has('notes', false)) {
      newValues.notes = drug.get('notes');
    }
    if (drug.has(['integrations', 'omnihealth', 'admin_time'], false)) {
      newValues.admin_time = drug.get(['integrations', 'omnihealth', 'admin_time']);
    }
    return newValues;
  }

  return {};
}

/**
 * Return message string of the drug names needed to warn about the drug allergy interaction
 * @param {Array<String>} drugNameList list of clinic drugs name
 * @returns {string}
 */
export const getDACDrugNames = (drugNameList: Array<String>) => {
  switch (true) {
    case (drugNameList.length === 1):
      return drugNameList[0] || '';
    case (drugNameList.length === 2):
      return `${drugNameList[0] || ''} and ${drugNameList[1] || ''}`;
    case (drugNameList.length > 2):
      return `${drugNameList.slice(0, drugNameList.length - 1).join(', ')} and ${drugNameList[drugNameList.length - 1] || ''}`;
    default:
      return '';
  }
};

/**
 * Return DAC warning modal message
 * @param {List<DrugModel>} drugs list of clinic drugs
 * @param {string} mode of prescribe if prescription mode else dispense
 * @returns {ReactElement}
 */
export const getDACDrugNamesMessage = (drugs: List<DrugModel>, mode = 'prescribe') => {
  const drugNameList = drugs.map(d => d.get('name')).toArray();
  const drugNames = getDACDrugNames(drugNameList);
  return (
    <>
      <span className="u-strong"> { translate('we_have_detected_allergy_alert', { names: drugNames }) } </span><br /> <br />
      <span className="u-strong"> { translate('we_will_remove_allergy_alert', { names: drugNames, mode }) } </span><br /> <br />
      { translate('confirm_prescriptions_of_allergy_drug', { mode }) }
    </>
  );
};

/**
 * Check the prescription interactions and return
 * @param {List<string>} clinicDrugIds prescribed or dispensed verified drugs
 * @param {List<AllergyModel>} allergies list of patient allergies
 * @returns {Promise<{ error: boolean, drugs: List<string>}>}
 */
export function checkPrescriptionInteractions(clinicDrugIds: List<string>,
  allergies: List<AllergyModel>) {
  if (!(allergies.size > 0 && clinicDrugIds.size > 0)) {
    return Promise.resolve({
      isError: false,
      drugs: List(),
    });
  }
  const { allergyDrugIds, allergyIngredientIDs } = allergies.reduce((items, allergy) => {
    const { drugId, activeIngredient } = allergy.getMedicationDrugOrIngredients();
    if (drugId || activeIngredient) {
      return ({
        allergyDrugIds: drugId ? items.allergyDrugIds.add(drugId) : items.allergyDrugIds,
        allergyIngredientIDs: activeIngredient ?
          items.allergyIngredientIDs.union(Set([activeIngredient])) : items.allergyIngredientIDs,
      });
    }
    return items;
  }, { allergyDrugIds: Set(), allergyIngredientIDs: Set() });
  const presAllergyCommonDrugIds = clinicDrugIds.filter(p => allergyDrugIds.includes(p));
  return getAllergyInteractions(
    clinicDrugIds.toArray(),
    allergyDrugIds.toArray(),
    allergyIngredientIDs.toArray(),
  ).then(({ error, allergyConflicts }:
    { error: boolean, msg: string, allergyConflicts: Map<string, List<string>> }) => {
    if (!error) {
      const setOfDrugs = allergyConflicts.reduce((items: Set<string>, item: List<string>) => {
        const setofConflictDrugs = Set(item);
        return items.union(setofConflictDrugs);
      }, Set()).union(presAllergyCommonDrugIds);
      const drugs = setOfDrugs.toList();
      return {
        isError: error,
        drugs,
      };
    }
    debugPrint('Something went wrong in checking the allegy interactions', 'error');
    return {
      isError: error,
      drugs: List(),
    };
  });
}

/**
 * Get allert info for allegy with sub category drug unspecified
 * @param {List<AllergyModel>} allergies Encounter data
 * @param {Function | undefined} onClose on close of alert modal
 * @returns {ReactElement} Alert modal message
 */
export function unspecifiedAlleryToolTip(allergies: List<AllergyModel>, onClose?: () => void) {
  const drugNameList = allergies.map(d => `"${d.get('name')}"`).toArray();
  const drugNames = getDACDrugNames(drugNameList);
  return (
    <div style={{ maxWidth: '500px', whiteSpace: 'pre-line'}}>
      <div className="u-flex-row u-flex-space-between">
        <div>{translate('unable_to_run_drug_allergy_checker')}</div>
        { onClose && <div onClick={() => onClose()} className="close-icon">×</div> }
      </div>
      <br />
      <div>{translate('patient_has_a_allergy', { drugNames })}</div>
      <br />
      <div>{translate('to_check_reaction_bw_drugs')}</div>
    </div>
  )
}

/**
 * Get flag info for allegy with sub category drug unspecified
 * @param {AllergyModel} allergy Encounter data
 * @returns {object} An object with flag information.
 */
export function getDrugUnspecifiedFlag( // eslint-disable-line import/prefer-default-export
  allergy: AllergyModel,
): TableFlagType {
  if (allergy.isMedicationAndUnspecified()) {
    return {
      active: true,
      color: 'red',
      tooltips: [{
        header: '',
        content: unspecifiedAlleryToolTip(List([allergy])),
      }],
    };
  }

  return {
    active: false,
    color: '',
    tooltips: [],
  };
}
