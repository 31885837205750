import { List } from 'immutable';
import BaseModel from './../baseModel';
import type { PharmaConnectDocRead } from './../../types';

type attributesType = {
  _id: string,
  type: 'pharmaconnect_read_documents_query_response',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,

  documents: List<PharmaConnectDocRead>,
}

/**
   * BrandResponseModel
   *
   * @namespace BrandResponseModel
   */
class ReadDocumentsModel extends BaseModel {
  attributes: attributesType;

  /**
     * @param {object} attributes - The attributes for this model.
     */
  constructor(attributes: {}) {
    super(attributes);
    this.attributes._id = '_';
    this.attributes.type = 'pharmaconnect_read_documents_query_response';
    this.attributes.documents = List(attributes.documents);
  }
}

export default ReadDocumentsModel;
