import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import { convertNumberToPrice } from './../../utils/utils';
import { UNICODE } from './../../constants';
import { calculateBillItemTotal, calculateFullDiscount } from './../../utils/billing';

import type DrugModel from './../../models/drugModel';
import type SalesItemModel from './../../models/salesItemModel';
import type BillItemModel from './../../models/billItemModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import type { Model } from './../../types';
import { debugPrint } from '../../utils/logging';

type Props = {
  disableScrolling?: boolean,
  showTotal?: boolean,
  drugs: List<DrugModel>,
  items: List<BillItemModel>,
  salesItems: List<SalesItemModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  usedDiscountsCharges: List<DiscountChargeModel>,
};

/**
 * Gets the name for a given itemID.
 * @param {string} itemID DrugModel/SalesItemModel id.
 * @param {List} items A List of either DrugModels or SalesItemModels (depending on the item type).
 * @param {?List<ProviderModel>} providers List of ProviderModel.
 * @returns {string} The item name or a dash if not found.
 */
const getItemNameFromModels = (itemID: string, items: List<Model>,
  providers: List<ProviderModel> = List()) => {
  const item = items.find(i => i.get('_id') === itemID);
  if (item) {
    return (item.get('type') === 'procedure_type' && providers) ? `${item.get('name')} - ${item.getProviderName(providers)}` : item.get('name');
  }
  return UNICODE.EMDASH;
};

/**
 * A component displaying a summary of the cost for the bill.
 * @class BillSummaryCost
 * @extends {React.PureComponent}
 */
class BillSummaryCost extends React.Component<Props, {}> {
  props: Props;

  /**
   * Always updates the component - needed because of this.props.items deep changes.
   * @returns {boolean}
   */
  shouldComponentUpdate() {
    return true; // TODO: This should be more discerning.
  }

  /**
   * Gets the name of a billing item from the Drug/SalesItemModel.
   * @param {object} item A billing item.
   * @returns {string} The name of the item.
   */
  getItemName(item: BillItemModel) {
    if (item.has('drug_id') && item.get('drug_id') !== null) {
      return getItemNameFromModels(item.get('drug_id'), this.props.drugs);
    } else if (item.has('sales_item_id')) {
      return getItemNameFromModels(item.get('sales_item_id'), this.props.salesItems);
    }
    return getItemNameFromModels(item.get('procedure_type_id'), this.props.procedureTypes, this.props.providers);
  }


  /**
   * Renders an individual item.
   * @param {object} item A billing item.
   * @returns {React.Component} The react component.
   */
  renderItem(item: BillItemModel) {
    return (
      <li className="o-data-list__row" key={`billingOverviewItem-${item.get('_id')}`}>
        <div className="o-data-list__row__item">{this.getItemName(item)}</div>
        <div className="o-data-list__row__item">{`${convertNumberToPrice(item.get('price'))} x ${item.get('quantity') === undefined ? UNICODE.EMDASH : item.get('quantity')}`}</div>
        <div className="o-data-list__row__item">{ convertNumberToPrice(item.get('price') * item.get('quantity')) }
        </div>
      </li>
    );
  }

  /**
   * Renders the subtotal row of the BillSummary.
   * @returns {React.Component} A row showing the total price of all items.
   */
  renderSubTotal() {
    return (
      <li className="o-data-list__row">
        <div className="o-data-list__row__item  u-strong">{translate('subtotal')}</div>
        <div className="o-data-list__row__item" />
        <div className="o-data-list__row__item  u-strong">
          {convertNumberToPrice(calculateBillItemTotal(this.props.items))}
        </div>
      </li>
    );
  }

  /**
   * Renders the total row of the BillSummary.
   * @returns {React.Component} A row showing the total price of all items.
   */
   renderTotal() {
    return (
      <li className="o-data-list__row">
        <div className="o-data-list__row__item  u-strong">{translate('total')}</div>
        <div className="o-data-list__row__item" />
        <div className="o-data-list__row__item  u-strong">
          {convertNumberToPrice(calculateBillItemTotal(this.props.items) + calculateFullDiscount(this.props.items, this.props.usedDiscountsCharges))}
        </div>
      </li>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering BillSummaryCost');
    const { items, showTotal = true, disableScrolling = false, usedDiscountsCharges } = this.props;
    return (
      <ul style={disableScrolling ? {} : { maxHeight: '20vh', overflowY: 'scroll' }}>
        { items.size === 0 && <p className="u-padding--1ws">{translate('no_items_added')}</p>}
        { items.map(item => this.renderItem(item)) }
        { showTotal && items.size !== 0 && this.renderSubTotal()}
        {
            usedDiscountsCharges?.reduce<[List<number>, Array<React.ReactElement>]>(([btItems, arr], item) => {
              const [amountToAdd, updatedBtItems] = btItems.reduce(([sum, list], bItem) => {
                const amountToAdd = bItem * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
                return [sum + amountToAdd, list.push(bItem + amountToAdd)]
              }, [0, List()]);
              return ([
                updatedBtItems,
                [...arr, (
                  <li className="o-data-list__row" key={`billingOverviewItem-${item.get('_id')}`}>
                    <div className="o-data-list__row__item">{item.get('name')}</div>
                    <div className="o-data-list__row__item" />
                    <div className="o-data-list__row__item">
                      { convertNumberToPrice(parseFloat(amountToAdd.toFixed(2))) }
                    </div>
                  </li>
                )],
              ]);
            }, [items.map(bItem => calculateBillItemTotal(List([bItem]))), []])[1]
          }
        { showTotal && items.size !== 0 && this.renderTotal()}
      </ul>
    );
  }
}

export default BillSummaryCost;
