import React from 'react';
import { List } from 'immutable';
import moment, { Moment } from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';

import translate from './../../utils/i18n';
import DatePicker from './../inputs/statefulDatepicker';
import TimeInput from './../inputs/timeInput';
import Select from './../inputs/select';

import { compareByAlphabeticalOrder } from './../../utils/comparators';
import { getSelectOptionsFromModelList } from '../../utils/inputs';

import type { Config } from './../../types';
import type PractitionerModel from './../../models/practitionerModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';

type Props = {
  config: Config,
  location?: string,
  encounterFlow?: EncounterFlowModel,
  date: Moment,
  doctor?: string,
  practitioners: List<PractitionerModel>,
  encounterFlows: List<EncounterFlowModel>,
  setLocation: (location: string) => void,
  setEncounterFlow: (encounterFlowId: string) => void,
  setDate: (date: Moment) => void,
  setDoctor: (doctor: string) => void,
  setTime: (time?: Moment) => void,
  time: Moment,
}

/**
 * A component that can be used for the creation of encounters when adding past bills. Usually
 * encounters just derive their data from the context they are created in, so this form allows that
 * context to be manually created (date/time, clinic, doctor, etc.);
 * @param {*} props The props for the component.
 * @returns {React.Component} A BillMetadataForm component.
 */
const BillMetadataForm = (props: Props) =>
  <div className="u-margin--standard">
    <DatePicker
      id="date"
      value={props.date}
      onValueChanged={(value) => { if (value) { props.setDate(value); } }}
      allowPast
      label={translate('date')}
      required
      showClearDate={false}
      isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
    />
    <TimeInput
      id="time"
      value={props.time}
      onChange={value => props.setTime(value)}
      label={translate('time')}
      required
      style={{ width: '150px' }}
    />
    <Select
      id="bill_encounter_type"
      label={translate('encounter_flow')}
      options={getSelectOptionsFromModelList(props.encounterFlows)}
      value={props.encounterFlow?.get('_id')}
      onValueChanged={encounterFlowId => props.setEncounterFlow(encounterFlowId)}
      style={{ width: '50%' }}
    />
    <Select
      id="bill_doctor"
      label={translate('doctor')}
      options={
        props.practitioners
          .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))
          .map(p => ({ label: p.get('name'), value: p.get('_id') })).toArray()
      }
      value={props.doctor}
      onValueChanged={doctor => props.setDoctor(doctor)}
      style={{ width: '50%' }}
    />
  </div>;


export default BillMetadataForm;
