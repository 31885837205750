import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { List, Map } from 'immutable';

import Sidebar from './../components/sidebar/sidebar';
import { setPatientSearchQuery, showAppointmentRequestNotificationIcon, toggleDebugModeFlag, updateConfig, updateModels } from './../actions';
import { sortModelByOrder } from './../utils/comparators';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State, Model, Config } from './../types';
import type PatientCampaignSetModel from '../models/patientCampaignSetModel';

/**
 * Returns unread campaign set count
 * @param {List<PatientCampaignSetModel>} campaignSets campaignSets
 * @param {Map<string, { lastEditedTime: number, isNew: boolean}>} readCampaignSetMap readCampaignSetMap
 * @returns {number}
 */
const getUnreadCampaignSetCount = (
  campaignSets: List<PatientCampaignSetModel>,
  readCampaignSetMap: Map<string, { lastEditedTime: number, isNew: boolean}>,
) => {
  const visibleCampaigns = campaignSets.filter(c => c.get('master_campaign_set_type') === 'MEDADVISOR' && c.isVisible());
  return visibleCampaigns.size - readCampaignSetMap.size;
};

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({
    categories, caseNoteFiles, templateGroups, user, config, isOnline, suppliers, userConfigs,
    appointments, isAppointmentRequestNotificationIconVisible, klinifyConfig, debugModeFlags,
    campaignSetView: { campaignSets, readCampaignSetMap },
    isConfigFetched,
    isAdminUser,
  }: State) => ({
    config,
    isConfigFetched,
    klinifyConfig,
    categories: categories.sort(sortModelByOrder),
    caseNoteFiles,
    templateGroups,
    user,
    isOnline,
    suppliers,
    userConfigs,
    appointments: appointments.filter(a => a.isPending() && a.isTodayOrFuture()),
    isAppointmentRequestNotificationIconVisible,
    unReadCampaignCount: getUnreadCampaignSetCount(campaignSets, readCampaignSetMap),
    isAdminUser,
    debugModeFlags,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetSearchQuery: () => dispatch(setPatientSearchQuery('')),
  saveModel: saveFactory(dispatch),
  toggleDebugModeFlag: (flag: string) => dispatch(toggleDebugModeFlag(flag)),
  showAppointmentRequestNotificationIcon: (show: boolean) =>
    dispatch(showAppointmentRequestNotificationIcon(show)),
  updateModelsInState: (models: Array<Model>) => dispatch(updateModels(models)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const SidebarContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));

export default SidebarContainer;
