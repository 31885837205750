import React from 'react';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import Table from './../table/table';
import { renderWithLink, renderDateWithLink, renderPreWithLink, renderTimeWithLink,
  renderPriceWithLink, renderListItem } from './../../utils/tables';
import { isLoading } from './../../utils/utils';
import { UNICODE } from './../../constants';

import type { Row } from './../../types';

type Props = {
  isFetching: boolean,
  currentDataViewsError?: APIError,
  columns: Array<{ label: string, value: string, renderMethod?: string }>,
  data: Array<{}>,
  onFilterAndSort: (data: Array<Row>) => void
};

/**
 * Renders the component.
 * @param {Props} props The props for the component.
 * @returns {React.Component} The rendered component.
 */
const ConsultReportsTable =
({ columns, isFetching, currentDataViewsError, data, onFilterAndSort }: Props) =>
  <Table
    columns={columns.map((c) => {
      if (c.value === 'encounterDate') {
        return { accessor: c.value, Header: c.label, Cell: renderDateWithLink };
      } else if (c.value === 'patientAddress') {
        return { accessor: c.value, Header: c.label, Cell: renderPreWithLink };
      } else if (c.value === 'scheduledTime') {
        return { accessor: c.value, Header: c.label, Cell: renderTimeWithLink };
      } else if (c.renderMethod === 'PRICE') {
        return { accessor: c.value, Header: c.label, Cell: renderPriceWithLink };
      } else if (c.value === 'stages') {
        return { accessor: c.value, Header: c.label, Cell: renderListItem, placeholder: UNICODE.EMDASH };
      }
      return { accessor: c.value, Header: c.label, Cell: renderWithLink };
    })}
    data={data}
    loading={isFetching}
    noDataText={
      translate(currentDataViewsError ? 'error_contact_support' : isLoading(isFetching, 'no_consults_found'))
    }
    showPagination
    filteredSortedDataHandler={filteredData => onFilterAndSort(filteredData)}
    initialDataHandler={initialData => onFilterAndSort(initialData)}
    defaultSorted={[{ id: 'encounterDate', desc: true }]}
  />;

export default ConsultReportsTable;
