import * as React from 'react';
import glamorous from 'glamorous';

import translate from './../utils/i18n';
import { logMessage } from './../utils/logging';

type Props = {
  children: React.Node,
};

type State = {
  hasError: boolean,
};

const ErrorWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ErrorMessage = glamorous.p({
  maxWidth: 300,
});

const RestartButton = glamorous.button({
  fontSize: 15,
});

/**
 * Displays fallback UI when app crashes
 * @namespace PatientOverview
 */
class ErrorBoundary extends React.Component<Props, State> {
  /**
   * Creates an instance of ErrorBoundary.
   * @param {object} props The initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  /**
   * TODO: here it would be better if we send the actual error to app insight
   * as componentDidCatch has to args {error, errorInfo}
   * Catch js errors
   * @returns {undefined}
   */
  componentDidCatch() {
    this.setState({ hasError: true });
    logMessage("React Component crashed during load and the level as 'error'");
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <ErrorMessage className="o-form__text-block o-form__text-block--error">
            {translate('something_went_wrong')}
          </ErrorMessage>
          <RestartButton className="o-text-button o-text-button--contextual" onClick={() => location.reload(true)}>
            {translate('please_click_here_to_restart_app')}
          </RestartButton>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
