import React from 'react';
import { Map, List, fromJS } from 'immutable';
import { Dispatch, Store } from 'redux';
import memoizeOne from 'memoize-one';
import { Span, Div, H6 } from 'glamorous';
import { Link } from 'react-router-dom';

import { fetchData } from './api';
import { getPatientCampaignDataViews, getPatientCampaignSetDataViews } from '../dataViews';
import type DrugModel from '../models/drugModel';
import type SalesItemModel from '../models/salesItemModel';
import type PractitionerModel from '../models/practitionerModel';
import type MasterDrugModel from '../models/masterDrugModel';
import PatientCampaignSetModel from '../models/patientCampaignSetModel';
import { setCampaignSetView, updateCampaignSetModels } from '../actions';
import { handleApiError } from './response';
import { createCustomNotification, createSuccessNotification } from './notifications';
import translate from './i18n';
import { colours } from './css';
import { saveModelsFactory } from './redux';
import PatientCampaignModel, { CampaignRuleType, CampaignRulesCondition } from '../models/patientCampaignModel';
import { ConfirmMessage } from './layout';
import { getConfirmation } from './utils';

/**
  * Convert sms campaign rule to string
  * @param {string} type The type of rule condition
  * @param {CampaignRulesCondition.metadata} metadata The metadata object containing the details of the rule condition
  * @param {Map<string, DrugModel>} drugs a map of all drugs in the clinic
  * @param {Map<string, SalesItemModel>} salesItems a map of all sales items
  * @param {Map<string, PractitionerModel>} doctors a map of all doctors in the clinic
  * @param {Map<string, MasterDrugModel>} masterDrugModelsMap a map of all master drugs in the clinic
  * @returns {string}
  */
export default function formatCampaignRulesConditionMetadata(
  type: CampaignRuleType,
  metadata: CampaignRulesCondition['metadata'],
  drugs: Map<string, DrugModel>,
  salesItems: Map<string, SalesItemModel>,
  doctors: Map<string, PractitionerModel>,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
) {
  const typeMetadatavalues = Map({
    encounter_type: salesItems.get(metadata.encounter_type)?.get('name'),
    doctor: doctors.get(metadata.doctor_id)?.get('name'),
    drug_prescribed: drugs.get(metadata.drug_id)?.get('name'),
    item_sold: salesItems.get(metadata.sales_item_id)?.get('name'),
    master_drug_sold: masterDrugModelsMap.get(metadata.master_drug_id)?.get('name'),
    prev_patient_job_encounter_time: `${metadata?.encounter_time_offset?.time_value} ${metadata?.encounter_time_offset?.time_unit}`,
  });
  return !typeMetadatavalues.has(type)
    ? (metadata[type] || null)
    : typeMetadatavalues.get(type) ? typeMetadatavalues.get(type) : null;
}

/**
 * Maps filter type string to label to show
 * @param {CampaignRuleType} filterType type in filter conditions in the doc
 * @returns {string}
 */
export const mapEncounterFilterTypeToLabel = (filterType: CampaignRuleType) => {
  switch (filterType) {
    case CampaignRuleType.MASTER_DRUG_SOLD:
      return translate('master_drug_dispensed');
    default:
      return translate(filterType.toLowerCase());
  }
};

/**
 * Returns read campaign set map
 * @returns {Map<string, {lastEditedTime: number, isNew: boolean}>}
 */
const getReadCamapaignSetMap = (): Map<string, {lastEditedTime: number, isNew: boolean}> => {
  const readCampaignSetMap = localStorage.getItem('readCampaignSetMap');
  if (readCampaignSetMap) {
    return fromJS(JSON.parse(readCampaignSetMap));
  }
  return Map();
};

/**
 * Called on campaign set page mount
 * Updates readCampaignSetMap in localStorage with the passed in campaign's id and last edited_by
 * @param {PatientCampaignSetModel} campaignSet Selected campaignset
 * @returns {Map<string, {lastEditedTime: number, isNew: boolean}>} Returns updated map
 */
export const setReadCampaignSetMap = memoizeOne((
  campaignSet: PatientCampaignSetModel,
): Map<string, {lastEditedTime: number, isNew: boolean}> => {
  const readCampaignSetMap = getReadCamapaignSetMap();
  const newState = readCampaignSetMap.set(campaignSet.get('_id'), { lastEditedTime: campaignSet.getLastUpdateTime(), isNew: false });
  localStorage.setItem('readCampaignSetMap', JSON.stringify(newState.toJS()));
  return newState;
});

/**
 *
 * @param {List<PatientCampaignSetModel>} campaignSets List of campaign set models
 * @returns {Map<string, {lastEditedTime: number, isNew: boolean}>}
 */
const getCategorisedCampaignSets = memoizeOne((
  campaignSets: List<PatientCampaignSetModel>,
): Map<string, any> => {
  const prevReadCampaigns = getReadCamapaignSetMap();
  return campaignSets.reduce((reduced, campaignset) => {
    if (prevReadCampaigns.has(campaignset.get('_id'))) {
      const prevEditedTime = prevReadCampaigns.getIn([campaignset.get('_id'), 'lastEditedTime']);
      const lastEditedTime = campaignset.getLastUpdateTime();
      if (prevEditedTime !== lastEditedTime) {
        return reduced.updateIn(['updatedCampaigns'], updatedCampaigns => updatedCampaigns.push(campaignset));
      }
      return reduced.setIn(['readCampaignsMap', campaignset.get('_id')], { lastEditedTime, isNew: false });
    }
    return reduced.updateIn(['newCampaigns'], newCampaigns => newCampaigns.push(campaignset));
  }, Map({ readCampaignsMap: Map(), updatedCampaigns: List(), newCampaigns: List() }));
});

/**
 * Show notification for medadvisor campaign
 * @param {List<PatientCampaignSetModel>}  updatedCampaigns updated Campaigns
 * @param {List<PatientCampaignSetModel>} newCampaigns new Campaigns
 * @param {Store} store Redux store
 * @returns {void}
 */
const notifiyNewCampaigns = (
  updatedCampaigns: List<PatientCampaignSetModel>,
  newCampaigns: List<PatientCampaignSetModel>,
  store: Store,
) => {
  const { config } = store.getState();
  const isMedadvisorEnabled = config?.getIn(['optional_features_enabled', 'medadvisor_campaigns', 'enabled'], false);
  if (!isMedadvisorEnabled) return;
  if (newCampaigns.size || updatedCampaigns.size) {
    const notificationTitle = translate('medadvisor_campaigns_update');
    const updatedNotificationContent = (
      <>
        <h6>{translate('updated_x', { x: translate('medadvisor_campaigns') })}:</h6>
        {updatedCampaigns.toList().map((campaign: PatientCampaignSetModel) => <h6>{`${campaign.get('name')}`}</h6>)}
      </>
    );
    const newNotificationContent = (
      <>
        <H6 css={{ marginTop: 8 }}>{`${translate('new')} ${translate('medadvisor_campaigns')}`}:</H6>
        {newCampaigns.toList().map((campaign: PatientCampaignSetModel) => <h6>{`${campaign.get('name')}`}</h6>)}
      </>
    );
    const notificationContent = (
      <>
        {updatedCampaigns.size > 0 && updatedNotificationContent}
        {newCampaigns.size > 0 && newNotificationContent}
        <Div css={{ marginTop: 8 }}>
          <Link to="/campaigns/medadvisor">
            <Span css={{ color: colours.green, cursor: 'pointer', fontWeight: 'bold' }}>{translate('view_campaigns')}</Span>
          </Link>
        </Div>
      </>
    );
    createCustomNotification(notificationTitle, '', notificationContent);
  }
};

/**
 * Returns un-notified campaign Ids to notify
 * @param {List<PatientCampaignSetModel>} campaigns List of campaign models
 * @param {List<string>} shownCampaignNotifications Notified campaign IDs
 * @returns {[List<string>, List<PatientCampaignSetModel>]}
 */
const getUnNotifiedCampaigns = (
  campaigns: List<PatientCampaignSetModel>,
  shownCampaignNotifications: List<string>,
): [List<string>, List<PatientCampaignSetModel>] =>
  campaigns.reduce(([updatedShownNotificationIds, unNotifiedCampaigns], campaign) => {
    const campaignId = campaign.get('_id');
    const isNewCampaign = shownCampaignNotifications.includes(campaignId);
    if (!isNewCampaign) {
      return [
        updatedShownNotificationIds.push(campaignId),
        unNotifiedCampaigns.push(campaign),
      ];
    }
    return [updatedShownNotificationIds, unNotifiedCampaigns];
  }, [List(), List()]);


/**
* Returns functions that fetch campaign sets and update in store.
* @returns {void}
*/
const fetchCampaignSets = () => {
  let interval: NodeJS.Timeout;
  let shownCampaignNotifications: List<string> = List();
  /**
  * Fetch and update campaign sets in store.
  * @param {Store} store Redux store
  * @param {boolean} includeStats If true, calls in campaign set endpoint with `include_stats=true`
  * @returns {void}
  */
  return (store: Store, includeStats: boolean = true) => {
    fetchData(getPatientCampaignSetDataViews(includeStats))
      .then((campaignSets: List<PatientCampaignSetModel>) => {
        const medadvisorCampaignsets = campaignSets.filter(c => c.get('master_campaign_set_type') === 'MEDADVISOR');
        const categorisedMedadvisorCampaignSets = getCategorisedCampaignSets(medadvisorCampaignsets);
        const updatedCampaigns: List<PatientCampaignSetModel> = categorisedMedadvisorCampaignSets.get('updatedCampaigns');
        const newCampaigns: List<PatientCampaignSetModel> = categorisedMedadvisorCampaignSets.get('newCampaigns');
        // `shownCampaignNotifications` keeps track of ids of campaigns which are shown
        // This is to avoid notifying the user with same campaign in Poll interval
        const [
          updatedShownNotificationIds,
          unNotifiedUpdatedCampaigns,
        ] = getUnNotifiedCampaigns(updatedCampaigns, shownCampaignNotifications);
        const [
          updatedNewShownNotificationIds,
          unNotifiedNewCampaigns,
        ] = getUnNotifiedCampaigns(newCampaigns, shownCampaignNotifications);
        store.dispatch(setCampaignSetView(campaignSets, categorisedMedadvisorCampaignSets.get('readCampaignsMap', Map())));
        notifiyNewCampaigns(unNotifiedUpdatedCampaigns, unNotifiedNewCampaigns, store);
        shownCampaignNotifications = shownCampaignNotifications
          .concat(updatedShownNotificationIds)
          .concat(updatedNewShownNotificationIds);
      })
      .catch((error) => {
        if (error.status === 401) {
          clearInterval(interval);
        } else {
          handleApiError(error);
        }
        return List();
      });
  };
};

export const syncCampaigns = fetchCampaignSets();

/**
 * Updates db and store with new value
 * @param {List<PatientCampaignSetModel>} campaignSets List of selected campaignsets
 * @param {string} action Performed action
 * @param {DispatchProp} dispatch Redux dispatch
 * @returns {Promise<void>}
 */
export const updateCampaignSets = (
  campaignSets: List<PatientCampaignSetModel>,
  action: 'activate' | 'deactivate' | 'delete' | 'create' | 'update',
  dispatch: Dispatch,
) => {
  /**
   * Saves the models
   * @param {List<PatientCampaignSetModel>} models models to save
   * @returns {Promise}
   */
  const saveModels = (models: List<PatientCampaignSetModel | PatientCampaignModel>) =>
    saveModelsFactory(dispatch)(models.toArray()).then(() =>
      dispatch(updateCampaignSetModels(models.filter(doc => doc.get('type') === 'patient_campaign_set'))))
      .then(() => {
        createSuccessNotification(translate(`campaign_sets_successfully_${action}`));
      })
      .catch(handleApiError);

  return Promise.all(campaignSets
    .filter(e => e.hasBeenSaved())
    .map(campaignSet => fetchData(getPatientCampaignDataViews(campaignSet.get('_id'), false))))
    .then((campaigns: Array<List<PatientCampaignModel>>) => {
      const allCampaigns = List(campaigns).flatten(true) as List<PatientCampaignModel>;
      switch (action) {
        case 'activate': {
          const updatedModels = campaignSets.concat(allCampaigns).map(c => c.set('is_active', true));
          return saveModels(updatedModels);
        }
        case 'deactivate': {
          const updatedModels = campaignSets.concat(allCampaigns).map(c => c.set('is_active', false));
          dispatch(updateCampaignSetModels(updatedModels));
          return saveModels(updatedModels);
        }
        case 'delete': {
          const updatedModels = campaignSets.map(c => c.set('hidden', true));
          dispatch(updateCampaignSetModels(updatedModels));
          return saveModels(updatedModels);
        }
        case 'update':
        case 'create': {
          dispatch(updateCampaignSetModels(campaignSets));
          return saveModels(campaignSets);
        }
        default:
          return Promise.resolve();
      }
    });
};


/**
 * Perform the triggered action
 * @param {boolean} isCampaignSet True for Campaign set and false for campaign
 * @param {string} action The  menu action
 * @param {Function} resolver Function to call after the confirmation
 * @param {Function} onCancel Function to call if user cancels the operation
 * @returns {Promise<void>}
 */
export const getUpdateConfirmation = (
  isCampaignSet: boolean,
  action: string,
  resolver: VoidFunction,
  onCancel?: VoidFunction,
) => {
  const valueKey = translate(isCampaignSet ? 'campaign_sets' : 'campaigns').toLocaleLowerCase();
  switch (action) {
    case 'delete': {
      const confirmation = (
        <>
          {translate('delete_selected_x_warning', { x: valueKey })}
          {isCampaignSet && (
          <ConfirmMessage className="u-strong">
            {translate('delete_selected_x_warning_description', { x: valueKey })}
          </ConfirmMessage>)}
        </>
      );
      return getConfirmation(confirmation, {
        modalTitle: translate('delete_x', { x: valueKey }),
        footerSaveButtonName: translate('delete'),
        proceedButtonClassName: 'o-button--danger',
        hideCancel: true,
      }).then(
        () => {
          resolver();
        },
        () => {
          if (onCancel) {
            onCancel();
          }
        },
      );
    }
    case 'deactivate': {
      const confirmation = (
        <>
          {translate('deactivate_selected_x_warning_title', { x: valueKey })}
          <ConfirmMessage className="u-strong">
            {isCampaignSet && translate('deactivate_selected_x_warning', { x: valueKey })}
            {translate('deactivate_selected_x_warning_description', { x: valueKey })}
          </ConfirmMessage>
        </>
      );
      return getConfirmation(confirmation, {
        modalTitle: translate('deactivate_x', { x: valueKey }),
        footerSaveButtonName: translate('deactivate'),
        proceedButtonClassName: 'o-button--danger',
        hideCancel: true,
      }).then(
        () => {
          resolver();
        },
        () => {
          if (onCancel) {
            onCancel();
          }
        },
      );
    }
    case 'activate': {
      const confirmation = (
        <>
          {translate('activate_selected_x_warning', { x: valueKey })}
          {isCampaignSet && (
          <ConfirmMessage className="u-strong">
            {translate('activate_selected_x_warning_description', { x: valueKey })}
          </ConfirmMessage>)}
        </>
      );
      return getConfirmation(confirmation, {
        modalTitle: translate('activate_x', { x: valueKey }),
        footerSaveButtonName: translate('activate'),
        hideCancel: true,
      }).then(
        () => {
          resolver();
        },
        () => {
          if (onCancel) {
            onCancel();
          }
        },
      );
    }
    default:
      if (resolver) {
        return resolver();
      }
      return Promise.resolve();
  }
};
