import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';

type Props = {
  children: React.Node,
  id?: string,
  className?: string,
  style?: { [string]: string | number },
}

/**
 * A wrapper that fades in the content on load.
 * @param {any} props The component props.
 * @returns {React.Component} A React transition component.
 */
const ContentTransition = ({
  children, className, style, id,
}: Props) =>
  <CSSTransitionGroup
    transitionName="c-app-transition--fast"
    transitionAppear
    transitionAppearTimeout={150}
    transitionEnter={false}
    transitionLeave={false}
    className={className}
    style={style}
    id={id}
  >
    {children}
  </CSSTransitionGroup>;

export default ContentTransition;
