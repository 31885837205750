import React, { useState } from 'react';
import translate from './../../utils/i18n';
import type AnalyticsQueryResponseModel from './../../models/analytics/queryResponseModel';
import SummaryList from './summaryList';
import ReportCardTable from './reportCardTable';
import ReportCardChart from './reportCardChart';
import tab from './tabUtils';

type Props = {
  data: AnalyticsQueryResponseModel
};

const views = {
  table: {
    key: 'table',
    label: translate('analytics_view_table'),
  },
  chart: {
    key: 'chart',
    label: translate('analytics_view_chart'),
  },
};

/**
 * Returns the title for the key in the display text object
 * @param {string} key key to extract the title for
 * @param {Object} displayText The object that holds the title and an optional infotext
 * @returns {string} The title
*/
function getTitle(key, { display_text: displayText }) {
  if (key === '__truncate') {
    return 'Others';
  }
  return displayText[key] ? displayText[key].title : translate('unknown');
}

/**
 * Renders a report card component that will show all the data and charts
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function ReportCard(props: Props) {
  const { data } = props;

  const [view, setView] = useState(views.chart.key);

  const title = getTitle(data.get('metric', '-'), data.get('meta', {}));

  /**
   * Returns if the current view is table
   * @returns {bool} is current view table
  */
  function isTable() {
    return view === views.table.key;
  }

  return (
    <div>
      <SummaryList
        title={title}
        items={data.get('key_metrics', [])}
      />
      <div className="o-card">
        <div className="o-card__header">
          <h1 className="o-card__title">{title}</h1>
          <div style={{ width: '100%', textAlign: 'right', marginRight: '1.33rem' }}>
            {tab.render(views, view, setView)}
          </div>
        </div>
        {
            isTable() ?
              <ReportCardTable
                data={data.get('aggregate', {})}
                xAxis={data.get('xaxis', {}).key || '-'}
                displayText={data.get('meta', {}).display_text}
              /> :
              <ReportCardChart
                metric={title}
                aggregate={data.get('aggregate', {})}
                timeSeries={data.get('time_series', {})}
                displayText={data.get('meta', {}).display_text}
              />
          }
      </div>
    </div>
  );
}

export default ReportCard;
