import React from 'react';
import { MenuItem, Button, Wrapper, Menu } from 'react-aria-menubutton';

export type MenuAction = {
  label: string,
  value: string,
  isDanger?: boolean,
  dataPublic?: boolean,
};

type Props = {
  buttonClassName?: string,
  className?: string,
  disabled?: boolean,
  label: string,
  items: Array<MenuAction>, // Ordered list of items with value and label
  onValueSelected: (value: string, event: SyntheticInputEvent<*>) => void, // Callback for when value is selected.
  dataPublic?: boolean,
}

/**
 * A MenuButton React Component. It creates a dropdown menu populated with the given items
 * that will call onValueSelected when clicked.
 * @param {Object[]} items A list of items to display.
 * @param {function} onValueSelected A callback for when an item is selected.
 * @returns {React.Component} A MenuButton component.
 */
const MenuButton = ({
  label, items, onValueSelected, disabled = false, className = '', buttonClassName = '', dataPublic,
}: Props) => {
  const menuItems = items.map((item: MenuAction) =>
    <li key={item.value} data-public={item.dataPublic} className="AriaMenuButton-menuItemWrapper">
      <MenuItem
        className={item.isDanger ? 'AriaMenuButton-menuItem AriaMenuButton-menuItem--danger' : 'AriaMenuButton-menuItem'}
        value={item.value}
      >
        {item.label}
      </MenuItem>
    </li>);

  return (
    <Wrapper className={`AriaMenuButton ${className}`} onSelection={onValueSelected} style={{ marginBottom: 0 }}>
      <Button data-public={dataPublic} className={`o-button AriaMenuButton-trigger ${buttonClassName} ${disabled ? 'AriaMenuButton-disabled' : ''}`} disabled={disabled}>{ label }</Button>
      <Menu className="AriaMenuButton-menu">
        <ul>{menuItems}</ul>
      </Menu>
    </Wrapper>
  );
};

export default MenuButton;
