import React from 'react';
import { List, Map } from 'immutable';
import Moment, { Moment as MomentType } from 'moment';
import { Link } from 'react-router-dom';
import glamorous from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import Header from './../header/header';
import translate from './../../utils/i18n';
import SidebarTextLink from './../sidebar/sidebarTextLink';
import SidebarTextLinkWrapper from './../sidebar/sidebarTextLinkWrapper';
import DatePicker from './../inputs/statefulDatepicker';
import TimeInput from './../inputs/timeInput';
import SaveButton from './../buttons/saveButton';
import MetricEntryForm from './metricEntryForm';
import MetricEntryModel from './../../models/metricEntryModel';
import { createSuccessNotification } from './../../utils/notifications';
import { getReferralQueryString, isReferralMode } from './../../utils/router';
import FormError from './../formError';

import type MetricTypeModel from './../../models/metricTypeModel';
import type { SaveModel } from './../../types';

type Props = {
  metricTypeID: string,
  metricTypes: List<MetricTypeModel>,
  patientID: string,
  saveModel: SaveModel,
  isOnline: boolean,
  isFromModal: boolean | null | undefined,
};

type State = {
  date: MomentType,
  time: MomentType,
  values: Map<string, List<string | number>>, // key is metricTypeID
  isSaving: boolean,
  errorMessage?: string,
  changesMade: boolean,
  modalVisible: boolean,
};

const VitalsForm = glamorous.div(({ isFromModal }) =>
  (isFromModal ? {} : {
    height: isReferralMode() ? 'calc(50vh - 50px)' : 'calc(100vh - 50px)',
  }));

const VitalsContainer = glamorous.div(({ isFromModal }) =>
  (isFromModal ? {} : {
    height: isReferralMode() ? '50vh' : '100vh',
  }));

const VitalsNav = glamorous.nav({
  height: isReferralMode() ? 'calc(50vh - 50px)' : 'calc(100vh - 50px)',
});

/**
 * A component for adding single or multiple vitals entries.
 * @class AddVitals
 * @extends {React.Component<Props, State>}
 */
class AddVitals extends React.Component<Props, State> {
  /**
   * Creates an instance of AddVitals.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      date: Moment(),
      time: Moment(),
      values: Map(),
      isSaving: false,
      changesMade: false,
      modalVisible: false,
    };
  }

  /**
   * Sets the value of the given index for a metricType.
   * @param {string} metricTypeID The metricType ID.
   * @param {(number | string)} value The new value
   * @param {number} index The index of the datatype the value is for.
   * @returns {void}
   */
  updateValue(metricTypeID: string, value: number | string, index: number) {
    this.setState({
      values: this.state.values.set(
        metricTypeID,
        this.state.values.get(metricTypeID, List()).set(index, value),
      ),
      changesMade: true,
    });
  }

  /**
   * Reset values when the metricTypeID changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.metricTypeID !== nextProps.metricTypeID) {
      this.setState({
        values: Map(),
        date: Moment(),
        time: Moment(),
        changesMade: false,
        errorMessage: undefined,
      });
    }
  }

  /**
   * Returns true if state.values has a valid entry for each metricType specified in props.
   * @returns {boolean}
   */
  valuesAreValid() {
    if (this.props.metricTypeID === 'ALL') {
      return this.props.metricTypes.every((metricType: MetricTypeModel) => {
        // when there is entry, the metricType should be complete
        if (this.state.values.get(metricType.get('_id'), List()).size > 0) {
          // ignore the ones with empty values for whole vital
          if (this.state.values.get(metricType.get('_id'), List()).every(i => i !== undefined && i !== null && i.toString() === '')) {
            return true;
          }
          return this.state.values.get(metricType.get('_id'), List()).size === metricType.get('datatype', []).length
              && this.state.values.get(metricType.get('_id'), List()).every(i => i !== undefined && i !== null && i.toString() !== '');
        }
        return true;
      })
        &&
        this.props.metricTypes.some(metricType => this.state.values.get(metricType.get('_id'), List()).size > 0
          && this.state.values.get(metricType.get('_id'), List()).every(i => i !== undefined && i !== null && i.toString() !== '')); // there should be minimum one entry
    }
    const metricType = this.props.metricTypes
      .find(mType => mType.get('_id') === this.props.metricTypeID);
    return metricType // This should never fire as the app would have redirected previously if it was the case, however its included to keep Flow happy.
      && metricType.get('datatype', []).length === this.state.values.get(this.props.metricTypeID, List()).size // State has same number of values as metricType has datatypes.
      && this.state.values.get(metricType.get('_id'), List()).every(i => i !== undefined && i !== null && i.toString() !== '');
  }

  /**
   * Returns true if the form is valid. If not false is returns and an error message set in state.
   * @returns {boolean}
   */
  formIsValid() {
    if (!this.state.date || !this.state.date.isValid()) {
      this.setState({ errorMessage: translate('vitals_form_date_invalid') });
      return false;
    } else if (!this.state.time || !this.state.time.isValid()) {
      this.setState({ errorMessage: translate('vitals_form_time_invalid') });
      return false;
    } else if (!this.valuesAreValid()) {
      let errorMessage = 'vitals_form_values_invalid';
      if (this.props.metricTypeID === 'ALL') {
        errorMessage = 'vitals_form_some_required';
      }
      this.setState({ errorMessage: translate(errorMessage) });
      return false;
    }
    return true;
  }

  /**
   * Saves all metric entries if the form is valid.
   * @returns {Promise<boolean>}
   */
  saveMetricEntries(): Promise<boolean> {
    if (this.formIsValid()) {
      this.setState({ isSaving: true });
      const measuredTime = this.state.date
        .clone()
        .hours(this.state.time.hours())
        .minutes(this.state.time.minutes())
        .valueOf();
      return Promise.all(this.state.values.keySeq()
        // filter those values which have empty array for the vital
        .filter(metricTypeID => this.state.values.get(metricTypeID, List()).toArray().toString().replace(/,/g, '') !== '')
        .map(metricTypeID => new MetricEntryModel({
          metric_type: metricTypeID,
          values: this.state.values.get(metricTypeID, List()).toArray(),
          measured_time: measuredTime,
          patient_id: this.props.patientID,
        }))
        .map(metricEntryModel => this.props.saveModel(metricEntryModel))
        .toArray()).then(() => {
        createSuccessNotification(translate('vitals_saved'));
        this.setState({
          isSaving: false,
          changesMade: false,
          values: Map(),
          date: Moment(),
          time: Moment(),
          errorMessage: undefined,
        });
        this.setState({ modalVisible: false });
        return true;
      });
    }
    return Promise.resolve(false);
  }

  /**
   * Render the form
   * @param {boolean} metricType the metric type
   * @returns {React.Component}
   */
  renderForm(metricType) {
    return (
      <VitalsForm
        className={this.props.isFromModal ? '' : 'c-template-selector__content u-padding--standard-sides'}
        isFromModal={this.props.isFromModal}
      >
        { !this.props.isFromModal &&
          <div>
            <h1 className="o-title">{this.props.metricTypeID === 'ALL' ? translate('all_vitals') : metricType.get('name')}</h1>
            <hr />
          </div>
        }
        {
          this.state.errorMessage &&
          <FormError isSticky>
            {this.state.errorMessage}
          </FormError>
        }
        <DatePicker
          id="date"
          value={this.state.date}
          onValueChanged={(date: MomentType) => {
            if (date) {
              this.setState({ date, changesMade: true });
            }
          }}
          allowPast
          label={translate('date')}
          required
          showClearDate={false}
          className="u-margin-bottom--1ws"
        />
        <TimeInput
          id="time"
          value={this.state.time}
          onChange={time => this.setState({ time, changesMade: true })}
          label={translate('time')}
          required
          style={{ width: '150px' }}
        />
        <hr />
        {
          this.props.metricTypeID === 'ALL' ?
            this.props.metricTypes.map(mType =>
              <MetricEntryForm
                metricTypeID={this.props.metricTypeID}
                key={`metricTypeForm-${mType.get('_id')}`}
                metricType={mType}
                values={this.state.values.get(mType.get('_id'), List())}
                onValueChanged={(value: string | number, index: number) =>
                  this.updateValue(mType.get('_id'), value, index)}
              />) :
            <MetricEntryForm
              metricType={metricType}
              values={this.state.values.get(this.props.metricTypeID, List())}
              onValueChanged={(value: string | number, index: number) =>
                this.updateValue(this.props.metricTypeID, value, index)}
            />
        }
        { !this.props.isFromModal &&
          <SaveButton isSaving={this.state.isSaving} onClick={() => this.saveMetricEntries()} className="u-margin-bottom--1ws" />
        }
      </VitalsForm>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const metricType = this.props.metricTypeID !== 'ALL' ?
      this.props.metricTypes.find(mType => mType.get('_id') === this.props.metricTypeID) :
      this.props.metricTypes.first(); // If there are no metric types we also want to 404. This will only get used to check the existence of some metric type.
    if (!metricType) {
      return (
        <div className="o-text-content">
          <p className="u-font-medium">{translate('no_vitals_types_created')}</p>
          <p>
            <Link
              className="o-button o-button--padded"
              to="/settings/vitals"
            >
              {translate('add_vital_type')}
            </Link>
          </p>
        </div>
      );
    }
    return (
      <VitalsContainer isFromModal={this.props.isFromModal}>
        { !this.props.isFromModal &&
          <Header
            label={translate('add_vitals')}
            backLink={`/patient/${this.props.patientID}${getReferralQueryString()}`}
            style={{ width: '100%' }}
            isOffline={!this.props.isOnline}
          />
        }
        <div className="u-flex-row">
          { !this.props.isFromModal &&
            <VitalsNav className="c-template-selector__sidebar">
              <SidebarTextLinkWrapper>
                <SidebarTextLink
                  label={translate('all')}
                  url={`/patient/${this.props.patientID}/vitals/ALL/add${getReferralQueryString()}`}
                />
                {
                  this.props.metricTypes
                    .map(mType =>
                      <SidebarTextLink
                        label={mType.get('name')}
                        url={`/patient/${this.props.patientID}/vitals/${mType.get('_id')}/add${getReferralQueryString()}`}
                      />)
                    .toArray()
                }
              </SidebarTextLinkWrapper>
            </VitalsNav>
          }
          { !this.props.isFromModal ?
            this.renderForm(metricType) :
            <StatelessModal
              id="addVitals"
              buttonLabel={translate('add_vitals')}
              buttonClass="o-button o-button--padded o-button--small u-width-180"
              title={translate('add_vitals')}
              setVisible={(isVisible) => {
                const { modalVisible, changesMade, errorMessage } = this.state;
                if (modalVisible && changesMade && !errorMessage) {
                  this.setState({ errorMessage: translate('you_have_unsaved_changes') });
                } else {
                  this.setState({ modalVisible: isVisible });
                }
              }}
              onClose={() => {
                const { changesMade, errorMessage } = this.state;
                if (!changesMade || errorMessage) {
                  this.setState({
                    errorMessage: undefined,
                    modalVisible: false,
                    changesMade: false,
                    values: Map(),
                    date: Moment(),
                    time: Moment(),
                  });
                }
              }}
              visible={this.state.modalVisible}
              explicitCloseOnly
              dataPublicHeader
            >
              <section className="o-form" id="addVitalsForm">
                {this.renderForm(metricType)}
              </section>
              <ModalFooter>
                <SaveButton
                  onClick={() => this.saveMetricEntries()}
                  isSaving={this.state.isSaving}
                  label={translate('save')}
                  className="o-button--small u-margin-right--half-ws"
                  dataPublic
                />
              </ModalFooter>
            </StatelessModal>
          }
        </div>
      </VitalsContainer>
    );
  }
}

export default AddVitals;
