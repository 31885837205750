import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import translate from './../utils/i18n';
import MapListEditor from './../components/listEditor/mapListEditor';
import { updateConfigValue, updateConfig } from './../actions';
import { updateClinicConfig } from './../utils/db';

import type { Dispatch, Config, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ config }: State) => ({
  config,
  items: config.getIn(['prescription', 'frequencies'], List()).filter((e: List<Map<string, string>>) => e !== null && e !== undefined),
  label: translate('drug_frequencies'),
  columns: [
    { accessor: 'name', Header: translate('name'), filterable: true, required: true },
    { accessor: 'short_code', Header: translate('short_code'), filterable: true, required: false },
  ],
  type: 'drug_frequencies',
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateMaps: (updatedMaps: List<Map<string, string>>) => dispatch(updateConfigValue(['prescription', 'frequencies'], updatedMaps)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

/**
 * Merges the props for this container.
 * @param {any} stateProps State Props
 * @param {any} dispatchProps Dispatch props
 * @returns {any} Merged Props
 */
const mergeProps = (stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>) => Object.assign({}, {
  updateMaps: (updatedMaps: List<Map<string, string>>) => {
    dispatchProps.updateMaps(updatedMaps);
    return updateClinicConfig(
      stateProps.config.toJS(),
      Map().setIn(['prescription', 'frequencies'], updatedMaps).toJS(),
      dispatchProps.updateConfig,
    );
  },
}, stateProps);

const DrugFrequencyEditorContainer =
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(MapListEditor);

export default DrugFrequencyEditorContainer;
