import { connect } from 'react-redux';
import type { List } from 'immutable';

import {
  setCurrentDataViews,
  setCurrentDataViewsError,
  setCurrentDataViewsModels,
  setIsFetching,
  updateConfig,
} from './../actions';
import PatientCampaignSets from '../components/patientOutreachCampaign/patientCampaignSets';

import type { Dispatch, State, Config } from './../types';
import type APIError from '../utils/apiError';
import PatientCampaignSetModel from '../models/patientCampaignSetModel';
import { updateCampaignSets } from '../utils/patientCampaign';
import AsyncFetch from '../components/asyncFetch';
import { getPatientCampaignSetDataViews } from '../dataViews';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State) => ({
  user: state.user,
  config: state.config,
  klinifyConfig: state.klinifyConfig,
  campaignSets: state.currentDataViewsModels
    .filter(m => m.get('type') === 'patient_campaign_set')
    .filter(c => c.get('master_campaign_set_type') !== 'MEDADVISOR' && c.isVisible()),
  containerDataViews: getPatientCampaignSetDataViews(true, 'klinify'),
  currentDataViews: state.currentDataViews,
  currentDataViewsError: state.currentDataViewsError,
  isFetching: state.isFetching,
  showLoadingIndicator: true,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  updateCampaignSets: (
    campaigns: List<PatientCampaignSetModel>,
    action: 'activate' | 'deactivate' | 'delete' | 'create' | 'update',
  ) => updateCampaignSets(campaigns, action, dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<PatientCampaignSetModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const PatientCampaignSetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AsyncFetch(PatientCampaignSets));

export default PatientCampaignSetsContainer;
