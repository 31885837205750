// This file contains reducers for all app wide data shared between users and patients.
import { List } from 'immutable';

import { decryptState, encryptData } from './../utils/crypto';

import type { Action } from './../actions';
import type { Model } from './../types';
import type AllergyModel from './../models/allergyModel';
import type BankModel from './../models/bankModel';
import type BillItemModel from './../models/billItemModel';
import type BillModel from './../models/billModel';
import type CaseNoteFileModel from './../models/caseNoteFileModel';
import type CategoryModel from './../models/categoryModel';
import type ClaimModel from './../models/claimModel';
import type ConditionModel from './../models/conditionModel';
import type CollectedSpecimenModel from './../models/collectedSpecimenModel';
import type CoveragePayorModel from './../models/coveragePayorModel';
import type CustomNoteModel from './../models/customNoteModel';
import type DocumentDataModel from '../models/documentDataModel';
import type DocumentTemplateModel from './../models/documentTemplateModel';
import type DrugModel from './../models/drugModel';
import type EncounterFlowModel from '../models/encounterFlowModel';
import type EncounterStageModel from '../models/encounterStageModel';
import type EncounterModel from './../models/encounterModel';
import type MedicalCertificateModel from './../models/medicalCertificateModel';
import type MedicalComplaintModel from './../models/medicalComplaintModel';
import type MedicationModel from './../models/medicationModel';
import type MetricEntryModel from './../models/metricEntryModel';
import type MetricTypeModel from './../models/metricTypeModel';
import type PatientModel from './../models/patientModel';
import type PaymentModel from './../models/paymentModel';
import type PaymentTypeModel from './../models/paymentTypeModel';
import type PractitionerModel from './../models/practitionerModel';
import type PrescriptionModel from './../models/prescriptionModel';
import type ProcedureTypeModel from './../models/procedureTypeModel';
import type ProcedureRequestModel from './../models/procedureRequestModel';
import type ProcedureStatusModel from './../models/procedureStatusModel';
import type ProcedureResultModel from './../models/procedureResultModel';
import type ProviderModel from './../models/providerModel';
import type ReceivableModel from './../models/receivableModel';
import type SalesItemModel from './../models/salesItemModel';
import type SpecimenModel from './../models/specimenModel';
import type TemplateGroupModel from './../models/templateGroupModel';
import type TemplateModel from './../models/templateModel';
import type TimeChitModel from './../models/timeChitModel';
import type UserConfigModel from './../models/userConfigModel';
import type UserGroupModel from './../models/userGroupModel';
import type AppointmentModel from './../models/appointmentModel';
import type ClinicModel from '../models/clinicModel';
import { docToModel } from '../utils/models';
import DiscountChargeModel from '../models/discountChargeModel';
import DosingRegimenModel from '../models/dosingRegimenModel';

/**
 * Handle the update of a model in state.
 * @param {string} type The model type
 * @param {Model} model The updated model
 * @param {List<Model>} state The current state.
 * @returns {List<Model>} The new state.
 */
function updateModel(type: string, model: Model, state: List<Model>): List<Model> {
  if (!model) {
    return state; // There seems to be some way for unknown documents to enter the app when interacting with old versions of Android. AFAIK we don't need those docs so we're just ignoring them.
  }
  const formattedType = type === 'CASENOTEFILE' ? 'caseNoteFile' : type.toLowerCase();
  if (model.get('type') !== formattedType) { return state; }
  const modelID = model.get('_id'); // Flow was getting confused when this is called directly in the below filter, so we're justing hoisting it up.
  let newState = List.isList(state) ? state.filter(m => m.get('_id') !== modelID) : List(); // Dump old version of new model.
  // Add new model unless the change specifies it was deleted.
  if (!model.attributes._deleted) {
    const modelToUpdate = model.beforeUpdate ? model.beforeUpdate() : model;
    newState = newState && newState.push(modelToUpdate);
  }
  return newState;
}

/**
   * Handles an action for the reducers in sharedDataReducerObject.
   * @param {string} type - The reducer type (e.g. CATEGORY, METRIC_TYPE)
   * @param {Immutable.List} state - The current state.
   * @param {Object} action - The action. Should have type and model values.
   * @return {Immutable.List} - The state after the actions have been computed.
   */
const handleModelAction = (type: string, state: List<Model>, action: Action): List<Model> | {} => {
  switch (action.type) {
    case 'ADD_MODELS': {
      const formattedType = type === 'CASENOTEFILE' ? 'caseNoteFile' : type.toLowerCase();
      return action.models.filter(model => model.get('type') === formattedType);
    }
    case 'UPDATE_MODEL': {
      return updateModel(type, action.model, state);
    }
    case 'UPDATE_MODELS': {
      const formattedType = type === 'CASENOTEFILE' ? 'caseNoteFile' : type.toLowerCase();
      const models = List(action.models)
        .filter(model => model.get('type') === formattedType);
      const ids = models.map(m => m.get('_id'));
      const filteredState = List.isList(state) ? state
        .filter(model => model && !ids.contains(model.get('_id'))) : List();
      return filteredState.concat(models.filter(m => !m.attributes._deleted))
        .map(m => (m.beforeUpdate ? m.beforeUpdate() : m));
    }
    case 'CLEAR_MODELS': {
      const formattedType = type === 'CASENOTEFILE' ? 'caseNoteFile' : type.toLowerCase();
      const models = List(action.models)
        .filter(model => model.get('type') === formattedType);
      const ids = models.groupBy(m => m.get('_id'));
      const filteredState = List.isList(state) ? state
        .filter(model => model && !ids.has(model.get('_id'))) : List();
      return filteredState;
    }
    case 'DECRYPT_MODEL_STATE': {
      // The state is stored as string in localstorage only.
      // $FlowFixMe
      const decryptedModels = decryptState(state, action.key, action.iv, action.userId);
      if (decryptedModels && Array.isArray(decryptedModels)) {
        return List(decryptedModels.map(decryptedModel => docToModel(decryptedModel.attributes)));
      }
      return List();
    }
    case 'ENCRYPT_MODEL_STATE': {
      return encryptData(state, action.key, action.iv, 'STATE', action.userId);
    }
    default:
      return state;
  }
};

// The reducers below are more or less the same with the only difference being the type provided
// to handleSharedDataAction. The action type (i.e. first arg to handleSharedDataAction) should
// be a uppercased (due to Redux convetions) versions of the document type.
const sharedDataReducerObject = {
  allergies: (state: List<AllergyModel> = List(), action: Action) => handleModelAction('ALLERGY', state, action),
  banks: (state: List<BankModel> = List(), action: Action) => handleModelAction('BANK', state, action),
  bills: (state: List<BillModel> = List(), action: Action) => handleModelAction('BILL', state, action),
  billItems: (state: List<BillItemModel> = List(), action: Action) => handleModelAction('BILL_ITEM', state, action),
  caseNoteFiles: (state: List<CaseNoteFileModel> = List(), action: Action) => handleModelAction('CASENOTEFILE', state, action),
  categories: (state: List<CategoryModel> = List(), action: Action) => handleModelAction('CATEGORY', state, action),
  claims: (state: List<ClaimModel> = List(), action: Action) => handleModelAction('CLAIM', state, action),
  clinics: (state: List<ClinicModel> = List(), action: Action) => handleModelAction('CLINIC', state, action),
  conditions: (state: List<ConditionModel> = List(), action: Action) => handleModelAction('CONDITION', state, action),
  collectedSpecimens: (state: List<CollectedSpecimenModel> = List(), action: Action) => handleModelAction('COLLECTED_SPECIMEN', state, action),
  coveragePayors: (state: List<CoveragePayorModel> = List(), action: Action) => handleModelAction('COVERAGE_PAYOR', state, action),
  customNotes: (state: List<CustomNoteModel> = List(), action: Action) => handleModelAction('CUSTOM_NOTE', state, action),
  documentData: (state: List<DocumentDataModel> = List(), action: Action) => handleModelAction('DOCUMENT_DATA', state, action),
  documentTemplates: (state: List<DocumentTemplateModel> = List(), action: Action) => handleModelAction('DOCUMENT_TEMPLATE', state, action),
  drugs: (state: List<DrugModel> = List(), action: Action) => handleModelAction('DRUG', state, action),
  encounterFlows: (state: List<EncounterFlowModel> = List(), action: Action) => handleModelAction('ENCOUNTER_FLOW', state, action),
  encounterStages: (state: List<EncounterStageModel> = List(), action: Action) => handleModelAction('ENCOUNTER_STAGE', state, action),
  encounters: (state: List<EncounterModel> = List(), action: Action) => handleModelAction('ENCOUNTER', state, action),
  medicalCertificates: (state: List<MedicalCertificateModel> = List(), action: Action) => handleModelAction('MEDICAL_CERTIFICATE', state, action),
  medicalComplaints: (state: List<MedicalComplaintModel> = List(), action: Action) => handleModelAction('MEDICAL_COMPLAINT', state, action),
  medication: (state: List<MedicationModel> = List(), action: Action) => handleModelAction('MEDICATION', state, action),
  metricEntries: (state: List<MetricEntryModel> = List(), action: Action) => handleModelAction('METRIC_ENTRY', state, action),
  metricTypes: (state: List<MetricTypeModel> = List(), action: Action) => handleModelAction('METRIC_TYPE', state, action),
  patients: (state: List<PatientModel> = List(), action: Action) => handleModelAction('PATIENT', state, action),
  payments: (state: List<PaymentModel> = List(), action: Action) => handleModelAction('PAYMENT', state, action),
  paymentTypes: (state: List<PaymentTypeModel> = List(), action: Action) => handleModelAction('PAYMENT_TYPE', state, action),
  practitioners: (state: List<PractitionerModel> = List(), action: Action) => handleModelAction('PRACTITIONER', state, action),
  prescriptions: (state: List<PrescriptionModel> = List(), action: Action) => handleModelAction('PRESCRIPTION', state, action),
  providers: (state: List<ProviderModel> = List(), action: Action) => handleModelAction('PROVIDER', state, action),
  procedureTypes: (state: List<ProcedureTypeModel> = List(), action: Action) => handleModelAction('PROCEDURE_TYPE', state, action),
  procedureRequests: (state: List<ProcedureRequestModel> = List(), action: Action) => handleModelAction('PROCEDURE_REQUEST', state, action),
  procedureStatuses: (state: List<ProcedureStatusModel> = List(), action: Action) => handleModelAction('PROCEDURE_STATUS', state, action),
  procedureResults: (state: List<ProcedureResultModel> = List(), action: Action) => handleModelAction('PROCEDURE_RESULT', state, action),
  receivables: (state: List<ReceivableModel> = List(), action: Action) => handleModelAction('RECEIVABLE', state, action),
  salesItems: (state: List<SalesItemModel> = List(), action: Action) => handleModelAction('SALES_ITEM', state, action),
  specimens: (state: List<SpecimenModel> = List(), action: Action) => handleModelAction('SPECIMEN', state, action),
  templateGroups: (state: List<TemplateGroupModel> = List(), action: Action) => handleModelAction('TEMPLATE_GROUP', state, action),
  templates: (state: List<TemplateModel> = List(), action: Action) => handleModelAction('TEMPLATE', state, action),
  timeChits: (state: List<TimeChitModel> = List(), action: Action) => handleModelAction('TIME_CHIT', state, action),
  userConfigs: (state: List<UserConfigModel> = List(), action: Action) => handleModelAction('USER_CONFIG', state, action),
  userGroups: (state: List<UserGroupModel> = List(), action: Action) => handleModelAction('USER_GROUP', state, action),
  suppliers: (state: List<UserGroupModel> = List(), action: Action) => handleModelAction('SUPPLIER', state, action),
  smsJobs: (state: List<smsJobs> = List(), action: Action) => handleModelAction('CAMPAIGN_JOB', state, action),
  appointments: (state: List<AppointmentModel> = List(), action: Action) => handleModelAction('APPOINTMENT', state, action),
  discountsCharges: (state: List<DiscountChargeModel> = List(), action: Action) => handleModelAction('DISCOUNT_CHARGE', state, action),
  dosingRegimens: (state: List<DosingRegimenModel> = List(), action: Action) => handleModelAction('DOSING_REGIMEN', state, action),
};

export default sharedDataReducerObject;
