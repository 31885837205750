import * as React from 'react';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import { isLoading } from './../../utils/utils';
import Table from './../table/table';

import type { Row, CustomColumn } from './../../types';

type Props = {
  isFetching: boolean,
  currentDataViewsError?: APIError,
  columns: Array<CustomColumn>,
  data: Array<{}>,
  onFilterAndSort: (data: Array<Row>) => void
};

/**
 * Payment displaying payment history of patients to clinic.
 * @class PaymentsTable
 * @extends {React.Component<Props>}
 */
class PaymentsTable extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Table
        columns={
          this.props.columns.map(c =>
            ({ accessor: c.value, Header: c.label, Cell: c.cell }))
        }
        data={this.props.data}
        loading={this.props.isFetching}
        noDataText={
          translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(this.props.isFetching, 'no_payments_found'))
        }
        defaultSorted={[{ id: 'paymentDate', desc: true }]}
        showPagination
        filteredSortedDataHandler={filteredData => this.props.onFilterAndSort(filteredData)}
        initialDataHandler={initialData => this.props.onFilterAndSort(initialData)}
      />);
  }
}

export default PaymentsTable;
