import { List } from 'immutable';
import React, { useEffect, useState } from 'react';

import CurrentConsultContainer from '../../containers/currentConsultContainer';
import { createPermission, hasSomePermission } from '../../utils/permissions';
import ConsultationHistoryGrid from '../consultations/consultationHistoryGrid';

import type AllergyModel from '../../models/allergyModel';
import type EncounterModel from '../../models/encounterModel';
import type PatientModel from '../../models/patientModel';
import type PatientStubModel from '../../models/patientStubModel';
import type { Config, User } from '../../types';

type Props = {
  isCurrent: boolean;
  pastEncounters: List<EncounterModel>;
  currentEncounter?: EncounterModel;
  user: User;
  config: Config;
  allergies: List<AllergyModel>;
  updateConfig: (config: Config) => void;
  updateConfigValue: (keys: Array<string>, value: List<string>) => void;
  patient: PatientModel | PatientStubModel;
};

/**
 * Renders the consultation details content.
 * Used to avoid UI if encounter is updated
 * @param {Props} props component props
 * @returns {React.FC}
 */
const ConsultationDetails = (props: Props) => {
  const {
    isCurrent,
    user,
    config,
    allergies,
    updateConfigValue,
    updateConfig,
    patient,
    pastEncounters,
    currentEncounter,
  } = props;

  const [visibleEncounter, setVisibleEncounter] = useState(currentEncounter);

  useEffect(() => {
    if (currentEncounter?.isCurrent()) {
      setVisibleEncounter(currentEncounter);
    }
  }, [currentEncounter]);

  if (isCurrent) {
    return (
      <div>
        {visibleEncounter &&
          hasSomePermission(
            user,
            List([
              createPermission('current_encounter_notes', 'read'),
              createPermission('current_encounter_conditions', 'read'),
              createPermission(
                'current_encounter_medical_certificates',
                'read',
              ),
              createPermission('current_encounter_time_chits', 'read'),
              createPermission('current_encounter_prescriptions', 'read'),
              createPermission('current_encounter_sales_items', 'read'),
            ]),
          ) && (
            <CurrentConsultContainer
              encounter={visibleEncounter}
              patient={patient}
              isCurrent={!!currentEncounter}
            />
        )}
      </div>
    );
  }
  return (
    <div>
      {hasSomePermission(
        user,
        List([
          createPermission('past_encounters_notes', 'read'),
          createPermission('past_encounters_conditions', 'read'),
          createPermission('past_encounters_medical_certificates', 'read'),
          createPermission('past_encounters_time_chits', 'read'),
          createPermission('past_encounters_prescriptions', 'read'),
        ]),
      ) && (
        <ConsultationHistoryGrid
          encounters={pastEncounters}
          config={config}
          allergies={allergies}
          patient={patient}
          updateConfigValue={updateConfigValue}
          updateConfig={updateConfig}
          user={user}
        />
      )}
    </div>
  );
};

export default ConsultationDetails;
