import React from 'react';
import glamorous from 'glamorous';

import Loading from './../loadingIndicator';
import translate from './../../utils/i18n';
import { boxShadows, colours, wsUnit, scaledH2 } from '../../utils/css';

type Props = {
  stat: number,
  imageSrc?: string,
  label: string,
  isFetching: boolean,
};


const Card = glamorous.div({
  backgroundColor: colours.maroon,
  borderColor: '#99264D',
  color: 'white',
  borderRadius: 4,
  boxShadow: boxShadows.card,
});

const CardHeader = glamorous.h1({
  fontSize: wsUnit,
  borderBottom: '1px solid #99264D',
  padding: 8,
});

const CardBody = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 12px',
  '& img': {
    width: 52,
    height: 'auto',
  },
  '& h2': {
    fontSize: scaledH2,
  },
});


/**
   * A Card Component showing stats
   * @namespace StatCard
   * @param {object} props The props for this component.
   * @returns {React.Component} statCard Component
   */
const StatCard = ({ stat, imageSrc, label, isFetching }: Props) => (
  <Card>
    <CardHeader>{translate(label)}</CardHeader>
    <CardBody>
      <img src={imageSrc || `static/images/icon_dashboard_${label}.png`} alt={label} />
      <h2>
        {stat || (stat === 0 && '0') || (isFetching && <Loading
          type="spin"
          size={32}
          color="white"
          style={{
            position: 'relative',
            top: '-16px',
          }}
        />) || '-'}
      </h2>
    </CardBody>
  </Card>
);

export default StatCard;
