import { connect } from 'react-redux';
import type { List } from 'immutable';

import ConsultationHistoryTable from './../components/consultations/consultationHistoryTable';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import { updateConfigValue, updateConfig } from './../actions';

import type { Dispatch, Config, State } from './../types';
import type EncounterModel from './../models/encounterModel';
import type PatientModel from './../models/patientModel';
import { getModelMapFromList } from '../utils/models';
import DosingRegimenModel from '../../models/dosingRegimenModel';

type Props = {
  encounters: List<EncounterModel>,
  patient: PatientModel,
};

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, drugs, medicalCertificates, salesItems, timeChits, prescriptions,
  isOnline, inventoryCount, conditions, practitioners, user, coveragePayors, bills, billItems,
  documentData, documentTemplates, procedureRequests, providers, procedureTypes, specimens,
  procedureStatuses, collectedSpecimens, isSendingLabOrder, procedureResults, klinifyConfig,
  verifiedDrugs, allergies,
  encounterStages, encounterFlows, dosingRegimens,
}: State, ownProps: Props) => ({
  coveragePayorID: ownProps.patient.getCoveragePayor(),
  coveragePayors,
  config,
  klinifyConfig,
  practitioners,
  drugs,
  salesItems,
  user,
  encounters: ownProps.encounters,
  conditions,
  medicalCertificates,
  prescriptions,
  timeChits,
  isOnline,
  inventoryCount,
  bills,
  billItems,
  documentData,
  documentTemplates: documentTemplates
    .filter(d => d.isVisible() && d.hasLocation('encounter')),
  providers,
  procedureTypes,
  procedureRequests,
  procedureStatuses,
  specimens,
  collectedSpecimens,
  isSendingLabOrder,
  procedureResults,
  verifiedDrugs,
  allergies: allergies.filter(m => m.get('patient_id') === ownProps.patient.get('_id') && m.isVisible()),
  encounterStageMap: getModelMapFromList(encounterStages),
  encounterFlows,
  dosingRegimens: dosingRegimens.filter(m => m.isVisible()),
  patientPrescriptionHistory: prescriptions.filter(p => p.get('patient_id') === ownProps.patient.get('_id')),
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateConfigValue,
  saveModels: saveModelsFactory(dispatch),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const ConsultationHistoryTableContainer =
  connect(mapStateToProps, mapDispatchToProps)(ConsultationHistoryTable);

export default ConsultationHistoryTableContainer;
