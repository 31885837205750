import React from 'react';
import { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import {
  removeEndOfLineSpaces,
} from './../../utils/utils';

import type { User } from './../../types';
import type ConditionModel from './../../models/conditionModel';
import type EncounterModel from './../../models/encounterModel';
import type PractitionerModel from '../../models/practitionerModel';
import type EncounterStageModel from '../../models/encounterStageModel';

type Props = {
  diagnoses: List<ConditionModel>,
  encounter: EncounterModel,
  symptoms: List<ConditionModel>,
  user: User,
  practitioners: List<PractitionerModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
};

/**
 * Shows the symptoms, diagnoses, and notes of an encounter.
 * @class EncounterSummary
 * @extends {React.PureComponent}
 */
class EncounterSummary extends React.PureComponent<Props> {
  props: Props;

  /**
   * Renders the a row component
   * @param {string} label The label
   * @param {string} key The key
   * @returns {React.Component} A row component.
   */
  renderRow = (label: string, key: string) =>
    <div className="o-data-list__row" key={key}>
      <div className="o-data-list__row__item">{label}</div>
    </div>

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() { // NOTE: The nested divs below are necessary for the CSS to work.
    const encounterNotes = this.props.encounter.getNotes(this.props.practitioners, this.props.encounterStageMap);
    return (
      <div>
        <PermissionWrapper permissionsRequired={List([createPermission('past_encounters_conditions', 'read')])} user={this.props.user}>
          <div>
            {
              this.props.symptoms.size > 0 &&
              <h2 data-public className="o-card__title o-card__title--white u-border--top">
                {translate('symptoms')}
              </h2>
            }
            {this.props.symptoms.map(s => this.renderRow(s.get('name'), s.get('_id')))}
          </div>
          <div>
            {
              this.props.diagnoses.size > 0 &&
              <h2 data-public className="o-card__title o-card__title--white u-border--top">
                {translate('diagnoses')}
              </h2>
            }
            {this.props.diagnoses.map(s => this.renderRow(s.get('name'), s.get('_id')))}
          </div>
        </PermissionWrapper>
        <PermissionWrapper permissionsRequired={List([createPermission('past_encounters_notes', 'read')])} user={this.props.user}>
          <h2 data-public className="o-card__title o-card__title--white u-border--top">
            {translate('notes')}
          </h2>
          <div className="_u-padding-bottom--1ws" style={{ whiteSpace: 'pre-wrap', overflowX: 'scroll' }}>
            {typeof encounterNotes !== 'string' ? Object.keys(encounterNotes).map(stageName => (
              <React.Fragment key={stageName}>
                <h6 className="o-card__title o-card__title--white u-border--top">
                  {`${stageName} ${translate('notes')}`}
                </h6>
                <pre className="u-padding--1ws u-pre-line">{removeEndOfLineSpaces(encounterNotes[stageName] || UNICODE.EMDASH)}</pre>
              </React.Fragment>
            )) : <pre className="u-padding--1ws u-pre-line">{removeEndOfLineSpaces(encounterNotes || UNICODE.EMDASH)}</pre>}
          </div>
        </PermissionWrapper>
      </div>
    );
  }
}

export default EncounterSummary;
