import React from 'react';
import glamorous from 'glamorous';
import { List } from 'immutable';

import Select from '../../inputs/select';
import translate from '../../../utils/i18n';
import BatchSelectTable from './batchSelectTable';
import type DrugModel from '../../../models/drugModel';
import type { Config, MapValue, CountPerSKUAndBatch } from '../../../types';
import SupplyItemModel from '../../../models/supplyItemModel';


type Props = {
  selectedDrug?: DrugModel,
  drugs: List<DrugModel>,
  onChangeSKU: (value: string) => void,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  inventoryCount: CountPerSKUAndBatch,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  showEmptyBatches: boolean,
  isSupplyItemChangeMode: boolean,
  displayTableColumnSettings: boolean,
  supplyItems: List<SupplyItemModel>,
  isFetching: boolean,
  onActionButtonClicked: (supplyItem: SupplyItemModel) => void,
  action: string,
  isBulk?: boolean,
  config: Config,
}

const SelectContainer = glamorous.div({
  zIndex: 1000,
});

const TableContainer = glamorous.div({
  zIndex: 0,
  position: 'relative',
});

/**
 * Renders a batch selection modal for the batch adjustment
 * @param {Props} props SummaryMetric
 * @returns {React.FunctionComponent} The rendered component
*/
function BatchSelectionModal(props: Props) {
  const { selectedDrug } = props;

  /**
   * @returns {List<SupplyItemModel>}
   */
  const getSupplyItems = () => (selectedDrug ? props.supplyItems.filter(s => s.get('sku_id') === selectedDrug.get('_id')) : List());

  return (
    <React.Fragment>
      <SelectContainer>
        <div className="u-margin--standard">
          <Select
            options={
            props.drugs
              .filter(model => model.isVisible())
              .map(i => ({ value: i.get('_id'), label: i.get('name') }))
              .toArray()
          }
            autoFocus
            id="drug"
            label={translate('search_for_sku')}
            value={selectedDrug ? selectedDrug.get('_id') : ''}
            onValueChanged={value => props.onChangeSKU(value)}
            required
          />
          <hr />
        </div>
      </SelectContainer>
      <TableContainer>
        <div className="o-card">
          <BatchSelectTable
            selectedDrug={props.selectedDrug}
            isSupplyItemChangeMode={props.isSupplyItemChangeMode}
            showEmptyBatches={props.showEmptyBatches}
            displayTableColumnSettings={props.displayTableColumnSettings}
            inventoryCountSyncStatus={props.inventoryCountSyncStatus}
            inventoryCount={props.inventoryCount}
            supplyItems={getSupplyItems()}
            isFetching={props.isFetching}
            action={props.action}
            onActionButtonClicked={props.onActionButtonClicked}
            updateConfigValue={props.updateConfigValue}
            updateConfig={props.updateConfig}
            isBulk={props.isBulk}
            config={props.config}
          />
        </div>
      </TableContainer>

    </React.Fragment>
  );
}

export default BatchSelectionModal;
