import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { List, Map } from 'immutable';
import { Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import 'react-dates/initialize';

import LoadingIndicator from './loadingIndicator';
import LoginContainer from './../containers/loginContainer';
import Layout from './layout';
import Notifications from './notification/notificationStack';
import DemoNotification from './notification/demoNotification';
import { WrappedRoute } from './../utils/router';
import { displayTip, createSuccessNotification, createErrorNotification } from './../utils/notifications';
import { createPermission } from './../utils/permissions';
import OfflineNotificationContainer from './../containers/offlineNotificationContainer';
import translate from './../utils/i18n';
import { isExportUser, isKlinifyUser } from './../utils/auth';
import MdlPopup from './inventory/mdlPopup';
import { onConfirmOrRejectRequest } from '../utils/appointment';
import { debugPrint } from '../utils/logging';
import { DOC_VALIDATION_ENABLED, MEDADVISOR_CAMPAIGNS_ENABLED, PHARMACONNECT_ENABLED, OUTBREAK_STATS_TAB_ENABLED } from '../constants';


import AccountsReceivableContainer from './../containers/accountsReceivableContainer';
import AccessManagementContainer from './../containers/accessManagementContainer';
import AddClaimInvoiceContainer from './../containers/addClaimInvoiceContainer';
import AddSupplyContainer from './../containers/addSupplyContainer';
import BanksContainer from './../containers/banksContainer';
import ChangeLogContainer from './../containers/changeLogContainer';
import ChangePasswordContainer from './../containers/changePasswordContainer';
import ClaimInvoiceContainer from './../containers/claimInvoiceContainer';
import ClaimInvoicesContainer from './../containers/claimInvoicesContainer';
import ClinicsContainer from './../containers/clinicsContainer';
import ClinicSettingsContainer from './../containers/clinicSettingsContainer';
import ConsultReportsContainer from './../containers/consultReportsContainer';
import CoveragePayorEditorContainer from './../containers/coveragePayorEditorContainer';
import CostReportContainer from './../containers/costReportContainer';
import InventoryStatusContainer from './../containers/inventoryStatusContainer';
import InventoryMappingContainer from './../containers/inventoryMappingContainer';
import DashboardContainer from './../containers/dashboardContainer';
import DiagnosesEditorContainer from './../containers/diagnosesEditorContainer';
import DoctorsContainer from './../containers/doctorsContainer';
import DocumentTemplatesContainer from './../containers/documentTemplatesContainer';
import DrugDispensationContainer from './../containers/drugDispensationContainer';
import DrugFrequencyEditorContainer from './../containers/drugFrequencyEditorContainer';
import DrugsContainer from './../containers/drugsContainer';
import ExpiryDatesContainer from './../containers/expiryDatesContainer';
import MetricTypesContainer from './../containers/metricTypesContainer';
import LabsContainer from './../containers/labsContainer';
import LabTestsContainer from './../containers/labTestsContainer';
import LabRequestsContainer from './../containers/labRequestsContainer';
import OperationsSummaryContainer from './../containers/operationsSummaryContainer';
import OutbreakGuidelinesContainer from './../containers/outbreakGuidelinesContainer';
import OutbreakStatsContainer from './../containers/outbreakStatsContainer';
import OutbreakLabsContainer from './../containers/outbreakLabsContainer';
import OutbreakBrochuresContainer from './../containers/outbreakBrochuresContainer';
import OutbreakResearchContainer from './../containers/outbreakResearchContainer';
import MykadContainer from './../containers/mykadContainer';
import PatientContainer from './../containers/patientContainer';
import PatientListContainer from './../containers/patientListContainer';
import PatientQueueContainer from './../containers/patientQueueContainer';
import PatientRegistrationContainer from './../containers/patientRegistrationContainer';
import PatientsListContainer from './../containers/patientsListContainer';
import PatientTagsEditorContainer from './../containers/patientTagsEditorContainer';
import PaymentsOutstandingContainer from './../containers/paymentsOutstandingContainer';
import PanelPaymentsContainer from './../containers/panelPaymentsContainer';
import PaymentHistoryContainer from './../containers/paymentHistoryContainer';
import PaymentTypesContainer from './../containers/paymentTypesContainer';
import SalesItemsContainer from './../containers/salesItemsContainer';
import SmsJobContainer from './../containers/smsJobContainer';
import Styleguide from './styleguide/styleguide';
import SuppliersContainer from './../containers/suppliersContainer';
import SupplyInvoicesContainer from './../containers/supplyInvoicesContainer';
import SupplyInvoicesListContainer from './../containers/supplyInvoicesListContainer';
import SuppliesReceivedContainer from './../containers/suppliesReceivedContainer';
import SymptomsEditorContainer from './../containers/symptomsEditorContainer';
import TransferInContainer from './../containers/transferInContainer';
import TransferOutContainer from './../containers/transferOutContainer';
import UnsyncedDocumentsContainer from '../containers/unsyncedDocumentsContainer';
import UserGroupPermissionsContainer from './../containers/userGroupPermissionsContainer';
import UserGroupsContainer from './../containers/userGroupsContainer';
import UserManagementContainer from './../containers/userManagementContainer';
import PanelCategoriesEditorContainer from './../containers/panelCategoriesEditorContainer';
import SpecimensContainer from './../containers/specimensContainer';
import DataDebug from './dataDebug/dataDebug';
import DrugDurationsContainer from './../containers/drugDurationsContainer';
import OfflineNotifierContainer from '../containers/offlineNotifierContainer';
import MedadvisorCampaignSetsContainer from '../containers/medadvisorCampaignSetsContainer';
import InventoryReportsContainer from './../containers/inventoryReportsContainer';
import SMSCampaignsContainer from '../containers/smsCampaignContainer';
import CampaignJobsContainer from '../containers/campaignJobsContainer';
import PatientCampaignSetsContainer from '../containers/patientCampaignSetsContainer';
import withStorageListener from '../components/hoc/withStorageListener';

import AnalyticsSummaryContainer from './../containers/analyticsSummaryContainer';
import AnalyticsNewReportContainer from './../containers/analyticsNewReportContainer';
import AnalyticsReportContainer from './../containers/analyticsReportContainer';
import StagesContainer from '../containers/encounterStageContainer';
import encounterFlowContainer from '../containers/encounterFlowContainer';
import DataExportContainer from '../containers/dataExportContainer';
import PharmaConnectContainer from '../containers/pharmaConnectContainer';
import PharmaConnectBrandContainer from '../containers/pharmaConnectBrandContainer';
import PatientModel from '../models/patientModel';
import DocumentValidationModal from '../containers/documentValidationContainer';
import AppointmentFormModal from '../components/patient/appointmentFormModal';
import AppointmentsContainer from '../containers/appointmentsContainer';
import DiscountsChargesContainer from './../containers/discountsChargesContainer';
import DosingRegimenContainer from './../containers/dosingRegimenContainer';

import type { ReactRouterLocation, Config, User, AppointmentArgs, MissingDocObject } from './../types';
import type AppointmentModel from '../models/appointmentModel';
import type PractitionerModel from '../models/practitionerModel';
import type SalesItemModel from '../models/salesItemModel';
import type EncounterFlowModel from './../models/encounterFlowModel';
import type EncounterModel from './../models/encounterModel';

type Props = RouteComponentProps & {
  appStartupComplete: boolean,
  hasAuth: number,
  hasUnsyncedDocuments: Promise<boolean>,
  isOnline: boolean,
  location: ReactRouterLocation,
  config: Config,
  klinifyConfig: Config,
  isSyncing: boolean,
  user: User,
  appointmentRequested: AppointmentModel | null,
  showAppointmentRequested: (model: AppointmentModel | null) => void,
  practitioners: List<PractitionerModel>,
  salesItems: List<SalesItemModel>,
  patients: List<PatientModel>,
  updateModelsInState: (models: AppointmentModel | null) => void,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  encounters: List<EncounterModel>,
  appointments: List<AppointmentModel>,
  docsForRegeneration: Map<string, MissingDocObject>,
};

type State = {
  hasUnsyncedDocuments: boolean,
}

/**
 * Checks if a tip should be displayed.
 * @param {any} config App config.
 * @returns {void}
 */
function triggerTip(config: Config) {
  const tipLikelihood = config.getIn(['mobileWebappSettings', 'tipLikelihood'], 0.2);
  if (!isNaN(tipLikelihood) && Math.random() < parseFloat(tipLikelihood)) {
    displayTip();
  }
}

/**
 * Renders the the appropriate child container.
 * @param {number} hasAuth 1 if user has auth else 0 and -1 if state has not been set.
 * @param {boolean} appStartupComplete True if app has completed startup.
 * @param {boolean} isOnline True if user is online.
 * @param {boolean} isSyncing True if app is syncing offline docs.
 * @param {User} user User.
 * @param {string} path current path from location prop
 * @param {boolean} hasUnsyncedDocuments True if user has unsynced docs.
 * @param {boolean} isDemo True if demo banner is set to show.
 * @param {boolean} showInventoryMapping True if user has authority to view inventory mapping
 * @param {boolean} isMedadvisorEnabled True if Medadvisor Campaign feature is enabled for this clinic
 * @returns {React.Component} The component.
 */
function renderApplicationContent(
  hasAuth: number,
  appStartupComplete: boolean,
  isOnline: boolean,
  isSyncing: boolean,
  user: User,
  path: string,
  hasUnsyncedDocuments: boolean,
  isDemo: boolean,
  showInventoryMapping: boolean,
  isMedadvisorEnabled: boolean,
) {
  const showCampaignpage = !(/^\/campaigns\/medadvisor(\/|$)/.test(path)) || isMedadvisorEnabled;
  if (hasAuth === -1) {
    return (
      <LoadingIndicator alignCenter style={{ marginTop: 'calc(50vh - 32px)' }} />
    );
  }
  if (hasAuth) {
    if (appStartupComplete) {
      if (
        user &&
        (user.get('model') === undefined || !user.get('model').hasPermissions()) &&
        (!isKlinifyUser(user) && (user.get('userGroup') === undefined || !user.get('userGroup').hasPermissions()))
      ) {
        return <p style={{ margin: '1em' }}>{translate('no_permissions_set')}</p>;
      }
      if (isExportUser(user)) {
        return (
          <Layout>
            <Switch>
              <WrappedRoute path="/data-export" component={DataExportContainer} title="data_export" user={user} />
              <Redirect to="/data-export" />
            </Switch>
          </Layout>
        );
      }
      return (
        <Layout>
          <Switch>
            <WrappedRoute exact path="/" component={PatientListContainer} title="patient_overview" user={user} />
            <WrappedRoute exact path="/accounts/claim-invoices" component={ClaimInvoicesContainer} title="claim_invoices" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('claim_invoice', 'read')])} />
            <WrappedRoute exact path="/accounts/claim-invoices/add" component={AddClaimInvoiceContainer} title="unclaimed_bills" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('claim_invoice', 'create')])} />
            <WrappedRoute exact path="/accounts/claim-invoices/:claimInvoiceId" component={ClaimInvoiceContainer} title="claim_invoice" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('claim_invoice', 'read')])} />
            <WrappedRoute exact path="/accounts/payments-outstanding" component={PaymentsOutstandingContainer} title="payments_outstanding" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute exact path="/accounts/panel-payments" component={PanelPaymentsContainer} title="panel_payments" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute exact path="/accounts/payment-history" component={PaymentHistoryContainer} title="payment_history" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute exact path="/admin/user-groups" component={UserGroupsContainer} title="user_groups" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('user_groups', 'read')])} />
            <WrappedRoute path="/admin/user-groups/:userGroupId" component={UserGroupPermissionsContainer} title="user_group_permissions" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('user_groups', 'update')])} />
            <WrappedRoute path="/admin/user-management" component={UserManagementContainer} title="user_management" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('users', 'read')])} />
            <WrappedRoute path="/appointments" component={AppointmentsContainer} title="appointments" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute path="/queue" component={PatientQueueContainer} title="patient_queue" user={user} />
            <WrappedRoute path="/reports/consults" component={ConsultReportsContainer} title="consult_reports" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('consult_reports', 'read')])} />
            <WrappedRoute path="/reports/accounts-receivable" component={AccountsReceivableContainer} title="accounts_receivable_reports" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('accounts_receivable', 'read')])} />
            <WrappedRoute path="/reports/operations-summary" component={OperationsSummaryContainer} title="operations_summary" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('operations_summary', 'read')])} />
            <WrappedRoute path="/reports/stock-adjustments" component={SuppliesReceivedContainer} title="stock_adjustments" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('supplies_added_and_transfers', 'read')])} />
            <WrappedRoute path="/reports/cost-of-goods-sold" component={CostReportContainer} title="cost_of_goods_sold" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('cost_report', 'read')])} />
            <WrappedRoute path="/reports/inventory" component={InventoryReportsContainer} title="inventory_reports" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('inventory_reports', 'read')])} />
            { (isKlinifyUser(user) || showInventoryMapping) && <WrappedRoute path="/inventory/inventory-mapping" component={InventoryMappingContainer} title="inventory_mapping" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('inventory_mapping', 'read')])} /> }
            <WrappedRoute path="/inventory/inventory-status" component={InventoryStatusContainer} title="inventory_status" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('inventory_status', 'read')])} />
            <WrappedRoute path="/inventory/inventory-items" component={DrugsContainer} title="inventory_items" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('inventory_items', 'read')])} />
            <WrappedRoute path="/inventory/add-supply" component={AddSupplyContainer} title="add_supply" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('supplies', 'create')])} />
            <WrappedRoute path="/inventory/transfer-in" component={TransferInContainer} title="transfer_in" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('supplies', 'create')])} />
            <WrappedRoute path="/inventory/transfer-out" component={TransferOutContainer} title="transfer_out" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('supplies', 'create')])} />
            <WrappedRoute path="/inventory/drug-dispensation" component={DrugDispensationContainer} title="drug_dispensation" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('drug_dispensation', 'read')])} />
            <WrappedRoute path="/inventory/expiry-dates" component={ExpiryDatesContainer} title="expiry_dates" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('expiry_dates', 'read')])} />
            <WrappedRoute path="/mykad" component={MykadContainer} title="reading_mykad" user={user} />
            <WrappedRoute exact path="/inventory/supply-invoices" component={SupplyInvoicesListContainer} title="supply_invoices" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('clinic_settings', 'update')])} />
            <WrappedRoute path="/inventory/supply-invoices/:supplierId" component={SupplyInvoicesContainer} title="supply_invoices" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('clinic_settings', 'update')])} />
            <WrappedRoute path="/patient-list" component={PatientsListContainer} title="patient_list" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('patient_list', 'read')])} />
            <WrappedRoute path="/lab-requests" component={LabRequestsContainer} title="lab_requests" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('lab_requests', 'read')])} />
            { isMedadvisorEnabled && <WrappedRoute exact path="/campaigns/medadvisor" component={MedadvisorCampaignSetsContainer} title="medadvisor_campaigns" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('sms_campaigns', 'read')])} />},
            <WrappedRoute exact path="/campaigns/patient" component={PatientCampaignSetsContainer} title="patient_campaigns" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('sms_campaigns', 'read')])} />,
            { showCampaignpage && <WrappedRoute exact path="/campaigns/:campaignSubType/:campaignSetID" component={SMSCampaignsContainer} title="sms_campaigns" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('sms_campaigns', 'read')])} />},
            { showCampaignpage && <WrappedRoute exact path="/campaigns/:campaignSubType/:campaignSetID/:campaignID" component={CampaignJobsContainer} title="sms_campaign_jobs" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('sms_campaigns', 'read')])} />},
            { showCampaignpage && <WrappedRoute exact path="/campaigns/:campaignSubType/:campaignSetID/:campaignID/jobs/:smsJobID" component={SmsJobContainer} title="SMS Campaign Job" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('sms_campaigns', 'read')])} />},
            <WrappedRoute path="/login" component={LoginContainer} title="login" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute path="/patient/:patientID" component={PatientContainer} title="patient" user={user} permissions={List([createPermission('patient', 'read')])} />
            <WrappedRoute path="/registration" component={PatientRegistrationContainer} title="registration" user={user} permissions={List([createPermission('patient', 'create')])} />
            <WrappedRoute exact path="/settings" component={ClinicSettingsContainer} title="clinic_settings" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('clinic_settings', 'update')])} />
            <WrappedRoute exact path="/settings/queue" component={ClinicSettingsContainer} title="queue_settings" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('clinic_settings', 'update')])} />
            <WrappedRoute path="/settings/clinics" component={ClinicsContainer} title="clinics" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('suppliers', 'read')])} />
            <WrappedRoute path="/settings/panel-categories" component={PanelCategoriesEditorContainer} title="panel_categories" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('panel_categories', 'read')])} />
            <WrappedRoute path="/settings/coverage-payors" component={CoveragePayorEditorContainer} title="coverage_payors" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('coverage_payors', 'read')])} />
            <WrappedRoute path="/settings/dosing-regimen" component={DosingRegimenContainer} title="dosing_regimen" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('dosing_regimen', 'read')])} />
            <WrappedRoute path="/settings/doctors" component={DoctorsContainer} title="doctors" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('doctors', 'read')])} />
            <WrappedRoute path="/settings/document-templates" component={DocumentTemplatesContainer} title="document_templates" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('document_templates_settings', 'read')])} />
            <WrappedRoute path="/settings/diagnoses" component={DiagnosesEditorContainer} title="diagnoses" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('diagnosis_options', 'read')])} />
            <WrappedRoute path="/settings/stages" component={StagesContainer} title="stages" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute path="/settings/flows" component={encounterFlowContainer} title="flows" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            <WrappedRoute path="/settings/patient-tags" component={PatientTagsEditorContainer} title="patient_tags" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('patient_tags_options', 'read')])} />
            <WrappedRoute path="/settings/drug-frequencies" component={DrugFrequencyEditorContainer} title="drug_frequencies" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('drug_frequencies', 'read')])} />
            <WrappedRoute path="/settings/drug-durations" component={DrugDurationsContainer} title="drug_durations" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('drug_durations', 'read')])} />
            <WrappedRoute path="/settings/sales-items" component={SalesItemsContainer} title="sales_items" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('sales_items', 'read')])} />
            <WrappedRoute path="/settings/suppliers" component={SuppliersContainer} title="suppliers" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('suppliers', 'read')])} />
            <WrappedRoute path="/settings/symptoms" component={SymptomsEditorContainer} title="symptoms" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('symptom_options', 'read')])} />
            <WrappedRoute path="/settings/vitals" component={MetricTypesContainer} title="vitals_settings" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('metric_types', 'read')])} />
            <WrappedRoute path="/settings/labs" component={LabsContainer} title="laboratories" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('laboratories', 'read')])} />
            <WrappedRoute path="/settings/lab-tests" component={LabTestsContainer} title="lab_tests" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('lab_tests', 'read')])} />
            <WrappedRoute path="/settings/specimens" component={SpecimensContainer} title="specimens" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('specimens', 'read')])} />
            <WrappedRoute path="/settings/discounts-charges" component={DiscountsChargesContainer} title="discounts_charges" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('discounts_charges', 'read')])} />
            <WrappedRoute path="/settings/banks" component={BanksContainer} title="banks_settings" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('banks', 'read')])} />
            <WrappedRoute path="/settings/payment-types" component={PaymentTypesContainer} title="payment_types" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('payment_types', 'read')])} />
            {isKlinifyUser(user) && <WrappedRoute path="/data-debug" component={DataDebug} title="data_debug" user={user} />}
            <WrappedRoute path="/styleguide" component={Styleguide} title="styleguide" user={user} />
            {isKlinifyUser(user) && <WrappedRoute path="/access-management" component={AccessManagementContainer} title="access_management" user={user} />}
            {isKlinifyUser(user) && <WrappedRoute path="/data-export" component={DataExportContainer} title="data_export" user={user} />}
            <WrappedRoute path="/release-notes/:versionNumber?" component={ChangeLogContainer} title="release_notes" user={user} />
            <WrappedRoute path="/unsynced-documents" component={UnsyncedDocumentsContainer} title="unsynced_documents" user={user} />
            <WrappedRoute path="/user/change-password" component={ChangePasswordContainer} title="change_password" disabledDueToOffline={!isOnline || isSyncing} user={user} />
            {/* Analytics Dashboard Routes - currently shown only for Klinify user */}
            {isKlinifyUser(user) && <WrappedRoute path="/analytics/reports/new" component={AnalyticsNewReportContainer} title="analytics_new_report" user={user} />}
            {isKlinifyUser(user) && <WrappedRoute path="/analytics/reports/:report_id" component={AnalyticsReportContainer} title="analytics_new_report" user={user} />}
            {isKlinifyUser(user) && <WrappedRoute path="/analytics/summary" component={AnalyticsSummaryContainer} title="analytics_summary_page_title" user={user} />}
            <Redirect exact from="/analytics" to="/analytics/today" />
            <WrappedRoute exact path="/analytics/:interval" component={DashboardContainer} title="stat_dashboard" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('stat_dashboard', 'read')])} />
            <WrappedRoute exact path="/analytics/:interval/:clinicId" component={DashboardContainer} title="stat_dashboard" disabledDueToOffline={!isOnline || isSyncing} user={user} permissions={List([createPermission('stat_dashboard', 'read')])} />
            <WrappedRoute exact path="/outbreak/guidelines" component={OutbreakGuidelinesContainer} title="outbreak_guidelines" user={user} />
            {OUTBREAK_STATS_TAB_ENABLED && <WrappedRoute exact path="/outbreak/stats" component={OutbreakStatsContainer} title="outbreak_stats" user={user} />}
            <WrappedRoute exact path="/outbreak/labs" component={OutbreakLabsContainer} title="outbreak_labs" user={user} />
            <WrappedRoute exact path="/outbreak/brochures" component={OutbreakBrochuresContainer} title="outbreak_brochures" user={user} />
            <WrappedRoute exact path="/outbreak/research" component={OutbreakResearchContainer} title="outbreak_research" user={user} />
            {PHARMACONNECT_ENABLED && <WrappedRoute exact path="/pharmaconnect/:brand_id" component={PharmaConnectBrandContainer} title="pharmaconnect" user={user} />}
            <WrappedRoute exact path="/pharmaconnect" component={PharmaConnectContainer} title="pharmaconnect" user={user} />
          </Switch>
          <Notifications>
            {(!isOnline || isSyncing || hasUnsyncedDocuments) && <OfflineNotificationContainer />}
            {isDemo && <DemoNotification />}
          </Notifications>
          <OfflineNotifierContainer />
          {/* Hide popup till new spec is out */}
          {(isKlinifyUser(user) || showInventoryMapping) && false && <MdlPopup user={user} />}
        </Layout>
      );
    }
    return <LoadingIndicator alignCenter style={{ marginTop: 'calc(50vh - 32px)' }} />;
  }
  return <LoginContainer />;
}

/**
 * Application component
 * @class Application
 * @extends {React.PureComponent<Props>}
 */
class Application extends React.Component<Props, State> {
  /**
   * Creates an instance of Application.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      hasUnsyncedDocuments: false,
    };
  }

  /**
   * Checks if the route changes after component update.
   * @param {Props} prevProps Prev props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (this.props.hasUnsyncedDocuments !== prevProps.hasUnsyncedDocuments) {
      this.props.hasUnsyncedDocuments.then((hasUnsyncedDocuments) => {
        this.setState({ hasUnsyncedDocuments });
      });
    }
  }

  /**
   * Called when the route changed.
   * @returns {void}
   */
  onRouteChanged() {
    triggerTip(this.props.config);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering Application');
    const {
      hasAuth, appStartupComplete, isOnline, isSyncing, user, config,
      appointmentRequested, patients, klinifyConfig, location,
    } = this.props;
    const patient = appointmentRequested && patients.find(p => p.get('_id') === appointmentRequested.get('patient_id'));
    return (
      <CSSTransitionGroup
        transitionName="c-app-transition"
        transitionAppear
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
      >
        {renderApplicationContent(
          hasAuth,
          appStartupComplete,
          isOnline,
          isSyncing,
          user,
          location.pathname,
          this.state.hasUnsyncedDocuments,
          (klinifyConfig && klinifyConfig.getIn(['showDemoBanner'], false)),
          (klinifyConfig && klinifyConfig.getIn(['inventory', 'showInventoryMappingPage'], false)),
          MEDADVISOR_CAMPAIGNS_ENABLED && (config && config.getIn(['optional_features_enabled', 'medadvisor_campaigns', 'enabled'], false)),
        )}
        {appointmentRequested &&
          <AppointmentFormModal
            updateModelsInState={this.props.updateModelsInState}
            buttonLabel={translate('view_request')}
            appointment={appointmentRequested}
            practitioners={this.props.practitioners}
            config={this.props.config}
            user={this.props.user}
            salesItems={this.props.salesItems}
            patient={patient}
            onSave={(args: AppointmentArgs) => {
              const updateNotificationKeyword = args.status &&
              args.status === 'booked' ? 'Confirming' : 'Cancelling';
              return onConfirmOrRejectRequest(patient, appointmentRequested, args)
                .then((withoutError) => {
                  this.props.showAppointmentRequested(null);
                  if (withoutError) {
                    createSuccessNotification(`Appointment successfully ${args.status}`);
                    return withoutError;
                  }
                  createErrorNotification(`Something went wrong in ${updateNotificationKeyword} the request`);
                  return withoutError;
                });
            }}
            onClose={() => {
              this.props.showAppointmentRequested(null);
            }}
            encounterFlowMap={this.props.encounterFlowMap}
            modalVisible
            appointments={this.props.appointments}
            encounters={this.props.encounters}
          />
        }
        { DOC_VALIDATION_ENABLED &&
          this.props.docsForRegeneration.size && <DocumentValidationModal
            id="doc_not_found_modal"
            footerButtonLabel="Save"
            docsForRegeneration={this.props.docsForRegeneration}
            show
          />
        }
      </CSSTransitionGroup>
    );
  }
}

export default withStorageListener(Application);
