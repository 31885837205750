import React from 'react';
import { List, Map } from 'immutable';

import StatelessModal from './../modals/statelessModal';
import PaymentForm from './../payments/paymentForm';
import translate from './../../utils/i18n';
import PatientPage from './../layout/patientPage';
import KeyValue from './../layout/keyValue';
import { sumAndFormatPrice, sum, convertNumberToPrice } from './../../utils/utils';
import PaymentHistoryTable from './paymentHistoryTable';
import { prettifyDate } from './../../utils/time';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Button from './../buttons/button';
import Header from './../header/header';

import type AllergyModel from '../../models/allergyModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type ReceivableModel from '../../models/receivableModel';
import type PaymentModel from '../../models/paymentModel';
import type PatientModel from './../../models/patientModel';
import type EncounterModel from './../../models/encounterModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import type { ActiveIngredient, SaveModel, SaveModels, User } from './../../types';
import DrugModel from './../../models/drugModel';

type Props = {
  allergies: List<AllergyModel>,
  patient: PatientModel,
  encounter: EncounterModel,
  coveragePayors: List<CoveragePayorModel>,
  receivables: List<ReceivableModel>,
  payments: List<PaymentModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  user: User,
  paymentTypes: List<PaymentTypeModel>,
  drugs: List<DrugModel>,
};

type State = {
  isModalVisible: boolean,
};

/**
 * A component that displays payments already made for a given bill, and allows new ones to be made.
 * @class BillPayments
 * @extends {React.Component<Props, State>}
 */
class BillPayments extends React.Component<Props, State> {
  /**
   * Creates an instance of PaymentHistoryTable.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      patient, coveragePayors, encounter, payments, receivables, saveModels, user, allergies,
      paymentTypes, saveModel,
    } = this.props;
    const paymentsTotal = sumAndFormatPrice(
      payments.filter(p => !p.isVoid()).map(p => p.get('amount', 0)),
    );
    const remainingTotal = sum(receivables.map(r => r.get('amount_due', 0)));
    const encounterTotal = sumAndFormatPrice(receivables.map(r => r.get('amount', 0)));
    // There isn't really a main receivable, but in 99% of cases there should only be a single non-void receivable.
    const mainReceivable = receivables.size > 0 ? receivables.get(0) : undefined;
    return (
      <PatientPage
        patient={patient}
        coveragePayors={coveragePayors}
        user={user}
        allergies={allergies}
        drugs={this.props.drugs}
      >
        <h1 data-public className="o-title">{translate('payments')}</h1>
        <div className="u-margin--standard">
          <KeyValue label={translate('encounter_date')} value={mainReceivable ? prettifyDate(mainReceivable.getDate()) : encounter.getDate()} />
          <KeyValue label={translate('encounter_fees')} value={encounterTotal} />
          <KeyValue label={translate('amount_already_paid')} value={paymentsTotal} />
          <KeyValue label={translate('amount_outstanding')} value={convertNumberToPrice(remainingTotal)} isDanger={remainingTotal > 0} />
        </div>
        <div className="o-card">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{translate('payment_history')}</h1>
            <span data-public className="o-card__header__right-aligned-text">
              <PermissionWrapper permissionsRequired={List([createPermission('payments', 'create')])} user={user}>
                <Button
                  dataPublic
                  className="o-button o-button--small"
                  disabled={!mainReceivable || remainingTotal <= 0}
                  onClick={() => this.setState({ isModalVisible: true })}
                >
                  {translate('make_payment')}
                </Button>
              </PermissionWrapper>
            </span>
          </Header>
          <PaymentHistoryTable
            payments={payments}
            receivable={mainReceivable}
            patient={patient}
            saveModels={saveModels}
            user={user}
          />
        </div>
        {
          mainReceivable &&
          <StatelessModal
            id="make-payment-modal"
            visible={this.state.isModalVisible}
            setVisible={(isModalVisible: boolean) => this.setState({ isModalVisible })}
            noButton
            explicitCloseOnly
            title={translate('make_payment')}
            style={{ height: '75vh' }}
            dataPublicHeader
          >

            <PaymentForm
              receivable={mainReceivable}
              patient={patient}
              onSave={() => this.setState({ isModalVisible: false })}
              saveModel={saveModel}
              saveModels={saveModels}
              paymentTypes={paymentTypes}
            />
          </StatelessModal>
        }
      </PatientPage>
    );
  }
}

export default BillPayments;
