import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import type { List } from 'immutable';

import SmsJob from './../components/patientOutreachCampaign/smsJob';
import { saveFactory, saveModelsFactory, saveModelsAPIFactory } from './../utils/redux';
import { saveCampaignJobStatusUpdate } from './../utils/api';
import AsyncFetch from './../components/asyncFetch';
import {
  setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfigValue,
  updateConfig, setCurrentDataViewsError, updateModels,
} from './../actions';
import { getSMSJobDataViews } from './../dataViews';

import type { Dispatch, State, Config, DataView } from './../types';
import type APIError from '../utils/apiError';
import type PatientCampaignModel from '../models/patientCampaignModel';
import type SMSJobModel from '../models/smsJobModel';
import type BaseModel from '../models/baseModel';

interface MatchParams {
  campaignID: string;
  smsJobID: string;
  campaignSetID: string,
  campaignSubType: string,
}
interface MatchProps extends RouteComponentProps<MatchParams> {
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({
  config, user, encounters,
  drugs, practitioners, currentDataViews, prescriptions,
  currentDataViewsModels, salesItems, patients,
  currentDataViewsError, isFetching, billItems, bills,
  campaignSetView,
}: State, { location, match }: MatchProps) => {
  const { campaignID, smsJobID, campaignSetID, campaignSubType } = match.params;
  const smsJob = currentDataViewsModels.find(sJ => sJ.get('type') === 'campaign_job' && !sJ.get('_deleted'));
  const encounter = smsJob && encounters.find(e => !e.get('_deleted') && smsJob.get('encounter_id') === e.get('_id'));
  const patient = smsJob && patients.find(p => !p.get('_deleted') && p.get('_id') === smsJob.get('patient_id'));
  return {
    smsJobID,
    smsJob,
    config,
    location,
    user,
    encounter,
    patient,
    encounters,
    drugs,
    prescriptions,
    practitioners,
    containerDataViews: getSMSJobDataViews(campaignID, smsJobID, campaignSetID),
    currentDataViews,
    patientCampaign: currentDataViewsModels.find(m => m.get('type') === 'patient_campaign' && !m.get('_deleted') && m.get('_id') === campaignID),
    salesItems,
    billItems,
    bills,
    currentDataViewsError,
    isFetching,
    showLoadingIndicator: true,
    selectedCampaignSet: currentDataViewsModels.find(set => set.get('_id') === campaignSetID),
    campaignSubType,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<PatientCampaignModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateCampaignJobStatus: (campaignJob: SMSJobModel) => (
    saveModelsAPIFactory(dispatch)(() => saveCampaignJobStatusUpdate(campaignJob, dispatch))
      .then((job) => {
        if (!job.unavailable && !job.error && Array.isArray(job)) {
          return job[0];
        }
        return job;
      })
  ),
  updateConfigValue,
  onAsyncFetchComplete: (models: List<BaseModel>) => dispatch(updateModels(
    models.filter(m => ['bill', 'bill_item', 'prescription', 'encounter', 'patient'].includes(m.get('type'))).toArray(),
  )),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const SmsJobContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(SmsJob));

export default SmsJobContainer;
