import { getBaseApiUrl, handleResponseError } from './utils';
import { handleApiError } from './response';
import { offlinePromptHandler, getIsOnline } from './offline';
import fetch from './fetch';
/* eslint-disable import/prefer-default-export */

/**
 * Get Patient List
 * @param {string} queryStrings The Query string
 * @param {AbortSignal} signal The abort controller signal
 * @returns {Array} Patient list
 */
export function getPatientsList(queryStrings?: string, signal?: AbortSignal) {
  if (getIsOnline()) {
    return fetch(new Request(`${getBaseApiUrl()}/analytics/patients/search${queryStrings ? `?${queryStrings}` : ''}`), {
      method: 'GET',
      credentials: 'same-origin',
      signal,
    })
      .then((response: Response) => {
        if (response.ok) {
          return response.json()
            .then(json => Promise.resolve({ data: json.rows, status: response.status }));
        }
        handleApiError({ error: resJson, message: 'Please try again with some more filters for better patient search results.' });
        return response.json().then(() =>
          Promise.resolve({
            data: [],
            status: response.status,
          }));
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          return Promise.reject({ name: error.name, message: 'Aborting request' });
        }
        handleResponseError(error);
        return error.json().then(() =>
          Promise.resolve({
            data: [],
            status: error.status,
          }));
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    data: [],
    status: 0,
  });
}