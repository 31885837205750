import React from 'react';
import { List, Map } from 'immutable';
import type { Node } from 'react';

import PatientPage from './../layout/patientPage';
import Table from './../table/table';
import translate from './../../utils/i18n';
import { renderDate } from './../../utils/tables';
import { createSuccessNotification } from './../../utils/notifications';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { printDocument } from './../../utils/print';
import { getConfirmation } from './../../utils/utils';
import EditHistory from './../editHistory/editHistory';
import type { User, SaveModel, ActiveIngredient } from './../../types';
import type DocumentDataModel from './../../models/documentDataModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PatientModel from './../../models/patientModel';
import type AllergyModel from './../../models/allergyModel';
import type DocumentTemplateModel from '../../models/documentTemplateModel';
import Button from './../buttons/button';
import { debugPrint } from '../../utils/logging';
import DrugModel from './../../models/drugModel';

type Props = {
  allergies: List<AllergyModel>,
  saveModel: SaveModel,
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  documentData: List<DocumentDataModel>,
  documentTemplates: List<DocumentTemplateModel>,
  drugs: List<DrugModel>,
};

const columns = [
  { accessor: 'date', Header: translate('date'), Cell: renderDate },
  { accessor: 'documentName', Header: translate('document_name') },
  { accessor: 'download', Header: '', sortable: false, show: true, width: 100 },
  { accessor: 'print', Header: '', sortable: false, show: true, width: 100 },
  { accessor: 'viewHistory', Header: '', sortable: false, show: true, width: 100 },
  { accessor: 'delete', Header: '', sortable: false, width: 100, show: true },
];

type Row = {
  date: number,
  documentName: string,
  download: Node,
  viewHistory: Node,
  delete: Node,
};

/**
 * Called when delete is clicked on an item. Updates its state to be hidden.
 * @param {DocumentDataModel} item The item to delete
 * @param {SaveModel} saveModel saveModel function
 * @returns {void}
 */
function onDeleteClicked(item: DocumentDataModel, saveModel: SaveModel) {
  if (item) {
    getConfirmation(translate('confirm_document_deletion'))
      .then(
        () => {
          saveModel(item.set({ is_void: true }))
            .then(() => {
              createSuccessNotification(translate('document_deleted'));
            });
        },
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {DocumentDataModel} item An item for the delete button
 * @param {SaveModel} saveModel saveModel function
 * @param {User} user User.
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(item: DocumentDataModel, saveModel: SaveModel, user: User) {
  return (
    <PermissionWrapper permissionsRequired={List([createPermission('document_templates', 'delete')])} user={user}>
      <Button
        className="o-text-button o-text-button--danger"
        onClick={() => onDeleteClicked(item, saveModel)}
        dataPublic
      >
        {translate('delete')}
      </Button>
    </PermissionWrapper>
  );
}

/**
 * A component that displays a table containing all documents created for the current patient.
 * @class Documents
 * @extends {React.PureComponent<Props>}
 */
class Documents extends React.PureComponent<Props> {
  /**
   * Gets the table row for the given DocumentDataModel
   * @param {DocumentDataModel} documentData A documentDataModel
   * @returns {Row}
   */
  getRow(documentData: DocumentDataModel): Row {
    const documentTemplate = this.props.documentTemplates
      .find(d => d.get('_id') === documentData.get('document_template_id'));
    if (!documentTemplate) {
      debugPrint(`Could not find DocumentTemplate referenced by DocumentData: ${documentData.get('_id')}`, 'error');
      throw new Error('The DocumentTemplateModel required by DocumentData could not be found.');
    }
    return {
      date: documentData.getTimestamp(),
      documentName: documentData.getName(),
      viewHistory: <EditHistory
        model={documentData}
        buttonLabel={translate('view_history')}
        buttonClass="o-text-button o-text-button--contextual"
      />,
      download: (
        <Button
          className="o-text-button o-text-button--contextual"
          onClick={() => documentData.downloadDocument(documentTemplate.get('asset_id'))}
          dataPublic
        >
          {translate('download')}
        </Button>
      ),
      print: (
        <Button
          className="o-text-button o-text-button--contextual"
          onClick={() => printDocument(documentData, documentTemplate)}
          dataPublic
        >
          {translate('print')}
        </Button>
      ),
      delete: getDeleteButton(documentData, this.props.saveModel, this.props.user),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { patient, allergies, coveragePayors, user, documentData } = this.props;
    return (
      <PatientPage
        patient={patient}
        coveragePayors={coveragePayors}
        allergies={allergies}
        user={user}
        drugs={this.props.drugs}
      >
        <div className="o-card">
          <Table
            columns={columns}
            data={documentData.map(d => this.getRow(d)).toArray()}
            noDataText={translate('no_documents_created')}
            defaultSorted={[{ id: 'date', desc: false }]}
          />
        </div>
      </PatientPage>
    );
  }
}

export default Documents;
