import React, { useEffect, useMemo, useState } from 'react';
import glamorous from 'glamorous';

import type { SortingRule } from 'react-table';
import type { Column, Config, SelectOption, User } from '../../types';

import translate from '../../utils/i18n';
import SortableList from '../layout/sortableList';
import Select from '../../components/inputs/select';
import { colours, margins } from '../../utils/css';
import Button from '../buttons/button';
import { getOverviewColumns } from './columns';
import ModalFooter from '../modals/modalFooter';
import SaveButton from '../buttons/saveButton';
import StatelessModal from '../modals/statelessModal';
import { pluralizeWord } from '../../utils/utils';
import FormError from '../formError';
import { compareByAlphabeticalOrder } from '../../utils/comparators';

const ItemRow = glamorous.div({
  alignItems: 'center',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '150px minmax(150px,1fr) 150px minmax(150px,1fr) 50px',
});

const CloseIcon = glamorous.i({
  color: colours.grey3,
  transition: 'color .2s eas-in',
  cursor: 'pointer',
  '&:hover': {
    color: colours.grey5,
  },
});

type Props = {
  sorted: Array<SortingRule>;
  onSave: (sortingModel: Array<SortingRule>) => void;
  config: Config;
  user: User;
};

/**
 * Maps column to select option
 * @param {Array<Column>} columns Table column array
 * @returns {Array<SelectOption>}
 */
const getColumnOptions = (columns: Array<Column> = []): Array<SelectOption> =>
  columns.map(c => ({ label: c.Header, value: c.accessor }))
    .sort((a, b) => compareByAlphabeticalOrder(a.label, b.label));

/**
 * Renders ModalContent for sort table
 * @param {Props} props Component props
 * @returns {React.FC}
 */
const TableSorter = (props: Props) => {
  const overviewColumnMap = useMemo(() => getOverviewColumns(props.config, props.user), []);
  const [isVisible, setIsVisible] = useState(false);
  const [columns, setColumns] = useState<Array<Column>>(
    overviewColumnMap.valueSeq().toArray(),
  );
  const [sortingRule, setSortingRule] = useState(props.sorted);
  const [formError, setFormError] = useState('');

  useEffect(() => {
    setSortingRule(props.sorted);
    setColumns(
      overviewColumnMap.valueSeq().toArray()
        .filter(c => !sortingRule.some(s => s.id === c.accessor)),
    );
    return () => {
      setFormError('');
    };
  }, [isVisible]);

  /**
   * Handles column selection
   * @param {string} columnName selected column key
   * @param {number} index sortingmodel index
   * @returns {void}
   */
  const handleColumnSelect = (columnName: string, index: number) => {
    const sortedBy = [...sortingRule];
    sortedBy[index] = { id: columnName, desc: sortedBy[index]?.desc };
    setSortingRule(sortedBy);
    setColumns(columns.filter(c => c.accessor !== columnName));
  };

  /**
   * Handles sort selection
   * @param {boolean} value is asc or desc
   * @param {number} index sortingmodel index
   * @returns {void}
   */
  const handleSortSelect = (value: string, index: number) => {
    const sortedBy = [...sortingRule];
    sortedBy[index] = { id: sortedBy[index]?.id, desc: value === 'desc' };
    setSortingRule(sortedBy);
  };

  /**
   * sort handler to be passed to sortable list component, rearranges array of cards as per latest sort.
   * @param {MapValue} funcArg object with old and new index.
   * @returns {void}
   */
  const onSort = (funcArg: { oldIndex: number; newIndex: number }): void => {
    const sorted = sortingRule.slice();
    sorted.splice(funcArg.newIndex, 0, sorted.splice(funcArg.oldIndex, 1)[0]);
    setSortingRule(sorted);
  };

  /**
   * Handles rule deletion and adds it to columns if not exists
   * @param {string} columnName Removed column from rule
   * @param {number} indexToRemove index to remove
   * @returns {void}
   */
  const removeSortBy = (columnName: string, indexToRemove: number) => {
    const columnToAdd = overviewColumnMap.get(columnName);
    if (columnToAdd) {
      const updatedColumns = columns.concat(columnToAdd);
      setColumns(updatedColumns);
    }
    const newRules = sortingRule.slice();
    newRules.splice(indexToRemove, 1);
    setSortingRule(newRules);
  };

  /**
   * @returns {void}
   */
  const handleClose = () => {
    if (!formError && JSON.stringify(props.sorted) !== JSON.stringify(sortingRule)) {
      setFormError(translate('unsaved_changes_error'));
    } else {
      setIsVisible(false);
    }
  };

  /**
   * Saves the selected sorting rule and saves it to local storage
   * @returns {void}
   */
  const saveSortRules = () => {
    const sanitisedRules = sortingRule.filter(s => s.id);
    if (sortingRule.length !== sanitisedRules.length) {
      return setFormError(translate('fill_required_fields'));
    }
    props.onSave(sanitisedRules);
    return setIsVisible(false);
  };

  const columnItems = sortingRule.map((col, idx) => (
    <ItemRow>
      <span>{translate(idx === 0 ? 'sort_by' : 'then_by')}</span>
      <Select
        id={`column-name-select-${idx}`}
        options={getColumnOptions(columns)}
        onValueChanged={newVal => handleColumnSelect(newVal, idx)}
        value={
          overviewColumnMap.get(col.id) && {
            label: overviewColumnMap.get(col.id)?.Header,
            value: col.id,
          }
        }
        menuPortalTarget={document.body}
        style={{ marginBottom: 0 }}
        clearable={false}
        required
      />
      <span className="u-text-align-center">{translate('from')}</span>
      <Select
        id={`sort-desc-select-${idx}`}
        options={[{ label: 'Asc', value: 'asc' }, { label: 'Dsc', value: 'desc' }]}
        onValueChanged={newVal => handleSortSelect(newVal, idx)}
        value={col.desc ? 'desc' : 'asc'}
        menuPortalTarget={document.body}
        style={{ marginBottom: 0 }}
        clearable={false}
      />
      <CloseIcon
        onClick={() => removeSortBy(col.id, idx)}
        className="u-padding--half-ws fa fa-close"
      />
    </ItemRow>
  ));

  return (
    <StatelessModal
      id="sorter"
      buttonLabel={(
        <span className="u-flex-row u-flex-align-items-center">
          <img src="static/images/icon_sort.svg" className="u-margin-right--half-ws" alt="" />
          {`${translate('sort')} ${sortingRule.length ? `(${translate('sorted_by')} ${pluralizeWord(translate('column'), sortingRule.length)})` : ''}`}
        </span>
      )}
      buttonClass="o-button o-button--info o-button--small"
      visible={isVisible}
      onClose={handleClose}
      setVisible={(visible) => {
        if (visible) {
          setIsVisible(visible);
        } else {
          handleClose();
        }
      }}
      explicitCloseOnly
      title={translate('sort')}
      dataPublicHeader
    >
      <div className="u-margin--standard">
        {formError &&
        <FormError isSticky>{formError}</FormError>}
        <SortableList
          itemStyles={{ backgroundColor: colours.grey1, padding: '8px 0', marginBottom: margins.standardMarginBottom }}
          items={columnItems}
          onSortEnd={onSort}
          itemZIndex={100}
        />
        <Button
          className="o-button o-button--small u-full-width u-margin-top--standard"
          onClick={() =>
            setSortingRule(sortingRule.concat({ id: '', desc: false }))
          }
        >
          {translate('add_new_field_to_sortby')}
        </Button>
      </div>
      <ModalFooter>
        <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
          <SaveButton
            className="o-button--small o-button--padded"
            onClick={saveSortRules}
          />
        </div>
      </ModalFooter>
    </StatelessModal>
  );
};

export default TableSorter;
