import React from 'react';

import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import { isTouchDevice } from './../../utils/utils';
import TextArea from './../inputs/textarea';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';

import type PatientModel from './../../models/patientModel';
import type { SaveModel } from './../../types';

type Props = {
  patient: PatientModel,
  saveModel: SaveModel,
};

type State = {
  isSaving: boolean,
  modalVisible: boolean,
  errorMessage?: string,
  patientAlert: string,
}

/**
 * A component for adding and editing Allergies.
 * @class AddEditPatientAlert
 * @extends {React.Component}
 */
class AddEditPatientAlert extends React.Component<Props, State> {
  defaultState = {
    isSaving: false,
    modalVisible: false,
    errorMessage: undefined,
  };

  /**
   * Creates an instance of AddEditPatientAlert.
   * @param {Props} props intial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      patientAlert: props.patient.get('alert', ''),
      ...this.defaultState,
    };
  }

  /**
   * Saves the patient alert
   * @returns {void}
   */
  handleSave() {
    this.setState({ isSaving: true });
    this.props.saveModel(this.props.patient.set({ alert: this.state.patientAlert }))
      .then(() => {
        createSuccessNotification(translate('patient_alert_saved'));
      }).then(() => {
        this.setState({ isSaving: false });
        this.setState({ modalVisible: false });
      })
  }

  /**
   * Hides the modal when cancel is clicked.
   * @returns {void}
   */
  handleCancel() {
    this.setState(Object.assign(this.state, {
      patientAlert: this.props.patient.get('alert', ''),
      ...this.defaultState,
    }));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <StatelessModal
        id="add-edit-patient-alert"
        buttonLabel={translate('edit_details')}
        buttonClass="o-button o-button--padded o-button--small u-width-180"
        title={translate('patient_alert')}
        setVisible={(isVisible) => {
          this.setState({ modalVisible: isVisible });
        }}
        visible={this.state.modalVisible}
        explicitCloseOnly
        onClose={() => this.handleCancel()}
        dataPublicHeader
      >
        { this.state.errorMessage &&
          <div className="u-margin--standard">
            <FormError containerElementID="add-edit-patient-alert-form">{this.state.errorMessage}</FormError>
          </div>
        }
        <section className="o-form" id="add-edit-patient-alert-form">
          <TextArea
            id="patient-alert"
            onValueChanged={value => this.setState({ patientAlert: value })}
            label={translate('patient_alert_details')}
            value={this.state.patientAlert}
            disabled={this.state.isSaving}
            autoFocus={!isTouchDevice()}
          />
        </section>
        <ModalFooter>
          <SaveButton
            dataPublic
            onClick={() => this.handleSave()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default AddEditPatientAlert;
