import React from 'react';

import Notification from './notification';
import translate from './../../utils/i18n';

/**
   * A component that shows a banner declaring the app to be in demo mode. Set from Clinic Settings.
   * @namespace DemoNotification
   * @returns {React.Component} The Layout component.
*/
const DemoNotification = () => (
  <Notification>
    <p>
      {translate('demo_banner_text_1')}
      <br />
      {translate('demo_banner_text_2')}
    </p>
  </Notification>
);

export default DemoNotification;
