import React from 'react';

import { fetchDataAsset, isImageFile, getBaseApiUrl } from './../../utils/utils';
import translate from './../../utils/i18n';
import ImageLoader from './../imageLoader';
import LoadingIndicator from './../loadingIndicator';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import StatelessModal from './../modals/statelessModal';
import PDFLoader from './../pdfLoader';
import Button from './../buttons/button';

import type { AssetObject } from './../../types';

type Props = {
  isProcedureResultModalVisible: boolean,
  assets: Array<AssetObject>,
  onClose: () => void,
  onClickedUpload: () => void,
  noUploadButton: boolean,
};

type State = {
  pdfDataContents: Array<{ content: string, name?: string }>,
  isProcessing: boolean,
};

/**
 * A component that display lab result
 * @namespace LabResult
 */
class LabResult extends React.Component<Props, State> {
  static defaultProps = {
    noUploadButton: false,
  };

  /**
   * Creates an instance of LabResult.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isProcessing: false,
      pdfDataContents: [],
    };
  }

  /**
   * Called after component mounted
   * @returns {void}
   */
  componentDidMount() {
    const { assets } = this.props;
    if (assets && assets.some(asset => asset.datatype === 'application/pdf')) {
      this.getAssetData(assets);
    }
  }

  /**
   * Returns asset data eg. base64 content
   * @param {Array<AssetObject>} assets The procedure result asset
   * @returns {void}
   */
  getAssetData(assets: Array<AssetObject>) {
    this.setState({ isProcessing: true });
    const dataContents = [];
    assets.map((asset) => {
      if (asset.datatype === 'application/pdf') {
        fetchDataAsset(asset.id, asset.datatype)
          .then((content) => {
            dataContents.push({ name: asset.name, content });
          });
      }
      return asset;
    });
    this.setState({ pdfDataContents: dataContents });
    setTimeout(() => {
      this.setState({ isProcessing: false });
    }, 2000);
  }

  /**
   * Renders assets component
   * @param {Array<AssetObject>} assets The procedure result assets
   * @returns {React.Component}
   */
  renderAssets(assets: Array<AssetObject>) {
    if (assets && !assets.length) {
      return <div>{translate('no_assets_attached')}</div>;
    }
    return [
      assets
        .filter(asset => isImageFile(asset.datatype))
        .map(asset => (
          <div style={{ marginBottom: 40 }}>
            <div>{asset.name}</div>
            <ImageLoader
              imageSrc={`${getBaseApiUrl()}/asset/${asset.id}`}
              imageClassName="c-casenote__asset__img"
              imageAlt={asset.id}
            />
          </div>
        )),
      this.state.pdfDataContents.map(pdf =>
        <div>
          <div>{pdf.name}</div>
          <PDFLoader urlData={pdf.content} />
        </div>),
      assets
        .filter(asset => !isImageFile(asset.datatype) && asset.datatype !== 'application/pdf')
        .map(asset => <div>{translate('unable_to_view_asset', { assetName: asset.name })}</div>),
    ];
  }

  /**
   * Renders the component.
   * @returns {React.PureComponent} The rendered component.
   */
  render() {
    const { isProcedureResultModalVisible, onClose, assets, noUploadButton } = this.props;
    return (
      <StatelessModal
        id="procedure_result_view_asset"
        buttonLabel=""
        buttonClass="o-text-button"
        title={translate('procedure_result')}
        className="js-modal"
        visible={isProcedureResultModalVisible}
        setVisible={() => {}}
        explicitCloseOnly
        onClose={() => onClose()}
        dataPublicHeader
      >
        <div className="u-margin--standard" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          { this.state.isProcessing ? <LoadingIndicator /> : this.renderAssets(assets) }
        </div>
        <ModalFooter>
          {!noUploadButton &&
            <Button
              type="button"
              className="o-button o-button--small u-margin-right--half-ws"
              onClick={() => this.props.onClickedUpload()}
              dataPublic
            >
              {translate('upload_result')}
            </Button>
          }
          <SaveButton
            dataPublic
            onClick={() => onClose()}
            label={translate('close')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default LabResult;
