import BaseModel from './baseModel';
import { getDocumentTemplate, setDocumentTemplateData, downloadDocument } from './../utils/documentTemplates';


import type { MapValue } from './../types';

type Attributes = {
  _id: string,
  practitioner_id: string,
  type: 'document_data',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  document_template_id: string,
  title: string,
  file_name: string,
  patient_id: string,
  encounter_id: string,
  data: { [key: string]: MapValue }, // Any valid json.
  is_void: boolean,
  asset_id: string,
};

/**
 * DocumentDataModel
 * https://klinify.atlassian.net/wiki/spaces/KLIN/pages/252215304/DocumentData
 * @namespace DocumentDataModel
 */
class DocumentDataModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'document_data';
  }

  /**
   * Gets the user friendly name of the DocumentData
   * @returns {string}
   */
  getName(): string {
    if (this.has('title')) {
      return this.get('title');
    }
    return this.has('file_name') ? this.get('file_name') : this.get('_id');
  }

  /**
   * Returns true if document data has been voided.
   * @returns {boolean}
   */
  isVoid(): boolean {
    return this.get('is_void', false);
  }

  /**
   * Downloads the filled Word document for this DocumentDataModel
   * @param {string} assetId The document template asset id.
   * @returns {Promise<void>}
   */
  downloadDocument(assetId: string): Promise<void> {
    return getDocumentTemplate(assetId)
      .then(doc => setDocumentTemplateData(doc, this.get('data')))
      .then(doc => downloadDocument(doc, this.get('file_name')));
  }

  /**
   * Returns the timestamp to use for this DocumentData. Tries timestamp field then falls back to
   * created_by.timestamp
   * @returns {number}
   */
  getTimestamp(): number {
    return this.has('timestamp') ? this.get('timestamp') : this.getCreatedTime();
  }
}

export default DocumentDataModel;
