import { connect } from 'react-redux';

import Labs from './../components/labs/labs';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State } from './../types';


/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ providers, config, user }: State) => ({
  providers: providers.filter(p => p.isActive()),
  config,
  user,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const LabsContainer = connect(mapStateToProps, mapDispatchToProps)(Labs);

export default LabsContainer;
