import React, { useEffect, useState } from 'react';
import glamorous from 'glamorous';
import csv from 'csvtojson';
import 'whatwg-fetch';
import Banner from '../components/outbreak/banner';
import translate from './../utils/i18n';
import { colours, borderRadius, wsUnit, scaledH3 } from './../utils/css';
import Input from './../components/inputs/input';
import LoadingIndicator from './../../src/components/loadingIndicator';

const labsURL =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vRgal9jf_vhzC3WKORTzZvIvXLJ_ZDO-eMN_WhjCDoDpVjYRKtLyUvCCeHi9R-4W9xSKfAXdx_oDBZ_/pub?gid=0&single=true&output=csv';

const Container = glamorous.div({
  padding: `${wsUnit} calc(1.5 * ${wsUnit})`,
  height: `calc(100vh - 2 * ${wsUnit})`,
});

const FetchError = glamorous.div({
  marginTop: wsUnit,
  border: `1px solid ${colours.red}`,
  padding: `calc(0.5 * ${wsUnit})`,
  borderRadius,
});

const LabWrapper = glamorous.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  gridColumnGap: '1rem',
  gridRowGap: '1rem',
  borderRadius,
});

const DisclaimerWrapper = glamorous.p({
  marginTop: '1rem',
  marginBottom: '1rem',
});

const LabItem = glamorous.div({
  backgroundColor: 'white',
  padding: '1rem',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
});

const LabName = glamorous.h5({
  fontSize: scaledH3,
  marginBottom: '0.8rem',
  fontWeight: 'bold',
});

const LabRow = glamorous.div({
  marginBottom: '0.6rem',
});

const LabIcon = glamorous.img({
  height: 16,
  width: 16,
  marginRight: '0.25rem',
});

const SearchBarWrapper = glamorous.div({
  display: 'grid',
  gridTemplateColumns: 'auto 200px',
});

const ClearButton = glamorous.button({
  marginTop: 6,
});

/**
 * Renders a lab card
 * @param {object} lab The lab data to display.
 * @returns {React.Component} A rendered lab component.
 */
const renderLab = lab => (
  // eslint-disable-next-line react/jsx-filename-extension
  <LabItem key={lab.name + lab.address + lab.phone_1}>
    <LabName>{lab.name}</LabName>
    <LabRow>
      <LabIcon src="/static/images/icon_address_black.png" alt="address" />{' '}
      {lab.address}
    </LabRow>
    {
      lab.contact_name_1 ?
        <LabRow>
          <LabIcon src="/static/images/icon_contact_black.png" alt="contact" />{' '}
          {lab.contact_name_1}
        </LabRow>
        : null
    }
    <LabRow>
      <LabIcon src="/static/images/icon_phone_black.png" alt="phone" />{' '}
      {lab.phone_1}
    </LabRow>
  </LabItem>
);

/**
 * A container for showing outbreak guidelines
 * Skipping the redux integration as this container doesn't need a state management
 * @class OutbreakLabsContainer
 * @extends {React.Component<Props, State>}
 */
const OutbreakLabsContainer = () => {
  const [labs, setLabs] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    fetch(labsURL)
      .then(async (result) => {
        const text = await result.text();
        csv().fromString(text).then((rows) => {
          setLabs(rows);
          setError(false);
        });
      })
      .catch(() => {
        setLabs([]);
        setError(true);
      })
      .then(() => setIsFetching(false));
  }, []);

  let displayLabs = labs;
  if (searchText !== '') {
    displayLabs = labs
      .filter(lab =>
        lab.search_tags.toLowerCase()
          .indexOf(searchText.toString().toLowerCase())
        >= 0);
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Container className="c-markdown o-scrollable-container">
      <Banner />
      {isFetching ?
        <LoadingIndicator alignCenter />
        :
        <>
          <SearchBarWrapper>
            <Input
              type="text"
              id="search"
              placeholder={translate('outbreak_labs_search_placeholder')}
              value={searchText}
              onValueChanged={value => setSearchText(value)}
            />
            <ClearButton
              disabled={searchText === ''}
              className="o-button"
              onClick={() => setSearchText('')}
            >
              Clear
            </ClearButton>
          </SearchBarWrapper>
          {isError ? (
            <FetchError>{translate('outbreak_labs_fetch_error')}</FetchError>
          ) : null}
          <h1 data-public>{translate('outbreak_labs_hotlines')}</h1>
          <LabWrapper>
            {displayLabs.filter(lab => lab.type === 'Hotline').map(lab => renderLab(lab))}
          </LabWrapper>
          <hr />
          <h1 data-public>{translate('outbreak_labs_list')}</h1>
          <DisclaimerWrapper>{translate('outbreaks_labs_disclaimer')}</DisclaimerWrapper>
          <LabWrapper>
            {displayLabs.filter(lab => lab.type === 'Lab').map(lab => renderLab(lab))}
          </LabWrapper>
          <hr />
          <h1 data-public>{translate('outbreak_labs_screening')}</h1>
          <LabWrapper>
            {displayLabs.filter(lab => lab.type === 'Screening Hospital').map(lab => renderLab(lab))}
          </LabWrapper>
          <hr />
          <h1 data-public>{translate('outbreak_labs_screening_admitting')}</h1>
          <LabWrapper>
            {displayLabs.filter(lab => lab.type === 'Screening & Admitting Hospital').map(lab => renderLab(lab))}
          </LabWrapper>
        </>
      }
    </Container>
  );
};

export default OutbreakLabsContainer;
