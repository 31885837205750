import React from 'react';
import glamorous from 'glamorous';
import { colours } from './../../utils/css';

const Header = glamorous.header({
  backgroundColor: `${colours.maroon}`,
  width: '100%',
  padding: '0.5em',
  textAlign: 'left',
});

const Logo = glamorous.div({
  height: '60px',
  width: '100px',
  margin: '0 0 0 5%',
  backgroundImage: 'url(././../static/images/klinify_logo_text.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '80px',
});

/**
 * Login page header component.
 * @return {string} - HTML markup for the component
 */
const LoginHeader = () => (
  <Header>
    <Logo />
  </Header>
);

export default LoginHeader;
