import React, { Fragment, Component } from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import Select from './../inputs/select';
import MultiSelect from './../inputs/multiSelect';
import translate from './../../utils/i18n';
import CloseButton from './../buttons/closeButton';
import { border } from './../../utils/css';

import type { Category } from './panelCategoryForm';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { SelectOption, SelectOpts, Config } from './../../types';

const InputContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyContent: 'left',
  ':not:last-of-type': {
    borderBottom: border,
  },
  '& button': {
    justifySelf: 'left',
  },
});

type Props = {
  config: Config,
  index: number,
  unusedCoveragePayors: List<CoveragePayorModel>,
  coveragePayors: List<CoveragePayorModel>,
  value: Category,
  setValue: (newValue: Category) => void,
  removeValue: () => void,
}

/**
 * A component for a single category of a drug.
 * @class PanelCategoryInput
 * @extends {React.Component<Props, State>}
 */
class PanelCategoryInput extends Component<Props, {}> {
  /**
   * Updates the category.
   * @param {string} category The category set in the input.
   * @return {void}
   */
  updateCategory(category: string) {
    this.props.setValue(this.props.value.set('category', category));
  }

  /**
   * Maps a give coveragePayor ID to a Select value (i.e. gives it a label).
   * @param {string} id A coveragePayor ID.
   * @returns {SelectOption}
   */
  mapIdToSelectValue(id: string): SelectOption {
    const payor = this.props.coveragePayors.find(cp => cp.get('_id') === id);
    return {
      value: id,
      label: payor ? payor.get('name') : id,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <InputContainer>
          <Select
            id={`panelCategory-${this.props.index}`}
            label={translate('category_x', { x: this.props.index + 1 })}
            options={this.props.config.getIn(['panel_categories', 'options'], List()).toArray()}
            value={this.props.value.get('category')}
            onValueChanged={category => this.updateCategory(category)}
          />
          <CloseButton dataPublic onClick={() => this.props.removeValue()} />
          <MultiSelect
            id={`panelDrugPanels-${this.props.index}`}
            style={{ gridColumn: '1/4' }}
            label={translate('panels_assigned_to_this_category')}
            options={this.props.unusedCoveragePayors.map(payor => ({
              value: typeof payor.get('_id') === 'string' ? payor.get('_id') : '',
              label: typeof payor.get('name') === 'string' ? payor.get('name') : '',
            })).toArray()}
            value={
              this.props.value.get('coveragePayorIds', List())
                .map(value => this.mapIdToSelectValue(value))
                .toArray()
            }
            onChange={
              (newValues: SelectOpts) =>
                this.props.setValue(this.props.value.set('coveragePayorIds', List(newValues.map(v => v.value))))
            }
          />
        </InputContainer>
      </Fragment>
    );
  }
}

export default PanelCategoryInput;
