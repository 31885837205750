import React, { useMemo, useState } from 'react';
import { List, Map } from 'immutable';
import glamorous, { Div, I } from 'glamorous';
import moment, { Moment } from 'moment';
import { isEqual, startCase } from 'lodash';
import memoizeOne from 'memoize-one';

import SortableList from '../layout/sortableList';
import Select from '../../components/inputs/select';
import Button from '../buttons/button';
import ModalFooter from '../modals/modalFooter';
import SaveButton from '../buttons/saveButton';
import StatelessModal from '../modals/statelessModal';
import DateInput from './../../components/inputs/dateInput';
import TimeInput from './../../components/inputs/timeInput';
import Input from './../inputs/input';
import FormError from '../formError';

import translate from '../../utils/i18n';
import { pluralizeWord } from '../../utils/utils';
import { getOverviewColumns } from './columns';
import { colours, margins, wsUnit } from '../../utils/css';
import { compareByAlphabeticalOrder } from '../../utils/comparators';
import { mapStringToOption } from '../../utils/inputs';

import type PractitionerModel from '../../models/practitionerModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type PaymentTypeModel from '../../models/paymentTypeModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type { Column, Config, SelectOption, User } from '../../types';
import type BaseModel from '../../models/baseModel';
import type PaymentModel from '../../models/paymentModel';

const ItemRow = glamorous.div({
  alignItems: 'center',
  width: '100%',
  display: 'grid',
  gridTemplateColumns:
    '120px minmax(150px,1fr) minmax(150px,1fr) minmax(150px,1fr) 50px',
});

const CloseIcon = glamorous.i({
  color: colours.grey3,
  transition: 'color .2s eas-in',
  cursor: 'pointer',
  '&:hover': {
    color: colours.grey5,
  },
});

/**
 * Returns select options with value and label as model's name attr
 * @param {List<T>} models List of any model with name attr
 * @returns {Array<SelectOption>}
 */
const getSelectOptionsFromModelName = <T extends BaseModel>(models: List<T>) => models
  .map(model => ({ label: model.get('name'), value: model.get('name') }))
  .sort((a, b) => compareByAlphabeticalOrder(a.label, b.label))
  .toArray();

type FilterDetails = {
  [accessorName: string] : {
    // Used by external filter component (in Overview Tables)
    filterType: 'text' | 'select' | 'date' | 'time' | 'number',
    filterOptions?: Array<SelectOption>,
  },
}

/**
 * Returns an Object that maps column name to filter type and select options (if filter type is 'select')
 * @param {Config} config Clinic config
 * @param {List<PractitionerModel>} practitioners Practitioners list
 * @param {List<EncounterFlowModel>} encounterFlows Encounter flow models
 * @param {List<EncounterStageModel>} encounterStages Encounter stage models
 * @param {List<PaymentTypeModel>} paymentTypes Payment models
 * @param {List<CoveragePayorModel>} coveragePayors Coverage payors
 * @returns {FilterDetails}
 */
const getAccessorToFilterTypeMap = memoizeOne((
  config: Config,
  practitioners: List<PractitionerModel>,
  encounterFlows: List<EncounterFlowModel>,
  encounterStages: List<EncounterStageModel>,
  paymentTypes: List<PaymentTypeModel>,
  coveragePayors: List<CoveragePayorModel>,
): FilterDetails => {
  const practionerOptions = getSelectOptionsFromModelName(practitioners);
  const flowOptions = getSelectOptionsFromModelName(encounterFlows)
    .concat({ label: translate('custom_flow'), value: translate('custom_flow') });
  const stageOptions = getSelectOptionsFromModelName(encounterStages);
  const genderOptions = config.getIn(['patient', 'options', 'sex'], List(['male', 'female']))
    .toArray()
    .map((value: string) => ({ label: value, value }));
  const paymentTypeOptions = getSelectOptionsFromModelName(paymentTypes);
  const panelNameOptions = getSelectOptionsFromModelName(coveragePayors.filter(c => c.isVisible()));
  const locationOptions = config.getIn(['clinic', 'locations'], List())
    .map((location: string) => ({ value: location, label: location }))
    .toArray();
  const panelOrCashOptions = mapStringToOption([translate('panel'), translate('cash')]);
  return {
    arrival_time: { filterType: 'time' },
    arrival_date: { filterType: 'date' },
    completed_time: { filterType: 'time' },
    completed_date: { filterType: 'date' },
    date: { filterType: 'date' },
    doctor: { filterType: 'select', filterOptions: practionerOptions },
    encounter_flow: { filterType: 'select', filterOptions: flowOptions },
    encounter_stage: { filterType: 'select', filterOptions: stageOptions },
    location: { filterType: 'select', filterOptions: locationOptions },
    payment_type: { filterType: 'select', filterOptions: paymentTypeOptions },
    panel_name: { filterType: 'select', filterOptions: panelNameOptions },
    q_no: { filterType: 'number' },
    scheduled_time: { filterType: 'time' },
    sex: { filterType: 'select', filterOptions: genderOptions },
    time: { filterType: 'time' },
    waiting_time: { filterType: 'number' },
    amount_received: { filterType: 'number' },
    panel_or_cash: { filterType: 'select', filterOptions: panelOrCashOptions },
  };
});

const filterConditions = {
  text: [
    { value: 'is', label: translate('filter_conditions.is') },
    { value: 'isNot', label: translate('filter_conditions.is_not') },
    { value: 'contains', label: translate('filter_conditions.contains') },
    { value: 'notContains', label: translate('filter_conditions.does_not_contain') },
    { value: 'isEmpty', label: translate('filter_conditions.is_empty') },
    { value: 'notEmpty', label: translate('filter_conditions.is_not_empty') },
  ],
  select: [
    { value: 'is', label: translate('filter_conditions.is') },
    { value: 'isNot', label: translate('filter_conditions.is_not') },
    { value: 'anyOf', label: translate('filter_conditions.is_any_of') },
    { value: 'noneOf', label: translate('filter_conditions.is_none_of') },
    { value: 'isEmpty', label: translate('filter_conditions.is_empty') },
    { value: 'notEmpty', label: translate('filter_conditions.is_not_empty') },
  ],
  time: [
    { value: 'isSame', label: translate('filter_conditions.is') },
    { value: 'isNotSame', label: translate('filter_conditions.is_not') },
    { value: 'isBefore', label: translate('filter_conditions.is_before') },
    { value: 'isAfter', label: translate('filter_conditions.is_after') },
    { value: 'onOrBefore', label: translate('filter_conditions.is_on_or_before') },
    { value: 'onOrAfter', label: translate('filter_conditions.is_on_or_after') },
    { value: 'isEmpty', label: translate('filter_conditions.is_empty') },
    { value: 'notEmpty', label: translate('filter_conditions.is_not_empty') },
  ],
  number: [
    { value: 'is', label: '=' },
    { value: 'isNot', label: '≠' },
    { value: 'isLessThan', label: '<' },
    { value: 'isGreaterThan', label: '>' },
    { value: 'isLessOrEqualTo', label: '≤' },
    { value: 'isGreaterOrEqualTo', label: '≥' },
    { value: 'isEmpty', label: translate('filter_conditions.is_empty') },
    { value: 'notEmpty', label: translate('filter_conditions.is_not_empty') },
  ],
};

type FilterConditions = 'is' | 'isNot' | 'contains' | 'notContains' | 'isEmpty' | 'notEmpty'
  | 'isSame' | 'isNotSame'
  | 'isBefore' | 'isAfter' | 'onOrBefore' | 'onOrAfter'
  | 'anyOf' | 'noneOf'
  | 'isLessThan' | 'isGreaterThan' | 'isLessOrEqualTo' | 'isGreaterOrEqualTo';

export type FilterRule = {
  id: string;
  condition: FilterConditions;
  value: string | number | string[];
};

export type FilteringRule = {
  logicalOp: 'and' | 'or';
  filterRules: Array<FilterRule>,
};

type Props = {
  filteringRule: FilteringRule;
  onSave: (filteringRule: FilteringRule) => void;
  config: Config;
  user: User;
  practitioners: List<PractitionerModel>,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  paymentTypes: List<PaymentTypeModel>,
  coveragePayors: List<CoveragePayorModel>,
};

/**
 * Maps column to select option
 * @param {Array<Column>} columns Table column array
 * @returns {Array<SelectOption>}
 */
const getColumnOptions = (columns: Array<Column>): Array<SelectOption> =>
  columns.map(c => ({ label: c.Header, value: c.accessor }))
    .sort((a, b) => compareByAlphabeticalOrder(a.label, b.label));

/**
 * Renders ModalContent for sort table
 * @param {Props} props Component props
 * @returns {React.FC}
 */
const TableFilter = (props: Props) => {
  const overviewColumnMap = useMemo(
    () => getOverviewColumns(props.config, props.user),
    [],
  );
  const [isVisible, setIsVisible] = useState(false);
  const [filterRules, setFilterRules] = useState(props.filteringRule);
  const [formError, setFormError] = useState('');

  const columns = overviewColumnMap.valueSeq().toArray();
  const accessorToFilterTypeMap = getAccessorToFilterTypeMap(
    props.config, props.practitioners,
    props.encounterFlowMap.toList(),
    props.encounterStageMap.toList().filter(f => f.isVisible()),
    props.paymentTypes,
    props.coveragePayors,
  );

  /**
   * Returns select options for condition dropdown
   * @param {string} columnName columnName
   * @returns {Array<SelectOption>}
   */
  const getConditionOptions = (columnName: string) => {
    const filterType = accessorToFilterTypeMap[columnName]?.filterType;
    if (!filterType) {
      return filterConditions.text;
    }
    if (filterType === 'date') {
      return filterConditions.time;
    }
    return filterConditions[filterType];
  };

  /**
   * Handles filter changes
   * @param {Partial<FilterRule>} newFilters filter rule
   * @param {number} index sortingmodel index
   * @returns {void}
   */
  const handleColumnSelect = (
    newFilters: Partial<FilterRule>,
    index: number,
  ) => {
    const filtered = filterRules.filterRules.slice();
    filtered[index] = { ...filtered[index], ...newFilters };
    setFilterRules({ filterRules: filtered, logicalOp: filterRules.logicalOp });
  };

  /**
   * Returns Component based on column's filter type
   * @param {string} columnName columnName
   * @param {FilterConditions} condition selected condition
   * @param {number} index index of filter rule
   * @returns {React.ComponentType}
   */
  const renderValueField = (columnName: string, condition: FilterConditions, index: number) => {
    if (condition === 'isEmpty' || condition === 'notEmpty') {
      return null;
    }
    const filterType = accessorToFilterTypeMap[columnName]?.filterType;
    const filterOptions = accessorToFilterTypeMap[columnName]?.filterOptions;
    const value = filterRules.filterRules[index]?.value;
    const isMultiSelect = condition === 'anyOf' || condition === 'noneOf';
    switch (filterType) {
      case 'time':
        return (
          <TimeInput
            id={`${columnName}-time`}
            value={value && moment(value).isValid() ? moment(value) : undefined}
            onChange={val =>
              val && handleColumnSelect({ value: val.valueOf() }, index)
            }
            style={{ width: '150px' }}
            hideLabel
            inputStyle={{ marginBottom: 0 }}
          />
        );
      case 'date':
        return (
          <DateInput
            id={`date-${index}`}
            value={
              value && moment(value).isValid()
                ? moment(value)
                : (value as string | Moment)
            }
            onChange={(val) => {
              if (val) handleColumnSelect({ value: val.valueOf() }, index);
            }}
            required
            hideLabel
            inputStyle={{ marginBottom: 0 }}
          />
        );
      case 'select':
        return (
          <Select
            id={`filter-value-select-${index}`}
            options={filterOptions || []}
            onValueChanged={newVal =>
              handleColumnSelect({
                value: Array.isArray(newVal) ? newVal.map(val => val.value) : newVal,
              }, index)
            }
            value={Array.isArray(value)
              ? filterOptions?.filter(opt => value.includes(opt.value)) || []
              : value || ''}
            menuPortalTarget={document.body}
            style={{ marginBottom: 0 }}
            clearable={false}
            hideLabel
            isMulti={isMultiSelect}
            required
          />
        );
      default:
        return (
          <Input
            id={`filter-${index}`}
            value={typeof value === 'string' ? value : ''}
            onValueChanged={val => handleColumnSelect({ value: val }, index)}
            inputStyle={{ marginBottom: 0 }}
            hideLabel
            placeholder={translate('select')}
            required
          />
        );
    }
  };

  /**
   * sort handler to be passed to sortable list component, rearranges array of cards as per latest sort.
   * @param {MapValue} funcArg object with old and new index.
   * @returns {void}
   */
  const onSort = (funcArg: { oldIndex: number; newIndex: number }): void => {
    const sorted = filterRules.filterRules.slice();
    sorted.splice(funcArg.newIndex, 0, sorted.splice(funcArg.oldIndex, 1)[0]);
    setFilterRules({ filterRules: sorted, logicalOp: filterRules.logicalOp });
  };

  /**
   * Saves the selected filter rule and saves it to local storage
   * @returns {void}
   */
  const saveFilterRules = () => {
    const sanitisedRules = filterRules.filterRules.filter((s) => {
      if (s.id && s.condition) {
        if (s.condition === 'isEmpty' || s.condition === 'notEmpty') {
          // Value field won't be rendered in this case
          return true;
        }
        return s.id && s.condition && s.value;
      }
      return false;
    });
    if (filterRules.filterRules.length !== sanitisedRules.length) {
      return setFormError(translate('fill_required_fields'));
    }
    props.onSave({ filterRules: sanitisedRules, logicalOp: filterRules.logicalOp });
    setFormError('');
    return setIsVisible(false);
  };

  /**
   * @returns {void}
   */
  const handleClose = () => {
    if (!formError && !isEqual(props.filteringRule, filterRules)) {
      setFormError(translate('unsaved_changes_error'));
    } else {
      setFilterRules(props.filteringRule);
      setFormError('');
      setIsVisible(false);
    }
  };

  /**
   * Handles column selection
   * @param {number} indexToRemove index to remove
   * @returns {void}
   */
  const removeSortBy = (indexToRemove: number) => {
    setFilterRules({
      filterRules: filterRules.filterRules.filter((_, idx) => idx !== indexToRemove),
      logicalOp: filterRules.logicalOp,
    });
  };

  const columnItems = filterRules.filterRules.map((rule, idx) => (
    <ItemRow>
      {idx === 0 ? (
        <span>{translate('where')}</span>
      ) : (
        <Select
          id={`logicalop-select-${idx}`}
          options={[
            { value: 'and', label: startCase(translate('and')) },
            { value: 'or', label: translate('or') },
          ]}
          onValueChanged={newVal =>
            setFilterRules({ logicalOp: newVal, filterRules: filterRules.filterRules })
          }
          value={filterRules.logicalOp || 'and'}
          menuPortalTarget={document.body}
          style={{ marginBottom: 0 }}
          disabled={idx !== 1}
          clearable={false}
          hideLabel
          autoFocus={idx === filterRules.filterRules.length - 1}
        />
      )}
      <Div margin={`0 calc(${wsUnit} / 2)`}>
        <Select
          id={`column-name-select-${idx}`}
          options={getColumnOptions(columns)}
          onValueChanged={(newVal) => {
            if (newVal !== rule.id) {
              // Clearing conditions on column change
              handleColumnSelect({ id: newVal, condition: undefined, value: undefined }, idx);
            }
          }}
          value={rule.id}
          menuPortalTarget={document.body}
          style={{ marginBottom: 0 }}
          hideLabel
          clearable={false}
          required
          autoFocus={idx === 0}
        />
      </Div>
      <Select
        id={`condition-${idx}`}
        options={getConditionOptions(rule.id)}
        onValueChanged={newVal =>
          handleColumnSelect({ condition: newVal }, idx)
        }
        value={rule.condition || null}
        menuPortalTarget={document.body}
        style={{ marginBottom: 0 }}
        hideLabel
        clearable={false}
        required
      />
      <Div margin={`0 calc(${wsUnit} / 2)`}>
        {renderValueField(rule.id, rule.condition as FilterConditions, idx)}
      </Div>
      <CloseIcon
        onClick={() => removeSortBy(idx)}
        className="u-padding--half-ws fa fa-close"
      />
    </ItemRow>
  ));

  return (
    <StatelessModal
      id="table-filter"
      buttonLabel={
        <span className="u-flex-row u-flex-align-items-center">
          <img
            src="static/images/icon_filter.svg"
            className="u-margin-right--half-ws"
            alt=""
          />
          {`${translate('filter')} ${
            filterRules.filterRules.length
              ? `(${pluralizeWord(
                translate('column'),
                filterRules.filterRules.length,
              )} ${translate('filtered')})`
              : ''
          }`}
        </span>
      }
      buttonClass="o-button o-button--info o-button--small u-margin-right--half-ws"
      onClose={handleClose}
      visible={isVisible}
      setVisible={(visible) => {
        if (visible) {
          setIsVisible(visible);
        } else {
          handleClose();
        }
      }}
      title={translate('filter')}
      dataPublicHeader
      large
      style={{ maxWidth: 860 }}
    >
      <div className="u-margin--standard">
        {formError && <FormError isSticky>{formError}</FormError>}
        <SortableList
          items={columnItems}
          onSortEnd={onSort}
          itemZIndex={100}
          itemStyles={{
            backgroundColor: colours.grey1,
            padding: '10px 0',
            marginBottom: margins.standardMarginBottom,
          }}
        />
        <Button
          className="o-button o-button--small u-full-width u-margin-top--standard"
          onClick={() =>
            setFilterRules({
              filterRules: filterRules.filterRules.concat({
                id: '',
                condition: 'is',
                value: '',
              }),
              logicalOp: filterRules.logicalOp,
            })
          }
        >
          <I className="fa fa-plus" css={{ float: 'left', padding: 4 }} />
          {translate('add_new_filter')}
        </Button>
      </div>
      <ModalFooter>
        <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
          <SaveButton className="o-button--small" onClick={saveFilterRules} />
        </div>
      </ModalFooter>
    </StatelessModal>
  );
};

export default TableFilter;
