import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

type Props = {
  onRemove: () => void,
  label?: string,
  value: string,
};

const ChipWrapper = glamorous.div({
  borderRadius: 2,
  backgroundColor: '#ebf5ff',
  display: 'inline-block',
  minWidth: 50,
  minHeight: 30,
  border: '1px solid rgba(0, 126, 255, 0.24)',
  lineHeight: '29px',
  paddingRight: 10,
  marginRight: 10,
  marginBottom: 20,
});

const ChipIcon = glamorous.span({
  display: 'inline-block',
  paddingLeft: 5,
  paddingRight: 5,
  borderRight: '1px solid rgba(0, 126, 255, 0.24)',
  height: 30,
  cursor: 'pointer',
  color: '#007eff',
  ':hover': {
    backgroundColor: '#e9efff',
  },
});

const ChipValue = glamorous.span({
  color: '#007eff',
  paddingLeft: 5,
});
/**
 * Renders Chip that can be used for showing tag or small information and can be remove.
 * @returns {React.PureComponent} The rendered component
*/
class Chip extends PureComponent<Props, {}> {
  props: Props;

  static defaultProps = {
    label: '',
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <ChipWrapper>
        <ChipIcon onClick={() => this.props.onRemove()}>&times;</ChipIcon>
        <ChipValue>{this.props.label} {this.props.value}</ChipValue>
      </ChipWrapper>
    );
  }
}

export default Chip;
