import React from 'react';
import type { List } from 'immutable';

import { UNICODE } from './../../constants';
import { convertNumberToPrice } from '../../utils/utils';
import type BillItemModel from './../../models/billItemModel';
import type SalesItemModel from './../../models/salesItemModel';

type Props = {
  item: BillItemModel,
  salesItems: List<SalesItemModel>,
  className: string,
};

/**
 * A component that display a Prescription
 * @class SalesItemsListItem
 * @extends {React.Component}
 */
class SalesItemsListItem extends React.PureComponent<Props> {
  props: Props

  static defaultProps = {
    className: '',
  };

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className={`o-data-list__row ${this.props.className ? this.props.className : ''}`}>
        <div className="o-data-list__row__item">
          {this.props.item.getItemName(this.props.salesItems)}
        </div>
        <div className="o-data-list__row__item">
          { `${convertNumberToPrice(this.props.item.get('price'))}` || UNICODE.EMDASH }
        </div>
        <div className="o-data-list__row__item">
          { `\u00D7 ${this.props.item.get('quantity')}` || UNICODE.EMDASH }
        </div>
      </div>
    );
  }
}

export default SalesItemsListItem;
