import { connect } from 'react-redux';
import type { List } from 'immutable';

import NewNoteHandler from './../components/casenotes/newNoteHandler';
import CaseNoteFileModel from './../models/caseNoteFileModel';
import { saveFactory } from './../utils/redux';
import { addRecentlyViewedCasenote } from './../actions';

import type { State, Dispatch } from './../types';

type Props = {
  patientID: string,
  categoryID?: string,
  casenoteID?: string,
  isReferralMode?: boolean,
  assetIndex?: number,
  templateID?: string,
};

/**
 * Returns the casenote matching the given ID, or throws an error if not found. If no ID is provided
 * then undefined is returned.
 * @param {string} casenoteID A casenote ID.
 * @param {List<CaseNoteFileModel>} casenotes A list of casenotes.
 * @returns {void | CaseNoteFileModel}
 */
function getCasenote(casenoteID: string | undefined, casenotes: List<CaseNoteFileModel>) {
  if (casenoteID) {
    const casenote = casenotes.find(c => c.get('_id') === casenoteID);
    if (!casenote) {
      // TODO: throw error or try to fetch.
      throw new Error('A casenote matching the given ID could not be found.');
    }
    return casenote;
  }
  return undefined;
}

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps The passed props.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: Props) => {
  const patient = state.patients.find(p => p.get('_id') === ownProps.patientID);
  if (!patient) {
    throw new Error('Patient not found.');
  }
  return {
    patientID: ownProps.patientID,
    patient,
    categoryID: ownProps.categoryID || state.config.getIn(['categories', 'notes_category'], '0_KLINIFY_NOTES'),
    assetIndex: ownProps.assetIndex ? parseInt(ownProps.assetIndex, 10) : 0,
    config: state.config,
    caseNoteFile: getCasenote(ownProps.casenoteID, state.caseNoteFiles),
    templates: state.templates,
    recentlyViewedCasenotes: state.recentlyViewedCasenotes,
    isReferralMode: ownProps.isReferralMode || false,
    templateID: ownProps.templateID || state.config.getIn(['templates', 'default_new_note_template'], 'KLINIFY_DEFAULT_NOTE_TEMPLATE'),
    isNewTextCasenote: location.hash.indexOf('/add-text') !== -1, // If this is a route for a new text casenote.
    isOnline: state.isOnline,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  addRecentlyViewedCasenote: (patientID: string, casenoteID: string) =>
    dispatch(addRecentlyViewedCasenote(patientID, casenoteID)),
});

const CasenoteEditorContainer = connect(mapStateToProps, mapDispatchToProps)(NewNoteHandler);

export default CasenoteEditorContainer;
