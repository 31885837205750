import React from 'react';
import { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import Table from './../table/table';
import { convertNumberToPrice, isLoading } from './../../utils/utils';
import { sortByPayment } from './../../utils/comparators';
import APIError from './../../utils/apiError';

import type { MapValue, Row, CustomColumn } from './../../types';

type Props = {
  data: List<Map<string, MapValue>>,
  columns: Array<CustomColumn>,
  isFetching: boolean,
  onFilterAndSort: (data: Array<Row>) => void,
  currentDataViewsError?: APIError,
};

/**
 * Generates a table row from the given data.
 * @param {string} panelName The panel name.
 * @param {number} payableAmount The payable amount as a number.
 * @returns {{ panelName: string, payableAmount: string }} The table row
 */
function getTableRow(panelName: string, payableAmount: number) {
  return {
    panelName,
    payableAmount: convertNumberToPrice(payableAmount),
  };
}

/**
 * Gets an array of columns for react-table.
 * @param {Array<CustomColumn>} columns The selected columns passed to the component.
 * @returns {object[]} An array of objects each representing a column in the table.
 */
function getColumns(columns: Array<CustomColumn>) {
  return columns.map(c =>
    ({
      accessor: c.value,
      Header: c.label,
      sortMethod: c.value === 'payableAmount' ? sortByPayment : undefined,
      filterable: c.filterable,
      renderMethod: c.renderMethod ? c.renderMethod : undefined,
    }));
}

/**
 * A table displaying Accounts Receivable information.
 * @param {Props} props The props for the component.
 * @returns {React.Component} The component.
 */
const AccountsReceivableTable =
({ columns, data, isFetching, onFilterAndSort, currentDataViewsError }: Props) => (
  <Table
    columns={getColumns(columns)}
    data={
      data
        .map(datum => getTableRow(datum.get('panelName'), datum.get('payableAmount')))
        .toArray()
    }
    loading={isFetching}
    noDataText={
      translate(currentDataViewsError ? 'no_accounts_receivable' : isLoading(isFetching, 'no_supplies_received_found'))
    }
    showPagination
    filteredSortedDataHandler={filteredData => onFilterAndSort(filteredData)}
    initialDataHandler={initialData => onFilterAndSort(initialData)}
  />
);

export default AccountsReceivableTable;
