// NOTE: This is from older versions of the app and it is unclear if we should continue using it in
// newer versions of the app. Please ask someone before using it.
import BaseModel from './baseModel';
import { UNICODE } from './../constants';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'medical_complaint',
  patient_id: string,
  complaint: string,
};

/**
 * MedicalComplaintModel
 * @namespace MedicalComplaintModel
 */
class MedicalComplaintModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'medical_complaint';
  }

  /**
   * Sets the value of this model.
   * @param {string} value The new value
   * @returns {this}
   */
  setValue(value: string): this {
    return this.set('complaint', value);
  }

  /**
   * Gets the main value of the model
   * @returns {string}
   */
  getValue(): string | void {
    return this.get('complaint');
  }

  /**
   * Gets the label to be displayed to the user for this model.
   * @returns {string}
   */
  getLabel(): string {
    return this.get('complaint', UNICODE.EMDASH, false);
  }

  /**
   * Sets the item visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {this}
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('hidden', !isVisible);
  }
}

export default MedicalComplaintModel;
