import React, { useState } from 'react';
import { List, Seq } from 'immutable';
import moment from 'moment';

import { renderDate } from '../../../utils/tables';
import { sortByDate } from '../../../utils/comparators';
import TableColumnsSettings from '../../table/tableColumnsSettings';
import { getStore } from '../../../utils/redux';
import Header from '../../header/header';
import Table from '../../table/table';
import SaveButton from '../../buttons/saveButton';
import { transformColumnKeys, transformFilteredColumns } from '../../../utils/utils';
import { syncBatchInventoryCountOnEvent, isBatchDataNotReady } from '../../../utils/inventory';
import translate from '../../../utils/i18n';
import { UNICODE, DATE_FORMAT_TO_SAVE } from '../../../constants';
import Button from '../../buttons/button';

import type DrugModel from '../../../models/drugModel';
import type { Config, MapValue, CountPerSKUAndBatch, Row } from '../../../types';
import SupplyItemModel from '../../../models/supplyItemModel';
import { ActionMode } from '../stockAdjustmentActions';

type Props = {
  selectedDrug?: DrugModel,
  showEmptyBatches: boolean,
  isSupplyItemChangeMode: boolean,
  displayTableColumnSettings: boolean,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  inventoryCount: CountPerSKUAndBatch,
  supplyItems: List<SupplyItemModel>,
  isFetching: boolean,
  action: string,
  onActionButtonClicked: (supplyItem: SupplyItemModel) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  config: Config,
  isBulk?: boolean,
}

const COLUMNS = List([
  { accessor: 'date', Header: translate('date'), Cell: renderDate, sortMethod: sortByDate, filterable: true },
  { accessor: 'batch_id', Header: translate('batch_id'), filterable: true },
  { accessor: 'received_qty', Header: translate('received_qty') },
  { accessor: 'remaining_qty', Header: translate('remaining_qty') },
  { accessor: 'dispensation_unit', Header: translate('dispensation_unit') },
  { accessor: 'cost_of_goods', Header: translate('cost_of_goods') },
  { accessor: 'unit_cost', Header: translate('unit_cost') },
  { accessor: 'expiry_dates', Header: translate('expiry_dates'), filterable: true },
  { accessor: 'manufacturer', Header: translate('manufacturer'), filterable: true },
  { accessor: 'notes', Header: translate('notes') },
  { accessor: 'recorded_date', Header: translate('recorded_date'), Cell: renderDate, sortMethod: sortByDate, filterable: true },
  { accessor: 'action', Header: 'action' },
]);

/**
 * Renders a batch selection modal for the batch adjustment
 * @param {Props} props SummaryMetric
 * @returns {React.FunctionComponent} The rendered component
*/
function BatchSelectTable(props: Props) {
  const { selectedDrug, isSupplyItemChangeMode } = props;
  const actionColumnIndex = COLUMNS.findIndex(c => c.accessor === 'action');
  const initialColumns =
    isSupplyItemChangeMode && actionColumnIndex
      ? COLUMNS.splice(actionColumnIndex, 1)
      : COLUMNS;
  const originalColumns = List(transformColumnKeys(initialColumns.toArray()));
  const [columns, setColumns] = useState(originalColumns);
  const [showEmptyBatches, setShowEmptyBatches] = useState(props.showEmptyBatches);
  const loading = props.isFetching || isBatchDataNotReady(
    props.supplyItems.map(s => ({
      sku_id: s.get('sku_id'),
      supply_item_id: s.get('_id'),
    })),
    props.inventoryCount,
    props.inventoryCountSyncStatus.last(),
  );

  /**
   * Renders the action button component.
   * @param {SupplyItemModel} supplyItem The supplyItemModel
   * @return {string} - HTML markup for the component
   */
  function getActionButton(supplyItem: SupplyItemModel) {
    return (
      <Button
        type="button"
        className="o-button o-button--small"
        onClick={() => props.onActionButtonClicked(supplyItem)}
        dataPublic
        disabled={(props.action === ActionMode.UPDATE_DETAILS) && supplyItem.get('_id') === 'UNKNOWN'}
      >
        {props.action === 'adjustment' ? translate('adjust') : translate(props.action)}
      </Button>
    );
  }

  /**
   * saves the toggles state of the empty batches
   * @param {boolean} emptyBatches The toggle state
   * @returns {void}
   */
  function toggleEmptyBatches(emptyBatches: boolean) {
    syncBatchInventoryCountOnEvent(getStore(), [props.selectedDrug?.get('_id')], emptyBatches, 0, []);
    setShowEmptyBatches(emptyBatches);
  }

  /**
   * @returns {Seq<Row>}
   */
  function getRows(): Seq<Row> {
    return props.supplyItems.toSeq()
      .map(supplyItem => ({
        id: supplyItem.get('_id'),
        date: supplyItem.get('date') ? moment(supplyItem.get('date'), DATE_FORMAT_TO_SAVE).valueOf() : UNICODE.EMDASH,
        batch_id: supplyItem.get('batch_id', UNICODE.EMDASH),
        received_qty: supplyItem.get('quantity', UNICODE.EMDASH),
        remaining_qty: props.inventoryCount.getIn([
          supplyItem.get('sku_id'),
          'batches',
          supplyItem.get('_id'),
          'batchStockRemaining',
        ], 0),
        dispensation_unit: selectedDrug?.get('dispensation_unit', UNICODE.EMDASH),
        cost_of_goods: supplyItem.get('total_price', UNICODE.EMDASH),
        unit_cost: Number(supplyItem.get('total_price', 0)) / Number(supplyItem.get('quantity', 0)) || UNICODE.EMDASH,
        expiry_dates: supplyItem.get('expiry_date', UNICODE.EMDASH),
        notes: supplyItem.get('notes', UNICODE.EMDASH),
        recorded_date: supplyItem.getCreatedTime(),
        action: getActionButton(supplyItem),
        manufacturer: selectedDrug?.get('manufacturer') || UNICODE.EMDASH,
      }));
  }

  const data = selectedDrug ? getRows() : Seq();
  const filteredData = showEmptyBatches ? data.toList() : data.filter(
    (d:Row) => d.remaining_qty > 0,
  );
  return (
    <React.Fragment>
      <Header className="o-card__header">
        <h1 className="o-card__title">{ isSupplyItemChangeMode ? translate('batch') : translate('batches_in', { sku: selectedDrug?.get('name') }) }</h1>
        { props.displayTableColumnSettings &&
          <TableColumnsSettings
            config={props.config}
            configFieldName={props.isBulk ? `inventory_bulk_${props.action}` : `inventory_${props.action}`}
            updateConfigValue={props.updateConfigValue}
            originalColumns={originalColumns.splice(-1, 1)}
            columns={columns.slice().splice(0, columns.size - 1).toArray()}
            onUpdateColumns={(updatedColumns) => {
              const actionColumn = originalColumns.find(c => c.value === 'action');
              setColumns(actionColumn ? List(updatedColumns.concat(actionColumn)) : columns);
            }}
            updateConfig={props.updateConfig}
          />
        }
      </Header>
      <div className="o-header-actions">
        <div className="u-flex-left u-margin-left--half-ws">
          <SaveButton
            dataPublic
            label={showEmptyBatches ? translate('hide_empty_batches') : translate('show_empty_batches')}
            onClick={() => toggleEmptyBatches(!showEmptyBatches)}
            className="o-button--small u-margin-right--half-ws"
            disabled={loading || data.size < 1 || !selectedDrug}
          />
        </div>
      </div>
      <Table
        columns={transformFilteredColumns(COLUMNS.toArray(), columns.toArray())}
        data={filteredData.toArray()}
        loading={loading}
        noDataText={isSupplyItemChangeMode ? translate('there_are_no_results') : translate('there_are_no_batch_results')}
        defaultSorted={[{ id: 'date', desc: true }]}
      />
    </React.Fragment>
  );
}

export default BatchSelectTable;
