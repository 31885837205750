import { Div } from 'glamorous';
import { List } from 'immutable';
import React, { useMemo, useState } from 'react';
import DiscountChargeModel from '../../models/discountChargeModel';
import translate from '../../utils/i18n';
import Button from '../buttons/button';
import CloseButton from '../buttons/closeButton';
import SaveButton from '../buttons/saveButton';
import Select from '../inputs/select';
import ModalFooter from '../modals/modalFooter';
import ModalFooter from '../modals/modalFooter';
import StatelessModal from '../modals/statelessModal';

type Props = {
  discountsCharges: List<DiscountChargeModel>,
  usedDiscountsCharges: List<DiscountChargeModel>,
  setUsedDiscountsCharges: (d: List<DiscountChargeModel>) => void
  disabled: boolean
}

/**
 * selector modal component used for selecting discounts
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const DiscountChargeSelectorModal = ({isVisible, discountsCharges, addDiscountCharge, handleFormClose}) => {
  const [selectedDiscountCharge, setSelectedDiscountCharge] = useState<string>(discountsCharges?.get(0)?.get('_id'));
  return (
    <StatelessModal
      id="discounts-form"
      visible={isVisible}
      setVisible={handleFormClose}
      title={translate('add_discount_s_charge_item_to_bill')}
      dataPublicHeader
      noButton
      explicitCloseOnly
    >
      <div className="u-margin--standard">
        <Select
          id="item_type"
          label={translate('discount_s_charge')}
          options={discountsCharges.map(d => ({ value: d.get('_id'), label: d.get('name') }))}
          value={selectedDiscountCharge}
          onValueChanged={(value) => {
            setSelectedDiscountCharge(value)
          }}
          required
        />
      </div>
      <ModalFooter>
        <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
          <SaveButton
            label={translate('add_item')}
            className="o-button--small"
            onClick={() => {
              const filteredDiscountCharge = discountsCharges.find(d => d.get('_id') === selectedDiscountCharge);
              addDiscountCharge(filteredDiscountCharge);
              handleFormClose();
            }}
            dataPublic
          />
        </div>
      </ModalFooter>
    </StatelessModal>
  )
}

/**
 * BillDiscountChargeForm
 * @namespace BillDiscountChargeForm
 * @returns {React.Component} A BillDiscountChargeForm component.
 */
const BillDiscountChargeForm = ({ discountsCharges, usedDiscountsCharges, setUsedDiscountsCharges, disabled }: Props) => {
  const [showAddItemForm, setShowAddItemForm] = useState(false);

  /**
   * Called to delete a used discount/charge
   * @param {string} id discount/charge id to delete
   * @returns {void}
   */
  const onDeleteClicked = (id: string) => {
    const filteredDiscountsCharges = usedDiscountsCharges.filter(d => d.get('_id') !== id);
    setUsedDiscountsCharges(filteredDiscountsCharges);
  }

  const availableDiscountsCharges = useMemo<List<DiscountChargeModel>>(() => discountsCharges.filter(e => usedDiscountsCharges.every(d => d.get('_id') !== e.get('_id'))), [discountsCharges, usedDiscountsCharges]);

  return (
    <div style={{ fontSize: '12px', width: '100%' }}>
      <h2 className="o-card__title">{translate('discounts_s_charges')}</h2>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {
          usedDiscountsCharges.map(d => (
            <div className="o-data-list__row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}>
              <h2>{d.get('name')}</h2>
              {!disabled &&
                <Div css={{ marginTop: '0px' }}>
                  <CloseButton dataPublic onClick={() => onDeleteClicked(d.get('_id'))} />
                </Div>
              }
            </div>
          ))
        }
      </div>
      <div style={{ padding: '20px', width: '100%', boxSizing: 'border-box' }}>
        <Button
          className="o-button o-button--small"
          style={{ marginTop: 0, width: '100%' }}
          onClick={() => { setShowAddItemForm(true); }}
          disabled={!availableDiscountsCharges.size || disabled}
          dataPublic
        >
          {translate('add_item')}
        </Button>
      </div>
      {showAddItemForm &&
        <DiscountChargeSelectorModal
          discountsCharges={availableDiscountsCharges}
          addDiscountCharge={(dc) => {
            setUsedDiscountsCharges(usedDiscountsCharges.push(dc));
          }}
          isVisible={showAddItemForm}
          handleFormClose={() => {
            setShowAddItemForm(false);
          }}
        />
      }
    </div>
  );
};

export default BillDiscountChargeForm;
