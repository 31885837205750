import React from 'react';
import { List, Map } from 'immutable';

import { convertNumberToPrice, sumAndFormatPrice } from './../../utils/utils';
import KeyValue from './../layout/keyValue';
import { prettifyDate } from './../../utils/time';
import { getModelsForBills } from './../../utils/api';
import APIError from './../../utils/apiError';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import { UNICODE } from './../../constants';
import { downloadCSV, convertToCSV } from './../../utils/export';
import { printCostReport } from './../../utils/print';
import TableColumnsSettings from './../table/tableColumnsSettings';
import TableDateRangePicker from './../table/tableDateRangePicker';
import TableExportPrintOptions from './../table/tableExportPrintOptions';
import CostReportTable from './costReportTable';
import Header from './../header/header';

import type { MapValue, Config, Row, CustomColumn } from './../../types';
import type SalesItemModel from './../../models/salesItemModel';
import type DrugModel from './../../models/drugModel';
import type BillItemModel from './../../models/billItemModel';
import type BillModel from './../../models/billModel';

type Props = {
  filter: Map<string, MapValue>,
  bills: List<BillModel>,
  filter: Map<string, MapValue>,
  billItems: List<BillItemModel>,
  drugs: List<DrugModel>,
  salesItems: List<SalesItemModel>,
  filter: Map<string, MapValue>,
  config: Config,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  updateConfig: (config: Config) => void,
};

type State = {
  columns: Array<CustomColumn>,
  data: Array<Row>,
  filteredData: Array<Row>,
  isFetchingData: boolean,
  exportPrintAttributes: {
    appendTotalsToExport: boolean,
    title: string,
    isLandscape: boolean,
  }
};

const COLUMNS = List([
  { value: 'name', label: translate('item_name'), filterable: true },
  { value: 'type', label: translate('type') },
  { value: 'quantity_sold', label: translate('quantity_sold') },
  { value: 'unit', label: translate('unit') },
  { value: 'cost_price_per_unit', label: translate('cost_price_per_unit'), renderMethod: 'PRICE' },
  { value: 'subtotal', label: translate('subtotal'), renderMethod: 'PRICE' },
]);

/**
 * A component that displays cost of goods sold.
 * @class CostReport
 * @extends {Component}
 */
class CostReport extends React.Component<Props, State> {
  props: Props

  /**
   * Creates an instance of CostReport.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredData: [],
      data: [],
      columns: COLUMNS.toArray(),
      isFetchingData: true,
      exportPrintAttributes: {
        title: 'Cost of Goods Sold Report',
        isLandscape: true,
        appendTotalsToExport: true,
      },
    };
  }

  /**
   * Called functions after component fully rendered
   * @returns {void}
   */
  componentDidMount() {
    this.getTableData(this.props.bills);
  }

  /**
   * Triggers a data fetch when the bills prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.bills !== this.props.bills) {
      this.getTableData(this.props.bills);
    }
  }

  /**
   * Get data for table rows.
   * @param {List<BillModel>} bills List of Bill model
   * @returns {void}
   */
  getTableData(bills: List<BillModel>) {
    if (bills.size === 0 || this.props.billItems === 0) {
      this.setState({ isFetchingData: false, data: [] });
    } else {
      Promise.all([
        getModelsForBills(bills.map(b => b.get('_id')), 'bill_item'),
      ])
        .then(([billItems]) => {
          if (bills === this.props.bills) {
            const data = billItems.map((billItem) => {
              let name;
              let type;
              let unit;
              let costPricePerUnit = 0;
              if (billItem.isPrescription()) {
                const drug = this.props.drugs.find(d => d.get('_id') === billItem.get('drug_id'));
                name = drug.get('name');
                type = translate('prescription_item');
                unit = drug.get('dispensation_unit') ? drug.get('dispensation_unit') : translate('n_a');
                costPricePerUnit = drug.get('cost_price');
              } else if (billItem.isSalesItem()) {
                const salesItem = this.props.salesItems.find(d => d.get('_id') === billItem.get('sales_item_id'));
                name = salesItem.get('name');
                type = translate('sales_item');
                unit = translate('n_a');
                costPricePerUnit = salesItem.get('cost_price');
              }
              return Object.assign({}, {
                name,
                type,
                quantity_sold: billItem.get('quantity'),
                unit,
                cost_price_per_unit: isNaN(parseFloat(costPricePerUnit)) ? 0 : costPricePerUnit,
                subtotal: isNaN(parseFloat(costPricePerUnit * billItem.get('quantity'))) ? 0 : (costPricePerUnit * billItem.get('quantity')),
              });
            });
            this.setState({ isFetchingData: false, data: data.toArray() });
          }
        });
    }
  }

  /**
   * Merges the given object with the current filter for cost report and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: {}) {
    this.props.onFilterUpdated(this.props.filter.merge(newValues));
  }

  /**
   * Get Total cost
   * @returns {void}
   */
  getTotalCost() {
    const totalCost = this.state.filteredData.filter(n => !isNaN(n.subtotal)).map(d => d.subtotal);
    return List(totalCost);
  }

  /**
   * Exports the cost of goods sold.
   * @returns {void}
   */
  onExportClicked() {
    const columns = this.state.columns.filter(c => c.value !== 'number');
    let data = this.state.filteredData.map(item => columns.map(column => item[column.value]));
    if (this.state.exportPrintAttributes.appendTotalsToExport) {
      data = data.concat([
        [translate('total_cost'), sumAndFormatPrice(this.getTotalCost())],
      ]);
    }
    downloadCSV(
      `${this.state.exportPrintAttributes.title}.csv`,
      convertToCSV(columns.map(c => c.label), data),
    );
  }

  /**
   * Prints the cost of goods sold.
   * @returns {Promise<void>} A promise upon print completion.
   */
  onPrintClicked(): Promise<void> {
    const data = this.state.filteredData.map((item, index) => this.state.columns.map((column) => {
      if (column.value === 'number') {
        return (index + 1).toString();
      }
      if (column.renderMethod === 'PRICE') {
        return convertNumberToPrice(item[column.value]);
      }
      const value = item[column.value] !== undefined ? item[column.value] : UNICODE.EMDASH;
      return value;
    }));
    printCostReport(
      this.state.columns,
      data,
      {
        totalCost: sumAndFormatPrice(this.getTotalCost()),
      },
      {
        filterDateStart: prettifyDate(Number(this.props.filter.get('filterDateStart'))),
        filterDateEnd: prettifyDate(Number(this.props.filter.get('filterDateEnd'))),
      },
      this.props.config,
      this.state.exportPrintAttributes.title,
      this.state.exportPrintAttributes.isLandscape,
    );
    return Promise.resolve();
  }

  /**
   * Helper function to update attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  updateExportPrintAttribute(key: string, value: MapValue) {
    this.setState({
      exportPrintAttributes: Object.assign({}, this.state.exportPrintAttributes, { [key]: value }),
    });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const isPrintExportButtonEnabled = this.state.isFetchingData ||
      this.props.isFetching || this.props.currentDataViewsError ||
      !this.state.data.length || !this.state.filteredData.length;
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('cost_of_goods_sold')}</h1>
        </Header>
        <div className="u-flex-row u-margin--standard u-flex-space-between u-flex-align-items-center">
          <KeyValue
            label={translate('total_cost')}
            value={sumAndFormatPrice(this.getTotalCost())}
          />
          <TableDateRangePicker
            filter={this.props.filter}
            onUpdateFilter={newFilter => this.updateFilter(newFilter)}
          />
        </div>
        <div className="o-card u-margin-bottom--4ws">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{ translate('cost_of_goods_sold') }</h1>
            <TableColumnsSettings
              config={this.props.config}
              configFieldName="cost_report"
              updateConfigValue={this.props.updateConfigValue}
              originalColumns={COLUMNS}
              columns={this.state.columns}
              onUpdateColumns={(columns) => {
                this.setState({ columns });
              }}
              updateConfig={this.props.updateConfig}
            />
          </Header>
          <div className="o-header-actions">
            <div className="u-flex-left u-margin-left--half-ws">
              <TableExportPrintOptions
                exportPrintAttributes={this.state.exportPrintAttributes}
                updateExportPrintAttribute={
                  (key, value) => this.updateExportPrintAttribute(key, value)
                }
                isPrintExportButtonEnabled={isPrintExportButtonEnabled}
                onExportClicked={() => this.onExportClicked()}
                onPrintClicked={() => this.onPrintClicked()}
              />
            </div>
          </div>
          <CostReportTable
            columns={this.state.columns}
            data={this.state.data}
            isFetching={this.state.isFetchingData || this.props.isFetching}
            currentDataViewsError={this.props.currentDataViewsError}
            onFilterAndSort={filteredData => this.setState({ filteredData })}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default CostReport;
