import { connect } from 'react-redux';
import { List } from 'immutable';

import DrugDurations from './../components/drugDurations/drugDurations';
import { saveFactory } from './../utils/redux';
import { updateConfigValue, updateConfig } from './../actions';

import type { Dispatch, State, Config } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ user, config }: State) => ({
  user,
  drugDurations: config.getIn(['prescription', 'durations'], List()).filter(e => e !== null && e !== undefined),
  config,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateList: updatedList => dispatch(updateConfigValue(['prescription', 'durations'], updatedList)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  updateConfigValue,
});

const DrugDurationsContainer = connect(mapStateToProps, mapDispatchToProps)(DrugDurations);

export default DrugDurationsContainer;
