import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { P, Div } from 'glamorous';

import SaveButton from './../buttons/saveButton';
import StatelessModal from './../modals/statelessModal';
import { margins } from './../../utils/css';
import translate from './../../utils/i18n';
import Button from './../buttons/button';

import type { ReactRouterLocation } from './../../types';

type Props = {
  when: boolean |
    ((currentLocation: ReactRouterLocation, nextLocation: ReactRouterLocation) => boolean),
  onSaveClicked: () => Promise<boolean>, // A promise that returns true on successful save.
};

type State = {
  isSaving: boolean,
};


/**
 * A component that displays a model asking the user if they want to save before leaving the current
 * page. Yes saves, no doesn't save, cancel cancels the route action. props.onSaveClicked should
 * be a promise returning a boolean. If false is returned the save operation has failed for some
 * reason and the route action is cancelled.
 * @class SavePrompt
 * @extends {React.Component<Props, State>}
 */
class SavePrompt extends React.Component<Props, State> {
  /**
   * Creates an instance of SavePrompt.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <NavigationPrompt when={this.props.when}>
        {({ onConfirm, onCancel }) => (
          <StatelessModal
            noButton
            visible
            explicitCloseOnly
            noCloseButton
            setVisible={() => {}}
            id="save-prompt-modal"
            title={translate('save_before_you_leave_the_page')}
            dataPublicHeader
          >
            <P css={{ margin: margins.standardMargins }}>
              {translate('save_before_you_leave_the_page_desc')}
            </P>
            <Div css={{ margin: margins.standardMargins, display: 'flex' }}>
              <SaveButton
                onClick={() => {
                  this.setState({ isSaving: true });
                  this.props.onSaveClicked().then((wasSuccessful) => {
                    if (wasSuccessful) {
                      onConfirm();
                      this.setState({ isSaving: false });
                    } else {
                      onCancel();
                      this.setState({ isSaving: false });
                    }
                  });
                }}
                label={translate('yes')}
                isSaving={this.state.isSaving}
                dataPublic
              />
              <Button
                className="o-button o-button--danger u-margin-left--1ws"
                disabled={this.state.isSaving}
                onClick={onConfirm}
                dataPublic
              >
                {translate('no')}
              </Button>
              <Button
                className="o-button o-button--danger u-margin-left--1ws"
                disabled={this.state.isSaving}
                onClick={onCancel}
                dataPublic
              >
                {translate('cancel')}
              </Button>
            </Div>
          </StatelessModal>
        )}
      </NavigationPrompt>
    );
  }
}

export default SavePrompt;
