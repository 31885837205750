import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import Moment, { Moment as IMoment } from 'moment';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import { wsUnit } from '../../utils/css';
import type { SaveModel, Config, SelectOption, MapValue } from './../../types';
import PatientRegistrationContainer from '../../containers/patientRegistrationContainer';
import DoctorForm from '../../components/doctors/doctorForm';
import StatelessModal from './../modals/statelessModal';
import FormError from './../formError';
import Select from './../inputs/select';
import DatePicker from './../inputs/statefulDatepicker';
import TimePicker from './../inputs/timepicker';
import TextArea from './../inputs/textarea';
import Button from '../buttons/button';
import FlowForm from '../encounter/flowForm';
import Input from '../inputs/input';
import translate from '../../utils/i18n';
import { validateAndTrimString } from './../../utils/utils';
import { getStageNamesWithSuffix, printQueueTicketonArrival, saveEncounterAndRelatedModels } from './../../utils/encounters';
import { getModelMapFromList } from '../../utils/models';
import { mapStringToOption } from '../../utils/inputs';
import type BaseModel from '../../models/baseModel';
import type BillModel from '../../models/billModel';
import type SalesItemModel from '../../models/salesItemModel';
import type PatientStubModel from '../../models/patientStubModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type PractitionerModel from '../../models/practitionerModel';
import type BillItemModel from '../../models/billItemModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import EncounterModel, { OccurrenceInfo } from '../../models/encounterModel';

type Props = {
    config: Config,
    saveModel: SaveModel,
    addPatientStub: (model: PatientStubModel) => void,
    bill: BillModel,
    coveragePayors: List<CoveragePayorModel>,
    patient: PatientStubModel,
    patientStubId: string,
    patientStubs: List<PatientStubModel>,
    practitioners: List<PractitionerModel>,
    salesItemsMap: Map<string, SalesItemModel>,
    salesItemModels: List<SalesItemModel>,
    billItemsForBill: List<BillItemModel>,
    encounterFlows: List<EncounterFlowModel>,
    stagesMap: Map<string, EncounterStageModel>,
    updateConfig: (config: Config) => Promise<Config>,
    // these props are from doc validation.
    isFromDocValidationModal?: boolean,
    validationDocObject?: { id: string, type: string }
    isDocValidationModalSaving?: boolean,
    noSave?: boolean,
    validationReferrerModel?: BaseModel,
    onSaveAtDocValidationModal?: (
      wasSuccessful: boolean,
      ...models: Array<EncounterModel | BillModel | BillItemModel | undefined>
    ) => void,
}

type EncounterAttributes = {
  patientId?: string,
  doctorId?: string,
  encounterDate: IMoment,
  encounterTime: IMoment,
  flow?: {
    name: string,
    flow_id: string,
    stages: List<{name: string, stageId: string, unsuffixed: string, notes?: string, occurrences?: Array<OccurrenceInfo>}>,
  }
}

const EncounterDateTimeContainer = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  '& > .o-form__item': {
    flex: 1,
    marginRight: wsUnit,
  },
  '& > .o-form__item:first-child': {
    flex: 2,
  },
});

/**
 * Gets all the available attrs to set in state.
 * @param {PatientStubModel} patient patient for this encounter.
 * @returns {EncounterAttributes}
 */
function getInitialAttrs(patient: PatientStubModel): EncounterAttributes {
  return {
    patientId: patient?.get('_id'),
    encounterDate: Moment(),
    encounterTime: Moment(),
  };
}

/**
 * Converts moment date and time to unix timestamp.
 * @param {IMoment} date encounter date.
 * @param {IMoment} time encounter time.
 * @returns {number | null}.
 */
function convertDateToTimestamp(date: IMoment, time: IMoment): number | null {
  if (date &&
    time &&
    date.isValid() &&
    time.isValid()) {
    const startOfDate = date?.startOf('day');
    const hoursToAdd = time?.hours();
    const minutesToAdd = time?.minutes();
    const modifiedTime = startOfDate.add(hoursToAdd, 'h').add(minutesToAdd, 'm');
    return modifiedTime?.valueOf();
  }
  return null;
}

/**
   * Validates the form before save.
   * @param {EncounterAttributes} attrs encounter attributes.
   * @returns {boolean}
   */
function validateForm(attrs: EncounterAttributes): boolean {
  const {
    encounterDate,
    encounterTime,
    patientId,
    doctorId,
    flow,
  } = attrs;
  if (encounterDate &&
    encounterDate.isValid() &&
    encounterTime &&
    encounterTime.isValid() &&
    patientId &&
    doctorId &&
    location &&
    flow) {
    return true;
  }
  return false;
}

/**
 * Component to add/edit drug
 * @returns {React.SFC}
 */
const AddEncounterForm = ({
  config,
  saveModel,
  bill,
  coveragePayors,
  patient,
  patientStubId,
  patientStubs,
  practitioners,
  salesItemsMap,
  salesItemModels,
  billItemsForBill,
  encounterFlows,
  stagesMap,
  updateConfig,
  // these props are from doc validation.
  noSave,
  isFromDocValidationModal,
  validationDocObject,
  isDocValidationModalSaving,
  onSaveAtDocValidationModal,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [encounterAttrs, setEncounterAttrs] = useState(getInitialAttrs(patient));
  const [patientRegistrationModalVisible, setPatientRegistrationModalVisible] = useState(false);
  const [doctorRegistrationModalVisible, setDoctorRegistrationModalVisible] = useState(false);
  const [flowFormVisible, setFlowFormVisible] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<EncounterFlowModel>();

  /**
   * Helper function to update encounter attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  const updateAttribute = (key: string, value: MapValue) => {
    setEncounterAttrs(Object.assign({}, encounterAttrs, { [key]: value }));
  };

  /**
   * Updates state with new encounter flow and closes select flow modal
   * @param {EncounterFlowModel} encounterFlow Selected encounter flow name
   * @returns {void}
   */
  const handleFlowChange = (encounterFlow: EncounterFlowModel) => {
    setSelectedFlow(encounterFlow);
    setEncounterAttrs(Object.assign({}, encounterAttrs, {
      flow: {
        name: encounterFlow.get('name'),
        flow_id: encounterFlow.get('_id'),
        stages: getStageNamesWithSuffix(encounterFlow.getStages(stagesMap)),
      },
    }));
    setFlowFormVisible(false);
    return Promise.resolve();
  };

  /**
   * Handles creation new items
   * @param {string} _option typed in data.
   * @param {string} _type determines the select input from where it is triggered.
   * @param {boolean} _visible should new modal be visible.
   * @returns {void}
   */
  const handleCreateModal = (_option: string = '', _type: string, _visible: boolean) => {
    if (_type === 'patient') {
      setPatientRegistrationModalVisible(_visible);
    } else if (_type === 'doctor') {
      setDoctorRegistrationModalVisible(_visible);
    }
  };

  /**
   * Gets all the patients options
   * @returns {Array<SelectOption>}
   */
  const getPatientsOptions = (): Array<SelectOption> => useMemo(() => (patient ? List([patient]) : List())
    .map(item => ({ value: item.get('_id'), label: `${item.get('patient_name')} - ${item.get('ic')}` }))
    .push({ value: 'create', label: translate('add_new_patient') })
    .toArray(), [patientStubs]);

  /**
   * Creates encounter model from encounter attributes state
   * @param {EncounterAttributes} formattedAttrs encounter attributes
   * @returns {EncounterModel}
   */
  const getEncounterModelFromAttributes = (formattedAttrs: EncounterAttributes) => {
    const {
      encounterDate,
      encounterTime,
      patientId,
      doctorId,
      flow,
    } = formattedAttrs;
    const attrs = {
      _id: validationDocObject?.id,
      patient_id: patientId,
      bill_id: bill?.get('_id'),
      doctor: doctorId,
      schedule_time: convertDateToTimestamp(encounterDate, encounterTime),
      encounter_events: [
        { type: 'arrived', time: convertDateToTimestamp(encounterDate, encounterTime) },
      ],
      flow: {
        name: flow?.name,
        flow_id: flow?.flow_id,
        stages: flow?.stages.map((stage, idx) => ({
          // Removing stageId, suffixed name etc from stages added by `getStageNamesWithSuffix`
          stage_id: stage.stageId,
          name: stage.unsuffixed,
          notes: validateAndTrimString(stage.notes || ''),
          occurrences: [{
            ...(stage.occurrences ? stage.occurrences[0] : []),
            events: idx === 0 ? [{ type: 'arrived', time: convertDateToTimestamp(encounterDate, encounterTime) }] : [],
          }],
        })).toArray(),
      },
    };
    return new EncounterModel(attrs, true);
  };

  /**
   * Gets all the doctors options
   * @returns {Array<SelectOption>}
   */
  const getDoctorsOptions = (): Array<SelectOption> => useMemo(() =>
    practitioners.map(item => ({ value: item.get('_id'), label: item.get('name') }))
      .push({ value: 'create', label: translate('add_new_doctor') })
      .toArray(), [practitioners]);

  /**
   * @desc check for mapped data and update api/models after adding/editing drug
   * @returns {boolean}
   */
  const onSaveClicked = (): Promise<boolean> => { // TODO: check for event and update api/models accordingly
    if (validateForm(encounterAttrs)) {
      setErrorMessage('');
      const encounterModel = getEncounterModelFromAttributes(encounterAttrs);
      // TODO: In this component we have to add a provision for creation of bills if not found.
      return saveEncounterAndRelatedModels(patient, encounterModel, bill, undefined, billItemsForBill.toArray())
        .then((savedModels) => {
          if (!savedModels) {
            if (onSaveAtDocValidationModal) onSaveAtDocValidationModal(false);
            return false;
          }
          if (onSaveAtDocValidationModal) onSaveAtDocValidationModal(true, encounterModel);
          printQueueTicketonArrival(encounterModel, config, salesItemModels);
          return true;
        });
    }
    setErrorMessage(translate('fill_required_fields'));
    return Promise.resolve(false);
  };

  /**
   * Returns locations and encounter notes fields for each stage in the selected encounter flow.
   * @returns {React.ReactNode}
   */
  const renderStageAttributes = (): React.ReactNode => encounterAttrs.flow?.stages?.map((stage, idx) => {
    const stageModel = stagesMap.get(stage.stageId);
    const locations = stageModel?.get('locations') || config.getIn(['clinic', 'locations'], []);
    const stageInfo = encounterAttrs?.flow?.stages?.get(idx);
    const locationField = (
      <Select
        id={`${stage.name}_location`}
        label={translate('x_location', { x: stage.name })}
        labelClassName="o-label"
        value={stageInfo?.occurrences && stageInfo?.occurrences[0]?.location}
        options={mapStringToOption(locations)}
        required
        onValueChanged={(newVal: string) => setEncounterAttrs({
          ...encounterAttrs,
          flow: {
            // These won't be rendered if flow is undefined, hence ignoring the type error
            // @ts-ignore
            name: encounterAttrs.flow?.name,
            // @ts-ignore
            flow_id: encounterAttrs.flow?.flow_id,
            // @ts-ignore
            stages: encounterAttrs.flow?.stages.set(idx, {
              ...(stageInfo || {}),
              occurrences: [{
                location: newVal,
                events: [],
              }],
            }),
          },
        })}
      />
    );
    if (stageModel?.get('has_notes')) {
      return (
        <Fragment key={stage.name}>
          {locationField}
          <TextArea
            id={`${stage.name}_notes`}
            label={`${stage.name} ${translate('notes')}`}
            value={stageInfo?.notes || ''}
            onValueChanged={newVal => setEncounterAttrs({
              ...encounterAttrs,
              flow: {
                // @ts-ignore
                name: encounterAttrs.flow?.name,
                // @ts-ignore
                flow_id: encounterAttrs.flow?.flow_id,
                // @ts-ignore
                stages: encounterAttrs?.flow?.stages?.set(idx, {
                  ...stageInfo,
                  occurrences: stageInfo?.occurrences,
                  notes: newVal,
                }),
              },
            })
            }
          />
        </Fragment>
      );
    }
    return locationField;
  });

  /**
 * Get the Patient Registration modal
 * @returns {ReactComponentElement} The proper react component to render as modal content
 */
  const PatientModalContent = useMemo(() =>
    withRouter(PatientRegistrationContainer),
  [patientRegistrationModalVisible]);

  /**
   * Triggers onSaveClicked on props change.
   */
  useEffect(() => {
    if (isFromDocValidationModal
      && isDocValidationModalSaving) {
      onSaveClicked();
    }
  }, [isDocValidationModalSaving]);

  return (
    <>
      <Fragment>
        <div className="o-form">
          {
            errorMessage &&
            <FormError>{errorMessage}</FormError>
          }
          <Select
            id="item_patient"
            label={translate('patient')}
            labelClassName="o-label"
            value={encounterAttrs?.patientId}
            onValueChanged={(value) => {
              if (value === 'create') {
                handleCreateModal('', 'patient', true);
              } else {
                updateAttribute('patientId', value);
              }
            }}
            options={getPatientsOptions()}
            required
            creatable
            disabled={!!patient}
            showNewOptionAtTop={false}
            onCreateOption={(option) => {
              handleCreateModal('', 'patient', true);
            }}
            createOptionPromptFactory={label => translate('add_x_to_patients', { x: label })}
          />
          <EncounterDateTimeContainer>
            <DatePicker
              id="encounterFormDatePicker"
              showClearDate={false}
              allowPast
              label={translate('date')}
              onValueChanged={value => updateAttribute('encounterDate', value || null)}
              value={encounterAttrs?.encounterDate}
              style={{ width: '200px', marginRight: '1rem' }}
              className="_u-padding-bottom--1ws"
              required
            />
            <TimePicker
              id="encounterFormTimePicker"
              value={encounterAttrs?.encounterTime}
              onValueChanged={value => updateAttribute('encounterTime', value || null)}
              label={translate('time')}
              style={{ width: '200px', marginRight: '1rem' }}
              minuteStep={15}
              allowEmpty={false}
            />
          </EncounterDateTimeContainer>
          <Select
            id="item_doctor"
            label={translate('doctor')}
            labelClassName="o-label"
            value={encounterAttrs?.doctorId}
            onValueChanged={(value) => {
              if (value === 'create') {
                handleCreateModal('', 'doctor', true);
              } else {
                updateAttribute('doctorId', value);
              }
            }}
            options={getDoctorsOptions()}
            creatable
            required
            showNewOptionAtTop={false}
            onCreateOption={(option) => {
              handleCreateModal('', 'doctor', true);
            }}
            createOptionPromptFactory={label => translate('add_x_to_doctors', { x: label })}
          />
          <Input
            id="flow_name"
            value={encounterAttrs?.flow?.name}
            disabled
            type="text"
            label={translate('encounter_flow')}
          />
          <Button
            dataPublic
            onClick={() => setFlowFormVisible(true)}
            className="o-button o-button--small"
          >
            {translate('select_or_edit_flow')}
          </Button>
          {renderStageAttributes()}
          {
            patientRegistrationModalVisible &&
            PatientModalContent &&
            <StatelessModal
              id="registerPatient"
              title={translate('register_patient')}
              setVisible={() => {
                setPatientRegistrationModalVisible(false);
              }}
              visible={patientRegistrationModalVisible}
              noButton
              explicitCloseOnly
              onClose={() => setPatientRegistrationModalVisible(false)}
              dataPublicHeader
            >
              <PatientModalContent
                isFromModal={isFromDocValidationModal}
                docId={patientStubId}
                showSaveButton
                onSave={(model) => {
                  if (model) {
                    updateAttribute('patientId', model.get('_id'));
                    handleCreateModal('', 'patient', false);
                  }
                }}
              />
            </StatelessModal>
          }
          {
            doctorRegistrationModalVisible &&
            <StatelessModal
              id="registerDoctor"
              title={translate('add_new_doctor')}
              setVisible={() => {
                setDoctorRegistrationModalVisible(false);
              }}
              visible={doctorRegistrationModalVisible}
              noButton
              explicitCloseOnly
              onClose={() => setDoctorRegistrationModalVisible(false)}
              dataPublicHeader
            >
              <DoctorForm
                config={config}
                onSave={practitioner =>
                  saveModel(practitioner).then((model) => {
                    if (model) {
                      updateAttribute('doctorId', model.get('_id'));
                      handleCreateModal('', 'doctor', false);
                    }
                    return model;
                  })
                }
              />

            </StatelessModal>
          }
          {flowFormVisible &&
            <FlowForm
              stagesMap={stagesMap}
              salesItems={salesItemsMap}
              updateConfig={updateConfig}
              config={config}
              saveModel={saveModel}
              coveragePayors={coveragePayors}
              isVisible={flowFormVisible}
              hideModal={() => setFlowFormVisible(false)}
              selectedFlow={selectedFlow}
              encounterFlowMap={getModelMapFromList(encounterFlows)}
              encounter={getEncounterModelFromAttributes(encounterAttrs)}
              onSave={(encounter, encounterFlow) => handleFlowChange(encounterFlow)}
              editable
            />}
        </div>
      </Fragment>
    </>
  );
};

export default AddEncounterForm;
