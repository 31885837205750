import React from 'react';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import Table from './../table/table';
import { convertNumberToPrice, isLoading } from './../../utils/utils';

import type { Row } from './../../types';

type Props = {
  isFetching: boolean,
  currentDataViewsError?: APIError,
  columns: Array<{ label: string, value: string, renderMethod?: string }>,
  data: Array<{}>,
  onFilterAndSort: (data: Array<Row>) => void,
  initialDataHandler: (data: Array<Row>) => void,
};

/**
 * Renders the component.
 * @param {Props} props The props for the component.
 * @returns {React.Component} The rendered component.
 */
const SuppliesReceivedTable =
  ({
    columns, isFetching, data, onFilterAndSort, initialDataHandler,
    currentDataViewsError,
  }: Props) =>
    <Table
      columns={columns.map(({ value, label, ...rest }) => (
        { accessor: value, Header: label, ...rest }))}
      data={data.map(d =>
        Object.assign(
          {},
          d,
          { supplyItemTotalPrice: convertNumberToPrice(d.supplyItemTotalPrice) },
        ))
        }
      loading={isFetching}
      noDataText={
          translate(currentDataViewsError ? 'error_contact_support' : isLoading(isFetching, 'no_supplies_received_found'))
        }
      showPagination
      defaultSorted={[{ id: 'supplyItemDate', desc: false }]}
      filteredSortedDataHandler={filteredData => onFilterAndSort(filteredData)}
      initialDataHandler={initialData => initialDataHandler(initialData)}
    />;

export default SuppliesReceivedTable;
