import { connect } from 'react-redux';

import ChangeLog, { Props } from './../components/changeLog/changeLog';
import { State } from '../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, { versionNumber }: Props) => ({ versionNumber });

const ChangeLogContainer = connect(mapStateToProps)(ChangeLog);

export default ChangeLogContainer;
