
import BaseModel from './baseModel';

export type Attributes = {
  _id: string,
  type: 'clinic',
  name: string,
  db_name: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
};

/**
 * SMSJobModel
 *
 * @namespace SMSJobModel
 */
class ClinicModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'clinic';
  }
}

export default ClinicModel;
