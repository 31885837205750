import React, { useState } from 'react';

import type { CellInfo, DerivedDataObject, Column, RowInfo } from 'react-table';
import { transformColumnKeys } from './../../utils/utils';

import translate from './../../utils/i18n';
import Table from './../table/table';

import type { Row, CustomColumn } from './../../types';

type Props = {
  columns: Array<Column>,
  data: Array<Row>,
  isFetching: boolean,
  onFilterAndSort: (data: Array<Row>) => void,
  setPage: (index: number, size?: number) => void,
  expanded?: { [index: number]: boolean },
  onPivotRowClick?: (newExpanded:{ [index: number]: boolean | {} }, rowInfo: RowInfo) => void,
};

/**
 * A component that displays StockTakeTable.
 * @param {Props} props The props for this component
 * @returns {React.Component}
 */
const StockTakeTable = (props: Props) => {

  // const [columns, setColumns] = useState(transformColumnKeys(props.columns));
  /**
   * Get table columns
   * @returns {void}
   */
  const getColumns = () => props.columns.map(c => ({
    accessor: c.accessor,
    Header: c.Header,
    Cell: c.Cell,
    PivotValue: c.PivotValue,
    uncustomizable: c.uncustomizable,
    width: c.width,
    filterable: c.filterable,
    aggregate: c.aggregate,
    Aggregated: c.Aggregated,
    headerClassName: c.headerClassName,
    className: c.className,
    filterMethod: c.filterMethod,
    sortMethod: c.sortMethod,
  }));

  return (
    <Table
      columns={getColumns()}
      data={props.data}
      loading={props.isFetching}
      noDataText={translate('no_items')}
      showPagination
      pivotBy={['sku_id']}
      defaultSorted={[{ id: 'added_time', desc: true }]}
      filteredSortedDataHandler={filteredData => props.onFilterAndSort(filteredData)}
      initialDataHandler={initialData => props.onFilterAndSort(initialData)}
      onPageChange={(pageIndex: number) => {
        props.setPage(pageIndex);
      }}
      onPageSizeChange={(pageSize: number, pageIndex: number) => {
        props.setPage(pageIndex, pageSize);
      }}
      showFixedColumns
      expanded={props.expanded}
      onPivotRowClick={props.onPivotRowClick}
    />
  );
};

export default StockTakeTable;
