import React from 'react';
import glamorous from 'glamorous';
import type { List } from 'immutable';
import SummaryItem from './summaryItem';
import type SummaryItemModel from '../../models/analytics/summaryItemModel';

import { wsUnit } from './../../utils/css';

type Props = {
  title?: string,
  items: List<SummaryItemModel>
};

const ListContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridColumnGap: wsUnit,
  margin: '1.33rem calc(1.5 * 1.33rem)',
});

/**
 * Renders the list of summary metrics passed to this along with an optional title
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function SummaryList(props: Props) {
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  const { title, items } = props;
  return (
    <div className="summary-List">
      { title && <h1 className="o-title">{title}</h1> }
      <ListContainer>
        { items.map(item =>
          <SummaryItem
            key={item.get('_id')}
            title={item.get('title')}
            value={item.get('value')}
            changePercent={item.get('change_percent')}
          />) }
      </ListContainer>
    </div>
  );
}

export default SummaryList;
