import { List } from 'immutable';
import { logMessage, debugPrint } from './logging';

import type { MapValue, Model } from './../types';


const forge = require('node-forge');

forge.options.usePureJavaScript = true;

/**
 * generateSalt
 * @returns {MapValue}
 */
export const generateSalt = () => forge.random.getBytesSync(128);

/**
 * generateIV
 * @returns {MapValue}
 */
export const generateIV = () => forge.random.getBytesSync(16);

/**
 * generate a password-based 16-byte key
 * @param {MapValue} password the password
 * @param {MapValue} salt random key
 * @returns {MapValue}
 */
export const generateEncryptionKey = (password: MapValue, salt: MapValue) =>
  forge.pkcs5.pbkdf2(password, salt, 10000, 16);

/**
 * generate a create Buffer from Hex
 * @param {MapValue} hex hex value
 * @returns {ByteBuffer}
 */
export const createBufferFromHex = (hex: string) =>

  forge.util.createBuffer(forge.util.hexToBytes(hex));

/**
 * Serializes and Encrypts given data using AES-CBC
 * @param {object} data The data object to encrypt
 * @param {string} key The generated Key
 * @param {string} iv The initializing vector
 * @param {string} type The type of data being encrypted
 * @param {string} userId The current user Id as we cannot get it directly from redux as it's called from reducers
 * @returns {string}
 */
export function encryptData(data: List<Model> | Array<>,
  key: string, iv: string, type: string, userId: string) {
  try {
    const cipher = forge.cipher.createCipher('AES-CBC', key);
    const serializedState = JSON.stringify(data);
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(serializedState, 'utf8'));
    cipher.finish();

    return cipher.output;
  } catch (error) {
    debugPrint(error ? error.toString() : error, 'error', userId);
    if (type === 'STATE') {
      logMessage('There was an error while encrypting redux state');
    } else if (type === 'UNSYNCED_MODELS') {
      logMessage(`There was an error while encrypting model: id: ${data.get('_id')} type: ${data.get('type')}`);
    }
    logMessage('There was an error while encrypting data');
    return encryptData(List(), key, iv, type);
  }
}

/**
 * Decrypts given data using AES-CBC
 * @param {string} encrypted The encrypted State object
 * @param {string} key The generated Key
 * @param {string} iv The encrypted State object
 * @returns {object}
 */
function decrypt(encrypted: string, key: string, iv: string) {
  const decipher = forge.cipher.createDecipher('AES-CBC', key);
  decipher.start({ iv });
  decipher.update(encrypted);
  decipher.finish();

  return decipher;
}

/**
 * Decrypts given state data using AES-CBC
 * @param {string} encrypted The encrypted State object
 * @param {string} key The generated Key
 * @param {string} iv The encrypted State object
 * @param {string} userId The current user Id as we cannot get it directly from redux as it's called from reducers
 * @returns {object}
 */
export function decryptState(encrypted: string, key: string, iv: string, userId: string) {
  try {
    const decrypted = decrypt(encrypted, key, iv).output;
    return JSON.parse(decrypted);
  } catch (error) {
    debugPrint(error ? error.toString() : error, 'error', userId);
    logMessage('There was an error while decrypting redux state');
    return null;
  }
}

/**
 * Decrypts given models data using AES-CBC
 * @param {string} encrypted The encrypted State object
 * @param {string} key The generated Key
 * @param {string} iv The encrypted State object
 * @returns {model} model data
 */
export function decryptModels(encrypted: string, key: string, iv: string) {
  try {
    const decrypted = decrypt(encrypted, key, iv).output;
    return JSON.parse(decrypted);
  } catch (error) {
    debugPrint(error ? error.toString() : error, 'error');
    logMessage('There was an error while decrypting Unsynced Models');
    return null;
  }
}

/**
 * Stores the key and salt in the session storage
 * @param {string} username User name
 * @param {string} key The generated Key
 * @param {string} salt The generated salt
 * @returns {object}
 */
export function setEncryptionKeyAndSalt(username: string, key: string, salt: string) {
  sessionStorage.setItem('encrypt_key', key);
  localStorage.setItem(`${username}_encrypt_salt`, salt);
}

/**
 * clears the key and salt in the session storage
 * @returns {object}
 */
export function clearEncryptionKey() {
  sessionStorage.removeItem('encrypt_key');
}

/**
 * Returns generated MD5 digest
 * @param {string} text text to generate digest
 * @param {string} salt salt to generate digest
 * @returns {string} Generated Digest
 */
export const generateMD5Digest = (text: string, salt: string = '') => {
  const md = forge.md.md5.create();
  md.update(text + salt);
  return md.digest().toHex();
};
