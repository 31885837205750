import React from 'react';
import moment, { Moment } from 'moment';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import DatePicker from './../inputs/statefulDatepicker';
import Input from './../inputs/input';
import TimePicker from './../inputs/timepicker';
import SaveButton from './../buttons/saveButton';
import { isEmailValid } from './../../utils/utils';
import { isDateTimeEqualTimestamp, isToday } from './../../utils/time';
import { trackEvent, CLICK, APPOINTMENT } from './../../utils/analytics';
import ModalFooter from './../modals/modalFooter';
import { getQueueConfirmModalMessage } from '../patientList/patientSearchResultList';
import EncounterModel from '../../models/encounterModel';
import PatientModel from '../../models/patientModel';
import Confirm from '../prompts/confirm';


type Props = {
  onSave: (date: Moment, time: Moment, email: string) => Promise<boolean>,
  email: string,
  timestamp?: number,
  emailDescription?: string,
  consultationMode?: 'tele_consult' | 'walk_in' | undefined,
  isSaving?: boolean,
  encounters: List<EncounterModel>,
  patient: PatientModel
};

type State = {
  date: Moment,
  time: Moment,
  email: string,
  isSaving: boolean,
  errorMessage?: string | boolean,
  showConfirmModal: boolean,
};

/**
   * Form for creating Time Chits.
   *
   * @namespace AppointmentForm
   */
class AppointmentForm extends React.Component<Props, State> {
  /**
   * Creates an instance of AppointmentForm.
   * @param {Props} props props
   * @return {void}
   */
  constructor(props: Props) {
    super(props);
    const timestamp = props.timestamp || moment().minute(Math.ceil((moment().minute()) / 15) * 15).add('15', 'm').valueOf();
    this.state = {
      isSaving: false,
      errorMessage: false,
      date: moment(timestamp),
      time: moment(timestamp),
      email: props.email || '',
      showConfirmModal: false,
    };
  }

  /**
   * Check if E-consult form is valid or not.
   * @returns {boolean} true if valid else false
   */
  isFormValid() {
    if (!this.state.date) {
      this.setState({
        errorMessage: translate('please_select_valid_date'),
      });
      return false;
    }
    if (!this.state.time) {
      this.setState({
        errorMessage: translate('fill_time_and_in_correct_format'),
      });
      return false;
    }
    if (!isEmailValid(this.state.email)) {
      this.setState({
        errorMessage: translate('fill_email_in_correct_format'),
      });
      return false;
    }
    return true;
  }

  /**
  * Call on save of E consult form submit
  * @return {undefined}
  */
  onSaveClicked() {
    if (this.isFormValid()) {
      const currentEncountersForPatient = this.props.encounters
        .filter(m => m.get('patient_id') === this.props.patient.get('_id') && m.isCurrent());
      const appointmentTime = moment(this.state.date)
        .hour(this.state.time.hour())
        .minute(this.state.time.minute())
        .second(0)
        .valueOf();
      if (currentEncountersForPatient.size > 0 && isToday(appointmentTime)) {
        this.setState({
          showConfirmModal: true,
        });
        return Promise.reject();
      }
      this.setState({ isSaving: true });
      this.props.onSave(this.state.date, this.state.time, this.state.email)
        .then(() => {
          const timestamp = moment().minute(Math.ceil((moment().minute()) / 15) * 15).add('15', 'm').valueOf();
          this.setState({
            time: moment(timestamp),
            date: moment(timestamp),
            email: '',
            isSaving: false,
            errorMessage: undefined,
          });
        })
        .catch(() => {
          this.setState({
            isSaving: false,
          });
        });
    }
  }

  /**
   * set email to state.
   * @param { string } email email address
   * @returns {void}
   */
  setemail(email: string) {
    this.setState({ email });
  }

  /**
   * Triggers a batch data sync status check when the inventory count changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    if (this.props.email !== prevProps.email) {
      this.setemail(this.props.email);
    }
  }

  /**
   * Renders the component.
   *
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <React.Fragment>
        <section className="o-form">
          {this.state.errorMessage && <div className="o-form__text-block o-form__text-block--error">{this.state.errorMessage}</div>}
          <DatePicker
            id="date"
            value={this.state.date}
            onValueChanged={(date) => { this.setState({ date }); }}
            allowPast={false}
            label={translate('date')}
            required
            showClearDate
          />
          <TimePicker
            id="time"
            value={this.state.time}
            onValueChanged={(time) => {
              this.setState({ time });
            }}
            label={translate('time')}
            style={{ width: '150px', marginTop: '1rem' }}
            minuteStep={15}
            allowEmpty={false}
          />
          <Input
            id="email_address"
            value={this.state.email}
            label={translate('patient_email_address')}
            description={this.props.emailDescription || ''}
            placeholder={translate('email_address')}
            onValueChanged={(email: string) => { this.setemail(email); }}
          />
        </section>
        <ModalFooter>
          <SaveButton
            onClick={() => {
              if (this.props.consultationMode === 'tele_consult') {
                trackEvent(APPOINTMENT, CLICK, 'schedule_econsult');
              } else {
                trackEvent(APPOINTMENT, CLICK, 'schedule_walkin');
              }
              this.onSaveClicked();
            }}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
            dataPublic
            isSaving={this.state.isSaving || this.props.isSaving}
            disabled={
              this.props.timestamp
              && isDateTimeEqualTimestamp(this.state.date, this.state.time, this.props.timestamp)
            }
          />
        </ModalFooter>
        <Confirm
          show={this.state.showConfirmModal}
          cancel={() => {
            this.setState({
              showConfirmModal: false,
            });
          }}
          proceed={() => {
            this.setState({
              showConfirmModal: false,
            });
          }}
          confirmation={getQueueConfirmModalMessage(this.props.patient.get('_id'), () => this.setState({ showConfirmModal: false }), true)}
          modalTitle={translate('patient_cannot_be_scheduled')}
          footerSaveButtonName={translate('acknowledge')}
          hideCancel
        />
      </React.Fragment>
    );
  }
}

export default AppointmentForm;
