/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
/* eslint-disable camelcase */
import React, { ReactElement, Fragment } from 'react';
import { List } from 'immutable';
import glamorous, { Hr } from 'glamorous';
import moment, { Moment } from 'moment';

import StatelessModal from './../modals/statelessModal';
import { checkPrescriptionInteractions, getDACDrugNames, getDACDrugNamesMessage } from '../../utils/prescriptions';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import PrescriptionModel from './../../models/prescriptionModel';
import SaveButton from './../buttons/saveButton';
import AddEditPrescriptionsItem from './addEditPrescriptionsItem';
import { generateGUID, singularizeWord } from './../../utils/utils';
import { sortByNumber } from './../../utils/comparators';
import { debugPrint } from './../../utils/logging';
import { wsUnit, colours, fonts } from './../../utils/css';
import FormError from './../formError';
import ModalFooter from './../modals/modalFooter';
import { updateClinicConfig } from './../../utils/db';
import Button from './../buttons/button';
import AutoClosablePrompt from '../prompts/autoClosablePrompt';
import UnsavedDataModal from '../../components/prompts/unsavedDataModal';
import { getStore } from '../../utils/redux';
import { getMDLInfo } from '../../utils/inventory';
import Confirm from './../prompts/confirm';

import DrugModel from './../../models/drugModel';
import type EncounterModel from './../../models/encounterModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, SaveModel, PrescriptionAttributes, InventoryCount, User, MapValue, SaveModels, SelectOption } from './../../types';
import InventoryMapModel from './../../models/inventoryMapModel';
import AllergyModel from './../../models/allergyModel';
import PatientAllergyBar from '../patient/patientAllergyBar';
import { CLICK, DRUG_ALLERGY_CHECKER, trackEvent } from '../../utils/analytics';
import { DIC_ENABLED, DATE_FORMAT_TO_SAVE } from '../../constants';
import DosingRegimenModel from './../../models/dosingRegimenModel';
import PractitionerModel from './../../models/practitionerModel';
import Select from '../inputs/select';
import PrescriptionHistoryList from './prescriptionsHistoryList';

const AlertTitle = glamorous.span({
  color: colours.red,
  fontFamily: fonts.bold,
});

type Props = {
  config: Config,
  klinifyConfig: Config,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  encounters: List<EncounterModel>,
  inventoryCount: InventoryCount,
  prescriptions: List<PrescriptionModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  useTextButton: boolean,
  isOnline: boolean,
  coveragePayorID?: string,
  coveragePayors: List<CoveragePayorModel>,
  isPrescriptionsModalVisible?: boolean,
  handlePrescriptionsModalVisible?: (state: boolean) => void,
  hidePrescriptionsModalButton?: boolean,
  user: User,
  isFromCurrentEncounter?: boolean,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  isFromDocValidationModal: boolean,
  isDocValidationModalSaving: boolean,
  onSaveAtDocValidationModal: (wasSuccessful: boolean) => void,
  validationDocObject?: { id: string, type: string }
  verifiedDrugs: List<InventoryMapModel>,
  allergies: List<AllergyModel>,
  dosingRegimens: List<DosingRegimenModel>,
  patientPrescriptionHistory: List<PrescriptionModel>,
  practitioners: List<PractitionerModel>,
};

type State = {
  isSaving: boolean,
  modalVisible: boolean,
  prescriptions: List<PrescriptionAttributes>,
  prescriptionHistory: [],
  errorMessage?: string,
  changesMade: boolean,
  showPrompt: boolean,
  proceedMessage?: string | ReactElement,
  interactedDrugs: List<DrugModel>,
  autoCloseModalDetail?: { title: string, message: string | ReactElement }
}

/**
 * Returns a List of Prescriptions derived from the given PrescriptionModels, or seeds a new List
 * with a blank prescription if no prescriptionModels given.
 * @param {List<PrescriptionModel>} prescriptions A list of prescription Models
 * @returns {List<Prescription>} The Prescription objects to be provided to the component state.
 */
function mapPrescriptionModelsToState(
  prescriptions: List<PrescriptionModel>,
): List<PrescriptionAttributes> {
  if (prescriptions.size === 0) {
    return List([{
      _id: generateGUID(), // Generate an ID so react doesn't lose its mind when we delete things.
      prescription_group_order: 0,
    }]);
  }
  return List(prescriptions.map(p => Object.assign({}, p.attributes)));
}

/**
 * Returns an array of grouped prescriptions by doctor and encounter id
 *  to be populated on repeat prescription dropdown
 * @param {List<PrescriptionModel>} patientPrescriptionHistory A list of prescription Models
 * @param {List<EncounterModel>} currentEncounter Current encounter
 * @returns {MapValue} the grouped prescriptions
 */
function aggregatePrescriptionsByEncounter(patientPrescriptionHistory: List<PrescriptionModel>, currentEncounter: EncounterModel): MapValue {
  const uniqueEncounterIds = [...new Set(patientPrescriptionHistory?.filter(c => c.get('practitioner_id') !== undefined).map(c => c.get('encounter_id')))];
  return uniqueEncounterIds.filter(e => e !== currentEncounter.get('_id')).map((ecId: string) =>
    ({
      encounter_id: ecId,
      practitioner_id: patientPrescriptionHistory.find(p => p.get('encounter_id') === ecId)?.get('practitioner_id'),
      prescriptions: Array.from(patientPrescriptionHistory.filter(p => p.get('encounter_id') === ecId).map(m => m.attributes)),
    }));
}

/**
 * A modal for editing prescriptions.
 * @class AddEditPrescriptions
 * @extends {React.Component}
 */
class AddEditPrescriptions extends React.Component<Props, State> {
  static defaultProps = {
    useTextButton: false,
    hidePrescriptionsModalButton: false,
  };

  /**
   * Creates an instance of AddEditPrescriptions.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      modalVisible: false,
      prescriptions: mapPrescriptionModelsToState(props.prescriptions),
      changesMade: false,
      showPrompt: false,
      proceedMessage: undefined,
      interactedDrugs: List(),
      prescriptionHistory: aggregatePrescriptionsByEncounter(props.patientPrescriptionHistory, props.encounter),
    };
    this.addDrugsFromPreviousPrescriptions = this.addDrugsFromPreviousPrescriptions.bind(this);
  }

  /**
   * Triggers the saving function when the isSaving prop changes
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isFromDocValidationModal
      && this.props.isDocValidationModalSaving !== prevProps.isDocValidationModalSaving
      && this.props.isDocValidationModalSaving) {
      this.onSave();
    }
  }

  /**
   * Update the state with the new prescriptions if they have changed, otherwise do nothing.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.prescriptions.equals(this.props.prescriptions)) {
      this.setState({ prescriptions: mapPrescriptionModelsToState(nextProps.prescriptions) });
    }
  }

  /**
   * Does all the state cleanups after onsave is clicked
   * @returns {void}
   */
  handleClearState() {
    if (this.props.handlePrescriptionsModalVisible) {
      this.props.handlePrescriptionsModalVisible(false);
    }
    this.setState({
      isSaving: false,
      modalVisible: false,
      showPrompt: false,
      errorMessage: undefined,
      changesMade: false,
    });
  }

  /**
   * Check whether to show the allergy interaction will not work if any of the selected drugs is not verified.
   * @param {List<PrescriptionAttributes>} prescriptions Next props
   * @returns {List<PrescriptionAttributes>}
   */
  getVerifiedPrescriptions(prescriptions: List<PrescriptionAttributes>) {
    return prescriptions.filter(p => !p._deleted && p.drug_id && this.props.verifiedDrugs.find(d => d.get('drug_id') === p.drug_id));
  }

  /**
   * Return the title and message of the auto close model
   * @param {boolean} hasOnlymappedDrugPrescribed Fasle if unmapped drugs are prescribed
   * @returns {MapValue}
   */
  getAutoCloseModalDetails(hasOnlymappedDrugPrescribed: boolean = false) {
    const uspecifiedAllergies = this.props.allergies.filter(a => a.isMedicationAndUnspecified());
    const drugNameList = uspecifiedAllergies.map(d => `"${d.get('name')}"`).toArray();
    const drugNames = getDACDrugNames(drugNameList);
    if (DIC_ENABLED && uspecifiedAllergies.size > 0 && !hasOnlymappedDrugPrescribed) {
      return {
        title: `${translate('drug_unmapped')} & ${translate('drug_unspecified_in_allergy')}`,
        message: (
          <>
            <div>{translate('drug_unmapped_warning', { user: this.props.user.get('id') })}</div><br />
            {translate('not_verified_warings')}
            <hr />
            {translate('patient_has_a_allergy', { drugNames })}
            <br />
            <br />
            {translate('to_check_reaction_bw_drugs')}
          </>
        ),
      };
    }
    if (!hasOnlymappedDrugPrescribed) {
      return {
        title: translate('drug_unmapped'),
        message: (
          <>
            <div>{translate('drug_unmapped_warning', { user: this.props.user.get('id') })}</div>
            <br />
            {translate('not_verified_warings')}
          </>
        ),
      };
    }
    if (DIC_ENABLED && uspecifiedAllergies.size > 0) {
      return {
        title: translate('drug_unspecified_in_allergy'),
        message: (
          <>
            {translate('patient_has_a_allergy', { drugNames })}
            <br />
            <br />
            {translate('to_check_reaction_bw_drugs')}
          </>
        ),
      };
    }
  }

  /**
   * Saves the changes to the prescriptions.
   * @returns {void}
   */
  handleSave() {
    this.setState({ isSaving: true });
    const prescriptionGroupID = new Date().getTime();
    // Convert prescription data changes to models.
    const models = this.state.prescriptions
      .filter(prescription =>
        !(prescription._deleted && (!prescription._rev && this.props.isOnline))) // Filter out items that were added and deleted before an initial save.
      .map(prescription =>
        new PrescriptionModel(Object.assign({}, prescription, {
          patient_id: this.props.encounter.get('patient_id'),
          encounter_id: this.props.encounter.get('_id'),
          practitioner_id: this.props.encounter.getValue('doctor'),
          prescription_group_id: prescriptionGroupID,
        })));
    if (models && this.props.saveModels) {
      this.props.saveModels(models.toArray(), () => this.handleSave())
        .then((savedModels) => {
          if (savedModels.some(model => model === null)) {
            if (this.props.onSaveAtDocValidationModal) {
              this.props.onSaveAtDocValidationModal(false);
            }
            return savedModels;
          }
          if (this.props.onSaveAtDocValidationModal) {
            this.props.onSaveAtDocValidationModal(true);
          }
          if (savedModels.length > 0) {
            createSuccessNotification(translate('prescription_creation_success'));
          }
          this.handleClearState();
          return savedModels;
        });
    }
    this.setState({ errorMessage: undefined, changesMade: false, proceedMessage: undefined, interactedDrugs: List() });
  }

  /**
   * on acknowledge clicked
   * @returns {void}
   */
  onAcknowledge() {
    const filteredPrescriptions = this.state.prescriptions.map((p: PrescriptionAttributes) => {
      const drug = this.state.interactedDrugs.find(d => d.get('_id') === p.drug_id);
      if (drug) {
        return Object.assign({}, p, { _deleted: true });
      }
      return p;
    });
    this.setState({
      prescriptions: filteredPrescriptions,
    }, () => {
      this.onProceed();
    });
  }

  /**
   * Calls on proceed
   * @returns {void}
   */
  onProceed() {
    if (this.props.klinifyConfig.getIn(['prescription', 'allowPopup'])) {
      const store = getStore();
      const hasOnlymappedDrugPrescribed = this.state.prescriptions.filter(p => !p._deleted)
        .every((prescription) => {
          if (!prescription.drug_id) return true;
          const mdlInfo = getMDLInfo(store, prescription.drug_id);
          return !!mdlInfo;
        });
      const details = this.getAutoCloseModalDetails(hasOnlymappedDrugPrescribed);
      if (details) {
        if (this.props.handlePrescriptionsModalVisible) {
          this.props.handlePrescriptionsModalVisible(false);
        }
        this.setState({
          autoCloseModalDetail: details,
          modalVisible: false,
          isSaving: false,
          errorMessage: undefined,
          proceedMessage: undefined,
          interactedDrugs: List(),
        });
      } else {
        this.handleSave();
      }
    } else {
      this.handleSave();
    }
  }

  /**
   * Check for unmapped drugs in prescription if any and shows alert if exists
   * @returns {void}
   */
  onSave = () => {
    const { isValid, error } = this.prescriptionsAreValid();
    if (isValid) {
      if (!DIC_ENABLED) {
        return this.onProceed();
      }
      const prescriptionIds =
        this.getVerifiedPrescriptions(this.state.prescriptions)
          .map(p => p.drug_id)
          .filter(id => !!id) as List<string>;
      this.setState({ isSaving: true });
      checkPrescriptionInteractions(prescriptionIds, this.props.allergies)
        .then(({ isError, drugs }: { isError: boolean, drugs: List<string> }) => {
          this.setState({ isSaving: false });
          if (drugs.size > 0 && !isError) {
            const drugModels = drugs.map((i: string) =>
              this.props.drugs.find(d => d.get('_id') === i)).filter(a => !!a) as List<DrugModel>;
            const proceedMessage = getDACDrugNamesMessage(drugModels);
            this.setState({
              proceedMessage,
              interactedDrugs: drugModels,
            });
            return;
          }
          this.onProceed();
        });
    } else if (error) {
      this.setState({ errorMessage: translate(error), showPrompt: false });
    } else {
      this.setState({ errorMessage: translate('drug_fields_cannot_be_empty_please_fill_out_the_field'), showPrompt: false });
    }
  }

  /**
   * Returns true if each prescription has a drug id or is marked for deletion.
   * @returns {object} Validity of prescriptions.
   */
  prescriptionsAreValid(): { isValid: boolean, error?: string } {
    return {
      isValid: this.state.prescriptions
        .every(prescription => (
          (
            (prescription.dosing_regimen_id ? !!prescription.start_date : true) &&
            prescription.drug_id &&
            prescription.drug_id !== this.props.validationDocObject?.id &&
            prescription.sale_quantity !== undefined && prescription.sale_quantity > 0 &&
            prescription.sale_price !== undefined && Math.abs(prescription.sale_price) >= 0
          ) || !!prescription._deleted
        )),
    };
  }

  /**
   * Resets the modal state.
   * @returns {void}
   */
  handleCancel() {
    this.setState({
      errorMessage: undefined,
      modalVisible: false,
      changesMade: false,
      prescriptions: mapPrescriptionModelsToState(this.props.prescriptions), // Reset state
      proceedMessage: '',
    });
    if (this.props.handlePrescriptionsModalVisible) {
      this.props.handlePrescriptionsModalVisible(false);
    }
  }

  /**
   * Overrides so that rerender doesn't occur while saving.
   * @param {Props} nextProps Next props.
   * @param {State} nextState Next state.
   * @returns {boolean} Should component update.
   */
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (this.state.isSaving && nextState.isSaving) {
      return false; // Because we need to save synchronously, the form will change in the bg while saving as models get altered in the db. Disabling render until save is complete prevents this.
    }
    return true;
  }

  /**
   * Get the next order number for this prescription group.
   * @returns {number} The next order value.
   */
  getNextOrder(): number {
    return this.state.prescriptions
      .maxBy(p => p.prescription_group_order).prescription_group_order + 1;
  }

  /**
   * Add drugs from previous prescriptions
   * @param {List<PrescriptionAttributes>} previousDrugs previous drugs
   * @returns {void}
   */
  addDrugsFromPreviousPrescriptions(previousDrugs: MapValue): void {
    const { prescriptions } = this.state;
    let newDrugs = Array.from(prescriptions);
    if (prescriptions.size === 1 && prescriptions.first<PrescriptionAttributes>().drug_id === undefined) {
      newDrugs = [];
    }

    // they can prescribe the same item multiple times within the same consultation with differing dosage,frequency or duration
    previousDrugs.forEach((p) => {
      if (!newDrugs.find(n => n.drug_id === p.drug_id && n.prescribed_dosage === p.prescribed_dosage &&
          n.prescribed_frequency === p.prescribed_frequency && n.prescribed_duration === p.prescribed_duration &&
          p.is_previous_prescribed && !n._deleted)) {
        p._id = generateGUID();
        p.prescription_group_order = newDrugs.length ? this.getNextOrder() : 0;
        newDrugs.push(p);
      }
    });
    this.setState({ prescriptions: List(newDrugs) });
  }

  /**
   * Handles saving of drug duration in config
   * @param {string} value drug duration value
   * @returns {Array<Array<number, string>>}
   */
  updateDrugDurationInConfig(value: string) {
    const { updateConfigValue, updateConfig, config } = this.props;
    const drugDurations = config.getIn(['prescription', 'durations'], List()).filter(e => e !== null && e !== undefined);
    const stringifiedDrugDurations = drugDurations.map(d => d.join(' ')).toArray();
    const newValue = singularizeWord(value);
    const newValueToArray = newValue.split(' ');
    if (newValueToArray.filter(v => v.length).length !== 2) {
      this.setState({ errorMessage: translate('invalid_drug_duration_format') });
      return undefined;
    }
    if (isNaN(Number(newValueToArray[0]))
      || !['day', 'days', 'week', 'weeks', 'month', 'months'].includes(newValueToArray[1])) {
      this.setState({ errorMessage: translate('invalid_drug_duration_format') });
      return undefined;
    }
    const newDrugDuration = [Number(newValueToArray[0]), newValueToArray[1]];
    if (!stringifiedDrugDurations.includes(newValue)) {
      const updatedConfig = config
        .setIn(['prescription', 'durations'], drugDurations.push(List(newDrugDuration)))
        .toJS();
      updateClinicConfig(updatedConfig, updateConfig);
      updateConfigValue(['prescription', 'durations'], drugDurations.push(List(newDrugDuration)));
    }
    return newDrugDuration;
  }

  /**
   * @returns {ReactElement}
   */
  getAddPrescriptionsItemsToRender() {
    const { prescriptionHistory, prescriptions } = this.state;
    return (
      <Fragment>
        {this.props.allergies.filter(a => a.isVisible() && a.isFlagged()).size > 0 &&
          <PatientAllergyBar
            user={this.props.user}
            allergies={this.props.allergies}
            drugs={this.props.drugs}
          />
        }
        {/* <InfoBar message={translate('not_verified_warings')} /> */}
        <section className="o-form">
          {this.state.errorMessage &&
            <FormError isSticky>{this.state.errorMessage}</FormError>
          }
          <UnsavedDataModal
            visible={this.state.showPrompt}
            onSave={this.onSave}
            onDiscard={() =>
              this.setState({
                modalVisible: false,
                showPrompt: false,
                changesMade: false,
                prescriptions: mapPrescriptionModelsToState(
                  this.props.prescriptions,
                ),
                proceedMessage: undefined,
                errorMessage: '',
                interactedDrugs: List(),
              })
            }
            onCancel={() => this.setState({ showPrompt: false })}
          />
          <Confirm
            show={Boolean(this.state.proceedMessage)}
            proceed={() => {
              trackEvent(DRUG_ALLERGY_CHECKER, CLICK, 'prescription_remove_and_acknowledge');
              this.onAcknowledge();
            }}
            cancel={() => {
              trackEvent(DRUG_ALLERGY_CHECKER, CLICK, 'prescription_continue_prescribing');
              this.onProceed();
            }}
            onClose={() => {
              trackEvent(DRUG_ALLERGY_CHECKER, CLICK, 'prescription_close');
              this.setState({
                proceedMessage: undefined,
                showPrompt: false,
                interactedDrugs: List(),
              });
            }}
            modalTitle={translate('alert')}
            footerSaveButtonName={translate('acknowledge_and_continue')}
            footerCancelButtonName={translate('continue_prescribing')}
            confirmation={this.state.proceedMessage}
          />
          {
            prescriptionHistory && prescriptionHistory.length > 0 &&
            <PrescriptionHistoryList
              coveragePayorID={this.props.coveragePayorID}
              prescriptionHistory={prescriptionHistory}
              practitioners={this.props.practitioners}
              drugs={this.props.drugs}
              addDrugsFromPreviousPrescriptions={this.addDrugsFromPreviousPrescriptions}
              currentEncounter={this.props.encounter}
              config={this.props.config}
              encounters={this.props.encounters}
            />
          }
          <h2 style={{ fontFamily: 'robotobold' }}>Select New Drugs</h2>
          <br />
          {
            prescriptions
              .sort(
                (a, b) => sortByNumber(a.prescription_group_order, b.prescription_group_order),
              )
              .filter(prescription => !prescription._deleted)
              .map((p) => {
                const obj = Object.assign({}, p, { ...(!p.start_date && { start_date: moment().format(DATE_FORMAT_TO_SAVE) }) });
                return obj;
              })
              .map((prescription, index) =>
                <AddEditPrescriptionsItem
                  key={prescription._id}
                  prescription={prescription}
                  dosingRegimens={this.props.dosingRegimens}
                  drugs={this.props.drugs}
                  isFirstChild={index === 0}
                  config={this.props.config}
                  coveragePayors={this.props.coveragePayors}
                  saveModel={this.props.saveModel}
                  inventoryCount={this.props.inventoryCount}
                  coveragePayorID={this.props.coveragePayorID}
                  onItemUpdated={(newValue: PrescriptionAttributes, key: string) => {
                    if (typeof newValue.prescribed_duration === 'string') {
                      Object.assign(newValue, {
                        prescribed_duration: this.updateDrugDurationInConfig(
                          newValue.prescribed_duration,
                        ),
                      });
                    }
                    if (newValue.dosing_regimen_id) {
                      const dosingRegimen = this.props.dosingRegimens.find(m => m.get('_id') === newValue.dosing_regimen_id);
                      const adminDesc = () => {
                        if (key === 'admin_desc') {
                          return newValue.admin_desc;
                        } else if (newValue.dosing_regimen_id !== prescription.dosing_regimen_id) {
                          return prescription.admin_desc || (dosingRegimen && dosingRegimen?.get('administration_description'));
                        }
                        return prescription.admin_desc;
                      };
                      if (prescription) {
                        Object.assign(newValue,
                          {
                            dosing_admin_time: dosingRegimen && dosingRegimen?.get('administration_time') ?
                              dosingRegimen?.get('administration_time') : '',
                            admin_desc: (key === 'admin_desc') ? newValue.admin_desc :
                              (prescription.admin_desc || (dosingRegimen && dosingRegimen?.get('administration_description'))),
                          });
                      }
                    }
                    if (newValue.drug_id !== prescription.drug_id) {
                      const drug = this.props.drugs.find(model => model.get('_id') === newValue.drug_id);
                      const isAtdpsDrugIntegrationEnabled = !!drug?.get('integrations')?.omnihealth?.enabled;
                      const dosingRegimenId = isAtdpsDrugIntegrationEnabled && drug?.get('integrations')?.omnihealth?.dosing_regimen_id;
                      const dosingRegimen = dosingRegimenId && this.props.dosingRegimens.find(m => m.get('_id') === dosingRegimenId);
                      if (drug) {
                        Object.assign(newValue,
                          {
                            prescribed_frequency: drug.get('frequency'),
                            reason: (drug.get('indications') && drug.get('indications').length) ? drug.get('indications').toString() : '',
                            notes: drug.get('notes'),
                            ...(isAtdpsDrugIntegrationEnabled && dosingRegimen && { dosing_regimen_id: dosingRegimenId }),
                            ...(isAtdpsDrugIntegrationEnabled && dosingRegimen && { dosing_admin_time: dosingRegimen?.get('administration_time') }),
                            ...(!prescription.admin_desc && isAtdpsDrugIntegrationEnabled && dosingRegimen && { admin_desc: dosingRegimen?.get('administration_description') }),
                          });
                      }
                    }
                    const newPrescriptions = this.state.prescriptions
                      .filter(p => p._id !== newValue._id) // Replace value
                      .push(newValue);
                    this.setState({ prescriptions: newPrescriptions, changesMade: true });
                  }}
                  user={this.props.user}
                  isFromCurrentEncounter={this.props.isFromCurrentEncounter}
                  updateConfigValue={this.props.updateConfigValue}
                  updateConfig={this.props.updateConfig}
                  drugDurations={this.props.config.getIn(['prescription', 'durations'], List()).filter(e => e !== null && e !== undefined)}
                  validationDocObject={this.props.validationDocObject}
                  isFromDocValidationModal={this.props.isFromDocValidationModal}
                />)
          }
          <Button
            className="o-button o-button--small u-margin--standard"
            style={{
              width: `calc(40% - 50px - ${wsUnit} - ${wsUnit} - ${wsUnit})`,
              margin: wsUnit,
              alignSelf: 'center',
            }}
            onClick={() => this.setState({
              prescriptions: this.state.prescriptions.push({
                _id: generateGUID(), // Generate an ID so react doesn't lose its mind when we delete things.
                prescription_group_order: this.state.prescriptions.size ? this.getNextOrder() : 0,
              }),
            })}
            dataPublic
          >{translate('add_new_prescription_item')}
          </Button>
        </section>
        <div className="u-margin-bottom--4ws" />
        {
          !this.props.isFromDocValidationModal &&
          <ModalFooter>
            <SaveButton
              dataPublic
              onClick={() => this.onSave()}
              isSaving={this.state.isSaving}
              label={translate('save')}
              className="o-button--small u-margin-right--half-ws"
            />
          </ModalFooter>
        }
      </Fragment>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <>
        {
          this.props.isFromDocValidationModal ?
            this.getAddPrescriptionsItemsToRender() :
            <StatelessModal
              id={`addEditPrescriptionsModal-${this.props.encounter.get('_id')}`}
              buttonLabel={(this.props.prescriptions.size > 0 ? translate('edit') : translate('prescribe'))}
              noButton={this.props.hidePrescriptionsModalButton}
              buttonClass={this.props.useTextButton ? 'o-text-button o-text-button--contextual' : 'o-button o-button--padded o-button--small u-width-180'}
              title={translate('prescriptions')}
              setVisible={(isVisible: boolean) => {
                if (this.state.changesMade &&
                  (this.props.isPrescriptionsModalVisible || this.state.modalVisible) && !isVisible) {
                  this.setState({ showPrompt: true });
                } else {
                  this.setState({ modalVisible: isVisible });
                }
              }}
              visible={this.props.isPrescriptionsModalVisible || this.state.modalVisible}
              large
              onClose={() => {
                if (!this.state.changesMade) {
                  this.handleCancel();
                } else {
                  this.setState({ showPrompt: true });
                }
              }}
              explicitCloseOnly
              dataPublicHeader
            >
              {this.getAddPrescriptionsItemsToRender()}
            </StatelessModal>
        }
        <AutoClosablePrompt
          visible={Boolean(this.state.autoCloseModalDetail)}
          onClose={() => {
            this.setState({ autoCloseModalDetail: undefined });
            this.handleSave();
          }}
          timeToClose={3000}
          modalTitle={<AlertTitle>{this.state.autoCloseModalDetail?.title}</AlertTitle>}
          content={this.state.autoCloseModalDetail?.message}
        />
      </>
    );
  }
}

export default AddEditPrescriptions;
