import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string,
  link: string,
  iconAssetName: string,
  isDefault: boolean
}

/**
 * Renders a report link
 * @param {ReportLink} props ReportLink
 * @returns {React.FunctionComponent} The rendered component
*/
function ReportLink(props: Props) {
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  const { title, link, iconAssetName, isDefault } = props;
  const iconToDisplay = isDefault ? iconAssetName : 'custom';
  return (
    <div className="report-link o-card">
      <div className="u-padding--standard">
        <Link to={`/analytics/reports/${link}`}>
          <img src={`static/images/icon_report_${iconToDisplay}.png`} alt="report-icon" />
          <span>{title}</span>
        </Link>
      </div>
    </div>
  );
}

export default ReportLink;
