import React, { useState } from 'react';
import { List, Map } from 'immutable';

import TableColumnsSettings from '../table/tableColumnsSettings';
import StageForm from './stageForm';
import Header from '../header/header';
import Table from '../table/table';
import Button from '../buttons/button';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import translate from '../../utils/i18n';
import { renderListItem } from '../../utils/tables';
import { UNICODE } from '../../constants';
import EncounterStageModel from '../../models/encounterStageModel';
import type { Config, SaveModel, User } from '../../types';
import type SalesItemModel from '../../models/salesItemModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';

const COLUMNS = [
  { accessor: 'name', Header: translate('name') },
  {
    accessor: 'relatedSalesItem',
    Header: translate('related_sales_items'),
    filterable: false,
    sortable: false,
    Cell: renderListItem,
  },
  { accessor: 'notes', Header: translate('notes_section'), filterable: false },
  { accessor: 'locations', Header: translate('locations'), filterable: false, Cell: renderListItem },
  { accessor: 'waiting_sound', Header: translate('x_sound', { x: translate('waiting') }), hideDefault: true },
  { accessor: 'inprogress_sound', Header: translate('x_sound', { x: translate('in_progress') }), hideDefault: true },
  { accessor: 'action', Header: '', filterable: false, width: 120 },
];

type Props = {
  config: Config;
  updateConfig: (config: Config) => void;
  stagesMap: Map<string, EncounterStageModel>;
  salesItems: Map<string, SalesItemModel>;
  saveModel: SaveModel;
  coveragePayors: List<CoveragePayorModel>,
  addStage: (stage: EncounterStageModel) => void;
  user: User;
}

/**
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const AddFlowStage = (props: Props) => {
  const [stageFormVisible, setStageFormVisible] = useState(false);
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));

  /**
   * @returns {Row}
   */
  const getStageItemRows = () => props.stagesMap.toList().map(stage => ({
    name: stage.get('name'),
    relatedSalesItem: stage.getSalesItemNames(props.salesItems),
    notes: stage.hasNotes(),
    locations: stage.getLocations(),
    waiting_sound: stage.get('waiting_alert')?.replace(/\.[^/.]+$/, '') || UNICODE.EMDASH,
    inprogress_sound: stage.get('in_progress_alert')?.replace(/\.[^/.]+$/, '') || UNICODE.EMDASH,
    action: (
      <Button
        className="o-button o-button--small"
        onClick={() => props.addStage(stage)}
      >
        {translate('add')}
      </Button>
    ),
  })).toArray();

  return (
    <>
      <div className="u-margin--standard o-card">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{translate('stages')}</h1>
          <div className="u-flex-right u-margin-right--half-ws">
            <TableColumnsSettings
              config={props.config}
              configFieldName="stage_to_flow"
              originalColumns={List(transformColumnKeys(COLUMNS))}
              columns={columns}
              onUpdateColumns={setColumns}
              updateConfig={props.updateConfig}
            />
          </div>
        </Header>
        <div className="o-header-actions">
          <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
            <Button
              onClick={() => setStageFormVisible(true)}
              className="o-button o-button--small"
              dataPublic
            >
              {translate('create_stage')}
            </Button>
          </div>
        </div>
        <Table
          columns={transformFilteredColumns(COLUMNS, columns)}
          data={getStageItemRows()}
          defaultSorted={[{ id: 'name', desc: false }]}
          noDataText={translate('no_sales_items_added')}
        />
      </div>
      {stageFormVisible && <StageForm
        config={props.config}
        updateConfig={props.updateConfig}
        salesItems={props.salesItems}
        saveModel={props.saveModel}
        coveragePayors={props.coveragePayors}
        isVisible={stageFormVisible}
        hideStageForm={() => setStageFormVisible(false)}
        user={props.user}
      />}
    </>
  );
};

export default AddFlowStage;
