import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PharmaConnectIndexPage from './../components/pharmaconnect';

import type { State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({
    pharmaconnectViews,
  }: State) => ({
    brandDocuments: pharmaconnectViews.brandDocuments,
  });


const PharmaConnectContainer =
withRouter(connect(mapStateToProps, {})(PharmaConnectIndexPage));

export default PharmaConnectContainer;
