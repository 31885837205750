import React from 'react';
import translate from '../../utils/i18n';

export type ParentProps = {
  totalStep: number,
  currentStep: number,
  stepIndex: number
}
export type InternalStepProps = {
  title?: string,
  stepContent?: HTMLElement | string,
  style?: { [key: string]: string | number },
  subHeadingLabel?: string,
  id: string
  stepSubLabel?: string,
  stepTitleClass?: string,
  stepCircleClass?: string,
}

export type StepProps = ParentProps & InternalStepProps;

export enum CurrentAction {
  FINISH = 'finish',
  WAIT = 'wait',
  PROCESS = 'process',
}

/**
 * get the status of the step
 * @param {number} currentIndex current index
 * @param {number} currentStep current step
 * @returns {string}
 */
const getStatus = (currentIndex: number, currentStep: number) => {
  const stepIndex = currentIndex + 1;
  switch (true) {
    case (stepIndex < currentStep):
      return CurrentAction.FINISH;
    case (stepIndex === currentStep):
      return CurrentAction.PROCESS;
    default:
      return CurrentAction.WAIT;
  }
};

/**
 * return the default icon/content for the step circle
 * @param {string} status status of the step
 * @returns {null | HTMLElement}
 */
const defaultCheckIcon = (status: string) => {
  if (status === CurrentAction.FINISH) {
    return (<i className="fa fa-check " style={{ color: 'white', fontSize: 'large' }} />);
  }
  if (status === CurrentAction.PROCESS) {
    return (<i className="fa fa-clock-o" style={{ color: 'rgba(0,0,0,.95)', fontSize: 'large' }} />);
  }
  return null;
};

/**
 * Steps step component.
 * @param {Props} props component props
 * @returns {React.Component | string}
 */
function Step(props: StepProps) {
  const classStatus = getStatus(props.stepIndex, props.currentStep);
  const content = props.stepContent ? props.stepContent : defaultCheckIcon(classStatus);
  return (
    <div className="step-container" style={props.style}>
      {((props.stepIndex + 1) !== props.totalStep) &&
        <div className={`step-item-connector step-${classStatus}`} />
      }
      <div className="step-item">
        <div className={`step-item-circle step-${classStatus} ${props.stepCircleClass || ''}`}>{content}</div>
        {props.title &&
          <div className={`step-title-${classStatus} ${props.stepTitleClass || ''}`}>
            {`${props.title}${props.subHeadingLabel ? ` (${props.subHeadingLabel})` : ''}`}
          </div>
        }
      </div>
      {props.stepContent && <div>{props.stepContent}</div>}
    </div>
  );
}

export default Step;
