// @flow
import { Map } from 'immutable';
import { DDOC_VERSION } from '../constants';
import { queryMap } from '../../src/types';

export type queryMappingType =  Map<string, queryMap>;
export const queryMapping: queryMappingType = Map({
  allByType: {
    name: 'Fetch all the docs matching doc type',
    description: "Find all docs filtered by a specific docType e.g., 'patient' (with no input will fetch all docs)",
    queryDDOCView: `${DDOC_VERSION}/allByType`,
    viewName: 'allByType',
    keysInput:
            {
              name: 'List of doc type',
              placeholder: 'Enter the list of  docType i.e., ["<docType1>","<docType2>"]',
              pseudoName: 'allByType',
              error: 'Please enter valid Doc type',
              value: '',
            },
  },
  getDocsForEncounter: {
    name: "Fetch all the docs matching encounter ID's and doc type",
    description: "Find all docs for the given encounter id filtered by a specific docType e.g., 'condition' (with no input will fetch all docs)",
    queryDDOCView: `${DDOC_VERSION}/allByEncounterIdAndType`,
    viewName: 'getDocsForEncounter',
    keysInput:
            {
              name: "List of encounter ID's with type",
              placeholder: "Enter the list of ID's with docType i.e., [\"<encounterID>\",\"<docType>\"](it can be of type Array<Array<string>>>",
              pseudoName: 'encounterIDs',
              error: 'Please enter valid IDs with type',
              value: '',
            },
  },
  patientRelatedDocs: {
    name: 'Fetch patients doc',
    description: 'Fetches patients docs from the given array of patient IDs. (with no input will fetch all docs)',
    queryDDOCView: `${DDOC_VERSION}/patientRelatedDocs`,
    viewName: 'patientRelatedDocs',
    keysInput:
            {
              name: "List of patient ID's",
              placeholder: "Enter the list of patient ID's i.e., [\"<id1>\",\"<id2>\"]",
              pseudoName: 'patientIDs',
              error: 'Please enter valid IDs',
              value: '',
            },
  },
  userConfigsByUserId: {
    name: 'Find User config',
    description: 'Attempts to find a User Config document for the given username in the database. (with no input will fetch all docs)',
    queryDDOCView: `${DDOC_VERSION}/userConfigsByUserId`,
    viewName: 'userConfigsByUserId',
    keysInput:
            {
              name: 'User IDs',
              placeholder: 'Enter the username i.e., ["<id1>","<id2>"]',
              pseudoName: 'userIDs',
              error: 'Please enter valid user names',
              value: '',
            },
  },
  allByBillIdAndType: {
    name: "Fetches all docs matching bill ID's and doc type",
    description: "Gets all docs with matching Bill IDs and type eg., '_any' (with no input will fetch all docs with bill ID)",
    queryDDOCView: `${DDOC_VERSION}/allByBillIdAndType`,
    viewName: 'allByBillIdAndType',
    keysInput:
            {
              name: 'List of Bill IDs with type',
              placeholder: "Enter the list of ID's with type i.e., [\"<billID>\",\"<docType>\"](it can be of type Array<Array<string>>",
              pseudoName: 'billIds',
              error: 'Please enter valid IDs with type',
              value: '',
            },
  },
  allByEncounterIdAndType: {
    name: "Fetches all docs matching encounter ID's and doc type",
    description: "Gets all docs with matching encounter IDs and type eg., '_any' (with no input will fetch all docs with encounter ID)",
    queryDDOCView: `${DDOC_VERSION}/allByEncounterIdAndType`,
    viewName: 'allByEncounterIdAndType',
    keysInput:
            {
              name: 'List of Encounter IDs with type',
              placeholder: "Enter the list of ID's with type i.e., [\"<encounterID>\",\"<docType>\"](it can be of type Array<Array<string>>",
              pseudoName: 'billIds',
              error: 'Please enter valid IDs with type',
              value: '',
            },
  },
  mcDaysByEncounterID: {
    name: 'Find an MC matching the given encounter IDs',
    description: 'Tries to find an MC matching the given encounter ID. If one is found the number of days of the MC is returned. If none then 0 is returned. (with no input will fetch all MC docs)',
    queryDDOCView: `${DDOC_VERSION}/mcDaysByEncounterID`,
    viewName: 'mcDaysByEncounterID',
    keysInput:
            {
              name: 'Encounter IDs',
              placeholder: "Enter the EncounterID's i.e., [\"<id1>\",\"<id2>\"]",
              pseudoName: 'encounterIDs',
              error: 'Please enter the valid EncounterIDs',
              value: '',
            },
  },
  patientsWithIncompleteEncounters: {
    name: "Fetches all the incomplete encounters given by the patient ID's",
    description: "Fetches the ids of all incomplete encounter w.r.t the given patient ID's. Note, there may be duplicates! (with no input will fetch all incomplete encounters)",
    queryDDOCView: `${DDOC_VERSION}/patientsWithIncompleteEncounters`,
    viewName: 'patientsWithIncompleteEncounters',
    keysInput:
      {
        name: 'Enter patients IDs',
        placeholder: "Enter the patients ID's i.e., [\"<id1>\",\"<id2>\"]",
        pseudoName: 'patientIDs',
        error: "Please enter the valid patientsID's",
        value: '',
      },
  },
  inventoryCount: {
    name: 'Fetches the transaction doc as per the drug keys',
    description: 'Fetches the ids of transactions w.r.t to the drugs keys, if keys not defined it will fetch all the transactions (with no input will fetch all transaction docs)',
    queryDDOCView: `${DDOC_VERSION}/inventoryCount`,
    viewName: 'inventoryCount',
    keysInput:
      {
        name: 'Enter IDs',
        placeholder: "Enter the Drug ID's i.e., [\"<id1>\",\"<id2>\"]",
        pseudoName: 'inventoryIDs',
        error: "Please enter the valid Drug ID's",
        value: '',
      },
  },
  encountersByFirstEventTime: {
    name: 'Fetches all the encounters by the last event time',
    description: 'Fetches all the encounters by the last event time, With no input will fetch all transaction docs',
    queryDDOCView: `${DDOC_VERSION}/encountersByFirstEventTime`,
    viewName: 'encountersByFirstEventTime',
    keysInput:
      {
        name: 'Enter Time',
        placeholder: 'Enter the time ["<time1>","<time2>"]',
        pseudoName: 'patientIDs',
        error: 'Please enter the valid time',
        value: '',
      },
  },
  encountersByLastEventTime: {
    name: 'Fetches all the encounters by the first event time',
    description: 'Fetches all the encounters by the first event time, With no input will fetch all transaction docs',
    queryDDOCView: `${DDOC_VERSION}/encountersByLastEventTime`,
    viewName: 'encountersByLastEventTime',
    keysInput:
      {
        name: 'Enter Time',
        placeholder: 'Enter the time ["<time1>","<time2>"]',
        pseudoName: 'patientIDs',
        error: 'Please enter the valid time',
        value: '',
      },
  },
  allByInvoiceIdAndType: {
    name: 'Fetches all docs by the claim invoice ID and doc type',
    description: 'Fetch all the docs whose keys match with given keys i.e., ["<claimInvoiceId, doctType>"](it can be of type Array<Array<string>> and with no input will fetch all docs with claim invoice ID)',
    queryDDOCView: `${DDOC_VERSION}/allByInvoiceIdAndType`,
    viewName: 'allByInvoiceIdAndType',
    keysInput:
      {
        name: 'List of invoice ID and type',
        placeholder: 'Enter the list of invoice ID and doc type i.e., ["<claimInvoiceId, doctType>"]',
        pseudoName: 'invoiceWithType',
        error: 'Please enter the invoice ID and doc type',
        value: '',
      },
  },
  caseNoteFileOrderByPatient: {
    name: 'Fetches all case Note docs by patient ID',
    description: 'Fetch all patient related case Notes i.e., ["<patientID1>, <PatientID2>"] (with no input will fetch all case note docs)',
    queryDDOCView: `${DDOC_VERSION}/caseNoteFileOrderByPatient`,
    viewName: 'caseNoteFileOrderByPatient',
    keysInput:
      {
        name: 'List of patient ID',
        placeholder: 'Enter the list of patient ID i.e., ["<patientID1>, <PatientID2>"]',
        pseudoName: 'caseNoteFileOrderByPatient',
        error: 'Please enter the list of patient IDs',
        value: '',
      },
  },
  paymentsByClaimId: {
    name: 'Fetches all payement matching claim ID',
    description: 'Fetch all patient matching claim ID., ["<claimID1>, <claimID2>"] (with no input will fetch all payment docs with defined claim ID)',
    queryDDOCView: `${DDOC_VERSION}/paymentsByClaimId`,
    viewName: 'paymentsByClaimId',
    keysInput:
      {
        name: 'Enter Claim IDs',
        placeholder: 'Enter the list of claim ID i.e., ["<claimID1>, <claimID2>"]',
        pseudoName: 'paymentsByClaimId',
        error: 'Please enter the list of claim IDs',
        value: '',
      },
  },
  claimsByCoveragePayorAndTimestamp: {
    name: 'Fetches all claim by coverage payor ID and timestamp',
    description: 'Fetch all claim by coverage payor ID and timestamp., ["<coveragePayor1, timestamp1>, <coveragePayor2, timestamp2>"] (with no input will fetch all claim docs)',
    queryDDOCView: `${DDOC_VERSION}/claimsByCoveragePayorAndTimestamp`,
    viewName: 'claimsByCoveragePayorAndTimestamp',
    keysInput:
      {
        name: 'Enter Claim IDs with timestamp',
        placeholder: 'Enter the list of coverage payor ID and timestamp i.e., ["<coveragePayor1, timestamp1>, <coveragePayor2, timestamp2>"]',
        pseudoName: 'claimsByCoveragePayorAndTimestamp',
        error: 'Please enter the list of coverage Payor with time',
        value: '',
      },
  },
  allByTimestamp: {
    name: 'Fetch all the docs matching doc type and timestamp',
    description: 'Find all docs filtered by a doc type and timestamp (with no input will fetch all docs)',
    queryDDOCView: `${DDOC_VERSION}/allByTimestamp`,
    viewName: 'allByTimestamp',
    keysInput:
            {
              name: 'List of doc type and timestamp',
              placeholder: 'Enter the list of  docType i.e., ["<docType1, timestamp1>","<docType2, timestamp2>"]',
              pseudoName: 'allByTimestamp',
              error: 'Please enter valid Doc type and timestamp',
              value: '',
            },
  },
  outstandingReceivablesByTimestamp: {
    name: 'Fetch all the recievable matching timestamp',
    description: 'Find all receivable docs filtered  by timestamp (with no input will fetch all non-void receivable docs whose due is greater than 0)',
    queryDDOCView: `${DDOC_VERSION}/outstandingReceivablesByTimestamp`,
    viewName: 'outstandingReceivablesByTimestamp',
    keysInput:
            {
              name: 'Enter list of timestamp',
              placeholder: 'Enter the list of timestamp i.e., ["<timestamp1>","<timestamp2>"]',
              pseudoName: 'outstandingReceivablesByTimestamp',
              error: 'Please enter valid timestamp',
              value: '',
            },
  },
});

export { queryMapping as default };
