import React, { Fragment } from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import BillFormItem from '../billing/billFormItem';
import SaveButton from './../buttons/saveButton';
import BillItemModel from './../../models/billItemModel';
import FormError from './../formError';
import ModalFooter from './../modals/modalFooter';
import UnsavedDataModal from '../../components/prompts/unsavedDataModal';
import SalesItemModel from './../../models/salesItemModel';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, InventoryCount, SaveModel, SaveModels, MapValue, User } from './../../types';
import type ProviderModel from '../../models/providerModel';

type Props = {
  billItems: List<BillItemModel>,
  config: Config,
  disabled?: boolean,
  salesItems: List<SalesItemModel>,
  isModalVisible?: (value: boolean) => void,
  inventoryCount: InventoryCount,
  saveModels: SaveModels,
  patientID: string,
  billID: string,
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  providers: List<ProviderModel>,
  isFromDocValidationModal?: boolean,
  isDocValidationModalSaving?: boolean;
  validationReferrerModel?: BillItemModel,
  validationDocObject?: { id: string, type: string },
  onSaveAtDocValidationModal?: (wasSuccessful: boolean, models: List<BillItemModel>) => void;
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  user: User,
  updateConfig: (config: Config) => void,
  onChangesMade?: (changesMade: boolean) => void,
  isFromEncounterHistory?: boolean,
  showPrompt: boolean,
  noSave: boolean,
  onPromptCancel: () => void,
  onDiscard: () => void,
};

type State = {
  isSaving: boolean,
  billItems: List<BillItemModel>,
  errorMessage?: string,
};

/**
 * A list of Bill items.
 * @class SalesItemsFormItem
 * @extends {Component}
 */
class SalesItemsFormItem extends React.Component<Props, State> {
  props: Props;

  /**
   * Creates an instance of SalesItemsFormItem.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      billItems: this.getSalesItems(),
    };
  }

  /**
   * Called when props changed
   * @param {Props} prevProps PrevProps
   * @returns {undefined}
   */
  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.isDocValidationModalSaving !== this.props.isDocValidationModalSaving &&
      this.props.isDocValidationModalSaving
    ) {
      this.handleSave();
    }
  }

  /**
   * returns sales items passed in props as bill items, if there are none then an empty one is set in list and sent.
   * @returns {List<BillItemModel>}
   */
  getSalesItems() {
    return this.props.billItems && this.props.billItems.size > 0 ?
      this.props.billItems : List([this.getEmptyBillItem()]);
  }

  /**
   * Returns true if the user can add a new Sale Item. This means that either no previous items have
   * been added or that all previously added items are fully filled out.
   * @returns {boolean} True if user can add a new item.
   */
  canAddNewItem(): boolean {
    const last = this.props.billItems.last();
    return last ? last.isValid() : true;
  }

  /**
   * An empty billing item is created and returned.
   * @returns {BillItemModel}
   */
  getEmptyBillItem() {
    return new BillItemModel({
      patient_id: this.props.patientID,
      bill_id: this.props.billID,
      sales_item_id: '',
    });
  }

  /**
   * Called when add is clicked on one of the billing lists. An empty billing item of the
   * appropriate type is created and added to the appropriate list in the parent state.
   * @param {string} type The billing item type (prescription or sales_item).
   * @returns {undefined}
   */
  onAddClicked() {
    if (this.canAddNewItem()) {
      const newItem = this.getEmptyBillItem();
      this.setState({ billItems: this.state.billItems.push(newItem) });
    }
  }

  /**
   * Removes an item from the appropriate list on the bill.
   * @param {string} itemID The item ID.
   * @returns {void}
   */
  onRemoveClicked(itemID: string) {
    this.updateValues(itemID, { _deleted: true });
  }


  /**
   * Updates values
   * @param {string} itemID Item ID
   * @param {{}} newValues The new Values
   * @return {void}
   */
  updateValues(itemID: string, newValues: { [key: string]: MapValue }) {
    const index = this.state.billItems.findIndex(i => i.get('_id') === itemID);
    if (index > -1) {
      const billItem = this.state.billItems.get(index);
      if (billItem) {
        const billAttributes = Object.assign(
          {},
          billItem.attributes,
          newValues,
        );
        this.setState({
          billItems: this.state.billItems.set(index, new BillItemModel(billAttributes)),
        });
        if (this.props.onChangesMade) {
          this.props.onChangesMade(true);
        }
      }
    }
  }

  /**
   * Returns true if form is valid.
   * @returns {boolean}
   */
  isValid(): boolean {
    if (this.state.billItems.some(i => i.get('sales_item_id') === this.props.validationDocObject?.id)) {
      this.setState({ errorMessage: translate('resolve_all_data_not_found_before_saving') });
      return false;
    }
    if (this.state.billItems.every(i => i.get('_deleted') || i.isValid())) {
      return true;
    }
    this.setState({ errorMessage: translate('bill_items_must_not_have_empty_fields') });
    return false;
  }

  /**
   * Save and Update billedSalesItems with new values.
   * @returns {undefined}
   */
  handleSave = () => {
    if (this.isValid()) {
      this.setState({ isSaving: true });
      const modelsToSave = this.state.billItems.filter(i => !i.get('_deleted') || i.hasBeenSaved()); // Filter out any models that were created than deleted before an initial save.
      (this.props.noSave
        ? Promise.resolve([])
        : this.props.saveModels(modelsToSave.toArray()))
        .then(() => {
          this.setState({ isSaving: false });
          if (this.props.isFromDocValidationModal && this.props.onSaveAtDocValidationModal) {
            this.props.onSaveAtDocValidationModal(true, modelsToSave);
          }
          if (this.props.isModalVisible && this.props.onChangesMade && this.props.onPromptCancel) {
            this.props.onPromptCancel();
            this.props.isModalVisible(false);
            this.props.onChangesMade(false);
          }
        });
    }
  }

  /**
   * Renders the component.
   * @return {string} - JSX markup for the component
   */
  render() {
    return (
      <Fragment>
        <UnsavedDataModal
          visible={this.props.showPrompt}
          onSave={this.handleSave}
          onDiscard={() => this.props.onDiscard()}
          onCancel={() => this.props.onPromptCancel()}
        />
        {
          this.state.errorMessage &&
          <div className="u-margin--standard">
            <FormError>{this.state.errorMessage}</FormError>
          </div>
        }
        {
          this.state.billItems
            .filter(i => !i.get('_deleted', false))
            .map((item, index) =>
              <BillFormItem
                key={`billedSalesItem-${item.get('_id')}`}
                hideLabel={index !== 0}
                autoFocus={index === this.props.billItems.size - 1}
                disabled={this.props.disabled}
                item={item}
                itemOptions={this.props.isFromDocValidationModal && this.props.validationDocObject
                  ? this.props.salesItems.push(new SalesItemModel({
                    _id: this.props.validationDocObject.id, is_missing: true,
                  }))
                  : this.props.salesItems
                }
                onRemoveClicked={() => this.onRemoveClicked(item.get('_id'))}
                onValueChanged={newValues => this.updateValues(item.get('_id'), newValues)}
                config={this.props.config}
                inventoryCount={this.props.inventoryCount}
                coveragePayors={this.props.coveragePayors}
                saveModel={this.props.saveModel}
                updateConfigValue={this.props.updateConfigValue}
                updateConfig={this.props.updateConfig}
                user={this.props.user}
                providers={this.props.providers}
                isFromEncounterHistory={this.props.isFromEncounterHistory}
              />).toArray()
        }
        <div className="u-margin--standard" style={{ marginTop: 5 }}>
          {
            !this.props.disabled &&
            <button
              className="o-button o-button--small"
              onClick={() => this.onAddClicked()}
            >
              {translate('add')}
            </button>
          }
        </div>
        {
          !this.props.isFromDocValidationModal &&
          (
            <ModalFooter>
              <SaveButton
                isSaving={this.state.isSaving}
                onClick={() => this.handleSave()}
                label={translate('save')}
                className="o-button--small u-margin-right--half-ws"
              />
            </ModalFooter>
          )
        }
      </Fragment>
    );
  }
}

export default SalesItemsFormItem;
