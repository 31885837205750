import React from 'react';
import glamorous from 'glamorous';
import type { List } from 'immutable';
import ReportLink from './reportLink';
import type AnalyticsReportModel from '../../models/analytics/reportModel';
import { wsUnit } from './../../utils/css';

type Props = {
  title: string,
  items: List<AnalyticsReportModel>
};

const ListContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridColumnGap: wsUnit,
  margin: '1.33rem calc(1.5 * 1.33rem)',
});

/**
 * Renders the list of all available reports and their links
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function ReportsList(props: Props) {
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  const { title, items } = props;
  return (
    <div className="report-list">
      <h1 className="o-title">{title}</h1>
      <ListContainer>
        { items.map(item =>
          <ReportLink
            key={item.get('_id')}
            title={item.get('title')}
            link={item.get('report_id')}
            iconAssetName={item.get('icon_asset')}
            isDefault={item.get('is_default')}
          />)}
      </ListContainer>
    </div>
  );
}

export default ReportsList;
