import React from 'react';

import translate from './../../utils/i18n';
import Button from './../buttons/button';

type Props = {
  onPrint?: () => void,
  buttonLabel?: string,
  disabled?: boolean,
};

/**
   * @param {props} props props
   * @return {ReactComponent} React component for a Print Button.
   */
const PrintButton = ({
  buttonLabel,
  onPrint,
  disabled,
}: Props) => (
  <Button
    className="o-text-button o-text-button--contextual"
    onClick={onPrint}
    dataPublic
    disabled={disabled}
  >
    { buttonLabel || translate('print')} 
  </Button>
);

export default PrintButton;
