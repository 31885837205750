import React from 'react';

import Input from './../inputs/input';
import translate from './../../utils/i18n';
import { convertNumberToPrice, filterUnwantedCharacters } from './../../utils/utils';
import Header from './../header/header';

type Props = {
  amount?: number,
  disabled: boolean,
  onChange: (value?: number | string) => void,
  maxClaimableAmount: ?number,
};

/**
 * An input form for copayments on a bill.
 * @class CoPaymentForm
 * @extends {React.Component<Props, State>}
 */
class CoPaymentForm extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card o-card--no-shadow" style={{ marginTop: 0 }}>
        <div style={{ fontSize: '12px' }}>
          <Header className="o-card__header" dataPublic>
            <h2 className="o-card__title">{translate('co_payment_amount')}</h2>
          </Header>
          <Input
            id="co-payment"
            value={this.props.amount}
            disabled={this.props.disabled}
            onValueChanged={
              (newValue: number) => {
                const filteredValue = filterUnwantedCharacters(newValue, '');
                this.props.onChange(filteredValue ? parseFloat(filteredValue) : '');
              }
            }
            type="number"
            hideLabel
            label={translate('co_payment')}
            inputStyle={{ margin: '1em', width: 'auto' }}
          />
          <Header className="o-card__header" dataPublic>
            <h2 className="o-card__title">{translate('maximum_claimable_amount')}</h2>
          </Header>
          <p className="o-card__line-item">
            {this.props.maxClaimableAmount ? convertNumberToPrice(this.props.maxClaimableAmount) : translate('none')}
          </p>
        </div>
      </div>
    );
  }
}

export default CoPaymentForm;
