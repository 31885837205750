import React from 'react';
import { Map } from 'immutable';
import glamorous from 'glamorous';
import Moment from 'moment';

import translate from './../../utils/i18n';

import DatePicker from './../inputs/statefulDatepicker';
import TimePicker from './../inputs/timepicker';
import type { MapValue } from './../../types';

type Props = {
  filter: Map<string, Moment>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  headerTitle: string,
};

type OnFilterUpdated = (filter: Map<string, MapValue>) => void;

const FilterWrapper = glamorous.div({
  display: 'flex',
});

const FilterHeader = glamorous.div({
  marginBottom: '1.333rem',
});

const FilterDivider = glamorous.div({
  height: '85px',
  lineHeight: '85px',
  verticalAlign: 'middle',
  marginRight: '2.666rem',
});

/**
 * Merges the given object with the current filter for the component and calls the handler passed in props.
 * @param {{}} newValues The new filter values to merge.
 * @param {Map<string, Moment>} filter The old filter passed to the component.
 * @param {OnFilterUpdated} onFilterUpdated handler method to update the filters in store and fetch data
 * @returns {void}
 */
function updateFilter(
  newValues: {},
  filter: Map<string, Moment>,
  onFilterUpdated: OnFilterUpdated,
) {
  const newFilterProps = filter.merge(newValues);
  if (onFilterUpdated) {
    onFilterUpdated(newFilterProps);
  }
}


/**
 * Renders the component.
 * @param {Props} props Component props
 * @returns {React.Component} A date time filter component.
 */
const ReportsDateTimeFilter = (props: Props) =>
  <div>
    <FilterHeader>{props.headerTitle}</FilterHeader>
    <FilterWrapper className="reports-date-time-filter">
      <DatePicker
        id="start-date-filter"
        showClearDate={false}
        allowPast
        label={translate('date_start')}
        value={props.filter.get('filterDateStart')}
        required
        style={{ marginRight: '1.333rem' }}
        onValueChanged={value =>
          updateFilter({ filterDateStart: value }, props.filter, props.onFilterUpdated)}
      />
      <TimePicker
        id="start_time"
        value={props.filter.get('filterTimeStart')}
        onValueChanged={value =>
          updateFilter({ filterTimeStart: value }, props.filter, props.onFilterUpdated)}
        label={translate('time')}
        style={{ marginRight: '2.666rem', width: '65px' }}
        popupClassName="report-start-end-time-filter"
      />
      <FilterDivider>-</FilterDivider>
      <DatePicker
        id="end-date-filter"
        showClearDate={false}
        allowPast
        label={translate('date_end')}
        value={props.filter.get('filterDateEnd')}
        required
        onValueChanged={value =>
          updateFilter({ filterDateEnd: value }, props.filter, props.onFilterUpdated)}
        style={{ marginRight: '1.333rem' }}
      />
      <TimePicker
        id="end_time"
        value={props.filter.get('filterTimeEnd')}
        onValueChanged={value =>
          updateFilter({ filterTimeEnd: value }, props.filter, props.onFilterUpdated)}
        label={translate('time')}
        style={{ width: '65px' }}
        popupClassName="report-start-end-time-filter"
      />
    </FilterWrapper>
  </div>;

export default ReportsDateTimeFilter;
