import { connect } from 'react-redux';

import UnsyncedDocuments from './../components/unsyncedDocuments/unsyncedDocuments';
import { getCurrentUnsyncedModels } from '../utils/sync';
import { getLocalDB } from '../utils/db';

import type { State } from './../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ user, isSyncing, isOnline }: State) => ({
  isSyncing,
  isOnline,
  unsyncedModels: getCurrentUnsyncedModels(getLocalDB(), user.get('id')),
});

const UnsyncedDocumentsContainer = connect(mapStateToProps)(UnsyncedDocuments);

export default UnsyncedDocumentsContainer;
