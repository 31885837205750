// @flow
import { connect } from 'react-redux';
import { List } from 'immutable';
import Dashboard from './../components/dashboard/dashboard';
import {
  setCurrentDataViews,
  setCurrentDataViewsError,
  setCurrentDataViewsModels,
  setIsFetching, updateModels,
} from './../actions';

import type { Dispatch, IntervalString, State, DataView } from './../types';
import AsyncFetch from '../components/asyncFetch';
import { getDashboardDataViews } from '../dataViews';
import APIError from '../utils/apiError';
import ClinicModel from '../models/clinicModel';
import DashboardStatModel from '../models/dashboardStatModel';
import BaseModel from '../models/baseModel';

type Props = {
  interval: IntervalString,
  clinicId?: string,
  addModels: (models: List<BaseModel>) => void,
}

/**
   * @param {Object} state Current app state.
   * @param {Object} props Current app props.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  {
    doctorNames, currentDataViews, currentDataViewsModels,
    currentDataViewsError, clinics, isFetching,
  }: State,
  { interval, clinicId }: Props,
) => ({
  doctorNames,
  stats: currentDataViewsModels.filter(m => m.get('type') === 'dashboard_stat'),
  containerDataViews: getDashboardDataViews(interval || 'today', clinicId, clinics.size === 0),
  currentDataViews,
  clinicList: clinics,
  currentDataViewsError,
  isFetching,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<ClinicModel | DashboardStatModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
  onAsyncFetchComplete: (models: List<BaseModel>) => dispatch(updateModels(
    models.filter(m => m.get('type') === 'clinic').toArray(),
  )),
});

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(Dashboard));

export default DashboardContainer;
