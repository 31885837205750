/* eslint-disable import/extensions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable require-jsdoc */
import React from 'react';
import { memoize } from 'lodash';
import { List, Map } from 'immutable';
import moment from 'moment';
import { valueContainerCSS } from 'react-select/src/components/containers';
import { UNICODE } from './../../constants';
import PractitionerModel from '../../models/practitionerModel';
import PatientModel from '../../models/patientModel';
import translate from '../../utils/i18n';
import type { Config, MapValue, SelectOption, AppointmentStatus } from '../../types';
import Select from '../inputs/select';
import Button from '../buttons/button';
import PatientStubModel from '../../models/patientStubModel';
import Header from '../header/header';
import { getMemoizePatientDocs, InputForm, InputFormGroup } from './appointments';
import { createErrorNotification } from '../../utils/notifications';
import { APPOINTMENT_PATIENT_FILTER_OPTIONS, LIMIT_PATIENT_RESULT } from './appointmentFilters';
import TableDateRangePicker, { NewFilterType } from '../table/tableDateRangePicker';
import { formatPatientOptions } from './addEditAppointment';
import { fetchAppointments } from '../../utils/api';
import { prettifyDate, prettifyTime } from '../../utils/time';
import { convertToCSV, downloadCSV } from '../../utils/export';
import { printAppointmentsReport } from '../../utils/print';
import { fetchPatientRelatedDocs } from '../../utils/db';

type Props = {
    config: Config;
    patientStubs: List<PatientStubModel>;
    practitioners: List<PractitionerModel>;
};

type State = {
    patientInput: string;
    patientSearchPlaceholder: string;
    patientOptions: List<SelectOption>;
    selectedPatientId: string;
    selectedDoctorId: string;
    selectedStatus: AppointmentStatus;
    patientFilterType: string;
    dateRangeFilter: MapValue;
    isLoadingExport: boolean;
    isLoadingPrint: boolean;
};

const STATUS_OPTION = [
  {
    label: 'Booked',
    value: 'booked',
  },
  {
    label: 'Fulfilled',
    value: 'fulfilled',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];

const COLUMNS = List([
  { value: 'start_timestamp', label: translate('date') },
  { value: 'time', label: translate('start_time') },
  { value: 'patientName', label: translate('patient_name') },
  { value: 'contact', label: translate('contact_number') },
  { value: 'email', label: translate('email') },
  { value: 'doctor', label: translate('preferred_doctor') },
  { value: 'status', label: translate('status') },
]);

// Create an empty row as a template for each row in the table/print out.
const EMPTY_ROW = {};
COLUMNS.forEach((c) => {
  EMPTY_ROW[c.value] = UNICODE.MINUS;
});

class AppointmentReports extends React.Component<Props, State> {
  /**
     * Creates an instance of TableColumnsSettings.
     * @param {Props} props props
     */
  constructor(props: Props) {
    super(props);
    this.state = {
      patientOptions: List<SelectOption>([]),
      patientInput: '',
      patientFilterType: 'patient_name',
      selectedPatientId: '',
      selectedDoctorId: '',
      selectedStatus: '',
      isLoadingExport: false,
      isLoadingPrint: false,
      patientSearchPlaceholder: translate('press_enter_to_search'),
      dateRangeFilter: Map({
        filterDateStart: moment().startOf('day'),
        filterDateEnd: moment().add(30, 'days').startOf('day'),
      }),
    };
  }

  onKeyDown(event: any) {
    const { patientInput, patientFilterType } = this.state;
    if (event.key === 'Enter') {
      if (!patientInput || patientInput.length < 3) {
        this.setState({ patientSearchPlaceholder: 'Please enter at least 3 characters' });
        return;
      }
      let newPatientOptions = List<SelectOption>([]);
      switch (patientFilterType) {
        case 'ic':
          newPatientOptions = this.props.patientStubs.filter(e => e.get('ic') && e.get('ic').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('ic'), name: e.get('patient_name'), tel: e.get('tel') }));
          break;
        case 'patient_name':
          newPatientOptions = this.props.patientStubs.filter(e => e.get('patient_name') && e.get('patient_name').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('patient_name'), ic: e.get('ic'), tel: e.get('tel') }));
          break;
        case 'tel':
          newPatientOptions = this.props.patientStubs.filter(e => e.get('tel') && e.get('tel').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('tel'), name: e.get('patient_name'), ic: e.get('ic') }));
          break;
        default:
          newPatientOptions = List<SelectOption>([]);
      }

      this.setState({
        patientOptions: newPatientOptions,
        patientSearchPlaceholder: newPatientOptions.size > 0 ? translate('press_enter_to_search') : `${translate('no_result_found_for')} ${patientInput}`,
      });
    }
  }

  onFilterInputChange(value: string) {
    const { patientInput, patientOptions } = this.state;
    let newPatientNameOptions = patientOptions;
    if (value !== patientInput) {
      newPatientNameOptions = List<SelectOption>([]);
    }
    this.setState({
      patientInput: value,
      patientOptions: newPatientNameOptions,
      patientSearchPlaceholder: translate('press_enter_to_search'),
    });
  }

  updatePatientFilterType(value: string) {
    this.setState({
      selectedPatientId: '',
      patientOptions: List<SelectOption>([]),
      patientInput: '',
      patientFilterType: value,
      patientSearchPlaceholder: translate('press_enter_to_search'),
    });
  }

  onUpdateDateFilter(newValues: NewFilterType) {
    this.setState({
      dateRangeFilter: Map({
        filterDateStart: moment(newValues.filterDateStart),
        filterDateEnd: moment(newValues.filterDateEnd),
      }),
    });
  }

  setLoading(loading: boolean, isExport: boolean) {
    if (isExport) this.setState({ isLoadingExport: loading });
    else this.setState({ isLoadingPrint: loading });
  }

  getMemoizePatientDocs = memoize((patientId: string) => fetchPatientRelatedDocs(patientId).then((resp) => {
    if (resp) {
      return resp.find(r => r instanceof PatientModel);
    }
    this.getMemoizePatientDocs.cache.delete(patientId);
    return null;
  }))

  onExportPrintClicked(isExport: boolean) {
    const { dateRangeFilter, selectedPatientId, selectedDoctorId, selectedStatus } = this.state;
    this.setLoading(true, isExport);
    fetchAppointments(moment(dateRangeFilter.get('filterDateStart')).startOf('day').valueOf(),
      moment(dateRangeFilter.get('filterDateEnd')).endOf('day').valueOf(), selectedStatus, selectedPatientId, selectedDoctorId).then((appointmentsResponse) => {
      if (appointmentsResponse.ok) {
        const appointments = appointmentsResponse.models;
        const patientIds = [...new Set(appointments?.map(c => c.get('patient_id')))];
        const promiseList = [];
        patientIds.forEach(id => promiseList.push(this.getMemoizePatientDocs(id)));

        Promise.all(promiseList).then((responses) => {
          const data = appointments?.sort((a, b) => 0 - (a.get('start_timestamp') > b.get('start_timestamp') ? 1 : -1))
            .map(event => COLUMNS.map((column) => {
              if (column.value === 'start_timestamp') {
                return prettifyDate(event.get('start_timestamp'));
              }
              if (column.value === 'time') {
                return prettifyTime(event.get('start_timestamp'));
              }
              if (column.value === 'patientName') {
                return event.getPatientName(this.props.patientStubs);
              }
              if (column.value === 'contact') {
                return responses.find(r => r.get('_id') === event.get('patient_id'))?.get('tel') ?? '-';
              }
              if (column.value === 'email') {
                return responses.find(r => r.get('_id') === event.get('patient_id'))?.get('email') ?? '-';
              }
              if (column.value === 'doctor') {
                return event.getDoctorName(this.props.practitioners);
              }
              if (column.value === 'status') {
                return event.get('status')?.toUpperCase() || '';
              }
            }).toArray()).toArray();
          if (isExport) {
            const start = moment(dateRangeFilter.get('filterDateStart')).format(this.props.config.get('date_format') ?? 'YYYYMMDD');
            const end = moment(dateRangeFilter.get('filterDateEnd')).format(this.props.config.get('date_format') ?? 'YYYYMMDD');
            downloadCSV(
              `appointments_${start}_${end}.csv`,
              convertToCSV(COLUMNS.map(c => c.label).toArray(), data),
            );
          } else {
            printAppointmentsReport(
              COLUMNS.toArray(),
              data,
              {
                filterDateStart: prettifyDate(moment(dateRangeFilter.get('filterDateStart')).valueOf()),
                filterDateEnd: prettifyDate(moment(dateRangeFilter.get('filterDateEnd')).valueOf()),
              },
              this.props.config,
              'Appointments',
            );
          }
          this.setLoading(false, isExport);
        }, () => this.setLoading(false, isExport));
      } else this.setLoading(false, isExport);
    }, () => this.getMemoizePatientDocs.cache?.clear());
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    const {
      patientOptions, isLoadingExport, isLoadingPrint,
      patientFilterType, selectedPatientId, selectedDoctorId,
      patientSearchPlaceholder, dateRangeFilter, selectedStatus,
    } = this.state;

    let practitioners = this.props.practitioners
      .filter(e => e.get('name'));

    if (practitioners.size === 0) // No on duty doctors so just use full list.
    { ({ practitioners } = this.props); }

    return (
      <div>
        <Header style={{ color: 'white', backgroundColor: '#CD386A' }} className="o-card__header" dataPublic>
          <h1 style={{ backgroundColor: '#CD386A' }} className="o-card__title">
            Export/Print Appointments
          </h1>
        </Header>

        <InputFormGroup>
          <Select
            style={{ marginBottom: '0rem' }}
            required
            id="patientFilterType"
            label="Search Patient"
            value={patientFilterType || null}
            options={APPOINTMENT_PATIENT_FILTER_OPTIONS}
            onValueChanged={value => this.updatePatientFilterType(value)}
          />
          <Select
            id="patientFilter"
            noResultsText={patientSearchPlaceholder}
            label=""
            formatOptionLabel={formatPatientOptions}
            value={selectedPatientId || null}
            onKeyDown={event => this.onKeyDown(event)}
            onInputChange={value => this.onFilterInputChange(value)}
            options={patientOptions}
            onValueChanged={value => this.setState({ selectedPatientId: value })}
          />
          <Select
            style={{ marginTop: '1rem' }}
            id="doctorFilterValue"
            noResultsText="Doctor not found"
            label="Search Doctor"
            formatOptionLabel={formatPatientOptions}
            value={selectedDoctorId || null}
            options={practitioners.map(e => ({ value: e.get('_id'), label: e.get('name') }))}
            onValueChanged={value => this.setState({ selectedDoctorId: value })}
          />

          <Select
            style={{ marginTop: '1rem' }}
            id="statusFilter"
            label="Search Status"
            value={selectedStatus || null}
            options={STATUS_OPTION}
            onValueChanged={value => this.setState({ selectedStatus: value })}
          />

          <TableDateRangePicker
            style={{}}
            orientation="vertical"
            small
            filter={dateRangeFilter}
            maxDate={moment().add(1, 'year')}
            onUpdateFilter={(newFilter: NewFilterType) => this.onUpdateDateFilter(newFilter)}
            label="Dates (From &rarr; To)"
          />

          <InputForm>
            <Button
              isLoading={isLoadingExport}
              style={{ marginTop: '1.6rem', position: 'relative' }}
              className="o-button o-button--small"
              onClick={() => this.onExportPrintClicked(true)}
              dataPublic
            >
              {translate('export')}
            </Button>

            <Button
              isLoading={isLoadingPrint}
              style={{ marginTop: '1.6rem', marginLeft: '0.5rem', position: 'relative' }}
              className="o-button o-button--small"
              onClick={() => this.onExportPrintClicked(false)}
              dataPublic
            >
              {translate('print')}
            </Button>
          </InputForm>
        </InputFormGroup>
      </div>
    );
  }
}
export default AppointmentReports;
