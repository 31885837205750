import { connect } from 'react-redux';

import DebugModeModal from '../components/debugComponent/debugModeModal';
import { unsetDebugModeData } from '../actions';

import type { Dispatch, State } from '../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ debugModeData }: State) => ({
  debugModeData,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  unsetDebugModeData: (flag: string) => dispatch(unsetDebugModeData(flag)),
});


const DebugModeContainer = connect(mapStateToProps, mapDispatchToProps)(DebugModeModal);

export default DebugModeContainer;
