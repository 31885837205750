import React from 'react';
import { List } from 'immutable';

import MedicalCertificateFormModal from './../medicalCertificates/medicalCertificateFormModal';
import MedicalCertificateModel from './../../models/medicalCertificateModel';
import EditHistory from './../editHistory/editHistory';
import Modal from './../modals/modal';
import PrintButton from './../buttons/printButton';
// import { userCanEdit } from './../../utils/permissions';
import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';

import type { Config, SaveModel, User } from './../../types';

type Props = {
  className: string,
  config: Config,
  disableChanges: boolean,
  medicalCertificate?: MedicalCertificateModel,
  hideButtons: boolean,
  saveModel: SaveModel,
  user: User,
  isCurrentEncounter: boolean, // Needed to handle permissions correctly
  onPrint?: () => void,
};

type State = {
  isEditModalVisible: boolean,
};

/**
 * Renders the details button for this component.
 * @param {MedicalCertificateModel} medicalCertificate The medical certificate to display details
 * for.
 * @returns {React.Component} A details button component.
 */
function renderDetailsButton(medicalCertificate: MedicalCertificateModel) {
  // Wrapped in a div due to some css weirdness.
  return (
    <Modal
      buttonLabel={translate('view_details')}
      buttonClass="o-text-button o-text-button--contextual"
      title={translate('medical_certificate')}
      dataPublic
    >
      <div className="u-padding--standard">
        <div className="c-patient-card__item">
          <span data-public className="c-patient-card__item__key">{translate('reason')}</span>
          <span className="c-patient-card__item__value">{ medicalCertificate.get('reason', UNICODE.EMDASH, false) }</span>
        </div>
        <div className="c-patient-card__item">
          <span data-public className="c-patient-card__item__key">{translate('days')}</span>
          <span className="c-patient-card__item__value">{ medicalCertificate.get('days', UNICODE.EMDASH, false) }</span>
        </div>
        <div className="c-patient-card__item">
          <span data-public className="c-patient-card__item__key">{translate('start_date')}</span>
          <span className="c-patient-card__item__value">{ medicalCertificate.getStartDate() }</span>
        </div>
        <div className="c-patient-card__item">
          <span data-public className="c-patient-card__item__key">{translate('mc_details')}</span>
          <span className="c-patient-card__item__value">{ medicalCertificate.get('notes', UNICODE.EMDASH, false) }</span>
        </div>
      </div>
    </Modal>
  );
}

/**
 * A component that display a Medical Certificate.
 * @class MedicalCertificateListItem
 * @extends {React.Component}
 */
class MedicalCertificateListItem extends React.Component<Props, State> {
  static defaultProps = {
    className: '',
    disableChanges: false,
    hideButtons: false,
    isCurrentEncounter: false,
  };

  /**
   * Creates an instance of MedicalCertificateListItem.
   * @param {Props} props Initial Props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditModalVisible: false,
    };
  }

  /**
   * Renders an edit button with a modal when activated.
   * @param {MedicalCertificateModel} model The MC model for editing.
   * @returns {React.Component} A react component.
   */
  renderEditButton(model: MedicalCertificateModel) {
    return (
      <PermissionWrapper
        permissionsRequired={List([createPermission(
          this.props.isCurrentEncounter ? 'current_encounter_medical_certificates' : 'past_encounters_medical_certificates',
          'update',
        )])}
        user={this.props.user}
      >
        <MedicalCertificateFormModal
          modalId={`medicalCertificateItem-${model.get('_id')}`}
          modalButtonLabel={translate('edit')}
          modalButtonClass="o-text-button o-text-button--contextual"
          modalVisible={this.state.isEditModalVisible}
          isModalVisible={isVisible => this.setState({ isEditModalVisible: isVisible })}
          modalTitle={translate('medical_certificate')}
          config={this.props.config}
          currentModel={model}
          onSave={(medicalCertificate) => {
            this.setState({ isEditModalVisible: false });
            return medicalCertificate
              .setSerialNumber()
              .then(updatedModel => this.props.saveModel(updatedModel));
          }}
          dataPublicHeader
        />
      </PermissionWrapper>
    );
  }

  /**
   * Asks user for confirmation and deletes this components MC if confirmed.
   * @returns {undefined}
   */
  onDeleteClicked() {
    getConfirmation(translate('confirm_item_deletion'))
      .then(
        () => {
          if (this.props.medicalCertificate) {
            this.props.saveModel(this.props.medicalCertificate.set('_deleted', true));
          }
        },
        () => {},
      );
  }

  /**
   * Renders the delete button for this component.
   * @returns {React.Component} A Delete button component.
   */
  renderDeleteButton() {
    // Wrapped in a div due to some css weirdness.
    return (
      <PermissionWrapper
        permissionsRequired={List([createPermission(
          this.props.isCurrentEncounter ? 'current_encounter_medical_certificates' : 'past_encounters_medical_certificates',
          'delete',
        )])}
        user={this.props.user}
      >
        <div>
          <Button
            className="o-text-button o-text-button--danger"
            onClick={() => this.onDeleteClicked()}
            dataPublic
          >
            {translate('delete')}
          </Button>
        </div>
      </PermissionWrapper>
    );
  }

  /**
   * Returns the description string for this MC.
   * @returns {string} Description string.
   */
  getDescription(): string {
    return this.props.medicalCertificate ?
      this.props.medicalCertificate.getDescription() : translate('no_mc_issued');
  }

  /**
   * Returns a notes string for the MC or an empty string if there is no MC.
   * @returns {string}
   */
  getNotes() {
    const mc = this.props.medicalCertificate;
    return mc ? `${translate('mc_details')}: ${mc.get('notes', UNICODE.EMDASH, false)}` : UNICODE.EMDASH;
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className={`o-data-list__row ${this.props.className}`}>
        <div className="o-data-list__row__item o-data-list__row__item--small">{translate('medical_certificate')}</div>
        <div className="o-data-list__row__item">
          <p className="u-font-small" style={{ marginBottom: '5px' }}>{this.getDescription()}</p>
          <p className="u-font-small">{this.getNotes()}</p>
        </div>
        {
          !this.props.hideButtons &&
          <div className="o-data-list__row__actions">
            { this.props.medicalCertificate && <PrintButton onPrint={this.props.onPrint} /> }
            { this.props.medicalCertificate && renderDetailsButton(this.props.medicalCertificate) }
            {
              this.props.medicalCertificate &&
              this.props.medicalCertificate.hasBeenSaved() &&
              <EditHistory
                model={this.props.medicalCertificate}
                buttonLabel="View History"
                buttonClass="o-text-button o-text-button--contextual"
              />
            }
            {
              this.props.medicalCertificate &&
              !this.props.disableChanges &&
              this.renderEditButton(this.props.medicalCertificate)
            }
            {
              this.props.medicalCertificate &&
              !this.props.disableChanges &&
              this.renderDeleteButton()
            }
          </div>
        }
      </div>
    );
  }
}

export default MedicalCertificateListItem;
