import React, { Fragment } from 'react';
import moment, { Moment } from 'moment';
import { List } from 'immutable';
import memoizeOne from 'memoize-one';
import { debounce, isEqual } from 'lodash';

import InventoryMapModel from './../../models/inventoryMapModel';
import DrugModel from './../../models/drugModel';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import { convertDateToTimestamp, formatDateForSave, isDateValid } from './../../utils/time';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import Input from './../inputs/input';
import Select from './../inputs/select';
import DateInput from './../inputs/dateInput';
import TextArea from './../inputs/textarea';
import AllergyModel from './../../models/allergyModel';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';
import Radio from './../inputs/radio';
import DeleteButton from '../../components/buttons/deleteButton';
import { fetchActiveIngredients } from './../../utils/api';
import { ALLERGIES } from './../../constants';
import SelectAsync from '../inputs/AsynSelect';

import type { ActiveIngredient, SaveModel, SelectOption } from './../../types';
import UnsavedDataModal from '../prompts/unsavedDataModal';

type Props = {
  isEditing?: boolean,
  model?: AllergyModel,
  onModelSaved?: (model: AllergyModel) => void,
  patientID?: string, // Should only be omitted if you plan on adding it elsewhere, e.g. after onModelAdded is run.
  saveModel?: SaveModel,
  onDeleteModel?: (allergyModel: AllergyModel) => void,
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
  allergies?: List<AllergyModel>,
};

type State = {
  name: string,
  reaction: string,
  reporter: string,
  onsetDate: string | Moment, //  Initialised as empty string and set as Moment object on datepicker change
  lastOccurrenceDate: string | Moment,
  status: string,
  verificationStatus: string,
  criticality: string,
  allergyType: string,
  category: string,
  notes: string,
  isSaving: boolean,
  modalVisible: boolean,
  errorMessage?: string,
  flagged: boolean,
  drugID?: string,
  activeIngredient?: string,
  medicationType?: string,
  showPrompt: boolean,
  changesMade: boolean,
}

/**
 * A component for adding and editing Allergies.
 * @class AddEditAllergy
 * @extends {React.Component}
 */
class AddEditAllergy extends React.Component<Props, State> {
  attributes = {
    name: '',
    reaction: '',
    reporter: '',
    onsetDate: '',
    lastOccurrenceDate: '',
    status: '',
    verificationStatus: '',
    criticality: '',
    allergyType: '',
    category: ALLERGIES.CATEGORY_OPTIONS[0],
    notes: '',
    flagged: true,
    drugID: undefined,
    activeIngredient: undefined,
    medicationType: undefined,
    showPrompt: false,
    changesMade: false,
    errorMessage: '',
  };

  /**
   * Creates an instance of AddEditAllergy.
   * @param {Props} props intial props
   */
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  /**
   * Set state attibutes from the model that we're editing
   * @return {void}
   */
  getInitialState() {
    const intialState = {
      isSaving: false,
      modalVisible: false,
      ...this.attributes,
    }
    if (!this.props.model) {
      return intialState
    }
    const { model } = this.props;
    const category = model.getOrBlank('category');
    const medicationType = model.getMedicationType();
    const { drugId: drugID, activeIngredient } =
      model.getMedicationDrugOrIngredients();
    return Object.assign({},
      intialState, {
        name: model.getOrBlank('name'),
        reaction: model.getOrBlank('reaction'),
        reporter: model.getOrBlank('reporter'),
        onsetDate: model.getOrBlank('onset_date'),
        lastOccurrenceDate: model.getOrBlank('last_occurrence_date'),
        status: model.getOrBlank('status'),
        verificationStatus: model.getOrBlank('verification_status'),
        criticality: model.getOrBlank('criticality'),
        allergyType: model.getOrBlank('allergy_type'),
        category,
        notes: model.getOrBlank('notes'),
        flagged: model.get('flagged', true),
        drugID,
        activeIngredient,
        medicationType,
      });
  }

  /**
   * Returns true if form is valid.
   * @returns {boolean}
   */
  isValid(): boolean {
    const dateValid = isDateValid(this.state.onsetDate) &&
      isDateValid(this.state.lastOccurrenceDate);
    if (!dateValid) {
      this.setState({ errorMessage: translate('fill_date_in_correct_format') });
      return false;
    }
    const isCategoryMedication = this.state.category === ALLERGIES.CATEGORY_OPTIONS[0];
    if (!isCategoryMedication && !this.state.name) {
      this.setState({ errorMessage: translate('name_cannot_be_empty_please_fill_out_the_field') });
      return false;
    }
    if (isCategoryMedication && !this.state.medicationType) {
      this.setState({ errorMessage: translate('please_select_valid_medication_type') });
      return false;
    }
    if (isCategoryMedication &&
      this.state.medicationType &&
      this.state.medicationType === ALLERGIES.MEDICATION_TYPES[2] &&
      !this.state.name) {
      this.setState({ errorMessage: translate('name_cannot_be_empty_please_fill_out_the_field') });
      return false;
    }
    if (isCategoryMedication &&
      this.state.medicationType &&
      this.state.medicationType === ALLERGIES.MEDICATION_TYPES[0] &&
      !this.state.drugID) {
      this.setState({ errorMessage: translate('please_select_valid_verified_drug') });
      return false;
    }
    if (isCategoryMedication &&
      this.state.medicationType &&
      this.state.medicationType === ALLERGIES.MEDICATION_TYPES[1] &&
      (!this.state.activeIngredient)) {
      this.setState({ errorMessage: translate('please_select_valid_active_ingredient') });
      return false;
    }
    return true;
  }

  /**
   * Returns true if form is valid.
   * @returns {any}
   */
  getCategoryMetaData() {
    const { category, drugID, activeIngredient, medicationType } = this.state;
    if (category && category === ALLERGIES.CATEGORY_OPTIONS[0]) {
      return {
        category_metadata: {
          category: this.state.category,
          type: medicationType,
          ...(drugID && medicationType === ALLERGIES.MEDICATION_TYPES[0]) && {
            drug_id: drugID,
          },
          ...(activeIngredient && medicationType === ALLERGIES.MEDICATION_TYPES[1]) && {
            active_ingredient_id: activeIngredient,
          },
        },
      };
    }
    return {
      ...category && {
        category_metadata: {
          category,
        },
      },
    };
  }


  /**
   * Saves the Allergy
   * @returns {void}
   */
  handleSave() {
    let model;
    const {
      name,
      reporter,
      onsetDate,
      lastOccurrenceDate,
      status,
      verificationStatus,
      criticality,
      allergyType,
      category,
      notes,
      reaction,
      flagged,
    } = this.state;
    const attributes = {
      name,
      reporter,
      onset_date: formatDateForSave(onsetDate),
      last_occurrence_date: formatDateForSave(lastOccurrenceDate),
      status,
      verification_status: verificationStatus,
      criticality,
      allergy_type: allergyType,
      category,
      notes,
      reaction,
      flagged,
    };
    const { patientID } = this.props;
    let notificationMessage;
    if (this.props.model && this.props.isEditing) {
      ({ model } = this.props); // Set as variable to stop false positive from Flow.
      model.set(Object.assign({}, { ...attributes }, this.getCategoryMetaData()));
      notificationMessage = 'allergy_update_success';
    } else {
      model = new AllergyModel(
        Object.assign({},
          { ...attributes, patient_id: patientID, reaction }, this.getCategoryMetaData()),
      );
      notificationMessage = 'allergy_added_success';
    }
    if (this.props.onModelSaved) {
      this.props.onModelSaved(model);
      this.setState({
        modalVisible: false,
        isSaving: false,
        changesMade: false,
        showPrompt: false,
      });
    } else if (this.props.saveModel) {
      this.props.saveModel(model)
        .then((savedModel) => {
          createSuccessNotification(translate(
            notificationMessage,
            { name: savedModel.get('name') }
          ));
          this.handleCancel();
        });
    }
    // TODO:
    // Update autocomplete with allergy name.
    // Utils.addToAutocomplete(this.state.name, 'drug_allergies');
  }

  /**
   * calls on save click
   * @returns {void}
   */
  onSaveClicked() {
    if (this.isValid()) {
      this.setState({
        isSaving: true,
      }, () => {
        this.handleSave();
      });
    }else {
      this.setState({ showPrompt: false });
    }
  }

  /**
   * Hides the modal when cancel is clicked.
   * @returns {void}
   */
  handleCancel() {
    const states = this.getInitialState();
    this.setState({ ...states });
  }

  /**
   * Called when date changes. Converts to a timestamp and updates state.
   * @param {SyntheticInputEvent} event The event
   * @returns {void}
   */
  handleDateChange(event: SyntheticInputEvent<*>) {
    const { state } = this;
    state[event.target.name] = convertDateToTimestamp(event.target.value);
    this.setState(state);
  }

  /**
   * Handle Allergy deletion
   * @param {AllergyModel} allergyModel the allergy to delete
   * @returns {void}
   */
  handleDeleteAllergy(allergyModel: AllergyModel) {
    this.setState({ modalVisible: false });
    if (this.props.saveModel) {
      this.props.saveModel(allergyModel.setVisible(false));
    }
    if (this.props.onDeleteModel) {
      this.props.onDeleteModel(allergyModel);
    }
  }

  /**
   * Get the sub category label
   * @param {string} value value
   * @returns {string}
   */
  getSubCategoryLabel(value: string) {
    switch (value) {
      case ALLERGIES.MEDICATION_TYPES[0]:
        return translate('label_for_drugs_medication_allergy');
      case ALLERGIES.MEDICATION_TYPES[1]:
        return translate('label_for_active_ingredient_medication_allergy');
      case ALLERGIES.MEDICATION_TYPES[2]:
        return (
        <Fragment>
          {`${translate('drugs')} - ${translate('unspecified')}`}   (<i>{translate('label_for_unspecified_medication_allergy')}</i>)
        </Fragment>
        );
      default:
        return translate(value);
    }
  }

  /**
   * get the active ingredients from query names
   * @param {string} name value
   * @returns {Promise<Arary<SelectOption>>}
   */
  fetchOptions(name: string) {
    return fetchActiveIngredients(name.toLowerCase());
  }

  /**
   * debounce the load option of async select
   * @param {string} name value
   * @param {Function} callback a async select callback to set the select options
   * @returns {void}
   */
  debouncedFetch = debounce((name, callback) => {
    this.fetchOptions(name)
      .then(async ({ activeIngredients, error }:
        { activeIngredients?: Array<ActiveIngredient>, error?: boolean }) => {
        if (!error && activeIngredients && activeIngredients.length > 0) {
          const options = activeIngredients.map(a => ({ value: a.id, label: a.name }));
          const appendedOptions = this.state.activeIngredient ? options
            .concat({ value: this.state.activeIngredient, label: this.state.name })
            : options;
          callback(appendedOptions);
          return;
        }
        callback(this.state.activeIngredient ?
          [{ value: this.state.activeIngredient, label: this.state.name }] : []);
      });
  }, 500);

  getVerifiedOptions = memoizeOne((verifiedDrugs: List<InventoryMapModel>) => verifiedDrugs.reduce(
    (options: List<SelectOption>, vd: InventoryMapModel) => {
      const drug = this.props.drugs.find(d => d.get('_id') === vd.get('drug_id'));
      if (drug) {
        return options.push({ value: vd.get('drug_id', ''), label: drug.get('name', '') });
      }
      return options;
    }, List(),
  ), (newArgs, lastArgs) => {
    if (newArgs[0].size !== lastArgs[0].size) {
      return false;
    }
    const prevDugIDs = lastArgs[0].map((d: InventoryMapModel) => d.get('drug_id')).toArray();
    const nextDugIDs = newArgs[0].map((d: InventoryMapModel) => d.get('drug_id')).toArray();
    return isEqual(prevDugIDs, nextDugIDs);
  });

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const isNonMedicationAllergy = this.state.category !== ALLERGIES.CATEGORY_OPTIONS[0];
    const renderAllergyName = this.state.category === ALLERGIES.CATEGORY_OPTIONS[0] &&
        (this.state.medicationType === ALLERGIES.MEDICATION_TYPES[2]);
    const renderMedicationSubType = (this.state.category === ALLERGIES.CATEGORY_OPTIONS[0]);
    const renderDrugSelection = renderMedicationSubType &&
      (this.state.medicationType === ALLERGIES.MEDICATION_TYPES[0]);
    const ingredientSelection = renderMedicationSubType &&
      (this.state.medicationType === ALLERGIES.MEDICATION_TYPES[1]);
    const verifiedDrugsOptions = this.getVerifiedOptions(this.props.verifiedDrugs);
    return (
      <StatelessModal
        id="addEditAllergy"
        buttonLabel={this.props.isEditing ? translate('edit') : translate('add')}
        buttonClass={this.props.isEditing ? 'o-text-button o-text-button--contextual' : 'o-button o-button--padded o-button--small u-width-180'}
        title={translate('allergy')}
        setVisible={(isVisible: boolean) => {
          if (this.state.changesMade && this.state.modalVisible && !isVisible) {
            this.setState({ showPrompt: true });
          } else {
            this.setState({ modalVisible: isVisible });
          }
        }}
        visible={this.state.modalVisible}
        explicitCloseOnly
        onClose={() => {
          if (!this.state.changesMade) {
            this.handleCancel();
          } else {
            this.setState({ showPrompt: true });
          }
        }}
        dataPublicHeader
      >
        <UnsavedDataModal
          visible={this.state.showPrompt}
          onSave={() => this.onSaveClicked()}
          onDiscard={() => this.handleCancel()}
          onCancel={() => this.setState({ showPrompt: false })}
        />
        <section className="o-form" id="addEditAllergyForm">
          { this.state.errorMessage &&
            <FormError isSticky>{this.state.errorMessage}</FormError>
          }
          <Select
            id="category"
            label={translate('category')}
            onValueChanged={(category: string) => {
              this.setState({
                category,
                medicationType: category === ALLERGIES.CATEGORY_OPTIONS[0] ?
                  ALLERGIES.MEDICATION_TYPES[0] : undefined,
                activeIngredient: '',
                drugID: undefined,
                name: '',
                changesMade: true,
              });
            }}
            options={ALLERGIES.CATEGORY_OPTIONS.map(value => ({ value, label: translate(value) }))}
            value={this.state.category}
          />
          {renderMedicationSubType &&
            <Radio
              id="sub-category"
              label={translate('medication_sub_category')}
              onValueChanged={medicationType =>
                this.setState({ medicationType, activeIngredient: '', drugID: undefined, name: '', changesMade: true, })}
              options={ALLERGIES.MEDICATION_TYPES.map((value: string) =>
                ({ value, label: this.getSubCategoryLabel(value) }))}
              value={this.state.medicationType}
              required={renderMedicationSubType}
              containerStyle={{ display: 'inline-block' }}
              optionStyle={{ justifyContent: 'flex-end', marginBottom: '10px' }}
              labelClassName="o-label--no-text-transform"
            />
          }
          {(renderAllergyName || isNonMedicationAllergy) && <Input
            autoFocus
            id="allergy-name"
            onValueChanged={(value: string) => this.setState({ name: value })}
            value={this.state.name}
            label={translate(isNonMedicationAllergy ? 'allergy_name' : 'drug_name')}
            required={renderAllergyName}
          />}
          {!renderAllergyName && renderDrugSelection && <Select
            id="status"
            label={translate('drug_name')}
            description={translate('select_one_of_the_verified_mapped_drug')}
            onValueChanged={drugID => this.setState({ drugID, name: this.props.drugs.find(d => d.get('_id') === drugID)?.get('name') || '', changesMade: true, })}
            options={verifiedDrugsOptions.toArray()}
            value={this.state.drugID}
            required={!renderAllergyName && renderDrugSelection}
          />}
          {!renderAllergyName && ingredientSelection &&
            <SelectAsync
              id="medication-active-ingredient"
              label={translate('active_ingrdient_name')}
              value={this.state.activeIngredient ?
                ({ value: this.state.activeIngredient, label: this.state.name }) : undefined}
              defaultOptions
              onChange={(newValue) => {
                this.setState({
                  activeIngredient: newValue ? newValue.value : '',
                  name: newValue ? newValue.label : '',
                  changesMade: true,
                });
              }}
              required={!renderAllergyName && ingredientSelection}
              loadOptions={this.debouncedFetch}
              isClearable
              placeholder={translate('search_active_ingredient')}
            />
          }
          <Input
            id="allergy-reaction"
            onValueChanged={value => this.setState({ reaction: value, changesMade: true, })}
            label={translate('reaction')}
            value={this.state.reaction}
          />
          <DateInput
            id="onset_date"
            label={translate('onset_date')}
            onChange={(value) => {
              this.setState({ onsetDate: value || '', changesMade: true, });
            }}
            value={
              this.state.onsetDate && moment(this.state.onsetDate).isValid() ?
                moment(this.state.onsetDate) : this.state.onsetDate
            }
          />
          <DateInput
            id="last_occurrence_date"
            label={translate('last_occurrence_date')}
            onChange={(value) => {
              this.setState({ lastOccurrenceDate: value || '', changesMade: true, });
            }}
            value={
              this.state.lastOccurrenceDate && moment(this.state.lastOccurrenceDate).isValid() ?
                moment(this.state.lastOccurrenceDate) : this.state.lastOccurrenceDate
            }
          />
          <Select
            id="status"
            label={translate('status')}
            onValueChanged={status => this.setState({ status, changesMade: true, })}
            options={ALLERGIES.STATUS_OPTIONS.map(value => ({ value, label: translate(value) }))}
            value={this.state.status}
          />
          <Select
            id="status"
            label={translate('verification_status')}
            onValueChanged={status => this.setState({ verificationStatus: status, changesMade: true, })}
            options={
              ALLERGIES.VERIFICATION_STATUS_OPTIONS
                .map(value => ({ value, label: translate(value) }))
            }
            value={this.state.verificationStatus}
          />
          <Select
            id="criticality"
            label={translate('criticality')}
            onValueChanged={criticality => this.setState({ criticality, changesMade: true,})}
            options={ALLERGIES.CRITICALITY_OPTIONS.map(v => ({ value: v, label: translate(v)}))}
            value={this.state.criticality}
          />
          <Select
            id="allergy_type"
            label={translate('allergy_type')}
            onValueChanged={allergyType => this.setState({ allergyType, changesMade: true, })} // eslint-disable-line camelcase
            options={[{ value: 'allergy', label: translate('allergy') }, { value: 'intolerance', label: translate('intolerance') }]}
            value={this.state.allergyType}
          />
          <TextArea
            id="allergy-notes"
            onValueChanged={value => this.setState({ notes: value, changesMade: true, })}
            label={translate('notes')}
            value={this.state.notes}
          />
          <Radio
            id="flagged"
            label={translate('flagged')}
            description={translate('flagged_desc')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.flagged.toString()}
            onValueChanged={newValue => this.setState({ flagged: newValue === 'true', changesMade: true, })}
          />
        </section>
        { this.props.model &&
          <DeleteButton
            className="o-delete-button"
            confirmTitle={translate('confirm_deleting_allergy', { name: this.props.model.get('name') })}
            onDelete={() => this.handleDeleteAllergy(this.props.model)}
          >
              {translate('delete')}
          </DeleteButton>
        }
        <ModalFooter>
          <SaveButton
            dataPublic
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default AddEditAllergy;
