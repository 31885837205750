import React from 'react';
import { List } from 'immutable';

import { UNICODE } from './../../constants';
import translate from './../../utils/i18n';
import Table from './../table/table';
import LabTestActionButton from './labTestActionButton';
import { getProcedureTypesTotalPrice } from './../../utils/labs';
import LabTestSubItems from './../labTests/labTestSubItems';
import { renderPrice, renderTextColorized } from './../../utils/tables';
import Button from './../buttons/button';

import type ProviderModel from './../../models/providerModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProcedureRequestModel from './../../models/procedureRequestModel';
import type SpecimenModel from './../../models/specimenModel';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type { SaveModel, SaveModels, Config, User } from './../../types';
import type EncounterModel from '../../models/encounterModel';

type Props = {
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  specimens?: List<SpecimenModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  originalProcedureTypes?: List<ProcedureTypeModel>,
  procedureRequests?: List<ProcedureRequestModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
  config: Config,
  isSearching?: boolean,
  searchQuery?: string,
  isOrderedTests?: boolean,
  showPagination?: boolean,
  showStar?: boolean,
  isShowingRequestedLab?: boolean,
  encounterID: string,
  patientID: string,
  encounter?: EncounterModel,
};

type State = {};

/**
 * Component displaying Lab Tests table.
 * @class LabTestsTable
 * @extends {React.Component}
 */
class LabTestsTable extends React.Component<Props, State> {
  static defaultProps = {
    searchQuery: '',
    isOrderedTests: false,
    showPagination: true,
    showStar: true,
    isShowingRequestedLab: false,
  };

  /**
   * Creates an instance of LabTestsTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Generates the table rows from the LabTestsTable.
   * @param {ProcedureTypeModel} procedureType a procedureType model
   * @param {ProviderModel} providers a providers model
   * @param {ProcedureRequestModel} procedureRequests a procedureRequests model
   * @returns {[Row]} The rows of the table
   */
  getRow(
    procedureType: ProcedureTypeModel,
    providers: List<ProviderModel>,
    procedureRequests?: List<ProcedureRequestModel>,
  ) {

    if (procedureType.isMissing() && procedureRequests) {
      const procedureRequest = procedureRequests.find(p => p.get('procedure_type_id') === procedureType.get('_id'));
      return {
        name: translate('data_not_found').toUpperCase(),
        action: this.getActionButton('cancel', procedureType, procedureRequest),
        is_missing_data: true,
      };
    }

    const provider = procedureType.getProvider(providers);
    const rowData = [];
    if (this.props.showStar) {
      rowData.push({ star: this.getStarButton(procedureType) });
    }
    rowData.push({
      name: procedureType.get('name'),
      procedure_code: procedureType.getTestCode() || UNICODE.EMDASH,
      provider_id: provider ? provider.get('name') : UNICODE.EMDASH,
      cost_price: procedureType.get('cost_price'),
      price: procedureType.get('price'),
      sub_items: procedureType.get('sub_items'),
    });
    if (location.hash === '#/settings/lab-tests') {
      rowData.push({
        edit: this.getActionButton('edit', procedureType),
        delete: this.getActionButton('delete', procedureType),
      });
    } else if (!this.props.isShowingRequestedLab && procedureRequests) {
      const procedureRequest = procedureRequests.find(p => p.get('procedure_type_id') === procedureType.get('_id'));
      rowData.push({
        action: this.getActionButton(procedureRequest ? 'cancel' : 'request', procedureType, procedureRequest),
      });
    }
    return Object.assign({}, ...rowData);
  }

  /**
   * Returns the button that will be use in the table
   * @param {string} type The action type eg. 'cancel', 'delete', 'request'
   * @param {ProcedureTypeModel} procedureType A ProcedureTypeModel
   * @param {ProcedureRequestModel?} procedureRequest A ProcedureRequestModel
   * @returns {React.Component}
   */
  getActionButton(type: string, procedureType: ProcedureTypeModel,
    procedureRequest: ProcedureRequestModel | null | undefined = undefined) {
    const {
      user, saveModel, providers, specimens,
      config, encounterID, patientID, saveModels, procedureStatuses, originalProcedureTypes,
    } = this.props;
    return (
      <LabTestActionButton
        type={type}
        procedureType={procedureType}
        procedureRequest={procedureRequest}
        saveModel={saveModel}
        saveModels={saveModels}
        user={user}
        providers={providers}
        specimens={specimens}
        config={config}
        encounterID={encounterID}
        patientID={patientID}
        procedureStatuses={procedureStatuses}
        procedureTypes={originalProcedureTypes}
        encounter={this.props.encounter}
      />
    );
  }

  /**
   * Returns an star button component for the given item.
   * @param {ProcedureTypeModel} item a procedureType model
   * @returns {React.Component} A star button.
   */
  getStarButton(item: ProcedureTypeModel) {
    const starred = item.isStarred();
    const img = starred ? 'icon_star' : 'icon_star_unchecked';
    return (
      <Button
        onClick={() => this.props.saveModel(item.toggleStarred())}
        className="o-text-button"
        style={{ marginLeft: 'auto', marginRight: '20px' }}
        dataPublic
      >
        <img
          src={`static/images/${img}.png`}
          alt={starred ? translate('starred') : translate('star')}
          style={{ width: '20px', height: '20px' }}
        />
      </Button>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      procedureTypes, providers, isSearching, searchQuery, isOrderedTests,
      showPagination, isShowingRequestedLab, procedureRequests, showStar, originalProcedureTypes,
    } = this.props;
    const isOnLabTestsPage = (location.hash === '#/settings/lab-tests');
    const columns = [];
    if (showStar) {
      columns.push({ accessor: 'star', Header: '', width: 50 });
    }
    columns.push(
      {
        accessor: 'name',
        Header: translate('test_name'),
        Footer: isOrderedTests ? (
          <span data-public style={{ marginLeft: 10 }}>
            <strong>{translate('total_price')}</strong>
          </span>
        ) : '',
        Cell: (props) => {
          if (props.original.is_missing_data) {
            return renderTextColorized(props, 'RED');
          }
          return props.value;
        },
        filterable: !isOrderedTests,
      },
      { accessor: 'procedure_code', Header: translate('test_id'), filterable: !isOrderedTests },
      { accessor: 'provider_id', Header: translate('laboratory'), filterable: !isOrderedTests },
      { accessor: 'cost_price', Header: translate('cost_price'), Cell: renderPrice },
      {
        accessor: 'price',
        Header: translate('selling_price'),
        Cell: renderPrice,
        Footer: isOrderedTests ? (
          <span style={{ marginLeft: 10 }}>
            <strong>
              {isOrderedTests ? getProcedureTypesTotalPrice(procedureTypes) : UNICODE.EMDASH}
            </strong>
          </span>
        ) : '',
      },
    );
    if (isOnLabTestsPage) {
      columns.push(
        { accessor: 'edit', Header: '', sortable: false, show: true, width: 100 },
        { accessor: 'delete', Header: '', sortable: false, width: 100, show: true },
      );
    } else if (!this.props.isShowingRequestedLab) {
      columns.push(
        { accessor: 'action', Header: 'Action', sortable: false, show: true, width: 200 },
      );
    }
    let defaultTitle;
    if (isOrderedTests) {
      defaultTitle = 'ordered_tests';
    } else if (isShowingRequestedLab) {
      defaultTitle = 'lab_test_requested';
    } else {
      defaultTitle = 'list_of_lab_tests';
    }
    const procedureTypesList = (isOrderedTests || originalProcedureTypes) ?
      originalProcedureTypes : procedureTypes;
    return (
      <div className="o-card" style={location.hash === '#/lab-requests' ? { marginBottom: 140 } : {}}>
        <div className="o-card__title">
          {(isSearching && !isOrderedTests && !isShowingRequestedLab)
            ?
              <h2 style={{ textTransform: 'capitalize', fontStyle: 'italic' }}>{translate('you_have_searched_for')}
              : <span style={{ fontWeight: 'bold', fontFamily: 'robotomedium', fontStyle: 'normal', textTransform: 'initial' }}> {searchQuery}</span>
              </h2>
            : <h2 data-public style={{ fontFamily: 'robotomedium' }}>{translate(defaultTitle)}</h2>
          }
        </div>
        { ((isOrderedTests && !procedureTypes.size) || !procedureTypes.size) ?
          <div className="u-flex-row">
            <p className="u-padding--1ws u-flex-centre">
              {isOrderedTests ? translate('no_lab_tests_ordered') : translate(isSearching ? 'no_lab_tests_found' : 'no_lab_tests_created')}
            </p>
          </div>
          : <Table
            columns={columns}
            data={procedureTypes
              .sort((a, b) => b.get('starred') - a.get('starred'))
              .map(procedureType =>
                this.getRow(procedureType, providers, procedureRequests)).toArray()
            }
            noDataText=""
            showPagination={showPagination}
            defaultSorted={isOnLabTestsPage ? [{ id: 'name', desc: false }] : []}
            subComponent={(row) => {
              const hasSubItems = row.original.sub_items && row.original.sub_items.length;
              if (hasSubItems) {
                return (
                  <LabTestSubItems
                    procedureTypes={procedureTypesList}
                    subItems={row.original.sub_items}
                  />
                );
              }
              return null;
            }}
            trProperties={(state, rowInfo) => ({
              'sub-items-count': rowInfo.original.sub_items ?
                procedureTypesList.filter(procedureType => rowInfo.original.sub_items.includes(procedureType.get('_id'))).size :
                0,
            })}
          />
          }
      </div>
    );
  }
}

export default LabTestsTable;
