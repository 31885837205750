import React from 'react';
import { List } from 'immutable';
import { Div } from 'glamorous';

import { convertNumberToPrice, getConfirmation } from './../../utils/utils';
import { exportClaimInvoices } from './../../utils/export';
import MenuButton from '../buttons/menuButton';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import ClaimInvoiceTable from './claimInvoiceTable';
import ClaimInvoicePaymentsTable from './../claimInvoicesPayment/claimInvoicePaymentsTable';
import KeyValue from './../layout/keyValue';
import { voidClaimInvoice, regenerateClaimInvoice, getAmountOutstanding, getAmountPaid } from './../../utils/claims';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { printClaimInvoice } from './../../utils/print';
import { hasPermission, createPermission } from './../../utils/permissions';
import { debugPrint } from '../../utils/logging';

import StatelessModal from './../modals/statelessModal';
import RecordPanelPaymentForm from './../claimInvoicesPayment/recordPanelPaymentForm';
import ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import ClaimReconciliationModel from './../../models/claimReconciliationModel';
import type BankModel from './../../models/bankModel';
import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type { SaveModels, SaveModel, Config, User, Model, APIResponse, ClaimInvoiceMetadata } from './../../types';
import type { MenuAction } from '../buttons/menuButton';
import type DrugModel from './../../models/drugModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import Header from './../header/header';
import ClaimModel from 'src/models/claimModel';

type Props = {
  claimInvoice: ClaimInvoiceModel,
  coveragePayors: List<CoveragePayorModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  saveClaimInvoiceModels: (claimInvoiceModels: Array<ClaimInvoiceMetadata>) => Promise<APIResponse<ClaimInvoiceModel | ClaimModel> | Array<ClaimInvoiceModel|ClaimModel>>,
  voidClaimInvoiceModels: (id: Array<string>) => Promise<APIResponse<ClaimInvoiceModel> | ClaimInvoiceModel[]>,
  config: Config,
  user: User,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimRecons: List<ClaimReconciliationModel>,
  banks: List<BankModel>,
  paymentTypes: List<PaymentTypeModel>,
  patientStubs: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
};

type State = {
  isProcessingAction: boolean,
  paymentModalIsVisible: boolean,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimRecons: List<ClaimReconciliationModel>,
};

/**
 * A component that displays claim invoice.
 * @class ClaimInvoice
 * @extends {Component}
 */
class ClaimInvoice extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of ClaimInvoice.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isProcessingAction: false,
      paymentModalIsVisible: false,
      claimInvoicePayments: this.props.claimInvoicePayments,
      claimRecons: this.props.claimRecons,
    };
  }

  /**
   * Handles the MenuButton selection
   * @param {string} value The value selected
   * @returns {void}
   */
  handleMenuSelection(value: string) {
    this.setState({ isProcessingAction: true });
    if (value === 'recordNewPayment') {
      this.setState({ paymentModalIsVisible: true });
    } else if (value === 'voidInvoice') {
      getConfirmation(translate('confirm_void_claim_invoice'))
        .then(
          () => this.props.voidClaimInvoiceModels(
            [this.props.claimInvoice.get('_id')],
          )
            .then(( savedModels ) => {
              (savedModels as APIResponse<ClaimInvoiceModel>).unavailable &&
                voidClaimInvoice(
                  this.props.claimInvoice,
                  this.props.saveModels,
                  this.state.claimInvoicePayments,
                )
            })
            .then(() => {
              createSuccessNotification(translate('claim_invoice_successfully_voided'));
              this.setState({ isProcessingAction: false });
            })
            .catch((error) => {
              debugPrint(error, 'error');
              createErrorNotification(translate('claim_invoice_void_failed'));
              this.setState({ isProcessingAction: false });
            }),
          () => this.setState({ isProcessingAction: false }),
        );
    } else if (value === 'regenerateClaimInvoice') {
      getConfirmation(translate('confirm_regenerate_claim_invoice'))
        .then(
          () => regenerateClaimInvoice(
            this.props.claimInvoice,
            this.props.saveClaimInvoiceModels,
            this.props.saveModels,
            this.props.patientStubs,
            this.props.salesItems,
            this.props.drugs,
            this.props.config,
            this.state.claimInvoicePayments,
          )
            .then((newInvoice) => {
              createSuccessNotification(
                translate('claim_invoice_successfully_regenerated'),
                {
                  label: translate('click_here_to_view_new_invoice'),
                  callback: () => { location.hash = `/accounts/claim-invoices/${newInvoice.get('_id')}`; },
                },
              );
              this.setState({ isProcessingAction: false });
            })
            .catch((error) => {
              if (error !== 'TIMEOUT') {
                debugPrint(error, 'error');
                createErrorNotification(translate('claim_invoice_regeneration_failed'));
              }
              this.setState({ isProcessingAction: false });
            }),
          () => this.setState({ isProcessingAction: false }),
        );
    } else if (value === 'printClaimInvoice') {
      const coveragePayor = this.props.coveragePayors.find(c => c.get('_id') === this.props.claimInvoice.get('coverage_payor_id'));
      if (!coveragePayor) {
        throw new Error('Coverage Payor not found');
      }
      printClaimInvoice(this.props.claimInvoice, coveragePayor,
        this.props.config)
        .then(() => this.setState({ isProcessingAction: false }));
    } else if (value === 'exportClaimInvoice') {
      exportClaimInvoices(List([this.props.claimInvoice]))
        .then(() => this.setState({ isProcessingAction: false }));
    } else {
      this.setState({ isProcessingAction: false });
    }
  }

  /**
   * Gets the menu actions available to the user.
   * @returns {Array<MenuAction>}
   */
  getMenuActions(): Array<MenuAction> {
    const menuActions = [];
    if (hasPermission(this.props.user, List([createPermission('claim_invoice_payment', 'create')]))) {
      menuActions.push({
        label: translate('record_new_payment'),
        value: 'recordNewPayment',
        dataPublic: true,
      });
    }
    if (hasPermission(this.props.user, List([createPermission('claim_invoice', 'update')]))) {
      menuActions.push({
        label: translate('regenerate_claim_invoice'),
        value: 'regenerateClaimInvoice',
        dataPublic: true,
      });
    }
    menuActions.push({
      label: translate('print_claim_invoice'),
      value: 'printClaimInvoice',
      dataPublic: true,
    });
    menuActions.push({
      label: translate('export_claim_invoice'),
      value: 'exportClaimInvoice',
      dataPublic: true,
    });
    if (hasPermission(this.props.user, List([createPermission('claim_invoice', 'delete')]))) {
      menuActions.push({
        label: translate('void_claim_invoice'),
        value: 'voidInvoice',
        isDanger: true,
        dataPublic: true,
      });
    }
    return menuActions;
  }

  /**
   * Resets the state for display of the record payment modal.
   * @returns {void}
   */
  resetModal() {
    this.setState({
      paymentModalIsVisible: false,
      isProcessingAction: false,
    });
  }

  /**
   * Saves invoice payment as voided in state after it is voided from table.
   * @param {ClaimInvoicePaymentModel} payment The payment to update
   * @returns {void}
   */
  updatePaymentOnDelete(payment: ClaimInvoicePaymentModel) {
    if (payment) {
      const payments = this.state.claimInvoicePayments.map((p) => {
        if (p.get('_id') === payment.get('_id')) {
          return payment;
        }
        return p;
      });
      this.setState({
        claimInvoicePayments: List(payments),
      });
    }
  }

  /**
   * Saves a claim reconciliation and related payments.
   * @param {Array<Model>} models The models that got saved
   * @returns {void}
   */
  onSavePayments(models: Array<Model>) {
    this.resetModal();
    const invoicePayments = this.state.claimInvoicePayments.concat(List(models.filter(m => m.get('type') === 'claim_invoice_payment')));
    const claimRecons = this.state.claimRecons.concat(List(models.filter(m => m.get('type') === 'claim_reconciliation')));
    this.setState({
      claimInvoicePayments: List(invoicePayments),
      claimRecons: List(claimRecons),
    });
  }

  /**
   * Set the claimInvoicePayments of the state.
   * @param {claimInvoicePayments} claimInvoicePayments Component state
   * @returns {void}
   */
  setClaimInvoicePayments(claimInvoicePayments: List<ClaimInvoicePaymentModel>) {
    this.setState({ claimInvoicePayments });
  }

  /**
   * Check and update state if claimInvoicePayments props are voided
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps : Props) {
    if ((this.props.claimInvoicePayments.filter(p => p.isVoid())).size !== (
      prevProps.claimInvoicePayments.filter(p => p.isVoid())).size) {
      this.setClaimInvoicePayments(this.props.claimInvoicePayments);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const coveragePayor = this.props.coveragePayors.find(c => c.get('_id') === this.props.claimInvoice.get('coverage_payor_id'));
    const coveragePayorName = coveragePayor ? coveragePayor.get('name') : translate('coverage_payor_not_found');
    return (
      <ContentTransition className="o-main__content">
        <div className="c-billing__content">
          <h1 data-public className="o-title">{translate('claim_invoice_for')}</h1>
          <Div css={{ display: 'flex', justifyContent: 'space-between' }} className="u-margin--standard">
            <div>
              <KeyValue label={translate('panel_name')} value={coveragePayorName} />
              <KeyValue label={translate('invoice_number')} value={this.props.claimInvoice.get('internal_clinic_id')} />
              <KeyValue label={translate('invoice_month')} value={this.props.claimInvoice.getInvoiceMonth()} />
              <KeyValue label={translate('date_generated')} value={this.props.claimInvoice.getDateGenerated()} />
              <KeyValue label={translate('total_number_of_claims')} value={this.props.claimInvoice.get('items', { claims: [] }).claims.length} />
              <div className="u-margin-bottom--1ws" />
              <KeyValue label={translate('total_amount')} value={this.props.claimInvoice.getAmountClaimed()} />
              <KeyValue label={translate('total_paid')} value={convertNumberToPrice(getAmountPaid(this.props.claimInvoice, this.state.claimInvoicePayments))} />
              <KeyValue isDanger label={translate('total_outstanding')} value={convertNumberToPrice(getAmountOutstanding(this.props.claimInvoice, this.state.claimInvoicePayments))} />
            </div>
            {
              !this.props.claimInvoice.get('is_void') &&
              <div className="u-align-self-bottom">
                <MenuButton
                  label={translate('actions')}
                  className="u-flex-right"
                  items={this.getMenuActions()}
                  disabled={this.state.isProcessingAction}
                  onValueSelected={value => this.handleMenuSelection(value)}
                  dataPublic
                />
              </div>
            }
          </Div>
          <StatelessModal
            id="invoice-payment-form"
            visible={this.state.paymentModalIsVisible}
            setVisible={() => this.resetModal()}
            noButton
            explicitCloseOnly
            title={translate('record_new_payment')}
            onClose={() => this.resetModal()}
            dataPublicHeader
          >
            <RecordPanelPaymentForm
              onSaveComplete={models =>
                this.onSavePayments(models)
              }
              coveragePayors={this.props.coveragePayors}
              claimInvoicePayments={this.state.claimInvoicePayments}
              claimInvoices={List([this.props.claimInvoice])}
              banks={this.props.banks}
              saveModels={this.props.saveModels}
              singleInvoice
              paymentTypes={this.props.paymentTypes}
            />
          </StatelessModal>
          {hasPermission(this.props.user, List([createPermission('claim_invoice_payment', 'read')])) &&
          <div className="o-card">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{translate('claim_payments')}</h1>
            </Header>
            <ClaimInvoicePaymentsTable
              claimInvoicePayments={this.state.claimInvoicePayments}
              claimRecons={this.state.claimRecons}
              saveModel={this.props.saveModel}
              onDeleteClicked={payment => this.updatePaymentOnDelete(payment)}
              user={this.props.user}
            />
          </div>
          }
          <div className="o-card">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{translate('claim_invoice')}</h1>
            </Header>
            <ClaimInvoiceTable
              claimInvoice={this.props.claimInvoice}
            />
          </div>
        </div>
      </ContentTransition>
    );
  }
}

export default ClaimInvoice;
