import React, { useState } from 'react';
import { List, Map } from 'immutable';
import translate from './../../utils/i18n';
import StatelessModal from '../modals/statelessModal';
import FormError from '../formError';
import Keypress from '../keypress';
import { createPermission, hasSomePermission } from './../../utils/permissions';
import ModalFooter from '../modals/modalFooter';
import { SaveModel, User } from './../../types';
import SaveButton from '../buttons/saveButton';
import Input from '../inputs/input';
import Select from '../inputs/select';
import Radio from '../inputs/radio';
import { validateAndTrimString } from '../../utils/utils';
import DiscountChargeModel from '../../models/discountChargeModel';
import { logDiscountChargeCreated, logDiscountChargeEdited } from '../../utils/logging';
import { createSuccessNotification } from '../../utils/notifications';
import { updateClinicConfig } from '../../utils/db';

type Props = {
  config: Config;
  updateConfig: (config: Config) => void,
  user: User;
  isVisible: boolean;
  discountCharge: any;
  hideModal: () => void;
  saveModel: SaveModel,
}

type DiscountChargeAttributes = {
  name: string;
  method: 'discount' | 'charge';
  type: 'fixed-value' | 'fixed-percent';
  amount: number;
  alwaysApplied: boolean,
};

/**
 * Form component used for creating and editing discounts and charges in settings
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const DiscountsChargesForm = (props: Props) => {
  const [formError, setFormError] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [isSaving, setSaving] = useState(false);

  /**
   * Returns DiscountChargeAttributes for showing initial value
   * @returns {Object}
   */
  const getInitialDiscountChargeAttributes = (): DiscountChargeAttributes => {
    if (props.discountCharge) {
      return {
        name: props.discountCharge.get('name'),
        method: props.discountCharge.get('method'),
        type: props.discountCharge.get('calculation_type'),
        amount: props.discountCharge.get('amount'),
        alwaysApplied: props.discountCharge.get('always_applied'),
      };
    }
    return {
      name: '',
      method: 'discount',
      type: 'fixed-percent',
      amount: 0,
      alwaysApplied: false,
    };
  };

  const [discountChargeAttributes, setDiscountChargeAttributes] = useState<DiscountChargeAttributes>(getInitialDiscountChargeAttributes());

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  const validateItem = (): boolean => {
    const trimmedFields = validateAndTrimString(discountChargeAttributes) as DiscountChargeAttributes;
    const {
      name, method, type, amount,
    } = trimmedFields;
    if (!name) {
      setFormError(translate('name_is_required'));
      return false;
    }
    if (!method) {
      setFormError(translate('discount_s_charge_is_required'));
      return false;
    }
    if (!type) {
      setFormError(translate('type_is_required'));
      return false;
    }
    if (amount === undefined || amount === null || amount === '') {
      setFormError(translate('amount_is_required'));
      return false;
    }
    return true;
  };

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  const onSaveClicked = () => {
    if (validateItem()) {
      setSaving(true);
      const discountChargeModelData = {
        name: validateAndTrimString(discountChargeAttributes.name),
        method: discountChargeAttributes.method,
        calculation_type: discountChargeAttributes.type,
        amount: discountChargeAttributes.amount,
        always_applied: discountChargeAttributes.alwaysApplied,
      };
      if (props.discountCharge) {
        const discountChargeModel = props.discountCharge.replaceAtrributes(discountChargeModelData);
        return props.saveModel(discountChargeModel).then((resp) => {
          if (resp) {
            logDiscountChargeEdited();
            createSuccessNotification(translate('discount_charge_updated'));
            setDiscountChargeAttributes(getInitialDiscountChargeAttributes());
            props.hideModal();
          }
          setSaving(false);
          return true;
        });
      }
      const discountChargeModel = new DiscountChargeModel(discountChargeModelData);
      return props.saveModel(discountChargeModel).then((resp) => {
        if (resp) {
          logDiscountChargeCreated();
          createSuccessNotification(translate('discount_charge_added'));
          setDiscountChargeAttributes(getInitialDiscountChargeAttributes());
          props.hideModal();
          const orderArr = props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()).push(discountChargeModel.get('_id'));
          updateClinicConfig(
            props.config.toJS(),
            Map().setIn(['discounts_charges', 'discounts_charges_order'], orderArr).toJS(),
            props.updateConfig,
          )
            .then(() => {
              setSaving(false);
            });
        } else {
          setSaving(false);
        }
        return true;
      });
    }
  };

  /**
   * Called on Create/Edit Form's onClose
   * @returns {void}
   */
  const handleFormClose = () => {
    if (!formError && formDirty) {
      setFormError(translate('unsaved_changes_error'));
    } else {
      props.hideModal();
    }
  };

  /**
   * Set current encounter flow name to Custom Flow onChange of flow attributes
   * @param {DiscountChargeAttributes} updatedDiscountChargeAttributes FlowAttributes
   * @return {void}
   */
  const setDiscountChargeEdited = (updatedDiscountChargeAttributes: DiscountChargeAttributes) => {
    if (!formDirty) {
      setFormDirty(true);
    }
    setDiscountChargeAttributes(
      Object.assign({}, updatedDiscountChargeAttributes),
    );
  };

  /**
   *
   * @param {string} fieldName Key in stageAttributes
   * @param {string | Array<string> | boolean} value New value to update
   * @returns {void}
   */
  const handleChange = (fieldName: string, value: string | Array<string> | boolean | number) => {
    const updatedDiscountChargeAttributes = Object.assign({}, discountChargeAttributes, { [fieldName]: value });
    setDiscountChargeEdited(updatedDiscountChargeAttributes);
  };

  return (
    <StatelessModal
      id="discounts-form"
      visible={props.isVisible}
      setVisible={handleFormClose}
      title={translate(props.discountCharge ? 'edit_x' : 'create_x', { x: translate('discount_s_charge') })}
      dataPublicHeader
      noButton
      explicitCloseOnly
    >
      <Keypress className="o-form" onEnterPressed={() => onSaveClicked()}>
        <div className="u-margin--standard">
          {formError && <FormError isSticky>{formError}</FormError>}
          <Input
            id="item_name"
            label={translate('name')}
            type="text"
            value={discountChargeAttributes.name}
            onValueChanged={value => handleChange('name', value)}
            required
            autoFocus
          />
          <Select
            id="item_method"
            label={translate('discount_s_charge')}
            options={[
              { label: translate('discount'), value: 'discount' },
              { label: translate('charge'), value: 'charge' },
            ]}
            value={discountChargeAttributes.method}
            onValueChanged={value => handleChange('method', value)}
            isValueCaseInsensitive
            required
          />
          <Select
            id="item_type"
            label={translate('type')}
            options={[
              { label: translate('fixed_percent'), value: 'fixed-percent' },
            ]}
            value={discountChargeAttributes.type}
            onValueChanged={value => handleChange('type', value)}
            isValueCaseInsensitive
            required
          />
          <Input
            id="item_amount"
            label={translate('amount')}
            value={discountChargeAttributes.amount}
            type="number"
            onValueChanged={value => handleChange('amount', value)}
            required
          />
          <Radio
            id="item"
            label={translate('always_applied_during_billing')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={discountChargeAttributes.alwaysApplied?.toString() || 'false'}
            onValueChanged={newValue => handleChange('alwaysApplied', newValue === 'true')}
          />
        </div>
      </Keypress>
      {hasSomePermission(props.user, List([createPermission('discounts_charges', 'create'), createPermission('discounts_charges', 'update')])) &&
        <ModalFooter>
          <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
            <SaveButton
              className="o-button--small"
              isSaving={isSaving}
              onClick={onSaveClicked}
            />
          </div>
        </ModalFooter>
      }
    </StatelessModal>
  );
};

export default DiscountsChargesForm;
