import * as React from 'react';
import { List } from 'immutable';

import Table from './../table/table';
import translate from './../../utils/i18n';
import { renderDateTime } from './../../utils/tables';

/* eslint-disable camelcase */
export type VitalsTableRow = {
  metric_type?: string,
  metric_entry: string,
  date: number,
  delete?: React.Node,
};
/* eslint-eanble camelcase */

type Props = {
  showMetricType: boolean,
  data: Array<VitalsTableRow>,
  minRows: number,
};

const columns = List([
  { accessor: 'metric_type', Header: translate('vitals') },
  { accessor: 'metric_entry', Header: translate('readings') },
  { accessor: 'date', Header: translate('date'), Cell: renderDateTime },
  {
    accessor: 'delete', Header: '', sortable: false, minWidth: 100, width: 100,
  },
]);

/**
 * A table for displaying MetricEntryModels
 * @class VitalsTable
 * @extends {React.Component<Props, State>}
 */
class VitalsTable extends React.PureComponent<Props> {
  static defaultProps = {
    showMetricType: false,
    minRows: 0,
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Table
        columns={this.props.showMetricType ? columns.toArray() : columns.shift().toArray()}
        data={this.props.data}
        noDataText={translate('no_vitals_history')}
        minRows={this.props.minRows}
        defaultSorted={[{ id: 'date', desc: true }]}
        showPagination={!this.props.showMetricType}
        defaultPageSize={this.props.showMetricType ? 10 : 5}
      />
    );
  }
}

export default VitalsTable;
