import React from 'react';
import { Page } from 'catalog';

import StyleguideReset from './styleguideReset';

/**
 * Accessibility section of styleguide.
 * @class StyleguideAccessibility
 * @extends {React.Component<Props, State>}
 */
const StyleguideAccessibility = () =>
  <StyleguideReset>
    <Page>
      <h2 data-public>Accessibility (A11y)</h2>
      <p>
        We have not yet sorted out what goes here, but in the meantime here are some good references
        and points of view:
      </p>
      <ul>
        <li><a href="https://a11yproject.com/">The A11Y Project</a></li>
        <li><a href="https://boagworld.com/accessibility/inclusive-design/">Stop Talking About Accessibility. Start Talking About Inclusive Design.</a></li>
      </ul>
      <p>
        <img src="https://i.imgur.com/qXgj7Uq.jpg" alt="A diagram of different type of Accessibility concerns." />
      </p>
    </Page>
  </StyleguideReset>;

export default StyleguideAccessibility;
