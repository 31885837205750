import React from 'react';
import { Link } from 'react-router-dom';

import translate from './../../utils/i18n';
import LoadingIndicator from './../loadingIndicator';
import { fetchTextAsset } from './../../utils/utils';
import { isReferralMode } from './../../utils/router';
import Header from './../header/header';

import type CaseNoteFileModel from './../../models/caseNoteFileModel';

type Props = {
  casenote: CaseNoteFileModel,
  casenoteIndex: number, // 1-based, not 0-based.
  casenoteTotal: number,
  previousLink?: string,
  nextLink?: string,
}

type State = {
  isLoading: boolean,
  content: string,
};

/**
 * A component that displays a single text casenote's thumbnail.
 * @class TextCasenote
 * @extends {React.Component}
 */
class TextCasenote extends React.Component<Props, State> {
  /**
   * Creates an instance of TextCasenote.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      content: '',
    };
    const assetID = props.casenote.getAssetID(0);
    if (assetID) {
      this.fetchAsset(assetID);
    }
  }

  /**
   * Fetches the asset and applies it to state.
   * @param {string} assetID Asset ID
   * @returns {void}
   */
  fetchAsset(assetID: string) {
    fetchTextAsset(assetID).then(content => this.setState({ content, isLoading: false }));
  }

  /**
   * Fetches the new asset if one has been given.
   * @param {Props} nextProps Next Props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    const nextAsset = nextProps.casenote.getAssetID(0);
    const currentAsset = this.props.casenote.getAssetID(0);
    if (nextAsset && nextAsset !== currentAsset) {
      this.setState({ isLoading: true });
      this.fetchAsset(nextAsset);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { casenote } = this.props;
    return (
      <div className="c-casenote">
        <Header className="c-casenote__header">
          <div>{translate('note')}: <strong>{this.props.casenoteIndex}/{this.props.casenoteTotal}</strong></div>
          <div>{translate('date_created')}: <strong>{casenote.getCreationDate()}</strong></div>
        </Header>
        <section
          className="c-casenote__text-asset"
        >
          { this.state.isLoading && <LoadingIndicator /> }
          { !this.state.isLoading && <h1>{casenote.get('title', translate('untitled'), false)}</h1> }
          { !this.state.isLoading && <div style={{ whiteSpace: 'pre-line' }}>{this.state.content}</div> }
        </section>
        {
          this.props.previousLink &&
          <Link
            to={this.props.previousLink}
            className="c-casenote__nav-button--left"
            style={isReferralMode() ? { top: '30%' } : {}}
          >
            <i className="fa fa-chevron-left" />
          </Link>
        }
        {
          this.props.nextLink &&
          <Link
            to={this.props.nextLink}
            className="c-casenote__nav-button--right"
            style={isReferralMode() ? { top: '30%' } : {}}
          >
            <i className="fa fa-chevron-right" />
          </Link>
        }
      </div>
    );
  }
}

export default TextCasenote;
