import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import AccountsReceivable from './../components/reports/accountsReceivable';
import AsyncFetch from './../components/asyncFetch';
import {
  setAccountsReceivableFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
  updateConfigValue, updateConfig, setCurrentDataViewsError,
} from './../actions';
import { getAccountsReceivableDataViews } from './../dataViews';
import translate from './../utils/i18n';

import type ClaimModel from './../models/claimModel';
import type CoveragePayorModel from './../models/coveragePayorModel';
import type { Dispatch, Model, Config, State, DataView } from './../types';
import type APIError from '../utils/apiError';
import type BaseModel from '../models/baseModel';

type UniquePanel = Map<string, string | number>;

/**
 * Returns List of Maps with panelName and payableAmount keys.
 * @param {List<ClaimModel>} claims All claims
 * @param {List<CoveragePayorModel>} coveragePayors All coveragePayors
 * @returns {List<UniquePanel>} The unique coveragePayors
 */
function getUniquePanels(
  claims: List<ClaimModel>,
  coveragePayors: List<CoveragePayorModel>,
): List<UniquePanel> {
  let uniquePanels = new Map();
  claims.forEach((claim) => {
    if (claim.get('amount_due') > 0) {
      uniquePanels = uniquePanels.set(
        claim.get('coverage_payor_id'),
        uniquePanels.get(claim.get('coverage_payor_id'), 0) + parseFloat(claim.get('amount_due')),
      );
    }
  });
  return List(uniquePanels
    .keySeq()
    .map((key) => {
      const coveragePayor = coveragePayors.find(cp => cp.get('_id') === key);
      return Map({
        panelName: coveragePayor ? coveragePayor.get('name') : translate('loading...'),
        payableAmount: uniquePanels.get(key),
      });
    }));
}

/**
 * Gets the claims to display from the current state.
 * @param {List<Model>} currentDataViewsModels The models for the current data views.
 * @returns {List<ClaimModel>}
 */
function getClaimsFromState(currentDataViewsModels: List<Model>): List<ClaimModel> {
  return currentDataViewsModels.filter(m => m.get('type') === 'claim' && !m.get('is_void'));
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  filter: state.reportsFilter.get('accountsReceivable'),
  config: state.config,
  data: getUniquePanels(
    state.isFetching ? List() : getClaimsFromState(state.currentDataViewsModels),
    state.coveragePayors,
  ),
  isFetching: state.isFetching,
  containerDataViews: getAccountsReceivableDataViews(
    state.reportsFilter.get('accountsReceivable').get('filterDateStart').valueOf(),
    state.reportsFilter.get('accountsReceivable').get('filterDateEnd')
      .clone()
      .add(1, 'd')
      .valueOf(),
  ),
  currentDataViews: state.currentDataViews,
  currentDataViewsError: state.currentDataViewsError,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, any>) => dispatch(setAccountsReceivableFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const AccountsReceivableContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(AccountsReceivable));

export default AccountsReceivableContainer;
