/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable require-jsdoc */
import React from 'react';
import { List } from 'immutable';
import translate from '../../utils/i18n';
import Select from '../inputs/select';
import Button from '../buttons/button';
import { formatPatientOptions } from './addEditAppointment';
import { AppointmentsFilter, SelectOption } from '../../types';
import Checkbox from '../inputs/checkbox';
import { FilterCheckBoxes } from './appointments';
import PatientStubModel from '../../models/patientStubModel';
import PractitionerModel from '../../models/practitionerModel';


export const LIMIT_PATIENT_RESULT : number = 1000;


export const APPOINTMENT_PATIENT_FILTER_OPTIONS = [
  { value: 'patient_name', label: 'Name' },
  { value: 'tel', label: 'Phone No.' },
  { value: 'ic', label: 'IC No.' },
];

type Props = {
  patientStubs: List<PatientStubModel>;
  practitioners: List<PractitionerModel>;
  filters: AppointmentsFilter;
  filteredPatientId: string;
  filteredDoctorId: string;
  setFilterPatientId: (value: string) => void;
  setFilterDoctorId: (value: string) => void;
  updateEventFilterType: (value: string, isChecked: boolean) => void;

};

type State = {
  patientSearchPlaceholder: string;
  patientOptions: List<SelectOption>;
  patientInput: string;
  patientFilterType: string;
};


class AppointmentFilters extends React.Component<Props, State> {
  /**
     * Creates an instance of TableColumnsSettings.
     * @param {Props} props props
     */
  constructor(props: Props) {
    super(props);
    this.state = {
      patientOptions: List<SelectOption>([]),
      patientInput: '',
      patientFilterType: 'patient_name',
      patientSearchPlaceholder: translate('press_enter_to_search'),
    };
  }

  onKeyDown(event: any) {
    const { patientInput, patientOptions, patientFilterType } = this.state;
    const { patientStubs } = this.props;
    if (event.key === 'Enter') {
      if (!patientInput || patientInput.length < 3) {
        this.setState({ patientSearchPlaceholder: 'Please enter at least 3 characters' });
        return;
      }
      let newPatientOptions = patientOptions;

      switch (patientFilterType) {
        case 'ic':
          newPatientOptions = patientStubs.filter(e => e.get('ic') && e.get('ic').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('ic'), name: e.get('patient_name'), tel: e.get('tel') }));
          break;
        case 'patient_name':
          newPatientOptions = patientStubs.filter(e => e.get('patient_name') && e.get('patient_name').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('patient_name'), ic: e.get('ic'), tel: e.get('tel') }));
          break;
        case 'tel':
          newPatientOptions = patientStubs.filter(e => e.get('tel') && e.get('tel').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('tel'), name: e.get('patient_name'), ic: e.get('ic') }));
          break;
        default:
          newPatientOptions = List<SelectOption>([]);
      }

      this.setState({
        patientSearchPlaceholder: newPatientOptions.size > 0 ? '' : `${translate('no_result_found_for')} ${patientInput}`,
        patientOptions: newPatientOptions ?? List<SelectOption>([]),
      });
    }
  }

  onFilterInputChange(value: string) {
    const { patientOptions, patientInput } = this.state;
    let newPatientOptions = patientOptions;
    if (value !== patientInput) {
      newPatientOptions = List<SelectOption>([]);
    }

    this.setState({
      patientInput: value,
      patientOptions: newPatientOptions,
      patientSearchPlaceholder: translate('press_enter_to_search'),
    });
  }

  updatePatientFilterType(value: string) {
    this.setState({
      patientInput: '',
      patientFilterType: value,
      patientOptions: List<SelectOption>([]),
      patientSearchPlaceholder: translate('press_enter_to_search'),
    });
    this.props.setFilterPatientId('');
  }

  render() {
    const { patientOptions, patientSearchPlaceholder, patientFilterType } = this.state;
    const { practitioners, filteredPatientId, filteredDoctorId } = this.props;
    return (
      <div>
        <div style={{ marginBottom: '2rem' }}>
          <div style={{ float: 'left', fontFamily: 'robotobold' }}>{translate('filters')}</div>
          <div style={{ float: 'right' }}>
            <Button
              style={{ border: 'none', backgroundColor: 'transparent' }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <img style={{ backgroundColor: '#4dd2b4' }} src="static/images/icon_filter.svg" alt="" />
            </Button>
          </div>
        </div>
        <Select
          style={{ marginTop: '1rem', marginBottom: '0rem' }}
          id="patientFilterType"
          label="Search Patient"
          value={patientFilterType || null}
          options={APPOINTMENT_PATIENT_FILTER_OPTIONS}
          onValueChanged={value => this.updatePatientFilterType(value)}
        />
        <Select
          id="patientFilterValue"
          noResultsText={patientSearchPlaceholder}
          // label="Search Patient"
          formatOptionLabel={formatPatientOptions}
          value={filteredPatientId || null}
          onKeyDown={event => this.onKeyDown(event)}
          onInputChange={value => this.onFilterInputChange(value)}
          options={patientOptions}
          onValueChanged={(value) => {
            this.props.setFilterPatientId(value);
          }}
        />
        <Select
          style={{ marginTop: '1rem' }}
          id="doctorFilterValue"
          noResultsText="Doctor not found"
          label="Search Doctor"
          formatOptionLabel={formatPatientOptions}
          value={filteredDoctorId || null}
          options={practitioners.map(e => ({ value: e.get('_id'), label: e.get('name') }))}
          onValueChanged={(value) => {
            this.props.setFilterDoctorId(value);
          }}
        />
        <label className="o-label">Search Event Type</label>
        {FilterCheckBoxes.map((item, index) => (
          <Checkbox
            containerStyle={{ marginBottom: '0px' }}
            inputStyle={{ backgroundColor: item.backgroundColor, borderColor: item.backgroundColor }}
            id={`include-endkey_${index}`}
            value={this.props.filters.filterEventType}
            options={[{ value: item.value, label: item.label }]}
            onValueChanged={(value, isChecked) => {
              this.props.updateEventFilterType(value, isChecked);
            }}
          />
        ))}
      </div>
    );
  }
}

export default AppointmentFilters;
