import { List } from 'immutable';

import { getBaseApiUrl, handleResponseError } from './utils';
import { handleApiError } from './response';
import { login } from './auth';
import fetch from './fetch';
import { createPermission } from './permissions';

import type { PouchError, FetchResponse, UserPermissionRecord } from './../types';
import { debugPrint } from './logging';

/**
 * Changes the password for the given user. NB: password validation should be done elsewhere.
 * @param {string} userID The user to change the password for.
 * @param {string} newPassword The new password.
 * @returns {Promise<boolean>} Returns true if operation successful
 */
export function changePassword(
  userID: string,
  newPassword: string,
): Promise<boolean> {
  const formData = new FormData();
  formData.append('username', userID);
  formData.append('password', newPassword);
  return fetch(new Request(`${getBaseApiUrl()}/user/change_user_password`), {
    method: 'POST',
    body: formData,
    credentials: 'same-origin',
  })
    .then((response: FetchResponse) => {
      if (!response.ok) {
        handleApiError({ error: response, message: 'Change password failed' });
      }
      return response.ok;
    })
    .catch((error: PouchError) => {
      handleApiError({ error, notificationType: 'silent' });
      return false;
    });
}

/**
 * Changes the password for the current user. NB: password validation should be done elsewhere.
 * @param {string} userID The user to change the password for.
 * @param {string} newPassword The new password.
 * @param {string} oldPassword The current password.
 * @returns {Promise<boolean>} Returns true if operation successful
 */
export function changeOwnPassword(
  userID: string,
  newPassword: string,
  oldPassword: string,
  workspace: string, 
) {
  const formData = new FormData();
  formData.append('username', userID);
  formData.append('new_password', newPassword);
  formData.append('password', oldPassword);
  return fetch(new Request(`${getBaseApiUrl()}/user/change_password`), {
    method: 'PUT',
    body: formData,
    credentials: 'same-origin',
  })
    .then((response: FetchResponse) => {
      if (!response.ok) {
        handleApiError({ error: response, message: 'Change password failed' });
        return response.ok;
      }
      return login(getBaseApiUrl(), userID, newPassword, workspace)
        .then((res) => res.ok)
        .catch((err) => debugPrint(err, 'error'));
    })
    .catch((error: PouchError) => {
      handleApiError({ error, notificationType: 'silent' });
      return false;
    });
}

/**
 * Creates a user with the given userID and password.
 * @param {string} userID The user name.
 * @param {string} password The password.
 * @returns {Promise<[boolean, number]>} Returns a boolean of the success of the request and the
 * status as a tuple.
 */
export function createUser(userID: string, password: string): Promise<[boolean, number]> {
  const formData = new FormData();
  formData.append('username', userID);
  formData.append('password', password);
  return fetch(new Request(`${getBaseApiUrl()}/user/add`), {
    method: 'PUT',
    body: formData,
    credentials: 'same-origin',
  })
    .then((response: FetchResponse) => {
      if (!response.ok) {
        handleApiError({ error: response, message: 'Create user failed' });
      }
      return [response.ok, response.status];
    })
    .catch((error: PouchError) => {
      handleApiError({ error, notificationType: 'silent' });
      return [false, error.status];
    });
}

/**
 * Updates a user as admin user.
 * @param {string} userID The user name.
 * @returns {Promise<[boolean, number]>} Returns a boolean of the success of the request and the
 * status as a tuple.
 */
export function updateUserToAdmin(userID: string): Promise<[boolean, number]> {
  const formData = new FormData();
  formData.append('username', userID);
  formData.append('is_admin', 'true');
  return fetch(new Request(`${getBaseApiUrl()}/user/change_admin_flag`), {
    method: 'PUT',
    body: formData,
    credentials: 'same-origin',
  })
    .then((response: FetchResponse) => {
      if (!response.ok) {
        handleApiError({ error: response, message: 'Updating user to admin failed.' });
      }
      return [response.ok, response.status];
    })
    .catch((error: PouchError) => {
      handleApiError({ error, notificationType: 'silent' });
      return [false, error.status];
    });
}

/**
 * Updates a user as non admin user.
 * @param {string} userID The user name.
 * @returns {Promise<[boolean, number]>} Returns a boolean of the success of the request and the
 * status as a tuple.
 */
export function updateUserToNonAdmin(userID: string): Promise<[boolean, number]> {
  const formData = new FormData();
  formData.append('username', userID);
  formData.append('is_admin', 'false');
  return fetch(new Request(`${getBaseApiUrl()}/user/change_admin_flag`), {
    method: 'PUT',
    body: formData,
    credentials: 'same-origin',
  })
    .then((response: FetchResponse) => {
      if (!response.ok) {
        handleApiError({ error: response, message: 'Updating user to admin failed.' });
      }
      return [response.ok, response.status];
    })
    .catch((error: PouchError) => {
      handleApiError({ error, notificationType: 'silent' });
      return [false, error.status];
    });
}

/**
 * checks if current user is an admin user.
 * @returns {Promise<[boolean, boolean]>} Returns a boolean of the success of the request and the
 * result as a tuple.
 */
export function isAdminUser(): Promise<[boolean, boolean]> | void {
  return fetch(new Request(`${getBaseApiUrl()}/user/is_admin`), {
    method: 'GET',
    credentials: 'same-origin',
  })
    .then((response) => {
      if (!response.ok) {
        handleResponseError(response?.json().then(json => json), 'Fetching admin status failed.');
      }
      return response.json().then(json => Promise.resolve(
        [response.ok, json ? json.result : false],
      )) as unknown as [boolean, boolean];
    })
    .catch((error) => {
      handleResponseError(error, 'Fetching admin status failed.');
      return [false, false];
    });
}

/**
 * checks if set of permissions have admin privileges.
 * @param {List<UserPermissionRecord>} permissions list of permissions
 * @returns {boolean} Returns a boolean of the result
 */
export function isAdminPermissionSet(permissions: List<UserPermissionRecord>): boolean {
  const adminPermissions = List([createPermission('users', 'create'),
    createPermission('users', 'update'), createPermission('users', 'delete')]);
  return adminPermissions.some(p => List([p]).every((permission) => {
    const userPermission = permissions.find(up => up.get('feature') === permission.get('feature'));
    return userPermission ?
      userPermission.get('access').contains(permission.get('access')) :
      false;
  }));
}
