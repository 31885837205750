import React from 'react';
import { Page } from 'catalog';

import StyleguideReset from './styleguideReset';

/**
 * Content section of styleguide
 * @class StyleguideContent
 * @extends {React.Component<Props, State>}
 */
const StyleguideContent = () =>
  <StyleguideReset>
    <Page>
      <h2 data-public>Grammar</h2>
      <h2 data-public>Vocabulary</h2>
    </Page>
  </StyleguideReset>;

export default StyleguideContent;
