import * as React from 'react';
import ReactHeight from 'react-height';
import glamorous from 'glamorous';

import CloseButton from './../buttons/closeButton';
import { colours } from './../../utils/css';
import Header from './../header/header';

import type { HTMLStyle } from './../../types';

type Props = {
  explicitCloseOnly: boolean,
  children: React.Node,
  style: HTMLStyle,
  large: boolean,
  className?: string,
  title: string,
  closePortal: () => void,
  noCloseButton: boolean,
  coverStyle: HTMLStyle,
  noHeaderHasCloseButton: boolean,
  noHeader: boolean,
  closeButtonStyle: HTMLStyle,
  dataPublicHeader?: boolean,
  unsetWidth?: boolean,
};

type State = {
  height: number,
};

const MAX_HEIGHT_RATIO = 0.8;

const ModalCover = glamorous.div({
  position: 'absolute',
  top: 0,
  height: '100%',
  width: '100%',
  backgroundColor: colours.transparentBlack,
  zIndex: 105,
  display: 'flex',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

const ModalContainer = glamorous.div({
  margin: 'auto',
});

/**
 * The internal content of a modal. It manages the height of the modal and the surrounding styles
 * such as the close button and the width of the container.
 * @class ModalContent
 * @extends {React.Component<Props, State>}
 */
class ModalContent extends React.Component<Props, State> {
  static defaultProps = {
    explicitCloseOnly: false,
    large: false,
    noCloseButton: false,
    coverStyle: {},
    noHeaderHasCloseButton: false,
    noHeader: false,
    closeButtonStyle: {},
  };

  /**
   * Creates an instance of ModalContent.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 250,
    };
  }

  /**
   * Sets the height of the modals content.
   * @param {number} height The height to set.
   * @returns {void}
   */
  setHeight(height: number) {
    if (height > window.innerHeight * MAX_HEIGHT_RATIO) {
      this.setState({ height: window.innerHeight * MAX_HEIGHT_RATIO });
    } else {
      this.setState({ height });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <ModalCover
        onClick={(event: SyntheticInputEvent<*>) => {
          event.stopPropagation(); // Nothing beneath the modal cover should be triggered.
          if (!this.props.explicitCloseOnly) {
            this.props.closePortal();
          }
        }}
        style={this.props.coverStyle}
      >
        <ModalContainer
          style={Object.assign({}, {
            height: this.state.height,
            width: this.props.large ? window.innerWidth * 0.9 : this.props.unsetWidth ? 'auto' : window.innerWidth / 2,
            display: 'table',
          }, this.props.style)}
          className={this.props.className}
          onClick={(event: SyntheticInputEvent<*>) => event.stopPropagation()}
        >
          <div className="o-card o-card--no-margin" style={{ position: 'relative', borderRadius: '4px', overflow: 'hidden' }}>
            {!this.props.noHeader &&
              <Header
                className="o-card__header"
                style={this.props.noHeaderHasCloseButton ? { position: 'absolute', right: 0, borderBottom: 0, background: 'transparent' } : {}}
                dataPublic={this.props.dataPublicHeader}
              >
                {!this.props.noHeaderHasCloseButton && <h1 className="o-card__title">{this.props.title}</h1>}
                {!this.props.noCloseButton &&
                  <CloseButton
                    onClick={this.props.closePortal}
                    style={this.props.closeButtonStyle}
                    dataPublic
                  />
                }
              </Header>
            }
            <ReactHeight
              className="modal-scrollable-container"
              style={{
                maxHeight: this.props.style && this.props.style.height ? this.props.style.height : `${window.innerHeight * MAX_HEIGHT_RATIO}px`,
                overflowY: 'auto',
              }}
              onHeightReady={height => this.setHeight(height)}
            >
              {this.props.children}
            </ReactHeight>
          </div>
        </ModalContainer>
      </ModalCover>
    );
  }
}

export default ModalContent;
