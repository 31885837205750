import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import glamorous from 'glamorous';

import { isTouchDevice } from './../../utils/utils';
import translate from './../../utils/i18n';

type Props = {
  patientSearchQuery: string,
  setPatientSearchQuery: (query: string) => void,
}

const SearchBarWrapper = glamorous.div({
  display: 'grid',
  gridTemplateColumns: 'auto 200px',
});

/**
 * An input search component.
 * @param {Props} props passed props for the component
 * @returns {React.Component} The rendered components
*/
function PatientListSearchInput(props: Props) {
  const [searchQuery, setSearchQuery] = useState('');

  /**
   * Handles changes in search patient query
   * @param {string} searchValue search patient query
   * @returns {void}
   */
  const handleChange = useCallback(debounce((searchValue: string) =>
    props.setPatientSearchQuery(searchValue), 400), []);

  useEffect(() => {
    if (props.patientSearchQuery === '') {
      setSearchQuery('');
    }
  }, [props.patientSearchQuery]);

  return (
    <SearchBarWrapper>
      <div className="c-patient-list__search-wrapper">
        <input
          className="c-patient-list__search o-text-input u-margin-bottom--zero"
          placeholder={translate('search_name_ic_phone_clinic_number')}
          type="text"
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value)
            handleChange(event.target.value);
          }}
          autoFocus={!isTouchDevice()}
        />
        <div className="c-patient-list__search-wrapper__mglass-wrapper">
          <span className="ico ico-mglass" />
        </div>
      </div>
      <Link to="/mykad" className="o-button u-margin--standard" style={{ marginLeft: 0 }}>Scan MyKad</Link>
    </SearchBarWrapper>
  )
}

export default PatientListSearchInput;