import { List } from 'immutable';
import BaseModel from './../baseModel';
import SummaryItemModel from './summaryItemModel';
import type { AnalyticsQueryResponseDateRange, AnalyticsQueryResponseKeyTitle, AnalyticsQueryResponseAggregateMetric, AnalyticsQueryResponseTimeSeriesMetric } from './../../types';

type attributesType = {
  _id: string,
  type: 'analytics_query_response_item',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,

  has_more: boolean,
  date_range: AnalyticsQueryResponseDateRange,
  meta: { display_text: {[key: string]: AnalyticsQueryResponseKeyTitle}},
  metric: string,
  xaxis: { key: string },
  key_metrics: List<SummaryItemModel>,
  aggregate: { [key: string]: AnalyticsQueryResponseAggregateMetric },
  time_series: { [key: string]: AnalyticsQueryResponseTimeSeriesMetric }
}

/**
   * AnalyticsQueryResponseModel
   *
   * @namespace AnalyticsQueryResponseModel
   */
class AnalyticsQueryResponseModel extends BaseModel {
    attributes: attributesType;

    /**
     * @param {object} attributes - The attributes for this model.
     */
    constructor(attributes: {} = {}) {
      super(attributes);
      this.attributes.type = 'analytics_query_response_item';
      this.attributes.key_metrics =
        List(attributes.key_metrics.map(metric => new SummaryItemModel(metric)));
    }
}

export default AnalyticsQueryResponseModel;
