import React, { Fragment } from 'react';
import type { Node } from 'react';
import type { List } from 'immutable';

import Input from './../inputs/input';
import Table from './../table/table';
import translate from './../../utils/i18n';
import { consecutiveCharSearch } from './../../utils/search';
import Button from './../buttons/button';

import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  documentTemplates: List<DocumentTemplateModel>,
  setSelectedDocumentTemplate: (documentTemplate: DocumentTemplateModel) => void,
};

type State = {
  searchQuery: string,
};

type Row = {
  name: string,
  create: Node,
};

const columns = [
  { accessor: 'name', Header: translate('document_templates') },
  { accessor: 'create', Header: '', sortable: false, width: 200, show: true },
];


/**
 * A component for selecting a Document Template
 * @class DocumentTemplateSelector
 * @extends {React.Component<Props, State>}
 */
class DocumentTemplateSelector extends React.Component<Props, State> {
  /**
   *Creates an instance of DocumentTemplateSelector.
   * @param {Props} props Props
   * @returns {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = { searchQuery: '' };
  }

  /**
   * Checks if the DocumentTemplate matches the current query
   * @param {DocumentTemplateModel} documentTemplate The DocumentTemplate to check
   * @returns {boolean}
   */
  documentTemplateMatchesQuery = (documentTemplate: DocumentTemplateModel): boolean => (
    this.state.searchQuery.length ?
      consecutiveCharSearch(this.state.searchQuery, documentTemplate.getName().toLowerCase()) :
      true
  );

  /**
   * Gets a table row for the given DocumentTemplate.
   * @param {DocumentTemplateModel} documentTemplate The DocumentTemplate
   * @returns {Row}
   */
  getRow = (documentTemplate: DocumentTemplateModel): Row => ({
    name: documentTemplate.getName(),
    create: (
      <Button
        className="o-button o-button--small"
        onClick={() => this.props.setSelectedDocumentTemplate(documentTemplate)}
        dataPublic
      >
        {translate('create')}
      </Button>),
  })

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="c-patient-list__search-wrapper">
          <Input
            id="doc-templates-search"
            className="c-patient-list__search o-text-input"
            placeholder={translate('search_document_templates')}
            label={translate('search_document_templates')}
            value={this.state.searchQuery}
            autoFocus
            onValueChanged={searchQuery => this.setState({ searchQuery })}
          />
        </div>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('document_templates')}</h2>
          <Table
            columns={columns}
            data={
              this.props.documentTemplates
                .filter(this.documentTemplateMatchesQuery)
                .map(this.getRow)
                .toArray()
              }
            noDataText={translate('no_document_templates_found')}
            defaultSorted={[{ id: 'name', desc: false }]}
          />
        </div>
      </Fragment>
    );
  }
}

export default DocumentTemplateSelector;
