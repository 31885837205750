/* eslint-disable no-alert */
import * as React from 'react';
import { ReactSpecimen } from 'catalog';

import CloseButton from './../../buttons/closeButton';
import LoadingButton from './../../buttons/loadingButton';
import MenuButton from './../../buttons/menuButton';
import SaveButton from './../../buttons/saveButton';

/**
 * Button components for style guide
 * @class Buttons
 * @extends {React.Component<Props, State>}
 */
const ButtonComponents = () =>
  <React.Fragment>
    <h2 data-public>Buttons</h2>
    <h3 data-public>CloseButton</h3>
    <p>
      A simple close button that takes an onClick event handler and optionally a label (defaults
      to an x). Generally this is used for close buttons on modals.
    </p>
    <ReactSpecimen>
      <CloseButton dataPublic onClick={() => alert('Closed!')} />
    </ReactSpecimen>
    <ReactSpecimen>
      <CloseButton dataPublic onClick={() => alert('???')} label="?" />
    </ReactSpecimen>
    <h3 data-public>LoadingButton</h3>
    <p>
      A permanently disabled button with a loading animation that takes a label and
      classNames/styles. It is unlikely this will be useful on its own but it may be useful for
      building other components (e.g. SaveButton).
    </p>
    <ReactSpecimen>
      <LoadingButton dataPublic label="I'm loading!" buttonClass="o-button" />
    </ReactSpecimen>
    <h3 data-public>MenuButton</h3>
    <p>
      An ARIA compatible toggleable menu button.
    </p>
    <ReactSpecimen>
      <MenuButton
        label="Menu"
        items={[
          { label: 'Hello', value: 'hello', dataPublic: true },
          { label: 'Danger! Danger!', value: 'danger', isDanger: true, dataPublic: true },
        ]}
        onValueSelected={value => alert(`You selected ${value}`)}
        dataPublic
      />
    </ReactSpecimen>
    <ReactSpecimen>
      <MenuButton
        label="Danger Menu"
        items={[
          { label: 'Hello', value: 'hello', dataPublic: true },
          { label: 'Danger! Danger!', value: 'danger', isDanger: true, dataPublic: true },
        ]}
        onValueSelected={value => alert(`You selected ${value}`)}
        disabled
        buttonClassName="o-button--danger"
        dataPublic
      />
    </ReactSpecimen>
    <h3 data-public>SaveButton</h3>
    <p>
      A button that can switch between a normal state and a loading state. The button itself is
      stateless and relies on the loading state to be stored in the parent component.
    </p>
    <ReactSpecimen>
      <SaveButton
        onClick={() => alert('I should popup!')}
        dataPublic
      />
    </ReactSpecimen>
    <ReactSpecimen>
      <SaveButton
        isSaving
        onClick={() => alert('I should not popup!')}
      />
    </ReactSpecimen>
  </React.Fragment>;

export default ButtonComponents;
