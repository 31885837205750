import { connect } from 'react-redux';
import type { List, Map } from 'immutable';

import Doctors from './../components/doctors/doctors';
import { saveFactory } from './../utils/redux';
import { updateConfigValue, updateConfig } from './../actions';

import type { Dispatch, State, Config } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ practitioners, config, user, userConfigs }: State) => ({
  practitioners: practitioners.filter(p => p.isVisible()),
  config,
  user,
  userConfigs,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateOnDuty: (onDuty: List<Map<string, string | boolean | void>>) => dispatch(updateConfigValue(['clinic', 'on_duty'], onDuty)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});


const DoctorsContainer = connect(mapStateToProps, mapDispatchToProps)(Doctors);

export default DoctorsContainer;
