import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';

import reducers from './../reducers/reducers';
import { updateModel, updateModels } from './../actions';
import { saveModel, saveModels } from './db';
import { getEncryptKey } from './auth';
import { getUserName } from "./user";
import { DEV_SERVERS } from './../constants';

import type { Dispatch, SaveModel, SaveModels, Model, MapValue, SaveModelRetryFunction, SaveModelsAPI, APIResponse } from './../types';
import type BaseModel from 'src/models/baseModel';

let store;

/**
 * Creates redux store.
 * @returns {undefined}
 */
export function createReduxStore() {
  // If is dev server turn on redux logging.
  store = DEV_SERVERS.some(server => location.hostname.indexOf(server) > -1) ? // eslint-disable-line import/prefer-default-export
    createStore(reducers, applyMiddleware(createLogger({ collapsed: true, duration: true }))) :
    createStore(reducers);
}

/**
 * Returns the redux store.
 * @returns {Object} The redux store.
 */
export function getStore() {
  return store;
}

/**
 * Extends db.saveModel to pass the savedModel to the Redux store after save (regardless of success,
 * errors are handled in db.saveModel).
 * @param {any} dispatch The dispatch function for the Redux store.
 * @returns {Promise<BaseModel>} A promise for the saved model.
 */
export function saveFactory(dispatch: Dispatch): SaveModel {
  const encryptKey = getEncryptKey() || '';
  const uID = getUserName() || '';
  return function saveAndUpdateStore(
    model: Model,
    retryAction?: SaveModelRetryFunction,
  ): Promise<Model> {
    const modelToSave = model.beforeSave ? model.beforeSave() : model;
    return saveModel(modelToSave, dispatch, encryptKey, uID, undefined, undefined, undefined, retryAction)
      .then((savedModel) => {
        if (savedModel) {
          dispatch(updateModel(savedModel));
        }
        return savedModel;
      });
  };
}

/** Extends db.saveModels to pass the saved models to the Redux store after save (regardless o
  * success, errors are handled in db.saveModels).
  * @param {Dispatch} dispatch The dispatch function for the Redux store.
  * @returns {SaveModels} A promise for the saved models.
  */
export function saveModelsFactory(dispatch: Dispatch): SaveModels {
  const encryptKey = getEncryptKey() || '';
  const userID = getUserName() || '';
  return function saveModelsAndUpdateStore(
    models: Array<Model>,
    retryAction?: SaveModelRetryFunction,
  ): Promise<Array<Model>> {
    return saveModels(models, dispatch, encryptKey, userID, retryAction)
      .then((savedModels) => {
        dispatch(updateModels(savedModels.filter(model => !!model)));
        return savedModels;
      });
  };
}

/** Extends api functions to pass the saved models to the Redux store after save.
  * @param {Dispatch} dispatch The dispatch function for the Redux store.
  * @returns {SaveModels} A promise for the saved models.
  */
export function saveModelsAPIFactory(dispatch: Dispatch): SaveModelsAPI {
  return function callAPIAndUpdateStore(
    callAPI: (dispatch?: Dispatch, opts?: { [key: string]: MapValue }) => Promise<APIResponse<BaseModel>>,
    opts?: { [key: string]: MapValue },
  ): Promise<Array<BaseModel> | APIResponse<BaseModel>> {
    return callAPI(dispatch, opts)
      .then((savedModels) => {
        if (savedModels.ok && !savedModels.unavailable && !savedModels.error && savedModels.data?.size) {
          dispatch(updateModels(savedModels.data?.toArray()));
          return savedModels.data.toArray();
        }
        return savedModels;
      });
  };
}
