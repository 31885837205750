import * as React from 'react';
import AutosizedTextArea from 'react-textarea-autosize';

import Label from './label';
import type { HTMLStyle } from './../../types';

type Props = {
  id: string,
  onValueChanged?: (value: string) => void,
  value: string,
  className?: string,
  description?: string,
  label?: string,
  labelClassName?: string,
  placeholder?: string,
  required?: boolean,
  style?: HTMLStyle,
  inputStyle?: HTMLStyle,
  hideLabel?: boolean,
  minRows?: number,
  maxRows?: number,
  disabled?: boolean,
  autoFocus: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
}

/**
 * A text area input component.
 * @class TextArea
 * @extends {React.PureComponent<Props>}
 */
class TextArea extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    style: {},
    inputStyle: {},
    minRows: 3,
    maxRows: 20,
    disabled: false,
    autoFocus: false,
    onValueChanged: () => {},
  };

  /**
   * Get the placeholder value
   * @returns {string}
   */
  getPlaceholder = () => this.props.placeholder || this.props.label;

  /**
   * Updates the vlaue if it is valid.
   * @param {Event} event The chance event
   * @returns {void}
   */
  onChange = (event: SyntheticInputEvent<*>) => {
    const { value } = event.target;
    if (this.props.onValueChanged && typeof value === 'string') {
      this.props.onValueChanged(value);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let className = 'o-text-input o-text-input--full-width';
    if (this.props.className) {
      className += ' ';
      className += this.props.className;
    }
    let description = '';
    if (this.props.description) {
      description = <p className="o-sub-label">{ this.props.description }</p>;
    }
    return (
      <div className="o-form__item o-form__item--full-width" style={this.props.style}>
        {
          this.props.label && !this.props.hideLabel &&
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label}
            invalid={this.props.required && (!this.props.value || this.props.value.length === 0)}
          />
        }
        { description }
        <AutosizedTextArea
          minRows={this.props.minRows}
          maxRows={this.props.maxRows}
          id={this.props.id}
          placeholder={this.getPlaceholder()}
          className={className}
          onChange={this.onChange}
          required={this.props.required}
          value={this.props.value}
          onKeyPress={event => event.stopPropagation()}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          style={Object.assign(
            {},
            { // The below styles fix a rendering issue that occurs on iOS devices.
              transform: 'translateZ(0px)',
              '-webkit-transform-style': 'translateZ(0px)',
            },
            this.props.inputStyle,
          )}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default TextArea;
