import React, { Fragment } from 'react';
import { List } from 'immutable';

import Input from './../inputs/input';
import TextArea from './../inputs/textarea';
import TagInput from './../inputs/tagInput';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import ProviderModel from './../../models/providerModel';
import SpecimenModel from './../../models/specimenModel';
import ProcedureTypeModel from './../../models/procedureTypeModel';
import Select from './../inputs/select';
import SavePrompt from './../prompts/savePrompt';
import ModalFooter from './../modals/modalFooter';
import Keypress from './../keypress';
import FormError from './../formError';

import type { SaveModel, MapValue } from './../../types';

type Props = {
  providers: List<ProviderModel>,
  modelToEdit?: ProcedureTypeModel,
  onSave: () => void,
  disabled: boolean,
  specimens: List<SpecimenModel>,
  saveModel: SaveModel,
};

/* eslint-disable camelcase */
type LabTestAttributes = {
  name: string,
  provider_id?: string,
  category?: string,
  description?: string,
  price?: number,
  cost_price?: number,
  is_editable?: boolean,
  discounted_price?: number,
  specimens_required?: Array<string>,
  specimens: Array<string>,
};
/* eslint-enable camelcase */

type State = {
  isSaving: boolean,
  changesMade: boolean,
  attributes: LabTestAttributes,
  errorMessage?: string,
  specimensField: Array<string>,
  newlySavedSpecimenIDs: Array<string>,
};

/**
 * Gets the default state of the component.
 * @param {ProcedureTypeModel?} model A provider model
 * @returns {State}
 */
function getDefaultState(model?: ProcedureTypeModel) {
  if (model) {
    return Object.assign({}, {
      isSaving: false,
      attributes: {
        name: model.get('name', ''),
        provider_id: model.get('provider_id'),
        category: model.get('category', ''),
        price: model.get('price'),
        cost_price: model.get('cost_price'),
        is_editable: model.get('is_editable', true),
        active: model.get('active', true),
        discounted_price: model.get('discounted_price'),
        description: model.get('description'),
        specimens: model.get('specimens', []),
        procedure_type: model.get('procedure_type', 'lab'),
        sub_items: model.get('sub_items', []),
      },
      changesMade: false,
      specimensField: [],
      newlySavedSpecimenIDs: [],
    });
  }
  return Object.assign({}, {
    isSaving: false,
    attributes: {
      name: '',
      procedure_type: 'lab',
      specimens: [],
      starred: false,
      is_editable: true,
      active: true,
      sub_items: [],
      category: '',
    },
    changesMade: false,
    specimensField: [],
    newlySavedSpecimenIDs: [],
  });
}

/**
 * A form for creating or editing Lab.
 * @class LabTestsForm
 * @extends {React.Component<Props, State>}
 */
class LabTestsForm extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  state: State;

  /**
   * Creates an instance of SupplierForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = getDefaultState(props.modelToEdit);
  }

  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.setSpecimensFieldValue();
  }

  /**
   * Set specimens value to state
   * @returns {void}
   */
  setSpecimensFieldValue() {
    const specimensFromState = this.state.attributes.specimens;
    const procedureTypeSpecimenIDs = (
      specimensFromState.length > 0 &&
      specimensFromState.flat()
    ) || [];
    if (procedureTypeSpecimenIDs) {
      const value = this.props.specimens
        .filter(s => procedureTypeSpecimenIDs.includes(s.get('_id')))
        .map(s => s.get('name')).toArray();
      this.setState({ specimensField: value });
    }
  }

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState({
      attributes: Object.assign(this.state.attributes, { [key]: value }),
      changesMade: true,
    });
  }

  /**
   * Updates state.SpecimensField with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateSpecimensField(key: string, value: MapValue) {
    const { specimensField } = this.state;
    this.setState(Object.assign(specimensField, { [key]: value, changesMade: true }));
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    if (this.state.attributes.name.length &&
    this.state.attributes.provider_id &&
    this.state.attributes.price &&
    this.state.specimensField.length) {
      return true;
    }
    return this.setState({ errorMessage: translate('fill_required_fields') });
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}  A promise with a value of true if successful and false if failed.
   */
  onSaveClicked(): Promise<boolean> {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const currentSpecimensNames = this.props.specimens.map(model => model.get('name')).toArray();
      const newSpecimensNames = this.state.specimensField
        .filter(name => !currentSpecimensNames.includes(name));
      Promise.all(newSpecimensNames.map(specimen =>
        this.props.saveModel(new SpecimenModel({ name: specimen, is_editable: true, active: true }))
          .then((savedSpecimen) => {
            this.state.newlySavedSpecimenIDs.push(savedSpecimen.get('_id'));
          })))
        .then(() => {
          const updatedSpecimensIDs = this.props.specimens
            .filter(model => this.state.specimensField.includes(model.get('name')))
            .map(model => model.get('_id')).toArray();
          const procedureTypeAttributes = Object.assign(this.state.attributes,
            { specimens: [updatedSpecimensIDs] });
          const procedureTypeModel = this.props.modelToEdit ?
            this.props.modelToEdit.set(procedureTypeAttributes) :
            new ProcedureTypeModel(procedureTypeAttributes);
          this.props.saveModel(procedureTypeModel).then(() => {
            this.setState({ isSaving: false });
            this.props.onSave();
          });
        });
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  /**
   * Update state if model changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      this.setState(getDefaultState(nextProps.modelToEdit));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const idPrefix = this.props.modelToEdit ?
      `lab_${this.props.modelToEdit.get('_id')}` : 'lab';
    const isEditable = !this.state.attributes.is_editable;
    return (
      <Fragment>
        <div id="labTestsForm" />
        <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
          <SavePrompt
            when={this.state.changesMade}
            onSaveClicked={() => this.onSaveClicked()}
          />
          { this.state.errorMessage &&
            <FormError containerElementID="labTestsForm">{this.state.errorMessage}</FormError>
          }
          <Input
            id={`${idPrefix}_name`}
            label={translate('name')}
            value={this.state.attributes.name}
            onValueChanged={value => this.updateAttribute('name', value)}
            required
            disabled={isEditable || this.props.disabled}
          />
          <Select
            id={`${idPrefix}_provider_id`}
            label={translate('laboratory')}
            options={
              this.props.providers
                .filter(p => p.isActive() || p.get('_id') === this.state.attributes.provider_id)
                .map(p => ({ value: p.get('_id'), label: p.get('name'), disabled: !p.isActive() }))
                .toArray()
            }
            onValueChanged={value => this.updateAttribute('provider_id', value)}
            value={this.state.attributes.provider_id}
            required
            disabled={isEditable || this.props.disabled}
          />
          <div className="u-flex-row">
            <Input
              id={`${idPrefix}_price`}
              label={translate('price')}
              value={this.state.attributes.price}
              onValueChanged={value => this.updateAttribute('price', value)}
              disabled={isEditable ? false : this.props.disabled}
              type="number"
              required
              divClassName="u-margin-right--1ws"
            />
            <Input
              id={`${idPrefix}_cost_price`}
              label={translate('cost_price')}
              value={this.state.attributes.cost_price}
              onValueChanged={value => this.updateAttribute('cost_price', value)}
              disabled={isEditable || this.props.disabled}
              type="number"
              divClassName="u-margin-right--1ws"
            />
          </div>
          <TagInput
            id="specimens_required"
            label={translate('specimens_required')}
            onChange={value => this.updateSpecimensField('specimensField', value.map(e => e.value))}
            value={this.state.specimensField ?
              this.state.specimensField.map(value => ({ value, label: value })) :
              []
            }
            options={
              this.props.specimens ?
                this.props.specimens
                  .filter(specimen => (specimen.has('active') ? specimen.get('active') : specimen))
                  .map(specimen => ({ value: specimen.get('name'), label: specimen.get('name') }))
                  .toArray() : []}
            disabled={isEditable || this.props.disabled}
            required
          />
          <TextArea
            id="description"
            label={translate('description')}
            value={this.state.attributes.description || ''}
            onValueChanged={value => this.updateAttribute('description', value)}
            disabled={isEditable || this.props.disabled}
          />
        </Keypress>
        <ModalFooter>
          {
            !this.props.disabled &&
            <SaveButton
              dataPublic
              className="o-button--small u-margin-right--half-ws"
              isSaving={this.state.isSaving}
              label={translate('save')}
              onClick={() => this.onSaveClicked()}
            />
          }
        </ModalFooter>
      </Fragment>
    );
  }
}

export default LabTestsForm;
