import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { P, Div } from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import SaveButton from './../buttons/saveButton';
import { margins } from './../../utils/css';
import translate from './../../utils/i18n';
import Button from './../buttons/button';

import type { ReactRouterLocation } from './../../types';

type Props = {
  when: boolean |
  ((currentLocation: ReactRouterLocation, nextLocation: ReactRouterLocation) => boolean),
  enableSave?: boolean,
  onDiscard?: () => void,
  title: string,
  description?: string,
  onSaveClicked?: () => Promise<boolean>, // A promise that returns true on successful save.
};

type State = {
  isSaving: boolean,
};

/**
 * A prompt informing the use that data will be lost if they continue. Primarily to be used when automatically saving
 * is not always an option (e.g. on the Billing page, as we want the use to first review the bill before
 * saving). Can double as a normal save prompt.
 * @class UnsavedDataPrompt
 * @extends {React.Component<Props, State>}
 */
class UnsavedDataPrompt extends React.PureComponent<Props, State> {
  static defaultProps = {
    onDiscard: () => {},
    title: translate('unsaved_data_will_be_lost'),
    description: translate('unsaved_data_will_be_lost_desc'),
  }

  /**
   * Creates an instance of UnsavedDataPrompt.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { title, description, onDiscard } = this.props;
    return (
      <NavigationPrompt when={this.props.when}>
        {({ onConfirm, onCancel }) => (
          <StatelessModal
            noButton
            visible
            explicitCloseOnly
            noCloseButton
            setVisible={() => {}}
            id="save-prompt-modal"
            title={title}
            dataPublicHeader
          >
            <P css={{ margin: margins.standardMargins }}>
              {description}
            </P>
            <Div css={{ margin: margins.standardMargins, display: 'flex' }}>
              {this.props.enableSave && this.props.onSaveClicked && <SaveButton
                onClick={() => {
                  this.setState({ isSaving: true });
                  this.props.onSaveClicked().then((wasSuccessful) => {
                    if (wasSuccessful) {
                      onConfirm();
                      this.setState({ isSaving: false });
                    } else {
                      onCancel();
                      this.setState({ isSaving: false });
                    }
                  });
                }}
                label={translate('save')}
                isSaving={this.state.isSaving}
              />}
              <Button
                className="o-button o-button--danger u-margin-left--1ws"
                onClick={() => {
                  if (onDiscard) {
                    onDiscard();
                  }
                  onConfirm();
                }}
                dataPublic
              >
                {translate('discard')}
              </Button>
              <Button
                className="o-button o-button--danger u-margin-left--1ws"
                onClick={onCancel}
                dataPublic
              >
                {translate('cancel')}
              </Button>
            </Div>
          </StatelessModal>
        )}
      </NavigationPrompt>
    );
  }
}

export default UnsavedDataPrompt;
