import { connect } from 'react-redux';

import UserGroupPermissions from './../components/userManagement/userGroupPermissions';
import { saveFactory } from './../utils/redux';
import { setUserAdminStatus } from './../actions';

import type { State, Dispatch } from './../types';

type Props = {
  userGroupId: string,
};

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ userGroups, userConfigs, isAdminUser, user, config, klinifyConfig }: State,
  { userGroupId }: Props) => ({
  userGroup: userGroups.find(group => group.get('_id') === userGroupId),
  userConfigs: userConfigs.filter(c => !c.get('user_id').startsWith('klinify_')),
  isAdminUser,
  user,
  config,
  klinifyConfig,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  setAdminUserStatus: (isAdmin?: boolean) => dispatch(setUserAdminStatus(isAdmin)),
});

const UserGroupPermissionsContainer =
  connect(mapStateToProps, mapDispatchToProps)(UserGroupPermissions);

export default UserGroupPermissionsContainer;
