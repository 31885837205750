
import BaseModel from './baseModel';

type EtchInstruction = {};
type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'template',
  category: string,
  name: string,
  template_group: string,
  order: number,
  assets: Array<string>,
  etch_instructions: Array<EtchInstruction>,
};

/**
   * TemplateModel
   * @namespace TemplateModel
   */
class TemplateModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'template';
  }

  /**
   * Gets the preview asset for this template.
   * @returns {string}
   */
  getPreviewAsset(): string {
    if (!this.has('assets') || this.get('assets').length === 0) {
      throw new Error('Template has no assets.');
    }
    return this.get('assets')[0];
  }

  /**
   * Gets the category for this template.
   * @returns {string}
   */
  getCategory() {
    return this.has('category') ? this.get('category') : '0_KLINIFY_NOTES';
  }
}

export default TemplateModel;
