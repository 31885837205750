import React from 'react';
import moment, { Moment } from 'moment';
import { List, Map } from 'immutable';

import StatelessModal from './../modals/statelessModal';
import ContentTransition from './../contentTransition';
import translate from './../../utils/i18n';
import AddToInventoryFormItem from './addToInventoryFormItem';
import DispensingBatch from './dispensingBatch';
import SaveButton from './../buttons/saveButton';
import { generateGUID } from './../../utils/utils';
import { fetchModel } from './../../utils/db';
import SupplyModel from './../../models/supplyModel';
import SupplyItemModel from './../../models/supplyItemModel';
import TransactionModel from './../../models/transactionModel';
import { createSuccessNotification } from './../../utils/notifications';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import { DATE_FORMAT_TO_SAVE } from './../../constants';
import ModalFooter from './../modals/modalFooter';
import Button from './../buttons/button';
import { getDateFormat } from './../../utils/time';
import AddToInventorySupplyFormItem from './addToInventorySupplyFormItem';

import type BaseModel from './../../models/baseModel';
import type DrugModel from './../../models/drugModel';
import type SupplierModel from './../../models/supplierModel';
import type { MapValue, CountPerSKUAndBatch, Config, SupplyItemAttributes } from './../../types';

/* eslint-disable camelcase */
type Attributes = {
  supplier_id: string,
  date: Moment,
  invoice_number?: string,
  delivery_order_number?: string,
  purchase_order_number?: string,
  doc_number?: string,
  notes?: string,
}

type SupplyItemAttr = SupplyItemAttributes & {
  key: string,
};
/* eslint-enable camelcase */

type State = {
  attributes: Attributes,
  isSaving: boolean,
  errorMessage?: string,
  changesMade: boolean,
  supplyItems: List<SupplyItemAttr>,
  isShowingUnsavedChangesError: boolean,
  isDispensingBatchModalVisible: boolean,
}

type Props = {
  drugs: List<DrugModel>,
  supplyItems: List<SupplyItemModel>,
  suppliers: List<SupplierModel>,
  saveModel: (model: BaseModel) => Promise<BaseModel>,
  isFetching: boolean,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  isOutgoing?: boolean,
  skuID: string,
  action: string,
  isVisible: boolean,
  onClose: (isFromClose: boolean) => void,
  isBulk: boolean,
  config: Config,
}


/**
 * A component containing a form for adding a Supply.
 * @class AddToInventoryForm
 * @extends {React.Component}
 */
class AddToInventoryForm extends React.Component<Props, State> {
  /**
   * Creates an instance of AddToInventoryForm.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      attributes: {
        supplier_id: '',
        date: moment(),
        notes: '',
        invoice_number: '',
        delivery_order_number: '',
        purchase_order_number: '',
        doc_number: '',
      },
      isSaving: false,
      changesMade: false,
      supplyItems: List([{ key: generateGUID(), ...!props.isBulk && { sku_id: props.skuID } }]), // Seed supply items with empty object.
      isShowingUnsavedChangesError: false,
      isDispensingBatchModalVisible: false,
    };
  }

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState(prevState => ({
      attributes: Object.assign(prevState.attributes, { [key]: value }),
      changesMade: true,
    }));
  }

  /**
   * Handle the updating of a supply item.
   * @param {number} index Index of the supply item to change.
   * @param {Attributes} changes The changes made to the supply item.
   * @returns {void}
   */
  updateSupplyItem(index: number, changes: Attributes) {
    this.setState((prevState) => {
      const item = Object.assign({}, prevState.supplyItems.get(index), changes);
      const unitCost = (item.total_price && item.quantity)
        ? Number(item.total_price) / Number(item.quantity)
        : undefined;
      return {
        supplyItems: prevState.supplyItems.set(
          index,
          Object.assign({}, prevState.supplyItems.get(index), changes,
            { unit_cost: unitCost, sku_id: this.props.isBulk ? item.sku_id : this.props.skuID }),
        ),
        changesMade: true,
      };
    });
  }

  /**
   * Validate form and returns true if all fields required are valid.
   * @returns {boolean} True if form is valid.
   */
  validateForm(): boolean {
    const { supplier_id: supplierId, date } = this.state.attributes;
    const { supplyItems } = this.state;
    if (!supplierId || !supplyItems.every(item => item.expiry_date && item.quantity)
    ) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    const isQuantityFormatValid = supplyItems.every(item => item.sku_id
        && !isNaN(item.quantity)
        && Math.floor(Number(item.quantity)) === Number(item.quantity)
        && parseInt(item.quantity, 10) > 0);
    if (!date) {
      this.setState({ errorMessage: translate('please_select_valid_date') });
      return false;
    }
    if (!supplyItems.every(item => (
      item.expiry_date ? moment(item.expiry_date, getDateFormat()).isValid() : true))
    ) {
      this.setState({ errorMessage: translate('expiry_date_in_correct_format') });
      return false;
    }
    if (!isQuantityFormatValid) {
      this.setState({ errorMessage: translate('quantity_field_required_and_min_1') });
      return false;
    }
    if (
      supplierId !== undefined &&
      supplierId !== '' &&
      date !== undefined &&
      isQuantityFormatValid
    ) {
      return true;
    }
    this.setState({ errorMessage: translate('fill_required_fields_and_in_correct_format') });
    return false;
  }

  onSaveClicked = (): Promise<boolean> => {
    if (this.validateForm()) {
      this.setState({ isSaving: true });
      const supplyModel = new SupplyModel({
        supplier_id: this.state.attributes.supplier_id,
        date: this.state.attributes.date.format(DATE_FORMAT_TO_SAVE),
        invoice_number: this.state.attributes.invoice_number,
        delivery_order_number: this.state.attributes.delivery_order_number,
        purchase_order_number: this.state.attributes.purchase_order_number,
        doc_number: this.state.attributes.doc_number,
        notes: this.state.attributes.notes,
      });
      const supplyItemModels = this.state.supplyItems.map((supplyItem) => {
        const quantity = (this.props.isOutgoing ? -1 : 1) * parseInt(supplyItem.quantity, 10);
        const supplyItemAttrs = Object.assign({}, supplyItem, {
          supply_id: supplyModel.get('_id'),
          quantity,
          date: this.state.attributes.date.format(DATE_FORMAT_TO_SAVE),
          expiry_date: (supplyItem && supplyItem.expiry_date?.isValid()) ? // eslint not able to parse that camel casing is disabled for these variables
            supplyItem.expiry_date.format(DATE_FORMAT_TO_SAVE) : undefined,
          total_price: supplyItem.total_price !== undefined ?
            parseFloat(String(supplyItem.total_price)) : supplyItem.total_price,
        });
        delete supplyItemAttrs.key; // Remove key used for React.
        return new SupplyItemModel(supplyItemAttrs);
      });
      // Create transactions for each supplyItem.
      const transactionModels = supplyItemModels.map(supplyItem => new TransactionModel({
        sku_id: supplyItem.get('sku_id'),
        change: supplyItem.get('quantity'),
        source_type: this.props.action,
        source_id: supplyItem.get('_id'),
        supply_item_id: supplyItem.get('_id'),
        timestamp: Number(this.state.attributes.date.format('x')),
      }));
      const models = List([supplyModel]).concat(supplyItemModels).concat(transactionModels);
      Promise
        .all(models.map(model => this.props.saveModel(model)))
        .then(() => {
          transactionModels.forEach(model => this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change')));
          this.setState({
            isSaving: false,
            changesMade: false,
            errorMessage: undefined,
            isShowingUnsavedChangesError: false,
          });
          createSuccessNotification(translate('supply_creation_success'));
          // location.hash = '/inventory/inventory-status';
          return true;
        })
        .then(() => {
          const dispensingOrder = this.props.config.getIn(['dispensation', 'dispensing_order'], 'expiry_date');
          const dispensingOrderField = dispensingOrder === 'supply_date' ? 'date' : 'expiry_date';
          const supplyItemsWithDateField = supplyItemModels
            .filter(si => si.has(dispensingOrderField, false));
          if (supplyItemsWithDateField.size && !this.props.isBulk) {
            const defaultDispensingBatch = this.props.drugs.find(d => d.get('_id') === this.props.skuID).get('default_dispensing_batch');
            const currentDispensingBatch = (defaultDispensingBatch
              && this.props.inventoryCount.getIn([this.props.skuID, 'batches', defaultDispensingBatch, 'batchStockRemaining'], 0))
              ? defaultDispensingBatch
              : this.props.inventoryCount
                .getIn([this.props.skuID, 'batches'], Map())
                .sortBy(batch => batch.get('index'))
                .keySeq()
                .first();

            if (currentDispensingBatch) {
              fetchModel(currentDispensingBatch).then((batch) => {
                if (batch && supplyItemsWithDateField
                  .some(si => si.isEarlierThan(batch, dispensingOrderField))
                ) {
                  this.setState({
                    isDispensingBatchModalVisible: true,
                  });
                } else {
                  this.props.onClose(false);
                }
              })
                .catch(() => {});
            } else {
              this.props.onClose(false);
            }
          }
          this.props.onClose(false);
        });
    }
    return Promise.resolve(false);
  }

  /**
   * Renders the AddToInventoryForm
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { action, isVisible, onClose, suppliers, skuID } = this.props;
    return (
      <React.Fragment>
        <StatelessModal
          id="add-to-inventory-form"
          buttonLabel=""
          buttonClass="o-button o-button--padded o-button--small"
          title={translate(action === 'supply_item' ? 'add_supply' : action)}
          setVisible={() => {}}
          visible={isVisible}
          onClose={() => {
            if (this.state.isShowingUnsavedChangesError) {
              onClose(true);
            } else if (this.state.changesMade) {
              this.setState({ isShowingUnsavedChangesError: true, errorMessage: translate('you_have_unsaved_changes') });
            } else {
              onClose(true);
            }
          }}
          explicitCloseOnly
          large
          dataPublicHeader
        >
          <ContentTransition className="o-scrollable-container" id="supply-form">
            <div className="o-form u-margin-bottom--4ws">
              <SavePrompt when={this.state.changesMade} onSaveClicked={this.onSaveClicked} />
              {
                this.state.errorMessage &&
                <FormError containerElementID="add-to-inventory-form">
                  {this.state.errorMessage}
                </FormError>
              }
              <AddToInventorySupplyFormItem
                suppliers={suppliers}
                action={this.props.action}
                onUpdateAttribute={(key: string, value: MapValue) => this.updateAttribute(key, value)}
                attributes={this.state.attributes}
              />
              {
                this.state.supplyItems.map((supplyItem, index) => <AddToInventoryFormItem
                  key={supplyItem.key}
                  supplyItem={supplyItem}
                  onChange={changes => this.updateSupplyItem(index, changes)}
                  onDeleteClicked={
                        () => this.setState(prevState => (
                          { supplyItems: prevState.supplyItems.remove(index) }))
                      }
                  showDeleteButton={this.state.supplyItems.size > 1}
                  isFirstChild={index === 0}
                  drugs={this.props.drugs}
                  skuID={skuID}
                />)
              }
              <Button
                type="button"
                className="o-button o-button--small"
                onClick={() => this.setState(prevState => ({
                  supplyItems: prevState.supplyItems.push({
                    key: generateGUID(),
                    ...!this.props.isBulk && { sku_id: this.props.skuID },
                  }),
                }))}
                style={{ margin: 'auto' }}
                dataPublic
              >
                <i className="fa fa-plus" />
                &nbsp;
                {translate('add_new_line_item')}
              </Button>
            </div>
          </ContentTransition>
          <ModalFooter>
            <SaveButton
              onClick={this.onSaveClicked}
              isSaving={this.state.isSaving}
              label={translate('save')}
              className="o-button--small u-margin-right--half-ws"
            />
          </ModalFooter>
        </StatelessModal>
        { (skuID || this.state.supplyItems.first().sku_id) &&
          <DispensingBatch
            config={this.props.config}
            drug={this.props.drugs
              .find(d => d.get('_id') === (skuID || this.state.supplyItems.first().sku_id))}
            drugs={this.props.drugs}
            supplyItems={this.props.supplyItems}
            saveModel={this.props.saveModel}
            isFetching={this.props.isFetching}
            inventoryCount={this.props.inventoryCount}
            inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
            isModalVisible={this.state.isDispensingBatchModalVisible}
            onClose={() => {
              this.setState({ isDispensingBatchModalVisible: false });
              this.props.onClose(false);
            }}
            fromAddSupplyForm
          />
        }
      </React.Fragment>
    );
  }
}

export default AddToInventoryForm;
