import React from 'react';
import RCTimePicker from 'rc-time-picker';
import type { Moment } from 'moment';

import Label from './label';

type Props = {
  className?: string,
  id: string,
  label?: string,
  labelClassName?: string,
  onValueChanged: (value: Moment) => void,
  style?: ({ [key: string]: string | number }),
  popupClassName?: string,
  value?: Moment,
  minuteStep?: number,
  allowEmpty?: boolean,
  hideLabel?: boolean,
}

/**
 * Renders a Timepicker component.
 * @param {Props} props Component props
 * @returns {React.Component} A Timepicker.
 */
const TimePicker = (props: Props) =>
  <div style={props.style} className={props.className}>
    {!props.hideLabel &&
      <Label className={props.labelClassName} id={props.id} label={props.label || ''} />}
    <RCTimePicker
      id={props.id}
      value={props.value}
      onChange={time => time && props.onValueChanged(time)}
      allowEmpty={false}
      showSecond={false}
      popupClassName={props.popupClassName}
      minuteStep={props.minuteStep}
    />
  </div>;

TimePicker.defaultProps = {
  className: '',
  style: {},
  label: '',
};

export default TimePicker;
