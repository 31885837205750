import * as React from 'react';
import ReactSelect, {
  createFilter,
  StylesConfig,
  Props as ReactSelectProps,
  SelectComponentsConfig,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { WindowedMenuList } from '@nvis/react-windowed-select';

import Label from './label';
import translate from './../../utils/i18n';
import { formatSelectOptions } from './../../utils/inputs';
import { borderRadius, colours } from '../../utils/css';

import type { HTMLStyle, MapValue, SelectOption } from './../../types';

type Props = {
  autoFocus?: boolean,
  className?: string,
  clearable?: boolean,
  defaultValue?: MapValue,
  disabled?: boolean,
  label?: string,
  labelClassName?: string,
  onKeyDown?: (e: any) => void,
  onInputChange?: (value: MapValue) => void,
  id: string,
  formatOptionLabel?: any,
  filterOption?: (option: SelectOption, query: string) => boolean,
  description?: string | React.ReactNode,
  onValueChanged: (value: MapValue) => void,
  options: Array<SelectOption>,
  required?: boolean,
  value?: MapValue,
  creatable?: boolean, // If true user can add own values.
  onCreateOption?: (option: string) => void,
  createOptionPromptFactory?: (label: string) => string,
  style?: { [key: string]: string | number },
  hideLabel?: boolean,
  inputStyle?: HTMLStyle,
  selectClassName?: string,
  showNewOptionAtTop: boolean,
  isValueCaseInsensitive?: boolean,
  components: SelectComponentsConfig<SelectOption>,
  noResultsText?: string,
  placeholder?: string,
  isMulti?: boolean,
  blurInputOnSelect?: boolean,
  isSearchable?: boolean,
  menuPortalTarget?: HTMLElement,
  windowThreshold?: number, // Defaults to 100
  isValueSensitive?: boolean,
};

export const reactSelectStyles: StylesConfig = {
  menuPortal: provided => ({
    ...provided,
    zIndex: 99,
  }),
  menu: provided => ({
    ...provided,
    marginTop: 0,
  }),
  container: provided => ({
    ...provided,
    position: 'relative',
  }),
  control: (_, { isFocused, menuIsOpen, selectProps }) => {
    const isInvalid = selectProps && selectProps.className ? selectProps.className.includes('js-border-invalid') : false;
    return {
      boxSizing: 'border-box',
      borderRadius,
      border: isInvalid ? menuIsOpen ? `2px solid ${colours.red}` : `1px solid ${colours.red}` : isFocused ? '1px solid #007eff' : `1px solid ${colours.grey3}`,
      color: '#333',
      display: 'flex',
      backgroundColor: '#fff',
      minHeight: '46px',
      width: '100%',
      cursor: isFocused ? 'text' : 'default',
      boxShadow: !menuIsOpen && isFocused && 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)',
    };
  },
  placeholder: provided => ({
    ...provided,
    color: 'rgb(117, 117, 117)',
    fontStyle: 'italic',
    fontSize: '20px',
    paddingRight: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  option: (provided, { data, isFocused, isDisabled, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused ? 'rgba(0, 126, 255, 0.08)' : isSelected ? 'rgba(0, 126, 255, 0.04)' : 'inherit',
    color: isDisabled ? '#ccc' : '#333',
    ...(data?.isOptionHidden && { display: 'none' }),
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: 'rgba(0, 126, 255, 0.08)',
    border: '1px solid rgba(0, 126, 255, 0.24)',
  }),
  multiValueLabel: provided => ({
    ...provided,
    fontSize: '100%',
    color: '#007eff',
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: '#007eff',
    cursor: 'pointer',
    borderLeft: '1px solid rgba(0, 126, 255, 0.24)',
    '&:hover': {
      backgroundColor: 'rgba(0, 113, 230, 0.08)',
      color: '#0071e6',
    },
  }),
};

/**
 * Rejects options that are empty strings or case
 * insensitive matches for existing options.
 * @param {string} input input string
 * @param {never} selectValue nselect value
 * @param {Array<SelectOption>} selectOptions existing options
 * @returns {boolean} True if option is unique.
 */
const isValidNewOption = (
  input: string,
  selectValue: never,
  selectOptions: Array<SelectOption>,
) =>
  (input?.trim().length
    ? !selectOptions.find(
      existingOption =>
        existingOption.label.toLowerCase() === input.toLowerCase() ||
          existingOption.value.toLowerCase() === input.toLocaleLowerCase(),
    )
    : false);

/**
 * Overrides the option renderer to add the word inactive to the label if the disabled param is
 * found.
 * @param {{ value: string, label: string}} option The option to render
 * @returns {string}
 */
function optionRenderer(option: SelectOption): string {
  if (option.disabled) {
    return `${option.label} (${translate('inactive')})`;
  }
  return option.label;
}

/**
 * Creates a Selct component that mimics a standad HTML select input with optional label and
 * description.
 * @param {object} props The component props.
 * @returns {React.Component} A Select component.
 */
const Select = (props: Props) => {
  let className = 'o-form__item u-margin-bottom--1wsunit';
  if (props.className) {
    className += ` ${props.className}`;
  }
  let description: React.ReactNode | string = '';
  if (props.description) {
    description = typeof props.description === 'string' ?
      <p className="o-sub-label">{ props.description }</p> :
      props.description;
  }

  /**
   * Returns SelectOption from options and value string
   * @returns {SelectOption}
   */
  const getValue = () => (typeof props.value === 'string' ? props.options.find(opt => opt.value === props.value) : props.value);
  const selectClassName = `${props.required && (!props.value || props.value.length === 0) ? 'js-border-invalid' : ''} ${props.selectClassName || ''}`;
  const selectProps: ReactSelectProps = {
    autoFocus: props.autoFocus,
    defaultValue: props.defaultValue,
    onKeyDown: props.onKeyDown,
    onInputChange: (newValue, actionMeta) => (props.onInputChange ? props.onInputChange(newValue) : undefined),
    name: props.id,
    isClearable: props.clearable,
    backspaceRemoves: props.clearable,
    filterOption: props.filterOption,
    onChange: (option: SelectOption) => {
      if (props.isMulti) {
        return props.onValueChanged(option ?? []);
      }
      if (props.isValueSensitive) {
        return props.onValueChanged(option);
      }
      if (option) {
        return props.onValueChanged(props.isValueCaseInsensitive ?
          option.valueOriginal : option.value);
      }
      return props.onValueChanged('');
    },
    value: getValue(),
    formatOptionLabel: props.formatOptionLabel,
    onCreateOption: props.onCreateOption,
    createOptionPosition: props.showNewOptionAtTop ? 'first' : 'last',
    formatCreateLabel: props.createOptionPromptFactory,
    isDisabled: props.disabled,
    className: selectClassName,
    options: formatSelectOptions(props.options, props.isValueCaseInsensitive),
    components: {
      ...(props.options.length > props.windowThreshold ? { MenuList: WindowedMenuList } : {}),
      ...props.components,
    },
    getOptionLabel: !props.components?.Option ? optionRenderer : undefined,
    ignoreCase: true,
    style: props.inputStyle,
    noOptionsMessage: () => props.noResultsText || null,
    placeholder: props.placeholder,
    styles: reactSelectStyles,
    isMulti: props.isMulti,
    maxMenuHeight: 180,
    blurInputOnSelect: props.blurInputOnSelect,
    isSearchable: props.isSearchable,
    // menuIsOpen: props.menuIsOpen,
    menuPortalTarget: props.menuPortalTarget,
    windowThreshold: props.windowThreshold,
    menuPlacement: 'auto',
  };
  return (
    <div className={className} style={props.style}>
      {
        !props.hideLabel &&
        <Label
          className={props.labelClassName}
          id={props.id}
          label={props.label || ''}
          invalid={props.required && (!props.value || props.value.length === 0)}
        />
      }
      { description }
      {
        props.creatable ?
          <CreatableSelect {...selectProps} isValidNewOption={isValidNewOption} /> :
          <ReactSelect {...selectProps} />
      }
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
  required: false,
  creatable: false,
  clearable: true,
  style: {},
  showNewOptionAtTop: true,
  isValueCaseInsensitive: false,
  isMulti: false,
  components: {},
  filterOption: createFilter({ ignoreAccents: false }),
  windowThreshold: 100,
  isValueSensitive: false,
};

export default React.memo(Select);
