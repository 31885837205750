import { List, Map } from 'immutable';

import BaseModel from './baseModel';
import Constants from './../constants';
import translate from '../utils/i18n';
import { ALLERGIES } from './../constants';

import DrugModel from './drugModel';
import { ActiveIngredient } from './../types';

type attributesType = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'allergy',
  criticality?: 'CRITL' | 'CRITH' | 'CRITU',
  status: 'active' | 'inactive' | 'resolved',
  verification_state: 'unconfirmed' | 'confirmed' | 'refuted' | 'entered-in-error',
  flagged: boolean,
  hidden: Boolean,
  name: string,
  onset_date?: number,
  last_occurrence_date?: number,
  allergy_type?: string,
  category?: string,
  notes?: string,
  category_metadata?: {
    category: string,
    type?: string,
    drug_id?: string,
    active_ingredient_id?: string,
  },
};

/**
   * AllergyModel
   *
   * @namespace AllergyModel
   */
class AllergyModel extends BaseModel {
  attributes: attributesType;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'allergy';
  }

  /**
   * Returns the human readable form of HL7 code for Allergy criticality or an em dash if none set.
   * @return {string} - Human readable form of criticality or em dash.
   */
  getCriticality(): string {
    const criticality = this.get('criticality');
    if (criticality && criticality.length) {
      if (criticality === 'CRITL') {
        return 'low_risk';
      } else if (criticality === 'CRITH') {
        return 'high_risk';
      } else if (criticality === 'CRITU') {
        return 'undetermined';
      }
    }
    return Constants.UNICODE.EMDASH;
  }

  /**
   * Returns true if the allergy is flagged.
   * @returns {boolean}
   */
  isFlagged(): boolean {
    return this.get('flagged', true);
  }

  /**
   * Toggles the flagged status of the allergy.
   * @returns {void}
   */
  toggleFlagged() {
    return this.set('flagged', !this.isFlagged());
  }

  /**
   * Returns the name and status as a combined string.
   * @returns {string}
   */
  getNameWithStatus(): string {
    return this.has('status') ?
      `${this.get('name')} (${translate(this.get('status'))})` :
      this.get('name');
  }

  /**
   * Returns true if item does not have a hidden flag.
   * @returns {boolean} True if item is not hidden.
   */
  isVisible(): boolean {
    return !this.get('hidden', false);
  }

  /**
   * Sets the salesItem visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered AllergyModel.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Checks allergy is of category medication
   * @returns {boolean} The altered AllergyModel.
   */
  isMedication() {
    const { category } = this.attributes;
    return category === ALLERGIES.CATEGORY_OPTIONS[0];
  }

  /**
 * Checks allergy is of category medication and type unspecified
 * @returns {boolean} The altered AllergyModel.
 */
  isMedicationAndUnspecified() {
    if (!this.isMedication()) {
      return false;
    }
    return this.get(['category_metadata', 'type']) === ALLERGIES.MEDICATION_TYPES[2];
  }

  /**
   * Checks allergy is of category medication and not type unspecified
   * @returns {boolean} The altered AllergyModel.
   */
  isMedicationAndNotUnspecified() {
    if (!this.isMedication()) {
      return false;
    }
    return this.get(['category_metadata', 'type']) !== ALLERGIES.MEDICATION_TYPES[2];
  }

  /**
   * Checks allergy is of category medication and type
   * @param {string} placeholder Vaule to return if type falsy
   * @returns {string} The altered AllergyModel.
   */
  getMedicationType(placeholder?: string) {
    if (!this.isMedication()) {
      return placeholder || '';
    }
    return (this.get(['category_metadata', 'type']) || placeholder || ALLERGIES.MEDICATION_TYPES[2]);
  }

  /**
   * Checks allergy is of categogy medication and not of type unspecified
   * @returns {{ drugId: string, activeIngredients: string }} The altered AllergyModel.
   */
  getMedicationDrugOrIngredients() {
    if (!this.isMedicationAndNotUnspecified()) {
      return { drugId: '', activeIngredient: '' };
    }
    return {
      drugId: this.get(['category_metadata', 'drug_id']) || '',
      activeIngredient: this.get(['category_metadata', 'active_ingredient_id']) || '',
    };
  }

  /**
   * Get the name of the Drug for category medication
   * @param {List<DrugModel>} drugs drugs
   * @returns {DrugModel | void} The altered AllergyModel.
   */
  getMedicationDrug(drugs: List<DrugModel>) {
    if (!this.isMedicationAndNotUnspecified()) {
      return undefined;
    }
    const { drugId } = this.getMedicationDrugOrIngredients();
    return drugs.find(d => d.get('_id') === drugId);
  }

  /**
   * Get the name of the
   * @param {List<DrugModel>} drugs drugs
   * @returns {string} The altered AllergyModel.
   */
  getAllergyBarDetails(drugs: List<DrugModel>) {
    const allergyStatus = this.get('status');
    const allergyName = this.get('name');
    const { drugId } = this.getMedicationDrugOrIngredients();
    if (drugId) {
      const drug = drugs.find(d => d.get('_id') === drugId);
      return allergyStatus ? `${drug?.get('name')}(${translate(allergyStatus)})` : drug?.get('name');
    }
    return allergyStatus ? `${allergyName}(${translate(allergyStatus)})` : allergyName;
  }
}

export default AllergyModel;