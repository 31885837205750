import { connect } from 'react-redux';

import BillPayments from './../components/billing/billPayments';
import { saveModelsFactory, saveFactory } from './../utils/redux';

import type { Dispatch, State, ReactRouterMatch } from './../types';

type Props = {
  match: ReactRouterMatch,
  patientID: string,
};

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, { match, patientID }: Props) => {
  const { encounterID } = match.params;
  const cachedPatient = state.patients.find(m => m.get('_id') === patientID);
  let patient;
  if (cachedPatient) {
    patient = cachedPatient;
  } else {
    patient = state.isFetching ?
      undefined : state.currentDataViewsModels.find(m => m.get('_id') === patientID);
  }
  const bill = state.bills.find(model => model.get('encounter_id') === encounterID);
  if (!bill) {
    throw new Error('Bill not found.');
  }
  return {
    patient,
    encounter: state.encounters.find(model => model.get('_id') === encounterID),
    coveragePayors: state.coveragePayors,
    payments: state.payments.filter(p => p.get('bill_id') === bill.get('_id')),
    // There should generally only be one receivable for a bill, but potentially there can be multiple so we need to handle such cases.
    receivables: state.receivables.filter(r => r.get('bill_id') === bill.get('_id') && !r.isVoid()),
    user: state.user,
    allergies: state.allergies.filter(m => m.get('patient_id') === patientID && m.isVisible()),
    paymentTypes: state.paymentTypes.filter(m => m.isVisible()),

  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
  saveModel: saveFactory(dispatch),
});

const BillPaymentsContainer = connect(mapStateToProps, mapDispatchToProps)(BillPayments);

export default BillPaymentsContainer;
