import * as React from 'react';
import glamorous, { CSSProperties } from 'glamorous';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { DragIcon, ListItem } from '../../utils/layout';

type Props = {
  items: Array<JSX.Element>,
  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => void,
  itemZIndex?: number | 'auto',
  itemStyles?: CSSProperties,
};

const List = glamorous.ul({
  listStyle: 'none',
});

const DragHandle = SortableHandle(() => <DragIcon className="fa fa-bars" />);
const SortableItem = SortableElement(
  ({ value, zIndex, itemStyles }: { value: JSX.Element, zIndex?: Props['itemZIndex'], itemStyles?: CSSProperties }) =>
    <ListItem zIndex={zIndex} css={itemStyles}>
      <DragHandle />
      {value}
    </ListItem>,
);

const ListContainer = SortableContainer(
  ({ items, itemZIndex, itemStyles }: { items: Props['items'], itemZIndex: Props['itemZIndex'], itemStyles?: CSSProperties }) =>
    <List>
      {items.map((value, index) => (
        <SortableItem itemStyles={itemStyles} key={`item-${index}`} index={index} value={value} zIndex={itemZIndex} /> // eslint-disable-line react/no-array-index-key
      ))}
    </List>,
);

/**
 * A sortable list. Sort handling is done via props, so you'll need to provide that yourself.
 * @class SortableList
 * @extends {React.Component<Props, State>}
 */
class SortableList extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { items, onSortEnd, itemZIndex, itemStyles } = this.props;
    return (<ListContainer
      items={items}
      onSortEnd={onSortEnd}
      useDragHandle
      itemZIndex={itemZIndex}
      itemStyles={itemStyles}
    />);
  }
}

export default SortableList;
