import { connect } from 'react-redux';
import { List } from 'immutable';
import Moment from 'moment';

import AsyncFetch from './../components/asyncFetch';
import { getCostReportDataViews } from './../dataViews';
import CostReport from './../components/reports/costReport';
import { setCostReportFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfigValue, updateConfig, setCurrentDataViewsError } from './../actions';

import type { Dispatch, State, Config, MapValue, DataView } from './../types';
import APIError from '../utils/apiError';
import BaseModel from '../models/baseModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  filter: state.reportsFilter.get('costReport'),
  bills: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'bill' && !m.isVoid() && m.isFinalised()),
  billItems: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'bill_item'),
  drugs: state.drugs,
  salesItems: state.salesItems,
  isFetching: state.isFetching,
  config: state.config,
  containerDataViews: getCostReportDataViews(
    state.reportsFilter.getIn(['costReport', 'filterDateStart'], new Moment()).valueOf(),
    state.reportsFilter.getIn(['costReport', 'filterDateEnd'], new Moment())
      .clone()
      .minutes(59)
      .hours(23)
      .seconds(59)
      .valueOf(),
  ),
  currentDataViews: state.currentDataViews,
  currentDataViewsError: state.currentDataViewsError,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, MapValue>) => dispatch(setCostReportFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const CostReportContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(CostReport));

export default CostReportContainer;
