import React from 'react';
import { List } from 'immutable';

import glamorous from 'glamorous';
import { mediaQueries } from './../../utils/css';
import translate from './../../utils/i18n';
import BillFormItem from './billFormItem';
import BillItemModel from './../../models/billItemModel';
import { createPermission, hasPermission, hasSomePermission } from './../../utils/permissions';
import Button from './../buttons/button';
import Header from './../header/header';

import type SalesItemModel from './../../models/salesItemModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import type DrugModel from './../../models/drugModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, MapValue, SaveModel, User, CountPerSKUAndBatch } from './../../types';
import { debugPrint } from '../../utils/logging';

type Props = {
  billItems: List<BillItemModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  config: Config,
  coveragePayorID?: string, // If set it can be used to pick the drug price if a specific price is set for that panel.
  disabled?: boolean,
  drugs: List<DrugModel>,
  inventoryCount: CountPerSKUAndBatch,
  salesItems: List<SalesItemModel>,
  updateBillItems: (billItems: List<BillItemModel>) => void,
  patientID: string,
  coveragePayors: List<CoveragePayorModel>,
  saveModel: SaveModel,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  user: User,
  drugDurations: List<List<number | string>>,
  isFromEncounterHistory: boolean,
};

type State = {
  lastAddedItemType: string,
};

const ItemContainer = glamorous.div({
  width: '100%',
  '@media (max-width: 1350px)': {
    width: '1000px',
  },
});

const Container = glamorous.div({
  overflowX: 'auto',
  maxWidth: 'none',
  // Hacky responsive CSS until needed
  '@media (max-width: 1350px)': {
    maxWidth: '50vw',
  },
  '@media (max-width: 1250px)': {
    maxWidth: '45vw',
  },
  '@media (max-width: 1100px)': {
    maxWidth: '40vw',
  },
});

/**
 * A list of Bill items.
 * @class BillFormItemList
 * @extends {Component}
 */
class BillFormItemList extends React.Component<Props, State> {
  static defaultProps = {
    isFromEncounterHistory: true,
  }

  /**
   * Creates an instance of BillFormItemList.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      lastAddedItemType: '', // Used to determine which input to autofocus on.
    };
  }

  /**
   * Returns true if the user can add a new BillItem of the specified type. This means that either
   * no previous items have been added or that all previously added items are fully filled out.
   * @param {string} type prescription or sales_item
   * @returns {boolean} True if user can add a new item.
   */
  canAddNewItem(type: 'prescription' | 'sales_item' | 'procedure_type'): boolean {
    const items = this.props.billItems.filter((i) => {
      if (type === 'prescription') {
        return i.isPrescription();
      } else if (type === 'sales_item') {
        return i.isSalesItem();
      }
      return i.isProcedureType();
    });
    if (items.size === 0) {
      return true;
    }
    return items.last().isValid();
  }

  /**
   * Called when add is clicked on one of the billing lists. An empty billing item of the
   * appropriate type is created and added to the appropriate list in the parent state.
   * @param {string} type The billing item type (prescription or sales_item).
   * @returns {undefined}
   */
  onAddClicked(type: string) {
    const newItem = new BillItemModel({ patient_id: this.props.patientID });
    if (type === 'prescription' && this.canAddNewItem('prescription')) {
      newItem.itemType = 'prescription';
      this.props.updateBillItems(this.props.billItems.push(newItem));
      this.setState({ lastAddedItemType: 'prescription' });
    } else if (type === 'sales_item' && this.canAddNewItem('sales_item')) {
      newItem.itemType = 'sales_item';
      this.props.updateBillItems(this.props.billItems.push(newItem));
      this.setState({ lastAddedItemType: 'salesItem' });
    } else if (type === 'procedure_type' && this.canAddNewItem('procedure_type')) {
      newItem.itemType = 'procedure_type';
      this.props.updateBillItems(this.props.billItems.push(newItem));
      this.setState({ lastAddedItemType: 'procedureType' });
    }
  }

  /**
   * Updates values
   * @param {string} itemID Item ID
   * @param {{}} newValues The new Values
   * @return {void}
   */
  updateValues(itemID: string, newValues: { [key: string]: MapValue }) {
    const values = newValues;
    const index = this.props.billItems.findIndex(i => i.get('_id') === itemID);
    if (index > -1) {
      const item = this.props.billItems.get(index, {});
      this.props.updateBillItems(
        this.props.billItems.set(index, new BillItemModel({ ...item.attributes, ...values })),
      );
    }
  }

  /**
   * Removes an item from the appropriate list on the bill.
   * @param {string} itemID The item ID.
   * @returns {void}
   */
  onRemoveClicked(itemID: string) {
    this.props.updateBillItems(this.props.billItems.filter(i => i.get('_id') !== itemID));
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering BillFormItemList');
    const salesItems = this.props.billItems.filter(item => item.isSalesItem());
    const prescriptions = this.props.billItems.filter(item => item.isPrescription());
    const procedureTypes = this.props.billItems.filter(item => item.isProcedureType());
    return (
      <Container className="u-padding--1ws" id="bill-sales-items">
        <Header className="o-header" dataPublic>
          <h1>{translate('sales_items')}</h1>
          {
            hasSomePermission(this.props.user, List([createPermission('perm_add_button_sales_items', 'update'), createPermission('perm_add_button_sales_items', 'read')])) &&
            !this.props.disabled &&
            <Button
              dataPublic
              className="o-button o-button--small"
              onClick={() => this.onAddClicked('sales_item')}
              disabled={!hasPermission(this.props.user, List([createPermission('perm_add_button_sales_items', 'update')]))}
            >
              {translate('add')}
            </Button>
          }
        </Header>
        <ItemContainer>
          {
            salesItems ? (
              salesItems.map((item, index) =>
                <BillFormItem
                  key={`billedSalesItem-${item.get('_id')}`}
                  hideLabel={index !== salesItems.size - 1}
                  autoFocus={this.state.lastAddedItemType === 'salesItem' && index === salesItems.size - 1}
                  disabled={this.props.disabled}
                  item={item}
                  itemOptions={this.props.salesItems}
                  onRemoveClicked={() => this.onRemoveClicked(item.get('_id'))}
                  onValueChanged={newValues => this.updateValues(item.get('_id'), newValues)}
                  config={this.props.config}
                  inventoryCount={this.props.inventoryCount}
                  coveragePayorID={this.props.coveragePayorID}
                  isFromBillPage
                  coveragePayors={this.props.coveragePayors}
                  saveModel={this.props.saveModel}
                  updateConfigValue={this.props.updateConfigValue}
                  updateConfig={this.props.updateConfig}
                  user={this.props.user}
                  providers={this.props.providers}
                  drugDurations={this.props.drugDurations}
                  isFromEncounterHistory={this.props.isFromEncounterHistory}
                />)
                .slice(0)
                .reverse()) : <p className="u-padding--1ws o-header">No items found</p>
          }
        </ItemContainer>
        <Header className="o-header" dataPublic>
          <h1>{translate('dispensation')}</h1>
          {
            hasSomePermission(this.props.user, List([createPermission('perm_add_button_prescriptions_items', 'update'), createPermission('perm_add_button_prescriptions_items', 'read')])) &&
            !this.props.disabled &&
            <Button
              dataPublic
              className="o-button o-button--small"
              onClick={() => this.onAddClicked('prescription')}
              disabled={!hasPermission(this.props.user, List([createPermission('perm_add_button_prescriptions_items', 'update')]))}
            >
              {translate('add')}
            </Button>
          }
        </Header>
        <ItemContainer>
          { prescriptions ? (
            prescriptions.map((item, index) =>
              <BillFormItem
                key={`billedPrescription-${item.get('_id')}`}
                hideLabel={index !== prescriptions.size - 1}
                autoFocus={this.state.lastAddedItemType === 'prescription' && index === prescriptions.size - 1}
                disabled={this.props.disabled}
                coveragePayorID={this.props.coveragePayorID}
                coveragePayors={this.props.coveragePayors}
                saveModel={this.props.saveModel}
                item={item}
                itemOptions={this.props.drugs}
                onRemoveClicked={() => this.onRemoveClicked(item.get('_id'))}
                onValueChanged={newValues => this.updateValues(item.get('_id'), newValues)}
                config={this.props.config}
                inventoryCount={this.props.inventoryCount}
                isFromBillPage
                updateConfigValue={this.props.updateConfigValue}
                updateConfig={this.props.updateConfig}
                user={this.props.user}
                providers={this.props.providers}
                drugDurations={this.props.drugDurations}
              />)
              .slice(0)
              .reverse()) : <p className="u-padding--1ws o-header">No items found</p>
          }
        </ItemContainer>
        <Header className="o-header" dataPublic>
          <h1>{translate('lab_tests')}</h1>
          {
            hasSomePermission(this.props.user, List([createPermission('perm_add_button_lab_tests', 'update'), createPermission('perm_add_button_lab_tests', 'read')])) &&
            !this.props.disabled &&
            <Button
              dataPublic
              className="o-button o-button--small"
              onClick={() => this.onAddClicked('procedure_type')}
            >
              {translate('add')}
            </Button>
          }
        </Header>
        <ItemContainer>
          { procedureTypes ? (
            procedureTypes.map((item, index) =>
              <BillFormItem
                key={`billedProcedureType-${item.get('_id')}`}
                hideLabel={index !== procedureTypes.size - 1}
                autoFocus={this.state.lastAddedItemType === 'procedureType' && index === procedureTypes.size - 1}
                disabled={this.props.disabled}
                coveragePayorID={this.props.coveragePayorID}
                coveragePayors={this.props.coveragePayors}
                saveModel={this.props.saveModel}
                item={item}
                itemOptions={this.props.procedureTypes}
                onRemoveClicked={() => this.onRemoveClicked(item.get('_id'))}
                onValueChanged={newValues => this.updateValues(item.get('_id'), newValues)}
                config={this.props.config}
                inventoryCount={this.props.inventoryCount}
                isFromBillPage
                updateConfigValue={this.props.updateConfigValue}
                user={this.props.user}
                providers={this.props.providers}
                updateConfig={this.props.updateConfig}
                drugDurations={this.props.drugDurations}
              />)
              .slice(0)
              .reverse()) : <p className="u-padding--1ws o-header">No items found</p>
          }
        </ItemContainer>
      </Container>
    );
  }
}
export default BillFormItemList;
