import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';

import { List, Map } from 'immutable';
import MasterCampaignModel from '../models/masterCampaignModel';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import SMSCampaigns from '../components/patientOutreachCampaign/smsCampaigns';
import AsyncFetch from './../components/asyncFetch';
import {
  setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfigValue,
  updateConfig, setCurrentDataViewsError, updateUnreadCamapaignSetMap,
} from './../actions';
import { getMasterDrugDataViews, getPatientCampaignDataViews } from './../dataViews';


import type { Dispatch, State, Config, DataView } from './../types';
import APIError from '../utils/apiError';
import PatientCampaignModel, { CampaignRuleType, CampaignRulesCondition } from '../models/patientCampaignModel';
import { setReadCampaignSetMap } from '../utils/patientCampaign';
import type MasterDrugModel from '../models/masterDrugModel';
import type BaseModel from '../models/baseModel';

/**
 * Fetches masterdrug models not present in Clinic's master drug list
 * @param {List<PatientCampaignModel>} patientCampaigns PatientCampaigns from currentDataViewsModels
 * @param {Map<string, MasterDrugModel>} masterDrugModelsMap Fetched master drug models in state
 * @returns {List<string>}
 */
const getMasterDrugModelIdsToFetch = (
  patientCampaigns: List<PatientCampaignModel>,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
) =>
  patientCampaigns.reduce(((ids, pc) => {
    const filterConditions: Array<CampaignRulesCondition> = pc.get('filter').conditions;
    const masterDrugTofetchInCampaign = filterConditions.reduce((masterDrugids, condition) => {
      if (condition.type === CampaignRuleType.MASTER_DRUG_SOLD &&
      condition.metadata.master_drug_id &&
      !masterDrugModelsMap.has(condition.metadata.master_drug_id)
      ) {
        return masterDrugids.push(condition.metadata.master_drug_id);
      }
      return masterDrugids;
    }, List());
    return masterDrugTofetchInCampaign.size ? ids.concat(masterDrugTofetchInCampaign) : ids;
  }), List());

/**
 * @param {Object} state Current app state.
 * @param {RouteComponentProps} ownProps RouteComponentProps
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: RouteComponentProps<{
  campaignSetID: string,
  campaignSubType: 'patient' | 'medadvisor',
}>) => {
  const filteredModels = state.currentDataViewsModels
    .filter(m => !m.get('_deleted') && !m.get('hidden'));
  const modelMapping = filteredModels
    .reduce((mapping: Map<string, Map<string, BaseModel>>, m: PatientCampaignModel) => mapping.update(m.get('type'), (values) => {
      if (!values && !Map.isMap(values)) {
        return Map([[m.get('_id'), m]]);
      }
      return values.set(m.get('_id'), m);
    }), Map());
  const patientCampaigns = (modelMapping.get('patient_campaign', Map()) as Map<string, PatientCampaignModel>)
    .filter((m: PatientCampaignModel) => m.get('campaign_set_id') === ownProps.match.params.campaignSetID);
  const masterPatientCampaigns = modelMapping.get('patient_campaign', Map()).filter((m: PatientCampaignModel) =>
    m.isMasterCampaign());
  const containerMasterDrugModelsMap = modelMapping.get('drug_master');
  // why?
  // const masterDrugModelsMap = ownProps.match.params.campaignSubType === 'medadvisor'
  //   ? state.masterDrugModelsMap.concat(containerMasterDrugModelsMap)
  //   : state.masterDrugModelsMap;

  const masterDrugModelsMap = state.masterDrugModelsMap.concat(containerMasterDrugModelsMap);
  const masterCampaignModels = modelMapping.get('campaign_master', Map()).merge(masterPatientCampaigns) as Map<string, PatientCampaignModel | MasterCampaignModel>;
  return {
    user: state.user,
    isFetching: state.isFetching,
    containerDataViews: getPatientCampaignDataViews(ownProps.match.params.campaignSetID, true)
      .concat(getMasterDrugDataViews(getMasterDrugModelIdsToFetch(patientCampaigns, state.masterDrugModelsMap))),
    currentDataViews: state.currentDataViews,
    patientCampaigns,
    currentDataViewsError: state.currentDataViewsError,
    config: state.config,
    klinifyConfig: state.klinifyConfig,
    drugs: state.drugs,
    salesItems: state.salesItems,
    practitioners: state.practitioners,
    selectedCampaignSet: filteredModels.find(set => set.get('_id') === ownProps.match.params.campaignSetID),
    readCampaignSetMap: state.campaignSetView.readCampaignSetMap,
    masterDrugModelsMap,
    encounterFlows: state.encounterFlows,
    masterCampaignModels,
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<PatientCampaignModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  updateUnreadCamapaignSetMap: (unreadCampaignSetMap: Map<string, {lastEditedTime: number, isNew: boolean}>) =>
    dispatch(updateUnreadCamapaignSetMap(unreadCampaignSetMap)),
});

/**
 * @param {Object} stateProps stateProps
 * @param {Object} dispatchProps dispatchProps
 * @param {Object} ownProps ownProps
 * @returns {Object}
 */
const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>,
  ownProps: RouteComponentProps,
) => {
  const { selectedCampaignSet, readCampaignSetMap, ...passedStateProps } = stateProps;
  const { updateUnreadCamapaignSetMap: updateUnreadCamapaignMap, ...passedDispatchProps } = dispatchProps;
  const isMedadvisorCampaign = ownProps.location.pathname.includes('/medadvisor');
  if (isMedadvisorCampaign && selectedCampaignSet) {
    const newReadCampaignSetMap = setReadCampaignSetMap(selectedCampaignSet);
    if (!readCampaignSetMap.has(selectedCampaignSet.get('_id'))) {
      updateUnreadCamapaignMap(newReadCampaignSetMap);
    }
  }
  return {
    ...passedStateProps,
    ...passedDispatchProps,
    match: ownProps.match,
    selectedCampaignSet,
  };
};

const SMSCampaignsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AsyncFetch(SMSCampaigns));

export default SMSCampaignsContainer;
