import type { List } from 'immutable';
import Papa from 'papaparse';
import outdent from '@jrolfs/outdent';
import Moment from 'moment';

import translate from './i18n';
import { dataURItoBlob, convertNumberToPrice } from './utils';
import { UNICODE } from './../constants';
import { prettifyDate, getDateFormat } from './../utils/time';

import type { Model } from './../types';
import type CoveragePayorModel from '../models/coveragePayorModel';
import type PatientModel from './../models/patientModel';
import type ClaimInvoiceModel from './../models/claimInvoiceModel';
import { getVisitType } from './encounters';

type EncounterDataProps = {
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
  configPatientCard: ?boolean,
  configAllergies: ?boolean,
  configEncounterNotes: ?boolean,
  configPrescriptions: ?boolean,
  configMedicalCertificates: ?boolean,
  configTimeChits: ?boolean,
  encounterNotes: string,
  prescriptions: string,
  allergies: string,
  medicalCertificates: string,
  timeChits: string,
  encounterDate: string,
};

/**
 * Converts the given parms to CSV format.
 * @param {Array<string>} fields Array of column names.
 * @param {Array<Array<string>>} data Array of arrays where each nested array is a row.
 * @returns {string} The generated CSV data.
 */
export function convertToCSV(fields: Array<string>, data: Array<Array<string>>): string {
  const unparseObj: PapaParse$UnparseObject = { fields, data };
  return Papa.unparse(unparseObj);
}


/**
 * Downloads a the given file data.
 * @param {string} fileName The file name.
 * @param {string | Blob} urlData The data to download.
 * @param {boolean} isBlob If true then urlData is a blob.
 * @returns {void}
 */
export function downloadFile(fileName: string, urlData: string | Blob, isBlob: boolean = false) {
  let hrefValue;
  if (isBlob && URL && URL.createObjectURL && typeof urlData !== 'string') {
    hrefValue = URL.createObjectURL(urlData);
  } else if (URL && URL.createObjectURL && typeof urlData === 'string') {
    hrefValue = URL.createObjectURL(dataURItoBlob(urlData)); // Although just passing urlData works in some cases, Chrome has a bug that will cause large urlDatas to be rejected.
  } else {
    hrefValue = urlData; // Handling if URL not implemented in browser / Node.
  }
  if (typeof hrefValue !== 'string') {
    throw new Error('Cannot put blob into string.');
  }
  const aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = hrefValue;
  const event = new MouseEvent('click');
  aLink.dispatchEvent(event);
  aLink.remove();
}

/**
 * Transforms CSV data to have proper metadata and downloads it.
 * @param {string} fileName The file name.
 * @param {string} urlData The data to download.
 * @returns {void}
 */
export function downloadCSV(fileName: string, urlData: string) {
  downloadFile(fileName, `data:text/csv;charset=UTF-8,${encodeURIComponent(urlData)}`);
}

/**
 * Transforms txt data to have proper metadata and downloads it.
 * @param {string} fileName The file name.
 * @param {string} urlData The data to download.
 * @returns {void}
 */
export function downloadTXT(fileName: string, urlData: string) {
  downloadFile(fileName, `data:text/plain;charset=UTF-8,${encodeURIComponent(urlData)}`);
}

/**
 * Returns a version of the patient card formatted for text export.
 * @param {PatientModel} patient The PatientModel
 * @param {List<CoveragePayorModel>} coveragePayors All possible CoveragePayorModels.
 * @returns {string}
 */
export function getPatientCardExport(
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
): string {
  return outdent`
  Patient Name: ${patient.get('patient_name', UNICODE.MINUS, false)}
  Case ID: ${patient.get('case_id', UNICODE.MINUS, false)}
  IC Number: ${patient.get('ic', UNICODE.MINUS, false)}
  Sex: ${patient.get('sex', UNICODE.MINUS, false)}
  Date of Birth: ${patient.get('dob', UNICODE.MINUS, false)}
  Nationality: ${patient.get('nationality', UNICODE.MINUS, false)}
  Ethnicity: ${patient.getEthnicity()}
  Telephone: ${patient.get('tel', UNICODE.MINUS, false)}
  Email: ${patient.get('email', UNICODE.MINUS, false)}
  Address: ${patient.get('address', UNICODE.MINUS, false)}
  Occupation: ${patient.get('occ', UNICODE.MINUS, false)}
  Current Employer: ${patient.get('current_employer', UNICODE.MINUS, false)}
  Notes: ${patient.get('notes', UNICODE.MINUS, false)}
  Coverage: ${patient.getCoveragePayorName(coveragePayors)}
`;
}

/**
 * Export encounter data
 * @param {EncounterDataProps} props Encounter Props
 * @returns {undefined}
 */
export function exportEncounterData(props: EncounterDataProps) {
  const {
    configPatientCard, encounterNotes, prescriptions, allergies, configAllergies,
    configEncounterNotes, configPrescriptions, configMedicalCertificates, medicalCertificates,
    configTimeChits, timeChits, encounterDate, patient, coveragePayors,
  } = props;
  const data = [];
  if (configPatientCard) {
    const patientCardData = outdent`
      ${translate('patient_card')}
      ------------
      ${getPatientCardExport(patient, coveragePayors)}

    `;
    data.push(patientCardData);
  }
  if (configAllergies) {
    const allergyList = outdent`

      ${translate('allergies')}
      ---------
      ${allergies}

    `;
    data.push(allergyList);
  }
  if (configEncounterNotes) {
    const encounterNotesData = outdent`
      ${translate('encounter_notes')}
      ---------------
      ${encounterNotes}

    `;
    data.push(encounterNotesData);
  }
  if (configPrescriptions) {
    const prescriptionList = outdent`

      ${translate('prescriptions')}
      -------------
      ${prescriptions}

    `;
    data.push(prescriptionList);
  }
  if (configMedicalCertificates) {
    const medicalCertificateList = outdent`

      ${translate('medical_certificates')}
      --------------------
      ${medicalCertificates}

    `;
    data.push(medicalCertificateList);
  }
  if (configTimeChits) {
    const timeChitList = outdent`

      ${translate('time_chits')}
      ----------
      ${timeChits}

    `;
    data.push(timeChitList);
  }
  downloadTXT(`${patient.get('patient_name', UNICODE.MINUS, false).replace(/\s/g, '-')}-${encounterDate}.txt`, data.toString().replace(/,/g, '').replace(/_/g, ','));
}

/**
 * Downloads the attributes of the given models as a JSON file
 * @export
 * @param {List<Model>} models The list of Models to export
 * @param {string} fileName The filename (minus extension) that will be used when downloaded.
 * @returns {void}
 */
export function exportModelsAsJSON(models: List<Model>, fileName: string) {
  const obj = models.map(m => m.attributes).toArray();
  const content = JSON.stringify(obj, null, 4);
  downloadFile(
    `${fileName}.json`,
    `data:application/json;charset=UTF-8,${encodeURIComponent(content)}`,
  );
}

/**
 * Export selected claim invoices as .csv file
 * @param {List<ClaimInvoiceModel>} claimInvoices List of ClaimInvoiceModel
 * @returns {Promise<void>}
 */
export function exportClaimInvoices(claimInvoices: List<ClaimInvoiceModel>): Promise<void> {
  const EXPORT_FIELDS = ['Date', 'Name', 'IC Number', 'Visit Type', 'Total Fees', 'Owned By Panel', 'Bill Notes'];
  claimInvoices.map((claimInvoice) => {
    const formattedData = claimInvoice.get('items', { claim: [] }).claims.map(c => [
      prettifyDate(c.timestamp),
      c.patient_name,
      c.patient_ic && c.patient_ic.length ? c.patient_ic : `${UNICODE.MINUS}`,
      getVisitType(c.encounter_flow, c.encounter_type),
      convertNumberToPrice(c.bill_total),
      convertNumberToPrice(c.amount),
      c.bill_notes && c.bill_notes.length ? c.bill_notes : `${UNICODE.MINUS}`,
    ]);
    formattedData.push(
      [],
      ['Date of export', new Moment().format(getDateFormat())],
      ['Panel name', claimInvoice.get('coverage_payor_name')],
      ['Cutoff dates set for this invoice', claimInvoice.getInvoiceMonth()],
      ['Total number of encounters in this invoice', claimInvoice.get('items', { claims: [] }).claims.length],
      ['Total claim amount of invoice', claimInvoice.getAmountClaimed()],
    );
    return downloadCSV(`${claimInvoice.get('internal_clinic_id', 'Claim Invoice')}.csv`, convertToCSV(EXPORT_FIELDS, formattedData));
  });
  return Promise.resolve();
}
