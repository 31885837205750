import PouchDB from 'pouchdb';
import type { Store } from 'redux';
import mergeDeepWith from 'lodash/mergeWith';
import { List, Map, fromJS, isImmutable } from 'immutable';

import { docToModel, foreignKeyToDocType } from './models';
import { getUserName } from './user';
import { isPrimitive, isObject, queryMapping, generateGUID } from './utils';
import { handleForbiddenResponse, handleApiError } from './response';
import { updateLocalUnsyncedModels } from './sync';
import { DDOC_VERSION, DOC_VALIDATION_ENABLED } from './../constants';
import { logMessage, debugPrint } from './logging';
import { withRetry, exponentialBackoffStrategy, linearRepeatStrategy } from './retry';
import { logout } from './auth';
import { startChangesFeed } from './changesFeed';
import resolveConflict from './conflictResolution';
import { createErrorNotification } from './notifications';
import translate from './i18n';
import { offlinePromptHandler, getIsOnline } from './offline';
import { version } from './../../package.json';

import type BaseModel from './../models/baseModel';
import type { Dispatch, ErrorResponse, Model, MapValue, DataView, Config, SaveModelRetryFunction } from './../types';
import APIError from './apiError';
import { setDocumentsPendingRegeneration, updateModel, clearModels, clearCurrentDataViewsModels, setDebugModeData } from '../actions';
import { getStore } from './redux';

PouchDB.plugin(require('pouchdb-authentication'));

let remoteDB: PouchDB.Database<{}>;
let localDB: PouchDB.Database<{}>;
let remoteDBChangesFeed: any;

const ENABLE_SAVE_MODEL_DOC_VALIDATION = false;

/**
 * An Error Class thrown if the model is deleted
 */
class ModelDeletedError extends Error {
  status: number;

  reason: string;

  /**
   * Creates an instance of ModelDeletedError.
   * @param {number} code Status Code to throw
   */
  constructor(code: number) {
    super('Model Deleted');
    this.name = 'ModelDeletedError';
    this.status = code;
    this.reason = 'Model Deleted';
  }
}

// Shared docs should always be kept up to date in state.
export const SHARED_DOC_TYPES = ['coverage_payor', 'drug', 'practitioner', 'sales_item', 'category',
  'template', 'template_group', 'metric_type', 'user_config', 'user_group', 'document_template', 'supplier',
  'bank', 'provider', 'procedure_type', 'specimen', 'payment_type', 'appointment',
  'encounter_stage', 'encounter_flow', 'discount_charge', 'dosing_regimen'];

/**
 * A generic error handler that will log the user out if the error is a 401 and just throw an error
 * in other situations.
 * @param {any} error The error response
 * @param {string} message A message for the thrown error.
 * @return {void}
 */
function handleGenericErrors(error: ErrorResponse,
  message: string = 'Something went wrong while getting from database') {
  handleApiError({ error, message });
}

/**
 * Creates instances of the required pouchDBs.
 * @returns {undefined}
 */
export function instantiateRemoteDBs() {
  remoteDB = new PouchDB(`${location.protocol}//${location.host}/db/klinifydev`, {
    skip_setup: true,
    timeout: 30000,
    fetch: (url, opts) => {
      if (!url.includes('_all_docs')) { // Only all_docs endpoint requires the content type to be application/json
        opts.headers.delete('content-type');
      }
      if (opts.body) {
        opts.headers.set('content-type', 'application/json');
      }
      opts.headers.set('x-utc-timestamp', Date.now());
      opts.headers.set('x-klinify-request-id', generateGUID());
      opts.headers.set('x-heals-request-id', `Klinify Webapp v${version}`);
      // TODO: uncomment for doc-validation
      // opts.headers.set('x-klinify-webapp-version', version);
      return PouchDB.fetch(url, opts);
    },
  });
}

/**
 * Creates instances of the required local pouchDBs.
 * @returns {Promise}
 */
export function instantiateLocalDBs() {
  localDB = new PouchDB(`${getUserName()}-klinifydb`);
  const ddoc = {
    _id: '_design/unsynced_docs',
    ver: version,
    views: {
      allUnsyncedDocs: {
        map: function mapFun(doc) {
          if (doc.type === 'unsynced_model') {
            // @ts-ignore
            emit(null, doc);
          }
        }.toString(),
      },
    },
  };
  return localDB.put(ddoc).catch((err) => {
    if (err.name !== 'conflict') {
      throw err;
    }
    localDB.get('_design/unsynced_docs').then((doc) => {
      if (doc.ver !== version) {
        localDB.put({ ...doc, ...ddoc });
      }
    });
  });
}

/**
 * Returns the remote DB.
 * @returns {PouchDB} The remote DB.
 */
export function getRemoteDB() {
  return remoteDB;
}

/**
 * Returns the local DB for the current user.
 * @returns {PouchDB} The remote DB.
 */
export function getLocalDB() {
  return localDB;
}

/**
 * Queries a design doc with retry
 * @param {string} url The url for the design doc.
 * @param {object} options An options object
 * @param {boolean} allowOffline Boolean to indicate this operation can fail silently if Offline
 * @return {Promise}
 */
export function queryDesignDoc(url: string, options: {} = {}, allowOffline: boolean = false) {
  if (getIsOnline() || allowOffline) {
    return withRetry(
      () => getRemoteDB().query(url, options),
      (retry, error) => {
        if (error.status === 502 || error.status === 0) {
          return retry('serverRetry', 1, exponentialBackoffStrategy(2));
        }
        if (error.status === 401) {
          return retry('authRetry', 1, linearRepeatStrategy(10));
        }
        if (error.status === 403) {
          handleForbiddenResponse(error);
        }
        throw error;
      },
    );
  }
  offlinePromptHandler();
  const error = new APIError(`Call to ${url} failed`, 0, {});
  return Promise.reject(error);
}

/**
 * @param {string} view The name of a CouchDB view.
 * @param {object} options An options object:
 *    keyFilter - A filter for the view's keys.
 * @return {[BaseModel]} Returns an array of CouchDB documents for the given view and filters.
 */
export function fetchDocsFromDesignDoc(view: string, options: {} = {}): Promise<Array<BaseModel>> {
  const url = `${DDOC_VERSION}/${view}`;

  return queryDesignDoc(url, options)
    .then(
      response => response.rows
        .map(row => docToModel(row.doc || row.value)) // If `include_docs=true` was part of the params then we need to use row.doc.
        .filter(model => model !== undefined), // If model type is not found docToModel will return undefined so we need to filter.
    ).catch((error) => {
      handleApiError({ error, message: `Unknown error occurred while fetching from view: ${url}` });
      if (error && (error.status === 0 || error.status === 502)) {
        createErrorNotification(`Error loading data from ${url}`);
      }
      if (error.name === 'not_found') {
        throw new Error(`Design doc is missing: ${url}`);
      }
      if (error.status === 0) {
        // Probably offline. In this case just return an empty list and ensure the components handle
        // it correctly.
        return [];
      }
      if (error.status === 401) {
        logout(true);
        return [];
      }
      throw new Error(`Unknown error occurred while fetching from view: ${url}`);
    });
}

/**
 * Fetches a document from the DB and converts it to a model.
 * @param {string} documentID A couch doc ID.
 * @param {function} callback An optional callback function that will be given the
 * converted model. Useful for actions like adding the model to the store after fetching.
 * @param {boolean} ignoreNotFound If true any 404s are ignored and returned as undefined.
 * @param {boolean} availableOffline If true the request is executed else error is thrown while ofline
 * @returns {BaseModel}
 */
export function fetchModel(
  documentID: string,
  callback?: (model: BaseModel) => void,
  ignoreNotFound: boolean = false,
  availableOffline: boolean = false,
) {
  if (getIsOnline() || availableOffline) {
    return withRetry(
      () => getRemoteDB().get(documentID),
      (retry, error) => {
        if (error.status === 502 || error.status === 0) {
          return retry('serverRetry', 4, exponentialBackoffStrategy(2));
        }
        if (error.status === 401) {
          return retry('authRetry', 1, linearRepeatStrategy(10));
        }
        if (error.status === 403) {
          handleGenericErrors(error);
        }
        throw error;
      },
    )
      .then(doc => docToModel(doc))
      .then((model) => {
        if (callback) {
          callback(model);
        }
        return model;
      })
      .catch((error) => {
        if (!(ignoreNotFound && error.status === 404)) {
          handleGenericErrors(error, 'Couldnt fetch model');
        }
      });
  }
  offlinePromptHandler();
  handleGenericErrors({ status: 0, name: 'TypeError' }, 'Couldnt fetch model');
  throw new APIError('Couldnt fetch model', 0, {});
}

/**
 * Fetches the models for the given document IDs and returns them as a List.
 * @param {Map<string, Array<string>>} documentIdMap A map of batch Identifiers to Array of document Ids
 * @param {boolean} ignoreNotFound If true any 404s are ignored and returned as undefined.
 * @returns {Promise<Map<string, List<Model>>>} A Promise to a map of batch Identifiers to List of fetched Models
 */
export function batchedFetchModels(
  documentIdMap: Map<string, Array<string>>,
  ignoreNotFound: boolean = false,
): Promise<Map<string, List<Model>>> {
  if (documentIdMap.size === 0) {
    return Promise.resolve(Map()); // No-op, mainly useful for testing.
  }
  const batchesData = documentIdMap.reduce((data, ids, key) => ({
    batchSlices: data.batchSlices
      .set(key, [data.documentIds.length, data.documentIds.length + ids.length]),
    documentIds: data.documentIds.concat(ids),
  }), { batchSlices: Map(), documentIds: [] });
  if (getIsOnline()) {
    return withRetry(
      () => getRemoteDB().allDocs({
        keys: batchesData.documentIds,
        include_docs: true,
      }),
      (retry, error) => {
        if (error.status === 502 || error.status === 0) {
          return retry('serverRetry', 4, exponentialBackoffStrategy(2));
        }
        if (error.status === 401) {
          return retry('authRetry', 1, linearRepeatStrategy(10));
        }
        if (error.status === 403) {
          handleGenericErrors(error, "Couldn't batch get docs");
        }
        throw error;
      },
    )
      .then(response => response.rows || [])
      .then((rows) => {
        const rowsNotFound = rows.filter(r => r.error && r.error === 'not_found') ||
          rows.filter(row => row.value && row.value.deleted === true);
        if (!ignoreNotFound && rowsNotFound && rowsNotFound.length > 0) {
          debugPrint(rowsNotFound, 'error');
          throw ModelDeletedError(404);
        }
        return rows;
      })
      .then(rows => batchesData.batchSlices.map(batch => List(
        rows
          .slice(...batch)
          .filter(row => !((row.value && row.value.deleted === true) || (row.error && row.error === 'not_found')))
          .map(row => docToModel(row.doc)),
      )))
      .catch((error) => {
        if (!(ignoreNotFound && error.status === 404) && error.name !== 'ModelDeletedError') {
          handleGenericErrors(error, 'Couldnt fetch model');
        }
      });
  }
  offlinePromptHandler();
  return Promise.resolve(Map());
}


/**
 * Fetches the models for the given document IDs and returns them as a List.
 * @param {Array<string>} documentIds An array of document Ids
 * @param {boolean} ignoreNotFound If true any 404s are ignored and returned as undefined.
 * @returns {Promise<List<Model>>}
 */
export function fetchModels(
  documentIds: Array<string>,
  ignoreNotFound: boolean = false,
): Promise<List<Model>> {
  if (documentIds.length === 0) {
    return Promise.resolve(List()); // No-op, mainly useful for testing.
  }
  if (getIsOnline()) {
    return withRetry(
      () => getRemoteDB().allDocs({ keys: documentIds, include_docs: true }),
      (retry, error) => {
        if (error.status === 502 || error.status === 0) {
          return retry('serverRetry', 4, exponentialBackoffStrategy(2));
        }
        if (error.status === 401) {
          return retry('authRetry', 1, linearRepeatStrategy(10));
        }
        if (error.status === 403) {
          handleGenericErrors(error, 'Couldnt fetch models');
        }
        throw error;
      },
    )
      .then(response => response.rows || [])
      .then((rows) => {
        if (!ignoreNotFound && rows.some(row => (row.value && row.value.deleted === true) ||
        row.error && row.error === 'not_found')) {
          throw ModelDeletedError(404);
        }
        return rows;
      })
      .then(rows => List(
        rows
          .filter(row => !(row.value && row.value.deleted === true))
          .map(row => docToModel(row.doc)),
      ))
      .catch((error) => {
        if (!(ignoreNotFound && error.status === 404) && error.name !== 'ModelDeletedError') {
          handleGenericErrors(error, 'Couldnt fetch model');
        }
      });
  }
  offlinePromptHandler();
  return Promise.resolve(List());
}

/**
 * Cancels all changes feeds if they are active.
 * @returns {void}
 */
export function cancelChangesFeeds() {
  if (remoteDBChangesFeed && remoteDBChangesFeed.cancel) {
    remoteDBChangesFeed.cancel();
  }
}

/**
 * Starts the remote DB changes feed.
 * @param {Redux.store} store The redux store for the app.
 * @param {object} retryOpts What retry attempt this is
 * @returns {void}
 */
export function startRemoteChangesFeed(store: Store, retryOpts: {} = {}) {
  debugPrint('Starting changes feed.');
  cancelChangesFeeds();
  remoteDBChangesFeed = startChangesFeed(remoteDB, store, retryOpts);
}

/**
   * A convenience function to fetch all docs needed app-wide (minus the bootstrap docs). This is
   * async (returns a promise).
   * @return {object[]} An array of documents.
   */
export function fetchSharedData(): Promise<Array<BaseModel>> {
  return fetchDocsFromDesignDoc(`${queryMapping.get('allByType')?.viewName}`, { keys: SHARED_DOC_TYPES, include_docs: true });
}

/**
   * A convenience function to fetch all docs needed for a patient. Async (returns a promise).
   * @param {string} patientID A couchDB ID for a Patient.
   * @return {object[]} An array of documents.
   */
export function fetchPatientRelatedDocs(patientID: string): Promise<Array<BaseModel>> {
  return fetchDocsFromDesignDoc(`${queryMapping.get('patientRelatedDocs')?.viewName}`, { key: patientID, include_docs: true });
}

/**
 * This function will be run by any function that save models to the DB (i.e. saveModel and
 * bulkSaveModels), before it is passed on to the DB. Currently it triggers updateEditedBy on the
 * model.
 * @param {BaseModel} model The model being saved.
 * @param {boolean} isMerge True if the model being saved was just merged with a conflict.
 * @param {string} editedByReason The reason for the edit (e.g. merge). Defaults to undefined.
 * @return {BaseModel} The updated model.
 */
function preSaveConfig(model: BaseModel, isMerge: boolean = false,
  editedByReason?: string) {
  model.updateEditedBy(editedByReason || (isMerge ? 'merge' : undefined));
  return model;
}

/**
   * A convenience function for saving a model. It also triggers the updating of edited_by
   * and the setting of _id (for new models) and _rev (all models).
   * NOTE: This is here because instead of as a method on the models as there were some brutal
   * issues with circular dependencies.
   * @param {BaseModel} model A BaseModel or extension of BaseModel.
   * @param {Dispatch} dispatch Redux dispatch function.
   * @param {string} encryptKey The key with which model will be encrypted saved for later in case of failure
   * @param {string} userID The userID to which the model belongs to beused during save for later in case of failure
   * @param {number} attempts The number of previous attempts to save the model.
   * @param {boolean} isMerge True if the model being saved was just merged with a conflict.
   * @param {string} editedByReason The reason for the edit (e.g. merge). Defaults to undefined.
   * @param {function} retryAction The function to call to retry save after resolving some issue that caused the current save call to fail.
   * @return {Promise} Returns a promise for the saving of the document.
   */
export function saveModel(
  model: BaseModel,
  dispatch: Dispatch,
  encryptKey: string,
  userID: string,
  attempts: number = 0,
  isMerge: boolean = false,
  editedByReason?: string,
  retryAction?: SaveModelRetryFunction,
): Promise<BaseModel | null> {
  if (getIsOnline()) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return getRemoteDB()
      .put(preSaveConfig(model, isMerge, editedByReason).attributes)
      .catch((error) => {
        if (error && error.status === 0) { // i.e. No internet connection.
          handleGenericErrors(error, 'There connection to the DB timed out');
          updateLocalUnsyncedModels(
            getLocalDB(), userID || getUserName(), List([model]), encryptKey,
          );
          return { ok: false };
        }
        if (DOC_VALIDATION_ENABLED &&
          ENABLE_SAVE_MODEL_DOC_VALIDATION &&
          error && error.error && error.fields?.length) {
          const missingDocs = error.fields?.map((field: string) =>
            docToModel({ _id: model.get(field), type: foreignKeyToDocType(field) }));
          dispatch(clearModels(missingDocs));
          dispatch(clearCurrentDataViewsModels(missingDocs));
          dispatch(setDocumentsPendingRegeneration(List(error.fields?.map((field: Array<string>) =>
            ({
              _id: model.get(field),
              type: field,
              referrerDoc: model,
              onDocsResolved: retryAction || (() => saveModel(
                model,
                dispatch,
                encryptKey,
                userID,
                0,
                isMerge,
                editedByReason,
              ).then((savedModel) => {
                dispatch(updateModel(savedModel));
                return savedModel;
              })),
            })
          ))));
          handleGenericErrors(error, 'There was an error while saving this model because some docs that you are saving this data with could not be found');
          return { ok: false, isUnsaved: true };
        }
        if (error && error.status === 409) {
          logMessage('A conflict occurred, attempting to resolve');
          return resolveConflict(model, dispatch, encryptKey, userID, attempts);
        }
        if (error && error.status === 403) {
          handleGenericErrors(error, 'The user tried to save a document with mismatching metadata');
          updateLocalUnsyncedModels(
            getLocalDB(), userID || getUserName(), List([model]), encryptKey,
          );
          return { ok: false };
        }
        if (error && error.status === 401) {
          updateLocalUnsyncedModels(
            getLocalDB(), userID || getUserName(), List([model]), encryptKey,
          );
          handleGenericErrors(error);
          // logout(true);
          return { ok: false };
        }
        handleGenericErrors(error, 'There was an error while running saveModel');
        updateLocalUnsyncedModels(getLocalDB(), userID || getUserName(), List([model]), encryptKey);
        return { ok: false };
      })
      .then((response) => {
        if (response.ok) {
          model.set('_id', response.id);
          model.set('_rev', response.rev);
        }
        model.clearChanges();
        if (isMerge) {
          alert(translate('merge_warning', { type: model.get('type') }));
        }
        if (response.isUnsaved) {
          return null;
        }
        return model;
      })
      .catch((error) => {
        handleGenericErrors(error, error?.message ?? 'Unexpected Error Occured:');
        return model;
      });
  }
  updateLocalUnsyncedModels(getLocalDB(), userID || getUserName(), List([model]), encryptKey);
  return Promise.resolve(model);
}


/**
 * Bulk saves an array of models.
 * @param {Array<BaseModel>} models Models to save
 * @param {Dispatch} dispatch Redux dispatch function.
 * @param {string} encryptKey The key with which model will be encrypted saved for later in case of failure
 * @param {string} userID The userID to which the model belongs to beused during save for later in case of failure
 * @param {function} retryAction The function to call to retry save after resolving some issue that caused the current save call to fail.
 * @returns {Promise<Array<BaseModel>>}
 */
export function saveModels(
  models: Array<BaseModel>,
  dispatch: Dispatch,
  encryptKey: string,
  userID: string,
  retryAction?: SaveModelRetryFunction,
): Promise<Array<BaseModel | null>> {
  if (ENABLE_SAVE_MODEL_DOC_VALIDATION && getStore().getState().debugModeFlags.docValidation) {
    dispatch(setDebugModeData(
      'docValidation',
      (newModels: List<Model>) => Promise.all(newModels.map((model) => {
        const modelToSave = model.beforeSave ? model.beforeSave() : model;
        return saveModel(
          modelToSave,
          dispatch,
          encryptKey,
          userID,
          undefined, undefined, undefined,
          retryAction,
        );
      })),
      List([
        ...models,
      ]),
    ));
    return Promise.resolve([null]);
  }
  return Promise.all(models.map((model) => {
    const modelToSave = model.beforeSave ? model.beforeSave() : model;
    return saveModel(
      modelToSave, dispatch, encryptKey, userID, undefined, undefined, undefined, retryAction,
    );
  }));
}

/**
 * Gets casenote stats for a patient
 * Will return something like this:
 * {
 *  'key':null,
 *  'value':{'sum':18350080,'count':15,'min':1097728,'max':1286144,'sumsqr':22481469440000}
 * }
 *
 * Only count, min, and max are of use. Min and max are for order, count is the number of
 * casenotes for that patient.
 * @param {string} patientID The patient to get the stats for.
 * @returns {Promise} A Promise that will return the stats.
 */
const getCasenoteStatsByPatient = (patientID: string) => queryDesignDoc(
  `${queryMapping.get('caseNoteFileOrderByPatient')?.queryDDOCView}`,
  {
    key: patientID,
  },
);

/**
  * Returns an ajax promise that will fetch the highest order of casenotes for a patient.
   * @param {string} patientID The patient ID.
   * @return {Promise} Returns a Promise that will return the current highest casenote order for
   * the given patient.
   */
export function getHighestCasenoteOrder(patientID: string) {
  return getCasenoteStatsByPatient(patientID).then((data) => {
    let max = 0;
    if (data !== undefined && data.rows !== undefined && data.rows.length > 0) {
      ({ max } = data.rows[0].value);
    }
    return max;
  }).catch((error) => {
    handleGenericErrors(error);
    Promise.reject(error);
  });
}

/**
 * Fetches the clinic config document.
 * @param {string} configID config id
 * @returns {Promise} A PouchDB promise for the operation.
 */
export function getClinicConfig(configID: string): Promise<{}> {
  // For testing return an empty Map.
  if (!getRemoteDB()) {
    return Promise.resolve({});
  }
  return withRetry(
    () => getRemoteDB().get(configID),
    (retry, error) => {
      if (error.status === 502 || error.status === 0) {
        return retry('serverRetry', 4, exponentialBackoffStrategy(2));
      }
      if (error.status === 401) {
        return retry('authRetry', 1, linearRepeatStrategy(10));
      }
      if (error.status === 403) {
        handleForbiddenResponse(error);
      }
      throw error;
    },
  )
    .catch(error => handleGenericErrors(error, 'Couldnt get clinic config'));
}

/**
 * check if the local changes create a conflict with the remote changes.
 * @param {object} updatedDoc The updated clinic config document to update.
 * @param {object} currentDoc The original clinic config document to update.
 * @param {object} changes The updates to apply to the config document.
 * @returns {boolean} whether changes have a conflict
 */
export function isClinicConfigMergeable(
  updatedDoc: object,
  currentDoc: object,
  changes: object,
): boolean {
  return !Object.keys(changes).some((key) => {
    const change = changes[key];
    if (isPrimitive(change) || Array.isArray(change)) {
      if (isPrimitive(updatedDoc[key]) && isPrimitive(currentDoc[key])) {
        return updatedDoc[key] !== currentDoc[key];
      }
      if (Array.isArray(currentDoc[key]) && Array.isArray(updatedDoc[key])) {
        if (updatedDoc[key].length !== currentDoc[key].length) {
          return true;
        }
        return JSON.stringify(updatedDoc[key]) !== JSON.stringify(currentDoc[key]);
      }
      if (isObject(currentDoc[key]) && isObject(updatedDoc[key])) {
        return JSON.stringify(updatedDoc[key]) !== JSON.stringify(currentDoc[key]);
      }
      return true;
    }
    if (isObject(change)) {
      if (isPrimitive(updatedDoc[key]) && isPrimitive(currentDoc[key])) {
        return updatedDoc[key] !== currentDoc[key];
      }
      if (Array.isArray(currentDoc[key]) && Array.isArray(updatedDoc[key])) {
        if (updatedDoc[key].length !== currentDoc[key].length) {
          return true;
        }
        return JSON.stringify(updatedDoc[key]) !== JSON.stringify(currentDoc[key]);
      }
      if (isObject(currentDoc[key]) && isObject(updatedDoc[key])) {
        return !isClinicConfigMergeable(updatedDoc[key], currentDoc[key], change);
      }
      return true;
    }
    return true;
  });
}

/**
 * Updates the clinic config document.
 * @param {object} clinicConfigDocument The original clinic config document to update.
 * @param {object} clinicConfigUpdates The updates to apply to the config document.
 * @param {function} saveConfigInStore function to update app's store with new config.
 * @returns {Promise} A PouchDB promise for the operation.
 */
export function updateClinicConfig(
  clinicConfigDocument: { [key: string]: MapValue },
  clinicConfigUpdates: { [key: string]: MapValue },
  saveConfigInStore: (config: Config) => void,
) {
  let updatedDoc = mergeDeepWith(
    {},
    clinicConfigDocument,
    clinicConfigUpdates,
    (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return srcValue;
      }
      return undefined;
    },
  );
  if (!clinicConfigDocument.created_by) {
    updatedDoc = Object.assign(updatedDoc, {
      created_by: {
        timestamp: new Date().getTime(),
        user_id: getUserName() || 'UNKNOWN_USER',
      },
    });
  }
  if (!clinicConfigDocument.edited_by) {
    updatedDoc = Object.assign(updatedDoc, { edited_by: [] });
  }
  updatedDoc.edited_by.push({
    timestamp: new Date().getTime(),
    user_id: getUserName() || 'UNKNOWN_USER',
  });
  debugPrint('Saving clinic config document.');
  return new Promise(
    (resolve, reject) => {
      getRemoteDB().put(updatedDoc,
        (error, response) => {
          if (error) {
            if (error.status === 409) {
              // Conflict, config has been modified since last fetch, refetch, merge changes and re-update
              return getClinicConfig(clinicConfigDocument._id)
                .then((updatedConfigDoc) => {
                  if (!isClinicConfigMergeable(
                    updatedConfigDoc,
                    clinicConfigDocument,
                    clinicConfigUpdates,
                  )) {
                    if (saveConfigInStore && updatedConfigDoc) {
                      saveConfigInStore(fromJS(updatedConfigDoc));
                    }
                    throw new Error('Couldnt merge with latest clinic config');
                  }
                  return resolve(updateClinicConfig(
                    updatedConfigDoc,
                    clinicConfigUpdates,
                    saveConfigInStore,
                  ));
                }).catch((err) => {
                  handleGenericErrors(err, 'Couldnt merge with latest clinic config');
                  return reject(err);
                });
            }
            handleGenericErrors(error, 'Couldnt update clinic config');
            return reject(error);
          }
          if (saveConfigInStore && response && response.ok) {
            updatedDoc._rev = response.rev;
            saveConfigInStore(fromJS(updatedDoc));
          }
          return resolve(response);
        });
    },
  );
}

/**
 * Fetches an Asset from the database (only for stored assets, i.e. templates).
 * @param {string} assetID Asset ID.
 * @returns {Promise<Blob>}
 */
export function fetchAssetFromCouch(assetID: string): Promise<Blob> {
  if (process.env.NODE_ENV === 'test') { return Promise.resolve(new Blob()); } // Fake the call if in a test environment.
  return withRetry(
    () => getRemoteDB().getAttachment(assetID, 'data'),
    (retry, error) => {
      if (error.status === 502 || error.status === 0) {
        return retry('serverRetry', 4, exponentialBackoffStrategy(2));
      }
      if (error.status === 401) {
        return retry('authRetry', 1, linearRepeatStrategy(10));
      }
      throw error;
    },
  )
    .catch(error => handleGenericErrors(error, 'Couldnt get asset'));
}

/**
 * Fetches a list of assets from the DB.
 * @param {Array<string>} assetIDs An array of asset IDs.
 * @returns {Promise<Array<any>>}
 */
export function fetchAssetsFromCouch(assetIDs: Array<string>): Promise<Array<Blob>> {
  return assetIDs.reduce((previousPromise: Promise<Array<Blob>>, currentAssetID) => (
    previousPromise.then((assetBlobs: Array<Blob>) => (
      fetchAssetFromCouch(currentAssetID).then((blob: Blob) => {
        assetBlobs.push(blob);
        return assetBlobs;
      })))
  ), Promise.resolve([]));
}

/**
 * Fetches the data for the given view and concatenates it to any previously fetched models.
 * @param {DataView} view view
 * @returns {Promise<List<Model>>}
 */
export function fetchFromView(view: DataView) {
  if (view.has('_id')) {
    const _id = view.get('_id');
    if (typeof _id !== 'string') {
      throw new Error('Incorrectly defined "id" field in DataView.');
    }
    return fetchModel(_id).then(model => List([model]))
      .catch((error) => {
        handleGenericErrors(error);
      });
  }
  const fetch = ((_view) => {
    if (_view.get('view')) {
      const viewName = _view.get('view');
      if (typeof viewName !== 'string') {
        throw new Error('Incorrectly defined "view" field in DataView.');
      }
      return fetchDocsFromDesignDoc(viewName, _view.get('options', new Map()).toJS());
    }
    if (_view.hasIn(['options', 'keys'])) {
      return fetchModels(_view.getIn(['options', 'keys'], []));
    }
    return undefined;
  })(view);

  if (fetch === undefined) {
    const retval: Promise<List<Model>> = Promise.resolve(List());
    return retval;
  }
  return fetch.then(models => List(Array.isArray(models) || isImmutable(models) ? models : []));
}
