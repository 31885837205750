import type { List } from 'immutable';

import { getBaseApiUrl, convertNumberToPrice, sum } from './utils';
import fetch from './fetch';
import { logToAppInsight, debugPrint } from './logging';

import type ProcedureRequestModel from './../models/procedureRequestModel';
import type ProcedureStatusModel from './../models/procedureStatusModel';
import type ProcedureTypeModel from './../models/procedureTypeModel';

/**
 * Sends a lab order to the API for 3rd party integration
 * @param {string} procedureRequestID procedure_request_id
 * @returns {Promise<Response | void>} integration id, void or Exception
 */
export function sendLabOrder(procedureRequestID: string): Promise<Response | void> {
  return fetch(new Request(`${getBaseApiUrl()}/integrations/labs/send_orders/${procedureRequestID}`), {
    method: 'PUT',
    credentials: 'same-origin',
  }).then((response) => {
    if (response) {
      return response;
    } else if (response && response.status === 401) { // mostly unreachable code here, added catch block
      window.logout(true); // This is not quite a proper logout but i can't import the logout from ./utils/auth due to some circular dependencies.
      return undefined;
    }
    debugPrint(response, 'error');
    throw new Error('Something went wrong while sending lab order to the API.');
  })
    .catch((error) => {
      debugPrint(error, 'error');
      logToAppInsight('Something went wrong while sending lab order.', error ? error.status : null);
      if (error && error.status === 401) {
        window.logout(true); // This is not quite a proper logout but i can't import the logout from ./utils/auth due to some circular dependencies.
      }
    });
}

/**
 * Cancel the lab order
 * @param {string} procedureRequestID the procedure reuquest id
 * @returns {Promise<Response | void>} integration id, void or Exception
 */
export function cancelLabOrder(procedureRequestID: string): Promise<Response | void> {
  return fetch(new Request(`${getBaseApiUrl()}/integrations/labs/cancel_orders/${procedureRequestID}`), {
    method: 'PUT',
    credentials: 'same-origin',
  }).then((response) => {
    if (response) {
      return response;
    } else if (response && response.status === 401) { // mostly unreachable code here, added catch block
      window.logout(true); // This is not quite a proper logout but i can't import the logout from ./utils/auth due to some circular dependencies.
      return undefined;
    }
    debugPrint(response, 'error');
    throw new Error('Something went wrong while cancelling lab order.');
  })
    .catch((error) => {
      debugPrint(error, 'error');
      logToAppInsight('Something went wrong while cancelling lab order.', error ? error.status : null);
      if (error && error.status === 401) {
        window.logout(true); // This is not quite a proper logout but i can't import the logout from ./utils/auth due to some circular dependencies.
      }
    });
}

/**
 * Returns a list of cancelled procedureType ids based on procedureRequests
 * @param {List<ProcedureRequestModel>} procedureRequests List of ProcedureRequestModel.
 * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
 * @param {string} encounterID The encounterID
 * @param {string} patientID the patientID
 * @returns {List<string>}
 */
export function getCancelledProcedureRequestsProcedureTypesIDs(
  procedureRequests: List<ProcedureRequestModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  encounterID: string,
  patientID: string,
) {
  return procedureRequests.filter(model => model.getStatusLastEventStatus(procedureStatuses) === 'cancelled' &&
      model.get('encounter_id') === encounterID &&
      model.get('patient_id') === patientID).map(model => model.get('procedure_type_id'));
}

/**
 * Returns the sum of ProcedureTypes total price.
 * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel
 * @return {string}
 */
export function getProcedureTypesTotalPrice(procedureTypes: List<ProcedureTypeModel>) {
  return convertNumberToPrice(sum(procedureTypes.filter(model => model.get('price')).map(model => model.get('price'))));
}
