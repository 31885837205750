import React from 'react';
import { isList, List, Map } from 'immutable';

import { Store } from 'redux';
import {
  updateModel, updatePatientStub, updateCurrentDataViewsModel,
  setLastReceivedDBSequence, updateModels, setCurrentDataViews,
  showAppointmentRequested, showAppointmentRequestNotificationIcon,
  updatePatientsSearch,
} from './../actions';
import { getBaseApiUrl, isClinicOverviewPage, queryMapping } from './utils';
import { handleForbiddenResponse } from './response';
import { isExportUser, logout } from './auth';
import { logToAppInsight, debugPrint } from './logging';
import { docToModel } from './models';
import { isToday, prettifyTime, prettifyDate } from './time';
import setOnlineStatus from './offline';
import { startRemoteChangesFeed, SHARED_DOC_TYPES, cancelChangesFeeds } from './db';
import { fetchDocsForPatients, fetchCampaign } from './api';
import { updateInventoryBatchCountMap } from './inventory';
import fetch from './fetch';
import { saveModelsAPIFactory } from './redux';
import { syncCampaigns } from './patientCampaign';
import PatientModel from './../models/patientModel';
import PatientStubModel from './../models/patientStubModel';

import { createAppointmentRequestNotification, createCustomNotification } from '../utils/notifications';
import translate from '../utils/i18n';
import Button from './../../src/components/buttons/button';

import AppointmentModel from '../models/appointmentModel';

const NESTED_VIEW_CONFIG = {
  // this means for a nested view allByType, with options having key as claim_invoice_payment, it can have documents with
  // type = 'claim_reconciliation' (any one from array) from its nested views
  [`${queryMapping.get('allByType')?.viewName}`]: {
    claim_invoice_payment: ['claim_reconciliation'],
  },
};

const CHANGESFEED_INTERVAL = 115000;

/**
 * This is to check specific case of nested view where the docs are retrieved using fetchFromValues after first view.
 * @param {any} view The view name
 * @param {any} doc A raw CouchDB document
 * @returns {boolean} True if doc is part of view.
 */
export function docFromNestedView(view, doc) {
  if (view.get('view')) {
    return NESTED_VIEW_CONFIG[view.get('view')] &&
      NESTED_VIEW_CONFIG[view.get('view')][view.get('options').get('key')] &&
      NESTED_VIEW_CONFIG[view.get('view')][view.get('options').get('key')].includes &&
      NESTED_VIEW_CONFIG[view.get('view')][view.get('options').get('key')].includes(doc.type);
  }
  return false;
}

/**
 * Checks if a given doc is part of the given view.
 * @param {any} view The view name
 * @param {any} doc A raw CouchDB document
 * @returns {boolean} True if doc is part of view.
 */
export function docMatchesView(view, doc) {
  switch (view.get('view')) {
    case 'encountersByLastEventTime':
      // TODO: This is shit and will return bills that shouldnt be viewed. Either filter here
      // or in claimsReportsContainer.
      return doc.type === 'encounter' || doc.type === 'bill';
    case `${queryMapping.get('allByType')?.viewName}`:
      if (view.get('options').get('keys')) {
        return view.get('options').get('keys').includes(doc.type);
      }
      return doc.type === view.get('options').get('key');
    case `${queryMapping.get('outstandingReceivablesByTimestamp')?.viewName}`:
      return doc.type === 'receivable';
    case `${queryMapping.get('allByInvoiceIdAndType')?.viewName}`:
      return (doc.type === 'claim_invoice') || (doc.type === 'claim_invoice_payment')
    default:
      return false;
  }
}

/**
  * Returns true if the app is online.
  * @returns {boolean}
  */
function isServerAvailable(): Promise<boolean> {
  return fetch(
    new Request(`${getBaseApiUrl()}/status`),
    {
      method: 'GET',
      credentials: 'same-origin',
    },
  )
    .then((response) => {
      if (!response.ok) {
        handleForbiddenResponse(response);
      }
      return !!response.ok;
    })
    .catch(() => false);
}

/**
 * Checks the server side connection on a 5 second cycle. Updates state if it changes. If going from offline to
 * online it will also request that any offline assets be saved.
 * @param {Redux.store} store  redux store
 * @param {object} retryOpts Changes feed retry info, for various errors. This is passed into changes feed, when app switches online
 * @param {boolean} delayStartup If set to true, will wait for 30 seconds before checking if server is online
 * @returns {void}
 */
const checkServerConnection = (store: Store, retryOpts: object, delayStartup = true) => {
  const clientOnline = navigator.onLine;
  if (store.getState().serverOffline) {
    if (clientOnline) {
      isServerAvailable()
        .then((online) => {
          if (store.getState().isOnline !== online) {
            setOnlineStatus(store, online, !online);
            if (online) {
              setTimeout(() => startRemoteChangesFeed(store, retryOpts), delayStartup ? CHANGESFEED_INTERVAL : 0);
            }
          }
          if (!online) {
            setTimeout(() => checkServerConnection(store, retryOpts, delayStartup), 5000);
          }
        });
    } else {
      setTimeout(() => checkServerConnection(store, retryOpts, false), 5000);
    }
  }
};


/**
   * Called when the DB updates. Converts the new documents to models and dispatches them to the
   * store.
   * @param {Redux.store} store - The redux store for the app.
   * @param {Object[]} docs - An array of documents from CouchDB.
   * @return {undefined}
   */
function handleChanges(store, docs) {
  const docsByRequestId = Map({});
  docs.forEach((doc) => {
    if (doc.created_by.request_id &&
      store.getState().unsyncedAPICalls.has(doc.created_by.request_id)) {
      docsByRequestId.update(doc.created_by.request_id, [], d => d.push(doc));
    }
    if (doc.type === 'patient') {
      // Get patient docs for the patient if it can't be found in state already.
      if (store.getState().patients &&
      !store.getState().patients.find(patient => patient.get('_id') === doc._id)) {
        fetchDocsForPatients([doc._id])
          .then(models => store.dispatch(updateModels(models)));
      }
      // Always update patients. Ideally we would be able to distinguish between stubs and full
      // models as needed, but this is a bit too much work at the moment.
      store.dispatch(updateModel(new PatientModel(doc)));
      store.dispatch(updatePatientStub(new PatientStubModel(doc)));
      store.dispatch(updatePatientsSearch([doc]));
    } else if (doc.type === 'appointment' && doc.status === 'pending') {
      const { appointments, patients } = store.getState();
      const requestedAppointments = appointments
        .filter((a: AppointmentModel) => a.isPending() && a.isTodayOrFuture());
      const requestAppointment = docToModel(doc) as AppointmentModel;
      store.dispatch(updateModel(requestAppointment));
      if (!requestedAppointments.find(a => a.get('_id') === doc._id) && doc.status === 'pending') {
        const patient = patients.find(p => p.get('_id') === doc.patient_id);
        const consultMode = requestAppointment.getConsultMode();
        const notificationTitle = translate(`new_${consultMode}_request_title`);
        const notificationMessage = translate(`new_${consultMode}_request_details`, {
          patient_name: patient.get('patient_name'),
          date: prettifyDate(doc.start_timestamp),
          time: prettifyTime(doc.start_timestamp),
        });
        if (!isClinicOverviewPage(window.location.hash)) {
          store.dispatch(showAppointmentRequestNotificationIcon(true));
        }
        createAppointmentRequestNotification(notificationTitle, notificationMessage,
          <Button
            className="o-text-button o-text-button--contextual u-margin-top--half-ws"
            onClick={() => {
              // intitaly form modal setVisible will not be called on click of view request
              store.dispatch(updateModel(requestAppointment.setUnreadCheck(false)));
              store.dispatch(showAppointmentRequested(requestAppointment));
            }}
            dataPublic
          >
            {translate('view_request')}
          </Button>);
      }
    } else if (SHARED_DOC_TYPES.indexOf(doc.type) !== -1) {
      // Always update shared documents.
      store.dispatch(updateModel(docToModel(doc)));
    } else if (doc.type === 'patient_campaign_set') {
      syncCampaigns(store, false);
    } else if (doc.type === 'patient_campaign') {
      // Always update patient_campaign doc in currentDataViewsModels
      const { currentDataViewsModels, currentDataViews } = store.getState();
      const modelTodit = currentDataViewsModels.find(d => d.get('_id') === doc._id);
      const isEditedFromcampaigsPage = currentDataViews.find(v => v.get('api') === 'PATIENT_CAMPAIGN_LIST');
      if (modelTodit && !isEditedFromcampaigsPage) {
        store.dispatch(setCurrentDataViews(null));
      } else {
        fetchCampaign(doc._id, true)
          .then(model => store.dispatch(updateCurrentDataViewsModel(model)));
      }
      // Store updation getting called twice for the appointment type doc
    } else if (doc.patient_id && store.getState().patients.find(p => p.get('_id') === doc.patient_id) && doc.type !== 'appointment') {
      // If a patient is cached then always update related docs.
      store.dispatch(updateModel(docToModel(doc)));
    } else {
      // If an encounter is for today, add it to the store and then trigger a fetch of all docs
      // for the associated patient. This will not fire on patient/encounters that are already in
      // store as they should have been captured in the previous if statement.
      if (doc.type === 'encounter' && doc.encounter_events && doc.encounter_events.length
        && isToday(doc.encounter_events[doc.encounter_events.length - 1].time)) {
        store.dispatch(updateModel(docToModel(doc)));
        // Get patient docs for the patient related to this encounter if it can't be found.
        if (!store.getState().patients.find(patient => patient.get('_id') === doc.patient_id)) {
          fetchDocsForPatients([doc.patient_id])
            .then(models => store.dispatch(updateModels(models)));
        }
      }
      // Check if the document matches any of the current views, and if so update it.
      store.getState().currentDataViews.forEach((view) => {
        if (docMatchesView(view, doc) || docFromNestedView(view, doc)) {
          store.dispatch(updateCurrentDataViewsModel(docToModel(doc)));
        }
      });
      if (doc.type === 'export' && doc.status === 'completed' && isExportUser(store.getState().user)) {
        const notificationContent = (
          <h6>{translate('data_export_completed_message')}</h6>
        );
        createCustomNotification(translate('data_exports_update'), '', notificationContent);
      }
    }
  });

  const transactions = docs.filter(doc => doc.type === 'transaction').map(doc => docToModel(doc));
  if (transactions && transactions.size) {
    updateInventoryBatchCountMap(
      store,
      transactions,
      store.getState().inventoryCount,
    );
  }

  docsByRequestId.entrySeq()
    .map((requestId, documents) => {
      const apiCall = store.getState().unsyncedAPICalls.get(requestId);
      if (apiCall) {
        return saveModelsAPIFactory(store.dispatch)(apiCall.method,
          { ...apiCall.opts, documents, hasChangesFeed: true });
      }
      return Promise.resolve();
    });
}

/**
 * Starts the changes feed and handles changes as necessary.
 * @param {PouchDB} db The PouchDB instance.
 * @param {Redux.store} store The Redux store.
 * @param {int} retry What retry attempt this is
 * @returns {PouchDB} The PouchDB instance.
 */
export function startChangesFeed(
  db,
  store,
  {
    authRetry = 0,
    status0Count = 0,
    errorCount = 0,
  } = {},
) {
  const startSequence = store.getState().lastReceivedDBSequence;
  return db
    .changes({
      since: startSequence !== null ? startSequence : 'now',
      live: true,
      include_docs: true,
      timeout: CHANGESFEED_INTERVAL,
      heartbeat: false,
    })
    .on('change', (change) => {
      // handle change
      debugPrint('K: Change');
      debugPrint(change);
      store.dispatch(setLastReceivedDBSequence(change.seq));
      handleChanges(store, new List([change.doc]));
    })
    .on('error', (error) => {
      debugPrint(JSON.stringify(error, null, 4), 'error');
      if (error && error.status === 401) {
        if (authRetry >= 1) {
          cancelChangesFeeds();
          logout(true);
        } else {
          setTimeout(() => startRemoteChangesFeed(store, { authRetry: authRetry + 1 }), 30000); // Restart after waiting 30 secs.
        }
      } else if (error && error.status === 0) {
        debugPrint('Changes feed returned with status 0, trying to re-establish connection', 'error');
        if (status0Count >= 5) {
          logToAppInsight('Changes feed returned with status 0 5 times.', 0);
        }
        const retry = status0Count >= 5 ? 0 : status0Count;
        setOnlineStatus(store, false, true);
        if (navigator.onLine) {
          checkServerConnection(
            store,
            {
              authRetry,
              status0Count: retry + 1,
              errorCount,
            },
          );
        } else {
          checkServerConnection(
            store,
            {
              authRetry,
              status0Count: retry,
              errorCount,
            },
          );
        }
      } else {
        debugPrint('Error occurred in changes feed, trying to re-establish connection', 'error');
        if (errorCount >= 5) {
          logToAppInsight('Changes feed throw 5xx error 5 times', error ? error.status : null);
        }
        const retry = errorCount >= 5 ? 0 : errorCount;
        setOnlineStatus(store, false, true);
        if (navigator.onLine) {
          checkServerConnection(
            store,
            {
              authRetry,
              status0Count,
              errorCount: retry + 1,
            },
          );
        } else {
          checkServerConnection(
            store,
            {
              authRetry,
              status0Count,
              errorCount: retry,
            },
          );
        }
      }
    });
}
