import React from 'react';

import Label from './label';
import Input from './input';
import Select from './select';
import Button from './../buttons/button';

import type { SelectOpts, MapValue } from './../../types';

type Row = { [key: string]: MapValue };
type Column = { value: string, label: string, options?: SelectOpts }; // Columns are the name/id of each input in the row.

type Props = {
  columns: Array<Column>,
  description?: string,
  divClassName: string,
  hideLabel?: boolean,
  id: string,
  label: string,
  labelClassName: string,
  onValueChanged: (row: Array<Row>) => void,
  required?: boolean,
  style?: { [key: string]: string | number },
  value: Array<Row>,
  disabled: boolean,
};

/**
 * A component for adding a grid style input, where each row is considered a collection of related
 * data. E.g. An eduction input where each row has Institution, Degree, and Year of Graduation.
 * @class GridInput
 * @extends {React.Component}
 */
class GridInput extends React.Component<Props, {}> {
  props: Props;

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    divClassName: '',
    labelClassName: '',
    inputStyle: {},
    value: [{}],
  };

  /**
   * Handles the change of a value.
   * @param {number} rowIndex Row index
   * @param {string} key The key of the field being changed.
   * @param {string} value The new value
   * @returns {void}
   */
  onChange(rowIndex: number, key: string, value: string) {
    this.props.value[rowIndex] = Object.assign({}, this.props.value[rowIndex], { [key]: value });
    this.props.onValueChanged(this.props.value);
  }

  /**
   * Safely gets the rows for the input. Will create an empty row if none found.
   * @param {Array<Row>} value The current value
   * @returns {Array<Row>}
   */
  getRows(value: Array<Row>) {
    return value.length ? value : [{}]; // If no value has been entered default to a single empty row.
  }

  /**
   * Renders the appropriate input component for the given item
   * @param {Row} row The row values
   * @param {number} rowIndex The row index.
   * @param {Column} column The Column to render
   * @param {number} columnIndex The column index
   * @param {string} width The width of the input (as a percentage string).
   * @returns {React.Component}
   */
  renderInput(row: Row, rowIndex: number, column: Column, columnIndex: number, width: string) {
    if (column.options) {
      return (
        <Select
          disabled={this.props.disabled}
          onValueChanged={value => this.onChange(rowIndex, column.value, value)}
          id={`${this.props.id}_${column.value}_${columnIndex}}`}
          label={column.label}
          placeholder={column.label}
          value={row[column.value]}
          style={{ width: '100%', maxWidth: width, marginLeft: columnIndex > 0 ? '5px' : 0 }}
          hideLabel={rowIndex > 0}
          options={column.options}
          clearable={false}
        />
      );
    }
    return (
      <Input
        disabled={this.props.disabled}
        onValueChanged={value => this.onChange(rowIndex, column.value, value)}
        id={`${this.props.id}_${column.value}_${columnIndex}}`}
        label={column.label}
        placeholder={column.label}
        value={row[column.value]}
        style={{ maxWidth: width, marginLeft: columnIndex > 0 ? '5px' : 0 }}
        hideLabel={rowIndex > 0}
      />
    );
  }

  /**
   * Renders an input row.
   * @param {Row} row The row values
   * @param {number} rowIndex The row index.
   * @returns {React.Component}
   */
  renderRow(row: Row, rowIndex: number) {
    const width = `${99 / this.props.columns.length}%`;
    const components = this.props.columns.map((column, colIndex) =>
      this.renderInput(row, rowIndex, column, colIndex, width));
    if (
      !this.props.disabled &&
      (this.props.value.length === 0 || rowIndex === this.props.value.length - 1)
    ) {
      components.push(
        <Button
          className="o-button o-button--small"
          onClick={() => {
            const newValue = this.props.value;
            if (newValue.length === 0) {
              newValue.push({});
            }
            newValue.push({});
            this.props.onValueChanged(newValue);
          }}
          style={{
            minWidth: '46px',
            width: '46px',
            alignSelf: rowIndex === 0 ? 'center' : undefined,
            height: '46px',
            marginLeft: '5px',
          }}
          dataPublic
        >
          +
        </Button>,
      );
    }
    return <div className="c-grid-input__row">{components}</div>;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let description = '';
    if (this.props.description) {
      description = <p className="o-sub-label">{this.props.description}</p>;
    }
    return (
      <div className={`o-form__item ${this.props.divClassName}`} style={this.props.style}>
        {
          !this.props.hideLabel &&
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label}
            invalid={this.props.required && (this.props.value === undefined || this.props.value === '')}
          />
        }
        { description }
        <div className="c-grid-input">
          {
            this
              .getRows(this.props.value)
              .map((row, index) => this.renderRow(row, index))
          }
        </div>
      </div>
    );
  }
}

export default GridInput;
