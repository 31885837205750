import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';
import glamorous, { Label, Div } from 'glamorous';

import translate from './../../utils/i18n';
import { wsUnit, fonts } from './../../utils/css';
import Header from './../header/header';
import SaveButton from './../buttons/saveButton';
import { isKlinifyUser } from './../../utils/auth';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { updateClinicConfig } from './../../utils/db';

import type { Config, User, MapValue } from './../../types';


type Props = {
  config: Config,
  user: User,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
};

const ItemHeader = glamorous.header({
  display: 'flex',
  margin: wsUnit,
  alignItems: 'center',
});

const SubItems = glamorous.div({
  marginBottom: wsUnit,
  marginLeft: `calc(3 * ${wsUnit})`,
});


/**
 * @param {Props} props Component props from container
 * @returns {React.FC}
 */
const UserAccessManagement = (props: Props) => {
  const [modAccessManagementObject, setModAccessManagementObject] = useState(props.config.getIn(['access_management', 'user_management']).toJS());
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setModAccessManagementObject(props.config.getIn(['access_management', 'user_management']).toJS());
  }, [props.config]);

  /**
   * Updates specific values or subItems
   * @param {any} obj object of items to be updated.
   * @param {boolean} bool bool
   * @returns {any}
   */
  const toggleAllAccessLevels = (obj: any, bool: boolean): any => {
    if (Object.keys(obj).length > 0) {
      return Object.keys(obj).reduce((acc, item) => {
        if (item && typeof acc[item] === 'boolean') {
          return { ...acc, [item]: bool };
        }
        if (item && typeof acc[item] === 'object') {
          acc[item] = toggleAllAccessLevels(acc[item], bool);
          return acc;
        }
        return acc;
      }, obj);
    }
    return obj;
  };

  /**
   * Toggles all required access levels
   * @param {any} obj object of items to be updated.
   * @returns {any}
   */
  const toggleAllRequiredAccessLevels = (obj: any) => {
    const formattedObj = Object.assign({}, obj);
    const keys = Object.keys(formattedObj).filter(i => i !== 'enabled');
    keys.reduce((acc, item) => {
      if (item && acc[item] && acc[item].sub_items) {
        const bool = Object.keys(acc[item].sub_items).every(j => acc[item].sub_items[j]);
        acc[item].enabled = bool;
      }
      return acc;
    }, formattedObj);
    const bool = keys.every(i => formattedObj[i].enabled);
    formattedObj.enabled = bool;
    return formattedObj;
  };

  /**
   * Saves all the updated config.
   * @returns {void}
   */
  const onSaveClicked = () => {
    setIsSaving(true);
    const configUpdates = Map()
      .setIn(['access_management', 'user_management'], modAccessManagementObject)
      .toJS();
    updateClinicConfig(props.config.toJS(), configUpdates, props.updateConfig)
      .then((resp: any) => {
        if (resp && resp.ok) {
          createSuccessNotification(translate('permissions_updated'));
        } else {
          createErrorNotification('Error saving to config');
        }
        setIsSaving(false);
      }).catch((err) => {
        createErrorNotification('Error saving to config');
        setIsSaving(false);
      });
  };

  /**
   * Returns view for items and subitems
   * @param {Array<string>} keysInItems item keys
   * @param {any} items items object
   * @returns {React.Component} The rendered component.
   */
  const getItemsAndSubItemsView = (keysInItems: Array<string>): any => keysInItems.map((i) => {
    if (i) {
      return (
        <div>
          <ItemHeader>
            <input
              id={`checkbox-${i}`}
              type="checkbox"
              checked={modAccessManagementObject[i].enabled}
              onChange={() => {
                const bool = !modAccessManagementObject[i].enabled;
                const obj = {
                  ...modAccessManagementObject,
                  [i]: toggleAllAccessLevels(Object.assign({}, modAccessManagementObject[i]), bool),
                };
                setModAccessManagementObject(toggleAllRequiredAccessLevels(obj));
              }
              }
              style={{ marginRight: `calc(${wsUnit} / 2)` }}
            />
            <Label css={{ fontFamily: fonts.medium }} htmlFor={`checkbox-${i}`}>
              {translate(i)}
            </Label>
          </ItemHeader>
          <SubItems>
            {
                Object.keys(modAccessManagementObject[i].sub_items).map(e => (
                  <Div key={`${i}-${e}`} css={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        const bool = !modAccessManagementObject[i].sub_items[e];
                        const obj = {
                          ...modAccessManagementObject,
                          [i]: { ...modAccessManagementObject[i], sub_items: { ...modAccessManagementObject[i].sub_items, [e]: bool } },
                        };
                        setModAccessManagementObject(toggleAllRequiredAccessLevels(obj));
                      }}
                      checked={modAccessManagementObject[i].sub_items[e]}
                      id={`checkbox-${i}-${e}`}
                      style={{ marginRight: `calc(${wsUnit} / 2)` }}
                    />
                    <label htmlFor={`checkbox-${i}-${e}`}>
                      {translate(e)}
                    </label>
                  </Div>
                ))}
          </SubItems>
          <hr />
        </div>
      );
    }
    return (<div />);
  });

  const keysInItems = Object.keys(modAccessManagementObject).filter(i => i !== 'enabled');
  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      <h1 className="o-title">{translate('access_management')}</h1>
      <div className="o-card">
        <Header className="o-card__header">
          <input
            id="user_management"
            type="checkbox"
            checked={modAccessManagementObject.enabled}
            onChange={() => {
              const bool = !modAccessManagementObject.enabled;
              setModAccessManagementObject(toggleAllAccessLevels(Object.assign({}, modAccessManagementObject), bool));
            }}
            style={{ marginLeft: wsUnit }}
          />
          <label className="o-card__title" style={{ paddingLeft: `calc(${wsUnit} / 2)` }} htmlFor="user_management">
            {translate('user_management')}
          </label>
        </Header>
        <div>
          {getItemsAndSubItemsView(keysInItems)}
        </div>
      </div>
      <SaveButton
        className="u-margin--standard"
        isSaving={isSaving}
        onClick={() => onSaveClicked()}
      />
    </section>
  );
};

export default UserAccessManagement;
