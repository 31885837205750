import React from 'react';
import type { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import ContentTransition from './../contentTransition';
import { sidebarWidth } from './../../utils/css';
import PaymentsOutstandingTable from './paymentsOutstandingTable';

import type BillModel from './../../models/billModel';
import PatientStubModel from '../../models/patientStubModel';
import ReceivableModel from '../../models/receivableModel';
import PaymentTypeModel from '../../models/paymentTypeModel';
import type { SaveModels, SaveModel, User, Config } from './../../types';

type Props = {
  receivables: List<ReceivableModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  user: User,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  paymentTypes: List<PaymentTypeModel>,
  patientStubsMap: Map<string, PatientStubModel>,
  billsMap: Map<string, BillModel>
  config: Config,
};

/**
 * A component for viewing and processing outstanding payments on bills.
 * @class PaymentsOutstanding
 * @extends {React.Component<Props, State>}
 */
class PaymentsOutstanding extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { receivables, saveModels, user, saveModel, patientStubsMap, billsMap, config } = this.props;
    return (
      <ContentTransition>
        <section className="o-scrollable-container" style={{ height: '100vh', maxWidth: `calc(100vw - ${sidebarWidth})` }}>
          <h1 data-public className="o-title">{translate('payments_outstanding')}</h1>
          <PaymentsOutstandingTable
            receivables={receivables}
            saveModels={saveModels}
            saveModel={saveModel}
            user={user}
            isFetching={this.props.isFetching}
            currentDataViewsError={this.props.currentDataViewsError}
            paymentTypes={this.props.paymentTypes}
            patientStubsMap={patientStubsMap}
            billsMap={billsMap}
            config={config}
          />
        </section>
      </ContentTransition>
    );
  }
}

export default PaymentsOutstanding;
