import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import { RouteComponentProps } from 'react-router-dom';

import AddEncounterForm from '../components/consultations/addEncounterForm';
import { fetchData } from '../utils/api';
import AsyncFetch from './../components/asyncFetch';
import { saveFactory, saveModelsFactory } from '../utils/redux';
import type { Dispatch, State, Config, DataView } from '../types';
import type BaseModel from '../models/baseModel';
import { getBillsByEncounter, getSalesItemsFormDataview } from './../dataViews';
import { setIsFetching, setCurrentDataViewsError, setCurrentDataViews, updateModels, updateConfigValue, updateConfig, updatePatientStub } from './../actions';
import { getModelMapFromList } from '../utils/models';
import type APIError from '../utils/apiError';
import type SalesItemModel from '../models/salesItemModel';
import type PatientStubModel from '../models/patientStubModel';
import type PatientModel from '../models/patientModel';
import type BillModel from '../models/billModel';
import type EncounterModel from '../models/encounterModel';


interface OwnProps extends RouteComponentProps {
    patient: PatientModel,
    bill: BillModel,
    isFromDocValidationModal?: boolean,
    validationDocObject?: { id: string, type: string }
    isDocValidationModalSaving?: boolean,
    noSave?: boolean,
    validationReferrerModel?: BaseModel,
    onSaveAtDocValidationModal?: (wasSuccessful: boolean, models?: List<EncounterModel>) => void,
}

/**
 * @description Creates a Map of sales items with _id
 * Used to reduce time complexity of iterating salesItems list
 * @param {List<SalesItemModel>} salesItems List of salesItems
 * @return {Map<string, SalesItemModel>} Map of salesItem with _id,
 */
const mapSalesItem = memoizeOne((salesItems: List<SalesItemModel>): Map<string, SalesItemModel> =>
  salesItems.reduce((salesItemMap: Map<string, SalesItemModel>, salesItem: SalesItemModel) => salesItemMap.set(salesItem.get('_id'), salesItem), Map()));

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const {
    user,
    config,
    coveragePayors,
    patientStubs,
    bills,
    practitioners,
    salesItems,
    billItems,
    currentDataViews,
    currentDataViewsError,
    encounterFlows,
    encounterStages,
  } = state;
  const { validationReferrerModel, validationDocObject } = ownProps;
  const salesItemsMap = mapSalesItem(salesItems);
  const patient = ownProps.patient || patientStubs.find(i => i.get('_id') === validationReferrerModel?.get('patient_id'));
  const patientStubId = ownProps.patient.get('_id') || validationReferrerModel?.get('patient_id');
  const bill = ownProps.bill || bills.find(i => i.get('encounter_id') === validationDocObject?.id);
  const billItemsForBill = billItems.filter(i => i.get('bill_id') === bill?.get('_id'));
  const salesItemModels = billItemsForBill.reduce((acc, item) => {
    const salesItem = salesItemsMap.get(item?.get('sales_item_id'));
    if (salesItem) {
      return acc.push(salesItem);
    }
    return acc;
  }, List());
  const stagesMap = getModelMapFromList(encounterStages);
  return {
    containerDataViews: getBillsByEncounter(validationDocObject?.id),
    currentDataViews,
    currentDataViewsError,
    user,
    config,
    coveragePayors,
    bill,
    patient,
    patientStubId,
    patientStubs,
    practitioners,
    salesItemsMap,
    salesItemModels,
    billItemsForBill,
    encounterFlows,
    stagesMap,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  onAsyncFetchComplete: (models: List<BillModel>) => {
    const billModel = models && List.isList(models) && models.get(0);
    fetchData(getSalesItemsFormDataview(billModel?.get('_id')))
      .then((billItemModels) => {
        dispatch(updateModels(billItemModels.filter(m => !!m)));
      }).catch(() => {});
  },
  addPatientStub: (model: PatientStubModel) => dispatch(updatePatientStub(model)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: models => dispatch(updateModels(models)),
});

const AddEncounterContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(AsyncFetch(AddEncounterForm));

export default AddEncounterContainer;
