import React from 'react';
import type { List, Map } from 'immutable';

import { UNICODE } from './../../constants';
import StockWarning from './stockWarning';

import type DrugModel from './../../models/drugModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type { Config } from './../../types';
import { convertNumberToPrice, getLowInventoryThreshold, pluralizeWord } from '../../utils/utils';
import Checkbox from './../inputs/checkbox';
import translate from './../../utils/i18n';

type Props = {
  config: Config,
  drugs: List<DrugModel>,
  prescription: PrescriptionModel,
  className: string,
  showLowStockWarning?: boolean,
  inventoryCount: Map<string, number>,
  onValueChanged: (isSelected: boolean) => void,
}

/**
 * A component that display a Prescription
 * @class PrescriptionListItem
 * @extends {React.Component}
 */
class PrescriptionListItem extends React.PureComponent<Props> {
  props: Props

  static defaultProps = {
    className: '',
  };

  /**
   * Gets a string with the price, quantity, and dispensation unit.
   * @param {?DrugModel} drug The Drug model
   * @returns {string}
   */
  getPriceAndQuantity(drug: DrugModel | null | undefined): string {
    const price = this.props.prescription.has('sale_price', false) ?
      `${convertNumberToPrice(this.props.prescription.get('sale_price'))}` :
      UNICODE.EMDASH;
    const quantity = this.props.prescription.has('sale_quantity', false) ?
      `${this.props.prescription.get('sale_quantity')}` :
      UNICODE.EMDASH;
    const string = `${price} \u00D7 ${quantity}`;
    return drug ? `${string} (${drug.getDispensationUnit()})` : string;
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const drug = this.props.drugs.find(d => d.get('_id') === this.props.prescription.get('drug_id'));
    const prescribedDuration = this.props.prescription.get('prescribed_duration', []);
    return (
      <div className={`o-data-list__row ${this.props.className}`}>
        <div className="o-data-list__row__item o-data-list__row__item--small">
          <Checkbox
            id={`item-prescription-${this.props.prescription.get('drug_id')}`}
            label={translate('selected')}
            value={this.props.prescription.get('selected') === false ? [] : ['selected']}
            options={[{ label: '', value: 'selected' }]}
            hideLabel
            // disabled={
            //   !this.getUpdatePermission(item, claimableCheckboxFieldPermissionFeatures) ||
            //   this.props.disabled
            // }
            onValueChanged={(val, isSelected) => {
              this.props.onValueChanged(isSelected);
            }}
            containerStyle={{marginBottom: 0 }}
          />
        </div>
        <div className="o-data-list__row__item">
          {drug ? drug.get('name') : UNICODE.EMDASH}
        </div>
        <div className="o-data-list__row__item">
          {this.props.prescription.getDosage() || UNICODE.EMDASH}
        </div>
        <div className="o-data-list__row__item">
          {Array.isArray(prescribedDuration) && prescribedDuration.length > 0
            ? pluralizeWord(prescribedDuration[1], prescribedDuration[0]) : UNICODE.EMDASH}
        </div>
        <div className="o-data-list__row__item">
          {this.props.prescription.get('reason', UNICODE.EMDASH, false)}
        </div>
        <div className="o-data-list__row__item">
          {this.props.prescription.get('notes', UNICODE.EMDASH, false)}
        </div>
        <div className="o-data-list__row__item">{this.getPriceAndQuantity(drug)}</div>
        <div className="o-data-list__row__item">
          {convertNumberToPrice(this.props.prescription.getTotalPrice()) || 0}
        </div>
        {
          !this.props.config.getIn(['inventory', 'hideInventoryWarnings'], false) &&
          this.props.showLowStockWarning &&
          <StockWarning
            className="o-data-list__row__item o-data-list__row__item--small u-font-color--red"
            count={this.props.inventoryCount.getIn([this.props.prescription.get('drug_id'), 'skuStockRemaining'], 0)}
            threshold={getLowInventoryThreshold(drug)}
          />
        }
      </div>
    );
  }
}

export default PrescriptionListItem;
