import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { List, RecordInstance } from 'immutable';

import glamorous from 'glamorous';
import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import Table from './../table/table';
import { renderDate } from './../../utils/tables';
import { sortByDate } from './../../utils/comparators';
import { UNICODE, DATE_FORMAT_TO_SAVE } from './../../constants';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import { isBatchDataNotReady } from './../../utils/inventory';
import FormError from './../formError';
import SavePrompt from './../prompts/savePrompt';
import SupplyItemModel from './../../models/supplyItemModel';
import Button from './../buttons/button';
import Header from './../header/header';

import type DrugModel from './../../models/drugModel';
import type { SaveModel, MapValue, Config, CountPerSKUAndBatch, BatchStockType, Row } from './../../types';

type mappedSupplyItemValue = {
  index: number,
  supplyItem: SupplyItemModel,
}

type sortedMappedSupplyItems = List<mappedSupplyItemValue>

type Props = {
  drug: DrugModel,
  supplyItems: List<SupplyItemModel>,
  saveModel: SaveModel,
  isFetching: boolean,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  fromAddSupplyForm?: boolean,
  isModalVisible?: boolean,
  config: Config,
  onClose: (isFromClose?: boolean) => void,
};

const TableContainer = glamorous.div({
  zIndex: 0,
  position: 'relative',
});

/**
 * Renders a component showing the current dispensing batch and all the batch available for dispensation.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
export default function DispensingBatch(props: Props) {
  /**
   * Returns the columns to show for the tables in the component
   * @param {boolean} forCurrent true if it is for currently dispensing table
   * @returns {Array<{ value: MapValue }>} The rendered component
  */
  const getColumns = (forCurrent: boolean): Array<{ [string]: MapValue }> => [
    { accessor: 'date', Header: translate('date'), Cell: renderDate, sortMethod: sortByDate, filterable: true },
    { accessor: 'batch_id', Header: translate('batch_id'), filterable: true },
    { accessor: 'received_qty', Header: translate('received_qty') },
    { accessor: 'remaining_qty', Header: translate('remaining_qty') },
    { accessor: 'dispensation_unit', Header: translate('dispensation_unit') },
    { accessor: 'cost_of_goods', Header: translate('cost_of_goods') },
    { accessor: 'unit_cost', Header: translate('unit_cost') },
    { accessor: 'expiry_dates', Header: translate('expiry_dates'), filterable: true },
    { accessor: 'manufacturer', Header: translate('manufacturer'), filterable: true },
    { accessor: 'notes', Header: translate('notes') },
    { accessor: 'recorded_date', Header: translate('recorded_date'), Cell: renderDate, sortMethod: sortByDate, filterable: true },
  ].concat(forCurrent ? [] : [{ accessor: 'action', Header: '' }]);

  /**
   * Get the sorted supplyItems of the drug.
   * @returns {sortedMappedSupplyItems} Empty List if drug have no supplyItems else return List of sorted and mapped supplyItems on basis of batch index.
   */
  const getSortedSupplyItemsOfDrug = (): sortedMappedSupplyItems => {
    const batches = props.inventoryCount.getIn([props.drug.get('_id'), 'batches']);
    return batches
      ? props.supplyItems
        .reduce(
          (
            mappedSupplyItems: sortedMappedSupplyItems,
            supplyItem: SupplyItemModel,
          ) => {
            const batch = batches.get(supplyItem.get('_id'));
            return supplyItem.get('sku_id') === props.drug.get('_id') &&
                batch &&
                batch.get('batchStockRemaining') > 0
              ? mappedSupplyItems.push({
                index: batch.get('index'),
                supplyItem,
              })
              : mappedSupplyItems;
          },
          List(),
        )
        .sortBy((obj: mappedSupplyItemValue) => obj.index)
      : List();
  };

  const sortedSupplyItems = useMemo(() => getSortedSupplyItemsOfDrug(), [props.supplyItems, props.inventoryCount.getIn([props.drug.get('_id'), 'batches'])]);

  const [isModalVisible, setModalVisible] = useState(false);
  const [isChangesMade, setChangesMade] = useState(false);
  const [isSaving, setSavingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [visibleAvailableBatchesData, setVisibleAvailableBatchesData] = useState(List([]));
  const [availableBatchesPageIndex, setAvailableBatchesPageIndex] = useState(0);
  const [availableBatchesPageSize, setAvailableBatchesPageSize] = useState(20);

  /**
   * Return the default Dispensing batch for Drug.
   * @return {SupplyItemModel} - Saved Batch
   */
  const getDefaultDispensingBatch = (): SupplyItemModel | null => {
    const { drug } = props;
    const currentDispensingBatch = drug.get('default_dispensing_batch');
    const dispensingBatchCount = props.inventoryCount
      .getIn([drug.get('_id'), 'batches', currentDispensingBatch, 'batchStockRemaining'], 0);
    if (currentDispensingBatch && dispensingBatchCount > 0) {
      return sortedSupplyItems.find(s => s.supplyItem.get('_id') === currentDispensingBatch).supplyItem;
    }
    const firstBatch = sortedSupplyItems.first();
    return firstBatch ? firstBatch.supplyItem : null;
  };

  /**
   * React hook runs both after the first render and after every update
   */
  useEffect(() => {
    setSelectedBatch(getDefaultDispensingBatch());
  }, []);

  /**
   * React hook act as a listener on props inventoryCount change
   */
  useEffect(() => {
    setSelectedBatch(getDefaultDispensingBatch());
  }, [props.inventoryCount]);

  /**
   * Renders the action button component.
   * @param {SupplyItemModel} supplyItem The supplyItemModel
   * @return {string} - HTML markup for the component
   */
  const getActionButton = (supplyItem: SupplyItemModel) => (
    <Button
      type="button"
      className="o-button o-button--small"
      onClick={() => {
        setChangesMade(true);
        setErrorMessage(null);
        setSelectedBatch(supplyItem);
      }}
      dataPublic
    >
      {translate('dispense_from')}
    </Button>
  );

  /**
   * prepares single row data for given batch data.
   * @param {SupplyItemModel} supplyItem supplyItem to get batch data from
   * @param {DrugModel} drug sku data
   * @param {RecordInstance} batch batch data to get remaining stock count from
   * @returns {Array<Row>}
   */
  const getRow = (
    supplyItem: SupplyItemModel,
    drug: DrugModel,
    batch: RecordInstance<BatchStockType> | null | undefined,
  ): Row => ({
    sku_id: supplyItem.get('sku_id'),
    supply_item_id: supplyItem.get('_id'),
    date: supplyItem.get('date') ? moment(supplyItem.get('date'), DATE_FORMAT_TO_SAVE).valueOf() : UNICODE.EMDASH,
    batch_id: supplyItem.get('batch_id', UNICODE.EMDASH),
    received_qty: supplyItem.get('quantity', UNICODE.EMDASH),
    remaining_qty: (batch && batch.get('batchStockRemaining')) || UNICODE.EMDASH,
    dispensation_unit: drug.get('dispensation_unit', UNICODE.EMDASH),
    cost_of_goods: supplyItem.get('total_price', UNICODE.EMDASH),
    unit_cost: Number(supplyItem.get('total_price', 0)) / Number(supplyItem.get('quantity', 0)) || UNICODE.EMDASH,
    expiry_dates: supplyItem.get('expiry_date', UNICODE.EMDASH),
    notes: supplyItem.get('notes', UNICODE.EMDASH),
    recorded_date: supplyItem.getCreatedTime(),
    action: getActionButton(supplyItem),
    manufacturer: drug.get('manufacturer') || UNICODE.EMDASH,
  });

  /**
   * prepares rows data for the available batch table.
   * @returns {Array<Row>}
   */
  const getCurrentlyDispensingBatchDataRow = (): Array<Row> => {
    const { drug } = props;
    const supplyItem = selectedBatch;
    if (supplyItem) {
      const batch = props.inventoryCount.getIn([supplyItem.get('sku_id'), 'batches', supplyItem.get('_id')]);
      return [getRow(supplyItem, drug, batch)];
    }
    return [];
  };

  /**
   * returns the text label for the current dispensing order from config
   * @returns {string}
   */
  const getDispensingOrderText = (): string => {
    const dispensingOrder = props.config.getIn(['dispensation', 'dispensing_order'], 'expiry_date');
    return dispensingOrder === 'expiry_date' ? 'Expiry Date' : 'Supply Date';
  };

  /**
   * prepares rows data for the available batch table.
   * @returns {Array<Row>}
   */
  const getAvailableBatchDataRows = (): Array<Row> => {
    const { drug } = props;
    return sortedSupplyItems.reduce((rows, supplyItemValue: mappedSupplyItemValue) => {
      const { supplyItem } = supplyItemValue;
      if (supplyItem.get('sku_id') !== drug.get('_id') || supplyItem.get('quantity') <= 0) {
        return rows;
      }
      if (selectedBatch && supplyItem.get('_id') === selectedBatch.get('_id')) {
        return rows;
      }
      const batch = props.inventoryCount.getIn([supplyItem.get('sku_id'), 'batches', supplyItem.get('_id')]);
      if (batch && batch.get('batchStockRemaining', 0) > 0) {
        return rows.push(getRow(supplyItem, drug, batch));
      }
      return rows;
    }, List()).toArray();
  };

  /**
   * Handles saving preferred dispensing batch. This will update the drug doc
   * @returns {void}
   */
  const onSaveClicked = () => {
    setSavingState(true);
    setErrorMessage(null);
    const { drug, saveModel } = props;
    const drugModel = drug.set({
      default_dispensing_batch: selectedBatch && selectedBatch.get('_id'),
    });
    return saveModel(drugModel).then(() => {
      setChangesMade(false);
      setSavingState(false);
      setModalVisible(false);
      props.onClose(false);
      return true;
    });
  };
  return (
    <StatelessModal
      id="select-batch-to-dispense-btn"
      buttonLabel={translate('select_batch_to_dispense')}
      buttonClass="o-button o-button--small u-margin-right--half-ws"
      title={translate('select_batch_to_dispense')}
      setVisible={(isVisible: boolean) => {
        if (isModalVisible && isChangesMade && !errorMessage) {
          setErrorMessage(translate('you_have_unsaved_changes'));
        } else {
          setModalVisible(isVisible);
        }
      }}
      visible={isModalVisible || Boolean(props.isModalVisible)}
      onClose={() => {
        if (!isChangesMade || errorMessage) {
          setSelectedBatch(getDefaultDispensingBatch());
          setChangesMade(false);
          setErrorMessage(null);
          props.onClose(true);
        }
      }}
      noButton={props.fromAddSupplyForm}
      explicitCloseOnly
      large
      dataPublicHeader
    >
      <SavePrompt when={isChangesMade} onSaveClicked={onSaveClicked} />
      <ContentTransition className="o-scrollable-container" id="supply-form">
        <div>
          {
            errorMessage &&
            <div className="u-margin--standard">
              <FormError containerElementID="add-to-inventory-form">
                {errorMessage}
              </FormError>
            </div>
          }
          {
            props.fromAddSupplyForm &&
            <TableContainer>
              <div className="o-card o-card--grey1">
                <Header className="o-card__header o-card__header--dark" dataPublic>
                  <h1 className="o-card__title o-card__title--dark">{translate('note')}</h1>
                </Header>
                <div className="u-margin--standard _u-padding-bottom--1ws">
                  <p>
                    { translate('batches_added_have_earlier_x', { x: getDispensingOrderText() }) }
                  </p>
                  <br />
                  <p>
                    { translate('review_available_batches') }
                  </p>
                </div>
              </div>
            </TableContainer>
          }
          <TableContainer>
            <div className="o-card">
              <Header className="o-card__header">
                <h1 className="o-card__title">{ translate('currently_dispensing_batch_for', { sku: props.drug.get('name') }) }</h1>
              </Header>
              <Table
                columns={getColumns(true)}
                data={getCurrentlyDispensingBatchDataRow()}
                noDataText={translate('no_currently_dispatching_batch')}
                defaultSorted={[{ id: 'date', desc: true }]}
              />
            </div>
          </TableContainer>
          <TableContainer>
            <div className="o-card">
              <Header className="o-card__header">
                <h1 className="o-card__title">{ translate('other_available_batches_in', { sku: props.drug.get('name') }) }</h1>
              </Header>
              <Table
                columns={getColumns(false)}
                data={getAvailableBatchDataRows() || []}
                loading={props.isFetching || isBatchDataNotReady(
                  visibleAvailableBatchesData,
                  props.inventoryCount,
                  props.inventoryCountSyncStatus.last(),
                )}
                noDataText={translate('no_available_batch_found_for_the_sku')}
                filteredSortedDataHandler={filteredData => setVisibleAvailableBatchesData(
                  List(filteredData.slice(
                    availableBatchesPageSize * availableBatchesPageIndex,
                    availableBatchesPageSize * (availableBatchesPageIndex + 1),
                  )),
                )}
                initialDataHandler={initialData => setVisibleAvailableBatchesData(
                  List(initialData.slice(
                    availableBatchesPageSize * availableBatchesPageIndex,
                    availableBatchesPageSize * (availableBatchesPageIndex + 1),
                  )),
                )}
                onPageChange={(pageIndex: number) => {
                  setAvailableBatchesPageIndex(pageIndex);
                }}
                onPageSizeChange={(pageSize: number, pageIndex: number) => {
                  setAvailableBatchesPageIndex(pageIndex);
                  setAvailableBatchesPageSize(pageSize);
                }}
                defaultSorted={[]}
              />
            </div>
          </TableContainer>
        </div>
      </ContentTransition>
      <ModalFooter>
        <SaveButton
          dataPublic
          onClick={onSaveClicked}
          isSaving={isSaving}
          label={translate('save')}
          className="o-button--small u-margin-right--half-ws"
          disabled={!isChangesMade}
        />
      </ModalFooter>
    </StatelessModal>
  );
}
