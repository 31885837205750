import { List } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setCurrentDataViews, setCurrentDataViewsError, setCurrentDataViewsModels, setIsFetching } from '../actions';
import AsyncFetch from '../components/asyncFetch';
import DataExport from '../components/dataExport/dataExport';
import { getExportDocsDataView } from '../dataViews';
import ExportModel from '../models/exportModel';
import APIError from '../utils/apiError';
import { sortByNumber } from '../utils/comparators';
import { saveFactory, saveModelsFactory } from '../utils/redux';

import type { DataView, State } from './../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ currentDataViewsModels, isFetching, currentDataViews }: State) => {
  const exportDocs = currentDataViewsModels.filter(m => m.get('type') === 'export')
    .sort((a, b) => sortByNumber(b.getCreatedTime(), a.getCreatedTime()));
  return {
    containerDataViews: getExportDocsDataView(),
    currentDataViews,
    exportDocs,
    latestDocCreationTime: exportDocs.find(e => e.get('status') === 'completed')?.getCreatedTime(),
    isFetching,
    exportsRemaining: currentDataViewsModels.find(m => m.get('type') === 'exports_remaining'),
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<ExportModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
});

const DateExportContainer = connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(DataExport));

export default (DateExportContainer);
