import React from 'react';
import moment from 'moment';
import glamorous from 'glamorous';

import translate from './../../utils/i18n';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';
import SupplyInvoicesTable from './supplyInvoicesTable';
import ContentTransition from './../contentTransition';
import { getSupplierInvoices } from './../../utils/api';
import { wsUnit, scaledH2, labelFontSize, colours } from './../../utils/css';
import { prettifyDateTime } from './../../utils/time';
import { UNICODE } from './../../constants';
import { fixedDecimal } from './../../utils/utils';
import type SupplierModel from './../../models/supplierModel';
import { debugPrint } from '../../utils/logging';

type Props = {
  supplier: SupplierModel,
}

/* eslint-disable camelcase */
export type SupplierInvoiceRow = {
  transaction_date?: string,
  amount?: number,
  document_number?: string,
  type?: string,
  currency?: string,
}

type SupplierBreakdown = {
  supplier_name?: string,
  credit_limit?: number,
  credit_availability?: number,
  currency?: string,
  rows?: Array<SupplierInvoiceRow>,
  customer_id?: string,
  customer_name?: string,
  credit_exposure?: number,
  total_outstanding?: number,
}

type State = {
  supplier_breakdown: SupplierBreakdown,
  loading_failed: boolean,
};
/* eslint-enable camelcase */

const HeaderContent = glamorous.div({
  display: 'flex',
});

const HeaderRightContent = glamorous.div({
  display: 'flex',
  margin: `calc(1.5 * ${wsUnit}) calc(1.5 * ${wsUnit}) ${wsUnit}`,
  fontSize: `calc(1.15 * ${labelFontSize})`,
  justifyContent: 'flex-end',
});

const HeaderTitle = glamorous.h1({
  margin: `${wsUnit} calc(1.5 * ${wsUnit}) ${wsUnit}`,
  fontSize: `${scaledH2}`,
  width: '72.5%',
  fontFamily: 'robotolight',
  textTransform: 'uppercase',
});

const Label = glamorous.span({
  fontFamily: '\'robotomedium\'',
  textTransform: 'uppercase',
});

const Value = glamorous.span({
  marginLeft: `calc(${wsUnit} / 2)`,
});

const DetailsRow = glamorous.div({
  display: 'flex',
  marginTop: `calc(${wsUnit} / 4)`,
  marginLeft: `calc(${wsUnit} * 1.5)`,
  fontSize: `calc(1.2 * ${labelFontSize})`,
  width: '64%',
});

const ButtonsRightContent = glamorous.div({
  fontSize: `calc(1.15 * ${labelFontSize})`,
  justifyContent: 'flex-end',
});

const DetailsRowRed = glamorous.div({
  display: 'flex',
  marginTop: `calc(${wsUnit} / 4)`,
  marginLeft: `calc(${wsUnit} * 1.5)`,
  fontSize: `calc(1.2 * ${labelFontSize})`,
  width: '64%',
  color: `${colours.red}`,
});

/**
 * A Supplier form component
 * @class SupplyInvoices
 * @extends {React.Component<Props, State>}
 */
class SupplyInvoices extends React.Component<Props, State> {
  /**
   * Creates an instance of SupplyInvoices.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      supplier_breakdown: {
        supplier_name: this.props.supplier.get('name', ''),
      },
      loading_failed: false,
    };
    this.fetchSupplierInvoices(this.props.supplier);
  }

  /**
   * fetches supplier invoice breakdown from integrated api.
   * @param {SupplierModel} supplier supplier model
   * @returns {void}
   */
  fetchSupplierInvoices(supplier: SupplierModel) {
    if (supplier) {
      this.setState({
        loading_failed: false,
      });
      getSupplierInvoices(supplier.get('_id'))
        .then((response) => {
          if (response) {
            if (response.error && response.msg) {
              debugPrint(response.msg, 'error');
            } else if (response.error) { // this is either status 500 or 504
              this.setState({
                loading_failed: true,
              });
            }
            const supplierBreakdown = Object.assign(
              {},
              response,
              {
                supplier_name: supplier.get('name', ''),
              },
            );
            // some null checks
            if (supplierBreakdown.customer_name === undefined || !supplierBreakdown.customer_name) {
              supplierBreakdown.customer_name = UNICODE.EMDASH;
            }
            this.setState({
              supplier_breakdown: supplierBreakdown,
            });
          }
        });
    }
  }

  /**
   * updates page by reloading all data again. On click handler for update page button.
   * @returns {void}
   */
  updatePage() {
    this.setState({
      supplier_breakdown: {
        supplier_name: this.props.supplier.get('name', ''),
      },
    });
    this.fetchSupplierInvoices(this.props.supplier);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { currency } = this.state.supplier_breakdown;
    const creditLimit = fixedDecimal(this.state.supplier_breakdown.credit_limit) !== UNICODE.EMDASH
      && currency ?
      `${currency} ${fixedDecimal(this.state.supplier_breakdown.credit_limit)}`
      : UNICODE.EMDASH;
    const creditExposure = fixedDecimal(this.state.supplier_breakdown.credit_exposure)
      !== UNICODE.EMDASH && currency ?
      `${currency} ${fixedDecimal(this.state.supplier_breakdown.credit_exposure)}`
      : UNICODE.EMDASH;
    const creditAvailability = fixedDecimal(this.state.supplier_breakdown.credit_availability)
      !== UNICODE.EMDASH && currency ?
      `${currency} ${fixedDecimal(this.state.supplier_breakdown.credit_availability)}`
      : UNICODE.EMDASH;
    const finalOutstanding = fixedDecimal(this.state.supplier_breakdown.total_outstanding)
      !== UNICODE.EMDASH && currency ?
      `${currency} ${fixedDecimal(this.state.supplier_breakdown.total_outstanding)}`
      : UNICODE.EMDASH;
    return (
      <ContentTransition>
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          {
            (!this.state.supplier_breakdown.customer_name || this.state.loading_failed) &&
            <h1 className="o-title">{this.state.supplier_breakdown.supplier_name}</h1>
          }
          {this.state.supplier_breakdown.customer_name && !this.state.loading_failed &&
          <div>
            <HeaderContent>
              <HeaderTitle>{this.state.supplier_breakdown.supplier_name}</HeaderTitle>
              <HeaderRightContent>
                <p>{translate('last_updated')}<span>&nbsp;</span></p>
                <p className="u-strong">{prettifyDateTime(moment().valueOf())}</p>
              </HeaderRightContent>
            </HeaderContent>
            <DetailsRow>
              <Label>{translate('customer_no')}:</Label>
              <Value>
                {
                  this.state.supplier_breakdown.customer_id ?
                    this.state.supplier_breakdown.customer_id : UNICODE.EMDASH
                }
              </Value>
            </DetailsRow>
            <HeaderContent>
              <DetailsRow>
                <Label>{translate('customer_name')}:</Label>
                <Value>
                  {
                    this.state.supplier_breakdown.customer_name ?
                      this.state.supplier_breakdown.customer_name : UNICODE.EMDASH
                  }
                </Value>
              </DetailsRow>
              <ButtonsRightContent>
                <SaveButton
                  dataPublic
                  className="o-button--small u-margin-right--1ws"
                  label={translate('download_statement_of_accounts')}
                  disabled
                  onClick={() => false}
                />
                <SaveButton
                  dataPublic
                  className="o-button--small"
                  label={translate('refresh')}
                  disabled={!this.state.supplier_breakdown.customer_name}
                  onClick={() => this.updatePage()}
                />
              </ButtonsRightContent>
            </HeaderContent>
            <br />
            <DetailsRow>
              <Label>{translate('credit_limit')}:</Label>
              <Value>
                {creditLimit}
              </Value>
            </DetailsRow>
            <DetailsRow>
              <Label>{translate('credit_exposure')}:</Label>
              <Value>
                {creditExposure}
              </Value>
            </DetailsRow>
            <DetailsRow>
              <Label>{translate('credit_availability')}:</Label>
              <Value>
                {creditAvailability}
              </Value>
            </DetailsRow>
            <DetailsRowRed>
              <Label>{translate('total_outstanding')}:</Label>
              <Value>
                {finalOutstanding}
              </Value>
            </DetailsRowRed>
          </div>
          }
          {this.state.supplier_breakdown.customer_name && this.state.loading_failed &&
          <div>
            <HeaderContent>
              <DetailsRow>
                <Label />
                <Value />
              </DetailsRow>
              <ButtonsRightContent>
                <SaveButton
                  dataPublic
                  className="o-button--small u-margin-right--1ws"
                  label={translate('download_statement_of_accounts')}
                  disabled
                  onClick={() => false}
                />
                <SaveButton
                  dataPublic
                  className="o-button--small"
                  label={translate('refresh')}
                  disabled={!this.state.supplier_breakdown.customer_name}
                  onClick={() => this.updatePage()}
                />
              </ButtonsRightContent>
            </HeaderContent>
          </div>
          }
          <div className="o-card u-margin-bottom--4ws">
            {
              this.state.loading_failed &&
              <FormError>
                {`${translate('problem_loading_supplier_details_for')} ${this.state.supplier_breakdown.supplier_name !== undefined ? this.state.supplier_breakdown.supplier_name : ''}`}
              </FormError>
            }
            {
              !this.state.loading_failed &&
              <h2 data-public className="o-card__title">{translate('open_items')}</h2>
            }
            {
              !this.state.loading_failed &&
              <SupplyInvoicesTable
                rows={this.state.supplier_breakdown.rows}
                currency={this.state.supplier_breakdown.currency}
              />
            }
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default SupplyInvoices;
