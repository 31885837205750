import * as React from 'react';
import { Link } from 'react-router-dom';

import type { HTMLStyle } from './../../types';

type Props = {
  className?: string,
  label?: string,
  backLink?: string,
  children?: React.Node,
  isOffline?: boolean,
  dataPublic?: boolean,
  style?: HTMLStyle,
};

/**
 * A full page length header to be used when the sidebar is hidden. It may take a set of children
 * to be displayed (e.g. buttons).
 * @class Header
 * @extends {React.Component<Props, State>}
 */
class Header extends React.PureComponent<Props> {
  props: Props;

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const className = this.props.className ? this.props.className : this.props.isOffline ? 'c-top-bar c-top-bar--offline' : 'c-top-bar';
    return (
      <header className={className} data-public={this.props.dataPublic} style={this.props.style}>
        {
          this.props.backLink &&
          <Link className="c-top-bar__back" to={this.props.backLink}>
            <i className="fa fa-chevron-left" />
          </Link>
        }
        {this.props.label && <h1 className="c-top-bar__title">{this.props.label}</h1>}
        {this.props.children}
      </header>
    );
  }
}

export default Header;
