import React from 'react';
import { List, Map } from 'immutable';
import type { SortingRule } from 'react-table';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import { prettifyDateTime } from './../../utils/time';
import { getRequestUnreadFlag } from './../../utils/appointment';
import getPanelNameForPatient from './../../utils/coveragePayors';
import Header from './../header/header';
import { fetchPatientsByID } from './../../utils/api';
import Button from './../buttons/button';
import TableCollapseIcon from './../icons/tableCollapseIcon';
import flagTableHOC from '../hoc/flagTable';
import UnreadIndicator from './../sidebar/unreadIndicator';
import TableColumnsSettings from '../table/tableColumnsSettings';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import { filterWithConditions } from '../../utils/tableFilter';
import { FilteringRule } from './tableFilter';
import { getAppointmentRequestTableColumns } from './columns';

import type AppointmentModel from './../../models/appointmentModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type PatientModel from '../../models/patientModel';
import type { Config, User, SaveModels, SaveModel, CustomColumn } from './../../types';

type Props = {
  config: Config,
  appointments: List<AppointmentModel>,
  patients: List<PatientStubModel | PatientModel>,
  salesItems: List<SalesItemModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  user: User,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
  updateModelsInState: (models: Array<PatientModel | AppointmentModel>) => void,
  showAppointmentRequested: (model: AppointmentModel) => void,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  updateConfig: (config: Config) => void,
  tableProps: {
    sorted: SortingRule[],
    filteringRule: FilteringRule,
  }
};


type State = {
  isLoading: boolean,
  isTableCollapsed: boolean,
  columns: Array<CustomColumn>,
}

const RequestTable = flagTableHOC(Table);

/**
 * A table containing all of today's appointment requested
 * @class AppointmentRequestsTable
 * @extends {React.Component<Props, State>}
 */
class AppointmentRequestsTable extends React.Component<Props, State> {
  /**
   * Creates an instance of ScheduledConsultsTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      isTableCollapsed: false,
      columns: transformColumnKeys(getAppointmentRequestTableColumns(props.config)),
    };
  }

  /**
   * Returns patient ID for patient model which is not in store and needs to be fetch.
   * @returns {List<string>}
   */
  getPatientIdsForFetch() {
    const patitentIDs = this.props.patients.map(p => p.get('_id'));
    return this.props.appointments.reduce((ids, appointment) => {
      if (!patitentIDs.includes(appointment.get('patient_id'))) {
        return ids.push(appointment.get('patient_id'));
      }
      return ids;
    }, List());
  }

  /**
   * Returns patients model and update in store
   * @param {List<string>} patientIDs ids
   * @returns {Promise<PatientModel>}
   */
  fetchPatients(patientIDs: List<string>) {
    return fetchPatientsByID(patientIDs)
      .then(models => this.props.updateModelsInState(models));
  }

  /**
   * calls on component update.
   * @returns {List<string>}
   */
  conponentDidUpdate() {
    const patientIDs = this.getPatientIdsForFetch();
    if (patientIDs.size) {
      this.setState({
        isLoading: true,
      });
      this.fetchPatients(patientIDs)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  /**
   * calls after component mount
   * @returns {void}
   */
  componentDidMount() {
    const patientIDs = this.getPatientIdsForFetch();
    if (patientIDs.size) {
      this.setState({
        isLoading: true,
      });
      this.fetchPatients(patientIDs)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
      return;
    }
    this.setState({
      isLoading: false,
    });
  }

  /**
   * Returns an object with appropriate fields for a Table row populated from an AppointmentModel.
   * @param {EncounterModel} appointment The appointment the button is for.
   * @param {number} rowIndex A list of all Practitioners
   * @returns {object} A table row object.
   */
  getTableRow(appointment: AppointmentModel) {
    const patient = this.props.patients.find(p => p.get('_id') === appointment.get('patient_id'));
    const flag = getRequestUnreadFlag(appointment);
    return {
      _id: appointment.get('_id'),
      flag: flag.active,
      flagData: { color: flag.color },
      tooltipData: flag.active && flag.tooltips,
      scheduled_time: prettifyDateTime(appointment.get('start_timestamp'), true),
      encounter_flow: appointment.getEncounterType(this.props.salesItems, this.props.encounterFlowMap),
      name: patient ? patient.get('patient_name', UNICODE.EMDASH) : translate('loading...'),
      ic: patient ? patient.get('ic', UNICODE.EMDASH, false) : translate('loading...'),
      case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : translate('loading...'),
      sex: patient ? patient.get('sex', UNICODE.EMDASH, false) : translate('loading...'),
      panel_or_cash: patient ? patient.getPaymentType() : translate('loading...'),
      panel_name: getPanelNameForPatient(patient, this.props.coveragePayors),
      doctor: appointment.get('practitioner_id') ? appointment.getDoctorName(this.props.practitioners) : translate('unassigned'),
      action: (
        <Button
          className="o-button o-button--small"
          onClick={(event) => {
            event.stopPropagation();
            // intitaly form modal setVisible will not be called on click of view request
            this.props.updateModelsInState([appointment.setUnreadCheck(false)]);
            this.props.showAppointmentRequested(appointment);
          }}
          disabled={this.state.isLoading}
          dataPublic
        >
          {translate('view_request')}
        </Button>
      ),
      link: `/patient/${appointment.get('patient_id')}`,
    };
  }

  /**
   * Returns table data
   * @param {List<AppointmentModel>} appointments appointment models
   * @returns {Array<Row>}
   */
  getTableData = (appointments: List<AppointmentModel> = List()) => {
    const tableData = appointments.map(appointment => this.getTableRow(appointment)).toArray();
    return {
      tableData,
      filteredTableData: filterWithConditions(tableData, this.props.tableProps.filteringRule),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { appointments, config } = this.props;
    const { tableData, filteredTableData } = this.getTableData(appointments);
    const data = this.state.isLoading ? [] : tableData;
    const unreadCount = data.filter(d => d.flag).length;
    const { isTableCollapsed } = this.state;
    const originalColumns = getAppointmentRequestTableColumns(config);
    return (
      <section className="o-card">
        <Header className="o-card__header">
          <h1 className="o-card__title o-card__title--no-right-padding u-margin-right--half-ws" data-public>{translate('appointment_requests')}</h1>
          <span>-&nbsp;&nbsp;{data.length}</span>
          <span className="o-card__header__right-aligned-text">
            <UnreadIndicator unreadCount={unreadCount} iconStyle={{ backgroundColor: 'red', fontWeight: 'bold' }} />
          </span>
          <span className="u-margin-right--1ws">
            <TableColumnsSettings
              config={config}
              configFieldName="appointment_requests"
              originalColumns={List(transformColumnKeys(originalColumns))}
              columns={this.state.columns}
              onUpdateColumns={columns => this.setState({ columns })}
              updateConfig={this.props.updateConfig}
              isCompact
            />
            <TableCollapseIcon
              id="appointment_requests"
              isTableCollapsed={isTableCollapsed}
              onClick={() =>
                this.setState({ isTableCollapsed: !isTableCollapsed })
              }
            />
          </span>
        </Header>
        {!isTableCollapsed &&
          <RequestTable
            columns={transformFilteredColumns(originalColumns, this.state.columns)}
            data={filteredTableData}
            defaultSorted={[{ id: 'time', desc: false }]}
            noDataText={this.state.isLoading
              ? translate('loading')
              : translate(
                this.props.tableProps.filteringRule?.filterRules?.length
                  ? 'no_filter_result'
                  : 'no_appointments_requested',
              )}
            sorted={this.props.tableProps.sorted}
          />
        }
      </section>
    );
  }
}

export default AppointmentRequestsTable;
