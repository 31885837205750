import React from 'react';
import type { List } from 'immutable';

import Select from './../inputs/select';
import translate from './../../utils/i18n';

import type { SaveModel } from './../../types';
import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import type CategoryModel from './../../models/categoryModel';

type Props = {
  casenote: CaseNoteFileModel,
  categories: List<CategoryModel>,
  saveModel: SaveModel,
};

type State = {
  isSaving: boolean,
};


/**
 * A component that display a select dropdown to change the category of the current casenote.
 * @class CasenoteCategorySelector
 * @extends {React.Component<Props, State>}
 */
class CasenoteCategorySelector extends React.Component<Props, State> {
  /**
   * Creates an instance of CasenoteCategorySelector.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isSaving: false };
  }

  /**
   * Handler for the change of the category.
   * @param {string} categoryID The category ID.
   * @returns {void}
   */
  onValueChanged(categoryID: string) {
    if (categoryID !== this.props.casenote.get('category')) {
      this.setState({ isSaving: true });
      this.props.saveModel(this.props.casenote.set('category', categoryID))
        .then(() => this.setState({ isSaving: false }));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="c-casenote-editor__category-selector">
        <Select
          id="category-selector"
          disabled={this.state.isSaving}
          label={translate('category')}
          options={
            this.props.categories
              .map(c => ({ value: c.get('_id'), label: c.get('name') }))
              .toArray()
          }
          clearable={false}
          onValueChanged={value => this.onValueChanged(value)}
          style={{ width: '400px', color: 'white' }}
          value={this.props.casenote.get('category')}
        />
      </div>
    );
  }
}

export default CasenoteCategorySelector;
