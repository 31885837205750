import moment from 'moment';

import BaseModel from './baseModel';
import { getDateFormat } from './../utils/time';
import { UNICODE } from './../constants';

export type Qualification = {
  issuer: string,
  year_received: string,
  type: string,
};

export type PractitionerRole = {
  speciality: Array<string>,
};

export type RegistrationNumber = {
  mmc: string,
};

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'practitioner',
  name: string,
  internal_clinic_id?: string,
  gender?: 'male' | 'female',
  dob?: string,
  ethnicity?: Array<string>,
  practitioner_role?: PractitionerRole,
  tel?: Array<string>,
  email?: Array<string>,
  qualifications?: Array<Qualification>,
  registration_number?: RegistrationNumber,
  user_id?: string,
};

const FIELDS = [
  'name', 'internal_clinic_id', 'gender', 'dob', 'ethnicity', 'practitioner_role', 'qualifications',
  'email', 'tel', 'ic', 'registration_number',
];

/**
   * PractitionerModel
   *
   * @namespace PractitionerModel
   */
class PractitionerModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'practitioner';
  }

  /**
   * Sets the practitioner visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered PractitionerModel.
   */
  setVisible(isVisible: boolean): PractitionerModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Returns a list of all fields for this document that aren't empty (excluding metadata fields
   * like id or edited_by).
   * @returns {Array<string>}
   */
  getNonEmptyFields() {
    return FIELDS.filter(field => this.has(field, false));
  }

  /**
   * Get Doctor's name
   * @returns {string}
   */
  getName(): string {
    return this.get('name') ? `Dr ${this.get('name')}` : 'Doctor';
  }

  /**
   * Gets a readable version of the doctor's date of birth.
   * @param {string | void} format Date format
   * @return {string} - DOB or dash if not available.
   */
  getDOB(format: string | void = getDateFormat()) {
    const dob = this.get('dob');
    if (dob === undefined || dob === null || dob.length === 0) {
      return UNICODE.EMDASH;
    }
    return typeof dob === 'string' ? moment(dob).format(format) : (moment.utc(dob)).format(format);
  }

  /**
   * Returns the ethnicity/s of a doctor as a comma seperated string or an em dash if not
   * available.
   * @returns  {string} - Ethnicities or dash if not available.
   */
  getEthnicity(): string {
    if (this.has('ethnicity') && this.get('ethnicity').length) {
      if (Array.isArray(this.get('ethnicity'))) {
        return this.get('ethnicity').join(', ');
      }
      return this.get('ethnicity');
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the telephone/s of a doctor as a comma seperated string or an em dash if not
   * available.
   * @returns  {string} - Telephone numbers or dash if not available.
   */
  getTelephone(): string {
    if (this.has('tel') && this.get('tel').length) {
      if (Array.isArray(this.get('tel'))) {
        return this.get('tel').join(', ');
      }
      return this.get('tel');
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the email/s of a doctor as a comma seperated string or an em dash if not
   * available.
   * @returns  {string} - emails or dash if not available.
   */
  getEmail(): string {
    if (this.has('email') && this.get('email').length) {
      if (Array.isArray(this.get('email'))) {
        return this.get('email').join(', ');
      }
      return this.get('email');
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the speciality/s of a doctor as a comma seperated string or an em dash if not
   * available.
   * @returns  {string} - Specialities or dash if not available.
   */
  getSpeciality(): string {
    if (this.has('practitioner_role') && this.get('practitioner_role').speciality.length) {
      if (Array.isArray(this.get('practitioner_role').speciality)) {
        return this.get('practitioner_role').speciality.join(', ');
      }
      return this.get('practitioner_role').speciality;
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the MMC of the doctor
   * @returns {string} - MMC or dash if not available
   */
  getMMC(): string {
    if (this.has('registration_number')) {
      return this.get('registration_number').mmc;
    }
    return UNICODE.EMDASH;
  }
}

export default PractitionerModel;
