import React from 'react';
import { List, Map } from 'immutable';
import memoize from "memoize-one";

import AddEditPrescriptions from './addEditPrescriptions';
import PrescriptionListItem from './prescriptionListItem';
import PrintButton from './../buttons/printButton';
import translate from './../../utils/i18n';
import { convertNumberToPrice } from '../../utils/utils';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Header from './../header/header';

import type DrugModel from './../../models/drugModel';
import type EncounterModel from './../../models/encounterModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DosingRegimenModel from './../../models/dosingRegimenModel';
import type { Config, SaveModel, SaveModels, InventoryCount, User, MapValue } from './../../types';
import InventoryMapModel from './../../models/inventoryMapModel';
import AllergyModel from './../../models/allergyModel';
import Button from '../buttons/button';
import { downloadATDPSFile } from './../../utils/api';
import Checkbox from '../inputs/checkbox';
import PractitionerModel from './../../models/practitionerModel';

type Props = {
  config: Config,
  klinifyConfig: Config,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  encounters: List<EncounterModel>,
  practitioners: List<PractitionerModel>,
  prescriptions: List<PrescriptionModel>,
  useTextButton: boolean,
  inventoryCount: InventoryCount,
  isOnline: boolean,
  saveModel: SaveModel,
  saveModels: SaveModels,
  showLowStockWarning: boolean,
  coveragePayorID?: string,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  isPrescriptionsModalVisible?: boolean,
  handlePrescriptionsModalVisible?: (state: boolean) => void,
  hidePrescriptionsModalButton?: boolean,
  hideTotal?: boolean,
  isFromCurrentEncounter?: boolean,
  onPrint?: (filter: (prescriptions: List<PrescriptionModel>) => List<PrescriptionModel>) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  verifiedDrugs: List<InventoryMapModel>,
  allergies: List<AllergyModel>,
  dosingRegimens: List<DosingRegimenModel>,
  patientPrescriptionHistory: List<PrescriptionModel>,
};

type State = {
  selectedPrescriptions: Map<string, boolean>
}

/**
   * A Component listing prescriptions.
   * @namespace PrescriptionList
   */
class PrescriptionList extends React.PureComponent<Props, State> {
  static defaultProps = {
    useTextButton: false,
    showLowStockWarning: false,
  };

  /**
   * Creates an instance of BillFormItem.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPrescriptions: Map({}),
    };
  }

  // eslint-disable-next-line require-jsdoc
  static getDerivedStateFromProps(props: Props, state: State) {
    if (!props.prescriptions.every(prescription => state.selectedPrescriptions.has(prescription.get('_id')))) {
      return {
        selectedPrescriptions: Map(props.prescriptions.map((prescription) => {
          if (state.selectedPrescriptions.has(prescription.get('_id'))) {
            return [prescription.get('_id'), state.selectedPrescriptions.get(prescription.get('_id'))];
          }
          const drug = props.drugs.find(d => d.get('_id') === prescription.get('drug_id'));
          if (drug?.get('atdps_integration')) {
            return [prescription.get('_id'), false];
          }
          return [prescription.get('_id'), true];
        })),
      };
    }
    return null;
  }

  hasATDPSPrints = (prescriptions: List<PrescriptionModel>, drugs: List<DrugModel>, selected: Map<string, boolean>) => {
    const drugsMap = drugs.groupBy(d => d.get('_id'));
    return prescriptions.some(p => {
      return (drugsMap.get(p.get('drug_id'))?.get(0)?.get('atdps_integration') && selected.get(p.get('_id'))) || false;
    });
  };

  handleATDPSDownloadClick = async () => {
    const drugsMap = this.props.drugs.groupBy(d => d.get('_id'));
    const prescriptionIds = this.props.prescriptions
      .filter(p => drugsMap.get(p.get('drug_id'))?.get(0)?.get('atdps_integration') && this.state.selectedPrescriptions.get(p.get('_id')))
      .map(p => p.get('_id'))
      .toArray();
    const response = await downloadATDPSFile(prescriptionIds);
    if (response.ok) {
      const opts = {
        suggestedName: `${response.data?.filename}`,
        types: [{
          description: 'Text file',
          accept: { 'text/plain': ['.txt'] },
        }],
      };
      const fileHandle = await window.showSaveFilePicker(opts);
      const fileStream = await fileHandle.createWritable();
      await fileStream.write(new Blob([response.data?.data || ''], { type: 'text/plain' }));
      await fileStream.close();
    }
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className="c-item-list__item__info">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{ translate('prescriptions') }</h1>

          <div className="u-flex-right u-margin-right--1ws">
            {
              this.props.prescriptions.size > 0 &&
              this.props.config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) &&
              <Button
                key="btn-atdps-print"
                className="o-text-button o-text-button--contextual"
                onClick={() => this.handleATDPSDownloadClick()}
                disabled={!this.hasATDPSPrints(this.props.prescriptions, this.props.drugs, this.state.selectedPrescriptions)}
                dataPublic
              >
                {translate('download_atdps_file')}
              </Button>
            }
            {
              this.props.prescriptions.size > 0 &&
              this.props.onPrint &&
              <PrintButton
                buttonLabel={translate('print_selected')}
                onPrint={() => {
                  if (this.props.onPrint) {
                    this.props.onPrint((prescriptions) => {
                      return prescriptions.filter(e => this.state.selectedPrescriptions.get(e.get('_id')));
                    });
                  }
                }}
                disabled={this.props.prescriptions.filter(e => this.state.selectedPrescriptions.get(e.get('_id'))).size <= 0}
              />
            }
            <PermissionWrapper
              permissionsRequired={List([createPermission(
                this.props.encounter.isCurrent() ? 'current_encounter_prescriptions' : 'past_encounters_prescriptions',
                'update',
              )])}
              user={this.props.user}
            >
              <AddEditPrescriptions
                isPrescriptionsModalVisible={this.props.isPrescriptionsModalVisible}
                handlePrescriptionsModalVisible={this.props.handlePrescriptionsModalVisible}
                hidePrescriptionsModalButton={this.props.hidePrescriptionsModalButton}
                useTextButton={this.props.useTextButton}
                prescriptions={this.props.prescriptions}
                practitioners={this.props.practitioners}
                dosingRegimens={this.props.dosingRegimens}
                encounters={this.props.encounters}
                drugs={this.props.drugs}
                config={this.props.config}
                klinifyConfig={this.props.klinifyConfig}
                encounter={this.props.encounter}
                saveModel={this.props.saveModel}
                saveModels={this.props.saveModels}
                isOnline={this.props.isOnline}
                inventoryCount={this.props.inventoryCount}
                coveragePayorID={this.props.coveragePayorID}
                coveragePayors={this.props.coveragePayors}
                user={this.props.user}
                isFromCurrentEncounter={this.props.isFromCurrentEncounter}
                updateConfigValue={this.props.updateConfigValue}
                updateConfig={this.props.updateConfig}
                verifiedDrugs={this.props.verifiedDrugs}
                allergies={this.props.allergies}
                patientPrescriptionHistory={this.props.patientPrescriptionHistory}
              />
            </PermissionWrapper>
          </div>
        </Header>
        {
          this.props.prescriptions.size > 0 &&
          <div className="o-data-list__row o-data-list__row--header">
            <div className="o-data-list__row__item">
              <Checkbox
                id="item-prescription-select-all"
                label={translate('selected')}
                value={this.props.prescriptions.every(p => this.state.selectedPrescriptions.get(p.get('_id'), false)) === false ? [] : ['selected']}
                options={[{ label: '', value: 'selected' }]}
                hideLabel
                // disabled={
                //   !this.getUpdatePermission(item, claimableCheckboxFieldPermissionFeatures) ||
                //   this.props.disabled
                // }
                onValueChanged={(val, isSelected) => {
                  this.setState({
                    selectedPrescriptions: Map(this.props.prescriptions.map(p => [p.get('_id'), isSelected])),
                  });
                }}
                containerStyle={{marginBottom: 0 }}
              />
            </div>
            <div className="o-data-list__row__item">
              {translate('name')}
            </div>
            <div className="o-data-list__row__item">
              {translate('dosage_unit_freq')}
            </div>
            <div className="o-data-list__row__item">
              {translate('duration')}
            </div>
            <div className="o-data-list__row__item">
              {translate('reason')}
            </div>
            <div className="o-data-list__row__item">
              {translate('notes')}
            </div>
            <div className="o-data-list__row__item">
              {translate('price_qty')}
            </div>
            <div className="o-data-list__row__item">
              {translate('total')}
            </div>
            {
              !this.props.config.getIn(['inventory', 'hideInventoryWarnings'], false)
              && this.props.showLowStockWarning
              && <div className="o-data-list__row__item o-data-list__row__item--small" />
            }
          </div>
        }
        { this.props.prescriptions.map(prescription =>
          <PrescriptionListItem
            key={prescription.get('_id')}
            prescription={prescription.set('selected', this.state.selectedPrescriptions.get(prescription.get('_id')) || false)}
            drugs={this.props.drugs}
            config={this.props.config}
            showLowStockWarning={this.props.showLowStockWarning}
            inventoryCount={this.props.inventoryCount}
            onValueChanged={(isSelected) => {
              this.setState({
                ...this.state,
                selectedPrescriptions: this.state.selectedPrescriptions.set(prescription.get('_id'), isSelected),
              });
            }}
          />)}
        {
          this.props.prescriptions.size > 0 && !this.props.hideTotal &&
          <div className="o-data-list__row o-data-list__row--header">
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item">
              {
               convertNumberToPrice(this.props.prescriptions
                 .map(p => p.getTotalPrice() || 0)
                 .reduce((a, b) => a + b, 0))
              }
            </div>
            {
              !this.props.config.getIn(['inventory', 'hideInventoryWarnings'], false)
              && this.props.showLowStockWarning
              && <div className="o-data-list__row__item o-data-list__row__item--small" />
            }
          </div>
        }
        {
          this.props.prescriptions.size === 0 &&
          <p className="u-padding--1ws u-flex-centre">{translate('no_medication_prescribed')}</p>
        }
      </div>
    );
  }
}
export default PrescriptionList;
