/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'react-router-dom';

import translate from '../utils/i18n';
import LoginHeader from './login/loginHeader';


/**
 * @returns {React.StatelessFunctionalComponent}
 */
const MaintenanceLandingComp = () => (
  <>
    <LoginHeader />
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', marginLeft: '10px' }}>
      <p><strong>Klinify is currently facing an issue and we are currently working hard to get our services back online. If you need assistance, please contact our support team at support@klinify.com.We apologize for the inconvenience caused.</strong></p>
    </div>
  </>
);

export default MaintenanceLandingComp;
