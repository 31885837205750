import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import BillItemCostPriceItem from './billItemCostPriceItem';
import BillItemModel from './../../models/billItemModel';
import { debugPrint } from './../../utils/logging';
import Header from './../header/header';

import type SalesItemModel from './../../models/salesItemModel';
import type DrugModel from './../../models/drugModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type { MapValue, User } from './../../types';

type Props = {
  billItems: List<BillItemModel>,
  drugs: List<DrugModel>,
  procedureTypes: List<ProcedureTypeModel>,
  onValueChanged: (itemID: string, newValues: { [key: string]: MapValue }) => void,
  salesItems: List<SalesItemModel>,
  user: User,
};

type State = {
  lastAddedItemType: string,
};

/**
 * A list of Bill items.
 * @class BillItemCostPriceList
 * @extends {React.PureComponent}
 */
class BillItemCostPriceList extends React.PureComponent<Props, State> {
  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering BillItemCostPriceList');
    const salesItems = this.props.billItems.filter(item => item.get('sales_item_id'));
    const prescriptions = this.props.billItems.filter(item => item.get('drug_id'));
    const procedureTypes = this.props.billItems.filter(item => item.get('procedure_type_id'));
    return (
      <div id="bill-sales-items">
        { salesItems.size > 0 &&
          <Header className="o-header" dataPublic>
            <h1>{translate('sales_items')}</h1>
          </Header>
        }
        {
          salesItems.map(item =>
            <BillItemCostPriceItem
              key={`billedSalesItem-${item.get('_id')}`}
              item={item}
              itemOptions={this.props.salesItems}
              onValueChanged={newValues => this.props.onValueChanged(item.get('_id'), newValues)}
              user={this.props.user}
            />)
            .slice(0)
            .reverse()
        }
        { prescriptions.size > 0 &&
          <Header className="o-header" dataPublic>
            <h1>{translate('prescriptions')}</h1>
          </Header>
        }
        {
          prescriptions.map(item =>
            <BillItemCostPriceItem
              key={`billedPrescription-${item.get('_id')}`}
              item={item}
              itemOptions={this.props.drugs}
              onValueChanged={newValues => this.props.onValueChanged(item.get('_id'), newValues)}
              user={this.props.user}
            />)
            .slice(0)
            .reverse()
        }
        { procedureTypes.size > 0 &&
          <Header className="o-header" dataPublic>
            <h1>{translate('lab_tests')}</h1>
          </Header>
        }
        {
          procedureTypes.map(item =>
            <BillItemCostPriceItem
              key={`billedProcedureType-${item.get('_id')}`}
              item={item}
              itemOptions={this.props.procedureTypes}
              onValueChanged={newValues => this.props.onValueChanged(item.get('_id'), newValues)}
              user={this.props.user}
            />)
            .slice(0)
            .reverse()
        }
      </div>
    );
  }
}
export default BillItemCostPriceList;
