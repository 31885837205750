/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable require-jsdoc */
import React, { useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CalendarEvent, CalendarRange, FilterCheckBoxes } from './appointments';


const AppointmentCalendar = ({ calendarLoading, calendarEvents, setSelectedEventToAppointment, setCalendarRange }) => {
  const onRangeChange = useCallback((range) => {
    const isArrayOfDays = Array.isArray(range);
    let eventRange: CalendarRange;
    if (isArrayOfDays) {
      eventRange = {
        start: range[0],
        end: range[range.length - 1],
      };
    } else eventRange = range;
    setCalendarRange(eventRange);
  }, []);

  const localizer = momentLocalizer(moment);
  return (<Calendar
    localizer={localizer}
    events={calendarEvents}
    eventPropGetter={(event: CalendarEvent) => {
      const backgroundColor = FilterCheckBoxes.find(f => f.value === event.subType)?.backgroundColor;
      const color = 'white';
      return { style: { backgroundColor, color } };
    }}
    onSelectEvent={(event: CalendarEvent) => {
      setSelectedEventToAppointment(event);
    }}
    startAccessor="start"
    endAccessor="end"
    style={{ height: '75vh', width: '65vw', opacity: calendarLoading ? '0.5' : '1' }}
    onRangeChange={onRangeChange}
    popup
  />);
};

export default AppointmentCalendar;
