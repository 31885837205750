const permissions = [
  {
    group: 'patient_details',
    features: [
      {
        feature: 'patient',
        access: ['read', 'create', 'update'],
      },
      {
        feature: 'patient_allergies',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'patient_metrics',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'patient_casenotes',
        access: ['read', 'create', 'update', 'delete'],
      },
    ],
  },
  {
    group: 'scheduling',
    features: [
      {
        feature: 'schedule_appointments',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'queue_patient_for_today',
        access: [
          'read', // i.e. View Queue
          'create', // i.e. Add to Queue
          'update', // i.e. Change Status
          'delete', // i.e. Cancel and void bill
        ],
      },
      {
        feature: 'e_consultation',
        access: ['read', 'create', 'update', 'delete'],
      },
    ],
  },
  {
    group: 'document_templates',
    features: [
      {
        feature: 'document_templates',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'document_templates_settings',
        access: ['read', 'create', 'update', 'delete'],
      },
    ],
  },
  {
    group: 'current_encounters',
    features: [
      {
        feature: 'current_encounter_notes',
        access: ['read', 'update'],
      },
      {
        feature: 'current_encounter_conditions',
        access: ['read', 'update'],
      },
      {
        feature: 'current_encounter_medical_certificates',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'current_encounter_time_chits',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'current_encounter_prescriptions',
        access: ['read', 'update'],
      },
      {
        feature: 'current_encounter_sales_items',
        access: ['read', 'update'],
      },
      {
        feature: 'current_encounter_view_cost_price',
        access: ['read'],
      },
      {
        feature: 'current_encounter_lab_tests',
        access: ['read', 'update'],
      },
    ],
  },
  {
    group: 'past_encounters',
    features: [
      {
        feature: 'past_encounters_notes',
        access: ['read', 'update'],
      },
      {
        feature: 'past_encounters_conditions',
        access: ['read', 'update'], // This will need to be expanded to Create/Update/Delete in the future once conditions have more metadata.
      },
      {
        feature: 'past_encounters_medical_certificates',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'past_encounters_time_chits',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'past_encounters_prescriptions',
        access: ['read', 'update'],
      },
      {
        feature: 'past_encounters_sales_items',
        access: ['read', 'update'],
      },
      {
        feature: 'past_encounters_view_cost_price',
        access: ['read'],
      },
      {
        feature: 'past_encounters_lab_tests',
        access: ['read', 'update'],
      },
    ],
  },
  {
    group: 'billing',
    features: [
      {
        feature: 'unfinalised_bill',
        access: ['read', 'update'],
      },
      {
        feature: 'unfinalised_bill_patient_details',
        access: ['read'],
      },
      {
        feature: 'finalised_bill',
        access: [
          'read', // i.e. Patient Billing history.
          'create', // i.e. Add Past Bill
          'update', // i.e. Edit a finalised bill
          'delete', // i.e. Void bill
        ],
      },
      {
        feature: 'payments',
        access: [
          'read',
          'create',
          'delete',
        ],
      },
      // Input specific permissions for sales items
      {
        feature: 'perm_add_button_sales_items',
        access: ['read', 'update'],
        section: 'sales_items',
      },
      {
        feature: 'perm_name_field_sales_items_line_item',
        access: ['read', 'update'],
        section: 'sales_items_line_item',
      },
      {
        feature: 'perm_price_field_sales_items_line_item',
        access: ['read', 'update'],
        section: 'sales_items_line_item',
      },
      {
        feature: 'perm_quantity_field_sales_items_line_item',
        access: ['read', 'update'],
        section: 'sales_items_line_item',
      },
      {
        feature: 'perm_claimable_checkbox_sales_items_line_item',
        access: ['read', 'update'],
        section: 'sales_items_line_item',
      },
      {
        feature: 'perm_x_delete_button_sales_items_line_item',
        access: ['read', 'update'],
        section: 'sales_items_line_item',
      },
      // Input specific permissions for prescriptions items
      {
        feature: 'perm_add_button_prescriptions_items',
        access: ['read', 'update'],
        section: 'prescriptions_items',
      },
      {
        feature: 'perm_name_field_prescriptions_items_line_item',
        access: ['read', 'update'],
        section: 'prescriptions_items_line_item',
      },
      {
        feature: 'perm_price_field_prescriptions_items_line_item',
        access: ['read', 'update'],
        section: 'prescriptions_items_line_item',
      },
      {
        feature: 'perm_quantity_field_prescriptions_items_line_item',
        access: ['read', 'update'],
        section: 'prescriptions_items_line_item',
      },
      {
        feature: 'perm_claimable_checkbox_prescriptions_items_line_item',
        access: ['read', 'update'],
        section: 'prescriptions_items_line_item',
      },
      {
        feature: 'perm_x_delete_button_prescriptions_items_line_item',
        access: ['read', 'update'],
        section: 'prescriptions_items_line_item',
      },
      {
        feature: 'perm_add_button_lab_tests',
        access: ['read', 'update'],
        section: 'lab_tests',
      },
      {
        feature: 'perm_name_field_lab_tests_line_item',
        access: ['read', 'update'],
        section: 'lab_tests_line_item',
      },
      {
        feature: 'perm_price_field_lab_tests_line_item',
        access: ['read', 'update'],
        section: 'lab_tests_line_item',
      },
      {
        feature: 'perm_quantity_field_lab_tests_line_item',
        access: ['read', 'update'],
        section: 'lab_tests_line_item',
      },
      {
        feature: 'perm_claimable_checkbox_lab_tests_line_item',
        access: ['read', 'update'],
        section: 'lab_tests_line_item',
      },
      {
        feature: 'perm_x_delete_button_lab_tests_line_item',
        access: ['read', 'update'],
        section: 'lab_tests_line_item',
      },
      // Permission for cost price
      {
        feature: 'perm_view_edit_cost_price_right_side_bar',
        access: ['read', 'update'],
        section: 'right_side_bar',
      },
    ],
  },
  {
    group: 'claims',
    features: [
      {
        feature: 'claim_invoice',
        access: ['read', 'update', 'create', 'delete'],
      },
      {
        feature: 'claim_invoice_payment',
        access: ['read', 'create', 'update', 'delete'],
      },
    ],
  },
  {
    group: 'reports',
    features: [
      {
        feature: 'consult_reports',
        access: ['read'],
      },
      {
        feature: 'accounts_receivable',
        access: ['read'],
      },
      {
        feature: 'operations_summary',
        access: ['read'],
      },
      {
        feature: 'supplies_added_and_transfers',
        access: ['read'],
      },
      {
        feature: 'cost_report',
        access: ['read'],
      },
      {
        feature: 'inventory_reports',
        access: ['read'],
      },
    ],
  },
  {
    group: 'inventory',
    features: [
      {
        feature: 'inventory_items',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'drug_frequencies',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'suppliers',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'supplies',
        access: ['create'],
      },
      {
        feature: 'sales_items',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'expiry_dates',
        access: ['read'],
      },
      {
        feature: 'inventory_status',
        access: [
          'read',
          'create', // i.e. Make an adjustment
        ],
      },
      {
        feature: 'inventory_mapping',
        access: [
          'read',
          'create',
        ],
      },
      {
        feature: 'drug_dispensation',
        access: ['read'],
      },
      {
        feature: 'supply_invoices',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'drug_durations',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'dosing_regimen',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'adjust_batch_quantity',
        access: ['create'],
      },
      {
        feature: 'batch_dispensing_order',
        access: ['update'],
      },
      {
        feature: 'currently_dispensing_batch',
        access: ['update'],
      },
      {
        feature: 'update_batch_details',
        access: ['update'],
      },
    ],
  },
  {
    group: 'settings',
    features: [
      {
        feature: 'doctors',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'symptom_options',
        access: ['read', 'create', 'delete'],
      },
      {
        feature: 'patient_tags_options',
        access: ['read', 'create', 'delete', 'update'],
      },
      {
        feature: 'diagnosis_options',
        access: ['read', 'create', 'delete'],
      },
      {
        feature: 'coverage_payors',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'metric_types',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'clinics',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'clinic_settings',
        access: ['update'],
      },
      {
        feature: 'panel_categories',
        access: ['read', 'create', 'delete'],
      },
      {
        feature: 'laboratories',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'lab_tests',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'specimens',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'discounts_charges',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'banks',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'payment_types',
        access: ['read', 'create', 'update', 'delete'],
      },
      {
        feature: 'flows',
        access: ['create', 'update', 'delete'],
      },
      {
        feature: 'stages',
        access: ['create', 'update', 'delete'],
      },
    ],
  },
  {
    group: 'user_management',
    features: [
      {
        feature: 'self_password',
        access: ['update'],
      },
      {
        feature: 'self_permissions',
        access: ['read'],
      },
      {
        feature: 'users',
        access: [
          'read',
          'create',
          'update', // i.e. Change password, name, user group. May need their own permissions?
          'delete',
        ],
      },
      {
        feature: 'user_groups',
        access: ['read', 'create', 'update', 'delete'],
      },
    ],
  },
  {
    group: 'patient_list',
    features: [
      {
        feature: 'patient_list',
        access: ['read'],
      },
    ],
  },
  {
    group: 'analytics_reports',
    features: [
      {
        feature: 'analytics_reports',
        access: ['read'],
      },
    ],
  },
  {
    group: 'lab_requests',
    features: [
      {
        feature: 'lab_requests',
        access: ['read', 'update'],
      },
    ],
  },
  {
    group: 'sms_campaigns',
    features: [
      {
        feature: 'sms_campaigns',
        access: ['read', 'create', 'update', 'delete'],
      },
    ],
  },
];

export default permissions;
