import React from 'react';
import { Div } from 'glamorous';
import { colours, wsUnit } from '../../utils/css';

/**
 * Renders the full width blue banner mostly used for displaying info about a feature in its page
 * @returns {React.SFC}
 */
const InfoHeader = ({ children }: { children: React.ReactNode | string }) => (
  <Div css={{ backgroundColor: colours.blueBright, color: '#fff', padding: `${wsUnit} calc(1.5 * ${wsUnit})` }}>
    {children}
  </Div>
);

export default React.memo(InfoHeader);
