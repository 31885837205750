import React from 'react';
import { List } from 'immutable';

import MedicalCertificateItem from './medicalCertificateItem';
import TimeChitItem from './timeChitItem';
import DocumentDataItem from './documentDataItem';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';

import type { Config, SaveModel, User } from './../../types';
import type TimeChitModel from './../../models/timeChitModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type DocumentDataModel from './../../models/documentDataModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  disableChanges?: boolean,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  documentData: List<DocumentDataModel>,
  config: Config,
  hideButtons?: boolean,
  showEmptyItems?: boolean, // e.g. If no MC, explicitly state no MC.
  saveModel: SaveModel,
  user: User,
  isCurrentEncounter?: boolean, // Needed to handle permissions correctly
  documentTemplates: List<DocumentTemplateModel>,
  onPrintMC?: (MedicalCertificateModel) => void,
  onPrintTC?: (TimeChitModel) => void,
};


/**
 * A List of issued items for an encounter (MC, time chit, etc).
 *
 * @namespace IssuedItemsList
 * @returns {React.Component} A list of issued items.
 */
const IssuedItemsList = ({
  medicalCertificates, disableChanges, config, timeChits, hideButtons,
  showEmptyItems, saveModel, user, isCurrentEncounter = false, documentData, documentTemplates,
  onPrintTC, onPrintMC,
}: Props) =>
  <div className="c-item-list__item__info">
    <PermissionWrapper
      permissionsRequired={List([createPermission('document_templates', 'read')])}
      user={user}
    >
      {
        documentData.map(
          dd =>
            <DocumentDataItem
              key={dd.get('_id')}
              documentData={dd}
              documentTemplates={documentTemplates}
              disableChanges={disableChanges}
              hideButtons={hideButtons}
              saveModel={saveModel}
              user={user}
            />,
        )
      }
    </PermissionWrapper>
    <PermissionWrapper
      permissionsRequired={List([createPermission(
        isCurrentEncounter ? 'current_encounter_medical_certificates' : 'past_encounters_medical_certificates',
        'read',
      )])}
      user={user}
    >
      { medicalCertificates.map(mc =>
        <MedicalCertificateItem
          key={mc.get('_id')}
          medicalCertificate={mc}
          disableChanges={disableChanges}
          config={config}
          hideButtons={hideButtons}
          saveModel={saveModel}
          user={user}
          onPrint={() => {
            if (onPrintMC) onPrintMC(mc);
          }}
          isCurrentEncounter={isCurrentEncounter}
        />)}
      {
          showEmptyItems &&
          medicalCertificates.size === 0 &&
          <MedicalCertificateItem
            disableChanges={disableChanges}
            config={config}
            hideButtons={hideButtons}
            saveModel={saveModel}
            user={user}
          />
        }
    </PermissionWrapper>
    <PermissionWrapper
      permissionsRequired={List([createPermission(
        isCurrentEncounter ? 'current_encounter_time_chits' : 'past_encounters_time_chits',
        'read',
      )])}
      user={user}
    >
      { timeChits.map(tc =>
        <TimeChitItem
          key={tc.get('_id')}
          timeChit={tc}
          disableChanges={disableChanges}
          config={config}
          hideButtons={hideButtons}
          saveModel={saveModel}
          user={user}
          onPrint={() => {
            if (onPrintTC) onPrintTC(tc);
          }}
          isCurrentEncounter={isCurrentEncounter}
        />)}
      {
          showEmptyItems &&
          timeChits.size === 0 &&
          <TimeChitItem
            disableChanges={disableChanges}
            config={config}
            hideButtons={hideButtons}
            saveModel={saveModel}
            user={user}
          />
        }
    </PermissionWrapper>
  </div>;

export default IssuedItemsList;
