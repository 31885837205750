import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'immutable';

import { fetchDataAsset, isEditableFile, getConfirmation } from './../../utils/utils';
import { colours } from './../../utils/css';
import { downloadFile } from './../../utils/export';
import translate from './../../utils/i18n';
import { getReferralQueryString } from './../../utils/router';
import StarButton from './starButton';
import { createSuccessNotification, createInfoNotification } from './../../utils/notifications';
import { createPermission, hasPermission } from './../../utils/permissions';
import Button from './../buttons/button';
import Header from './../header/header';

import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import type { SaveModel, User } from './../../types';

type Props = {
  gridViewActive: boolean,
  casenote?: CaseNoteFileModel,
  categoryID?: string,
  patientID: string,
  saveModel: SaveModel,
  isOnline: boolean,
  user: User,
};

/**
 * A component to be displayed above a casenotes view.
 * @class CasenotesHeader
 * @extends {React.PureComponent<Props>}
 */
class CasenotesHeader extends React.PureComponent<Props> {
  props: Props;

  onDeleteClicked = () => {
    const { casenote, saveModel } = this.props;
    if (casenote) {
      const confirmLabel = casenote.isVisible() ?
        'confirm_casenote_deletion' : 'confirm_casenote_restore';
      getConfirmation(translate(confirmLabel))
        .then(
          () => {
            saveModel(casenote.setVisible(!casenote.isVisible()))
              .then(() => {
                createSuccessNotification(casenote.isVisible()
                  ? translate('casenote_remove_from_bin_success')
                  : translate('casenote_deletion_success'));
              });
          },
          () => {},
        );
    }
  }

  /**
   * Download an asset
   * @param {string} assetID An asset ID.
   * @param {string} datatype An asset mimetype.
   * @returns {void}
   */
  downloadAsset(assetID: string, datatype: string) {
    fetchDataAsset(assetID, datatype)
      .then((content) => {
        const { casenote } = this.props;
        if (casenote) {
          const datatypeExtension = datatype.split('/').pop();
          let datatypeAsExtension = datatypeExtension;
          if (datatypeExtension === 'plain') {
            datatypeAsExtension = 'txt';
          }
          const fileExtension = (datatype && casenote.has('file_name') ? casenote.get('file_name').split('.').pop() : datatypeAsExtension);
          const filename = `${(casenote.get('title') !== null && (casenote.get('title', '').trim().length) ? casenote.get('title') : casenote.get('_id')).replace(/\s/g, '-')}${fileExtension ? `.${fileExtension}` : ''}`;
          return downloadFile(filename, content);
        }
        return false;
      });
  }

  /**
   * Gets the buttons to display in the header
   * @returns {Array | null}
   */
  getButtons() {
    const { gridViewActive, casenote, user, saveModel, categoryID } = this.props;
    const buttons = [];
    if (!gridViewActive && casenote) {
      const asset = casenote.getAsset(0);
      if (
        casenote.isVisible() &&
        hasPermission(user, List([createPermission('patient_casenotes', 'update')]))
      ) {
        buttons.push(
          <StarButton
            casenote={casenote}
            saveModel={saveModel}
            key={`starButton-${casenote.get('_id')}`}
          />,
        );
      }
      if (casenote.isVisible() && asset) {
        buttons.push(
          <Button
            className="o-text-button o-text-button--large u-margin-right--1ws"
            onClick={() => this.downloadAsset(asset.id, asset.datatype)}
            key={`downloadButton-${casenote.get('_id')}`}
            dataPublic
          >
            <img src="static/images/icon_download_2.svg" alt={translate('download')} style={{ height: 26, marginTop: 2 }} />
          </Button>,
        );
      }
      if (
        this.props.isOnline &&
        casenote.isVisible() &&
        hasPermission(this.props.user, List([createPermission('patient_casenotes', 'update')]))
      ) {
        const fromCategory = categoryID ? categoryID.toString() : '';
        let editButtonProps;
        if (asset) {
          const fileExtension = asset.datatype && asset.datatype.split('/')[1];
          editButtonProps = {
            to: !isEditableFile(asset.datatype) ? '#' : `/patient/${casenote.get('patient_id')}/casenotes/${casenote.get('_id')}/edit?fromCategory=${fromCategory}`,
            onClick: !isEditableFile(asset.datatype) ? () => createInfoNotification(translate('unable_to_edit_casenote_info', { fileExtension })) : '',
            style: { marginRight: '20px', color: !isEditableFile(asset.datatype) ? colours.grey3 : '' },
          };
        }
        buttons.push(
          // @ts-ignore
          <Link
            id="casenote-edit-button"
            className="o-text-button o-text-button--large"
            {...editButtonProps}
            key={`editButton-${casenote.get('_id')}`}
          >
            {translate('edit')}
          </Link>,
        );
      }
      if (hasPermission(this.props.user, List([createPermission('patient_casenotes', 'delete')]))) {
        buttons.push(
          <Button
            className="o-text-button o-text-button--large o-text-button--danger"
            onClick={this.onDeleteClicked}
            key={`deleteButton-${casenote.get('_id')}`}
            dataPublic
          >
            { casenote.isVisible() ? translate('delete') : translate('remove_from_bin')}
          </Button>,
        );
      }
    }
    return buttons.length > 0 ? buttons : null;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { gridViewActive, patientID, categoryID = 'ALL' } = this.props;
    const prefix = `/patient/${patientID}/categories/${categoryID}`;
    const gridLink = gridViewActive ?
      `${prefix}${getReferralQueryString()}` :
      `${prefix}/grid${getReferralQueryString()}`;
    return (
      <Header className="c-casenotes-header" dataPublic>
        <Link to={gridLink}>
          <img
            src="static/images/icon_grid.png"
            alt={translate('grid_view')}
            style={{ width: '30px', height: '30px' }}
          />
        </Link>
        {this.getButtons()}
      </Header>
    );
  }
}

export default CasenotesHeader;
