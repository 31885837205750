import React, { Fragment } from 'react';

import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import GridInput from './../inputs/gridInput';
import MetricTypeModel from './../../models/metricTypeModel';
import ModalFooter from './../modals/modalFooter';
import { validateAndTrimString } from './../../utils/utils';

import type { MetricDatatype } from './../../models/metricTypeModel';
import type { SaveModel } from './../../types';

type Props = {
  modelToEdit?: MetricTypeModel,
  onCancel: () => void,
  onSave: SaveModel,
}

/* eslint-disable camelcase */
type MetricTypeAttr = {
  name: string,
  display_format: string,
  unit: string,
  datatype: Array<MetricDatatype>,
};
/* eslint-enable camelcase */

type State = {
  isSaving: boolean,
  errorMessage?: string,
  attributes: MetricTypeAttr,
}

/**
 * Gets the default state of the component.
 * @param {MetricTypeModel?} model A metricType model
 * @returns {State}
 */
function getDefaultState(model?: MetricTypeModel) {
  if (model) {
    return Object.assign({}, {
      isSaving: false,
      attributes: {
        name: model.get('name', ''),
        display_format: model.get('display_format', ''),
        unit: model.get('unit', ''),
        datatype: model.get('datatype', []),
      },
    });
  }
  return Object.assign({}, {
    isSaving: false,
    attributes: {
      name: '', display_format: '', unit: '', datatype: [],
    },
  });
}


/**
 * A form for the creation/editing of a MetricType.
 * @class MetricTypeForm
 * @extends {React.Component<Props, State>}
 */
class MetricTypeForm extends React.Component<Props, State> {
  /**
   * Creates an instance of MetricTypeForm.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = getDefaultState(props.modelToEdit);
  }

  /**
   * Validates the datatypes field of the form.
   * @returns {boolean}
   */
  datatypesAreValid() {
    if (this.state.attributes.datatype.length === 0) {
      this.setState({ errorMessage: 'You must have at least one datatype.' });
      return false;
    } else if (this.state.attributes.datatype.some(d => !d.type)) {
      this.setState({ errorMessage: 'You must enter a type for each datatype.' });
      return false;
    }
    return true;
  }

  /**
   * Validates the display_format field of the form.
   * @returns {boolean}
   */
  displayFormatIsValid() {
    if (this.state.attributes.display_format.length === 0) {
      this.setState({ errorMessage: 'The display format cannot be empty.' });
      return false;
    }
    const valueCount = (this.state.attributes.display_format.match(/{v}/g) || []).length;
    if (valueCount !== this.state.attributes.datatype.length) {
      this.setState({ errorMessage: 'The number of {v} entries does not match the number of datatypes you have created.' });
      return false;
    }
    return true;
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    if (!this.datatypesAreValid()) {
      return false;
    } else if (!this.displayFormatIsValid()) {
      return false;
    } else if (!validateAndTrimString(this.state.attributes.name).length ||
      !this.state.attributes.unit.length) {
      this.setState({ errorMessage: 'You have not filed out all required fields.' });
      return false;
    }
    return true;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {undefined}
   */
  onSaveClicked() {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const model = this.props.modelToEdit ?
        this.props.modelToEdit
          .set({ ...this.state.attributes, ...validateAndTrimString(this.state.attributes) }) :
        new MetricTypeModel(this.state.attributes);
      this.props.onSave(model).then(() => {
        this.setState({ isSaving: false });
        if (!this.props.modelToEdit) {
          this.setState(getDefaultState());
        }
      });
    }
  }

  /**
   * Update state if model changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      this.setState(getDefaultState(nextProps.modelToEdit));
    }
  }

  /**
   * Called when closing the component
   * @returns {void}
   */
  componentWillUnmount() {
    this.setState(getDefaultState(this.props.modelToEdit));
    this.props.onCancel();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const idPrefix = this.props.modelToEdit ?
      `doctor_${this.props.modelToEdit.get('_id')}` : 'doctor';
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <div className="o-form__text-block o-form__text-block--error">
              {this.state.errorMessage}
            </div>
          }
          <Input
            id={`${idPrefix}_name`}
            label={translate('name')}
            value={this.state.attributes.name}
            onValueChanged={
              (name: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { name }),
              })
            }
            required
          />
          <Input
            id={`${idPrefix}_display_format`}
            label={translate('display_format')}
            description={translate('display_format_desc')}
            value={this.state.attributes.display_format}
            onValueChanged={
              (value: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { display_format: value }),
              })
            }
            required
          />
          <Input
            id={`${idPrefix}_unit`}
            label={translate('unit')}
            value={this.state.attributes.unit}
            onValueChanged={
              (unit: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { unit }),
              })
            }
            required
          />
          <GridInput
            id={`${idPrefix}_datatype`}
            label={translate('datatype')}
            description={translate('datatype_desc')}
            value={this.state.attributes.datatype}
            columns={[
              {
                value: 'type',
                label: translate('type'),
                options: [
                  { value: 'string', label: translate('string') },
                  { value: 'float', label: translate('float') },
                  { value: 'integer', label: translate('integer') },
                ],
              },
              { value: 'range', label: translate('range') },
            ]}
            onValueChanged={
              datatype => this.setState({
                attributes: Object.assign({}, this.state.attributes, { datatype }),
              })
            }
          />
        </div>
        <ModalFooter>
          <SaveButton
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={this.props.modelToEdit ? translate('save') : translate('add_new_vital')}
            onClick={() => this.onSaveClicked()}
            dataPublic
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default MetricTypeForm;
