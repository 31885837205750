import React from 'react';

import ImageLoader from './../imageLoader';
import FileIcon from './../fileIcon';
import { isImageFile, getBaseApiUrl } from './../../utils/utils';
import translate from './../../utils/i18n';
import { getReferralQueryString } from './../../utils/router';

import type { HTMLStyle } from './../../types';
import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import { debugPrint } from '../../utils/logging';

type Props = {
  casenote: CaseNoteFileModel, // The casenote to display.
  style: HTMLStyle,
  assetIndex: number, // The index of the asset for this casenote to display
  isEditLink: boolean,
  disableLink: boolean,
  showPageCount: boolean, // If true, the UI will display the number of pages for the CNF
  assetID?: string, // Optional ID used for being strict about re-renders
  isSelected?: boolean,
  beforeRoute?: () => Promise<boolean>, // A function that should run before the user is routed away. Only route if boolean is true.
  sidebarCategoryID?: string,
};

type State = {
  PDFUrlData?: string,
};

/**
 * A component that displays a single casenote's thumbnail.
 * @class CasenoteThumbnail
 * @extends {React.Component}
 */
class CasenoteThumbnail extends React.Component<Props, State> {
  props: Props;

  static defaultProps = {
    assetIndex: 0,
    imgStyle: { width: '100%', height: '100%' },
    style: { border: '1px black solid' },
    isEditLink: false,
    disableLink: false,
    showPageCount: false,
  };

  /**
   * Only update the thumbnail if the asset ID has changed.
   * @param {Props} nextProps Next props
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps: Props) {
    if ((this.props.assetID || nextProps.assetID) && this.props.assetID !== nextProps.assetID) {
      return true;
    }
    return this.props.casenote.getAssetID(this.props.assetIndex)
      !== nextProps.casenote.getAssetID(nextProps.assetIndex)
      || this.props.isSelected !== nextProps.isSelected;
  }

  /**
   * Onclick handler for the thumbnail if it is a link. It will run a beforeRoute hook if provided
   * and only proceed if true is returned.
   * @param {string} link The url to link to
   * @returns {void}
   */
  onClick(link: string) {
    if (this.props.beforeRoute) {
      this.props.beforeRoute()
        .then((wasSuccessful) => {
          if (wasSuccessful) {
            location.hash = link;
          }
        });
    } else {
      location.hash = link;
    }
  }

  /**
   * Renders an Asset component
   * @returns {React.Component}
   */
  renderAsset() {
    const assetID = this.props.casenote.getAssetID(this.props.assetIndex);
    if (!assetID) {
      debugPrint(`Tried to access non-existant asset index of ${this.props.assetIndex} for the casenote with ID ${this.props.casenote.get('_id')}`, 'error');
      throw new Error('Trying to access non-existant asset index of casenote.');
    }
    const asset = this.props.casenote.getAsset(this.props.assetIndex);
    if (asset) {
      if (isImageFile(asset.datatype)) {
        const src = this.props.casenote.getAssetUrl(assetID, `${getBaseApiUrl()}/`, undefined, true);
        return (
          <ImageLoader
            imageSrc={src}
            imageClassName="c-casenote__asset__img"
            imageAlt={this.props.casenote.get('_id')}
          />
        );
      }
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FileIcon filename={this.props.casenote.get('file_name', translate('untitled'), false)} filetype={asset.datatype} />
        </div>
      );
    }
    return false;
  }

  /**
   * Renders assets count
   * @returns {undefined}
  */
  renderAssetsCount() {
    if (this.props.showPageCount && this.props.casenote.filterNullFromAssets().length > 1) {
      return (
        <div key={`pages-${this.props.casenote.get('_id')}`} className="c-casenote-thumbnail__page-count">
          {translate('x_pages', { x: this.props.casenote.filterNullFromAssets().length })}
        </div>
      );
    }
    return false;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const className = this.props.isSelected ? 'c-active-casenotes-selector__list--selected' : '';
    if (this.props.disableLink) {
      return [this.renderAsset(), this.renderAssetsCount()];
    }
    const assetSuffix = this.props.assetIndex > 0 ? `/${this.props.assetIndex}` : '';
    const prefix = `/patient/${this.props.casenote.get('patient_id')}`;
    let link = this.props.isEditLink ?
      `${prefix}/casenotes/${this.props.casenote.get('_id')}/edit${assetSuffix}` :
      `${prefix}/categories/${this.props.casenote.get('category')}/${this.props.casenote.get('_id')}`;
    if (!this.props.casenote.isVisible()) {
      link = `${prefix}/categories/BIN/${this.props.casenote.get('_id')}`;
    }
    if (this.props.casenote.isStarred() && this.props.sidebarCategoryID === 'STARRED') {
      link = `${prefix}/categories/STARRED/${this.props.casenote.get('_id')}`;
    }
    link += getReferralQueryString();
    return (
      <div
        onClick={() => this.onClick(link)}
        style={Object.assign({}, { display: 'inline' }, this.props.style)}
        className={className}
      >
        {this.renderAsset()}
        {this.renderAssetsCount()}
      </div>
    );
  }
}

export default CasenoteThumbnail;
