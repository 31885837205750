import React from 'react';
import moment from 'moment';

import Modal from './../modals/modal';
import translate from './../../utils/i18n';
import AllergyModel from './../../models/allergyModel';
import { UNICODE } from './../../constants';
import { prettifyDate } from './../../utils/time';

/**
 * A modal that displays the details of an allergyModel.
 * @param {props} props The props for this component.
 * @returns {React.Component} An AllergyDetails component.
 */
const AllergyDetails = ({ model }: { model: AllergyModel }) =>
  <Modal
    buttonLabel={translate('view_details')}
    buttonClass="o-text-button o-text-button--contextual"
    title={translate('allergy')}
    dataPublic
  >
    <div className="u-padding--standard">
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('name')}</span>
        <span className="c-patient-card__item__value">{model.get('name', UNICODE.EMDASH, false)}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('creation_date')}</span>
        <span className="c-patient-card__item__value">{model.getCreationDate()} by {model.getCreator()}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('last_edited')}</span>
        <span className="c-patient-card__item__value">{model.getDateAndTime()} by {model.getLastEditor()}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('onset_date')}</span>
        <span className="c-patient-card__item__value">
          {moment(model.get('onset_date')).isValid() ? prettifyDate(model.get('onset_date')) : UNICODE.EMDASH}
        </span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('last_occurrence_date')}</span>
        <span className="c-patient-card__item__value">
          {moment(model.get('last_occurrence_date')).isValid() ? prettifyDate(model.get('last_occurrence_date')) : UNICODE.EMDASH}
        </span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('reaction')}</span>
        <span className="c-patient-card__item__value">{model.get('reaction', UNICODE.EMDASH, false)}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('status')}</span>
        <span className="c-patient-card__item__value">{translate(model.get('status', UNICODE.EMDASH, false))}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('verification_status')}</span>
        <span className="c-patient-card__item__value">{translate(model.get('verification_status', UNICODE.EMDASH, false))}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('criticality')}</span>
        <span className="c-patient-card__item__value">{translate(model.getCriticality())}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('allergy_type')}</span>
        <span className="c-patient-card__item__value">{model.get('allergy_type', UNICODE.EMDASH, false)}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('category')}</span>
        <span className="c-patient-card__item__value">{model.get('category', UNICODE.EMDASH, false)}</span>
      </div>
      <div className="c-patient-card__item">
        <span data-public className="c-patient-card__item__key">{translate('notes')}</span>
        <span className="c-patient-card__item__value">{model.get('notes', UNICODE.EMDASH, false)}</span>
      </div>
    </div>
  </Modal>;

export default AllergyDetails;
