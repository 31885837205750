import React from 'react';
import { List } from 'immutable';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

import { UNICODE } from './../../constants';
import ContentTransition from './../contentTransition';
import SmsJobEncounterDetail from './smsJobEncounterDetail';
import SmsJobMessages from './smsJobMessages';
// import SmsJobSendInput from './smsJobSendInput';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import translate from '../../utils/i18n';
import { debugPrint } from '../../utils/logging';
import APIError from '../../utils/apiError';
import LoadingIndicator from '../loadingIndicator';

import type DrugModel from './../../models/drugModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type PractitionerModel from './../../models/practitionerModel';
import PatientModel from './../../models/patientModel';
import PatientStubModel from './../../models/patientStubModel';
import SMSJobModel from '../../models/smsJobModel';
import PatientCampaignModel from '../../models/patientCampaignModel';
import SalesItemModel from '../../models/salesItemModel';
import BillItemModel from '../../models/billItemModel';
import BillModel from '../../models/billModel';
import EncounterModel from '../../models/encounterModel';
import PatientCampaignSetModel from '../../models/patientCampaignSetModel';

import type { Config, SaveModel } from './../../types';

type Props = {
  patientCampaign: PatientCampaignModel,
  smsJob: SMSJobModel,
  encounter: EncounterModel,
  config: Config,
  patient: PatientModel | PatientStubModel,
  drugs: List<DrugModel>,
  prescriptions: List<PrescriptionModel>,
  practitioners: List<PractitionerModel>,
  bills: List<BillModel>,
  billItems: List<BillItemModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  currentDataViewsError: APIError,
  isFetching: boolean,
  updateCampaignJobStatus: (campaignJob: SMSJobModel) => SMSJobModel | APIResponse,
  smsJobID: string,
  selectedCampaignSet: PatientCampaignSetModel,
  campaignSubType: string,
};

type State = {
  status: string,
}
/**
 * A Sms Job encounter component that wraps SmsJobEncounterDetail, SmsJobMessages, SmsJobSendInput component.
 * @class SmsJob
 * @extends {React.Component}
 */
class SmsJob extends React.Component<Props, State> {
  /**
   * Creates an instance of PatientList.
   * @param {Props} props intial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      status: this.props.smsJob && this.props.smsJob.get('is_resolved') ? 'resolved' : 'unresolved',
    };
  }

  /**
   * Handles change of the status
   * @param {string} status which needs to be set
   * @returns {void}
   */
  onStatusChange(status: string) {
    const { smsJob } = this.props;
    const properties = {
      is_resolved: status === 'resolved',
      status,
    };
    const job = new SMSJobModel({
      ...smsJob.attributes,
      ...properties,
    });
    this.props.updateCampaignJobStatus(job)
      .then((savedJob) => {
        if (savedJob.error && savedJob.msg === 'SERVER_FAILED') {
          createErrorNotification(translate('jobs_status_change_failed'));
        } else if (savedJob.error) {
          createErrorNotification(translate('something_went_wrong'));
        } else {
          createSuccessNotification(translate('job_status_changed'));
        }
      })
      .catch((error) => {
        debugPrint(error, 'error');
        createErrorNotification(translate('something_went_wrong'));
      });
  }

  /**
   * Handles sending of a new message
   * @param {string} text which needs to be send
   * @returns {void}
   */
  onSendMessage(text: string) {
    const { smsJob } = this.props;
    const replies = List(smsJob.get('replies'));
    const properties = {
      is_resolved: this.state.status === 'resolved',
      replies: replies.push({
        sender: 'clinic',
        message: text,
        received_at: moment().valueOf(),
      }).toArray(),
    };
    const job = this.props.smsJob.set(properties);
    this.props.saveModel(job)
      .then(() => {
        createSuccessNotification(translate('message_added_sucessfully'));
      });
  }

  /**
   * Gets list of bill items matching the encounter passed and item name/type passed.
   * @param {EncounterModel} encounter Encounter model.
   * @param {string} name name of bill item type.
   * @returns {List<string>}.
   */
  getSalesItems(encounter: EncounterModel): List<SalesItemModel> {
    const { bills, billItems, salesItems } = this.props;
    const bill = encounter && bills
      .find(model => model.get('encounter_id') === encounter.get('_id'));
    if (bill) {
      const filteredBillItems = billItems.filter(i => i.get('bill_id') === bill.get('_id'));
      return filteredBillItems.reduce((data, item) => {
        if (item.isSalesItem()) {
          return data.push(salesItems.find(s => s.get('_id') === item.get('sales_item_id')));
        }
        return data;
      }, List());
    }
    return List();
  }

  /**
   * Gets list of bill items matching the encounter passed and item name/type passed.
   * @param {SMSJobModel} job name of bill item type.
   * @returns {List<string>}.
   */
  getMessageStatus(job: SMSJobModel) {
    const status = job ? job.getStatus() : null;
    if (status === 'queued' || status === 'cancelled' || status === 'error') {
      return status;
    }
    if (status === 'in_progress') {
      return 'sending';
    }
    return status ? 'sent' : null;
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const {
      patient, encounter, prescriptions, drugs,
      patientCampaign, smsJob, config, isFetching, smsJobID,
      selectedCampaignSet, campaignSubType
    } = this.props;
    const isMedadvisor = campaignSubType === 'medadvisor';
    if (!selectedCampaignSet) {
      return (<LoadingIndicator alignCenter />);
    }
    const isPatientTypeCampaign = selectedCampaignSet.get('type') === 'patient_campaign_set'
      && selectedCampaignSet.get('master_campaign_set_type') !== 'MEDADVISOR';
    if (isPatientTypeCampaign === isMedadvisor) {
      return translate('page_not_found');
    }
    const drugPrescribed = prescriptions.reduce((data, p) => {
      const drug = encounter && drugs.find(d => (d.get('_id') === p.get('drug_id')) && p.get('encounter_id') === encounter.get('_id'));
      return drug ? data.push(drug.get('name')) : data;
    }, List());
    const salesItems = this.getSalesItems(encounter);
    const campaignName = patientCampaign ? patientCampaign.get('name') : UNICODE.EMDASH;
    const patientName = patient ? patient.get('patient_name', UNICODE.EMDASH) : UNICODE.EMDASH;
    const encounterDate = encounter ? encounter.getDate() : UNICODE.EMDASH;
    const messages = (patientCampaign && smsJob.get('message')
      ? List([{ message: smsJob.get('message'), sender: 'clinic', status: this.getMessageStatus(smsJob) }])
      : List())
      .concat(smsJob ? List(smsJob.get('replies')) : List());
    if (smsJob && smsJob.get('_id') !== smsJobID) {
      const path = `/campaigns/${patientCampaign.get('_id')}/jobs/${smsJob.get('_id')}`;
      debugPrint(`Redirecting to ${path}`);
      return (
        <Redirect to={path} />
      );
    }
    return (
      <ContentTransition id="main-content" className="o-scrollable-container" style={{ height: '100vh' }}>
        <div className="o-form u-margin-bottom--zero">
          <h1 className="o-form__title">{`${campaignName} - ${encounterDate} / ${patientName}`}</h1>
        </div>
        <SmsJobEncounterDetail
          encounter={encounter}
          patient={patient}
          drugPrescribed={drugPrescribed}
          onStatusChange={status => this.onStatusChange(status)}
          status={
            smsJob && smsJob.isResolvable()
              ? smsJob.getStatus()
              : ''
          }
          practitioners={this.props.practitioners}
          salesItems={salesItems}
        />
        <div className="u-margin--1ws">
          <SmsJobMessages
            messages={messages}
            smsJob={smsJob}
            patient={patient}
            config={config}
            currentDataViewsError={this.props.currentDataViewsError}
            isFetching={isFetching}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default SmsJob;
