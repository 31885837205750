import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';

import defaultTooltip from './../components/tooltip';
import defaultFlag from './../components/flag';

import { prettifyDate, prettifyDateTime, prettifyTime, getDateFormat } from './time';
import { convertNumberToPrice } from './utils';

import type { CellProps, SelectOption } from './../types';
import { UNICODE } from './../constants';
import translate from './i18n';

/**
 * Safely extract a link from props.
 * @param {CellProps} props The Cell props.
 * @returns {string} The link URL.
 */
export function getLink(props: CellProps): string {
  return props.original && props.original.link ? props.original.link : '';
}

/**
 * Returns a linked table cell. Will only work if table data has a field called link.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderWithLink(props: CellProps) {
  const link = getLink(props);
  return link.length ?
    <Link to={link} className="o-table__cell o-table__cell--link">{props.value}</Link> :
    <div className="o-table__cell">{props.value}</div>;
}

/**
 * Returns a linked table cell. Will only work if table data has a field called link.
 * @param {React.Component} Flag The flag component to render.
 * @param {React.Component} Tooltip The tooltip component to render.
 * @returns {Function} A table cell function.
 */
export function renderWithTooltip(
  Flag: React.Component = defaultFlag,
  Tooltip: React.Component = defaultTooltip,
) {
  return (props: CellProps) => {
    const link = getLink(props);
    return link.length ?
      <Link to={link} className="o-table__cell o-table__cell--link">
        { props.value ? <Flag options={props.original.flagData} /> : null }
        {(props.value && props.original.hover) ?
          <Tooltip tooltips={props.original.tooltipData} className="o-table__cell-tooltip" /> :
          null
        }
      </Link> :
      <div className="o-table__cell">
        { props.value ? <Flag options={props.original.flagData} /> : null }
        {(props.value && props.original.hover) ?
          <Tooltip tooltips={props.original.tooltipData} className="o-table__cell-tooltip" /> :
          null
        }
      </div>;
  };
}

/**
 * Returns a linked table cell. Will only work if table data has a field called link.
 * THe contests are rendered in a pre tag in order to preserve line breaks, etc.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderPreWithLink(props: CellProps) {
  const link = getLink(props);
  return link.length ?
    <Link to={link} className="o-table__cell o-table__cell--link"><pre>{props.value}</pre></Link> :
    <pre className="o-table__cell">{props.value}</pre>;
}

/**
 * Returns a linked table cell with value set to index of the row + 1. Will only work if table data has a field called link
 * and the value is a timestamp.
 * Ingores the value
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderSerialNumber(props: CellProps) {
  return <Link to={getLink(props)} className="o-table__cell o-table__cell--link">{props.index + 1}</Link>;
}

/**
 * Renders a table cell for a date. Value must be a timestamp. If value can't be parsed it is
 * displayed as is.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderDateTime(props: CellProps) {
  const date = Number.isInteger(props.value) ?
    prettifyDateTime(parseInt(props.value, 10)) : props.value;
  return <div className="o-table__cell">{date}</div>;
}

/**
 * Renders a linked table cell for a date time. Value must be a timestamp. If value can't be parsed it is
 * displayed as is.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderDateTimeWithLink(props: CellProps) {
  const link = getLink(props);
  if (link.length === 0) {
    return renderDateTime(props);
  }
  const date = Number.isInteger(props.value) ?
    prettifyDateTime(parseInt(props.value, 10)) : props.value;
  return <Link to={link} className="o-table__cell o-table__cell--link">{date}</Link>;
}

/**
 * Renders a table cell for a date. Value must be a timestamp. If value can't be parsed it is
 * displayed as is.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderDate(props: CellProps) {
  const date = Number.isInteger(props.value) ?
    prettifyDate(parseInt(props.value, 10)) : props.value;
  return props.original.renderAsVoid ?
    <div className="o-table__cell"><span style={{ opacity: 0.2 }}>{date}</span></div> :
    <div className="o-table__cell">{date}</div>;
}

/**
 * Returns a linked table cell with the value "prettified". Will only work if table data has a field
 * called link and the value is a timestamp.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderDateWithLink(props: CellProps) {
  const link = getLink(props);
  if (link.length === 0) {
    return renderDate(props);
  }
  const date = Number.isInteger(props.value) ?
    prettifyDate(parseInt(props.value, 10)) : props.value;
  return <Link to={link} className="o-table__cell o-table__cell--link">{date}</Link>;
}

/**
 * Renders a table cell for a time. Value must be a timestamp. If value can't be parsed it is
 * displayed as is.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderTime(props: CellProps) {
  const time = Number.isInteger(props.value) ?
    prettifyTime(parseInt(props.value, 10)) : props.value;
  return <div className="o-table__cell">{time}</div>;
}

/**
 * Renders a table cell for translated value. Value must be a string.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderTranslatedValue(props: CellProps) {
  return <div className="o-table__cell">{translate(props.value)}</div>;
}

/**
 * Renders a linked table cell for translated value. Value must be a string.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderTranslatedValueWithLink(props: CellProps) {
  const link = getLink(props);
  if (link.length === 0) {
    return renderTranslatedValue(props);
  }
  return <Link to={link} className="o-table__cell">{translate(props.value)}</Link>;
}
/**
 * Returns a linked table cell with the value "prettified". Will only work if table data has a field
 * called link and the value is a timestamp.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderTimeWithLink(props: CellProps) {
  const link = getLink(props);
  if (link.length === 0) {
    return renderTime(props);
  }
  const time = Number.isInteger(props.value) ?
    prettifyTime(parseInt(props.value, 10)) : props.value;
  return <Link to={link} className="o-table__cell o-table__cell--link">{time}</Link>;
}

/**
 * Renders a table cell that can span multiple lines
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderMultilineCell(props: CellProps) {
  return props.original.renderAsVoid ?
    <div className="o-table__cell o-table__cell--multiline">
      <span style={{ opacity: 0.2 }}>{props.value}</span>
    </div> :
    <div className="o-table__cell o-table__cell--multiline">{props.value}</div>;
}

/**
 * Renders a table cell for a price. Value must be a numbery value. If value can't be parsed it is
 * returned as a dash.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderPrice(props: CellProps) {
  return props.original.renderAsVoid ?
    <div className="o-table__cell o-table__cell--right">
      <span style={{ opacity: 0.2 }}>{convertNumberToPrice(props.value)}</span>
    </div> :
    <div className="o-table__cell o-table__cell--right">{convertNumberToPrice(props.value)}</div>;
}

/**
 * Returns a linked table cell with the value "prettified". Will only work if table data has a field
 * called link.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderPriceWithLink(props: CellProps) {
  const link = getLink(props);
  if (link.length === 0) {
    return renderPrice(props);
  }
  return <Link to={link} className="o-table__cell o-table__cell--link o-table__cell--right">{convertNumberToPrice(props.value)}</Link>;
}

/**
 * Renders a table cell that has line breaks and displays in next line
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderMultilineContent(props: CellProps) {
  return <div className="o-table__cell o-table__cell--linebreak">{props.value}</div>;
}

/**
 * Renders a linked table cell that has line breaks and displays in next line
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderMultilineContentWithLink(props: CellProps) {
  const link = getLink(props);
  if (link.length === 0) {
    return renderMultilineContent(props);
  }
  return <Link to={link} className="o-table__cell o-table__cell--linebreak">{props.value}</Link>;
}
/**
 * Renders a table cell width clinic spefic format for a date. Value will be date string.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderDateInClinicFormat(props: CellProps) {
  const date = props.value ?
    moment(props.value, 'YYYY-MM-DD').format(getDateFormat()) : props.value;
  return <div className="o-table__cell">{date}</div>;
}

/**
 * Renders the given content with a wrapper to show it is void if isVoid is true.
 * @param {string} content Content to wrap.
 * @param {boolean} isVoid True if bill is void.
 * @returns {string | React.Node}
 */
export function renderWithVoidWrapper(content: string, isVoid: boolean) {
  return isVoid ? <span style={{ opacity: 0.2 }}>{content}</span> : content;
}

/**
 * Renders a table cell with a list of items
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderList(props: CellProps) {
  return (
    <div className="o-table__cell" style={{ flexDirection: 'column', paddingTop: '1rem', paddingBottom: '1rem', alignItems: 'flex-start' }}>
      {
        props.value && typeof props.value[0] === 'string' && Array.isArray(props.value) ?
          props.value.map(e => <p>{e}</p>) : 'Default'
      }
    </div>
  );
}

/**
 * Renders a table cell with a list of payment items
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderPaymentList(props: CellProps) {
  return (
    <div className="o-table__cell" style={{ flexDirection: 'column', paddingTop: '1rem', paddingBottom: '1rem', alignItems: 'flex-end' }}>
      {
        Array.isArray(props.value) ?
          props.value.map(e => (typeof e === 'number' ? <p>{convertNumberToPrice(e)}</p> : `RM${UNICODE.EMDASH}`))
          : `RM${UNICODE.EMDASH}`
      }
    </div>
  );
}

/**
 * Renders a table cell that capitalised the text first letter
 * displayed as is.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderTextCapitalized(props: CellProps) {
  const text = props.value;
  return <div className="u-text-capitalised">{text}</div>;
}

/**
 * Renders a table cell with the text colorized
 * @param {CellProps} props The props for the cell.
 * @param {string} color Hex value or common name for the color of the text.
 * @returns {React.Component} The rendered cell
 */
export function renderTextColorized(props: CellProps, color: string) {
  const text = props.value;
  return <span style={{ color }}>{text}</span>;
}

/**
 *
 * converts filtered array from react table to key: value pairs for ease in api fetch calls
 * @param {Array<object>} filtered object from react table onFilterChange
 * @returns {Object}
 */
export const getFilterParamFromFiltered = (filtered: Array<Object>) =>
  filtered.reduce((obj, item) => ({ ...obj, [item.id]: item.value }), {});

/**
* Renders the filters drop down
* @param {{id: string, value: string}} filter The filter data.*
* @param {Function} onChange function call when dropdown select change
* @param {Array<SelectOption>} typeOptions option data for the dropdown
* @returns {React.PureComponent} The rendered component
*/
export const dropDownFilterMethod = (filter: {id: string, value: string},
  onChange: Function, typeOptions: Array<SelectOption>) => (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%', height: 34 }}
      value={filter ? filter.value : 'all'}
    >
      <option style={{ maxWidth: '20px' }} value="all" />
      {
      typeOptions.map(({ label, value }) =>
        <option value={value}>{truncate(label, { length: 20 })}</option>)
      }
    </select>);

/**
 * Safely extract a align from props.
 * @param {CellProps} props The Cell props.
 * @returns {string} The classname corresponding to alignment.
 */
export function getAlignedClassName(props: CellProps): string {
  if (props.column.align) {
    if (props.column.align === 'right') {
      return 'u-flex-justify-content-right';
    }
  }
  return '';
}

/**
 * Returns a linked table cell. Will only work if table data has a field called link.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderAlignedCellWithLink(props: CellProps) {
  const link = getLink(props);
  const className = getAlignedClassName(props);
  return link.length ?
    <Link to={link} className={`o-table__cell o-table__cell--link ${className}`}>{props.value}</Link> :
    <div className={`o-table__cell ${className}`}>{props.value}</div>;
}

/**
 * Returns a linked table cell. Will only work if table data has a field called link.
 * @param {object} props A rowdata object.
 * @returns {React.Component} A table cell constructed from an anchor tag.
 */
export function renderCellWithLinkAndClassName(props: CellProps) {
  const link = getLink(props);
  const className = props.column.className || '';
  return link.length ?
    <Link to={link} className={`o-table__cell o-table__cell--link ${className}`}>{props.value}</Link> :
    <div className={`o-table__cell ${className}`}>{props.value}</div>;
}

/**
 * Renders a table cell with a list of items
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderListItem(props: CellProps) {
  return (
    <ul className="o-table__cell--ul">
      {
        props.value && typeof props.value[0] === 'string' && Array.isArray(props.value) ?
          props.value.map(e => <li>{e}</li>) : props.placeholder || null
      }
    </ul>
  );
}

/**
 * Renders a table cell for a price. Value can be a numbery/ any string value.
 * If value can't be parsed, the value is returned.
 * @param {CellProps} props The props for the cell.
 * @returns {React.Component} The rendered cell
 */
export function renderPriceOrString(props: CellProps) {
  return props.original.renderAsVoid ?
    <div className="o-table__cell o-table__cell--right">
      <span style={{ opacity: 0.2 }}>{convertNumberToPrice(props.value)}</span>
    </div> :
    <div className="o-table__cell o-table__cell--right">{convertNumberToPrice(props.value)}</div>;
}
