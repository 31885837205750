module.exports = {
  prescription: {
    allowPopup: false,
  },
  inventory: {
    showInventoryMappingPage: false,
    showAllDrugs: false,
  },
  patient_action_button: {
    hide_add_notes_templates_text_buttons: true,
  },
  showDemoBanner: false, // If true a banner is shown to declare the app to be running as demo.
  premium_features_enabled: {
    patient_outreach_campaigns: {
      enabled: false,
    },
  },
  scheduling: {
    scheduleTeleConsultation: false,
    scheduleAppointments: false,
  },
  pharmaconnect: {
    enable: false,
    modal_ad: true,
    splash_ad: true,
    premium_features_enabled: false,
  },
  server_setting: {
    area_of_operation: 'malaysia',
  },
};
