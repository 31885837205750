import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import SaveButton from './../buttons/saveButton';
import StatelessModal from './../modals/statelessModal';
import Table from './../table/table';
import AddEditDrugDurationForm from './addEditDrugDurationForm';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';
import { pluralizeWord, getConfirmation } from '../../utils/utils';
import { updateClinicConfig } from './../../utils/db';
import { createSuccessNotification } from './../../utils/notifications';
import Button from './../buttons/button';

import type { SaveModel, User, Column, Row, Config } from './../../types';

type Props = {
  saveModel: SaveModel,
  drugDurations: List<array>,
  user: User,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
};

type State = {
  drugDuration?: Array<[number, string]>,
  formIsVisible: boolean,
};

/**
 * DrugDurations
 * @namespace DrugDurations
 */
class DrugDurations extends React.Component<Props, State> {
  /**
   * Creates an instance of DrugDurations.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      drugDuration: undefined,
      formIsVisible: false,
    };
  }

  /**
   * Resets the state for display of the record payment modal.
   * @returns {void}
   */
  hideModal() {
    this.setState({ formIsVisible: false });
  }

  /**
   * Creates an edit link for every row.
   * @param {List<number, string>} drugDuration current drug duration item.
   * @returns {React.Component} An edit link/button
   */
  getEditLink(drugDuration: List<number, string>) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('drug_durations', 'update')])} user={this.props.user}>
        <Button
          onClick={() => this.setState({ drugDuration: drugDuration.toJS(), formIsVisible: true })}
          className="o-text-button o-text-button--contextual"
          type="button"
          dataPublic
        >
          {translate('edit')}
        </Button>
      </PermissionWrapper>
    );
  }

  /**
   * Creates a delete link for every row.
   * @param {number} index index of an item.
   * @returns {React.Component} a delete link
   */
  getDeleteLink(index) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('drug_durations', 'delete')])} user={this.props.user}>
        <Button
          type="button"
          className="o-text-button o-text-button--danger"
          onClick={() => {
            this.onDelete(index);
          }}
          dataPublic
        >
          {translate('delete')}
        </Button>
      </PermissionWrapper>
    );
  }

  /**
   * Handles deletion of drug duration
   * @param {number} index index of an item.
   * @returns {void}
   */
  onDelete(index) {
    const { config, updateConfigValue, drugDurations, updateConfig } = this.props;
    const newDrugDurations = drugDurations.delete(index);
    getConfirmation(translate('confirm_deleting_drug_duration'))
      .then(
        () => {
          const updatedConfig = config
            .setIn(['prescription', 'durations'], newDrugDurations)
            .toJS();
          updateClinicConfig(updatedConfig, updateConfig)
            .then(() => createSuccessNotification(translate('drug_duration_deleted')));
          updateConfigValue(['prescription', 'durations'], newDrugDurations);
        },
        () => {},
      );
  }

  /**
   * prepares rows data for the tablel.
   * @returns {Array<Row>}
   */
  getRows(): Array<Row> {
    return this.props.drugDurations.map((d, i) => ({
      name: pluralizeWord(d.toArray()[1], d.toArray()[0]),
      edit: this.getEditLink(d),
      delete: this.getDeleteLink(i),
    })).toArray();
  }

  /**
   * prepares columns data for the tablel.
   * @returns {Array<Column>}
   */
  getColumns(): Array<Column> {
    const columns = [
      { accessor: 'name', Header: translate('name'), filterable: true, className: 'o-column-border-right' },
    ];
    if (hasPermission(this.props.user, List([createPermission('drug_durations', 'update')]))) {
      columns.push({
        accessor: 'edit', Header: '', sortable: false, width: 150,
      });
    }
    if (hasPermission(this.props.user, List([createPermission('drug_durations', 'delete')]))) {
      columns.push({
        accessor: 'delete', Header: '', sortable: false, width: 150,
      });
    }
    return columns;
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <ContentTransition className="o-main__content">
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <StatelessModal
            id="add-edit-drug-duration-form"
            visible={this.state.formIsVisible}
            setVisible={() => this.hideModal()}
            noButton
            explicitCloseOnly
            title={translate('add_edit_drug_duration')}
            onClose={() => this.hideModal()}
            dataPublicHeader
          >
            <AddEditDrugDurationForm
              onSaveClicked={() => this.hideModal()}
              drugDuration={this.state.drugDuration}
              saveModel={this.props.saveModel}
              drugDurations={this.props.drugDurations}
              config={this.props.config}
              updateConfig={this.props.updateConfig}
            />
          </StatelessModal>
          <div className="o-header u-flex-space-between">
            <h1 data-public>{translate('drug_durations')}</h1>
            <PermissionWrapper permissionsRequired={List([createPermission('drug_durations', 'create')])} user={this.props.user}>
              <SaveButton
                dataPublic
                onClick={() => this.setState({ drugDuration: undefined, formIsVisible: true })}
                className="o-button--small"
                label={translate('add_drug_duration')}
              />
            </PermissionWrapper>
          </div>
          <div className="o-card u-margin-bottom--4ws banks-table">
            <h2 data-public className="o-card__title">{translate('drug_durations')}</h2>
            <Table
              columns={this.getColumns()}
              data={this.getRows()}
              noDataText={translate('no_drug_durations_created')}
              defaultSorted={[{ id: 'name', desc: false }]}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default DrugDurations;
