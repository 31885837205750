/* eslint-disable react/no-unescaped-entities */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { Page } from 'catalog';

import StyleguideReset from './styleguideReset';

/**
 * Coding style section of styleguide
 * @class StyleguideCodingStyle
 * @extends {React.Component<Props, State>}
 */
const StyleguideCodingStyle = () =>
  <StyleguideReset>
    <Page>
      <h2 data-public>Javascript</h2>
      <p>
        Our coding style is dictated by our ESLint rules. The majority of these are inherited
        from <a href="https://github.com/airbnb/javascript">AirBnB's style guide</a> which has a
        great explanation of each style choice. Below you can find some of the exceptions we have
        made and why:
      </p>
      <h3 data-public>max-lines</h3>
      <p>i.e. <code>{`"max-lines": ["warn", 300]`}</code></p>
      <p>In order to avoid spaghetti code, files should avoid being over 300 lines long.</p>
      <h3 data-public>no-plusplus</h3>
      <p>i.e. <code>{`"no-plusplus": ["error", { "allowForLoopAfterthoughts": true }]`}</code></p>
      <p>
        In order to be as explicit as possible it preferred to use <code>x += 1</code> instead 
        of <code>x++</code>, the one exception being for use in for loops.
      </p>
      <h3 data-public>require-jsdoc &amp; valid-jsdoc</h3>
      <p>
        JSDoc style documentation is required on all functions and classes in order to ensure
        proper documentation of the application's code.
      </p>
      <h2 data-public>HTML</h2>
      <p>
        Although the majority of the Klinify web client is delivered in Javascript, using
        standards based HTML5 is still very important to us. This means using the correct
        accessibility tags on HTML elements, placing elements in the DOM in a semantic way, 
        and relying on the HTML API wherever possible.
      </p>
      <h2 data-public>CSS</h2>
      <h3 data-public>Writing CSS</h3>
      <p>
        Global styles are linted using Stylelint. Currently it is not possible to do the same for 
        our component based styles, however these too should follow the rules set out in 
        <code>./src/.stylelintrc</code>.
      </p>
      <h3 data-public>Global Styles</h3>
      <p>
        Despite our transition to component based styling, global styles are still likely to be
        necessary in the long term. These styles should generally exist in one of the files 
        already present in <code>./src/stylesheets/postcss</code> and stick to using  
        <a href="http://getbem.com/">BEM-style naming conventions</a>.
      </p>
      <h3 data-public>Component Styles</h3>
      <p>We are working on migrating component-level styling to <a href="https://glamorous.rocks">Glamorous</a>.</p>
      <p>For working examples see:</p>
      <ul>
        <li><code>./src/components/login</code></li>
        <li><code>./src/components/patientQueue</code></li>
        <li><code>./src/components/buttons/loadingButton.jsx</code></li>
      </ul>
      <p>
        Everything except global styles (e.g. styles applied to html/body tags, scrollbar 
        behaviour, etc.) will eventually be converted to use Glamorous syntax.
      </p>
      <p>
        Variables live in <code>./src/utils/css.js</code>. If using a colour, font, or break-point, it must come from
        here. Similarly most size related styles (padding, margin, height, etc.) should have an origin in
        variables, except in edge cases.
      </p>
      <p>
        Don't change the breakpoints in <code>css.js</code> without asking someone first. They have been selected as
        they are for particular reasons.
      </p>
      <p>There are three patterns to follow when converting/implementing styles:</p>
      <ol>
        <li>
          <p>
            If it is an element that will be reused often and across the whole app (e.g. a card layout), 
            create an exportable component for it in the folder <code>./src/components/layout/</code>. If the styled
            component will only be used in a certain group of components, feel free to place it somewhere
            else appropriate (e.g. a styled button component could be placed in <code>./src/components/buttons/</code>.
          </p>
        </li>
        <li>
          <p>
            For styled components with complex CSS or that will be used more than once in a component, create
            a reusable component at the top of the JSX file underneath the type declarations. See LoginWrapper
            and LoginTitle in the file <code>./src/components/login/login.jsx</code> for an example of this.
          </p>
        </li>
        <li>
          <p>For simple styled components, it is fine to use the element inline, e.g.:</p>
          <pre>
            <code>
{`<P css={{ fontSize: scaledH3, textAlign: 'center' }}>Please log in to continue.</P>
<Div css={{ marginTop: \`calc(3 * \${wsUnit})\`, width: '100%' }}>
{ someOtherStuff }
</Div>`}
            </code>
          </pre>
          <p>
            However, if the inline component extends past the 100 character ruler, you'll need to refactor
            it into a standalone component (i.e. the previous step).
          </p>
        </li>
      </ol>
      <p>
        When naming styled components use descriptive names based on content, e.g. LoginContainer rather
        than LoginDiv, PatientCardTitle rather than PatientCardH1.
      </p>
    </Page>
  </StyleguideReset>;

export default StyleguideCodingStyle;
