import { connect } from 'react-redux';
import { List } from 'immutable';

import CurrentConsult from './../components/consultations/currentConsult';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import { updateConfigValue, updateConfig } from './../actions';
import { getModelMapFromList } from '../utils/models';

import type { Dispatch, State, Config } from './../types';
import type EncounterModel from '../models/encounterModel';
import type PatientModel from '../models/patientModel';
import type PatientStubModel from '../models/patientStubModel';

type OwnProps = {
  encounter: EncounterModel;
  patient: PatientModel | PatientStubModel;
  isCurrent: boolean;
};

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const bill = state.bills.find(model => model.get('_id') === ownProps.encounter.get('bill_id'));
  const procedureRequests = state.procedureRequests
    .filter(procedureRequest => procedureRequest.get('encounter_id') === ownProps.encounter.get('_id') &&
    procedureRequest.get('patient_id') === ownProps.encounter.get('patient_id'));
  const specimens = state.specimens
    .filter(specimen =>
      specimen.get('patient_id') === ownProps.encounter.get('patient_id') &&
      procedureRequests.filter(procedureRequest =>
        specimen.get('procedure_request_id') === procedureRequest.get('_id')));

  return {
    patient: ownProps.patient,
    bill,
    billItems: bill ? state.billItems.filter(i => i.get('bill_id') === bill.get('_id')) : List(),
    user: state.user,
    config: state.config,
    klinifyConfig: state.klinifyConfig,
    coveragePayors: state.coveragePayors,
    coveragePayorID: ownProps.patient.getCoveragePayor(),
    collectedSpecimens: state.collectedSpecimens,
    drugs: state.drugs,
    salesItems: state.salesItems,
    encounter: ownProps.encounter,
    dosingRegimens: state.dosingRegimens.filter(m => m.isVisible),
    diagnoses: state.conditions
      .filter(c => c.get('encounter_id') === ownProps.encounter.get('_id') && c.isVisible() && c.isDiagnosis()),
    symptoms: state.conditions
      .filter(c => c.get('encounter_id') === ownProps.encounter.get('_id') && c.isVisible() && c.isSymptom()),
    medicalCertificates: state.medicalCertificates
      .filter(mc => mc.get('encounter_id') === ownProps.encounter.get('_id')),
    prescriptions: state.prescriptions
      .filter(prescription => prescription.get('encounter_id') === ownProps.encounter.get('_id')),
    providers: state.providers,
    procedureTypes: state.procedureTypes,
    procedureRequests,
    procedureStatuses: state.procedureStatuses,
    procedureResults: state.procedureResults,
    specimens,
    timeChits: state.timeChits
      .filter(timeChit => timeChit.get('encounter_id') === ownProps.encounter.get('_id')),
    isOnline: state.isOnline,
    inventoryCount: state.inventoryCount,
    // patient: state.patients.find(p => p.get('_id') === ownProps.encounter.get('patient_id')),
    practitioners: state.practitioners,
    documentTemplates: state.documentTemplates
      .filter(d => d.hasLocation('encounter')),
    documentData: state.documentData
      .filter(d => d.get('encounter_id') === ownProps.encounter.get('_id') && !d.isVoid()),
    appointment: state.appointments.find(a => (
      a.get('patient_id') === ownProps.patient.get('_id')
      && !a.isCancelled()
      && a.get('_id') === ownProps.encounter.get('appointment_id')
    )),
    encounterStageMap: getModelMapFromList(state.encounterStages),
    verifiedDrugs: state.verifiedDrugs,
    activeIngredients: state.activeIngredients,
    allergies: state.allergies.filter(m => m.get('patient_id') === ownProps.patient.get('_id') && m.isVisible()),
    isCurrent: ownProps.isCurrent,
    patientPrescriptionHistory: state.prescriptions.filter(p => p.get('patient_id') === ownProps.patient.get('_id')),
    encounters: state.encounters.filter(e => e.get('patient_id') === ownProps.patient.get('_id')),
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const CurrentConsultContainer = connect(mapStateToProps, mapDispatchToProps)(CurrentConsult);

export default CurrentConsultContainer;
