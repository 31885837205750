import * as React from 'react';
import { TypeSpecimen } from 'catalog';

import { fonts, scaledH1, scaledH2, scaledH3, colours, fontSize } from './../../../utils/css';

/**
 * Typography section of style guide.
 * @class Typography
 * @extends {React.Component<Props, State>}
 */
const Typography = () =>
  <React.Fragment>
    <h2 data-public>Typography</h2>
    <h3 data-public>Headers</h3>
    <p>All headings should use one of the below 3 sizes (and follow semantic ordering).</p>
    <TypeSpecimen
      headings={[
        { label: 'H1 (css.scaledH1)', value: scaledH1 },
        { label: 'H2 (css.scaledH2)', value: scaledH2 },
        { label: 'H3 (css.scaledH3)', value: scaledH3 },
      ]}
      font={fonts.medium}
      color={colours.black}
    />
    <h3 data-public>Content</h3>
    <p>
      All content should default to using the following size (the applications styles will set
      this by default so you should not need to add anything).
    </p>
    <TypeSpecimen
      paragraphs={['16px']}
      font={fonts.light}
      color={colours.black}
      kafka
    />
    <h3 data-public>Miscellaneous</h3>
    <TypeSpecimen
      headings={[
        { label: 'Input Labels', value: fontSize.label },
      ]}
      font={fonts.bold}
      color={colours.black}
      span={3}
    />
    <TypeSpecimen
      headings={[
        { label: 'Small text (Input label descriptions, notes, etc.)', value: fontSize.small },
      ]}
      font={fonts.light}
      color={colours.black}
      span={3}
    />
  </React.Fragment>;

export default Typography;
