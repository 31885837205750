import { connect } from 'react-redux';
import type { List } from 'immutable';
import memoizeOne from 'memoize-one';
import PatientList from './../components/patientList/patientList';
import {
  setPatientSearchQuery, updateModels, setPatientStubs, updateModel,
  showAppointmentRequested, showAppointmentRequestNotificationIcon, updateConfig,
} from './../actions';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import { getModelMapFromList, getOrderedModelMapFromList } from '../utils/models';

import type { Config, Dispatch, State } from './../types';
import AppointmentModel from '../models/appointmentModel';
import PatientStubModel from '../models/patientStubModel';
import { compareByAlphabeticalOrder } from '../utils/comparators';

const getPatientStubMap = memoizeOne((patientStubs) => getModelMapFromList(patientStubs));
const getBillMap = memoizeOne((bills) => getModelMapFromList(bills));

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  appointments: state.appointments.filter(a => a.get('status') !== 'cancelled'),
  config: state.config,
  salesItems: state.salesItems,
  encounters: state.encounters,
  bills: getBillMap(state.bills),
  patientSearchQuery: state.patientSearchQuery,
  patientStubs: getPatientStubMap(state.patientStubs),
  patients: state.patients,
  payments: state.payments.filter(p => !p.isVoid()),
  outstandingReceivables: state.receivables &&
      state.receivables.filter(r => !r.isVoid() && r.hasOutstanding()),
  user: state.user,
  practitioners: state.practitioners,
  coveragePayors: state.coveragePayors,
  isOnline: state.isOnline,
  patientsSearch: state.patientsSearch,
  klinifyConfig: state.klinifyConfig,
  encounterStageMap: getModelMapFromList(state.encounterStages),
  encounterFlowMap: getOrderedModelMapFromList(state.encounterFlows.filter(f => f.isVisible()).sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))),
  paymentTypes: state.paymentTypes.filter(p => p.isVisible()),
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  showAppointmentRequested: (model: AppointmentModel) => dispatch(showAppointmentRequested(model)),
  setPatientSearchQuery: (query: string) => dispatch(setPatientSearchQuery(query)),
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  addModelToStore: (model: AppointmentModel) => dispatch(updateModel(model)),
  setPatientStubs: (patientStubs: List<PatientStubModel>) =>
    dispatch(setPatientStubs(patientStubs)),
  updateModelsInState: models => dispatch(updateModels(models)),
  showAppointmentRequestNotificationIcon: (show: boolean) =>
    dispatch(showAppointmentRequestNotificationIcon(show)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const PatientListContainer = connect(mapStateToProps, mapDispatchToProps)(PatientList);

export default PatientListContainer;
