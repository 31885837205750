import React from 'react';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import type { SortingRule } from 'react-table';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import flagTableHOC from '../hoc/flagTable';
import { prettifyDate, prettifyTime } from './../../utils/time';
import { getEncounterFlag, getEncounterCreatedBy } from './../../utils/encounters';
import { transformColumnKeys, transformFilteredColumns, getWebappSettings, setLocalWebappSettings } from './../../utils/utils';
import { calculatePaymentsTotal } from './../../utils/billing';
import { createPermission, hasPermission } from '../../utils/permissions';
import getPanelNameForPatient from './../../utils/coveragePayors';
import Header from './../header/header';
import TableCollapseIcon from './../icons/tableCollapseIcon';
import { getCompletedEncounterTableColumns } from './columns';
import TableColumnsSettings from '../table/tableColumnsSettings';
import { filterWithConditions } from '../../utils/tableFilter';

import type EncounterModel from './../../models/encounterModel';
import type BillModel from './../../models/billModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PaymentModel from './../../models/paymentModel';
import type { Config, User, TableFlagType, CustomColumn } from './../../types';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ReceivableModel from './../../models/receivableModel';
import type SalesItemModel from '../../models/salesItemModel';
import type { FilteringRule } from './tableFilter';

type Props = {
  config: Config,
  encounters: List<EncounterModel>,
  bills: Map<string, BillModel>,
  label: string,
  patients: Map<string, PatientStubModel>,
  payments: List<PaymentModel>,
  practitioners: List<PractitionerModel>,
  user: User,
  outstandingReceivables: List<ReceivableModel>,
  coveragePayors: List<CoveragePayorModel>,
  updateConfig: (config: Config) => void,
  salesItems: List<SalesItemModel>,
  tableProps: {
    sorted: SortingRule[],
    filteringRule: FilteringRule,
  }
};

type State = {
  isTableCollapsed: boolean,
  columns: Array<CustomColumn>,
}

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @param {User} user User
 * @returns {object[]} An array of objects each representing a column in the table.
 */
const getColumns = (config: Config, user: User) => getCompletedEncounterTableColumns(config, user);

const FlagTable = flagTableHOC(Table);

/**
 * An CompletedEncounterTable component to be used within the PatientList component.
 * @param {any} props The props for the component.
 * @returns {React.Component} An EncounterTable component.
 */
class CompletedEncounterTable extends React.Component<Props, State> {
  localSettings: Map<string, any>;

  /**
   * Creates an instance of EncounterTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.localSettings = getWebappSettings();
    this.state = {
      columns: transformColumnKeys(getColumns(props.config, props.user)).filter(i => !i.hideDefault),
      isTableCollapsed: this.localSettings.getIn(['patient_overview', 'collapse_completed_card']) !== undefined
        ? this.localSettings.getIn(['patient_overview', 'collapse_completed_card']) : true,
    };
  }

  /**
 * Returns an object with appropriate fields for a Table row populated from an EncounterModel.
 * @param {EncounterModel} encounter The encounter the button is for.
 * @param {Map<string, BillModel>} bills A map of all Bills.
 * @param {Map<string, PatientStubModel>} patients A map of all Patient Stubs.
 * @param {List<PaymentModel>} payments A list of Payments.
 * @param {List<PractitionerModel>} practitioners A list of all Practitioners
 * @param {User} user User.
 * @param {List<CoveragePayorModel>} coveragePayors A list of all panels
 * @returns {object} A table row object.
 */
  getTableRow(
    encounter: EncounterModel, bills: Map<string, BillModel>,
    patients: Map<string, PatientStubModel>,
    payments: List<PaymentModel>, practitioners: List<PractitionerModel>,
    user: User, coveragePayors: List<CoveragePayorModel>,
  ) {
    const patient = patients.get(encounter.get('patient_id'));
    const bill = bills.get(encounter.get('bill_id'));
    let cashLabel: string | number = translate('loading_bill');
    let paymentMethod = '';
    let billTotal = 0;
    if (bill) {
      if (bill.isVoid()) {
        cashLabel = translate('bill_voided');
      } else {
        const billPayments = payments.filter(p => p.get('bill_id') === bill.get('_id')).filter(x => !x.isVoid());
        billTotal = calculatePaymentsTotal(billPayments);
        cashLabel = billTotal;
        paymentMethod = billPayments.size ? billPayments.get(0)?.get('method') : UNICODE.EMDASH;
      }
    }
    if (billTotal === 0) {
      paymentMethod = UNICODE.EMDASH;
    }
    let doctor = encounter.getDoctorName(practitioners);
    if (doctor === UNICODE.EMDASH || !doctor?.length) {
      doctor = translate('unassigned');
    }
    const encounterFlag: TableFlagType = getEncounterFlag(
      encounter,
      patient,
      this.props.outstandingReceivables,
    );
    return {
      flag: encounterFlag.active,
      flagData: { color: encounterFlag.color },
      tooltipData: encounterFlag.active && encounterFlag.tooltips,
      q_no: encounter.getQueueNumber(),
      arrival_time: prettifyTime(encounter.getArrivalTime()),
      arrival_date: prettifyDate(encounter.getArrivalTime()),
      completed_time: prettifyTime(encounter.getCompletedTime()),
      completed_date: prettifyDate(encounter.getCompletedTime()),
      name: patient ? patient.get('patient_name', UNICODE.EMDASH) : translate('loading...'),
      ic: patient ? patient.get('ic', UNICODE.EMDASH, false) : translate('loading...'),
      case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : translate('loading...'),
      sex: patient ? patient.get('sex', UNICODE.EMDASH, false) : translate('loading...'),
      panel_or_cash: patient ? patient.getPaymentType() : translate('loading...'),
      panel_name: getPanelNameForPatient(patient, coveragePayors),
      doctor,
      creator: getEncounterCreatedBy(encounter),
      encounter_flow: encounter.getEncounterType(this.props.salesItems),
      amount_received: user && hasPermission(user, List([createPermission('finalised_bill', 'read')])) ? cashLabel : '',
      payment_type: paymentMethod,
      link: `/patient/${encounter.get('patient_id')}`,
    };
  }


  /**
   * Returns memoized data for the table
   * @returns {Array<Row>}
   */
  getTableData = memoizeOne(
    (
      encounters: List<EncounterModel>,
      bills: Map<string, BillModel>,
      patients: Map<string, PatientStubModel>,
      payments: List<PaymentModel>,
      practitioners: List<PractitionerModel>,
      user: User,
      coveragePayors: List<CoveragePayorModel>,
      filteringRule: FilteringRule,
    ) => {
      const tableData = encounters
        ?.map(encounter =>
          this.getTableRow(
            encounter,
            bills,
            patients,
            payments,
            practitioners,
            user,
            coveragePayors,
          )
        )
        .toArray();
      return {
        tableData,
        filteredTableData: filterWithConditions(tableData, filteringRule),
      };
    },
  );


  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
 */
  render() {
    const {
      encounters, bills, label, patients,
      config, payments, practitioners, user, coveragePayors,
      tableProps,
    } = this.props;
    const { isTableCollapsed } = this.state;
    const transformedColumnKeys = transformColumnKeys(getColumns(config, user));
    const { filteredTableData } = this.getTableData(
      encounters,
      bills,
      patients,
      payments,
      practitioners,
      user,
      coveragePayors,
      tableProps.filteringRule,
    );
    return (
      <section className="o-card">
        <Header className="o-card__header">
          <h1 className="o-card__title o-card__title--no-right-padding u-margin-right--half-ws" data-public>{label}</h1>
          <span>-&nbsp;&nbsp;{encounters.size}</span>
          <span className="o-card__header__right-aligned-text">
            <TableColumnsSettings
              config={config}
              configFieldName="completed_encounter"
              originalColumns={List(transformedColumnKeys)}
              columns={transformedColumnKeys}
              onUpdateColumns={updatedColumns => this.setState({ columns: updatedColumns })}
              updateConfig={this.props.updateConfig}
              isCompact
            />
            <TableCollapseIcon
              id="completed_encounter"
              isTableCollapsed={isTableCollapsed}
              onClick={() => {
                this.setState((state) => {
                  setLocalWebappSettings(this.localSettings.setIn(['patient_overview', 'collapse_completed_card'], !state.isTableCollapsed));
                  return { isTableCollapsed: !state.isTableCollapsed };
                });
              }
              }
            />
          </span>
        </Header>
        {!isTableCollapsed &&
          <FlagTable
            columns={transformFilteredColumns(getColumns(config, user), this.state.columns)}
            data={filteredTableData}
            defaultSorted={[{ id: 'arrival_time', desc: false }]}
            noDataText={translate(tableProps?.filteringRule?.filterRules.length ? 'no_filter_result' : 'no_patients')}
            sorted={tableProps.sorted}
          />
        }
      </section>
    );
  }
}

export default CompletedEncounterTable;
