import { List, Map } from 'immutable';
import PatientCampaignModel, { CampaignRulesCondition, CampaignRuleType } from '../models/patientCampaignModel';
import { covertDateOffsetToDays } from './time';

/**
 * Get attr of the patient campaigns
 * @param {PatientCampaignModel} patientCampaign patient campaign
 * @returns {{}} policy name.
 */
export default function getCampaignDefaultAttr(
  patientCampaign: PatientCampaignModel | undefined) {
  const filterConditions: List<CampaignRulesCondition> = patientCampaign ? List(patientCampaign.get('filter').conditions) : List();
  const filterConditionsMap = Map(filterConditions.map(c => [c.type, c]));
  const encounterTimeRule = filterConditionsMap.get(CampaignRuleType.ENCOUNTER_TIME);
  const masterCampaignjobTimeRule = filterConditionsMap.get(
    CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME,
  );
  const campaignjobTimeRule = filterConditionsMap.get(
    CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME,
  );
  /* eslint-disable camelcase */
  const masterOrCampaingJobFilter = (masterCampaignjobTimeRule || campaignjobTimeRule);

  const jobScheduleValue = (masterOrCampaingJobFilter?.metadata?.comparator === 'btw'
    ? masterOrCampaingJobFilter?.metadata?.job_time_offset_min?.time_value
    : masterOrCampaingJobFilter?.metadata?.job_time_offset?.time_value) ?? null;

  const jobScheduleUnit = (masterOrCampaingJobFilter?.metadata?.comparator === 'btw'
    ? masterOrCampaingJobFilter?.metadata?.job_time_offset_min?.time_unit
    : masterOrCampaingJobFilter?.metadata?.job_time_offset?.time_unit) ?? null;

  const encounterScheduleValue = (encounterTimeRule?.metadata?.comparator === 'btw'
    ? encounterTimeRule?.metadata?.encounter_time_offset_min?.time_value
    : encounterTimeRule?.metadata?.encounter_time_offset?.time_value) ?? null;

  const encounterscheduleUnit = (encounterTimeRule?.metadata?.comparator === 'btw'
    ? encounterTimeRule?.metadata?.encounter_time_offset_min?.time_unit
    : encounterTimeRule?.metadata?.encounter_time_offset?.time_unit) ?? null;

  const encounterEnrolmentValue = encounterTimeRule?.metadata?.encounter_time_offset_max?.time_value
    ?? null;
  const encounterEnrolmentUnit = encounterTimeRule?.metadata?.encounter_time_offset_max?.time_unit ?? null;
  const jobEnrolmentValue = masterOrCampaingJobFilter?.metadata?.job_time_offset_max?.time_value
    ?? null;

  const jobEnrolmentUnit = masterOrCampaingJobFilter?.metadata?.job_time_offset_max?.time_unit ?? null;

  const campaignJobCampaignId = campaignjobTimeRule?.metadata?.campaign_id ?? '';

  const masterJobCampaignId = masterCampaignjobTimeRule?.metadata?.master_campaign_id ?? '';
  const jobType = masterCampaignjobTimeRule ? CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME :
    campaignjobTimeRule ? CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME :
      CampaignRuleType.ENCOUNTER_TIME;
  /* eslint-disable camelcase */
  const scheduleValue = masterOrCampaingJobFilter ? jobScheduleValue : encounterScheduleValue;
  const scheduleUnit = masterOrCampaingJobFilter ? jobScheduleUnit : encounterscheduleUnit;
  const enrolmentValue = masterOrCampaingJobFilter ? jobEnrolmentValue : encounterEnrolmentValue;
  const enrolmentUnit = masterOrCampaingJobFilter ? jobEnrolmentUnit : encounterEnrolmentUnit;

  const target = (covertDateOffsetToDays(scheduleValue, scheduleUnit) >=
    covertDateOffsetToDays(enrolmentValue, enrolmentUnit)) ? 'prospective' : 'retrospective';
  return {
    filterConditions: filterConditions.filter((c: CampaignRulesCondition) =>
      ![CampaignRuleType.ENCOUNTER_TIME, CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME,
        CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME].includes(c.type)),
    scheduleValue,
    scheduleUnit,
    enrolmentValue,
    enrolmentUnit,
    target,
    masterCampaignJobId: masterJobCampaignId,
    campaignJobId: campaignJobCampaignId,
    jobType,
  };
}
