import React from 'react';
import type { List } from 'immutable';
import glamorous, { Div, H1 } from 'glamorous';

import { colours, wsUnit, border, borderRadius, fonts } from './../../utils/css';

import type EncounterModel from './../../models/encounterModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PractitionerModel from './../../models/practitionerModel';
import type { Config } from '../../types';

type Props = {
  encounter: EncounterModel,
  patientStubs: List<PatientStubModel>,
  practitioners: List<PractitionerModel>,
  config: Config,
}

const QueueItem = glamorous.div({
  display: 'flex',
  fontSize: '2em',
  color: colours.midnightGrey,
  borderTop: border,
  '&:last-child': {
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  },
  '&:nth-of-type(even)': {
    backgroundColor: colours.grey1,
  },
}, ({ isNew }) => (!isNew ? {} : {
  backgroundColor: colours.green,
  fontSize: '3em',
  color: colours.white,
  '&:nth-of-type(even)': {
    backgroundColor: colours.greenHover,
  },
}));

const PatientIdentifier = glamorous.div({
  width: '60%',
  padding: wsUnit,
  display: 'flex',
  alignItems: 'center',
}, ({ isNew }) => (!isNew ? {} : { fontFamily: fonts.medium }));

const NEW_ITEM_THRESHOLD = 60000;

/**
 * Returns true if encounter was moved to in progress in the last minute (or however long
 * NEW_ITEM_THRESHOLD is).
 * @param {EncounterModel} encounter The encounter for this item.
 * @returns {boolean} True if model is new.
 */
function isNewEncounter(encounter: EncounterModel) {
  return new Date().valueOf() < encounter.getLastEventTime() + NEW_ITEM_THRESHOLD;
}

/**
 * A Patient Queue item displaying a patient identifier (currently their name) and the room and
 * doctor they are being called for.
 * @class PatientQueueItem
 * @extends {React.Component<Props, State>}
 */
class PatientQueueItem extends React.PureComponent<Props> {
  /**
   * Creates an instance of PatientQueueItem.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    // Make sure we re-render after encounter is no longer "new"
    if (isNewEncounter(props.encounter)) {
      const timeUntilNotNew = props.encounter.getLastEventTime() + NEW_ITEM_THRESHOLD
        - new Date().valueOf() + 5000; // The 5000 is just a bit of overcompensation to make sure we actual get it right.
      setTimeout(() => this.forceUpdate(), timeUntilNotNew);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { encounter, patientStubs, practitioners, config } = this.props;
    const patient = patientStubs.find(p => p.get('_id') === encounter.get('patient_id'));
    const patientName = patient ? patient.get('patient_name') : encounter.get('patient_id');
    const patientIdentifier = config.getIn(['patient_queue', 'patient_identifier']) === 'patient_name'
      ? patientName
      : encounter.getQueueNumber()?.toString().padStart(3, '0') || patientName;
    return (
      <QueueItem isNew={isNewEncounter(encounter)}>
        <PatientIdentifier>{patientIdentifier}</PatientIdentifier>
        <Div css={{ borderLeft: border, padding: `calc(${wsUnit} / 2) ${wsUnit}` }}>
          <H1 css={{ fontFamily: fonts.medium }}>{encounter.getValue('location') || ''}</H1>
          <Div css={{ fontSize: '0.5em' }}>{encounter.getDoctorName(practitioners)}</Div>
        </Div>
      </QueueItem>
    );
  }
}

export default PatientQueueItem;
