import React from 'react';
import type { AnalyticsQueryResponseAggregateMetric, AnalyticsQueryResponseKeyTitle } from './../../types';
import Table from './../table/table';
import translate from './../../utils/i18n';

type Props = {
  data: { [key: string]: AnalyticsQueryResponseAggregateMetric },
  displayText: { [key: string]: AnalyticsQueryResponseKeyTitle },
  xAxis: string
};

/**
 * Returns the title for the key in the display text object
 * @param {string} key key to extract the title for
 * @param {Object} displayText The object that holds the title and an optional infotext
 * @returns {string} The title
*/
function getTitle(key, displayText) {
  if (key === '__truncate') {
    return 'Others';
  }
  return displayText[key] ? displayText[key].title : translate('unknown');
}

/**
 * Gets an array of columns for react-table.
 * @param {Props.data} data Props
 * @param {Props.displayText} displayText Props
 * @param {string} xAxis title of the left most col
 * @returns {Array<{Header: string, accessor: string}>} The list of data columns
*/
function getColumns(data, displayText, xAxis) {
  const columns = Object.keys(data).map(key => ({
    accessor: key,
    Header: getTitle(key, displayText),
  }));
  return [{ Header: xAxis, accessor: 'title__local' }, ...columns];
}

/**
 * Gets an array of rows for react-table.
 * @param {Props.data} data Props
 * @param {Props.displayText} displayText Props
 * @returns {Array} The list of data for row
*/
function getRows(data, displayText) {
  const colCategories = Object.keys(data);
  const rowCategories = Object.keys(data[colCategories[0]]);

  return rowCategories.map(rC => Object.assign(
    { title__local: getTitle(rC, displayText) },
    ...colCategories.map(k => ({ [k]: data[k][rC] })),
  ));
}

/**
 * Renders a dummy component that will simply prints a placeholder string
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function ReportCardTable(props: Props) {
  const {
    data,
    displayText,
    xAxis,
  } = props;

  const xAxisTitle = getTitle(xAxis, displayText);

  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  return (
    <Table
      data={getRows(data, displayText)}
      noDataText="No data for this report"
      columns={getColumns(data, displayText, xAxisTitle)}
    />
  );
}

export default ReportCardTable;
