import BaseModel from './baseModel';

export enum CampaignRuleType {
  ENCOUNTER_TYPE = 'encounter_type', // DEPRECATED
  ENCOUNTER_DOCTOR = 'doctor', // DEPRECATED
  DRUG_PRESCRIBED = 'drug_prescribed', // DEPRECATED
  ITEM_SOLD = 'item_sold', // DEPRECATED
  ENCOUNTER_TIME = 'encounter_time',
  PREV_PATIENT_JOB_ENCOUNTER_TIME = 'prev_patient_job_encounter_time',
  MASTER_DRUG_SOLD = 'master_drug_sold',
  SPECIFIC_MASTER_CAMPAIGN_JOB_TIME = 'specific_master_campaign_job_time',
  SPECIFIC_CAMPAIGN_JOB_TIME = 'specific_campaign_job_time',
  // DIAGNOSIS = 'diagnosis',
  // MASTER_DRUG_PRESCRIBED = 'master_drug_prescribed',
  // MASTER_DRUG_DISPENSED = 'master_drug_dispensed',
}

export type TimeUnit = 'day' | 'week' | 'month' | 'year';

enum Comparator {
  EQ = 'eq',
  LTE = 'lte',
  GTE = 'gte',
  BTW= 'btw',
}

interface CampaignScheduleAt {
  time_unit: TimeUnit;
  time_value: number;
}

interface CampaignTimeFilterEqCondition {
  encounter_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ;
}

interface CampaignTimeFilterBtwCondition {
  encounter_time_offset_max: CampaignScheduleAt;
  encounter_time_offset_min: CampaignScheduleAt;
  comparator: Comparator.BTW;
}

interface SpecificMasterCampaignJobTimeFilterCondition {
  master_campaign_id: string;
  job_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ;
}

interface SpecificCampaignJobTimeFilterCondition {
  campaign_id: string;
  job_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ;
}

interface CampaignPrevPatientJobEncounterTimeFilterCondition {
  encounter_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ | Comparator.GTE;
}

interface FilterCondition<T extends CampaignRuleType> {
  type: T;
  metadata: T extends
      | CampaignRuleType.ENCOUNTER_TIME
      ? (CampaignTimeFilterEqCondition | CampaignTimeFilterBtwCondition)
      : T extends CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME
      ? CampaignPrevPatientJobEncounterTimeFilterCondition
      : T extends CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME
      ? SpecificCampaignJobTimeFilterCondition
      : T extends CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME
      ? SpecificMasterCampaignJobTimeFilterCondition
      : string
}

export type CampaignRulesCondition =
  | FilterCondition<CampaignRuleType.ENCOUNTER_TYPE>
  | FilterCondition<CampaignRuleType.ENCOUNTER_DOCTOR>
  | FilterCondition<CampaignRuleType.ENCOUNTER_TIME>
  | FilterCondition<CampaignRuleType.ENCOUNTER_TYPE>
  | FilterCondition<CampaignRuleType.ITEM_SOLD>
  | FilterCondition<CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME>
  | FilterCondition<CampaignRuleType.DRUG_PRESCRIBED>
  | FilterCondition<CampaignRuleType.MASTER_DRUG_SOLD>
  | FilterCondition<CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME>
  | FilterCondition<CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME>

export type CampaignRules = {
  logical_op: 'or' | 'and';
  conditions: Array<CampaignRulesCondition>;
};

export type Attributes = {
  type: 'campaign_master';
  edited_by: Array<{ timestamp: number, user_id: string }>,
  filter: CampaignRules,
  message: string;
  max_frequency_per_patient?: number;
  is_active: boolean;
  name: string;
  description?: string;
  campaign_set_master_id: string;
  created_at: string;
  created_by: string;
  _id: string;
}

/**
 * SMSCampaignModel
 *
 * @namespace MasterCampaignModel
 */
class MasterCampaignModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'campaign_master';
  }

  /**
   * @return {string} status of the campaign
   */
  getStatus() {
    return this.get('is_active') ? 'active' : 'inactive';
  }

  /**
   * @return {boolean} is visible/deleted or not
   */
  isVisible() {
    return !this.get('hidden');
  }
}

export default MasterCampaignModel;
