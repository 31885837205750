import React from 'react';
import Glamorous, { Div } from 'glamorous';
import Moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';

import type { List } from 'immutable';
import CloseButton from './../buttons/closeButton';
import Input from './../inputs/input';
import Select from './../inputs/select';
import translate from './../../utils/i18n';
import { wsUnit, colours } from './../../utils/css';
import DatePicker from './../inputs/statefulDatepicker';
import PaymentTypeModel from './../../models/paymentTypeModel';

import type { MapValue, SaveModel } from './../../types';
import type PaymentModel from './../../models/paymentModel';

type Props = {
  payment: PaymentModel,
  updatePayment: (paymentId: string, changes: { [key: string]: MapValue }) => void,
  disabled: boolean,
  paymentTypes: List<PaymentTypeModel>,
  saveModel: SaveModel,
};

const PaymentLineInputContainer = Glamorous.div({
  display: 'flex',
  margin: `calc(${wsUnit} / 2)`,
  '& > div': {
    width: `calc(50% - (${wsUnit} / 4))`,
  },
  '& > div:first-child': {
    marginRight: `calc(${wsUnit} / 2)`,
  },
});

const PaymentLineInputGroupContainer = Glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100% !important',
  alignItems: 'center',
  borderBottom: `1px solid ${colours.grey3}`,
  padding: '20px 0px',
});

/**
 * A component that allows that contains a form that renders on a single line and allows for the
 * editing of a Payment.
 * @class PaymentLineInput
 * @extends {React.PureComponent<Props>}
 */
class PaymentLineInput extends React.PureComponent<Props> {
  static defaultProps = {
    showLabels: false,
  };

  /**
   * Updates the amount value.
   * @param {string} value The new input value
   * @returns {void}
   */
  onAmountChanged = (value: string) =>
    this.props.updatePayment(
      this.props.payment.get('_id'),
      { amount: isNaN(parseFloat(value)) ? value : parseFloat(value) },
    )

  /**
   * Updates the payment method value.
   * @param {string} method The new input value
   * @returns {void}
   */
  onPaymentMethodChanged = (method: string) => {
    this.updatePaymentTypesProp(method);
    this.props.updatePayment(this.props.payment.get('_id'), { method });
  }

  /**
   * Voids the payment
   * @returns {void}
   */
  onDeleteClicked = () =>
    this.props.updatePayment(this.props.payment.get('_id'), { is_void: true });

  /**
   * Checks the given method value against what exists in payment types props and add new value if necessary.
   * @param {string} newValue A new string from newly created payment method.
   * @returns {void}
   */
  updatePaymentTypesProp = (newValue: string) => {
    const existingValues = this.props.paymentTypes.map(paymentType => paymentType.get('name'));
    const isNewValueExist = existingValues
      .find(existingValue => existingValue.toLowerCase() === newValue.toLowerCase());
    if (!isNewValueExist) {
      this.props.saveModel(new PaymentTypeModel({ name: newValue }));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { payment, disabled } = this.props;
    const paymentTypes = this.props.paymentTypes.size ?
      this.props.paymentTypes
        .map(p => ({ label: p.get('name'), value: p.get('name') }))
        .toArray() : [];
    return (
      <PaymentLineInputContainer>
        <PaymentLineInputGroupContainer>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Input
                id={`amount-${payment.get('_id')}`}
                label={translate('amount')}
                value={parseFloat(parseFloat(payment.get('amount', '')).toFixed(2))}
                onValueChanged={this.onAmountChanged}
                required
                type="number"
                min={0.01}
                disabled={disabled || payment.hasBeenSaved()} // Disabled after finalisation, only void is allowed.
                step="any"
                divClassName="u-margin-right--half-ws"
              />
              <Select
                id={`method-${payment.get('_id')}`}
                label={translate('payment_type')}
                options={
                  payment.get('method', '').length && !paymentTypes.find(m => m.value === payment.get('method')) ?
                    paymentTypes.concat([{
                      value: payment.get('method'),
                      label: payment.get('method'),
                      disabled: true,
                    }]) : // Append the current value to select options if not in presets. Useful for legacy values.
                    paymentTypes
                }
                value={payment.get('method')}
                onValueChanged={this.onPaymentMethodChanged}
                required
                clearable={false}
                disabled={disabled || payment.hasBeenSaved()} // Disabled after finalisation, only void is allowed.
                selectClassName="Select--small"
                style={{ minWidth: 100 }}
                creatable
              />
            </div>
            <DatePicker
              id="paymentLineInputDatePicker"
              showClearDate={false}
              allowPast
              label={translate('date_received')}
              onValueChanged={value => this.props.updatePayment(payment.get('_id'), { timestamp: value ? value.valueOf() : (new Moment()).valueOf() })}
              value={payment.get('timestamp') ? Moment(payment.get('timestamp')) : new Moment()}
              style={{ minWidth: 240 }}
              disabled={disabled || payment.hasBeenSaved()} // Disabled after finalisation, only void is allowed.
              isOutsideRange={day => !isInclusivelyBeforeDay(day, Moment())}
            />
          </div>
          {
            !disabled &&
            <Div css={{ marginTop: '20px' }}>
              <CloseButton dataPublic onClick={this.onDeleteClicked} />
            </Div>
          }
        </PaymentLineInputGroupContainer>
      </PaymentLineInputContainer>
    );
  }
}

export default PaymentLineInput;
