import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PharmaConnectBrandPage from './../components/pharmaconnect/brandPage';
import { setPharmaConnectReadDocuments } from './../actions';

import type { State, Dispatch, ReactRouterProps } from './../types';
import type ReadDocumentsModel from '../models/pharmaconnect/readDocumentsModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({
    pharmaconnectViews,
  }: State, props: ReactRouterProps) => ({
    trackBrandEvent: props?.location?.trackEvent,
    brandDocuments: pharmaconnectViews.brandDocuments,
    readDocuments: pharmaconnectViews.readDocuments,
    brandID: props.match.params.brand_id,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPharmaConnectReadDocuments: (documents: ReadDocumentsModel) =>
    dispatch(setPharmaConnectReadDocuments(documents)),
});

const PharmaConnectBrandContainer =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PharmaConnectBrandPage));

export default PharmaConnectBrandContainer;
