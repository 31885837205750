/**
 * Safely get the current user name. If window is not available (i.e. on node), returns user as
 * 'TEST_ENV'.
 * @returns {string} The username.
 */
export function getUserName(): string | void {
  if (process.env.NODE_ENV === 'test') {
    return 'TEST_ENV';
  }
  if (window.getUserName && window.getUserName()) {
    return window.getUserName();
  }
  console.log('Cannot get username before user has logged in.');
  return undefined;
}
