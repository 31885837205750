import React from "react";
import AsyncSelect from "react-select/async";

import Label from './label';

import type { HTMLStyle, MapValue, SelectOpts } from './../../types';
import { reactSelectStyles } from "./select";
import { colours } from "./../../utils/css";

type Props = {
  closeOnSelect?: boolean,
  id: string,
  label: string,
  labelClassName?: string,
  value: MapValue,
  defaultOptions: SelectOpts | boolean,
  onChange: (newValues: MapValue) => void,
  required?: boolean,
  style?: HTMLStyle,
  disabled?: boolean,
  description?: string,
  isMulti?: boolean,
  isValueCaseInsensitive?: boolean,
  loadOptions: (value: string) => Promise<SelectOpts>,
  cacheOptions?: boolean,
  isClearable?: boolean,
  placeholder?: string,
};

/**
 * LoadingIndicatior
 * @namespace LoadingIndicator
 * @returns {React.Component} - A loading indicator component
 */
const LoadingIndicator = () => {
  return (
    <i className="fa fa-circle-o-notch fa-spin u-padding--half-ws" style={{ color: colours.blue }} aria-hidden="true"></i>
  );
};

/**
   * MultiSelect
   *
   * @namespace MultiSelect
   * @returns {React.Component} - A MultiSelect component.
   */
const SelectAsync = ({
  id, label, value, onChange, labelClassName,
  closeOnSelect = false, required = false, style = {}, disabled = false, description,
  isMulti, loadOptions, cacheOptions, isClearable, placeholder,
}: Props) => (
  <div style={style}>
    <Label
      className={labelClassName}
      id={id}
      label={label}
      invalid={required && (!value || value.length === 0)}
    />
    {
      description &&
      <p className="o-sub-label">{description}</p>
    }
    <AsyncSelect
      id={id}
      value={value}
      defaultOptions
      onChange={onChange}
      hideLabel
      blurInputOnSelect={closeOnSelect}
      className={required && (!value || value.length === 0) ? 'js-border-invalid' : undefined}
      disabled={disabled}
      isMulti={isMulti}
      components={{ LoadingIndicator }}
      loadOptions={loadOptions}
      cacheOptions={cacheOptions}
      isClearable={isClearable}
      styles={reactSelectStyles}
      placeholder={placeholder}
    />
  </div>
);

export default SelectAsync;
