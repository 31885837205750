import React from 'react';
import glamorous from 'glamorous';

type Props = {
  options: {
    color: string,
  }
};

const FlagBlock = glamorous.div({
  borderRadius: 10,
  display: 'inline-block',
  width: '100%',
  minWidth: 10,
  minHeight: 40,
},
({ color = 'red' }) => ({
  backgroundColor: color,
}));

/**
 * Renders Chip that can be used for showing tag or small information and can be remove.
 * @returns {React.Component} The rendered component
*/
const Flag = ({ options }: Props) => (
  <FlagBlock color={options.color} />
);

export default Flag;
