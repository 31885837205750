import React from 'react';
import glamorous from 'glamorous';

import Radio from '../inputs/radio';
import { queryMapping } from '../../utils/utils';
import DataQueryInput from './dataQueryInput';
import SaveButton from '../buttons/saveButton';

import type { queryMap } from '../../../src/types';
import translate from '../../utils/i18n';

const QueryMapWrapper = glamorous.div({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: '#e0dfdf',
  padding: '10px',
});

type State={
  selectedKey: string,
  fetchedData: Array<any>,
  isFetched: boolean,
  docIDToFetch: string,
}
/**
 * DataQuery
 * @namespace DataQuery
 */
class DataQuery extends React.Component<any, State> {
  /**
   * Creates an instance of DebugQuery.
   * @param {object} props The props for this component.
   */
  constructor(props: any) {
    super(props);
    this.state = {
      selectedKey: '',
      fetchedData: [],
      isFetched: false,
      docIDToFetch: '',
    };
    this.cancelDataQueryInput = this.cancelDataQueryInput.bind(this);
    this.handleFetchedData = this.handleFetchedData.bind(this);
  }


  /**
   * Called when user clicks cancle
   * @return {void}
   */
  cancelDataQueryInput() {
    this.setState({
      selectedKey: '',
      fetchedData: [],
      isFetched: false,
    });
  }

  /**
   * Called when user clicks save to fetch the data.
   * @param {string} fetchedData Fetched rows
   * @return {void}
   */
  handleFetchedData(fetchedData: Array<any>) {
    this.setState({
      fetchedData,
      isFetched: true,
    });
  }

  /**
    * Called when user clicks on specific ID to get the details of the doc.
    * @param {string} id ID of the individual document
    * @return {void}
  */
  onSaveClicked(id: string) {
    this.setState({ docIDToFetch: id });
    this.props.handleSearchDocument(id)
      .then(() => this.setState({ docIDToFetch: '' }))
  }

  /**
  * Render the query input
  * @return {React.ElementType}
  */
  renderChild() {
    const { isFetched, selectedKey,fetchedData } = this.state;
    const mapping = queryMapping.get(this.state.selectedKey);
    if (this.state.selectedKey === '') {
      return (
        <div className="o-form__column">
          <p className="u-strong">Result:</p>
          <div className="o-sidebar--right">
            <p className="o-title u-font-color-white">[  ]</p>
          </div>
        </div>
      );
    }
    if (isFetched && selectedKey) {
      return (
        <div className="o-form__column">
          <p className="u-strong">Result:</p>
          <div className="o-sidebar--right" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            {fetchedData.length ? fetchedData.map(row => (
              <SaveButton
                key={`${row.id}`}
                className="o-button--small o-button--padded"
                style={{ margin: '15px 25px 15px 25px' }}
                label={row.id}
                onClick={() => this.onSaveClicked(row.id)}
                savingLabel={this.state.docIDToFetch === row.id ? translate('fetching') : row.id}
                disabled={Boolean(this.state.docIDToFetch)}
                isSaving={this.state.docIDToFetch === row.id}
              />
            )) : <p className="o-title u-font-color-white">[  ]</p>}
          </div>
        </div>
      );
    } else if (mapping !== undefined) {
      return (
        <DataQueryInput
          selectedKey={selectedKey}
          queryMap={mapping}
          cancelDataQueryInput={this.cancelDataQueryInput}
          handleFetchedData={this.handleFetchedData}
        />
      );
    }
    return null;
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <section style={{ height: '100vh' }}>
        <header className="o-header">
          <h1>Debug Query</h1>
        </header>
        <div className="o-form">
          <div className="o-form__column-wrapper">
            <div className="o-form__column">
              <div className="o-card" style={{ margin: 'unset', maxHeight: '80vh', overflowY: 'auto' }}>
                <h2 className="o-card__title">Query Name</h2>
                {queryMapping.map((value: queryMap) => (
                  <QueryMapWrapper key={`${value.viewName}`}>
                    <Radio
                      id="flagged"
                      description={value.description}
                      label=""
                      options={[{ label: value.name, value: value.viewName }]}
                      value={this.state.selectedKey}
                      onValueChanged={(value) => this.setState({
                        selectedKey: value,
                        isFetched: false,
                      })}
                    />
                  </QueryMapWrapper>
                )).toList().toArray()}
              </div>
            </div>
            {this.renderChild()}
          </div>
        </div>
      </section>
    );
  }
}

export default DataQuery;
