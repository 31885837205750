import React from 'react';
import { Catalog } from 'catalog';

import Intro from './styleguideIntro';
import CodingStyle from './styleguideCodingStyle';
import Components from './styleguideComponents';
import Visuals from './styleguideVisuals';
import Content from './styleguideContent';
import Accessibility from './styleguideAccessibility';

/**
 * Styleguide component
 * @class Styleguide
 * @extends {React.PureComponent<Props>}
 */
const Styleguide = () =>
  <Catalog
    logoSrc="../../static/images/klinify_logo.png"
    title="Klinify Styleguide"
    styles={['/../../../stylesheets/postcss/components/styleguide.css']}
    pages={[
      {
        path: '/styleguide',
        title: 'Introduction',
        content: Intro,
      },
      {
        path: '/styleguide/accessiblity',
        title: 'Accessibility',
        content: Accessibility,
      },
      {
        path: '/styleguide/content',
        title: 'Content',
        content: Content,
      },
      {
        path: '/styleguide/visuals',
        title: 'Visuals',
        content: Visuals,
      },
      {
        path: '/styleguide/components',
        title: 'Components',
        content: Components,
      },
      {
        path: '/styleguide/coding-style',
        title: 'Coding Style',
        content: CodingStyle,
      },
    ]}
  />;

export default Styleguide;
