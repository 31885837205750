import React, { Fragment } from 'react';
import moment from 'moment';
import { List, Set } from 'immutable';
import glamorous from 'glamorous';

import Input from './../inputs/input';
import Select from './../inputs/select';
import Radio from './../inputs/radio';
import DatePicker from './../inputs/statefulDatepicker';
import SaveButton from './../buttons/saveButton';
import TextArea from './../inputs/textarea';
import CloseButton from './../buttons/closeButton';
import translate from './../../utils/i18n';
import { wsUnit } from './../../utils/css';
import ModalFooter from './../modals/modalFooter';
import FormError from './../formError';
import ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import ClaimReconciliationModel from './../../models/claimReconciliationModel';
import { createSuccessNotification } from './../../utils/notifications';
import { getAmountOutstanding } from './../../utils/claims';
import { convertNumberToPrice, roundMoneyForSave } from './../../utils/utils';

import type BankModel from './../../models/bankModel';
import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { SelectOption, SaveModels, Model } from './../../types';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import { getDefaultPaymentMethod } from '../../utils/billing';

/* eslint-disable camelcase */
type ClaimInvoicePaymentAttributes = {
  timestamp: number,
  amount: string,
  claim_invoice_id: string,
  is_void: boolean,
};

export type ClaimReconAttributes = {
  timestamp: number,
  amount: string,
  method: string,
  notes?: string,
  cheque_number?: string,
  coverage_payor_id: string,
  bank?: string,
  payments: Array<ClaimInvoicePaymentAttributes>,
};
/* eslint-enable camelcase */

type Props = {
  onSaveComplete: (models: Array<Model>) => void,
  coveragePayors: List<CoveragePayorModel>,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimInvoices: List<ClaimInvoiceModel>,
  banks: List<BankModel>,
  saveModels: SaveModels,
  singleInvoice?: boolean, // this indicates if the payment modal opened from single claim invoice page
  paymentTypes: List<PaymentTypeModel>,
};

type State = {
  isSaving: boolean,
  attributes: ClaimReconAttributes,
  errorMessage?: string,
  claimInvoice?: ClaimInvoiceModel,
  splitPaymentError: boolean,
  noBankSetError: boolean,
};

const SplitPaymentRow = glamorous.div({
  display: 'grid',
  gridColumnGap: `calc(${wsUnit} / 2)`,
  alignItems: 'center',
  gridTemplateColumns: '5fr 5fr 1fr',
});
/**
 * A form for the payment metatdata of a ClaimInvoice payment
 * @class RecordPanelPaymentForm
 * @extends {React.Component<Props, State>}
 */
class RecordPanelPaymentForm extends React.Component<Props, State> {
  /**
   * Creates an instance of RecordPanelPaymentForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = this.getDefaultState();
  }

  /**
   * Creates default initial state for RecordPanelPaymentForm.
   * @returns {State}
   */
  getDefaultState() {
    const { singleInvoice } = this.props;
    const claimInvoice = singleInvoice ? this.props.claimInvoices.first() : undefined;
    return {
      isSaving: false,
      attributes: {
        timestamp: new Date().valueOf(),
        amount: '',
        method: getDefaultPaymentMethod(this.props.paymentTypes, 'transfer'),
        coverage_payor_id: claimInvoice !== undefined ?
          claimInvoice.get('coverage_payor_id', '') : '',
        payments: singleInvoice ? [{
          amount: '',
          is_void: false,
          claim_invoice_id: claimInvoice !== undefined ?
            claimInvoice.get('_id') : '',
        }] : [],
      },
      singleInvoice,
      claimInvoice,
      errorMessage: undefined,
      splitPaymentError: false,
      noBankSetError: false,
    };
  }

  /**
   * returns the total amount if it is single payment, return empty otherwise, payment amount will be updated later.
   * @returns {string} amount for the invoice
   */
  getPaymentForInvoiceId(): string {
    if (this.state.attributes.payments && this.state.attributes.payments.length === 1) {
      return this.state.attributes.amount;
    }
    return '';
  }

  /**
   * returns the total amount calculating sum of all amounts in all payments.
   * @returns {number} total amount
   */
  getTotalPayment(): number {
    return List(this.state.attributes.payments)
      .reduce((total, p) => total + parseFloat(p.amount), 0);
  }

  /**
   * Checks if a valid claim invoice id for a specific month is selected,
   * returns true or false accordingly
   * @returns {boolean}
   */
  isClaimInvoiceSelected(): boolean {
    return this.state.attributes.payments && this.state.attributes.payments.length > 0 &&
    this.state.attributes.payments.every(p => p.claim_invoice_id);
  }

  /**
   * Validates the payment form by checking the following:
   * - Amount and method fields have values
   * - Amount is valid
   * - bank is mentioned for bank transfer, cheque number is entered for cheque payment.
   * - if there is no bank set from settings
   * - if sum of all payments > total amount
   * @returns {boolean}
   */
  isValid(): boolean {
    this.setState({ errorMessage: undefined, noBankSetError: false, splitPaymentError: false });
    if (
      !this.state.attributes.amount ||
      !this.state.attributes.method ||
      !this.state.attributes.coverage_payor_id || !this.isClaimInvoiceSelected()
    ) {
      this.setState({ errorMessage: 'fill_required_fields' });
      return false;
    }
    if (
      isNaN(parseFloat(this.state.attributes.amount)) ||
      (this.state.attributes.payments.length > 1 &&
      this.state.attributes.payments.find(p => isNaN(parseFloat(p.amount))))
    ) {
      this.setState({ errorMessage: 'amount_entered_is_invalid' });
      return false;
    }
    if (this.getTotalPayment() > parseFloat(this.state.attributes.amount)) {
      this.setState({ splitPaymentError: true });
      return false;
    }
    if (this.getTotalPayment() < parseFloat(this.state.attributes.amount)) {
      this.setState({ splitPaymentError: true });
      return false;
    }
    const method = this.state.attributes.method.trim().toLowerCase();
    if (
      method === 'transfer' &&
      (!this.props.banks || this.props.banks.size === 0)
    ) {
      this.setState({ noBankSetError: true });
      return false;
    }
    if (
      method === 'transfer' && !this.state.attributes.bank
    ) {
      this.setState({ errorMessage: 'for_bank_transfer_bank_name_must_be_filled' });
      return false;
    }
    if (
      method === 'cheque' && !this.state.attributes.cheque_number
    ) {
      this.setState({ errorMessage: 'cheque_number_must_be_filled_for_cheque_payment' });
      return false;
    }
    return true;
  }

  /**
   * Creates new claim recon and payment documents and saved them
   * @returns {void}
   */
  OnSavePayment(): void {
    // if valid form, save payments
    if (this.isValid()) {
      const { attributes } = this.state;
      this.setState({ isSaving: true });
      const { timestamp, amount, notes, bank } = attributes;
      const method = this.state.attributes.method.trim().toLowerCase();
      // claim recon
      const claimRecon = new ClaimReconciliationModel({
        timestamp,
        amount: roundMoneyForSave(amount, 0),
        method,
        notes,
        cheque_number: method === 'cheque' ? attributes.cheque_number : undefined,
        coverage_payor_id: attributes.coverage_payor_id,
        bank: method === 'transfer' ? bank : undefined,
      });
      if (attributes.payments && attributes.payments.length) {
        const payments = attributes.payments.map(payment => new ClaimInvoicePaymentModel({
          timestamp, // use the timestamp from the claimrecon model as it will be updated to match the datepicker
          amount: roundMoneyForSave(payment.amount ? payment.amount : claimRecon.get('amount'), 0),
          claim_invoice_id: payment.claim_invoice_id,
          is_void: payment.is_void,
          claim_recon_id: claimRecon.get('_id'),
        }));
        this.props.saveModels(payments.concat([claimRecon]))
          .then((models) => {
            createSuccessNotification(translate('claim_invoice_payment_saved'));
            this.props.onSaveComplete(models);
          });
      }
    }
  }

  /**
   * sets payments array in state
   * @param {Array<ClaimInvoicePaymentAttributes>} payments payments to set
   * @returns {void}
   */
  setPayments(payments: Array<ClaimInvoicePaymentAttributes>): void {
    this.setState({
      attributes: Object.assign({}, this.state.attributes, {
        payments,
      }),
    });
  }

  /**
   * handles invoice month dropdown change
   * @param {string} claimInvoiceId invoice id
   * @param {string} oldInvoiceId invoice id of previous selection, in case if dropdown is modified for more than once
   * @returns {void}
   */
  onMonthChange(claimInvoiceId: string, oldInvoiceId?: string): void {
    const amount = this.props.singleInvoice ?
      this.state.attributes.amount : this.getPaymentForInvoiceId();
    if ((this.state.attributes.payments && this.state.attributes.payments.length === 0) ||
      (this.state.attributes.payments && this.state.attributes.payments.length === 1)) {
      this.setPayments([{
        amount,
        timestamp: new Date().valueOf(),
        is_void: false,
        claim_invoice_id: claimInvoiceId,
      }]);
    } else if (this.state.attributes.payments && oldInvoiceId) {
      this.setPayments(this.state.attributes.payments.map((payment) => {
        if (payment.claim_invoice_id === oldInvoiceId) {
          const newPayment = {
            amount,
            timestamp: new Date().valueOf(),
            is_void: false,
            claim_invoice_id: claimInvoiceId,
          };
          return newPayment;
        }
        return payment;
      }));
    }
  }

  /**
   * handles deleting a payment
   * @param {ClaimInvoicePaymentAttributes} payment payment which is changed
   * @returns {void}
   */
  onDelete(payment: ClaimInvoicePaymentAttributes): void {
    if (this.state.attributes.payments && payment) {
      const updated = this.state.attributes.payments.findIndex(p =>
        JSON.stringify(p) === JSON.stringify(payment));
      const payments = this.state.attributes.payments.map(p => p);
      payments.splice(updated, 1);
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          payments,
        }),
      });
    }
  }

  /**
   * handles invoice month dropdown change after payment is split
   * @param {string} claimInvoiceId invoice id
   * @param {ClaimInvoicePaymentAttributes} payment payment which is changed
   * @returns {void}
   */
  onMonthChangeSplitPayment(claimInvoiceId: string, payment: ClaimInvoicePaymentAttributes): void {
    if (this.state.attributes.payments && payment) {
      const updated = this.state.attributes.payments.findIndex(p =>
        JSON.stringify(p) === JSON.stringify(payment));
      const payments = this.state.attributes.payments.map(p => p);
      const amount = this.props.singleInvoice ?
        this.state.attributes.amount : this.getPaymentForInvoiceId();
      payments[updated] = {
        amount,
        timestamp: new Date().valueOf(),
        is_void: false,
        claim_invoice_id: claimInvoiceId,
      };
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          payments,
        }),
      });
    }
  }

  /**
   * Updates the fields for payment whenever month dropdown is changed.also creates new doc on first value change
   * @param {ClaimInvoicePaymentAttributes} payment payment which is changed
   * @param {string} claimInvoiceId invoice id
   * @param {string} amount changed amount
   * @returns {void}
   */
  updatePayments(payment: ClaimInvoicePaymentAttributes,
    claimInvoiceId: string,
    amount: string): void {
    if (this.state.attributes.payments && this.state.attributes.payments.length && payment) {
      const updated = this.state.attributes.payments.findIndex(p =>
        JSON.stringify(p) === JSON.stringify(payment));
      const payments = this.state.attributes.payments.map(p => p);
      payments[updated] = {
        amount,
        timestamp: new Date().valueOf(),
        is_void: false,
        claim_invoice_id: claimInvoiceId,
      };
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          payments,
        }),
      });
    }
  }

  /**
   * checks if there is an outstanding payable amount for an invoice.
   * @param {ClaimInvoiceModel} invoice The claim invoice doc
   * @returns {boolean}
   */
  isPaymentOustandingForInvoice(invoice: ClaimInvoiceModel): boolean {
    const invoicePayments = this.props.claimInvoicePayments.filter(payment => payment.get('claim_invoice_id') === invoice.get('_id') && !payment.isVoid());
    return (invoice.get('amount', 0) - invoicePayments.reduce((total, p) => parseFloat(total) + parseFloat(p.get('amount', 0)), 0)) > 0;
  }

  /**
 * Gets an array of unique months for dropdown, also adds outstanding amount for each label
 * @param {List<ClaimInvoiceModel>} invoices The available invoices
 * @returns {Array<{ value: string, label: string}>}
 */
  getSortedUniqueMonths(invoices: List<ClaimInvoiceModel>): Array<SelectOption> {
    const months = invoices.filter(i =>
      i.get('coverage_payor_id') === this.state.attributes.coverage_payor_id &&
      this.isPaymentOustandingForInvoice(i))
      .map(invoice =>
        ({
          dateValue: `${invoice.get('items').month.month}_${invoice.get('items').month.year}`,
          label: moment({
            month: invoice.get('items').month.month - 1,
            year: invoice.get('items').month.year,
            day: 1,
          }).format('MMM YYYY'),
          value: invoice.get('_id'),
          outstanding: getAmountOutstanding(invoice, this.props.claimInvoicePayments),
        }));
    const uniqueMonths = Set(List(months));
    return uniqueMonths.sort((a, b) => {
      const newA = a.dateValue.split('_');
      const newB = b.dateValue.split('_');
      return new Date(Number(newA[1]), Number(newA[0]), 1) -
      new Date(Number(newB[1]), Number(newB[0]), 1);
    }).map(monthData => ({
      label: `${monthData.label} (${monthData.outstanding} Outstanding)`,
      value: monthData.value,
    })).toArray();
  }

  /**
   * handles split payment, create two new payments with default data to be filled in the modal
   * @returns {void}
   */
  splitPayment(): void {
    if (this.state.attributes.payments && this.state.attributes.payments.length < 2) {
      const payments = [{
        amount: '',
        timestamp: new Date().valueOf(),
        is_void: false,
        claim_invoice_id: '',
      },
      {
        amount: '',
        timestamp: new Date().valueOf(),
        is_void: false,
        claim_invoice_id: '',
      }];
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          payments,
        }),
      });
    } else if (this.state.attributes.payments && this.state.attributes.payments.length >= 2) {
      const payments = this.state.attributes.payments.map(p => p);
      payments.push({
        amount: '',
        timestamp: new Date().valueOf(),
        is_void: false,
        claim_invoice_id: '',
      });
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          payments,
        }),
      });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const invoiceID = this.state.claimInvoice ?
      this.state.claimInvoice.get('_id', '') : '';
    const firstInvoiceId = this.state.attributes.payments &&
     this.state.attributes.payments.length === 1 &&
    this.state.attributes.payments[0] ? this.state.attributes.payments[0].claim_invoice_id : '';
    const paymentTypes = this.props.paymentTypes && this.props.paymentTypes.size ?
      this.props.paymentTypes
        .map(p => ({ label: translate(p.get('name')), value: p.get('name') }))
        .toArray() : [];
    const method = this.state.attributes.method.trim().toLowerCase();
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <FormError isSticky>{translate(this.state.errorMessage)}</FormError>
          }
          {
            this.state.noBankSetError &&
            <FormError isSticky>{translate('no_bank_has_been_set')}<a className="o-text-button o-text-button--link" href="#/settings/banks">{` ${translate('add_bank')}`}</a></FormError>
          }
          <Select
            label={translate('payment_from')}
            id="payment-from-panel"
            onValueChanged={(value: string) => this.setState({
              attributes: Object.assign({}, this.state.attributes, {
                coverage_payor_id: value,
              }),
            })}
            options={
              this.props.coveragePayors
                .map(c => ({
                  label: c.get('name'),
                  value: c.get('_id'),
                }))
                .toArray()
            }
            value={this.state.attributes.coverage_payor_id}
            disabled={this.state.isSaving || this.props.singleInvoice}
            required
          />
          <DatePicker
            id="claim_invoice_payment_date"
            label={translate('date_of_payment')}
            value={moment(this.state.attributes.timestamp)}
            onValueChanged={
              (momentObj: moment) => this.setState({
                attributes: Object.assign({}, this.state.attributes, {
                  timestamp: momentObj.valueOf(),
                }),
              })
            }
            required
            disabled={this.state.isSaving}
            allowPast
            style={{ marginBottom: wsUnit }}
            showClearDate={false}
          />
          <Input
            id="claim_invoice_payment_amount"
            label={translate('total_amount_received')}
            value={
                this.state.attributes.amount}
            onValueChanged={
              (amount: string) => {
                this.setState({
                  attributes: Object.assign({}, this.state.attributes, { amount }),
                });
              }
            }
            required
            type="number"
            min={0}
            step={0.01}
            disabled={this.state.isSaving}
          />
          <Radio
            id="invoice_payment_type"
            label={translate('payment_type')}
            value={this.state.attributes.method}
            onValueChanged={
              (method: string) => {
                this.setState({
                  attributes: Object.assign({}, this.state.attributes, { method }),
                });
              }
            }
            options={[...paymentTypes]}
            required
          />
          {
            method && method === 'transfer' &&
            <Select
              label={translate('bank')}
              id="payment-from-panel"
              onValueChanged={(bank: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { bank }),
              })}
              options={
              this.props.banks
                .map(b => ({
                  label: b.get('name', ''),
                  value: b.get('name', ''),
                })).toArray()
            }
              value={this.state.attributes.bank}
              disabled={this.state.isSaving}
              required
            />
          }
          {
            method && method === 'cheque' &&
            <Input
              id="claim_invoice_payment_cheque"
              label={translate('cheque_number')}
              value={
                this.state.attributes.cheque_number}
              onValueChanged={
              (chequeNumber: string) => {
                this.setState({
                  attributes: Object.assign({}, this.state.attributes, {
                    cheque_number: chequeNumber,
                  }),
                });
              }
            }
              required
              disabled={this.state.isSaving}
            />
          }
          <hr />
          {this.props.singleInvoice && <Select
            label={translate('invoice_month')}
            id="claim-invoice-month"
            options={this.getSortedUniqueMonths(this.props.claimInvoices)}
            value={invoiceID}
            onValueChanged={(value: string) => this.onMonthChange(value)}
            disabled={this.props.singleInvoice}
            required // always disabled
          />}
          { !this.props.singleInvoice && this.state.attributes.payments &&
            this.state.attributes.payments.length < 2 &&
            <SplitPaymentRow>
              <Select
                label={translate('invoice_month')}
                id="claim-invoice-month"
                options={this.state.attributes.coverage_payor_id ?
                  this.getSortedUniqueMonths(this.props.claimInvoices) : []}
                value={firstInvoiceId}
                onValueChanged={(value: string) => this.onMonthChange(value, firstInvoiceId)}
                disabled={this.state.isSaving}
                noResultsText={translate('no_outstanding_invoices')}
                required
              />
            </SplitPaymentRow>
          }
          { !this.props.singleInvoice && this.state.attributes.payments &&
            this.state.attributes.payments.length > 1 &&
            this.state.attributes.payments.map(payment => (
              <SplitPaymentRow>
                <Select
                  label={translate('invoice_month')}
                  id="claim-invoice-month"
                  options={this.state.attributes.coverage_payor_id ?
                    this.getSortedUniqueMonths(this.props.claimInvoices) : []}
                  value={payment.claim_invoice_id}
                  onValueChanged={(value: string) => this.onMonthChangeSplitPayment(value, payment)}
                  disabled={this.state.isSaving}
                  required
                />
                <Input
                  id="payment_amount"
                  label={translate('amount')}
                  value={payment.amount}
                  onValueChanged={
                (amount: number) => this.updatePayments(payment, payment.claim_invoice_id, amount)
              }
                  required
                  type="number"
                  min={0}
                  step={0.01}
                  disabled={this.state.isSaving}
                />
                <CloseButton
                  className="o-text-button"
                  onClick={() => this.onDelete(payment)}
                  dataPublic
                />
              </SplitPaymentRow>))
          }
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            label={translate('split_this_payment')}
            onClick={() => this.splitPayment()}
            disabled={this.state.isSaving || this.props.singleInvoice}
          />
          {
            this.state.splitPaymentError &&
            <br />
          }
          {
            this.state.splitPaymentError &&
            <FormError>{translate('split_payment_match_total_amount', { amount: convertNumberToPrice(this.state.attributes.amount) })}</FormError>
          }
          <hr />
          <TextArea
            id="claim_invoice_payment_notes"
            label={translate('notes')}
            value={this.state.attributes.notes || ''}
            onValueChanged={
              (notes: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { notes }),
              })
            }
            disabled={this.state.isSaving}
          />
        </div>
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('save_payment')}
            onClick={() => this.OnSavePayment()}
            disabled={this.state.isSaving}
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default RecordPanelPaymentForm;
