import React, { useState } from 'react';
import type { List, Map } from 'immutable';

import StatelessModal from '../modals/statelessModal';
import Button from '../buttons/button';
import NonDrugMapping from '../inventory/nonDrugMapping';
import InventoryMappingTable from '../inventory//inventoryMappingTable';
import AddDetailForm from '../inventory//addDetailsForm';
import translate from './../../utils/i18n';

import MasterDrugModel from '../../models/masterDrugModel';
import type { Attributes as DrugSuggestionAttributes } from '../../models/drugSuggestionModel';
import type InventoryMapModel from '../../models/inventoryMapModel';
import type DrugModel from '../../models/drugModel';
import type { Config, MapValue } from '../../types';
import type DrugManufacturerModel from '../../models/drugManufacturerModel';


type Props = {
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drug?: DrugModel,
  fetchTableMasterData: (ids: List<string>) => Promise<void>,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
  updateMapping: (data: Partial<DrugSuggestionAttributes> | void,
    event: 'confirm_drug' | 'reject_drug' | 'add_drug_details' | 'add_non_drug_details',
    formData: {
      itemName?: string,
      itemDispensationUnit?: string,
    }) => void,
  visible: boolean,
  setDrugMappingVisible: (visible: boolean) => void,
  setDrugManufacturers: (models: List<DrugManufacturerModel>) => void,
  drugManufacturers: List<DrugManufacturerModel>,
}

/**
 * Component to map the new drug to MDL
 * @returns {React.SFC}
 */
const DrugMapping = ({
  config,
  updateConfigValue,
  updateConfig,
  drug,
  masterDrugModelsMap,
  fetchTableMasterData,
  updateMapping,
  visible,
  setDrugMappingVisible,
  setDrugManufacturers,
  drugManufacturers,
}: Props) => {
  const [nonDrugMappingVisible, setNonDrugMappingVisible] = useState(false);
  const [showAddDetailForm, setShowAddDetailForm] = useState(false);
  const [savePromptVisible, setSavePromptVisible] = useState(false);

  /**
   * @desc Calls updateMapping with data needs to update and initial value to be filled in drugForm
   * @param {void} _ inventoryItem passed to the Child components. Will be undefined here
   * @param {Object} data params to call suggestion api.
   * @param {string} event add_drug_details
   * @returns {Promise<void>}
   */
  const onUpdateDrugSuggestion = (_: ?InventoryMapModel, data: Partial<DrugSuggestionAttributes>, event: 'add_drug_details') =>
    new Promise((resolve) => {
      const initialFormData = {
        itemName: data.name,
        itemDispensationUnit: data.dispensation_unit,
      };
      updateMapping(data, event, initialFormData);
      resolve();
      setDrugMappingVisible(true);
    });

  /**
   * @desc Calls updateMapping with data needs to update and initial value to be filled in drugForm
   * @param {void} _ inventoryItem passed to the Child components. Will be undefined here
   * @param {MasterDrugModel | void} data will be MasterDrugModel for confirm_drug event and void for reject_drug
   * @param {string} event 'confirm_drug' | 'reject_drug'
   * @returns {Promise<void>}
   */
  const onUpdateMapping = (_: ?InventoryMapModel, data: Partial<DrugSuggestionAttributes> | void, event: 'confirm_drug' | 'reject_drug') =>
    new Promise((resolve) => {
      const initialFormData = {
        itemName: data && data.name,
      };
      updateMapping(data, event, initialFormData);
      resolve();
      setDrugMappingVisible(true);
    });

  /**
   * @desc Calls updateMapping with data needs to update and initial value to be filled in drugForm
   * @param {void} _ inventoryItem passed to the Child components. Will be undefined here
   * @param {Object} data { master_drug_id: string, name: string }
   * @param {string} event Update event
   * @returns {Promise<void>}
   */
  const onUpdateNonDrugType = (_: ?InventoryMapModel, data: Partial<DrugSuggestionAttributes>, event: 'add_non_drug_details') =>
    new Promise((resolve) => {
      const initialFormData = {
        itemName: data.name,
      };
      updateMapping(data, event, initialFormData);
      resolve();
      setDrugMappingVisible(true);
    });


  /**
   * @returns {void}
   */
  const onModalClose = () => {
    if (showAddDetailForm && !savePromptVisible) {
      // Used for showing unsaved data prompt in addDetailsForm
      setSavePromptVisible(true);
    } else {
      setDrugMappingVisible(false);
    }
  };

  const drugMapper = (
    <>
      <div className="u-margin--standard">
        <Button
          className="o-button o-button--small u-full-width"
          onClick={() => setNonDrugMappingVisible(true)}
        >
          {translate('not_a_drug')}
        </Button>
      </div>
      <InventoryMappingTable
        isManualMapping
        updateConfigValue={updateConfigValue}
        config={config}
        updateConfig={updateConfig}
        masterDrugModelsMap={masterDrugModelsMap}
        drug={drug}
        setShowAddDetailForm={setShowAddDetailForm}
        updateInventoryItem={onUpdateMapping}
        fetchTableMasterData={fetchTableMasterData}
      />
    </>
  );

  const addDetailsForm = (
    <AddDetailForm
      drug={drug}
      savePromptVisible={savePromptVisible}
      setSavePromptVisible={setSavePromptVisible}
      onClose={setShowAddDetailForm}
      updateInventoryItem={onUpdateDrugSuggestion}
      setDrugManufacturers={setDrugManufacturers}
      drugManufacturers={drugManufacturers}
    />
  );

  return (
    <>
      <StatelessModal
        id="drug_mapping"
        title={showAddDetailForm ? translate('add_details') : translate('drug_mapping')}
        setVisible={() => {}}
        visible={visible}
        onClose={onModalClose}
        noButton
        large={!showAddDetailForm}
      >
        {showAddDetailForm ? addDetailsForm : drugMapper}
      </StatelessModal>
      {nonDrugMappingVisible && <NonDrugMapping
        selectedDrug={drug}
        updateInventoryItem={onUpdateNonDrugType}
        hideModal={() => setNonDrugMappingVisible(false)}
        visible={nonDrugMappingVisible}
      />}
    </>
  );
};

export default DrugMapping;
