import React from 'react';
import moment from 'moment';
import { Map, List, is, isList } from 'immutable';

import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import Select from './../inputs/select';
import { UNICODE } from './../../constants';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import Input from './../inputs/input';
import DatePicker from './../inputs/statefulDatepicker';
import TransactionModel from './../../models/transactionModel';
import SupplyModel from './../../models/supplyModel';
import SupplyItemModel from './../../models/supplyItemModel';
import CloseButton from './../buttons/closeButton';
import Button from './../buttons/button';
import BatchSelectionModal from './adjustBatchQuantity/batchSelectionModal';
import AdjustBatchForm from './adjustBatchQuantity/adjustBatchForm';
import { ActionMode } from './stockAdjustmentActions';

import type DrugModel from './../../models/drugModel';
import type SupplierModel from './../../models/supplierModel';
import type BaseModel from './../../models/baseModel';
import type { SaveModel, Config, MapValue, CountPerSKUAndBatch, SaveModels } from './../../types';
import UpdateBatchDetailsForm from './updateBatchDetailsForm';


type Props = {
  drug?: DrugModel,
  supplyItems: List<SupplyItemModel>,
  action: string,
  drugs: List<DrugModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  suppliers: List<SupplierModel>,
  isVisible: boolean,
    isFetching: boolean,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  inventoryCount: CountPerSKUAndBatch,
  config: Config,
  onClose: (isFromClose?: boolean) => void,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  supplyItem?: SupplyItemModel,
  isFromStockTake: boolean,
  onSave: (supplyItemID: string, skuID: string,
    models: [TransactionModel, SupplyItemModel | null | undefined, SupplyModel | null | undefined],
    stockFormData: MapValue) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  stockFormActionData: MapValue,
  supplies: List<SupplyModel>,
};

type State = {
  selectedSupplyItem?: SupplyItemModel,
  selectedDrug?: DrugModel,
  bulkAddedLineItems: Map<
    string,
    List<TransactionModel | SupplyItemModel | SupplyModel | null | undefined>
  >;
  supplyItems: List<SupplyItemModel>,
  inventoryCount: CountPerSKUAndBatch,
  currentSelectedMode: ModalModes,
  isSaving: boolean,
};

// Entry can happen via any of the modes
// It can exit directly using a callback
// If isBulk is true, the flow starts from bulkLineItem
// If isFromStockTake is true, the flow just does supplyItemChange
// Otherwise, the flow goes from skuSupplyItems -> supplyItemChange
export enum ModalModes {
  // Displays line items and allows adding a new adjustment line item
  // Can save the set of line items after all have been added, or cancel to go back.
  bulkLineItem,
  // Displays batches by drug and allows selecting a batch
  // Can cancel to go back or pass the selected batch to supplyItemChange
  skuSupplyItems,
  // Allows adding adjustment to the selected batch.
  // Can either add it to the bulkLineItem, exit using a saveCallback or cancel to go back
  supplyItemChange,
}

/**
 * @param {ModalModes} mode ModalModes
 * @returns {ModalModes}
 */
const getPreviousMode = (mode: ModalModes) => mode - 1;

/**
 * @param {ModalModes} mode ModalModes
 * @returns {ModalModes}
 */
const getNextMode = (mode: ModalModes) => mode + 1;

// eslint-disable-next-line valid-jsdoc
/**
 * A component containing a form for adding a Supply.
 * @class AdjustBatchQuantityModal
 * @extends {React.Component}
 */
class AdjustBatchQuantityModal extends React.Component<Props, State> {
  static defaultProps = {
    isFromStockTake: false,
    onSave: () => {},
    stockFormActionData: {
      notes: '',
      quantity: null,
    },
  };


  intialModalMode: ModalModes;

  isBulk: boolean;

  /**
   * Creates an instance of AdjustBatchQuantityModal.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.intialModalMode = this.getInitialMode();
    this.state = {
      selectedSupplyItem: undefined,
      bulkAddedLineItems: Map(),
      selectedDrug: this.getSelectedDrug(),
      supplyItems: props.supplyItems || List(),
      inventoryCount: props.inventoryCount || Map(),
      currentSelectedMode: this.intialModalMode,
      isSaving: false,
    };
    this.onBulkAdjustmentAddClicked = this.onBulkAdjustmentAddClicked.bind(this);
  }

  /**
   * @returns {ModalModes}
   */
  getInitialMode() {
    if (this.props.isFromStockTake || this.props.supplyItem) {
      return ModalModes.supplyItemChange;
    }
    if (this.props.drug) {
      return ModalModes.skuSupplyItems;
    }
    return ModalModes.bulkLineItem;
  }

  /**
   * Returns a DrugModel and depend on the prop drug or supply item value
   * @returns {DrugModel | void} drug model which needs to be set on initialization
   */
  getSelectedDrug() {
    const { supplyItem, drug, drugs } = this.props;
    if (drug) {
      return drug;
    }
    if (supplyItem) {
      return drugs.find(d => d.get('_id') === supplyItem.get('sku_id'));
    }
    return undefined;
  }

  /**
   * Set iventoryCount and supplyItems to the state
   * @returns {void}
   */
  updateCountAndSupply() {
    this.setState({
      inventoryCount: this.props.inventoryCount,
      supplyItems: this.props.supplyItems,
    });
  }

  /**
   * Triggers a batch data sync status check when the inventory count changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (
      !is(this.props.inventoryCount, prevProps.inventoryCount) ||
      !is(this.props.supplyItems, prevProps.supplyItems)
    ) {
      this.updateCountAndSupply();
    }
  }

  /**
   * Handles action button when clicked
   * @param {SupplyItemModel} selectedSupplyItem SupplyitemModel
   * @returns {void}
   */
  onActionButtonClicked(selectedSupplyItem?: SupplyItemModel) {
    this.setState({
      ...selectedSupplyItem && { selectedSupplyItem },
      currentSelectedMode: getNextMode(this.state.currentSelectedMode),
    });
  }

  /**
   * Handle changing of SKU
   * @param {string} value Value from the select menu
   * @returns {void}
   */
  onChangeSKU(value: string) {
    const drug = this.props.drugs.find(d => d.get('_id') === value);
    if (drug) {
      this.setState({ selectedDrug: drug });
    }
  }

  /**
   * Handle deleteing line item after save
   * @param {string} id transaction id which to delete
   * @returns {void}
   */
  onDeleteLineItem(id: string) {
    const models:
      | List<BaseModel | null | undefined>
      | undefined = this.state.bulkAddedLineItems.get(id);
    const isBulk = this.intialModalMode === ModalModes.bulkLineItem;
    if (isBulk && models && models.size) {
      this.onRemoveModelsToSave(models.filter((m): m is BaseModel => !!m));
    }
  }

  /**
   * Get supplyItem
   * @returns {SupplyItemModel}
   */
  getSelectedSupplyItem() {
    return this.state.selectedSupplyItem || this.props.supplyItem;
  }


  /**
   * Reset the state
   * @returns {void}
   */
  resetState() {
    this.setState({
      selectedSupplyItem: undefined,
      currentSelectedMode: this.intialModalMode,
      selectedDrug: undefined,
    });
  }

  /**
   * Append with current models which needs to be save on click of add on bulk adjustment
   * @param {List<BaseModel>} models models
   * @returns {void}
   */
  onAddModelsToSave(models: List<TransactionModel | SupplyItemModel | SupplyModel>) {
    const { inventoryCount } = this.state;
    const transaction = models.find(m => m.get('type') === 'transaction');
    const selectedSupplyItem = this.getSelectedSupplyItem();
    if (selectedSupplyItem) {
      const quantity = inventoryCount.getIn([
        selectedSupplyItem.get('sku_id'),
        'batches',
        selectedSupplyItem.get('_id'),
        'batchStockRemaining',
      ], 0);
      const remainingQuantity = transaction && quantity > 0 ? (quantity + transaction.get('change')) : 0;
      this.setState(prevState => ({
        bulkAddedLineItems: transaction ?
          prevState.bulkAddedLineItems.set(transaction.get('_id'), models) : prevState.bulkAddedLineItems,
        inventoryCount: inventoryCount.setIn([
          selectedSupplyItem.get('sku_id'),
          'batches',
          selectedSupplyItem.get('_id'),
          'batchStockRemaining',
        ], remainingQuantity),
      }));
    }
  }

  /**
   * Remove from current models which needs to be save on click of remove from line items
   * @param {List<BaseModel>} models models
   * @returns {void}
   */
  onRemoveModelsToSave(models: List<BaseModel>) {
    const { inventoryCount, supplyItems } = this.state;
    const transaction = models.find(m => m.get('type') === 'transaction');
    const supplyItem = transaction && supplyItems.find(s => s.get('_id') === transaction.get('supply_item_id'));
    if (supplyItem) {
      const quantity = inventoryCount.getIn([
        supplyItem.get('sku_id'),
        'batches',
        supplyItem.get('_id'),
        'batchStockRemaining',
      ], 0);
      const remainingQuantity = transaction ? quantity - transaction.get('change') : quantity;
      this.setState(prevState => ({
        inventoryCount: inventoryCount.setIn([
          supplyItem.get('sku_id'),
          'batches',
          supplyItem.get('_id'),
          'batchStockRemaining',
        ], remainingQuantity),
        bulkAddedLineItems: transaction ? prevState.bulkAddedLineItems.delete(transaction.get('_id')) : prevState.bulkAddedLineItems,
      }));
    }
  }

  /**
   * Handles saving bulk action form. This will create new transactions doc.
   * @returns {void}
   */
  onBulkAdjustmentSaveClicked = () => {
    const stateModels = this.state.bulkAddedLineItems.toList().flatten().toArray();
    this.setState({ isSaving: true });
    this.props.saveModels(stateModels)
      .then((models) => {
        const transactions: TransactionModel[] = models.filter(m => m.get('type') === 'transaction');
        const inventoryCountMappedList = transactions.reduce((all, t) => (
          all.update(t.get('sku_id'), (val = 0) => (val + t.get('change')))), Map<string, number>()).toArray();
        if (inventoryCountMappedList.length) {
          Promise.all(inventoryCountMappedList.map((t:[string, number]) =>
            this.props.updateInventoryItemCount(t[0], t[1])))
            .then(() => this.props.onClose(false));
        }
      });
  }

  onBulkAdjustmentAddClicked = (supplyItemID: string | undefined, skuID: string | undefined,
    models: [TransactionModel, SupplyItemModel, SupplyModel]) => {
    this.onAddModelsToSave(List(models));
    this.resetState();
  }


  /**
   * Handle closing of modal
   * @returns {void}
   */
  handleCloseModal() {
    const isBulk = this.intialModalMode === ModalModes.bulkLineItem;
    const { currentSelectedMode, bulkAddedLineItems } = this.state;
    this.setState({
      currentSelectedMode:
        currentSelectedMode <= ModalModes.skuSupplyItems
          ? this.intialModalMode
          : getPreviousMode(currentSelectedMode),
    });
    if (
      isBulk &&
      currentSelectedMode === ModalModes.skuSupplyItems &&
      bulkAddedLineItems.size === 0
    ) {
      this.props.onClose(true);
      return;
    }
    if (currentSelectedMode === this.intialModalMode) {
      this.props.onClose(true);
    }
  }

  /**
   * Renders action form component.
   * @param {TransactionModel} item The supplyItemModel or transactionModel
   * @returns {React.Component} The rendered component.
   */
  renderActionForm(item: TransactionModel) {
    const { drugs, action, suppliers } = this.props;
    const isBulk = this.intialModalMode === ModalModes.bulkLineItem;
    const { supplyItems, bulkAddedLineItems, inventoryCount } = this.state;
    const drug = drugs.find(d => d.get('_id') === item.get('sku_id'));
    const supplyItem: SupplyItemModel | null | undefined = supplyItems.find((s): s is SupplyItemModel => s.get('_id') === item.get('supply_item_id'));
    // @ts-ignore
    const outSupplyItem: SupplyItemModel | null | undefined = bulkAddedLineItems.get(item.get('_id'))?.find((m): m is SupplyItemModel => m?.get('type') === 'supply_item');
    // @ts-ignore
    const outSupplyModel: SupplyModel | null | undefined = bulkAddedLineItems.get(item.get('_id'))?.find((m): m is SupplyModel => m?.get('type') === 'supply');
    const remainingQtyInBatch = supplyItem && inventoryCount.getIn([
      supplyItem.get('sku_id'),
      'batches',
      supplyItem.get('_id'),
      'batchStockRemaining',
    ]);
    return (
      <div className="o-form o-form--horizontal u-flex-row">
        <DatePicker
          id="action-form-supplyFormDatePicker"
          showClearDate={false}
          allowPast
          label={translate('date')}
          value={moment(item.get('timestamp'))}
          required
          style={{ marginBottom: '1.333em' }}
          disabled
          className="u-margin-right--half-ws"
          openDirection="up"
          noBorder
          appendToBody
          onValueChanged={() => {}}
        />
        <Input
          id="action-form-sku-name"
          label={translate('name')}
          value={drug && drug.get('name')}
          onValueChanged={() => {}}
          disabled
        />
        <Input
          id={`action-form-supply_item_batch_${item.get('_id')}`}
          label={translate('batch_#')}
          value={supplyItem && supplyItem.get('batch_id') ? supplyItem.get('batch_id') : UNICODE.EMDASH}
          onValueChanged={() => {}}
          disabled
        />
        { (action === 'transfer_out' || action === 'loan_out') &&
          <Select
            id="supply_supplier"
            autoFocus
            label={action === 'transfer_out' ? translate('transfer_to') : translate('loan_to')}
            options={
              suppliers
                .filter(supplier => supplier.isClinic())
                .map((supplier: SupplierModel) => ({ value: supplier.get('_id'), label: supplier.get('name') }))
                .toArray()
            }
            onValueChanged={() => {}}
            value={outSupplyModel?.get('_id')}
            required
            disabled
            style={{ flexGrow: 2, width: '20%' }}
          />
        }
        <Input
          id="action-form-dispensation-unit"
          label={translate('dispensation_unit')}
          value={(drug && drug.get('dispensation_unit'))}
          onValueChanged={() => {}}
          disabled
        />
        <Input
          id="action-form-qty"
          label={action !== 'adjustment' ? translate('quantity') : translate('adjustment')}
          value={Math.abs(item.get('change'))}
          onValueChanged={() => {}}
          disabled
          type="number"
        />
        { action === 'adjustment' &&
          <Input
            id="action-qty-after-adjustment"
            label={translate('qty_after_adjustment')}
            value={remainingQtyInBatch
              ? (remainingQtyInBatch + Number(item.get('quantity') || 0))
              : UNICODE.EMDASH}
            onValueChanged={() => {}}
            disabled
          />
        }
        <Input
          id="action-cost_of_goods"
          label={translate('cost_of_goods')}
          value={(supplyItem && (Number(supplyItem.get('total_price', 0)) / Number(supplyItem.get('quantity', 0))) *
            (item.get('change'))) || UNICODE.EMDASH}
          onValueChanged={() => {}}
          disabled
        />
        { (action === 'transfer_out' || action === 'loan_out') &&
          <Input
            id="action-amount-received"
            label={translate('amount_received')}
            value={outSupplyItem?.get('total_price')}
            onValueChanged={() => {}}
            disabled
            type="number"
          />
        }
        <Input
          id="action-form-notes"
          label={translate('notes')}
          value={item.get('notes')}
          onValueChanged={() => {}}
          disabled
        />
        { isBulk && !!item &&
          <CloseButton
            onClick={() => this.onDeleteLineItem(item.get('_id'))}
            dataPublic
          />
        }
      </div>
    );
  }


  /**
   * creat a map of supply with with supply item
   * @returns {Map<string, List<SupplyItemModel>>} The rendered component.
   */
  getSupplySupplyItemMap = () => {
    return this.props.supplyItems.reduce((mapping: Map<string, List<SupplyItemModel>>, s: SupplyItemModel) => {
      return mapping.update(s.get('supply_id'),
        (v : List<SupplyItemModel> | void) => (v && List.isList(v) && v.size > 0 ? v.concat(s) : List([s])))
    }, Map());
  }

  /**
   * Renders the AdjustBatchQuantityModal
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { bulkAddedLineItems, isSaving } = this.state;
    const { action, isVisible, onClose, drugs, drug, isFromStockTake, supplyItem } = this.props;
    const isBulk = this.intialModalMode === ModalModes.bulkLineItem;
    const selectedDrug = this.state.selectedDrug || drug;
    const selectedSupplyItem = this.getSelectedSupplyItem();
    return (
      <StatelessModal
        id="adjust-batch-quantity-modal"
        buttonLabel=""
        buttonClass="o-button o-button--padded o-button--small"
        title={action === 'adjustment' ? translate('adjust_batch_quantity') : `${isBulk ? translate('bulk') : ''}  ${translate(action)}`}
        setVisible={() => {}}
        visible={isVisible}
        onClose={
          () => {
            if (isFromStockTake || supplyItem) {
              onClose();
            } else {
              this.handleCloseModal();
            }
          }
        }
        explicitCloseOnly
        large
        dataPublicHeader
      >

        <ContentTransition className="o-scrollable-container" id="add-to-inventory-form">
          {selectedSupplyItem && this.state.currentSelectedMode === ModalModes.supplyItemChange &&
            <AdjustBatchForm
              drugs={drugs}
              onChangeSKU={value => this.onChangeSKU(value)}
              inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
              inventoryCount={this.state.inventoryCount}
              updateConfigValue={this.props.updateConfigValue}
              updateConfig={this.props.updateConfig}
              supplyItems={this.state.supplyItems}
              isFetching={this.props.isFetching}
              onActionButtonClicked={(item: SupplyItemModel) =>
                this.onActionButtonClicked(item)
              }
              action={action}
              stockFormActionData={this.props.stockFormActionData}
              supplyItem={selectedSupplyItem}
              suppliers={this.props.suppliers}
              isAddAction={this.props.isFromStockTake || isBulk}
              updateInventoryItemCount={this.props.updateInventoryItemCount}
              saveModel={this.props.saveModel}
              onAdd={this.props.isFromStockTake ?
                this.props.onSave : this.onBulkAdjustmentAddClicked}
              isBulk={isBulk}
              config={this.props.config}
              onSave={this.props.onSave}
              onClose={isBulk ? () => {} : this.props.onClose}
            />
          }
          {selectedSupplyItem && this.state.currentSelectedMode === ModalModes.supplyItemChange &&
            this.props.action === ActionMode.UPDATE_DETAILS &&
            <UpdateBatchDetailsForm
              {...this.props}
              skuID={drug ? drug.get('_id') : ''}
              supplyItem={selectedSupplyItem}
              suppliers={this.props.suppliers}
              supplyItems={this.state.supplyItems}
              isVisible={isVisible}
              supplySupplyItemMap={this.getSupplySupplyItemMap()}
            />
          }
          {this.state.currentSelectedMode === ModalModes.skuSupplyItems &&
            <BatchSelectionModal
              selectedDrug={selectedDrug}
              drugs={drugs}
              onChangeSKU={value => this.onChangeSKU(value)}
              inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
              inventoryCount={this.state.inventoryCount}
              updateConfigValue={this.props.updateConfigValue}
              updateConfig={this.props.updateConfig}
              isSupplyItemChangeMode={false}
              showEmptyBatches={false}
              displayTableColumnSettings
              supplyItems={this.state.supplyItems}
              isFetching={this.props.isFetching}
              onActionButtonClicked={(item: SupplyItemModel) =>
                this.onActionButtonClicked(item)
              }
              action={action}
              config={this.props.config}
              isBulk={isBulk}
            />
          }
          {this.state.currentSelectedMode === ModalModes.bulkLineItem
            && this.state.bulkAddedLineItems.valueSeq()
              // @ts-ignore. The first item will always be a transaction, but that cant be typed for a List.
              .map(item => item && this.renderActionForm(item.first()))}

          { (isBulk && this.state.currentSelectedMode === ModalModes.bulkLineItem) &&
            <div
              className="u-margin--1ws u-margin-bottom--6ws u-margin-top--standard"
              style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <div>
                <p>{translate('bulk_action_add_new_line_item_desc')}</p>
              </div>
              <Button
                type="button"
                className="o-button o-button--small u-margin--standard"
                onClick={() => this.setState({
                  currentSelectedMode: ModalModes.skuSupplyItems,
                })}
                dataPublic
              >
                {translate('add_line_item')}
              </Button>
            </div>
          }
        </ContentTransition>
        {bulkAddedLineItems.size > 0 &&
          this.state.currentSelectedMode === ModalModes.bulkLineItem && (
            <ModalFooter>
              <SaveButton
                dataPublic
                onClick={() => this.onBulkAdjustmentSaveClicked()}
                isSaving={isSaving}
                label={translate('save')}
                className="o-button--small u-margin-right--half-ws"
              />
            </ModalFooter>
        )}
      </StatelessModal>
    );
  }
}

export default AdjustBatchQuantityModal;
