import { connect } from 'react-redux';

import QueueButton, { Props as OwnProps } from './../components/patient/queueButton';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import { updateConfig, updateModel } from './../actions';
import { getModelMapFromList } from '../utils/models';

import type { Config, Dispatch, State } from './../types';
import type BaseModel from '../models/baseModel';
import { compareByAlphabeticalOrder } from '../utils/comparators';

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (
  {
    user, config, salesItems, practitioners, coveragePayors,
    encounters, patients, appointments, klinifyConfig,
    encounterFlows, encounterStages,
  }: State,
  { patient, disabled, isSchedule }: OwnProps,
) => {
  const encountersForPatient = encounters.filter(m => m.get('patient_id') === patient.get('_id'));
  const encountersByAppointment = encountersForPatient.filter(e => e.isCurrent() && e.get('appointment_id')).groupBy(e => e.get('appointment_id'));
  return ({
    patient: patients.find(p => p.get('_id') === patient.get('_id')) || patient,
    user,
    config,
    klinifyConfig,
    salesItems,
    practitioners: practitioners.filter(p => p.isVisible()),
    coveragePayors,
    encounters,
    encounterFlows: encounterFlows.filter(f => f.isVisible()).sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
    encounterStagesMap: getModelMapFromList(encounterStages),
    ...(disabled === undefined && isSchedule) && {
      disabled: appointments
        .some(a => a.get('status') !== 'cancelled' && a.get('patient_id') === patient.get('_id') &&
          ((a.isActive() && a.isTodayOrFuture()) || encountersByAppointment.has(a.get('_id')))),
    },
  });
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  addModelToStore: <T extends BaseModel>(model: T) => dispatch(updateModel(model)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const QueueButtonContainer = connect(mapStateToProps, mapDispatchToProps)(QueueButton);

export default QueueButtonContainer;
