
import BaseModel from './baseModel';

export type Attributes = {
  _id: string,
  type: 'payment_type',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  _deleted?: boolean,
  name: string,
};

/**
   * PaymentTypeModel
   *
   * @namespace PaymentTypeModel
   */
class PaymentTypeModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'payment_type';
  }

  /**
   * sets model as deleted if true
   * @param {boolean} isDeleted is model deleted.
   * @returns {BaseModel} The altered PaymentTypeModel.
   */
  setDeleted(isDeleted: boolean): PaymentTypeModel {
    return this.set('hidden', isDeleted);
  }
}

export default PaymentTypeModel;
