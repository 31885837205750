import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  placeholder: String
}

/**
 * Renders a dummy component that will simply prints a placeholder string
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function DummyComponent(props: Props) {
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  return <div>{props.placeholder}</div>;
}

export default DummyComponent;
