import { List, Map } from 'immutable';
import BaseModel from './baseModel';
import translate from '../utils/i18n';
import type SalesItemModel from './salesItemModel';

export type SalesItem = {
  sales_item_id: string,
  quantity: number,
}

export type EncounterStageAttributes = {
  _id: string;
  created_by: { timestamp: number, user_id: string };
  edited_by: Array<{ timestamp: number, user_id: string }>;
  type: 'encounter_stage',
  name: string,
  locations: Array<string>,
  sales_items: Array<SalesItem>,
  has_notes: boolean,
  waiting_alert: string,
  in_progress_alert: string,
  metadata?: {
    is_generated: boolean,
  }
};

/**
  * EncounterStageModel
  * @namespace EncounterStageModel
  */
class EncounterStageModel extends BaseModel {
  attributes: EncounterStageAttributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'encounter_stage';
  }

  /**
   * @param {Map<string, SalesItemModel>} salesItemMap SalesItemMap
   * @returns {List<{salesItem: SalesItemModel, quantity: number}>}
   */
  getSalesItemInfo(
    salesItemMap: Map<string, SalesItemModel>,
  ): List<{ salesItem: SalesItemModel; quantity: number }> {
    const salesItems: Array<SalesItem> = this.get('sales_items') || [];
    return salesItems.reduce((salesItemInfo, item) => {
      if (salesItemMap.has(item.sales_item_id)) {
        return salesItemInfo.push({
          salesItem: salesItemMap.get(item.sales_item_id),
          quantity: item.quantity || salesItemMap.get(item.sales_item_id)?.getDefaultQuantity(),
        });
      }
      return salesItemInfo;
    }, List());
  }

  /**
   * @param {Map<string, SalesItemModel>} salesItemMap SalesItemMap
   * @returns {Array<string>}
   */
  getSalesItemNames(salesItemMap: Map<string, SalesItemModel>): Array<string> {
    const salesItems: Array<SalesItem> = this.get('sales_items') || [];
    return salesItems.map(item => salesItemMap.get(item.sales_item_id)?.get('name'))
      .filter(s => !!s);
  }

  /**
   * Returns 'YES' if `has_notes` is true
   * @returns {string}
   */
  hasNotes(): string {
    return translate(this.get('has_notes') ? 'yes' : 'no');
  }

  /**
   * @returns {Array<string>}
   */
  getLocations(): Array<string> {
    return this.get('locations');
  }
}

export default EncounterStageModel;
