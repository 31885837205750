import React, { Component } from 'react';
import glamorous from 'glamorous';
import StatelessModal from './../modals/statelessModal';
import Label from './../inputs/label';
import translate from './../../utils/i18n';
import { colours, borderRadius, wsUnit } from './../../utils/css';
import Button from './../buttons/button';

type Props = {
  onSaveLogo: (file: Blob, logoDataUrl: string) => void,
  labelClassName: string,
  isFormClear: boolean, // eslint-disable-line
};

type State = {
  isModalVisible: boolean,
  fileSizeError: boolean,
  imageDataUrl: string | null | undefined,
  file: Blob | null | undefined,
};

const InputForm = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '100%',
});

const InputFormGroup = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '500px',
  marginRight: wsUnit,
});

const InputField = glamorous.input({
  border: `1px dashed ${colours.green}`,
  padding: wsUnit,
  background: colours.grey1,
  borderRadius,
  marginTop: `calc(0.25 * ${wsUnit})`,
});

const InputFileError = glamorous.div({
  marginTop: wsUnit,
  border: `1px solid ${colours.red}`,
  padding: `calc(0.5 * ${wsUnit})`,
  borderRadius,
});

/**
   * A Component for adding Clinic logo
   * @namespace ClinicLogoForm
   */
class ClinicLogoForm extends Component<Props, State> {
  static defaultProps = {
    labelClassName: '',
  };

  /**
   * Creates an instance
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
      file: undefined,
      imageDataUrl: undefined,
      fileSizeError: false,
    };
  }

  /**
   * Called when props have been change
   * @param {Props} nextProps Next props
   * @returns {undefined}
   */
  componentWillReceiveProps(nextProps: Props) {
    this.clearForm(nextProps);
  }

  /**
   * Clear the form if the logo has been removed
   * @param {Props} props Next props
   * @returns {undefined}
   */
  clearForm(props: Props) {
    if (props.isFormClear) {
      this.setState({ file: undefined, imageDataUrl: undefined });
    }
  }

  /**
   * Handle File Upload
   * @param {React.Event} event A Keypress event.
   * @returns {undefined}
   */
  handleFileUpload = (event: SyntheticInputEvent<*>) => {
    this.setState({ fileSizeError: undefined });
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1048576) { // 1MB
        this.setState({ fileSizeError: true });
      } else {
        const img = new Image();
        const url = window.URL || window.webkitURL;
        img.src = url.createObjectURL(file);
        this.setState({ file, imageDataUrl: img.src });
      }
    }
  }

  /**
   * Save the uploaded image
   * @returns {undefined}
   */
  saveLogo = () => {
    const { file, imageDataUrl } = this.state;
    if (file && imageDataUrl) {
      this.props.onSaveLogo(file, imageDataUrl);
    }
    this.setState({ isModalVisible: false });
  }

  /**
   * Toggles the modal state.
   * @param {boolean} isModalVisible True if modal should be visible.
   * @returns {void}
   */
  toggleModal = (isModalVisible: boolean) => this.setState({ isModalVisible });

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const { isModalVisible, imageDataUrl, fileSizeError } = this.state;
    return (
      <div className="o-scrollable-container">
        <StatelessModal
          id="clinicLogoFormModal"
          buttonLabel={translate('update_clinic_logo')}
          buttonClass="o-button o-button--small"
          title={translate('clinic_logo')}
          visible={isModalVisible}
          setVisible={this.toggleModal}
          explicitCloseOnly
          style={{ height: imageDataUrl ? '' : 'auto' }}
          dataPublicHeader
        >
          <div className="o-form o-form--horizontal">
            <InputForm>
              <InputFormGroup>
                <Label
                  className={this.props.labelClassName}
                  id="imageCropLabelImage"
                  label={translate('image')}
                />
                <InputField
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={this.handleFileUpload}
                />
                { fileSizeError &&
                  <InputFileError>
                    { translate('upload_clinic_logo_size_error') }
                  </InputFileError>
                }
              </InputFormGroup>
              { imageDataUrl &&
                <div className="u-flex-column u-margin-top--standard" style={{ alignItems: 'center' }}>
                  <img src={imageDataUrl} alt="" style={{ height: 'auto', width: '100%', maxWidth: 300 }} />
                  <Button
                    style={{ alignSelf: 'center' }}
                    className="o-button o-button--small u-margin-top--standard"
                    onClick={this.saveLogo}
                    dataPublic
                  >
                    {translate('save')}
                  </Button>
                </div>
              }
            </InputForm>
          </div>
        </StatelessModal>
      </div>
    );
  }
}

export default ClinicLogoForm;
