import React from 'react';
import { List } from 'immutable';
import glamorous, { Div } from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import AddEditDrugContainer from './../../containers/addEditDrugContainer';
import SalesItemForm from './../salesItems/salesItemForm';
import Select from './../inputs/select';
import Input from './../inputs/input';
import CloseButton from './../buttons/closeButton';
import Checkbox from './../inputs/checkbox';
import translate from './../../utils/i18n';
import SalesItemModel from './../../models/salesItemModel';
import DrugModel from './../../models/drugModel';
import StockWarning from './../prescriptions/stockWarning';
import { getLowInventoryThreshold, validateAndTrimString } from './../../utils/utils';
import { wsUnit } from './../../utils/css';
import { UNICODE } from './../../constants';
import { createPermission, hasPermission, hasSomePermission } from './../../utils/permissions';
import { knuthMorrisPratt } from './../../utils/search';
import PermissionWrapper from './../permissions/permissionWrapper';
import type BillItemModel from './../../models/billItemModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, MapValue, SaveModel, SelectOption, User, CountPerSKUAndBatch } from './../../types';
import type ProviderModel from './../../models/providerModel';
import { debugPrint } from '../../utils/logging';

const BillItemRow = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  '& > .o-form__item': {
    flex: 1,
    marginRight: wsUnit,
  },
  '& > .o-form__item:first-child': {
    flex: 2,
  },
});

type Props = {
  autoFocus: boolean,
  config: Config,
  disabled?: boolean,
  hideLabel?: boolean,
  coveragePayorID?: string, // If set it can be used to pick the drug price if a specific price is set for that panel. Also enables claimable checkbox
  inventoryCount: CountPerSKUAndBatch,
  item: BillItemModel,
  itemOptions: List<SalesItemModel> | List<DrugModel>,
  onRemoveClicked: () => void,
  onValueChanged: (value: { [key: string]: MapValue }) => void,
  isFromBillPage?: boolean,
  coveragePayors: List<CoveragePayorModel>,
  saveModel: SaveModel,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  user: User,
  providers: List<ProviderModel>,
  drugDurations: List<List<number | string>>,
  isFromEncounterHistory?: boolean,
};

type State = {
  selectedOption?: string | void,
  newItemModalVisible: boolean,
  newItemInput: string,
  modelToEdit: { [key: string]: MapValue } | void,
};

/**
 * An Item within a BillForm
 * @param {object} props The item props.
 * @returns {React.Component} A BillFormItem.
 */
class BillFormItem extends React.Component<Props, State> {
  /**
   * Creates an instance of BillFormItem.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: undefined,
      newItemModalVisible: false,
      newItemInput: '',
      modelToEdit: undefined,
    };
  }

  /**
   * Shows Create new Drug Modal
   * @param {string} option New Drug name user input
   * @param {boolean} visible Modal visibility
   * @param {string} type Either prescription or salesItem.
   * @returns {void}
   */
  handleCreateModal(
    option: string,
    visible: boolean,
    type: string,
  ) {
    const newState = {
      newItemModalVisible: visible,
      modelToEdit: undefined,
      newItemInput: (type === 'prescription') && option ? validateAndTrimString(option) : this.state.newItemInput,
    };
    if ((type === 'salesItem')
    && visible
    && option) {
      newState.modelToEdit = new DrugModel({ name: validateAndTrimString(option) });
    }
    this.setState(newState);
  }


  /**
   * Returns proper item options appended with new item option
   * @param {BillItemModel} item Bill Item
   * @param {List<SalesItemModel> | List<DrugModel>} itemOptions item options to append new option to
   * @returns {Array}
   */
  getItemOptions(item: BillItemModel, itemOptions: List<SelectOption>): Array<SelectOption> {
    if (item.isPrescription()) {
      return itemOptions.push({ value: 'create', label: translate('add_new_prescription_item') }).toArray();
    } else if (item.isSalesItem()) {
      return itemOptions.push({ value: 'create', label: translate('add_new_sales_item') }).toArray();
    }
    return itemOptions.toArray();
  }

  /**
   * Returns permission feature that will be use in createPermission() func.
   * the first item in array should be for prescription and the second item is for salesItem.
   * @param {BillItemModel} item The BillItemModel
   * @param {Array<string>} permisionFeatures An array of permission features.
   * @returns {string}
   */
  getInputPermissionFeature(item: BillItemModel, permisionFeatures: Array<string>) {
    if (item.isPrescription()) {
      return permisionFeatures[0];
    } else if (item.isSalesItem()) {
      return permisionFeatures[1];
    } else if (item.isProcedureType()) {
      return permisionFeatures[2];
    }
    return '';
  }

  /**
   * check if the user has permission to update field.
   * @param {BillItemModel} item The BillItemModel
   * @param {Array<string>} permisionFeatures An array of permission features.
   * @returns {boolean}
   */
  getUpdatePermission(item: BillItemModel, permisionFeatures: Array<string>) {
    return hasPermission(this.props.user, List([
      createPermission(this.getInputPermissionFeature(
        item, permisionFeatures,
      ), 'update'),
    ]));
  }

  /**
   * Rerturns true or false based on if any read or update permission enabled for the input set
   * @param {BillItemModel} item The BillItemModel
   * @param {Array<string>} permisionFeatures An array of permission features.
   * @returns {boolean}
   */
  hasPermissionEnabled(item: BillItemModel, permisionFeatures: Array<string>) {
    return hasSomePermission(this.props.user, List([
      createPermission(this.getInputPermissionFeature(
        item, permisionFeatures,
      ), 'read'),
      createPermission(this.getInputPermissionFeature(
        item, permisionFeatures,
      ), 'update'),
    ]));
  }

  /**
   * Render
   * @returns {React.Component}
   */
  render() {
    debugPrint('Rendering BillFormItem');
    const { item, providers, isFromEncounterHistory } = this.props;
    const selectType = item.isPrescription() ? 'prescription' : 'salesItem';
    const permissionPrefix = isFromEncounterHistory ? 'past_encounters' : 'current_encounter';
    const idKey = item.getItemIdKey();
    const selectedItemID = item.getItemId();
    const selectedDrug = item.isPrescription() ?
      this.props.itemOptions.find(model => selectedItemID === model.get('_id')) : undefined;
    const itemOptions = this.props.itemOptions
      .filter(i => (item.isProcedureType() ? i.isActive() : i.isVisible()) || i.get('_id') === selectedItemID)
      .map(i => ({
        value: i.get('_id'),
        label: i.isMissing() ?
          translate('data_not_found').toUpperCase() :
          i.get('type') === 'procedure_type' ?
            `${i.get('name')} - ${i.getProviderName(providers)}` : i.get('name'),
        disabled: i.get('type') === 'procedure_type' ? !i.isActive() : !i.isVisible(),
        ...(i.isMissing() && {
          isOptionHidden: true,
          style: {
            color: 'red',
          },
        }),
      }));
    const nameFieldPermissionFeatures = [
      'perm_name_field_prescriptions_items_line_item',
      'perm_name_field_sales_items_line_item',
      'perm_name_field_lab_tests_line_item',
    ];
    const priceFieldPermissionFeatures = [
      'perm_price_field_prescriptions_items_line_item',
      'perm_price_field_sales_items_line_item',
      'perm_price_field_lab_tests_line_item',
    ];
    const quantityFieldPermissionFeatures = [
      'perm_quantity_field_prescriptions_items_line_item',
      'perm_quantity_field_sales_items_line_item',
      'perm_quantity_field_lab_tests_line_item',
    ];
    const claimableCheckboxFieldPermissionFeatures = [
      'perm_claimable_checkbox_prescriptions_items_line_item',
      'perm_claimable_checkbox_sales_items_line_item',
      'perm_claimable_checkbox_lab_tests_line_item',
    ];
    const deleteButtonPermissionFeatures = [
      'perm_x_delete_button_prescriptions_items_line_item',
      'perm_x_delete_button_sales_items_line_item',
      'perm_x_delete_button_lab_tests_line_item',
    ];
    return (
      <div className="u-margin--standard">
        <BillItemRow>
          { this.hasPermissionEnabled(item, nameFieldPermissionFeatures) &&
            <Select
              options={this.getItemOptions(item, itemOptions)}
              id={`item-${item.get('_id')}`}
              disabled={
                !this.getUpdatePermission(item, nameFieldPermissionFeatures) || this.props.disabled
              }
              label={translate('name')}
              value={selectedItemID}
              creatable={item.isPrescription() || item.isSalesItem()}
              showNewOptionAtTop={false}
              onCreateOption={(option) => {
                this.handleCreateModal(option, true, selectType);
              }}
              createOptionPromptFactory={(label) => {
                if (item.isPrescription()) {
                  return translate('add_x_to_prescriptions', { x: label });
                } else if (item.isSalesItem()) {
                  return translate('add_x_to_sales_item', { x: label });
                }
                return '';
              }}
              onValueChanged={(selectedOption) => {
                this.setState({ selectedOption });
                if (idKey && (!selectedOption || selectedOption.length === 0)) {
                  const emptyValues = { [idKey]: '' };
                  this.props.onValueChanged(emptyValues);
                } else if (selectedOption === 'create') {
                  this.handleCreateModal('', true, selectType);
                } else {
                  const selectedModel = this.props.itemOptions.find(i => i.get('_id') === selectedOption);
                  if (!selectedModel) {
                    throw new Error('Item not found.');
                  }
                  const newValues = {
                    quantity: selectedModel.getDefaultQuantity(),
                    price: selectedModel.getPrice(this.props.coveragePayorID),
                    cost_price: selectedModel.get('cost_price', 0),
                    ...(idKey ? { [idKey]: selectedModel.get('_id') } : {}),
                  };
                  this.props.onValueChanged(newValues);
                }
              }}
              hideLabel={this.props.hideLabel}
              autoFocus={this.props.autoFocus}
              {
                ...((item.isPrescription() || item.isSalesItem() || item.isProcedureType()) ? {
                  filterOption: (option, query: string) => {
                    // This is a custom filter to ensure search only applies to start of words.
                    if (option.data?.isOptionHidden || (query && option.value === 'create')) { // Used to remove the create option from search filter as that is handled by select creatable
                      return false;
                    }
                    const queryStrings = query.toLowerCase().split(' ');
                    return queryStrings.every(queryString =>
                      knuthMorrisPratt(
                        option.label.toLowerCase(),
                        queryString.toLowerCase(),
                      ) !== -1);
                  },
                } : {})
              }
            />
          }
          { this.hasPermissionEnabled(item, priceFieldPermissionFeatures) &&
            <Input
              id={`item-price-${item.get('_id')}`}
              disabled={
                !this.getUpdatePermission(item, priceFieldPermissionFeatures) || this.props.disabled
              }
              required
              label={translate('price')}
              value={item.get('price', '')}
              type="number"
              onValueChanged={(value: string) => this.props.onValueChanged({ price: value && value !== '' ? parseFloat(value) : value })}
              hideLabel={this.props.hideLabel}
            />
            }
          { this.hasPermissionEnabled(item, quantityFieldPermissionFeatures) &&
            <Input
              id={`item-quantity-${item.get('_id')}`}
              disabled={
                !this.getUpdatePermission(item, quantityFieldPermissionFeatures) ||
                this.props.disabled
              }
              required
              label={translate('quantity')}
              value={item.get('quantity', '')}
              min={1}
              type="number"
              onValueChanged={(value: string) => this.props.onValueChanged({ quantity: value && value !== '' ? parseFloat(value) : value })}
              hideLabel={this.props.hideLabel}
            />
            }
          <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_view_cost_price`, 'read')])} user={this.props.user}>
            <Input
              id={`item-cost-price-${item.get('_id')}`}
              disabled
              label={translate('cost_price')}
              value={item.get('cost_price', '')}
              type="number"
              hideLabel={this.props.hideLabel}
            />
          </PermissionWrapper>
          {
            item.isPrescription() &&
            <Input
              id={`item-dispensation-unit-${item.get('_id')}`}
              disabled
              label={translate('disp_unit')}
              value={item.getDispensationUnit(this.props.itemOptions) || UNICODE.EMDASH}
              hideLabel={this.props.hideLabel}
            />
          }
          <Input
            id={`item-total-${item.get('_id')}`}
            disabled
            label={translate('total')}
            value={item.getCalculatedTotal()}
            hideLabel={this.props.hideLabel}
          />
          {
            this.hasPermissionEnabled(item, claimableCheckboxFieldPermissionFeatures) &&
                this.props.coveragePayorID &&
                  <Div css={this.props.hideLabel ? { minWidth: 85 } : {}}>
                    <Checkbox
                      id={`item-claimable-${item.get('_id')}`}
                      label={translate('claimable')}
                      value={item.get('coverage_payor_id') === null ? [] : ['claimable']}
                      options={[{ label: '', value: 'claimable' }]}
                      hideLabel={this.props.hideLabel}
                      disabled={
                        !this.getUpdatePermission(item, claimableCheckboxFieldPermissionFeatures) ||
                        this.props.disabled
                      }
                      onValueChanged={() => {
                        const newValues = {
                          coverage_payor_id: item.get('coverage_payor_id') === null ? undefined : null,
                        };
                        if (item.isPrescription()) {
                          const selectedModel = this.props.itemOptions
                            .find(i => i.get('_id') === (this.state.selectedOption ? this.state.selectedOption : selectedItemID));
                          if (selectedModel) {
                            Object.assign(
                              {},
                              newValues,
                              {
                                price: selectedModel.getPrice(item.get('coverage_payor_id') !== null ? undefined : this.props.coveragePayorID),
                              },
                            );
                          }
                        }
                        this.props.onValueChanged(newValues);
                      }}
                      inputStyle={{ marginLeft: 20 }}
                    />
                  </Div>
          }
          { this.hasPermissionEnabled(item, deleteButtonPermissionFeatures) &&
            <Div css={this.props.hideLabel ? { height: `calc(46px + ${wsUnit})` } : {}}>
              <CloseButton
                disabled={!this.getUpdatePermission(
                  item,
                  deleteButtonPermissionFeatures,
                ) || this.props.disabled}
                onClick={() => this.props.onRemoveClicked()}
                dataPublic
              />
            </Div>
          }
        </BillItemRow>
        {
          !this.props.config.getIn(['inventory', 'hideInventoryWarnings'], false) &&
          item.isPrescription() &&
          selectedItemID &&
          <StockWarning
            count={this.props.inventoryCount.getIn([selectedItemID, 'skuStockRemaining'], 0)}
            threshold={getLowInventoryThreshold(selectedDrug)}
          />
        }
        { item.isPrescription() &&
          this.state.newItemModalVisible &&
            <AddEditDrugContainer
              onSave={
                (selectedModel) => {
                  const newValues = {
                    quantity: selectedModel.getDefaultQuantity(),
                    price: selectedModel.getPrice(this.props.coveragePayorID),
                    cost_price: selectedModel.get('cost_price', 0),
                    ...(idKey ? { [idKey]: selectedModel.get('_id') } : {}),
                  };
                  this.props.onValueChanged(newValues);
                  this.handleCreateModal('', false, selectType);
                }
              }
              labelClassName=""
              autofocus={this.state.newItemInput ? 'item_default_prescribed_dosage' : 'item_name'}
              saveIsSticky
              noClearButton
              drugName={this.state.newItemInput}
              drugDurations={this.props.drugDurations}
              setAddEditDrugModalVisible={(visibility) => {
                if (!visibility) {
                  this.setState({ newItemInput: '' });
                }
                this.setState({ newItemModalVisible: visibility });
              }}
            />
        }
        { item.isSalesItem() &&
          <StatelessModal
            id={`addNewSalesItemModal-${item.get('_id')}-${this.state.newItemInput}`}
            title={translate('add_sales_items')}
            setVisible={(isVisible: boolean) => this.setState({ newItemModalVisible: isVisible })}
            visible={this.state.newItemModalVisible}
            noButton
            onClose={() => {
              this.handleCreateModal('', false, selectType);
            }}
            dataPublic
          >
            <SalesItemForm
              modelToEdit={this.state.modelToEdit && new SalesItemModel(this.state.modelToEdit)}
              clearModelToEdit={() => this.setState({ modelToEdit: undefined })}
              coveragePayors={this.props.coveragePayors}
              config={this.props.config}
              saveModel={this.props.saveModel}
              onSave={
                (selectedModel) => {
                  const newValues = {
                    quantity: selectedModel.getDefaultQuantity(),
                    price: selectedModel.getPrice(this.props.coveragePayorID),
                    cost_price: selectedModel.get('cost_price', 0),
                    ...(idKey ? { [idKey]: selectedModel.get('_id') } : {}),
                  };
                  this.props.onValueChanged(newValues);
                  this.handleCreateModal('', false, selectType);
                }
              }
              labelClassName=""
              autofocus="item_type"
              saveIsSticky
              noClearButton
              updateConfigValue={this.props.updateConfigValue}
            />
          </StatelessModal>
        }
      </div>
    );
  }
}

export default BillFormItem;
