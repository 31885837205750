import React, { Fragment } from 'react';

import Input from './../inputs/input';
import { createUser } from './../../utils/userManagement';
import NewPasswordInput from './../inputs/newPasswordInput';
import translate from './../../utils/i18n';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';
import { createSuccessNotification } from './../../utils/notifications';
import UserConfigModel from './../../models/userConfigModel';
import ModalFooter from './../modals/modalFooter';

import type { SaveModel } from './../../types';

type Props = {
  saveModel: SaveModel,
  onUserAdded: () => void,
};

type State = {
  userName: string,
  password: string,
  validationPassword: string,
  isSaving: boolean,
  errorMessage?: string,
};

/**
 * A form for creating a new user.
 * @class AddUserForm
 * @extends {React.Component<Props, State>}
 */
class AddUserForm extends React.Component<Props, State> {
  /**
   * Creates an instance of AddUserForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      validationPassword: '',
      userName: '',
      isSaving: false,
    };
  }

  /**
   * Returns true if form is valid. If not returns false and sets appropriate error message.
   * @returns {Promise<boolean>}
   */
  formIsValid() {
    if (this.state.password !== this.state.validationPassword) {
      this.setState({ errorMessage: translate('passwords_dont_match') });
      return false;
    } else if (this.state.password.length < 8) {
      this.setState({ errorMessage: translate('new_password_must_be_at_least_8_characters_long') });
      return false;
    } else if (this.state.userName.length === 0) {
      this.setState({ errorMessage: translate('username_cant_be_empty') });
      return false;
    }
    return true;
  }

  /**
   * Saves the new user.
   * @returns {void}
   */
  onSaveClicked() {
    if (this.formIsValid()) {
      this.setState({ isSaving: true });
      createUser(this.state.userName, this.state.password)
        .then(([wasSuccessful, status]) => {
          if (status === 409) {
            this.setState({
              errorMessage: 'user_creation_try_different_username',
              isSaving: false,
            });
          } else if (wasSuccessful) {
            this.props.saveModel(new UserConfigModel({ user_id: this.state.userName }))
              .then(() => {
                createSuccessNotification(translate('user_successfully_added'));
                this.props.onUserAdded();
              });
          } else {
            this.setState({
              errorMessage: 'user_creation_failed',
              isSaving: false,
            });
          }
        });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <FormError>
              {translate(this.state.errorMessage)}
            </FormError>
          }
          <Input
            id="username-input"
            value={this.state.userName}
            onValueChanged={userName => this.setState({ userName: userName.replace(/\s/g, '') })}
            required
            label={translate('username')}
          />
          <NewPasswordInput
            value={this.state.password}
            validationValue={this.state.validationPassword}
            onValueChanged={({ value, validationValue }) =>
              this.setState({
                password: value,
                validationPassword: validationValue,
              })
            }
          />
        </div>
        <ModalFooter>
          <SaveButton
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            className="o-button--small u-margin-right--half-ws"
            dataPublic
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default AddUserForm;
