import React from 'react';

import type { HTMLStyle } from './../../types';

type Props = {
  className: string,
  id: string,
  style: HTMLStyle,
  isTableCollapsed: boolean,
  onClick: () => void,
}

/**
 * A table collapsed icon component.
 */
class TableCollapseIcon extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    className: '',
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { className, style, isTableCollapsed, id } = this.props;
    return (
      <i
        className={`fa fa-caret-${isTableCollapsed ? 'down' : 'up'} ico-caret ${className}`}
        id={id}
        style={style}
        onClick={this.props.onClick}
      />
    );
  }
}

export default TableCollapseIcon;
