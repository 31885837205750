export const alertSounds = [
  { label: 'Darter', value: 'Darter.mp3' },
  { label: 'Hornbill', value: 'Hornbill.mp3' },
  { label: 'Jacana', value: 'Jacana.mp3' },
  { label: 'Kingfisher', value: 'Kingfisher.mp3' },
  { label: 'Myna', value: 'Myna.mp3' },
  { label: 'Pelican', value: 'Pelican.mp3' },
  { label: 'Plover', value: 'Plover.mp3' },
  { label: 'Skua', value: 'Skua.mp3' },
];

/**
 * Plays a sound one time.
 * @param {string} fileName Name of the audio file to play
 * @returns {void}
 */
export function playSound(fileName: string) { // eslint-disable-line import/prefer-default-export
  const audio = new Audio(`static/audio/${fileName}`);
  const playPromise = audio.play();
  // In browsers that don’t yet support this functionality,
  // playPromise won’t be defined.
  if (playPromise !== undefined) {
    playPromise.then(() => {
      // Automatic playback started!
    }).catch(() => {
      // Automatic playback failed.
      // This could be due to device being iphone or some other promise rejection error.
    });
  }
}
