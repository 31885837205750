/* eslint-disable camelcase */
import React, { useState } from 'react';
import { List, Map, Set } from 'immutable';
import { cloneDeep } from 'lodash';

import Header from '../header/header';
import Input from '../inputs/input';
import Label from '../inputs/label';
import Table from '../table/table';
import Radio from '../inputs/radio';
import TableColumnsSettings from '../table/tableColumnsSettings';
import TagInput from '../inputs/tagInput';
import Select from '../inputs/select';
import DeleteButton from '../buttons/deleteButton';
import SaveButton from '../buttons/saveButton';
import FormError from '../formError';
import StatelessModal from '../modals/statelessModal';
import ModalFooter from '../modals/modalFooter';
import Button from '../buttons/button';
import SalesItemForm from './../salesItems/salesItemForm';
import PermissionWrapper from '../permissions/permissionWrapper';

import {
  getConfirmation,
  transformColumnKeys,
  transformFilteredColumns,
  validateAndTrimString,
} from '../../utils/utils';
import { alertSounds, playSound } from '../../utils/audio';
import translate from '../../utils/i18n';
import { mapStringToOption } from '../../utils/inputs';
import { createPermission, hasSomePermission } from '../../utils/permissions';
import { updateClinicConfig } from '../../utils/db';
import { createSuccessNotification } from '../../utils/notifications';
import EncounterStageModel, { SalesItem, EncounterStageAttributes } from '../../models/encounterStageModel';
import SalesItemModel from './../../models/salesItemModel';
import type { Config, SaveModel, User } from '../../types';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import EncounterModel from '../../models/encounterModel';

const COLUMNS = [
  { accessor: 'sales_item', Header: translate('sales_item') },
  {
    accessor: 'quantity',
    Header: translate('quantity'),
    filterable: false,
    sortable: false,
    width: 150,
  },
  { accessor: 'action', Header: '', filterable: false, width: 120 },
];

type Props = {
  isVisible: boolean;
  config: Config;
  updateConfig: (config: Config) => void;
  salesItems: Map<string, SalesItemModel>;
  selectedStage?: EncounterStageModel;
  coveragePayors: List<CoveragePayorModel>;
  saveModel: SaveModel;
  hideStageForm: () => void;
  currentEncounters?: List<EncounterModel>;
  onDelete?: () => void;
  user?: User;
};

/**
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const StageForm = (props: Props) => {
  const availableLocations = props.config
    .getIn(['clinic', 'locations'], List())
    .map((location: string) => ({ value: location, label: location }))
    .toArray();

  /**
   * Returns clone of Encounter stage attributes
   * @returns {EncounterStageAttributes}
   */
  const getInitialState = () => cloneDeep(props.selectedStage?.attributes || { has_notes: true });

  const [stageAttributes, setStageAttributes] = useState<EncounterStageAttributes>(
    // @ts-ignore
    getInitialState(),
  );
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));
  const [invalidError, setInvalidError] = useState('');
  const [salesItemModalVisible, setSalesItemModalVisible] = useState(false);
  const [selectedSalesItem, setSelectedSalesItem] = useState<SalesItem>();
  const [selectedSalesItemIndex, setSelectedSalesItemIndex] = useState<number>(-1);
  const [unsavedSalesItem, setUnsavedSalesItem] = useState<SalesItem>();
  const [salesItemError, setSalesItemError] = useState('');
  const [newSalesItem, setNewSalesItem] = useState<SalesItemModel>();
  const [formDirty, setFormDirty] = useState(false);
  const [unsavedErrorVisible, setUnsavedErrorVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Reset states and closes Sales Item Modal
   * @returns {void}
   */
  const hideModal = () => {
    setSelectedSalesItem(undefined);
    setUnsavedSalesItem(undefined);
    setSalesItemError('');
    setSalesItemModalVisible(false);
    if (props.onDelete) props.onDelete();
  };

  /**
   * Handles Delete stage
   * @returns {void}
   */
  const onDelete = () => {
    const hasEncountersWithSelectedStage = props.currentEncounters?.some((encounter) => {
      const encounterStages = encounter?.getActiveStages().reduce((stageIds, stage) =>
        stageIds.push(stage.stage_id), List());
      return encounterStages.includes(props.selectedStage?.get('_id'));
    });
    if (hasEncountersWithSelectedStage) {
      const messageContent = (
        <>
          <p className="u-margin-bottom--half-ws">
            {translate('there_are_queued_patients_using_this_stage_message')}
          </p>
          <p>
            {translate(
              'there_are_queued_patients_using_this_stage_message_description',
            )}
          </p>
        </>
      );
      return getConfirmation(messageContent, {
        modalTitle: `${translate('error')} - ${translate(
          'there_are_queued_patients_using_this_stage',
        )}`,
        hideCancel: true,
        footerSaveButtonName: translate('acknowledge'),
      }).catch(() => {});
    }
    return props.saveModel(props.selectedStage?.set('hidden', true)).then(() => {
      createSuccessNotification(translate('item_deleted'));
      hideModal();
    });
  };

  /**
   * Shows Create new Sales item Modal
   * @param {string} option New Sales Item name user input
   * @returns {void}
   */
  const handleCreateModal = (option: string) => {
    if (option) {
      setNewSalesItem(new SalesItemModel({ name: validateAndTrimString(option) }));
    }
  };

  /**
   * Handles Delete sales item from stage
   * @returns {void}
   */
  const onSalesItemDelete = () => {
    const newStageAttributes = {
      ...stageAttributes,
      sales_items: stageAttributes?.sales_items.filter(
        (_, idx) => idx !== selectedSalesItemIndex,
      ),
    };
    setStageAttributes(newStageAttributes);
    setSalesItemModalVisible(false);
    setSelectedSalesItem(undefined);
    setFormDirty(true);
    setSelectedSalesItemIndex(-1);
  };

  /**
   *
   * @param {string} fieldName Key in stageAttributes
   * @param {string | Array<string> | boolean} value New value to update
   * @returns {void}
   */
  const handleChange = (
    fieldName: string,
    value: string | Array<string> | boolean,
  ) => {
    setStageAttributes(
      Object.assign({}, stageAttributes, { [fieldName]: value }),
    );
    if (!formDirty) {
      setFormDirty(true);
    }
  };

  /**
   * Validates form
   * @returns {boolean}
   */
  const isValid = () => {
    const { name, locations = [] } = stageAttributes;
    if (name && locations.length) {
      return true;
    }
    if (!locations.length) {
      setInvalidError('fill_location');
      return false;
    }
    setInvalidError('fill_required_fields');
    return false;
  };

  /**
   * Validates required field and save stage
   * @returns {void}
   */
  const onSave = () => {
    if (isValid()) {
      setIsSaving(true);
      const stageModel = props.selectedStage
        ? props.selectedStage.replaceAtrributes(stageAttributes)
        : new EncounterStageModel(stageAttributes);
      props.saveModel(stageModel)
        .then(() => {
          const currentClinicLocations = props.config
            .getIn(['clinic', 'locations'], List()).toArray();
          const stageLocations = stageAttributes.locations;
          const clinicLocations = Set(currentClinicLocations.concat(stageLocations)).toArray();
          if (clinicLocations.length > currentClinicLocations.length) {
            const configUpdates = {
              clinic: {
                locations: clinicLocations,
              },
            };
            updateClinicConfig(props.config.toJS(), configUpdates, props.updateConfig);
          }
          props.hideStageForm();
        })
        .finally(() => setIsSaving(false));
    }
  };

  /**
   * Called on sales item add/edit Save
   * @returns {void}
   */
  const handleSalesItemSave = () => {
    if ((unsavedSalesItem?.sales_item_id ?? selectedSalesItem?.sales_item_id) && (unsavedSalesItem?.quantity ?? selectedSalesItem?.quantity)) {
      if (selectedSalesItemIndex > -1) {
        const newStageAttr = { ...stageAttributes };
        newStageAttr.sales_items[selectedSalesItemIndex] = {
          sales_item_id: unsavedSalesItem?.sales_item_id ?? selectedSalesItem?.sales_item_id as string,
          quantity: unsavedSalesItem?.quantity ?? selectedSalesItem?.quantity as number,
        };
        setStageAttributes(newStageAttr);
      } else {
        const newStageAttr = {
          ...stageAttributes,
          sales_items: [
            ...(stageAttributes.sales_items || []),
            {
              sales_item_id: unsavedSalesItem?.sales_item_id,
              quantity: unsavedSalesItem?.quantity,
            },
          ],
        };
        setStageAttributes(newStageAttr);
      }
      setSelectedSalesItem(undefined);
      setSalesItemModalVisible(false);
      setUnsavedSalesItem(undefined);
      setFormDirty(true);
    } else {
      setSalesItemError(translate('fill_required_fields'));
    }
  };

  /**
   * Called on Create/Edit sales item Form's onClose
   * @returns {void}
   */
  const handleSalesFormClose = () => {
    if (unsavedSalesItem && !salesItemError) {
      setSalesItemError(translate('unsaved_changes_error'));
    } else {
      hideModal();
    }
  };

  /**
   * Called on Create/Edit stage Form's onClose
   * @returns {void}
   */
  const handleStageFormClose = () => {
    if (unsavedErrorVisible || !formDirty) {
      hideModal();
      props.hideStageForm();
    } else if (formDirty) {
      setUnsavedErrorVisible(true);
    }
  };

  /**
   * Returns table data for sales item table
   * @returns {Row}
   */
  const getSalesItemRows = () =>
    (stageAttributes.sales_items ? stageAttributes.sales_items?.sort(
      (i: SalesItem, j: SalesItem) =>
        (props.salesItems.get(i.sales_item_id)?.get('name') || '').localeCompare(
          props.salesItems.get(j.sales_item_id)?.get('name') || '',
        ))
      .map((salesItem, idx) => ({
        sales_item: props.salesItems.get(salesItem.sales_item_id)?.get('name'),
        quantity: salesItem.quantity,
        action: (
          <Button
            dataPublic
            onClick={() => {
              setSelectedSalesItemIndex(idx);
              setSelectedSalesItem(salesItem);
            }}
            className="o-button o-button--small"
          >
            {translate('view')}
          </Button>
        ),
      })) : []);

  const salesItemForm = (
    <>
      <StatelessModal
        id="addsales-item-form"
        visible={Boolean(salesItemModalVisible || selectedSalesItem)}
        setVisible={handleSalesFormClose}
        title={translate(
          selectedSalesItem ? 'edit_x_in_stage' : 'add_x_to_stage',
          { x: translate('sales_item') },
        )}
        dataPublicHeader
        noButton
        explicitCloseOnly
      >
        <div className="u-margin--standard">
          {salesItemError && <FormError isSticky>{salesItemError}</FormError>}
          <Select
            id="sales_item"
            label={translate('sales_item')}
            value={
            unsavedSalesItem?.sales_item_id ?? selectedSalesItem?.sales_item_id
          }
            required
            isValueSensitive
            options={props.salesItems
              .sort((i: SalesItemModel, j: SalesItemModel) =>
                (i.get('name') || '').localeCompare(j.get('name') || ''))
              .map(i => ({ value: i.get('_id'), label: i.get('name'), customDetails: { defaultQuantity: i.getDefaultQuantity() } }))
              .toList()
              .toArray()}
            onValueChanged={(newValue) => {
              setUnsavedSalesItem({
                quantity: newValue.customDetails.defaultQuantity, sales_item_id: newValue.value,
              });
            }}
            creatable
            onCreateOption={option =>
              handleCreateModal(option)
          }
          />
          <Input
            id="sales_item_quantity"
            label={translate('quantity')}
            type="number"
            value={unsavedSalesItem?.quantity ?? selectedSalesItem?.quantity}
            onValueChanged={(newValue: number) => {
              // @ts-ignore
              setUnsavedSalesItem({ ...unsavedSalesItem, quantity: newValue });
            }}
            required
          />
        </div>
        {selectedSalesItem && (
        <DeleteButton
          className="o-delete-button"
          confirmModalTitle={translate('delete_x', {
            x:
              props.salesItems
                .get(selectedSalesItem?.sales_item_id)
                ?.get('name') ?? '',
          })}
          confirmTitle={translate('this_cant_be_undone')}
          onDelete={onSalesItemDelete}
          proceedButton={translate('delete')}
        />
        )}
        <div className="u-margin--standard u-text-align-right">
          <SaveButton onClick={handleSalesItemSave} />
        </div>
      </StatelessModal>
      <StatelessModal
        id="addNewSalesItemModal"
        title={translate('add_sales_items')}
        setVisible={() => setNewSalesItem(undefined)}
        visible={Boolean(newSalesItem)}
        noButton
        onClose={() => setNewSalesItem(undefined)}
      >
        <SalesItemForm
          modelToEdit={newSalesItem}
          clearModelToEdit={() => setNewSalesItem(undefined)}
          coveragePayors={props.coveragePayors}
          config={props.config}
          saveModel={props.saveModel}
          onSave={
            (salesModel) => {
              setUnsavedSalesItem({ sales_item_id: salesModel.get('_id'), quantity: salesModel.getDefaultQuantity() });
              handleCreateModal('');
              setNewSalesItem(undefined);
            }
          }
          labelClassName=""
          autofocus="item_type"
          saveIsSticky
          noClearButton
        />
      </StatelessModal>
    </>
  );

  const alertSoundsWithNone = [{ label: translate('none'), value: 'none' }].concat(alertSounds);

  return (
    <>
      <StatelessModal
        id="stage-form"
        visible={props.isVisible}
        setVisible={handleStageFormClose}
        title={translate(props.selectedStage ? 'edit_x' : 'create_x', { x: translate('stage') })}
        dataPublicHeader
        noButton
        explicitCloseOnly
      >
        <div className="u-margin--standard">
          {(unsavedErrorVisible || invalidError) && (
          <FormError isSticky>
            {translate(
              invalidError || 'unsaved_changes_error',
            )}
          </FormError>
          )}
          <Input
            id="stage-name"
            label={translate('stage_name')}
            value={stageAttributes.name}
            onValueChanged={(name: string) =>
              handleChange('name', name)
          }
            required
            autoFocus
          />
          <Label
            id="related-sales-item"
            label={translate('related_sales_items')}
            className="u-margin-top--half-ws"
          />
          <span className="o-sub-label">
            {translate('related_sales_items_label')}
          </span>
          <div className="u-margin-bottom--half-ws u-margin-top--half-ws o-card-shadow">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{translate('sales_items_in_stage')}</h1>
              <div className="u-flex-right u-margin-right--half-ws">
                <TableColumnsSettings
                  config={props.config}
                  configFieldName="stage_sales_items"
                  originalColumns={List(transformColumnKeys(COLUMNS))}
                  columns={columns}
                  onUpdateColumns={setColumns}
                  updateConfig={props.updateConfig}
                />
              </div>
            </Header>
            <div className="o-header-actions">
              <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
                <button
                  onClick={() => setSalesItemModalVisible(true)}
                  className="o-button o-button--small"
                >
                  {translate('add_sales_item_to_stage')}
                </button>
              </div>
            </div>
            <Table
              columns={transformFilteredColumns(COLUMNS, columns)}
              data={getSalesItemRows()}
              noDataText={translate('no_sales_items_added')}
            />
          </div>
          <Radio
            id="page_to_open_on_patientrow_click"
            label={translate('create_notes_section_in_encounter')}
            description={translate('create_notes_section_in_encounter_desc', {
              stageName:
              stageAttributes.name ||
              `[${translate('stage_name').toUpperCase()}]`,
            })}
            options={[
              { label: translate('create'), value: 'true' },
              { label: translate('do_not_create'), value: 'false' },
            ]}
            value={stageAttributes.has_notes?.toString() ?? 'false'}
            onValueChanged={(has_notes: string) =>
              handleChange('has_notes', has_notes === 'true')
          }
            className="u-margin-bottom--half-ws"
          />
          <TagInput
            id="locations"
            label={translate('stage_locations_label')}
            value={mapStringToOption(stageAttributes.locations || [])}
            options={availableLocations}
            required
            onChange={newValue =>
              handleChange(
                'locations',
                newValue.map(v => v.value),
              )
          }
          />
          <Select
            id="waiting_sectionAlert_sound"
            label={translate('stage_waiting_section_alert_label')}
            clearable
            className="u-margin-bottom--half-ws"
            description={
            stageAttributes?.waiting_alert && (
              <a
                className="o-text-button o-text-button--contextual"
                onClick={() => playSound(stageAttributes?.waiting_alert)}
              >
                {translate('preview')}
              </a>
            )
          }
            options={alertSoundsWithNone}
            value={stageAttributes?.waiting_alert || 'none'}
            onValueChanged={newValue =>
              handleChange('waiting_alert', newValue)
          }
          />
          <Select
            id="inprogress_section_alert_sound"
            label={translate('stage_inprogress_section_alert_label')}
            clearable
            description={
            stageAttributes?.in_progress_alert && (
              <a
                className="o-text-button o-text-button--contextual"
                onClick={() =>
                  playSound(stageAttributes?.in_progress_alert)
                }
              >
                {translate('preview')}
              </a>
            )
          }
            options={alertSoundsWithNone}
            value={stageAttributes?.in_progress_alert || 'none'}
            onValueChanged={newValue =>
              handleChange('in_progress_alert', newValue)
          }
          />
        </div>
        {props.selectedStage && props.user && (
        <PermissionWrapper permissionsRequired={List([createPermission('stages', 'delete')])} user={props.user}>
          <DeleteButton
            className="o-delete-button u-margin-bottom--1ws"
            confirmModalTitle={translate('delete_x', {
              x: stageAttributes.name,
            })}
            confirmTitle={translate('this_cant_be_undone')}
            onDelete={onDelete}
            proceedButton={translate('delete_x', { x: 'Stage' })}
            hasFeedback
          />
        </PermissionWrapper>
        )}
        {props.user && hasSomePermission(props.user, List([createPermission('stages', 'create'), createPermission('stages', 'update')])) &&
          <ModalFooter>
            <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
              <SaveButton className="o-button--small" onClick={onSave} isSaving={isSaving} />
            </div>
          </ModalFooter>
          }
      </StatelessModal>
      {(salesItemModalVisible || selectedSalesItem) && salesItemForm}
    </>
  );
};

export default StageForm;
