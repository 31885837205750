import { connect, MapStateToProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { List } from 'immutable';

import Application from './../components/application';
import { getCurrentUnsyncedModels } from '../utils/sync';
import { getLocalDB } from '../utils/db';
import { showAppointmentRequested, updateModels, setDocumentsPendingRegeneration, unsetDocumentsPendingRegeneration } from './../actions';
import { compareByAlphabeticalOrder } from '../utils/comparators';

import type { State, Dispatch, Model } from './../types';
import type AppointmentModel from '../models/appointmentModel';
import { getOrderedModelMapFromList } from './../utils/models';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  appStartupComplete: state.appStartupComplete,
  hasAuth: state.hasAuth,
  isOnline: state.isOnline,
  config: state.config,
  isSyncing: state.isSyncing,
  user: state.user,
  hasUnsyncedDocuments: getCurrentUnsyncedModels(getLocalDB(), state.user.get('id'))
    .then(unsyncedModels =>
      unsyncedModels.size > 0 ||
      state.unsyncedAPICalls.filter(unsyncedCall => unsyncedCall.enabled).size > 0),
  appointmentRequested: state.appointmentRequested,
  patients: state.patients,
  practitioners: state.practitioners,
  salesItems: state.salesItems,
  docsForRegeneration: state.docsForRegeneration,
  klinifyConfig: state.klinifyConfig,
  encounterFlowMap: List.isList(state.encounterFlows) && getOrderedModelMapFromList(state.encounterFlows.filter(e => e.isVisible()).sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))),
  appointments: state.appointments,
  encounters: state.encounters,
});

type TStateProps = ReturnType<typeof mapStateToProps>

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateModelsInState: (models: Array<Model>) => dispatch(updateModels(models)),
  showAppointmentRequested: (model: AppointmentModel | null) =>
    dispatch(showAppointmentRequested(model)),
});

const ApplicationContainer = withRouter(
  connect(
    mapStateToProps as MapStateToProps<TStateProps, RouteComponentProps, State>, mapDispatchToProps,
  )(Application),
);

export default ApplicationContainer;
