import * as React from 'react';
import glamorous from 'glamorous';

import { fonts, scaledH3, colours, wsUnit } from './../../utils/css';
import Header from './../header/header';

type Props = {
  title: string,
  children: React.Node,
  startClosed: boolean,
  footer?: React.Node, // A footer that will be displayed whether closed or not.
  isClosed?: (status: boolean) => void,
  dataPublic?: boolean,
};

type State = {
  isOpen: boolean,
};

const ArrowButton = glamorous.button({
  padding: `0 ${wsUnit}`,
  appearance: 'none',
  border: 'none',
  outline: 'none',
  background: 'none',
  fontFamily: fonts.icons,
  fontSize: scaledH3,
  color: colours.midnightGrey,
  cursor: 'pointer',
  ':hover': {
    color: colours.midnightGreyHover,
  },
});

/**
 * A card component that can be toggled open or close.
 * @class ToggleCard
 * @extends {React.Component<Props, State>}
 */
class ToggleCard extends React.Component<Props, State> {
  static defaultProps = {
    startClosed: false,
    isClosed: () => {},
  };

  /**
   * Creates an instance of ToggleCard.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: !props.startClosed };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card">
        <Header className="o-card__header" data-public={this.props.dataPublic}>
          <h1 className="o-card__title">{this.props.title}</h1>
          <ArrowButton
            onClick={() => {
              this.setState({ isOpen: !this.state.isOpen });
              if (this.props.isClosed) {
                this.props.isClosed(this.state.isOpen);
              }
            }
            }
          >
            {
              this.state.isOpen && <i className="fa  fa-caret-up" />
            }
            {
              !this.state.isOpen && <i className="fa  fa-caret-down" />
            }
          </ArrowButton>
        </Header>
        {this.state.isOpen && this.props.children}
        {this.props.footer}
      </div>
    );
  }
}

export default ToggleCard;
