import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { List } from 'immutable';

import PatientRegistration from './../components/patientRegistration/patientRegistration';
import { compareByAlphabeticalOrder } from './../utils/comparators';
import { saveFactory } from './../utils/redux';
import { updatePatientStub, updateConfigValue, updateConfig } from './../actions';

import type { Dispatch, State, Config, ReactRouterProps, SelectOption } from './../types';
import type PatientStubModel from '../models/patientStubModel';
import type PatientModel from '../models/patientModel';
import type BaseModel from '../models/baseModel';
import InventoryMapModel from '../models/inventoryMapModel';

interface OwnProps extends RouteComponentProps {
  isFromModal: boolean,
  showSaveButton: boolean,
  docId?: string,
  isSaving?: boolean,
  referrerDoc?: BaseModel,
  onSave?: (model: PatientModel) => void,
  onSaveAtValidationModal?: (wasSuccessful: boolean) => void,
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  { config, coveragePayors, user, patientStubs, patients, currentDataViewsModels, verifiedDrugs, drugs }: State,
  { location, isFromModal, docId }: OwnProps,
) => ({
  config,
  coveragePayors: coveragePayors
    .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
  location,
  user,
  patientStubs,
  patient: isFromModal && docId
    ? (patients.find(e => e.get('_id') === docId) ||
      currentDataViewsModels.find(e => e.get('_id') === docId) ||
      patientStubs.find(e => e.get('_id') === docId))
    : undefined,
  verifiedDrugs,
  drugs,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  addPatientStub: (model: PatientStubModel) => dispatch(updatePatientStub(model)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const PatientRegistrationContainer =
  connect(mapStateToProps, mapDispatchToProps)(PatientRegistration);

export default PatientRegistrationContainer;
