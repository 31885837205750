import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'category',
  name: string,
};

/**
   * CategoryModel
   *
   * @namespace CategoryModel
   */
class CategoryModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'category';
  }
}

export default CategoryModel;
