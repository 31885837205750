import * as React from 'react';

import type { ComponentReference, HTMLStyle } from './../types';

type Props = {
  containerElementID?: string,
  children: React.Node,
  isSticky?: boolean,
  style?: HTMLStyle
};

/**
 * A component the displays an error message and scrolls the container on to itself when it is
 * created.
 * @class FormError
 * @extends {React.Component<Props, State>}
 */
class FormError extends React.Component<Props, {}> {
  element: ComponentReference;

  /**
   * Called when component mounts.
   * @returns {void}
   */
  componentDidMount() {
    this.focusDiv();
  }

  /**
   * Scrolls to the container element.
   * @returns {void}
   */
  focusDiv() {
    if (this.props.containerElementID) {
      const container = document.getElementById(this.props.containerElementID);
      if (container && this.element) {
        const { top } = this.element.getBoundingClientRect();
        container.scrollTop = top;
      }
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const className = this.props.isSticky ?
      'o-form__text-block o-form__text-block--error u-sticky-to-top' :
      'o-form__text-block o-form__text-block--error';
    return (
      <div
        className={className}
        style={this.props.style}
        ref={(c) => { this.element = c; }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default FormError;
