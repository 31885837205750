
import { Record } from 'immutable';
import type { RecordFactory, RecordOf } from 'immutable';
import BaseModel from './baseModel';

export type MappedStatus = 'MAPPED' | 'REJECTED' | 'VERIFIED' | 'UNMAPPED' | 'VOIDED';
export type MapAction = 'VERIFY' | 'REJECT' | 'VOID' | 'PENDING_REVIEW';

type InventoryMapUpdateObj = {
  sku_id: string | void,
  accepted_id: string | void,
  accepted_name: string | void,
  action: MapAction | void,
};

export type InventoryMapUpdate = RecordOf<InventoryMapUpdateObj>

export const InventoryMapUpdateModel: RecordFactory<InventoryMapUpdateObj> = Record({
  sku_id: undefined,
  accepted_id: undefined,
  accepted_name: undefined,
  action: undefined,
});


// This is NOT A CouchDB Model
type Attributes = {
  _id: string,
  // Keep created/ edited fields to allow compatibility with BaseModel
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: string,
  drug_id: string,
  clinic_id: string,
  verified: boolean,
  dispensation_unit: string,
  manufacturer_id: string,
  manufacturer_name: string,
  notes: string,
  active_ingredients: Array<string>,
  name: string,
  dosage_unit: string,
  drug_master_id_maps: Array<string>,
  mapping_status: MappedStatus,
  accepted_id?: string,
  accepted_name?: string,
}

/**
  * InventoryMapModel
  * @namespace InventoryMapModel
  */
class InventoryMapModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'inventory_map';
  }

  /**
   * Applies the inventory map update to this inventory map item.
   * Changes status and updates the accepted_ids if verified.
   * @param {InventoryMapUpdate | void} update update to apply
   * @return {InventoryMapModel} this
   */
  applyUpdate(update: InventoryMapUpdate | void): InventoryMapModel {
    if (!update) {
      return this;
    }
    if (update.get('sku_id') === this.get('drug_id')) {
      const actionToStatus = {
        VERIFY: 'VERIFIED',
        REJECT: 'REJECTED',
        VOID: 'VOIDED',
        PENDING_REVIEW: 'PENDING_REVIEW',
      };
      const newStatus = update ? actionToStatus[update.get('action')] : undefined;
      if (newStatus) {
        this.set('mapping_status', newStatus);
        if (update.get('action') === 'VERIFY') {
          this.set('accepted_id', update.get('accepted_id'));
          this.set('accepted_name', update.get('accepted_name'));
          this.set('verified', true);
        }
      }
    }
    return this;
  }

  /**
   * Converts the inventorymap model into a update as if the current status was an update.
   * Returns undefined,  if the status is unmapped / mapped.
   * @returns {InventoryMapUpdate} inventoryMapUpdate.
   */
  convertToUpdate(): InventoryMapUpdate | void {
    const statusToAction = {
      VERIFIED: 'VERIFY',
      REJECTED: 'REJECT',
      VOIDED: 'VOID',
    };
    const action = statusToAction[this.get('mapping_status')];
    if (action === 'VERIFY') {
      return InventoryMapUpdateModel({
        sku_id: this.get('drug_id'),
        accepted_id: this.get('accepted_id'),
        accepted_name: this.get('accepted_name'),
        action,
      });
    }
    if (action) {
      return InventoryMapUpdateModel({
        sku_id: this.get('drug_id'),
        accepted_id: undefined,
        accepted_name: undefined,
        action,
      });
    }

    return undefined;
  }

  /**
   * clears drug master id array for an item.
   * @return {InventoryMapModel} returns this.
   */
  clearDrugMasterIds(): InventoryMapModel {
    this.set('drug_master_id_maps', []);
    return this;
  }

  /**
   * returns an update.
   * @param {string} acceptedMapId id of the mapped_drug it needs to be updated to.
   * @param {string} acceptedMapValue name of the mapped_drug it needs to be updated to.
   * @returns {InventoryMapUpdate} inventoryMapUpdate.
   */
  getInventoryMapUpdate(acceptedMapId: string,
    acceptedMapValue: string): InventoryMapUpdate | void {
    const update = acceptedMapId ? {
      sku_id: this.get('drug_id'),
      action: 'VERIFY',
      acceptedMapId,
      acceptedMapValue,
    } : {};
    return InventoryMapUpdateModel(update);
  }
}

export default InventoryMapModel;
