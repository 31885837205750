import React from 'react';
import { List, Map } from 'immutable';
import Moment from 'moment';

import { convertToCSV, downloadCSV } from './../../utils/export';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import AccountsReceivableTable from './accountsReceivableTable';
import TableDateRangePicker from '../table/tableDateRangePicker';
import TableColumnsSettings from '../table/tableColumnsSettings';
import APIError from './../../utils/apiError';
import Button from './../buttons/button';
import Header from './../header/header';

import type { Config, MapValue, Row, CustomColumn } from './../../types';

const EXPORT_FIELDS = ['Panel Name', 'Invoice Number', 'Payment Amount', 'Date Received', 'Cheque Number', 'Bank Name', 'Remarks'];

type Props = {
  data: List<MapValue>,
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  isFetching: boolean,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  currentDataViewsError?: APIError,
};

type State = {
  columns: Array<CustomColumn>,
  filteredData: Array<Row>,
}

const COLUMNS = List([
  { value: 'panelName', label: translate('panel_name'), filterable: true },
  { value: 'payableAmount', label: translate('payable_amount'), renderMethod: 'PRICE' },
]);
/**
 * A component that displays all bills for a clinic.
 * @class AccountsReceivable
 * @extends {Component}
 */
class AccountsReceivable extends React.Component<Props, State> {
  /**
   * Creates an instance of AccountsReceivable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredData: [],
      columns: COLUMNS.toArray(),
    };
  }

  /**
   * Based on the bills given, determines if the export button should be displayed or not.
   * @param {List<Map>} data A List of Maps.
   * @returns {boolean} Whether or not the export button should display.
   */
  showExportButton(data: List<Map<*, *>>): boolean {
    return data.size > 0 && this.props.filter.get('filterDateEnd', new Moment())
      && this.props.filter.get('filterDateStart', new Moment());
  }

  /**
   * Downloads a CSV file of the accounts receivable.
   * @return {void}
   */
  onExportClicked() {
    const formattedData = this.state.filteredData
      .map(datum =>
        [
          datum.panelName,
          '',
          datum.payableAmount,
          '',
          '',
          '',
          '',
        ]);
    downloadCSV('Accounts Receivable.csv', convertToCSV(EXPORT_FIELDS, formattedData));
  }

  /**
   * Merges the given object with the current filter for cost report and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: {}) {
    this.props.onFilterUpdated(this.props.filter.merge(newValues));
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <ContentTransition className="o-main__content">
        <div className="c-billing__content">
          <h1 data-public className="o-title">{translate('accounts_receivable')}</h1>
          <div className="u-flex-row u-margin--standard u-flex-space-between u-flex-align-items-center">
            <TableDateRangePicker
              filter={this.props.filter}
              onUpdateFilter={newFilter => this.updateFilter(newFilter)}
            />
          </div>
          <div className="o-card u-margin-bottom--4ws accounts-receivable-card">
            <Header className="o-card__header">
              <h1 data-public className="o-card__title">{ translate('accounts_receivable') }</h1>
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="accounts_receivable"
                updateConfigValue={this.props.updateConfigValue}
                originalColumns={COLUMNS}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
            </Header>
            <div className="o-header-actions">
              { this.state.filteredData && this.state.filteredData.length > 0 &&
              <div className="u-flex-left u-margin-left--half-ws">
                <Button
                  className="o-button o-button--small o-button--padded"
                  onClick={() => this.onExportClicked()}
                  dataPublic
                >{ translate('export') }
                </Button>
              </div>
              }
            </div>
            <AccountsReceivableTable
              {...this.props}
              columns={this.state.columns}
              onFilterAndSort={filteredData => this.setState({ filteredData })}
            />
          </div>
        </div>
      </ContentTransition>
    );
  }
}

export default AccountsReceivable;
