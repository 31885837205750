import BaseModel from './baseModel';
import { getTimeChitSerialNumber } from './../utils/serialNumbers';
import translate from './../utils/i18n';
import { prettifyTime } from './../utils/time';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'time_chit',
  start_time: number,
  end_time: number,
  practitioner_id: string,
};

/**
   * TimeChitModel
   *
   * @namespace TimeChitModel
   */
class TimeChitModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'time_chit';
  }

  /**
   * Sets the serial number for this TimeChit. This is an async function has it may have to interact
   * with the remote DB. It is a no-op if a serial number has already been assigned
   * @returns {Promise} A promise that returns this model.
   */
  setSerialNumber(): Promise<TimeChitModel> {
    if (this.has('internal_clinic_id')) {
      return Promise.resolve(this);
    }
    return getTimeChitSerialNumber()
      .then((serialNumber: string): TimeChitModel => this.set('internal_clinic_id', serialNumber));
  }

  /**
   * Returns the description for this Time Chit, containing time range of the time chit.
   * @returns {string} The MC description.
   */
  getDescription(): string {
    return `${translate('from')} ${prettifyTime(this.get('start_time'))} ${translate('to')} ${prettifyTime(this.get('end_time'))}`;
  }
}

export default TimeChitModel;
