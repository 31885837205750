import { connect } from 'react-redux';
import type { List } from 'immutable';

import ClaimInvoice from './../components/reports/claimInvoice';
import AsyncFetch from './../components/asyncFetch';
import { saveModelsFactory, saveFactory, saveModelsAPIFactory } from './../utils/redux';
import { generateClaimInvoiceModels, voidClaimInvoiceModels } from './../utils/api';
import {
  setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
} from './../actions';
import { getClaimInvoiceDataViews } from './../dataViews';

import type { State, Dispatch, DataView, ClaimInvoiceMetadata } from './../types';
import BaseModel from '../models/baseModel';

type Props = {
  claimInvoiceId: string
};

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (
  {
    isFetching, currentDataViews, currentDataViewsModels, coveragePayors, config, patientStubs,
    salesItems, user, billItems, drugs, banks, paymentTypes,
  }: State,
  { claimInvoiceId }: Props,
) => ({
  claimInvoice: currentDataViewsModels.filter(m => m.get('type') === 'claim_invoice').first(),
  isFetching,
  containerDataViews: getClaimInvoiceDataViews(claimInvoiceId),
  currentDataViews,
  showLoadingIndicator: true,
  coveragePayors,
  config,
  patientStubs,
  salesItems,
  user,
  billItems,
  drugs,
  claimInvoicePayments: currentDataViewsModels.filter(m => m.get('type') === 'claim_invoice_payment'),
  claimRecons: currentDataViewsModels.filter(m => m.get('type') === 'claim_reconciliation'),
  banks: banks.filter(b => b.isVisible()),
  paymentTypes: paymentTypes.filter(m => m.isVisible()),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  saveModels: saveModelsFactory(dispatch),
  saveModel: saveFactory(dispatch),
  saveClaimInvoiceModels: (invoices: ClaimInvoiceMetadata[], regenerate = false) => (
    saveModelsAPIFactory(dispatch)(() => generateClaimInvoiceModels(invoices, regenerate, dispatch))
  ),
  voidClaimInvoiceModels: (invoices: Array<string>) => (
    saveModelsAPIFactory(dispatch)(() => voidClaimInvoiceModels(invoices, dispatch))
  ),
});

const ClaimInvoiceContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(ClaimInvoice));

export default ClaimInvoiceContainer;
