import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import PatientModel from './../../models/patientModel';
import { UNICODE } from './../../constants';
import NotFoundContainer from './../../containers/notFoundContainer';
import { logMessage } from './../../utils/logging';

import type { Config } from './../../types';
import type PatientStubModel from './../../models/patientStubModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';


type Props = {
  config: Config,
  coveragePayors: List<CoveragePayorModel>,
  patient?: PatientModel,
  patientStub?: PatientStubModel,
};


/**
 * Creates a card displaying patient profile and a number of other things relating to the patient.
 * @class PatientCard
 * @extends {React.Component}
 */
const PatientCard = ({
  patient, patientStub, coveragePayors, config,
}: Props) => {
  const patientModel = patient || patientStub;
  if (!patientModel) {
    logMessage('Patient model not provided.', 'error');
    return <NotFoundContainer />;
  }
  return (
    <div className="c-patient-card" id="patient-card">
      <div className="c-patient-card__content">
        <div className="c-patient-card__subsection">
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'case_id'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.get('case_id', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('ic') }</span>
            <span className="c-patient-card__item__value">{ patientModel.get('ic', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('sex') }</span>
            <span className="c-patient-card__item__value">{ patientModel.get('sex', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('date_of_birth') }</span>
            <span className="c-patient-card__item__value">{ patientModel.getDOB() }</span>
          </div>
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'nationality'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.get('nationality', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('ethnicity') }</span>
            <span className="c-patient-card__item__value">{ patientModel.getEthnicity() }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('phone_number') }</span>
            <span className="c-patient-card__item__value">{ patientModel.get('tel', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('email') }</span>
            <span className="c-patient-card__item__value">
              { patientModel.get('email') ? <a href={`mailto:${patientModel.get('email')}`} className="o-text-button--link"> { patientModel.get('email') }</a> : UNICODE.EMDASH }
            </span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('address') }</span>
            <span className="c-patient-card__item__value">{ patientModel.getAddress() }</span>
          </div>
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'postal_code'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.get('postal_code', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('occupation') }</span>
            <span className="c-patient-card__item__value">{ patientModel.get('occ', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('current_employer') }</span>
            <span className="c-patient-card__item__value">{ patientModel.get('current_employer', UNICODE.EMDASH, false) }</span>
          </div>
        </div>
        <div className="c-patient-card__subsection">
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'coverage', 'payor'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.getCoveragePayorName(coveragePayors) }</span>
          </div>
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'coverage', 'policy'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.getCoveragePayorPolicyName(coveragePayors) }</span>
          </div>
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'coverage', 'policyHolder'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.getCoverageField('policyHolder') }</span>
          </div>
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'coverage', 'subscriber'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.getCoverageField('subscriber') }</span>
          </div>
          <div className="c-patient-card__item">
            <span className="c-patient-card__item__key">{config.getIn(['patient', 'labels', 'coverage', 'relationship'])}</span>
            <span className="c-patient-card__item__value">{ patientModel.getCoverageField('relationship') }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('notes') }</span>
            <span className="c-patient-card__item__value">{ patientModel.get('notes', UNICODE.EMDASH, false) }</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{ translate('tags') }</span>
            <span className="c-patient-card__item__value">{ (patientModel.get('tags') && patientModel.get('tags').length) ? patientModel.get('tags').join(', ') : UNICODE.EMDASH }</span>
          </div>
          {config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) &&
          <div>
            <div className="c-patient-card__item">
              <span data-public className="c-patient-card__item__key">{ translate('ward_number') }</span>
              <span className="c-patient-card__item__value">{ patientModel.get('ward_number', UNICODE.EMDASH, false) }</span>
            </div>
            <div className="c-patient-card__item">
              <span data-public className="c-patient-card__item__key">{ translate('room_number') }</span>
              <span className="c-patient-card__item__value">{ patientModel.get('room_number', UNICODE.EMDASH, false) }</span>
            </div>
            <div className="c-patient-card__item">
              <span data-public className="c-patient-card__item__key">{ translate('bed_number') }</span>
              <span className="c-patient-card__item__value">{ patientModel.get('bed_number', UNICODE.EMDASH, false) }</span>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

PatientCard.defaultProps = {
  hasCurrentConsult: false,
  showActions: false,
};

export default PatientCard;
