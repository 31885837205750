import React, { useEffect, useState } from 'react';
import glamorous from 'glamorous';
import { get } from 'lodash';
import csv from 'csvtojson';
import { List } from 'immutable';
import 'whatwg-fetch';
import translate from './../utils/i18n';
import Table from './../components/table/table';
import Banner from '../components/outbreak/banner';
import { wsUnit, borderRadius, scaledH2, scaledH1, colours } from './../utils/css';

const statsURL =
  'https://docs.google.com/spreadsheets/d/e/2PACX-1vRgal9jf_vhzC3WKORTzZvIvXLJ_ZDO-eMN_WhjCDoDpVjYRKtLyUvCCeHi9R-4W9xSKfAXdx_oDBZ_/pub?gid=183127792&single=true&output=csv';
const statsChartImg = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRgal9jf_vhzC3WKORTzZvIvXLJ_ZDO-eMN_WhjCDoDpVjYRKtLyUvCCeHi9R-4W9xSKfAXdx_oDBZ_/pubchart?oid=1245559160&format=image';

const PageTitle = glamorous.h1({
  fontSize: scaledH1,
});

const Container = glamorous.div({
  padding: `${wsUnit} calc(1.5 * ${wsUnit})`,
  height: `calc(100vh - 2 * ${wsUnit})`,
});

const FetchError = glamorous.div({
  marginTop: wsUnit,
  border: `1px solid ${colours.red}`,
  padding: `calc(0.5 * ${wsUnit})`,
  borderRadius,
});

const DisclaimerWrapper = glamorous.div({
  marginTop: '1rem',
  marginBottom: '1rem',
});

const StatsWrapper = glamorous.div({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto',
  gridColumnGap: '1rem',
  gridRowGap: '1rem',
  borderRadius,
});

const CardContainer = glamorous.div({
  backgroundColor: 'white',
  padding: '1rem',
  marginTop: '1rem',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  textAlign: 'center',
});

const CardMetric = glamorous.h3({
  fontSize: scaledH2,
  marginBottom: 0,
});

const ChartImage = glamorous.img({
  maxWidth: '100%',
});

const columns = [
  {
    accessor: 'state',
    Header: translate('outbreak_stats_region'),
    className: 'o-column-border-right',
  },
  {
    accessor: 'confirmed',
    Header: translate('outbreak_stats_confirmed'),
    className: 'o-column-border-right',
  },
  {
    accessor: 'deaths',
    Header: translate('outbreak_stats_deaths'),
    className: 'o-column-border-right',
  },
];

type covidStatsAttributes<T> = {
  state: string;
  confirmed: T;
  deaths: T;
  discharged: string;
};

/**
 * A container for showing outbreak guidelines
 * Skipping the redux integration as this container doesn't need a state management
 * @class OutbreakStatsContainer
 * @extends {React.Component<Props, State>}
 */
const OutbreakStatsContainer = () => {
  const [numbers, setNumbers] = useState(List<covidStatsAttributes<string>>([]));
  const [isError, setError] = useState(false);

  useEffect(() => {
    fetch(statsURL)
      .then(async (result) => {
        const text = await result.text();
        csv()
          .fromString(text)
          .then((rows : Array<covidStatsAttributes<string>>) => {
            setNumbers(List(rows));
            setError(false);
          });
      })
      .catch(() => {
        setNumbers(List([]));
        setError(true);
      });
  }, []);

  const total = numbers.first(null);
  const lastUpdated = numbers.last(null)?.confirmed || 'unknown';

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Container className="o-scrollable-container">
      <Banner />
      <PageTitle>{translate('outbreak_stats')}</PageTitle>
      {isError ? (
        <FetchError>{translate('outbreak_stats_fetch_err')}</FetchError>
      ) : null}
      <DisclaimerWrapper>
        <p>
          <em>{translate('outbreak_stats_update_prepend')}{lastUpdated}</em>
        </p>
        <p>
          <em>{translate('outbreak_stats_disclaimer')}
            <a
              href="https://newslab.malaysiakini.com/covid-19/en"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate('outbreak_stats_disclaimer_link_label')}
            </a>.
          </em>
        </p>
      </DisclaimerWrapper>
      <StatsWrapper>
        <CardContainer>
          <p>{translate('outbreak_stats_confirmed')}</p>
          <CardMetric>{get(total, 'confirmed', '-')}</CardMetric>
        </CardContainer>
        <CardContainer>
          <p>{translate('outbreak_stats_deaths')}</p>
          <CardMetric>{get(total, 'deaths', '-')}</CardMetric>
        </CardContainer>
        <CardContainer>
          <p>{translate('outbreak_stats_discharged')}</p>
          <CardMetric>{get(total, 'discharged', '-')}</CardMetric>
        </CardContainer>
      </StatsWrapper>
      <CardContainer>
        <ChartImage alt="chart" src={statsChartImg} />
      </CardContainer>
      <CardContainer>
        <Table
          columns={columns}
          data={numbers.reduce(
            (acc : Array<covidStatsAttributes<number>>, n) => {
              if(n.state !== 'TOTAL' && n.state !== 'LAST_UPDATED'){
                acc.push(
                  {
                    ...n, 
                    confirmed: parseInt(n.confirmed),
                    deaths: parseInt(n.deaths),
                  }
                );
              }
              return acc
            },[]
          )}
          apiSearch={false}
          fetchSearchData={() => {}}
          filtered={[]}
          noDataText={translate('outbreak_stats_not_found')}
          defaultSorted={[{id: 'confirmed', desc: false}]}
        />
      </CardContainer>
    </Container>
  );
};

export default OutbreakStatsContainer;
