import { List, Map } from 'immutable';
import BaseModel from './baseModel';
import type EncounterStageModel from './encounterStageModel';
import type SalesItemModel from './salesItemModel';

export type SalesItem = {
  sales_item_id: string;
  quantity: number;
};

export type EncounterFlowAttributes = {
  _id: string;
  created_by: { timestamp: number; user_id: string };
  edited_by: Array<{ timestamp: number; user_id: string }>;
  type: 'encounter_flow';
  name: string;
  stages: Array<string>;
  hidden?: boolean;
};

/**
 * EncounterFlowModel
 * @namespace EncounterFlowModel
 */
class EncounterFlowModel extends BaseModel {
  attributes: EncounterFlowAttributes;

  /**
   * @param {Object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'encounter_flow';
  }

  /**
   * Returns EncounterStageModels of stages linked with `this` EncounterFlowModel
   * @param { Map<string, EncounterStageModel>} stagesMap Mapped available stages
   * @returns {string}
   */
  getStages(stagesMap: Map<string, EncounterStageModel>): List<EncounterStageModel> {
    const stages: Array<string> = this.get('stages') || [];
    // @ts-ignore
    return List(stages.map(stageId => stagesMap.get(stageId)).filter(s => !!s));
  }

  /**
   * Returns names of stages linked to `this` EncounterFlowModel
   * @param { Map<string, EncounterStageModel>} stagesMap Mapped available stages
   * @returns {Array<string>}
   */
  getStageNames(stagesMap: Map<string, EncounterStageModel>): Array<string> {
    const stages = this.getStages(stagesMap) || [];
    return stages
      .map(stage => stage?.get('name')).toArray();
  }

  /**
   * Returns names of sales items linked with each stage related to `this` EncounterFlowModel
   * @param { Map<string, EncounterStageModel>} stagesMap Mapped available stages
   * @param {Map<string, SalesItemModel>} salesItemMap SalesItemMap
   * @returns {Array<string>}
   */
  getSalesItemNames(
    stagesMap: Map<string, EncounterStageModel>,
    salesItemMap: Map<string, SalesItemModel>,
  ): Array<string> {
    const stages = this.getStages(stagesMap) || [];
    return stages
      .reduce((salesItemNames, stage) => {
        const salesItems: Array<SalesItem> = stage?.get('sales_items') || [];
        return salesItemNames.concat(
          salesItems.map(salesItem =>
            salesItemMap.get(salesItem.sales_item_id)?.get('name')),
        );
      }, List())
      .filter(s => !!s).toArray();
  }
}

export default EncounterFlowModel;
