import * as React from 'react';
import glamorous from 'glamorous';

import { colours, fonts } from './../utils/css';
import type { HTMLStyle } from './../types';

type Props = {
  children: React.Node,
  // type: 'info' | 'warning' | 'danger', // To be expanded upon as needed.
  style: HTMLStyle,
};

const IconSpan = glamorous.span({
  color: colours.red,
  fontFamily: fonts.medium,
  '&:hover': {
    color: colours.redHover,
  },
});


/**
 * A component for use as an icon.
 * @class Icon
 * @extends {React.Component<Props, State>}
 */
class Icon extends React.PureComponent<Props> {
  static defaultProps = {
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <IconSpan style={this.props.style}>
        {this.props.children}
      </IconSpan>
    );
  }
}

export default Icon;
