/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import type { ComponentType } from 'react';

/**
 * @param {ComponentType} WrappedComponent HoC child
 * @returns {Hoc}
 */
function withStorageListener(WrappedComponent: ComponentType<any>): ComponentType<any> {
  /**
    @class WithStorageListner
    @desc Used for sharing sessionStorage between tabs,
    so that closing one tab never logs out user from others
   */
  return class WithStorageListner extends Component<{}> {
    constructor(props: any) {
      super(props);
      window.addEventListener('storage', (event) => {
        const credentials = window.sessionStorage.getItem('encrypt_key');
        if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
          window.localStorage.setItem(
            'CREDENTIALS_SHARING',
            credentials,
          );
          window.localStorage.removeItem('CREDENTIALS_SHARING');
        }
        if (event.key === 'CREDENTIALS_SHARING' && !credentials) {
          window.sessionStorage.setItem('encrypt_key', event.newValue);
        }
      });
    }

    componentDidMount() {
      // We just need to let all the other tabs know that we opened a new one.
      window.localStorage.setItem(
        'REQUESTING_SHARED_CREDENTIALS',
        Date.now().toString(),
      );
      window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withStorageListener;
