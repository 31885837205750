import React from 'react';
import glamorous from 'glamorous';
import { List } from 'immutable';
import { Link, RouteComponentProps } from 'react-router-dom';
import translate from './../../utils/i18n';
import BrandResponseModel from '../../models/pharmaconnect/brandResponseModel';
import { wsUnit } from './../../utils/css';
import { PHARMACONNECT_ENABLED } from './../../constants';

interface Props extends RouteComponentProps {
  brandDocuments: List<BrandResponseModel>,
}

type State = {}

const ListContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridColumnGap: wsUnit,
  margin: '1.33rem 0',
});

const LogoWrapper = glamorous.div({
  textAlign: 'center',
});

const Logo = glamorous.img({
  maxHeight: 96,
});


/**
 * A Sidebar component
 * @class Sidebar
 * @extends {React.Component<Props, State>}
 */
class PharmaConnectIndexPage extends React.Component<Props, State> {
  /**
   * Creates an instance of Sidebar.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { brandDocuments } = this.props;
    if (PHARMACONNECT_ENABLED) {
      if (brandDocuments === null) {
        return null;
      }
    }

    return (
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <h1 className="o-title">{translate('pharmaconnect')}</h1>
        {
          PHARMACONNECT_ENABLED ?
            <ListContainer>
              {
              brandDocuments.map(brandDocument => (
                <Link to={{ pathname: `/pharmaconnect/${brandDocument.get('id')}`, trackEvent: true }} key={brandDocument.get('id')}>
                  <div className="o-card">
                    <LogoWrapper>
                      <Logo src={brandDocument.get('logo_url', '')} alt="logo" />
                    </LogoWrapper>
                    <h1 className="o-card__title">{brandDocument.get('name', 'Brand Name')}</h1>
                  </div>
                </Link>
              ))
            }
            </ListContainer> : <p className="o-title" style={{ fontSize: '15px' }}>{translate('stay_tuned_for_more')}</p>
        }
      </section>
    );
  }
}

export default PharmaConnectIndexPage;
