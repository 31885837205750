import * as React from 'react';
import { List } from 'immutable';

import ContentTransition from './../contentTransition';
import translate from './../../utils/i18n';
import Table from './../table/table';
import StatelessModal from './../modals/statelessModal';
import AddUserForm from './addUserForm';
import PasswordChangeForm from './passwordChangeForm';
import UserConfigModel from '../../models/userConfigModel';
import { createPermission, hasPermission, hasSomePermission } from './../../utils/permissions';
import { updateUserToAdmin, updateUserToNonAdmin, isAdminPermissionSet } from './../../utils/userManagement';
import { isKlinifyUser } from './../../utils/auth';
import type UserGroupModel from './../../models/userGroupModel';
import type { SaveModel, User, Config } from './../../types';
import Button from './../buttons/button';
import Header from './../header/header';

const NO_USER_GROUP_STRING = translate('no_user_group');


type Props = {
  userGroups: List<UserGroupModel>,
  userConfigs: List<UserConfigModel>,
  saveModel: SaveModel,
  config: Config,
  user: User,
  isAdminUser: boolean,
  setAdminUserStatus: (status: boolean) => void,
};

type State = {
  isAddUserModalVisible: boolean,
  userToEdit: string,
  isChangePasswordModalVisible: boolean,
};

/**
 * A component displaying a list of users and allowing the creation of new users and changing
 * passwords for existing ones.
 * @class UserManagement
 * @extends {React.Component<Props, State>}
 */
class UserManagement extends React.Component<Props, State> {
  /**
   * Creates an instance of UserManagement.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isAddUserModalVisible: false,
      isChangePasswordModalVisible: false,
      userToEdit: '',
    };
    this.updateAdminStatus();
  }

  /**
   * checks for user permissions and updates admin status of current user, if not klinify user, to no admin, if needed.
   * @returns {void}
   */
  updateAdminStatus() {
    if (!isKlinifyUser(this.props.user)) {
      if (!hasSomePermission(this.props.user, List([createPermission('users', 'create'),
        createPermission('users', 'update'), createPermission('users', 'delete')])) && this.props.isAdminUser) {
        updateUserToNonAdmin(this.props.user.get('id', ''))
          .then(() => { this.props.setAdminUserStatus(false); });
      }
    }
  }

  /**
   * sets admin status of current user in redux store.
   * @param {string} id the user id of user for whom new group is assigned.
   * @param {boolean} status the user group id of new group assigned.
   * @returns {void}
   */
  setCurrentUserAdminStatus(id: string, status: boolean) {
    if (this.props.user.get('id', '') === id) {
      if (status) {
        this.props.setAdminUserStatus(true);
      } else {
        this.props.setAdminUserStatus(false);
      }
    }
  }

  /**
   * checks for user permissions and updates admin status of the user, for whom the group is updated.
   * @param {string} userId the user id of user for whom new group is assigned.
   * @param {string} userGroupId the user group id of new group assigned.
   * @returns {void}
   */
  onUserGroupChange(userId: string, userGroupId?: string) {
    if (userGroupId) {
      const userGroup = this.props.userGroups.find(group => group.get('_id') === userGroupId);
      if (userGroup && userGroup.hasPermissions()) {
        const isAdmin = isAdminPermissionSet(userGroup.getPermissions());
        if (isAdmin) {
          updateUserToAdmin(userId)
            .then(() => { this.setCurrentUserAdminStatus(userId, true); });
        } else {
          updateUserToNonAdmin(userId)
            .then(() => { this.setCurrentUserAdminStatus(userId, false); });
        }
      } else {
        updateUserToNonAdmin(userId)
          .then(() => { this.setCurrentUserAdminStatus(userId, false); });
      }
    }
  }

  /**
   * A button that when clicked toggles the change password modal for the given user.
   * @param {string} userID A user ID
   * @returns {React.Component}
   */
  getChangePasswordButton(userID: string) {
    return (
      <Button
        className="o-text-button o-text-button--contextual"
        onClick={() => this.setState({ userToEdit: userID, isChangePasswordModalVisible: true })}
        dataPublic
      >
        {translate('change_password')}
      </Button>
    );
  }

  /**
   * Renders the select input for choosing the user's user group.
   * @param {UserConfigModel} userConfig The user config
   * @returns {React.Component}
   */
  getUserGroupSelect(userConfig: UserConfigModel) {
    return (
      <select
        value={userConfig.get('user_group_id') || NO_USER_GROUP_STRING}
        onChange={(event: SyntheticInputEvent<*>) => {
          const newValue = event.target.value === NO_USER_GROUP_STRING ?
            undefined : event.target.value;
          this.props.saveModel(userConfig.set('user_group_id', newValue));
          this.onUserGroupChange(userConfig.get('user_id'), newValue);
        }}
        disabled={
          isKlinifyUser(this.props.user) ? false :
            !(this.props.config.getIn(['access_management', 'user_management', 'users', 'sub_items', 'assign_user_group'], true) && this.props.isAdminUser)
        }
      >
        <option value={NO_USER_GROUP_STRING}>{NO_USER_GROUP_STRING}</option>
        {
          this.props.userGroups
            .map((userGroup: UserGroupModel) => <option key={`${userConfig.get('user_id')}_${userGroup.get('_id')}`} value={userGroup.get('_id')}>{userGroup.get('name')}</option>)
            .toArray()
        }
      </select>
    );
  }

  /**
   * Gets the rows for the user table.
   * @returns {Array<{ userID: string, changePassword: React.Node }>}
   */
  getRows(): Array<{ userID: string, changePassword: React.Node }> {
    return this.props.userConfigs
      .map((userConfig: UserConfigModel) => ({
        userID: userConfig.get('user_id'),
        userGroup: this.getUserGroupSelect(userConfig),
        changePassword: this.getChangePasswordButton(userConfig.get('user_id')),
      }))
      .toArray();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const columns = [
      { accessor: 'userID', Header: translate('name') },
      { accessor: 'userGroup', Header: translate('user_group') },
    ];
    if (hasPermission(this.props.user, List([createPermission('users', 'update')])) &&
      this.props.isAdminUser) {
      columns.push({
        accessor: 'changePassword', Header: '', sortable: false, minWidth: 200, width: 200,
      });
    }
    return (
      <ContentTransition className="o-scrollable-container" style={{ height: '100vh' }}>
        <Header className="o-header" dataPublic>
          <h1>{translate('user_management')}</h1>
          <StatelessModal
            id="addNewUserModal"
            buttonLabel={translate('add_new_user')}
            buttonClass="o-button o-button--small"
            title={translate('add_new_user')}
            visible={this.state.isAddUserModalVisible}
            setVisible={isVisible => this.setState({ isAddUserModalVisible: isVisible })}
            explicitCloseOnly
            noButton={isKlinifyUser(this.props.user) ? false :
              !(
                this.props.config.getIn(['access_management', 'user_management', 'users', 'sub_items', 'create'], true) &&
                  hasPermission(this.props.user, List([createPermission('users', 'create')])) &&
                  this.props.isAdminUser
              )
              }
            dataPublicHeader
          >
            <AddUserForm
              onUserAdded={() => this.setState({ isAddUserModalVisible: false })}
              saveModel={this.props.saveModel}
            />
          </StatelessModal>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('users')}</h2>
          <StatelessModal
            id="changePasswordModal"
            buttonLabel={translate('change_password')}
            buttonClass="o-button o-button--small"
            title={translate('change_password')}
            visible={this.state.isChangePasswordModalVisible}
            setVisible={isVisible => this.setState({ isChangePasswordModalVisible: isVisible })}
            explicitCloseOnly
            noButton
            dataPublicHeader
          >
            <PasswordChangeForm
              userID={this.state.userToEdit}
              onSave={() => this.setState({ isChangePasswordModalVisible: false })}
            />
          </StatelessModal>
          <Table
            columns={columns}
            defaultSorted={[{ id: 'userID', desc: false }]}
            data={this.getRows()}
            noDataText={translate('error_loading_users')}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default UserManagement;
