import React from 'react';

import translate from './../../utils/i18n';

type Props = {
  count: number,
  threshold: number,
  className?: string,
  verbose?: boolean,
}

/**
 * Gets the label for the stock warning.
 * @param {number} count Stock level
 * @param {boolean} verbose Flag to show verbose or non verbose label.
 * @returns {string} The label.
 */
function getLabel(count: number, verbose: boolean): string {
  if (count > 0) {
    return verbose ? translate('low_stock_warning') : translate('low_stock');
  }
  return verbose ? translate('no_stock_warning') : translate('no_stock');
}

/**
 * If given count is below threshold it displays a stock warning. If count is below 0 it specifies
 * no stock.
 * @param {{ count: number, threshold: number }} props The props for this component.
 * @returns {React.Component} A stock warning component.
 */
const StockWarning = (props: Props) => {
  const {
    count,
    threshold,
    className = 'o-form--horizontal__text-underlay',
    verbose = false,
  } = props;
  if (count < threshold || count < 1) {
    return <div className={className}>{ getLabel(count, verbose) }</div>;
  }
  return <div className={className} />;
};

export default StockWarning;
