/* eslint-disable import/extensions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable require-jsdoc */
import React from 'react';
import Datetime from 'react-datetime';
import { List } from 'immutable';
import moment from 'moment';
import EncounterFlowModel from '../../models/encounterFlowModel';
import PractitionerModel from '../../models/practitionerModel';
import PatientModel from '../../models/patientModel';
import { isEmailValid } from '../../utils/utils';
import { isOnDuty } from '../../../src/utils/encounters';
import translate from '../../utils/i18n';
import type { MapValue, AppointmentData, AppointmentStatus, Config, SaveModel, SelectOption } from '../../types';
import Input from '../inputs/input';
import Select from '../inputs/select';
import Button from '../buttons/button';
import PatientStubModel, { PatientStubAttributes } from '../../models/patientStubModel';
import Header from '../header/header';
import TextArea from '../inputs/textarea';
import { Appointment, formatOptionLabel, getMemoizePatientDocs, InputForm, InputFormGroup } from './appointments';
import AppointmentModel from '../../models/appointmentModel';
import { createErrorNotification } from '../../utils/notifications';
import { APPOINTMENT_PATIENT_FILTER_OPTIONS, LIMIT_PATIENT_RESULT } from './appointmentFilters';
import { iconStyle, SectionForm } from './viewAppointment';

/**
 * Custom format style for select option
 * @param param0
 * @returns {HTMLElement}
 */
export const formatPatientOptions = ({ label, name, ic, tel }, { context }) => (
  <div style={{ flex: '10' }}>
    <h4>
      <strong>{label}</strong>
    </h4>
    {context === 'menu' && (
    <div>
      {name && (
      <span style={{ fontSize: '12px' }}>
        <strong>Name:&nbsp;</strong>
        {name}
      </span>
      )}
      {ic && (
      <span style={{ fontSize: '12px', marginLeft: name ? '10px' : '0px' }}>
        <strong>IC:&nbsp;</strong>
        {ic}
      </span>
      )}
      {tel && (
      <span style={{ fontSize: '12px', marginLeft: ic || name ? '10px' : '0px' }}>
        <strong>Tel:&nbsp;</strong>
        {tel}
      </span>
      )}
    </div>
    )}
  </div>
);

const APPOINTMENT_DURATION = [
  { value: '15', label: '15 minutes' },
  { value: '30', label: '30 minutes' },
  { value: '45', label: '45 minutes' },
  { value: '60', label: '60 minutes', extraLabel: '1 hour' },
  { value: '75', label: '75 minutes' },
  { value: '90', label: '90 minutes', extraLabel: '1.5 hour' },
  { value: '105', label: '105 minutes' },
  { value: '120', label: '120 minutes', extraLabel: '2 hours' },
];

const appointmentRequiredFields = ['duration', 'consult_mode', 'flow_id', 'practitioner_id'];

export const CONSULT_MODES = [
  {
    value: 'walk_in',
    label: 'Walk-in',
  },
  {
    value: 'tele_consult',
    label: 'E-Consultation',
  },
];

type Props = {
    isSaving: boolean;
    config: Config;
    isEditMode: boolean;
    encounterFlows: List<EncounterFlowModel>;
    patientStubs: List<PatientStubModel>;
    practitioners: List<PractitionerModel>;
    appointment: AppointmentData & Appointment;
    selectedPatientModel: PatientModel;
    saveModel: SaveModel;
    updateAppointmentState: (key: string, value: MapValue) => void;
    onUpdateAppointment: (status?: AppointmentStatus) => void;
    onSubmitAppoinment: () => void;
    resetAppointmentBooking: () => void;
};

type State = {
    newEmail: string;
    patientInput: string;
    patientSearchPlaceholder: string;
    patientOptions: List<SelectOption>;
    selectedPatientId: string;
    selectedPatient: PatientStubAttributes;
    patientFilterType: string;
    nextAppointment: AppointmentModel;
    patientDocs: PatientModel;
    errors: {
        appointment: boolean;
        patient: boolean;
        email: boolean;
    };
};

const defaultErrorState = {
  appointment: false,
  patient: false,
  email: false,
};

class AddEditAppointment extends React.Component<Props, State> {
  /**
     * Creates an instance of TableColumnsSettings.
     * @param {Props} props props
     */
  constructor(props: Props) {
    super(props);
    this.state = {
      newEmail: '',
      errors: defaultErrorState,
      patientOptions: List<SelectOption>([]),
      patientInput: '',
      patientFilterType: 'patient_name',
      selectedPatientId: '',
      patientSearchPlaceholder: translate('press_enter_to_search'),
      nextAppointment: {},
      patientDocs: {},
    };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.appointment !== prevProps.appointment) {
      this.setState({ newEmail: '', errors: defaultErrorState });
    }
  }

  onValidateAppointment() {
    const { patientDocs, newEmail, selectedPatientId } = this.state;
    const { appointment } = this.props;
    const errors = defaultErrorState;
    let hasError = false;
    for (const key in appointment) {
      if (appointmentRequiredFields.includes(key)) {
        if (!appointment[key]) {
          hasError = true;
          break;
        }
      }
    }
    errors.appointment = hasError;
    errors.email = false;
    errors.patient = false;

    if (!this.props.isEditMode) {
      if (Object.keys(patientDocs).length) {
        if (newEmail && !isEmailValid(newEmail)) errors.email = true;
        else errors.patient = !selectedPatientId || (!patientDocs.get('email') && !newEmail);
      } else {
        errors.patient = true;
      }
    }

    this.setState({ errors });
    if (errors.appointment || errors.patient || errors.email) return;

    if (newEmail) {
      this.props
        .saveModel(patientDocs.set('email', newEmail))
        .then((model) => {
          if (model.ok === false) {
            createErrorNotification(translate('something_went_wrong_email_update'));
            return false;
          }
          if (this.props.isEditMode) this.props.onUpdateAppointment();
          else this.props.onSubmitAppoinment();
          return true;
        })
        .catch(() => {});
    } else if (this.props.isEditMode) this.props.onUpdateAppointment();
    else this.props.onSubmitAppoinment();
  }

  onKeyDown(event: any) {
    const { patientInput, patientFilterType } = this.state;
    if (event.key === 'Enter') {
      if (!patientInput || patientInput.length < 3) {
        this.setState({ patientSearchPlaceholder: 'Please enter at least 3 characters' });
        return;
      }
      let newPatientOptions = List<SelectOption>([]);
      switch (patientFilterType) {
        case 'ic':
          newPatientOptions = this.props.patientStubs.filter(e => e.get('ic') && e.get('ic').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('ic'), name: e.get('patient_name'), tel: e.get('tel') }));
          break;
        case 'patient_name':
          newPatientOptions = this.props.patientStubs.filter(e => e.get('patient_name') && e.get('patient_name').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('patient_name'), ic: e.get('ic'), tel: e.get('tel') }));
          break;
        case 'tel':
          newPatientOptions = this.props.patientStubs.filter(e => e.get('tel') && e.get('tel').toLowerCase()
            .includes(patientInput.toLowerCase())).slice(0, LIMIT_PATIENT_RESULT)
            .map(e => ({ value: e.get('_id'), label: e.get('tel'), name: e.get('patient_name'), ic: e.get('ic') }));
          break;
        default:
          newPatientOptions = List<SelectOption>([]);
      }

      this.setState({
        patientOptions: newPatientOptions,
        patientSearchPlaceholder: newPatientOptions.size > 0 ? translate('press_enter_to_search') : `${translate('no_result_found_for')} ${patientInput}`,
      });
    }
  }

  onPatientFilterValueChange(patientId: string) {
    getMemoizePatientDocs(patientId).then((docs) => {
      if (docs) {
        this.setState({
          nextAppointment: docs?.nextAppointment,
          patientDocs: docs?.patientModel,
          selectedPatientId: patientId,
        });
        this.props.updateAppointmentState('patient_id', patientId);
      } else getMemoizePatientDocs.cache.delete(patientId);
    });
  }

  onFilterInputChange(value: string) {
    const { patientInput, patientOptions } = this.state;
    let newPatientNameOptions = patientOptions;
    if (value !== patientInput) {
      newPatientNameOptions = List<SelectOption>([]);
    }
    this.setState({
      patientInput: value,
      patientOptions: newPatientNameOptions,
      patientSearchPlaceholder: translate('press_enter_to_search'),
    });
  }

  updatePatientFilterType(value: string) {
    this.setState({
      selectedPatientId: '',
      patientOptions: List<SelectOption>([]),
      patientInput: '',
      patientFilterType: value,
      nextAppointment: {},
      patientDocs: {},
      patientSearchPlaceholder: translate('press_enter_to_search'),
    });

    this.props.updateAppointmentState('patient_id', '');
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    const { errors, patientOptions, patientFilterType, selectedPatientId, nextAppointment, patientDocs, patientSearchPlaceholder } = this.state;
    let practitioners = this.props.practitioners
      .filter(e => e.get('name') && isOnDuty(this.props.config, e.get('_id')));
    if (practitioners.size === 0) // No on duty doctors so just use full list.
    { ({ practitioners } = this.props); }

    return (
      <div>
        <Header style={{ color: 'white', backgroundColor: '#CD386A' }} className="o-card__header" dataPublic>
          <h1 style={{ backgroundColor: '#CD386A' }} className="o-card__title">
            {this.props.isEditMode ? translate('edit_appointment') : translate('doctor_appointment')}
          </h1>
        </Header>

        { !this.props.isEditMode &&
        <InputFormGroup>
          <h2 style={{ fontFamily: 'robotobold' }}>Search and Select Patient</h2>
          <br />
          {(errors.patient || errors.email) && (
          <div className="o-form__text-block o-form__text-block--error">
            {translate(errors.patient ? 'please_fill_patient_details' : 'fill_email_in_correct_format')}
          </div>
          )}

          <Select
            style={{ marginBottom: '0rem' }}
            required
            id="patientFilterType"
            label={translate('search_by')}
            value={patientFilterType || null}
            options={APPOINTMENT_PATIENT_FILTER_OPTIONS}
            onValueChanged={value => this.updatePatientFilterType(value)}
          />

          <Select
            required
            id="patientFilter"
            autoFocus
            noResultsText={patientSearchPlaceholder}
            label=""
            formatOptionLabel={formatPatientOptions}
            disabled={this.props.isEditMode}
            value={selectedPatientId || null}
            onKeyDown={event => this.onKeyDown(event)}
            onInputChange={value => this.onFilterInputChange(value)}
            options={patientOptions}
            onValueChanged={value => this.onPatientFilterValueChange(value)}
          />
          { selectedPatientId && patientDocs && Object.keys(patientDocs).length > 0 &&
          <div>
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{translate('patient_details')} </h1>
            </Header>
            <section style={{ padding: '1.33rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {
            patientDocs.get('patient_name') &&
            <SectionForm>
              <img style={iconStyle} alt="patient" src="static/images/icons_appointment_patient.png" />
              {patientDocs.get('patient_name')}
            </SectionForm>
            }
                {
              patientDocs.get('ic') &&
              <SectionForm>
                <img style={iconStyle} alt="ic" src="static/images/icons_appointment_id.png" />
                {patientDocs.get('ic')}
              </SectionForm>
            }
                {
              patientDocs.get('sex') &&
              <SectionForm>
                <img style={iconStyle} alt="gender" src="static/images/icons_appointment_gender.png" />
                {patientDocs.get('sex')}
              </SectionForm>
            }
                {
              patientDocs.get('tel') &&
              <SectionForm>
                <img style={iconStyle} alt="phone" src="static/images/icons_appointment_phone.png" />
                {patientDocs.get('tel')}
              </SectionForm>
            }
                {
            patientDocs.get('email') &&
            <SectionForm>
              <img style={iconStyle} alt="email" src="static/images/icons_appointment_email.png" />
              {patientDocs.get('email')}
            </SectionForm>
            }
              </div>
            </section>
          </div>
          }
          {!this.props.isEditMode && nextAppointment && Object.keys(nextAppointment).length > 0 && (
          <Input
            id="next-appointment"
            label={translate('next_appointment')}
            disabled
            value={moment(nextAppointment.get('start_timestamp')).format(`${this.props.config.get('date_format')} LT` ?? 'YYYY-MM-DD')}
          />
          )}
          {selectedPatientId && patientDocs && Object.keys(patientDocs).length > 0 && !patientDocs.get('email') && (
          <Input
            type="email"
            required
            id="email"
            label={translate('email')}
            value={this.state.newEmail}
            onValueChanged={(value: string) => this.setState({ newEmail: value })}
          />
          )}
        </InputFormGroup>
        }

        { this.props.isEditMode &&
        <div className="o-card">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{translate('patient_details')} </h1>
          </Header>
          <section style={{ padding: '1.33rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {
            this.props.selectedPatientModel.get('patient_name') &&
            <SectionForm>
              <img style={iconStyle} alt="patient" src="static/images/icons_appointment_patient.png" />
              {this.props.selectedPatientModel.get('patient_name')}
            </SectionForm>
            }
              {
              this.props.selectedPatientModel.get('ic') &&
              <SectionForm>
                <img style={iconStyle} alt="ic" src="static/images/icons_appointment_id.png" />
                {this.props.selectedPatientModel.get('ic')}
              </SectionForm>
            }
              {
              this.props.selectedPatientModel.get('sex') &&
              <SectionForm>
                <img style={iconStyle} alt="gender" src="static/images/icons_appointment_gender.png" />
                {this.props.selectedPatientModel.get('sex')}
              </SectionForm>
            }
              {
              this.props.selectedPatientModel.get('tel') &&
              <SectionForm>
                <img style={iconStyle} alt="phone" src="static/images/icons_appointment_phone.png" />
                {this.props.selectedPatientModel.get('tel')}
              </SectionForm>
            }
              {
            this.props.selectedPatientModel.get('email') &&
            <SectionForm>
              <img style={iconStyle} alt="email" src="static/images/icons_appointment_email.png" />
              {this.props.selectedPatientModel.get('email')}
            </SectionForm>
            }
            </div>
          </section>
        </div>
        }

        <InputFormGroup>
          <h2 style={{ fontFamily: 'robotobold' }}>Schedule Appointment</h2>
          <br />
          {errors.appointment && <div className="o-form__text-block o-form__text-block--error">{translate('please_fill_appointment_details')}</div>}
          <div className="o-form__item u-margin-bottom--1wsunit">
            <label className="o-label">Visit Date and Time</label>
            <Datetime
              value={moment(this.props.appointment.start_timestamp)}
              onChange={(value) => {
                if (typeof value !== 'string') {
                  this.props.updateAppointmentState('start_timestamp', moment(value).hour(value.hour()).minute(value.minute()).second(0)
                    .valueOf());
                }
              }}
              dateFormat="DD-MM-YYYY"
              inputProps={{ label: 'Visit Date and Time', className: 'css-66wpz6-Control css-g1d714-ValueContainer', style: { fontSize: 'inherit' } }}
            />
          </div>
          <Select
            required
            id="duration"
            label={translate('duration')}
            formatOptionLabel={formatOptionLabel}
            value={this.props.appointment.duration || null}
            options={APPOINTMENT_DURATION}
            onValueChanged={value => this.props.updateAppointmentState('duration', value)}
          />
          <Select
            required
            id="consult_mode"
            label={translate('consult_mode')}
            value={this.props.appointment.consult_mode}
            options={CONSULT_MODES}
            onValueChanged={value => this.props.updateAppointmentState('consult_mode', value)}
          />
          <Select
            required
            id="purpose"
            label={translate('purpose_appointment')}
            value={this.props.appointment.flow_id || null}
            options={this.props.encounterFlows.filter(e => e.get('name')).map(e => ({ value: e.get('_id'), label: e.get('name') }))}
            onValueChanged={value => this.props.updateAppointmentState('flow_id', value)}
          />
          <Select
            required
            id="doctor"
            label={translate('preferred_doctor')}
            value={this.props.appointment.practitioner_id || null}
            options={practitioners.map(e => ({ value: e.get('_id'), label: e.get('name') }))} // .concat([{ value: UNASSIGNED_STATE, label: 'Unassigned' }])}
            onValueChanged={value => this.props.updateAppointmentState('practitioner_id', value)}
          />
          <TextArea
            id="remarks"
            placeholder="Write a comment..."
            label={translate('remarks')}
            value={this.props.appointment.notes || ''}
            onValueChanged={value => this.props.updateAppointmentState('notes', value)}
          />
          <InputForm>
            <Button
              style={{ marginBottom: '1rem', backgroundColor: 'white', color: 'orange', border: '2px solid orange' }}
              className="o-button o-button--small"
              onClick={this.props.resetAppointmentBooking}
              dataPublic
            >
              {translate('cancel')}
            </Button>
            <Button
              isLoading={this.props.isSaving}
              style={{ marginBottom: '1rem', position: 'relative' }}
              className="o-button o-button--small"
              onClick={() => this.onValidateAppointment()}
              dataPublic
            >
              {this.props.isEditMode ? translate('update_appointment') : translate('save_appointment')}
            </Button>
          </InputForm>
        </InputFormGroup>
      </div>
    );
  }
}
export default AddEditAppointment;
