import type { List } from 'immutable';

import BaseModel from './baseModel';
import { fetchDataAsset } from './../utils/utils';
import { getDocumentTemplate, extractFields, fillFormFields } from './../utils/documentTemplates';
import { CASENOTE_ORDER_INCREMENT } from './../constants';

import type { Fields, DataObject } from './../utils/documentTemplates';

type Attributes = {
  _id: string,
  type: 'document_template',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  asset_id: string,
  file_name: string,
  title: string,
  description: string,
  format: 'docx', // Only one option at the moment.
  context: 'encounter', // Only one option at the moment.
  locations: Array<string>, // Current options: "encounter", "bill"
  tags: Array<string>,
  hidden?: boolean,
  order?: number,
};

/**
   * DocumentTemplateModel
   * https://klinify.atlassian.net/wiki/spaces/KLIN/pages/252149761/DocumentTemplate
   * @namespace DocumentTemplateModel
   */
class DocumentTemplateModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'document_template';
  }

  /**
   * Sets the DocumentTemplate visibility to the  given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered DocumentTemplate.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Gets the user friendly name of the DocumentTemplate
   * @returns {string}
   */
  getName(): string {
    if (this.has('title')) {
      return this.get('title');
    }
    return this.has('file_name') ? this.get('file_name') : this.get('_id');
  }

  /**
   * Gets the filename for the DocumentTemplate
   * @returns {string}
   */
  getFileName(): string {
    return this.has('file_name') ?
      this.get('file_name') :
      `${this.getName()}.docx`;
  }

  /**
   * Gets the export filename for the DocumentTemplate (i.e. the filename for DocumentData)
   * @returns {string}
   */
  getExportFileName(): string {
    return `${this.getName()}.docx`;
  }

  /**
   * Returns true if this DocumentTemplate has the given location set on it.
   * @param {string} location A location in the app
   * @returns {boolean}
   */
  hasLocation(location: string): boolean {
    return this.get('locations', []).indexOf(location) > -1;
  }

  /**
   * Fetch the asset of this DocumentTemplate
   * @returns {Promise<string>}
   */
  getAsset(): Promise<string> {
    return fetchDataAsset(this.get('asset_id'), 'docx');
  }

  /**
   * Get the placeholder fields from the DocumentTemplate and fill the values.
   * @param {DataObject} dataObject The DataObject containing the relevant data.
   * @returns {Promise<Fields>}
   */
  getFormFields(dataObject: DataObject): Promise<Fields> {
    return getDocumentTemplate(this.get('asset_id'))
      .then(doc => extractFields(doc))
      .then(fields => fillFormFields(fields, dataObject));
  }

  /**
   * Sets the order of this casenote to be the highest possible order in comparison to the given
   * DocumentTemplates
   * @param {List<DocumentTemplateModel>} documentTemplates A list of DocumentTemplates
   * @returns {DocumentTemplateModel}
   */
  setHighestOrder(documentTemplates: List<DocumentTemplateModel>): DocumentTemplateModel {
    const highestOrderDocTemplate = documentTemplates
      .max((a, b) => {
        if (!a) {
          return -1;
        } else if (!b) {
          return 1;
        }
        return a.get('order', CASENOTE_ORDER_INCREMENT) - b.get('order', CASENOTE_ORDER_INCREMENT);
      });
    return highestOrderDocTemplate ?
      this.set({ order: highestOrderDocTemplate.get('order', CASENOTE_ORDER_INCREMENT) + CASENOTE_ORDER_INCREMENT }) :
      this.set({ CASENOTE_ORDER_INCREMENT });
  }
}

export default DocumentTemplateModel;
