import * as React from 'react';
import { ReactSpecimen } from 'catalog';

import FormError from './../../formError';

/**
 * Misc components for styleguide
 * @class MiscComponents
 * @extends {React.Component<Props, State>}
 */
const MiscComponents = () =>
  <React.Fragment>
    <h2 data-public>Misc. Components</h2>
    <h3 data-public>FormError</h3>
    <p>
      A component for displaying errors above forms and elsewhere. It will scroll into view when
      it is initially created if props.containerElementId is set (it is not set here).
    </p>
    <ReactSpecimen>
      <FormError>Oh no! Error!</FormError>
    </ReactSpecimen>
  </React.Fragment>;

export default MiscComponents;
