
import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  type: 'metric_entry',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  patient_id: string,
  metric_type: string,
  measured_time: number,
  display_format: string,
  values: Array<string | number>,
  hidden?: boolean,
};

/**
   * MetricTypeModel
   *
   * @namespace MetricTypeModel
   */
class MetricEntryModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'metric_entry';
  }
}

export default MetricEntryModel;
