import * as React from 'react';
import ReactDocumentTitle from 'react-document-title';


/**
 * @param {string} title Document title
 * @returns {string} Formatted Title
 */
export const formatTitle = (title: string) => `${title} | Klinify`;

type Props = {
  children: React.Node,
  title: string,
};

/**
 * A wrapper component that sets the document title.
 * @class DocumentTitle
 * @extends {React.PureComponent<Props>}
 */
class DocumentTitle extends React.PureComponent<Props> {
  props: Props;

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <ReactDocumentTitle title={formatTitle(this.props.title)}>
        {this.props.children}
      </ReactDocumentTitle>
    );
  }
}

export default DocumentTitle;
