import React from 'react';
import moment, { Moment } from 'moment';
import { List } from 'immutable';

import ContentTransition from './../contentTransition';
import translate from './../../utils/i18n';
import DatePicker from './../inputs/statefulDatepicker';
import Select from './../inputs/select';
import TextArea from './../inputs/textarea';
import SupplyFormItem from './supplyFormItem';
import SaveButton from './../buttons/saveButton';
import { generateGUID } from './../../utils/utils';
import SupplyModel from './../../models/supplyModel';
import SupplyItemModel from './../../models/supplyItemModel';
import TransactionModel from './../../models/transactionModel';
import { createSuccessNotification } from './../../utils/notifications';
import { getDateFormat } from './../../utils/time';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import { DATE_FORMAT_TO_SAVE } from './../../constants';
import Button from './../buttons/button';

import type BaseModel from './../../models/baseModel';
import type DrugModel from './../../models/drugModel';
import type SupplierModel from './../../models/supplierModel';
import type { MapValue, supplyItemAttributes } from './../../types';

/* eslint-disable camelcase */
type Attributes = {
  supplier_id: string,
  date: Moment,
  notes?: string,
};
/* eslint-enable camelcase */

type supplyItemAttr = supplyItemAttributes & {
  key: string,
};

type Props = {
  drugs: List<DrugModel>,
  suppliers: List<SupplierModel>,
  saveModel: (model: BaseModel) => Promise<BaseModel>,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  isOutgoing?: boolean,
  isSupplier?: boolean,
}

type State = {
  attributes: Attributes,
  isSaving: boolean,
  errorMessage?: string,
  changesMade: boolean,
  supplyItems: List<supplyItemAttr>,
}

/**
 * A component containing a form for adding a Supply.
 * @class SupplyForm
 * @extends {React.Component}
 */
class SupplyForm extends React.Component<Props, State> {
  /**
   * Creates an instance of SupplyForm.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      attributes: {
        supplier_id: '',
        date: moment(),
        notes: '',
      },
      isSaving: false,
      changesMade: false,
      supplyItems: List([{ key: generateGUID() }]), // Seed supply items with empty object.
    };
  }

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState({
      attributes: Object.assign(this.state.attributes, { [key]: value }),
      changesMade: true,
    });
  }

  /**
   * Handle the updating of a supply item.
   * @param {number} index Index of the supply item to change.
   * @param {Attributes} changes The changes made to the supply item.
   * @returns {void}
   */
  updateSupplyItem(index: number, changes: Attributes) {
    this.setState({
      supplyItems: this.state.supplyItems.set(
        index,
        Object.assign({}, this.state.supplyItems.get(index), changes),
      ),
      changesMade: true,
    });
  }

  /**
   * Validate form and returns true if all fields required are valid.
   * @returns {boolean} True if form is valid.
   */
  validateForm(): boolean {
    const { supplier_id: supplierId, date } = this.state.attributes;
    const { supplyItems } = this.state;
    if (!supplierId || !supplyItems.every(item => item.expiry_date && item.quantity)
    ) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    const isQuantityFormatValid = supplyItems.every(item => item.sku_id
        && !isNaN(item.quantity)
        && Math.floor(Number(item.quantity)) === Number(item.quantity)
        && parseInt(item.quantity, 10) > 0);
    if (!date) {
      this.setState({ errorMessage: translate('please_select_valid_date') });
      return false;
    }
    if (!supplyItems.every(item => (
      item.expiry_date ?
        (
          moment.isMoment(item.expiry_date) &&
          moment(item.expiry_date, getDateFormat()).isValid() &&
          moment(item.expiry_date).format(DATE_FORMAT_TO_SAVE) !== 'Invalid date'
        ) :
        true
    ))) {
      this.setState({ errorMessage: translate('expiry_date_in_correct_format') });
      return false;
    }
    if (!isQuantityFormatValid) {
      this.setState({ errorMessage: translate('quantity_field_required_and_min_1') });
      return false;
    }
    if (
      supplierId !== undefined &&
      supplierId !== '' &&
      date !== undefined &&
      isQuantityFormatValid
    ) {
      return true;
    }
    this.setState({ errorMessage: translate('fill_required_fields_and_in_correct_format') });
    return false;
  }

  onSaveClicked = (): Promise<boolean> => {
    if (this.validateForm()) {
      this.setState({ isSaving: true });
      const supplyDate = this.state.attributes.date.format(DATE_FORMAT_TO_SAVE);
      const supplyModel = new SupplyModel({
        date: supplyDate,
        supplier_id: this.state.attributes.supplier_id,
        notes: this.state.attributes.notes,
      });
      const supplyItemModels = this.state.supplyItems.map((supplyItem) => {
        let quantity = parseInt(supplyItem.quantity, 10);
        if (this.props.isOutgoing) {
          quantity = -parseInt(supplyItem.quantity, 10);
        }
        const supplyItemAttrs = Object.assign({}, supplyItem, {
          supply_id: supplyModel.get('_id'),
          quantity,
          date: supplyDate,
          expiry_date: (
            supplyItem.expiry_date &&
            moment.isMoment(supplyItem.expiry_date) &&
            supplyItem.expiry_date.isValid()
          ) ?
            supplyItem.expiry_date.format(DATE_FORMAT_TO_SAVE) : undefined,
          total_price: supplyItem.total_price !== undefined ?
            parseFloat(supplyItem.total_price) : supplyItem.total_price,
        });
        delete supplyItemAttrs.key; // Remove key used for React.
        return new SupplyItemModel(supplyItemAttrs);
      });
      let sourceType;
      if (this.props.isOutgoing) {
        sourceType = 'transfer_out';
      } else if (this.props.isSupplier) {
        sourceType = 'supply_item';
      } else {
        sourceType = 'transfer_in';
      }
      // Create transactions for each supplyItem.
      const supplyTime = new Date(this.state.attributes.date.startOf('day')).getTime();
      const transactionModels = supplyItemModels.map(supplyItem => new TransactionModel({
        sku_id: supplyItem.get('sku_id'),
        change: supplyItem.get('quantity'),
        source_type: sourceType,
        source_id: supplyItem.get('_id'),
        notes: this.state.attributes.notes,
        timestamp: supplyTime,
      }));
      const models = List([supplyModel]).concat(supplyItemModels).concat(transactionModels);
      Promise
        .all(models.map(model => this.props.saveModel(model)))
        .then(() => {
          transactionModels.forEach(model => this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change')));
          this.setState({
            isSaving: false,
            changesMade: false,
            errorMessage: undefined,
          });
          createSuccessNotification(translate('supply_creation_success'));
          location.hash = '/inventory/inventory-status';
          return true;
        });
    }
    return Promise.resolve(false);
  }

  /**
   * Renders the SupplyForm
   * @returns {React.Component} The rendered component.
   */
  render() {
    let supplierLabel = '';
    let formAndSaveButtonTitle = '';
    if (this.props.isOutgoing || !this.props.isOutgoing) {
      supplierLabel = 'clinic';
    }
    if (this.props.isOutgoing) {
      formAndSaveButtonTitle = 'transfer_out';
    } else {
      formAndSaveButtonTitle = 'transfer_in';
    }
    if (this.props.isSupplier) {
      supplierLabel = 'supplier';
      formAndSaveButtonTitle = 'add_supply';
    }
    return (
      <ContentTransition className="o-scrollable-container" id="supply-form">
        <div className="o-form u-margin-bottom--4ws">
          <h1 data-public className="o-form__title">{translate(formAndSaveButtonTitle)}</h1>
          <SavePrompt when={this.state.changesMade} onSaveClicked={this.onSaveClicked} />
          {
            this.state.errorMessage &&
            <FormError containerElementID="supply-form">
              {this.state.errorMessage}
            </FormError>
          }
          <Select
            id="supply_supplier"
            label={translate(supplierLabel)}
            options={
              this.props.suppliers
                .map((s: SupplierModel) => ({ value: s.get('_id'), label: s.get('name') }))
                .toArray()
            }
            onValueChanged={value => this.updateAttribute('supplier_id', value)}
            value={this.state.attributes.supplier_id}
            required
          />
          <DatePicker
            id="supplyFormDatePicker"
            showClearDate={false}
            allowPast
            label={translate('date')}
            onValueChanged={value => this.updateAttribute('date', value)}
            value={this.state.attributes.date}
            required
            style={{ marginBottom: '1.333em' }}
          />
          {
            this.state.supplyItems.map((supplyItem, index) =>
              <SupplyFormItem
                key={supplyItem.key}
                supplyItem={supplyItem}
                onChange={changes => this.updateSupplyItem(index, changes)}
                onDeleteClicked={
                  () => this.setState({ supplyItems: this.state.supplyItems.remove(index) })
                }
                showDeleteButton={this.state.supplyItems.size > 1}
                isFirstChild={index === 0}
                drugs={this.props.drugs}
              />)
          }
          <Button
            className="o-button o-button--small"
            onClick={() => this.setState({
              supplyItems: this.state.supplyItems.push({ key: generateGUID() }),
            })}
            style={{ marginBottom: '1.333em' }}
            dataPublic
          >
            {translate('add_line_item')}
          </Button>
          <TextArea
            id="supply_notes"
            label={translate('notes')}
            onValueChanged={value => this.updateAttribute('notes', value)}
            value={this.state.attributes.notes || ''}
          />
          <SaveButton
            dataPublic
            label={translate(formAndSaveButtonTitle)}
            onClick={this.onSaveClicked}
            isSaving={this.state.isSaving}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default SupplyForm;
