import React from 'react';

import type { HTMLStyle } from './../../types';

type Props = {
  className: string,
  label: string,
  id: string,
  invalid?: boolean,
  style: HTMLStyle,
}

/**
 * A Label component to be used with an input-type component.
 */
class Label extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    className: '',
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { className, id, label, invalid, style } = this.props;
    return (
      <label
        className={`${invalid ? 'o-label o-label--warning' : 'o-label'} ${className}`}
        htmlFor={id}
        style={style}
      >
        {label}
      </label>
    );
  }
}

export default Label;
