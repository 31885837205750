import React from 'react';

import TextArea from './../inputs/textarea';
import translate from './../../utils/i18n';
import Header from './../header/header';

type Props = {
  notes: string,
  disabled: boolean,
  onChange: (notes: string) => void,
};

/**
 * An input form for notes on a bill.
 * @class BillNotesForm
 * @extends {React.PureComponent<Props>}
 */
class BillNotesForm extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card o-card--no-shadow" style={{ marginTop: 0 }}>
        <div style={{ fontSize: '12px' }}>
          <Header className="o-card__header" dataPublic>
            <h2 className="o-card__title">{translate('bill_notes')}</h2>
          </Header>
          <TextArea
            id="bill-notes"
            value={this.props.notes}
            disabled={this.props.disabled}
            onValueChanged={newValue => this.props.onChange(newValue)}
            hideLabel
            label={translate('bill_notes')}
            inputStyle={{ margin: '1em', width: 'calc(100% - 2em)' }}
          />
        </div>
      </div>
    );
  }
}

export default BillNotesForm;
