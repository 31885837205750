import React from 'react';
import { Map } from 'immutable';
import type { Moment } from 'moment';

import translate from './../../utils/i18n';
import DateRangePicker from './../inputs/dateRangePicker';
import Checkbox from './../inputs/checkbox';
import { debugPrint } from './../../utils/logging';
import Button from './../buttons/button';

import type { MapValue } from './../../types';

type Props = {
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  onEmptyBatchVisibiltyChange: (isChecked: boolean) => void,
  showEmptyBatches: boolean,
};

type State = {};

/**
 * A component that displays all bills for a clinic.
 * @class ConsultReports
 * @extends {Component}
 */
class ExpiryDatesSidebar extends React.Component<Props, State> {
  /**
   * Creates an instance of ExpiryDatesSidebar.
   * @param {any} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Merges the given object with the current filter for consult reports and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: {}) {
    this.props.onFilterUpdated(this.props.filter.merge(newValues));
  }

  /**
   * Get DateRangePicker Value
   * @param {?Moment} startDate DateRangePicker startDate
   * @param {?Moment} endDate DateRangePicker endDate
   * @returns {{}}
   */
  getDatePickerValue = (startDate?: Moment, endDate?: Moment): {} => {
    // We need to do this because of how DRP handles out of bounds selections.
    const newFilter = {};

    if (startDate) {
      newFilter.filterDateStart = startDate;
      newFilter.hasSetFilterDateStart = true;
    } else if (startDate === null) {
      newFilter.filterDateStart = endDate;
      newFilter.hasSetFilterDateStart = true;
    }

    if (endDate) {
      newFilter.filterDateEnd = endDate;
      newFilter.hasSetFilterDateEnd = true;
    } else if (endDate === null) { // occurs if end date set before start date.
      newFilter.filterDateEnd = startDate;
      newFilter.hasSetFilterDateEnd = true;
    }

    return newFilter;
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    debugPrint('Rendering ExpiryDatesSidebar');
    const { filter } = this.props;
    return (
      <div className="o-sidebar--right c-billing__overview">
        <h1 data-public className="c-billing__overview__title">{translate('filter')}</h1>
        <DateRangePicker
          className="u-margin-left--1ws u-margin-right--1ws"
          label={translate('dates')}
          labelClassName="u-font-white"
          id="filter-dates"
          startDate={filter.get('hasSetFilterDateStart') ? filter.get('filterDateStart') : null}
          endDate={filter.get('hasSetFilterDateEnd') ? filter.get('filterDateEnd') : null}
          onValueChanged={(value: { startDate?: Moment, endDate?: Moment }) => {
            this.updateFilter(this.getDatePickerValue(value.startDate, value.endDate));
          }}
        />
        <Checkbox
          disabled={filter.get('hasSetFilterDateStart')}
          className="u-margin-left--1ws u-margin-right--1ws"
          id="show_all_dates"
          onValueChanged={(value, isChecked) =>
            this.updateFilter({ isItemsWithoutExpiryHidden: isChecked })}
          options={[{ label: translate('hide_items_without_expiry_dates'), value: 'true' }]}
          value={[filter.get('isItemsWithoutExpiryHidden') ? 'true' : 'false']}
          label=""
          listLabelClassName="u-font-white"
        />
        <Checkbox
          className="u-margin-left--1ws u-margin-right--1ws"
          id="show_empty_batches"
          onValueChanged={(value, isChecked) => this.props.onEmptyBatchVisibiltyChange(isChecked)}
          options={[{ label: translate('show_empty_batches'), value: 'true' }]}
          value={[this.props.showEmptyBatches ? 'true' : 'false']}
          label=""
          listLabelClassName="u-font-white"
        />
        <Button
          disabled={!filter.get('hasSetFilterDateStart') && !filter.get('isItemsWithoutExpiryHidden')}
          className="o-button o-button--small u-margin--standard u-margin-bottom--zero"
          onClick={() => {
            this.updateFilter({
              hasSetFilterDateStart: false,
              hasSetFilterDateEnd: false,
              isItemsWithoutExpiryHidden: false,
            });
          }}
          dataPublic
        >{translate('reset_filter')}
        </Button>
      </div>
    );
  }
}

export default ExpiryDatesSidebar;
