import React from 'react';
import { List, Map } from 'immutable';
import Moment from 'moment';

import { consecutiveCharSearch } from './../../utils/search';
import translate from './../../utils/i18n';
import { batchedFetchModels } from './../../utils/db';
import ContentTransition from './../contentTransition';
import ClaimInvoicesTable from './claimInvoicesTable';
import ClaimInvoicesSidebar from './claimInvoicesSidebar';

import type { MapValue, SaveModels, SelectOption, Config, User, ClaimInvoiceMetadata, APIResponse } from './../../types';
import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type DrugModel from './../../models/drugModel';
import type BillModel from './../../models/billModel';
import type ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import type { ClaimData } from './../../models/claimInvoiceModel';
import ClaimModel from 'src/models/claimModel';

type Props = {
  coveragePayors: List<CoveragePayorModel>,
  claimInvoices: List<ClaimInvoiceModel>,
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  saveModels: SaveModels,
  saveClaimInvoiceModels: (claimInvoiceModels: Array<ClaimInvoiceMetadata>) => Promise<APIResponse<ClaimInvoiceModel | ClaimModel> | Array<ClaimInvoiceModel | ClaimModel>>,
  voidClaimInvoiceModels: (claimInvoiceModels: Array<string>) => Promise<APIResponse<ClaimInvoiceModel> | ClaimInvoiceModel[]>,
  uniqueMonths: Array<SelectOption>,
  config: Config,
  patientStubs: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  user: User,
  drugs: List<DrugModel>,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
};

type State = {
  isLoading: boolean,
};

/**
 * Partitions the invoice list into a invoice without bill data and a unresolved invoice list
 * @param {List<ClaimInvoiceModel>} invoices The invoices to check.
 * @returns {[List<ClaimInvoiceModel>, List<ClaimInvoiceModel>]} tuple [invoicesWithoutBillData, unresolvedInvoices]
 */
const partitionInvoicesByBillData = (
  invoices: List<ClaimInvoiceModel>,
): [List<ClaimInvoiceModel>, List<ClaimInvoiceModel>] => invoices
  .reduce(([pass, fail], i) => {
    if (i.shouldResolveObsoleteBillData()) {
      return (i.get('items', { claims: [] }).claims || [])
        .some((c : ClaimData) => c.bill_id === undefined || c.bill_last_edit_timestamp === undefined)
        ? [pass.push(i), fail]
        : [pass, fail.push(i)];
    }
    return [pass, fail];
  }, [List<ClaimInvoiceModel>([]), List<ClaimInvoiceModel>([])]);

/**
 * resolves bill related data for invoices that don't have bill data
 * @param {List<ClaimInvoiceModel>} invoices The invoices to check.
 * @returns {Promise<List<boolean>>}
 */
const resolveBillDataForInvoices = (
  invoices: List<ClaimInvoiceModel>,
): Promise<List<boolean>> => {
  const invoicesClaimIdMap = invoices
    .reduce((invoicesMap, invoice) => invoicesMap.set(
      invoice.get('_id'),
      (invoice.get('items', { claims: [] }).claims || [])
        .filter((c : ClaimData) => c._id)
        .map((c : ClaimData) => c._id),
    ), Map<string, string[]>());

  return batchedFetchModels(invoicesClaimIdMap)
    .then(claimModelsMap => invoices
      .map(invoice => invoice.resolveBillData(
        true,
        claimModelsMap.get(invoice.get('_id')) || List(),
      )));
};

/**
 * Returns invoices which has obsolete bill data
 * @param {List<ClaimInvoiceModel>} invoices The invoices to check.
 * @param {Map<string, List<BillModel>>} billsModelMap The bills models mapped by the invoice id
 * @returns {List<ClaimInvoiceModel>}
 */
const getInvoicesWithObsoleteBillData = (
  invoices: List<ClaimInvoiceModel>,
  billsModelMap: Map<string, List<BillModel>>,
) => invoices.filter((invoice) => {
  const hasObsoleteBillData = invoice.containsObsoleteBillData(
    (billsModelMap && billsModelMap.get(invoice.get('_id'))) || List(),
  );
  if (!hasObsoleteBillData) {
    invoice.resolveBillData(false);
  }
  return hasObsoleteBillData;
});

/**
 * Resolves bill related data for invoices with unresolved hasObsoleteBillData flag
 * @param {List<ClaimInvoiceModel>} invoices The invoices to check.
 * @returns {Promise<List<boolean>>}
 */
const resolveBillDataForUnresolvedInvoices = (
  invoices: List<ClaimInvoiceModel>,
): Promise<List<boolean>> => {
  const invoicesBillIdMap = invoices
    .reduce((invoicesMap, invoice) => invoicesMap.set(
      invoice.get('_id'),
      (invoice.get('items', { claims: [] }).claims || []).map((c : ClaimData) => c.bill_id),
    ), Map<string, string[]>());
  return batchedFetchModels(invoicesBillIdMap)
    .then((billsModelMap: Map<string, List<BillModel>>) => {
      const invoicesWithObsoleteBills = getInvoicesWithObsoleteBillData(invoices, billsModelMap);
      return resolveBillDataForInvoices(invoicesWithObsoleteBills);
    });
};

/**
 * A component that displays all generated claim invoices.
 * @class ClaimsInvoices
 * @extends {Component}
 */
class ClaimInvoices extends React.Component<Props, State> {
  /**
   * Creates an instance of ClaimsInvoices.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.resolveAllBillDataForInvoices(props.claimInvoices);
  }

  /**
   * Handles the receiving of new props.
   * @param {Props} nextProps Props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    const newInvoices = nextProps.claimInvoices.filter(
      newInvoice => this.props.claimInvoices.find(i => i.get('_id') === newInvoice.get('_id')) === undefined,
    );
    if (newInvoices.size > 0) {
      this.setState({ isLoading: true });
      this.resolveAllBillDataForInvoices(newInvoices);
    }
  }

  /**
   * Checks the invoices for obselete bill data.
   * @param {List<ClaimInvoiceModel>} invoices The invoices to check.
   * @returns {void}
   */
  resolveAllBillDataForInvoices(invoices: List<ClaimInvoiceModel>): void {
    const [invoicesWithoutBillData, unresolvedInvoices] = partitionInvoicesByBillData(invoices);
    Promise.all([
      resolveBillDataForInvoices(invoicesWithoutBillData),
      resolveBillDataForUnresolvedInvoices(unresolvedInvoices),
    ])
      .then(() => this.setState({ isLoading: false }));
  }

  /**
   * Returns a list of ClaimInvoiceModels filtered according to the current filter.
   * @returns {List<ClaimInvoiceModel>}
   */
  getFilteredClaimInvoices(): List<ClaimInvoiceModel> {
    return this.props.claimInvoices
      .filter((i) => {
        const coveragePayorID = this.props.filter.get('coveragePayorID');
        const invoiceNumber = this.props.filter.get('invoiceNumber', '');
        const startDate = this.props.filter.get('filterDateStart');
        const endDate = this.props.filter.get('filterDateEnd');
        if (coveragePayorID && i.get('coverage_payor_id') !== coveragePayorID) {
          return false;
        }
        if (invoiceNumber.length > 0 && !consecutiveCharSearch(invoiceNumber, i.get('internal_clinic_id'))) {
          return false;
        }
        if (this.props.filter.get('month') !== undefined && !i.matchesMonth(this.props.filter.get('month').toJS())) {
          return false;
        }
        if (startDate && startDate.isAfter(Moment(i.get('timestamp')), 'day')) {
          return false;
        }
        if (endDate && endDate.isBefore(Moment(i.get('timestamp')), 'day')) {
          return false;
        }
        return true;
      });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const filteredInvoices = this.getFilteredClaimInvoices();
    const invoicesWithObsoleteBillData = filteredInvoices.filter(i => i.hasObsoleteBillData);
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <div className="c-billing__content">
          <h1 data-public className="o-title">{translate('generated_claim_invoices')}</h1>
          {
            invoicesWithObsoleteBillData.size > 0 &&
            <div className="o-card">
              <ClaimInvoicesTable
                claimInvoices={invoicesWithObsoleteBillData}
                coveragePayors={this.props.coveragePayors}
                saveModels={this.props.saveModels}
                saveClaimInvoiceModels={this.props.saveClaimInvoiceModels}
                voidClaimInvoiceModels={this.props.voidClaimInvoiceModels}
                config={this.props.config}
                patientStubs={this.props.patientStubs}
                salesItems={this.props.salesItems}
                drugs={this.props.drugs}
                user={this.props.user}
                isLoading={this.state.isLoading}
                label={translate('claim_invoices_with_edited_bills')}
                claimInvoicePayments={this.props.claimInvoicePayments}
              />
            </div>
          }
          <div className="o-card">
            <ClaimInvoicesTable
              claimInvoices={filteredInvoices.filter(i => !i.hasObsoleteBillData)}
              coveragePayors={this.props.coveragePayors}
              saveModels={this.props.saveModels}
              saveClaimInvoiceModels={this.props.saveClaimInvoiceModels}
              voidClaimInvoiceModels={this.props.voidClaimInvoiceModels}
              config={this.props.config}
              patientStubs={this.props.patientStubs}
              salesItems={this.props.salesItems}
              drugs={this.props.drugs}
              user={this.props.user}
              isLoading={this.state.isLoading}
              claimInvoicePayments={this.props.claimInvoicePayments}
            />
          </div>
        </div>
        <ClaimInvoicesSidebar
          uniqueMonths={this.props.uniqueMonths}
          coveragePayors={this.props.coveragePayors}
          claimInvoices={filteredInvoices}
          claimInvoicePayments={this.props.claimInvoicePayments}
          config={this.props.config}
          filter={this.props.filter}
          onFilterUpdated={this.props.onFilterUpdated}
        />
      </ContentTransition>
    );
  }
}

export default ClaimInvoices;
