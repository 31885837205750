import React from 'react';
import { List } from 'immutable';

import { UNICODE } from './../../constants';
import translate from './../../utils/i18n';
import { getConfirmation } from './../../utils/utils';
import Table from './../table/table';
import { createSuccessNotification } from './../../utils/notifications';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';
import SpecimensForm from './specimensForm';
import Button from './../buttons/button';

import type SpecimenModel from './../../models/specimenModel';
import type { SaveModel, Config, User } from './../../types';

type Props = {
  saveModel: SaveModel,
  user: User,
  config: Config,
  specimens: List<SpecimenModel>,
};

type State = {
  modelBeingEdited?: string,
};

/**
 * Called when delete is clicked on an item. Updates its state to be hidden.
 * @param {SpecimenModel} item The item to delete
 * @param {SaveModel} saveModel saveModel function
 * @returns {void}
 */
function onDeleteClicked(item: SpecimenModel, saveModel: SaveModel) {
  if (item) {
    getConfirmation(translate('confirm_specimen_deletion'))
      .then(
        () => {
          saveModel(item.setActive(false))
            .then(() => {
              createSuccessNotification(translate('lab_test_deleted'));
            });
        },
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {ProcedureTypeModel} item An item for the delete button
 * @param {SaveModel} saveModel saveModel function
 * @param {User} user User.
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(item: SpecimenModel, saveModel: SaveModel, user: User) {
  return (
    <PermissionWrapper permissionsRequired={List([createPermission('specimens', 'delete')])} user={user}>
      <Button
        className="o-text-button o-text-button--danger"
        onClick={() => onDeleteClicked(item, saveModel)}
        dataPublic
      >
        {translate('delete')}
      </Button>
    </PermissionWrapper>
  );
}

/**
 * Component displaying Specimens table.
 * @class LabTestsTable
 * @extends {React.Component}
 */
class SpecimensTable extends React.Component<Props, State> {
  /**
   * Creates an instance of SpecimensTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = { modelBeingEdited: undefined };
  }

  /**
   * Generates the table rows from the SupplyItemModels.
   * @param {SpecimenModel} specimen a specimen model
   * @returns {[Row]} The rows of the table
   */
  getRow(specimen: SpecimenModel) {
    const rowData = {
      name: specimen.get('name'),
      internal_clinic_id: specimen.get('internal_clinic_id') || UNICODE.EMDASH,
      edit: specimen.get('is_editable') ? this.getEditButton(specimen, this.props.user) : null,
      delete: specimen.get('is_editable') ? getDeleteButton(specimen, this.props.saveModel, this.props.user) : null,
    };
    return rowData;
  }

  /**
   * Returns an edit button component for the given item.
   * @param {SpecimenModel} item The item the edit button is for.
   * @param {User} user User.
   * @returns {React.Component} A edit button
   */
  getEditButton(item: SpecimenModel, user: User) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('specimens', 'update')])} user={user}>
        <StatelessModal
          id={`form-modal-${item.get('_id')}`}
          buttonLabel={translate('edit')}
          buttonClass="o-text-button o-text-button--contextual"
          title={translate('add_edit_specimen')}
          className="js-modal"
          visible={this.state.modelBeingEdited === item.get('_id')}
          setVisible={isVisible => this.setState({ modelBeingEdited: isVisible ? item.get('_id') : undefined })}
          explicitCloseOnly
          disableButton={!item.get('is_editable')}
          dataPublicHeader
        >
          <SpecimensForm
            config={this.props.config}
            onSave={specimen =>
              this.props.saveModel(specimen).then(() => {
                this.setState({ modelBeingEdited: undefined });
                return specimen;
              })
            }
            saveModel={this.props.saveModel}
            onCancel={() => this.setState({ modelBeingEdited: undefined })}
            modelToEdit={item}
            disabled={!hasPermission(this.props.user, List([createPermission('specimens', 'update')])) || !item.get('is_editable')}
          />
        </StatelessModal>
      </PermissionWrapper>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const columns = [
      { accessor: 'name', Header: translate('name'), filterable: true },
      { accessor: 'internal_clinic_id', Header: translate('custom_name'), filterable: true },
      { accessor: 'edit', Header: '', sortable: false, width: 100 },
      { accessor: 'delete', Header: '', sortable: false, width: 100 },
    ];
    return (
      <div className="o-card">
        <div className="o-card__title">
          <h2 data-public style={{ fontFamily: 'robotomedium' }}>{translate('specimens')}</h2>
        </div>
        <Table
          columns={columns}
          data={
            this.props.specimens.map(specimen => this.getRow(specimen)).toArray()
          }
          noDataText={translate('no_items')}
          showPagination
          defaultSorted={[{ id: 'name', desc: false }]}
        />
      </div>
    );
  }
}

export default SpecimensTable;
