import React from 'react';

import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import translate from './../../utils/i18n';
import Button from './../buttons/button';

type Props = {
  showOfflinePrompt: boolean,
  onClose: (isVisible: boolean) => void,
}

/**
 * Modal to show offline warning hwne user tries to attempt network request while offline mode
 * @param {Props} props Props for the component
 * @returns {React.Node}
 */
function OfflineNotifier(props: Props) {
  return (
    <StatelessModal
      id="confirm-dialog"
      visible={props.showOfflinePrompt}
      title={translate('offline')}
      noButton
      onClose={() => props.onClose(false)}
      setVisible={() => {}}
      dataPublicHeader
    >
      <p className="u-margin--standard">{translate('offline_message')}</p>
      <ModalFooter>
        <Button dataPublic className="o-button o-button--small" onClick={() => props.onClose(false)}>{translate('okay')}</Button>
      </ModalFooter>
    </StatelessModal>
  );
}

export default OfflineNotifier;
