import React from 'react';
import { List, Set, Map } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import ListEditorTable from './../listEditor/listEditorTable';
import AddEditDrug from './addEditDrug';
import { createPermission, hasPermission } from './../../utils/permissions';
import Header from './../header/header';

import Button from './../buttons/button';
import type { MapValue, SaveModel, Config, Column, User, CustomColumn, DrugMap } from './../../types';
import type DrugModel from './../../models/drugModel';
import TableColumnsSettings from '../table/tableColumnsSettings';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type MasterDrugModel from '../../models/masterDrugModel';
import type DrugManufacturerModel from '../../models/drugManufacturerModel';
import type DosingRegimenModel from '../../models/dosingRegimenModel';
import { transformColumnKeys, transformFilteredColumns } from './../../utils/utils';


type Props = {
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  config: Config,
  columns: Array<Column>,
  items: List<DrugModel>,
  user: User,
  dispensationUnitOptions: Set<string>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugDurations: List<List<[number, string]>>,
  fetchTableMasterData: (ids: List<string>) => Promise<void>,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
  drugMap: DrugMap,
  setDrugManufacturers: (models: List<DrugManufacturerModel>) => void,
  drugManufacturers: List<DrugManufacturerModel>,
  dosingRegimens: List<DosingRegimenModel>,
  secondaryTableData?: {[key: string]: MapValue},
};

type State = {
  columns: Array<CustomColumn>,
  modelToEdit: DrugModel | void,
  addEditDrugModalVisible: boolean,
};

/**
 * A component for displaying, adding, and editing all Drugs.
 * @class Drugs
 * @extends {React.Component<Props, State>}
 */
class Drugs extends React.Component<Props, State> {
  originalColumns: List<CustomColumn> = List();

  /**
   * Creates an instance of Drugs.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.originalColumns = this.originalColumns.concat(transformColumnKeys(this.props.columns));
    this.state = {
      modelToEdit: undefined,
      columns: this.originalColumns.toArray(),
      addEditDrugModalVisible: false,
    };
  }

  /**
   * Renders the AddEditDrug component
   * @returns {ReactElement}
   */
  renderAddEditDrug() {
    return (
      <AddEditDrug
        config={this.props.config}
        modelToEdit={this.state.modelToEdit}
        saveModel={this.props.saveModel}
        drugMap={this.props.drugMap}
        coveragePayors={this.props.coveragePayors}
        dosingRegimens={this.props.dosingRegimens}
        dispensationUnitOptions={this.props.dispensationUnitOptions}
        setModelToEdit={(model) => { this.setState({ modelToEdit: model }); }}
        updateConfigValue={this.props.updateConfigValue}
        updateConfig={this.props.updateConfig}
        drugDurations={this.props.drugDurations}
        setAddEditDrugModalVisible={(visibility) => {
          this.setState({ addEditDrugModalVisible: visibility });
        }}
        fetchTableMasterData={this.props.fetchTableMasterData}
        masterDrugModelsMap={this.props.masterDrugModelsMap}
        setDrugManufacturers={this.props.setDrugManufacturers}
        drugManufacturers={this.props.drugManufacturers}
        saveIsSticky
        noClearButton
      />
    );
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <ContentTransition className="o-main__content">
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <Header className="o-header" dataPublic>
            <h1>{translate('inventory_items')}</h1>
            {
              hasPermission(this.props.user, List([createPermission('inventory_items', 'create')])) &&
              <Button
                key="add-new-drug"
                className="o-button o-button--small o-button--padded"
                onClick={() => { this.setState({ addEditDrugModalVisible: true }); }}
                dataPublic
              >
                {translate('add_new_drug')}
              </Button>
            }
            {this.state.addEditDrugModalVisible && this.renderAddEditDrug()}
          </Header>
          <div className="o-card u-margin-bottom--4ws">
            <Header className="o-card__header" dataPublic>
              <h2 data-public className="o-card__title">{translate('inventory_items')}</h2>
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="inventory_items"
                originalColumns={this.originalColumns}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
            </Header>
            <div className="o-header-actions">
              <div className="u-margin-left--half-ws u-margin-right--half-ws">
                {
              hasPermission(this.props.user, List([createPermission('inventory_items', 'create')])) &&
              <Button
                key="add-new-drug"
                className="o-button o-button--small o-button--padded"
                onClick={() => { this.setState({ addEditDrugModalVisible: true }); }}
                dataPublic
              >
                {translate('add_new_drug')}
              </Button>
            }
                {this.state.addEditDrugModalVisible && this.renderAddEditDrug()}
              </div>
            </div>
            <ListEditorTable
              onEditClicked={modelToEdit => this.setState({ modelToEdit, addEditDrugModalVisible: true })}
              saveModel={this.props.saveModel}
              items={this.props.items}
              columns={transformFilteredColumns(this.props.columns, this.state.columns)}
              canEdit={hasPermission(this.props.user, List([createPermission('inventory_items', 'update')]))}
              canDelete={hasPermission(this.props.user, List([createPermission('inventory_items', 'delete')]))}
              coveragePayors={this.props.coveragePayors}
              secondaryData={this.props.secondaryTableData}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default Drugs;
