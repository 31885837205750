import React from 'react';
import glamorous from 'glamorous';
import Moment from 'moment';
import type { List } from 'immutable';
import { fromJS } from 'immutable';
import Button from './../buttons/button';

import { wsUnit } from './../../utils/css';
import type { Config } from './../../types';
import LoadingIndicator from './../loadingIndicator';
import PatientStubModel from '../../models/patientStubModel';
import { identityFn } from './../../utils/utils';
import { debugPrint } from '../../utils/logging';

const ContentWrapper = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '& p': {
    maxWidth: '50%',
    textAlign: 'center',
  },
  '& button': {
    marginTop: wsUnit,
  },
});

type Props = {
  patientStubs: List<PatientStubModel>,
  config: Config,
};
type State = {
  isScanningCard: boolean,
};

type KridentiaJSON = { // NOTE: This is theoretical, needs testing.
  ReadMyKadResult: Array<string>,
};

type SecureMetricsErrorJSON = {
  error: {
    message: string
  },
  success: false
};

type SecureMetricsReadersJSON = {
  payload: {
    readers: Array<{
      name: string
    }>
  },
  success: true
};

/* eslint-disable camelcase */
type SecureMetricsMyKadData = {
  address1: string,
  address2: string,
  address3: string,
  citizenship: string,
  city: string,
  date_of_birth: string,
  gender: string,
  gmpc_name: string,
  ic_no: string,
  issue_date: string,
  kpt_name: string,
  name: string,
  old_ic_no: string,
  place_of_birth: string,
  postcode: string,
  race: string,
  religion: string,
  state: string,
};

type SecureMetricsMyKidData = {
  address1: string,
  address2: string,
  address3: string,
  birth_cert_no: string,
  citizenship: string,
  city: string,
  date_of_birth: string,
  father: {
      ic_no: string,
      name: string,
      race: string,
      religion: string,
      resident_type: string,
  },
  gender: string,
  ic_no: string,
  mother: {
      ic_no: string,
      name: string,
      race: string,
      religion: string,
      resident_type: string,
  },
  name: string,
  place_of_birth: string,
  postcode: string,
  registration_date: string,
  state: string,
  time_of_birth: string,
};

type SecureMetricsMyKadPayload = {
  card_type: 10241,
  mykad: SecureMetricsMyKadData
};

type SecureMetricsMyKidPayload = {
  card_type: 10242,
  mykid: SecureMetricsMyKidData
}
/* eslint-enable camelcase */

type SecureMetricsCardJSON = {
  payload: SecureMetricsMyKadPayload | SecureMetricsMyKidPayload,
  success: true
};

// translate common ethnicities
const ETHNICITY_MAP = {
  CINA: 'Chinese',
  MELAYU: 'Malay',
  MALAYU: 'Malay',
  INDIAN: 'Indian',
  INDIA: 'Indian',
};

// list of supported mykad data with transforms if any.
const SECUREMETRICS_MAP_FUNCTIONS = {
  sex: (value: string) => ({ L: 'Male', P: 'Female' }[value] || ''),
  ethnicity: (value: string) => (ETHNICITY_MAP[value] !== undefined ? ETHNICITY_MAP[value] : value),
  nationality: (value: string) => (value === 'WARGANEGARA' ? 'Malaysia' : ''),
};

// A mapping of Mykad values to keys used by the Patient Registration page. Doesnt exactly match the PatientModel.
const SECUREMETRICS_RESPONSE_MAPPING = {
  name: 'name',
  ic_no: 'ic',
  gender: 'sex',
  race: 'ethnicity', // not available in mykid
  citizenship: 'nationality',
  date_of_birth: 'dob',
  address1: 'address1',
  address2: 'address2',
  address3: 'address3',
  postcode: 'postal_code',
  city: 'city',
  state: 'state',
};

const KRIDENTIA_MAP_FUNCTIONS = {
  ...SECUREMETRICS_MAP_FUNCTIONS,
  dob: dob => Moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD'),
};

// A mapping of Mykad values to keys used by the Patient Registration page. Doesnt exactly match the PatientModel.
// Index should match index in KridentiaJSON
const KRIDENTIA_RESPONSE_MAPPING = [
  undefined, // ignore - success
  'name',
  'ic',
  'sex',
  'ethnicity',
  'nationality',
  undefined, // ignore - religion
  'dob',
  undefined, // ignore - birth_place
  'address1',
  'address2',
  'address3',
  'postal_code',
  'city',
  'state',
  undefined, // ignore - fingerprint
  undefined, // ignore - fingerprint
  undefined, // ignore - other id
  undefined, // ignore - photo
];

/**
 * Checks response data from Kridentia and throws errors if anything looks incorrect.
 * @param {Array<string>} data The data received from the MyKad server
 * @returns {void}
 */
function verifyKridentiaResponse(data: Array<string>) {
  if (!data) {
    throw new Error('Malformed response from the MyKad Reader server.');
  } else if (
    data[0] !== '"Done"' ||
    !(data.length >= 3) ||
    !data[1] ||
    data[1].length === 0 ||
    !data[2] ||
    data[2].length === 0
  ) {
    // This checks if the Patient Name and IC are both present in the response. If they aren't
    // then we can't do anything with the response, so it's considered an error.
    throw new Error('Either the patient name or IC number was missing in the response from the Mykad Server.');
  }
}

/**
 * Returns the patient data from a mykad / mykid payload
 * @param {SecureMetricsMyKadPayload | SecureMetricsMyKidPayload} payload payload field of the SecureMetrics response.
 * @return {SecureMetricsMyKadData | SecureMetricsMyKidData} patient data
 */
function getSecureMetricsMyKadData(payload: SecureMetricsMyKadPayload | SecureMetricsMyKidPayload) {
  return payload.card_type === 10242 ? payload.mykid : payload.mykad;
}

/**
 * Checks response data from SecureMetrics and throws errors if anything looks incorrect.
 * @param {SecureMetricsCardJSON} jsonResponse The data received from the MyKad server
 * @returns {void}
 */
function verifySecureMetricsResponse(jsonResponse: SecureMetricsCardJSON | SecureMetricsErrorJSON) {
  if (!jsonResponse) {
    throw new Error('Malformed response from the MyKad Reader server.');
  } else if (!jsonResponse.success) {
    throw new Error(jsonResponse.error.message);
  } else if (!jsonResponse.payload) {
    throw new Error('Malformed response from the MyKad Reader server.');
  } else {
    const patientData = getSecureMetricsMyKadData(jsonResponse.payload);
    if (!patientData.ic_no || patientData.ic_no.length === 0 ||
      !patientData.name || patientData.name.length === 0) {
      throw new Error('Either the patient name or IC number was missing in the response from the Mykad Server.');
    }
  }
}


/**
 * Converts the KridentiaMyKad data to a URL query string.
 * @param {Array<string>} data The Mykad data
 * @returns {string}
 */
function getQueryStringKridentia(data: Array<string>): string {
  return data
    .map((v, i) => {
      if (KRIDENTIA_RESPONSE_MAPPING.length <= i) {
        return undefined;
      }
      const field = KRIDENTIA_RESPONSE_MAPPING[i];
      if (field === undefined) {
        return undefined;
      }
      const transformFn = KRIDENTIA_MAP_FUNCTIONS[field] || identityFn;
      return `${field}=${encodeURIComponent(transformFn(v))}`;
    })
    .filter(v => v !== undefined)
    .join('&');
}

/**
 * Converts the KridentiaMyKad data to a URL query string.
 * @param {SecureMetricsMyKadData | SecureMetricsMyKidData} data The Mykad data
 * @returns {string}
 */
function getQueryStringSecureMetrics(
  data: SecureMetricsMyKadData | SecureMetricsMyKidData,
): string {
  return fromJS(data)
    .mapEntries(([k, v]) => {
      const field = SECUREMETRICS_RESPONSE_MAPPING[k];
      if (field === undefined) {
        return [k, undefined];
      }
      const transformFn = SECUREMETRICS_MAP_FUNCTIONS[field] || identityFn;
      return [k, `${field}=${encodeURIComponent(transformFn(v))}`];
    })
    .valueSeq()
    .filter(v => v !== undefined)
    .join('&');
}

/**
 * A higher order component that can acts as a Modal containing any passed children.
 * @class Modal
 * @extends {Component}
 */
class Mykad extends React.Component<Props, State> {
  /**
   *Creates an instance of Modal.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isScanningCard: true,
    };
    this.scanCard();
  }

  /**
   * Scan MyKad reader using the Kridentia SDK. verify the response.
   * Redirect to registration page, patient page or throw an erroras appropriate.
   * @param {string} url Url of the Kridentia MyKad SDK
   * @return {void}
   */
  scanCardKridentia(url: string) {
    fetch(new Request(url), {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => response.json())
      .then((jsonResponse: KridentiaJSON) => {
        const data = jsonResponse.ReadMyKadResult;
        verifyKridentiaResponse(data);
        const cleanData = data.map(s => (s.length > 1 ? s.slice(1, -1) : s)); // Remove extraneous quotes.
        const icNumber = cleanData[2];
        const patientStub = this.props.patientStubs.find(p => p.get('ic') === icNumber);
        if (patientStub) {
          location.hash = `/patient/${patientStub.get('_id')}`;
        } else {
          location.hash = `/registration?${getQueryStringKridentia(cleanData)}`;
        }
      })
      .catch((error) => {
        debugPrint(error, 'error');
        this.setState({ isScanningCard: false });
      });
  }


  /**
   * Scan MyKad reader using the SecureMetrics SDK. verify the response.
   * If multiple, mykad readers are connected, tries to read from the first one.
   * We need to change this to allow the user to pick
   * Redirect to registration page, patient page or throw an erroras appropriate.
   * @param {string} url Url of the SecureMetrics MyKad SDK
   * @return {void}
   */
  scanCardSecureMetrics(url: string) {
    const listReaderCommand = `${url}/listreaders`;
    const readCardCommand = `${url}/readcard`;
    fetch(new Request(listReaderCommand), {
      method: 'GET',
      mode: 'cors',
    })
      .then(response => response.json())
      .then((jsonResponse: SecureMetricsReadersJSON | SecureMetricsErrorJSON) => {
        if (!jsonResponse.success) {
          throw new Error(jsonResponse.error.message);
        }
        if (jsonResponse.payload.readers.length === 0) {
          throw new Error('No MyKad readers connected.');
        }
        const readerName = jsonResponse.payload.readers[0].name;
        const readCardFromReaderCommand = `${readCardCommand}?{"reader":"${readerName}","read_photo":false}`;
        return fetch(new Request(readCardFromReaderCommand), {
          method: 'GET',
          mode: 'cors',
        });
      })
      .then(response => response.json())
      .then((jsonResponse: SecureMetricsCardJSON | SecureMetricsErrorJSON) => {
        // get mykad or mykid, and then process the data.
        verifySecureMetricsResponse(jsonResponse);
        const patientData: SecureMetricsMyKadData | SecureMetricsMyKidData
          = getSecureMetricsMyKadData(jsonResponse.payload);
        const icNumber = patientData.ic_no;
        const patientStub = this.props.patientStubs.find(p => p.get('ic') === icNumber);
        if (patientStub) {
          location.hash = `/patient/${patientStub.get('_id')}`;
        } else {
          location.hash = `/registration?${getQueryStringSecureMetrics(patientData)}`;
        }
      })
      .catch((error) => {
        debugPrint(error, 'error');
        this.setState({ isScanningCard: false });
      });
  }

  /**
   * Try to guess the vendor from the url structure.
   * @param {string} url url of the SDK to connect to.
   * @returns {string | undefined} kridentia or securemetrics
   */
  guessMyKadVendor(url: string) {
    // https://local.myidreader.com:48180/readcard?{%22reader%22:%22Feitian%20SCR301%200%22,%22read_photo%22:true}
    // https://localhost:2020/BioPakWeb/ReadMyKad
    // test urls will include kridentia / securemetrics keywords
    if (url.includes('BioPakWeb') || url.includes('kridentia')) {
      return 'kridentia';
    } else if (url.includes('48180') || url.includes('securemetrics')) {
      return 'securemetrics';
    }
    return undefined;
  }

  /**
   *  Scans the Mykad and either reroutes the user to the patient or patient registration if they
   *  don't already exist.
   * @returns {void}
   */
  scanCard() {
    const url = this.props.config.getIn(['mykad_url']);
    const vendor = this.props.config.getIn(['mykad_vendor']) || this.guessMyKadVendor(url);
    if (url === undefined) {
      debugPrint('url is not defined in mykad_url config', 'error');
      this.setState({ isScanningCard: false });
      return;
    }

    if (vendor === 'kridentia') {
      this.scanCardKridentia(url);
    } else if (vendor === 'securemetrics') {
      this.scanCardSecureMetrics(url);
    } else {
      debugPrint('Cannot determine vendor of mykad sdk from mykad_url or mykad_vendor config', 'error');
      this.setState({ isScanningCard: false });
    }
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return this.state.isScanningCard ?
      (
        <ContentWrapper>
          <LoadingIndicator />
          <p>Reading MyKad</p>
        </ContentWrapper>
      ) :
      (
        <ContentWrapper>
          <p>
            The MyKad could not be read. Please check that the scanner is connected to the
            computer and that the MyKad is correctly placed in the scanner.
          </p>
          <Button
            className="o-button"
            onClick={() => {
              this.setState({ isScanningCard: true });
              this.scanCard();
            }}
            dataPublic
          >
            Scan MyKad
          </Button>
        </ContentWrapper>
      );
  }
}

export default Mykad;
