/* eslint-disable max-lines */
import { List, Set, Map, isImmutable, fromJS } from 'immutable';
import { pickBy } from 'lodash';

import EncounterModel from '../models/encounterModel';
import BillModel from '../models/billModel';
import BillItemModel from '../models/billItemModel';
import {
  getBaseUrl, getBaseApiUrl, getClinicID,
  generateGUID, getAppointmentUpdateAPIType, queryMapping, getServerName, getPrependString,
} from './utils';
import { handleApiError, handleUnauthorisedApiResponse } from './response';
import { queryDesignDoc, fetchModels, fetchFromView } from './db';
import { getClaimByCoveragePayorWithBillsBillItemsAndEncounters, getSupplyItemDocs } from '../dataViews';
import AnalyticsSummaryItemModel from './../models/analytics/summaryItemModel';
import AnalyticsReportModel from '../models/analytics/reportModel';
import AnalyticsQueryResponseModel from '../models/analytics/queryResponseModel';
import BrandResponseModel from '../models/pharmaconnect/brandResponseModel';
import ReadDocumentsModel from '../models/pharmaconnect/readDocumentsModel';
import DetailingResponseModel from '../models/pharmaconnect/detailingResponseModel';
import { DDOC_VERSION_INVENTORY_3, DOC_VALIDATION_ENABLED } from './../constants';
import { toType } from './lang';
import translate from './i18n';
import { docToModel, genericDocToModel, foreignKeyToDocType, getModelMapFromList } from './models';
import PatientStubModel from './../models/patientStubModel';
import { logMessage, logToAppInsight, debugPrint } from './logging';
import { fetchWithRetry } from './retry';
import APIError from './apiError';
import { offlinePromptHandler, getIsOnline, setIsOnline } from './offline';

import {
  updateUnsyncedAPICalls, setUnsyncedAPICalls, deleteUnsyncedAPICalls, updateCurrentDataViewsModel,
  replaceCurrentDataViewsModel,
  clearModels,
  clearCurrentDataViewsModels,
  setDocumentsPendingRegeneration,
  updateModels,
} from './../actions';

import TransactionModel from './../models/transactionModel';

import type BaseModel from './../models/baseModel';
import type {
  Model, ClaimInvoiceMetadata, SaveInventoryMappingResponse,
  DataView, Dispatch, APIResponse, AnalyticsReport as AnalyticsReportJSON,
  AnalyticsReportsListResponse, AnalyticsKeyMetrics, AnalyticsKeyMetricsResponse,
  AnalyticsReportDetailResponse, AnalyticsQuery as AnalyticsQueryJSON,
  AnalyticsQueryDetailResponse, PharmaConnectBrandDocumentResponseJSON,
  PharmaconnectDocResponseJSON, AppointmentData, AppointmentAPIResponse, FetchResponse,
  SuggestInventoryMappingResponse, CouchResponse, MissingDocObject, MapValue, DownloadATDPSResponse,
  BlockOffTimeAPIResponse,
  BlockOffTime,
  AppointmentStatus,
} from './../types';
import type SMSJobModel from './../models/smsJobModel';
import type AppointmentModel from '../models/appointmentModel';
import { createStaticErrorNotification } from './notifications';
import PatientModel from '../models/patientModel';
import PaymentModel from '../models/paymentModel';
import ReceivableModel from '../models/receivableModel';
import ClaimModel from '../models/claimModel';
import { CampaignRules, CampaignRuleType } from '../models/masterCampaignModel';

const MCDaysByEncounterDesignDoc = `${queryMapping.get('mcDaysByEncounterID')?.queryDDOCView}`;

/**
 * Generic error handling for API calls.
 * @param {any} error The Error being returned
 * @param {string} designDoc The design doc path.
 * @returns {void}
 */
export function handleError(error: any, designDoc: string) {
  handleApiError({ error, designDoc });
}


/**
 * Converts an API constant to the actual api endpoint
 * @param {string} api List of APIs
 * @param {object} params object with url params
 * @returns {string} the api endpoint
 */
function getDataEndpoint(api: string, params: object) {
  switch (api) {
    case 'DRUG_MAPPINGS':
      return `masterdata/drugs/mappings/${params.get('clinicID')}`;
    case 'PRESCRIBED_DRUG_MAPPINGS':
      return `masterdata/drugs/mappings/${params.get('clinicID')}`;
    case 'DRUG_CLINIC':
      return `masterdata/drugs/clinic/${params.get('clinicID')}`;
    case 'DRUG_MASTER':
      return 'masterdata/drugs/master';
    case 'DRUG_MASTER_SEARCH':
      return 'masterdata/drugs/master/search';
    case 'DRUG_SUGGESTION':
      return `masterdata/drugs/suggestions/${params.get('clinicID')}`;
    case 'PATIENT_CAMPAIGN_SETS':
      return 'campaigns/campaign_set';
    case 'PATIENT_CAMPAIGN_LIST':
      return `campaigns/campaign_set/${params.get('campaignSetId')}/campaign`;
    case 'PATIENT_CAMPAIGN':
      return `campaigns/campaign/${params.get('campaignID')}`;
    case 'PATIENT_CAMPAIGN_JOBS':
      return `campaigns/campaign/${params.get('campaignID')}/jobs`;
    case 'PATIENT_CAMPAIGN_SINGLE_JOB':
      return `campaigns/jobs/${params.get('jobID')}`;
    case 'FILTERED_CAMPAIGN_JOBS':
      return 'campaigns/campaign/list';
    case 'TIME_FILTERED_TRANSACTION_DOCS':
      return 'api/transactions';
    case 'DRUG_MANUFACTURERS':
      return 'masterdata/drugs/manufacturers';
    case 'CLINIC_LIST':
      return 'api/stats/doctor_search';
    case 'DASHBOARD_STATS':
      return `api/stats/${params.get('interval')}/${params.get('clinicId')}`;
    case 'DASHBOARD_STATS_ALL':
      return `api/stats/${params.get('interval')}`;
    default:
      return null;
  }
}

const VALUES_FUNCS = {
  // Cant directly use functions because the dataviews has to be a clean map
  // else it goes into an infinite loop becasue `equals` fails
  KEY_TO_KEY_BILL: ((key: string) => [key, 'bill']),
  KEY_TO_KEY_BILL_ITEM: ((key: string) => [key, 'bill_item']),
  KEY_FOR_BILL_ITEM_TRANSACTION: ((key: string, model: TransactionModel) => {
    if (model.get('source_type') === 'bill_item') {
      return key;
    }
    return null;
  }),
  KEY_FOR_SPECIFIC_CAMPAIGN: ((filter: CampaignRules) => {
    const specificCampaingJobFilter = filter.conditions
      .find(r => r.type === CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME ||
        r.type === CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME);
    if (specificCampaingJobFilter && specificCampaingJobFilter.metadata.campaign_id) {
      return specificCampaingJobFilter.metadata.campaign_id;
    }
    if (specificCampaingJobFilter && specificCampaingJobFilter.metadata.master_campaign_id) {
      return getPrependString(`mc-${getServerName()}-`, specificCampaingJobFilter.metadata.master_campaign_id);
    }
    return null;
  }),
};

/**
 * Sends a GET request to the API at the given endpoint, and handles erros
 * Will throw APIError if any the requests fail
 * @param {DataView} dataView API endpoint to retrieve data from. // { api: string, doc_type: string }
 * @returns {Promise<List<BaseModel>>} Promise that resolves to the data from the endpoint
 * @throws {Promise<APIError>} details of the failed API call
 */
export function fetchDocsFromApi(dataView: DataView): Promise<List<BaseModel | void>> {
  const endpoint = getDataEndpoint(dataView.get('api'), dataView.get('params')) || '';
  if (getIsOnline()) {
    const docType = dataView.get('doc_type') || '';
    const customURL = dataView.get('customURL') || '';
    const isMock = dataView.get('mock') || false;
    const isSingle = dataView.get('single') || false;
    const query = dataView.get('query') ? dataView.get('query').toJS() : {};
    if ((endpoint === '' || docType === '') && !customURL) {
      logToAppInsight(`Bad Request ${endpoint} ${docType}`, null);
      throw new Error('Incorrectly defined "view" field in DataView.');
    }

    const opts = Object.assign({}, {
      credentials: 'same-origin',
    },
    dataView.get('data') ? {
      method: 'POST',
      body: JSON.stringify(dataView.get('data')),
      headers: {
        'Content-Type': 'application/json',
      },
    } : {});
    const queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    const url = customURL || `${isMock ? process.env.KLINIFY_MOCK_API_URL : getBaseUrl()}/${endpoint}`;
    const urlWithQuery = queryString ? `${url}?${queryString}` : `${url}`;
    return fetchWithRetry(urlWithQuery, opts)
      .catch((error) => {
        const errorMessage = `API ${endpoint} failed`;
        handleApiError({ error, message: errorMessage });
        throw new APIError(`Call to ${endpoint} failed`, error.status, error);
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then((json) => {
            if (isSingle) {
              if (!json && toType(json) !== 'object' && json.type !== docType) {
                throw new APIError(`Bad data from ${endpoint}`, response.status, response);
              }
              return List([genericDocToModel(json, docType)]);
            }

            // The standard is supposed to be total_rows and rows.
            // But Old APIs return 'content', and stats returns 'results' or 'data'
            const content = json.content || json.rows || json.results || json.data;
            if (!content && !Array.isArray(content)) {
              throw new APIError(`Bad data from ${endpoint}`, response.status, response);
            }
            if (dataView.get('api') === 'DRUG_MASTER_SEARCH') {
              return content || List();
            }
            return List(content.map((doc: object) => (typeof doc === 'object' ? genericDocToModel(doc, docType) : undefined)));
          });
        }
        if (response && response.status !== 401 && response.status !== 200) {
          const apiError = new APIError(`API ${endpoint} faled`, response?.status, response?.json().then((data: any) => data));
          handleApiError({ error: apiError });
        }
        throw new APIError(`Call to ${endpoint} failed`, response.status, response);
      });
  }
  offlinePromptHandler();
  throw new APIError(`Call to ${endpoint} failed`, 0, {});
}

/**
 * This function fetches the data from the specified API / Couchdb View and returns a list of models.
 * Will throw an APIError if ANY of the dataview calls return an error.
 * @param {List} dataViews The couchdb view / API for this container.
 * @param {List<Model>} previousModels Models previously fetched for this set of views.
 * @returns {Promise<List<BaseModel | void>>} An array of models created from the documents fetched.
 * @throws {Promise<APIError>} details of the failed API call
 * @throws {Error} If the dataviews are incorrectly defined.
 */
export function fetchData(
  dataViews: List<DataView>,
  previousModels: List<Model> = List(),
): Promise<List<BaseModel | void>> {
  return Promise
    .all(dataViews.map((view) => {
      const fetch = ((_view) => {
        if (_view.has('api')) {
          const endpoint = _view.get('api');
          if (typeof endpoint !== 'string') {
            throw new Error('Incorrectly defined "api" field in DataView.');
          }
          return fetchDocsFromApi(_view);
        }
        return fetchFromView(_view);
      })(view);

      if (fetch === undefined) {
        const retval: Promise<List<Model>> = Promise.resolve(List());
        return retval;
      }

      if (view.has('fetchFromValues')) {
        return fetch
          .then((models) => {
            const subView = view.get('fetchFromValues');
            if (!subView || !Map.isMap(subView)) { // First check is just to let Flow know its defined.
              throw new Error('Incorrectly defined "fetchFromValues" field in DataView.');
            }
            const fetchFromValuesFunc = view.get('fetchFromValuesFunc');
            const fetchFromValuesFuncData = view.get('fetchFromValuesFuncData');
            const keyFunc = (fetchFromValuesFunc && VALUES_FUNCS[fetchFromValuesFunc]) ?
              VALUES_FUNCS[fetchFromValuesFunc] :
              (key => key);
            const fetchFromValuesKeys = view.get('fetchFromValuesKeys');
            if (!(List.isList(fetchFromValuesKeys) && fetchFromValuesKeys.size > 0)) {
              throw new Error('Incorrectly defined "fetchFromValuesKeys" field in DataView.');
            }
            const subKeys = Array.isArray(models) || isImmutable(models)
              ? models.reduce((keys, model) => fetchFromValuesKeys.reduce((modelKeys, key) => {
                const field = model.get(key);
                if (field) {
                  const haveKey = keyFunc(field, model, fetchFromValuesFuncData);
                  if (haveKey) {
                    return modelKeys.push(keyFunc(field, model, fetchFromValuesFuncData));
                  }
                }
                return modelKeys;
              }, keys), List()).toArray()
              : [];
            // filter out duplicate ids here
            const keysUnique = subKeys ? Set(subKeys).toArray() : subKeys;
            const options = fromJS({ keys: keysUnique, include_docs: true });
            return fetchData(List([subView.set('options', options)]), previousModels.concat(List(models)));
          });
      }
      return fetch.then(models => previousModels
        .concat(List(Array.isArray(models) || isImmutable(models) ? models : [])));
    }))
    .then(values => values.reduce((a, b) => a.concat(b), List()))
    .catch((error) => {
      handleApiError({ error });
      if (error?.status === 0) {
        return Promise.resolve([]);
      }
      throw error;
    });
}

/**
 * Tries to find an MC matching the given encounter ID. If one is found the number of days of the MC
 * is returned. If none then 0 is returned.
 * @param {string | Array<string>} encounterID An encounter ID or a list of encounterIDs to find an MC for.
 * @returns {Promise} A promise that resolves to the number of MC days.
 */
export function getMCDaysForEncounter(
  encounterID: string | Array<string>,
): Promise<number | Map<string, number>> {
  if (!Array.isArray(encounterID) && typeof encounterID !== 'string') {
    return Promise.reject(new Error('Expected a encounterID or array of encounterID'));
  }
  const handleResults = (results) => { // eslint-disable-line require-jsdoc
    if (Array.isArray(encounterID)) {
      return results.rows
        .reduce((resultMap: Map<string, number>, b: { value: string, key: string }) => resultMap.set(
          b.key,
          parseInt(resultMap.get(b.key, 0), 10) + parseInt(b.value, 10),
        ), Map());
    }
    if (!results || !results.rows || !results.rows.length) {
      return 0;
    }
    return results.rows
      .map(row => row.value)
      .reduce((a, b) => parseInt(a, 10) + parseInt(b, 10));
  };
  return queryDesignDoc(
    MCDaysByEncounterDesignDoc,
    { [Array.isArray(encounterID) ? 'keys' : 'key']: encounterID },
  )
    .then(handleResults)
    .catch((error) => {
      const errorMessage = `Could not get MC days for encounter: ${encounterID}`;
      handleApiError({ error, message: errorMessage });
      return Map();
    });
}

/**
 * Returns a List of all Conditions for a given Encounter
 * @param {string | Array<string>} encounterId The encounter Id or a list of encounterIds
 * @returns {Promise<List<Model>>}
 */
export function getConditionsForEncounter(
  encounterId: string | Array<string>,
): Promise<List<Model> | Map<string, List<Model>>> {
  if (!Array.isArray(encounterId) && typeof encounterId !== 'string') {
    return Promise.reject(new Error('Expected a encounterID or array of encounterID'));
  }
  const handleResults = (results) => { // eslint-disable-line require-jsdoc
    if (Array.isArray(encounterId)) {
      return results.rows
        .reduce((resultMap, row) => {
          const model = docToModel(row.doc);
          if (model) {
            return resultMap.set(
              row.key[0],
              resultMap.get(row.key[0], List()).push(model),
            );
          }
          return resultMap;
        }, Map());
    }
    return List(results.rows)
      .map(row => docToModel(row.doc))
      .filter(model => model !== undefined); // If model type is not found docToModel will return undefined so we need to filter.
  };
  const getKeys = () => { // eslint-disable-line require-jsdoc
    if (Array.isArray(encounterId)) {
      return {
        keys: encounterId.map(id => [id, 'condition']),
      };
    }
    return {
      key: [encounterId, 'condition'],
    };
  };
  return queryDesignDoc(
    `${queryMapping.get('allByEncounterIdAndType')?.queryDDOCView}`,
    {
      ...getKeys(),
      include_docs: true,
    },
  )
    .then(handleResults)
    .catch((error) => {
      handleError(error, `${queryMapping.get('allByEncounterIdAndType')?.queryDDOCView}`);
      return Map();
    });
}

/**
 * Fetches all patient docs matching the given ids.
 * @param {List<string>} patientIDs A List of patient IDs.
 * @returns {Promise<Array<PatientModel>>} The array of models fetched (it can be assumed that they are
 * all patient models).
 */
export function fetchPatientsByID(patientIDs: List<string>): Promise<Array<Model>> { // We know its definitely return a PatientModel but we need to say any because flow cant work that out.
  return fetchModels(Set(patientIDs).toArray()).then(models => models.toArray()).catch(() => []);
}

/**
 * Fetches all patients from DB as stubs.
 * @param {boolean} allowOffline Boolean to indicate this fetch can fail silently if Offline
 * @returns {Array<PatientStubModel>} An array of PatientStubModels.
 */
export function fetchPatientStubs(allowOffline: boolean = false):
  Promise<Array<PatientStubModel> | null | undefined> {
  return queryDesignDoc('patientStubsWithTel/patientStubs', undefined, allowOffline)
    .then(response => response.rows.map(row => new PatientStubModel(row.value)))
    .catch((error) => {
      handleError(error, 'patientStubsWithTel/patientStubs');
      return Promise.reject(error);
    });
}

/**
 * Fetches patientStubs from the given array of patient IDs.
 * @param {Array<string>} patientIDs An array of couch IDs.
 * @returns {Promise<Array<PatientStubModel>>} A promise returning an array of patientStub models.
 */
export function fetchPatientStubsByID(patientIDs: List<string>): Promise<Array<PatientStubModel>> {
  const keys = patientIDs && patientIDs.size > 0 ? Set(patientIDs).toArray() : [];
  return queryDesignDoc('patientStubsWithTel/patientStubs', { keys })
    .then(response => response.rows.map((row: { value: {} | undefined; }) => new PatientStubModel(row.value)))
    .catch(error => handleError(error, 'patientStubsWithTel/patientStubs'));
}

/**
 * Fetches patients from the given array of patient IDs.
 * @param {Array<string>} patientIDs An array of couch IDs.
 * @param {boolean} availableOffline Boolean to indicate this operation can be called while offline
 * @returns {Promise<Array<BaseModel>>} A promise returning an array of models.
 */
export function fetchDocsForPatients(patientIDs: Array<string>, availableOffline?: boolean):
  Promise<Array<BaseModel>> {
  return queryDesignDoc(`${queryMapping.get('patientRelatedDocs')?.queryDDOCView}`, { keys: Set(patientIDs).toArray(), include_docs: true }, availableOffline)
    .then(
      response => response.rows
        .map((row: { doc: { [key: string]: any; type: string; }; }) => docToModel(row.doc))
        .filter(model => model !== undefined), // If model type is not found docToModel will return undefined so we need to filter.
    )
    .catch(error => handleError(error, `${queryMapping.get('patientRelatedDocs')?.queryDDOCView}`));
}

/**
 * Fetches the ids of all scheduled patients.
 * @returns {Promise<Array<string>>} The fetched patient IDs.
 */
function fetchScheduledPatients(): Promise<Array<string>> {
  const startkey = new Date().setHours(0, 0, 0, 0);
  return queryDesignDoc(`${queryMapping.get('encountersByLastEventTime')?.queryDDOCView}`, {
    startkey,
    endKey: startkey + 86400000,
    include_docs: true,
  })
    .then(
      response => response.rows
        .map((row: { doc: { [key: string]: any; type: string; }; }) => docToModel(row.doc))
        .filter(model => model !== undefined), // If model type is not found docToModel will return undefined so we need to filter.
    )
    .catch((error) => {
      handleError(error, `${queryMapping.get('encountersByLastEventTime')?.queryDDOCView}`);
      return [];
    });
}

/**
 * Fetches the ids of all patients with incomplete encounters. Note, there may be duplicates!
 * @returns {Promise<Array<string>>} The fetched patient IDs.
 */
function fetchPatientsWithIncompleteEncounters(): Promise<Array<string>> {
  return queryDesignDoc(`${queryMapping.get('patientsWithIncompleteEncounters')?.queryDDOCView}`, {
    include_docs: true,
  })
    .then(
      response => response.rows
        .map((row: { doc: { [key: string]: any; type: string; }; }) => docToModel(row.doc))
        .filter(model => model !== undefined), // If model type is not found docToModel will return undefined so we need to filter.
    )
    .catch((error) => {
      handleError(error, `${queryMapping.get('patientsWithIncompleteEncounters')?.queryDDOCView}`);
      return [];
    });
}

/**
 * Combines fetchScheduledPatients and fetchPatientsWithIncompleteEncounters and gets all related
 * patient documents that will be needed by the app.
 * @returns {Promise<Array<BaseModel>>} A promise returning all models related to patients that need
 * to be cached.
 */
export function fetchPatientsToBeCached(): Promise<Array<BaseModel>> {
  return Promise.all([fetchScheduledPatients(), fetchPatientsWithIncompleteEncounters()])
    .then((results) => {
      const encounterArr = Set(results[0].concat(results[1])).toArray();
      return encounterArr;
    });
}

/**
 * Fetches the inventory count.
 * @returns {Promise<Map<string, number>>} A promise returning a Map with key of SKU and value of
 * SKU count. If an error occurs undefined is returned.
 */
export function fetchInventoryCount(): Promise<Map<string, number> | void> {
  return queryDesignDoc(`${queryMapping.get('inventoryCount')?.queryDDOCView}`, { group: true })
    .then((response: CouchResponse) => {
      if (!response || !response.rows) {
        return undefined;
      }
      let map = Map();
      response.rows.forEach((row) => {
        map = map.set(row.key, row.value);
      });
      return map as Map<string, number>;
    })
    .catch((error) => {
      handleError(error, `${queryMapping.get('inventoryCount')?.queryDDOCView}`);
      return Promise.reject(error);
    });
}


/**
 * Find all docs for the given encounter id filtered by a specific 'type'
 * @param {string} encounterIDs An array of encounter IDs to find documents for
 * @param {string} docType The type of doc to filter.
 * @returns {Promise} A promise returning all documents with the given list of encounterIds, further filtered by the type filter
 * @todo make the docType optional
 */
export function getDocsForEncounter(
  encounterIDs: Array<string>,
  docType: string,
): Promise<Array<BaseModel>> {
  const keys = Set(encounterIDs).map(encounterID => [encounterID, docType]);
  return queryDesignDoc(`${queryMapping.get('allByEncounterIdAndType')?.queryDDOCView}`, { keys, include_docs: true })
    .then(
      response => response.rows
        .map(row => docToModel(row.doc))
        .filter(model => model !== undefined), // If model type is not found docToModel will return undefined so we need to filter.
    )
    .catch(error => handleError(error, `${queryMapping.get('allByEncounterIdAndType')?.queryDDOCView}`));
}

/**
 * Gets all models associated with a Bill
 * @param {string} billID The bill ID
 * @returns {Promise<List<Model>>}
 */
export function getModelsForBill(billID: string): Promise<List<Model>> {
  return queryDesignDoc(`${queryMapping.get('allByBillIdAndType')?.queryDDOCView}`, { key: [billID, '_any'], include_docs: true })
    .then(
      // If model type is not found docToModel will return undefined so we need to filter.
      response => List(response.rows.map(
        row => docToModel(row.doc),
      ).filter(<T extends BaseModel>(model: T | void) => model !== undefined)),
    )
    .catch((error) => {
      handleError(error, `${queryMapping.get('allByBillIdAndType')?.queryDDOCView}`);
      return Promise.reject();
    });
}

/**
 * Gets all models associated with a list of Bills
 * @param {List<string>} billIds The bill IDs
 * @param {?string} type The type of the model
 * @returns {Promise<List<Model>>}
 */
export function getModelsForBills(billIds: List<string>, type: string = '_any'): Promise<List<Model>> | void {
  return queryDesignDoc(`${queryMapping.get('allByBillIdAndType')?.queryDDOCView}`, {
    keys: Set(billIds).map(billId => [billId, type]),
    include_docs: true,
  })
    .then(
      // If model type is not found docToModel will return undefined so we need to filter.
      response => List(response.rows.map(
        row => docToModel(row.doc),
      ).filter(<T extends BaseModel>(model: T | void) => model !== undefined)),
    )
    .catch((error) => {
      handleError(error, `${queryMapping.get('allByBillIdAndType')?.queryDDOCView}`);
      return List();
    });
}

/**
 * Fetches all claims and related documents given a time range
 * @param {string} coveragePayorId ID of the coverage payor
 * @param {number} startkey The start of the time range.
 * @param {number} endkey The end of the time range.
 * @returns {Promise<List<Model> | void>} Promise<List<BillItemModel | ClaimModel | BillModel | EncounterModel>>
 */
export function fetchClaimsForCoveragePayorWithBillsBillItemsAndEncounters(
  coveragePayorId: string, startkey: number, endkey: number,
): Promise<List<Model> | void> {
  return fetchFromView(
    getClaimByCoveragePayorWithBillsBillItemsAndEncounters(coveragePayorId, startkey, endkey),
  )
    .catch(error => handleError(error, 'api.js => fetchClaimsWithBillsBillItemsAndEncounters'));
}

/**
 * Attempts to find a User Config document for the given user ID in the database. If one or mores is
 * found then the first one is returned, otherwise undefined is returned.
 * @param {string} userId The user ID to match to.
 * @returns {Promise<?Model>}
 */
export function getUserConfigByUserId(userId: string): Promise<Model | null | undefined> {
  return queryDesignDoc(`${queryMapping.get('userConfigsByUserId')?.queryDDOCView}`, { key: userId, include_docs: true })
    .then(
      response => List(
        response.rows.map(row => docToModel(row.doc)).filter(model => model !== undefined), // If model type is not found docToModel will return undefined so we need to filter.
      ).first(),
    )
    .catch((error) => {
      handleError(error, `${queryMapping.get('userConfigsByUserId')?.queryDDOCView}`);
      return Promise.reject(error);
    });
}

/**
 * Gets supplier invoices data for the supplier id passed
 * @param {string} supplierId the supplier id
 * @returns {any} supplier invoices
 */
export function getSupplierInvoices(supplierId: string) {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/integrations/supplier/transactions/${encodeURIComponent(supplierId)}/breakdown`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response.status);
        if (response.ok) {
          return response.json().then(json => Promise.resolve(json));
        }
        if (response && response.status !== 401 && response.status !== 200) {
          handleApiError({
            error: response.json ? response.json().then(json => Promise.resolve(json)) : response,
            message: 'Suppliers breakdown api failed',
          });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({
          error,
          message: 'Suppliers breakdown api failed',
        });
        // return error so there is no unresolved promise
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Gets unique active ingredients
 * @param {string} name
 * @param {number} limit
 * @returns {any} supplier invoices
 */
export function fetchActiveIngredients(name: string, limit: number = 110) {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseUrl()}/masterdata/active_ingredients/search`,
      {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
          filter: {
            name,
          },
          limit,
        }),
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response.status);
        if (response.ok) {
          return response.json().then((json) => {
            const { rows: activeIngredients } = json;
            return Promise.resolve(({ activeIngredients, error: false }));
          });
        }
        if (response && response.status !== 401 && response.status !== 200) {
          handleApiError({
            error: response.json ? response.json().then(json => Promise.resolve(json)) : response,
            message: 'Get unique active ingredient api failed',
          });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({
          error,
          message: 'Get unique active ingredient api failed',
        });
        // return error so there is no unresolved promise
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Check the interaction of the drug-drug or drug-active-ingredient
 * @param {Array<string>} prescriptionIDs the prescription ids
 * @param {Array<string>} allergyDrugIds the allergy drug ids
 * @param {Array<string>} allergyIngredientIDs the allergy ingredients ids
 * @returns {any} supplier invoices
 */
export function getAllergyInteractions(
  prescriptionIDs: Array<string>, allergyDrugIds?: Array<string>, allergyIngredientIDs?: Array<string>,
) {
  const data = {
    ...prescriptionIDs.length > 0 && {
      prescriptions: {
        clinic_drug_ids: prescriptionIDs,
      },
    },
    ...((allergyDrugIds && allergyDrugIds?.length > 0) || (allergyIngredientIDs && allergyIngredientIDs?.length > 0)) && {
      allergy_rules: {
        ...(allergyDrugIds && allergyDrugIds?.length > 0) && { clinic_drug_ids: allergyDrugIds },
        ...(allergyIngredientIDs && allergyIngredientIDs?.length > 0) && {
          active_ingredient_ids: allergyIngredientIDs,
        },
      },
    },
  };
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseUrl()}/masterdata/drugs/interaction`,
      {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(data),
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response.status);
        if (response.ok) {
          return response.json().then(json => Promise.resolve({
            error: false,
            msg: '',
            allergyConflicts: fromJS(json.content.allergy_conflicts),
          }));
        }
        if (response && response.status !== 401 && response.status !== 200) {
          handleApiError({
            error: response.json ? response.json().then(json => Promise.resolve(json)) : response,
            message: 'Drug interaction api failed',
          });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({
          error,
          message: 'Drug interaction api failed',
        });
        // return error so there is no unresolved promise
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Gets supplier summary for the supplier id passed
 * @param {string} supplierId the supplier id
 * @returns {any} supplier summary
 */
export function getSupplierSummary(supplierId: string) {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/integrations/supplier/transactions/${encodeURIComponent(supplierId)}/summary`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json => Promise.resolve(json));
        }
        handleApiError({
          error: response?.json().then(data => data),
          message: 'Suppliers summary api failed',
        });
        return response?.json().then(data => Promise.resolve({
          error: true,
          msg: '',
          detailedText: data ? data.msg : '', // sometimes the error message is not for the whole page, though 500
        }));
      })
      .catch((error) => {
        handleApiError({
          error,
          message: 'Suppliers summary api failed',
        });
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Gets supplier summary for the supplier id passed
 * @param {number} startTime Starting timestamp filter in UTCmilliseconds
 * @param {number} endTime Ending timestamp filter in UTCmilliseconds
 * @param {Array<string>} skuIDs skuIDs (Optional)
 * @returns {any} supplier summary
 */
export function fetchFilteredTransaction(
  startTime: number,
  endTime: number,
  skuIDs?: Array<string>,
): Promise<{
  transactions: List<TransactionModel>;
  isFiltered?: boolean;
  error?: boolean;
  msg?: string,
}> {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/transactions?start_time=${startTime}&end_time=${endTime}${
        skuIDs && skuIDs.length > 0 ? `&sku_ids=${JSON.stringify(skuIDs)}` : ''
      }`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json().then((json) => {
            const transactions = json.rows.map((row) => {
              const { total_after_change: totalAfterChange, ...attr } = row;
              const transaction = new TransactionModel(attr);
              transaction.setTotalAfterChange(totalAfterChange);
              return transaction;
            });
            return Promise.resolve({
              isFiltered: true,
              transactions: List(transactions),
            });
          });
        }
        if (response.status === 404) {
          debugPrint(response.statusText, 'error');
          logMessage(
            'Filtered Transactions with stock total api not found',
            response.status === 0 ? 'info' : 'error',
          );
          return response.json().then(data =>
            Promise.resolve({
              error: true,
              msg: '',
              detailedText: data ? data.msg : '',
            }));
        }
        if (response.status === 500 || response.status === 504) {
          const apiError = new APIError(
            'Filtered Transactions with stock total api failed',
            response.status,
            response.json().then(data => data),
          );
          handleApiError({ error: apiError });
          return response.json().then(data =>
            Promise.resolve({
              error: true,
              msg: '',
              detailedText: data ? data.msg : '', // sometimes the error message is not for the whole page, though 500
            }));
        }
        if (response && response.status !== 401) {
          const apiError = new APIError(
            'Filtered Transactions with stock total api failed',
            response.status,
            response.json().then(data => data),
          );
          handleApiError({ error: apiError });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({ error });
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
    transactions: List(),
  });
}


/**
 * Gets supplier summary for the supplier id passed
 * @param {string} start Starting timestamp filter in UTCmilliseconds
 * @param {string} end Ending timestamp filter in UTCmilliseconds
 * @returns {any} supplier summary
 */
export function fetchInventoryReports(start: string, end: string) {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/stats/inventory_report?start_time=${start}&end_time=${end}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        if (response.ok) {
          return response.json().then(json => Promise.resolve({
            unavailable: false,
            data: List(json.contents.map(e => Map(e))),
          }));
        } if (response.status === 404) {
          return Promise.resolve({
            unavailable: true,
            data: List([]),
          });
        }
        const apiError = new APIError(
          'Inventory reports api failed',
          response.status,
          response.json().then(data => data),
        );
        handleApiError({ error: apiError });
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({ error });
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

const ANALYTICS_BASE_URL = 'https://klinify-api-backup-67notv5dhzz7.runkit.sh';
/**
 * Gets all reports list for this account
 * @param {string} dateRange date range for the api
 * @returns {any} Analytics summary
 */
export function fetchKeyMetricsList(dateRange: string): Promise<AnalyticsKeyMetricsResponse> {
  return fetchWithRetry(`${ANALYTICS_BASE_URL}/keymetrics?dateRange=${dateRange}`, { method: 'GET', credentials: 'same-origin' })
    .then((response) => {
      handleUnauthorisedApiResponse(response ? response.status : null);
      if (response.status === 200) {
        return response.json().then((json: Array<AnalyticsKeyMetrics>) => Promise.resolve({
          status: response.status,
          data: List(json.map(item => new AnalyticsSummaryItemModel(item))),
        }));
      }
      const apiError = new APIError(
        'api.ts => fetchKeyMetricsList',
        response.status,
        response.json().then(data => data),
      );
      handleApiError({ error: apiError });
      return Promise.resolve({
        error: true,
        msg: '',
        data: List([]),
      });
    })
    .catch(error => handleApiError({ error }));
}

/**
 * Gets all reports list for this account
 * @returns {any} Analytics reports
 */
export function fetchAnalyticsReportsList(): Promise<AnalyticsReportsListResponse> {
  return fetchWithRetry(`${ANALYTICS_BASE_URL}/reports`, { method: 'GET', credentials: 'same-origin' })
    .then((response) => {
      handleUnauthorisedApiResponse(response ? response.status : null);
      if (response.status === 200) {
        return response.json().then((json: Array<AnalyticsReportJSON>) => Promise.resolve({
          status: response.status,
          data: List(json.map(item => new AnalyticsReportModel(item))),
        }));
      }
      const apiError = new APIError(
        'api.ts => fetchKeyMetricsList',
        response.status,
        response.json().then(data => data),
      );
      handleApiError({ error: apiError });
      return Promise.resolve({
        error: true,
        msg: '',
        data: List([]),
      });
    })
    .catch(error => handleApiError({ error }));
}

/**
 * Gets details of a specific report
 * @param {string} reportID ID of the report needed
 * @returns {any} supplier summary
 */
export function fetchAnalyticsReportDetail(
  reportID: string,
): Promise<AnalyticsReportDetailResponse> {
  return fetchWithRetry(`${ANALYTICS_BASE_URL}/reports/${reportID}`, { method: 'GET', credentials: 'same-origin' })
    .then((response) => {
      handleUnauthorisedApiResponse(response ? response.status : null);
      if (response.status === 200) {
        return response.json().then((json: AnalyticsReportJSON) => Promise.resolve({
          status: response.status,
          data: new AnalyticsReportModel(json),
        }));
      }
      const apiError = new APIError(
        'api.ts => fetchAnalyticsReportDetail',
        response.status,
        response.json().then(data => data),
      );
      handleApiError({ error: apiError });
      return Promise.resolve({
        error: true,
        msg: '',
        data: Map,
      });
    })
    .catch(error => handleApiError({ error }));
}

/**
 * Gets details of a cached query in a report
 * @param {string} cachedQueryID ID of the cached query needed
 * @param {string} dateRange date range for the api
 * @returns {any} cached query data
 */
export function fetchAnalyticsQueryDetail(
  cachedQueryID: string,
  dateRange: string,
): Promise<AnalyticsQueryDetailResponse> {
  return fetchWithRetry(`${ANALYTICS_BASE_URL}/cached_query/${cachedQueryID}?dateRange=${dateRange}`, { method: 'GET', credentials: 'same-origin' })
    .then((response) => {
      handleUnauthorisedApiResponse(response ? response.status : null);
      if (response.status === 200) {
        return response.json().then((json: AnalyticsQueryJSON) => Promise.resolve({
          status: response.status,
          data: new AnalyticsQueryResponseModel(json),
        }));
      }
      const apiError = new APIError(
        'api.ts => fetchAnalyticsQueryDetail',
        response.status,
        response.json().then(data => data),
      );
      handleApiError({ error: apiError });
      return Promise.resolve({
        error: true,
        msg: '',
        data: Map,
      });
    })
    .catch(error => handleApiError({ error }));
}

/**
 * Generates claimInvoiceModels and saves to the db.
 * @param {Array<ClaimInvoiceModel>} invoices The invoices models to save
 * @param {boolean} regenerate if the claimInvoice is being regenerated
 * @param {Dispatch} dispatch Redux dispatch function.
 * @param {string} requestId A requestId to associate with the request to be sent
 * @returns {any} supplier summary
 */
export function generateClaimInvoiceModels(
  invoices: Array<ClaimInvoiceMetadata>,
  regenerate: boolean = false,
  dispatch: Dispatch,
  requestId: string = generateGUID(),
) {
  const retryJob = Map({
    [requestId]: {
      enabled: true,
      method: _dispatch => generateClaimInvoiceModels(invoices, regenerate, _dispatch, requestId),
    },
  });
  if (getIsOnline()) {
    dispatch(setUnsyncedAPICalls(Map({
      [requestId]: {
        enabled: false,
        method: (
          _dispatch,
          opts = {
            docs: [],
            isResponseDataReceived: false,
          },
        ) => {
          if (opts.isResponseDataReceived) {
            if (opts.docs.some(doc => doc.type === 'claim_invoice' && doc.created_by.request_id === requestId)) {
              _dispatch(deleteUnsyncedAPICalls(List([requestId])));
            }
            return Promise.resolve({
              ok: true,
              data: List(opts.docs.map(doc => docToModel(doc))),
            });
          }
          return generateClaimInvoiceModels(invoices, regenerate, _dispatch, requestId);
        },
      },
    })));
    return fetchWithRetry(
      `${getBaseApiUrl()}/claims/invoice/${regenerate ? 'regenerate' : 'generate'}`,
      {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
        },
        body: JSON.stringify({ invoices }),
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          dispatch(deleteUnsyncedAPICalls(List([requestId])));
          return response.json()
            .then(json => Promise.resolve({
              ok: true,
              data: List(json.rows.map(doc => docToModel(doc))),
            }));
        }
        if (response.status === 404) {
          dispatch(deleteUnsyncedAPICalls(List([requestId])));
          return Promise.resolve({
            unavailable: true,
            data: List([]),
          });
        }
        if (response && (response.status === 502 || response.status === 500)) {
          const apiError = new APIError(
            'api.ts => generateClaimInvoiceModels',
            response.status,
            response?.json().then(data => data ?? response),
          );
          handleApiError({ error: apiError, message: translate('taking_longer_than_usual_notification', { x: 'Claim Invoice Generation' }) });
          setTimeout(() => dispatch(updateUnsyncedAPICalls(Map({
            [requestId]: {
              enabled: true,
            },
          }))), 300000);
          return Promise.resolve({
            error: true,
            msg: 'TIMEOUT',
          });
        }
        if (response && response.status !== 401) {
          setIsOnline(false);
          dispatch(updateUnsyncedAPICalls(retryJob));
          const apiError = new APIError(
            'api.ts => generateClaimInvoiceModels',
            response.status,
            response?.json().then(data => data ?? response),
          );
          handleApiError({ error: apiError, message: 'generateClaimInvoiceModels api failed' });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        if (error && error.status !== 401) {
          if (error.status === 0) {
            setIsOnline(false);
            dispatch(updateUnsyncedAPICalls(retryJob));
          }
        }
        handleApiError({ error });
        return Promise.resolve({
          error: true,
          msg: error,
        });
      });
  }
  dispatch(updateUnsyncedAPICalls(retryJob));
  return Promise.resolve({
    error: true,
    msg: 'Offline',
  });
}

/**
 * Voids claimInvoice and related docs and saves to the db.
 * @param {Array<string>} invoiceIds The caim invoice ids to void
 * @param {Dispatch} dispatch Redux dispatch function.
 * @returns {any} supplier summary
 */
export function voidClaimInvoiceModels(
  invoiceIds: Array<string>,
  dispatch: Dispatch,
) {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/claims/invoice/void`,
      {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ invoice_ids: invoiceIds }),
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json()
            .then(json => Promise.resolve({
              ok: true,
              data: List(json.rows.map(doc => docToModel(doc))),
            }));
        }
        const apiError = new APIError(
          'voidClaimInvoiceModels api failed',
          response.status,
            response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError });
        if (response.status === 404) {
          return Promise.resolve({
            unavailable: true,
            data: List([]),
          });
        }
        if (response && response.status !== 401) {
          setIsOnline(false);
          dispatch(updateUnsyncedAPICalls(List([_dispatch => (
            voidClaimInvoiceModels(invoiceIds, _dispatch)
          )])));
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({ error });
        if (error && error.status !== 401) {
          if (error.status === 0) {
            setIsOnline(false);
            dispatch(updateUnsyncedAPICalls(List([_dispatch => (
              voidClaimInvoiceModels(invoiceIds, _dispatch)
            )])));
          }
        }
        return Promise.resolve({
          error: true,
          msg: error,
        });
      });
  }
  dispatch(updateUnsyncedAPICalls(List([_dispatch => (
    voidClaimInvoiceModels(invoiceIds, _dispatch)
  )])));
  return Promise.resolve({
    error: true,
    msg: 'Offline',
  });
}

/**
 * Suggest the inventory item along with the filters.
 * @param {object} suggestionParam suggestion param object.
 * @param {string} clinicDrugId clinic drug id.
 * @returns {Promise<SuggestInventoryMappingResponse>} API response
 */
export function suggestInventoryItem(
  suggestionParam: object,
  clinicDrugId: string,
): Promise<SuggestInventoryMappingResponse> {
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseUrl()}/masterdata/drugs/suggestions/${getClinicID()}/${clinicDrugId}`,
      {
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(suggestionParam),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then((json: SuggestInventoryMappingResponse) =>
            Promise.resolve({
              status: response.status,
              ok: json.ok,
              msg: json.msg,
            }));
        }
        const apiError = new APIError(
          'Save inventory mapping api failed',
          response.status,
          response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Save inventory mapping api failed' });
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
            msg: `server returned ${response.status}`,
          });
        }
        return Promise.resolve({
          ok: false,
          msg: `server returned ${response.status}`,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Save inventory mapping api failed' });
        return Promise.resolve({
          ok: false,
          msg: 'error',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
    msg: 'error',
  });
}

/**
 * Saves the inventory mapping updates by calling the API
 * @param {List<object>} updates Inventory Mapping updates
 * @returns {Promise<SaveInventoryMappingResponse>} API response
 */
export function saveInventoryMapping(
  updates: List<object>,
): Promise<SaveInventoryMappingResponse> {
  if (getIsOnline()) {
    const updateData = JSON.stringify(updates.toJS());

    return fetchWithRetry(`${getBaseUrl()}/masterdata/drugs/mappings/${getClinicID()}`,
      {
        method: 'PUT',
        credentials: 'same-origin',
        body: updateData,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then((json: SaveInventoryMappingResponse) =>
            Promise.resolve({
              status: response.status,
              ok: json.ok,
              total_rows: json.total_rows,
              rows: List(json.rows),
            }));
        }
        const apiError = new APIError(
          'Save inventory mapping api failed',
          response.status,
            response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Save inventory mapping api failed' });
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
            total_rows: 0,
            rows: List([]),
          });
        }
        return Promise.resolve({
          ok: false,
          total_rows: 0,
          rows: List([]),
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Save inventory mapping api failed' });
        return Promise.resolve({
          ok: false,
          total_rows: 0,
          rows: List([]),
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
    total_rows: 0,
    rows: List([]),
  });
}

/**
 * Get the inventory count per batch subject to different filters
 * @param {Array<string> | 'ALL'} skuIds An array of sku ID for which to fetch Count or ALL to fetch for all the sku
 * @param {Boolean} showHiddenBatches flag to show hidden batches with count < 0, defaults to false
 * @param {Number} limit Number of rows of batch to return, Defaults to 100
 * @param {'ASC' | 'DESC'} sortDir The sort direction of the batches
 * @param {'expiry_date' | 'supply_date'} sortBy The date field by which the batches should be sorted
 * @param {Boolean} includeDocs flag to fetch docs
 * @returns {Promise<APIResponse>} supplier summary
 */
export function fetchInventoryCountByBatch(
  skuIds: Array<string> | 'ALL',
  showHiddenBatches: boolean = true,
  limit: number = 100,
  sortDir: 'ASC' | 'DESC' = 'ASC',
  sortBy: 'expiry_date' | 'supply_date',
  includeDocs: boolean = false,
): Promise<APIResponse> {
  if (!Array.isArray(skuIds) && skuIds !== 'ALL') {
    return Promise.reject(new Error('skuIds should either be a array of string ids or "ALL"'));
  }
  const params = {
    ...(showHiddenBatches ? {} : { min_stock_remaining: 0 }),
    limit,
    sort_dir: sortDir.toLowerCase(),
    sort_by: sortBy,
    include_docs: includeDocs.toString(),
  };

  const queryString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

  return fetchWithRetry(
    `${getBaseApiUrl()}/transactions/supply_items?${skuIds === 'ALL' ? queryString : ''}`,
    {
      method: 'GET',
      credentials: 'same-origin',
      ...(skuIds !== 'ALL' ?
        {
          method: 'POST',
          body: JSON.stringify({ ...params, sku_ids: skuIds }),
          headers: {
            'Content-Type': 'application/json',
          },
        } : null),
    },
  )
    .then((response) => {
      handleUnauthorisedApiResponse(response ? response.status : null);
      if (response.ok) {
        return response.json().then(json => Promise.resolve({
          status: response.status,
          data: List(json.rows),
          error: false,
          msg: '',
        }));
      } if (response.status === 404 || response.status === 405) {
        // If the API is unavailable use the fake API. It gets the supply item docs and fakes inventory counts.
        return fetchFromView(
          getSupplyItemDocs(),
        )
          .then((models) => {
            const filteredModels = models.filter(model => !!model);
            const sortedModels = sortDir === 'ASC' ?
              filteredModels.sortBy(model => model.get('date')) :
              filteredModels.sortBy(model => model.get('date')).reverse();
            const suppliesBySku = sortedModels
              .groupBy(si => si.get('sku_id'))
              .filter((_, skuId) => skuIds === 'ALL' || skuIds.includes(skuId));
            const data = suppliesBySku.entrySeq().map(([skuId, batches]) => ({
              total_rows: batches.slice(Math.floor(batches.size * 0.4)).size,
              sku_id: skuId,
              rows: batches.slice(Math.floor(batches.size * 0.4)).slice(0, limit).map(si => ({
                _id: si.get('_id'),
                sku_id: si.get('sku_id'),
                type: 'supply_item',
                stock_remaining: Math.floor(Math.random() * 100),
              })),
            }));
            return Promise.resolve({
              status: response.status,
              data: List(data),
              error: false,
              msg: '',
            });
          })
          .catch((error) => {
            handleError(error, 'api.js => getSupplyItemDocs');
            return Promise.resolve({
              error: true,
              msg: '',
              status: error.status,
              data: List(),
            });
          });
      } if (response.status === 500 || response.status === 504) {
        return response.json().then(data => Promise.resolve({
          error: true,
          msg: '',
          status: response.status,
          data: List(),
          detailedText: data ? data.msg : '', // sometimes the error message is not for the whole page, though 500
        }));
      }
      if (response && response.status !== 401) {
        const apiError = new APIError(
          'api.ts => getSupplyItemDocs',
          response.status,
          response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError });
      }
      return Promise.resolve({
        error: true,
        msg: '',
        status: response.status,
        data: List(),
      });
    })
    .catch((error) => {
      handleApiError({ error, message: 'fetchInventoryCountByBatch api failed' });
      return Promise.resolve({
        error: true,
        msg: '',
        status: error.status,
        data: List(),
      });
    });
}

/**
 * Attempts to get transactions docs for the source_ids passed.
 * @param {List<string>} sourceIDs list of source_id values in transactions.
 * @returns {Promise<List<TransactionModel>>}
 */
export function getTransactionsBySourceIds(sourceIDs: List<string>):
  Promise<List<TransactionModel>> {
  const keys = sourceIDs && sourceIDs.size > 0 ? Set(sourceIDs).toArray() : [];
  if (keys.length === 0) {
    return Promise.resolve(List());
  }
  return queryDesignDoc(`${DDOC_VERSION_INVENTORY_3}/transactionsBySourceId`, { keys, include_docs: true })
    .then(
      response => (response && response.rows ?
        List(response.rows.map(row => docToModel(row.doc))) : List()),
    )
    .catch((error) => {
      handleError(error, `${DDOC_VERSION_INVENTORY_3}/transactionsBySourceId`);
      return Promise.reject(error);
    });
}

/**
 * Gets the details for a particular campaign
 * @param {string} campaignId the id of the campaign to fetch
 * @param {boolean} includeStats whether should include stats of this campaign
 * @returns {any} supplier summary
 */
export function fetchCampaign(campaignId: string, includeStats: boolean = false) {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseUrl()}/campaigns/campaign/${campaignId}?include_stats=${includeStats}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json => Promise.resolve(genericDocToModel(json, 'patient_campaign')));
        }
        const apiError = new APIError(
          'api.ts => generateClaimInvoiceModels',
          response.status,
            response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Fetch Campaign api failed' });
        if (response.status === 404) {
          return Promise.resolve({
            error: true,
            msg: 'unavailable',
          });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      })
      .catch((error) => {
        handleApiError({ error });
        return Promise.resolve({
          error: true,
          msg: '',
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Saves the current status update for a campaign job
 * @param {List<InventoryMapUpdate>} updatedJob the sms campaign job model
 * @param {Dispatch} dispatch Redux dispatch function.
 * @returns {Promise<APIResponse>} API response
 */
export function saveCampaignJobStatusUpdate(
  updatedJob: SMSJobModel,
  dispatch: Dispatch,
): Promise<APIResponse<SMSJobModel>> {
  const updatedStatus = updatedJob.getStatus();
  if (!['unresolved', 'resolved', 'cancelled'].includes(updatedStatus)) {
    return Promise.reject(new Error(`Invalid Status : Only status "unresolved", "resolved" and "cancelled" is allowed. Received ${updatedStatus}`));
  }
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseUrl()}/campaigns/jobs/${updatedJob.get('_id')}`,
      {
        method: 'PATCH',
        credentials: 'same-origin',
        body: JSON.stringify({ status: updatedStatus }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then((json) => {
            const doc = docToModel(json);
            if (doc) {
              if (response.redirected && updatedJob.get('_id') !== json._id) {
                dispatch(replaceCurrentDataViewsModel(updatedJob, doc));
                return Promise.resolve({
                  ok: true,
                  data: List([doc]),
                });
              }
              dispatch(updateCurrentDataViewsModel(doc));
            }
            return Promise.resolve({
              ok: true,
              data: List([doc]),
            });
          });
        }
        if (response.status === 500) {
          const apiError = new APIError(
            'SERVER_FAILED => saveCampaignJobStatusUpdate',
            response.status,
            response?.json().then(data => data ?? response),
          );
          handleApiError({ error: apiError });
          return Promise.resolve({
            error: true,
            msg: 'SERVER_FAILED',
            status: response.status,
            data: List(),
          });
        }
        if (response.status === 403) {
          const apiError = new APIError(
            'BAD_DATA => saveCampaignJobStatusUpdate',
            response.status,
            response?.json().then(data => data ?? response),
          );
          handleApiError({ error: apiError });
          return Promise.resolve({
            error: true,
            msg: 'BAD_DATA',
            status: response.status,
            data: List(),
          });
        }
        if (response.status === 404 || response.status === 400) {
          const apiError = new APIError(
            'api.ts => saveCampaignJobStatusUpdate',
            response.status,
            response?.json().then(data => data ?? response),
          );
          handleApiError({ error: apiError, message: 'Save inventory mapping api failed' });
          return Promise.resolve({
            error: true,
            msg: 'UNAVAILABLE',
            status: response.status,
            data: List(),
          });
        }
        return Promise.resolve({
          error: true,
          msg: '',
          status: response.status,
          data: List(),
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Save inventory mapping api failed' });
        return Promise.resolve({
          error: true,
          msg: '',
          status: error.status,
          data: List(),
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
    status: 0,
  });
}

// Pharmaconnect API functions
const PHARMACONNECT_BASE_URL = '/pharmaconnect';

/**
 * Gets all the brands and their documents
 * @returns {Promise<List<BrandResponseModel>>} Brands and their documents
 */
export function fetchPharmaConnectBrandDocuments(): Promise<List<BrandResponseModel>> {
  return fetchWithRetry(`${PHARMACONNECT_BASE_URL}/brands`,
    {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then((response) => {
      // handleUnauthorisedApiResponse(response ? response.status : null);

      if (response.status === 200) {
        return response.json().then((json: PharmaConnectBrandDocumentResponseJSON) => {
          const filteredData = json.rows
            .map((row) => {
              const newRow = new BrandResponseModel(row);
              newRow.set('documents', List(row.documents));
              return newRow;
            });
          return Promise.resolve(List(filteredData));
        });
      }

      return Promise.reject();
    }).catch(err => Promise.reject(err));
}


/**
 * Gets all the read documents by this user
 * @returns {Promise<List<BrandResponseModel>>} Brands and their documents
 */
export function fetchPharmaConnectReadDocuments(): Promise<ReadDocumentsModel> {
  const params = {
    event_type: 'READ',
    asset_type: 'DOCUMENT',
    unique: 'true',
  };
  const paramStr = new URLSearchParams(params).toString();
  return fetchWithRetry(`${PHARMACONNECT_BASE_URL}/events?${paramStr}`, {
    method: 'GET',
    credentials: 'same-origin',
  }).then((response) => {
    if (response.status === 200) {
      return response.json().then((json: PharmaconnectDocResponseJSON) => {
        const data = new ReadDocumentsModel({ documents: List(json.rows) });
        return Promise.resolve(data);
      });
    }
    return Promise.reject();
  }).catch(err => Promise.reject(err));
}

/**
 * Save an event for pharmaconnect
 * @param {string} eventType Event type
 * @param {string} assetType Asset type
 * @param {string} assetID Asset id
 * @returns {Promise<null>} Promise
 */
export function savePharmaConnectEvent(
  eventType: string,
  assetType: string,
  assetID: string,
): Promise<null> {
  const body = {
    event_type: eventType,
    asset_type: assetType,
    asset_id: assetID,
  };
  return fetchWithRetry(`${PHARMACONNECT_BASE_URL}/events`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status === 200) {
      return response.json().then(res => Promise.resolve(res.msg));
    }
    return Promise.reject();
  }).catch(err => Promise.reject(err));
}

/**
 * Send email to the brand
 * @param {string} brandID Brand ID
 * @param {string} name Name of the doc
 * @param {string} phoneNumber Phone number of the doc
 * @param {string} clinic_name Clinic name
 * @param {string} clinic_address Clinic address
 * @param {string} message Message from the doc
 * @returns {Promise<null>} Promise
 */
export function sendPharmaConnectEmail(
  brandID: string,
  name: string,
  phoneNumber: string,
  clinic_name: string, // eslint-disable-line camelcase
  clinic_address: string, // eslint-disable-line camelcase
  message: string,
): Promise<null> {
  return fetchWithRetry(`${PHARMACONNECT_BASE_URL}/email/send`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({
      name,
      brand_id: brandID,
      phone_number: phoneNumber,
      clinic_name,
      clinic_address,
      message,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status === 200) {
      return response.json().then(res => Promise.resolve(res.msg));
    }
    return Promise.reject();
  }).catch(err => Promise.reject(err));
}

/**
 * Get detailings based on size
 * @param {string} size LG or SM
 * @param {Array<string>} excludedBrandIds List of adds to exclude
 * @returns {Promise<null>} Promise
 */
export function fetchRandomDetailing(
  size: string,
  excludedBrandIds?: Array<string>,
): Promise<DetailingResponseModel> {
  const params = {
    size,
    filter: 'random',
    exclude_brand_ids: excludedBrandIds?.length ? JSON.stringify(excludedBrandIds) : undefined,
  };
  // @ts-ignore
  const paramStr = new URLSearchParams(pickBy(params, x => x)).toString();
  return fetchWithRetry(`${PHARMACONNECT_BASE_URL}/detailings?${paramStr}`, {
    method: 'GET',
    credentials: 'same-origin',
  }).then((response) => {
    if (response.status === 200) {
      return response.json().then((json) => {
        if (json.rows.length === 0) return Promise.reject();
        const data = new DetailingResponseModel(json.rows[0]);
        return Promise.resolve(data);
      });
    }
    return Promise.reject();
  }).catch(err => Promise.reject(err));
}

/**
 * schedules an appointment
 * @param {AppointAppointmentDatamentModel} appointmentData Appointment data to schedule with
 * @returns {Promise<SaveInventoryMappingResponse>} API response
 */
export function scheduleAppointment(
  appointmentData: AppointmentData,
): Promise<AppointmentAPIResponse> {
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseApiUrl()}/appointments`,
      {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(appointmentData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json =>
            Promise.resolve({
              status: response.status,
              ok: true,
              model: docToModel(json),
            }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        const apiError = new APIError(
          'api.ts => scheduleAppointment',
          response.status,
          response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Schedule Appointment api failed' });
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Schedule Appointment api failed' });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
  });
}

/**
 * creates block off time
 * @param {BlockOffTime} blockOffTimeData block off time data
 * @returns {Promise<BlockOffTimeAPIResponse>} API response
 */
export function createBlockOffTime(
  isNew: boolean = true,
  blockOffTimeData: BlockOffTime,
): Promise<BlockOffTimeAPIResponse> {
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseApiUrl()}/block_off_time`,
      {
        method: isNew ? 'POST' : 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(blockOffTimeData),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json =>
            Promise.resolve({
              status: response.status,
              ok: true,
              model: json,
            }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        const apiError = new APIError(
          'api.ts => createBlockOffTime',
          response.status,
          response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Add block off time api failed' });
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Add block off time api failed' });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
  });
}

/**
 * Gets appointments based on supplied arguments
 * @param {number} startTime Starting timestamp filter in UTCmilliseconds
 * @param {number} endTime Ending timestamp filter in UTCmilliseconds
 * @param {AppointmentStatus} status appointment status to query
 * @param {string} patientId Patient ID
 * @param {string} practitionerId Doctor ID
 * @returns {Promise<AppointmentAPIResponse>} API Response
 */
export function fetchAppointments(startTime: number, endTime: number,
  status?: AppointmentStatus, patientId?: string, practitionerId?: string)
  : Promise<AppointmentAPIResponse> {
  if (getIsOnline()) {
    let requestUrl = `${getBaseApiUrl()}/appointments?start_timestamp=${startTime}&end_timestamp=${endTime}`;
    if (status) requestUrl += `&status=${status}`;
    if (patientId) requestUrl += `&patient_id=${patientId}`;
    if (practitionerId) requestUrl += `&practitioner_id=${practitionerId}`;
    return fetchWithRetry(requestUrl,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json =>
            Promise.resolve({
              status: response.status,
              ok: true,
              models: List<AppointmentModel>(json.appointments.map(e => docToModel(e))),
            }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        const apiError = new APIError(
          'api.ts => scheduleAppointment',
          response.status,
        response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Schedule Appointment api failed' });
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Schedule Appointment api failed' });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Gets block off time based on supplied date arguments
 * @param {number} startTime Starting timestamp filter in UTCmilliseconds
 * @param {number} endTime Ending timestamp filter in UTCmilliseconds
 * @param {Array<string>} skuIDs skuIDs (Optional)
 * @returns {any} supplier summary
 */
export function fetchBlockOffTime(startTime?: number, endTime?: number)
  : Promise<BlockOffTimeAPIResponse> {
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/block_off_time`,
      {
        method: 'GET',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json =>
            Promise.resolve({
              status: response.status,
              ok: true,
              models: List<BlockOffTime>(json),
            }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        const apiError = new APIError(
          'api.ts => fetchBlockOffTime',
          response.status,
        response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'Block Off Time api failed' });
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Block Off Time api failed' });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Deletes block off time based on supplied _id
 * @param {id} id of block time to delete
 * @returns {any} supplier summary
 */
export function deleteBlockOffTime(id: string)
  : Promise<BlockOffTimeAPIResponse> {
  const formData = new FormData();
  formData.append('_id', id);
  if (getIsOnline()) {
    return fetchWithRetry(
      `${getBaseApiUrl()}/block_off_time?_id=${id}`,
      {
        body: formData,
        method: 'DELETE',
        credentials: 'same-origin',
      },
    )
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(() =>
            Promise.resolve({
              status: response.status,
              ok: true,
            }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        const apiError = new APIError(
          'api.ts => deleteBlockOffTime',
          response.status,
        response?.json().then(data => data ?? response),
        );
        handleApiError({ error: apiError, message: 'deleteBlockOffTime Time api failed' });
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'deleteBlockOffTime Time api failed' });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    error: true,
    msg: '',
  });
}

/**
 * Cancels given appointment
 * @param {AppointmentModel} appointment Appointment to cancel
 * @param { Object } data attributes which needs update
 * @returns {Promise<SaveInventoryMappingResponse>} API response
 */
export function updateAppointment(
  appointment: AppointmentModel,
  data: Object,
): Promise<AppointmentAPIResponse> {
  const logMessageHeader = getAppointmentUpdateAPIType(data);
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseApiUrl()}/appointments/${appointment.get('_id')}`,
      {
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify({
          ...appointment.attributes,
          ...data,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json =>
            Promise.resolve({
              status: response.status,
              ok: true,
              model: docToModel(json),
            }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        if (response && response.status !== 401) {
          const apiError = new APIError(
            'API Failed => updateAppointment',
            response.status,
            response?.json().then(d => d ?? response),
          );
          handleApiError({ error: apiError });
        }
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
  });
}

/**
 * Cancels given appointment
 * @param {string} prescriptionId Appointment to cancel
 * @returns {Promise<SaveInventoryMappingResponse>} API response
 */
export function downloadATDPSFile(
  prescriptionIds: Array<string>,
): Promise<DownloadATDPSResponse> {
  if (getIsOnline()) {
    const params = {
      prescription_id: prescriptionIds,
      machine_type: 'ATDPS',
    };
    // const paramStr = new URLSearchParams([...prescriptionIds.map(s=>['prescription_id',s]), ['machine_type', 'ATDPS']]).toString();
    const paramStr = new URLSearchParams(params).toString();
    return fetchWithRetry(`${getBaseApiUrl()}/prescription_export?${paramStr}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return response.json().then(json => Promise.resolve({
            status: response.status,
            ok: true,
            data: json.export,
          }));
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        if (response && response.status !== 401) {
          const apiError = new APIError(
            'API Failed => updateAppointment',
            response.status,
            response?.json().then(d => d ?? response),
          );
          handleApiError({ error: apiError });
        }
        return Promise.resolve({
          ok: false,
        });
      })
      .catch((error) => {
        handleApiError({ error });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
  });
}

/**
 * Force send an email to the patient
 * @param {string} appointmentID appointment ID
 * @param {string} emailType email type for the email template
 * @param {string} email email
 * @returns {Promise<FetchResponse>} API response
 */
export function forceSendEmail(
  appointmentID: string,
  emailType: string,
  email: string,
): Promise<FetchResponse> {
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseApiUrl()}/appointments/${appointmentID}/email/send`,
      {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
          patient_email: email,
          email_type: emailType,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return Promise.resolve({
            status: response.status,
            ok: true,
          });
        }
        if (response.status === 404 || response.status === 400) {
          return Promise.resolve({
            status: response.status,
            ok: false,
          });
        }
        if (response && response.status !== 401) {
          const apiError = new APIError(
            'BAD_DATA => generateClaimInvoiceModels',
            response.status,
            response?.json().then(data => data ?? response),
          );
          handleApiError({ error: apiError, message: 'Force send an email to the patient api failed' });
        }
        return Promise.resolve({
          ok: false,
          status: response.status,
        });
      })
      .catch((error) => {
        handleApiError({ error, message: 'Force send an email to the patient api failed' });
        return Promise.resolve({
          ok: false,
          status: error?.status,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
    status: 0,
  });
}

/**
 * saves an encounter and related docs like bill and bill_item that are created for queueing a patient
 * @param {PatientModel} patient patient to queue
 * @param {EncounterModel} encounter encounter to create
 * @param {BillModel} bill bill associated with given encounter
 * @param {Array<BillItemModel>} billItems encounter type sales_item bill item
 * @param {Dispatch} dispatch Redux dispatch function.
 * @param {Function} onMissingDocsResolved function to run on missing doc resolve and succesful save.
 * @param {string} requestId A requestId to associate with the request to be sent
 * @returns {Promise<Array<BaseModel>>} Models saved
 */
export function saveEncounterRelatedModels(
  patient: PatientModel | PatientStubModel,
  encounter: EncounterModel,
  bill: BillModel,
  billItems: Array<BillItemModel> | undefined,
  dispatch: Dispatch,
  onMissingDocsResolved: (resp: Array<EncounterModel | BillModel | BillItemModel> |
    APIResponse<EncounterModel | BillModel | BillItemModel>) => void,
  requestId: string = generateGUID(),
): Promise<APIResponse<EncounterModel | BillModel | BillItemModel>> {
  const models = {
    encounter: encounter.attributes,
    bill: bill.attributes,
    ...(billItems?.length && { bill_items: billItems.map(bi => ({ ...bi.attributes })) }),
  };

  const retryJob = Map({
    [requestId]: {
      enabled: true,
      method: (_dispatch: Dispatch) =>
        saveEncounterRelatedModels(
          patient,
          encounter,
          bill,
          billItems,
          _dispatch,
          onMissingDocsResolved,
          requestId,
        ),
    },
  });
  if (getIsOnline()) {
    dispatch(setUnsyncedAPICalls(Map({
      [requestId]: {
        enabled: false,
        method: (
          _dispatch,
          opts = {
            docs: [],
            isResponseDataReceived: false,
          },
        ) => {
          if (opts.isResponseDataReceived) {
            if (opts.docs?.some(doc => doc.created_by.request_id === requestId)) {
              _dispatch(deleteUnsyncedAPICalls(List([requestId])));
            }
            return Promise.resolve(
              {
                ok: true,
                data: List(opts.docs?.map(doc => docToModel(doc))),
              },
            );
          }
          return saveEncounterRelatedModels(
            patient, encounter, bill, billItems, _dispatch, onMissingDocsResolved, requestId,
          );
        },
      },
    })));
    return fetchWithRetry(
      `${getBaseApiUrl()}/encounter`,
      {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
        },
        body: JSON.stringify(models),
      },
    )
      .then((response): Promise<APIResponse<EncounterModel | BillModel | BillItemModel>> => response?.json().then((json) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          dispatch(deleteUnsyncedAPICalls(List([requestId])));
          return Promise.resolve({
            ok: true,
            data: List(json.updated_docs?.map(doc => docToModel(doc))),
          });
        }
        if (response?.status === 400 && json.error && json.duplicate_queue) {
          return Promise.resolve({
            status: 400,
            error: true,
            msg: 'DUPLICATE_QUEUE',
          });
        }
        if (response?.status === 400 && json.error && json.doc_fields && DOC_VALIDATION_ENABLED) {
          const modelIdMap: {[x: string]: EncounterModel | BillModel | BillItemModel} =
              Object.assign({
                [encounter.get('_id')]: encounter,
                [bill.get('_id')]: bill,
              }, ...(billItems?.length ? billItems.map(bi => ({ [bi.get('_id')]: bi })) : []));
          const [missingDocs, missingDocResolverObjects] = Object.keys(json.doc_fields)
            .reduce(([docs, resolvers]: [List<BaseModel>, List<MissingDocObject>], id: string) => {
              const model = modelIdMap[id];
              const fields = json.doc_fields[id];
              return fields.reduce((
                [_docs, _resolvers]: [List<BaseModel>, List<MissingDocObject>],
                field: string,
              ) => {
                const missingDocModel = docToModel({
                  _id: model.get(field),
                  type: foreignKeyToDocType(field),
                });
                return ([
                  missingDocModel ? _docs.push(missingDocModel) : _docs,
                  _resolvers.push({
                    _id: model.get(field),
                    type: field,
                    referrerDoc: model,
                    onDocsResolved: (() => saveEncounterRelatedModels(
                      patient,
                      encounter,
                      bill,
                      billItems,
                      dispatch,
                      onMissingDocsResolved,
                      requestId,
                    ).then((resp) => {
                      if (resp.ok && !resp.unavailable && !resp.error && resp.data?.size) {
                        dispatch(updateModels(resp.data?.toArray()));
                        onMissingDocsResolved(resp.data.toArray());
                      }
                      onMissingDocsResolved(resp);
                    })),
                  }),
                ]);
              }, [docs, resolvers]);
            }, [List(), List()]);
          dispatch(clearModels(missingDocs.toArray()));
          dispatch(clearCurrentDataViewsModels(missingDocs));
          dispatch(setDocumentsPendingRegeneration(missingDocResolverObjects));
          const apiError = new APIError(
            'api.ts => saveEncounterRelatedModels',
            response.status,
            json ?? response,
          );
          handleApiError({ error: apiError, message: 'saveEncounterRelatedModels api failed' });
          createStaticErrorNotification('There was an error while queueing this patient because some docs that you are saving this data with could not be found');
          return Promise.resolve({
            error: true,
            msg: 'MISSING_DOCS',
          });
        }
        if (response.status === 404) {
          dispatch(deleteUnsyncedAPICalls(List([requestId])));
          return Promise.resolve({
            unavailable: true,
            data: List([]),
          });
        }
        if (response && (response.status === 502 || response.status === 500)) {
          const apiError = new APIError(
            'api.ts => saveEncounterRelatedModels',
            response.status,
            json ?? response,
          );
          handleApiError({ error: apiError, message: translate('taking_longer_than_usual_notification', { x: 'Queuing Patient' }) });
          setTimeout(() => dispatch(updateUnsyncedAPICalls(Map({
            [requestId]: {
              enabled: true,
            },
          }))), 300000);
          return Promise.resolve({
            error: true,
            msg: 'TIMEOUT',
          });
        }
        if (response && response.status !== 401) {
          setIsOnline(false);
          dispatch(updateUnsyncedAPICalls(retryJob));
          const apiError = new APIError(
            'api.ts => saveEncounterRelatedModels',
            response.status,
            json ?? response,
          );
          handleApiError({ error: apiError, message: 'saveEncounterRelatedModels api failed' });
        }
        return Promise.resolve({
          error: true,
          msg: '',
        });
      }))
      .catch((error) => {
        if (error && error.status !== 401) {
          if (error.status === 0) {
            setIsOnline(false);
            dispatch(updateUnsyncedAPICalls(retryJob));
          }
        }
        handleApiError({ error });
        return Promise.resolve({
          error: true,
          msg: error,
        });
      });
  }
  dispatch(updateUnsyncedAPICalls(retryJob));
  return Promise.resolve({
    error: true,
    msg: 'Offline',
  });
}

type BillingRelatedModels = EncounterModel
  | BillModel
  | BillItemModel
  | TransactionModel
  | PaymentModel
  | ReceivableModel
  | ClaimModel


/**
 * saves bill and related docs like payment and bill_item during a bill finalization event
 * @param {Dispatch} dispatch Redux dispatch function.
 * @param {BillModel} bill bill being finalized
 * @param {List<TransactionModel>} transactions transactions for bill items
 * @param {List<BillItemModel>} billItems bills items in the current bill
 * @param {EncounterModel} encounter encounter for this bill
 * @param {List<PaymentModel>} payments payment models (optional)
 * @param {List<ReceivableModel>} receivables receivable model for this bill (optional)
 * @param {List<ClaimModel>} claims claim model for this bill (optional)
 * @param {function} onSaveAfterDocValidation function to call after missing docs are resolved and bill finalized
 * @param {string} requestId A requestId to associate with the request to be sent
 * @returns {Promise<Array<BaseModel>>} Models saved
 */
export function saveBillingRelatedModels(
  dispatch: Dispatch,
  bill: BillModel,
  transactions: List<TransactionModel>,
  billItems: List<BillItemModel>,
  encounter: EncounterModel,
  payments?: List<PaymentModel>,
  receivables?: List<ReceivableModel>,
  claims?: List<ClaimModel>,
  onSaveAfterDocValidation?: (savedModels: Array<Model>) => void,
  requestId: string = generateGUID(),
): Promise<APIResponse<BillingRelatedModels>> {
  const requestData = {
    encounter: encounter.attributes,
    bill: bill.attributes,
    ...(transactions && { transactions: transactions.filter(e => !!e).map(e => e.attributes) }),
    bill_items: billItems.filter(e => !!e).map(e => e.attributes),
    ...(payments && { payments: payments.filter(e => !!e).map(e => e.attributes) }),
    ...(receivables && { receivables: receivables.filter(e => !!e).map(e => e.attributes) }),
    ...(claims && { claims: claims.filter(e => !!e).map(e => e.attributes) }),
  };

  const retryJob = Map({
    [requestId]: {
      enabled: true,
      method: (_dispatch: Dispatch) =>
        saveBillingRelatedModels(
          _dispatch,
          bill,
          transactions,
          billItems,
          encounter,
          payments,
          receivables,
          claims,
          onSaveAfterDocValidation,
          requestId,
        ),
    },
  });
  if (getIsOnline()) {
    dispatch(setUnsyncedAPICalls(Map({
      [requestId]: {
        enabled: false,
        method: (
          _dispatch,
          opts = {
            docs: [],
            isResponseDataReceived: false,
          },
        ) => {
          if (opts.isResponseDataReceived) {
            if (opts.docs?.some(doc => doc.created_by.request_id === requestId)) {
              _dispatch(deleteUnsyncedAPICalls(List([requestId])));
            }
            return Promise.resolve(
              {
                ok: true,
                data: List(opts.docs?.map(doc => docToModel(doc))),
              },
            );
          }
          return saveBillingRelatedModels(
            _dispatch,
            bill,
            transactions,
            billItems,
            encounter,
            payments,
            receivables,
            claims,
            onSaveAfterDocValidation,
            requestId,
          );
        },
      },
    })));
    return fetchWithRetry(
      `${getBaseApiUrl()}/billing`,
      {
        method: 'PUT',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'request-id': requestId,
        },
        body: JSON.stringify(requestData),
      },
    )
      .then((response): Promise<APIResponse<BillingRelatedModels>> => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        return response?.json().then((json) => {
          if (response.ok) {
            dispatch(deleteUnsyncedAPICalls(List([requestId])));
            return Promise.resolve({
              ok: true,
              data: List(json.updated_docs.map(doc => docToModel(doc))),
            });
          }
          if (response?.status === 400 && json.error && json.doc_fields && DOC_VALIDATION_ENABLED) {
            const models = List([
              encounter,
              bill,
            ]).concat<TransactionModel|BillItemModel|PaymentModel| ReceivableModel|ClaimModel>(
              receivables || [],
              claims || [],
              transactions || [],
              billItems || [],
              payments || [],
            );
            const modelIdMap = models
              .reduce<{[x: string]: BillingRelatedModels}>((modelMap, model) => (
                { ...modelMap, [model.get('_id')]: model }), {});
            const [missingDocs, missingDocResolverObjects] = Object.keys(json.doc_fields)
              .reduce((
                [docs, resolvers]: [List<BaseModel>, List<MissingDocObject>],
                id: string,
              ) => {
                const model = modelIdMap[id];
                const fields = json.doc_fields[id];
                return fields.reduce((
                  [_docs, _resolvers]: [List<BaseModel>, List<MissingDocObject>],
                  field: string,
                ) => {
                  const missingDocModel = docToModel({
                    _id: model.get(field),
                    type: foreignKeyToDocType(field),
                  });
                  return ([
                    missingDocModel ? _docs.push(missingDocModel) : _docs,
                    _resolvers.push({
                      _id: model.get(field),
                      type: field,
                      referrerDoc: model,
                      noSave: ['sales_item'].includes(foreignKeyToDocType(field)),
                      onDocsResolved: ((resolvedModels: List<Model>) => {
                        const resolvedModelsMap = getModelMapFromList(resolvedModels);
                        return saveBillingRelatedModels(
                          dispatch,
                          resolvedModels.find(e => e.get('type') === 'bill') || bill,
                          transactions?.filter(e => !resolvedModelsMap.has(e.get('_id')))
                            .concat(resolvedModels
                              .filter(e => e.get('type') === 'transaction' && (!e.get('_deleted') || e.hasBeenSaved()))) || List(),
                          billItems?.filter(e => !resolvedModelsMap.has(e.get('_id')))
                            .concat(resolvedModels
                              .filter(e => e.get('type') === 'bill_item' && (!e.get('_deleted') || e.hasBeenSaved()))) || List(),
                          resolvedModels.find(e => e.get('type') === 'encounter') || encounter,
                          payments?.filter(e => !resolvedModelsMap.has(e.get('_id')))
                            .concat(resolvedModels
                              .filter(e => e.get('type') === 'payment' && (!e.get('_deleted') || e.hasBeenSaved()))),
                          receivables?.filter(e => !resolvedModelsMap.has(e.get('_id')))
                            .concat(resolvedModels
                              .filter(e => e.get('type') === 'receivable' && (!e.get('_deleted') || e.hasBeenSaved()))) || List(),
                          claims?.filter(e => !resolvedModelsMap.has(e.get('_id')))
                            .concat(resolvedModels
                              .filter(e => e.get('type') === 'claim' && (!e.get('_deleted') || e.hasBeenSaved()))) || List(),
                          onSaveAfterDocValidation,
                          requestId,
                        ).then((res) => {
                          if (res.ok) {
                            if (onSaveAfterDocValidation) {
                              onSaveAfterDocValidation(res.data?.toArray() || []);
                            }
                            return (
                              res.data?.toArray() as Array<EncounterModel|BillModel|BillItemModel>
                            );
                          }
                          return [];
                        });
                      }),
                    }),
                  ]);
                }, [docs, resolvers]);
              }, [List(), List()]);
            dispatch(clearModels(missingDocs.toArray()));
            dispatch(clearCurrentDataViewsModels(missingDocs));
            dispatch(setDocumentsPendingRegeneration(missingDocResolverObjects));
            const apiError = new APIError(
              'api.ts => saveBillingRelatedModels',
              response.status,
              json,
            );
            handleApiError({ error: apiError, message: 'saveBillingRelatedModels api failed' });
            createStaticErrorNotification('There was an error while finalizing this bill because some docs that you are saving this data with could not be found');
            return Promise.resolve({
              error: true,
              msg: 'MISSING_DOCS',
            });
          }
          if (response.status === 404) {
            dispatch(deleteUnsyncedAPICalls(List([requestId])));
            return Promise.resolve({
              unavailable: true,
              data: List([]),
            });
          }
          if (response && (response.status === 502 || response.status === 500)) {
            const apiError = new APIError(
              'api.ts => saveBillingRelatedModels',
              response.status,
              json,
            );
            handleApiError({ error: apiError, message: translate('taking_longer_than_usual_notification', { x: 'Finalizing bill' }) });
            setTimeout(() => dispatch(updateUnsyncedAPICalls(Map({
              [requestId]: {
                enabled: true,
              },
            }))), 300000);
            return Promise.resolve({
              error: true,
              msg: 'TIMEOUT',
            });
          }
          if (response && response.status !== 401) {
            setIsOnline(false);
            dispatch(updateUnsyncedAPICalls(retryJob));
            const apiError = new APIError(
              'api.ts => saveBillingRelatedModels',
              response.status,
              json,
            );
            handleApiError({ error: apiError, message: 'saveBillingRelatedModels api failed' });
          }
          return Promise.resolve({
            error: true,
            msg: '',
          });
        });
      })
      .catch((error) => {
        if (error && error.status !== 401) {
          if (error.status === 0) {
            setIsOnline(false);
            dispatch(updateUnsyncedAPICalls(retryJob));
          }
        }
        handleApiError({ error });
        return Promise.resolve({
          error: true,
          msg: error,
        });
      });
  }
  dispatch(updateUnsyncedAPICalls(retryJob));
  return Promise.resolve({
    error: true,
    msg: 'Offline',
  });
}
/**
 * updates clinic settingd through API and updates config in redux store
 * @param {MapValue} settings settings to update
 * @returns {Promise<void>} API response
 */
export function updateClinicSettings(
  settings: MapValue,
): Promise<any> {
  if (getIsOnline()) {
    return fetchWithRetry(`${getBaseUrl()}/campaigns/settings`,
      {
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(settings),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        handleUnauthorisedApiResponse(response ? response.status : null);
        if (response.ok) {
          return Promise.resolve({ ok: true });
        }
      })
      .catch((error) => {
        handleApiError({ error });
        return Promise.resolve({
          ok: false,
        });
      });
  }
  offlinePromptHandler();
  return Promise.resolve({
    ok: false,
  });
}

/**
 * Save an event for pharmaconnect
 * @returns {Promise<null>} Promise
 */
export const createExportRequest = (
): Promise<null> => fetchWithRetry('api/export', {
  method: 'POST',
  credentials: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
}).then((response) => {
  if (response.status === 200) {
    return response.json().then(res => Promise.resolve(res.msg));
  }
  if (response.status === 409) {
    createStaticErrorNotification('Another data export is in progress');
    return Promise.reject();
  }
  const apiError = new APIError(
    'api.ts => createExportRequest',
    response.status,
    response,
  );
  handleApiError({ error: apiError, message: 'There was an error while creating data export request' });
  return Promise.reject();
}).catch(err => handleApiError(err));
