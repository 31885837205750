import * as React from 'react';
import { PortalWithState } from 'react-portal';

import ModalContent from './modalContent';
import Button from './../buttons/button';

import type { HTMLStyle } from './../../types';

type Props = {
  buttonClass: string,
  buttonLabel: string,
  className: string,
  disableButton: boolean,
  children: React.Node,
  noButton: boolean, // If true, no activator is displayed.
  onClose?: () => void,
  title: string,
  style: HTMLStyle,
  coverStyle: HTMLStyle,
  large?: boolean,
  explicitCloseOnly: boolean, // If true the modal can only be closed by clicking on a button (ie. not esc or clicking on mask).
  dataPublic?: boolean,
};

/**
 * A higher order component that can acts as a Modal containing any passed children.
 * @class Modal
 * @extends {Component}
 */
class Modal extends React.PureComponent<Props> {
  static defaultProps = {
    buttonClass: 'o-button',
    className: '',
    disableButton: false,
    noButton: false,
    style: {},
    explicitCloseOnly: false,
    coverStyle: {},
  };

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <PortalWithState
        closeOnOutsideClick={!this.props.explicitCloseOnly}
        closeOnEsc={!this.props.explicitCloseOnly}
        onClose={() => {
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
      >
        {({ openPortal, closePortal, portal }) => [
          !this.props.noButton &&
          <Button
            className={this.props.buttonClass}
            onClick={openPortal}
            disabled={this.props.disableButton}
            dataPublic={this.props.dataPublic}
          >
            {this.props.buttonLabel}
          </Button>,
          portal(
            <ModalContent
              {...this.props}
              closePortal={() => {
                closePortal();
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}
            >
              {this.props.children}
            </ModalContent>,
          ),
        ]}
      </PortalWithState>
    );
  }
}

export default Modal;
