import React from 'react';
import Popover from 'react-popover';

import CanvasColourPicker from './canvasColourPicker';
import translate from './../../utils/i18n';

import type { CanvasPenSettings } from './../../types';

type Props = {
  penSettings: CanvasPenSettings,
  onSettingsChanged: (penSettings: CanvasPenSettings) => void,
};

type State = {
  sizePopupOpen: boolean,
};

/**
 * A component displaying a color picker and pen size picker for a canvas component.
 * @class CanvasSettings
 * @extends {React.Component<Props, State>}
 */
class CanvasSettings extends React.Component<Props, State> {
  /**
   * Creates an instance of CanvasSettings.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      sizePopupOpen: false,
    };
  }

  /**
   * Handles a pen size change event.
   * @param {string} size The new size
   * @returns {void}
   */
  onPenSizeChanged(size: string) {
    const newSettings = Object.assign({}, this.props.penSettings, { size });
    this.props.onSettingsChanged(newSettings);
  }

  /**
   * Renders the size input.
   * @returns {React.Component}
   */
  renderSizeInput() {
    return (
      <div>
        <input
          type="range"
          min="1"
          max="50"
          value={this.props.penSettings.size}
          onChange={(event: SyntheticInputEvent<*>) => this.onPenSizeChanged(event.target.value)}
        />
        <span data-public>{ this.props.penSettings.size }{translate('px')}</span>
      </div>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <aside className="c-canvas-settings">
        <CanvasColourPicker
          penSettings={this.props.penSettings}
          onChange={this.props.onSettingsChanged}
        />
        <Popover
          isOpen={this.state.sizePopupOpen}
          body={this.renderSizeInput()}
          className="c-canvas-settings__size__popover"
          place="below"
        >
          <div
            className="c-canvas-settings__size"
            onClick={() => this.setState({ sizePopupOpen: !this.state.sizePopupOpen })}
          >
            {translate('size')}
          </div>
        </Popover>
      </aside>
    );
  }
}

export default CanvasSettings;
