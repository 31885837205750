import { List } from 'immutable';

import { docToModel } from './models';
import type { Model, ErrorResponse } from './../types';
import { decryptModels, encryptData, createBufferFromHex } from './crypto';
import { getEncryptKey, getEncryptSalt } from './auth';
import { logMessage } from './logging';
import { handleApiError } from './response';

const DECRYPT_RETRY_LIMIT = 5;

/**
 * A generic error handler that will log the error to the console and app insight and show a notification to the user
 * @param {any} error The error response
 * @param {string} message A message for the thrown error.
 * @return {void}
 */
function handleErrors(
  error: ErrorResponse,
  message: string = 'Something went wrong with the unsynced docs',
) {
  handleApiError({ error, message, notificationType: 'static' });
}

/**
  * Get the decrypted unsynced model from the localStorage.
  * @param {PouchDB} db handle to the local db
  * @param {string} userID user ID mapped with the unsync doc.
  * @return {List<Model>}
  */
export function getCurrentUnsyncedModels(db: PouchDB.Static, userID: string): Promise<List<Model>> {
  if (!db) {
    return Promise.resolve(List());
  }
  return db
    .query('unsynced_docs/allUnsyncedDocs')
    .catch((err) => {
      handleErrors(err, 'Something went wrong when getting the current unsynced models');
    })
    .then((res) => {
      if (res && res.total_rows && getEncryptKey()) {
        const [decryptedModels, failedModels] = res.rows
          .reduce(([decrypted, failed], row) => {
            if (row.value) {
              const model = decryptModels(
                createBufferFromHex(row.value.doc),
                getEncryptKey(),
                getEncryptSalt(userID),
              );
              if (model) {
                return [decrypted.push(model), failed];
              }
              if (row.value.retry >= DECRYPT_RETRY_LIMIT) {
                logMessage(`Removing undecryptable model: id: ${row.value._id}, type: ${row.value.doc_type}`, 'info');
              }
              return [decrypted, failed.push({
                ...row.value,
                ...row.value.retry >= DECRYPT_RETRY_LIMIT && { _deleted: true },
                retry: row.value.retry ? row.value.retry + 1 : 1,
              })];
            }
            return [decrypted, failed];
          }, [List(), List()]);
        return (failedModels.size
          ? db.bulkDocs(failedModels.toArray())
            .catch((err) => {
              handleErrors(err, 'Something went wrong when updating the current failed to decrypt unsynced models with retry');
            })
          : Promise.resolve())
          .then(() => {
            const modelsObjects = decryptedModels && decryptedModels.size ?
              decryptedModels : List();
            return modelsObjects.reduce((modelListAccumulator, currentModel) => {
              const convertedModel = docToModel(currentModel.attributes);
              convertedModel.changes = currentModel.changes;
              convertedModel.mergeFunctions = currentModel.mergeFunctions;
              return modelListAccumulator.push(convertedModel);
            }, List());
          });
      }
      return List();
    })
    .catch((err) => {
      handleErrors(err, 'Something went wrong when decrypting the unsynced models');
    });
}

/**
  * Update the encrypted unsynced model to the localStorage.
  * @param {PouchDB} db handle to the local db
  * @param {string} userID user ID mapped with the unsync doc.
  * @param {List<Model>} models A list of models to be updatedss.
  * @param {string} encryptKey the key used to encrypt the model made from auth details
  * @return {void}
  */
export function updateLocalUnsyncedModels(
  db: PouchDB.Static,
  userID: string,
  models: List<Model>,
  encryptKey: string,
): void {
  const encryptedModels = models.map(model => ({
    _id: model.get('_id'),
    type: 'unsynced_model',
    doc_type: model.get('type'),
    doc: encryptData(model, (encryptKey || getEncryptKey()), getEncryptSalt(userID), 'UNSYCNED_MODELS').toHex(),
  })).toJS();
  db.bulkDocs(encryptedModels)
    .catch((err) => {
      handleErrors(err, 'Something went wrong when updating the current unsynced models');
    });
}

/**
  * Deletes all the local unsynced Models.
  * @param {PouchDB} db handle to the local db
  * @return {void}
  */
export function deleteAllLocalUnsyncedModels(db: PouchDB.Static): Promise<void> {
  return db.query('unsynced_docs/allUnsyncedDocs')
    .then((res) => {
      const encryptedModels = res.rows.map(row => ({
        ...row.value,
        _deleted: true,
      }));
      return db.bulkDocs(encryptedModels)
        .catch((err) => {
          handleErrors(err, 'Something went wrong when deleteing all the current unsynced models');
        });
    });
}

/**
  * Deletes all the local unsynced Models.
  * @param {PouchDB} db handle to the local db
  * @param {Array<string>} ids the row ids of the models to delete
  * @return {void}
  */
export function deleteLocalUnsyncedModels(db: PouchDB.Static, ids: Array<string>): Promise<void> {
  return db.allDocs({
    keys: ids,
    include_docs: true,
  })
    .then((res) => {
      const encryptedModels = res.rows
        .filter(row => !row.value.deleted)
        .map(row => ({
          ...row.doc,
          _deleted: true,
        }));
      db.bulkDocs(encryptedModels)
        .catch((err) => {
          handleErrors(err, 'Something went wrong when deleteing the current unsynced models');
        });
    });
}
