import React from 'react';
import glamorous, { P } from 'glamorous';

import { margins } from './../utils/css';
import Modal from './modals/statelessModal';
import SaveButton from './buttons/saveButton';
import translate from './../utils/i18n';

const MessageWrapper = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  margin: margins.standardMargins,
  '& button': {
    alignSelf: 'flex-end',
  },
});

/**
 * A modal that can't be closed with a button that will restart the app.
 * @class TimedOutModal
 * @extends {React.Component<Props, State>}
 */
const TimedOutModal = function TimedOutModal() {
  return (
    <Modal
      id="time-out-modal"
      visible
      noButton
      noCloseButton
      title={translate('restart_klinify')}
      setVisible={() => {}}
      dataPublic
    >
      <MessageWrapper>
        <P css={{ marginBottom: margins.standardMarginBottom }}>
          There&apos;s a new version of Klinify available. Restart the app to check it out!
        </P>
        <SaveButton onClick={() => location.reload(true)} label={translate('restart_klinify')} />
      </MessageWrapper>
    </Modal>
  );
};

export default TimedOutModal;
