/**
 * An Error Class thrown if the API request has an error that needs to be propagated back to the caller
 */
class APIError extends Error {
  status: number;

  reason: string;

  resp: {};

  /**
   * Creates an instance of APIError.
   * @param {string} reason Error message.
   * @param {number} status Status code
   * @param {object} resp the response from the API if any.
   */
  constructor(reason: string, status: number, resp: {}) {
    super(`API request failed. Code: ${status}; msg: ${reason};`);
    this.status = status;
    this.reason = reason;
    this.resp = resp;
  }
}

export default APIError;
