/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable require-jsdoc */
import React from 'react';
import EncounterFlowModel from 'src/models/encounterFlowModel';
import PractitionerModel from 'src/models/practitionerModel';
import Datetime from 'react-datetime';
import { List } from 'immutable';
import glamorous from 'glamorous';
import moment, { Moment } from 'moment';
import translate from '../../utils/i18n';
import { wsUnit } from '../../utils/css';
import type { MapValue, BlockOffTime } from '../../types';

import Select from '../inputs/select';
import Button from '../buttons/button';
import 'react-datetime/css/react-datetime.css';
import Header from '../header/header';
import { InputForm, InputFormGroup, ViewMode } from './appointments';
import TextArea from '../inputs/textarea';
import Confirm from '../prompts/confirm';


type Props = {
    isSaving: boolean;
    isDeleting: boolean;
    practitioners: List<PractitionerModel>;
    blockOffTime: BlockOffTime,
    viewMode: ViewMode,
    onCreateBlockOffTime: (isNew: boolean) => void,
    onCancelBlockOffTime: () => void,
    onDeleteBlockOffTime: () => void,
    updateBlockOffTimeState: (key: string, value: MapValue) => void,
};

type State = {
  showPrompt: boolean
}

const BLOCK_TYPES = [
  {
    value: 'clinic',
    label: 'Clinic',
  },
  {
    value: 'doctor',
    label: 'Doctor',
  },
  {
    value: 'service',
    label: 'Service',
  },
];

const REPEAT_WEEKS = [
  {
    value: '1',
    label: '1 Week',
  },
  {
    value: '2',
    label: '2 Weeks',
  },
  {
    value: '3',
    label: '3 Weeks',
  },
  {
    value: '4',
    label: '4 Weeks',
  },
];

const REPEAT_DAYS = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '7', label: 'Sunday' },
];


const BLOCK_NAME_CLINIC = [
  { value: 'Consultation', label: 'Consultation' },
  { value: 'Home Visit', label: 'Home Visit' },
  { value: 'Teleconsultation', label: 'Teleconsultation' },
  { value: 'Employment Checkup', label: 'Employment Checkup' },
  { value: 'Health Screening', label: 'Health Screening' },
  { value: 'Drug Refill', label: 'Drug Refill' },
];


/**
 * A component for selecting the filter for displaying lab requests
 * @class AddEditAppointments
 * @extends {Component}
 */
class BlockTime extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showPrompt: false,
    };
  }

  getBlockNames(): MapValue {
    switch (this.props.blockOffTime.block_main_type) {
      case 'doctor':
        return this.props.practitioners.map(e => ({ value: e.get('_id'), label: e.get('name') }));
      case 'service':
        return BLOCK_NAME_CLINIC;
      default:
        return [];
    }
  }

  render() {
    const { showPrompt } = this.state;
    return (
      <div>
        <Header style={{ color: 'white', backgroundColor: '#CD386A' }} className="o-card__header" dataPublic>
          <h1 style={{ backgroundColor: '#CD386A' }} className="o-card__title">
            { translate('block_time')}
          </h1>
        </Header>
        <InputFormGroup>
          <Select
            disabled={this.props.viewMode === 'edit'}
            id="block-type"
            required
            label={translate('type')}
            value={this.props.blockOffTime.block_main_type || null}
            options={BLOCK_TYPES}
            onValueChanged={value => this.props.updateBlockOffTimeState('block_main_type', value)}
          />
          <Select
            disabled={this.props.blockOffTime.block_main_type === 'clinic' || this.props.viewMode === 'edit'}
            id="block-name"
            placeholder={this.props.blockOffTime.block_main_type === 'clinic' ? 'Not applicable' : 'Select...'}
            label={translate('name')}
            options={this.getBlockNames()}
            value={this.props.blockOffTime.block_name || null}
            onValueChanged={value => this.props.updateBlockOffTimeState('block_name', value)}
          />

          <Select
            disabled={this.props.blockOffTime.block_main_type !== 'doctor' || this.props.viewMode === 'edit'}
            id="block-subtype"
            placeholder={this.props.blockOffTime.block_main_type !== 'doctor' ? 'Not applicable' : 'Select...'}
            label={translate('block_type')}
            options={[{ value: 'Leave', label: 'Leave' }, { value: 'Blocked Time', label: 'Blocked Time' }]}
            value={this.props.blockOffTime.block_sub_type || null}
            onValueChanged={value => this.props.updateBlockOffTimeState('block_sub_type', value)}
          />
          <div className="o-form__item u-margin-bottom--1wsunit">
            <label className="o-label">Start Date and Time</label>
            <Datetime
              value={moment(this.props.blockOffTime.start_timestamp)}
              onChange={(value) => {
                if (typeof value !== 'string') {
                  this.props.updateBlockOffTimeState('start_timestamp', moment(value)
                    .hour(value.hour())
                    .minute(value.minute())
                    .second(0)
                    .valueOf());
                }
              }}
              dateFormat="DD-MM-YYYY"
              inputProps={{ label: 'Start Date and Time', className: 'css-66wpz6-Control css-g1d714-ValueContainer', style: { fontSize: 'inherit' } }}
            />
          </div>

          <div className="o-form__item u-margin-bottom--1wsunit">
            <label className="o-label">End Date and Time</label>
            <Datetime
              value={moment(this.props.blockOffTime.end_timestamp)}
              onChange={(value) => {
                if (typeof value !== 'string') {
                  this.props.updateBlockOffTimeState('end_timestamp', moment(value)
                    .hour(value.hour())
                    .minute(value.minute())
                    .second(0)
                    .valueOf());
                }
              }}
              dateFormat="DD-MM-YYYY"
              inputProps={{ label: 'End Date and Time', className: 'css-66wpz6-Control css-g1d714-ValueContainer', style: { fontSize: 'inherit' } }}
            />
          </div>
          <Select
            id="block-repeat-weeks"
            label={translate('repeats_every')}
            options={REPEAT_WEEKS}
            value={this.props.blockOffTime.repeat_every_weeks.toString() || null}
            onValueChanged={value => this.props.updateBlockOffTimeState('repeat_every_weeks', +value)}
          />
          <Select
            id="block-repeat-days"
            label={translate('repeats_on')}
            isMulti
            options={REPEAT_DAYS}
            value={this.props.blockOffTime.repeat_on_weekdays_raw || null}
            onValueChanged={value => this.props.updateBlockOffTimeState('repeat_on_weekdays_raw', value)}
          />
          <TextArea
            id="remarks"
            placeholder="Write a comment..."
            label={translate('remarks')}
            value={this.props.blockOffTime.remarks || ''}
            onValueChanged={value => this.props.updateBlockOffTimeState('remarks', value)}
          />
          <InputForm>
            <Button
              isLoading={this.props.viewMode === 'edit' ? this.props.isDeleting : false}
              style={{ marginBottom: '1rem', position: 'relative', backgroundColor: 'orange', color: 'white', border: '2px solid orange' }}
              className="o-button o-button--small"
              onClick={() => (this.props.viewMode === 'edit' ? this.setState({ showPrompt: true })
                : this.props.onCancelBlockOffTime())}
              dataPublic
            >
              {translate(this.props.viewMode === 'edit' ? 'delete' : 'cancel')}
            </Button>
            <Button
              isLoading={this.props.isSaving}
              style={{ marginBottom: '1rem', position: 'relative' }}
              className="o-button o-button--small"
              onClick={() => this.props.onCreateBlockOffTime(this.props.viewMode === 'create')}
              dataPublic
            >
              {translate(this.props.viewMode === 'edit' ? 'update_block_off_time' : 'save_block_off_time')}
            </Button>
          </InputForm>
        </InputFormGroup>
        <Confirm
          show={showPrompt}
          cancel={() => this.setState({ showPrompt: false })}
          proceed={() => {
            this.setState({ showPrompt: false }, () => this.props.onDeleteBlockOffTime());
          }}
          confirmation={translate('delete_block_off_time_warning')}
          modalTitle={translate('warning')}
          footerSaveButtonName={translate('confirm')}
        />
      </div>
    );
  }
}

export default BlockTime;
