import React, { Fragment } from 'react';

import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import SpecimenModel from './../../models/specimenModel';
import SavePrompt from './../prompts/savePrompt';
import ModalFooter from './../modals/modalFooter';
import Keypress from './../keypress';
import FormError from './../formError';

import type { SaveModel, MapValue } from './../../types';


type Props = {
  modelToEdit?: SpecimenModel,
  onSave: SaveModel,
  disabled: boolean,
};

/* eslint-disable camelcase */
type SpecimenAttr = {
  name: string,
  is_editable: boolean,
  internal_clinic_id: string,
}
/* eslint-enable camelcase */

type State = {
  isSaving: boolean,
  changesMade: boolean,
  attributes: SpecimenAttr,
  errorMessage?: string,
};

/**
 * Gets the default state of the component.
 * @param {SpecimenModel?} model A specimen model
 * @returns {State}
 */
function getDefaultState(model?: SpecimenModel) {
  if (model) {
    return Object.assign({}, {
      isSaving: false,
      changesMade: false,
      attributes: {
        name: model.get('name', ''),
        is_editable: model.get('is_editable', true),
        internal_clinic_id: model.get('internal_clinic_id', ''),
      },
    });
  }
  return Object.assign({}, {
    isSaving: false,
    changesMade: false,
    attributes: {
      name: '',
      active: true,
      is_editable: true,
      internal_clinic_id: '',
    },
  });
}

/**
 * A form for creating or Specimen.
 * @class LabsForm
 * @extends {React.Component<Props, State>}
 */
class SpecimensForm extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  state: State;

  /**
   * Creates an instance of SpecimensForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = getDefaultState(props.modelToEdit);
  }

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState({
      attributes: Object.assign(this.state.attributes, { [key]: value }),
      changesMade: true,
    });
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    if (this.state.attributes.name.length) {
      return true;
    }
    return this.setState({ errorMessage: translate('name_cannot_be_empty_please_fill_out_the_field') });
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}  A promise with a value of true if successful and false if failed.
   */
  onSaveClicked(): Promise<boolean> {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const model = this.props.modelToEdit ?
        this.props.modelToEdit.set(this.state.attributes) :
        new SpecimenModel(this.state.attributes);
      this.props.onSave(model).then(() => {
        this.setState({ isSaving: false });
      });
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  /**
   * Update state if model changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      this.setState(getDefaultState(nextProps.modelToEdit));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const idPrefix = this.props.modelToEdit ?
      `specimen_${this.props.modelToEdit.get('_id')}` : 'specimen';
    return (
      <Fragment>
        <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
          { this.state.errorMessage &&
            <FormError containerElementID="specimensForm">{this.state.errorMessage}</FormError>
          }
          <SavePrompt
            when={this.state.changesMade}
            onSaveClicked={() => this.onSaveClicked()}
          />
          <Input
            id={`${idPrefix}_name`}
            label={translate('name')}
            value={this.state.attributes.name}
            onValueChanged={value => this.updateAttribute('name', value)}
            required
            disabled={!this.state.attributes.is_editable}
          />
          <Input
            id={`${idPrefix}_internal_clinic_id`}
            label={translate('custom_name')}
            value={this.state.attributes.internal_clinic_id}
            onValueChanged={value => this.updateAttribute('internal_clinic_id', value)}
            disabled={!this.state.attributes.is_editable}
          />
        </Keypress>
        <ModalFooter>
          {
            !this.props.disabled &&
            <SaveButton
              className="o-button--small u-margin-right--half-ws"
              isSaving={this.state.isSaving}
              label={translate('save')}
              onClick={() => this.onSaveClicked()}
              dataPublic
            />
          }
        </ModalFooter>
      </Fragment>
    );
  }
}

export default SpecimensForm;
