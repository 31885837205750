import { List } from 'immutable';
import { connect } from 'react-redux';

import DocumentDataForm from './../components/documentTemplates/documentDataForm';
import { saveFactory } from './../utils/redux';
import { getModelMapFromList } from '../utils/models';

import type { Dispatch, State } from './../types';
import type DocumentTemplateModel from './../models/documentTemplateModel';

type Props = {
  documentTemplate: DocumentTemplateModel,
  closeModal: () => void,
  encounterId: string,
  patientId: string,
  practitionerId?: string,
  customDocumentDataTitle?: string,
}

/**
   * @param {Object} state Current app state.
   * @param {Props} ownProps Props
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  {
    config, allergies, medicalCertificates, prescriptions, timeChits, patients, encounters,
    salesItems, drugs, coveragePayors, practitioners, bills, billItems, payments, conditions,
    procedureTypes, encounterStages, discountsCharges,
  }: State,
  ownProps: Props,
) => {
  const { patientId, encounterId } = ownProps;
  const encounter = encounters.find(m => m.get('_id') === encounterId);
  const bill = encounter ?
    bills.find(b => b.get('_id') === encounter.get('bill_id') && !b.isVoid() && b.isFinalised()) : // If bill is void or unfinalised then we won't be using it yet.
    undefined;
  return Object.assign({}, ownProps, {
    ownProps,
    documentTemplateData: {
      config,
      patient: patients.find(m => m.get('_id') === patientId),
      encounter,
      allergies: allergies.filter(m => m.get('patient_id') === patientId),
      medicalCertificates: medicalCertificates.filter(m => m.get('encounter_id') === encounterId),
      prescriptions: prescriptions.filter(m => m.get('encounter_id') === encounterId),
      timeChits: timeChits.filter(m => m.get('encounter_id') === encounterId),
      salesItems,
      drugs,
      coveragePayors,
      practitioners,
      practitioner: practitioners.find(p => p.get('_id') === encounter?.getValue('doctor')),
      bill,
      billItems: bill ?
        billItems.filter(i => i.get('bill_id') === bill.get('_id')) :
        List(),
      payments: bill ?
        payments.filter(p => p.get('bill_id') === bill.get('_id') && !p.isVoid()) :
        List(),
      conditions: conditions.filter(m => m.get('encounter_id') === encounterId),
      procedureTypes,
      encounterStageMap: getModelMapFromList(encounterStages),
      discountsCharges,
      usedDiscountsCharges: bill ?
        List(bill.get('applied_discounts_charges') ? (bill.get('applied_discounts_charges').map(e => discountsCharges?.find(d => d.get('_id') === e)).filter(e => !!e) || []) : []) :
        List(),
    },
  });
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({ saveModel: saveFactory(dispatch) });

const DocumentDataFormContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentDataForm);

export default DocumentDataFormContainer;
