
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { List } from 'immutable';

import type { Dispatch, State, ReactRouterProps } from './../types';
import { fetchAnalyticsReportDetail, fetchAnalyticsQueryDetail } from './../utils/api';
import { setIsFetching, setAnalyticsDateRange, setAnalyticsReportDetail, setAnalyticsQueriesDetail } from './../actions';

import ReportPage from './../components/analytics/reportPage';
import type AnalyticsReportModel from '../models/analytics/reportModel';
import type AnalyticsQueryResponseModel from '../models/analytics/queryResponseModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ analyticsReportViews }: State, { match }: ReactRouterProps) => ({
  dateRange: analyticsReportViews.dateRange,
  reportDetail: analyticsReportViews.reportDetail,
  queriesDetail: analyticsReportViews.queriesDetail,
  reportID: match.params.report_id,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAnalyticsReportDetail,
  fetchAnalyticsQueryDetail,
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setAnalyticsDateRange: (dateRange: string) => dispatch(setAnalyticsDateRange(dateRange)),
  setAnalyticsReportDetail: (reportDetail: AnalyticsReportModel) =>
    dispatch(setAnalyticsReportDetail(reportDetail)),
  setAnalyticsQueriesDetail: (queriesDetail: List<AnalyticsQueryResponseModel>) =>
    dispatch(setAnalyticsQueriesDetail(queriesDetail)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportPage));
