import * as React from 'react';
import UrlPattern from 'url-pattern';

import { isReferralMode } from './../utils/router';
import SidebarContainer from './../containers/sidebarContainer';

const FULL_SCREEN_ROUTES = [
  new UrlPattern('#/queue'),
  new UrlPattern('#/patient/:patientID/categories/:categoryID/add(/*)'),
  new UrlPattern('#/patient/:patientID/categories/:categoryID/add-text'),
  new UrlPattern('#/patient/:patientID/casenotes/:casenoteID/edit(/*)'),
  new UrlPattern('#/patient/:patientID/templates(/:templateGroupID)'),
  new UrlPattern('#/patient/:patientID/vitals/:metricTypeID/add'),
  new UrlPattern('#/styleguide(/*)'),
];

type Props = {
  children: React.Node,
  ignoreReferralMode?: boolean,
};

/**
   * Layout is a component that wraps a page in the Klinify app. It contains a Sidebar and Header
   * component.
   * @namespace Layout
   * @param {Object} props The props for this component.
   * @returns {React.Component} The Layout component.
*/
const Layout = ({ children, ignoreReferralMode }: Props) => (
  FULL_SCREEN_ROUTES.some(route => route.match(window.location.hash.split('?')[0])) ||
    (!ignoreReferralMode && isReferralMode()) ?
      <main className="o-main o-main--full-width">{children}</main> :
      <main className="o-main o-main--left-sidebar">
        <SidebarContainer />
        {children}
      </main>
);

export default Layout;
