import { connect } from 'react-redux';
import { List } from 'immutable';

import PanelPayments from './../components/claimInvoicesPayment/panelPayments';
import AsyncFetch from './../components/asyncFetch';
import { saveModelsFactory } from './../utils/redux';
import {
  setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfigValue,
  updateConfig, setCurrentDataViewsError,
} from './../actions';
import { getClaimInvoicesDataViews } from './../dataViews';

import type { Dispatch, State, Config, DataView } from './../types';
import type APIError from '../utils/apiError';
import type BaseModel from '../models/baseModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  user: state.user,
  config: state.config,
  banks: state.banks.filter(b => b.isVisible()),
  coveragePayors: state.coveragePayors.filter(m => m.isVisible()),
  containerDataViews: getClaimInvoicesDataViews(),
  currentDataViews: state.currentDataViews,
  isFetching: state.isFetching,
  claimInvoices: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'claim_invoice' && !m.get('is_void')),
  claimRecons: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'claim_reconciliation' && !m.isVoid()),
  claimInvoicePayments: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'claim_invoice_payment' && !m.isVoid()),
  paymentTypes: state.paymentTypes.filter(m => m.isVisible()),
  currentDataViewsError: state.currentDataViewsError,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const PanelPaymentsContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(PanelPayments));

export default PanelPaymentsContainer;
