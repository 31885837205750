import React from 'react';
import glamorous from 'glamorous';
import { Map } from 'immutable';

import { isEqual } from 'lodash';
import { wsUnit, colours, fontSize, topBarHeight } from '../../utils/css';
import EncounterStageModel from '../../models/encounterStageModel';
import { Config, SaveModel } from '../../types';
import UnreviewedStageForm from './unreviewedStageForm';

type Props = {
  encounterStageMap: Map<string, EncounterStageModel>,
  saveModel: SaveModel,
  config: Config,
  updateConfig: (config: Config) => void,
  largeDisplay?: boolean
}

const InfoBarConatiner = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  color: colours.white,
  fontSize: fontSize.large,
  backgroundColor: colours.red,
  justifyContent: 'center',
},
({ largeDisplay }) => (largeDisplay ? ({
  height: topBarHeight,
}) : ({ padding: `calc(0.75 * ${wsUnit})` })));

const InfoBarDiv = glamorous.div({
  display: 'flex',
  alignItems: 'center',
});

/**
 * A StagesInfoBar component.
 * @param {Props} props props
 * @returns {React.Component} A StagesInfoBar component
 */
const StagesInfoBarComponent = ({
  saveModel, config, updateConfig, encounterStageMap, largeDisplay = true,
}: Props) => {
  const unreviewedStages = encounterStageMap.filter((s) => {
    const reviewedStages = config.getIn(['reviewed_stages'], []);
    const metadata = s.get('metadata');
    if (!reviewedStages.includes(s.get('_id')) &&
      s.isVisible() && metadata &&
      'is_generated' in metadata &&
      metadata.is_generated) {
      return true;
    }
    return false;
  }).toOrderedMap();

  if (!(unreviewedStages && unreviewedStages.size > 0)) {
    return null;
  }

  return (
    <InfoBarConatiner className="u-sticky-to-top" largeDisplay={largeDisplay}>
      {largeDisplay ?
        <React.Fragment>
          <InfoBarDiv className="u-strong">
            <img src="../../../static/images/icon_warning.svg" alt="warning icon" style={{ height: 20, marginRight: 5 }} />
          &nbsp;
          Locations are now tied to Stages and not Doctors.
          </InfoBarDiv> &nbsp;
          <InfoBarDiv>Please review the clinic Stages and
            add locations to them.
          </InfoBarDiv>&nbsp;&nbsp;
          <UnreviewedStageForm
            saveModel={saveModel}
            config={config}
            updateConfig={updateConfig}
            unreviewedStages={unreviewedStages}
            buttonStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
          /> &nbsp;&nbsp;
          <InfoBarDiv>
            <a href="https://klin.freshdesk.com/support/solutions/folders/36000221434" target="_blank" rel="noopener noreferrer" style={{ color: colours.white, textDecoration: 'underline' }}>Learn More</a>
          </InfoBarDiv>
        </React.Fragment>
        :
        <React.Fragment>
          <InfoBarDiv style={{ fontWeight: 'bold', flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
            <img src="../../../static/images/icon_warning.svg" alt="warning icon" style={{ height: 20, marginRight: 5 }} />
          </InfoBarDiv>
          <InfoBarDiv style={{ flex: 20, flexDirection: 'column', alignItems: 'flex-start' }}>
            <InfoBarDiv className="u-strong">Locations are now tied to Stages and not Doctors.</InfoBarDiv><br />
            <InfoBarDiv>The Queue TV and the clinic
              records will not reflect the correct location until the<br />Stages have
              been reviewed and locations set for them.
            </InfoBarDiv>
            &nbsp;&nbsp;
            <InfoBarDiv>
              <UnreviewedStageForm
                saveModel={saveModel}
                config={config}
                updateConfig={updateConfig}
                unreviewedStages={unreviewedStages}
                buttonStyle={{ marginTop: 'auto', marginBottom: 'auto' }}
              /> &nbsp;&nbsp;
              <a href="https://klin.freshdesk.com/support/solutions/folders/36000221434" target="_blank" rel="noopener noreferrer" style={{ color: colours.white, textDecoration: 'underline' }}>Learn More</a>

            </InfoBarDiv>
          </InfoBarDiv>
        </React.Fragment>
      }
    </InfoBarConatiner>
  );
};

const StagesInfoBar = React.memo(StagesInfoBarComponent, (prevProps, nextProps) => {
  if (prevProps.largeDisplay !== nextProps.largeDisplay) {
    return false;
  }
  const prevReviewedStages = prevProps.config.getIn(['reviewed_stages'], []);
  const nextReviewedStages = nextProps.config.getIn(['reviewed_stages'], []);
  return isEqual(prevReviewedStages, nextReviewedStages);
});

export default StagesInfoBar;
