import React from 'react';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import Table from './../table/table';
import { renderPrice } from './../../utils/tables';
import { isLoading } from './../../utils/utils';

import type { Row, CustomColumn } from './../../types';

type Props = {
  columns: Array<CustomColumn>,
  data: Array<Row>,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  onFilterAndSort: (data: Array<Row>) => void
};

/**
 * Component displaying all Claim Invoices.
 * @class CostReportTable
 * @extends {React.Component}
 */
class CostReportTable extends React.Component<Props, {}> {
  /**
   * Creates an instance of CostReportTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Get table columns
   * @returns {void}
   */
  getColumns() {
    return this.props.columns.map((c) => {
      const defaultColumns = { accessor: c.value, Header: c.label };
      if (c.value === 'type') {
        return {
          ...defaultColumns,
          filterable: true,
          id: 'type',
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            }
            if (filter.value === 'prescription_item') {
              return row[filter.id] === translate('prescription_item');
            }
            return row[filter.id] === translate('sales_item');
          },
          Filter: ({ filter, onChange }) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%', height: 34 }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">{translate('show_all')}</option>
              <option value="prescription_item">{translate('prescription_item')}</option>
              <option value="sales_item">{translate('sales_item')}</option>
            </select>,
        };
      } else if (c.renderMethod === 'PRICE') {
        return { ...defaultColumns, Cell: renderPrice };
      }
      return { ...defaultColumns, filterable: c.filterable };
    });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Table
        columns={this.getColumns()}
        data={this.props.data}
        loading={this.props.isFetching}
        noDataText={
          translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(this.props.isFetching))
        }
        showPagination
        defaultSorted={[{ id: 'name', desc: false }]}
        filteredSortedDataHandler={filteredData => this.props.onFilterAndSort(filteredData)}
        initialDataHandler={initialData => this.props.onFilterAndSort(initialData)}
      />
    );
  }
}

export default CostReportTable;
