import React from 'react';

import { HTMLStyle } from '../../types';
import Step, { InternalStepProps } from './step';

type Props = {
  currentStep: number,
  stepsData: Array<InternalStepProps>,
  style?: HTMLStyle,
  className?: string,
}

/**
 * Steps component.
 * @param {Props} props component props
 * @returns {React.Component | string}
 */
function Steps(props: Props) {
  if (props.stepsData.length < 1) {
    return null;
  }
  const totalStep = props.stepsData && props.stepsData.length > 0 ? props.stepsData.length : 1;
  const currentStep = props.currentStep > totalStep ? totalStep : props.currentStep;
  return (
    <div className={`steps-container ${props.className || ''}`} style={props.style}>
      {
        props.stepsData.map((data, index) =>
          <Step
            key={data.id}
            {...data}
            totalStep={totalStep}
            currentStep={currentStep}
            stepIndex={index}
          />)
      }
    </div>
  );
}

export default Steps;
