import React from 'react';
import type { List } from 'immutable';

import DocumentTemplateSelector from './documentTemplateSelector';
import DocumentDataFormContainer from './../../containers/documentDataFormContainer';
import translate from './../../utils/i18n';

import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  documentTemplates: List<DocumentTemplateModel>,
  closeModal: () => void,
  patientId: string,
  encounterId: string,
  isOnline: boolean,
};

type State = {
  selectedDocumentTemplate: DocumentTemplateModel | void,
};

/**
 * A component for selecting and using a DocumentTemplate to create DocumentData.
 * @class AddDocumentDataView
 * @extends {React.Component<Props, State>}
 */
class AddDocumentDataView extends React.Component<Props, State> {
  /**
   * Creates an instance of AddDocumentDataView.
   * @param {Props} props Props
   * @returns {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = { selectedDocumentTemplate: undefined };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const renderValue = this.state.selectedDocumentTemplate ?
      (<DocumentDataFormContainer
        documentTemplate={this.state.selectedDocumentTemplate}
        closeModal={this.props.closeModal}
        encounterId={this.props.encounterId}
        patientId={this.props.patientId}
      />) :
      (<DocumentTemplateSelector
        closeModal={this.props.closeModal}
        documentTemplates={this.props.documentTemplates}
        setSelectedDocumentTemplate={
          selectedDocumentTemplate => this.setState({ selectedDocumentTemplate })
        }
      />);
    return this.props.isOnline ? renderValue : <p className="u-margin--standard">{translate('cant_create_document_while_offline')}</p>;
  }
}

export default AddDocumentDataView;
