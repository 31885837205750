import BaseModel from './baseModel';

export type Attributes = {
  _id: string,
  type: 'export',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  timestamp: number,
  status: 'queued' | 'in-progress' | 'completed' | 'failed' | 'voided',
  result: null
};

/**
 * ExportModel
 *
 * @namespace ExportModel
*/
class ExportModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes = {}) {
    super(attributes);
    this.attributes.type = 'export';
  }
}

export default ExportModel;
