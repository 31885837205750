import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import Moment from 'moment';

import AsyncFetch from './../components/asyncFetch';
import { getOperationsSummaryDataViews } from './../dataViews';
import OperationsSummary from './../components/reports/operationsSummary';
import { setOperationsSummaryFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels } from './../actions';

import type { Dispatch, State, DataView } from './../types';
import type BaseModel from '../models/baseModel';

/**
 * Get ContainerDataViews. Check consult report dates if equals to operation dates
 * if so, combine the time from consult, if not, just get the dates from operationsSummary filter.
 * @param {State} filter filter from state
 * @returns {void}
 */
function getContainerDataViews(filter: Map<string, any>) {
  const dateStart = filter.getIn(['operationsSummary', 'filterDateStart'], new Moment()).valueOf();
  const dateEnd = filter.getIn(['operationsSummary', 'filterDateEnd'], new Moment())
    .clone()
    .minutes(59)
    .hours(23)
    .seconds(59)
    .valueOf();
  return getOperationsSummaryDataViews(dateStart, dateEnd);
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => {
  const supplyItems = state.currentDataViewsModels.filter(m => m.get('type') === 'supply_item');
  const supplies = state.currentDataViewsModels.filter(m => m.get('type') === 'supply');
  return ({
    filter: state.reportsFilter.get('operationsSummary'),
    bills: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'bill' && !m.isVoid() && m.isFinalised()),
    containerDataViews: getContainerDataViews(state.reportsFilter),
    currentDataViews: state.currentDataViews,
    supplyItems: state.isFetching ? Map() : supplyItems.groupBy(si => si.get('supply_id')),
    supplies: state.isFetching ? List() : supplies,
  });
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, any>) => dispatch(setOperationsSummaryFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const OperationsSummaryContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(OperationsSummary));

export default OperationsSummaryContainer;
