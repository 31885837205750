import { List } from 'immutable';
import { startCase } from 'lodash';
import BaseModel from '../models/baseModel';
import type { SelectOpts, SelectOption } from './../types';
import { compareByAlphabeticalOrder } from './comparators';

/**
 * format each option by setting value as case sensitive or insensitive way of selection, depending on input prop.
 * @param {SelectOption} option The individual option provided as props.
 * @param {boolean} isValueCaseInsensitive if the value of the select dropdown should be selected case insensitive way.
 * @returns {SelectOption} Formatted option.
 */
export function getFromattedSelectOption(
  option: SelectOption,
  isValueCaseInsensitive: boolean,
): SelectOption {
  if (isValueCaseInsensitive && option && option.value) {
    return Object.assign(option,
      { value: option.value.toLowerCase(), valueOriginal: option.value });
  }
  return option;
}

/**
 * TODO: Once the app is fully type checked we can ditch this
 * Hacky way to allow options to just be a list of strings, rather than in the format
 * of an object with label and value.
 * @param {any} options The options provided as props.
 * @param {boolean} isValueCaseInsensitive if the value of the select dropdown should be selected case insensitive way.
 * @returns {any} Formatted options.
 */
export function formatSelectOptions(options: SelectOpts, isValueCaseInsensitive: boolean = false) {
  if (options && options.length > 0) {
    if (typeof options[0] === 'string') {
      return options.map(value =>
        getFromattedSelectOption({ value, label: value }, isValueCaseInsensitive));
    }
    return options.map(option => getFromattedSelectOption(option, isValueCaseInsensitive));
  }
  return options;
}

/**
 * Formats options from list of strings to value label pairs,
 * which is used in Select and Radio
 * @param {any} options The options provided as props.
 * @returns {Array<SelectOption>} Formatted options.
 */
export const mapStringToOption = (
  options: Array<string>,
): Array<SelectOption> => options.filter(option => !!option)
  .map(option => ({
    value: option,
    label: startCase(option),
  }));


/**
 * Gets the options for a Select input from Model Lists.
 * @param {List<BaseModel>} models models.
 * @returns {Array<SelectOption>}
 */
export const getSelectOptionsFromModelList = <T extends BaseModel>(
  models: List<T> = List(),
): Array<SelectOption> =>
    models.map(model => ({
      // encounters are filtered using flow name in backend,
      value: model.get('_id'),
      label: model.get('name'),
    }))
      .sort((a, b) => compareByAlphabeticalOrder(a.label, b.label))
      .toArray();
