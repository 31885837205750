import * as React from 'react';
import { is, List } from 'immutable';

import { hasPermission } from './../../utils/permissions';

import type { User, PermissionRecord } from './../../types';
import memoizeOne from 'memoize-one';

type Props = {
  user: User,
  permissionsRequired: List<PermissionRecord>,
  children?: React.Node,
  denialContent?: () => React.Node,
  showDenialMessage: boolean, // Currently only useful for debugging.
};

const hasPermissionMemo = memoizeOne(
  (user, permissionsRequired) => hasPermission(user, permissionsRequired),
  ([user, permsRequired], [lastUser, lastPermsRequired]) => {
    return (is(user, lastUser) && is(permsRequired, lastPermsRequired));
  },
);

/**
 * A component that can be wrapped around another react component/s and only render them if the user
 * has the specified permissions
 * @class PermissionWrapper
 * @extends {React.PureComponent<Props>}
 */
class PermissionWrapper extends React.PureComponent<Props> {
  static defaultProps = {
    showDenialMessage: false,
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    if (
      this.props.children &&
      (
        !this.props.permissionsRequired ||
        this.props.permissionsRequired.size === 0 ||
        hasPermissionMemo(this.props.user, this.props.permissionsRequired)
      )
    ) {
      return this.props.children;
    } else if (this.props.denialContent) {
      return this.props.denialContent();
    } else if (this.props.showDenialMessage) {
      return <p>Permission Denied</p>;
    }
    return null;
  }
}

export default PermissionWrapper;
