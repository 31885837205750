import 'whatwg-fetch';
import { version } from './../../package.json';
import { generateGUID } from './utils';
/* eslint-disable import/prefer-default-export */

/**
 * Return fetch function with custom headers
 * @param {Request} req endpoint
 * @param {*} opts additional options for the fetch
 * @returns {fetch}
 */
export default function Fetch(req: Request, opts: Object) {
  const hasBody = opts && opts.body;
  const isJsonData = hasBody && !(opts.body instanceof FormData);
  const defaultHeaders = {
    'x-utc-timestamp': Date.now(),
    'x-klinify-request-id': generateGUID(),
    'x-heals-request-id': `Klinify Webapp v${version}`,
    // TODO: uncomment for doc-validation
    // 'x-klinify-webapp-version': version,
    ...(isJsonData && { 'Content-Type': 'application/json' }),
  };
  const options = {
    ...opts,
    headers: {
      ...defaultHeaders,
      ...opts.headers,
    },
  };
  return fetch(req, options);
}
