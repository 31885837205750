import React from 'react';
import type { List } from 'immutable';

import GenericPatientDataForm from './genericPatientDataForm';
import StatelessModal from './../modals/statelessModal';
import Table from './../table/table';
import translate from './../../utils/i18n';
import { renderDate, renderMultilineCell } from './../../utils/tables';
import { createSuccessNotification } from './../../utils/notifications';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';
import Header from './../header/header';

import type { SaveModel, GenericPatientDataModel } from './../../types';

const labels = {
  custom_note: { plural: 'custom_notes', singular: 'note', noItems: 'no_custom_notes' },
  medical_complaint: { plural: 'medical_complaints', singular: 'complaint', noItems: 'no_medical_complaints' },
  medication: { plural: 'current_medication', singular: 'medication', noItems: 'no_medication' },
};

type Props = {
  type: 'custom_note' | 'medical_complaint' | 'medication',
  saveModel: SaveModel,
  patientID: string,
  items: List<GenericPatientDataModel>,
};

type State = {
  isModalVisible: boolean,
  modelToEdit?: GenericPatientDataModel,
};

/**
 * A component that displays a list of generic patient data models of a certain type (e.g.
 * CustomNote, Complaint, or CurrentMedication).
 * @class GenericPatientDataList
 * @extends {React.Component<Props, State>}
 */
class GenericPatientDataList extends React.Component<Props, State> {
  /**
   * Creates an instance of GenericPatientDataList.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }

  /**
   * Called when delete is clicked on an item. Updates its state to be hidden.
   * @param {GenericPatientDataModel} model The item to delete
   * @returns {void}
   */
  onDeleteClicked(model: GenericPatientDataModel) {
    if (model) {
      getConfirmation(translate('confirm_item_deletion'))
        .then(
          () => this.props.saveModel(model.setVisible(false))
            .then(() => {
              createSuccessNotification(translate('item_deleted'));
            }),
          () => {},
        );
    }
  }

  /**
   * Returns a Delete button for the given item.
   * @param {GenericPatientDataModel} model An item for the delete button
   * @returns {React.Component} A Delete button component.
   */
  getDeleteButton(model: GenericPatientDataModel) {
    return (
      <Button
        className="o-text-button o-text-button--danger"
        onClick={() => this.onDeleteClicked(model)}
        dataPublic
      >
        {translate('delete')}
      </Button>
    );
  }

  /**
   * Returns an edit button for the given model.
   * @param {GenericPatientDataModel} model The model to edit.
   * @returns {React.Component}
   */
  getEditButton(model: GenericPatientDataModel) {
    return (
      <Button
        className="o-text-button o-text-button--contextual"
        onClick={() => this.setState({ modelToEdit: model, isModalVisible: true })}
        dataPublic
      >
        {translate('edit')}
      </Button>
    );
  }

  /**
   * Format a model to a Table row.
   * @param {GenericPatientDataModel} model The model for the row
   * @returns {Row}
   */
  getRow(model: GenericPatientDataModel) {
    return {
      date: model.getLastUpdateTime(),
      value: model.getLabel(),
      edit: this.getEditButton(model),
      delete: this.getDeleteButton(model),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <section className="o-card">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{translate(labels[this.props.type].plural)}</h1>
          <div className="u-flex-right u-margin-right--1ws">
            <StatelessModal
              id={`addGenericItemModal-${this.props.type}`}
              buttonLabel={translate(`add_new_${labels[this.props.type].singular}`)}
              buttonClass="o-button o-button--small"
              title={translate(labels[this.props.type].singular)}
              visible={this.state.isModalVisible}
              setVisible={isVisible => this.setState({
                isModalVisible: isVisible,
                modelToEdit: undefined,
              })}
              explicitCloseOnly
              dataPublicHeader
            >
              <GenericPatientDataForm
                onSaveComplete={() => this.setState({ isModalVisible: false })}
                patientID={this.props.patientID}
                type={this.props.type}
                saveModel={this.props.saveModel}
                modelToEdit={this.state.modelToEdit}
              />
            </StatelessModal>
          </div>
        </Header>
        <Table
          data={this.props.items.map(item => this.getRow(item)).toArray()}
          columns={[
            {
              accessor: 'date', Header: translate('date'), Cell: renderDate, maxWidth: 240,
            },
            { accessor: 'value', Header: translate(labels[this.props.type].singular), Cell: renderMultilineCell },
            {
              accessor: 'edit', Header: '', sortable: false, width: 120,
            },
            {
              accessor: 'delete', Header: '', sortable: false, width: 120,
            },
          ]}
          defaultSorted={[{ id: 'time', desc: false }]}
          noDataText={translate(labels[this.props.type].noItems)}
          showPagination
          defaultPageSize={5}
        />
      </section>
    );
  }
}

export default GenericPatientDataList;
