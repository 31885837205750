import React from 'react';

import type { HTMLStyle, UnusedReturn } from './../../types';

type Props = {
  className: string,
  id?: string,
  disabled?: boolean,
  style: HTMLStyle,
  children?: React.ReactNode,
  onClick: (event: MouseEvent) => UnusedReturn,
  type?: string,
  name?: string,
  value?: string,
  isLoading?: boolean,
  dataPublic?: boolean,
}

/**
 * A Button component.
 */
class Button extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    className: '',
    style: {},
    type: 'button',
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { className, id, disabled, style, type, name, value, dataPublic, isLoading } = this.props;
    return (
      <button
        className={isLoading ? className.concat(' button--loading disable-pointer') : className}
        id={id}
        style={style}
        disabled={disabled}
        onClick={this.props.onClick}
        name={name}
        type={type}
        value={value}
        data-public={dataPublic}
      >
        { !isLoading ? this.props.children || null : null }
      </button>
    );
  }
}

export default Button;
