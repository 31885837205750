import BaseModel from './baseModel';

export type CategoryCoveragePayor = { coverage_payor_id: string, category: string };
export type PriceCoveragePayor = { coverage_payor_id: string, price: number };

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'discount_charge',
  hidden: boolean,
  name: string,
  method: 'discount' | 'charge',
  calculation_type: 'fixed-percent',
  amount: number,
  always_applied: boolean
};

/**
   * DiscountChargeModel
   *
   * @namespace DiscountChargeModel
   */
class DiscountChargeModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'discount_charge';
  }
}

export default DiscountChargeModel;
