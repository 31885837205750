import React from 'react';
import { List } from 'immutable';
import moment from 'moment';

import translate from './../../utils/i18n';
import Input from './../inputs/input';
import Select from './../inputs/select';
import DateInput from './../inputs/dateInput';
import { UNICODE } from './../../constants';
import Button from './../buttons/button';
import { DATE_FORMAT_TO_SAVE } from './../../constants';

import type DrugModel from './../../models/drugModel';
import type { MapValue, SupplyItemAttributes } from './../../types';
import { getDateFormat } from '../../utils/time';

type Props = {
  supplyItem: SupplyItemAttributes,
  skuID?: string,
  drugs: List<DrugModel>,
  onChange: (value: { [key: string]: MapValue }) => void,
  onDeleteClicked: () => void,
  isFirstChild: boolean,
  showDeleteButton: boolean,
  isBatchUpdate?: boolean,
  isSupplyItemInputNotAccepted?: boolean
}

/**
 * A form component for a Supply item.
 * @param {Props} props The props for the component.
 * @returns {React.Component}
 */
const AddToInventoryFormItem = (props: Props) => {
  const {
    drugs, onChange, onDeleteClicked, isFirstChild, showDeleteButton, skuID, supplyItem
  } = props;
  const selectedDrug = drugs.find(d => d.get('_id') === props.supplyItem.sku_id);
  return (
    <div className="o-form o-form--horizontal" style={{ margin: 0 }}>
      <Select
        options={
          drugs
            .filter(model => model.isVisible())
            .map(i => ({ value: i.get('_id'), label: i.get('name') }))
            .toArray()
        }
        id="drug"
        label={translate('SKU')}
        value={skuID || props.supplyItem.sku_id}
        onValueChanged={value => onChange({ sku_id: value })}
        style={{ flexGrow: 2, width: 0 }}
        hideLabel={!isFirstChild}
        required
        disabled={(props.isBatchUpdate) || !!skuID}
      />
      <Input
        id="supply_item_batch_id"
        label={translate('batch_#')}
        hideLabel={!isFirstChild}
        onValueChanged={value => onChange({ batch_id: value })}
        value={props.supplyItem.batch_id}
        style={{ flexGrow: 2, width: 0 }}
        disabled={(!isFirstChild && props.isBatchUpdate)}

      />
      <DateInput
        id="expiry_date"
        label={translate('expiry_date')}
        hideLabel={!isFirstChild}
        onChange={value => onChange({ expiry_date: value })}
        value={
          supplyItem.expiry_date && moment(supplyItem.expiry_date).isValid() ?
            moment(supplyItem.expiry_date) : supplyItem.expiry_date
        }
        style={{ flexGrow: 1, width: 0 }}
        required
        disabled={(!isFirstChild && props.isBatchUpdate)}
        showInvalid={props.isSupplyItemInputNotAccepted}
      />
      <Input
        id="supply_item_dispensation_unit"
        label={translate('dispensation_unit')}
        hideLabel={!isFirstChild}
        value={selectedDrug ? selectedDrug.getDispensationUnit() : UNICODE.EMDASH}
        style={{ flexGrow: 2, width: 0 }}
        disabled
      />
      <Input
        id="supply_item_quantity"
        type="number"
        label={translate('quantity')}
        hideLabel={!isFirstChild}
        onValueChanged={value => onChange({ quantity: value })}
        value={props.supplyItem.quantity}
        min={1}
        style={{ flexGrow: 1, width: 0 }}
        required
        disabled={props.isBatchUpdate}
      />
      <Input
        id="supply_item_total_price"
        type="number"
        label={translate('total_price')}
        hideLabel={!isFirstChild}
        onValueChanged={value => onChange({ total_price: value })}
        value={props.supplyItem.total_price}
        style={{ flexGrow: 1, width: 0 }}
        disabled={(!isFirstChild && props.isBatchUpdate)}
      />
      <Input
        id="supply_item_unit_cost"
        label={translate('unit_cost')}
        hideLabel={!isFirstChild}
        onValueChanged={value => onChange({ unit_cost: value })}
        value={props.supplyItem.unit_cost}
        style={{ flexGrow: 1, width: 0 }}
        disabled
      />
      <Input
        id="supply_item_notes"
        label={translate('notes')}
        hideLabel={!isFirstChild}
        onValueChanged={value => onChange({ notes: value })}
        value={props.supplyItem.notes}
        style={{ flexGrow: 2, width: 0 }}
        disabled={(!isFirstChild && props.isBatchUpdate)}
      />
      {
        showDeleteButton &&
        <Button
          type="button"
          className="o-text-button"
          onClick={() => onDeleteClicked()}
          style={{
            fontSize: '25pt',
            padding: 0,
            height: !isFirstChild ? '46px' : '79px',
          }}
          dataPublic
        >
        &times;
        </Button>
      }
    </div>
  );
};

export default AddToInventoryFormItem;
