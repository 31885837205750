import React from 'react';
import moment from 'moment';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';

import { UNICODE } from '../../constants';
import translate from './../../utils/i18n';
import DatePicker from './../inputs/statefulDatepicker';
import Input from './../inputs/input';
import TimePicker from './../inputs/timepicker';
import SaveButton from './../buttons/saveButton';
import { isEmailValid } from './../../utils/utils';
import { getDefaultDoctorAndLocation, isOnDuty } from './../../utils/encounters';
import ModalFooter from './../modals/modalFooter';
import StatelessModal from './../modals/statelessModal';
import Select from './../inputs/select';
import Radio from './../inputs/radio';

import PatientModel from '../../models/patientModel';
import AppointmentModel from '../../models/appointmentModel';
import PractitionerModel from '../../models/practitionerModel';
import SalesItemModel from '../../models/salesItemModel';
import type EncounterModel from '../../models/encounterModel';

import { AppointmentArgs, Config } from '../../types';
import EncounterFlowModel from '../../models/encounterFlowModel';
import Confirm from '../prompts/confirm';
import { isToday } from '../../utils/time';
import { getQueueConfirmModalMessage } from '../patientList/patientSearchResultList';

type Props = {
  config: Config,
  patient: PatientModel,
  salesItems: List<SalesItemModel>,
  onSave: (args: AppointmentArgs) => Promise<void>,
  practitioners: List<PractitionerModel>,
  buttonClass?: string,
  buttonLabel: string,
  showBusyIndicator?: boolean,
  disabled?: boolean,
  appointment: AppointmentModel,
  createOrUpdateFooterLabel?: string,
  rejectFooterLabel?: string,
  confirmFooterLabel?: string,
  emailDescription?: string,
  title?: string,
  onClose?: () => void,
  modalVisible?: boolean,
  updateModelsInState: (models: any) => void,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  encounters: List<EncounterModel>,
  appointments: List<AppointmentModel>,
}

type State = AppointmentArgs & {
  isSaving: boolean,
  errorMessage?: string,
  modalVisible: boolean,
  flowID?: string,
  showConfirmModal: boolean,
};

const UNASSIGNED_STATE = 'UNASSIGNED_STATE';

/**
 * Used to reduce time complexity of iterating patient details content in modal
 * @param {PatientModel} patient List of drugs from drugsModel
 * @return {React.ElementType} Map of drug with _id,
 */
export const getPatientDetailsSection = memoizeOne((patient: PatientModel): JSX.Element => (
  <div className="c-item-list__item__info">
    <div className="c-patient-card__item u-margin-bottom--1ws">
      <span className="c-patient-card__item__key">{translate('patient_name')}:</span>
      <span className="c-patient-card__item__value">{patient.get('patient_name')}</span>
    </div>
    <div className="c-patient-card__item u-margin-bottom--1ws">
      <span className="c-patient-card__item__key">{translate('ic_number')}:</span>
      <span className="c-patient-card__item__value">{patient.get('ic') || UNICODE.EMDASH}</span>
    </div>
    <div className="c-patient-card__item">
      <span className="c-patient-card__item__key">{translate('telephone_number')}:</span>
      <span className="c-patient-card__item__value">{patient.get('tel') || UNICODE.EMDASH}</span>
    </div>
  </div>
));


/**
 * Form for approving/rejecting appointment
 * @namespace AppointmentFormModal
 * @returns {React.Component}
 */
class AppointmentFormModal extends React.Component<Props, State> {
  /**
   * Creates an instance of AppointmentForm.
   * @param {Props} props props
   * @return {void}
   */
  constructor(props: Props) {
    super(props);
    const state: AppointmentArgs = this.propsToState(props);
    this.state = {
      isSaving: false,
      errorMessage: '',
      modalVisible: props.modalVisible || false,
      showConfirmModal: false,
      ...state,
    };
  }

  /**
   * Returns the original state using props passed to the component.
   * @param {Props} props props.
   * @returns {CustomState}  The original/initial state for some of the values
   */
  propsToState(props: Props): Partial<State> {
    const { appointment, patient, salesItems, config, practitioners } = props;
    const timestamp = appointment?.get('start_timestamp') || moment().minute(Math.ceil((moment().minute()) / 15) * 15).add('15', 'm').valueOf();
    const requestData = appointment && appointment.isPending() && appointment.getRequestData();
    const encounterData = {
      flowID: (appointment && appointment.get('flow_id')),
      practitioner: (appointment && appointment.get('practitioner_id')) ||
        getDefaultDoctorAndLocation(config, practitioners).doctor || UNASSIGNED_STATE,
    };
    if (requestData) {
      return {
        date: moment(requestData.start_timestamp) || moment(timestamp),
        time: moment(requestData.start_timestamp) || moment(timestamp),
        email: requestData.email || UNICODE.EMDASH,
        consultMode: (requestData.consult_mode) || appointment?.get('consult_mode'),
        ...encounterData,
      };
    }
    return {
      date: moment(timestamp),
      time: moment(timestamp),
      email: patient.get('email', '', false, false),
      consultMode: (appointment && appointment.get('consult_mode')) || 'tele_consult',
      ...encounterData,
    };
  }

  /**
   * Check if E-consult form is valid or not.
   * @returns {boolean} true if valid else false
   */
  isFormValid() {
    if (!this.state.date) {
      this.setState({
        errorMessage: translate('please_select_valid_date'),
      });
      return false;
    }
    if (!this.state.time) {
      this.setState({
        errorMessage: translate('fill_time_and_in_correct_format'),
      });
      return false;
    }
    if (!isEmailValid(this.state.email)) {
      this.setState({
        errorMessage: translate('fill_email_in_correct_format'),
      });
      return false;
    }
    if (!this.state.flowID) {
      this.setState({
        errorMessage: translate('please_select_encounter_flow'),
      });
      return false;
    }
    if (!this.state.practitioner) {
      this.setState({
        errorMessage: translate('please_select_a_doctor'),
      });
      return false;
    }
    return true;
  }

  /**
  * reset the state
  * @return {void}
  */
  resetState() {
    const state = this.propsToState(this.props);
    this.setState({
      errorMessage: '',
      modalVisible: false,
      isSaving: false,
      ...state,
    });
  }

  /**
   * Saves the appointment model
   * @param {string} status appointment status
   * @returns {Promise}
   */
  onProceedClick = (status: string) => {
    const {
      date,
      time,
      email,
      consultMode,
      flowID,
      practitioner,
    } = this.state;
    const args = Object.assign(
      {},
      {
        date,
        time,
        email,
        consultMode,
        flow_id: flowID,
        practitioner: practitioner === UNASSIGNED_STATE ? undefined : practitioner,
        status,
      },
    );
    return this.props.onSave(args);
  }

  /**
  * Call on save of E consult form submit
  * @param {string | undefined} status of appointment
  * @return {Promise<boolean>}
  */
  onSaveClicked(status: string): Promise<boolean | void> {
    if (status === 'rejected') {
      this.setState({ isSaving: true });
      return this.props.onSave({ status });
    }
    if (this.isFormValid()) {
      if (status === 'booked') {
        const currentEncountersForPatient = this.props.encounters.filter(m => m.get('patient_id') === this.props.patient.get('_id') && m.isCurrent());
        const currentAppointmentsForPatient = this.props.appointments
          .filter(a => a.get('status') !== 'cancelled' && a.get('patient_id') === this.props.patient?.get('_id') &&
          a.isActive() && a.isToday() && !a.isPending());
        const time = moment(this.state.date)
          .hour(this.state.time.hour())
          .minute(this.state.time.minute())
          .second(0)
          .valueOf();
        if ((currentEncountersForPatient.size > 0 || currentAppointmentsForPatient.size > 0)
          && isToday(time)) {
          this.setState({
            showConfirmModal: true,
          });
          return Promise.resolve(false);
        }
      }
      this.setState({ isSaving: true });
      return this.onProceedClick(status);
    }
    return Promise.resolve(false);
  }

  /**
   * set email to state.
   * @param { string } email email address
   * @returns {void}
   */
  setemail(email: string) {
    this.setState({ email });
  }

  /**
   * Triggers a batch data sync status check when the inventory count changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.patient.get('email') !== prevProps.patient.get('email')) {
      this.setemail(this.props.patient.get('email'));
    }
  }

  /**
   * Renders appointment schedule sections.
   * @returns {React.Component} The modal content to render.
   */
  renderScheduleSection() {
    return (
      <React.Fragment>
        <DatePicker
          id="date"
          value={this.state.date}
          onValueChanged={(date) => { this.setState({ date }); }}
          allowPast={false}
          label={translate('date')}
          required
          showClearDate
        />
        <TimePicker
          id="time"
          value={this.state.time}
          onValueChanged={(time) => {
            this.setState({ time });
          }}
          label={translate('time')}
          required
          style={{ width: '150px', marginTop: '1rem' }}
          minuteStep={15}
          allowEmpty={false}
        />
        <Input
          id="email_address"
          value={this.state.email}
          label={translate('patient_email_address')}
          description={this.props.emailDescription || ''}
          placeholder={translate('email_address')}
          onValueChanged={(email: string) => this.setemail(email)}
          required
        />
      </React.Fragment>
    );
  }

  /**
   * Renders appointment encounter section.
   * @returns {React.Component} The modal content to render.
   */
  renderDetailsSection() {
    const { appointment } = this.props;
    const isRequest = appointment && appointment.isPending();
    const practitioners = this.props.practitioners.filter(p => isOnDuty(this.props.config, p.get('_id')));
    const doctors = practitioners.size ? practitioners : this.props.practitioners;
    return (
      <React.Fragment>
        {isRequest &&
          <React.Fragment>
            <p style={{ whiteSpace: 'pre' }}>
              Please choose the &apos;Flow&apos; as well
              as the &apos;Doctor&apos; for the E-Consult:
            </p>
            <br />
          </React.Fragment>
        }
        {!isRequest &&
          <Radio
            id="consult_mode"
            label={translate('consult_mode')}
            options={[{ label: translate('e_consult'), value: 'tele_consult' }, { label: translate('walk_in'), value: 'walk_in' }]}
            value={this.state.consultMode}
            onValueChanged={consultMode => this.setState({ consultMode })}
          />
        }
        <Select
          id="flow-select"
          label={translate('flow')}
          options={this.props.encounterFlowMap?.toList().map(f => ({
            label: f.get('name'),
            value: f.get('_id'),
          })).toArray() || []}
          value={this.state.flowID}
          onValueChanged={value => this.setState({ flowID: value })}
          required
        />
        <Select
          id="doctor"
          label={translate('doctor')}
          onValueChanged={practitioner => this.setState({
            practitioner,
          })}
          options={
            doctors
              .map(p => ({ value: p.get('_id'), label: p.get('name') })).push(
                { value: UNASSIGNED_STATE, label: translate('unassigned') },
              ).toArray()
          }
          value={this.state.practitioner}
          required
        />
      </React.Fragment>
    );
  }

  /**
   * Renders the component.
   *
   * @return {string} - HTML markup for the component
   */
  render() {
    const { appointment, patient } = this.props;
    const { consultMode } = this.state;
    const isRequest = appointment && appointment.isPending();
    const title = isRequest ?
      translate(`${consultMode}_request_by_x`, { x: patient.get('patient_name') })
      : translate('schedule_appointment');
    const patientSection = getPatientDetailsSection(patient);
    return (
      <StatelessModal
        id={`pending-${this.props.appointment.get('_id')}`}
        buttonLabel={this.props.buttonLabel}
        buttonClass={this.props.buttonClass || 'o-button o-button--small u-margin-right--1ws'}
        title={title}
        visible={this.state.modalVisible}
        setVisible={(isVisible) => {
          // initially setVisible will not be called when props modalVisible is present
          if (isVisible && appointment?.unread) {
            this.props.updateModelsInState([appointment.setUnreadCheck(false)]);
          }
          this.setState({ modalVisible: isVisible });
        }}
        onClose={() => {
          this.resetState();
          if (this.props.onClose) {
            this.props.onClose();
          }
        }}
        style={{ maxWidth: '720px' }}
        disableButton={this.props.disabled}
        showBusyIndicator={this.props.showBusyIndicator}
        explicitCloseOnly
        dataPublicHeader
      >
        <section className="o-form">
          {this.state.errorMessage && <div className="o-form__text-block o-form__text-block--error">{this.state.errorMessage}</div>}
          {patientSection}
          <hr />
          {isRequest ?
            <React.Fragment>
              {this.renderScheduleSection()}
              <hr />
              {this.renderDetailsSection()}
            </React.Fragment>
            :
            <React.Fragment>
              {this.renderDetailsSection()}
              <hr />
              {this.renderScheduleSection()}
            </React.Fragment>
          }
        </section>
        <ModalFooter>
          {!isRequest &&
            <SaveButton
              onClick={() => {}}
              label={this.props.createOrUpdateFooterLabel || translate('save')}
              className="o-button--small u-margin-right--half-ws"
              dataPublic
              isSaving={this.state.isSaving}
              savingLabel={this.props.createOrUpdateFooterLabel || translate('save')}
            />
          }
          {isRequest &&
            <SaveButton
              onClick={() => {
                this.onSaveClicked('rejected')
                  .then((withoutError: boolean) => withoutError && this.resetState());
              }}
              label={translate(`reject_${consultMode}_request`)}
              className="o-button--small o-button--danger u-margin-right--half-ws"
              dataPublic
              isSaving={this.state.isSaving}
              savingLabel={translate(`reject_${consultMode}_request`)}
            />
          }
          {isRequest &&
            <SaveButton
              onClick={() => {
                this.onSaveClicked('booked')
                  .then((withoutError: boolean) => withoutError && this.resetState());
              }}
              label={translate(`confirm_${consultMode}_request`)}
              className="o-button--small u-margin-right--half-ws"
              dataPublic
              isSaving={this.state.isSaving}
              savingLabel={translate(`confirm_${consultMode}_request`)}
            />
          }
          <Confirm
            show={this.state.showConfirmModal}
            cancel={() => {
              this.setState({
                showConfirmModal: false,
              });
            }}
            proceed={() => {
              this.setState({
                showConfirmModal: false,
              });
            }}
            confirmation={getQueueConfirmModalMessage(patient.get('_id'), () => this.setState({ showConfirmModal: false }), true)}
            modalTitle={translate('patient_cannot_be_scheduled')}
            footerSaveButtonName={translate('acknowledge')}
            hideCancel
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default AppointmentFormModal;
