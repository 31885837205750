import BaseModel from './../baseModel';

type attributesType = {
  _id: string,
  type: 'pharmaconnect_detailing_response',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,

  size: string,
  brand_id: string,
  blob_url: string,
}

/**
   * BrandResponseModel
   *
   * @namespace BrandResponseModel
   */
class DetailingResponseModel extends BaseModel {
  attributes: attributesType;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: { id: string, size: string, blob_url: string, brand_id: string, }) {
    super(attributes);
    this.attributes._id = attributes.id;
    this.attributes.type = 'pharmaconnect_detailing_response';
    this.attributes.size = attributes.size;
    this.attributes.brand_id = attributes.brand_id;
    this.attributes.blob_url = attributes.blob_url;
  }
}

export default DetailingResponseModel;
