
const phoneUpperBoundary = 600;
const tabletPortraitUpperBoundary = 900;
const tabletLandscapeUpperBoundary = 1240;
const laptopUpperBoundary = 1440;
const desktopUpperBoundary = 1800;

export const mediaQueries = {
  forPhoneOnly: `@media (max-width: ${phoneUpperBoundary - 1}px)`,
  forTabletPortraitUp: `@media (min-width: ${phoneUpperBoundary}px)`,
  forTabletLandscapeUp: `@media (min-width: ${tabletPortraitUpperBoundary}px)`,
  forDesktopDown: `@media (max-width: ${tabletLandscapeUpperBoundary}px)`,
  forDesktopUp: `@media (min-width: ${tabletLandscapeUpperBoundary}px)`,
  forLaptopDown: `@media (max-width: ${laptopUpperBoundary}px)`,
  forLaptopUp: `@media (min-width: ${laptopUpperBoundary}px)`,
  forBigDesktopUp: `@media (min-width: ${desktopUpperBoundary}px)`,
};

export const colours = {
  black: '#000',
  blue: '#6da9d1',
  transparentBlack: 'rgba(0, 0, 0, 0.9)',
  transparentMidnightGrey: 'rgba(47, 53, 64, 0.8)',
  green: '#4dd2b4',
  greenHover: '#40ae95',
  grey1: '#f5f5f5',
  grey2: 'rgba(0, 0, 0, 0.05)',
  grey3: '#e0dfdf',
  grey4: '#f0f0f0',
  grey5: '#9b9b9b',
  grey6: '#747474',
  grey7: '#a5a5a5',
  grey8: '#3F3F3F',
  maroonDark: 'rgb(159, 33, 75)',
  maroon: 'rgb(205, 56, 106)',
  midnightGrey: '#2f3540',
  midnightGreyHover: '#1e222a',
  red: '#ff4f02',
  redHover: '#ff4f0275',
  white: '#fff',
  blueBright: '#4a90e2',
  yellow: 'rgb(242 188 110)',
  lightDark: '#4e4848',
  tableHighlight: '#e8f6ff',
};

export const chartColours = [
  '#d81b60',
  '#8e24aa',
  '#3949ab',
  '#1e88e5',
  '#00acc1',
  '#00897b',
  '#43a047',
  '#c0ca33',
  '#fb8c00',
  '#6d4c41',
  '#616161',
];

export const fonts = {
  light: 'robotolight',
  medium: 'robotomedium',
  bold: 'robotobold',
  icons: 'FontAwesome',
};

export const fontSize = {
  small: '0.75rem',
  label: '0.75rem',
  large: '0.9rem',
};

export const wsUnit = '1.333rem';

export const casenoteMenuHeight = '50px';
export const sidebarWidth = '200px';

export const border = `1px solid ${colours.grey3}`;
export const tableBorder = `1px solid ${colours.grey2}`;
export const borderRadius = '5px';
export const topBarHeight = '50px';

export const scaledH1 = '2.369rem';
export const scaledH2 = '1.777rem';
export const scaledH3 = '1.333rem';
export const labelFontSize = '0.75rem';

export const margins = {
  standardMarginBottom: wsUnit,
  standardMarginSides: `calc(1.5 * ${wsUnit})`,
  standardMargins: `${wsUnit} calc(1.5 * ${wsUnit}) ${wsUnit}`,
};

export const boxShadows = {
  card: '0 2px 5px 1px rgba(223, 223, 223, 1)',
};
