import * as React from 'react';
import glamorous from 'glamorous';
import CloseButton from './../buttons/closeButton';
import Input from './../inputs/input';
import translate from './../../utils/i18n';

import type { PolicyAttributes } from './../../models/coveragePayorPolicyModel';

type Props = {
    itemCount: number,
    policy: PolicyAttributes,
    storePolicy: (model: PolicyAttributes) => void
  };

type State = {
    policyId: string,
    policyName: string,
    policyCoPayment: number | null,
    policyMaximumClaimableAmount: number | null,
    active: boolean,
};

const defaultState: State = {
  policyId: '',
  policyName: '',
  policyCoPayment: null,
  policyMaximumClaimableAmount: null,
  active: true,
};

const Header = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.333rem',
});

/**
 * Returns State object based on the updated model
 * @param {PolicyAttributes} policy Model to transform
 * @returns {object}
*/
const modelToState = (policy: PolicyAttributes) => ({
  policyId: policy._id,
  policyName: policy.policy_name,
  policyCoPayment: policy.policy_co_payment_amount,
  policyMaximumClaimableAmount: policy.policy_maximum_claimable_amount,
  active: policy.active,
});


/**
 * A form for creating and editing Coverage Payor Policy models.
 * @class CoveragePayorPolicyForm
 * @extends {React.Component<Props, State>}
 */
class CoveragePayorPolicyForm extends React.Component<Props, State> {
  /**
   * Creates an instance of CoveragePayorPolicyForm.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    if (this.props.policy) {
      this.state = {
        ...defaultState,
        ...modelToState(this.props.policy),
      };
    } else {
      this.state = defaultState;
    }
  }

  /**
   * Invokes the storePolicy function in CoveragePayorForm
   * @returns {void}
   */
  handleChange() {
    this.props.storePolicy({
      _id: this.state.policyId,
      policy_name: this.state.policyName,
      policy_co_payment_amount: this.state.policyCoPayment,
      policy_maximum_claimable_amount: this.state.policyMaximumClaimableAmount,
      active: this.state.active,
    });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div>
        <hr />
        <Header>
          <h2 className="o-label u-margin-bottom--1ws" style={{ fontSize: '1rem' }}>Policy {this.props.itemCount === 1 ? `${this.props.itemCount} (Default)` : this.props.itemCount}</h2>
          {
            this.props.itemCount !== 1 &&
            <CloseButton
              onClick={() => {
                this.setState({ active: false }, () => {
                  this.handleChange();
                });
              }}
              dataPublic
            />
          }
        </Header>
        <Input
          id="policy_name"
          label={translate('name')}
          value={this.state.policyName}
          onValueChanged={(value) => {
            this.setState({ policyName: value }, () => {
              this.handleChange();
            });
          }}
          required
        />
        <Input
          id="policy_co_payment"
          label={translate('co_payment_amount')}
          value={this.state.policyCoPayment === null ? '' : this.state.policyCoPayment}
          type="number"
          min={0}
          onValueChanged={(value) => {
            this.setState(
              {
                policyCoPayment:
                  value && !isNaN(value) ? Math.abs(Number(value)) : null,
              },
              () => {
                this.handleChange();
              },
            );
          }}
        />
        <Input
          id="policy_maximum_claimable_amount"
          label={translate('maximum_claimable_amount')}
          value={this.state.policyMaximumClaimableAmount === null ? '' : this.state.policyMaximumClaimableAmount}
          type="number"
          min={0}
          onValueChanged={(value) => {
            this.setState(
              {
                policyMaximumClaimableAmount:
                  value && !isNaN(value) ? Math.abs(Number(value)) : null,
              },
              () => {
                this.handleChange();
              },
            );
          }}
        />
      </div>
    );
  }
}

export default CoveragePayorPolicyForm;
