import * as React from 'react';

import CasenoteEditorContainer from './../../containers/casenoteEditorContainer';
import Layout from './../layout';

import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';

type Props = {
  children: React.Node,
  casenoteID: string,
  patient: PatientModel,
  patientStub: PatientStubModel,
};

/**
 * A component that serves as a router wrapper to display a casenote editor alongside a "referral"
 * document.
 * @class CasenoteEditorWithReferral
 * @extends {React.Component<Props>}
 */
class CasenoteEditorWithReferral extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    if (!this.props.patient && !this.props.patientStub) {
      throw Error('No patient passed to component.');
    }
    const patientID = this.props.patient ?
      this.props.patient.get('_id') : this.props.patientStub.get('_id');
    return (
      <div style={{ width: '100%' }}>
        <div className="c-referral-container">
          <Layout ignoreReferralMode>
            {this.props.children}
          </Layout>
        </div>
        <div className="c-referral-container">
          <CasenoteEditorContainer
            isReferralMode
            patientID={patientID}
            casenoteID={this.props.casenoteID}
          />
        </div>
      </div>
    );
  }
}

export default CasenoteEditorWithReferral;
