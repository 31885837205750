import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import translate from './../../utils/i18n';
import AnnouncementModal from '../modals/announcementModal';

type Props = {
  program: string,
};

/**
 * Render the banner
 * @param {string} outbreak text to add
 * @returns {HTMLElement} html element
 */
const OutbreakModal = ({ program }: Props) => {
  const [redirect, setRedirect] = useState<boolean | void>();
  /**
   * Handle click
   * @returns {void} void sets seen key & closes modal
   */
  const handleClick = () => {
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to="/outbreak/guidelines" />;
  }
  return (
    <AnnouncementModal
      program={program}
      onExplicitClose={handleClick}
      footerButtonLabel={translate('outbreak_modal_button_label')}
      modalHeading={translate('outbreak_modal_title')}
      id="outbreak-modal"
      image={<img src="/static/outbreak/modal.jpg" alt="outbreak" />}
    >
      <p>{translate('outbreak_modal_text')}</p>
    </AnnouncementModal>
  );
};

export default OutbreakModal;
