import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'claim_reconciliation',
  timestamp: number,
  amount: number,
  method: 'transfer' | 'cash' | 'cheque' | 'card',
  notes?: string,
  is_void: boolean,
  cheque_number?: string,
  bank?: string,
  coverage_payor_id: string,
};

/**
   * ClaimReconciliationModel, a document representing details about overall panel payment, done at a time
   * @namespace ClaimReconciliationModel
   */
class ClaimReconciliationModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'claim_reconciliation';
  }

  /**
   * Returns true if claim invoice payment has been voided.
   * @returns {boolean}
   */
  isVoid(): boolean {
    return this.get('is_void', false);
  }
}

export default ClaimReconciliationModel;
