
import BaseModel from './baseModel';

export type Attributes = {
  _id: string,
  type: 'bank',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  _deleted?: boolean,
  name: string,
};

/**
   * BankModel
   *
   * @namespace BankModel
   */
class BankModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'bank';
  }

  /**
   * Returns true if item does not have a hidden flag.
   * @returns {boolean} True if item is not hidden.
   */
  isVisible(): boolean {
    return !this.get('hidden', false);
  }

  /**
   * sets model as deleted if true
   * @param {boolean} isDeleted is model deleted.
   * @returns {BaseModel} The altered BankModel.
   */
  setDeleted(isDeleted: boolean): BankModel {
    return this.set('hidden', isDeleted);
  }
}

export default BankModel;
