import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { List, Map } from 'immutable';
import NotificationSystem from 'react-notification-system';
import semver from 'semver';
import { AppContainer } from 'react-hot-loader';
import Idle from 'react-idle';
import { mount, configure } from 'enzyme';
import Adapter from 'enzyme-adapter-react-16';

import TimedOutModal from './components/timedOutModal';
import ApplicationContainer from './containers/applicationContainer';
import MaintenanceLandingComp from './components/maintenanceLandingPage';
import pjson from './../package.json';
import translate from './utils/i18n';
import { setUserId } from './utils/analytics';
import {
  addModels, setAuthStatus, setUserID, setAppStartupComplete,
  updateConfig, updateConfigValue, setPatientStubs, setIsSyncing,
  setLastReceivedDBSequence, setUserModel, setUserGroupModel,
  updateModels, setUserAdminStatus, decryptModelState, setEncrypted,
  deleteUnsyncedAPICalls, updatePatientsSearch, updateKlinifyConfig,
  setConfigFetched,
} from './actions';
import {
  instantiateRemoteDBs, instantiateLocalDBs, getRemoteDB, fetchSharedData, getLocalDB,
  getClinicConfig as getClinicConfigFromDB, startRemoteChangesFeed, updateClinicConfig, saveModels,
} from './utils/db';
import { setEncryptionKeyAndSalt } from './utils/crypto';
import { syncBatchInventoryCountAll, fetchVerifiedDrugs, fetchCompletedSuggestions, getInventoryCount } from './utils/inventory';
import { saveFactory, createReduxStore, getStore, saveModelsAPIFactory } from './utils/redux';
import { seriesExecutor } from './utils/lang';
import {
  setUserForLogging, logAppInitialisation, logMessage, logToAppInsight,
  startLogRocketLogging, setUserForLogRocketLogging, isProduction, debugPrint,
  initializeAppInsights,
} from './utils/logging';
import setOnlineStatus from './utils/offline';
import { getUserLoggedIn, logout, getEncryptKey, getEncryptSalt } from './utils/auth';
import { fetchPatientsToBeCached, fetchPatientStubs, getUserConfigByUserId } from './utils/api';
import { setNotificationComponent, createStaticNotification, createStaticErrorNotification } from './utils/notifications';
import { isAdminUser } from './utils/userManagement';
import { mapFromJS, getBaseApiUrl, validateAndSetDeviceWindowId, handleResponseError } from './utils/utils';
import { getUserName } from './utils/user';
import { deleteLocalUnsyncedModels, getCurrentUnsyncedModels } from './utils/sync';
import UserConfigModel from './models/userConfigModel';
import { DEFAULT_CLINIC_CONFIG_FILE_ID, DEFAULT_KLINIFY_CONFIG_FILE_ID, MEDADVISOR_CAMPAIGNS_ENABLED } from './constants';

import type { Config, DBInfo } from './types';
import { handleUnauthorisedApiResponse } from './utils/response';
import { syncCampaigns } from './utils/patientCampaign';
import { hasPermission, createPermission } from './utils/permissions';

require('../stylesheets/postcss/styles.css');

const TIME_TO_SHOW_RELEASE_NOTES_MESSAGE = 86400000; // i.e. 1 day
const TIME_TO_APP_TIME_OUT = 21600000; // 6hrs
const LOGROCKET_ENABLED = true;
let appTimedOut = false;
let syncQueueTimerId: number | void;

instantiateRemoteDBs();
createReduxStore();
const store = getStore();

// Show landing page during klinify downtime
const onMaintenance = false;

/**
 * updates config doc in store.
 * @param {Config} config the config doc
 * @return {void}
 */
function updateConfigInStore(config: Config) {
  store.dispatch(updateConfig(config));
}

/**
 * Checks the last version of the app this user used and display a message to view release notes if
 * necessary.
 * @return {void}
 */
function checkLastVersionUsed() {
  const statsMap = store.getState().config.getIn([
    'usage_statistics',
    'latest_version_used_by_user',
    store.getState().user.get('id'),
  ], Map());
  const previousSemVer = semver.valid(statsMap.get('version')) ? statsMap.get('version') : '0.0.0'; // Default to 0.0.0 if version is invalid as we probably want to show the notification anyway.
  const semVerDifference = semver.diff(previousSemVer, pjson.version);
  if (semVerDifference === 'major' || semVerDifference === 'minor') {
    createStaticNotification(translate('klinify_updated'), translate('klinify_updated_desc'), true);
    const userId = store.getState().user.get('id') || 'unknown_user'; // Quashes a type error.
    if (typeof userId !== 'string') {
      throw new Error('User ID is not a string.');
    }
    store.dispatch(updateConfigValue(
      [
        'usage_statistics',
        'latest_version_used_by_user',
        userId,
      ],
      Map({ version: pjson.version, time_first_used: new Date().valueOf() }),
    ));
    updateClinicConfig(store.getState().config.toJS(), {}, updateConfigInStore);
  } else if (statsMap.get('time_first_used') + TIME_TO_SHOW_RELEASE_NOTES_MESSAGE > new Date().valueOf()) {
    createStaticNotification(translate('klinify_updated'), translate('klinify_updated_desc'), true);
  }
}

/**
 * checks if user is admin and sets the status in store.
 * @return {Promise<boolean>} true if api success and admin status has been set else false
 */
const setCurrentUserAdminStatus = () => {
  if (store.getState().user.get('id')) {
    // get admin status of user
    return isAdminUser().then(([success, result]) => {
      if (success) {
        store.dispatch(setUserAdminStatus(result));
        return true;
      }
    });
  }
};

/**
 * Called when both initialSyncComplete and initialFetchComplete are true.
 * @return {void}
 */
const startApp = async () => {
  await setCurrentUserAdminStatus();
  store.dispatch(setAppStartupComplete());
  checkLastVersionUsed();
  if (MEDADVISOR_CAMPAIGNS_ENABLED) {
    const hasCampaignReadPermission = hasPermission(store.getState().user, List([createPermission('sms_campaigns', 'read')]));
    if (hasCampaignReadPermission) {
      syncCampaigns(store, false);
    }
  }
};

/**
 * Called after the startup to fetch mdl mappings and suggestions.
 * @return {void}
 */
const getMappingsAndSuggestions = () => {
  if (isProduction()) {
    fetchVerifiedDrugs(store.dispatch);
    fetchCompletedSuggestions(store.dispatch);
  }
};

/**
   * Gets all models required app wide for starting the app and puts them
   * into state.
   * @return {undefined}
   */
function getSharedData() {
  // eslint-disable-next-line no-return-await
  return Promise
    .all([
      fetchSharedData(),
      fetchPatientsToBeCached(),
      fetchPatientStubs(), // NOTE: If you add anything to this, update the next few lines as well!
    ])
    .then((modelsArray) => {
      const uniqueModels = List(modelsArray[0].concat(modelsArray[1]))
        .groupBy(m => m.get('_id'))
        .map(models => models.first())
        .toList();
      store.dispatch(addModels(uniqueModels));
      store.dispatch(setPatientStubs(List(modelsArray[2])));
      store.dispatch(updatePatientsSearch(modelsArray[2].map(i => i.attributes)));
    });
}

/**
 * If the app is online and not currently syncing, it will trigger the processing of the unsynced
 * models queue. Regardless of whether or not it is triggered, the function will run again after
 * an interval (currently 30s)
 * @return {void}
 */
function processUnsyncedDocumentsQueue() {
  const CHECK_INTERVAL = 30000; // Try to sync every 30s
  window.clearTimeout(syncQueueTimerId);
  const { isOnline, isSyncing, unsyncedAPICalls, user } = store.getState();
  const enabledUnsyncedAPICalls = unsyncedAPICalls.filter(call => call.enabled);
  return getCurrentUnsyncedModels(getLocalDB(), user.get('id')).then((unsyncedModels) => {
    if (isOnline && !isSyncing
      && ((unsyncedModels && unsyncedModels.size)
      || (enabledUnsyncedAPICalls && enabledUnsyncedAPICalls.size))) {
      store.dispatch(setIsSyncing(true));
      deleteLocalUnsyncedModels(getLocalDB(), unsyncedModels.map(m => m.get('_id')).toArray()).then(() => {
        saveModels(unsyncedModels, store.dispatch, getEncryptKey(), getUserName())
          .then((savedModels) => {
            store.dispatch(updateModels(savedModels));
          })
          .then(() => seriesExecutor(
            enabledUnsyncedAPICalls.entrySeq()
              .map(([requestId, apiCall]) => () => {
                if (apiCall.enabled) {
                  store.dispatch(deleteUnsyncedAPICalls(List([requestId])));
                  return saveModelsAPIFactory(store.dispatch)(apiCall.method, apiCall.opts);
                }
                return Promise.resolve();
              })
              .toArray(),
          ))
          .then(() => {
            store.dispatch(setIsSyncing(false));
            syncQueueTimerId = setTimeout(() => processUnsyncedDocumentsQueue(), CHECK_INTERVAL);
          });
      });
    } else {
      syncQueueTimerId = setTimeout(() => processUnsyncedDocumentsQueue(), CHECK_INTERVAL);
    }
  });
}

/**
 * Checks the client side connection on a 5 second cycle. Updates state if it changes.
 * @returns {undefined}
 */
const checkConnection = () => {
  const isOnline = navigator.onLine;
  if (store.getState().isOnline !== isOnline) {
    if (!isOnline) {
      setOnlineStatus(store, false);
    } else if (isOnline && !store.getState().serverOffline) {
      setOnlineStatus(store, true);
    }
  }

  setTimeout(() => checkConnection(), 5000);
};

/**
 * Automatically reload the app when a changes occurs
 * @param {React.Component} RootComponent Main ApplicationContainer
 * @returns {undefined}
*/
const renderApp = (RootComponent) => {
  const el = document.getElementById('app');
  if (!el) {
    throw new Error('App element not found');
  }

  const AppElem = (
    <AppContainer warnings={false}>
      <div>
        <Router>
          <Provider store={store}>
            <RootComponent />
          </Provider>
          <NotificationSystem ref={(component) => { setNotificationComponent(component); }} />
        </Router>
        <Idle
          timeout={TIME_TO_APP_TIME_OUT}
          onChange={({ idle }) => {
            if (idle) {
              appTimedOut = true;
            }
          }}
          render={() => (appTimedOut ? <TimedOutModal /> : null)}
        />
      </div>
    </AppContainer>
  );
  if (process.env.NODE_ENV === 'test_e2e' || window.isTest) {
    configure({ adapter: new Adapter() });
    const app = mount(AppElem, { attachTo: el });
    window.app = app;
  } else {
    ReactDOM.render(
      AppElem,
      el,
    );
  }
};

renderApp(onMaintenance ? MaintenanceLandingComp : ApplicationContainer);

if (module.hot) {
  module.hot.accept('./containers/applicationContainer',
    () => {
      const RootComponent = require('./containers/applicationContainer').default; // eslint-disable-line
      renderApp(RootComponent);
    });
}

initializeAppInsights();
// if we ban the mutation observer we also have to stop logrocket initilization.
if (LOGROCKET_ENABLED) {
  startLogRocketLogging(); // Starts Logrocket logging.
}
checkConnection(); // Start tracking online status.

/**
 * Handles the setting of the user's model and usergroup model.
 * @param {string} userID The user ID.
 * @param {number} retry What retry attempt this is
 * @returns {void}
 */
function handleUserMetadata(userID: string, retry = 0) {
  const storedModel = store.getState().userConfigs.find(u => u.get('user_id') === userID);
  const userModelPromise = storedModel ?
    Promise.resolve(storedModel) :
    getUserConfigByUserId(userID);
  return userModelPromise.then((userModel) => {
    if (userModel) {
      store.dispatch(setUserModel(userModel));
      if (userModel.has('user_group_id')) {
        const userGroupModel = store.getState().userGroups
          .find(g => g.get('_id') === userModel.get('user_group_id'));
        if (userGroupModel) {
          store.dispatch(setUserGroupModel(userGroupModel));
        }
      }
    } else {
      logMessage('No userConfig document found, creating one.');
      const userConfig = new UserConfigModel({ user_id: userID });
      saveFactory(store.dispatch)(userConfig)
        .then((model: UserConfigModel) => store.dispatch(setUserModel(model)))
        .catch(error => handleResponseError(error));
    }
  }).catch((error) => {
    if (error) {
      debugPrint(`handleUserMetadata: ${retry}`);
      if (retry >= 1) {
        logToAppInsight(`Unable to get User Config after ${retry + 1} retries`, error.status);
        createStaticErrorNotification(translate('app_start_failed'));
        logout(false);
      } else {
        handleUserMetadata(store, retry + 1);
      }
    }
  });
}

/**
 * Initialises the Klinify app.
 * @param {string} key The key to decrypt.
 * @param {string} salt The salt to decrypt.
 * @returns {Promise<void>} Promise for the completion of the operation.
 */
const init = (key: string, salt: string): Promise<void> => getRemoteDB()
  .getSession((e, response) => {
    if (e) {
      if (e === 'Error: ETIMEDOUT' || e.toString() === 'Error: ETIMEDOUT') {
        setTimeout(() => init(key, salt), 5000); // Wait 5 seconds and then try again.
      } else if (e && e.status === 401) {
        handleUnauthorisedApiResponse(401);
      } else {
        debugPrint(e, 'error');
        throw new Error('Error while getting session.');
      }
    } else {
      const { encryptionOptions } = store.getState();
      store.dispatch(setUserID(response.userCtx.name));
      setEncryptionKeyAndSalt(response.userCtx.name, key, salt);
      if (encryptionOptions.encrypted) {
        store.dispatch(decryptModelState(key, encryptionOptions.iv, response.userCtx.name));
        store.dispatch(setEncrypted(false));
      }
      if (LOGROCKET_ENABLED) {
        setUserForLogRocketLogging(response.userCtx.name);
      }
      setUserForLogging(response.userCtx.name);
      setUserId(response.userCtx.name);
      validateAndSetDeviceWindowId();
      logAppInitialisation();
      // getSharedData();
      // Load the apps config and shared data and then start the app.
      Promise.all([getClinicConfigFromDB(DEFAULT_CLINIC_CONFIG_FILE_ID),
        getClinicConfigFromDB(DEFAULT_KLINIFY_CONFIG_FILE_ID)])
        .then((configArray) => {
          store.dispatch(updateConfig(mapFromJS(configArray[0])));
          store.dispatch(updateKlinifyConfig(mapFromJS(configArray[1])));
          store.dispatch(setConfigFetched(true));
        })
        .then(instantiateLocalDBs)
        .then(getSharedData)
        .then(() => handleUserMetadata(response.userCtx.name))
        .then(() => syncBatchInventoryCountAll(store))
        .then(() => getInventoryCount(store, true))
        .then(() => getRemoteDB().info())
        .then((info: DBInfo) => {
          if (!(info) || (info && !info.update_seq)) {
            const message = !info ?
              `Object info is not present in remote DB, info: ${JSON.stringify(info)}` :
              `update_seq is not present the remote DB info object, info: ${JSON.stringify(info)}`;
            logMessage(message);
          } else {
            store.dispatch(setLastReceivedDBSequence(info.update_seq));
          }
          startRemoteChangesFeed(store);
        })
        .then(() => processUnsyncedDocumentsQueue())
        .then(startApp)
        .then(getMappingsAndSuggestions)
        .catch((err) => {
          if (err.status === 401) {
            return handleUnauthorisedApiResponse(err.status);
          }
          throw err;
        });
    }
  });

// Gets the current app config from state. Should be used sparingly (generally only in util files).
// Attached to window to avoid circular dependencies.
window.getConfig = () => store.getState().config;
window.onLogin = (key, salt) => init(key, salt);
window.logoutUser = (wasTimeout = false, error) => logout(wasTimeout, error);
window.getUserName = () => store.getState().user.get('id');
window.processUnsyncedDocumentsQueue = () => processUnsyncedDocumentsQueue();

getUserLoggedIn()
  .then((username) => {
    store.dispatch(setAuthStatus(Number(!!username)));
    if (username) {
      if (getEncryptKey()) {
        const key = getEncryptKey();
        const salt = getEncryptSalt(username);
        init(key, salt);
      } else {
        logout(false);
      }
    }
  });
