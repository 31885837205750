import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { List, Record, Map } from 'immutable';

import InventoryMapping from './../components/inventory/inventoryMapping';
import { saveFactory } from './../utils/redux';
import AsyncFetch from './../components/asyncFetch';
import { fetchData, saveInventoryMapping, suggestInventoryItem } from '../utils/api';
import { getInventoryMappingDataViews, getPrescribedInventoryMappingDataViews, getMasterDrugDataViews, getPrescribedInventoryDrugDataViews } from './../dataViews';
import { setIsFetching, setCurrentDataViewsError, setCurrentDataViews, replaceCurrentDataViewsModel, deleteDrugSuggestionModel, updateDrugSuggestionModel, setCurrentDataViewsModels, setMasterDrugModels, setPrescribedDrugModels, updateConfigValue, updateConfig, setDrugManufacturerModels } from './../actions';
import { mapDrug, fetchMasterData } from '../utils/inventory';

import type { Dispatch, State, CategorizedInventoryMapItems, Config, MapValue, DrugMap, DataView } from './../types';
import InventoryMapModel from '../models/inventoryMapModel';
import DrugModel from '../models/drugModel';
import type InventoryDrugModel from '../models/inventoryDrugModel';
import type DrugSuggestionModel from '../models/drugSuggestionModel';
import type APIError from '../utils/apiError';
import type DrugManufacturerModel from '../models/drugManufacturerModel';

export const CategorizedInventoryMapItemsModel = Record({
  currentInventoryItems: List(),
  reviewRejectedInventoryItems: List(),
  acceptedInventoryItems: List(),
  completedPendingReviewInventoryItems: List(),
  inCompletePendingReviewInventoryItems: List(),
});

/**
 * categorizes a list of inventory maps into various categories
 * filters out inventory maps that arent part of drugs
 * @param {List<InventoryMapModel>} pendingInventoryItems unmapped / rejected inventory items.
 * @param {List<InventoryDrugModel>} prescribedDrugs drugs prescribed by clinics in last 12 months.
 * @returns {CategorizedInventoryMapItems}
 */
export const categorizePendingItems = memoizeOne((
  pendingInventoryItems: List<InventoryMapModel>,
  prescribedDrugs: Map<string, InventoryDrugModel>,
  drugSuggestions: Map<string, DrugSuggestionModel>,
  drugs: List<DrugModel>,
): CategorizedInventoryMapItems => {
  const visibleDrugs = drugs.filter(d => d.isVisible() || prescribedDrugs.has(d.get('_id'))).map(d => d.get('_id')).toSet();
  const categorizedPendingItems = pendingInventoryItems.reduce((itemsMap, item) => {
    if (!item || !visibleDrugs.has(item.get('drug_id'))) {
      return itemsMap;
    }
    const mappedStatus = item.get('mapping_status');
    if (mappedStatus === 'REVIEW_REJECTED') {
      return itemsMap.set('reviewRejectedInventoryItems',
        itemsMap.get('reviewRejectedInventoryItems').push(item));
    }
    if (mappedStatus === 'VERIFIED') {
      return itemsMap.set('acceptedInventoryItems',
        itemsMap.get('acceptedInventoryItems').push(item));
    }
    if (mappedStatus === 'PENDING_REVIEW' && drugSuggestions.has(item.get('drug_id'))) {
      return itemsMap.set('completedPendingReviewInventoryItems',
        itemsMap.get('completedPendingReviewInventoryItems').push(item));
    }
    if (mappedStatus === 'PENDING_REVIEW' && !drugSuggestions.has(item.get('drug_id'))) {
      return itemsMap.set('inCompletePendingReviewInventoryItems',
        itemsMap.get('inCompletePendingReviewInventoryItems').push(item));
    }
    return itemsMap.set('currentInventoryItems',
      itemsMap.get('currentInventoryItems').push(item));
  },
  CategorizedInventoryMapItemsModel());
  return categorizedPendingItems;
});

/**
 * @description Created a Map of mappings records with drug_id
 * @param {List<InventoryMapModel>} pendingInventoryItems unmapped / rejected inventory items.
 * @return {Map<string, InventoryMapModel>} Map of mappings with drug_id as key
 */
const mappingsMap = memoizeOne(
  (
    currentDataViewsModels: List<InventoryMapModel>,
    prescribedDrugModelsMap: Map<string, InventoryDrugModel>,
    drugMap: DrugMap,
  ) =>
    currentDataViewsModels.reduce((mappingMap, mapping) => {
      const drugId = mapping.get('drug_id');
      const isPrescribedDrug = prescribedDrugModelsMap.has(drugId);
      const drugModel = drugMap.get(drugId);
      if (isPrescribedDrug || (drugModel && drugModel.isVisible())) {
        return mappingMap.set(drugId, mapping);
      }
      return mappingMap;
    }, Map()),
);

/**
 * @description returns a number representing the total number of required bill items.
 * @param {List<InventoryMapModel>} currentDataViewsModels unmapped / rejected inventory items.
 * @param {Map<string, InventoryDrugModel>} prescribedDrugModelsMap prescribed drug models map.
 * @param {Map<string, DrugModel>} drugMap drug models map.
 * @return {Map<string, InventoryMapModel>} Map of mappings with drug_id as key
 */
const billItemCount = memoizeOne(
  (
    currentDataViewsModels: List<InventoryMapModel>,
    prescribedDrugModelsMap: Map<string, InventoryDrugModel>,
    drugMap: DrugMap,
  ) => {
    const regex = /^(MSP|NOT)/gmi;
    const totalBillItemCount = currentDataViewsModels.reduce((count, item) => {
      const drugId = item.get('drug_id');
      const mappedStatus = item.get('mapping_status');
      const idMaps = item.get('drug_master_id_maps');
      const isPrescribedDrug = prescribedDrugModelsMap.has(drugId);
      const drugModel = drugMap.get(drugId);
      const isShown = (isPrescribedDrug || (drugModel && drugModel.isVisible()));
      if (mappedStatus === 'VERIFIED' && idMaps.length === 1 && regex.test(idMaps[0])) {
        return count;
      }
      if (isShown) {
        let tempCount = count;
        tempCount += Number(item.get('mapping_priority'));
        return tempCount;
      }
      return count;
    }, 0);
    return totalBillItemCount;
  },
);

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  drugs, masterDrugModelsMap, user, config, prescribedDrugModelsMap, drugSuggestions,
  isFetching, currentDataViewsError, currentDataViewsModels, currentDataViews, drugManufacturers,
  klinifyConfig,
}: State) => {
  const drugMap = mapDrug(drugs);
  const showAllDrugs = klinifyConfig.getIn(['inventory', 'showAllDrugs'], false);
  const totalDrugs = showAllDrugs ?
    mappingsMap(currentDataViewsModels, prescribedDrugModelsMap, drugMap) : prescribedDrugModelsMap;
  const totalV2Count = totalDrugs.filter((d: InventoryDrugModel) => d.get('mapping_category') > 1).size;
  const totalBillItemCount = billItemCount(
    currentDataViewsModels, prescribedDrugModelsMap, drugMap,
  );
  return {
    masterDrugModelsMap,
    user,
    config,
    categorizedInventoryMapItems: categorizePendingItems(
      currentDataViewsModels, prescribedDrugModelsMap, drugSuggestions, drugs,
    ),
    currentDataViews,
    containerDataViews: showAllDrugs ? getInventoryMappingDataViews() :
      getPrescribedInventoryMappingDataViews(),
    isFetching,
    currentDataViewsError,
    drugSuggestions,
    showLoadingIndicator: true,
    drugMap,
    totalDrugsCount: totalDrugs.size,
    totalV2Count,
    totalBillItemCount,
    drugManufacturers,
  };
};


/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  onAsyncFetchComplete: (models: List<InventoryDrugModel>) => {
    fetchData(getPrescribedInventoryDrugDataViews())
      .then((prescribedDrugsModels) => {
        dispatch(setPrescribedDrugModels(prescribedDrugsModels.filter(m => !!m)));
      }).catch(() => {});
    fetchData(getMasterDrugDataViews(List(models).map(r => List(r.get('drug_master_id_maps'))).flatten() as List<string>))
      .then((masterDrugModels) => {
        dispatch(setMasterDrugModels(masterDrugModels.filter(m => !!m)));
      }).catch(() => {});
  },
  fetchTableMasterData: (ids: List<string>) => fetchMasterData(ids, dispatch),
  setCurrentDataViewsModels: (models: List<InventoryDrugModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
  replaceCurrentDataViewsModel: (prevModel: InventoryDrugModel, newModel: InventoryDrugModel) =>
    dispatch(replaceCurrentDataViewsModel(prevModel, newModel)),
  deleteDrugSuggestionModel: (modelID: string) => dispatch(deleteDrugSuggestionModel(modelID)),
  updateDrugSuggestionModel: (model: InventoryDrugModel) =>
    dispatch(updateDrugSuggestionModel(model)),
  saveInventoryMapping,
  suggestInventoryItem,
  updateConfigValue: (keys: Array<string>, value: MapValue) =>
    dispatch(updateConfigValue(keys, value)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  saveModel: saveFactory(dispatch),
  setDrugManufacturers: (models: List<DrugManufacturerModel>) =>
    dispatch(setDrugManufacturerModels(models)),
});

const InventoryMappingContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(InventoryMapping));

export default InventoryMappingContainer;
