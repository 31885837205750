import type { List } from 'immutable';

import BaseModel from './baseModel';
import { getConfig } from './../utils/utils';

import type CoveragePayorModel from './coveragePayorModel';

export type Attributes = {
  _id: string,
  type: 'bill',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  is_finalised: boolean,
  is_void: boolean,
  coverage_payor_id?: string,
  coverage_policy_id?: string,
  encounter_id: string,
  patient_id: string,
  total_amount: number,
  timestamp: number,
  co_payment: number | null,
  notes?: string,
  internal_clinic_id?: string,
  applied_discounts_charges?: Array<string>
};

/**
 * BillModel
 *
 * @namespace BillModel
*/
class BillModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'bill';
  }

  /**
   * Gets the date of the bill (not exactly the same as created_by or edited_by).
   * @returns {number}
   */
  getDate(): number {
    return this.has('timestamp') ? this.get('timestamp') : this.getLastUpdateTime();
  }

  /**
   * Returns true if model has been "finalised".
   * @returns {boolean} True if this model has been edited at least once.
   */
  isFinalised(): boolean {
    return this.getOrFalse('is_finalised');
  }

  /**
   * Returns true if this bill has been voided.
   * @returns {boolean} True if bill is void.
   */
  isVoid(): boolean {
    return this.getOrFalse('is_void');
  }

  /**
   * Gets the payor for this bill or an empty string if non-existant.
   * @param {Immutable.List} coveragePayors A List of all CoveragePayorModels.
   * @returns {string} Coverage payor.
   */
  getCoveragePayorName(coveragePayors: List<CoveragePayorModel>): string {
    if (this.has('coverage_payor_id')) {
      const payor = coveragePayors.find(model => model.get('_id') === this.get('coverage_payor_id'));
      if (payor) {
        return payor.get('name');
      }
    }
    return getConfig().getIn(['patient', 'labels', 'coverage', 'no_coverage'], 'No Panel (Cash)');
  }
}

export default BillModel;
