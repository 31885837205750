import React from 'react';
import { List, Map, is } from 'immutable';
import Moment from 'moment';

import { sumAndFormatPrice, validateAndTrimString } from './../../utils/utils';
import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import { downloadCSV, convertToCSV } from './../../utils/export';
import { prettifyDate, validateTimeFilter } from './../../utils/time';
import ContentTransition from './../contentTransition';
import SuppliesReceivedTable from './suppliesReceivedTable';
import { printSuppliesReceived } from './../../utils/print';
import { renderDate } from './../../utils/tables';
import { sidebarWidth } from './../../utils/css';
import DateRangePicker from './../inputs/dateRangePicker';
import FormError from './../formError';
import Radio from './../inputs/radio';
import Input from './../inputs/input';
import Checkbox from './../inputs/checkbox';
import SaveButton from './../buttons/saveButton';
import { UNICODE } from './../../constants';
import ToggleCard from './../layout/toggleCard';
import { sortByPayment } from './../../utils/comparators';

import type { Config, MapValue, Row } from './../../types';
import type DrugModel from './../../models/drugModel';
import type SupplierModel from './../../models/supplierModel';
import type SupplyItemModel from './../../models/supplyItemModel';
import type SupplyModel from './../../models/supplyModel';
import type TransactionModel from './../../models/transactionModel';

const TYPE_OF_TRANSACTION_NOTES = ['write_off', 'damaged', 'expired', 'returned', 'recalled', 'missing', 'adjustment', 'dispensing_error', 'consumed'];
const TYPE_OF_SUPPLY_ITEM_NOTES = ['supply_item', 'loan_in', 'transfer_in', 'bonus', 'transfer_out', 'loan_out', 'bill_item'];

const COLUMNS = List([
  { value: 'number', label: '#' },
  { value: 'supplyItemDate', label: translate('date'), Cell: renderDate },
  { value: 'transactionType', label: translate('transaction_type') },
  { value: 'from', label: translate('from') },
  { value: 'to', label: translate('to') },
  { value: 'supplyNotes', label: translate('notes_batch') },
  { value: 'supplyItemSKU', label: translate('sku') },
  { value: 'supplyItemBatchID', label: translate('batch_id') },
  { value: 'supplyItemQuantity', label: translate('quantity') },
  { value: 'dispensationUnitOfSKU', label: translate('unit') },
  { value: 'supplyItemTotalPrice', label: translate('transaction_amount'), sortMethod: sortByPayment },
  { value: 'supplyItemExpiryDate', label: translate('expiry_date') },
  { value: 'supplyItemNotes', label: translate('notes_line_item') },
  { value: 'supplyInvoiceNumber', label: translate('invoice_#'), hidden: true },
  { value: 'supplyDeliveryOrderNumber', label: translate('delivery_order_#'), hidden: true },
  { value: 'supplyPurchaseOrderNumber', label: translate('purchase_order_#'), hidden: true },
  { value: 'supplyDocNumber', label: translate('doc_#'), hidden: true },
]);
// Create an empty row as a template for each row in the table/print out.
const EMPTY_ROW = {};
COLUMNS.forEach((c) => {
  EMPTY_ROW[c.value] = UNICODE.EMDASH;
});

type Props = {
  config: Config,
  drugs: List<DrugModel>,
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  suppliers: List<SupplierModel>,
  supplyItems: List<SupplyItemModel>,
  supplies: List<SupplyModel>,
  transactions: Map<string, TransactionModel>,
};

type State = {
  title: string,
  isLandscape: boolean,
  appendTotalsToExport: boolean,
  columns: Array<string>,
  isPrinting: boolean,
  isFetchingData: boolean,
  data: List<Row>,
  filteredData: Array<Row>,
  errorMessage?: string,
  filterOrSorted: boolean,
};

/**
 * A component that displays all bills for a clinic.
 * @class SuppliesReceived
 * @extends {Component}
 */
class SuppliesReceived extends React.Component<Props, State> {
  /**
   * Creates an instance of SuppliesReceived.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      title: 'Supplies Added and Transfers Report',
      isLandscape: true,
      appendTotalsToExport: true,
      columns: COLUMNS.filter(c => !c.hidden).map(c => c.value).toArray(),
      isPrinting: false,
      isFetchingData: true,
      data: List(),
      filteredData: [],
      errorMessage: undefined,
      filterOrSorted: false,
    };
  }

  /**
   * Called fundtions after component fully rendered
   * @returns {void}
   */
  componentDidMount() {
    this.fetchData();
  }

  /**
   * Get the drugs data
   * @param {string} SKUID the drug id
   * @returns {object}
   */
  getDrugsData(SKUID: string) {
    const drug = this.props.drugs.find(d => d.get('_id') === SKUID);
    return {
      supplyItemSKU: (drug && drug.get('name')) || UNICODE.EMDASH,
      dispensationUnitOfSKU: (drug && drug.get('dispensation_unit')) || UNICODE.EMDASH,
    };
  }

  /**
   * Get Supply and Supplier data
   * @param {string} supplyID the supply id
   * @returns {object}
   */
  getSupplyAndSupplierData(supplyID: string) {
    const supply = this.props.supplies.get(supplyID);
    if (supply) {
      return {
        supplyInvoiceNumber: supply.get('invoice_number', UNICODE.EMDASH),
        supplyDeliveryOrderNumber: supply.get('delivery_order_number', UNICODE.EMDASH),
        supplyPurchaseOrderNumber: supply.get('purchase_order_number', UNICODE.EMDASH),
        supplyDocNumber: supply.get('doc_number', UNICODE.EMDASH),
        supplyNotes: supply.get('notes') || UNICODE.EMDASH,
      };
    }
    return {};
  }

  /**
   * Returns the name of the supplier passed. If null returns UNICODE.EMDASH
   * @param {SupplierModel | void} supplier the supplier model.
   * @returns {string} supplier name or UNICODE.EMDASH
   */
  getSupplierName(supplier: SupplierModel | void): string {
    return supplier ? validateAndTrimString(supplier.get('name')) : UNICODE.EMDASH; // supplier name
  }

  /**
   * Get Transaction Data
   * @param {TransactionModel} transaction the transaction model to format data from
   * @param {SupplierModel} supplier All suppliers.
   * @returns {object}
   */
  getTransactionData(transaction: TransactionModel, supplier: SupplierModel) {
    const clinic = this.props.config.getIn(['clinic', 'name'])
      ? this.props.config.getIn(['clinic', 'name']) : UNICODE.EMDASH;
    let transactionType;
    let from = UNICODE.EMDASH;
    let to = UNICODE.EMDASH;
    if (transaction) {
      switch (transaction.get('source_type')) {
        case 'supply_item': {
          transactionType = 'supply_added';
          to = clinic;
          from = this.getSupplierName(supplier);
          break;
        }
        case 'loan_out': {
          transactionType = 'loan_out';
          to = this.getSupplierName(supplier);
          from = clinic;
          break;
        }
        case 'transfer_in': {
          transactionType = 'transfer_in';
          to = clinic;
          from = this.getSupplierName(supplier);
          break;
        }
        case 'transfer_out': {
          transactionType = 'transfer_out';
          to = this.getSupplierName(supplier);
          from = clinic;
          break;
        }
        default:
          transactionType = transaction.getType();
      }
    }
    return {
      transactionId: transaction.get('_id'),
      transactionType: transactionType ? translate(transactionType) : UNICODE.EMDASH,
      from,
      to,
    };
  }

  /**
   * Get Transaction Note
   * @param {SupplyItemModel} supplyItem model for which supplyItem note is needed.
   * @param {TransactionModel} transaction model for which transaction note is needed.
   * @returns {string} returns a transaction or supplyItem note.
   */
  getNotes(supplyItem: SupplyItemModel, transaction: TransactionModel): string {
    const isTransactionNote: boolean = transaction && TYPE_OF_TRANSACTION_NOTES.includes(transaction.get('source_type'));
    const isSupplyItemNote: boolean = supplyItem && TYPE_OF_SUPPLY_ITEM_NOTES.includes(transaction.get('source_type'));
    if (isSupplyItemNote) {
      return supplyItem.get('notes') || UNICODE.EMDASH;
    }
    if (isTransactionNote) {
      return transaction.get('notes') || UNICODE.EMDASH;
    }
    return UNICODE.EMDASH;
  }

  /**
   * Extracts the necessary data for the tables/exports/printed reports from props and updates the State.
   * @returns {void}
   */
  fetchData() {
    if (this.props.supplyItems.size === 0) {
      this.setState({ data: List(), isFetchingData: false });
    } else {
      const { suppliers, supplies, supplyItems, transactions } = this.props;
      const data = transactions.valueSeq()
        .filter(transaction => (
          transaction &&
          transaction.get('source_type') !== 'bill' &&
          transaction.get('source_type') !== 'bill_item'
        ))
        .map((transaction) => {
          const supplyItem = supplyItems.get(transaction.getSourceDoc());
          if (supplyItem) {
            const supply = supplies.get(supplyItem.get('supply_id'));
            const supplier = suppliers.get(supply.get('supplier_id'));
            return Object.assign(
              {},
              EMPTY_ROW,
              {
                supplyItemId: supplyItem.get('_id'),
                supplyItemDate: Number(transaction.getTimestamp()),
                supplyItemBatchID: supplyItem.get('batch_id', UNICODE.EMDASH),
                supplyItemQuantity: transaction.get('change'),
                supplyItemTotalPrice: (
                  transaction.hasPurchasePriceData()
                  && supplyItem.getPriceData()
                ) || UNICODE.EMDASH,
                supplyItemExpiryDate: supplyItem.get('expiry_date', UNICODE.EMDASH),
                supplyItemNotes: this.getNotes(supplyItem, transaction),
              },
              this.getSupplyAndSupplierData(supplyItem.get('supply_id')),
              this.getDrugsData(supplyItem.get('sku_id')),
              this.getTransactionData(transaction, supplier),
            );
          }
          return null;
        })
        .filter(datum => !!datum)
        .toArray();
      this.setState({ isFetchingData: false, data: List(data) });
    }
    this.setState({ filterOrSorted: false });
  }

  /**
   * Triggers a data fetch when the bills prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.filter, this.props.filter) ||
      !is(prevProps.supplyItems.size, this.props.supplyItems.size)) {
      this.fetchData();
    }
  }

  /**
   * Based on the props, determines if the export or print button should be displayed or not.
   * @returns {boolean} Whether or not the print button should display.
   */
  canExport(): boolean {
    return this.state.data.size > 0
      && this.props.filter.get('filterDateEnd') !== undefined
      && this.props.filter.get('filterDateStart') !== undefined;
  }

  /**
   * Validates title field
   * @returns {void}
   */
  isTitleValid() {
    return validateAndTrimString(this.state.title).length;
  }

  /**
   * Prints the supplies received.
   * @returns {Promise<void>} A promise upon print completion.
   */
  onPrintClicked(): Promise<void> {
    if (!this.isTitleValid()) {
      return this.setState({ errorMessage: translate('fill_required_fields') });
    }
    const { filteredData, title, isLandscape } = this.state;
    const { filter, config } = this.props;
    const columns = this.getColumns(true).toArray();
    const printData = List(filteredData)
      .map((item, index) => columns.map((column) => {
        if (column.value === 'number') {
          return (index + 1).toString();
        }
        if (column.value === 'supplyItemDate') {
          return Number.isInteger(item[column.value]) ?
            prettifyDate(parseInt(item[column.value], 10)) : item[column.value];
        }
        const value = item[column.value] !== undefined ? item[column.value] : UNICODE.EMDASH;
        return value;
      }));
    printSuppliesReceived(
      columns,
      printData.toArray(),
      {
        totalPurchases: this.getTotalPurchases(),
      },
      {
        filterDateStart: prettifyDate(Number(filter.get('filterDateStart'))),
        filterDateEnd: prettifyDate(Number(filter.get('filterDateEnd'))),
      },
      config,
      validateAndTrimString(title),
      isLandscape,
    );
    return Promise.resolve();
  }

  /**
   * Exports the supplies received.
   * @returns {void}
   */
  onExportClicked() {
    if (!this.isTitleValid()) {
      this.setState({ errorMessage: translate('fill_required_fields') });
    } else {
      const columns = this.getColumns(true).filter(c => c.value !== 'number');
      const { filteredData, appendTotalsToExport, title } = this.state;
      let exportData = List(filteredData)
        .map(item => columns.map((column) => {
          if (column.value === 'supplyItemDate') {
            return Number.isInteger(item[column.value]) ?
              prettifyDate(parseInt(item[column.value], 10)) : item[column.value];
          }
          return (item[column.value] !== UNICODE.EMDASH ? item[column.value] : '');
        }).toArray()).toArray();
      if (appendTotalsToExport) {
        exportData = exportData.concat([
          [translate('total_purchases'), this.getTotalPurchases()],
        ]);
      }
      downloadCSV(
        `${title}.csv`,
        convertToCSV(columns.map(c => c.label).toArray(), exportData),
      );
    }
  }

  /**
   * Get total purchases. Sums of all supply items price
   * @returns {void}
   */
  getTotalPurchases() {
    const { transactions, supplyItems } = this.props;
    const filteredData = this.state.filterOrSorted ?
      this.state.filteredData : this.state.data.toArray();
    const prices = filteredData.map((d) => {
      const transaction = transactions.get(d.transactionId);
      const supplyItem = supplyItems.get(d.supplyItemId);
      if (transaction && supplyItem) {
        return (transaction.hasPurchasePriceData() && supplyItem.getPriceData()) || 0;
      }
      return 0;
    });
    return sumAndFormatPrice(prices);
  }

  /**
   * Merges the given object with the current filter for accounts receivable reports and updates
   * them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: { filterDateStart?: Moment, filterDateEnd?: Moment }) {
    const newFilterProps = this.props.filter.merge(newValues);
    this.setState({ errorMessage: undefined });
    if (validateTimeFilter(
      newFilterProps.get('filterDateStart'),
      newFilterProps.get('filterDateStart'),
      newFilterProps.get('filterDateEnd'),
      newFilterProps.get('filterDateEnd'),
    )) {
      this.props.onFilterUpdated(newFilterProps);
      this.setState({ errorMessage: undefined });
    } else {
      this.setState({ errorMessage: translate('start_time_gt_end_time_error') });
    }
  }

  /**
   * Gets the columns with optional filtering
   * @param {boolean} filterForSelected If true it filters out any unselected columns
   * @returns {List<{ value: string, label: string }>}
   */
  getColumns(
    filterForSelected: boolean = false,
  ): List<{ value: string, label: string, renderMethod?: string }> {
    return filterForSelected ?
      COLUMNS.filter(column => this.state.columns.find(c => c === column.value)) : COLUMNS;
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <ContentTransition>
        <section className="o-scrollable-container" style={{ height: '100vh', maxWidth: `calc(100vw - ${sidebarWidth})` }}>
          <h1 data-public className="o-title">{translate('stock_adjustments')}</h1>
          <ToggleCard
            title={translate('print_export_options')}
            dataPublic
            startClosed
            footer={
              <footer className="o-card__footer">
                <SaveButton
                  className="o-button--small u-margin-right--1ws"
                  label={translate('export')}
                  disabled={this.state.isFetchingData || this.props.isFetching ||
                    this.props.currentDataViewsError || !this.canExport()}
                  onClick={() => this.onExportClicked()}
                  dataPublic
                />
                <SaveButton
                  isSaving={this.state.isPrinting}
                  className="o-button--small u-margin-right--1ws"
                  label={translate('print')}
                  disabled={this.state.isFetchingData || this.props.isFetching ||
                    this.props.currentDataViewsError || !this.canExport()}
                  onClick={() => this.onPrintClicked()}
                  dataPublic
                />
              </footer>
            }
          >
            <section className="u-margin--standard">
              <Input
                id="title"
                label={translate('title')}
                value={this.state.title}
                required
                onValueChanged={title => this.setState({ title })}
              />
              <Radio
                id="append_totals_to_export"
                label={translate('append_totals_to_export')}
                value={this.state.appendTotalsToExport.toString()}
                onValueChanged={value => this.setState({ appendTotalsToExport: value === 'true' })}
                options={[{ value: 'true', label: translate('yes') }, { value: 'false', label: translate('no') }]}
              />
              <Radio
                id="print_layout"
                label={translate('print_layout')}
                value={this.state.isLandscape.toString()}
                onValueChanged={value => this.setState({ isLandscape: value === 'true' })}
                options={[{ value: 'false', label: translate('portrait') }, { value: 'true', label: translate('landscape') }]}
              />
            </section>
          </ToggleCard>
          <ToggleCard title={translate('filter_options')} dataPublic>
            { this.state.errorMessage &&
              <div className="u-margin--standard">
                <FormError containerElementID="supplies-received-filter">{this.state.errorMessage}</FormError>
              </div>
            }
            <section className="u-margin--standard" id="supplies-received-filter">
              <DateRangePicker
                label={translate('dates')}
                id="filter-dates"
                startDate={this.props.filter.get('filterDateStart')}
                endDate={this.props.filter.get('filterDateEnd')}
                maxDate={Moment(new Date())}
                onValueChanged={({ startDate, endDate }) => {
                  // We need to do this because of how DRP handles out of bounds selections.
                  const newFilter = {};
                  if (startDate) {
                    newFilter.filterDateStart = startDate;
                  }
                  if (endDate) {
                    newFilter.filterDateEnd = endDate.clone().endOf('day');
                  } else if (endDate === null) { // occurs if end date set before start date.
                    newFilter.filterDateEnd = startDate?.clone().endOf('day');
                  }
                  this.updateFilter(newFilter);
                }}
              />
              <Checkbox
                id="columns"
                label={translate('columns')}
                labelClassName="o-label"
                options={this.getColumns().map(({ value, label }) => ({ value, label })).toArray()}
                value={this.state.columns}
                onValueChanged={toggledColumn => this.setState({
                  columns: this.state.columns.includes(toggledColumn) ?
                    this.state.columns.filter(c => c !== toggledColumn) :
                    this.state.columns.concat([toggledColumn]),
                })}
                multiColumn
                style={{ paddingBottom: '1px' }}
              />
            </section>
          </ToggleCard>
          <div className="u-margin--standard u-flex-justify-content-right">
            <p>
              {`${translate('total_purchases')}:  ${this.getTotalPurchases()}`}
            </p>
          </div>
          <div className="o-card u-margin-bottom--4ws">
            <h2 data-public className="o-card__title">{translate('stock_adjustments')}</h2>
            <SuppliesReceivedTable
              isFetching={this.state.isFetchingData || this.props.isFetching}
              currentDataViewsError={this.props.currentDataViewsError}
              columns={this.getColumns(true).filter(v => v.value !== 'number').toArray()}
              data={this.state.data.toArray()}
              onFilterAndSort={filteredData => this.setState({
                filteredData,
                filterOrSorted: true,
              })}
              initialDataHandler={initialData => this.setState({ filteredData: initialData })}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default SuppliesReceived;
