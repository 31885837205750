import React, { useEffect, useState } from 'react';
import glamorous from 'glamorous';

const ProgressBarVisual = glamorous.div({
  transition: 'width .2s',
  padding: '6px 0',
  textAlign: 'center',
});

const ProgressVisual = glamorous.div({
  display: 'flex',
  margin: '20px 0',
  border: '1px solid #e5e5e5',
});

type Data = Array<{
  label: string,
  value: number,
  strokeColor: string
}>;

/**
 * @param {Data} data data to be shown in progress bar{
 * @returns {React.Component}
 */
const ProgressLine = ({
  data = [],
}: {
  data: Data,
}) => {
  const [widths, setWidths] = useState(data.map(() => 0));

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
    // You need to wrap it to trigger the animation
    requestAnimationFrame(() => {
      // Set a new array of percentage widths based on the props
      setWidths(data.map(item => `${item.value}%`));
    });
  }, [data]);

  return (
    <>
      <ProgressVisual>
        {data.map((item, index) => (
          // map each part into separate div and each will be animated
          // because of the "transition: width 2s;" css in class "progressVisualPart"
          // and because of the new width ("widths[index]", previous one was 0)
          <ProgressBarVisual
            key={item.label}
            style={{
              width: widths[index],
              backgroundColor: item.strokeColor,
            }}
          >
            {item.value > 5 ? (
              <div>
                <h6>{item.label}</h6>
                <h6>{widths[index]}</h6>
              </div>
            ) : null}
          </ProgressBarVisual>
        ))}
      </ProgressVisual>
    </>
  );
};

export default ProgressLine;
