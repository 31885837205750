import React from 'react';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';

import { convertNumberToPrice, getConfirmation } from './../../utils/utils';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import translate from './../../utils/i18n';
import Table from './../table/table';
import { renderWithLink, renderDateWithLink } from './../../utils/tables';
import { UNICODE } from './../../constants';
import EditHistory from './../editHistory/editHistory';
import PatientPage from './../layout/patientPage';
import { sortByDate, sortByPayment } from './../../utils/comparators';
import NotFoundContainer from './../../containers/notFoundContainer';
import { logMessage, debugPrint } from './../../utils/logging';
import { getReferralQueryString } from '../../utils/router';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { voidBill, getReverseTransactions } from './../../utils/billing';
import { getTransactionsBySourceIds, getModelsForBill } from './../../utils/api';
import Button from './../buttons/button';

import type AllergyModel from './../../models/allergyModel';
import type BillModel from './../../models/billModel';
import type EncounterModel from './../../models/encounterModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type SalesItemModel from './../../models/salesItemModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type { ActiveIngredient, SaveModels, User } from './../../types';
import DrugModel from './../../models/drugModel';

type Props = {
  allergies: List<AllergyModel>,
  bills: List<BillModel>,
  encounters: List<EncounterModel>,
  medicalCertificates: List<MedicalCertificateModel>,
  salesItems: List<SalesItemModel>,
  coveragePayors: List<CoveragePayorModel>,
  saveModels: SaveModels,
  patient?: PatientModel,
  patientStub?: PatientStubModel,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  user: User,
  drugs: List<DrugModel>,
};

const COLUMNS = [
  {
    accessor: 'date', Header: translate('date'), Cell: renderDateWithLink, sortMethod: sortByDate,
  },
  { accessor: 'encounter_flow', Header: translate('encounter_flow'), Cell: renderWithLink },
  {
    accessor: 'mc', Header: translate('medical_certificate'), minWidth: 200, Cell: renderWithLink,
  },
  {
    accessor: 'fees', Header: translate('fees'), Cell: renderWithLink, sortMethod: sortByPayment,
  },
  { accessor: 'view_payments', Header: '', sortable: false },
  { accessor: 'view_history', Header: '', sortable: false },
  {
    accessor: 'delete', Header: '', sortable: false, minWidth: 100, width: 100,
  },
];

/**
 * Renders the given content with a wrapper to show it is void if isVoid is true.
 * @param {string} content Content to wrap.
 * @param {boolean} isVoid True if bill is void.
 * @returns {string | React.Component}
 */
function renderWithVoidWrapper(content: string, isVoid: boolean) {
  return isVoid ? <span style={{ opacity: 0.2 }}>{content}</span> : content;
}

/**
 * Creates a PatientBillingHistory component.
 * @param {object} props The props for the component.
 * @returns {React.Component} PatientBillingHistory
 */
class PatientBillingHistory extends React.PureComponent<Props> {
  /**
   * Generate a table row for the given bill.
   * @param {BillModel} bill A Bill model
   * @param {List<MedicalCertificateModel>} medicalCertificates All MCs.
   * @param {List<EncounterModel>} encounters All encounters
   * @param {List<SalesItemModel>} salesItems All Sales items.
   * @returns {{}}
   */
  getTableRow(
    bill: BillModel, medicalCertificates: List<MedicalCertificateModel>,
    encounters: List<EncounterModel>, salesItems: List<SalesItemModel>,
  ) {
    // TODO: Just get encounter direct from DB.
    const encounter = encounters.find(e => e.get('_id') === bill.get('encounter_id'));
    if (!encounter) {
      debugPrint(`Cant find the encounter ${bill.get('encounter_id')} for the bill ${bill.get('_id')}`, 'error');
      logMessage('Encounter referenced by bill not found.', 'error');
    }
    const medicalCertificate = medicalCertificates
      .find(mc => mc.get('encounter_id') === bill.get('encounter_id'));
    const paymentsLink = `/patient/${bill.get('patient_id')}/billing/${bill.get('encounter_id')}/payments${getReferralQueryString()}`;
    return {
      date: bill.getDate(),
      encounter_flow: renderWithVoidWrapper(encounter ? encounter.getEncounterType(salesItems) : translate('loading...'), bill.isVoid()),
      fees: renderWithVoidWrapper(convertNumberToPrice(bill.get('total_amount')), bill.isVoid()),
      mc: renderWithVoidWrapper(medicalCertificate ?
        medicalCertificate.getDescription() : UNICODE.EMDASH, bill.isVoid()),
      view_history: <EditHistory
        model={bill}
        buttonLabel={translate('view_history')}
        buttonClass="o-text-button o-text-button--contextual"
      />,
      view_payments: <Link to={paymentsLink} className="o-text-button o-text-button--contextual" style={{ fontSize: '11px' }}>{translate('view_payments')}</Link>,
      delete: !bill.isVoid() ? this.getDeleteButton(bill) : <Button dataPublic className="o-text-button o-text-button--danger">{translate('voided')}</Button>,
      link: encounter ? `/patient/${bill.get('patient_id')}/billing/${bill.get('encounter_id')}${getReferralQueryString()}` : undefined,
    };
  }

  /**
   * Called when delete is clicked on an item. Updates its state to be hidden.
   * @param {BaseModel} item The item to delete
   * @returns {undefined}
   */
  onDeleteClicked(item: BillModel) {
    if (item) {
      getConfirmation(translate('confirm_bill_void'))
        .then(
          () => {
            getConfirmation(translate('confirm_void_payments'))
              .then(
                () => this.voidBill(item, true),
                () => this.voidBill(item, false),
              );
          },
          () => {},
        );
    }
  }

  /**
   * Handle the model updates needed to void a bill.
   * @param {BillModel} item The bill to be voided.
   * @param {boolean} voidPayments If true the payments associated with this bill will also be voided.
   * @returns {Promise<Array<Model>>}
   */
  voidBill(item: BillModel, voidPayments: boolean) {
    getModelsForBill(item.get('_id'))
      .then((models) => {
        const dispensations = models ? models.filter(m => m.get('type') === 'bill_item' && m.isPrescription()) : List();
        getTransactionsBySourceIds(dispensations.map(i => i.get('_id')))
          .then((transactions) => {
            const reverseTransactions = getReverseTransactions(transactions);
            voidBill(item, this.props.saveModels, voidPayments, models, reverseTransactions)
              .then(() => {
                reverseTransactions.forEach(model => this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change')));
                createSuccessNotification(translate('bill_voided'));
              });
          });
      })
      .catch(() => createErrorNotification(translate('bill_void_error')));
  }

  /**
   * Returns a Delete button for the given item.
   * @param {BaseModel} item An item for the delete button
   * @returns {React.Component} A Delete button component.
   */
  getDeleteButton(item: BillModel) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('finalised_bill', 'delete')])} user={this.props.user}>
        <Button
          className="o-text-button o-text-button--danger"
          onClick={() => this.onDeleteClicked(item)}
          dataPublic
        >
          {translate('void_bill')}
        </Button>
      </PermissionWrapper>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      bills, medicalCertificates, encounters, salesItems, coveragePayors,
    } = this.props;
    const patient = this.props.patient ? this.props.patient : this.props.patientStub;
    if (!patient) {
      logMessage('PatientModel not provided.', 'error');
      return <NotFoundContainer />;
    }
    return (
      <PatientPage
        patient={patient}
        coveragePayors={coveragePayors}
        allergies={this.props.allergies}
        user={this.props.user}
        drugs={this.props.drugs}
      >
        <h1 data-public className="o-title">{translate('billing_history')}</h1>
        <div className="o-card">
          <Table
            noDataText={translate('no_previous_bills')}
            showPagination
            columns={COLUMNS}
            defaultSorted={[{ id: 'date', desc: true }]}
            data={
              bills
                .map(bill =>
                  this.getTableRow(bill, medicalCertificates, encounters, salesItems))
                .toArray()
            }
          />
        </div>
      </PatientPage>
    );
  }
}

export default PatientBillingHistory;
