import { connect } from 'react-redux';

import ChangePassword from './../components/userManagement/changePassword';
import { State } from '../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ user, workspace }: State) => ({ user, workspace, });

const ChangePasswordContainer = connect(mapStateToProps)(ChangePassword);

export default ChangePasswordContainer;
