import React, { Fragment } from 'react';
import { List } from 'immutable';

import { createSuccessNotification, createInfoNotification, createErrorNotification } from './../../utils/notifications';
import { saveBlob, getConfirmation } from './../../utils/utils';
import translate from './../../utils/i18n';
import LabResult from './labResult';
import { sendLabOrder, cancelLabOrder } from './../../utils/labs';
import { createPermission, hasPermission } from './../../utils/permissions';
import ProcedureResultModel from './../../models/procedureResultModel';
import Button from './../buttons/button';

import type { SaveModel, SaveModels, User, ComponentReference } from './../../types';
import type ProcedureRequestModel from './../../models/procedureRequestModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SpecimenModel from './../../models/specimenModel';

type Props = {
  procedureRequest: ProcedureRequestModel,
  procedureStatuses: List<ProcedureStatusModel>,
  procedureTypes: List<ProcedureTypeModel>,
  collectedSpecimens: List<CollectedSpecimenModel>,
  procedureResults: List<ProcedureResultModel>,
  patientStubs: List<PatientStubModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
  isSendingInProgress: (status: boolean) => void,
  inProgressProcedureRequestID: (ID: string) => void,
  specimens: List<SpecimenModel>,
};

type State = {
  isDisabled: boolean,
  isProcedureResultModalVisible: boolean,
};

/**
 * A component that display buttons for ordered lab request.
 * @namespace LabRequestActionButton
 */
class LabRequestActionButton extends React.Component<Props, State> {
  uploadInput: ComponentReference | void;

  /**
   * Creates an instance of LabRequestActionButton.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isDisabled: false,
      isProcedureResultModalVisible: false,
    };
  }

  /**
   * Clear the state after the process is done.
   * @param {boolean} status true if in progress.
   * @returns {void}
   */
  inProgress(status: boolean = true) {
    this.setState({ isDisabled: status });
    this.props.isSendingInProgress(status);
  }

  /**
   * Returns true if meets the criteria.
   * @param {ProcedureRequestModel} procedureRequest The ProcedureRequestModel.
   * @param {string} statusLastEventStatus The status of procedure request.
   * @param {?string} collectedSpecimenLastEventStatus The status of the collected specimen.
   * @param {{}} typeIntegrations The ProcedureType integrations settings and information.
   * @returns {boolean}
   */
  isVisible(procedureRequest: ProcedureRequestModel,
    statusLastEventStatus: string,
    collectedSpecimenLastEventStatus?: string,
    typeIntegrations: {}) {
    const { procedureTypes, specimens } = this.props;
    const hideToStatuses = [
      'sent_success',
      'in_progress',
      'completed',
      'cancelled',
      'reflex',
      'mismatch',
    ];

    if ((collectedSpecimenLastEventStatus === 'courier_collected' ||
      collectedSpecimenLastEventStatus === 'provider_received' ||
      collectedSpecimenLastEventStatus === 'provider_rejected') &&
      statusLastEventStatus === 'acknowledged') {
      hideToStatuses.push('acknowledged');
    }

    /**
     * In Flow 2, supports.receive_result is set to false
     * that why we need to hide if the status is 'approved' in Flow 1.
     */
    if (typeIntegrations && typeIntegrations.supports.receive_result) {
      hideToStatuses.push('approved');
    }

    /**
     * In Flow 3, hide button if procedure status is not 'pending'
     * and collected specimen status is not 'courier_collected'.
     */
    if (!typeIntegrations &&
      (statusLastEventStatus !== 'pending') &&
      ((collectedSpecimenLastEventStatus !== 'courier_collected') ||
      (collectedSpecimenLastEventStatus === 'courier_collected')) &&
      ((statusLastEventStatus === 'rejected') || (statusLastEventStatus === 'cancelled'))) {
      hideToStatuses.push('rejected', 'cancelled');
    }

    /**
     * The button will also be hidden if collectedSpecimen has
     * one of these status 'courier_collected', 'provider_received' and 'provider_rejected'.
     * This won't effect if the procedure type has integrations.
     */
    if (collectedSpecimenLastEventStatus !== undefined && collectedSpecimenLastEventStatus !== 'pending') {
      hideToStatuses.push(collectedSpecimenLastEventStatus);
    }

    /**
     * Hide button if the procedureType does not have any specimens
     */
    const specimenRequirements = procedureRequest.getSpecimens(procedureTypes, specimens);
    if (!specimenRequirements.size) {
      hideToStatuses.push('pending');
    }

    return !hideToStatuses.includes(statusLastEventStatus);
  }

  /**
   * Returns a button label.
   * @param {string} statusLastEventStatus The status of procedure request.
   * @param {?string} collectedSpecimenLastEventStatus The status of the collected specimen.
   * @param {{}} typeIntegrations The ProcedureType integrations settings and information.
   * @param {boolean} inProgress If the procedure request is currently proccessing.
   * @returns {string}
   */
  getLabel(statusLastEventStatus: string,
    collectedSpecimenLastEventStatus?: string,
    typeIntegrations: {},
    inProgress: boolean) {
    /**
     * In Flow 2, supports.receive_result is set to false
     * need to change label based on the procedure status.
     */
    if (typeIntegrations && !typeIntegrations.supports.receive_result) {
      switch (statusLastEventStatus) {
        case 'approved':
          return 'upload_results';
        case 'acknowledged':
          return 'courier_collected';
        case 'results_received':
          return 'view_results';
        case 'sent_error':
          return inProgress ? 'resending_order' : 'retry';
        default:
          return inProgress ? 'sending_order' : 'send_order';
      }
    }

    /**
     * Flow 3 - If no integrations,
     * need to change label based on the collected specimen status.
     */
    if (!typeIntegrations && (statusLastEventStatus === 'pending')) {
      if (collectedSpecimenLastEventStatus && collectedSpecimenLastEventStatus === 'courier_collected') {
        return 'upload_results';
      }
      return inProgress ? 'updating_specimens_status' : 'courier_collected';
    }

    switch (statusLastEventStatus) {
      case 'pending':
        return inProgress ? 'sending_order' : 'send_order';
      case 'sent_error':
        return inProgress ? 'resending_order' : 'retry';
      case 'acknowledged':
        return 'courier_collected';
      case 'results_received':
        return 'view_results';
      default:
        return inProgress ? 'sending_order' : 'send_order';
    }
  }

  /**
   * Returns the button action when clicked.
   * @param {ProcedureRequestModel} procedureRequest The ProcedureRequestModel.
   * @param {{}} typeIntegrations The ProcedureType integrations settings and information.
   * @param {string} statusLastEventStatus The status of procedure request.
   * @param {?string} collectedSpecimenLastEventStatus The status of collected specimens.
   * @returns {void}
   */
  getActions = (procedureRequest: ProcedureRequestModel,
    typeIntegrations: {},
    statusLastEventStatus: string,
    collectedSpecimenLastEventStatus?: string) => {
    const { procedureResults, saveModel } = this.props;
    /**
     * Flow 2
     */
    if (typeIntegrations && !typeIntegrations.supports.receive_result) {
      if (statusLastEventStatus === 'approved') {
        return this.handleUploadClick();
      }
      if (statusLastEventStatus === 'results_received') {
        return this.setState({ isProcedureResultModalVisible: true });
      }
      if (statusLastEventStatus === 'acknowledged') {
        return this.updateCollectedSpecimensStatus(procedureRequest);
      }
      // if no procedure_result doc yet, need to manually create it.
      const hasProcedureResult = procedureRequest.getResult(procedureResults);
      if (!hasProcedureResult) {
        const procedureResult = new ProcedureResultModel({
          procedure_request_id: procedureRequest.get('_id'),
          procedure_type_id: procedureRequest.get('procedure_type_id'),
          patient_id: procedureRequest.get('patient_id'),
          assets: [],
        });
        return saveModel(procedureResult)
          .then(() => this.handleUploadClick());
      }
    }

    /**
     * Flow 3 - No integrations
     */
    if (!typeIntegrations && (statusLastEventStatus === 'pending')) {
      if (collectedSpecimenLastEventStatus && collectedSpecimenLastEventStatus === 'courier_collected') {
        return this.handleUploadClick();
      }
      return this.updateCollectedSpecimensStatus(procedureRequest);
    }

    switch (statusLastEventStatus) {
      case 'pending':
        return this.handleSendLabTestOrder(procedureRequest);
      case 'sent_error':
        return this.handleSendLabTestOrder(procedureRequest);
      case 'acknowledged':
        return this.updateCollectedSpecimensStatus(procedureRequest);
      case 'results_received': {
        return this.setState({ isProcedureResultModalVisible: true });
      }
      default:
        return this.handleSendLabTestOrder(procedureRequest);
    }
  }

  /**
   * Updates the status of all collected specimens.
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel.
   * @returns {void}
   */
  updateCollectedSpecimensStatus(procedureRequest: ProcedureRequestModel) {
    this.inProgress();
    this.props.inProgressProcedureRequestID(procedureRequest.get('_id'));
    const { procedureTypes, collectedSpecimens, saveModel } = this.props;
    const specimens = procedureRequest.getCollectedSpecimens(procedureTypes, collectedSpecimens);
    if (specimens.size > 0) {
      Promise.all(specimens
        .map((model) => {
          model.addEvent('courier_collected');
          return saveModel(model);
        }))
        .then(() => {
          createSuccessNotification(translate('specimens_status_updated'));
          this.inProgress(false);
        });
    }
  }

  /**
   * Handles the click event for hidden input field.
   * @return {void}
   */
  handleUploadClick = () => {
    if (this.uploadInput) {
      this.uploadInput.click();
    }
  }

  /**
   * Handle uploading of asset to the ProcedureResultModel
   * @returns {void}
   */
  handleUpload = () => {
    const { procedureRequest, procedureResults, procedureStatuses, saveModels } = this.props;
    if (this.uploadInput && this.uploadInput.files.length > 0) {
      const file = this.uploadInput.files[0];
      if (file.size > 10485760) { // 10MB
        createInfoNotification(translate('upload_file_limit_error'));
      } else {
        this.inProgress();
        this.props.inProgressProcedureRequestID(procedureRequest.get('_id'));
        saveBlob(file)
          .then((assetObject) => {
            Object.assign(assetObject, { name: file.name, timestamp: new Date().getTime() });
            saveModels([
              procedureRequest.addResultAsset(procedureResults, assetObject),
              procedureRequest.addStatusEvent(procedureStatuses, 'results_received'),
            ]);
          })
          .then(() => {
            createSuccessNotification(translate('result_upload_successfully'));
            this.inProgress(false);
          });
      }
    }
  }

  /**
   * Returns true if the procedure request is on progress sending to api.
   * @param {string} status The event status
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel
   * @returns {Promise<boolean>}
   */
  setProcedureRequestInProgress(
    status: string,
    procedureRequest: ProcedureRequestModel,
  ): Promise<boolean> {
    const { saveModel, procedureStatuses } = this.props;
    const procedureStatus = procedureRequest.getStatus(procedureStatuses);
    if (procedureStatus) {
      return saveModel(procedureRequest.addStatusEvent(procedureStatuses, status))
        .then(() => Promise.resolve(true));
    }
    return Promise.resolve(true);
  }

  /**
   * Send lab order to the integrated lab
   * @param {ProcedureRequestModel} procedureRequest a ProcedureRequestModel
   * @returns {undefined}
   */
  handleSendLabTestOrder(procedureRequest: ProcedureRequestModel) {
    this.inProgress();
    this.props.inProgressProcedureRequestID(procedureRequest.get('_id'));
    const { patientStubs, procedureStatuses } = this.props;
    this.setProcedureRequestInProgress('sending', procedureRequest)
      .then(() => {
        sendLabOrder(procedureRequest.get('_id')).then((response) => {
          if (response && response.status === 504) {
            createErrorNotification(translate('sending_status_timeout'));
            this.inProgress(false);
          } else if (response && !response.ok) {
            this.setProcedureRequestInProgress('sent_error', procedureRequest)
              .then(() => {
                createErrorNotification(translate('error_sending_order_for_patient', { patientName: procedureRequest.getPatientName(patientStubs) }));
                this.inProgress(false);
              });
          } else {
            createSuccessNotification(translate('lab_test_sent_successfully'));
            this.inProgress(false);
          }
        })
          .then(() => (procedureRequest.getStatusLastEventStatus(procedureStatuses) !== 'sending' ? this.inProgress(false) : false));
      });
  }

  /**
   * Handle cancelling lab test
   * Need to call cancel api route. Wating for Let
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @returns {undefined}
   */
  handleCancelOrderedLabTest(procedureRequest: ProcedureRequestModel,
    procedureStatuses: List<ProcedureStatusModel>) {
    const procedureStatus = procedureRequest.getStatus(procedureStatuses);
    getConfirmation(translate('confirm_procedure_request_cancellation'))
      .then(() => {
        this.inProgress();
        if (procedureStatus) {
          return this.props.saveModel(procedureRequest.addStatusEvent(this.props.procedureStatuses, 'cancelled'))
            .then(() => {
              createSuccessNotification(translate('lab_test_cancelled_successfully', { labTestName: procedureRequest.getTypeName(this.props.procedureTypes) }));
              this.inProgress(false);
            });
        }
        return cancelLabOrder(procedureRequest.get('_id')).then((response) => {
          if (response && !response.ok) {
            createErrorNotification(translate('error_cancelling_order'));
            this.inProgress(false);
          } else {
            createSuccessNotification(translate('lab_test_cancelled_successfully', { labTestName: procedureRequest.getTypeName(this.props.procedureTypes) }));
            this.inProgress(false);
          }
        })
          .then(() => this.inProgress(false));
      },
      () => {});
  }

  /**
   * Check if the cancel should be disabled based on the criteria
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel
   * @param {boolean} inProgress if state is in progress
   * @return {boolean}
   */
  isCancelButtonDisabled(
    procedureRequest: ProcedureRequestModel,
    procedureStatuses: List<ProcedureStatusModel>, inProgress: boolean,
  ) {
    const procedureStatus = procedureRequest.getStatus(procedureStatuses);
    if (procedureStatus) {
      if (procedureStatus.hasStatus('sent_success') ||
        (!!procedureStatus.hasStatus('sent_success') && procedureStatus.hasStatus('sent_error')) ||
        procedureRequest.getStatusLastEventStatus(procedureStatuses) === 'sending' ||
        inProgress) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns an object that contains action onClick, label and check for button visibility
   * @returns {void}
   */
  generateActionButtonProperties() {
    const {
      procedureRequest, procedureStatuses, procedureTypes,
      collectedSpecimens, user,
    } = this.props;
    const statusLastEventStatus = procedureRequest.getStatusLastEventStatus(procedureStatuses);
    const collectedSpecimenLastEventStatus = procedureRequest ?
      procedureRequest.getCollectedSpecimensLastEventStatus(
        procedureTypes, collectedSpecimens,
      ) : '';
    const { isDisabled } = this.state;
    const typeIntegrations = procedureRequest.getTypeIntegrations(procedureTypes);
    const inProgress = isDisabled || (procedureRequest.getStatus(procedureStatuses) && statusLastEventStatus === 'sending');
    const isNotOnLabRequestsPage = (location.hash !== '#/lab-requests');
    // Cancel button properties
    if (isNotOnLabRequestsPage) {
      return {
        isVisible: true,
        isDisabled: this.isCancelButtonDisabled(procedureRequest, procedureStatuses, inProgress),
        label: inProgress ? 'cancelling_order' : 'cancel',
        action: () => this.handleCancelOrderedLabTest(procedureRequest, procedureStatuses),
        className: 'o-button--danger',
      };
    }
    return {
      isVisible: this.isVisible(
        procedureRequest,
        statusLastEventStatus,
        collectedSpecimenLastEventStatus,
        typeIntegrations,
      ),
      isDisabled: inProgress || !hasPermission(user, List([createPermission('lab_requests', 'update')])),
      label: this.getLabel(
        statusLastEventStatus,
        collectedSpecimenLastEventStatus,
        typeIntegrations,
        inProgress,
      ),
      action: () => this.getActions(
        procedureRequest,
        typeIntegrations,
        statusLastEventStatus,
        collectedSpecimenLastEventStatus,
      ),
      className: '',
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      isVisible, isDisabled, label,
      action, className = '',
    } = this.generateActionButtonProperties();
    const { isProcedureResultModalVisible } = this.state;
    const { procedureRequest, procedureResults } = this.props;
    const isOnLabRequestsPage = (location.hash === '#/lab-requests');
    return (
      <div>
        { isVisible &&
          <Button
            className={`o-button o-button--small ${className}`}
            disabled={isDisabled}
            onClick={() => action()}
            dataPublic
          >
            {translate(label)}
          </Button>
        }
        { isOnLabRequestsPage &&
          <Fragment>
            <input
              key="hiddenUploadInput"
              type="file"
              id="upload-input"
              style={{ display: 'none' }}
              ref={(uploadInput) => { this.uploadInput = uploadInput; }}
              onChange={this.handleUpload}
              accept=".pdf,image/jpeg,image/png"
            />
            { isProcedureResultModalVisible &&
            <LabResult
              assets={procedureRequest.getResultAssets(procedureResults)}
              isProcedureResultModalVisible
              onClose={() => this.setState({ isProcedureResultModalVisible: false })}
              onClickedUpload={() => this.handleUploadClick()}
            />}
          </Fragment>
        }
      </div>
    );
  }
}

export default LabRequestActionButton;
