import { setConnectionStatus, setOfflinePromptVisible } from './../actions';
import { logExitOfflineState } from './logging';
import { getStore } from './redux';

/**
 * Updates state wih connection status.
 * @param {boolean} isOnline current online state
 * @param {boolean} serverOffline Server is offline
 * @returns {void}
 */
export function setIsOnline(isOnline: boolean, serverOffline?: boolean) {
  getStore().dispatch(setConnectionStatus(isOnline, serverOffline));
}

/**
 * Updates state wih connection status. If going from offline to
 * online it will also request that any offline assets be saved.
 * @param {Redux.store} store - The redux store for the app.
 * @param {boolean} isOnline Online Status
 * @param {boolean} serverOffline whether the server is offline
 * @returns {void}
 */
export default function setOnlineStatus(store, isOnline, serverOffline) {
  setIsOnline(isOnline, serverOffline);
  if (isOnline) {
    logExitOfflineState();
    window.processUnsyncedDocumentsQueue();
  }
}


/**
 * Expected to be called when tried to attempt network call while offline.
 * setOfflinePromptVisible sets the offline modal/prompt visible.
 * @returns {Void}
 */
export function offlinePromptHandler() {
  getStore().dispatch(setOfflinePromptVisible(true));
}

/**
 * Returns true if app is online
 * @returns {boolean}
 */
export function getIsOnline(): boolean {
  return getStore().getState().isOnline;
}
