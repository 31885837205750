import { List } from 'immutable';

import { mapFromJS, getClinicID, queryMapping } from './utils/utils';

import type { DataView, IntervalString, Model } from './types';
import { fetchWithRetry } from './utils/retry';
import { handleApiError, handleUnauthorisedApiResponse } from './utils/response';

const ALL_BY_ENCOUNTER: DataView = mapFromJS({ view: 'allByEncounterIdAndType', options: { include_docs: true } }); // Requires encounter IDs to first be fetched and set at options.keys
const DOCS_BY_ID: DataView = mapFromJS({ view: null });

const BILLS_BY_ID_WITH_ENCOUNTERS: DataView = mapFromJS({
  view: null,
  fetchFromValues: DOCS_BY_ID,
  fetchFromValuesKeys: ['encounter_id'],
});

const BILL_ITEMS_BY_ID_WITH_BILLS: DataView = mapFromJS({
  view: null,
  fetchFromValues: DOCS_BY_ID,
  fetchFromValuesKeys: ['bill_id'],
});

const BILLS_BILL_ITEMS_ENCOUNTERS_BY_BILL_ID: DataView = mapFromJS({
  // first get bill items, then get bills and encounters.
  // need to pass in a list of <bill_id>, "bill_item" keys
  view: `${queryMapping.get('allByBillIdAndType')?.viewName}`,
  fetchFromValues: BILLS_BY_ID_WITH_ENCOUNTERS,
  fetchFromValuesKeys: ['bill_id'],
});

/**
 * Returns a List of claims within the timeframe.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Tiemstamp for end of range
 * @returns {DataView}
 */
function getClaims(startkey?: number, endkey?: number): DataView {
  const type = 'claim';
  return mapFromJS({
    view: `${queryMapping.get('allByTimestamp')?.viewName}`,
    options: { startkey: [type, startkey], endkey: [type, endkey], include_docs: true },
  });
}

/**
 * Returns a List of bills within the timeframe.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Tiemstamp for end of range
 * @returns {DataView}
 */
function getBills(startkey?: number, endkey?: number): DataView {
  const type = 'bill';
  return mapFromJS({
    view: `${queryMapping.get('allByTimestamp')?.viewName}`,
    options: { startkey: [type, startkey], endkey: [type, endkey], include_docs: true },
  });
}

/**
 * Returns a List of bill_items for the given bill id.
 * @param {string} billId Bill to get bill items for
 * @returns {DataView}
 */
function getBillItemsByBill(billId: string): DataView {
  const type = 'bill_item';
  return mapFromJS({
    view: `${queryMapping.get('allByBillIdAndType')?.viewName}`,
    options: { key: [billId, type], include_docs: true },
  });
}

/**
 * @param {string} billId Bill to get bill items for
 * @returns {DataView}
 */
function getTypeByBillIds(type: 'payment' | 'bill', ...billIds: string[]): DataView {
  return mapFromJS({
    view: `${queryMapping.get('allByBillIdAndType')?.viewName}`,
    options: { keys: billIds.map(billId => [billId, type]), include_docs: true },
  });
}

/**
 * Returns a List of bill_items for the given bill id.
 * @param {string} encounterId EncounterId to get bills and bill items for
 * @returns {List<DataView>}
 */
export function getBillsByEncounter(encounterId: string): List<DataView> {
  return List([mapFromJS({
    view: `${queryMapping.get('allByEncounterIdAndType')?.viewName}`,
    options: { key: [encounterId, 'bill'], include_docs: true },
  })]);
}

/**
 * Returns a List of claims with the associated Bills and Encounters and bill items by timeframe
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Tiemstamp for end of range
 * @returns {DataView}
 */
function getClaimsWithBillsBillItemsAndEncounters(startkey?: number, endkey?: number): DataView {
  const type = 'claim';
  return mapFromJS({
    view: `${queryMapping.get('allByTimestamp')?.viewName}`,
    options: { startkey: [type, startkey], endkey: [type, endkey], include_docs: true },
    fetchFromValues: BILLS_BILL_ITEMS_ENCOUNTERS_BY_BILL_ID,
    fetchFromValuesKeys: ['bill_id'],
    fetchFromValuesFunc: 'KEY_TO_KEY_BILL_ITEM',
  });
}

/**
 * Returns a List of claims for a specific coverage payor by timestamp with the associated Bills and Encounters and bill items
 * @param {string} coveragePayorId Id of the coverage payor to filter by.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Tiemstamp for end of range
 * @param {List<string>} claimIds List of claim ids
 * @returns {DataView}
 */
export function getClaimByCoveragePayorWithBillsBillItemsAndEncounters(
  coveragePayorId: string, startkey?: number, endkey?: number,
): DataView {
  return mapFromJS({
    view: `${queryMapping.get('claimsByCoveragePayorAndTimestamp')?.viewName}`,
    options: {
      startkey: [coveragePayorId, startkey],
      endkey: [coveragePayorId, endkey],
      include_docs: true,
    },
    fetchFromValues: BILLS_BILL_ITEMS_ENCOUNTERS_BY_BILL_ID,
    fetchFromValuesKeys: ['bill_id'],
    fetchFromValuesFunc: 'KEY_TO_KEY_BILL_ITEM',
  });
}

/**
 * A view for fetching encounters and encounters by last event time.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Tiemstamp for end of range
 * @returns {DataView}
 */
function getEncountersByLastEventTimeWithBills(startkey?: number, endkey?: number): DataView {
  return mapFromJS({
    view: `${queryMapping.get('encountersByLastEventTime')?.viewName}`,
    options: { startkey, endkey, include_docs: true },
    fetchFromValues: ALL_BY_ENCOUNTER,
    fetchFromValuesFunc: 'KEY_TO_KEY_BILL',
    fetchFromValuesKeys: ['_id'],
  });
}

/**
 * A view for fetching encounters and encounters by first event time.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Tiemstamp for end of range
 * @returns {DataView}
 */
function getEncountersByFirstEventTimeWithBills(startkey?: number, endkey?: number): DataView {
  return mapFromJS({
    view: `${queryMapping.get('encountersByFirstEventTime')?.viewName}`,
    options: { startkey, endkey, include_docs: true },
    fetchFromValues: ALL_BY_ENCOUNTER,
    fetchFromValuesFunc: 'KEY_TO_KEY_BILL',
    fetchFromValuesKeys: ['_id'],
  });
}

/**
 * A view for fetching all docs for the given patientID.
 * @param {string} patientID A patient ID.
 * @returns {DataView}
 */
function getPatientRelatedDocs(patientID: string): DataView {
  return mapFromJS({
    view: `${queryMapping.get('patientRelatedDocs')?.viewName}`,
    options: { key: patientID, include_docs: true },
  });
}

/**
 * A view for fetching all docs for the given billID.
 * @param {string} billID A patient ID.
 * @returns {DataView}
 */
function getBillRelatedDocs(billID: string): DataView {
  return mapFromJS({
    view: `${queryMapping.get('allByBillIdAndType')?.viewName}`,
    options: { key: [billID, '_any'], include_docs: true },
  });
}

/**
 * A view for fetching all ProcedureRequest docs.
 * @returns {List<DataView>}
 */
export function getProcedureRequestDocs(): List<DataView> {
  return List([mapFromJS({ view: `${queryMapping.get('allByType')?.viewName}`, options: { key: 'procedure_request', include_docs: true } })]);
}


/**
 * Returns a List of payments with the associated Bills and Encounters by timeframe
 * @param {number | null} startkey Timestamp for start of range
 * @param {number | null} endkey Tiemstamp for end of range
 * @returns {DataView}
 */
function getPaymentsWithBillsAndEncounters(startkey?: number | null,
  endkey?: number | null): DataView {
  const type = 'payment';
  return mapFromJS({
    view: `${queryMapping.get('allByTimestamp')?.viewName}`,
    options: { startkey: [type, startkey], endkey: [type, endkey], include_docs: true },
    fetchFromValues: BILLS_BY_ID_WITH_ENCOUNTERS,
    fetchFromValuesKeys: ['bill_id'],
  });
}

/**
 * A view for fetching all supplier docs.
 * @returns {DataView}
 */
function getSupplierDocs(): DataView {
  return mapFromJS({
    view: `${queryMapping.get('allByType')?.viewName}`,
    options: { key: 'supplier', include_docs: true },
  });
}

/**
 * A view for fetching all supply docs.
 * @returns {DataView}
 */
function getSupplyDocs(): DataView {
  return mapFromJS({ view: `${queryMapping.get('allByType')?.viewName}`, options: { key: 'supply', include_docs: true } });
}

/**
 * A view for fetching all SupplyItem docs.
 * @returns {DataView}
 */
export function getSupplyItemDocs(): DataView {
  return mapFromJS({ view: `${queryMapping.get('allByType')?.viewName}`, options: { key: 'supply_item', include_docs: true } });
}

/**
 * A view for fetching all claim invoice docs.
 * @returns {DataView}
 */
function getClaimInvoiceDocs(): DataView {
  return mapFromJS({ view: `${queryMapping.get('allByType')?.viewName}`, options: { key: 'claim_invoice', include_docs: true } });
}

/**
 * A view for fetching all claim recon and payment docs.
 * @returns {DataView}
 */
function getClaimReconPaymentDocs(): DataView {
  return mapFromJS({
    view: `${queryMapping.get('allByType')?.viewName}`,
    options: { key: 'claim_invoice_payment', include_docs: true },
    fetchFromValues: DOCS_BY_ID,
    fetchFromValuesKeys: ['claim_recon_id'],
  });
}

/**
 * A view for fetching all claim invoice payments and claim recon docs for the given claimInvoiceId.
 * @param {string} claimInvoiceId A claim invoice ID.
 * @returns {DataView}
 */
function getClaimReconPaymentsForInvoice(claimInvoiceId: string): DataView {
  return mapFromJS({
    view: `${queryMapping.get('allByInvoiceIdAndType')?.viewName}`,
    options: { key: [claimInvoiceId, 'claim_invoice_payment'], include_docs: true },
    fetchFromValues: DOCS_BY_ID,
    fetchFromValuesKeys: ['claim_recon_id'],
  });
}

/**
 * A view for fetching all the dispensation docs filtered by time.
 * @param {number} startTime Timestamp for start of range
 * @param {number} endTime Timestamp for end of range
 * @returns {DataView}
 */
export function getDispensationByTimeDataViews(startTime: number, endTime: number): DataView {
  return mapFromJS({
    view: `${queryMapping.get('allByTimestamp')?.viewName}`,
    options: { startkey: ['transaction', startTime], endkey: ['transaction', endTime], include_docs: true },
    fetchFromValues: BILL_ITEMS_BY_ID_WITH_BILLS,
    fetchFromValuesFunc: 'KEY_FOR_BILL_ITEM_TRANSACTION',
    fetchFromValuesKeys: ['source_id'],
  });
}

/**
 * Get views required for the Accounts Receivable section of the app.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Timestamp for end of range
 * @returns {List<DataView>}
 */
export const getAccountsReceivableDataViews =
  (startkey: number, endkey: number): List<DataView> => List([getClaims(startkey, endkey)]);

/**
 * Get views required for the Consult reports section of the app.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Timestamp for end of range
 * @returns {List<DataView>}
 */
export const getConsultReportsDataViews =
  (startkey: number, endkey: number): List<DataView> =>
    List([getEncountersByFirstEventTimeWithBills(startkey, endkey)]);

/**
 * Get views required for the patient section of the app.
 * @param {string} patientID Patient ID
 * @returns {List<DataView>}
 */
export const getPatientDataViews =
  (patientID: string): List<DataView> => List([getPatientRelatedDocs(patientID)]);

/**
 * Get views required for Supplier data.
 * @returns {List<DataView>}
 */
export const getSupplierDataViews = (): List<DataView> => List([getSupplierDocs()]);

/**
 * Get views required for SupplyItem data.
 * @returns {List<DataView>}
 */
export const getSupplyItemDataViews = (): List<DataView> => List([getSupplyItemDocs()]);

/**
 * Get views required for Cost Report data.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Timestamp for end of range
 * @returns {List<DataView>}
 */
export const getCostReportDataViews = (startkey: number, endkey: number): List<DataView> =>
  List([getBills(startkey, endkey)]);

/**
 * A view for fetching all transactions based on the start and end time
 * @param {number} startTime start time
 * @param {number} endTime end time
 * @returns {DataView}
 */
export function getTimeFilteredTransactions(startTime: number, endTime: number): DataView {
  const query = {
    start_time: startTime,
    end_time: endTime,
  };
  return mapFromJS({
    api: 'TIME_FILTERED_TRANSACTION_DOCS',
    doc_type: 'transaction',
    query,
  });
}

/**
 * Get views required for transaction data.
 * @param {number} startTime start time
 * @param {number} endTime end time
 * @returns {List<DataView>}
 */
export const getStockReportDataViews = (startTime: number, endTime: number): List<DataView> =>
  List([getTimeFilteredTransactions(startTime, endTime),
    getSupplierDocs(), getSupplyItemDocs(), getSupplyDocs()]);

/**
 * Get views required for transaction data.
 * @param {number} startTime Timestamp for start of range
 * @param {number} endtime Timestamp for end of range
 * @returns {List<DataView>}
 */
export const getInventoryDataViews = (startTime: number, endtime: number): List<DataView> => List([
  getSupplierDocs(), getSupplyItemDocs(), getSupplyDocs(),
  getDispensationByTimeDataViews(startTime, endtime),
]);

/**
 * Get views required for operations summary data.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Timestamp for end of range
 * @returns {List<DataView>}
 */
export const getOperationsSummaryDataViews =
(startkey: number, endkey: number): List<DataView> => List([
  getEncountersByLastEventTimeWithBills(startkey, endkey),
  getSupplyItemDocs(),
  getSupplyDocs(),
]);

/**
 * Get views required for the Add Claim Invoice section of the app.
 * @param {number} startkey Timestamp for start of range
 * @param {number} endkey Timestamp for end of range
 * @returns {List<DataView>}
 */
export function getAddClaimInvoiceDataViews(startkey: number, endkey: number): List<DataView> {
  return List([getClaimsWithBillsBillItemsAndEncounters(startkey, endkey), getClaimInvoiceDocs()]);
}

/**
 * Get views required for claims invoices view. Currently this does no filtering as the filter
 * needed is more complex than couch can handle and the number of ClaimInvoice docs is likely to be
 * small enough to not cause performance issues.
 * @returns {List<DataView>}
 */
export const getClaimInvoicesDataViews = (): List<DataView> =>
  List([getClaimInvoiceDocs(), getClaimReconPaymentDocs()]);

/**
 * Gets the views needed for the outstanding payments page. This includes any non-void Receivable
 * with an amount_due > 0.
 * @returns {List<DataView>}
 */
export const getOutstandingPaymentsDataViews = (): List<DataView> => List([mapFromJS({ view: `${queryMapping.get('outstandingReceivablesByTimestamp')?.viewName}`, fetchFromValues: DOCS_BY_ID, fetchFromValuesKeys: ['bill_id'], options: { include_docs: true } })]);

/**
 * Gets the views needed for a claim invoice view. This is a single document at the moment, hence
 * the only field in the view being id.
 * @param {string} claimInvoiceId Claim invoice to fetch
 * @returns {List<DataView>}
 */
export const getClaimInvoiceDataViews = (claimInvoiceId: string): List<DataView> =>
  List([mapFromJS({ _id: claimInvoiceId }), getClaimReconPaymentsForInvoice(claimInvoiceId)]);

/**
 * Get views required for payment history.
 * @param {number | null} startkey Timestamp for start of range
 * @param {number | null} endkey Timestamp for end of range
 * @returns {List<DataView>}
 */
export function getPaymentHistoryDataViews(startkey?: number | null,
  endkey?: number | null): List<DataView> {
  return List([getPaymentsWithBillsAndEncounters(startkey, endkey)]);
}

/**
 * A view for fetching all inventory items that have not been mapped to the master list.
 * @returns {List<DataView>}
 */
export function getInventoryMappingDataViews(): List<DataView> {
  const query = {
    ignore_voided: false,
    prescribed_only: false,
  };
  return List([mapFromJS({
    api: 'DRUG_MAPPINGS',
    doc_type: 'inventory_map',
    params: {
      clinicID: getClinicID(),
    },
    query,
  })]);
}

/**
 * A view for fetching all precribed inventory items that have not been mapped to the master list.
 * @returns {List<DataView>}
 */
export function getPrescribedInventoryMappingDataViews(): List<DataView> {
  const query = {
    ignore_voided: false,
    prescribed_only: true,
  };
  return List([mapFromJS({
    api: 'PRESCRIBED_DRUG_MAPPINGS',
    doc_type: 'inventory_map',
    params: {
      clinicID: getClinicID(),
    },
    query,
  })]);
}

/**
 * A view for fetching all prescribed inventory drug items.
 * @returns {List<DataView>}
 */
export function getPrescribedInventoryDrugDataViews(): List<DataView> {
  const query = {
    ignore_voided: false,
    prescribed_only: true,
  };
  return List([mapFromJS({
    api: 'DRUG_CLINIC',
    doc_type: 'drug_clinic',
    params: {
      clinicID: getClinicID(),
    },
    query,
  })]);
}

/**
 * A view for fetching master drug data for the provided Ids.
 * @param {Array<string>} masterDrugIds ids to fetch
 * @returns {List<DataView>}
 */
export function getMasterDrugDataViews(masterDrugIds: List<string>): List<DataView> {
  return List([(mapFromJS({
    api: 'DRUG_MASTER',
    data: { filter: { _ids: masterDrugIds.toArray() } },
    doc_type: 'master_drug',
  }) as DataView)]);
}

/**
 * A view for fetching master drug data for the provided filter properties.
 * @param {object} filterParams parameters object required for this search.
 * @returns {List<DataView>}
 */
export function getSearchMasterDrugDataViews(filterParams: object): List<DataView> {
  return List([(mapFromJS({
    api: 'DRUG_MASTER_SEARCH',
    data: filterParams,
    doc_type: 'master_drug_id',
  }) as DataView)]);
}

/**
 * A view for fetching all prescribed inventory drug items.
 * @returns {List<DataView>}
 */
export function getVerifiedDrugDataView(): List<DataView> {
  const query = {
    ignore_voided: false,
    prescribed_only: false,
    verified_only: true,
  };
  return List([mapFromJS({
    api: 'DRUG_CLINIC',
    doc_type: 'drug_clinic',
    params: {
      clinicID: getClinicID(),
    },
    query,
  })]);
}

/**
 * A view for fetching all prescribed inventory drug items.
 * @returns {List<DataView>}
 */
export function getCompletedSuggestionDrugDataView(): List<DataView> {
  const query = {
    has_completed: true,
  };
  return List([mapFromJS({
    api: 'DRUG_SUGGESTION',
    doc_type: 'drug_master_suggestion',
    params: {
      clinicID: getClinicID(),
    },
    query,
  })]);
}

/**
 * A view for fetching all the patient campaign sets.
 * @param {boolean} includeStats If true, calls in campaign set endpoint with `include_stats=true`
 * @param {string} subtype of campaign set to fetch. Returns both sets if undefined.
 * @returns {List<DataView>}
 */
export function getPatientCampaignSetDataViews(
  includeStats: boolean,
  subtype?:'medadvisor' | 'klinify',
): List<DataView> {
  const query = {
    include_stats: includeStats,
    subtypes: subtype ? JSON.stringify([subtype]) : '',
  };
  return List([(mapFromJS({
    api: 'PATIENT_CAMPAIGN_SETS',
    doc_type: 'patient_campaign_set',
    query,
    mock: false,
  }))]);
}

/**
 * A view for fetching all the patient campaign docs.
 * @param {string} campaignSetId campaignSetId of parent set
 * @param {boolean} includeStats Fetches stats if true
 * @returns {List<DataView>}
 */
export function getPatientCampaignDataViews(
  campaignSetId: string,
  includeStats: boolean,
): List<DataView> {
  const query = {
    include_stats: includeStats,
  };
  return List([
    mapFromJS({
      view: null,
      _id: campaignSetId,
    }),
    (mapFromJS({
      api: 'PATIENT_CAMPAIGN_LIST',
      doc_type: 'patient_campaign',
      query,
      params: {
        campaignSetId,
      },
      mock: false,

    // Temporary fix
    // TODO: Revert and use /campaigns endpoint to fetch all the jobs check, FRON-708
    // fetchFromValues: DOCS_BY_ID,
    // fetchFromValuesKeys: ['filter'],
    // fetchFromValuesFunc: 'KEY_FOR_SPECIFIC_CAMPAIGN',
    })),
  ]);
}

/**
 * A view for fetching all the campaign jobs doc
 * @param {string} campaignSetID campaign set ID
 * @param {string} campaignID campaign ID.
 * @returns {List<DataView>}
 */
export function getCampaignJobsDataViews(campaignSetID: string, campaignID: string): List<DataView> {
  return List([
    mapFromJS({
      view: null,
      _id: campaignSetID,
    }),
    mapFromJS({
      api: 'PATIENT_CAMPAIGN',
      doc_type: 'patient_campaign',
      single: true,
      params: {
        campaignID,
      },
      mock: false,
    }),
    mapFromJS({
      api: 'PATIENT_CAMPAIGN_JOBS',
      doc_type: 'campaign_job',
      params: {
        campaignID,
      },
      fetchFromValues: mapFromJS({
        view: `${queryMapping.get('patientRelatedDocs')?.viewName}`,
      }),
      fetchFromValuesKeys: ['patient_id'],
      mock: false,
    })]);
}

/**
 * A view for fetching detail of the campaign sms job
 * @param {string} campaignID campaign ID.
 * @param {string} jobID sms Job ID.
 * @param {string} campaignSetID campaign set id
 * @returns {List<DataView>}
 */
export function getSMSJobDataViews(campaignID: string, jobID: string,
  campaignSetID: string): List<DataView> {
  return List([
    mapFromJS({
      view: null,
      _id: campaignSetID,
    }),
    mapFromJS({
      api: 'PATIENT_CAMPAIGN',
      doc_type: 'patient_campaign',
      single: true,
      params: {
        campaignID,
      },
      mock: false,
    }),
    mapFromJS({
      api: 'PATIENT_CAMPAIGN_SINGLE_JOB',
      doc_type: 'campaign_job',
      single: true,
      params: {
        campaignID,
        jobID,
      },
      fetchFromValues:
        mapFromJS({
          view: `${queryMapping.get('patientRelatedDocs')?.viewName}`,
        }),
      fetchFromValuesKeys: ['patient_id'],
      mock: false,
    }),
  ]);
}

export const DRUG_MANUFACTURERS = List([mapFromJS({
  api: 'DRUG_MANUFACTURERS',
  doc_type: 'drug_manufacturer',
})]);

/**
 * A view for fetching clinic list from the backend
 * @param {IntervalString} interval interval
 * @param {string} clinicId clinicId
 * @param {boolean} fetchClinicList fetchClinicList
 * @returns {List<DataView>}
 */
export function getDashboardDataViews(interval?: IntervalString,
  clinicId?: string, fetchClinicList: boolean = false): List<DataView> {
  const dashboardStatView = interval ? {
    api: clinicId ? 'DASHBOARD_STATS' : 'DASHBOARD_STATS_ALL',
    doc_type: 'dashboard_stat',
    params: {
      interval,
      clinicId,
    },
    mock: false,
  } : null;

  const clinicView = fetchClinicList ? List([mapFromJS({
    api: 'CLINIC_LIST',
    doc_type: 'clinic',
    mock: false,
  })]) : List();

  return clinicView.concat(
    dashboardStatView ? List([mapFromJS(dashboardStatView)]) : List(),
  );
}

/**
 * A view for fetching all the required docs for LabRequestsList.
 * @param {Model} doc Timestamp for start of range
 * @returns {DataView}
 */
export function getLabRequestsListDataViews(doc: Model): List<DataView> {
  if (doc.get('type') === 'encounter') {
    return List([mapFromJS({
      view: null,
      _id: doc.get('patient_id'),
    })]);
  }
  if (doc.get('type') === 'bill_item') {
    return List([mapFromJS({
      view: null,
      options: { keys: [doc.get('patient_id'), doc.get('bill_id')], include_docs: true },
      fetchFromValues: DOCS_BY_ID,
      fetchFromValuesKeys: ['encounter_id'],
    })]);
  }
  if (doc.get('type') === 'procedure_request') {
    return List([mapFromJS({
      view: null,
      options: { keys: [doc.get('patient_id'), doc.get('encounter_id')], include_docs: true },
    })]);
  }
  return List([mapFromJS({
    view: null,
    _id: doc.get('procedure_request_id'),
    fetchFromValues: DOCS_BY_ID,
    fetchFromValuesKeys: ['patient_id', 'encounter_id'],
  })]);
}

/**
 * Get views required for SalesItemForm.
 * @param {string} billId Bill to get bill items for
 * @returns {List<DataView>}
 */
export const getSalesItemsFormDataview = (billId: string): List<DataView> =>
  List([getBillItemsByBill(billId)]);

/**
 * @param {string} billId Bill to get bill items for
 * @returns {List<DataView>}
 */
export const getByTypeFromBillIdsFormDataview = (type: 'payment' | 'bill', ...billIds: string[]): List<DataView> =>
  List([getTypeByBillIds(type, ...billIds)]);

/**
 * Get views required for BillRegenerationForm.
 * @param {string} billId Bill to get bill items for
 * @returns {List<DataView>}
 */
export const getBillGenerationFormDataview = (billId?: string): List<DataView> =>
  (billId ? List([getBillRelatedDocs(billId)]) : List());

/**
 * A view for fetching all the patient campaign sets.
 * @param {Object} data If true, calls in campaign set endpoint with `include_stats=true`
 * @returns {List<DataView>}
 */
export const getCampaignsFilterDataview = (data: {
  keys?: Array<string>;
  filter?: any; // campaign metadata
  filterType?: string // 'and' | 'or'. Defaults to 'and'
}): List<DataView> => List([
  mapFromJS({
    api: 'FILTERED_CAMPAIGN_JOBS',
    doc_type: 'campaign_job',
    data,
  }),
]);

/**
 * A view for fetching all export docs.
 * @returns {DataView}
 */
export const getExportDocsDataView = (): List<DataView> =>
  List([
    mapFromJS({
      view: `${queryMapping.get('allByType')?.viewName}`,
      options: { keys: ['export', 'exports_remaining'], include_docs: true },
    }),
  ]);

/**
 * A view for fetching all export docs.
 * @returns {DataView}
 */
export const getDocById = (docId: string | string[]): Promise<any> => {
  let keys = `"${docId}"`;
  if (Array.isArray(docId)) {
    keys = docId.map(id => `"${id}"`).join(',');
  }
  return fetchWithRetry(`/db/klinifydev/_all_docs?include_docs=true&keys=[${keys}]`, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({
      filter: {
        name,
      },
    }),
  }).then((response) => {
    handleUnauthorisedApiResponse(response.status);
    if (response.ok) {
      return response.json().then((json: any) => {
        const { rows } = json;
        return Promise.resolve(({ rows, error: false }));
      });
    }
    if (response && response.status !== 401 && response.status !== 200) {
      handleApiError({
        error: response.json ? response.json().then((json: any) => Promise.resolve(json)) : response,
        message: 'Get docs api failed',
      });
    }
    return Promise.resolve({
      error: true,
      msg: '',
    });
  })
    .catch((error) => {
      handleApiError({
        error,
        message: 'Get docs api failed',
      });
      // return error so there is no unresolved promise
      return Promise.resolve({
        error: true,
        msg: '',
      });
    });
}