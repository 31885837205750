import { connect } from 'react-redux';
import { List } from 'immutable';

import AsyncFetch from './../components/asyncFetch';
import ExpiryDates from './../components/inventory/expiryDates';
import { getSupplyItemDataViews } from './../dataViews';
import { setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateInventoryItemCount, setExpiryDatesFilter, setCurrentDataViewsError } from './../actions';
import { saveFactory } from './../utils/redux';
import { mapDrug } from '../utils/inventory';
import TransactionModel from './../models/transactionModel';

import type { Dispatch, State, DataView } from './../types';
import APIError from '../utils/apiError';
import BaseModel from '../models/baseModel';

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  drugs,
  currentDataViewsModels,
  isFetching,
  currentDataViews,
  inventoryCount,
  reportsFilter,
  currentDataViewsError,
  inventoryCountSyncStatus,
  isSKUCountSyncing,
}: State) => ({
  drugMap: mapDrug(drugs),
  inventoryCount,
  supplyItems: isFetching ? List() : currentDataViewsModels.filter(m => m.get('type') === 'supply_item'),
  isFetching,
  containerDataViews: getSupplyItemDataViews(),
  currentDataViews,
  filter: reportsFilter.get('expiryDates'),
  currentDataViewsError,
  inventoryCountSyncStatus,
  isSKUCountSyncing,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  adjustInventory:
  (SKUID: string, change: number, notes: string | undefined, supplyItemId: string) => {
    const model = new TransactionModel({
      sku_id: SKUID,
      supply_item_id: supplyItemId,
      change,
      source_type: 'write_off',
      notes,
      timestamp: new Date().getTime(),
    });
    return saveFactory(dispatch)(model)
      .then(dispatch(updateInventoryItemCount(model.get('sku_id'), model.get('change'))));
  },
  onFilterUpdated: (filter: Map<string, any>) => dispatch(setExpiryDatesFilter(filter)),
});

const ExpiryDatesContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(ExpiryDates));

export default ExpiryDatesContainer;
