import React from 'react';
import { List, Set, is } from 'immutable';

import MenuButton from '../buttons/menuButton';
import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import SelectTable from './../table/selectTable';
import StatelessModal from './../modals/statelessModal';
import RecordPanelPaymentForm from './recordPanelPaymentForm';
import PermissionWrapper from './../permissions/permissionWrapper';
import ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import ClaimReconciliationModel from './../../models/claimReconciliationModel';
import { createPermission, hasPermission } from './../../utils/permissions';
import { renderMultilineContent, renderDate } from './../../utils/tables';
import { filterDropDown, filterDate } from './../../utils/filters';
import { sortByDate } from './../../utils/comparators';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { getConfirmation, convertNumberToPrice, isLoading } from './../../utils/utils';
import { UNICODE } from './../../constants';
import Button from './../buttons/button';

import type { MenuAction } from '../buttons/menuButton';
import type { SaveModels, Column, CustomColumn, User, Model, CellProps } from './../../types';
import type BankModel from './../../models/bankModel';
import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import { debugPrint } from '../../utils/logging';

type PaymentsRow = {
  _id: string,
  invoiceNumber: string,
  panelName: string,
  invoiceMonth: string,
  date: string,
  amountPaid: string,
  paymentType: string,
};

type Props = {
  columns: Array<CustomColumn>,
  claimInvoices: List<ClaimInvoiceModel>,
  coveragePayors: List<CoveragePayorModel>,
  saveModels: SaveModels,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimRecons: List<ClaimReconciliationModel>,
  banks: List<BankModel>,
  user: User,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  paymentTypes: List<PaymentTypeModel>,
};

type State = {
  selectedRows: Array<string>,
  isProcessingAction: boolean,
  paymentModalIsVisible: boolean,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimRecons: List<ClaimReconciliationModel>,
};

/**
 * Table Component displaying all Claim Invoices payments made by panel.
 * @class PanelPaymentsTable
 * @extends {React.Component}
 */
class PanelPaymentsTable extends React.Component<Props, State> {
  /**
   * Creates an instance of PanelPaymentsTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRows: [],
      isProcessingAction: false,
      paymentModalIsVisible: false,
      claimInvoicePayments: this.props.claimInvoicePayments,
      claimRecons: this.props.claimRecons,
    };
  }

  /**
   * sets new docs in state after property change
   * @returns {void}
   */
  setClaimInvoicePayments(): void {
    this.setState({
      claimInvoicePayments: this.props.claimInvoicePayments,
    });
  }

  /**
   * sets new docs in state after property change
   * @returns {void}
   */
  setClaimRecons(): void {
    this.setState({
      claimRecons: this.props.claimRecons,
    });
  }

  /**
   * Check if props changed and update state accordingly
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.claimInvoicePayments, this.props.claimInvoicePayments)) {
      this.setClaimInvoicePayments();
    }
    if (!is(prevProps.claimRecons, this.props.claimRecons)) {
      this.setClaimRecons();
    }
  }

  /**
   * Generates the table rows from the SupplyItemModels.
   * @returns {[PaymentsRow]} The rows of the table
   */
  getRows(): Array<PaymentsRow> {
    return this.state.claimRecons
      .map(cr => ({
        _id: cr.get('_id'),
        paymentRecorded: cr.getCreatedTime(),
        paymentReceived: cr.get('timestamp'),
        panelName: this.getPanelNameforRecon(cr),
        invoiceMonth: this.getInvoiceMonthforRecon(cr),
        invoiceNumber: this.getInvoiceNumbers(cr),
        paymentType: this.getPaymentType(cr),
        bank: cr.get('bank'),
        notes: cr.get('notes'),
        amountPaid: this.getAmountPaid(cr),
      }))
      .toArray();
  }

  /**
   * Gets the panel name specific to the reconciliation document.
   * @param {ClaimReconciliationModel} claimRecon The reconciliation document
   * @returns {string}
   */
  getPanelNameforRecon(claimRecon: ClaimReconciliationModel): string {
    const payor = this.props.coveragePayors.find(p => p.get('_id') === claimRecon.get('coverage_payor_id'));
    return payor && payor !== undefined ? payor.get('name') : UNICODE.EMDASH;
  }

  /**
   * Gets the invoice number of each invoice specific to the reconciliation document.
   * @param {ClaimReconciliationModel} claimRecon The reconciliation document
   * @returns {string}
   */
  getInvoiceNumbers(claimRecon: ClaimReconciliationModel): string {
    const payments = this.state.claimInvoicePayments.filter(p => p.get('claim_recon_id') === claimRecon.get('_id'));
    return payments.map((p) => {
      const invoice = this.props.claimInvoices.find(i => i.get('_id') === p.get('claim_invoice_id'));
      return invoice && invoice !== undefined ? invoice.get('internal_clinic_id') : UNICODE.EMDASH;
    }).toArray().toString()
      .replace(/,/g, '\n') || UNICODE.EMDASH;
  }

  /**
   * Gets the invoice months of each invoice specific to the reconciliation document.
   * @param {ClaimReconciliationModel} claimRecon The reconciliation document
   * @returns {string}
   */
  getInvoiceMonthforRecon(claimRecon: ClaimReconciliationModel): string {
    const payments = this.state.claimInvoicePayments.filter(p => p.get('claim_recon_id') === claimRecon.get('_id'));
    return payments.map((p) => {
      const invoice = this.props.claimInvoices.find(i => i.get('_id') === p.get('claim_invoice_id'));
      return invoice && invoice !== undefined ? invoice.getInvoiceMonth() : UNICODE.EMDASH;
    }).toArray().toString()
      .replace(/,/g, '\n') || UNICODE.EMDASH;
  }

  /**
   * Gets the payment type specific to the reconciliation document.
   * @param {ClaimReconciliationModel} claimRecon The reconciliation document
   * @returns {string}
   */
  getPaymentType(claimRecon: ClaimReconciliationModel): string {
    return claimRecon.get('method') === 'transfer' ? 'bank_transfer' : claimRecon.get('method');
  }

  /**
   * Gets the payments made for the whole reconciliation document, by getting each payment for a invoice.
   * @param {ClaimReconciliationModel} claimRecon The reconciliation document
   * @returns {string}
   */
  getAmountPaid(claimRecon: ClaimReconciliationModel): string {
    const payments = this.state.claimInvoicePayments.filter(p => p.get('claim_recon_id') === claimRecon.get('_id'));
    return payments && payments.size > 0 ?
      payments.map(p => convertNumberToPrice(p.get('amount'))).toArray().toString()
        .replace(/,/g, '\n') : UNICODE.EMDASH;
  }

  /**
   * Gets the selected claim reconciliations.
   * @returns {List<claimReconciliationModel>}
   */
  getSelectedClaimRecons(): List<ClaimReconciliationModel> {
    return this.state.claimRecons.filter(c => this.state.selectedRows.includes(c.get('_id')));
  }

  /**
   * Gets the documents to be updated as voided, includes reconciliations, payments and updates is_void to true.
   * @returns {Array<Model>}
   */
  getDocsToVoid(): Array<Model> {
    const selectedRecons = this.getSelectedClaimRecons().map(cr => cr.set({ is_void: true }));
    const reconIds = selectedRecons.map(cr => cr.get('_id'));
    const payments = this.state.claimInvoicePayments.filter(payment =>
      reconIds.contains(payment.get('claim_recon_id')))
      .map(p => p.set({ is_void: true }));
    return selectedRecons.toArray().concat(payments.toArray());
  }

  /**
   * Handles the MenuButton selection
   * @param {string} value The value selected
   * @returns {void}
   */
  handleMenuSelection(value: string) {
    this.setState({ isProcessingAction: true });
    if (value === 'voidSelected') {
      getConfirmation(translate('confirm_payment_void'))
        .then(
          () => this.props.saveModels(this.getDocsToVoid())
            .then(() => {
              createSuccessNotification(translate('payment_voided'));
              this.setState({
                isProcessingAction: false,
                selectedRows: [],
              });
            })
            .catch((error) => {
              debugPrint(error, 'error');
              createErrorNotification(translate('something_went_wrong'));
              this.setState({ isProcessingAction: false, selectedRows: [] });
            }),
          () => this.setState({ isProcessingAction: false, selectedRows: [] }),
        );
    } else {
      this.setState({ isProcessingAction: false, selectedRows: [] });
    }
  }

  /**
   * Gets the menu actions available to the user.
   * @returns {Array<MenuAction>}
   */
  getMenuActions(): Array<MenuAction> {
    const menuActions = [];
    if (hasPermission(this.props.user,
      List([createPermission('claim_invoice_payment', 'delete')]))) {
      menuActions.push({
        label: translate('void_selected'),
        value: 'voidSelected',
        dataPublic: true,
      });
    }
    return menuActions;
  }

  /**
   * Gets the unique panels names for all invoices for which payment is made.
   * @returns {Array<string>}
   */
  getPanels(): Array<string> {
    return Set(this.state.claimRecons.map((cr) => {
      const payor = this.props.coveragePayors.find(p =>
        p.get('_id') === cr.get('coverage_payor_id'));
      return payor && payor !== undefined ? payor.get('name') : '';
    })).toArray();
  }

  /**
   * Gets the columns headers.
   * @returns {Array<Column>}
   */
  getColumns(): Array<Column> {
    return this.props.columns.map((c) => {
      const defaultColumns = {
        accessor: c.value,
        Header: c.label,
        filterable: c.filterable,
        id: c.value,
        sortMethod: c.sortMethod,
      };
      if (c.value === 'panelName') {
        return {
          ...defaultColumns,
          filterMethod: filterDropDown,
          Filter: ({ filter, onChange }) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%', height: 34 }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">{translate('show_all')}</option>
              {
            this.getPanels().map((panel: string) =>
              <option value={panel}>{panel}</option>)
          }
            </select>,
        };
      } else if (c.value === 'paymentType') {
        return {
          ...defaultColumns,
          filterMethod: filterDropDown,
          Cell: (cellProps: CellProps) => <div className="o-table__cell">{translate(cellProps.value)}</div>,
          Filter: ({ filter, onChange }) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%', height: 34 }}
              value={filter ? filter.value : 'all'}
            >
              <option value="all">{translate('show_all')}</option>
              {
                this.props.paymentTypes.map((p: { label: string, value: string }) => {
                  const name = p.get('name').trim().toLowerCase() === 'transfer' ? 'bank_transfer' : p.get('name');
                  return (
                    <option value={name}>
                      {translate(name)}
                    </option>
                  );
                })
              }
            </select>,
        };
      } else if (c.value === 'paymentReceived' || c.value === 'paymentRecorded') {
        return {
          ...defaultColumns,
          Cell: renderDate,
          sortMethod: sortByDate,
          filterMethod: filterDate,
        };
      }
      return { ...defaultColumns, Cell: c.multilineContent ? renderMultilineContent : undefined };
    });
  }

  /**
   * Resets the state for display of the record payment modal.
   * @returns {void}
   */
  resetModal() {
    this.setState({
      paymentModalIsVisible: false,
      isProcessingAction: false,
    });
  }

  /**
   * Saves a claim reconciliation and related payments.
   * @returns {void}
   */
  onSavePayments() {
    this.resetModal();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div>
        <StatelessModal
          id="invoice-payment-form"
          visible={this.state.paymentModalIsVisible}
          setVisible={() => this.resetModal()}
          noButton
          explicitCloseOnly
          title={translate('record_new_payment')}
          onClose={() => this.resetModal()}
          dataPublicHeader
        >
          <RecordPanelPaymentForm
            onSaveComplete={() =>
              this.onSavePayments()
              }
            coveragePayors={this.props.coveragePayors}
            claimInvoicePayments={this.state.claimInvoicePayments}
            claimInvoices={this.props.claimInvoices}
            banks={this.props.banks}
            saveModels={this.props.saveModels}
            paymentTypes={this.props.paymentTypes}
          />
        </StatelessModal>
        <div className="o-header-actions">
          <div className="u-flex-row u-margin-left--half-ws">
            <MenuButton
              label={translate('actions')}
              className="u-flex-right u-margin-right--half-ws o-button--disabled"
              items={this.getMenuActions()}
              disabled={this.state.isProcessingAction || this.state.selectedRows.length === 0}
              onValueSelected={value => this.handleMenuSelection(value)}
              dataPublic
            />
            <PermissionWrapper permissionsRequired={List([createPermission('claim_invoice_payment', 'create')])} user={this.props.user}>
              <Button
                className="o-button o-button--small u-margin-right--half-ws"
                onClick={() => this.setState({ paymentModalIsVisible: true })}
                dataPublic
              >{ translate('record_new_payment') }
              </Button>
            </PermissionWrapper>
          </div>
        </div>
        <SelectTable
          columns={this.getColumns()}
          data={this.getRows()}
          noDataText={
            translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(this.props.isFetching, 'no_payments_found'))
          }
          loading={this.props.isFetching}
          showPagination
          defaultSorted={[{ id: 'date', desc: true }]}
          getSelectedRows={selectedRows => this.setState({ selectedRows })}
        />
      </div>
    );
  }
}

export default PanelPaymentsTable;
