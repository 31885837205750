import { connect } from 'react-redux';
import { List } from 'immutable';
import SupplyInvoices from './../components/inventory/supplyInvoices';
import type SupplierModel from '../models/supplierModel';
import type { State } from './../types';

type Props = {
  supplierId: string,
};

/**
 * Returns matching supplier using the supplierId passed.
 * @param {string} supplierId supplier id.
 * @param {List<SupplierModel>} suppliers A List of suppliers.
 * @returns {SupplierModel | void} filtered supplier
 */
function getSupplier(supplierId: string,
  suppliers: List<SupplierModel>): SupplierModel | void {
  const supplierList = suppliers.filter(s => s.get('_id') === supplierId);
  if (supplierList && supplierList.size === 1) {
    return supplierList.toArray()[0];
  }
  return undefined;
}

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (
  { suppliers, config }: State,
  { supplierId }: Props,
) => ({
  supplier: getSupplier(supplierId, suppliers),
  config,
});

const SupplyInvoicesContainer = connect(mapStateToProps)(SupplyInvoices);
export default SupplyInvoicesContainer;
