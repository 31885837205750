import { Map } from 'immutable';
import Moment from 'moment';

import type { Action } from './../actions';
import type { MapValue } from './../types';

const DEFAULT_STATE: Map<string, Map<string, MapValue>> = Map({
  consults: Map({
    filterTimeStart: new Moment().minutes(0).hours(0).seconds(0),
    filterTimeEnd: new Moment().minutes(59).hours(23).seconds(59),
    filterDateStart: new Moment(),
    filterDateEnd: new Moment(),
  }),
  addClaimInvoice: Map({
    month: Map({ year: parseInt(Moment().format('YYYY'), 10), month: parseInt(Moment().format('MM'), 10) }),
    coveragePayorID: undefined,
    isModeMultiple: true,
  }),
  claimInvoices: Map({
    month: Map({ year: parseInt(Moment().format('YYYY'), 10), month: parseInt(Moment().format('MM'), 10) }),
    coveragePayorID: undefined,
    filterDateStart: undefined,
    filterDateEnd: undefined,
    invoiceNumber: '',
    isModeMultiple: true,
  }),
  accountsReceivable: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(0).hours(0).seconds(0),
  }),
  drugDispensation: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(0).hours(0).seconds(0),
  }),
  expiryDates: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(0).hours(0).seconds(0),
    hasSetFilterDateStart: false,
    hasSetFilterDateEnd: false,
    isItemsWithoutExpiryHidden: false,
  }),
  payments: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(59).hours(23).seconds(59),
  }),
  operationsSummary: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(0).hours(0).seconds(0),
  }),
  suppliesReceived: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: (new Moment().minutes(0).hours(0).seconds(0))
      .clone()
      .endOf('day'),
  }),
  costReport: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(0).hours(0).seconds(0),
  }),
  inventory: Map({
    filterDateStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateEnd: new Moment().minutes(0).hours(0).seconds(0),
  }),
  labRequests: Map({
    filterDateOrderedStart: new Moment().minutes(0).hours(0).seconds(0),
    filterDateOrderedEnd: new Moment().minutes(0).hours(0).seconds(0),
    filterLastUpdateStart: null,
    filterLastUpdateEnd: null,
  }),
});

/**
 * State reducer. NOTE: Make sure to clone when manipulating as MomentJS is lame and will mutate
 * state when you dont expect it. This will send react into a feedback loop that can get quite nasty
 * @param {Immutable.Map} state The current state.
 * @param {object} action The action
 * @returns {Immutable.Map} The new state
 */
function reportsReducer(
  state: Map<string, MapValue> = DEFAULT_STATE,
  action: Action,
): typeof DEFAULT_STATE {
  switch (action.type) {
    case 'SET_OPERATIONS_SUMMARY_FILTER':
      return state.set('operationsSummary', action.state);
    case 'SET_COST_REPORT_FILTER':
      return state.set('costReport', action.state);
    case 'SET_LAB_REQUESTS_FILTER':
      return state.set('labRequests', action.state);
    case 'SET_SUPPLIES_RECEIVED_FILTER':
      return state.set('suppliesReceived', action.state);
    case 'SET_ACCOUNTS_RECEIVABLE_FILTER':
      return state.set('accountsReceivable', action.state);
    case 'SET_ADD_CLAIM_INVOICE_FILTER':
      return state.set('addClaimInvoice', action.state);
    case 'SET_CLAIM_INVOICES_FILTER':
      return state.set('claimInvoices', action.state);
    case 'SET_CONSULT_REPORTS_FILTER':
      return state.set('consults', action.state);
    case 'SET_DRUG_DISPENSATION_FILTER':
      return state.set('drugDispensation', action.state);
    case 'SET_EXPIRY_DATES_FILTER':
      return state.set('expiryDates', action.state);
    case 'SET_PAYMENT_HISTORY_FILTER':
      return state.set('payments', action.state);
    case 'SET_INVENTORY_REPORTS_FILTER':
      return state.set('inventory', action.state);
    default:
      return state;
  }
}

export default reportsReducer;
