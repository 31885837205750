/* eslint-disable require-jsdoc */
import React from 'react';
import glamorous, { Div } from 'glamorous';
import { List, Map } from 'immutable';
import translate from '../../utils/i18n';
import CampaignRulesFormSection from './campaignRulesFormSection';
import Input from '../inputs/input';
import Keypress from '../keypress';
import ModalFooter from '../modals/modalFooter';
import SaveButton from '../buttons/saveButton';
import TextArea from '../inputs/textarea';
import Radio from '../inputs/radio';
import FormError from '../formError';
import { createSuccessNotification } from '../../utils/notifications';
import { colours, wsUnit } from '../../utils/css';
import { prefixString } from '../../utils/utils';

import PatientCampaignModel, { CampaignRuleType, TimeUnit } from '../../models/patientCampaignModel';
import DrugModel from '../../models/drugModel';
import SalesItemModel from '../../models/salesItemModel';
import type PractitionerModel from '../../models/practitionerModel';

import type { CampaignRulesCondition } from '../../models/patientCampaignModel';
import type { SaveModel, Config, ScheduleAt, User, EnrolAt } from '../../types';
import type PatientCampaignSetModel from '../../models/patientCampaignSetModel';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission } from '../../utils/permissions';
import type MasterDrugModel from '../../models/masterDrugModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import { getUpdateConfirmation } from '../../utils/patientCampaign';
import { covertDateOffsetToDays } from '../../utils/time';
import getCampaignDefaultAttr from '../../utils/campaign';
import MasterCampaignModel from '../../models/masterCampaignModel';

const DeleteButton = glamorous.button({
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem',
  backgroundColor: colours.grey3,
  margin: `0 calc(-1.5 * ${wsUnit})`,
  width: `calc(100% + (2 * 1.5 * ${wsUnit}))`,
});

type CustomState = {
  name: string | number,
  status: string,
  messageTemplate: string,
  campaignRulesAttributes: {
    scheduleValue: number,
    scheduleUnit: TimeUnit,
    enrolmentValue: number,
    enrolmentUnit: TimeUnit,
    target: 'prospective' | 'retrospective',
    filterConditions: List<CampaignRulesCondition>,
    logicalOp: 'or' | 'and',
    campaignJobId?: string,
    masterCampaignJobId?: string,
    jobType: string,
  },
}
type State = CustomState & {
  errorMessage: string,
  isSaving: boolean,
}
type Props = {
  patientCampaign?: PatientCampaignModel,
  saveModel: SaveModel,
  onSaveClicked: () => void,
  disabled: boolean,
  config: Config,
  klinifyConfig: Config,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  practitioners: List<PractitionerModel>,
  isReadOnly: boolean,
  user: User,
  selectedCampaignSet: PatientCampaignSetModel,
  masterDrugModelsMap:Map<string, MasterDrugModel>,
  readOnlyError?: string,
  encounterFlows: List<EncounterFlowModel>,
  masterCampaignModels: Map<string, MasterCampaignModel | PatientCampaignModel>,
  patientCampaigns: Map<string, PatientCampaignModel>,
  disableProspective?: boolean,
  jobs: Map<string, PatientCampaignModel>,
};

const SMS_PREFIX = translate('sms_prefix');
const SMS_CUTOFF_LENGTH = 160;
/**
 * A SMSCampaignForm component.
 * @class SMSCampaignForm
 * @extends {React.Component<Props, State>}
 */
class SMSCampaignForm extends React.Component<Props, State> {
  /**
   * Creates an instance of SalesItemForm.
   * @param {Props} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    const state: CustomState = this.propsToState(props);
    this.state = {
      errorMessage: '',
      isSaving: false,
      ...state,
    };
  }

  /**
   * Returns the original state using props passed to the component.
   * @param {Props} props props.
   * @returns {CustomState}  The original/initial state for some of the values
   */
  propsToState(props: Props): CustomState {
    const { patientCampaign } = props;
    const { scheduleValue, scheduleUnit, ...rest } =
      getCampaignDefaultAttr(patientCampaign);
    const enrolmentValue = props.disableProspective && !rest.enrolmentValue ?
      scheduleValue : rest.enrolmentValue;
    const enrolmentUnit = props.disableProspective && !rest.enrolmentUnit ?
      scheduleUnit : rest.enrolmentUnit;
    const target = props.disableProspective ? 'retrospective' : rest.target;
    return {
      name: patientCampaign ? patientCampaign.get('name', '') : '',
      campaignRulesAttributes: {
        ...rest,
        logicalOp: patientCampaign ? patientCampaign.get('filter').logical_op : 'and',
        scheduleUnit: scheduleUnit || 'day',
        scheduleValue,
        enrolmentUnit: enrolmentUnit || 'day',
        enrolmentValue,
        target,
      },
      status: patientCampaign ? patientCampaign.getStatus() : 'active',
      messageTemplate: patientCampaign ? patientCampaign.get('message', '') : '',
    };
  }

  /**
   * Calls when form rules change
   * @param {Props} rules props.
   * @returns {void}  The original/initial state for some of the values
   */
  onRulesFormFieldChange({ filters, logicalOp }: {filters: Array<CampaignRulesCondition>, logicalOp: 'and' | 'or'}) {
    this.setState({
      campaignRulesAttributes: {
        ...this.state.campaignRulesAttributes,
        filterConditions: List(filters),
        logicalOp,
      },
    });
  }

  /**
   * Returns the form schedule change.
   * @param {string} newValue props.
   * @returns {void} The original/initial state for some of the values
   */
  onScheduleFormFieldChange({ scheduleValue, scheduleUnit }: ScheduleAt) {
    this.setState({
      campaignRulesAttributes: {
        ...this.state.campaignRulesAttributes,
        scheduleValue,
        scheduleUnit,
        ...this.props.disableProspective && {
          enrolmentValue: scheduleValue,
          enrolmentUnit: scheduleUnit,
        },
      },
    });
  }

  /**
   * Returns the form target patient change.
   * @param {string} target props.
   * @returns {void} The original/initial state for some of the values
   */
  onTargetPatientFormFieldChange(target : 'prospective' | 'retrospective') {
    this.setState({
      campaignRulesAttributes: {
        ...this.state.campaignRulesAttributes,
        target,
      },
    });
  }

  /**
   * Returns the form enrolment change.
   * @param {string} newValue props.
   * @returns {void} The original/initial state for some of the values
   */
  onEnrolmentFormFieldChange({ enrolmentValue, enrolmentUnit }: EnrolAt) {
    this.setState({
      campaignRulesAttributes: {
        ...this.state.campaignRulesAttributes,
        enrolmentValue,
        enrolmentUnit,
      },
    });
  }

  /**
   * Returns the form schedule change.
   * @param {string | number} name props.
   * @returns {void} The original/initial state for some of the values
   */
  onNameFormFieldChange(name: string | number) {
    this.setState({ name });
  }

  /**
   * Check validation of the campaign form
   * @returns {boolean} The original/initial state for some of the values
   */
  isValid() {
    const {
      name,
      status,
      messageTemplate,
    } = this.state;
    const {
      scheduleUnit,
      scheduleValue,
      enrolmentUnit,
      enrolmentValue,
      target,
      logicalOp,
      filterConditions,
    } = this.state.campaignRulesAttributes;
    if (!name) {
      this.setState({ errorMessage: translate('please_enter_valid_campaign_name') });
      return false;
    }
    if (!name || isNaN(scheduleValue) || scheduleValue === null || !scheduleUnit) {
      this.setState({ errorMessage: translate('please_ensure_schedule_have_been_filed') });
      return false;
    }
    if (target === 'retrospective' && (isNaN(enrolmentValue) || enrolmentValue === null || !enrolmentUnit)) {
      this.setState({ errorMessage: translate('please_ensure_schedule_have_been_filed') });
      return false;
    }
    if (logicalOp && filterConditions.size &&
        !filterConditions.every(r => (r.type &&
          r.type === CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME
          ? Boolean(r.metadata.encounter_time_offset && r.metadata.comparator)
          : Object.values(r.metadata).length !== 0))) {
      this.setState({ errorMessage: translate('please_ensure_rules_have_been_filed_with_valid_format') });
      return false;
    }
    if (scheduleValue < 1 || scheduleValue > 365) {
      this.setState({ errorMessage: translate('please_ensure_schedule_value_have_valid_format') });
      return false;
    }
    if (target === 'retrospective' && (enrolmentValue < 1 || enrolmentValue > 365)) {
      this.setState({ errorMessage: translate('please_ensure_enrolment_value_have_valid_format') });
      return false;
    }
    if (!status) {
      this.setState({ errorMessage: translate('please_ensure_status_have_been_selected') });
      return false;
    }
    if (!messageTemplate.substr(0, SMS_PREFIX.length).trim()) {
      this.setState({ errorMessage: translate('please_ensure_message_have_been_filed') });
      return false;
    }
    if (messageTemplate) {
      const matches = messageTemplate.match(/{([^}]*)}/g);
      if (matches && !matches.includes('{patient_name}')) {
        this.setState({ errorMessage: translate('please_ensure_message_template_in_correct_format') });
        return false;
      }
    }
    this.setState({ errorMessage: '' });
    return true;
  }

  /**
   * Handle the saving of the form.
   * @returns {Promise<boolean>}
   */
  saveCampaign() {
    const {
      name,
      campaignRulesAttributes,
      status,
      messageTemplate,
    } = this.state;

    const { scheduleValue, scheduleUnit, enrolmentUnit, enrolmentValue } = campaignRulesAttributes;

    const timeConditionMetadata = (campaignRulesAttributes.target === 'prospective' ||
      (covertDateOffsetToDays(scheduleValue, scheduleUnit) >=
      covertDateOffsetToDays(enrolmentValue, enrolmentUnit)))
      ? {
        encounter_time_offset: {
          time_value: scheduleValue,
          time_unit: scheduleUnit,
        },
        comparator: 'eq',
      }
      : {
        encounter_time_offset_max: {
          time_value: enrolmentValue,
          time_unit: enrolmentUnit,
        },
        encounter_time_offset_min: {
          time_value: scheduleValue,
          time_unit: scheduleUnit,
        },
        comparator: 'btw',
      };

    const filterConditions = campaignRulesAttributes.filterConditions
      .filter(e => e.type !== CampaignRuleType.ENCOUNTER_TIME)
      .unshift({
        type: CampaignRuleType.ENCOUNTER_TIME,
        metadata: timeConditionMetadata,
      }).toArray();
    const properties = {
      name,
      filter: {
        logical_op: campaignRulesAttributes.logicalOp,
        conditions: filterConditions,
      },
      is_active: status === 'active',
      message: messageTemplate,
      campaign_set_id: this.props.selectedCampaignSet.get('_id'),
      version: '2.0',
    };
    const campaign = this.props.patientCampaign
      ? this.props.patientCampaign.replaceAtrributes(properties)
      : new PatientCampaignModel(properties);
    this.setState({ isSaving: true });
    return this.props.saveModel(campaign)
      .then(() => {
        this.setState({ isSaving: false });
        this.props.onSaveClicked();
        if (this.props.patientCampaign) {
          createSuccessNotification(translate('succesfully_edited_campaign'));
          return;
        }
        createSuccessNotification(translate('succesfully_saved_campaign'));
      });
  }

  /**
   * Calls isValid and get confirmation from user if need
   * @returns {Promise}
   */
  onSaveClicked() {
    if (!this.isValid()) {
      return Promise.resolve(false);
    }
    if (this.props.patientCampaign &&
      this.props.patientCampaign?.getStatus() !== this.state.status) {
      const action = this.state.status === 'active' ? 'activate' : 'deactivate';
      return getUpdateConfirmation(false, action, () => {
        this.saveCampaign();
      });
    }
    return this.saveCampaign();
  }

  /**
   * Handle campaign deletion
   * @param {PatientCampaignModel} patientCampaign campaign which needs to delete
   * @returns {void}
   */
  handleDeleteCampaign(patientCampaign: PatientCampaignModel) {
    getUpdateConfirmation(false, 'delete', () => {
      this.props.saveModel(patientCampaign.set('hidden', true));
      this.props.onSaveClicked();
    });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { isReadOnly, readOnlyError, klinifyConfig } = this.props;
    const { name, campaignRulesAttributes, messageTemplate } = this.state;
    const isPrefix = klinifyConfig.getIn(['server_setting', 'area_of_operation'], 'malaysia') === 'malaysia';
    const messageValue = prefixString(messageTemplate, isPrefix ? SMS_PREFIX : undefined);
    const messageLength = (messageValue || '').length;
    return (
      <div>
        {
          this.state.errorMessage &&
          <FormError containerElementID="supply-form">
            {this.state.errorMessage}
          </FormError>
        }
        <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
          <Input
            id="name"
            label={translate('campaign_name')}
            placeholder="eg. Medication Reminder"
            value={name}
            onValueChanged={v => this.onNameFormFieldChange(v)}
            required
            disabled={isReadOnly}
          />
          <hr />
          <CampaignRulesFormSection
            rules={campaignRulesAttributes}
            onScheduleChange={value => this.onScheduleFormFieldChange(value)}
            onRulesChange={value => this.onRulesFormFieldChange(value)}
            onTargetPatientChange={value => this.onTargetPatientFormFieldChange(value)}
            onEnrolmentChange={value => this.onEnrolmentFormFieldChange(value)}
            config={this.props.config}
            drugs={this.props.drugs}
            salesItems={this.props.salesItems}
            practitioners={this.props.practitioners}
            errorMessage={this.state.errorMessage}
            disabled={isReadOnly}
            masterDrugModelsMap={this.props.masterDrugModelsMap}
            encounterFlows={this.props.encounterFlows}
            patientCampaigns={this.props.patientCampaigns}
            masterCampaignModels={this.props.masterCampaignModels}
            disableProspective={this.props.disableProspective}
            jobs={this.props.jobs}
          />
          <TextArea
            id="message-template"
            label={translate('message')}
            rows={5}
            disabled={isReadOnly}
            value={messageValue}
            onValueChanged={value => this.setState({ messageTemplate: value })}
            description={klinifyConfig.getIn(['server_setting', 'area_of_operation'], 'malaysia') === 'malaysia' ? translate('sms_template_description', {
              smsPrefix: SMS_PREFIX,
              messageLength,
              cutOffLength: SMS_CUTOFF_LENGTH,
            }) : translate('sms_template_description_for_sg_and_in', {
              messageLength,
              cutOffLength: SMS_CUTOFF_LENGTH,
            })}
          />
          {isReadOnly && readOnlyError ? (
            <Div css={{ color: colours.grey5, lineHeight: 1.5 }} className="u-font-italic">
              {readOnlyError}
            </Div>
          ) : (
            <>
              <Radio
                id="status"
                description="Campaigns set to inactive will neither send SMSes nor queue SMSes up"
                label={translate('campaign_status')}
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
                onValueChanged={(value: string) => this.setState({ status: value })}
                value={this.state.status}
                disabled={isReadOnly}
              />
              {this.props.patientCampaign && (
                <PermissionWrapper permissionsRequired={List([createPermission('sms_campaigns', 'delete')])} user={this.props.user}>
                  <DeleteButton
                    className="o-text-button o-text-button--danger u-full-width"
                    // @ts-ignore
                    onClick={() => this.handleDeleteCampaign(this.props.patientCampaign)}
                  >
                    {translate('delete')}
                  </DeleteButton>
                </PermissionWrapper>
              )}
            </>
          )}
        </Keypress>
        {!isReadOnly && (
        <ModalFooter>
          {!this.props.disabled && (
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('save')}
            onClick={() => this.onSaveClicked()}
          />
          )}
        </ModalFooter>)}
      </div>
    );
  }
}

export default SMSCampaignForm;
