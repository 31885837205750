import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import { updateConfigValue, updateConfig } from '../actions';

import type { Dispatch, State, Config } from '../types';
import Stages from '../components/encounter/stages';
import { saveFactory } from '../utils/redux';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
({ config, user, salesItems, coveragePayors, encounterStages, encounters }: State) => ({
  config,
  user,
  salesItems: Map(salesItems.filter(i => !i.getOrFalse('hidden')).map(s => [s.get('_id'), s])),
  coveragePayors,
  encounterStages: encounterStages.filter(stage => stage.isVisible()),
  currentEncounters: encounters.filter(encounter => !encounter.containsEvent('cancelled') && !encounter.containsEvent('completed')),
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateStrings: (updatedStrings: List<string>) => dispatch(updateConfigValue(['diagnoses', 'options'], updatedStrings)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  saveModel: saveFactory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Stages);
