import * as React from 'react';
import { Link } from 'react-router-dom';

import { trackEvent, CLICK, SIDEBAR_ITEM } from './../../utils/analytics';
import translate from './../../utils/i18n';
import SidebarTextLinkWrapper from './sidebarTextLinkWrapper';
import UnreadIndicator from './unreadIndicator';
import type { HTMLStyle } from './../../types';

type Props = {
  children?: React.ReactNode,
  isSelected: boolean,
  label: string,
  onClick?: () => void,
  url: string,
  imageSrc?: string,
  unreadCount?: number | string,
  iconStyle?: HTMLStyle,
};

/**
 * Creates a SideBarItem
 * @param {Props} props The props for the component.
 * @returns {React.Component} The SideBarItem
 */
const SidebarItem = (props: Props) => {
  const {
    isSelected, label, url, onClick, children, imageSrc, unreadCount,
    iconStyle,
  } = props;
  let className = 'c-sidebar__item';
  if (isSelected) {
    className += ' c-sidebar__item--selected';
  }
  return (
    <Link
      to={url}
      className={className}
      onClick={() => {
        trackEvent(SIDEBAR_ITEM, CLICK, label);
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className="c-sidebar__item__main" data-public>
        <div className="c-sidebar__item__main__icon">
          <img src={imageSrc || `static/images/icon_sidebar_${label}.png`} alt={label} />
        </div>
        <div className="c-sidebar__item__main__text">{translate(label)} <UnreadIndicator unreadCount={unreadCount} iconStyle={iconStyle} /></div>
      </div>
      {
        children &&
        isSelected &&
        <SidebarTextLinkWrapper>{children}</SidebarTextLinkWrapper>
      }
    </Link>
  );
};

export default SidebarItem;
