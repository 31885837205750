/* eslint-disable no-underscore-dangle */
import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import TextArea from './../inputs/textarea';
import SaveButton from './../buttons/saveButton';
import { createSuccessNotification } from './../../utils/notifications';
import ModalFooter from './../modals/modalFooter';

import type EncounterModel from './../../models/encounterModel';
import type { Config } from './../../types';
import type EncounterStageModel from '../../models/encounterStageModel';
import type PractitionerModel from '../../models/practitionerModel';
import { getUpdatedStagesWithNotes } from '../../utils/encounters';

type Props = {
  config: Config,
  encounter: EncounterModel,
  onSave: (encounterModel: EncounterModel) => Promise<void>,
  encounterStageMap: Map<string, EncounterStageModel>,
  practitioners: List<PractitionerModel>,
};

type State = {
  notes: {[stageName: string]: string} | string,
  isSaving: boolean,
}

/**
   * Form for editing of Encounters.
   * @namespace EncounterForm
   */
class EncounterForm extends React.Component<Props, State> {
  /**
   * Instantiate the state of the component.
   * @param {props} props - The props for the component
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      notes: props.encounter.getNotes(props.practitioners),
    };
  }

  /**
   * Called when user clicks save. Updates the current model or
   * creates a new one with the value of state.attributes transfered onto model.attributes. props.onSave
   * is then run with the new/updated encounter as the first param. THE MODEL IS NOT SAVED WITHIN IN THIS
   * FUNCTION.
   * @return {undefined}
   */
  onSaveClicked() {
    this.setState({ isSaving: true });
    this.props.onSave(getUpdatedStagesWithNotes(this.props.encounter, this.state.notes))
      .then(() => {
        createSuccessNotification(translate('encounter_update_success', { encounterLabel: this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter') }));
        this.setState({ isSaving: false });
      });
  }

  /**
   * Renders consult notes field
   * @returns {JSX.Element}
   */
  renderNotes = () => {
    // Render single field if encounter has `notes` field (Old docs)
    if (typeof this.state.notes === 'string') {
      return (
        <TextArea
          id="encounter-notes-text-input"
          label={`${this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter')} ${translate('notes')}`}
          onValueChanged={(value) => {
            this.setState({
              notes: value,
            });
          }}
          value={this.state.notes || ''}
        />
      );
    }
    return this.props.encounter.getActiveStages().map((stageInfo) => {
      const stage = this.props.encounterStageMap.get(stageInfo.stage_id);
      if (stage && typeof this.state.notes !== 'string') {
        const hasStageNotes = this.state.notes[stageInfo.name];
        const isNotesEnabled = stage.get('has_notes');
        if (hasStageNotes || isNotesEnabled) {
          return (
            <TextArea
              key={stageInfo._name}
              id={`encounter-notes-${stageInfo._name}`}
              label={`${stageInfo._name} ${translate('notes')}`}
              onValueChanged={(value) => {
                this.setState({
                  notes: Object.assign(this.state.notes, {
                    [stageInfo._name]: value,
                  }),
                });
              }}
              disabled={!isNotesEnabled}
              value={this.state.notes[stageInfo._name] || ''}
            />
          );
        } return null;
      } return null;
    });
  }


  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <div>
        <section className="o-form">
          {this.renderNotes()}
        </section>
        <ModalFooter>
          <SaveButton
            dataPublic
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </div>
    );
  }
}

export default EncounterForm;
