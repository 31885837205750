import React from 'react';
import glamorous from 'glamorous';
import type { List } from 'immutable';

import Input from './../inputs/input';
import Radio from './../inputs/radio';
import { wsUnit } from './../../utils/css';

import type MetricTypeModel from './../../models/metricTypeModel';

type Props = {
  metricTypeID?: string,
  metricType: MetricTypeModel,
  values: List<string | number>,
  onValueChanged: (value: string | number, index: number) => void,
}

const Form = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  '& .o-form__item, & span': {
    marginRight: `calc(${wsUnit} / 2)`,
  },
});

/**
 * A form for creating the values of a given MetricType. This form does not actually save data and
 * has no state. Instead the values should be passed down and updated to a parent component using
 * props.values and props.onValueChanged.
 * @class MetricTypeForm
 * @extends {React.PureComponent<Props>}
 */
class MetricEntryForm extends React.PureComponent<Props> {
  /**
   * Renders an input matching the datatype of the metricType at the index given.
   * @param {number} index The index of the datatype.
   * @returns {React.Component}
   */
  renderInput(index: number) {
    const id = `input-${this.props.metricType.get('_id')}-${index}`;
    if (index >= this.props.metricType.get('datatype', []).length) {
      // TODO: This input shouldnt exist
      return '';
    }
    const datatype = this.props.metricType.get('datatype', [])[index];
    const inputProps = {
      id,
      label: id,
      hideLabel: true,
      value: this.props.values.get(index, ''),
      onValueChanged: value => this.props.onValueChanged(value, index),
      placeholder: ' ', // The space is because Input switches out empty string placeholders for the label.
      required: true,
    };
    if (this.props.metricTypeID && this.props.metricTypeID === 'ALL') {
      inputProps.required = false;
    }
    if (datatype.type === 'string') {
      if (datatype.range) {
        const options = datatype.range.split(',')
          .map(string => ({ value: string.trim(), label: string.trim() }));
        return <Radio {...inputProps} options={options} />;
      }
      return <Input {...inputProps} />;
    }
    return <Input type="number" {...inputProps} />;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let inputIndex = 0;
    return (
      <div>
        <h1 className="o-label">{this.props.metricType.get('name')}</h1>
        <Form>
          {
            this.props.metricType.getDisplayFormatArray()
              .map((item: string) => {
                if (item === '{v}') {
                  const input = this.renderInput(inputIndex);
                  inputIndex += 1;
                  return input;
                }
                return <span>{item}</span>;
              })
          }
        </Form>
      </div>
    );
  }
}

export default MetricEntryForm;
