import React from 'react';
import { List } from 'immutable';


import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import StringListEditorTable from './stringListEditorTable';
import StringListEditorForm from './stringListEditorForm';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';

import type { User, Config, SaveModel } from './../../types';
import type PatientStubModel from '../../models/patientStubModel';

type Props = {
  items: List<string>,
  label: string,
  type: string,
  updateStrings: (item: List<string>) => Promise<void>,
  permissionName: string, // e.g. 'diagnosis_options', 'symptom_options',
  user: User,
  columnLabel: string,
  config: Config,
  patientStubs?: List<PatientStubModel>,
  saveModel?: SaveModel,
};

type State = {
  modelToEdit?: string,
};

/**
 * Similiar to ListEditor, but for strings rather than models.
 * @namespace StringListEditor
 */
class StringListEditor extends React.PureComponent<Props, State> {
  static defaultProps = {};

  /**
   * Creates an instance of StringListEditor.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = { modelToEdit: undefined };
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <h1 data-public className="o-title">{this.props.label}</h1>
          <div className="o-card u-margin-bottom--4ws">
            <h2 data-public className="o-card__title">{this.props.label}</h2>
            <StringListEditorTable
              {...this.props}
              deleteString={
                (string: string): Promise<void> =>
                  this.props.updateStrings(this.props.items.filter(i => i !== string))
              }
              columnLabel={this.props.columnLabel}
              canDelete={hasPermission(this.props.user, List([createPermission(this.props.permissionName, 'delete')]))}
              canEdit={hasPermission(this.props.user, List([createPermission(this.props.permissionName, 'update')]))}
              onEditClicked={modelToEdit => this.setState({ modelToEdit })}
            />
          </div>
        </section>
        <section className="o-sidebar--right">
          <PermissionWrapper permissionsRequired={List([createPermission(this.props.permissionName, 'create')])} user={this.props.user}>
            <h1 data-public className="o-title u-font-color-white">
              {translate('add_item')}
            </h1>
            <StringListEditorForm
              type={this.props.type}
              config={this.props.config}
              modelToEdit={this.state.modelToEdit}
              clearModelToEdit={() => this.setState({ modelToEdit: undefined })}
              addString={
                (string: string): Promise<void> =>
                  this.props.updateStrings(this.props.items.push(string))
              }
              editString={
                (index, string: string): Promise<void> =>
                  this.props.updateStrings(this.props.items.splice(index, 1, string))
              }
              items={this.props.items}
              patientStubs={this.props.patientStubs}
              saveModel={this.props.saveModel}
            />
          </PermissionWrapper>
        </section>
      </ContentTransition>
    );
  }
}

export default StringListEditor;
