import { connect } from 'react-redux';
import type { List } from 'immutable';

import DocValidation from '../components/modals/docValidationModal';
import { saveFactory, saveModelsFactory } from '../utils/redux';

import type { Dispatch, State, MissingDocObject } from '../types';
import { setDocumentsPendingRegeneration, unsetDocumentsPendingRegeneration } from '../actions';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  config: state.config,
  user: state.user,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  setDocumentsPendingRegeneration: (docs: List<MissingDocObject>) =>
    dispatch(setDocumentsPendingRegeneration(docs)),
  unsetDocumentsPendingRegeneration: (ids: List<string>) =>
    dispatch(unsetDocumentsPendingRegeneration(ids)),
});

const DocumentValidationContainer = connect(mapStateToProps, mapDispatchToProps)(DocValidation);

export default DocumentValidationContainer;
