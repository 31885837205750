import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { List } from 'immutable';

import ErrorBoundary from './../components/errorBoundary';
import CasenoteEditorWithReferral from './../components/casenotes/casenoteEditorWithReferral';
import ContentTransition from './../components/contentTransition';
import PatientActionButton from './../components/patientActionButton/patientActionButton';
import DocumentTitle, { formatTitle } from './../components/documentTitle';
import translate from './i18n';
import Offline from './../components/offline/offlineComponent';
import PermissionWrapper from './../components/permissions/permissionWrapper';

import type { ReactRouterProps, SaveModel, Config, HOCChild, User, PermissionRecord } from './../types';
import type PatientModel from './../models/patientModel';
import type PatientStubModel from './../models/patientStubModel';

interface WrappedRouteProps extends RouteProps {
  component: HOCChild,
  title: string,
  disabledDueToOffline?: boolean,
  permissions?: List<PermissionRecord>,
  user: User,
  exact?: boolean,
  path: string,
}

type PatientRouteProps = {
  component: HOCChild,
  showActionButton?: boolean,
  allowReferral?: boolean,
  exact?: boolean,
  path: string,
  saveModel: SaveModel,
  config: Config,
  klinifyConfig: Config,
  patient: PatientModel,
  patientStub: PatientStubModel,
  isOnline: boolean,
  user: User,
};

/**
 * Returns the query string for the current referral
 * @returns {string}
 */
export function getReferralQueryString(): string {
  if (!location) {
    return '';
  }
  const locationSplit = location.href.split('?');
  if (locationSplit.length < 2) {
    return '';
  }
  const queryParams = parse(locationSplit[1]);
  return `?${stringify({ referralId: queryParams.referralId, referralAssetIndex: queryParams.referralAssetIndex })}`;
}

/**
 * Returns true if route indicates the user is in Referral mode.
 * @returns {boolean}
 */
export function isReferralMode(): boolean {
  if (!location) {
    return false;
  }
  const locationSplit = location.href.split('?');
  if (locationSplit.length < 2) {
    return false;
  }
  const queryParams = parse(locationSplit[1]);
  return queryParams.referralId !== undefined;
}

/**
 * Logs a page view to Google Analytics
 * @param {string} match The matched router path
 * @param {string} title Document title
 * @returns {void}
 */
function logToGoogleAnalytics(match: string, title: string) {
  if (typeof window.ga === 'function') {
    window.ga('set', 'page', match);
    window.ga('set', 'title', formatTitle(title));
    window.ga('send', 'pageview');
  }
}

/**
 * Wraps a route to handle offline checks, document titles, and the pass through of url parameters.
 * @param {WrappedRouteProps} props Props for the route.
 * @returns {React.Component}
 */
export const WrappedRoute = ({
  component: Component,
  title,
  disabledDueToOffline = false,
  permissions = List(),
  user,
  location,
  path,
  ...rest
}: WrappedRouteProps) => {
  const [isDisabledDueToOffline, setDisabled] = React.useState(false);
  /**
    Current route don't need to be hidden even if app goes offline
    This effect listens to the location and onRouteChange, the <OfflineComponent /> is shown.
    The state isDisabledDueToOffline is used for detecting route has changed after app went offline.
   */
  React.useEffect(() => {
    if (disabledDueToOffline) {
      setDisabled(true);
    }
    if (path === '/pharmaconnect/:brand_id') {
      logToGoogleAnalytics(location?.pathname, translate(title));
      return;
    }
    // overview page will be logged inside patientList component.
    // Moved from here to set custom dimensions
    if (path !== '/patient/:patientID' && path !== '/') { // Will be logged by PatientRoute
      logToGoogleAnalytics(path, translate(title));
    }
  }, [location?.pathname]);

  /**
    Listens to the connection status of app here (disabledDueToOffline).
    When we're back online set the isDisabledDueToOffline state to initial value.
   */
  React.useEffect(() => {
    if (!disabledDueToOffline) {
      setDisabled(false);
    }
  }, [disabledDueToOffline]);

  return (
    <Route
      {...rest}
      render={(props: ReactRouterProps) => (isDisabledDueToOffline ?
        <Offline /> :
        <DocumentTitle title={translate(title)}>
          <ErrorBoundary>
            <PermissionWrapper permissionsRequired={permissions} user={user} showDenialMessage>
              <Component {...props} {...props.match.params} />
            </PermissionWrapper>
          </ErrorBoundary>
        </DocumentTitle>)}
    />
  );
};

/**
 * Wraps a patient route to handle pass through of url parameters, referral mode, and the display
 * of the patient action button.
 * @param {PatientRouteProps} props Props for the route.
 * @returns {React.Component}
 */
export const PatientRoute = ({
  component: Component,
  showActionButton,
  allowReferral,
  exact,
  path,
  ...parentProps
}: PatientRouteProps) => {
  React.useEffect(() => {
    logToGoogleAnalytics(path, translate('patient'));
  }, [path]);
  return (
    <Route
      exact={exact}
      path={path}
      render={(props: ReactRouterProps) => {
        const patient = parentProps.patient || parentProps.patientStub;
        const { params } = props.match;
        const parsedQueryString = parse(props.location.search.substring(1));
        return (
          <DocumentTitle title={patient ? patient.get('patient_name') : translate('patient')}>
            <ErrorBoundary>
              <ContentTransition>
                {
                  allowReferral &&
                  parsedQueryString.referralId ?
                    <CasenoteEditorWithReferral
                      {...parentProps}
                      {...props}
                      casenoteID={parsedQueryString.referralId}
                    >
                      <Component {...parentProps} {...props} {...params} />
                    </CasenoteEditorWithReferral> :
                    <Component {...parentProps} {...props} {...params} />
                }
                {
                  showActionButton &&
                  <PatientActionButton
                    patientID={patient.get('_id')}
                    config={parentProps.config}
                    klinifyConfig={parentProps.klinifyConfig}
                    categoryID={params.categoryID !== 'ALL' ? params.categoryID : undefined}
                    saveModel={parentProps.saveModel}
                    isOnline={parentProps.isOnline}
                    user={parentProps.user}
                  />
                }
              </ContentTransition>
            </ErrorBoundary>
          </DocumentTitle>
        );
      }}
    />
  );
};
