import React from 'react';
import glamorous from 'glamorous';

import { fonts, colours } from './../../utils/css';

type Props = {
  label: string,
  value: string,
  isDanger: boolean, // Basically should the value be shown in red text.
};

const Key = glamorous.span({
  textTransform: 'uppercase',
  fontFamily: fonts.medium,
}, ({ isDanger }) => ({
  color: isDanger ? colours.red : colours.black,
}));

const Value = glamorous.span({}, ({ isDanger }) => ({
  color: isDanger ? colours.red : colours.black,
}));

/**
 * A component that displays a key: value.
 * @class KeyValue
 * @extends {React.Component<Props, State>}
 */
class KeyValue extends React.PureComponent<Props> {
  static defaultProps = {
    isDanger: false,
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div>
        <Key>{this.props.label}: </Key>
        <Value isDanger={this.props.isDanger}>{this.props.value}</Value>
      </div>
    );
  }
}

export default KeyValue;
