import React from 'react';
import glamorous from 'glamorous';

import { List } from 'immutable';
import DashboardStatModel from '../../models/dashboardStatModel';

import StatCard from './statCard';
import { IntervalString } from '../../types';


type Props = {
  stats: List<DashboardStatModel>,
  isFetching: boolean,
  interval: IntervalString,
};

const Container = glamorous.div({
  margin: '32px',
  '@supports (display: grid)': {
    display: 'grid',
    gridGap: 10,
    gridtemplateColumns: '1fr',
    '@media (min-width: 1200px)': { // Large Screens - Desktop
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    '@media (max-width: 1199px) and (min-width: 768px)': { // Tablets
      gridTemplateColumns: '1fr 1fr',
    },
  },
});

/**
  * A Component showing stats for today
  *
  * @namespace StatsToday
  */
class StatsToday extends React.PureComponent<Props> {
  /**
    * Renders the component.
    * @return {string} - HTML markup for the component
    */
  render() {
    const { stats, isFetching } = this.props;

    const stat: DashboardStatModel | null =
        (stats && stats.first()) || null;
    return stat && (
      <Container>
        <StatCard
          stat={stat.get('patients_registered')}
          label="patients_registered"
          isFetching={isFetching}
        />
        <StatCard
          stat={stat.get('bills')}
          label="bills"
          isFetching={isFetching}
        />
        <StatCard
          stat={stat.get('foc_bills')}
          label="foc_bills"
          isFetching={isFetching}
        />
        <StatCard
          stat={stat.get('revenue_cash')}
          label="revenue_cash"
          isFetching={isFetching}
        />
        <StatCard
          stat={stat.get('revenue_total')}
          label="revenue_total"
          isFetching={isFetching}
        />
      </Container>
    );
  }
}

export default StatsToday;
