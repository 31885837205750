import moment from 'moment';
import first from 'lodash/head';
import last from 'lodash/last';
import { List, Set } from 'immutable';

import Constants from './../constants';
import { generateGUID, mapFromJS, getDeviceWindowId } from './../utils/utils';
import { getUserName } from "../utils/user";
import { getDateTimeFormat, prettifyDate, isToday } from './../utils/time';
import { logMessage, debugPrint } from './../utils/logging';

import type { MapValue } from './../types';

type EditedBy = {
  timestamp: number,
  user_id: string,
  [key: string]: MapValue,
}

type BaseAttributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<EditedBy>,
  is_missing?: boolean,
  type: string,
  [key: string]: MapValue,
}

/**
   * BaseModel
   *
   * @namespace BaseModel
   */
class BaseModel {
  attributes: BaseAttributes;

  changes: { [key: string]: MapValue };

  // Override in extending models. Key should be doc field and value a function that takes two values
  // to be merged and returns the merged value. Signature is (localValue, remoteValue) => mergedValue
  mergeFunctions = {};

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: { [key: string]: MapValue } = {}) {
    this.changes = {};
    let updatedAttributes = Object.assign({}, attributes);
    // Assign ID if this is a new model.
    if (!attributes._id) {
      updatedAttributes = Object.assign({ _id: generateGUID() }, updatedAttributes);
    }
    // Add createdBy if this is a new model
    if (!attributes.created_by) {
      const timestamp = new Date().getTime();
      updatedAttributes = Object.assign({
        created_by: { timestamp, user_id: getUserName() || 'unknownUser' },
        edited_by: [],
        is_missing: false,
      }, updatedAttributes);
    }
    this.attributes = updatedAttributes;
  }

  /**
   * Clears any changes being tracked for this rev.
   * @returns {this}
   */
  clearChanges() {
    this.changes = {};
    return this;
  }

  /**
   * Copys the attrs of a model and returns a new model.
   * @param {object} changeAttrs attributes to be copied.
   * @param {Array<string>} attrsToDelete optional array of keys of attributes to be deleted.
   * @returns {object}
   */
  copyData(changeAttrs: Object = {}, attrsToDelete?: Array<string>): Object {
    const delAttrs = attrsToDelete && Array.isArray(attrsToDelete)
      ? attrsToDelete
      : ['_id', '_rev', 'created_by', 'edited_by'];
    const attrs = Object.assign({}, this.attributes, changeAttrs);
    delAttrs.forEach(
      key => attrs[key] && delete attrs[key],
    );
    return attrs;
  }

  /**
   * Returns true if this model has the correct metadata (i.e. _id, created_by and edited_by and
   * type fields).
   * @returns {boolean}
   */
  hasValidMetadata(): boolean {
    return this.has('_id') &&
      this.has('created_by') &&
      this.has('edited_by') &&
      this.has('type');
  }

  /**
   * @param {string|string[]} key - An attributes key. If key is an array it will be treated as
   *                                nested keys.
   * @param {boolean} allowEmptyString - If false an empty string will return false.
   * @return {boolean} Returns true if value can be gotten with key.
   */
  has(key: string | Array<string>, allowEmptyString: boolean = true): boolean {
    if (Array.isArray(key)) {
      if (!allowEmptyString) {
        return mapFromJS(this.attributes).getIn(key) && mapFromJS(this.attributes).getIn(key) !== '';
      }
      return mapFromJS(this.attributes).hasIn(key);
    }
    if (!allowEmptyString) {
      return this.attributes[key] !== undefined && this.attributes[key] !== '';
    }
    return this.attributes[key] !== undefined;
  }

  /**
   * @param {string|string[]} key - An attributes key. If key is an array it will be treated as
   *                                nested keys.
   * @param {any} placeholder  - A placeholder value to return if value not found.
   * @param {boolean} allowEmptyString - If false the placeholder is returned if the value is an
   * empty string.
   * @param {boolean} treatNullAsValid If true then null values will be returned. If false nulls
   * will be converted to empty strings. Defaults to true.
   * @return {any} Returns the value at attributes[key] or placeholder/undefined if not found.
   */
  get(
    key: string | Array<string>,
    placeholder?: MapValue,
    allowEmptyString: boolean = true,
    treatNullAsValid: boolean = true,
  ): MapValue {
    let value;
    if (Array.isArray(key)) {
      value = mapFromJS(this.attributes).getIn(key, placeholder);
      value = value && value.toJS ? value.toJS() : value;
    } else {
      value = this.attributes[key];
    }
    if (
      value !== undefined &&
      (allowEmptyString || value !== '') &&
      (treatNullAsValid || value !== null)
    ) {
      return value;
    }
    return placeholder;
  }

  /**
   * Gets the value or returns an empty string if it's undefined.
   * @param {string|string[]} key - An attributes key. If key is an array it will be treated as
   *                                nested keys.
   * @param {boolean} treatNullAsValid If true then null values will be returned. If false nulls
   * will be converted to empty strings. Defaults to false.
   * @return {any} Returns the value at attributes[key] or empty string if not found.
   */
  getOrBlank(key: string | Array<string>, treatNullAsValid: boolean = false) {
    return this.get(key, '', true, treatNullAsValid);
  }

  /**
   * Gets the value or returns false if it's undefined.
   * @param {string|string[]} key - An attributes key. If key is an array it will be treated as
   *                                nested keys.
   * @return {any} Returns the value at attributes[key] or false if not found.
   */
  getOrFalse(key: string | Array<string>) {
    return this.get(key, false);
  }

  /**
   * Sets an attribute value or a map of attributes. If keyOrObject is an object, value is ignored.
   * and keyOrObject is merged into this.attributes. Otherwise keyOrObject is assumed to be a key
   * and value is added to this.attributes.
   * All string values are trimmed of trailing and leading whitespace.
   * @param {any} keyOrObject - An attributes key or an object of K:Vs.
   * @param {any} value - A value to be placed at attributes[key].
   * @return {BaseModel} Returns this class.
   */
  set(keyOrObject: string | {}, value?: MapValue): this {
    if (keyOrObject !== null && typeof keyOrObject === 'object') {
      Object.keys(keyOrObject).forEach((key) => {
        if (typeof keyOrObject[key] === 'string') {
          keyOrObject[key] = keyOrObject[key].trim(); // eslint-disable-line no-param-reassign
        }
      });
      this.attributes = Object.assign(this.attributes, keyOrObject);
      this.changes = Object.assign(this.changes, keyOrObject);
    } else {
      const trimmedValue = typeof value === 'string' ? value.trim() : value;
      this.attributes[keyOrObject] = trimmedValue;
      this.changes[keyOrObject] = trimmedValue;
    }
    return this;
  }

  /**
   * Replace the attrs of a model and returns a model.
   * @param {object} changeAttrs attributes to be replaced.
   * @returns {BaseModel} Returns this class.
   */
  replaceAtrributes(changeAttrs: object): this {
    const initialAttr = Object.assign({}, this.attributes);
    const baseAttr = Object.assign({}, {
      _id: initialAttr._id,
      created_by: initialAttr.created_by,
      edited_by: initialAttr.edited_by,
      _rev: initialAttr._rev,
      type: initialAttr.type,
    });
    this.attributes = Object.assign(baseAttr, changeAttrs);
    this.changes = Object.assign(this.changes, changeAttrs);
    return this;
  }

  /**
   * Checks if a Model has actually been saved to the db. attributes.created_by is created at
   * instantiation, but edited_by is empty until the first save, so we check for that.
   * @returns {boolean} True if model has been saved to the database.
   */
  hasBeenSaved(): boolean {
    return this.attributes.edited_by && this.attributes.edited_by.length > 0;
  }

  /**
   * Update the edited_by object with a new timestamp.
   * @param {string} reason The reason for the edit (e.g. merge). Defaults to undefined.
   * @return {BaseModel}
   */
  updateEditedBy(reason?: string) {
    this.attributes.edited_by.push({
      timestamp: new Date().getTime(),
      user_id: getUserName() || 'unknownUser',
      device_identifier: getDeviceWindowId(),
      reason,
    });
    this.changes.edited_by = this.attributes.edited_by.map(e => Object.assign({}, e));
    return this;
  }

  /**
   * Gets the username of the creator of this Model.
   * @return {string} - Username.
   */
  getCreator(): string {
    return this.get('created_by', {}).user_id;
  }

  /**
   * Gets last update time for this model.
   * @return {int} - Timestamp.
   */
  getLastUpdateTime(): number {
    let timestamp: number;
    if (this.has('edited_by') && this.get('edited_by').length > 0) {
      ({ timestamp } = last(this.get('edited_by')));
      if (timestamp !== undefined) {
        return timestamp;
      }
    }
    return this.get('created_by', {}).timestamp;
  }

  /**
   * Returns a prettified version of the last update tiem for this model.
   * @return {string} - DateTime string.
   */
  getDateAndTime(): string {
    return moment(this.getLastUpdateTime()).format(getDateTimeFormat());
  }

  /**
   * Returns a list of edited_by entries that are separated by more than THRESHOLD number of
   * minutes or edited by unique users. This function will always return something as at least one
   * edited_by entry is guaranteed by the API. Returns an Immutable.JS List.
   * @return {List} - A List of edited_by entries.
   */
  getEditHistory(): List<{ timestamp: number, user_id: string }> {
    let history = List.of(this.get('edited_by')[0]);
    let lastTime = this.get('edited_by')[0].timestamp;
    let lastUser = this.get('edited_by')[0].user_id;
    this.get('edited_by').forEach((entry) => {
      if (entry.user_id !== lastUser
        || entry.timestamp > lastTime
        || (this.get('type') === 'caseNoteFile' && entry.timestamp > lastTime + Constants.EDIT_HISTORY_THRESHOLD)) {
        // New user is editing or over THRESHOLD.
        history = history.push(entry);
        lastTime = entry.timestamp;
        lastUser = entry.user_id;
      }
    });
    return history;
  }

  /**
   * Returns a the timestamp for the creation of this model. Returns 0 if not found. This shouldnt
   * be possible due to DB restrictions.
   * @return {int} - Creation timestamp.
   */
  getCreatedTime(): number {
    // Returns the timestamp for the creation of this model.
    let timestamp: number;
    if (this.has('created_by')) {
      ({ timestamp } = this.get('created_by'));
      if (timestamp !== undefined) {
        return timestamp;
      }
    }
    if (this.has('edited_by') && this.get('edited_by').length > 0) {
      ({ timestamp } = first(this.get('edited_by')));
      if (timestamp !== undefined) {
        return timestamp;
      }
    }
    return 0;
  }

  /**
   * Returns a prettified version of the creation date for this model.
   * @return {string} - Date string.
   */
  getCreationDate(): string {
    // Returns prettified version of the creation date.
    return prettifyDate(this.getCreatedTime());
  }

  /**
   * Returns a prettified version of the creation date and time for this model.
   * @return {string} - Date and time string.
   */
  getCreationDateAndTime(): string {
    // Returns prettified version of the creation date and time.
    return moment(this.getCreatedTime()).format(getDateTimeFormat());
  }

  /**
   * Returns the name of the last person to edit this model.
   * @return {string} Username of last editor.
   */
  getLastEditor(): string {
    return this.hasHistory() ? last(this.get('edited_by')).user_id : this.getCreator();
  }

  /**
   * Returns true if this model was created today, false otherwise.
   * @return {boolean} Whether or not this model was created today.
   */
  isCreatedToday(): boolean {
    const createdTime = this.getCreatedTime();
    return createdTime ? isToday(new Date(createdTime)) : false;
  }

  /**
   * Returns true if this model was edited today, false otherwise.
   * @return {boolean} Whether or not this model was edited today.
   */
  isEditedToday(): boolean {
    const editedTime = this.getLastUpdateTime();
    return editedTime ? isToday(new Date(editedTime)) : false;
  }

  /**
   * Returns true if this model was either edited or created today, false otherwise.
   * @return {boolean} Whether or not this model was either edited or created today.
   */
  isToday(): boolean {
    return this.isCreatedToday() || this.isEditedToday();
  }

  /**
   * Returns true if this doc is missing in the backend. Used to show data not found prompts
   * @return {boolean}
   */
  isMissing(): boolean {
    return this.get('is_missing');
  }

  /**
   * Returns true if model has history/
   * @returns {boolean} True if model has history.
   */
  hasHistory(): boolean {
    return this.get('edited_by', []).length > 0;
  }

  /**
   * Checks if this is a new document being saved again due to previous save returning status 0
   * by using the edited by fields of both local and db documents.
   * @param {Array<EditedBy>} localEditedBy edited by array of lcoal document.
   * @param {Array<EditedBy>} remoteEditedBy edited by array of remote document(from db).
   * @returns {boolean}
   */
  isNewDocSaveReattempt(localEditedBy: Array<EditedBy>, remoteEditedBy: Array<EditedBy>): boolean {
    // there can be more than one edited by in local doc depending on the number of save attempt
    // Hence if the remote doc has complete subset of local doc's edited by we can skip logging error
    // considering it as a new doc being saved again because of status 0.
    if (remoteEditedBy && remoteEditedBy.length > 0 &&
      localEditedBy && localEditedBy.length > 0) {
      return Object.is(JSON.stringify(remoteEditedBy),
        JSON.stringify(localEditedBy.slice(0, remoteEditedBy.length)));
    }
    return false;
  }

  /**
   * Merges the remote model and the local model. An error is thrown if id, type or created_by
   * dont match. Custom merge functions for particular fields can be added in the model file at
   * this.mergeFunctions.
   * @param {BaseModel} remoteModel The model generated from teh remote revision.
   * @returns {BaseModel} The merged model.
   */
  merge(remoteModel: BaseModel) {
    if (
      remoteModel.get('type') !== this.get('type') || remoteModel.get('_id') !== this.get('_id') ||
      remoteModel.get('created_by').user_id !== this.get('created_by').user_id ||
      remoteModel.get('created_by').timestamp !== this.get('created_by').timestamp
    ) {
      debugPrint(`Local model: ${this.get('_id')}`);
      debugPrint(`Remote model: ${remoteModel.get('_id')}`);
      throw new Error('Trying to merge two models with different types, createdBys or ID.');
    }
    const mergedAttributes = Object.assign({}, remoteModel.attributes);
    Object.keys(this.changes).forEach((key) => {
      if (key !== 'edited_by' && key !== '_rev') {
        const value = this.changes[key];
        if (this.mergeFunctions[key]) {
          mergedAttributes[key] = this.mergeFunctions[key](value, mergedAttributes[key]);
        } else if (Array.isArray(value)) {
          // Default action is to merge the arrays of the conflicting docs. NOTE THAT THIS WILL NOT
          // CORRECTLY HANDLE ITEMS BEING REMOVED FROM ARRAYS. E.g. The original doc has [1,2,3] and
          // the remote doc has a list [1,2,3,4], if the local changes are to [1,2,5], the resulting
          // merge will be [1,2,3,4,5].
          mergedAttributes[key] = Set(value).concat(Set(mergedAttributes[key])).toJS();
        } else if (typeof value === 'object' && value !== null) {
          mergedAttributes[key] = Object.assign({}, mergedAttributes[key], value);
        } else {
          mergedAttributes[key] = value;
        }
      }
      if (this.get('_rev', '') && remoteModel.get('_rev', '')) {
        const localRev = parseInt(this.get('_rev', '').split('-')[0], 10);
        const remoteRev = parseInt(remoteModel.get('_rev', '').split('-')[0], 10);
        if (remoteRev < localRev) {
          logMessage('Remote document has lower revision than local for _id: ', 'error', { _id: remoteModel.get('_id') });
        }
      } else if (!this.get('_rev', '')) {
        const localEditedBy = this.get('edited_by', []);
        const remoteEditedBy = remoteModel.get('edited_by', []);
        if (remoteEditedBy.length === 0) {
          logMessage('edited by is missing in remote document with _id: ', 'error', { _id: remoteModel.get('_id') });
        }
        if (localEditedBy.length === 0) {
          logMessage('edited by is missing in local document with _id: ', 'error', { _id: this.get('_id') });
        }
        // This is to avoid a non error use case when there is multiple save attempts with response status as 0 for new document
        // but status 0 is when it is response timeout in one of them, in which case the save is actually successful. Since mwa is not aware
        // of it there is a re-attempt on save and also a 409. But edited by fields in this case are not same since every save
        // attempt adds a new edited by to the doc. remote doc ideally will have only one edited by item in this case
        // TODO we need to make sure this situation does not need a save again when its merely the same save attempted again
        if (!this.isNewDocSaveReattempt(localEditedBy, remoteEditedBy)) {
          logMessage('revision is missing in local document with _id: ', 'error', { _id: remoteModel.get('_id') });
        }
      } else if (!remoteModel.get('_rev', '')) {
        logMessage('revision is missing in remote document with _id: ', 'error', { _id: remoteModel.get('_id') });
      }
    });
    this.attributes = mergedAttributes;
    return this;
  }

  /**
   * Returns true if item does not have a hidden flag.
   * @returns {boolean} True if item is not hidden.
   */
  isVisible(): boolean {
    return !this.get('hidden', false);
  }
}

export default BaseModel;
