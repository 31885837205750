import React from 'react';
import { List } from 'immutable';
import { UNICODE } from '../../constants';

import translate from './../../utils/i18n';
import { convertNumberToPrice } from '../../utils/utils';
import Header from './../header/header';

import type BillItemModel from './../../models/billItemModel';
import type DrugModel from './../../models/drugModel';
import type SalesItemModel from './../../models/salesItemModel';


type Props = {
  salesItems: List<SalesItemModel>,
  billItems: List<BillItemModel>,
  drugs: List<DrugModel>,
  headerText: string,
  noDataText: string,
};

/**
   * A Component listing sales items.
   * @namespace ConsultationSalesItems
   */
class ConsultationSalesItems extends React.PureComponent<Props> {
  /**
   * Gets name of the sales/bill item depending on its type.
   * @param {BillItemModel} billItem the bill item model
   * @returns {string}.
   */
  getName(billItem: BillItemModel): string {
    if (billItem.isPrescription()) {
      const drug = this.props.drugs.find(d => d.get('_id') === billItem.get('drug_id'));
      return drug ? drug.get('name') : UNICODE.EMDASH;
    }
    const salesItem = this.props.salesItems.find(s => s.get('_id') === billItem.get('sales_item_id'));
    return salesItem ? salesItem.get('name') : UNICODE.EMDASH;
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className="c-item-list__item__info c-sales-item-conatiner">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{this.props.headerText}</h1>
        </Header>
        {
          this.props.billItems.size > 0 &&
          <div className="o-data-list__row o-data-list__row--header">
            <div className="o-data-list__row__item wider-column-name">
              {translate('name')}
            </div>
            <div className="o-data-list__row__item">
              {translate('price')}
            </div>
            <div className="o-data-list__row__item wider-column-qty">
              {translate('quantity')}
            </div>
            <div className="o-data-list__row__item">
              {translate('total')}
            </div>
          </div>
        }
        { this.props.billItems.map(billItem =>
          <div className="o-data-list__row">
            <div className="o-data-list__row__item wider-column-name">
              {this.getName(billItem)}
            </div>
            <div className="o-data-list__row__item">
              {convertNumberToPrice(billItem.get('price', ''))}
            </div>
            <div className="o-data-list__row__item wider-column-qty">
              {billItem.get('quantity', UNICODE.EMDASH)}
            </div>
            <div className="o-data-list__row__item">
              {convertNumberToPrice((billItem.has('quantity', false) ?
                billItem.get('quantity') : 0) * (billItem.has('price', false) ?
                billItem.get('price') : 0))}
            </div>
          </div>)
          }
        {
          this.props.billItems.size === 0 &&
          <p className="u-padding--1ws">{this.props.noDataText}</p>
        }
      </div>
    );
  }
}
export default ConsultationSalesItems;
