import { connect } from 'react-redux';

import VitalsTable from './../components/vitals/vitalsTable';

import type { State } from './../types';
import type MetricEntryModel from '../models/metricEntryModel';

type Props = { patientID: string };

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps The props of this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps =
({ metricTypes, metricEntries }: State, ownProps: Props) => ({
  data: metricEntries
    .filter(metricEntry => metricEntry.get('patient_id') === ownProps.patientID)
    .groupBy(metricEntry => metricEntry.get('metric_type'))
    .valueSeq()
    .map(entriesList => entriesList.sortBy(entry => entry.get('measured_time')))
    .map(list => list.last())
    .map((entry: MetricEntryModel) => {
      if (!entry) {
        throw new Error('Entry not found');
      }
      const metricType = metricTypes.find(mType => mType.get('_id') === entry.get('metric_type'));
      return {
        metric_type: metricType ? metricType.get('name') : entry.get('metric_type'),
        metric_entry: metricType ? metricType.formatValues(entry.get('values')) : entry.get('values'),
        date: entry.get('measured_time'),
      };
    })
    .toArray(),
  showMetricType: true,
});

const LatestVitalsContainer = connect(mapStateToProps)(VitalsTable);

export default LatestVitalsContainer;
