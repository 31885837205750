
import React from 'react';
import Immutable, { List, Map } from 'immutable';

import ButtonGroup from './../buttons/buttonGroup';
import { getConfirmation } from './../../utils/utils';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import AddClaimInvoiceTable from './addClaimInvoiceTable';
import AddClaimInvoiceMultipleTable from './addClaimInvoiceMultipleTable';
import AddClaimInvoiceSidebar from './addClaimInvoiceSidebar';
import ClaimInvoiceModel from './../../models/claimInvoiceModel';
import StatelessModal from './../modals/statelessModal';
import ClaimInvoiceMetadataForm from './claimInvoiceMetadataForm';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { printClaimInvoice, printClaimInvoices } from './../../utils/print';
import { regenerateClaimInvoice, createClaimInvoiceData, createClaimInvoiceDataCompat } from './../../utils/claims';
import { debugPrint } from '../../utils/logging';

import type ClaimModel from './../../models/claimModel';
import type BillModel from './../../models/billModel';
import type EncounterModel from './../../models/encounterModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PatientStubModel from './../../models/patientStubModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type { MapValue, Config, ClaimInvoiceFormAttributes, SaveModels, ClaimInvoiceMetadata, APIResponse } from './../../types';
import type DrugModel from './../../models/drugModel';
import type BillItemModel from './../../models/billItemModel';

type ResponseModel = ClaimModel | ClaimInvoiceModel;

type Props = {
  claimInvoices: List<ClaimInvoiceModel>,
  coveragePayors: List<CoveragePayorModel>,
  claims: List<ClaimModel>,
  bills: List<BillModel>,
  encounters: List<EncounterModel>,
  patientStubs: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  saveModels: SaveModels,
  saveClaimInvoiceModels: (claimInvoiceModels: Array<ClaimInvoiceMetadata>) => Promise<APIResponse<ResponseModel> | Array<ResponseModel>>,
  config: Config,
  unfilteredClaims: List<ClaimModel>,
  drugs: List<DrugModel>,
  billItems: List<BillItemModel>,
};

type State = {
  isSaving: boolean,
  isSavingAndPrinting: boolean,
  metadataModalIsVisible: boolean,
  uncreatedCoveragePanels: List<CoveragePayorModel>,
  selectedRows: Array<string>, // Coverage Payor IDs
};

/**
 * A component for creating new claim invoices.
 * @class AddClaimInvoice
 * @extends {Component}
 */
class AddClaimInvoice extends React.Component<Props, State> {
  /**
   * Creates an instance of AddClaimInvoice.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      isSavingAndPrinting: false,
      metadataModalIsVisible: false,
      uncreatedCoveragePanels: List(),
      selectedRows: [],
    };
  }

  /**
   * Get all uncreate coverage payors
   * @returns {undefined}
   */
  componentDidMount() {
    this.getCoveragePayorsWithClaimsThisMonth();
  }

  /**
   * Get Coverage Payors
   * @returns {undefined}
   */
  getCoveragePayorsWithClaimsThisMonth() {
    let uncreatedCoveragePanels = List();
    this.props.unfilteredClaims.filter(uc => this.props.coveragePayors.filter((cp) => {
      if (cp.get('_id') === uc.get('coverage_payor_id') &&
        uc.canBeAssignedToInvoice() &&
        !uncreatedCoveragePanels.find(u => u.get('_id') === cp.get('_id'))
      ) {
        uncreatedCoveragePanels = uncreatedCoveragePanels.push(cp);
      }
      return false;
    }));
    this.setState({ uncreatedCoveragePanels });
  }

  /**
   * Get claims
   * @returns {List<ClaimModel>} List of claims
   */
  getClaims() {
    let claimModels;
    if (this.props.filter.get('isModeMultiple')) {
      let selectedClaims = List();
      this.state.selectedRows.map(s => this.props.unfilteredClaims.filter((uc) => {
        if (uc.get('coverage_payor_id') === s && uc.canBeAssignedToInvoice()) {
          selectedClaims = selectedClaims.push(uc);
        }
        return false;
      }));
      claimModels = selectedClaims;
    } else {
      claimModels = this.props.claims;
    }
    return claimModels;
  }

  /**
   * Saves a ClaimInvoice and updates all selected claims to reflect that they were claimed.
   * @param {ClaimInvoiceFormAttributes} attributes The invoice attributes.
   * @returns {void}
   */
  saveInvoice(attributes: ClaimInvoiceFormAttributes) {
    const claims = this.getClaims().map(c => c.set('status', 'pending'));
    const coveragePayor = this.props.coveragePayors.find(cp => cp.get('_id') === this.props.filter.get('coveragePayorID'));
    if (!coveragePayor) {
      throw new Error('Coverage Payor not found.');
    }
    createClaimInvoiceData(
      claims,
      coveragePayor,
      this.props.filter.get('month', {}).toJS(),
      attributes,
    )
      .then(claimInvoice => this.props.saveClaimInvoiceModels([claimInvoice]))
      .then((response) => (
        !((response as APIResponse<ResponseModel>).error) ?
          Promise.resolve(response)
            .then(savedModels => (
              (savedModels as APIResponse<ResponseModel>).unavailable ?
                createClaimInvoiceDataCompat(
                  claims,
                  coveragePayor,
                  this.props.filter.get('month', {}).toJS(),
                  attributes,
                  this.props.patientStubs,
                  this.props.salesItems,
                  this.props.drugs,
                  this.props.billItems,
                  this.props.bills,
                  this.props.encounters,
                  this.props.config,
                )
                  .then(claimInvoice => this.props.saveModels(claims.push(claimInvoice).toArray())
                    .then(() => claimInvoice)) :
                (savedModels as Array<ResponseModel>).find(e => e.get('type') === 'claim_invoice')))
            .then((claimInvoice : ClaimInvoiceModel) => {
              createSuccessNotification(translate('claim_invoice_created'));
              const printJob = this.state.isSavingAndPrinting ?
                printClaimInvoice(claimInvoice, coveragePayor, this.props.config) :
                Promise.resolve();
              printJob.then(() => {
                this.resetState();
                this.getCoveragePayorsWithClaimsThisMonth();
              });
            }) :
          Promise.resolve()
      ));
  }

  /**
   * Saves ClaimInvoices and updates all claims to reflect that they were claimed.
   * @param {ClaimInvoiceFormAttributes} attributes The invoice attributes.
   * @returns {void}
   */
  saveInvoices(attributes: ClaimInvoiceFormAttributes) {
    const selectedClaims = this.getClaims()
      .filter(c => this.state.selectedRows.includes(c.get('coverage_payor_id')))
      .map(c => c.set('status', 'pending'));
    const groupedClaims = selectedClaims.groupBy(c => c.get('coverage_payor_id'));
    Promise.all(
      groupedClaims.mapEntries(([coveragePayorId, claims] : [string, List<ClaimModel>]) =>
        [coveragePayorId, createClaimInvoiceData(
          claims,
          this.props.coveragePayors.find(cp => cp.get('_id') === coveragePayorId),
          this.props.filter.get('month', {}).toJS(),
          attributes,
        )]).valueSeq().toArray(),
    )
      .then(claimInvoices =>
        this.props.saveClaimInvoiceModels(claimInvoices))
      .then(response => (
        !(response as APIResponse<ResponseModel>).error ?
          Promise.resolve(response)
            .then(savedModels => (
              (savedModels as APIResponse<ResponseModel>).unavailable ?
                Promise.all(
                  groupedClaims.mapEntries(([coveragePayorId, claims] : [string, List<ClaimModel>]) =>
                    [coveragePayorId, createClaimInvoiceDataCompat(
                      claims,
                      this.props.coveragePayors.find(cp => cp.get('_id') === coveragePayorId),
                      this.props.filter.get('month', {}).toJS(),
                      attributes,
                      this.props.patientStubs,
                      this.props.salesItems,
                      this.props.drugs,
                      this.props.billItems,
                      this.props.bills,
                      this.props.encounters,
                      this.props.config,
                    )]).valueSeq().toArray(),
                )
                  .then(claimInvoices =>
                    this.props.saveModels(selectedClaims.concat(claimInvoices).toJS())
                      .then(() => claimInvoices)) :
                (savedModels as Array<ResponseModel>).filter(e => e.get('type') === 'claim_invoice')))
            .then((claimInvoices : ClaimInvoiceModel[]) => {
              createSuccessNotification(translate('claim_invoice_created'));
              const printJob = this.state.isSavingAndPrinting ?
                printClaimInvoices(List(claimInvoices.filter(c => c.get('items').claims.length)), this.props.coveragePayors, this.props.config) :
                Promise.resolve();

              printJob.then(() => {
                this.resetState();
                this.setState({ selectedRows: [] });
                this.getCoveragePayorsWithClaimsThisMonth();
              });
            }) :
          Promise.resolve()
      ));
  }

  /**
   * When save is clicked it triggers the correct saving state and opens the metadata modal.
   * @param {boolean} printOnCompletion If true this will trigger print on save completion.
   * @returns {void}
   */
  onSaveClicked(printOnCompletion: boolean = false) {
      printOnCompletion ? 
      this.setState({ isSavingAndPrinting: true, metadataModalIsVisible: true } ) : 
      this.setState({ isSaving: true, metadataModalIsVisible: true },
    );
  }

  /**
   * Resets the state of the component.
   * @returns {void}
   */
  resetState() {
    this.setState({
      isSaving: false,
      isSavingAndPrinting: false,
      metadataModalIsVisible: false,
    });
  }

  /**
   * Handle toggling of table mode
   * @returns {undefined}
   */
  onToggleMode() {
    this.props.onFilterUpdated(this.props.filter.set('isModeMultiple', !this.props.filter.get('isModeMultiple')));
    this.setState({ selectedRows: [] });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { month, year } = this.props.filter.get('month', Map({ month: 1, year: 2018 })).toJS();
    const coveragePayorID = this.props.filter.get('coveragePayorID');
    const isModeMultiple = this.props.filter.get('isModeMultiple');
    let coveragePayors = List();
    if (isModeMultiple) {
      let selectedCoveragePayors = List();
      this.state.selectedRows.map(i => this.props.coveragePayors.filter((cp) => {
        if (cp.get('_id') === i) {
          selectedCoveragePayors = selectedCoveragePayors.push(cp);
        }
        return false;
      }));
      coveragePayors = selectedCoveragePayors;
    } else {
      coveragePayors = this.props.coveragePayors.filter(c => c.get('_id') === coveragePayorID);
    }
    let generatedClaimInvoice : ClaimInvoiceModel | undefined;
    let isRegenerateButtonEnabled = false;
    if (coveragePayorID) {
      generatedClaimInvoice = this.props.claimInvoices
        .filter(c => !c.get('is_void'))
        .find(
          claimInvoice =>
            (claimInvoice.get('coverage_payor_id') === coveragePayorID) &&
            (claimInvoice.get('items').month.month === month),
        );
      if (generatedClaimInvoice) {
        const isClaimItemsMatchIDs = Immutable.is(this.props.claims.map(c => c.get('_id')), generatedClaimInvoice.getClaimIds());
        isRegenerateButtonEnabled = isClaimItemsMatchIDs;
      }
    }
    const pageHeaderLabel = isModeMultiple ? 'panels_with_unclaimed_bills' : 'unclaimed_bills';
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <div className="c-billing__content">
          <div className="u-flex-row u-flex-space-between">
            <h1 data-public className="o-title u-margin-bottom--zero">{translate(pageHeaderLabel)}</h1>
            <ButtonGroup
              id="toggle-button"
              className="u-margin-right--1ws"
              label={`${translate('generate')}: `}
              buttons={[
                {
                  label: translate('single'),
                  isActive: !isModeMultiple,
                  onClick: () => (!isModeMultiple ? undefined : this.onToggleMode()),
                },
                {
                  label: translate('multiple'),
                  isActive: isModeMultiple,
                  onClick: () => (isModeMultiple ? undefined : this.onToggleMode()),
                },
              ]}
            />
          </div>
          <StatelessModal
            id="metadata-form"
            visible={this.state.metadataModalIsVisible}
            setVisible={() => this.resetState()}
            noButton
            explicitCloseOnly
            title={translate('unclaimed_bills')}
            onClose={() => this.resetState()}
            dataPublicHeader
          >
            <ClaimInvoiceMetadataForm
              onSaveClicked={attributes =>
                (isModeMultiple ? this.saveInvoices(attributes) : this.saveInvoice(attributes))
              }
              coveragePayors={coveragePayors}
              config={this.props.config}
            />
          </StatelessModal>
          <div className="o-card">
            { isModeMultiple ?
              <AddClaimInvoiceMultipleTable
                uncreatedCoveragePanels={this.state.uncreatedCoveragePanels}
                onClickRow={(row) => {
                  this.props.onFilterUpdated(this.props.filter.set('coveragePayorID', row.original._id).set('isModeMultiple', false));
                }}
                getSelectedRows={selectedRows => this.setState({ selectedRows })}
                unfilteredClaims={this.props.unfilteredClaims}
              />
              :
              <AddClaimInvoiceTable
                claims={this.props.claims}
                bills={this.props.bills}
                salesItems={this.props.salesItems}
                encounters={this.props.encounters}
                patientStubs={this.props.patientStubs}
                selectedCoveragePayorId={coveragePayorID}
              />
            }
          </div>
        </div>
        <AddClaimInvoiceSidebar
          unfilteredClaims={this.props.unfilteredClaims}
          isModeMultiple={isModeMultiple}
          coveragePayors={this.props.coveragePayors}
          selectedCoveragePayorId={coveragePayorID}
          onCoveragePayorSelected={(id: string) => {
            this.props.onFilterUpdated(this.props.filter.set('coveragePayorID', id));
          }}
          monthValue={{ month, year }}
          onMonthValueChanged={value => this.props.onFilterUpdated(this.props.filter.set('month', Map(value)))}
          enableButtons={isModeMultiple
            ? ((this.state.selectedRows.length > 0) &&
              (this.state.uncreatedCoveragePanels.size > 0))
            : this.props.claims.size > 0
          }
          onSaveClicked={printOnCompletion => this.onSaveClicked(printOnCompletion)}
          isSaving={this.state.isSaving}
          isSavingAndPrinting={this.state.isSavingAndPrinting}
          isRegenerateButtonEnabled={isRegenerateButtonEnabled}
          onRegenerateInvoice={() => {
            if (generatedClaimInvoice) {
              getConfirmation(translate('confirm_regenerate_claim_invoices_from_add_claim',
                { internalClinicID: generatedClaimInvoice.get('internal_clinic_id') }))
                .then(
                  () => {
                    regenerateClaimInvoice(
                      generatedClaimInvoice,
                      this.props.saveClaimInvoiceModels,
                      this.props.saveModels,
                      this.props.patientStubs,
                      this.props.salesItems,
                      this.props.drugs,
                      this.props.config,
                      List(),
                    )
                      .then((newInvoice) => {
                        createSuccessNotification(
                          translate('claim_invoice_successfully_regenerated'),
                          {
                            label: translate('click_here_to_view_new_invoice'),
                            callback: () => { location.hash = `/accounts/claim-invoices/${newInvoice.get('_id')}`; },
                          },
                        );
                        location.hash = '/accounts/claim-invoices';
                      })
                      .catch((error) => {
                        if (error !== 'TIMEOUT') {
                          debugPrint(error, 'error');
                          createErrorNotification(translate('claim_invoice_regeneration_failed'));
                        }
                      });
                  },
                  () => {},
                );
            }
          }}
        />
      </ContentTransition>
    );
  }
}

export default AddClaimInvoice;
