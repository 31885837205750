import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import moment from 'moment';

import InventoryStatus from './../components/inventory/inventoryStatus';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import AsyncFetch from './../components/asyncFetch';
import TransactionModel from './../models/transactionModel';
import {
  setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, setCurrentDataViewsError,
  updateInventoryItemCount, updateConfigValue, updateConfig, resetInventoryCountSyncStatus,
  resetAllInventoryBatchIndex,
} from './../actions';

import { getInventoryDataViews } from './../dataViews';
import { listToMap } from '../utils/utils';

import type { Dispatch, State, DataView, Config } from './../types';
import type APIError from '../utils/apiError';
import SupplyItemModel from '../models/supplyItemModel';
import DrugModel from '../models/drugModel';
import type BillItemModel from '../models/billItemModel';
import type BillModel from '../models/billModel';


/**
 *
 * @param {List<TransactionModel>} transactions List of transaction models
 * @param {Map<string, BillItemModel> }billItems Map of billItems
 * @param {Map<string, BillModel>} bills Map of Bills
 * @returns {Map<string, number>}
 */
function getDispensationCount(
  transactions: List<TransactionModel>,
  billItems: Map<string, BillItemModel>,
  bills: Map<string, BillModel>,
): Map<string, number> {
  return transactions.reduce((counts, transaction) => {
    const billItem = billItems.get(transaction.get('source_id'));
    const bill = billItem && bills.get(billItem.get('bill_id'));
    if (bill && bill.get('timestamp') < moment().subtract(30, 'days').startOf('day').valueOf()) {
      return counts;
    }
    return counts.update(transaction.get('sku_id'), 0, (c: number) => c - transaction.get('change'));
  }, Map());
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  drugs, inventoryCount, user, config, inventoryCountSyncStatus,
  isFetching, currentDataViewsModels, currentDataViews, currentDataViewsError,
  isSKUCountSyncing,
}: State): object => {
  const filteredSupplyItems = isFetching ? List() : currentDataViewsModels.filter(m => m.get('type') === 'supply_item');
  const supplyItems = drugs.reduce((items: List<SupplyItemModel>, drug: DrugModel) => {
    const hasUnKnownBatch = inventoryCount.hasIn([drug.get('_id'), 'batches', 'UNKNOWN']);
    if (hasUnKnownBatch) {
      return items.concat(new SupplyItemModel({
        _id: 'UNKNOWN',
        batch_id: 'UNKNOWN',
        sku_id: drug.get('_id'),
      }));
    }
    return items;
  }, filteredSupplyItems as List<SupplyItemModel>);
  return ({
    drugs,
    inventoryCount,
    inventoryCountSyncStatus,
    user,
    config,
    containerDataViews: getInventoryDataViews(moment().subtract(30, 'days').startOf('day').valueOf(), moment().endOf('day').valueOf()),
    isSupplier: true,
    currentDataViews,
    isFetching,
    isSKUCountSyncing,
    supplies: isFetching ? List() : currentDataViewsModels.filter(m => m.get('type') === 'supply'),
    suppliers: isFetching ? List() : currentDataViewsModels.filter(m => m.get('type') === 'supplier' && m.isVisible()),
    supplyItems,
    dispensationCount: getDispensationCount(
      currentDataViewsModels.filter(m => m.get('type') === 'transaction' && m.get('source_type') === 'bill_item'),
      listToMap(currentDataViewsModels.filter(m => m.get('type') === 'bill_item'), (e: BillItemModel) => e.get('_id')),
      listToMap(currentDataViewsModels.filter(m => m.get('type') === 'bill'), (e: BillModel) => e.get('_id')),
    ),
    currentDataViewsError,
  });
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  adjustInventory: (SKUID: any, change: any, notes: any) => {
    const model = new TransactionModel({
      sku_id: SKUID,
      change,
      source_type: 'write_off',
      notes,
      timestamp: new Date().getTime(),
    });
    return saveFactory(dispatch)(model)
      .then(dispatch(updateInventoryItemCount(model.get('sku_id'), model.get('change'))));
  },
  saveModel: saveFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<import('../models/baseModel').default>) => dispatch(setCurrentDataViewsModels(models)),
  updateInventoryItemCount: (skuID: string, change: number) =>
    dispatch(updateInventoryItemCount(skuID, change)),
  updateConfigValue: (keys: string[], value: any) => dispatch(updateConfigValue(keys, value)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  resetInventoryBatchCountSync: () => {
    dispatch(resetAllInventoryBatchIndex());
    dispatch(resetInventoryCountSyncStatus());
  },
  saveModels: saveModelsFactory(dispatch),
});

const InventoryStatusContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(InventoryStatus));

export default InventoryStatusContainer;
