import React from 'react';
import Select from './../inputs/select';

const DateRangeOptions = [
  {
    value: 'today',
    label: 'Today',
  },
  {
    value: 'yesterday',
    label: 'Yesterday',
  },
  {
    value: 'last_week',
    label: 'Last week',
  },
  {
    value: 'last_month',
    label: 'Last month',
  },
  {
    value: 'last_quarter',
    label: 'Last quarter',
  },
];

type Props = {
  dateRange: string,
  onChange: (dateRangeValue: string) => void
};

/**
 * Renders a dummy component that will simply prints a placeholder string
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function DateRangeSelect(props: Props) {
  const dateRange = DateRangeOptions.find(option => option.value === props.dateRange) || 'last_week';
  return (
    <div className="date-select">
      <Select
        id="date-select"
        label="Choose date range"
        onValueChanged={(label: string) => { props.onChange(label); }}
        options={DateRangeOptions.map(({ value, label }) => ({ value, label }))}
        value={dateRange}
      />
    </div>
  );
}

export default DateRangeSelect;
