import glamorous from 'glamorous';
import { wsUnit, colours, fonts, borderRadius } from '../../utils/css';

const Notification = glamorous.div({
  background: colours.transparentMidnightGrey,
  color: colours.white,
  fontFamily: fonts.medium,
  width: '100%',
  padding: wsUnit,
  marginTop: wsUnit,
  textAlign: 'center',
  borderRadius,
});

export default Notification;
