import React, { useState } from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import Radio from '../inputs/radio';
import { DebugModeData } from '../../types';
import translate from '../../utils/i18n';
import { docToModel, isModelAttributesForValidation } from '../../utils/models';
import ModalFooter from '../modals/modalFooter';
import SaveButton from '../buttons/saveButton';
import { generateGUID } from '../../utils/utils';

import type BaseModel from '../../models/baseModel';


type Props = {
  debugModeData: DebugModeData,
  unsetDebugModeData: (flag: string) => void,
};

const DocumentContainer = glamorous.div({
  margin: '1rem 0',
  background: 'gainsboro',
});

const DocumentHeader = glamorous.h2({
  fontWeight: 'bold',
  fontSize: '1.333rem',
});

/**
 * Renders Debug Mode Modal content when doc validation flag is activated.
 * @returns {React.Component} The rendered component
*/
const DocValidationDebugModalContent = ({ debugModeData, unsetDebugModeData }: Props) => {
  const [missingKeys, setMissingKey] = useState(debugModeData.models?.reduce(
    (keyMap, model) => ({ ...keyMap, [model.get('_id')]: null }), {}));

  const [isSaving, setSaving] = useState(false);


  /**
  * Call on save of validaiton modal
  * @return {undefined}
  */
  const onSaveClicked = () => {
    setSaving(true);
    if (debugModeData.saveModelsFn) {
      debugModeData.saveModelsFn(debugModeData.models
        ? debugModeData.models.map((model) => {
          const key = missingKeys[model.get('_id')];
          if (key) {
            return docToModel({ ...model.attributes, [key]: generateGUID() });
          }
          return model;
        })
        : List())
        .then(() => {
          setSaving(false);
          unsetDebugModeData('docValidation');
        });
    } else {
      setSaving(false);
    }
  };

  return (
    <div>
      <p>Doc Validation Debug Mode is Active. These documents are being saved. <br />
      You can select docs referenced by these docs and mark them as missing.<br />
      Only docs which can be validated are shown.
      </p>
      {
        debugModeData.models
          ? debugModeData.models
            .filter((model: BaseModel) => Object.keys(model.attributes)
              .filter(key => isModelAttributesForValidation(key, model.get('type'))).length > 0)
            .map((model: BaseModel) => (
              <DocumentContainer>
                <DocumentHeader>{translate(model.get('type'))} - {model.get('_id')}</DocumentHeader>
                <Radio
                  id="missing_docs_marker"
                  label={translate('dependent_documents')}
                  options={
                    [
                      ...Object.keys(model.attributes)
                        .filter(key => isModelAttributesForValidation(key, model.get('type')) &&
                          model.get(key) &&
                          !debugModeData.models?.some(m => m.get('_id') === model.get(key)))
                        .map(key => ({ label: `${key} - ${model.get(key)}`, value: key })),
                      ({ label: 'NONE', value: '' }),
                    ]
                  }
                  value={missingKeys && missingKeys[model.get('_id')]}
                  onValueChanged={
                    (key: string | null) => setMissingKey({
                      ...missingKeys,
                      [model.get('_id')]: key,
                    })
                  }
                />
              </DocumentContainer>
            ))
          : null
      }
      <ModalFooter>
        <SaveButton
          onClick={() => { onSaveClicked(); }}
          label={translate('save')}
          className="o-button--small u-margin-right--half-ws"
          dataPublic
          isSaving={isSaving}
        />
      </ModalFooter>
    </div>
  );
};

export default DocValidationDebugModalContent;
