
import React from 'react';
import { is, List, Map } from 'immutable';
import glamorous from 'glamorous';

import translate from './../../utils/i18n';
import PatientQueueItem from './patientQueueItem';
import { playSound } from './../../utils/audio';
import { colours, wsUnit, borderRadius, border, fonts } from './../../utils/css';

import type { Config } from './../../types';
import type EncounterModel from './../../models/encounterModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PractitionerModel from './../../models/practitionerModel';
import type EncounterStageModel from '../../models/encounterStageModel';

type Props = {
  config: Config,
  encounters: List<EncounterModel>,
  patientStubs: List<PatientStubModel>,
  practitioners: List<PractitionerModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
};

const QueueBackground = glamorous.div({
  backgroundColor: colours.midnightGrey,
  padding: `calc(2 * ${wsUnit})`,
  height: '100%',
  textTransform: 'uppercase',
  maxHeight: '100%',
});

const QueueContainer = glamorous.section({
  backgroundColor: colours.white,
  borderRadius,
  border,
  maxHeight: `calc(100vh - (4 * ${wsUnit}))`,
  overflow: 'hidden',
});

const QueueHeader = glamorous.header({
  backgroundColor: colours.maroonDark,
  color: colours.white,
  padding: `calc(${wsUnit} / 2) ${wsUnit}`,
  fontSize: '2em',
  fontFamily: fonts.medium,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
});

/**
 * A patient queue component.
 * @class PatientQueue
 * @extends {React.Component<Props, State>}
 */
class PatientQueue extends React.Component<Props, {}> {
  props: Props;

  /**
   * Plays an alert sound when a new encounter arrives.
   * @param {Props} prevProps Prev Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.encounters, this.props.encounters)) {
      const newEncounters = this.props.encounters.filter(encounter1 => !prevProps.encounters.some(encounter2 => encounter1.get('_id') === encounter2.get('_id')));
      newEncounters.forEach((e) => {
        const { currentStageIndex } = e.getCurrentStageIndex();
        // eslint-disable-next-line camelcase
        const stageId = e.getActiveStages().get(currentStageIndex)?.stage_id;
        const stageModel: EncounterStageModel = this.props.encounterStageMap.get(stageId);
        if (stageModel.get('in_progress_alert') !== 'none') {
          playSound(stageModel?.get('in_progress_alert', 'announcement_down.mp3'));
        }
      });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <QueueBackground>
        <QueueContainer>
          <QueueHeader>{translate('now_serving')}</QueueHeader>
          <ul>
            {
              this.props.encounters.map(e =>
                <PatientQueueItem
                  key={e.get('_id')}
                  encounter={e}
                  patientStubs={this.props.patientStubs}
                  practitioners={this.props.practitioners}
                  config={this.props.config}
                />)
            }
          </ul>
        </QueueContainer>
      </QueueBackground>
    );
  }
}

export default PatientQueue;
