import React from 'react';
import glamorous from 'glamorous';

import PharmaConnectDetailingContainer from '../../containers/pharmaConnectDetailingContainer';
import SaveButton from './../buttons/saveButton';
import Input from './../inputs/input';
import translate from './../../utils/i18n';
import { login } from '../../utils/auth';
import { logFailedLogin, logToAppInsight, debugPrint } from './../../utils/logging';
import { wsUnit, scaledH1, borderRadius, colours, fontSize, mediaQueries } from './../../utils/css';
import { getBaseApiUrl, getClinicID } from './../../utils/utils';
import { FRESHCHAT_TOKEN, FRESHCHAT_HOST, FRESHCHAT_TAGS, PHONE_NUMBERS, APP_MODE, PHARMACONNECT_ENABLED } from './../../constants';
import LoginHeader from './loginHeader';
import WhatsNew from '../WhatsNew/whatsNew';

type Props = {
  authError: string | null,
  appMode: string,
  setHasAuth: (num: number) => void,
  setUserID: (userID: string) => void,
  setWorkspace: (workspace: string) => void,
  setAuthError: (error: string | null) => void,
}

type State = {
  isLoggingIn: boolean,
  username: string,
  password: string,
  workspace: string,
  errorMessage: string,
};

const Row = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '100vw',
  height: '85vh',
});

const Column = glamorous.div({
  display: 'flex',
  flexBasis: '100%',
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const AnnouncementColumn = glamorous.div({
  display: 'flex',
  flexBasis: '100%',
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  [mediaQueries.forPhoneOnly]: {
    display: 'none',
  },
});

const Container = glamorous.div({
  margin: `${wsUnit} auto`,
  maxWidth: '1440px',
});

const LoginCard = glamorous.div({
  display: 'flex',
  backgroundColor: '#fff',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  width: '80%',
  maxWidth: '600px',
  padding: `calc(2*${wsUnit})`,
  borderRadius: `${borderRadius}`,
  // marginBottom: '3px',
});

const LoginCardContent = glamorous.div({
  marginTop: `${wsUnit}`,
  width: '100%',
});

const LoginCardSubText = glamorous.div({
  marginTop: `${wsUnit}`,
  color: colours.grey7,
  fontSize: fontSize.large,
  textAlign: 'center',
});

const LoginTitle = glamorous.h1({
  fontSize: scaledH1,
  textAlign: 'center',
  fontWeight: 'bold',
});

const LoginSubtitle = glamorous.p({
  fontSize: fontSize.large,
  textAlign: 'center',
  marginTop: `calc(0.5*${wsUnit})`,
});

const AdvertisementContainer = glamorous.div({
  maxWidth: '40%',
});

const SocialIcon = glamorous.a({
  height: '35px',
  width: '35px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '30px',
});

const SocialSubtext = glamorous.div({
  marginTop: `calc(0.5*${wsUnit})`,
  color: colours.grey7,
  fontSize: fontSize.label,
  textAlign: 'center',
});

// Since not logged in, will not know if a klinify user or not
const showDetailing = true;

/**
 * A Login component.
 * @class Login
 * @extends {React.Component}
 */
class Login extends React.Component<Props, State> {
  /**
   * Creates an instance of Login.
   * @param {object} props The initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoggingIn: false,
      username: '',
      password: '',
      workspace: getClinicID(),
      errorMessage: '',
    };
  }

  /**
   * Called when props changed
   * @returns {void}
   */
  componentDidUpdate() {
    if (window && window.fcWidget && !window.fcWidget.isInitialized()) {
      this.initialiseFreshchat();
    }
  }

  /**
   * Initialises freshchat (Anonymous user)
   * @returns {void}
   */
  initialiseFreshchat() {
    window.fcWidget.init({
      token: FRESHCHAT_TOKEN,
      host: FRESHCHAT_HOST,
      tags: FRESHCHAT_TAGS,
    });
  }

  /**
   * Called when closing the component
   * @returns {void}
   */
  componentWillUnmount() {
    if (window && window.fcWidget && window.fcWidget.isInitialized()) {
      window.fcWidget.destroy();
    }
  }

  /**
   * Validate form
   * Returns true if username and password are filled.
   * @returns {boolean}
   */
  isFormValid() {
    const { username, password } = this.state;
    return !(username.length === 0 || password.length === 0);
  }

  /**
   * Called when login is clicked. Attempts to authenticate. If successful the user is redirected
   * to postLoginHash.
   * @returns {undefined}
   */
  onLoginClicked() {
    if (!this.isFormValid()) {
      this.setState({ errorMessage: translate('fill_required_fields') });
    } else {
      this.setState({ isLoggingIn: true });
      this.props.setAuthError(null);
      login(getBaseApiUrl(), this.state.username, this.state.password, this.state.workspace)
        .then((res) => {
          if (res.ok) {
            this.props.setUserID(this.state.username);
            this.props.setHasAuth(1);
            this.props.setWorkspace(this.state.workspace);
          }
          return res;
        })
        .then(
          res =>
            res &&
            // eslint-disable-next-line consistent-return
            res.json().then((data) => {
              if (data.error) {
                logFailedLogin();
                if (data.msg && data.msg === 'Request timestamp out of range') {
                  return this.onLoginError(
                    res.status,
                    translate('pc_time_not_matched'),
                  );
                }
                this.onLoginError(res.status, res.statusText);
              }
            }),
        )
        .catch((err) => {
          this.onLoginError(err.status, err.toString());
        });
    }
  }

  /**
   * Handles diplaying of messages when error occurs.
   * @param {number} status The response status code
   * @param {string} message the response text status
   * @returns {void}
   */
  onLoginError(status: number, message: string) {
    const errorMessage =
    status === 401 ? translate('login_unauthorized_warning') : message;
    if (status !== 200 && status !== 401) {
      // UI shows the error message when there is invalid credentials, skipping 401
      debugPrint(message, 'error');
      logToAppInsight('Error occurred at login.', status);
    }
    this.setState({
      password: '',
      isLoggingIn: false,
      errorMessage: status === 200 ? '' : errorMessage,
    });
  }

  /**
  * Keypress listener for the component that will listen for an enter press and run onLoginClicked.
  * @param {React.Event} event A Keypress event.
  * @returns {undefined}
  */
  onKeyPress(event: SyntheticKeyboardEvent<>) {
    if (event.charCode === 13) {
      this.onLoginClicked();
    }
  }

  /**
  * Renders the component.
  *
  * @return {string} - HTML markup for the component
  */
  render() {
    return (
      <>
        <LoginHeader />
        <Container>
          <Row>
            <AnnouncementColumn>
              <WhatsNew />
              {PHARMACONNECT_ENABLED && showDetailing && (
              <AdvertisementContainer>
                <PharmaConnectDetailingContainer showControls cycleAdverts />
              </AdvertisementContainer>
              )}
            </AnnouncementColumn>
            <Column>
              <LoginCard onKeyPress={event => this.onKeyPress(event)}>
                <div>
                  <LoginTitle>{translate('login_title')}</LoginTitle>
                  <LoginSubtitle>
                    {translate('login_subtitle')}
                  </LoginSubtitle>
                  <LoginCardContent>
                    {this.state.errorMessage && (
                    <div className="o-form__text-block o-form__text-block--error">
                      {this.state.errorMessage}
                    </div>
                    )}
                    {this.props.authError && (
                    <div className="o-form__text-block o-form__text-block--error">
                      {this.props.authError}
                    </div>
                    )}
                    <Input
                      className="login-test-input"
                      id="username"
                      value={this.state.username}
                      required
                      label={translate('username')}
                      onValueChanged={(value) => {
                        this.setState({ username: value as string });
                      }}
                    />
                    <Input
                      id="password"
                      value={this.state.password}
                      required
                      label={translate('password')}
                      type="password"
                      onValueChanged={(value) => {
                        this.setState({ password: value as string });
                      }}
                    />
                    {this.props.appMode === APP_MODE.INTERNAL && <Input id="workspace" value={this.state.workspace} required label="workspace" onValueChanged={(value) => { this.setState({ workspace: value as string }); }} /> }
                    <SaveButton
                      dataPublic
                      style={{ marginTop: `${wsUnit}`, borderRadius: `${borderRadius}` }}
                      label={translate('login')}
                      isSaving={this.state.isLoggingIn}
                      savingLabel={translate('logging_in')}
                      fullWidth
                      onClick={() => this.onLoginClicked()}
                    />
                    <LoginCardSubText>
                      <a href="https://klin.freshdesk.com/support/solutions/articles/36000219218-alamak-i-forgot-my-password-" target="_blank" rel="noopener noreferrer" style={{ color: colours.grey7 }}>
                        {translate('login_subtext')}
                      </a>
                    </LoginCardSubText>
                  </LoginCardContent>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', width: '100%' }}>
                  <Column>
                    <SocialIcon style={{ backgroundImage: 'url(././../static/images/facebook_logo.png)' }} href="https://www.facebook.com/klinify/" target="_blank" />
                    <SocialSubtext>
                      Like our Facebook page!
                    </SocialSubtext>
                  </Column>
                  <Column>
                    <SocialIcon style={{ backgroundImage: 'url(././../static/images/klinify_logo.png)' }} href="https://www.klinify.com" target="_blank" />
                    <SocialSubtext>
                      Visit our website!
                    </SocialSubtext>
                  </Column>
                  <Column>
                    <SocialIcon style={{ backgroundImage: 'url(././../static/images/telegram_logo.png)' }} href="https://t.me/joinchat/SsY96AxrKr65aUjC" target="_blank" />
                    <SocialSubtext>
                      Contact us on Telegram!
                    </SocialSubtext>
                  </Column>
                </div>
              </LoginCard>
            </Column>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
