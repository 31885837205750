import { connect } from 'react-redux';
import { List } from 'immutable';

import type { Dispatch, State, DataView, Model, Config } from '../types';
import { setIsFetching, setCurrentDataViews, updateConfigValue, updateModels, updateConfig } from '../actions';
import { saveFactory, saveModelsFactory } from '../utils/redux';
import { getSalesItemsFormDataview } from '../dataViews';
import AsyncFetch from '../components/asyncFetch';
import SalesItemsFormItem from '../components/salesItems/salesItemsFormItem';

import type PatientModel from '../models/patientModel';
import type BillModel from '../models/billModel';
import type BillItemModel from '../models/billItemModel';


type OwnProps = {
  bill?: BillModel,
  patient?: PatientModel,
  disabled?: boolean,
  isFromEncounterHistory?: boolean,
  isFromDocValidationModal?: boolean,
  isDocValidationModalSaving?: boolean;
  validationReferrerModel?: BillItemModel,
  validationDocObject?: { id: string, type: string },
  showPrompt?: boolean,
  noSave?: boolean,
  isModalVisible?: (value: boolean) => void,
  onPromptCancel?: () => void,
  onDiscard?: () => void,
  onSaveAtDocValidationModal?: (wasSuccessful: boolean, models: List<BillItemModel>) => void;
  onChangesMade?: (changesMade: boolean) => void,
}

/**
 * @param {Object} state Current app state.
 * @param {OwnProps} ownProps props passed to the container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, user, salesItems, providers, coveragePayors, inventoryCount,
  isFetching, currentDataViews, billItems,
}: State, ownProps: OwnProps) => {
  const billID = ownProps.bill?.get('_id') || ownProps.validationReferrerModel?.get('bill_id');
  const filteredBillItems = billItems.filter(model =>
    model.get('bill_id') === billID && model.isSalesItem() && model.get('_id') !== ownProps.validationReferrerModel?.get('_id'))
    .push(...(ownProps.validationReferrerModel ? [ownProps.validationReferrerModel] : []))
  || List();
  return ({
    billItems: filteredBillItems,
    config,
    salesItems,
    inventoryCount,
    containerDataViews: getSalesItemsFormDataview(billID),
    currentDataViews,
    isFetching,
    patientID: ownProps.patient?.get('_id') || ownProps.validationReferrerModel?.get('patient_id'),
    billID: ownProps.bill?.get('_id') || ownProps.validationReferrerModel?.get('bill_id'),
    coveragePayors,
    providers,
    user,
  });
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: Model) => dispatch(updateModels(models)),
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const SalesItemFormContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(SalesItemsFormItem));

export default SalesItemFormContainer;
