import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';

import Patient from './../components/patient/patient';
import AsyncFetch from './../components/asyncFetch';
import {
  setIsFetching, setCurrentDataViews, updateModels, updateModel,
  updateInventoryItemCount, updateConfigValue, updateConfig,
} from './../actions';
import { getPatientDataViews } from './../dataViews';
import { saveFactory } from './../utils/redux';

import type { Dispatch, ReactRouterProps, Config, State, DataView } from './../types';
import type PatientModel from '../models/patientModel';
import AppointmentModel from '../models/appointmentModel';

/**
 * Returns props for a cached patient.
 * @param {PatientModel} cachedPatient The cached patient model.
 * @param {any} state App state.
 * @returns {any} The props for this container.
 */
function getPropsForCachedPatient(cachedPatient: PatientModel, state: State) {
  const patientID = cachedPatient.get('_id');

  const encounters = state.encounters.filter(m => m.get('patient_id') === patientID);
  const encountersByAppointment = encounters.filter(e => e.isCurrent() && e.get('appointment_id')).groupBy(e => e.get('appointment_id'));
  const timeSortedAppointments = state.appointments.sortBy(a => a.get('start_timestamp'));
  return {
    containerDataViews: getPatientDataViews(patientID),
    currentDataViews: getPatientDataViews(patientID), // Fake it as we're grabbing from elswehere in store. This will prevent AsyncFetch form trigerring.
    coveragePayors: state.coveragePayors,
    user: state.user,
    config: state.config,
    klinifyConfig: state.klinifyConfig,
    isOnline: state.isOnline,
    drugs: state.drugs,
    salesItems: state.salesItems,
    isFetching: state.isFetching,
    patientID,
    patientStubs: state.patientStubs,
    patient: cachedPatient,
    bills: state.bills.filter(m => m.get('patient_id') === patientID),
    encounters,
    allergies: state.allergies.filter(m => m.get('patient_id') === patientID && m.isVisible()),
    medicalCertificates: state.medicalCertificates.filter(m => m.get('patient_id') === patientID),
    prescriptions: state.prescriptions.filter(m => m.get('patient_id') === patientID),
    timeChits: state.timeChits.filter(m => m.get('patient_id') === patientID),
    showLoadingIndicator: true,
    inventoryCount: state.inventoryCount,
    metricTypes: state.metricTypes.filter(p => p.isVisible()),
    appointments: state.appointments.filter(a => encountersByAppointment.has(a.get('_id')))
      .concat(timeSortedAppointments.filter(a => (
        a.get('patient_id') === patientID
        && a.isTodayOrFuture()
        && a.isActive()
      ))),
    verifiedDrugs: state.verifiedDrugs,
    activeIngredients: state.activeIngredients,
  };
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State, { match }: ReactRouterProps) => {
  // This section is a little wacky, so here's the explanation.
  // - First we check if patient is in cache, if so go to getPropsForCachedPatient
  // - If not, fetch docs, and then generally this container will rerender and then go to
  //   getPropsForCachedPatient
  // - The weird part comes when offline. AsyncFetch will run as usual, but after completion
  //   we won't have cachedpatient still, so the app will fall through to the last return. Then
  //   we return the patientstub and use that to render patient.jsx.
  const { patientID } = match.params;
  if (!patientID) {
    throw new Error('Patient ID not found in URL.');
  }
  const cachedPatient = state.patients.find(m => m.get('_id') === patientID);
  if (cachedPatient) {
    return getPropsForCachedPatient(cachedPatient, state);
  }
  const encounters = state.encounters.filter(m => m.get('patient_id') === patientID);
  const encountersByAppointment = encounters.filter(e => e.isCurrent() && e.get('appointment_id')).groupBy(e => e.get('appointment_id'));
  const timeSortedAppointments = state.appointments.sortBy(a => a.get('start_timestamp'));
  return {
    containerDataViews: getPatientDataViews(patientID),
    currentDataViews: state.currentDataViews,
    user: state.user,
    config: state.config,
    klinifyConfig: state.klinifyConfig,
    isOnline: state.isOnline,
    isFetching: state.isFetching,
    showLoadingIndicator: true,
    coveragePayors: state.coveragePayors,
    drugs: state.drugs,
    salesItems: state.salesItems,
    // patient: undefined, // NO PATIENT WILL BE PASSED AS THEY COULDNT BE FOUND/LOADED
    patientStubs: state.patientStubs,
    patientStub: state.patientStubs.find(stub => stub.get('_id') === patientID),
    bills: state.bills.filter(m => m.get('patient_id') === patientID),
    encounters,
    allergies: state.allergies.filter(m => m.get('patient_id') === patientID && m.isVisible()),
    medicalCertificates: state.medicalCertificates.filter(m => m.get('patient_id') === patientID),
    prescriptions: state.prescriptions.filter(m => m.get('patient_id') === patientID),
    timeChits: state.timeChits.filter(m => m.get('patient_id') === patientID),
    inventoryCount: state.inventoryCount,
    patientID,
    metricTypes: state.metricTypes.filter(p => p.isVisible()),
    appointments: state.appointments.filter(a => encountersByAppointment.has(a.get('_id')))
      .concat(timeSortedAppointments.filter(a => (
        a.get('patient_id') === patientID
        && a.isTodayOrFuture()
        && a.isActive()
      ))),
    verifiedDrugs: state.verifiedDrugs,
    activeIngredients: state.activeIngredients,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  // For patient related models we dont save them as the view, rather we just keep them in shared doc cache.
  setCurrentDataViewsModels: models => dispatch(updateModels(models)),
  addModelToStore: (model: AppointmentModel) => dispatch(updateModel(model)),
  saveModel: saveFactory(dispatch),
  updateInventoryItemCount: (skuID: string, change: number) =>
    dispatch(updateInventoryItemCount(skuID, change)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const PatientContainer =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(Patient)));

export default PatientContainer;
