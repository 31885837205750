import { List, Map } from 'immutable';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { docToModel } from '../utils/models';

import type SalesItemModel from './salesItemModel';
import type PractitionerModel from './practitionerModel';
import type EncounterFlowModel from './encounterFlowModel';

import BaseModel from './baseModel';
import { UNICODE, APPOINTMENT_FLOW_FALLBACK_ENABLED } from './../constants';
import { getNotesStringFromObject, getSalesItemModel, getSalesItemsFromEncounterType } from '../utils/encounters';
import EncounterStageModel from './encounterStageModel';
import { debugPrint } from '../utils/logging';
import PatientStubModel from './patientStubModel';
import { AppointmentStatus } from '../../src/types';

export type PatientNotification = {
  type: string,
  action: 'reschedule' | 'book' | 'cancel',
  status: 'processed' | 'deferred' | 'delivered' | 'bounced' | 'dropped',
  timestamp?: number,
}

type AppointmentRequestData = {
  name: string,
  ic: string,
  email: string,
  phone: string,
  postal_code: string,
  consult_mode: 'tele_consult' | 'walk_in',
  start_timestamp: number,
  end_timestamp?: number,
}

type attributesType = {
  _id: string,
  patient_id: string,
  practitioner_id: string,
  type: 'appointment',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  start_timestamp: number,
  end_timestamp?: number,
  status: AppointmentStatus,
  consult_mode: 'tele_consult',
  tele_consult?: {
    url: string,
  },
  flow_id: string,
  patient_notifications?: List<PatientNotification>,
  notes?: string,
  request_data?: AppointmentRequestData,
};

/**
   * AppointmentModel
   *
   * @namespace AppointmentModel
   */
export default class AppointmentModel extends BaseModel {
  attributes: attributesType;

  unread: boolean; // static variable to check for the showing tooltip when unread

  /**
   * @param {object} attributes - The attributes for this model.
   * @returns {void}
   */
  constructor(attributes: {} = {}) {
    const { status } = attributes as attributesType;
    super(attributes);
    this.attributes.type = 'appointment';
    this.unread = status === 'pending';
  }

  /**
   * @param {boolean} uread - appointment has been acknowleged or not.
   * @returns {AppointmentModel}
   */
  setUnreadCheck(uread: boolean) {
    const model = docToModel(this.attributes) as AppointmentModel;
    model.unread = uread;
    return model;
  }

  /**
   * Get latest patient notification for the appointment
   * @returns {PatientNotification | undefined} PatientNotification object or undefined.
   */
  getLatestNotification() {
    const patientNotifications = this.get('patient_notifications', []);
    const latestNotif = patientNotifications.reduce((notif: any, item: any) => {
      if (isEmpty(notif) || Number(item.timestamp) > Number(notif.timestamp)) {
        return { ...item };
      }
      return notif;
    }, {});
    return latestNotif;
  }

  /**
   * Get latest patient notification for the appointment
   * @returns {PatientNotification | undefined} PatientNotification object or undefined.
   */
  isEmailDelivered() {
    const patientNotification = this.getLatestNotification();
    return patientNotification && patientNotification.type === 'email'
      && patientNotification.status === 'delivered';
  }

  /**
   * Get the Doctor name for this appointment. If the practitioner is not found then the doctor id
   * specified on the appointment is returned. If the doctor field is not specified an empty string is
   * returned.
   * @param {List<PractitionerModel>} practitioners List of all practitioners
   * @returns {string}
   */
  getDoctorName(practitioners: List<PractitionerModel>) {
    const practitioner = practitioners.find(p => p.get('_id') === this.get('practitioner_id'));
    return practitioner ? practitioner.get('name', '') : this.get('practitioner_id', '');
  }

  /**
   * Get the patient name for this appointment
   * @param {List<PatientStubModel>} patients List of all patients
   * @returns {string}
   */
  getPatientName(patients: List<PatientStubModel>) {
    const patient = patients.find(p => p.get('_id') === this.get('patient_id'));
    return patient ? patient.get('patient_name', '') : this.get('patient_id');
  }

  /**
   * Returns the name of the salesItem referenced at this.attributes.consult_type, or an emdash
   * if not found.
   * @param {Immutable.List} salesItems A List of SalesItemModels.
   * @param {Map<string, EncounterFlowModel>} encounterFlowMap map of encounter flow and _id
   * @returns {string} The consult type name.
   */
  getEncounterType(salesItems: List<SalesItemModel>,
    encounterFlowMap?: Map<string, EncounterFlowModel>): string {
    if (this.get('flow_id')) {
      return encounterFlowMap?.get(this.get('flow_id'))?.get('name') || UNICODE.EMDASH;
    }
    if (!this.has('consult_type') || this.get('consult_type').length === 0) {
      return UNICODE.EMDASH;
    }
    const salesItem = salesItems.find(i => i.get('_id') === this.get('consult_type'));
    return salesItem ? salesItem.get('name') : this.get('consult_type');
  }

  /**
   * Returns whether the appointment is cancelled or not.
   * @returns {boolean} true if the appointment status is 'cancelled]'
   */
  isCancelled(): boolean {
    return this.get('status') === 'cancelled';
  }

  /**
   * Returns whether the appointment is pending or not.
   * @returns {boolean} true if the appointment status is 'pending'
   */
  isPending(): boolean {
    return this.get('status') === 'pending';
  }

  /**
   * Returns whether the appointment is still active and should be shown
   * @returns {boolean} true if the appointment status is 'pending' or 'booked'
   */
  isActive(): boolean {
    return this.get('status') !== 'fulfilled' && this.get('status') !== 'cancelled';
  }

  /**
   * Returns whether the appointment is started today
   * @returns {boolean} true if the appointment status today else false
   */
  isToday(): boolean {
    const startTimestamp = this.get('start_timestamp');
    return startTimestamp && moment(startTimestamp).startOf('day').valueOf() === moment().startOf('day').valueOf();
  }

  /**
   * Returns whether the appointment is started today or schdule for future
   * @returns {boolean} true if the appointment schedule for today or future
   */
  isTodayOrFuture(): boolean {
    const startTimestamp = this.get('start_timestamp');
    return startTimestamp && moment(startTimestamp).startOf('day').valueOf() >= moment().startOf('day').valueOf();
  }

  /**
   * Returns whether the appointment is started in the past
   * @returns {boolean} true if the appointment schedule was in the past
   */
  isPast(): boolean {
    const startTimestamp = this.get('start_timestamp');
    return startTimestamp && moment(startTimestamp).startOf('day').valueOf() < moment().startOf('day').valueOf();
  }

  /**
   * Returns whether the appointment consult mode is tele consult or not.
   * @returns {boolean} true if the appointment consult mode is tele consult.
   */
  isTeleConsult(): boolean {
    return this.get('consult_mode') === 'tele_consult';
  }

  /**
   * Returns data value start_timestamp
   * @returns {Date} date value of start_timestamp
   */
  getStartDateTime(): Date {
    return moment(this.get('start_timestamp')).toDate();
  }

  /**
   * Returns data value end_timestamp
   * @returns {Date} date value of end_timestamp
   */
  getEndDateTime(): Date {
    if (!this.get('end_timestamp')) { return this.getStartDateTime(); }
    return moment(this.get('end_timestamp')).toDate();
  }

  /**
   * Returns the duration of the appointment (end_timestamp - start_timestamp) in minutes
   * @returns {number} duration in minutes
   */
  getDuration(): number {
    if (this.get('end_timestamp')) {
      return moment(this.get('end_timestamp')).diff(moment(this.get('start_timestamp')), 'minutes');
    }
    return 0;
  }

  /**
   * Returns the appointment consult mode
   * @returns {string} the appointment consult mode is tele consult or walkin.
   */
  getConsultMode(): 'tele_consult' | 'walk_in' {
    return this.get('consult_mode') || 'walk_in';
  }

  /**
   * Returns the appointment consult mode
   * @returns {string} the appointment consult mode is tele consult or walkin.
   */
  getConsultModeText(): string {
    return (this.get('consult_mode') || 'walk_in') === 'walk_in' ? 'Walk-in' : 'E-Consultation';
  }

  /**
   * Return appointment request data or void if not found.
   * @returns {AppointmentRequestData | void} request data or void.
   */
  getRequestData(): AppointmentRequestData | void {
    return this.get('request_data', undefined, false, false);
  }

  /**
   * Related sales item for this appointment
   * @param {List<SalesItemModel>} salesItems SalesItemModels
   * @param {Map<string, EncounterFlowModel>} encounterFlowMap map of encounter flow and _id
   * @param {List<EncounterStageModel>} encounterStagesMap encounter stages Map
   * @returns {List<{salesItem: SalesItemModel, quantity: number}>}
   */
  getSalesItems(salesItems: List<SalesItemModel>,
    encounterFlowMap: Map<string, EncounterFlowModel> | undefined,
    encounterStagesMap?: Map<string, EncounterStageModel>): Promise<List<{salesItem: SalesItemModel, quantity: number}>> {
    if (this.get('consult_type')) {
      return getSalesItemModel(this.get('consult_type'), salesItems).then(salesItem => List([{ salesItem, quantity: salesItem.getDefaultQuantity() }]));
    }
    if (this.get('flow_id') && encounterFlowMap && encounterStagesMap) {
      const encounterFlow = encounterFlowMap.get(this.get('flow_id'));
      return getSalesItemsFromEncounterType(salesItems, encounterFlow, encounterStagesMap);
    }
    return Promise.resolve(List());
  }

  /**
   * Returns fields for creating encounter doc from AppointmentModel
   * @param {EncounterFlowModel} encounterFlowModel selected encounter flow model
   * @param {List<EncounterStageModel>} encounterStagesMap encounter stages Map
   * @returns {Object}
   */
  getEncounterAttributes(encounterFlowModel?: EncounterFlowModel,
    encounterStagesMap?: Map<string, EncounterStageModel>) {
    if (!APPOINTMENT_FLOW_FALLBACK_ENABLED && encounterFlowModel) {
      // App Rolled back after encounter stage feature deployment
      const initialNotes = encounterFlowModel.get('stages', []).reduce((linkedStage: List<{ stage: string, notes: ''}>, stageId: string) => {
        const stageInfo = encounterStagesMap?.get(stageId);
        if (stageInfo) {
          return linkedStage.push({
            stage: stageInfo.get('name'),
            notes: '',
          });
        }
        debugPrint('Stage linked with flow not found');
        return linkedStage;
      }, List());
      return {
        notes: getNotesStringFromObject(initialNotes),
        consult_type: encounterFlowModel.get('name'),
      };
    }
    return {};
  }
}
