import { List } from 'immutable';

import BaseModel from './baseModel';

import type { AssetObject } from './../types';
import type ProcedureStatusModel from './procedureStatusModel';
import type ProcedureTypeModel from './procedureTypeModel';
import type CollectedSpecimenModel from './collectedSpecimenModel';
import type SpecimenModel from './specimenModel';
import type ProcedureResultModel from './procedureResultModel';
import type PatientStubModel from './patientStubModel';
import type ProviderModel from './providerModel';

type Attributes = {
  _id: string,
  internal_clinic_id?: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'procedure_request',
  patient_id: string,
  encounter_id: string,
  procedure_type_id: string,
  urgent?: boolean,
  requester?: string,
  reason?: string,
  notes?: string,
  body_site?: string,
  contact_fax?: string,
  contact_telephone?: string,
  copy_to?: string,
  bill_to?: string,
  payment_state?: string,
  completion_state?: string,
  practitioner_id: string,
};

/**
  * ProcedureRequestModel
  * @namespace ProcedureRequestModel
  */
class ProcedureRequestModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'procedure_request';
  }

  /**
   * Returns true if item status is 'active'.
   * @returns {boolean} True if item is active.
   */
  isActive(): boolean {
    return this.get('status') === 'active';
  }

  /**
   * Get the date when the procedure_status and collected_specimen doc was updated
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel
   * @returns {number} timestamp
   */
  getLastEventTimestampOfProcedureStatusAndCollectedSpecimen(
    procedureStatuses: List<ProcedureStatusModel>, collectedSpecimens: List<CollectedSpecimenModel>,
    procedureTypes: List<ProcedureTypeModel>,
  ) {
    const dateNow = new Date().getTime();
    const procedureStatus = this.getStatus(procedureStatuses);
    const collectedSpecimen = this.getCollectedSpecimen(procedureTypes, collectedSpecimens);
    const procedureStatusTimestamp = procedureStatus ? procedureStatus.get('events')[procedureStatus.get('events').length - 1].timestamp : dateNow;
    const collectedSpecimenTimestamp = collectedSpecimen ? collectedSpecimen.get('events')[collectedSpecimen.get('events').length - 1].timestamp : dateNow;
    return Math.max(procedureStatusTimestamp, collectedSpecimenTimestamp);
  }

  /**
   * Returns the ProcedureType based on the ProcedureRequest 'procedure_type_id'.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @returns {ProcedureTypeModel}
   */
  getType(procedureTypes: List<ProcedureTypeModel>) {
    return procedureTypes.find(model => model.get('_id') === this.get('procedure_type_id'));
  }

  /**
   * Returns the Procedure type name
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @returns {string}
   */
  getTypeName(procedureTypes: List<ProcedureTypeModel>) {
    const procedureType = this.getType(procedureTypes);
    return procedureType ? procedureType.getName() : '';
  }

  /**
   * Returns the Provdider based on the ProcedureType
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<ProviderModel>} providers List of ProviderModel.
   * @returns {ProviderModel}
   */
  getProviderName(procedureTypes: List<ProcedureTypeModel>, providers: List<ProviderModel>) {
    return this.getType(procedureTypes).getProviderName(providers);
  }

  /**
   * Returns the status of the ProcedureRequest.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @returns {ProcedureStatusModel}
   */
  getStatus(procedureStatuses: List<ProcedureStatusModel>) {
    return procedureStatuses.find(model =>
      model.get('procedure_request_id') === this.get('_id') &&
      model.get('procedure_type_id') === this.get('procedure_type_id') &&
      model.get('patient_id') === this.get('patient_id'));
  }

  /**
   * Returns the last event status eg. 'pending', 'sent_success' or 'sent_error'.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @returns {(string | undefined)}
   */
  getStatusLastEventStatus(procedureStatuses: List<ProcedureStatusModel>) {
    const procedureStatus = this.getStatus(procedureStatuses);
    return procedureStatus ? procedureStatus.getLastEventStatus() : 'pending';
  }

  /**
   * Adds an event with the given status to the ProcedureStatusModel.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @param {string} status The status eg. 'sent_success'.
   * @param {string} notes The motes for this event.
   * @returns {void}
   */
  addStatusEvent(procedureStatuses: List<ProcedureStatusModel>, status: string, notes: string = '') {
    return this.getStatus(procedureStatuses).addEvent(status, notes);
  }

  /**
   * Returns a untranslated string that will be use in displaying procedure status in the table
   * eg. 'pending', 'results_received', received_by_provider.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @returns {string}
   */
  getStatusUiStatus(procedureStatuses: List<ProcedureStatusModel>) {
    const procedureStatus = this.getStatus(procedureStatuses);
    return procedureStatus ? this.getStatus(procedureStatuses).getUiStatus() : 'pending';
  }

  /**
   * Returns a untranslated string that will be use in displaying collected status in the table
   * eg. 'courier_collected', 'specimens_rejected', 'specimens_received_by'.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {string}
   */
  getCollectedSpecimenUiStatus(procedureStatuses: List<ProcedureStatusModel>,
    procedureTypes: List<ProcedureTypeModel>,
    collectedSpecimens: List<CollectedSpecimenModel>) {
    const collectedSpecimen = this.getCollectedSpecimen(procedureTypes, collectedSpecimens);
    return collectedSpecimen ?
      collectedSpecimen.getUiStatus(this, procedureStatuses) :
      this.getStatusUiStatus(procedureStatuses);
  }

  /**
   * Returns the procedure request status in the table.
   * Combination of procedure status and collected specimen status.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {string}
   */
  getUiStatus(procedureStatuses: List<ProcedureStatusModel>,
    procedureTypes: List<ProcedureTypeModel>,
    collectedSpecimens: List<CollectedSpecimenModel>) {
    const typeIntegrations = this.getTypeIntegrations(procedureTypes);
    const collectedSpecimensLastEventStatus = this.getCollectedSpecimensLastEventStatus(
      procedureTypes,
      collectedSpecimens,
    );
    const statusLastEventStatus = this.getStatusLastEventStatus(procedureStatuses);
    if (collectedSpecimensLastEventStatus && statusLastEventStatus === 'cancelled') {
      return 'request_cancelled';
    } else if (!typeIntegrations && (statusLastEventStatus === 'rejected') &&
      collectedSpecimensLastEventStatus === 'courier_collected') {
      return 'order_rejected';
    }
    // If one of these is the current status,
    // it will override collected specimen status if it's already
    // 'courier collected' | 'provider received' | 'provider rejected'
    const statusesToPriorities = [
      'in_progress',
      'completed',
      'approved',
      'results_received',
      'rejected',
      'cancelled',
      'reflex',
      'mismatch',
    ];
    if (statusesToPriorities.includes(statusLastEventStatus)) {
      return statusLastEventStatus;
    }
    return this.getCollectedSpecimenUiStatus(
      procedureStatuses,
      procedureTypes,
      collectedSpecimens,
    );
  }

  /**
   * Returns collected specimens (with their status in it) that requires by the ProcedureType.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {List<CollectedSpecimenModel>}
   */
  getCollectedSpecimens(
    procedureTypes: List<ProcedureTypeModel>,
    collectedSpecimens: List<CollectedSpecimenModel>,
  ) {
    const procedureType = this.getType(procedureTypes);
    return procedureType ? procedureType.getCollectedSpecimens(this, collectedSpecimens) : List();
  }

  /**
   * Returns a single CollectedSpecimen model
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {CollectedSpecimenModel}
   */
  getCollectedSpecimen(
    procedureTypes: List<ProcedureTypeModel>,
    collectedSpecimens: List<CollectedSpecimenModel>,
  ) {
    return this.getCollectedSpecimens(procedureTypes, collectedSpecimens)
      .toArray()[0];
  }

  /**
   * Returns the last event status eg. 'pending', 'collected' or 'courier_collected' of one of
   * the collected specimen.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {(string | undefined)}
   */
  getCollectedSpecimensLastEventStatus(
    procedureTypes: List<ProcedureTypeModel>,
    collectedSpecimens: List<CollectedSpecimenModel>,
  ) {
    const collectedSpecimen = this.getCollectedSpecimen(procedureTypes, collectedSpecimens);
    return collectedSpecimen ? collectedSpecimen.getLastEventStatus() : undefined;
  }

  /**
   * Returns true if the collected specimen has the status given.
   * @param {string} status The event status
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {boolean}
   */
  isCollectedSpecimensHasStatus(
    status: string,
    procedureTypes: List<ProcedureTypeModel>,
    collectedSpecimens: List<CollectedSpecimenModel>,
  ) {
    const collectedSpecimen = this.getCollectedSpecimen(procedureTypes, collectedSpecimens);
    return collectedSpecimen ? collectedSpecimen.hasStatus(status) : false;
  }

  /**
   * Returns list specimens that requires by the ProcedureType.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @param {List<SpecimenModel>} specimens List of SpecimenModel.
   * @returns {List<SpecimenModel>} List of SpecimenModel.
   */
  getSpecimens(procedureTypes: List<ProcedureTypeModel>, specimens: List<SpecimenModel>) {
    return this.getType(procedureTypes).getSpecimens(specimens);
  }

  /**
   * Returns the result of the ProcedureRequest.
   * @param {List<ProcedureResultModel>} procedureResults List of ProcedureResultModel.
   * @returns {ProcedureResultModel}
   */
  getResult(procedureResults: List<ProcedureResultModel>) {
    return procedureResults.find(model =>
      model.get('procedure_request_id') === this.get('_id') &&
      model.get('procedure_type_id') === this.get('procedure_type_id') &&
      model.get('patient_id') === this.get('patient_id'));
  }

  /**
   * Returns the result assets of the ProcedureRequest.
   * @param {List<ProcedureResultModel>} procedureResults List of ProcedureResultModel.
   * @returns {(AssetObject | undefined)}
   */
  getResultAssets(procedureResults: List<ProcedureResultModel>) {
    return this.getResult(procedureResults) ?
      this.getResult(procedureResults).getAssets() : undefined;
  }

  /**
   * Adds an asset to the ProcedureResultModel
   * @param {List<ProcedureResultModel>} procedureResults List of ProcedureResultModel
   * @param {AssetObject} asset An asset object.
   * @returns {void}
   */
  addResultAsset(procedureResults: List<ProcedureResultModel>, asset: AssetObject) {
    return this.getResult(procedureResults).addAsset(asset);
  }

  /**
   * Returns the Provider LIS integrations settings and information.
   * @param {List<ProcedureTypeModel>} procedureTypes List of ProcedureTypeModel.
   * @returns {void}
   */
  getTypeIntegrations(procedureTypes: List<ProcedureTypeModel>) {
    const procedureType = this.getType(procedureTypes);
    return procedureType ? procedureType.getIntegrations() : undefined;
  }

  /**
   * Returns the Patient name for this procedure request.
   * @param {List<PatientStubModel>} patientStubs List of PatientStubModel
   * @return {string}
   */
  getPatientName(patientStubs: List<PatientStubModel>) {
    return patientStubs.find(model => model.get('_id') === this.get('patient_id')).get('patient_name');
  }
}

export default ProcedureRequestModel;
