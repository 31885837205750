import React from 'react';
import { Menu, MainButton, ChildButton } from 'react-mfb';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';

import CaseNoteFileModel from './../../models/caseNoteFileModel';
import translate from './../../utils/i18n';
import { saveBlob, isImageFile, handleResponseError } from './../../utils/utils';
import { getHighestCasenoteOrder } from './../../utils/db';
import { createSuccessNotification, createInfoNotification } from './../../utils/notifications';
import { CASENOTE_ORDER_INCREMENT } from './../../constants';
import { getReferralQueryString } from './../../utils/router';
import { hasPermission, createPermission } from './../../utils/permissions';

import type { Config, SaveModel, User, ComponentReference } from './../../types';

type Props = {
  patientID: string,
  config: Config,
  klinifyConfig: Config,
  categoryID?: string | null | undefined,
  saveModel: SaveModel,
  isOnline: boolean,
  user: User,
  history?: {
    push: (path: string) => void,
  },
};

/**
 * A floating button that shows create actions for the given patient.
 * @class PatientActionButton
 * @extends {React.Component<Props, State>}
 */
class PatientActionButton extends React.PureComponent<Props> {
  props: Props;

  node: ComponentReference;

  uploadInput: HTMLInputElement | null | undefined;

  /**
   * Closes the menu when a click occurs and optionally reroutes if a URL is given.
   * @param {string} url An optional url to route to.
   * @return {void}
   */
  onClick(url?: string) {
    const fakeEvent = { preventDefault: () => {} };
    this.node.toggleMenu(fakeEvent);
    if (url && this.props.history) {
      this.props.history.push(url);
    }
  }

  handleUploadClick = () => {
    this.onClick();
    if (this.uploadInput) {
      this.uploadInput.click();
    }
  }

  handleUpload = () => {
    if (this.uploadInput && this.uploadInput.files.length > 0) {
      const file = this.uploadInput.files[0];
      if (file.size > 10485760) { // 10MB
        createInfoNotification(translate('upload_file_limit_error'));
      } else {
        saveBlob(file)
          .then((assetObject) => {
            const title = file.name.replace(/\.[^/.]+$/, '');
            const casenote = new CaseNoteFileModel({
              category: this.props.categoryID || this.props.config.getIn(['categories', 'notes_category'], '0_KLINIFY_NOTES'),
              patient_id: this.props.patientID,
              source: { webapp_imported: true },
              assets: [assetObject],
              star: false,
              order: 0,
              title,
              file_name: file.name,
            });
            getHighestCasenoteOrder(this.props.patientID)
              .then(order => this.props.saveModel(casenote.set('order', order + CASENOTE_ORDER_INCREMENT)))
              .then(() => {
                createSuccessNotification(translate(isImageFile(file.type) ? 'image_uploaded' : 'file_uploaded', { name: file.name }));
              })
              .catch((error) => {
                handleResponseError(error);
              });
          });
      }
    }
  }

  /**
   * Gets the buttons to be displayed based on the permissions and connectivity status.
   * @returns {Array<React.Component>}
   */
  getButtons() {
    let buttons = [];
    if (hasPermission(this.props.user, List([createPermission('patient_metrics', 'create')]))) {
      buttons.push(<ChildButton
        key="add-vitals"
        icon="fa fa-thermometer-three-quarters"
        label={translate('add_vitals')}
        onClick={() => this.onClick(`/patient/${this.props.patientID}/vitals/ALL/add${getReferralQueryString()}`)}
      />);
    }
    if (
      this.props.isOnline &&
      hasPermission(this.props.user, List([createPermission('patient_casenotes', 'create')]))
    ) {
      const defaultCategory = this.props.config
        .getIn(['categories', 'notes_category'], '0_KLINIFY_NOTES');
      buttons = buttons.concat([
        <ChildButton
          key="upload-file"
          icon="fa fa-file-text-o"
          label={translate('upload_file')}
          onClick={this.handleUploadClick}
        />,
      ]);
      if (!this.props.klinifyConfig.getIn(['patient_action_button', 'hide_add_notes_templates_text_buttons'], false)) {
        buttons = buttons.concat([
          <ChildButton
            key="add-template"
            icon="fa fa-file-image-o"
            label={translate('add_template')}
            onClick={() => this.onClick(`/patient/${this.props.patientID}/templates${getReferralQueryString()}`)}
          />,
          <ChildButton
            key="add-text-casenote"
            icon="fa fa-file-text-o"
            label={translate('add_text_casenote')}
            onClick={() => this.onClick(`/patient/${this.props.patientID}/categories/${defaultCategory}/add-text`)}
          />,
          <ChildButton
            key="add-note"
            icon="fa fa-file-text-o"
            label={translate('add_note')}
            onClick={() => this.onClick(`/patient/${this.props.patientID}/categories/${defaultCategory}/add`)}
          />,
        ]);
      }
    }
    return buttons;
  }

  /**
   * Renders the menu items.
   * @returns {React.Component}
   */
  renderMenu() {
    const buttons = this.getButtons();
    return buttons.length === 0 ?
      null :
      <Menu key="patientActionButton" effect="slidein-spring" position="br" ref={(node) => { this.node = node; }}>
        <MainButton iconResting="fa fa-plus" iconActive="fa fa-times" />
        {buttons}
      </Menu>;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return [
      <input
        key="hiddenUploadInput"
        type="file"
        id="upload-input"
        style={{ display: 'none' }}
        ref={(uploadInput) => { this.uploadInput = uploadInput; }}
        onChange={this.handleUpload}
      />,
      this.renderMenu(),
    ];
  }
}

export default withRouter(PatientActionButton);
