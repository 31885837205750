import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import AddClaimInvoice from './../components/claimInvoices/addClaimInvoice';
import AsyncFetch from './../components/asyncFetch';
import { saveModelsAPIFactory, saveModelsFactory } from './../utils/redux';
import { compareByAlphabeticalOrder } from './../utils/comparators';
import { generateClaimInvoiceModels, voidClaimInvoiceModels } from './../utils/api';
import {
  setAddClaimInvoiceFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
} from './../actions';
import { getStartOfMonth, getEndOfMonth } from './../utils/time';
import { getAddClaimInvoiceDataViews } from './../dataViews';

import type { State, Dispatch, Model, DataView, ClaimInvoiceMetadata } from './../types';
import type BaseModel from '../models/baseModel';

/**
 * Returns a List of claims filtered by validity and the given Coverage Payor.
 * @param {List<Model>} models A List of models.
 * @param {string} coveragePayorID The coverage payor ID to filter for.
 * @returns {List<Model>}
 */
function filterClaims(models: List<Model>, coveragePayorID: string): List<Model> {
  return models.filter(
    (c: Model) => c && c.get('type') === 'claim'
      && c.canBeAssignedToInvoice()
      && c.get('coverage_payor_id') === coveragePayorID,
  );
}

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  coveragePayors,
  patientStubs,
  reportsFilter,
  isFetching,
  currentDataViews,
  currentDataViewsModels,
  salesItems,
  config,
  drugs,
}: State) => {
  const { month, year } = reportsFilter
    .get('addClaimInvoice')
    .get('month', Map({ month: 1, year: 2018 }))
    .toJS();
  return {
    coveragePayors: coveragePayors
      .filter(c => c.isVisible())
      .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
    claims: filterClaims(currentDataViewsModels, reportsFilter.get('addClaimInvoice').get('coveragePayorID')),
    unfilteredClaims: currentDataViewsModels.filter(m => m && m.get('type') === 'claim'),
    claimInvoices: currentDataViewsModels.filter(m => m && m.get('type') === 'claim_invoice'),
    bills: currentDataViewsModels.filter(m => m && m.get('type') === 'bill'),
    encounters: currentDataViewsModels.filter(m => m && m.get('type') === 'encounter'),
    patientStubs,
    filter: reportsFilter.get('addClaimInvoice'),
    isFetching,
    containerDataViews: getAddClaimInvoiceDataViews(
      getStartOfMonth(month - 1, year),
      getEndOfMonth(month - 1, year),
    ),
    currentDataViews,
    salesItems,
    showLoadingIndicator: true,
    config,
    drugs,
    billItems: currentDataViewsModels.filter(m => m && m.get('type') === 'bill_item'),
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, any>) => dispatch(setAddClaimInvoiceFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  saveModels: saveModelsFactory(dispatch),
  saveClaimInvoiceModels: (invoices: ClaimInvoiceMetadata[], regenerate: boolean = false) => (
    saveModelsAPIFactory(dispatch)(() => generateClaimInvoiceModels(invoices, regenerate, dispatch))
  ),
  voidClaimInvoiceModels: (invoices: Array<string>) => (
    saveModelsAPIFactory(dispatch)(() => voidClaimInvoiceModels(invoices, dispatch))
  ),
});

const AddClaimInvoiceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AsyncFetch(AddClaimInvoice));

export default AddClaimInvoiceContainer;
