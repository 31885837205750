import glamorous from 'glamorous';
import { wsUnit } from '../../utils/css';

const NotificationStack = glamorous.div({
  position: 'fixed',
  left: '25%',
  bottom: wsUnit,
  width: '50%',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export default NotificationStack;
