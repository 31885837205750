import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import Table from './../table/table';
import { createSuccessNotification } from './../../utils/notifications';
import { getConfirmation } from './../../utils/utils';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';
import LabsForm from './labsForm';
import Button from './../buttons/button';

import type ProviderModel from './../../models/providerModel';
import type { SaveModel, User } from './../../types';

type Props = {
  providers: List<ProviderModel>,
  saveModel: SaveModel,
  user: User,
};

type State = {
  modelBeingEdited?: string,
};

/**
 * Called when delete is clicked on an item. Updates its state to be hidden.
 * @param {ProviderModel} item The item to delete
 * @param {SaveModel} saveModel saveModel function
 * @returns {void}
 */
function onDeleteClicked(item: ProviderModel, saveModel: SaveModel) {
  if (item) {
    getConfirmation(translate('confirm_lab_deletion'))
      .then(
        () => {
          saveModel(item.setActive(false))
            .then(() => {
              createSuccessNotification(translate('lab_deleted'));
            });
        },
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {ProviderModel} item An item for the delete button
 * @param {SaveModel} saveModel saveModel function
 * @param {User} user User.
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(item: ProviderModel, saveModel: SaveModel, user: User) {
  return (
    <PermissionWrapper permissionsRequired={List([createPermission('laboratories', 'delete')])} user={user}>
      <Button
        className="o-text-button o-text-button--danger"
        onClick={() => onDeleteClicked(item, saveModel)}
        disabled={item.getIntegrations()}
        dataPublic
      >
        {translate('delete')}
      </Button>
    </PermissionWrapper>
  );
}

/**
 * Component displaying all Claim Invoices.
 * @class LabsTable
 * @extends {React.Component}
 */
class LabsTable extends React.Component<Props, State> {
  /**
   * Creates an instance of LabsTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = { modelBeingEdited: undefined };
  }

  /**
   * Generates the table rows from the SupplyItemModels.
   * @param {ProviderModel} provider a Provider model
   * @returns {[Row]} The rows of the table
   */
  getRow(provider: ProviderModel) {
    return {
      name: provider.get('name'),
      edit: this.getEditButton(provider),
      delete: getDeleteButton(provider, this.props.saveModel, this.props.user),
    };
  }

  /**
   * Returns an edit button component for the given item.
   * @param {ProviderModel} item The item the edit button is for.
   * @param {User} user User.
   * @returns {React.Component} A edit button.
   */
  getEditButton(item: ProviderModel) {
    return (
      <StatelessModal
        id={`form-modal-${item.get('_id')}`}
        buttonLabel={translate('view_edit_details')}
        buttonClass="o-text-button o-text-button--contextual"
        title={translate('add_edit_laboratory')}
        className="js-modal"
        visible={this.state.modelBeingEdited === item.get('_id')}
        setVisible={isVisible => this.setState({ modelBeingEdited: isVisible ? item.get('_id') : undefined })}
        explicitCloseOnly
        dataPublicHeader
      >
        <LabsForm
          onSave={provider =>
            this.props.saveModel(provider).then(() => {
              this.setState({ modelBeingEdited: undefined });
              return provider;
            })
          }
          onCancel={() => this.setState({ modelBeingEdited: undefined })}
          modelToEdit={item}
          disabled={item.getIntegrations() || !hasPermission(this.props.user, List([createPermission('laboratories', 'update')]))}
        />
      </StatelessModal>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card u-margin-bottom--4ws">
        <h2 data-public className="o-card__title">{translate('laboratories')}</h2>
        <Table
          columns={[
            { accessor: 'name', Header: translate('name') },
            { accessor: 'edit', Header: '', sortable: false, show: true, width: 100 },
            { accessor: 'delete', Header: '', sortable: false, width: 100, show: true },
          ]}
          data={this.props.providers.map(p => this.getRow(p)).toArray()}
          noDataText={translate('no_laboratories_created')}
        />
      </div>
    );
  }
}

export default LabsTable;
