import React, { useCallback } from 'react';
import glamorous from 'glamorous';
import { startCase } from 'lodash';

const Card = glamorous.div({
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.07), 0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
});

const Column = glamorous.table({
  flex: 1,
});

const Td = glamorous.td({
  display: 'table-cell',
});

const Label = glamorous.td(({ isLeft }: { isLeft: boolean}) => ({
  display: 'table-cell',
  lineHeight: 1.7,
  width: isLeft ? '22%' : '32%',
}));

type Props = {
 title: string,
 data: {
   [key: string]: string | number,
 }
};

/**
 * @returns {SFC}
 */
const InventoryItemCard: React.SFC<Props> = ({ title, data }: Props) => {
  const mapDataToColumns = useCallback(
    () => { // Divides keys of data into two for displaying as two columns
      const keys = Object.keys(data);
      const col1 = keys.slice(0, (keys.length / 2));
      const col2 = keys.slice(keys.length / 2);
      return { col1, col2 };
    },
    [],
  );

  /**
   * @param {Array<string>} keys keys from data object
   * @param {boolean} isLeft boolean to indicate if the card is in left side
   * @returns {React.Element}
   */
  const renderInfoFromKeys = (keys: Array<string>, isLeft: boolean) => keys.map(key => (
    <tr key={key}>
      <Label className="o-label" isLeft={isLeft}>{startCase(key)}:</Label>
      <Td className="u-font-small">&nbsp;{data[key]}</Td>
    </tr>
  ));

  const { col1, col2 } = mapDataToColumns();

  return (
    <Card className="u-margin--standard">
      <header className="o-card__header">
        <h1 className="o-card__title">{title}</h1>
      </header>
      <div className="u-margin--standard">
        <div className="u-flex-row _u-padding-bottom--1ws">
          <Column>
            {renderInfoFromKeys(col1, true)}
          </Column>
          <Column>
            {renderInfoFromKeys(col2, false)}
          </Column>
        </div>
      </div>
    </Card>
  );
};

export default InventoryItemCard;
