import React from 'react';
import { List, Map } from 'immutable';
import Tooltip from 'react-tooltip-lite';
import type { SortingRule } from 'react-table';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import flagTableHOC from '../hoc/flagTable';
import { prettifyTime } from './../../utils/time';
import {
  createEncounterAndBillModels, getEncounterAttributes, getNextQueueNumber,
  saveEncounterAndRelatedModels,
  printQueueTicketonArrival,
  alertEncounterStage,
} from './../../utils/encounters';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import getPanelNameForPatient from './../../utils/coveragePayors';
import QueueButtonContainer from './../../containers/queueButtonContainer';
import Button from './../buttons/button';
import TableCollapseIcon from './../icons/tableCollapseIcon';
import Header from './../header/header';
import OptionButton from '../patient/optionButton';
import { debugPrint, logPatientScheduled } from './../../utils/logging';
import { wsUnit } from './../../utils/css';

import { updateAppointment, forceSendEmail } from './../../utils/api';
import { getAppointmentFlag } from './../../utils/appointment';
import UpdatePatientEmail from './../patient/updatePatientEmail';
import { fetchModel } from './../../utils/db';
import ClipboardCopy from './../clipboardCopy';
import TableColumnsSettings from '../table/tableColumnsSettings';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import { getModelMapFromList } from '../../utils/models';
import { filterWithConditions } from '../../utils/tableFilter';
import { getScheduledEncounterTableColumns } from './columns';

import type BaseModel from './../../models/baseModel';
import type AppointmentModel from './../../models/appointmentModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterModel from '../../models/encounterModel';
import type { FilteringRule } from './tableFilter';

import type { Config, User, SaveModels, SaveModel, Model, TableFlagType, CustomColumn } from './../../types';
import { setDebugModeData } from './../../actions';
import { getStore } from './../../utils/redux';

type Props = {
  config: Config,
  appointments: List<AppointmentModel>,
  patients: Map<string, PatientStubModel>,
  salesItems: List<SalesItemModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  user: User,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
  updateModelsInState: (models) => void,
  addModelToStore: (model: BaseModel) => void,
  encounterStageMap: Map<string, EncounterStageModel>,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  updateConfig: (config: Config) => void,
  encounters: List<EncounterModel>,
  tableProps: {
    sorted: SortingRule[],
    filteringRule: FilteringRule,
  }
};


type State = {
  currentSaving: string, // encounter id of currently being saved encounter
  isTableCollapsed: boolean,
  columns: Array<CustomColumn>,
}

const FlagTable = flagTableHOC(Table);

const serverName = window.location.host.split('.')[0];
/**
 * A table containing all of today's encounter with a waiting state.
 * @class ScheduledConsultsTable
 * @extends {React.Component<Props, State>}
 */
class ScheduledConsultsTable extends React.Component<Props, State> {
  interval: void | IntervalID;

  tooltipRef: Tooltip | null;

  /**
   * Creates an instance of ScheduledConsultsTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      currentSaving: '',
      isTableCollapsed: false,
      columns: transformColumnKeys(getScheduledEncounterTableColumns(props.config)),
    };
    this.tooltipRef = null;
    this.interval = setInterval(() => this.forceUpdate(), 30000); // Refresh every 30 secs so waiting time updates.
  }

  /**
   * Clean up interval on unmount.
   * @returns {void}
   */
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * Creates an encounter with a consult type and doctor specified.
   * @param {BaseModel} patient patient model
   * @param {BaseModel} appointment model

   * @returns {undefined}
   */
  handleCreateEncounterAndBill(patient, appointment) {
    updateAppointment(appointment, { status: 'fulfilled' })
      .then((resp) => {
        if (resp.ok) {
          this.props.updateModelsInState([resp.model]);
          return resp.model;
        }
        createErrorNotification('Something went wrong in moving to queue.');
        return null;
      })
      .then((appointmentModel: AppointmentModel) => {
        if (appointmentModel) {
          appointmentModel.getSalesItems(this.props.salesItems,
            this.props.encounterFlowMap, this.props.encounterStageMap)
            .then((salesItems) => {
              let encounterAttributes = getEncounterAttributes(
                'arrived',
                appointmentModel.get('consult_type'),
                appointmentModel.get('practitioner_id'),
                undefined,
                appointmentModel.get('_id'),
                this.props.encounterFlowMap.get(appointmentModel.get('flow_id')),
                this.props.encounterStageMap,
                appointmentModel,
              );
              const isQueueNumberEnabled = this.props.config.getIn(['patient_queue', 'enable_queue_number'], true);
              if (isQueueNumberEnabled) {
                const queueNumber = getNextQueueNumber(this.props.encounters);
                encounterAttributes = Object.assign(encounterAttributes, { queue: [queueNumber] });
              }
              createEncounterAndBillModels(patient, salesItems, this.props.coveragePayors,
                encounterAttributes)
                .then((models: List<Model>) => {
                  const encounterModel = models.find(model => model.get('type') === 'encounter');
                  if (getStore().getState().debugModeFlags.docValidation) {
                    getStore().dispatch(setDebugModeData(
                      'docValidation',
                      (newModels: List<Model>) => saveEncounterAndRelatedModels(
                        newModels.find(model => model.get('type') === 'patient'),
                        newModels.find(model => model.get('type') === 'encounter'),
                        newModels.find(model => model.get('type') === 'bill'),
                        undefined,
                        newModels.filter(model => model.get('type') === 'bill_item').toArray(),
                      ).then((savedModels) => {
                        if (savedModels && savedModels.length) {
                          printQueueTicketonArrival(encounterModel, this.props.config, this.props.salesItems);
                          logPatientScheduled();
                          createSuccessNotification(translate('x_added_to_queue', { x: patient.get('patient_name') }));
                          location.hash = '#';
                          this.setState({ currentSaving: '' });
                        }
                      }),
                      List(models),
                    ));
                    return Promise.resolve();
                  }
                  return saveEncounterAndRelatedModels(
                    models.find(model => model.get('type') === 'patient'),
                    models.find(model => model.get('type') === 'encounter'),
                    models.find(model => model.get('type') === 'bill'),
                    undefined,
                    models.filter(model => model.get('type') === 'bill_item').toArray(),
                  ).then((savedModels) => {
                    if (savedModels && savedModels.length) {
                      printQueueTicketonArrival(encounterModel, this.props.config, this.props.salesItems);
                      logPatientScheduled();
                      createSuccessNotification(translate('x_added_to_queue', { x: patient.get('patient_name') }));
                      const flowModel = this.props.encounterFlowMap.get(appointmentModel.get('flow_id'));
                      const stageModel = flowModel?.getStages(this.props.encounterStageMap)?.first() as EncounterStageModel;
                      alertEncounterStage(stageModel, 'waiting_alert');
                      location.hash = '#';
                      this.setState({ currentSaving: '' });
                    }
                  });
                });
            });
        }
      });
  }

  /**
   * @param {string} email email which needs to be updated
   * @param {PatientStubModel} patientModel patientModel which needs to update,
   * @param {AppointmentModel} appointment appointment which needs to update
   * @returns {Promise<boolen>} true if response without error else false
   */
  onUpdateEmail(email: string, patientModel: PatientStubModel, appointment: AppointmentModel) {
    return forceSendEmail(appointment.get('_id'), 'booked', email)
      .then((res) => {
        if (!res.ok) {
          createErrorNotification(translate('error_contact_support'));
          return false;
        }
        return fetchModel(patientModel.get('_id'))
          .then(patient => this.props.saveModel(patient.set('email', email)))
          .then((model) => {
            if (model.ok === false) {
              createErrorNotification(translate('something_went_wrong_email_update'));
              return false;
            }
            this.props.addModelToStore(model);
            createSuccessNotification(translate('x_email_have_been_updated', { x: patientModel.get('patient_name') }));
            return true;
          })
          .catch(() => {});
      });
  }

  /**
   * return button label as per the status of the appointment
   * @param {string} status patientNotification status
   * @returns {string} label of the button
   */
  getButtonLabel(status: string) {
    switch (status) {
      case 'pending':
      case 'processed':
        return translate('sending_email');
      case 'deferred':
        return translate('retrying_email');
      default:
        return translate('update_email');
    }
  }

  /**
   * @param {AppointmentModel} appointment Encounter model
   * @returns {React.ReactNode}
   */
  renderOptionButton(appointment: AppointmentModel) {
    return (
      <OptionButton
        appointment={appointment}
        encounterFlowMap={this.props.encounterFlowMap}
        encounters={this.props.encounters}
        config={this.props.config}
        practitioners={this.props.practitioners}
        encounterStageMap={this.props.encounterStageMap}
        salesItems={getModelMapFromList(this.props.salesItems)}
        coveragePayors={this.props.coveragePayors}
        saveModel={this.props.saveModel}
        updateConfig={this.props.updateConfig}
        saveModels={this.props.saveModels}
        user={this.props.user}
      />
    );
  }

  /**
   * Returns an object with appropriate fields for a Table row populated from an AppointmentModel.
   * @param {EncounterModel} appointment The appointment the button is for.
   * @returns {object} A table row object.
   */
  getTableRow(appointment: AppointmentModel) {
    const patient = this.props.patients.get(appointment.get('patient_id'));
    const appointmentFlag: TableFlagType = getAppointmentFlag(
      appointment,
    );
    const latestNotification = appointment.getLatestNotification();
    const buttonLabel = latestNotification ? this.getButtonLabel(latestNotification.status) : translate('sending_email');
    const hasTeleConsultPermission = hasPermission(this.props.user, List([createPermission('e_consultation', 'update')]));
    return {
      flag: appointmentFlag.active,
      flagData: { color: appointmentFlag.color },
      tooltipData: appointmentFlag.active && appointmentFlag.tooltips,
      scheduled_time: prettifyTime(appointment.get('start_timestamp')),
      encounter_flow: appointment.getEncounterType(this.props.salesItems, this.props.encounterFlowMap),
      name: patient ? patient.get('patient_name', UNICODE.EMDASH) : translate('loading...'),
      ic: patient ? patient.get('ic', UNICODE.EMDASH, false) : translate('loading...'),
      case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : translate('loading...'),
      sex: patient ? patient.get('sex', UNICODE.EMDASH, false) : translate('loading...'),
      panel_or_cash: patient ? patient.getPaymentType() : translate('loading...'),
      panel_name: getPanelNameForPatient(patient, this.props.coveragePayors),
      doctor: appointment.get('practitioner_id') ? appointment.getDoctorName(this.props.practitioners) : translate('unassigned'),
      action: (
        <PermissionWrapper permissionsRequired={List([createPermission('queue_patient_for_today', 'update')])} user={this.props.user}>
          {
            latestNotification &&
            !appointment.isEmailDelivered() && hasTeleConsultPermission ?
              <div className="u-flex-row u-full-width">
                <UpdatePatientEmail
                  title={translate('update_patient_email_address')}
                  patient={patient}
                  buttonLabel={buttonLabel}
                  onSave={(email: string) => this.onUpdateEmail(email, patient, appointment)}
                  disabled={(!latestNotification) || ['pending', 'processed', 'deferred'].includes(latestNotification.status)}
                />
                {this.renderOptionButton(appointment)}
              </div>
              :
              !appointment.get('practitioner_id') ?
                <div className="u-flex-row">
                  <QueueButtonContainer
                    patient={patient}
                    encounterDoctor={appointment.get('practitioner_id')}
                    isFromPatientList
                    appointment={appointment}
                  />
                  {this.renderOptionButton(appointment)}
                </div>
                :
                <div className="u-flex-row">
                  <Button
                    className="o-button o-button--small"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.setState({ currentSaving: appointment.get('_id') });
                      this.handleCreateEncounterAndBill(patient, appointment);
                    }}
                    disabled={this.state.currentSaving === appointment.get('_id')}
                    dataPublic
                  >
                    {translate('patient_arrived')}
                  </Button>
                  {this.renderOptionButton(appointment)}
                </div>
          }
        </PermissionWrapper>
      ),
      link: `/patient/${appointment.get('patient_id')}`,
    };
  }

  /**
   * Returns memoized data for the table
   * @returns {Object}
   */
  getTableData = () => {
    const tableData = this.props.appointments.reduce((rows, appointment) => {
      if (appointment.get('status') === 'booked' && appointment.isToday()) {
        return rows.push(this.getTableRow(appointment));
      }
      return rows;
    }, List()).toArray();
    return {
      tableData,
      filteredTableData: filterWithConditions(tableData, this.props.tableProps.filteringRule),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { config, tableProps } = this.props;
    const { isTableCollapsed } = this.state;
    const { tableData, filteredTableData } = this.getTableData();
    const COLUMNS = getScheduledEncounterTableColumns(config);
    return (
      <section className="o-card">
        <Header className="o-card__header">
          <Tooltip
            ref={(r) => { this.tooltipRef = r; }}
            content={(
              <ClipboardCopy
                id="clinics-appointment-url"
                copyContent={`https://patient.klinify.com/${serverName}/appointments`}
                onCloseModal={() => {
                  this.tooltipRef.endHover();
                }}
                description={translate('appointment_booking_link_tootltip_text')}
                className="u-width-400"
              />
            )}
            tipContentHover
            background="#eeebf2"
            direction="left"
            styles={{ paddingLeft: '10px', height: '21px', width: '21px' }}
          >
            <img src="../../../static/images/information_icon.svg" style={{ width: wsUnit }} alt="i" />
          </Tooltip>
          <h1 className="o-card__title o-card__title--no-right-padding u-margin-right--half-ws" data-public>{translate('scheduled_consults')}</h1>
          <span>-&nbsp;&nbsp;{tableData.length}</span>
          <span className="o-card__header__right-aligned-text">
            <TableColumnsSettings
              config={config}
              configFieldName="scheduled_consults"
              originalColumns={List(transformColumnKeys(COLUMNS))}
              columns={this.state.columns}
              onUpdateColumns={columns => this.setState({ columns })}
              updateConfig={this.props.updateConfig}
              isCompact
            />
            <TableCollapseIcon
              id="scheduled_consults"
              isTableCollapsed={isTableCollapsed}
              onClick={() =>
                this.setState({ isTableCollapsed: !isTableCollapsed })
              }
            />
          </span>
        </Header>
        {!isTableCollapsed &&
          <FlagTable
            columns={transformFilteredColumns(COLUMNS, this.state.columns)}
            data={filteredTableData}
            defaultSorted={[{ id: 'time', desc: false }]}
            noDataText={translate(tableProps.filteringRule?.filterRules.length ? 'no_filter_result' : 'no_scheduled_consults')}
            sorted={this.props.tableProps.sorted}
          />
        }
      </section>
    );
  }
}

export default ScheduledConsultsTable;
