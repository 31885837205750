/* eslint-disable require-jsdoc */
/* eslint-disable no-bitwise */
import React, { useState, useEffect } from 'react';
import { List } from 'immutable';
import translate from './../../utils/i18n';
import StatelessModal from '../modals/statelessModal';
import FormError from '../formError';
import Keypress from '../keypress';
import { createPermission, hasSomePermission } from './../../utils/permissions';
import ModalFooter from '../modals/modalFooter';
import { SaveModel, User } from './../../types';
import SaveButton from '../buttons/saveButton';
import Input from '../inputs/input';
import { validateAndTrimString } from '../../utils/utils';
import DosingRegimenModel from '../../models/dosingRegimenModel';
import { createSuccessNotification } from '../../utils/notifications';
import TimeInput from '../inputs/timeInput';

type Props = {
  user: User;
  isVisible: boolean;
  dosingRegimen: any;
  hideModal: () => void;
  saveModel: SaveModel,
}

type DosingRegimenAttributes = {
  name: string;
  frequency: string,
  interval: string,
  administrationFirstTime: string,
  administrationTime: string,
  administrationDescription: string,
};

/**
 * Form component used for creating and editing dosing regimen
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const DosingRegimenForm = (props: Props) => {
  const [formError, setFormError] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const timeFormatRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  /**
   * Returns DosingRegimenAttributes for showing initial value
   * @returns {Object}
   */
  const getInitialDosingRegimenAttributes = (): DosingRegimenAttributes => {
    if (props.dosingRegimen) {
      return {
        name: props.dosingRegimen.get('name'),
        frequency: props.dosingRegimen.get('frequency'),
        interval: props.dosingRegimen.get('interval'),
        administrationFirstTime: props.dosingRegimen.get('administration_first_time'),
        administrationTime: props.dosingRegimen.get('administration_time'),
        administrationDescription: props.dosingRegimen.get('administration_description'),
      };
    }
    return {
      name: '',
      frequency: '',
      interval: '',
      administrationFirstTime: '',
      administrationTime: '',
      administrationDescription: '',
    };
  };

  const [dosingRegimeAttrs, setDosingRegimeAttrs] = useState<DosingRegimenAttributes>(getInitialDosingRegimenAttributes());

  /**
   * Convert time in H[:mm[:ss]] format to seconds
   * @param {string} time : time in same format as t0
   * @returns {number}
  */
  const timeToSecs = (time: string) => {
    const [h, m, s] = time.split(':');
    return h * 3600 + (m | 0) * 60 + (s | 0) * 1;
  };

  /**
     * Convert seconds to time in H:mm:ss format
     * @param {number} seconds time in seconds
     * @returns {number}
     */
  const secsToTime = (seconds: number) => {
    const z = n => (n < 10 ? '0' : '') + n;
    const hours = (seconds / 3600 | 0);
    return `${hours < 24 ? hours.toString().padStart(2, '0') : (hours - 24).toString().padStart(2, '0')}:${
      z((seconds % 3600) / 60 | 0)}`;
  };

  /**
     * Auto generate next administration time.
     * @returns {string} summ of t0 and t1 in h:mm:ss format
     * @return {void}
    */
  const generateAdministrationTime = () => {
    let administrationTimeList = List([dosingRegimeAttrs.administrationFirstTime]);
    if (dosingRegimeAttrs.frequency &&
        dosingRegimeAttrs.administrationFirstTime &&
        dosingRegimeAttrs.administrationFirstTime.length === 5 &&
        Number(dosingRegimeAttrs.frequency) > 1) {
      for (let i = 1; i < Number(dosingRegimeAttrs.frequency); i++) {
        if (dosingRegimeAttrs.interval && Number(dosingRegimeAttrs.interval)) {
          const newAdminTime = secsToTime(timeToSecs(dosingRegimeAttrs.interval) + timeToSecs(administrationTimeList.last()));
          administrationTimeList = administrationTimeList.push(newAdminTime);
        }
      }
    }
    const updatedDosingRegimenAttributes = Object.assign({}, dosingRegimeAttrs, { administrationTime: administrationTimeList.join(', ') });
    setDosingRegimeAttrs(updatedDosingRegimenAttributes);
  };

  useEffect(() => {
    generateAdministrationTime();
  }, [dosingRegimeAttrs.frequency, dosingRegimeAttrs.interval, dosingRegimeAttrs.administrationFirstTime]);

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  const validateItem = (): boolean => {
    const trimmedFields = validateAndTrimString(dosingRegimeAttrs) as DosingRegimenAttributes;
    const {
      name,
      frequency,
      interval,
      administrationFirstTime,
      administrationTime,
    } = trimmedFields;
    if (!name) {
      setFormError(translate('name_is_required'));
      return false;
    }
    if (!Number(frequency)) {
      setFormError(translate('frequency_is_required'));
      return false;
    }
    if (Number(frequency) && (Number(frequency) > 1) && !Number(interval)) {
      setFormError(translate('interval_is_required'));
      return false;
    }
    if (!administrationFirstTime) {
      setFormError(translate('admin_first_time_is_required'));
      return false;
    }
    if (!administrationTime) {
      setFormError(translate('admin_time_is_required'));
      return false;
    }
    return true;
  };

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  const onSaveClicked = () => {
    if (validateItem()) {
      setSaving(true);
      const dosingRegimeModelData = {
        name: validateAndTrimString(dosingRegimeAttrs.name),
        frequency: validateAndTrimString(dosingRegimeAttrs.frequency),
        interval: validateAndTrimString(dosingRegimeAttrs.interval),
        administration_first_time: validateAndTrimString(dosingRegimeAttrs.administrationFirstTime),
        administration_time: validateAndTrimString(dosingRegimeAttrs.administrationTime),
        administration_description: validateAndTrimString(dosingRegimeAttrs.administrationDescription),
      };
      if (props.dosingRegimen) {
        const dosingRegimeModel = props.dosingRegimen.replaceAtrributes(dosingRegimeModelData);
        return props.saveModel(dosingRegimeModel).then((resp) => {
          if (resp) {
            createSuccessNotification(translate('dosing_regimen_updated'));
            setDosingRegimeAttrs(getInitialDosingRegimenAttributes());
            props.hideModal(); // Todo see how the hidemodal functions
          }
          setSaving(false);
          return true;
        });
      }
      const dosingRegimeModel = new DosingRegimenModel(dosingRegimeModelData);
      return props.saveModel(dosingRegimeModel).then((resp) => {
        if (resp) {
          createSuccessNotification(translate('dosing_regimen_added'));
          setDosingRegimeAttrs(getInitialDosingRegimenAttributes());
          props.hideModal(); // Todo see how the hidemodal functions
        } else {
          setSaving(false);
        }
        return true;
      });
    }
  };

  /**
   * Called on Create/Edit Form's onClose
   * @returns {void}
   */
  const handleFormClose = () => {
    if (!formError && formDirty) {
      setFormError(translate('unsaved_changes_error'));
    } else {
      props.hideModal();
    }
  };

  /**
   * Set dosing regime changes.
   * @param {DosingRegimenAttributes} updatedDosingRegimeAttributes Dosing Regime Attributes
   * @return {void}
   */
  const setDosingRegimenEdited = (updatedDosingRegimeAttributes: DosingRegimenAttributes) => {
    if (!formDirty) {
      setFormDirty(true);
    }
    setDosingRegimeAttrs(
      Object.assign({}, updatedDosingRegimeAttributes),
    );
  };

  /**
   *
   * @param {string} fieldName Key in stageAttributes
   * @param {string | Array<string> | boolean} value New value to update
   * @returns {void}
   */
  const handleChange = (fieldName: string, value: string | Array<string> | boolean | number) => {
    if (fieldName === 'administrationFirstTime' &&
    typeof value === 'string') {
      if (!timeFormatRegex.test(value)) setFormError(translate('time_format_wrong'));
      else setFormError('');
    }
    const updatedDosingRegimenAttributes = Object.assign({}, dosingRegimeAttrs, { [fieldName]: value });
    setDosingRegimenEdited(updatedDosingRegimenAttributes);
  };

  return (
    <StatelessModal
      id="dosing-regimen-form"
      visible={props.isVisible}
      setVisible={handleFormClose}
      title={translate('add_edit_dosing_regimen')}
      dataPublicHeader
      noButton
      explicitCloseOnly
    >
      <Keypress className="o-form" onEnterPressed={() => onSaveClicked()}>
        <div className="u-margin--standard">
          {formError && <FormError isSticky>{formError}</FormError>}
          <Input
            id="item_name"
            label={translate('name')}
            type="text"
            value={dosingRegimeAttrs.name}
            onValueChanged={value => handleChange('name', value)}
            required
            autoFocus
          />
          <Input
            id="item_frequency"
            label={translate('frequency_per_day')}
            type="text"
            value={dosingRegimeAttrs.frequency}
            onValueChanged={value => handleChange('frequency', value)}
            required
          />
          <Input
            id="item_interval"
            label={translate('interval_in_hours')}
            type="text"
            value={dosingRegimeAttrs.interval}
            onValueChanged={value => handleChange('interval', value)}
          />
          <Input
            id="item_first_time_of_administration"
            label={translate('first_time_of_administration')}
            type="text"
            value={dosingRegimeAttrs.administrationFirstTime}
            onValueChanged={value => handleChange('administrationFirstTime', value)}
            required
          />
          <Input
            id="item_administration_time"
            label={translate('administration_time')}
            type="text"
            value={dosingRegimeAttrs.administrationTime}
            onValueChanged={value => handleChange('administrationTime', value)}
            disabled
          />
          {/**
           * Later change the dosing admin time to use TimeInput
           */}
          {/* <TimeInput
            id="item_atdps_time"
            value={this.state.atdpsAdminTime}
            onChange={value => this.handleChange({ atdpsAdminTime: value })}
            label={translate('drug_administration_time')}
            style={{ minWidth: '200px', width: '25%' }}
            disabled={!this.state.atdpsIntegration}
            required={this.state.atdpsIntegration}
          /> */}
          <Input
            id="item_administration_description"
            label={translate('administration_desc')}
            type="text"
            value={dosingRegimeAttrs.administrationDescription}
            onValueChanged={value => handleChange('administrationDescription', value)}
          />
        </div>
      </Keypress>
      {hasSomePermission(props.user, List([createPermission('discounts_charges', 'create'), createPermission('discounts_charges', 'update')])) &&
        <ModalFooter>
          <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
            <SaveButton
              className="o-button--small"
              isSaving={isSaving}
              onClick={onSaveClicked}
            />
          </div>
        </ModalFooter>
      }
    </StatelessModal>
  );
};

export default DosingRegimenForm;
