import React from 'react';
import { List, Record } from 'immutable';
import type { RecordFactory } from 'immutable';

import PermissionsEditorItem from './permissionsEditorItem';
import { createUserPermission } from './../../utils/permissions';
import translate from './../../utils/i18n';
import { wsUnit } from './../../utils/css';
import InputSpecificPermissionsModal from './inputSpecificPermissionsModal';
import Header from './../header/header';
import { isKlinifyUser } from './../../utils/auth';

import type { Permission } from './../../models/userConfigModel';
import type { UserPermissionRecord, PermissionRecordProps, Config, User } from './../../types';

type Props = {
  permissionsConfig: List<UserPermissionRecord>,
  permissionsGroup: {
    group: string,
    features: Array<Permission>,
  },
  updatePermissions: (permissions: List<UserPermissionRecord>) => void,
  config: Config,
  klinifyConfig: Config,
  user: User,
};

/**
 * A component for editing permissions within a specified group (e.g. billing, inventory, etc.)
 * @class PermissionsEditorGroup
 * @extends {React.Component<Props, State>}
 */
class PermissionsEditorGroup extends React.Component<Props, {}> {
  /**
   * Returns true if all access levels are toggled on.
   * @returns {boolean}
   */
  areAllAccessLevelsToggled(): boolean {
    return this.props.permissionsGroup.features
      .filter(permission => !permission.feature.startsWith('perm_'))
      .every((permission) => {
        const configPermission = this.props.permissionsConfig
          .find(p => p.get('feature') === permission.feature);
        if (configPermission) {
          return configPermission.get('access').size === permission.access.length;
        }
        return false;
      });
  }

  /**
   * Toggles the access level for this group to either all on or all off.
   * @returns {void}
   */
  toggleAllAccessLevels() {
    const groupFeatures: Array<string> = this.props.permissionsGroup.features
      .filter(f => !f.feature.startsWith('perm_'))
      .map(f => f.feature);
    let newPermissions = this.props.permissionsConfig
      .filter((permission) => {
        const feature: string = permission.get('feature');
        return !groupFeatures.includes(feature);
      });
    if (this.areAllAccessLevelsToggled()) {
      newPermissions = newPermissions
        .concat(List(groupFeatures).map(feature => createUserPermission(feature, List())));
    } else {
      const immutableFeature = List(this.props.permissionsGroup.features.map(feature =>
        createUserPermission(feature.feature, List(feature.access))));
      newPermissions = newPermissions.concat(immutableFeature);
    }
    this.props.updatePermissions(newPermissions);
  }

  /**
   * Returns an array of permissions
   * @param {boolean} isInputSpecific If it's for input specific permissions
   * @returns {Array<Permission>}
   */
  getPermissionsGroupFeatures(isInputSpecific: boolean = false): Array<Permission> {
    return this.props.permissionsGroup.features
      .filter((i: Permission) => (isInputSpecific ? i.feature.startsWith('perm_') : !i.feature.startsWith('perm_')));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const scheduleEConsultation = this.props.klinifyConfig.getIn(['scheduling', 'scheduleTeleConsultation'], false);
    return (
      <div className="o-card">
        <Header className="o-card__header">
          <input
            id={`checkbox-group-${this.props.permissionsGroup.group}`}
            type="checkbox"
            checked={this.areAllAccessLevelsToggled()}
            onChange={() => this.toggleAllAccessLevels()}
            style={{ marginLeft: wsUnit }}
          />
          <label className="o-card__title" style={{ paddingLeft: `calc(${wsUnit} / 2)` }} htmlFor={`checkbox-group-${this.props.permissionsGroup.group}`}>
            {translate(this.props.permissionsGroup.group)}
          </label>
          { this.props.permissionsGroup.group === 'billing' &&
            <div className="u-flex-right">
              <InputSpecificPermissionsModal
                permissionsConfig={this.props.permissionsConfig}
                permissionsGroupFeatures={
                  this.getPermissionsGroupFeatures(true)
                    .map((i: Permission) => {
                      const item = {
                        feature: i.feature,
                        access: List(i.access),
                        section: i.section,
                      };
                      const PRecord: RecordFactory<PermissionRecordProps> = Record(item);
                      return PRecord(item);
                    })
                }
                updatePermissions={(feature: string, access: List<string>) => {
                  const newPermissions = this.props.permissionsConfig
                    .filter(p => p.get('feature') !== feature);
                  this.props.updatePermissions(
                    newPermissions.push(createUserPermission(feature, access)),
                  );
                }}
              />
            </div>
          }
        </Header>
        <div>
          {
            this.getPermissionsGroupFeatures()
              .map((i: Permission) => createUserPermission(i.feature, List(i.access)))
              .reduce((permissions, item: UserPermissionRecord) => {
                if (item.get('feature') === 'e_consultation' && (!isKlinifyUser(this.props.user) && !scheduleEConsultation)) {
                  return permissions;
                }
                const permission = this.props.permissionsConfig
                  .find((f: UserPermissionRecord) => f.get('feature') === item.get('feature'));
                return permissions.push(
                  <PermissionsEditorItem
                    item={item}
                    key={item.get('feature')}
                    permissions={permission}
                    user={this.props.user}
                    config={this.props.config}
                    updatePermissions={(feature: string, access: List<string>) => {
                      const newPermissions = this.props.permissionsConfig
                        .filter(p => p.get('feature') !== feature);
                      this.props.updatePermissions(
                        newPermissions.push(createUserPermission(feature, access)),
                      );
                    }}
                  />,
                );
              }, List()).toArray()
          }
        </div>
      </div>
    );
  }
}

export default PermissionsEditorGroup;
