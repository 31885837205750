import type { List } from 'immutable';

import BaseModel from './baseModel';
import { convertNumberToPrice, roundMoneyForSave } from '../utils/utils';

import type { Model } from './../types';
import type SalesItemModel from './salesItemModel';
import type DrugModel from './drugModel';
import type ProcedureTypeModel from './procedureTypeModel';

type Alteration = { type: string, amount: number, rate: number }; // Tax/Discount

export type BillableItem = DrugModel | SalesItemModel | ProcedureTypeModel;

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'bill_item',
  patient_id: string,
  bill_id: string,
  quantity: number,
  cost_price?: number,
  temp_cost_price?: number,
  price: number,
  total_amount: number,
  drug_id?: string,
  sales_item_id?: string,
  tax: Array<Alteration>,
  discounts: Array<Alteration>,
  coverage_payor_id?: string, // Defined means it's definitely claimed, null means its definitely not claimed, undefined means refer to Bill.
  payment_id?: string,
  receivable_id?: string,
  claim_id?: string,
};

/**
   * BillItemModel
   * @namespace BillItemModel
   */
class BillItemModel extends BaseModel {
  attributes: Attributes;

  itemType: 'prescription' | 'sales_item' | 'procedure_type' | void; // A temporary flag that can be used when adding items to a bill prior to a drug or salesItem ID being assigned to know which category it should be in.

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'bill_item';
  }

  /**
   * Returns true if this item is a prescription item (i.e. it has a drug_id).
   * @returns {boolean}
   */
  isPrescription(): boolean {
    return (this.has('drug_id') && this.get('drug_id') !== null)
      || this.itemType === 'prescription';
  }

  /**
   * Returns true if this item is a sales item (i.e. it has a sales_item_id).
   * @returns {boolean}
   */
  isSalesItem(): boolean {
    return (this.has('sales_item_id') && this.get('sales_item_id') !== null)
      || this.itemType === 'sales_item';
  }

  /**
   * Returns true if this item is a procedure request item (i.e. it has a procedure_type_id).
   * @returns {boolean}
   */
  isProcedureType(): boolean {
    return (this.has('procedure_type_id') && this.get('procedure_type_id') !== null)
      || this.itemType === 'procedure_type';
  }

  /**
   * Returns true if the BillItem has a drug_id or sales_item_id or procedure_type_id set.
   * @returns {boolean}
   */
  hasItemID(): boolean {
    return (this.has('sales_item_id') && this.get('sales_item_id') !== null && this.get('sales_item_id').length > 0) ||
      (this.has('drug_id') && this.get('drug_id') !== null && this.get('drug_id').length > 0) ||
      (this.has('procedure_type_id') && this.get('procedure_type_id') !== null && this.get('procedure_type_id').length > 0);
  }

  /**
   * Sets the total amount of the BillItem based on the set quantity and price.
   * @returns {this}
   */
  setTotal(): this {
    return this.set('total_amount', roundMoneyForSave(this.get('price', 0) * this.get('quantity', 0), 0));
  }

  /**
   * Returns the total of the bill item calculated from the price and quantity fields and
   * converted to a price string. Useful when first creating the bill item (i.e. prior to bill
   * finalisation when total_price will be set).
   * @returns {string}
   */
  getCalculatedTotal(): string {
    return convertNumberToPrice(this.get('price', 0) * this.get('quantity', 0));
  }

  /**
   * Returns true if billItem is claimed.
   * @param {boolean} billHasPanel Specifies if the bill of this billItem has a panel specified
   * (i.e. the bill will be claimed).
   * @returns {boolean}
   */
  isClaimed(billHasPanel: boolean): boolean {
    return (this.has('coverage_payor_id') && this.get('coverage_payor_id') !== null) ||
      (!this.has('coverage_payor_id') && billHasPanel);
  }

  /**
   * Returns true if this BillItem is valid, meaning it has an SKU ID, a price, and a quantity.
   * @returns {boolean}
   */
  isValid(): boolean {
    if (this.isProcedureType()) {
      return this.hasItemID() && this.get('price') !== undefined && this.get('price') !== '' &&
      this.get('quantity') !== undefined && this.get('quantity') !== '' && this.get('quantity') > 0;
    }
    return this.hasItemID() &&
    this.get('price') !== undefined && this.get('price') !== '' &&
    this.get('quantity') !== undefined && this.get('quantity') !== '' && this.get('quantity') > 0;
  }

  /**
   * Returns the key that has the SKU item ID for this Bill item.
   * If no SKU Id is tagged, returns null
   * @returns {string | null}
   */
  getItemIdKey(): string | null {
    if (this.isPrescription()) {
      return 'drug_id';
    } else if (this.isSalesItem()) {
      return 'sales_item_id';
    } else if (this.isProcedureType()) {
      return 'procedure_type_id';
    }
    return null;
  }

  /**
   * Returns the SKU item ID for this Bill item.
   * @returns {string | null}
   */
  getItemId(): string | null {
    const idKey = this.getItemIdKey();
    return idKey ? this.get(idKey) : null;
  }

  /**
   * Returns the SKU item if found
   * @param {List<BillableItem>} items A list of either Drug and / or SalesItem models.
   * @returns {(BillableItem | void)}
   */
  getItem(items: List<BillableItem>): BillableItem | void {
    return items.find(i => i.get('_id') === this.getItemId());
  }

  /**
   * Returns the SKU item name if found
   * @param {List<Model>} items A list of either Drug or SalesItem models.
   * @returns {(string | void)}
   */
  getItemName(items: List<Model>): string | void {
    const item = this.getItem(items);
    return item ? item.get('name') : undefined;
  }

  /**
   * Gets the dispensation unit for the bill item (if it is a prescription).
   * @param {List<Model>} items The list of DrugModels.
   * @returns {(string | void)}
   */
  getDispensationUnit(items: List<Model>): string | void {
    if (this.isPrescription()) {
      const item = items.find(i => i.get('_id') === this.getItemId());
      return item ? item.getDispensationUnit() : undefined;
    }
    return undefined;
  }
}

export default BillItemModel;
