import React from 'react';
import { List } from 'immutable';

import ContentTransition from './../contentTransition';
import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import MetricTypesTable from './metricTypesTable';
import MetricTypeForm from './metricTypeForm';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Header from './../header/header';

import type MetricTypeModel from './../../models/metricTypeModel';
import type { SaveModel, User } from './../../types';

type Props = {
  metricTypes: List<MetricTypeModel>,
  saveModel: SaveModel,
  user: User,
};

type State = {
  isModalVisible: boolean,
}


/**
 * A component for viewing, editing, and adding MetricType Models.
 * @class MetricTypes
 * @extends {React.Component<Props, State>}
 */
class MetricTypes extends React.Component<Props, State> {
  /**
   * Creates an instance of MetricTypes.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isModalVisible: false };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('vitals_settings')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <PermissionWrapper permissionsRequired={List([createPermission('metric_types', 'create')])} user={this.props.user}>
              <StatelessModal
                id="addNewMetricTypeModal"
                buttonLabel={translate('add_new_vital')}
                buttonClass="o-button o-button--small"
                title={translate('add_new_vital')}
                visible={this.state.isModalVisible}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                explicitCloseOnly
                dataPublicHeader
              >
                <MetricTypeForm
                  onSave={metricType =>
                    this.props.saveModel(metricType).then(() => {
                      this.setState({ isModalVisible: false });
                      return metricType;
                    })
                  }
                  onCancel={() => this.setState({ isModalVisible: false })}
                />
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('vitals')}</h2>
          <MetricTypesTable metricTypes={this.props.metricTypes} saveModel={this.props.saveModel} />
        </div>
      </ContentTransition>
    );
  }
}

export default MetricTypes;
