import React from 'react';
import Moment from 'moment';
import type { List } from 'immutable';

import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import { trackEvent, CLICK, CLAIM_INVOICES } from './../../utils/analytics';
import Select from './../inputs/select';
import MonthPicker from './../inputs/monthPicker';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ClaimModel from './../../models/claimModel';
import type { Month } from './../../types';

type Props = {
  coveragePayors: List<CoveragePayorModel>,
  selectedCoveragePayorId?: string,
  monthValue: Month,
  onCoveragePayorSelected: (coveragePayorId: string) => void,
  onMonthValueChanged: (month : Month) => void,
  enableButtons: boolean,
  isSaving: boolean,
  isSavingAndPrinting: boolean,
  onSaveClicked: (printOnCompletion: boolean) => void,
  isRegenerateButtonEnabled: boolean,
  onRegenerateInvoice: () => void,
  isModeMultiple: boolean,
  unfilteredClaims: List<ClaimModel>,
};

/**
 * A component that displays AddClaimInvoiceSidebar
 * @class ConsultReports
 * @extends {Component}
 */
class AddClaimInvoiceSidebar extends React.PureComponent<Props> {
  /**
   * Get the total count of CLaim Invoices based on the selected Coverage payor and month
   * @param {string} coveragePayorID The selected coverage payor id
   * @returns {number} Total claim invoices count
   */
  getClaimInvoicesTotalCount(coveragePayorID: string) {
    return this.props.unfilteredClaims.filter(c => c.get('coverage_payor_id') === coveragePayorID && c.canBeAssignedToInvoice()).size;
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <div className="o-sidebar--right c-billing__overview">
        <h1 data-public className="c-billing__overview__title">{translate('filter')}</h1>
        { !this.props.isModeMultiple &&
          <Select
            className="u-margin-left--1ws u-margin-right--1ws"
            label={translate('panel_name')}
            labelClassName="u-font-white"
            id="filter-panel"
            onValueChanged={this.props.onCoveragePayorSelected}
            options={
              this.props.coveragePayors
                .map(c => ({
                  label: `${c.get('name')} - ${this.getClaimInvoicesTotalCount(c.get('_id'))} ${translate('claims')}`,
                  value: c.get('_id'),
                }))
                .toArray()
            }
            value={this.props.selectedCoveragePayorId}
            disabled={this.props.isSaving || this.props.isSavingAndPrinting}
          />
        }
        <MonthPicker
          id="claim-month-picker"
          divClassName="u-margin-left--1ws u-margin-right--1ws"
          label={translate('invoice_month')}
          value={this.props.monthValue}
          onChange={value => this.props.onMonthValueChanged(value)}
          labelClassName="u-font-white"
          maxValue={{ year: parseInt(Moment().format('YYYY'), 10), month: parseInt(Moment().format('MM'), 10) }}
          disabled={this.props.isSaving || this.props.isSavingAndPrinting}
        />
        { !this.props.isModeMultiple &&
          <SaveButton
            dataPublic
            className="u-margin--standard o-button--small"
            style={{ marginBottom: 0 }}
            disabled={
              !this.props.enableButtons ||
              !this.props.isRegenerateButtonEnabled
            }
            onClick={() => this.props.onRegenerateInvoice()}
            label={translate('regenerate_claim_invoice')}
            isSaving={this.props.isSaving}
          />
        }
        <SaveButton
          dataPublic
          className="u-margin--standard o-button--small"
          style={{ marginBottom: 0 }}
          disabled={!this.props.enableButtons || this.props.isSavingAndPrinting}
          onClick={() => {
            trackEvent(CLAIM_INVOICES, CLICK, 'generate_invoices');
            this.props.onSaveClicked(false);
          }}
          label={translate(this.props.isModeMultiple ? 'generate_invoices' : 'generate_invoice')}
          isSaving={this.props.isSaving}
        />
        <SaveButton
          dataPublic
          className="u-margin--standard o-button--small"
          disabled={!this.props.enableButtons || this.props.isSaving}
          onClick={() => {
            this.props.onSaveClicked(true);
            trackEvent(CLAIM_INVOICES, CLICK, 'generate_invoices_and_print');
          }}
          label={translate(this.props.isModeMultiple ? 'generate_invoices_and_print' : 'generate_invoice_and_print')}
          isSaving={this.props.isSavingAndPrinting}
        />
      </div>
    );
  }
}

export default AddClaimInvoiceSidebar;
