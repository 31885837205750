
import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  type: 'condition',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  subtype: 'symptom' | 'diagnosis',
  encounter_id: string,
  patient_id: string,
  name: string,
  hidden?: boolean,
};

/**
   * ConditionModel
   *
   * @namespace ConditionModel
   */
class ConditionModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'condition';
  }

  /**
   * Returns true if this condition has the subtype symptom.
   * @returns {boolean} True if condition is a symptom.
   */
  isSymptom(): boolean {
    return this.get('subtype') === 'symptom';
  }

  /**
   * Returns true if this condition has the subtype diagnosis.
   * @returns {boolean} True if condition is a diagnosis.
   */
  isDiagnosis(): boolean {
    return this.get('subtype') === 'diagnosis';
  }
}

export default ConditionModel;
