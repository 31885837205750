
import type { List } from 'immutable';

import BaseModel from './baseModel';
import type SupplierModel from './supplierModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'supply',
  supplier_id: string,
  date: string,
  invoice_number?: string,
  delivery_order_number?: string,
  purchase_order_number?: string,
  doc_number?: string,
  notes?: string,
};

/**
   * SupplyModel
   * @namespace SupplyModel
   */
class SupplyModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'supply';
  }

  /**
   * Tries to get the Supplier of this Supply.
   * @param {List<SupplierModel>} suppliers A List of Suppliers
   * @returns {(SupplierModel | void)}
   */
  getSupplier(suppliers: List<SupplierModel>): SupplierModel | void {
    return suppliers.find(s => s.get('_id') === this.get('supplier_id'));
  }
}

export default SupplyModel;
