import { connect } from 'react-redux';

import { setAuthStatus, setUserID, setAuthError, setWorkspace } from './../actions';
import Login from './../components/login/login';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ authError }: State) => ({
  authError,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setHasAuth: (num: number): void => dispatch(setAuthStatus(num)),
  setUserID: (userID: string): void => dispatch(setUserID(userID)),
  setWorkspace: (workspace: string): void => dispatch(setWorkspace(workspace)),
  setAuthError: (error: string | null) => dispatch(setAuthError(error)),
});

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

export default LoginContainer;
