import React from 'react';
import { List } from 'immutable';
import glamorous, { Hr } from 'glamorous';
import moment, { Moment } from 'moment';

import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import { convertNumberToPrice, getLowInventoryThreshold, validateAndTrimString, pluralizeWord } from './../../utils/utils';
import { drugToPrescriptionAttributes } from './../../utils/prescriptions';
import { consecutiveCharSearch, knuthMorrisPratt } from './../../utils/search';
import { durationInput, getDefaultDurationOptions } from './../../utils/drug';
import { optionWithDrugStockWarning, valueWithDrugStockWarning } from './detailedStockWarning';
import DateInput from './../inputs/dateInput';
import DatePicker from './../inputs/statefulDatepicker';
import Input from './../inputs/input';
import Select from './../inputs/select';
import TextArea from './../inputs/textarea';
import TagInput from './../inputs/tagInput';
import StockWarning from './stockWarning';
import CloseButton from './../buttons/closeButton';
import AddEditDrugDurationForm from './../drugDurations/addEditDrugDurationForm';
import AddEditDrugContainer from '../../containers/addEditDrugContainer';
import { wsUnit, mediaQueries } from './../../utils/css';
import { UNICODE, DATE_FORMAT_TO_SAVE } from './../../constants';
import { createPermission, hasSomePermission } from './../../utils/permissions';

import { getStore } from '../../utils/redux';
import { getMDLInfo } from '../../utils/inventory';

import DrugModel from './../../models/drugModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DosingRegimenModel from './../../models/dosingRegimenModel';
import type { Config, PrescriptionAttributes, InventoryCount, SelectOption, User, SaveModel, MapValue } from './../../types';
import TimeInput from '../inputs/timeInput';

const ItemContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 40px',
  gridColumnGap: wsUnit,
  marginBottom: 0,
});

const PrescriptionFormContainer = glamorous.div({
  display: 'grid',
  gridColumnGap: wsUnit,
  paddingRight: wsUnit,
  gridTemplateAreas: `
  "name"
  "warning"
  "dosage"
  "dispensation"
  "reason"
  "notes"
  "dosing"
  `,
  [mediaQueries.forTabletLandscapeUp]: {
    gridAutoColumns: '1fr',
    gridTemplateAreas: `
    "name name"
    "warning warning"
    "dosage dosage"
    "dispensation dispensation"
    "reason notes"
    "dosing dosing"
    `,
  },
});

const ThreeColFormSection = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridColumnGap: wsUnit,
});

const FourColFormSection = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  gridColumnGap: wsUnit,
});

type Props = {
  config: Config,
  drugs: List<DrugModel>,
  onItemUpdated: (newValue: PrescriptionAttributes) => void,
  prescription: PrescriptionAttributes,
  inventoryCount: InventoryCount,
  coveragePayorID?: string,
  user: User,
  isFromCurrentEncounter?: boolean,
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugDurations: List<List<number, string>>,
  validationDocObject?: { id: string, type: string }
  isFromDocValidationModal: boolean,
  dosingRegimens: List<DosingRegimenModel>,
};

type State = {
  newDrugModalVisible: boolean,
  newDrugInput: string,
  newDrugDuration?: Array<[number, string]>,
  addEditDrugDurationFormIsVisible: boolean,
}

/**
 * An individual item in the add/edit prescriptions form.
 * @class AddEditPrescriptionsItem
 * @extends {React.Component<Props>}
 */
class AddEditPrescriptionsItem extends React.Component<Props, State> {
  props: Props;

  state: State = {
    newDrugModalVisible: false,
    newDrugInput: '',
    newDrugDuration: undefined,
    addEditDrugDurationFormIsVisible: false,
  }

  /**
   * Handles the update of an item.
   * @param {PrescriptionAttributes} changesObject An object containing the fields to change.
   * @param {string} key field key.
   * @returns {void}
   */
  onValueChanged(changesObject: PrescriptionAttributes, key: string = '') {
    this.props.onItemUpdated(Object.assign({}, this.props.prescription, changesObject), key);
  }

  /**
   * Formats the frequency options for the Select component.
   * @param {string | void} currentValue The current value to append to the list if not found.
   * @returns {Array<SelectOption>}
   */
  getFormattedFrequencyOptions(currentValue: string | void): Array<SelectOption> {
    const options = this.props.config
      .getIn(['prescription', 'frequencies'], List())
      .filter((e: { get: (arg0: string) => any; } | null | undefined) => e !== null && e !== undefined && e.get('name'))
      .toArray()
      .map((option: { get: (arg0: string) => any; }) => ({ value: option.get('name'), label: option.get('name'), tags: option.get('short_code') }));
    if (currentValue && currentValue.length && !options.some((o: { value: string; }) => o.value === currentValue)) {
      options.push({ value: currentValue, label: currentValue });
    }
    return options;
  }

  /**
   * checks if it is a valid scenario where detailed stock warnings is enabled in settings.
   * @returns {boolean}
   */
  isDetailedStockWarningShown(): boolean {
    return this.props.config.getIn(['inventory', 'showDetailedInventoryWarnings'], true);
  }

  /**
   * Returns proper item options appended with new item option
   * @returns {Array}
   */
  getItemOptions(): Array<SelectOption> {
    const store = getStore();
    const drugs = (this.props.isFromDocValidationModal && this.props?.validationDocObject) ?
      this.props.drugs.push(new DrugModel({
        _id: this.props.validationDocObject.id, is_missing: true,
      })) : this.props.drugs;
    return drugs
      .filter(model => model.isVisible() || model.get('_id') === this.props.prescription.drug_id)
      .map((i) => {
        const mdlInfo = getMDLInfo(store, i.get('_id'));
        const showMdlName = this.props.config.getIn(['master_drug_info', 'prescribing_modal', 'showMasterDrugName'], true);
        const mappedDrug = mdlInfo && mdlInfo.mapped_drug ? mdlInfo.mapped_drug : 'UNMAPPED';
        const mappedDrugName = showMdlName ? mappedDrug : undefined;
        return {
          value: i.get('_id'),
          label: i.isMissing() ?
            translate('data_not_found').toUpperCase() : i.get('name'),
          disabled: !i.isVisible(),
          customDetails: {
            inventoryCount: this.props.inventoryCount.getIn([i.get('_id'), 'skuStockRemaining'], 0),
            threshold: getLowInventoryThreshold(i),
            dispensationUnit: i.getDispensationUnit(),
            showDetailedInventoryWarnings: this.isDetailedStockWarningShown(),
            mappedDrugName,
          },
          ...(i.isMissing() && {
            isOptionHidden: true,
            style: {
              color: 'red',
            },
          }),
        };
      })
      .push({ value: 'create', label: translate('add_new_prescription_item') })
      .toArray();
  }

  /**
   * Gets the dispensation unit for the selected drug.
   * @param {?DrugModel} selectedDrug The selected Drug
   * @returns {string} Dispensation unit or an emdash if no drug given.
   */
  getDispensationUnit(selectedDrug: DrugModel | null | undefined): string {
    return selectedDrug ? selectedDrug.getDispensationUnit() : UNICODE.EMDASH;
  }

  /**
   * Gets the prescription unit for the selected drug.
   * @param {?DrugModel} selectedDrug The selected Drug
   * @returns {string} Prescription unit or an emdash if no drug given.
   */
  getPrescriptionUnit(selectedDrug: DrugModel | null | undefined): string {
    return selectedDrug ? selectedDrug.getPrescriptionUnit() : UNICODE.EMDASH;
  }

  /**
   * Shows Create new Drug Modal
   * @param {string} option New Drug name user input
   * @param {boolean} visible Modal visibility
   * @returns {void}
   */
  handleCreateDrugModal(option: string, visible: boolean) {
    this.setState({
      newDrugModalVisible: visible,
      newDrugInput: option && validateAndTrimString(option),
    });
  }

  /**
   * @param {string} option new input duration value
   * @returns {void}
   */
  handleCreateNewDrugDuration(option: string) {
    const matchedArray = option && option.match(/[a-z]+|[0-9]+/gi);
    if (matchedArray) {
      const newValueArray = matchedArray.filter((elem, index) => index <= 1);
      this.setState({ newDrugDuration: durationInput(newValueArray) });
    }
    this.setState({ addEditDrugDurationFormIsVisible: true });
  }

  /**
   * Hides the AddEditDrugDurationForm
   * @returns {void}
   */
  hideAddEditDrugDurationModal() {
    this.setState({ addEditDrugDurationFormIsVisible: false });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const selectedDrug = this.props.drugs.find(model => this.props.prescription.drug_id === model.get('_id'));
    const prescribedDuration = this.props.prescription.prescribed_duration || '';
    const dosingRegimen = this.props.dosingRegimens.find(m => m.get('_id') === this.props.prescription.dosing_regimen_id);
    const administrationTime = (dosingRegimen && dosingRegimen.get('administration_time')) || UNICODE.EMDASH;
    const administrationDesc = this.props.prescription && this.props.prescription.admin_desc;

    return (
      <ItemContainer>
        <PrescriptionFormContainer>
          <Select
            style={{ gridArea: 'name' }}
            options={this.getItemOptions()}
            autoFocus
            creatable
            id="drug"
            label={translate('drug_name')}
            value={this.props.prescription.drug_id}
            showNewOptionAtTop={false}
            onCreateOption={(option) => {
              this.handleCreateDrugModal(option, true);
            }}
            createOptionPromptFactory={label => translate('add_x_to_prescriptions', { x: label })}
            onValueChanged={(selectedOption) => {
              if (!selectedOption || selectedOption.length === 0) {
                const emptyValues: PrescriptionAttributes = { drug_id: '' };
                this.onValueChanged(emptyValues);
              } else if (selectedOption === 'create') {
                this.handleCreateDrugModal('', true);
              } else {
                // Fill default values on drug change.
                const drug = this.props.drugs.find(d => d.get('_id') === selectedOption);
                this.onValueChanged(drugToPrescriptionAttributes(drug, this.props.coveragePayorID));
              }
            }}
            filterOption={(option, query: string) => {
              // This is a custom filter to ensure search only applies to start of words.

              if (option.data?.isOptionHidden || (query && option.value === 'create')) { // Used to remove the create option from search filter as that is handled by select creatable
                return false;
              }
              const queryStrings = query.toLowerCase().split(' ');
              return queryStrings.every(queryString =>
                knuthMorrisPratt(`${option.label?.toLowerCase()} ${option.data.customDetails?.mappedDrugName?.toLowerCase()}`, queryString.toLowerCase()) !== -1);
            }}
            required
            components={{
              Option: optionWithDrugStockWarning,
              SingleValue: valueWithDrugStockWarning,
            }}
          />
          {
            !this.props.config.getIn(['inventory', 'hideInventoryWarnings'], false) &&
            this.props.prescription.drug_id &&
            <StockWarning
              style={{ gridArea: 'warning' }}
              count={this.props.inventoryCount.getIn([this.props.prescription.drug_id, 'skuStockRemaining'], 0)}
              threshold={getLowInventoryThreshold(selectedDrug)}
            />
          }
          <ThreeColFormSection css={{ gridArea: 'dosage' }}>
            <Input
              id="prescription_dosage"
              type="number"
              label={translate('dosage')}
              onValueChanged={v => this.onValueChanged({ prescribed_dosage: parseFloat(v) })}
              value={this.props.prescription.prescribed_dosage}
            />
            <Input
              id="prescription_unit"
              label={translate('unit')}
              value={this.getPrescriptionUnit(selectedDrug)}
              disabled
            />
            <Select
              options={
                this.getFormattedFrequencyOptions(this.props.prescription.prescribed_frequency)
              }
              id="frequency"
              label={translate('frequency')}
              value={this.props.prescription.prescribed_frequency}
              onValueChanged={value => this.onValueChanged({ prescribed_frequency: value })}
              filterOption={(option, filterString: string) => {
                // This is a custom filter to allow more complicated autocomplete. It splits filter
                // string into chars and returns true if each char is found in consecutive order.
                const label = option.label.toLowerCase();
                const tags = (option.tags ? option.tags.toLowerCase() : '');
                return consecutiveCharSearch(filterString, label) ||
                consecutiveCharSearch(filterString, tags);
              }}
              creatable
            />
            <Select
              id="item_default_duration"
              label={translate('duration')}
              options={
                getDefaultDurationOptions(this.props.drugDurations).map(
                  d => ({ label: pluralizeWord(d[1], d[0]), value: pluralizeWord(d[1], d[0]) }),
                )}
              onCreateOption={(option) => {
                this.handleCreateNewDrugDuration(option);
              }}
              value={prescribedDuration.length > 0 ? pluralizeWord(prescribedDuration[1], prescribedDuration[0]) : ''}
              onValueChanged={value => this.onValueChanged({ prescribed_duration: value })}
              creatable
              showNewOptionAtTop={false}
              // description="eg. 1 week"
            />
          </ThreeColFormSection>
          <FourColFormSection css={{ gridArea: 'dispensation' }}>
            <Input
              id="prescription_price"
              type="number"
              label={translate('price_du')}
              onValueChanged={value => this.onValueChanged({ sale_price: parseFloat(value) })}
              value={this.props.prescription.sale_price}
              required
            />
            <Input
              id="prescription_cost_price"
              type="number"
              label={translate('cost_du')}
              value={
                ((this.props.isFromCurrentEncounter && hasSomePermission(this.props.user, List([createPermission('current_encounter_view_cost_price', 'read')]))) ||
                (!this.props.isFromCurrentEncounter && hasSomePermission(this.props.user, List([createPermission('past_encounters_view_cost_price', 'read')]))))
                  ? this.props.prescription.cost_price
                  : ''
              }
              disabled
            />
            <Input
              id="prescription_quantity"
              type="number"
              label={translate('qty')}
              onValueChanged={value => this.onValueChanged({ sale_quantity: parseFloat(value) })}
              value={this.props.prescription.sale_quantity}
              required
            />
            <Input
              id="prescription_dispensation_unit"
              label={translate('dispensation_unit')}
              value={this.getDispensationUnit(selectedDrug)}
              disabled
            />
            <Input
              id="total-cost"
              disabled
              label={translate('total_cost')}
              value={
                this.props.prescription.sale_price && this.props.prescription.sale_quantity ?
                  convertNumberToPrice(
                    this.props.prescription.sale_price * this.props.prescription.sale_quantity,
                  ) :
                  UNICODE.EMDASH
              }
            />
          </FourColFormSection>
          {
          this.props.config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) &&
          <ThreeColFormSection css={{ gridArea: 'dosing' }}>
            <DatePicker
              id="start-date"
              showClearDate={false}
              allowPast
              label={translate('start_date')}
              value={
                this.props.prescription &&
                this.props.prescription.start_date &&
                moment(this.props.prescription.start_date).isValid() ?
                  moment(this.props.prescription.start_date) : this.props.prescription.start_date
              }
              required
              style={{ marginRight: '1.333rem' }}
              onValueChanged={(value) => {
                this.onValueChanged({
                  start_date: value && value.format ? value.format(DATE_FORMAT_TO_SAVE) : value,
                });
              }}
            />
            <Select
              id="dosing_regimen"
              label={translate('dosing_regimen')}
              options={this.props.dosingRegimens.map(i => ({ value: i.get('_id'), label: i.get('name') })).toArray()}
              value={this.props.prescription.dosing_regimen_id}
              onValueChanged={value => this.onValueChanged({
                dosing_regimen_id: value,
              })}
              required
            />
            <TextArea
              id="administration_time"
              label={translate('administration_time')}
              value={administrationTime}
              minRows={1}
              disabled
            />
            <TextArea
              id="administration_desc"
              label={translate('administration_desc')}
              value={administrationDesc}
              onValueChanged={value => this.onValueChanged({
                admin_desc: value,
              }, 'admin_desc')}
              minRows={1}
            />
          </ThreeColFormSection>
          }
          <TagInput
            style={{ gridArea: 'reason' }}
            id="prescription_reason"
            label={translate('reason')}
            onChange={newReason =>
              this.onValueChanged({
                reason: newReason.map(v => v.value).join(','),
              })
              }
            value={this.props.prescription.reason ?
              this.props.prescription.reason.split(',').map(value => ({ value, label: value })) :
              []
            }
            options={
              (selectedDrug && selectedDrug.get('indications')) ?
                List(selectedDrug.get('indications').map((value: any) => ({ value, label: value }))).toArray()
                : []
            }
          />
          <TextArea
            style={{ gridArea: 'notes' }}
            id="prescription_notes"
            label={translate('notes')}
            onValueChanged={(value: string) => this.onValueChanged({ notes: value })}
            value={this.props.prescription.notes ||
              (selectedDrug ? selectedDrug.get('notes', '') : '')}
            minRows={1}
          />
        </PrescriptionFormContainer>
        <CloseButton
          className="o-text-button"
          onClick={() => this.onValueChanged({ _deleted: true })}
          dataPublic
        />
        <Hr css={{ gridColumnStart: 1, gridColumnEnd: 3 }} />
        { this.state.newDrugModalVisible && <AddEditDrugContainer
          drugDurations={this.props.drugDurations}
          saveIsSticky
          noClearButton
          autofocus={this.state.newDrugInput ? 'item_default_prescribed_dosage' : 'item_name'}
          labelClassName=""
          drugName={this.state.newDrugInput}
          onSave={
              (drug) => {
                this.onValueChanged(drugToPrescriptionAttributes(drug, this.props.coveragePayorID));
                this.handleCreateDrugModal('', false);
              }
            }
          setAddEditDrugModalVisible={(visibility) => {
            if (!visibility) {
              this.setState({ newDrugInput: '' });
            }
            this.setState({ newDrugModalVisible: visibility });
          }}
        />
          }
        <StatelessModal
          id="add-edit-drug-duration-form"
          visible={this.state.addEditDrugDurationFormIsVisible}
          setVisible={() => {
            this.hideAddEditDrugDurationModal();
          }}
          noButton
          explicitCloseOnly
          title={translate('add_edit_drug_duration')}
          onClose={() => {
            this.hideAddEditDrugDurationModal();
            this.setState({ newDrugDuration: undefined });
          }}
          dataPublicHeader
        >
          <AddEditDrugDurationForm
            onSaveClicked={(duration: any, unit: any) => {
              this.hideAddEditDrugDurationModal();
              this.setState({ newDrugDuration: undefined });
              const value = [duration, unit];
              this.onValueChanged({ prescribed_duration: value });
            }}
            drugDurations={this.state.newDrugDuration}
            saveModel={this.props.saveModel}
            drugDurations={this.props.drugDurations}
            newDurationAvailable
            config={this.props.config}
            updateConfig={this.props.updateConfig}
          />
        </StatelessModal>
      </ItemContainer>
    );
  }
}

export default AddEditPrescriptionsItem;
