import React from 'react';
import glamorous from 'glamorous';
import {
  LineChart as RechartsLineChart, Line, XAxis as RechartsXAxis, YAxis as RechartsYAxis,
  CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

import { wsUnit, colours, mediaQueries } from './../../utils/css';

type Props = {
  data: Array<{
    [key: string]: number | string
  }>,
  xAxisLabel: string,
  yAxisLabel: string,
  xAxisKey: string,
  yAxisKeys: Array<string>,
  yAxisNames?: Array<string>,
  tooltipContent?: React.Component,
};

const LINE_CHART_WIDTH = '99%';
const LINE_CHART_HEIGHT = 350;
const LINE_CHART_DASH_ARRAY = '3 3';
const LINE_CHART_COLOURS = [colours.green, colours.blue, colours.maroon];
const LINE_CHART_DOT = { r: 5 };

const Container = glamorous.div({
  display: 'grid',
  gridTemplateColumns: `auto calc(3 * ${wsUnit})`,
  gridTemplateRows: `${wsUnit} auto`,
});

const XAxis = glamorous.div({
  marginBottom: `${wsUnit}`,
  marginTop: `calc(15 * ${wsUnit})`,
  gridRowStart: 2,
  gridColumnStart: 2,
  verticalAlign: 'top',
  fontWeight: 'bold',
  [mediaQueries.forPhoneOnly]: {
    marginTop: `calc(20 * ${wsUnit})`,
  },
});

const YAxis = glamorous.div({
  marginLeft: `${wsUnit}`,
  marginTop: `calc(1.5 * ${wsUnit})`,
  fontWeight: 'bold',
});

const Chart = glamorous.div({
  marginBottom: `${wsUnit}`,
  marginTop: `calc(2 * ${wsUnit})`,
  gridRowStart: 2,
});

/**
 * A component that displays line chart by using given data.
 * @class LineChart
 * @extends {React.PureComponent<Props>}
 */
class LineChart extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      xAxisKey,
      yAxisKeys,
      yAxisNames,
      data,
      yAxisLabel,
      xAxisLabel,
      tooltipContent,
    } = this.props;

    return (
      <Container>
        <YAxis>
          {yAxisLabel}
        </YAxis>
        <Chart>
          <ResponsiveContainer width={LINE_CHART_WIDTH} height={LINE_CHART_HEIGHT}>
            <RechartsLineChart
              data={data}
            >
              <RechartsXAxis dataKey={xAxisKey} />
              <RechartsYAxis />
              <CartesianGrid strokeDasharray={LINE_CHART_DASH_ARRAY} />
              <Tooltip
                data={data}
                xAxisKey={xAxisKey}
                content={tooltipContent}
              />
              <Legend />
              {
                yAxisKeys && yAxisKeys.length > 0 ?
                  yAxisKeys.map((key, index) => {
                    let stroke;
                    if (index < 3) { // pick first three colors for the lines from css, and repeat them for additional lines
                      stroke = LINE_CHART_COLOURS[index];
                    } else {
                      stroke = LINE_CHART_COLOURS[index % 3];
                    }
                    return (
                      <Line
                        dataKey={key}
                        stroke={stroke}
                        activeDot={LINE_CHART_DOT}
                        name={yAxisNames && yAxisNames[index]}
                      />
                    );
                  })
                  :
                  <Line />
              }
            </RechartsLineChart>
          </ResponsiveContainer>
        </Chart>
        <XAxis>
          {xAxisLabel}
        </XAxis>
      </Container>
    );
  }
}

export default LineChart;
