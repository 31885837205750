import { connect } from 'react-redux';

import OfflineNotifier from './../components/prompts/offlineNotifier';
import { setOfflinePromptVisible } from '../actions';

import type { State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({ showOfflinePrompt }: State) => ({
    showOfflinePrompt,
  });

const mapDispatchToProps = {
  onClose: setOfflinePromptVisible,
};

const OfflineNotificationContainer =
  connect(mapStateToProps, mapDispatchToProps)(OfflineNotifier);

export default OfflineNotificationContainer;
