import * as React from 'react';
import { List, Map } from 'immutable';

import DrugModel from './../../models/drugModel';
import InventoryMapModel from './../../models/inventoryMapModel';
import translate from './../../utils/i18n';
import AllergiesList from './../allergies/allergyList';
import AddVitals from './../vitals/addVitals';
import PatientAlert from './../patientAlert/patientAlert';
import PatientAppointmentInfoCard from '../patientAppointment/patientAppointmentInfoCard';
import PatientCard from './../patientCard/patientCard';
import PatientModel from './../../models/patientModel';
import PatientPage from './../layout/patientPage';
import LatestVitalsContainer from './../../containers/latestVitalsContainer';
import { getReferralQueryString } from './../../utils/router';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { isKlinifyUser } from './../../utils/auth';
import ConsultationDetails from './consultationDetails';

import type AllergyModel from './../../models/allergyModel';
import PractitionerModel from '../../models/practitionerModel';
import type BillModel from './../../models/billModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type EncounterModel from './../../models/encounterModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type MetricTypeModel from './../../models/metricTypeModel';
import type { Config, SaveModel, SaveModels, User } from './../../types';
import type AppointmentModel from '../../models/appointmentModel';
import Button from './../buttons/button';
import Header from './../header/header';
import type BaseModel from '../../models/baseModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import PatientFlows from './patientFlows';
import { getModelMapFromList } from '../../utils/models';

type Props = {
  bills: Map<string, BillModel>,
  config: Config,
  klinifyConfig: Config,
  coveragePayors: List<CoveragePayorModel>,
  patient?: PatientModel,
  patientStub?: PatientStubModel,
  allergies: List<AllergyModel>,
  encounters: List<EncounterModel>,
  user: User,
  salesItems: List<SalesItemModel>,
  appointments: List<AppointmentModel>,
  saveModel: SaveModel,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  metricTypes: List<MetricTypeModel>,
  updateConfig: (config: Config) => void,
  addModelToStore: (model: BaseModel) => void,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  encounterStagesMap: Map<string, EncounterStageModel>
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
  practitioners: List<PractitionerModel>,
  saveModels: SaveModels
};
/**
 * Displays different Patient Cards in patient page, using order and config from clinic config.
 * @namespace PatientDetails
 */
class PatientDetails extends React.PureComponent<Props> {
  /**
   * Returns either the patientModel or patientStubModel depending on which is available. An error
   * is thrown if neither exist, though this shouldn't ever occur.
   * @returns {(PatientModel | PatientStubModel)} The patient for this component.
   */
  getPatient(): PatientModel | PatientStubModel {
    if (this.props.patient) {
      return this.props.patient;
    }
    if (this.props.patientStub) {
      return this.props.patientStub;
    }
    throw new Error('No patient model provided.');
  }

  /**
   * Returns HTML corresponding to patient profile card
   * @returns {React.ReactNode} The html for this card.
   */
  renderPatientProfile(): React.ReactNode {
    const currentEncounter = this.props.encounters.find(encounter => encounter.isCurrent());
    return (
      <div className="o-card">
        <div className="o-card__header">
          <h1 data-public className="o-card__title">{translate('patient_profile')}</h1>
          <PermissionWrapper permissionsRequired={List([createPermission('patient', 'update')])} user={this.props.user}>
            {
              this.props.patient &&
              <Button
                className="u-flex-right u-margin-right--1ws o-button o-button--small u-width-180"
                onClick={() => { location.hash = `/patient/${this.getPatient().get('_id')}/profile/edit${getReferralQueryString()}`; }}
                dataPublic
              >
                {translate('edit_patient_details')}
              </Button>
            }
          </PermissionWrapper>
        </div>
        <PatientCard
          config={this.props.config}
          coveragePayors={this.props.coveragePayors}
          patient={this.props.patient}
          patientStub={this.props.patientStub}
          hasCurrentConsult={currentEncounter !== undefined}
          showActions
        />
      </div>
    );
  }

  /**
   * Returns HTML corresponding to latest vitals card
   * @returns {React.ReactNode} The html for this card.
   */
  renderVitals(): React.ReactNode {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('patient_metrics', 'read')])} user={this.props.user}>
        <section className="o-card">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{translate('latest_vitals')}</h1>
            <div className="u-flex-right u-margin-right--1ws">
              <AddVitals
                metricTypeID="ALL"
                metricTypes={this.props.metricTypes}
                patientID={this.getPatient().get('_id')}
                saveModel={this.props.saveModel}
                isFromModal
                isOnline
              />
            </div>
          </Header>
          <LatestVitalsContainer patientID={this.getPatient().get('_id')} />
        </section>
      </PermissionWrapper>
    );
  }

  /**
   * Returns HTML corresponding to allergies card
   * @returns {React.ReactNode} The html for this card.
   */
  renderAllergies(): React.ReactNode {
    return (
      <PermissionWrapper
        permissionsRequired={List([createPermission('patient_allergies', 'read')])}
        user={this.props.user}
        key="allergyList"
      >
        <AllergiesList
          patientID={this.getPatient().get('_id')}
          items={this.props.allergies}
          user={this.props.user}
          saveModel={this.props.saveModel}
          verifiedDrugs={this.props.verifiedDrugs}
          drugs={this.props.drugs}
          allergies={this.props.allergies}
        />
      </PermissionWrapper>
    );
  }

  /**
   * Returns HTML corresponding to consultation history and current consultation card
   * @param {boolean} isCurrent to indicate if it is current consultation.
   * @returns {React.ReactNode} The html for this card.
   */
  renderConsultationDetails(isCurrent: boolean): React.ReactNode {
    let currentEncounter;
    let pastEncounters = this.props.encounters.sort(
      (a, b) => b.getStartTime() - a.getStartTime(),
    );
    const currentConsultIndex = pastEncounters.findIndex(encounter =>
      encounter.isCurrent());
    if (currentConsultIndex > -1) {
      currentEncounter = pastEncounters.get(currentConsultIndex);
      pastEncounters = pastEncounters.delete(currentConsultIndex);
    }
    return (
      <ConsultationDetails
        patient={this.getPatient()}
        config={this.props.config}
        allergies={this.props.allergies}
        updateConfigValue={this.props.updateConfigValue}
        updateConfig={this.props.updateConfig}
        user={this.props.user}
        isCurrent={isCurrent}
        currentEncounter={currentEncounter}
        pastEncounters={pastEncounters}
      />
    );
  }

  /**
   * Returns HTML corresponding to patient alert card
   * @returns {React.ReactNode} The html for this card.
   */
  renderPatientAlert(): React.ReactNode {
    return (
      <PatientAlert
        patient={this.getPatient()}
        saveModel={this.props.saveModel}
      />
    );
  }

  /**
   * Returns HTML corresponding to patient alert card
   * @returns {React.ReactNode} The html for this card.
   */
  renderAppointmentQueueInfo(): React.ReactNode {
    const consultAppointments = this.props.appointments.filter(a => !a.isPending() && !a.isCancelled());
    return (consultAppointments.first()
      ? <PatientAppointmentInfoCard
        user={this.props.user}
        patient={this.getPatient()}
        appointment={consultAppointments.first()}
        saveModel={this.props.saveModel}
        salesItems={this.props.salesItems}
        coveragePayors={this.props.coveragePayors}
        addModelToStore={this.props.addModelToStore}
        encounters={this.props.encounters}
        encounterFlowMap={this.props.encounterFlowMap}
        encounterStagesMap={this.props.encounterStagesMap}
        config={this.props.config}
      />
      : null
    );
  }

  /**
   * Returns HTML corresponding to different cards based on config settings
   * @returns {React.Node} The html for all cards which are enabled to display.
   */
  renderCards(): React.ReactNode {
    const patientPageCards = this.props.config.getIn(['patient_page_cards'], List()).toArray()
      .map(item => item.toJS());
    const scheduleConsultation =
      isKlinifyUser(this.props.user)
      || this.props.klinifyConfig.getIn(['scheduling', 'scheduleTeleConsultation'], false)
      || this.props.klinifyConfig.getIn(['scheduling', 'scheduleAppointments'], false);
    return patientPageCards
      .filter(item => item.show)
      .map((item) => {
        switch (item.name) {
          case 'patient_profile':
            return this.renderPatientProfile();
          case 'allergies':
            return this.renderAllergies();
          case 'latest_vitals':
            return this.renderVitals();
          case 'current_encounter':
            return this.renderConsultationDetails(true);
          case 'encounter_history':
            return this.renderConsultationDetails(false);
          case 'patient_alert':
            return this.renderPatientAlert();
          case 'econsult_info':
            return scheduleConsultation ? this.renderAppointmentQueueInfo() : null;
          default:
            return <div />;
        }
      });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const currentEncounter = this.props.encounters.find(encounter => encounter.isCurrent());
    const currentBill = currentEncounter ?
      this.props.bills.get(currentEncounter.get('bill_id')) : undefined;

    return (
      <PatientPage
        patient={this.getPatient()}
        coveragePayors={this.props.coveragePayors}
        allergies={this.props.allergies}
        user={this.props.user}
        encounters={this.props.encounters}
        appointments={this.props.appointments}
        saveModel={this.props.saveModel}
        currentBill={currentBill}
        currentEncounter={currentEncounter}
        patientStub={this.props.patientStub}
        config={this.props.config}
        klinifyConfig={this.props.klinifyConfig}
        drugs={this.props.drugs}
        activeIngredients={this.props.activeIngredients}
        isFromOverviewPage
      >
        <PatientFlows
          encounterFlowMap={this.props.encounterFlowMap.filter(f => f.isVisible())}
          encounters={this.props.encounters}
          config={this.props.config}
          practitioners={this.props.practitioners}
          bills={this.props.bills}
          encounterStageMap={this.props.encounterStagesMap}
          salesItems={getModelMapFromList(this.props.salesItems)}
          coveragePayors={this.props.coveragePayors}
          saveModel={this.props.saveModel}
          updateConfig={this.props.updateConfig}
          saveModels={this.props.saveModels}
          user={this.props.user}
        />
        {this.renderCards()}
      </PatientPage>
    );
  }
}

export default PatientDetails;
