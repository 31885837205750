/* eslint-disable camelcase */
/* eslint-disable require-jsdoc */
import React from 'react';
import { List, Map } from 'immutable';
import { get } from 'lodash';
import { components, OptionProps, SingleValueProps } from 'react-select';
import { Span } from 'glamorous';

import Select from '../inputs/select';
import translate from '../../utils/i18n';
import { compareByAlphabeticalOrder } from '../../utils/comparators';
import Button from './../buttons/button';
import Input from '../inputs/input';

import SalesItemModel from '../../models/salesItemModel';
import DrugModel from '../../models/drugModel';
import PractitionerModel from '../../models/practitionerModel';

import { knuthMorrisPratt } from '../../utils/search';
import { CAMPAIGN_TIME_UNITS } from '../../constants';
import { colours, labelFontSize } from '../../utils/css';
import { CampaignRulesCondition, CampaignRuleType } from '../../models/patientCampaignModel';
import type { Config, SelectOption } from '../../types';
import type MasterDrugModel from '../../models/masterDrugModel';
import type BaseModel from '../../models/baseModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';

const ValueWithDeletedText = ({ children, ...props }: SingleValueProps<SelectOption>) => {
  const { customDetails } = props.data;
  return (
    <components.SingleValue {...props}>
      <span title={`${props.data.label} ${customDetails?.deleted ? `(${translate('deleted')})` : ''}`}>
        {children}
        {customDetails?.deleted && <Span textTransform="capitalize" color={colours.grey7} marginLeft={4}>({translate('deleted')})</Span>}
      </span>
    </components.SingleValue>
  );
};

const OptionWithDeletedText = (props: OptionProps<SelectOption>) => {
  const { customDetails } = props.data;
  return (
    <components.Option {...props}>
      <span>
        {props.data.label}
        {customDetails?.deleted
          ? <Span textTransform="capitalize" color={colours.grey7} marginLeft={4}>({translate('deleted')})</Span>
          : ''}
      </span>
    </components.Option>
  );
};

/**
 * Gets the encounter flow options for a Select input.
 * @param {List<EncounterFlowModel>} encounterFlowModels Encounter flow models.
 * @returns {Array<SelectOption>}
 */
function getEncounterFlowOptions(
  encounterFlowModels: List<EncounterFlowModel> = List(),
): Array<SelectOption> {
  return encounterFlowModels.map(model => ({
    value: model.get('_id'),
    label: model.get('name', ''),
    isDisabled: !model.isVisible(),
    customDetails: {
      deleted: !model.isVisible(),
    },
  }))
    .toArray()
    .sort((a, b) => {
      if (a.isDisabled === b.isDisabled) {
        return compareByAlphabeticalOrder(a.label, b.label);
      }
      return a.isDisabled < b.isDisabled ? -1 : 1;
    });
}

const comparatorOptions = [{ value: 'eq', label: '=' }, { value: 'gte', label: '>=' }];

type Props = {
  config: Config,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  practitioners: List<PractitionerModel>,
  logicalOp: 'and' | 'or',
  filterConditions: List<CampaignRulesCondition>,
  onChange: (rules: {filters: Array<CampaignRulesCondition>, logicalOp: 'and' | 'or'}) => void,
  disabled?: boolean,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
  encounterFlows: List<EncounterFlowModel>,
};

const LOGICAL_OPS = [{ value: 'and', label: translate('and_uc') }, { value: 'or', label: translate('or_uc') }];

const RULE_TYPES = [
  { value: CampaignRuleType.ENCOUNTER_FLOW, label: translate('encounter_flow') },
  { value: CampaignRuleType.ENCOUNTER_DOCTOR, label: translate('encounter_doctor') },
  { value: CampaignRuleType.DRUG_PRESCRIBED, label: translate('drug_prescribed') },
  { value: CampaignRuleType.MASTER_DRUG_SOLD, label: translate('master_drug_dispensed') },
  { value: CampaignRuleType.ITEM_SOLD, label: translate('items_sold') },
  { value: CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME, label: translate('prev_patient_job_encounter_time') },
];

/**
 * Renders a component showing the campaign rules section of the sms campaign form.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
export default function RulesFormField(props: Props) {
  const { logicalOp, filterConditions, practitioners, drugs, salesItems, masterDrugModelsMap } = props;

  function getSelectOptionsFromModels(itemOptions: (List<BaseModel>)) {
    return itemOptions.map(i => ({
      value: i.get('_id'),
      label: i.get('name'),
    })).toArray();
  }

  function updateFilterMetadata(filterIndex: number, metadataField: string, metadataValue: any) {
    const newFilters = filterConditions.set(filterIndex, {
      ...filterConditions.get(filterIndex),
      metadata: {
        ...filterConditions.get(filterIndex)?.metadata,
        [metadataField]: metadataValue,
      },
    });
    props.onChange({ filters: newFilters.toArray(), logicalOp });
  }

  function renderFilterMetadataField(filterIndex: number, metadataField: string, customProps) {
    return (
      <Select
        id="filter-metadata-field"
        label=""
        className="u-width-180 u-margin-right--half-ws"
        value={get(filterConditions.get(filterIndex), `metadata.${metadataField}`)}
        onValueChanged={newValue => updateFilterMetadata(filterIndex, metadataField, newValue)}
        clearable={false}
        disabled={props.disabled}
        {...customProps}
      />
    );
  }

  function getFilterMetadataField(filterIndex: number) {
    const filterType = filterConditions.get(filterIndex)?.type;
    if (filterType === CampaignRuleType.ENCOUNTER_FLOW) {
      return renderFilterMetadataField(filterIndex, 'encounter_flow', {
        id: 'encounter-flow-filter-metadata-field',
        options: getEncounterFlowOptions(props.encounterFlows),
        components: {
          SingleValue: ValueWithDeletedText,
          Option: OptionWithDeletedText,
        },
      });
    }
    if (filterType === CampaignRuleType.ENCOUNTER_DOCTOR) {
      return renderFilterMetadataField(filterIndex, 'doctor_id', {
        id: 'doctor-filter-metadata-field',
        options: practitioners
          .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))
          .map(p => ({ label: p.get('name'), value: p.get('_id') })).toArray(),
      });
    }
    if (filterType === CampaignRuleType.DRUG_PRESCRIBED) {
      return renderFilterMetadataField(filterIndex, 'drug_id', {
        id: 'drug-filter-metadata-field',
        options: getSelectOptionsFromModels(drugs),
        filterOption: (option, query: string) => {
          // This is a custom filter to ensure search only applies to start of words.
          const queryStrings = query.toLowerCase().split(' ');
          return queryStrings.every(queryString =>
            knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1);
        },
      });
    }
    if (filterType === CampaignRuleType.ITEM_SOLD) {
      return renderFilterMetadataField(filterIndex, 'sales_item_id', {
        id: 'sales-item-filter-metadata-field',
        options: getSelectOptionsFromModels(salesItems),
        filterOption: (option, query: string) => {
          // This is a custom filter to ensure search only applies to start of words.
          const queryStrings = query.toLowerCase().split(' ');
          return queryStrings.every(queryString =>
            knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1);
        },
      });
    }
    if (filterType === CampaignRuleType.MASTER_DRUG_SOLD) {
      return renderFilterMetadataField(filterIndex, 'master_drug_id', {
        id: 'master-drug-sold-metadata-field',
        options: getSelectOptionsFromModels(masterDrugModelsMap.toList()),
      });
    }
    if (filterType === CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME) {
      return (
        <>
          {renderFilterMetadataField(filterIndex, 'comparator', {
            id: 'last_job_encounter-comparator-field',
            options: comparatorOptions,
          })}
          <Input
            id="last_job_encounter-time-value"
            label=""
            divClassName="u-width-180 u-margin-right--half-ws"
            type="number"
            value={get(filterConditions.get(filterIndex), 'metadata.encounter_time_offset.time_value')}
            placeholder="#"
            min={1}
            max={365}
            disabled={props.disabled}
            onValueChanged={(newValue: string) => {
              const { metadata: { encounter_time_offset = {} } = {} } =
                filterConditions.get(filterIndex) as
                CampaignRulesCondition;
              updateFilterMetadata(filterIndex, 'encounter_time_offset', { ...encounter_time_offset, time_value: newValue });
            }}
          />
          {renderFilterMetadataField(filterIndex, 'encounter_time_offset.time_unit', {
            id: 'last_job_encounter-metadata-field',
            options: CAMPAIGN_TIME_UNITS,
            onValueChanged: (newValue: string) => {
              const { metadata: { encounter_time_offset = {} } = {} } =
                filterConditions.get(filterIndex) as
                CampaignRulesCondition;
              updateFilterMetadata(filterIndex, 'encounter_time_offset', { ...encounter_time_offset, time_unit: newValue });
            },
          })}
        </>
      );
    }
    return null;
  }

  return (
    <React.Fragment>
      {/* Removing encounter time in filter condition */}
      {filterConditions.map((filter, i) => (
        <div className="u-flex-row">
          <div className="u-flex-row">
            {/* Rule Type */}
            <Select
              id="ruleType"
              label=""
              value={filter.type}
              onValueChanged={(value) => {
                const newFilters = filterConditions.set(i, {
                  type: value,
                  metadata: {},
                });
                props.onChange({ filters: newFilters, logicalOp });
              }}
              options={RULE_TYPES}
              className="u-width-180 u-margin-right--half-ws"
              clearable={false}
              disabled={props.disabled}
            />
            {/* Rule Widget */}
            {getFilterMetadataField(i)}
            {/* Operator */}
            <Select
              label=""
              id="logicalOp"
              value={logicalOp}
              onValueChanged={(value) => {
                props.onChange({ filters: filterConditions, logicalOp: value });
              }}
              options={LOGICAL_OPS}
              className="u-margin-right--half-ws"
              style={{ minWidth: 90 }}
              clearable={false}
              disabled={props.disabled}
            />
            <Button
              className="o-button u-margin-top--quarter-ws u-margin-left--half-ws"
              onClick={() => {
                const newFilters = filterConditions.delete(i);
                props.onChange({ filters: newFilters, logicalOp: newFilters.size <= 1 ? 'and' : logicalOp });
              }}
              style={{ minWidth: 'inherit' }}
              disabled={props.disabled}
              dataPublic
            >
                ×
            </Button>
          </div>
        </div>
      ))}
      <div>
        <Button
          className="o-button o-button--small  o-button--padded u-margin-bottom--1ws"
          disabled={props.disabled}
          onClick={() => {
            const newFilters = filterConditions.push({
              type: CampaignRuleType.ENCOUNTER_FLOW,
              metadata: {},
            });
            if (filterConditions.size > 1) {
              props.onChange({ filters: newFilters, logicalOp });
              return;
            }
            props.onChange({ filters: newFilters, logicalOp: 'and' });
          }}
          dataPublic
        >
          <i className="fa fa-plus" />
          {translate('add_rule')}
        </Button>
      </div>
    </React.Fragment>
  );
}
