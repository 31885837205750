import React from 'react';
import { Link } from 'react-router-dom';

import { fetchAssetFromCouch } from './../../utils/db';
import LoadingIndicator from './../loadingIndicator';
import translate from './../../utils/i18n';

import type TemplateModel from './../../models/templateModel';

type Props = {
  template: TemplateModel,
  patientID: string,
};

type State = {
  imageBlob?: Blob,
};

/**
 * A component that displays a single templates's thumbnail.
 * @class TemplateThumbnail
 * @extends {React.Component}
 */
class TemplateThumbnail extends React.Component<Props, State> {
  /**
   * Creates an instance of TemplateThumbnail.
   * @param {Props} props The component props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      imageBlob: undefined,
    };
    fetchAssetFromCouch(this.props.template.getPreviewAsset())
      .then((blob: Blob) => {
        this.setState({ imageBlob: blob });
      });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    if (!this.state.imageBlob) {
      return <LoadingIndicator />;
    }
    const categoryID = this.props.template.getCategory();
    const link = `/patient/${this.props.patientID}/categories/${categoryID}/add/${this.props.template.get('_id')}`;
    const src = this.state.imageBlob ? URL.createObjectURL(this.state.imageBlob) : undefined; // This is kind of pointless, just to shut Flow up.
    return (
      <Link to={link} style={{ border: '1px black solid', maxHeight: '518px', overflow: 'hidden' }}>
        <img
          src={src}
          alt={this.props.template.get('_id')}
          style={{ width: '100%', height: '100%' }}
        />
        {
          this.props.template.get('assets').length > 1 &&
          <div className="c-casenote-thumbnail__page-count">
            {translate('x_pages', { x: this.props.template.get('assets').length })}
          </div>
        }
      </Link>
    );
  }
}

export default TemplateThumbnail;
