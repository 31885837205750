import * as React from 'react';
import ReactPaginate from 'react-paginate';
import type { List } from 'immutable';

import translate from './../../utils/i18n';

type Props = { items: List<React.Node> };
type State = { index: number };

/**
 * A Pagination HOC
 * @class Pagination
 * @extends {React.Component<Props, State>}
 * @param {?} data // TODO: This is to quash an eslint bug.
 */
class Pagination extends React.Component<Props, State> {
  /**
   * Creates an instance of Pagination.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = { index: 0 };
  }

  /**
   * When receiving new props ensure that the index in state isn't larger than the items length.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.state.index >= nextProps.items.size) {
      this.setState({ index: nextProps.items.size - 1 });
    }
  }

  handlePageClick = (data: { selected: number }) => {
    this.setState({ index: data.selected });
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div>
        { this.props.items.get(this.state.index) }
        <ReactPaginate
          previousLabel={translate('previous')}
          nextLabel={translate('next')}
          breakLabel="..."
          breakClassName="break"
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          pageCount={this.props.items.size}
          onPageChange={this.handlePageClick}
          containerClassName="c-pagination"
          subContainerClassName="pages c-pagination"
          activeClassName="active"
        />
      </div>
    );
  }
}

export default Pagination;
