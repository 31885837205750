import glamorous from 'glamorous';
import { border, colours, wsUnit } from './css';

export const GridContainer = glamorous.div(
  {
    display: 'grid',
  },
  ({ rows, cols, styles }) => ({
    ...(cols && { gridTemplateColumns: cols }),
    ...(rows && { gridTemplateRows: rows }),
    ...styles,
  }),
);

export const GridElement = glamorous.div(({ col, row, styles }) => ({
  gridColumnStart: col[0],
  gridColumnEnd: col[1],
  gridRowStart: row[0],
  gridRowEnd: row[1],
  ...styles,
}));

export const ConfirmMessage = glamorous.h6({
  marginTop: 8,
  lineHeight: 2,
});

export const ListItem = glamorous.li({
  '&:not(:last-child)': {
    borderBottom: border,
  },
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
}, ({ zIndex = 'auto', disabled = false }: { zIndex?: number | 'auto', disabled?: boolean }) => ({
  background: disabled ? colours.grey4 : colours.white,
  zIndex,
}));

export const DragIcon = glamorous.i({
  padding: `calc(${wsUnit} / 2)`,
  color: colours.grey3,
},
({ disabled = false }: { disabled?: boolean }) => ({
  cursor: disabled ? 'default' : 'ns-resize',
}));
