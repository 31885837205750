import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import { updateConfigValue, updateConfig } from '../actions';
import { saveFactory } from '../utils/redux';
import { getModelMapFromList } from '../utils/models';

import type { Dispatch, State, Config } from '../types';
import DosingRegimen from '../components/dosingRegimen/dosingRegimen';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config,
  user,
  dosingRegimens,

}: State) => ({
  config,
  user,
  dosingRegimens: dosingRegimens.filter(m => m.isVisible()),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateStrings: (updatedStrings: List<string>) =>
    dispatch(updateConfigValue(['diagnoses', 'options'], updatedStrings)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  saveModel: saveFactory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DosingRegimen);
