import React from 'react';
import glamorous from 'glamorous';

import Label from './label';
import { wsUnit, colours, borderRadius, fonts, mediaQueries } from './../../utils/css';

import type { HTMLStyle } from './../../types';

type Props = {
  label: string,
  labelClassName?: string,
  className: string,
  id: string,
  inputStyle: HTMLStyle,
  description?: string,
  onValueChanged: (value: string, isChecked: boolean, label?: string) => void,
  options: Array<{ label: string, value: string }>,
  value: Array<string>,
  disabled: boolean,
  hideLabel: boolean,
  listLabelClassName?: string,
  listLabelStyle?: HTMLStyle,
  multiColumn: boolean,
  style: HTMLStyle,
  containerStyle?: HTMLStyle
};

const CheckboxContainer = glamorous.div(
  { marginBottom: `calc(2 * ${wsUnit})` },
  ({ multiColumn }) => (!multiColumn ? {} : {
    display: 'grid',
    gridTemplateColumns: '1fr',
    [mediaQueries.forTabletLandscapeUp]: { gridTemplateColumns: 'repeat(2, 1fr)' },
    [mediaQueries.forDesktopUp]: { gridTemplateColumns: 'repeat(3, 1fr)' },
    [mediaQueries.forBigDesktopUp]: { gridTemplateColumns: 'repeat(4, 1fr)' },
  }),
);

const CheckboxInput = glamorous.input({
  marginRight: wsUnit,
  height: '20px',
  width: '20px',
  appearance: 'none',
  WebkitAppearance: 'none',
  border: '2px solid',
  borderColor: colours.green,
  borderRadius,
  cursor: 'pointer',
  '&:active, &:checked:active': {
    boxShadow: '0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1)',
  },
  '&:checked': {
    backgroundColor: colours.green,
    color: colours.white,
  },
  '&:checked:after': {
    content: '\uf00c',
    fontSize: '11px',
    position: 'relative',
    left: '3px',
    color: colours.white,
    fontFamily: fonts.icons,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});

/**
 * A Checkbox input component.
 * @class Checkbox
 * @extends {React.Component<Props, {}>}
 */
class Checkbox extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    className: '',
    inputStyle: {},
    disabled: false,
    hideLabel: false,
    listLabelClassName: '',
    listLabelStyle: {},
    multiColumn: false,
    style: {},
    containerStyle: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let description = '';
    if (this.props.description) {
      description = <p className="o-sub-label">{ this.props.description }</p>;
    }
    let listLabelClassName = 'o-label';
    if (this.props.listLabelClassName) {
      listLabelClassName += ` ${this.props.listLabelClassName}`;
    }
    return (
      <div className={this.props.className} style={this.props.style}>
        {
          !this.props.hideLabel &&
          <fieldset className="o-form__item o-form__item--full-width">
            <Label
              className={this.props.labelClassName}
              id={this.props.id}
              label={this.props.label}
            />
            { description }
          </fieldset>
        }
        <CheckboxContainer multiColumn={this.props.multiColumn} style={this.props.containerStyle}>
          {
            this.props.options.map((option: {label: string, value: string}) =>
              <div
                key={`${this.props.id}_${option.value}`}
                className="o-checkbox"
              >
                <CheckboxInput
                  disabled={this.props.disabled}
                  checked={this.props.value.includes(option.value)}
                  id={`${this.props.id}_${option.value}`}
                  key={this.props.id}
                  name={this.props.id}
                  type="checkbox"
                  style={this.props.inputStyle}
                  value={option.value}
                  onChange={event =>
                    this.props.onValueChanged(option.value, event.target.checked, option.label)
                  }
                />
                <label
                  className={listLabelClassName}
                  style={this.props.listLabelStyle}
                  htmlFor={`${this.props.id}_${option.value}`}
                >
                  {option.label}
                </label>
              </div>)
          }
        </CheckboxContainer>
      </div>
    );
  }
}

export default Checkbox;
