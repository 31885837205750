import translate from './i18n';
import { logMessage } from './logging';

import type { ComponentReference } from './../types';

type Notification = any; // createNotification will return void if notificationSystem isn't initialized. This is fine, but removeNotification needs to know to expect undefined.
type NotificationAction = { label: string, callback: () => void };

let notificationSystem;

/**
 * Sets the passed notification component for this local scope.
 * @param {any} componentRef A ReactNotification component.
 * @returns {undefined}
 */
export function setNotificationComponent(componentRef: ComponentReference) {
  notificationSystem = componentRef;
}

/**
   * Creates a popup notification.
   * @param {Object} notification - Object with optional values for title, level and message.
   * @return {undefined}
   */
function createNotification(notification): Notification {
  if (notificationSystem) {
    return notificationSystem.addNotification(notification);
  }
  logMessage('Notifications not initialised.'); // This only ever happens if there was a previous error somewhere in the app.
  return undefined;
}

/**
 * Manually removes the given notification.
 * @param {Notification} notification The notification to remove.
 * @returns {undefined}
 */
export const removeNotification = (notification: Notification) => {
  if (notificationSystem && notification) {
    notificationSystem.removeNotification(notification);
  } else if (!notificationSystem) {
    logMessage('Notifications not initialised.'); // This only ever happens if there was a previous error somewhere in the app.
  }
};

/**
 * Creates a notification of a succesful action.
 * @param  {string} message The message to display
 * @param {NotificationAction}  action An action to attach to the notification (optional).
 * @return {Object}         A Notifcation object with title, level, and message.
 */
export const createSuccessNotification = (message: string, action?: NotificationAction) =>
  createNotification({
    title: translate('success'),
    level: 'success',
    message,
    position: 'tr', // Top right
    action,
  });

/**
 * Creates a notification of an error.
 * @param  {string} message The message to display
 * @return {Object}         A Notifcation object with title, level, and message.
 */
export const createErrorNotification = (message: string) => createNotification({
  title: translate('error'),
  level: 'error',
  message,
  position: 'tr', // Top right
});


/**
 * Creates a notification at an info level.
 * @param  {string} message The message to display.
 * @return {Object}         A Notifcation object with title, level, and message.
 */
export const createInfoNotification = (message: string) => createNotification({
  title: translate('info'),
  level: 'info',
  message,
  position: 'tr', // Top right
});

/**
 * Creates a notification at an info level that does not autoDismiss.
 * @param  {string} title The title to display.
 * @param  {string} message The message to display.
 * @param {boolean} dismissible If true the notification can be closed by the user.
 * @return {Object}         A Notifcation object with title, level, and message.
 */
export const createStaticNotification =
  (title: string, message: string, dismissible: boolean = false) => createNotification({
    title,
    level: 'info',
    message,
    position: 'tr', // Top right
    autoDismiss: 0,
    dismissible,
  });

/**
 * Creates a notification at an error level that does not autoDismiss.
 * @param  {string} message The message to display.
 * @return {Object}         A Notifcation object with title, level, and message.
 */
export const createStaticErrorNotification =
(message: string) => createNotification({
  title: translate('error'),
  level: 'error',
  message,
  position: 'tr', // Top right
  autoDismiss: 0,
  dismissible: true,
});

/**
 * Helper function to generate a static saving notification.
 *  @return {Object}         A Notifcation object with title, level, and message.
 */
export const createSavingNotification = () =>
  createStaticNotification(translate('saving'), translate('saving_casenote'));

/**
 * Displays a tip notification.
 * @returns {undefined}
 */
export function displayTip() {
  const tips = [
    'You can sort tables by any column by clicking on the column headers.',
    'Remember to make adjustments to current inventory to keep it up to date!',
    'Hide stock level warnings by changing your Clinic Settings. ',
    'You can set a default time chit length in Clinic Settings.',
    'Drag and drop column headers to resize columns!',
  ];
  const tip = tips[Math.floor(Math.random() * tips.length)];
  createNotification({
    title: translate('tip'),
    level: 'info',
    message: tip,
  });
}

/**
 * Helper function to generate a static saving notification.
 * @param {string} title title of the notification
 * @param {string} message of the notification
 * @param {React.Component} action which needs to perform
 * @return {Object} A Notifcation object with title, level, and message.
 */
export const createAppointmentRequestNotification =
  (title: string, message: string, action: React.Component) =>
    createNotification({
      title,
      level: 'success',
      message,
      children: (action),
      autoDismiss: 15,
      dismissible: true,
    });

/**
 * Helper function to generate customised notification.
 * @param {string} title title of the notification
 * @param {string} message of the notification
 * @param {React.ReactElement} children Children to render
 * @return {Object} A Notifcation object with title, level, and message.
 */
export const createCustomNotification =
  (title: string, message: string, children: React.ReactElement) =>
    createNotification({
      title,
      level: 'success',
      message,
      children,
      autoDismiss: 0,
    });
