/**
 * This splits the needle into chars
 * and returns true if each char is found in consecutive order (but not necessarily together) in the haystack.
 * Spaces always return true and are not matched.
 * Examples:
 * given string: "14 units qid"
 * a needle value of:
 * "1uqid" returns true.
 * "4 u qid" returns true.
 * "4   utqid" returns true.
 * "4" returns true.
 * "1uqid4" returns false.
 * "13uqid" returns false.
 * "nqqid" returns false.
 * "uqid14" returns false.
 * @param {string} searchTerm The search term
 * @param {string} string The text to be searched
 * @returns {bool} Whether the needle is found in the haystack
 */
export function consecutiveCharSearch(searchTerm: string, string: string): boolean { // eslint-disable-line import/prefer-default-export
  let haystack = string;
  return Array.from(searchTerm.toLowerCase()).every((char) => {
    if (char === ' ') {
      return true;
    }
    const i = haystack.indexOf(char);
    if (i === -1) {
      return false;
    }
    haystack = haystack.substring(i + 1);
    return true;
  });
}

/**
 * @see https://github.com/trekhleb/javascript-algorithms/tree/master/src/algorithms/string/knuth-morris-pratt
 * @param {string} word string of which occurrence needs to find.
 * @return {Array<number>}
 */
export const buildPatternTable = (word: string) => {
  const patternTable = [0];
  let prefixIndex = 0;
  let suffixIndex = 1;

  while (suffixIndex < word.length) {
    if (word[prefixIndex] === word[suffixIndex]) {
      patternTable[suffixIndex] = prefixIndex + 1;
      suffixIndex += 1;
      prefixIndex += 1;
    } else if (prefixIndex === 0) {
      patternTable[suffixIndex] = 0;
      suffixIndex += 1;
    } else {
      prefixIndex = patternTable[prefixIndex - 1];
    }
  }

  return patternTable;
};

/**
 * @see https://github.com/trekhleb/javascript-algorithms/tree/master/src/algorithms/string/knuth-morris-pratt
 * @param {string} text string in which occurrence needs to find.
 * @param {string} word string of which occurrence needs to find.
 * @return {number} -1 if not found else index of the first occurrence.
 */
export const knuthMorrisPratt = (text: string, word: string) => {
  if (word.length === 0) {
    return 0;
  }

  let textIndex = 0;
  let wordIndex = 0;

  const patternTable = buildPatternTable(word);

  while (textIndex < text.length) {
    if (text[textIndex] === word[wordIndex]) {
      // We've found a match.
      if (wordIndex === word.length - 1) {
        return (textIndex - word.length) + 1;
      }
      wordIndex += 1;
      textIndex += 1;
    } else if (wordIndex > 0) {
      wordIndex = patternTable[wordIndex - 1];
    } else {
      wordIndex = 0;
      textIndex += 1;
    }
  }

  return -1;
};
