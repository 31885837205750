import { connect } from 'react-redux';
import { List } from 'immutable';

import Bill from './../components/billing/bill';
import { saveFactory, saveModelsAPIFactory, saveModelsFactory } from './../utils/redux';
import { saveBillingRelatedModels } from './../utils/api';
import { generateGUID } from './../utils/utils';
import { updateInventoryItemCount, updateModel, updateConfigValue, updateConfig, setDebugModeData } from './../actions';
import ClaimModel from './../models/claimModel';
import { getModelMapFromList } from '../utils/models';

import type { Dispatch, State, ReactRouterMatch, Config, Model } from './../types';
import type BillModel from './../models/billModel';
import type TransactionModel from './../models/transactionModel';
import type EncounterModel from './../models/encounterModel';
import type BillItemModel from './../models/billItemModel';
import type PaymentModel from './../models/paymentModel';
import type ReceivableModel from './../models/receivableModel';

type Props = {
  match: ReactRouterMatch,
  patientID: string,
};

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State, { match, patientID }: Props) => {
  const { encounterID } = match.params;
  const cachedPatient = state.patients.find(m => m.get('_id') === patientID);
  let patient;
  if (cachedPatient) {
    patient = cachedPatient;
  } else {
    patient = state.isFetching ?
      undefined : state.currentDataViewsModels.find(m => m.get('_id') === patientID);
  }
  const encounter = state.encounters.find(model => model.get('_id') === encounterID);
  const bill = state.bills.find(model => model.get('_id') === encounter?.get('bill_id'));
  if (!bill) {
    throw new Error('Bill not found.');
  }
  let claim = state.claims.find(c => c.get('bill_id') === bill.get('_id'));
  if (!claim) {
    claim = new ClaimModel({
      patient_id: patientID,
      bill_id: bill.get('_id'),
      timestamp: bill.getDate(),
    });
  }
  const statusesToNotInclude = ['cancelled', 'rejected'];
  const filteredProcedureRequests = state.procedureRequests
    .filter(procedureRequest => procedureRequest.get('encounter_id') === encounterID);
  return {
    bill,
    discountsCharges: state.discountsCharges,
    billItems: state.billItems.filter(i => i.get('bill_id') === bill.get('_id')),
    claim,
    config: state.config,
    coveragePayors: state.coveragePayors,
    drugs: state.drugs,
    encounter,
    encounterStageMap: getModelMapFromList(state.encounterStages),
    paymentTypes: state.paymentTypes.filter(m => m.isVisible()),
    patient,
    patientStub: state.patientStubs.find(stub => stub.get('_id') === patientID),
    payments: state.payments.filter(p => p.get('bill_id') === bill.get('_id')),
    receivables: state.receivables.filter(r => r.get('bill_id') === bill.get('_id')),
    salesItems: state.salesItems,
    inventoryCount: state.inventoryCount,
    inventoryCountSyncStatus: state.inventoryCountSyncStatus,
    medicalCertificates: state.medicalCertificates.filter(m => m.get('encounter_id') === encounterID),
    prescriptions: state.prescriptions.filter(m => m.get('encounter_id') === encounterID),
    timeChits: state.timeChits.filter(m => m.get('encounter_id') === encounterID),
    practitioners: state.practitioners,
    user: state.user,
    providers: state.providers,
    procedureTypes: state.procedureTypes.filter(p => p.isActive()),
    procedureRequests: filteredProcedureRequests.filter(p =>
      !statusesToNotInclude.includes(p.getStatusLastEventStatus(state.procedureStatuses))),
    diagnoses: state.conditions
      .filter(c => c.get('patient_id') === patientID &&
        c.isVisible() &&
        c.isDiagnosis() &&
        c.get('encounter_id') === encounterID),
    conditions: state.conditions
      .filter(c => c.get('patient_id') === patientID &&
        c.isVisible() &&
        c.get('encounter_id') === encounterID),
    documentTemplates: state.documentTemplates,
    documentData: state.documentData
      .filter(d => d.get('encounter_id') === encounterID && !d.isVoid()),
    drugDurations: state.config.getIn(['prescription', 'durations'], List()).filter(e => e !== null && e !== undefined),
    isOnline: state.isOnline,
    appointment: state.appointments.find(a => (
      a.get('patient_id') === patientID
      && !a.isCancelled()
      && a.get('_id') === encounter?.get('appointment_id')
    )),
    debugModeFlags: state.debugModeFlags,
    verifiedDrugs: state.verifiedDrugs,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
  saveModel: saveFactory(dispatch),
  updateInventoryItemCount: (skuID: string, change: number) =>
    dispatch(updateInventoryItemCount(skuID, change)),
  updateModelsInState: (models: List<Model>) => models.map(model => dispatch(updateModel(model))),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  saveBillModels: (
    bill: BillModel,
    transactions: List<TransactionModel>,
    billItems: List<BillItemModel>,
    encounter: EncounterModel,
    payments?: List<PaymentModel>,
    receivables?: List<ReceivableModel>,
    claims?: List<ClaimModel>,
    onSaveAfterDocValidation?: (savedModels: Array<Model>) => void,
    requestId: string = generateGUID(),
  ) => saveModelsAPIFactory(dispatch)(() =>
    saveBillingRelatedModels(
      dispatch,
      bill,
      transactions,
      billItems,
      encounter,
      payments,
      receivables,
      claims,
      onSaveAfterDocValidation,
      requestId,
    )),
  setDebugModeData: (
    flag: string,
    saveModelsFn: (models: List<Model>) => Promise<any>,
    models: List<Model>,
  ) => dispatch(setDebugModeData(flag, saveModelsFn, models)),
});

const BillContainer = connect(mapStateToProps, mapDispatchToProps)(Bill);

export default BillContainer;
