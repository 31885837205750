import React from 'react';
import { List, Set } from 'immutable';
import { Link } from 'react-router-dom';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import PermissionsEditor from './../permissions/permissionsEditor';
import SaveButton from './../buttons/saveButton';
import SavePrompt from './../prompts/savePrompt';
import { createSuccessNotification } from './../../utils/notifications';
import { updateUserToAdmin, updateUserToNonAdmin, isAdminPermissionSet } from './../../utils/userManagement';
import Header from './../header/header';

import type { SaveModel, UserPermissionRecord, User, Config } from './../../types';
import type UserGroupModel from './../../models/userGroupModel';
import type UserConfigModel from './../../models/userConfigModel';

type Props = {
  userGroup?: UserGroupModel,
  saveModel: SaveModel,
  userConfigs: List<UserConfigModel>,
  isAdminUser: boolean,
  user: User,
  setAdminUserStatus: (status: boolean) => void,
  config: Config,
  klinifyConfig: Config,
}

type State = {
  permissions: List<UserPermissionRecord>,
  changesMade: boolean,
  isSaving: boolean,
}

/**
 * A component for editing the permissions of a UserGroup.
 * @class UserGroupPermissions
 * @extends {React.Component<Props, State>}
 */
class UserGroupPermissions extends React.Component<Props, State> {
  /**
   * Creates an instance of UserGroupPermissions.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      permissions: props.userGroup ? props.userGroup.getPermissions() : List(),
      changesMade: false,
      isSaving: false,
    };
  }

  /**
   * sets admin status of current user in redux store.
   * @param {string} id the user id of user for whom new group is assigned.
   * @param {boolean} status the user group id of new group assigned.
   * @returns {void}
   */
  setCurrentUserAdminStatus(id: string, status: boolean) {
    if (this.props.user.get('id', '') === id) {
      if (status) {
        this.props.setAdminUserStatus(true);
      } else {
        this.props.setAdminUserStatus(false);
      }
    }
  }

  /**
   * checks for user permissions and updates admin status of the user, for whom the group is updated.
   * @param {string} userGroupId the user group id of group updated.
   * @param {List<UserPermissionRecord>} permissions list of permissions user has set
   * @returns {void}
   */
  onUserPermissionChange(userGroupId: string, permissions: List<UserPermissionRecord>) {
    const users = this.props.userConfigs.filter(c => c.get('user_group_id', '') === userGroupId);
    if (users && permissions && this.props.isAdminUser) {
      const userIds = Set(users.map(user => user.get('user_id')));
      const isAdmin = isAdminPermissionSet(permissions);
      if (isAdmin) {
        userIds.map(userId => updateUserToAdmin(userId)
          .then(() => { this.setCurrentUserAdminStatus(userId, true); }));
      } else {
        userIds.map(userId => updateUserToNonAdmin(userId)
          .then(() => { this.setCurrentUserAdminStatus(userId, false); }));
      }
    }
  }

  /**
   * Saves the new user group permissions.
   * @param {UserGroupModel} userGroup The current userGroup.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(userGroup: UserGroupModel): Promise<boolean> {
    this.setState({ isSaving: true });
    return this.props.saveModel(userGroup.setPermissions(this.state.permissions.toJS()))
      .then(() => {
        this.setState({ isSaving: false, changesMade: false });
        this.onUserPermissionChange(userGroup.get('_id'), this.state.permissions);
        createSuccessNotification(translate('permissions_updated'));
        return true;
      });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { userGroup } = this.props;
    if (!userGroup) {
      return <p>404</p>; // TODO:
    }
    return (
      <ContentTransition className="o-scrollable-container" style={{ height: '100vh' }}>
        <Header className="o-header">
          <h1>{userGroup.get('name')}</h1>
          <Link to="/admin/user-groups" className="o-button" style={{ marginLeft: 'auto' }}>{translate('back')}</Link>
        </Header>
        <SavePrompt
          when={this.state.changesMade}
          onSaveClicked={() => this.onSaveClicked(userGroup)}
        />
        <PermissionsEditor
          permissionsConfig={this.state.permissions}
          updatePermissions={permissions => this.setState({ permissions, changesMade: true })}
          isAdminUser={this.props.isAdminUser}
          config={this.props.config}
          klinifyConfig={this.props.klinifyConfig}
          user={this.props.user}
        />
        <SaveButton
          className="u-margin--standard"
          isSaving={this.state.isSaving}
          onClick={() => this.onSaveClicked(userGroup)}
        />
      </ContentTransition>
    );
  }
}

export default UserGroupPermissions;
