import React from 'react';
import { List } from 'immutable';

import CasenoteThumbnail from './casenoteThumbnail';
import TextCasenoteThumbnail from './../textCasenotes/textCasenoteThumbnail';
import translate from './../../utils/i18n';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Header from './../header/header';

import CaseNoteFileModel from './../../models/caseNoteFileModel';
import type { Config, User, SaveModel } from './../../types';
import { saveBlob, isImageFile, handleResponseError } from '../../utils/utils';
import { createInfoNotification, createSuccessNotification } from '../../utils/notifications';
import { getHighestCasenoteOrder } from '../../utils/db';
import { CASENOTE_ORDER_INCREMENT } from './../../constants';

type Props = {
  config: Config,
  casenotes: List<CaseNoteFileModel>, // The casenotes to display.
  categoryID: string, // The category the component will add casenotes to.
  patientID: string,
  title: string, // The title to show for the component.
  isOnline: boolean,
  user: User,
  sidebarCategoryID: string,
  saveModel: SaveModel,
};

/**
 * A view in card format with a casenote category assigned to it. There is a category title and
 * add casenote button at the top, then a paginated section displaying the casenotes of that
 * category.
 * @class CategoryOverview
 * @extends {React.Component}
 */
class CasenoteGrid extends React.PureComponent<Props> {
  props: Props;

  uploadInput: HTMLInputElement | null;

  handleUpload = () => {
    if (this.uploadInput && this.uploadInput.files.length > 0) {
      const file = this.uploadInput.files[0];
      if (file.size > 10485760) { // 10MB
        createInfoNotification(translate('upload_file_limit_error'));
      } else {
        saveBlob(file)
          .then((assetObject) => {
            const title = file.name.replace(/\.[^/.]+$/, '');
            const casenote = new CaseNoteFileModel({
              category: this.props.categoryID || this.props.config.getIn(['categories', 'notes_category'], '0_KLINIFY_NOTES'),
              patient_id: this.props.patientID,
              source: { webapp_imported: true },
              assets: [assetObject],
              star: false,
              order: 0,
              title,
              file_name: file.name,
            });
            getHighestCasenoteOrder(this.props.patientID)
              .then(order => this.props.saveModel(casenote.set('order', order + CASENOTE_ORDER_INCREMENT)))
              .then(() => {
                createSuccessNotification(translate(isImageFile(file.type) ? 'image_uploaded' : 'file_uploaded', { name: file.name }));
              })
              .catch((error) => {
                handleResponseError(error.status);
              });
          });
      }
    }
  }

  renderUploadButton = () => (
    <>
      <input
        key="hiddenUploadInput"
        type="file"
        id="upload-input"
        style={{ display: 'none' }}
        ref={(uploadInput) => { this.uploadInput = uploadInput; }}
        onChange={this.handleUpload}
      />
      <button
        className="o-button o-button--small"
        onClick={() => this.uploadInput?.click()}
      >
        {translate('add_casenote')}
      </button>
    </>
  )

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card" style={{ paddingBottom: '0.5px' }}>
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{this.props.title}</h1>
          <div className="u-flex-right u-margin-right--1ws">
            {
              this.props.sidebarCategoryID !== 'BIN' &&
              this.props.isOnline &&
              <PermissionWrapper permissionsRequired={List([createPermission('patient_casenotes', 'create')])} user={this.props.user}>
                {this.renderUploadButton()}
              </PermissionWrapper>
            }
          </div>
        </Header>
        <div className="c-casenote-grid">
          {
            this.props.casenotes.size === 0 ?
              <p>{translate('no_casenotes_in_category')}</p> :
              this.props.casenotes
                .map(c => (
                  c.isText()
                    ?
                      <TextCasenoteThumbnail
                        config={this.props.config}
                        casenote={c}
                        key={`thumbnail-${c.get('_id')}`}
                        sidebarCategoryID={this.props.sidebarCategoryID}
                      />
                    :
                      <CasenoteThumbnail
                        sidebarCategoryID={this.props.sidebarCategoryID}
                        key={`thumbnail-${c.get('_id')}`}
                        config={this.props.config}
                        casenote={c}
                        showPageCount
                        style={{ maxHeight: '493px', border: '1px solid black', overflow: 'hidden', cursor: 'pointer' }}
                      />
                ))
                .toArray()
          }
        </div>
      </div>
    );
  }
}

export default CasenoteGrid;
