import * as React from 'react';
import { List } from 'immutable';

import { convertNumberToPrice } from './../../utils/utils';
import translate from './../../utils/i18n';
import BillSummaryCost from './billSummaryCost';
import { calculateBillItemTotal } from './../../utils/billing';
import Icon from './../icon';
import { colours, wsUnit } from './../../utils/css';

import type BillItemModel from './../../models/billItemModel';
import type DrugModel from './../../models/drugModel';
import type SalesItemModel from './../../models/salesItemModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import type { ComponentReference } from './../../types';
import { debugPrint } from '../../utils/logging';
import DiscountChargeModel from 'src/models/discountChargeModel';

type Props = {
  drugs: List<DrugModel>,
  hasCoveragePayor: boolean,
  maxClaimableAmount: ?number,
  items: List<BillItemModel>,
  salesItems: List<SalesItemModel>,
  procedureTypes: List<ProcedureTypeModel>,
  title: string,
  coPayment: number,
  providers: List<ProviderModel>,
  usedDiscountsCharges: List<DiscountChargeModel>,
  isAddPastBill: boolean,
};

/**
 * BillSummary
 * @namespace BillSummary
 * @returns {React.Component} A BillSummary component.
 */
class BillSummary extends React.Component<Props, {}> {
  static defaultProps = {
    hasPanel: false,
    coPayment: 0, // If undefined we treat it as 0 for calculations sake.
  };

  props: Props;

  itemList: ComponentReference | null | undefined;

  /**
   * Scroll to bottom of items list when new item added.
   * @param {Props} prevProps previous props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.items.size < this.props.items.size && this.itemList) {
      this.itemList.scrollTop = this.itemList.scrollHeight;
    }
  }

  /**
   * Calculates the total discounts and charges.
   * @param {List<BillItemModel>} items previous props
   * @param {List<DiscountChargeModel>} usedDiscountsCharges previous props
   * @returns {number} A formatted price
   */
  calculateFullDiscount(items: List<BillItemModel>, usedDiscountsCharges: List<DiscountChargeModel>): number {
    return items.reduce((sum, bItem) => {
      return sum + usedDiscountsCharges.reduce(([subtotal, discount], item) => {
        const amountToAdd = subtotal * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
        return [subtotal + amountToAdd, discount + amountToAdd];
      }, [calculateBillItemTotal(List([bItem])), 0])[1];
    }, 0);
  }

  /**
   * Calculates the total owed by the coverage payor.
   * @returns {number} The total price
   */
  calculateCoveragePayorTotal(): number {
    const claimedBillItems = this.props.items.filter(i => i.isClaimed(this.props.hasCoveragePayor));
    const billItemTotal = calculateBillItemTotal(
      claimedBillItems,
    ) + this.calculateFullDiscount(claimedBillItems, this.props.usedDiscountsCharges);
    if (!billItemTotal || this.props.coPayment > billItemTotal) {
      return 0;
    }
    return billItemTotal - this.props.coPayment;
  }


  /**
   * Calculates the total owed by the patient.
   * @returns {number} A formatted price
   */
  calculatePatientTotal(): number {
    const nonClaimedBillItems = this.props.items.filter(i => !i.isClaimed(this.props.hasCoveragePayor));
    const nonClaimedItemsTotal = calculateBillItemTotal(nonClaimedBillItems) + this.calculateFullDiscount(nonClaimedBillItems, this.props.usedDiscountsCharges);
    const claimedBillItems = this.props.items.filter(i => i.isClaimed(this.props.hasCoveragePayor));
    const claimedItemsTotal = calculateBillItemTotal(claimedBillItems) + this.calculateFullDiscount(claimedBillItems, this.props.usedDiscountsCharges);
    if (claimedItemsTotal > this.props.coPayment || this.props.coPayment === 0) {
      return nonClaimedItemsTotal + this.props.coPayment;
    }
    return nonClaimedItemsTotal + claimedItemsTotal; // i.e. claimed total is less then co-payment amount, so patient pays all.
  }

  /**
   * Calculates the total after adding discounts and charges.
   * @returns {string} A formatted price
   */
  calculateFullTotal(): number {
    // return convertNumberToPrice(this.props.usedDiscountsCharges.reduce((subtotal, item) => {
    //   const amountToAdd = subtotal * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
    //   return subtotal + amountToAdd;
    // }, calculateBillItemTotal(this.props.items)));
    const totalDiscount = this.props.usedDiscountsCharges.reduce<[List<number>, number]>(([btItems, subtot], item) => {
      const [amountToAdd, updatedBtItems] = btItems.reduce(([sum, list], bItem) => {
        const amountToAdd = bItem * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
        return [sum + amountToAdd, list.push(bItem + amountToAdd)];
      }, [0, List()]);
      return ([
        updatedBtItems,
        subtot + amountToAdd,
      ]);
    }, [this.props.items.map(bItem => calculateBillItemTotal(List([bItem]))), 0])[1];
    return calculateBillItemTotal(this.props.items) + totalDiscount;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering BillSummary');
    const {
      items,
      drugs,
      salesItems,
      title,
      hasCoveragePayor,
      maxClaimableAmount,
      usedDiscountsCharges,
    } = this.props;
    return (
      <div style={{ fontSize: '12px' }}>
        <h2 className="o-card__title">{title}</h2>
        <h2 data-public className="o-card__title">{translate('claimable_items')}</h2>
        <BillSummaryCost
          procedureTypes={this.props.procedureTypes}
          providers={this.props.providers}
          drugs={drugs}
          salesItems={salesItems}
          items={items.filter(i => i.isClaimed(hasCoveragePayor))}
          showTotal={false}
          ref={(c) => { this.itemList = c; }}
        />
        <h2 data-public className="o-card__title">{translate('unclaimable_items')}</h2>
        <BillSummaryCost
          procedureTypes={this.props.procedureTypes}
          providers={this.props.providers}
          drugs={drugs}
          salesItems={salesItems}
          items={items.filter(i => !i.isClaimed(hasCoveragePayor))}
          showTotal={false}
          ref={(c) => { this.itemList = c; }}
        />
        <li className="o-data-list__row u-bg-grey3">
          <div className="o-data-list__row__item  u-strong">{translate('subtotal')}</div>
          <div className="o-data-list__row__item" />
          <div className="o-data-list__row__item  u-strong">{convertNumberToPrice(calculateBillItemTotal(items))}</div>
        </li>
        { !this.props.isAddPastBill && <h2 data-public className="o-card__title">{translate('discounts_charges')}</h2> }
        <ul style={{ maxHeight: '20vh', overflowY: 'scroll' }}>
          { !this.props.isAddPastBill && usedDiscountsCharges.size === 0 && <p className="u-padding--1ws">{translate('no_discounts_charges_added')}</p>}
          {
            usedDiscountsCharges.reduce<[List<number>, Array<React.ReactElement>]>(([btItems, arr], item) => {
              const [amountToAdd, updatedBtItems] = btItems.reduce(([sum, list], bItem) => {
                const amountToAdd = bItem * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
                return [sum + amountToAdd, list.push(bItem + amountToAdd)]
              }, [0, List()]);
              return ([
                updatedBtItems,
                [...arr, (
                  <li className="o-data-list__row" key={`billingOverviewItem-${item.get('_id')}`}>
                    <div className="o-data-list__row__item">{item.get('name')}</div>
                    <div className="o-data-list__row__item" />
                    <div className="o-data-list__row__item">
                      { convertNumberToPrice(parseFloat(amountToAdd.toFixed(2))) }
                    </div>
                  </li>
                )],
              ]);
            }, [items.map(bItem => calculateBillItemTotal(List([bItem]))), []])[1]
          }
        </ul>
        <li className="o-data-list__row u-bg-grey3">
          <div className="o-data-list__row__item  u-strong">{translate('total')}</div>
          <div className="o-data-list__row__item" />
          <div className="o-data-list__row__item  u-strong">{convertNumberToPrice(this.calculateFullTotal())}</div>
        </li>
        {
          hasCoveragePayor &&
          <li className="o-data-list__row u-bg-grey3">
            <div className="o-data-list__row__item  u-strong">{translate('patient_pays')}</div>
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item  u-strong">{convertNumberToPrice(this.calculatePatientTotal())}</div>
          </li>
        }
        {
          hasCoveragePayor &&
          <li className="o-data-list__row u-bg-grey3">
            <div className="o-data-list__row__item  u-strong">{translate('bill_to_panel')}</div>
            <div className="o-data-list__row__item" />
            <div className="o-data-list__row__item  u-strong">{convertNumberToPrice(this.calculateCoveragePayorTotal())}</div>
          </li>
        }
        {
          maxClaimableAmount !== undefined &&
          maxClaimableAmount !== null &&
          maxClaimableAmount < this.calculateCoveragePayorTotal() &&
          <li className="o-data-list__row u-font-color-white" style={{ background: colours.red }}>
            <div className="o-data-list__row__item  u-strong">
              <Icon style={{ color: colours.white, marginRight: wsUnit }}>!</Icon>
              {translate('maximum_claimable_amount_exceeded')}
            </div>
          </li>
        }
      </div>
    );
  }
}

export default BillSummary;
