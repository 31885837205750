import React from 'react';
import { List } from 'immutable';
import { Link } from 'react-router-dom';

import { fetchDataAsset, isImageFile, getBaseApiUrl } from './../../utils/utils';
import { isReferralMode } from './../../utils/router';
import { hasPermission, createPermission } from './../../utils/permissions';
import ImageLoader from './../imageLoader';
import LoadingIndicator from './../loadingIndicator';
import FileIcon from './../fileIcon';
import PDFLoader from './../pdfLoader';
import translate from './../../utils/i18n';
import TextCasenote from './../textCasenotes/textCasenote';
import Header from './../header/header';

import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import type { Config, User } from './../../types';

type AssetObject = {
  id: string,
  datatype: string,
  url: string,
};

type Props = {
  casenote?: CaseNoteFileModel,
  casenoteIndex: number, // 1-based, not 0-based.
  casenoteTotal: number,
  previousLink?: string,
  nextLink?: string,
  categoryID?: string,
  config: Config,
  user: User,
}

type State = {
  PDFUrlData?: string,
  assetID?: string,
};

/**
 * A view for a single casenote that can scroll to view each asset. If nextLink and previousLink
 * props are given then links to the previous and next casenotes in the selected category will also
 * be shown.
 * @class Casenote
 * @extends {React.Component}
 */
class Casenote extends React.Component<Props, State> {
  /**
   * Creates an instance of Casenote.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Render asset component
   * @param {Object} asset asset object
   * @param {CaseNoteFileModel} casenote a casenote
   * @returns {React.Component} asset component
   */
  renderAsset(asset: AssetObject, casenote: CaseNoteFileModel) {
    if (isImageFile(asset.datatype)) {
      return (
        <ImageLoader
          imageSrc={asset.url}
          imageClassName="c-casenote__asset__img"
          imageAlt={casenote.get('_id')}
        />
      );
    } else if ((asset.datatype === 'application/pdf') && this.state.PDFUrlData) {
      // Clear current pdf to load the next pdf much faster
      if (this.state.assetID !== asset.id) {
        this.setState({ PDFUrlData: undefined });
      }
      return <PDFLoader urlData={this.state.PDFUrlData} />;
    }
    if (asset.datatype === 'application/pdf' && !this.state.PDFUrlData) return <LoadingIndicator />;
    const extension = (asset.datatype && casenote.has('file_name') && casenote.get('file_name').split('.').pop());
    const filename = `${casenote.get('title', translate('untitled'), false)}${extension ? `.${extension}` : ''}`;
    return (
      <FileIcon filename={filename} />
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { casenote } = this.props;
    if (!casenote) {
      let message;
      if (this.props.categoryID === 'BIN') {
        message = 'casenotes_bin_empty';
      } else if (this.props.categoryID === 'STARRED') {
        message = 'no_starred_casenotes';
      } else {
        message = hasPermission(this.props.user, List([createPermission('patient_casenotes', 'create')])) ?
          'no_casenotes_created_create_one' : 'no_casenotes_created';
      }
      return (
        <p className="u-margin--standard">
          {translate(message)}
        </p>
      );
    } else if (casenote.isText()) {
      return <TextCasenote {...this.props} />;
    }
    const assetURLs = casenote.getAssetUrls(`${getBaseApiUrl()}/`);
    return (
      <div className="c-casenote">
        <Header className="c-casenote__header" dataPublic>
          <div>{translate('note')}: <strong>{this.props.casenoteIndex}/{this.props.casenoteTotal}</strong></div>
          <div>{translate('date_created')}: <strong>{casenote.getCreationDate()}</strong></div>
        </Header>
        {
          assetURLs
            .map((asset: AssetObject, index: number) => {
              const assetID = asset.id;
              if (this.state.assetID !== assetID && asset.datatype === 'application/pdf') {
                this.setState({ assetID }, () => {
                  fetchDataAsset(assetID, asset.datatype)
                    .then(content => this.setState({ PDFUrlData: content }));
                });
              }
              return (
                <div className="c-casenote__asset" key={index}>
                  {this.renderAsset(asset, casenote)}
                  {
                    assetURLs.length > 1 &&
                    <div className="c-casenote__asset__page">
                      {`${index + 1}/${assetURLs.length}`}
                    </div>
                  }
                </div>
              );
            })
        }
        {
          this.props.previousLink &&
          <Link
            to={this.props.previousLink}
            className="c-casenote__nav-button--left"
            style={isReferralMode() ? { top: '30%' } : {}}
          >
            <i className="fa fa-chevron-left" />
          </Link>
        }
        {
          this.props.nextLink &&
          <Link
            to={this.props.nextLink}
            className="c-casenote__nav-button--right"
            style={isReferralMode() ? { top: '30%' } : {}}
          >
            <i className="fa fa-chevron-right" />
          </Link>
        }
      </div>
    );
  }
}

export default Casenote;
