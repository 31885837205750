import React, { Component } from 'react';
import { List } from 'immutable';

import SelectTable from './../table/selectTable';
import translate from './../../utils/i18n';
import StatelessModal from './../modals/statelessModal';
import TagInput from './../inputs/tagInput';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import { UNICODE } from './../../constants';
import { prettifyDate } from './../../utils/time';
import { downloadCSV, convertToCSV } from './../../utils/export';
import { renderWithLink, renderDateWithLink, renderMultilineContentWithLink } from './../../utils/tables';
import { fetchModels } from './../../utils/db';
import { trackEvent, CLICK, PATIENT_LIST } from './../../utils/analytics';
import Button from './../buttons/button';

import type { Config, SaveModel, Row, Column, CustomColumn } from './../../types';
import TableColumnsSettings from '../table/tableColumnsSettings';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import Header from '../header/header';
import CoveragePayorModel from '../../models/coveragePayorModel';
import { PolicyAttributes } from 'src/models/coveragePayorPolicyModel';

/* eslint-disable camelcase */

type Coverage = Array<{
  payor: string,
  subscriber: string,
  relationship: string,
  policy: string,
  policyHolder: string,
}>

type Patient = {
  patient_id: string,
  last_encounter: number,
  name?: string,
  phone?: string,
  patient_tags?: Array<string>,
  address?: string,
  case_id?: string,
  coverage?: Coverage,
  current_employer?: string,
  dob?: string,
  email?: string,
  ethnicity?: Array<String>,
  ic?: string,
  nationality?: string,
  notes?: string,
  occupation?: string,
  postal_code?: string,
  sex: string,
};
/* eslint-enable camelcase */

type Props = {
  patientList: Array<Patient>,
  config: Config,
  saveModel: SaveModel,
  isFetching: boolean,
  refetchPatientList: () => void,
  filterValues: Array<string>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  coveragePayors: List<CoveragePayorModel>,
};

type State = {
  isModalVisible: boolean,
  isSaving: boolean,
  selectedRows: Array<string>,
  modalTagsValue: Array<string>,
  filteredData: Array<Row>,
  columns: Array<CustomColumn>,
};

const COLUMNS = List(
  [
    { accessor: 'last_encounter_date', Header: translate('last_encounter_date'), Cell: renderDateWithLink, hideDefault: true },
    { accessor: 'patient_name', Header: translate('patient_name'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'contact_number', Header: translate('contact_number'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'tags', Header: translate('tags'), Cell: renderMultilineContentWithLink, hideDefault: true },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'ic', Header: translate('ic_number'), Cell: renderWithLink },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'dob', Header: translate('date_of_birth'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'nationality', Header: translate('country'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'ethnicity', Header: translate('ethnicity'), Cell: renderMultilineContentWithLink, hideDefault: true },
    { accessor: 'email', Header: translate('email'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'address', Header: translate('address'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'postal_code', Header: translate('postal_code'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'occupation', Header: translate('occupation'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'current_employer', Header: translate('current_employer'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'notes', Header: translate('notes'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'panel_name', Header: translate('panel'), Cell: renderWithLink },
    { accessor: 'policy_name', Header: translate('policy'), Cell: renderWithLink },
    { accessor: 'policy_holder', Header: translate('policy_holder'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'subscriber', Header: translate('subscriber'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'relationship', Header: translate('subscriber_relationship'), Cell: renderWithLink, hideDefault: true },
  ],
);

/**
 * A component that displays List of patients.
 * @class PatientsListTable
 * @extends {Component}
 */
class PatientsListTable extends Component<Props, State> {
  /**
   * Creates an instance of PatientsListTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isSaving: false,
      selectedRows: [],
      modalTagsValue: [],
      filteredData: [],
      columns: transformColumnKeys(COLUMNS.toArray()).filter(i => !i.hideDefault),
    };
  }

  /**
   * Creates the table rows for the patient.
   * @param {Patient} patient The patient
   * @returns {{}} An array of rows.
   */
  getRows(patient: Patient) {
    const patientCoverage = patient.coverage && patient.coverage[0];
    const patientCoveragePayor = patientCoverage && this.props.coveragePayors.find(m => m.get('_id') === patientCoverage.payor);
    const coverageData = {
      panel_name: patientCoveragePayor ? patientCoveragePayor.get('name') : UNICODE.EMDASH,
      policy_name: patientCoveragePayor ?
        patientCoveragePayor.get('policies', [])
          .find((a: PolicyAttributes) => a._id === patientCoverage?.policy)?.policy_name || UNICODE.EMDASH
        : UNICODE.EMDASH,
      subscriber: patientCoverage?.subscriber || UNICODE.EMDASH,
      relationship: patientCoverage?.relationship || UNICODE.EMDASH,
      policy_holder: patientCoverage?.policyHolder || UNICODE.EMDASH,
    }
    return {
      _id: patient.patient_id,
      last_encounter_date: patient.last_encounter,
      patient_name: patient.name || UNICODE.EMDASH,
      contact_number: patient.phone || UNICODE.EMDASH,
      tags: (patient.patient_tags && patient.patient_tags.length) ? patient.patient_tags.join(', ') : UNICODE.EMDASH,
      link: `/patient/${patient.patient_id}`,
      address: patient.address || UNICODE.EMDASH,
      case_id: patient.case_id || UNICODE.EMDASH,
      current_employer: patient.current_employer || UNICODE.EMDASH,
      dob: patient.dob || UNICODE.EMDASH,
      email: patient.email || UNICODE.EMDASH,
      ethnicity: patient.ethnicity || UNICODE.EMDASH,
      ic: patient.ic || UNICODE.EMDASH,
      nationality: patient.nationality || UNICODE.EMDASH,
      notes: patient.notes || UNICODE.EMDASH,
      occupation: patient.occupation || UNICODE.EMDASH,
      postal_code: patient.postal_code || UNICODE.EMDASH,
      sex: patient.sex || UNICODE.EMDASH,
      ...coverageData,
    };
  }

  /**
   * Handle saving of patient tags
   * @returns {undefined}
   */
  onSaveClicked() {
    fetchModels(this.state.selectedRows)
      .then((patients) => {
        Promise.all(
          patients.map(patient => this.props.saveModel(patient.set('tags', patient.has('tags')
            ? patient.get('tags').concat(this.state.modalTagsValue)
            : this.state.modalTagsValue))),
        )
          .then(() => {
            this.setState({ isModalVisible: false, modalTagsValue: [] });
            this.props.refetchPatientList();
          });
      });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const transformedColumnKeys = transformColumnKeys(COLUMNS.toArray());
    return (
      <div className="o-card u-margin-bottom--4ws">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{ translate('patient_list') }</h1>
        </Header>
        <div className="o-header-actions">
          <div className="u-flex-left u-margin-left--half-ws">
            <StatelessModal
              id="edit_tags_patients"
              buttonLabel={translate('add_patient_tags')}
              buttonClass="o-button o-button--small u-margin-right--half-ws"
              title={translate('add_patient_tags')}
              className="js-modal"
              visible={this.state.isModalVisible}
              setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
              explicitCloseOnly
              disableButton={this.state.selectedRows.length === 0}
              dataPublicHeader
            >
              <div className="o-form">
                <TagInput
                  id="patient_tags"
                  label={translate('tags')}
                  value={this.state.modalTagsValue
                    ? this.state.modalTagsValue.map(value =>
                      ({ value, label: value }))
                    : []
                  }
                  options={this.props.config.getIn(['patient_tags', 'options'], List())
                    .map(value => ({ value, label: value }))
                    .toArray()
                  }
                  onChange={newValue =>
                    this.setState({ modalTagsValue: newValue.map(v => v.value) })
                  }
                />
              </div>
              <ModalFooter>
                <SaveButton
                  dataPublic
                  onClick={() => {
                    trackEvent(PATIENT_LIST, CLICK, 'add_patient_tag_save_button');
                    this.onSaveClicked();
                  }}
                  isSaving={this.state.isSaving}
                  label={translate('save')}
                  className="o-button--small u-margin-right--half-ws"
                />
              </ModalFooter>
            </StatelessModal>
            <Button
              className="o-button o-button--small"
              onClick={() => {
                trackEvent(PATIENT_LIST, CLICK, 'export_result_button');
                const { columns } = this.state;
                const formattedData = this.state.filteredData.map(item => columns.map((column) => {
                  if (column.value === 'last_encounter_date') {
                    return prettifyDate(item[column.value]);
                  }
                  return item[column.value];
                }));
                downloadCSV('PatientList.csv', convertToCSV(columns.map(c => c.label), formattedData));
              }}
              disabled={(this.props.patientList && !this.props.patientList.length)}
              dataPublic
            >
              {translate('export_results')}
            </Button>
          </div>
          <div className="u-flex-right u-margin-right--half-ws">
            <TableColumnsSettings
              config={this.props.config}
              configFieldName="patient_list_column_setting"
              updateConfigValue={this.props.updateConfigValue}
              originalColumns={List(transformedColumnKeys)}
              columns={transformedColumnKeys}
              onUpdateColumns={columns => this.setState({ columns })}
              updateConfig={this.props.updateConfig}
            />
          </div>
        </div>
        <SelectTable
          columns={transformFilteredColumns(COLUMNS.toArray(), this.state.columns)}
          data={
            this.props.patientList ?
              this.props.patientList.map(patient => this.getRows(patient)) : []
          }
          noDataText={!this.props.filterValues.length ? translate('filter_at_least_one_of_the_options') :
            translate('no_items_matched_your_selection')}
          showPagination
          defaultSorted={[{ id: 'last_encounter_date', desc: true }]}
          getSelectedRows={selectedRows => this.setState({ selectedRows })}
          loading={this.props.isFetching}
          filteredSortedDataHandler={filteredData => this.setState({ filteredData })}
          initialDataHandler={initialData => this.setState({ filteredData: initialData })}
        />
      </div>
    );
  }
}

export default PatientsListTable;
