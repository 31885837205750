import { connect } from 'react-redux';

import PaymentTypes from './../components/paymentTypes/paymentTypes';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  paymentTypes: state.paymentTypes.filter(b => b.isVisible()),
  user: state.user,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const PaymentTypesContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentTypes);

export default PaymentTypesContainer;
