import { connect } from 'react-redux';

import CoveragePayors from './../components/coveragePayors/coveragePayors';
import { saveFactory } from './../utils/redux';
import { updateConfigValue, updateConfig } from './../actions';

import type { Dispatch, State, Config } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ coveragePayors, user, config }: State) =>
  ({ coveragePayors, user, config });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const CoveragePayorEditorContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePayors);

export default CoveragePayorEditorContainer;
