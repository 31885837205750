import React from 'react';
import { Map, List } from 'immutable';
import type { Column, MapValue } from './../../types';

import { UNICODE } from './../../constants';
import { createSuccessNotification } from './../../utils/notifications';
import translate from './../../utils/i18n';
import Table from './../table/table';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';
import { debugPrint } from '../../utils/logging';

type Props = {
  items: List<Map<string, string>>,
  columns: Array<Column>,
  onEditClicked: (item: Map<string, string>) => void,
  secondaryData?: {[key: string]: {[key: string]: string}},
  deleteMap: (item: Map<string, string>) => Promise<void>,
}

const defaultSorted = [{ id: 'name', desc: false }];

/**
   * Called when delete is clicked on an item. Updates its state to be hidden.
   * @param {Map<string, string>} item The item to delete
   * @param {deleteMap} deleteMap The function to delete the item.
   * @returns {void}
   */
function onDeleteClicked(
  item: Map<string, string>,
  deleteMap: (item: Map<string, string>) => Promise<void>,
) {
  if (item) {
    getConfirmation(translate('confirm_item_deletion'))
      .then(
        () => deleteMap(item)
          .then(() => { createSuccessNotification(translate('item_deleted')); }),
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {Map<string, string>} item An item for the delete button
 * @param {deleteMap} deleteMap The function to delete the item.
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(
  item: Map<string, string>,
  deleteMap: (item: Map<string, string>) => Promise<void>,
) {
  return (
    <Button
      className="o-text-button o-text-button--danger"
      onClick={() => onDeleteClicked(item, deleteMap)}
      dataPublic
    >
      {translate('delete')}
    </Button>
  );
}

/**
 * Returns an edit button component for the given item.
 * @param {Map<string, string>} item The item the edit button is for.
 * @param {function} onClick The function to run when edit is clicked.
 * @returns {React.Component} An edit button.
 */
function getEditButton(item: Map<string, string>, onClick: (item: Map<string, string>) => void) {
  return (
    <Button
      className="o-text-button o-text-button--contextual"
      onClick={() => onClick(item)}
      dataPublic
    >
      {translate('edit')}
    </Button>
  );
}

/**
 * Get a table row for the given item.
 * @param {Map<string, string>} item An item config
 * @param {Array<Column>} columns The table columns.
 * @param {any} onEditClicked onEditClicked
 * @param {any} secondaryData secondaryData
 * @param {DeleteMap} deleteMap deleteMap function
 * @returns {{}}
 */
function getTableRow(
  item: Map<string, string>,
  columns: Array<Column>, onEditClicked, secondaryData,
  deleteMap: (item: Map<string, string>) => Promise<void>,
) {
  const row: { [key: string]: MapValue } = {
    edit: getEditButton(item, onEditClicked),
    delete: getDeleteButton(item, deleteMap),
  };
  columns.forEach((column) => {
    if (column.useSecondaryData && secondaryData && secondaryData[item.get('_id')]) {
      row[column.accessor] = secondaryData[item.get('_id')][column.accessor];
    } else {
      row[column.accessor] = item.get(column.accessor, '');
    }
  });
  if (!row.short_code) {
    row.short_code = UNICODE.EMDASH;
  }
  return row;
}

/**
 * Table of strings
 * @param {Props} props Props
 * @returns {React.Component}
 */
const MapListEditorTable = ({
  items, columns, onEditClicked, secondaryData, deleteMap,
}: Props) => {
  debugPrint('Rendering MapListEditorTable');
  const allColumns = columns.concat([
    {
      accessor: 'edit', Header: '', sortable: false, show: true, width: 60,
    },
    {
      accessor: 'delete', Header: '', sortable: false, width: 60, show: true,
    },
  ]);
  const itemRows = items
    .map(item => getTableRow(item, columns, onEditClicked, secondaryData, deleteMap))
    .toArray();
  return (
    <Table
      columns={allColumns}
      data={itemRows}
      noDataText={translate('no_items')}
      showPagination
      defaultSorted={defaultSorted}
    />
  );
};

export default MapListEditorTable;
