import React from 'react';
import { Div } from 'glamorous';

import SaveButton from './../buttons/saveButton';
import TextArea from './../inputs/textarea';
import translate from './../../utils/i18n';
import CustomNoteModel from './../../models/customNoteModel';
import MedicalComplaintModel from './../../models/medicalComplaintModel';
import MedicationModel from './../../models/medicationModel';
import { wsUnit } from '../../utils/css';

import type { SaveModel, GenericPatientDataModel } from './../../types';

type Props = {
  onSaveComplete: () => void,
  patientID: string,
  saveModel: SaveModel,
  modelToEdit?: GenericPatientDataModel,
  type: 'custom_note' | 'medical_complaint' | 'medication',
};

type State = {
  isSaving: boolean,
  value: string,
  errorMessage?: string,
};


/**
 * A form component for editing or creating a generic patient data model (CustomNote, Complaint,
 * or CurrentMedication).
 * @class GenericPatientDataForm
 * @extends {React.Component<Props, State>}
 */
class GenericPatientDataForm extends React.Component<Props, State> {
  /**
   * Creates an instance of GenericPatientDataForm.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      value: this.props.modelToEdit ? this.props.modelToEdit.getValue() : '',
    };
  }

  /**
   * Updates the value according to the incoming model.
   * @param {Props} nextProps Next props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    const currentModel = this.props.modelToEdit;
    const nextModel = nextProps.modelToEdit;
    if (nextModel && (!currentModel || currentModel.get('_id') !== nextModel.get('_id'))) {
      this.setState({ value: nextModel.getValue() });
    }
  }

  /**
   * Checks the form validity and sets an error message if necessary.
   * @returns {boolean}
   */
  isValid() {
    if (this.state.value && this.state.value.length > 0) {
      return true;
    }
    this.setState({ errorMessage: translate('form_field_cannot_be_empty') });
    return false;
  }

  /**
   * Gets a new model of the correct type.
   * @returns {GenericPatientDataModel}
   */
  getNewModel(): GenericPatientDataModel {
    switch (this.props.type) {
      case 'custom_note':
        return new CustomNoteModel({ patient_id: this.props.patientID });
      case 'medical_complaint':
        return new MedicalComplaintModel({ patient_id: this.props.patientID });
      case 'medication':
        return new MedicationModel({ patient_id: this.props.patientID });
      default:
        throw new Error('The type given doesn\'t match any models.');
    }
  }

  /**
   * Handles save being clicked
   * @returns {void}
   */
  onSaveClicked() {
    if (this.isValid()) {
      const model = this.props.modelToEdit ? this.props.modelToEdit : this.getNewModel();
      this.props.saveModel(model.setValue(this.state.value))
        .then(() => {
          this.setState({
            isSaving: false,
            value: '',
            errorMessage: undefined,
          });
          this.props.onSaveComplete();
        });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Div css={{ padding: wsUnit, display: 'flex', flexDirection: 'column' }}>
        {
          this.state.errorMessage &&
          <div className="o-form__text-block o-form__text-block--error">
            {this.state.errorMessage}
          </div>
        }
        <TextArea
          id="genericPatientDataFormValue"
          value={this.state.value}
          onValueChanged={value => this.setState({ value })}
        />
        <SaveButton
          dataPublic
          isSaving={this.state.isSaving}
          onClick={() => this.onSaveClicked()}
          style={{ marginLeft: 'auto' }}
        />
      </Div>
    );
  }
}

export default GenericPatientDataForm;
