import moment from 'moment';
import { fromJS } from 'immutable';
import type { List } from 'immutable';

import BaseModel from './baseModel';
import { getDateFormat } from './../utils/time';
import { UNICODE } from './../constants';
import translate from './../utils/i18n';
import { getConfig, filterNonPrintableCharsExceptNewline } from './../utils/utils';

import type CoveragePayorModel from './coveragePayorModel';

export type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'patient',
  patient_name: string,
  ic?: string,
  dob?: string,
  sex?: string,
  postal_code?: string,
  ethnicity?: string | Array<string>,
  case_id?: string,
  nationality?: string,
  email?: string,
  tel?: string,
  address?: string,
  postal_code?: string,
  occ?: string,
  current_employer?: string,
  notes?: string,
  tags?: Array<string>,
  ward_number?: string,
  room_number?: string,
  bed_number?: string,
  coverage?: Array<{
    payor: string,
    subscriber: string,
    relationship: string,
    policy: string,
    policyHolder: string,
  }>,
  alert?: string,
};

/**
   * PatientModel
   *
   * @namespace PatientModel
   */
class PatientModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'patient';
  }

  /**
   * Returns false as this model is not a stub. Useful when we have a collection of PatientModels
   * and PatientStubModels mixed together and need to sort them out.
   * @returns {boolean} Always false
   */
  isStub(): boolean { return false; }

  /**
   * Gets a readable version of the patient's date of birth.
   * @param {string | void} format Date format
   * @return {string} - DOB or dash if not available.
   */
  getDOB(format: string | void = getDateFormat()) {
    const dob = this.get('dob');
    if (dob === undefined || dob === null || dob.length === 0) {
      return UNICODE.EMDASH;
    }
    return typeof dob === 'string' ? moment(dob).format(format) : (moment.utc(dob)).format(format);
  }

  /**
   * Returns the ethnicity/s of a patient as a comma seperated string or an em dash if not
   * available.
   * @returns  {string} - Ethnicities or dash if not available.
   */
  getEthnicity(): string {
    if (this.has('ethnicity') && this.get('ethnicity').length) {
      if (Array.isArray(this.get('ethnicity'))) {
        return this.get('ethnicity').join(', ');
      }
      return this.get('ethnicity');
    }
    return UNICODE.EMDASH;
  }

  /**
   * Return age as of today (or date provided) if 'dob' exists, dash string otherwise.
   * @param {number} asOf millisecond utc. Get date as of this date if provided.
   * @returns {string} Patient age.
   */
  getAge(asOf?: number): string {
    const dob = this.getOrBlank('dob');
    // Doing this to quash annoying deprecation warning.
    if (dob === '') {
      return UNICODE.EMDASH;
    }
    const currDate = asOf ? moment(asOf) : moment();
    const date = typeof dob === 'string' ? moment(dob) : moment.utc(dob);
    const diffMonths = date.isValid() ? currDate.diff(date, 'months') : undefined;
    const diffYears = date.isValid() ? currDate.diff(date, 'years') : undefined;

    if (!date.isValid()) {
      return UNICODE.EMDASH;
    }
    if (diffYears !== undefined && diffMonths !== undefined && diffYears < 5) {
      return translate('x_years_m_months_old', { x: diffYears, m: diffMonths % 12 });
    }
    return translate('x_years_old', { x: currDate.diff(date, 'years') });
  }

  /**
   * Returns the permissions map for this Patient or an empty map if none was found.
   * @return {Immutable.Map} - The permissions Map.
   */
  getPermissions() {
    return fromJS(this.get('permissions', {}));
  }

  /**
   * Returns true if the patient has coverage specified.
   * @returns {boolean} True if patient has coverage.
   */
  hasCoverage(): boolean {
    return this.has('coverage') && this.get('coverage').length > 0
      && this.get('coverage')[0].payor !== undefined
      && this.get('coverage')[0].payor !== null
      && this.get('coverage')[0].payor.length > 0;
  }

  /**
   * Returns true if the patient has policy specified.
   * @returns {boolean} True if patient has policy.
   */
  hasPolicy(): boolean {
    return this.has('coverage') && this.get('coverage').length > 0
      && this.get('coverage')[0].policy !== undefined
      && this.get('coverage')[0].policy !== null
      && this.get('coverage')[0].policy.length > 0;
  }

  /**
   * Returns the id of the Coverage payor for this patient or undefined if none specified.
   * @returns {(string | void)} CoveragePayor ID or undefined.
   */
  getCoveragePayor(): string | void {
    return this.hasCoverage() ? this.get('coverage')[0].payor : undefined;
  }

  /**
   * Returns the id of the Coverage payor policy for this patient or undefined if none specified.
   * @returns {(string | void)} CoveragePayorPolicy ID or undefined.
   */
  getCoveragePolicy(): string | void {
    return this.hasPolicy() ? this.get('coverage')[0].policy : undefined;
  }

  /**
   * Sets the coveragePayorID for this patient (currently assumes a single coveragePayor). If
   * no string is given then it's assumed the patient is being updated to have no coverage.
   * @param {string} coveragePayorID A coveragePayorID.
   * @returns {BaseModel} The updated model.
   */
  setCoveragePayor(coveragePayorID?: string): BaseModel {
    if (coveragePayorID) {
      let updatedCoverage;
      if (this.hasCoverage()) {
        updatedCoverage = Object.assign(this.get('coverage')[0], { payor: coveragePayorID });
      } else {
        updatedCoverage = { payor: coveragePayorID };
      }
      return this.set('coverage', [updatedCoverage]);
    }
    return this.set('coverage', []);
  }

  /**
   * Sets the coveragePayorID and policyID for this patient (currently assumes a single coveragePayor). If
   * no string is given then it's assumed the patient is being updated to have no coverage.
   * @param {string} coveragePayorID A coveragePayorID.
   * @param {string} policyID A policyID.
   * @returns {BaseModel} The updated model.
   */
  setCoverageField(coveragePayorID?: string, policyID?: string): BaseModel {
    if (coveragePayorID) {
      let updatedCoverage;
      if (this.hasCoverage()) {
        updatedCoverage = Object.assign(this.get('coverage')[0], { payor: coveragePayorID, policy: policyID || '' });
      } else {
        updatedCoverage = { payor: coveragePayorID, policy: policyID || '' };
      }
      return this.set('coverage', [updatedCoverage]);
    }
    return this.set('coverage', []);
  }

  /**
   * Gets the payor for this patients coverage or an empty string if non-existant.
   * @param {Immutable.List} coveragePayors A List of all CoveragePayorModels.
   * @returns {string} Coverage payor.
   */
  getCoveragePayorName(coveragePayors: List<CoveragePayorModel>): string {
    if (this.hasCoverage()) {
      const payor = coveragePayors.find(model => model.get('_id') === this.get('coverage')[0].payor);
      if (payor) {
        return payor.get('name');
      }
    }
    return getConfig().getIn(['patient', 'labels', 'coverage', 'no_coverage'], 'No Panel (Cash)');
  }

  /**
   * Gets the policy name for this patient or an empty string if non-existant.
   * @param {Immutable.List} coveragePayors A List of all CoveragePayorModels.
   * @returns {string} Coverage policy name.
   */
  getCoveragePayorPolicyName(coveragePayors: List<CoveragePayorModel>): string {
    if (this.hasCoverage()) {
      const payor = coveragePayors.find(model => model.get('_id') === this.get('coverage')[0].payor);
      if (payor && this.get('coverage')[0].policy) {
        const policy = payor.get('policies', []).find(model => model._id === this.get('coverage')[0].policy);
        if (policy) {
          return policy.policy_name;
        }
        return UNICODE.EMDASH;
      }
      return UNICODE.EMDASH;
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the value at the given key in patient.coverage, or an em dash if not found.
   * @param {string} key The key of the field to get.
   * @returns {string} The value.
   */
  getCoverageField(key: string): string {
    if (this.hasCoverage() && this.get('coverage')[0][key] && this.get('coverage')[0][key].length > 0) {
      return this.get('coverage')[0][key];
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the payment type for this patient.
   * @returns {string} Payment type.
   */
  getPaymentType(): string {
    return this.hasCoverage() ? translate('panel') : translate('cash');
  }

  /**
   * Returns the address value of the patient by filtering non printable characters if any, used only
   * for the display in read only mode.
   * @returns  {string} - address or dash if not available.
   */
  getAddress(): string {
    const address = this.getOrBlank('address');
    if (address && address.length) {
      return filterNonPrintableCharsExceptNewline(address);
    }
    return UNICODE.EMDASH;
  }
}

export default PatientModel;
