import React from 'react';

import translate from './../../utils/i18n';

import { isEmailValid } from './../../utils/utils';
import TimeChitModel from './../../models/timeChitModel';
import SaveButton from './../buttons/saveButton';
import ModalFooter from './../modals/modalFooter';
import StatelessModal from './../modals/statelessModal';
import Input from './../inputs/input';
import { fetchModel } from './../../utils/db';

import type { Config } from './../../types';
import type PatientStubModel from '../../models/patientStubModel';

type Props = {
  title: string,
  buttonLabel: string,
  config: Config,
  disabled: boolean,
  onSave: (timeChit: TimeChitModel) => void,
  patient: PatientStubModel,
};

type State = {
  isSaving: boolean,
  errorMessage?: string,
  email: string,
  isEditModalVisible: boolean,
};

/**
   * Form for Updating patient Email.
   *
   * @namespace UpdatePatientEmail
   */
class UpdatePatientEmail extends React.Component<Props, State> {
  /**
   * Creates an instance of TimeChitForm.
   * @param {Props} props props
   * @return {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: undefined,
      email: this.props.patient.get('email') || '',
      isEditModalVisible: false,
      isSaving: false,
    };
  }

  /**
   * Called when component mounts.
   * @returns {void}
   */
  componentDidMount() {
    if (!this.props.patient.get('email')) {
      fetchModel(this.props.patient.get('_id'))
        .then((patient) => {
          this.setState({
            email: patient.get('email', ''),
          });
        })
        .catch(() => {});
    }
  }

  /**
   * Validate form
   * @return {boolean} - true if valid, false otherwise.
   */
  isFormValid() {
    if (!isEmailValid(this.state.email)) {
      this.setState({
        errorMessage: translate('fill_email_in_correct_format'),
      });
      return false;
    }
    return true;
  }

  /**
   * called on save click
   * @return {void}
   */
  onSaveClicked() {
    if (this.isFormValid()) {
      this.setState({ isSaving: true, errorMessage: undefined });
      this.props.onSave(this.state.email).then(() => {
        this.setState({
          isSaving: false,
          email: '',
          isEditModalVisible: false,
        });
      });
    }
    // TODO: Handle invalid form.
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <StatelessModal
        id="update-appointment-email-address"
        buttonLabel={this.props.buttonLabel}
        buttonClass="o-button o-button--small u-flex-1"
        disableButton={this.props.disabled}
        visible={this.state.isEditModalVisible}
        setVisible={isVisible => this.setState({ isEditModalVisible: isVisible })}
        onClose={() => { this.setState({ errorMessage: undefined, isSaving: false }); }}
        title={this.props.title}
        dataPublicHeader
      >
        <section className="o-form">
          {this.state.errorMessage && <div className="o-form__text-block o-form__text-block--error">{this.state.errorMessage}</div>}
          <Input
            id="email_address"
            value={this.state.email}
            label={translate('patient_email_address')}
            description={translate('econsult_email_update_message')}
            placeholder={translate('email_address')}
            onValueChanged={(email) => { this.setState({ email }); }}
          />
        </section>
        <ModalFooter>
          <SaveButton
            onClick={() => this.onSaveClicked()}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
            dataPublic
            isSaving={this.state.isSaving}
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default UpdatePatientEmail;
