import { connect } from 'react-redux';
import type { List } from 'immutable';

import CasenotesHeader from './../components/casenotes/casenotesHeader';
import { saveFactory } from './../utils/redux';
import { sortByNumber } from './../utils/comparators';

import type { State, Dispatch } from './../types';
import type CaseNoteFileModel from './../models/caseNoteFileModel';

type Props = {
  patientID: string,
  casenoteID?: string,
  categoryID?: string,
  gridViewActive?: boolean,
};

/**
 * Returns the first casenote to be displayed (for when now casenoteID is provided)
 * @param {List<CaseNoteFileModel>} casenotes A list of (ordered) casenotes.
 * @param {string} categoryID A category ID.
 * @returns {CaseNoteFileModel}
 */
function getFirstCasenote(casenotes: List<CaseNoteFileModel>, categoryID?: string) {
  let casenote;
  if (categoryID === 'BIN') {
    casenote = casenotes.find(c => !c.isVisible());
  } else if (categoryID === 'STARRED') {
    casenote = casenotes.find(c => c.isVisible() && c.isStarred());
  } else if (!categoryID || categoryID === 'ALL') {
    casenote = casenotes.find(c => c.isVisible());
  } else {
    casenote = casenotes.find(c => c.get('category') === categoryID && c.isVisible());
  }
  return casenote;
}

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps Container props.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (
  state: State,
  {
    casenoteID, patientID, categoryID, gridViewActive,
  }: Props,
) => {
  const casenotes = state.caseNoteFiles
    .filter(c => c.hasValidAssets() && c.get('patient_id') === patientID)
    .sort((a, b) => sortByNumber(a.get('order'), b.get('order')))
    .reverse();
  const casenote = casenoteID ?
    casenotes.find(c => c.get('_id') === casenoteID) :
    getFirstCasenote(casenotes, categoryID); // If no casenoteID given just pick the first casenote in the category
  return {
    casenote,
    patientID,
    categoryID,
    gridViewActive,
    isOnline: state.isOnline,
    user: state.user,
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const CasenotesHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(CasenotesHeader);

export default CasenotesHeaderContainer;
