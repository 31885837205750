import React from 'react';
import glamorous from 'glamorous';

import { fonts, scaledH2, colours, wsUnit } from './../../utils/css';

import type { HTMLStyle } from './../../types';

type Props = {
  onClick: () => void,
  label: string,
  disabled: boolean,
  style: HTMLStyle,
  dataPublic?: boolean,
};

const CloseButtonElement = glamorous.button({
  padding: `0 ${wsUnit}`,
  appearance: 'none',
  border: 'none',
  outline: 'none',
  background: 'none',
  fontFamily: fonts.medium,
  fontSize: scaledH2,
  color: colours.midnightGrey,
  cursor: 'pointer',
  ':hover': {
    color: colours.midnightGreyHover,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});

/**
 * A styled close button that triggers the function props.onClick when clicked.
 * @class CloseButton
 * @extends {React.Component<Props, State>}
 */
class CloseButton extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    label: '\u00d7',
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { disabled, onClick, label } = this.props;
    return (
      <CloseButtonElement
        onClick={() => !disabled && onClick()}
        disabled={disabled}
        style={this.props.style}
        data-public={this.props.dataPublic}
      >
        {label}
      </CloseButtonElement>
    );
  }
}

export default CloseButton;
