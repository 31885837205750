// @flow

import BaseModel from './baseModel';

type Attributes = {
    _id: string,
    created_by: { timestamp: number, user_id: string },
    edited_by: Array<{ timestamp: number, user_id: string }>,
    type: string,
    drug_id: string,
    has_prescribed: boolean,
    is_void: string,
    manufacturer: string,
    mapping_status: 'VERIFIED' | 'REJECTED' | 'MAPPED' | 'UNMAPPED' | 'PENDING_REVIEW',
    active_ingredients: string[],
    clinic_id: string,
    dispensation_unit: null,
    dosage_unit: string,
    drug_master_id: string | null,
    id: 9223,
    mapping_category: null | 0 | 1 | 2,
    name: string,
    prescribed_count: number,
    type: string,
  };


/**
  * InventoryDrugModel
  * @namespace InventoryDrugModel
  */
class InventoryDrugModel extends BaseModel {
    attributes: Attributes;

    /**
     * @param {object} attributes - The attributes for this model.
     */
    constructor(attributes: {} = {}) {
      super(attributes);
      this.attributes.type = 'drug_clinic';
    }
}

export default InventoryDrugModel;
