import { connect } from 'react-redux';
import { List } from 'immutable';

import AddNewBill from './../components/billing/addNewBill';
import { saveModelsFactory, saveFactory, saveModelsAPIFactory } from './../utils/redux';
import { updateInventoryItemCount, updateConfigValue, updateConfig } from './../actions';
import { saveBillingRelatedModels } from '../utils/api';
import { compareByAlphabeticalOrder } from '../utils/comparators';

import type { Dispatch, Config, State } from './../types';
import { getModelMapFromList } from '../utils/models';
import type BillItemModel from '../models/billItemModel';
import type BillModel from '../models/billModel';
import type EncounterModel from '../models/encounterModel';
import type TransactionModel from '../models/transactionModel';
import type PaymentModel from '../models/paymentModel';
import type ReceivableModel from '../models/receivableModel';
import type ClaimModel from '../models/claimModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State, { patientID }: { patientID: string }) => {
  const cachedPatient = state.patients.find(m => m.get('_id') === patientID);
  let patient;
  if (cachedPatient) {
    patient = cachedPatient;
  } else {
    patient = state.isFetching ?
      undefined : state.currentDataViewsModels.find(m => m.get('_id') === patientID);
  }
  if (!patient) {
    patient = state.patientStubs.find(stub => stub.get('_id') === patientID);
  }
  if (!patient) {
    throw new Error('Patient not found.');
  }
  const filteredProcedureRequests = state.procedureRequests
    .filter(procedureRequest => procedureRequest.get('patient_id') === patientID);
  return {
    config: state.config,
    klinifyConfig: state.klinifyConfig,
    coveragePayors: state.coveragePayors,
    drugs: state.drugs,
    discountsCharges: state.discountsCharges,
    patient,
    practitioners: state.practitioners.filter(p => p.isVisible()),
    salesItems: state.salesItems,
    inventoryCount: state.inventoryCount,
    inventoryCountSyncStatus: state.inventoryCountSyncStatus,
    user: state.user,
    providers: state.providers.filter(p => p.isActive()),
    procedureTypes: state.procedureTypes.filter(p => p.isActive() &&
    // @ts-ignore
      p.getProvider(state.providers).isActive()),
    procedureRequests: filteredProcedureRequests,
    documentTemplates: state.documentTemplates,
    paymentTypes: state.paymentTypes.filter(m => m.isVisible()),
    drugDurations: state.config.getIn(['prescription', 'durations'], List()).filter((e: Array<[number, string]>) => e !== null && e !== undefined),
    isOnline: state.isOnline,
    encounterFlows: state.encounterFlows.filter(flow => flow.isVisible()).sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
    encounterStageMap: getModelMapFromList(state.encounterStages),
    verifiedDrugs: state.verifiedDrugs,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
  saveModel: saveFactory(dispatch),
  updateInventoryItemCount: (skuID: string, change: number) =>
    dispatch(updateInventoryItemCount(skuID, change)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  saveBillingRelatedModels: (
    bill: BillModel,
    transactions: List<TransactionModel>,
    billItems: List<BillItemModel>,
    encounter: EncounterModel,
    payments?: List<PaymentModel>,
    receivables?: List<ReceivableModel>,
    claims?: List<ClaimModel>,
  ) => saveModelsAPIFactory(dispatch)(() =>
    saveBillingRelatedModels(dispatch, bill, transactions, billItems, encounter, payments, receivables, claims)),
});

const AddNewBillContainer = connect(mapStateToProps, mapDispatchToProps)(AddNewBill);

export default AddNewBillContainer;
