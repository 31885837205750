import React, { useState } from 'react';
import { Div } from 'glamorous';
import { List } from 'immutable';

import StatelessModal from '../modals/statelessModal';
import translate from '../../utils/i18n';
import Input from '../inputs/input';
import TextArea from '../inputs/textarea';
import Select from '../inputs/select';
import { mapStringToOption } from '../../utils/inputs';
import PatientCampaignSetModel from '../../models/patientCampaignSetModel';
import Button from '../buttons/button';
import { colours, wsUnit } from '../../utils/css';
import ModalFooter from '../modals/modalFooter';
import { createPermission } from '../../utils/permissions';
import PermissionWrapper from '../permissions/permissionWrapper';
import UnsavedDataModal from '../prompts/unsavedDataModal';
import type { User } from '../../types';
import FormError from '../formError';

type Props = {
  isVisible: boolean;
  selectedCampaignSet?: PatientCampaignSetModel;
  /** onDelete is not required if readOnly is true */
  onDelete?: (campaignset: PatientCampaignSetModel) => void;
  hideModal: () => void;
  onSave: (
    campaign: PatientCampaignSetModel,
    action: 'update' | 'create',
    newStatus?: 'activate' | 'deactivate',
  ) => Promise<void>;
  user: User;
  /** Modal title */
  title: string;
  hasUpdatePermission?: boolean;
  hasCreatePermission?: boolean;
};

const campaignStatuses = [
  'no_changes',
  'active',
  'inactive',
];

/**
 *
 * @param {Props} prop prop to the component
 * @returns {React.ReactNode}
 */
const CampaignForm = ({
  isVisible,
  selectedCampaignSet,
  hideModal,
  onDelete,
  onSave,
  user,
  title,
  hasUpdatePermission,
  hasCreatePermission,
}: Props) => {
  const defaultCampaignStatus = selectedCampaignSet ? 'no_changes' : 'active';
  const campaignDropdownoptions = mapStringToOption(
    selectedCampaignSet ? campaignStatuses : campaignStatuses.slice(1),
  );
  const [campaignSetAttributes, setCampaignSetAttributes] = useState({
    name: selectedCampaignSet?.get('name') ?? '',
    description: selectedCampaignSet?.get('description') ?? '',
    is_active: undefined,
  });
  const [campaignSetStatus, setCampaignSetStatus] = useState<
    string | undefined
  >(defaultCampaignStatus);
  const [isSaving, setIsSaving] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [unsavedPromptVisible, setUnsavedPromptVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  /**
   * @param {boolean} visible isVisible flag
   * @param {boolean} forceClose forceclose the modal, even if changesMade is true
   * Used to override async nature of usestate
   * @return {void}
   */
  const handleClose = (visible: boolean, forceClose: boolean = false) => {
    if (!visible) {
      if (changesMade && !forceClose) {
        setUnsavedPromptVisible(true);
      } else {
        hideModal();
      }
    }
  };
  /**
   * @param {string} value Value to set
   * @param {string} key attribute name
   * @returns {void}
   */
  const handleChange = (value: string, key: string) => {
    setCampaignSetAttributes({ ...campaignSetAttributes, [key]: value });
    if (selectedCampaignSet && !changesMade) {
      setChangesMade(true);
    }
  };

  /**
   * Returns `is_active` value of campaign set from selected dropdown value
   * Returns false if campaignSetStatus is not set or set to `no_change`.
   * @returns {boolean}
   */
  const getCampaignSetStatus = () => {
    switch (campaignSetStatus) {
      case 'active':
        return true;
      case 'inactive':
        return false;
      default:
        return selectedCampaignSet?.get('is_active') ?? false;
    }
  };

  /**
   * Returns true if required fields are present
   * Else shows error message
   * @returns {boolean}
   */
  const isValid = (): boolean => {
    if (!campaignSetAttributes.name) {
      setErrorMessage(translate('please_enter_valid_campaign_set_name'));
      return false;
    }
    return true;
  };

  /**
   * Saves the campaign set
   * Called only when accessed from patient campaign set.
   * @return {void}
   */
  const saveCampaignSet = () => {
    if (isValid()) {
      setIsSaving(true);
      const selectedCapaignSetStatus = selectedCampaignSet?.get('is_active') ? 'active' : 'inactive';
      const newStatus = campaignSetStatus !== 'no_changes' && selectedCapaignSetStatus !== campaignSetStatus
        ? campaignSetStatus === 'active' ? 'activate' : 'deactivate'
        : undefined;

      const modelToUpdate = selectedCampaignSet?
        new PatientCampaignSetModel(Object.assign({},
          selectedCampaignSet.attributes, campaignSetAttributes,
          { is_active: getCampaignSetStatus()},
          selectedCampaignSet.get('master_campaign_set_type')
            ? { master_campaign_set_type: selectedCampaignSet.get('master_campaign_set_type') }
            : {}
          ))
        : new PatientCampaignSetModel({
          ...campaignSetAttributes,
          is_active: getCampaignSetStatus(),
        });
      const action = selectedCampaignSet ? 'update' : 'create';
      onSave(modelToUpdate, action, newStatus)
        .then(() => {
          setChangesMade(false);
          setIsSaving(false);
          handleClose(false, true);
        })
        .catch(() => setIsSaving(false));
    }
  };

  const isMedadvisorCampaign =
    selectedCampaignSet?.get('master_campaign_set_type') === 'MEDADVISOR';
  const readOnly = isMedadvisorCampaign || (selectedCampaignSet && !hasUpdatePermission);
  const isCampaignGloballyActive = true;
  // Status can be changed for globally active Medadvisor campaign sets, but not other fields
  const isStatusChangable = isMedadvisorCampaign
    ? isCampaignGloballyActive && hasUpdatePermission
    : selectedCampaignSet ? hasUpdatePermission : hasCreatePermission;
  const medadvisorCampaignNote = isCampaignGloballyActive
    ? (
      <>Note: As this is a MedAdvisor Campaign Set, campaign set details
    cannot be edited. If you do not wish to run this campaign set, you
    may set the campaign set status to &quot;Inactive&quot;.
      </>)
    : 'Note: This campaign set is temporarily inactive.';
  return (
    <StatelessModal
      id="add-edit-bank-form"
      visible={isVisible}
      setVisible={handleClose}
      noButton
      explicitCloseOnly={!readOnly}
      title={title}
      dataPublicHeader
    >
      {errorMessage &&
        <FormError containerElementID="supply-form">
          {errorMessage}
        </FormError>
      }
      <section className="o-form" id="add-edit-campaign-form">
        <Input
          autoFocus
          id="campaign-set-name"
          onValueChanged={(value: string) => handleChange(value, 'name')}
          value={campaignSetAttributes.name}
          label={translate('campaign_set_name')}
          required
          placeholder={translate('campaign_name_palceholder')}
          disabled={readOnly}
          className="u-margin-bottom--1ws"
        />
        <TextArea
          id="campaign-set-goal"
          value={campaignSetAttributes.description}
          onValueChanged={value => handleChange(value, 'description')}
          label={translate('goal')}
          placeholder={translate('campaign_goal_placeholder')}
          disabled={readOnly}
        />
        {isMedadvisorCampaign && (
          <Div css={{ color: colours.grey5, lineHeight: 1.5 }}>
            {medadvisorCampaignNote}
          </Div>
        )}
        <hr />
        <Select
          id="campaign-set-status"
          options={campaignDropdownoptions}
          onValueChanged={newVal =>
            setCampaignSetStatus(newVal)
          }
          value={campaignSetStatus}
          label={translate('campaign_set_status')}
          disabled={!isStatusChangable}
          description={
            <p className="u-font-italic u-font-small o-label">
              This will apply to all campaigns in the campaign set{' '}
              <span>
                Inactive campaigns will neither send SMSes nor queue SMSes up.
              </span>
            </p>
          }
        />
        {onDelete && selectedCampaignSet && (
          <PermissionWrapper
            permissionsRequired={List([
              createPermission('sms_campaigns', 'delete'),
            ])}
            user={user}
          >
            <Div css={{ margin: `0 calc(-1.5 * ${wsUnit})` }}>
              <Button
                className="o-delete-button"
                onClick={() =>
                  // @ts-ignore
                  onDelete(selectedCampaignSet).then(() => hideModal())
                }
              >
                {translate('delete')}
              </Button>
            </Div>
          </PermissionWrapper>
        )}
        {isStatusChangable && (
          <ModalFooter>
            <button
              type="button"
              className="o-button o-button--padded o-button--small"
              disabled={isSaving}
              onClick={saveCampaignSet}
            >
              {translate('save')}
            </button>
          </ModalFooter>)}
      </section>
      <UnsavedDataModal
        visible={unsavedPromptVisible}
        onSave={saveCampaignSet}
        onDiscard={() => hideModal()}
        onCancel={() => setUnsavedPromptVisible(false)}
      />
    </StatelessModal>
  );
};

export default CampaignForm;
