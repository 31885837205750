import React, { useState, useEffect } from 'react';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import type { Search } from 'js-search';

import translate from './../../utils/i18n';
import QueueButtonContainer from './../../containers/queueButtonContainer';
import PermissionWrapper from './../permissions/permissionWrapper';
import { renderDateWithLink, renderWithLink } from './../../utils/tables';
import { UNICODE } from './../../constants';
import { sortByDate } from './../../utils/comparators';
import { createPermission } from './../../utils/permissions';
import { isKlinifyUser } from './../../utils/auth';
import Button from '../buttons/button';

import type { Config, Row, User } from './../../types';
import PatientStubModel from './../../models/patientStubModel';
import PatientSearchTable from './patientSearchTable';
import EncounterModel from '../../models/encounterModel';
import AppointmentModel from '../../models/appointmentModel';
import Confirm from './../prompts/confirm';
import PatientModel from '../../models/patientModel';

type Props = {
  config: Config,
  klinifyConfig: Config,
  onPatientAddedToQueue: (patientID: string) => void,
  patientStubs: Map<string, PatientStubModel>,
  searchQuery: string,
  user: User,
  scheduledPatients: Map<string, boolean>,
  patientsSearch: Search,
  patientSearchQuery: string,
  encounters: List<EncounterModel>,
  appointments: List<AppointmentModel>,
};

/**
 * Creates a table row for the search result table.
 * @param {PatientModel} patient The patient
 * @param {boolean} isScheduled Already scheduled patient
 * @returns {object} A table row.
 */
function getTableRow(patient: PatientModel, isScheduled: boolean) {
  return {
    patient_id: patient.get('_id'),
    case_id: patient.get('case_id', UNICODE.EMDASH, false),
    patient_name: patient.get('patient_name', UNICODE.EMDASH, false),
    sex: patient.get('sex', UNICODE.EMDASH, false),
    ic: patient.get('ic', UNICODE.EMDASH, false),
    dob: patient.get('dob', UNICODE.EMDASH, false),
    payment_type: patient.getPaymentType(),
    action: '',
    link: `/patient/${patient.get('_id')}`,
    isScheduled,
  };
}

/**
 * Returns content to show inside confirmation modal tried to queue same patient multiple times
 * @param {string} patientID patient id
 * @param {Function} hideConfirm callback to hide confirm modal
 * @param {boolean} isScheduling True if user schedule button is clicked
 * @returns {JSX.Element}
 */
export const getQueueConfirmModalMessage = (
  patientID: string,
  hideConfirm: () => void,
  isScheduling: boolean,
) => {
  if (isScheduling) {
    return (
      <React.Fragment>
        {translate('patient_have_appointment_warning')}<br /><br />
        {translate('consider_adding_stage_to_patient_flow')}<br /><br />
        <a href="https://klin.freshdesk.com/support/solutions/folders/36000221434" target="_blank" rel="noopener noreferrer">Learn More.</a><br /><br />
        <Link onClick={hideConfirm} to={`/patient/${patientID}`} className="o-button o-button--small  o-button--full-width" style={{ marginLeft: 0, padding: 'unset', display: 'inline-block' }}>{translate('go_to_patients_page')}</Link>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {translate('patient_cant_be_scheduled_warning')}<br /><br />
      {translate('consider_adding_stage_to_patient_flow')}<br /><br />
      <a href="https://klin.freshdesk.com/support/solutions/folders/36000221434" target="_blank" rel="noopener noreferrer">Learn More.</a><br /><br />
      <Link onClick={hideConfirm} to={`/patient/${patientID}`} className="o-button o-button--small  o-button--full-width" style={{ marginLeft: 0, padding: 'unset', display: 'inline-block' }}>{translate('go_to_patients_page')}</Link>
    </React.Fragment>
  );
};

/**
   * PatientSearchResultList
   * @namespace PatientSearchResultList
   * @param {object} props The props for this component.
   * @returns {React.Component} A PatientSearchResultList
   */
const PatientSearchResultList = ({
  searchQuery, onPatientAddedToQueue, patientStubs,
  config, user, scheduledPatients, patientsSearch,
  patientSearchQuery, klinifyConfig, encounters, appointments,
}: Props) => {
  const [patients, setPatients] = useState([]);
  const [isSearchResultReady, setIsSearchResultReady] = useState(false);

  /**
   * convert patients stub to rows
   * @param {Array<PatientStubModel>} patientsStubs patients stub
   * @returns {List<Row>} resolved or not
   */
  const getRows = (patientsStubs: Array<PatientStubModel>) => patientStubs && patientStubs.size > 0 && patientsStubs
    .map(patient =>
      getTableRow(patient, scheduledPatients.get(patient.get('_id'))));

  useEffect(() => {
    setIsSearchResultReady(false);
    patientsSearch.search(patientSearchQuery).then((data: any[]) => {
      const patientArr = data.map((d: string) => d && patientStubs.get(d));
      setPatients(patientArr);
      setIsSearchResultReady(true);
    });
  }, [patientSearchQuery]);

  const tableData = getRows(patients);
  const [queuePatient, setQueuePatient] = useState('');
  const [queueMode, setQueueMode] = useState('');
  const scheduleAppointments = isKlinifyUser(user) || klinifyConfig.getIn(['scheduling', 'scheduleAppointments'], false);
  // const [confirmModalPatientID, setConfirmModalPatientID] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  /**
   * Returns true if the patient has an active/ scheduled encounter for today
   * @param {string} id patient id
   * @returns {boolean}
   */
  const getPatientQueuedOrScheduled = (id: string) => {
    const currentEncountersForPatient = encounters.filter(m => m.get('patient_id') === id && m.isCurrent());
    const currentAppointmentsForPatient = appointments
      .filter(a => a.get('status') !== 'cancelled' && a.get('patient_id') === id &&
        a.isActive() && a.isToday());
    return currentAppointmentsForPatient.size > 0 || currentEncountersForPatient.size > 0;
  };


  /**
   * Gets an array of columns for react-table.
   * @returns {object[]} An array of objects each representing a column in the table.
   */
  function getColumns() {
    return [
      { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink },
      {
        accessor: 'patient_name', Header: translate('name'), minWidth: 200, Cell: renderWithLink,
      },
      { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink },
      {
        accessor: 'dob',
        Header: translate('dob'),
        Cell: renderDateWithLink,
        sortType: sortByDate,
      },
      { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink },
      { accessor: 'payment_type', Header: translate('payment_type'), Cell: renderWithLink },
      {
        accessor: 'action',
        Header: '',
        sortable: false,
        minWidth: 150,
        Cell: ({ row } : { row: Row }) => (row._original.patient_id ? (
          <div>
            <Button
              className="o-button o-button--small"
              onClick={(event) => {
                event.stopPropagation();
                setQueueMode('queue');
                const isPatientQueuedOrSchedule = getPatientQueuedOrScheduled(row._original.patient_id);
                if (isPatientQueuedOrSchedule) {
                  setShowConfirmModal(true);
                }
                setQueuePatient(row._original.patient_id);
              }}
              dataPublic
            >
              {translate('queue')}
            </Button>
            <PermissionWrapper permissionsRequired={List([createPermission('schedule_appointments', 'create')])} user={user}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              {scheduleAppointments &&
                <Button
                  className="o-button o-button--small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setQueueMode('schedule');
                    setQueuePatient(row._original.patient_id);
                  }}
                  disabled={row._original.isScheduled}
                  dataPublic
                >
                  {translate('schedule')}
                </Button>
                }
            </PermissionWrapper>
          </div>) : null),
      },
    ];
  }

  if (patients.length === 0) {
    return (
      <div className="o-text-content">
        <p className="u-font-italic">{isSearchResultReady ? translate('no_patients_matching_query', { query: searchQuery }) : translate('loading...')}</p>
        {isSearchResultReady ?
          <><p className="u-font-medium u-font-italic">{translate('registration_suggestion')}</p>
            <p>
              <Link
                className="o-button o-button--padded"
                to={`/registration?name=${encodeURIComponent(searchQuery)}`}
              >
                {translate('register_patient')}
              </Link>
            </p>
          </> : null
        }
      </div>
    );
  }
  const patient = patients.find(p => p.get('_id') === queuePatient);

  /**
   * @returns {void}
   */
  const resetConfirmModalState = () => {
    setQueueMode('');
    setQueuePatient('');
    setShowConfirmModal(false);
  };

  return (
    <div className="o-card u-margin-bottom--4ws">
      <h1 className="o-card__title" style={{ textTransform: 'none' }}>
        <span data-public className="u-font-light u-font-italic">{translate('you_have_searched_for')} </span><span className="u-font-medium u-font-italic">{searchQuery}</span>
      </h1>
      <PatientSearchTable
        columns={getColumns()}
        config={config}
        data={tableData}
      />
      <Confirm
        show={showConfirmModal}
        cancel={() => resetConfirmModalState()}
        proceed={() => resetConfirmModalState()}
        confirmation={getQueueConfirmModalMessage(patient?.get('_id'), resetConfirmModalState, false)}
        modalTitle={translate('patient_cannot_be_queued')}
        footerSaveButtonName={translate('acknowledge')}
        hideCancel
      />
      {queueMode === 'schedule' && patient &&
        <QueueButtonContainer
          patient={patient as PatientModel | PatientStubModel}
          onSuccess={(id) => {
            onPatientAddedToQueue(id);
            resetConfirmModalState();
          }}
          onError={() => {
            resetConfirmModalState();
          }}
          onClose={() => {
            resetConfirmModalState();
          }}
          modalVisible
          isSchedule
          noButton
        />
      }
      {queueMode === 'queue' && patient && !showConfirmModal &&
        <QueueButtonContainer
          patient={patient as PatientModel | PatientStubModel}
          onSuccess={(id) => {
            onPatientAddedToQueue(id);
            resetConfirmModalState();
          }}
          onError={() => {
            resetConfirmModalState();
          }}
          onClose={() => {
            resetConfirmModalState();
          }}
          modalVisible
          noButton
        />
      }
    </div>
  );
};

export default PatientSearchResultList;
