import React from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';
import { snakeCase } from 'lodash';

import { capitalize } from './../../utils/utils';
import { prettifyDate } from './../../utils/time';
import translate from './../../utils/i18n';
import { colours, wsUnit, scaledH1, scaledH3 } from '../../utils/css';

import DashboardStatModel from '../../models/dashboardStatModel';
import type { IntervalString } from './../../types';

import Chart from './../charts/lineChart';
import Loading from './../loadingIndicator';


type Props = {
  stats: List<DashboardStatModel>,
  isFetching: boolean,
  interval: IntervalString,
};

type PlottableData = {
    date: string,
    bills: number,
    focBills: number,
    patientsRegistered: number,
} | {
  date: string,
  revenueTotal: number,
  revenueCash: number,
} | {};


const Container = glamorous.div({
  '& h1': {
    fontSize: scaledH1,
  },
});

const ChartHeader = glamorous.div({
  background: colours.maroon,
  padding: `calc(${wsUnit} / 2) ${wsUnit}`,
  color: 'white',
  '& h3': {
    fontSize: scaledH3,
  },
  justifyContent: 'space-between',
});

/**
  * A component showing stat charts for given interval
  *
  * @namespace StatsInterval
  */
class StatsInterval extends React.PureComponent<Props> {
  billChartKeys = [
    'bills',
    'focBills',
    'patientsRegistered',
  ];

  revenueChartkeys = [
    'revenueTotal',
    'revenueCash',
  ];

  /**
   *
   * @param {DashboardStatModel} datum DashboardStatModel
   * @param {IntervalString} interval interval string
   * @returns {string}
   */
  getPlottableDate(datum: DashboardStatModel, interval: IntervalString) {
    switch (interval) {
      case 'daily':
        if (datum.get('day')) {
          return prettifyDate(datum.get('day'));
        }
        return '';
      case 'weekly':
        if (datum.get('week_end')) {
          return `${prettifyDate(datum.get('week_end'))}`;
        }
        return '';
      case 'monthly':
        if (datum.get('month') && datum.get('year')) {
          return `${datum.get('month').substring(0, 3)} '${String(datum.get('year')).substring(2, 4)}`; // False flow error on 'datum.year.toString()' so using the less stict String constructor instead
        }
        return '';
      default:
        return '';
    }
  }

  /**
   * Converts raw data to chart plottable data
   * @param {string} typ chart type i.e. 'bills' | 'revenue'
   * @param {string} interval interval type i.e. 'daily' | 'weekly' | 'monthly'
   * @param {List<DashboardStatModel>} data the stats for this interval
   * @returns {Array<object>} Array of data object for chart
   */
  makeChartData(
    typ: string,
    interval: IntervalString,
    data: List<DashboardStatModel>,
  ): Array<PlottableData> {
    return data.map((datum) => {
      switch (typ) {
        case 'bills':
          return {
            date: this.getPlottableDate(datum, interval),
            bills: datum.get('bills', 0),
            focBills: datum.get('foc_bills', 0),
            patientsRegistered: datum.get('patients_registered', 0),
          };
        case 'revenue':
          return {
            date: this.getPlottableDate(datum, interval),
            revenueTotal: datum.get('revenue_total', 0),
            revenueCash: datum.get('revenue_cash', 0),
          };
        default:
          return {};
      }
    }).toArray();
  }

  /**
    * Renders the component.
    * @return {string} - HTML markup for the component
    */
  render() {
    const { stats, isFetching, interval } = this.props;

    return (
      <Container>
        <h1 className="u-margin--standard">{capitalize(interval)} Stats</h1>
        <div className="o-card">
          <ChartHeader className="o-card__header">
            <h3>{translate('bills')}</h3>
            {
              isFetching && <Loading
                type="spin"
                size={32}
              />
            }
          </ChartHeader>
          {
            stats && <Chart
              data={this.makeChartData('bills', interval, stats)}
              xAxisLabel="Date"
              yAxisLabel="Quantity"
              xAxisKey="date"
              yAxisKeys={this.billChartKeys}
              yAxisNames={this.billChartKeys.map(e => translate(snakeCase(e)))}
              context="dashboard"
            />
          }
        </div>
        <div className="o-card">
          <ChartHeader className=".o-card__header">
            <h3>{translate('revenue')}</h3>
            {
              isFetching && <Loading
                type="spin"
                size={32}
              />
            }
          </ChartHeader>
          {
            stats && <Chart
              data={this.makeChartData('revenue', interval, stats)}
              xAxisLabel="Date"
              yAxisLabel="Quantity"
              xAxisKey="date"
              yAxisKeys={this.revenueChartkeys}
              yAxisNames={this.revenueChartkeys.map(e => translate(snakeCase(e)))}
              context="dashboard"
            />
          }
        </div>
      </Container>
    );
  }
}

export default StatsInterval;
