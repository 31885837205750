import React from 'react';
import { List, Map, is, Seq, Set } from 'immutable';
import Moment, { Moment as IMoment } from 'moment';

import { sumAndFormatPrice, convertNumberToPrice, sum, validateAndTrimString } from './../../utils/utils';
import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import { downloadCSV, convertToCSV } from './../../utils/export';
import { prettifyTime, validateTimeFilter, prettifyDate } from './../../utils/time';
import ContentTransition from './../contentTransition';
import ConsultReportsTable from './consultReportsTable';
import ReportsDateTimeFilter from './reportsDateTimeFilter';
import { printConsultReports } from './../../utils/print';
import { fetchPatientsByID, getModelsForBills } from './../../utils/api';
import { sidebarWidth } from './../../utils/css';
import Radio from './../inputs/radio';
import Input from './../inputs/input';
import Checkbox from './../inputs/checkbox';
import SaveButton from './../buttons/saveButton';
import { UNICODE } from './../../constants';
import { calculatePaymentsTotal, calculateClaimsTotal, calculateReceivablesTotal } from './../../utils/billing';
import { createPermission, hasPermission } from './../../utils/permissions';
import ToggleCard from './../layout/toggleCard';
import FormError from './../formError';

import type { Config, User, MapValue, Model, Row, CustomColumn } from './../../types';
import type BillModel from './../../models/billModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DrugModel from './../../models/drugModel';
import type EncounterModel from './../../models/encounterModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PractitionerModel from './../../models/practitionerModel';
import type PatientModel from './../../models/patientModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import ProcedureTypeModel from './../../models/procedureTypeModel';
import ProviderModel from './../../models/providerModel';

const COLUMNS = List([
  { value: 'number', label: '#' },
  { value: 'encounterDate', label: translate('encounter_date') },
  { value: 'scheduledTime', label: translate('start_time') },
  { value: 'billCoveragePayor', label: translate('panel') },
  { value: 'patientName', label: translate('patient_name') },
  { value: 'patientIc', label: translate('ic_number') },
  { value: 'patientPhoneNumber', label: translate('phone_number') },
  { value: 'patientAddress', label: translate('address') },
  { value: 'claimsTotal', label: translate('claimed_amount'), renderMethod: 'PRICE' },
  { value: 'receivablesTotal', label: translate('outstanding'), renderMethod: 'PRICE' },
  { value: 'encounterDoctor', label: translate('doctor') },
  { value: 'billDrugs', label: translate('medication') }, // Drugs that were part of bill
  { value: 'salesItems', label: translate('sales_items') },
  { value: 'labTests', label: translate('lab_tests') },
  { value: 'stages', label: translate('stages') },
  { value: 'encounterFlowName', label: translate('visit_type') },
  { value: 'billTotal', label: translate('fees'), renderMethod: 'PRICE' }, // Total of bill
]);
// Create an empty row as a template for each row in the table/print out.
const EMPTY_ROW = {};
COLUMNS.forEach((c) => {
  EMPTY_ROW[c.value] = UNICODE.MINUS;
});

type Props = {
  bills: List<BillModel>,
  config: Config,
  coveragePayors: List<CoveragePayorModel>,
  drugs: List<DrugModel>,
  encounters: List<EncounterModel>,
  filter: Map<string, IMoment>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  salesItems: Map<string, SalesItemModel>,
  practitioners: List<PractitionerModel>,
  user: User,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  filterTimeStart: IMoment,
  filterTimeEnd: IMoment,
  paymentTypes: List<PaymentTypeModel>,
  procedureTypes: Map<String, ProcedureTypeModel>,
  providers: List<ProviderModel>,
};

type State = {
  title: string,
  isLandscape: boolean,
  groupByCoveragePayors: boolean,
  appendTotalsToExport: boolean,
  columns: Array<string>,
  isPrinting: boolean,
  isFetchingData: boolean,
  errorMessage?: string,
  data: List<Row>,
  filteredData: List<Row>,
};

/**
 * A component that displays all bills for a clinic.
 * @class ConsultReports
 * @extends {Component}
 */
class ConsultReports extends React.Component<Props, State> {
  /**
   * Creates an instance of ConsultReports.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    const paymentTypesColumns = this.getPaymentTypesColumns().map(p => p.value).toArray();
    this.state = {
      title: 'Consult Reports',
      isLandscape: true,
      groupByCoveragePayors: false,
      appendTotalsToExport: false,
      columns: [...COLUMNS.map(c => c.value).toArray(), ...paymentTypesColumns],
      isPrinting: false,
      isFetchingData: true,
      errorMessage: undefined,
      data: List(),
      filteredData: List(),
    };
  }

  /**
   * Called fundtions after component fully rendered
   * @returns {void}
   */
  componentDidMount() {
    this.fetchData();
  }

  /**
   * Returns a list of columns based on paymentTypes
   * @returns {List<CustomColumn>}
   */
  getPaymentTypesColumns(): List<CustomColumn> {
    const paymentTypesColumns = this.props.paymentTypes.map(m =>
      ({ value: `paymentType_${m.get('name').trim().toLowerCase()}`, label: m.get('name'), renderMethod: 'PRICE' }));
    return paymentTypesColumns;
  }

  /**
   * Returns an object with patient data that applies to all relevant consults.
   * @param {PatientModel} patient The patient model
   * @returns {{}}
   */
  getPatientData(patient: PatientModel) {
    return {
      patientName: patient.get('patient_name', UNICODE.MINUS, false),
      patientAddress: patient.get('address', UNICODE.MINUS, false),
      patientPhoneNumber: patient.get('tel', UNICODE.MINUS, false),
      patientIc: patient.get('ic', UNICODE.MINUS, false),
    };
  }

  /**
   * Returns an object with encounter data that applies to all relevant consults.
   * @param {EncounterModel | void} encounter The encounter model
   * @returns {{}}
   */
  getEncounterData(encounter: EncounterModel | void) {
    const encounterSchedultedTimeout = encounter ? Moment(encounter.getArrivalTime()) : Moment();
    const stages = encounter && encounter.getActiveStages();
    return {
      encounterDate: encounter ? encounter.getArrivalTime() : UNICODE.MINUS,
      scheduledTime: encounter ? encounter.getArrivalTime() : UNICODE.MINUS,
      encounterDoctor: encounter ?
        encounter.getDoctorName(this.props.practitioners, true) : UNICODE.MINUS,
      encounterFlowName: encounter ? encounter.getEncounterType(this.props.salesItems.toList()) : UNICODE.MINUS,
      stages: stages && stages.size > 0 &&
        stages.map(s => s.name || UNICODE.MINUS).toArray(),
      scheduledTimeMoment: encounterSchedultedTimeout,
    };
  }

  /**
   * Returns an object with any bill related data for the consult.
   * @param {BillModel} bill A Bill model.
   * @param {List<any>} billRelatedModels A List of models matching the bill's ID.
   * @returns {{}}
   */
  getBillRelatedData(bill: BillModel, billRelatedModels: List<Model>) {
    const groupedModels = billRelatedModels.filter(m => (!m.get('_deleted') && !m.get('is_void')))
      .groupBy(m => m.get('type')) as Seq.Keyed<string, List<Model>>;
    const billProceduresType = groupedModels.get('bill_item', List()).filter(m => m.isProcedureType());
    const payments = groupedModels.get('payment', List());
    const claims = groupedModels.get('claim', List());
    const receivables = groupedModels.get('receivable', List());
    const prescriptions = groupedModels.get('bill_item', List()).filter(m => m.isPrescription());
    const billSalesItems = groupedModels.get('bill_item', List()).filter(m => m.isSalesItem());
    const data = {
      billCoveragePayor: bill.getCoveragePayorName(this.props.coveragePayors),
      claimsTotal: calculateClaimsTotal(claims),
      receivablesTotal: calculateReceivablesTotal(receivables),
      billDrugs: prescriptions.size > 0 ? prescriptions.map(d => d.getItemName(this.props.drugs)).join(', ') : UNICODE.MINUS,
      salesItems: billSalesItems.size > 0 ?
        billSalesItems.map(s => this.props.salesItems.get(s.get('sales_item_id'))?.get('name') || '').join(', ') : UNICODE.MINUS,
      labTests: billProceduresType.size > 0 ? billProceduresType.map((s) => {
        const procedure = this.props.procedureTypes.get(s.get('procedure_type_id'));
        const provider = procedure?.getProvider(this.props.providers);
        return provider && procedure ? `${provider.get('name') || UNICODE.MINUS} - ${procedure.get('name') || UNICODE.MINUS}` : UNICODE.MINUS;
      }).join(', ') : UNICODE.MINUS,
      billTotal: bill.get('total_amount'),
    };
    const paymentTypesData = this.getPaymentTypesColumns().toArray().reduce((acc, cur) => ({ ...acc, [cur.value]: calculatePaymentsTotal(payments.filter(p => p.get('method') === cur.label)) }), {});
    return { ...data, ...paymentTypesData };
  }

  /**
   * Fetches the necessary data for the tables/exports/printed reports.
   * @returns {void}
   */
  fetchData() {
    if (this.props.bills.size === 0) {
      this.setState({ data: List(), isFetchingData: false });
    } else {
      this.setState({ isFetchingData: true });
      Promise.all([
        getModelsForBills(this.props.bills.map(b => b.get('_id'))),
        fetchPatientsByID(this.props.bills.map(bill => bill.get('patient_id'))),
      ])
        .then(([billRelatedModels, patients]) => {
          const data = this.props.bills.map((bill) => {
            const patient = patients.find(p => p.get('_id') === bill.get('patient_id'));
            const encounter = this.props.encounters.find(e => e.get('_id') === bill.get('encounter_id'));
            return Object.assign(
              {},
              EMPTY_ROW,
              patient ? this.getPatientData(patient) : {},
              this.getEncounterData(encounter),
              this.getBillRelatedData(
                bill,
                billRelatedModels.filter(m => m.get('bill_id') === bill.get('_id')),
              ),
              {
                link: encounter && hasPermission(this.props.user, List([createPermission('finalised_bill', 'read')]))
                  ? `/patient/${encounter.get('patient_id')}/billing/${encounter.get('_id')}` : '',
              },
            );
          });
          const filteredByTimeData = data ? data
            .filter(d => d.scheduledTimeMoment.isBetween(
              this.props.filterTimeStart, this.props.filterTimeEnd, null, '[]',
            )) : List();
          this.setState({ isFetchingData: false, data: filteredByTimeData });
        }).catch(() => {
          this.setState({ isFetchingData: false, data: List() });
        });
    }
  }

  /**
   * Triggers a data fetch when the bills prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.bills, this.props.bills)) {
      this.fetchData();
    }
  }

  /**
   * Based on the props, determines if the export or print button should be displayed or not.
   * @returns {boolean} Whether or not the print button should display.
   */
  canExport(): boolean {
    return this.props.bills.size > 0
      && this.props.filter.get('filterDateEnd') !== undefined
      && this.props.filter.get('filterDateStart') !== undefined
      && this.props.filter.get('filterTimeStart') !== undefined
      && this.props.filter.get('filterTimeEnd') !== undefined;
  }

  /**
   * Validates title field
   * @returns {void}
   */
  isTitleValid() {
    return validateAndTrimString(this.state.title).length;
  }

  /**
   * Prints the consult reports.
   * @param {boolean} isGrouped If true, group the printed consults by panel.
   * @returns {Promise<void>} A promise upon print completion.
   */
  onPrintClicked(isGrouped: boolean = false): Promise<void> {
    if (!this.isTitleValid()) {
      return this.setState({ errorMessage: translate('fill_required_fields') });
    }
    const columns = this.getColumns(true).toArray();
    let data: List<MapValue>; // This sucks, but the data types differ.
    if (isGrouped) {
      data = List();
      List(this.state.filteredData).forEach((item, index) => {
        const payor = data.find(i => i.name === item.billCoveragePayor);
        const row = columns.map((column) => {
          if (column.value === 'number') {
            return (index + 1).toString();
          }
          if (column.value === 'encounterDate') {
            return prettifyDate(item[column.value]);
          }
          if (column.value === 'scheduledTime') {
            return prettifyTime(item[column.value]);
          }
          if (column.renderMethod === 'PRICE') {
            return convertNumberToPrice(item[column.value]);
          }
          return item[column.value];
        });
        const patientTotal = Object.keys(item).reduce((total, key) => (key.startsWith('paymentType_') ? total + Number(item[key]) : total), 0);
        const claimedTotal = item.claimsTotal;
        if (payor) {
          payor.items.push(row);
          payor.paidSubtotal += patientTotal;
          payor.claimedSubtotal += claimedTotal;
          payor.subtotal += (patientTotal + claimedTotal);
        } else {
          data = data.push({
            name: item.billCoveragePayor,
            items: [row],
            paidSubtotal: parseFloat(patientTotal.toFixed(2)),
            claimedSubtotal: parseFloat(claimedTotal.toFixed(2)),
            subtotal: parseFloat((patientTotal + claimedTotal).toFixed(2)),
          });
        }
      });
    } else {
      data = this.state.filteredData.map((item, index) => columns.map((column) => {
        if (column.value === 'number') {
          return (index + 1).toString();
        }
        if (column.value === 'encounterDate') {
          return prettifyDate(item[column.value]);
        }
        if (column.value === 'scheduledTime') {
          return prettifyTime(item[column.value]);
        }
        if (column.renderMethod === 'PRICE') {
          return convertNumberToPrice(item[column.value]);
        }
        const value = item[column.value] !== undefined ? item[column.value] : UNICODE.MINUS;
        return value;
      }));
    }

    const summaryData = this.getSummaryData();
    printConsultReports(
      isGrouped,
      columns,
      data.toArray(),
      summaryData,
      {
        fromDate: this.props.filter.get('filterDateStart'),
        toDate: this.props.filter.get('filterDateEnd'),
      },
      this.props.config,
      validateAndTrimString(this.state.title),
      this.state.isLandscape,
    );
    return Promise.resolve();
  }

  /**
   * Get the reports summary data
   * @returns {{}} A Data object.
   */
  getSummaryData() {
    const totalCashReceived = this.state.data.map(d => d.paymentType_cash || 0);
    const totalCreditReceived = this.state.data.map(d => (d.paymentType_card || d.paymentType_credit) || 0);
    const totalPanelPaymentsClaimable = this.state.data.map(d => d.claimsTotal);
    const availablePaymentTypes = Set(this.props.paymentTypes.map(m => m.get('name').trim().toLowerCase())); //To filter out duplicate payment types
    const totalReceivedAndClaimable = sum(this.state.data.map(d => (
      availablePaymentTypes.reduce((total, m) => total + (Number(d[`paymentType_${m}`]) || 0), 0)
    ))) + sum(totalPanelPaymentsClaimable);
    const customPayments = this.props.paymentTypes.filter((p) => {
      const name = p.get('name', '').trim().toLowerCase();
      return name !== 'cash' && name !== 'card' && name !== 'credit';
    })
      .map(paymentType => ({
        name: paymentType.get('name'),
        totalReceived: sumAndFormatPrice(
          this.state.data.map(d => d[`paymentType_${[paymentType.get('name', '').trim().toLowerCase()]}`] || 0),
        ),
      })).toArray();
    return ({
      customPayments,
      totalFees: sumAndFormatPrice(this.state.data.map(d => d.billTotal)),
      totalPatientOutstanding: sumAndFormatPrice(this.state.data.map(d => d.receivablesTotal)),
      totalReceivedAndClaimable: convertNumberToPrice(totalReceivedAndClaimable),
      totalCashReceived: sumAndFormatPrice(totalCashReceived),
      totalCreditReceived: sumAndFormatPrice(totalCreditReceived),
      totalPanelPaymentsClaimable: sumAndFormatPrice(totalPanelPaymentsClaimable),
    });
  }

  /**
   * Exports the consult reports.
   * @returns {void}
   */
  onExportClicked() {
    if (!this.isTitleValid()) {
      this.setState({ errorMessage: translate('fill_required_fields') });
    } else {
      const columns = this.getColumns(true).filter(c => c.value !== 'number');
      let data = this.state.filteredData.map(item => columns.map((column) => {
        if (column.value === 'encounterDate') {
          return prettifyDate(item[column.value]);
        }
        if (column.value === 'scheduledTime') {
          return prettifyTime(item[column.value]);
        }
        return item[column.value];
      }).toArray()).toArray();
      if (this.state.appendTotalsToExport) {
        const summaryData = this.getSummaryData();
        data = data.concat([
          [translate('total_fees'), summaryData.totalFees],
          [translate('total_patient_outstanding'), summaryData.totalPatientOutstanding],
          [translate('total_received_and_claimable'), summaryData.totalReceivedAndClaimable],
          [translate('total_cash_received'), summaryData.totalCashReceived],
          [translate('total_credit_received'), summaryData.totalCreditReceived],
          ...[...summaryData.customPayments.map(customPayment => [
            `Total ${customPayment.name} Received`, customPayment.totalReceived])],
          [translate('total_panel_payments_claimable'), summaryData.totalPanelPaymentsClaimable],
        ]);
      }
      downloadCSV(
        `${this.state.title}.csv`,
        convertToCSV(columns.map(c => c.label).toArray(), data),
      );
    }
  }

  /**
   * Merges the given object with the current filter for consult reports and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: {}) {
    const newFilterProps = this.props.filter.merge(newValues);
    if (validateTimeFilter(
      newFilterProps.get('filterDateStart'),
      newFilterProps.get('filterTimeStart'),
      newFilterProps.get('filterDateEnd'),
      newFilterProps.get('filterTimeEnd'),
    )) {
      this.props.onFilterUpdated(newFilterProps);
      this.setState({ errorMessage: undefined });
    } else {
      this.setState({ errorMessage: translate('start_time_end_time_invalid_error') });
    }
  }

  /**
   * Gets the columns with optional filtering
   * @param {boolean} filterForSelected If true it filters out any unselected columns
   * @returns {List<{ value: string, label: string }>}
   */
  getColumns(
    filterForSelected: boolean = false,
  ): List<{ value: string, label: string, renderMethod?: string }> {
    const withPaymentTypesColumns = this.props.paymentTypes.size
      ? COLUMNS.concat(this.getPaymentTypesColumns().toArray())
      : COLUMNS;
    return filterForSelected
      ? withPaymentTypesColumns.filter(column => this.state.columns.find(c => c === column.value))
      : withPaymentTypesColumns;
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <ContentTransition id="consult-report-filter">
        <section className="o-scrollable-container" style={{ height: '100vh', maxWidth: `calc(100vw - ${sidebarWidth})` }}>
          <h1 data-public className="o-title">{translate('consult_reports')}</h1>
          <ToggleCard
            title={translate('print_export_options')}
            dataPublic
            startClosed
            footer={
              <footer className="o-card__footer">
                <SaveButton
                  className="o-button--small u-margin-right--1ws"
                  label={translate('export')}
                  disabled={
                    this.state.isFetchingData ||
                    this.props.isFetching ||
                    this.props.currentDataViewsError ||
                    !this.canExport()}
                  onClick={() => this.onExportClicked()}
                  dataPublic
                />
                <SaveButton
                  isSaving={this.state.isPrinting}
                  className="o-button--small u-margin-right--1ws"
                  label={translate('print')}
                  disabled={this.state.isFetchingData ||
                    this.props.isFetching ||
                    this.props.currentDataViewsError ||
                    !this.canExport()}
                  onClick={() => this.onPrintClicked(this.state.groupByCoveragePayors)}
                  dataPublic
                />
              </footer>
            }
          >
            <section className="u-margin--standard">
              <Input
                id="title"
                label={translate('title')}
                value={this.state.title}
                required
                onValueChanged={title => this.setState({ title })}
              />
              <Radio
                id="group_by_panels"
                label={translate('group_by_panels_print_only')}
                value={this.state.groupByCoveragePayors.toString()}
                onValueChanged={value => this.setState({ groupByCoveragePayors: value === 'true' })}
                options={[{ value: 'true', label: translate('yes') }, { value: 'false', label: translate('no') }]}
              />
              <Radio
                id="append_totals_to_export"
                label={translate('append_totals_to_export')}
                value={this.state.appendTotalsToExport.toString()}
                onValueChanged={value => this.setState({ appendTotalsToExport: value === 'true' })}
                options={[{ value: 'true', label: translate('yes') }, { value: 'false', label: translate('no') }]}
              />
              <Radio
                id="print_layout"
                label={translate('print_layout')}
                value={this.state.isLandscape.toString()}
                onValueChanged={value => this.setState({ isLandscape: value === 'true' })}
                options={[{ value: 'false', label: translate('portrait') }, { value: 'true', label: translate('landscape') }]}
              />
            </section>
          </ToggleCard>
          <ToggleCard title={translate('filter_options')} dataPublic>
            <section className="u-margin--standard">
              {
                this.state.errorMessage &&
                <FormError>
                  {this.state.errorMessage}
                </FormError>
              }
              <ReportsDateTimeFilter
                filter={this.props.filter}
                onFilterUpdated={newValue => this.updateFilter(newValue)}
                headerTitle={translate('encounters_filter_by_date_time_description')}
              />
              <Checkbox
                id="columns"
                label={translate('columns')}
                labelClassName="o-label"
                options={this.getColumns().map(({ value, label }) => ({ value, label })).toArray()} // The map is a stupid thing to get flow to be quiet for now.
                value={this.state.columns}
                onValueChanged={toggledColumn => this.setState({
                  columns: this.state.columns.includes(toggledColumn)
                    ? this.state.columns.filter(c => c !== toggledColumn)
                    : this.state.columns.concat([toggledColumn]),
                })}
                multiColumn
                style={{ paddingBottom: '1px' }}
              />
            </section>
          </ToggleCard>
          <div className="o-card u-margin-bottom--4ws">
            <h2 data-public className="o-card__title">{translate('consults')}</h2>
            <ConsultReportsTable
              isFetching={this.state.isFetchingData || this.props.isFetching}
              currentDataViewsError={this.props.currentDataViewsError}
              columns={this.getColumns(true).filter(v => v.value !== 'number').toArray()}
              data={this.state.data.toArray()}
              onFilterAndSort={filteredData => this.setState({ filteredData: List(filteredData) })}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default ConsultReports;
