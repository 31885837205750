import { connect } from 'react-redux';
import { List } from 'immutable';

import translate from './../utils/i18n';
import CasenoteGrid from './../components/casenotes/casenoteGrid';
import { sortByNumber } from './../utils/comparators';

import type { State, Dispatch } from './../types';
import type CategoryModel from './../models/categoryModel';
import { saveFactory } from '../utils/redux';

type Props = {
  patientID: string,
  categoryID?: string,
  sidebarCategoryID: string, // Categories based from left sidebar (eg. All, Notes, Uncategories, Starred, Bin)
}

/**
 * Gets the category model for the given ID or returns undefined.
 * @param {List<CategoryModel>} categories All CategoryModels
 * @param {string} categoryID Category ID
 * @returns {CategoryModel | void}
 */
function getCategory(categories: List<CategoryModel>, categoryID?: string) {
  return categoryID !== undefined ?
    categories.find((c: CategoryModel) => c.get('_id') === categoryID) : categoryID;
}

/**
   * @param {Object} state Current app state.
   * @param {Props} ownProps Container props.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State, ownProps: Props) => {
  const { categoryID, patientID, sidebarCategoryID } = ownProps;
  const {
    categories, config, caseNoteFiles, isOnline, user,
  } = state;
  const category = getCategory(categories, categoryID);
  const title = category ? category.get('name') : translate('recent_casenotes');
  let casenotes;
  if (sidebarCategoryID === 'ALL') {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && c.isVisible() && c.get('category') === categoryID);
  } else if (sidebarCategoryID === 'BIN') {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && !c.isVisible() && c.get('category') === categoryID);
  } else if (sidebarCategoryID === 'STARRED') {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && c.isStarred() && c.isVisible() && c.get('category') === categoryID);
  } else {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && c.get('category') === categoryID && c.isVisible());
  }
  return {
    config,
    casenotes: casenotes
      .sort((a, b) => sortByNumber(a.get('order'), b.get('order')))
      .reverse(),
    // Get the ID of the category to save the notes to.
    categoryID: categoryID || config.getIn(['categories', 'notes_category'], '0_KLINIFY_NOTES'),
    rows: 1,
    title,
    patientID,
    isOnline,
    user,
    sidebarCategoryID,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const CasenoteGridContainer = connect(mapStateToProps, mapDispatchToProps)(CasenoteGrid);

export default CasenoteGridContainer;
