import React from 'react';
import type { List } from 'immutable';

import translate from './../../utils/i18n';
import Table from './../table/table';
import { sumAndFormatPrice } from './../../utils/utils';
import { renderDate, renderMultilineCell, renderWithLink, renderPriceWithLink } from './../../utils/tables';
import { sortByDate } from './../../utils/comparators';
import { UNICODE } from './../../constants';
import Header from './../header/header';

import type ClaimModel from './../../models/claimModel';
import type BillModel from './../../models/billModel';
import type EncounterModel from './../../models/encounterModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PatientStubModel from './../../models/patientStubModel';
import { debugPrint } from '../../utils/logging';

type Row = {
  date: string,
  name: string,
  icNumber: string,
  visitType: string,
  totalFees: string,
  owedByPanel: string,
  billNotes: string,
  link: string,
};

type Props = {
  claims: List<ClaimModel>,
  bills: List<BillModel>,
  encounters: List<EncounterModel>,
  patientStubs: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  selectedCoveragePayorId?: string,
};

/**
 * Component displaying all Claim Invoices.
 * @class AddClaimInvoiceTable
 * @extends {React.Component}
 */
class AddClaimInvoiceTable extends React.PureComponent<Props> {
  /**
   * Generates a table row from the Claim.
   * @param {ClaimModel} claim A claim model
   * @returns {Row}
   */
  getRow(claim: ClaimModel): Row {
    const patient = this.props.patientStubs.find(p => p.get('_id') === claim.get('patient_id'));
    const bill = this.props.bills.find(b => b.get('_id') === claim.get('bill_id'));
    const encounter = bill ?
      this.props.encounters.find(e => e.get('_id') === bill.get('encounter_id')) : undefined;
    return {
      date: claim.get('timestamp'),
      name: patient ? patient.get('patient_name') : translate('patient_not_found'),
      icNumber: patient ? patient.get('ic', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      visitType: encounter ? encounter.getEncounterType(this.props.salesItems) : translate('encounter_not_found'),
      totalFees: bill ? bill.get('total_amount') : undefined,
      owedByPanel: claim.get('amount_due'),
      billNotes: bill ? bill.get('notes', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      link: `/patient/${bill?.get('patient_id')}/billing/${bill?.get('encounter_id')}`,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering AddClaimInvoiceTable');
    let noDataText;
    if (!this.props.selectedCoveragePayorId) {
      noDataText = 'please_select_panel_to_view_claims';
    } else if (this.props.selectedCoveragePayorId && this.props.claims.size === 0) {
      noDataText = 'no_unclaimed_items_available';
    } else {
      noDataText = 'no_items';
    }
    return (
      <div>
        <Header className="o-card__header">
          <h1 className="o-card__title" data-public>{translate('claims')}</h1>
          <span className="o-card__header__right-aligned-text">
            {`${translate('total_to_be_claimed')}`}: <span className="u-strong">{`${sumAndFormatPrice(this.props.claims.map(c => c.get('amount_due')))}`}</span>
          </span>
        </Header>
        <Table
          columns={[
            { accessor: 'date', Header: translate('date'), Cell: renderDate, sortMethod: sortByDate },
            { accessor: 'name', Header: translate('name'), Cell: renderWithLink },
            { accessor: 'icNumber', Header: translate('ic_number'), Cell: renderWithLink },
            { accessor: 'visitType', Header: translate('visit_type'), Cell: renderWithLink },
            { accessor: 'totalFees', Header: translate('total_fees'), Cell: renderPriceWithLink },
            { accessor: 'owedByPanel', Header: translate('owed_by_panel'), Cell: renderPriceWithLink },
            { accessor: 'billNotes', Header: translate('bill_notes'), Cell: renderMultilineCell },
          ]}
          data={this.props.claims.map(c => this.getRow(c)).toArray()}
          noDataText={translate(noDataText)}
          showPagination
          defaultSorted={[{ id: 'icNumber', desc: false }]}
        />
      </div>
    );
  }
}

export default AddClaimInvoiceTable;
