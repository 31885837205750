import { connect } from 'react-redux';

import Specimens from './../components/specimens/specimens';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State } from './../types';


/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ user, specimens }: State) => ({
  user,
  specimens: specimens.filter(p => p.isActive()),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const SpecimensContainer = connect(mapStateToProps, mapDispatchToProps)(Specimens);

export default SpecimensContainer;
