/* eslint react/jsx-filename-extension: 0 */
import React from 'react';
import { List } from 'immutable';
import PizZip from 'pizzip';
import JSZipUtils from 'jszip-utils';
import Docxtemplater from 'docxtemplater';
import Moment from 'moment';
import ImageModule from 'docxtemplater-image-module-free';

import TextArea from './../components/inputs/textarea';
import Multiselect from './../components/inputs/multiSelect';
import TimePicker from './../components/inputs/timepicker';
import DatePicker from './../components/inputs/statefulDatepicker';
import Select from './../components/inputs/select';
import DateTimePicker from './../components/inputs/dateTimePicker';
import {
  getBaseApiUrl, convertNumberToPrice, isFloatEqual, sizeOfImage, filterNonPrintableCharsExceptNewline,
} from './utils';
import { handleForbiddenResponse, handleUnauthorisedApiResponse } from './response';
import fetch from './fetch';
import { getDateFormat, getTimeFormat, getDateTimeFormat } from './time';
import { calculateBillItemTotal, calculatePatientTotal, calculatePaymentsTotal } from './billing';
import AllergyModel from '../models/allergyModel';
import { UNICODE } from './../constants';
import { downloadFile } from './../utils/export';
import translate from './../utils/i18n';

import type { Config, MapValue } from './../types';
import type MedicalCertificateModel from './../models/medicalCertificateModel';
import type TimeChitModel from './../models/timeChitModel';
import type BillModel from './../models/billModel';
import type BillItemModel from './../models/billItemModel';
import type DrugModel from './../models/drugModel';
import type PatientModel from './../models/patientModel';
import type PractitionerModel from './../models/practitionerModel';
import type PrescriptionModel from './../models/prescriptionModel';
import EncounterModel, { StageInfo } from './../models/encounterModel';
import type CoveragePayorModel from './../models/coveragePayorModel';
import type ConditionModel from './../models/conditionModel';
import type PaymentModel from './../models/paymentModel';
import type SalesItemModel from './../models/salesItemModel';
import { debugPrint } from './logging';
import ProcedureTypeModel from '../models/procedureTypeModel';
import { getCompletedStageLocations, getConcatenatedValueFromStage, getDoctorNames, getNextEventTime, getStageEventsAt } from './encounters';
import type EncounterStageModel from '../models/encounterStageModel';
import DiscountChargeModel from '../models/discountChargeModel';

const InspectModule = require('docxtemplater/js/inspect-module');

type DocumentObject = { [key: string]: MapValue }; // The Document object returned by Docxtemplater.
type ZipContent = any;

export type Fields = {};

/* eslint-disable camelcase */
type EncounterContextData = {
  patient?: PatientModel,
  allergies?: List<AllergyModel>,
  encounter?: EncounterModel,
  bill?: BillModel,
  billItems?: List<BillItemModel>,
  billItem?: BillItemModel | void,
  drugs?: List<DrugModel>,
  medicalCertificates?: List<MedicalCertificateModel>,
  timeChits?: List<TimeChitModel>,
  coveragePayors?: List<CoveragePayorModel>,
  conditions?: List<ConditionModel>,
  payments?: List<PaymentModel>,
  payment?: PaymentModel,
  practitioners?: List<PractitionerModel>,
  prescriptions?: List<PrescriptionModel>,
  prescription?: PrescriptionModel,
  salesItems?: List<SalesItemModel>,
  config?: Config,
  practitioner?: PractitionerModel,
  practitioner_qualification?: {
    issuer?: string,
    type?: string,
    year_received?: string,
  },
  procedureTypes?: List<ProcedureTypeModel>,
  stageInfo?: StageInfo,
  encounterStageMap: Map<string, EncounterStageModel>,
  discountsCharges?: List<DiscountChargeModel>,
  usedDiscountsCharges?: List<DiscountChargeModel>,
  discountCharge?: DiscountChargeModel,
};
/* eslint-enable camelcase */

export type DataObject = EncounterContextData; // Represents any ContextData type.

const DATE_FIELDS = ['date', 'patient_dob', 'mc_start_date', 'mc_end_date'];
const TIME_FIELDS = ['time'];
const DATE_TIME_FIELDS = [
  'encounter_arrival', 'encounter_start', 'encounter_completion', 'bill_date', 'bill_last_edited',
  'tc_start_time', 'tc_end_time',
];
const LOOP_FIELDS = ['prescriptions', 'bill_items', 'doctor_qualifications'];
const EMPTY_VALID_FIELDS =
  ['patient_allergy_summary', 'encounter_diagnoses', 'encounter_symptoms']; // fields that can have empty string as value eg: diagnoses, symptoms, allergy etc
// Fields that can have newlines/enter key which are ignored by docxtemplater library,
// hence using them similar to loop fields
const TEXTAREA_FIELDS = ['header', 'clinic_address', 'patient_address', 'patient_notes', 'encounter_notes',
  'bill_notes', 'payment_notes', 'mc_notes', 'tc_notes'];

/**
 * Fetches a file as binary (i.e. a zip file)
 * @param {string} assetId The asset ID
 * @returns {Promise<ZipContent>}
 */
function getAsset(assetId: string): Promise<ZipContent> {
  const url = `${getBaseApiUrl()}/asset/${assetId}`;
  return new Promise(
    (resolve, reject) => JSZipUtils.getBinaryContent(
      url,
      (error, content) => {
        if (error) {
          const isUnauthorized = error.toString().split(' ').includes('401');
          if (isUnauthorized) {
            handleUnauthorisedApiResponse(401);
          }
          return reject(error);
        }
        return resolve(content);
      },
    ),
  );
}

/**
 * Fetches a file as binary (i.e. a zip file)
 * @param {ArrayBuffer} img The imageBuffer
 * @param {string} tagName placeholder tag text
 * @returns {Array} An array containing width and height value
 */
function getSize(img: ArrayBuffer, tagName: string) {
  const tagArray = tagName.split('|');
  if (tagArray.length > 2) {
    return tagArray.slice(1, 3).map(e => parseInt(e, 10));
  } else if (tagArray.length === 2) {
    if (tagArray[1][0].toLowerCase() === 'x') {
      return sizeOfImage(img)
        .then(dimensions => dimensions.map(e => Math.floor(e * parseFloat(tagArray[1].slice(1)))));
    }
    return [tagArray[1], tagArray[1]];
  }
  return sizeOfImage(img);
}

// Options that will be passed to ImageModule instance
const imageModuleOpts = {
  centered: false,
  fileType: 'docx',
  getImage: tagValue => getAsset(tagValue),
  getSize: (img, tagValue, tagName) => getSize(img, tagName),
};
const imageModule = new ImageModule(imageModuleOpts);

/**
 * Gets the DocumentTemplate object using the Docxtemplater library.
 * @param {string} assetId The asset to get.
 * @returns {Promise<DocumentObject>}
 */
export function getDocumentTemplate(assetId: string): Promise<DocumentObject> {
  return getAsset(assetId)
    .then((content) => {
      const zip = new PizZip(content);
      const doc = new Docxtemplater()
        .attachModule(imageModule)
        .loadZip(zip);
      doc.setOptions({ linebreaks: true });
      return doc;
    });
}

/**
 * Takes the data to be set in docxtemplater for print/download and formats textarea values into loop fields.
 * @param {DocumentObject} data The data to apply to the placeholders.
 * @returns {DocumentObject}
 */
function processTextAreaFields(data: DocumentObject): DocumentObject {
  const newData = data;
  TEXTAREA_FIELDS.map((textField) => {
    if (newData[textField] !== undefined && newData[textField] !== null &&
      newData[textField] !== '' && newData[textField] !== UNICODE.EMDASH &&
      newData[textField].length) {
      newData[textField] = filterNonPrintableCharsExceptNewline(newData[textField]);
    }
    return textField;
  });
  return newData;
}

/**
 * Takes a DocumentObject and sets the given data on it.
 * @param {DocumentObject} doc The DocumentObject
 * @param {{}} data The data to apply to the placeholders.
 * @returns {DocumentObject}
 */
export function setDocumentTemplateData(doc: DocumentObject, data: {}): DocumentObject {
  return new Promise((resolve) => {
    doc.compile();
    doc.resolveData(processTextAreaFields(data)).then(() => {
      try {
        doc.render();
      } catch (error) {
        throw error;
      }
      resolve(doc);
    });
  });
}

/**
 * Get Document Blob
 * @param {DocumentObject} doc The document object
 * @returns {Blob}
 */
export function getDocumentBlob(doc: DocumentObject) {
  const blob = doc.getZip().generate({
    type: 'blob',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });
  return blob;
}

/**
 * Downloads a Document
 * @param {DocumentObject} doc The document object
 * @param {string} fileName The name to give the file when downloading it.
 * @returns {void}
 */
export function downloadDocument(doc: DocumentObject, fileName: string) {
  downloadFile(fileName, getDocumentBlob(doc), true);
}

/**
 * Convert .docx file into pdf
 * @param {File} file Document Template
 * @returns {any}
 */
export function convertDocumentAndPrint(
  file: File,
) {
  const formData = new FormData();
  formData.append('file', file);
  return fetch(new Request(`${getBaseApiUrl()}/convert_doc_template`), {
    method: 'POST',
    body: formData,
    credentials: 'same-origin',
  })
    .then((response) => {
      handleUnauthorisedApiResponse(response.status);
      if (response.ok) {
        return response.json();
      }
      handleForbiddenResponse(response);
      throw new Error(response.statusText);
    })
    .catch((error) => {
      debugPrint(error, 'error');
    });
}

/**
 * Takes a document object and returns the available fields in the template.
 * @param {DocumentObject} documentObject The document object.
 * @returns {Fields}
 */
export function extractFields(documentObject: DocumentObject): Fields {
  const iModule = InspectModule();
  documentObject.attachModule(iModule);
  documentObject.compile(); // doc.compile can also be used to avoid having runtime errors
  const tags = iModule.getAllTags();
  return tags;
}


/**
 * Takes a Fields object as returned from a Word document, and a DataObject, and fills Fields with
 * data derived from DataObject.
 * @param {Fields} fields The empty Fields object.
 * @param {DataObject} dataObject The DataObject, containing all the available models for the
 * current context.
 * @returns {Fields} The filled Fields object.
 */
export function fillFormFields(fields: Fields, dataObject: DataObject): Fields {
  let filledFields = {};
  Object.keys(fields).forEach((key) => {
    filledFields =
      Object.assign({}, filledFields, { [key]: getPlaceholderValue(key, dataObject, fields[key]) }); // eslint-disable-line no-use-before-define
  });
  return filledFields;
}

/**
 * Gets the particular field of a MC from the given dataObject.
 * @param {DataObject} dataObject The data object.
 * @param {string} field The field to get.
 * @param {string} placeholder The value to return if the MC value doesn't exist.
 * @returns {string}
 */
function getMCValue(
  dataObject: DataObject,
  field: string,
  placeholder: string | void,
): string | void {
  if (dataObject.medicalCertificates && dataObject.medicalCertificates.size > 0) {
    const mc = dataObject.medicalCertificates.get(0);
    if (mc) {
      return mc.get(field, placeholder, false);
    }
  }
  return placeholder;
}

/**
 * Gets the particular field of a Time Chit from the given dataObject.
 * @param {DataObject} dataObject The data object.
 * @param {string} field The field to get.
 * @param {string} placeholder The value to return if the TimeChit value doesn't exist.
 * @returns {string}
 */
function getTimeChitValue(
  dataObject: DataObject,
  field: string,
  placeholder: string | void,
): string | void {
  if (dataObject.timeChits && dataObject.timeChits.size > 0) {
    const tc = dataObject.timeChits.get(0);
    if (tc) {
      return tc.get(field, placeholder, false);
    }
  }
  return placeholder;
}

/**
 * Gets the amount owed to clinic by the patient(balance amount).
 * @param {DataObject} dataObject The data object.
 * @returns {string}
 */
function getBillBalance(
  dataObject: DataObject,
): string {
  const zero = convertNumberToPrice(0);
  if (dataObject.bill && dataObject.bill.attributes &&
    dataObject.billItems && dataObject.payments) {
    const usedDiscountsCharges = dataObject.usedDiscountsCharges ||
      List(dataObject.bill.attributes.applied_discounts_charges?.map(e => dataObject.discountsCharges?.find(d => d.get('_id') === e)).filter(e => !!e) || []);
    const patientTotal = calculatePatientTotal(dataObject.bill.attributes, dataObject.billItems, usedDiscountsCharges);
    const paymentsTotal = calculatePaymentsTotal(dataObject.payments !== undefined ?
      dataObject.payments : List());
    const amountOwed = patientTotal - paymentsTotal;
    // adding a tolerance of 0.01 occuring due to 3 decimals we have.
    if (isFloatEqual(patientTotal, paymentsTotal, 0.01)) {
      return zero;
    }
    return convertNumberToPrice(amountOwed);
  }
  return zero;
}

/**
 * Takes a  DataObject, and gets the address of the panel attached to bill using the same
 * @param {DataObject} dataObject The DataObject, containing all the available models for the
 * current context.
 * @returns {string} panel address.
 */
function getPanelAddressForBill(dataObject: DataObject): string {
  const coveragePayorId = dataObject.bill && dataObject.bill.has('coverage_payor_id') ?
    dataObject.bill.get('coverage_payor_id') : '';
  if (coveragePayorId && dataObject.coveragePayors) {
    const payor = dataObject.coveragePayors.find(model =>
      model.get('_id') === coveragePayorId);
    if (payor) {
      return payor.get('address', UNICODE.EMDASH);
    }
  }
  return UNICODE.EMDASH;
}

/**
 * Takes a String value and modifies it to a single line based on provided modifier
 * @param {string} data The text to transform.
 * @param {string} modifier The modifier to use.
 * @returns {string}.
 */
function resolveSingleLineModifier(data: string, modifier: string) {
  if (modifier === 'single_line') {
    return data.trim().split(/\r?\n/).join(', ');
  }
  return data.trim();
}

/**
 * Returns the value for a placeholder of the data for it exists. Otherwise returns a dash.
 * @param {string} placeholder The placeholder
 * @param {DataObject} dataObject An object containing assorted models (e.g.  EncounterContextData)
 * @param {Fields} children The value of the Fields items prior to placeholders being inserted. For
 * most placeholder this is just a {} and can be replaced by a string/number value. For loop
 * placeholders this will be an object containing the sub-fields of the loop.
 * @returns {string}
 */
function getPlaceholderValue(
  placeholder: string,
  dataObject: DataObject,
  children: { [key: string]: {} },
): string | number | Fields | Array<Fields> | void { // String should be returned unless the value is going to be passed into something like a datepicker.
  const NO_VALUE = UNICODE.EMDASH;
  const placeholderName = placeholder.split('|')[0];
  const modifier = placeholder.split('|')[1];
  switch (placeholderName) {
    // GENERIC
    case 'date':
      // TODO: Validation of modifier (things get borked if a weird format is given).
      return Moment().format(modifier || getDateFormat());
    case 'time':
      // TODO: Validation of modifier (things get borked if a weird format is given).
      return Moment().format(modifier || getTimeFormat());
    // CONFIG
    // adding empty string check to config values, as we use emdash for empty.
    case 'header':
      return dataObject.config && dataObject.config.getIn(['print', 'header'], NO_VALUE) ?
        dataObject.config.getIn(['print', 'header'], NO_VALUE) : NO_VALUE;
    case 'signature':
      return dataObject.config && dataObject.config.getIn(['print', 'signatureLabel'], NO_VALUE) ?
        dataObject.config.getIn(['print', 'signatureLabel'], NO_VALUE) : NO_VALUE;
    case 'clinic_name':
      return dataObject.config && dataObject.config.getIn(['clinic', 'name'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'name'], NO_VALUE) : NO_VALUE;
    case 'clinic_address':
      return dataObject.config && dataObject.config.getIn(['clinic', 'address'], NO_VALUE) ?
        resolveSingleLineModifier(dataObject.config.getIn(['clinic', 'address'], NO_VALUE), modifier) : NO_VALUE;
    case 'clinic_email':
      return dataObject.config && dataObject.config.getIn(['clinic', 'email'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'email'], NO_VALUE) : NO_VALUE;
    case 'clinic_phone':
      return dataObject.config && dataObject.config.getIn(['clinic', 'phone'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'phone'], NO_VALUE) : NO_VALUE;
    case 'clinic_fax':
      return dataObject.config && dataObject.config.getIn(['clinic', 'fax'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'fax'], NO_VALUE) : NO_VALUE;
    case 'clinic_website':
      return dataObject.config && dataObject.config.getIn(['clinic', 'website'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'website'], NO_VALUE) : NO_VALUE;
    case 'clinic_misc':
      return dataObject.config && dataObject.config.getIn(['clinic', 'misc'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'misc'], NO_VALUE) : NO_VALUE;
    case 'clinic_logo':
      return dataObject.config && dataObject.config.getIn(['clinic', 'logo', 'assetID'], NO_VALUE) ?
        dataObject.config.getIn(['clinic', 'logo', 'assetID'], NO_VALUE) : NO_VALUE;
    // PRACTITIONER
    case 'doctor_name':
      return dataObject.practitioner ? dataObject.practitioner.get('name', NO_VALUE, false) : NO_VALUE;
    case 'doctor_internal_clinic_id':
      return dataObject.practitioner ? dataObject.practitioner.get('internal_clinic_id', NO_VALUE, false) : NO_VALUE;
    case 'doctor_gender':
      return dataObject.practitioner ? dataObject.practitioner.get('gender', NO_VALUE, false) : NO_VALUE;
    case 'doctor_dob':
      return dataObject.practitioner ? dataObject.practitioner.getDOB(modifier) : undefined;
    case 'doctor_ethnicity':
      return dataObject.practitioner ? dataObject.practitioner.getEthnicity() : NO_VALUE;
    case 'doctor_tel':
      return dataObject.practitioner ? dataObject.practitioner.getTelephone() : NO_VALUE;
    case 'doctor_email':
      return dataObject.practitioner ? dataObject.practitioner.getEmail() : NO_VALUE;
    case 'doctor_ic_number':
      return dataObject.practitioner ? dataObject.practitioner.get('ic', NO_VALUE, false) : NO_VALUE;
    case 'doctor_registration_number':
      return dataObject.practitioner ? dataObject.practitioner.getMMC() : NO_VALUE;
    case 'doctor_qualifications':
      return (dataObject.practitioner ? dataObject.practitioner.get('qualifications') : [])
        .map(qualification =>
          fillFormFields(children, Object.assign({}, dataObject, {
            practitioner_qualification: qualification,
          })));
    case 'doctor_qualification_issuer':
      return dataObject.practitioner_qualification ? dataObject.practitioner_qualification.issuer
        : NO_VALUE;
    case 'doctor_qualification_type':
      return dataObject.practitioner_qualification ? dataObject.practitioner_qualification.type
        : NO_VALUE;
    case 'doctor_qualification_year_received':
      return dataObject.practitioner_qualification
        ? dataObject.practitioner_qualification.year_received : NO_VALUE;
    case 'doctor_speciality':
      return dataObject.practitioner ? dataObject.practitioner.getSpeciality() : NO_VALUE;
    // PATIENT
    case 'patient_id':
      return dataObject.patient ? dataObject.patient.get('_id', NO_VALUE, false) : NO_VALUE;
    case 'patient_name':
      return dataObject.patient ? dataObject.patient.get('patient_name', NO_VALUE, false) : NO_VALUE;
    case 'patient_dob':
      return dataObject.patient ? dataObject.patient.getDOB(modifier) : undefined;
    case 'patient_age':
      return dataObject.patient ? dataObject.patient.getAge() : NO_VALUE;
    case 'patient_ic':
      return dataObject.patient ? dataObject.patient.get('ic', NO_VALUE, false) : NO_VALUE;
    case 'patient_sex':
      return dataObject.patient ? dataObject.patient.get('sex', NO_VALUE, false) : NO_VALUE;
    case 'patient_case_id':
      return dataObject.patient ? dataObject.patient.get('case_id', NO_VALUE, false) : NO_VALUE;
    case 'patient_citizenship':
      return dataObject.patient ? dataObject.patient.get('nationality', NO_VALUE, false) : NO_VALUE;
    case 'patient_ethnicity':
      return dataObject.patient ? dataObject.patient.getEthnicity() : NO_VALUE;
    case 'patient_tel':
      return dataObject.patient ? dataObject.patient.get('tel', NO_VALUE, false) : NO_VALUE;
    case 'patient_email':
      return dataObject.patient ? dataObject.patient.get('email', NO_VALUE, false) : NO_VALUE;
    case 'patient_address':
      return dataObject.patient ? dataObject.patient.get('address', NO_VALUE, false) : NO_VALUE;
    case 'patient_postal_code':
      return dataObject.patient ? dataObject.patient.get('postal_code', NO_VALUE, false) : NO_VALUE;
    case 'patient_occ':
      return dataObject.patient ? dataObject.patient.get('occ', NO_VALUE, false) : NO_VALUE;
    case 'patient_current_employer':
      return dataObject.patient ? dataObject.patient.get('current_employer', NO_VALUE, false) : NO_VALUE;
    case 'patient_notes':
      return dataObject.patient ? dataObject.patient.get('notes', NO_VALUE, false) : NO_VALUE;
    case 'patient_allergy_summary':
      return dataObject.allergies ?
        dataObject.allergies
          .map(a => a.get('name'))
          .join(', ') :
        NO_VALUE;
    case 'patient_panel_name':
      return dataObject.patient && dataObject.coveragePayors ?
        dataObject.patient.getCoveragePayorName(dataObject.coveragePayors) : NO_VALUE;
    case 'patient_panel_policy_holder':
      return dataObject.patient ? dataObject.patient.getCoverageField('policyHolder') : NO_VALUE;
    case 'patient_panel_employee':
      return dataObject.patient ? dataObject.patient.getCoverageField('subscriber') : NO_VALUE;
    case 'patient_panel_relation':
      return dataObject.patient ? dataObject.patient.getCoverageField('relationship') : NO_VALUE;
    // Start of Encounter placeholders
    case 'encounter_doctor':
      return dataObject.encounter && dataObject.practitioners ?
        dataObject.encounter.getDoctorName(dataObject.practitioners, true) || NO_VALUE :
        NO_VALUE;
    case 'encounter_arrival':
      return dataObject.encounter && dataObject.encounter.containsEvent('arrived') ?
        Moment(dataObject.encounter.getArrivalTime()).format(modifier || getDateTimeFormat()) :
        NO_VALUE;
    case 'encounter_start':
      return dataObject.encounter && dataObject.encounter.containsEvent('started') ?
        Moment(dataObject.encounter.getStartTime()).format(modifier || getDateTimeFormat()) :
        NO_VALUE;
    case 'encounter_completion':
      return dataObject.encounter && dataObject.encounter.containsEvent('finished_consult') ?
        Moment(dataObject.encounter.getConsultFinishTime())
          .format(modifier || getDateTimeFormat()) :
        NO_VALUE;
    case 'encounter_consult_type':
    case 'flow_name':
      return dataObject.encounter ?
        dataObject.encounter.getEncounterType(dataObject.salesItems || List()) :
        NO_VALUE;
    case 'encounter_location':
      return dataObject.encounter
        ? getCompletedStageLocations(dataObject.encounter)
        : NO_VALUE;
    case 'encounter_notes':
      return dataObject?.encounter?.getNotes(dataObject.practitioners, dataObject.encounterStageMap, true) ?? NO_VALUE;
    case 'stages':
      return dataObject?.encounter?.getStages()
          .map(stageInfo => fillFormFields(children, Object.assign({}, dataObject, { stageInfo })))
          .toArray()
        ?? NO_VALUE;
    case 'stage_name':
      return dataObject?.stageInfo?.name ?? NO_VALUE;
    case 'stage_doctor':
      return dataObject.practitioners && dataObject.encounter
        ? getDoctorNames(dataObject.practitioners, List([dataObject.stageInfo]), dataObject.encounter.get('doctor'))
        : NO_VALUE;
    case 'stage_waiting_start':
      return getStageEventsAt('arrived', dataObject?.stageInfo) || NO_VALUE;
    case 'stage_waiting_moved_out':
      return getStageEventsAt('started', dataObject?.stageInfo)
        ? getStageEventsAt('started', dataObject?.stageInfo)
        : (dataObject.encounter && dataObject.stageInfo)
          ? getNextEventTime(dataObject.encounter, 'arrived', dataObject.stageInfo)
          : NO_VALUE;
    case 'stage_waiting_completion':
    case 'stage_in_progress_start':
      return getStageEventsAt('started', dataObject?.stageInfo) || NO_VALUE;
    case 'stage_in_progress_moved_out':
      return dataObject.encounter && dataObject.stageInfo
        ? getNextEventTime(dataObject.encounter, 'started', dataObject.stageInfo)
        : NO_VALUE;
    case 'stage_in_progress_completion':
      return getStageEventsAt('completed', dataObject?.stageInfo) || NO_VALUE;
    case 'stage_cancelled':
      return getStageEventsAt('cancelled', dataObject?.stageInfo) || NO_VALUE;
    case 'stage_location':
      return dataObject?.stageInfo
        ? getConcatenatedValueFromStage(dataObject.stageInfo, 'location').join(', ')
        : NO_VALUE;
    case 'stage_notes':
      return dataObject?.stageInfo?.notes ?? NO_VALUE;
    // End of encounter placeholders
    case 'encounter_diagnoses':
      return dataObject.conditions ?
        dataObject.conditions
          .filter(c => c.isDiagnosis())
          .map(a => a.get('name'))
          .join(', ') :
        NO_VALUE;
    case 'encounter_symptoms':
      return dataObject.conditions ?
        dataObject.conditions
          .filter(c => c.isSymptom())
          .map(a => a.get('name'))
          .join(', ') :
        NO_VALUE;
    case 'encounter_prescription_summary':
      return dataObject.encounter && dataObject.prescriptions && dataObject.drugs ?
        dataObject.encounter.getPrescriptionSummary(dataObject.prescriptions, dataObject.drugs) :
        NO_VALUE;
    // PRESCRIPTIONS
    case 'prescriptions':
      return (dataObject.prescriptions || List())
        .map(
          prescription => fillFormFields(children, Object.assign({}, dataObject, { prescription })),
        )
        .toArray();
    case 'prescription_name':
      return dataObject.prescription ?
        dataObject.prescription.getDrug(dataObject.drugs || List()) :
        NO_VALUE;
    case 'prescription_dosage':
      return dataObject.prescription ?
        dataObject.prescription.get('prescribed_dosage', NO_VALUE, false) :
        NO_VALUE;
    case 'prescription_dosage_unit':
      return dataObject.prescription ?
        dataObject.prescription.get('prescribed_dosage_unit', NO_VALUE, false) :
        NO_VALUE;
    case 'prescription_frequency':
      return dataObject.prescription ?
        dataObject.prescription.get('prescribed_frequency', NO_VALUE, false) :
        NO_VALUE;
    case 'prescription_price':
      return dataObject.prescription ?
        convertNumberToPrice(dataObject.prescription.get('sale_price', NO_VALUE, false)) :
        NO_VALUE;
    case 'prescription_quantity':
      return dataObject.prescription ?
        dataObject.prescription.get('sale_quantity', NO_VALUE, false) :
        NO_VALUE;
    case 'prescription_total_price':
      return dataObject.prescription ?
        convertNumberToPrice(dataObject.prescription.getTotalPrice()) :
        NO_VALUE;
    case 'prescription_dispensation_unit':
      if (dataObject.prescription) {
        const drugId = dataObject.prescription.get('drug_id');
        const drug = (dataObject.drugs || List()).find(d => d.get('_id') === drugId);
        if (drug) {
          return drug.get('dispensation_unit', translate('unit'), false);
        }
      }
      return translate('unit');
    case 'prescription_notes':
      return dataObject.prescription ?
        dataObject.prescription.get('notes', NO_VALUE, false) :
        NO_VALUE;
    case 'prescription_reason':
      return dataObject.prescription ?
        dataObject.prescription.get('reason', NO_VALUE, false) :
        NO_VALUE;
    // BILL
    case 'bill_date':
      return dataObject.bill ?
        Moment(dataObject.bill.getDate()).format(modifier || getDateTimeFormat()) : NO_VALUE;
    case 'bill_last_edited':
      return dataObject.bill ?
        Moment(dataObject.bill.getLastUpdateTime()).format(modifier || getDateTimeFormat())
        : NO_VALUE;
    case 'bill_panel_name':
      return dataObject.bill && dataObject.coveragePayors ?
        dataObject.bill.getCoveragePayorName(dataObject.coveragePayors) : NO_VALUE;
    case 'bill_panel_address':
      return dataObject.bill && dataObject.coveragePayors ?
        getPanelAddressForBill(dataObject) : NO_VALUE;
    case 'bill_total_amount':
      return dataObject.bill ?
        convertNumberToPrice(dataObject.bill.get('total_amount', NO_VALUE, false)) : NO_VALUE;
    case 'bill_co_payment':
      return dataObject.bill ?
        convertNumberToPrice(dataObject.bill.get('co_payment', NO_VALUE, false)) : NO_VALUE;
    case 'bill_balance_amount':
      return dataObject.bill ?
        getBillBalance(dataObject) : NO_VALUE;
    case 'bill_notes':
      return dataObject.bill ? dataObject.bill.get('notes', NO_VALUE, false) : NO_VALUE;
    case 'bill_serial_number':
      return dataObject.bill ? dataObject.bill.get('internal_clinic_id', NO_VALUE, false) : NO_VALUE;
    // BILL ITEMS
    case 'bill_items':
      return (dataObject.billItems || List())
        .map(billItem => fillFormFields(children, Object.assign({}, dataObject, { billItem })))
        .toArray();
    case 'bill_item_quantity':
      return dataObject.billItem ? dataObject.billItem.get('quantity', NO_VALUE, false) : NO_VALUE;
    case 'bill_item_dispensation_unit':
      return dataObject.billItem && dataObject.drugs ?
        dataObject.billItem.getDispensationUnit(dataObject.drugs) || NO_VALUE :
        NO_VALUE;
    case 'bill_item_price':
      return dataObject.billItem ?
        convertNumberToPrice(dataObject.billItem.get('price', NO_VALUE, false)) : NO_VALUE;
    case 'bill_item_total_price':
      return dataObject.billItem ?
        convertNumberToPrice(dataObject.billItem.get('total_amount', NO_VALUE, false)) : NO_VALUE;
    case 'bill_item_name':
      if (dataObject.billItem && dataObject.billItem.isPrescription() && dataObject.drugs) {
        return dataObject.billItem.getItemName(dataObject.drugs) || NO_VALUE;
      }
      if (dataObject.billItem && dataObject.billItem.isSalesItem() && dataObject.salesItems) {
        return dataObject.billItem.getItemName(dataObject.salesItems) || NO_VALUE;
      }
      if (dataObject.billItem && dataObject.billItem.isProcedureType() &&
        dataObject.procedureTypes) {
        return dataObject.billItem.getItemName(dataObject.procedureTypes) || NO_VALUE;
      }
      return NO_VALUE;
    case 'bill_item_type':
      if (dataObject.billItem && dataObject.billItem.isPrescription()) {
        return translate('drug');
      } else if (dataObject.billItem && dataObject.billItem.isSalesItem()) {
        return translate('sales_item');
      }
      return NO_VALUE;
    case 'bill_item_is_claimed':
      if (dataObject.billItem && dataObject.bill) {
        return dataObject.billItem.isClaimed(dataObject.bill.has('coverage_payor_id')) ?
          translate('yes') : translate('no');
      }
      return NO_VALUE;
    // PAYMENTS
    case 'payments':
      return (dataObject.payments || List())
        .map(payment => fillFormFields(children, Object.assign({}, dataObject, { payment })))
        .toArray();
    case 'payment_date':
      return dataObject.payment && dataObject.payment.get('timestamp') ?
        Moment(dataObject.payment.get('timestamp'))
          .format(getDateFormat()) : NO_VALUE;
    case 'payment_amount':
      return dataObject.payment ?
        convertNumberToPrice(dataObject.payment.get('amount', NO_VALUE, false)) : NO_VALUE;
    case 'payment_method':
      return dataObject.payment ?
        translate(dataObject.payment.get('method', NO_VALUE, false)) : NO_VALUE;
    case 'payment_notes':
      return dataObject.payment ? dataObject.payment.get('notes', NO_VALUE, false) : NO_VALUE;
    // MEDICAL CERTIFICATE
    case 'mc_reason':
      return getMCValue(dataObject, 'reason', NO_VALUE);
    case 'mc_days':
      return getMCValue(dataObject, 'days', NO_VALUE);
    case 'mc_start_date': {
      const startDate = getMCValue(dataObject, 'start_date', undefined);
      return startDate ? Moment(startDate).format(modifier || getDateFormat()) : '';
    }
    case 'mc_end_date': {
      const endDate = getMCValue(dataObject, 'start_date', undefined);
      const days = getMCValue(dataObject, 'days', undefined);
      return endDate && days ?
        Moment(endDate).add(parseInt(Math.ceil(days), 10) - 1, 'd').format(modifier || getDateFormat()) : '';
    }
    case 'mc_notes':
      return getMCValue(dataObject, 'notes', NO_VALUE);
    case 'mc_internal_clinic_id':
      return getMCValue(dataObject, 'internal_clinic_id', NO_VALUE);
    // TIME CHIT
    case 'tc_start_time': {
      const value = getTimeChitValue(dataObject, 'start_time', undefined);
      return value ? Moment(value).format(modifier || getTimeFormat()) : '';
    }
    case 'tc_end_time': {
      const value = getTimeChitValue(dataObject, 'end_time', undefined);
      return value ? Moment(value).format(modifier || getTimeFormat()) : '';
    }
    case 'tc_notes':
      return getTimeChitValue(dataObject, 'notes', NO_VALUE);
    case 'tc_internal_clinic_id':
      return getTimeChitValue(dataObject, 'internal_clinic_id', NO_VALUE);
    // DISCOUNTS AND CHARGES
    case 'discounts_charges':
      return ((dataObject.usedDiscountsCharges || List())
        .reduce<[List<number>, Array<Fields>]>(([btItems, arr], item) => {
          const [amountToAdd, updatedBtItems] = btItems.reduce(([sum, list], bItem) => {
            const amountToAdd = bItem * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
            return [sum + amountToAdd, list.push(bItem + amountToAdd)];
          }, [0, List()]);
          return ([
            updatedBtItems,
            [
              ...arr,
              fillFormFields(children, Object.assign({}, dataObject, {
                discountCharge: item,
                amountToAdd: convertNumberToPrice(parseFloat(amountToAdd.toFixed(2))),
              })),
            ],
          ]);
        }, [dataObject.billItems?.map(bItem => calculateBillItemTotal(List([bItem]))), []])[1]);
    case 'discount_charge_amount':
      return dataObject.discountCharge ? `${dataObject.discountCharge.get('amount', NO_VALUE, false)}${dataObject.discountCharge.get('calculation_type') === 'fixed-percent' ? ' %' : ''}` : NO_VALUE;
    case 'discount_charge_method':
      return dataObject.discountCharge ? dataObject.discountCharge.get('method', NO_VALUE, false) : NO_VALUE;
    case 'discount_charge_to_add':
      return dataObject.amountToAdd || NO_VALUE;
    case 'discount_charge_name':
      return dataObject.discountCharge ? dataObject.discountCharge.get('name', NO_VALUE, false) : NO_VALUE;
    default:
      return ''; // It's assumed the user will want to fill in these fields themselves.
  }
}

/**
 * Takes a field with defined parameters, and extracts them into an array.
 * @param {string} field The field name (e.g. `MyField[[1,2,3]]`)
 * @param {string} delimiter The start delimiter (e.g. `[` or `[[`)
 * @param {string} endDelimiter The end delimiter (e.g. `]` or `]]`)
 * @returns {{ options: Array<string>, label: string }}
 */
function extractOptions(
  field: string,
  delimiter: string,
  endDelimiter: string,
): { options: Array<string>, label: string } {
  const strings = field.split(delimiter);
  return strings[1].indexOf(endDelimiter) === -1 ? // i.e. There's no end brackets.
    { options: [], label: field } :
    { label: strings[0], options: strings[1].split(endDelimiter)[0].split(',') };
}

/**
 * Gets the correct input component for the given field/value
 * @param {string} field The input field
 * @param {(string)} value The value
 * @param {function} onValueChanged A handler for when the value changes
 * @returns {React.ReactNode}
 */
export function getFormInput(
  field: string,
  value: string,
  onValueChanged: (field: string, newValue: string) => void,
): React.ReactNode {
  if (field.indexOf('[[') > -1) {
    const options = extractOptions(field, '[[', ']]');
    if (options.options.length) {
      const label = field.split('[')[0];
      return (
        <Multiselect
          id={label}
          label={translate(label)}
          value={value.split(', ').filter(v => v !== '').map(o => ({ value: o, label: o }))}
          options={options.options.map(o => ({ value: o, label: o }))}
          onChange={newValue => onValueChanged(field, newValue.map(v => v.value).join(', '))}
        />
      );
    }
  } else if (field.indexOf('[') > -1) {
    const options = extractOptions(field, '[', ']');
    if (options.options.length) {
      const label = field.split('[')[0];
      return (
        <Select
          id={label}
          label={translate(label)}
          value={value}
          options={options.options.map(o => ({ value: o, label: o }))}
          onValueChanged={newValue => onValueChanged(field, newValue)}
        />
      );
    }
  } else if (DATE_FIELDS.indexOf(field.split('|')[0]) > -1) {
    const format = field.split('|')[1] || getDateFormat();
    // TODO: Validation of modifier (things get borked if a weird format is given).
    return (
      <DatePicker
        id={field}
        label={translate(field.split('|')[0])}
        value={value && Moment(value, format).isValid() ? Moment(value, format) : undefined}
        onValueChanged={
          newValue => onValueChanged(field, newValue ? newValue.format(format) : newValue)
        }
        allowPast
        className="u-margin-bottom--1ws"
      />
    );
  } else if (TIME_FIELDS.indexOf(field.split('|')[0]) > -1) {
    const format = field.split('|')[1] || getTimeFormat();
    // TODO: Validation of modifier (things get borked if a weird format is given).
    return (
      <TimePicker
        id={field}
        label={translate(field.split('|')[0])}
        value={value ? Moment(value, format) : value}
        onValueChanged={
          newValue => onValueChanged(field, newValue ? newValue.format(format) : newValue)
        }
      />
    );
  } else if (DATE_TIME_FIELDS.indexOf(field.split('|')[0]) > -1) {
    const format = field.split('|')[1] || getDateTimeFormat();
    return (
      <DateTimePicker
        id={field}
        label={translate(field.split('|')[0])}
        value={value && Moment(value, format).isValid() ? Moment(value, format) : undefined}
        onValueChanged={
          newValue => onValueChanged(field, newValue ? newValue.format(format) : '')
        }
      />
    );
  } else if (LOOP_FIELDS.indexOf(field) > -1) {
    return <p>{value.toString()}</p>;
  }
  return (
    <TextArea
      id={field}
      label={translate(field)}
      value={value}
      onValueChanged={newValue => onValueChanged(field, newValue)}
      minRows={1}
    />
  );
}

/**
 * Checks if the document data has any custom placeholder and needs input from user.
 * @param {Fields} fields The fields object with key and value
 * @returns {boolean}
 */
export function isCustomFieldPresent(
  fields: Fields,
): boolean {
  return Object.keys(fields).some(key => !Array.isArray(fields[key]) && fields[key] === '' &&
    DATE_TIME_FIELDS.indexOf(key.split('|')[0]) === -1 &&
    DATE_FIELDS.indexOf(key.split('|')[0]) === -1 && EMPTY_VALID_FIELDS.indexOf(key.split('|')[0]) === -1);
}
