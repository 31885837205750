import React, { Fragment } from 'react';

import LoadingIndicator from './loadingIndicator';

import { logout } from '../utils/auth';

import type { HTMLStyle } from './../types';

type Props = {
  imageSrc: string,
  imageClassName: string,
  imageAlt: string,
  imageStyle: HTMLStyle,
};

type State = {
  isLoading: boolean,
}


/**
 * A Component that loads an image and shows a loading indicator while loading.
 * @class ImageLoader
 * @extends {React.Component<Props, State>}
 */
class ImageLoader extends React.Component<Props, State> {
  static defaultProps = {
    imageClassName: '',
    imageStyle: {},
  };

  /**
   * Creates an instance of ImageLoader.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        {this.state.isLoading && <LoadingIndicator />}
        <img
          onLoad={() => this.setState({ isLoading: false })}
          src={this.props.imageSrc}
          alt={this.props.imageAlt}
          className={this.props.imageClassName}
          style={this.state.isLoading ? { display: 'none' } : this.props.imageStyle}
          onError={() => logout(false)}
        />
      </Fragment>
    );
  }
}

export default ImageLoader;
