import moment from 'moment';
import { Map } from 'immutable';
import { isEmpty } from 'lodash';

import { Filter } from 'react-table';
import { getDateFormat } from './time';

import type { Config, PharmaConnectBrandDocumentResponseJSON, Row } from './../types';

import { knuthMorrisPratt } from '../utils/search';
import { getConfig } from './utils';


/**
* Filter method for the date column, converts date to required format before applying filter.
* @param {{id: string, value: string}} filter The filter data.
* @param {Row} row The row of data on filter will be applied.
* @returns {boolean}
*/
export function filterDate(filter: {id: string, value: string}, row: Row) {
  const filterWords = filter.value.toLowerCase().split(' ');
  return row[filter.id] !== undefined && row[filter.id] !== null ? // For some reason this can get to be null.
    filterWords.every(word => (Number.isInteger(row[filter.id]) ? moment(row[filter.id]).format(getDateFormat()) : moment(row[filter.id], 'YYYY-MM-DD').format(getDateFormat())).toLowerCase().indexOf(word) !== -1)
    : false;
}

/**
 *
 * @param {Filter} filter Reacttable filter
 * @param {Row} row Table Row
 * @returns {string}
 */
export function filterTextDefault(filter: Filter, row: Row) {
  return row[filter.id]?.toLowerCase().startsWith(filter.value.toLowerCase());
}
/**
* Filter method for column with dropdown filters.
* @param {{id: string, value: string}} filter The filter data.
* @param {Row} row The row of data on filter will be applied.
* @returns {boolean}
*/
export function filterDropDown(filter: {id: string, value: string}, row: Row) {
  if (filter.value === 'all') {
    return true;
  }
  return row[filter.id].toLowerCase() === filter.value.toLowerCase();
}

/**
* Filter method for column with dropdown filters.
* @param {{id: string, value: string}} filter The filter data.
* @param {Row} row The row of data on filter will be applied.
* @returns {boolean}
*/
export function filterMultiLineTypeDropDown(filter: {id: string, value: string}, row: Row) {
  if (filter.value === 'all') {
    return true;
  }
  const val = knuthMorrisPratt(row[filter.id].toLowerCase(), filter.value.toLowerCase());
  return val !== -1;
}

/**
* Filter method for the date column, converts date to required format before applying filter.
* @param {{id: string, value: string}} filter The filter data.
* @param {Row} row The row of data on filter will be applied.
* @returns {boolean}
*/
export function filterDateRange(filter:
  {id: string, value: {startDate: moment.Moment, endDate: moment.Moment}}, row: Row) {
  if (!filter.value.startDate && !filter.value.endDate) {
    return true;
  }
  if (!filter.value.startDate) {
    return row[filter.id] !== undefined && row[filter.id] !== null ? // For some reason this can get to be null.
      (Number.isInteger(row[filter.id]) ? moment(row[filter.id]) : moment(row[filter.id], 'YYYY-MM-DD')).isSameOrBefore(filter.value.endDate)
      : false;
  }
  if (!filter.value.endDate) {
    return row[filter.id] !== undefined && row[filter.id] !== null ? // For some reason this can get to be null.
      (Number.isInteger(row[filter.id]) ? moment(row[filter.id]) : moment(row[filter.id], 'YYYY-MM-DD')).isSameOrAfter(filter.value.startDate)
      : false;
  }

  return row[filter.id] !== undefined && row[filter.id] !== null ? // For some reason this can get to be null.
    (Number.isInteger(row[filter.id]) ? moment(row[filter.id]) : moment(row[filter.id], 'YYYY-MM-DD')).isBetween(filter.value.startDate, filter.value.endDate, undefined, '[]')
    : false;
}

/**
 * @param {Function} filterMethod Filter method
 * @returns {boolean}
 */
export function filterSubrows(filterMethod?: (filter: Filter, row: Row) => boolean) {
  return (filter: Filter, row: Row) => {
    // eslint-disable-next-line no-underscore-dangle
    const _filterMethod: (filter: Filter, row: Row) => boolean = filterMethod || filterTextDefault;
    // eslint-disable-next-line no-underscore-dangle
    return _filterMethod(filter, row) || row._subRows?.some((e: Row) => _filterMethod(filter, e));
  };
}
