import React, { useState } from 'react';
import { List } from 'immutable';

import DrugModel from './../../models/drugModel';
import translate from './../../utils/i18n';
import AddEditAllergy from './addEditAllergy';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { compareByAlphabeticalOrder } from './../../utils/comparators';
import Header from './../header/header';
import { UNICODE } from '../../constants';

import type { SaveModel, TableFlagType, User } from './../../types';
import type AllergyModel from './../../models/allergyModel';
import InventoryMapModel from './../../models/inventoryMapModel';
import Table from './../table/table';
import flagTableHOC from '../hoc/flagTable';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import AllergyDetails from './allergyDetails';
import EditHistory from '../editHistory/editHistory';
import FlagButton from '../buttons/flagButton';
import { getDrugUnspecifiedFlag } from '../../utils/prescriptions';

type Props = {
  className?: string,
  hideLastEdited?: boolean,
  items: List<AllergyModel>,
  patientID?: string,
  onModelSaved?: (allergyModel: AllergyModel) => void, // Can be used to pass to AddEditAllergy if you dont want the allergies immediately saved to the DB.
  saveModel?: SaveModel, // Optional as there are cases where this component is used without the need to immediately save (e.g. patient registration).
  user: User,
  onDeleteModel?: (allergyModel: AllergyModel) => void, // Used to delete allergy from local state
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
  allergies?: List<AllergyModel>,
};

const FlagTable = flagTableHOC(Table);

const COLUMNS = [
  { accessor: 'allergy_flag', Header: '', width: 50 },
  { accessor: 'allergy_category', Header: translate('allergy_category') },
  {
    accessor: 'allergy_sub_category', Header: translate('allergy_sub_category'),
  },
  { accessor: 'name', Header: translate('name') },
  { accessor: 'allergy_type', Header: translate('allergy_type') },
  { accessor: 'reaction', Header: translate('reaction') },
  { accessor: 'criticality', Header: translate('criticality') },
  { accessor: 'status', Header: translate('status') },
  {
    accessor: 'last_edited', Header: translate('last_edited'),
  },
  {
    accessor: 'action', Header: '', sortable: false, width: 250,
  },
];

/**
 * A list of Allergy models rendered as AllergyListItem components.
 * @param {Props} props The props for this component.
 * @returns {React.Component} An AllergyList.
 */
const AllergyList = ({
  items, patientID, onModelSaved, className = 'o-card', hideLastEdited, saveModel, user, onDeleteModel,
  verifiedDrugs, drugs, allergies,
}: Props) => {
  const columns = hideLastEdited ?
    COLUMNS.splice(COLUMNS.length - 2, 1) : COLUMNS;
  const data = items
    .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))).map((model) => {
      const allergyFlag: TableFlagType = getDrugUnspecifiedFlag(
        model,
      );
      return ({
        allergy_flag: (
          <FlagButton
            isFlagged={model.isFlagged()}
            onClick={() => {
              if (saveModel) {
                saveModel(model.toggleFlagged());
              }
            }}
          />
        ),
        flag: allergyFlag.active,
        flagData: { color: allergyFlag.color },
        tooltipData: allergyFlag.active && allergyFlag.tooltips,
        id: model.get('_id'),
        allergy_category: model.get('category', '\u2014', false),
        allergy_sub_category: model.getMedicationType(UNICODE.EMDASH),
        name: model.get('name', '\u2014', false),
        allergy_type: translate(model.get('allergy_type', '\u2014', false)),
        reaction: model.get('reaction', '\u2014', false),
        criticality: translate(model.get('criticality', '\u2014', false)),
        status: translate(model.get('status', '\u2014', false)),
        last_edited: model.getDateAndTime(),
        action: (
          <div className="o-data-list__row__actions">
            <AllergyDetails model={model} />
            {
              model.hasHistory() &&
              <EditHistory
                model={model}
                buttonLabel={translate('view_history')}
                buttonClass="o-text-button o-text-button--contextual"
              />
            }
            <PermissionWrapper permissionsRequired={List([createPermission('patient_allergies', 'update')])} user={user}>
              <AddEditAllergy
                model={model}
                isEditing
                onModelSaved={onModelSaved}
                saveModel={saveModel}
                onDeleteModel={onDeleteModel}
                verifiedDrugs={verifiedDrugs}
                drugs={drugs}
                allergies={allergies}
              />
            </PermissionWrapper>
          </div>
        ),
      });
    }).toArray();
  return (
    <section className={className || 'o-card'}>
      <Header className="o-card__header" dataPublic>
        <h1 className="o-card__title">{translate('allergies')}</h1>
        <PermissionWrapper permissionsRequired={List([createPermission('patient_allergies', 'create')])} user={user}>
          <div className="u-flex-right u-margin-right--1ws">
            <AddEditAllergy
              patientID={patientID}
              onModelSaved={onModelSaved}
              saveModel={saveModel}
              verifiedDrugs={verifiedDrugs}
              drugs={drugs}
              allergies={allergies}
            />
          </div>
        </PermissionWrapper>
      </Header>
      <FlagTable
        columns={columns}
        data={data}
        noDataText={translate('no_allergies_added')}
      />
    </section>
  );
};

export default AllergyList;
