import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'claim_invoice_payment',
  timestamp: number,
  amount: number,
  claim_invoice_id: string,
  claim_recon_id: string,
  is_void: boolean,
};

/**
   * ClaimInvoicePaymentModel, a document representing payment done by the panel for a claim invoice
   * @namespace ClaimInvoicePaymentModel
   */
class ClaimInvoicePaymentModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'claim_invoice_payment';
  }

  /**
   * Returns true if claim invoice payment has been voided.
   * @returns {boolean}
   */
  isVoid(): boolean {
    return this.get('is_void', false);
  }

  /**
   * sets model as deleted if true
   * @param {boolean} isDeleted is model deleted.
   * @returns {BaseModel} The altered ClaimInvoicePaymentModel.
   */
  setDeleted(isDeleted: boolean): ClaimInvoicePaymentModel {
    return this.set('is_void', isDeleted);
  }
}

export default ClaimInvoicePaymentModel;
