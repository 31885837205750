import { connect } from 'react-redux';
import type { List } from 'immutable';

import VitalsHistory from './../components/vitals/vitalsHistory';
import { saveFactory } from './../utils/redux';

import type { State, Dispatch } from './../types';

import type MetricTypeModel from './../models/metricTypeModel';
import type MetricEntryModel from './../models/metricEntryModel';

type Props = { patientID: string };

/**
 * Returns a List metric types filtered by if they are hidden and having no metric entries for each type.
 * @param {List<MetricTypeModel>} metricTypes A List of metric types.
 * @param {List<MetricEntryModel>} metricEntries A List of metric entries.
 * @returns {List<MetricTypeModel>} filtered metric types
 */
function filterMetricTypes(metricTypes: List<MetricTypeModel>,
  metricEntries: List<MetricEntryModel>): List<MetricTypeModel> {
  return metricTypes.filter(
    (type: MetricTypeModel) => {
      if (!type.isVisible()) {
        return metricEntries.some(entry => entry.get('metric_type') === type.get('_id'));
      }
      return true;
    },
  );
}

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ metricEntries, metricTypes }: State, ownProps: Props) => {
  const patientMetricEntries = metricEntries.filter(m => m.get('patient_id') === ownProps.patientID);
  return {
    metricEntries: patientMetricEntries,
    metricTypes: filterMetricTypes(metricTypes, patientMetricEntries),
    patientID: ownProps.patientID,
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const LatestVitalsContainer = connect(mapStateToProps, mapDispatchToProps)(VitalsHistory);

export default LatestVitalsContainer;
