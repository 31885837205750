
import BaseModel from './baseModel';

export type Attributes = {
  _id: string,
  type: 'dashboard_stat',
  patients_consulted: number,
  doctors_consulting: number,
  patients_registered: number,
  bills: number,
  foc_bills: number,
  revenue_cash: number,
  revenue_total: number,
  day?: Date,
  week_end?: Date,
  week_start?: Date,
  month?: string,
  year?: number,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
};

/**
 * SMSJobModel
 *
 * @namespace SMSJobModel
 */
class DashboardStatModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'dashboard_stat';
  }
}

export default DashboardStatModel;
