import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import LabTestsTable from './labTestsTable';
import LabTestsForm from './labTestsForm';
import Header from './../header/header';

import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import type SpecimenModel from './../../models/specimenModel';
import type { SaveModel, Config, User } from './../../types';

type Props = {
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  specimens: List<SpecimenModel>,
  saveModel: SaveModel,
  config: Config,
  user: User,
};

type State = {
  isModalVisible: boolean,
};

/**
 * A component for LabTests.
 * @class LabTests
 * @extends {Component}
 */
class LabTests extends React.Component<Props, State> {
  /**
   * Creates an instance of LabTests.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isModalVisible: false };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { providers, procedureTypes, saveModel, user } = this.props;
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('lab_tests')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <PermissionWrapper permissionsRequired={List([createPermission('lab_tests', 'create')])} user={user}>
              <StatelessModal
                id="addNewProcedureTypeModal"
                buttonLabel={translate('add_new_lab_test')}
                buttonClass="o-button o-button--small"
                title={translate('add_edit_lab_test')}
                className="js-modal"
                visible={this.state.isModalVisible}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                explicitCloseOnly
                dataPublicHeader
              >
                <LabTestsForm
                  config={this.props.config}
                  providers={providers}
                  onSave={() => this.setState({ isModalVisible: false })}
                  onCancel={() => this.setState({ isModalVisible: false })}
                  saveModel={this.props.saveModel}
                  specimens={this.props.specimens}
                />
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        <LabTestsTable
          providers={providers}
          procedureTypes={procedureTypes}
          saveModel={saveModel}
          user={user}
          config={this.props.config}
          specimens={this.props.specimens}
        />
      </ContentTransition>
    );
  }
}

export default LabTests;
