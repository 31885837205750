import React from 'react';
import { List } from 'immutable';

import Table from './../table/table';
import translate from './../../utils/i18n';
import { renderDate, renderMultilineCell, renderPrice } from './../../utils/tables';
import { sortByDate } from './../../utils/comparators';
import { UNICODE } from './../../constants';
import { createSuccessNotification } from './../../utils/notifications';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';

import type PaymentModel from './../../models/paymentModel';
import type ReceivableModel from './../../models/receivableModel';
import type { SaveModels, User } from './../../types';

type Props = {
  payments: List<PaymentModel>,
  receivable?: ReceivableModel | null | undefined,
  saveModels: SaveModels,
  user: User,
};

type State = {
  isModalVisible: boolean,
};

/**
 * Renders the given content with a wrapper to show it is void if isVoid is true.
 * @param {string} content Content to wrap.
 * @param {boolean} isVoid True if bill is void.
 * @returns {string | React.Component}
 */
function renderWithVoidWrapper(content: string, isVoid: boolean) {
  return isVoid ? <span style={{ opacity: 0.2 }}>{content}</span> : content;
}

/**
 * A table component that displays a list of Payments.
 * @class PaymentHistoryTable
 * @extends {React.Component<Props, State>}
 */
class PaymentHistoryTable extends React.Component<Props, State> {
  /**
   * Called when delete is clicked on an item. Updates its state to be hidden.
   * @param {BaseModel} item The item to delete
   * @returns {undefined}
   */
  onDeleteClicked(item: PaymentModel) {
    if (item) {
      getConfirmation(translate('confirm_payment_void'))
        .then(
          () => {
            const models = [];
            if (this.props.receivable) {
              const { receivable } = this.props;
              const newAmountDue = parseFloat(receivable.get('amount_due')) +
                parseFloat(item.get('amount'));
              models.push(receivable.set('amount_due', newAmountDue));
            }
            models.push(item.set('is_void', true));
            this.props.saveModels(models)
              .then(() => {
                createSuccessNotification(translate('payment_voided'));
              });
          },
          () => {},
        );
    }
  }

  /**
   * Returns a Delete button for the given item.
   * @param {BaseModel} item An item for the delete button
   * @returns {React.Component} A Delete button component.
   */
  getDeleteButton(item: PaymentModel) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('payments', 'delete')])} user={this.props.user}>
        <Button
          className="o-text-button o-text-button--danger"
          onClick={() => this.onDeleteClicked(item)}
          dataPublic
        >
          {translate('void_payment')}
        </Button>
      </PermissionWrapper>
    );
  }

  /**
   * Returns a table row for the given payment.
   * @param {PaymentModel} payment The payment for this row.
   * @returns {{}}
   */
  getRow(payment: PaymentModel) {
    return {
      date: payment.getDate(),
      amount_paid: payment.get('amount', 0),
      notes: payment.get('notes', UNICODE.EMDASH, false),
      payment_method: renderWithVoidWrapper(translate(payment.get('method', UNICODE.EMDASH, false)), payment.isVoid()),
      delete: !payment.isVoid() ? this.getDeleteButton(payment) : <Button dataPublic className="o-text-button o-text-button--danger" disabled>{translate('voided')}</Button>,
      renderAsVoid: payment.isVoid(),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { payments } = this.props;
    return (
      <Table
        columns={[
          { accessor: 'date', Header: translate('date'), Cell: renderDate, sortMethod: sortByDate },
          { accessor: 'amount_paid', Header: translate('amount_paid'), Cell: renderPrice },
          { accessor: 'payment_method', Header: translate('payment_method') },
          { accessor: 'notes', Header: translate('notes'), Cell: renderMultilineCell },
          { accessor: 'delete', Header: '' },
        ]}
        data={payments.map(p => this.getRow(p)).toArray()}
        noDataText={translate('no_payments_have_been_made')}
        showPagination
        defaultSorted={[{ id: 'date', desc: false }]}
      />
    );
  }
}

export default PaymentHistoryTable;
