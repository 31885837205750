import { List } from 'immutable';

import { getLocalDB, getRemoteDB, saveModel } from './db';
import { docToModel } from './models';
import { handleResponseError } from './utils';
import { getUserName } from "./user";
import { logMessage, debugPrint } from './logging';
import { updateLocalUnsyncedModels } from './sync';

import type BaseModel from './../models/baseModel';
import type { Dispatch } from './../types';

export const MAX_ATTEMPTS = 3;

/**
 * Handles a model where save failed due to a conflict.
 * @param {BaseModel} model The model with the conflict error.
 * @param {Dispatch} dispatch Redux dispatch.
 * @param {string} encryptKey The key with which model will be encrypted saved for later in case of failure
 * @param {string} userID The userID to which the model belongs to beused during save for later in case of failure
 * @param {number} attempts The number of previous attempts to resolve the conflict.
 * @returns {Promise<BaseModel?>} A promise for the completion of the save
 */
export default function resolveConflict(
  model: BaseModel,
  dispatch: Dispatch,
  encryptKey: string,
  userID: String,
  attempts: number = 0,
) {
  if (attempts > MAX_ATTEMPTS) {
    debugPrint(`Failed resolving conflicts for ${model.get('_id')}`, 'error');
    logMessage('Attemping to resolve conflict but failed multiple times.', 'error');
    updateLocalUnsyncedModels(getLocalDB(), userID || getUserName(), List([model]), encryptKey);
  }
  return getRemoteDB()
    .get(model.get('_id'))
    .then((remoteDoc) => {
      const remoteModel = docToModel(remoteDoc);
      return remoteModel
        ? saveModel(model.merge(remoteModel), dispatch, encryptKey, userID, attempts + 1, true)
        : Promise.resolve();
    })
    .catch((error) => {
      handleResponseError(error, 'error while getting document for merging');
    });
}
