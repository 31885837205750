import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'dosing_regimen',
  hidden: boolean,
  name: string,
  frequency: string,
  interval: string,
  administration_first_time: string,
  administration_time: string,
  administration_description: string,

};

/**
   * DosingRegimenModel
   *
   * @namespace DosingRegimenModel
   */
class DosingRegimenModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'dosing_regimen';
  }
}

export default DosingRegimenModel;
