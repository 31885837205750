import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'receivable',
  patient_id: string,
  bill_id: string,
  amount: number,
  timestamp: number,
  amount_due: number,
  notes: string,
  is_void: boolean,
};

/**
   * ReceivableModel
   * @namespace ReceivableModel
   */
class ReceivableModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'receivable';
  }

  /**
   * Returns true if Receivable has been voided.
   * @returns {boolean}
   */
  isVoid(): boolean {
    return this.get('is_void', false);
  }

  /**
   * Returns true if Receivable has been voided.
   * @returns {boolean}
   */
  hasOutstanding(): boolean {
    return this.get('amount_due', 0) > 0;
  }

  /**
   * Gets the date of the receivable (not exactly the same as created_by or edited_by).
   * @returns {number}
   */
  getDate(): number {
    return this.has('timestamp') ? this.get('timestamp') : this.getLastUpdateTime();
  }
}

export default ReceivableModel;
