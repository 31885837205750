import React from 'react';
import { Page } from 'catalog';

import StyleguideReset from './styleguideReset';
import Colours from './visuals/colours';
import Typography from './visuals/typography';

/**
 * Visuals section of styleguide
 * @class StyleguideVisuals
 * @extends {React.Component<Props, State>}
 */
const StyleguideVisuals = () =>
  <StyleguideReset>
    <Page>
      <Colours />
      <Typography />
      <h2 data-public>Icons</h2>
    </Page>
  </StyleguideReset>;

export default StyleguideVisuals;
