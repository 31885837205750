import React from 'react';
import { List, Map } from 'immutable';

import ScheduleFormField from './scheduleFormField';
import RulesFormField from './rulesFormField';
import Label from '../inputs/label';
import translate from '../../utils/i18n';
import SalesItemModel from '../../models/salesItemModel';
import DrugModel from '../../models/drugModel';

import PatientCampaignModel, { CampaignRulesCondition, CampaignRuleType, TimeUnit } from '../../models/patientCampaignModel';
import type PractitionerModel from '../../models/practitionerModel';
import type { Config, EnrolAt, ScheduleAt } from '../../types';
import type MasterDrugModel from '../../models/masterDrugModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import EnrolmentFormField from './enrolmentFormField';
import TargetPatientFormField from './targetPatientsFormField';
import MasterCampaignModel from '../../models/masterCampaignModel';
import { getServerName, getPrependString } from '../../utils/utils';

type Props = {
  config: Config,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  practitioners: List<PractitionerModel>,
  rules: {
    scheduleValue: number,
    scheduleUnit: TimeUnit,
    enrolmentValue: number,
    enrolmentUnit: TimeUnit,
    target: 'prospective' | 'retrospective',
    filterConditions: List<CampaignRulesCondition>,
    logicalOp: 'or' | 'and',
    campaignJobId?: string,
    masterCampaignJobId?: string,
    jobType: string,
  },
  disableProspective?: boolean,
  onRulesChange: (rules: {filters: Array<CampaignRulesCondition>, logicalOp: 'and' | 'or'}) => void,
  onScheduleChange: (value: ScheduleAt) => void,
  onEnrolmentChange: (value: EnrolAt) => void,
  onTargetPatientChange: (value: 'prospective' | 'retrospective') => void,
  errorMessage: string,
  disabled?: boolean,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
  encounterFlows: List<EncounterFlowModel>,
  masterCampaignModels: Map<string, MasterCampaignModel | PatientCampaignModel>,
  patientCampaigns: Map<string, PatientCampaignModel>,
  jobs: Map<string, PatientCampaignModel>,
}

/**
 * Renders a component showing the campaign rules section of the sms campaign form.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
export default function CampaignRulesFormSection(props: Props) {
  return (
    <div className="o-form__item">
      <Label
        id="campaign-rules-label"
        label={translate('campaign_rules')}
        invalid={false}
      />
      <RulesFormField
        logicalOp={props.rules.logicalOp}
        filterConditions={
          props.rules.filterConditions.filter(c => c.type !== CampaignRuleType.ENCOUNTER_TIME)
        }
        onChange={props.onRulesChange}
        drugs={props.drugs}
        salesItems={props.salesItems}
        config={props.config}
        practitioners={props.practitioners}
        disabled={props.disabled}
        masterDrugModelsMap={props.masterDrugModelsMap}
        encounterFlows={props.encounterFlows}
      />
      <TargetPatientFormField
        onChange={props.onTargetPatientChange}
        target={props.rules.target}
        errorMessage={props.errorMessage}
        disabled={props.disabled}
        disableProspective={props.disableProspective}
      />
      {
        props.rules.target === 'retrospective'
          ? (<EnrolmentFormField
            onChange={props.onEnrolmentChange}
            enrolmentValue={props.rules.enrolmentValue}
            enrolmentUnit={props.rules.enrolmentUnit}
            disableProspective={props.disableProspective}
            errorMessage={props.errorMessage}
            disabled={props.disabled}
          />)
          : null
      }
      <ScheduleFormField
        onChange={props.onScheduleChange}
        target={props.rules.target}
        scheduleValue={props.rules.scheduleValue}
        scheduleUnit={props.rules.scheduleUnit}
        errorMessage={props.errorMessage}
        disabled={props.disabled}
        jobType={props.rules.jobType}
        campaignId={getPrependString(`mc-${getServerName()}-`, props.rules.masterCampaignJobId) || props.rules.campaignJobId}
        campaigns={props.masterCampaignModels.merge(props.patientCampaigns)}
        jobs={props.jobs}
      />
    </div>
  );
}
