import React from 'react';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import type { SortingRule } from 'react-table';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import flagTableHOC from '../hoc/flagTable';
import { prettifyDate, prettifyTime } from './../../utils/time';
import { getEncounterFlag, alertEncounterStage, getEncounterCreatedBy } from './../../utils/encounters';
import { playSound } from './../../utils/audio';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import getPanelNameForPatient from './../../utils/coveragePayors';
import Button from './../buttons/button';
import Header from './../header/header';
import TableCollapseIcon from './../icons/tableCollapseIcon';
import TableColumnsSettings from '../table/tableColumnsSettings';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import OptionButton from '../patient/optionButton';
import { getModelMapFromList } from '../../utils/models';
import { getEncounterTableColumns } from './columns';
import { filterWithConditions } from '../../utils/tableFilter';

import type EncounterModel from './../../models/encounterModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type { SaveModel, Config, User, TableFlagType, CustomColumn, SaveModels, Column } from './../../types';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ReceivableModel from './../../models/receivableModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type BillModel from '../../models/billModel';
import type { FilteringRule } from './tableFilter';

type Props = {
  config: Config,
  encounters: List<EncounterModel>,
  label: string,
  patients: Map<string, PatientStubModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  playAudioAlert: boolean,
  user: User,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
  outstandingReceivables: List<ReceivableModel>,
  updateConfig: (config: Config) => void,
  configField: string,
  encounterStageMap: Map<string, EncounterStageModel>,
  inProgress?: boolean,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  bills: Map<string, BillModel>,
  saveModels: SaveModels,
  currentEncounters: List<EncounterModel>,
  tableProps: {
    sorted: SortingRule[],
    filteringRule: FilteringRule,
  }
};

type State = {
  currentSaving: string, // encounter id of currently being saved encounter
  isTableCollapsed: boolean,
  columns: Array<CustomColumn>,
}

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @param {boolean} includeStage Includes stage column (Only needed for inprogress table)
 * @returns {object[]} An array of objects each representing a column in the table.
 */
const getColumns = (config: Config, includeStage?: boolean) =>
  getEncounterTableColumns(config, includeStage);

const FlagTable = flagTableHOC(Table);

/**
 * An EncounterTable component to be used within the PatientList component.
 * @class EncounterTable
 * @extends {React.Component<Props, State>}
 */
class EncounterTable extends React.Component<Props, State> {
  props: Props;

  columns: Array<Column>;

  static defaultProps = {
    playAudioAlert: false,
  };

  /**
   * Creates an instance of EncounterTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.columns = getColumns(props.config, props.inProgress);
    this.state = {
      currentSaving: '',
      isTableCollapsed: false,
      columns: transformColumnKeys(this.columns).filter(i => !i.hideDefault),
    };
  }

  /**
   * Plays an alert sound when a new encounter arrives.
   * @param {Props} prevProps Prev Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.encounters.size !== prevProps.encounters.size) {
      const newEncounters = this.props.encounters.filter(newEncounter => !prevProps.encounters.some(oldEncounter => oldEncounter.get('_id') === newEncounter.get('_id')));
      if (this.props.playAudioAlert && newEncounters.size) { // Completed encounter
        playSound(this.props.config.getIn(['patient_overview', 'alert_sound'], 'announcement_down.mp3'));
      } else if (newEncounters.size) { // In-progress encounters
        newEncounters.forEach((encounter) => {
          const encounterStages = encounter.getActiveStages();
          const { currentStageIndex } = encounter.getCurrentStageIndex();
          const currentStage = encounterStages.get(currentStageIndex);
          const encounterStageModel: EncounterStageModel = this.props.encounterStageMap.get(currentStage?.stage_id);
          alertEncounterStage(encounterStageModel, 'in_progress_alert');
        });
      }
    }
  }

  /**
   * Renders the encounter status change button for a given encounter.
   * @param {EncounterModel} encounter The encounter to render the button for.
   * @param {SaveModel} saveModel Function that saves the model to DB.
   * @param {User} user User.
   * @returns {React.Component} A button component or null as required.
   */
  getEncounterStatusButton(
    encounter: EncounterModel,
    saveModel: SaveModel,
    user: User,
  ): JSX.Element | void {
    const optionButton = (
      <OptionButton
        encounter={encounter}
        encounterFlowMap={this.props.encounterFlowMap}
        encounters={this.props.currentEncounters}
        config={this.props.config}
        practitioners={this.props.practitioners}
        bills={this.props.bills}
        encounterStageMap={this.props.encounterStageMap}
        salesItems={getModelMapFromList(this.props.salesItems)}
        coveragePayors={this.props.coveragePayors}
        saveModel={this.props.saveModel}
        updateConfig={this.props.updateConfig}
        saveModels={this.props.saveModels}
        user={this.props.user}
      />
    );
    switch (encounter.getLastEventType()) {
      case 'started':
        return (
          <PermissionWrapper permissionsRequired={List([createPermission('queue_patient_for_today', 'update')])} user={user}>
            <Button
              className="o-button o-button--small"
              onClick={(event) => {
                event.stopPropagation();
                this.setState({ currentSaving: encounter.get('_id') });
                const encounterFlowStages = encounter.getActiveStages();
                const { currentStageIndex } = encounter.getCurrentStageIndex();
                const nextStage = encounterFlowStages.get(currentStageIndex + 1);
                if (encounter.isLastStage()) { // If last stage, finish consult
                  saveModel(encounter.addStageEvent('completed').addEvent('finished_consult'))
                    .finally(() => this.setState({ currentSaving: '' }));
                } else if (nextStage) { // Move to next stage's waiting room
                  saveModel(
                    encounter.addStageEvent('completed')
                      .addStage(nextStage.stage_id, nextStage._name),
                  )
                    .then(() => {
                      const nextStageModel = this.props.encounterStageMap.get(nextStage.stage_id);
                      alertEncounterStage(nextStageModel, 'waiting_alert');
                    })
                    .finally(() => this.setState({ currentSaving: '' }));
                }
              }}
              disabled={this.state.currentSaving === encounter.get('_id')}
              dataPublic
            >
              {translate('completed')}
            </Button>
            {optionButton}
          </PermissionWrapper>
        );
      case 'finished_consult':
        return (
          <PermissionWrapper permissionsRequired={List([createPermission('unfinalised_bill', 'read')])} user={user}>
            <Button
              className="o-button o-button--small"
              onClick={(event) => {
                event.stopPropagation();
                location.hash = `/patient/${encounter.get('patient_id')}/billing/${encounter.get('_id')}`;
              }}
              dataPublic
            >
              {translate('dispense_bill')}
              {/* {translate('go_to_dispensation_billing')} */}
            </Button>
            {optionButton}
          </PermissionWrapper>
        );
      default:
        return undefined;
    }
  }

  /**
   * Returns an object with appropriate fields for a Table row populated from an EncounterModel.
   * @param {EncounterModel} encounter The encounter the button is for.
   * @param {List<PatientStubModel>} patients A list of all PatientStubs.
   * @param {List<SalesItemModel>} salesItems A list of all salesItems.
   * @param {SaveModel} saveModel The save model function.
   * @param {User} user User.
   * @param {List<PractitionerModel>} practitioners A list of all Practitioners
   * @returns {object} A table row object.
   */
  getTableRow(
    encounter: EncounterModel, patients: Map<string, PatientStubModel>,
    salesItems: List<SalesItemModel>, saveModel: SaveModel, user: User,
    practitioners: List<PractitionerModel>,
  ) {
    const patient = patients.get(encounter.get('patient_id'));
    let doctor = encounter.getDoctorName(practitioners);
    if (doctor === UNICODE.EMDASH || !doctor?.length) {
      doctor = translate('unassigned');
    }

    const encounterFlag: TableFlagType = getEncounterFlag(
      encounter,
      patient,
      this.props.outstandingReceivables,
    );

    return {
      flag: encounterFlag.active,
      flagData: { color: encounterFlag.color },
      tooltipData: encounterFlag.active && encounterFlag.tooltips,
      q_no: encounter.getQueueNumber(),
      arrival_time: prettifyTime(encounter.getArrivalTime()),
      arrival_date: prettifyDate(encounter.getArrivalTime()),
      name: patient ? patient.get('patient_name', UNICODE.EMDASH) : translate('loading...'),
      ic: patient ? patient.get('ic', UNICODE.EMDASH, false) : translate('loading...'),
      case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : translate('loading...'),
      location: encounter.getValue('location') || UNICODE.EMDASH,
      sex: patient ? patient.get('sex', UNICODE.EMDASH, false) : translate('loading...'),
      panel_or_cash: patient ? patient.getPaymentType() : translate('loading...'),
      panel_name: getPanelNameForPatient(patient, this.props.coveragePayors),
      doctor,
      creator: getEncounterCreatedBy(encounter),
      encounter_flow: encounter.getEncounterType(this.props.salesItems),
      encounter_stage: this.props.inProgress ? encounter.getValue('name') || UNICODE.EMDASH : '',
      action: this.getEncounterStatusButton(encounter, saveModel, user),
      link: `/patient/${encounter.get('patient_id')}`,
    };
  }

  /**
   * Returns memoized data for the table
   * @param {List<EncounterModel>} encounters list of encounters for single stage
   * @returns {Object}
   */
  getTableData = memoizeOne((encounters: List<EncounterModel>, filteringRule: FilteringRule) => {
    const {
      patients, salesItems, saveModel, user, practitioners,
    } = this.props;
    const tableData = encounters &&
    encounters
      .map(encounter =>
        this.getTableRow(
          encounter,
          patients,
          salesItems,
          saveModel,
          user,
          practitioners,
        ))
      .toArray();
    return {
      tableData,
      filteredTableData: filterWithConditions(tableData, filteringRule),
    };
  });

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      encounters, label, updateConfig, config, tableProps,
    } = this.props;
    const transformedColumnKeys = transformColumnKeys(this.columns);
    const { isTableCollapsed } = this.state;
    const { filteredTableData } = this.getTableData(encounters, tableProps.filteringRule);
    return (
      <section className="o-card">
        <Header className="o-card__header">
          <h1 className="o-card__title o-card__title--no-right-padding u-margin-right--half-ws" data-public>{ label }</h1>
          <span>-&nbsp;&nbsp;{encounters.size}</span>
          <span className="o-card__header__right-aligned-text">
            <TableColumnsSettings
              config={config}
              configFieldName={this.props.configField}
              originalColumns={List(transformedColumnKeys)}
              columns={transformedColumnKeys}
              onUpdateColumns={columns => this.setState({ columns })}
              updateConfig={updateConfig}
              isCompact
            />
            <TableCollapseIcon
              id={this.props.configField}
              isTableCollapsed={isTableCollapsed}
              onClick={() =>
                this.setState({ isTableCollapsed: !isTableCollapsed })
              }
            />
          </span>
        </Header>
        {!isTableCollapsed &&
          <FlagTable
            columns={transformFilteredColumns(this.columns, this.state.columns)}
            data={filteredTableData}
            defaultSorted={[{ id: 'arrival_time', desc: false }]}
            noDataText={translate(tableProps?.filteringRule?.filterRules.length ? 'no_filter_result' : 'no_patients')}
            sorted={tableProps.sorted}
          />
        }
      </section>
    );
  }
}

export default EncounterTable;
