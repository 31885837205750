import React from 'react';
import { List } from 'immutable';

import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import Keypress from './../keypress';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import { fetchModels } from './../../utils/db';
import { validateAndTrimString } from './../../utils/utils';
import Button from './../buttons/button';

import type { Config, Model, SaveModel } from './../../types';
import type PatientStubModel from '../../models/patientStubModel';

type Props = {
  addString: (string: string) => Promise<void>,
  editString: (number: number, string: string) => Promise<void>,
  config: Config,
  type: string,
  modelToEdit?: Model,
  clearModelToEdit: () => void,
  items: List<string>,
  saveModel?: SaveModel,
  patientStubs?: List<PatientStubModel>,
};

type State = {
  isSaving: boolean,
  item: string,
  changesMade: boolean,
  errorMessage?: string,
};

const defaultState = {
  isSaving: false,
  item: '',
  changesMade: false,
  errorMessage: undefined,
};

/**
 * A form component for the string list editor.
 * @class StringListEditorForm
 * @extends {React.Component<Props, State>}
 */
class StringListEditorForm extends React.Component<Props, State> {
  /**
   * Creates an instance of StringListEditorForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
  }

  /**
   * Resets the model on props change and sets state to duplicate attributes for the incoming model
   * if there is one.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      if (nextProps.modelToEdit) {
        const { modelToEdit } = nextProps;
        this.setState({
          item: modelToEdit,
          changesMade: false,
        });
      } else {
        this.resetState();
      }
    }
  }

  /**
   * Resets the state.
   * @returns {void}
   */
  resetState() {
    this.setState({
      isSaving: false,
      changesMade: false,
      item: '',
    });
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    const trimmedItem = validateAndTrimString(this.state.item);
    if (!trimmedItem.length) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    if ((this.props.type === 'diagnoses' ||
        this.props.type === 'symptoms' ||
        this.props.type === 'patient_tags' ||
        this.props.type === 'panel_categories') &&
      this.props.config.getIn([this.props.type, 'options'], List())
        .find(l => l === trimmedItem)) {
      this.setState({ errorMessage: translate('item_already_exists') });
      return false;
    }
    return true;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    const trimmedValue = validateAndTrimString(this.state.item);
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      if (this.props.modelToEdit) {
        const index = this.props.items.toArray().indexOf(this.props.modelToEdit);
        this.props.editString(index, trimmedValue)
          .then(() => {
            if (this.props.type === 'patient_tags' && this.props.patientStubs && this.props.saveModel) {
              const patientIDs = [];
              this.props.patientStubs.map(patient => patientIDs.push(patient.get('_id')));
              fetchModels(patientIDs)
                .then((models) => {
                  models.filter((patient) => {
                    if (!patient.has('tags') || !patient.get('tags').length) {
                      return false;
                    }
                    // Update Patient tags
                    if (patient.get('tags').includes(this.props.modelToEdit)) {
                      const pTags = patient.get('tags');
                      const tagIndex = pTags.indexOf(this.props.modelToEdit);
                      pTags.splice(tagIndex, 1, trimmedValue);
                      // $FlowFixMe
                      this.props.saveModel(patient.set('tags', pTags));
                    }
                    return true;
                  });
                });
            }
          })
          .then(() => {
            createSuccessNotification(translate('item_updated'));
            this.setState(defaultState);
            return true;
          });
      } else {
        return this.props.addString(trimmedValue)
          .then(() => {
            createSuccessNotification(translate('item_added'));
            this.setState(defaultState);
            return true;
          });
      }
    }
    return Promise.resolve(false);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
        <SavePrompt when={this.state.changesMade} onSaveClicked={() => this.onSaveClicked()} />
        {
          this.state.errorMessage &&
          <FormError containerElementID="right-sidebar">
            {this.state.errorMessage}
          </FormError>
        }
        <Input
          id="item"
          label={translate('name')}
          labelClassName="u-font-color-white"
          value={this.state.item}
          onValueChanged={value => this.setState({ item: value, changesMade: true })}
          required
        />
        <SaveButton
          dataPublic
          className="o-button--small"
          isSaving={this.state.isSaving}
          label={translate(this.props.modelToEdit ? 'save' : 'add_item')}
          onClick={() => this.onSaveClicked()}
          fullWidth
        />
        {
          this.props.modelToEdit &&
          <Button
            onClick={this.props.clearModelToEdit}
            className="o-button o-button--small o-button--danger u-margin-top--standard"
            dataPublic
          >
            {translate('cancel')}
          </Button>
        }
      </Keypress>
    );
  }
}

export default StringListEditorForm;
