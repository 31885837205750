import React from 'react';
import { List, Map } from 'immutable';
import moment from 'moment';
import Button from './../buttons/button';

import { CONFIG, DEFAULT_CLINIC_CONFIG_FILE_ID, DEFAULT_KLINIFY_CONFIG_FILE_ID } from './../../constants';
import DefaultConfig from './../../config/defaultConfig';
import DefaultKlinifyConfig from './../../config/defaultKlinifyConfig';


import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { updateClinicConfig, getClinicConfig } from './../../utils/db';
import { mergeWithoutLists, saveBlob, mapFromJS, getBaseApiUrl, getConfirmation } from './../../utils/utils';
import { isKlinifyUser } from './../../utils/auth';
import translate from './../../utils/i18n';
import Input from './../inputs/input';
import Checkbox from './../inputs/checkbox';
import TextArea from './../inputs/textarea';
import Select from './../inputs/select';
import SaveButton from './../buttons/saveButton';
import ContentTransition from './../contentTransition';
import TagInput from './../inputs/tagInput';
import MultiSelect from './../inputs/multiSelect';
import LoadingIndicator from './../loadingIndicator';
import Radio from './../inputs/radio';
import { playSound } from './../../utils/audio';
import SavePrompt from './../prompts/savePrompt';
import ClinicLogoForm from './clinicLogoForm';
import Keypress from './../keypress';
import type { Config, MapValue, User, SelectOption } from './../../types';
import type SalesItemModel from './../../models/salesItemModel';
import { printQueueTicket } from '../../utils/print';
import EncounterModel from '../../models/encounterModel';

type Props = {
  salesItems: Map<string, SalesItemModel>,
  user: User,
  updateConfig: (config: Config) => void,
  updateKlinifyConfig: (config: Config) => void,
  isQueueSetting?: boolean,
};

type State = {
  clinicConfig: Config,
  klinifyConfig: Config,
  originalConfig: Config,
  originalKlinifyConfig: Config,
  configModifications: Partial<Config>,
  klinifyConfigModifications: Partial<Config>,
  isSaving: boolean,
  isLoading: boolean,
  isClinicConfigChanged: boolean,
  isKlinifyConfigChanged: boolean,
  logoDataUrl: string | null | undefined,
  isLogoChanged: boolean,
  isUploadFormClear: boolean,
};

/**
 * Settings
 *
 * @namespace Settings
 */
class Settings extends React.Component<Props, State> {
/**
   * Creates an instance of Settings.
   * @param {object} props The props for this Component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      isLoading: true,
      isClinicConfigChanged: false,
      clinicConfig: mapFromJS(DefaultConfig),
      klinifyConfig: mapFromJS(DefaultKlinifyConfig),
      logoDataUrl: undefined,
      isLogoChanged: false,
      isUploadFormClear: true,
      configModifications: Map(),
      klinifyConfigModifications: Map(),
      originalConfig: Map(),
      originalKlinifyConfig: Map(),
      isKlinifyConfigChanged: false,
    };
    if (props.isQueueSetting) {
      Promise.all([getClinicConfig(DEFAULT_CLINIC_CONFIG_FILE_ID)])
        .then((configArray) => {
          const clinicConfig =
            mapFromJS(DefaultConfig).mergeWith(mergeWithoutLists, mapFromJS(configArray[0]));
          const updatedClinicConfig = this.getDefaultClinicConfigUpdatedValues(clinicConfig);
          this.setState({
            clinicConfig: updatedClinicConfig,
            originalConfig: updatedClinicConfig,
            isLoading: false,
          });
        });
    } else {
      Promise.all([getClinicConfig(DEFAULT_CLINIC_CONFIG_FILE_ID),
        getClinicConfig(DEFAULT_KLINIFY_CONFIG_FILE_ID)])
        .then((configArray) => {
          const clinicConfig =
            mapFromJS(DefaultConfig).mergeWith(mergeWithoutLists, mapFromJS(configArray[0]));
          const updatedClinicConfig = this.getDefaultClinicConfigUpdatedValues(clinicConfig);
          const klinifyConfig =
            mapFromJS(DefaultKlinifyConfig).mergeWith(mergeWithoutLists, mapFromJS(configArray[1]));
          this.setState({
            clinicConfig: updatedClinicConfig,
            klinifyConfig,
            originalConfig: updatedClinicConfig,
            originalKlinifyConfig: klinifyConfig,
            isLoading: false,
          });
        });
    }
  }

  /**
   * Returns default updated values of the clinic config
   * @param {Config} clinicConfig clinic config
   * @returns {Config}
   */
  getDefaultClinicConfigUpdatedValues = (clinicConfig: Config) => {
    const ticketTitleText = clinicConfig.getIn(['patient_queue', 'ticket_config', 'title']);
    const clinicName = clinicConfig.getIn(['clinic', 'name']);
    return clinicConfig.setIn(['patient_queue', 'ticket_config', 'title'], ticketTitleText || clinicName);
  }

  /**
   * Updates a value in the config Map.
   * @param {string[]} keyArray An array of keys to the value.
   * @param {any} newValue The new value.
   * @param {boolean} isKlinifyConfig is klinify config,
   * @returns {undefined}
   */
  updateConfigValue(keyArray: Array<string>, newValue: MapValue, isKlinifyConfig?: boolean) {
    if (isKlinifyConfig) {
      this.setState({
        klinifyConfig: this.state.klinifyConfig.setIn(keyArray, newValue),
        klinifyConfigModifications: this.state.klinifyConfigModifications.setIn(keyArray, newValue),
        isKlinifyConfigChanged: true,
      });
      return;
    }
    this.setState({
      isClinicConfigChanged: true,
      clinicConfig: this.state.clinicConfig.setIn(keyArray, newValue),
      configModifications: this.state.configModifications.setIn(keyArray, newValue),
    });
  }

  /**
   * Updates premium settings in the config Map.
   * These settings are available only to klinify users and premium users
   * If user is premium, clinic config overrides klinify config
   * @param {string[]} keyArray An array of keys to the value.
   * @param {any} newValue The new value.
   * @param {boolean} isKlinifyConfig is klinify config,
   * @returns {undefined}
   */
  updatePremiumConfigValues(keyArray: Array<string>, newValue: MapValue) {
    if (isKlinifyUser(this.props.user)) {
      this.setState({
        klinifyConfig: this.state.klinifyConfig.setIn(keyArray, newValue),
        klinifyConfigModifications: this.state.klinifyConfigModifications.setIn(keyArray, newValue),
        isKlinifyConfigChanged: true,
      });
    }
    this.setState({
      isClinicConfigChanged: true,
      clinicConfig: this.state.clinicConfig.setIn(keyArray, newValue),
      configModifications: this.state.configModifications.setIn(keyArray, newValue),
    });
  }

  /**
   * If form is valid and changes have been made it saves the changes to config.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    if (this.state.isClinicConfigChanged || this.state.isKlinifyConfigChanged) {
      this.setState({ isSaving: true });
      /**
       * Promise of array to update clinic and klinify config
       * on basis of changes made.
       * @returns {Array<Promise<any>>}
       */
      const resolvedUpdater = () => ([
        ...this.state.isClinicConfigChanged ?
          [updateClinicConfig(
            this.state.originalConfig.toJS(),
            this.state.configModifications.toJS(),
            this.props.updateConfig,
          )] : [],
        ...this.state.isKlinifyConfigChanged ?
          [updateClinicConfig(
            this.state.originalKlinifyConfig.toJS(),
            this.state.klinifyConfigModifications.toJS(),
            this.props.updateklinifyConfig,
          )] : [],
      ]);
      return Promise.all(resolvedUpdater())
        .then(() => {
          const { isClinicConfigChanged, isKlinifyConfigChanged } = this.state;
          if (isClinicConfigChanged && isKlinifyConfigChanged) {
            return Promise.all([
              getClinicConfig(DEFAULT_CLINIC_CONFIG_FILE_ID),
              getClinicConfig(DEFAULT_KLINIFY_CONFIG_FILE_ID),
            ]);
          }
          if (this.state.isClinicConfigChanged) {
            return Promise.all([getClinicConfig(DEFAULT_CLINIC_CONFIG_FILE_ID)]);
          }
          return Promise.all([getClinicConfig(DEFAULT_KLINIFY_CONFIG_FILE_ID)]);
        })
        .then((configDocArray) => {
          createSuccessNotification(translate('settings_updated'));

          /**
           * return klinify and clinic config on basis of the resolved
           * config array and changes made.
           * @param {Config} clinicConfig clinic config
           * @param {Config} klinifyConfig klinify config
           * @returns {{ clinicConfig: Config, klinifyConfig: Config}} klinify and clinic config
           */
          const getConfig = () => {
            if (configDocArray.length === 1 && this.state.isKlinifyConfigChanged) {
              return {
                klinifyConfig: mapFromJS(DefaultKlinifyConfig)
                  .mergeWith(mergeWithoutLists, mapFromJS(configDocArray[0])),
                clinicConfig: this.state.clinicConfig,
              };
            }
            if (configDocArray.length === 1 && this.state.isClinicConfigChanged) {
              return {
                clinicConfig: mapFromJS(DefaultConfig)
                  .mergeWith(mergeWithoutLists, mapFromJS(configDocArray[0])),
                klinifyConfig: this.state.klinifyConfig,
              };
            }
            if (configDocArray.length > 1) {
              return {
                clinicConfig: mapFromJS(DefaultConfig)
                  .mergeWith(mergeWithoutLists, mapFromJS(configDocArray[0])),
                klinifyConfig: mapFromJS(DefaultKlinifyConfig)
                  .mergeWith(mergeWithoutLists, mapFromJS(configDocArray[1])),
              };
            }
            return {
              clinicConfig: this.state.clinicConfig,
              klinifyConfig: this.state.klinifyConfig,
            };
          };
          const { klinifyConfig, clinicConfig } = getConfig();
          this.setState({
            clinicConfig,
            klinifyConfig,
            originalConfig: clinicConfig,
            originalKlinifyConfig: klinifyConfig,
            configModifications: Map(),
            klinifyConfigModifications: Map(),
            isSaving: false,
            isClinicConfigChanged: false,
            isLogoChanged: false,
            isUploadFormClear: true,
            isKlinifyConfigChanged: false,
          });
          return true;
        }).catch(() => {
          this.setState({ isSaving: false });
          createErrorNotification(translate('error_saving'));
          return false;
        });
    }
    return Promise.resolve(false);
  }

  /**
   * Get uploaded logo
   * @param { Blob } logoFile Image file
   * @param { string } logoDataUrl Image file
   * @returns {undefined}
   */
  onSaveLogo = (logoFile: Blob, logoDataUrl: string) => {
    this.setState({
      logoDataUrl,
      isClinicConfigChanged: true,
      isLogoChanged: true,
      isUploadFormClear: false,
    });
    saveBlob(logoFile)
      .then((assetObject) => {
        this.updateConfigValue(['clinic', 'logo', 'assetID'], assetObject.id);
      });
  }

  /**
   * Remove clinic logo
   * @returns {undefined}
   */
  onRemoveLogo = () => {
    getConfirmation(translate('remove_logo_confirm'))
      .then(
        () => {
          this.updateConfigValue(['clinic', 'logo', 'assetID'], '');
          this.setState({ isUploadFormClear: true });
        },
        () => {},
      );
  }

  getQueueSettings = () => {
    const queueNumberEnabled = this.state.clinicConfig.getIn(['patient_queue', 'enable_queue_number'], true);
    return (
      <>
        <div className="o-form__subtitle">{translate('queue_number_settings')}</div>
        <Radio
          id="use_queue_no_system"
          label={translate('use_queue_no_system')}
          description={translate('use_queue_no_setting_description')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={queueNumberEnabled.toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'enable_queue_number'], newValue === 'true')}
        />
        <div className="o-form__subtitle">{translate('queue_ticket_settings')}</div>
        <Radio
          id="automtatic_print_queue_number"
          label={translate('automtatic_print_queue_number')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['patient_queue', 'print_ticket_on_arrival'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'print_ticket_on_arrival'], newValue === 'true')}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="ticket_width"
          label={translate('ticket_width')}
          description={translate('deafult_x', { x: 80 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'width'], 80)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'width'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="ticket_top_padding"
          label={translate('ticket_top_padding')}
          description={translate('deafult_x', { x: 15 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'top_padding'], 15)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'top_padding'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="ticket_title_text"
          label={translate('ticket_title_text')}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'title'])}
          type="text"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'title'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="ticket_title_font_size"
          label={translate('ticket_title_font_size')}
          description={translate('deafult_x', { x: 11 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'title_font_size'], 11)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'title_font_size'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="queue_no_font_size"
          label={translate('queue_no_font_size')}
          description={translate('deafult_x', { x: 72 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'queue_number_font_size'], 72)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'queue_number_font_size'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="stage_name_font_size"
          label={translate('stage_name_font_size')}
          description={translate('deafult_x', { x: 24 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'stageName_font_size'], 24)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'stageName_font_size'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="location_name_font_size"
          label={translate('location_name_font_size')}
          description={translate('deafult_x', { x: 13 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'location_font_size'], 13)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'location_font_size'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="ticket_custom_text"
          label={translate('ticket_custom_text')}
          description={translate('ticket_custom_text_description')}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'custom_text'], '')}
          type="text"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'custom_text'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Input
          id="ticket_custom_text_font_size"
          label={translate('ticket_custom_text_font_size')}
          description={translate('deafult_x', { x: 13 })}
          value={this.state.clinicConfig.getIn(['patient_queue', 'ticket_config', 'custom_text_font_size'], 13)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'ticket_config', 'custom_text_font_size'], newValue)}
          disabled={!queueNumberEnabled}
        />
        <Button
          className="o-button o-button--small"
          onClick={() => {
            const encounter = new EncounterModel({
              consult_type: 'ENCOUNTER TYPE',
              location: 'LOCATION',
              queue: [{ type: 'assigned', number: 1 }],
            });
            printQueueTicket(encounter, this.state.clinicConfig);
          }}
          disabled={!queueNumberEnabled}
        >
          {translate('print_test_queue_ticket')}
        </Button>
        <br />
        <br />
        <div className="o-form__subtitle" style={{ marginBottom: 'none' }}>{translate('internal_queue_display_settings')}</div>
        <p className="o-sub-label">
          {translate('internal_queue_display_settings_description')}
          <a href={`${window.location.origin}/#/queue`} target="_blank" rel="noopener noreferrer">&nbsp;here</a>
        </p>
        <br />
        <Radio
          disabled={!queueNumberEnabled}
          id="patient_identifier_to_use"
          label={translate('patient_identifier_to_use')}
          options={[{ label: translate('queue_no'), value: 'queue_number' }, { label: translate('patient_name'), value: 'patient_name' }]}
          value={this.state.clinicConfig.getIn(['patient_queue', 'patient_identifier'], 'patient_name').toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'patient_identifier'], newValue)}
        />
        <div className="o-form__subtitle">{translate('misc_settings')}</div>
        <Radio
          id="patient_overview_enable_sound"
          label={translate('patient_overview_enable_sound')}
          description={translate('alert_overview_setting_description')}
          options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['patient_overview', 'play_alert_on_finished_consult'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient_overview', 'play_alert_on_finished_consult'], newValue === 'true')}
        />
        <Select
          id="patient_overview_sound"
          label={translate('patient_overview_sound')}
          clearable={false}
          description={
            <a
              className="o-text-button o-text-button--contextual"
              onClick={() => playSound(this.state.clinicConfig.getIn(['patient_overview', 'alert_sound'], 'Hornbill.mp3'))}
            >
              {translate('preview')}
            </a>
          }
          options={[
            { label: 'Darter', value: 'Darter.mp3' },
            { label: 'Hornbill', value: 'Hornbill.mp3' },
            { label: 'Jacana', value: 'Jacana.mp3' },
            { label: 'Kingfisher', value: 'Kingfisher.mp3' },
            { label: 'Myna', value: 'Myna.mp3' },
            { label: 'Pelican', value: 'Pelican.mp3' },
            { label: 'Plover', value: 'Plover.mp3' },
            { label: 'Skua', value: 'Skua.mp3' },
          ]}
          value={this.state.clinicConfig.getIn(['patient_overview', 'alert_sound'], 'Hornbill.mp3')}
          onValueChanged={newValue => this.updateConfigValue(['patient_overview', 'alert_sound'], newValue)}
        />
        <Radio
          id="patient_assign_doctor_on_arrival"
          label={translate('assign_doctor_on_arrival')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['patient_queue', 'assign_doctor_on_arrival'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient_queue', 'assign_doctor_on_arrival'], newValue === 'true')}
        />
      </>
    );
  }

  getClinicSettings = () => {
    const logoAssetID = this.state.clinicConfig.getIn(['clinic', 'logo', 'assetID']);
    let logoSrc;
    if (this.state.logoDataUrl && this.state.isLogoChanged) {
      logoSrc = this.state.logoDataUrl;
    } else if (!this.state.isLogoChanged && logoAssetID) {
      logoSrc = `${getBaseApiUrl()}/asset/${logoAssetID}`;
    }
    const patientRequiredValues = this.state.clinicConfig.getIn(['patient', 'requiredFields'], List())
      .reduce((filteredList: List<SelectOption>, v: string) => {
        if (!v) {
          return filteredList;
        }
        return filteredList.concat({ value: v, label: translate(v) });
      }, List()).toArray();
    const pastBillCreationPresetSalesItemsValues = this.state.clinicConfig.get('pastBillCreationPresetSalesItems', List())
      .reduce((filteredList: List<SelectOption>, v: string) => {
        const salesItem = this.props.salesItems.get(v);
        if (!(v && salesItem)) {
          return filteredList;
        }
        return filteredList.concat({ value: v, label: salesItem.get('name') });
      }, List()).toArray();
    return (
      <>
        <div className="o-form__subtitle">{translate('patient_search_settings')}</div>
        <Select
          id="patient_search_sort_order"
          label={translate('sort_order')}
          clearable={false}
          options={[
            { label: translate('case_id'), value: 'case_id' },
            { label: translate('patient_name'), value: 'patient_name' },
          ]}
          value={this.state.clinicConfig.getIn(['patientSearch', 'defaultSorting', 'id'])}
          onValueChanged={newValue => this.updateConfigValue(['patientSearch', 'defaultSorting', 'id'], newValue)}
        />
        <Select
          id="patient_search_sort_order_direction"
          label={translate('sort_order_direction')}
          clearable={false}
          options={[
            { label: translate('ascending'), value: false },
            { label: translate('descending'), value: true },
          ]}
          value={this.state.clinicConfig.getIn(['patientSearch', 'defaultSorting', 'desc'])}
          onValueChanged={newValue => this.updateConfigValue(['patientSearch', 'defaultSorting', 'desc'], newValue)}
        />
        <div className="o-form__subtitle">{translate('patient_profile_settings')}</div>
        <Radio
          id="patient_case_id_autogenerate"
          label={translate('patient_case_id_autogenerate')}
          description={translate('patient_case_id_autogenerate_desc')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['patient', 'case_id', 'autogenerate'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'case_id', 'autogenerate'], newValue === 'true')}
        />
        <Input
          id="patient_case_id_autogenerate_prefix"
          label={translate('patient_case_id_autogenerate_prefix')}
          description={translate('patient_case_id_autogenerate_prefix_desc')}
          value={this.state.clinicConfig.getIn(['patient', 'case_id', 'autogenerate_prefix'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'case_id', 'autogenerate_prefix'], newValue)}
        />
        <MultiSelect
          id="patient_required_fields"
          label={translate('required_fields')}
          description={translate('required_fields_desc')}
          value={patientRequiredValues}
          options={
            CONFIG.PATIENT_REQUIRED_FIELDS
              .map((value: string) => ({ value, label: translate(value) }))
          }
          onChange={newValue => this.updateConfigValue(['patient', 'requiredFields'], List(newValue.map(v => v.value)))}
        />
        <Radio
          id="allow_same_ic"
          label={translate('allow_patients_with_same_ic_to_register')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['patient', 'allow_same_ic'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'allow_same_ic'], newValue === 'true')}
        />
        <Input
          id="case_id_label"
          label={translate('case_id_label')}
          description={translate('case_id_label_desc')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'case_id'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'case_id'], newValue)}
        />
        <Input
          id="postal_code_label"
          label={translate('postal_code_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'postal_code'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'postal_code'], newValue)}
        />
        <Input
          id="coverage_policy_holder_label"
          label={translate('coverage_payor_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'coverage', 'payor'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'coverage', 'payor'], newValue)}
        />
        <Input
          id=""
          label={translate('coverage_policy_holder_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'coverage', 'policyHolder'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'coverage', 'policyHolder'], newValue)}
        />
        <Input
          id="coverage_subscriber_label"
          label={translate('coverage_subscriber_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'coverage', 'subscriber'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'coverage', 'subscriber'], newValue)}
        />
        <Input
          id="coverage_relationship_label"
          label={translate('coverage_relationship_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'coverage', 'relationship'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'coverage', 'relationship'], newValue)}
        />
        <Input
          id="no_coverage_label"
          label={translate('no_coverage_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'coverage', 'no_coverage'], '')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'coverage', 'no_coverage'], newValue)}
        />
        <TagInput
          id="patient_options_ethnicity"
          label={translate('patient_options_ethnicity')}
          value={
            this.state.clinicConfig.getIn(['patient', 'options', 'ethnicity'], List())
              .map(value => ({ value, label: value }))
              .toArray()
          }
          options={[]}
          onChange={newValue => this.updateConfigValue(['patient', 'options', 'ethnicity'], List(newValue.map(v => v.value)))}
        />
        <Input
          id="nationality_label"
          label={translate('nationality_label')}
          value={this.state.clinicConfig.getIn(['patient', 'labels', 'nationality'], 'Nationality')}
          onValueChanged={newValue => this.updateConfigValue(['patient', 'labels', 'nationality'], newValue)}
        />
        <TagInput
          id="patient_options_sex"
          label={translate('patient_options_sex')}
          value={
            this.state.clinicConfig.getIn(['patient', 'options', 'sex'], List())
              .map(value => ({ value, label: value }))
              .toArray()
          }
          options={[]}
          onChange={newValue => this.updateConfigValue(['patient', 'options', 'sex'], List(newValue.map(v => v.value)))}
        />
        <div className="o-form__subtitle">{translate('bill_settings')}</div>
        <Radio
          id="bill_allow_editing"
          label={translate('bill_allow_editing')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['bills', 'allowEditing'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['bills', 'allowEditing'], newValue === 'true')}
        />
        <MultiSelect
          id="pastBillCreationPresetSalesItems"
          label={translate('past_bill_preset_sales_items')}
          value={pastBillCreationPresetSalesItemsValues}
          options={
            this.props.salesItems
              .map(i => ({ value: i.get('_id'), label: i.get('name') }))
              .toList()
              .toArray()
          }
          onChange={newValues => this.updateConfigValue(['pastBillCreationPresetSalesItems'], List(newValues.map(o => o.value)))}
        />
        <Radio
          id="bill_show_sales_items_on_encounter_page"
          label={translate('bill_show_sales_items_on_encounter_page')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['bills', 'showSalesItemsOnEncountersPage'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['bills', 'showSalesItemsOnEncountersPage'], newValue === 'true')}
        />
        <Radio
          id="bill_panel_maximum_claimable_amount_can_proceed"
          label={translate('above_maximum_claimable_amount_can_proceed')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['bills', 'aboveMaximumClaimableAmountCanProceed'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['bills', 'aboveMaximumClaimableAmountCanProceed'], newValue === 'true')}
        />
        <div className="o-form__subtitle">{translate('prescription_settings')}</div>
        <TagInput
          id="prescription_drug_tags"
          label={translate('drug_tags')}
          value={
            this.state.clinicConfig.getIn(['prescription', 'drug_tags'], List())
              .map(unit => ({ value: unit, label: unit }))
              .toArray()
          }
          options={[]}
          onChange={
            values => this.updateConfigValue(['prescription', 'drug_tags'], List(values.map(v => v.value)))
          }
        />
        <TagInput
          id="prescription_dosage_units"
          label={translate('dosage_units')}
          value={
            this.state.clinicConfig.getIn(['prescription', 'dosage_units'], List())
              .map(unit => ({ value: unit, label: unit }))
              .toArray()
          }
          options={[]}
          onChange={
            values => this.updateConfigValue(['prescription', 'dosage_units'], List(values.map(v => v.value)))
          }
        />
        {isKlinifyUser(this.props.user) &&
          <Radio
            id="allow_popup_message"
            label={translate('allow_popup_message')}
            options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
            value={this.state.klinifyConfig.getIn(['prescription', 'allowPopup'], false).toString()}
            onValueChanged={newValue => this.updateConfigValue(['prescription', 'allowPopup'], newValue === 'true', true)}
          />
        }
        <div className="o-form__subtitle">{translate('prescription_label_settings')}</div>
        <Input
          id="prescription_label_width"
          label={translate('prescription_label_width')}
          description={translate('prescription_label_width_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'width'], 102)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'width'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_height"
          label={translate('prescription_label_height')}
          description={translate('prescription_label_height_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'height'], 36)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'height'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_margin_horizontal"
          label={translate('prescription_label_margin_horizontal')}
          description={translate('prescription_label_margin_horizontal_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'margin_horizontal'], 11)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'margin_horizontal'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_margin_vertical"
          label={translate('prescription_label_margin_vertical')}
          description={translate('prescription_label_margin_vertical_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'margin_vertical'], 11)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'margin_vertical'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_padding_top"
          label={translate('prescription_label_padding_top')}
          description={translate('prescription_label_padding_top_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'paddingTop'], 0)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'paddingTop'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_sidebar_width"
          label={translate('prescription_label_sidebar_width')}
          description={translate('prescription_label_sidebar_width_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'sidebarWidth'], 20)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'sidebarWidth'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_label_topbar_height"
          label={translate('prescription_label_label_topbar_height')}
          description={translate('prescription_label_label_topbar_height_desc')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'labelTopbarHeight'], 20)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'labelTopbarHeight'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_header_title_font_size"
          label={translate('prescription_label_header_title_font_size')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'headerTitleFontSize'], 24)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'headerTitleFontSize'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_title_font_size"
          label={translate('prescription_label_title_font_size')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'titleFontSize'], 13)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'titleFontSize'], parseFloat(newValue))}
        />
        <Input
          id="prescription_label_content_font_size"
          label={translate('prescription_label_content_font_size')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'contentFontSize'], 13)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'contentFontSize'], parseFloat(newValue))}
        />
        <Radio
          id="prescription_label_show_header"
          label={translate('prescription_label_show_header')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'showHeader'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'showHeader'], newValue === 'true')}
        />
        <Radio
          id="prescription_label_show_label_topbar"
          label={translate('prescription_label_show_label_topbar')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'showLabelTopbar'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'showLabelTopbar'], newValue === 'true')}
        />
        <Radio
          id="prescription_label_show_sidebar"
          label={translate('prescription_label_show_sidebar')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'showSidebar'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'showSidebar'], newValue === 'true')}
        />
        <Radio
          id="prescription_label_show_field_labels"
          label={translate('prescription_label_show_field_labels')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'showFieldLabels'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'showFieldLabels'], newValue === 'true')}
        />
        <Radio
          id="prescription-label-show-prescribed-duration"
          label={translate('print_prescribed_duration_in_label')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'showPrescribedDuration'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'showPrescribedDuration'], newValue === 'true')}
        />
        <Input
          id="prescription_label_title"
          label={translate('prescription_label_title')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'title'], 'Your Clinic')}
          type="text"
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'title'], newValue)}
        />
        <TextArea
          id="prescription_label_label_topbar"
          label={translate('prescription_label_label_topbar')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'labelTopbar'], '')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'labelTopbar'], newValue)}
        />
        <TextArea
          id="prescription_label_sidebar"
          label={translate('prescription_label_sidebar')}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'sidebar'], 'Your Clinic Information')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'sidebar'], newValue)}
        />
        <Radio
          id="prescription_atdps_integration"
          label={translate('prescription_label_atdps_integration')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'prescription_labels', 'atdpsIntegration'], newValue === 'true')}
        />
        <div className="o-form__subtitle">{translate('master_drug_name_settings')}</div>
        <Radio
          id="prescribing-modal-show-master-drug-name"
          label={translate('prescribing_modal_show_master_drug_name')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['master_drug_info', 'prescribing_modal', 'showMasterDrugName'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['master_drug_info', 'prescribing_modal', 'showMasterDrugName'], newValue === 'true')}
        />
        <div className="o-form__subtitle">{translate('casenote_editor_settings')}</div>
        <Input
          id="casenote_editor_pen_size"
          label={translate('casenote_editor_pen_size')}
          value={this.state.clinicConfig.getIn(['casenote_editor', 'pen_settings', 'size'], 1)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['casenote_editor', 'pen_settings', 'size'], parseFloat(newValue))}
        />
        <div className="o-form__subtitle">{translate('inventory_settings')}</div>
        <Input
          id="low_inventory_warning_threshold"
          label={translate('low_inventory_warning_threshold')}
          description={translate('low_inventory_warning_threshold_desc')}
          value={this.state.clinicConfig.getIn(['inventory', 'lowInventoryWarningThreshold'], 0)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['inventory', 'lowInventoryWarningThreshold'], parseInt(newValue, 10))}
        />
        <Radio
          id="simple_inventory_warnings"
          label={translate('simple_inventory_warnings')}
          options={[{ label: translate('hide'), value: 'true' }, { label: translate('show'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['inventory', 'hideInventoryWarnings'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['inventory', 'hideInventoryWarnings'], newValue === 'true')}
        />
        <Radio
          id="detailed_inventory_warnings"
          label={translate('detailed_inventory_warnings')}
          options={[{ label: translate('hide'), value: 'false' }, { label: translate('show'), value: 'true' }]}
          value={this.state.clinicConfig.getIn(['inventory', 'showDetailedInventoryWarnings'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['inventory', 'showDetailedInventoryWarnings'], newValue === 'true')}
        />
        <Radio
          id="drugs_not_in_stock_cannot_be_dispensed"
          label={translate('drugs_not_in_stock_cannot_be_dispensed')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['inventory', 'blockDispensingNoStockDrugs'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['inventory', 'blockDispensingNoStockDrugs'], newValue === 'true')}
        />
        { isKlinifyUser(this.props.user) &&
          <Radio
            id="show_inventory_mapping_page"
            label={translate('show_inventory_mapping_page')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.klinifyConfig.getIn(['inventory', 'showInventoryMappingPage'], false).toString()}
            onValueChanged={newValue => this.updateConfigValue(['inventory', 'showInventoryMappingPage'], newValue === 'true', true)}
          />
        }
        { isKlinifyUser(this.props.user) &&
          <Radio
            id="show_all_drugs"
            label={translate('show_all_drugs')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.klinifyConfig.getIn(['inventory', 'showAllDrugs'], false).toString()}
            onValueChanged={newValue => this.updateConfigValue(['inventory', 'showAllDrugs'], newValue === 'true', true)}
          />
        }
        <div className="o-form__subtitle">{translate('print_settings')}</div>
        <TextArea
          id="print_header_label"
          label={translate('print_header_label')}
          value={this.state.clinicConfig.getIn(['print', 'header'], '')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'header'], newValue)}
        />
        <TextArea
          id="print_signature_label"
          label={translate('print_signature_label')}
          value={this.state.clinicConfig.getIn(['print', 'signatureLabel'], '')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'signatureLabel'], newValue)}
        />
        <TextArea
          id="print_claims_report_note"
          label={translate('print_claims_report_note')}
          value={this.state.clinicConfig.getIn(['print', 'claimsReportNote'], '')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'claimsReportNote'], newValue)}
        />
        <Radio
          id="print_claims_report_show_diagnosis"
          label={translate('print_claims_report_show_diagnosis')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'claimsReportShowDiagnosis'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'claimsReportShowDiagnosis'], newValue === 'true')}
        />
        <Radio
          id="print_claims_report_show_invoice_number"
          label={translate('print_claims_report_show_invoice_number')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'claimsReportShowInvoiceNumber'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'claimsReportShowInvoiceNumber'], newValue === 'true')}
        />
        <Input
          id="print_claims_report_legend_title"
          label={translate('print_claims_report_legend_title')}
          value={this.state.clinicConfig.getIn(['print', 'claimsReportLegendTitle'], 'Treatment Codes')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'claimsReportLegendTitle'], newValue)}
        />
        <TagInput
          id="print_claims_report_legend_items"
          label={translate('print_claims_report_legend_items')}
          description={translate('print_claims_report_legend_items_desc')}
          value={
            this.state.clinicConfig.getIn(['print', 'claimsReportLegendItems'], List())
              .map(value => ({ value, label: value }))
              .toArray()
          }
          options={[]}
          onChange={newValue =>
            this.updateConfigValue(
              ['print', 'claimsReportLegendItems'],
              List(newValue.map(v => v.value)),
            )
          }
        />
        <Radio
          id="print_claims_report_use_new_panel_categories"
          label={translate('print_claims_report_use_panel_categories')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'usePanelCategoriesSummary'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'usePanelCategoriesSummary'], newValue === 'true')}
        />
        <Radio
          id="print_bill_invoice_show_items"
          label={translate('print_bill_invoice_show_items')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'billInvoiceShowItems'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'billInvoiceShowItems'], newValue === 'true')}
        />
        <Input
          id="print_bill_height"
          label={translate('print_bill_height')}
          description={translate('print_bill_height_desc')}
          value={this.state.clinicConfig.getIn(['print', 'bill', 'height'], undefined)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'bill', 'height'], newValue)}
        />
        <Input
          id="print_bill_margin_top"
          label={translate('print_bill_margin_top')}
          description={translate('print_bill_margin_top_desc')}
          value={this.state.clinicConfig.getIn(['print', 'bill', 'marginTop'], 0)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'bill', 'marginTop'], parseFloat(newValue))}
        />
        <Radio
          id="print_bill_font_size"
          label={translate('print_bill_font_size')}
          options={[{ label: translate('smallest'), value: '0.6' }, { label: translate('small'), value: '0.8' }, { label: translate('normal'), value: '1' }]}
          value={this.state.clinicConfig.getIn(['print', 'bill', 'fontSize'], 1).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'bill', 'fontSize'], parseFloat(newValue))}
        />
        <Radio
          id="print_bill_show_header"
          label={translate('print_bill_show_header')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'bill', 'showHeader'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'bill', 'showHeader'], newValue === 'true')}
        />
        <Input
          id="print_medical_certificate_height"
          label={translate('print_medical_certificate_height')}
          description={translate('print_medical_certificate_height_desc')}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificate', 'height'], undefined)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificate', 'height'], newValue)}
        />
        <Input
          id="print_medical_certificate_margin_top"
          label={translate('print_medical_certificate_margin_top')}
          description={translate('print_medical_certificate_margin_top_desc')}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificate', 'marginTop'], 0)}
          type="number"
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificate', 'marginTop'], parseFloat(newValue))}
        />
        <Radio
          id="print_medical_certificate_font_size"
          label={translate('print_medical_certificate_font_size')}
          options={[
            { label: translate('smallest'), value: '0.6' },
            { label: translate('small'), value: '0.8' },
            { label: translate('normal'), value: '1' },
            { label: translate('large'), value: '1.4' },
            { label: translate('largest'), value: '1.8' },
          ]}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificate', 'fontSize'], 1).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificate', 'fontSize'], parseFloat(newValue))}
        />
        <Radio
          id="print_medical_certificate_show_header"
          label={translate('print_medical_certificate_show_header')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificate', 'showHeader'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificate', 'showHeader'], newValue === 'true')}
        />
        <Radio
          id="print_medical_certificate_show_ic"
          label={translate('print_medical_certificate_show_ic')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificateShowIC'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificateShowIC'], newValue === 'true')}
        />
        <Radio
          id="print_medical_certificate_show_time"
          label={translate('print_medical_certificate_show_time')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificateShowTime'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificateShowTime'], newValue === 'true')}
        />
        <Radio
          id="print_medical_certificate_show_diagnosis"
          label={translate('print_medical_certificate_show_diagnoses')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificateShowDiagnoses'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificateShowDiagnoses'], newValue === 'true')}
        />
        <Radio
          id="print_medical_certificate_show_case_id"
          label={translate('print_medical_certificate_show_case_id')}
          options={[{ label: translate('show'), value: 'true' }, { label: translate('hide'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificateShowCaseID'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificateShowCaseID'], newValue === 'true')}
        />
        <Input
          id="print_medical_certificate_default_employer"
          label={translate('print_medical_certificate_default_employer')}
          description={translate('print_medical_certificate_default_employer_desc')}
          value={this.state.clinicConfig.getIn(['print', 'medicalCertificateDefaultEmployer'], '')}
          onValueChanged={newValue => this.updateConfigValue(['print', 'medicalCertificateDefaultEmployer'], newValue)}
        />
        <Radio
          id="print_allow_custom_claims_invoice_number"
          label={translate('print_allow_custom_claims_invoice_number')}
          options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'allowCustomClaimsReportInvoiceNumber'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'allowCustomClaimsReportInvoiceNumber'], newValue === 'true')}
        />
        <Radio
          id="print_allow_custom_claims_report_date"
          label={translate('print_allow_custom_claims_report_date')}
          options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['print', 'allowCustomClaimsReportDate'], true).toString()}
          onValueChanged={newValue => this.updateConfigValue(['print', 'allowCustomClaimsReportDate'], newValue === 'true')}
        />
        {
          isKlinifyUser(this.props.user) &&
          <React.Fragment>
            <div className="o-form__subtitle">{translate('premium_features_settings')}</div>
            <Radio
              id="enable_patient_outreach_campaigns_section"
              label={translate('enable_patient_outreach_campaigns')}
              options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
              value={this.state.klinifyConfig.getIn(['premium_features_enabled', 'patient_outreach_campaigns', 'enabled'], false).toString()}
              onValueChanged={newValue => this.updateConfigValue(['premium_features_enabled', 'patient_outreach_campaigns', 'enabled'], newValue === 'true', true)}
            />
          </React.Fragment>
        }
        <div className="o-form__subtitle">{translate('misc_settings')}</div>
        <Input
          id="date_format"
          label={translate('date_format')}
          description={`e.g. ${moment().format(this.state.clinicConfig.getIn(['date_format']))} (Use 'DD' for date,  'MM' for month', 'YYYY' for year)`}
          value={this.state.clinicConfig.getIn(['date_format'], 'DD/MM/YYYY')}
          onValueChanged={newValue => this.updateConfigValue(['date_format'], newValue)}
        />
        <TagInput
          id="locations"
          label={translate('locations')}
          value={
            this.state.clinicConfig.getIn(['clinic', 'locations'], List())
              .map(location => ({ value: location, label: location }))
              .toArray()
          }
          options={[]}
          onChange={newValue =>
            this.updateConfigValue(['clinic', 'locations'], List(newValue.map(v => v.value)))
          }
        />
        <TagInput
          id="sales_item_types"
          label={translate('sales_item_types')}
          value={
            this.state.clinicConfig.getIn(['mobileWebappSettings', 'salesItemTypes'], List())
              .map(value => ({ value, label: value }))
              .toArray()
          }
          options={[]}
          onChange={newValue =>
            this.updateConfigValue(
              ['mobileWebappSettings', 'salesItemTypes'],
              List(newValue.map(v => v.value)),
            )
          }
        />
        <TagInput
          id="medical_certificate_reasons"
          label={translate('medical_certificate_reasons')}
          value={
            this.state.clinicConfig.getIn(['medical_certificate', 'options', 'reason'], List())
              .map(value => ({ value, label: value }))
              .toArray()
          }
          options={[]}
          onChange={newValue =>
            this.updateConfigValue(
              ['medical_certificate', 'options', 'reason'],
              List(newValue.map(v => v.value)),
            )
          }
        />
        <Input
          id="default_time_chit_length"
          label={translate('default_time_chit_length')}
          description={translate('default_time_chit_length_desc')}
          type="number"
          value={this.state.clinicConfig.getIn(['timeChit', 'defaultLength'], 180)}
          onValueChanged={newValue => this.updateConfigValue(['timeChit', 'defaultLength'], newValue)}
        />
        <Input
          id="currency_shorthand_label"
          label={translate('currency_shorthand_label')}
          type="text"
          value={this.state.clinicConfig.get('currency_shorthand_label', 'RM')}
          onValueChanged={newValue => this.updateConfigValue(['currency_shorthand_label'], newValue)}
        />
        <Input
          id="tip_likelihood"
          label={translate('tip_likelihood')}
          description={translate('tip_likelihood_desc')}
          type="number"
          value={this.state.clinicConfig.getIn(['mobileWebappSettings', 'tipLikelihood'], 0) * 100}
          onValueChanged={newValue => this.updateConfigValue(['mobileWebappSettings', 'tipLikelihood'], parseInt(newValue, 10) / 100)}
        />
        { isKlinifyUser(this.props.user) &&
          <Radio
            id="hide_add_notes_templates_text_buttons"
            label={translate('hide_add_notes_templates_text_buttons')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.klinifyConfig.getIn(['patient_action_button', 'hide_add_notes_templates_text_buttons'], false).toString()}
            onValueChanged={newValue => this.updateConfigValue(['patient_action_button', 'hide_add_notes_templates_text_buttons'], newValue === 'true', true)}
          />
        }
        {
          isKlinifyUser(this.props.user) &&
          <Radio
            id="show_demo_banner"
            label={translate('show_demo_banner')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.klinifyConfig.getIn(['showDemoBanner'], false).toString()}
            onValueChanged={newValue => this.updateConfigValue(['showDemoBanner'], newValue === 'true', true)}
          />
        }
        <Input
          id="mykad_url"
          label={translate('mykad_url')}
          type="text"
          value={this.state.clinicConfig.get('mykad_url', '')}
          onValueChanged={newValue => this.updateConfigValue(['mykad_url'], newValue)}
        />
        <Radio
          id="categorize_and_view_incomplete_encounters"
          label={translate('categorize_and_view_incomplete_encounters')}
          options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
          value={this.state.clinicConfig.getIn(['encounters', 'categorize_and_view_incomplete_encounters'], false).toString()}
          onValueChanged={newValue => this.updateConfigValue(['encounters', 'categorize_and_view_incomplete_encounters'], newValue === 'true')}
        />
        <div className="o-form__subtitle">{translate('encounter_settings')}</div>
        <Checkbox
          id="checkbox-export-encounter"
          onValueChanged={(value, isChecked) =>
            this.updateConfigValue(['encounters', 'export_sections', value], isChecked)
          }
          options={
            Object.keys(this.state.clinicConfig.getIn(['encounters', 'export_sections'], Map()).toJS())
              .map(value => ({ value, label: translate(value) }))
          }
          value={
            Object.keys(
              this.state.clinicConfig.getIn(['encounters', 'export_sections'], Map())
                .filter(value => value === true)
                .toJS(),
            )
          }
          label={translate('sections_to_be_exported')}
          multiColumn
        />
        <Input
          id="encounter_label"
          label={translate('encounter_label')}
          value={this.state.clinicConfig.getIn(['encounters', 'labels', 'encounter'], 'Encounter')}
          onValueChanged={newValue => this.updateConfigValue(['encounters', 'labels', 'encounter'], newValue)}
        />
        <Input
          id="encounters_label"
          label={translate('encounters_label')}
          value={this.state.clinicConfig.getIn(['encounters', 'labels', 'encounters'], 'Encounters')}
          onValueChanged={newValue => this.updateConfigValue(['encounters', 'labels', 'encounters'], newValue)}
        />
        <Input
          id="clinic_name"
          label={translate('clinic_name')}
          value={this.state.clinicConfig.getIn(['clinic', 'name'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'name'], newValue)}
        />
        <TextArea
          id="clinic_address"
          label={translate('clinic_address')}
          value={this.state.clinicConfig.getIn(['clinic', 'address'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'address'], newValue)}
        />
        <Input
          id="clinic_email"
          type="email"
          label={translate('clinic_email')}
          value={this.state.clinicConfig.getIn(['clinic', 'email'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'email'], newValue)}
        />
        <Input
          id="clinic_phone"
          type="tel"
          label={translate('clinic_phone')}
          value={this.state.clinicConfig.getIn(['clinic', 'phone'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'phone'], newValue)}
        />
        <Input
          id="clinic_fax"
          type="text"
          label={translate('clinic_fax')}
          value={this.state.clinicConfig.getIn(['clinic', 'fax'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'fax'], newValue)}
        />
        <Input
          id="clinic_website"
          type="text"
          label={translate('clinic_website')}
          value={this.state.clinicConfig.getIn(['clinic', 'website'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'website'], newValue)}
        />
        <TextArea
          id="clinic_misc"
          label={translate('clinic_misc')}
          value={this.state.clinicConfig.getIn(['clinic', 'misc'], '')}
          onValueChanged={newValue => this.updateConfigValue(['clinic', 'misc'], newValue)}
        />
        { isKlinifyUser(this.props.user) &&
          <React.Fragment>
            <div className="o-form__subtitle">{translate('scheduling_settings')}</div>
            <Radio
              id="schedule_appointments"
              label={translate('schedule_appointments')}
              options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
              value={this.state.klinifyConfig.getIn(['scheduling', 'scheduleAppointments'], false).toString()}
              disabled
            />
            <Radio
              id="schedule_e_consultation"
              label={translate('schedule_e_consultation')}
              options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
              value={this.state.klinifyConfigModifications.getIn(['scheduling', 'scheduleTeleConsultation'], false).toString()}
              onValueChanged={(newValue) => {
                const keyArray = ['scheduling', 'scheduleTeleConsultation'];
                const value = newValue === 'true';
                this.setState({
                  // $FlowFixMe
                  klinifyConfig: this.state.klinifyConfig.setIn(keyArray, value),
                  klinifyConfigModifications: this.state.klinifyConfigModifications.setIn(keyArray, value),
                }, () => {
                  this.updateConfigValue(['scheduling', 'scheduleAppointments'], newValue === 'true', true);
                });
              }}
            />
          </React.Fragment>
        }
        {
          // Below settings are available to premium and klinify users
          (isKlinifyUser(this.props.user) || this.state.klinifyConfig.getIn(['pharmaconnect', 'premium_features_enabled'], false)) &&
          <React.Fragment>
            <div className="o-form__subtitle">{translate('pharmaconnect_settings')}</div>
            <Radio
              id="enable_pharmaconnect"
              label={translate('enable_pharmaconnect')}
              options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
              value={this.state.clinicConfig.getIn(['pharmaconnect', 'enable'], false).toString()}
              onValueChanged={newValue => this.updatePremiumConfigValues(['pharmaconnect', 'enable'], newValue === 'true')}
            />
            {isKlinifyUser(this.props.user) &&
              <Radio
                id="enable_pharmaconnect_settings"
                label={translate('enable_pharmaconnect_premium_settings')}
                options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
                value={this.state.klinifyConfig.getIn(['pharmaconnect', 'premium_features_enabled'], false).toString()}
                onValueChanged={newValue => this.updateConfigValue(['pharmaconnect', 'premium_features_enabled'], newValue === 'true', true)}
              />}
            <Radio
              id="enable_pharmaconnect_modal"
              label={translate('enable_pharmaconnect_modal_ad')}
              options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
              value={this.state.clinicConfig.getIn(['pharmaconnect', 'modal_ad'], false).toString()}
              onValueChanged={newValue => this.updatePremiumConfigValues(['pharmaconnect', 'modal_ad'], newValue === 'true')}
            />
            <Radio
              id="enable_pharmaconnect_splash_ad"
              label={translate('enable_pharmaconnect_splash_ad')}
              options={[{ label: translate('enable'), value: 'true' }, { label: translate('disable'), value: 'false' }]}
              value={this.state.clinicConfig.getIn(['pharmaconnect', 'splash_ad'], true).toString()}
              onValueChanged={newValue => this.updatePremiumConfigValues(['pharmaconnect', 'splash_ad'], newValue === 'true')}
            />
          </React.Fragment>
        }
        {
          isKlinifyUser(this.props.user) &&
          <React.Fragment>
            <div className="o-form__subtitle">{translate('server_setting')}</div>
            <Radio
              id="area_of_operation"
              label={translate('area_of_operation')}
              options={[
                { label: translate('malaysia'), value: 'malaysia' },
                { label: translate('singapore'), value: 'singapore' },
                { label: translate('indonesia'), value: 'indonesia' },
              ]}
              value={this.state.klinifyConfig.getIn(['server_setting', 'area_of_operation'], 'malaysia').toString()}
              onValueChanged={newValue => {
                this.updateConfigValue(['server_setting', 'area_of_operation'], newValue, true)
              }}
            />
          </React.Fragment>
        }
        <div className="o-form__subtitle">{translate('clinic_logo')}</div>
        { logoAssetID &&
          <div className="u-margin-top--standard">
            <img src={logoSrc} alt="logo preview" style={{ maxWidth: 300 }} />
          </div>
        }
        <div className="u-margin-top--standard u-flex-row">
          <ClinicLogoForm
            onSaveLogo={this.onSaveLogo}
            isFormClear={this.state.isUploadFormClear}
          />
          {logoAssetID &&
            <Button
              className="o-button o-button--small o-button--danger"
              onClick={this.onRemoveLogo}
            >
              {translate('remove_logo')}
            </Button>
          }
        </div>
      </>
    );
  }

  renderChildContent = () => {
    if (this.props.isQueueSetting) {
      return this.getQueueSettings();
    }
    return this.getClinicSettings();
  }

  getPageName = () => {
    if (this.props.isQueueSetting) {
      return translate('queue_settings');
    }
    return translate('clinic_settings');
  }

  /**
   * Renders the component.
   *
   * @return {string} - HTML markup for the component
   */
  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator alignCenter />;
    }

    return (
      <ContentTransition className="o-scrollable-container" style={{ height: '100vh' }}>
        <SavePrompt
          when={this.state.isClinicConfigChanged || this.state.isKlinifyConfigChanged}
          onSaveClicked={() => this.onSaveClicked()}
        />
        <h1 data-public className="o-title">{this.getPageName()}</h1>
        <Keypress
          className="u-margin--standard u-margin-bottom--4ws"
          onEnterPressed={() => this.onSaveClicked()}
        >
          {this.renderChildContent()}
          <div className="u-margin-top--standard">
            <SaveButton
              isSaving={this.state.isSaving}
              disabled={!(this.state.isClinicConfigChanged || this.state.isKlinifyConfigChanged)}
              onClick={() => this.onSaveClicked()}
            />
          </div>
        </Keypress>
      </ContentTransition>
    );
  }
}

export default Settings;
