import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Img } from 'glamorous';
import { List, Map } from 'immutable';
import AnnouncementModal, { getSeenKey } from '../../modals/announcementModal';
import translate from '../../../utils/i18n';
import { updateClinicSettings } from '../../../utils/api';
import type { Config, MapValue, User } from './../../../types';
import { updateClinicConfig } from '../../../utils/db';


const Banner = (
  <a
    href="https://www.klinify.com/medadvisor-campaigns"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Img
      css={{ maxWidth: '100%' }}
      src="../../../../static/images/medAdvisor_campaigns_app_anner.png"
      alt="MedAdvisor Banner"
    />
  </a>
);

const AnnouncementContent = (
  <>
    <span className="u-strong">
      By clicking “Acknowledge”, you acknowledge that:
    </span>
    <br /><br />
    <p>
      The MedAdvisor Campaigns feature will be enabled. This allows Klinify to
      run MedAdvisor Campaigns for your clinic.
    </p>
    <br />
    <p>
      All available MedAdvisor Campaigns will be activated for your clinic. If
      you would like to deactivate any campaign, you may do so from the
      “MedAdvisor Campaigns” page.
    </p>
    <br />
    <p>
      Klinify is allowed to share anonymised information with MedAdvisor of all
      drugs dispensed (this includes drugs that are not referenced in any
      MedAdvisor Campaigns) starting from the date of agreement. Patient
      details, clinic details and doctor details will be anonymised - your patient’s,
      clinic and doctor’s personal information will <b className="u-strong">not</b> be shared with MedAdvisor.
    </p>
    <br />
    <p>
      Klinify is allowed to send SMS to patients based on the campaign rules in
      a MedAdvisor Campaign, unless the campaign has been deactivated.
    </p>
    <br />
    <p>
      For all new MedAdvisor Campaigns, you will receive a notification 2
      working days before any SMS is sent from the campaign.
    </p>
    <br />
    <p>
      All messages will be signed off with the name of your clinic/doctor.
      Klinify or MedAdvisor will not be mentioned in the SMS.
    </p>
    <br />
    <p>
      You approve the content and the list of patients to whom it is being sent.
    </p>
  </>
);


const MedadvisorEnabledMessage = (isEnabled: boolean) => (
  <>
    <p>MedAdvisor Campaigns are targeted messages delivered to your patients via SMS,
      to improve quality of use of medicines, health literacy and adherence.
    </p>
    <br />
    <p>Studies have shown that these campaigns have helped increase patient returns
      and improve adherence.
    </p>
    <br />
    <p>This feature is completely <b className="u-strong">free of charge</b> and the campaigns can be
      deactivated at any point in time.
    </p>
    <br />
    <p>Do note that your patient’s, clinic and doctor’s personal information
      will <b className="u-strong">not</b> be shared with MedAdvisor.
    </p>
    <br />
    { isEnabled
      ? <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <p className="u-strong">The MedAdvisor Campaigns feature has been enabled!
        </p>
        <br />
        <p className="u-strong">You may view this under SMS Campaigns &gt; MedAdvisor Campaigns.</p>
        </div>
      : <p className="u-strong">To enable the MedAdvisor Campaigns feature, an admin user must login
        to the Klinify app and click “Acknowledge” on the pop-up message that appears.
        </p>
    }
  </>
);

/**
 * Render the Announcement content
 * @returns {HTMLElement} html element
 */
const MedadvisorAnnouncementModal = ({ user, show, isMedadvisorEnabled, isAdminUser, isMedAdvisorEnabledInfoModal, isMedAdvisorAgreementModalForAdmin, updateConfig, config }: {
  show: boolean,
  isAdminUser: boolean,
  isMedadvisorEnabled: boolean,
  user: User,
  updateConfig: (config: Config) => void,
  config: Config,
  isMedAdvisorAgreementModalForNonAdmin: boolean,
  isMedAdvisorEnabledInfoModal: boolean,
  isMedAdvisorAgreementModalForAdmin: boolean,
}) => {
  const [medAdvisorEnabled, setMedAdvisorEnabled] = useState(isMedadvisorEnabled);
  const program = !isMedAdvisorAgreementModalForAdmin ? `medadvisor-agreement-${user.get('id')}` : 'medadvisor-agreement';
  const isSeen = localStorage.getItem(getSeenKey(program)) === 'true';
  const [showAnnouncement, setShowAnnouncement] = useState(show && !isSeen);
  const history = useHistory();

  /**
   * update medadvisor clinic config
   * @param {Array<string>} key config key
   * @param {MapValue} values value
   * @returns {Promise<any>}
   */
  const updateMedadvisorClinicConfig = (key: Array<string>, values: MapValue) => {
    const configUpdates = Map()
      .setIn(key, values)
      .toJS();
    return updateClinicConfig(config.toJS(), configUpdates, updateConfig);
  }

  /**
   * Hides announcement and shows medAdvisor enabled modal if not acknowledged
   * Closes or pushes history to campaign page if clicked after enabling/acknowledging MedAdvisor
   * @returns {void}
   */
  const onButtonOrCloseClick = () => {
    if (isMedAdvisorAgreementModalForAdmin) {
      setMedAdvisorEnabled(true);
      const settings = {
        enable_medadvisor_campaign_sets: true,
      };
      return updateMedadvisorClinicConfig(['optional_features_enabled', 'medadvisor_campaigns', 'enabled'], {
        enabled_by: { timestamp: Date.now(), user_id: user.get('id') },
        enabled: true,
      }).then(() => {
        updateClinicSettings(settings);
      });
    }
    if (isMedAdvisorEnabledInfoModal) {
      const featureAckBy = config.getIn(['optional_features_enabled', 'medadvisor_campaigns', 'feature_ack_by'], List());
      return updateMedadvisorClinicConfig(['optional_features_enabled', 'medadvisor_campaigns', 'feature_ack_by'],
        featureAckBy.concat(user.get('id')));
    }
    return Promise.resolve(true);
  };
  if (!showAnnouncement) return null;
  return (
    <AnnouncementModal
      program={program}
      show={showAnnouncement || show}
      modalTitle="MEDADVISOR FEATURE UPDATE"
      id="medadvisor-agreement"
      modalStyle={{ minWidth: 500 }}
      footerButtonLabel={isMedAdvisorAgreementModalForAdmin ? translate('acknowledge') : translate('okay')}
      explicitCloseOnly
      noCloseButton={!medAdvisorEnabled}
      onExplicitClose={() => onButtonOrCloseClick().then(() => setShowAnnouncement(false))}
      image={Banner}
      onModalClose={() => setShowAnnouncement(false)}
      buttonClassName="o-button-green"
    >
      {isMedAdvisorAgreementModalForAdmin ?
        AnnouncementContent
        :
        MedadvisorEnabledMessage(isMedAdvisorEnabledInfoModal)}
    </AnnouncementModal>
  );
};

export default MedadvisorAnnouncementModal;
