import React, { Component } from 'react';

import StatelessModal from './../modals/statelessModal';
import Radio from './../inputs/radio';
import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import ModalFooter from './../modals/modalFooter';
import translate from './../../utils/i18n';
import Button from './../buttons/button';

import type { MapValue } from './../../types';

type Props = {
  exportPrintAttributes: {
    appendTotalsToExport?: boolean,
    title: string,
    isLandscape: boolean,
  },
  isPrintExportButtonEnabled: boolean,
  updateExportPrintAttribute: (key: string, value: MapValue) => void,
  onExportClicked: () => void,
  onPrintClicked: () => void,
};

type State = {
  exportPrintModalVisible: boolean,
}

/**
 * Renders table export/ print options
 * @returns {React.PureComponent} The rendered component
*/
class TableExportPrintOptions extends Component<Props, State> {
  static defaultProps = {
  }

  /**
   * Creates an instance of TableExportPrintOptions.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      exportPrintModalVisible: false,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { isLandscape, appendTotalsToExport, title } = this.props.exportPrintAttributes;
    return (
      <StatelessModal
        id="export_or_print"
        buttonLabel={translate('export_or_print')}
        buttonClass="o-button o-button--small"
        title={translate('print_export_options')}
        setVisible={isVisible => this.setState({ exportPrintModalVisible: isVisible })}
        visible={this.state.exportPrintModalVisible}
        explicitCloseOnly
        dataPublicHeader
      >
        <section className="u-margin--standard">
          <Input
            id="title"
            label={translate('title')}
            value={title}
            required
            onValueChanged={value => this.props.updateExportPrintAttribute('title', value)}
          />
          {appendTotalsToExport !== undefined && (
            <Radio
              id="append_totals_to_export"
              label={translate('append_totals_to_export')}
              value={appendTotalsToExport.toString()}
              onValueChanged={value => this.props.updateExportPrintAttribute('appendTotalsToExport', value === 'true')}
              options={[{ value: 'true', label: translate('yes') }, { value: 'false', label: translate('no') }]}
            />
          )}
          <Radio
            id="print_layout"
            label={translate('print_layout')}
            value={isLandscape.toString()}
            onValueChanged={value => this.props.updateExportPrintAttribute('isLandscape', value === 'true')}
            options={[{ value: 'false', label: translate('portrait') }, { value: 'true', label: translate('landscape') }]}
          />
        </section>
        <ModalFooter>
          <Button
            className="o-button o-button--small u-margin-right--half-ws"
            disabled={this.props.isPrintExportButtonEnabled}
            onClick={() => this.props.onExportClicked()}
            dataPublic
          >{ translate('export') }
          </Button>
          <SaveButton
            disabled={this.props.isPrintExportButtonEnabled}
            onClick={() => this.props.onPrintClicked()}
            label={translate('print')}
            className="o-button--small u-margin-right--half-ws"
            dataPublic
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default TableExportPrintOptions;
