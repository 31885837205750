/* eslint-disable */
module.exports = {
  databaseName: 'klinifydev',
  debug: true,
  metricsToDisplay: 'blood_pressure', // Comma separated IDs of metric_type documents. Default is empty. eg. "pain,height,weight"
  usage_statistics: {
    latest_version_used_by_user: {
      // This should be updated after login and if the user is using a new version of the app.
      // time_first_used is tracked so that we can give the user notifications about the release for
      // x amount of time after they first use it.
      // e.g. clinic_admin: { version: '1.11.1', time_first_used: 1235 }
    },
    inventory_map_acknowledgement: {
      // This should be updated after user acknowledge that they read the inventory mapping instructions.
      // e.g. clinic_admin: { accepted: true }
    }
  },

  // Categories
  categories: {
    notes_category: "0_KLINIFY_NOTES",
    uncategorized_category: "3_bbbb510a798680c18cf490b45d019f37",
    lab_test_category: "c0d4ca961b49dce46a38c78ef601c6e7",
    prescriptions_category: "1d1334f436c3129b4b9020e4cf0279aa",
    mri_category: "c0d4ca961b49dce46a38c78ef601c6e7"
  },
  clinic: {
    name: '', // Used for official purposes such as populating the claim invoice from field.
    locations: [], // A list of potential locations for encounters in the clinic.
    // A list of doctors on duty in the format { practitionerID, location, onDuty },
    // with location being optional and onDuty being a boolean. The reason to have an explicit check
    // on onDuty is so that if a practitioner is not on duty we can still track the last location.
    on_duty: [],
    logo: {
      assetID: ''
    },
    address: '',
    email: '',
    phone: '',
    fax: '',
    website: '',
    misc: '',
  },
  currency_shorthand_label: 'RM', // e.g. RM, $, SGD. Intended to precede prices, e.g. RM140, $140
  date_format: 'DD-MM-YYYY', // Make it something sensible (e.g. can be parsed by Date() or Moment.js)
  diagnoses: {
    options: [],
  },
  document_templates: {
    print_templates: {
      patient_receipt: undefined,
      medical_certificate: undefined,
      time_chit: undefined,
      prescription_labels: undefined,
    },
  },
  encounters: {
    labels: {
      encounter: 'Encounter',
      encounters: 'Encounters',
    },
    export_sections: {
      patient_card: true,
      allergies: true,
      encounter_notes: true,
      prescriptions: true,
      medical_certificates: true,
      time_chits: true
    },
    history_section: {
      default_view_selected: 'single',
      default_page_size_selected: 10,
      columns: {
        date_and_time: true,
        encounter_type: true,
        encounter_status: false,
        doctor: true,
        symptoms: true,
        diagnoses: true,
        notes: true,
        rx_summary: true,
        rx_name: false,
        rx_dosage: false,
        rx_reason: false,
        rx_notes: false,
        rx_price: false,
        rx_qty: false,
        rx_total: false,
        mc_time_chit: true,
        documents: false,
        dispense_summary: false,
        dispense_price: false,
        dispense_total: false,
        sales_item_summary: false,
        sales_item_price: false,
        sales_item_total: false,
        lab_test: false,
        lab_test_id: false,
        lab_vendor: false,
        lab_sale_price: false,
        lab_results: false,
        total_fee: true,
      },
    },
    'categorize_and_view_incomplete_encounters': false,
  },

  // Templates
  templates: {
    default_new_note_template: "KLINIFY_DEFAULT_NOTE_TEMPLATE",
    form_template: '5f5550ceedc5a51a908aef7ed412733d',
    prescription_template: "1d1334f436c3129b4b9020e4cf00474a",
  },

  // Form stuffs
  formSettings: {
    marginTop: 100, // px
    marginBottom: 100, // px
  },

  inventory: {
    lowInventoryWarningThreshold: 10, // The number at which a display for low inventory should show to the user.
    hideInventoryWarnings: true, // If true no inventory warnings are shown at all.
    showDetailedInventoryWarnings: true,
    blockDispensingNoStockDrugs: true,
    showTransactionHistoryPage: false,
  },

  dispensation: {
    dispensing_order: 'expiry_date',
  },

  // Global Settings ###
  global_settings: {
    custom_notes_enabled: true,
    custom_notes_title: 'Custom Notes',
  },

  bills: {
    pastBillCreationPresetSalesItems: [], // IDs of sales items to be added when creating a past bill.
    allowEditing: true,
    showMalay: true,
    showSalesItemsOnEncountersPage: false,
    aboveMaximumClaimableAmountCanProceed: false,
  },

  medical_certificate: {
    options: {
      reason: ['Unfit for Duty', 'Unfit for Attending School', 'Unfit for Physical Training'],
    },
  },

  timeChit: {
    defaultLength: 180, // Minutes
  },

  patient: {
    caseId: {
      autogenerate: false,
      autogenerate_prefix: '',
    },
    labels: {
      case_id: 'Clinic Code',
      coverage: {
        payor: 'Panel Name',
        policy: 'Policy Name',
        policyHolder: 'Policy Holder',
        subscriber: 'Panel Employee',
        relationship: 'Panel Relation',
        no_coverage: 'No Panel (Cash)',
        no_policy: 'No Policy'
      },
      nationality: 'Citizenship',
      postal_code: 'Postal Code',
    },
    allow_same_ic: false,
    options: {
      citizenship: ['Citizen', 'Permanent Resident', 'Non-citizen'],
      ethnicity: [
        'Malay',
        'Chinese',
        'Punjabi',
        'Sikh',
        'Indian',
        'Kadazan',
        'Bajau',
        'Murut',
        'Iban',
        'Bidayuh',
        'Melanau',
        'Natives from Peninsular',
        'Natives from Sabah',
        'Natives from Sarawak',
        'Others',
      ],
      sex: ['Male', 'Female'],
    },
    requiredFields: [],
  },

  labs: {
    tags: ['lab']
  },

  patient_overview: {
    play_alert_on_finished_consult: true,
    alert_sound: 'Hornbill.mp3',
  },

  patient_queue: {
    assign_doctor_on_arrival: true,
    enable_queue_number: true,
    print_ticket_on_arrival: false,
    patient_identifier: 'patient_name',
    ticket_config: {
      width: 80,
      top_padding: 15,
      title: null,
      title_font_size: 11,
      queue_number_font_size: 72,
      stageName_font_size: 24,
      location_font_size: 13,
      custom_text: '',
      custom_text_font_size: 13,
    }
  },

  patient_page_cards: [
    {name: 'econsult_info', show: true},
    {name: 'patient_profile', show: true},
    {name: 'latest_vitals', show: true},
    {name: 'allergies', show: true},
    {name: 'current_encounter', show: true},
    {name: 'encounter_history', show: true},
    {name: 'patient_alert', show: true},
  ],

  // For table columns settings
  sortable_table_columns: {
    cost_report: [],
    inventory_items: [],
    inventory_manual_mapping: [],
    coverage_payors: [],
    patient_campaigns: [],
    campaign_jobs: [],
    panel_payments: [],
    lab_requests: [],
    accounts_receivable: [],
    payment_history: [],
    inventory_reports:[],
    stock_take: [],

    inventory_map: [],

    inventory_write_off: [],
    inventory_damaged: [],
    inventory_expired: [],
    inventory_loan_out: [],
    inventory_transfer_out: [],
    inventory_missing: [],
    inventory_dispensing_error: [],
    inventory_consumed: [],

    inventory_bulk_write_off: [],
    inventory_bulk_damaged: [],
    inventory_bulk_expired: [],
    inventory_bulk_loan_out: [],
    inventory_bulk_transfer_out: [],
    inventory_bulk_missing: [],
    inventory_bulk_dispensing_error: [],
    inventory_bulk_consumed: [],

    medadvisor_campaign_sets: [],

    encounter_stages: [],
    stage_sales_items: [],
    encounter_flows: [],
    stage_to_flow: [],
    waiting_encounter: [],
    inprogress_encounter: [],
    billing_encounter: [],
    completed_encounter: [],
    scheduled_consults: [],
    incomplete_encounter: [],
    appointment_requests: [],
  },

  patientSearch: {
    defaultSorting: { id: 'case_id', desc: true }, // Follows the react-table spec. id should match patient doc field.
  },

  prescription: {
    dosage_units: ["Tablet"],
    drug_tags: ["Poison", "Psychotropic"],
    frequencies: [
      { "name": "stat dose", "short_code": "" },
      { "name": "every hour", "short_code": "" },
      { "name": "every alternate day", "short_code": "" },
      { "name": "every 8 hours", "short_code": "" },
      { "name": "every 6 hours", "short_code": "" },
      { "name": "every 4 hours", "short_code": "" },
      { "name": "before meals", "short_code": "" },
      { "name": "every morning", "short_code": "OM" },
      { "name": "at bedtime", "short_code": "ON" },
      { "name": "at alternate day", "short_code": "" },
      { "name": "after meals", "short_code": "" },
      { "name": "5 times a day", "short_code": "" },
      { "name": "4 times a day", "short_code": "QID" },
      { "name": "3 times a day", "short_code": "TDS" },
      { "name": "2 times a day", "short_code": "BD" },
      { "name": "1 time per week", "short_code": "" },
      { "name": "1 time a day", "short_code": "OD" },
      { "name": "when necessary", "short_code": "PRN" },
    ],
    durations:  [
      [1, "day"],
      [2, "day"],
      [3, "day"],
      [4, "day"],
      [5, "day"],
      [6, "day"],
      [7, "day"],
      [1, "week"],
      [2, "week"],
      [3, "week"],
      [4, "week"],
      [5, "week"],
      [6, "week"],
      [7, "week"],
      [1, "month"],
      [2, "month"],
      [3, "month"],
      [4, "month"],
      [5, "month"],
      [6, "month"],
      [7, "month"],
    ],
  },

  print: { // All measurements in millimetres
    header: "Go to settings to add your own header",
    signatureLabel: "Go to settings to add your own signature",
    claimsReportNote: 'Put footer notes here',
    billInvoiceShowItems: true,
    medicalCertificateShowIC: true,
    medicalCertificateShowTime: true,
    medicalCertificateShowDiagnoses: false,
    medicalCertificateShowCaseID: false,
    medicalCertificateDefaultEmployer: '',
    bill: {
      height: undefined, // If undefined then it defaults to print page height.
      marginTop: 0,
      showHeader: true,
      fontSize: 1, // Set the base em of the MC. Default should be 1.
    },
    medicalCertificate: {
      height: undefined, // If undefined then it defaults to print page height.
      marginTop: 0,
      showHeader: true,
      fontSize: 1, // Set the base em of the MC. Default should be 1.
    },
    prescription_labels: {
      width: 102,
      height: 36,
      margin_horizontal: 11,
      margin_vertical: 1,
      paddingTop: 0,
      showFieldLabels: false, // E.g. Name: Bob Bob, Dosage: blah blah,
      title: 'Your Clinic',
      sidebar: 'Your clinic\'s info goes here',
      showHeader: true, // Incorporates title + date
      showSidebar: true,
      sidebarWidth: 20,
      headerTitleFontSize: 24,
      titleFontSize: 13,
      contentFontSize: 13,
      labelTopbarHeight: 20,
      showLabelTopbar: false,
      labelTopbar: '',
      showPrescribedDuration: true,
    },
    allowCustomClaimsReportDate: false,
    allowCustomClaimsReportInvoiceNumber: true,
    usePanelCategoriesSummary: false,
  },

  master_drug_info: {
    prescribing_modal: {
      showMasterDrugName: true,
    }
  },

  casenote_editor: {
    pen_settings: {
      size: 1,
    }
  },

  symptoms: {
    options: []
  },

  patient_tags: {
    options: []
  },

  panel_categories: {
    options: []
  },

  // Web Application Settings ###
  webapp_settings: {

    // General Settings ###
    scheduleApptOnCreate: true,
    language: 'ENGLISH',
    showScheduleTomorrow: true, // Toggles schedule tomorrow on the sidebar.
    showRegistrationButton: true, // Toggles the registration button on the sidebar.
    printFromApi: false,
    splitPDFsOnUpload: false,
    enableUserHints: true,

    // Form Settings ###
    defaultRadioValue: 'No', // Yes, No or Unspecified

    // Search/Patient Registration Settings ###
    customSearchField: 'ic', // Sets the field to be searched for patients besides name. Uses Patient Doc fields ('ic, 'tel', etc.)
    newPatientIdentifier: 'patient_name', // The field that is entered into the search/add patient input. Uses Patient Doc fields ('ic, 'tel', etc.)
    requiredFields: 'patient_name, ic', // Fields that cannot be empty when creating a new patient. Comma-seperated. Uses Patient Doc fields ('ic, 'tel', etc.)

    // Accountant Specific Settings ###
    isAccountant: false,
  },

  // Web Application Layout Settings
  webapp_layout_settings: {
    patient_summary_ordering: [
      "prescriptions",
      "allergy",
      "immunizations",
      "family_history",
      "medical_complaints",
      "current_medications",
      "custom_notes",
      "dynamic_forms",
      "metrics",
    ],
  },

  // Mobile Web App specific settings
  mobileWebappSettings: {
    requireOTP: false,
    // For layout each array is used as ordering for the items. Show determines if the item is
    // visible to the user.
    layout: {
      patientData: [
        { id: 'allergy', show: true },
        { id: 'consultation_history', show: true },
        { id: 'immunization', show: false },
        { id: 'family_history', show: false },
        { id: 'medical_complaint', show: false },
        { id: 'medication', show: false },
        { id: 'custom_note', show: false },
      ],
      consultData: [
        { id: 'diagnosis', show: false },
        { id: 'metric', show: false },
        { id: 'prescription', show: false },
        { id: 'medical_certificate', show: false },
        { id: 'procedure_request', show: false },
        { id: 'referral', show: false },
        { id: 'form', show: false },
        { id: 'casenotes', show: false },
      ],
    },
    salesItemTypes: ['Service', 'Other'],
    tipLikelihood: 0.2,
  },

  use_legacy_scheduling: false, // If true this disables encounters and displays patients scheduled for today as per the old scheduling spec.
  mykad_url: 'https://local.myidreader.com:48180',
  // 'https://localhost:2020/BioPakWeb/ReadMyKad', for kridentia
  // https://localhost:15000/mykad-test/securemetrics/mykad for securemetrics test
  // https://<server>:15000/mykad-test/kridentia  to test kridentia
  // to test securemetrics (card_type is mykad | mykid | error)
  // mykad_vendor: 'securemetrics', // kridentia or securemetrics. Will guess if not set.
  suppliersForIntegration: [ 'zuellig' ], // list of suppliers which can be integrated.

  optional_features_enabled: {
    medadvisor_campaigns: {
      enabled: false,
      enabled_by: {},
      feature_ack_by: [],
    },
  },
  pharmaconnect: {
    enable: false,
    modal_ad: false,
  },
  reviewed_stages: [],
  access_management: {
    user_management: {
      users: {
        sub_items: {
          create: true,
          assign_user_group: true,
        },
        enabled: true, 
      },
      user_groups: {
        sub_items: {
          create: true,
          change_permissions: true,
          rename: true,
        },
        enabled: true,
      },
      enabled: true,
    }
  },
};
