import { List, Map, Set } from 'immutable';
import { flattenDeep, isNil, omitBy, startCase, isEqual, difference, isEmpty } from 'lodash';
import moment from 'moment';

import { isToday, prettifyDate, prettifyTime } from './time';
import { convertNumberToPrice, getConfig } from './utils';
import { UNICODE, APPOINTMENT_FLOW_FALLBACK_ENABLED } from './../constants';

import { fetchModel } from '../utils/db';
import { debugPrint, logMessage } from '../utils/logging';
import { getPatientCoPayment } from '../utils/coveragePayors';
import { salesItemToBillItem, voidBillWithTransactions } from '../utils/billing';
import { updateModel, setDebugModeData } from './../actions';
import { saveModelsFactory, getStore, saveModelsAPIFactory } from '../utils/redux';

import SalesItemModel from '../models/salesItemModel';
import PatientModel from '../models/patientModel';
import BillModel from '../models/billModel';
import CoveragePayorModel from '../models/coveragePayorModel';
import EncounterModel, { EncounterAttributes, StageInfo, StageEvent, StageEventType } from '../models/encounterModel';
import PractitionerModel from '../models/practitionerModel';
import EncounterFlowModel from '../models/encounterFlowModel';

import type ReceivableModel from './../models/receivableModel';
import type PatientStubModel from './../models/patientStubModel';
import type EncounterStageModel from '../models/encounterStageModel';
import type AppointmentModel from '../models/appointmentModel';
import type BillItemModel from '../models/billItemModel';

import type { EncounterTooltip, TableFlagType, Config, State, MapValue, Dispatch, Model, SelectOption, APIResponse } from './../types';
import { handleApiError } from './response';
import { getModelMapFromList } from './models';
import translate from './i18n';
import { createErrorNotification, createSuccessNotification } from './notifications';
import { saveEncounterRelatedModels } from './api';
import { printQueueTicket } from './print';
import { playSound } from './audio';

/**
 * Get tooltip info for arrival
 * @param {EncounterModel} encounter Encounter data
 * @returns {string} An object with flag information.
 */
export const getIncompleteEncounterTooltipContent = (encounter: EncounterModel) => { // eslint-disable-line import/prefer-default-export
  if (encounter.highestEventIs('arrived')) {
    return `Patient arrived on ${prettifyDate(encounter.getArrivalTime())} at ${prettifyTime(encounter.getArrivalTime())}`;
  }
  if (encounter.highestEventIs('started')) {
    return `Consultation was started on ${prettifyDate(encounter.getStartTime())} at ${prettifyTime(encounter.getStartTime())}`;
  }
  if (encounter.highestEventIs('finished_consult')) {
    return `Consultation was completed on ${prettifyDate(encounter.getConsultFinishTime())} at ${prettifyTime(encounter.getConsultFinishTime())}`;
  }
  if (encounter.highestEventIs('completed')) {
    return `Patient arrived on ${prettifyDate(encounter.getArrivalTime())} at ${prettifyTime(encounter.getArrivalTime())}, bill finalised today at ${prettifyTime(encounter.getCompletedTime())}`;
  }
  return `Consultation cancelled on ${prettifyDate(encounter.getLastEventTime())} at ${prettifyTime(encounter.getLastEventTime())}`;
};

/**
 * Check and Get Incomplete Encounter flag info
 * @param {EncounterModel} encounter Encounter data
 * @returns {EncounterTooltip} An object with flag information.
 */
const getIncompleteEncounterFlag =
(encounter: EncounterModel): EncounterTooltip | null | undefined => (
  (encounter && !encounter.isToday()) ?
    {
      header: 'Incomplete Encounter',
      content: getIncompleteEncounterTooltipContent(encounter),
    }
    : null);

/**
 * Check and Get Outstanding Encounter flag info
 * @param {EncounterModel} encounter Encounter data
 * @param {PatientStubModel} patient Patient data
 * @param {List<ReceivableModel>} outstandingReceivables List of Outstanding Payments
 * @returns {EncounterTooltip} An object with flag information.
 */
const getOutstandingPaymentFlag = (
  encounter: EncounterModel,
  patient: PatientStubModel,
  outstandingReceivables: List<ReceivableModel>,
): EncounterTooltip | null | undefined => {
  const outstandingPayments = outstandingReceivables ?
    outstandingReceivables.filter(receivable => (
      receivable &&
      encounter &&
      receivable.get('patient_id') === encounter.get('patient_id')
    )) : List();
  const totalOutstandingAmount = outstandingPayments.reduce((sum, payment) => payment.get('amount_due') + sum, 0);
  return outstandingPayments.size ?
    {
      header: 'Outstanding Payment',
      content: `${patient.get('patient_name', UNICODE.EMDASH)} has an oustanding payment of ${convertNumberToPrice(totalOutstandingAmount)}`,
    }
    : null;
};

/**
 * Get flag info for arrival
 * @param {EncounterModel} encounter Encounter data
 * @param {PatientStubModel} patient Patient data
 * @param {List<ReceivableModel>} outstandingReceivables List of Outstanding Payments
 * @returns {object} An object with flag information.
 */
export function getEncounterFlag( // eslint-disable-line import/prefer-default-export
  encounter: EncounterModel,
  patient: PatientStubModel,
  outstandingReceivables: List<ReceivableModel>,
): TableFlagType {
  const IncompleteEncounterFlag = getIncompleteEncounterFlag(encounter);
  const outstandingPaymentFlag = getOutstandingPaymentFlag(
    encounter,
    patient,
    outstandingReceivables,
  );

  return {
    active: !!(IncompleteEncounterFlag || outstandingPaymentFlag),
    color: 'red',
    tooltips: [IncompleteEncounterFlag, outstandingPaymentFlag].filter(e => !!e),
  };
}

/**
 * Tries to get the specified SalesItem from the Redux store, and if that fails tries to retrieve
 * it from the database. If that fails then an error will be thrown.
 * @param {string} salesItemID A sales item ID.
 * @param {List<SalesItemModel>} salesItems salesItems
 * @returns {Promise<SalesItemModel>}
 */
export function getSalesItemModel(salesItemID: string,
  salesItems: List<SalesItemModel>): Promise<SalesItemModel> {
  const salesItem = salesItems.find(item => item.get('_id') === salesItemID);
  if (salesItem) {
    return Promise.resolve(salesItem);
  }
  logMessage(`SalesitemID not found, trying to find in database.: ${salesItemID}`);
  return fetchModel(salesItemID, model => getStore().dispatch(updateModel(model)))
    .catch(error => handleApiError({ error }));
}

/**
 * Creates a bill and billItems for the given encounter ID.
 * @param {string} encounterID An encounter ID.
 * @param {PatientModel} patient patient
 * @param {List<{salesItem: SalesItemModel, quantity: number}>} salesItemInfo salesItems and its quantity
 * @param {List<CoveragePayorModel>} coveragePayors coverage payors
 * @returns {Promise<Array<Model>>} An array featuring a billModel and any necessary BillItemModels
 */
function createBill(encounterID: string, patient: PatientModel,
  salesItemInfo: List<{salesItem: SalesItemModel, quantity: number}>, coveragePayors: List<CoveragePayorModel>): Promise<Array<Model>> {
  const coPayment = getPatientCoPayment(patient, coveragePayors);
  const bill = new BillModel({
    encounter_id: encounterID,
    patient_id: patient.get('_id'),
    coverage_payor_id: patient.getCoveragePayor(),
    coverage_policy_id: patient.getCoveragePolicy(),
    co_payment: coPayment,
  });
  if (salesItemInfo.size) {
    return Promise.resolve(salesItemInfo.map(({ salesItem, quantity }) =>
      salesItemToBillItem(salesItem, quantity, patient.get('_id'), bill.get('_id'))).push(bill).toArray());
  }
  return Promise.resolve([bill]);
}

/**
  * Returns list of sales Items linked to the encounter flow passed-in
  * @param {List<SalesItemModel>} salesItems Sales items
  * @param {EncounterFlowModel} encounterFlow selected encounter flow model
  * @param {List<EncounterStageModel>} encounterStagesMap encounter stages Map
  * @param {string} consultType consult_type
  * @returns {List<SalesItemModel>}
  */
export const getSalesItemsFromEncounterType = (
  salesItems: List<SalesItemModel>,
  encounterFlow?: EncounterFlowModel | undefined,
  encounterStagesMap?: Map<string, EncounterStageModel> | undefined,
  consultType?: string,
): Promise<List<{salesItem: SalesItemModel, quantity: number}>> => {
  if (!consultType && !encounterFlow) return Promise.resolve(List());
  if (consultType) {
    return getSalesItemModel(consultType, salesItems)
      .then(salesItem => (salesItem ? List([salesItem]) : List()))
      .catch(() => List());
  }
  const stages: Array<string> = encounterFlow?.get('stages') || [];
  const salesItemMap = getModelMapFromList(salesItems);
  const salesItemModels = stages.reduce((relatedSalesItemInfo, stageId) => {
    const stage = encounterStagesMap?.get(stageId);
    if (stage) {
      return relatedSalesItemInfo.concat(stage.getSalesItemInfo(salesItemMap));
    }
    logMessage('Stage not found.');
    return relatedSalesItemInfo;
  }, List());
  return Promise.resolve(salesItemModels);
};

/**
 * Returns the assigned queue number for the next encounter
 * @param {List<EncounterModel>} encounters list of encounters
 * @returns {number}
 */
export const getNextQueueNumber = (encounters: List<EncounterModel>) => {
  // todaysEncounters: Encounters that are created today, no need to consider prev day encounters while assigning
  const todaysEncounters = encounters.filter(encounter => isToday(encounter.getArrivalTime()));
  if (!todaysEncounters.size) {
    return {
      number: 1,
      type: 'assigned',
    };
  }
  const lastAssignedQueueNumber = todaysEncounters.reduce((lastAssigned, e) => {
    const assignedQueueNumber = e.getQueueNumber(true);
    if (assignedQueueNumber &&
      !Number.isNaN(assignedQueueNumber) &&
      assignedQueueNumber > lastAssigned) {
      return Number(assignedQueueNumber);
    }
    return lastAssigned;
  }, 0);
  return {
    number: lastAssignedQueueNumber + 1,
    type: 'assigned',
  };
};

/**
 * Save encounter and related models.
 * @param {PatientStubModel} patientStub patient model to save.
 * @param {EncounterModel} encounter encounter model to save.
 * @param {BillModel} bill bill to be saved for this encounter.
 * @param {SalesItemModel} salesItem sales item for creation of bill item.
 * @param {Array<BillItemModel>} billItems bill items to be saved for this encounter.
 * @param {Function} onMissingDocsResolved function to run on missing doc resolve and succesful save.
 * @returns {SaveModels}
 */
export function saveEncounterAndRelatedModels(
  patientStub: PatientStubModel,
  encounter: EncounterModel,
  bill: BillModel,
  salesItem?: SalesItemModel,
  billItems?: Array<BillItemModel>,
  onMissingDocsResolved?: (models: Array<Model> | null) => void,
) {
  const billItemModels = billItems?.length ? billItems : (salesItem && [salesItemToBillItem(salesItem, patientStub.get('_id'), bill.get('_id'))]);

  /**
 * Function to run after save attempt
 * @param {APIResponse} savedModels api response data
 * @returns {SaveModels}
 */
  const resolvedFn = (savedModels: APIResponse<EncounterModel | BillModel | BillItemModel>): any => {
    if (savedModels.status === 400 && savedModels.error && savedModels.msg === 'DUPLICATE_QUEUE') {
      const store = getStore();
      const { encounters }
      = store.getState() as State;
      const queueNumber = getNextQueueNumber(encounters);
      const encounterAttrs = { ...encounter.attributes };
      const modEncounterAttrs = Object.assign(encounterAttrs, { queue: [queueNumber] });
      const encounterModel = new EncounterModel(modEncounterAttrs);
      return saveEncounterAndRelatedModels(patientStub, encounterModel, bill, salesItem, billItems)
        .then(models => models);
    }
    if (!savedModels.unavailable && !savedModels.error && Array.isArray(savedModels)) {
      return savedModels;
    }
    return null;
  };
  return saveModelsAPIFactory(getStore().dispatch)(() =>
    saveEncounterRelatedModels(
      patientStub,
      encounter,
      bill,
      billItemModels,
      getStore().dispatch,
      (savedModels) => {
        if (onMissingDocsResolved) {
          return onMissingDocsResolved(resolvedFn(savedModels));
        }
        return resolvedFn(savedModels);
      },
    ))
    .then(resolvedFn);
}

/**
 * Checks settings for print queue tocket on arrival.
 * If true, then prints the ticket
 * @param {EncounterModel} encounter EncounterModel
 * @param {Config} config Clininc Config
 * @param {List<SalesItemModel>} salesItems sales items to check consult type name
 * @returns {void}
 */
export const printQueueTicketonArrival =
  (encounter: EncounterModel, config: Config, salesItems: List<SalesItemModel>) => {
    if (config.getIn(['patient_queue', 'enable_queue_number'], true) &&
    config.getIn(['patient_queue', 'print_ticket_on_arrival'], false)) {
      printQueueTicket(encounter, config, salesItems);
    }
  };

/**
 * Creates an encounter with a consult type and doctor specified.
 * @param {PatientModel} patient patient
 * @param {List<{salesItem: SalesItemModel, quantity: number}>} salesItemInfo salesItems and its quantity from stage doc
 * @param {List<CoveragePayorModel>} coveragePayors coverage payors
 * @param {Partial<EncounterAttributes>} encounterAttributes encounter status
 * @returns {SaveModels}
 */
export function createEncounterAndBillModels(
  patient: PatientModel, salesItemInfo: List<{salesItem: SalesItemModel, quantity: number}>, coveragePayors: List<CoveragePayorModel>,
  encounterAttributes: Partial<EncounterAttributes> = {},
) {
  const encounter = new EncounterModel({
    patient_id: patient.get('_id'),
    ...encounterAttributes,
  }, true);
  return createBill(encounter.get('_id'), patient, salesItemInfo, coveragePayors)
    .then((models) => {
      const bill = models.find(m => m.get('type') === 'bill');
      if (!bill) {
        throw new Error('Bill was not created and cant be referenced'); // Not really possibly but Flow doesn't know that.
      }
      models.push(patient, encounter.set({
        bill_id: bill.get('_id'),
      }));

      return List(models);
    });
}

/**
   * Returns the name of the salesItem referenced by the given consult type id
   * @param {string} consultTypeId The id value of the consult type
   * @param {Immutable.List} salesItems A List of SalesItemModels.
   * @returns {string} The consult type name.
   */
export function getConsultTypeName(
  consultTypeId: string,
  salesItems: List<SalesItemModel>,
): string {
  const salesItem = salesItems.find(i => i.get('_id') === consultTypeId);
  return salesItem ? salesItem.get('name') : '';
}

/**
 * Gets the default doctor and location based on config and practitioners in the DB. If only one dr
 * or location than these are used as defaults. If only one dr on duty in config than this is used
 * as the default. Otherwise the defaults are set to undefined.
 * @param {Config} config The config map.
 * @param {List<PractitionerModel>} practitioners A list of PractitionerModels.
 * @returns {{}}
 */
export function getDefaultDoctorAndLocation(
  config: Config, practitioners: List<PractitionerModel>,
) {
  let doctor;
  let location;
  if (practitioners.size === 1) {
    const practitioner = practitioners.first() as undefined | PractitionerModel;
    doctor = practitioner ? practitioner.get('_id') : undefined;
  }
  if (config.getIn(['clinic', 'locations'], List()).size === 1) {
    location = config.getIn(['clinic', 'locations'], List()).first();
  }
  const onDuty = config.getIn(['clinic', 'on_duty'], List())
    .filter(i => i.get('onDuty', false));
  const firstItem = onDuty.first();
  if (firstItem && practitioners.some(p => p.get('_id') === firstItem.get('practitionerID'))) { // Make sure the onduty doctor isn't deleted/hidden.
    doctor = onDuty.size > 1 ? undefined : firstItem.get('practitionerID');
  }
  return { doctor, location };
}

/**
 * Returns true if the practitioner is on duty.
 * @param {Config} config App config
 * @param {string} practitionerID Practitioner ID
 * @returns {boolean}
 */
export function isOnDuty(config: Config, practitionerID: string) {
  const onDutyItem = config.getIn(['clinic', 'on_duty'], List())
    .filter(i => i.get('onDuty', false))
    .find(i => i.get('practitionerID') === practitionerID);
  return onDutyItem !== undefined;
}

/**
  * Returns fields for creating encounter doc from AppointmentModel
  * @param {string} encounterStatus encounter status
  * @param {string | undefined} consultType consult type or encounter flow _id
  * @param {string | undefined} doctor  doctor for encounter
  * @param {string | undefined} location location for encounter
  * @param {string | undefined} appointmentId the appointment id if creating from appointment
  * @param {Map<string, EncounterFlowModel>} encounterFlowModel Selected encounter flow model
  * @param {Map<string, EncounterStageModel>} encounterStageMap map of encounter stages and _id
  * @param {AppointmentModel} appointment Appointment Model
  * @returns {Object}
  */
export const getEncounterAttributes = (
  encounterStatus: StageEventType,
  consultType: string | undefined,
  doctor: string | undefined,
  location: string | undefined,
  appointmentId: string | undefined,
  encounterFlowModel: EncounterFlowModel | undefined,
  encounterStageMap: Map<string, EncounterStageModel>,
  appointment?: AppointmentModel,
): Partial<EncounterAttributes> => {
  const now = (new Date()).valueOf();
  if (!APPOINTMENT_FLOW_FALLBACK_ENABLED && encounterFlowModel) {
    return {
      flow: {
        flow_id: encounterFlowModel.get('_id'),
        name: encounterFlowModel.get('name'),
        stages: encounterFlowModel.get('stages', []).reduce((linkedStage: List<StageInfo>, stageId: string, index: number) => {
          const stageInfo = encounterStageMap?.get(stageId);
          if (stageInfo && stageInfo.isVisible()) {
            // _name is added only for indexing stages and not saved to db.
            // Hence ignoring it here.
            // @ts-ignore
            return linkedStage.push({
              name: stageInfo.get('name'),
              stage_id: stageId,
              // Set status of first stage as arrived
              occurrences: index === 0 ? [{
                events: [{ type: encounterStatus, time: now }],
                doctor,
                location,
              }] : [],
            });
          }
          debugPrint('Stage linked with flow not found');
          return linkedStage;
        }, List()).toArray(),
      },
      appointment_id: appointmentId,
      schedule_time: now,
      encounter_events: [
        { type: encounterStatus, time: now },
      ],
    };
  }
  return {
    consult_type: consultType,
    doctor,
    location,
    schedule_time: now,
    appointment_id: appointmentId,
    encounter_events: [
      { type: encounterStatus, time: now },
    ],
    ...appointment?.getEncounterAttributes(encounterFlowModel),
  };
};

/**
 * Get the total number of patients who are queued to the doctor
 * @param {List<EncounterModel>} encounters List of encounter models
 * @param {string} doctorID Doctor ID
 * @returns {string}
 */
export const getTotalNumberOfQueuedPatients = (
  encounters: List<EncounterModel>,
  doctorID: string,
) => {
  const filteredEncounters = encounters.filter(encounter => encounter.isToday() &&
    !encounter.containsEvent('cancelled') &&
    (encounter.highestEventIs('arrived') || encounter.highestEventIs('started')) &&
    (encounter.getValue('doctor') === doctorID));
  const total = filteredEncounters.size;
  return ` - ${total} ${translate(total === 1 ? 'patient' : 'patients')}`;
};

/**
 * Returns true if the encounter has flow same as encoubnter flow model
 * @param {EncounterModel} encounter EncounterModel
 * @param {EncounterFlowModel} encounterFlow EncounterFlowModel
 * @returns {boolean}
 */
export const isEncounterFlowEqual =
  (encounter?: EncounterModel, encounterFlow?: EncounterFlowModel) => {
    const existingStages = encounter?.getActiveStages().map(stage => stage.stage_id).toArray().sort();
    const newStages = (encounterFlow?.get('stages') || []).slice().sort();
    return isEqual(existingStages, newStages);
  };

/**
  * Handle the model updates needed to void a bill.
  * Change bill id and flow attribute in encounter model
  * The returned models will be the result of SaveModels.
  * StageInfo added if any will be removed on changing flow
  * @param {EncounterModel} encounter encounter to change flow_id
  * @param {EncounterFlowModel} encounterFlow New flow selected
  * @param {Map<string, EncounterStageModel>} encounterStageMap Map of available stages
  * @param {BillModel} bill BillModel
  * @param {List<PatientModel>} patients List of patients
  * @param {List<SalesItemModel>} salesItems List of SalesItems
  * @param {List<CoveragePayorModel>} coveragePayors List of coveragePayors
  * @param {dispatch} dispatch Redux dispatch
  * @returns {Promise<List<Models>>}
  */
const changeEncounterFlow = (
  encounter: EncounterModel,
  encounterFlow: EncounterFlowModel,
  encounterStageMap: Map<string, EncounterStageModel>,
  bill: BillModel,
  patients: List<PatientModel>,
  salesItems: List<SalesItemModel>,
  coveragePayors: List<CoveragePayorModel>,
  dispatch: Dispatch,
): Promise<List<Models>> => voidBillWithTransactions(
  bill,
  saveModelsFactory(dispatch),
  true,
  List(),
)
  .then(async () => {
    createSuccessNotification(translate('bill_voided'));
    const patient = patients.find(p => p.get('_id') === encounter.get('patient_id'));
    if (!patient) throw new Error('Patient not found');
    getSalesItemsFromEncounterType(salesItems, encounterFlow, encounterStageMap)
      .then((relatedSalesItems) => {
        let encounterAttributes = getEncounterAttributes(
          'arrived',
          undefined,
          undefined,
          undefined,
          undefined,
          encounterFlow,
          encounterStageMap,
        );
        encounterAttributes = {
          ...encounter.attributes, // Keep _id, _rev, edited-by, queue & schedule time
          ...encounterAttributes, // updating encounter flow related info
          encounter_events: [{ ...encounter.get('encounter_events')[0] }], // Keeping only the arrived time, because the patient will be moved to waiting room of 1st stage on flow change
        };
        createEncounterAndBillModels(patient, relatedSalesItems, coveragePayors, encounterAttributes)
          .then((models) => {
            saveEncounterAndRelatedModels(
              patient,
              models.find(model => model.get('type') === 'encounter'),
              models.find(model => model.get('type') === 'bill'),
              undefined,
              models.filter(model => model.get('type') === 'bill_item').toArray(),
            );
            createSuccessNotification(translate('flow_changed'));
          });
      });
  })
  .catch(() => createErrorNotification(translate('bill_void_error')));

/**
  * Handle the model updates needed to void a bill.
  * Change bill id and flow attribute in encounter model
  * The returned models will be the result of SaveModels.
  * @param {EncounterModel} encounter encounter to change flow_id. This will be updated one in case of sort
  * @param {EncounterFlowModel} encounterFlow New flow selected
  * @param {Map<string, EncounterStageModel>} encounterStageMap Map of available stages
  * @param {boolean} flowChanged Will be true if a new encounter flow is selected and false incase of edit
  * @param {EncounterModel} encounterToUpdate Encounter model to be updated
  * @returns {Promise<List<Models>>}
  */
export const updateEncounterFlow = (
  encounter: EncounterModel,
  encounterFlow: EncounterFlowModel,
  encounterStageMap: Map<string, EncounterStageModel>,
  flowChanged: boolean,
  encounterToUpdate: EncounterModel,
) => {
  const store = getStore();
  const { bills, patients, salesItems, coveragePayors, billItems }
  = store.getState() as State;
  const bill = bills.find(b => b.get('_id') === encounter.get('bill_id'));
  if (!bill) throw new Error('Bill was not created and cant be referenced');
  if (flowChanged) { // Flow change
    return changeEncounterFlow(
      encounter, encounterFlow, encounterStageMap, bill, patients, salesItems, coveragePayors,
      store.dispatch,
    );
  }
  const isFlowEqual = isEncounterFlowEqual(encounterToUpdate, encounterFlow);
  if (isFlowEqual) { // Stage reorder/Sort
    return saveModelsFactory(getStore().dispatch)([encounter]).then(() =>
      createSuccessNotification(translate('flow_updated')));
  }
  // Stage removal/ addition
  const encounterStageIds = encounterToUpdate.getActiveStages().map(s => s.stage_id).toArray();
  const flowStages = encounterFlow.get('stages') || [];
  const stagesRemoved = difference(encounterStageIds, flowStages);
  const stagesAdded = difference(flowStages, encounterStageIds);
  if (stagesRemoved.length || stagesAdded.length) {
    const stageModels = stagesAdded.map(stageId => encounterStageMap.get(stageId));
    const salesItemMap: Map<string, SalesItemModel> = getModelMapFromList(store.getState().salesItems);
    let billItemsToUpdate: List<BillItemModel> = List();
    if (stagesAdded.length) {
      // Creates bill items if new stages are added t the flow and nothing is removed
      const billItemsToCreate: List<BillItemModel> = stageModels.reduce((_billItems, stage) => {
        const salesItemsForStage = stage?.getSalesItemInfo(salesItemMap);
        const newbillItems = salesItemsForStage?.map(({ salesItem, quantity }) =>
          salesItemToBillItem(salesItem, quantity, encounter.get('patient_id'), encounter.get('bill_id')));
        return newbillItems ? _billItems.concat(newbillItems) : _billItems;
      }, List());
      billItemsToUpdate = billItemsToUpdate.concat(billItemsToCreate);
    }
    if (stagesRemoved.length) {
      const existingBillItems = billItems.filter(bi => bi.get('bill_id') === bill.get('_id'));
      const stageModelsRemoved = stagesRemoved.map(stageId => encounterStageMap.get(stageId));
      const billItemsToDelete = stageModelsRemoved.reduce((_billItems, stage) => {
        const salesItemsForStage = stage?.getSalesItemInfo(salesItemMap);
        const linkedBillItems = salesItemsForStage?.reduce((_linkedBillItems, { salesItem, quantity }) => {
          const linkedBillItem = existingBillItems.find(bi => bi.get('sales_item_id') === salesItem.get('_id') && bi.get('quantity') === quantity);
          return linkedBillItem ? _linkedBillItems.push(linkedBillItem) : _linkedBillItems;
        }, List());
        return _billItems.concat(linkedBillItems);
      }, List());
      const updatedBillItems = billItemsToDelete.map(bi => bi.set('_deleted', true)).toArray();
      billItemsToUpdate = billItemsToUpdate.concat(updatedBillItems);
    }
    // Saving encounter doc, because the stages can be sorted as well as edited.
    return saveModelsFactory(getStore().dispatch)([...billItemsToUpdate.toArray(), encounter]).then(() =>
      createSuccessNotification(translate('flow_updated')));
  }
  // Unlikely to reach here.
  // Flow should be neither changed, sorted nor edited to execute this section
  return Promise.resolve();
};

/**
 * Returns a string to place as initial value in encounter notes field,
 * if app is rolled back after new version of encounter/appointment doc
 * @param {List<MapValue>} notesInfo Attributes from which the string will be created
 * @param {boolean} addNewLine If true, adds value in next line after key
 * @returns {string}
 * TODO: This is a backward compatibility layer.
 * Remove when the app is stable with new doc structure
 */
export const getNotesStringFromObject = (notesInfo: List<MapValue>, addNewLine: boolean = false) =>
  notesInfo.reduce((notes, attr) =>
    `${notes}${Object.keys(attr).map(key =>
      `${startCase(key)}: ${addNewLine ? `\n${attr[key]}` : attr[key]}\n`).join('')}-----\n`,
  '');

/**
 * Returns comma separated values for the keys passed in from the StageInfo
 * @param {StageInfo} stage stage in encounter model
 * @param {string} key key to look for in the stage
 * @returns {string}
 */
export const getConcatenatedValueFromStage = (
  stage: StageInfo | undefined,
  key: 'doctor' | 'location',
): List<string> => {
  if (stage && stage.occurrences?.length) {
    return stage.occurrences.reduce((reducedVal, occuranceInfo) => {
      const value = occuranceInfo[key];
      if (value) {
        return reducedVal.add(value);
      }
      return reducedVal;
    }, Set()).toList();
  }
  return List();
};

/**
 * Returns concatenated string of values of key passed in from encounter stages info
 * @param {List<StageInfo>} stages encounter model
 * @param {string} key Key to check in flow stages
 * @returns {List<string>}
 */
export const getConcatenatedValueFromStages = (stages: List<StageInfo | undefined>, key: 'doctor' | 'location')
 : List<string> => {
  if (!stages.size) return List();
  return stages.reduce((reduced, stage) => {
    if (stage && stage.occurrences?.length) {
      return reduced.concat(getConcatenatedValueFromStage(stage, key));
    }
    return reduced;
  }, Set()).toList();
};

/**
 * Returns formatted event time of passed type
 * @param {Array<EncounterEvents>} events Encounter events
 * @param {string} eventType Event type
 * @returns {string}
 */
const getTimeAtEvent = (events: Array<{type: string, time: number}>, eventType: string) => {
  const event = events.find(ev => ev.type === eventType);
  if (event && event.time) {
    return moment(event.time).format(`${getConfig().get('date_format')} h:mm a`);
  }
  return null;
};

/**
 * Returns List of objects with info available in stages
 * This object is used to create encounter notes,
 * if app is rolled back after encounter stages feature deployment.
 * @param {EncounterModel} encounter encounter model
 * @param {List<PractitionerModel>} practitioners List of practioners
 * @returns {List<Object>}
 */
export const flattenStageInfo = (encounter: EncounterModel,
  practitioners: List<PractitionerModel>): List<MapValue> => {
  const stages = encounter.getActiveStages();
  if (!stages.size) return List();
  return stages.reduce((notesInfo, stage) => {
    const stageInfo = {
      stage: stage.name,
      notes: stage.notes || '',
    };
    if (stage.occurrences?.length) {
      const occurrenceInfo = stage.occurrences.reduce((reduced, occurrence) => {
        const practitioner = practitioners.find(p => p.get('_id') === occurrence.doctor);
        const infoToShow = {
          startTime: getTimeAtEvent(occurrence.events, 'started'),
          completeTime: getTimeAtEvent(occurrence.events, 'completed'),
          doctorAssigned: practitioner ? practitioner.get('name', '') : occurrence.doctor,
          location: occurrence.location,
        };
        return reduced.push(omitBy(infoToShow, isNil));
      }, List());
      return notesInfo.push([Object.assign(occurrenceInfo.get(0) || {}, stageInfo)]);
    } return notesInfo.push([stageInfo]);
  }, List());
};

/**
 * Returns array of timestamps, at which the `eventType` has ocured in the stageInfo
 * @param {string} eventType Event type
 * @param {StageInfo} stageInfo stage info in encounter doc
 * @returns {List<number>}
 */
export const getOccurredEventTimes = (eventType: string, stageInfo?: StageInfo): List<number> => {
  const stageEvents = stageInfo?.occurrences.reduce((events, occurence) =>
    events.concat(occurence.events), List()).flatten(true) || List() as List<StageEvent>;
  return stageEvents.reduce((filteredEvents, event) => {
    if (event.type === eventType) {
      return filteredEvents.push(event.time);
    } return filteredEvents;
  }, List());
};

/**
 * Returns comma separated list of formatted time the passed event type has occured in the stage
 * @param {string} eventType Event type
 * @param {StageInfo} stageInfo stage info in encounter doc
 * @returns {Array<{type: string, time: number}>}
 */
export const getStageEventsAt = (eventType: string, stageInfo?: StageInfo): string =>
  getOccurredEventTimes(eventType, stageInfo)
    .map(eventTime => moment(eventTime).format(`${getConfig().get('date_format')} h:mm a`))
    .join(', ');

/**
 * Returns locations of completed encounter stages.
 * If APPOINTMENT_FLOW_FALLBACK_ENABLED is true, returns the location field of Model
 * @param {EncounterModel} encounter EncounterModel
 * @param {StageInfo} stageInfo Doctor name of this stage info will be returned
 * @returns {string}
 */
export const getCompletedStageLocations = (
  encounter: EncounterModel,
) => {
  if (encounter.has('location') && APPOINTMENT_FLOW_FALLBACK_ENABLED) {
    return encounter.get('location') || UNICODE.EMDASH;
  }
  const completedLocations = encounter.getActiveStages().reduce((completedStageLocations, stage) =>
    stage.occurrences?.reduce((_, occurrence) => {
      if (occurrence.location && occurrence?.events?.some(ev => ev.type === 'completed')) {
        return completedStageLocations.push(occurrence.location);
      }
      return completedStageLocations;
    }, List()), List());
  return completedLocations.size ? completedLocations.join() : UNICODE.EMDASH;
};

/**
 * Returns formatted next event time after the argument
 * @param {EncounterModel} encounter EncounterModel
 * @param {string} eventType event type to look-out for
 * @param {StageInfo} stageInfo Doctor name of this stage info will be returned
 * @returns {string}
 */
export const getNextEventTime = (
  encounter: EncounterModel, eventType: string, stageInfo: StageInfo,
): string => stageInfo.occurrences.reduce((nextEvents, occurrence) => {
  const currentEventIndex = occurrence.events.findIndex(e => e.type === eventType);
  if (currentEventIndex === -1) {
    return nextEvents;
  }
  if (currentEventIndex && occurrence.events[currentEventIndex + 1]) {
    // Returns if next event present in the occurrence
    const nextEventTime = occurrence.events[currentEventIndex + 1]?.time;
    return nextEvents.push(moment(nextEventTime).format(`${getConfig().get('date_format')} h:mm a`));
  }
  // else check for the next event time in all stages
  const stageEventTimes = encounter.getStages().map(stage => stage.occurrences
    .map(occurence => occurence?.events
          .map(event => event.time))).toArray();
  const currentEventTime = occurrence.events[currentEventIndex]?.time;
  const followerEvents = flattenDeep(stageEventTimes).filter(et => et > currentEventTime);
  if (followerEvents.length) {
    const nextEventTime = Math.min(...followerEvents);
    return nextEvents.push(moment(nextEventTime).format(`${getConfig().get('date_format')} h:mm a`));
  }
  return nextEvents;
}, List()).join(', ');

/** Return the visit type for the claim invoice
 * @param {string} encounterFlow Encounter flow
 * @param {string} encounterType Encounter type
 * @returns {string}
 */
export const getVisitType = (encounterFlow?: string, encounterType?: string) => {
  if (encounterFlow) {
    return encounterFlow;
  }
  return encounterType || UNICODE.EMDASH;
};

/**
 * Returns name of doctors in stage info
 * @param {List<PractitionerModel>} practitioners List of all practitioners
 * @param {List<StageInfo>} stages of stage info in encounter doc to check for doctor
 * @param {string} doctorId doctor
 * @returns {string}
 */
export const getDoctorNames = (
  practitioners: List<PractitionerModel>,
  stages:List<StageInfo | undefined> = List(),
  doctorId?: string,
): string => {
  const practionerIds = getConcatenatedValueFromStages(stages, 'doctor');
  const stageDoctorNames = practionerIds.reduce((practionerNames, practionerId) => {
    const practionerModel = practitioners.find(p => p.get('_id') === practionerId);
    if (practionerModel) {
      return practionerNames.push(practionerModel.get('name'));
    }
    return practionerNames;
  }, List());
  if (doctorId && !practionerIds.includes(doctorId)) {
    const practitioner = practitioners.find(p => p.get('_id') === doctorId);
    const doctorName = practitioner ? practitioner.get('name', '') : doctorId || UNICODE.EMDASH;
    return stageDoctorNames.size ? `${stageDoctorNames.join(', ')}, ${doctorName}` : doctorName;
  }
  return stageDoctorNames.join(', ') || UNICODE.EMDASH;
};

/**
 * Returns stages of encounter with updated stages
 * @param {List<StageInfo>} stages All stages in the encounter
 * @param {List<StageInfo>} activeStages Active/ non-cancelled stages in the encounter
 * @returns {List<StageInfo>}
 */
export const mergeStages =
  (stages: List<StageInfo>, activeStages: List<StageInfo>): Array<StageInfo> =>
    stages.reduce((merged, stage) => {
      const activeStage = activeStages.find(s =>
        // eslint-disable-next-line no-underscore-dangle
        s.stage_id === stage.stage_id && s._name === stage._name);
      if (activeStage) return merged.push(activeStage);
      return merged.push(stage);
    }, List()).toArray();

/**
 * Returns formatted info from stages attributesin encounter doc
 * @param {List<EncounterStageModel>} stages stages in encounter model
 * @returns {List<{name: string, stage_id: string}>}
 */
export const getSuffixedStageInfoFromStageAttributes =
  (stages: List<{ name: string, stageId: string, [key: string]: MapValue }>) =>
  stages
    .reduce(
      (
        [stageIdMap, stageNameMap]: [
          Map<string, number>,
          Map<string, { name: string; stageId: string, unsuffixed: string }>
        ],
        stage,
      ) => {
        if (stageIdMap.has(stage.stageId)) {
          const suffix = stageIdMap.get(stage.stageId) ?? 1;
          const updatedStageNameMap = stageNameMap
            .set(`${stage.name} ${suffix + 1}`, {
              ...stage,
              name: `${stage.name} ${suffix + 1}`,
              stageId: stage.stageId,
              unsuffixed: stage.name,
            });
          if (suffix === 1) {
            return [
              stageIdMap.update(stage.stageId, occurrence => occurrence + 1),
              updatedStageNameMap
                .update(stage.name, (stageData) => {
                  if (stageData) {
                    return {
                      ...stageData,
                      name: `${stage.name} ${suffix}`,
                      stageId: stage.stageId,
                      unsuffixed: stage.name,
                    };
                  }
                }),
            ];
          }
          return [
            stageIdMap.update(stage.stageId, occurrence => occurrence + 1),
            updatedStageNameMap,
          ];
        }
        return [
          stageIdMap.set(stage.stageId, 1),
          stageNameMap.set(stage.name, {
            ...stage,
            name: stage.name,
            stageId: stage.stageId,
            unsuffixed: stage.name,
          }),
        ];
      },
      [Map(), Map()],
    )[1]
    .valueSeq().toList() as
    List<{name: string, stageId: string, unsuffixed: string, [key: string]: MapValue}>;

/**
 * Returns list of stage info with chronological numbers as suffix to stages name
 * @param {List<EncounterStageModel>} stages stages in encounter model
 * @returns {List<{name: string, stage_id: string}>}
 */
export const getStageNamesWithSuffix = (stages: List<EncounterStageModel>) => {
  const stageAttributes = stages.map(stageModel => ({
    name: stageModel.get('name'),
    stageId: stageModel.get('_id'),
  }));
  return getSuffixedStageInfoFromStageAttributes(stageAttributes);
};


/**
 * Iterates through notes state
 * Returns updated stages with notes in corresponding stages in EncounterModel
 * @param {EncounterModel} encounter EncounterModel
 * @param {string | Object} notes stage name to encounter note map
 * @returns {Array<StageInfo>}
 */
export const getUpdatedStagesWithNotes = (
  encounter: EncounterModel,
  notes: string | {[stageName: string]: string},
): EncounterModel => {
  if (typeof notes === 'string') {
    return encounter.set('notes', notes);
  }
  const updatedActiveStages = encounter.getActiveStages().reduce((reduced, stage) => {
    // Using name to identify stage because same stage can be present in the flow multiple times.
    // eslint-disable-next-line no-underscore-dangle
    if (typeof notes !== 'string' && notes[stage._name]) {
      // eslint-disable-next-line no-underscore-dangle
      return reduced.push({ ...stage, notes: notes[stage._name] });
    } return reduced;
  }, List());
  const updatedStages = mergeStages(encounter.getStages(), updatedActiveStages);
  const encounterChangeAttrs = { ...encounter.attributes, flow: { ...encounter.get('flow', {}), stages: updatedStages } };
  return encounter.replaceAtrributes(encounterChangeAttrs);
};

/**
 * Filter and return encounters created today
 * @param {List<EncounterModel>} encounters list of encounters
 * @returns {List<EncounterModel>}
 */
export const getTodaysEncounters = (encounters: List<EncounterModel>) =>
  encounters.filter(encounter => isToday(encounter.getCreatedTime()));

/**
 * Plays in-progress or waiting alert in passed encounter stage
 * @param {EncounterStageModel} encounterStage Encounter stage model
 * @param {string} alertKey key to check in stage model
 * @returns {void}
 */
export const alertEncounterStage = (encounterStage: EncounterStageModel | undefined, alertKey: string) => {
  const alert = encounterStage?.get(alertKey);
  if (alert !== 'none') {
    playSound(alert);
  }
};

/**
 * Returns the created_by of the encounter doc
 * @param {EncounterModel} encounter Encounter model
 * @returns {string}
 */
export const getEncounterCreatedBy = (encounter: EncounterModel): string => {
  if (encounter && !isEmpty(encounter?.get('created_by'))) {
    return encounter?.get('created_by').user_id;
  }
  return '';
}
