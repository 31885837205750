import React from 'react';
import type { List, Map } from 'immutable';
import glamorous from 'glamorous';
import { Link } from 'react-router-dom';

import VitalsGridContent from './vitalsGridContent';
import PatientHeader from './../patient/patientHeader';
import Button from './../buttons/button';

import { mediaQueries, wsUnit } from './../../utils/css';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import { isReferralMode } from './../../utils/router';
import { getConfirmation } from './../../utils/utils';

import type AllergyModel from './../../models/allergyModel';
import type { VitalsTableRow } from './vitalsTable';
import type { ActiveIngredient, SaveModel, User } from './../../types';
import type MetricTypeModel from './../../models/metricTypeModel';
import type MetricEntryModel from './../../models/metricEntryModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import DrugModel from './../../models/drugModel';

type Props = {
  allergies: List<AllergyModel>,
  metricTypes: List<MetricTypeModel>,
  metricEntries: List<MetricEntryModel>,
  patientID: string,
  saveModel: SaveModel,
  patient: PatientModel | PatientStubModel,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  drugs: List<DrugModel>,
};

type State = {
  patientHeaderHeight: number,
};

const VitalsGrid = glamorous.div({
  margin: wsUnit,
  display: 'grid',
  gridColumnGap: wsUnit,
  gridRowGap: wsUnit,
  gridTemplateColumns: '1fr',
  [mediaQueries.forBigDesktopUp]: {
    gridTemplateColumns: '1fr 1fr',
  },
}, ({ isReferring }) => (isReferring ? { height: '50vh' } : { }));

/**
 * A component that displays tables for each metric type for a patient.
 * @class VitalsHistory
 * @extends {React.PureComponent<Props, State>}
 */
class VitalsHistory extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of VitalsHistory.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      patientHeaderHeight: 0,
    };
  }

  /**
   * Handles the deletion of a MetricEntryModel
   * @param {MetricEntryModel} metricEntry A metricEntry
   * @returns {void}
   */
  onDeleteClicked(metricEntry: MetricEntryModel) {
    if (metricEntry) {
      getConfirmation(translate('confirm_item_deletion'))
        .then(
          () => this.props.saveModel(metricEntry.set('_deleted', true))
            .then(() => {
              createSuccessNotification(translate('item_deleted'));
            }),
          () => {},
        );
    }
  }

  /**
   * Returns an array of objects with the entries for the given metric type in a format acceptable
   * to VitalsTable.
   * @param {MetricTypeModel} metricType The metricType to filter for.
   * @param {List<MetricEntryModel>} entries All metricEntries for the current patient.
   * @returns {Array<VitalsTableRow>}
   */
  getRows(metricType: MetricTypeModel, entries: List<MetricEntryModel>): Array<VitalsTableRow> {
    return entries
      .filter(entry => entry.get('metric_type') === metricType.get('_id'))
      .map(entry => ({
        metric_entry: metricType.formatValues(entry.get('values')),
        date: entry.get('measured_time'),
        delete: (
          <Button
            className="o-text-button o-text-button--danger"
            onClick={() => this.onDeleteClicked(entry)}
            dataPublic
          >
            {translate('delete')}
          </Button>
        ),
      }))
      .toArray();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const containerCSS = {
      overflowY: 'scroll',
      height: `calc(100vh - ${2 * this.state.patientHeaderHeight}px)`,
    };
    return (
      <div>
        <PatientHeader 
          patient={this.props.patient}
          coveragePayors={this.props.coveragePayors}
          allergies={this.props.allergies}
          user={this.props.user}
          onHeightReady={height => this.setState({ patientHeaderHeight: height })}
          drugs={this.props.drugs}
        />
        <div style={containerCSS}>
          { this.props.metricTypes.size === 0 ?
            <div className="o-text-content">
              <p className="u-font-medium">{translate('no_vitals_types_created')}</p>
              <p>
                <Link
                  className="o-button o-button--padded"
                  to="/settings/vitals"
                >
                  {translate('add_vital_type')}
                </Link>
              </p>
            </div> :
            <VitalsGrid isReferralMode={isReferralMode()}>
              {
                this.props.metricTypes.map((metricType) => {
                  const graphViewActive = true; // graph view is default
                  return (
                    <VitalsGridContent
                      metricType={metricType}
                      metricEntries={this.props.metricEntries}
                      patientID={this.props.patientID}
                      saveModel={this.props.saveModel}
                      graphViewActive={graphViewActive}
                      user={this.props.user}
                    />
                  );
                })
              }
            </VitalsGrid>
        }
        </div>
      </div>
    );
  }
}

export default VitalsHistory;
