import React, { useState, useMemo, useEffect } from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { propTypes } from 'react-notification-system';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';

import type { State as Store, HTMLStyle, MissingDocObject, Config, SaveModel, SaveModels } from './../../types';
import PatientRegistrationContainer from '../../containers/patientRegistrationContainer';
import AddEditPrescriptionsContainer from '../../containers/addEditPrescriptionsContainer';
import AddEditDrugContainer from '../../containers/addEditDrugContainer';
import AddSalesItemContainer from '../../containers/addSalesItemContainer';
import AddEncounterContainer from '../../containers/addEncounterContainer';
import SaveButton from '../buttons/saveButton';
import translate from '../../utils/i18n';
import DoctorForm from '../doctors/doctorForm';
import PractitionerModel from '../../models/practitionerModel';
import LabRequestsListContainer from '../../containers/labRequestsListContainer';
import BaseModel from '../../models/baseModel';
import ProcedureTypeModel from '../../models/procedureTypeModel';
import type ProcedureRequestModel from '../../models/procedureRequestModel';
import type ProcedureStatusModel from '../../models/procedureStatusModel';
import type ProcedureResultModel from '../../models/ProcedureResultModel';
import SalesItemFormContainer from '../../containers/salesItemsFormContainer';
import BillRegenerationForm from '../billing/billRegenerationForm';
import CoveragePayorModel from './../../models/coveragePayorModel';
import BillRegenerationContainer from '../../containers/billRegenerationContainer';

const ModalContainer = glamorous.div({
  padding: '2rem',
});

const ModalHeader = glamorous.p({
  fontFamily: '"roboto", sans-serif',
  marginBottom: '1rem',
  color: 'red',
});

type Props = {
  user: User,
  config: Config,
  footerButtonLabel?: String,
  modalHeading?: string,
  id: string,
  modalStyle?: HTMLStyle,
  onModalClose?: () => void,
  show?: boolean, // If true, shows the popup even if storage has the program key
  docsForRegeneration: Map<string, MissingDocObject>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  unsetDocumentsPendingRegeneration: (ids: List<string>) => void,
};

interface ModalContentProp {
  config: Config;
  isFromModal: boolean;
  docId: string;
  referrerDoc: BaseModel;
  isSaving: boolean;
  noSave: boolean;
  onSaveAtValidationModal: (wasSuccessful: boolean) => void;
  saveModel: SaveModel;
}

/**
 * Get the Modal Content Component based on doc Id type
 * @param {string} docIdType type of doc id to fetch the appropriate component
 * @param {string} referrerDocType type of the referrer doc on save of which the error occured.
 * @returns {ReactComponentElement} The proper react component to render as modal content
 */
function getModalContent(docIdType?: string, referrerDocType?: string): React.ElementType | null {
  switch (true) {
    case (docIdType === 'patient_id'):
      return withRouter(PatientRegistrationContainer);
    case (docIdType === 'practitioner_id') || (docIdType === 'doctor_id') || (docIdType === 'doctor'):
      return connect((state: Store, props: ModalContentProp) => ({
        doctor: state.practitioners.find(e => e.get('_id') === props.docId) ||
        state.currentDataViewsModels.find(e => e.get('_id') === props.docId) ||
        new PractitionerModel({ _id: props.docId }),
      }))((props: {doctor: PractitionerModel} & ModalContentProp) => (<DoctorForm
        isFromDocValidationModal
        config={props.config}
        modelToEdit={props.doctor}
        isSaving={props.isSaving}
        onSave={practitioner => props.saveModel(practitioner)
          .then(model => props.onSaveAtValidationModal(!!model))}
      />));
    case (docIdType === 'drug_id') && (referrerDocType === 'prescription'):
      return (props: ModalContentProp) => <AddEditPrescriptionsContainer
        isFromDocValidationModal={props.isFromModal}
        validationReferrerModel={props.referrerDoc}
        validationDocObject={{ id: props.docId, type: 'drug' }}
        isDocValidationModalSaving={props.isSaving}
        onSaveAtDocValidationModal={props.onSaveAtValidationModal}
      />;
    case (docIdType === 'coverage_payor_id') && (referrerDocType === 'drug'):
      return (props: ModalContentProp) => <AddEditDrugContainer
        isFromDocValidationModal={props.isFromModal}
        validationReferrerModel={props.referrerDoc}
        validationDocObject={{ id: props.docId, type: 'coverage_payor' }}
        isDocValidationModalSaving={props.isSaving}
        onSaveAtDocValidationModal={props.onSaveAtValidationModal}
      />;
    case (docIdType === 'consult_type') && (referrerDocType === 'encounter'):
      return AddSalesItemContainer;
    case (docIdType === 'encounter_id') &&
      ((referrerDocType === 'bill') ||
      (referrerDocType === 'drug') ||
      (referrerDocType === 'medical_certificate') ||
      (referrerDocType === 'prescription') ||
      (referrerDocType === 'procedure_request') ||
      (referrerDocType === 'time_chit')):
      return (props: ModalContentProp) => <AddEncounterContainer
        isFromDocValidationModal={props.isFromModal}
        validationReferrerModel={props.referrerDoc}
        validationDocObject={{ id: props.docId, type: 'encounter' }}
        isDocValidationModalSaving={props.isSaving}
        onSaveAtDocValidationModal={props.onSaveAtValidationModal}
      />;
    case (docIdType === 'procedure_type_id'):
      return (props: ModalContentProp) => <LabRequestsListContainer
        isFromDocValidationModal={props.isFromModal}
        validationReferrerModel={props.referrerDoc}
        validationDocObject={{ id: props.docId, type: 'procedure_type' }}
        isDocValidationModalSaving={props.isSaving}
        onSaveAtDocValidationModal={props.onSaveAtValidationModal}
      />;
    case (docIdType === 'sales_item_id'):
      return (props: ModalContentProp) => <SalesItemFormContainer
        isFromDocValidationModal={props.isFromModal}
        validationReferrerModel={props.referrerDoc}
        validationDocObject={{ id: props.docId, type: 'sales_item' }}
        noSave={props.noSave}
        isDocValidationModalSaving={props.isSaving}
        onSaveAtDocValidationModal={props.onSaveAtValidationModal}
      />;
    case (docIdType === 'bill_id'):
      return (props: ModalContentProp) => <BillRegenerationContainer
        {...props}
        isFromDocValidationModal={props.isFromModal}
        validationReferrerModel={props.referrerDoc}
        isDocValidationModalSaving={props.isSaving}
        onSaveAtDocValidationModal={props.onSaveAtValidationModal}
      />;
    default:
      return null;
  }
}

/**
 * Render the Document Validation Modal
 * @returns {HTMLElement} html element
 */
const DocumentValidationModal = ({
  id, modalStyle, onModalClose, show = false, config, user, saveModel,
  saveModels, docsForRegeneration, unsetDocumentsPendingRegeneration,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [resolvedModels, setResolvedModels] = useState(Map());
  const docForRegeneration = docsForRegeneration.first(null);

  const ModalContent = useMemo(
    () => getModalContent(docForRegeneration?.type, docForRegeneration?.referrerDoc?.get('type')),
    [docForRegeneration?.type],
  );

  /**
   * Removes the changed child doc Id from docsForRegeneration if they arn't found.
   */
  useEffect(() => {
    const missingDocObject = docsForRegeneration.first(null);
    const missingDocId = missingDocObject?._id;
    const missingDocType = missingDocObject?.type;
    const referrerDoc = missingDocObject?.referrerDoc;
    const referrerDocType = referrerDoc?.get('type');
    switch (referrerDocType) {
      case 'drug': {
        if (missingDocId && missingDocType === 'coverage_payor_id') {
          const priceCoveragePayor = referrerDoc?.get('price_coverage_payor');
          const obj = priceCoveragePayor.find(
            (item: any) => item.coverage_payor_id === missingDocId,
          );
          if (!obj) {
            unsetDocumentsPendingRegeneration(List([missingDocId]));
          }
        }
        break;
      }
      case 'prescription': {
        if (missingDocId && missingDocType === 'drug_id') {
          const drugId = referrerDoc?.get('drug_id');
          if (missingDocId !== drugId) {
            unsetDocumentsPendingRegeneration(List([missingDocId]));
          }
        }
        break;
      }
      default:
        break;
    }
  }, [docsForRegeneration]);

  /**
   * Handle close click
   * @returns {void} closes modal
   */
  const handleClose = () => {
    if (docsForRegeneration && docsForRegeneration.size) {
      unsetDocumentsPendingRegeneration(List(docsForRegeneration.valueSeq().map(doc => doc._id)));
    }
    if (onModalClose) {
      onModalClose();
    }
  };

  /**
   * Handle save click
   * @returns {void}
   */
  const handleSaveClick = () => {
    if (!isSaving) {
      setIsSaving(true);
    }
  };


  /**
   * Called with the saving result of the doc to set the visible state of the modal
   * @param {boolean} wasSuccessful whether the saving operation was succesful
   * @param {List<BaseModel>} currentResolvedModels the various models that was resolved using the missing doc specific strategy
   * @returns {void} void sets seen key & closes modal
   */
  const onSave = (wasSuccessful: boolean, currentResolvedModels?: List<BaseModel>) => {
    setIsSaving(false);
    const resolvedModelsMap = currentResolvedModels
      ? currentResolvedModels
        .reduce((_resolvedModels, model) => _resolvedModels.set(model.get('_id'), model), resolvedModels)
      : resolvedModels;

    if (currentResolvedModels) {
      setResolvedModels(resolvedModelsMap);
    }

    if (wasSuccessful && docForRegeneration) {
      if (
        docsForRegeneration.size <= 1
        && docsForRegeneration.first({ _id: null })._id === docForRegeneration._id
      ) {
        docForRegeneration.onDocsResolved(resolvedModelsMap.toList());
      }
      unsetDocumentsPendingRegeneration(List([docForRegeneration._id]));
    }
  };


  return (
    <StatelessModal
      title={translate('error_detected_during_save')}
      setVisible={() => { }}
      onClose={() => handleClose()}
      visible={show}
      id={id}
      noButton
      noCloseButton
      style={modalStyle || { maxWidth: 900 }}
    >
      <ModalContainer>
        <ModalHeader>
          The {docForRegeneration?.type?.match(/(?:(.+)_id)|(.+)/)?.slice(1,).find(e => !!e)?.split('_').join(' ') || ''} that you are saving data with cannot be found.<br /><br />
          Please review the data below to fix this issue.
        </ModalHeader>
        {
          ModalContent && <ModalContent
            isFromModal
            docId={docForRegeneration?._id}
            referrerDoc={docForRegeneration?.referrerDoc}
            noSave={docForRegeneration?.noSave}
            isSaving={isSaving}
            onSaveAtValidationModal={onSave}
            config={config}
            user={user}
            saveModel={saveModel}
            saveModels={saveModels}
          />
        }
      </ModalContainer>
      <ModalFooter>
        <SaveButton
          dataPublic
          isSaving={isSaving}
          onClick={() => handleSaveClick()}
        />
      </ModalFooter>
    </StatelessModal>
  );
};

export default DocumentValidationModal;
