
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import type { State } from './../types';

import NewReportPage from './../components/analytics/placeholder';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  user: state.user,
  placeholder: 'Analytics new report page',
});

export default withRouter(connect(mapStateToProps)(NewReportPage));
