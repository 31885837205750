import React from 'react';
import glamorous, { ThemeProvider } from 'glamorous';
import { wsUnit } from './../../utils/css';

type AllTabs = {
  [key: string]: {
    key: string,
    label: string,
  }
};

const Container = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: wsUnit,
  padding: '1.33rem 1.33rem 1.33rem 0.33rem',
});

const Body = glamorous.div({
  padding: '0 1.33rem 1.33rem 1.33rem',
});

const activeTab = {
  backgroundColor: '#4a90e2',
  color: 'white',
};

const inactiveTab = {
  backgroundColor: 'white',
  color: '#4a90e2',
};

const TabButton = glamorous.button({
  padding: '0.3rem 1rem',
  fontSize: '1rem',
  cursor: 'pointer',
  border: '1px solid #4a90e2',
}, ({ theme }) => ({
  backgroundColor: theme.backgroundColor,
  color: theme.color,
}));

/**
 * Returns the tabs with active
 * @param {AllTabs} tabs All tabs data
 * @param {string} activeKey Selected key
 * @param {Function} setKey SetState function for the key
 * @returns {React.FunctionComponent} The rendered tab
*/
function render(tabs: AllTabs, activeKey: string, setKey: Function) {
// function renderTabs(tabs: AllTabs, activeKey: string, setKey: Function) {
  const elements = Object.keys(tabs).map(tab => (
    <ThemeProvider
      key={tab}
      theme={activeKey === tab ? activeTab : inactiveTab}
    >
      <TabButton
        onClick={() => setKey(tab)}
        disabled={activeKey === tab}
      >
        {activeKey === tab ? '✔' : ''} {tabs[tab].label}
      </TabButton>
    </ThemeProvider>
  ));

  return <span style={{ marginLeft: '1rem' }}>{elements}</span>;
}

export default { render, TabButton, Container, Body };
