import React from 'react';
import { Document, Page } from 'react-pdf';
import glamorous from 'glamorous';

import LoadingIndicator from './loadingIndicator';

type Props = {
  urlData: string,
};

type State = {
  file?: string,
  numPages?: number,
  urlData?: string,
}

const PDFDocumentWrapper = glamorous.div({
  display: 'flex',
  justifyContent: 'flex-start',
  maxWidth: 'calc(100vw - 400px)',
  overflowX: 'auto',
});

/**
 * A Component that loads an pdf file.
 * @class PDFLoader
 * @extends {React.Component<Props, State>}
 */
class PDFLoader extends React.Component<Props, State> {
  /**
   * Creates an instance of PDFLoader.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * When PDF file load success
   * @param {number} numPages pdf page
   * @returns {undefined}
   */
  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => { // eslint-disable-line
    this.setState({ numPages });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <PDFDocumentWrapper>
        <Document
          onLoadSuccess={this.onDocumentLoadSuccess}
          file={this.props.urlData}
          loading={<LoadingIndicator />}
        >
          { Array.from(new Array(this.state.numPages || 0), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              scale={2}
            />
          ))
          }
        </Document>
      </PDFDocumentWrapper>
    );
  }
}

export default PDFLoader;
