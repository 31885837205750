import React from 'react';
import { List } from 'immutable';

import PermissionsEditorGroup from './permissionsEditorGroup';
import permissions from './../../permissions';

import type { Permission } from './../../models/userConfigModel';
import type { UserPermissionRecord, Config, User } from './../../types';

type Props = {
  permissionsConfig: List<UserPermissionRecord>,
  updatePermissions: (permissions: List<UserPermissionRecord>) => void,
  isAdminUser: boolean,
  config: Config,
  klinifyConfig: Config,
  user: User,
};

type PermissionGroups = Array<{
  group: string,
  features: Array<Permission>,
}>;
/**
 * A component that displays a list of PermissionsEditorGroup components covering all possible
 * permissions.
 * @class PermissionsEditor
 * @extends {React.Component<Props, State>}
 */
class PermissionsEditor extends React.PureComponent<Props> {
  /**
   * Filters create/update/delete user permissions if current user is not admin.
   * @param {PermissionGroups} allPermissions all permissions available.
   * @returns {PermissionGroups} permissions filtered based on user's admin status
   */
  filterAdminPermissions(allPermissions: PermissionGroups): PermissionGroups {
    if (this.props.isAdminUser) {
      return allPermissions;
    }
    const userManagementGroup = allPermissions.find(p => p.group === 'user_management');
    if (userManagementGroup && userManagementGroup.features &&
       userManagementGroup.features.length) {
      const newGroup = {
        group: userManagementGroup.group,
        features: [],
      };
      userManagementGroup.features.map((feature) => {
        if (feature.feature === 'users') {
          newGroup.features.push({
            feature: 'users',
            access: ['read'],
          });
        } else {
          newGroup.features.push(feature);
        }
        return feature;
      });
      return allPermissions.map((permission) => {
        if (permission.group === 'user_management') {
          return newGroup;
        }
        return permission;
      });
    }
    return allPermissions;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const newPermissions = this.filterAdminPermissions(permissions);
    return (
      <div>
        {
          newPermissions.map(p =>
            <PermissionsEditorGroup
              key={p.group}
              permissionsGroup={p}
              permissionsConfig={this.props.permissionsConfig}
              updatePermissions={this.props.updatePermissions}
              config={this.props.config}
              klinifyConfig={this.props.klinifyConfig}
              user={this.props.user}
            />)
        }
      </div>
    );
  }
}

export default PermissionsEditor;
