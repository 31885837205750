import React from 'react';
import { List, fromJS } from 'immutable';

import { createSuccessNotification } from './../../utils/notifications';
import { UNICODE } from './../../constants';
import translate from './../../utils/i18n';
import Table from './../table/table';
import { convertNumberToPrice, validateAndTrimString, getConfirmation, pluralizeWord } from './../../utils/utils';
import Button from './../buttons/button';
import { debugPrint } from '../../utils/logging';

import type BaseModel from './../../models/baseModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Column, SaveModel, Model, MapValue, Config } from './../../types';

type Props = {
  columns: Array<Column>,
  items: List<Model>,
  onEditClicked: (model: Model) => void,
  secondaryData?: {[key: string]: MapValue},
  saveModel: SaveModel,
  canEdit: boolean,
  canDelete: boolean,
  config?: Config,
  coveragePayors?: List<CoveragePayorModel>,
  noDataText?: string,
  loading?: boolean,
};

const defaultSorted = [{ id: 'name', desc: false }];

/**
 * Called when delete is clicked on an item. Updates its state to be hidden.
 * @param {BaseModel} item The item to delete
 * @param {function} saveModel saveModel function
 * @returns {undefined}
 */
function onDeleteClicked(item: Model, saveModel: (model: BaseModel) => Promise<BaseModel>) {
  if (item) {
    getConfirmation(translate('confirm_item_deletion'))
      .then(
        () => saveModel(item.setVisible(false))
          .then(() => { createSuccessNotification(translate('item_deleted')); }),
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {BaseModel} item An item for the delete button
 * @param {function} saveModel saveModel function
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(item: Model, saveModel: (model: BaseModel) => Promise<BaseModel>) {
  return (
    <Button
      className="o-text-button o-text-button--danger"
      onClick={() => onDeleteClicked(item, saveModel)}
      dataPublic
    >
      {translate('delete')}
    </Button>
  );
}

/**
 * Returns an edit button component for the given item.
 * @param {BaseModel} item The item the edit button is for.
 * @param {function} onClick The function to run when edit is clicked.
 * @returns {React.Component} A edit button.
 */
function getEditButton(item: Model, onClick: (model: Model) => void) {
  return (
    <Button
      className="o-text-button o-text-button--contextual"
      onClick={() => onClick(item)}
      dataPublic
    >
      {translate('edit')}
    </Button>
  );
}

/**
 * Returns the trimmed value of accessor in an item, if value is string, else returns the value as it is.
 * @param {BaseModel} item The item for each row.
 * @param {string} accessor The accessor to be used to get value.
 * @returns {MapValue} the value corresponding to accessor.
 */
function getAccessorValue(item: Model, accessor: string) {
  const value = item.get(accessor, UNICODE.EMDASH, false);
  if (typeof value !== 'string') {
    return value;
  }
  return validateAndTrimString(value);
}

/**
 * Returns the trimmed value of accessor in an item, if value is string, else returns the value as it is.
 * @param {BaseModel} item The item for each row.
 * @param {string} accessor The accessor to be used to get value.
 * @param {Config} config config object
 * @returns {MapValue} the value corresponding to accessor.
 */
function getIntegrationValue(item: Model, accessor: string, config?: Config) {
  const integrations = item.get('integrations', {});
  const supplierIntegrations = Object.keys(integrations);
  if (config !== undefined &&
    config.getIn(['suppliersForIntegration'], List()).toJS().includes(supplierIntegrations[0])) {
    if (accessor === 'supplierId') {
      return validateAndTrimString(supplierIntegrations && supplierIntegrations.length > 0 &&
        supplierIntegrations[0] && integrations[supplierIntegrations[0]] ?
        integrations[supplierIntegrations[0]].id : UNICODE.EMDASH);
    }
    if (accessor === 'supplierIntegration') {
      return supplierIntegrations && supplierIntegrations.length > 0 ?
        translate(supplierIntegrations[0]) : UNICODE.EMDASH;
    }
  }
  return UNICODE.EMDASH;
}

/**
 * Get a table row for the given item.
 * @param {ValidModel} item An item model
 * @param {Array<Column>} columns The table columns.
 * @param {any} onEditClicked onEditClicked
 * @param {any} secondaryData secondaryData
 * @param {SaveModel} saveModel SaveModel function
 * @param {Config} config config object
 * @param {List<CoveragePayorModel>} coveragePayors list of coveragePayors
 * @param {Map<string, Object>} additionalData Map<itemId, Object> additional data for table, not in the item Model
 * @returns {{}}
 */
function getTableRow(
  item: Model, columns: Array<Column>, onEditClicked, secondaryData,
  saveModel, config?: Config, coveragePayors?: List<CoveragePayorModel>,
) {
  const row: { [key: string]: MapValue } = {
    edit: getEditButton(item, onEditClicked),
    delete: getDeleteButton(item, saveModel),
  };
  columns.forEach((column) => {
    if (column.useSecondaryData && secondaryData && secondaryData[item.get('_id')]) {
      row[column.accessor] = (secondaryData[item.get('_id')][column.accessor]) || UNICODE.EMDASH;
    } else if (column.isIntegration) {
      row[column.accessor] = getIntegrationValue(item, column.accessor, config);
    } else {
      row[column.accessor] = getAccessorValue(item, column.accessor);
    }
    if (column.renderFromLink) {
      row.link = secondaryData[item.get('_id')].link;
    }
  });
  if (row.price || row.price === 0) {
    row.price = convertNumberToPrice(row.price);
  }
  if (row.cost_price || row.cost_price === 0) {
    row.cost_price = convertNumberToPrice(row.cost_price);
  }
  if (row.tags && Array.isArray(row.tags)) {
    row.tags = row.tags.join(', ');
  }
  if (row.panel_price) {
    const values = [];
    values.push({ label: translate('default'), value: convertNumberToPrice(item.get('coverage_payor_price')) });
    if (coveragePayors && coveragePayors.size && item.get('price_coverage_payor')) {
      const itemPriceCoveragePayors = item.get('price_coverage_payor')
        .map((p) => {
          const coveragePayorModel = coveragePayors.find(c => c.get('_id') === p.coverage_payor_id);
          return {
            label: coveragePayorModel ? coveragePayorModel.get('name') : '',
            value: convertNumberToPrice(p.price),
          };
        });
      values.push(...itemPriceCoveragePayors);
    }
    row.panel_price = (
      <div>
        {values.map(v => (
          <div key={`${v.label}${v.value}`}>
            {v.label}
            -
            {v.value}
          </div>))}
      </div>
    );
  }
  if (row.default_prescribed_duration) {
    if (Array.isArray(row.default_prescribed_duration)) {
      row.default_prescribed_duration = pluralizeWord(row.default_prescribed_duration[1],
        row.default_prescribed_duration[0]);
    }
  }
  return row;
}

/**
 * List editor table
 * @param {Props} props Props
 * @returns {React.Component}
 */
const ListEditorTable = ({
  items, columns, onEditClicked, secondaryData, saveModel,
  canEdit = true, canDelete = true, config, coveragePayors,
  noDataText, loading,
}: Props) => {
  debugPrint('Rendering ListEditorTable');
  const allColumns = fromJS(columns).toJS();
  // Using let instead of count to get number of frozen columns
  let frozenColsCount = 0;
  if (canEdit) {
    allColumns.push({
      accessor: 'edit', Header: '', sortable: false, show: true, width: 80,
    });
    frozenColsCount += 1;
  }
  if (canDelete) {
    allColumns.push({
      accessor: 'delete', Header: '', sortable: false, width: 80, show: true,
    });
    frozenColsCount += 1;
  }

  /**
 * Get a table item row
 * @returns {Row}
 */
  const itemRows = () =>
    items
      .filter(item => item.isVisible())
      .map(item => getTableRow(item, columns, onEditClicked, secondaryData,
        saveModel, config, coveragePayors))
      .toArray();
  return (
    <div className={`rt-frozen-cols-${frozenColsCount}`}>
      <Table
        columns={allColumns}
        data={loading ? [] : itemRows()}
        noDataText={noDataText || translate('no_items')}
        showPagination
        defaultSorted={defaultSorted}
        loading={loading}
      />
    </div>
  );
};

export default ListEditorTable;
