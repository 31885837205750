import { List } from 'immutable';
import React from 'react';
import glamorous, { Label, Div } from 'glamorous';
import translate from './../../utils/i18n';
import { wsUnit, fonts } from './../../utils/css';
import { isKlinifyUser } from './../../utils/auth';

import type { UserPermissionRecord, Config, User } from './../../types';

type Props = {
  item: UserPermissionRecord,
  permissions?: UserPermissionRecord | null | undefined,
  updatePermissions: (feature: string, access: List<string>) => void,
  config: Config,
  user: User,
};

const ItemHeader = glamorous.header({
  display: 'flex',
  margin: wsUnit,
  alignItems: 'center',
});

const SubItems = glamorous.div({
  marginBottom: wsUnit,
  marginLeft: `calc(3 * ${wsUnit})`,
});

/**
 * A component for editing a single feature permission.
 * @class PermissionsEditorItem
 * @extends {React.Component<Props, State>}
 */
class PermissionsEditorItem extends React.Component<Props, {}> {
  /**
   * Toggles an individual access level for this feature.
   * @param {string} accesslevel The access level to toggle
   * @returns {void}
   */
  toggleAccessLevel(accesslevel: string) {
    let newAccessLevels: List<string> = this.props.permissions ?
      this.props.permissions.get('access') : List();
    if (newAccessLevels.includes(accesslevel)) {
      newAccessLevels = newAccessLevels.filter(l => l !== accesslevel);
    } else {
      newAccessLevels = newAccessLevels.push(accesslevel);
    }
    this.props.updatePermissions(this.props.item.get('feature'), newAccessLevels);
  }

  /**
   * Returns true if all access levels for the feature are toggled on.
   * @returns {boolean}
   */
  areAllAccessLevelsToggled(): boolean {
    const currentPermissionsLength = this.props.permissions && this.props.permissions.get('access') ?
      this.props.permissions.get('access').size : 0;
    return this.props.item.get('access').size === currentPermissionsLength;
  }

  /**
   * Toggles all access levels for the feature to be on or off.
   * @returns {void}
   */
  toggleAllAccessLevels() {
    if (this.areAllAccessLevelsToggled()) {
      this.props.updatePermissions(this.props.item.get('feature'), List());
    } else {
      this.props.updatePermissions(this.props.item.get('feature'), this.props.item.get('access'));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const feature = this.props.item.get('feature');
    return (
      <div>
        <ItemHeader>
          <input
            id={`checkbox-${feature}`}
            type="checkbox"
            checked={this.areAllAccessLevelsToggled()}
            onChange={() => this.toggleAllAccessLevels()}
            style={{ marginRight: `calc(${wsUnit} / 2)` }}
          />
          <Label css={{ fontFamily: fonts.medium }} htmlFor={`checkbox-${feature}`}>
            {translate(feature)}
          </Label>
        </ItemHeader>
        <SubItems>
          {
            this.props.item.get('access').map((accessLevel) => {
              const currentAccessLevels = this.props.permissions ?
                this.props.permissions.get('access') : List();
              if (!isKlinifyUser(this.props.user) && (feature === 'users' &&
                accessLevel === 'create' &&
                !this.props.config.getIn(['access_management', 'user_management', 'users', 'sub_items', 'create'], true))
              ) {
                return null;
              }
              if (!isKlinifyUser(this.props.user) && (feature === 'user_groups' &&
                ((accessLevel === 'create') &&
                !this.props.config.getIn(['access_management', 'user_management', 'user_groups', 'sub_items', 'create'], true)))
              ) {
                return null;
              }
              if (!isKlinifyUser(this.props.user) && (feature === 'user_groups' &&
              ((accessLevel === 'update') &&
              !this.props.config.getIn(['access_management', 'user_management', 'user_groups', 'sub_items', 'rename'], true) &&
              !this.props.config.getIn(['access_management', 'user_management', 'user_groups', 'sub_items', 'change_permissions'], true))
              )
              ) {
                return null;
              }
              return (
                <Div key={`${feature}-${accessLevel}`} css={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    onChange={() => this.toggleAccessLevel(accessLevel)}
                    checked={currentAccessLevels.includes(accessLevel)}
                    id={`checkbox-${feature}-${accessLevel}`}
                    style={{ marginRight: `calc(${wsUnit} / 2)` }}
                  />
                  <label htmlFor={`checkbox-${feature}-${accessLevel}`}>
                    {translate(accessLevel)}
                  </label>
                </Div>
              );
            })}
        </SubItems>
        <hr />
      </div>
    );
  }
}

export default PermissionsEditorItem;
