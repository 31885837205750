import BaseModel from './baseModel';
import type { AssetObject } from './../types';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'procedure_result',
  procedure_request_id: string,
  procedure_type_id: string,
  patient_id: string,
  description?: string,
  assets: Array<AssetObject>,
};

/**
  * ProcedureResult
  * @namespace ProcedureResult
  */
class ProcedureResult extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'procedure_result';
  }

  /**
   * Adds an asset to the ProcedureResult.
   * @param {AssetObject} asset The newly added asset object.
   * @returns {AssetObject} Returns the updated assets.
   */
  addAsset(asset: AssetObject) {
    const assets = this.get('assets', []);
    const newAssets = assets.concat(asset);
    this.set('assets', newAssets);
    return this;
  }

  /**
   * Returns the assets of the ProcedureResult.
   * @returns {AssetObject | undefined}
   */
  getAssets() {
    if (this.has('assets') && this.get('assets')) {
      return this.get('assets');
    }
    return undefined;
  }
}

export default ProcedureResult;
