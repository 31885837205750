import BaseModel from './../baseModel';

type attributesType = {
  _id: string,
  type: 'analytics_summary_item',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  key: string,
  title: string,
  value: string,
  change_percent: number,
  change_value: string,
  is_positive_good: boolean
};

/**
   * AnalyticsSummaryItemModel
   *
   * @namespace AnalyticsSummaryItemModel
   */
class AnalyticsSummaryItemModel extends BaseModel {
  attributes: attributesType;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'analytics_summary_item';
  }
}

export default AnalyticsSummaryItemModel;
