import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { trackEvent, CLICK, SIDEBAR_SUBITEM } from './../../utils/analytics';
import translate from './../../utils/i18n';
import UnreadIndicator from './unreadIndicator';

type Props = {
  label: string,
  url?: string,
  skipTranslate?: boolean,
  unreadCount?: number,
  onClick?: () => void,
  isSelected?: boolean,
};

/**
 * A link item for the sidebar component.
 * @class SidebarTextLink
 * @extends {React.PureComponent<Props>}
 */
class SidebarTextLink extends React.PureComponent<Props> {
  /**
   * Handle click
   * @param {Event} event Click event
   * @returns {void}
   */
  onClick = (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    trackEvent(SIDEBAR_SUBITEM, CLICK, this.props.label);
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { url, label, skipTranslate, unreadCount, isSelected } = this.props;
    if (!url) {
      return (
        <div className="c-sidebar__item__subitems__item" onClick={this.onClick} data-public>
          {skipTranslate === true ? label : translate(label)}
          <UnreadIndicator unreadCount={unreadCount} />
        </div>
      );
    }
    return (
      <NavLink
        exact
        to={url}
        activeClassName="c-sidebar__item__subitems__item c-sidebar__item__subitems__item--selected"
        className={isSelected ? 'c-sidebar__item__subitems__item c-sidebar__item__subitems__item--selected' : 'c-sidebar__item__subitems__item'}
        onClick={this.onClick}
        data-public
      >
        {skipTranslate === true ? label : translate(label)}
        <UnreadIndicator unreadCount={unreadCount} />
      </NavLink>
    );
  }
}

export default SidebarTextLink;
