import { connect } from 'react-redux';

import PatientQueue from './../components/patientQueue/patientQueue';
import { sortByNumber } from './../utils/comparators';
import { getModelMapFromList } from '../utils/models';

import type { State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => {
  const eConsultAppointments = state.appointments.filter(a => a.isTeleConsult()).groupBy(a => a.get('_id'));
  return ({
    config: state.config,
    encounters: state.encounters
      .filter(e => !eConsultAppointments.has(e.get('appointment_id')) && e.isCurrent() && e.lastEventIs('started') && e.getCurrentEvent()?.type === 'started')
      .sort((a, b) => -(sortByNumber(a.getLastEventTime(), b.getLastEventTime()))),
    patientStubs: state.patientStubs,
    practitioners: state.practitioners,
    encounterStageMap: getModelMapFromList(state.encounterStages),
  });
};

const PatientListContainer = connect(mapStateToProps)(PatientQueue);

export default PatientListContainer;
