import { connect } from 'react-redux';
import type { List } from 'immutable';

import type { Dispatch, State, DataView, MapValue, Config } from './../types';
import { setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfig, setLabRequestsFilter, updateModels } from './../actions';
import { saveFactory, saveModelsFactory } from './../utils/redux';

import LabRequests from './../components/labRequests/labRequests';
import type BaseModel from '../models/baseModel';
import type ProcedureRequestModel from '../models/procedureRequestModel';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, user, patientStubs, providers, procedureTypes, procedureRequests,
  specimens, isFetching, currentDataViews, procedureStatuses, collectedSpecimens,
  procedureResults, patients, reportsFilter,
}: State) => ({
  config,
  user,
  patientStubs,
  providers,
  procedureTypes,
  procedureRequests,
  procedureStatuses,
  collectedSpecimens,
  procedureResults,
  specimens,
  isFetching,
  currentDataViews,
  patients,
  filter: reportsFilter.get('labRequests'),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  setProcedureRequests: <T extends ProcedureRequestModel>(models: List<T>) =>
    dispatch(updateModels(models)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  onFilterUpdated: (filter: Map<string, MapValue>) => dispatch(setLabRequestsFilter(filter)),
});

const LabRequestsContainer = connect(mapStateToProps, mapDispatchToProps)(LabRequests);

export default LabRequestsContainer;
