import React, { ReactElement } from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';
import { Link } from 'react-router-dom';

import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import { createPermission, hasPermission, hasSomePermission } from './../../utils/permissions';
import translate from './../../utils/i18n';
import PermissionWrapper from './../permissions/permissionWrapper';
import { calculateCoveragePayorTotal } from './../../utils/billing';
import AddDocumentDataView from './../documentTemplates/addDocumentDataView';
import DocumentDataFormContainer from './../../containers/documentDataFormContainer';
import StatelessModal from './../modals/statelessModal';
import { convertNumberToPrice } from './../../utils/utils';
import { prettifyDate } from './../../utils/time';
import { getMaxClaimableAmountForBill } from './../../utils/coveragePayors';
import QueueButtonContainer from './../../containers/queueButtonContainer';
import BillItemCostPriceList from './billItemCostPriceList';
import Checkbox from './../inputs/checkbox';
import documentPrintFactory from './../hoc/documentPrintFactory';
import BillItemModel from './../../models/billItemModel';
import Button from './../buttons/button';
import { checkPrescriptionInteractions, getDACDrugNamesMessage } from '../../utils/prescriptions';
import InventoryMapModel from './../../models/inventoryMapModel';
import AllergyModel from './../../models/allergyModel';
import Confirm from '../prompts/confirm';
import { CLICK, DRUG_ALLERGY_CHECKER, trackEvent } from '../../utils/analytics';
import { DIC_ENABLED } from '../../constants';

import type DrugModel from './../../models/drugModel';
import type TimeChitModel from './../../models/timeChitModel';
import type SalesItemModel from './../../models/salesItemModel';
import type EncounterModel from './../../models/encounterModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type { Attributes as BillAttributes } from './../../models/billModel';
import type PatientModel from './../../models/patientModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type { User, Config, MapValue, PaymentToBePrint } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type PaymentModel from './../../models/paymentModel';
import type AppointmentModel from './../../models/appointmentModel';

type Props = {
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  procedureTypes: List<ProcedureTypeModel>,
  config: Config,
  klinifyConfig: Config,
  coveragePayor?: CoveragePayorModel | null | undefined,
  coveragePayors: List<CoveragePayorModel>,
  isEditingAfterFinalisation: boolean,
  isSaving: boolean,
  user: User,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  medicalCertificates: List<MedicalCertificateModel>,
  patient: PatientModel,
  prescriptions: List<PrescriptionModel>,
  salesItems: List<SalesItemModel>,
  timeChits: List<TimeChitModel>,
  onBillItemsChanged?: (newItems: List<BillItemModel>) => void,
  onVoidClicked?: () => void, // Only needed if expecting a finalised bill to begin with. E.g. AddNewBill won't need this.
  onFinaliseClicked: (updatedBillItems: List<BillItemModel>, isChanged?: boolean) => void,
  onEditClicked?: () => void, // Only needed if expecting a finalised bill to begin with. E.g. AddNewBill won't need this.
  documentTemplates: List<DocumentTemplateModel>,
  isFromAddPastBill?: boolean,
  payments: List<PaymentModel>,
  patientTotal: number,
  documentTemplateForPrint: DocumentTemplateModel | void,
  documentDataTitle: string | void,
  documentDataType: string | void,
  printModalVisible: boolean,
  isPrinting: boolean,
  isFinaliseButtonBusy: boolean,
  paymentsToBePrint: List<PaymentToBePrint>,
  onPrintTimeChitClicked: (timechitModel: TimeChitModel | null | undefined) => void,
  onPrintMedicalCertificateClicked: (
    medicalCertificateModel: MedicalCertificateModel | null | undefined,
  ) => void,
  onPrintInvoiceClicked: () => void,
  onPrintPrescriptionLabelsClicked: () => void,
  setDocumentPrintFactoryState: (arg: object) => void,
  onCancelClicked?: () => void,
  isOnline: boolean,
  appointment?: AppointmentModel,
  verifiedDrugs: Map<string, InventoryMapModel>,
  allergies: List<AllergyModel>,
};

type State = {
  documentTemplateModalVisible: boolean,
  costPricesModalVisible: boolean,
  isSavingBillItemsCostPrices: boolean,
  costPriceBillItems: List<BillItemModel>,
  printReceiptModalVisible: boolean,
  proceedMessage: string | ReactElement,
  interactedDrugs: List<DrugModel>
  isCheckingInteraction: boolean,
};

const SidebarButtonsContainer = glamorous.section({
  display: 'flex',
  flexDirection: 'column',
});

/**
 * A component that controls the display of the different buttons possible in the bill sidebar.
 * @class BillSidebarButtons
 * @extends {React.Component<Props, State>}
 */
class BillSidebarButtons extends React.Component<Props, State> {
  props: Props;

  state = {
    documentTemplateModalVisible: false,
    costPricesModalVisible: false,
    costPriceBillItems: List(),
    isSavingBillItemsCostPrices: false,
    printReceiptModalVisible: false,
    proceedMessage: '',
    interactedDrugs: List(),
    isCheckingInteraction: false,
  };

  /**
   * on acknowledge clicked
   * @returns {void}
   */
  onAcknowledge() {
    const filteredSupplyItems = this.props.billItems.filter((p: BillItemModel) => {
      const drudId = p.get('drug_id', '');
      if (drudId) {
        const isDrugIdPresent = this.state.interactedDrugs.find(s => s.get('_id') === drudId);
        return !isDrugIdPresent;
      }
      return true;
    });
    this.setState({ proceedMessage: '' });
    this.props.onFinaliseClicked(filteredSupplyItems, true);
  }

  onFinalisationClicked = () => {
    if (!DIC_ENABLED) {
      return this.props.onFinaliseClicked(this.props.billItems, false);
    }
    this.setState({ isCheckingInteraction: true });
    const drugIds = this.props.billItems.filter(b => b.isPrescription()).map(i => i.get('drug_id'));
    const verifiedDrugsItemsIds = drugIds.filter(d => this.props.verifiedDrugs.get(d));
    checkPrescriptionInteractions(verifiedDrugsItemsIds, this.props.allergies)
      .then(({ isError, drugs }: { isError: boolean, drugs: List<string> }) => {
        this.setState({ isCheckingInteraction: false });
        if (drugs.size > 0 && !isError) {
          const drugModels = drugs.map((i: string) =>
            this.props.drugs.find(d => d.get('_id') === i)).filter(a => !!a) as List<DrugModel>;
          const proceedMessage = getDACDrugNamesMessage(drugModels, 'dispense');
          this.setState({
            proceedMessage,
            interactedDrugs: drugModels,
          });
          return;
        }
        this.props.onFinaliseClicked(this.props.billItems, false);
      });
  }

  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.setCostPriceBillItems(this.props.billItems);
  }

  /**
   * Handle props changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.billItems !== this.state.costPriceBillItems) {
      this.setCostPriceBillItems(nextProps.billItems);
    }
  }

  /**
   * Check if payments props changed
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.payments.size !== this.props.paymentsToBePrint.size) {
      this.setPaymentsToBePrint();
    }
  }

  /**
   * Sets payments to be print
   * @returns {void}
   */
  setPaymentsToBePrint() {
    const payments = this.props.payments ? this.props.payments.size : 0;
    this.props.setDocumentPrintFactoryState({
      paymentsToBePrint: this.props.payments.map(p => ({
        id: p.get('_id'),
        amount: p.get('amount'),
        amountOutstanding: this.props.patientTotal - p.get('amount'),
        method: p.get('method'),
        dateReceived: prettifyDate(p.getDate()),
        isSelected: payments === 1,
      })),
    });
  }

  /**
   * Set costPriceBillItems in state for cost price modal
   * @param {List<BillItemModel>} billItems List of BillItems
   * @returns {void}
   */
  setCostPriceBillItems(billItems: List<BillItemModel>) {
    this.setState({ costPriceBillItems: billItems });
  }

  /**
   * Returns true if bill had been finalised, is not void, and is not being edited after
   * finalisation.
   * @returns {boolean}
   */
  isFinalisedAndValid(): boolean {
    return this.props.billAttributes.is_finalised && !this.props.billAttributes.is_void
      && !this.props.isEditingAfterFinalisation && !this.props.isSaving;
  }

  /**
   * Return true if the bill can be saved. For a new bill this requires bill creation permission,
   * for a non-new bill this requires update permisions for an unfinalised_bill, plus the bill either
   * being unfinalised or editing after finalisation and not void.
   * @returns {boolean}
   */
  canSave(): boolean {
    return (
      hasPermission(this.props.user, List([createPermission('finalised_bill', 'create')])) &&
      !(this.props.billAttributes.edited_by && this.props.billAttributes.edited_by.length > 0) // i.e. Has been saved.
    ) || (
      hasPermission(this.props.user, List([createPermission('unfinalised_bill', 'update')])) &&
      (!this.props.billAttributes.is_finalised || this.props.isEditingAfterFinalisation) &&
      !this.props.billAttributes.is_void
    );
  }

  /**
   * Returns true if one of the conditions is met
   * @returns {boolean}
  */
  isFinaliseButtonDisabled() {
    if (
      !this.props.config.getIn(['bills', 'aboveMaximumClaimableAmountCanProceed']) &&
      this.props.coveragePayor &&
      Number(getMaxClaimableAmountForBill(this.props.billAttributes, this.props.coveragePayors)) &&
      Number(getMaxClaimableAmountForBill(this.props.billAttributes, this.props.coveragePayors)) <
      calculateCoveragePayorTotal(this.props.billAttributes, this.props.billItems, this.props.usedDiscountsCharges)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Updates BillItems values
   * @param {string} itemID Item ID
   * @param {{}} newValues The new Values
   * @return {void}
   */
  updateCostPriceItemValues(itemID: string, newValues: { [key: string]: MapValue }) {
    const index = this.state.costPriceBillItems.findIndex(i => i.get('_id') === itemID);
    if (index > -1) {
      const item = this.state.costPriceBillItems.get(index, {});
      this.updateCostPriceBillItems(
        this.state.costPriceBillItems
          .set(index, new BillItemModel({ ...item.attributes, ...newValues })),
      );
    }
  }

  /**
   * Update BillItems in state
   * @param {List<BillItemModel>} costPriceBillItems List of BillItems
   * @returns {void}
   */
  updateCostPriceBillItems(costPriceBillItems: List<BillItemModel>) {
    this.setState({ costPriceBillItems });
  }

  /**
   * Delete temp_cost_price property
   * @param {BillItemModel} billItem BillItem model
   * @returns {void}
   */
  deleteTempCostPriceAttr(billItem: BillItemModel) {
    if (billItem.has('temp_cost_price')) {
      delete billItem.attributes.temp_cost_price; // eslint-disable-line no-param-reassign
    }
  }

  /**
   * Handle saving of BillItems with updated cost price
   * @returns {void}
   */
  saveBillItemsCostPrices() {
    this.setState({ isSavingBillItemsCostPrices: true });
    const modelsToSave = this.state.costPriceBillItems.map((b) => {
      let costPrice;
      if (typeof b.get('temp_cost_price') === 'string' || (b.get('temp_cost_price') === 0)) {
        costPrice = '';
      } else if (b.get('temp_cost_price')) {
        costPrice = b.get('temp_cost_price');
      } else if (!b.get('temp_cost_price') && b.get('cost_price')) {
        costPrice = b.get('cost_price');
      }
      return b.set('cost_price', costPrice);
    });
    // if any of the bill items cost price is changed, just update the state, no need to save in db
    // this will handle scenarios like new bill getting created, cost price updated before finalising,
    // and also bill is not saved, or while editing bill cost price saved but bill not finalised.
    if (this.props.onBillItemsChanged !== undefined) {
      this.props.onBillItemsChanged(modelsToSave);
      this.updateCostPriceBillItems(modelsToSave);
      this.setState({ costPricesModalVisible: false, isSavingBillItemsCostPrices: false });
    }
  }

  /**
   * Handle closing of Cost Price modal
   * @returns {void}
   */
  handleCancelCostPriceModal() {
    this.state.costPriceBillItems.map(m => this.deleteTempCostPriceAttr(m));
    this.setState({ costPricesModalVisible: false });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { appointment } = this.props;
    const selectedPayments = this.props.paymentsToBePrint.filter(p => p.isSelected);
    const costPriceBillItems = this.state.costPriceBillItems &&
    this.state.costPriceBillItems.filter(c => c.get('sales_item_id') || c.get('drug_id') || c.has('procedure_type_id'));
    const isTeleConsult = appointment && appointment.isTeleConsult();
    const shouldRenderFinaliseBillSaveButton = this.props.isFromAddPastBill ||
      (isTeleConsult && this.props.encounter.getValue('doctor')) ||
      (!isTeleConsult && (this.props.encounter.getValue('location') && this.props.encounter.getValue('doctor')));
    const canSave = this.canSave();
    return (
      <SidebarButtonsContainer>
        <StatelessModal
          id="printReceiptModal"
          noButton
          visible={this.state.printReceiptModalVisible}
          setVisible={isVisible => this.setState({ printReceiptModalVisible: isVisible })}
          title={translate('print_receipt')}
          explicitCloseOnly
          dataPublicHeader
        >
          <div className="u-margin--standard">
            <div className="u-padding-bottom--2ws">
              {translate('number_of_payments_for_this_bill',
                {
                  isAre: this.props.paymentsToBePrint.size > 1 ? 'are' : 'is',
                  number: this.props.paymentsToBePrint.size,
                })}
            </div>
            { this.props.paymentsToBePrint.map((p, i) =>
              <div className="_u-padding-bottom--1ws">
                <Checkbox
                  className=""
                  id="selectPayment"
                  onValueChanged={() => {
                    const newValue = Object.assign({}, p, { isSelected: !p.isSelected });
                    const index = this.props.paymentsToBePrint.findIndex(idx => idx.id === p.id);
                    const newPaymentsToBePrint = this.props.paymentsToBePrint
                      .splice(index, 1, newValue);
                    this.props.setDocumentPrintFactoryState({
                      paymentsToBePrint: newPaymentsToBePrint,
                    });
                  }}
                  options={[{ label: `${translate('payment')} ${i + 1}`, value: p.isSelected.toString() }]}
                  value={['true']}
                  label=""
                  containerStyle={{ marginBottom: 0 }}
                />
                <div style={{ marginLeft: 45 }}>
                  <p><span className="u-strong">{translate('amount')}:</span> {convertNumberToPrice(p.amount)}</p>
                  <p><span className="u-strong">{translate('payment_type')}:</span> {p.method}</p>
                  <p><span className="u-strong">{translate('date_received')}:</span> {p.dateReceived}</p>
                </div>
              </div>)
            }
          </div>
          <ModalFooter>
            <Button
              className="o-button o-button--small u-margin-right--half-ws"
              onClick={this.props.onPrintInvoiceClicked}
              disabled={!selectedPayments.size}
              dataPublic
            >{ translate('print') }
            </Button>
          </ModalFooter>
        </StatelessModal>
        <StatelessModal
          id="printDocumentTemplate"
          noButton
          visible={this.props.printModalVisible}
          setVisible={
            isVisible => this.props.setDocumentPrintFactoryState({ printModalVisible: isVisible })
          }
          title={this.props.documentDataType ? translate(`print_${this.props.documentDataType}`) : ''}
          explicitCloseOnly
          onClose={() => this.props.setDocumentPrintFactoryState({ isPrinting: false })}
        >
          <DocumentDataFormContainer
            documentTemplate={this.props.documentTemplateForPrint}
            closeModal={() => this.props.setDocumentPrintFactoryState({
              printModalVisible: false,
              isPrinting: false,
            })}
            encounterId={this.props.encounter.get('_id')}
            patientId={this.props.patient.get('_id')}
            customDocumentDataTitle={this.props.documentDataTitle}
          />
        </StatelessModal>
        {
          this.isFinalisedAndValid() &&
          <Button
            className="o-button o-button--small u-margin--standard"
            style={{ marginTop: 0 }}
            disabled={this.props.isPrinting}
            onClick={() => {
              if (!this.props.paymentsToBePrint.size ||
               this.props.paymentsToBePrint.size === 1) {
                this.props.onPrintInvoiceClicked();
              } else {
                this.setState({ printReceiptModalVisible: true });
              }
            }}
            dataPublic
          >
            {translate('print_receipt')}
          </Button>
        }
        {
          this.isFinalisedAndValid() &&
          this.props.prescriptions.size > 0 &&
          <Button
            className="o-button o-button--small u-margin--standard"
            style={{ marginTop: 0 }}
            disabled={this.props.isPrinting}
            onClick={() => this.props.onPrintPrescriptionLabelsClicked()}
            dataPublic
          >
            {translate('print_prescription_labels')}
          </Button>
        }
        {
          this.isFinalisedAndValid() &&
          this.props.medicalCertificates.size > 0 &&
          this.props.medicalCertificates.first().has('days') &&
          <Button
            className="o-button o-button--small u-margin--standard"
            style={{ marginTop: 0 }}
            disabled={this.props.isPrinting}
            onClick={
              () => this.props.onPrintMedicalCertificateClicked(
                this.props.medicalCertificates.first(),
              )
            }
            dataPublic
          >
            {translate('print_mc')}
          </Button>
        }
        {
          this.isFinalisedAndValid() &&
          this.props.timeChits.size > 0 &&
          <Button
            className="o-button o-button--small u-margin--standard"
            style={{ marginTop: 0 }}
            onClick={() => this.props.onPrintTimeChitClicked(this.props.timeChits.first())}
            disabled={this.props.isPrinting}
            dataPublic
          >
            {translate('print_time_chit')}
          </Button>
        }
        {
          this.props.config.getIn(['bills', 'allowEditing'], false) &&
          this.isFinalisedAndValid() &&
          <PermissionWrapper permissionsRequired={List([createPermission('finalised_bill', 'update')])} user={this.props.user}>
            <Button
              className="o-button o-button--small u-margin--standard"
              onClick={this.props.onEditClicked}
              style={{ marginTop: 0 }}
              dataPublic
            >
              {translate('edit_bill')}
            </Button>
          </PermissionWrapper>
        }
        { canSave && shouldRenderFinaliseBillSaveButton &&
          <SaveButton
            disabled={this.isFinaliseButtonDisabled() || this.props.isFinaliseButtonBusy}
            showBusyIndicator={this.props.isFinaliseButtonBusy || this.state.isCheckingInteraction}
            className="u-margin--standard o-button--small"
            isSaving={this.props.isSaving}
            onClick={() => this.onFinalisationClicked()}
            label={translate('finalise_bill')}
            style={{ marginTop: 0 }}
          />
        }
        { canSave && !shouldRenderFinaliseBillSaveButton &&
          <QueueButtonContainer
            patient={this.props.patient}
            isBillingValidation
            showBusyIndicator={this.props.isFinaliseButtonBusy || this.state.isCheckingInteraction}
            encounterLocation={isTeleConsult ?
              undefined : appointment ? appointment.get('location') :
                this.props.encounter.getValue('location')}
            encounterID={this.props.encounter.get('_id')}
            encounterDoctor={appointment ?
              appointment.get('practitioner_id') : this.props.encounter.getValue('doctor')}
            buttonLabel="finalise_bill"
            onSuccess={() => this.onFinalisationClicked()}
            appointment={appointment}
          />
        }
        {
          hasSomePermission(this.props.user, List([createPermission('perm_view_edit_cost_price_right_side_bar', 'update'), createPermission('perm_view_edit_cost_price_right_side_bar', 'read')])) &&
          this.canSave() &&
            <StatelessModal
              id="viewEditCostPrices"
              buttonLabel={translate('view_edit_cost_prices')}
              buttonClass="o-button o-button--small u-margin--standard"
              visible={this.state.costPricesModalVisible}
              setVisible={isVisible => this.setState({ costPricesModalVisible: isVisible })}
              onClose={() => this.handleCancelCostPriceModal()}
              title={translate('view_edit_cost_prices')}
              explicitCloseOnly
              buttonStyle={{ marginTop: 0 }}
              disableButton={!costPriceBillItems || !costPriceBillItems.size}
              dataPublicHeader
            >
              <BillItemCostPriceList
                billItems={costPriceBillItems}
                drugs={this.props.drugs}
                salesItems={this.props.salesItems}
                isChangesCancelled={this.state.costPricesModalVisible}
                onValueChanged={(itemID, newValues) =>
                  this.updateCostPriceItemValues(itemID, newValues)
                }
                user={this.props.user}
                procedureTypes={this.props.procedureTypes}
              />
              <ModalFooter>
                <SaveButton
                  dataPublic
                  onClick={() => this.saveBillItemsCostPrices()}
                  isSaving={this.state.isSavingBillItemsCostPrices}
                  label={translate('update')}
                  className="o-button--small u-margin-right--half-ws"
                  disabled={
                    !hasPermission(this.props.user, List([
                      createPermission('perm_view_edit_cost_price_right_side_bar', 'update'),
                    ]))
                  }
                />
              </ModalFooter>
            </StatelessModal>
        }
        {
          this.isFinalisedAndValid() &&
          <Link
            className="o-button u-margin--standard o-button--small"
            style={{ marginTop: 0 }}
            to={`/patient/${this.props.billAttributes.patient_id}/billing/${this.props.billAttributes.encounter_id}/payments`}
          >
            {translate('view_payment_details')}
          </Link>
        }
        {
          this.isFinalisedAndValid() &&
          <PermissionWrapper permissionsRequired={List([createPermission('document_templates', 'create')])} user={this.props.user}>
            <StatelessModal
              id="currentConsultDocumentTemplate"
              buttonLabel={translate('create_document')}
              buttonClass="o-button o-button--small u-margin--standard"
              visible={this.state.documentTemplateModalVisible}
              setVisible={isVisible => this.setState({ documentTemplateModalVisible: isVisible })}
              title={translate('create_document')}
              explicitCloseOnly
              buttonStyle={{ marginTop: 0 }}
              dataPublicHeader
            >
              <AddDocumentDataView
                documentTemplates={this.props.documentTemplates.filter(d => d.isVisible() && d.hasLocation('bill'))}
                closeModal={() => this.setState({ documentTemplateModalVisible: false })}
                patientId={this.props.encounter.get('patient_id')}
                encounterId={this.props.encounter.get('_id')}
                isOnline={this.props.isOnline}
              />
            </StatelessModal>
          </PermissionWrapper>
        }
        {
          this.isFinalisedAndValid() &&
          this.props.onVoidClicked &&
          <PermissionWrapper permissionsRequired={List([createPermission('finalised_bill', 'delete')])} user={this.props.user}>
            <Button
              className="o-button o-button--danger u-margin--standard o-button--small"
              onClick={this.props.onVoidClicked}
              style={{ marginTop: 0 }}
              dataPublic
            >
              {translate('void_bill')}
            </Button>
          </PermissionWrapper>
        }
        {
          this.props.billAttributes.is_finalised && this.canSave() &&
          this.props.onCancelClicked &&
          <Button
            className="o-button o-button--danger u-margin--standard o-button--small"
            onClick={this.props.onCancelClicked}
            style={{ marginTop: 0 }}
            dataPublic
          >
            {translate('cancel_edit')}
          </Button>
        }
        <Confirm
          show={Boolean(this.state.proceedMessage)}
          proceed={() => {
            trackEvent(DRUG_ALLERGY_CHECKER, CLICK, 'dispensation_remove_and_acknowledge');
            this.onAcknowledge();
          }}
          cancel={() => {
            trackEvent(DRUG_ALLERGY_CHECKER, CLICK, 'dispensation_continue_dispensing');
            this.setState({ proceedMessage: '' });
            this.props.onFinaliseClicked(this.props.billItems, false);
          }}
          onClose={() => {
            trackEvent(DRUG_ALLERGY_CHECKER, CLICK, 'dispensation_close');
            this.setState({
              proceedMessage: '',
              interactedDrugs: List(),
            });
          }}
          modalTitle={translate('alert')}
          footerSaveButtonName={translate('acknowledge_and_continue')}
          footerCancelButtonName={translate('continue_dispensing')}
          confirmation={this.state.proceedMessage}
        />
      </SidebarButtonsContainer>
    );
  }
}

export default documentPrintFactory(BillSidebarButtons);
