import BaseModel from './baseModel';

export type Attributes = {
  _id: string,
  type: 'exports_remaining',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  count: number,
};

/**
 * ExportsRemainingModel
 *
 * @namespace ExportsRemainingModel
*/
class ExportsRemainingModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes = {}) {
    super(attributes);
    this.attributes.type = 'exports_remaining';
  }
}

export default ExportsRemainingModel;
