import { connect } from 'react-redux';
import { List } from 'immutable';

import ListEditor from './../components/listEditor/listEditor';
import translate from './../utils/i18n';
import { saveFactory } from './../utils/redux';
import { hasPermission, createPermission } from '../utils/permissions';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ isFetching, user, suppliers }: State) => ({
  columns: [
    {
      accessor: 'name', Header: translate('name'), minWidth: 200, filterable: true,
    },
    { accessor: 'address', Header: translate('address') },
  ],
  label: translate('clinics'),
  type: 'supplier',
  items: isFetching ? new List() : suppliers.filter(m => m.isVisible() && m.isClinic()),
  canAdd: hasPermission(user, List([createPermission('clinics', 'create')])),
  canEdit: hasPermission(user, List([createPermission('clinics', 'update')])),
  canDelete: hasPermission(user, List([createPermission('clinics', 'delete')])),
  supplierType: 'clinic',
  user,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const ClinicsContainer = connect(mapStateToProps, mapDispatchToProps)(ListEditor);

export default ClinicsContainer;
