import { List } from 'immutable';
import { UNICODE } from './../constants';

import type PatientModel from './../models/patientModel';
import type CoveragePayorModel from './../models/coveragePayorModel';
import type BillAttributes from './../models/billModel';

/**
 * a wrapper function to get the panel name using patient model's function. This will return emdash if there is no panel.
 * @param {PatientModel} patient patient for which panel name needs to be found
 * @param {List<CoveragePayorModel>} coveragePayors list of panels
 * @returns {string}
 */
export default function getPanelNameForPatient(
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
) {
  return patient && patient.hasCoverage() ?
    patient.getCoveragePayorName(coveragePayors) : UNICODE.EMDASH;
}

/**
 * Get coverage policy name
 * @param {Props} props This component Props
 * @returns {string} policy name.
 */
export function getCoveragePolicyName(props) {
  const {
    patient, coveragePayor, coveragePayors, coveragePayorPolicy,
  } = props;
  if (patient && patient.getCoveragePayorPolicyName(coveragePayors) !== UNICODE.EMDASH) {
    return patient.getCoveragePayorPolicyName(coveragePayors);
  } else if (coveragePayor && coveragePayor.get('policies') && coveragePayorPolicy) {
    const policy = coveragePayor.get('policies', []).find(p =>
      p._id === coveragePayorPolicy);
    return policy ? policy.policy_name : UNICODE.EMDASH;
  }
  return UNICODE.EMDASH;
}

/**
 * Get coverage policy details from coverage payor doc
 * @param {string} policyId policy id
 * @param {string} payorId coverage payor id
 * @param {List<CoveragePayorModel>} coveragePayors list of panels
 * @returns {object | undefined} policy data if found.
 */
function getPolicyFromCoverage(
  policyId: string | void,
  payorId: string | void,
  coveragePayors: List<CoveragePayorModel>,
) {
  if (policyId && payorId && coveragePayors) {
    const payor = coveragePayors.find(model => model.get('_id') === payorId);
    if (payor) {
      return payor.get('policies', []).find(model => model._id === policyId);
    }
    return undefined;
  }
  return undefined;
}

/**
 * Get default coverage policy details from coverage payor doc
 * @param {CoveragePayorModel} coveragePayor panel to find default from
 * @returns {object | undefined} policy data if found.
 */
function getDefaultPolicyFromCoverage(
  coveragePayor: CoveragePayorModel,
) {
  if (coveragePayor && Array.isArray(coveragePayor.get('policies')) && coveragePayor.get('policies').length) {
    return coveragePayor.get('policies')[0];
  }
  return undefined;
}

/**
 * Get coverage policy details from patient doc
 * @param {PatientModel} patient patient for which panel name needs to be found
 * @param {List<CoveragePayorModel>} coveragePayors list of panels
 * @returns {object | undefined} policy data if found.
 */
function getPatientCoveragePolicyData(
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
) {
  if (patient && patient.hasPolicy() && patient.hasCoverage()) {
    const policyId = patient.getCoveragePolicy();
    const payorId = patient.getCoveragePayor();
    return getPolicyFromCoverage(policyId, payorId, coveragePayors);
  }
  return undefined;
}

/**
 * Get copayment for patient using the coverage payor or policy
 * @param {PatientModel} patient patient for which panel name needs to be found
 * @param {List<CoveragePayorModel>} coveragePayors list of panels
 * @returns {number} co payment value
 */
export function getPatientCoPayment(
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
) {
  const policy = getPatientCoveragePolicyData(patient, coveragePayors);
  const patientCoveragePayorID = patient.getCoveragePayor();
  const coveragePayor = coveragePayors.find(m => m.get('_id') === patientCoveragePayorID);
  const policyCoPayment = policy ? Number(policy.policy_co_payment_amount || 0) : 0;
  const defaultPolicyCoPayment = coveragePayor && coveragePayor.get('policies') ? Number(coveragePayor.get('policies')[0].policy_co_payment_amount) || 0 : 0;
  return policy ? policyCoPayment : defaultPolicyCoPayment;
}

/**
 * Get maximum claimable amount for bill using the coverage payor or policy
 * @param {BillAttributes} attributes bill attributes
 * @param {List<CoveragePayorModel>} coveragePayors list of panels
 * @returns {number | undefined} maximum claimable amount
 */
export function getMaxClaimableAmountForBill(
  attributes: BillAttributes,
  coveragePayors: List<CoveragePayorModel>,
) {
  const coveragePayor = coveragePayors.find(m => m.get('_id') === attributes.coverage_payor_id);
  const policy = getPolicyFromCoverage(
    attributes.coverage_policy_id,
    attributes.coverage_payor_id, coveragePayors,
  ) || getDefaultPolicyFromCoverage(coveragePayor);

  if (policy) {
    if (Number(policy.policy_maximum_claimable_amount)
      || policy.policy_maximum_claimable_amount === 0) {
      return Number(policy.policy_maximum_claimable_amount);
    }
    return undefined;
  }
  return undefined;
}
