import React from 'react';
import glamorous from 'glamorous';

import { createSuccessNotification } from './../../utils/notifications';
import TextArea from './../inputs/textarea';
import FormError from './../formError';
import { getRemoteDB, saveModel } from './../../utils/db';
import { docToModel, typeToCouchModelMap } from './../../utils/models';
import { wsUnit } from '../../utils/css';
import Input from './../inputs/input';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import SaveButton from './../buttons/saveButton';
import { getStore } from '../../utils/redux';
import DeleteForm from './deleteForm';
import { getEncryptKey } from '../../utils/auth';
import { getUserName } from "../../utils/user";
import { logToAppInsight } from './../../utils/logging';
import Button from './../buttons/button';
import DataQuery from './dataQuery';
import { MapValue } from './../../types';
import BaseModel from './../../models/baseModel';

const SearchWrapper = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  '& .o-form__item': {
    flexGrow: 1,
    marginRight: wsUnit,
  },
});

const CodeHighligherWrapper = glamorous.pre({
  fontFamily: 'monospace',
  backgroundColor: '#eaeaea',
  padding: 10,
  borderRadius: 5,
  wordBreak: 'break-word',
  whiteSpace: 'normal',
  maxHeight: '80vh',
  overflowY: 'auto'
});

type State = {
  searchValue: string,
  currentDocument: MapValue,
  currentDocumentValues: string,
  errorMessage: string,
  changesMade: boolean,
  isEditing: boolean,
  isCreating: boolean,
  isSaving: boolean,
  isSearching: boolean,
  recentDocumentIDs: Array<string>,
  reasonValue: string,
  deleteModalVisible: boolean,
};

/**
 * DataDebug
 * @namespace DataDebug
 */
class DataDebug extends React.Component<Props, State> {
  /**
   * Creates an instance of DataDebug.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: '',
      currentDocument: {},
      currentDocumentValues: '', // doc values to render
      errorMessage: '',
      changesMade: false,
      isEditing: false,
      isCreating: false,
      isSaving: false,
      recentDocumentIDs: [],
      reasonValue: '',
      deleteModalVisible: false,
      isSearching: false,
    };
    this.handleSearchDocument = this.handleSearchDocument.bind(this);
  }

  /**
   * Called when component completely mounted
   * @return {void}
   */
  componentDidMount() {
    this.updateRecentDocumentIDsFromState();
  }

  /**
   * Handle searching of document
   * @param {string} searchValue Value from search field
   * @returns {void}
   */
  handleSearchDocument(searchValue: string) {
    return getRemoteDB()
      .get(searchValue)
      .then((doc: MapValue) => {
        if (!doc.type) {
          logToAppInsight("Document doesn't have a type", null, doc);
          throw new Error("Document doesn't have a type");
        }
        this.updateRecentDocIDsFromCookies(doc._id);
        this.setState({
          currentDocument: docToModel(doc) || new BaseModel(doc),
          isCreating: false,
          isEditing: false,
          isSearching: false,
          reasonValue: '',
        }, () => {
          if (this.state.errorMessage) {
            this.setState({ errorMessage: '' });
          }
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.status === 404 ? 'document_not_found' : error.message,
          currentDocument: {},
          currentDocumentValues: '',
          isSearching: false,
          isCreating: false,
          isEditing: false,
          reasonValue: '',
        });
      });
  }

  /**
   * Validate JSON
   * @param {string} str the string to validate
   * @returns {boolean}
   */
  IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Handle saving of document
   * @returns {void}
   */
  handleSavingDoc() {
    this.setState({ isSaving: true });
    if (!this.IsJsonString(this.state.currentDocumentValues)) {
      return this.setState({ errorMessage: 'invalid_json_object', isSaving: false });
    }
    const docValue = JSON.parse(this.state.currentDocumentValues);
    const docType = docValue.type;
    const protectedKeys = ['type', '_id', '_rev', 'created_by', 'edited_by'];
    if (!docType && this.state.isCreating) {
      return this.setState({ errorMessage: 'document_type_not_provided', isSaving: false });
    }
    const removedTypeProtectedKeys = protectedKeys.slice(2, protectedKeys.length); // remove "type", "_id" from protectedKeys
    if (this.state.isCreating &&
      Object.keys(docValue).some(k => removedTypeProtectedKeys.includes(k))) {
      return this.setState({ errorMessage: 'no_need_to_add_other_protected_keys', isSaving: false });
    }
    if (this.state.isEditing && Object.keys(docValue).some(k => protectedKeys.includes(k))) {
      return this.setState({ errorMessage: 'not_allowed_to_edit_protected_keys', isSaving: false });
    }
    if (docType && !typeToCouchModelMap[docType]) {
      return this.setState({ errorMessage: 'invalid_document_type', isSaving: false });
    }
    if (!this.state.reasonValue.length || !this.state.currentDocumentValues.length) {
      return this.setState({ errorMessage: 'fill_required_fields', isSaving: false });
    }
    if (this.state.isCreating) {
      const model = new typeToCouchModelMap[docType]();
      saveModel(
        model.set({ ...docValue }),
        getStore().dispatch,
        getEncryptKey(),
        getUserName(),
        0,
        false,
        this.state.reasonValue,
      )
        .then((savedModel) => {
          this.setState({ currentDocumentValues: '', isSaving: false, isCreating: false, changesMade: false, errorMessage: '', reasonValue: '' });
          createSuccessNotification(translate('document_created_successfully'));
          this.updateRecentDocIDsFromCookies(savedModel.get('_id'));
          this.updateRecentDocumentIDsFromState();
        });
    } else {
      const updatedModel = Object.assign(this.state.currentDocument.attributes,
        JSON.parse(this.state.currentDocumentValues));
      // if deleted some keys from editor, then need the doc needs to be updated.
      const inputKeys = Object.keys(JSON.parse(this.state.currentDocumentValues));
      const updatedModelKeys = Object.keys(updatedModel).filter(u => !protectedKeys.includes(u));
      const keysToBeDeleted = updatedModelKeys.filter(u => !inputKeys.includes(u));
      if (keysToBeDeleted.length > 0) {
        keysToBeDeleted.map(k => delete updatedModel[k]);
      }
      saveModel(
        this.state.currentDocument.set({ ...updatedModel }),
        getStore().dispatch,
        getEncryptKey(),
        getUserName(),
        0, false, this.state.reasonValue,
      )
        .then(() => {
          this.setState({ isSaving: false, isEditing: false, changesMade: false, errorMessage: '', reasonValue: '', currentDocumentValues: '' });
          createSuccessNotification(translate('document_updated_successfully'));
        });
    }
    return true;
  }

  /**
   * Handle edit
   * @returns {void}
   */
  handleEdit() {
    this.setState({
      isEditing: true,
      currentDocumentValues: JSON.stringify(this.getDocObjectToRender()),
    });
  }

  /**
   * Handle Deletion of Doc
   * @returns {void}
   */
  handleDelete() {
    if (!this.state.reasonValue.length) {
      this.setState({ errorMessage: 'fill_required_fields', isSaving: false });
    } else {
      if (this.state.recentDocumentIDs.length > 0) {
        this.handleDeleteRecentDocIDs(this.state.currentDocument.get('_id'), false);
      }
      saveModel(this.state.currentDocument.set({ _deleted: true }),
        getStore().dispatch,
        getEncryptKey(),
        getUserName(),
        0, false, this.state.reasonValue)
        .then(() => createSuccessNotification(translate('deleted_successfully')));
      this.setState({
        currentDocument: {},
        currentDocumentValues: '{}',
        searchValue: '',
        isEditing: false,
        errorMessage: '',
        changesMade: false,
        reasonValue: '',
        deleteModalVisible: false,
      });
    }
  }

  /**
   * Handle deletion of recent docs
   * @param {string} itemIndex the item index
   * @param {boolean} isDeleteCurrentDoc set to false if we dont want to delete current doc from state
   * @returns {void}
   */
  handleDeleteRecentDocIDs(itemIndex: string, isDeleteCurrentDoc: boolean = true) {
    const index = this.state.recentDocumentIDs.indexOf(itemIndex);
    if (index > -1) {
      this.state.recentDocumentIDs.splice(index, 1).toString();
      document.cookie = `data_debug_recent_doc_ids=[${this.state.recentDocumentIDs.toString()}]`;
      this.updateRecentDocumentIDsFromState();
      const currentDocument = isDeleteCurrentDoc ? { currentDocument: {} } : {};
      this.setState({ ...currentDocument, currentDocumentValues: '{}' });
    }
  }

  /**
   * Handle create
   * @returns {void}
   */
  handleCreate() {
    this.setState({ isCreating: true, isEditing: false, currentDocument: {}, currentDocumentValues: '{}', searchValue: '', reasonValue: '' });
  }

  /**
   * Get doc object to render, removed _id, _rev, created_by and edited_by
   * @param {boolean} viewProtected Set to true to view protected fields
   * @returns {Object}
   */
  getDocObjectToRender(viewProtected: boolean = false) {
    const docObject = Object.assign({}, this.state.currentDocument.attributes);
    if (!viewProtected) {
      delete docObject._id;
      delete docObject._rev;
      delete docObject.created_by;
      delete docObject.edited_by;
      delete docObject.type;
    }
    return docObject;
  }

  /**
   * Get recent doc ids from browser cookies
   * @returns {Array<string>}
   */
  getRecentDocIDsFromCookies(): Array<string> {
    const cookieValue = document.cookie
      .replace(/(?:(?:^|.*;\s*)data_debug_recent_doc_ids\s*\=\s*([^;]*).*$)|^.*$/, '$1') // eslint-disable-line
      .substr(1)
      .slice(0, -1)
      .split(',')
      .filter(c => c);
    return cookieValue;
  }

  /**
   * updateRecentDocIDsFromCookies
   * @param {string} docID Doc id
   * @returns {void}
   */
  updateRecentDocIDsFromCookies(docID: string) {
    const recentDocIDs = this.getRecentDocIDsFromCookies();
    if (!recentDocIDs || !recentDocIDs.includes(docID)) {
      const newDocIDs = [...recentDocIDs];
      newDocIDs.push(docID);
      document.cookie = `data_debug_recent_doc_ids=[${newDocIDs.toString()}]`;
      this.state.recentDocumentIDs.push(docID);
      if (newDocIDs.length > 20) {
        document.cookie = `data_debug_recent_doc_ids=[${newDocIDs.toString()}]`;
      }
    }
  }

  /**
   * Update Recent DocumentIDs From State
   * @returns {void}
   */
  updateRecentDocumentIDsFromState() {
    this.setState({ recentDocumentIDs: this.getRecentDocIDsFromCookies() });
  }

  /**
   * Render stringified JSON object
   * @param {Object} code json object
   * @returns {string}
   */
  renderCode(code: Object) {
    return (
      <CodeHighligherWrapper>
        {JSON.stringify(code, null, 2)}
      </CodeHighligherWrapper>
    );
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const recentDocumentIDs = [...this.state.recentDocumentIDs];
    return (
      <ContentTransition className="o-scrollable-container o-main__content">
        <section style={{ height: 'fit-content' }}>
          { this.state.errorMessage && !this.state.deleteModalVisible &&
            <div className="u-margin--standard">
              <FormError>{translate(this.state.errorMessage)}</FormError>
            </div>
          }

          { this.state.deleteModalVisible &&
            <DeleteForm
              isVisible={this.state.deleteModalVisible}
              setVisible={visible => this.setState({ deleteModalVisible: visible })}
              reasonValue={this.state.reasonValue}
              onChangedReason={reason => this.setState({ reasonValue: reason })}
              onClickDelete={() => this.handleDelete()}
              isSaving={this.state.isSaving}
              onClose={() => this.setState({ reasonValue: '' })}
            />
          }

          <div className="o-header u-flex-space-between">
            <h1 data-public>{translate('data_debug')}</h1>
            <Button
              className="o-button o-button--small"
              onClick={() => this.handleCreate()}
              disabled={this.state.isCreating}
              dataPublic
            >Create New Doc
            </Button>
          </div>
          <div className="o-form">
            <div className="o-form__column-wrapper">
              <div className="o-form__column u-margin-top--standard">
                { (!this.state.isCreating) ?
                  <div>
                    <SearchWrapper>
                      <Input
                        id="input"
                        label="Search Document ID"
                        value={this.state.searchValue}
                        onValueChanged={searchValue => this.setState({ searchValue })}
                        required
                      />
                      <SaveButton
                        dataPublic
                        isSaving={this.state.isSearching}
                        onClick={() => {
                          this.handleSearchDocument(this.state.searchValue);
                          this.setState({ isSearching: true });
                        }}
                        label={translate('search')}
                        savingLabel={translate('searching')}
                        disabled={!this.state.searchValue}
                      />
                    </SearchWrapper>
                    {this.state.recentDocumentIDs.length > 0 &&
                      <React.Fragment>
                        <p className="u-strong">{translate('recent_documents')}:</p>
                        <div style={{maxHeight: "80vh", overflowY: "auto"}}>
                          {recentDocumentIDs
                            .reverse()
                            .slice(0, 20)
                            .map(r => (
                              <div key={r} className="u-border--bottom u-flex-row u-flex-space-between">
                                <div
                                  className="u-padding--half-ws"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => this.handleSearchDocument(r)}
                                >{r}
                                </div>
                                <Button
                                  onClick={() => {
                                    this.handleDeleteRecentDocIDs(r);
                                  }}
                                  className="o-text-button o-text-button--danger"
                                >{translate('delete')}
                                </Button>
                              </div>
                            ))
                          }
                        </div>
                      </React.Fragment>
                    }
                  </div>
                  :
                  <div>
                    <p className="u-strong">{translate('example')}:</p>
                    {this.renderCode({ type: 'bank', name: 'Bank Name' })}
                  </div>
                }

              </div>
              <div className="o-form__column">
                { (Object.keys(this.state.currentDocument).length > 0 && !this.state.isCreating) ?
                  <div className="u-pull-right">
                    <Button dataPublic className="o-text-button o-text-button--contextual" onClick={() => this.handleEdit()}>{translate('edit_document')}</Button>
                    <Button dataPublic className="o-text-button o-text-button--danger" onClick={() => this.setState({ deleteModalVisible: true })}>{translate('delete_document')}</Button>
                  </div>
                  : <div />
                }
                <br />
                { (!this.state.isEditing && !this.state.isCreating) &&
                  <p className="u-strong">{translate('result')}:</p>
                }
                { (this.state.isEditing || this.state.isCreating) ?
                  <div>
                    <TextArea
                      id="doc"
                      onValueChanged={value => this.setState({
                        currentDocumentValues: value,
                        changesMade: true,
                      })}
                      label={translate(this.state.isCreating ? 'create_new_document' : 'edit_document')}
                      value={this.state.currentDocumentValues}
                      inputStyle={{ fontFamily: 'monospace' }}
                      required
                    />
                    <Input
                      id="reason"
                      label={translate('reason')}
                      value={this.state.reasonValue}
                      onValueChanged={reasonValue => this.setState({ reasonValue })}
                      required
                    />
                    <SaveButton
                      dataPublic
                      isSaving={this.state.isSaving}
                      onClick={() => this.handleSavingDoc()}
                      label={translate('save')}
                      savingLabel={translate('saving')}
                      disabled={!this.state.changesMade}
                      className="o-button o-button--small u-margin-right--half-ws"
                    />
                    <Button
                      className="o-button o-button--small o-button--danger"
                      onClick={() => this.setState({
                        isCreating: false,
                        isEditing: false,
                        changesMade: false,
                        errorMessage: '',
                        reasonValue: '',
                      })}
                      dataPublic
                    >
                      {translate('cancel')}
                    </Button>
                  </div>
                  :
                  this.renderCode(this.getDocObjectToRender(true))
                }
              </div>
            </div>
          </div>
        </section>
        <DataQuery
          handleSearchDocument={this.handleSearchDocument}
        />
      </ContentTransition>
    );
  }
}

export default DataDebug;
