import React, { useEffect, useState } from 'react';
import { List, Map } from 'immutable';
import { Ul } from 'glamorous';
import translate from '../../utils/i18n';
import { getConfirmation, transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import { renderListItem } from '../../utils/tables';
import { getModelMapFromList } from '../../utils/models';
import { createSuccessNotification } from '../../utils/notifications';
import ContentTransition from '../contentTransition';
import Header from '../header/header';
import Table from '../table/table';
import TableColumnsSettings from '../table/tableColumnsSettings';
// import FlowForm from './flowForm';
import Button from '../buttons/button';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission, hasPermission } from '../../utils/permissions';

import type EncounterStageModel from '../../models/encounterStageModel';
import type SalesItemModel from '../../models/salesItemModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type { Config, SaveModel, User } from '../../types';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterModel from '../../models/encounterModel';
import type PatientCampaignModel from '../../models/patientCampaignModel';
import DiscountsChargesForm from './discountsChargesForm';
import DiscountChargeModel from '../../models/discountChargeModel';
import ListEditorTable from '../listEditor/listEditorTable';
import DiscountsChargesOrderForm from './discountsChargesOrderForm';

const COLUMNS = [
  { accessor: 'order', Header: translate('Order'), show: false },
  { accessor: 'name', Header: translate('name') },
  { accessor: 'method', Header: translate('discount_s_charge'), filterable: false },
  { accessor: 'type', Header: translate('type'), filterable: false, sortable: false },
  { accessor: 'amount', Header: translate('amount'), filterable: false },
  { accessor: 'alwaysApplied', Header: translate('always_applied'), filterable: false },
];

type Props = {
  stagesMap: Map<string, EncounterStageModel>;
  salesItems: Map<string, SalesItemModel>;
  discountsCharges: List<DiscountChargeModel>;
  updateConfig: (config: Config) => void;
  config: Config;
  saveModel: SaveModel;
  coveragePayors: List<CoveragePayorModel>;
  encounterFlows: List<EncounterFlowModel>;
  currentEncounters: List<EncounterModel>;
  user: User;
  fetchRelatedCampaigns: (flowId: string) => Promise<List<PatientCampaignModel>>
}

/**
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const DiscountsCharges = (props: Props) => {
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));
  const [addFlowFormVisible, setAddFlowFormVisible] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<EncounterFlowModel>();
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [selectedDiscountCharge, setSelectedDiscountCharge] = useState<DiscountChargeModel>();
  const [flowCampaignMap, setFlowCampaignMap] = useState<
    Map<string, List<PatientCampaignModel>>
  >(Map());
  const showFlowForm = Boolean(addFlowFormVisible || selectedFlow);

  useEffect(() => {
    const selectedFlowId = selectedFlow?.get('_id');
    if (selectedFlowId && !flowCampaignMap.has(selectedFlowId)) {
      props.fetchRelatedCampaigns(selectedFlowId)
        .then((campaignDocs) => {
          if (campaignDocs) {
            setFlowCampaignMap(flowCampaignMap.set(selectedFlowId, campaignDocs));
          }
        })
        .catch(() => {});
    }
  }, [selectedFlow]);

  /**
   * prepares columns data for the tablel.
   * @returns {Array<Column>}
   */
  const getColumns = () => {
    const modColumns = [...COLUMNS];
    if (hasPermission(props.user, List([createPermission('discounts_charges', 'update')]))) {
      modColumns.push({ accessor: 'edit', Header: '', sortable: false, show: true, width: 80 });
    }
    if (hasPermission(props.user, List([createPermission('discounts_charges', 'delete')]))) {
      modColumns.push({
        accessor: 'delete', Header: '', sortable: false, width: 80, show: true,
      });
    }
    return modColumns;
  };

  /**
   * Hides Flow form modal
   * @returns {void}
   */
  const hideModal = () => {
    setSelectedDiscountCharge(undefined);
    setAddFlowFormVisible(false);
  };

  /**
   * Hides order form modal
   * @returns {void}
   */
  const hideOrderModal = () => {
    setSelectedDiscountCharge(undefined);
    setShowOrderForm(false);
  };

  /**
   * Deletes the selected encounter flow model
   * @returns {Promise<any>}
   */
  const deleteFlow = () => props.saveModel(selectedDiscountCharge?.set('hidden', true)).then(() => {
    createSuccessNotification(translate('item_deleted'));
    hideModal();
  });

  /**
   * Deletes the selected encounter flow model
   * @param {DiscountChargeModel} discountCharge discountCharge to delete
   * @returns {Promise<any>}
   */
  const deleteDiscountCharge = (discountCharge: DiscountChargeModel) => {
    getConfirmation(translate('confirm_x_deletion', { x: discountCharge?.get('name') }), {
      modalTitle: `${translate('delete_x', { x: discountCharge?.get('name') })}`,
      footerSaveButtonName: translate('delete_x', { x: discountCharge.get('method') }),
    })
      .then(() => props.saveModel(discountCharge?.set('hidden', true)).then(() => {
        createSuccessNotification(translate('item_deleted'));
        hideModal();
      }));
  };

  /**
   * Handles Delete stage
   * @returns {Promise<void>}
   */
  const onDelete = () => {
    if (flowCampaignMap.get(selectedFlow?.get('_id'))?.size) {
      const messageContent = (
        <>
          <p className="u-margin-bottom--half-ws">{translate('this_cant_be_undone')}</p>
          <p className="u-margin-bottom--half-ws">{translate('this_flow_is_tied_to_following_campaigns')}</p>
          <Ul className="u-margin-bottom--half-ws" listStyle="none">
            {flowCampaignMap.get(selectedFlow?.get('_id'))?.map(campaign => (
              <li> - {campaign.get('name')}</li>
            ))}
          </Ul>
          <p>{translate('campaigns_may_no_longer_active_after_flow_deletion')}</p>
        </>
      );
      return getConfirmation(messageContent, {
        modalTitle: `${translate('delete_x', { x: selectedFlow?.get('name') })}?`,
        footerSaveButtonName: translate('delete_x', { x: 'Flow' }),
      })
        .then(deleteFlow)
        .catch(() => {});
    }
    const hasEncountersWithSelectedFlow = props.currentEncounters.some(encounter =>
    // eslint-disable-next-line camelcase
       selectedFlow?.get('_id') === encounter.get('flow')?.flow_id);
    if (hasEncountersWithSelectedFlow) {
      const messageContent = (
        <>
          <p className="u-margin-bottom--half-ws">{translate('there_are_queued_patients_using_this_flow_message')}</p>
          <p>{translate('there_are_queued_patients_using_this_flow_message_description')}</p>
        </>
      );
      return getConfirmation(messageContent, {
        modalTitle: `${translate('error')} - ${translate('there_are_queued_patients_using_this_flow')}`,
        hideCancel: true,
        footerSaveButtonName: translate('acknowledge'),
      })
      // Can't delete flow in case of flow is in use. Hence leaving `then` as empty
        .then(() => {})
        .catch(() => {});
    }
    return getConfirmation(`${translate('delete_x', { x: selectedFlow?.get('name') })}?`, {
      modalTitle: translate('this_cant_be_undone'),
      footerSaveButtonName: translate('delete_x', { x: 'Flow' }),
    })
      .then(deleteFlow)
      .catch(() => {});
  };

  /**
   * Returns table row data
   * @returns {Array<Row>}
   */
  const getFlowRows = () => props.discountsCharges.map((charge, i) => {
    const discountChargeOrder = props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()).findIndex((c: string) => c === charge.get('_id'));
    return ({
      order: discountChargeOrder < 0 ? props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()).size + i : discountChargeOrder,
      name: charge.get('name'),
      type: charge.get('calculation_type') === 'fixed-percent' ? 'Fixed %' : charge.get('calculation_type'),
      method: charge.get('method'),
      amount: charge.get('amount'),
      alwaysApplied: charge.get('always_applied') ? 'Yes' : 'No',
      edit: (
        <Button
          className="o-text-button o-text-button--contextual"
          onClick={() => {
            setSelectedDiscountCharge(charge);
            setAddFlowFormVisible(true);
          }}
          dataPublic
        >
          {translate('edit')}
        </Button>
      ),
      delete: (
        <Button
          className="o-text-button o-text-button--danger"
          onClick={() => deleteDiscountCharge(charge)}
          dataPublic
        >
          {translate('delete')}
        </Button>
      ),
    });
  }).toArray();

  return (
    <ContentTransition>
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <h1 className="o-title">{translate('discounts_charges')}</h1>
        <div className="o-card u-margin-bottom--4ws">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{ translate('discounts_charges') }</h1>
            <div className="u-flex-right u-margin-right--half-ws">
              <TableColumnsSettings
                config={props.config}
                configFieldName="encounter_flows"
                originalColumns={List(transformColumnKeys(COLUMNS))}
                columns={columns}
                onUpdateColumns={setColumns}
                updateConfig={props.updateConfig}
              />
            </div>
          </Header>
          <div className="o-header-actions">
            <PermissionWrapper permissionsRequired={List([createPermission('discounts_charges', 'update')])} user={props.user}>
              <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
                <button className="o-button o-button--small" onClick={() => setShowOrderForm(true)}>{translate('edit_x', { x: translate('order') }) }</button>
              </div>
            </PermissionWrapper>
            <PermissionWrapper permissionsRequired={List([createPermission('discounts_charges', 'create')])} user={props.user}>
              <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
                <button className="o-button o-button--small" onClick={() => setAddFlowFormVisible(true)}>{translate('add_new_x', { x: translate('discount_s_charge') })}</button>
              </div>
            </PermissionWrapper>
          </div>
          <div className={`${hasPermission(props.user, List([createPermission('discounts_charges', 'update')])) || hasPermission(props.user, List([createPermission('discounts_charges', 'delete')])) ? 'rt-frozen-cols-2' : ''}`}>
            <Table
              columns={getColumns()}
              data={getFlowRows()}
              noDataText="No Discount/Charges found"
              defaultSorted={[{ id: 'order', desc: false }]}
            />
          </div>
        </div>
      </section>
      {showOrderForm &&
        <DiscountsChargesOrderForm
          discountsCharges={props.discountsCharges}
          updateConfig={props.updateConfig}
          config={props.config}
          saveModel={props.saveModel}
          isVisible={showOrderForm}
          hideModal={hideOrderModal}
          user={props.user}
        />}
      {showFlowForm &&
        <DiscountsChargesForm
          stagesMap={props.stagesMap}
          discountCharge={selectedDiscountCharge}
          salesItems={props.salesItems}
          updateConfig={props.updateConfig}
          config={props.config}
          saveModel={props.saveModel}
          coveragePayors={props.coveragePayors}
          isVisible={showFlowForm}
          hideModal={hideModal}
          selectedFlow={selectedFlow}
          onDelete={onDelete}
          encounterFlowMap={getModelMapFromList(props.encounterFlows)}
          user={props.user}
        />}
    </ContentTransition>
  );
};

export default DiscountsCharges;
