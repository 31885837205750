import React from 'react';
import glamorous from 'glamorous';
import Input from '../inputs/input';
import Label from '../inputs/label';
import Select from '../inputs/select';
import translate from '../../utils/i18n';
import { CAMPAIGN_TIME_UNITS } from '../../constants';

import type { EnrolAt } from '../../types';

type Props = {
  errorMessage: string,
  onChange: (value: EnrolAt) => void,
  enrolmentValue: number | null,
  enrolmentUnit: string,
  disabled?: boolean,
  disableProspective?: boolean
};

const FormFieldContainer = glamorous.div({
  alignItems: 'center',
});

const SubLabel = glamorous.span({
  margin: '0 1rem 1.333rem 0',
  fontStyle: 'normal',
});

/**
 * Renders a component showing the schedule form section of the sms campaign form.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
function EnrolmentFormField(props: Props) {
  /**
   * Calls when form schedule value change
   * @param {string} value props.
   * @returns {void}
   */
  function onEnrolmentUnitChange(value: string) {
    props.onChange({
      enrolmentUnit: value,
      enrolmentValue: props.enrolmentValue,
    });
  }

  /**
   * Calls when form schedule unit change
   * @param {number | string} value props.
   * @returns {void}
   */
  function onEnrolmentValueChange(value: number | string) {
    props.onChange({
      enrolmentUnit: props.enrolmentUnit,
      enrolmentValue: value !== '' ? parseInt(value, 10) : null,
    });
  }

  return (
    <React.Fragment>
      <div>
        <Label
          id="scheduled-day-label"
          label={`${translate('patients_who_have_visited_recently')}:`}
          invalid={false}
        />
      </div>
      <div>
        <FormFieldContainer className="u-flex-row">
          <SubLabel className="o-sub-label">{translate('patients_who_have_visited_in_the_past')}</SubLabel>
          <Input
            id="rule-schedule-value"
            label=""
            divClassName="u-width-180 u-margin-right--half-ws"
            type="number"
            value={props.enrolmentValue ? String(props.enrolmentValue) : ''}
            placeholder="#"
            min={1}
            max={365}
            onValueChanged={props.disableProspective ? () => { } : onEnrolmentValueChange}
            disabled={props.disableProspective || props.disabled}
          />
          <Select
            id="rule-schedule-unit"
            label=""
            options={CAMPAIGN_TIME_UNITS}
            value={props.enrolmentUnit}
            onValueChanged={props.disableProspective ? () => { } : onEnrolmentUnitChange}
            className="u-width-180 u-margin-right--half-ws"
            clearable={false}
            disabled={props.disableProspective || props.disabled}
          />
        </FormFieldContainer>
      </div>
      <div>
        {props.errorMessage && (
          <div
            className="o-text-input__error"
            style={{ marginTop: 0, marginBottom: '1rem' }}
          >
            {props.errorMessage}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default EnrolmentFormField;
