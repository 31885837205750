import React, { useRef, useEffect } from 'react';
import { List } from 'immutable';

import { removeEndOfLineSpaces, getBaseApiUrl } from './../../utils/utils';
import PatientModel from '../../models/patientModel';
import PatientStubModel from '../../models/patientStubModel';
import APIError from '../../utils/apiError';
import translate from './../../utils/i18n';

import type { Config } from './../../types';

type Message = {
  message: string,
  sender: string,
};

type Props = {
  config: Config,
  messages: List<Message>,
  patient: PatientModel | PatientStubModel,
  currentDataViewsError: APIError,
  isFetching: boolean,
}

/**
 * Renders a component shows the messages for the specific encouter of sms campaign.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered components
*/
export default function SmsJobMessages(props: Props) {
  const messagesEndRef = useRef(null);
  const { isFetching } = props;
  const isEmpty = props.messages.size === 0;
  const isError = props.currentDataViewsError;
  /**
  * A util for scroll down to the bootom of message
  * @return {void}
  */
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [isFetching, props.messages.size]);

  /**
  * Function which render specific message on the container.
  * @param {string} reply which needs to be render.
  * @param {number} index index of map of message
  * @return {HTMLElement}
  */
  function renderMessage(reply: Message, index: number) {
    const { sender, message, status } = reply;
    const messageFromMe = sender === 'clinic';
    const className = messageFromMe ?
      'messages-message current-member' : 'messages-message';
    const logoAssetID = props.config.getIn(['clinic', 'logo', 'assetID']);
    const clinicLogoURL = `${getBaseApiUrl()}/asset/${logoAssetID}`;
    return (
      <li key={index} className={className}>
        <span
          className="avatar"
          style={{ backgroundColor: '#d4d3d3', background: `url("${clinicLogoURL}")` }}
        />
        <div className="message-content">
          <div className="header">
            {messageFromMe && status ? `${status.toUpperCase()} - ` : ''}
            {messageFromMe ? props.config.getIn(['clinic', 'name']) : props.patient.get('patient_name')}
          </div>
          <div className="text">{removeEndOfLineSpaces(message)}</div>
        </div>
      </li>
    );
  }

  return (
    <ul className="messages-list u-margin--standard">
      {isFetching &&
        <div className="u-text-align-center">
          {translate('loading...')}
        </div>
      }
      {isError &&
        <div className="u-text-align-center">
          {translate('something_went_wrong')}
        </div>
      }
      {!isFetching && !isError && isEmpty &&
        <div className="u-text-align-center">
          {translate('no_conversation_found')}
        </div>
      }
      {
        !isError && !isEmpty && !isFetching &&
        props.messages.map((reply, index) => renderMessage(reply, index))
      }
      <div ref={messagesEndRef} />
    </ul>
  );
}
