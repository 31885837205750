import { connect } from 'react-redux';
import { getOrderedModelMapFromList } from '../utils/models';
import { compareByAlphabeticalOrder } from '../utils/comparators';
import { State, Dispatch } from '../types';
import { setAppointmentFilters } from '../actions';
import Appointments from '../components/appointments/appointments';
import { saveFactory } from './../utils/redux';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State) => ({
  config: state.config,
  user: state.user,
  workspace: state.workspace,
  appointmentsFilter: state.appointmentsFilter,
  isFetching: state.isFetching,
  patientStubs: state.patientStubs,
  encounterFlows: state.encounterFlows.filter(f => f.isVisible()).sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
  practitioners: state.practitioners.filter(p => p.isVisible()),
  salesItems: state.salesItems,
  encounterFlowMap: getOrderedModelMapFromList(state.encounterFlows.filter(f => f.isVisible()).sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFiltersUpdated: filters => dispatch(setAppointmentFilters(filters)),
  saveModel: saveFactory(dispatch),
});

const AppointmentsContainer = connect(mapStateToProps, mapDispatchToProps)(Appointments);

export default AppointmentsContainer;
