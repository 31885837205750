import { connect } from 'react-redux';

import OfflineNotification from './../components/offline/offlineNotification';
import { getCurrentUnsyncedModels } from '../utils/sync';
import { getLocalDB } from '../utils/db';


import type { State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({ isOnline, isSyncing, unsyncedAPICalls, user }: State) => ({
    isOnline,
    isSyncing,
    unsyncedModelCount: getCurrentUnsyncedModels(getLocalDB(), user.get('id'))
      .then(unsyncedModels => unsyncedModels.size + unsyncedAPICalls.size),
  });

const OfflineNotificationContainer = connect(mapStateToProps)(OfflineNotification);

export default OfflineNotificationContainer;
