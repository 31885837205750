import React from 'react';
import glamorous from 'glamorous';
import { components, OptionProps, SingleValueProps } from 'react-select';
import translate from './../../utils/i18n';

import { colours, fontSize } from './../../utils/css';
import { SelectOption } from '../../types';

type Props = {
  inventoryCount: number,
  threshold: number,
  dispensationUnit: string,
  label: string,
  mappedDrugName?: string,
};

const OptionWrapper = glamorous.div({
  display: 'flex',
});

const WarningInfoWrapper = glamorous.span({
  fontSize: fontSize.small,
  fontStyle: 'italic',
  display: 'flex',
  alignItems: 'center',
},
({ isWarning }: { isWarning?: boolean }) => (isWarning ? { color: colours.red } :
  { color: colours.grey5 }
));

const StockWarning = glamorous.label({
  fontWeight: 600,
  textTransform: 'uppercase',
  fontFamily: '\'robotobold\'',
});

const Warning = glamorous.span({
  display: 'flex',
  alignItems: 'center',
  fontSize: fontSize.small,
  fontWeight: 600,
  fontFamily: '\'robotobold\'',
  color: colours.red,
});

/**
 * Returns if the text for stock count to be displayed as warning or not.
 * @param {number} inventoryCount stock count
 * @param {number} threshold threshold value for stock warning to display
 * @returns {boolean}
 */
function isTextWarning(inventoryCount: number, threshold: number) {
  return inventoryCount < threshold;
}

/**
 * Returns label needed for stock warning text, depending on stock count.
 * @param {number} inventoryCount stock count
 * @returns {string}
 */
function getWarningText(inventoryCount: number) {
  if (inventoryCount === 0) {
    return 'no_stock';
  } else if (inventoryCount < 0) {
    return 'negative_stock';
  }
  return 'low';
}

/**
 * Returns the whole text needed for stock warning, depending on stock count.
 * @param {number} inventoryCount stock count
 * @param {string} dispensationUnit the dispensation unit
 * @returns {React.DOMElement}
 */
function getWarningWrapperContent(inventoryCount: number, dispensationUnit: string) {
  if (inventoryCount > 0) {
    return (
      <WarningInfoWrapper isWarning>
        &nbsp;&nbsp;&nbsp;({inventoryCount} {translate('units_available', { dispensationUnit })} -&nbsp;
        <StockWarning>{translate(getWarningText(inventoryCount))}</StockWarning>
        )
      </WarningInfoWrapper>
    );
  }
  return (
    <WarningInfoWrapper isWarning>
      &nbsp;&nbsp;&nbsp;(
      <StockWarning>{translate(getWarningText(inventoryCount))}</StockWarning>
      )
    </WarningInfoWrapper>
  );
}

/**
 * Returns label appended with inactive if needed.
 * @param {boolean} disbaled if the option item is disabled
 * @param {string} label label of option
 * @returns {string}
 */
function formatLabel(disbaled: boolean | undefined, label: string) {
  if (disbaled) {
    return `${label} (${translate('inactive')})`;
  }
  return label;
}

/**
 * @param {string} name Drug name
 * @returns {React.ReactNode}
 */
const getMappedDrugName = (name?: string) => {
  if (name) {
    return name === 'UNMAPPED' ?
      <Warning>
        &nbsp;&nbsp;({name})
      </Warning> : ` (${name})`;
  } return null;
};


/**
 * Renders the option label with mapped MDL drug name
 * @param {OptionRendererProps} { label, mappedDrugName }
 * @returns {React.Component} The rendered component
 */
const OptionRenderer = ({ label, mappedDrugName }: { label: string, mappedDrugName?: string }) => (
  <OptionWrapper>
    { label }
    { getMappedDrugName(mappedDrugName) }
  </OptionWrapper>
);

/**
 * Renders the option label formatted with stock warnings and details.
 * @returns {React.Component} The rendered component
*/
const DetailedStockWarning =
({ inventoryCount, threshold, dispensationUnit, label, mappedDrugName }: Props) => (
  <OptionWrapper>
    { label }
    { getMappedDrugName(mappedDrugName) }
    { isTextWarning(inventoryCount, threshold) &&
      getWarningWrapperContent(inventoryCount, dispensationUnit)
    }
    { !isTextWarning(inventoryCount, threshold) &&
      <WarningInfoWrapper>
        &nbsp;&nbsp;&nbsp;{ `(${inventoryCount} ${translate('units_available', { dispensationUnit })})` }
      </WarningInfoWrapper>
    }
  </OptionWrapper>
);

/**
 * Formats option label to a componenet display of stock warnings.
 * @param {SelectOption} option the select option
 * @returns {React.Component | string}
 */
export function optionWithDrugStockWarning(props: OptionProps<SelectOption>) {
  const { label, disabled, customDetails } = props.data;
  if (customDetails && customDetails.showDetailedInventoryWarnings) {
    return (
      <components.Option {...props}>
      <DetailedStockWarning
        label={formatLabel(disabled, label)}
        inventoryCount={customDetails.inventoryCount}
        threshold={customDetails.threshold}
        dispensationUnit={customDetails.dispensationUnit}
        mappedDrugName={customDetails.mappedDrugName}
      />
      </components.Option>
    );
  }
  return (
    <components.Option {...props}>
    <OptionRenderer
      label={formatLabel(disabled, label)}
      mappedDrugName={customDetails && customDetails.mappedDrugName}
    />
    </components.Option>
  );
}

/**
 * Formats option label to a componenet display of stock warnings.
 * @param {SelectOption} option the select option
 * @returns {React.Component | string}
 */
export function valueWithDrugStockWarning(props: SingleValueProps<SelectOption>) {
  const { label, disabled, customDetails } = props.data;
  if (customDetails && customDetails.showDetailedInventoryWarnings) {
    return (
      <components.SingleValue {...props}>
        <DetailedStockWarning
          label={formatLabel(disabled, label)}
          inventoryCount={customDetails.inventoryCount}
          threshold={customDetails.threshold}
          dispensationUnit={customDetails.dispensationUnit}
          mappedDrugName={customDetails.mappedDrugName}
        />
      </components.SingleValue>
    );
  }
  return (
    <components.SingleValue {...props}>
      <OptionRenderer
        label={formatLabel(disabled, label)}
        mappedDrugName={customDetails && customDetails.mappedDrugName}
      />
    </components.SingleValue>
  );
}

export default DetailedStockWarning;
