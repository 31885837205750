import { List, Map, OrderedMap } from 'immutable';

import AllergyModel from './../models/allergyModel';
import AppointmentModel from './../models/appointmentModel';
import BankModel from './../models/bankModel';
import BillModel from './../models/billModel';
import BillItemModel from './../models/billItemModel';
import CaseNoteFileModel from './../models/caseNoteFileModel';
import CategoryModel from './../models/categoryModel';
import ClaimModel from './../models/claimModel';
import ClaimInvoiceModel from './../models/claimInvoiceModel';
import ClaimReconciliationModel from './../models/claimReconciliationModel';
import ClaimInvoicePaymentModel from './../models/claimInvoicePaymentModel';
import ConditionModel from './../models/conditionModel';
import CollectedSpecimenModel from './../models/collectedSpecimenModel';
import CoveragePayorModel from './../models/coveragePayorModel';
import CustomNoteModel from './../models/customNoteModel';
import DocumentDataModel from './../models/documentDataModel';
import DocumentTemplateModel from './../models/documentTemplateModel';
import DrugModel from './../models/drugModel';
import EncounterModel from './../models/encounterModel';
import InventoryMapModel from './../models/inventoryMapModel';
import MasterDrugModel from './../models/masterDrugModel';
import MedicalCertificateModel from './../models/medicalCertificateModel';
import MedicalComplaintModel from './../models/medicalComplaintModel';
import MedicationModel from './../models/medicationModel';
import MetricEntryModel from './../models/metricEntryModel';
import MetricTypeModel from './../models/metricTypeModel';
import PatientModel from './../models/patientModel';
import PaymentModel from './../models/paymentModel';
import PaymentTypeModel from './../models/paymentTypeModel';
import PractitionerModel from './../models/practitionerModel';
import PrescriptionModel from './../models/prescriptionModel';
import ProcedureTypeModel from './../models/procedureTypeModel';
import ProcedureRequestModel from './../models/procedureRequestModel';
import ProcedureStatusModel from './../models/procedureStatusModel';
import ProcedureResultModel from './../models/procedureResultModel';
import ProviderModel from './../models/providerModel';
import ReceivableModel from './../models/receivableModel';
import SalesItemModel from './../models/salesItemModel';
import SpecimenModel from './../models/specimenModel';
import SupplierModel from './../models/supplierModel';
import SupplyItemModel from './../models/supplyItemModel';
import TemplateModel from './../models/templateModel';
import TemplateGroupModel from './../models/templateGroupModel';
import TimeChitModel from './../models/timeChitModel';
import TransactionModel from './../models/transactionModel';
import UserConfigModel from './../models/userConfigModel';
import UserGroupModel from './../models/userGroupModel';
import { debugPrint } from './logging';
import SupplyModel from '../models/supplyModel';
import InventoryDrugModel from './../models/inventoryDrugModel';
import DrugSuggestionModel from './../models/drugSuggestionModel';
import BaseModel from '../models/baseModel';
import PatientCampaignModel from '../models/patientCampaignModel';
import SMSJobModel from '../models/smsJobModel';
import DrugManufacturerModel from '../models/drugManufacturerModel';
import ClinicModel from '../models/clinicModel';
import DashboardStatModel from '../models/dashboardStatModel';
import PatientCampaignSetModel from '../models/patientCampaignSetModel';
import EncounterStageModel from '../models/encounterStageModel';
import EncounterFlowModel from '../models/encounterFlowModel';
import MasterCampaignModel from '../models/masterCampaignModel';
import ExportModel from '../models/exportModel';
import ExportsRemainingModel from '../models/exportsRemainingModel';
import DiscountChargeModel from '../models/discountChargeModel';
import DosingRegimenModel from '../models/dosingRegimenModel';

export const typeToCouchModelMap = {
  allergy: AllergyModel,
  appointment: AppointmentModel,
  bank: BankModel,
  bill: BillModel,
  bill_item: BillItemModel,
  caseNoteFile: CaseNoteFileModel,
  category: CategoryModel,
  claim: ClaimModel,
  claim_invoice: ClaimInvoiceModel,
  claim_reconciliation: ClaimReconciliationModel,
  claim_invoice_payment: ClaimInvoicePaymentModel,
  clinic: ClinicModel,
  collected_specimen: CollectedSpecimenModel,
  condition: ConditionModel,
  coverage_payor: CoveragePayorModel,
  custom_note: CustomNoteModel,
  dashboard_stat: DashboardStatModel,
  document_data: DocumentDataModel,
  document_template: DocumentTemplateModel,
  drug: DrugModel,
  encounter: EncounterModel,
  drug_mapping: InventoryMapModel,
  drug_master: MasterDrugModel,
  drug_clinic: InventoryDrugModel,
  drug_manufacturer: DrugManufacturerModel,
  drug_master_suggestion: DrugSuggestionModel,
  encounter_stage: EncounterStageModel,
  encounter_flow: EncounterFlowModel,
  medical_certificate: MedicalCertificateModel,
  medical_complaint: MedicalComplaintModel,
  medication: MedicationModel,
  metric_entry: MetricEntryModel,
  metric_type: MetricTypeModel,
  patient: PatientModel,
  patient_campaign_set: PatientCampaignSetModel,
  payment: PaymentModel,
  payment_type: PaymentTypeModel,
  practitioner: PractitionerModel,
  prescription: PrescriptionModel,
  procedure_type: ProcedureTypeModel,
  procedure_request: ProcedureRequestModel,
  procedure_status: ProcedureStatusModel,
  procedure_result: ProcedureResultModel,
  provider: ProviderModel,
  receivable: ReceivableModel,
  sales_item: SalesItemModel,
  specimen: SpecimenModel,
  supplier: SupplierModel,
  supply: SupplyModel,
  supply_item: SupplyItemModel,
  template: TemplateModel,
  template_group: TemplateGroupModel,
  time_chit: TimeChitModel,
  transaction: TransactionModel,
  user_config: UserConfigModel,
  user_group: UserGroupModel,
  patient_campaign: PatientCampaignModel,
  campaign_job: SMSJobModel,
  campaign_master: MasterCampaignModel,
  export: ExportModel,
  exports_remaining: ExportsRemainingModel,
  discount_charge: DiscountChargeModel,
  dosing_regimen: DosingRegimenModel,
};

// No couch  models, may not have type field
export const typeToModelMap = {
  inventory_map: InventoryMapModel,
  master_drug: MasterDrugModel,
};

/** Finds the appropriate Model for a document and converts the document to that model type.
   * @param {object} doc Any JSON Object
   * @param {string | void} type the type of the doc if not specified in the doc.
   * @return {BaseModel | undefined} Returns the appropriate subclass of BaseModel for the given doc's type.
   */
export const genericDocToModel = (
  doc: { type?: string }, type: string | void,
): BaseModel | void => {
  const safeType = type || 'UNDEFINED';
  const safeDoc = doc || { type: safeType };
  const docType = (doc && doc.type) || safeType;

  if (docType !== 'UNDEFINED' && typeToCouchModelMap[docType]) {
    return new typeToCouchModelMap[docType](safeDoc); // Model was found for type so return that.
  }
  debugPrint(`Document model not specified for doc type ${docType}.`);
  return undefined;
};


/** Finds the appropriate Model for a document and converts the document to that model type.
 * @param {object} doc A couchDB document.
 * @return {BaseModel} Returns the appropriate subclass of BaseModel for the given doc's type.
 */
export const docToModel = (doc: { type: string, [key: string]: any}): BaseModel | void =>
  genericDocToModel(doc);

/** Finds the appropriate Doc type for a given foreign key name
 * @param {string} key A foreign key name
 * @return {string} Returns the appropriate type of doc
 */
export const foreignKeyToDocType = (key: string): string => {
  switch (key) {
    case 'doctor':
      return 'practitioner';
    default: {
      const type = key.trim().match(/(.*)_id$/);
      return type ? type[1] : '';
    }
  }
};

/** Converts a list of models into a map of <_id, model> key value pairs
 * @param {List<BaseModel>} models A list of Models.
 * @return {Map<string, BaseModel>} Models mapped by their id
 */
export const getModelMapFromList = <T extends BaseModel>(models: List<T>): Map<string, T> => models
  .reduce((mappedModels, model) => mappedModels.set(model.get('_id'), model), Map());

/** returns if the attribute has available validation
 * @param {string} key A foreign key name
 * @param {string} referrerDocType the parent doc type
 * @return {string} Returns the appropriate type of doc
 */
export const isModelAttributesForValidation = (key: string, referrerDocType: string): boolean => ((key === 'patient_id') ||
    ((key === 'practitioner_id') || (key === 'doctor_id') || (key === 'doctor')) ||
    ((key === 'drug_id') && (referrerDocType === 'prescription')) ||
    ((key === 'coverage_payor_id') && (referrerDocType === 'drug')) ||
    ((key === 'consult_type') && (referrerDocType === 'encounter')) ||
    ((key === 'encounter_id') &&
    ((referrerDocType === 'bill') ||
    (referrerDocType === 'drug') ||
    (referrerDocType === 'medical_certificate') ||
    (referrerDocType === 'prescription') ||
    (referrerDocType === 'procedure_request') ||
    (referrerDocType === 'time_chit'))) ||
    (key === 'procedure_type_id') ||
    (key === 'sales_item_id') ||
    (key === 'bill_id'));

/** Converts a list of models into a map of <_id, model> key value pairs
 * @param {List<BaseModel>} models A list of Models.
 * @return {Map<string, BaseModel>} Models mapped by their id
 */
export const getOrderedModelMapFromList = <T extends BaseModel>(models: List<T>)
  : OrderedMap<string, T> => models
    .reduce((mappedModels, model) => mappedModels.set(model.get('_id'), model), OrderedMap());
