import React, { Fragment } from 'react';
import { List } from 'immutable';

import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import ModalFooter from './../modals/modalFooter';
import Input from './../inputs/input';
import FormError from './../formError';
import PaymentTypeModel from './../../models/paymentTypeModel';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { validateAndTrimString } from './../../utils/utils';

import type { SaveModel } from './../../types';
import { debugPrint } from '../../utils/logging';

type Props = {
  modelToEdit?: PaymentTypeModel,
  onSaveClicked: () => void,
  saveModel: SaveModel,
  paymentTypes: List<PaymentTypeModel>,
};

type State = {
  isSaving: boolean,
  errorMessage?: string,
  paymentType: string,
};

/**
 * A form that allows exiting paymentType to be edited or saving new paymentType.
 * @class AddEditPaymentTypeForm
 * @extends {React.Component<Props, State>}
 */
class AddEditPaymentTypeForm extends React.Component<Props, State> {
  /**
   * Creates an instance of AddEditPaymentTypeForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      paymentType: props.modelToEdit ? props.modelToEdit.get('name', '') : '',
    };
  }

  /**
   * Validates the form.
   * @returns {boolean}
   */
  isValid() {
    if (!validateAndTrimString(this.state.paymentType).length) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    const modelToEditId = this.props.modelToEdit !== undefined ?
      this.props.modelToEdit.get('_id') : '';
    const paymentTypes = modelToEditId ?
      this.props.paymentTypes.filter(b => b.get('_id') !== modelToEditId) :
      this.props.paymentTypes;
    if (paymentTypes.find(b => String(b.get('name', '')).toLowerCase() === this.state.paymentType.toLowerCase())) {
      this.setState({ errorMessage: translate('payment_type_already_exists') });
      return false;
    }
    return true;
  }

  /**
   * Handle the saving of the form.
   * @returns {void}
   */
  onSaveClicked() {
    this.setState({ errorMessage: undefined });
    if (this.isValid()) {
      this.setState({ isSaving: true });
      const paymentTypeModel = this.props.modelToEdit ? this.props.modelToEdit :
        new PaymentTypeModel({});
      this.props.saveModel(paymentTypeModel.set({ name: this.state.paymentType }))
        .then((model) => {
          if (model && model.get) {
            createSuccessNotification(translate('payment_type_saved'));
            this.props.onSaveClicked();
          }
        })
        .catch((error) => {
          debugPrint(error, 'error');
          createErrorNotification(translate('something_went_wrong'));
          this.setState({ isSaving: false });
        });
    } else {
      this.setState({ isSaving: false });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <FormError>{translate(this.state.errorMessage)}</FormError>
          }
          <Input
            id="payment_type"
            label={translate('payment_type')}
            value={
                this.state.paymentType}
            onValueChanged={
              (paymentType: string) => {
                this.setState({
                  paymentType,
                });
              }
            }
            required
            disabled={this.state.isSaving}
          />
        </div>
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('save')}
            onClick={() => this.onSaveClicked()}
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default AddEditPaymentTypeForm;
