import React from 'react';
import glamorous from 'glamorous';

import Input from './input';
import translate from './../../utils/i18n';
import { mediaQueries } from './../../utils/css';
import { createInfoNotification } from './../../utils/notifications';
import Button from './../buttons/button';

import type { ComponentReference } from './../../types';

type Props = {
  value?: File | void,
  onValueChanged: (file: File | void) => void,
  accept: string,
};

type State = {}

const FileInputWrapper = glamorous.div({
  display: 'flex',
  width: '100%',
  '& .o-text-input': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '& .o-button': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginTop: '18px',
    height: '43px',
    [mediaQueries.forTabletLandscapeUp]: {
      marginTop: '20px',
      height: '46px',
    },
  },
});

/**
 * An input for uploading a file.
 * @class FileInput
 * @extends {React.Component<Props, State>}
 */
class FileInput extends React.Component<Props, State> {
  uploadInput: ComponentReference | void;

  /**
   * Handles the upload event.
   * @returns {void}
   */
  handleUpload = () => {
    if (this.uploadInput && this.uploadInput.files.length > 0) {
      const file = this.uploadInput.files[0];
      if (file.size > 10485760) { // 10MB
        createInfoNotification(translate('upload_file_limit_error'));
      } else {
        this.props.onValueChanged(file);
      }
    } else {
      this.props.onValueChanged();
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <FileInputWrapper>
        <input
          type="file"
          id="upload-input"
          style={{ display: 'none' }}
          onChange={this.handleUpload}
          ref={(uploadInput) => { this.uploadInput = uploadInput; }}
          accept={this.props.accept}
        />
        <Input
          id="file_name_input"
          label={translate('file')}
          required
          disabled
          value={this.props.value ? this.props.value.name : undefined}
          placeholder={translate('no_file_selected')}
          onValueChanged={() => {}}
          style={{ width: '100%' }}
        />
        <Button
          className="o-button o-button--small"
          onClick={() => (this.uploadInput ? this.uploadInput.click() : undefined)}
          dataPublic
        >
          {translate('select_file')}
        </Button>
      </FileInputWrapper>
    );
  }
}

export default FileInput;
