import React, { useEffect, useRef, useState } from 'react';
import glamorous from 'glamorous';
import { List, Map } from 'immutable';
import Tooltip from 'react-tooltip-lite';

import { UNICODE } from './../../constants';
import translate from './../../utils/i18n';
import { wsUnit, colours, fontSize, fonts } from './../../utils/css';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';

import type AllergyModel from './../../models/allergyModel';
import type { ActiveIngredient, User } from './../../types';
import DrugModel from './../../models/drugModel';
import { unspecifiedAlleryToolTip } from '../../utils/prescriptions';

type Props = {
  allergies: List<AllergyModel>,
  user: User,
  drugs: List<DrugModel>,
  isFromOverviewPage?: boolean,
}

const AllergyContainer = glamorous.div({
  position: 'relative',
  padding: `calc(0.75 * ${wsUnit})`,
  display: 'flex',
  flexDirection: 'row',
  color: colours.white,
  borderBottom: `1px solid ${colours.grey3}`,
  fontSize: fontSize.large,
  textTransform: 'uppercase',
}, ({ isActive }: {isActive: boolean | void}) => ({
  background: isActive ? colours.red : colours.grey5,
}));

const AllergyTitle = glamorous.h2({
  fontFamily: fonts.medium,
  marginRight: '10px',
});

/**
 * @param {Immutable.List} allergies A list of AllergyModels.
 * @param {List<DrugModel>} drugs drugs.
 * @returns {string} Comma separated list of allergy names or em dash if no allergies.
 */
function getAllergiesString(allergies: List<AllergyModel>, drugs: List<DrugModel>) {
  if (allergies.size > 0) {
    const allergyString = allergies
      .filter(a => a.get('flagged') || a.get('flagged') === undefined)
      .map(allergy => allergy.getAllergyBarDetails(drugs))
      .toJS()
      .join(', ');
    return allergyString || UNICODE.EMDASH;
  }
  return UNICODE.EMDASH;
}

/**
 * A PatientAllergyBar component.
 * @param {Props} props props
 * @returns {React.Component} A PatientHeader component
 */
const PatientAllergyBar = ({
  user, allergies, drugs, isFromOverviewPage,
}: Props) => {
  const allergyString = getAllergiesString(allergies, drugs);
  const isAllergyActive = allergyString && allergyString !== UNICODE.EMDASH;
  const toolTipRef = useRef(null);
  const unspecifiedAllergies = allergies.filter(a => a.isMedicationAndUnspecified());

  useEffect(() => {
    if (toolTipRef && toolTipRef.current && isFromOverviewPage && unspecifiedAllergies.size > 0) {
      toolTipRef.current?.startHover();
      setTimeout(
        () => toolTipRef.current?.endHover(),
        3000,
      );
    }
  }, []);
  return (
    <PermissionWrapper permissionsRequired={List([createPermission('patient_allergies', 'read')])} user={user}>
      {
        allergies &&
        <AllergyContainer isActive={isAllergyActive}>
          <AllergyTitle>{translate('allergies')} :</AllergyTitle>
          <div>{allergyString}</div>
          {isFromOverviewPage && unspecifiedAllergies.size > 0 &&
            <Tooltip
              ref={toolTipRef}
              content={(
                <div style={{ color: colours.white }}>
                  {unspecifiedAlleryToolTip(unspecifiedAllergies, () =>
                    toolTipRef.current.endHover())}
                </div>
              )}
              hoverDelay={300}
              mouseOutDelay={300}
              arrow={false}
              background="rgba(98, 98, 98, 0.9)"
              direction="down"
              styles={{ position: 'absolute', right: `calc(0.75 * ${wsUnit})` }}
            >
              <img
                src="static/images/icon_warning.svg"
                alt="warning icon"
              />
            </Tooltip>
          }
        </AllergyContainer>
      }
    </PermissionWrapper>
  );
};

export default PatientAllergyBar;
