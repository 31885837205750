import { connect } from 'react-redux';
import type History from 'history';
import { List } from 'immutable';
import { saveFactory, saveModelsFactory, saveModelsAPIFactory } from './../utils/redux';
import { saveCampaignJobStatusUpdate } from './../utils/api';
import {
  setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfigValue,
  updateConfig, setCurrentDataViewsError, updateModels,
} from './../actions';
import AsyncFetch from './../components/asyncFetch';
import CampaignJobs from '../components/patientOutreachCampaign/campaignJobs';
import { getCampaignJobsDataViews } from '../dataViews';

import type { Dispatch, State, DataView, Config, Model } from './../types';
import type APIError from '../utils/apiError';
import type SMSJobModel from '../models/smsJobModel';
import BaseModel from '../models/baseModel';
import { mapDrug } from '../utils/inventory';

/**
 * @param {Map<string, MasterDrugModel>} masterDrugModelsMap Map of MasterDrugModels
 * @returns {List<SelectOption>}
 */
const getMasterDrugOptions = (masterDrugModelsMap: State['masterDrugModelsMap']) =>
  masterDrugModelsMap.valueSeq().toList().map(m => ({ label: m.get('name'), value: m.get('_id') }));

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, user, userConfigs, drugs,
  salesItems, encounters, billItems, practitioners, bills,
  currentDataViews, currentDataViewsModels, currentDataViewsError,
  patients, isFetching, prescriptions, conditions, masterDrugModelsMap,
}: State, { campaignSetID, campaignID, location }:
  { campaignSetID: string, campaignID: string, location: History.Location }) => ({
  config,
  user,
  campaign: currentDataViewsModels.find(m => m.get('type') === 'patient_campaign' &&
    m.get('_id') === campaignID && !m.get('_deleted')),
  salesItems,
  encounters,
  bills,
  billItems,
  practitioners,
  drugs,
  userConfigs,
  containerDataViews: getCampaignJobsDataViews(campaignSetID, campaignID),
  currentDataViews,
  smsJobs: currentDataViewsModels.filter(m => m.get('type') === 'campaign_job' && !m.get('_deleted')) || List(),
  currentDataViewsError,
  patients,
  showLoadingIndicator: true,
  isFetching,
  prescriptions,
  conditions,
  drugMap: mapDrug(drugs),
  masterDrugOptions: getMasterDrugOptions(masterDrugModelsMap),
  isMedadvisorCampaign: location.pathname.includes('medadvisor'),
  selectedCampaignSet: currentDataViewsModels.find(set => set.get('_id') === campaignSetID),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: Model) => dispatch(setCurrentDataViewsModels(models)),
  updateCampaignJobStatus: (campaignJobs: Array<SMSJobModel>) => (
    Promise.all(campaignJobs.map(campaignJob => 
      saveModelsAPIFactory(dispatch)(() => saveCampaignJobStatusUpdate(campaignJob, dispatch))
        .then((job) => {
          if (!job.unavailable && !job.error && Array.isArray(job)) {
            return job[0];
          }
          return job;
        })))
  ),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  onAsyncFetchComplete: (models: List<BaseModel>) => dispatch(updateModels(
    models.filter(m => ['bill', 'bill_item', 'prescription', 'encounter', 'patient'].includes(m.get('type'))).toArray(),
  )),
});

const CampaignJobsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AsyncFetch(CampaignJobs));

export default CampaignJobsContainer;
