import React from 'react';
import UrlPattern from 'url-pattern';
import { Link } from 'react-router-dom';
import type { Node } from 'react';

import Notification from './../notification/notification';
import translate from './../../utils/i18n';
import PreventReload from './preventReload';

type Props = {
  isOnline: boolean,
  unsyncedModelCount: Promise<number>,
  isSyncing: boolean,
};

type State = {
  unsyncedModelCount: number,
}

const CASENOTE_ROUTES = [
  new UrlPattern('#/patient/:patientID/categories/:categoryID/add(/*)'),
  new UrlPattern('#/patient/:patientID/categories/:categoryID/add-text'),
  new UrlPattern('#/patient/:patientID/casenotes/:casenoteID/edit(*)'),
];

/**
 * Displays information about the users connection.
 * @class OfflineNotification
 * @extends {React.Component<Props, State>}
 */
class OfflineNotification extends React.Component<Props, State> {
  /**
   * Creates an instance of OfflineNotification.
   * @param {Props} props Props
   */
  constructor(props) {
    super(props);
    this.state = {
      unsyncedModelCount: 0,
    };
  }

  /**
   * Checks if the route changes after component update.
   * @param {Props} prevProps Prev props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.unsyncedModelCount !== prevProps.unsyncedModelCount) {
      this.props.unsyncedModelCount.then((unsyncedModelCount) => {
        this.setState({ unsyncedModelCount });
      });
    }
  }

  /**
   * Informs the user of their connection status.
   * @returns {string}
   */
  getMainMessage(): string {
    if (this.props.isOnline && this.props.isSyncing) {
      return 'You are connected to the internet. Please wait while we sync your changes to the database.';
    } else if (this.props.isOnline) {
      return 'You are connected to the internet.';
    }
    return CASENOTE_ROUTES.some(route => route.match(`${location.hash}`)) ?
      `${translate('you_are_not_connected_to_the_internet')}\u0020${translate('you_will_loose_your_writing')}` :
      `${translate('you_are_not_connected_to_the_internet_with_no_live_search')}`;
  }

  /**
   * Shows a message related to unsynced changes.
   * @returns {Node}
   */
  getSecondaryMessage(): Node {
    return this.props.isOnline && this.props.isSyncing ?
      <p>Syncing changes to database...</p> :
      <p>
        {translate('you_have_unsynced_data', { unsyncedDataCount: this.state.unsyncedModelCount })}
        <Link to="/unsynced-documents">View the unsynced documents here.</Link>
      </p>;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Notification>
        <p>{this.getMainMessage()}</p>
        {this.getSecondaryMessage()}
        <PreventReload
          when={
            this.state.unsyncedModelCount > 0 ||
            (this.state.unsyncedModelCount > 0 && this.props.isSyncing) ||
            (this.props.isOnline && this.props.isSyncing)
          }
        />
      </Notification>
    );
  }
}

export default OfflineNotification;
