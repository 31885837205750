import { template } from 'lodash';
import numberToWords from 'number-to-words';
import Moment from 'moment';
import { List } from 'immutable';

import { convertNumberToPrice, dataURItoBlob, getBaseApiUrl, pluralizeWord, getBaseUrl } from './utils';
import { createInfoNotification, createErrorNotification } from './notifications';
import {
  convertDocumentAndPrint, getDocumentTemplate, setDocumentTemplateData,
  getDocumentBlob,
} from './documentTemplates';

import { getDateFormat, prettifyTime, prettifyDate, getTimeFormat } from './time';
import { logPrescriptionLabelPrinted } from './logging';
import translate from './i18n';
import browserIs from './browser';

import pjson from './../../package.json';
import { UNICODE } from './../constants';
import defaultConfig from '../config/defaultConfig';

import type { Config, PaymentToBePrint, CustomColumn } from './../types';
import type { Attributes as BillAttributes } from './../models/billModel';
import type CoveragePayorModel from './../models/coveragePayorModel';
import type DrugModel from './../models/drugModel';
import type EncounterModel from './../models/encounterModel';
import type MedicalCertificateModel from './../models/medicalCertificateModel';
import type PatientModel from './../models/patientModel';
import type SalesItemModel from './../models/salesItemModel';
import type TimeChitModel from './../models/timeChitModel';
import type PrescriptionModel from './../models/prescriptionModel';
import type BillItemModel from './../models/billItemModel';
import type ClaimInvoiceModel from './../models/claimInvoiceModel';
import type ProcedureTypeModel from './../models/procedureTypeModel';
import type ProviderModel from './../models/providerModel';
import type ConditionModel from './../models/conditionModel';
import type DocumentDataModel from './../models/documentDataModel';
import type DocumentTemplateModel from './../models/documentTemplateModel';
import DiscountChargeModel from 'src/models/discountChargeModel';
import { calculateBillItemTotal } from './billing';

let singleBillTemplate;
let multipleBillTemplate;
let billTemplate;
let billEnglishTemplate;
let claimInvoiceTemplate;
let consultReportsTemplate;
let consultReportsGroupedTemplate;
let costReportTemplate;
let appointmentReportTemplate;
let stockTakeTemplate;
let suppliesReceivedTemplate;
let headerTemplate;
let medicalCertificateTemplate;
let timeChitTemplate;
let stylesTemplate;
let prescriptionLabelTemplate;
let multipleClaimInvoicesTemplate;
let singleClaimInvoiceTemplate;
let prescriptionsReportTemplate;
let prescriptionReportsGroupedTemplate;
let claimInvoicesSummaryTemplate;
let inventoryReportTemplate;
let inventoryReportsTemplate;
let paymentHistoryTemplate;
let campaignJobsListTemplate;
let queueTicketTemplate;
let paymentsOutstandingReport;

if (process.env.NODE_ENV !== 'test') {
  billEnglishTemplate = require('./printTemplates/billEnglish.ejs'); // eslint-disable-line global-require
  billTemplate = require('./printTemplates/bill.ejs'); // eslint-disable-line global-require
  singleBillTemplate = require('./printTemplates/singleBill.ejs'); // eslint-disable-line global-require
  multipleBillTemplate = require('./printTemplates/multipleBills.ejs'); // eslint-disable-line global-require
  claimInvoiceTemplate = require('./printTemplates/claimInvoice.ejs'); // eslint-disable-line global-require
  consultReportsTemplate = require('./printTemplates/consultReports.ejs'); // eslint-disable-line global-require
  consultReportsGroupedTemplate = require('./printTemplates/consultReportsGrouped.ejs'); // eslint-disable-line global-require
  suppliesReceivedTemplate = require('./printTemplates/suppliesReceived.ejs'); // eslint-disable-line global-require
  appointmentReportTemplate = require('./printTemplates/appointmentsReport.ejs'); // eslint-disable-line global-require
  costReportTemplate = require('./printTemplates/costReport.ejs'); // eslint-disable-line global-require
  stockTakeTemplate = require('./printTemplates/stockTake.ejs'); // eslint-disable-line global-require
  campaignJobsListTemplate = require('./printTemplates/campaignJobsList.ejs'); // eslint-disable-line global-require
  headerTemplate = require('./printTemplates/header.ejs'); // eslint-disable-line global-require
  medicalCertificateTemplate = require('./printTemplates/medicalCertificate.ejs'); // eslint-disable-line global-require
  timeChitTemplate = require('./printTemplates/timeChit.ejs'); // eslint-disable-line global-require
  stylesTemplate = require('./printTemplates/styles.ejs'); // eslint-disable-line global-require
  prescriptionLabelTemplate = require('./printTemplates/prescriptionLabel.ejs'); // eslint-disable-line global-require
  singleClaimInvoiceTemplate = require('./printTemplates/singleClaimInvoice.ejs'); // eslint-disable-line global-require
  claimInvoicesSummaryTemplate = require('./printTemplates/claimInvoicesSummary.ejs'); // eslint-disable-line global-require
  multipleClaimInvoicesTemplate = require('./printTemplates/multipleClaimInvoices.ejs'); // eslint-disable-line global-require
  prescriptionsReportTemplate = require('./printTemplates/prescriptionReport.ejs'); // eslint-disable-line global-require
  prescriptionReportsGroupedTemplate = require('./printTemplates/prescriptionReportsGrouped.ejs'); // eslint-disable-line global-require
  inventoryReportTemplate = require('./printTemplates/inventoryReport.ejs'); // eslint-disable-line global-require
  inventoryReportsTemplate = require('./printTemplates/inventoryReports.ejs'); // eslint-disable-line global-require
  paymentHistoryTemplate = require('./printTemplates/paymentHistory.ejs'); // eslint-disable-line global-require
  queueTicketTemplate = require('./printTemplates/queueTicket.ejs'); // eslint-disable-line global-require
  paymentsOutstandingReport = require('./printTemplates/paymentsOutstandingReport.ejs'); // eslint-disable-line global-require
}

const { version } = pjson;
const stylesData = {
  stylesPath: process.env.NODE_ENV === 'development' ?
    `dist/styles.${version}.css` : `stylesheets/styles.${version}.min.css`,
};

/**
 * Takes a string and replaces all line breaks (i.e. \n, \r) with HTML breaks (i.e. <br />)
 * @param {string} string The string to convert.
 * @returns {string} The string with line breaks converted to HTML breaks.
 */
export function lineBreaksToHTML(string: string): string {
  return string.replace(/(\r\n|\n|\r)/gm, '<br />');
}

/**
 * Prints the given content.
 * @param {string} content The content of the HTML page to print.
 * @returns {undefined}
 */
function print(content: string) {
  // debugger;
  const newWindow = window.open('');
  if (!newWindow || !newWindow.document) {
    alert('Something went wrong while tying to print the selected document. Please ensure that you\'ve enabled popups for Klinify in your browser. If this does not fix the problem then please contact Klinify for further assistance.');
  } else {
    newWindow.document.open().write(content);
    newWindow.document.close();
    newWindow.onload = () => {
      newWindow.print();
      newWindow.onblur = () => { // There is a bit of delay sometimes so we actually wait for the window to lose focus to the print dialog first
        newWindow.onfocus = () => {
          newWindow.close();
        };
      };
    };
  }
}

/**
 * Returns a header template for a clinc print out where lines 1-3 come from the given clinic config
 * @param {Map} config Clinic config.
 * @returns {string} A HTML string.
 */
export function getHeaderContent(config: Config): string {
  return template(headerTemplate)({
    header: `<strong>${lineBreaksToHTML(config.getIn(['print', 'header'], ''))}</strong>`,
  });
}


/**
 * Converts a number representing patient fees to text form (as would be suitable for a cheque).
 * @param {number | string} fees The fees as a number (can be a string of a number).
 * @param {string} currencyLabel Optional currency label.
 * @returns {string} The text form of fees.
 */
function feesToText(fees: number | string, currencyLabel: string = 'Ringgit'): string {
  const decimal = parseFloat(fees) - parseInt(fees, 10);
  let string = `${numberToWords.toWords(fees)} ${currencyLabel}`;
  if (decimal > 0) {
    string = `${string} and ${numberToWords.toWords((decimal * 100).toFixed())} cents`;
  }
  return string.toUpperCase();
}

/**
 * Returns a template for a single Bill.
 * @param {BillAttributes} billAttributes A Bill to print.
 * @param {PatientModel} patient The patient for the Bill.
 * @param {Immutable.Map} config Clinic config.
 * @param {EncounterModel} encounter The encounter for the Bill.
 * @param {List<SalesItemModel>} salesItems A List of SalesItemModels.
 * @param {List<BillItemModel>} billItems A list of bill items.
 * @param {List<DrugModel>} drugs A List of DrugModels.
 * @param {List<ProcedureTypeModel>} procedureTypes A list of procedure types.
 * @param {List<ProviderModel>} providers A list of ProviderModel.
 * @param {?string} amountOutstanding the balance amount
 * @param {?PaymentToBePrint} payment A payment object.
 * @param {boolean} isForMultipleBills Check if printing multiple bills.
 * @returns {undefined}
 */
function getBillTemplate(
  billAttributes: BillAttributes,
  patient: PatientModel,
  config: Config,
  encounter: EncounterModel,
  salesItems: List<SalesItemModel>,
  billItems: List<BillItemModel>,
  drugs: List<DrugModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  amountOutstanding?: string,
  discountsCharges?: List<DiscountChargeModel>,
  payment: PaymentToBePrint | null | undefined = undefined,
  isForMultipleBills: boolean = false,
) {
  const coPayment = billAttributes.co_payment ? billAttributes.co_payment : 0;
  const billHasPanel = billAttributes.coverage_payor_id !== undefined &&
    billAttributes.coverage_payor_id !== null;
  const items = billItems.map((item) => {
    const itemModel = item.isPrescription() ? item.getItem(drugs) :
      item.getItem(salesItems) || item.getItem(procedureTypes);
    // @ts-ignore
    const itemName = itemModel.get('type') === 'procedure_type' ? `${itemModel.get('name')} - ${itemModel.getProviderName(providers)}` : itemModel.get('name');
    return {
      name: itemName || '-',
      total: convertNumberToPrice(item.get('total_amount')),
      price: convertNumberToPrice(item.get('price')),
      quantity: `x${item.get('quantity')}`,
      patientTotal: item.isClaimed(billHasPanel) ? convertNumberToPrice(0) : convertNumberToPrice(item.get('total_amount')),
    };
  });
  const currencyLabel = config.getIn(['print', 'showMalay'], true) ? 'Ringgit' : 'Dollars';
  const bTemplate = config.getIn(['print', 'showMalay'], true) ? billTemplate : billEnglishTemplate;
  const amountPaid = payment ? payment.amount : 0;
  const usedDiscountsCharges = ((discountsCharges || List())
    .reduce<[List<number>, List<number>, Array<{ name: string, amountToAdd: string }>]>(([btItems, unclaimedBtItems, arr], item) => {
      const [totalToAdd, updatedBtItems] = btItems.reduce(([sum, list], bItem) => {
        const amountToAdd = bItem * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
        return [sum + amountToAdd, list.push(bItem + amountToAdd)];
      }, [0, List()]);
      const [amountToAdd, updatedUnclaimedBtItems] = unclaimedBtItems.reduce(([sum, list], bItem) => {
        const amountToAdd = bItem * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
        return [sum + amountToAdd, list.push(bItem + amountToAdd)];
      }, [0, List()]);
      return ([
        updatedBtItems,
        updatedUnclaimedBtItems,
        [
          ...arr,
          {
            name: item.get('name'),
            totalToAdd: convertNumberToPrice(parseFloat(totalToAdd.toFixed(2))),
            amountToAdd: convertNumberToPrice(parseFloat(amountToAdd.toFixed(2))),
          },
        ],
      ]);
    }, [
      billItems?.map(bItem => calculateBillItemTotal(List([bItem]))),
      billItems?.filter(b => !b.isClaimed(billHasPanel)).map(bItem => calculateBillItemTotal(List([bItem]))),
      []
    ])[2]);
  return template(bTemplate)({
    header: getHeaderContent(config),
    logoURL: config.getIn(['clinic', 'logo', 'assetID']) ?
      `${getBaseApiUrl()}/asset/${config.getIn(['clinic', 'logo', 'assetID'])}` : undefined,
    date: prettifyDate(billAttributes.timestamp),
    patientName: patient.get('patient_name'),
    coPayment: convertNumberToPrice(coPayment),
    items: items.toArray(),
    fees: convertNumberToPrice(Number(amountPaid)),
    textFees: feesToText(Number(amountPaid), currencyLabel),
    consultType: encounter.getEncounterType(salesItems),
    showItems: config.getIn(['print', 'billInvoiceShowItems'], true),
    signatureLabel: lineBreaksToHTML(config.getIn(['print', 'signatureLabel'], '')),
    serialNumber: billAttributes.internal_clinic_id,
    userStyles: {
      showHeader: config.getIn(['print', 'bill', 'showHeader'], true),
      hasFixedHeight: config.getIn(['print', 'bill', 'height']) !== undefined,
      height: config.getIn(['print', 'bill', 'height']) !== undefined ?
        `${config.getIn(['print', 'bill', 'height'], 0) - config.getIn(['print', 'bill', 'marginTop'], 0)}mm` :
        'auto',
      marginTop: `${config.getIn(['print', 'bill', 'marginTop'], '0')}mm`,
      fontSize: `${config.getIn(['print', 'bill', 'fontSize'], '1')}em`,
    },
    isForMultipleBills,
    paymentDate: payment ? payment.dateReceived : new Moment().format(getDateFormat()),
    amountPaid: convertNumberToPrice(amountPaid),
    amountOutstanding,
    discountsCharges: usedDiscountsCharges,
  });
}

/**
 * Prints a bill invoice with the given data.
 * @param {string} amountOwed the balance amount.
 * @param {Immutable.Map} config Clinic config.
 * @param {BillAttributes} billAttributes A Bill to print.
 * @param {PatientModel} patient The patient for the Bill.
 * @param {EncounterModel} encounter The encounter for the Bill.
 * @param {List<SalesItemModel>} salesItems A List of SalesItemModels.
 * @param {List<DrugModel>} drugs A List of DrugModels.
 * @param {List<BillItemModel>} billItems A list of bill items.
 * @param {List<ProcedureTypeModel>} procedureTypes A list of procedure types.
 * @param {List<ProviderModel>} providers A list of ProviderModel.
 * @param {List<DiscountChargeModel>} usedDiscountsCharges A list of discounts and charges.
 * @returns {undefined}
 */
export function printBill(
  amountOwed: string,
  config: Config, billAttributes: BillAttributes, patient: PatientModel,
  encounter: EncounterModel, salesItems: List<SalesItemModel>, drugs: List<DrugModel>,
  billItems: List<BillItemModel>, procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  usedDiscountsCharges?: List<DiscountChargeModel>,
) {
  print(template(singleBillTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('invoice'),
    bill: getBillTemplate(
      billAttributes,
      patient,
      config,
      encounter,
      salesItems,
      billItems,
      drugs,
      procedureTypes,
      providers,
      amountOwed,
      usedDiscountsCharges,
    ),
  }));
}

/**
 * Prints all bill invoices based on the selected payments.
 * @param {string} amountOwed the balance amount.
 * @param {List<PaymentToBePrint>} paymentsToBePrint List of payments for printing.
 * @param {Immutable.Map} config Clinic config.
 * @param {BillAttributes} billAttributes A Bill to print.
 * @param {PatientModel} patient The patient for the Bill.
 * @param {EncounterModel} encounter The encounter for the Bill.
 * @param {List<SalesItemModel>} salesItems A List of SalesItemModels.
 * @param {List<DrugModel>} drugs A List of DrugModels.
 * @param {List<BillItemModel>} billItems A list of bill items.
 * @param {List<ProcedureTypeModel>} procedureTypes A list of procedure types.
 * @param {List<ProviderModel>} providers A list of ProviderModel
 * @param {List<DiscountChargeModel>} usedDiscountsCharges A list of discounts and charges.
 * @returns {undefined}
 */
export function printBills(
  amountOwed: string,
  paymentsToBePrint: List<PaymentToBePrint>,
  config: Config, billAttributes: BillAttributes, patient: PatientModel,
  encounter: EncounterModel, salesItems: List<SalesItemModel>, drugs: List<DrugModel>,
  billItems: List<BillItemModel>,
  procedureTypes: List<ProcedureTypeModel>, providers: List<ProviderModel>,
  usedDiscountsCharges?: List<DiscountChargeModel>,
) {
  const bills = paymentsToBePrint.map(payment => getBillTemplate(
    billAttributes,
    patient,
    config,
    encounter,
    salesItems,
    billItems,
    drugs,
    procedureTypes,
    providers,
    amountOwed,
    usedDiscountsCharges,
    payment,
    true,
  )).toArray();
  print(template(multipleBillTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('invoice'),
    bills,
  }));
}

/**
 * Returns the template for a single ClaimInvoice.
 * @param {ClaimInvoiceModel} claimInvoice The ClaimInvoice the template is for.
 * @param {CoveragePayorModel} coveragePayor The coveragePayor of the ClaimInvoice.
 * @param {Config} config App Config.
 * @returns {string}
 */
function getClaimInvoiceTemplate(
  claimInvoice: ClaimInvoiceModel,
  coveragePayor: CoveragePayorModel,
  config: Config,
): string {
  return template(claimInvoiceTemplate)({
    header: getHeaderContent(config),
    coveragePayorName: claimInvoice.get('to'),
    coveragePayorAddress: lineBreaksToHTML(claimInvoice.get('coverage_payor_address', '', true, false)), // some doc have this value set to null
    date: claimInvoice.getDateGenerated(),
    dateRange: claimInvoice.getInvoiceMonth(),
    claimsInvoiceNumber: claimInvoice.get('internal_clinic_id'),
    signatureLabel: config.getIn(['print', 'signatureLabel'], '').replace(/(\r\n|\n|\r)/gm, '<br />'),
    claims: claimInvoice.getClaimsDataForPrint(),
    legendTitle: config.getIn(['print', 'claimsReportLegendTitle'], 'Treatment Codes'),
    legendItems: config.getIn(['print', 'claimsReportLegendItems'], []),
    showInvoiceNumber: config.getIn(['print', 'claimsReportShowInvoiceNumber'], true),
    showDiagnosis: config.getIn(['print', 'claimsReportShowDiagnosis'], false),
    claimsReportNote: config.getIn(['print', 'claimsReportNote'], '').replace(/(\r\n|\n|\r)/gm, '<br />'),
    totalFees: claimInvoice.getAmountClaimed(),
    panelCategories: claimInvoice.get('treatment_category_list', []),
    showPanelCategories: coveragePayor.get(['invoice_settings', 'show_treatment_details'], false),
    showPanelCategoriesBreakdown: coveragePayor.get(['invoice_settings', 'show_treatment_breakdown'], false),
    showPanelCategoriesAsLegend: config.getIn(['print', 'usePanelCategoriesSummary'], false),
  });
}

/**
 * Prints a given List of ClaimInvoices, separating each one onto their own pages.
 * @export
 * @param {List<ClaimInvoiceModel>} claimInvoices A List of ClaimInvoices to print.
 * @param {List<CoveragePayorModel>} coveragePayors A List of all available CoveragePayors.
 * @param {Config} config App Config.
 * @param {List<SalesItemModel>} salesItems A List of SalesItemModels.
 * @param {List<DrugModel>} drugs A List of DrugModels.
 * @param {List<BillItemModel>} billItems A list of bill items.
 * @returns {Promise<void>}
 */
export function printClaimInvoices(
  claimInvoices: List<ClaimInvoiceModel>,
  coveragePayors: List<CoveragePayorModel>,
  config: Config,
): Promise<void> {
  const invoices = claimInvoices.map((claimInvoice) => {
    const coveragePayor = coveragePayors.find(c => c.get('_id') === claimInvoice.get('coverage_payor_id'));
    if (!coveragePayor) {
      throw new Error('Coverage payor not found');
    }
    return getClaimInvoiceTemplate(claimInvoice, coveragePayor, config);
  }).toArray();
  print(template(multipleClaimInvoicesTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('invoice'),
    invoices,
  }));
  return Promise.resolve();
}

/**
 * Prints a given List of ClaimInvoices.
 * @export
 * @param {List<ClaimInvoiceModel>} claimInvoices A List of ClaimInvoices.
 * @param {List<CoveragePayorModel>} coveragePayors A List of all CoveragePayors.
 * @param {Config} config App Config.
 * @param {Moment} startDate Filter start Date.
 * @param {Moment} endDate Filter end Date.
 * @param {string} invoiceMonth Claims month generated.
 * @returns {Promise<void>}
 */
export function printClaimInvoicesSummary(
  claimInvoices: List<ClaimInvoiceModel>,
  coveragePayors: List<CoveragePayorModel>,
  config: Config,
  startDate?: Moment,
  endDate?: Moment,
  invoiceMonth?: string,
): Promise<void> {
  print(template(claimInvoicesSummaryTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('claim_invoice_summary'),
    invoices: claimInvoices.toArray(),
    coveragePayors,
    header: getHeaderContent(config),
    date: new Moment().format(getDateFormat()),
    startDate: startDate ? startDate.format(getDateFormat()) : undefined,
    endDate: endDate ? endDate.format(getDateFormat()) : undefined,
    invoiceMonth: invoiceMonth ? invoiceMonth.replace('_', '-') : '-',
    currencyLabel: config.get('currency_shorthand_label', 'RM'),
  }));
  return Promise.resolve();
}

/**
 * Prints a claim invoice with the given data.
 * @param {ClaimInvoiceModel} claimInvoice Claim Invoice
 * @param {CoveragePayorModel} coveragePayor The panel for the claim
 * @param {Config} config Clinic config.
 * @param {List<SalesItemModel>} salesItems A List of SalesItemModels.
 * @param {List<DrugModel>} drugs A List of DrugModels.
 * @param {List<BillItemModel>} billItems A list of bill items.
 * @returns {Promise<void>}
 */
export function printClaimInvoice(
  claimInvoice: ClaimInvoiceModel,
  coveragePayor: CoveragePayorModel,
  config: Config,
): Promise<void> {
  const isLandscape = coveragePayor.get(['invoice_settings', 'is_landscape'], false);
  print(template(singleClaimInvoiceTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('invoice'),
    invoice: getClaimInvoiceTemplate(
      claimInvoice,
      coveragePayor,
      config,
    ),
    pageSize: isLandscape ? 'landscape' : 'portrait',
  }));
  return Promise.resolve();
}


/**
 * Prints a consult report
 * @param {boolean} isGrouped If true it will used the grouped data template.
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {any} totals An object with different totals
 * @param {any} dates An object with from and to dates
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @returns {void}
 */
export function printConsultReports(
  isGrouped: boolean,
  columns: Array<{ label: string, value: string, renderMethod?: string }>,
  data: Array<Array<string>> | Array<{}>,
  totals: { [key: string]: string },
  dates: { [key: string]: Moment },
  config: Config,
  title: string,
  isLandscape: boolean,
) {
  const params = Object.assign(
    {},
    totals,
    {
      fromDate: dates.fromDate ? dates.fromDate.format(getDateFormat()) : '',
      toDate: dates.toDate ? dates.toDate.format(getDateFormat()) : '',
    },
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );
  if (isGrouped) {
    print(template(consultReportsGroupedTemplate)(params));
  } else {
    print(template(consultReportsTemplate)(params));
  }
}

/**
 * Prints a consult report
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {any} totals An object with different totals
 * @param {any} dateRange An object with date range selected
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @returns {void}
 */
export function printSuppliesReceived(
  columns: Array<{ label: string, value: string, renderMethod?: string }>,
  data: Array<Array<string>> | Array<{}>,
  totals: { [key: string]: string },
  dateRange: { [key: string]: string },
  config: Config,
  title: string,
  isLandscape: boolean,
) {
  const params = Object.assign(
    {},
    totals,
    dateRange,
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );
  print(template(suppliesReceivedTemplate)(params));
}

/**
 * Prints appointments report
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {any} dateRange An object with date range selected
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @returns {void}
 */
export function printAppointmentsReport(
  columns: Array<{ label: string, value: string }>,
  data: Array<Array<string>> | Array<{}>,
  dateRange: { [key: string]: string },
  config: Config,
  title: string,
  isLandscape: boolean = false,
) {
  const params = Object.assign(
    {},
    dateRange,
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );
  print(template(appointmentReportTemplate)(params));
}

/**
 * Prints a consult report
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {any} totals An object with different totals
 * @param {any} dateRange An object with date range selected
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @returns {void}
 */
export function printCostReport(
  columns: Array<CustomColumn>,
  data: Array<Array<string>> | Array<{}>,
  totals: { [key: string]: string },
  dateRange: { [key: string]: string },
  config: Config,
  title: string,
  isLandscape: boolean,
) {
  const params = Object.assign(
    {},
    totals,
    dateRange,
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );
  print(template(costReportTemplate)(params));
}

/**
 * Prints a stock take
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @returns {void}
 */
export function printStockTake(
  columns: Array<CustomColumn>,
  data: Array<Array<string>> | Array<{}>,
  config: Config,
  title: string,
) {
  const params = Object.assign(
    {},
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: 'landscape',
    },
  );
  print(template(stockTakeTemplate)(params));
}

/**
 * Prints a campaign jobs list
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape is protrait or landscape
 * @returns {void}
 */
export function printCampaignJobsList(
  columns: Array<CustomColumn>,
  data: Array<Array<string>> | Array<{}>,
  config: Config,
  title: string,
  isLandscape: boolean,
) {
  const params = Object.assign(
    {},
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );
  print(template(campaignJobsListTemplate)(params));
}
/**
 * Prints a Medical Certificate with the given data.
 * @param {Immutable.Map} config Clinic config.
 * @param {MedicalCertificateModel} medicalCertificate A MedicalCertificate to print.
 * @param {PatientModel} patient The patient for the MedicalCertificate.
 * @param {string} doctorOnDuty The name of the Dr on duty.
 * @param {List<ConditionModel>} diagnoses List of all diagnoses.
 * @returns {undefined}
 */
export function printMedicalCertificate(
  config: Config, medicalCertificate: MedicalCertificateModel,
  patient: PatientModel, doctorOnDuty: string, diagnoses: List<ConditionModel>,
) {
  print(template(medicalCertificateTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('medical_certificate'),
    logoURL: config.getIn(['clinic', 'logo', 'assetID']) ?
      `${getBaseApiUrl()}/asset/${config.getIn(['clinic', 'logo', 'assetID'])}` : undefined,
    header: getHeaderContent(config),
    date: medicalCertificate.getCreationDate(),
    doctor: doctorOnDuty,
    patientIC: patient.get('ic', '          '),
    patientCaseID: patient.get('case_id', ''),
    patientName: patient.get('patient_name', ''),
    employer: config.getIn(['print', 'medicalCertificateDefaultEmployer'], ''),
    days: medicalCertificate.get('days', ''),
    reason: medicalCertificate.get('reason', ''),
    notes: medicalCertificate.get('notes', ''),
    startDate: medicalCertificate.getStartDate(),
    endDate: medicalCertificate.getEndDate(),
    showIC: config.getIn(['print', 'medicalCertificateShowIC'], true),
    showTime: config.getIn(['print', 'medicalCertificateShowTime'], true),
    showDiagnoses: config.getIn(['print', 'medicalCertificateShowDiagnoses'], false),
    diagnoses: diagnoses.map(d => d.get('name')).toArray().join(', '),
    showCaseID: config.getIn(['print', 'medicalCertificateShowCaseID'], false),
    signatureLabel: config.getIn(['print', 'signatureLabel'], '').replace(/(\r\n|\n|\r)/gm, '<br />'),
    serialNumber: medicalCertificate.get('internal_clinic_id', ''),
    timeIssued: prettifyTime(medicalCertificate.getTimestamp()),
    userStyles: {
      showHeader: config.getIn(['print', 'medicalCertificate', 'showHeader'], true),
      hasFixedHeight: config.getIn(['print', 'medicalCertificate', 'height']) !== undefined,
      height: config.getIn(['print', 'medicalCertificate', 'height']) !== undefined ?
        `${config.getIn(['print', 'medicalCertificate', 'height'], 0) - config.getIn(['print', 'medicalCertificate', 'marginTop'], 0)}mm` :
        'auto',
      marginTop: `${config.getIn(['print', 'medicalCertificate', 'marginTop'], '0')}mm`,
      fontSize: `${config.getIn(['print', 'medicalCertificate', 'fontSize'], '1')}em`,
    },
  }));
}

/**
 * Prints a Time Chit with the given data.
 * @param {Immutable.Map} config Clinic config.
 * @param {TimeChitModel} timeChit A TimeChit to print.
 * @param {PatientModel} patient The patient for the TimeChit.
 * @param {string} encounterType The type of the encounter.
 * @returns {undefined}
 */
export function printTimeChit(
  config: Config, timeChit: TimeChitModel,
  patient: PatientModel, encounterType: string,
) {
  print(template(timeChitTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title: translate('time_chit'),
    header: getHeaderContent(config),
    logoURL: config.getIn(['clinic', 'logo', 'assetID']) ?
      `${getBaseApiUrl()}/asset/${config.getIn(['clinic', 'logo', 'assetID'])}` : undefined,
    date: timeChit.getCreationDate(),
    patientIC: patient.get('ic', ''),
    patientName: patient.get('patient_name', ''),
    startTime: prettifyTime(timeChit.get('start_time', '')),
    endTime: prettifyTime(timeChit.get('end_time', '')),
    notes: timeChit.get('notes', ''),
    signatureLabel: config.getIn(['print', 'signatureLabel'], '').replace(/(\r\n|\n|\r)/gm, '<br />'),
    encounterType,
    serialNumber: timeChit.get('internal_clinic_id', ''),
  }));
}

/**
 * Returns the prescribed duration by joining the duration tuple to single string format, if found.
 * Returns emdash otherwise. a sample duration value will be : [ 3, 'day']
 * @param {PrescriptionModel} prescription The prescription to print durations for
 * @returns {string}
 */
function getPrescribedDuration(prescription: PrescriptionModel) {
  const duration = prescription.get('prescribed_duration', UNICODE.EMDASH);
  if (duration === UNICODE.EMDASH) {
    return duration;
  }
  if (Array.isArray(duration) && duration.length === 2) {
    return pluralizeWord(duration[1], duration[0]);
  }
  return UNICODE.EMDASH;
}

/**
 * Prints prescription labels for the given prescriptions.
 * @export
 * @param {Config} config App config
 * @param {List<PrescriptionModel>} prescriptions The prescriptions to print labels for
 * @param {PatientModel} patient The patient the prescription is for.
 * @param {List<DrugModel>} drugs All drugs in DB.
 * @returns {void}
 */
export function printPrescriptionLabels(
  config: Config, prescriptions: List<PrescriptionModel>,
  patient: PatientModel, drugs: List<DrugModel>,
) {
  const height = config.getIn(['print', 'prescription_labels', 'height'], 36);
  const marginVertical = config.getIn(['print', 'prescription_labels', 'margin_vertical'], 10);
  logPrescriptionLabelPrinted();
  print(template(prescriptionLabelTemplate)({
    title: translate('prescription_labels'),
    date: new Moment().format(getDateFormat()),
    patientName: patient.get('patient_name', ''),
    width: config.getIn(['print', 'prescription_labels', 'width'], 89),
    height,
    marginVertical,
    marginHorizontal: config.getIn(['print', 'prescription_labels', 'margin_horizontal'], 10),
    paddingTop: config.getIn(['print', 'prescription_labels', 'paddingTop'], 0),
    labelHeight: height - (2 * marginVertical),
    labelTitle: config.getIn(['print', 'prescription_labels', 'title'], ''),
    labelSidebar: lineBreaksToHTML(config.getIn(['print', 'prescription_labels', 'sidebar'], '')),
    sidebarWidth: config.getIn(['print', 'prescription_labels', 'sidebarWidth'], 20),
    headerTitleFontSize: config.getIn(['print', 'prescription_labels', 'headerTitleFontSize'], 24),
    contentFontSize: config.getIn(['print', 'prescription_labels', 'contentFontSize'], 13),
    titleFontSize: config.getIn(['print', 'prescription_labels', 'titleFontSize'], 13),
    prescriptions: prescriptions.map(p => ({
      name: p.getDrug(drugs),
      instructions: p.getDosage(),
      reason: p.get('reason', ''),
      notes: p.get('notes', ''),
      duration: getPrescribedDuration(p),
    })).toArray(),
    userStyles: {
      showHeader: config.getIn(['print', 'prescription_labels', 'showHeader'], true),
      showSidebar: config.getIn(['print', 'prescription_labels', 'showSidebar'], true),
      showFieldLabels: config.getIn(['print', 'prescription_labels', 'showFieldLabels'], true),
      showLabelTopbar: config.getIn(['print', 'prescription_labels', 'showLabelTopbar'], false),
      showPrescribedDuration: config.getIn(['print', 'prescription_labels', 'showPrescribedDuration'], true),
    },
    labelTopbar: config.getIn(['print', 'prescription_labels', 'labelTopbar'], ''),
    labelTopbarHeight: config.getIn(['print', 'prescription_labels', 'labelTopbarHeight'], 20),
  }));
}

/**
 * Prints a prescription report with the given data and columns.
 * @param {Config} config App Config
 * @param {string} title The title of the printed document.
 * @param {Array<string>} columns An array of column headers
 * @param {Array<object>} data The data for each prescription
 * @param {Moment} startDate The start of the date range this represents
 * @param {Moment} endDate The end of the date range this represents
 * @param {Array<string>} columnKeys An array of column keys (for accessing data).
 * @param {boolean} groupDrugs If true group by drugs when printing.
 * @returns {Promise<void>}
 */
export function printPrescriptionsReport(
  config: Config,
  title: string,
  columns: Array<string>,
  data: Array<{ [key: string]: string }>,
  startDate: Moment,
  endDate: Moment,
  columnKeys: Array<string>,
  groupDrugs: boolean,
): Promise<void> {
  if (groupDrugs) {
    const groupedData = {};
    data.forEach((row) => {
      if (groupedData[row.drugId]) {
        groupedData[row.drugId].push(row);
      } else {
        groupedData[row.drugId] = [row];
      }
    });
    print(template(prescriptionReportsGroupedTemplate)({
      styles: template(stylesTemplate)(stylesData),
      title,
      date: new Moment().format(getDateFormat()),
      startDate: startDate.format(getDateFormat()),
      endDate: endDate.format(getDateFormat()),
      header: lineBreaksToHTML(config.getIn(['print', 'header'], '')),
      columns,
      data: groupedData,
      dataKeys: Object.keys(groupedData),
      columnKeys,
    }));
  } else {
    print(template(prescriptionsReportTemplate)({
      styles: template(stylesTemplate)(stylesData),
      title,
      date: new Moment().format(getDateFormat()),
      startDate: startDate.format(getDateFormat()),
      endDate: endDate.format(getDateFormat()),
      header: lineBreaksToHTML(config.getIn(['print', 'header'], '')),
      columns,
      data,
      columnKeys,
    }));
  }
  return Promise.resolve();
}

/**
 * Prints payemnt history table
 * @param {Array<CustomColumn>} columns The columns to display
 * @param {any} data The data to display
 * @param {any} totals An object with different totals
 * @param {any} dates An object with from and to dates
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @returns {void}
 */
export function printPaymentHistory(
  columns: Array<CustomColumn>,
  data: Array<Array<string>> | Array<{}>,
  totals: { [key: string]: string },
  dates: { [key: string]: Moment },
  config: Config,
  title: string,
  isLandscape: boolean,
) {
  const params = Object.assign(
    {},
    totals,
    {
      fromDate: dates.fromDate ? dates.fromDate.format(getDateFormat()) : '',
      toDate: dates.toDate ? dates.toDate.format(getDateFormat()) : '',
    },
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );
  print(template(paymentHistoryTemplate)(params));
}

/**
 * Prints the current inventory table as inventory report
 * @param {Config} config App Config
 * @param {string} title The title of the printed document.
 * @param {Array<string>} columnHeaders An array of column headers
 * @param {Array<object>} data The data for each prescription
 * @param {Array<string>} columnKeys An array of column keys (for accessing data).
 * @returns {void}
 */
export function printInventoryReport(
  config: Config,
  title: string,
  columnHeaders: Array<string>,
  data: Array<{ [key: string]: string }>,
  columnKeys: Array<string>,
): void {
  print(template(inventoryReportTemplate)({
    styles: template(stylesTemplate)(stylesData),
    title,
    date: new Moment().format(getDateFormat()),
    header: lineBreaksToHTML(config.getIn(['print', 'header'], '')),
    columnHeaders,
    data,
    columnKeys,
  }));
}


/**
 * Prints the inventory Reports
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {any} dates An object with from and to dates
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @returns {void}
 */
export function printInventoryReports(
  columns: Array<{ label: string, value: string, renderMethod?: string }>,
  data: Array<Array<string>> | Array<{}>,
  dates: { [key: string]: Moment },
  config: Config,
  title: string,
  isLandscape: boolean,
) {
  const params = Object.assign(
    {},
    {
      fromDate: dates.fromDate ? dates.fromDate.format(getDateFormat()) : '',
      toDate: dates.toDate ? dates.toDate.format(getDateFormat()) : '',
    },
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: new Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
    },
  );

  print(template(inventoryReportsTemplate)(params));
}
/**
 * Handle printing of Document Template
 * @param {DocumentDataModel} documentData A documentDataModel
 * @param {DocumentTemplateModel} documentTemplate Document Template model
 * @returns {void}
 */
export function printDocument(
  documentData: DocumentDataModel,
  documentTemplate: DocumentTemplateModel,
): void {
  createInfoNotification(translate('please_wait_while_converting_doc_file',
    { docTitle: documentTemplate.get('title') }));
  const assetID = documentTemplate.get('asset_id');
  getDocumentTemplate(assetID)
    .then(doc => setDocumentTemplateData(doc, documentData.get('data')))
    .then(doc => getDocumentBlob(doc))
    .then((blob) => {
      convertDocumentAndPrint(new File([blob], documentTemplate.get('file_name')))
        .then((response) => {
          if (response) {
            if (response.ok && response.contents) {
              const newBlob = dataURItoBlob(`data:application/pdf;base64,${response.contents}`);
              const url = URL.createObjectURL(newBlob);
              if (browserIs('chrome')) {
                const newWindow = window.open(url);
                if (newWindow) { // will be null if popup is disabled
                  newWindow.onload = () => {
                    newWindow.print();
                    newWindow.onblur = () => { // There is a bit of delay sometimes so we actually wait for the window to lose focus to the print dialog first
                      newWindow.onfocus = () => {
                        newWindow.close();
                      };
                    };
                  };
                }
              } else if (browserIs('firefox')) {
                const newWindow = window.open('');
                if (newWindow) {
                  const iframe = newWindow.document.createElement('iframe');
                  iframe.style.width = '100%';
                  iframe.style.height = '100%';
                  iframe.setAttribute('src', `${getBaseUrl()}/static/libs/pdf-auto-print/web/viewer.html?file=${url}`);
                  newWindow.document.body.appendChild(iframe);
                }
              }
            } else {
              createErrorNotification(response.msg);
            }
          } else {
            createErrorNotification(translate('something_went_wrong'));
          }
        });
    });
}

/**
 * Prints a consult report
 * @param {EncounterModel} encounter encounter model
 * @param {Config} config Clinic config
 * @param {List<SalesItemModel>} salesItems List of sales items to chec encounter type
 * @returns {void}
 */
export function printQueueTicket(
  encounter: EncounterModel,
  config: Config,
  salesItems?: List<SalesItemModel>,
) {
  const queueNumber = encounter.getQueueNumber();
  const formattedQno = queueNumber && !Number.isNaN(queueNumber)
    ? queueNumber.toString().padStart(3, '0')
    : queueNumber || '';
  const userTickerConfig = config.getIn(['patient_queue', 'ticket_config']).toJS();
  const ticketConfig = Object.assign(userTickerConfig, {
    // default value of title is clinic name
    // clinic name can't be set inside defaultConfig, hence check for null and show clinic name
    // `null` value in config indicates field not modified by user
    title: userTickerConfig.title === null ? config.getIn(['clinic', 'name']) : userTickerConfig.title,
    // additional check for width equals zero
    width: userTickerConfig.width || defaultConfig.patient_queue.width,
  });
  const params = Object.assign(
    ticketConfig,
    {
      styles: template(stylesTemplate)(stylesData),
      stages: encounter.getActiveStages().toArray(),
      queueNumber: formattedQno,
      pageTitle: `Print ${encounter.getQueueNumber()}`,
      date: Moment().format(getDateFormat()),
      time: Moment().format(getTimeFormat()),
      location: encounter.get('location'),
      encounterType: encounter.getEncounterType(salesItems),
    },
  );
  print(template(queueTicketTemplate)(params));
}

/**
 * Prints a consult report
 * @param {Array<{ label: string, value: string }>} columns The columns to display
 * @param {any} data The data to display
 * @param {Config} config The app Config.
 * @param {string} title The title of the report.
 * @param {boolean} isLandscape If true then print in landscape.
 * @param {string} totalOutStanding Total outstanding amount if selected
 * @returns {void}
 */
export function printPaymentsOutstandingReport(
  columns: Array<CustomColumn>,
  data: Array<Array<string>> | Array<{}>,
  config: Config,
  title: string,
  isLandscape: boolean,
  totalOutStanding?: string,
) {
  const params = Object.assign(
    {},
    {
      styles: template(stylesTemplate)(stylesData),
      title,
      header: getHeaderContent(config),
      columns,
      data,
      date: Moment().format(getDateFormat()),
      pageSize: isLandscape ? 'landscape' : 'portrait',
      totalOutStanding,
    },
  );
  print(template(paymentsOutstandingReport)(params));
}
