import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import translate from './../../utils/i18n';
import { colours } from './../../utils/css';
import { fetchRandomDetailing, savePharmaConnectEvent } from './../../utils/api';
import AnnouncementModal, { getSeenKey } from '../modals/announcementModal';
import DetailingResponseModel from './../../models/pharmaconnect/detailingResponseModel';
import type { Config } from '../../types';
import { CLICK, PHARMACONNECT_SPLASH_AD, SHOW, trackEvent } from './../../utils/analytics';


interface Props extends RouteComponentProps {
  program: string,
  detailing: DetailingResponseModel,
  setDetailing: (details: DetailingResponseModel) => void,
  klinifyConfig: Config,
}

const ListContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr',
  gridColumnGap: 0,
});

const Img = glamorous.img({
  maxWidth: 640,
  maxHeight: 640,
});

const Wrapper = glamorous.div({
  background: colours.midnightGrey,
});

/**
 * Render the banner
 * @param {string} outbreak text to add
 * @returns {HTMLElement} html element
 */
const DetailingModal = ({
  program,
  detailing,
  setDetailing,
}: Props) => {
  const [isOpen, setOpenModal] = useState(false);
  const [redirect, setRedirect] = useState<boolean>();

  useEffect(() => {
    fetchRandomDetailing('LG').then((res) => {
      setDetailing(res);
      setOpenModal(true);
      trackEvent(PHARMACONNECT_SPLASH_AD, SHOW, res.get('brand_id'));
    }).catch(() => {
      setOpenModal(false);
    });
  }, []);

  if (redirect) {
    return <Redirect to={`/pharmaconnect/${detailing.get('brand_id', '')}`} />;
  }

  if (!isOpen) {
    return null;
  }

  const modalContent = (
    <ListContainer>
      <Wrapper>
        <Img src="/static/images/pharmaconnect-sidebar.png" alt="indicator" />
      </Wrapper>
      <Wrapper>
        <Img
          src={detailing.get('blob_url', '')}
          alt="brochure"
        />
      </Wrapper>
    </ListContainer>
  );

  return (
    <AnnouncementModal
      program={program}
      footerButtonLabel={translate('pharmaconnect_view_brochure')}
      id="pharmaconnect-modal"
      onExplicitClose={() => {
        savePharmaConnectEvent('CLICK', 'AD', detailing.get('id', ''));
        trackEvent(PHARMACONNECT_SPLASH_AD, CLICK, detailing.get('brand_id'));
        setRedirect(true);
      }}
      onClose={() => {
        savePharmaConnectEvent('READ', 'AD', detailing.get('id', ''));
      }}
      saveInSessionStorage
    >
      {modalContent}
    </AnnouncementModal>
  );
};

export default DetailingModal;
