import React from 'react';
import ReactMonthPicker from 'react-month-picker';
import glamorous from 'glamorous';

import { MONTHS } from './../../utils/time';
import Label from './label';
import { colours } from './../../utils/css';

export type Value = { month: number, year: number };

type Props = {
  description?: string,
  disabled: boolean,
  divClassName: string,
  id: string,
  label: string,
  labelClassName: string,
  onChange: (value: Value) => void,
  required?: boolean,
  style: { [key: string]: string | number },
  value: Value,
  maxValue?: Value, // Max month/year value possible (inclusive).
  errorMessage?: string,
  hideLabel: boolean,
};

const ValueBox = glamorous.div({
  cursor: 'pointer',
  background: colours.white,
  marginBottom: 0,
});


/**
 * A component for selecting a month and year.
 * @class MonthPicker
 * @extends {React.Component<Props>}
 */
class MonthPicker extends React.PureComponent<Props> {
  picker: null;

  static defaultProps = {
    disabled: false,
    divClassName: '',
    placeholder: '',
    labelClassName: '',
    inputStyle: {},
    style: {},
    value: undefined,
    hideLabel: false,
  };

  /**
   * Returns the formatted value.
   * @returns {string}
   */
  getFormattedValue(): string {
    const month = MONTHS.find(m => m.value === parseInt(this.props.value.month, 10)) || {};
    return `${month.label} ${this.props.value.year}`;
  }

  /**
   * Gets the range for the MonthPicker
   * @returns {({ min: Value, max: Value } | void)}
   */
  getRange(): { min: Value, max: Value } | void {
    return this.props.maxValue ?
      { min: { year: 1900, month: 1 }, max: this.props.maxValue } :
      undefined;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const divClassName = `o-form__item ${this.props.divClassName}`;
    return (
      <div className={divClassName} style={this.props.style}>
        {
          !this.props.hideLabel &&
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label}
            invalid={this.props.required && (this.props.value === undefined || this.props.value === '')}
          />
        }
        {
          this.props.description &&
          <p className="o-sub-label">{this.props.description}</p>
        }
        <ReactMonthPicker
          disabled={this.props.disabled}
          ref={(c) => { this.picker = c; }}
          value={this.props.value}
          lang={MONTHS.map(m => m.label.substring(0, 3))}
          onChange={(year, month) => {
            this.props.onChange({ year, month });
            if (this.picker) {
              this.picker.dismiss();
            }
          }}
          onDismiss={value => this.props.onChange(value)}
          years={this.getRange()}
        >
          <ValueBox
            className="o-text-input"
            onClick={() => {
              if (this.picker) {
                this.picker.show();
              }
            }}
          >
            <label>{this.getFormattedValue()}</label>
          </ValueBox>
        </ReactMonthPicker>
        {
          this.props.errorMessage &&
          <div className="o-text-input__error">{this.props.errorMessage}</div>
        }
      </div>
    );
  }
}

export default MonthPicker;
