import { useState, useEffect } from 'react';

/**
 *
 * @desc Custom hook to debounce value
 * @param {any} value Value to debounce
 * @param {number} delay Delay in ms
 * @returns {value} Returns the value passed
 */
function useDebounce(value: any, delay?: number = 400) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // Prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay],
  );

  return debouncedValue;
}

export default useDebounce;
