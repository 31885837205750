/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable require-jsdoc */
import React, { Fragment, useState } from 'react';
import { List } from 'immutable';
import glamorous, { Hr } from 'glamorous';
import { CellInfo } from 'react-table';
import moment from 'moment';
import { DATE_FORMAT_TO_SAVE } from '../../constants';
import Select from '../inputs/select';
import translate from '../../utils/i18n';
import { Config, MapValue, PrescriptionAttributes, SelectOption } from '../../types';
import { renderMultilineContent, renderPriceOrString } from '../../utils/tables';
import Table from '../table/table';
import DrugModel from '../../models/drugModel';
import PractitionerModel from '../../models/practitionerModel';
import Button from '../buttons/button';
import EncounterModel from '../../models/encounterModel';

type Props = {
    coveragePayorID?: string,
    prescriptionHistory?: any,
    practitioners: List<PractitionerModel>,
    drugs: List<DrugModel>
    addDrugsFromPreviousPrescriptions: (addedDrugs?: MapValue) => void,
    currentEncounter: EncounterModel,
    config: Config,
    encounters: List<EncounterModel>,
}

type State = {
    previousPrescriptions: MapValue,
    previousEncounter?: EncounterModel,
    selectedEncounterId: string
}

const LIMIT_PREVIOUS_CONSULT: number = 5;

const Row = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  marginTop: '1rem',
});

const Col = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  flex: '1',
});

const iconStyle = {
  marginBottom: '-3px',
  marginRight: '0.5rem',
};

const formatOptionLabel = ({ label, dateLabel }) => (
  <div style={{ display: 'flex' }}>
    <div>{label}</div>
    <div style={{ position: 'absolute', right: '1.33rem' }}>
      {dateLabel}
    </div>
  </div>
);

class PrescriptionHistoryList extends React.Component<Props, State> {
  /**
     * Creates an instance of PrescriptionHistoryList.
     * @param {Props} props props
     */
  constructor(props: Props) {
    super(props);
    this.state = {
      previousPrescriptions: [],
      selectedEncounterId: '',
      previousEncounter: undefined,
    };
  }

  loadDoctorEncounterHistory(): List<SelectOption> {
    const options = this.props.prescriptionHistory.filter(p => p.prescriptions && p.encounter_id !== this.props.currentEncounter.get('_id')).map((p) => {
      const ecounterDetail = this.props.encounters.find(e => e.get('_id') === p.encounter_id);
      return {
        value: p.encounter_id,
        label: this.props.practitioners?.find(pr => pr.get('_id') === p.practitioner_id)?.getName() || '',
        dateTimestamp: ecounterDetail?.getStartTime() || 0,
        dateLabel: moment(ecounterDetail?.getStartTime()).format(`${this.props.config.get('date_format')}` ?? 'YYYY-MM-DD'),
      };
    }).sort((a, b) => b.dateTimestamp - a.dateTimestamp).slice(0, LIMIT_PREVIOUS_CONSULT);
    return options;
  }

  onSelectDrug(event: MapValue, drugId: string) {
    const { previousPrescriptions } = this.state;
    const newPrescriptions = previousPrescriptions;
    const drugToBeAdded = newPrescriptions.find(p => p.drug_id === drugId);
    drugToBeAdded.is_selected = event?.target.checked;
    this.setState({ previousPrescriptions: newPrescriptions });
  }


  getDrugListFromPreviousEncounter(encounterId: string): void {
    if (!encounterId) {
      this.setState({
        previousPrescriptions: [],
        selectedEncounterId: '',
        previousEncounter: undefined,
      });
      return;
    }
    const previousDrugs: List<PrescriptionAttributes> = this.props.prescriptionHistory.find(p => p.encounter_id === encounterId).prescriptions;
    const mappedPreviousPrescriptions = previousDrugs.map((p) => {
      const drugDetails = this.props.drugs.find(d => d.get('_id') === p.drug_id);
      return {
        admin_desc: p?.admin_desc,
        dosing_regimen_id: p?.dosing_regimen_id,
        dosing_admin_time: p?.dosing_admin_time,
        drug_id: p.drug_id,
        is_selected: true,
        drug_name: drugDetails?.get('name'),
        prescribed_dosage: p.prescribed_dosage,
        prescribed_dosage_unit: p.prescribed_dosage_unit,
        prescribed_frequency: p.prescribed_frequency,
        prescribed_duration: p?.prescribed_duration,
        sale_price: drugDetails?.getPrice(this.props.coveragePayorID) || 0, // get latest price
        cost_price: drugDetails?.get('cost_price') || 0, // get latest cost price
        sale_quantity: p.sale_quantity,
        dispense_unit: drugDetails?.getDispensationUnit(),
        start_date: moment().format(DATE_FORMAT_TO_SAVE),
        reason: p.reason,
        notes: p.notes,
        is_previous_prescribed: true,
      };
    });

    const previousEncounter = this.props.encounters.find(e => e.get('_id') === encounterId);
    this.setState({ previousPrescriptions: mappedPreviousPrescriptions, previousEncounter });
  }

  getColumns(): List<MapValue> {
    return List([
      {
        accessor: 'is_selected',
        Header: translate('select'),
        Cell: (row: CellInfo) =>
          <div className="o-table__cell">
            <input
              type="checkbox"
              checked={row.value}
              onChange={e => this.onSelectDrug(e, row.original?.drug_id)}
            />
          </div>,
      },
      { accessor: 'drug_name', Header: translate('drug_name'), minWidth: 110 },
      { accessor: 'prescribed_dosage', Header: translate('dosage') },
      { accessor: 'prescribed_dosage_unit', Header: translate('unit'), Cell: renderMultilineContent },
      { accessor: 'prescribed_frequency', Header: translate('frequency'), Cell: renderMultilineContent },
      { accessor: 'prescribed_duration', Header: translate('duration'), Cell: renderMultilineContent },
      { accessor: 'sale_price', Header: translate('price_du'), Cell: renderPriceOrString },
      { accessor: 'cost_price', Header: translate('cost_du'), Cell: renderPriceOrString },
      { accessor: 'sale_quantity', Header: translate('quantity'), Cell: renderMultilineContent },
      { accessor: 'dispense_unit', Header: translate('dispensation_unit'), Cell: renderMultilineContent },
      { accessor: 'reason', Header: translate('reason'), Cell: renderMultilineContent },
      { accessor: 'notes', Header: translate('note'), Cell: renderMultilineContent },
    ]);
  }

  render() {
    const { selectedEncounterId, previousPrescriptions, previousEncounter } = this.state;
    return (
      <div>
        <Row>
          <Col>
            <h2 style={{ fontFamily: 'robotobold' }}>Select Drugs from Previous Consultation</h2>
            <br />
            <Select
              style={{ width: '30vw' }}
              formatOptionLabel={formatOptionLabel}
              options={
            this.loadDoctorEncounterHistory()
            }
              id="doctor"
              value={selectedEncounterId}
              onValueChanged={(value: string) => {
                this.setState({ selectedEncounterId: value },
                  () => this.getDrugListFromPreviousEncounter(value));
              }}
            />
          </Col>
          { previousEncounter &&
          <Col>
            <div className="o-card" style={{ padding: '1.33rem' }}>
              <h2 style={{ fontFamily: 'robotobold' }}>Cosultation Details</h2>
              <Row>
                <Col>
                  <span><img style={iconStyle} alt="date" src="static/images/icons_appointment_date.png" />
                    { previousEncounter.getDate() }
                  </span>
                </Col>
                <Col>
                  <span> <img style={iconStyle} alt="time" src="static/images/icons_appointment_time.png" />
                    { moment(previousEncounter.getStartTime()).format('LT')}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span><h3><strong>Purpose of Visit:</strong></h3>{ previousEncounter.getEncounterType() }</span>
                </Col>
              </Row>
            </div>
          </Col>
        }
        </Row>

        {
        previousPrescriptions && previousPrescriptions?.length > 0 &&
        <Fragment>
          <Button
            style={{ marginBottom: '1rem', width: '30vw' }}
            className="o-button o-button--small"
            onClick={() => this.props.addDrugsFromPreviousPrescriptions(previousPrescriptions.filter(p => p.is_selected))}
            dataPublic
          >
            {translate('add_selected_drugs')}
          </Button>
          <Table
            columns={this.getColumns()}
            data={previousPrescriptions}
          />
        </Fragment>
        }
        <Hr css={{ gridColumnStart: 1, gridColumnEnd: 3 }} />
        <br />
      </div>

    );
  }
}

export default PrescriptionHistoryList;
