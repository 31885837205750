import { connect } from 'react-redux';

import UserGroups from './../components/userManagement/userGroups';
import { saveFactory } from './../utils/redux';

import type { State, Dispatch, Config } from './../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ userGroups, user, config }: State) => ({
  userGroups,
  user,
  config,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const UserGroupsContainer = connect(mapStateToProps, mapDispatchToProps)(UserGroups);

export default UserGroupsContainer;
