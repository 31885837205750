import * as React from 'react';
import { List, Map } from 'immutable';

import PatientHeader from './../patient/patientHeader';
import ContentTransition from './../contentTransition';
import { isReferralMode } from './../../utils/router';

import type AllergyModel from './../../models/allergyModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type BillModel from './../../models/billModel';
import type EncounterModel from './../../models/encounterModel';

import type { SaveModel, User, Config } from './../../types';
import type AppointmentModel from '../../models/appointmentModel';
import type DrugModel from './../../models/drugModel';

type Props = {
  allergies: List<AllergyModel>,
  patient: PatientModel | PatientStubModel,
  coveragePayors: List<CoveragePayorModel>,
  headerHeight?: number,
  header?: React.ReactNode,
  children: React.ReactNode,
  saveModel?: SaveModel,
  currentEncounter?: EncounterModel | null | undefined,
  currentBill?: BillModel | null | undefined,
  patientStub?: PatientStubModel,
  user: User,
  config?: Config,
  klinifyConfig?: Config,
  encounters?: List<EncounterModel>,
  appointments?: List<AppointmentModel>
  drugs: List<DrugModel>,
  isFromOverviewPage?: boolean
};

type State = {
  patientHeaderHeight: number,
};

/**
 * A layout component for a patient page with a PatientHeader and a scrollable container for the
 * given child component.
 * @class PatientPage
 * @extends {React.Component<Props, State>}
 */
class PatientPage extends React.Component<Props, State> {
  /**
   * Creates an instance of PatientPage.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      patientHeaderHeight: 0,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const headerHeight = this.props.headerHeight ?
      this.state.patientHeaderHeight + this.props.headerHeight : this.state.patientHeaderHeight;
    return (
      <ContentTransition>
        <PatientHeader
          isFromOverviewPage={this.props.isFromOverviewPage}
          patient={this.props.patient}
          coveragePayors={this.props.coveragePayors}
          allergies={this.props.allergies}
          onHeightReady={height => this.setState({ patientHeaderHeight: height })}
          user={this.props.user}
          showActions
          hasCurrentConsult={this.props.currentEncounter !== undefined}
          saveModel={this.props.saveModel}
          currentBill={this.props.currentBill}
          currentEncounter={this.props.currentEncounter}
          patientStub={this.props.patientStub}
          config={this.props.config}
          klinifyConfig={this.props.klinifyConfig}
          encounters={this.props.encounters
            ? this.props.encounters.filter(encounter =>
              encounter.isCurrent() &&
                encounter.isToday() &&
                encounter.get('patient_id') === this.props.patient.get('_id'))
            : List()
          }
          appointments={this.props.appointments}
          drugs={this.props.drugs}
        />
        {this.props.header}
        <div
          className="o-scrollable-container"
          style={{
            height: !isReferralMode() ?
              `calc(100vh - ${headerHeight}px` :
              `calc(50vh - ${headerHeight}px`,
          }}
        >
          {this.props.children}
          <div className="u-margin-bottom--6ws" />
        </div>
      </ContentTransition>
    );
  }
}

export default PatientPage;
