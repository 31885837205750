import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import SpecimensTable from './specimensTable';
import SpecimensForm from './specimensForm';
import Header from './../header/header';

import type SpecimenModel from './../../models/specimenModel';
import type { SaveModel, Config, User } from './../../types';

type Props = {
  saveModel: SaveModel,
  config: Config,
  user: User,
  specimens: List<SpecimenModel>
};

type State = {
  isModalVisible: boolean,
};

/**
 * A component for Specimens.
 * @class Specimens
 * @extends {Component}
 */
class Specimens extends React.Component<Props, State> {
  /**
   * Creates an instance of Specimens.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isModalVisible: false };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { saveModel, user } = this.props;
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('specimens')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <PermissionWrapper permissionsRequired={List([createPermission('specimens', 'create')])} user={user}>
              <StatelessModal
                id="addNewProcedureTypeModal"
                buttonLabel={translate('add_new_specimen')}
                buttonClass="o-button o-button--small"
                title={translate('add_edit_specimen')}
                className="js-modal"
                visible={this.state.isModalVisible}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                explicitCloseOnly
                dataPublicHeader
              >
                <SpecimensForm
                  onSave={specimen =>
                    this.props.saveModel(specimen).then(() => {
                      this.setState({ isModalVisible: false });
                      return specimen;
                    })
                  }
                  onCancel={() => this.setState({ isModalVisible: false })}
                />
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        <SpecimensTable
          saveModel={saveModel}
          user={user}
          config={this.props.config}
          specimens={this.props.specimens}
        />
      </ContentTransition>
    );
  }
}

export default Specimens;
