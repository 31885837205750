/* eslint-disable no-alert */
import * as React from 'react';
import { ReactSpecimen } from 'catalog';
import Moment from 'moment';

import Checkbox from './../../inputs/checkbox';
import Input from './../../inputs/input';
import Label from './../../inputs/label';
import MonthPicker from './../../inputs/monthPicker';
import MultiSelect from './../../inputs/multiSelect';
import NewPasswordInput from './../../inputs/newPasswordInput';
import Radio from './../../inputs/radio';
import Select from './../../inputs/select';
import TagInput from './../../inputs/tagInput';
import TextArea from './../../inputs/textarea';
import TimeInput from './../../inputs/timeInput';
import TimePicker from './../../inputs/timepicker';

import type { SelectOption } from './../../../types';
import type { Item } from './../../inputs/tagInput';

import type { Value as MonthPickerValue } from './../../inputs/monthPicker';

type State = {
  checkboxA: Array<string>,
  checkboxB: Array<string>,
  checkboxC: Array<string>,
  inputA: string,
  inputB: string,
  inputC: number,
  monthPickerA: MonthPickerValue,
  monthPickerB: MonthPickerValue,
  multiSelectA: Array<SelectOption>,
  multiSelectB: Array<SelectOption>,
  newPasswordInputValue: string,
  newPasswordInputValidationValue: string,
  radioA: string,
  radioB: string,
  selectA: string | void,
  selectB: string | void,
  selectC: string | void,
  selectCOptions: Array<SelectOption>,
  tagInputA: Array<Item>,
  tagInputB: Array<Item>,
  textAreaA: string,
  textAreaB: string,
  timeInputA: Moment,
  timePickerA: Moment,
};

/**
 * Input components for style guide
 * @class Buttons
 * @extends {React.Component<Props, State>}
 */
class InputComponents extends React.Component<{}, State> {
  /**
   * Creates an instance of InputComponents.
   * @param {{}} props Props
   * @returns {void}
   */
  constructor(props: {}) {
    super(props);
    this.state = {
      checkboxA: ['1', '3'],
      checkboxB: ['1'],
      checkboxC: ['1', '6'],
      inputA: '',
      inputB: 'Disabled',
      inputC: 0,
      monthPickerA: { month: 3, year: 2014 },
      monthPickerB: { month: 3, year: 2014 },
      multiSelectA: ['Hulk', 'Thor'].map(value => ({ value, label: value })),
      multiSelectB: [],
      newPasswordInputValue: '',
      newPasswordInputValidationValue: '',
      radioA: '1',
      radioB: 'A',
      selectA: 'Cats',
      selectB: undefined,
      selectC: undefined,
      selectCOptions: [{ value: 'cats', label: 'Cats' }],
      tagInputA: [],
      tagInputB: [],
      textAreaA: '',
      textAreaB: '',
      timeInputA: Moment(),
      timePickerA: Moment(),
    };
  }

  /**
   * Render
   * @returns {Component}
   */
  render() {
    const selectOptions = [
      { value: 'cats', label: 'Cats' },
      { value: 'dogs', label: 'Dogs' },
      { value: 'birds', label: 'Birds' },
    ];
    return (
      <React.Fragment>
        <h2 data-public>Inputs</h2>
        <h3 data-public>Checkbox</h3>
        <p>
          A component that can create one or more checkboxes as a single input.
        </p>
        <ReactSpecimen>
          <Checkbox
            id="example-1"
            label="Multiple Options"
            description="I can have as many options as I want."
            options={[
              { label: 'Option 1', value: '1' },
              { label: 'Option 2', value: '2' },
              { label: 'Option 3', value: '3' },
            ]}
            value={this.state.checkboxA}
            onValueChanged={(value, isChecked) => (
              isChecked ?
                this.setState({ checkboxA: this.state.checkboxA.concat([value]) }) :
                this.setState({ checkboxA: this.state.checkboxA.filter(v => v !== value) })
            )}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Checkbox
            id="example-2"
            label="Single Option"
            description="A single option is fine too."
            options={[
              { label: 'On', value: '1' },
            ]}
            value={this.state.checkboxB}
            onValueChanged={(value, isChecked) => (
              isChecked ?
                this.setState({ checkboxB: this.state.checkboxB.concat([value]) }) :
                this.setState({ checkboxB: this.state.checkboxB.filter(v => v !== value) })
            )}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Checkbox
            id="example-3"
            label="Multiple Columns"
            description="If props.multiColumn is set then the checkboxes will be spread across multiple columns."
            multiColumn
            options={[
              { label: 'Option 1', value: '1' },
              { label: 'Option 2', value: '2' },
              { label: 'Option 3', value: '3' },
              { label: 'Option 4', value: '4' },
              { label: 'Option 5', value: '5' },
              { label: 'Option 6', value: '6' },
              { label: 'Option 7', value: '7' },
              { label: 'Option 8', value: '8' },
              { label: 'Option 9', value: '9' },
            ]}
            value={this.state.checkboxC}
            onValueChanged={(value, isChecked) => (
              isChecked ?
                this.setState({ checkboxA: this.state.checkboxC.concat([value]) }) :
                this.setState({ checkboxA: this.state.checkboxC.filter(v => v !== value) })
            )}
          />
        </ReactSpecimen>
        <h3 data-public>Input</h3>
        <p>
          Standard HTML inputs as React components.
        </p>
        <ReactSpecimen>
          <Input
            id="input-1"
            label="Text Input"
            placeholder="Placeholder text"
            description="You can add a description here."
            value={this.state.inputA}
            errorMessage={this.state.inputA.length ? undefined : 'An input can also be given an error message!'}
            required
            onValueChanged={v => this.setState({ inputA: v })}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Input
            id="input-2"
            label="Disabled Input"
            value={this.state.inputB}
            disabled
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Input
            id="input-3"
            label="Number Input"
            value={this.state.inputC}
            required
            onValueChanged={v => this.setState({ inputC: parseFloat(v) })}
          />
        </ReactSpecimen>
        <h3 data-public>Label</h3>
        <p>
          The label component is used in place of standard html &lt;label&gt; elements. It is not
          intended for use on its own, rather it should be used in the construction of new input
          components.
        </p>
        <ReactSpecimen>
          <Label label="Standard Label" id="label-example" />
        </ReactSpecimen>
        <ReactSpecimen>
          <Label label="Label When Input Component Is Invalid" id="label-example" invalid />
        </ReactSpecimen>
        <h3 data-public>MonthPicker</h3>
        <p>
          The label component is used in place of standard html &lt;label&gt; elements. It is not
          intended for use on its own, rather it should be used in the construction of new input
          components.
        </p>
        <ReactSpecimen>
          <MonthPicker
            id="monthpicker-a"
            label="Standard MonthPicker"
            value={this.state.monthPickerA}
            onChange={value => this.setState({ monthPickerA: value })}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <MonthPicker
            id="monthpicker-b"
            label="MonthPicker with Max Value and an Error"
            value={this.state.monthPickerB}
            onChange={value => this.setState({ monthPickerB: value })}
            errorMessage="Oh no error!"
            maxValue={{ month: 4, year: 2015 }}
          />
        </ReactSpecimen>
        <h3 data-public>MultiSelect</h3>
        <p>
          This component allows a user to select multiple options from a set list of options.
        </p>
        <ReactSpecimen>
          <MultiSelect
            id="multi-select-a"
            label="Standard MultiSelect"
            value={this.state.multiSelectA}
            onChange={value => this.setState({ multiSelectA: value.map(v => v.value) })}
            options={[
              { label: 'Hulk', value: 'Hulk' },
              { label: 'Thor', value: 'Thor' },
              { label: 'Captain America', value: 'Captain America' },
              { label: 'Black Panther', value: 'Black Panther' },
            ]}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <MultiSelect
            id="multi-select-b"
            label="MultiSelect with closeOnSelect and required Props"
            description="This will blur the input when an item is selected"
            value={this.state.multiSelectB}
            onChange={value => this.setState({ multiSelectB: value.map(v => v.value) })}
            closeOnSelect
            required
            options={[
              { label: 'John', value: 'John' },
              { label: 'Paul', value: 'Paul' },
              { label: 'George', value: 'George' },
              { label: 'Ringo', value: 'Ringo' },
            ]}
          />
        </ReactSpecimen>
        <h3 data-public>NewPasswordInput</h3>
        <p>
          A component for setting a new password. It verifies that the two new passwords match.
        </p>
        <ReactSpecimen>
          <NewPasswordInput
            value={this.state.newPasswordInputValue}
            validationValue={this.state.newPasswordInputValidationValue}
            onValueChanged={
              ({ value, validationValue }) =>
                this.setState({
                  newPasswordInputValue: value,
                  newPasswordInputValidationValue: validationValue,
                })
            }
          />
        </ReactSpecimen>
        <h3 data-public>Radio</h3>
        <p>
          A component for creating Radio inputs.
        </p>
        <ReactSpecimen>
          <Radio
            id="radio-a"
            label="Standard Radio component"
            value={this.state.radioA}
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' },
            ]}
            onValueChanged={value => this.setState({ radioA: value })}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Radio
            id="radio-b"
            label="Radio with Required and Description"
            description="I'm a description of this input"
            value={this.state.radioB}
            options={[
              { value: 'A', label: 'A' },
              { value: 'B', label: 'B' },
              { value: 'C', label: 'C' },
            ]}
            required
            onValueChanged={value => this.setState({ radioB: value })}
          />
        </ReactSpecimen>
        <h3 data-public>Select</h3>
        <p>
          A component for creating Select style inputs.
        </p>
        <ReactSpecimen>
          <Select
            id="select-a"
            label="Standard Select component with required prop"
            value={this.state.selectA}
            options={selectOptions}
            onValueChanged={value => this.setState({ selectA: value })}
            required
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Select
            id="select-b"
            label="Standard Select component with clearable prop set to false"
            value={this.state.selectB}
            options={selectOptions}
            onValueChanged={value => this.setState({ selectB: value })}
            required
            clearable={false}
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <Select
            id="select-c"
            label="Creatable Select component"
            value={this.state.selectC}
            options={this.state.selectCOptions}
            onValueChanged={(value) => {
              let newOptions = this.state.selectCOptions;
              if (
                value &&
                value.length &&
                !this.state.selectCOptions.find(o => o.value === value)
              ) {
                newOptions = newOptions.concat([{ value, label: value }]);
              }
              this.setState({ selectC: value, selectCOptions: newOptions });
            }}
            creatable
          />
        </ReactSpecimen>
        <h3 data-public>TagInput</h3>
        <p>
          A component that allows the user to select multiple options from a list or add their own
          options.
        </p>
        <ReactSpecimen>
          <TagInput
            id="tag-input-a"
            label="Standard TagInput component with options"
            value={this.state.tagInputA}
            options={selectOptions}
            onChange={value => this.setState({ tagInputA: value })}
            required
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <TagInput
            id="tag-input-b"
            label="Standard TagInput component without options"
            value={this.state.tagInputB}
            options={[]}
            onChange={value => this.setState({ tagInputB: value })}
            required
          />
        </ReactSpecimen>
        <h3 data-public>TextArea</h3>
        <p>
          A component that replaces the standard HTML textarea input. By default it will resize as
          the user adds more lines.
        </p>
        <ReactSpecimen>
          <TextArea
            id="textarea-a"
            label="Standard TextArea component"
            value={this.state.textAreaA}
            onValueChanged={value => this.setState({ textAreaA: value })}
            required
          />
        </ReactSpecimen>
        <ReactSpecimen>
          <TextArea
            id="textarea-b"
            label="Standard TextArea component with minRows set to 1"
            value={this.state.textAreaB}
            onValueChanged={value => this.setState({ textAreaB: value })}
            minRows={1}
            required
            placeholder="Custom Placeholder"
          />
        </ReactSpecimen>
        <h3 data-public>TimeInput</h3>
        <p>
          A component for entering time values.
        </p>
        <ReactSpecimen>
          <TimeInput
            id="timeinput-a"
            label="Standard TimeInput component"
            value={this.state.timeInputA}
            onChange={value => this.setState({ timeInputA: value })}
            required
          />
        </ReactSpecimen>
        <h3 data-public>TimePicker</h3>
        <p>
          A component for entering time values.
        </p>
        <ReactSpecimen>
          <TimePicker
            id="timepicker-a"
            label="Standard TimePicker component"
            value={this.state.timePickerA}
            onValueChanged={value => this.setState({ timePickerA: value })}
          />
        </ReactSpecimen>
      </React.Fragment>
    );
  }
}

export default InputComponents;
