import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import ReactMarkdown from 'react-markdown';
import translate from './../utils/i18n';
import Fetch from './../utils/fetch';
import { wsUnit } from './../utils/css';
import Banner from '../components/outbreak/banner';

const guidelinesURL = '/static/outbreak/guidelines.md';

const Page = glamorous.div({
  padding: `${wsUnit} calc(1.5 * ${wsUnit})`,
  height: `calc(100vh - 2 * ${wsUnit})`,
});

const Container = glamorous.div({
  maxWidth: 720,
});

const CardContainer = glamorous.div({
  backgroundColor: 'white',
  padding: '0 1rem 1rem 1rem',
  marginTop: '1rem',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
});

/**
 * A container for showing outbreak guidelines
 * Skipping the redux integration as this container doesn't need a state management
 * @class OutbreakGuidelinesContainer
 * @extends {React.Component<Props, State>}
 */
const OutbreakGuidelinesContainer = () => {
  const [content, setContent] = useState(translate('loading...'));

  useEffect(() => {
    Fetch(new Request(guidelinesURL), {})
      .then(async (result) => {
        const mdText = await result.text();
        setContent(mdText);
      })
      .catch(() => setContent(translate('outbreak_guidelines_fetch_err')));
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Page className="c-markdown o-scrollable-container">
      <Container>
        <Banner />
        <h2 data-public>{translate('outbreak_guidelines_title')}</h2>
        <CardContainer className="outbreak-guidelines">
          <ReactMarkdown source={content} />
        </CardContainer>
      </Container>
    </Page>
  );
};

export default OutbreakGuidelinesContainer;
