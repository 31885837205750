import React from 'react';

type Props = {
  title: string,
  value: string,
  changePercent: number
}

/**
 * Renders a single summary component that will simply display value and change.
 * @param {SummaryMetric} props SummaryMetric
 * @returns {React.FunctionComponent} The rendered component
*/
function SummaryItem(props: Props) {
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  const { title, value, changePercent } = props;
  const changeClassName = changePercent >= 0 ? 'green' : 'red';
  const changeIndicator = changePercent >= 0 ? '▲' : '▼';
  return (
    <div className="summary-item">
      <label>{title}</label>
      <h3>{value}</h3>
      <span className={changeClassName}>{changeIndicator}{changePercent.toString()}%</span>
    </div>
  );
}

export default SummaryItem;
