import React, { Fragment } from 'react';
import glamorous from 'glamorous';
import type { List } from 'immutable';

import Icon from './../icon';
import Input from './../inputs/input';
import translate from './../../utils/i18n';
import PaymentLineInput from './../payments/paymentLineInput';
import FormError from './../formError';
import { convertNumberToPrice, isFloatEqual } from './../../utils/utils';
import { calculatePaymentsTotal } from './../../utils/billing';
import { wsUnit, colours } from './../../utils/css';
import Header from './../header/header';

import type { MapValue, SaveModel } from './../../types';
import type PaymentModel from './../../models/paymentModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';

type Props = {
  payments: List<PaymentModel>,
  updatePayment: (paymentId: string, changes: { [key: string]: MapValue }) => void,
  addNewPayment: () => void,
  patientTotal: number,
  disabled: boolean,
  paymentTypes: List<PaymentTypeModel>,
  saveModel: SaveModel,
  isPaymentInvalid: boolean,
};

const AddNewPaymentButton = glamorous.button({
  margin: `0 calc(${wsUnit} / 2) calc(${wsUnit} / 2)`,
  width: `calc(100% - ${wsUnit})`,
});

/**
 * A component that will exist in the sidebar of the bill page and allow for the display and editing
 * of bill payments.
 * @class BillPaymentSummary
 * @extends {React.Component<Props, State>}
 */
class BillPaymentSummary extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { payments, addNewPayment, patientTotal, disabled, updatePayment } = this.props;
    let amountOwed = patientTotal - calculatePaymentsTotal(payments);
    // adding a tolerance of 0.01 occuring due to 3 decimals we have.
    if (isFloatEqual(patientTotal, calculatePaymentsTotal(payments), 0.01)) {
      amountOwed = 0;
    }
    const amountOwedAsString = convertNumberToPrice(amountOwed > 0 ? amountOwed : 0);
    const showwarning = !(patientTotal < 0 && payments.size === 0); // sometime just the -ve bill total comes up with patient owed as 0
    return (
      <div className="o-card o-card--no-shadow" style={{ marginTop: 0, fontSize: '12px' }}>
        <Header className="o-card__header" dataPublic>
          <h2 className="o-card__title">{translate('payments_received')}</h2>
        </Header>
        {
          this.props.isPaymentInvalid &&
          <div className="o-card u-no-shadow">
            <FormError>
              {translate('payments_must_not_have_empty_fields')}
            </FormError>
          </div>
        }
        <Fragment>
          {
            payments.map(
              p =>
                <PaymentLineInput
                  payment={p}
                  key={p.get('_id')}
                  updatePayment={(paymentId, changes) => updatePayment(paymentId, changes)}
                  disabled={disabled}
                  paymentTypes={this.props.paymentTypes}
                  saveModel={this.props.saveModel}
                />,
            ).toArray()
          }
        </Fragment>
        <AddNewPaymentButton
          className="o-button o-button--small"
          onClick={() => addNewPayment()}
          style={payments.size ? {} : { marginTop: `calc(${wsUnit} / 2)` }}
          disabled={disabled || amountOwed <= 0}
        >
          {payments.size ? translate('add_another_payment') : translate('add_payment')}
        </AddNewPaymentButton>
        <Header className="o-card__header" dataPublic>
          <h2 className="o-card__title">{translate('amount_owed_to_clinic')}</h2>
        </Header>
        <Input
          id="amount_owed"
          value={amountOwedAsString}
          hideLabel
          label={translate('amount_owed_to_clinic')}
          inputStyle={{ margin: '1em', width: 'auto' }}
          disabled
        />
        {
          amountOwed < 0 && showwarning &&
          <div className="o-data-list__row u-font-color-white" style={{ background: colours.red }}>
            <div className="o-data-list__row__item  u-strong">
              <Icon style={{ color: colours.white, marginRight: wsUnit }}>!</Icon>
              {translate('payments_received_amount_exceeded')}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default BillPaymentSummary;
