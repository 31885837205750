import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import Tooltip from 'react-tooltip-lite';
import translate from '../../utils/i18n';
import SaveButton from '../buttons/saveButton';
import ContentTransition from '../contentTransition';
import Header from '../header/header';
import Table from '../table/table';
import Button from '../buttons/button';
import flagTable from '../hoc/flagTable';
import { createExportRequest } from '../../utils/api';
import { createSuccessNotification } from '../../utils/notifications';
import { renderTextCapitalized, renderTime } from '../../utils/tables';
import { getClinicID, getConfirmation } from '../../utils/utils';
import { colours } from '../../utils/css';
import { CLICK, DATA_EXPORT_DOWNLOAD, DATA_EXPORT_REQUEST, trackEvent } from '../../utils/analytics';

import type { MapValue, SaveModel, SaveModels, TrProps } from '../../types';
import type ExportModel from '../../models/exportModel';
import type ExportsRemainingModel from '../../models/exportsRemainingModel';

const FlagTable = flagTable(Table);

type Props = {
  exportDocs: List<ExportModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  setCurrentDataViewsModels: (models: List<ExportModel>) => void,
  latestDocCreationTime?: number,
  exportsRemaining: ExportsRemainingModel,
}

const COLUMNS = [
  { accessor: 'date', Header: translate('date') },
  { accessor: 'time', Header: translate('time'), Cell: renderTime },
  { accessor: 'status', Header: translate('status'), Cell: renderTextCapitalized },
  { accessor: 'id', Header: translate('doc_#') },
  { accessor: 'action', Header: '' },
];

const UNREAD_FLAG_VALUE = {
  color: 'dodgerblue',
  tooltips: [{
    content: translate('data_export_password_info'),
  }],
};

/**
 * Returns true if the export doc is stuck in queued state for more than x hours
 * Happens if the backend dies for any reason
 * @param {ExportModel} exportDoc export doc
 * @param {number} hoursFromLastUpdate Time in hours till last update
 * @returns {boolean} Returns true if the doc is not updated in `hoursFromLastUpdate` hours
 */
const isUpdatedAfter = (exportDoc: ExportModel, hoursFromLastUpdate: number = 24) =>
  (exportDoc.getLastUpdateTime() + (60 * 60 * hoursFromLastUpdate * 1000)) >= Date.now();

/**
 * @param {Props} props Component props from container
 * @returns {React.FC}
 */
const DataExport = (props: Props) => {
  const [isLoading, setIsLoading] = useState('');

  /**
   * Marks the export docs with `failed` if the state is `queued` for more than 24hrs
   * @returns {void}
   */
  const voidStuckRequests = () => {
    const failedRequests = props.exportDocs.filter(m =>
      (m.get('status') === 'queued' && isUpdatedAfter(m))
      || (m.get('status') === 'in-progress' && isUpdatedAfter(m, 24)))
      .map(m => m.set('status', 'failed'));
    if (failedRequests.size) {
      props.saveModels(failedRequests.toArray());
    }
  };

  useEffect(() => {
    const inteval = setInterval(() => {
      voidStuckRequests();
    }, 1000 * 60);
    return () => {
      clearInterval(inteval);
    };
  }, []);

  /**
   * Hits API that'll create an export doc
   * @returns {void}
   */
  const requestExport = () => {
    /**
     * @returns {void}
     */
    const createRequest = () => {
      setIsLoading('NEW_EXPORT');
      createExportRequest()
        .then((res) => {
          if (res) {
            createSuccessNotification('Export queued sucessfully');
            trackEvent(DATA_EXPORT_REQUEST, CLICK, getClinicID());
          }
        })
        .finally(() => setIsLoading(''));
    };
    if (props.exportDocs.filter(m => m.get('status') === 'queued').size) {
      getConfirmation(translate('data_export_requests_will_be_voided_warning'))
        .then(() => {
          const requestsToVoid = props.exportDocs.filter(m =>
            (m.get('status') === 'queued'))
            .map(m => m.set('status', 'voided'));
          if (requestsToVoid.size) {
            props.saveModels(requestsToVoid.toArray());
          }
          createRequest();
        })
        .catch(() => {});
    } else {
      createRequest();
    }
  };

  /**
   * Voids the export requests
   * @param {ExportModel} exportDoc export doc model
   * @returns {void}
   */
  const voidRequest = (exportDoc: ExportModel) => {
    setIsLoading(exportDoc.get('_id'));
    props.saveModel(exportDoc.set('status', 'voided'))
      .then((savedModel) => {
        setIsLoading('');
        props.setCurrentDataViewsModels(
          props.exportDocs.filter(doc => doc.get('_id') !== exportDoc.get('_id'))
            .push(savedModel),
        );
      });
  };

  /**
   * Triggers on export doc download.
   * Logs the action to GA
   * @param {string} docId _id of export doc
   * @returns {void}
   */
  const onDownload = (docId: string) => {
    trackEvent(DATA_EXPORT_DOWNLOAD, CLICK, `${getClinicID()}-${docId}`);
  };

  /**
   * Returns array of table row
   * @returns {Array<Row>}
   */
  const getTableData = () => props.exportDocs.map((exportDoc) => {
    const status = exportDoc.get('status');
    const docId = exportDoc.get('_id');
    const isValidResult = isUpdatedAfter(exportDoc, 24 * 14);
    return {
      flag: status === 'completed' && isValidResult,
      flagData: { color: UNREAD_FLAG_VALUE.color },
      tooltipData: UNREAD_FLAG_VALUE.tooltips,
      id: docId,
      date: exportDoc.getCreationDate(),
      time: exportDoc.getCreatedTime(),
      status,
      action: status === 'completed' ?
        isValidResult ?
          (
            <a href={exportDoc.get('result')} onClick={() => onDownload(docId)} target="_blank" rel="noopener noreferrer" className="o-text-button o-text-button--contextual">
              {translate('download')}
            </a>
          ) : (
            <Button
              className="o-text-button u-margin-left--zero"
              disabled
              onClick={() => {}}
            >
              {translate('expired')}
            </Button>
          ) : (
            <Button
              className="o-text-button o-text-button--danger u-margin-left--zero"
              disabled={!!isLoading || status !== 'queued'}
              onClick={() => voidRequest(exportDoc)}
            >
              {translate(status === 'queued' ? 'void' : '')}
            </Button>
        ),
    };
  }).toArray();

  /**
   * Row properties style object to pass to table component,
   * this will add a highlighted color to the latest row sorted by time
   * @param {MapValue} state state of ReactTable.
   * @param {MapValue} rowInfo info object about the row clicked, along with data sent to table
   * @returns {TrProps} properties to be used for row
   */
  const getTrProperties = (state: MapValue, rowInfo: MapValue): TrProps => {
    if (rowInfo.original.time === props.latestDocCreationTime) {
      return {
        style: {
          background: colours.tableHighlight,
        },
      };
    }
    return {};
  };

  const inprogressExports = props.exportDocs.filter(m => m.get('status') === 'in-progress' && isUpdatedAfter(m, 24));

  const exportsRemaining = props.exportsRemaining?.get('count');

  return (
    <ContentTransition>
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <Header className="o-card__header" dataPublic>
          <h1 className="o-title">{translate('data_export')}</h1>
          <div className="u-flex-row u-flex-right u-margin-right--1ws">
            <Tooltip
              content={`${exportsRemaining > 0 ? exportsRemaining : 'No'} exports remaining`}
              direction="left"
              tipContentHover
              useDefaultStyles
              tipContentClassName="u-font-small"
            >
              <SaveButton
                onClick={requestExport}
                label="Export data"
                className="o-button o-button--small"
                isSaving={isLoading === 'NEW_EXPORT'}
                disabled={inprogressExports.size > 0 || exportsRemaining <= 0}
              />
            </Tooltip>
          </div>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{ translate('data_export') }</h1>
          </Header>
          <FlagTable
            columns={COLUMNS}
            data={getTableData()}
            noDataText="You haven't requested an export. Click the export data button to create a request."
            defaultSorted={[{ id: 'time', desc: true }, { id: 'date', desc: true }]}
            trProperties={getTrProperties}
            showPagination={props.exportDocs.size > 10}
          />
        </div>
      </section>
    </ContentTransition>
  );
};

export default DataExport;
