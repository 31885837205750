// https://react-table.js.org/#/story/select-table-hoc
import React from 'react';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';

import type { Column, CellProps, MapValue, ComponentReference } from './../../types';

if (process.env.NODE_ENV !== 'test') {
  require('react-table/react-table.css'); // eslint-disable-line global-require
}

type Data = { [key: string]: MapValue }

type Props = {
  columns: Array<Column>,
  data: Array<Data>,
  noDataText: string,
  minRows?: number,
  showPagination?: boolean,
  defaultSorted?: [{ id: string, desc: boolean }],
  loading?: boolean,
  defaultPageSize?: number,
  tableComponentHOC: (component: React.Component) => React.Component,
  getSelectedRows: (selection: Array<string>) => void,
  onClickRow?: (rowInfo?: {}) => void,
  filteredSortedDataHandler?: (filteredData: Array<Data>) => void,
  initialDataHandler?: (filteredData: Array<Data>) => void,
  className?: string,
}

type State = {
  selectAll: boolean,
  selection: Array<string>,
  filteredDataLength?: number,
  filteredSortedDataLength?: number,
};

/**
 * Creates a Table component.
 * @param {any} props The this.props.
 * @returns {React.Component} Table component.
 */
class SelectTable extends React.Component<Props, State> {
  checkboxTable: ComponentReference;

  static defaultProps = {
    showPagination: false,
    tableComponentHOC: component => component,
    getSelectedRows: () => {},
    onClickRow: () => {},
  };

  /**
   * Creates an instance of SelectTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      selection: [],
      selectAll: false,
      filteredDataLength: undefined,
      filteredSortedDataLength: undefined,
    };
    this.wrapCheckboxTable = checkboxHOC(props.tableComponentHOC(ReactTable));
  }

  /**
   * Called functions after component fully rendered
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    this.getInitialData();
    if (prevProps.data.length !== this.props.data.length) {
      this.resetSelection();
    }
  }

  /**
   * Resets selection to initial value
   * @returns {void}
   */
  resetSelection() {
    this.setState({
      selection: [],
      selectAll: false,
    });
  }

  /**
   * Get table initial data
   * @returns {void}
   */
  getInitialData() {
    if (this.checkboxTable) {
      if (!this.checkboxTable.wrappedInstance) throw new Error('SelectTable - checkBoxTable - No wrapped instance');
      const wrappedInstance = this.checkboxTable.getWrappedInstance
        ? this.checkboxTable.getWrappedInstance()
        : this.checkboxTable.wrappedInstance;
      if (wrappedInstance.getResolvedState() && wrappedInstance.getResolvedState().sortedData) {
        const { initialDataHandler } = this.props;
        if (initialDataHandler &&
          this.state.filteredSortedDataLength !==
          wrappedInstance.getResolvedState().sortedData.length
        ) {
          this.setState({ filteredSortedDataLength: undefined });
          if (!this.state.filteredSortedDataLength) {
            this.setState({
              filteredSortedDataLength: wrappedInstance
                .getResolvedState()
                .sortedData
                .length,
            });
          }
          initialDataHandler(
            wrappedInstance
              .getResolvedState()
              .sortedData
              .map(data => data._original), // eslint-disable-line no-underscore-dangle
          );
        }
      }
    }
  }

  /**
   * @param {string} key key
  //  * @param {boolean} shift key
  //  * @param {object[]} row row
   * @returns {undefined}
   */
  toggleSelection = (key: string) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      selection.push(key);
    }
    // update the state
    this.setState({ selection });
    if (this.props.getSelectedRows) {
      this.props.getSelectedRows(selection);
    }
  }

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = !this.state.selectAll;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(item._original._id); // eslint-disable-line
      });
    }
    this.setState({ selectAll, selection });
    if (this.props.getSelectedRows) {
      this.props.getSelectedRows(selection);
    }
  }

  /**
   * Instead of passing our external selection state we provide an 'isSelected'
   * callback and detect the selection state ourselves. This allows any implementation
   * for selection (either an array, object keys, or even a Javascript Set object).
   * @param {string} key key
   * @returns {undefined}
   */
  isSelected = (key: string) => this.state.selection.includes(key);

  /**
   * This method takes array of row objects of latest table and returns them in the same format as data
   * passed in properties. This is called if property filteredSortedDataHandler is passed to <Table> component.
   * Use this property when there is a need to get back filtered/sorted data from table and use it for print/export.
   * @param {Array<Data>} resolvedData data having array of row object after filtered or sorted
   * @returns {string}
   */
  returnFilteredSortedData(resolvedData: Array<Data>): void {
    const { filteredSortedDataHandler } = this.props;
    if (filteredSortedDataHandler !== undefined && resolvedData !== undefined) {
      if (this.state.filteredSortedDataLength !== resolvedData.length) {
        this.setState({ filteredSortedDataLength: resolvedData.length });
      }
      filteredSortedDataHandler(resolvedData.map(data => data._original)); // eslint-disable-line no-underscore-dangle
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    // If headers is given it overrides columns.
    let { columns } = this.props;
    if (this.props.data.length) {
      // If no specified render function we need to wrap it in a div to get proper margins.
      columns = columns.map((column: Column) => {
        if (!column.Cell) {
          return Object.assign({}, column, {
            Cell: ({ value }: CellProps) => (<div className="o-table__cell">{value}</div>),
          });
        }
        return column;
      });
    } else {
      // No data so hide headers.
      columns = columns.map((column: Column) => Object.assign({}, column, { show: false }));
    }
    let defaultPageSize = 100;
    if (this.props.defaultPageSize) {
      ({ defaultPageSize } = this.props);
    } else if (this.props.showPagination) {
      defaultPageSize = 20;
    }
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll } = this.state;

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
    };

    const CheckboxTable = this.wrapCheckboxTable;

    return (
      <div>
        <CheckboxTable
          ref={(r) => { this.checkboxTable = r; }}
          className={this.props.className || ''}
          loading={this.props.loading}
          data={this.props.data}
          columns={columns}
          defaultFilterMethod={(filter, row) => {
            const filterWords = filter.value.toLowerCase().split(' ');
            return row[filter.id] !== undefined && row[filter.id] !== null ? // For some reason this can get to be null.
              filterWords.every(word => row[filter.id].toLowerCase().indexOf(word) !== -1) : false;
          }}
          defaultSorted={this.props.defaultSorted}
          showPagination={
            this.state.filteredDataLength !== undefined
              ? this.state.filteredDataLength > 5
              : (this.props.showPagination && this.props.data.length &&
              this.props.data.length > 5) ||
                this.props.data.length >= 100
          } // If data size is over 100 always show pagination.
          noDataText={this.props.noDataText}
          minRows={this.props.minRows || 0}
          defaultPageSize={defaultPageSize}
          style={{ minWidth: 400 }}
          PadRowComponent={() => <span className="o-table__cell">&nbsp;</span>}
          selectWidth="40px"
          {...checkboxProps}
          getTrProps={(state, rowInfo) => ({
            onClick: () => (this.props.onClickRow ? this.props.onClickRow(rowInfo) : undefined),
          })}
          onFilteredChange={
            () => {
              const resolvedData = this.checkboxTable && this.checkboxTable.getWrappedInstance &&
              this.checkboxTable.getWrappedInstance().getResolvedState() ?
                this.checkboxTable.getWrappedInstance().getResolvedState().sortedData : undefined;
              this.setState({
                filteredDataLength: resolvedData !== undefined ? resolvedData.length : undefined,
              });
              if (this.props.filteredSortedDataHandler && resolvedData !== undefined &&
                resolvedData.length >= 0) {
                this.returnFilteredSortedData(resolvedData);
              }
            }
          }
          onSortedChange={
            () => {
              const resolvedData = this.checkboxTable && this.checkboxTable.getWrappedInstance &&
              this.checkboxTable.getWrappedInstance().getResolvedState() ?
                this.checkboxTable.getWrappedInstance().getResolvedState().sortedData : undefined;
              if (this.props.filteredSortedDataHandler && resolvedData !== undefined &&
                resolvedData.length >= 0) {
                this.returnFilteredSortedData(resolvedData);
              }
            }
          }
        />
      </div>

    );
  }
}

export default SelectTable;
