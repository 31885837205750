import React from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import { colours } from './../../utils/css';
import MenuButton from '../buttons/menuButton';
import translate from './../../utils/i18n';
import SelectTable from './../table/selectTable';
import { renderDateWithLink, renderWithLink, renderPriceWithLink } from './../../utils/tables';
import { voidClaimInvoices, regenerateClaimInvoices, getAmountPaid, getAmountOutstanding } from './../../utils/claims';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { printClaimInvoices } from './../../utils/print';
import { hasPermission, createPermission } from './../../utils/permissions';
import { getConfirmation } from './../../utils/utils';
import { exportClaimInvoices } from './../../utils/export';
import { sortByPayment } from './../../utils/comparators';
import { trackEvent, CLICK, CLAIM_INVOICES } from './../../utils/analytics';

import type { MenuAction } from '../buttons/menuButton';
import type { SaveModels, Config, User, Column, ClaimInvoiceMetadata, APIResponse } from './../../types';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type DrugModel from './../../models/drugModel';
import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import { debugPrint } from '../../utils/logging';
import ClaimModel from 'src/models/claimModel';

type Row = {
  _id: string,
  invoiceNo: string,
  panelName: string,
  invoiceMonth: string,
  dateGenerated: string,
  amount: string,
  paid: number,
  outstanding: number,
};

type Props = {
  claimInvoices: List<ClaimInvoiceModel>,
  coveragePayors: List<CoveragePayorModel>,
  saveModels: SaveModels,
  saveClaimInvoiceModels: (claimInvoiceModels: Array<ClaimInvoiceMetadata>) => Promise<APIResponse<ClaimInvoiceModel | ClaimModel> | Array<ClaimInvoiceModel | ClaimModel>>,
  voidClaimInvoiceModels: (claimInvoiceModels: Array<string>) => Promise<APIResponse<ClaimInvoiceModel> | ClaimInvoiceModel[]>,
  config: Config,
  patientStubs: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  user: User,
  isLoading: boolean,
  label: string,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
};

type State = {
  selectedRows: Array<string>,
  isProcessingAction: boolean,
};

const IconWrapper = glamorous.div({
  background: colours.red,
  borderRadius: 100,
  height: 20,
  width: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& span': {
    color: 'white',
  },
  ':after': {
    content: '!',
    color: 'white',
    fontWeight: 'bolder',
  },
  ':hover': {
    color: 'transparent',
    background: 'transparent',
    '& span': {
      color: 'white',
    },
  },
}, ({ newTotal }) => ({
  ':hover': {
    ':after': {
      content: newTotal,
      color: 'red',
      marginLeft: 50,
    },
  },
}));


/**
 * Component displaying all Claim Invoices.
 * @class ClaimsInvoicesTable
 * @extends {React.Component}
 */
class ClaimInvoicesTable extends React.Component<Props, State> {
  static defaultProps = {
    label: translate('generated_claim_invoices'),
  };

  /**
   * Creates an instance of ClaimsInvoicesTable.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedRows: [],
      isProcessingAction: false,
    };
  }

  /**
   * Generates the table rows from the SupplyItemModels.
   * @returns {[Row]} The rows of the table
   */
  getRows(): Array<Row> {
    return this.props.claimInvoices
      .map(i => ({
        _id: i.get('_id'),
        invoiceNo: i.get('internal_clinic_id'),
        panelName: i.get('coverage_payor_name'),
        invoiceMonth: i.getInvoiceMonth(),
        dateGenerated: i.getDateGenerated(),
        amount: i.getAmountClaimed(),
        paid: getAmountPaid(i, this.props.claimInvoicePayments),
        outstanding: getAmountOutstanding(i, this.props.claimInvoicePayments),
        link: `/accounts/claim-invoices/${i.get('_id')}`,
        icon: i.hasObsoleteBillData ? <IconWrapper newTotal={i.getNewtotal()} /> : null,
      }))
      .toArray();
  }

  /**
   * Gets the selected claim invoices.
   * @returns {List<ClaimInvoiceModel>}
   */
  getSelectedClaimInvoices(): List<ClaimInvoiceModel> {
    return this.props.claimInvoices.filter(c => this.state.selectedRows.includes(c.get('_id')));
  }

  /**
   * Handles the MenuButton selection
   * @param {string} value The value selected
   * @returns {void}
   */
  handleMenuSelection(value: string) {
    this.setState({ isProcessingAction: true });
    if (value === 'voidSelected') {
      getConfirmation(translate('confirm_void_claim_invoices'))
        .then(
          () => this.props.voidClaimInvoiceModels(
            this.getSelectedClaimInvoices().map(e => e.get('_id')).filter(e => !!e).toArray(),
          )
            .then(savedModels => {
              (savedModels as APIResponse<ClaimInvoiceModel>).unavailable &&
                voidClaimInvoices(
                  this.getSelectedClaimInvoices(),
                  this.props.saveModels,
                  this.props.claimInvoicePayments,
                ) 
              }
            )
            .then(() => {
              createSuccessNotification(translate('claim_invoices_successfully_voided'));
              this.setState({ isProcessingAction: false });
            })
            .catch((error) => {
              debugPrint(error, 'error');
              createErrorNotification(translate('claim_invoices_void_failed'));
              this.setState({ isProcessingAction: false });
            }),
          () => this.setState({ isProcessingAction: false }),
        );
    } else if (value === 'regenerateClaimInvoices') {
      trackEvent(CLAIM_INVOICES, CLICK, 'regenerate_invoices');
      getConfirmation(translate('confirm_regenerate_claim_invoices'))
        .then(
          () => regenerateClaimInvoices(
            this.getSelectedClaimInvoices(),
            this.props.saveClaimInvoiceModels,
            this.props.saveModels,
            this.props.patientStubs,
            this.props.salesItems,
            this.props.drugs,
            this.props.config,
            List(),
          )
            .then(() => {
              createSuccessNotification(translate('claim_invoices_successfully_regenerated'));
              this.setState({ isProcessingAction: false });
            })
            .catch((error) => {
              if (error !== 'TIMEOUT') {
                debugPrint(error, 'error');
                createErrorNotification(translate('claim_invoice_regeneration_failed'));
              }
              this.setState({ isProcessingAction: false });
            }),
          () => this.setState({ isProcessingAction: false }),
        );
    } else if (value === 'printSelected') {
      trackEvent(CLAIM_INVOICES, CLICK, 'print_selected');
      printClaimInvoices(
        this.getSelectedClaimInvoices(),
        this.props.coveragePayors,
        this.props.config,
      )
        .then(() => this.setState({ isProcessingAction: false }));
    } else if (value === 'exportSelected') {
      trackEvent(CLAIM_INVOICES, CLICK, 'export_selected');
      exportClaimInvoices(this.getSelectedClaimInvoices())
        .then(() => this.setState({ isProcessingAction: false }));
    } else {
      this.setState({ isProcessingAction: false });
    }
  }


  /**
   * Gets the menu actions available to the user.
   * @returns {Array<MenuAction>}
   */
  getMenuActions(): Array<MenuAction> {
    const menuActions = [];
    if (hasPermission(this.props.user, List([createPermission('claim_invoice', 'update')]))) {
      menuActions.push({
        label: translate('regenerate_claim_invoices'),
        value: 'regenerateClaimInvoices',
        dataPublic: true,
      });
    }
    menuActions.push({
      label: translate('print_selected'),
      value: 'printSelected',
      dataPublic: true,
    });
    menuActions.push({
      label: translate('export_selected'),
      value: 'exportSelected',
      dataPublic: true,
    });
    if (hasPermission(this.props.user, List([createPermission('claim_invoice', 'delete')]))) {
      menuActions.push({
        label: translate('void_selected'),
        value: 'voidSelected',
        isDanger: true,
        dataPublic: true,
      });
    }
    return menuActions;
  }

  /**
   * Gets the columns headers.
   * @returns {Array<Column>}
   */
  getColumns(): Array<Column> {
    return [
      { accessor: 'invoiceNo', Header: translate('invoice_no'), Cell: renderWithLink },
      { accessor: 'panelName', Header: translate('panel_name'), Cell: renderWithLink },
      { accessor: 'invoiceMonth', Header: translate('invoice_month'), Cell: renderWithLink },
      { accessor: 'dateGenerated', Header: translate('date_generated'), Cell: renderDateWithLink },
      { accessor: 'amount', Header: translate('amount'), Cell: renderWithLink, sortMethod: sortByPayment },
      { accessor: 'paid', Header: translate('paid'), Cell: renderPriceWithLink },
      { accessor: 'outstanding', Header: translate('outstanding'), Cell: renderPriceWithLink },
      { accessor: 'icon', Header: '', Cell: renderWithLink, width: 150 },
    ];
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div>
        <div className="o-card__title o-table-header-button">
          <h2 data-public>{this.props.label}</h2>
          <MenuButton
            label={translate('actions')}
            className="u-flex-right"
            items={this.getMenuActions()}
            disabled={this.state.isProcessingAction || this.state.selectedRows.length === 0}
            onValueSelected={value => this.handleMenuSelection(value)}
            dataPublic
          />
        </div>
        <SelectTable
          columns={this.getColumns()}
          loading={this.props.isLoading}
          data={this.getRows()}
          noDataText={translate('no_generated_claim_invoices')}
          showPagination
          defaultSorted={[{ id: 'invoice_no', desc: false }]}
          getSelectedRows={selectedRows => this.setState({ selectedRows })}
        />
      </div>
    );
  }
}

export default ClaimInvoicesTable;
