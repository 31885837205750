import React from 'react';
import glamorous from 'glamorous';
import { colours, border, borderRadius } from './../../utils/css';
import { UNICODE } from './../../constants';

import type { MapValue } from './../../types';

type Payloads = Array<{
  [key: string]: MapValue,
}>;

type LineChartValue = {
  [key: string]: MapValue,
};

export type Props = {
  active: boolean,
  data: Array<LineChartValue>,
  label: string,
  payload: Payloads,
  xAxisKey: string,
};

const TooltipContainer = glamorous.div({
  padding: '10px',
  backgroundColor: colours.white,
  border,
  borderRadius,
  whiteSpace: 'nowrap',
});

/**
 * A component that displays line chart tooltip by using given data.
 * @class LineChartTooltip
 * @extends {React.PureComponent<Props>}
 */
class LineChartTooltip extends React.PureComponent<Props> {
  /**
   * Returns the data corresponding to current tooltip, from array of data passed to chart component
   * using the payload of current tooltip to compare and extract data.Uses the formatted data from extracted,
   * to display the tooltip.
   * @param {Array<LineChartValue>} lineData Data corresponding to all graph node passed to LineChart
   * @param {Payloads} payloads Array of payload for current graph node. array length will be equal to number of lines.
   * @param {string} label this is the value of x axis for the corresponding graph node.
   * @param {string} xAxisKey this is the key to be used to extract x axis values from data array.
   * @returns {LineChartValue | void} individual data from array of data, corresponding to the graph node or undefined if not found.
   */
  extractToolTipData(lineData: Array<LineChartValue>, payloads: Payloads, label: string,
    xAxisKey: string): LineChartValue | void {
    if (payloads.length > 1) {
      // take the first two values of metric type and extract tooltip data
      return lineData.find(dt => dt[xAxisKey] === label
        && dt[payloads[0].name] === payloads[0].value
        && dt[payloads[1].name] === payloads[1].value);
    }
    return lineData.find(dt => dt[xAxisKey] === label
      && dt[payloads[0].name] === payloads[0].value);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { active } = this.props;
    if (active) {
      const { payload, data, label, xAxisKey } = this.props;
      const toolTipData = this.extractToolTipData(data, payload, label, xAxisKey);
      const tooltipLabel = toolTipData ? toolTipData.formattedValue : UNICODE.EMDASH;
      return (
        <TooltipContainer>
          <p className="label">{tooltipLabel}</p>
          <p className="desc">{label}</p>
        </TooltipContainer>
      );
    }
    return null;
  }
}
export default LineChartTooltip;
