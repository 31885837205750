import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'payment',
  patient_id: string,
  bill_id: string,
  is_finalised: boolean,
  timestamp: number,
  amount: number,
  method: 'cash' | 'card' | 'transfer' | 'cheque',
  payor_type: 'patient' | 'coverage_payor',
  claim_id?: string,
  receivable_id?: string,
  individual_payor_id?: string,
  claim_reconciliation_id?: string,
  internal_clinic_id?: string,
  notes: string,
  is_void: boolean,
};

/**
   * PaymentModel
   * @namespace PaymentModel
   */
class PaymentModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'payment';
  }

  /**
   * Gets the date of the payment (not exactly the same as created_by or edited_by).
   * @returns {number}
   */
  getDate(): number {
    return this.has('timestamp') ? this.get('timestamp') : this.getLastUpdateTime();
  }

  /**
   * Returns true if payment method was by card.
   * @returns {boolean}
   */
  isCard(): boolean {
    return this.get('method', '').trim().toLowerCase() === 'card' || this.get('method', '').trim().toLowerCase() === 'credit'; // Credit is legacy method.
  }

  /**
   * Returns true if payment method was by cash.
   * @returns {boolean}
   */
  isCash(): boolean {
    return this.get('method', '').trim().toLowerCase() === 'cash';
  }

  /**
   * Returns true if payment method was by neither cash nor card.
   * @returns {boolean}
   */
  isOther(): boolean {
    return !this.isCard() && !this.isCash();
  }

  /**
   * Returns true if payment has been voided.
   * @returns {boolean}
   */
  isVoid(): boolean {
    return this.get('is_void', false);
  }

  /**
   * Returns true if the data in attributes is valid
   * @returns {boolean}
   */
  isValid(): boolean {
    if (this.get('method', '').length === 0) {
      return false;
    }
    if (!this.has('amount') || this.get('amount') === '' || this.get('amount') <= 0 || isNaN(parseFloat(this.get('amount')))) {
      return false;
    }
    return true;
  }
}

export default PaymentModel;
