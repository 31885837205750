import * as React from 'react';

import SidebarTextLinkWrapper from './sidebarTextLinkWrapper';
import { trackEvent, CLICK, SIDEBAR_ITEM } from './../../utils/analytics';

type Props = {
  label: string,
  isToggled: boolean,
  onClick?: () => void,
  children: React.Node,
  analyticsLabel?: string,
}

/**
 * A toggle sidebar component.
 * @class SidebarToggleItem
 * @extends {React.Component<Props, State>}
 */
class SidebarToggleItem extends React.PureComponent<Props> {
  props: Props;

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      isToggled, label, onClick, children, analyticsLabel,
    } = this.props;
    let className = 'c-sidebar__item';
    if (isToggled) {
      className += ' c-sidebar__item--selected';
    }
    return (
      <div
        className={className}
        onClick={() => {
          trackEvent(SIDEBAR_ITEM, CLICK, analyticsLabel || label);
          if (onClick) {
            onClick();
          }
        }}
        style={{ marginTop: 'auto' }}
        data-public
      >
        <div className="c-sidebar__item__main">
          <div className="c-sidebar__item__main__icon">
            <img src="static/images/icon_sidebar_user.png" alt={label} />
          </div>
          <div className="c-sidebar__item__main__text">{label}</div>
        </div>
        {
          children &&
          isToggled &&
          <SidebarTextLinkWrapper>{children}</SidebarTextLinkWrapper>
        }
      </div>
    );
  }
}

export default SidebarToggleItem;
