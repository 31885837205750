import { cancelChangesFeeds, getRemoteDB } from './db';
import { setUserForLogging } from './logging';
import { generateEncryptionKey, generateSalt, generateIV, clearEncryptionKey } from './crypto';
import { getBaseApiUrl, handleResponseError, handleUnauthorisedApiResponse } from './utils';
import fetch from './fetch';
import { getStore } from './redux';
import translate from './i18n';
import { setAppStartupComplete, setAuthStatus, setUserID, setAuthError, setIV, encryptModelState, setEncrypted, setWorkspace } from '../actions';

import type { User, ErrorResponse } from './../types';

/**
 * Returns a encrypt key from sessionStorage
 * @returns {string} encrypt key
 */
export const getEncryptKey = () => sessionStorage.getItem('encrypt_key');

/**
 * Returns a encrypt salt from localStorage
 * @param {userID} userID user name
 * @returns {string} encrypt salt
 */
export const getEncryptSalt = (userID: String) => localStorage.getItem(`${userID}_encrypt_salt`);


/**
 * Returns true if the current user is a member of Klinify.
 * @param {User} user Current user
 * @returns {boolean}
 */
export function isKlinifyUser(user: User): boolean {
  return user && user.get('id', '').startsWith('klinify');
}

/**
 * Returns true if the current user can export data.
 * Note: `klinify_user_export` user is treated differently. The can user will only have access to data export page.
 * @param {User} user Current user
 * @returns {boolean}
 */
export function isExportUser(user: User): boolean {
  return user && user.get('id', '') === 'klinify_user_export';
}

/**
 * Returns username if user logged in, null if not.
 * @returns {Promise} username if user logged in, null if not.
 */
export function getUserLoggedIn(): Promise<string> {
  if (getRemoteDB()) {
    return new Promise((resolve) => {
      getRemoteDB().getSession((err, response) => {
        resolve((!err && response.userCtx
          && response.userCtx.name !== undefined &&
          response.userCtx.name !== null) ? response.userCtx.name : null);
      });
    });
  }
  return Promise.resolve(null);
}

/**
 * Clear up misc things on logout
 * @param {User} user user
 * @returns {undefined}
 */
function onLogoutCleanup(user: User) {
  // clear the pharmaconnect modal
  window.sessionStorage.removeItem('pharmaconnect-intro-modal-seen');
  window.localStorage.removeItem('medadvisor-agreement-intro-modal-seen');
  window.localStorage.removeItem(`medadvisor-agreement-${user.get('id')}-intro-modal-seen`);
}

/**
 * Logs the user out of the app. If wasTimeout is true then the store is updated to show that the
 * user was logged out due to a DB timeout.
 * @param {boolean} wasTimeout True if the the user was logged out due to timeout.
 * @param {string} error Error to show in the login container
 * @returns {undefined}
 */
function logoutFromApp(wasTimeout: boolean = false, error?: string) {
  const reduxStore = getStore();
  const { encryptionOptions, user } = reduxStore.getState();
  const iv = generateIV();
  const key = getEncryptKey();
  reduxStore.dispatch(setAppStartupComplete(false));
  reduxStore.dispatch(setAuthStatus(0));
  reduxStore.dispatch(setUserID());
  reduxStore.dispatch(setWorkspace('')); 
  const errorMessage = wasTimeout ? translate('auth_timeout_message') : error;
  reduxStore.dispatch(setAuthError(errorMessage || null));
  if (key && !encryptionOptions.encrypted) {
    reduxStore.dispatch(setIV(iv));
    reduxStore.dispatch(encryptModelState(key, iv, user.get('id', 'unknownUser')));
    reduxStore.dispatch(setEncrypted(true));
    clearEncryptionKey();
  }
  cancelChangesFeeds();
  onLogoutCleanup(user);
}

/**
 * Logout the current user.
 * @param {boolean} wasTimeout True if user was timed out from DB.
 * @param {string} error Error to show in the login container
 * @return {undefined}
 */
export function logout(wasTimeout: boolean = false, error?: string) {
  fetch(new Request(`${getBaseApiUrl()}/clear`), {
    method: 'GET',
    credentials: 'same-origin',
  });
  logoutFromApp(wasTimeout, error);
  setUserForLogging();
  if (window.fcWidget) {
    window.fcWidget.destroy();
    if (window.fcWidget.user) {
      window.fcWidget.user.clear();
    }
  }
}

/**
 * Attempts auth for the user with the DB and runs the callback function. If login is successful
 * the user state is updated and the onLogin function run.
 * @param {string} baseAPIURL: The base API url (no trailing slash needed).
 * @param {string} username The given username.
 * @param {string} password The given password.
 * @param {function} callback A callback function to be run regardless of the success of the auth
 * request.
 * @returns {undefined}
 */
export function login(
  baseAPIURL: string,
  username: string,
  password: string,
  workspace: string
): Promise<Response> {
  return fetch(new Request(`${baseAPIURL}/startsession`), {
    headers: { 'Content-type': 'application/json' },
    body: JSON.stringify({ name: username, password, workspace: workspace}),
    method: 'POST',
    credentials: 'same-origin',
  }).then((res) => {
    if (res.ok && res.status === 200) {
      setUserForLogging(username);
      const salt = getEncryptSalt(username);
      const newSalt = salt || generateSalt();
      const key = generateEncryptionKey(password, newSalt);
      if (typeof (window) !== 'undefined' && window.onLogin) {
        window.onLogin(key, newSalt);
      }
    }
    return res;
  });
}
