import React from 'react';

import Label from './label';

import type { HTMLStyle } from './../../types';

type Props = {
  className?: string,
  disabled?: boolean,
  hideLabel?: boolean,
  label: string,
  labelClassName: string,
  containerStyle: HTMLStyle,
  id: string,
  description?: string,
  required?: boolean,
  onValueChanged: (value: string) => void,
  options: Array<{ value: string, label: string }>,
  value?: string | number | null,
  style: HTMLStyle,
  radioClassName?: string,
  optionStyle?: HTMLStyle,
}


/**
 * Returns a Radio component.
 * @param {Object} props The props for the component.
 * @returns {React.Component} Returns a Radio component.
 */
class Radio extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    className: '',
    labelClassName: '',
    style: {},
    containerStyle: {},
    radioClassName: '',
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    // TODO: THe key below is generated randomly to ensure uniqueness, but this means lots of rerendering too.
    // Solution would be to ensure unique keys (see add mc buttons on consunlt history page for source of issues.)
    const {
      label, id, description, onValueChanged, options, value, disabled, required,
      labelClassName, containerStyle, style,
    } = this.props;
    const className = this.props.className ? `o-form__item ${this.props.className}` : 'o-form__item o-form__item--full-width';
    const radioClassName = required && (!value || value.length === 0) ? 'o-radio o-radio--invalid' : 'o-radio';
    return (
      <div>
        <fieldset className={className} style={style}>
          {
            !this.props.hideLabel &&
            <Label
              id={id}
              label={label}
              className={labelClassName}
              invalid={required && (value === undefined || (typeof (value) === 'string' && value.length === 0))}
            />
          }
          { description && <p className="o-sub-label">{description}</p>}
          <div className="o-radio-container" style={containerStyle}>
            {
              options
                .map(option =>
                  (Object.assign({}, option, { valueString: option.value.toString() }))) // This is to coerce booleans to strings
                .map(option =>
                  <div
                    className={`${radioClassName} ${this.props.radioClassName}`}
                    key={`option_${id}_${option.valueString}`}
                    style={this.props.optionStyle}>
                    <label
                      htmlFor={`${id}_${option.valueString}`}
                      className={labelClassName}
                      style={disabled ? { cursor: 'not-allowed' } : {}}
                    >
                      {option.label}
                    </label>
                    <input
                      checked={value === option.value}
                      id={`${id}_${option.valueString}`}
                      name={`${id}_${option.valueString}`}
                      type="radio"
                      value={option.value}
                      onChange={event => onValueChanged(event.target.value)}
                      disabled={disabled}
                    />
                  </div>)
            }
          </div>
        </fieldset>
      </div>
    );
  }
}

export default Radio;
