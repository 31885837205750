import { Map, List, is } from 'immutable';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import SearchApi from 'js-worker-search';
import reportsReducer from './reportsReducer';
import modelReducers from './modelReducers';
import DefaultConfig from './../config/defaultConfig';
import DefaultKlinifyConfig from './../config/defaultKlinifyConfig';
import { mergeWithoutLists, mapFromJS, getJsSearchInstance } from './../utils/utils';
import { SkuStock } from './../utils/inventory';

import type BaseModel from './../models/baseModel';
import type PatientStubModel from './../models/patientStubModel';
import type { Config, DataView, CountPerSKUAndBatch, InventoryCountSyncStatus, User, MapValue, MissingDocObject, DebugModeData, AppointmentsFilter } from './../types';
import type { Action } from './../actions';
import type APIError from '../utils/apiError';
import AppointmentModel from '../models/appointmentModel';
import type DrugManufacturerModel from '../models/drugManufacturerModel';
import type PatientCampaignSetModel from '../models/patientCampaignSetModel';
import { APP_MODE } from '../constants';
import moment from 'moment';


/**
 * The current appointment filters. Kept here to allow the user to leave and return to the filters.
 * @param {Object} state The current appointment filters.
 * @param {Action} action The current action.
 * @returns {string}
 */
const appointmentsFilter = (state = null, action: Action) => {
  switch (action.type) {
    case 'SET_APPOINTMENT_FILTERS':
      return action.state;
    default:
      return state;
  }
};

/**
   * Reducer for Config. Inherits initially from LocalConfig. ADD_CONFIG just receives a new config
   * state to be used.
   * @param {Immutable.Set} state - Either the current state of the reducer or a default.
   * @param {Object} action - The action object. Should have values for `type` and `config`.
   * @return {Immutable.Set} - The state after the actions have been computed.
   */
const config = (state: Config = mapFromJS(DefaultConfig), action: Action): Config => {
  switch (action.type) {
    case 'UPDATE_CONFIG':
      return state.mergeWith(mergeWithoutLists, action.config);
    case 'UPDATE_CONFIG_VALUE':
      return state.setIn(action.keys, action.value);
    default:
      return state;
  }
};

/**
   * Reducer for klinify Config. Inherits initially from LocalConfig. ADD_CONFIG just receives a new klinify config
   * state to be used.
   * @param {Immutable.Set} state - Either the current state of the reducer or a default.
   * @param {Object} action - The action object. Should have values for `type` and `config`.
   * @return {Immutable.Set} - The state after the actions have been computed.
   */
const klinifyConfig = (state: Config = mapFromJS(DefaultKlinifyConfig), action: Action): Config => {
  switch (action.type) {
    case 'UPDATE_KLINIFY_CONFIG':
      return state.mergeWith(mergeWithoutLists, action.config);
    case 'UPDATE_KLINIFY_CONFIG_VALUE':
      return state.setIn(action.keys, action.value);
    default:
      return state;
  }
};

/**
 * Reducer for isConfigFetched
 * @param {boolean} state - True if config config is fetched
 * @param {Object} action - The action object. Should have values for `type` and `config`.
 * @return {Immutable.Set} - The state after the actions have been computed.
 */
const isConfigFetched = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_CONFIG_FETCHED':
      return action.isFetched;
    default:
      return state;
  }
};

/**
 * Currently selected dataviews
 * @param {List<DataView>} state A list of Dataviews
 * @param {Action} action The current action.
 * @returns {List<DataView>}
 */
const currentDataViews = (state: List<DataView> = List(), action: Action): List<DataView> => {
  switch (action.type) {
    case 'SET_CURRENT_DATA_VIEWS':
      return action.state;
    default:
      return state;
  }
};

/**
 * Models from the selected dataviews
 * @param {List<BaseModel>} state A list of models.
 * @param {Action} action The current action.
 * @returns {List<BaseModel>}
 */
const currentDataViewsModels =
  (state: List<BaseModel> = List(), action: Action): List<BaseModel> => {
    switch (action.type) {
      case 'SET_CURRENT_DATA_VIEWS_MODELS':
        return action.state;
      case 'UPDATE_CURRENT_DATA_VIEWS_MODEL': {
        const modelID = action.model.get('_id'); // Flow was getting confused when this is called directly in the below filter, so we're justing hoisting it up.
        let newState = state.filter(model => model.get('_id') !== modelID); // Dump old version of new model.
        // Add new model unless the change specifies it was deleted.
        if (!action.model.attributes._deleted) {
          newState = newState.push(action.model);
        }
        return newState;
      }
      case 'CLEAR_CURRENT_DATA_VIEWS_MODELS': {
        const ids = List(action.models).groupBy(m => m.get('_id'));
        return List.isList(state) ? state
          .filter(model => model && !ids.has(model.get('_id'))) : List();
      }
      case 'REPLACE_CURRENT_DATA_VIEWS_MODEL': {
        const modelID = action.prevModel.get('_id'); // Flow was getting confused when this is called directly in the below filter, so we're justing hoisting it up.
        let newState = state.filter(model => model.get('_id') !== modelID); // Dump old version of new model.
        // Add new model unless the change specifies it was deleted.
        if (!action.prevModel.attributes._deleted) {
          newState = newState.push(action.newModel);
        }
        return newState;
      }
      default:
        return state;
    }
  };


/**
 * Error for the selected data view
 * @param {?APIError} state APIError or null (if error needs to be reset)
 * @param {Action} action The current action.
 * @returns {?APIError}
 */
const currentDataViewsError =
(state: APIError | null | undefined = null, action: Action): APIError | null | undefined => {
  switch (action.type) {
    case 'SET_CURRENT_DATA_VIEWS_ERROR':
      return action.response;
    default:
      return state;
  }
};

/**
 * Models from the master drug API
 * @param {Map<string, BaseModel>} state A Map of models.
 * @param {Action} action The current action.
 * @returns {Map<string, BaseModel>}
 */
const masterDrugModelsMap =
(state: Map<string, BaseModel> = Map(), action: Action): Map<string, BaseModel> => {
  switch (action.type) {
    case 'SET_MASTER_DRUG_MODELS': {
      return action.models.reduce((acc, item) => {
        if (!state.has(item.get('_id'))) {
          return acc.set(item.get('_id'), item);
        }
        return acc;
      }, state);
    }
    default:
      return state;
  }
};

/**
 * Map of Prescribed drugs.
 * @param {Map<string, BaseModel>} state A map of prescribedDrugModels.
 * @param {Action} action The current action.
 * @returns {Map<string, BaseModel>}
 */
const prescribedDrugModelsMap =
(state: Map<string, BaseModel> = Map(), action: Action): Map<string, BaseModel> => {
  switch (action.type) {
    case 'SET_PRESCRIBED_DRUG_MODELS': {
      return action.models.reduce((acc, item) => {
        if (!state.has(item.get('drug_id'))) {
          return acc.set(item.get('drug_id'), item);
        }
        return acc;
      }, state);
    }
    default:
      return state;
  }
};

/**
 * The current search query, this update is a workaround to reset the query when user click on the
 * clinic overview page as component will not rerender i.e., clear the previous query on click of clinic overview
 * page by being on clinic overview page.
 * @param {string} state The current search query.
 * @param {Action} action The current action.
 * @returns {string}
 */
const patientSearchQuery = (state: string = '', action: Action): string => (action.type === 'SET_PATIENT_SEARCH_QUERY' ? action.query : state);

/**
 * The current patient list filters. Kept here to allow the user to leave and return to the filters.
 * @param {Object} state The current patient list filters.
 * @param {Action} action The current action.
 * @returns {string}
 */
const patientListFilters = (state = {}, action: Action) => {
  switch (action.type) {
    case 'SET_PATIENT_LIST_FILTERS':
      return action.state;
    default:
      return state;
  }
};

/**
 * All patient stubs in database. These are reduced versions of the patient model that can be used
 * for searching.
 * @param {Search} state A list of PatientStubModels.
 * @param {Action} action The current action.
 * @returns {SearchApi}
 */
const patientsSearch =
  (state: SearchApi = getJsSearchInstance(), action: Action): SearchApi => {
    switch (action.type) {
      case 'UPDATE_PATIENTS_SEARCH': {
        // Note: deep cloining of search instance was avoided purposely.
        // For large data, deep clone ca cause memory issue and we don't search
        // instance to get created everytime, as it was taking more than 11 sec and breaking
        // the app
        // state.addDocuments(action.patientStubAttributes);
        return action.patientStubAttributes &&
        Array.isArray(action.patientStubAttributes) &&
        action.patientStubAttributes.length > 0 ?
          action.patientStubAttributes.reduce((modifiedState: any, patientStub) => {
            const patientId = patientStub._id;
            const patientName = patientStub.patient_name;
            const caseId = patientStub.case_id;
            const patientIc = patientStub.ic;
            const patientTel = patientStub.tel;
            if (patientId) {
              if (patientName) {
                modifiedState.indexDocument(patientId, patientName);
              }
              if (caseId) {
                modifiedState.indexDocument(patientId, caseId);
              }
              if (patientIc) {
                modifiedState.indexDocument(patientId, patientIc);
              }
              if (patientTel) {
                modifiedState.indexDocument(patientId, patientTel);
              }
            }
            return modifiedState;
          }, state) : state;
      }
      default:
        return state;
    }
  };

/**
 * All patient stubs in database. These are reduced versions of the patient model that can be used
 * for searching.
 * @param {List<PatientStubModel>} state A list of PatientStubModels.
 * @param {Action} action The current action.
 * @returns {List<PatientStubModel>}
 */
const patientStubs =
  (state: List<PatientStubModel> = List(), action: Action): List<PatientStubModel> => {
    switch (action.type) {
      case 'SET_PATIENT_STUBS':
        return action.state;
      case 'UPDATE_PATIENT_STUB': {
        const modelID = action.patientStub.get('_id'); // Flow was getting confused when this is called directly in the below filter, so we're justing hoisting it up.
        let newState = state.filter(model => model.get('_id') !== modelID); // Dump old version of new model.
        // Add new model unless the change specifies it was deleted.
        if (!action.patientStub.attributes._deleted) {
          newState = newState.push(action.patientStub);
        }
        return newState;
      }
      default:
        return state;
    }
  };

/**
 * A Map with key of patientID and value as a List of casenoteIDs for casenotes that have been
 * viewed for that patient in the current session.
 * @param {Map<string, List<string>>} state The current state of recently viewed casenotes.
 * @param {Action} action The current action.
 * @returns {Map<string, List<string>>}
 */
const recentlyViewedCasenotes = (state = Map(), action: Action) => {
  switch (action.type) {
    case 'ADD_RECENTLY_VIEWED_CASENOTE': {
      const { patientID, casenoteID } = action;
      const newList = state.get(patientID, List())
        .filter((i: string) => i !== casenoteID)
        .push(casenoteID);
      return state.set(patientID, newList);
    }
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_USER_ID' then state is changed to action.userID,
 * otherwise state remains the same.
 * @param {Immutable.Map} state The current state of user.
 * @param {Object} action The current action.
 * @returns {Immutable.Map} The state after the actions have been computed.
 */
const user = (state: User = Map(), action: Action) => {
  switch (action.type) {
    case 'SET_USER_ID':
      return state.set('id', action.userID);
    case 'SET_USER_MODEL':
      return state.set('model', action.model);
    case 'SET_USER_GROUP_MODEL':
      return state.set('userGroup', action.model);
    default:
      return state;
  }
};

/**
 * If action.type is 'SHOW_APPOINTMENT_REQUESTED' then set the modal to the state,
 * otherwise state remains the same.
 * @param {AppointmentModel | void} state The current state of user.
 * @param {Object} action The current action.
 * @returns {AppointmentModel | void} The state after the actions have been computed.
 */
const appointmentRequested = (state: AppointmentModel | null = null, action: Action) => {
  switch (action.type) {
    case 'SHOW_APPOINTMENT_REQUESTED':
      return action.model;
    default:
      return state;
  }
};

/**
 * If action.type is 'SHOW_APPOINTMENT_REQUEST_NOTIFICATION_ICON' then set the modal to the state,
 * otherwise state remains the same.
 * @param { boolean } state The current state of user.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const isAppointmentRequestNotificationIconVisible = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case 'SHOW_APPOINTMENT_REQUEST_NOTIFICATION_ICON':
      return action.state;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_WORKSPACe' then set the modal to the state,
 * otherwise state remains the same.
 * @param { string } state The current workspace
 * @param {Object} action The current action.
 * @returns {string} The state after the actions have been computed.
 */
const workspace = (state: string = window.localStorage.getItem('workspace') || '', action: Action) => {
  switch (action.type) {
    case 'SET_WORKSPACE':
      return action.workspace;
    default:
      return state;
  }
};


/**
 * If action.type is 'SET_APP_STARTUP_COMPLETE' then state is changed to action.appStartupComplete,
 * otherwise state remains the same.
 * @param {boolean} state The current state of appStartupComplete.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const appStartupComplete = (state = false, action: Action) => {
  switch (action.type) {
    case 'SET_APP_STARTUP_COMPLETE':
      return action.appStartupComplete;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_AUTH_STATUS' then state is changed to action.hasAuth, otherwise
 * state remains the same.
 * @param {number} state Current auth state -1 if fecting 0 if not authorized else 1 if authorized.
 * @param {Object} action The current action.
 * @returns {number} The state after the actions have been computed.
 */
const hasAuth = (state: number = -1, action: Action): number => {
  switch (action.type) {
    case 'SET_AUTH_STATUS':
      return action.hasAuth;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_AUTH_ERROR' then state is changed to action.hasAuth, otherwise
 * state remains the same.
 * @param {boolean} state The current state of hasAuth.
 * @param {Object} action The current action.
 * @returns {string} The state after the actions have been computed.
 */
const authError = (state: string | null = null, action: Action): string | null => {
  switch (action.type) {
    case 'SET_AUTH_ERROR':
      return action.payload;
    default:
      return state;
  }
};

/**
 * The Inventory count for each SKU in the database.
 * @param {InventoryCount} state A map with sku ID as key and the count as the value.
 * @param {Action} action The current action.
 * @returns {CountPerSKUAndBatch}
 */
const inventoryCount = (
  state: CountPerSKUAndBatch = Map(),
  action: Action,
): CountPerSKUAndBatch => {
  switch (action.type) {
    case 'SET_INVENTORY_COUNT':
      return action.state.reduce((newState, count, skuId) => newState
        .update(skuId, SkuStock(), val => val.set('skuStockRemaining', count)), state);
    case 'UPDATE_INVENTORY_ITEM_COUNT':
      // Update the count of a single inventory item by adding the stock change.
      return state.update(
        action.skuID,
        SkuStock(),
        skuVal => skuVal
          .set('skuStockRemaining', state.getIn([action.skuID, 'skuStockRemaining'], 0) + action.change),
      );
    case 'RESET_ALL_INVENTORY_BATCH_INDEX':
      return state.map(skuVal => skuVal.update(
        'batches',
        (batches: any[]) => batches.map((batch: { set: (arg0: string, arg1: null) => any; }) => batch.set('index', null)),
      ));
    case 'SET_INVENTORY_BATCH_COUNT':
      return action.state.reduce((newState, batches, skuId) => newState.update(
        skuId,
        SkuStock(),
        skuVal => skuVal
          .update('batches', (batchVal: { mergeWith: (arg0: (currentBatch: any, newBatch: any) => any, arg1: any) => any; }) => batchVal.mergeWith((currentBatch: { get: (arg0: string) => null; }, newBatch: { get: (arg0: string) => null; set: (arg0: string, arg1: any) => any; }) => {
            if (newBatch.get('index') === null && currentBatch.get('index') !== null) {
              return newBatch.set('index', currentBatch.get('index'));
            }
            return newBatch;
          }, batches.get('batches')))
          .set('batchCount', batches.get('batchCount')),
      ), state);
    default:
      return state;
  }
};

/**
 * The Inventory count for each SKU in the database.
 * @param {InventoryCount} state A map with sku ID as key and the count as the value.
 * @param {Action} action The current action.
 * @returns {InventoryCount}
 */
const inventoryCountSyncStatus = (
  state: InventoryCountSyncStatus = List(),
  action: Action,
): InventoryCountSyncStatus => {
  switch (action.type) {
    case 'SET_INVENTORY_COUNT_STATUS':
      return !state.includes(action.status) ? state.push(action.status) : state;
    case 'RESET_INVENTORY_COUNT_STATUS':
      return List();
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_IS_FETCHING' then state is changed to action.isFetching, otherwise
 * state remains the same.
 * @param {boolean} state The current state of isFetching.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const isFetching = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_IS_FETCHING':
      return action.state;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_IS_SKU_COUNT_SYNCING' then state is changed to action.isSKUCountSyncing, otherwise
 * state remains the same.
 * @param {boolean} state The current state of isSKUCountSyncing.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const isSKUCountSyncing = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_IS_SKU_COUNT_SYNCING':
      return action.state;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_CONNECTION_STATUS' then state is changed to action.isOnline, otherwise
 * state remains the same.
 * @param {boolean} state The current state of isOnline.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const isOnline = (state: boolean = true, action: Action): boolean => {
  switch (action.type) {
    case 'SET_CONNECTION_STATUS':
      return action.isOnline;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_OFFLINE_PROMPT_VISIBLE' then state is changed to action.isOnline, otherwise
 * state remains the same.
 * @param {boolean} state The current state of isOnline.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const showOfflinePrompt = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_OFFLINE_PROMPT_VISIBLE':
      return action.showOfflinePrompt;
    default:
      return state;
  }
};

/**
 * If action.type is 'SET_CONNECTION_STATUS' then state is changed to action.serverOffline, otherwise
 * state remains the same.
 * @param {boolean} state The current state of isOnline.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const serverOffline = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_CONNECTION_STATUS':
      return action.serverOffline === undefined ? state : action.serverOffline;
    default:
      return state;
  }
};

/**
 * The last received DB sequence. Defaults to null.
 * @param {(number | null)} state Last received sequence or nul.
 * @param {Action} action The action to take.
 * @returns {(number | null)}
 */
const lastReceivedDBSequence = (state: number | null = null, action: Action): number | null => {
  switch (action.type) {
    case 'SET_LAST_RECEIVED_DB_SEQUENCE':
      return action.state;
    default:
      return state;
  }
};

/**
 * If action.type is 'UPDATE_UNSYNCED_MODELS' then state is updated,
 * otherwise state remains the same.
 * @param {int} state The current state of unsyncedModels.
 * @param {Object} action The current action.
 * @returns {boolean} The state after the actions have been computed.
 */
const unsyncedAPICalls = (state: Map = Map(), action: Action) => {
  switch (action.type) {
    // @ts-ignore
    case 'SET_UNSYNCED_API_CALLS':
      return state.merge(action.calls);
    case 'UPDATE_UNSYNCED_API_CALLS': {
      return action.calls.reduce((_state, call, requestId) => (
        _state.update(requestId, (val: any) => (val ? { ...val, ...call } : val))
      ), state);
    }
    case 'DELETE_UNSYNCED_API_CALLS': {
      return state.deleteAll(action.requestIds);
    }
    default:
      return state;
  }
};


/**
 * Signifies if the app is syncing or not.
 * @param {boolean} state The current state of isSyncing.
 * @param {Object} action The current action.
 * @returns {boolean}
 */
const isSyncing = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_IS_SYNCING':
      return action.isSyncing;
    default:
      return state;
  }
};

/**
 * Signifies if current user is admin or not.
 * @param {boolean} state The current state of isAdmin.
 * @param {Object} action The current action.
 * @returns {boolean}
 */
const isAdminUser = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case 'SET_IS_ADMIN_USER':
      return action.isAdmin;
    default:
      return state;
  }
};

/**
 * Signifies if current user is admin or not.
 * @param {boolean} state The current state of isAdmin.
 * @param {Object} action The current action.
 * @returns {MapValue}
 */
const encryptionOptions = (state: MapValue = {
  encrypted: false,
  iv: null,
}, action: Action) => {
  switch (action.type) {
    case 'SET_ENCRYPTED':
      return { ...state, encrypted: action.encrypted };
    case 'SET_IV':
      return { ...state, iv: action.iv };
    default:
      return state;
  }
};

/**
 * List of verified drugs
 * @param {List<BaseModel>} state The current state of verified drug list
 * @param {Action} action dispatched action
 * @returns {Map<string, BaseModel>}
 */
const verifiedDrugs = (state: Map<string, BaseModel> = Map(), action: Action) => {
  switch (action.type) {
    case 'SET_VERIFIED_DRUG_MODELS':
      return action.models.reduce((acc, item) => {
        if (!state.has(item.get('drug_id'))) {
          return acc.set(item.get('drug_id'), item);
        }
        return acc;
      }, state);
    case 'DELETE_VERIFIED_DRUG_MODEL':
      if (state.has(action.modelID)) {
        return state.delete(action.modelID);
      }
      return state;
    case 'UPDATE_VERIFIED_DRUG_MODEL':
      if (action.model) {
        return state.set(action.model.get('drug_id'), action.model);
      }
      return state;
    default:
      return state;
  }
};

/**
 * List of drug suggestions.
 * @param {List<BaseModel>} state The current state drug suggestion list
 * @param {Action} action dispatched action
 * @returns {Map<string, BaseModel>}
 */
const drugSuggestions = (state: Map<string, BaseModel> = Map(), action: Action) => {
  switch (action.type) {
    case 'SET_DRUG_SUGGESTION_MODELS':
      return action.models.reduce((acc, item) => {
        if (!state.has(item.get('drug_id'))) {
          return acc.set(item.get('drug_id'), item);
        }
        return acc;
      }, state);
    case 'DELETE_DRUG_SUGGESTION': {
      if (state.has(action.modelID)) {
        return state.delete(action.modelID);
      }
      return state;
    }
    case 'UPDATE_DRUG_SUGGESTION':
      return state.set(action.model.get('drug_id'), action.model);
    default:
      return state;
  }
};

/**
 * List of drug suggestions.
 * @param {List<BaseModel>} state The current state drug suggestion list
 * @param {Action} action dispatched action
 * @returns {List<DrugManufacturerModel>}
 */
const drugManufacturers = (state: List<DrugManufacturerModel> = List(), action: Action) => {
  switch (action.type) {
    case 'SET_DRUG_MANUFACTURER_MODELS':
      return action.models;
    default:
      return state;
  }
};

/**
 * View models, daterange for analytics report
 * @param {Object} state The current state of analytics report
 * @param {Object} action The current action.
 * @returns {MapValue}
 */
const analyticsReportViews = (state: MapValue = {
  dateRange: 'last_7_days',
  keyMetrics: List(),
  reportLinks: List(),
  reportDetail: Map(),
  queriesDetail: List(),
}, action: Action) => {
  switch (action.type) {
    case 'SET_ANALYTICS_DATE_RANGE':
      return { ...state, dateRange: action.dateRange };
    case 'SET_ANALYTICS_REPORT_LIST':
      return { ...state, reportLinks: action.reportLinks };
    case 'SET_ANALYTICS_KEY_METRICS_LIST':
      return { ...state, keyMetrics: action.keyMetrics };
    case 'SET_ANALYTICS_REPORT_DETAIL':
      return { ...state, reportDetail: action.reportDetail };
    case 'SET_ANALYTICS_QUERIES_DETAIL':
      return { ...state, queriesDetail: action.queriesDetail };
    default:
      return state;
  }
};

/**
 * View models for pharmaconnect
 * @param {Object} state The current state of analytics report
 * @param {Object} action The current action.
 * @returns {MapValue}
 */
const pharmaconnectViews = (state: MapValue = {
  brandDocuments: List(),
  readDocuments: null,
  smallDetailing: null,
  largeDetailing: null,
}, action: Action) => {
  switch (action.type) {
    case 'SET_PHARMACONNECT_BRAND_DOCUMENTS':
      return { ...state, brandDocuments: action.brandDocuments };
    case 'SET_PHARMACONNECT_READ_DOCUMENTS':
      return { ...state, readDocuments: action.readDocuments };
    case 'SET_PHARMACONNECT_SMALL_DETAILING':
      return { ...state, smallDetailing: action.detailing };
    case 'SET_PHARMACONNECT_LARGE_DETAILING':
      return { ...state, largeDetailing: action.detailing };
    default:
      return state;
  }
};

/**
 * View models for pharmaconnect
 * @param {Object} state The current state of analytics report
 * @param {Object} action The current action.
 * @returns {MapValue}
 */
const docsForRegeneration = (state: Map<string, MissingDocObject> = Map(), action: Action) => {
  switch (action.type) {
    case 'SET_DOCUMENTS_PENDING_REGENERATION':
      return action.docs.reduce((allDocs, doc) => allDocs.set(doc._id, doc), state);
    case 'UNSET_DOCUMENTS_PENDING_REGENERATION':
      return action.docs.reduce((allDocs, doc) => allDocs.delete(doc), state);
    default:
      return state;
  }
};

/**
 * List of Campaign set models and uread set map
 * @param {Object} state Current state
 * @param {Action} action Redux action
 * @return {Object}
 */
const campaignSetView = (
  state: {
    campaignSets: List<PatientCampaignSetModel>;
    readCampaignSetMap: Map<string, { lastEditedTime: number, isNew: boolean}>;
  } = {
    campaignSets: List(),
    readCampaignSetMap: Map(),
  },
  action: Action,
) => {
  switch (action.type) {
    case 'SET_CAMPAIGN_SET_VIEW':
      return is(state, action.data) ? state : action.data;
    case 'UPDATE_CAMPAIGN_SET_MODELS': {
      const updatedIds = action.models.map(m => m.get('_id'));
      const filteredSets = state.campaignSets.filter(set => !updatedIds.contains(set.get('_id')));
      return {
        ...state,
        campaignSets: filteredSets.concat(action.models),
      };
    }
    case 'UPDATE_READ_CAMPAIGN_SET_MAP':
      return {
        ...state,
        readCampaignSetMap: action.readCampaignSetMap,
      };
    default:
      return state;
  }
};

/**
 * flag states for various debug mode
 * @param {Object} state The current state of analytics report
 * @param {Object} action The current action.
 * @returns {MapValue}
 */
const debugModeFlags = (state: MapValue = {
  docValidation: false,
}, action: Action) => {
  switch (action.type) {
    case 'TOGGLE_DEBUG_MODE_FLAG':
      return { ...state, [action.flag]: !state[action.flag] };
    default:
      return state;
  }
};

/**
 * flag states for various debug mode
 * @param {Object} state The current state of analytics report
 * @param {Object} action The current action.
 * @returns {MapValue}
 */
const debugModeData = (state: DebugModeData = {
  show: false,
}, action: Action) => {
  switch (action.type) {
    case 'SET_DEBUG_MODE_DATA':
      return {
        ...state,
        flag: action.flag,
        show: true,
        saveModelsFn: action.saveModelsFn,
        models: action.models,
      };
    case 'UNSET_DEBUG_MODE_DATA':
      return {
        show: false,
      };
    default:
      return state;
  }
};


// Because sharedDataReducers is already an object we need to merge the other reducers into one
// objects so we can pass to combineReducers.
const reducerObject = Object.assign({}, modelReducers, {
  reportsFilter: reportsReducer,
  appointmentsFilter: appointmentsFilter,
  config,
  klinifyConfig,
  currentDataViews,
  currentDataViewsModels,
  currentDataViewsError,
  masterDrugModelsMap,
  prescribedDrugModelsMap,
  inventoryCount,
  inventoryCountSyncStatus,
  isConfigFetched,
  lastReceivedDBSequence,
  patientSearchQuery,
  patientStubs,
  user,
  workspace,
  appStartupComplete,
  hasAuth,
  authError,
  isFetching,
  isSKUCountSyncing,
  isOnline,
  showOfflinePrompt,
  serverOffline,
  isSyncing,
  recentlyViewedCasenotes,
  routerReducer,
  unsyncedAPICalls,
  patientListFilters,
  isAdminUser,
  encryptionOptions,
  verifiedDrugs,
  drugSuggestions,
  drugManufacturers,
  analyticsReportViews,
  pharmaconnectViews,
  appointmentRequested,
  isAppointmentRequestNotificationIconVisible,
  docsForRegeneration,
  campaignSetView,
  patientsSearch,
  debugModeFlags,
  debugModeData,
});

const reducers = combineReducers(reducerObject);
export default reducers;
