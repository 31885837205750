import { connect } from 'react-redux';

import CasenoteCategorySelector from './../components/casenotes/casenoteCategorySelector';
import { saveFactory } from './../utils/redux';
import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ categories }: State) => ({ categories });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const CasenoteCategorySelectorContainer =
  connect(mapStateToProps, mapDispatchToProps)(CasenoteCategorySelector);

export default CasenoteCategorySelectorContainer;
