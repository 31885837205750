import BaseModel from './baseModel';
import type { PolicyAttributes } from './coveragePayorPolicyModel';

export type InvoiceSettings = {
  show_treatment_details: boolean,
  show_treatment_breakdown: boolean,
  is_landscape: boolean
}

type Attributes = {
  _id: string,
  type: 'coverage_payor',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  name: string,
  address?: string,
  policies: Array<PolicyAttributes>, // not the model but only attributes objects are in the array
  internal_clinic_id?: string,
  invoice_settings: InvoiceSettings,
  active: boolean,
};

/**
   * CoveragePayorModel
   *
   * @namespace CoveragePayorModel
   */
class CoveragePayorModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'coverage_payor';
  }

  /**
   * Returns true if item does has an active flag.
   * @returns {boolean} True if item is active.
   */
  isVisible(): boolean {
    return this.get('active', false);
  }

  /**
   * Sets the CoveragePayorModel visibility to the  given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered CoveragePayorModel.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('active', isVisible);
  }

  /**
   * Returns the clinic's internal id if available, otherwise the coverage payor name is returned.
   * @returns {string} Internal clinic ID or coverage payor name.
   */
  getClinicID(): string {
    if (this.has('internal_clinic_id') && this.get('internal_clinic_id').length) {
      return this.get('internal_clinic_id');
    }
    return this.get('name');
  }

  /**
   * Returns the default co payment for a policy, otherwise returns default co payment for default policy.
   * @param {string} policyId policyId for which the default co payment is returned.
   * @returns {number} default co payment for the policy or default policy or 0.
   */
  getCoPayment(policyId: string | void): number {
    const policies = this.get('policies');
    if (policies && policies.length && policyId) {
      const policy = policies.find(_policy => _policy._id === policyId);
      return Number(
        policy ? policy.policy_co_payment_amount : policies[0].policy_co_payment_amount || 0,
      );
    }
    return 0;
  }
}

export default CoveragePayorModel;
