import * as React from 'react';
import type { List } from 'immutable';

import translate from './../../utils/i18n';
import type ProcedureTypeModel from './../../models/procedureTypeModel';

type Props = {
  procedureTypes: List<ProcedureTypeModel>,
  value: string,
  onChange: (value: string, procedureTypes: List<ProcedureTypeModel>, isSearching: boolean) => void,
};

/**
 * A component to display LabRequestsSearchInput.
 * @param {Props} props The component props.
 * @returns {React.Component}
 */
const LabRequestsSearchInput = ({ procedureTypes, value, onChange }: Props) =>
  <div className="c-patient-list__search-wrapper">
    <input
      className="c-patient-list__search o-text-input"
      placeholder={translate('search_lab_test')}
      type="text"
      value={value}
      onChange={(event) => {
        const filteredProcedureTypes = procedureTypes
          .filter(p => p.matchesQuery(event.target.value) && p.isActive());
        onChange(event.target.value, filteredProcedureTypes, !!event.target.value);
      }}
    />
    <div className="c-patient-list__search-wrapper__mglass-wrapper">
      <span className="ico ico-mglass" />
    </div>
    <hr />
  </div>;

export default LabRequestsSearchInput;
