import React from 'react';
import { List } from 'immutable';

import EncounterModel from './../../models/encounterModel';
import StatelessModal from './../modals/statelessModal';
import MedicalCertificateFormModal from './../medicalCertificates/medicalCertificateFormModal';
import TimeChitFormModal from './../timeChits/timeChitFormModal';
import translate from './../../utils/i18n';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import AddDocumentDataView from './../documentTemplates/addDocumentDataView';

import type { SaveModel, Config, User } from './../../types';
import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  config: Config,
  encounter: EncounterModel,
  hasMedicalCertificate: boolean,
  hasTimeChit: boolean,
  saveModel: SaveModel,
  user: User,
  documentTemplates: List<DocumentTemplateModel>,
  isOnline: boolean,
};

type State = {
  medicalCertificateModalVisible: boolean,
  timeChitModalVisible: boolean,
  documentTemplateModalVisible: boolean,
};

/**
 * A component displaying possible actions the user can take during a consult.
 * @class CurrentConsultActions
 * @extends {Component}
 */
class CurrentConsultActions extends React.Component<Props, State> {
  /**
   * Creates an instance of CurrentConsultActions.
   * @param {any} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      medicalCertificateModalVisible: false,
      timeChitModalVisible: false,
      documentTemplateModalVisible: false,
    };
  }

  /**
   * Renders a card component listing possible actions the user can take during a consult.
   * @returns {React.Component} The CurrentConsultActions component.
   */
  render() {
    const { documentTemplates } = this.props;
    return (
      <div className="o-card">
        <h2 data-public className="o-card__title">
          {translate('actions')}
        </h2>
        <div style={{ display: 'flex' }}>
          <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_medical_certificates', 'create')])} user={this.props.user}>
            <MedicalCertificateFormModal
              modalId="currentConsultMedicalCertificate"
              modalButtonLabel={translate('issue_medical_certificate')}
              modalButtonClass="o-button o-button--small u-margin--1ws u-width-180"
              modalDisableButton={this.props.hasMedicalCertificate}
              modalVisible={this.state.medicalCertificateModalVisible}
              isModalVisible={isVisible =>
                this.setState({ medicalCertificateModalVisible: isVisible })
              }
              modalTitle={translate('medical_certificate')}
              explicitCloseOnly
              config={this.props.config}
              patientID={this.props.encounter.get('patient_id')}
              encounter={this.props.encounter}
              encounterID={this.props.encounter.get('_id')}
              onSave={(medicalCertificate) => {
                this.setState({ medicalCertificateModalVisible: false });
                return medicalCertificate
                  .setSerialNumber()
                  .then(model => this.props.saveModel(model));
              }}
              dataPublicHeader
            />
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_time_chits', 'create')])} user={this.props.user}>
            <TimeChitFormModal
              modalId="currentConsultTimeChit"
              modalButtonLabel={translate('issue_time_chit')}
              modalButtonClass="o-button o-button--small u-margin--1ws u-width-180"
              modalDisableButton={this.props.hasTimeChit}
              modalVisible={this.state.timeChitModalVisible}
              isModalVisible={isVisible => this.setState({ timeChitModalVisible: isVisible })}
              modalTitle={translate('time_chit')}
              explicitCloseOnly
              config={this.props.config}
              patientID={this.props.encounter.get('patient_id')}
              encounter={this.props.encounter}
              onSave={(timeChit) => {
                this.setState({ timeChitModalVisible: false });
                timeChit
                  .setSerialNumber()
                  .then(model => this.props.saveModel(model));
              }}
              dataPublicHeader
            />
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('document_templates', 'create')])} user={this.props.user}>
            <StatelessModal
              id="currentConsultDocumentTemplate"
              buttonLabel={translate('create_document')}
              buttonClass="o-button o-button--small u-margin--1ws u-width-180"
              visible={this.state.documentTemplateModalVisible}
              setVisible={isVisible => this.setState({ documentTemplateModalVisible: isVisible })}
              title={translate('create_document')}
              explicitCloseOnly
              dataPublicHeader
            >
              <AddDocumentDataView
                documentTemplates={documentTemplates.filter(documentTemplate => !documentTemplate.get('hidden'))}
                closeModal={() => this.setState({ documentTemplateModalVisible: false })}
                patientId={this.props.encounter.get('patient_id')}
                encounterId={this.props.encounter.get('_id')}
                isOnline={this.props.isOnline}
              />
            </StatelessModal>
          </PermissionWrapper>
        </div>
      </div>
    );
  }
}

export default CurrentConsultActions;
