import React from 'react';
import { List } from 'immutable';

import { debugPrint } from './../../utils/logging';
import CoveragePayorForm from './../coveragePayors/coveragePayorForm';
import SalesItemForm from './../salesItems/salesItemForm';
import SupplierForm from './../inventory/supplierForm';

import type { SaveModel, Config, Model, User } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';

type Props = {
  type: 'coverage_payor' | 'doctor' | 'drug' | 'sales_item' | 'supplier',
  clearModelToEdit: () => void, // eslint-disable-line react/no-unused-prop-types
  modelToEdit?: Model, // eslint-disable-line react/no-unused-prop-types
  saveModel: SaveModel, // eslint-disable-line react/no-unused-prop-types
  config: Config, // eslint-disable-line react/no-unused-prop-types
  coveragePayors: List<CoveragePayorModel>, // eslint-disable-line react/no-unused-prop-types
  user: User,
};

/**
 * Renders the appropriate component.
 * @param {Props} props Props
 * @return {Reac.Component}
 */
const ListEditorForm = (props: Props) => {
  debugPrint('Rendering ListEditorForm');
  switch (props.type) {
    case 'coverage_payor':
      return <CoveragePayorForm {...props} />;
    case 'sales_item':
      return <SalesItemForm {...props} />;
    case 'supplier':
      return <SupplierForm {...props} />;
    default:
      throw new Error('Unspecified form type.');
  }
};

export default ListEditorForm;
