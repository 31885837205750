/* eslint quote-props: ["error", "always"] */
/* eslint max-len: 0 */
/* eslint max-lines: 0 */
/* eslint comma-dangle: 0 */
module.exports = {
  'a_payment_of_x_has_been_made_for_patient_y_for_the_encounter_on_z': 'A payment of %{x} has been made for patient %{y} for the encounter on %{z}.',
  'above_maximum_claimable_amount_can_proceed': 'Above Maximum Claimable Amount Can Proceed',
  'accepted_by_provider': 'Accepted by %{providerName}',
  'accepted_mapping': 'Accepted Mapping',
  'account': 'Account',
  'accounts_receivable_reports': 'Accounts Receivable Reports',
  'accounts_receivable': 'Accounts Receivable',
  'accounts': 'Accounts',
  'access_management': 'Access Management',
  'acknowledge': 'Acknowledge',
  'acknowledged': 'Acknowledged',
  'actions': 'Actions',
  'active': 'Active',
  'activate': 'Activate',
  'activate_x': 'Activate %{x}',
  'activate_selected_x_warning': 'Are you sure you want to activate the selected %{x}?',
  'activate_selected_x_warning_description': 'This will activate all campaigns in the selected %{x}.',
  'activate_campaign_sets': 'Activate campaign Sets',
  'active_ingrdient_name': 'Active Ingredient Name',
  'alert': 'Alert',
  'activate_campaign_sets_warning': 'Are you sure you want to activate the selected campaign sets?',
  'activate_campaign_sets_warning_description': 'This will activate all campaigns in the selected campaign sets.',
  'active_ingredients': 'Active Ingredients',
  'added_time': 'Added Time',
  'add_another_payment': 'Add Another Payment',
  'add_bank_name': 'Add Bank Name',
  'add_bank': 'Add Bank',
  'add_campaign_set': 'Add Campaign Set',
  'add_casenote': 'Add Casenote',
  'add_claim_invoice': 'Add Claim Invoice',
  'active-ingredient': 'Active Ingredient',
  'add_diagnosis': 'Add Diagnosis',
  'add_discount_s_charge_item_to_bill': 'Add Discount/Charge Item to Bill',
  'add_drug': 'Add Drug',
  'add_details': 'Add Details',
  'add_drug_duration': 'Add Drug Duration',
  'add_edit_drug_duration': 'Add/Edit Drug Duration',
  'add_edit_dosing_regimen': 'Add/Edit Dosing Regimen',
  'add_edit_procedure_request': 'Add/Edit Procedure Request',
  'add_edit_laboratory': 'Add/Edit Laboratory',
  'add_edit_lab_test': 'Add/Edit Lab Test',
  'add_edit_referral': 'Add/Edit Referral',
  'add_edit_specimen': 'Add/Edit Specimen',
  'add_edit_x': 'Add/Edit %{x}',
  'add_new_x': 'Add New %{x}',
  'add_item': 'Add Item',
  'and_if_the_following_criteria_are_met': 'and if the following criteria are met',
  'add_line_item': 'Add Line Item',
  'add_new_line_item': 'Add New Line Item',
  'add_mc': 'Add MC',
  'add_medical_certificate': 'Add Medical Certificate',
  'add_metrics': 'Add Metrics',
  'add_new_bill_error': 'Incomplete fields. Please complete all fields to add new bill.',
  'add_new_bill': 'Add New Bill',
  'add_new_category': 'Add New Category',
  'add_new_clinic': 'Add New Clinic',
  'add_new_campaign': 'Add New Campaign',
  'add_new_complaint': 'Add New Complaint',
  'add_new_coverage_payor': 'Add New Coverage Payor',
  'add_new_coverage_policy': 'Add New Coverage Policy',
  'add_new_doctor': 'Add New Doctor',
  'add_new_drug': 'Add New Drug',
  'add_new_drug_duration': 'Add New Drug Duration',
  'add_new_encounter': 'Add New Encounter',
  'add_new_field_to_sortby': 'Add new field to sort by',
  'add_new_filter': 'Add New Filter',
  'add_new_lab_test': 'Add New Lab Test',
  'add_new_lab': 'Add New Laboratory',
  'add_new_medication': 'Add New Medication',
  'add_new_note': 'Add New Note',
  'add_new_policy_type': 'Add New Policy Type',
  'add_new_prescription_item': 'Add new Prescription Item',
  'add_new_price': 'Add New Panel Price',
  'add_new_sales_item': 'Add New Sales Item',
  'add_new_specimen': 'Add New Specimen',
  'add_new_supplier': 'Add New Supplier',
  'add_new_user_group': 'Add New User Group',
  'add_new_user': 'Add New User',
  'add_new_vital': 'Add New Vital',
  'add_new_x_panel_label': 'Add new \"%{x}\" Panel Label', // eslint-disable-line no-useless-escape
  'add_new_x_drug_duration': 'Add new \"%{x}\" Drug Duration', // eslint-disable-line no-useless-escape
  'add_new_x_policy_label': 'Add new \"%{x}\" Policy Label', // eslint-disable-line no-useless-escape
  'add_new': 'Add New',
  'add_new_stage': 'Add new stage',
  'add_new_patient': 'Add New Patient',
  'add_note': 'Add Note',
  'add_past_bill': 'Add Past Bill',
  'add_patient_tags': 'Add Patient Tags',
  'acknowledge_and_continue': 'Acknowledge and Continue',
  'add_patient': 'Add Patient',
  'add_payment': 'Add Payment',
  'add_payment_type': 'Add Payment Type',
  'add_prescription': 'Add Prescription',
  'add_prescriptions': 'Add Prescriptions',
  'add_procedure_requrest': 'Add Procedure Request',
  'add_referral': 'Add Referral',
  'add_rule': 'Add rule',
  'add_sales_items': 'Add Sales Items',
  'add_sales_item_to_stage': 'Add Sales Item to Stage',
  'add_selected_drugs': 'Add selected drugs for Repeat Medicine',
  'add_supply': 'Add Supply',
  'add_stage_to_flow': 'Add Stage to Flow',
  'add_template': 'Add Template',
  'add_text_casenote': 'Add Text Note',
  'add_time_chit': 'Add Time Chit',
  'add_to_queue': 'Add to Queue',
  'add_vital_type': 'Click here to add a vital type.',
  'add_vitals': 'Add Vitals',
  'add_x_to_flow': 'Add "%{x}" to Flow',
  'add_x_to_prescriptions': 'Add \"%{x}\" to Prescriptions', // eslint-disable-line no-useless-escape
  'add_x_to_patients': 'Add \"%{x}\" to Patients', // eslint-disable-line no-useless-escape
  'add_x_to_doctors': 'Add \"%{x}\" to Doctors', // eslint-disable-line no-useless-escape
  'add_x_to_coverage_payors': 'Add \"%{x}\" to Coverage Payors', // eslint-disable-line no-useless-escape
  'add_x_to_sales_item': 'Add \"%{x}\" to Sales Item', // eslint-disable-line no-useless-escape
  'add_x_to_stage': 'Add %{x} to stage',
  'add': 'Add',
  'allergy_name': 'Allergy Name',
  'allergy_category': 'Allergy Category',
  'allergy_sub_category': 'Allergy Sub-Category',
  'add_change_mapping': 'Add / Change Mapping',
  'additional_info_attached': 'Additional info attached',
  'additional_info': 'Additional Info',
  'additional_information': 'Additional Information',
  'address_of_patient': 'Address of Patient',
  'address': 'Address',
  'adjustment': 'Adjustment',
  'adjustment_field_required_and_must_be_nonzero': 'Adjustment field is required and must be Non-zero',
  'adjust': 'Adjust',
  'adjust_batch_quantity': 'Adjust Batch Quantity',
  'admin_adjustments': 'Admin Adjustments',
  'administration_time': 'Administration Time',
  'administration_first_time': 'Administration First Time',
  'administration_desc': 'Administration Description',
  'admin_first_time_is_required': 'Administration first time is required',
  'admin_time_is_required': 'Administration time is required',
  'adopted_child': 'Adopted Child',
  'adopted_daughter': 'Adopted Daughter',
  'adopted_son': 'Adopted Son',
  'adoptive_father': 'Adoptive Father',
  'adoptive_mother': 'Adoptive Mother',
  'adoptive_parent': 'Adoptive Parent',
  'advanced': 'Advanced',
  'advanced_panel_settings': 'Advanced Panel Settings',
  'advanced_price_settings': 'Advanced Price Settings',
  'advise_to_diagnosis': 'Advise to diagnosis',
  'after': 'after',
  'after_their_x': 'After their %{x}',
  'age': 'Age',
  'always_applied': 'Always Applied',
  'always_applied_during_billing': 'Always applied during billing',
  'all_vitals': 'All Vitals',
  'all': 'All',
  'allergies': 'Allergies',
  'allergy_creation_success': 'Allergy %{name} added to patient.',
  'allergy_type': 'Allergy Type',
  'allergy_update_success': 'Allergy %{name} has been updated.',
  'allergy_added_success': 'Allergy %{name} has been added.',
  'allergy': 'Allergy',
  'allow_patients_with_same_ic_to_register': 'Allow patients with the same IC numbers to be registered',
  'allow_popup_message': 'Allow pop-up message',
  'alert_overview_setting_description': 'Alert plays on clinic overview page when a consultation has ended (moved to Billing).',
  'amount_already_paid': 'Amount Already Paid',
  'amount_entered_is_invalid': 'Please enter a valid amount.',
  'amount_in_local_currency': 'Amount In Local Currency',
  'amount_outstanding': 'Amount Outstanding',
  'amount_owed_to_clinic': 'Amount Owed To Clinic',
  'amount_received': 'Amount received',
  'amount_paid': 'Amount Paid',
  'amount': 'Amount',
  'analytics': 'Analytics',
  'analytics_new_report': 'Analytics New Report',
  'analytics_report': 'Analytics Report',
  'analytics_summary_page_title': 'Analytics Summary',
  'analytics_view_table': 'Table',
  'analytics_view_chart': 'Chart',
  'analytics_chart_aggregate': 'Aggregate',
  'analytics_chart_time_series': 'Over time',
  'analytics_chart_pie': 'Pie',
  'analytics_chart_bar': 'Bar',
  'analytics_chart_compare': 'Compare',
  'analytics_chart_stacked': 'Stacked',
  'and': 'and',
  'append_totals_to_export': 'Append Totals To Export',
  'appointments': 'Appointments',
  'approved': 'Approved',
  'automtatic_print_queue_number': 'Automatically Print Queue Numbers after Queueing Patients',
  'appointment_requests': 'Appointment Requests',
  'app_start_failed': 'App failed to start. Try logging in again.',
  'area_of_operation': 'Area of Operation',
  'are_you_sure_you_wish_to_proceed': 'Are you sure you wish to proceed?',
  'arrival_date': 'Arrival Date',
  'arrival_time': 'Arrival Time',
  'arrived': 'Arrived',
  'ascending': 'Ascending',
  'assign_doctor_on_arrival': 'Assign doctor on arrival',
  'assume_further_management': 'Assume further management',
  'assign_user_group': 'Assign User Group',
  'assume_management_and_return_after_care': 'Assume management and return after care',
  'atdps_id': 'ATDPS ID',
  'atdps_id_required': 'ATDPS ID is required for ATDPS integrated drugs',
  'atdps_dosing_regimen_required': 'ATDPS Dosing Regimen is required',
  'atdps_admin_time_required': 'Drug Administration Time is required for ATDPS integrated drugs',
  'atdps_admin_time_invalid': 'Drug Administration Time entered in invalid',
  'atdps_integrated': 'ATDPS Integrated',
  'aunt': 'Aunt',
  'auth_timeout_message': 'You have been logged out after being inactive for some time. Any changes you made have been saved and will be synced to the server once you log in.',
  'average_dispensed_per_transaction': 'Average Dispensed per Transaction',
  'back_to_patient': 'Back to Patient',
  'back': 'Back',
  'balance': 'Balance',
  'bank_already_exists': 'Bank already exists',
  'bank_name': 'Bank Name',
  'bank_saved': 'The bank was successfully saved.',
  'bank_transfer': 'Bank Transfer',
  'bank': 'Bank',
  'banks_settings': 'Banks Settings',
  'banks': 'Banks',
  'batch': 'Batch',
  'batch_id': 'Batch ID',
  'bill': 'Bill',
  'bill_item': 'Bill Item',
  'bills': 'Bills',
  'batch_#': 'Batch #',
  'batch_stock_remaining': 'Batch Stock Remaining',
  'batches_added_have_earlier_x': 'The batch(es) that were just added have an earlier %{x} than the currently dispensing batch.',
  'batch_dispensing_order': 'Batch Dispensing Order',
  'batches_in': 'Batches in %{sku}',
  'bed_number': 'Bed Number',
  'bill_allow_editing': 'Allow Editing',
  'bill_balance_amount': 'Balance of bill',
  'bill_co_payment': 'Bill Co-Payment',
  'bill_created': 'Bill added to patient.',
  'bill_date': 'Bill Date',
  'bill_item_dispensation_unit': 'Bill Item Dispensation Unit',
  'bill_item_is_claimed': 'Bill Item Is Claimed?',
  'bill_item_name': 'Bill Item Name',
  'bill_item_price': 'Bill Item Price',
  'bill_item_quantity': 'Bill Item Quantity',
  'bill_item_total_price': 'Bill Item Total Price',
  'bill_item_type': 'Bill Item Type',
  'bill_items_must_not_have_empty_fields': 'Billing items cannot have empty fields. Also, quantities cannot be negative.',
  'bill_items': 'Bill Items',
  'bill_last_edited': 'Bill Last Edited',
  'bill_must_have_items': 'Bills cannot be empty. Please add a billing item below.',
  'bill_not_found': 'Bill Not Found',
  'bill_notes': 'Bill Notes',
  'bill_panel_address': 'Panel Address',
  'bill_panel_name': 'Bill Panel Name',
  'bill_serial_number': 'Bill Serial Number',
  'bill_settings': 'Bill Settings',
  'bill_show_sales_items_on_encounter_page': 'Show Sales Items on Encounter Page',
  'bill_to_panel': 'Bill to Panel',
  'bill_to': 'Bill to',
  'bill_total_amount': 'Bill Total Amount',
  'bill_total_cannot_be_negative': 'Bill total cannot be negative amount.',
  'bill_updated': 'Bill successfully updated.',
  'bill_voided': 'Bill Voided',
  'bill_void_error': 'Bill was not voided due to some error.',
  'billing_history': 'Billing History',
  'billing_page': 'Billing Page',
  'billing': 'Billing',
  'billing_staff': 'Billing Staff',
  'bin': 'Bin',
  'blood': 'Blood',
  'block_time': 'Block Off time',
  'block_type': 'Block Type',
  'bottom': 'Bottom',
  'booking_details': 'Booking Details',
  'bonus': 'Bonus',
  'brand': 'Brand',
  'brother-in-law': 'Brother-in-law',
  'brother': 'Brother',
  'bulk': 'Bulk',
  'bulk_mapping': 'Bulk Mapping',
  'bulk_stock_adjustment': 'Bulk Stock Adjustment',
  'bulk_action_add_new_line_item_desc': 'To continue with your bulk action, please click on the button below to add a new line item',
  'call_when_you_have_seen_patient': 'Call when you have seen patient',
  'campaign': 'Campaign',
  'campaign_goal_placeholder': 'eg. Reach out to Diabetic patients',
  'campaign_name': 'Campaign Name',
  'campaigns_may_no_longer_active_after_flow_deletion': 'The campaigns above may no longer be active after the deletion of this Flow.',
  'campaign_name_palceholder': 'eg. Diabetic program',
  'campaign_rules': 'Campaign Rules',
  'campaign_set_name': 'Campaign Set Name',
  'campaign_set_status': 'Campaign Set Status',
  'campaign_status': 'Campaign Status',
  'campaign_set_recently_added': 'This campaign set has been recently added!',
  'campaign_sets': 'Campaign sets',
  'campaign_sets_successfully_activate': 'Campaign sets successfully activated',
  'campaign_sets_successfully_create': 'Campaign sets successfully created',
  'campaign_sets_successfully_deactivate': 'Campaign sets successfully deactivated',
  'campaign_sets_successfully_delete': 'Campaign sets successfully deleted',
  'campaign_sets_successfully_update': 'Campaign sets successfully updated',
  'campaigns': 'Campaigns',
  'campaigns_sucessfully_activate': 'Campaigns sucessfully activated',
  'campaigns_sucessfully_deactivate': 'Campaigns sucessfully deactivated',
  'campaigns_sucessfully_delete': 'Campaigns sucessfully deleted',
  'campaigns_sucessfully_duplicate': 'Campaigns sucessfully duplicated',
  'cancel': 'Cancel',
  'cancel_appointment_confirm_message': 'Are you sure to cancel this appointment?',
  'cancel_appointment': 'Cancel Appointment',
  'cancel_edit': 'Cancel Edit',
  'cancel_SMS_job': 'Cancel SMS Job',
  'cancelled': 'Cancelled',
  'cancelling_order': 'Cancelling Order...',
  'cant_seem_correct_item': 'I can’t seem to find the correct item',
  'cant_create_document_while_offline': 'Cannot create a document while offline',
  'cant_find_the_drug_i_need': 'I cannot find the drug I need',
  'card_name': 'Card Name',
  'card': 'Card',
  'case_id_label_desc': 'This is used to represent internal clinic IDs.',
  'case_id_label': 'Case ID Label',
  'case_id': 'Case ID',
  'casenote_creation_success': 'A casenote has been added to patient.',
  'casenote_deletion_success': 'A casenote has been deleted.',
  'casenote_editor_pen_size': 'Pen size (px)',
  'casenote_editor_settings': 'Casenote Editor Settings',
  'casenote_remove_from_bin_success': 'A casenote has been removed from bin.',
  'casenote_save_failed_desc': 'The casenote could not be saved. Please try again, and if the problem persists contact Klinify for assistance.',
  'casenote_save_failed': 'Casenote Save Failed',
  'casenote_title': 'Casenote Title',
  'casenote_update_success': 'A casenote has been updated.',
  'casenote': 'Casenote',
  'casenotes_bin_empty': 'Your casenotes bin is empty.',
  'casenotes': 'Casenotes',
  'cash': 'Cash',
  'categorize_and_view_incomplete_encounters': 'Categorize and view incomplete encounters',
  'category': 'Category',
  'category_x': 'Category %{x}',
  'change_doctor': 'Change Doctor',
  'change_flow_warning': 'This will remove the patient from the queue and queue them for %{flow_name}.\n\nAny data, including notes, will be saved into the current flow and will not be visible in the new flow.\n\nThis will also void this Encounter’s bill.',
  'change_password_failed': 'The password could not be changed. Please try again, and if the problem persists contact Klinify for assistance.',
  'change_password': 'Change Password',
  'change_permissions': 'Change Permissions',
  'change': 'Change',
  'change_to_x': 'Change to %{x}',
  'changelog': 'Change Log',
  'charge': 'Charge',
  'cheque_number_must_be_filled_for_cheque_payment': 'For cheque payments cheque number must be filled.',
  'cheque_number': 'Cheque No.',
  'cheque': 'Cheque',
  'child-in-law': 'Child-in-law',
  'choose_non_drug_type': 'Choose non-drug type',
  'choose_one_of_these_actions_to_add': 'Choose one of these actions to add to the inventory',
  'choose_one_of_these_actions_to_batch_modification': 'Choose one of these actions to make Batch Modifications',
  'choose_one_of_these_actions_to_remove': 'Choose one of these actions to remove from the inventory',
  'choose_one_of_these_actions_to_add_bulk': 'Choose one of these actions to add in bulk to the inventory',
  'choose_one_of_these_actions_to_remove_bulk': 'Choose one of these actions to remove in bulk from the inventory',
  'child': 'Child',
  'cigarettes_per_day': 'Cigarettes Per Day',
  'citizenship': 'Citizenship',
  'claim_invoice_created': 'Claim Invoice created',
  'claim_invoice_for': 'Claim Invoice For:',
  'claim_invoice_payment_saved': 'Payment for claim invoice has been recorded successfully.',
  'claim_invoice_payment': 'Claim Invoice Payment',
  'claim_invoice_regeneration_failed': 'Claim Invoice regeneration failed. Please try again, and if the problem persists contact Klinify for assistance.',
  'claim_invoice_successfully_regenerated': 'Claim Invoice successfully regenerated.',
  'claim_invoice_successfully_voided': 'Claim Invoice successfully voided.',
  'claim_invoice_summary': 'Claim Invoice Summary',
  'claim_invoice_void_failed': 'Claim Invoice void failed. Please try again, and if the problem persists contact Klinify for assistance.',
  'claim_invoice': 'Claim Invoice',
  'claim_invoices_successfully_regenerated': 'Claim Invoices successfully regenerated.',
  'claim_invoices_successfully_voided': 'The Claim Invoices were successfully voided.',
  'claim_invoices_void_failed': 'The Claim Invoices failed to be voided. Please try again, and if the problem persists contact Klinify for assistance.',
  'claim_invoices_with_edited_bills': 'Claim Invoices With Edited Bills',
  'claim_invoices': 'Claim Invoices',
  'claim_payments': 'Claim Payments',
  'claim_reports': 'Claim Reports',
  'claimable_items': 'Claimable Items',
  'claimable': 'Claimable',
  'claimed_amount': 'Claimed Amount',
  'claims': 'Claims',
  'clear_filter': 'Clear Filter',
  'click_here_to_refer': 'Click here to refer',
  'click_here_to_register_patient_x': 'Click here to register patient %{x}.',
  'click_here_to_view_new_invoice': 'Click here to view the new claim invoice',
  'clinic_added': 'Clinic Added',
  'clinic_address': 'Clinic Address',
  'clinic_address|single_line': 'Clinic Address(Single Line)',
  'clinic_email': 'Clinic Email',
  'clinic_fax_no': 'Clinic Fax No.',
  'clinic_fax': 'Clinic Fax',
  'clinic_id': 'Clinic ID',
  'clinic_logo': 'Clinic Logo',
  'clinic_misc': 'Clinic Misc',
  'clinic_name': 'Clinic Name',
  'clinic_overview': 'Clinic Overview',
  'clinic_phone': 'Clinic Phone Number',
  'clinic_settings': 'Clinic Settings',
  'clinic_tel_no': 'Clinic Tel No.',
  'clinic_updated': 'Clinic Updated',
  'clinic_website': 'Clinic Website',
  'clinic': 'Clinic',
  'clinical_status': 'Clinical Status',
  'clinics': 'Clinics',
  'close': 'Close',
  'co_payment_amount': 'Co-payment Amount',
  'co_payment': 'Co-payment',
  'collected_by': 'Collected by',
  'column_name': 'Column Name',
  'columns_to_be_displayed': 'Columns To Be Displayed',
  'column': 'column',
  'columns': 'columns',
  'comments': 'Comments',
  'company_name': 'Company Name',
  'company': 'Company',
  'complaint': 'Complaint',
  'complete_encounter_stage_and_see_next_patient': 'Complete %{stage} Stage and see next patient in %{stage}',
  'complete': 'Complete',
  'complete_stage': 'Complete %{stage} Stage',
  'completed': 'Completed',
  'completed_date': 'Completed Date',
  'completed_time': 'Completed Time',
  'condition': 'Condition',
  'confirm_tele_consult_request': 'Confirm E-Consultation Request',
  'confirm_walk_in_request': 'Confirm Walk-In Request',
  'confirm_bill_void': 'Are you sure you want to void this bill? Doing so will return all billed items to the inventory. It will not cancel any claims that have already been assigned to an invoice.',
  'confirm_cancel_appointment': 'Are you sure you want to cancel this appointment? An email will be sent to the patient notifying them.',
  'confirm_cancel': 'Are you sure you want to cancel this appointment? This action will also set the bill for this appointment as void.',
  'confirm_casenote_deletion': 'Are you sure you want to delete this casenote?',
  'confirm_casenote_restore': 'Are you sure you want to return this casenote to it\'s original category?',
  'confirm_deleting_allergy': 'You will be deleting %{name} permanently. Are you sure you want to continue?',
  'confirm_deleting_campaign': 'You will be deleting %{name} permanently. Are you sure you want to continue?',
  'confirm_deleting_drug_duration': 'Are you sure you want to delete this drug duration?',
  'confirm_diagnosis': 'Confirm diagnosis',
  'confirm_doctor_deletion': 'Are you sure you want to delete this doctor?',
  'confirm_document_deletion': 'Are you sure you want to delete this document?',
  'confirm_document_template_deletion': 'Are you sure you want to delete this document template?',
  'confirm_drug': 'Confirm Drug',
  'confirm_invoice': 'Confirm Invoice',
  'confirm_x_deletion': 'Are you sure you want to delete this %{x}?',
  'confirm_item_deletion': 'Are you sure you want to remove this item?',
  'confirm_lab_deletion': 'Are you sure you want to delete this lab?',
  'confirm_lab_test_deletion': 'Are you sure you want to delete this lab test \"%{labTestName}\"?', // eslint-disable-line no-useless-escape
  'confirm_new_password': 'Confirm New Password',
  'confirm_payment_void': 'Are you sure you want to void this payment?',
  'confirm_job_resolve': 'Are you sure you want to resolve the Job(s)?',
  'confirm_job_cancel': 'Are you sure you want to cancel the Job(s)?',
  'confirm_prescription_deletion': 'Are you sure you want to delete this prescription?',
  'confirm_procedure_request_cancellation': 'Are you sure you want to delete this lab request?',
  'confirm_remove_from_queue': 'This will also void this Encounter’s bill.',
  'confirm_regenerate_claim_invoice': 'Are you sure you want to regenerate this claim invoice?',
  'confirm_regenerate_claim_invoices_from_add_claim': 'Are you sure you want to regenerate claim invoice %{internalClinicID}?',
  'confirm_regenerate_claim_invoices': 'Are you sure you want to regenerate the selected claim invoices?',
  'confirm_save_casenote_before_route': 'You have unsaved changes to the current casenote. To save the changes click OK. To discard the changes click cancel',
  'confirm_specimen_deletion': 'Are you sure you want to delete this specimen?',
  'confirm_vital_deletion': 'Are you sure you want to remove this Vital?',
  'confirm_void_claim_invoice': 'Are you sure you want to void this claim invoice? All associated claims will be marked as unclaimed.',
  'confirm_void_claim_invoices': 'Are you sure you want to void the selected claim invoices? All associated claims will be marked as unclaimed.',
  'confirm_void_payments': 'Would you like to cancel any patient payments made for this bill once it is voided?',
  'confirm': 'Confirm',
  'confirm_campaign_activate': 'Are you sure you want to activate the selected campaigns?',
  'confirm_campaign_deactivate': 'Are you sure you want to deactivate the selected campaigns?',
  'confirm_campaign_delete': 'Are you sure you want to delete the selected campaigns?',
  'confirm_campaign_duplicate': 'Are you sure you want to duplicate the selected campaigns?',
  'confirmed': 'Confirmed',
  'confirmation': 'Confirmation',
  'consider_adding_stage_to_patient_flow': 'If the patient needs to go through more services, consider adding a Stage to their Flow.',
  'consumed': 'Consumed',
  'consult_mode': 'Consult Mode',
  'consult_notes_saved': '%{encounterLabel} Notes Saved',
  'consult_reports': 'Consult Reports',
  'consult_reports_summary': 'Consult Reports Summary',
  'consult_type': 'Consult Type',
  'consult_types': 'Consult Types',
  'consultation_cost': '%{encounterLabel} Cost',
  'consults': 'Consults',
  'contact_details': 'Contact Details',
  'contact_number': 'Contact Number',
  'contact_us': 'Contact Us',
  'continue': 'Continue',
  'copy': 'copy',
  'copy_to': 'Copy to',
  'copy_link': 'Copy Link',
  'cosmetic_drug': 'Cosmetic Drug',
  'cosmetic_drug_examples': 'Examples of cosmetic drugs: Shampoo, Conditioner, Moisturiser, Sunsceen',
  'cost_du': 'Cost/DU',
  'cost_of_goods': 'Cost of Goods',
  'cost_of_goods_sold': 'Cost of Goods Sold',
  'cost_price_per_unit': 'Cost Price Per Unit',
  'cost_price': 'Cost Price',
  'cost_report': 'Cost Report',
  'count': 'Count',
  'country': 'Country',
  'courier_collected': 'Courier Collected',
  'cousin': 'Cousin',
  'continue_prescribing': 'I want to prescribe drugs that the patient is allergic to',
  'continue_dispensing': 'I want to dispense drugs that the patient is allergic to',
  'coverage_payor': 'Coverage Payor',
  'coverage_payor_added': 'Coverage Payor Added',
  'coverage_payor_deleted': 'Coverage Payor Deleted',
  'coverage_payor_label': 'Coverage - Payor Label',
  'coverage_payor_not_found': 'Coverage Payor Not Found',
  'coverage_payor_updated': 'Coverage Payor Updated',
  'coverage_payors': 'Coverage Payors',
  'coverage_policy_holder_label': 'Coverage - Policy Holder Label',
  'coverage_relationship_label': 'Coverage - Relationship Label',
  'coverage_subscriber_label': 'Coverage - Subscriber Label',
  'cpu': 'CPU',
  'creator': 'Creator',
  'create_document': 'Create Document',
  'create_new_claim_invoices': 'Create New Claim Invoices',
  'create_new_document': 'Create New Document',
  'create': 'Create',
  'create_new_flow': 'Create New Flow',
  'create_new_report': 'Create new report',
  'create_notes_section_in_encounter': 'Create “Notes” section in the encounter',
  'create_notes_section_in_encounter_desc': 'This will display as “%{stageName} Notes” within the encounter section',
  'create_stage': 'Create Stage',
  'create_x': 'Create %{x}',
  'created_by': 'Created By',
  'created_on_date': 'Created on %{date} by %{user}.',
  'creation_date': 'Creation Date',
  'credit_availability': 'Credit Availability',
  'credit_card': 'Credit Card',
  'credit_exposure': 'Credit Exposure',
  'credit_limit': 'Credit Limit',
  'credit': 'Credit',
  'criticality': 'Criticality',
  'CRITL': 'Low Risk',
  'CRITH': 'High Risk',
  'CRITU': 'Undetermined',
  'currency_shorthand_label': 'Currency Shorthand Label',
  'current_clinic_stock_of_drug': 'Current Clinic Stock of Drug',
  'current_consult': 'Current Consult',
  'current_cost_price': 'Current Cost Price',
  'current_count': 'Current Count',
  'current_employer': 'Current Employer',
  'current_encounter_conditions': 'Conditions', // Permissions label
  'current_encounter_lab_tests': 'Lab Tests', // Permissions label
  'current_encounter_medical_certificates': 'Medical Certificates', // Permissions label
  'current_encounter_notes': 'Notes', // Permissions label
  'current_encounter_position': 'Current %{encounterLabel} Position',
  'current_encounter_prescriptions': 'Prescriptions', // Permissions label
  'current_encounter_sales_items': 'Sales Items', // Permissions label
  'current_encounter_time_chits': 'Time Chits', // Permissions label
  'current_encounter_view_cost_price': 'View Cost Price',
  'current_encounter': 'Current Encounter',
  'current_encounters': 'Current Encounters', // Permissions label
  'current_flow': 'Current Flow',
  'current_inventory': 'Current Inventory',
  'current_inventory_items': 'Current Items',
  'current_medication': 'Current Medication',
  'current_password_incorrect': 'The password you entered as your current password was incorrect.',
  'current_password': 'Current Password',
  'current_version': 'Current Version',
  'current': 'Current',
  'cost': 'Cost',
  'confirm_file_download': 'Are you sure you want to download the %{name}?',
  'currently_dispensing_batch': 'Currently Dispensing Batch',
  'currently_dispensing_batch_for': 'Currently dispensing batch for %{sku}',
  'custom_flow': 'Custom Flow',
  'custom_formulary': 'My item is a custom formulary',
  'custom_name': 'Custom Name',
  'custom_note': 'Custom Notes',
  'custom_notes': 'Custom Notes',
  'customer_name': 'Customer Name',
  'customer_no': 'Customer No',
  'customise_columns': 'Customise Columns',
  'damaged': 'Damaged',
  'dashboard_today': "Today's Stats",
  'dashboard_daily': 'Daily Stats',
  'dashboard_weekly': 'Weekly Stats',
  'dashboard_monthly': 'Monthly Stats',
  'datatype_desc': 'Each datatype represents one part of a vital recording. Range is used to restrict the number of options available. E.g. to restrict the options to yes and no set the Range field to show `Yes, No`.',
  'datatype': 'Datatype',
  'data_debug': 'Data Debug',
  'data_export': 'Data Export',
  'data_export_password_info': 'Password for the exported data has been sent to authorized email.',
  'data_export_requests_will_be_voided_warning': 'This will void all previous data export requests.',
  'data_export_completed_message': 'Your data export request has been completed. Please check data-export page to download the data',
  'data_exports_update': 'Data Export Update',
  'data_not_found': 'Data Not Found',
  'date_and_time': 'Date and Time',
  'date_created': 'Date Created',
  'date_end': 'Date End',
  'date_format': 'Date Format',
  'date_generated': 'Date Generated',
  'date_of_birth': 'Date of Birth',
  'date_of_payment': 'Date of payment',
  'date_of_sale_supply': 'Date of Sale / Supply',
  'date_of_specimen_collection': 'Date of specimen collection',
  'date_range': 'Date Range',
  'date_received': 'Date Received',
  'date_supplied_administered_received': 'Date Supplied / Administered / Received',
  'date_start': 'Date Start',
  'date': 'Date',
  'dates': 'Dates',
  'daughter_in-law': 'Daughter-in-law',
  'daughter': 'Daughter',
  'day': 'Day',
  'days': 'Days',
  'drugs': 'Drugs',
  'days_of_stock_left': 'Days of Stock Left',
  'days_since_last_visit': 'Days since last visit',
  'days_since_last_visit_error': 'Days since last visit should be a number and cannot be negative value',
  'days_value_is_not_valid': 'Days value is not valid',
  'db_admin_users_cannot_change_password_from_within_app': 'The database admin user cannot change their password from within the Klinify app.',
  'default': 'Default',
  'deactivate': 'Deactivate',
  'deactivate_x': 'Deactivate %{x}',
  'deactivate_selected_x_warning_title': 'Are you sure you want to deactivate the selected %{x}?',
  'deactivate_selected_x_warning': 'This will deactivate all campaigns in the selected %{x}. \n',
  'deactivate_selected_x_warning_description': 'Warning: Deactivating a campaign may affect any incoming revenue from it.',
  'debug_mode': 'Debug Mode',
  'default_cash_price': 'Default Cash Price',
  'default_duration': 'Default Duration',
  'default_dispensation_quantity': 'Default Dispensation Quantity',
  'default_frequency': 'Default Frequency',
  'default_panel_category': 'Default Panel Category',
  'default_panel_price': 'Default Panel Price',
  'default_prescribed_dosage': 'Default Prescribed Dosage',
  'default_quantity': 'Default Quantity',
  'default_row_number': 'Default Row Number',
  'default_template': 'Default Template',
  'default_time_chit_length_desc': 'The default number of minutes a time chit will be issued for.',
  'default_time_chit_length': 'Default Time Chit Length',
  'default_view_and_page_size_cannot_be_empty': 'Default view and page size cannot be empty. Please fill out the fields.',
  'default_view': 'Default View',
  'delete': 'Delete',
  'deleted': 'Deleted',
  'delete_x': 'Delete %{x}',
  'delete_block_off_time_warning': 'Are you sure you want to delete this block off time?',
  'delete_selected_x_warning': 'Are you sure you want to delete selected %{x}?',
  'delete_selected_x_warning_description': 'This will delete all campaigns in the selected %{x}.',
  'deleted_successfully': 'Deleted successfully',
  'delete_document': 'Delete Document',
  'delete_document_confirm': 'Are you sure you want to delete this document?',
  'delivery_order_#': 'Delivery Order #',
  'demand_per_day': 'Demand / Day',
  'demo_banner_text_1': 'This server is created for demonstration purposes only and will be wiped at a later date.',
  'demo_banner_text_2': 'Do not save sensitive data into this server.',
  'dependent_documents': 'Dependent Documents',
  'descending': 'Descending',
  'description': 'Description',
  'deselect_all': 'Deselect All',
  'detailed_inventory_warnings': 'Detailed Inventory Warnings',
  'diagnoses': 'Diagnoses',
  'diagnosis_creation_success': 'A diagnosis has been added to patient.',
  'diagnosis_history': 'Diagnosis History',
  'diagnosis_options': 'Diagnosis Options', // Permissions label
  'diagnosis_search': 'Diagnosis Search',
  'diagnosis_update_success': 'A diagnosis has been updated.',
  'diagnosis': 'Diagnosis',
  'differential': 'Differential',
  'disable': 'Disable',
  'discard': 'Discard',
  'drug_unspecified_in_allergy': 'Allergies in “Drugs - Unspecified” Sub-Category',
  'discount': 'Discount',
  'discount_s_charge': 'Discount/Charge',
  'discounts_s_charges': 'Discounts/Charges',
  'discounts_charges': 'Discounts and Charges',
  'discounted_price': 'Discounted Price',
  'disp_unit': 'Disp. Unit',
  'dispensation_unit': 'Dispensation Unit',
  'dispensation_unit_mapping': 'Dispensation Unit Mapping',
  'dispensation': 'Dispensation',
  'dispense_batches_by': 'Dispense Batches By',
  'dispense_bill': 'Dispense/Bill',
  'dispense_by_x': 'Dispense By: %{x}',
  'dispensing_error': 'Dispensing Error',
  'dispense_from': 'Dispense From',
  'dispensing_order': 'Dispensing Order',
  'dispensing_order_set_to_x': 'Dispensing Order set succesfully to %{x}',
  'dispense_price': 'Dispense Price',
  'dispense_summary': 'Dispense Summary',
  'dispense_total': 'Dispense Total',
  'dispensed_only': 'Dispensed Only',
  'dispensed_prescription_items': 'Dispensed Prescription Items',
  'dispensing_order_confirm_warning_message': 'This will apply to all drugs and reset all currently dispensing batches.\n\nNote, if you have manually selected batches to dispense from they will NOT be affected by this.\n\nAre you sure you wish to continue?',
  'display_format_desc': 'This is the format of the vital. To place a datatype you can enter {v} and to place a unit label you can enter {u}. E.g. For blood pressure set the display format as {v}/{v} {u} and the resulting display will look like: 100/120 mmHg. The number of times {v} is used should equal the number of datatypes below.',
  'display_format': 'Display Format',
  'doing_this_completes_stages_message': 'Doing this will complete the following stages for this patient:',
  'doing_this_reactivate_stages_message': 'Doing this will reactivate the following stages for this patient:',
  'do_not_create': 'Do not create',
  'dob': 'Date of Birth',
  'doc_#': 'Doc #',
  'doctor_appointment': 'Doctor Appointment',
  'doctor_deleted': 'Doctor deleted.',
  'doctor_dob': 'Doctor DOB',
  'doctor_email': 'Doctor Email',
  'doctor_ethnicity': 'Doctor Ethnicity',
  'doctor_gender': 'Doctor Gender',
  'doctor_ic_number': 'Doctor IC Number',
  'doctor_id': 'Doctor ID',
  'doctor_internal_clinic_id': 'Doctor Internal Clinic ID',
  'doctor_name': 'Doctor Name',
  'doctor_qualifications': 'Doctor Qualifications',
  'doctor_registration_number': 'Doctor Registration Number',
  'doctor_speciality': 'Doctor Speciality',
  'doctor_tel': 'Doctor Telephone',
  'doctor': 'Doctor',
  'doctors_name': 'Doctor\'s Name',
  'doctors_page': 'Doctor\'s Page',
  'doctors': 'Doctors',
  'doc_validation_debug_mode': 'Doc Validation Debug Mode',
  'document_created_successfully': 'Document created successfully',
  'document_date': 'Document Date',
  'document_deleted': 'The document was successfully deleted.',
  'document_location': 'Document Location',
  'document_name': 'Document Name',
  'document_number': 'Document Number',
  'document_not_found': 'Document not found',
  'document_saved': 'The document was successfully saved.',
  'document_template_created': 'Document Template successfully created.',
  'document_template_edited': 'Document Template successfully edited.',
  'document_template_save_failed_desc': 'An error occurred while creating your document template. PLease try again, and if the error persists contact Klinify for assistance.',
  'document_template_save_failed': 'Document Template Save Failed',
  'document_templates_settings': 'Document Templates Settings',
  'document_templates': 'Document Templates',
  'downloads': 'Downloads',
  'document_type': 'Document Type',
  'document_type_not_provided': 'You must provide a document type',
  'document_updated_successfully': 'Document updated successfully!',
  'document': 'Document',
  'documents': 'Documents',
  'does_not_have_waiting_section': 'Does not have a “Waiting” Section',
  'domestic_partner': 'Domestic Partner',
  'done': 'Done',
  'dosage_unit_freq': 'Dosage / Unit / Frequency',
  'dosage_unit': 'Dosage Unit',
  'dosage_units': 'Dosage Units',
  'dosage': 'Dosage',
  'dosing_regimen': 'Dosing Regimen',
  'dosing_regimen_added': 'Dosing Regime Added',
  'dosing_regimen_updated': 'Dosing Regimen Updated',
  'deafult_x': 'Default: %{x}',
  'dosage_amounts': 'Dosage amounts',
  'download_atdps_file': 'Download file for ATDPS items',
  'download_pdf': 'Download PDF',
  'download_statement_of_accounts': 'Download Statement Of Accounts',
  'download': 'Download',
  'duration': 'Duration',
  'draw': 'Draw',
  'dropdown_placeholder': 'Select from the dropdown or choose one of the options below',
  'drug': 'Drug',
  'drug_administration_time': 'Drug Administration Time',
  'drug_dispensed': 'Drug Dispensed',
  'drug_durations': 'Drug Durations',
  'drug_duration_created': 'Drug duration successfully created',
  'drug_duration_already_exists': 'Drug duration already exists',
  'drug_duration_deleted': 'Drug duration successfully deleted',
  'drug_duration_updated': 'Drug duration successfully updated',
  'drug_dispensation_report': 'Drug Dispensation Report',
  'drug_dispensation_reports': 'Drug Dispensation Reports',
  'drug_dispensation': 'Drug Dispensation',
  'drug_fields_cannot_be_empty_please_fill_out_the_field': 'Drug name cannot be empty. quantity should be minimum 1, price should be a number and start date needs to be filled.',
  'drug_frequencies': 'Drug Frequencies',
  'drug_name': 'Drug Name',
  'drug_tags': 'Drug Tags',
  'drug_type': 'Drug Type',
  'drug_mapping': 'drug mapping',
  'drug_unmapped': 'Drug Unmapped',
  'drug_unmapped_warning': 'Hi %{user}! The drugs that you are prescribing is not mapped. Kindly map it if you would not like to see me again.',
  'drugs_dispensed': 'Drugs Dispensed',
  'drug_prescribed': 'Drug Prescribed',
  'drugs_newly_added': 'Drugs that were added after the January mapping proccess',
  'drugs_prescribed_by_doctors': 'Drug Prescribed by Doctors',
  'drugs_reviewed_by_klinify_team': 'Drugs that does not have a match and are being reviewed by the Klinify team',
  'drugs_require_your_input': 'Drugs that require your input',
  'drugs_successfully_mapped': 'Drugs that are successfully mapped',
  'drugs_not_in_stock_cannot_be_dispensed': 'Drugs that are not in stock cannot be dispensed',
  'duplicate': 'Duplicate',
  'duplicate_ic_detected': 'Duplicate IC Number Detected',
  'duplicate_ic_error': 'This IC number is already registered to another patient and cannot be used again',
  'duplicate_ic_warning': 'This IC number is already registered to another patient. Saving this will result in multiple patients with the same IC number.',
  'dynamic_forms': 'Forms',
  'earn_rm100': 'Earn RM100',
  'earn_upto_rm500': 'Earn up to RM500',
  'earliest_expiry_date': 'Earliest Expiry Date',
  'earliest_supply_date': 'Earliest Supply Date',
  'edit_appointment': 'Edit Appointment',
  'edit_bank_name': 'Edit Bank Name',
  'edit_bill': 'Edit Bill',
  'edit_campaign_set': 'Edit Campaign Set',
  'edit_details': 'Edit Details',
  'edit_document': 'Edit Document',
  'edit_document_template': 'Edit Document Template',
  'edit_drug': 'Edit Drug',
  'edit_encounter_note': 'Edit Encounter Note',
  'edit_flow': 'Edit Flow',
  'edit_campaign': 'Edit Campaign',
  'edit_sms_campaign': 'Edit SMS Campaign',
  'edit_history': 'Edit History',
  'edit_item': 'Edit Item',
  'edit_lab_test_order': 'Edit Lab Test Order',
  'edit_other': 'Edit Other',
  'edit_panel_attached_to_bill': 'Edit Panel Attached To Bill',
  'edit_patient_details': 'Edit Patient Details',
  'edit_patient_profile': 'Edit Patient Profile',
  'edit_payment_type': 'Edit Payment Type',
  'edit_queue_number': 'Edit Queue Number',
  'edit_sales_item': 'Edit Sales Item',
  'edit_x_in_stage': 'Edit %{x} in Stage',
  'edit_scheduled_time': 'Edit Scheduled Time',
  'edit': 'Edit',
  'edit_x': 'Edit %{x}',
  'tele_consult_request_by_x': 'E-Consult Request by %{x}',
  'walk_in_request_by_x': 'Walk-in Request by %{x}',
  'econsult_program_modal_title': 'New E-consultation feature on Klinify!',
  'editable': 'Editable',
  'education': 'Education',
  'email': 'Email',
  'email_address': 'Email Address',
  'enable': 'Enable',
  'enable_patient_outreach_campaigns': 'Enable Patient Outreach Campaigns',
  'enable_pharmaconnect': 'Enable Pharmaconnect',
  'enable_pharmaconnect_modal_ad': 'Enable Pharmaconnect Modal Ad',
  'enable_pharmaconnect_premium_settings': 'Enable Pharmaconnect Premium Settings',
  'enable_pharmaconnect_splash_ad': 'Enable Pharmaconnect Splash Ad',
  'encounter': 'Encounter',
  'encounter_arrival': 'Encounter Arrival',
  'encounter_completion': 'Encounter Completion',
  'encounter_consult_type': 'Encounter Consult Type',
  'encounter_date': 'Encounter Date',
  'encounter_diagnoses': 'Encounter Diagnoses',
  'encounter_doctor': 'Encounter Doctor',
  'encounter_fees': 'Encounter Fees',
  'encounter_flow': 'Encounter Flow',
  'encounter_history_settings': 'Encounter History Settings',
  'encounter_history': 'Encounter History',
  'encounter_label': 'Encounter Label',
  'encounter_location': 'Encounter Location',
  'encounter_not_found': 'Encounter not found',
  'encounter_notes': 'Encounter Notes',
  'encounter_prescription_summary': 'Encounter Prescription Summary',
  'encounter_settings': 'Encounter Settings',
  'encounter_start': 'Encounter Start',
  'encounter_status': 'Encounter Status',
  'encounter_symptoms': 'Encounter Symptoms',
  'encounter_type': 'Encounter Type',
  'encounter_types': '%{encounterLabel} Types',
  'encounter_time': 'Encounter time',
  'encounter_update_success': 'An %{encounterLabel} was updated for the patient.',
  'encounter_updated_error': 'We are unable to save as a newer version of this document has been saved by another user. \n Please note down or take pictures of any information you think is important and then refresh the page. \n Data on this page 𝗪𝗜𝗟𝗟 𝗕𝗘 𝗟𝗢𝗦𝗧 once you refresh the page',
  'encounters_filter_by_date_time_description': 'Show encounters (with finalized bills only) that were registered between:',
  'encounters_label': 'Encounters Label',
  'campaign_sms_will_send_after_job': 'Send %{time_value} %{time_unit}(s) after %{jobName}',
  'end_time': 'End Time',
  'entered_in_error': 'Entered In Error',
  'ensuring_information_desc': 'We need your help with ensuring information keyed into the system is correct.',
  'error_contact_support': 'Error - Please contact Klinify support!',
  'entered-in-error': 'Entered In Error',
  'environment': 'Environment',
  'error_cancelling_order': 'Error cancelling order. Please retry or contact klinify support.',
  'error_detected_during_save': 'Error Detected During Save',
  'error_loading_users': 'There was an error while loading users',
  'error_saving': 'There was an error while saving. Please retry or contact klinify support.',
  'estimate': 'Estimate',
  'error_sending_order_for_patient': 'Error sending order for patient %{patientName}. Please retry or contact klinify support.',
  'error_sending_order': 'Error sending order, retry or contact support',
  'error_drug_duration_updated': 'Something went wrong in updation of drug duration',
  'error_drug_duration_created': 'Something went wrong in creation of drug duration',
  'error': 'Error',
  'ethnicity': 'Ethnicity',
  'example': 'Example',
  'exceeded_on_panel_maximum_claimable_amount': 'Warning: The claimed amount exceeds the maximum claimable amount.',
  'exclusions': 'Exclusions',
  'exit': 'Exit',
  'expand_card_to_see_filter_options': 'Expand this card to see filter options.',
  'expired': 'Expired',
  'expiry_date': 'Expiry Date',
  'expiry_dates': 'Expiry Dates',
  'export_all': 'Export All',
  'export_as_csv': 'Export as CSV',
  'export_claim_invoice': 'Export Claim Invoice',
  'export_documents': 'Export Documents',
  'export_encounter': 'Export Encounter',
  'export_or_print': 'Export/Print',
  'export_results': 'Export Results',
  'export_selected': 'Export Selected',
  'export': 'Export',
  'extended_family_member': 'Extended Family Member',
  'faeces': 'Faeces',
  'false': 'False',
  'family_details': 'Family Details',
  'family_history_creation_success': 'A Family History entry was created for the patient.',
  'family_history_update_success': 'A Family History entry was updated for the patient.',
  'family_history': 'Family History',
  'family_member': 'Family Member',
  'fasting': 'Fasting',
  'father-in-law': 'Father-in-law',
  'father': 'Father',
  'feature_locked_message': 'Oops - this feature is locked!',
  'feature_unavailable_notification': 'This feature is not available.',
  'fetch': 'Fetch',
  'fetching': 'Fetching',
  'fees': 'Fees',
  'female': 'Female',
  'field_cant_be_empty': 'The input field cannot be empty.',
  'file_uploaded_is_invalid': 'File is invalid, please upload a .docx file',
  'file_uploaded': 'File uploaded: %{name}',
  'file': 'File',
  'fill_date_in_correct_format': 'Please ensure that date is in correct format.',
  'fill_email_in_correct_format': 'Please enter valid email',
  'fill_location': 'Please ensure location is filled out.',
  'expiry_date_in_correct_format': 'Please ensure that expiry date is in correct format.',
  'date_in_correct_format': 'Please ensure that date is in correct format.',
  'fill_time_and_in_correct_format': 'Please ensure that time have been filled out and use correct format.',
  'fill_required_fields_and_in_correct_format': 'Please ensure that all required fields have been filled out and having correct format.',
  'fill_required_fields': 'Please ensure that all required fields have been filled out.',
  'fill_sales_item': 'Please ensure sales items are added',
  'filter_at_least_one_of_the_options': 'Filter at least one of the options above to generate the patient list.',
  'filter_options': 'Filter Options',
  'filter': 'Filter',
  'filter_conditions': {
    'is': 'Is',
    'is_not': 'Is not',
    'is_before': 'Is before',
    'is_after': 'Is after',
    'is_on_or_before': 'Is on or before',
    'is_on_or_after': 'Is on or after',
    'is_empty': 'Is empty',
    'is_not_empty': 'Is not empty',
    'is_any_of': 'Is any of',
    'is_none_of': 'Is none of',
    'contains': 'Contains',
    'does_not_contain': 'Does not contain',
  },
  'filtered': 'filtered',
  'filters': 'Filters',
  'finalise_bill': 'Finalise Bill',
  'finalized': 'Finalized',
  'finalised_bill': 'Finalised Bills', // Permissions label
  'find_condition': 'Find Condition',
  'finding': 'Finding',
  'finished_consult': 'Finished Consult',
  'first_time_of_administration': 'First time of Administration',
  'fixed_percent': 'Fixed %',
  'flagged_desc': 'If an allergy is flagged as important, it will be displayed in the red Allergies bar that appears in the patient profile.',
  'flagged': 'Flagged',
  'float': 'Decimal',
  'flow': 'Flow',
  'flow_name': 'Flow Name',
  'flow_name_already_exists': 'Flow with same name already exists',
  'flow_changed': 'Flow changed',
  'flow_updated': 'Flow updated',
  'flows': 'Flows',
  'fluids': 'Fluids',
  'foc_bills': 'FOC Bills',
  'follow_up': 'Follow Up',
  'food': 'Food',
  'for_bank_transfer_bank_name_must_be_filled': 'For bank transfers bank name must be filled.',
  'for_every_doctor_you_refer_to_us': 'for every doctor you refer to us',
  'form_creation_success': 'A form was created for the patient.',
  'form_field_cannot_be_empty': 'Form field cannot be empty.',
  'form_successfully_saved': 'Form successfully saved.',
  'form_templates': 'Form Templates',
  'form_update_success': 'A form was updated for the patient.',
  'form': 'Form',
  'former_spouse': 'Former Spouse',
  'forms': 'Forms',
  'foster_child': 'Foster Child',
  'foster_daughter': 'Foster Daugher',
  'foster_father': 'Foster Father',
  'foster_mother': 'Foster Mother',
  'foster_parent': 'Foster Parent',
  'foster_son': 'Foster Son',
  'fraternal_twin_brother': 'Fraternal Twin Brother',
  'fraternal_twin_sister': 'Fraternal Twin Sister',
  'fraternal_twin': 'Fraternal Twin',
  'frequency': 'Frequency',
  'frequency_is_required': 'Frequency is required',
  'frequency_per_day': 'Frequency Per Day',
  'from': 'From',
  'gender': 'Gender',
  'general_details': 'General Details',
  'generate_invoice_and_print': 'Generate Invoice & Print',
  'generate_invoice': 'Generate Invoice',
  'generate_invoices_and_print': 'Generate Invoices & Print',
  'generate_invoices': 'Generate Invoices',
  'generate': 'Generate',
  'generated_claim_invoices': 'Generated Claim Invoices',
  'generating': 'Generating',
  'gestational_mother': 'Gestational Mother',
  'goal': 'Goal',
  'go_back': 'Go Back',
  'go_to_patients_page': "Go to Patient's page",
  'go_to_bill': 'Go To Bill',
  'go_to_dispensation_billing': 'Dispense/Bill',
  'go_to_settings': 'Go to the Clinic Settings page',
  'grandchild': 'Grandchild',
  'granddaughter': 'Granddaughter',
  'grandfather': 'Grandfather',
  'grandmother': 'Grandmother',
  'grandparent': 'Grandparent',
  'grandson': 'Grandson',
  'graph': 'Graph',
  'great_grandfather': 'Great Grandfather',
  'great_grandmother': 'Great Grandmother',
  'great_grandparent': 'Great Grandparent',
  'grid_view': 'Grid View',
  'group_by_panels_print_only': 'Group By Panels (Print Only)',
  'group_drugs': 'Group Drugs',
  'half-brother': 'Half-brother',
  'half-sibling': 'Half-sibling',
  'half-sister': 'Half-sister',
  'has_waiting_section': 'has "Waiting" section',
  'header': 'Header',
  'help': 'Help',
  'hide_empty_batches': 'Hide Empty Batches',
  'hide_add_notes_templates_text_buttons': 'Hide "Add Notes, Add Templates, Add Text Note" buttons',
  'hide_items_without_expiry_dates': 'Hide Items Without Expiry Dates',
  'hide': 'Hide',
  'high_risk': 'High Risk',
  'history': 'History',
  'holder': 'Holder',
  'holder_address': 'Holder Address',
  'husband': 'Husband',
  'ic_number': 'IC Number',
  'ic_passport_number_of_patient': 'IC / Passport Number of Patient',
  'ic': 'IC Number',
  'identical_twin_brother': 'Identical Twin Brother',
  'identical_twin_sister': 'Identical Twin Sister',
  'identical_twin': 'Identical Twin',
  'image_uploaded': 'Image uploaded: %{name}',
  'image': 'Image',
  'immunization_creation_success': 'An immunization has been added to patient.',
  'immunization_date_info': 'If the patient is unsure of the month the immunization was administered, enter January 1st as the day/month.',
  'immunization_date': 'Date of Immunization',
  'immunization_update_success': 'An immunization has been updated.',
  'immunizations': 'Immunizations',
  'in': 'In',
  'internal_queue_display_settings': 'Internal Queue Display Settings',
  'internal_queue_display_settings_description': 'This refers to the queue display for patients inside the clinic and can be found',
  'interval_in_hours': 'Interval (Hours)',
  'imported': 'Imported',
  'in_progress': 'In Progress',
  'inactive': 'Inactive',
  'includes_sales_and_prescription_items': 'Includes Sales and Prescription items',
  'inclusions': 'Inclusions',
  'incomplete': 'Incomplete',
  'indications': 'Indications',
  'indonesia': 'Indonesia',
  'info': 'Info',
  'initialising_app': 'Initialising Klinify...',
  'inlaw': 'Inlaw',
  'input_name': 'Input Name',
  'input_specific_permissions': 'Input Specific Permissions',
  'input_text': 'Input Text',
  'integer': 'Whole Number',
  'integration_id_does_not_exist': 'Error: Integration ID does not exist',
  'integration_id': 'Integration ID',
  'intolerance': 'Intolerance',
  'interval': 'Interval',
  'interval_is_required': 'Interval is required',
  'invalid_json_object': 'Invalid JSON object',
  'invalid_document_type': 'Invalid document type',
  'invalid_drug_duration_format': 'Invalid drug duration format',
  'inventory_count': 'Inventory Count',
  'inventory_cost': 'Inventory Cost',
  'inventory_change': 'Inventory Change',
  'inventory_hide_warnings': 'Hide Inventory Warnings',
  'inventory_mapping': 'Inventory Mapping',
  'inventory_mapping_help_text': '* Percentages and counts are of bill items created in the last 12 months, starting from the 1st day of the current month',
  'inventory_mapping_progress': 'Inventory Mapping Progress',
  'inventory_mapping_completed_message': 'There are no drugs left. Congratulations!',
  'inventory_reports': 'Inventory Reports',
  'inventory_settings': 'Inventory Settings',
  'inventory_status': 'Inventory Status',
  'inventory': 'Inventory',
  'inventory_item_to_map': 'Inventory Item To Map',
  'inventory_items': 'Inventory Items',
  'invoice_month': 'Invoice Month',
  'invoice_no': 'Invoice No.',
  'invoice_number': 'Invoice Number',
  'invoice_#': 'Invoice #',
  'invoice': 'Invoice',
  'is_this_the_patients_current_panel': 'Is this the patient\'s current panel?',
  'is_this_the_patients_current_policy': 'Is this the patient\'s current policy?',
  'issue_medical_certificate': 'Issue Medical Certificate',
  'issue_time_chit': 'Issue Time Chit',
  'issued_items': 'Issued Items',
  'issuer': 'Issuer',
  'item_added': 'Item Added',
  'item_already_exists': 'Item already exists.',
  'item_deleted': 'Item Deleted',
  'item_is_a_cosmetic_drug': 'Item is a cosmetic drug',
  'item_is_a_type_of_service': 'Item is a type of service',
  'item_is_a_non_drug_item': 'Item is a non-drug item',
  'item_issued': 'Item Issued',
  'item_name': 'Item Name',
  'item_updated': 'Item Updated',
  'items_sold': 'Items Sold',
  'items': 'Items',
  'jobs_resolved': 'Job(s) resolved',
  'jobs_canceled': 'Job(s) canceled',
  'jobs_cancel_failed': 'Job(s) cancel failed',
  'jobs_resolve_failed': 'Job(s) resolve failed',
  'job_status_changed': 'Job status changed',
  'jobs_status_change_failed': 'Job(s) status change failed',
  'klinify_updated_desc': 'Klinify has been updated. Please view the release notes for an overview of the changes.',
  'klinify_updated': 'Klinify Updated',
  'lab_deleted': 'Lab deleted',
  'lab_requests': 'Lab Requests',
  'lab_results': 'Lab Results',
  'lab_sale_price': 'Lab Sale Price',
  'lab_test_cancelled_successfully': 'Lab test "%{labTestName}" cancelled successfully.',
  'lab_test_cant_be_deleted_no_permission': 'You don\'t have permission to delete this lab test.',
  'lab_test_cant_be_deleted': 'This lab test cannot be deleted.',
  'lab_test_cant_be_edited_no_permission': 'You don\'t have permission to edit this lab test.',
  'lab_test_deleted': 'Lab Test Deleted',
  'lab_test_ordered_successfully': 'Lab test \"%{labTestName}\" ordered successfully!', // eslint-disable-line no-useless-escape
  'lab_test_request_added': 'You have successfully ordered the %{labTestName} lab test for %{patientName}',
  'lab_test_request_updated': 'You have successfully updated the %{labTestName} lab test for %{patientName}',
  'lab_test_requested': 'Lab Test Requested',
  'lab_test_sent_successfully': 'Lab test sent successfully.',
  'lab_test': 'Lab Test',
  'lab_test_id': 'Lab Test ID',
  'lab_tests_line_item': 'Lab Tests - Line Item',
  'lab_tests': 'Lab Tests',
  'lab_vendor': 'Lab Vendor',
  'lab': 'Lab',
  'laboratory': 'Laboratory',
  'laboratories': 'Laboratories',
  'labs': 'Labs',
  'landscape': 'Landscape',
  'large': 'Large',
  'largest': 'Largest',
  'last_amended_by': 'Last Amended By',
  'last_edited_on_date': 'Last edited on %{date} by %{user}.',
  'last_edited': 'Last Edited',
  'last_encounter_date': 'Last Encounter Date',
  'last_occurrence_date': 'Last Occurrence Date',
  'last_saved_at': 'Last Saved At',
  'last_update': 'Last Update',
  'last_updated': 'Last Updated',
  'latest_vitals': 'Latest Vitals',
  'latest_patient_response': 'Latest patient response',
  'list_of_lab_tests': 'List of Lab Tests',
  'list_of_patients_with_payments_outstanding': 'List of Patients with Payments Outstanding',
  'load_preset': 'Load Preset',
  'loading_bill': 'Loading Bill...',
  'loading...': 'Loading...',
  'loading': 'Loading',
  'loan_in': 'Loan In',
  'loan_out': 'Loan Out',
  'loan_to': 'Loan To',
  'location': 'Location',
  'locations': 'Locations',
  'logging_in': 'Logging In',
  'label_for_unspecified_medication_allergy': 'Drugs in these sub-category will not trigger the Drug Allergy Checker Alert',
  'label_for_active_ingredient_medication_allergy': 'Active Ingredients',
  'label_for_drugs_medication_allergy': 'Drug - Mapped',
  'login_failed_warning': 'The username or password that you entered is incorrect.',
  'login_title': 'Welcome back to Klinify!',
  'login_subtitle': 'Log in to your account to start seeing your patients.',
  'login_subtext': 'Please click here if you need help with your password',
  'login_unauthorized_warning': 'The login details you entered were incorrect.',
  'login': 'Login',
  'logout': 'Logout',
  'low_inventory_warning_threshold_desc': 'When the inventory level for an item is below this count you will be warned when trying to add that item to a bill.',
  'low_inventory_warning_threshold': 'Low Inventory Warning Threshold',
  'low_risk': 'Low Risk',
  'low_stock_warning': 'The stock of this item is low. Please check before dispensing.',
  'low_stock': 'Low stock',
  'low': 'Low',
  'make_payment': 'Make Payment',
  'male': 'Male',
  'mal_number': 'MAL Number',
  'malaysia': 'Malaysia',
  'manufacturer': 'Manufacturer',
  'manufacturer_address': 'Manufacturer Address',
  'manual_mapping': 'Manual Mapping',
  'map_all_skus_before_saving': 'Please map the highlighted SKUs before you proceed',
  'mapped': 'Mapped',
  'mapped_drug': 'Mapped Drug',
  'mapped_manufacturer': 'Mapped Manufacturer',
  'mapped_status': 'Mapped Status',
  'master_drug_dispensed': 'Master Drug Dispensed',
  'master_drug_name_settings': 'Master Drug Name Settings',
  'master_drug_prescribed': 'Master drug prescribed',
  'maternal_aunt': 'Maternal Aunt',
  'maternal_cousin': 'Maternal Cousin',
  'maternal_grandfather': 'Maternal Grandfather',
  'maternal_grandmother': 'Maternal Grandmother',
  'maternal_grandparent': 'Maternal Grandparent',
  'maternal_great-grandfather': 'Maternal Great Grandfather',
  'maternal_great-grandmother': 'Maternal Great Grandmother',
  'maternal_great-grandparent': 'Maternal Great Grandparent',
  'maternal_uncle': 'Maternal Uncle',
  'maintenance_landing_page': 'Klinify is currently facing an issue and we are currently working hard to get our services back online. If you need assistance, please contact our support team at support@klinify.com.We apologize for the inconvenience caused.',
  'maximum_claimable_amount_exceeded': 'Maximum Claimable Amount Exceeded',
  'maximum_claimable_amount': 'Maximum Claimable Amount',
  'mc_days': 'Medical Certificate Days',
  'mc_details': 'Details',
  'mc_end_date': 'Medical Certificate End Date',
  'mc_internal_clinic_id': 'Medical Certificate Serial Number',
  'mc_notes': 'Medical Certificate Notes',
  'mc_reason': 'Medical Certificate Reason',
  'mc_start_date': 'Medical Certificate Start Date',
  'mc_time_chit': 'MC/Time Chit',
  'MC': 'MC',
  'medadvisor_campaigns': 'MedAdvisor Campaigns',
  'medadvisor_campaigns_edit_rule_errormessage': 'Note: As this is a MedAdvisor Campaign, campaign rules and messages cannot be edited.',
  'medadvisor_campaigns_update': 'MedAdvisor Campaigns Update',
  'medical_certificate_creation_success': 'A Medical Certificate has been created for the patient.',
  'medical_certificate_history': 'Medical Certificate History',
  'medical_certificate_reasons': 'Medical Certificate Reasons',
  'medical_certificate': 'Medical Certificate',
  'medical_certificates': 'Medical Certificates',
  'medical_complaint': 'Medical Complaints',
  'medical_complaints': 'Medical Complaints',
  'medical_leave_for': 'Medical leave for %{days} days from %{startDate} to %{endDate}.',
  'medical_leave': 'Medical Leave',
  'medication': 'Medication',
  'multiple_locations_can_be_set': 'Multiple locations can be set',
  'merge_warning': 'While trying to save your %{type} we found a different version in the database. We have done our best to merge the two, however we recommend that you manually check if the result was correct.',
  'merge': 'Merge',
  'metric_creation_success': 'Metric Creation Success',
  'metric_history': 'Metric History',
  'metric_types': 'Vitals Types', // Permissions label
  'metrics': 'Metrics',
  'message_added_sucessfully': 'Message added sucessfully',
  'minutes': 'minutes',
  'misc_settings': 'Misc. Settings',
  'mismatch': 'Mismatch',
  'missing': 'Missing',
  'mmc_id': 'MMC Id',
  'mmc_number': 'MMC Number',
  'modify': 'Modify',
  'modify_batch_info': 'Modify batch Information',
  'modify_stock_for': 'Modify Stock For %{sku}',
  'months': 'Months',
  'medication_sub_category': 'Medication Sub-Category',
  'more_details': 'More Details',
  'mother-in-law': 'Mother-in-law',
  'mother': 'Mother',
  'move': 'Move',
  'move_to_billing': 'Move to billing',
  'move_to_in_progress': 'Move to In Progress',
  'move_to': 'Move to',
  'multiple': 'Multiple',
  'mykad_url': 'Mykad URL',
  'n_a': 'N/A',
  'n_unsaved_documents': '%{smart_count} unsaved document |||| %{smart_count} unsaved documents',
  'name_cannot_be_empty_please_fill_out_the_field': 'Name cannot be empty. Please fill out the field.',
  'please_select_valid_verified_drug': 'Please select valid verfied drug from which patient is allergic to',
  'name_of_document_template': 'Name of Document Template',
  'name_of_patient': 'Name of Patient',
  'name': 'Name',
  'name_on_box': 'Name on box',
  'nationality_label': 'Nationality Label',
  'nationality': 'Nationality',
  'natural_brother': 'Natural Brother',
  'natural_child': 'Natural Child',
  'natural_daughter': 'Natural Daughter',
  'natural_father_of_fetus': 'Natural Father of Fetus',
  'natural_father': 'Natural Father',
  'natural_mother of fetus': 'Natural Mother of Fetus',
  'natural_mother': 'Natural Mother',
  'natural_parent': 'Parent',
  'natural_sibling': 'Natural Sibling',
  'natural_sister': 'Natural Sister',
  'natural_son': 'Natural Son',
  'negative_stock': 'Negative Stock',
  'nephew': 'Nephew',
  'nett_summary': 'Nett Summary',
  'new': 'New',
  'new_appointment': 'New Appointment',
  'new_campaign_set': 'New Campaign Set',
  'not_verified_warings': 'Drugs that have not been mapped/verified will not be checked against our Drug Allergy Checker.\nPlease make sure that your drugs have been mapped and verified',
  'new_count': 'New Count',
  'new_count_field_cannot_be_negative': '"New Count" value cannot be negative',
  'new_count_field_is_required': '"New Count" is required',
  'new_count_must_be_less_than_current_count': '"New Count" value must be lower than "Current Count"',
  'new_claim_invoices': 'New Claim Invoices',
  'new_note': 'New Note',
  'new_password_must_be_at_least_8_characters_long': 'The new password must be at least 8 characters long.',
  'new_password': 'New Password',
  'new_patient_registration': 'New Patient Registration',
  'new_queue_number': 'New Queue Number',
  'new_queue_number_desc': 'This change will also be reflected in the Queue Display',
  'new_tele_consult_request_title': 'New E-Consultation request',
  'new_tele_consult_request_details': '%{patient_name} has requested for a E-Consult appointment on %{date} at %{time}',
  'new_total': 'New Total',
  'new_walk_in_request_title': 'New Walk-in request',
  'new_walk_in_request_details': '%{patient_name} has requested for a Walk-In appointment on %{date} at %{time}',
  'newest_first': 'Newest First',
  'newly_added': 'Newly Added',
  'next_appointment': 'Next Appointment',
  'next_of_kin_address': 'Next of Kin Address',
  'next_of_kin_phone': 'Next of Kin Phone',
  'next_of_kin': 'Next of Kin',
  'next_page': 'Next Page',
  'next': 'Next',
  'niece_nephew': 'Niece/Nephew',
  'niece': 'Niece',
  'no_scheduled_consults': 'No Scheduled Consults',
  'no_appointments_requested': 'No Appointments requested',
  'not_allowed_to_edit_protected_keys': 'Not allowed to edit protected keys such as _id, _rev, type, created_by and edited_by',
  'not_uploaded': 'Not Uploaded',
  'no_need_to_add_other_protected_keys': 'No need to add other protected keys such as _rev, created_by or edited_by',
  'no_accounts_receivable': 'No Accounts Receivable',
  'no_allergies_added': 'No allergies have been created for this patient.',
  'no_action_taken': 'No action taken',
  'no_assets_attached': 'No assets attached!',
  'no_available_batch_found_for_the_sku': 'No Available batch found for the SKU',
  'no_bank_has_been_set': 'No bank details have been added.',
  'no_batch_found_for_the_searched_sku': 'No batch found for the searched SKU',
  'no_campaign_found': 'No campaign found',
  'no_jobs_found': 'No Jobs found',
  'no_casenotes_created': 'No casenotes have been created yet.',
  'no_casenotes_created_create_one': 'No casenotes have been created yet. Add a casenote using the add button in the bottom right corner of the app.',
  'no_casenotes_in_category': 'No casenotes in category.',
  'no_collected_specimens_on_test': 'No specimens collected on this test.',
  'no_comments_added': 'No comments have been added for this patient.',
  'no_condition_selected': 'No diagnosis selected.',
  'no_consults_found': 'No consults found.',
  'no_coverage_label': 'No Coverage Label',
  'no_conversation_found': 'No conversation found',
  'no_custom_notes': 'No custom notes have been created for this patient.',
  'no_currently_dispatching_batch': 'No preferred dispensing batch set for this sku',
  'no_diagnoses_today': 'There are no diagnoses for today.',
  'no_diagnoses': 'There are no diagnoses for this patient.',
  'no_doctor_assigned': 'No Doctor Assigned',
  'no_doctors_created': 'No Doctors Created',
  'no_drug_durations_created': 'No Drug Durations Created',
  'no_document_templates_created': 'No Document Templates created.',
  'no_document_templates_found': 'No Document Templates found.',
  'no_documents_created': 'No Documents Created',
  'no_dosage_set': 'No dosage set',
  'no_drugs_matching_query': 'No drugs match the current query.',
  'no_drugs': 'No Drugs',
  'no_encounter_flows_warning': 'You have not yet added any Encounter flows to the Klinify system. Please visit the link below to add an Encounter flow.',
  'no_family_history_added': 'No family history added.',
  'no_file_has_been_selected': 'No file has been selected',
  'no_file_selected': 'No file selected',
  'no_filter_result': 'No results based on your filter criteria',
  'no_generated_claim_invoices': 'There are no claim invoices matching your selection. To create a new Claim Invoice click the Generate Claim Invoice button in the menu on the left of the screen.',
  'no_immunizations_added': 'No immunizations added.',
  'no_incomplete_encounters': 'No incomplete %{encountersLabel}.',
  'no_integration_id_set': 'Error: No integration ID set for this supplier integration',
  'no_inventory_reports': 'No Inventory Reports',
  'no_items_added': 'No items have been added for this patient.',
  'no_items_matched_your_selection': 'No items match your selection.',
  'no_items': 'There are no items available.',
  'no_lab_tests_created': 'No Lab Tests Created.',
  'no_lab_tests_found': 'No Lab Tests Found.',
  'no_lab_tests_ordered': 'No lab tests ordered.',
  'no_laboratories_created': 'No Laboratories Created.',
  'no_location_set': 'No location set',
  'no_locations_warning': 'You have not yet added any Locations to the Klinify system. Please visit the link below to add a Location.',
  'no_mc_issued': 'No MC Issued',
  'no_medical_certificates': 'There are no medical certificates for this patient.',
  'no_medical_complaints': 'No medical complaints have been created for this patient.',
  'no_medication_dispensed': 'No medication dispensed.',
  'no_medication_prescribed': 'No medication prescribed.',
  'no_medication': 'No current medication has been created for this patient.',
  'no_metric_entries': 'No Metric Entries',
  'no_metrics_history': 'No Metrics History',
  'no_of_bills': 'No. of Bills',
  'no_of_days': 'No. of Days',
  'no_outstanding_invoices': 'No outstanding invoices',
  'no_panel': 'No Panel',
  'no_patient_payments_outstanding': 'There are no patient payments outstanding.',
  'no_patients_matching_query': 'There are no patients that match the search term %{query}.',
  'no_patients_scheduled_today': 'There are no patients scheduled for today.',
  'no_patients': 'No patients.',
  'no_payment_types_created': 'No Payment Types Created',
  'no_payments_found': 'No payments found.',
  'no_payments_have_been_made': 'No payments have been made yet.',
  'no_period_set': 'No period set',
  'no_permissions_set': 'No permissions have been set for this user. Please contact Klinify for assistance.',
  'no_practitioner_selected': 'No practitioner has been selected yet.',
  'no_practitioners_warning': 'You have not yet added any Doctors to the Klinify system. Please visit the link below to add a Doctor.',
  'no_precautions': 'There are no special precautions for this prescription.',
  'no_previous_bills': 'No Previous Bills',
  'no_previous_consultations': 'No Previous Consultations',
  'no_procedure_requests': 'There are no procedure requests for this patient.',
  'no_procedure_selected': 'No procedure has been selected yet.',
  'no_referrals': 'There are no referrals for this patient.',
  'no_result_found_for': 'No result found for',
  'no_sales_items': 'No Sales Items',
  'no_sales_items_added': 'No sales items added',
  'no_special_instructions': 'No special instructions',
  'no_starred_casenotes': 'No starred casenotes.',
  'no_stock': 'No stock',
  'no_stock_to_dispense': 'No stock to dispense',
  'no_stock_warning': 'There is no stock for this item. Please check before dispensing.',
  'no_supplier_integrated': 'No supplier account added. To add a supplier, please contact Klinify support.',
  'no_supplies_received_found': 'No Supplies Found',
  'no_templates_available': 'There are no templates available in this category.',
  'no_time_chit_issued': 'No Time Chit Issued',
  'no_transactions': 'No transactions.',
  'no_unclaimed_items_available': 'No unclaimed items available',
  'no_unsynced_documents': 'No unsynced documents',
  'no_user_group': 'No user group',
  'no_user_groups_created': 'No User Groups have been created yet.',
  'no_vitals_created': 'No Vitals have been created yet.',
  'no_vitals_history': 'There are no entries for this vital.',
  'no_vitals_types_created': 'No vitals types have been created yet.',
  'no': 'No',
  'no_changes': 'No Changes',
  'non_drug_item': 'Non-Drug Item',
  'non_drug_mapping_title': 'Is the item cosmetic drug, a type of service or a non-drug item?',
  'non_drug_mapping_warning': 'Please only proceed if the item you are adding is not a drug.',
  'non_drug_item_examples': 'Examples of non-drug items: Gauze, Needles, Bandages, Face masks',
  'none': 'None',
  'none_of_the_above': 'None of the above',
  'normal': 'Normal',
  'not_a_drug': 'Not a Drug',
  'not_fasting': 'Not Fasting',
  'not_filled': 'Not Filled',
  'not_mapped': 'Not Mapped',
  'not_urgent': 'Not Urgent',
  'note_x_of_y': 'Note %{x} of %{y}',
  'note': 'Note',
  'notes_section': 'Notes Section',
  'notepad': 'Notepad',
  'notes_batch': 'Notes (Batch)',
  'notes_for_vendor': 'Notes for vendor',
  'notes_line_item': 'Notes (Line Item)',
  'notes': 'Notes',
  'now_serving': 'Now Serving',
  'number_of_payments_for_this_bill': 'There %{isAre} %{number} payment(s) for this bill. Please select the payment(s) that you would like to print receipt(s) for.',
  'units_available': '%{dispensationUnit}s available',
  'number': 'Number',
  'number_of_bill_items': '# of Bill Items*',
  'occupation': 'Occupation',
  'offline_documents_successfully_saved': 'Offline documents successfully saved.',
  'offline_message': 'This part of the app is not available while offline.',
  'offline_mode': 'Offline Mode',
  'offline': 'Offline',
  'okay': 'Okay',
  'oldest_first': 'Oldest First',
  'on_duty': 'On Duty',
  'onset_date': 'Onset Date',
  'open_active_casenotes_slider': 'Open Active Casenotes Slider',
  'open_items': 'Open Items',
  'operations_summary': 'Operations Summary',
  'or': 'Or',
  'order': 'Order',
  'order_lab_test': 'Order Lab Test',
  'order_of_discounts_and_charges': 'Order of Discounts & Charges',
  'order_printed': 'Order Printed',
  'order_rejected': 'Order Rejected',
  'ordered_tests': 'Ordered Tests',
  'other': 'Other',
  'others': 'Others',
  'other_available_batches_in': 'Other Available Batches in %{sku}',
  'other_details': 'Other Details',
  'other_reasons': 'Other reason(s)',
  'otp_empty_warning': 'OTP cannot be empty.',
  'otp_failed_warning': 'The OTP that you entered is incorrect.',
  'otp_input_description': 'A One Time Password (OTP) is available at %{otpURL}',
  'otp': 'OTP',
  'out': 'Out',
  'ok': 'Ok',
  'outbreak_banner_default_title': 'Fighting COVID-19 together',
  'outbreak_name': 'COVID-19',
  'outbreak_guidelines': 'Guidelines',
  'outbreak_guidelines_title': 'Guideline COVID-19 Management',
  'outbreak_guidelines_fetch_err': 'Guidelines loading failed, please try again',
  'outbreak_stats': 'Statistics',
  'outbreak_stats_fetch_err': 'Error fetching statistics list, please try again later',
  'outbreak_stats_confirmed': 'Confirmed',
  'outbreak_stats_deaths': 'Deaths',
  'outbreak_stats_discharged': 'Discharged',
  'outbreak_stats_region': 'State',
  'outbreak_stats_not_found': 'No stats found',
  'outbreak_stats_update_prepend': 'Latest statistic of COVID-19 in Malaysia as of ',
  'outbreak_stats_disclaimer': 'Disclaimer: Our numbers include state government data. May not tally with MOH. Based on ',
  'outbreak_stats_disclaimer_link_label': 'here',
  'outbreak_labs': 'Directory',
  'outbreak_labs_list': 'Labs',
  'outbreak_labs_hotlines': 'Hotlines',
  'outbreak_labs_screening': 'Screening hospitals',
  'outbreak_labs_screening_admitting': 'Screening & Admitting hospitals',
  'outbreak_labs_search_placeholder': 'Search by state',
  'outbreak_labs_fetch_error': 'Error fetching labs list, please try again later',
  'outbreaks_labs_disclaimer': 'These are the list of private lab in Malaysia offering the service for collection of samples for Covid-19 testing from the homes of individuals who wish to test for the disease but do not meet the patient under investigation (PUI) criteria.',
  'outbreak_brochures': 'Brochures',
  'outbreak_brochures_fetch_err': 'Error fetching brochures, please try again later',
  'outbreak_research': 'Research',
  'outbreak_research_fetch_err': 'Error fetching research, please try again later',
  'outbreak_modal_title': 'Fighting COVID-19 together',
  'outbreak_modal_text': 'In light of the Covid-19 pandemic, we are introducing a "Covid-19" tab that will allow you to easily access Covid-19 related information immediately. We will regularly update this tab with recent guidelines, statistics, and brochures on COVID-19 that you can share with your patients. If you have suggestions on other information that would help you, please let us know. The Klinify team is ever grateful to all the doctors, nurses and healthcare staff who are in the frontlines fighting this. Thank you for your hard work!',
  'outbreak_modal_button_label': 'Check it out',
  'overall': 'Overall',
  'outstanding': 'Outstanding',
  'overview': 'Overview',
  'owed_by_panel': 'Owed By Panel',
  'page_not_found': 'The page you tried to access could not be found.',
  'page_settings': 'Page Settings',
  'paid': 'Paid',
  'pan': 'Pan',
  'panel_attached_to_bill': 'Panel Attached To Bill',
  'panel_categories': 'Panel Categories',
  'panel_category_desc': 'This categorization will appear in the claim invoice',
  'panel_category_form_error_empty_price': 'The category fields cannot be empty.',
  'panel_coverage': 'Panel Coverage',
  'panel_drug_price_form_error_empty_panels': 'The panels fields cannot be empty.',
  'panel_drug_price_form_error_empty_price': 'The price fields cannot be empty.',
  'panel_employee': 'Panel Employee',
  'panel_id': 'Panel ID',
  'panel_name': 'Panel Name',
  'panel_or_cash': 'Panel/Cash',
  'panel_payment_history': 'Panel Payment History',
  'panel_payment': 'Panel Payment',
  'panel_payments': 'Panel Payments',
  'panel_price': 'Panel Price',
  'price_update_message': 'A price has been updated due to the change in the panel status for this bill.\n\nPlease find the item name, updated price and the reason for the change below.\n\n',
  'panel_policy_name_attached_to_bill': 'Panel - Policy Name Attached To Bill',
  'panel_relation': 'Panel Relation',
  'panel': 'Panel',
  'panels_assigned_to_this_category': 'Panels Assigned To This Category',
  'panels_assigned_to_this_price': 'Panels Assigned To This Price',
  'panels_with_unclaimed_bills': 'Panels With Unclaimed Bills',
  'parent_in-law': 'Parent-in-law',
  'parent': 'Parent',
  'password_empty_warning': 'Password cannot be empty.',
  'password_successfully_changed': 'The password was successfuly changed.',
  'password': 'Password',
  'passwords_dont_match': 'The passwords you entered do not match.',
  'past_bill_preset_sales_items': 'Past Bill Preset Sales Items',
  'past_encounters_conditions': 'Conditions', // Permissions label
  'past_encounters_medical_certificates': 'Medical Certificates', // Permissions label
  'past_encounters_notes': 'Notes', // Permissions label
  'past_encounters_prescriptions': 'Prescriptions', // Permissions label
  'past_encounters_sales_items': 'Sales Items', // Permissions label
  'past_encounters_time_chits': 'Time Chits', // Permissions label
  'past_encounters_view_cost_price': 'View Cost Price',
  'past_encounters_lab_tests': 'Lab Tests',
  'past_encounters': 'Past Encounters', // Permissions label
  'past_prescriptions': 'Past Prescriptions',
  'paternal_aunt': 'Paternal Aunt',
  'paternal_cousin': 'Paternal Cousin',
  'please_select_valid_active_ingredient': 'Please select valid active ingredients from which patient is allergic to',
  'paternal_grandfather': 'Paternal Grandfather',
  'paternal_grandmother': 'Paternal Grandmother',
  'paternal_grandparent': 'Paternal Grandparent',
  'paternal_great-grandfather': 'Paternal Great Grandfather',
  'paternal_great-grandmother': 'Paternal Great Grandmother',
  'paternal_great-grandparent': 'Paternal Great Grandparent',
  'paternal_uncle': 'Paternal Uncle',
  'patient_address': 'Patient Address',
  'patient_cannot_be_queued': 'Patient cannot be queued',
  'patient_cannot_be_scheduled': 'Patient cannot be scheduled for today',
  'patient_alert_details': 'Patient Alert Details',
  'patient_alert_saved': 'Patient alert saved successfully.',
  'patient_alert': 'Patient Alert',
  'patient_allergies': 'Allergies', // Permissions label
  'patient_allergy_summary': 'Patient Allergy Summary',
  'patient_arrived': 'Patient Arrived',
  'patient_billing_history': 'Patient Billing History',
  'patient_campaigns': 'Patient Campaigns',
  'patient_card': 'Patient Card',
  'patient_email_address': "Patient's Email Address",
  'update_patient_email_address': 'Update Patient Email Address',
  'update_email': 'Update Email',
  'patient_case_id_autogenerate_desc': 'This will automatically generate a unique, random 6 digit Case ID for a patient when they are being registered.',
  'patient_case_id_autogenerate_prefix_desc': 'Any text entered here will be used as a prefix for the generated Case ID. E.g. If the prefix is CASE- then a generated Case ID may look like CASE-000000 or CASE-452156.',
  'patient_case_id_autogenerate_prefix': 'Autogenerated Case ID Prefix',
  'patient_case_id_autogenerate': 'Autogenerate Case ID',
  'patient_case_id': 'Patient ID',
  'patient_casenotes': 'Casenotes', // Permissions label
  'patient_citizenship': 'Patient Citizenship',
  'patient_creation_fail': 'Patient %{name} could not be created. Please contact Klinify if the problem persists.',
  'patient_creation_success': 'Patient %{name} successfully created.',
  'patient_current_employer': 'Patient Current Employer',
  'patient_data_creation_success': '%{type} has been created for this patient.',
  'patient_data_update_success': '%{type} has been updated for this patient.',
  'patient_details': 'Patient Details',
  'patient_dob': 'Patient DOB',
  'patient_edit_success': 'Patient %{name} successfully updated.',
  'patient_email': 'Patient Email',
  'patient_phone': 'Patient Phone Number',
  'patient_ethnicity': 'Patient Ethnicity',
  'patient_fasting': 'Patient Fasting',
  'patient_have_appointment_warning': 'The patient cannot be added to the queue as they are either already queued or have an appointment set for today.',
  'patient_cant_be_scheduled_warning': 'The patient cannot be schedule for today as they are either already queued or have an appointment already set for today.',
  'patient_ic': 'Patient IC',
  'patient_id': 'Patient ID',
  'patient_is_unfit_for': 'Patient is',
  'patient_list': 'Patient List',
  'patient_metrics': 'Vitals', // Permissions label
  'patient_name': 'Patient Name',
  'patient_nationality': 'Patient Nationality',
  'patient_not_found': 'Patient not found',
  'patient_notes': 'Patient Notes',
  'patient_occ': 'Patient Occupation',
  'patient_offline_warning': 'This patient is not synced offline. Restore internet connection to view full patient history.',
  'patient_options_citizenship': 'Citizenship Options',
  'patient_options_coverage_payor': 'Coverage Payor Options',
  'patient_options_ethnicity': 'Ethnicity Options',
  'patient_options_sex': 'Sex Options',
  'patient_overview_enable_sound': 'Enable Alert Sound on Patient Overview',
  'patient_overview_sound': 'Patient Overview Alert Sound',
  'patient_overview': 'Patient Overview',
  'patient_page_cards': 'Patient Page Cards',
  'patient_page_settings': 'Patient Page Settings',
  'patient_page': 'Patient Page',
  'patient_panel_employee': 'Patient Panel Employee',
  'patient_panel_name': 'Patient Panel Name',
  'patient_panel_policy_holder': 'Patient Panel Policy Holder',
  'patient_panel_relation': 'Patient Panel Relation',
  'patient_pays': 'Patient Pays',
  'patient_postal_code': 'Patient Postal Code',
  'patient_profile_settings': 'Patient Profile Settings',
  'patient_profile': 'Patient Profile',
  'patient_queue_sound': 'Patient Queue Alert Sound',
  'patient_queue': 'Patient Queue',
  'patient_receipt': 'Patient Receipt',
  'patient_search_settings': 'Patient Search Settings',
  'patient_sex': 'Patient Sex',
  'patient_tags_options': 'Patient Tags Options',
  'patient_tags': 'Patient Tags',
  'patient_tel': 'Patient Telephone',
  'patient_type': 'Patient Type',
  'patient_identifier_to_use': 'patient identifier to use',
  'patient': 'Patient',
  'patients_search_fetch_err': 'Error fetching patients list, please try again later',
  'patients_scheduled': 'Here are your patients for Today (%{date}):',
  'patients': 'Patients',
  'patients_last_visit_more_than_days_ago': 'Patient\'s last visit more than __ days ago',
  'patients_registered': 'New Patients',
  'patients_who_have_visited_in_the_past': 'Patients who have visited in the past',
  'who_have_visited_in_the_past': 'who have visited in the past',
  'payable_amount': 'Payable Amount',
  'payment_amount': 'Payment Amount',
  'payment_by': 'Payment By',
  'payment_date': 'Payment Date',
  'payment_from': 'Payment From',
  'payment_history': 'Payment History',
  'payment_method': 'Payment Method',
  'payment_notes': 'Payment Notes',
  'payment_received': 'Payment Received',
  'patients_who_have_visited_recently': 'Patients who visited recently',
  'payment_recorded': 'Payment Recorded',
  'payment_type': 'Payment Type',
  'payment_via': 'Payment Via',
  'payment_voided': 'Payment voided',
  'payment_types': 'Payment Types',
  'payment_type_already_exists': 'Payment type already exists',
  'payment_type_saved': 'Payment type saved',
  'payments_must_not_exceed_total': 'Payments must not exceed the total amount of patient owed fees.',
  'payments_must_not_have_empty_fields': 'Amount cannot be 0 or empty',
  'payments_outstanding': 'Payments Outstanding',
  'payments_received_amount_exceeded': 'Payments Received Amount Exceeded',
  'payments_received': 'Payments Received',
  'payment': 'Payment',
  'payments': 'Payments',
  'payor_not_found': 'Coverage payor not found',
  'pending': 'Pending',
  'pending_review': 'Pending review',
  'period': 'Period',
  'percent_of_bill_items': '% of Bill Items*',
  'perm_add_button_lab_tests': '\"Add\" button', // eslint-disable-line no-useless-escape
  'perm_add_button_prescriptions_items': '\"Add\" button', // eslint-disable-line no-useless-escape
  'perm_add_button_sales_items': '\"Add\" button', // eslint-disable-line no-useless-escape
  'perm_claimable_checkbox_lab_tests_line_item': '\"Claimable\" checkbox', // eslint-disable-line no-useless-escape
  'perm_claimable_checkbox_prescriptions_items_line_item': '\"Claimable\" checkbox', // eslint-disable-line no-useless-escape
  'perm_claimable_checkbox_sales_items_line_item': '\"Claimable\" checkbox', // eslint-disable-line no-useless-escape
  'perm_name_field_lab_tests_line_item': '\"Name\" field', // eslint-disable-line no-useless-escape
  'perm_name_field_prescriptions_items_line_item': '\"Name\" field', // eslint-disable-line no-useless-escape
  'perm_name_field_sales_items_line_item': '\"Name\" field', // eslint-disable-line no-useless-escape
  'perm_price_field_lab_tests_line_item': '\"Price\" field', // eslint-disable-line no-useless-escape
  'perm_price_field_prescriptions_items_line_item': '\"Price\" field', // eslint-disable-line no-useless-escape
  'perm_price_field_sales_items_line_item': '\"Price\" field', // eslint-disable-line no-useless-escape
  'perm_quantity_field_lab_tests_line_item': '\"Quantity\" field', // eslint-disable-line no-useless-escape
  'perm_quantity_field_prescriptions_items_line_item': '\"Quantity\" field', // eslint-disable-line no-useless-escape
  'perm_quantity_field_sales_items_line_item': '\"Quantity\" field', // eslint-disable-line no-useless-escape
  'perm_view_edit_cost_price_right_side_bar': '\"View / Edit Cost Price\" button', // eslint-disable-line no-useless-escape
  'perm_x_delete_button_lab_tests_line_item': '\"X (Delete)\" button', // eslint-disable-line no-useless-escape
  'perm_x_delete_button_prescriptions_items_line_item': '\"X (Delete)\" button', // eslint-disable-line no-useless-escape
  'perm_x_delete_button_sales_items_line_item': '\"X (Delete)\" button', // eslint-disable-line no-useless-escape
  'permissions_updated': 'The user permissions were successfully updated.',
  'pharmaconnect': 'PharmaConnect',
  'pharmaconnect_view_brochure': 'View brochure',
  'pharmaconnect_brand_not_found': 'Pharmaconnect brand not found',
  'pharmaconnect_brochure_choose': 'Choose a brochure',
  'pharmaconnect_contact_cta': 'Get a callback',
  'pharmaconnect_contact_header': 'Get a sales representative to get in touch with you.',
  'pharmaconnect_document_not_found': 'Pharmaconnect document not found',
  'pharmaconnect_disclaimer': 'Your clinic contact and your name would be sent.',
  'pharmaconnect_email_failed': 'Email sending failed, please try again later!',
  'pharmaconnect_email_sent': 'Email sent! A representative will be in touch shortly.',
  'pharmaconnect_modal_title': 'Introducing Pharma Connect!',
  'pharmaconnect_modal_text': 'View new information about key products from our partners in Malaysia. Our goal is to bring relevant information to you in a dedicated tab where you can view the content when you want to.',
  'pharmaconnect_modal_about': 'About',
  'pharmaconnect_modal_button_label': 'Take me there',
  'pharmaconnect_settings': 'Pharmaconnect Settings',
  'phone_number': 'Phone Number',
  'please_click_here_to_restart_app': 'Please click here to restart the app.',
  'please_contact_your_vendor_directly_to_cancel_this_order': 'Please contact your vendor directly to cancel this order.  This cancelled status is for record keeping purposes only and we are unable to contact this vendor for you',
  'please_give_additional_details': 'Please give additional details',
  'please_filter_atleast_one_option': 'Please filter at least one of the options above to begin.',
  'please_search_for_drug_you_want_to_add': 'Please search for the drug you want to add in the "%{label}" field above to continue',
  'please_fill_appointment_details': 'Please fill required appointment details',
  'please_fill_patient_details': 'Please fill patient details',
  'please_select_a_doctor': 'Please Select a Doctor.',
  'please_select_a_location': 'Please Select a Location',
  'please_select_encounter_type': 'Please Select Encounter Type.',
  'please_select_encounter_flow': 'Please Select Encounter Flow.',
  'please_select_location': 'Please Select Location.',
  'please_select_panel_to_view_claims': 'Please select panel to view claims.',
  'please_select_vital': 'Please select a vital to input data for.',
  'please_select_valid_date': 'Please select a valid date',
  'please_enter_valid_campaign_name': 'Please enter valid campaign name',
  'please_enter_valid_campaign_set_name': 'Please enter valid campaign set name',
  'please_ensure_enrolment_value_have_valid_format': 'Please ensure enrolment value have valid format',
  'please_ensure_schedule_have_been_filed': 'Please ensure schedule number field has been filled',
  'please_ensure_message_have_been_filed': 'Please ensure message field has been filled',
  'please_ensure_rules_have_been_filed_with_valid_format': 'Please ensure rules have beed filled with valid format',
  'please_ensure_schedule_value_have_valid_format': 'Please ensure schedule number field have valid format',
  'please_ensure_status_have_been_selected': 'Please ensure status has been selected',
  'please_ensure_message_template_in_correct_format': 'Please ensure message template is in correct format',
  'please_select_valid_medication_type': 'Please select valid medication type',
  'please_wait_while_converting_doc_file': 'Please wait while we convert %{docTitle} into a PDF file.',
  'poison_register': 'Poison Register',
  'poisons_medicine_supplied': 'Poisons / Medicine Supplied',
  'policy_name': 'Policy Name',
  'portrait': 'Portrait',
  'postal_code_label': 'Postal Code Label',
  'practitioner_search': 'Practitioner Search',
  'practitioner': 'Practitioner',
  'price_updated': 'Price Updated',
  'precautions': 'Precautions',
  'preferred_name': 'Preferred Name',
  'preferred_doctor': 'Preferred Doctor',
  'premium_feature': 'Premium feature',
  'patient_has_a_allergy': 'Patient has an allergy %{drugNames} that is in the "Drugs - Unspecified" sub-category. Allergies in these sub-category will not trigger the Drug Allergy Checker Alert',
  'premium_features_settings': 'Premium Features Settings',
  'prescribe': 'Prescribe',
  'prescribing_modal_show_master_drug_name': 'Prescribing Modal - Show Master Drug Name',
  'prescription_creation_success': 'A prescription has been added to patient.',
  'prescription_deletion_success': 'A prescription has been deleted.',
  'prescription_dispensation_unit': 'Prescription Dispensation Unit',
  'prescription_dosage_unit': 'Prescription Dosage Unit',
  'prescription_dosage': 'Prescription Dosage',
  'prescribed_dosage': 'Prescribed Dosage',
  'prescription_frequency': 'Prescription Frequency',
  'prescription_history': 'Prescription History',
  'prescription_item_added': 'Prescription Item Added',
  'prescription_item_deleted': 'Prescription Item Deleted',
  'prescription_item_updated': 'Prescription Item Updated',
  'prescription_item': 'Prescription Item',
  'prescription_items': 'Prescription Items',
  'prescription_label_atdps_integration': 'ATDPS Integration',
  'prescription_label_content_font_size': 'Field content font size (px)',
  'prescription_label_header_title_font_size': 'Header title font size (px)',
  'prescription_label_height_desc': 'The height of the label sticker.',
  'prescription_label_height': 'Label Height (mm)',
  'prescription_label_label_topbar_height_desc': 'The height of label topbar. If the content is taller than the given height the content will overlap with the prescription content.',
  'prescription_label_label_topbar_height': 'Label Topbar Height (%)',
  'prescription_label_label_topbar': 'Label Topbar',
  'prescription_label_margin_horizontal_desc': 'The distance from the left edge of the label sticker to the edge of the backing paper.',
  'prescription_label_margin_horizontal': 'Label Horizontal Margin (mm)',
  'prescription_label_margin_vertical_desc': 'The distance from the top edge of the first label sticker to the top of the backing paper.',
  'prescription_label_margin_vertical': 'Label Vertical Margin (mm)',
  'prescription_label_padding_top_desc': 'Empty space left at the top of the label. Useful for pre-printed labels.',
  'prescription_label_padding_top': 'Label Top Padding (mm)',
  'prescription_label_settings': 'Prescription Label Settings',
  'prescription_label_show_field_labels': 'Show Field Labels',
  'prescription_label_show_header': 'Show Label Header',
  'prescription_label_show_label_topbar': 'Show Label Topbar',
  'prescription_label_show_sidebar': 'Show Label Sidebar',
  'prescription_label_sidebar_width_desc': 'The width of label sidebar',
  'prescription_label_sidebar_width': 'Label Sidebar Width (%)',
  'prescription_label_sidebar': 'Label Sidebar',
  'prescription_label_title_font_size': 'Field title font size (px)',
  'prescription_label_title': 'Label Title',
  'prescription_label_width_desc': 'The width of the label sticker.',
  'prescription_label_width': 'Label Width (mm)',
  'prescription_labels': 'Prescription Labels',
  'prescription_name': 'Prescription Name',
  'prescription_notes': 'Prescription Notes',
  'prescription_price': 'Prescription Price',
  'prescription_quantity': 'Prescription Quantity',
  'prescription_reason': 'Prescription Reason',
  'prescription_reports': 'Prescription Reports',
  'prescription_settings': 'Prescription Settings',
  'prescription_total_price': 'Prescription Total Price',
  'prescriptions_items_line_item': 'Prescriptions Items - Line Item',
  'prescriptions_items': 'Prescriptions Items',
  'prescriptions': 'Prescriptions',
  'presets': 'Presets',
  'press_enter_to_search': 'Press ENTER to search',
  'prev_patient_job_encounter_time': 'Previous Patient Job Encounter Time',
  'preview': 'Preview',
  'previous_page': 'Previous Page',
  'previous': 'Previous',
  'price_du': 'Price/DU',
  'price_qty': 'Price x Quantity (Disp. Unit)',
  'price_x': 'Price %{x}',
  'price': 'Price',
  'print_allow_custom_claims_invoice_number': 'Allow Custom Claims Invoice Number',
  'print_allow_custom_claims_report_date': 'Allow Custom Claims Report Date',
  'print_bill_font_size': 'Bill Font Size',
  'print_bill_height_desc': 'The height of the paper the Bill is printed on (inclusive of any margins). Leave empty if you want to be able to set the paper size at printing time.',
  'print_bill_height': 'Bill Height (mm)',
  'print_bill_invoice_show_items': 'Show Bill Invoice Items',
  'print_bill_margin_top_desc': 'The height of the margin at the top of the Bill. Useful for pre-printed paper.',
  'print_bill_margin_top': 'Bill Margin Top (mm)',
  'print_bill_show_header': 'Show Header Label on Bill',
  'print_by_panels': 'Print By Panels',
  'print_claim_invoice': 'Print Claim Invoice',
  'print_claims_report_legend_items_desc': 'Usually a list of treatment codes',
  'print_claims_report_legend_items': 'Items to show in the Legend for the Claims Report. (To be phased out)',
  'print_claims_report_legend_title': 'Claims Report Legend Title',
  'print_claims_report_note': 'Claims Report Note',
  'print_claims_report_show_diagnosis': 'Show Diagnosis in Claims Report',
  'print_claims_report_show_invoice_number': 'Show Invoice Number in Claims Report',
  'print_claims_report_use_panel_categories': 'Use panel categories summary for claim invoice treatment details',
  'print_export_options': 'Print/Export Options',
  'print_header_label': 'Header Label',
  'print_inventory_for_stock_take': 'Print Inventory for Stock Take',
  'print_labels': 'Print Labels',
  'print_layout': 'Print Layout',
  'print_mc': 'Print MC',
  'print_medical_certificate_default_employer_desc': 'E.g. To whom it may concern',
  'print_medical_certificate_default_employer': 'Default Employer on Medical Certificate',
  'print_medical_certificate_font_size': 'Medical Certificate Font Size',
  'print_medical_certificate_height_desc': 'The height of the paper the MC is printed on (inclusive of any margins). Leave empty if you want to be able to set the paper size at printing time.',
  'print_medical_certificate_height': 'Medical Certificate Height (mm)',
  'print_medical_certificate_margin_top_desc': 'The height of the margin at the top of the MC. Useful for pre-printed paper.',
  'print_medical_certificate_margin_top': 'Medical Certificate Margin Top (mm)',
  'print_medical_certificate_show_case_id': 'Show Case ID on Medical Certificate',
  'print_medical_certificate_show_diagnoses': 'Show Diagnoses on Medical Certificate',
  'print_medical_certificate_show_header': 'Show Header Label on Medical Certificate',
  'print_medical_certificate_show_ic': 'Show Patient IC on Medical Certificate',
  'print_medical_certificate_show_time': 'Show Time Issued on Medical Certificate',
  'print_medical_certificate': 'Print Medical Certificate',
  'print_options': 'Print Options',
  'print_patient_receipt': 'Print Patient Receipt',
  'print_prescribed_duration_in_label': 'Print prescribed duration in label',
  'print_prescription_labels': 'Print Prescription Labels',
  'print_queue_ticket': 'Print Queue Ticket',
  'pc_time_not_matched': 'Your PC time does not match with your standard zone time',
  'print_receipt': 'Print Receipt',
  'print_selected': 'Print Selected',
  'print_settings': 'Print Settings',
  'print_signature_label': 'Signature Label',
  'print_summary': 'Print Summary',
  'print_time_chit': 'Print Time Chit',
  'print_test_queue_ticket': 'Print Test Queue Ticket',
  'print': 'Print',
  'problem_loading_supplier_details_for': 'There was a problem while loading details for',
  'problem_loading_suppliers_list': 'There was a problem while loading suppliers.',
  'procedure_code': 'Procedure Code',
  'procedure_request_creation_success': 'Procedure Request Creation Success',
  'procedure_request_history': 'Procedure Request History',
  'procedure_requests': 'Procedure Requests',
  'procedure_result': 'Procedure Result',
  'procedure_search': 'Procedure Search',
  'procedures': 'Procedures',
  'provisional': 'Provisional',
  'proceed': 'Proceed',
  'proceed_anyway': 'Proceed anyway',
  'psychotropic_register': 'Psychotropic Register',
  'purchase_order_#': 'Purchase Order #',
  'purpose_appointment': 'Purpose of Visit',
  'postal_code': 'Postal Code',
  'policy': 'Policy',
  'policy_holder': 'Policy Holder',
  'px': 'px',
  'qty': 'Qty',
  'qty_after_adjustment': 'Qty After Adjustment',
  'qty_valid_format': 'Please ensure that quantity is in correct format.',
  'qualification': 'Qualification',
  'qualifications': 'Qualifications',
  'quantity_change': 'Quantity Change',
  'quantity_sold': 'Quantity Sold',
  'quantity': 'Quantity',
  'quantity_field_required_and_min_1': 'Quantity field is required and should be minimum of 1',
  'quantity_removed_cannot_be_more_than_received_quantity': 'Quantity removed cannot be more than the received quantity',
  'quantity_removed_cannot_be_more_than_amount_remains': 'Quantity removed cannot be more than the amount that remains in this batch',
  'queue_patient': 'Queue Patient',
  'queue_patient_for_today': 'Queue patients for today',
  'queue': 'Queue',
  'queue_number_settings': 'Queue Number Settings',
  'queue_ticket_settings': 'Queue Ticket Settings',
  'use_queue_no_system': 'use queue number system',
  'queue_settings': 'Queue Settings',
  'queue_no': 'Queue Number',
  'use_queue_no_setting_description': 'Assigns a sequential number to patients starting from “001” when they are queued.  Scheduled patients do not have a queue number assigned until they are queued. The sequence is reset and starts from “001” at 12 AM everyday.',
  'queue_no_already_in_use': 'Queue number is already in use',
  'q_no': 'Q.No',
  'range': 'Range',
  'reaction': 'Reaction',
  'read_other': 'Read Other',
  'read': 'Read',
  'reading_mykad': 'Reading MyKad',
  'readings': 'Readings',
  'reason': 'Reason',
  'reason_for_change': 'Reason for Change',
  'reason_field_is_required': '"Reason for Change" is required',
  'reasons': 'Reasons',
  'received_by_provider': 'Received by %{providerName}',
  'recent_casenotes': 'Recent Casenotes',
  'recent_documents': 'Recent Documents',
  'recent_searches': 'Recent Searches',
  'reception_staff': 'Reception Staff',
  'recorded_date': 'Recorded Date',
  'record_new_payment': 'Record New Payment',
  'redo': 'Redo',
  'refer_a_doctor_now': 'Refer a Doctor now!',
  'refer_and_earn': 'Refer & Earn',
  'referal_program_modal_title': '😎 It’s simple to Refer and Earn up to RM500 with Klinify',
  'referal_program_button_label': 'Refer a Friend',
  'referral_creation_success': 'Referral Creation Success',
  'referral_history': 'Referral History',
  'referrals': 'Referrals',
  'referring_doctor': 'Referring Doctor',
  'reflex': 'Reflex',
  'refresh': 'Refresh',
  'refuted': 'Refuted',
  'regenerate_claim_invoice': 'Regenerate Claim Invoice',
  'regenerate_claim_invoices': 'Regenerate Claim Invoices',
  'register_patient': 'Register Patient',
  'registration_suggestion': 'The patient may not be registered in the system. Would you like to register the patient?',
  'registration': 'Registration',
  'rejected': 'Rejected',
  'rejected_review_items': 'Rejected Review Items',
  'reject_tele_consult_request': 'Reject E-Consultation Request',
  'reject_walk_in_request': 'Reject Walk-In Request',
  'relapse': 'Relapse',
  'related_sales_items': 'Related Sales Items',
  'related_sales_items_label': 'These sales items will be added to the patient’s bill automatically if they go through this stage.',
  'relationship': 'Relationship',
  'release_notes': 'Release Notes',
  'remarks': 'Remarks',
  'reminder': 'Reminder',
  'remission': 'Remission',
  'remove': 'Remove',
  'remove_from_bin': 'Remove From Bin',
  'remove_from_queue': 'Remove From Queue',
  'remove_logo_confirm': 'Are you sure you want to remove the clinic logo?',
  'remove_logo': 'Remove Logo',
  'remove_stage_warning': 'This will remove all sales items related to this stage from the bill.\n\nThis will also remove any text entered into the “Notes” section of this stage and cannot be undone.',
  'remove_x_from_flow': 'Remove %{x} from Flow',
  'rename': 'Rename',
  'reports': 'Reports',
  'repeats_every': 'Repeats Every',
  'repeats_on': 'Repeats On',
  'request_cancelled': 'Request Cancelled',
  'request_could_not_be_completed_due_to_some_error': 'The request could not be completed due to some error.',
  'request_for': 'Request for %{patientName}',
  'request_order': 'Request Order',
  'request_timestamp_out_of_range_error': 'Invalid computer time',
  'requesting_order': 'Requesting Order...',
  'required_fields_desc': 'Note: Patient name is always required.',
  'required_fields': 'Required Fields',
  'recalled': 'Recalled',
  'received_qty': 'Received Qty',
  'remaining_qty': 'Remaining Qty',
  'resending_order': 'Resending Order...',
  'reset_filter': 'Reset Filter',
  'reset': 'Reset',
  'resolve': 'Resolve',
  'resolve_all_data_not_found_before_saving': 'Resolve all data not found before saving',
  'resolved': 'Resolved',
  'responded': 'Responded',
  'restart_klinify': 'Restart Klinify',
  'result': 'Result',
  'results': 'Results',
  'result_upload_successfully': 'Result uploaded successfully.',
  'results_received': 'Results Received',
  'retry': 'Retry',
  'retrying_email': 'Retrying Email',
  'return_to_edit': 'Return To Edit',
  'revenue': 'Revenue',
  'revenue_cash': 'Cash Revenue',
  'revenue_total': 'Total Revenue',
  'returned': 'Returned',
  'review_available_batches': 'Please review the available batches below and ensure that the correct batch is being dispensed.',
  'review_bill_summary': 'Please review items before finalising bill, in case of missing/inaccurate information.',
  'review_bill': 'Review Bill',
  'review_rejected': 'Review Rejected',
  'right_side_bar': 'Right Side Bar',
  'rotate_left': 'Rotate Left',
  'rotate_right': 'Rotate Right',
  'room_number': 'Room Number',
  'route_of_administration': 'Route of administration',
  'review_stages': 'Review Stages',
  'rx_dosage': 'Rx Dosage',
  'rx_name': 'Rx Name',
  'rx_notes': 'Rx Notes',
  'rx_price': 'Rx Price',
  'rx_qty': 'Rx Qty',
  'rx_reason': 'Rx Reason',
  'rx_summary': 'Rx Summary',
  'rx_total': 'Rx Total',
  'sale_price': 'Sale Price',
  'sales_item_added': 'Sales Item Added',
  'sales_item_deleted': 'Sales Item Deleted',
  'sales_item_price': 'Sales Item Price',
  'sales_items_in_stage': 'Sales Items in Stage',
  'sales_items_prescribed': 'Sales items Prescribed',
  'sales_item_summary': 'Sales Item Summary',
  'sales_item_total': 'Sales Item Total',
  'sales_item_types': 'Sales Item Types',
  'sales_item_updated': 'Sales Item Updated',
  'sales_item': 'Sales Item',
  'sales_items_line_item': 'Sales Items - Line Item',
  'sales_items': 'Sales Items',
  'save_appointment': 'Save Appointment',
  'save_block_off_time': 'Save Block Off Time',
  'save_and_complete_consult': 'Save and Complete Consult',
  'save_and_download': 'Save and Download',
  'save_and_map_next_x': 'Save and map next %{x}',
  'save_and_print': 'Save and Print',
  'save_before_you_leave_the_page_desc': 'You have unsaved changes. Would you like to save your changes before you leave the page?',
  'save_before_you_leave_the_page': 'Save Before You Leave The Page',
  'save_documents': 'Save Documents',
  'save_medical_certificate': 'Save Medical Certificate',
  'save_payment': 'Save Payment',
  'save_procedure_request': 'Save Procedure Request',
  'save_referral': 'Save Referral',
  'save_to': 'Save to',
  'save': 'Save',
  'saved': 'Saved',
  'saving_casenote': 'Saving Casenote',
  'saving_offline_documents': 'Saving Offline Documents',
  'saving': 'Saving',
  'schedule_for_today': 'Schedule For Today',
  'schedule_appointment': 'Schedule Appointment',
  'schedule_appointments': 'Schedule Appointments',
  'schedule_time': 'Schedule Time',
  'scheduled_day_for_sms_to_be_sent': 'Scheduled Day for SMS to be sent',
  'scheduled_time': 'Scheduled Time',
  'scheduled_date': 'Scheduled Date',
  'appointment_booking_link_tootltip_text': "This is your Clinic's unique e-consult / appointment booking link. Share this with your patients and ask them to save it in their contacts book!",
  'scheduling_settings': 'Scheduling Settings',
  'scheduling': 'Scheduling', // Permissions label
  'something_went_wrong_email_update': 'Something went wrong in updating the email address. Please try again.',
  'search_document_templates': 'Search Document Templates',
  'search_lab_test': 'Search by Test Name or Test ID',
  'specific_job_target_column_message': 'If the Scheduled day for SMS to be sent is over, SMS will be sent %{enrolmentValue} %{enrolmentUnit} after campaign activation.',
  'search_patient': 'Search Patient',
  'search': 'Search',
  'search_by': 'Search by',
  'search_for_drug_to_map': 'Search for drug to map',
  'search_for_sku': 'Search for sku',
  'search_name_ic_phone_clinic_number': 'Search by Name, IC, Phone, or Case ID',
  'search_results': 'Search Results',
  'search_active_ingredient': 'Search for allergic ingredient',
  'searching': 'Searching',
  'section': 'Section',
  'sections_to_be_exported': 'Sections to be Exported',
  'see_patient': 'See Patient',
  'select': 'Select',
  'select_all': 'Select All',
  'select_batch_to_dispense': 'Select Batch to Dispense From',
  'select_doctor': 'Select Doctor',
  'select_encounter_flow': 'Select %{encounterLabel} Flow',
  'select_file': 'Select File',
  'select_location': 'Select Location',
  'select_or_edit_flow': 'Select / Edit Flow',
  'selected_diagnosis': 'Selected Diagnosis',
  'selected_practitioner': 'Selected Practitioner',
  'selected_procedure': 'Selected Procedure',
  'select_one_of_the_verified_mapped_drug': 'Select one of the verified drug',
  'selected_sku_not_found': 'Selected SKU not found',
  'self_password': 'Password (Only this user)', // Permissions label
  'self_permissions': 'Permissions (Only this user)', // Permissions label
  'selling_price_per_unit': 'Selling Price Per Unit',
  'selling_price': 'Selling Price',
  'send_order': 'Send Order',
  'send_message': 'Send Message',
  'send_sms_to_patients': 'Send SMS to patients',
  'send_written_report_when_consultation_is_complete': 'Send written report when consultation is complete',
  'sending_order_in_progress_desc': 'Sending order is in progress. Please wait for the results before leaving this page.',
  'sending_order_in_progress': 'Sending order',
  'sending_order': 'Sending Order...',
  'sending_status_timeout': 'Error sending order. 504 Gateway Timeout',
  'sending': 'Sending',
  'sending_email': 'Sending Email',
  'sent_error': 'Sent Error',
  'sent_success': 'Sent Success',
  'serial_no_of_prescription': 'Serial No. of Prescription',
  'serial_number': 'S/N',
  'service': 'Service',
  'service_requested': 'Service Requested',
  'services_example': 'Examples of service: Blood tests, Consultations, Ultrasounds, X-rays',
  'set_as_default_template_for': 'Set As Default Template For:',
  'settings_page': 'Setting\'s Page.',
  'settings_updated': 'Settings successfully updated.',
  'settings': 'Settings',
  'sex': 'Sex',
  'server_setting': 'Server Settings',
  'short_code': 'Short Code',
  'show_all': 'Show All',
  'show_column': 'Show Column',
  'show_demo_banner': 'Show Demo Banner',
  'show_less': 'Show less',
  'show_more': 'Show more',
  'show_on_page': 'Show On Page',
  'show_only_unverified_entries': 'Show only unverified entries',
  'show_empty_batches': 'Show Empty Batches',
  'show_transaction_history_page': 'Show Transaction History Page',
  'show_inventory_mapping_page': 'Show Inventory Mapping Page',
  'show_all_drugs': 'Show All Drugs',
  'show_treatment_breakdown': 'Show Treatment Breakdown',
  'show_treatment_details': 'Show Treatment Details',
  'show': 'Show',
  'sibling-in-law': 'Sibling-in-law',
  'sibling': 'Sibling',
  'signature': 'Signature',
  'significant_other': 'Significant Other',
  'single': 'Single',
  'singapore': 'Singapore',
  'sister-in-law': 'Sister-in-law',
  'sister': 'Sister',
  'size': 'Size',
  'simple_inventory_warnings': 'Simple Inventory Warnings',
  'sku_name': 'SKU Name',
  'sku': 'SKU',
  'sku_in_manual_mapping': '%{sku}',
  'SKU': 'SKU',
  'small': 'Small',
  'smallest': 'Smallest',
  'sms_campaigns': 'SMS Campaigns',
  'sms_campaign_jobs': 'Sms Campaign Jobs',
  'start_econsultation': 'Start E-Consultation',
  'stay_tuned_for_more': 'STAY TUNED FOR MORE...',
  'add_sms_campaign': 'Add Campaign',
  'after_encounter': 'After encounter',
  'item_sold': 'Item sold',
  'and_uc': 'AND',
  'or_uc': 'OR',
  'consultation': 'Consultation',
  'message': 'Message',
  'responses': 'Responses',
  'unresolved': 'Unresolved',
  'queued': 'Queued',
  'search_clinic': 'Search Clinic',
  'sent': 'Sent',
  'rule_summary': 'Rule Summary',
  'send': 'Send',
  'smoker': 'Smoker',
  'sms_jobs': 'SMS Jobs',
  'sms_prefix': 'RM0.00 ',
  'sms_template_description': '`Messages have to start with %{smsPrefix}. Use {patient_name} (eg. \'Hi {patient_name}\') to insert patient name but take note that it will cause the message length to vary. Messages > %{cutOffLength} chars long will be split into multiple messages. Current length: %{messageLength} characters`',
  'sms_template_description_for_sg_and_in': 'Use {patient_name} (eg. \'Hi {patient_name}\') to insert patient name but take note that it will cause the message length to vary. Messages > %{cutOffLength} chars long will be split into multiple messages. Current length: %{messageLength} characters`',
  'sms_will_be_sent': 'SMS will be sent',
  'master_campaign': 'Master Campaign',
  'something_went_wrong': 'Something went wrong!',
  'son_in-law': 'Son-in-law',
  'son': 'Son',
  'sort': 'Sort',
  'sorted_by': 'Sorted by',
  'sort_by': 'Sort by',
  'sort_order_direction': 'Sort Order Direction',
  'sort_order': 'Sort Order',
  'select_consult_mode': 'Select Consult Mode',
  'specimen_requirements': 'Specimen Requirements:',
  'specialities': 'Specialities',
  'speciality': 'Speciality',
  'specimen_deleted': 'Specimen Deleted',
  'specimen_to_be_collected': 'Specimen to be collected',
  'specimens_received_by': 'Specimen(s) received by %{providerName}',
  'specimens_rejected': 'Specimen(s) rejected',
  'specimens_required': 'Specimens Required',
  'specimens_status_updated': 'Specimens status has been updated.',
  'specimens': 'Specimens',
  'split_payment_match_total_amount': 'The sum of the above lines must be equal to  %{amount}.',
  'split_this_payment': 'Split this payment',
  'split': 'Split',
  'spouse': 'Spouse',
  'sputum': 'Sputum',
  'stage': 'Stage',
  'stage_locations_label': 'Location(s) this Stage can take place in',
  'stage_name': 'Stage Name',
  'stage_order': 'Stage Order',
  'stage_waiting_section_alert_label': 'Sound to play when a patient enters the “Waiting” section',
  'stage_inprogress_section_alert_label': 'Sound to play when a patient enters the “In Progress” section',
  'stages': 'Stages',
  'stages_in_flow': 'Stages in flow',
  'star': 'Star',
  'starred': 'Starred',
  'start_date': 'Start Date',
  'start_time_end_time_invalid_error': 'Date and time cannot be empty and start time cannot be later than end time.',
  'start_time_gt_end_time_error': 'Start time cannot be later than end time.',
  'start_time': 'Start Time',
  'started': 'Started',
  'status': 'Status',
  'subscriber': 'Subscriber',
  'subscriber_relationship': 'Subscriber Relationship',
  'stat_dashboard': 'Stat Dashboard',
  'step_child': 'Step Child',
  'step_parent': 'Step Parent',
  'step_sibling': 'Step Sibling',
  'stepbrother': 'Stepbrother',
  'stepdaughter': 'Stepdaughter',
  'stepfather': 'Stepfather',
  'stepmother': 'Stepmother',
  'stepsister': 'Stepsister',
  'stepson': 'Stepson',
  'string': 'Text',
  'stock_adjustment': 'Stock Adjustment',
  'stock_adjustments': 'Stock Adjustments',
  'stock_adjustment_for': 'Stock Adjustment For %{sku}',
  'stock_take': 'Stock Take',
  'styleguide': 'Styleguide',
  'subtotal': 'Subtotal',
  'scheduled_consults': 'Scheduled Consults',
  'success': 'Success',
  'succesfully_edited_campaign': 'Succesfully edited campaign',
  'succesfully_saved_campaign': 'Succesfully saved campaign',
  'schedule': 'Schedule',
  'schedule_e_consultation': 'Schedule E-Consultation',
  'schedule_tele_consult': 'Schedule E-Consultation',
  'schedule_walk_in': 'Schedule Walk-in',
  'suggest_medication': 'Suggest medication',
  'suggested_mappings': 'Suggested Mappings',
  'suggested_mapping': 'Suggested Mapping',
  'supplier_added': 'Supplier Added',
  'supplier_id_required_when_integration_is_selected': 'Supplier ID is required when supplier integration is selected.',
  'supplier_integration': 'Supplier Integration',
  'supplier_name_and_address': 'Name and Address of Patient / Supplier',
  'supplier_name': 'Supplier Name',
  'supplier_updated': 'Supplier Updated',
  'supplier': 'Supplier',
  'suppliers': 'Suppliers',
  'supplies_added_and_transfers': 'Supplies Added and Transfers',
  'supplies': 'Supplies', // Permissions label
  'supply_added': 'Supply Added',
  'supply_creation_success': 'Supply was successfully added to inventory.',
  'supply_date': 'Supply Date',
  'supply_invoices': 'Supply Invoices',
  'supply': 'Supply',
  'support': 'Support',
  'swab': 'Swab',
  'symptom_options': 'Symptom Options', // Permissions label
  'symptom': 'Symptom',
  'symptoms': 'Symptoms',
  'synced': 'Synced',
  'table': 'Table',
  'tag': 'Tag',
  'tags': 'Tags',
  'target_patients': 'Target Patients',
  'taking_longer_than_usual_notification': 'The %{x} is taking longer than usual. Please wait.',
  'tc_end_time': 'Time Chit End Time',
  'tc_internal_clinic_id': 'Time Chit Serial Number',
  'tc_notes': 'Time Chit Notes',
  'tc_start_time': 'Time Chit Start Time',
  'tel': 'Telephone',
  'telephone': 'Telephone',
  'telephone_number': 'Telephone Number',
  'tele_consult_queue_info': 'E-consultation Queue Info',
  'walk_in_queue_info': 'Walk in Queue Info',
  'econsult_video_link': 'E-Consulting Video Link',
  'econsult_email_update_message': 'Patient\'s email will be updated. Email containing E-Consult link will be sent to this email once you click "Save".',
  'template': 'Template',
  'templates': 'Templates',
  'test_approved_pending_results': 'Test approved, pending results',
  'test_completed_pending_approval': 'Test completed, pending approval',
  'test_id': 'Test ID',
  'test_in_progress': 'Test in progress',
  'test_includes': 'Test Includes:',
  'test_name': 'Test Name',
  'test': 'Test',
  'text_casenotefile_created': 'Text Casenote Created',
  'text_casenotefile_updated': 'Text Casenote Updated',
  'text_editor': 'Text Editor',
  'text': 'Text',
  'tele_consult_email_placeholder': "Patient's email will be updated. Email containing E-Consultation link will be sent to this email once you click 'Save'",
  'walk_in_email_placeholder': "Patient's email will be updated. Email with details of the appointment will be sent to this email once you click 'Save'",
  'e_consultation': 'E-Consultation',
  'then_by': 'Then by',
  'there_are_no_batch_results': 'To see batches, search for the SKU in the field above.',
  'there_are_no_results': 'There are no results',
  'there_are_queued_patients_using_this_stage': 'There are queued patients using this stage',
  'there_are_queued_patients_using_this_stage_message': 'This stage cannot be deleted as there are queued patients using this stage.',
  'there_are_queued_patients_using_this_stage_message_description': 'You must either remove those patients from the queue or complete the flows for those patients before you can delete this stage.',
  'there_are_queued_patients_using_this_flow': 'There are queued patients using this flow',
  'there_are_queued_patients_using_this_flow_message': 'This flow cannot be deleted as there are queued patients using this flow.',
  'there_are_queued_patients_using_this_flow_message_description': 'You must either remove those patients from the queue or complete the flows for those patients before you can delete this flow.',
  'this_inventory_item_is_a_x': 'This inventory item is a %{x}',
  'time_after_patient_is_enrolled_into_the_campaign': 'Time after patient is enrolled into the campaign',
  'this_cant_be_undone': 'This can\'t be undone.',
  'this_flow_is_tied_to_following_campaigns': 'This Flow is tied to the following Patient Campaigns:',
  'time_and_date_patient_moved_to_in_progress': 'Time and date the patient was moved to In Progress',
  'time_chit_creation_success': 'A Time Chit has been created for the patient.',
  'time_chit': 'Time Chit',
  'time_chits': 'Time Chits',
  'time_of_specimen_collection': 'Time of specimen collection',
  'time': 'Time',
  'time_format_wrong': 'Time format is wrong',
  'tip_likelihood_desc': 'The percentage chance that a tip will show. Setting to 0 will disable tips.',
  'tip_likelihood': 'Tip Likelihood',
  'tip': 'Tip',
  'ticket_width': 'Ticket Width (MM)',
  'ticket_top_padding': 'Ticket Top Padding (MM)',
  'ticket_title_text': 'Ticket Title Text',
  'ticket_title_font_size': 'Ticket Title Font Size (PX)',
  'queue_no_font_size': 'Queue Number Font Size (PX)',
  'stage_name_font_size': 'Encounter Type Font Size (PX)',
  'location_name_font_size': 'Location Name Font Size (PX)',
  'ticket_custom_text': 'Ticket Custom Text',
  'ticket_custom_text_font_size': 'Ticket Custom Text Font Size (PX)',
  'ticket_custom_text_description': 'Custom text shown between the Encounter Type and the print date and time',
  'title': 'Title',
  'to': 'To',
  'today': 'Today',
  'todays_prescriptions': 'Today\'s Prescriptions',
  'top': 'Top',
  'total_after_change': 'Total After Change',
  'total_amount_received': 'Total Amount Received',
  'total_amount': 'Total Amount',
  'total_cash_received': 'Total Cash Received',
  'total_cost': 'Total Cost',
  'total_credit_received': 'Total Credit Received',
  'total_fee': 'Total Fee',
  'total_fees': 'Total Fees',
  'total_inventory_quantity': 'Total Inventory Quantity',
  'total_number_of_claims': 'Total Number of Claims',
  'total_other_payments_received': 'Total Other Payments Received',
  'total_outstanding': 'Total Outstanding',
  'total_paid': 'Total Paid',
  'total_panel_payments_claimable': 'Total Panel Payments Claimable',
  'total_panel_payments_received': 'Total Panel Payments Received',
  'total_patient_outstanding': 'Total Patient Outstanding',
  'total_price': 'Total Price',
  'total_purchases': 'Total Transaction Amount',
  'total_received_and_claimable': 'Total Received and Claimable',
  'total_received': 'Total Received',
  'total_revenue': 'Total Revenue',
  'total_stock_in_possession': 'Total Stock in Possession',
  'total_to_be_claimed': 'Total to be claimed',
  'to_check_reaction_bw_drugs': 'To check if there is a reaction between the drugs prescribed and this allergy, please edit this allergy sub-category in the allergy section.',
  'total': 'Total',
  'transaction_amount': 'Transaction Amount',
  'transaction_change_description': 'This value will indicate a subtraction to the stock count',
  'transaction_history': 'Transaction History',
  'transaction_type': 'Transaction Type',
  'transfer_in': 'Transfer In',
  'transfer_to': 'Transfer To',
  'transferred_in': 'Transferred In',
  'transferred_from': 'Transferred from',
  'transfer_out': 'Transfer Out',
  'true': 'True',
  'twin_brother': 'Twin Brother',
  'twin_sister': 'Twin Sister',
  'twin': 'Twin',
  'type_report_here': 'Type report here',
  'type_a_message': 'Type a message...',
  'type': 'Type',
  'unable_to_edit_casenote_info': 'Unable to edit casenote because it\'s a %{fileExtension} file.',
  'unable_to_match_icd10_query': 'Unable to find any ICD10 codes that match that query.',
  'unable_to_save': 'Unable to save',
  'unable_to_view_asset': 'Unable to view asset %{assetName}',
  'unassigned': 'Unassigned',
  'unavailable_offline': 'Unavailable offline',
  'uncategorized': 'Uncategorized',
  'unclaimable_items': 'Unclaimable Items',
  'unclaimed_bills': 'Unclaimed Bills',
  'uncle': 'Uncle',
  'unconfirmed': 'Unconfirmed',
  'unable_to_run_drug_allergy_checker': 'Unable to run Drug Allergy Checker',
  'undetermined': 'Undetermined',
  'undo': 'Undo',
  'unfinalised_bill_patient_details': 'Patient Details on Unfinalised Bills', // Permissions label
  'unfinalised_bill': 'Unfinalised Bills', // Permissions label
  'unit': 'Unit',
  'unit_cost': 'Unit Cost',
  'units': 'Units',
  'unknown': 'Unknown',
  'unmapped': 'Unmapped',
  'unsaved_changes_error': 'You have unsaved changes. Please save your changes before closing the modal or click the close icon again to close.',
  'unsaved_data_will_be_lost_desc': 'You have unsaved data that will be lost once you leave the page. If you\'d like to save your data first, please press Cancel, other wise press Discard to continue without saving.',
  'unsaved_data_will_be_lost': 'Unsaved Data Will Be Lost',
  'unsynced_documents': 'Unsynced Documents',
  'untitled_casenote': 'Untitled Casenote',
  'untitled_metric': 'Untitled Metric',
  'untitled_template_group': 'Untitled Template Group',
  'untitled': 'Untitled',
  'unreviewed_stage_location_message': 'Locations set for the Stage will show up in the Queue TV as well as in the Encounter notes. If multiple locations are set, you can pick from those locations when a patient is being seen to ensure that they are sent to the right place.',
  'update_clinic_logo': 'Update Clinic Logo',
  'update': 'Update',
  'update_block_off_time': 'Update Block Off Time',
  'update_appointment': 'Update Appointment',
  'updated_x': 'Updated %{x}',
  'update_batch_details': 'Update Batch Details',
  'update_details': 'Update Details',
  'updating_specimens_status': 'Updating specimens status...',
  'upload_asset': 'Upload Asset',
  'upload_clinic_logo_size_error': 'Image size is too large. Should not exceed 1MB',
  'upload_document_template': 'Upload Document Template',
  'upload_file_limit_error': 'The file exceeds the maximum upload size of 10MB',
  'upload_file': 'Upload File',
  'upload_note': 'Upload Note',
  'upload_result': 'Upload Result',
  'upload_results': 'Upload Results',
  'urgent': 'Urgent',
  'urine': 'Urine',
  'user_creation_failed': 'The user could not be created. Please try again, and if the problem persists contact Klinify for assistance.',
  'user_creation_try_different_username': 'Username already in use. Please try a different username.',
  'user_group_created': 'The user group was successfully created.',
  'user_group_creation_failed': 'User group creation failed. Please try again, and if the problem persists contact Klinify for assistance.',
  'user_group_permissions': 'User Group Permissions',
  'user_group': 'User Group',
  'user_groups': 'User Groups',
  'user_management': 'User Management',
  'user_permissions_updated': 'Permissions updated for user %{username}',
  'user_permissions': 'User Permissions',
  'user_successfully_added': 'User successfully added.',
  'user': 'User',
  'unspecified': 'Unspecified',
  'username_cant_be_empty': 'Username cannot be empty.',
  'username_empty_warning': 'Username cannot be empty.',
  'username_is_already_in_use': 'The username you entered is already in use.',
  'username': 'Username',
  'users': 'Users',
  'val': 'Val',
  'vendor': 'Vendor',
  'verification_status': 'Verification Status',
  'verified': 'Verified',
  'view_all_reports': 'View All Reports',
  'view_bill': 'View Bill',
  'view_campaign_set': 'View Campaign Set',
  'view_campaign': 'View Campaign',
  'view_campaigns': 'View Campaigns',
  'view_details': 'View Details',
  'view_edit_cost_prices': 'View/Edit Cost Prices',
  'view_edit': 'View/Edit',
  'view_edit_details': 'View/Edit Details',
  'view_full_casenote': 'View Full Casenote',
  'view_history': 'View History',
  'view_payment_details': 'View Payment Details',
  'view_payments': 'View Payments',
  'view_results': 'View Results',
  'view_request': 'View Request',
  'view': 'View',
  'visit_type': 'Visit Type',
  'vital_deleted': 'The Vital was successfully removed.',
  'vitals_form_date_invalid': 'The date entered is invalid.',
  'vitals_form_some_required': 'Please ensure at least one vital is filled out and all fields for the vital are filled.',
  'vitals_form_time_invalid': 'The time entered is invalid.',
  'vitals_form_values_invalid': 'Please ensure all fields are filled out.',
  'vitals_history': 'Vitals History',
  'vitals_saved': 'Vitals saved',
  'vitals_settings': 'Vitals Settings',
  'vitals': 'Vitals',
  'void_bill': 'Void Bill',
  'void_claim_invoice': 'Void Claim Invoice',
  'void_payment': 'Void Payment',
  'void_selected': 'Void Selected',
  'void': 'Void',
  'voided': 'Voided',
  'waiting_section': '"WAITING" Section',
  'waiting_time': 'Waiting Time',
  'waiting_time_mins': 'Waiting Time (Minutes)',
  'ward_number': 'Ward Number',
  'waiting': 'Waiting',
  'warning': 'Warning',
  'walk_in': 'Walk-in',
  'weeks': 'Weeks',
  'where': 'Where',
  'whats_new': 'What’s New in Klinify?',
  'who_visit_in_the_future': 'Who visit in the future',
  'who_visit_in_the_future_and_visited_recently': 'Who visit in the future, and who have visited recently',
  'wife': 'Wife',
  'we_have_detected_allergy_alert': 'We have detected that the patient has a drug allergy to %{names}.',
  'we_will_remove_allergy_alert': 'We will remove %{names} from the list of items to be %{mode}d to the patient.',
  'confirm_prescriptions_of_allergy_drug': 'If you still want to %{mode} this drug to the patient, click on the "I want to %{mode} drugs that the patient is allergic to" button. This action will be saved by the system.',
  'write_off': 'Write Off',
  'x_added_to_queue': '%{x} added to queue.',
  'x_email_have_been_updated': '%{x} email have been updated',
  'x_location': '%{x} Location',
  'x_minutes': '%{x} minutes',
  'x_notes': '%{x} Notes',
  'x_pages': '%{x} Pages',
  'x_sound': '"%{x}" sound',
  'x_unsynced_documents': '%{x} unsynced documents',
  'x_years_m_months_old': '%{x}y %{m}m',
  'x_years_old': '%{x} y/o',
  'year_received': 'Year Received',
  'yes': 'Yes',
  'you_are_not_connected_to_the_internet_with_no_live_search': 'You are not connected to the internet and search results will not be live.',
  'you_are_not_connected_to_the_internet': 'You are not connected to the internet.',
  'you_will_loose_your_writing': 'You will lose your writing or text if you leave this page.',
  'you_have_searched_for': 'You have searched for',
  'you_have_unsynced_data': 'You have %{unsyncedDataCount} unsynced changes. If you restart or close the app, these documents will be lost.',
  'you_have_unsaved_changes': 'You have unsaved changes. Please save your changes before closing the modal or click the icon again to close.',
  'zoom_in': 'Zoom In',
  'zoom_out': 'Zoom Out',
  'zoom_pan': 'Zoom/Pan',
  'zuellig': 'Zuellig',
  'inventory_mapping_notice': {
    'header_label': 'IMPORTANT NOTICE',
    'description': "We are using the National Pharmaceutical Regulatory Agency (NPRA) list as the standard. If mapped incorrectly, it may affect the outcomes of feature patient drug interaction checker, patient allergy interaction checker, and patient support programs in the future. It is important that the right drug name from the master drug list (NPRA) is mapped to your clinic's respective stock keeping unit (SKU). Please take the time to ensure the correct drug is matched in this process.\n\nYou will find the instructions in the FAQ page in the chat channel button on the bottom right corner of the page.\n\nClick on the button \"Acknowledge\" below to proceed.",
    'footer_label': 'Acknowledge',
  }
};
