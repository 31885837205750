import * as React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import TableColumnsSettings from './../table/tableColumnsSettings';
import ContentTransition from './../contentTransition';
import PanelPaymentsTable from './panelPaymentsTable';
import { sortByPayment } from './../../utils/comparators';
import Header from './../header/header';

import type BankModel from './../../models/bankModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import type ClaimReconciliationModel from './../../models/claimReconciliationModel';
import type { SaveModels, Config, MapValue, User, CustomColumn } from './../../types';


type Props = {
  config: Config,
  coveragePayors: List<CoveragePayorModel>,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimRecons: List<ClaimReconciliationModel>,
  saveModels: SaveModels,
  claimInvoices: List<ClaimInvoiceModel>,
  banks: List<BankModel>,
  user: User,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  paymentTypes: List<PaymentTypeModel>,
  updateConfig: (config: Config) => void,
};

const COLUMNS = List([
  { value: 'paymentRecorded', label: translate('payment_recorded'), filterable: true },
  { value: 'paymentReceived', label: translate('payment_received'), filterable: true },
  { value: 'panelName', label: translate('panel_name'), filterFromOptions: true, filterable: true },
  { value: 'paymentType', label: translate('payment_type'), filterFromOptions: true, filterable: true },
  { value: 'bank', label: translate('bank') },
  { value: 'invoiceMonth', label: translate('invoice_month'), multilineContent: true },
  { value: 'invoiceNumber', label: translate('invoice_number'), multilineContent: true },
  { value: 'amountPaid', label: translate('amount_paid'), multilineContent: true, sortMethod: sortByPayment },
  { value: 'notes', label: translate('notes'), multilineContent: true },
]);

type State = {
  columns: Array<CustomColumn>,
};

/**
 * Component displaying all panel payments as table.
 * @class ClaimInvoicePaymentsTable
 * @extends {React.Component}
 */
class PanelPayments extends React.PureComponent<Props, State> {
  props: Props;


  /**
   * Creates an instance of PanelPayments.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: COLUMNS.toArray(),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('panel_payment')}</h1>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <div className="u-flex-row o-card__header">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{ translate('panel_payment_history') }</h1>
            </Header>
            <div className="u-flex-right">
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="panel_payments"
                updateConfigValue={this.props.updateConfigValue}
                originalColumns={COLUMNS}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
            </div>
          </div>
          <PanelPaymentsTable
            columns={this.state.columns}
            claimInvoicePayments={this.props.claimInvoicePayments}
            claimRecons={this.props.claimRecons}
            saveModels={this.props.saveModels}
            claimInvoices={this.props.claimInvoices}
            coveragePayors={this.props.coveragePayors}
            banks={this.props.banks}
            user={this.props.user}
            isFetching={this.props.isFetching}
            currentDataViewsError={this.props.currentDataViewsError}
            paymentTypes={this.props.paymentTypes}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default PanelPayments;
