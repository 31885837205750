import React from 'react';
import { List } from 'immutable';
import VitalsTable from './vitalsTable';
import LineChart from './../charts/lineChart';
import ButtonGroup from './../buttons/buttonGroup';
import Button from './../buttons/button';

import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import { hasPermission, createPermission } from './../../utils/permissions';
import { formatDateForGraph } from './../../utils/time';
import { getConfirmation } from './../../utils/utils';

import type { VitalsTableRow } from './vitalsTable';
import type { SaveModel, MapValue, User } from './../../types';
import type MetricTypeModel from './../../models/metricTypeModel';
import type MetricEntryModel from './../../models/metricEntryModel';

import LineChartTooltip from './../charts/lineChartTooltip';
import type { Props as TooltipProps } from './../charts/lineChartTooltip';

type Props = {
  metricType: MetricTypeModel,
  metricEntries: List<MetricEntryModel>,
  patientID: string,
  saveModel: SaveModel,
  graphViewActive: boolean,
  user: User,
};

type State = {
  graphViewActive: boolean, // this will be toggle button checked status index corresponding to each metric type
};

type LineChartData = {
  data?: Array<{
    [key: string]: MapValue
  }>,
  xAxisLabel?: string,
  yAxisLabel?: string,
  xAxisKey?: string,
  yAxisKeys?: Array<string>,
};

/**
 * A component for each metric type, displays charts or table depending on toggle button,
 * used when there are some metric entries for the metric type
 * @class VitalsGridContent
 * @extends {React.PureComponent<Props, State>}
 */
class VitalsGridContent extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of VitalsGridContent.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      // set to true to indicate default view is graph, on load
      graphViewActive: this.props.graphViewActive,
    };
  }

  /**
   * Handles the toggle button click, called from each metricType toggle button click, updates state accordingly
   * @param {number} index index of metric type in the props list
   * @returns {void}
   */
  toggle = (): void => {
    this.setState(prevState => ({ graphViewActive: !prevState.graphViewActive }));
  }

  /**
   * prepares data needed for displaying chart/graph
   * @param {MetricTypeModel} metricType The metricType to filter for.
   * @param {List<MetricEntryModel>} entries All metricEntries for the current patient.
   * @returns {LineChartData} graphData
   */
  prepareGraphData(metricType: MetricTypeModel, entries: List<MetricEntryModel>): LineChartData {
    const dataTypes = metricType.get('datatype');
    const lineData = [];
    const keys = [];
    const graphData: LineChartData = {
      xAxisLabel: 'Time',
      xAxisKey: 'time',
    };
    let dataValid = true;
    entries.sortBy(e => e.get('measured_time')).forEach((entry) => {
      const values = entry.get('values');
      const graphNode = {};
      graphNode.time = Number.isInteger(entry.get('measured_time')) ?
        formatDateForGraph(parseInt(entry.get('measured_time'), 10)) : entry.get('measured_time');
      let count = 1;
      values.forEach((value, index) => {
        // validate if all values are not of string
        if (isNaN(value) && dataTypes[index] && dataTypes[index].type !== 'string') {
          dataValid = false;
        }
        if (dataTypes[index]) {
          if (dataTypes[index].type === 'string') {
            return;
          }
          if (dataTypes.length === 1) {
            graphNode[`${metricType.get('name')}`] = Number(value);
            if (!graphData.yAxisKeys) {
              keys.push(`${metricType.get('name')}`);
            }
          } else {
            graphNode[`Value ${count}`] = Number(value);
            if (!graphData.yAxisKeys) {
              keys.push(`Value ${count}`);
            }
            count += 1;
          }
        }
      });
      graphNode.formattedValue = metricType.formatValues(values);
      if (!graphData.yAxisKeys) {
        Object.assign(graphData, { yAxisKeys: keys });
      }
      lineData.push(graphNode);
    });
    if (lineData.length > 0 && dataValid) {
      Object.assign(graphData, {
        data: lineData,
        yAxisLabel: `${metricType.get('name')} (${metricType.get('unit')})`,
      }); // if valid data is set
    }
    return graphData;
  }

  /**
   * Checks if metric type needs toggle button to switch to graph view, checks for multiple scenarios and returns data
   * needed for displaying graph is all are success, return null if toggle is not needed.
   * @param {MetricTypeModel} metricType The metricType to filter for.
   * @param {List<MetricEntryModel>} entries All metricEntries for the current patient.
   * @returns {LineChartData | null} graphData or null
   */
  getGraphData(metricType: MetricTypeModel, entries: List<MetricEntryModel>): LineChartData | null {
    const metricTypeEntries = entries.filter(entry => entry.get('metric_type') === metricType.get('_id'));
    // minimum of 2 entries needed for graph
    if (metricTypeEntries && metricTypeEntries.size > 1) {
      // check for string datatype
      if (metricType.get('datatype', []).every(dataType => dataType.type === 'string')) {
        return null;
      }
      const graphData = this.prepareGraphData(metricType, metricTypeEntries);
      return graphData;
    }
    return null;
  }

  /**
   * Handles the deletion of a MetricEntryModel
   * @param {MetricEntryModel} metricEntry A metricEntry
   * @returns {void}
   */
  onDeleteClicked(metricEntry: MetricEntryModel) {
    if (metricEntry) {
      getConfirmation(translate('confirm_item_deletion'))
        .then(
          () => this.props.saveModel(metricEntry.set('_deleted', true))
            .then(() => {
              createSuccessNotification(translate('item_deleted'));
            }),
          () => {},
        );
    }
  }

  /**
   * Returns an array of objects with the entries for the given metric type in a format acceptable
   * to VitalsTable.
   * @param {MetricTypeModel} metricType The metricType to filter for.
   * @param {List<MetricEntryModel>} entries All metricEntries for the current patient.
   * @returns {Array<VitalsTableRow>}
   */
  getRows(metricType: MetricTypeModel, entries: List<MetricEntryModel>): Array<VitalsTableRow> {
    return entries
      .filter(entry => entry.get('metric_type') === metricType.get('_id'))
      .map(entry => ({
        metric_entry: metricType.formatValues(entry.get('values')),
        date: entry.get('measured_time'),
        delete: hasPermission(this.props.user,
          List([createPermission('patient_metrics', 'delete')])) ? (
            <Button
              className="o-text-button o-text-button--danger"
              onClick={() => this.onDeleteClicked(entry)}
              dataPublic
            >
              {translate('delete')}
            </Button>
          ) : undefined,
      }))
      .toArray();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const rows = this.getRows(this.props.metricType, this.props.metricEntries);
    const graphData = this.getGraphData(this.props.metricType, this.props.metricEntries);
    return (
      <div
        className="o-card"
        style={{ margin: 0 }}
        key={`vitalsTable-${this.props.patientID}-${this.props.metricType.get('_id')}`}
      >
        {
        graphData && graphData.data ?
          <div className="o-card__header">
            <h1 className="o-card__title o-card__title--inline">{this.props.metricType.get('name')}</h1>
            <ButtonGroup
              id="toggle-button"
              className="u-margin-right--1ws"
              label={`${translate('view')}: `}
              buttons={[
                {
                  label: translate('graph'),
                  isActive: this.state.graphViewActive,
                  onClick: () => (this.state.graphViewActive ? undefined : this.toggle()),
                },
                {
                  label: translate('table'),
                  isActive: !this.state.graphViewActive,
                  onClick: () => (!this.state.graphViewActive ? undefined : this.toggle()),
                },
              ]}
            />
          </div>
          :
          <h1 className="o-card__title">{this.props.metricType.get('name')}</h1>
      }
        {
          graphData && graphData.data && this.state.graphViewActive ?
            <LineChart
              data={graphData.data}
              yAxisLabel={graphData.yAxisLabel || ''}
              xAxisLabel={graphData.xAxisLabel || ''}
              xAxisKey={graphData.xAxisKey || ''}
              yAxisKeys={graphData.yAxisKeys || []}
              tooltipContent={
                (props: TooltipProps) => <LineChartTooltip {...props} />
              }
            />
            :
            <VitalsTable data={rows} minRows={rows.length > 0 ? 5 : 0} />
        }
      </div>
    );
  }
}

export default VitalsGridContent;
