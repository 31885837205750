import React, { Fragment } from 'react';
import { fromJS } from 'immutable';

import translate from './../../utils/i18n';
import SaveButton from './../buttons/saveButton';
import TextArea from './../inputs/textarea';
import InputGroup from './../inputs/inputGroup';
import ModalFooter from './../modals/modalFooter';

const defaultState = fromJS({
  isSaving: false,
  showError: false,
  attributes: {
    change: 0,
    notes: '',
  },
});

type State = {
  isSaving: boolean,
  showError: boolean,
  attributes: {
    change: string | number,
    notes: string,
  },
};

type Props = {
  adjustInventory: (SKUID: string, change: number, notes: string) => Promise<void>,
  SKUID: string,
  dispensationUnit?: string,
  change?: string | number,
  notes?: string
};

/**
 * A for for adding adjustments to SKUs.
 * @class InventoryAdjustmentForm
 * @extends {React.Component}
 */
class InventoryAdjustmentForm extends React.Component<Props, State> {
  /**
   * Creates an instance of InventoryAdjustmentForm.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = Object.assign({}, defaultState.toJS());
  }

  /**
   * Get Change and notes props
   * @return {void}
   */
  componentDidMount() {
    this.getChangeNotesProps();
  }

  /**
   * Get Change and notes props and set new value to attributes
   * @return {void}
   */
  getChangeNotesProps() {
    const { change, notes } = this.props;
    const { attributes } = this.state;

    if (change || notes) {
      this.setState({ attributes: Object.assign({}, attributes, { change, notes }) });
    }
  }

  /**
   * Updates state with user entered value.
   * @param {string} key - An attribute key.
   * @param {any} value - Value entered by user.
   * @return {undefined}
   */
  onValueChanged(key: string, value: string | number) {
    const newModelState = this.state.attributes;
    newModelState[key] = value;
    this.setState({ attributes: newModelState });
  }

  /**
   * Updates state for change with user entered value.
   * @param {string} value - Value entered by user.
   * @return {undefined}
   */
  onWriteOffValueChanged(value: string) {
    if (!isNaN(value) && value !== '' && value !== null && parseFloat(value) > 0) {
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          change: -1 * parseFloat(value),
        }),
      });
    } else {
      this.setState({
        attributes: Object.assign({}, this.state.attributes, {
          change: value,
        }),
      });
    }
  }

  /**
   * Returns true if form is valid.
   * @returns {boolean} True if form is valid.
   */
  formIsValid(): boolean {
    return this.state.attributes.change !== undefined &&
      !isNaN(this.state.attributes.change) &&
      this.state.attributes.change !== '' &&
      this.state.attributes.change !== null &&
      parseFloat(this.state.attributes.change) !== 0;
    // Check is float or int and not 0 and not undefined.
  }

  /**
   * Handle saving of the transaction.
   * @returns {void}
   */
  onSaveClicked() {
    if (this.formIsValid()) {
      this.setState({ isSaving: true, showError: false });
      this.props.adjustInventory(
        this.props.SKUID,
        parseFloat(this.state.attributes.change),
        this.state.attributes.notes,
      ).then(() => {
        this.setState(Object.assign({}, defaultState.toJS()));
      });
    } else {
      this.setState({ showError: true });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <section className="o-form">
          {this.state.showError && <div className="o-form__text-block o-form__text-block--error">{translate('fill_required_fields')}</div>}
          <InputGroup
            inputLeft
            content={this.props.dispensationUnit}
            id="change"
            label={translate('write_off')}
            description={translate('transaction_change_description')}
            value={this.state.attributes.change}
            type="number"
            onValueChanged={value => this.onWriteOffValueChanged(value)}
            required
          />
          <TextArea
            id="notes"
            label={translate('notes')}
            value={this.state.attributes.notes}
            onValueChanged={value => this.onValueChanged('notes', value)}
          />
        </section>
        <ModalFooter>
          <SaveButton
            dataPublic
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default InventoryAdjustmentForm;
