import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Set, List } from 'immutable';

import AddEditDrug from './../components/drugs/addEditDrug';
import { saveFactory } from './../utils/redux';
import { updateConfigValue, updateConfig, setDrugManufacturerModels } from './../actions';
import { mapDrug, fetchMasterData } from '../utils/inventory';

import type { Dispatch, State, Config } from './../types';
import type DrugManufacturerModel from '../models/drugManufacturerModel';
import type BaseModel from '../models/baseModel';


interface OwnProps extends RouteComponentProps {
  isFromDocValidationModal?: boolean,
  validationDocObject?: { id: string, type: string }
  isDocValidationModalSaving?: boolean,
  validationReferrerModel?: BaseModel,
  onSaveAtDocValidationModal?: (wasSuccessful: boolean) => void,
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const { isFromDocValidationModal, validationReferrerModel } = ownProps;
  const { drugs, config, coveragePayors, masterDrugModelsMap, drugManufacturers } = state;
  const modelToEdit = (isFromDocValidationModal && validationReferrerModel) ? drugs.find(i => i.get('_id') === validationReferrerModel.get('_id')) : undefined;
  return {
    dispensationUnitOptions: Set(drugs.map(d => d.get('dispensation_unit')))
      .filter(i => i && i.length > 1),
    config,
    coveragePayors,
    drugDurations: config.getIn(['prescription', 'durations'], List()).filter((e: Array<[number, string]>) => e !== null && e !== undefined),
    masterDrugModelsMap,
    modelToEdit,
    drugMap: mapDrug(drugs),
    drugManufacturers,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  fetchTableMasterData: (ids: List<string>) => fetchMasterData(ids, dispatch),
  setDrugManufacturers: (models: List<DrugManufacturerModel>) =>
    dispatch(setDrugManufacturerModels(models)),
});

const AddEditDrugContainer = connect(mapStateToProps, mapDispatchToProps)(AddEditDrug);

export default AddEditDrugContainer;
