import * as React from 'react';
import glamourous from 'glamorous';
import { colours } from './../../utils/css';

import type { HTMLStyle } from './../../types';

type Props = {
  unreadCount?: number | string,
  iconStyle?: HTMLStyle,
};

const IndicatorSpan = glamourous.span({
  backgroundColor: colours.maroon,
  color: colours.white,
  marginLeft: 6,
  borderRadius: '100%',
  padding: '2px 4px',
  width: 12,
  fontSize: '0.8rem',
  display: 'inline-block',
  textAlign: 'center',
}, ({ iconStyle }) => iconStyle);

/**
 * Creates a UnreadIndicator badge
 * @param {Props} props The props for the component.
 * @returns {React.Component} The Unread indicator
 */
const UnreadIndicator = (props: Props) => {
  const {
    unreadCount,
  } = props;

  if (!unreadCount) {
    return null;
  }

  return <IndicatorSpan iconStyle={props.iconStyle}>{unreadCount}</IndicatorSpan>;
};

export default UnreadIndicator;
