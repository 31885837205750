import { connect } from 'react-redux';

import Banks from './../components/banks/banks';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  banks: state.banks.filter(b => b.isVisible()),
  user: state.user,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const BanksContainer = connect(mapStateToProps, mapDispatchToProps)(Banks);

export default BanksContainer;
