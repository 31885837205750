import * as React from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import ClampLines from 'react-clamp-lines';

import Table from './../table/table';
import { UNICODE } from '../../constants';
import translate from './../../utils/i18n';
import { renderMultilineContent, renderDateTime, renderPrice } from './../../utils/tables';
import { convertNumberToPrice } from '../../utils/utils';
import { getConsultHistoryPermissions, createPermission } from './../../utils/permissions';
import ConsultationItemAction from './consultationItemAction';
import PermissionWrapper from './../permissions/permissionWrapper';
import LabRequestsList from './../labRequests/labRequestsList';
import LabResult from './../labRequests/labResult';
import { sortByPayment } from './../../utils/comparators';
import Button from './../buttons/button';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import PatientModel from './../../models/patientModel';
import type AllergyModel from './../../models/allergyModel';
import type BillModel from './../../models/billModel';
import type BillItemModel from './../../models/billItemModel';
import type ConditionModel from './../../models/conditionModel';
import type DrugModel from './../../models/drugModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type PractitionerModel from './../../models/practitionerModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type EncounterModel from './../../models/encounterModel';
import ConsultationHistoryDetails from './consultationHistoryDetails';
import type {
  Config, SaveModel, SaveModels, User, InventoryCount, Column,
  MapValue, TrProps, Model, AssetObject, CellProps,
} from './../../types';
import type DocumentDataModel from './../../models/documentDataModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type ProviderModel from './../../models/providerModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProcedureRequestModel from './../../models/procedureRequestModel';
import type SpecimenModel from './../../models/specimenModel';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type ProcedureResultModel from './../../models/procedureResultModel';
import type InventoryMapModel from './../../models/inventoryMapModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import DosingRegimenModel from '../../models/dosingRegimenModel';

/* eslint-disable camelcase */
type ConsultationHistoryTableRow = {
  date_and_time?: number,
  encounter_type?: string,
  encounter_status?: string,
  doctor?: string,
  symptoms?: string,
  diagnoses?: string,
  notes?: string | { [stageName: string]: string },
  rx_summary?: string,
  rx_name?: string,
  rx_dosage?: string,
  rx_reason?: string,
  rx_notes?: string,
  rx_price?: string,
  rx_qty?: string,
  rx_total?: string,
  mc_time_chit?: string,
  documents?: string,
  dispense_summary?: string,
  dispense_price?: string,
  dispense_total?: string,
  sales_item_summary?: string,
  sales_item_price?: string,
  sales_item_total?: string,
  total_fee?: string,
  actions: React.Node,
};

type LabTestData = {
  lab_test: string,
  lab_test_id: string,
  lab_vendor: string,
  lab_sale_price: string,
  lab_results: Array<AssetObject> | string,
};
/* eslint-enable camelcase */

type Props = {
  bills: List<BillModel>,
  billItems: List<BillItemModel>,
  encounters: List<EncounterModel>,
  config: Config,
  klinifyConfig: Config,
  patient: PatientModel,
  allergies: List<AllergyModel>,
  user: User,
  practitioners: List<PractitionerModel>,
  drugs: List<DrugModel>,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  prescriptions: List<PrescriptionModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  isOnline: boolean,
  inventoryCount: InventoryCount,
  showLowStockWarning: boolean,
  conditions: List<ConditionModel>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  coveragePayorID?: string,
  coveragePayors: List<CoveragePayorModel>,
  documentTemplates: List<DocumentTemplateModel>,
  documentData: List<DocumentDataModel>,
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
  specimens: List<SpecimenModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  collectedSpecimens: List<CollectedSpecimenModel>,
  procedureResults: List<ProcedureResultModel>,
  verifiedDrugs: List<InventoryMapModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  encounterFlows: List<EncounterFlowModel>,
  dosingRegimens: List<DosingRegimenModel>,
  patientPrescriptionHistory: List<PrescriptionModel>,
};

type EncounterMappedData = Map<string, {
  bill: BillModel | null,
  symptoms: List<ConditionModel>,
  diagnoses: List<ConditionModel>,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  prescriptions: List<PrescriptionModel>,
}>;

type BillBillItemsMappedData = Map<string, {
  dispensation: List<DrugModel>,
  salesItem: List<SalesItemModel>,
}>

type MappedData = {
  encountersMappedData: EncounterMappedData,
  billBillItemsMappedData: BillBillItemsMappedData,
  prescriptionsDrugMappedData: Map<string, DrugModel>,
  dispensationsDrugMappedData: Map<string, DrugModel>,
  billItemsSalesItemMappedData: Map<string, SalesItemModel>,
};


type State = {
  showRowInfo: boolean,
  encounterID: string,
  orderLabRequestModalEncounterID: string,
  isLabRequestsFormModalVisible: boolean,
  isLabResultVisible: boolean,
};

const StageNameContainer = glamorous.h6({
  textDecoration: 'underline',
});

/**
 * Returns cell renderer for encounter notes
 * @param {CellProps} props cell props
 * @returns {JSX.Element}
 */
const renderEncounterNotes = ({ value }: CellProps) => (typeof value === 'string'
  ? <div className="o-table__cell o-table__cell--linebreak">{value}</div>
  : (
    <div className="u-full-width">
      {Object.keys(value).map(stageName => (
        <React.Fragment key={stageName}>
          <StageNameContainer className="u-margin-top--half-ws">{stageName}</StageNameContainer>
          <ClampLines
            text={value[stageName] || UNICODE.EMDASH}
            id={stageName}
            moreText={translate('show_more')}
            lessText={translate('show_less')}
            lines={4}
            buttons
            stopPropagation={false}
            className="c-consultation-history-table-clamper"
          />
        </React.Fragment>
      ))}
    </div>)
);

const columns = List([
  { accessor: 'date_and_time', Header: translate('date_and_time'), Cell: renderDateTime },
  { accessor: 'encounter_type', Header: translate('encounter_flow'), minWidth: 110 },
  { accessor: 'encounter_status', Header: translate('encounter_status') },
  { accessor: 'doctor', Header: translate('doctor'), Cell: renderMultilineContent },
  { accessor: 'symptoms', Header: translate('symptoms'), Cell: renderMultilineContent },
  { accessor: 'diagnoses', Header: translate('diagnoses'), Cell: renderMultilineContent },
  { accessor: 'notes', Header: translate('notes'), Cell: renderEncounterNotes },
  { accessor: 'rx_summary', Header: translate('rx_summary'), Cell: renderMultilineContent },
  { accessor: 'rx_name', Header: translate('rx_name'), Cell: renderMultilineContent },
  { accessor: 'rx_dosage', Header: translate('rx_dosage'), Cell: renderMultilineContent },
  { accessor: 'rx_reason', Header: translate('rx_reason'), Cell: renderMultilineContent },
  { accessor: 'rx_notes', Header: translate('rx_notes'), Cell: renderMultilineContent },
  { accessor: 'rx_price', Header: translate('rx_price'), Cell: renderMultilineContent, sortMethod: sortByPayment },
  { accessor: 'rx_qty', Header: translate('rx_qty'), Cell: renderMultilineContent },
  { accessor: 'rx_total', Header: translate('rx_total'), Cell: renderMultilineContent, sortMethod: sortByPayment },
  { accessor: 'mc_time_chit', Header: translate('mc_time_chit') },
  { accessor: 'documents', Header: translate('documents') },
  {
    accessor: 'dispense_summary',
    Header: translate('dispense_summary'),
    Cell: renderMultilineContent,
  },
  {
    accessor: 'dispense_price',
    Header: translate('dispense_price'),
    Cell: renderMultilineContent,
    sortMethod: sortByPayment,
  },
  {
    accessor: 'dispense_total',
    Header: translate('dispense_total'),
    Cell: renderMultilineContent,
    sortMethod: sortByPayment,
  },
  {
    accessor: 'sales_item_summary',
    Header: translate('sales_item_summary'),
    Cell: renderMultilineContent,
  },
  {
    accessor: 'sales_item_price',
    Header: translate('sales_item_price'),
    Cell: renderMultilineContent,
    sortMethod: sortByPayment,
  },
  {
    accessor: 'sales_item_total',
    Header: translate('sales_item_total'),
    Cell: renderMultilineContent,
    sortMethod: sortByPayment,
  },
  { accessor: 'lab_test', Header: translate('lab_test') },
  { accessor: 'lab_test_id', Header: translate('test_id') },
  { accessor: 'lab_vendor', Header: translate('vendor') },
  { accessor: 'lab_sale_price', Header: translate('sale_price'), Cell: renderPrice },
  { accessor: 'lab_results', Header: translate('lab_results'), width: 203 },
  { accessor: 'total_fee', Header: translate('total_fee'), Cell: renderMultilineContent, sortMethod: sortByPayment },
  { accessor: 'actions', Header: translate('actions'), sortable: false, width: 203 },
]);

export const columnsForPermissionCheck = {
  viewBill: List(['rx_price', 'rx_total', 'dispense_price', 'dispense_total', 'sales_item_price',
    'sales_item_total', 'total_fee']),
  viewPrescriptions: List(['rx_summary', 'rx_name', 'rx_notes',
    'rx_reason', 'rx_qty', 'rx_dosage']),
  viewConditions: List(['symptoms', 'diagnoses']),
  viewNotes: List(['notes']),
  viewMcTc: List(['mc_time_chit']),
  viewLabConditions: List(['lab_test', 'lab_test_id', 'lab_vendor', 'lab_sale_price', 'lab_results']),
};

const TableContainer = glamorous.div({
  display: 'grid',
  '& .rt-tr': {
    cursor: 'pointer',
  },
});

/**
 * A table for displaying consultation history table when multiple view is selected
 * @class ConsultationHistoryTable
 * @extends {React.Component<Props>}
 */
class ConsultationHistoryTable extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of ConsultationHistoryTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      showRowInfo: false,
      encounterID: '',
      orderLabRequestModalEncounterID: '',
      isLabRequestsFormModalVisible: false,
      isLabResultVisible: false,
    };
  }

  /**
   * acts as reduce function to update the mapped data against encounter id with new object with lists
   * @param {billBillItemsMappedData | EncounterMappedData} reducedData flattened data mapped by encounter id
   * @param {string} fieldName name of the field to be updated in the value object in map, eg: symptoms, presciptions etc
   * @param {Model} model any model to be part of flattened data
   * @param {string} accessor name of the field in the model to use as key in map eg: encounter_id if model is bill
   * @return {billBillItemsMappedData | EncounterMappedData} updated mapped data
   */
  reduceData(
    reducedData: BillBillItemsMappedData | EncounterMappedData,
    fieldName: string,
    model: Model,
    accessor: string,
  ) : BillBillItemsMappedData | EncounterMappedData{
    const valueData = reducedData.get(model.get(accessor)) || {};
    const toUpdate : List<Model> = valueData[fieldName as keyof typeof valueData] || List();
    (valueData[fieldName as keyof typeof valueData] as List<Model>) = toUpdate.push(model);
    return reducedData.set(model.get(accessor), valueData);
  }

  /**
   * Creates a map with key as encounter id and value as  { key: List<Model> or Model, ...} where key is the referenced
   * value names to encounter eg: prescriptions, symptoms, bill etc
   * @return {EncounterMappedData} mapped data
   */
  getEncountersMappedData(): EncounterMappedData {
    // get initial map
    const emptyEncountersMappedData = this.props.encounters.reduce((encounterMappedData, e) => encounterMappedData.set(e.get('_id'), {
      bill: null,
      diagnoses: List(),
      symptoms: List(),
      medicalCertificates: List(),
      timeChits: List(),
      prescriptions: List(),
    }), Map() as EncounterMappedData);

    const encountersMappedDataConditions = this.props.conditions.reduce((mappedData, c) => {
      if (c.isVisible() && c.isDiagnosis()) {
        return this.reduceData(mappedData, 'diagnoses', c, 'encounter_id');
      }
      if (c.isVisible() && c.isSymptom()) {
        return this.reduceData(mappedData, 'symptoms', c, 'encounter_id');
      }
      return mappedData;
    }, emptyEncountersMappedData);

    const encountersMappedDataConditionsMC = this.props.medicalCertificates.reduce(
      (mappedData, mc) => this.reduceData(mappedData, 'medicalCertificates', mc, 'encounter_id'), encountersMappedDataConditions,
    );

    const encountersMappedDataConditonsMCTimeChit = this.props.timeChits.reduce(
      (mappedData, tc) => this.reduceData(mappedData, 'timeChits', tc, 'encounter_id'), encountersMappedDataConditionsMC,
    );
    return encountersMappedDataConditonsMCTimeChit as EncounterMappedData;
  }

  /**
   * Creates an object having all mapped data created.
   * @return {MappedData} all the mapped data
   */
  getMappedData() {
    const salesItemsMap = Map(this.props.salesItems.map(d => [d.get('_id'), d]));
    const drugsMap = Map(this.props.drugs.map(d => [d.get('_id'), d]));

    const mappedDataWithEncounters = {
      encountersMappedData: this.getEncountersMappedData(),
      billBillItemsMappedData: Map() as BillBillItemsMappedData,
      prescriptionsDrugMappedData: Map(),
      dispensationsDrugMappedData: Map(),
      billItemsSalesItemMappedData: Map(),
    };

    const mappedDataWithBills = this.props.bills.reduce((mappedData, bill) => {
      const { billBillItemsMappedData, encountersMappedData } = mappedData;
      const encounterData = encountersMappedData.get(bill.get('encounter_id'));

      return Object.assign({}, mappedData, {
        billBillItemsMappedData: billBillItemsMappedData.set(bill.get('_id'), {
          dispensations: List(),
          salesItems: List(),
        }),
        // also setting bill against encounter id
        encountersMappedData: encounterData ? encountersMappedData.set(bill.get('encounter_id'), Object.assign({}, encounterData, { bill }))
          : encountersMappedData,
      });
    }, mappedDataWithEncounters);

    const mappedDataWithPrescriptions = this.props.prescriptions.reduce((mappedData, p) => {
      const { prescriptionsDrugMappedData, encountersMappedData } = mappedData;
      return Object.assign({}, mappedData, {
        prescriptionsDrugMappedData: prescriptionsDrugMappedData.set(p.get('_id'), drugsMap.get(p.get('drug_id'))),
        // also setting prescriptions against encounter id
        encountersMappedData: this.reduceData(encountersMappedData, 'prescriptions', p, 'encounter_id'),
      });
    }, mappedDataWithBills);

    const dispensations = this.props.billItems
      .filter(i => i.isPrescription());

    const mappedDataWithDispensations = dispensations.reduce((mappedData, item) => {
      const { dispensationsDrugMappedData, billBillItemsMappedData} = mappedData;
      return Object.assign({}, mappedData, {
        dispensationsDrugMappedData: dispensationsDrugMappedData.set(item.get('_id'), drugsMap.get(item.get('drug_id'))),
        // also setting dispensations against bill id
        billBillItemsMappedData: this.reduceData(billBillItemsMappedData, 'dispensations', item, 'bill_id'),
      });
    }, mappedDataWithPrescriptions);

    const billSalesItems = this.props.billItems
      .filter(i => i.isSalesItem());

    const mappedDataWithBillSalesItems = billSalesItems.reduce((mappedData, item) => {
      const { billItemsSalesItemMappedData, billBillItemsMappedData } = mappedData;
      return Object.assign({}, mappedData, {
        billItemsSalesItemMappedData: billItemsSalesItemMappedData.set(item.get('_id'), salesItemsMap.get(item.get('sales_item_id'))),
        // also setting sales items against bill id
        billBillItemsMappedData: this.reduceData(billBillItemsMappedData, 'salesItems', item, 'bill_id'),
      });
    }, mappedDataWithDispensations);
    return mappedDataWithBillSalesItems;
  }

  /**
   * Gets action button and menu as react node for the given encounter.
   * @param {EncounterModel} encounter Encounter model.
   * @param {EncounterMappedData} encountersMappedData flattened data mapped by encounter id
   * @returns {React.Node}.
   */
  getActions(encounter: EncounterModel, encountersMappedData: EncounterMappedData): React.Node {
    const mappedData = encountersMappedData.get(encounter.get('_id'));
    const bill = mappedData && mappedData.bill;
    const billItems = this.props.billItems.filter(i => bill && i?.get('bill_id') === bill.get('_id'));
    const newProps = {
      doctor: encounter.getDoctorName(this.props.practitioners, true),
      encounter,
      diagnoses: mappedData?.diagnoses || List(),
      symptoms: mappedData?.symptoms || List(),
      medicalCertificates: mappedData?.medicalCertificates || List(),
      prescriptions: mappedData?.prescriptions || List(),
      timeChits: mappedData?.timeChits || List(),
      documentTemplates: this.props.documentTemplates,
      onOrderLabTest: (isVisible : boolean, encounterID : string) => this.setState({
        isLabRequestsFormModalVisible: isVisible,
        orderLabRequestModalEncounterID: encounterID,
      }),
      billItems,
    };
    const actionProps = Object.assign({}, this.props, newProps);
    return (
      <ConsultationItemAction
        {...actionProps}
        actionClassName="c-consultation-history-table-action"
        practitioners={this.props.practitioners}
      />
    );
  }

  /**
   * Gets a string representing a diagnosis details for the encounter. Will return an empty string if it
   * can't be found.
   * @param {EncounterModel} encounter Encounter model.
   * @param {EncounterMappedData} encountersMappedData flattened data mapped by encounter id
   * @returns {string}.
   */
  getDiagnosis(encounter: EncounterModel, encountersMappedData: EncounterMappedData): string {
    return (encountersMappedData.get(encounter.get('_id'))?.diagnoses || List())
      .map(s => s.get('name')).toArray().toString()
      .replace(/,/g, '\n') || UNICODE.EMDASH;
  }

  /**
   * Gets a string representing a symptoms details for the encounter. Will return an empty string if it
   * can't be found.
   * @param {EncounterModel} encounter Encounter model.
   * @param {EncounterMappedData} encountersMappedData flattened data mapped by encounter id
   * @returns {string}.
   */
  getSymptoms(encounter: EncounterModel, encountersMappedData: EncounterMappedData): string {
    return (encountersMappedData.get(encounter.get('_id'))?.symptoms || List())
      .map(s => s.get('name')).toArray().toString()
      .replace(/,/g, '\n') || UNICODE.EMDASH;
  }

  /**
   * Gets a string representing a details about medical sertificate and time chit for the encounter.
   * Will return an empty string if it can't be found.
   * @param {EncounterModel} encounter Encounter model.
   * @param {EncounterMappedData} encountersMappedData flattened data mapped by encounter id
   * @returns {string}.
   */
  getMcTimeChit(encounter: EncounterModel, encountersMappedData: EncounterMappedData): string {
    const mc = (encountersMappedData.get(encounter.get('_id'))?.medicalCertificates || List());
    const tc = (encountersMappedData.get(encounter.get('_id'))?.timeChits || List());
    return `${mc && mc.size > 0 ? 'Yes' : 'No'}
      /
      ${tc && tc.size > 0 ? 'Yes' : 'No'}`;
  }

  /**
   * Gets a string representing a details about document templates created for the encounter
   * Will return an empty string if it can't be found.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {string}.
   */
  getDocumentData(encounter: EncounterModel): string {
    const documents = this.props.documentData
      .filter(m => m.get('encounter_id') === encounter.get('_id'));
    return documents && documents.size > 0 ? 'Yes' : 'No';
  }

  /**
   * Gets a string representing a value for each of the column name passed, columns related to sales items
   * and bills for an encounter.
   * @param {EncounterModel} encounter Encounter model.
   * @param {{}} mappedData the flattened data with differents maps specific to bill, encounter etc
   * @returns {{}}.
   */
  getSalesDetails(
    encounter: EncounterModel,
    mappedData: {[key: string]: MapValue} = {},
  ): {[key: string]: MapValue} {
    const { bill } = mappedData.encountersMappedData.get(encounter.get('_id'), {});
    let data = {
      dispense_summary: [],
      dispense_price: [],
      dispense_total: [],
      total_fee: '',
      sales_item_summary: [],
      sales_item_price: [],
      sales_item_total: [],
    };
    if (bill) {
      const salesItems = mappedData.billBillItemsMappedData.get(bill.get('_id'), {}).salesItems || List();
      const prescriptions = mappedData.billBillItemsMappedData.get(bill.get('_id'), {}).dispensations || List();
      if (prescriptions && prescriptions.size > 0) {
        data = prescriptions.reduce((prescriptionData, p) => {
          const drug = mappedData.dispensationsDrugMappedData.get(p.get('_id'));
          prescriptionData.dispense_summary.push(
            `${drug ?
              `${drug.get('name')} - ${p.get('quantity', '')} ${drug.getDispensationUnit()}`
              : UNICODE.EMDASH}`,
          );
          prescriptionData.dispense_price.push(
            convertNumberToPrice(p.get('price', '')),
          );
          prescriptionData.dispense_total.push(
            convertNumberToPrice((p.has('quantity', false) ?
              p.get('quantity') : 0) * (p.has('price', false) ?
              p.get('price') : 0)),
          );
          return prescriptionData;
        }, data);
      }

      if (bill.isVoid()) {
        data.total_fee = `${UNICODE.EMDASH}`;
      }
      const fees = convertNumberToPrice(bill.get('total_amount', ''));
      if (fees === this.props.config.get('currency_shorthand_label', 'RM')) {
        data.total_fee = `${UNICODE.EMDASH}`;
      }
      data.total_fee = fees;

      data = salesItems.reduce((salesData, item) => {
        const salesItem = mappedData.billItemsSalesItemMappedData.get(item.get('_id'));
        salesData.sales_item_summary.push(
          `${salesItem ? salesItem.get('name') : UNICODE.EMDASH} - ${item.get('quantity')}`,
        );
        salesData.sales_item_price.push(
          convertNumberToPrice(item.get('price', '')),
        );
        salesData.sales_item_total.push(
          convertNumberToPrice((item.has('quantity', false) ?
            item.get('quantity') : 0) * (item.has('price', false) ?
            item.get('price') : 0)),
        );
        return salesData;
      }, data);
      return data;
    }
    return data;
  }

  /**
   * gets data for columns specific to prescriptions in table, for each row
   * @param {EncounterModel} encounter Encounter model.
   * @param {{}} mappedData the flattened data with differents maps specific to bill, encounter etc
   * @returns {{}}.
   */
  getPrescriptionDetails(
    encounter: EncounterModel,
    mappedData: {[key: string]: MapValue} = {},
  ): {[key: string]: Array<MapValue>} {
    const { prescriptions } = mappedData.encountersMappedData.get(encounter.get('_id'), {});
    const data = {
      rx_summary: [],
      rx_name: [],
      rx_dosage: [],
      rx_reason: [],
      rx_notes: [],
      rx_price: [],
      rx_qty: [],
      rx_total: [],
    };
    if (prescriptions && prescriptions.size > 0) {
      return prescriptions.reduce((prescriptionData, p) => {
        const drug = mappedData.prescriptionsDrugMappedData.get(p.get('_id'));
        prescriptionData.rx_summary.push(`${drug ?
          `${drug.get('name')} - ${p.get('sale_quantity', '')} ${drug.getDispensationUnit()}`
          : UNICODE.EMDASH}`);
        prescriptionData.rx_name.push(`${drug ?
          `${drug.get('name')}`
          : UNICODE.EMDASH}`);
        prescriptionData.rx_dosage.push(`${p.getDosage()}`);
        prescriptionData.rx_reason.push(`${p.get('reason', UNICODE.EMDASH)}`);
        prescriptionData.rx_notes.push(`${p.get('notes', UNICODE.EMDASH)}`);
        prescriptionData.rx_price.push(p.has('sale_price', false) ?
          `${convertNumberToPrice(p.get('sale_price'))}` : UNICODE.EMDASH);
        prescriptionData.rx_qty.push(p.has('sale_quantity', false) ?
          `${p.get('sale_quantity')}` : UNICODE.EMDASH);
        prescriptionData.rx_total.push(`${convertNumberToPrice((p.has('sale_quantity', false) ?
          p.get('sale_quantity') : 0) * (p.has('sale_price', false) ?
          p.get('sale_price') : 0))}`);
        return prescriptionData;
      }, data);
    }
    return data;
  }

  /**
   * Returns Lab test details
   * @param {EncounterModel} encounter The encounterModel
   * @returns {LabTestData}
   */
  getLabTestData(encounter: EncounterModel): LabTestData {
    const { procedureTypes, procedureRequests, providers, procedureResults } = this.props;
    const procedureRequest = procedureRequests.find(p => p.get('encounter_id') === encounter.get('_id') && p.get('patient_id') === encounter.get('patient_id'));
    const resultAssets = procedureRequest && procedureRequest.getResultAssets(procedureResults);
    const data = {
      lab_test: procedureRequest ? procedureRequest.getTypeName(procedureTypes) : UNICODE.EMDASH,
      lab_test_id: (procedureRequest && procedureRequest.getType(procedureTypes))
        ? (procedureRequest.getType(procedureTypes)?.getTestCode() || UNICODE.EMDASH)
        : UNICODE.EMDASH,
      lab_vendor: procedureRequest
        ? procedureRequest.getProviderName(procedureTypes, providers) : UNICODE.EMDASH,
      lab_sale_price: procedureRequest ? procedureRequest.getType(procedureTypes)?.get('price') : UNICODE.EMDASH,
      lab_results:
        (procedureRequest &&
          resultAssets &&
          (resultAssets.length
            ? this.renderLabResult(resultAssets)
            : translate('not_uploaded'))) ||
        UNICODE.EMDASH,
    };
    return data;
  }

  /**
   * Renders button and lab reuslt component
   * @param {Array<AssetObject>} assets Lab result assets
   * @returns {React.Component}
   */
  renderLabResult(assets: Array<AssetObject>) {
    return (
      <div>
        <LabResult
          assets={assets}
          isProcedureResultModalVisible={this.state.isLabResultVisible}
          onClose={() => this.setState({ isLabResultVisible: false })}
          onClickedUpload={() => {}}
          noUploadButton
        />
        <Button
          type="button"
          className="o-button o-button--small"
          onClick={() => this.setState({ isLabResultVisible: true })}
          dataPublic
        >
          {translate('view_results')}
        </Button>
      </div>
    );
  }

  /**
   * Returns an array of objects with the entries for the list of encounetrs in a format
   * to be dispayed in table view.
   * @returns {Array<ConsultationHistoryTableRow>}
   */
  getRows(): Array<ConsultationHistoryTableRow> {
    const mappedData = this.getMappedData() || {}; // flattened data for preparing rows, to avoid nested loops
    return this.props.encounters
      .map((ec) => {
        const prescriptionData = this.getPrescriptionDetails(ec, mappedData);
        const salesData = this.getSalesDetails(ec, mappedData);
        return {
          date_and_time: ec.getStartTime(),
          encounter_type: ec.getEncounterType(this.props.salesItems),
          encounter_status: `${translate(ec.getLastEventType())}`,
          doctor: ec.getDoctorName(this.props.practitioners, true),
          symptoms: this.getSymptoms(ec, mappedData.encountersMappedData),
          diagnoses: this.getDiagnosis(ec, mappedData.encountersMappedData),
          notes: ec.getNotes(this.props.practitioners, this.props.encounterStageMap),
          rx_summary: prescriptionData.rx_summary && prescriptionData.rx_summary.length ?
            prescriptionData.rx_summary.join('\n') : UNICODE.EMDASH,
          rx_name: prescriptionData.rx_name && prescriptionData.rx_name.length ?
            prescriptionData.rx_name.join('\n') : UNICODE.EMDASH,
          rx_dosage: prescriptionData.rx_dosage && prescriptionData.rx_dosage.length ?
            prescriptionData.rx_dosage.join('\n') : UNICODE.EMDASH,
          rx_reason: prescriptionData.rx_reason && prescriptionData.rx_reason.length ?
            prescriptionData.rx_reason.join('\n') : UNICODE.EMDASH,
          rx_notes: prescriptionData.rx_notes && prescriptionData.rx_notes.length ?
            prescriptionData.rx_notes.join('\n') : UNICODE.EMDASH,
          rx_price: prescriptionData.rx_price && prescriptionData.rx_price.length ?
            prescriptionData.rx_price.join('\n') : UNICODE.EMDASH,
          rx_qty: prescriptionData.rx_qty && prescriptionData.rx_qty.length ?
            prescriptionData.rx_qty.join('\n') : UNICODE.EMDASH,
          rx_total: prescriptionData.rx_total && prescriptionData.rx_total.length ?
            prescriptionData.rx_total.join('\n') : UNICODE.EMDASH,
          mc_time_chit: this.getMcTimeChit(ec, mappedData.encountersMappedData),
          documents: this.getDocumentData(ec),
          dispense_summary: salesData.dispense_summary && salesData.dispense_summary.length ?
            salesData.dispense_summary.join('\n') : UNICODE.EMDASH,
          dispense_price: salesData.dispense_price && salesData.dispense_price.length ?
            salesData.dispense_price.join('\n') : UNICODE.EMDASH,
          dispense_total: salesData.dispense_total && salesData.dispense_total.length ?
            salesData.dispense_total.join('\n') : UNICODE.EMDASH,
          sales_item_summary: salesData.sales_item_summary && salesData.sales_item_summary.length ?
            salesData.sales_item_summary.join('\n') : UNICODE.EMDASH,
          sales_item_price: salesData.sales_item_price && salesData.sales_item_price.length ?
            salesData.sales_item_price.join('\n') : UNICODE.EMDASH,
          sales_item_total: salesData.sales_item_total && salesData.sales_item_total.length ?
            salesData.sales_item_total.join('\n') : UNICODE.EMDASH,
          ...this.getLabTestData(ec),
          total_fee: salesData.total_fee ? salesData.total_fee : UNICODE.EMDASH,
          actions: this.getActions(ec, mappedData.encountersMappedData),
          id: ec.get('_id'),
        };
      })
      .toArray();
  }

  /**
   * updates columns according to configuration settings to show/hide and returns new array of updated columns
   * to be dispayed in table view.
   * @returns {Array<Column>}
   */
  updateColumnConfig(): Array<Column> {
    let columnsCount = 0;
    const consultHistoryPermissions = getConsultHistoryPermissions(this.props.user);
    const newColumns = columns.map((column) => {
      const showConfig = this.props.config
        .getIn(['encounters', 'history_section', 'columns', column.accessor]);
      const show = column.accessor === 'actions' ? true : showConfig;
      if (show) {
        if ((!consultHistoryPermissions.hasPrescriptionViewPermission &&
          columnsForPermissionCheck.viewPrescriptions.contains(column.accessor)) ||
          (!consultHistoryPermissions.hasConditionsViewPermission &&
            columnsForPermissionCheck.viewConditions.contains(column.accessor)) ||
            (!consultHistoryPermissions.hasNotesViewPermission &&
              columnsForPermissionCheck.viewNotes.contains(column.accessor)) ||
              (!consultHistoryPermissions.hasBillViewPermission &&
                columnsForPermissionCheck.viewBill.contains(column.accessor)) ||
                (!consultHistoryPermissions.hasMcTcViewPermission &&
                  columnsForPermissionCheck.viewMcTc.contains(column.accessor)) ||
                  (!consultHistoryPermissions.hasLabViewPermission &&
                    columnsForPermissionCheck.viewLabConditions.contains(column.accessor))) {
          return Object.assign({}, column, { show: false });
        }
        columnsCount += 1;
      }
      return Object.assign({}, column, { show });
    }).toArray();
    if (columnsCount > 10) {
      // assign className to column with Action button menu, to adjust positioning
      // when there is scrollbar for the table
      return newColumns.map((column) => {
        const className = column.accessor === 'actions' ? 'c-consultation-history-table-column' : '';
        return className ? Object.assign({}, column, { className }) : column;
      });
    }
    return newColumns;
  }

  /**
   * Row properties with on click handler function to pass to table component,
   * this will set state values needed to display row info modal
   * @param {MapValue} state state of ReactTable.
   * @param {MapValue} rowInfo info object about the row clicked, along with data sent to table
   * @returns {TrProps} properties to be used for row
   */
  getTrProperties = (state: MapValue, rowInfo: MapValue): TrProps => ({
    onClick: (event: SyntheticInputEvent<*>) => {
      // check for action button click
      event.stopPropagation();
      const targetClassName = (event.target && event.target.className) || '';
      if ((targetClassName.indexOf('AriaMenuButton') < 0
        && targetClassName.indexOf('o-button o-button--small') < 0)
      ) {
        this.setState({
          showRowInfo: true,
          encounterID: (rowInfo && rowInfo.original && rowInfo.original.id
            ? rowInfo.original.id : ''),
        });
      }
    },
  })

  /**
   * callback to be called after the row details modal is closed.
   * @returns {void}.
   */
  onModalClose = (): void => {
    this.setState({
      showRowInfo: false,
    });
  }

  /**
   * Handle modal visibility of Lab Request form modal
   * @param {boolean} state modal visibility
   * @returns {void}
   */
  handleLabRequestsFormModalVisibility = (state: boolean) => {
    this.setState({ isLabRequestsFormModalVisible: state, orderLabRequestModalEncounterID: '' });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const data = this.getRows();
    const defaultPageSize = this.props.config
      .getIn(['encounters', 'history_section', 'default_page_size_selected']);
    const columnsUpdated = this.updateColumnConfig();
    const filteredProcedureRequests = this.props.procedureRequests
      .filter(procedureRequest => procedureRequest.get('encounter_id') === (this.state.orderLabRequestModalEncounterID || this.state.encounterID) &&
      procedureRequest.get('patient_id') === this.props.patient.get('_id') && procedureRequest.get('status') !== 'cancelled');
    const filteredSpecimens = this.props.specimens
      .filter(specimen =>
        specimen.get('patient_id') === this.props.patient.get('_id') &&
        filteredProcedureRequests.filter(procedureRequest =>
          specimen.get('procedure_request_id') === procedureRequest.get('_id')));
    return (
      <React.Fragment>
        { this.state.orderLabRequestModalEncounterID &&
          <PermissionWrapper permissionsRequired={List([createPermission('past_encounters_lab_tests', 'read')])} user={this.props.user}>
            <LabRequestsList
              providers={this.props.providers}
              procedureTypes={this.props.procedureTypes}
              procedureRequests={filteredProcedureRequests}
              procedureStatuses={this.props.procedureStatuses}
              procedureResults={this.props.procedureResults}
              specimens={filteredSpecimens}
              saveModel={this.props.saveModel}
              saveModels={this.props.saveModels}
              user={this.props.user}
              config={this.props.config}
              updateConfig={this.props.updateConfig}
              patientID={this.props.patient.get('_id')}
              patientName={this.props.patient.get('patient_name')}
              encounterID={this.state.orderLabRequestModalEncounterID}
              isLabRequestsFormModalVisible={this.state.isLabRequestsFormModalVisible}
              handleLabRequestsFormModalVisibility={this.handleLabRequestsFormModalVisibility}
              hideLabRequestsModalButton
              hideLabRequestsTable
              collectedSpecimens={this.props.collectedSpecimens}
            />
          </PermissionWrapper>
        }
        <TableContainer>
          <Table
            columns={columnsUpdated}
            data={data}
            noDataText={translate('no_previous_consultations')}
            defaultSorted={[{ id: 'date_and_time', desc: true }]}
            showPagination
            defaultPageSize={defaultPageSize}
            trProperties={this.getTrProperties}
          />
          {this.state.showRowInfo ?
            <ConsultationHistoryDetails
              onClose={this.onModalClose}
              encounterID={this.state.encounterID}
              {...this.props}
              procedureTypes={this.props.procedureTypes}
              procedureRequests={filteredProcedureRequests}
            />
            : ''}
        </TableContainer>
      </React.Fragment>
    );
  }
}

export default ConsultationHistoryTable;
