/**
 * Returns the filter for the given path
 * @param  {string} path The pathname to extract filter from
 * @returns {string}
 */
export function getFilter(path: string): string | null {
  const ps = path.split('/');
  return (ps.includes('analytics') && ps[ps.indexOf('analytics') + 2]) || null;
}

/**
 * Returns the interval for the given path
 * @param  {string} path The pathname to extract filter from
 * @returns {string}
 */
export function getInterval(path: string): string | null {
  const ps = path.split('/');
  const interval = ps.includes('analytics') && ps[ps.indexOf('analytics') + 1];
  return (
    (['daily',
      'weekly',
      'monthly',
      'today',
    ].includes(interval || '') && interval) || null);
}
