import React from 'react';

import translate from './../../utils/i18n';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import { getConfirmation } from './../../utils/utils';

import type { SaveModel, Model } from './../../types';
import Button from './../buttons/button';
import { debugPrint } from '../../utils/logging';

type Props = {
  item?: Model,
  saveModel?: SaveModel,
  label?: string,
  confirmTitle?: string | React.ReactNode,
  successMessage?: string,
  callback?: (model: Model) => void,
  dataPublic?: boolean,
  className?: string,
  onDelete?: () => void | Promise<any>,
  confirmModalTitle?: string, // Title for confirm Modal
  proceedButton?: string,// Proceed button label
  hasFeedback?: boolean, // Used to show loading indicator in confirm modal
};

/**
 * A delete button compoenet that is commonly used. This takes a model and sets its flag to true/false based on
 * the flag to be used in the model. model will need method "setDeleted()". to set individual flags. For eg: documents
 * like lookup types eg: banks can set flag "hidden" to true, on delete where as payements are voided, so this can be handled
 * in the respective setDeleted method.
 * @class DeleteButton
 * @extends {React.Component<Props, State>}
 */
class DeleteButton extends React.PureComponent<Props> {
  /**
 * Called when delete is clicked on an item. Updates its state to be hidden/voided.
 * @param {Model} item The item to delete
 * @param {SaveModel} saveModel saveModel function
 * @returns {void}
 */
  onDeleteClicked() {
    const { item, onDelete, hasFeedback } = this.props;
    getConfirmation(this.props.confirmTitle || translate('confirm_item_deletion'),
      { modalTitle: this.props.confirmModalTitle, footerSaveButtonName: this.props.proceedButton, onProceed: hasFeedback && onDelete })
      .then(
        () => {
          if (item) {
            this.props.saveModel(item.setDeleted(true))
              .then((model) => {
                if (model && model.get) {
                  createSuccessNotification(this.props.successMessage || translate('item_deleted'));
                  if (this.props.callback) {
                    this.props.callback(model);
                  }
                }
              })
              .catch((error) => {
                debugPrint(error, 'error');
                createErrorNotification(translate('something_went_wrong'));
              });
          } else if (onDelete) {
            onDelete();
          }
        },
        () => {},
      );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Button
        className={this.props.className || 'o-text-button o-text-button--danger'}
        onClick={() => this.onDeleteClicked()}
        dataPublic={this.props.dataPublic}
      >
        {this.props.label || translate('delete')}
      </Button>
    );
  }
}

export default DeleteButton;
