import { connect } from 'react-redux';

import LabTests from './../components/labTests/labTests';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State } from './../types';


/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ providers, procedureTypes, config, user, specimens }: State) => ({
  providers: providers.filter(p => p.isActive()),
  procedureTypes: procedureTypes.filter(p => p.isActive()),
  config,
  user,
  specimens,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const LabTestsContainer = connect(mapStateToProps, mapDispatchToProps)(LabTests);

export default LabTestsContainer;
