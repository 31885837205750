import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import { renderWithLink } from './../../utils/tables';
import { isToday } from './../../utils/time';
import Header from './../header/header';

import type PatientStubModel from './../../models/patientStubModel';

type Props = {
  patients: List<PatientStubModel>,
};

/**
 * Returns an object with appropriate fields for a Table row populated from a PatientModel.
 * @param {PatientStubModel} patient The patient the button is for.
 * @returns {object} A table row object.
 */
function getTableRow(patient) {
  return {
    case_id: patient.get('case_id', UNICODE.EMDASH),
    name: patient.get('patient_name', UNICODE.EMDASH),
    link: `/patient/${patient.get('_id')}`,
  };
}

/**
 * A table for displaying patients scheduled using the pre-Encounter style of scheduling.
 * @class LegacyScheduleTable
 * @extends {React.Component<Props, State>}
 */
class LegacyScheduleTable extends React.PureComponent<Props> {
  props: Props;

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const patients = this.props.patients
      .filter(p => p.has('appt_date') && isToday(p.get('appt_date')));
    return (
      <section className="o-card">
        <Header className="o-card__header">
          <h1 className="o-card__title" data-public>{translate('today')}</h1>
          <span className="o-card__header__right-aligned-text">{patients.size}</span>
        </Header>
        <Table
          columns={[
            { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink },
            {
              accessor: 'name', Header: translate('name'), minWidth: 200, Cell: renderWithLink,
            },
          ]}
          data={patients.map(patient => getTableRow(patient)).toArray()}
          defaultSorted={[{ id: 'name', desc: false }]}
          noDataText={translate('no_patients')}
        />
      </section>
    );
  }
}

export default LegacyScheduleTable;
