import * as React from 'react';
import ReactMinimap from 'react-minimap';

type Props = {
  children: React.Node,
  selector: string,
  className: string,
  childComponent?: () => React.Node,
};


/**
 * A Minimap HOC
 * @class Minimap
 * @extends {React.PureComponent<Props>}
 */
class Minimap extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className={this.props.className}>
        <ReactMinimap
          selector={this.props.selector}
          childComponent={this.props.childComponent}
          width={108}
          height={155}
        >
          {this.props.children}
        </ReactMinimap>
      </div>
    );
  }
}

export default Minimap;
