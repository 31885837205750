import React from 'react';
import Moment, { Moment as IMoment } from 'moment';
import { List, Map } from 'immutable';
import { memoize } from 'lodash';

import { convertToCSV, downloadCSV } from './../../utils/export';
import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import ContentTransition from './../contentTransition';
import Radio from './../inputs/radio';
import Input from './../inputs/input';
import Select from './../inputs/select';
import Checkbox from './../inputs/checkbox';
import MenuButton from './../buttons/menuButton';
import SaveButton from './../buttons/saveButton';
import DateRangePicker from './../inputs/dateRangePicker';
import { fetchModels } from './../../utils/db';
import { fetchFilteredTransaction } from './../../utils/api';
import { prettifyDateTime } from './../../utils/time';
import { mergeTransactionsBySourceAndSupplyItemId, updateTransactionWithTotalStock } from './../../utils/inventory';
import { printPrescriptionsReport } from './../../utils/print';
import { sidebarWidth } from './../../utils/css';
import { compareByAlphabeticalOrder, sortByNumber, sortByPayment } from './../../utils/comparators';
import { fixedDecimal, validateAndTrimString } from './../../utils/utils';
import DrugDispensationTable from './drugDispensationTable';
import { UNICODE } from './../../constants';
import TransactionModel from '../../models/transactionModel';
import Header from './../header/header';
import FormError from './../formError';

import BillItemModel from '../../models/billItemModel';
import BillModel from '../../models/billModel';
import type { Config, SelectOpts, Row } from './../../types';
import type PatientStubModel from '../../models/patientStubModel';
import type DrugModel from '../../models/drugModel';
import type SupplierModel from '../../models/supplierModel';
import type SupplyItemModel from '../../models/supplyItemModel';

const PSYCHOTROPIC_REGISTER = 'PSYCHOTROPIC_REGISTER';
const POISON_REGISTER = 'POISON_REGISTER';
const SUPPLY_IN_TRANSACTION_TYPES = ['supply_item', 'transfer_in', 'loan_in', 'bonus'];
const SUPPLY_OUT_TRANSACTION_TYPES = ['transfer_out', 'loan_out'];
const BILL_TRANSACTION_TYPES = ['bill', 'bill_item'];
const SUPPLY_TRANSACTION_TYPES = SUPPLY_IN_TRANSACTION_TYPES.concat(SUPPLY_OUT_TRANSACTION_TYPES);

const COLUMNS = List([
  { value: 'number', label: '#' },
  { value: 'date', label: translate('date_supplied_administered_received'), dispensedOnlyLabel: translate('date_of_sale_supply') },
  { value: 'serialNumber', label: translate('serial_number'), dispensedOnlyLabel: translate('serial_no_of_prescription'), filterable: true },
  { value: 'patientName', label: translate('name_of_patient'), filterable: true },
  { value: 'patientAddress', label: translate('address_of_patient'), filterable: true },
  { value: 'patientDob', label: translate('patient_dob'), filterable: true },
  { value: 'patientSex', label: translate('patient_sex'), filterable: true },
  { value: 'patientIc', label: translate('ic_passport_number_of_patient'), filterable: true },
  { value: 'supplierNameAndAddress', label: translate('supplier_name_and_address'), filterable: true },
  { value: 'from', label: translate('from'), filterable: true, hideDefault: true },
  { value: 'to', label: translate('to'), filterable: true, hideDefault: true },
  { value: 'user', label: translate('user'), filterable: true, hideDefault: true },
  { value: 'poisonsMedicineSupplied', label: translate('poisons_medicine_supplied'), filterable: true },
  { value: 'tags', label: translate('tags'), filterable: true },
  { value: 'supplyItemBatchID', label: translate('batch_id'), hideDefault: false, filterable: true },
  { value: 'inventoryChange', label: translate('quantity_change'), filterable: true },
  { value: 'totalInventoryQuantity', label: translate('total_stock_in_possession'), filterable: true },
  { value: 'dispensationUnit', label: translate('dispensation_unit'), filterable: true },
  { value: 'transactionType', label: translate('transaction_type'), filterable: true },
  { value: 'notes', label: translate('notes'), filterable: true },
  { value: 'supplyInvoiceNumber', label: translate('invoice_#'), hideDefault: true },
  { value: 'supplyDeliveryOrderNumber', label: translate('delivery_order_#'), hideDefault: true },
  { value: 'supplyPurchaseOrderNumber', label: translate('purchase_order_#'), hideDefault: true },
  { value: 'supplyDocNumber', label: translate('doc_#'), hideDefault: true },
  { value: 'supplyItemExpiryDate', label: translate('expiry_date'), hideDefault: true },
  { value: 'supplyItemTotalPrice', label: translate('total_price'), hideDefault: true, sortMethod: sortByPayment },
]);
// Create an empty row as a template for each row in the table/print out.
const EMPTY_ROW = COLUMNS.reduce((a, c) => ({ ...a, [c.value]: UNICODE.EMDASH }), {});

type Props = {
  patientStubs: List<PatientStubModel>,
  drugs: List<DrugModel>,
  config: Config,
  suppliers: List<SupplierModel>,
  tagList: SelectOpts,
  isFetching: boolean,
  currentDataViewsError?: APIError,
};

type State = {
  dispensedOnly: boolean,
  groupDrugs: boolean,
  columns: Array<string>,
  title: string,
  isPrinting: boolean,
  startDate: IMoment,
  endDate: IMoment,
  drugId?: string,
  tag?: string,
  isFetching: boolean,
  isFiltered: boolean,
  data: List<Row>,
  filteredData: List<Row>,
  errorMessage?: string,
  transactions: List<TransactionModel>,
};

/**
 * A component for selecting the format of printed prescription report.
 * @class DrugDispensation
 * @extends {React.Component<Props, State>}
 */
class DrugDispensation extends React.Component<Props, State> {
  /**
   * Creates an instance of DrugDispensation.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      dispensedOnly: true,
      groupDrugs: false,
      title: 'Prescription Report',
      isPrinting: false,
      columns: COLUMNS.filter(c => !c.hideDefault).map(c => c.value).toArray(),
      startDate: Moment().startOf('day'),
      endDate: Moment().endOf('day'),
      drugId: undefined,
      tag: undefined,
      isFetching: true,
      isFiltered: false,
      data: List(),
      filteredData: List(),
      errorMessage: undefined,
      transactions: List(),
    };
  }

  /**
   * Sets the preset if a matching value is found.
   * @param {string} value The preset value.
   * @returns {void}
   */
  setPreset(value: string) {
    if (value === POISON_REGISTER) {
      this.setState({
        title: 'Prescription Register for Poisons',
        dispensedOnly: true,
        groupDrugs: false,
        columns: ['number', 'date', 'serialNumber', 'patientName', 'patientAddress', 'poisonsMedicineSupplied', 'inventoryChange', 'dispensationUnit'],
        drugId: undefined,
        tag: this.props.tagList.find(t => t.value === 'Poison') ? 'Poison' : undefined,
      });
    } else if (value === PSYCHOTROPIC_REGISTER) {
      this.setState({
        title: 'Prescription Register for Psychotropic Substances',
        dispensedOnly: false,
        groupDrugs: true,
        columns: ['number', 'date', 'serialNumber', 'supplierNameAndAddress', 'inventoryChange', 'totalInventoryQuantity'],
        drugId: undefined,
        tag: this.props.tagList.find(t => t.value === 'Psychotropic') ? 'Psychotropic' : undefined,
      });
    }
  }

  /**
   * fetch data after component is loaded
  * @return {void}
   */
  componentDidMount() {
    this.fetchTransactions();
  }

  /**
   * Triggers a data fetch when either date or the dispensed only state changes.
   * @param {Props} prevProps Previous Props
   * @param {State} prevState Previous State
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      if (this.state.isFiltered) {
        this.fetchTransactions();
      } else {
        this.fetchData(this.state.transactions || List());
      }
    } else if (prevState.dispensedOnly !== this.state.dispensedOnly ||
      prevState.drugId !== this.state.drugId ||
      prevState.tag !== this.state.tag
    ) {
      this.fetchData(this.state.transactions || List());
    }
  }

  /**
   * Triggers a data fetch when either date or the dispensed only state changesto get and set transactions.
   * @returns {void}
   */
  fetchTransactions() {
    this.setState({ isFetching: true });
    fetchFilteredTransaction(this.state.startDate.valueOf(), this.state.endDate.valueOf())
      .then((models) => {
        this.setState({ isFetching: false });
        if (!models.error) {
          const transactions = models.isFiltered ?
            models.transactions :
            updateTransactionWithTotalStock(models.transactions
              .sort((a, b) => sortByNumber(a.getTimestamp(), b.getTimestamp())));
          this.setTransactions(transactions, models.isFiltered);
          this.fetchData(transactions);
        }
      }).catch(() => this.setState({ isFetching: false }));
  }

  /**
   * sets the transactions updated with total stcok count in state.
   * @param {List<TransactionModel>} transactions all transactions
   * @param {boolean} isFiltered are the transactions already filtered from backend
   * @returns {void}
   */
  setTransactions(transactions: List<TransactionModel>, isFiltered: boolean = false) {
    this.setState({ transactions, isFiltered });
  }

  /**
   * Gets the labels for the selected columns.
   * @returns {Array<string>}
   */
  getColumnNames(): Array<string> {
    return this.getColumns(true)
      .map(c => c.label)
      .toArray();
  }

  /**
   * Returns the name of the supplier passed. If null returns UNICODE.EMDASH
   * @param {SupplierModel | void} supplier the supplier model.
   * @returns {string} supplier name or UNICODE.EMDASH
   */
  getSupplierName(supplier: SupplierModel | void): string {
    return supplier ? validateAndTrimString(supplier.get('name')) : UNICODE.EMDASH; // supplier name
  }

  /**
   * Returns the transactions models meeting the criteria of the current filter.
   * @param {List<TransactionModel>}  allTransactions all transactions
   * @returns {List<TransactionModel>}
   */
  getFilteredTransactions(allTransactions: List<TransactionModel>): List<TransactionModel> {
    return allTransactions
      .filter(
        transaction =>
          (!this.state.drugId || transaction.get('sku_id') === this.state.drugId) &&
          (this.state.isFiltered || this.state.startDate.isSameOrBefore(transaction.getTimestamp(), 'day')) &&
          (this.state.isFiltered || this.state.endDate.isSameOrAfter(transaction.getTimestamp(), 'day')),
      );
  }

  /**
   * returns an array of source doc ids from a list of transaction models
   * @param {List<TransactionModel>} transactions Transaction models.
   * @returns {string[]}
   */
  getSourceDocIds(transactions: List<TransactionModel>) {
    return transactions.map(t => t.getSourceDoc())
      .filter(t => typeof t === 'string')
      .toArray() as Array<string>;
  }

  /**
   * Fetches the data items for the given transactions with source_type 'bill'.
   * @param {List<TransactionModel>} transactions Transaction models.
   * @param {Map<string, BillModel>} billsMap A map of bills.
   * @returns {Promise<List<{}>>}
   */
  getDataForBillTransactions(
    transactions: List<TransactionModel>,
    billsMap: Map<string, BillModel>,
  ) {
    const billTransactions = transactions.filter(t => t.get('source_type') === 'bill');
    const validTransactions = billTransactions.filter((transaction) => {
      const sourceID = transaction.getSourceDoc();
      const bill = billsMap.get(sourceID || '');
      return bill && !bill.isVoid() && bill.isFinalised(); // Remove transactions for voided or non-existant bills. Also unfinalised, however this can't really happen atm.
    });
    const mergedTransactions = mergeTransactionsBySourceAndSupplyItemId(validTransactions);
    return this.getRowForBillAndBillItem(mergedTransactions, billsMap);
  }

  /**
   * Fetches the data items for the given transactions with source_type 'bill'.
   * @param {List<TransactionModel>} mergedTransactions Transaction models.
   * @param {Map<string, BillModel>} billsMap A map of bills.
   * @param {Map<string, BillItemModel>} billItemsMap A map of bill items.
   * @returns {Promise<List<{}>>}
   */
  getRowForBillAndBillItem(
    mergedTransactions: List<TransactionModel>,
    billsMap: Map<string, BillModel>,
    billItemsMap?: Map<string, BillItemModel>,
  ) {
    const supplyItemsIds = mergedTransactions.toSeq().map(t => t.get('supply_item_id', '')).filter(id => id !== 'UNKNOWN').toArray();
    return (supplyItemsIds.length < 0) ?
      Promise.resolve(List()) :
      fetchModels(supplyItemsIds, true)
        .then((models) => {
          const supplyItemMap = models.size > 0 ? Map(models.map(t => [t.get('_id'), t])) : Map();
          return mergedTransactions.map((transaction) => {
            const sourceID = transaction.getSourceDoc();
            const billItem = sourceID && billItemsMap?.get(sourceID);
            const bill = billItem ? billsMap.get(billItem.get('bill_id', '')) : billsMap.get(sourceID || '');
            const patient = bill && this.props.patientStubs.find(p => p.get('_id') === bill.get('patient_id'));
            const supplyItem = supplyItemMap.get(transaction.get('supply_item_id', ''));
            return Object.assign(
              {},
              EMPTY_ROW,
              { supplyItemBatchID: supplyItem && supplyItem.get('batch_id', UNICODE.EMDASH) },
              this.getTransactionData(transaction),
              this.getPatientData(patient),
              {
                notes: bill ? bill.get('notes', UNICODE.EMDASH, false) : UNICODE.EMDASH,
                link: bill ? `/patient/${bill.get('patient_id')}/billing/${bill.get('encounter_id')}` : '',
              },
            );
          });
        });
  }


  /**
   * Fetches the data items for the given transactions with source_type 'bill_item'.
   * @param {List<TransactionModel>} transactions Transaction models.
   * @param {Map<string, BillItemModel>} billItemsMap A map of bill items.
   * @param {List<string>} billIdsFromBillItem a list of bill ids from the bill items.
   * @returns {Promise<List<{}>>}
   */
  getDataForBillItemTransactions(
    transactions: List<TransactionModel>,
    billItemsMap: Map<string, BillItemModel>,
    billIdsFromBillItem: List<string>,
  ) {
    const billItemTransactions = transactions.filter(t => t.get('source_type') === 'bill_item');
    const billIds = billIdsFromBillItem.toArray();
    return fetchModels(billIds, true)
      .then((bills) => {
        const validTransactions = billItemTransactions.filter((transaction) => {
          const sourceId = transaction.getSourceDoc();
          const billItem = billItemsMap.get(sourceId || '');
          const bill = billItem ?
            bills.find(b => b && b.get('_id') === billItem.get('bill_id')) : undefined;
          return billItem !== undefined && // If it was undefined that means it was deleted and is now invalid (there should be matching outgoing and incoming transactions for such cases)
            bill !== undefined && // Remove transactions for voided or non-existant bills. Also unfinalised, however this can't really happen atm.
            !bill.isVoid() &&
            bill.isFinalised();
        });
        const mergedTransactions = mergeTransactionsBySourceAndSupplyItemId(validTransactions); // It is unclear if this is actually necessary.
        const billsMap = Map(bills.map(t => [t.get('_id'), t]));
        return this.getRowForBillAndBillItem(mergedTransactions, billsMap, billItemsMap);
      });
  }

  /**
   * Fetches all source docs for the transactions.
   * @param {List<TransactionModel>} transactions Transaction models.
   * @returns {Promise<List<{BillItemModel}>>}
   */
  getSourceDocs(transactions: List<TransactionModel>) {
    const sourceDocIds = this.getSourceDocIds(transactions);
    return fetchModels(sourceDocIds, true)
      .then(models => models.reduce((acc, model) => {
        if (model && (model.get('type') === 'bill')) {
          return {
            ...acc,
            bills: acc.bills.set(model.get('_id'), model),
          };
        }
        if (model && (model.get('type') === 'bill_item')) {
          return {
            ...acc,
            billItems: acc.billItems.set(model.get('_id'), model),
            billIdsFromBillItem: acc.billIdsFromBillItem.push(model.get('bill_id')),
          };
        }
        if (model && (model.get('type') === 'supply_item')) {
          return {
            ...acc,
            supplyItems: acc.supplyItems.set(model.get('_id'), model),
            supplyIdsFromSupplyItems: acc.supplyIdsFromSupplyItems.push(model.get('supply_id')),
          };
        }
        return acc;
      }, {
        bills: Map<string, BillModel>(),
        billItems: Map<string, BillItemModel>(),
        billIdsFromBillItem: List(),
        supplyItems: Map<string, SupplyItemModel>(),
        supplyIdsFromSupplyItems: List(),
      }));
  }


  /**
   * Fetches the data items for the given transactions with source_type 'supply_item'.
   * @param {List<TransactionModel>} transactions transaction models.
   * @param {Map<string, SupplyItemModel>} supplyItemsMap supplyItem models map.
   * @param {List<string>} supplyIdsFromSupplyItems supply ids from supply items map.
   * @returns {Promise<List<{}>>}
   */
  getDataForSupplyItemTransactions(
    transactions: List<TransactionModel>,
    supplyItemsMap: Map<string, SupplyItemModel>,
    supplyIdsFromSupplyItems: List<string>,
  ) {
    const supplyItemTransactions = transactions.filter(
      t => SUPPLY_TRANSACTION_TYPES.includes(t.get('source_type')),
    );
    const clinic = this.props.config.getIn(['clinic', 'name'])
      ? this.props.config.getIn(['clinic', 'name']) : UNICODE.EMDASH;
    const supplyIds = supplyIdsFromSupplyItems.toArray();
    return fetchModels(supplyIds, true)
      .then(
        supplies => supplyItemTransactions.map((transaction) => {
          const supplyItem = supplyItemsMap.get(transaction.getSourceDoc() as string);
          const supply = supplyItem ? supplies.find(s => s && s.get('_id') === supplyItem.get('supply_id')) : undefined;
          const supplier = supply ? this.props.suppliers.find(s => s && s.get('_id') === supply.get('supplier_id')) : undefined;
          const [from, to] = (SUPPLY_IN_TRANSACTION_TYPES.includes(transaction.get('source_type')) && [this.getSupplierName(supplier), clinic])
          || (SUPPLY_OUT_TRANSACTION_TYPES.includes(transaction.get('source_type')) && [clinic, this.getSupplierName(supplier)])
          || (UNICODE.EMDASH, UNICODE.EMDASH);
          return Object.assign(
            {},
            EMPTY_ROW,
            this.getTransactionData(transaction),
            {
              from,
              to,
              supplierNameAndAddress: SUPPLY_OUT_TRANSACTION_TYPES.includes(transaction.get('source_type')) ? clinic : (supplier && supplier.getNameAndAddress()) || UNICODE.EMDASH,
              notes: supplyItem && supplyItem.get('notes', UNICODE.EMDASH, false),
              supplyInvoiceNumber: supply && supply.get('invoice_number', UNICODE.EMDASH),
              supplyDeliveryOrderNumber: supply && supply.get('delivery_order_number', UNICODE.EMDASH),
              supplyPurchaseOrderNumber: supply && supply.get('purchase_order_number', UNICODE.EMDASH),
              supplyDocNumber: supply && supply.get('doc_number', UNICODE.EMDASH),
              supplyItemBatchID: supplyItem && supplyItem.get('batch_id', UNICODE.EMDASH),
              supplyItemExpiryDate: supplyItem && supplyItem.get('expiry_date', UNICODE.EMDASH),
              supplyItemTotalPrice: (
                transaction.hasPurchasePriceData()
                && supplyItem && supplyItem.getPriceData()
              ) || UNICODE.EMDASH,
              link: '/inventory/transaction-history',
            },
          );
        }),
      );
  }

  /**
   * Fetches the data items for the given transactions with a source_type that is not
   * bill, bill_item, or supply_item or no source_type value is set.
   * @param {List<TransactionModel>} transactions Transaction models.
   * @returns {Promise<List<{}>>}
   */
  getDataForOtherTransactions(transactions: List<TransactionModel>): Promise<List<{}>> {
    const otherTransactions = transactions
      .filter(t => !((SUPPLY_TRANSACTION_TYPES.concat(BILL_TRANSACTION_TYPES)).includes(t.get('source_type'))));
    return Promise.resolve(otherTransactions.map(transaction => Object.assign(
      {},
      EMPTY_ROW,
      this.getTransactionData(transaction),
      {
        notes: transaction.get('notes', UNICODE.EMDASH, false),
        link: '/inventory/transaction-history',
      },
    )));
  }

  /**
   * Returns an object with transaction data that applies to all transaction types.
   * @param {TransactionModel} transaction The transaction model
   * @returns {{}}
   */
  getTransactionData = memoize((transaction: TransactionModel) => {
    const drug = this.props.drugs.find(d => d.get('_id') === transaction.get('sku_id'));
    const tags = drug ? drug.getTags() : UNICODE.EMDASH;
    return {
      serialNumber: transaction.get('_id'),
      drugId: transaction.get('sku_id'), // Used internally for filtering
      transactionType: transaction && translate(transaction.getType()),
      date: transaction.getTimestamp(),
      formattedDate: prettifyDateTime(transaction.getTimestamp()),
      inventoryChange: transaction.getChange(),
      totalInventoryQuantity: fixedDecimal(transaction.totalAfterChange),
      poisonsMedicineSupplied: drug ? drug.get('name') : UNICODE.EMDASH,
      tagsArray: drug ? drug.get('tags', []) : [], // Used internally for filtering
      tags: tags.length ? tags : UNICODE.EMDASH,
      dispensationUnit: drug ? drug.getDispensationUnit() : UNICODE.EMDASH,
      user: transaction.getCreator(),
    };
  }, (transaction: TransactionModel) => transaction.get('_id'))

  /**
   * Returns an object with patient data that applies to all relevant transaction types.
   * @param {PatientStubModel | void} patient The patient model. Although it should generally always
   * be available there are cases where import issues may lead to patients not existing in the DB.
   * @returns {{}}
   */
  getPatientData = memoize((patient: PatientStubModel | void) => {
    const patientName = patient ?
      patient.get('patient_name', UNICODE.EMDASH, false) : UNICODE.EMDASH;
    const patientAddress = patient ? patient.get('address', UNICODE.EMDASH, false) : UNICODE.EMDASH;
    return {
      patientName,
      patientAddress,
      patientDob: patient ? patient.get('dob', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      patientSex: patient ? patient.get('sex', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      supplierNameAndAddress: patient ? `${patientName}\n${patientAddress}` : UNICODE.EMDASH,
      patientIc: patient ? patient.get('ic', UNICODE.EMDASH, false) : UNICODE.EMDASH,
    };
  }, (patient: PatientStubModel | void) => patient && patient.attributes._id)

  /**
   * calls the data fetch method after filtering transactions and sets data in state.
   * @param {List<TransactionModel>}  allTransactions all transactions
   * @returns {void}
   */
  fetchData(allTransactions: List<TransactionModel>) {
    if (this.state.startDate === null || this.state.endDate === null) {
      this.setState({ data: List() });
    } else {
      this.setState({ isFetching: true });
      const transactions = this.getFilteredTransactions(allTransactions);
      if (transactions && transactions.size > 0) {
        const dataFetchingTasks = this.getAllData(transactions);
        dataFetchingTasks.then(results => this.setState({
          data: this.getDrugFilteredData(
            results.reduce((a, b) => a.concat(b), List()),
          ),
          isFetching: false,
        }));
      } else {
        this.setState({
          data: List(),
          isFetching: false,
        });
      }
    }
  }

  /**
   * Asyncly fetches the data needed to populate the table/printout for the current filter.
   * @param {List<TransactionModel>}  transactions filtered transactions
   * @returns {Promise}
   */
  getAllData(transactions: List<TransactionModel>): Promise<Array<{
    bills: Map<string, BillModel>,
    billItems: Map<string, BillItemModel>,
    billIdsFromBillItem: List<any>,
    supplyItems: Map<string, SupplyItemModel>,
    supplyIdsFromSupplyItems: List<any>,
}>> {
    return this.getSourceDocs(transactions).then((docObj) => {
      if (this.state.dispensedOnly) {
        return Promise.all([
          this.getDataForBillTransactions(transactions, docObj.bills),
          this.getDataForBillItemTransactions(
            transactions,
            docObj.billItems,
            docObj.billIdsFromBillItem,
          ),
        ]);
      }
      return Promise.all([
        this.getDataForBillTransactions(transactions, docObj.bills),
        this.getDataForBillItemTransactions(
          transactions,
          docObj.billItems,
          docObj.billIdsFromBillItem,
        ),
        this.getDataForSupplyItemTransactions(
          transactions,
          docObj.supplyItems,
          docObj.supplyIdsFromSupplyItems,
        ),
        this.getDataForOtherTransactions(transactions),
      ]);
    });
  }

  /**
   * Returns the current data in state filtered by drug and drug tag (if those have been set).
   * @returns {List<Row>}
   */
  getFilteredData(): List<Row> {
    let { filteredData } = this.state;
    if (this.state.drugId) {
      filteredData = filteredData.filter(d => d.drugId === this.state.drugId);
    }
    if (this.state.tag) {
      filteredData = filteredData.filter(d => d.tagsArray.includes(this.state.tag));
    }
    return filteredData;
  }

  /** Validates title field
   * @returns {void}
   */
  isTitleValid() {
    return validateAndTrimString(this.state.title).length;
  }

  /**
   * filters the data in state by drug id and drug tag and sets this in state.data to pass to the table.
   * This is different than the filtered data returned from table, this is the data specific to UI filters
   * used in the page.
   * @param {List<Row>} data the data to be filtered
   * @returns {List<Row>}
   */
  getDrugFilteredData(data: List<Row>): List<Row> {
    if (this.state.drugId && this.state.tag) {
      return data.filter(d =>
        d.drugId === this.state.drugId && d.tagsArray.includes(this.state.tag));
    } else if (this.state.tag) {
      return data.filter(d => d.tagsArray.includes(this.state.tag));
    } else if (this.state.drugId) {
      return data.filter(d => d.drugId === this.state.drugId);
    }
    return data;
  }

  /**
   * Handles print being clicked.
   * @returns {void}
   */
  onPrintClicked() {
    if (!this.isTitleValid()) {
      this.setState({ errorMessage: translate('fill_required_fields') });
    } else {
      this.setState({ isPrinting: true });
      let sortedData = this.getFilteredData();
      if (this.state.groupDrugs) {
        sortedData = sortedData.sort(
          (a, b) =>
            compareByAlphabeticalOrder(a.poisonsMedicineSupplied, b.poisonsMedicineSupplied),
        );
      }
      printPrescriptionsReport(
        this.props.config,
        validateAndTrimString(this.state.title) as string,
        this.getColumnNames(),
        sortedData.toArray(),
        this.state.startDate,
        this.state.endDate,
        this.getColumns(true).map(c => c.value).toArray(),
        this.state.groupDrugs,
      ).then(() => this.setState({ isPrinting: false }));
    }
  }

  /**
   * Downloads a CSV file of the drug dispensation.
   * @return {void}
   */
  onExportClicked() {
    if (!this.isTitleValid()) {
      this.setState({ errorMessage: translate('fill_required_fields') });
    } else {
      const filename = this.state.title ? `${validateAndTrimString(this.state.title)}.csv` : 'Drug Dispensation.csv';
      const dataAsArrays = this.getFilteredData()
        .map(
          (row, index) => this.getColumns(true)
            .map((column) => {
              if (column.value === 'number') {
                return String(index + 1);
              } else if (column.value === 'date') {
                return prettifyDateTime(row[column.value]);
              }
              return row[column.value];
            })
            .toArray(),
        )
        .toArray();
      downloadCSV(filename, convertToCSV(this.getColumnNames(), dataAsArrays));
    }
  }

  /**
   * Gets the columns with label set to the appropriate label
   * @param {boolean} filterForSelected If true it filters out any unselected columns
   * @returns {List<{ value: string, label: string }>}
   */
  getColumns(filterForSelected: boolean = false): List<{ value: string, label: string }> {
    const columns = filterForSelected ?
      COLUMNS.filter(column => this.state.columns.find(c => c === column.value)) : COLUMNS;
    return columns.map(column => (
      this.state.dispensedOnly && column.dispensedOnlyLabel ?
        { ...column, label: column.dispensedOnlyLabel } :
        { ...column, label: column.label }
    ));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const hasData = this.getFilteredData().size > 0;
    return (
      <ContentTransition>
        <section className="o-scrollable-container" style={{ height: '100vh', maxWidth: `calc(100vw - ${sidebarWidth})` }}>
          <h1 data-public className="o-title">{translate('drug_dispensation_reports')}</h1>
          <div className="o-card">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{ translate('print_options') }</h1>
              <MenuButton
                className="u-margin-left--auto u-margin-right--1ws"
                items={[
                  { label: translate('poison_register'), value: POISON_REGISTER, dataPublic: true },
                  { label: translate('psychotropic_register'), value: PSYCHOTROPIC_REGISTER, dataPublic: true },
                ]}
                onValueSelected={value => this.setPreset(value)}
                label={translate('presets')}
                dataPublic
              />
            </Header>
            <section className="u-margin--standard">
              { this.state.errorMessage &&
                <div className="u-margin--standard">
                  <FormError>{translate(this.state.errorMessage)}</FormError>
                </div>
              }
              <Input
                id="title"
                label={translate('title')}
                value={this.state.title}
                required
                onValueChanged={(title: string) => this.setState({ title })}
              />
              <DateRangePicker
                label={translate('date_range')}
                id="date-range"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                maxDate={Moment(new Date())}
                onValueChanged={({ startDate, endDate }) => {
                  // DRP allows undefined as a value but we don't want to allow that.
                  let parsedEndDate = endDate;
                  if (!parsedEndDate) {
                    parsedEndDate = startDate && startDate.isAfter(this.state.endDate) ?
                      startDate :
                      this.state.endDate;
                  }
                  this.setState({
                    startDate: startDate || this.state.startDate,
                    endDate: parsedEndDate.clone().endOf('day'),
                  });
                }}
              />
              <Radio
                id="group_drugs"
                label={translate('group_drugs')}
                options={[
                  { value: 'true', label: translate('yes') },
                  { value: 'false', label: translate('no') },
                ]}
                value={this.state.groupDrugs ? 'true' : 'false'}
                onValueChanged={value => this.setState({ groupDrugs: value === 'true' })}
                required
                disabled={this.state.drugId !== undefined && this.state.drugId !== ''}
              />
              <Radio
                id="dispensed_only"
                label={translate('dispensed_only')}
                options={[
                  { value: 'true', label: translate('yes') },
                  { value: 'false', label: translate('no') },
                ]}
                value={this.state.dispensedOnly ? 'true' : 'false'}
                onValueChanged={value => this.setState({ dispensedOnly: value === 'true' })}
                required
              />
              <Select
                label={translate('drug')}
                id="drug"
                onValueChanged={value => this.setState({ drugId: value })}
                options={this.props.drugs.map(d => ({
                  label: d.isVisible() ? d.get('name') : `${d.get('name')} (${translate('inactive')})`,
                  value: d.get('_id'),
                })).toArray()}
                value={this.state.drugId}
              />
              <Select
                label={translate('tag')}
                id="tag"
                onValueChanged={tag => this.setState({ tag })}
                options={this.props.tagList}
                value={this.state.tag}
              />
              <Checkbox
                id="columns"
                label={translate('columns')}
                labelClassName="o-label"
                options={this.getColumns().toArray()}
                value={this.state.columns}
                onValueChanged={toggledColumn => this.setState({
                  columns: this.state.columns.includes(toggledColumn) ?
                    this.state.columns.filter(c => c !== toggledColumn) :
                    this.state.columns.concat([toggledColumn]),
                })}
                multiColumn
              />
            </section>
            <footer className="o-card__footer">
              <SaveButton
                dataPublic
                className="o-button--small u-margin-right--1ws"
                label={translate('export')}
                disabled={this.state.isFetching || !hasData}
                onClick={() => this.onExportClicked()}
              />
              <SaveButton
                dataPublic
                isSaving={this.state.isPrinting}
                className="o-button--small u-margin-right--1ws"
                label={translate('print')}
                disabled={this.state.isFetching || !hasData}
                onClick={() => this.onPrintClicked()}
              />
            </footer>
          </div>
          <DrugDispensationTable
            groupByDrugs={this.state.groupDrugs}
            columns={
              this.getColumns(true)
                .filter(v => v.value !== 'number')
                .toArray()
            }
            data={this.state.data}
            isFetching={this.state.isFetching || this.props.isFetching}
            currentDataViewsError={this.props.currentDataViewsError}
            onFilterAndSort={filteredData => this.setState({ filteredData: List(filteredData) })}
          />
        </section>
      </ContentTransition>
    );
  }
}

export default DrugDispensation;
