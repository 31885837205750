import { connect } from 'react-redux';
import { List } from 'immutable';
import Moment from 'moment';

import ConsultReports from './../components/reports/consultReports';
import AsyncFetch from './../components/asyncFetch';
import {
  setConsultReportsFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
  setCurrentDataViewsError,
} from './../actions';
import { getConsultReportsDataViews } from './../dataViews';
import { combineDateAndTime } from './../utils/time';

import type { Dispatch, State, MapValue, DataView } from './../types';
import APIError from '../utils/apiError';
import BaseModel from '../models/baseModel';
import { getModelMapFromList } from '../utils/models';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => {
  const reportsDataViewStartKey = combineDateAndTime(
    state.reportsFilter.getIn(['consults', 'filterDateStart'], new Moment()),
    state.reportsFilter.getIn(['consults', 'filterTimeStart'], new Moment().minutes(0).hours(0).seconds(0)),
  );
  const filterTimeEnd = combineDateAndTime(
    state.reportsFilter.getIn(['consults', 'filterDateEnd'], new Moment()),
    state.reportsFilter.getIn(['consults', 'filterTimeEnd'], new Moment().minutes(59).hours(23).seconds(59)),
  ); // removed adding 59 secs to time filter used in UI. this will add additional minute and will cause filtering going wrong
  // eg: if the consultation start time is 10:59, the time 10:58 selected will include this.
  return {
    user: state.user,
    filter: state.reportsFilter.get('consults'),
    config: state.config,
    drugs: state.drugs,
    encounters: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'encounter'),
    bills: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'bill' && !m.isVoid() && m.isFinalised()),
    isFetching: state.isFetching,
    patientStubs: state.patientStubs,
    coveragePayors: state.coveragePayors,
    salesItems: getModelMapFromList(state.salesItems),
    practitioners: state.practitioners,
    containerDataViews: getConsultReportsDataViews(
      reportsDataViewStartKey.valueOf(),
      filterTimeEnd.valueOf(),
    ),
    currentDataViews: state.currentDataViews,
    showLoadingIndicator: false,
    filterTimeStart: reportsDataViewStartKey,
    filterTimeEnd,
    paymentTypes: state.paymentTypes.filter(p => p.isVisible()),
    currentDataViewsError: state.currentDataViewsError,
    procedureTypes: getModelMapFromList(state.procedureTypes),
    providers: state.providers,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, MapValue>) => dispatch(setConsultReportsFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const ConsultReportsContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(ConsultReports));

export default ConsultReportsContainer;
