import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PharmaConnectDetailing from './../components/pharmaconnect/detailing';
import { setPharmaConnectSmallDetailing } from './../actions';

import type { Dispatch, State } from './../types';
import type DetailingResponseModel from '../models/pharmaconnect/detailingResponseModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({
    pharmaconnectViews,
  }: State) => ({
    detailing: pharmaconnectViews.smallDetailing,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDetailing: (detailing: DetailingResponseModel) =>
    dispatch(setPharmaConnectSmallDetailing(detailing)),
});

const PharmaConnectDetailingContainer =
withRouter(connect(mapStateToProps, mapDispatchToProps)(PharmaConnectDetailing));

export default PharmaConnectDetailingContainer;
