import * as React from 'react';
import InputMask from 'react-input-mask';
import moment, { Moment as IMoment } from 'moment';

import Label from './label';
import { getTimeFormat } from './../../utils/time';

type Props = {
  autoFocus?: boolean,
  className: string,
  description?: string,
  disabled?: boolean,
  hideLabel?: boolean,
  id: string,
  label?: string,
  labelClassName?: string,
  onChange: (moment?: IMoment) => void,
  required?: boolean,
  style?: { [string: string ]: string | number },
  value?: IMoment,
  inputStyle?: React.CSSProperties,
}
const MASK_CHAR = '_';

/**
 * Takes a string and replaces any of the letters h or m with the number 9. This can be used as the mask for the input
 * @param {string} timeFormat A time format
 * @returns {string} A mask of timeFormat.
 */
function getMask(timeFormat: string): string {
  return timeFormat.replace(/[Hm]/gi, '9');
}

/**
 * Creates a TimeInput component that shows a masked input for a time.
 * @param {object} props The component props.
 * @returns {React.Component} A TimeInput component.
 */
class TimeInput extends React.Component<Props, { value: string }> {
  static defaultProps = {
    className: '',
    style: {},
  };

  /**
   * Creates an instance of TimeInput.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value && props.value !== '' ? props.value.format(getTimeFormat()) : '',
    };
  }

  /**
   * Updates state when receiving new props.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      if (nextProps.value === undefined || nextProps.value === '') {
        this.setState({ value: getMask(getTimeFormat()).replace(/9/g, MASK_CHAR) });
      } else if (nextProps.value && moment.isMoment(nextProps.value)) {
        this.setState({ value: nextProps.value.format(getTimeFormat()) });
      }
    }
  }

  /**
   * Returns true if given value is a valid time.
   * @param {string} value The time value
   * @returns {boolean} True if value is valid
   */
  isValidTime(value: string): boolean {
    return moment(value, getTimeFormat(), true).isValid();
  }

  /**
   * Handle state on input change.
   * @param {SyntheticInputEvent} event Change event.
   * @returns {void}
   */
  onChange(event: SyntheticInputEvent<*>) {
    const { value } = event.target;
    if (value === getMask(getTimeFormat()).replace(/9/g, MASK_CHAR)
      && this.props.value !== undefined) {
      this.props.onChange(); // Mask is empty, set value to undefined.
    } else if (this.isValidTime(value)) {
      this.props.onChange(moment(value, getTimeFormat()));
    } else {
      this.setState({ value });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let description = '';
    if (this.props.description) {
      description = <p className="o-sub-label">{ this.props.description }</p>;
    }
    const inputProps = {
      autoFocus: this.props.autoFocus,
      className: this.isValidTime(this.state.value) || this.state.value === undefined || this.state.value === '' ? 'o-text-input' : 'o-text-input o-text-input--invalid',
      name: this.props.id,
      onChange: event => this.onChange(event),
      placeholder: getTimeFormat(),
      value: this.state.value,
      disabled: this.props.disabled,
      required: this.props.required,
      style: this.props.inputStyle,
    };
    return (
      <div className={`o-form__item ${this.props.className}`} style={this.props.style}>
        {
          !this.props.hideLabel &&
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label || ''}
            invalid={this.props.required && (!this.props.value || this.state.value.length === 0)}
          />
        }
        { description }
        <InputMask key={`timeInputMask-${this.props.id}`} {...inputProps} mask={getMask(getTimeFormat())} maskChar={MASK_CHAR} />
      </div>
    );
  }
}


export default TimeInput;
