import { List } from 'immutable';
import BaseModel from './../baseModel';
import type { PharmaConnectBrandDocument } from './../../types';

type attributesType = {
  _id: string,
  type: 'pharmaconnect_brands_query_response',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,

  brands: List<PharmaConnectBrandDocument>,
}

/**
   * BrandResponseModel
   *
   * @namespace BrandResponseModel
   */
class BrandResponseModel extends BaseModel {
  attributes: attributesType;

  /**
     * @param {object} attributes - The attributes for this model.
     */
  constructor(attributes: {}) {
    super(attributes);
    this.attributes.type = 'pharmaconnect_brands_query_response';
    this.attributes.brands = List(attributes.brands);
  }
}

export default BrandResponseModel;
