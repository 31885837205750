import { connect } from 'react-redux';
import { List, Set } from 'immutable';

import DrugDispensation from './../components/inventory/drugDispensation';
import { compareByAlphabeticalOrder } from './../utils/comparators';
import AsyncFetch from './../components/asyncFetch';
import { getSupplierDataViews } from './../dataViews';
import { setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, setCurrentDataViewsError } from './../actions';

import type { State, SelectOpts, Dispatch, DataView } from './../types';
import type DrugModel from './../models/drugModel';
import type APIError from '../utils/apiError';
import type BaseModel from '../models/baseModel';

/**
* Formats the coverage payor models for the Select input and sorts them alphabetically.
* @param {List<DrugModel>} drugs A list of coverage payor models.
* @returns {SelectOpts} An array of options for the select input.
*/
function getTagList(drugs: List<DrugModel>): SelectOpts {
  return drugs
    .reduce((tagSet, drug) => (
      drug.get('tags') ? tagSet.concat(drug.get('tags')) : tagSet
    ), Set())
    .sort((a, b) => compareByAlphabeticalOrder(a, b))
    .map(tag => ({
      value: tag,
      label: tag,
    }))
    .toArray();
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, patientStubs, drugs, isFetching, currentDataViews, currentDataViewsModels,
  currentDataViewsError,
}: State) =>
  ({
    config,
    patientStubs,
    drugs,
    tagList: getTagList(drugs),
    isFetching,
    containerDataViews: getSupplierDataViews(),
    currentDataViews,
    suppliers: isFetching ? List() : currentDataViewsModels.filter(m => m && m.get('type') === 'supplier'),
    currentDataViewsError,
  });

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const PrescriptionReportsContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(DrugDispensation));

export default PrescriptionReportsContainer;
