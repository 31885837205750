import { List, OrderedMap, Map, Record, is } from 'immutable';
import memoizeOne from 'memoize-one';

import type { OrderedMap as OrderedMapType } from 'immutable';

import type { Store, Dispatch } from 'redux';
import { saveFactory } from './../utils/redux';
import type { CountPerSKUAndBatch, State, DrugMap } from './../types';
import { Action, setInventoryCount, setIsSKUCountSyncing } from './../actions';

import { fetchInventoryCountByBatch, fetchData, saveInventoryMapping, suggestInventoryItem, fetchInventoryCount } from './api';
import { getMasterDrugDataViews, getVerifiedDrugDataView, getCompletedSuggestionDrugDataView, getInventoryMappingDataViews, getPrescribedInventoryMappingDataViews } from '../dataViews';
import TransactionModel from './../models/transactionModel';
import {
  setBatchInventoryCount,
  setInventoryCountSyncStatus,
  setVerifiedDrugModels,
  setMasterDrugModels,
  setDrugSuggestionModels,
  updateDrugSuggestionModel,
  replaceCurrentDataViewsModel,
  updateCurrentDataViewsModel,
  updateVerfiedDrugsModel,
  deleteVerifiedDrugModel,
  deleteDrugSuggestionModel,
} from './../actions';
import DrugModel from '../models/drugModel';
import { debugPrint } from './logging';
import InventoryMapModel from '../models/inventoryMapModel';
import InventoryDrugModel from '../models/inventoryDrugModel';
import BaseModel from '../models/baseModel';
import DrugSuggestionModel from '../models/drugSuggestionModel';
import type { Attributes as DrugSuggestionAttributes } from '../models/drugSuggestionModel';
import { INVENTORY_MAPPING_STATUSES, UNICODE } from '../constants';
import translate from './i18n';
import APIError from './apiError';
import { handleApiError } from './response';

/* eslint-disable camelcase */
type rowData = {
  sku_id: String,
  supply_item_id: String,
}
/* eslint-enable camelcase */


export const SkuStock = Record({
  skuStockRemaining: 0,
  batchCount: 0,
  batches: Map(),
}, 'Sku Stock Information');

export const BatchStock = Record({
  batchStockRemaining: 0,
  index: null,
}, 'Batch Stock Information');

/**
 * Takes a list of transaction models and merges any with the same SKU, then filters any where the
 * change amounts to 0. Should be used only for single actions (e.g. finalising a bill).
 * @param {List<TransactionModel>} transactions A list of transactions
 * @returns {Array<TransactionModel>} The merged and filter list of transactions
 */
export function flattenTransactions(transactions: List<TransactionModel>): List<TransactionModel> {
  let mergedTransactions = List();
  transactions.forEach((transaction) => {
    const match = mergedTransactions.find(t => t.get('sku_id') === transaction.get('sku_id'));
    if (match) {
      match.set('change', Number((match.get('change') + transaction.get('change')).toFixed(4)));
    } else {
      mergedTransactions = mergedTransactions.push(transaction);
    }
  });
  return mergedTransactions.filter(t => t.get('change') !== 0);
}

/**
 * This is to merge all transactions pertaining to single [sku, source_id, supply_item]
 * For example when there are multiple transaction docs for dispensation matching single bill, one transaction doc
 * with sum of all values for change in all docs with its totalAfterChange value will be used in table if it belongs to
 * the same supply.
 * These transactions have same timestamp value and are ordered by latest edited by in ascending so the last one
 * must have the latest value.
 * @param {List<TransactionModel>} transactions A list of transactions ordered edited_by ascending
 * @returns {List<TransactionModel>} The merged and filter list of transactions
 */
export function mergeTransactionsBySourceAndSupplyItemId(
  transactions: List<TransactionModel>,
): List<TransactionModel> {
  return transactions.reduce(
    (mergedTransactions :OrderedMapType<string, TransactionModel>,
      transaction: TransactionModel) => {
      const key = transaction.get('sku_id', '') +
        (transaction.getSourceDoc() || '') + transaction.get('supply_item_id', '');
      if (key) {
        const match = mergedTransactions.get(key);
        if (match) {
          const transactionMerged = new TransactionModel(transaction.copyData({
            change: transaction.get('change', 0) + match.get('change', 0),
          }));
          transactionMerged.setTotalAfterChange(transaction.totalAfterChange);
          return mergedTransactions.set(key, transactionMerged);
        }
        return mergedTransactions.set(key, transaction);
      }
      return mergedTransactions;
    }, OrderedMap<string, TransactionModel>(),
  )
    .toList()
    .filter(t => t.get('change') !== 0);
}

/**
 * Calculates the total of the inventory for that particular SKU after that transaction and appends
 * it to the model. This function uses transactions ordered by timestamp in transaction doc.
 * @param {List<TransactionModel>} sortedTransactions All Transaction Models which are sorted by specific time
 * @returns {List<TransactionModel>} The transaction models with the total appended.
 */
export function updateTransactionWithTotalStock(sortedTransactions: List<TransactionModel>) {
  let currentTotalPerSKU = Map();
  return sortedTransactions
    .reduce((updatedTransactions, transaction) => {
      const SKU = transaction.get('sku_id');
      currentTotalPerSKU = currentTotalPerSKU.set(SKU,
        currentTotalPerSKU.get(SKU, 0) + transaction.get('change', 0));
      const cloneTransaction = new TransactionModel(transaction.copyData());
      cloneTransaction.setTotalAfterChange(currentTotalPerSKU.get(SKU, 0));
      return updatedTransactions.push(cloneTransaction);
    }, List());
}

/**
 * Checks the inventory count map and sets the inventoryCountSyncStatus in redux store.
 * @param {Redux.store} store A handle to the redux store
 * @returns {void}
 */
function updateInventoryCountSyncStatus(store: Store<State, Action>) {
  const { inventoryCountSyncStatus, inventoryCount } = store.getState();
  const path: string = window.location.hash;
  const inventoryUrlPattern = /^#\/+inventory($|\/+)/g;
  if (inventoryCountSyncStatus.size === 0) {
    if (inventoryUrlPattern.test(path)) { // If the path is from inventory section we load the batches in descending order first.
      store.dispatch(setInventoryCountSyncStatus('DESC'));
    } else {
      store.dispatch(setInventoryCountSyncStatus('ASC'));
    }
  } else if (inventoryCount.every((count) => {
    const batches = count.get('batches', Map());
    return batches.size >= count.get('batchCount') && batches.every(batch => batch.get('index') !== null);
  })) {
    store.dispatch(setInventoryCountSyncStatus('STOP'));
  } else if (
    inventoryCountSyncStatus.includes('ASC') &&
    inventoryCountSyncStatus.includes('DESC') &&
    !inventoryCountSyncStatus.includes('SYNC')
  ) {
    // SYNC is used to differentiate between the initial rounds of sync which are location dependent
    // and the later continuous sync. It keeps the inventorySyncStatus cleaner.
    store.dispatch(setInventoryCountSyncStatus('SYNC'));
  } else if (!inventoryCountSyncStatus.includes('ASC')) {
    store.dispatch(setInventoryCountSyncStatus('ASC'));
  } else if (!inventoryCountSyncStatus.includes('DESC')) {
    store.dispatch(setInventoryCountSyncStatus('DESC'));
  }
}

/**
 * Check the current data views if it is of inventory mapping.
 * @param {Redux.store} store store
 * @returns {Boolean}
 */
function isInventoryMappingModelsInitialised(store: Store<State, Action>) {
  const { currentDataViews } = store.getState();
  return is(currentDataViews, getInventoryMappingDataViews())
  || is(currentDataViews, getPrescribedInventoryMappingDataViews());
}


/**
 * Get drug Id
 * @param {InventoryMapModel | DrugModel} item item
 * @returns {string}
 */
function getDrugID(item: InventoryMapModel | DrugModel) {
  if (item && item.get('type') === 'inventory_map') {
    return item.get('drug_id');
  } else if (item && item.get('type') === 'drug') {
    return item.get('_id');
  }
  return undefined;
}

/**
 * Returns the InventoryMapModel if typeof item is InventoryMapModel or
 * returns DrugModel if item is DrugModel and currentDataViewsModels does not have an item with drug_id of item
 * @param {Redux.store} store store
 * @param {InventoryMapModel | DrugModel} item inventory items to update.
 * @param {List<InventoryMapModel>} currentDataViewsModels currentDataViewsModels.
 * @returns {InventoryMapModel | DrugModel}
 */
function resolveInventoryMapModel(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  currentDataViewsModels: List<InventoryMapModel>,
): InventoryMapModel | DrugModel {
  if (item && item.get('type') === 'inventory_map') {
    return item;
  }
  const inventoryMapModel = isInventoryMappingModelsInitialised(store)
    && currentDataViewsModels.find(i => i && (i.get('drug_id') === item.get('_id')));
  return inventoryMapModel || item;
}

/**
 * Returns the correct mapping status depending on the events.
 * @param {string} event triggered event from the different modals.
 * @returns {string}
 */
function getMappingStatus(event: string): string {
  if (event === 'confirm_drug') {
    return INVENTORY_MAPPING_STATUSES.VERIFIED;
  } else if (event === 'reject_drug') {
    return INVENTORY_MAPPING_STATUSES.REJECTED;
  } else if (event === 'add_drug_details' || event === 'add_non_drug_details') {
    return INVENTORY_MAPPING_STATUSES.PENDING_REVIEW;
  }
  return '';
}

/**
 * Updates suggestions in store.
 * @param {Redux.store} store store
 * @param {InventoryMapModel | DrugModel} item item
 * @param {object} update update
 * @returns {void}
 */
function updateDrugSuggestions(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  update: Object,
) {
  const drugSuggestionModel = new DrugSuggestionModel({ ...update, drug_id: getDrugID(item) });
  store.dispatch(updateDrugSuggestionModel(drugSuggestionModel));
}

/**
 * Updates verifiedDrugs in store.
 * @param {Redux.store} store store
 * @param {InventoryMapModel | DrugModel} item item
 * @param {object} update update
 * @returns {void}
 */
function updateVerifiedDrugs(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  update: Object,
) {
  const drugId = getDrugID(item);
  const attrs = {
    drug_id: drugId,
    drug_master_id: update && update.master_drug_id,
    mapping_status: INVENTORY_MAPPING_STATUSES.VERIFIED,
  };
  const inventoryDrugModel = new InventoryDrugModel(attrs);
  store.dispatch(updateVerfiedDrugsModel(inventoryDrugModel));
}

/**
 * Replace inventory map model from current data views model
 * @param {Redux.store} store store
 * @param {InventoryMapModel | DrugModel} item item
 * @param {object} update update
 * @param {string} event event
 * @returns {BaseModel}
 */
function replaceInventoryMapModelInCurrentDataViewModels(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  update: Object,
  event: string,
) {
  const mappingStatus = getMappingStatus(event);
  const attrs = {
    mapping_status: mappingStatus,
    drug_master_id_maps: mappingStatus === INVENTORY_MAPPING_STATUSES.VERIFIED ?
      [update.master_drug_id] :
      item.get('drug_master_id_maps'),
  };
  const newInventoryMapModel = new InventoryMapModel(item.copyData(attrs));
  store.dispatch(replaceCurrentDataViewsModel(item, newInventoryMapModel));
  return newInventoryMapModel;
}

/**
 * Updates the new inventory map model in current data views model
 * @param {redux.Store} store store
 * @param {InventoryMapModel | DrugModel} item item
 * @param {object} update update
 * @param {string} event event
 * @returns {void}
 */
function updateInventoryMapModelInCurrentDataViewModels(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  update: Object,
  event: string,
) {
  const mappingStatus = getMappingStatus(event);
  const attrs = {
    name: item.get('name'),
    drug_id: item.get('_id'),
    manufacturer: item.get('manufacturer'),
    mapping_status: mappingStatus,
    drug_master_id_maps: mappingStatus === INVENTORY_MAPPING_STATUSES.VERIFIED ?
      [update.master_drug_id] : [],
    mapping_category: 1,
    mapping_priority: 0,
  };
  const newInventoryMapModel = new InventoryMapModel(attrs);
  store.dispatch(updateCurrentDataViewsModel(newInventoryMapModel));
  return newInventoryMapModel;
}

/**
 * Updates mapping store values.
 * @param {Redux.store} store store
 * @param {InventoryMapModel | DrugModel} item item
 * @param {object} update update
 * @param {string} event event
 * @returns {BaseModel}
 */
function updateCurrentDataViewModelsAndSuggestions(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  update: Object,
  event: 'confirm_drug' | 'reject_drug' | 'add_drug_details' | 'add_non_drug_details',
) {
  const isInventoryMapModel = item && (item.get('type') === 'inventory_map');
  const drugId = getDrugID(item);
  // adds the drug suggestions to store.
  if (event === 'add_drug_details' || event === 'add_non_drug_details') {
    updateDrugSuggestions(store, item, update);
    store.dispatch(deleteVerifiedDrugModel(drugId));
  }
  // add the verified drug to the store.
  if (event === 'confirm_drug') {
    updateVerifiedDrugs(store, item, update);
    store.dispatch(deleteDrugSuggestionModel(drugId));
  }
  // replace the current data view models with the existing drug's new inventoryMapModel.
  if (item && isInventoryMappingModelsInitialised(store) && isInventoryMapModel) {
    return replaceInventoryMapModelInCurrentDataViewModels(store, item, update, event)
    || new BaseModel();
  } else if (item && isInventoryMappingModelsInitialised(store)) {
    // update the current data view models with the new drug's inventoryMapModel.
    return updateInventoryMapModelInCurrentDataViewModels(store, item, update, event)
    || new BaseModel();
  }
  return new BaseModel();
}

/**
   * Resolves promise and modifies state after saving current batch or item update.
   * @param {Redux.store} store A handle to the redux store
   * @param {InventoryMapModel | DrugModel} item inventory items to update.
   * @param {List<Promise>} saveFns params to save.
   * @param {object} update object with which the drug or non-drug will be updated.
   * @param {string} event triggered event from the different modals.
   * @returns {Promise}
   */
function updateMappingStateChange(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  saveFns: List<Promise>,
  update: Object,
  event: 'confirm_drug' | 'reject_drug' | 'add_drug_details' | 'add_non_drug_details',
): Promise<{ inventoryItem?: InventoryMapModel | DrugModel, errorMessage: string | void }> {
  return Promise.all(saveFns)
    .then((resp) => {
      const { currentDataViewsModels } = store.getState();
      const inventoryItemToUpdate = resolveInventoryMapModel(store, item, currentDataViewsModels);
      const response = resp && resp[0];
      const mappingUpdateResp = (event === 'confirm_drug' || event === 'reject_drug')
        ? response.rows && response.rows.get(0) && response.rows.get(0).ok
        : true;
      if (response.ok && mappingUpdateResp && inventoryItemToUpdate) {
        const newModel = updateCurrentDataViewModelsAndSuggestions(
          store, inventoryItemToUpdate, update, event,
        );
        return {
          inventoryItem: newModel,
          errorMessage: undefined,
        };
      }
      return {
        errorMessage: translate('error_saving'),
      };
    });
}

/**
   * Returns a promise to invoke put mappings api to update the drug's mapping status.
   * @param {Redux.store} store A handle to the redux store
   * @param {InventoryMapModel | DrugModel} item inventory item.
   * @param {Partial<DrugSuggestionAttributes>} params update object.
   * @param {string} event param to indentify if its a confirm or pending_review.
   * @returns {void}
   */
function updateCurrentDrug(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  params: Partial<DrugSuggestionAttributes>,
  event: 'confirm_drug' | 'reject_drug',
): Promise<void> {
  if (item && (event === 'confirm_drug' || event === 'reject_drug')) {
    const acceptedID = params && params.selected_map_id;
    const update = {
      clinic_drug_id: getDrugID(item),
      master_drug_id: acceptedID,
      status: event === 'confirm_drug' ? 'verify' : 'reject',
    };
    const inventoryMappingsToUpdate = List([update]);
    const saveFn = ((event === 'confirm_drug') && acceptedID) || (event === 'reject_drug') ? saveInventoryMapping(inventoryMappingsToUpdate) : null;
    // return updateMappingStateChange(store, List([item]), List([saveFn]), update, event);
    return new Promise(resolve => resolve())
      .then(() => updateMappingStateChange(store, item, List([saveFn]), update, event));
  } return Promise.reject();
}

/**
 * @param {Redux.store} store A handle to the redux store
 * @param {InventoryMapModel | DrugModel} item inventory map model.
 * @param {Partial<DrugSuggestionAttributes>} params params
 * @param {string} event Non-Drug master id
 * @returns {void}
 */
function updateNonDrugType(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  params: Partial<DrugSuggestionAttributes>,
  event: string,
): Promise<void> {
  if (item && event === 'add_non_drug_details') {
    const saveFn = suggestInventoryItem(params, getDrugID(item));
    return new Promise(resolve => resolve())
      .then(() => updateMappingStateChange(store, item, List([saveFn]), params, event));
  } return Promise.reject();
}

/**
 * @param {Redux.store} store A handle to the redux store
 * @param {InventoryMapModel | DrugModel} item InventoryMapModel
 * @param {Partial<DrugSuggestionAttributes>} params Non-Drug master id
 * @param {string} event event that was triggered from a modal.
 * @param {DrugMap} drugMap map of drug models.
 * @returns {void}
 */
function updateDrugSuggestion(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  params: Partial<DrugSuggestionAttributes>,
  event: string,
  drugMap: DrugMap,
): Promise<void> {
  const dispensationUnit = params.dispensation_unit;
  const drug = drugMap.get(getDrugID(item));
  const saveFn = suggestInventoryItem(params, drug.get('_id'));
  if (event === 'add_drug_details') {
    return new Promise((resolve) => {
      const saveModel = saveFactory(store.dispatch);
      resolve(saveModel(drug.set('dispensation_unit', dispensationUnit)));
    })
      .then(() => updateMappingStateChange(store, item, List([saveFn]), params, event));
  } return Promise.reject();
}


/**
 * Updates the mapping status or suggestions of an inventory item.
 * @param {Redux.store} store A handle to the redux store
 * @param {InventoryMapModel | DrugModel} item inventory item.
 * @param {Partial<DrugSuggestionAttributes>} params update object.
 * @param {string} event triggered event.
 * @returns {void}
 */
export function updateInventoryItemDetails(
  store: Store<State, Action>,
  item: InventoryMapModel | DrugModel,
  params: Partial<DrugSuggestionAttributes>,
  event: string,
) {
  const drugMap = mapDrug(store.getState().drugs);
  if (item && (event === 'confirm_drug' || event === 'reject_drug')) {
    return updateCurrentDrug(store, item, params, event);
  } else if (event === 'add_non_drug_details') {
    return updateNonDrugType(store, item, params, event);
  } else if (event === 'add_drug_details') {
    return updateDrugSuggestion(store, item, params, event, drugMap);
  }
  return Promise.reject();
}


/**
 * Get the sort direction of the next inventory count fetch.
 * @param {List<string>} inventoryCountSyncStatus A stack indicating the current status of the inventory count sync
 * @returns {string} ASC | DESC
 */
function getInventoryCountSortDirection(inventoryCountSyncStatus: List<string>): ('ASC' | 'DESC') {
  const syncStatus = inventoryCountSyncStatus.get(-1);
  if (syncStatus === 'DESC') {
    return 'DESC';
  }
  return 'ASC';
}

/**
 * Get the list of skuId to fetch, limit and the remaining skuIds for this round.
 * @param {Redux.store} store A handle to the redux stor
 * @param {Array<string>} skus the sku ids needed to be synced
 * @param {number} lastLimit The last limit value used to get inventoryCounts
 * @param {Array<string>} lastRemainingSkuIds the remaining sku ids needed to be synced in the current cycle
 * @returns {Object}
 */
function getInventoryCountFilters(
  store: Store<State, Action>,
  skus?: Array<string>,
  lastLimit?: number,
  lastRemainingSkuIds?: Array<string>,
): {skuIds: 'ALL' | Array<string>, limit: number, remainingSkuIds?: Array<string>} {
  const { inventoryCountSyncStatus, inventoryCount } = store.getState();
  const LIMIT_STEP = 100;
  if (inventoryCountSyncStatus.size <= 1) {
    return {
      skuIds: 'ALL',
      limit: LIMIT_STEP,
    };
  }
  const unsyncedSkus = skus || inventoryCount
    .filter(count => count.get('batchCount') > count.get('batches').size ||
      count.get('batches').some(batch => batch.get('index') === null))
    .keySeq().toArray();
  if (
    inventoryCountSyncStatus.includes('ASC') &&
    inventoryCountSyncStatus.includes('DESC') &&
    lastLimit && lastLimit > 0
  ) {
    const TOTAL_LIMIT = 50000;
    const availableSkus = lastRemainingSkuIds && lastRemainingSkuIds.length > 0 ?
      lastRemainingSkuIds :
      unsyncedSkus;
    const limit = lastRemainingSkuIds && lastRemainingSkuIds.length > 0 ?
      lastLimit :
      lastLimit + LIMIT_STEP;
    const skuCount = Math.ceil(TOTAL_LIMIT / limit);
    const skuIds = availableSkus.slice(0, skuCount);
    const remainingSkuIds = availableSkus.slice(skuCount);
    return {
      skuIds,
      limit,
      remainingSkuIds,
    };
  }
  return {
    skuIds: unsyncedSkus,
    limit: LIMIT_STEP,
  };
}

/**
 * Fetches the current batchwise inventory numbers from the remote db and creates a map from them to be saved.
 * @param {Array<string> | 'ALL'} skuIds the sku ids needed to be synced
 * @param {boolean} showEmpty whether we need to show the empty rows
 * @param {number} limit Number of rows of batch to return
 * @param {'ASC' | 'DESC'} sortDir The sort direction of the batches
 * @param {'expiry_date' | 'supply_date'} sortBy The date field by which the batches should be sorted
 * @returns {Promise<CountPerSKUAndBatch>} A promise for the completion of the action.
 */
function getBatchInventoryCount(
  skuIds: Array<string> | 'ALL',
  showEmpty: Boolean = false,
  limit: number,
  sortDir: 'ASC' | 'DESC',
  sortBy?: 'expiry_date' | 'supply_date',
): Promise<CountPerSKUAndBatch> {
  return fetchInventoryCountByBatch(skuIds, showEmpty, limit, sortDir, sortBy || 'supply_date')
    .then((resp) => {
      if (resp.error) {
        const apiError = new APIError(
          'inventory.ts => fetchInventoryCountByBatch',
          resp.status,
          resp.json().then((data: any) => data),
        );
        throw apiError;
      }
      return resp.data
        .reduce((currentCounts: CountPerSKUAndBatch, row) => {
          const { sku_id: skuId, total_rows: totalRows } = row;
          return currentCounts.update(
            skuId,
            SkuStock(),
            skuStockData => skuStockData
              .update(
                'batches',
                batches => row.rows
                  .reduce((finalBatches, batch, index) => finalBatches
                    .set(batch._id, BatchStock({
                      batchStockRemaining: batch.stock_remaining,
                      index: !sortBy
                        ? null
                        : sortDir === 'ASC'
                          ? index
                          : totalRows - index - 1,
                    })), batches),
              )
              .set('batchCount', totalRows),
          );
        }, Map());
    })
    .catch((error) => {
      handleApiError({ error });
    });
}

/**
 * Fetches the current batchwise inventory numbers from the remote db and updates them in the app state.
 * @param {Redux.store} store A handle to the redux store
 * @param {number} lastLimit The last limit value used to get inventoryCounts
 * @param {Array<string>} lastRemainingSkuIds the remaining sku ids needed to be synced in the current cycle
 * @param {number} retry number of retry on this round
 * @returns {Promise<void>} A promise for the completion of the action.
 */
export function syncBatchInventoryCountAll(
  store: Store<State, Action>,
  lastLimit?: number,
  lastRemainingSkuIds?: Array<string>,
  retry?: number = 0,
): Promise<void> {
  if (retry === 0) {
    updateInventoryCountSyncStatus(store);
  }
  const { inventoryCountSyncStatus, config } = store.getState();
  const dispensingOrder = config.getIn(['dispensation', 'dispensing_order'], 'expiry_date');
  if (!inventoryCountSyncStatus.includes('STOP')) {
    const sortDir = getInventoryCountSortDirection(inventoryCountSyncStatus);
    const { skuIds, limit, remainingSkuIds } = getInventoryCountFilters(
      store,
      undefined,
      lastLimit,
      lastRemainingSkuIds,
    );
    getBatchInventoryCount(
      skuIds,
      true,
      limit,
      sortDir,
      (sortDir === 'DESC' && dispensingOrder !== 'supply_date') ? undefined : dispensingOrder,
    )
      .then((counts) => {
        if (counts && counts.size) {
          store.dispatch(setBatchInventoryCount(counts));
        }
      })
      .then(() => {
        syncBatchInventoryCountAll(store, limit, remainingSkuIds, 0);
      })
      .catch((e) => {
        if (retry < 5 && e.message === 'APIFAIL') {
          syncBatchInventoryCountAll(store, limit, remainingSkuIds, retry + 1);
        }
        if (retry >= 5) {
          // After the initial retry just keep on trying the current stage at 30 seconds interval
          setTimeout(
            () => syncBatchInventoryCountAll(store, limit, remainingSkuIds, retry + 1),
            30000,
          );
        }
      });
  }
  return Promise.resolve();
}

/**
 * Fetches the current batchwise inventory numbers from the remote db and updates them in the app state.
 * @param {Redux.store} store A handle to the redux store
 * @param {Array<string>} skus the sku ids needed to be synced
 * @param {boolean} showEmpty whether we need to show the empty rows
 * @param {number} lastLimit The last limit value used to get inventoryCounts
 * @param {Array<string>} lastRemainingSkuIds the remaining sku ids needed to be synced in the current cycle
 * @returns {void}
 */
export function syncBatchInventoryCountOnEvent(
  store: Store<State, Action>,
  skus: Array<string>,
  showEmpty: Boolean = true,
  lastLimit?: number,
  lastRemainingSkuIds?: Array<string>,
) {
  const { skuIds, limit, remainingSkuIds } = getInventoryCountFilters(
    store,
    skus,
    lastLimit,
    lastRemainingSkuIds,
  );
  const { config } = store.getState();
  const dispensingOrder = config.getIn(['dispensation', 'dispensing_order'], 'expiry_date');
  getBatchInventoryCount(skuIds, showEmpty, limit, 'ASC', dispensingOrder)
    .then((counts) => {
      if (counts && counts.size) {
        store.dispatch(setBatchInventoryCount(counts));
      }
      const nextSkus = counts.filter(count => count.get('batchCount') > limit).keySeq().toArray();
      if ((nextSkus && nextSkus.length) || (remainingSkuIds && remainingSkuIds.length)) {
        syncBatchInventoryCountOnEvent(
          store,
          nextSkus,
          showEmpty,
          limit,
          remainingSkuIds,
        );
      }
    });
}

/**
 * Using new transactions it updates the map cached in redux store and returns the updated map
 * @param {Redux.store} store A handle to the redux store
 * @param {List<TransactionModel>} transactions All Tnew transactions
 * @param {CountPerSKUAndBatch} inventoryCountMap the old map of inventory counts
 * @param {function} setInventoryCountPerBatch to set the map in redux store
 * @returns {void}
 */
export function updateInventoryBatchCountMap(
  store: Store<State, Action>,
  transactions: List<TransactionModel>,
  inventoryCountMap: CountPerSKUAndBatch,
) {
  // list of SKU ids, which do not have the supply/ batch in transactions in cached, we need to do new fetch
  // to get updated map for the sku since we do nto know the order where they appear
  const counts = transactions.reduce((currentCounts: CountPerSKUAndBatch, transaction) => {
    const skuId: string = transaction.get('sku_id');
    const batch: string = transaction.get('supply_item_id');
    const change: number = transaction.get('_deleted') ? (-1 * transaction.get('change', 0)) : transaction.get('change', 0);
    const currentBatches = inventoryCountMap.getIn([skuId, 'batches'], Map());
    const currentBatchCount = currentBatches.getIn([batch, 'batchStockRemaining'], 0);
    const totalRows = currentBatches.has(batch) ? currentBatches.size : currentBatches.size + 1;
    if (!currentCounts.hasIn([skuId, 'batches', batch])) {
      return currentCounts;
    }
    return currentCounts.update(
      skuId,
      SkuStock(),
      currentCount => currentCount
        .update(
          'batches',
          batches => batches.setIn([batch, 'batchStockRemaining'], currentBatchCount + change),
        )
        .set('batchCount', totalRows),
    );
  }, Map());
  if (counts && counts.size) {
    store.dispatch(setBatchInventoryCount(counts));
  }
  const skuIds = transactions.groupBy(t => t.get('sku_id')).keySeq().toArray();
  if (skuIds && skuIds.length) {
    // As an additional safety net we take this oppurtunity to sync our inventory count map for the skuis that had a transaction.
    syncBatchInventoryCountOnEvent(store, skuIds, true, 0, []);
  }
}

/**
 * Checks if the batch data for the given batches ready to be shown
 * @param {List<rowData>} visibleData All visible batch row data to be shown
 * @param {CountPerSKUAndBatch} inventoryCount the old map of inventory counts
 * @param {string} inventoryCountSyncStatus the sync status of batch inventory count
 * @returns {boolean}
 */
export function isBatchDataNotReady(
  visibleData: List<rowData>,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: 'ASC' | 'DESC' | 'SYNC' | 'STOP',
): boolean {
  return !(inventoryCountSyncStatus === 'STOP'
    || (visibleData.size && visibleData.every(s => inventoryCount.getIn([
      s.sku_id,
      'batches',
      s.supply_item_id,
      'index',
    ]))));
}

/**
* Fetches drugs with verified mapping status and linked MDL
* @param {Dispatch} dispatch redux dispatch
* @returns {void}
*/
export const fetchVerifiedDrugs = (
  dispatch: Dispatch<ReturnType<typeof setVerifiedDrugModels | typeof setMasterDrugModels>>,
) => {
  fetchData(getVerifiedDrugDataView())
    .then((verifiedDrugMappings) => {
      // @ts-ignore
      dispatch(setVerifiedDrugModels(verifiedDrugMappings.filter(m => !!m)));
      const masterDrugIds = verifiedDrugMappings.reduce((masterDrugIdLst, mapping) => {
        if (mapping && mapping.get('drug_master_id')) {
          masterDrugIdLst.push(mapping.get('drug_master_id'));
        }
        return masterDrugIdLst;
      }, []);
      fetchData(getMasterDrugDataViews(List(masterDrugIds))).then(
        (masterDrugModels) => {
          // @ts-ignore
          dispatch(setMasterDrugModels(masterDrugModels.filter(m => !!m)));
        },
      ).catch(error => debugPrint(error, 'error'));
    })
    .catch(error => debugPrint(error, 'error'));
};

/**
* Fetches completed info drugs from drug suggestion table.
* @param {Dispatch} dispatch redux dispatch
* @returns {void}
*/
export const fetchCompletedSuggestions = (
  dispatch: Dispatch<ReturnType<typeof setDrugSuggestionModels>>,
) => {
  fetchData(getCompletedSuggestionDrugDataView())
    .then((drugSuggestions) => {
      // @ts-ignore
      dispatch(setDrugSuggestionModels(drugSuggestions.filter(m => !!m)));
    })
    .catch(error => debugPrint(error, 'error'));
};

/**
 * Checks if the initial batch data required for dispensation loaded
 * @param {List<DrugModel>} skus All drugs to check dispensation readiness for
 * @param {CountPerSKUAndBatch} inventoryCount the old map of inventory counts
 * @param {string} inventoryCountSyncStatus the sync status of batch inventory count
 * @returns {boolean}
 */
export function isInventoryCountReadyForDispensation(
  skus: List<DrugModel>,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
): boolean {
  return (inventoryCountSyncStatus.includes('STOP')
    || inventoryCountSyncStatus.includes('SYNC')
    || (skus.every(s => (s.get('default_dispensing_batch')
      ? (inventoryCount.getIn([
        s.get('_id'),
        'batches',
        s.get('default_dispensing_batch'),
        'index',
      ]) &&
      (inventoryCount.getIn([
        s.get('_id'),
        'batches',
        s.get('default_dispensing_batch'),
        'batchStockRemaining',
      ], 0) > 0 ||
      !!inventoryCount.getIn([
        s.get('_id'),
        'batches',
      ]).find(b => b.get('index') === 0)))
      : !!inventoryCount.getIn([
        s.get('_id'),
        'batches',
      ], Map()).find(b => b.get('index') === 0))))
  );
}

/**
 * @description Creates a Map of drug with _id
 * Used to reduce time complexity of iterating drugModel in InventoryMapping
 * @param {List<DrugModel>} drugs List of drugs from drugsModel
 * @return {DrugMap} Map of drug with _id,
 */
export const mapDrug = memoizeOne((drugs: List<DrugModel>): DrugMap =>
  drugs.reduce((drugMap: DrugMap, drug: DrugModel) => drugMap.set(drug.get('_id'), drug), Map()));


/**
 * @description Fetches MasterDrugModels of passed ids and sets to Store
 * @param {List<string>} ids list of id of MD models
 * @param {Dispatch} dispatch redux dispatch
 * @returns {Promise<void>}
 */
export const fetchMasterData = (
  ids: List<string>,
  dispatch: Dispatch<ReturnType<typeof setMasterDrugModels>>,
): Promise<void> =>
  new Promise((resolve) => {
    fetchData(getMasterDrugDataViews(ids)).then((masterDrugModels) => {
      dispatch(setMasterDrugModels(masterDrugModels.filter(m => !!m)));
      resolve();
    }).catch(() => resolve());
  });

type MDLInfo = {
  mapped_drug: string;
  mapped_manufacturer: string;
  mmc_id: string;
  active_ingredients: string;
}

/**
 * @param {Redux.store} store A handle to the redux store
 * @param {string} drugId Id of drug ro check status
 * @returns {void | mdlInfo} mdl Info if mapped
 */
export const getMDLInfo = (
  store: Store<State, Action>,
  drugId: string,
): void | MDLInfo => {
  const { verifiedDrugs, drugSuggestions, masterDrugModelsMap } = store.getState();
  const isMappedDrug = verifiedDrugs.has(drugId) || drugSuggestions.has(drugId);
  if (isMappedDrug) {
    const verifiedDrug = verifiedDrugs.get(drugId);
    const masterDrug =
      masterDrugModelsMap.get(verifiedDrug && verifiedDrug.get('drug_master_id'));
    const activeIngredients = masterDrug?.get('active_ingredients') ?? [];
    return {
      mapped_drug: masterDrug ?
        masterDrug.get('name') :
        (drugSuggestions.get(drugId) && drugSuggestions.get(drugId)?.get('name')),
      mapped_manufacturer: masterDrug?.get('manufacturer') ?? UNICODE.EMDASH,
      mmc_id: masterDrug?.get('mmc_id') ?? UNICODE.EMDASH,
      active_ingredients: activeIngredients.join(', '),
    };
  }
  return undefined;
};

/**
 * Fetches the current inventory numbers from the remote db and updates them in the app state.
 * @param {Store} store Redux store
 * @param {boolean} shouldUpdateSkuCountSyncFlag true for first time and false for subsequent.
 * @returns {Promise<void>} A promise for the completion of the action.
 */
export function getInventoryCount(
  store: Store, shouldUpdateSkuCountSyncFlag: boolean,
): Promise<void> {
  if (shouldUpdateSkuCountSyncFlag) {
    store.dispatch(setIsSKUCountSyncing(true));
  }
  fetchInventoryCount()
    .then((inventoryCount) => {
      if (inventoryCount) {
        store.dispatch(setInventoryCount(inventoryCount));
      }
      if (shouldUpdateSkuCountSyncFlag) {
        store.dispatch(setIsSKUCountSyncing(false));
      }
    })
    // eslint-disable-next-line no-use-before-define
    .then(() => startInventorySync(store))
    .catch(() => {});
  return Promise.resolve();
}

/**
 * Starts a routine that updates the inventory state every 2 mins,
 * @param {Store} store Redux store
 * @returns {void}
 */
function startInventorySync(store: Store) {
  const CHECK_INTERVAL = 120000; // Update inventory count every 120s
  return setTimeout(() => {
    if (store.getState().isOnline) {
      getInventoryCount(store, false);
    } else {
      startInventorySync(store);
    }
  }, CHECK_INTERVAL);
}
