import React, { Fragment } from 'react';
import { List, Map, is } from 'immutable';

import { convertToCSV, downloadCSV } from './../../utils/export';
import translate from './../../utils/i18n';
import { printInventoryReports } from './../../utils/print';
import { fetchInventoryReports } from './../../utils/api';
import ContentTransition from './../contentTransition';
import InventoryReportsTable from './inventoryReportsTable';
import TableDateRangePicker from '../table/tableDateRangePicker';
import TableColumnsSettings from '../table/tableColumnsSettings';
import TableExportPrintOptions from './../table/tableExportPrintOptions';
import LoadingIndicator from './../loadingIndicator';
import { renderPrice } from './../../utils/tables';
import Header from './../header/header';

import type { Config, MapValue, Row, CustomColumn } from './../../types';

type Props = {
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  isFetching: boolean,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  setIsFetching: (isFetching: boolean) => void,
  updateConfig: (config: Config) => void,
};

type State = {
  columns: Array<CustomColumn>,
  data: List<MapValue>,
  filteredData: Array<Row>,
  unavailable?: boolean,
  exportPrintAttributes: {
    title: string,
    isLandscape: boolean,
  },
};

const COLUMNS = List([
  { value: 'drug_name', label: translate('drug_name'), filterable: true },
  { value: 'default_cash_price', label: translate('default_cash_price'), renderMethod: 'PRICE' },
  { value: 'default_panel_price', label: translate('default_panel_price'), renderMethod: 'PRICE' },
  { value: 'expiry_date', label: translate('earliest_expiry_date') },
  { value: 'qty_in', label: `${translate('qty').toUpperCase()} (${translate('in')})` },
  {
    value: 'weighted_cpu',
    label: `${translate('cpu').toUpperCase()} (${translate('in')})`,
    renderMethod: 'PRICE',
    cell: renderPrice,
  },
  {
    value: 'value_in',
    label: `${translate('val').toUpperCase()} (${translate('in')})`,
    renderMethod: 'PRICE',
    cell: renderPrice,
  },
  { value: 'qty_out', label: `${translate('qty').toUpperCase()} (${translate('out')})` },
  {
    value: 'value_out',
    label: `${translate('val').toUpperCase()} ${translate('estimate')} (${translate('out')})`,
    renderMethod: 'PRICE',
    cell: renderPrice,
  },
  { value: 'qty_balance', label: `${translate('qty').toUpperCase()} (${translate('balance')})` },
  {
    value: 'weighted_cpu_balance',
    label: `${translate('cpu').toUpperCase()} (${translate('balance')})`,
    renderMethod: 'PRICE',
    cell: renderPrice,
  },
  {
    value: 'value_balance',
    label: `${translate('val').toUpperCase()} (${translate('balance')})`,
    renderMethod: 'PRICE',
    cell: renderPrice,
  },
  { value: 'qty_avg_out', label: translate('average_dispensed_per_transaction') },
  { value: 'cost_price', label: translate('cost_price'), renderMethod: 'PRICE', cell: renderPrice },
]);

/**
 * A component that displays all bills for a clinic.
 * @class InventoryReports
 * @extends {Component}
 */
class InventoryReports extends React.Component<Props, State> {
  state: State = {
    filteredData: [],
    data: List([]),
    unavailable: undefined,
    columns: COLUMNS.toArray(),
    exportPrintAttributes: {
      title: 'Inventory Report',
      isLandscape: true,
    },
  };


  /**
   * Called functions after component fully rendered
   * @returns {void}
   */
  componentDidMount() {
    this.getTableData();
  }

  /**
   * Triggers a data fetch when the filter prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.filter, this.props.filter)) {
      this.getTableData();
    }
  }

  /**
   * Get data for table rows.
   * @returns {void}
   */
  getTableData() {
    this.props.setIsFetching(true);
    this.setState({ data: List([]) });
    fetchInventoryReports(
      this.props.filter
        .get('filterDateStart')
        .clone()
        .startOf('day')
        .valueOf(),
      this.props.filter
        .get('filterDateEnd')
        .clone()
        .endOf('day')
        .valueOf(),
    ).then(({ error, unavailable, data }) => {
      if (!error) {
        this.setState({ unavailable, data });
      }
      this.props.setIsFetching(false);
    });
  }

  /**
   * Merges the given object with the current filter for cost report and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: {}) {
    this.props.onFilterUpdated(this.props.filter.merge(newValues));
  }

  /**
   * Downloads a CSV file of the inventory report.
   * @return {void}
   */
  onExportClicked() {
    const formattedData = this.state.filteredData
      .map(datum =>
        this.state.columns.map(c => datum[c.value]));
    downloadCSV(`${this.state.exportPrintAttributes.title || 'Inventory Report'}.csv`, convertToCSV(this.state.columns.map(c => c.label), formattedData));
  }

  /**
   * Print the inventory report.
   * @return {void}
   */
  onPrintClicked() {
    const formattedData = this.state.filteredData
      .map(datum =>
        this.state.columns.map(c => datum[c.value]));

    printInventoryReports(
      this.state.columns,
      formattedData,
      {
        fromDate: this.props.filter.get('filterDateStart'),
        toDate: this.props.filter.get('filterDateEnd'),
      },
      this.props.config,
      this.state.exportPrintAttributes.title,
      this.state.exportPrintAttributes.isLandscape,
    );
  }

  /**
   * Helper function to update attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  updateExportPrintAttribute(key: string, value: MapValue) {
    this.setState({
      exportPrintAttributes: { ...this.state.exportPrintAttributes, [key]: value },
    });
  }

  /**
   * Renders the table part of the component.
   * @return {string} - HTML markup for the component
   */
  renderTable() {
    return (
      <Fragment>
        <div className="u-flex-row u-margin--standard u-flex-space-between u-flex-align-items-center">
          <TableDateRangePicker
            filter={this.props.filter}
            onUpdateFilter={newFilter => this.updateFilter(newFilter)}
          />
        </div>

        <div className="o-card u-margin-bottom--4ws accounts-receivable-card">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{translate('inventory_reports')}</h1>
            <TableColumnsSettings
              config={this.props.config}
              configFieldName="inventory_reports"
              updateConfigValue={this.props.updateConfigValue}
              originalColumns={COLUMNS}
              columns={this.state.columns}
              onUpdateColumns={(columns) => {
                this.setState({ columns });
              }}
              updateConfig={this.props.updateConfig}
            />
          </Header>
          <div className="o-header-actions">
            {this.state.filteredData && this.state.filteredData.length > 0 && (
              <div className="u-flex-left u-margin-left--half-ws">
                <TableExportPrintOptions
                  exportPrintAttributes={this.state.exportPrintAttributes}
                  updateExportPrintAttribute={(key, value) =>
                    this.updateExportPrintAttribute(key, value)
                  }
                  isPrintExportButtonEnabled={
                    !(this.state.filteredData && this.state.filteredData.length > 0)
                  }
                  onExportClicked={() => this.onExportClicked()}
                  onPrintClicked={() => this.onPrintClicked()}
                />
              </div>
            )}
          </div>
          <InventoryReportsTable
            isFetching={this.props.isFetching}
            data={this.state.data}
            columns={this.state.columns}
            onFilterAndSort={filteredData => this.setState({ filteredData })}
          />
        </div>
      </Fragment>
    );
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <ContentTransition className="o-main__content">
        <div className="c-billing__content">
          <Header className="o-header" dataPublic>
            <h1>{translate('inventory_reports')}</h1>
          </Header>
          {
            this.state.unavailable === undefined &&
            this.props.isFetching &&
            <LoadingIndicator alignCenter style={{ marginTop: 'calc(50vh - 102px)', width: '100%' }} />
          }
          {
            this.state.unavailable === true &&
            <div className="o-form__text-block o-form__text-block--error">{translate('feature_unavailable_notification')}</div>
          }
          {this.state.unavailable === false && this.renderTable()}
        </div>
      </ContentTransition>
    );
  }
}

export default InventoryReports;
