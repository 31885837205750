/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { List, Map } from 'immutable';
import glamorous, { Ul } from 'glamorous';
import { Link } from 'react-router-dom';
import memoizeOne from 'memoize-one';

import Select from '../../components/inputs/select';
import SaveButton from '../buttons/saveButton';
import StatelessModal from '../modals/statelessModal';
import FlowForm from '../encounter/flowForm';

import Input from '../inputs/input';
import Button from '../buttons/button';
import ModalFooter from '../modals/modalFooter';

import EncounterFlowModel from '../../models/encounterFlowModel';
import EncounterModel, { StageInfo, QueueEvent, EncounterEvent } from '../../models/encounterModel';
import PractitionerModel from '../../models/practitionerModel';
import EncounterStageModel from '../../models/encounterStageModel';

import { colours, borderRadius, boxShadows, wsUnit } from '../../utils/css';
import translate from '../../utils/i18n';
import { getConfirmation } from '../../utils/utils';
import { updateEncounterFlow, getTotalNumberOfQueuedPatients, isOnDuty, getConsultTypeName, alertEncounterStage } from '../../utils/encounters';
import { compareByAlphabeticalOrder } from '../../utils/comparators';
import { debugPrint } from '../../utils/logging';
import { createPermission, hasPermission } from './../../utils/permissions';

import type { Config, SaveModel, SaveModels, SelectOption, User } from '../../types';
import type BillModel from '../../models/billModel';
import type SalesItemModel from '../../models/salesItemModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type AppointmentModel from '../../models/appointmentModel';
import { voidBillWithTransactions } from '../../utils/billing';
import FormError from '../formError';
import { printQueueTicket } from '../../utils/print';

const ValueButton = glamorous.button({
  padding: '0 8px',
  borderRadius,
  height: 36,
  width: 40,
  fontFamily: 'robotobold',
  letterSpacing: 2,
  color: '#fff',
  backgroundColor: colours.green,
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  ':hover': {
    backgroundColor: colours.greenHover,
    outline: 'none',
    border: 'none',
  },
  ':focus': {
    backgroundColor: colours.greenHover,
    outline: 'none',
    border: 'none',
  },
  '> div': {
    display: 'none',
  },
});

const Option = glamorous.div({
  padding: '6px 8px',
  backgroundColor: colours.green,
  color: '#fff',
  textAlign: 'center',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: colours.greenHover,
  },
});

const SelectContainer = glamorous.div({
  minHeight: 'unset',
  marginBottom: 0,
  padding: 0,
  '> div': {
    minHeight: 'unset',
    display: 'block',
  },
});

const Menu = glamorous.div({
  position: 'absolute',
  right: 12,
  zIndex: 2,
  minWidth: 180,
  padding: '2px 0',
  backgroundColor: colours.green,
  borderRadius,
  boxShadow: boxShadows.card,
  '> div': {
    maxHeight: 'unset',
  },
});

enum Options {
  MOVE_TO_BILLING = 'move_to_billing',
  EDIT_FLOW = 'edit_flow',
  CHANGE_DOCTOR = 'change_doctor',
  EDIT_QUEUE_NUMBER = 'edit_queue_number',
  PRINT_QUEUE_TICKET = 'print_queue_ticket',
  REMOVE_FROM_QUEUE = 'remove_from_queue',
  CANCEL_APPOINTMENT = 'cancel_appointment',
}

/**
 * @param {any} props Value container props of react-select
 * @returns {JSX.Element}
 */
const CustomContainer = (props: any) => (
  <SelectContainer {...props}>{props.children}</SelectContainer>
);

/**
 * Removes Arrow from select
 * @returns {null}
 */
const IndicatorsContainer = () => null;

/**
 * @param {any} props Value container props of react-select
 * @returns {JSX.Element}
 */
const ValueContainer = (props: any) => (
  <ValueButton {...props}>...
    {props.children}
  </ValueButton>
);

/**
 * @param {any} props Menu props of react-select
 * @returns {JSX.Element}
 */
const MenuComponent = ({ innerProps, ...props }: any) => (
  <Menu {...props} {...innerProps}>
    {props.children}
  </Menu>
);

/**
 * @param {any} props Value container props of react-select
 * @returns {JSX.Element}
 */
const CustomOption = (props: any) =>
  <Option {...props} {...props.innerProps} />;

type Props = {
  encounterFlowMap: Map<string, EncounterFlowModel>,
  encounter: EncounterModel,
  saveModel: SaveModel,
  bills: Map<string, BillModel>,
  config: Config,
  practitioners: List<PractitionerModel>,
  encounters: List<EncounterModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  coveragePayors: List<CoveragePayorModel>,
  salesItems: Map<string, SalesItemModel>,
  updateConfig: (config: Config) => void,
  saveModels: SaveModels,
  appointment?: AppointmentModel,
  user: User,
}

/**
 * Returns Options in the dropdown
 * @returns {Array<SelectOption>}
 */
const getOptions = memoizeOne((
  user: User,
  encounter: EncounterModel | undefined,
  config: Config,
  appointment?: AppointmentModel,
): Array<SelectOption> => {
  const defaultOptions = List([
    encounter && { value: Options.MOVE_TO_BILLING, label: `${translate('move_to')} "${translate('billing')}"` },
    { value: Options.EDIT_FLOW, label: translate(Options.EDIT_FLOW) },
    { value: Options.CHANGE_DOCTOR, label: translate(Options.CHANGE_DOCTOR) },
    encounter && hasPermission(user, List([createPermission('queue_patient_for_today', 'delete')])) && { value: Options.REMOVE_FROM_QUEUE, label: translate(Options.REMOVE_FROM_QUEUE) },
    appointment && { value: Options.CANCEL_APPOINTMENT, label: translate(Options.CANCEL_APPOINTMENT) },
  ]);
  const printOptions = [
    { value: Options.EDIT_QUEUE_NUMBER, label: translate(Options.EDIT_QUEUE_NUMBER) },
    { value: Options.PRINT_QUEUE_TICKET, label: translate(Options.PRINT_QUEUE_TICKET) },
  ];
  const stages = encounter?.getActiveStages();
  const { currentStageIndex } = encounter?.getCurrentStageIndex() || {};
  const options = encounter && stages ? encounter.lastEventIs('finished_consult')
    ? stages.map(stage => ({
      label: `${translate('move_to')} "${stage._name}"`,
      value: stage.stage_id,
      customDetails: {
        stageName: stage.name,
        _stageName: stage._name,
      },
    })).concat(defaultOptions.slice(1).filter(o => o))
    // Remove current stage from options
    : stages.splice(currentStageIndex, 1).map(stage => ({
      label: `${translate('move_to')} "${stage._name}"`,
      value: stage.stage_id,
      customDetails: {
        stageName: stage.name,
        _stageName: stage._name,
      },
    })).concat(defaultOptions.filter(o => o)) : defaultOptions.filter(o => o);
  const queueNumberEnabled = config.getIn(['patient_queue', 'enable_queue_number'], true);
  // @ts-ignore
  return queueNumberEnabled && encounter
    ? options.splice(-1, 0, ...printOptions).toArray()
    : options.toArray();
});

/**
 * Returns component that renders option button with three dots
 * Used in encounter tables along with action button for
 * moving patient to another stage, Change doctor, remove from queue etc
 * @param {Props} props Component props
 * @returns {React.FC}
 */
const OptionButton = (props: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [changeDoctorModalVisible, setChangeDoctorModalVisible] = useState(false);
  const [editFlowModalVisible, setEditFlowModalVisible] = useState(false);
  const [editQueueNumberModalVisible, setEditQueueNumberModalVisible] = useState(false);
  const [queueNumber, setQueueNumber] = useState(props.encounter?.getQueueNumber());
  const [changesMade, setChangesMade] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Resets states to initial
   * @returns {void}
   */
  const resetState = () => {
    setChangesMade(false);
    setErrorMessage('');
    setIsSaving(false);
  };

  /**
   * @param {List<StageInfo>} stages Stages that will be updated on move
   * @param {string} newStageName Selected stage to Move into
   * @param {boolean} isReactivate Boolean to indicate if moving stages higher/ lower order
   * @returns {void}
   */
  const confirmMoveStage = (
    stages: List<StageInfo>,
    newStageName: string,
    isReactivate: boolean = false,
  ) => {
    const message = translate(isReactivate ? 'doing_this_reactivate_stages_message' : 'doing_this_completes_stages_message');
    const confirmation = (
      <>
        <h6>{message}</h6>
        <Ul css={{ listStyle: '"- "', paddingLeft: wsUnit }}>
          {props.encounter.has('consult_type')
            ? getConsultTypeName(props.encounter.get('consult_type'), props.salesItems.toList())
            : stages.map(stage => <li>{stage._name}</li>)}
        </Ul>
      </>
    );
    const modalTitle = `${translate('move_to')} ${newStageName}?`;
    return getConfirmation(confirmation, {
      modalTitle,
      footerSaveButtonName: translate('move'),
    });
  };

  /**
   * Return stages to update on moving from one stage to another
   * @param {EncounterModel} encounter EncounterModel
   * @param {string} stageId stage_id to move into
   * @param {string} stageName stage to move into
   * @returns {List<StageInfo>}
   */
  const getStagesToUpdate = (
    encounter: EncounterModel,
    stageId: string,
    stageName: string,
  ): { stagesToUpdate: List<StageInfo>; isReactivating: boolean } => {
    const { currentStageIndex } = encounter.getCurrentStageIndex();
    const encounterFlowStages = encounter.getActiveStages();
    const newStageIndex = encounterFlowStages.findIndex(s =>
      s.name === stageName && s.stage_id === stageId);
    const hasFinishedConsult = encounter.lastEventIs('finished_consult');
    if (hasFinishedConsult) {
      return { stagesToUpdate: encounterFlowStages.slice(newStageIndex + 1), isReactivating: true };
    }
    if (newStageIndex > currentStageIndex) { // Moving to stage / section lower in the order
      const stagesToUpdate = encounterFlowStages
        .slice(currentStageIndex, newStageIndex);
      return { stagesToUpdate, isReactivating: false };
    } // Moving to stage / section higher in the order
    const stagesToUpdate = encounterFlowStages
      .slice(
        newStageIndex + 1,
        // No need to notify current stage reactivation if user is in waiting room
        currentStageIndex + (encounter.getCurrentEvent().type === 'arrived' ? 0 : 1),
      );
    return { stagesToUpdate, isReactivating: true };
  };

  /**
   * Prints the queue ticket
   * @param {EncounterModel} encounter EncounterModel
   * @returns {void}
   */
  const printTicket = (encounter: EncounterModel) => {
    printQueueTicket(encounter, props.config, props.salesItems.toList());
  };

  /**
   * Validates and saves new queue number
   * @param {boolean} print Printes queue ticket after reassigning if true
   * @returns {Promise | void}
   */
  const saveQueueNumber = (print: boolean = false) => {
    // eqeq is intended because `getQueueNumber()` can return number | string (in case of reassignment)
    // eslint-disable-next-line eqeqeq
    if (props.encounters.some(encounter => encounter.getQueueNumber() == queueNumber)) {
      return setErrorMessage(translate('queue_no_already_in_use'));
    }
    setIsSaving(true);
    let queueAttr = [];
    if (props.encounter.get('queue', []).find(q => q.type === 'assigned')) {
      queueAttr = props.encounter.get('queue', [])
        .filter((q: QueueEvent) => q.type !== 'reassigned')
        .concat({ type: 'reassigned', number: queueNumber });
    } else {
      // Assigning queue number if its not assigned before
      // Eg: Clinic enabled queue number while encounters are in progress
      queueAttr = [{ type: 'assigned', number: queueNumber }];
    }
    const updatedEncounter = props.encounter.set('queue', queueAttr);
    return props.saveModel(updatedEncounter)
      .then(() => {
        if (print) {
          printTicket(updatedEncounter);
        }
        setEditQueueNumberModalVisible(false);
        resetState();
      });
  };

  /**
   * @param {SelectOption} selectOption Selected option
   * @returns {void}
   */
  const handleChange = (selectOption: SelectOption) => {
    switch (selectOption.value) {
      case Options.MOVE_TO_BILLING: {
        setIsSaving(true);
        const { encounter } = props;
        const { currentStageIndex, currentStageOccurenceIndex } = encounter.getCurrentStageIndex();
        const stages = encounter.getActiveStages();
        const selectedStageToMove = translate('billing');
        return confirmMoveStage(stages.slice(currentStageIndex), selectedStageToMove).then(() => {
          const currentOccurringStage = stages
            .get(currentStageIndex)?.occurrences[currentStageOccurenceIndex];
          // If stage is in progress, mark it as complete on moving
          const lastEvent = Array.isArray(currentOccurringStage?.events)
            && currentOccurringStage?.events[currentOccurringStage?.events.length - 1];
          if (lastEvent && lastEvent.type === 'started') {
            return props.saveModel(encounter.addStageEvent('completed').addEvent('finished_consult'))
              .then(() => setIsSaving(false));
          }
          return props.saveModel(encounter.addEvent('finished_consult'))
            .then(() => setIsSaving(false));
        });
      }
      case Options.REMOVE_FROM_QUEUE: {
        setIsSaving(true);
        const currentBill = props.bills.get(props.encounter.get('bill_id'));
        return getConfirmation(translate('confirm_remove_from_queue'), {
          footerSaveButtonName: translate('remove'),
          modalTitle: translate('remove_from_queue'),
        })
          .then(
            () => {
              voidBillWithTransactions(currentBill, props.saveModels, true, List())
                .then(() => {
                  props.saveModel(props.encounter.addEvent('cancelled').addStageEvent('cancelled'));
                  setIsSaving(false);
                });
            },
            () => setIsSaving(false),
          );
      }
      case Options.CANCEL_APPOINTMENT: {
        setIsSaving(true);
        return props.saveModel(props.appointment?.set('status', 'cancelled'))
          .then(() => setIsSaving(false));
      }
      case Options.CHANGE_DOCTOR: {
        return setChangeDoctorModalVisible(true);
      }
      case Options.EDIT_FLOW: {
        return setEditFlowModalVisible(true);
      }
      case Options.EDIT_QUEUE_NUMBER: {
        return setEditQueueNumberModalVisible(true);
      }
      case Options.PRINT_QUEUE_TICKET: {
        return printTicket(props.encounter);
      }
      default:
        if (props.encounterStageMap.has(selectOption.value)) {
          setIsSaving(true);
          const { encounter } = props;
          const stageName = selectOption.customDetails?.stageName;
          const { stagesToUpdate, isReactivating } = getStagesToUpdate(props.encounter,
            selectOption.value, stageName);
          return confirmMoveStage(stagesToUpdate, stageName, isReactivating)
            .then(() => {
              if (encounter.lastEventIs('finished_consult')) {
                // Moving to stage from billing
                const updatedEncounterEvents = encounter.get('encounter_events', []).filter((ev: EncounterEvent) => ev.type !== 'finished_consult');
                encounter.set('encounter_events', updatedEncounterEvents);
              }
              props.saveModel(
                encounter.addStage(selectOption.value, selectOption.customDetails?._stageName),
              )
                .then(() => {
                  const stageModel = props.encounterStageMap.get(selectOption.value);
                  // Patient will be moved to waiting room on 'move'
                  alertEncounterStage(stageModel, 'waiting_alert');
                });
            })
            .catch(() => {})
            .finally(() => setIsSaving(false));
        }
        return debugPrint('stage not found');
    }
  };

  /**
  * Renders the modal content for doctor selection.
  * @returns {React.Component} The modal content to render.
  */
  const renderDoctorButtons = () => {
    let practitioners = props.practitioners.filter(p => p.isVisible() && isOnDuty(props.config, p.get('_id')));
    if (practitioners.size === 0) {
      ({ practitioners } = props); // No on duty doctors so just use full list.
    }
    return (
      <StatelessModal
        id={`changeDoctorModal-${props.encounter?.get('_id')}`}
        buttonClass="o-button o-button--small"
        showBusyIndicator={isSaving}
        title={translate('select_doctor')}
        visible={changeDoctorModalVisible}
        setVisible={(isVisible: boolean) => setChangeDoctorModalVisible(isVisible)}
        noButton
      >
        <div className="o-button-list">
          {
         practitioners.size === 0 &&
           [
             <p style={{ whiteSpace: 'normal' }}>{translate('no_practitioners_warning')}</p>,
             <p style={{ textAlign: 'center', marginTop: '10px' }}>
               <Link to="/settings/doctors" className="o-button">{translate('doctors_page')}</Link>
             </p>,
           ]
       }
          { practitioners.toArray()
            .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))
            .map(practitioner =>
              <SaveButton
                key={practitioner.get('_id')}
                label={`${practitioner.get('name')} ${getTotalNumberOfQueuedPatients(props.encounters, practitioner.get('_id'))}`}
                onClick={() => {
                  setIsSaving(true);
                  if (props.appointment) {
                    return props.saveModel(props.appointment.set('practitioner_id', practitioner.get('_id')))
                      .then(() => setChangeDoctorModalVisible(false))
                      .finally(() => setIsSaving(false));
                  }
                  return props.saveModel(props.encounter.updateCurrentStageOccurrenceAttributes({ doctor: practitioner.get('_id') }))
                    .then(() => setChangeDoctorModalVisible(false))
                    .finally(() => setIsSaving(false));
                }}
                isSaving={isSaving}
                className="o-button--small"
              />)}
        </div>
      </StatelessModal>
    );
  };

  /**
  * Renders the modal content for reassifning queue number
  * @returns {React.Component} The modal content to render.
  */
  const renderQueueNumberModal = () => (
    <StatelessModal
      id={`reassign-qno-${props.encounter.get('_id')}`}
      showBusyIndicator={isSaving}
      title={translate('edit_queue_number')}
      visible={editQueueNumberModalVisible}
      onClose={() => (changesMade && !errorMessage
        ? setErrorMessage(translate('unsaved_changes_error'))
        : setEditQueueNumberModalVisible(false))}
      setVisible={() => {}}
      noButton
    >
      <div className="u-padding--standard">
        <FormError>{errorMessage}</FormError>
        <Input
          id="reassign-queue-number"
          label={translate('new_queue_number')}
          description={translate('new_queue_number_desc')}
          value={queueNumber}
          onValueChanged={(newValue) => {
            setQueueNumber(newValue);
            setChangesMade(true);
          }}
          required
          autoFocus
        />
        <ModalFooter>
          <Button
            disabled={isSaving}
            dataPublic
            className="o-button o-button--small o-button--danger u-margin-right--half-ws"
            onClick={() => {
              setEditQueueNumberModalVisible(false);
              setQueueNumber(props.encounter.getQueueNumber());
            }}
          >{translate('cancel')}
          </Button>
          <SaveButton isSaving={isSaving} dataPublic className="o-button o-button--small u-margin-right--half-ws" onClick={() => saveQueueNumber(true)} label={translate('save_and_print')} />
          <SaveButton isSaving={isSaving} dataPublic className="o-button o-button--small" onClick={() => saveQueueNumber()} label={translate('save')} />
        </ModalFooter>
      </div>
    </StatelessModal>
  );

  return (
    <>
      <Select
        id="select"
        className="u-margin-left--half-ws"
        options={getOptions(props.user, props.encounter, props.config, props.appointment)}
        onValueChanged={handleChange}
        value="..."
        placeholder="..."
        isSearchable={false}
        hideLabel
        components={{
          SelectContainer: CustomContainer,
          IndicatorsContainer,
          ValueContainer,
          Option: CustomOption,
          Menu: MenuComponent,
        }}
        disabled={isSaving}
        isValueSensitive
        style={{ marginBottom: 0 }}
      />
      {changeDoctorModalVisible && renderDoctorButtons()}
      {editFlowModalVisible && <FlowForm
        user={props.user}
        isVisible={editFlowModalVisible}
        hideModal={() => setEditFlowModalVisible(false)}
        config={props.config}
        updateConfig={props.updateConfig}
        salesItems={props.salesItems}
        stagesMap={props.encounterStageMap.filter(stage => stage.isVisible())}
        encounterFlowMap={props.encounterFlowMap.filter(flow => flow.isVisible())}
        coveragePayors={props.coveragePayors}
        saveModel={props.saveModel}
        encounter={props.encounter}
        selectedFlow={props.encounterFlowMap.get(props.appointment?.get('flow_id'))}
        appointment={props.appointment}
        onSave={(encounter, encounterFlow, flowId) => {
          if (props.appointment) {
            if (flowId !== props.appointment.get('flow_id')) {
              return props.saveModel(props.appointment.set('flow_id', flowId))
                .then(() => setEditFlowModalVisible(false));
            } return Promise.resolve(setEditFlowModalVisible(false));
          }
          return updateEncounterFlow(encounter, encounterFlow, props.encounterStageMap,
            Boolean(flowId), props.encounter)
            .then(() => setEditFlowModalVisible(false));
        }}
      />}
      {editQueueNumberModalVisible && renderQueueNumberModal()}
    </>
  );
};

export default OptionButton;
