import React from 'react';
import glamorous from 'glamorous';
import {
  PieChart as RePieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { wsUnit, chartColours } from './../../utils/css';

type Props = {
  data: Array<{
    name: string,
    value: number
  }>
};

const CHART_WIDTH = '99%';
const CHART_HEIGHT = 350;
const RADIAN = Math.PI / 180;

const Chart = glamorous.div({
  marginBottom: `${wsUnit}`,
  marginTop: `calc(2 * ${wsUnit})`,
  gridRowStart: 2,
});

/**
 * Returns the label text
 * @param {Props.data} data aggregate data
 * @param {object} params All the params for the pie chart label
 * @returns {text} svg text element
*/
const renderCustomizedLabel = (data, params) => {
  const { cx, cy, midAngle, outerRadius, percent, index } = params;
  const x = cx + (1.1 * outerRadius * Math.cos(-midAngle * RADIAN));
  const y = cy + (1.1 * outerRadius * Math.sin(-midAngle * RADIAN));

  return (
    <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {data[index].name} ({`${(percent * 100).toFixed(0)}%`})
    </text>
  );
};

/**
 * A component that displays pie chart by using given data.
 * @class PieChart
 * @extends {React.PureComponent<Props>}
 */
class PieChart extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { data } = this.props;
    return (
      <Chart>
        <ResponsiveContainer width={CHART_WIDTH} height={CHART_HEIGHT}>
          <RePieChart>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data}
              label={renderCustomizedLabel(null, data)}
            >
              {
                data.map((entry, index) => {
                  const i = index >= chartColours.length ? chartColours.length - 1 : index;
                  return <Cell fill={chartColours[i]} />;
                })
              }
            </Pie>
            <Tooltip />
          </RePieChart>
        </ResponsiveContainer>
      </Chart>
    );
  }
}

export default PieChart;
