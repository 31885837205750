import React from 'react';
import glamorous, { Div } from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import { downloadFile } from './../../utils/export';
import { wsUnit } from './../../utils/css';
import translate from './../../utils/i18n';
import { getConfirmation } from './../../utils/utils';
import DocumentDataForm from './documentDataForm';
import { UNICODE } from './../../constants';
import Button from './../buttons/button';

import type { SaveModel, Config } from './../../types';
import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  config: Config,
  documentTemplate: DocumentTemplateModel,
  onEditClicked: (documentTemplate: DocumentTemplateModel) => void,
  saveModel: SaveModel,
};

type State = {
  showDataModal: boolean,
};

const Row = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: `calc(${wsUnit} / 2)`,
});

const DEFAULT_TEMPLATE_OPTIONS = [
  'patient_receipt',
  'medical_certificate',
  'time_chit',
  'prescription_labels',
];

/**
 * A row in the list found in DocumentTemplates
 * @class DocumentTemplatesRow
 * @extends {React.PureComponent<Props>}
 */
class DocumentTemplatesRow extends React.Component<Props, State> {
  /**
   * Creates an instance of DocumentTemplatesRow.
   * @param {Props} props Props
   * @returns {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = { showDataModal: false };
  }

  download = () => {
    const { documentTemplate } = this.props;
    documentTemplate.getAsset()
      .then(content => downloadFile(documentTemplate.getFileName(), content));
  }

  onDeleteClicked = () => {
    getConfirmation(translate('confirm_document_template_deletion'))
      .then(
        () => this.props.saveModel(this.props.documentTemplate.setVisible(false)),
        () => {},
      );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { documentTemplate, onEditClicked } = this.props;
    const defaultTo = DEFAULT_TEMPLATE_OPTIONS
      .filter(o => this.props.config.getIn(['document_templates', 'print_templates', o]) === documentTemplate.get('_id'))
      .map(l => translate(l));
    const documentTemplateData = {
      config: this.props.config,
    };
    return (
      <Row>
        <StatelessModal
          id="doc-template-modal"
          noButton
          title={documentTemplate.getName()}
          visible={this.state.showDataModal}
          setVisible={isVisible => this.setState({ showDataModal: isVisible })}
          explicitCloseOnly
        >
          <DocumentDataForm
            documentTemplate={documentTemplate}
            closeModal={() => this.setState({ showDataModal: false })}
            encounterId="test" // Doesn't get used but its expected
            patientId="test" // Doesn't get used but its expected
            documentTemplateData={documentTemplateData}
          />
        </StatelessModal>
        <Div css={{ minWidth: '30%' }}>{documentTemplate.getName()}</Div>
        <div style={{ minWidth: '15%' }}>{defaultTo.length ? defaultTo.join(', ') : UNICODE.EMDASH}</div>
        <div>
          <Button
            className="o-text-button o-text-button--contextual"
            onClick={this.download}
            dataPublic
          >
            {translate('download')}
          </Button>
          <Button
            className="o-text-button o-text-button--contextual"
            onClick={() => this.setState({ showDataModal: true })}
            dataPublic
          >
            {translate('test')}
          </Button>
          <Button
            className="o-text-button o-text-button--contextual"
            onClick={() => onEditClicked(documentTemplate)}
            dataPublic
          >
            {translate('edit')}
          </Button>
          <Button
            className="o-text-button o-text-button--danger"
            onClick={this.onDeleteClicked}
            dataPublic
          >
            {translate('delete')}
          </Button>
        </div>
      </Row>
    );
  }
}

export default DocumentTemplatesRow;
