import React from 'react';
import { List, Map } from 'immutable';
import SaveButton from './../buttons/saveButton';

import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import { updateClinicConfig } from './../../utils/db';

import type { Config, User } from '../../types';

type Props = {
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  config: Config,
  user: User,
  updateConfig: (config: Config) => void,
  headerLabel: string,
  description: string,
  footerLabel: String,
};


type State = {
  isSaving: boolean,
  show: boolean
};

/**
 * A component for inventory mapping notice dialog.
 * @class Confirm
 * @extends {React.Component<Props, State>}
 */
class InventoryAcknowledgeDialog extends React.Component<Props, State> {
  /**
   * Creates an instance of InventoryAcknowledgeDialog.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      config, updateConfigValue, updateConfig, headerLabel,
      description, footerLabel, user,
    } = this.props;
    const acknowledgeConfig = config.getIn([
      'usage_statistics',
      'inventory_map_acknowledgement',
      user.get('id'),
    ], Map());
    const isVisible = !acknowledgeConfig.get('accepted', false);
    return (
      <StatelessModal
        id="inventory-acknowlege-dialog"
        visible={isVisible}
        title={headerLabel}
        noButton
        explicitCloseOnly
        noCloseButton
      >
        <p className="u-margin--standard" style={{ whiteSpace: 'pre-line' }}>{description}</p>
        <ModalFooter>
          <SaveButton
            onClick={() => {
              this.setState({ isSaving: true });
              const updatedConfig = config.setIn(
                [
                  'usage_statistics',
                  'inventory_map_acknowledgement',
                  user.get('id'),
                ],
                Map({ accepted: true }),
              ).toJS();
              updateClinicConfig(updatedConfig, updateConfig)
                .then(() => this.setState({ isSaving: false }));
              updateConfigValue(
                [
                  'usage_statistics',
                  'inventory_map_acknowledgement',
                  user.get('id'),
                ],
                Map({ accepted: true }),
              );
            }}
            label={footerLabel}
            isSaving={this.state.isSaving}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default InventoryAcknowledgeDialog;
