import React from 'react';
import { Link } from 'react-router-dom';

import translate from './../../utils/i18n';
import LoadingIndicator from './../loadingIndicator';
import { fetchTextAsset } from './../../utils/utils';
import { getReferralQueryString } from './../../utils/router';

import type { HTMLStyle } from './../../types';
import type CaseNoteFileModel from './../../models/caseNoteFileModel';

type Props = {
  casenote: CaseNoteFileModel, // The casenote to display.
  style: HTMLStyle,
  isSelected?: boolean,
  isEditLink: boolean,
  sidebarCategoryID?: string,
};

type State = {
  isLoading: boolean,
  content: string,
};

/**
 * A component that displays a single text casenote's thumbnail.
 * @class TextCasenoteThumbnail
 * @extends {React.Component}
 */
class TextCasenoteThumbnail extends React.Component<Props, State> {
  static defaultProps = {
    style: {
      border: '1px black solid', padding: '10px', overflow: 'hidden', color: 'black',
    },
    isEditLink: false,
  };

  /**
   * Creates an instance of TextCasenoteThumbnail.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      content: '',
    };
    const assetID = props.casenote.getAssetID(0);
    if (assetID) {
      this.fetchAsset(assetID);
    }
  }

  /**
   * Fetches the asset and applies it to state.
   * @param {string} assetID Asset ID
   * @returns {void}
   */
  fetchAsset(assetID: string) {
    fetchTextAsset(assetID).then(content => this.setState({ content, isLoading: false }));
  }

  /**
   * Fetches the new asset if one has been given.
   * @param {Props} nextProps Next Props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    const nextAsset = nextProps.casenote.getAssetID(0);
    const currentAsset = this.props.casenote.getAssetID(0);
    if (nextAsset && nextAsset !== currentAsset) {
      this.setState({ isLoading: true });
      this.fetchAsset(nextAsset);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const className = this.props.isSelected ? 'c-active-casenotes-selector__list--selected' : '';
    const prefix = `/patient/${this.props.casenote.get('patient_id')}`;
    let link = this.props.isEditLink ?
      `${prefix}/casenotes/${this.props.casenote.get('_id')}/edit` :
      `${prefix}/categories/${this.props.casenote.get('category')}/${this.props.casenote.get('_id')}`;
    if (!this.props.casenote.isVisible()) {
      link = `${prefix}/categories/BIN/${this.props.casenote.get('_id')}`;
    }
    if (this.props.casenote.isStarred() && this.props.sidebarCategoryID === 'STARRED') {
      link = `${prefix}/categories/STARRED/${this.props.casenote.get('_id')}`;
    }
    link += getReferralQueryString();
    if (this.state.isLoading) {
      return (
        <Link to={link} style={this.props.style} className={className}>
          <LoadingIndicator />
        </Link>
      );
    }
    return (
      <Link to={link} style={this.props.style} className={className}>
        <h1 style={{ fontFamily: 'robotomedium' }}>{this.props.casenote.get('title', translate('untitled'), false)}</h1>
        <div style={{ whiteSpace: 'pre-line', height: '400px', overflow: 'hidden' }}>{this.state.content}</div>
      </Link>
    );
  }
}

export default TextCasenoteThumbnail;
