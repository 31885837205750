import { connect } from 'react-redux';
import { List } from 'immutable';

import RecentlyViewedCasenotes from './../components/casenotes/recentlyViewedCasenotes';

import type { State, RecentlyViewedCasenotesState, Model } from './../types';

type Props = {
  patientID: string,
  casenoteFileID: string,
  assetIndex: number,
}

/**
 * Gets the casenotes that were recently viewed for this patient.
 * @param {RecentlyViewedCasenotesState} recentlyViewedCasenotes Recently viewed casenotes map.
 * @param {string} patientID Patient ID.
 * @param {List<Model>} casenotes All casenotes
 * @returns {List<Model>}
 */
function getCasenotes(
  recentlyViewedCasenotes: RecentlyViewedCasenotesState,
  patientID: string,
  casenotes: List<Model>,
): List<Model> {
  return recentlyViewedCasenotes
    .get(patientID, List())
    .map(casenoteID => casenotes.find(c => c.get('_id') === casenoteID))
    .filter(casenote => casenote !== undefined)
    .reverse();
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (
  { recentlyViewedCasenotes, caseNoteFiles, config }: State,
  { patientID, casenoteFileID, assetIndex }: Props,
) => ({
  casenoteFileID,
  config,
  assetIndex,
  casenotes: getCasenotes(recentlyViewedCasenotes, patientID, caseNoteFiles),
});


const RecentlyViewedCasenotesContainer = connect(mapStateToProps)(RecentlyViewedCasenotes);

export default RecentlyViewedCasenotesContainer;
