import React, { Component } from 'react';
import glamorous from 'glamorous';
import { stringify } from 'qs';
import { List } from 'immutable';
import { debounce } from 'lodash';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import ToggleCard from './../layout/toggleCard';
import PatientsListTable from './patientsListTable';
import Input from './../inputs/input';
import TagInput from './../inputs/tagInput';
import FormError from './../formError';
import { wsUnit } from './../../utils/css';
import { getPatientsList } from '../../utils/patientsList';
import Chip from './../chip';
import PatientListWorker from './patientList.worker';

import type { MapValue, Config, SaveModel, SelectOption } from './../../types';
import type DrugModel from './../../models/drugModel';
import type SalesItemModel from './../../models/salesItemModel';
import { debugPrint } from '../../utils/logging';
import CoveragePayorModel from '../../models/coveragePayorModel';
import Select from './../inputs/select';

type PatientAttributes = {
  symptoms: Array<string>,
  diagnoses: Array<string>,
  tags: Array<string>,
  prescriptions: Array<string>,
  billItems: Array<string>,
  lastEncounterBefore: string,
  panel: string,
};

/* eslint-disable camelcase */
type Patient = {
  patient_id: string,
  last_encounter: number,
  name?: string,
  phone?: string,
  patient_tags?: Array<string>,
};
/* eslint-enable camelcase */

type Props = {
  config: Config,
  drugs: List<DrugModel>,
  salesItems: List<SalesItemModel>,
  patientListFilters?: PatientAttributes,
  onFiltersUpdated: (PatientAttributes) => void,
  saveModel: SaveModel,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  coveragePayors: List<CoveragePayorModel>,
};

type State = {
  attributes: PatientAttributes,
  patients: Array<Patient>,
  isToggleCardClosed: boolean,
  isFetching: boolean,
  filterValues: Array<string>,
  errorMessage?: string,
  itemSoldOptions: Array<SelectOption>,
  drugsPrescribedOptions: Array<SelectOption>,
};

const defaultAttributes: PatientAttributes = {
  diagnoses: [],
  symptoms: [],
  tags: [],
  prescriptions: [],
  billItems: [],
  lastEncounterBefore: '',
  panel: '',
};

const FilterContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: wsUnit,
  margin: wsUnit,
});

/**
 * A component that displays List of patients.
 * @class PatientsList
 * @extends {Component}
 */
class PatientsList extends Component<Props, State> {
  private debouncedGetPatientList: (() => void) = debounce(this.getPatientList, 500);

  private aborter: AbortController | null = null;

  private worker: Worker;

  /**
   * Creates an instance of PatientsList.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.worker = new PatientListWorker();
    this.state = {
      attributes: Object.assign({}, props.patientListFilters || defaultAttributes),
      patients: [],
      isToggleCardClosed: false,
      isFetching: false,
      filterValues: [],
      itemSoldOptions: [],
      drugsPrescribedOptions: [],
    };
    this.getPatientList();
  }

  /**
   * Get Sorted Options from worker
   * @returns {void}
   */
  componentDidMount() {
    this.worker.onmessage = (event) => {
      if (event.data.method === 'getItemSoldOptions') {
        this.setState({ itemSoldOptions: event.data.payload });
      } else if (event.data.method === 'getDrugsPrescripedOptions') {
        this.setState({ drugsPrescribedOptions: event.data.payload });
      }
    };
    this.worker.postMessage({
      method: 'getItemSoldOptions',
      payload: {
        list: this.props.drugs.concat(this.props.salesItems).toArray(),
      },
    });
    this.worker.postMessage({
      method: 'getDrugsPrescripedOptions',
      payload: {
        list: this.props.drugs.toArray(),
      },
    });
  }

  /**
   * Check for new props and invoke worker thread if there are changes
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!prevProps.drugs.equals(this.props.drugs)) {
      this.worker.postMessage({
        method: 'getDrugsPrescripedOptions',
        payload: {
          list: this.props.drugs.toArray(),
        },
      });
    }
    if (!prevProps.salesItems.equals(this.props.salesItems)) {
      this.worker.postMessage({
        method: 'getItemSoldOptions',
        payload: {
          list: this.props.drugs.concat(this.props.salesItems).toArray(),
        },
      });
    }
  }

  /**
   * Clean up interval on unmount.
   * @returns {void}
   */
  componentWillUnmount() {
    this.worker.terminate();
  }

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState({
      attributes: Object.assign(this.state.attributes, { [key]: value }),
    });
    this.props.onFiltersUpdated(this.state.attributes);
    this.debouncedGetPatientList();
  }

  /**
   * checks if __days before filter has value < 0
   * @returns {boolean}
   */
  isDaysCountValid(): boolean {
    if (this.state.attributes.lastEncounterBefore === '' ||
    this.state.attributes.lastEncounterBefore === undefined) {
      return true;
    }
    const days = parseInt(this.state.attributes.lastEncounterBefore, 10);
    return !isNaN(days) && days >= 0;
  }

  /**
   * checks if all filters are empty
   * @returns {boolean}
   */
  areFiltersEmpty(): boolean {
    const {
      diagnoses, symptoms, tags, prescriptions,
      billItems, lastEncounterBefore, panel
    } = this.state.attributes;
    if ((lastEncounterBefore !== '' && lastEncounterBefore !== undefined) ||
    (Array.isArray(diagnoses) && diagnoses.length) ||
    (Array.isArray(symptoms) && symptoms.length) || (Array.isArray(tags) && tags.length) ||
    (Array.isArray(prescriptions) && prescriptions.length) ||
    (Array.isArray(billItems) && billItems.length) ||
    (!!panel)) {
      return false;
    }
    return true;
  }

  /**
   * Get Patient List
   * @returns {undefined}
   */
  getPatientList() {
    this.setState({ errorMessage: undefined });
    if (!this.isDaysCountValid()) {
      this.setState({ errorMessage: translate('days_since_last_visit_error') });
    } else if (!this.areFiltersEmpty()) { // do not make calls when there is nothing to fetch
      this.setState({ isFetching: true });
      const {
        diagnoses, symptoms, tags, prescriptions,
        billItems, lastEncounterBefore, panel,
      } = this.state.attributes;

      const queryStrings = stringify({
        diagnoses: JSON.stringify(diagnoses),
        symptoms: JSON.stringify(symptoms),
        prescriptions: JSON.stringify(prescriptions),
        bill_items: JSON.stringify(billItems),
        last_encounter_before: lastEncounterBefore,
        patient_tags: JSON.stringify(tags),
        coverage_payors: JSON.stringify(panel ? [panel] : []),
      }, { encode: true, indices: false, arrayFormat: 'repeat' });
      const filterValues = Object.values(this.state.attributes).toString().split(',').filter(a => a);
      if (this.aborter) this.aborter.abort();
      this.aborter = new AbortController();
      getPatientsList(queryStrings, this.aborter.signal)
        .then(response =>
          this.setState({ patients: response.data, isFetching: false, filterValues }))
        .catch((err) => {
          if (err.name === 'AbortError') {
            debugPrint(err.message, 'info');
          }
          this.setState({ isFetching: false, errorMessage: translate('patients_search_fetch_err')})
        });
    }
  }

  /**
   * Render Chip
   * @param {string} key the property to update
   * @param {Array<string>} items List of filters
   * @param {string} item An inidividual item from list
   * @param {number} index The index of an item from list
   * @returns {React.Component} Render filter chips.
   */
  getChip(key: string, items: Array<string>, item: string, index: number) {
    return (
      <Chip
        onRemove={() => {
          if (index !== -1) items.splice(index, 1);
          this.updateAttribute(key, items);
        }}
        value={item}
      />
    );
  }

  /**
   * Render Filter Chips
   * @returns {React.Component} Render list filter chips.
   */
  renderFilterChips() {
    const {
      diagnoses, symptoms, prescriptions,
      billItems, tags, lastEncounterBefore,
    } = this.state.attributes;
    const allTags = [...diagnoses || [], ...symptoms || [],
      ...prescriptions || [], ...billItems || [], ...tags || []];
    return (
      <div className="u-margin--standard">
        { diagnoses && diagnoses.map((item, index) => this.getChip('diagnoses', diagnoses, item, index))}
        { symptoms && symptoms.map((item, index) => this.getChip('symptoms', symptoms, item, index))}
        { prescriptions && prescriptions.map((item, index) =>
          this.getChip('prescriptions', prescriptions, item, index))
        }
        { billItems && billItems.map((item, index) => this.getChip('billItems', billItems, item, index))}
        { tags && tags.map((item, index) => this.getChip('tags', tags, item, index))}
        { lastEncounterBefore &&
          <Chip
            onRemove={() => this.updateAttribute('lastEncounterBefore', '')}
            value={lastEncounterBefore}
            label={`${translate('days_since_last_visit')}:`}
          />
        }
        { !(allTags.length || lastEncounterBefore) &&
          <div className="_u-padding-bottom--1ws">{translate('expand_card_to_see_filter_options')}</div>
        }
      </div>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    // Todo:
    // - Convert filters into a separate component
    return (
      <ContentTransition id="main-content" className="o-scrollable-container" style={{ height: '100vh' }}>
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <h1 data-public className="o-title">{translate('patient_list')}</h1>
          <ToggleCard
            dataPublic
            title={translate('filters')}
            footer={this.state.isToggleCardClosed && this.renderFilterChips()}
            isClosed={isClosed => this.setState({ isToggleCardClosed: isClosed })}
          >
            { this.state.errorMessage &&
              <FormError>{this.state.errorMessage}</FormError>
            }
            <FilterContainer>
              <TagInput
                id="filter_diagnoses"
                label={translate('diagnoses')}
                value={this.state.attributes.diagnoses
                  ? this.state.attributes.diagnoses.map(value =>
                    ({ value, label: value }))
                  : []
                }
                options={this.props.config.getIn(['diagnoses', 'options'], List())
                  .map(value => ({ value, label: value }))
                  .toArray()
                }
                onChange={value => this.updateAttribute('diagnoses', value.map(e => e.value))}
              />
              <TagInput
                id="filter_symptoms"
                label={translate('symptoms')}
                value={this.state.attributes.symptoms
                  ? this.state.attributes.symptoms.map(value =>
                    ({ value, label: value }))
                  : []
                }
                options={this.props.config.getIn(['symptoms', 'options'], List())
                  .map(value => ({ value, label: value }))
                  .toArray()
                }
                onChange={value => this.updateAttribute('symptoms', value.map(e => e.value))}
              />
              <TagInput
                id="filter_drugs_prescribed_by_doctors"
                label={translate('drugs_prescribed_by_doctors')}
                value={this.state.attributes.prescriptions
                  ? this.state.attributes.prescriptions.map(value =>
                    ({ value, label: value }))
                  : []
                }
                options={this.state.drugsPrescribedOptions}
                onChange={value => this.updateAttribute('prescriptions', value.map(e => e.value))}
              />
              <TagInput
                id="filter_items_sold"
                label={translate('items_sold')}
                description={translate('includes_sales_and_prescription_items')}
                value={this.state.attributes.billItems
                  ? this.state.attributes.billItems.map(value =>
                    ({ value, label: value }))
                  : []
                }
                options={this.state.itemSoldOptions}
                onChange={value => this.updateAttribute('billItems', value.map(e => e.value))}
              />
              <Input
                id="filter_patients_last_visit_more_than_days_ago"
                label={translate('patients_last_visit_more_than_days_ago')}
                type="number"
                min={0}
                value={this.state.attributes.lastEncounterBefore}
                onValueChanged={value => this.updateAttribute('lastEncounterBefore', value)}
              />
              <TagInput
                id="filter_tags"
                label={translate('tags')}
                value={this.state.attributes.tags
                  ? this.state.attributes.tags.map(value =>
                    ({ value, label: value }))
                  : []
                }
                options={this.props.config.getIn(['patient_tags', 'options'], List())
                  .map(value => ({ value, label: value }))
                  .toArray()
                }
                onChange={value => this.updateAttribute('tags', value.map(e => e.value))}
              />
              <Select
                id=""
                label={translate('panel')}
                value={this.state.attributes.panel}
                options={this.props.coveragePayors.map(c => ({ label: c.get('name'), value: c.get('_id') }))
                  .toArray()
                }
                onValueChanged={value => this.updateAttribute('panel', value)}
              />
            </FilterContainer>
          </ToggleCard>
          <PatientsListTable
            patientList={this.state.patients}
            config={this.props.config}
            saveModel={this.props.saveModel}
            isFetching={this.state.isFetching}
            refetchPatientList={() => this.getPatientList()}
            filterValues={this.state.filterValues}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            coveragePayors={this.props.coveragePayors}
          />
        </section>
      </ContentTransition>
    );
  }
}

export default PatientsList;
