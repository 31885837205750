import React, { useCallback, useState } from 'react';
import glamorous from 'glamorous';

import DeleteButton from '../../components/buttons/deleteButton';
import InventoryItemCard from './inventoryItemCard';
import DispensationUnitSelect from './dispensationUnitSelect';
import translate from './../../utils/i18n';
import { DISPENSATION_UNITS, DRUG_TYPES } from '../../constants';
import { mapStringToOption } from '../../utils/inputs';
import { wsUnit, margins } from '../../utils/css';

import type DrugModel from '../../models/drugModel';

type Props = {
  drug: DrugModel,
  onDelete: () => Promise<void>,
  saveDispensationUnit: (dispensationUnit: string) => Promise<void>,
}

const Footer = glamorous.div({
  margin: `calc(${wsUnit}/2) ${margins.standardMarginSides} calc(${wsUnit}/2)`,
});

/**
 * @returns {SFC}
 */
const DispensationUnitMapping: React.SFC<Props> = ({
  drug,
  onDelete,
  saveDispensationUnit,
}: Props) => {
  const name = drug.get('name');
  const [dispensationUnit, setDispensationUnit] = useState(drug.get('dispensation_unit', ''));
  const [isSaving, setIsSaving] = useState(false);
  const getAttributes = useCallback(
    () => ({
      dosage: drug.get('default_prescribed_dosage', ''),
      dosageUnit: drug.get('dosage_unit', ''),
      frequency: drug.get('frequency', ''),
      duration: drug.get('default_prescribed_duration', ''),
      defaultQuantity: drug.get('default_quantity', ''),
      dispensationUnit: drug.get('dispensation_unit', ''),
      sellingPrice: drug.getPrice() || '',
      manufacturer: drug.get('manufacturer', ''),
      notes: drug.get('notes', ''),
    }),
    [drug],
  );

  /**
   * @returns {void}
   */
  const saveDispensation = () => {
    if (dispensationUnit) {
      setIsSaving(true);
      saveDispensationUnit(dispensationUnit).then(() => setIsSaving(false));
    }
  };

  return (
    <>
      <InventoryItemCard
        title={`${translate('inventory_item_to_map')} : ${name}`}
        data={getAttributes()}
      />
      <div className="u-margin--standard">
        <hr />
        <DispensationUnitSelect
          selectOptions={DRUG_TYPES}
          radioOptions={mapStringToOption(DISPENSATION_UNITS)}
          value={dispensationUnit}
          onChange={newValue => setDispensationUnit(newValue)}
          autoFocus
        />
      </div>
      <DeleteButton className="o-delete-button" onDelete={onDelete} />
      <Footer className=" u-text-align-right">
        <button
          className="o-button o-button--padded o-button--small"
          disabled={isSaving}
          onClick={saveDispensation}
        >
          {translate('save')}
        </button>
      </Footer>
    </>
  );
};

export default DispensationUnitMapping;
