import BaseModel from './baseModel';
import { UNICODE } from './../constants';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'supplier',
  name: string,
  address?: string,
  hidden: boolean,
  supplier_type: 'supplier' | 'clinic',
};

/**
   * SupplierModel
   * @namespace SupplierModel
   */
class SupplierModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'supplier';
  }

  /**
   * Gets a user friendly formatting of the name and address of the supplier.
   * @returns {string}
   */
  getNameAndAddress(): string {
    return `${this.get('name')}\n${this.get('address', UNICODE.EMDASH, true)}`;
  }

  /**
   * Sets the salesItem visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {SupplierModel} The altered SupplierModel.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Returns true if supplier_type is clinic
   * @returns {boolean} True if supplier_type is clinic
  */
  isClinic(): boolean {
    return this.get('supplier_type') === 'clinic';
  }
}

export default SupplierModel;
