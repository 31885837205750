import React, { useState, useRef } from 'react';
import { List } from 'immutable';
import glamorous, { Div } from 'glamorous';
import Header from '../header/header';
import Button from '../buttons/button';
import InfoHeader from '../header/infoHeader';
import translate from '../../utils/i18n';
import TableColumnsSettings from '../table/tableColumnsSettings';
import SelectTable from '../table/selectTable';
import type APIError from '../../utils/apiError';
import type { Config, Column, User } from '../../types';
import {
  renderAlignedCellWithLink,
  dropDownFilterMethod,
  renderWithLink,
} from '../../utils/tables';
import { sortByNumber } from '../../utils/comparators';
import { filterDropDown } from '../../utils/filters';
import {
  transformFilteredColumns,
  transformColumnKeys,
  isLoading,
} from '../../utils/utils';
import { isKlinifyUser } from '../../utils/auth';
import { wsUnit } from '../../utils/css';
import CampaignForm from './campaignForm';
import PatientCampaignSetModel from '../../models/patientCampaignSetModel';
import { createPermission, hasPermission } from '../../utils/permissions';
import PermissionWrapper from '../permissions/permissionWrapper';
import { getUpdateConfirmation } from '../../utils/patientCampaign';

const A = glamorous.a({
  textDecoration: 'underline',
  color: '#fff',
});

type Props = {
  currentDataViewsError?: APIError;
  isFetching: boolean;
  config: Config;
  klinifyConfig: Config;
  updateConfig: (config: Config) => void;
  user: User;
  campaignSets: List<PatientCampaignSetModel>;
  updateCampaignSets: (
    campaigns: List<PatientCampaignSetModel>,
    action: string
  ) => Promise<void>;
};

const COLUMNS: Array<Column> = [
  {
    accessor: 'name',
    Header: translate('name'),
    filterable: true,
    Cell: renderWithLink,
  },
  {
    accessor: 'campaigns',
    Header: `# ${translate('campaigns')}`,
    sortMethod: sortByNumber,
    Cell: renderAlignedCellWithLink,
    align: 'right',
  },
  {
    accessor: 'sent',
    Header: `# ${translate('sent')}`,
    sortMethod: sortByNumber,
    Cell: renderAlignedCellWithLink,
    align: 'right',
  },
  {
    accessor: 'queued',
    Header: `# ${translate('queued')}`,
    sortMethod: sortByNumber,
    Cell: renderAlignedCellWithLink,
    align: 'right',
  },
  {
    accessor: 'status',
    Header: translate('status'),
    filterable: true,
    filterFromOptions: true,
    Cell: renderWithLink,
    filterMethod: filterDropDown,
    Filter: ({ filter, onChange }) =>
      dropDownFilterMethod(
        filter,
        onChange,
        ['active', 'inactive'].map(s => ({ value: s, label: translate(s) })),
      ),
  },
  { accessor: 'action', Header: '', sortable: false, show: true },
];

/**
 * Renders Med advisor campaign sets
 * @returns {React.SFC}
 */
const PatientCampaignSets = ({
  config,
  klinifyConfig,
  updateCampaignSets,
  updateConfig,
  user,
  campaignSets,
  isFetching,
}: Props) => {
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));
  const [campaignFormVisible, setCampaignFormVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [selectedCamapaignSet, setSelectedCamapaignSet] = useState<
    PatientCampaignSetModel | undefined
  >();
  const tableRef = useRef<SelectTable>(null);
  const showPatientOutreachCampaigns =
    isKlinifyUser(user) ||
    (klinifyConfig &&
      klinifyConfig.getIn(
        [
          'premium_features_enabled',
          'patient_outreach_campaigns',
          'enabled',
        ],
        false,
      ));

  /**
   * Clears selected rows in table
   * @returns {void}
   */
  const resetTableSelection = () => tableRef?.current?.resetSelection();

  /**
   * Handles the action
   * @param {string} action The  menu action
   * @returns {void}
   */
  const handleMenuSelection = (action: string) => {
    const selectedCampaignSets = campaignSets.filter(set =>
      selectedRows.some(row => row === set.get('_id')));
    return getUpdateConfirmation(true, action, () => {
      updateCampaignSets(List(selectedCampaignSets), action)
        .then(resetTableSelection);
    });
  };

  /**
   * Returns List of Permission record of `sms_campaigns` feature
   * @param {string} access Access required
   * @returns {List<Record>}
   */
  const getFeaturePermission = (access: 'read' | 'create' | 'update' | 'delete') =>
    List([createPermission('sms_campaigns', access)]);

  const hasEditAccess = hasPermission(user, getFeaturePermission('update')) || hasPermission(user, getFeaturePermission('delete'));

  /**
   * @returns {Array<Row>}
   */
  const getRows = () => campaignSets
    .map((campaign) => {
      const { sent, queued } = campaign.get('stats', {});
      return {
        _id: campaign.get('_id'),
        name: campaign.get('name'),
        campaigns: campaign.get('campaign_count'),
        sent: isFetching ? translate('loading...') : sent,
        queued: isFetching ? translate('loading...') : queued,
        status: translate(campaign.getStatus()?.toLowerCase()),
        action: (
          <Button
            className="o-text-button o-text-button--contextual"
            onClick={() => setSelectedCamapaignSet(campaign)}
            dataPublic
          >
            {hasEditAccess ? translate('edit') : translate('view')}
          </Button>
        ),
        link: `/campaigns/patient/${campaign.get('_id')}`,
      };
    })
    .toArray();

  const infomessage = (
    <>
      You can{' '}
      <A
        className="u-font-bold"
        href="https://www.klinify.com/solution-increase-revenue"
        target="_blank"
        rel="noopener noreferrer"
      >
        increase revenue
      </A>{' '}
      and{' '}
      <A
        className="u-font-bold"
        href="https://www.klinify.com/solution-improve-patient-outcomes"
        target="_blank"
        rel="noopener noreferrer"
      >
        improve patient outcomes
      </A>{' '}
      by running your very own customised SMS Campaigns. Contact our sales team{' '}
      <A
        className="u-font-bold"
        css={{ '&:hover': { textDecoration: 'underline' }, color: '#fff' }}
        href="https://www.klinify.com/sales-contacts-page"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </A>{' '}
      to sign up for this premium feature!
    </>
  );

  const lockedFeatureMessage = (
    <>
      <div className="u-flex-row o-card__header">
        <Header className="o-card__header">
          <h1 className="o-card__title">
            {`${translate('patient_campaigns')} (${translate(
              'premium_feature',
            )})`}
          </h1>
        </Header>
      </div>
      <Div
        className="u-text-align-center"
        css={{ padding: `calc(2 * ${wsUnit})` }}
      >
        <h5 className="u-strong u-margin-bottom--half-ws">
          {translate('feature_locked_message')}
        </h5>
        <h6>
          To start running your very own SMS campaigns, contact our sales team
          and sign up for this premium feature today!
        </h6>
      </Div>
    </>
  );

  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      <Header className="o-header" dataPublic>
        <h1 className="u-text-capitalised">{translate('patient_campaigns')}</h1>
        <PermissionWrapper permissionsRequired={getFeaturePermission('create')} user={user}>
          {showPatientOutreachCampaigns &&
          <div className="u-flex-right u-margin-right--1ws">
            <Button
              dataPublic
              className="o-button o-button--small"
              onClick={() => setCampaignFormVisible(true)}
            >
              {translate('add_campaign_set')}
            </Button>
          </div>}
        </PermissionWrapper>
      </Header>
      {!showPatientOutreachCampaigns && <InfoHeader>{infomessage}</InfoHeader>}
      <div className="o-card u-margin-bottom--4ws">
        {showPatientOutreachCampaigns ? (
          <>
            <div className="u-flex-row o-card__header">
              <Header className="o-card__header">
                <h1 className="o-card__title">{translate('campaign_sets')}</h1>
              </Header>
              <TableColumnsSettings
                config={config}
                configFieldName="medadvisor_campaign_sets"
                originalColumns={List(transformColumnKeys(COLUMNS))}
                columns={columns}
                onUpdateColumns={cols => setColumns(cols)}
                updateConfig={updateConfig}
              />
            </div>
            <div className="o-header-actions">
              <div className="u-flex-left u-margin-left--half-ws">
                <PermissionWrapper permissionsRequired={getFeaturePermission('update')} user={user}>
                  <>
                    <Button
                      dataPublic
                      className="o-button o-button--small have-background u-margin-left--half-ws"
                      onClick={() => handleMenuSelection('activate')}
                      disabled={selectedRows.every((r) => {
                        const campaign = campaignSets.find(
                          c => c.get('_id') === r,
                        );
                        return !campaign || campaign.get('is_active');
                      })}
                    >
                      {translate('activate')}
                    </Button>
                    <Button
                      dataPublic
                      className="o-button o-button--small have-background u-margin-left--half-ws"
                      onClick={() => handleMenuSelection('deactivate')}
                      disabled={selectedRows.every((r) => {
                        const campaign = campaignSets.find(
                          c => c.get('_id') === r,
                        );
                        return !(campaign && campaign.get('is_active'));
                      })}
                    >
                      {translate('deactivate')}
                    </Button>
                  </>
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={getFeaturePermission('delete')} user={user}>
                  <Button
                    dataPublic
                    className="o-button o-button--small have-background u-margin-left--half-ws"
                    onClick={() => handleMenuSelection('delete')}
                    disabled={!selectedRows.length}
                  >
                    {translate('delete')}
                  </Button>
                </PermissionWrapper>
              </div>
            </div>
            <SelectTable
              ref={tableRef}
              columns={transformFilteredColumns(COLUMNS, columns)}
              data={getRows()}
              showPagination
              defaultSorted={[{ id: 'name', desc: false }]}
              getSelectedRows={value => setSelectedRows(value)}
              noDataText={translate('no_campaign_found')}
            />
          </>
        ) : (
          lockedFeatureMessage
        )}
      </div>
      {(campaignFormVisible || Boolean(selectedCamapaignSet)) && (
        <CampaignForm
          isVisible
          hideModal={() => {
            setSelectedCamapaignSet(undefined);
            setCampaignFormVisible(false);
          }}
          selectedCampaignSet={selectedCamapaignSet}
          onDelete={campaign =>
            getUpdateConfirmation(true, 'delete', () =>
              updateCampaignSets(List([campaign]), 'delete'))
          }
          onSave={(campaign, action, newStatus) => {
            if (newStatus) {
              return getUpdateConfirmation(true, newStatus, () =>
                updateCampaignSets(List([campaign]), newStatus));
            }
            return updateCampaignSets(List([campaign]), action);
          }}
          user={user}
          hasCreatePermission={hasPermission(user, getFeaturePermission('create'))}
          hasUpdatePermission={hasPermission(user, getFeaturePermission('update'))}
          title={hasEditAccess && selectedCamapaignSet ? translate('edit_campaign_set') : translate('add_campaign_set')}
        />
      )}
    </section>
  );
};

export default PatientCampaignSets;
