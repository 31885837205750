import React from 'react';
import translate from './../../utils/i18n';

/**
   * @param {props} props props
   * @return {ReactComponent} React Component for a 404 page.
   */
const NotFound = () => (
  <h1 data-public style={{ padding: 20 }}>{translate('page_not_found')}</h1>
);

export default NotFound;
