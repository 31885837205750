import React from 'react';
import { List, Map, is } from 'immutable';
import { isArray } from 'lodash';
import glamorous from 'glamorous';

import type { CellInfo, DerivedDataObject, Column, RowInfo } from 'react-table';
import { filterDate, filterSubrows } from './../../utils/filters';
import { renderDate } from './../../utils/tables';
import { sortByDate, sortByNumber } from './../../utils/comparators';
import { UNICODE, TRANSACTION_TYPES } from './../../constants';
import { validateAndTrimString, fixedDecimal, sum, transformColumnKeys, transformFilteredColumns} from './../../utils/utils';
import Input from './../inputs/input';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import SavePrompt from './../prompts/savePrompt';
import StatelessModal from './../modals/statelessModal';
import StockTakeTable from './stockTakeTable';
import TableColumnsSettings from '../table/tableColumnsSettings';
import translate from './../../utils/i18n';
import AdjustBatchQuantityModal from './adjustBatchQuantityModal';
import FormError from './../formError';
import TransactionModel from './../../models/transactionModel';
import { printStockTake } from './../../utils/print';
import { prettifyDate } from './../../utils/time';
import { isBatchDataNotReady } from './../../utils/inventory';
import Button from './../buttons/button';
import Header from './../header/header';

import type { MapValue, Config, CustomColumn, Row, SaveModel, CountPerSKUAndBatch } from './../../types';
import type DrugModel from './../../models/drugModel';
import type SupplyItemModel from '../../models/supplyItemModel';
import type SupplierModel from '../../models/supplierModel';

type Props = {
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugs: List<DrugModel>,
  supplyItems: List<SupplyItemModel>,
  isFetching: boolean,
  saveModel: SaveModel,
  suppliers: List<SupplierModel>,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  updateInventoryItemCount: (skuID: string, change: number) => void,
};

type State = {
  columns: Array<CustomColumn>,
  data: Array<Row>,
  isFetching: boolean,
  changesMade: boolean,
  errorMessage: string,
  isSaving?: boolean,
  hasFormErrorOnClose: boolean,
  updatedRows: Map<string, Row>,
  filteredData: Array<Row>,
  isStockTakeModalVisible: boolean,
  isStockActionFormVisible: boolean,
  stockActionFormDrug?: DrugModel,
  stockActionFormSupplyItem?: SupplyItemModel,
  stockActionFormType: string,
  stockFormActionData: MapValue,
  pageIndex: number,
  pageSize: number,
  expanded: { [index: number]: boolean } | {},
  expandedBatches: Map<number, RowInfo | undefined>,
  showEmptyBatches: boolean,
};

type DrugMapRecord = { hasSupplyItems: boolean, drug: DrugModel, qty: number, price: number }

const FixedTableButtonHeader = glamorous.div({
  position: 'sticky',
  top: 0,
  zIndex: 1,
});
const COLUMNS = List([
  {
    accessor: 'sku_id',
    Header: '',
    PivotValue: () => null,
    width: 30,
    uncustomizable: true,
  },
  {
    accessor: 'added_time',
    Header: translate('added_time'),
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value ? prettifyDate(row.value) : UNICODE.EMDASH}</div>,
    width: 120,
    filterable: true,
    filterMethod: filterSubrows(filterDate),
    sortMethod: sortByDate,
    headerClassName: 'u-flex-justify-content-right',
    Aggregated: () => <div className="o-table__cell u-flex-justify-content-right">{UNICODE.EMDASH}</div>,
  },
  {
    accessor: 'sku',
    Header: translate('sku'),
    Cell: () => null,
    filterable: true,
    aggregate: (values: Array<string>) => values[0],
    Aggregated: ({ value, subRows }: CellInfo) => {
      // eslint-disable-next-line no-underscore-dangle
      const haveSubrows = subRows?.find(r => r._original.is_subrow);
      if (haveSubrows) {
        return `${value} (${subRows.length})`;
      }
      return value;
    },
    className: 'u-flex-align-items-center',
  },
  {
    accessor: 'batch_number',
    Header: translate('batch_#'),
    Cell: (row: CellInfo) => <div className="o-table__cell">{row.value || UNICODE.EMDASH}</div>,
    filterable: true,
    filterMethod: filterSubrows(),
    Aggregated: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
    width: 100,
  },
  {
    accessor: 'current_count',
    Header: translate('current_count'),
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value || 0.00}</div>,
    headerClassName: 'u-flex-justify-content-right',
    sortMethod: sortByNumber,
    aggregate: (values: Array<number>, rows: Array<DerivedDataObject>) =>
    // eslint-disable-next-line no-underscore-dangle
      (rows[0]._original.skuCount || fixedDecimal(sum(List(values).filter(v => !isNaN(v))))),
    Aggregated: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
    width: 130,
  },
  {
    accessor: 'new_count',
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value || 0.00}</div>,
    Header: translate('new_count'),
    Aggregated: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
  },
  {
    accessor: 'reason_for_change',
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value || 0.00}</div>,
    Header: translate('reason_for_change'),
    Aggregated: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
  },
  {
    accessor: 'comment',
    Header: translate('comments'),
    Aggregated: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
  },
]);

/**
 * A component that displays StockTakeList.
 * @class StockTakeTable
 * @extends {Component}
 */
class StockTakeList extends React.Component<Props, State> {
  /**
   * Creates an instance of StockTakeList
   * @param {any} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: transformColumnKeys(COLUMNS.toArray()),
      filteredData: [],
      data: [],
      changesMade: false,
      updatedRows: Map(),
      isStockTakeModalVisible: false,
      isStockActionFormVisible: false,
      errorMessage: '',
      hasFormErrorOnClose: false,
      stockFormActionData: {},
      stockActionFormType: '',
      pageIndex: 0,
      pageSize: 20,
      expanded: {},
      expandedBatches: Map(),
      showEmptyBatches: false,
    };
  }

  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.getRows(this.props.inventoryCount, this.props.drugs);
  }

  /**
   * Check if props changed
   * @param {*} prevProps Props
   * @param {*} prevState Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!is(this.state.updatedRows, prevState.updatedRows)
      || (!is(this.state.showEmptyBatches, prevState.showEmptyBatches))
      || !is(this.props.supplyItems, prevProps.supplyItems)
      || !is(this.props.inventoryCount, prevProps.inventoryCount)
      || !is(this.props.inventoryCountSyncStatus, prevProps.inventoryCountSyncStatus)) {
      this.getRows(this.props.inventoryCount, this.props.drugs);
    }
  }

  /**
   * Check if the reason is either loan or tranfer
   * @param {string} reason Props
   * @returns {boolean}
   */
  isLoanOrTransfer = (reason: string): boolean => reason === 'loan_to' || reason === 'transfer_to';

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} supplyItemID supplyItem ID
   * @param {string} skuID sku ID
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(supplyItemID: string, skuID: string, key: string, value: MapValue) {
    const currentCount = this.props.inventoryCount.getIn([
      skuID,
      'batches',
      supplyItemID,
      'batchStockRemaining',
    ], 0);
    const rowKey = `${supplyItemID}-${skuID}`;
    const row = this.state.updatedRows.get(rowKey, { supplyItemID, skuID });
    const newRow = { ...row, [key]: value };
    const updatedNewRow = (key === 'newCount')
      ? { ...newRow, stockForm: { ...newRow.stockForm, quantity: (value - currentCount) } }
      : (key === 'comment')
        ? { ...newRow, stockForm: { ...newRow.stockForm, notes: value } }
        : newRow;
    this.setState(prevState => ({
      updatedRows: prevState.updatedRows.set(rowKey, updatedNewRow),
      changesMade: true,
    }));
    if (key === 'reasonForChange' && (value === 'loan_to' || value === 'transfer_to')) {
      this.handleActionFormVisibility(supplyItemID,
        value,
        row.stockForm);
    }
  }

  /**
   * Creates the table rows for the current inventory.
   * @param {Map<string, Map>} inventoryCount The current inventory state.
   * @param {List<DrugModel>} drugs All drug models.
   * @returns {[]} An array of rows.
   */
  getRows(inventoryCount: CountPerSKUAndBatch, drugs: List<DrugModel>) {
    const drugsMap = drugs.reduce<Map<string, DrugMapRecord>>((all, d) => all.set(
      d.get('_id'),
      {
        hasSupplyItems: false,
        drug: d,
        qty: 0,
        price: 0,
      },
    ), Map());
    const [batchRows, filteredDrugsmap] = this.props.supplyItems
      .reduce<[Array<Row>, Map<string, DrugMapRecord>]>((
        [_rows, _drugsMap],
        supplyItem: SupplyItemModel,
      ) => {
        const drug = _drugsMap.get(supplyItem.get('sku_id'))?.drug;
        const batch = this.props.inventoryCount.getIn([supplyItem.get('sku_id'), 'batches', supplyItem.get('_id')]);

        if (drug && drug.isVisible()) {
          const count = batch && batch.get('batchStockRemaining', 0);
          if (!this.state.showEmptyBatches && (!count || count <= 0)) {
            return [_rows, _drugsMap.update(supplyItem.get('sku_id'), drugItem => ({
              ...drugItem,
            }))];
          }

          const itemRow: Row = {
            sku_id: supplyItem.get('sku_id', ''),
            supply_item_id: supplyItem.get('_id'),
            added_time: supplyItem.getCreatedTime(),
            sku: validateAndTrimString(drug.get('name')),
            batch_number: supplyItem.get('batch_id', UNICODE.EMDASH),
            current_count: count,
            new_count: this.getNewCountFormField(supplyItem.get('_id'), supplyItem.get('sku_id')),
            reason_for_change: this.getReasonFormField(supplyItem.get('_id'), supplyItem.get('sku_id')),
            comment: this.getCommentFormField(supplyItem.get('_id'), supplyItem.get('sku_id')),
            action: this.getActions(supplyItem.get('_id')),
            is_subrow: true,
          };
          return [[..._rows, itemRow], _drugsMap.update(supplyItem.get('sku_id'), drugItem => ({
            ...drugItem,
            hasSupplyItems: true,
          }))];
        }
        return [_rows, _drugsMap];
      }, [[], drugsMap]);

    const drugsRows = filteredDrugsmap.reduce((_rows, drugRecord) => {
      const { drug, hasSupplyItems } = drugRecord;
      if (drug && drug.isVisible() && !hasSupplyItems) {
        const count = inventoryCount.get(drug.get('_id'), Map()).get('skuStockRemaining', 0);
        const itemRow = {
          sku_id: drug.get('_id', ''),
          sku: validateAndTrimString(drug.get('name')),
          current_count: fixedDecimal(count),
          is_subrow: false,
        };
        return [..._rows, itemRow];
      }
      return _rows;
    }, []);
    const data = batchRows.map(row => ({
      ...row,
    })).concat(drugsRows);
    this.setState({ data });
  }

  /**
   * Get input field value
   * @param {string} supplyItemID supplyItem ID
   * @param {string} skuId sku id
   * @param {string} field field to get the value from
   * @returns {string}
   */
  getUpdatedRowValue(supplyItemID: string, skuId: string, field: string) {
    const mapKey = `${supplyItemID}-${skuId}`;
    const row = this.state.updatedRows.get(mapKey);
    return row ? row[field] : '';
  }

  /**
   * Handles action form visibility
   * @param {string} supplyItemID the supply item id to show action form for
   * @param {string} actionType type of the action eg. loan_to
   * @param {{}} stockFormActionData stockFormActionData
   * @returns {void}
   */
  handleActionFormVisibility(
    supplyItemID: string,
    actionType: string,
    stockFormActionData: MapValue = {},
  ) {
    const supplyItem = this.props.supplyItems.find(s => s.get('_id') === supplyItemID);
    const drug = this.props.drugs.find(d => d.get('_id') === supplyItem.get('sku_id'));
    return this.setState({
      isStockActionFormVisible: true,
      stockActionFormType: actionType,
      stockActionFormSupplyItem: supplyItem,
      stockActionFormDrug: drug,
      stockFormActionData,
    });
  }

  /**
   * Renders input field for new count column
   * @param {string} supplyItemID supplyItem ID
   * @param {string} skuID sku ID
   * @returns {React.Component}
   */
  getNewCountFormField(supplyItemID: string, skuID: string) {
    return (
      <Input
        id={`${supplyItemID}_new_count`}
        label=""
        value={this.getUpdatedRowValue(supplyItemID, skuID, 'newCount')}
        type="number"
        onValueChanged={value => this.updateAttribute(supplyItemID, skuID, 'newCount', value)}
        required={Boolean(this.getUpdatedRowValue(supplyItemID, skuID, 'reasonForChange')) || Boolean(this.getUpdatedRowValue(supplyItemID, skuID, 'comment'))}
        min={0}
      />
    );
  }

  /**
   * Renders select field for reasn column
   * @param {string} supplyItemID supplyItem Id
   * @param {string} skuID sku ID
   * @returns {React.Component}
   */
  getReasonFormField(supplyItemID: string, skuID: string) {
    return (
      <select
        onChange={event => this.updateAttribute(supplyItemID, skuID, 'reasonForChange', event.target.value)}
        style={{ width: '100%', height: 35, fontSize: 16, color: '#4a4a4a' }}
        value={this.getUpdatedRowValue(supplyItemID, skuID, 'reasonForChange')}
        required={this.getUpdatedRowValue(supplyItemID, skuID, 'newCount')}
      >
        <option value="" />
        {
          TRANSACTION_TYPES.map(value =>
            <option key={value} value={value}>{translate(value)}</option>)
        }
      </select>
    );
  }

  /**
   * Renders input field for comment column
   * @param {string} supplyItemID supplyItemID
   * @param {string} skuID sku ID
   * @returns {React.Component}
   */
  getCommentFormField(supplyItemID: string, skuID: string) {
    return (
      <Input
        id={`${supplyItemID}_comment`}
        label=""
        value={this.getUpdatedRowValue(supplyItemID, skuID, 'comment')}
        onValueChanged={value => this.updateAttribute(supplyItemID, skuID, 'comment', value)}
      />
    );
  }

  /**
   * Renders action button
   * @param {string} supplyItemID supplyItemModel id
   * @returns {React.Component}
   */
  getActions(supplyItemID: string) {
    const row = this.state.updatedRows.get(supplyItemID);
    if (row && row.stockForm && row.stockForm.isUpdated) {
      return (
        <Button
          type="button"
          className="o-text-button o-text-button--contextual"
          onClick={() =>
            this.handleActionFormVisibility(supplyItemID, row.stockForm.actionType, row.stockForm)}
          dataPublic
        >
          {translate('view_edit')}
        </Button>
      );
    }
    return null;
  }

  onPivotRowClick = (newExpanded:{ [index: number]: boolean| {} }, rowInfo: RowInfo) => {
    const { expandedBatches } = this.state;
    const index = rowInfo.nestingPath[0];
    const isExpanded = newExpanded[index];
    if (isExpanded && isExpanded !== undefined) {
      this.setState({
        expanded: newExpanded,
        expandedBatches: expandedBatches.update(index, () => rowInfo.row),
      });
    } else {
      this.setState({
        expanded: newExpanded,
        expandedBatches: expandedBatches.delete(index),
      });
    }
  }

  /**
   * Handle closing of modal
   * @returns {void}
   */
  onModalClose() {
    this.setState({
      updatedRows: Map(),
      errorMessage: '',
      isSaving: false,
      changesMade: false,
      isStockTakeModalVisible: false,
      hasFormErrorOnClose: false,
    });
  }

  /**
   * Handles save
   * @returns {Promise<boolean>}
   */
  onSaveClicked() {
    this.setState({ isSaving: true });
    const filteredUpdatedRows = this.state.updatedRows.valueSeq().filter(r =>
      r.newCount || r.comment || r.reasonForChange);
    if (filteredUpdatedRows.some(f => (f.newCount && this.props.inventoryCount.getIn([
      f.skuID,
      'batches',
      f.supplyItemID,
      'batchStockRemaining',
    ], 0) < f.newCount))) {
      this.setState({ errorMessage: translate('new_count_must_be_less_than_current_count'), isSaving: false });
      return Promise.resolve(false);
    }
    if (filteredUpdatedRows.some(f => f.newCount < 0)) {
      this.setState({ errorMessage: translate('new_count_field_cannot_be_negative'), isSaving: false });
      return Promise.resolve(false);
    }
    if (filteredUpdatedRows.some(f => (f.comment && !f.newCount && !f.reasonForChange))) {
      this.setState({ errorMessage: translate('fill_required_fields'), isSaving: false });
      return Promise.resolve(false);
    } else if (filteredUpdatedRows.some(f => f.newCount && !f.reasonForChange)) {
      this.setState({ errorMessage: translate('reason_field_is_required'), isSaving: false });
      return Promise.resolve(false);
    } else if (filteredUpdatedRows.some(f => !f.newCount && f.reasonForChange)) {
      this.setState({ errorMessage: translate('new_count_field_is_required'), isSaving: false });
      return Promise.resolve(false);
    }
    this.setState({ errorMessage: '' });
    // Create transactions for each supplyItem.
    const modelsToSave = filteredUpdatedRows.reduce((allModels, r) => {
      const currentCount = this.props.inventoryCount.getIn([
        r.skuID,
        'batches',
        r.supplyItemID,
        'batchStockRemaining',
      ], 0);
      if (r.newCount - currentCount === 0) {
        return allModels;
      }
      if (this.isLoanOrTransfer(r.reasonForChange) && r.models) {
        const [transactionModel, supplyItemModel, supplyModel] = r.models;
        return allModels.concat([
          transactionModel.set({
            change: r.newCount - currentCount,
          }),
          supplyItemModel.set({
            notes: r.comment,
            quantity: r.newCount - currentCount,
          }),
          supplyModel,
        ]);
      }
      return allModels.push(new TransactionModel({
        sku_id: r.skuID,
        change: r.newCount - currentCount,
        source_type: r.reasonForChange,
        source_id: r.supplyItemID,
        supply_item_id: r.supplyItemID,
        notes: r.comment,
        timestamp: new Date().getTime(),
      }));
    }, List());
    return Promise
      .all(modelsToSave.map(model => this.props.saveModel(model)).toArray())
      .then(savedModels => savedModels.map((model) => {
        if (model && model.get('type') === 'transaction') {
          this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change'));
        }
        return true;
      }))
      .then(() => {
        this.onModalClose();
        return true;
      })
      .catch(() => false);
  }

  /**
   * Handles printing of stock take
   * @returns {void}
   */
  onPrintClicked() {
    const columns = this.state.columns.filter(c => c.value !== 'action');
    const data = this.state.filteredData.map(item => columns.map((column) => {
      if (column.value === 'comment' ||
          column.value === 'new_count' ||
          column.value === 'reason_for_change') {
        return UNICODE.EMDASH;
      }
      if (column.value === 'added_time') {
        return prettifyDate(item[column.value]);
      }
      return item[column.value] !== undefined ? item[column.value] : UNICODE.EMDASH;
    }));
    printStockTake(
      columns,
      data,
      this.props.config,
      translate('stock_take'),
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const fetching = this.props.isFetching || isBatchDataNotReady(
      (
        this.state.filteredData.length
          ? this.state.filteredData.slice(
            this.state.pageSize * this.state.pageIndex,
            this.state.pageSize * (this.state.pageIndex + 1),
          )
          : this.state.data.length
            ? this.state.data
            : []
      ),
      this.props.inventoryCount,
      this.props.inventoryCountSyncStatus.last(),
    );
    return (
      <div>
        { this.state.isStockActionFormVisible &&
          <AdjustBatchQuantityModal
            action={
              this.state.stockActionFormType === 'transfer_to'
                ? 'transfer_out'
                : this.state.stockActionFormType === 'loan_to'
                  ? 'loan_out'
                  : this.state.stockActionFormType
            }
            isVisible={this.state.isStockActionFormVisible}
            supplyItem={this.state.stockActionFormSupplyItem}
            stockFormActionData={this.state.stockFormActionData}
            drug={this.state.stockActionFormDrug}
            drugs={this.props.drugs}
            supplyItems={this.props.supplyItems}
            onClose={() => {
              this.setState({
                isStockActionFormVisible: false,
                stockActionFormType: '',
                stockActionFormSupplyItem: undefined,
                stockActionFormDrug: undefined,
              });
            }}
            onSave={(supplyItemID, skuID, models, stockFormData) => {
              const currentCount = this.props.inventoryCount.getIn([
                skuID,
                'batches',
                supplyItemID,
                'batchStockRemaining',
              ], 0);
              this.updateAttribute(supplyItemID, skuID, 'models', models);
              this.updateAttribute(supplyItemID, skuID, 'newCount', currentCount + stockFormData.quantity);
              this.updateAttribute(supplyItemID, skuID, 'comment', stockFormData.notes);
              this.updateAttribute(supplyItemID, skuID, 'stockForm', { ...stockFormData, isUpdated: true });
            }}
            saveModel={this.props.saveModel}
            inventoryCount={this.props.inventoryCount}
            updateInventoryItemCount={this.props.updateInventoryItemCount}
            isFromStockTake
            suppliers={this.props.suppliers}
            config={this.props.config}
            updateConfig={this.props.updateConfig}
            updateConfigValue={this.props.updateConfigValue}
            inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
          />
        }
        <StatelessModal
          id="stock_take"
          buttonLabel={translate('stock_take')}
          buttonClass="o-button o-button--padded o-button--small"
          title={translate('stock_take')}
          setVisible={isVisible => this.setState({ isStockTakeModalVisible: isVisible })}
          visible={this.state.isStockTakeModalVisible}
          onClose={() => {
            if (this.state.hasFormErrorOnClose) {
              this.onModalClose();
            } else if (this.state.changesMade) {
              this.setState({ hasFormErrorOnClose: true, isStockTakeModalVisible: true, errorMessage: translate('you_have_unsaved_changes') });
            } else {
              this.onModalClose();
            }
          }}
          explicitCloseOnly
          large
          dataPublicHeader
        >
          { this.state.errorMessage &&
            <div className="u-margin--standard">
              <FormError isSticky>{this.state.errorMessage}</FormError>
            </div>
          }
          <div className="o-card u-margin-bottom--4ws">
            <SavePrompt
              when={this.state.changesMade}
              onSaveClicked={this.onSaveClicked}
            />
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{ translate('inventory_count') }</h1>
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="stock_take"
                updateConfigValue={this.props.updateConfigValue}
                originalColumns={List(transformColumnKeys(COLUMNS.toArray()))}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
            </Header>
            <FixedTableButtonHeader>
            <div className="o-header-actions">
              <div className="u-flex-left u-margin-left--half-ws">
                <Button
                  type="button"
                  className="o-button o-button--small have-background"
                  onClick={() => this.onPrintClicked()}
                  disabled={fetching}
                  dataPublic
                >
                  {translate('print_inventory_for_stock_take')}
                </Button>
                <SaveButton
                  dataPublic
                  label={this.state.showEmptyBatches ? translate('hide_empty_batches') : translate('show_empty_batches')}
                  onClick={() => {
                    const { expanded, expandedBatches, showEmptyBatches } = this.state;
                    const { expandValue, expandedBatchValues } = Object.keys(expanded).reduce(({ expandValue, expandedBatchValues }, v) => {
                      if (showEmptyBatches) {
                        const rowData = expandedBatches.get(Number(v));
                        const subRows = rowData && rowData._subRows;
                        const allBatchesAreNegative = subRows && isArray(subRows) ? subRows.every((s: any) => s._original.count && !isNaN(s._original.count) && Number(s._original.count) <= 0) : undefined;
                        if (allBatchesAreNegative) {
                          return {
                            expandValue: Object.assign({}, expandValue, { [v]: false }),
                            expandedBatchValues: expandedBatchValues.delete(Number(v)),
                          };
                        }
                      }
                      return { expandValue, expandedBatchValues };
                    }, { expandValue: expanded, expandedBatchValues: expandedBatches });
                    this.setState({
                      expanded: expandValue,
                      expandedBatches: expandedBatchValues,
                      showEmptyBatches: !showEmptyBatches,
                    });
                  }}
                  className="o-button u-margin-left--half-ws o-button--small have-background"
                />
              </div>
            </div>
            </FixedTableButtonHeader>
            <StockTakeTable
              isFetching={fetching}
              columns={transformFilteredColumns(COLUMNS.toArray(), this.state.columns)}
              data={this.state.data}
              onFilterAndSort={filteredData => this.setState({
                filteredData,
                expanded: {},
                expandedBatches: Map(),
              })}
              setPage={(index, size) => this.setState({
                pageIndex: index,
                ...(size && { pageSize: size }),
              })}
              expanded={this.state.expanded}
              onPivotRowClick={this.onPivotRowClick}
            />
          </div>
          <ModalFooter>
            <SaveButton
              dataPublic
              onClick={() => this.onSaveClicked()}
              isSaving={this.state.isSaving}
              label={translate('save')}
              className="o-button--small u-margin-right--half-ws"
              disabled={!this.state.changesMade}
            />
          </ModalFooter>
        </StatelessModal>
      </div>
    );
  }
}

export default StockTakeList;
