import { connect } from 'react-redux';

import Mykad from './../components/mykad/mykad';

import type { State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ patientStubs, config }: State) => ({ patientStubs, config });

const MykadContainer = connect(mapStateToProps)(Mykad);

export default MykadContainer;
