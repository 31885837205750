import React from 'react';
import { List } from 'immutable';

import EditHistory from './../editHistory/editHistory';
import PrintButton from './../buttons/printButton';
import translate from './../../utils/i18n';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { printDocument } from './../../utils/print';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';

import type { SaveModel, User } from './../../types';
import type DocumentDataModel from './../../models/documentDataModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  className: string,
  disableChanges: boolean,
  documentData: DocumentDataModel,
  documentTemplates: List<DocumentTemplateModel>,
  hideButtons: boolean,
  saveModel: SaveModel,
  user: User,
};
/**
 * A component that displays a DocumentDataModel.
 * @class DocumentDataItem
 * @extends {React.PureComponent}
 */
class DocumentDataItem extends React.PureComponent<Props> {
  static defaultProps = {
    disableChanges: false,
    hideButtons: false,
    isCurrentEncounter: false,
    className: '',
  };

  /**
   * Asks user for confirmation and deletes this components Document data if confirmed.
   * @param {DocumentDataModel} model The DocumentData model for editing.
   * @returns {undefined}
   */
  onDeleteClicked(model: DocumentDataModel) {
    getConfirmation(translate('confirm_item_deletion'))
      .then(
        () => this.props.saveModel(model.set('_deleted', true)),
        () => {},
      );
  }

  /**
   * Renders the delete button for this component.
   * @param {DocumentDataModel} model The DocumentData model for voiding.
   * @returns {React.Component} A Delete button component.
   */
  renderDeleteButton(model: DocumentDataModel) {
    return (
      <PermissionWrapper
        permissionsRequired={List([createPermission('document_templates', 'delete')])}
        user={this.props.user}
      >
        <div>
          <Button
            className="o-text-button o-text-button--danger"
            onClick={() => this.onDeleteClicked(model)}
            dataPublic
          >
            {translate('delete')}
          </Button>
        </div>
      </PermissionWrapper>
    );
  }

  /**
   * Returns the description string for this document data.
   * @returns {string} Description string.
   */
  getDescription(): string {
    return this.props.documentData.getName();
  }

  download = () => {
    const documentTemplate = this.props.documentTemplates
      .find(t => t.get('_id') === this.props.documentData.get('document_template_id'));
    if (documentTemplate) {
      this.props.documentData.downloadDocument(documentTemplate.get('asset_id'));
    }
  }

  /**
   * Renders the actions for this document data.
   * @param {DocumentDataModel} documentData The document data model.
   * @returns {React.Node}
   */
  renderActions(documentData: DocumentDataModel) {
    const documentTemplate = this.props.documentTemplates
      .find(t => t.get('_id') === documentData.get('document_template_id'));
    return (
      <div className="o-data-list__row__actions">
        <Button
          className="o-text-button o-text-button--contextual"
          onClick={this.download}
          dataPublic
        >
          {translate('download')}
        </Button>
        {
          documentTemplate &&
          <PrintButton onPrint={() => printDocument(documentData, documentTemplate)} />
        }
        {
          documentData.hasBeenSaved() &&
          <EditHistory
            model={documentData}
            buttonLabel="View History"
            buttonClass="o-text-button o-text-button--contextual"
          />
        }
        { !this.props.disableChanges && this.renderDeleteButton(documentData) }
      </div>
    );
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className={`o-data-list__row ${this.props.className}`}>
        <div className="o-data-list__row__item o-data-list__row__item--small">{translate('document')}</div>
        <div className="o-data-list__row__item">
          <p className="u-font-small">{this.getDescription()}</p>
        </div>
        {
          this.props.documentData &&
          !this.props.hideButtons &&
          this.renderActions(this.props.documentData)
        }
      </div>
    );
  }
}

export default DocumentDataItem;
