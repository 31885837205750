import moment from 'moment';
import { generateMD5Digest } from './crypto';

// NOTE: Use types to keep the actual categories/labels/actions being sent to GA in check. Make sure
// any new items are approved by Adam (or whoever is in charge of analytics) first, so that
// we know everything is relevant.
type GAEventAction = 'click' | 'show';
type GAEventCategory = 'SIDEBAR_ITEM' | 'SIDEBAR_SUBITEM' | 'PATIENT_LIST' | 'REFER_MODAL'
  | 'DRUG_ALLERGY_CHECKER' | 'DATA_EXPORT_DOWNLOAD' | 'DATA_EXPORT_REQUEST' | 'PHARMACONNECT_CALLBACK' | 'PHARMACONNECT_BRAND_CARD'
  | 'CLAIM_INVOICES' | 'APPOINTMENT' | 'PHARMACONNECT_SPLASH_AD';
type GAEventLabel = string; // Just make sure it's useful and consistent.

// EventActions
export const CLICK = 'click';
export const SHOW = 'show';

// EventCategories
export const SIDEBAR_ITEM = 'SIDEBAR_ITEM';
export const SIDEBAR_SUBITEM = 'SIDEBAR_SUBITEM';
export const PATIENT_LIST = 'PATIENT_LIST';
export const REFER_MODAL = 'REFER_MODAL';
export const DRUG_ALLERGY_CHECKER = 'DRUG_ALLERGY_CHECKER';
export const DATA_EXPORT_DOWNLOAD = 'DATA_EXPORT_DOWNLOAD';
export const DATA_EXPORT_REQUEST = 'DATA_EXPORT_REQUEST';
export const PHARMACONNECT_CALLBACK = 'PHARMACONNECT_CALLBACK';
export const PHARMACONNECT_BRAND_CARD = 'PHARMACONNECT_BRAND_CARD';
export const CLAIM_INVOICES = 'CLAIM_INVOICES';
export const APPOINTMENT = 'APPOINTMENT';
export const PHARMACONNECT_SPLASH_AD = 'PHARMACONNECT_SPLASH_AD';

/**
 * Sends an event to Google Analytics
 * @param {GAEventCategory} category The category of the event
 * @param {GAEventAction} action The action being tracked
 * @param {GAEventLabel} label The label of the event
 * @param {Object} value An optional value for the event.
 * @returns {void}
 */
export function trackEvent(
  category: GAEventCategory,
  action: GAEventAction,
  label: GAEventLabel,
  value?: Object,
) {
  if (window.ga) {
    const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    const formattedValue = value ?
      { ...value, dimension2: date } :
      { dimension2: date };
    window.ga('send', 'event', category, action, label, formattedValue);
  }
}

/**
 * Set the current user.
 * @param {string} userId The User id
 * @returns {void}
 */
export function setUserId(userId: string) {
  const MD5_SALT = 'GmX#cufjxNMd8X7J';
  if (window.ga) {
    window.ga('set', 'userId', `${generateMD5Digest(location.host, MD5_SALT)}-${generateMD5Digest(userId, MD5_SALT)}`);
    window.ga('send', 'event', 'authentication', 'user-id available');
  }
}
