import * as React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import Table from './../table/table';
import PermissionWrapper from './../permissions/permissionWrapper';
import DeleteButton from './../buttons/deleteButton';
import { createPermission } from './../../utils/permissions';
import { renderDate, renderMultilineCell, renderPrice, renderWithVoidWrapper, renderTextCapitalized } from './../../utils/tables';
import { sortByDate } from './../../utils/comparators';
import { UNICODE } from './../../constants';
import Button from './../buttons/button';

import type ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import type ClaimReconciliationModel from './../../models/claimReconciliationModel';
import type { SaveModel, User } from './../../types';

type Row = {
  paymentType: string | React.Node,
  bank: string | React.Node,
  chequeNumber: string | React.Node,
  amountPaid: string,
  notes: string,
  renderAsVoid: boolean,
  delete: React.Node,
  paymentRecorded: number,
  paymentReceived: number,
};

type Props = {
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  claimRecons: List<ClaimReconciliationModel>,
  saveModel: SaveModel,
  onDeleteClicked: (payment: ClaimInvoicePaymentModel) => void,
  user: User,
};
/**
 * Component displaying Claim Invoice payments.
 * @class ClaimInvoicePaymentsTable
 * @extends {React.Component}
 */
class ClaimInvoicePaymentsTable extends React.PureComponent<Props> {
  props: Props;

  /**
   * Returns a Delete button for the given item.
   * @param {ClaimInvoicePaymentModel} item An item for the delete button
   * @returns {React.Node} A Delete button element.
   */
  getDeleteButton(item: ClaimInvoicePaymentModel) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('claim_invoice_payment', 'delete')])} user={this.props.user}>
        <DeleteButton
          item={item}
          saveModel={this.props.saveModel}
          label={translate('void_payment')}
          callback={this.props.onDeleteClicked}
          confirmTitle={translate('confirm_payment_void')}
          successMessage={translate('payment_voided')}
          dataPublic
        />
      </PermissionWrapper>
    );
  }

  /**
   * Generates the table rows from the ClaimInvoice payments.
   * @param {ClaimInvoicePaymentModel} claimInvoicePayment An individual payment
   * @returns {Row} The row of the table
   */
  getRow(claimInvoicePayment: ClaimInvoicePaymentModel): Row {
    const claimRecon = this.props.claimRecons.find(c =>
      c.get('_id') === claimInvoicePayment.get('claim_recon_id'));
    return {
      paymentRecorded: claimInvoicePayment.getCreatedTime(),
      paymentReceived: claimRecon.get('timestamp'), // we were(are) not setting claimInvoicePayment timestamp correctly
      amountPaid: claimInvoicePayment.get('amount', 0),
      notes: claimRecon.get('notes', UNICODE.EMDASH, false),
      paymentType: renderWithVoidWrapper(claimRecon.get('method', UNICODE.EMDASH), claimInvoicePayment.isVoid()),
      bank: renderWithVoidWrapper(claimRecon.get('bank', UNICODE.EMDASH), claimInvoicePayment.isVoid()),
      chequeNumber: renderWithVoidWrapper(claimRecon.get('cheque_number', UNICODE.EMDASH), claimInvoicePayment.isVoid()),
      delete: !claimInvoicePayment.isVoid() ? this.getDeleteButton(claimInvoicePayment) : <Button dataPublic className="o-text-button o-text-button--danger" disabled>{translate('voided')}</Button>,
      renderAsVoid: claimInvoicePayment.isVoid(),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { claimInvoicePayments } = this.props;
    return (
      <Table
        columns={[
          { accessor: 'paymentRecorded', Header: translate('payment_recorded'), Cell: renderDate, sortMethod: sortByDate },
          { accessor: 'paymentReceived', Header: translate('payment_received'), Cell: renderDate, sortMethod: sortByDate },
          { accessor: 'paymentType', Header: translate('payment_type'), Cell: renderTextCapitalized },
          { accessor: 'bank', Header: translate('bank') },
          { accessor: 'chequeNumber', Header: translate('cheque_number') },
          { accessor: 'amountPaid', Header: translate('amount_paid'), Cell: renderPrice },
          { accessor: 'notes', Header: translate('notes'), Cell: renderMultilineCell },
          { accessor: 'delete', Header: '' },
        ]}
        data={claimInvoicePayments.map(p => this.getRow(p)).toArray()}
        noDataText={translate('no_items')}
        showPagination
        defaultSorted={[{ id: 'date', desc: false }]}
      />
    );
  }
}

export default ClaimInvoicePaymentsTable;
