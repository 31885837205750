import { connect } from 'react-redux';

import DocumentTemplates from './../components/documentTemplates/documentTemplates';
import { saveFactory } from './../utils/redux';
import { sortModelByOrder } from './../utils/comparators';
import { updateConfig } from './../actions';

import type { Dispatch, State, Config } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ user, documentTemplates, config }: State) => ({
  config,
  user,
  documentTemplates: documentTemplates
    .filter(d => d.isVisible())
    .sort(sortModelByOrder),
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const DocumentTemplatesContainer = connect(mapStateToProps, mapDispatchToProps)(DocumentTemplates);

export default DocumentTemplatesContainer;
