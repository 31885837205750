import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PharmaConnectDetailingModal from './../components/pharmaconnect/detailingModal';
import { setPharmaConnectLargeDetailing } from './../actions';

import type { Dispatch, State } from './../types';
import DetailingResponseModel from '../models/pharmaconnect/detailingResponseModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({
    pharmaconnectViews,
    klinifyConfig,
  }: State) => ({
    // make sure to clear this from session storage on logout
    program: 'pharmaconnect',
    detailing: pharmaconnectViews.largeDetailing,
    klinifyConfig,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setDetailing: (detailing: DetailingResponseModel) =>
    dispatch(setPharmaConnectLargeDetailing(detailing)),
});

const PharmaConnectDetailingContainer =
withRouter(connect(mapStateToProps, mapDispatchToProps)(PharmaConnectDetailingModal));

export default PharmaConnectDetailingContainer;
