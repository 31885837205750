/* eslint-disable import/prefer-default-export */
import { List } from 'immutable';

/**
 * Takes an array of functions and executes them in series. Returns a promise which resolves
 * to an array of results for all the functions
 * @param {Array<Functions>} providers An array of functions to be executed.
 * @returns {Promise<Array<any>>} A promise for an arrray of results.
 */
export function seriesExecutor(providers) {
  const ret = Promise.resolve(null);
  const results = List([]);

  return providers.reduce((result, provider, index) =>
    result.then(() =>
      Promise.resolve(provider())
        .then(val => results.set(index, val))), ret)
    .then(() => results);
}

/**
 * Takes any object or primitive and returns the proper type. Used to fix issues with the native typeof
 * @param {any} obj The object to get type of
 * @returns {type}
 */
export function toType(obj) {
  return Object.prototype.toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
}
