import * as React from 'react';
import { Map } from 'immutable';
import moment, { Moment } from 'moment';

import translate from './../../utils/i18n';
import DateRangePicker from './../inputs/dateRangePicker';

import type { Values } from './../inputs/dateRangePicker';
import type { HTMLStyle, MapValue } from './../../types';
import { OrientationShape } from 'react-dates';


export type NewFilterType = {
  filterDateStart: Moment,
  filterDateEnd: Moment,
};

type Props = {
  filter: Map<string, MapValue>,
  label?: string,
  maxDate?: Moment,
  style: HTMLStyle,
  orientation?: OrientationShape,
  small?: boolean,
  onUpdateFilter: (newFilter: Partial<NewFilterType>) => void,
};

/**
 * The TableDateRangePicker component
 * @class ModalFooter
 * @extends {React.Component<Props, State>}
 */
const TableDateRangePicker = ({ style = {}, filter, onUpdateFilter, label = '', maxDate = moment(), orientation = 'horizontal', small = false }: Props) => (
  <DateRangePicker
    style={style}
    className="u-margin-right--1ws u-flex-column"
    label={label || translate('dates')}
    id="filter-dates"
    startDate={filter.get('filterDateStart')}
    endDate={filter.get('filterDateEnd')}
    maxDate={maxDate}
    small={small}
    orientation={orientation}
    onValueChanged={(value: Values) => {
      // We need to do this because of how DRP handles out of bounds selections.
      const newFilter = {};
      if (value.startDate) {
        newFilter.filterDateStart = value.startDate;
      }
      if (value.endDate) {
        newFilter.filterDateEnd = value.endDate;
      } else if (value.endDate === null) { // occurs if end date set before start date.
        newFilter.filterDateEnd = value.startDate;
      }
      onUpdateFilter(newFilter);
    }}
  />
);

export default TableDateRangePicker;
