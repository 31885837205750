type src = {
    data: Promise<string | void>;
    version: string;
};

/**
 * @param {string[]} changelogArray Takes in a string array with changelog filenames
 * @returns {string[]} Returns a changelog array sorted with latest first
 */
function createChangelog(changelogArray: string[]): string[] {
  const regex = /^[0-9]*-[0-9]*-[0-9]*\.md$/;
  const result = changelogArray.filter(file => file.match(regex)).map(fileName => fileName.replace('.md', '')).sort().reverse();
  return result;
}

/**
 * @param {string} versionNumber Version number with dashes instead of dots. E.g. 1.10.3 becomes 1-10-3
 * @returns {Promise}
 */
function fetchChangelog(versionNumber: string) {
  return fetch(`./static/changelogs/${versionNumber}.md`)
    // eslint-disable-next-line consistent-return
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

export const CHANGELOGS = createChangelog(process.env.CHANGELOGS as unknown as string[]);

/**
 * @returns {src} Returns data and version of the latest changelog.
 */
export function getLatestChangelog() {
  const src: src = {
    data: fetchChangelog(CHANGELOGS[0]),
    version: CHANGELOGS[0],
  };
  return src;
}


/**
 * @param {string} versionNumber Version number with dashes instead of dots. E.g. 1.10.3 becomes 1-10-3
 * @returns {string} Data of the requested changelog.
 */
export function getChangelog(versionNumber: string) {
  return fetchChangelog(versionNumber);
}
