import React, { useEffect } from 'react';
import type { List } from 'immutable';
import type { RouteComponentProps } from 'react-router-dom';
import type { AnalyticsReportsListResponse, AnalyticsKeyMetricsResponse } from './../../types';
import DateRangeSelect from './dateRangeSelect';
import SummaryList from './summaryList';
import ReportsList from './reportsList';
import type SummaryItemModel from '../../models/analytics/summaryItemModel';
import type ReportModel from '../../models/analytics/reportModel';

type Props = RouteComponentProps & {
  dateRange: string,
  summaryItems: List<SummaryItemModel>,
  reportItems: List<ReportModel>,
  fetchKeyMetricsList: (string) => Promise<AnalyticsKeyMetricsResponse>,
  fetchAnalyticsReportsList: () => Promise<AnalyticsReportsListResponse>,
  setAnalyticsKeyMetricsList: (summaryItems: List<SummaryItemModel>) => void,
  setAnalyticsReportList: (reportItems: List<ReportModel>) => void,
  setAnalyticsDateRange: (range: string) => void
};

/**
 * Renders the summary page in /analytics route
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function SummaryPage(props: Props) {
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  const {
    dateRange,
    summaryItems,
    reportItems,
    fetchKeyMetricsList,
    fetchAnalyticsReportsList,
    setAnalyticsKeyMetricsList,
    setAnalyticsReportList,
    setAnalyticsDateRange,
  } = props;

  /**
   * fetch data within the date range.
   * @param {string} dRange Date range string.
   * @returns {null}
   */
  function fetchData(dRange: string) {
    const dataPromises = [fetchKeyMetricsList(dRange), fetchAnalyticsReportsList()];

    Promise.all(dataPromises).then((response) => {
      setAnalyticsKeyMetricsList(response[0].data);
      setAnalyticsReportList(response[1].data);
    });
  }

  useEffect(() => {
    fetchData(dateRange);
  }, [dateRange]);

  const reportsList = reportItems
    .groupBy(item => item.get('category_type'))
    .valueSeq()
    .map((list) => {
      const category = list.first().get('category_type');
      return (
        <ReportsList
          key={category}
          title={category}
          items={list}
        />
      );
    });

  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      <DateRangeSelect dateRange={dateRange} onChange={value => setAnalyticsDateRange(value)} />
      <SummaryList title="Summary" items={summaryItems} />
      <hr />
      {reportsList}
    </section>
  );
}

export default SummaryPage;
