import React from 'react';
import glamorous from 'glamorous';
import { Map } from 'immutable';

import Input from '../inputs/input';
import Label from '../inputs/label';
import Select from '../inputs/select';
import translate from '../../utils/i18n';
import { CAMPAIGN_TIME_UNITS, CAMPAIGN_SPECIFIC_OPTIONS } from '../../constants';

import type { ScheduleAt } from '../../types';
import PatientCampaignModel from '../../models/patientCampaignModel';
import MasterCampaignModel from '../../models/masterCampaignModel';

type Props = {
  errorMessage: string,
  onChange: (value: ScheduleAt) => void,
  target: string,
  scheduleValue: number | null,
  scheduleUnit: string,
  disabled?: boolean,
  campaignId?: string,
  jobType: string,
  campaigns: Map<string, PatientCampaignModel | MasterCampaignModel>,
  jobs: Map<string, PatientCampaignModel>,
};

const FormFieldContainer = glamorous.div({
  alignItems: 'center',
});

const SubLabel = glamorous.span({
  margin: '0 1rem 1.333rem 0',
  fontStyle: 'normal',
});
const SubText = glamorous.p({
  fontStyle: 'italic',
  fontSize: '0.9rem',
  margin: '0 1rem 1.333rem 0',
});

/**
 * Renders a component showing the schedule form section of the sms campaign form.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
function ScheduleFormField(props: Props) {
  /**
   * Calls when form schedule value change
   * @param {string} value props.
   * @returns {void}
   */
  function onScheduleUnitChange(value: string) {
    props.onChange({
      scheduleUnit: value,
      scheduleValue: props.scheduleValue,
    });
  }

  /**
   * Calls when form schedule unit change
   * @param {number | string} value props.
   * @returns {void}
   */
  function onScheduleValueChange(value: number | string) {
    props.onChange({
      scheduleUnit: props.scheduleUnit,
      scheduleValue: value !== '' ? parseInt(value, 10) : null,
    });
  }
  const jobOptions = props.campaigns.merge(props.jobs)
    .map(c => ({ value: c.get('_id'), label: c.get('name') })).toList().toArray();
  return (
    <React.Fragment>
      <div>
        <Label
          id="scheduled-day-label"
          label={`${translate('scheduled_day_for_sms_to_be_sent')}:`}
          invalid={false}
        />
      </div>
      <div>
        <FormFieldContainer className="u-flex-row">
          <SubLabel className="o-sub-label">{translate('sms_will_be_sent')}</SubLabel>
          <Input
            id="rule-schedule-value"
            label=""
            divClassName="u-width-180 u-margin-right--half-ws"
            type="number"
            value={props.scheduleValue ? String(props.scheduleValue) : ''}
            placeholder="#"
            min={1}
            max={365}
            onValueChanged={onScheduleValueChange}
            disabled={props.disabled}
          />
          <Select
            id="rule-schedule-unit"
            label=""
            options={CAMPAIGN_TIME_UNITS}
            value={props.scheduleUnit}
            onValueChanged={onScheduleUnitChange}
            className="u-width-180 u-margin-right--half-ws"
            clearable={false}
            disabled={props.disabled}
          />
          <SubLabel className="o-sub-label">{translate('after')}</SubLabel>
          <Select
            id="job-type"
            label=""
            options={CAMPAIGN_SPECIFIC_OPTIONS}
            value={props.jobType}
            onValueChanged={() => { }}
            className="u-width-180 u-margin-right--half-ws"
            clearable={false}
            disabled
          />

          {/* <SubLabel className="o-sub-label">{translate('after_encounter')}</SubLabel> */}
        </FormFieldContainer>
        {props.campaignId &&
          <Select
            id="job-campaign"
            label=""
            options={jobOptions}
            value={props.campaignId}
            onValueChanged={() => { }}
            clearable={false}
            disabled
          />
        }
        <br />
        {
          props.target === 'retrospective' &&
          <SubText>
            If the Scheduled day for SMS to be sent is over,
            SMS will be sent 1 day after campaign activation.
          </SubText>
        }
      </div>
      <div>
        {props.errorMessage && (
          <div
            className="o-text-input__error"
            style={{ marginTop: 0, marginBottom: '1rem' }}
          >
            {props.errorMessage}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ScheduleFormField;
