import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import Moment from 'moment';

import PaymentHistory from './../components/paymentHistory/paymentHistory';
import AsyncFetch from './../components/asyncFetch';
import {
  setPaymentHistoryFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
  updateConfigValue, updateConfig, setCurrentDataViewsError,
} from './../actions';
import { getPaymentHistoryDataViews } from './../dataViews';

import type { Dispatch, State, Model, MapValue, Config, DataView } from './../types';
import APIError from '../utils/apiError';
import BaseModel from '../models/baseModel';

/**
 * Returns a List of unique bills by filtering duplicates if any returned from view.
 * @param {List<Model>} dataViewModels A List ofcurrent data view models.
 * @returns {List<Model>} list of filtered unique bill models
 */
function uniqueBills(dataViewModels: List<Model>): List<Model> {
  const bills = dataViewModels.filter(m => m.get('type') === 'bill' && !m.isVoid() && m.isFinalised());
  let uniqueBillList = List();
  bills.forEach((b) => {
    if (!uniqueBillList.find(u => u.get('_id') === b.get('_id'))) {
      uniqueBillList = uniqueBillList.push(b);
    }
  });
  return uniqueBillList;
}

/**
 * Returns date for the filter name passed, from reportsFilter
 * @param {Map<string, MapValue>} reportsFilter filter for all reports
 * @param {string} filterName name of the filter.
 * @returns {Moment}
 */
function getDateValue(reportsFilter: Map<string, MapValue>, filterName: string): Moment {
  const date = reportsFilter.get('payments') !== undefined ?
    reportsFilter.getIn(['payments', filterName], new Moment()) : undefined;
  return date || new Moment(); // instead of null, returning current date as default
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  user: state.user,
  filter: state.reportsFilter.get('payments'),
  config: state.config,
  encounters: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'encounter'),
  bills: state.isFetching ? List() : uniqueBills(state.currentDataViewsModels),
  payments: state.isFetching ? List() : state.currentDataViewsModels.filter(m => m.get('type') === 'payment' && !m.isVoid()),
  isFetching: state.isFetching,
  containerDataViews: getPaymentHistoryDataViews(
    getDateValue(state.reportsFilter, 'filterDateStart').valueOf(),
    getDateValue(state.reportsFilter, 'filterDateEnd')
      .clone()
      .minutes(59)
      .hours(23)
      .seconds(59)
      .valueOf(),
  ),
  currentDataViews: state.currentDataViews,
  showLoadingIndicator: false,
  currentDataViewsError: state.currentDataViewsError,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, any>) => dispatch(setPaymentHistoryFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const PaymentHistoryContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(PaymentHistory));

export default PaymentHistoryContainer;
