import React, { Fragment } from 'react';
import moment, { Moment } from 'moment';
import { List } from 'immutable';

import Input from './../inputs/input';
import DatePicker from './../inputs/statefulDatepicker';
import SaveButton from './../buttons/saveButton';
import TextArea from './../inputs/textarea';
import translate from './../../utils/i18n';
import { wsUnit } from './../../utils/css';
import ModalFooter from './../modals/modalFooter';
import FormError from './../formError';
import { UNICODE } from './../../constants';
import { validateAndTrimString } from './../../utils/utils';

import type { Config, ClaimInvoiceFormAttributes } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';

type Props = {
  onSaveClicked: (attributes: ClaimInvoiceFormAttributes) => void,
  coveragePayors: List<CoveragePayorModel> | null | undefined,
  config: Config,
};

type State = {
  isSaving: boolean,
  attributes: ClaimInvoiceFormAttributes,
  coveragePayorID?: string,
  invoiceID?: string,
  name?: string,
  errorMessage?: string,
};


/**
 * A form for the metadata of a ClaimInvoice
 * @class ClaimInvoiceMetadataForm
 * @extends {React.Component<Props, State>}
 */
class ClaimInvoiceMetadataForm extends React.Component<Props, State> {
  /**
   * Creates an instance of ClaimInvoiceMetadataForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    let attributes = {
      timestamp: new Date().valueOf(),
      from: props.config.getIn(['clinic', 'name'], ''),
      to: [],
      internal_clinic_id: [],
      notes: '',
    };
    if (props.coveragePayors) {
      attributes = Object.assign({}, attributes, this.getCoveragePayorInfo(props.coveragePayors));
    }
    this.state = {
      isSaving: false,
      attributes,
      errorMessage: undefined,
    };
  }

  /**
   * Handle props changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    const currentPayors = this.props.coveragePayors ? this.props.coveragePayors.size : undefined;
    const nextPayors = nextProps.coveragePayors ? nextProps.coveragePayors.size : undefined;
    if (currentPayors !== nextPayors && nextProps.coveragePayors) {
      this.setState({
        attributes: Object.assign(
          {},
          this.state.attributes,
          this.getCoveragePayorInfo(nextProps.coveragePayors),
        ),
      });
    }
  }

  /**
   * Sets the Invoice ID and to field using the current coveragePayor.
   * @param {List<CoveragePayorModel>} coveragePayors The coverage payor to use to set the ID.
   * @return {{}}
   */
  getCoveragePayorInfo(coveragePayors: List<CoveragePayorModel>) {
    const invoiceIds : Array<{coveragePayorID : string, invoiceID : string}> = [];
    const to : Array<{coveragePayorID : string, name : string}>= [];
    coveragePayors.map((coveragePayor) => {
      invoiceIds.push({ coveragePayorID: coveragePayor.get('_id'), invoiceID: `${moment().format('YYYYMM')}${coveragePayor.getClinicID()}` });
      to.push({ coveragePayorID: coveragePayor.get('_id'), name: coveragePayor.get('name') });
      return true;
    });
    return {
      internal_clinic_id: invoiceIds,
      to,
    };
  }

  /**
   * Returns true if form is valid.
   * @returns {boolean}
   */
  isValid(): boolean {
    if (this.state.attributes.internal_clinic_id &&
      this.state.attributes.internal_clinic_id.length &&
      (validateAndTrimString(this.state.attributes.internal_clinic_id[0]) as { [key: string]: string }).invoiceID.length &&
      this.state.attributes.to &&
      this.state.attributes.to.length &&
      (validateAndTrimString(this.state.attributes.to[0]) as { [key: string]: string }).name.length &&
      validateAndTrimString(this.state.attributes.from).length &&
      this.state.attributes.timestamp) {
      return true;
    }
    if (this.state.attributes.timestamp === null) {
      this.setState({ errorMessage: translate('fill_date_in_correct_format') });
      return false;
    }
    this.setState({ errorMessage: translate('fill_required_fields') });
    return false;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const isMultipleCoveragePayors = this.props.coveragePayors
      ? this.props.coveragePayors.size > 1
      : false;
    return (
      <Fragment>
        <div className="o-form">
          { this.state.errorMessage &&
            <div className="u-margin--standard">
              <FormError containerElementID="addEditAllergyForm">{this.state.errorMessage}</FormError>
            </div>
          }
          <Input
            id="claim_invoice_invoice_number"
            label={translate('invoice_number')}
            value={isMultipleCoveragePayors
              ? UNICODE.EMDASH
              : (this.state.attributes.internal_clinic_id &&
                this.state.attributes.internal_clinic_id.length &&
                this.state.attributes.internal_clinic_id[0].invoiceID)
            }
            onValueChanged={
              (value: string) => {
                this.setState(
                  Object.assign(this.state.attributes.internal_clinic_id[0],
                    { invoiceID: value }),
                );
              }
            }
            required
            disabled={this.state.isSaving || isMultipleCoveragePayors}
          />
          <Input
            id="claim_invoice_from"
            label={translate('from')}
            value={this.state.attributes.from}
            onValueChanged={
              (from: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { from }),
              })
            }
            required
            disabled={this.state.isSaving}
          />
          <Input
            id="claim_invoice_to"
            label={translate('to')}
            value={
              isMultipleCoveragePayors
                ? UNICODE.EMDASH
                : (this.state.attributes.to &&
                this.state.attributes.to.length &&
                this.state.attributes.to[0].name)}
            onValueChanged={
              (value: string) => {
                this.setState(
                  Object.assign(this.state.attributes.to[0],
                    { name: value }),
                );
              }
            }
            required
            disabled={this.state.isSaving || isMultipleCoveragePayors}
          />
          <DatePicker
            id="claim_invoice_date"
            label={translate('date')}
            value={this.state.attributes.timestamp ? moment(this.state.attributes.timestamp) : null}
            onValueChanged={
              (momentObj: Moment) => this.setState({
                attributes: Object.assign({}, this.state.attributes, {
                  timestamp: momentObj ? momentObj.valueOf() : null,
                }),
              })
            }
            required
            disabled={this.state.isSaving}
            allowPast
            style={{ marginBottom: wsUnit }}
            showClearDate={false}
          />
          <TextArea
            id="claim_invoice_notes"
            label={translate('notes')}
            value={this.state.attributes.notes}
            onValueChanged={
              (notes: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { notes }),
              })
            }
            disabled={this.state.isSaving}
          />
        </div>
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('confirm_invoice')}
            onClick={() => {
              this.setState({ errorMessage: undefined });
              if (this.isValid()) {
                this.setState({ isSaving: true });
                this.props.onSaveClicked(this.state.attributes);
              }
            }}
            disabled={this.state.isSaving}
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default ClaimInvoiceMetadataForm;
