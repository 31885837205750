import React from 'react';
import AnnouncementModal from './modals/announcementModal';

type Props = {
  program: string,
  onModalClose?: () => void,
  show?: boolean,
};

/**
 * Render the banner
 * @param {string} program text to add
 * @returns {HTMLElement} html element
 */
const NewMDLFeatureModal = ({ program, onModalClose, show }: Props) => (
  <AnnouncementModal
    program={program}
    id="new-inventory-mapping-feature"
    modalStyle={{ minWidth: 500 }}
    onModalClose={onModalClose}
    show={show}
    image={<img src="/static/images/MDL_V3__Popup.png" alt="Mapping feature" className="u-full-width" />}
  >
    <p>
        We are launching these features in phases starting from&nbsp;
      <span className="u-strong">20th April 2020</span> to help you with your mapping process.
        We need your help to update the details of the last few drugs
        that you are left unmapped through these new features.
    </p>
    <br />
    <p>Our new features include<span className="u-strong">:</span></p>
    <br />
    <ol className="u-padding--standard-sides">
      <li>
        <span className="u-strong">The ability to add information of drugs
            that are not available on the MDL.
        </span>
      </li>
      <li>
        <span className="u-strong">The ability to map drugs that are newly added
            to the inventory on one page.
        </span>
      </li>
      <li>
        <span className="u-strong">Displaying of MDL name in your inventory and
            prescription tabs.
        </span>
      </li>
    </ol>
    <br />
    <p>
        Instructions to fill in active ingredients and the addition of new drugs can be found here
      <a target="_blank" rel="noopener noreferrer" href="https://klin.freshdesk.com/support/solutions/folders/36000208719">
        <span className="u-strong">&nbsp;FAQ</span>
      </a>
    </p>
    <br />
    <p>
        There will be a popup reminder
        for unmapped drugs after the completion of an encounter and a bill to
        ensure the drug list is completely mapped. This ensures for features like
        the drug interaction checker work optimally.
    </p>
    <p className="u-margin-top--standard">If you need further assistance, feel free to check our FAQ or contact
        Support Klinify via the icon chat
    </p>
    <i style={{ content: "url('/static/images/freshchat_icon.png')", height: 48, marginTop: 6 }} />
    <br />
    <p>Thank You.</p>

  </AnnouncementModal>
);

export default NewMDLFeatureModal;
