import { fabric } from 'fabric';
import moment from 'moment';

import { dataURItoBlob } from './utils';

import type TemplateModel from './../models/templateModel';
import type PatientModel from './../models/patientModel';

type FabricTextParams = {
  left: number,
  top: number,
  fontSize: number,
  stroke: string,
};

/**
 * Takes an image blob and creates a Fabric.Canvas instance from it.
 * @param {Blob} blob A blob representing an image
 * @returns {Promise<fabric.Canvas>}
 */
function blobToCanvas(blob: Blob) {
  return new Promise((resolve) => {
    fabric.Image.fromURL(URL.createObjectURL(blob), (img: Image) => {
      const canvas = new fabric.Canvas(document.createElement('canvas'));
      canvas.setDimensions({
        width: img.width,
        height: img.height,
      });
      canvas.setBackgroundImage(img);
      resolve(canvas);
    });
  });
}

/**
 * Converts a canvas to a Blob for saving to the API.
 * @param {fabric.Canvas} canvas The Fabric Canvas.
 * @returns {Promise<Blob>}
 */
function canvasToBlob(canvas) {
  return Promise.resolve(dataURItoBlob(canvas.toDataURL({ enableRetinaScaling: true })));
}

/**
 * Converts the parameters of an etch as specified in the Template spec to an object of params
 * understandable by Fabric.
 * @param {number} xStart The starting X co-ordinate
 * @param {number} yStart The starting Y co-ordinate
 * @param {string} fontColor The font colour of the etch
 * @param {number} fontSize The font Size of the Etch
 * @returns {{}}
 */
function convertParams(
  xStart: number,
  yStart: number,
  fontColor: string = '#000',
  fontSize: number = 12,
) {
  return {
    left: xStart,
    top: yStart,
    fontSize,
    stroke: fontColor,
  };
}

/**
 * Etches a text object to the canvas.
 * @param {fabric.Canvas} canvas The Fabric canvas
 * @param {string} text The text to etch
 * @param {FabricTextParams} params The etching parameters.
 * @returns {void}
 */
function etchText(canvas: fabric.Canvas, text: string, params: FabricTextParams) {
  const textObject = new fabric.Text(text, params);
  canvas.add(textObject);
}

/**
 * Applies any necessary etches to the given asset (if that asset matches the one specified in the
 * instructions).
 * @param {TemplateModel} template The template.
 * @param {Blob} asset The asset blob.
 * @param {number} index The index of the asset (used to determine the assetID).
 * @param {PatientModel} patient The patient this asset will be created for.
 * @returns {Blob}
 */
function applyEtch(template: TemplateModel, asset: Blob, index: number, patient: PatientModel) {
  const assetID = template.get('assets').length > index ? template.get('assets')[index] : undefined;
  return blobToCanvas(asset)
    .then((canvas) => {
      template.get('etch_instructions').forEach((instruction) => {
        if (!instruction.asset_id || instruction.asset_id === assetID) { // Only etch if the asset_id matches
          const params = convertParams(
            instruction.coordinate_x_start,
            instruction.coordinate_y_start,
            instruction.font_color,
            instruction.font_size,
          );
          if (instruction.etch_data_type === 'current_date') {
            etchText(canvas, moment().format('DD/MM/YYYY'), params);
          } else if (instruction.etch_data_type === 'patient_name') {
            etchText(canvas, patient.get('patient_name'), params);
          } else if (instruction.etch_data_type === 'patient_ic') {
            etchText(canvas, patient.get('ic'), params);
          }
        }
      });
      return canvasToBlob(canvas);
    });
}

/**
 * Receives a template and an array of AssetBlobs. If the Template has etch_instructions these are
 * applied to etch Blob. If not the assetBlobs are returned unaltered.
 * @param {TemplateModel} template A TemplateModel
 * @param {Array<Blob>} assets An array of blobs for image assets.
 * @param {PatientModel} patient The patient this casenote is being created for.
 * @returns {Promise<Array<Blob>>}
 */
export function applyEtches( // eslint-disable-line import/prefer-default-export
  template: TemplateModel,
  assets: Array<Blob>,
  patient: PatientModel,
): Promise<Array<Blob>> {
  if (!template.has('etch_instructions') || template.get('etch_instructions').length === 0) {
    return Promise.resolve(assets);
  }
  return Promise.all(assets.map((blob, i) => applyEtch(template, blob, i, patient)));
}
