import React, { useState } from 'react';
import translate from './../../utils/i18n';

import type {
  AnalyticsQueryResponseAggregateMetric,
  AnalyticsQueryResponseTimeSeriesMetric,
  AnalyticsQueryResponseKeyTitle,
} from './../../types';
import tab from './tabUtils';
import BarChart from '../charts/barChart';
import PieChart from '../charts/pieChart';

type Props = {
  aggregate: { [key: string]: AnalyticsQueryResponseAggregateMetric },
  timeSeries: { [key: string]: AnalyticsQueryResponseTimeSeriesMetric },
  displayText: { [key: string]: AnalyticsQueryResponseKeyTitle },
  metric: string,
};

const chartTabs = {
  aggregate: {
    key: 'aggregate',
    label: translate('analytics_chart_aggregate'),
  },
  timeSeries: {
    key: 'timeSeries',
    label: translate('analytics_chart_time_series'),
  },
};

const aggregateCharts = {
  pie: {
    key: 'pie',
    label: translate('analytics_chart_pie'),
  },
  bar: {
    key: 'bar',
    label: translate('analytics_chart_bar'),
  },
};

const timeSeriesCharts = {
  compare: {
    key: 'compare',
    label: translate('analytics_chart_compare'),
  },
  stacked: {
    key: 'stacked',
    label: translate('analytics_chart_stacked'),
  },
};

/**
 * Returns the title for the key in the display text object
 * @param {string} key key to extract the title for
 * @param {Object} displayText The object that holds the title and an optional infotext
 * @returns {string} The title
*/
function getTitle(key, displayText) {
  if (key === '__truncate') {
    return 'Others';
  }
  return displayText[key] ? displayText[key].title : translate('unknown');
}

/**
 * Returns the data transformed for rendering barchart
 * @param {AnalyticsQueryResponseAggregateMetric} data aggregate data
 * @param {string} dataKey Key for obj
 * @param {AnalyticsQueryResponseKeyTitle} displayText meta data for display
 * @returns {Array} The list format of the data format
*/
function transformBarChartData(data, dataKey, displayText) {
  const list = Object.keys(data).map((key) => {
    const obj = {
      name: getTitle(key, displayText),
    };
    obj[dataKey] = data[key];
    return obj;
  });
  return list;
}

/**
 * Returns the data transformed for rendering piechart
 * @param {AnalyticsQueryResponseAggregateMetric} data aggregate data
 * @param {string} dataKey Key for obj
 * @param {AnalyticsQueryResponseKeyTitle} displayText meta data for display
 * @returns {Array} The list format of the data format
*/
function transformPieChartData(data, dataKey, displayText) {
  const list = Object.keys(data).map(key => ({
    name: getTitle(key, displayText),
    value: data[key],
  }));
  return list;
}

/**
 * Renders a report card with different charts
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function ReportCardChart(props: Props) {
  const {
    aggregate,
    displayText,
    metric,
  } = props;

  const [chartTab, setChartTab] = useState(chartTabs.aggregate.key);
  const [aggregateChartType, setAggregateChartType] = useState(aggregateCharts.pie.key);
  const [timeSeriesChartType, setTimeSeriesChartType] = useState(timeSeriesCharts.compare.key);
  const [dataKey, setDataKey] = useState(Object.keys(aggregate).length > 0 ? Object.keys(aggregate)[0] : '');

  const dataKeys = {};
  Object.keys(aggregate).forEach((key) => {
    dataKeys[key] = {
      key,
      label: getTitle(key, displayText),
    };
  });

  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  return (
    <div>
      <tab.Container>
        <div>
          {tab.render(chartTabs, chartTab, setChartTab)}
          {
              chartTab === chartTabs.aggregate.key ?
                tab.render(aggregateCharts, aggregateChartType, setAggregateChartType)
                : null
            }
          {
              chartTab === chartTabs.timeSeries.key ?
                tab.render(timeSeriesCharts, timeSeriesChartType, setTimeSeriesChartType)
                : null
            }
        </div>
        <div style={{ textAlign: 'right' }}>
          {tab.render(dataKeys, dataKey, setDataKey)}
        </div>
      </tab.Container>
      <tab.Body>
        {
          chartTab === chartTabs.aggregate.key && aggregateChartType === aggregateCharts.bar.key ?
            <BarChart
              data={transformBarChartData(aggregate[dataKey], dataKey, displayText)}
              xAxisKey={dataKey}
              yAxisLabel={metric}
            /> : null
        }
        {
          chartTab === chartTabs.aggregate.key && aggregateChartType === aggregateCharts.pie.key ?
            <PieChart
              data={transformPieChartData(aggregate[dataKey], dataKey, displayText)}
            /> : null
        }
        {
          chartTab === chartTabs.timeSeries.key &&
          timeSeriesChartType === timeSeriesCharts.compare.key ?
            <div>Time series comparision chart coming soon</div> : null
        }
        {
          chartTab === chartTabs.timeSeries.key &&
          timeSeriesChartType === timeSeriesCharts.stacked.key ?
            <div>Stacked time series chart coming soon</div> : null
        }
      </tab.Body>
    </div>
  );
}

export default ReportCardChart;
