import React, { Fragment } from 'react';
import glamorous from 'glamorous';

import LoadingIndicator from './../loadingIndicator';
import { getFormInput, getDocumentTemplate, setDocumentTemplateData, downloadDocument } from './../../utils/documentTemplates';
import SaveButton from './../buttons/saveButton';
import ModalFooter from './../modals/modalFooter';
import translate from './../../utils/i18n';
import { printDocument } from './../../utils/print';
import { createSuccessNotification } from './../../utils/notifications';
import DocumentDataModel from './../../models/documentDataModel';
import Button from './../buttons/button';

import type { Fields, DataObject } from './../../utils/documentTemplates';
import type { SaveModel } from './../../types';
import type DocumentTemplateModel from './../../models/documentTemplateModel';

type Props = {
  documentTemplate: DocumentTemplateModel,
  closeModal: () => void,
  saveModel?: SaveModel | null | undefined, // If not provided it's assumed the use case is to not save DocumentData to db (for example for testing a template)
  disabled: boolean,
  patientId: string,
  encounterId: string,
  practitionerId?: string,
  documentTemplateData: DataObject,
  customDocumentDataTitle?: string,
};

type State = {
  fields: Fields | void,
  isSaving: boolean,
};

const LoadingWrapper = glamorous.div({
  minHeight: '90vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

/**
 * A form component for entering data derived from a DocumentTemplate.
 * @class DocumentDataForm
 * @extends {React.Component<Props, State>}
 */
class DocumentDataForm extends React.Component<Props, State> {
  static defaultProps = {
    documentTemplateData: {},
    disabled: false,
  };

  /**
   *Creates an instance of DocumentDataForm.
   * @param {Props} props Props
   * @returns {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = { isSaving: false, fields: undefined };
    props.documentTemplate.getFormFields(props.documentTemplateData)
      .then(fields => this.setState({ fields }));
  }

  /**
   * Handles the changing of a value.
   * @param {string} field The field to update.
   * @param {string} newValue The new value.
   * @returns {void}
   */
  onValueChanged = (field: string, newValue: string | number) => {
    this.setState({
      fields: Object.assign({}, this.state.fields || {}, { [field]: newValue }),
    });
  }

  /**
   * Renders the inputs for the form.
   * @param {Fields} fields The Fields object from state. Passed as param so we can test if defined
   * prior to the function being called.
   * @returns {Array<React.Node>}
   */
  renderInputs(fields: Fields) {
    const reservedSingleWordPlaceholders = ['date', 'time', 'header', 'signature'];
    const customFields = Object.keys(fields)
      .filter(key => !key.includes('_') &&
        !reservedSingleWordPlaceholders.includes(key) &&
        !Array.isArray(fields[key]));
    const clinicLogoFieldIndex = Object.keys(fields).findIndex(s => s.match(/^clinic_logo/));
    return (
      [
        Object.keys(fields)
          .filter(key => customFields.includes(key))
          .map(key => getFormInput(key, fields[key], this.onValueChanged)),
        customFields.length > 0 && <hr />,
        Object.keys(fields)
          .filter((key, i) => !customFields.includes(key) &&
            (i === clinicLogoFieldIndex || !key.match(/^clinic_logo/)))
          .map((key) => {
            if (Array.isArray(fields[key])) {
              return (
                <div>
                  <p className="o-label">{translate(key)}</p>
                  <p className="u-margin-bottom--1ws">
                    The data for this item is collected from a number of sources
                    and cannot be edited before saving.
                  </p>
                </div>
              );
            } else if (key.match(/^clinic_logo/)) {
              return (
                <div>
                  <p className="o-label">{translate('clinic_logo')}</p>
                  <p className="u-margin-bottom--1ws">
                    The data for Clinic Logo is collected from Clinic Settings Page
                    and cannot be edited before saving.
                  </p>
                </div>
              );
            }

            return getFormInput(key, fields[key], this.onValueChanged);
          }),
      ]
    );
  }

  /**
   * handles click of save button
   * @returns {void}
   */
  onSaveClicked = () => {
    this.saveDocument(true);
  }

  /**
   * Saves document data. Shows success message if it is called from only Save button.
   * Also does nothing if saveModel is not passed in properties, eg: test document flow.
   * @param {boolean} showSuccess to indicate if success message is needed.
   * @returns {void}
   */
  saveDocument(showSuccess?: boolean): void {
    const { fields } = this.state;
    if (fields) {
      this.setState({ isSaving: true });
      if (this.props.saveModel) {
        const fileName = this.props.customDocumentDataTitle ?
          `${this.props.customDocumentDataTitle}.docx` :
          this.props.documentTemplate.getExportFileName();
        const documentData = new DocumentDataModel({
          timestamp: new Date().getTime(),
          document_template_id: this.props.documentTemplate.get('_id'),
          title: this.props.customDocumentDataTitle || this.props.documentTemplate.getName(),
          file_name: fileName,
          patient_id: this.props.patientId,
          encounter_id: this.props.encounterId,
          practitioner_id: this.props.practitionerId,
          data: this.state.fields,
        });
        if (this.props.saveModel && this.props.saveModel !== undefined) {
          this.props.saveModel(documentData)
            .then(() => {
              this.setState({ isSaving: false });
              this.props.closeModal();
              if (showSuccess) {
                createSuccessNotification(translate('document_saved'));
              }
            });
        }
      } else {
        this.setState({ isSaving: false });
        this.props.closeModal();
      }
    }
  }

  /**
   * Handles save and download of document.
   * @returns {void}
   */
  onSaveDownloadClicked = () => {
    const { fields } = this.state;
    if (fields) {
      this.saveDocument();
      const fileName = this.props.customDocumentDataTitle ?
        `${this.props.customDocumentDataTitle}.docx` :
        this.props.documentTemplate.getExportFileName();
      getDocumentTemplate(this.props.documentTemplate.get('asset_id'))
        .then(doc => setDocumentTemplateData(doc, fields))
        .then(doc => downloadDocument(doc, fileName));
    }
  }

  /**
   * Handles print and download of document.
   * @returns {void}
   */
  onSavePrintClicked = () => {
    const { fields } = this.state;
    if (fields) {
      const documentData = new DocumentDataModel({
        data: this.state.fields,
      });
      if (this.props.saveModel) {
        this.saveDocument();
        printDocument(documentData, this.props.documentTemplate);
      } else {
        this.setState({ isSaving: false });
        this.props.closeModal();
        printDocument(documentData, this.props.documentTemplate);
      }
    }
  }

  /**
   * Returns modal footer with buttons. Renders different footer with only Download and Print buttons
   * instead of save for test document template flow
   * @returns {React.Component} A ModalFooter component.
   */
  getModalFooter = () => (
    this.props.saveModel ?
      (
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            disabled={this.state.isSaving}
            label={translate('save')}
            onClick={this.onSaveClicked}
          />
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            disabled={this.state.isSaving}
            label={translate('save_and_download')}
            onClick={this.onSaveDownloadClicked}
          />
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            disabled={this.state.isSaving}
            label={translate('save_and_print')}
            onClick={this.onSavePrintClicked}
          />
          {
              !this.props.disabled &&
              <Button
                onClick={() => this.props.closeModal()}
                className="o-button o-button--small o-button--danger u-margin-right--half-ws"
                dataPublic
              >
                {translate('cancel')}
              </Button>
            }
        </ModalFooter>
      )
      :
      (
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            disabled={this.state.isSaving}
            label={translate('download')}
            onClick={this.onSaveDownloadClicked}
          />
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            disabled={this.state.isSaving}
            label={translate('print')}
            onClick={this.onSavePrintClicked}
          />
          {
              !this.props.disabled &&
              <Button
                onClick={() => this.props.closeModal()}
                className="o-button o-button--small o-button--danger u-margin-right--half-ws"
                dataPublic
              >
                {translate('cancel')}
              </Button>
            }
        </ModalFooter>
      )
  )

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { fields } = this.state;
    if (!fields) {
      // Minheight below is a hack to make ReactHeight understand that we need this to be big (it wont pick up on re-renders in children).
      return (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      );
    }
    return (
      <Fragment>
        <form className="o-form">
          {this.renderInputs(fields)}
        </form>
        {
          this.getModalFooter()
        }
      </Fragment>
    );
  }
}

export default DocumentDataForm;
