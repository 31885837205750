import React from 'react';
import Moment from 'moment';
import { List, Map } from 'immutable';

import { convertNumberToPrice } from '../../utils/utils';
import { printClaimInvoicesSummary } from './../../utils/print';
import { getAmountPaid, getAmountOutstanding } from './../../utils/claims';
import translate from './../../utils/i18n';
import DateRangePicker from './../inputs/dateRangePicker';
import Select from './../inputs/select';
import Input from './../inputs/input';
import { debugPrint } from './../../utils/logging';
import { downloadCSV, convertToCSV } from './../../utils/export';
import Button from './../buttons/button';

import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import type ClaimInvoicePaymentModel from './../../models/claimInvoicePaymentModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, MapValue, SelectOption } from './../../types';

type Props = {
  claimInvoices: List<ClaimInvoiceModel>,
  coveragePayors: List<CoveragePayorModel>,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  uniqueMonths: Array<SelectOption>,
  config: Config,
};

/**
 * A component that displays all drug dispensation for a clinic.
 * @class ConsultReports
 * @extends {Component}
 */
class ClaimsInvoicesSidebar extends React.PureComponent<Props> {
  /**
   * Updates the filter with the given value.
   * @param {string} key The key for the new value.
   * @param {MapValue} value The new value.
   * @returns {void}
   */
  updateFilter(key: string, value: MapValue) {
    this.props.onFilterUpdated(this.props.filter.set(key, value));
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    debugPrint('Rendering ClaimsInvoicesSidebar');
    const month = this.props.filter.get('month');
    const invoiceMonthValue = month ? `${month.get('month')}_${month.get('year')}` : undefined;
    const filterDateStart = this.props.filter.get('filterDateStart');
    const filterDateEnd = this.props.filter.get('filterDateEnd');
    const EXPORT_FIELDS = [
      'Date Generated',
      'Invoice No.',
      'Invoice Month',
      'Panel Name',
      `Amount (${this.props.config && this.props.config.get('currency_shorthand_label', 'RM')})`,
      `Paid (${this.props.config && this.props.config.get('currency_shorthand_label', 'RM')})`,
      `Outstanding (${this.props.config && this.props.config.get('currency_shorthand_label', 'RM')})`,
    ];
    return (
      <div className="o-sidebar--right c-billing__overview">
        <h1 data-public className="c-billing__overview__title">{translate('filter')}</h1>
        <Select
          className="u-margin-left--1ws u-margin-right--1ws"
          label={translate('panel_name')}
          labelClassName="u-font-white"
          id="filter-coverage-payor"
          onValueChanged={value => this.updateFilter('coveragePayorID', value)}
          options={
            this.props.coveragePayors
              .map(c => ({ label: c.get('name'), value: c.get('_id') }))
              .toArray()
          }
          value={this.props.filter.get('coveragePayorID')}
        />
        <Input
          label={translate('invoice_number')}
          labelClassName="u-font-white"
          divClassName="u-padding-sides--1ws"
          id="filter-invoice-number"
          onValueChanged={value => this.updateFilter('invoiceNumber', value)}
          value={this.props.filter.get('invoiceNumber')}
        />
        <Select
          className="u-margin-left--1ws u-margin-right--1ws"
          label={translate('invoice_month')}
          labelClassName="u-font-white"
          id="filter-invoice-month"
          options={this.props.uniqueMonths}
          value={invoiceMonthValue}
          onValueChanged={(value) => {
            if (value) {
              const values = value.split('_');
              this.updateFilter(
                'month',
                Map({ month: parseInt(values[0], 10), year: parseInt(values[1], 10) }),
              );
            } else {
              this.updateFilter('month', undefined);
            }
          }}
        />
        <DateRangePicker
          className="u-margin-left--1ws u-margin-right--1ws"
          label={translate('date_generated')}
          labelClassName="u-font-white"
          id="filter-dates"
          startDate={this.props.filter.get('filterDateStart')}
          endDate={this.props.filter.get('filterDateEnd')}
          maxDate={Moment(new Date())}
          onValueChanged={({ startDate, endDate }) => {
            const filter = this.props.filter
              .set('filterDateStart', startDate)
              .set('filterDateEnd', endDate);
            this.props.onFilterUpdated(filter);
          }}
        />
        <Button
          className="o-button o-button--small u-margin--standard u-margin-bottom--zero"
          disabled={this.props.claimInvoices && !this.props.claimInvoices.size}
          onClick={() =>
            printClaimInvoicesSummary(
              this.props.claimInvoices,
              this.props.coveragePayors,
              this.props.config,
              filterDateStart,
              filterDateEnd,
              invoiceMonthValue,
            )
          }
          dataPublic
        >{translate('print_summary')}
        </Button>
        <Button
          className="o-button o-button--small u-margin--standard u-margin-bottom--zero"
          disabled={this.props.claimInvoices && !this.props.claimInvoices.size}
          onClick={() => {
            const formattedData = this.props.claimInvoices.map(invoice => [
              invoice.getDateGenerated(),
              invoice.get('internal_clinic_id'),
              invoice.getInvoiceMonth(),
              invoice.get('coverage_payor_name'),
              invoice.getAmountClaimed(),
              convertNumberToPrice(getAmountPaid(invoice, this.props.claimInvoicePayments)),
              convertNumberToPrice(getAmountOutstanding(invoice, this.props.claimInvoicePayments)),
            ]).toArray();
            downloadCSV('ClaimInvoicesSummary.csv', convertToCSV(EXPORT_FIELDS, formattedData));
          }}
          dataPublic
        >{translate('export_as_csv')}
        </Button>
      </div>
    );
  }
}

export default ClaimsInvoicesSidebar;
