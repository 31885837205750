import React from 'react';
import { fabric } from 'fabric';
import { List } from 'immutable';

import { logMessage, debugPrint } from './../../utils/logging';
import { fetchAsset } from './../../utils/utils';

import type { CanvasPenSettings } from './../../types';

const MAX_WIDTH_RATIO = 0.9;

type FabricPath = {}; // A Fabric path object

type Props = {
  assetID: string,
  penSettings: CanvasPenSettings,
  onCanvasChanged: (canUndo: boolean, canRedo: boolean) => void,
};

type State = {
  undoQueue: List<FabricPath>,
  redoQueue: List<FabricPath>,
  height: number,
  width: number,
  originalHeight?: number,
  originalWidth?: number,
  resizeRatio?: number,
};

/**
 * A component that loads a given asset for editing.
 * @class CasenoteEditorCanvas
 * @extends {React.Component<Props, State>}
 */
class CasenoteEditorCanvas extends React.Component<Props, State> {
  canvasInstance: fabric.Canvas | void;

  mounted: boolean; // Useful for handling ongoing canvas loading that we can't cancel while unmounting.

  /**
   * Creates an instance of CasenoteEditorCanvas.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      undoQueue: List(),
      redoQueue: List(),
    };
    this.handleNewAsset(this.props.assetID);
  }

  /**
   * Handles a new asset being given to the canvas.
   * @param {string} assetID The asset ID.
   * @returns {void}
   */
  handleNewAsset(assetID: string) {
    return fetchAsset(assetID, 'image/png') // This datatype is not actually correct, but we know its an image at this point
      .then(response => (response ? response.blob() : undefined)) // The undefined will be returned from fetchAsset in the case of an auth failure which should lead to a redirect, so just handle it by not doing anything here.
      .then((blob) => {
        if (this.mounted && blob) {
          fabric.Image.fromURL(URL.createObjectURL(blob), (img: Image) => {
            const canvas = this.canvasInstance || new fabric.Canvas('canvas', { isDrawingMode: true, imageSmoothingEnabled: false });
            const resizeRatio = this.getResizeRatio(img.width);
            canvas.setDimensions({
              width: img.width * resizeRatio,
              height: img.height * resizeRatio,
            });
            canvas.setZoom(resizeRatio);
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
              width: img.width,
              height: img.height,
            });
            if (!this.canvasInstance) {
              this.onCanvasInit(canvas);
            }
            this.setState({
              originalHeight: img.height,
              originalWidth: img.width,
              height: img.height * resizeRatio,
              width: img.width * resizeRatio,
              resizeRatio,
            });
          });
        }
      });
  }

  /**
   * Gets the resize ratio of the canvas.
   * @param {number} width The width of the image.
   * @returns {number} The resize ratio.
   */
  getResizeRatio(width: number) {
    const maxWidth = window.innerWidth * MAX_WIDTH_RATIO;
    return width > maxWidth ? maxWidth / width : 1;
  }

  /**
   * Called when Fabric intialises. save the fabric instance to this and add event listeners.
   * @param {fabric} canvasInstance Fabric instance.
   * @returns {undefined}
   */
  onCanvasInit(canvasInstance: fabric.Canvas) {
    this.canvasInstance = canvasInstance;
    const { freeDrawingBrush } = this.canvasInstance;
    // Apply color and size based on new Pen settings
    freeDrawingBrush.color = this.props.penSettings.color;
    freeDrawingBrush.width = this.props.penSettings.size;
    // Whenever the drawing changes, let the parent component know. At the same time let it know
    // if undo and redo actions are available.
    canvasInstance.on('path:created', ({ path }) =>
      this.setState({ undoQueue: this.state.undoQueue.push(path), redoQueue: List() }, () =>
        this.props.onCanvasChanged(this.state.undoQueue.size > 0, this.state.redoQueue.size > 0)));
  }

  /**
   * Resets the canvas by removing all fabric objects.
   * @param {fabric.Canvas} canvas Fabric Canvas instance.
   * @returns {void}
   */
  resetCanvas(canvas: fabric.Canvas) {
    if (canvas) {
      canvas._objects.forEach(o => canvas.remove(o)); // eslint-disable-line no-underscore-dangle
    }
  }

  /**
   * Update only if dimensions changed.
   * @param {Props} nextProps Next Props
   * @param {State} nextState Next State.
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    // i.e. dimensions changed.
    return this.state.height !== nextState.height || this.state.width !== nextState.width;
  }

  /**
   * Handles state changes according to the new props given.
   * @param {Props} nextProps Next props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.penSettings.color !== nextProps.penSettings.color) {
      this.setPenColor(nextProps.penSettings.color);
    }
    if (this.props.penSettings.size !== nextProps.penSettings.size) {
      this.setPenSize(nextProps.penSettings.size);
    }
    if (nextProps.assetID !== this.props.assetID && nextProps.assetID) {
      this.setState({
        undoQueue: List(),
        redoQueue: List(),
      }, () => {
        this.resetCanvas(this.canvasInstance);
        this.handleNewAsset(nextProps.assetID);
        this.props.onCanvasChanged(this.state.undoQueue.size > 0, this.state.redoQueue.size > 0);
      });
    }
  }

  /**
   * Sets the pen color
   * @param {string} color Color string.
   * @returns {void}
   */
  setPenColor(color: string) {
    const canvas = this.canvasInstance; // This is redundant but makes flow happy.
    if (canvas) {
      canvas.freeDrawingBrush.color = color;
    }
  }

  /**
   * Sets the pen size
   * @param {string} size A size (number but given as string).
   * @returns {void}
   */
  setPenSize(size: string) {
    const canvas = this.canvasInstance; // This is redundant but makes flow happy.
    if (canvas) {
      canvas.freeDrawingBrush.width = parseInt(size, 10) || 1;
    }
  }

  /**
   * Calls undo on the LiterallyCanvas object if it can be found.
   * @returns {undefined}
   */
  undo() {
    const canvas = this.canvasInstance; // This is redundant but makes flow happy.
    if (canvas) {
      const lastPath = this.state.undoQueue.last();
      canvas.remove(lastPath);
      this.setState({
        redoQueue: this.state.redoQueue.push(lastPath),
        undoQueue: this.state.undoQueue.pop(),
      }, () =>
        this.props.onCanvasChanged(this.state.undoQueue.size > 0, this.state.redoQueue.size > 0));
    } else {
      logMessage('Undo called when no canvas was initialised.', 'error');
    }
  }

  /**
   * Calls redo on the LiterallyCanvas object if it can be found.
   * @returns {undefined}
   */
  redo() {
    const canvas = this.canvasInstance; // This is redundant but makes flow happy.
    if (canvas) {
      const lastPath = this.state.redoQueue.last();
      canvas.add(lastPath);
      this.setState({
        redoQueue: this.state.redoQueue.pop(),
        // @ts-ignore
        undoQueue: this.state.undoQueue.push(lastPath),
      }, () =>
        this.props.onCanvasChanged(this.state.undoQueue.size > 0, this.state.redoQueue.size > 0));
    } else {
      logMessage('Redo called when no canvas was initialised.', 'error');
    }
  }

  /**
   * If Fabric is initialised, returns a clone of the current canvas resized to match
   * the original template or casenote image size.
   * @returns {DataURL} Returns the canvas as a data URL  .
   */
  getImage() {
    const canvas = this.canvasInstance; // This is redundant but makes flow happy.
    if (canvas) {
      canvas.setZoom(1); // Unset zoom so we get correct sized canvas.
      const dataURL = canvas.toDataURL({
        width: this.state.originalWidth,
        height: this.state.originalHeight,
        enableRetinaScaling: true,
      });
      canvas.setZoom(this.state.resizeRatio); // Reset zoom.
      return dataURL;
    }
    throw new Error('Canvas not found.');
  }

  /**
   * Called when component mounts. We just set a mounted flag to help with tracking canvas loading.
   * @returns {void}
   */
  componentWillMount() {
    this.mounted = true;
  }

  /**
   * Unmount handler.
   * @returns {void}
   */
  componentWillUnmount() {
    this.mounted = false;
    const canvas = this.canvasInstance; // This is redundant but makes flow happy.
    if (canvas) {
      debugPrint('Cleaning up canvas.');
      canvas.__eventListeners = {}; // This is maybe needed, maybe not
      canvas.clear();
      canvas.dispose();
      debugPrint('Canvas cleaned up.');
    }
    this.canvasInstance = undefined;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div
        className="c-casenote-editor__casenote js-casenote-editor__canvas"
        style={{
          height: undefined,
          width: `${this.state.width}px`,
          backgroundColor: 'white',
          margin: '0 auto 100px',
        }}
      >
        <canvas id="canvas" />
      </div>
    );
  }
}

export default CasenoteEditorCanvas;
