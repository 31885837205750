import * as React from 'react';
import { Portal } from 'react-portal';

import ModalContent from './modalContent';
import LoadingIndicator from './../loadingIndicator';
import Button from './../buttons/button';

import type { HTMLStyle } from './../../types';

type Props = {
  id: string, // Needed to generate the keys for child elements. Try to make it as unique as possible.
  visible: boolean,
  setVisible: (isVisible: boolean) => void,
  buttonClass: string,
  buttonLabel: string | React.Node,
  buttonStyle: HTMLStyle,
  className?: string,
  buttonIcon?: string,
  disableButton: boolean,
  children?: React.Node,
  noButton: boolean, // If true, no activator is displayed.
  onClose?: () => void,
  title: string,
  style?: HTMLStyle,
  large?: boolean,
  showBusyIndicator?: boolean,
  explicitCloseOnly: boolean, // If true the modal can only be closed by clicking on a button (ie. not esc or clicking on mask).
  noCloseButton: boolean,
  dataPublicHeader?: boolean,
  unsetWidth?: boolean
};

/**
 * Use this modal if you want to be in total control of when to show and hide it. Use the visible
 * prop to set the visibility of the modal and the setVisible prop to pass a function used to change
 * the visibility.
 *
 * A good rule of thumb for when to use this type of modal is if the content of the modal will lead
 * to the modal being closed (e.g. saving a form).
 * @class StatelessModal
 * @extends {React.PureComponent<Props>}
 */
class StatelessModal extends React.PureComponent<Props> {
  static defaultProps = {
    noButton: false,
    noCloseButton: false,
    disableButton: false,
    explicitCloseOnly: false,
    buttonLabel: '', // Technically these are required, only exception is when noButton is true.
    buttonClass: '', // Technically these are required, only exception is when noButton is true.
    buttonStyle: {},
    buttonIcon: '',
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <React.Fragment>
        {
          !this.props.noButton &&
          <Button
            key={`button-${this.props.id}`}
            className={this.props.buttonClass}
            onClick={() => this.props.setVisible(true)}
            disabled={this.props.disableButton}
            style={{
              ...this.props.buttonStyle,
              ...this.props.showBusyIndicator && {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            dataPublic
          >
            {this.props.buttonIcon && <i className={this.props.buttonIcon} />}
            {this.props.buttonLabel}
            { this.props.showBusyIndicator ? <LoadingIndicator style={{ display: 'inline-block', marginLeft: 8 }} size={32} /> : null }
          </Button>
        }
        {
          this.props.visible &&
          <Portal style={this.props.style} key={`portal-${this.props.id}`}>
            <ModalContent
              {...this.props}
              closePortal={() => {
                this.props.setVisible(false);
                if (this.props.onClose) {
                  this.props.onClose();
                }
              }}
            >
              {this.props.children || null}
            </ModalContent>
          </Portal>
        }
      </React.Fragment>
    );
  }
}

export default StatelessModal;
