import React from 'react';
import { SketchPicker } from 'react-color';
import { Div } from 'glamorous';

import { casenoteMenuHeight, colours } from './../../utils/css';

type Props = {
  onChange: (penSettings: { color: string, size: string }) => void,
  penSettings: { color: string, size: string },
};

type State = { displayColorPicker: boolean };

const containerCSS = {
  width: casenoteMenuHeight,
  display: 'flex',
  justifyContent: 'center',
  padding: '0 10px',
};

const buttonCSS = {
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  border: `1px solid ${colours.white}`,
  borderRadius: '50%',
};

const coverCSS = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: '#00000075',
  zIndex: 4,
  cursor: 'pointer',
};

const pickerCSS = {
  position: 'fixed !important',
  top: '130px !important',
  zIndex: 5,
};

/**
 * A component for selecting a color.
 * @class CanvasColourPicker
 * @extends {React.Component<Props, State>}
 */
class CanvasColourPicker extends React.Component<Props, State> {
  /**
   * Creates an instance of CanvasColourPicker.
   * @param {Props} props The props for the component
   */
  constructor(props: Props) {
    super(props);
    this.state = { displayColorPicker: false };
  }

  /**
   * Updates the color state.
   * @param {{ hex: string }} color Color object.
   * @returns {void}
   */
  onPenColorChanged(color: { hex: string }) {
    const newSettings = Object.assign({}, this.props.penSettings, { color: color.hex });
    this.props.onChange(newSettings);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Div css={containerCSS}>
        <Div
          css={Object.assign({}, buttonCSS, { backgroundColor: this.props.penSettings.color })}
          onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker })}
        />
        {
          this.state.displayColorPicker &&
          <div>
            <Div css={coverCSS} onClick={() => this.setState({ displayColorPicker: false })} />
            <Div css={pickerCSS}>
              <SketchPicker
                color={this.props.penSettings.color}
                onChangeComplete={color => this.onPenColorChanged(color)}
              />
            </Div>
          </div>
        }
      </Div>
    );
  }
}

export default CanvasColourPicker;
