import React from 'react';
import Loading from 'react-loading';

type Props = {
  alignCenter?: boolean,
  size: number,
  style: {[string]: string | number},
  type: 'bars' | 'spin',
};

/**
 * Creates a loading indicator component.
 * @class LoadingIndicator
 * @extends {React.Component<Props, State>}
 */
class LoadingIndicator extends React.PureComponent<Props> {
  static defaultProps = {
    alignCenter: false,
    type: 'bars',
    size: 64,
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      style, alignCenter, size, type,
    } = this.props;
    return (
      <div
        style={
          alignCenter ? Object.assign({}, style, { display: 'flex', justifyContent: 'center' }) : style
        }
      >
        <Loading type={type} color="#444eee" height={size} width={size} />
      </div>
    );
  }
}

export default LoadingIndicator;
