import { List } from 'immutable';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import AddEditPrescriptions from '../components/prescriptions/addEditPrescriptions';
import AsyncFetch from './../components/asyncFetch';
import { saveFactory, saveModelsFactory } from '../utils/redux';
import type { Dispatch, State, Config, DataView } from '../types';
import type BaseModel from '../models/baseModel';
import { getPatientDataViews } from './../dataViews';
import { setIsFetching, setCurrentDataViewsError, setCurrentDataViews, updateModels, updateConfigValue, updateConfig } from './../actions';
import type APIError from '../utils/apiError';


interface OwnProps extends RouteComponentProps {
    isFromDocValidationModal: boolean,
    isDocValidationModalSaving: boolean,
    validationReferrerModel: BaseModel,
    validationDocObject?: { id: string, type: string }
    onSaveAtDocValidationModal: (wasSuccessful: boolean) => void,
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const {
    user,
    config,
    drugs,
    coveragePayors,
    isOnline,
    inventoryCount,
    prescriptions,
    encounters,
    patientStubs,
    currentDataViews,
    currentDataViewsError,
    dosingRegimens,
    patientPrescriptionHistory,
    practitioners,
  } = state;
  const prescriptionModel = prescriptions
    .find(prescription => prescription.get('_id') === ownProps?.validationReferrerModel?.get('_id'));
  const encounterId = prescriptionModel && prescriptionModel.get('encounter_id');
  const patientId = prescriptionModel && prescriptionModel.get('patient_id');
  const encounter = encounters.find(model => model.get('_id') === encounterId);
  const patient = patientStubs.find(p => p.get('_id') === patientId);
  const coveragePayorID = patient && patient.getCoveragePayor();
  const useTextButton = true;
  const hidePrescriptionsModalButton = true;
  const isPrescriptionsModalVisible = true;
  return {
    user,
    config,
    drugs,
    coveragePayors,
    containerDataViews: getPatientDataViews(patientId),
    currentDataViews,
    currentDataViewsError,
    coveragePayorID,
    encounter,
    prescriptions: prescriptions
      .filter(prescription => prescription.get('encounter_id') === encounterId),
    isOnline,
    inventoryCount,
    useTextButton,
    isPrescriptionsModalVisible,
    hidePrescriptionsModalButton,
    dosingRegimens: dosingRegimens.filter(m => m.isVisible()),
    patientPrescriptionHistory,
    practitioners,
    encounters: encounters.filter(e => e.get('patient_id') === patientId),
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: models => dispatch(updateModels(models)),

});

const AddEditPrescriptionsContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(AsyncFetch(AddEditPrescriptions));

export default AddEditPrescriptionsContainer;
