import { List, fromJS } from 'immutable';

import BaseModel from './baseModel';
import translate from './../utils/i18n';
import { prettifyDate } from './../utils/time';
import { convertNumberToPrice } from './../utils/utils';
import { UNICODE } from './../constants';
import { fetchModels } from './../utils/db';
// DO NOT PASS ANY OTHER DB DOCUMENTS TO THIS MODEL FOR COMPUTATION.
// ALL REQUIRED DATA SHOULD BE COPIED INTO THIS MODEL.
// IT SHOULD BE TOTALLY INDEPENDENT AND SERVE AS A SNAPSHOT OF THE SYSTEM AT THE POINT OF INVOICE CREATION.

import type { Month } from './../types';
import type ClaimModel from './claimModel';
import type BillModel from './billModel';

export type TreatmentCategories = Array<{ name: string, amount: number}>;
export type TreatmentCategoryList = Array<string>;

// This is data taken from a ClaimModel and stored in the ClaimInvoice so that changes to the original
// claim do not alter the ClaimInvoice. No fields are optional, however they are typed as such as it
// is possible for certain documents to not be found.
export type ClaimData = {
  _id: string, // Claim Id
  bill_id: string, // Needed to check on when a bill was edited after the claim invoice was finalised
  bill_last_edit_timestamp: number, // Needed to check on when a bill was edited after the claim invoice was finalised
  encounter_id?: string,
  patient_id: string,
  timestamp: number, // Claim timestamp
  patient_name?: string,
  patient_ic?: string,
  encounter_type?: string,
  encounter_flow?: string,
  amount: number,
  bill_notes?: string,
  bill_total?: number,
  mc_days?: number,
  diagnoses?: string,
  treatment_categories?: TreatmentCategories, // treatment categories tagged to this claim item. order not important
};

type PrintData = {
  _id: string, // Claim Id
  encounter_id?: string,
  patient_id: string,
  timestamp: number, // Claim timestamp
  patient_name?: string,
  patient_ic: string,
  encounter_type?: string,
  encounter_flow?: string,
  amount: number,
  bill_notes: string,
  bill_total?: number,
  mc_days: string,
  diagnoses: string,
  date: string,
  payment: string,
  treatment_details_indexes?: string,
  treatment_details_amounts?: Array<string>,
};

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'claim_invoice',
  items: {
    claims: Array<ClaimData>,
    month: Month, // Jan = 1
  },
  timestamp: number,
  to: string,
  from: string,
  asset_id: string,
  internal_clinic_id: string,
  is_finalised: boolean,
  is_void: boolean,
  amount: number,
  coverage_payor_id: string,
  coverage_payor_name: string,
  coverage_payor_address: string,
  notes: string,
  parent_invoice?: string,
  treatment_category_list?: TreatmentCategoryList,
  // list of treatment categories that need to appear in the invoice.
  // order is important, it is used for the index
};

/**
   * ClaimInvoiceModel
   * @namespace ClaimInvoiceModel
   */
class ClaimInvoiceModel extends BaseModel {
  attributes: Attributes;

  hasObsoleteBillData: boolean;

  // This is just a convenience flag to stop the check being made multiple times if the user is on the claims invoices pages.
  newTotal: number;

  timeOfLastObsoleteBillDataCheck: number;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'claim_invoice';
    this.hasObsoleteBillData = false;
    this.timeOfLastObsoleteBillDataCheck = -1; // i.e. Not checked yet.
  }

  /**
   * Returns a List of the Claim Ids for this ClaimInvoice.
   * @returns {List<string>}
   */
  getClaimIds(): List<string> {
    return this.get('items', {}).claims
      ? List(this.get('items', {}).claims.map(( c : ClaimData ) => c._id))
      : List();
  }

  /**
   * Returns a user-friendly representation of the invoice month for this claim invoice.
   * @returns {string}
   */
  getInvoiceMonth(): string {
    const { month, year } = this.get('items', { month: {} }).month || {};
    const paddedMonth = month < 10 ? `0${month}` : month;
    return month ? `${paddedMonth}/${year}` : UNICODE.EMDASH;
  }

  /**
   * Returns a user-friendly representation of the generation date for this claim invoice.
   * @returns {string}
   */
  getDateGenerated(): string {
    return prettifyDate(this.get('timestamp'));
  }

  /**
   * Returns a user-friendly representation of the claimed amount for this claim invoice.
   * @returns {string}
   */
  getAmountClaimed(): string {
    return convertNumberToPrice(this.get('amount', 0));
  }

  /**
   * Returns a user-friendly representation of the new total.
   * Does not calculate the total. That has to be done separately.
   * @returns {string}
   */
  getNewtotal(): string {
    return convertNumberToPrice(this.newTotal);
  }

  /**
   * Returns true if the month object given matches the month this invoice was made for.
   * @param {{ month: number, year: number }} monthToMatch The month object
   * @returns {boolean}
   */
  matchesMonth(monthToMatch: { month: number, year: number }): boolean {
    const { month, year } = this.get('items', {}).month || {};
    return month === monthToMatch.month && year === monthToMatch.year;
  }

  /**
   * Returns a user-friendly representation of the current status of the ClaimInvoice (currently
   * assumed to be pending unless is_void is true).
   * @returns {string}
   */
  getStatus(): string {
    return this.get('is_void') ? translate('void') : translate('pending');
  }

  /**
   * Recalculates invoice total by adding up all the claims
   * @param {List<ClaimModel>} claimModels The claim models for this invoice.
   * @returns {number}
   */
  calculateNewTotal(claimModels: List<ClaimModel>): number {
    this.newTotal = claimModels.reduce((sum, c) => sum + c.get('amount_due'), 0);
    return this.newTotal;
  }


  /**
   * Returns true if the hasObsoleteBillData flag is older than 10 mins and is invalidated
   * @returns {boolean}
   */
  shouldResolveObsoleteBillData(): boolean {
    return this.timeOfLastObsoleteBillDataCheck < new Date().getTime() - 600000; // I.e. In the last ten minutes.
  }

  /**
   * Sets the hasObsoleteBillData flag, and time of obsolete check and calculates new total
   * the invoice was finalised.
   * @param {boolean} hasObsoleteBillData The bill contains obsolete bill data.
   * @param {List<ClaimModel>} claimModels The claim models for this invoice.
   * @returns {boolean} returns hasObsoleteBillData.
   */
  resolveBillData(hasObsoleteBillData: boolean, claimModels?: List<ClaimModel>) {
    this.timeOfLastObsoleteBillDataCheck = new Date().getTime();
    this.hasObsoleteBillData = hasObsoleteBillData;
    if (hasObsoleteBillData && claimModels) {
      this.calculateNewTotal(claimModels);
    }
    return this.hasObsoleteBillData;
  }

  /**
   * Returns true if this claim invoice contains claims that have had their bills changed since
   * the invoice was finalised.
   * @param {List<BillModel>} bills The billModels for this invoice claim items
   * @returns {boolean}
   */
  containsObsoleteBillData(bills: List<BillModel>): boolean {
    if (!this.shouldResolveObsoleteBillData()) {
      return this.hasObsoleteBillData;
    }
    return bills.some((model, index) => {
      const billLastEditTimestamp = ((this.get('items', { claims: [] }).claims || [])[index]).bill_last_edit_timestamp;
      return model.getLastUpdateTime() !== billLastEditTimestamp;
    });
  }

  /**
   * Resolves bill data and returns true if this claim invoice contains claims that have had their bills changed since
   * the invoice was finalised.
   * @returns {Promise<boolean>}
   */
  resolveObsoleteBillData(): Promise<boolean> {
    if (!this.shouldResolveObsoleteBillData()) {
      return Promise.resolve(this.hasObsoleteBillData);
    }
    const billIDTimestampTuples = this.get('items', { claims: [] }).claims.map(( c: ClaimData ) => [
      c.bill_id,
      c.bill_last_edit_timestamp,
    ]);
    if (billIDTimestampTuples.some(( t : [string, number]) => t[0] === undefined || t[1] === undefined)) {
      return fetchModels(this.get('items', { claims: [] }).claims.filter(( c: ClaimData ) => c._id).map(( c: ClaimData ) => c._id))
        .then(claimModels => this.resolveBillData(true, claimModels))
        .then(() => true);
    }
    return fetchModels(billIDTimestampTuples.map(( t : [string, number]) => t[0]))
      .then(
        models => models.map(
          (model, index) => model.getLastUpdateTime() === billIDTimestampTuples[index][1],
        ),
      )
      .then((results: List<boolean>) => {
        const hasObsoleteBillData = results.some(r => r === false);
        return hasObsoleteBillData
          ? fetchModels(this.get('items', { claims: [] }).claims.filter(( c: ClaimData ) => c._id).map(( c: ClaimData ) => c._id))
            .then(claimModels => this.resolveBillData(true, claimModels))
          : Promise.resolve(this.resolveBillData(false));
      });
  }

  /**
   * Returns an array of objects for each claim assigned to this invoice that can be used for
   * printing.
   * @returns {Array<PrintData>}
   */
  getClaimsDataForPrint(): Array<PrintData> {
    const treatmentCategoryList: List<string> = fromJS(this.get('treatment_category_list', []));
    return this.get('items', { claims: [] }).claims
      .map(( c : ClaimData ) => {
        const treatmentCategories: List<Map<string, string | number>> = fromJS(c.treatment_categories || []);
        return Object.assign({}, c, {
          date: prettifyDate(c.timestamp),
          payment: convertNumberToPrice(c.amount),
          patient_ic: c.patient_ic && c.patient_ic.length ? c.patient_ic : UNICODE.EMDASH,
          bill_notes: c.bill_notes && c.bill_notes.length ? c.bill_notes : UNICODE.EMDASH,
          mc_days: c.mc_days !== undefined ? `${c.mc_days}` : UNICODE.EMDASH,
          diagnoses: c.diagnoses && c.diagnoses.length ? c.diagnoses : UNICODE.EMDASH,
          treatment_details_indexes: treatmentCategories
            .map(tc => treatmentCategoryList
              .findIndex(tcListItem => tcListItem === tc.get('name')) + 1)
            .filter(num => num > 0)
            .sort()
            .join(', '),
          treatment_details_amounts: treatmentCategoryList
            .map(tcListItem => treatmentCategories.find(tc => tc.get('name') === tcListItem))
            .map(tc => `${tc === undefined ? UNICODE.EMDASH : tc.get('amount')}`)
            .toArray(),
        });
      });
  }
}

export default ClaimInvoiceModel;
