import { connect } from 'react-redux';
import { List } from 'immutable';
import { RouteComponentProps } from 'react-router-dom';

import BillRegenerationForm from './../components/billing/billRegenerationForm';
import AsyncFetch from './../components/asyncFetch';
import { saveFactory, saveModelsAPIFactory, saveModelsFactory } from '../utils/redux';
import type { Dispatch, State, Config, DataView, Model } from '../types';
import { getBillGenerationFormDataview } from './../dataViews';
import { setIsFetching, setCurrentDataViewsError, setCurrentDataViews, updateModels, updateConfigValue, updateConfig, updatePatientStub } from './../actions';
import type APIError from '../utils/apiError';
import type PatientStubModel from '../models/patientStubModel';
import type PatientModel from '../models/patientModel';
import type BillModel from '../models/billModel';
import type EncounterModel from '../models/encounterModel';
import ReceivableModel from '../models/receivableModel';
import BillItemModel from '../models/billItemModel';
import PaymentModel from '../models/paymentModel';
import { saveEncounterRelatedModels } from '../utils/api';


interface OwnProps extends RouteComponentProps {
  patient?: PatientModel,
  encounter?: EncounterModel,
  isFromDocValidationModal?: boolean,
  docId?: string,
  isDocValidationModalSaving?: boolean,
  noSave?: boolean,
  validationReferrerModel?: ReceivableModel | BillItemModel | PaymentModel | EncounterModel,
  onSaveAtDocValidationModal?: (wasSuccessful: boolean, models?: List<BillModel>) => void,
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const {
    user,
    config,
    coveragePayors,
    patients,
    encounters,
    practitioners,
    salesItems,
    billItems,
    currentDataViews,
    currentDataViewsError,
  } = state;
  const { validationReferrerModel } = ownProps;
  const patient = ownProps.patient || patients.find(i => i.get('_id') === validationReferrerModel?.get('patient_id'));
  const encounter = ownProps.encounter || encounters.find(i => i.get('bill_id') === ownProps.docId);
  const billItemsForBill = billItems.filter(i => i.get('bill_id') === ownProps.docId);
  return {
    containerDataViews: getBillGenerationFormDataview(ownProps.docId),
    currentDataViews,
    currentDataViewsError,
    user,
    config,
    coveragePayors,
    encounter,
    patient,
    billItemsForBill,
    practitioners,
    salesItems,
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  addPatientStub: (model: PatientStubModel) => dispatch(updatePatientStub(model)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<Model>) => dispatch(updateModels(models.toArray())),
  saveBillAndEncounterModels: (
    patient: PatientModel,
    encounter: EncounterModel,
    bill: BillModel,
  ) => saveModelsAPIFactory(dispatch)(() =>
    saveEncounterRelatedModels(patient, encounter, bill, undefined, dispatch)),
});

const BillRegenerationContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(AsyncFetch(BillRegenerationForm));

export default BillRegenerationContainer;
