import { List, Map } from 'immutable';
import Moment from 'moment';

import { fetchModels } from './db';
import { sum, listToMap } from './utils';
import { getStartOfMonth, getEndOfMonth } from './time';
import {
  fetchClaimsForCoveragePayorWithBillsBillItemsAndEncounters,
  getMCDaysForEncounter, getConditionsForEncounter,
} from './api';
import ClaimInvoiceModel, { ClaimData } from './../models/claimInvoiceModel';

import type { Model, SaveModels, Month, ClaimInvoiceFormAttributes, ClaimInvoiceMetadata, Config, APIResponse } from './../types';
import type ClaimModel from './../models/claimModel';
import type CoveragePayorModel from './../models/coveragePayorModel';
import type SalesItemModel from './../models/salesItemModel';
import BillItemModel, { BillableItem } from './../models/billItemModel';
import type DrugModel from './../models/drugModel';
import type ClaimInvoicePaymentModel from '../models/claimInvoicePaymentModel';
import type { TreatmentCategories } from './../models/claimInvoiceModel';
import type BillModel from './../models/billModel';
import type EncounterModel from './../models/encounterModel';
import type PatientStubModel from './../models/patientStubModel';
import { logMessage } from './logging';
import type BaseModel from './../models/baseModel';
import { CategoryCoveragePayor } from './../models/salesItemModel';

/**
 * Handle the model updates needed to void a ClaimInvoice. The returned models will be the result of
 * SaveModels.
 * @param {ClaimInvoiceModel} claimInvoice The claimInvoice to be voided.
 * @param {SaveModels} saveModels The SaveModels function.
 * @param {List<ClaimInvoicePaymentModel>} claimInvoicePayments payments made to invoice
 * @returns {Promise<Array<Model>>}
 */
export function voidClaimInvoice(
  claimInvoice: ClaimInvoiceModel,
  saveModels: SaveModels,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
): Promise<Array<Model>> {
  return fetchModels(claimInvoice.getClaimIds().toArray())
    .then((claims) => {
      const updatedClaims = claims.map(c => c.set({ status: 'unclaimed' }));
      if (claimInvoicePayments) {
        claimInvoicePayments.map(p => updatedClaims.push(p.set({ is_void: true })));
      }
      return saveModels(updatedClaims.push(claimInvoice.set({ is_void: true })).toArray());
    });
}

/**
 * Voids a List of Claim invoices.
 * @param {List<ClaimInvoiceModel>} claimInvoices List of Claim Invoices
 * @param {SaveModels} saveModels Savemodels function
 * @param {List<ClaimInvoicePaymentModel>} claimInvoicePayments payments made to invoice
 * @returns {Promise<Array<Model>>}
 */
export function voidClaimInvoices(
  claimInvoices: List<ClaimInvoiceModel>,
  saveModels: SaveModels,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
): Promise<Array<Model>> {
  return Promise
    .all(claimInvoices.map(c => voidClaimInvoice(c, saveModels, claimInvoicePayments.filter(cip => cip.get('claim_invoice_id') === c.get('_id')))))
    .then(results => results.reduce((a, b) => a.concat(b), []));
}

type ClaimRelatedModel = ClaimModel | BillModel | BillItemModel | EncounterModel;
/**
 * Gets all unclaimed claims and related docs for the given month and payor.
 * Also filters claims to only those that are available to be assigned to an invoice
 * Note that the related docs might contain documents belonging to claims not in the claims set.
 * The only guarantee is the docs required for the claim docs will be found if searched for.
 * @param {string} coveragePayorId The coverage payor ID.
 * @param {Month} month A month object
 * @returns {Promise<Map<string, List<ClaimRelatedModel>>>}
 * Map with keys bill | bill_item | claim | encounter, with the values being a list of the respective docs
 */
function getUnclaimedClaimsAndRelatedDocs(coveragePayorId: string,
  // mixed list are naturally dodgy. bill / bill item / claim / encounter arent compatible with each other
  // Downstream code handles each type of doc different, but that is done based on the type field.
  // flow doesnt have visibility into that.
  month: Month): Promise<Map<string, List<ClaimRelatedModel>>> {
  const startKey = getStartOfMonth(month.month - 1, month.year);
  const endKey = getEndOfMonth(month.month - 1, month.year);
  // You cant be certain the DB will return only claim related models.
  // This depends on trusting the couch view, and cant be validate in flow.
  // So we have to ignore the error.
  return fetchClaimsForCoveragePayorWithBillsBillItemsAndEncounters(
    coveragePayorId, startKey, endKey,
  )
    .then(
      docs => (docs ? docs.groupBy(d => d.get('type')).toMap() : Map()),
    )
    // type: claim will ensure the type is ClaimModel. But this cant be validated in Flow
    .then((groupedDocs : Map<string, List<ClaimRelatedModel>>) => groupedDocs.set('claim', groupedDocs.get('claim', List()).filter(claim => claim && claim.canBeAssignedToInvoice())));
}

/**
 * Copies a ClaimInvoice minus any claims or amount data.
 * @param {ClaimInvoiceModel} oldInvoice The old claim invoice.
 * @returns {ClaimInvoiceModel}
 */
export function copyInvoice(oldInvoice: ClaimInvoiceModel): ClaimInvoiceModel {
  const { month } = oldInvoice.get('items', {});
  return new ClaimInvoiceModel({
    to: oldInvoice.get('to'),
    from: oldInvoice.get('from'),
    internal_clinic_id: oldInvoice.get('internal_clinic_id'),
    is_finalised: true,
    coverage_payor_id: oldInvoice.get('coverage_payor_id'),
    coverage_payor_name: oldInvoice.get('coverage_payor_name'),
    coverage_payor_address: oldInvoice.get('coverage_payor_address', '', true, false),
    notes: oldInvoice.get('notes'),
    parent_invoice: oldInvoice.get('parent_invoice'),
    timestamp: oldInvoice.get('timestamp'),
    items: {
      month: {
        month: month.month,
        year: month.year,
      },
      start_date: Moment([month.year, month.month - 1]).valueOf(),
      end_date: Moment([month.year, month.month - 1]).endOf('month').valueOf(),
    },
  });
}

/**
 * Copies a ClaimInvoice minus any claims or amount data.
 * @param {ClaimInvoiceModel} oldInvoice The old claim invoice.
 * @returns {ClaimInvoiceMetadata}
 */
export function copyInvoiceMetadata(oldInvoice: ClaimInvoiceModel) {
  const { month } = oldInvoice.get('items', {});
  return {
    ids: [oldInvoice.get('_id')],
    to: oldInvoice.get('to'),
    from: oldInvoice.get('from'),
    internal_clinic_id: oldInvoice.get('internal_clinic_id'),
    coverage_payor_id: oldInvoice.get('coverage_payor_id'),
    notes: oldInvoice.get('notes'),
    parent_invoice: oldInvoice.get('parent_invoice'),
    timestamp: oldInvoice.get('timestamp'),
    items: {
      month: {
        month: month.month,
        year: month.year,
      },
      start_date: Moment([month.year, month.month - 1]).valueOf(),
      end_date: Moment([month.year, month.month - 1]).endOf('month').valueOf(),
    },
  };
}

/**
 * Get the name of the coverage payor category attached to the itemDoc.
 * Returns undefined if not found
 * @param {BillableItem} itemDoc whether it'a a drug or salesItem model
 * @param {string} coveragePayorId the coverage payor
 * @return {string | void}
 */
export function getCoveragePayorCategory(itemDoc: BillableItem,
  coveragePayorId: string) {
  const cpc = (itemDoc.get('coverage_payor_category') || []).find((_cpc : CategoryCoveragePayor) => _cpc.coverage_payor_id === coveragePayorId);
  return cpc ? cpc.category : itemDoc.get('default_coverage_payor_category');
}

/**
 * Returns a TreatmentCategories with claim categories mapped to amount for that category
 * @param {List<BillItemModel>} billItems bill items from which panel categories are to be picked up
 * @param {List<SalesItemModel>} salesItems full list of sales items
 * @param {List<DrugModel>} drugs full list of drugs
 * @param {string} coveragePayorId the coverage payor id
 * @returns {TreatmentCategories}
 */
export function getTreatmentCategories(
  billItems: List<BillItemModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  coveragePayorId: string,
): TreatmentCategories {
  const combinedItemsList = drugs.concat(salesItems);
  return billItems.groupBy((bi) => {
    const billItemDoc = combinedItemsList.find(i => bi.getItemId() === i.get('_id'));
    if (!billItemDoc) {
      logMessage(`${bi.getItemId()} not found, but is part of claim for ${coveragePayorId}`, 'error');
      return undefined;
    }
    return getCoveragePayorCategory(billItemDoc, coveragePayorId);
  })
    .filter((_, name) => name !== undefined && name !== null) // remove undefined drug / category
    // the || '' is to keep flow happen. Shouldnt happen because we removed undefined.
    .map((billItemGroup, name) => ({ name: name || '', amount: sum(billItemGroup.map(bi => bi.get('total_amount')).toList()) }))
    .toList()
    .toArray();
}

/**
 * Produces a snapshot of the Claim at this point in time.
 * @param {List<ClaimModel>} claims the claims to generate snapshots for
 * @param {List<PatientStubModel>} patientsList List of PatientStubs containing the relevant patient
 * @param {List<BillModel>} billList List containing the bills referenced in the claim.
 * @param {List<EncounterModel>} encounterList List containing the encounters referenced in the claim.
 * @param {List<BillItemModel>} billItemList List containing the bill items referenced in the claim.
 * @param {List<SalesItemModel>} salesItems List containing the sales items referenced in the claim.
 * @param {List<DrugModel>} drugs List containing the drugs referenced in the claim.
 * @returns {Promise<ClaimData>}
 */
function getSnapshotsForClaims(
  claims: List<ClaimModel>,
  patientsList: List<PatientStubModel>,
  billList: List<BillModel>,
  encounterList: List<EncounterModel>,
  billItemList: List<BillItemModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
): Promise<List<ClaimData>> {
  const patientsMap = listToMap(patientsList, (patient : PatientStubModel)  => patient.get('_id'));
  const billMap = listToMap(billList, (bill : BillModel) => bill.get('_id'));
  const encounterMap = listToMap(encounterList, (encounter : EncounterModel) => encounter.get('_id'));
  const billItemMap = billItemList.reduce((all, bi) => (
    all.update(bi.get('bill_id'), (val = List()) => val.push(bi))), Map<string, List<BillItemModel>>());
  const encounters = claims
    .map((claim: ClaimModel) => {
      const bill = billMap.get(claim.get('bill_id'));
      return bill ? bill.get('encounter_id') : undefined;
    })
    .filter(e => !!e)
    .toArray();
  return Promise
    .all([getMCDaysForEncounter(encounters), getConditionsForEncounter(encounters)])
    .then(results => claims.map((claim: ClaimModel) => {
      const patient = patientsMap.get(claim.get('patient_id'));
      const bill = billMap.get(claim.get('bill_id'));
      const encounter = bill ? encounterMap.get(bill.get('encounter_id')) : undefined;
      const billItems = bill && billItemMap.count() ? billItemMap.get(bill.get('_id'), List()) : List();

      return claim.getSnapshot(
        patient,
        encounter,
        bill,
        salesItems,
        getTreatmentCategories(billItems, salesItems, drugs, claim.get('coverage_payor_id')),
        (results[0] as Map<string, number>).get(encounter && encounter.get('_id'), 0),
        encounter ? (results[1] as Map<string, List<any>>).get(encounter.get('_id'), List()) : List(),
      );
    }));
}

/**
 * Takes a claim invoice prior to saving without claims set. Update the claim invoices with
 * claims info and saves all related models.
 * @param {ClaimInvoiceModel} newInvoice The new ClaimInvoiceModel.
 * @param {SaveModels} saveModels SaveModels function.
 * @param {List<PatientStubModel>} patients PatientStubs.
 * @param {List<SalesItemModel>} salesItems SalesItemModel.
 * @param {List<DrugModel>} drugs SalesItemModel.
 * @param {Config} config config
 * @returns {Promise<ClaimInvoiceModel>}
 */
function populateClaimInvoice(
  newInvoice: ClaimInvoiceModel,
  saveModels: SaveModels,
  patients: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  config: Config,
): Promise<ClaimInvoiceModel> {
  return getUnclaimedClaimsAndRelatedDocs(newInvoice.get('coverage_payor_id'), newInvoice.get('items').month)
    .then((docs) => {
      const claims: List<ClaimModel> = (docs.get('claim') || List()).map((c: ClaimModel) => c.set('status', 'pending'));
      return getSnapshotsForClaims(claims, patients, docs.get('bill') || List(),
        docs.get('encounter') || List(), docs.get('bill_item') || List(), salesItems, drugs)
        .then((claimsSnapshots) => {
          newInvoice.set({
            amount: sum(claims.map(c => c.get('amount_due'))),
            items: { month: newInvoice.get('items').month, claims: claimsSnapshots.toArray() },
            treatment_category_list: config.getIn(['panel_categories', 'options'], List()).toArray(),
          });

          //@ts-ignore
          return saveModels(claims.push(newInvoice).toArray());
        })
        .then(() => newInvoice);
    });
}

/**
 * Generates a new ClaimInvoiceModel using the data provided.
 * @param {List<ClaimModel>} claims filtered list of claims to use to generate the invoice
 * @param {CoveragePayorModel} coveragePayor coveragePayor the invoice is for
 * @param {Month} month month of the invoice
 * @param {ClaimInvoiceFormAttributes} attributes attributes to set in the invoice
 * @returns {Promise<ClaimInvoiceMetadata>} The new ClaimInvoiceModel
 */
export function createClaimInvoiceData(
  claims: List<ClaimModel>,
  coveragePayor: CoveragePayorModel | undefined,
  month: Month,
  attributes: ClaimInvoiceFormAttributes,
): Promise<ClaimInvoiceMetadata> {
  const { from, notes, to, internal_clinic_id, timestamp } = attributes; // eslint-disable-line
  const coveragePayorId = coveragePayor?.get('_id');
  const toDetail = to.find(t => t.coveragePayorID === coveragePayorId);
  const invoiceDetail = internal_clinic_id.find(i => i.coveragePayorID === coveragePayorId);
  return Promise.resolve({
    coverage_payor_id: coveragePayorId,
    from,
    notes,
    timestamp,
    to: (toDetail && toDetail.name) || undefined,
    internal_clinic_id: (invoiceDetail && invoiceDetail.invoiceID) || undefined,
    items: {
      month,
      start_date: Moment([month.year, month.month - 1]).valueOf(),
      end_date: Moment([month.year, month.month - 1]).endOf('month').valueOf(),
    },
  });
}

/**
 * Generates a new ClaimInvoiceModel using the data provided. Used to maintain Backward Compatibility.
 * @param {List<ClaimModel>} claims filtered list of claims to use to generate the invoice
 * @param {CoveragePayorModel} coveragePayor coveragePayor the invoice is for
 * @param {Month} month month of the invoice
 * @param {ClaimInvoiceFormAttributes} attributes attributes to set in the invoice
 * @param {List<PatientStubModel>} patients patient list that should contain the patients
 * referenced in the claims
 * @param {List<SalesItemModel>} salesItems sales item list that should contain the sales
 * item referenced in the bills linked to the claims
 * @param {List<DrugModel>} drugs drug list that should contain the drugs
 * referenced in the bills linked to the claims
 * @param {List<BillItemModel>} billItemList bill item list that should contain the bill items
 * referenced in the bills linked to the claims
 * @param {List<BillModel>} billList bill list that should contain the bills
 * referenced in the claims
 * @param {List<EncounterModel>} encounterList encounter list that should contain the encounters
 * referenced in the bills linked to the claims
 * @param {Config} config config
 * @returns {Promise<ClaimInvoiceModel>} The new ClaimInvoiceModel
 */
export function createClaimInvoiceDataCompat(
  claims: List<ClaimModel>,
  coveragePayor: CoveragePayorModel | undefined,
  month: Month,
  attributes: ClaimInvoiceFormAttributes,
  patients: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  billItemList: List<BillItemModel>,
  billList: List<BillModel>,
  encounterList: List<EncounterModel>,
  config: Config,
): Promise<ClaimInvoiceModel> {
  return getSnapshotsForClaims(claims, patients, billList,
    encounterList, billItemList, salesItems, drugs)
    .then((snapshots) => {
      const { from, notes, to, internal_clinic_id, timestamp } = attributes; // eslint-disable-line
      const coveragePayorId = coveragePayor?.get('_id');
      const toDetail = to.find(t => t.coveragePayorID === coveragePayorId);
      const invoiceDetail = internal_clinic_id.find(i => i.coveragePayorID === coveragePayorId);
      return new ClaimInvoiceModel({
        coverage_payor_id: coveragePayorId,
        coverage_payor_name: coveragePayor?.get('name'),
        coverage_payor_address: coveragePayor?.get('address', '', true, false),
        is_finalised: true,
        from,
        notes,
        timestamp,
        to: (toDetail && toDetail.name) || undefined,
        internal_clinic_id: (invoiceDetail && invoiceDetail.invoiceID) || undefined,
        items: {
          month,
          claims: snapshots.toArray(),
          start_date: Moment([month.year, month.month - 1]).valueOf(),
          end_date: Moment([month.year, month.month - 1]).endOf('month').valueOf(),
        },
        amount: sum(claims.map(c => c.get('amount_due'))),
        treatment_category_list: config.getIn(['panel_categories', 'options'], List()).toArray(),
      });
    });
}

type ResponseModel = ClaimModel | ClaimInvoiceModel;
/**
 * Voids the given ClaimInvoice and generates a new one using the same coveragePayor and month,
 * adding all unclaimed claims to the new Invoice.
 * @param {ClaimInvoiceModel} claimInvoice The ClaimInvoice to regenerate.
 * @param {Function} saveClaimInvoiceModels SaveModels function.
 * @param {SaveModels} saveModels SaveModels function.
 * @param {List<PatientStubModel>} patients PatientStubs.
 * @param {List<SalesItemModel>} salesItems SalesItemModel.
 * @param {List<DrugModel>} drugs SalesItemModel.
 * @param {Config} config config
 * @param {List<ClaimInvoicePaymentModel>} claimInvoicePayments payments made to invoice
 * @returns {Promise<ClaimInvoiceModel>} The new ClaimInvoiceModel
 */
export function regenerateClaimInvoice(
  claimInvoice: ClaimInvoiceModel | undefined,
  saveClaimInvoiceModels: (models: Array<ClaimInvoiceMetadata>, regenerate : boolean) => Promise<APIResponse<ResponseModel> | Array<ResponseModel>>,
  saveModels: SaveModels,
  patients: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  config: Config,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
): Promise<ClaimInvoiceModel> {
  if (!claimInvoice?.get('items', {}).month || !claimInvoice.get('coverage_payor_id')) {
    throw new Error('ClaimInvoice to regenerate is missing required fields');
  }
  const newInvoiceData = copyInvoiceMetadata(claimInvoice);
  return saveClaimInvoiceModels([newInvoiceData], true)
    .then(response => (
      !(response as APIResponse<ResponseModel>).error ?
        Promise.resolve(response)
          .then(savedModels => (
            (savedModels as APIResponse<ResponseModel>).unavailable ?
              voidClaimInvoice(claimInvoice, saveModels, claimInvoicePayments)
                .then(() => populateClaimInvoice(
                  copyInvoice(claimInvoice),
                  saveModels,
                  patients,
                  salesItems,
                  drugs,
                  config,
                )) :
                //@ts-ignore
              savedModels.find(e => e.get('type') === 'claim_invoice'))) :
        Promise.reject((response as APIResponse<ResponseModel>).msg)
    ));
}

/**
 * Regenerates multiple claim invoices. Any invoices with matching panel and month will be
 * consolidated into one invoice.
 * @param {List<ClaimInvoiceModel>} claimInvoices The ClaimInvoices to regenerate.
 * @param {Function} saveClaimInvoiceModels SaveModels function.
 * @param {SaveModels} saveModels SaveModels function.
 * @param {List<PatientStubModel>} patients PatientStubs.
 * @param {List<SalesItemModel>} salesItems SalesItemModel.
 * @param {List<DrugModel>} drugs SalesItemModel.
 * @param {Config} config config
 * @param {List<ClaimInvoicePaymentModel>} claimInvoicePayments payments made to invoices
 * @returns {Promise<List<ClaimInvoiceModel>>}
 */
export function regenerateClaimInvoices(
  claimInvoices: List<ClaimInvoiceModel>,
  saveClaimInvoiceModels: (models: Array<ClaimInvoiceMetadata>, regenerate?: boolean) => Promise<APIResponse<ResponseModel> | ResponseModel[]>,
  saveModels: SaveModels,
  patients: List<PatientStubModel>,
  salesItems: List<SalesItemModel>,
  drugs: List<DrugModel>,
  config: Config,
  claimInvoicePayments: List<ClaimInvoicePaymentModel>,
): Promise<List<ClaimInvoiceModel>> {
  const uniqueInvoicesData = claimInvoices.reduce(
    (uniques, invoice) => {
      const { month } = invoice.get('items', {});
      const key = [invoice.get('coverage_payor_id'), month.month, month.year].toString();
      const newInvoice = uniques.get(key);
      if (newInvoice) {
        return uniques.set(key, { ...newInvoice, ids: [...newInvoice.ids, invoice.get('_id')] });
      }
      return uniques.set(key, copyInvoiceMetadata(invoice));
    },
    Map(),
  );
  return saveClaimInvoiceModels(uniqueInvoicesData.valueSeq().toArray(), true)
    .then(response => (
      !(response as APIResponse<ResponseModel>).error ?
        Promise.resolve(response)
          .then((savedModels) => {
            if ((savedModels as APIResponse<ResponseModel>).unavailable) {
              const uniqueInvoices = claimInvoices.reduce(
                (uniques, invoice) => (uniques.find(i => i.get('coverage_payor_id') === invoice.get('coverage_payor_id')
                  && i.matchesMonth(invoice.get('items', {}).month)) !== undefined
                  ? uniques
                  : uniques.push(copyInvoice(invoice))),
                List(),
              );
              return Promise
                .all(claimInvoices.map(invoice => voidClaimInvoice(invoice, saveModels, claimInvoicePayments.filter(cip => cip.get('claim_invoice_id') === invoice.get('_id')))).toArray())
                .then(() => Promise.all(
                  uniqueInvoices
                    .map(i =>
                      populateClaimInvoice(i, saveModels, patients, salesItems, drugs, config))
                    .toArray(),
                ));
            }
            return (savedModels as Array<ResponseModel>).filter(e => e.get('type') === 'claim_invoice');
          })
          .then((newInvoices : ClaimInvoiceModel[]) => List(newInvoices)) :
        Promise.reject((response as APIResponse<ResponseModel>).msg)
    ));
}

/**
   * Returns a user-friendly representation of the total paid amount for this claim invoice.
   * @param {ClaimInvoiceModel} claimInvoice The value selected
   * @param {List<ClaimInvoicePaymentModel>} payments payments made to invoice
   * @returns {number}
   */
export function getAmountPaid(claimInvoice: ClaimInvoiceModel,
  payments: List<ClaimInvoicePaymentModel>): number {
  const invoicePayments = payments.filter(payment => payment.get('claim_invoice_id') === claimInvoice.get('_id') && !payment.isVoid());
  return invoicePayments.reduce((total, p) => total + parseFloat(p.get('amount', 0)), 0);
}

/**
   * Returns a user-friendly representation of the outstanding amount for this claim invoice.
   * @param {ClaimInvoiceModel} claimInvoice The value selected
   * @param {List<ClaimInvoicePaymentModel>} payments payments made to invoice
   * @returns {number}
   */
export function getAmountOutstanding(claimInvoice: ClaimInvoiceModel,
  payments: List<ClaimInvoicePaymentModel>): number {
  const invoicePayments = payments.filter(payment => payment.get('claim_invoice_id') === claimInvoice.get('_id') && !payment.isVoid());
  return claimInvoice.get('amount', 0) - invoicePayments.reduce((total, p) => total + parseFloat(p.get('amount', 0)), 0);
}
