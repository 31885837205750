import { List } from 'immutable';

import BaseModel from './baseModel';
import translate from './../utils/i18n';

import type BillModel from './billModel';
import type CoveragePayorModel from './coveragePayorModel';
import type EncounterModel from './encounterModel';
import type PatientStubModel from './patientStubModel';
import type SalesItemModel from './salesItemModel';
import type { ClaimData, TreatmentCategories } from './claimInvoiceModel';
import type ConditionModel from './conditionModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'claim',
  patient_id: string,
  bill_id: string,
  amount: number,
  timestamp: number,
  coverage_payor_id: string,
  amount_due: number,
  status: 'unclaimed' | 'pending' | 'approved' | 'rejected' | 'partial',
  notes: string,
  is_void: boolean,
};

/**
   * ClaimModel
   * @namespace ClaimModel
   */
class ClaimModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'claim';
  }

  /**
   * Returns true if claim has been voided.
   * @returns {boolean}
   */
  isVoid(): boolean {
    return this.get('is_void', false);
  }

  /**
   * Returns true if the current attributes are valid.
   * @returns {boolean}
   */
  isValid(): boolean {
    return this.hasValidMetadata() &&
      this.has('patient_id') &&
      this.has('bill_id') &&
      this.has('amount') &&
      this.has('timestamp') &&
      this.has('coverage_payor_id') &&
      this.has('amount_due') &&
      this.has('status');
  }

  /**
   * Gets the payor name for this claim
   * @param {Immutable.List} coveragePayors A List of all CoveragePayorModels.
   * @returns {string} Coverage payor name.
   */
  getCoveragePayorName(coveragePayors: List<CoveragePayorModel>): string {
    const payor = coveragePayors.find(model => model.get('_id') === this.get('coverage_payor_id'));
    return payor ? payor.get('name') : translate('payor_not_found');
  }

  /**
   * Returns true if the claim can be assigned to an invoice. I.e. it is not void and is neither
   * pending or approved.
   * @returns {boolean}
   */
  canBeAssignedToInvoice(): boolean {
    const approvedOrPending = this.get('status') === 'pending' || this.get('status') === 'approved';
    return !this.get('is_void') && !approvedOrPending && this.get('amount_due') > 0;
  }

  /**
   * Produces a snapshot of the Claim at this point in time.
   * @param {(PatientModel | PatientStubModel)} patient The patient for this claim.
   * @param {EncounterModel} encounter The encounter for this claim.
   * @param {BillModel} bill  The bill for this claim.
   * @param {List<SalesItemModel>} salesItems The full sales item list
   * @param {TreatmentCategories} treatmentCategories List of treatment categories to attach to the invoice
   * @param {Number} mcDays Number of Days of MC for this encounter
   * @param {List<ConditionModel>} conditions List of conditions for this encounter
   * @returns {ClaimData}
   */
  getSnapshot(
    patient: PatientStubModel | null | undefined,
    encounter: EncounterModel | null | undefined,
    bill: BillModel | null | undefined,
    salesItems: List<SalesItemModel>,
    treatmentCategories: TreatmentCategories | null | undefined,
    mcDays?: number,
    conditions?: List<ConditionModel>,
  ): ClaimData {
    const data: ClaimData = {
      _id: this.get('_id'),
      bill_id: this.get('bill_id'),
      bill_last_edit_timestamp: bill ? bill.getLastUpdateTime() : this.getLastUpdateTime(), // Claim last edited time should be a reasonable proxy when bill not found.
      timestamp: this.get('timestamp'),
      patient_id: this.get('patient_id'),
      patient_name: patient ? patient.get('patient_name') : undefined,
      patient_ic: patient ? patient.get('ic') : undefined,
      encounter_type: encounter ? encounter.getEncounterType(salesItems) : undefined,
      encounter_id: encounter ? encounter.get('_id') : undefined,
      amount: this.get('amount'),
      bill_total: bill ? bill.get('total_amount') : undefined,
      bill_notes: bill ? bill.get('notes') : undefined,
      treatment_categories: treatmentCategories || undefined,
      mc_days: mcDays,
      diagnoses: conditions && conditions
        .filter(c => c.isDiagnosis() && c.isVisible())
        .map(c => c.get('name'))
        .toArray()
        .join(', '),
    };
    return data;
  }
}

export default ClaimModel;
