import fetch from './fetch';
import { handleForbiddenResponse } from './response';

/**
 * Curried Factory function to keep track of retries
 * @param {Object} retryCounts Optional Current Retry Count Map
 * @param {Error} error The error thrown by the promise
 * @returns {Function}
 */
function retryFactory(retryCounts, error) {
  // eslint-disable-next-line no-use-before-define
  return (retryKey, TotalCount, retryStrategy = linearRepeatStrategy(10)) => {
    if (retryCounts && retryCounts[retryKey]) {
      if (retryCounts[retryKey] > TotalCount) throw error;
      return retryStrategy(
        retryCounts[retryKey],
        { ...retryCounts, [retryKey]: retryCounts[retryKey] + 1 },
      );
    }
    return retryStrategy(1, { ...retryCounts, [retryKey]: 2 });
  };
}

/**
 * implement retry functionality for a promise
 * @param {Function} task The task function that has to be retried
 * @param {Function} handler The handler function to trigger retry
 * @param {Object} retries Optional Current Retry Count Map
 * @returns {Promise}
 */
export function withRetry(task, handler, retries = {}) {
  return Promise.resolve(task())
    .catch(error => handler(retryFactory(retries, error), error)(task, handler));
}

/**
 * A exponential backoff Strategy for firing retries that increases interval exponentially
 * @param {int} base The exponent base for interval calculation
 * @returns {Function}
 */
export function exponentialBackoffStrategy(base = 2) {
  return (count, retries) => (task, handler) => new Promise(resolve => (
    setTimeout(() => resolve(withRetry(task, handler, retries)), (base ** count) * 1000)
  ));
}

/**
* A linear repeat Strategy for firing retries that retries after a fixed interval
 * @param {int} interval The fixed interval in seconds
 * @returns {Function}
 */
export function linearRepeatStrategy(interval = 10) {
  return (count, retries) => (task, handler) => new Promise(resolve => (
    setTimeout(() => resolve(withRetry(task, handler, retries)), interval * 1000)
  ));
}


/**
 * Fetches from the given url and retries on failure
 * @param {string} url The url for fetch.
 * @param {object} opts Additional options.
 * @returns {Promise<?Model>}
 */
export function fetchWithRetry(url, opts) {
  return withRetry(
    () => fetch(new Request(url), opts).then((response) => {
      if (response.ok) {
        return response;
      }
      throw response;
    }),
    (retry, error) => {
      if (error.status === 502 || error.status === 0) {
        return retry('serverRetry', 4, exponentialBackoffStrategy(2));
      }
      if (error.status === 401) {
        return retry('authRetry', 1, linearRepeatStrategy(10));
      } else if (error.status === 403) {
        handleForbiddenResponse(error);
      }
      throw error;
    },
  );
}
