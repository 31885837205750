import React from 'react';
import type { Moment } from 'moment';
import { SingleDatePicker } from 'react-dates';

import { getDateFormat } from './../../utils/time';
import Label from './label';

type Props = {
  allowPast?: boolean,
  label: string,
  labelClassName?: string,
  id: string,
  date: Moment,
  disabled?: boolean,
  focused?: boolean,
  onDateChange: (date: Moment) => void,
  onFocusChange: (state: { focused: boolean }) => void,
  appendToBody?: boolean,
  openDirection?: 'up' | 'down',
  small?: boolean,
  regular?: boolean,
  noBorder?: boolean,
}

/**
   * DatePicker
   *
   * @namespace DatePicker
   * @param {any} props props
   * @returns {React.Component} - DatePicker
   */
const DatePicker = (props: Props) => (
  <div>
    <Label className={props.labelClassName} id={props.id} label={props.label} />
    <SingleDatePicker
      id={props.id}
      date={props.date}
      focused={props.focused}
      onDateChange={props.onDateChange}
      onFocusChange={props.onFocusChange}
      numberOfMonths={1}
      displayFormat={getDateFormat()}
      disabled={props.disabled}
      isOutsideRange={props.allowPast ? () => false : undefined}
      appendToBody={props.appendToBody}
      openDirection={props.openDirection}
      small={props.small}
      regular={props.regular}
      noBorder={props.noBorder}
    />
  </div>
);

DatePicker.defaultProps = {
  disabled: false,
};

export default DatePicker;
