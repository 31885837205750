import React from 'react';
import { List, Map } from 'immutable';

import Moment from 'moment';

import translate from './../../utils/i18n';
import Table from './../table/table';
import { getDateFormat } from './../../utils/time';
import { sortByPayment } from './../../utils/comparators';

import type { MapValue, Row, CustomColumn } from './../../types';

type Props = {
  data: List<Map<string, MapValue>>,
  columns: Array<CustomColumn>,
  isFetching: boolean,
  onFilterAndSort: (data: Array<Row>) => void,
};

/**
 * Generates a table row from the given data.
 * @param {CustomColumn} column The selected columns passed to the component.
 * @param {object} value The data value to be formatted.
 * @returns {string} The value string
 */
function formatData(column: CustomColumn, value: Object) {
  if (Moment.isMoment(value)) {
    return value.format(getDateFormat());
  }
  return value;
}

/**
 * Generates a table row from the given data.
 * @param {Array<CustomColumn>} columns The selected columns passed to the component.
 * @param {Map} datum The data item.
 * @returns {{ panelName: string, payableAmount: string }} The table row
 */
function getTableRow(columns: Array<CustomColumn>, datum: typeof Map) {
  return columns.reduce(
    // @ts-ignore
    (obj, c) => ({ ...obj, [c.value]: formatData(c, datum.get(c.value)) }),
    {},
  );
}

/**
 * Gets an array of columns for react-table.
 * @param {Array<CustomColumn>} columns The selected columns passed to the component.
 * @returns {object[]} An array of objects each representing a column in the table.
 */
function getColumns(columns: Array<CustomColumn>) {
  return columns.map(c => ({
    accessor: c.value,
    Header: c.label,
    sortMethod: c.value === 'payableAmount' ? sortByPayment : undefined,
    filterable: c.filterable,
    renderMethod: c.renderMethod ? c.renderMethod : undefined,
    minWidth: c.value === 'panelName' ? 300 : 100,
    Cell: c.cell || undefined,
  }));
}

/**
 * A table displaying Accounts Receivable information.
 * @param {Props} props The props for the component.
 * @returns {React.Component} The component.
 */
const InventoryReportsTable = ({ columns, data, isFetching, onFilterAndSort }: Props) => (
  <Table
    columns={getColumns(columns)}
    data={data
      // @ts-ignore
      .map(datum => getTableRow(columns, datum))
      .toArray()}
    loading={isFetching}
    noDataText={translate('no_inventory_reports')}
    showPagination
    filteredSortedDataHandler={filteredData => onFilterAndSort(filteredData)}
    initialDataHandler={initialData => onFilterAndSort(initialData)}
  />
);

export default InventoryReportsTable;
