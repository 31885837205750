/* eslint-disable no-nested-ternary */
import React from 'react';

import translate from './../../utils/i18n';
import SaveButton from './../buttons/saveButton';
import Input from './../inputs/input';
import TextArea from './../inputs/textarea';
import FormError from './../formError';
import Checkbox from './../inputs/checkbox';
import Select from './../inputs/select';
import { queryDesignDoc } from '../../utils/db';
import { handleError } from '../../utils/api';
import Radio from './../inputs/radio';

import type { queryInput, queryMap } from '../../../src/types';

type Props = {
    selectedKey: string,
    queryMap: queryMap,
    cancelDataQueryInput: ()=>void,
    handleFetchedData: (fetchedData: Array<any>) => void,
};

type State = {
    keysInput: queryInput,
    isSaving: boolean,
    inputError: string,
    backendError: string,
    reduce: boolean,
    groupLevel: number,
    limit: number,
    selectKeyInputType: string,
    startKey: string,
    endKey: string,
    includeEndKey: boolean,
    inlcudeDocAndDesc: Array<string>
};

/**
 * DataQueryInput
 * @namespace DataQueryInput
 */
class DataQueryInput extends React.Component<Props, State> {
  /**
 * Creates an instance of DataQueryInput.
 * @param {object} props The props for this component.
 */
  constructor(props: Props) {
    super(props);
    this.state = {
      keysInput: props.queryMap.keysInput,
      isSaving: false,
      inputError: '',
      backendError: '',
      reduce: false,
      groupLevel: 0, // unset
      limit: 100,
      selectKeyInputType: props.queryMap.disabled ? '' : 'by-keys',
      startKey: '',
      endKey: '',
      includeEndKey: true,
      inlcudeDocAndDesc: [],
    };
  }

  /**
   * Validate JSON
   * @param {string} str the string to validates
   * @returns {boolean}
   */
  IsJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Set state to inital state on props change.
   * @param {input} keysInput Mapping Key Input
   * @returns {void}
   */
  setStatetoInitial(keysInput: queryInput) {
    this.setState({
      inputError: '',
      backendError: '',
      keysInput,
      isSaving: false,
      reduce: false,
      groupLevel: 0, // unset
      limit: 100,
      selectKeyInputType: 'by-keys',
      startKey: '',
      endKey: '',
      includeEndKey: true,
      inlcudeDocAndDesc: [],
    });
  }

  /**
   * Checks if the props changes after component update.
   * @param {Props} oldProps Previous props
   * @returns {void}
   */
  componentDidUpdate(oldProps: Props) {
    const newProps = this.props;
    if (oldProps.selectedKey !== newProps.selectedKey) {
      this.setStatetoInitial(newProps.queryMap.keysInput);
    }
  }

  /**
   * Set the extra params state value.
   * @param {string} value value on input change
   * @param {boolean} isChecked true if checkbox is selected
   * @returns {void}
   */
  handleExtraParams = (value: string, isChecked: boolean) => {
    this.setState(prevState => ({
      inlcudeDocAndDesc: isChecked ?
        prevState.inlcudeDocAndDesc.concat([value]) :
        prevState.inlcudeDocAndDesc.filter(l => l !== value),
    }));
  }

  /**
   * Handle validation for the between-keys input
   * @param {string} key startKey or endkey
   * @param {string} value value of the input change
   * @returns {void}
   */
  handleBetweenKeyInputChange(key: keyof State, value: string) {
    if (value && !(this.IsJsonString(value))) {
      const inputError = 'Enter Valid JSON string';
      this.setState(prevState => ({ //Issue with dynamic key https://github.com/DefinitelyTyped/DefinitelyTyped/issues/26635
        ...prevState,
        [key]: value,
        inputError,
      }));
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      [key]: value,
      inputError: '',
    }));
  }

  /**
   * Handle validation for the by-keys input
   * @param {string} value value of the input change
   * @returns {void}
   */
  handleByKeyInputChange(value: string) {
    const keysInput = Object.assign({}, this.state.keysInput, {value});
    if (value && !(this.IsJsonString(value))) {
      const inputError = this.state.keysInput.error;
      this.setState({
        keysInput: keysInput,
        inputError,
      });
      return;
    }
    this.setState({
      keysInput: keysInput,
      inputError: '',
    });
  }

  /**
   * Fetch the query as per states value
   * @returns {void}
   */
  async handleSaving() {
    this.setState({ isSaving: true });
    let startKey: any = null;
    let endKey: any = null;
    let finalInput: any = null;
    if (this.state.selectKeyInputType === 'between-keys') {
      let inputError: string = 'Enter Valid JSON string';
      if (this.state.startKey && !this.state.endKey && this.IsJsonString(this.state.startKey)) {
        startKey = JSON.parse(this.state.startKey);
        inputError = '';
      }
      if (this.state.endKey && !this.state.startKey && this.IsJsonString(this.state.endKey)) {
        endKey = JSON.parse(this.state.endKey);
        inputError = '';
      }
      if (this.state.startKey && this.state.endKey &&
          this.IsJsonString(this.state.startKey) &&
          this.IsJsonString(this.state.endKey)) {
        inputError = '';
        startKey = JSON.parse(this.state.startKey);
        endKey = JSON.parse(this.state.endKey);
      }
      if (inputError && !(this.state.startKey === '' && this.state.endKey === '')) {
        this.setState({
          inputError,
          isSaving: false,
        });
        return;
      }
    } else if (this.state.keysInput && this.state.keysInput.value) {
      if (!(this.IsJsonString(this.state.keysInput.value))) {
        this.setState(prevState => ({
          inputError: prevState.keysInput.error,
          isSaving: false,
        }));
        return;
      }
      finalInput = JSON.parse(this.state.keysInput.value);
    }
    try {
      const data = await queryDesignDoc(this.props.queryMap.queryDDOCView,
        {
          ...finalInput && this.state.keysInput.value && { keys: finalInput },
          ...startKey && { start_key: startKey },
          ...endKey && { end_key: endKey },
          limit: this.state.limit === -1 ? 100 : this.state.limit,
          reduce: this.state.reduce,
          ... this.state.groupLevel !== 0 && {
            group_level: this.state.groupLevel,
          },
          ...this.state.includeEndKey && endKey && { inclusive_end: true },
          ...this.state.inlcudeDocAndDesc.includes('include_docs') && { include_docs: true },
          ...this.state.inlcudeDocAndDesc.includes('descending') && { descending: true },
        });
      this.setState({
        isSaving: false,
      });
      this.props.handleFetchedData(data.rows);
    } catch (error) {
      handleError(error, this.props.queryMap.queryDDOCView);
      this.setState({
        backendError: error.message,
        isSaving: false,
      });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-form__column">
        { (this.state.inputError || this.state.backendError) &&
        <FormError>
          {
            this.state.inputError ? this.state.inputError : this.state.backendError
          }
        </FormError>
        }
        <Radio
          id="keyType"
          label="Keys Input Types"
          description=""
          options={[
            { label: 'By keys', value: 'by-keys' },
            { label: 'Between Keys', value: 'between-keys' },
          ]}
          value={this.state.selectKeyInputType}
          onValueChanged={newValue => this.setState({
            selectKeyInputType: newValue,
            keysInput: this.props.queryMap.keysInput,
            inputError: '',
            startKey: '',
            endKey: '',
            backendError: '',
          })}
          disabled={this.props.queryMap.disabled}
        />
        <React.Fragment>
          {this.state.selectKeyInputType === 'by-keys' &&
            <TextArea
              id={this.state.keysInput.pseudoName}
              label={this.state.keysInput.name}
              value={this.state.keysInput.value}
              inputStyle={{ fontFamily: 'monospace', maxHeight: '300px' }}
              onValueChanged={
                value => this.handleByKeyInputChange(value)}
              placeholder={this.state.keysInput.placeholder}
            />}
          {this.state.selectKeyInputType === 'between-keys' &&
            <React.Fragment>
              <Input
                placeholder={'e.g., "123" or["<id1>","<id2>"]'}
                id="start-key"
                label="Start Key"
                value={this.state.startKey}
                inputStyle={{ fontFamily: 'monospace', maxHeight: '300px' }}
                onValueChanged={
                  (value: string) => this.handleBetweenKeyInputChange('startKey', value)}
              />
              <Input
                placeholder={'e.g., "123" or["<id1>","<id2>"]'}
                id="end-key"
                label="End Key"
                value={this.state.endKey}
                inputStyle={{ fontFamily: 'monospace', maxHeight: '300px' }}
                onValueChanged={
                  (value: string) => this.handleBetweenKeyInputChange('endKey', value)}
              />
              <Checkbox
                id="include-endkey"
                label=""
                value={[this.state.includeEndKey.toString()]}
                options={[
                  { value: 'true', label: 'Include End Key' },
                ]}
                onValueChanged={(value, isChecked) => {
                  this.setState({
                    includeEndKey: isChecked,
                    backendError: '',
                  });
                }}
              />
            </React.Fragment>}
          <Select
            id="query-limit"
            label="Limit"
            onValueChanged={(value: string) => this.setState({
              limit: value ? Number(value) : -1,
            })}
            options={
              [5, 10, 20, 30, 50, 100, 500, 1000]
                .map(value => ({ value: value.toString(), label: value.toString() }))
              }
            value={this.state.limit.toString()}
          />

          <Checkbox
            id="query-reduce"
            label=""
            value={[this.state.reduce.toString()]}
            options={[
              { value: 'true', label: 'Reduce Query' },
            ]}
            onValueChanged={(value, isChecked) => {
              this.setState({
                reduce: isChecked,
                groupLevel: 0,
                backendError: '',
              });
            }}
            disabled={this.props.queryMap.disabled}
          />
          {this.state.reduce ?
            <Input
              placeholder="default 0 i.e, exact"
              id="group-values-query"
              label="Group levels"
              value={this.state.groupLevel}
              type="number"
              min={0}
              inputStyle={{ padding: '10px' }}
              onValueChanged={(value) => {
                this.setState({
                  backendError: '',
                  groupLevel: Number(value),
                });
              }}
            />
            :
            null}
          <h4 style={{ fontSize: '1em', fontFamily: 'robotobold', marginBottom: '10px' }}>
              Additional Parameter
          </h4>
          <Checkbox
            id="additional-params"
            label=""
            value={this.state.inlcudeDocAndDesc}
            options={[
              { value: 'include_docs', label: 'Include Docs' },
              { value: 'descending', label: 'Descending' },
            ]}
            onValueChanged={this.handleExtraParams}
            multiColumn
          />
          <SaveButton
            id="fetch-data"
            isSaving={this.state.isSaving}
            onClick={() => this.handleSaving()}
            label={translate('fetch')}
            savingLabel={translate('fetching')}
            disabled={
                !!((this.state.inputError || this.state.backendError))
            }
            className="o-button o-button--small u-margin-right--half-ws"
          />
          <button
            id="cancel-query"
            type="button"
            className="o-button o-button--small o-button--danger"
            onClick={() => this.props.cancelDataQueryInput()}
          >
            {translate('cancel')}
          </button>
        </React.Fragment>
      </div>

    );
  }
}

export default DataQueryInput;
