import { connect } from 'react-redux';

import CategoryOverview from './../components/casenotes/categoryOverview';
import { sortModelByOrder } from './../utils/comparators';

import type { State } from './../types';

type Props = {
  patientID: string,
  categoryID?: string,
  casenoteID?: string,
}

/**
   * @param {Object} state Current app state.
   * @param {Props} ownProps Container props.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  (state: State, { casenoteID, categoryID, patientID }: Props) => ({
    casenoteID: casenoteID === 'grid' ? undefined : casenoteID, // CasenoteID may be overrided with "grid" to display grid view.
    categoryID,
    categories: state.categories.sort(sortModelByOrder),
    coveragePayors: state.coveragePayors,
    selectedCategory: categoryID && categoryID !== 'ALL'
      ? state.categories.find(c => c.get('_id') === categoryID) : undefined,
    patient: state.patients.find(p => p.get('_id') === patientID),
    patientStub: state.patientStubs.find(m => m.get('_id') === patientID),
    useGridView: casenoteID === 'grid',
    drugs: state.drugs,
    activeIngredients: state.activeIngredients,
  });

const CategoryOverviewContainer = connect(mapStateToProps)(CategoryOverview);

export default CategoryOverviewContainer;
