import React from 'react';
import type { Moment } from 'moment';
import { SingleDatePicker } from 'react-dates';
import glamorous from 'glamorous';

import { getDateFormat } from './../../utils/time';
import Label from './label';

type Props = {
  id: string,
  label: string,
  labelClassName?: string,
  value: Moment | null,
  onValueChanged: (value: Moment) => void,
  className?: string,
  allowPast?: boolean,
  showClearDate?: boolean,
  isOutsideRange?: (day: any) => boolean,
  style?: { [key: string]: string | number },
  disabled?: boolean,
  appendToBody?: boolean,
  openDirection?: 'up' | 'down',
  small?: boolean,
  regular?: boolean,
  noBorder?: boolean,
  required?: boolean,
  showInvalid?: boolean,
};

type State = {
  isFocused: boolean,
};

const Wrapper = glamorous.div({
  '& .SingleDatePickerInput': {
    border: '1px solid red',
  },
  '& .DateInput': {
    background: 'unset',
  },
  '& .DateInput_input__focused': {
    backgroundColor: '#ffffff',
  },
});

/**
   * StatefulDatepicker
   * @namespace StatefulDatepicker
   * @returns {React.Component} - StatefulDatepicker
   */
class StatefulDatepicker extends React.Component<Props, State> {
  static defaultProps = {
    allowPast: false,
    showClearDate: true,
    style: {},
  };

  /**
   * Creates an instance of StatefulDatepicker. Creates state with an isFocused parameter.
   * @param {any} props Props for the component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false,
      wrapDatePicker: false,
    };
  }

  /**
   * Handles date change as react-dates.
   * @param {Moment | void} newValue The new value from DatePicker.
   * @return {void}
   */
  onDateChange = (newValue: Moment | void) => {
    const { onValueChanged } = this.props;
    this.setState({
      wrapDatePicker: this.props.required && !newValue,
    });
    onValueChanged(newValue);
  }

  /**
   * Renders a react component containing a label and a datepicker.
   * @returns {React.Component} The react component.
   */
  render() {
    const { showInvalid } = this.props;
    let isOutsideRangeValue;
    if (this.props.allowPast) {
      isOutsideRangeValue = () => false;
    }
    if (this.props.isOutsideRange) {
      isOutsideRangeValue = this.props.isOutsideRange;
    }
    const wrapDatePicker = showInvalid !== undefined ? showInvalid : this.state.wrapDatePicker
    const singleDatePicker = (<SingleDatePicker
      id={this.props.id}
      date={this.props.value}
      focused={this.state.isFocused}
      onDateChange={this.onDateChange}
      onFocusChange={state => this.setState({ isFocused: state.focused })}
      numberOfMonths={1}
      displayFormat={getDateFormat()}
      showClearDate={this.props.showClearDate}
      isOutsideRange={isOutsideRangeValue}
      disabled={this.props.disabled}
      appendToBody={this.props.appendToBody}
      openDirection={this.props.openDirection}
      small={this.props.small}
      regular={this.props.regular}
      noBorder={this.props.noBorder}
      required={this.props.required}
    />);
    return (
      <div className={this.props.className} style={this.props.style}>
        <Label className={this.props.labelClassName} id={this.props.id} label={this.props.label} />
        {wrapDatePicker ?
          <Wrapper>
            {singleDatePicker}
          </Wrapper>
          :
          singleDatePicker}
      </div>
    );
  }
}

export default StatefulDatepicker;
