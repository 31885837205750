import { connect } from 'react-redux';
import { List } from 'immutable';
import Moment from 'moment';

import ClaimInvoices from './../components/reports/claimInvoices';
import AsyncFetch from './../components/asyncFetch';
import { saveModelsFactory, saveModelsAPIFactory } from './../utils/redux';
import { generateClaimInvoiceModels, voidClaimInvoiceModels } from './../utils/api';
import { compareByAlphabeticalOrder } from './../utils/comparators';
import {
  setClaimInvoicesFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
} from './../actions';
import { getClaimInvoicesDataViews } from './../dataViews';

import type { State, Dispatch, Model, SelectOption, MapValue, DataView, ClaimInvoiceMetadata } from './../types';
import type BaseModel from '../models/baseModel';

/**
 * Gets an array of unique months for the filter.
 * @param {List<Model>} invoices The available invoices
 * @returns {Array<{ value: string, label: string}>}
 */
export function getUniqueMonths(invoices: List<Model>): Array<SelectOption> {
  let uniqueMonths = List();
  invoices.forEach((invoice) => {
    const { month } = invoice.get('items');
    if (
      month !== undefined &&
      !uniqueMonths.find(i => i.month === month.month && i.year === month.year)
    ) {
      uniqueMonths = uniqueMonths.push(month);
    }
  });
  const formattedUniqueMonths = uniqueMonths
    .toArray()
    .map(i => ({
      value: `${i.month}_${i.year}`,
      label: Moment({ month: i.month - 1, year: i.year, day: 1 }).format('MMM YYYY'),
    }));
  const sortedUniqueMonths = formattedUniqueMonths.sort((a, b) => {
    const newA = a.value.split('_');
    const newB = b.value.split('_');
    return new Date(Number(newA[1]), Number(newA[0]), 1) -
      new Date(Number(newB[1]), Number(newB[0]), 1);
  });
  return sortedUniqueMonths;
}

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  coveragePayors, reportsFilter, isFetching, currentDataViews, currentDataViewsModels, config,
  patientStubs, salesItems, user, drugs,
}: State) => ({
  claimInvoices: currentDataViewsModels.filter(m => m && m.get('type') === 'claim_invoice' && !m.get('is_void')),
  claims: currentDataViewsModels.filter(m => m && m.get('type') === 'claim'),
  coveragePayors: coveragePayors
    .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
  filter: reportsFilter.get('claimInvoices'),
  isFetching,
  containerDataViews: getClaimInvoicesDataViews(),
  currentDataViews,
  showLoadingIndicator: true,
  config,
  patientStubs,
  salesItems,
  uniqueMonths: getUniqueMonths(currentDataViewsModels.filter(m => m && m.get('type') === 'claim_invoice' && !m.get('is_void'))),
  user,
  drugs,
  claimInvoicePayments: currentDataViewsModels.filter(m => m && m.get('type') === 'claim_invoice_payment' && !m.isVoid()),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, MapValue>) => dispatch(setClaimInvoicesFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  saveModels: saveModelsFactory(dispatch),
  saveClaimInvoiceModels: (invoices: Array<ClaimInvoiceMetadata>, regenerate = false) => (
    saveModelsAPIFactory(dispatch)(() => generateClaimInvoiceModels(invoices, regenerate, dispatch))
  ),
  voidClaimInvoiceModels: (invoices: Array<string>) => (
    saveModelsAPIFactory(dispatch)(() => voidClaimInvoiceModels(invoices, dispatch))
  ),
});

const ClaimInvoicesContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(ClaimInvoices));

export default ClaimInvoicesContainer;
