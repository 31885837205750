import { Record, List } from 'immutable';
import type { RecordFactory } from 'immutable';

import { isKlinifyUser } from './auth';

import type { User, PermissionRecordProps, UserPermissionRecordProps, UserPermissionRecord, PermissionRecord, MapValue } from './../types';

const PRecord: RecordFactory<PermissionRecordProps> = Record({ feature: '', access: '' });
const UPRecord: RecordFactory<UserPermissionRecordProps> = Record({ feature: '', access: '' });

/**
 * Creates a PermissionRecord representing a single access level needed by the application.
 * @param {string} feature The feature the access is for.
 * @param {string} access The access level
 * @returns {PermissionRecord}
 */
export function createPermission(feature: string, access: string): PermissionRecord {
  return PRecord({ feature, access });
}

/**
 * Creates a UserPermissionRecord representing feature and the level of access the user has for it.
 * Note that this is different from PermissionRecord.
 * @param {string} feature The feature the access is for.
 * @param {List<string>} access The access levels the user has (can be empty).
 * @returns {UserPermissionRecord}
 */
export function createUserPermission(
  feature: string,
  access: List<string> = List(),
): UserPermissionRecord {
  return UPRecord({ feature, access });
}

/**
 * Returns true if the user has the given set of permissions.
 * @param {User} user The current User.
 * @param {List<PermissionRecord>} permissionsRequired The required permissions.
 * @returns {boolean}
 */
export function hasPermission(user: User, permissionsRequired: List<PermissionRecord>): boolean {
  const userGroup = user.get('userGroup');
  const userModel = user.get('model');
  if (permissionsRequired.size === 0 || isKlinifyUser(user)) {
    return true;
  }
  const userPermissions: Map<string, UserPermissionRecord> | null | undefined =
  (userGroup && userGroup.hasPermissions() && userGroup.getPermissionsMap()) ||
  (userModel && userModel.hasPermissions() && userModel.getPermissionsMap());
  if (!userPermissions) {
    return false;
  }

  return permissionsRequired.every((permission) => {
    if (!userPermissions) {
      return false; // Stupidness to shut up flow.
    }
    const userPermission = userPermissions.get(permission.get('feature'));
    return userPermission ?
      userPermission.get('access').contains(permission.get('access')) :
      false;
  });
}

/**
 * Returns true if the user has one of the given set of permissions.
 * @param {User} user The current User.
 * @param {List<PermissionRecord>} permissions The possible permissions.
 * @returns {boolean}
 */
export function hasSomePermission(user: User, permissions: List<PermissionRecord>): boolean {
  return permissions.some(p => hasPermission(user, List([p])));
}

/**
 * Returns all the permissions needed in consults history table and settings for column filtering.
 * @param {User} user The current User.
 * @returns {MapValue} Object with boolean value for each permissions
 */
export function getConsultHistoryPermissions(user: User): MapValue {
  const hasBillViewPermission = hasPermission(user,
    List([createPermission('finalised_bill', 'read')]));
  const hasPrescriptionViewPermission = hasPermission(user,
    List([createPermission('past_encounters_prescriptions', 'read')]));
  const hasConditionsViewPermission = hasPermission(user,
    List([createPermission('past_encounters_conditions', 'read')]));
  const hasNotesViewPermission = hasPermission(user,
    List([createPermission('past_encounters_notes', 'read')]));
  const hasMcTcViewPermission = hasPermission(user,
    List([createPermission('past_encounters_medical_certificates', 'read'),
      createPermission('past_encounters_time_chits', 'read')]));
  const hasLabViewPermission = hasPermission(user,
    List([createPermission('past_encounters_lab_tests', 'read')]));
  return {
    hasBillViewPermission,
    hasPrescriptionViewPermission,
    hasConditionsViewPermission,
    hasNotesViewPermission,
    hasMcTcViewPermission,
    hasLabViewPermission,
  };
}
