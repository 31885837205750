import React from 'react';
import { List } from 'immutable';
import { Link } from 'react-router-dom';
import glamorous, { Div } from 'glamorous';
import moment from 'moment';

import { fonts, colours } from './../../utils/css';
import { UNICODE } from './../../constants';
import translate from './../../utils/i18n';
import Select from './../inputs/select';

import type PractitionerModel from './../../models/practitionerModel';
import type SalesItemModel from './../../models/salesItemModel';
import EncounterModel from '../../models/encounterModel';
import PatientModel from '../../models/patientModel';
import PatientStubModel from '../../models/patientStubModel';

type Props = {
  encounter: EncounterModel,
  patient: PatientModel | PatientStubModel,
  drugPrescribed: List<string>,
  status: string,
  onStatusChange: (string) => void,
  practitioners: List<PractitionerModel>,
  salesItems: List<SalesItemModel>,
};

type KeyValueProps = {
  label: string,
  value: string | HTMLElement,
}

const Key = glamorous.div({
  marginRight: '15px',
  textTransform: 'uppercase',
  fontFamily: fonts.medium,
  flex: 1,
}, ({ isDanger, style }) => {
  const intialValue = { color: isDanger ? colours.red : colours.black };
  return style ? Object.assign(intialValue, style) : intialValue;
});

const Value = glamorous.div({
  flex: 1,
}, ({ isDanger }) => ({
  color: isDanger ? colours.red : colours.black,
}));

/**
 * Util function to create a key pair with proper format.
 * @param {KeyValueProps} keyValue conatins label and value which needs to be shown
 * @returns {HTMLElement}
*/
function KeyValue(keyValue: KeyValueProps) {
  return (
    <Div css={{ display: 'flex' }}>
      <Key>{keyValue.label}:</Key> <Value>{keyValue.value}</Value>
    </Div>
  );
}

/**
 * Renders a component showing the current dispensing batch and all the batch available for dispensation.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
export default function SmsJobEncounterDetail(props: Props) {
  const { encounter, patient } = props;
  const encounterType = encounter && encounter.getEncounterType(props.salesItems);
  const encounterTime = encounter && moment(encounter.getLastEventTime()).format('hh:mm:ss a');
  const encounterDate = encounter && encounter.getDate();
  const doctorName = (encounter && encounter.getDoctorName(props.practitioners)) || UNICODE.EMDASH;
  const receptionStaff = (encounter && encounter.get('created_by').user_id) || UNICODE.EMDASH;
  return (
    <Div css={{ display: 'flex', background: '#eeeded', flexWrap: 'wrap' }}>
      <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }} className="u-margin--standard">
        <KeyValue
          label={translate('patient_name')}
          value={patient ?
            <Link to={`/patient/${patient.get('_id')}`} style={{ color: 'black' }}>
              {patient.get('patient_name')}
            </Link> : UNICODE.EMDASH}
        />
        <KeyValue label={translate('encounter_type')} value={encounterType} />
        <KeyValue label={translate('encounter_date')} value={encounterDate} />
        <KeyValue label={translate('encounter_time')} value={encounterTime} />
        <div className="u-margin-bottom--1ws" />
        <Div css={{ display: 'flex' }}>
          <Key>{`${translate('drug_prescribed')}:`}</Key>
          <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {props.drugPrescribed.size ? props.drugPrescribed.map(drug =>
              <Value>{drug}</Value>) : UNICODE.EMDASH}
          </Div>
        </Div>
        <div className="u-margin-bottom--1ws" />
        <Div css={{ display: 'flex' }}>
          <Key>{`${translate('sales_items_prescribed')}:`}</Key>
          <Div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {props.salesItems.size ? props.salesItems.map(item =>
              <Value>{item.get('name')}</Value>) : UNICODE.EMDASH}
          </Div>
        </Div>
        <div className="u-margin-bottom--1ws" />
        <KeyValue label={translate('doctor')} value={doctorName} />
        <KeyValue label={translate('reception_staff')} value={receptionStaff} />
      </Div>
      <Div css={{ display: 'flex', justifyContent: 'space-between', flex: 1 }} className="u-margin--standard o-form">
        <Div css={{ display: 'none' }}>
          <Key style={{ textAlign: 'end', padding: '18px' }}>{`${translate('status')}:`}</Key>
          <Select
            id="status"
            value={props.status}
            label=""
            options={[{ label: translate('resolved'), value: 'resolved' }, { label: translate('unresolved'), value: 'unresolved' }]}
            onValueChanged={value => props.onStatusChange(value)}
            clearable={false}
            style={{ flex: 1 }}
            disabled={!props.status}
          />
        </Div>
      </Div>
    </Div>
  );
}
