import React, { Fragment } from 'react';

import translate from '../../utils/i18n';
import Radio from '../inputs/radio';

import type { InvoiceSettings } from './../../models/coveragePayorModel';

type Props = {
  value: InvoiceSettings,
  onValueChanged: (
    value: InvoiceSettings,
  ) => void,
};

type State = {
  showTreatmentDetails: boolean,
  showTreatmentBreakdown: boolean,
  isLandscape: boolean,
};

/**
 * A form that allows managing print setting for a panel
 * @class CoveragePayorInvoiceSettingsForm
 * @extends {React.Component<Props, State>}
 */
class CoveragePayorInvoiceSettingsForm extends React.Component<Props, State> {
  /**
   * Creates an instance of PanelCategoryForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      showTreatmentDetails: props.value.show_treatment_details,
      showTreatmentBreakdown: props.value.show_treatment_breakdown,
      isLandscape: props.value.is_landscape,
    };
  }

  /**
   * Handle field change
   * @param {{}} value The field value
   * @returns {void}
   */
  handleOnChange(value: {}) {
    const newValue = Object.assign(this.state, value);
    this.props.onValueChanged(Object.assign({}, {
      show_treatment_details: newValue.showTreatmentDetails,
      show_treatment_breakdown: newValue.showTreatmentBreakdown,
      is_landscape: newValue.isLandscape,
    }));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="o-form" style={{ margin: 0 }}>
          <Radio
            id="show_treatment_details"
            label={translate('show_treatment_details')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.showTreatmentDetails.toString()}
            onValueChanged={value => this.handleOnChange({ showTreatmentDetails: value === 'true' })}
          />
          <Radio
            id="show_treatment_breakdown"
            label={translate('show_treatment_breakdown')}
            options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
            value={this.state.showTreatmentBreakdown.toString()}
            onValueChanged={value => this.handleOnChange({ showTreatmentBreakdown: value === 'true' })}
          />
          <Radio
            id="print_layout"
            label={translate('print_layout')}
            value={this.state.isLandscape.toString()}
            onValueChanged={value => this.handleOnChange({ isLandscape: value === 'true' })}
            options={[{ value: 'false', label: translate('portrait') }, { value: 'true', label: translate('landscape') }]}
          />
        </div>
      </Fragment>
    );
  }
}

export default CoveragePayorInvoiceSettingsForm;
