import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import { updateConfigValue, updateConfig } from '../actions';
import { saveFactory } from '../utils/redux';
import { getModelMapFromList } from '../utils/models';
import { fetchData } from '../utils/api';
import { getCampaignsFilterDataview } from '../dataViews';

import type { Dispatch, State, Config } from '../types';
import DiscountsCharges from '../components/discounts/discountsCharges';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config,
  user,
  salesItems,
  discountsCharges,
  coveragePayors,
  encounterFlows,
  encounterStages,
  encounters,
}: State) => ({
  config,
  user,
  salesItems: Map(
    salesItems
      .filter(i => !i.getOrFalse('hidden'))
      .map(s => [s.get('_id'), s]),
  ),
  discountsCharges: discountsCharges.filter(m => m.isVisible()),
  coveragePayors,
  stagesMap: getModelMapFromList(encounterStages.filter(stage => stage.isVisible())),
  encounterFlows: encounterFlows.filter(flow => flow.isVisible()),
  currentEncounters: encounters.filter(encounter => !encounter.containsEvent('cancelled') && !encounter.containsEvent('completed')),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateStrings: (updatedStrings: List<string>) =>
    dispatch(updateConfigValue(['diagnoses', 'options'], updatedStrings)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  saveModel: saveFactory(dispatch),
  fetchRelatedCampaigns: (flowId: string) => {
    const data = {
      filters: [{
        metadata: {
          encounter_flow: flowId,
        },
      }],
      filterType: 'and',
    };
    return fetchData(getCampaignsFilterDataview(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsCharges);
