import * as React from 'react';
import { ReactSpecimen } from 'catalog';

import Modal from './../../modals/modal';
import { wsUnit } from '../../../utils/css';

/**
 * Modal components for styleguide
 * @class ModalComponents
 * @extends {React.Component<Props, State>}
 */
const ModalComponents = () =>
  <React.Fragment>
    <h2 data-public>Modal Components</h2>
    <h3 data-public>Modal</h3>
    <p>
      A modal component that keeps its state internal.
    </p>
    <ReactSpecimen>
      <Modal
        title="My Example Modal"
        buttonLabel="Click Here"
        coverStyle={{ position: 'fixed' }} // Needed to work in style guide.
        dataPublic
      >
        <p style={{ margin: wsUnit }}>¯\_(ツ)_/¯</p>
      </Modal>
    </ReactSpecimen>
  </React.Fragment>;

export default ModalComponents;
