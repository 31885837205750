import { connect } from 'react-redux';

import PatientsList from './../components/patientsList/patientsList';
import { saveFactory } from './../utils/redux';
import { updateConfigValue, setPatientListFilters, updateConfig } from './../actions';

import type { State, Dispatch, MapValue, Config } from './../types';
import { compareByAlphabeticalOrder } from './../utils/comparators';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => ({
  drugs: state.drugs,
  config: state.config,
  salesItems: state.salesItems,
  patientListFilters: state.patientListFilters,
  coveragePayors: state.coveragePayors
    .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateConfigValue: (keys: Array<string>, value: MapValue) =>
    dispatch(updateConfigValue(keys, value)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  onFiltersUpdated: filters => dispatch(setPatientListFilters(filters)),
});

const PatientsListContainer = connect(mapStateToProps, mapDispatchToProps)(PatientsList);

export default PatientsListContainer;
