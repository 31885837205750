import { List } from 'immutable';

/**
 * Sort comparator for drug durations.
 * @param {Array<number, string>} a first element for comparison
 * @param {Array<number, string>} b second element for comparison
 * @returns {void}
 */
function sortDefaultDurationComparator(a, b) {
  const durationEnum = { day: 1, week: 2, month: 3 };
  if (durationEnum[a[1]] < durationEnum[b[1]]) return -1;
  if (durationEnum[a[1]] > durationEnum[b[1]]) return 1;
  if (a[0] === b[0]) return 0;
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;
  return 0;
}

/**
 * Returns the options for the default duration Select
 * @param {Array<Array<number, string>>} drugDurations array of drugdurations
 * @returns {Array<Array<number, string>>} sorted array of drugDurations
 */
export function getDefaultDurationOptions(drugDurations: List<List<number | string>>) {
  return drugDurations
    .map(duration => duration.toArray())
    .toArray()
    .sort(sortDefaultDurationComparator);
}
/**
* @param {Array<string>} input new duration input array
* @returns {Array<number, string>} returns a valid drug duration
*/
export function durationInput(input: Array<string>): [number, string] | void {
  const durationEnum = { d: 'day', w: 'week', m: 'month' };
  const inputLength = input.length;
  if (inputLength === 1 && !isNaN(Number(input[0]))) {
    return [Number(input[0]), 'day'];
  } if (inputLength === 2 && !isNaN(Number(input[0])) && ['d', 'w', 'm', 'D', 'W', 'M'].includes(input[1][0])) {
    return [Number(input[0]), durationEnum[input[1][0].toLowerCase()]];
  }
  return undefined;
}
