import React from 'react';
import { List, Map } from 'immutable';
import Moment, { Moment as TMoment } from 'moment';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import LabRequestsList from './../labRequests/labRequestsList';

import type PatientStubModel from '../../models/patientStubModel';
import type ProviderModel from './../../models/providerModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProcedureRequestModel from './../../models/procedureRequestModel';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type ProcedureResultModel from './../../models/procedureResultModel';
import type SpecimenModel from './../../models/specimenModel';
import type PatientModel from './../../models/patientModel';
import type { User, Config, SaveModel, SaveModels, MapValue } from './../../types';
import type { NewFilterType } from './../table/tableDateRangePicker';
import TableDateRangePicker from './../table/tableDateRangePicker';
import UnsavedDataPrompt from './../prompts/unsavedDataPrompt';
import Header from './../header/header';
import { fetchData } from '../../utils/api';
import { getProcedureRequestDocs } from '../../dataViews';

type Props = {
  patientStubs: List<PatientStubModel>,
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  collectedSpecimens: List<CollectedSpecimenModel>,
  procedureResults: List<ProcedureResultModel>,
  patients: List<PatientModel>,
  specimens: List<SpecimenModel>,
  user: User,
  config: Config,
  saveModel: SaveModel,
  saveModels: SaveModels,
  setProcedureRequests: (models: List<ProcedureRequestModel>) => void,
  updateConfig: (config: Config) => void,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  filter: Map<string, TMoment>,
};

/**
 * A component for selecting the filter for displaying lab requests
 * @class DrugDispensation
 * @extends {React.Component<Props, State>}
 */
class LabRequests extends React.PureComponent<Props> {
  /**
   * Creates an instance of LabRequestsList.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    fetchData(getProcedureRequestDocs()).then(
      (models: List<ProcedureRequestModel>) => {
        this.props.setProcedureRequests(models);
      },
    );
  }

  /**
   * Merges the given object with the current filter for cost report and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @param {boolean} isDateOrdered True if filter is by Date ordered.
   * @returns {void}
   */
  updateFilter(newValues: NewFilterType, isDateOrdered: boolean = false) {
    const dateOrderedValues = {
      filterDateOrderedStart: newValues.filterDateStart,
      filterDateOrderedEnd: newValues.filterDateEnd,
    };
    const lastUpdateValues = {
      filterLastUpdateStart: newValues.filterDateStart,
      filterLastUpdateEnd: newValues.filterDateEnd,
    };
    this.props.onFilterUpdated(
      this.props.filter.merge(isDateOrdered ? dateOrderedValues : lastUpdateValues),
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const dateOrderedFilter = Map({
      filterDateStart: this.props.filter.get('filterDateOrderedStart'),
      filterDateEnd: this.props.filter.get('filterDateOrderedEnd'),
    });
    const lastUpdateFilter = Map({
      filterDateStart: this.props.filter.get('filterLastUpdateStart'),
      filterDateEnd: this.props.filter.get('filterLastUpdateEnd'),
    });
    return (
      <ContentTransition className="o-scrollable-container">
        <UnsavedDataPrompt
          when={this.props.procedureRequests.filter(m => m.getStatusLastEventStatus(this.props.procedureStatuses) === 'sending').size > 0}
          title={translate('sending_order_in_progress')}
          description={translate('sending_order_in_progress_desc')}
        />
        <Header className="o-header" dataPublic>
          <h1>{translate('lab_requests')}</h1>
        </Header>
        <div className="u-flex-column u-margin--standard">
          <div className="u-flex-right">
            <TableDateRangePicker
              filter={dateOrderedFilter}
              onUpdateFilter={newFilter => this.updateFilter(newFilter, true)}
              label="Date Ordered"
            />
            <TableDateRangePicker
              filter={lastUpdateFilter}
              onUpdateFilter={newFilter => this.updateFilter(newFilter)}
              label="Last Update"
            />
          </div>
        </div>
        <div className="o-card u-margin-bottom--4ws">
          <LabRequestsList
            providers={this.props.providers}
            procedureTypes={this.props.procedureTypes}
            procedureRequests={
              this.props.procedureRequests.filter(procedureRequest =>
                (location.hash === '#/lab-requests' ?
                  Moment(procedureRequest.getCreatedTime()).isBetween(
                    this.props.filter.get('filterDateOrderedStart'),
                    this.props.filter.get('filterDateOrderedEnd'), 'day', [],
                  ) : procedureRequest))
            }
            procedureStatuses={this.props.procedureStatuses}
            collectedSpecimens={this.props.collectedSpecimens}
            procedureResults={this.props.procedureResults}
            updateConfig={this.props.updateConfig}
            patients={this.props.patients}
            specimens={this.props.specimens}
            saveModel={this.props.saveModel}
            saveModels={this.props.saveModels}
            user={this.props.user}
            config={this.props.config}
            hideLabRequestsModalButton
            patientStubs={this.props.patientStubs}
            lastUpdateFilter={lastUpdateFilter}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default LabRequests;
