import React from 'react';
import Tooltip from 'react-tooltip-lite';
import glamorous from 'glamorous';
import { wsUnit } from '../../utils/css';
import ProgressLine from '../progress-bars/progress-line';
import translate from '../../utils/i18n';

const LegendIdentifier = glamorous.span(({ strokeColor }) => ({
  backgroundColor: strokeColor,
  width: 24,
  height: 24,
  marginRight: 8,
  border: '1px solid #e5e5e5',
}));

const LegendInfo = glamorous.div({
  padding: `calc(${wsUnit} / 2)`,
});

const LegendDescription = glamorous.span({
  flex: 1,
});

const ListItem = glamorous.h6({
  display: 'list-item',
  listStyle: 'disc',
});

const List = glamorous.ul({
  position: 'relative',
  left: '4rem',
});

type Props = {
  categorizedPercentage: {
    verified: string,
    verifiedPercentage: string,
    pendingReview: string,
    pendingReviewV2Count: number,
    pendingReviewPercentage: string,
    newlyAdded: string,
    newlyAddedPercentage: string,
    unmapped: string,
    unmappedPercentage: string,
    pendingReviewCount: number,
    totalOverview: number,
    total: number,
    verifiedCountNew: number,
    pendingCountNew: number,
    verifiedInventoryItemCount: number,
    reviewRejectedV2Count: number,
    reviewRejectedCountNew: number,
  }
};

/**
 *
 * @returns {React.Node}
 */
const InventoryMappingProgressBar = ({
  categorizedPercentage: {
    verified,
    verifiedPercentage,
    pendingReview,
    pendingReviewV2Count,
    pendingReviewPercentage,
    newlyAdded,
    newlyAddedPercentage,
    unmapped,
    unmappedPercentage,
    total,
    pendingReviewCount,
    totalOverview,
    verifiedCountNew,
    pendingCountNew,
    verifiedInventoryItemCount,
    reviewRejectedV2Count,
    reviewRejectedCountNew,
  } = {},
}: Props) => {
  const data = {
    verified: {
      label: translate('verified'),
      value: verifiedPercentage,
      strokeColor: '#f8cecc',
    },
    pending: {
      label: translate('pending_review'),
      value: pendingReviewPercentage,
      strokeColor: '#dbe8fc',
    },
    newlyAdded: {
      label: translate('newly_added'),
      value: newlyAddedPercentage,
      strokeColor: '#d5e8d4',
    },
    unmapped: {
      label: translate('unmapped'),
      value: unmappedPercentage,
      strokeColor: 'white',
    },
  };

  /**
   * @param {string} name Legend name
   * @param {string} color Legend identifier
   * @param {string} description Legend description
   * @param {string} value value in fraction
   * @param {number} percentage Percentage
   * @returns {React.Node}
   */
  const renderLegend = ({
    label,
    strokeColor,
    description,
    overview,
    value,
  }: {
    label: string,
    strokeColor: string,
    description: string,
    overview: string,
    value: number
  }) => (
    <LegendInfo className="u-flex-row">
      <LegendIdentifier strokeColor={strokeColor} />
      <h6 className="u-font-bold u-font-bold u-text-uppercased">{label}</h6>
      <span>{` : ${overview} (${value || 0}%) -`}</span>
      <LegendDescription>&nbsp;{description}</LegendDescription>
    </LegendInfo>
  );

  const tooltipContent = (
    <div className="u-font-small">
      <div className="u-border--bottom">
        {renderLegend({ ...data.verified, overview: verified, description: translate('drugs_successfully_mapped') })}
        {renderLegend({ ...data.pending, overview: pendingReview, description: translate('drugs_reviewed_by_klinify_team') })}
        <List>
          <li><span className="u-font-bold u-text-uppercased">{translate('pending_review')}</span>: {pendingReviewV2Count}</li>
          <li><span className="u-font-bold u-text-uppercased">{translate('review_rejected')}</span>: {reviewRejectedV2Count}</li>
        </List>
        {renderLegend({ ...data.newlyAdded, overview: newlyAdded, description: translate('drugs_newly_added') })}
        <List>
          <li><span className="u-font-bold u-text-uppercased">{translate('pending_review')}</span>: {pendingCountNew}</li>
          <li><span className="u-font-bold u-text-uppercased">{translate('review_rejected')}</span>: {reviewRejectedCountNew}</li>
          <li><span className="u-font-bold u-text-uppercased">{translate('verified')}</span>: {verifiedCountNew}</li>
        </List>
        {renderLegend({ ...data.unmapped, overview: unmapped, description: translate('drugs_require_your_input') })}
      </div>
      <LegendInfo className="u-flex-row u-flex-align-items-center u-flex-space-between">
        <h6>
          <span data-public className="u-font-bold u-text-uppercased">
            {translate('overall')}
          </span>
          : {`${totalOverview}/${total}`}
        </h6>
        <ListItem>
          <span data-public className="u-font-bold">{translate('pending_review')}</span>:{' '}
          {pendingReviewCount}
        </ListItem>
        <ListItem>
          <span data-public className="u-font-bold">{translate('verified')}</span>:{' '}
          {verifiedInventoryItemCount}
        </ListItem>
      </LegendInfo>
    </div>
  );

  return (
    <Tooltip
      content={tooltipContent}
      direction="bottom-middle"
      tipContentHover
      background="#eeebf2"
      arrow={false}
    >
      <ProgressLine data={Object.values(data).flat()} />
    </Tooltip>
  );
};

export default InventoryMappingProgressBar;
