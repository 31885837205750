import BaseModel from './baseModel';

type Event = {
  status: 'pending'
    | 'order_generated'
    | 'order_printed'
    | 'sending'
    | 'sent_success'
    | 'sent_error'
    | 'acknowledged'
    | 'in_progress'
    | 'completed'
    | 'approved'
    | 'results_received'
    | 'rejected'
    | 'cancelled'
    | 'reflex'
    | 'mismatch',
  timestamp: number,
  notes: string,
};

type Events = Array<Event>;

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'procedure_status',
  procedure_request_id: string,
  procedure_type_id: string,
  patient_id: string,
  events: Events,
};

/**
  * ProcedureStatusModel
  * @namespace ProcedureStatusModel
  */
class ProcedureStatusModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'procedure_status';
  }

  /**
   * Adds an event with the given type to the ProcedureStatusModel.
   * @param {string} status The status of event.
   * @param {string} notes The notes for the event.
   * @returns {EncounterModel} Returns the updated encounter.
   */
  addEvent(status: string, notes: string = '') {
    const newEvents = this.get('events', []);
    newEvents.push({ status, notes, timestamp: new Date().getTime() });
    this.set('events', newEvents);
    return this;
  }

  /**
   * Gets the last event status. If no event is found then the default event status 'pending' is returned.
   * @returns {string} The last event type.
   */
  getLastEventStatus() {
    if (!this.has('events') || this.get('events', []).length === 0) {
      return 'pending';
    }
    return this.get('events')[this.get('events').length - 1].status;
  }

  /**
   * Returns a (untranslated) string that will be use in displaying procedure status in the table
   * eg. 'received_by_provider', 'accepted_by_provider', test_in_progress.
   * @returns {string}
   */
  getUiStatus() {
    switch (this.getLastEventStatus()) {
      case 'sent_success':
        return 'received_by_provider';
      case 'sent_error':
        return 'error_sending_order';
      case 'acknowledged':
        return 'accepted_by_provider';
      case 'in_progress':
        return 'test_in_progress';
      case 'completed':
        return 'test_completed_pending_approval';
      case 'approved':
        return 'test_approved_pending_results';
      case 'results_received':
        return 'results_received';
      case 'rejected':
        return 'order_rejected';
      case 'cancelled':
        return 'request_cancelled';
      case 'reflex':
        return 'reflex';
      case 'mismatch':
        return 'mismatch';
      case 'sending':
        return 'sending';
      default:
        return 'pending';
    }
  }

  /**
   * Returns true if status events has status of given param
   * @param {string} status Event status
   * @returns {boolean}
   */
  hasStatus(status: string) {
    return this.get('events', []).some(model => model.status === status);
  }
}

export default ProcedureStatusModel;
