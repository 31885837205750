import React, { useState } from 'react';
import { List, Map } from 'immutable';
import translate from '../../utils/i18n';
import { transformColumnKeys, transformFilteredColumns } from '../..//utils/utils';
import { renderListItem } from '../../utils/tables';
import ContentTransition from '../contentTransition';
import Header from '../header/header';
import Table from '../table/table';
import TableColumnsSettings from '../table/tableColumnsSettings';
import StageForm from './stageForm';
import Button from '../buttons/button';
import { UNICODE } from '../../constants';
import type EncounterStageModel from '../../models/encounterStageModel';
import type { Config, User, SaveModel } from '../../types';
import type SalesItemModel from '../../models/salesItemModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type EncounterModel from '../../models/encounterModel';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission } from '../../utils/permissions';


const COLUMNS = [
  { accessor: 'name', Header: translate('name') },
  { accessor: 'related_sales_items', Header: translate('related_sales_items'), filterable: false, sortable: false, Cell: renderListItem },
  { accessor: 'notes_section', Header: translate('notes_section'), sortable: false },
  { accessor: 'locations', Header: translate('locations'), filterable: false, Cell: renderListItem },
  { accessor: 'waiting_sound', Header: translate('x_sound', { x: translate('waiting') }), hideDefault: true },
  { accessor: 'inprogress_sound', Header: translate('x_sound', { x: translate('in_progress') }), hideDefault: true },
  { accessor: 'action', Header: '', sortable: false, width: 130 },
];

type Props = {
  config: Config;
  user: User;
  encounterStages: List<EncounterStageModel>;
  salesItems: Map<string, SalesItemModel>;
  updateConfig: (config: Config) => void;
  coveragePayors: List<CoveragePayorModel>;
  saveModel: SaveModel;
  currentEncounters: List<EncounterModel>;
}

/**
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const Stages = (props: Props) => {
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS).filter(c => !c.hideDefault));
  const [selectedStage, setSelectedStage] = useState<EncounterStageModel>();
  const [stageFormVisible, setStageFormVisible] = useState(false);

  /**
   * Reset states and closes Modal
   * @returns {void}
   */
  const hideModal = () => {
    setSelectedStage(undefined);
    setStageFormVisible(false);
  };

  const isStageFormVisible = Boolean(stageFormVisible || selectedStage);

  /**
   * Returns table data
   * @returns {Array<Row>}
   */
  const getStageRows = () => props.encounterStages.map(stage => ({
    name: stage.get('name'),
    related_sales_items: stage.getSalesItemNames(props.salesItems),
    notes_section: stage.hasNotes(),
    locations: stage.getLocations(),
    waiting_sound: stage.get('waiting_alert')?.replace(/\.[^/.]+$/, '') || UNICODE.EMDASH,
    inprogress_sound: stage.get('in_progress_alert')?.replace(/\.[^/.]+$/, '') || UNICODE.EMDASH,
    action: (
      <Button
        className="o-button o-button--small"
        onClick={() => setSelectedStage(stage)}
      >
        {translate('view')}
      </Button>
    ),
  })).toArray();

  const transformedColumnKeys = transformColumnKeys(COLUMNS);
  return (
    <ContentTransition>
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <h1 className="o-title">{translate('stages')}</h1>
        <div className="o-card u-margin-bottom--4ws">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{ translate('stages') }</h1>
            <div className="u-flex-right u-margin-right--half-ws">
              <TableColumnsSettings
                config={props.config}
                configFieldName="encounter_stages"
                originalColumns={List(transformedColumnKeys)}
                columns={transformedColumnKeys}
                onUpdateColumns={setColumns}
                updateConfig={props.updateConfig}
              />
            </div>
          </Header>
          <PermissionWrapper permissionsRequired={List([createPermission('stages', 'create')])} user={props.user}>
            <div className="o-header-actions">
              <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
                <button className="o-button o-button--small" onClick={() => setStageFormVisible(true)}>{translate('create_stage')}</button>
              </div>
            </div>
          </PermissionWrapper>
          <Table
            columns={transformFilteredColumns(COLUMNS, columns)}
            data={getStageRows()}
            noDataText="No stages found"
            defaultSorted={[{ id: 'name', desc: false }]}
          />
        </div>
      </section>
      {isStageFormVisible && <StageForm
        config={props.config}
        updateConfig={props.updateConfig}
        salesItems={props.salesItems}
        selectedStage={selectedStage}
        saveModel={props.saveModel}
        coveragePayors={props.coveragePayors}
        isVisible={isStageFormVisible}
        hideStageForm={hideModal}
        currentEncounters={props.currentEncounters}
        onDelete={() => setSelectedStage(undefined)}
        user={props.user}
      />}
    </ContentTransition>
  );
};

export default Stages;
