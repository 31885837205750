/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable require-jsdoc */
import React from 'react';
import glamorous from 'glamorous';
import moment from 'moment';
import type AppointmentModel from 'src/models/appointmentModel';
import type { AppointmentData, AppointmentStatus, Config } from '../../types';
import translate from '../../utils/i18n';
import Button from '../buttons/button';
import Header from '../header/header';
import { Appointment, ViewMode, FilterContainer, InputForm } from './appointments';
import Confirm from '../prompts/confirm';
import PatientModel from '../../models/patientModel';

export const iconStyle = {
  marginBottom: '-3px',
  marginRight: '0.5rem',
};

export const SectionForm = glamorous.div({
  marginBottom: '1rem',
  fontSize: '14px',
});

type Props = {
    readonly: boolean;
    config: Config;
    isSaving: boolean;
    appointmentModel: AppointmentModel;
    appointment: AppointmentData & Appointment;
    selectedPatientModel: PatientModel;
    setAppointmentMode: (mode: ViewMode) => void,
    onUpdateAppointment: (status: AppointmentStatus) => void,
  };

type State = {
  showPrompt: boolean
};

class ViewAppointment extends React.Component<Props, State> {
  /**
     * Creates an instance of TableColumnsSettings.
     * @param {Props} props props
     */
  constructor(props: Props) {
    super(props);
    this.state = {
      showPrompt: false,
    };
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const { showPrompt } = this.state;
    return (
      <div>
        <Header style={{ color: 'white', backgroundColor: '#CD386A' }} className="o-card__header" dataPublic>
          <h1 style={{ backgroundColor: '#CD386A' }} className="o-card__title">
            {translate('doctor_appointment')}
          </h1>
        </Header>
        <div className="o-card">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{translate('patient_details')} </h1>
          </Header>
          <section style={{ padding: '1.33rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {
              this.props.selectedPatientModel.get('patient_name') &&
              <SectionForm>
                <img style={iconStyle} alt="patient" src="static/images/icons_appointment_patient.png" />
                {this.props.selectedPatientModel.get('patient_name')}
              </SectionForm>
              }
              {
                this.props.selectedPatientModel.get('ic') &&
                <SectionForm>
                  <img style={iconStyle} alt="ic" src="static/images/icons_appointment_id.png" />
                  {this.props.selectedPatientModel.get('ic')}
                </SectionForm>
              }
              {
                this.props.selectedPatientModel.get('sex') &&
                <SectionForm>
                  <img style={iconStyle} alt="gender" src="static/images/icons_appointment_gender.png" />
                  {this.props.selectedPatientModel.get('sex')}
                </SectionForm>
              }
              {
                this.props.selectedPatientModel.get('tel') &&
                <SectionForm>
                  <img style={iconStyle} alt="phone" src="static/images/icons_appointment_phone.png" />
                  {this.props.selectedPatientModel.get('tel')}
                </SectionForm>
              }
              {
              this.props.selectedPatientModel.get('email') &&
              <SectionForm>
                <img style={iconStyle} alt="email" src="static/images/icons_appointment_email.png" />
                {this.props.selectedPatientModel.get('email')}
              </SectionForm>
              }
            </div>
          </section>
        </div>

        <div className="o-card">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{translate('booking_details')} </h1>
          </Header>
          <section style={{ padding: '1.33rem' }}>
            <SectionForm>
              <img style={iconStyle} alt="doctor" src="static/images/icons_appointment_doctor.png" />
              {this.props.appointment.practitionerName}
            </SectionForm>
            <FilterContainer>
              <SectionForm>
                <img style={iconStyle} alt="date" src="static/images/icons_appointment_date.png" />
                { moment(this.props.appointment.start_timestamp).format(this.props.config.get('date_format') ?? 'YYYY-MM-DD')}
              </SectionForm>
              <SectionForm style={{ marginLeft: '1.2rem' }}>
                <img style={iconStyle} alt="time" src="static/images/icons_appointment_time.png" />
                { moment(this.props.appointment.start_timestamp).format('LT')}
              </SectionForm>
            </FilterContainer>
            {
                this.props.appointment.duration &&
                <SectionForm>
                  <img style={iconStyle} alt="time" src="static/images/icons_appointment_duration.png" />
                  { `${this.props.appointment.duration} minutes` }
                </SectionForm>
              }
            <br />
            <h1 style={{ fontFamily: 'robotobold' }}>{translate('consult_mode')}</h1>
            <SectionForm>{this.props.appointmentModel.getConsultModeText()}</SectionForm>
            <h1 style={{ fontFamily: 'robotobold' }}>{translate('purpose_appointment')}</h1>
            <SectionForm>{this.props.appointment.purpose}</SectionForm>
            <h1 style={{ fontFamily: 'robotobold' }}>{translate('remarks')}</h1>
            <SectionForm>{this.props.appointment.notes || '--'}</SectionForm>
          </section>
        </div>


        <InputForm>
          { !this.props.readonly &&
          <>
            <Button
              isLoading={this.props.isSaving}
              style={{ marginBottom: '1rem', position: 'relative', backgroundColor: 'orange', color: 'white', border: '2px solid orange' }}
              className="o-button o-button--small"
              onClick={() => this.setState({ showPrompt: true })}
              dataPublic
            >
              {translate('cancel_appointment')}
            </Button>
            <Button
              style={{ marginBottom: '1rem' }}
              className="o-button o-button--small"
              onClick={() => this.props.setAppointmentMode('edit')}
              dataPublic
            >
              {translate('edit')}
            </Button>
          </>
          }
        </InputForm>
        <Confirm
          style={{ zIndex: 102 }}
          show={showPrompt}
          cancel={() => this.setState({ showPrompt: false })}
          proceed={() => {
            this.setState({ showPrompt: false }, () => this.props.onUpdateAppointment('cancelled'));
          }}
          confirmation={translate('cancel_appointment_confirm_message')}
          modalTitle={translate('warning')}
          footerSaveButtonName={translate('confirm')}
        />
      </div>

    );
  }
}
export default ViewAppointment;
