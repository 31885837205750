import type { List } from 'immutable';
import moment from 'moment';

import BaseModel from './baseModel';
import { DATE_FORMAT_TO_SAVE } from './../constants';

import type SupplyModel from './supplyModel';
import type SupplierModel from './supplierModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'supply_item',
  supply_id: string,
  sku_id: string,
  batch_id: string,
  quantity: number,
  total_price?: number,
  expiry_date?: string,
  date: string,
  notes?: string,
};

/**
   * SupplyItemModel
   * @namespace SupplyItemModel
   */
class SupplyItemModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'supply_item';
  }

  /**
   * Tries to get the Supply of this SupplyItem.
   * @param {List<SupplyModel>} supplies A List of Supplies
   * @returns {(SupplyModel | void)}
   */
  getSupply(supplies: List<SupplyModel>): SupplyModel | void {
    return supplies.find(s => s.get('_id') === this.get('supply_id'));
  }

  /**
   * Tries to get the Supplier of this SupplyItem.
   * @param {List<SupplyModel>} supplies A List of Supplies
   * @param {List<SupplierModel>} suppliers A List of suppliers
   * @returns {(SupplierModel | void)}
   */
  getSupplier(supplies: List<SupplyModel>, suppliers: List<SupplierModel>): SupplierModel | void {
    const supply = this.getSupply(supplies);
    return supply ? supply.getSupplier(suppliers) : undefined;
  }

  /**
   * Get Total Price Data of a supplyItem
   * @returns {number}
   */
  getPriceData() {
    return (Number(this.get('total_price')) || 0) * Math.sign(Number(this.get('quantity')));
  }

  /**
   * Check if this supply item is earlier than the other provided one by the given key
   * @param {SupplyItemModel} other The supply item model to compare to
   * @param {string} byKey the key of the attribute to compare with, SHOULD BE A DATE FIELD
   * @returns {boolean}
   */
  isEarlierThan(other: SupplyItemModel, byKey: string): boolean {
    if (!this.get(byKey)) {
      return false;
    }
    if (this.get(byKey) && !other.get(byKey)) {
      return true;
    }
    if (byKey === 'date' || byKey === 'expiry_date') {
      return moment(this.get(byKey), DATE_FORMAT_TO_SAVE)
        .isBefore(moment(other.get(byKey), DATE_FORMAT_TO_SAVE));
    }
    if (byKey === 'timestamp') {
      return this.get(byKey) < other.get(byKey);
    }
    throw new Error('Invalid byKey provided for Comparison. Must be a Date field key.');
  }
}

export default SupplyItemModel;
