import React from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';

import InventoryMapModel from './../../models/inventoryMapModel';
import translate from './../../utils/i18n';
import { wsUnit, colours } from './../../utils/css';
import EncounterModel from './../../models/encounterModel';
import MedicalCertificateModel from './../../models/medicalCertificateModel';
import IssuedItemsList from './../issuedItems/issuedItemsList';
import PrescriptionList from './../prescriptions/prescriptionList';
import { prettifyTime, prettifyDate } from './../../utils/time';
import ConsultationItemAction from './consultationItemAction';
import EncounterSummary from './encounterSummary';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasSomePermission } from './../../utils/permissions';
import LabRequestsList from './../labRequests/labRequestsList';
import DocumentDataFormContainer from './../../containers/documentDataFormContainer';
import StatelessModal from './../modals/statelessModal';
import documentPrintFactory from './../hoc/documentPrintFactory';
import SalesItemsList from '../salesItems/salesItemsList';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PatientModel from './../../models/patientModel';
import type AllergyModel from './../../models/allergyModel';
import type ConditionModel from './../../models/conditionModel';
import type DrugModel from './../../models/drugModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type { Config, SaveModel, SaveModels, User } from './../../types';
import type ProviderModel from './../../models/providerModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProcedureRequestModel from './../../models/procedureRequestModel';
import type SpecimenModel from './../../models/specimenModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type ProcedureResultModel from './../../models/procedureResultModel';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type BillItemModel from './../../models/billItemModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';

import type DocumentDataModel from './../../models/documentDataModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type PractitionerModel from '../../models/practitionerModel';
import type DosingRegimenModel from '../../models/dosingRegimenModel';

type Props = {
  patient: PatientModel,
  allergies: List<AllergyModel>,
  billItems: List<BillItemModel>,
  doctor: string,
  disableChanges: boolean,
  drugs: List<DrugModel>,
  collectedSpecimens: List<CollectedSpecimenModel>,
  procedureResults: List<ProcedureResultModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  encounter: EncounterModel,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  config: Config,
  klinifyConfig: Config,
  prescriptions: List<PrescriptionModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  isOnline: boolean,
  inventoryCount: Map<string, number>,
  showLowStockWarning: boolean,
  symptoms: List<ConditionModel>,
  diagnoses: List<ConditionModel>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  coveragePayors: List<CoveragePayorModel>,
  coveragePayorID?: string,
  user: User,
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
  specimens: List<SpecimenModel>,
  documentData: List<DocumentDataModel>,
  documentTemplates: List<DocumentTemplateModel>,
  onPrintTimeChitClicked: (timeChitModel: TimeChitModel | null | undefined) => void,
  onPrintMedicalCertificateClicked: (
    medicalCertificateModel: MedicalCertificateModel | null | undefined,
  ) => void,
  onPrintPrescriptionLabelsClicked: (filter: Function) => void,
  setDocumentPrintFactoryState: (arg: object) => void,
  printModalVisible: boolean,
  documentTemplateForPrint: DocumentTemplateModel | void,
  documentDataTitle: string | void,
  documentDataType: string | void,
  verifiedDrugs: List<InventoryMapModel>,
  practitioners: List<PractitionerModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  encounterFlows: List<EncounterFlowModel>,
  dosingRegimens: List<DosingRegimenModel>,
  patientPrescriptionHistory: List<PrescriptionModel>,
  encounters: List<EncounterModel>,
};

type State = {
  isLabRequestsFormModalVisible: boolean,
};

const ConsultationsHeader = glamorous.div({
  backgroundColor: colours.grey1,
  borderBottom: '1px solid #f0f0f0',
  width: '100%',
});

const ConsultationsTitle = glamorous.h2({
  padding: `calc(${wsUnit} / 2) ${wsUnit}`,
  textTransform: 'uppercase',
  fontFamily: 'robotomedium',
  display: 'inline-block',
  border: 'none',
});

/**
 * Creates a ConsultationHistoryItem that represents an individual Encounter and will be a child of
 * ConsultationHistory.
 * @param {object} props The props for the component.
 * @returns {React.PureComponent} ConsultationHistoryItem.
 */
class ConsultationHistoryItem extends React.PureComponent<Props, State> {
  /**
   *Creates an instance of ConsultationHistoryItem.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isLabRequestsFormModalVisible: false,
    };
  }

  props: Props

  /**
   * Handle modal visibility of Lab Request form modal
   * @param {boolean} state modal visibility
   * @returns {undefined}
   */
  handleLabRequestsFormModalVisibility = (state: boolean) => {
    this.setState({ isLabRequestsFormModalVisible: state });
  }

  /**
   * Renders the component.
   * @returns {React.PureComponent} The rendered component.
   */
  render() {
    const {
      encounter, medicalCertificates, salesItems, timeChits, doctor, documentData,
    } = this.props;
    const permissionPrefix = encounter.isCurrent() ? 'current_encounter' : 'past_encounters'; // Sets permission required based on type of encounter
    const actionProps = Object.assign({}, this.props, {
      documentTemplates: this.props.documentTemplates ? this.props.documentTemplates
        .filter(d => d.isVisible() && d.hasLocation('encounter')) : List(),
      onOrderLabTest: isVisible => this.setState({ isLabRequestsFormModalVisible: isVisible }),
    });
    return (
      <div>
        <StatelessModal
          id="printDocumentTemplate"
          noButton
          visible={this.props.printModalVisible}
          setVisible={(isVisible) => {
            this.props.setDocumentPrintFactoryState({ printModalVisible: isVisible });
          }}
          title={this.props.documentDataType ? translate(`print_${this.props.documentDataType}`) : ''}
          explicitCloseOnly
          // onClose={() => this.setState({ isPrinting: false })}
        >
          <DocumentDataFormContainer
            documentTemplate={this.props.documentTemplateForPrint}
            closeModal={() => this.props.setDocumentPrintFactoryState({
              printModalVisible: false,
              // isPrinting: false,
            })}
            encounterId={this.props.encounter.get('_id')}
            patientId={this.props.patient.get('_id')}
            practitionerId={this.props.encounter?.getValue('doctor')}
            customDocumentDataTitle={this.props.documentDataTitle}
          />
        </StatelessModal>
        <ConsultationsHeader>
          <ConsultationsTitle>
            {`${prettifyDate(encounter.getStartTime())} - ${prettifyTime(encounter.getStartTime())} - ${encounter.getEncounterType(salesItems)} - ${translate(encounter.getLastEventType())} - ${doctor}`}
          </ConsultationsTitle>
          <ConsultationItemAction {...actionProps} practitioners={this.props.practitioners} />
        </ConsultationsHeader>
        <EncounterSummary
          encounter={encounter}
          symptoms={this.props.symptoms}
          diagnoses={this.props.diagnoses}
          user={this.props.user}
          practitioners={this.props.practitioners}
          encounterStageMap={this.props.encounterStageMap}
        />
        {
          hasSomePermission(this.props.user, List([createPermission(`${permissionPrefix}_medical_certificates`, 'read'), createPermission(`${permissionPrefix}_time_chits`, 'read')])) &&
          medicalCertificates.size + timeChits.size + documentData.size > 0 &&
          <h2 data-public className="o-card__title o-card__title--white u-border--top">
            {translate('issued_items')}
          </h2>
        }
        {
          hasSomePermission(this.props.user, List([createPermission(`${permissionPrefix}_medical_certificates`, 'read'), createPermission(`${permissionPrefix}_time_chits`, 'read')])) &&
          medicalCertificates.size + timeChits.size + documentData.size > 0 &&
          <IssuedItemsList
            config={this.props.config}
            medicalCertificates={medicalCertificates}
            documentData={documentData}
            timeChits={timeChits}
            saveModel={this.props.saveModel}
            user={this.props.user}
            documentTemplates={this.props.documentTemplates}
            onPrintMC={this.props.onPrintMedicalCertificateClicked}
            onPrintTC={this.props.onPrintTimeChitClicked}
          />
        }
        <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_prescriptions`, 'read')])} user={this.props.user}>
          <PrescriptionList
            useTextButton
            coveragePayorID={this.props.coveragePayorID}
            coveragePayors={this.props.coveragePayors}
            config={this.props.config}
            practitioners={this.props.practitioners}
            klinifyConfig={this.props.klinifyConfig}
            drugs={this.props.drugs}
            encounter={this.props.encounter}
            encounters={this.props.encounters}
            prescriptions={this.props.prescriptions}
            dosingRegimens={this.props.dosingRegimens}
            saveModel={this.props.saveModel}
            saveModels={this.props.saveModels}
            isOnline={this.props.isOnline}
            inventoryCount={this.props.inventoryCount}
            showLowStockWarning={this.props.showLowStockWarning}
            user={this.props.user}
            onPrint={this.props.onPrintPrescriptionLabelsClicked}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            verifiedDrugs={this.props.verifiedDrugs}
            allergies={this.props.allergies}
            patientPrescriptionHistory={this.props.patientPrescriptionHistory}
          />
        </PermissionWrapper>
        <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_sales_items`, 'read')])} user={this.props.user}>
          <SalesItemsList
            disableChanges={this.props.disableChanges}
            billItems={this.props.billItems.filter(i => i.isSalesItem())}
            salesItems={this.props.salesItems}
            config={this.props.config}
            inventoryCount={this.props.inventoryCount}
            coveragePayors={this.props.coveragePayors}
            saveModel={this.props.saveModel}
            saveModels={this.props.saveModels}
            user={this.props.user}
            patientID={this.props.encounter.get('patient_id')}
            billID={this.props.encounter.get('bill_id')}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            useTextButton
            isFromEncounterHistory
          />
        </PermissionWrapper>
        <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_lab_tests`, 'read')])} user={this.props.user}>
          <LabRequestsList
            providers={this.props.providers}
            procedureTypes={
                this.props.procedureTypes
              }
            procedureRequests={this.props.procedureRequests}
            procedureStatuses={this.props.procedureStatuses}
            collectedSpecimens={this.props.collectedSpecimens}
            procedureResults={this.props.procedureResults}
            specimens={this.props.specimens}
            saveModel={this.props.saveModel}
            saveModels={this.props.saveModels}
            user={this.props.user}
            config={this.props.config}
            patientID={this.props.encounter.get('patient_id')}
            patientName={this.props.patient.get('patient_name')}
            encounterID={this.props.encounter.get('_id')}
            isLabRequestsFormModalVisible={this.state.isLabRequestsFormModalVisible}
            handleLabRequestsFormModalVisibility={this.handleLabRequestsFormModalVisibility}
            isFromEncounterHistory
            updateConfig={this.props.updateConfig}
            encounter={this.props.encounter}
          />
        </PermissionWrapper>
      </div>
    );
  }
}

export default documentPrintFactory(ConsultationHistoryItem);
