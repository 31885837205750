import React from 'react';

type Props = {
  when: boolean,
};

/**
 * Displays navigation confirmation.
 * @class PreventReload
 * @extends {React.Component<Props, State>}
 */
class PreventReload extends React.Component<Props, {}> {
  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.checkEventListener();
  }

  /**
   * Called when props changed
   * @returns {void}
   */
  componentDidUpdate() {
    this.checkEventListener();
  }

  /**
   * Called when closing the component
   * @returns {void}
   */
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleReloadPage);
  }

  /**
   * Check when to add event listener
   * @returns {void}
   */
  checkEventListener() {
    if (this.props.when) {
      window.addEventListener('beforeunload', this.handleReloadPage);
    }
  }

  /**
   * Handle reload event
   * @param {BeforeUnloadEvent} event The event
   * @returns {string}
   */
  handleReloadPage(event: BeforeUnloadEvent) {
    return Object.assign(event, { returnValue: 'Changes you made may not be saved.' });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return '';
  }
}

export default PreventReload;
