import React from 'react';
import type { List } from 'immutable';
import glamorous from 'glamorous';
import { sum } from 'lodash';

import { DerivedDataObject } from 'react-table';
import translate from './../../utils/i18n';
import { convertNumberToPrice } from './../../utils/utils';
import Table from './../table/table';
import { renderWithLink, renderDateWithLink, renderPriceWithLink } from './../../utils/tables';
import LabTestSubItems from './../labTests/labTestSubItems';

import type { MapValue, Row, TrProps } from './../../types';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type EncounterModel from '../../models/encounterModel';

type Props = {
  columns: Array<{value: string, label: string}>,
  isFetching?: boolean,
  data: List<{}>,
  labRequestsTotal?: string,
  getTrProperties?: (labRequestID: string) => void,
  procedureTypes: List<ProcedureTypeModel>,
  encounter?: EncounterModel,
};

const TableContainer = glamorous.div({
  display: 'grid',
},
({ isOnLabequestsPage }) => ({
  '& .rt-tr': {
    cursor: isOnLabequestsPage ? 'pointer' : 'initial',
  },
}));

/**
 * A table for displaying lab orders and their statuses
 * @class LabRequestsTable
 * @extends {React.Component<Props, {}>}
 */
class LabRequestsTable extends React.Component<Props, {}> {
  getTrProperties: (rowInfo: MapValue) => TrProps;

  /**
   * Creates an instance of LabRequestsTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.getTrProperties = this.getTrProperties.bind(this);
  }

  /**
   * Row properties with on click handler function to pass to table component,
   * this will set state values needed to display row info modal
   * @param {MapValue} state state of ReactTable.
   * @param {MapValue} rowInfo info object about the row clicked, along with data sent to table
   * @returns {TrProps} properties to be used for row
   */
  getTrProperties(state: MapValue, rowInfo: MapValue): TrProps {
    return {
      onClick: (event: SyntheticInputEvent<*>) => {
        // check for action button click
        event.stopPropagation();
        if (event.target && event.target.className &&
          (
            event.target.className.indexOf('o-button') < 0 &&
            event.target.className.indexOf('rt-expander') < 0 &&
            event.target.className.indexOf('rt-td') < 0 &&
            event.target.className.indexOf('rt-tr') < 0 &&
            event.target.className.indexOf('rt-expandable') < 0)
        ) {
          if (this.props.getTrProperties) {
            this.props.getTrProperties((rowInfo && rowInfo.original && rowInfo.original.id
              ? rowInfo.original.id : ''));
          }
        }
      },
      'sub-items-count': rowInfo.original.procedure_type_sub_items ?
        this.props.procedureTypes.filter(procedureType => rowInfo.original.procedure_type_sub_items.includes(procedureType.get('_id'))).size :
        0,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const isOnLabequestsPage = (location.hash === '#/lab-requests');
    return (
      <TableContainer isOnLabequestsPage={isOnLabequestsPage}>
        {
          this.props.data.size === 0 ?
            <p className="u-padding--1ws u-flex-centre">{translate('no_lab_tests_ordered')}</p>
            :
            <Table
              columns={this.props.columns.map((c) => {
                if (c.value === 'date' || c.value === 'procedure_name') {
                  return {
                    accessor: c.value,
                    Header: c.label,
                    Cell: renderDateWithLink,
                    filterable: (c.value !== 'date' && isOnLabequestsPage) || false,
                    Footer: (!isOnLabequestsPage) ? (
                      <span data-public style={{ marginLeft: 10, alignSelf: 'center' }}>
                        <strong>{translate('total')}</strong>
                      </span>
                    ) : '',
                  };
                }
                if (c.value === 'specimen_type') {
                  return {
                    accessor: c.value,
                    Header: c.label,
                    Cell: renderDateWithLink,
                    style: { textTransform: 'capitalize' },
                  };
                }
                if (c.value === 'sale_price') {
                  return {
                    accessor: c.value,
                    Header: c.label,
                    Cell: renderPriceWithLink,
                    Footer: ({ data }: { data: Array<DerivedDataObject>}) =>
                      ((!isOnLabequestsPage) && (data.length > 0) ? (
                        <span style={{ alignSelf: 'center' }}>
                          <strong>{
                            convertNumberToPrice(sum(data.map((d: DerivedDataObject) =>
                              // eslint-disable-next-line no-underscore-dangle
                              Number((d._original as Row).sale_price) || 0) as Array<Number>))
                          }
                          </strong>
                        </span>
                      ) : ''),
                  };
                }
                if (c.value === 'status') {
                  return {
                    accessor: c.value,
                    Header: c.label,
                    sortable: false,
                  };
                }
                if (c.value === 'procedure_code') {
                  return {
                    accessor: c.value,
                    Header: c.label,
                    filterable: isOnLabequestsPage,
                  };
                }
                if (c.value === 'actions' || c.value === 'last_update') {
                  return {
                    accessor: c.value,
                    Header: c.label,
                    filterable: false,
                  };
                }
                return {
                  accessor: c.value,
                  Header: c.label,
                  Cell: renderWithLink,
                  filterable: isOnLabequestsPage,
                };
              })}
              loading={this.props.isFetching}
              data={this.props.data.toArray()}
              defaultSorted={[{ id: 'date', desc: true }]}
              noDataText={translate('no_items')}
              showPagination
              trProperties={this.getTrProperties}
              subComponent={(row) => {
                const hasSubItems = row.original.procedure_type_sub_items &&
                  row.original.procedure_type_sub_items.length;
                if (row && hasSubItems) {
                  return (
                    <LabTestSubItems
                      procedureTypes={this.props.procedureTypes}
                      subItems={row.original.procedure_type_sub_items}
                    />
                  );
                }
                return null;
              }
              }
            />
        }
      </TableContainer>
    );
  }
}

export default LabRequestsTable;
