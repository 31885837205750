import React, { Component, Fragment } from 'react';
import { List } from 'immutable';

import { LAB } from './../../constants';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import SaveButton from './../buttons/saveButton';
import SavePrompt from './../prompts/savePrompt';
import ProcedureRequestModel from './../../models/procedureRequestModel';
import ModalFooter from './../modals/modalFooter';
import Keypress from './../keypress';
import Select from './../inputs/select';
import { colours } from './../../utils/css';
import StatelessModal from './../modals/statelessModal';

import type { SaveModel } from './../../types';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type SpecimenModel from './../../models/specimenModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';

type Props = {
  saveModel: SaveModel,
  procedureRequest: ProcedureRequestModel,
  specimens: List<SpecimenModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  collectedSpecimens: List<CollectedSpecimenModel>,
  procedureTypes: List<ProcedureTypeModel>,
  handleFormVisibility: () => void,
};

type State = {
  isSaving: boolean,
  currentStatus: string,
  changesMade: boolean,
  reminderModalVisible: boolean,
};

/**
 * A form for creating or editing ProcedureRequest(labRequest).
 * @class LabTestsForm
 * @extends {React.Component<Props, State>}
 */
class LabRequestsForm extends Component<Props, State> {
  state: State;

  /**
   * Creates an instance of SupplierForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      currentStatus: props.procedureRequest.getStatusLastEventStatus(props.procedureStatuses),
      changesMade: false,
      reminderModalVisible: false,
    };
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}  A promise with a value of true if successful and false if failed.
   */
  onSaveClicked = (): Promise<boolean> => {
    this.setState({ isSaving: true });
    this.saveProcedureStatus().then(() => {
      this.setState({ isSaving: false });
      this.props.handleFormVisibility();
    });
    return Promise.resolve(true);
  }

  /**
   * Called to save the procedure status.
   * @returns {Promise<void>}
   */
  saveProcedureStatus = (): Promise<void> => {
    const { procedureRequest, procedureStatuses, saveModel } = this.props;
    const procedureStatus = procedureRequest.getStatus(procedureStatuses);
    procedureStatus.addEvent(this.state.currentStatus);
    return saveModel(procedureStatus)
      .then(() => {
        createSuccessNotification('Order status has been updated.');
      });
  }

  /**
   * handles the save button click.
   * @param {boolean} hasProcedureStatusDoc has procedure status doc.
   */
  handleSaveButtonClick = (hasProcedureStatusDoc: boolean) => {
    if (this.state.currentStatus === 'cancelled') {
      this.setState({ reminderModalVisible: true });
      this.saveProcedureStatus();
    } else if (!hasProcedureStatusDoc) {
      this.props.handleFormVisibility();
    } else {
      this.onSaveClicked();
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      procedureRequest, procedureStatuses,
      procedureTypes, collectedSpecimens, specimens,
    } = this.props;
    const isCollectedSpecimensHasStatusCourierCollected = procedureRequest.isCollectedSpecimensHasStatus('courier_collected', procedureTypes, collectedSpecimens);
    const labRequestStatusOptions = LAB.STATUS_OPTIONS.filter((option) => {
      if (!procedureRequest.getTypeIntegrations(procedureTypes)) { // flow 3 - no integrations
        const noIntegrationStatuses = ['rejected', 'cancelled'];
        if (isCollectedSpecimensHasStatusCourierCollected) {
          noIntegrationStatuses.push('results_received');
        }
        return noIntegrationStatuses.includes(option);
      }
      if (option === 'pending' ||
        option === 'sending' ||
        (!isCollectedSpecimensHasStatusCourierCollected && option === 'results_received')) {
        return false;
      }
      return option;
    });
    const hasProcedureStatusDoc = !!procedureRequest.getStatus(procedureStatuses);
    return (
      <Fragment>
        <StatelessModal
          id="reminder"
          buttonLabel=""
          buttonClass=""
          noButton
          title={translate('reminder')}
          setVisible={isVisible => this.setState({ reminderModalVisible: isVisible })}
          visible={this.state.reminderModalVisible}
          explicitCloseOnly
          onClose={() => {}}
        >
          <div className="u-margin--standard">
            {translate('please_contact_your_vendor_directly_to_cancel_this_order')}
          </div>
          <ModalFooter>
            <Fragment>
              <SaveButton
                onClick={() => this.props.handleFormVisibility()}
                isSaving={this.state.isSaving}
                label={translate('okay')}
                className="o-button--small u-margin-right--half-ws"
              />
            </Fragment>
          </ModalFooter>
        </StatelessModal>
        <Keypress className="o-form" onEnterPressed={() => this.handleSaveButtonClick(hasProcedureStatusDoc)}>
          <SavePrompt
            when={this.state.changesMade}
            onSaveClicked={() => this.handleSaveButtonClick(hasProcedureStatusDoc)}
          />
          <div className="u-margin-bottom--1ws">
            <p className="u-strong">{translate('specimen_requirements')}</p>
            { specimens.size > 0 ? specimens.map(specimen => <div> - {specimen.get('name')} </div>) : translate('none')}
          </div>
          <Select
            id="status"
            label={translate('status')}
            onValueChanged={status => this.setState({ currentStatus: status, changesMade: true })}
            options={labRequestStatusOptions.map(value => ({ value, label: translate(value) }))}
            value={this.state.currentStatus}
            disabled={!hasProcedureStatusDoc}
          />
          {(hasProcedureStatusDoc && procedureRequest.getTypeIntegrations(procedureTypes)) && this.state.currentStatus === 'cancelled' &&
            <p style={{ color: colours.red }}>
              {translate('please_contact_your_vendor_directly_to_cancel_this_order')}
            </p>}
        </Keypress>
        <ModalFooter
          style={{ position: 'absolute', width: '-webkit-fill-available' }}
        >
          <Fragment>
            <SaveButton
              dataPublic
              onClick={() => this.handleSaveButtonClick(hasProcedureStatusDoc)}
              isSaving={this.state.isSaving}
              label={translate(!hasProcedureStatusDoc ? 'close' : 'save')}
              className="o-button--small u-margin-right--half-ws"
              disabled={hasProcedureStatusDoc && !this.state.changesMade}
            />
          </Fragment>
        </ModalFooter>
      </Fragment>
    );
  }
}

export default LabRequestsForm;
