import React from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';

import translate from './../../utils/i18n';
import { wsUnit, colours } from './../../utils/css';
import { updateClinicConfig } from './../../utils/db';
import { createSuccessNotification } from './../../utils/notifications';
import { getConsultHistoryPermissions } from './../../utils/permissions';
import { columnsForPermissionCheck } from './consultationHistoryTable';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import Checkbox from './../inputs/checkbox';
import Select from './../inputs/select';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';

import type { Config, MapValue, User } from './../../types';

type Props = {
  config: Config,
  onSave: (config: Config) => void,
  user: User,
  updateConfig: (config: Config) => void,
};

type State = {
  settingsModalVisisble: boolean,
  config: Config,
  configUpdates: Partial<Config>,
  errorMessage?: string,
  user?: User,
};

const EncountersSettings = glamorous.div({
  width: '36px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: `calc(${wsUnit} / 6) ${wsUnit} 0 calc(${wsUnit} / 2)`,
  cursor: 'pointer',
  borderRadius: '4px',
  backgroundColor: colours.grey1,
  '& img': {
    ':last-child': {
      display: 'none',
    },
  },
  ':hover': {
    '& img': {
      ':last-child': {
        display: 'block',
      },
      ':first-child': {
        display: 'none',
      },
    },
  },
});

const COLUMNS = List([
  'date_and_time',
  'encounter_type',
  'encounter_status',
  'doctor',
  'symptoms',
  'diagnoses',
  'notes',
  'rx_summary',
  'rx_name',
  'rx_dosage',
  'rx_reason',
  'rx_notes',
  'rx_price',
  'rx_qty',
  'rx_total',
  'mc_time_chit',
  'documents',
  'dispense_summary',
  'dispense_price',
  'dispense_total',
  'sales_item_summary',
  'sales_item_price',
  'sales_item_total',
  'lab_test',
  'lab_test_id',
  'lab_vendor',
  'lab_sale_price',
  'lab_results',
  'total_fee',
]);

const DefaultViews = ['single', 'multiple'];

const DefaultPageSizes = [5, 10, 20, 25, 50, 100];

type LabelValue = { label: string, value: string };
/**
 * A component displaying the consultation history settings modal from patient page.
 * @class ConsultationHistorySettings
 * @extends {React.Component}
 */
class ConsultationHistorySettings extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of ConsultationHistorySettings.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      settingsModalVisisble: false,
      config: this.props.config,
      configUpdates: Map(),
    };
  }

  /**
   * Reset values when config value changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.config.equals(nextProps.config)) {
      this.setState({
        config: nextProps.config,
      });
    }
  }

  /**
   * Updates settings/config selection status in local state.
   * @param {string[]} keyArray An array of keys to the value.
   * @param {MapValue} newValue The new value.
   * @returns {void}
   */
  updateConfig(keyArray: Array<string>, newValue: MapValue): void {
    this.setState({
      // @ts-ignore
      config: this.state.config.setIn(keyArray, newValue),
      configUpdates: this.state.configUpdates.setIn(keyArray, newValue),
    });
  }

  /**
   * Saves the encounter history settings updated by user
   * @returns {void}
   */
  handleSave(): void {
    if (!this.state.config.getIn(['encounters', 'history_section', 'default_view_selected'])
       || !this.state.config.getIn(['encounters', 'history_section', 'default_page_size_selected'])) {
      this.setState({ errorMessage: translate('default_view_and_page_size_cannot_be_empty') });
    } else {
      updateClinicConfig(
        this.props.config.toJS(),
        this.state.configUpdates.toJS(),
        this.props.updateConfig,
      )
        .then(() => {
          createSuccessNotification(translate('settings_updated'));
          this.props.onSave(this.state.config);
          this.resetStateConfig();
        });
      this.setState({ settingsModalVisisble: false });
    }
  }

  /**
   * Hides the modal when cancel is clicked.
   * @returns {void}
   */
  handleCancel(): void {
    this.setState({ settingsModalVisisble: false, errorMessage: undefined });
    this.resetStateConfig();
  }

  /**
   * Reset state config
   * @returns {void}
   */
  resetStateConfig() {
    if (!this.props.config.equals(this.state.config)) {
      this.setState({ config: this.props.config, configUpdates: Map() });
    }
  }

  /**
   * checks if the columns is something that does not need any permission check, returns true if yes.
   * @param {string} column column to check
   * @returns {boolean}
   */
  isColumnWithoutPermissionNeeded(column: string): boolean {
    return (
      !columnsForPermissionCheck.viewPrescriptions.contains(column) &&
          !columnsForPermissionCheck.viewConditions.contains(column) &&
            !columnsForPermissionCheck.viewNotes.contains(column) &&
              !columnsForPermissionCheck.viewBill.contains(column) &&
                !columnsForPermissionCheck.viewMcTc.contains(column) &&
                  !columnsForPermissionCheck.viewLabConditions.contains(column)
    );
  }

  /**
   * Does a permission check and returns columns as options accordingly.
   * @returns {Array<LabelValue>}
   */
  getColumnsAsOptions(): Array<LabelValue> {
    const consultHistoryPermissions = getConsultHistoryPermissions(this.props.user);
    return COLUMNS.filter(column => (
      (consultHistoryPermissions.hasPrescriptionViewPermission &&
        columnsForPermissionCheck.viewPrescriptions.contains(column)) ||
        (consultHistoryPermissions.hasConditionsViewPermission &&
          columnsForPermissionCheck.viewConditions.contains(column)) ||
          (consultHistoryPermissions.hasNotesViewPermission &&
            columnsForPermissionCheck.viewNotes.contains(column)) ||
            (consultHistoryPermissions.hasBillViewPermission &&
              columnsForPermissionCheck.viewBill.contains(column)) ||
              (consultHistoryPermissions.hasMcTcViewPermission &&
                columnsForPermissionCheck.viewMcTc.contains(column)) ||
                (consultHistoryPermissions.hasLabViewPermission &&
                  columnsForPermissionCheck.viewLabConditions.contains(column)) ||
                  this.isColumnWithoutPermissionNeeded(column)
    ))
      .map(col => ({ label: translate(col), value: col })).toArray();
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <EncountersSettings
        onClick={() => {
          this.setState({ settingsModalVisisble: true });
        }}
      >
        <img src="static/images/icon_settings.svg" alt={translate('settings')} />
        <img src="static/images/icon_settings_hover.svg" alt={translate('settings')} />
        <StatelessModal
          noButton
          id="encounter-history-settings"
          buttonLabel=""
          buttonClass=""
          title={translate('encounter_history_settings')}
          visible={this.state.settingsModalVisisble}
          setVisible={isVisible => this.setState({ settingsModalVisisble: isVisible })}
          explicitCloseOnly
          onClose={() => this.handleCancel()}
          dataPublicHeader
        >
          { this.state.errorMessage &&
          <div className="u-margin--standard">
            <FormError containerElementID="addEditAllergyForm">{this.state.errorMessage}</FormError>
          </div>
        }
          <section className="o-form" id="encounterHistorySettings">
            <Select
              id="default_view"
              clearable={false}
              label={translate('default_view')}
              onValueChanged={defaultViewSelected => this.updateConfig(['encounters', 'history_section', 'default_view_selected'], defaultViewSelected)}
              options={DefaultViews.map(value => ({ value, label: translate(value) }))}
              value={this.state.config.getIn(['encounters', 'history_section', 'default_view_selected'])}
            />
            <hr />
            <Select
              id="default_page_size"
              clearable={false}
              label={translate('default_row_number')}
              onValueChanged={defaultPageSizeSelected => this.updateConfig(['encounters', 'history_section', 'default_page_size_selected'], defaultPageSizeSelected)}
              options={DefaultPageSizes.map(value => ({ value, label: value.toString() }))}
              value={this.state.config.getIn(['encounters', 'history_section', 'default_page_size_selected'])}
            />
            <hr />
            <Checkbox
              id="columns-to_display"
              onValueChanged={(value, isChecked) =>
                this.updateConfig(['encounters', 'history_section', 'columns', value], isChecked)
            }
              options={
                this.getColumnsAsOptions()
            }
              value={
              Object.keys(
                this.state.config.getIn(['encounters', 'history_section', 'columns'], Map())
                  .filter(value => value === true)
                  .toJS(),
              )
            }
              label={translate('columns_to_be_displayed')}
              multiColumn
            />
          </section>
          <ModalFooter>
            <SaveButton
              dataPublic
              onClick={() => this.handleSave()}
              label={translate('save')}
              className="o-button--small u-margin-right--half-ws"
            />
          </ModalFooter>
        </StatelessModal>
      </EncountersSettings>
    );
  }
}

export default ConsultationHistorySettings;
