import React, { useEffect, useState } from 'react';
import glamorous from 'glamorous';
import ReactMarkdown from 'react-markdown';
import { borderRadius, colours, fontSize, wsUnit } from '../../utils/css';
import { getLatestChangelog } from '../../utils/changelog';
import translate from '../../utils/i18n';

const Card = glamorous.div({
  borderRadius: `${borderRadius}`,
  width: '80%',
  maxWidth: '560px',
  maxHeight: '45%',
  boxSizing: 'border-box',
});

const CardHeading = glamorous.div({
  backgroundColor: `${colours.grey8}`,
  color: 'white',
  fontSize: fontSize.large,
  fontWeight: 'bold',
  textAlign: 'center',
  paddingTop: `${wsUnit}`,
  paddingBottom: `calc(0.5*${wsUnit})`,
  borderRadius: `${borderRadius} ${borderRadius} 0 0`,
  boxSizing: 'border-box',
});

const CardSubheading = glamorous.p({
  fontSize: fontSize.small,
  marginTop: `calc(0.5*${wsUnit})`,
});

const CardContent = glamorous.div({
  fontSize: fontSize.large,
  backgroundColor: '#fff',
  padding: `${wsUnit}`,
  maxHeight: '80%',
  overflow: 'auto',
  borderRadius: `0 0 ${borderRadius} ${borderRadius}`,
  boxSizing: 'border-box',
});

/**
 * Whats new component displays latest app changelog.
 * @return {jsx} - HTML markup for the component
 */
export default function WhatsNew() {
  const [changelogData, setChangelogData] = useState<string | void>('');
  const [changelogVersion, setChangelogVersion] = useState('');
  useEffect(() => {
    setChangelogVersion(getLatestChangelog().version);
    getLatestChangelog().data.then(res => setChangelogData(res)).catch(null);
  }, []);
  return (
    <Card>
      <CardHeading>{translate('whats_new')}<br />
        <CardSubheading>{changelogData ? `${translate('current_version')}: ${changelogVersion.split('-').join('.')}` : ''}</CardSubheading>
      </CardHeading>
      <CardContent>
        {changelogData ? <ReactMarkdown
          source={changelogData as string}
          className="c-markdown u-margin--standard"
          transformImageUri={uri => `static/changelogs/${uri}`}
        /> : 'Whoops! Failed to load latest release notes.' }
      </CardContent>
    </Card>
  );
}
