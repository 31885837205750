import { queryDesignDoc } from './db';
import { handleResponseError } from './utils';
import { getUserName as _getUserName } from "./user";
import { DDOC_VERSION } from './../constants';

import type { CouchResponse } from './../types';

/**
 * Gets the username and returns it after converting to uppercase. 'NOT_LOGGED_IN' is returned if
 * the username is undefined, though this really shouldn't be possible.
 * @returns {string} username
 */
export function getUserName(): string {
  const username = _getUserName();
  return username ? username.toUpperCase() : 'NOT_LOGGED_IN';
}

/**
 * Returns the last used serial number.
 * @param {string} docType The document type (bill, medical_certificate etc.)
 * @returns {Promise} A promise for the last used serial number.
 */
function getLastSerialNumber(docType: string): Promise<string | void> {
  return queryDesignDoc(`${DDOC_VERSION}/lastSerialNumberByType`, { key: docType })
    // Return 0 if no rows as it means theres no matches in DB.
    .then((response: CouchResponse): number =>
      (response.rows.length ? response.rows[0].value.max : 0))
    .catch((error) => {
      handleResponseError(error);
    });
}

/**
 * Returns a serial number derived from the locally stored last serial number.
 * @param {string} type The type of serial number (MC or VR).
 * @returns {string} An offline serial number.
 */
export function getOfflineSerialNumber(type: string): string {
  return `${type}-0-OFFLINE-${getUserName()}-${new Date().getTime()}`;
}

/**
 * Gets a new MC Serial number
 * @returns {Promise} A Promise for a MC serial number.
 */
export function getMCSerialNumber(): Promise<string> {
  return getLastSerialNumber('medical_certificate')
    .then((lastSerialNumber: string): string => `MC-${parseInt(lastSerialNumber, 10) + 1}`)
    .catch((): string => getOfflineSerialNumber('MC'));
}

/**
 * Gets a new Bill Serial number
 * @returns {Promise} A Promise for a bill serial number.
 */
export function getBillSerialNumber(): Promise<string> {
  return getLastSerialNumber('bill')
    .then((lastSerialNumber: string): string => `VR-${parseInt(lastSerialNumber, 10) + 1}`)
    .catch((): string => getOfflineSerialNumber('VR')); // VR = Visit Receipt
}

/**
 * Gets a new Time Chit Serial number
 * @returns {Promise} A Promise for a Time Chit serial number.
 */
export function getTimeChitSerialNumber(): Promise<string> {
  return getLastSerialNumber('time_chit')
    .then((lastSerialNumber: string): string => `TC-${parseInt(lastSerialNumber, 10) + 1}`)
    .catch((): string => getOfflineSerialNumber('TC'));
}
