import { connect } from 'react-redux';

import AddVitals from './../components/vitals/addVitals';
import { saveFactory } from './../utils/redux';

import type { State, Dispatch } from './../types';

type Props = {
  patientID: string,
  metricTypeID?: string,
};

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ metricTypes, isOnline }: State, { metricTypeID, patientID }: Props) => ({
  metricTypes: metricTypes.filter(p => p.isVisible()),
  patientID,
  metricTypeID,
  isOnline,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const AddVitalsContainer = connect(mapStateToProps, mapDispatchToProps)(AddVitals);

export default AddVitalsContainer;
