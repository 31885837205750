import React from 'react';
import type { List } from 'immutable';

import translate from './../../utils/i18n';
import Table from './../table/table';
import { getConfirmation } from './../../utils/utils';
import { createSuccessNotification } from './../../utils/notifications';
import Button from './../buttons/button';

import type { SaveModel } from './../../types';
import type MetricTypeModel from './../../models/metricTypeModel';

type Props = {
  metricTypes: List<MetricTypeModel>,
  saveModel: SaveModel
};

const columns = [
  { accessor: 'name', Header: translate('name') },
  {
    accessor: 'delete', Header: '', sortable: false, width: 100, show: true,
  },
];

/**
 * A table displaying all MetricTypes
 * @class MetricTypesTable
 * @extends {React.PureComponent<Props> }
 */
class MetricTypesTable extends React.PureComponent<Props> {
  /**
   * Called when delete is clicked on a vital/metric type. Updates its state to be hidden.
   * @param {MetricTypeModel} item The item to delete
   * @returns {void}
   */
  onDeleteClicked(item: MetricTypeModel) {
    if (item) {
      getConfirmation(translate('confirm_vital_deletion'))
        .then(
          () => this.props.saveModel(item.setVisible(false))
            .then(() => {
              createSuccessNotification(translate('vital_deleted'));
            }),
          () => {},
        );
    }
  }

  /**
   * Returns a Delete button for the given vital row.
   * @param {MetricTypeModel} item An item for the delete button
   * @returns {React.Component} A Delete button component.
   */
  getDeleteButton(item: MetricTypeModel) {
    return (
      <Button
        className="o-text-button o-text-button--danger"
        onClick={() => this.onDeleteClicked(item)}
        dataPublic
      >
        {translate('delete')}
      </Button>
    );
  }

  /**
   * Converts a metricTypeModel to a table row.
   * @param {MetricTypeModel} metricType A metricType
   * @returns {Row}
   */
  getRow(metricType: MetricTypeModel) {
    return {
      name: metricType.get('name'),
      delete: this.getDeleteButton(metricType),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Table
        columns={columns}
        data={this.props.metricTypes.map(mType => this.getRow(mType)).toArray()}
        noDataText={translate('no_vitals_created')}
      />
    );
  }
}

export default MetricTypesTable;
