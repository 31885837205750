import React from 'react';
import { List } from 'immutable';
import moment from 'moment';

import type { Moment } from 'moment';

import Select from './../inputs/select';
import DatePicker from './../inputs/statefulDatepicker';
import SupplierModel from '../../models/supplierModel';
import { MapValue } from '../../types';
import translate from '../../utils/i18n';
import Input from './../inputs/input';
import TextArea from '../inputs/textarea';
import { getDateFormat } from '../../utils/time';

/* eslint-disable camelcase */
type Attributes = {
  supplier_id: string,
  date: Moment,
  invoice_number?: string,
  delivery_order_number?: string,
  purchase_order_number?: string,
  doc_number?: string,
  notes?: string,
}
/* eslint-enable camelcase */

type Props = {
  suppliers: List<SupplierModel>,
  action: string,
  onUpdateAttribute: (key: string, value: MapValue) => void,
  attributes: Attributes,
  isSupplyInputNotAccepted?: boolean,
}


/**
 * A component containing a form for adding a Supply.
 * @param {Props} props component props
 * @returns {React.ElementType}
 */
function AddToInventorySupplyFormItem(props: Props) {

  /**
   * get label
   * @returns {string}
   */
  const getSupplierSelectLabel = () => {
    const { action } = props;
    switch (action) {
      case 'loan_in':
        return 'clinic';
      case 'transfer_in':
        return 'clinic';
      case 'transferred_in':
        return 'transferred_from';
      default:
        return 'supplier';
    }
  }

  const { suppliers, action, attributes } = props;
  return (
    <div>
      <div className="o-form o-form--horizontal u-flex-row u-flex-space-between" style={{ margin: 0 }}>
        <Select
          id="supply_supplier"
          autoFocus={['supply_item', 'bonus', 'loan_in', 'transfer_in'].includes(action)}
          label={translate(getSupplierSelectLabel())}
          options={
          suppliers
            .filter(supplier => (['loan_in', 'transferred_in', 'transfer_in'].includes(action) ? supplier.isClinic() : !supplier.isClinic()))
            .map((supplier: SupplierModel) => ({ value: supplier.get('_id'), label: supplier.get('name') }))
            .toArray()
        }
          onValueChanged={value => props.onUpdateAttribute('supplier_id', value)}
          value={attributes.supplier_id}
          required
          style={{ flexGrow: 2, width: '20%' }}
        />
        <DatePicker
          id="supplyFormDatePicker"
          showClearDate={false}
          allowPast
          label={translate('date')}
          onValueChanged={value => props.onUpdateAttribute('date', value)}
          value={attributes.date}
          required
          style={{ marginBottom: '1.333em' }}
          className="u-margin-right--half-ws"
          showInvalid={props.isSupplyInputNotAccepted}
        />
        <Input
          id="invoice_number"
          label={translate('invoice_#')}
          value={attributes.invoice_number}
          onValueChanged={(value: string)  => props.onUpdateAttribute('invoice_number', value)}
        />
        <Input
          id="delivery_order_number"
          label={translate('delivery_order_#')}
          value={attributes.delivery_order_number}
          onValueChanged={(value: string)  => props.onUpdateAttribute('delivery_order_number', value)}
        />
        <Input
          id="purchase_order_number"
          label={translate('purchase_order_#')}
          value={attributes.purchase_order_number}
          onValueChanged={(value: string)  => props.onUpdateAttribute('purchase_order_number', value)}
        />
        <Input
          id="doc_no"
          label={translate('doc_#')}
          value={attributes.doc_number}
          onValueChanged={(value: string)  => props.onUpdateAttribute('doc_number', value)}
        />
      </div>
      <TextArea
        id="supply_notes"
        label={translate('notes')}
        onValueChanged={(value: string) => props.onUpdateAttribute('notes', value)}
        value={attributes.notes || ''}
      />
      <hr style={{ marginTop: 0, marginBottom: 25 }} />
    </div>
  );
}

export default AddToInventorySupplyFormItem;