import React, { useRef } from 'react';
import copy from 'clipboard-copy';

import translate from '../utils/i18n';
import Button from './buttons/button';

type Props = {
  id: string,
  copyContent: string,
  onCloseModal: () => void;
  description?: string,
  className?: string,
}

/**
 * Renders a copy to clipboard content
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
function ClipboardCopy(props: Props) {
  const inputRef = useRef(null);

  /**
   * call when copy click
   * @returns {void}
   */
  function copyCodeToClipboard() {
    const el = inputRef.current;
    copy(el.value);
    props.onCloseModal();
  }

  return (
    <div className={props.className}>
      <input
        id={props.id}
        ref={inputRef}
        value={props.copyContent}
        disabled
        style={{ width: '100%' }}
        className="o-form__item o-text-input"
      />
      {props.description &&
        <p>{props.description}</p>
      }
      <br />
      <div>
        <Button
          className="o-button o-button--small"
          onClick={(event) => {
            event.stopPropagation();
            copyCodeToClipboard();
          }}
          dataPublic
        >
          {translate('copy_link')}
        </Button>
      </div>
    </div>
  );
}

export default ClipboardCopy;
