import React from 'react';

import translate from './../../utils/i18n';
import CanvasSettings from './canvasSettings';
import Button from './../buttons/button';
import Header from './../header/header';

type Props = {
  canRedo: boolean,
  canSave: boolean,
  canUndo: boolean,
  canAddNewNote: boolean,
  onPenSettingsChanged: () => void,
  onRedoClicked: () => void,
  onSaveClicked: () => Promise<boolean>,
  onUndoClicked: () => void,
  onNewNoteClicked: () => void,
  penSettings: {
    color: string,
    size: string,
  },
};

/**
 * A CasenoteEditorMenu component.
 * @namespace CasenoteEditorMenu
 * @returns {React.Component}
 */
class CasenoteEditorMenu extends React.Component<Props, {}> {
  props: Props;

  onBackClicked = () => {
    window.history.back();
    if (window.location.hash.endsWith('/add')) {
      window.history.back();
    }
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <Header className="c-casenote-editor__menu" dataPublic>
        <Button className="c-casenote-editor__menu__button" onClick={this.props.onUndoClicked} disabled={!this.props.canUndo}>
          <img src="static/images/icon_undo.png" alt={translate('undo')} />
        </Button>
        <Button dataPublic className="c-casenote-editor__menu__button" onClick={this.props.onRedoClicked} disabled={!this.props.canRedo}>
          <img src="static/images/icon_redo.png" alt={translate('redo')} />
        </Button>
        <CanvasSettings
          penSettings={this.props.penSettings}
          onSettingsChanged={this.props.onPenSettingsChanged}
        />
        {
          this.props.canAddNewNote &&
          <Button
            className="c-casenote-editor__menu__button"
            onClick={this.props.onNewNoteClicked}
            dataPublic
          >
            {translate('new_note')}
          </Button>
        }
        <Button
          className="c-casenote-editor__menu__button"
          onClick={this.props.onSaveClicked}
          disabled={!this.props.canSave}
          style={{ display: 'block', textAlign: 'center' }}
          dataPublic
        >
          {translate('save')}
        </Button>
      </Header>
    );
  }
}

export default CasenoteEditorMenu;
