import React from 'react';
import { List } from 'immutable';
import moment, { Moment } from 'moment';

import translate from './../../utils/i18n';
import Datepicker from './../inputs/datepicker';
import Radio from './../inputs/radio';
import Input from './../inputs/input';
import TextArea from './../inputs/textarea';
import ModalFooter from './../modals/modalFooter';

import MedicalCertificateModel from './../../models/medicalCertificateModel';
import UnsavedDataModal from '../../components/prompts/unsavedDataModal';
import SaveButton from './../buttons/saveButton';
import { createSuccessNotification } from './../../utils/notifications';
import { debugPrint } from './../../utils/logging';

import type { Config, SaveModel } from './../../types';

type Props = {
  config: Config,
  currentModel?: MedicalCertificateModel,
  initialDate?: number,
  encounterID?: string,
  onSave: SaveModel,
  patientID?: string,
  practitionerID: string,
  onChangesMade: (changesMade: boolean) => void,
  showPrompt: boolean,
  onPromptCancel: () => void,
  onDiscard: () => void,
};

/* eslint-disable camelcase */
type MedicalCertificateAttributes = {
  reason: string,
  days: number,
  start_date?: number,
  notes: string,
};
/* eslint-enable camelcase */

type State = {
  isSaving: boolean,
  isStartDateFocused: boolean,
  attributes: MedicalCertificateAttributes,
  errorMessage?: string,
};

/**
 * Form for creating Medical Certificates.
 * @namespace MedicalCertificateForm
 */
class MedicalCertificateForm extends React.Component<Props, State> {
  static defaultProps = {
    initialDate: (new Date()).getTime(),
  };

  /**
   * Instantiate the state of the component.
   * @param {props} props - The props for the component
   */
  constructor(props: Props) {
    super(props);
    const reasonOptions = this.props.config.getIn(['medical_certificate', 'options', 'reason'], List());
    const defaultReason = reasonOptions.size ? reasonOptions.first() || '' : '';
    const attributes: MedicalCertificateAttributes = {
      reason: this.props.currentModel ? this.props.currentModel.get('reason', '') : defaultReason,
      days: this.props.currentModel ? this.props.currentModel.get('days', 1) : 1,
      start_date: this.props.currentModel ? this.props.currentModel.get('start_date', props.initialDate) : props.initialDate,
      notes: this.props.currentModel ? this.props.currentModel.get('notes', '') : '',
    };
    this.state = {
      isSaving: false,
      isStartDateFocused: false,
      attributes,
      errorMessage: undefined,
    };
  }

  /**
   * Set start date to initial date if props don't match.
   * @param {Props} nextProps The incoming props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.initialDate !== this.props.initialDate && this.props.currentModel === undefined) {
      this.setState({
        attributes: Object.assign({}, this.state.attributes, { start_date: nextProps.initialDate }),
      });
    }
  }

  /**
     * Called when user clicks save. Checks validity of form. If valid it updates the current model or
     * creates a new one with the value of state.attributes transfered onto model.attributes. props.onSave
     * is then run with the new/updated mc as the first param. THE MODEL IS NOT SAVED WITHIN IN THIS
     * FUNCTION. MAKE SURE TO RUN mc.setSerialNumber before saving.If not valid then the appropriate
     * error messages are displayed.
     * @return {undefined}
     */
  onSaveClicked = () => {
    if (this.isFormValid()) {
      this.setState({ isSaving: true });
      // Either used the supplied model or create a new one.
      let model: MedicalCertificateModel;
      if (this.props.currentModel) {
        model = this.props.currentModel.set(this.state.attributes);
      } else {
        model = new MedicalCertificateModel({
          patient_id: this.props.patientID,
          encounter_id: this.props.encounterID,
          practitioner_id: this.props.practitionerID,
          timestamp: new Date().getTime(),
        });
        model = model.set(this.state.attributes);
      }
      createSuccessNotification(translate('medical_certificate_creation_success'));
      this.setState({ isSaving: false, errorMessage: undefined });
      this.props.onPromptCancel();
      this.props.onChangesMade(false);
      this.props.onSave(model);
    } else {
      debugPrint('Form invalid');
    }
  }

  /**
   * Validate form.
   * @return {boolean} - true if valid, false otherwise.
   */
  isFormValid() {
    const { days, reason } = this.state.attributes;
    if (!(days || days === 0) || !reason) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    } else if (Number(days) <= 0) {
      this.setState({ errorMessage: translate('days_value_is_not_valid') });
      return false;
    }
    return true;
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    const convertedDays = Math.ceil(this.state.attributes.days);
    const dateToAdd = (convertedDays <= 1) ? 0 : (convertedDays - 1);
    return (
      <div>
        <UnsavedDataModal
          visible={this.props.showPrompt}
          onSave={this.onSaveClicked}
          onDiscard={() => this.props.onDiscard()}
          onCancel={() => this.props.onPromptCancel()}
        />
        <section className="o-form">
          {this.state.errorMessage && <div className="o-form__text-block o-form__text-block--error">{this.state.errorMessage}</div>}
          <Radio
            id="reason"
            label={translate('patient_is_unfit_for')}
            options={
              this.props.config.getIn(['medical_certificate', 'options', 'reason'], List())
                .toArray()
                .map((value: string) => ({ label: value, value }))
            }
            value={this.state.attributes.reason}
            onValueChanged={(reason: string) => {
              this.setState({
                attributes: Object.assign({}, this.state.attributes, { reason }),
              });
              this.props.onChangesMade(true);
            }}
            required
          />
          <Input
            id="days"
            label={translate('days')}
            value={this.state.attributes.days}
            type="number"
            onValueChanged={(days) => {
              this.setState({
                attributes: Object.assign({}, this.state.attributes, { days }),
              });
              this.props.onChangesMade(true);
            }}
            required
          />
          <div
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            className="u-margin-bottom--8ws"
          >
            <Datepicker
              id="start_date"
              label={translate('from')}
              date={moment(this.state.attributes.start_date)}
              focused={this.state.isStartDateFocused}
              onDateChange={(momentObj: Moment) => {
                this.setState({
                  attributes: Object.assign({}, this.state.attributes, {
                    start_date: momentObj ? momentObj.valueOf() : undefined,
                  }),
                });
                this.props.onChangesMade(true);
              }}
              onFocusChange={(state: { focused: boolean }) =>
                this.setState({ isStartDateFocused: state.focused })}
              allowPast
            />
            <Datepicker
              id="end_date"
              label={translate('to')}
              disabled
              allowPast
              onDateChange={() => {}}
              onFocusChange={() => {}}
              date={(moment(this.state.attributes.start_date)).add(dateToAdd, 'days')}
            />
          </div>
          <TextArea
            id="details"
            label={translate('mc_details')}
            value={this.state.attributes.notes}
            onValueChanged={(notes: string) => {
              this.setState({
                attributes: Object.assign({}, this.state.attributes, { notes }),
              });
              this.props.onChangesMade(true);
            }}
          />
        </section>
        <ModalFooter>
          <SaveButton
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            label={translate('save_medical_certificate')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </div>
    );
  }
}

export default MedicalCertificateForm;
