import React, { useEffect, useState } from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import translate from '../../utils/i18n';
import StatelessModal from '../modals/statelessModal';
import FormError from '../formError';
import Keypress from '../keypress';
import { createPermission, hasSomePermission } from '../../utils/permissions';
import ModalFooter from '../modals/modalFooter';
import { Config, SaveModel, User } from '../../types';
import SaveButton from '../buttons/saveButton';
import Input from '../inputs/input';
import Select from '../inputs/select';
import Radio from '../inputs/radio';
import { validateAndTrimString } from '../../utils/utils';
import DiscountChargeModel from '../../models/discountChargeModel';
import { logDiscountChargeCreated, logDiscountChargeEdited } from '../../utils/logging';
import { createSuccessNotification } from '../../utils/notifications';
import SortableList from '../layout/sortableList';
import { updateClinicConfig } from '../../utils/db';
import { wsUnit } from '../../utils/css';

type Props = {
  user: User;
  config: Config;
  isVisible: boolean;
  discountsCharges: List<DiscountChargeModel>;
  hideModal: () => void;
  saveModel: SaveModel,
  updateConfig: (config: Config) => void,
}

type DiscountChargeAttributes = {
  name: string;
  method: 'discount' | 'charge';
  type: 'fixed-value' | 'fixed-percent';
  amount: number;
  alwaysApplied: boolean,
};

const SortableListHeader = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #e0dfdf',
  height: 47,
  alignItems: 'center',
  padding: '0 20px',
});

const CardRow = glamorous.div({
  padding: `10 calc(${wsUnit} / 4)`,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: 47,
});

/**
 * Form component used for creating and editing discounts and charges in settings
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const DiscountsChargesOrderForm = (props: Props) => {
  const [formError, setFormError] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const [sortableDiscountsCharges, setSortableDiscountsCharges] = useState<Array<{id: string, name: string}>>([]);

  useEffect(() => {
    if (props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()).size > 0) {
      const discountChargesMap = Map(props.discountsCharges.map(d => [d.get('_id'), d]));
      let restItems = discountChargesMap;
      const sortableList = List(props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()))
        .map(id => {
          if (discountChargesMap.get(id)) {
            const name = discountChargesMap.get(id)?.get('name');
            restItems = restItems.delete(id);
            return ({
              id,
              name,
            });
          }
          return null;
        })
        .filter(d => !!d)
        .toArray();
      const dc = sortableList.concat(restItems.valueSeq().toArray().map(item => ({ id: item.get('_id'), name: item.get('name') })));
      setSortableDiscountsCharges(dc);
      if (restItems.size) {
        const orderArr = dc.map(d => d.id);
        updateClinicConfig(
          props.config.toJS(),
          Map().setIn(['discounts_charges', 'discounts_charges_order'], orderArr).toJS(),
          props.updateConfig,
        );
      }
    } else {
      const sortableList = props.discountsCharges
        .map(d => ({
          id: d.get('_id'),
          name: d.get('name'),
        }))
        .filter(d => !!d)
        .toArray();

      setSortableDiscountsCharges(sortableList);
    }
  }, [props.config.getIn(['discounts_charges', 'discounts_charges_order'], List())]);

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  const onSaveClicked = () => {
    setSaving(true);
    const orderArr = sortableDiscountsCharges.map(d => d.id);
    updateClinicConfig(
      props.config.toJS(),
      Map().setIn(['discounts_charges', 'discounts_charges_order'], orderArr).toJS(),
      props.updateConfig,
    )
      .then(() => {
        setSaving(false);
        props.hideModal();
      });
  };

  /**
   * Called on Create/Edit Form's onClose
   * @returns {void}
   */
  const handleFormClose = () => {
    if (!formError && formDirty) {
      setFormError(translate('unsaved_changes_error'));
    } else {
      props.hideModal();
    }
  };

  /**
   * sort handler to be passed to sortable list component, rearranges array of cards as per latest sort.
   * @param {MapValue} funcArg object with old and new index.
   * @returns {void}
   */
  const onSort = (funcArg: { oldIndex: number, newIndex: number}): void => {
    const newCards = sortableDiscountsCharges.slice();
    newCards.splice(funcArg.newIndex, 0, newCards.splice(funcArg.oldIndex, 1)[0]);
    setSortableDiscountsCharges(newCards);
  }

  /**
   *
   * @param {string} fieldName Key in stageAttributes
   * @param {string | Array<string> | boolean} value New value to update
   * @returns {void}
   */
  const getColumnItems = () => {
    return sortableDiscountsCharges.map(i => (
      <CardRow>
        <div>{i.name}</div>
      </CardRow>
    ));
  };

  return (
    <StatelessModal
      id="discounts-form"
      visible={props.isVisible}
      setVisible={handleFormClose}
      title={translate('edit_x', { x: translate('order') })}
      dataPublicHeader
      noButton
      explicitCloseOnly
    >
      {formError && <FormError isSticky>{formError}</FormError>}
      <SortableListHeader>
        <p className="u-strong">{translate('order_of_discounts_and_charges')}</p>
      </SortableListHeader>
      <SortableList
        items={getColumnItems()}
        onSortEnd={onSort}
        itemZIndex={100}
      />
      {hasSomePermission(props.user, List([createPermission('discounts_charges', 'create'), createPermission('discounts_charges', 'update')])) &&
        <ModalFooter>
          <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
            <SaveButton
              className="o-button--small"
              isSaving={isSaving}
              onClick={onSaveClicked}
              dataPublic
            />
          </div>
        </ModalFooter>
      }
    </StatelessModal>
  );
};

export default DiscountsChargesOrderForm;
