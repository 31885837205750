import translate from './i18n';
import APIError from './apiError';
import { createErrorNotification, createStaticErrorNotification } from './notifications';
import { logToAppInsight, debugPrint, logError } from './logging';
import type { PouchError, FetchResponse } from '../types';

/**
 * Forbidden error handling for API calls.
 * @param {any} error The Error being returned
 * @returns {void}
 */
export const handleForbiddenResponse = (error: any) => {
  if (error.status === 403) {
    return (error.json ? Promise.resolve(error.json()) : Promise.resolve(error)).then((err) => {
      if (err.error && err.msg === 'Request timestamp out of range') {
        window.logoutUser(false, translate('request_timestamp_out_of_range_error'));
      }
    });
  } return undefined;
};

/**
 * @param {string} message Messageto show
 * @param {'silent' | 'static' | 'error'} notificationType Notification type
 * @returns {void}
 */
const nofifyUser = (message: string, notificationType: 'silent' | 'static' | 'error') => {
  if (notificationType !== 'silent') {
    if (notificationType === 'static') {
      createStaticErrorNotification(message);
    } else {
      createErrorNotification(message);
    }
  }
};

/**
 * Check if API response status if it's unauthorized
 * @param {number | null} status response status (401)
 * @returns {boolean} true if it's authorized
 */
export function handleUnauthorisedApiResponse(status: number | null) {
  if (status === 401) {
    debugPrint('Unauthorized');
    if (window.logoutUser) {
      window.logoutUser(true);
    }
    return false;
  }
  return true;
}

interface Arg {
  error: APIError | PouchError | Response;
  designDoc?: string;
  message?: string;
  notificationType?: 'silent' | 'static' | 'error';
}
/**
 * handles all backend response errors, as a common handler to display tooltip error message.
 * @param {Arg} arg ApiError
 * @returns {void}
 */
export const handleApiError = ({ error, designDoc, message, notificationType = 'error' }: Arg) => {
  const err = error?.json ?
    Promise.resolve(error.json().then((json: FetchResponse) => json)) : error;
  const errorMsg = message ?? err?.message;
  const logMessage = err.reason || err.message || err;
  const status = err?.status;

  // Log valid error response
  if (!(status === 401 || status === 403)) {
    logError(errorMsg, designDoc, err);
  }

  // @ts-ignore
  if ((status === 'ETIMEDOUT') ||
    (err && err.toString() && err.toString().indexOf('ETIMEDOUT') !== -1)) {
    if (designDoc) {
      debugPrint(`DDoc: ${designDoc}`, 'error');
    } else {
      debugPrint(err, 'error');
    }
  } else if (status === 401) {
    handleUnauthorisedApiResponse(status);
  } else if (status === 403) {
    handleForbiddenResponse(err);
  } else if (status === 0) {
    if (designDoc) {
      nofifyUser(`Error loading data from DDoc: ${designDoc}`, notificationType);
      debugPrint(`status 0 occurred while fetching the design document or view. DDoc: ${designDoc}`, 'error');
    } else {
      debugPrint(err, 'error');
    }
  } else if (status >= 500) {
    if (designDoc) {
      nofifyUser(`Error loading data from DDoc: ${designDoc}`, notificationType);
    }
    debugPrint(err, 'error');
  } else {
    if (designDoc) {
      if (err?.name === 'not_found') {
        throw new Error(`Design doc is missing: ${designDoc}`);
      }
      debugPrint(`DDoc: ${designDoc}`, 'error');
    }
    if (status === 400) {
      debugPrint(err, 'error');
    } else {
      debugPrint(err, 'error');
      nofifyUser(errorMsg, 'static');
    }
  }
};
