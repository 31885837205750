import React, { useEffect, useState } from 'react';
import { List, Map } from 'immutable';
import { Ul } from 'glamorous';
import translate from '../../utils/i18n';
import { getConfirmation, transformColumnKeys, transformFilteredColumns } from '../..//utils/utils';
import { renderListItem } from '../../utils/tables';
import { getModelMapFromList } from '../../utils/models';
import { createSuccessNotification } from '../../utils/notifications';
import ContentTransition from '../contentTransition';
import Header from '../header/header';
import Table from '../table/table';
import TableColumnsSettings from '../table/tableColumnsSettings';
import FlowForm from './flowForm';
import Button from '../buttons/button';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission } from '../../utils/permissions';

import type EncounterStageModel from '../../models/encounterStageModel';
import type SalesItemModel from '../../models/salesItemModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type { Config, SaveModel, User } from '../../types';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterModel from '../../models/encounterModel';
import type PatientCampaignModel from '../../models/patientCampaignModel';

const COLUMNS = [
  { accessor: 'name', Header: translate('name') },
  { accessor: 'stage_order', Header: translate('stage_order'), filterable: false, Cell: renderListItem },
  { accessor: 'related_sales_items', Header: translate('related_sales_items'), filterable: false, sortable: false, Cell: renderListItem },
  { accessor: 'action', Header: '', sortable: false, show: true, width: 130 },
];

type Props = {
  stagesMap: Map<string, EncounterStageModel>;
  salesItems: Map<string, SalesItemModel>;
  updateConfig: (config: Config) => void;
  config: Config;
  saveModel: SaveModel;
  coveragePayors: List<CoveragePayorModel>;
  encounterFlows: List<EncounterFlowModel>;
  currentEncounters: List<EncounterModel>;
  user: User;
  fetchRelatedCampaigns: (flowId: string) => Promise<List<PatientCampaignModel>>
}

/**
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const Flows = (props: Props) => {
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));
  const [addFlowFormVisible, setAddFlowFormVisible] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<EncounterFlowModel>();
  const [flowCampaignMap, setFlowCampaignMap] = useState<
    Map<string, List<PatientCampaignModel>>
  >(Map());
  const showFlowForm = Boolean(addFlowFormVisible || selectedFlow);

  useEffect(() => {
    const selectedFlowId = selectedFlow?.get('_id');
    if (selectedFlowId && !flowCampaignMap.has(selectedFlowId)) {
      props.fetchRelatedCampaigns(selectedFlowId)
        .then((campaignDocs) => {
          if (campaignDocs) {
            setFlowCampaignMap(flowCampaignMap.set(selectedFlowId, campaignDocs));
          }
        })
        .catch(() => {});
    }
  }, [selectedFlow]);

  /**
   * Hides Flow form modal
   * @returns {void}
   */
  const hideModal = () => {
    setSelectedFlow(undefined);
    setAddFlowFormVisible(false);
  };

  /**
   * Deletes the selected encounter flow model
   * @returns {Promise<any>}
   */
  const deleteFlow = () => props.saveModel(selectedFlow?.set('hidden', true)).then(() => {
    createSuccessNotification(translate('item_deleted'));
    hideModal();
  });

  /**
   * Handles Delete stage
   * @returns {Promise<void>}
   */
  const onDelete = () => {
    if (flowCampaignMap.get(selectedFlow?.get('_id'))?.size) {
      const messageContent = (
        <>
          <p className="u-margin-bottom--half-ws">{translate('this_cant_be_undone')}</p>
          <p className="u-margin-bottom--half-ws">{translate('this_flow_is_tied_to_following_campaigns')}</p>
          <Ul className="u-margin-bottom--half-ws" listStyle="none">
            {flowCampaignMap.get(selectedFlow?.get('_id'))?.map(campaign => (
              <li> - {campaign.get('name')}</li>
            ))}
          </Ul>
          <p>{translate('campaigns_may_no_longer_active_after_flow_deletion')}</p>
        </>
      );
      return getConfirmation(messageContent, {
        modalTitle: `${translate('delete_x', { x: selectedFlow?.get('name') })}?`,
        footerSaveButtonName: translate('delete_x', { x: 'Flow' }),
      })
        .then(deleteFlow)
        .catch(() => {});
    }
    const hasEncountersWithSelectedFlow = props.currentEncounters.some(encounter =>
    // eslint-disable-next-line camelcase
       selectedFlow?.get('_id') === encounter.get('flow')?.flow_id);
    if (hasEncountersWithSelectedFlow) {
      const messageContent = (
        <>
          <p className="u-margin-bottom--half-ws">{translate('there_are_queued_patients_using_this_flow_message')}</p>
          <p>{translate('there_are_queued_patients_using_this_flow_message_description')}</p>
        </>
      );
      return getConfirmation(messageContent, {
        modalTitle: `${translate('error')} - ${translate('there_are_queued_patients_using_this_flow')}`,
        hideCancel: true,
        footerSaveButtonName: translate('acknowledge'),
      })
      // Can't delete flow in case of flow is in use. Hence leaving `then` as empty
        .then(() => {})
        .catch(() => {});
    }
    return getConfirmation(`${translate('delete_x', { x: selectedFlow?.get('name') })}?`, {
      modalTitle: translate('this_cant_be_undone'),
      footerSaveButtonName: translate('delete_x', { x: 'Flow' }),
    })
      .then(deleteFlow)
      .catch(() => {});
  };

  /**
   * Returns table row data
   * @returns {Array<Row>}
   */
  const getFlowRows = () => props.encounterFlows.map(flow => ({
    name: flow.get('name'),
    stage_order: flow.getStageNames(props.stagesMap),
    related_sales_items: flow.getSalesItemNames(props.stagesMap, props.salesItems),
    action: (
      <Button
        dataPublic
        className="o-button o-button--small"
        onClick={() => setSelectedFlow(flow)}
      >
        {translate('view')}
      </Button>
    ),
  })).toArray();

  return (
    <ContentTransition>
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <h1 className="o-title">{translate('flows')}</h1>
        <div className="o-card u-margin-bottom--4ws">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{ translate('flows') }</h1>
            <div className="u-flex-right u-margin-right--half-ws">
              <TableColumnsSettings
                config={props.config}
                configFieldName="encounter_flows"
                originalColumns={List(transformColumnKeys(COLUMNS))}
                columns={columns}
                onUpdateColumns={setColumns}
                updateConfig={props.updateConfig}
              />
            </div>
          </Header>
          <PermissionWrapper permissionsRequired={List([createPermission('flows', 'create')])} user={props.user}>
            <div className="o-header-actions">
              <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
                <button className="o-button o-button--small" onClick={() => setAddFlowFormVisible(true)}>{translate('create_x', { x: translate('flow') })}</button>
              </div>
            </div>
          </PermissionWrapper>
          <Table
            columns={transformFilteredColumns(COLUMNS, columns)}
            data={getFlowRows()}
            noDataText="No Flows found"
            defaultSorted={[{ id: 'name', desc: false }]}
          />
        </div>
      </section>
      {showFlowForm &&
        <FlowForm
          stagesMap={props.stagesMap}
          salesItems={props.salesItems}
          updateConfig={props.updateConfig}
          config={props.config}
          saveModel={props.saveModel}
          coveragePayors={props.coveragePayors}
          isVisible={showFlowForm}
          hideModal={hideModal}
          selectedFlow={selectedFlow}
          onDelete={onDelete}
          encounterFlowMap={getModelMapFromList(props.encounterFlows)}
          user={props.user}
        />}
    </ContentTransition>
  );
};

export default Flows;
