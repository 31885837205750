/* eslint-disable no-underscore-dangle */
import { List, Map } from 'immutable';
import moment from 'moment';

import BaseModel from './baseModel';
import { isToday, isPast, prettifyDate } from './../utils/time';
import { UNICODE, APPOINTMENT_FLOW_FALLBACK_ENABLED } from './../constants';
import { flattenStageInfo, getConcatenatedValueFromStages, getDoctorNames, getNotesStringFromObject, getSuffixedStageInfoFromStageAttributes, mergeStages } from '../utils/encounters';

import type SalesItemModel from './salesItemModel';
import type PractitionerModel from './practitionerModel';
import type DrugModel from './drugModel';
import type PrescriptionModel from './prescriptionModel';
import type EncounterStageModel from './encounterStageModel';
import translate from '../utils/i18n';

type EncounterEvents = Array<EncounterEvent>;
type EncounterEventType = 'arrived' | 'started' | 'finished_consult' | 'completed' | 'cancelled';

export type StageEventType = 'arrived' | 'started' | 'completed' | 'cancelled';

export type EncounterEvent = {
  time: number,
  type: EncounterEventType,
}


export type StageEvent = {
  time: number,
  type: StageEventType,
};

export type OccurrenceInfo = {
  events: Array<StageEvent>,
  location?: string,
  doctor?: string,
}

export type StageInfo = {
  stage_id: string,
  name: string,
  notes?: string, // depends on has_notes value of stage_doc
  occurrences: Array<OccurrenceInfo>,
  _name: string, // Note: it will be removed before save
};

type EncounterFlow = {
  name: string,
  flow_id: string,
  stages: Array<StageInfo>,
};

export type QueueEvent = {
  type: 'assigned' | 'reassigned' | 'missing'
  number?: number | string, // Clinics should be able to reassign queue number with number | string (eg: EMER)
  time?: number,
}

export type EncounterAttributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'encounter',
  encounter_events: EncounterEvents,
  patient_id: string,
  schedule_time?: number,
  appointment_id?: string,
  flow: EncounterFlow,
  queue?: Array<QueueEvent>,
};

/**
   * EncounterModel
   *
   * @namespace EncounterModel
   */
class EncounterModel extends BaseModel {
  attributes: EncounterAttributes;

  mergeFunctions = {
    encounter_events: this.handleEncounterEventsMerge,
  };

  /**
   * @param {object} attributes - The attributes for this model.
   * @param {boolean} skipFormat - Skips formatting stage if true, Called from beforeSave
   * @returns {void}
   */
  constructor(attributes: Partial<EncounterAttributes> = {}, skipFormat: boolean = false) {
    super(attributes);
    this.attributes.type = 'encounter';
    if (this.attributes.flow?.stages?.length && !skipFormat) {
      const stageAttributes = this.attributes.flow?.stages
      .filter(stage => stage.stage_id && stage.name)
      .map(({ stage_id, ...stage }) => Object.assign(stage, { stageId: stage_id }));
      const formattedStages = getSuffixedStageInfoFromStageAttributes(List(stageAttributes))
        .map(({ unsuffixed, stageId, ...formattedStage }) => ({
          ...formattedStage,
          // Added for better caching and to distinguish stage, if the flow contains same stage multiple times
          // The underscored values should be removed beforeSave.
          name: unsuffixed,
          _name: formattedStage.name,
          stage_id: stageId,
        })).toArray();
      // StageInfo is spreaded into the object, hence ignoring the type error
      // @ts-ignore
      this.attributes.flow.stages = formattedStages;
    }
  }

  /**
   * Merges the local and remote values of encounter_events in the case of a merge conflict.
   * @param {EncounterEvents} localValue The local value of encounter_events.
   * @param {EncounterEvents} remoteValue The remote value of encounter_events.
   * @returns {EncounterEvents} The merged encounter events.
   */
  handleEncounterEventsMerge(localValue: EncounterEvents, remoteValue: EncounterEvents) {
    const filterLocalValue = localValue.filter(
      v => remoteValue.find(rv => rv.time === v.time && rv.type === v.type) === undefined, // Only return vals not in the remoteValue.
    );
    return List(remoteValue.concat(filterLocalValue))
      .sort((a, b) => a.time - b.time)
      .toArray();
  }

  /**
   * Adds an event of the given type to the EncounterModel.
   * @param {string} eventType The type of event.
   * @param {number} time The timestamp for the event. Defaults to the current time.
   * @returns {EncounterModel} Returns the updated encounter.
   */
  addEvent(eventType: string, time: number = (new Date()).valueOf()) {
    const newEvents = this.get('encounter_events', []);
    newEvents.push({ type: eventType, time });
    this.set('encounter_events', newEvents);
    return this;
  }

  /**
   * Returns true if the highest event for this Encounter matches the provided event type. If no
   * events exist false is returned.
   * @param {string} eventType The type of event to check for.
   * @returns {boolean} Whether or not the last event matches the provided event.
   */
  highestEventIs(eventType: EncounterEventType): boolean {
    if (!this.has('encounter_events') || this.get('encounter_events', []).length === 0) {
      return false;
    }
    switch (eventType) {
      case 'completed':
        return this.containsEvent('completed');
      case 'finished_consult':
        return this.containsEvent('finished_consult') &&
          !this.containsEvent('completed');
      case 'started':
        return this.containsEvent('started') &&
          !this.containsEvent('finished_consult') &&
          !this.containsEvent('completed');
      case 'arrived':
        return this.containsEvent('arrived') &&
          !this.containsEvent('started') &&
          !this.containsEvent('finished_consult') &&
          !this.containsEvent('completed');
      default:
        return false;
    }
  }

  /**
   * Returns true if the last event for this Encounter matches the provided event type. If no last
   * event exist false is returned.
   * @param {string} eventType The type of event to check for.
   * @returns {boolean} Whether or not the last event matches the provided event.
   */
  lastEventIs(eventType: EncounterEventType): boolean {
    if (!this.has('encounter_events') || this.get('encounter_events', []).length === 0) {
      return false;
    }
    return this.get('encounter_events')[this.get('encounter_events').length - 1].type === eventType;
  }

  /**
   * Returns the last event time. If no events exist, schedule_time is returned (this is unlikely to
   * occur).
   * @returns {number} The last event time.
   */
  getLastEventTime(): number {
    if (!this.has('encounter_events') || this.get('encounter_events', []).length === 0) {
      return this.get('schedule_time');
    }
    return this.get('encounter_events')[this.get('encounter_events').length - 1].time;
  }


  /**
   * Gets the last event type. If no event is found then the default event 'arrived' is returned.
   * @returns {string} The last event type.
   */
  getLastEventType(): EncounterEventType {
    if (!this.has('encounter_events') || this.get('encounter_events', []).length === 0) {
      return 'arrived';
    }
    return this.get('encounter_events')[this.get('encounter_events').length - 1].type;
  }

  /**
   * Returns list of stages
   * @returns {List<StageInfo>}
   */
  getStages(): List<StageInfo> {
    return List(this.get('flow')?.stages || []) as List<StageInfo>;
  }

  /**
   * Returns list of active stages in the encounter
   * @returns {List<StageInfo>}
   */
  getActiveStages() {
    return this.getStages().filter(stage =>
      !stage.occurrences?.length || !stage.occurrences.some(occ =>
        occ.events?.some(event => event.type === 'cancelled')));
  }

  /**
   * Returns the time for the arrival of the patient. If there is no such event then the last event
   * time is returned.
   * @returns {number} A timestamp for the encounter arrival.
   */
  getArrivalTime(): number {
    const startEvent = this.get('encounter_events', [])
      .find((event: EncounterEvent) => event.type === 'arrived');
    return startEvent ? startEvent.time : this.getLastEventTime();
  }

  /**
   * Returns the time for the start of an encounter. If there is no such event then the last event
   * time is returned.
   * @returns {number} A timestamp for the encounter start.
   */
  getStartTime(): number {
    const startEvent: EncounterEvent = this.get('encounter_events', [])
      .find((event: EncounterEvent) => event.type === 'started');
    return startEvent ? startEvent.time : this.getLastEventTime();
  }

  /**
   * Returns the time for the end of a consult. If there is no such event then the last event
   * time is returned.
   * @returns {number} A timestamp for the encounter start.
   */
  getConsultFinishTime(): number {
    const startEvent: EncounterEvent = this.get('encounter_events', [])
      .find((event: EncounterEvent) => event.type === 'finished_consult');
    return startEvent ? startEvent.time : this.getLastEventTime();
  }

  /**
   * Returns the time for the first bill finallzation. If there is no such event then the last event
   * time is returned.
   * @returns {number} A timestamp for the encounter start.
   */
  getCompletedTime(): number {
    const completedEvent: EncounterEvent = this.get('encounter_events', [])
      .find((event: EncounterEvent) => event.type === 'completed');
    return completedEvent ? completedEvent.time : this.getLastEventTime();
  }

  /**
   * Returns true if the last event time was today.
   * @returns {boolean} True if the last event time was today.
   */
  isToday(): boolean {
    return isToday(this.getLastEventTime());
  }

  /**
   * Returns true if the given event type has occurred in this encounter.
   * @param {string} eventType An Encounter Event type.
   * @returns {boolean}
   */
  containsEvent(eventType: EncounterEventType): boolean {
    return this.get('encounter_events', [])
      .some(e => e.type === eventType);
  }

  /**
   * Returns true if the last event time was today and the encounter is not finished.
   * @returns {boolean} True if the encounter is current.
   */
  isCurrent(): boolean {
    return !this.containsEvent('finished_consult')
      && !this.containsEvent('completed') // NOTE: This is modified to check for any event regardless of time, rather than the last event. If we want in the future to use time based event structures this will need to change.
      && !this.containsEvent('cancelled')
      && !this.containsEvent('rescheduled');
  }

  /**
   * Returns true if the last event time was before today.
   * @returns {boolean} True if the last event time was before today.
   */
  isPast(): boolean {
    return isPast(this.getLastEventTime());
  }

  /**
   * Returns true if the last event time was before today.
   * @returns {boolean} True if the last event time was before today.
   */
  isIncomplete(): boolean {
    return !this.containsEvent('cancelled') && !this.lastEventIs('completed');
  }

  /**
   * Returns true if the last event time is prior to today and the encounter is not finished.
   * @returns {boolean} True if the encounter is past and incomplete.
   */
  isPastAndIncomplete(): boolean {
    return this.isPast()
      && !this.containsEvent('completed') // NOTE: This is modified to check for any event regardless of time, rather than the last event. If we want in the future to use time based event structures this will need to change.
      && !this.containsEvent('cancelled');
  }

  /**
   * Returns prettified date of the last event time for this encounter.
   * @returns {string} The date for this encounter.
   */
  getDate(): string {
    return prettifyDate(this.getLastEventTime());
  }

  /**
   * Returns the name of the salesItem referenced at this.attributes.consult_type, or an emdash
   * if not found.
   * @param {Immutable.List} salesItems A List of SalesItemModels.
   * @returns {string} The consult type name.
   */
  getEncounterType(salesItems: List<SalesItemModel> = List()): string {
    if (this.has('flow')) {
      return this.get('flow')?.name || UNICODE.EMDASH;
    }
    if (!this.has('consult_type') || this.get('consult_type').length === 0) {
      return UNICODE.EMDASH;
    }
    const salesItem = salesItems.find(i => i.get('_id') === this.get('consult_type'));
    return salesItem ? salesItem.get('name') : this.get('consult_type');
  }

  /**
   * Get the Doctor name for this encounter. If the practitioner is not found then the doctor id
   * specified on the encounter is returned. If the doctor field is not specified an empty string is
   * returned.
   * @param {List<PractitionerModel>} practitioners List of all practitioners
   * @param {boolean} showAllDoctors If true returns list of all doctors in the doc.
   * @returns {string}
   */
  getDoctorName(practitioners: List<PractitionerModel>, showAllDoctors: boolean = false) {
    if (showAllDoctors && this.get('flow')) {
      return getDoctorNames(practitioners, this.getActiveStages(), this.get('doctor'));
    }
    const doctorId = this.getValue('doctor');
    const practitioner = practitioners.find(p => p.get('_id') === doctorId);
    return practitioner ? practitioner.get('name', '') : doctorId || UNICODE.EMDASH;
  }

  /**
   * Returns the location attribute for this encounter.
   * If the location is not found then a concatenated list of all locations in the stages is returned.
   * If flow/stages is not found then EMDASH is returned
   * @param {List<PractitionerModel>} practitioners List of all practitioners
   * @returns {string}
   */
  getLocation() {
    if (this.has('location') && APPOINTMENT_FLOW_FALLBACK_ENABLED) {
      return this.get('location');
    }
    if (this.get('flow')) {
      return getConcatenatedValueFromStages(this.getActiveStages(), 'location').join(', ');
    }
    return UNICODE.EMDASH;
  }

  /**
   * Returns the number of minutes since the 'arrived' event.
   * @returns {number}
   */
  getMinutesSinceLastStageEventUpdate(): number {
    return moment().diff(this.getCurrentEvent().time, 'minutes');
  }

  /**
   * Returns a human readable summary of the prescriptions for this Encounter.
   * @param {List<PrescriptionModel>} prescriptions The prescriptions for the encounter.
   * @param {List<DrugModel>} drugs All drugs
   * @returns {string}
   */
  getPrescriptionSummary(prescriptions: List<PrescriptionModel>, drugs: List<DrugModel>): string {
    return prescriptions
      .map((p) => {
        const drug = drugs.find(d => d.get('_id') === p.get('drug_id'));
        const name = drug ? drug.get('name') : UNICODE.EMDASH;
        const unit = drug ? drug.getDispensationUnit() : UNICODE.EMDASH;
        return `${name} - ${p.get('sale_quantity')} ${unit}`;
      })
      .toArray()
      .join('\n');
  }

  /**
   * Returns the last updated stage
   * @returns {StageInfo}
   */
  getCurrentStageIndex(): { currentStageIndex: number, currentStageOccurenceIndex: number } {
    const stages = this.getActiveStages();
    return stages.reduce(({ currentStageIndex, currentStageOccurenceIndex }, stage, stageIndex) => {
      if (stage?.occurrences?.length) {
        return stage?.occurrences.reduce((_, stageOccurance, occuranceIndex) => {
          const highestCurrentOccuredEventByTime = stageOccurance?.events &&
            stageOccurance?.events[(stageOccurance?.events?.length || 0) - 1];
          const highestOccuredStageInfo = stages.get(currentStageIndex)?.occurrences[currentStageOccurenceIndex];
          const highestOccuredEventByTime = highestOccuredStageInfo?.events &&
            highestOccuredStageInfo?.events[(highestOccuredStageInfo?.events?.length || 0) - 1];
          if (highestCurrentOccuredEventByTime?.time >= highestOccuredEventByTime?.time) {
            return {
              currentStageIndex: stageIndex,
              currentStageOccurenceIndex: occuranceIndex,
            };
          } return {
            currentStageIndex, currentStageOccurenceIndex,
          };
        }, { currentStageIndex, currentStageOccurenceIndex });
      }
      return { currentStageIndex, currentStageOccurenceIndex };
    }, { currentStageIndex: 0, currentStageOccurenceIndex: 0 });
  }

  /**
   * Returns true if current stage is last stage in the selected flow
   * @returns {boolean}
   */
  isLastStage() {
    if (!this.has('flow')) {
      return true; // There will be single stage in this case -> `consult_type`
    }
    const { currentStageIndex } = this.getCurrentStageIndex();
    const totalStages = this.getActiveStages();
    return totalStages.size === currentStageIndex + 1;
  }

  /**
   * Adds an encounter event to the highest stage
   * @param {StageEventType} event event to add
   * @returns {void}
   */
  addStageEvent(event: StageEventType) {
    if (!this.has('flow')) {
      return this;
    }
    const { currentStageIndex, currentStageOccurenceIndex } = this.getCurrentStageIndex();
    const stages = this.getActiveStages();
    const currentStage = stages.get(currentStageIndex) as StageInfo;
    const occurrences = List(currentStage?.occurrences || []);
    const currentOccuredStage = occurrences.get(currentStageOccurenceIndex, {}) as OccurrenceInfo;
    if (currentOccuredStage?.events) {
      currentOccuredStage.events.push({
        type: event,
        time: new Date().valueOf(),
      });
    } else {
      currentOccuredStage.events = [{ type: event, time: new Date().valueOf() }];
    }
    const updatedOccurringStage =
      occurrences.splice(currentStageOccurenceIndex, 1, currentOccuredStage).toArray();
    const updatedActiveStages = stages
      .splice(currentStageIndex, 1, { ...currentStage, occurrences: updatedOccurringStage });
    const updatedStages = mergeStages(this.getStages(), updatedActiveStages);
    return this.set('flow', { ...this.get('flow'), stages: updatedStages });
  }

  /**
   * Adds new stage to the encounter. Called on moving to another stage
   * @param {string} stageId Id of stage doc
   * @param {string} name Formatted Stage name (_name)
   * @returns {EncounterModel}
   */
  addStage(stageId: string, name: string) {
    const encounterStages = this.getActiveStages();
    const stageIndexToChange = encounterStages.findIndex(stage =>
      stage._name === name && stage.stage_id === stageId);
    if (stageIndexToChange !== -1) {
      const stageToAdd = encounterStages.get(stageIndexToChange) as StageInfo;
      stageToAdd.occurrences.push({
        events: [{
          type: 'arrived',
          time: new Date().valueOf(),
        }],
        // Preset next stage doctor from current stage value
        // This will avoid need of selecting doctor on each stage.
        // User can change doctor from the dropdown, if neccessary
        doctor: this.getValue('doctor'),
      });
      const updatedActiveStages = encounterStages.set(stageIndexToChange, stageToAdd);
      const updatedStages = mergeStages(this.getStages(), updatedActiveStages);
      const flow = { ...this.get('flow'), stages: updatedStages };
      return this.set('flow', flow);
    }
    return this;
  }

  /**
   * Sets the passed attributes to the last stage
   * @param {Partial<StageInfo>} attributes Attributes to update in the stage
   * @returns {EncounterModel}
   */
  updateCurrentStageOccurrenceAttributes(attributes: Partial<{doctor: string, location: string}>) {
    if (!this.has('flow')) {
      Object.keys(attributes).forEach(attr => this.set(attr, attributes[attr]));
      return this;
    }
    const stages = this.getActiveStages();
    const { currentStageIndex, currentStageOccurenceIndex } = this.getCurrentStageIndex();
    const currentStage = stages.get(currentStageIndex) as StageInfo;
    const occurrences = List(currentStage?.occurrences || []);
    const currentOccurringStage = occurrences.get(currentStageOccurenceIndex, {}) as OccurrenceInfo;
    const updatedAttributes = { ...currentOccurringStage, ...attributes };
    const updatedOccurringStage =
      occurrences.splice(currentStageOccurenceIndex, 1, updatedAttributes).toArray();
    const updatedActiveStages =
      stages.splice(currentStageIndex, 1, { ...currentStage, occurrences: updatedOccurringStage });
    const updatedStages = mergeStages(this.getStages(), updatedActiveStages);
    return this.set('flow', { ...this.get('flow'), stages: updatedStages });
  }

  /**
   * Returns value of key from attributes if exists passed
   * else check for value in last encounter stage
   * @param {string} key atrribute key in stage info to get value
   * @returns {any}
   */
  getValue(key: keyof StageInfo | keyof OccurrenceInfo) {
    if (!this.has('flow') || (this.has(key) && APPOINTMENT_FLOW_FALLBACK_ENABLED)) { // For old version doc
      return this.get(key);
    }
    const stages = this.getActiveStages();
    const { currentStageIndex, currentStageOccurenceIndex } = this.getCurrentStageIndex();
    const currentStage = stages.get(currentStageIndex);
    if (currentStage && currentStage[key]) {
      return currentStage[key];
    }
    const occurrences = List(currentStage?.occurrences || []);
    const currentOccurringStage = occurrences.get(currentStageOccurenceIndex);
    return currentOccurringStage
      ? currentOccurringStage[key]
      : undefined;
  }

  /**
   * Returns current occurring stage event
   * @returns {StageEvent}
   */
  getCurrentEvent(): StageEvent | EncounterEvent {
    if (!this.has('flow')) {
      return { time: this.getArrivalTime(), type: this.getLastEventType() };
    }
    const curentStageEvents: List<StageEvent> = List(this.getValue('events') || []);
    return curentStageEvents.last({}) as StageEvent;
  }

  /**
   * Returns notes string if attribute `notes` in encounter model (Old docs)
   * else returns map of stage name and notes eg: { stagename: 'stagename notes }
   * @param {List<PractitionerModel>} practitioners List of all practitioners
   * @param {Map<string, EncounterStageModel>} encounterStageMap Map of encounter stage with _id
   * @param {boolean} stringify If true, returns notes as string, else as an object corresponding to each stage
   * @returns {string | Object}
   */
  getNotes(
    practitioners: List<PractitionerModel> = List(),
    encounterStageMap: Map<string, EncounterStageModel> = Map(),
    stringify: boolean = false,
  ): string | {[stageName: string]: string} {
    const notes = this.get('notes');
    if (notes || !this.has('flow')) {
      return notes || '';
    }
    if (stringify) {
      const stageNotes = this.getActiveStages().reduce((stagenote, stage) => {
        if (encounterStageMap) {
          if (encounterStageMap.get(stage.stage_id)?.get('has_notes')) {
            return stagenote.push({ [`${stage._name} ${translate('notes')}`]: stage.notes || '' });
          }
          return stagenote;
        }
        return stagenote.push({ [`${stage._name} ${translate('notes')}`]: stage.notes || '' });
      }, List());
      return getNotesStringFromObject(stageNotes, true);
    }
    if (APPOINTMENT_FLOW_FALLBACK_ENABLED) {
      return flattenStageInfo(this, practitioners).map(Obj => getNotesStringFromObject(Obj)).join('\n');
    }
    return this.getActiveStages().reduce((stagenote, stage) => {
      if (encounterStageMap.size) {
        if (encounterStageMap.get(stage.stage_id)?.get('has_notes')) {
          return stagenote.set(stage._name, stage.notes || '');
        }
        return stagenote;
      }
      return stagenote.set(stage._name, stage.notes || '');
    }, Map()).toObject() as {[key: string]: string};
  }

  /**
   * Called before saving model
   * Attributes which are not part of doc should be removed here
   * @returns {EncounterModel}
   */
  beforeSave(): EncounterModel {
    if (this.get('flow')?.name) {
      const stageAttributes = this.getStages()
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .reduce((stages, { _name, ...stage }) => stages.push(stage), List()); // Removing _name from stageInfo
      const encounterAttributes = Object.assign({}, this.attributes, {
        flow: {
          name: this.get('flow')?.name,
          stages: stageAttributes.toArray(),
        },
      });
      const model = new EncounterModel(encounterAttributes, true);
      model.changes = this.changes;
      return model;
    }
    return this;
  }

  /**
   * Sets the extra attributes in the model after saving
   * @returns {EncounterModel}
   */
  beforeUpdate() {
    // Adds the removed values back to the model after updating db.
    return new EncounterModel(this.attributes);
  }

  /**
   * Returns current queue number (reassigned if exists, else assigned), if `assigned` is false,
   * else returns assigned queue number
   * @param {boolean} assigned Returns assigned queue number if true
   * @returns {number}
   */
  getQueueNumber(assigned: boolean = false): number | string | undefined {
    const queue: Array<QueueEvent> = this.get('queue', []);
    return queue.find((q: QueueEvent) => {
      if (!assigned && queue.some(qt => qt.type === 'reassigned')) {
        return q.type === 'reassigned';
      }
      return q.type === 'assigned';
    })?.number;
  }
}


export default EncounterModel;
