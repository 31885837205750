import * as React from 'react';

import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import Input from './../inputs/input';

type Props = {
  setVisible: (visible: boolean) => void,
  isVisible: boolean,
  reasonValue: string,
  onChangedReason: (reason: string) => void,
  onClickDelete: () => void,
  onClose: () => void,
  isSaving: boolean,
}

/**
 * A wrapper that fades in the content on load.
 * @param {any} props The component props.
 * @returns {React.Component} A React transition component.
 */
const ContentTransition = ({
  setVisible, isVisible, reasonValue, onChangedReason, onClickDelete, isSaving, onClose,
}: Props) => (
  <StatelessModal
    id="delete_doc"
    buttonLabel=""
    title={translate('delete_document')}
    setVisible={visible => setVisible(visible)}
    visible={isVisible}
    explicitCloseOnly
    onClose={onClose}
    dataPublicHeader
  >
    <section className="o-form">
      <Input
        autoFocus
        id="delete-reason"
        onValueChanged={value => onChangedReason(value)}
        value={reasonValue}
        label={translate('reason')}
        required
      />
    </section>
    <ModalFooter>
      <SaveButton
        dataPublic
        onClick={() => onClickDelete()}
        isSaving={isSaving}
        label={translate('delete')}
        className="o-button--small u-margin-right--half-ws"
        disabled={!reasonValue}
      />
    </ModalFooter>
  </StatelessModal>
);

export default ContentTransition;
