/* eslint-disable import/prefer-default-export */
import moment, { Moment } from 'moment';
import { FilteringRule } from '../components/encounterTable/tableFilter';
import translate from './i18n';
import { getDateFormat } from './time';
import { UNICODE } from '../constants';

/**
 * Creates from formatted date or time string
 * @param {string} data formatted value
 * @returns {Moment}
 */
const getMomentFromFormattedTimeString = (data: string | number): Moment | false => {
  let dataToCheck;
  if (data && typeof data === 'string') {
    // For formatted time
    if (data.includes(':')) {
      dataToCheck = moment(data, 'HH:mm');
    } else {
      dataToCheck = moment(data, getDateFormat());
    }
    return dataToCheck;
  }
  return false;
};

/**
 * Returns parsed number from string
 * @param {string | number} str string to parse
 * @returns {number}
 */
const parseNumberString = (str: string | number) => {
  if (typeof str === 'string' && str.includes(translate('minutes'))) {
    // Waiting time will be in the format `X minutes`
    const mins = str.split(' ')[0];
    return Number(mins);
  }
  return Number(str);
};

/**
 * Returns true if data string contains value
 * @param {string} data value to check against condition
 * @param {string} value value to check against
 * @returns {boolean}
 */
const containsValue = (data: string | number, value: string | number | string[]) => {
  if (typeof data === 'string') {
    return data.toLowerCase().includes(`${value}`.toLowerCase());
  }
  return false;
};

/**
 * Returns true if data string contains value
 * @param {string} data value to check against condition
 * @param {string} value value to check against
 * @returns {boolean}
 */
const anyOf = (data: string | number, value: string | number | string[]) => {
  if (Array.isArray(value)) {
    return value.map(v => v.toLowerCase()).includes(`${data}`.toLowerCase());
  }
  return false;
};

/**
 * Checks equality
 * @param {string | number} data value to check against condition
 * @param {string | number} value value to check against
 * @returns {boolean}
 */
const isEquals = (data: string | number, value: string | number | string[]) => {
  const dataToCheck = parseNumberString(data);
  if (dataToCheck) {
    return dataToCheck === Number(value);
  }
  return String(data) === String(value);
};

/**
 * Checks if value is empty. A value is considered empty unless the value is not string,hyphen or undefined
 * @param {string | number} data The value to inspect.
 * @return {boolean} Returns true if value is empty, else false.
 */
const isNonEmpty = (data: string | number) => data && data !== UNICODE.EMDASH;

/**
 * Checks equality
 * @param {string | number} data value to check against condition
 * @param {string | number} value value to check against
 * @returns {boolean}
 */
const isSame = (data: string | number, value: string | number | string[]) => {
  const dataToCheck = getMomentFromFormattedTimeString(data);
  const valueToCheck = moment(value);
  if (dataToCheck && dataToCheck.isValid() && valueToCheck.isValid()) {
    return dataToCheck.isSame(valueToCheck);
  }
  return false;
};

/**
 * Returns true if the data is less than value.
 * Used to compare numbers and dates (milliseconds)
 * @param {string | number} data value to check against condition
 * @param {string | number} value value to check against
 * @param {boolean} includeValue If true, checks if less than or equal
 * @returns {boolean}
 */
const isBefore = (
  data: string | number,
  value: string | number | string[],
  includeValue: boolean = false,
): boolean => {
  const dataToCheck = getMomentFromFormattedTimeString(data);
  const valueToCheck = moment(value);
  if (dataToCheck && dataToCheck.isValid() && valueToCheck.isValid()) {
    return includeValue
      ? dataToCheck.isSameOrBefore(valueToCheck)
      : dataToCheck.isBefore(valueToCheck);
  }
  return false;
};

/**
 * Returns true if the data is less than value.
 * Used to compare numbers and dates (milliseconds)
 * @param {string | number} data value to check against condition
 * @param {string | number} value value to check against
 * @param {boolean} includeValue If true, checks if less than or equal
 * @returns {boolean}
 */
const isAfter = (
  data: string | number,
  value: string | number | string[],
  includeValue: boolean = false,
): boolean => {
  const dataToCheck = getMomentFromFormattedTimeString(data);
  const valueToCheck = moment(value);
  if (dataToCheck && dataToCheck.isValid() && valueToCheck.isValid()) {
    return includeValue
      ? dataToCheck.isSameOrAfter(valueToCheck)
      : dataToCheck.isAfter(valueToCheck);
  }
  return false;
};

/**
 * Returns true if the data is less than value.
 * @param {string | number} data value to check against condition
 * @param {string | number} value value to check against
 * @param {boolean} includeValue If true, checks if less than or equal
 * @returns {boolean}
 */
const isLessOrEqual = (
  data: string | number,
  value: string | number | string[],
  includeValue: boolean = false,
) => {
  const dataToCheck = parseNumberString(data);
  const valueToCheck = Number(value);
  if (typeof dataToCheck === 'number' && typeof valueToCheck === 'number') {
    return includeValue
      ? dataToCheck <= valueToCheck
      : dataToCheck < valueToCheck;
  }
  return false;
};

/**
 * Returns true if the data is greater than value.
 * @param {string | number} data value to check against condition
 * @param {string | number} value value to check against
 * @param {boolean} includeValue If true, checks if less than or equal
 * @returns {boolean}
 */
const isGreaterOrEqual = (
  data: string | number,
  value: string | number | string[],
  includeValue: boolean = false,
) => {
  const dataToCheck = parseNumberString(data);
  const valueToCheck = Number(value);
  if (typeof dataToCheck === 'number' && typeof valueToCheck === 'number') {
    return includeValue
      ? dataToCheck >= valueToCheck
      : dataToCheck > valueToCheck;
  }
  return false;
};

const conditonStringToFuncMap = {
  is: isEquals,
  isNot: (data: string | number, value: string | number | string[]) => !isEquals(data, value),
  contains: containsValue,
  notContains: (data: string | number, value: string | number | string[]) =>
    !containsValue(data, value),
  isEmpty: (data: string | number) => !isNonEmpty(data),
  notEmpty: (data: string | number) => isNonEmpty(data),
  isBefore: (data: string | number, value: string | number | string[]) => isBefore(data, value),
  isAfter: (data: string | number, value: string | number | string[]) => isAfter(data, value),
  onOrBefore: (data: string | number, value: string | number | string[]) =>
    isBefore(data, value, true),
  onOrAfter: (data: string | number, value: string | number | string[]) =>
    isAfter(data, value, true),
  anyOf,
  noneOf: (data: string | number, value: string | number | string[]) => !anyOf(data, value),
  isLessThan: (data: string | number, value: string | number | string[]) =>
    isLessOrEqual(data, value),
  isGreaterThan: (data: string | number, value: string | number | string[]) =>
    isGreaterOrEqual(data, value),
  isLessOrEqualTo: (data: string | number, value: string | number | string[]) =>
    isLessOrEqual(data, value, true),
  isGreaterOrEqualTo: (data: string | number, value: string | number | string[]) =>
    isGreaterOrEqual(data, value, true),
  isSame,
  isNotSame: (data: string | number, value: string | number | string[]) => !isSame(data, value),
};

/**
 * Returns true if data satisfies the condition
 * @param {string} data value to check against condition
 * @param {string} conditon key of conditions
 * @param {string} value value to check against
 * @returns {boolean}
 */
const compareByCondition = (
  data: string,
  conditon: keyof typeof conditonStringToFuncMap,
  value: string | number | string[],
): boolean => {
  const compareBy = conditonStringToFuncMap[conditon];
  if (compareBy) {
    return compareBy(data, value);
  }
  return false;
};

/**
 * Filters the data by rules
 * @param {Array<Row>} tableData data to be filtered
 * @param {FilteringRule} filteringRules Rules to filter data
 * @returns {Array<Row>}
 */
export const filterWithConditions = (
  tableData: Array<any>,
  filteringRules: FilteringRule,
) => {
  const { logicalOp, filterRules } = filteringRules;
  if (!filterRules.length) {
    return tableData;
  }
  return tableData.filter(row => (logicalOp === 'or'
    ? filterRules.some(rule => compareByCondition(row[rule.id], rule.condition, rule.value))
    : filterRules.every(rule => compareByCondition(row[rule.id], rule.condition, rule.value))));
};
