import BaseModel from './baseModel';
import { isImageFile } from '../utils/utils';

type Asset = {
  id: string,
  datatype: string,
}

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'caseNoteFile',
  patient_id: string,
  category?: string,
  order: number,
  batch_id?: string,
  assets: Array<Asset>,
  star: boolean,
  title?: string,
  file_name?: string,
  hidden: boolean,
};

/**
   * CaseNoteFileModel
   *
   * @namespace CaseNoteFileModel
   */
class CaseNoteFileModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'caseNoteFile';
  }

  /**
   * Sets the salesItem visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered SalesItemModel.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Returns true if the first asset of the casenote is a text file (text casenotes sohuld only have
   * a single asset).
   * @returns {boolean}
   */
  isText() {
    const asset = this.getAsset(0);
    return asset && asset.datatype === 'text/plain';
  }

  /**
   * Gets the asset for the given asset index or returns undefined if not found.
   * @param {number} assetIndex Asset index
   * @returns {Asset | void}
   */
  getAsset(assetIndex: number) {
    if (this.has('assets') && this.filterNullFromAssets().length > assetIndex) {
      return this.filterNullFromAssets()[assetIndex];
    }
    return undefined;
  }

  /**
   * Gets the asset ID for the given asset index or returns undefined if not found.
   * @param {number} assetIndex Asset index
   * @returns {string | void}
   */
  getAssetID(assetIndex: number) {
    if (this.has('assets') && this.filterNullFromAssets().length > assetIndex) {
      return this.filterNullFromAssets()[assetIndex].id;
    }
    return undefined;
  }

  /**
   * @param {string} assetID The ID of the asset to get the URL for
   * @param {string} baseAPIURL The API URL.
   * @param {number} maxWidth An optional maxwidth to pass to the API to request a resizing of the
   * asset if it is larger.
   * @param {boolean} isImage True if asset is image. Determines API parameters.
   * @return {string} The asset URL.
   */
  getAssetUrl(assetID: string, baseAPIURL: string, maxWidth?: number, isImage: boolean = false) {
    let url = `${baseAPIURL}asset/${assetID}`;
    if (isImage) {
      url += '?auto_orient=1';
      if (maxWidth) {
        url = `${url}&scale=${maxWidth}`;
      }
    }
    return url;
  }

  /**
   * Returns a list of objects for the assets in this casenote. The maxWidth of
   * may be set in order to scale the image. The object contains a url and
   * a datatype item, where url is the url to get the asset and datatype is
   * the data type of the asset (see https://klinify.atlassian.net/wiki/display/KLIN/CaseNoteFile
   * for the list of valid data types).
   * @param {string} baseAPIURL The API URL.
   * @param {number} maxWidth An optional maxwidth to pass to the API to request a resizing of the
   * asset if it is larger.
   * @return {Object[]} this is return.
   */
  getAssetUrls(baseAPIURL: string, maxWidth?: number) {
    if (this.has('assets') && this.filterNullFromAssets().length && Array.isArray(this.filterNullFromAssets())) {
      return this.filterNullFromAssets().map(asset => (
        // if (asset.datatype && asset.datatype === 'text/x-markdown') {
        //   return {
        //     id: asset.id,
        //     url: this.getAssetUrl(asset.id, baseAPIURL),
        //     content: markdown.toHTML(this.getAsset(asset.id, baseAPIURL)),
        //     datatype: asset.datatype,
        //   };
        // }
        {
          id: asset.id,
          url: this.getAssetUrl(asset.id, baseAPIURL, maxWidth, isImageFile(asset.datatype)),
          datatype: asset.datatype,
        }));
    }
    throw new Error(`CaseNoteFile ${this.get('_id')} has no assets.`);
  }


  /**
   * Returns an asset URL for previewing. Throws an error if the model has no assets.
   * @param {string} baseAPIURL The API URL.
   * @return {string} The asset URL.
   */
  getPreviewAssetURL(baseAPIURL: string) {
    const assetUrls = this.getAssetUrls(baseAPIURL);
    if (assetUrls.length) {
      return assetUrls[0].url;
    }
    throw new Error(`CaseNoteFile ${this.get('_id')} has no assets.`);
  }

  /**
   * Returns true if the casenote is starred.
   * @returns {boolean}
   */
  isStarred(): boolean {
    return this.getOrFalse('star');
  }

  /**
   * Toggles the starred status of the casenote.
   * @returns {void}
   */
  toggleStarred() {
    return this.set('star', !this.isStarred());
  }

  // This check is needed because assets value set to [null],
  // if file failed to upload.
  /**
   * Check for the valid assets in the casenote
   * @returns {boolean} true if valid else false
   */
  hasValidAssets() {
    return this.get('assets', []).every(asset => !!asset);
  }

  /**
   * Filter null value from assets array.
   * @returns {Array<Asset>} Returns filtered assets.
   */
  filterNullFromAssets() {
    return this.get('assets').filter(asset => !!asset);
  }
}

export default CaseNoteFileModel;
