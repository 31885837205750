import React from 'react';

import { prettifyDateTime } from './../../utils/time';

import type BaseModel from './../../models/baseModel';

type Props = {
  model: BaseModel,
}


/**
 * Renders a history item.
 * @param {any} item An edited_by item
 * @param {boolean} isCreationEvent True if it is a creation event.
 * @returns {React.Component} A component displaying the edit info.
 */
function renderItem(item, isCreationEvent) {
  return (
    <div key={`${item.user_id}_${item.timestamp}`}>
      {isCreationEvent ? 'Created' : 'Edited'} by {item.user_id} at {prettifyDateTime(item.timestamp)}.
    </div>
  );
}

/**
   * EditHistorySummary
   *
   * @returns {ReactComponent} - Text content for edit history.
   */
const EditHistorySummary = ({ model }: Props) => (
  <div className="u-padding--standard">
    {
      model
        .getEditHistory()
        .toArray()
        .map((item, index) => renderItem(item, index === 0))
        .reverse()
    }
  </div>
);

export default EditHistorySummary;
