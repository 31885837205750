import PatientModel from './patientModel';
import translate from './../utils/i18n';

import type { MapValue } from './../types';

export type PatientStubAttributes = {
  type: 'patient',
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  patient_name: string,
  ic: string | void,
  case_id?: string,
  dob: string | void,
  sex: string | void,
  ethnicity?: string | Array<string>,
  postal_code?: string,
  email?: string,
  address?: string,
  coverage: Array<{
    payor: string,
    subscriber: string,
    relationship: string,
    policy: string,
    policyHolder: string,
  }>,
  [key: string]: MapValue, // Can happen when a stub is created locally, it'll get extra attrs from PatientModel
};

/**
   * PatientStubModel
   * @namespace PatientStubModel
   */
class PatientStubModel extends PatientModel {
  attributes: PatientStubAttributes;

  /**
   * Returns true as this is a stub.
   * @returns {boolean} Always returns true.
   */
  isStub(): boolean { return true; }

  /**
   * This just throws an error as you shouldnt be mutating a patientStub. It overrides
   * BaseModel.set()
   * @returns {Error} Throws an error.
   */
  set() {
    throw new Error('Dont be trying to mutate a stub!!!');
  }

  /**
   * patientStubModel does not have access to ethnicity.
   * @returns {String} A string stating that ethnicity is unavailable offline.
   */
  getEthnicity(): string {
    return translate('unavailable_offline');
  }

  /**
   * Returns true if each word of query matches one of the following: name, case id, ic, ph number.
   * @param {string} query The query to search for.
   * @returns {boolean} True if the query was matched, false otherwise.
   */
  matchesQuery(query: string): boolean {
    return query.split(' ').every(queryWord =>
      this.nameMatchesQuery(queryWord) ||
      this.caseIDMatchesQuery(queryWord) ||
      this.ICMatchesQuery(queryWord) ||
      this.TelMatchesQuery(queryWord));
  }

  /**
   * @param {string} query A search query to match against the patient's name
   * @return {boolean} Returns true if query matches patient name.
   */
  nameMatchesQuery(query: string): boolean {
    const nameStrings = this.getOrBlank('patient_name').split(' ');
    return nameStrings.some(str => str.toLowerCase().startsWith(query.toLowerCase()));
  }

  /**
   * @param {string} query A search query to match against the patient's case ID
   * @return {boolean} Returns true if query matches patient case ID.
   */
  caseIDMatchesQuery(query: string): boolean {
    return this.getOrBlank('case_id').toLowerCase().indexOf(query.toLowerCase()) > -1;
  }

  /**
   * @param {string} query A search query to match against the patient's IC number
   * @return {boolean} Returns true if query matches patient IC number.
   */
  ICMatchesQuery(query: string): boolean {
    return this.getOrBlank('ic').toLowerCase().indexOf(query.toLowerCase()) === 0;
  }

  /**
   * @param {string} query A search query to match against the patient's Tel number
   * @return {boolean} Returns true if query matches patient Tel number.
   */
  TelMatchesQuery(query: string): boolean {
    return this.getOrBlank('tel').toLowerCase().indexOf(query.toLowerCase()) === 0;
  }
}

export default PatientStubModel;
