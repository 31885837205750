import React from 'react';
import type { List, Map } from 'immutable';
import { Route, Switch } from 'react-router-dom';

import InventoryMapModel from './../../models/inventoryMapModel';
import DrugModel from './../../models/drugModel';
import { PatientRoute } from './../../utils/router';
import AddVitalsContainer from './../../containers/addVitalsContainer';
import AddNewBillContainer from './../../containers/addNewBillContainer';
import BillContainer from './../../containers/billContainer';
import BillPaymentsContainer from './../../containers/billPaymentsContainer';
import CasenoteEditorContainer from './../../containers/casenoteEditorContainer';
import CategoryOverviewContainer from './../../containers/categoryOverviewContainer';
import DocumentsContainer from './../../containers/documentsContainer';
import NotFoundContainer from './../../containers/notFoundContainer';
import PatientBillingHistoryContainer from './../../containers/patientBillingHistoryContainer';
import PatientOverview from './../patientOverview/patientOverview';
import PatientDetailsContainer from '../../containers/patientDetailsContainer';
import PatientProfileEditor from './../patient/patientProfileEditor';
import TemplatesContainer from './../../containers/templatesContainer';
import VitalsHistoryContainer from './../../containers/vitalsHistoryContainer';
import PatientPageSettings from './../settings/patientPageSettings';

import type BillModel from './../../models/billModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type EncounterModel from './../../models/encounterModel';
import type AllergyModel from './../../models/allergyModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type TimeChitModel from './../../models/timeChitModel';
import type { SaveModel, Config, ReactRouterLocation, ReactRouterMatch, User, ActiveIngredient } from './../../types';

type Props = {
  location: ReactRouterLocation, // eslint-disable-line react/no-unused-prop-types
  match: ReactRouterMatch,
  isOnline: boolean, // eslint-disable-line react/no-unused-prop-types
  patient: PatientModel,
  patientStub: PatientStubModel,
  patientStubs: List<PatientStubModel>,
  config: Config, // eslint-disable-line react/no-unused-prop-types
  klinifyConfig: Config, // eslint-disable-line react/no-unused-prop-types
  // TODO: Below is dumb stuff to get flow not to throw errors. A better solution would be to wrap
  // PatientOverview and PatientProfileEditor in containers so they dont require passed through props
  allergies: List<AllergyModel>, // eslint-disable-line react/no-unused-prop-types
  bills: List<BillModel>, // eslint-disable-line react/no-unused-prop-types
  coveragePayors: List<CoveragePayorModel>, // eslint-disable-line react/no-unused-prop-types
  encounters: List<EncounterModel>, // eslint-disable-line react/no-unused-prop-types
  medicalCertificates: List<MedicalCertificateModel>, // eslint-disable-line react/no-unused-prop-types
  salesItems: List<SalesItemModel>, // eslint-disable-line react/no-unused-prop-types
  timeChits: List<TimeChitModel>, // eslint-disable-line react/no-unused-prop-types
  user: User, // eslint-disable-line react/no-unused-prop-types
  saveModel: SaveModel, // eslint-disable-line react/no-unused-prop-types
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
}

/**
   * Patient - A full screen component with Sidebar, Header, and the currently selected sub
   * component for a patient.
   * @namespace Patient
   */
class Patient extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    // No record of patient so it's assumed they dont exist.
    if (this.props.patient || this.props.patientStub) {
      return (
        <Switch>
          <PatientRoute exact path="/patient/:patientID" component={this.props.config.get('use_legacy_scheduling', false) ? PatientOverview : PatientDetailsContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/billing" component={PatientBillingHistoryContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/billing/add" component={AddNewBillContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/billing/:encounterID" component={BillContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/billing/:encounterID/payments" component={BillPaymentsContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/categories" component={CategoryOverviewContainer} allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/categories/:categoryID/add/:templateID?" component={CasenoteEditorContainer} {...this.props} />
          <PatientRoute exact path="/patient/:patientID/categories/:categoryID/add-text" component={CasenoteEditorContainer} {...this.props} />
          <PatientRoute exact path="/patient/:patientID/categories/:categoryID/:casenoteID?" component={CategoryOverviewContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/casenotes/:casenoteID/edit/:assetIndex?" component={CasenoteEditorContainer} {...this.props} />
          <PatientRoute exact path="/patient/:patientID/documents" component={DocumentsContainer} allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/profile/edit" component={PatientProfileEditor} allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/templates" component={TemplatesContainer} allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/templates/:templateGroupID" component={TemplatesContainer} allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/vitals/ALL" component={VitalsHistoryContainer} showActionButton allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/vitals/:metricTypeID/add" component={AddVitalsContainer} allowReferral {...this.props} />
          <PatientRoute exact path="/patient/:patientID/page-settings" component={PatientPageSettings} allowReferral {...this.props} />
          <Route path="*" component={NotFoundContainer} />
        </Switch>
      );
    }
    return <NotFoundContainer />;
  }
}

export default Patient;
