import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import translate from './../utils/i18n';
import Banner from '../components/outbreak/banner';
import { wsUnit, colours, borderRadius } from './../utils/css';

const brochuresURL = '/static/outbreak/brochures.json';

const Page = glamorous.div({
  padding: `${wsUnit} calc(1.5 * ${wsUnit})`,
  height: '100vh',
});

const FetchError = glamorous.div({
  marginTop: wsUnit,
  border: `1px solid ${colours.red}`,
  padding: `calc(0.5 * ${wsUnit})`,
  borderRadius,
});

const TabItem = glamorous.span({
  padding: '6px 12px',
  border: '1px solid silver',
  cursor: 'pointer',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const TabItemSelected = glamorous.span({
  padding: '6px 12px',
  border: '1px solid silver',
  backgroundColor: 'silver',
});

const CardContainer = glamorous.div({
  backgroundColor: 'white',
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '2rem',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
});

const DownloadButton = glamorous.button({
  marginBottom: '1rem',
});

const Img = glamorous.img({
  maxWidth: '100%',
});

const Obj = glamorous.object({
  height: '80vh',
  width: '100%',
});

/**
 * Render a brochure based on its type
 * @param {Object} brochure filter from state
 * @returns {HTMLElement} html element
 */
const renderBrochure = (brochure) => {
  if (!brochure) return null;
  const url = `/static/outbreak/brochures/${brochure.file}`;
  switch (brochure.type) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return (
        <div>
          <div>
            <a href={url} download>
              <DownloadButton className="o-button o-button--small">
                {translate('download')}
              </DownloadButton>
            </a>
          </div>
          <Img src={url} alt="brochure" />
        </div>
      );
    case 'pdf':
      return (
        <Obj
          data={url}
          type="application/pdf"
          aria-label="brochure"
          alt="brochure"
          style={{
            height: '80vh',
            width: '100%',
          }}
        />
      );
    default:
      return null;
  }
};

/**
 * A container for showing outbreak guidelines
 * Skipping the redux integration as this container doesn't need a state management
 * @class OutbreakBrochuresContainer
 * @extends {React.Component<Props, State>}
 */
const OutbreakBrochuresContainer = () => {
  const [selected, selectBrochure] = useState(null);
  const [brochures, setBrochures] = useState([]);
  const [isError, setError] = useState(false);

  useEffect(() => {
    fetch(brochuresURL)
      .then(async (result) => {
        const text = await result.text();
        const b = JSON.parse(text);
        setBrochures(b.brochures);
        selectBrochure(b.brochures[0]);
        setError(false);
      })
      .catch(() => {
        setBrochures([]);
        selectBrochure(null);
        setError(true);
      });
  }, []);

  return (
    <Page className="c-markdown o-scrollable-container">
      <Banner />
      <h1 data-public>{translate('outbreak_brochures')}</h1>
      {isError ? <FetchError>{translate('outbreak_brochures_fetch_err')}</FetchError> : null}
      {brochures.map((brochure) => {
        if (selected && brochure.file === selected.file) {
          return (
            <TabItemSelected key={brochure.file}>
              {brochure.title}
            </TabItemSelected>
          );
        }
        return (
          <TabItem key={brochure.file} onClick={() => selectBrochure(brochure)}>
            {brochure.title}
          </TabItem>
        );
      })}
      <CardContainer>{renderBrochure(selected)}</CardContainer>
    </Page>
  );
};

export default OutbreakBrochuresContainer;
