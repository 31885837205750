import BaseModel from './baseModel';

export type PolicyAttributes = {
  _id: string,
  type?: 'coverage_payor_policy',
  policy_name: string,
  policy_maximum_claimable_amount: number | null,
  policy_co_payment_amount: number | null,
  active: boolean,
};

/**
 * CoveragePayorPolicyModel
 *
 * @namespace CoveragePayorPolicyModel
 */
class CoveragePayorPolicyModel extends BaseModel {
  attributes: PolicyAttributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'coverage_payor_policy';
  }

  /**
   * Returns true if item does has an active flag.
   * @returns {boolean} True if item is active.
   */
  isVisible(): boolean {
    return this.get('active', false);
  }

  /**
   * Sets the CoveragePayorPolicyModel visibility to the  given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered CoveragePayorModel.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('active', isVisible);
  }
}
export default CoveragePayorPolicyModel;
