import * as React from 'react';

import Keypress from './../keypress';
import Input from './../inputs/input';
import TextArea from './../inputs/textarea';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import CoveragePayorModel from './../../models/coveragePayorModel';
import CoveragePayorPolicyModel from './../../models/coveragePayorPolicyModel';
import { createSuccessNotification } from './../../utils/notifications';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import ModalFooter from './../modals/modalFooter';
import CoveragePayorInvoiceSettingsForm from './coveragePayorInvoiceSettingsForm';
import CoveragePayorPolicyForm from './coveragePayorPolicyForm';
import Button from './../buttons/button';
import { validateAndTrimString } from './../../utils/utils';

import type { InvoiceSettings } from './../../models/coveragePayorModel';
import type { PolicyAttributes } from './../../models/coveragePayorPolicyModel';

import type BaseModel from './../../models/baseModel';

type Props = {
  clearModelToEdit?: () => void,
  modelToEdit?: CoveragePayorModel,
  saveModel: (model: BaseModel) => Promise<BaseModel>,
  labelClassName?: string,
  autofocus?: string,
  saveIsSticky: boolean,
  noClearButton?: boolean,
  onSave?: (model: CoveragePayorModel) => void,

};

type State = {
  isSaving: boolean,
  itemName: string,
  itemClinicID: string,
  itemPolicies: Array<PolicyAttributes>,
  itemAddress: string,
  invoiceSettings: InvoiceSettings,
  changesMade: boolean,
  errorMessage?: string,
}

const defaultState: State = {
  isSaving: false,
  itemName: '',
  itemClinicID: '',
  itemPolicies: [new CoveragePayorPolicyModel({ policy_name: '', policy_co_payment_amount: null, policy_maximum_claimable_amount: null, active: true }).attributes],
  itemAddress: '',
  invoiceSettings: {
    is_landscape: false,
    show_treatment_details: false,
    show_treatment_breakdown: false,
  },
  changesMade: false,
  errorMessage: undefined,
};

/**
 * Returns State object based on the updated model
 * @param {CoveragePayorModel} modelToEdit Model to transform
 * @returns {object}
*/
const modelToState = (modelToEdit: CoveragePayorModel) => ({
  itemName: modelToEdit.get('name', defaultState.itemName),
  itemClinicID: modelToEdit.get('internal_clinic_id', defaultState.itemClinicID),
  itemPolicies: modelToEdit.get('policies', defaultState.itemPolicies),
  itemAddress: modelToEdit.get('address', defaultState.itemAddress),
  invoiceSettings: modelToEdit.get('invoice_settings', defaultState.invoiceSettings),
  errorMessage: defaultState.errorMessage,
  changesMade: defaultState.changesMade,
});


/**
 * A form for creating and editing Coverage Payor models.
 * @class CoveragePayorForm
 * @extends {React.Component<Props, State>}
 */
class CoveragePayorForm extends React.Component<Props, State> {
  static defaultProps = {
    labelClassName: '',
    saveIsSticky: false,
    noClearButton: false,
  };

  /**
   * Creates an instance of CoveragePayorForm.
   * @param {Props} props Props.
   */
  constructor(props: Props) {
    super(props);
    if (this.props.modelToEdit) {
      this.state = {
        ...defaultState,
        ...modelToState(this.props.modelToEdit),
      };
    } else {
      this.state = defaultState;
    }
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    if (
      !validateAndTrimString(this.state.itemName).length ||
      this.state.itemPolicies.some(policy =>
        !validateAndTrimString(policy.policy_name) ||
        validateAndTrimString(policy.policy_name).length === 0)
    ) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    return true;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const panelModelData = {
        name: this.state.itemName,
        internal_clinic_id: this.state.itemClinicID,
        policies: this.state.itemPolicies,
        invoice_settings: this.state.invoiceSettings,
        address: this.state.itemAddress,
      };
      if (this.props.modelToEdit) {
        const panelModel = this.props.modelToEdit.set(validateAndTrimString(panelModelData));
        return this.props.saveModel(panelModel).then(() => {
          createSuccessNotification(translate('coverage_payor_updated'));
          this.setState(defaultState);
          if (this.props.onSave) this.props.onSave(panelModel);
          if (this.props.clearModelToEdit) {
            this.props.clearModelToEdit();
          }
          return true;
        });
      }
      const panelModel = new CoveragePayorModel({
        ...validateAndTrimString(panelModelData), active: true,
      });
      return this.props.saveModel(panelModel).then(() => {
        createSuccessNotification(translate('coverage_payor_added'));
        this.setState(defaultState);
        if (this.props.onSave) this.props.onSave(panelModel);
        if (this.props.clearModelToEdit) {
          this.props.clearModelToEdit();
        }
        return true;
      });
    }
    return Promise.resolve(false);
  }


  /**
   * Called when Add new policy type button is clicked. Adds a new policy.
   * @returns {void}
   */
  onAddPolicyClick = () => {
    const model = new CoveragePayorPolicyModel({ policy_name: '', policy_co_payment_amount: null, policy_maximum_claimable_amount: null, active: true }).attributes;
    this.setState({
      itemPolicies: [...this.state.itemPolicies, model],
    });
  }

  /**
   * Called from child components to update policies array.
   * @param {CoveragePayorPolicyModel} model the model
   * @returns {void}
   */
  storePolicy = (model: PolicyAttributes) => {
    this.setState({
      itemPolicies: this.state.itemPolicies.map(el => ((el._id === model._id) ? model : el)),
    });
  }

  /**
   * Called when component wil receive props. Updates the state to either match the model being
   * edited or reset state.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      if (nextProps.modelToEdit) {
        const { modelToEdit } = nextProps;
        this.setState(modelToState(modelToEdit));
      } else {
        this.setState(defaultState);
      }
    }
  }

  /**
   * Render footer buttons
   * @param {boolean} isSticky whether save button is added to a sticky footer
   * @returns {React.Component}
   */
  renderFooterButton = (isSticky: boolean) => {
    if (isSticky) {
      return (
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('save')}
            onClick={() => this.onSaveClicked()}
          />
          {
            this.props.modelToEdit && !this.props.noClearButton &&
            <Button
              onClick={this.props.clearModelToEdit}
              className="o-button o-button--small o-button--danger u-margin-right--half-ws"
              dataPublic
            >
              {translate('cancel')}
            </Button>
          }
        </ModalFooter>
      );
    }
    return (
      <SaveButton
        dataPublic
        className="o-button--small u-margin-bottom--4ws"
        isSaving={this.state.isSaving}
        label={this.props.modelToEdit ? translate('save') : translate('add_new_coverage_payor')}
        onClick={() => this.onSaveClicked()}
        fullWidth
      />
    );
  }


  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <React.Fragment>
        <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
          <SavePrompt when={this.state.changesMade} onSaveClicked={() => this.onSaveClicked()} />
          {
            this.state.errorMessage &&
            <FormError isSticky containerElementID="right-sidebar">
              {this.state.errorMessage}
            </FormError>
          }
          <Input
            id="item_name"
            label={translate('name')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemName}
            onValueChanged={value => this.setState({ itemName: value, changesMade: true })}
            required
            autoFocus={this.props.autofocus === 'item_name'}
          />
          <Input
            id="item_clinic_id"
            label={translate('panel_id')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemClinicID}
            type="text"
            onValueChanged={value => this.setState({ itemClinicID: value, changesMade: true })}
          />
          <TextArea
            id="item_address"
            label={translate('address')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemAddress}
            onValueChanged={value => this.setState({ itemAddress: value, changesMade: true })}
          />
          {
             this.state.itemPolicies
               .filter(model => model.active)
               .map((model, index) => <CoveragePayorPolicyForm
                 key={model._id}
                 itemCount={index + 1}
                 policy={model}
                 storePolicy={this.storePolicy}
               />)
          }
          <SaveButton
            dataPublic
            className="o-button o-button--small u-margin-right--half-ws"
            label={translate('add_new_policy_type')}
            onClick={() => this.onAddPolicyClick()}
          />
          <section>
            <hr />
            <h2 data-public className="o-label u-margin-bottom--1ws" style={{ fontSize: '1rem' }}>{translate('print_settings')}</h2>
            <CoveragePayorInvoiceSettingsForm
              value={this.state.invoiceSettings}
              onValueChanged={(invoiceSettings: InvoiceSettings) =>
                this.setState({
                  invoiceSettings,
                  changesMade: true,
                })
              }
            />
          </section>
          {!this.props.saveIsSticky && this.renderFooterButton(this.props.saveIsSticky)}
        </Keypress>
        {this.props.saveIsSticky && this.renderFooterButton(this.props.saveIsSticky)}
      </React.Fragment>
    );
  }
}

export default CoveragePayorForm;
