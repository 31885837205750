import { connect } from 'react-redux';

import Templates from './../components/templates/templates';
import { sortModelByOrder } from './../utils/comparators';

import type { State } from './../types';

type Props = {
  patientID: string,
  templateGroupID?: string,
};

/**
   * @param {Object} state Current app state.
   * @param {Props} ownProps Container props.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  { templates, templateGroups, isOnline }: State,
  { patientID, templateGroupID }: Props,
) => {
  const orderedTemplates = templates.sort(sortModelByOrder);
  return {
    templateGroup: templateGroupID ? templateGroups.find(tg => tg.get('_id') === templateGroupID) : undefined,
    templates: templateGroupID ? orderedTemplates.filter(t => t.get('template_group') === templateGroupID) : orderedTemplates,
    templateGroups: templateGroups.sort(sortModelByOrder),
    patientID,
    isOnline,
  };
};

const TemplatesContainer = connect(mapStateToProps)(Templates);

export default TemplatesContainer;
