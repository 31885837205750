import React, { useEffect, useState } from 'react';
import Confirm from './confirm';

type Props = {
  visible: boolean;
  onClose: () => void;
  timeToClose: number; // time in milliseconds
  modalTitle: string | React$Node;
  content: string | React$Node;
}

/**
 * Returns a Modal that can be auto closed in time provided
 * @param {Props} props Props
 * @returns {React.SFC}
 */
const AutoClosablePrompt = ({ visible, onClose, timeToClose, modalTitle, content }: Props) => {
  const [timeLeft, setTimeLeft] = useState(timeToClose);

  useEffect(() => {
    let interval;
    if (visible) {
      setTimeLeft(timeToClose);
      interval = setInterval(() => {
        setTimeLeft((t) => {
          if (t > 0) {
            return t - 1000;
          }
          // Need to execute next return before onClose, hence the timeout.
          setTimeout(() => onClose(), 0);
          return t;
        });
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [visible]);

  const closeButtonLabel = `Close (${(timeLeft) / 1000})`;

  return (
    <Confirm
      explicitCloseOnly
      show={visible}
      proceed={onClose}
      modalTitle={modalTitle}
      footerSaveButtonName={closeButtonLabel}
      confirmation={content}
      cancel={false}
      proceedButtonClassName="o-button--padded"
    />
  );
};

export default AutoClosablePrompt;
