
import BaseModel from './baseModel';


type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'transaction',
  sku_id: string,
  change: number,
  source_type: 'bill' | 'supply_item' | 'bonus' | 'loan_in' | 'transfer_in' | 'write_off' | 'damaged' | 'expired' | 'loan_out' | 'transfer_out' | 'missing' | 'dispensing_error' | 'consumed' | 'adjustment' | 'bill_item',
  source_id?: string,
  notes?: string,
  timestamp?: number, // Not optional but legacy data might not have this field. Best way to get time of Transaction is getTimestamp() function below.
  supply_item_id?: string,
};

const SUPPLY_ITEM_SOURCES = ['loan_in', 'transfer_in', 'supply_item', 'bonus', 'loan_out', 'transfer_out'];
const ADJUSTMENT_SOURCE = ['write_off', 'damaged', 'expired', 'returned', 'recalled', 'missing', 'adjustment', 'dispensing_error', 'consumed'];

/**
   * TransactionModel
   * @namespace TransactionModel
   */
class TransactionModel extends BaseModel {
  attributes: Attributes;

  totalAfterChange: number;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'transaction';
    this.attributes.source_id = this.getInitialSourceId(attributes as Attributes);
  }

  /**
   * Set default source id to same as supply_item_id where adjustment transactions souce_id is not set
   * @param {Attributes} attributes attributes of
   * @returns {(string | void)}
   */
  getInitialSourceId(attributes: Attributes) {
    const sourceId = attributes.source_id;
    const sourceType = attributes.source_type;
    const supplyItemId = attributes.supply_item_id;
    if (sourceId) {
      return sourceId;
    } else if (ADJUSTMENT_SOURCE.includes(sourceType) && supplyItemId) {
      return supplyItemId;
    }
    return undefined;
  }

  /**
   * returns the source_id
   * @returns {(string | void)}
   */
  getSourceDoc(): string | void {
    return this.get('source_id');
  }

  /**
   * Gets the total count for this SKU in inventory after change. This is calculated locally and so
   * shouldn't be treated as definitive.
   * @returns {number} The total number of SKU in the inventory after this transaction.
   */
  getTotalAfterChange(): number {
    return this.totalAfterChange || 0;
  }

  /**
   * Sets the total count for this SKU in inventory after change. This is calculated locally and so
   * shouldn't be treated as definitive.
   * @param {number} total The total number of SKU in the inventory after this transaction.
   * @returns {void}
   */
  setTotalAfterChange(total: number) {
    this.totalAfterChange = total;
  }

  /**
   * Returns a human readable string of the change with + or - prepended.
   * @returns {string}
   */
  getChange(): string {
    if (!this.has('change')) {
      return '0';
    }
    if (this.get('change') > 0) {
      return `+${this.get('change')}`;
    }
    return Number(this.get('change')).toString();
  }

  /**
   * Returns a source type of the transaction,
   * @returns {string}
   */
  getType(): string {
    const type = this.get('source_type');
    if (type === 'bill' || type === 'bill_item') {
      return 'dispensation';
    } if (type === 'supply_item') {
      return 'supply';
    }
    return type;
  }

  /**
   * Returns the timestamp to use for this transaction. Tries timestamp field then falls back to
   * create_by.timestamp
   * @returns {number}
   */
  getTimestamp(): number {
    return this.has('timestamp') ? this.get('timestamp') : this.getCreatedTime();
  }

  /**
   * Returns whether the transaction has a supply item with purchase price data associated with it
   * @returns {boolean}
   */
  hasPurchasePriceData(): boolean {
    return this.get('source_type') && SUPPLY_ITEM_SOURCES.includes(this.get('source_type'));
  }
}

export default TransactionModel;
