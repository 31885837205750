import React from 'react';

import MedicalCertificateModel from '../../models/medicalCertificateModel';
import EncounterModel from '../../models/encounterModel';
import StatelessModal from '../modals/statelessModal';
import MedicalCertificateForm from './medicalCertificateForm';

import type { Config, SaveModel } from '../../types';

type Props = {
  config: Config,
  currentModel?: MedicalCertificateModel,
  initialDate?: number,
  encounterID?: string,
  encounter?: EncounterModel,
  onSave: SaveModel,
  patientID?: string,
  modalId?: string,
  modalButtonLabel?: string,
  modalButtonClass?: string,
  modalTitle?: string,
  isModalVisible: (value: boolean) => void,
  modalVisible: boolean,
  explicitCloseOnly?: boolean,
  modalClassName?: string,
  modalDisableButton?: boolean,
  dataPublicHeader?: boolean,
};

type State = {
  showPrompt: boolean,
  changesMade: boolean,
};

/**
 * Form for creating Medical Certificates.
 * @namespace MedicalCertificateForm
 * @returns {React.ComponentType}
 */
class MedicalCertificateFormModal extends React.Component<Props, State> {
  /**
   * Instantiate the state of the component.
   * @param {props} props - The props for the component
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      changesMade: false,
      showPrompt: false,
    };
  }

  handleClose = () => {
    this.setState({
      changesMade: false,
      showPrompt: false,
    });
    this.props.isModalVisible(false);
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <StatelessModal
        id={this.props.modalId || ''}
        buttonLabel={this.props.modalButtonLabel || ''}
        buttonClass={this.props.modalButtonClass || ''}
        title={this.props.modalTitle || ''}
        className={this.props.modalClassName}
        setVisible={(isVisible: boolean) => {
          if (this.state.changesMade && this.props.modalVisible && !isVisible) {
            this.setState({ showPrompt: true });
          } else {
            this.props.isModalVisible(isVisible);
          }
        }}
        onClose={() => {
          if (this.state.changesMade) {
            this.setState({
              showPrompt: true,
            });
          } else {
            this.handleClose();
          }
        }}
        visible={this.props.modalVisible}
        explicitCloseOnly={this.props.explicitCloseOnly}
        disableButton={this.props.modalDisableButton}
        dataPublicHeader={this.props.dataPublicHeader}
      >
        <MedicalCertificateForm
          config={this.props.config}
          currentModel={this.props.currentModel}
          initialDate={this.props.initialDate}
          encounterID={this.props.encounterID}
          patientID={this.props.patientID}
          practitionerID={this.props.encounter?.getValue('doctor')}
          onSave={this.props.onSave}
          onChangesMade={changesMade => this.setState({ changesMade })}
          showPrompt={this.state.showPrompt}
          onPromptCancel={() => this.setState({ showPrompt: false })}
          onDiscard={this.handleClose}
        />
      </StatelessModal>
    );
  }
}

export default MedicalCertificateFormModal;
