import React, { useEffect, useState } from 'react';
import glamourous from 'glamorous';
import type { RouteComponentProps } from 'react-router-dom';
import { fetchRandomDetailing, savePharmaConnectEvent } from './../../utils/api';
import DetailingResponseModel from '../../models/pharmaconnect/detailingResponseModel';
import { colours } from '../../utils/css';
import LoadingIndicator from '../loadingIndicator';

const Container = glamourous.div({
  maxWidth: '100%',
  position: 'relative',
});

const Img = glamourous.img({
  maxWidth: '100%',
});

const buttonStyles = {
  cursor: 'pointer',
  position: 'absolute',
  top: 'calc(50% - 28px)',
  padding: '16px',
  color: 'white',
  fontSize: '18px',
  textShadow: `${colours.grey6} 1px 0 6px`,
  userSelect: 'none',
  transition: '0.2s ease',
  ':hover': {
    color: 'black',
  },
};

// TODO: Figure out these override errors
const PrevButton = glamourous.a({
  left: '0',
}, buttonStyles);

const NextButton = glamourous.a({
  right: '0',
}, buttonStyles);

interface Props extends RouteComponentProps {
  detailing: DetailingResponseModel,
  setDetailing: (detail: DetailingResponseModel) => void
  showControls?: boolean,
  cycleAdverts?: boolean,
}

/**
 * Renders the detailing
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function PharmaConnectDetailing(props: Props) {
  const { detailing, setDetailing, showControls = false, cycleAdverts = false } = props;
  const [loading, setLoading] = useState(false);

  /**
   * Returns and displays random advertisement banner
   * @returns {void} void
  */
  const getAdvert = () => {
    setLoading(true);
    fetchRandomDetailing('SM').then((res) => {
      setLoading(false);
      setDetailing(res);
    }).catch(() => {
      setLoading(false);
      setDetailing(null);
    });
  };

  /**
   * Saves the viewed pharmaconnect event
   * @returns {void} void
  */
  const sendReadEvent = () => {
    savePharmaConnectEvent('READ', 'AD', detailing.get('id', ''));
  };

  // Add a timer to cycle through ads
  useEffect(() => {
    if (!cycleAdverts) {
      getAdvert();
      return () => {
        if (detailing) {
          sendReadEvent();
        }
      };
    }
    const intervalID = setInterval(
      () => getAdvert(),
      10000,
    );
    return () => {
      if (detailing) {
        sendReadEvent();
      }
      clearInterval(intervalID);
    };
  }, []);

  // Silently fail as it is an 'ad'
  if (!detailing) return null;

  return (
    <Container>
      <Img src={detailing.get('blob_url')} alt="alt" style={{ opacity: loading ? '0.5' : '1' }} />
      {loading && <LoadingIndicator
        style={{
          position: 'absolute',
          top: 'calc(50% - 16px)',
          left: 'calc(50% - 16px)',
        }}
        type="spin"
        size={32}
        alignCenter
      />}
      { showControls &&
        <>
          <PrevButton onClick={() => getAdvert()}>❮</PrevButton>
          <NextButton onClick={() => getAdvert()}>❯</NextButton>
        </>
      }
    </Container>
  );
}

export default PharmaConnectDetailing;
