import type { List } from 'immutable';
import BaseModel from './baseModel';

import type ProcedureRequestModel from './procedureRequestModel';
import type ProcedureStatusModel from './procedureStatusModel';

type Event = {
  status: 'pending'
    | 'collected'
    | 'courier_contacted'
    | 'courier_collected'
    | 'provider_received'
    | 'provider_rejected',
  timestamp: number,
  notes: string,
  fasting?: boolean,
  collected_by?: string,
};

type Events = Array<Event>;

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'collected_specimen',
  procedure_request_id: string,
  procedure_type_id: string,
  specimen_id: string,
  patient_id: string,
  is_void?: boolean,
  fasting?: boolean,
  events: Events,
};

/**
  * CollectedSpecimenModel
  * @namespace CollectedSpecimenModel
  */
class CollectedSpecimenModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'collected_specimen';
  }

  /**
   * Adds an event with the given type to the CollectedSpecimenModel.
   * @param {string} status The status of event.
   * @param {string} notes The notes for the event.
   * @returns {EncounterModel} Returns the updated encounter.
   */
  addEvent(status: string, notes: string = '') {
    const newEvents = this.get('events', []);
    newEvents.push({ status, notes, timestamp: new Date().getTime() });
    this.set('events', newEvents);
    return this;
  }

  /**
   * Gets the last event status. If no event is found then the default event status 'pending' is returned.
   * @returns {string} The last event type.
   */
  getLastEventStatus() {
    if (!this.has('events') || this.get('events', []).length === 0) {
      return 'pending';
    }
    return this.get('events')[this.get('events').length - 1].status;
  }

  /**
   * Returns a (unstranslated) string that will be use in displaying collected specimen status in the table
   * eg. 'courier_collected', 'specimens_received_by'.
   * @param {ProcedureRequestModel} procedureRequest a ProcedureRequestModel.
   * @param {List<ProcedureStatusModel>} procedureStatuses List of ProcedureStatusModel.
   * @returns {string}
   */
  getUiStatus(procedureRequest: ProcedureRequestModel,
    procedureStatuses: List<ProcedureStatusModel>) {
    switch (this.getLastEventStatus()) {
      case 'courier_collected': {
        if (procedureRequest.getStatusLastEventStatus(procedureStatuses) === 'results_received') {
          return 'results_received';
        }
        return 'courier_collected';
      }
      case 'provider_received':
        return 'specimens_received_by';
      case 'provider_rejected':
        return 'specimens_rejected';
      default:
        return procedureRequest.getStatusUiStatus(procedureStatuses);
    }
  }

  /**
   * Returns true if status events has status of given param
   * @param {string} status Event status
   * @returns {boolean}
   */
  hasStatus(status: string) {
    return this.get('events', []).some(model => model.status === status);
  }
}

export default CollectedSpecimenModel;
