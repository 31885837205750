import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import LabsTable from './labsTable';
import LabsForm from './labsForm';
import Header from './../header/header';

import type ProviderModel from './../../models/providerModel';
import type { SaveModel, Config, User } from './../../types';

type Props = {
  providers: List<ProviderModel>,
  saveModel: SaveModel,
  config: Config,
  user: User,
};

type State = {
  isModalVisible: boolean,
};

/**
 * A component for Labs.
 * @class Labs
 * @extends {Component}
 */
class Labs extends React.Component<Props, State> {
  /**
   * Creates an instance of Labs.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isModalVisible: false };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { providers, saveModel, user } = this.props;
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('laboratories')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <PermissionWrapper permissionsRequired={List([createPermission('laboratories', 'create')])} user={user}>
              <StatelessModal
                id="addNewLabModal"
                buttonLabel={translate('add_new_lab')}
                buttonClass="o-button o-button--small"
                title={translate('add_edit_laboratory')}
                className="js-modal"
                visible={this.state.isModalVisible}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                explicitCloseOnly
                dataPublicHeader
              >
                <LabsForm
                  onSave={provider =>
                    this.props.saveModel(provider).then(() => {
                      this.setState({ isModalVisible: false });
                      return provider;
                    })
                  }
                  onCancel={() => this.setState({ isModalVisible: false })}
                />
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        <LabsTable
          providers={providers}
          saveModel={saveModel}
          user={user}
          config={this.props.config}
        />
      </ContentTransition>
    );
  }
}

export default Labs;
