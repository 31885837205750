import React from 'react';
import glamourous, { Span } from 'glamorous';

import { colours } from './../../utils/css';

import type { HTMLStyle } from './../../types';
import Button from './../buttons/button';

type Props = {
  buttonClass: string,
  label: string,
  style: HTMLStyle,
  dataPublic?: boolean,
};

const ButtonWrapper = glamourous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
});

const ButtonAnimation = glamourous.div({
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  minWidth: '40px',
});

const ButtonAnimationSubDiv = glamourous.div({
  height: '5px',
  width: '5px',
  background: colours.white,
  borderRadius: '50%',
  position: 'relative',
  animation: 'bounce-small 1.4s ease infinite',
  '&:nth-child(2)': {
    transform: 'translateX(10px)',
    WebkitAnimationDelay: '0.08s',
  },
  '&:nth-child(3)': {
    transform: 'translateX(20px)',
    WebkitAnimationDelay: '0.16s',
  },
});

/**
 * This is a 'button' component that displays a loading animation. It has no interactivity and
 * should only be used as a child of other components.
 * @class LoadingButton
 * @extends {React.Component<Props, State>}
 */
class LoadingButton extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    buttonClass: '',
    style: {},
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { buttonClass, label, style } = this.props;
    return (
      <Button dataPublic className={`${buttonClass} o-button--is-loading`} disabled type="button" style={style}>
        <ButtonWrapper>
          <Span css={{ marginRight: '1em' }}>{label}</Span>
          <ButtonAnimation>
            <ButtonAnimationSubDiv />
            <ButtonAnimationSubDiv />
            <ButtonAnimationSubDiv />
          </ButtonAnimation>
        </ButtonWrapper>
      </Button>
    );
  }
}

export default LoadingButton;
