import React from 'react';

import translate from './../../utils/i18n';
import Table from './../table/table';
import { debugPrint } from './../../utils/logging';
import { getVisitType } from './../../utils/encounters';
import { renderDateWithLink, renderWithLink, renderPriceWithLink } from './../../utils/tables';
import { UNICODE } from './../../constants';

import type ClaimInvoiceModel from './../../models/claimInvoiceModel';
import { ClaimData } from './../../models/claimInvoiceModel';

type Row = {
  date: string,
  name: string,
  icNumber: string,
  visitType: string,
  totalFees: string,
  owedByPanel: string,
  billNotes: string,
};

type Props = {
  claimInvoice: ClaimInvoiceModel,
};
/**
 * Component displaying Claim Invoice.
 * @class ClaimInvoiceTable
 * @extends {React.Component}
 */
class ClaimInvoiceTable extends React.PureComponent<Props> {
  props: Props;

  /**
   * Generates the table rows from the ClaimInvoice.
   * @returns {[Row]} The rows of the table
   */
  getRows(): Array<Row> {
    return this.props.claimInvoice.get('items', { claim: [] }).claims
      .map((c : ClaimData) => ({
        date: c.timestamp,
        name: c.patient_name,
        icNumber: c.patient_ic && c.patient_ic.length ? c.patient_ic : UNICODE.EMDASH,
        visitType: getVisitType(c.encounter_flow, c.encounter_type),
        totalFees: c.bill_total,
        owedByPanel: c.amount,
        billNotes: c.bill_notes && c.bill_notes.length ? c.bill_notes : UNICODE.EMDASH,
        link: c.patient_id && c.encounter_id ? `/patient/${c.patient_id}/billing/${c.encounter_id}` : undefined,
      }));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering Claim Invoice');
    return (
      <Table
        columns={[
          { accessor: 'date', Header: translate('date'), Cell: renderDateWithLink },
          { accessor: 'name', Header: translate('name'), Cell: renderWithLink },
          { accessor: 'icNumber', Header: translate('ic_number'), Cell: renderWithLink },
          { accessor: 'visitType', Header: translate('visit_type'), Cell: renderWithLink },
          { accessor: 'totalFees', Header: translate('total_fees'), Cell: renderPriceWithLink },
          { accessor: 'owedByPanel', Header: translate('owed_by_panel'), Cell: renderPriceWithLink },
          { accessor: 'billNotes', Header: translate('bill_notes'), Cell: renderWithLink },
        ]}
        data={this.getRows()}
        noDataText={translate('no_items')}
        showPagination
        defaultSorted={[{ id: 'invoice_no', desc: false }]}
      />
    );
  }
}

export default ClaimInvoiceTable;
