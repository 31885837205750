
import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'template_group',
  order: number,
  name: string
};

/**
   * TemplateGroupModel
   * @namespace TemplateGroupModel
   */
class TemplateGroupModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'template_group';
  }
}

export default TemplateGroupModel;
