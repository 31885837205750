
import BaseModel from './baseModel';

import type { EditEntry } from './../types';

export type Permission = {
  feature: string,
  access: Array<'create' | 'read' | 'update' | 'delete'>,
  section?: string,
};

export type UserConfig = {
  permissions: Array<Permission>,
};

type Attributes = {
  _id: string,
  created_by: EditEntry,
  edited_by: Array<EditEntry>,
  type: 'user_config',
  user_id: string,
  user_group_id: string,
  config: UserConfig,
  freshchat_restore_id: string,
};

/**
   * UserConfigModel
   * @namespace UserConfigModel
   */
class UserConfigModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'user_config';
  }

  /**
   * Returns true if user has permissions set.
   * @returns {boolean}
   */
  hasPermissions(): boolean {
    return this.get('config', {}).permissions !== undefined;
  }
}

export default UserConfigModel;
