import React, { Fragment } from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import ModalFooter from './../modals/modalFooter';
import Input from './../inputs/input';
import FormError from './../formError';
import { createSuccessNotification } from './../../utils/notifications';
import { pluralizeWord, singularizeWord } from './../../utils/utils';
import { debugPrint } from './../../utils/logging';
import { wsUnit } from './../../utils/css';
import Select from './../inputs/select';
import { updateClinicConfig } from './../../utils/db';

import type { Config } from './../../types';

type Props = {
  drugDuration?: [number, string],
  onSaveClicked: () => void,
  drugDurations: List<List<number | string>>,
  config: Config,
  updateConfig: (config: Config) => void,
  newDurationAvailable: boolean,
};

type State = {
  isSaving: boolean,
  errorMessage?: string,
  duration?: number,
  unit?: string,
  changesMade: boolean,
};

const FormRow = glamorous.div({
  display: 'grid',
  gridColumnGap: `calc(${wsUnit} / 2)`,
  alignItems: 'center',
  gridTemplateColumns: '1fr 1fr',
});

/**
 * A form that allows exiting drug duration to be edited or saving new drug duration.
 * @class AddEditDrugDurationForm
 * @extends {React.Component<Props, State>}
 */
class AddEditDrugDurationForm extends React.Component<Props, State> {
  /**
   * Creates an instance of AddEditDrugDurationForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      duration: props.drugDuration ? props.drugDuration[0] : '',
      unit: props.drugDuration ? props.drugDuration[1] : '',
      changesMade: false,
    };
  }

  /**
   * Handle the saving of the form.
   * @returns {void}
   */
  onSaveClicked() {
    this.setState({ errorMessage: undefined, isSaving: true });
    const { unit, duration } = this.state;
    const { drugDurations } = this.props;
    if (!unit || !duration) {
      return this.setState({ errorMessage: translate('fill_required_fields'), isSaving: false });
    }
    const stateDrugDurationInString = `${duration} ${unit}`;
    const newDrugDuration = [Number(duration), unit];
    const stringifiedDrugDurations = drugDurations.map(d => d.join(' ')).toArray();
    if (stringifiedDrugDurations.includes(stateDrugDurationInString)) {
      return this.setState({ errorMessage: translate('drug_duration_already_exists'), isSaving: false });
    }
    if (this.props.drugDuration && !this.props.newDurationAvailable) {
      const propDrugDurationInString = this.props.drugDuration.join(' ');
      const index = stringifiedDrugDurations.indexOf(propDrugDurationInString);
      const newDrugDurations = drugDurations.splice(index, 1, newDrugDuration);
      return this.updateConfig(newDrugDurations);
    }
    return this.updateConfig(drugDurations.push(List(newDrugDuration)));
  }

  /**
   * Update Clinic Config
   * @param {List<List<number, string>>} drugDurations List of DrugDurations
   * @returns {void}
   */
  async updateConfig(drugDurations: List<List<number, string>>) {
    const { config, updateConfig, onSaveClicked, drugDuration } = this.props;
    const updatedConfig = config
      .setIn(['prescription', 'durations'], drugDurations)
      .toJS();
    try {
      await updateClinicConfig(updatedConfig, {}, updateConfig) as Config;
      onSaveClicked(this.state.duration, this.state.unit);
      createSuccessNotification(translate(drugDuration ? 'drug_duration_updated' : 'drug_duration_created'))
    } catch (err) {
      debugPrint(err.toString(), 'error');
      this.setState({
        errorMessage: translate(drugDuration ? 'error_drug_duration_updated' : 'error_drug_duration_created'),
      });
    } finally {
      this.setState({
        isSaving: false,
      })
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const isSaveDisabled = !this.props.newDurationAvailable && !this.state.changesMade;
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage
            && <FormError>{translate(this.state.errorMessage)}</FormError>
          }
          <FormRow>
            <Input
              id="default_duration"
              autoFocus
              label={translate('default_duration')}
              value={this.state.duration}
              onValueChanged={
                (duration: number) => this.setState({ duration, changesMade: true })
              }
              required
              disabled={this.state.isSaving}
              type="number"
            />
            <Select
              style={{ marginTop: 30, marginBottom: 14 }}
              label=""
              id="duration-options"
              onValueChanged={(value: string) => this.setState({
                unit: singularizeWord(value.toLocaleLowerCase()),
                changesMade: true,
              })}
              options={
                ['day', 'week', 'month'].map(u => ({ label: translate(pluralizeWord(u || '')), value: u }))
              }
              value={singularizeWord(this.state.unit || '')}
              disabled={this.state.isSaving}
              required
            />
          </FormRow>
        </div>
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('save')}
            onClick={() => this.onSaveClicked()}
            disabled={isSaveDisabled}
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default AddEditDrugDurationForm;
