
import BaseModel from './baseModel';

export type Attributes = {
    _id: string,
    type: 'drug_master_suggestion',
    clinic_id: string,
    drug_id: string,
    review_status: 'PENDING' | 'ACCEPTED' | 'REJECTED',
    is_completed: boolean,
    notes: string,
    name: string,
    non_drug_master_id: 'MSP20200203S' | 'MSP20200202O' | 'MSP20200201C',
    selected_map_id: string,
    drug_type: string,
    packaging_unit: string,
    dosage: string,
    dispensation_unit: string,
    holder: string,
    manufacturer_name: string,
    active_ingredients: Array<string>,
  };


/**
  * DrugSuggestionModel
  * @namespace DrugSuggestionModel
  */
class DrugSuggestionModel extends BaseModel {
    attributes: Attributes;

    /**
     * @param {object} attributes - The attributes for this model.
     */
    constructor(attributes: {} = {}) {
      super(attributes);
      this.attributes.type = 'drug_suggestion';
    }
}

export default DrugSuggestionModel;
