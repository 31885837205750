import { connect } from 'react-redux';
import type { List } from 'immutable';

import GenericPatientDataList from './../components/genericPatientData/genericPatientDataList';
import { saveFactory } from './../utils/redux';

import type { State, Dispatch, GenericPatientDataModel } from './../types';

type Type = 'custom_note' | 'medical_complaint' | 'medication';

type Props = {
  patientID: string,
  type: Type,
};

/**
 * Returns a list of models based on the given type.
 * @param {Type} type The type of the data to display.
 * @param {string} patientID The patient ID
 * @param {State} state The Redux store
 * @returns {List<GenericPatientDataModel>}
 */
function getItems(type: Type, patientID: string, state: State): List<GenericPatientDataModel> {
  switch (type) {
    case 'custom_note':
      return state.customNotes.filter(m => m.get('patient_id') === patientID && m.isVisible());
    case 'medical_complaint':
      return state.medicalComplaints.filter(m => m.get('patient_id') === patientID && m.isVisible());
    case 'medication':
      return state.medication.filter(m => m.get('patient_id') === patientID && m.isVisible());
    default:
      throw new Error('Valid type not specified');
  }
}

/**
 * @param {Object} state Current app state.
 * @param {Props} ownProps The props of this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, { type, patientID }: Props) => ({
  type,
  patientID,
  items: getItems(type, patientID, state),
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const GenericPatientDataListContainer =
  connect(mapStateToProps, mapDispatchToProps)(GenericPatientDataList);

export default GenericPatientDataListContainer;
