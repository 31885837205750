import * as React from 'react';
import { List, Set, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import Moment from 'moment';

import Input from './../inputs/input';
import Select from './../inputs/select';
import TagInput from './../inputs/tagInput';
import TextArea from './../inputs/textarea';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import DrugModel from './../../models/drugModel';
import PanelDrugPriceForm from './panelDrugPriceForm';
import AddEditDrugDurationForm from './../drugDurations/addEditDrugDurationForm';
import StatelessModal from '../modals/statelessModal';
import { createSuccessNotification } from './../../utils/notifications';
import { logSKUCreation, logDrugCreated, logDrugEdited } from './../../utils/logging';
import { durationInput, getDefaultDurationOptions } from './../../utils/drug';
import { validateAndTrimString, toPositiveFixedDecimal, roundMoneyForSave, pluralizeWord, singularizeWord, filterUnwantedCharacters } from './../../utils/utils';
import Keypress from './../keypress';
import UnsavedDataModal from './../prompts/unsavedDataModal';
import FormError from './../formError';
import PanelCategoryForm from './panelCategoryForm';
import { updateClinicConfig } from './../../utils/db';
import Button from './../buttons/button';
import Radio from './../inputs/radio';

import ModalFooter from '../modals/modalFooter';
import type { PriceCoveragePayor, CategoryCoveragePayor } from './../../models/drugModel';
import type { Config, SaveModel, SelectOption, MapValue } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type BaseModel from './../../models/baseModel';
import type DosingRegimenModel from '../../models/dosingRegimenModel';
import CoveragePayors from '../coveragePayors/coveragePayors';
import TimeInput from '../inputs/timeInput';

type Props = {
  clearModelToEdit: () => void,
  config: Config,
  modelToEdit?: DrugModel,
  saveDrugAndMappingData: (model: DrugModel) => void,
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  dosingRegimens: List<DosingRegimenModel>,
  dispensationUnitOptions: Set<string>,
  labelClassName?: string,
  autofocus?: string,
  saveIsSticky: boolean,
  noClearButton?: boolean,
  onSave?: (drug: DrugModel) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugDurations: List<List<[number, string]>>,
  initialValue: {
    itemName?: string,
    itemDispensationUnit?: string,
  },
  setDrugFormChanged: (changesMade: boolean) => void,
  showDrugFormPrompt: boolean,
  onCancel: () => void,
  onDiscard: () => void,
  drugName?: string,

  // these props are from doc validation.
  isFromDocValidationModal?: boolean,
  validationDocObject?: { id: string, type: string }
  isDocValidationModalSaving?: boolean,
  onSaveAtDocValidationModal?: (wasSuccessful: boolean) => void,
};

type State = {
  errorMessage?: string,
  addEditDrugDurationFormIsVisible: boolean,
  isSaving: boolean,
  itemName: string,
  itemDefaultPrescribedDosage: string,
  itemDosageUnit: string,
  itemDefaultFrequency: string,
  itemDefaultDuration?: string,
  itemSellingPrice: string,
  itemCostPrice: string,
  itemDefaultQuantity: string,
  itemManufacturer: string,
  itemIndications: List<string>,
  itemTags: List<string>,
  itemPriceCoveragePayor: List<PriceCoveragePayor>,
  itemCategoryCoveragePayor: List<CategoryCoveragePayor>,
  itemDispensationUnit: string | null | undefined,
  itemDefaultCashPrice?: string,
  itemDefaultPanelPrice?: string,
  itemDefaultPanelCategory?: string,
  itemLowInventoryThreshold?: string,
  itemNotes: string,
  drugDuration?: [number, string],
  atdpsIntegration?: boolean,
  atdpsId?: string,
  atdpsDosingRegimenId?: string,
};

const defaultState: State = {
  isSaving: false,
  addEditDrugDurationFormIsVisible: false,
  itemName: '',
  itemDefaultPrescribedDosage: '',
  itemDosageUnit: '',
  itemDefaultFrequency: '',
  itemDefaultDuration: undefined,
  itemSellingPrice: '',
  itemCostPrice: '',
  itemDefaultQuantity: '1',
  itemManufacturer: '',
  itemDispensationUnit: undefined,
  itemIndications: List(),
  itemTags: List(),
  itemPriceCoveragePayor: List(),
  itemCategoryCoveragePayor: List(),
  itemDefaultCashPrice: undefined,
  itemDefaultPanelPrice: undefined,
  itemDefaultPanelCategory: undefined,
  itemLowInventoryThreshold: undefined,
  itemNotes: '',
  drugDuration: undefined,
  atdpsIntegration: false,
  atdpsId: '',
  atdpsDosingRegimenId: '',
};

/**
   * Returns State object based on the updated model
   * @param {DrugModel} model Model to transform
   * @param {Props} props props of this component
   * @returns {object}
   */
const modelToState = (model: DrugModel, props: Props) => {
  const { drugName } = props;
  const prescribedDuration = model.get('default_prescribed_duration', []);
  return {
    itemName: drugName || validateAndTrimString(model.get('name', '')),
    itemDefaultPrescribedDosage: model.get('default_prescribed_dosage', ''),
    itemDosageUnit: model.get('dosage_unit', ''),
    itemDefaultFrequency: model.get('frequency', ''),
    itemDefaultDuration: prescribedDuration.length
      ? pluralizeWord(prescribedDuration[1], prescribedDuration[0]) : undefined,
    itemSellingPrice: model.get('price', ''),
    itemCostPrice: model.get('cost_price', ''),
    itemDefaultQuantity: model.get('default_quantity', '1'),
    itemManufacturer: model.get('manufacturer', ''),
    itemIndications: List(model.get('indications', [])),
    itemTags: List(model.get('tags', [])),
    itemPriceCoveragePayor: List(model.get('price_coverage_payor')),
    itemCategoryCoveragePayor: List(model.get('coverage_payor_category', [])),
    itemDispensationUnit: model.get('dispensation_unit'),
    itemDefaultCashPrice: model.get('non_coverage_payor_price'),
    itemDefaultPanelPrice: model.get('coverage_payor_price'),
    itemDefaultPanelCategory: model.get('default_coverage_payor_category', ''),
    itemLowInventoryThreshold: model.get('low_inventory_threshold'),
    itemNotes: model.get('notes', ''),
    atdpsIntegration: model.get('atdps_integration', false),
    atdpsId: model.get(['integrations', 'omnihealth', 'drug_id'], ''),
    atdpsDosingRegimenId: model.get(['integrations', 'omnihealth', 'dosing_regimen_id'], ''),
  };
};

/**
 * A form component for a prescription.
 * @class DrugForm
 * @extends {React.Component<Props, State>}
 */
class DrugForm extends React.Component<Props, State> {
  static defaultProps = {
    labelClassName: '',
    saveIsSticky: false,
    noClearButton: false,
    initialValue: {},
  };

  /**
   * Creates an instance of DrugForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    if (this.props.modelToEdit) {
      this.state = {
        ...defaultState,
        ...modelToState(this.props.modelToEdit, this.props),
        ...this.props.initialValue,
      };
    } else {
      this.state = {
        ...defaultState,
        ...this.props.initialValue,
      };
    }
    if (this.props.initialValue.itemName) {
      this.props.setDrugFormChanged(true);
    }
  }

  /**
   * Triggers the saving function when the isSaving prop changes
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isFromDocValidationModal
      && this.props.isDocValidationModalSaving !== prevProps.isDocValidationModalSaving
      && this.props.isDocValidationModalSaving) {
      this.onSaveClicked();
    }
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    const trimmedFields = validateAndTrimString(this.state);
    const {
      itemName, itemSellingPrice,
      itemDefaultQuantity, itemDefaultPrescribedDosage,
      itemDispensationUnit,
    } = trimmedFields;
    if (this.state.atdpsIntegration && !this.state.atdpsId) {
      this.setState({ errorMessage: translate('atdps_id_required') });
      return false;
    }
    if (this.state.atdpsIntegration && !this.state.atdpsDosingRegimenId) {
      this.setState({ errorMessage: translate('atdps_dosing_regimen_required') });
      return false;
    }
    if (
      itemName
      && itemName.length
      && itemDispensationUnit
      && itemDispensationUnit.length
      && itemSellingPrice !== undefined
      && itemSellingPrice !== ''
      && !isNaN(itemSellingPrice)
      && itemDefaultQuantity !== undefined
      && itemDefaultQuantity !== ''
      && !isNaN(itemDefaultQuantity)
      && itemDefaultPrescribedDosage !== undefined
      && itemDefaultPrescribedDosage !== ''
      && !isNaN(itemDefaultPrescribedDosage)
      && !this.state.itemPriceCoveragePayor.find(i => i.coverage_payor_id === this.props.validationDocObject?.id)
    ) {
      this.setState({ errorMessage: '' });
      return true;
    }
    if (this.state.itemCategoryCoveragePayor && this.state.itemCategoryCoveragePayor.some(item => item && validateAndTrimString(item.category) === '')) {
      this.setState({ errorMessage: translate('panel_category_form_error_empty_price') });
      return false;
    }
    if (this.state.itemCategoryCoveragePayor && this.state.itemCategoryCoveragePayor.some(item => item && validateAndTrimString(item.coverage_payor_id) === '')) {
      this.setState({ errorMessage: translate('panel_drug_price_form_error_empty_panels') });
      return false;
    }
    if (this.state.itemPriceCoveragePayor && this.state.itemPriceCoveragePayor.some(item => item && (item.price === '' || item.price === undefined))) {
      this.setState({ errorMessage: translate('panel_drug_price_form_error_empty_price') });
      return false;
    }
    if (this.state.itemPriceCoveragePayor && this.state.itemPriceCoveragePayor.some(item => item && validateAndTrimString(item.coverage_payor_id) === '')) {
      this.setState({ errorMessage: translate('panel_drug_price_form_error_empty_panels') });
      return false;
    }
    this.setState({ errorMessage: translate('fill_required_fields') });
    return false;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  onSave = (): Promise<boolean> => {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const defaultDurationArray = this.state.itemDefaultDuration ? this.state.itemDefaultDuration.split(' ') : undefined;
      const prescribeDuration = defaultDurationArray ? [Number(defaultDurationArray[0]),
        singularizeWord(defaultDurationArray[1])] : undefined;
      const costPrice = roundMoneyForSave(this.state.itemCostPrice, null);
      const drugModelData = {
        name: validateAndTrimString(this.state.itemName),
        dosage_unit: this.state.itemDosageUnit,
        default_prescribed_dosage: parseFloat(this.state.itemDefaultPrescribedDosage),
        default_prescribed_duration: prescribeDuration,
        frequency: this.state.itemDefaultFrequency,
        price: roundMoneyForSave(this.state.itemSellingPrice, 0),
        ...costPrice && {
          cost_price: costPrice,
        },
        default_quantity: toPositiveFixedDecimal(this.state.itemDefaultQuantity, 1, 2),
        manufacturer: this.state.itemManufacturer,
        indications: this.state.itemIndications.toArray(),
        tags: this.state.itemTags.toArray(),
        price_coverage_payor: this.state.itemPriceCoveragePayor.toArray(),
        coverage_payor_category: this.state.itemCategoryCoveragePayor.toArray(),
        dispensation_unit: this.state.itemDispensationUnit,
        ...filterUnwantedCharacters(this.state.itemDefaultCashPrice, null) && {
          non_coverage_payor_price: parseFloat(this.state.itemDefaultCashPrice),
        },
        ...filterUnwantedCharacters(this.state.itemDefaultPanelPrice, null) && {
          coverage_payor_price: parseFloat(this.state.itemDefaultPanelPrice),
        },
        default_coverage_payor_category: this.state.itemDefaultPanelCategory,
        ...filterUnwantedCharacters(this.state.itemLowInventoryThreshold, null) && {
          low_inventory_threshold: parseInt(this.state.itemLowInventoryThreshold, 10),
        },
        notes: this.state.itemNotes,
        atdps_integration: this.state.atdpsIntegration,
        integrations: {
          omnihealth: {
            enabled: this.state.atdpsIntegration,
            drug_id: this.state.atdpsId,
            dosing_regimen_id: this.state.atdpsDosingRegimenId,
          },
        },
      };
      if (this.props.modelToEdit) {
        const drugModel = this.props.modelToEdit.replaceAtrributes(drugModelData);
        return this.props.saveDrugAndMappingData(drugModel).then((resp) => {
          if (this.props.onSaveAtDocValidationModal) {
            this.props.onSaveAtDocValidationModal(!!resp);
          }
          if (!resp) {
            if (!this.props.isFromDocValidationModal
              && this.props.clearModelToEdit) this.props.clearModelToEdit();
            return false;
          }
          logDrugEdited();
          createSuccessNotification(translate('prescription_item_updated'));
          this.setState(defaultState);
          if (this.props.onSave) this.props.onSave(drugModel);
          this.props.setDrugFormChanged(false);
          this.props.clearModelToEdit();
          return true;
        });
      }
      const drugModel = new DrugModel(drugModelData);
      return this.props.saveDrugAndMappingData(drugModel).then((resp) => {
        if (this.props.onSaveAtDocValidationModal) {
          this.props.onSaveAtDocValidationModal(!!resp);
        }
        if (!resp) {
          if (!this.props.isFromDocValidationModal) this.props.clearModelToEdit();
          return false;
        }
        logSKUCreation();
        logDrugCreated();
        createSuccessNotification(translate('prescription_item_added'));
        this.setState(defaultState);
        if (this.props.onSave) this.props.onSave(drugModel);
        this.props.setDrugFormChanged(false);
        this.props.clearModelToEdit();
        return true;
      });
    }
    if (this.props.onSaveAtDocValidationModal) {
      this.props.onSaveAtDocValidationModal(false);
    }
    this.props.onCancel();
    return Promise.resolve(false);
  }

  /**
   * Called when save is clicked and also update the drug tag in the clinic config.
   * @returns {Promise<boolean>}
   */
  onSaveClicked = () => {
    const configDrugTags = this.props.config.getIn(['prescription', 'drug_tags'], List());
    const stateTags = this.state.itemTags;
    return this.onSave().then((isValid: boolean) => {
      if (isValid) {
        const tags = stateTags.reduce((existingTags, tag) => {
          if (existingTags.indexOf(tag) !== -1) {
            return existingTags;
          }
          return existingTags.push(tag);
        }, configDrugTags);
        if (!configDrugTags.equals(tags)) {
          const configUpdates = Map()
            .setIn(['prescription', 'drug_tags'], tags)
            .toJS();
          updateClinicConfig(this.props.config.toJS(), configUpdates, this.props.updateConfig);
          this.props.updateConfigValue(
            ['prescription', 'drug_tags'], tags,
            this.props.updateConfig,
          );
        }
        return true;
      }
      return false;
    });
  }

  /**
   * If model is changing update state to reflect that.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      const model = nextProps.modelToEdit; // Flow is dumb so we need an explicit ref to modelToEdit
      if (model) {
        this.setState(modelToState(model));
      } else {
        this.setState(defaultState);
      }
    }
    if (this.props.initialValue !== nextProps.initialValue && nextProps.initialValue.itemName) {
      this.setState({ ...this.state, ...nextProps.initialValue });
      this.props.setDrugFormChanged(true);
    }
  }

  /**
   * Checks the given dosage unit value against what exists in config and updates config if necessary.
   * @param {string} newValue A new string from newly created dosage unit.
   * @returns {void}
   */
  updateDosageUnitsInConfig = (newValue: string) => {
    const existingValues = this.props.config.getIn(['prescription', 'dosage_units'], List());
    const isNewValueExist = existingValues
      .find(existingValue => existingValue.toLowerCase() === newValue.toLowerCase());
    if (!isNewValueExist) {
      const configUpdates = Map()
        .setIn(['prescription', 'dosage_units'], existingValues.concat(newValue))
        .toJS();
      updateClinicConfig(this.props.config.toJS(), configUpdates, this.props.updateConfig);
      this.props.updateConfigValue(
        ['prescription', 'dosage_units'], existingValues.concat(newValue),
        this.props.updateConfig,
      );
    }
  }

  /**
   * Returns the options for the dosage unit Select, adding the current value in state if
   * it is defined.
   * @returns {Array<SelectOption>}
   */
  getDosageUnitOptions(): Array<SelectOption> {
    return this.props.config.getIn(['prescription', 'dosage_units'], List())
      .map(value => ({ value, label: value })).toArray();
  }

  /**
   * Update config on Dosage unit input change
   * @param {string} value The dosage unit field value
   * @returns {void}
   */
  onDosageChangeValue = (value: string) => {
    if (!value) {
      this.setState({ itemDosageUnit: '' });
    } else {
      this.setState({ itemDosageUnit: value });
      this.updateDosageUnitsInConfig(value);
    }
  }

  /**
   * Handles saving of default  duration
   * @param {string} value default duration value eg. 3 weeks, 1 day, 2 days
   * @returns {void}
   */
  onDefaultDurationChangeValue = (value: string) => {
    if (!value) {
      this.setState({ itemDefaultDuration: undefined });
    } else if (value === 'add_new_drug_duration') {
      this.setState({ addEditDrugDurationFormIsVisible: true });
    } else {
      this.setState({ itemDefaultDuration: value });
    }
  }

  /**
   * Returns the options for the dispensation unit Select, adding the current value in state if
   * it is defined.
   * @returns {Array<SelectOption>}
   */
  getDispensationUnitOptions(): Array<SelectOption> {
    const options = this.state.itemDispensationUnit ?
      this.props.dispensationUnitOptions.add(this.state.itemDispensationUnit) :
      this.props.dispensationUnitOptions;
    return options.map(value => ({ value, label: value })).toArray();
  }

  /**
   * Hides the AddEditDrugDurationForm
   * @returns {void}
   */
  hideModal() {
    this.setState({ addEditDrugDurationFormIsVisible: false });
  }

  /**
   * @param {string} option new input duration value
   * @returns {void}
   */
  handleCreateNewDrugDuration(option: string) {
    const matchedArray = option && option.match(/[a-z]+|[0-9]+/gi);
    if (matchedArray) {
      const newValueArray = matchedArray.filter((elem, index) => index <= 1);
      this.setState({ drugDuration: durationInput(newValueArray) });
    }
    this.setState({ addEditDrugDurationFormIsVisible: true });
  }

  /**
   * @param {object} value new input duration value
   * @returns {void}
   */
  handleChange(value: Object): void {
    this.setState({ ...value });
    this.props.setDrugFormChanged(true);
  }

  /**
   * Render footer buttons
   * @param {boolean} isSticky whether save button is added to a sticky footer
   * @returns {React.Component}
   */
  renderFooterButton = (isSticky: boolean) => {
    if (isSticky) {
      return (
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={this.props.modelToEdit ? translate('save') : translate('add_new_drug')}
            onClick={() => this.onSaveClicked()}
          />
          {
            this.props.modelToEdit && !this.props.noClearButton &&
            <Button dataPublic onClick={this.props.clearModelToEdit} className="o-button o-button--small o-button--danger u-margin-right--half-ws">
              {translate('cancel')}
            </Button>
          }
        </ModalFooter>
      );
    }
    return (
      <SaveButton
        dataPublic
        className="o-button--small"
        isSaving={this.state.isSaving}
        label={this.props.modelToEdit ? translate('save') : translate('add_new_drug')}
        onClick={() => this.onSaveClicked()}
        fullWidth
      />
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <React.Fragment>
        <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
          <UnsavedDataModal
            visible={this.props.showDrugFormPrompt}
            onSave={this.onSaveClicked}
            onDiscard={() => this.props.onDiscard()}
            onCancel={() => this.props.onCancel()}
          />
          {
            this.state.errorMessage &&
            <FormError isSticky>{this.state.errorMessage}</FormError>
          }
          <Input
            id="item_name"
            label={translate('name')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemName}
            onValueChanged={value => this.handleChange({ itemName: value })}
            required
            autoFocus={this.props.autofocus === 'item_name'}
          />
          <Input
            id="item_default_prescribed_dosage"
            label={translate('default_prescribed_dosage')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemDefaultPrescribedDosage}
            type="number"
            onValueChanged={value => this.handleChange({
              itemDefaultPrescribedDosage: value,
            })}
            required
            autoFocus={this.props.autofocus === 'item_default_prescribed_dosage'}
          />
          <Select
            id="item_dosage_unit"
            label={translate('dosage_unit')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemDosageUnit}
            onValueChanged={this.onDosageChangeValue}
            options={this.getDosageUnitOptions()}
            creatable
          />
          <Select
            id="item_frequency"
            label={translate('frequency')}
            labelClassName={this.props.labelClassName}
            options={this.props.config.getIn(['prescription', 'frequencies'], List()).filter(e => e !== null && e !== undefined).map(f => ({ label: f.get('name'), value: f.get('name') })).toArray()}
            value={this.state.itemDefaultFrequency || ''}
            onValueChanged={value => this.handleChange({
              itemDefaultFrequency: value,
            })}
            isValueCaseInsensitive
          />
          <Select
            id="item_default_duration"
            label={translate('default_duration')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemDefaultDuration}
            onCreateOption={(option) => {
              this.handleCreateNewDrugDuration(option);
            }}
            createOptionPromptFactory={label => translate('add_new_x_drug_duration', { x: label })}
            options={[...getDefaultDurationOptions(this.props.drugDurations).map(
              d => ({ label: translate(pluralizeWord(d[1], d[0])), value: pluralizeWord(d[1], d[0]) }),
            ), { label: translate('add_new_drug_duration'), value: 'add_new_drug_duration' }]
            }
            showNewOptionAtTop={false}
            onValueChanged={this.onDefaultDurationChangeValue}
            creatable
          />
          <TagInput
            id="item_reasons"
            label={translate('reasons')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemIndications.map(value => ({ value, label: value })).toArray()}
            options={[]}
            onChange={newValue =>
              this.handleChange({
                itemIndications: List(newValue.map(v => v.value)),
              })
            }
          />
          <TextArea
            id="item_notes"
            label={translate('notes')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemNotes}
            onValueChanged={value => this.handleChange({ itemNotes: value })}
            minRows={1}
          />
          <Select
            id="item_dispensation_unit"
            creatable
            label={translate('dispensation_unit')}
            labelClassName={this.props.labelClassName}
            options={this.getDispensationUnitOptions()}
            value={this.state.itemDispensationUnit}
            onValueChanged={value => this.handleChange({
              itemDispensationUnit: value,
            })}
            required
          />
          <Input
            id="item_default_quantity"
            label={translate('default_dispensation_quantity')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemDefaultQuantity}
            type="number"
            onValueChanged={value => this.handleChange(
              { itemDefaultQuantity: value },
            )}
            required
          />
          <Input
            id="item_cost_price"
            label={translate('cost_price_per_unit')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemCostPrice}
            type="number"
            onValueChanged={value => this.handleChange(
              { itemCostPrice: value },
            )}
          />
          <Input
            id="item_selling_price"
            label={translate('selling_price_per_unit')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemSellingPrice}
            type="number"
            onValueChanged={value => this.handleChange(
              { itemSellingPrice: value },
            )}
            required
          />
          <TagInput
            id="item_tags"
            label={translate('tags')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemTags.map(value => ({ value, label: value })).toArray()}
            options={this.props.config.getIn(['prescription', 'drug_tags'], List()).map(value => ({ value, label: value })).toArray()}
            onChange={newValue =>
              this.handleChange({ itemTags: List(newValue.map(v => v.value)) })
            }
          />
          <Input
            id="item_manufacturer"
            label={translate('manufacturer')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemManufacturer}
            onValueChanged={value => this.handleChange({ itemManufacturer: value })}
          />
          <Input
            id="item_inventory_threshold"
            label={translate('low_inventory_warning_threshold')}
            description={translate('low_inventory_warning_threshold_desc')}
            labelClassName={this.props.labelClassName}
            descClassName="o-sub-label-font o-sub-label-margin"
            type="number"
            value={this.state.itemLowInventoryThreshold}
            onValueChanged={value =>
              this.handleChange({ itemLowInventoryThreshold: value })
            }
          />
          <section>
            <hr />
            <h2 data-public className="o-label u-margin-bottom--1ws" style={{ fontSize: '1rem' }}>{translate('advanced_price_settings')}</h2>
            <PanelDrugPriceForm
              coveragePayors={this.props.coveragePayors}
              validationDocObject={
                this.props.isFromDocValidationModal &&
                this.props.validationDocObject
              }
              value={this.state.itemPriceCoveragePayor}
              saveModel={this.props.saveModel}
              defaultCashPriceValue={this.state.itemDefaultCashPrice}
              defaultPanelPriceValue={this.state.itemDefaultPanelPrice}
              onValueChanged={
                (itemPriceCoveragePayor: List<PriceCoveragePayor>,
                  defaultCashPrice, defaultPanelPrice) =>
                  this.handleChange({
                    itemPriceCoveragePayor,
                    itemDefaultCashPrice: defaultCashPrice,
                    itemDefaultPanelPrice: defaultPanelPrice,
                  })
              }
            />
          </section>
          <section>
            <hr />
            <h2 data-public className="o-label u-margin-bottom--1ws" style={{ fontSize: '1rem' }}>{translate('advanced_panel_settings')}</h2>
            <PanelCategoryForm
              config={this.props.config}
              coveragePayors={this.props.coveragePayors}
              value={this.state.itemCategoryCoveragePayor}
              defaultPanelCategoryValue={this.state.itemDefaultPanelCategory}
              onValueChanged={
                (itemCategoryCoveragePayor: List<CategoryCoveragePayor>,
                  defaultPanelCategory) =>
                  this.handleChange({
                    itemCategoryCoveragePayor,
                    itemDefaultPanelCategory: defaultPanelCategory,
                  })
              }
              style={{ margin: 0 }}
            />
          </section>
          {
            this.props.config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) &&
            <section>
              <hr />
              <h2 data-public className="o-label u-margin-bottom--1ws" style={{ fontSize: '1rem' }}>{translate('prescription_label_atdps_integration')}</h2>
              <Radio
                id="prescription_atdps_integration"
                label={translate('atdps_integrated')}
                options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
                value={this.state.atdpsIntegration?.toString() || 'false'}
                onValueChanged={newValue => this.setState({ atdpsIntegration: newValue === 'true' })}
              />
              <Input
                id="item_atdps_id"
                label={translate('atdps_id')}
                labelClassName={this.props.labelClassName}
                value={this.state.atdpsId}
                onValueChanged={value => this.handleChange({ atdpsId: value })}
                disabled={!this.state.atdpsIntegration}
                required={this.state.atdpsIntegration}
              />
              <Select
                id="item_atdps_dosing_regimen"
                label={translate('dosing_regimen')}
                labelClassName={this.props.labelClassName}
                options={this.props.dosingRegimens.map(i => ({ value: i.get('_id'), label: i.get('name') })).toArray()}
                value={this.state.atdpsDosingRegimenId}
                onValueChanged={value => this.handleChange({
                  atdpsDosingRegimenId: value,
                })}
                disabled={!this.state.atdpsIntegration}
                required
              />
            </section>
          }
          {
          !this.props.isFromDocValidationModal &&
          !this.props.saveIsSticky &&
          this.renderFooterButton(this.props.saveIsSticky)
          }
          <StatelessModal
            id="add-edit-drug-duration-form"
            visible={this.state.addEditDrugDurationFormIsVisible}
            setVisible={() => {
              this.hideModal();
            }}
            noButton
            explicitCloseOnly
            title={translate('add_edit_drug_duration')}
            onClose={() => {
              this.hideModal();
              this.setState({ drugDuration: undefined });
            }}
            dataPublicHeader
          >
            <AddEditDrugDurationForm
              onSaveClicked={(duration, unit) => {
                this.hideModal();
                this.setState({ itemDefaultDuration: pluralizeWord(unit, duration) });
                this.setState({ drugDuration: undefined });
              }}
              drugDuration={this.state.drugDuration}
              saveModel={this.props.saveModel}
              drugDurations={this.props.drugDurations}
              newDurationAvailable
              config={this.props.config}
              updateConfig={this.props.updateConfig}
            />
          </StatelessModal>
        </Keypress>
        {this.props.saveIsSticky && this.renderFooterButton(this.props.saveIsSticky)}
      </React.Fragment>
    );
  }
}

export default DrugForm;
