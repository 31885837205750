import React from 'react';
import { List } from 'immutable';
import { UNICODE } from '../../constants';

import translate from './../../utils/i18n';
import { convertNumberToPrice } from '../../utils/utils';
import Header from './../header/header';

import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProcedureRequestModel from './../../models/procedureRequestModel';

type Props = {
  headerText: string,
  noDataText: string,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
};

/**
   * A Component listing Lab Requests.
   * @namespace ConsultationLabRequests
   */
class ConsultationLabRequests extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className="c-item-list__item__info c-sales-item-conatiner">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{this.props.headerText}</h1>
        </Header>
        {
          this.props.procedureRequests.size > 0 &&
          <div className="o-data-list__row o-data-list__row--header">
            <div className="o-data-list__row__item wider-column-name">
              {translate('name')}
            </div>
            <div className="o-data-list__row__item">
              {translate('price')}
            </div>
            <div className="o-data-list__row__item wider-column-qty">
              {translate('quantity')}
            </div>
            <div className="o-data-list__row__item">
              {translate('total')}
            </div>
          </div>
        }
        { this.props.procedureRequests.map((procedureRequest) => {
          const procedureType = this.props.procedureTypes.find(p => p.get('_id') === procedureRequest.get('procedure_type_id'));
          return (
            <div className="o-data-list__row">
              <div className="o-data-list__row__item wider-column-name">
                {procedureType.get('name') || UNICODE.EMDASH}
              </div>
              <div className="o-data-list__row__item">
                {convertNumberToPrice(procedureType.get('price')) || UNICODE.EMDASH}
              </div>
              <div className="o-data-list__row__item wider-column-qty">
                1
              </div>
              <div className="o-data-list__row__item">
                {convertNumberToPrice(procedureType.get('price')) || UNICODE.EMDASH}
              </div>
            </div>
          );
        })
          }
        {
          this.props.procedureRequests.size === 0 &&
          <p className="u-padding--1ws">{this.props.noDataText}</p>
        }
      </div>
    );
  }
}
export default ConsultationLabRequests;
