import React from 'react';

import Input from './input';
import translate from './../../utils/i18n';

type Props = {
  value: string,
  validationValue: string,
  onValueChanged: ({ value: string, validationValue: string }) => void,
};

/**
 * An input component for entering a new password with matching validation field.
 * @class NewPasswordInput
 * @extends {React.Component<Props, State>}
 */
class NewPasswordInput extends React.Component<Props, {}> {
  /**
   * Gets the appropriate error message for the validation field.
   * @returns {(string | void)}
   */
  getValidationFieldErrorMessage(): string | void {
    if (this.props.value !== this.props.validationValue && this.props.validationValue.length > 0) {
      return translate('passwords_dont_match');
    }
    return undefined;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div>
        <Input
          id="password-input"
          required
          label={translate('new_password')}
          value={this.props.value}
          onValueChanged={value => this.props.onValueChanged({
            value,
            validationValue: this.props.validationValue,
          })}
          type="password"
        />
        <Input
          id="validation-password-input"
          required
          label={translate('confirm_new_password')}
          value={this.props.validationValue}
          onValueChanged={validationValue => this.props.onValueChanged({
            value: this.props.value,
            validationValue,
          })}
          type="password"
          errorMessage={this.getValidationFieldErrorMessage()}
        />
      </div>
    );
  }
}

export default NewPasswordInput;
