import React from 'react';

import glamorous from 'glamorous';

type Props = {
  id: string,
  label?: string,
  buttons: Array<{
    onClick: () => void,
    label: string,
    isActive?: boolean,
  }>,
  className?: string,
};

const ButtonGroupWrapper = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: 30,
});

const ButtonGroupDiv = glamorous.div({
  display: 'flex',
  '& button:first-child': {
    borderRadius: '5px 0px 0px 5px',
  },
  '& button:last-child': {
    borderRadius: '0px 5px 5px 0px',
  },
});

const ButtonGroupFieldLabel = glamorous.div({
  marginRight: 10,
});

const ButtonGroupButton = glamorous.button({
  cursor: 'pointer',
  borderRadius: 0,
});

/**
 * A button group component
 * @class ButtonGroup
 * @extends {React.PureComponent<Props>}
 */
class ButtonGroup extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
  };

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { id, label, buttons, className } = this.props;
    return (
      <ButtonGroupWrapper className={className}>
        {
          label &&
          <ButtonGroupFieldLabel>{label}</ButtonGroupFieldLabel>
        }
        <ButtonGroupDiv>
          {
            buttons.map(
              ({ label: buttonLabel, isActive = true, onClick }) =>
                <ButtonGroupButton
                  key={`${id}-${buttonLabel}`}
                  className={`o-button o-button--small ${!isActive ? 'o-button--inactive' : ''}`}
                  onClick={() => onClick()}
                >
                  {buttonLabel}
                </ButtonGroupButton>,
            )
          }
        </ButtonGroupDiv>
      </ButtonGroupWrapper>
    );
  }
}

export default ButtonGroup;
