import React, { ReactElement, Fragment } from 'react';
import { List } from 'immutable';

import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import SalesItemModel from './../../models/salesItemModel';
import { createSuccessNotification } from './../../utils/notifications';
import { toPositiveFixedDecimal, roundMoneyForSave, validateAndTrimString } from './../../utils/utils';
import Select from './../inputs/select';
import Keypress from './../keypress';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import PanelCategoryForm from './../drugs/panelCategoryForm';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import Button from './../buttons/button';

import type { CategoryCoveragePayor } from './../../models/drugModel';
import type BaseModel from './../../models/baseModel';
import type { Config, SaveModel } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';

type Props = {
  clearModelToEdit: () => void,
  modelToEdit?: SalesItemModel,
  saveModel: SaveModel,
  config: Config,
  coveragePayors: List<CoveragePayorModel>,
  labelClassName?: string,
  autofocus?: string,
  saveIsSticky: boolean,
  noClearButton?: boolean,
  onSave?: (model: SalesItemModel) => void,
  // doc validation props
  docId?: string,
  isFromModal?: boolean,
  isSaving?: boolean,
  onSaveAtValidationModal?: (wasSuccessful: boolean) => void,
};

type State = {
  isSaving: boolean,
  itemType: string,
  itemName: string,
  itemPrice: string,
  itemDefaultQuantity: string,
  itemCostPrice: string,
  changesMade: boolean,
  errorMessage?: string,
  isPanelModalVisible: boolean,
  itemCategoryCoveragePayor: List<CategoryCoveragePayor>,
  itemDefaultPanelCategory?: string,
}

const defaultState = {
  isSaving: false,
  itemType: '',
  itemName: '',
  itemPrice: '',
  itemDefaultQuantity: '1',
  itemCostPrice: '',
  changesMade: false,
  errorMessage: undefined,
  isPanelModalVisible: false,
  itemCategoryCoveragePayor: List(),
  itemDefaultPanelCategory: undefined,
};


/**
   * Returns State object based on the updated model
   * @param {BaseModel} model Model to transform
   * @returns {object}
   */
const modelToState = (model: BaseModel) => ({
  itemType: model.get('item_type', ''),
  itemName: model.get('name', ''),
  itemPrice: model.get('price', ''),
  itemDefaultQuantity: model.get('default_quantity', '1'),
  itemCostPrice: model.get('cost_price', ''),
  changesMade: false,
  errorMessage: undefined,
  itemCategoryCoveragePayor: List(model.get('coverage_payor_category', [])),
  itemDefaultPanelCategory: model.get('default_coverage_payor_category'),
});

/**
 * A SalesItem form component.
 * @class SalesItemForm
 * @extends {React.Component<Props, State>}
 */
class SalesItemForm extends React.Component<Props, State> {
  static defaultProps = {
    labelClassName: 'u-font-color-white',
    saveIsSticky: false,
    noClearButton: false,
  };

  /**
   * Creates an instance of SalesItemForm.
   * @param {Props} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    if (this.props.modelToEdit) {
      this.state = {
        ...defaultState,
        ...modelToState(this.props.modelToEdit),
      };
    } else {
      this.state = defaultState;
    }
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    const {
      itemName,
      itemPrice,
      itemType,
      itemDefaultQuantity,
    } = validateAndTrimString(this.state);
    if (
      itemName.length
      && itemPrice !== undefined
      && itemPrice !== ''
      && itemType.length
      && itemDefaultQuantity !== undefined
      && itemDefaultQuantity !== ''
    ) {
      return true;
    }
    this.setState({ errorMessage: translate('fill_required_fields') });
    return false;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const salesModelData = {
        item_type: this.state.itemType,
        name: validateAndTrimString(this.state.itemName),
        price: roundMoneyForSave(this.state.itemPrice, 0),
        default_quantity: toPositiveFixedDecimal(this.state.itemDefaultQuantity, 1, 2),
        cost_price: roundMoneyForSave(this.state.itemCostPrice, 0),
        coverage_payor_category: this.state.itemCategoryCoveragePayor.toArray(),
        default_coverage_payor_category: this.state.itemDefaultPanelCategory,
      };

      if (this.props.modelToEdit) {
        const salesModel = this.props.modelToEdit.set(salesModelData);
        return this.props.saveModel(salesModel).then((model) => {
          if (!model && this.props.onSaveAtValidationModal) {
            this.props.onSaveAtValidationModal(false);
            return model;
          }
          createSuccessNotification(translate('sales_item_updated'));
          this.setState(defaultState);
          if (this.props.onSave) this.props.onSave(salesModel);
          if (this.props.clearModelToEdit) this.props.clearModelToEdit();
          if (this.props.onSaveAtValidationModal) this.props.onSaveAtValidationModal(true);
          return true;
        });
      }
      // incase if the doc goes missing we will regenerate sales item doc.
      const modifiedSalesModelData = this.props.isFromModal && this.props.docId ?
        {
          ...salesModelData,
          _id: this.props.docId,
        } : { ...salesModelData };
      const salesModel = new SalesItemModel(modifiedSalesModelData);
      return this.props.saveModel(salesModel).then((model) => {
        if (!model && this.props.onSaveAtValidationModal) {
          this.props.onSaveAtValidationModal(false);
          return model;
        }
        createSuccessNotification(translate('sales_item_added'));
        this.setState(defaultState);
        if (this.props.onSave) this.props.onSave(salesModel);
        if (this.props.clearModelToEdit) this.props.clearModelToEdit();
        if (this.props.onSaveAtValidationModal) this.props.onSaveAtValidationModal(true);
        return true;
      });
    }
    return Promise.resolve(false);
  }

  /**
   * Triggers the saving function when the isSaving prop changes
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isFromModal
      && this.props.isSaving !== prevProps.isSaving
      && this.props.isSaving) {
      this.onSaveClicked();
    }
  }

  /**
   * Called when component will receive props. If a model is being passed for editing sets the state
   * to match.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      if (nextProps.modelToEdit) {
        const { modelToEdit } = nextProps; // Put this here because Flow will error out otherwise.
        this.setState(modelToState(modelToEdit));
      } else {
        this.setState(defaultState);
      }
    }
  }

  /**
   * Render save button
   * @param {boolean} isSticky whether save button is added to a sticky footer
   * @returns {React.Component}
   */
  renderSaveButton = (isSticky: boolean) => {
    if (isSticky) {
      return (
        <ModalFooter>
          <SaveButton
            className="o-button--small o-button--padded"
            isSaving={this.state.isSaving}
            label={translate('add_new_sales_item')}
            onClick={() => this.onSaveClicked()}
            dataPublic
          />
        </ModalFooter>
      );
    }
    return (
      <SaveButton
        className="o-button--small"
        isSaving={this.state.isSaving}
        label={this.props.modelToEdit ? translate('save') : translate('add_new_sales_item')}
        onClick={() => this.onSaveClicked()}
        fullWidth
        dataPublic
      />
    );
  }

  /**
   * @returns {ReactElement}
  */
  getSalesFormItemsToRender() {
    return (
      <Fragment>
        <Input
          id="item_default_quantity"
          label={translate('default_quantity')}
          labelClassName={this.props.labelClassName}
          value={this.state.itemDefaultQuantity}
          type="number"
          min={1}
          onValueChanged={value => this.setState(
            {
              itemDefaultQuantity: value,
              changesMade: true,
            },
          )}
          required
        />
        <Input
          id="item_cost_price"
          label={translate('cost_price')}
          labelClassName={this.props.labelClassName}
          value={this.state.itemCostPrice}
          type="number"
          onValueChanged={value => this.setState({ itemCostPrice: value, changesMade: true })}
        />
        <StatelessModal
          id="panelFormModal"
          visible={this.state.isPanelModalVisible}
          setVisible={(isPanelModalVisible: boolean) => this.setState({ isPanelModalVisible })}
          buttonClass={`o-button o-button--small u-margin-bottom--1ws ${this.props.saveIsSticky ? 'u-flex-left' : ''}`}
          buttonLabel={translate('advanced_panel_settings')}
          explicitCloseOnly
          title={translate('advanced_panel_settings')}
          style={{ height: '75vh' }}
          dataPublicHeader
        >
          <PanelCategoryForm
            config={this.props.config}
            coveragePayors={this.props.coveragePayors}
            value={this.state.itemCategoryCoveragePayor}
            defaultPanelCategoryValue={this.state.itemDefaultPanelCategory}
            onValueChanged={
                (itemCategoryCoveragePayor: List<CategoryCoveragePayor>,
                  defaultPanelCategory) =>
                  this.setState({
                    itemCategoryCoveragePayor,
                    changesMade: true,
                    itemDefaultPanelCategory: defaultPanelCategory,
                  })
              }
          />
        </StatelessModal>
        {!this.props.saveIsSticky && this.renderSaveButton(this.props.saveIsSticky)}
        {
            this.props.modelToEdit && !this.props.noClearButton &&
            <Button dataPublic onClick={this.props.clearModelToEdit} className="o-button o-button--small o-button--danger u-margin-top--standard">
              {translate('cancel')}
            </Button>
          }
      </Fragment>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <React.Fragment>
        <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
          <SavePrompt when={this.state.changesMade} onSaveClicked={() => this.onSaveClicked()} />
          {
            this.state.errorMessage &&
            <FormError isSticky containerElementID="right-sidebar">
              {this.state.errorMessage}
            </FormError>
          }
          <Select
            id="item_type"
            label={translate('type')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemType}
            options={this.props.config.getIn(['mobileWebappSettings', 'salesItemTypes'], List()).toJS()}
            onValueChanged={value => this.setState({ itemType: value, changesMade: true })}
            autoFocus={this.props.autofocus === 'item_type'}
            required
          />
          <Input
            id="item_name"
            label={translate('name')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemName}
            onValueChanged={value => this.setState({ itemName: value, changesMade: true })}
            required
          />
          <Input
            id="item_price"
            label={translate('price')}
            labelClassName={this.props.labelClassName}
            value={this.state.itemPrice}
            type="number"
            min={0}
            onValueChanged={value => this.setState(
              {
                itemPrice: value,
                changesMade: true,
              },
            )}
            required
          />
          {!this.props.isFromModal && this.getSalesFormItemsToRender()}
        </Keypress>
        {
          !this.props.isFromModal &&
          this.props.saveIsSticky &&
          this.renderSaveButton(this.props.saveIsSticky)
        }
      </React.Fragment>
    );
  }
}

export default SalesItemForm;
