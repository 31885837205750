import { connect } from 'react-redux';
import type { List } from 'immutable';

import Documents from './../components/documentTemplates/documents';
import { saveFactory } from './../utils/redux';

import type { Dispatch, State } from './../types';
import type AllergyModel from './../models/allergyModel';
import type PatientModel from './../models/patientModel';

type Props = {
  patient: PatientModel,
  allergies: List<AllergyModel>,
};

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  { user, documentTemplates, documentData, drugs, activeIngredients, }: State,
  { allergies, patient }: Props,
) =>
  ({
    user,
    documentData: documentData.filter(d => d.get('patient_id') === patient.get('_id') && !d.isVoid()),
    documentTemplates,
    patient,
    allergies,
    drugs,
    activeIngredients,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const DocumentsContainer = connect(mapStateToProps, mapDispatchToProps)(Documents);

export default DocumentsContainer;
