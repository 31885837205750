import React from 'react';
import translate from './../utils/i18n';
import AnnouncementModal from './modals/announcementModal';

type Props = {
  program: string,
};

/**
 * Render the banner
 * @param {string} program text to add
 * @returns {HTMLElement} html element
 */
const ReferralProgramModal = ({ program }: Props) => {
  /**
   * Handle click
   * @returns {void} void sets seen key & closes modal
   */
  const handleClick = () => {
    window.open('https://www.klinify.com/refer-earn-500?utm_source=mobile-web-app&utm_medium=webapp&utm_campaign=refer-earn-500', '_blank');
  };

  return (
    <AnnouncementModal
      program={program}
      onExplicitClose={handleClick}
      footerButtonLabel={translate('referal_program_button_label')}
      modalHeading={translate('referal_program_modal_title')}
      id="program-modal"
      image={<img src="/static/images/refer_earn_rm500_modal.png" alt="program" style={{ maxWidth: '620px' }} />}
    >
      <p>Introducing our new Refer and Earn Program where you and a friend could earn up to
        <span className="u-strong">&nbsp;RM500</span> each! That is a total of
        <span className="u-strong">&nbsp;RM1,000.00!*</span>
        <br />
        What’s more! If you refer to more than 5 clinics, you will receive up to 6 months
        of free access to premium features on Klinify worth
        <span className="u-strong">&nbsp;RM1800!*</span> 🤩
        <br />
        <br />
        Remember, the more doctors who click onto your link,
        the more referrals you get. So sign up via the link below
        and share your unique referral link along with a positive
        experience of klinify and the promotional details to your
        Facebook/Whatsapp groups.
      </p>
      <br />
      Click the&nbsp;
      <a style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer" href="https://www.klinify.com/refer-earn-500">
        Refer a Friend
      </a>
      &nbsp;button to get Started!
      <br />
      <br />
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <p>Feel free to check our FAQ or contact Support Klinify via the icon chat</p>
        <i style={{ content: "url('/static/images/freshchat_icon.png')", height: '40px' }} />
      </div>
      <br />
      <p>Thank You.</p>
      <br />
      <p>*Terms and conditions apply</p>
    </AnnouncementModal>
  );
};

export default ReferralProgramModal;
