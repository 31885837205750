import React, { Fragment } from 'react';
import { List, Map } from 'immutable';
import Moment, { Moment as IMoment } from 'moment';

import translate from './../../utils/i18n';
import { prettifyDate, convertTimestampToDate, convertDateToTimestamp } from './../../utils/time';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { hasPermission, createPermission } from './../../utils/permissions';
import LabTestsTable from './../labTests/labTestsTable';
import ProviderModel from './../../models/providerModel';
import ProcedureTypeModel from './../../models/procedureTypeModel';
import ProcedureRequestModel from './../../models/procedureRequestModel';
import ProcedureStatusModel from './../../models/procedureStatusModel';
import SpecimenModel from './../../models/specimenModel';
import LabRequestsForm from './labRequestsForm';
import ModalFooter from './../modals/modalFooter';
import LabRequestsTable from './labRequestsTable';
import { UNICODE } from './../../constants';
import ContentTransition from './../contentTransition';
import TableColumnsSettings from './../table/tableColumnsSettings';
import LabRequestActionButton from './labRequestActionButton';
import LabRequestsSearchInput from './labRequestsSearchInput';
import LabResult from './../labRequests/labResult';
import Button from './../buttons/button';
import Header from './../header/header';

import type ProcedureResultModel from './../../models/procedureResultModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type PatientStubModel from './../../models/patientStubModel';
import type { Config, SaveModel, SaveModels, User, MapValue, CustomColumn, AssetObject } from './../../types';
import type EncounterModel from '../../models/encounterModel';
import FormError from '../formError';
import { renderPrice } from './../../utils/tables';

type Props = {
  config: Config,
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  collectedSpecimens?: List<CollectedSpecimenModel>,
  procedureResults?: List<ProcedureResultModel>,
  specimens: List<SpecimenModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
  patientID?: string,
  encounterID?: string,
  encounter?: EncounterModel,
  isLabRequestsFormModalVisible: boolean,
  hideLabRequestsModalButton?: boolean,
  handleLabRequestsFormModalVisibility?: (state: boolean) => void,
  isFetching?: boolean,
  patientStubs?: List<PatientStubModel>,
  hideLabRequestsTable?: boolean,
  lastUpdateFilter: Map<string, IMoment>,
  updateConfig: (config: Config) => any,
  isFromEncounterHistory: boolean,
  isFromDocValidationModal: boolean,
  validationReferrerModel?: ProcedureRequestModel | ProcedureStatusModel | ProcedureResultModel,
  validationDocObject?: { id: string, type: string },
  isDocValidationModalSaving?: boolean;
  onSaveAtDocValidationModal?: (wasSuccessful: boolean) => void;
};

type State = {
  modalVisible: boolean,
  errorMessage?: string,
  procedureTypes?: List<ProcedureTypeModel>,
  requestedProcedureTypeID: string,
  requestedProcedureTypeName: string,
  orderedProcedureTypes?: List<ProcedureTypeModel>,
  searchQuery?: string,
  isSearching: boolean,
  isRequestingLab: boolean,
  isEditingOrderedLabTest: boolean,
  orderedProcedureRequest?: ProcedureRequestModel,
  columns: Array<CustomColumn>,
  inProgressProcedureRequestID: string,
  inProgressProcedureRequestUiStatus: string,
  isLabResultVisible: boolean,
};

/**
 * Get default columns for table
 * @param {string} locationHash the location.hash value
 * @returns {Array<string>} list of columns
 */
function getOriginalColumns(locationHash: string) {
  const isOnLabRequestsPage = (locationHash === '#/lab-requests');
  const columns = [];
  if (isOnLabRequestsPage) {
    columns.push(
      { value: 'date', label: translate('date') },
      { value: 'patient_name', label: translate('patient_name') },
    );
  }
  columns.push(
    { value: 'procedure_name', label: translate('lab_test') },
    { value: 'procedure_code', label: translate('test_id') },
    { value: 'provider_name', label: translate('vendor') },
  );
  if (isOnLabRequestsPage) {
    columns.push({ value: 'status', label: translate('status') });
  }
  if (!isOnLabRequestsPage) {
    columns.push({ value: 'sale_price', label: translate('sale_price'), Cell: renderPrice });
    columns.push({ value: 'results', label: translate('results') });
  }
  if (isOnLabRequestsPage) {
    columns.push({ value: 'last_update', label: translate('last_update') },
      { value: 'actions', label: translate('actions') });
  }
  return columns;
}

/**
   * A Component listing lab requests.
   * @namespace LabRequestsList
   */
class LabRequestsList extends React.Component<Props, State> {
  static defaultProps = {
    useTextButton: false,
    isLabRequestsFormModalVisible: false,
    hideLabRequestsModalButton: false,
    hideLabRequestsTable: false,
    isFromEncounterHistory: false,
  };

  props: Props

  /**
   * Creates an instance of LabRequestsList.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      searchQuery: '',
      errorMessage: '',
      isSearching: false,
      isRequestingLab: false,
      requestedProcedureTypeID: '',
      requestedProcedureTypeName: '',
      isEditingOrderedLabTest: false,
      columns: getOriginalColumns(location.hash),
      inProgressProcedureRequestID: '',
      inProgressProcedureRequestUiStatus: '',
      isLabResultVisible: false,
    };
  }

  /**
   * Called when component rendered completely
   * @returns {undefined}
   */
  componentDidMount() {
    this.getOrderedProcedureTypes();
  }

  /**
   * Called when props changed
   * @param {Props} prevProps PrevProps
   * @returns {undefined}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.procedureRequests !== this.props.procedureRequests) {
      this.getOrderedProcedureTypes();
    }

    if (
      prevProps.isDocValidationModalSaving !== this.props.isDocValidationModalSaving &&
      this.props.isDocValidationModalSaving &&
      this.props.onSaveAtDocValidationModal
    ) {
      if (!this.getProcedureRequests().some(procedureRequest => (
        !!this.props.validationDocObject &&
        this.props.validationDocObject.type === 'procedure_type' &&
        this.props.validationDocObject.id === procedureRequest.get('procedure_type_id')
      ))) {
        this.setState({
          errorMessage: '',
        });
        this.props.onSaveAtDocValidationModal(true);
      } else {
        this.setState({
          errorMessage: translate('resolve_all_data_not_found_before_saving'),
        });
        this.props.onSaveAtDocValidationModal(false);
      }
    }
  }

  /**
   * Get orderedProcedureTypes
   * @returns {undefined}
   */
  getOrderedProcedureTypes() {
    const { procedureTypes } = this.props;
    const orderedProcedureTypes = this.getProcedureRequests()
      .map((procedureRequest) => {
        if (
          this.props.validationDocObject &&
          this.props.validationDocObject.type === 'procedure_type' &&
          this.props.validationDocObject.id === procedureRequest.get('procedure_type_id')
        ) {
          return new ProcedureTypeModel({ ...this.props.validationDocObject, is_missing: true });
        }
        return procedureRequest.getType(procedureTypes);
      });
    this.setState({
      orderedProcedureTypes: (orderedProcedureTypes.size > 0) ? orderedProcedureTypes : List(),
    });
  }

  /**
   * Returns a list of procedure request
   * @param {boolean} includeCancelled whether to include cancelled requests. Defaults to false.
   * @returns {List<ProcedureRequestModel>} Procedure requests model
   */
  getProcedureRequests(includeCancelled: boolean = false) {
    const { encounterID, patientID, procedureRequests, procedureStatuses } = this.props;
    const statusesToNotInclude = ['cancelled', 'rejected'];
    const allProcedureRequests = procedureRequests.filter(procedureRequest =>
      (includeCancelled ?
        procedureRequest : !statusesToNotInclude
          .includes(procedureRequest.getStatusLastEventStatus(procedureStatuses))));
    const filteredProcedureRequests = allProcedureRequests.filter(p => p.get('encounter_id') === encounterID && p.get('patient_id') === patientID);
    return (encounterID && patientID) ? filteredProcedureRequests : allProcedureRequests;
  }

  /**
   * Get specimens for the procedure request.
   * @param {ProcedureRequestModel} procedureRequest the ProcedureRequestModel
   * @returns { List<SpecimenModel> }
   */
  getSpecimens(procedureRequest: ProcedureRequestModel) {
    const { procedureTypes, specimens } = this.props;
    return procedureRequest.getSpecimens(procedureTypes, specimens);
  }

  /**
   * Handle closing modal
   * @returns {undefined}
   */
  handleClose = () => {
    this.setState({ modalVisible: false });
    if (this.props.handleLabRequestsFormModalVisibility) {
      this.props.handleLabRequestsFormModalVisibility(false);
    }
  }

  /**
   * Handle editing ordered procedure request
   * @param {ProcedureRequestModel} procedureRequest a procedureRequest model
   * @returns {undefined}
   */
  handleEditingOrderedLabTest = (procedureRequest: ProcedureRequestModel) => {
    const procedureTypes = this.props.procedureTypes.filter(p => p.get('_id') === procedureRequest.get('procedure_type_id'));
    const procedureType = this.props.procedureTypes.find(p => p.get('_id') === procedureRequest.get('procedure_type_id'));
    this.setState({
      modalVisible: true,
      isEditingOrderedLabTest: true,
      orderedProcedureRequest: procedureRequest,
      isRequestingLab: true,
      procedureTypes,
      requestedProcedureTypeID: procedureType.get('_id'),
      requestedProcedureTypeName: procedureType.get('name'),
    });
  }

  /**
   * Returns the procedureRequest models meeting the criteria of the current filter.
   * @returns {List<MapValue>}
   */
  getFilteredData(): List<MapValue> {
    const ordering = {};
    const sortOrder = [
      'sending',
      'pending',
      'received_by_provider',
      'error_sending_order',
      'accepted_by_provider',
      'courier_collected',
      'specimens_received_by',
      'specimens_rejected',
      'test_in_progress',
      'test_completed_pending_approval',
      'test_approved_pending_results',
      'results_received',
      'order_rejected',
      'request_cancelled',
      'mismatch',
    ];
    sortOrder.map((s, i) => ordering[sortOrder[i]] = i); // eslint-disable-line
    const {
      procedureStatuses,
      procedureTypes,
      providers,
      collectedSpecimens,
      procedureResults,
    } = this.props;
    const isLabRequestsPage = location.hash === '#/lab-requests';
    return this.getProcedureRequests(isLabRequestsPage)
      .map((procedureRequest) => {
        const procedureType = procedureRequest.getType(procedureTypes);
        const patient = isLabRequestsPage ? this.props.patientStubs.find(model =>
          procedureRequest.get('patient_id') === model.get('_id')) : undefined;
        const providerName = procedureType ? procedureType.getProviderName(providers) : undefined;
        const lastUpdate = isLabRequestsPage ?
          procedureRequest.getLastEventTimestampOfProcedureStatusAndCollectedSpecimen(
            this.props.procedureStatuses,
            this.props.collectedSpecimens,
            this.props.procedureTypes,
          ) : new Date().getTime();
        const untranslatedStatus = procedureRequest.getUiStatus(procedureStatuses,
          procedureTypes,
          collectedSpecimens);
        const translateStatusWithProviderName = [
          'specimens_received_by',
          'received_by_provider',
          'accepted_by_provider'];
        const resultAssets = procedureRequest && procedureRequest.getResultAssets(procedureResults);
        const results =
          (procedureRequest &&
            resultAssets &&
            (resultAssets.length
              ? this.renderLabResult(resultAssets)
              : translate('not_uploaded'))) ||
          UNICODE.EMDASH;
        return {
          id: procedureRequest.get('_id'),
          number: '',
          date: procedureRequest.getCreatedTime(),
          procedure_name: procedureType ? procedureType.getName() : UNICODE.EMDASH,
          patient_name: patient ? patient.get('patient_name') : UNICODE.EMDASH,
          procedure_code: procedureType.getTestCode() || UNICODE.EMDASH,
          provider_name: providerName || UNICODE.EMDASH,
          sale_price: procedureType ? procedureType.get('price') : undefined, // this value will aggregated further, don't pass the string
          results,
          status: translate(
            untranslatedStatus,
            translateStatusWithProviderName.includes(untranslatedStatus) ? { providerName } : {},
          ),
          untranslatedStatus: this.state.inProgressProcedureRequestID === procedureRequest.get('_id') ?
            this.state.inProgressProcedureRequestUiStatus : untranslatedStatus,
          actions: this.getActionButton(procedureRequest, untranslatedStatus),
          last_update: isLabRequestsPage ? convertTimestampToDate(lastUpdate) : '',
          procedure_type_sub_items: procedureType ? procedureType.get('sub_items') : [],
        };
      })
      .sort((a, b) => (ordering[a.untranslatedStatus] - ordering[b.untranslatedStatus]) ||
        b.date - a.date); // sort by status and by date.
  }

  /**
   * Renders action buttons
   * @param {ProcedureRequestModel} procedureRequest The procedureRequest model
   * @param {string} untranslatedStatus the row untranslated status. eg.received_by_provider, pending
   * @return {string} - HTML markup for lab order id
   */
  getActionButton(procedureRequest: ProcedureRequestModel, untranslatedStatus: string) {
    return (
      <LabRequestActionButton
        procedureRequest={procedureRequest}
        procedureStatuses={this.props.procedureStatuses}
        procedureResults={this.props.procedureResults}
        procedureTypes={this.props.procedureTypes}
        specimens={this.props.specimens}
        collectedSpecimens={this.props.collectedSpecimens}
        saveModel={this.props.saveModel}
        saveModels={this.props.saveModels}
        patientStubs={this.props.patientStubs}
        user={this.props.user}
        isSendingInProgress={(isSending) => {
          if (isSending) {
            this.setState({ inProgressProcedureRequestUiStatus: untranslatedStatus });
          } else {
            this.setState({
              inProgressProcedureRequestID: '',
              inProgressProcedureRequestUiStatus: '',
            });
          }
        }}
        inProgressProcedureRequestID={
          inProgressProcedureRequestID => this.setState({ inProgressProcedureRequestID })
        }
      />
    );
  }

  /**
   * Renders button and lab reuslt component
   * @param {Array<AssetObject>} assets Lab result assets
   * @returns {React.Component}
   */
  renderLabResult(assets: Array<AssetObject>) {
    return (
      <div>
        <LabResult
          assets={assets}
          isProcedureResultModalVisible={this.state.isLabResultVisible}
          onClose={() => this.setState({ isLabResultVisible: false })}
          onClickedUpload={() => {}}
          noUploadButton
        />
        <Button
          type="button"
          className="o-button o-button--small"
          onClick={() => this.setState({ isLabResultVisible: true })}
          dataPublic
        >
          {translate('view_results')}
        </Button>
      </div>
    );
  }

  /**
   * Renders lab request edit modal content
   * @param {Array<AssetObject>} assets Lab result assets
   * @returns {JSX.Element}
   */
  renderAddEditLabRequestsModalContent() {
    const labTestsTableProcedureTypes = this.state.procedureTypes // this.state.procedureTypes - used when searching lab tests.
      ? this.state.procedureTypes
      : this.props.validationDocObject && this.props.validationDocObject.type === 'procedure_type'
        ? this.props.procedureTypes
          .filter(e => e.get('_id') !== this.props.validationDocObject?.id)
          .unshift(new ProcedureTypeModel({ ...this.props.validationDocObject, is_missing: true }))
        : this.props.procedureTypes;
    return (
      <Fragment>
        {
          this.state.errorMessage &&
          <div className="o-form"><FormError isSticky>{this.state.errorMessage}</FormError></div>
        }
        { !this.state.isRequestingLab &&
          this.state.orderedProcedureTypes &&
          <div>
            <LabTestsTable
              isOrderedTests
              providers={this.props.providers}
              procedureTypes={this.state.orderedProcedureTypes}
              originalProcedureTypes={this.props.procedureTypes}
              procedureRequests={this.getProcedureRequests()}
              saveModel={this.props.saveModel}
              user={this.props.user}
              config={this.props.config}
              isSearching={this.state.isSearching}
              searchQuery={this.state.searchQuery}
              showPagination={false}
              showStar={false}
              encounterID={this.props.encounterID}
              patientID={this.props.patientID}
              saveModels={this.props.saveModels}
              procedureStatuses={this.props.procedureStatuses}
              encounter={this.props.encounter}
            />
          </div>
        }
        { !this.state.isRequestingLab &&
          <LabRequestsSearchInput
            value={this.state.searchQuery || ''}
            procedureTypes={this.props.procedureTypes}
            onChange={
              (searchQuery, procedureTypes, isSearching) =>
                this.setState({
                  searchQuery,
                  procedureTypes,
                  isSearching,
                })
            }
          />
        }
        { this.state.isRequestingLab &&
          <LabRequestsForm
            requestedProcedureTypeID={this.state.requestedProcedureTypeID}
            requestedProcedureTypeName={this.state.requestedProcedureTypeName}
            saveModel={this.props.saveModel}
            procedureRequest={this.state.orderedProcedureRequest}
            procedureStatuses={this.props.procedureStatuses}
            procedureResults={this.props.procedureResults}
            procedureTypes={this.props.procedureTypes}
            collectedSpecimens={this.props.collectedSpecimens}
            specimens={
              this.state.orderedProcedureRequest ?
                this.getSpecimens(this.state.orderedProcedureRequest) : undefined
            }
            isEditingOrderedLabTest={this.state.isEditingOrderedLabTest}
            handleLabRequestsFormModalVisibility={
              this.props.handleLabRequestsFormModalVisibility
            }
            handleFormVisibility={() => this.handleClose()}
          />
        }
        <LabTestsTable
          providers={this.props.providers}
          procedureTypes={
            labTestsTableProcedureTypes.filter(m => m.isMissing() ||
            (m.isActive() && m.getProvider(this.props.providers)?.isActive()))
          }
          originalProcedureTypes={this.props.procedureTypes}
          saveModel={this.props.saveModel}
          user={this.props.user}
          config={this.props.config}
          isSearching={this.state.isSearching}
          searchQuery={this.state.searchQuery}
          isShowingRequestedLab={this.state.isRequestingLab}
          showPagination={!this.state.isRequestingLab}
          procedureRequests={this.getProcedureRequests()}
          encounterID={this.props.encounterID}
          patientID={this.props.patientID}
          saveModels={this.props.saveModels}
          procedureStatuses={this.props.procedureStatuses}
          encounter={this.props.encounter}
        />
      </Fragment>
    );
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const isOnLabRequestsPage = (location.hash === '#/lab-requests');
    if (this.props.isFromDocValidationModal) {
      return this.renderAddEditLabRequestsModalContent();
    }
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-card__header" style={this.props.hideLabRequestsTable ? { display: 'none' } : {}} dataPublic>
          <h1 className="o-card__title">{ translate('lab_tests') }</h1>
          <div className="u-flex-right u-margin-right--1ws">
            <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_lab_tests', 'update')])} user={this.props.user}>
              <StatelessModal
                id="addEditLabRequestsModal"
                buttonLabel={translate(this.getFilteredData().size > 0 ? 'edit' : 'order')}
                buttonClass={this.props.isFromEncounterHistory ? 'o-text-button o-text-button--contextual' : 'o-button o-button--padded o-button--small u-width-180'}
                title={translate('request_for', { patientName: this.state.orderedProcedureRequest ? this.state.orderedProcedureRequest.getPatientName(this.props.patientStubs) : '' })}
                noButton={this.props.hideLabRequestsModalButton}
                setVisible={
                  (isVisible: boolean) => {
                    if (this.props.handleLabRequestsFormModalVisibility) {
                      this.props.handleLabRequestsFormModalVisibility(false);
                    }
                    this.setState({
                      modalVisible: isVisible,
                      isRequestingLab: false,
                      procedureTypes: undefined,
                      isEditingOrderedLabTest: false,
                    });
                  }
                }
                visible={this.state.modalVisible || this.props.isLabRequestsFormModalVisible}
                large
                explicitCloseOnly
                dataPublicHeader
              >
                { this.renderAddEditLabRequestsModalContent() }
                { !this.state.isRequestingLab &&
                  <ModalFooter>
                    <Button dataPublic className="o-button o-button--small u-margin-right--half-ws" onClick={() => this.handleClose()}>{ translate('close') }</Button>
                  </ModalFooter>
                }
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        { location.hash === '#/lab-requests' &&
          <div className="o-header-actions">
            <div className="u-flex-right u-margin-right--half-ws">
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="lab_requests"
                updateConfig={this.props.updateConfig}
                originalColumns={List(getOriginalColumns(location.hash))}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
              />
            </div>
          </div>
        }
        { !this.props.hideLabRequestsTable &&
          <div>
            <LabRequestsTable
              columns={this.state.columns}
              data={
                this.getFilteredData()
                  .filter((data) => {
                    if (isOnLabRequestsPage) {
                      if (this.props.lastUpdateFilter.get('filterDateStart') === null
                        || this.props.lastUpdateFilter.get('filterDateEnd') === null) {
                        return data;
                      }
                      return Moment(data.last_update).isBetween(
                        this.props.lastUpdateFilter.get('filterDateStart'),
                        this.props.lastUpdateFilter.get('filterDateEnd'), 'day', [],
                      );
                    }
                    return data;
                  })
                  .map(data => (data.last_update ?
                    Object.assign(data, {
                      last_update: prettifyDate(convertDateToTimestamp(data.last_update)),
                    }) : data))
              }
              isFetching={this.props.isFetching}
              getTrProperties={(procedureRequestID) => { // When clicking row in Lab Requests page
                if (isOnLabRequestsPage && hasPermission(this.props.user, List([createPermission('lab_requests', 'update')]))) {
                  const procedureRequest = this.props.procedureRequests.find(p => p.get('_id') === procedureRequestID);
                  this.handleEditingOrderedLabTest(procedureRequest);
                }
              }}
              procedureTypes={this.props.procedureTypes}
              encounter={this.props.encounter}
            />
          </div>
        }
      </ContentTransition>
    );
  }
}
export default LabRequestsList;
