import * as React from 'react';
import { ReactSpecimen } from 'catalog';
import { Div } from 'glamorous';
import { MemoryRouter } from 'react-router-dom';

import { wsUnit } from './../../../utils/css';
import Header from './../../header/header';
import KeyValue from './../../layout/keyValue';
import ToggleCard from './../../layout/toggleCard';

/**
 * Layout components for styleguide
 * @class LayoutComponents
 * @extends {React.Component<Props, State>}
 */
const LayoutComponents = () =>
  <React.Fragment>
    <h2 data-public>Layout Components</h2>
    <h3 data-public>Header</h3>
    <p>
      A page wide header used to display a title for context and any necessary buttons. This
      component should only be used when the application sidebar is hidden. Generally the backLink
      prop should be given in order to allow the user to navigate away from the full screen view.
    </p>
    <ReactSpecimen>
      <Header label="My Header" />
    </ReactSpecimen>
    <ReactSpecimen>
      <MemoryRouter initialEntries={['/']}>
        <Header label="My Header" backLink="#" />
      </MemoryRouter>
    </ReactSpecimen>
    <p>It can accept children components (styling is independent).</p>
    <ReactSpecimen>
      <Header label="My Header">
        <div style={{ marginLeft: 'auto', marginRight: '1rem' }}>Item 1</div>
        <div style={{ marginRight: '1rem' }}>Item 2</div>
      </Header>
    </ReactSpecimen>
    <p>It can accept an `isOffline` prop that will change the colour of the Header.</p>
    <ReactSpecimen>
      <Header label="My Header" isOffline />
    </ReactSpecimen>
    <h3 data-public>KeyValue</h3>
    <p>
      A component for displaying lists of data with a key and value. The prop label is used instead
      of key in order to avoid clashes with the React API.
    </p>
    <ReactSpecimen>
      <KeyValue label="Key" value="Value" />
    </ReactSpecimen>
    <ReactSpecimen>
      <Div css={{ display: 'flex', flexDirection: 'column' }}>
        <KeyValue label="Key" value="Value" isDanger />
        <KeyValue label="Key" value="Value" />
        <KeyValue label="Key" value="Value" isDanger />
      </Div>
    </ReactSpecimen>
    <h3 data-public>ToggleCard</h3>
    <p>A card components that can be toggled open and close.</p>
    <ReactSpecimen>
      <ToggleCard title="My ToggleCard" dataPublic>
        <p style={{ padding: wsUnit }}>Hello!</p>
      </ToggleCard>
    </ReactSpecimen>
  </React.Fragment>;

export default LayoutComponents;
