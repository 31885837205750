import { List, Map } from 'immutable';
import { uniqBy, concat } from 'lodash';

import { sortByDate, sortByPayment, compareByAlphabeticalOrder } from '../../utils/comparators';
import translate from '../../utils/i18n';
import { createPermission, hasPermission } from '../../utils/permissions';
import { renderCellWithLinkAndClassName, renderDateWithLink, renderPriceOrString, renderWithLink } from '../../utils/tables';
import type { Column, Config, User } from '../../types';

// TODO: Remove `optionalColumns` checks from all the tables after 2.36 release.
// The `show` boolean value for some columns was moved from another field of config to sortable column.
// The below checks are done for glitch-free transition of users from 2.35 to 2.36

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @returns {object[]} An array of objects each representing a column in the table.
 */
export const getAppointmentRequestTableColumns = (config: Config) => {
  const optionalColumns = config.getIn(['patient_overview', 'patient_fields'], List()).toArray();
  return [
    { accessor: 'scheduled_time', Header: translate('scheduled_time'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right' },
    {
      accessor: 'name', Header: translate('name'), Cell: renderWithLink,
    },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('case_id') },
    { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('ic') },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('sex') },
    { accessor: 'panel_or_cash', Header: translate('panel_or_cash'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'panel_name', Header: translate('panel_name'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('panel_name') },
    { accessor: 'doctor', Header: translate('doctor'), Cell: renderWithLink },
    { accessor: 'encounter_flow', Header: translate('encounter_flow'), Cell: renderWithLink },
    {
      accessor: 'action', Header: '', sortable: false,
    },
  ];
};

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @param {User} user User
 * @returns {object[]} An array of objects each representing a column in the table.
 */
export const getCompletedEncounterTableColumns = (config: Config, user: User) => {
  const showQueueNumber = config.getIn(['patient_queue', 'enable_queue_number'], true);
  const optionalColumns = config.getIn(['patient_overview', 'patient_fields'], List()).toArray();
  const completedCardColumnShown = config.getIn(['patient_overview', 'completed_encounter_card_columns_shown'], 'arrival_time');
  const columns = [
    showQueueNumber && {
      accessor: 'q_no',
      Header: translate('q_no'),
      Cell: renderCellWithLinkAndClassName,
      width: 60,
      className: 'u-strong',
    },
    { accessor: 'arrival_time', Header: translate('arrival_time'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right' },
    { accessor: 'arrival_date', Header: translate('arrival_date'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right', hideDefault: true },
    {
      accessor: 'name', Header: translate('name'), Cell: renderWithLink, show: true,
    },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('case_id') },
    { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('ic') },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('sex') },
    { accessor: 'panel_or_cash', Header: translate('panel_or_cash'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'panel_name', Header: translate('panel_name'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('panel_name') },
    { accessor: 'doctor', Header: translate('doctor'), Cell: renderWithLink, show: true },
    { accessor: 'creator', Header: translate('creator'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'encounter_flow', Header: translate('encounter_flow'), Cell: renderWithLink, show: true },
    { accessor: 'completed_time', Header: translate('completed_time'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'completed_date', Header: translate('completed_date'), Cell: renderWithLink, hideDefault: true },
    {
      accessor: 'amount_received',
      Header: user && hasPermission(user, List([createPermission('finalised_bill', 'read')])) ? translate('amount_received') : '',
      Cell: renderPriceOrString,
      sortMethod: sortByPayment,
    },
    { accessor: 'payment_type', Header: translate('payment_type'), Cell: renderWithLink, show: true },
  ];
  return columns.reduce((columnsToSort, col) => {
    if (!col) return columnsToSort;
    if (col.accessor === completedCardColumnShown) {
      return columnsToSort.splice(showQueueNumber, 0, {
        ...col,
        hideDefault: false,
      });
    }
    return columnsToSort.push(col);
  }, List()).toArray();
};

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @param {boolean} includeStage Includes stage column (Only needed for inprogress table)
 * @returns {object[]} An array of objects each representing a column in the table.
 */
export const getEncounterTableColumns = (config: Config, includeStage?: boolean) => {
  const optionalColumns = config.getIn(['patient_overview', 'patient_fields'], List()).toArray();
  const showQueueNumber = config.getIn(['patient_queue', 'enable_queue_number'], true);
  const columns = [
    showQueueNumber && {
      accessor: 'q_no',
      Header: translate('q_no'),
      Cell: renderCellWithLinkAndClassName,
      width: 60,
      className: 'u-strong',
    },
    { accessor: 'arrival_time', Header: translate('arrival_time'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right' },
    { accessor: 'arrival_date', Header: translate('arrival_date'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right', hideDefault: true },
    {
      accessor: 'name', Header: translate('name'), Cell: renderWithLink,
    },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('case_id') },
    { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('ic') },
    { accessor: 'location', Header: translate('location'), Cell: renderWithLink, show: true },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('sex') },
    { accessor: 'panel_or_cash', Header: translate('panel_or_cash'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'panel_name', Header: translate('panel_name'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('panel_name') },
    { accessor: 'doctor', Header: translate('doctor'), Cell: renderWithLink },
    { accessor: 'creator', Header: translate('creator'), Cell: renderWithLink, hideDefault: true },
    includeStage && { accessor: 'encounter_stage', Header: translate('stage'), Cell: renderWithLink, show: true },
    { accessor: 'encounter_flow', Header: translate('encounter_flow'), Cell: renderWithLink },
    {
      accessor: 'action', Header: '', sortable: false, width: 200,
    },
  ];
  return columns.filter(c => !!c);
};

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @param {boolean} includeStage Includes stage column (Only needed for inprogress table)
 * @returns {object[]} An array of objects each representing a column in the table.
 */
export const getIncompletedEncounterTableColumns = (config: Config) => {
  const optionalColumns = config.getIn(['patient_overview', 'patient_fields'], List()).toArray();
  return [
    {
      accessor: 'date', Header: translate('date'), Cell: renderDateWithLink, sortMethod: sortByDate,
    },
    { accessor: 'time', Header: translate('time'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right' },
    { accessor: 'name', Header: translate('name'), Cell: renderWithLink },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('case_id') },
    { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('ic') },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('sex') },
    { accessor: 'panel_name', Header: translate('panel_name'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('panel_name') },
    { accessor: 'doctor', Header: translate('doctor'), Cell: renderWithLink },
    { accessor: 'creator', Header: translate('creator'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'view_bill', Header: '', sortable: false },
    { accessor: 'void_bill', Header: '', sortable: false },
  ];
};

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @returns {object[]} An array of objects each representing a column in the table.
 */
export const getScheduledEncounterTableColumns = (config: Config) => {
  const optionalColumns = config.getIn(['patient_overview', 'patient_fields'], List()).toArray();
  return [
    { accessor: 'scheduled_time', Header: translate('scheduled_time'), Cell: renderWithLink },
    {
      accessor: 'name', Header: translate('name'), Cell: renderWithLink,
    },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('case_id') },
    { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('ic') },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('sex') },
    { accessor: 'panel_or_cash', Header: translate('panel_or_cash'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'panel_name', Header: translate('panel_name'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('panel_name') },
    { accessor: 'doctor', Header: translate('doctor'), Cell: renderWithLink },
    { accessor: 'encounter_flow', Header: translate('encounter_flow'), Cell: renderWithLink },
    {
      accessor: 'action', Header: '', sortable: false, width: 207,
    },
  ];
};

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @returns {object[]} An array of objects each representing a column in the table.
 */
export const getWaitingEncounterTableColumns = (config: Config) => {
  const showQueueNumber = config.getIn(['patient_queue', 'enable_queue_number'], true);
  const optionalColumns = config.getIn(['patient_overview', 'patient_fields'], List()).toArray();
  const columns = [
    showQueueNumber && {
      accessor: 'q_no',
      Header: translate('q_no'),
      Cell: renderCellWithLinkAndClassName,
      width: 60,
      className: 'u-strong',
    },
    { accessor: 'arrival_time', Header: translate('arrival_time'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right' },
    { accessor: 'arrival_date', Header: translate('arrival_date'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right', hideDefault: true },
    { accessor: 'waiting_time', Header: translate('waiting_time_mins'), Cell: renderCellWithLinkAndClassName, className: 'u-flex-justify-content-right' },
    {
      accessor: 'name', Header: translate('name'), Cell: renderWithLink,
    },
    { accessor: 'case_id', Header: translate('case_id'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('case_id') },
    { accessor: 'location', Header: translate('location'), Cell: renderWithLink, show: true },
    { accessor: 'ic', Header: translate('ic'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('ic') },
    { accessor: 'sex', Header: translate('sex'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('sex') },
    { accessor: 'panel_or_cash', Header: translate('panel_or_cash'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'panel_name', Header: translate('panel_name'), Cell: renderWithLink, hideDefault: !optionalColumns.includes('panel_name') },
    { accessor: 'doctor', Header: translate('doctor'), Cell: renderWithLink },
    { accessor: 'creator', Header: translate('creator'), Cell: renderWithLink, hideDefault: true },
    { accessor: 'encounter_stage', Header: translate('stage'), Cell: renderWithLink },
    { accessor: 'encounter_flow', Header: translate('encounter_flow'), Cell: renderWithLink },
    { accessor: 'action', Header: '', sortable: false, width: 180 },
  ];
  return columns.filter(c => !!c);
};

/**
 * Returns an array of unique columns that is used in sort and filter for whole page
 * @param {Config} config clinic config
 * @param {User} user UserModel
 * @returns {Map<string, Column>}
 */
export const getOverviewColumns = (config: Config, user: User): Map<string, Column> => {
  const allColumns = concat(
    getAppointmentRequestTableColumns(config),
    getCompletedEncounterTableColumns(config, user),
    getEncounterTableColumns(config),
    getIncompletedEncounterTableColumns(config),
    getWaitingEncounterTableColumns(config),
  ).filter(col => !!col.Header && col.sortable !== false);
  return Map(
    uniqBy(allColumns, 'accessor')
      .sort((a, b) => compareByAlphabeticalOrder(a.Header, b.Header))
      .map(c => [c.accessor, c]),
  );
};
