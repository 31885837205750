import React, { Children, isValidElement, cloneElement } from 'react';
import { List, Map } from 'immutable';
import moment from 'moment';
import glamorous from 'glamorous';

import { HL7_POLICY_HOLDER_RELATIONSHIP_CODES, NATIONALITIES, DATE_FORMAT_TO_SAVE } from './../../constants';
import translate from './../../utils/i18n';
import AllergyList from './../allergies/allergyList';
import Input from './../inputs/input';
import MultiSelect from './../inputs/multiSelect';
import Radio from './../inputs/radio';
import Select from './../inputs/select';
import DateInput from './../inputs/dateInput';
import TextArea from './../inputs/textarea';
import TagInput from './../inputs/tagInput';
import SaveButton from './../buttons/saveButton';
import SavePrompt from './../prompts/savePrompt';
import CoveragePayorForm from './../coveragePayors/coveragePayorForm';
import StatelessModal from './../modals/statelessModal';
import FormError from './../formError';
import { isReferralMode } from '../../utils/router';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { getUniqueCaseId, getDobFromIcNumber, getSexFromIcNumber, validateAndTrimString, getConfirmation } from './../../utils/utils';
import { wsUnit } from '../../utils/css';
import { updateClinicConfig } from './../../utils/db';
import { getDateFormat, formatDateForSave } from './../../utils/time';
import KeyPress from './../keypress';
import { knuthMorrisPratt } from './../../utils/search';

import type AllergyModel from './../../models/allergyModel';
import CoveragePayorModel from './../../models/coveragePayorModel';
import CoveragePayorPolicyModel from './../../models/coveragePayorPolicyModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PatientModel from './../../models/patientModel';
import type { Config, MapValue, User, SaveModel } from './../../types';
import type InventoryMapModel from './../../models/inventoryMapModel';
import type DrugModel from './../../models/drugModel';

type Props = {
  config: Config,
  saveModel: SaveModel,
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
  initialAttributes: {
    patient_name: string, // eslint-disable-line camelcase
    [key: string]: MapValue,
    _id?: string,
  },
  showAllergies?: boolean,
  editInfoFromIC?: boolean,
  onSaveClicked: (attributes: object, models: List<AllergyModel>) => Promise<boolean>,
  user: User,
  patientStubs: List<PatientStubModel>,
  isFromModal: boolean,
  showSaveButton?: boolean,
  isSaving?: boolean,
  onSaveAtValidationModal?: (wasSuccessful: boolean) => void,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
  allergies?: List<AllergyModel>,
};

/* eslint-disable camelcase */
type PatientAttributes = {
  patient_name: string,
  case_id?: string,
  ic?: string,
  sex?: string,
  dob?: string,
  nationality?: string,
  ethnicity?: Array<string>,
  email?: string,
  tel?: string,
  address?: string,
  postal_code?: string,
  occ?: string,
  current_employer?: string,
  notes?: string,
  tags?: Array<string>,
  ward_number?: string,
  room_number?: string,
  bed_number?: string,
  allergies: List<AllergyModel>,
  coverage?: Array<{
    payor: string,
    subscriber: string,
    relationship: string,
    policy: string,
    policyHolder: string,
  }>,
  [key: string]: MapValue,
};
/* eslint-disable camelcase */

type State = {
  attributes: PatientAttributes,
  isSaving: boolean,
  showError: boolean,
  errorMessage: string,
  changesMade: boolean,
  isGeneratingCaseId: boolean,
  newPanelModalVisible: boolean,
  newPanelInput: string,
  newPanelPolicyInputId: string,
  modelToEdit: CoveragePayorModel | void,
};

const CaseIdWrapper = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  '& .o-form__item': {
    flexGrow: 1,
    marginRight: wsUnit,
  },
});

const DEFAULT_ATTRIBUTES: PatientAttributes = {
  patient_name: '',
  case_id: '',
  ic: '',
  sex: '',
  dob: '',
  nationality: '',
  ethnicity: [],
  tel: '',
  email: '',
  address: '',
  postal_code: '',
  occ: '',
  current_employer: '',
  notes: '',
  tags: [],
  ward_number: '',
  room_number: '',
  bed_number: '',
  allergies: List(),
  coverage: [],
};

type FormWrapperProps = {
  children: React.ReactNode[],
  isFromModal: boolean,
}

const FormWrapper = (WrappedComponent => (({ children, ...rest }: FormWrapperProps) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        width: `${100 / children.length}%`,
      });
    }

    return child;
  });
  return (
    <WrappedComponent
      {...rest}
    >
      {childrenWithProps}
    </WrappedComponent>
  );
}))(glamorous.div<{
  isFromModal?: boolean,
}>({
  display: 'flex',
  flexWrap: 'nowrap',
}, ({ isFromModal }) => ({
  flexDirection: isFromModal ? 'column' : 'row',
})));

const FormColumn = glamorous.div<{
  isFromModal?: boolean,
  width?: string,
}>({
  display: 'block',
  flexGrow: 1,
  paddingRight: 'calc(1.5 * 1.333rem)',
  paddingLeft: 'calc(1.5 * 1.333rem)',
  borderRight: '1px solid #e0dfdf',
  '&:first-child': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
    border: 'none',
  },
}, ({ isFromModal, width }) => ({
  width: isFromModal ? '100%' : width,
  ...(isFromModal ? { padding: 0, border: 'none' } : {}),
}));


/**
 * A form that contains all editable fields of a patient document, as well as allergies if props
 * allow
 * @class PatientProfileForm
 * @extends {React.Component}
 */
class PatientProfileForm extends React.Component<Props, State> {
  static defaultProps = { initialAttributes: {} };

  /**
   * Creates an instance of PatientProfileForm.
   * @param {Props} props The initial props.
   */
  constructor(props: Props) {
    super(props);
    const attributes = Object.assign({}, DEFAULT_ATTRIBUTES);
    Object.keys(props.initialAttributes).forEach((key) => {
      if (props.initialAttributes[key] !== undefined && attributes[key] !== undefined) {
        // check for ethnicity
        if (key === 'ethnicity' &&
          typeof props.initialAttributes[key] === 'string') {
          const ethnicityItem = this.getMatchingEthnicityItem(props.initialAttributes[key]);
          if (ethnicityItem && !props.initialAttributes._id) {
            // if the passed value is something we already have in select options then either pass converting to lower
            // case and set the value from matching option in state if new patient registartion.
            attributes.ethnicity = ethnicityItem;
          } else {
            attributes[key] = props.initialAttributes[key];
          }
        } else {
          attributes[key] = props.initialAttributes[key];
        }
      }
    });
    this.state = {
      attributes,
      changesMade:
        props.initialAttributes &&
        Object.keys(props.initialAttributes).length === 1 &&
        props.initialAttributes.patient_name !== undefined &&
        props.initialAttributes.patient_name.length > 0, // Consider a change made if patient name prefilled.
      isSaving: false,
      showError: false,
      errorMessage: translate('fill_required_fields'),
      isGeneratingCaseId: false,
      newPanelModalVisible: false,
      newPanelInput: '',
      newPanelPolicyInputId: '',
      modelToEdit: undefined,
    };
  }

  /**
   * Triggers the saving function when the isSaving prop changes
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isFromModal
      && this.props.isSaving !== prevProps.isSaving
      && this.props.isSaving) {
      this.onSaveClicked();
    }
  }

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState({
      attributes: Object.assign(this.state.attributes, { [key]: value }),
      changesMade: true,
    });
    if (key === 'tags') {
      this.updatePatientTagsConfig(value);
    }
  }

  /**
   * Checks the given tags values against what exists in config and updates config if necessary.
   * @param {[string]} values A list of strings derived from newly created tags.
   * @returns {void}
   */
  updatePatientTagsConfig = (values: Array<string>) => {
    const existingValues = this.props.config.getIn(['patient_tags', 'options'], List());
    const newValues = values.filter(newValue => !existingValues.find(existingValue =>
      existingValue.toLowerCase() === newValue.toLowerCase()));
    if (newValues.length > 0) {
      const configUpdates = Map()
        .setIn(['patient_tags', 'options'], existingValues.concat(List(newValues)))
        .toJS();
      updateClinicConfig(this.props.config.toJS(), configUpdates, this.props.updateConfig);
      this.props.updateConfigValue(['patient_tags', 'options'], existingValues.concat(List(newValues)));
    }
  }

  /**
   * Updates state.attribute for date of birth and sex with details from IC number.
   * @param {string} ic The string entered in the IC field
   * @returns {object} k:v pairs for dob and sex
   */
  parseAttributesFromIC(ic: string) {
    if (ic && ic.length > 11) {
      const dobFromIc = getDobFromIcNumber(ic);
      const dob = dobFromIc && moment(dobFromIc).isValid() ?
        dobFromIc.format(DATE_FORMAT_TO_SAVE) : undefined;
      const updates = { dob, sex: getSexFromIcNumber(ic) };
      this.setState({ attributes: Object.assign(this.state.attributes, updates) });
    }
  }

  /**
   * Checks that the values of the form are valid.
   * @param {string} field For each field in the form
   * @returns {boolean} True if valid, false otherwise.
   */
  async formatAndValidateAttributes(): Promise<false | PatientAttributes> {
    const { ...attributes } = this.state.attributes;
    const requiredFields = this.props.config
      .getIn(['patient', 'requiredFields'], List())
      .push('patient_name'); // Patient name is always required
    const isDobRequired = requiredFields.includes('dob');
    const formattedDate = formatDateForSave(attributes.dob);
    if ((isDobRequired && !formattedDate) || (attributes.dob && !formattedDate)) {
      this.setState({ errorMessage: translate('fill_date_in_correct_format') });
      return false;
    }
    attributes.dob = formattedDate;
    const requiredFieldsValid = requiredFields.every((field: string) =>
      attributes[field] !== undefined &&
      validateAndTrimString(attributes[field]).length > 0);
    if (!requiredFieldsValid) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    const isICExisting = attributes.ic && this.props.patientStubs.some(p => p.get('ic')?.toUpperCase() === attributes.ic?.toUpperCase());
    if ((attributes?.ic?.toUpperCase() !== this.props?.patient?.attributes?.ic?.toUpperCase()) && isICExisting) {
      const isSameICAllowed = this.props.config.getIn(['patient', 'allow_same_ic'], false);
      const confirmMessage = translate(isSameICAllowed ? 'duplicate_ic_warning' : 'duplicate_ic_error');
      const confirmOptions = {
        modalTitle: translate('duplicate_ic_detected'),
        hideCancel: true,
        ...(isSameICAllowed && {
          hideCancel: false,
          footerSaveButtonName: translate('proceed_anyway'),
          footerCancelButtonName: translate('cancel'),
          cancelButtonClassName: 'o-button-green',
          proceedButtonClassName: 'o-button--danger',
        }),
      };
      return getConfirmation(confirmMessage, confirmOptions).then(() => {
        if (isSameICAllowed) {
          return attributes;
        }
        this.setState({ errorMessage: translate('duplicate_ic_detected') });
        return false;
      }, () => {
        this.setState({ errorMessage: translate('duplicate_ic_detected') });
        return false;
      });
    }
    return attributes;
  }

  /**
   * Called when save is clicked. Passes the new patient atrributes and the new allergies to
   * props.onSaveClicked
   * @returns {Promise<boolean>}
   */
  onSaveClicked = async (): Promise<boolean> => {
    const attributes = await this.formatAndValidateAttributes();
    if (attributes) {
      this.setState({ isSaving: true, changesMade: false });
      const { allergies } = attributes;
      delete attributes.allergies;
      return this.props.onSaveClicked(attributes, allergies)
        .then((wasSuccessful) => {
          if (this.props.onSaveAtValidationModal) this.props.onSaveAtValidationModal(wasSuccessful);
          if (!wasSuccessful) {
            this.setState({ isSaving: false, changesMade: true, showError: true });
          }
          return wasSuccessful;
        });
    }
    if (this.props.onSaveAtValidationModal) this.props.onSaveAtValidationModal(false);
    this.setState({ showError: true });
    return Promise.resolve(false);
  }

  /**
   * Returns the value of the field at key in the coverage object, or an empty string if not
   * available.
   * @param {string} key The key of the value to fetch.
   * @returns {string} The coverage field value.
   */
  getCoverageField(key: string): string {
    if (!this.state.attributes.coverage || this.state.attributes.coverage.length === 0) {
      return '';
    }
    return this.state.attributes.coverage[0][key] ? this.state.attributes.coverage[0][key] : '';
  }

  /**
   * Returns an array of objects, or an empty Array if not
   * available.
   * @param {Props} props The props.
   * @returns {Array<object>}
   */
  getCoveragePolicyOptions = (props: Props) => {
    const coveragePayor = props.coveragePayors ?
      props.coveragePayors.find(payor => payor.get('_id') === this.getCoverageField('payor')) :
      undefined;
    return coveragePayor && coveragePayor.get('policies') ?
      [...coveragePayor.get('policies')
        .filter(policy => policy.active)
        .map(policy => ({ value: policy._id, label: policy.policy_name })),
      { value: 'create', label: translate('add_new_coverage_policy') }] :
      [{ value: 'create', label: translate('add_new_coverage_policy') }];
  }

  /**
   * Sets the field at key in coverage to the given value. If key is 'payor' and value is ''
   * then the coverage object is reset.
   * @param {string} key The key to updated.
   * @param {string} value The value to update.
   * @returns {undefined}
   */
  setCoverageField(key: string, value: string) {
    if (key === 'payor' && value === '') {
      this.updateAttribute('coverage', [{}]);
    } else {
      let currentCoverage;
      if (!this.state.attributes.coverage || this.state.attributes.coverage.length === 0) {
        currentCoverage = {
          payor: '',
          subscriber: '',
          relationship: '',
          policy: '',
          policyHolder: '',
        };
      } else {
        currentCoverage = Object.assign({}, this.state.attributes.coverage[0]);
      }
      const updatedField = {};
      updatedField[key] = value;
      this.updateAttribute('coverage', [Object.assign(currentCoverage, updatedField)]);
    }
  }

  /**
   * Panel Modal Handler
   * @param {string} option New Drug name user input
   * @param {boolean} visible Modal visibility
   * @returns {void}
   */
  handleCreatePanelModal(option: string, visible: boolean) {
    this.setState({
      newPanelModalVisible: visible,
      newPanelInput: option,
      modelToEdit: (visible && option)
        ? new CoveragePayorModel({ name: validateAndTrimString(option), active: true })
        : undefined,
    });
  }

  /**
   * Handles panel modal for new policy input
   * @param {string} option New policy name user input
   * @param {boolean} visible Modal visibility
   * @returns {void}
   */
  handleCreatePolicy(option: string, visible: boolean) {
    const payorId = this.getCoverageField('payor');
    if (payorId) {
      const coveragePayor = this.props.coveragePayors.find(payor => payor.get('_id') === payorId);
      const model = new CoveragePayorPolicyModel({
        policy_name: option || '',
        policy_co_payment_amount: '',
        policy_maximum_claimable_amount: '',
        active: true,
      });
      this.setState({
        newPanelModalVisible: visible,
        newPanelPolicyInputId: model.attributes._id,
        modelToEdit: (coveragePayor && visible)
          ? new CoveragePayorModel({ ...coveragePayor.attributes, policies: [...(coveragePayor.get('policies') || []), model.attributes] })
          : undefined,
      });
    }
  }

  /**
   * clears the local state variable of newly input policy id
   * @returns{void}
   */
  clearNewPanelPolicyInputId() {
    this.setState({ newPanelPolicyInputId: '' });
  }

  /**
   * returns the matching value if the option available in select options list.
   * @param {string} ethnicity ethnicity value passed a property.
   * @returns {string}
   */
  getMatchingEthnicityItem(ethnicity: string) {
    return this.props.config.getIn(['patient', 'options', 'ethnicity'], List())
      .find(value => value && value.toLowerCase() === ethnicity.toLowerCase());
  }

  /**
   * function to get the value for the multiselect dropdown. this returns the value as the value from options available
   * if the patient is a new registration and has not saved yet.IF it is edit patient then just adds case insensitivity
   * to value selection.
   * @returns {string | []}
   */
  getEthnicityValue() {
    if (this.state.attributes.ethnicity && typeof this.state.attributes.ethnicity === 'string') {
      const ethnicityItem = this.getMatchingEthnicityItem(this.state.attributes.ethnicity);
      if (ethnicityItem && !this.state.attributes._id) {
        // if the passed value is something we already have in select options then pass converting to lower
        // case if new patient registartion.
        return this.state.attributes.ethnicity.toLowerCase();
      }
      return this.state.attributes.ethnicity;
    }
    return (this.state.attributes.ethnicity || [])
      .map(value => ({ value, label: value }));
  }

  /**
     * Renders the component.
     *
     * @returns {string} - HTML markup for the component
     */
  render() {
    const requiredFields = this.props.config.getIn(['patient', 'requiredFields'], List());
    const { patientStubs, config, isFromModal, showSaveButton } = this.props;
    return (
      <KeyPress onEnterPressed={this.onSaveClicked}>
        <SavePrompt
          when={!isReferralMode() && this.state.changesMade}
          onSaveClicked={this.onSaveClicked}
        />
        <FormWrapper
          isFromModal={isFromModal}
        >
          <FormColumn
            isFromModal={isFromModal}
          >
            {
              this.state.showError &&
              <FormError containerElementID="main-content">
                {this.state.errorMessage}
              </FormError>
            }
            <Input
              id="patient_name"
              autoFocus
              label={translate('name')}
              value={this.state.attributes.patient_name}
              onValueChanged={(value: string) => this.updateAttribute('patient_name', value)}
              required
            />
            <CaseIdWrapper>
              <Input
                id="case_id"
                label={this.props.config.getIn(['patient', 'labels', 'case_id'], 'Case ID')}
                value={this.state.attributes.case_id}
                onValueChanged={value => this.updateAttribute('case_id', value)}
                required={requiredFields.contains('case_id')}
              />
              <SaveButton
                dataPublic
                isSaving={this.state.isGeneratingCaseId}
                onClick={() => {
                  this.setState({ isGeneratingCaseId: true });
                  const caseId = getUniqueCaseId(patientStubs, config.getIn(['patient', 'case_id', 'autogenerate_prefix'], ''));
                  this.updateAttribute('case_id', caseId);
                  this.setState({ isGeneratingCaseId: false });
                }}
                label={translate('generate')}
                savingLabel={translate('generating')}
              />
            </CaseIdWrapper>
            <Input
              id="ic"
              label={translate('ic')}
              value={this.state.attributes.ic}
              onValueChanged={(value) => {
                this.updateAttribute('ic', value?.toUpperCase());
                if (this.props.editInfoFromIC) {
                  this.parseAttributesFromIC(value?.toUpperCase());
                }
              }}
              required={requiredFields.contains('ic')}
            />
            <Radio
              id="sex"
              label={translate('sex')}
              options={
                this.props.config.getIn(['patient', 'options', 'sex'], List(['male', 'female']))
                  .toArray()
                  .map(value => ({ label: value, value }))
              }
              value={this.state.attributes.sex || ''}
              onValueChanged={value => this.updateAttribute('sex', value)}
              required={requiredFields.contains('sex')}
            />
            <DateInput
              id="dob"
              label={translate('dob')}
              onChange={(value) => {
                this.updateAttribute('dob', value);
              }}
              value={
                this.state.attributes.dob &&
                moment(this.state.attributes.dob, DATE_FORMAT_TO_SAVE, true).isValid()
                  ? moment(this.state.attributes.dob)
                  : this.state.attributes.dob
              }
              required={requiredFields.contains('dob')}
            />
            <Select
              id="nationality"
              label={this.props.config.getIn(['patient', 'labels', 'nationality'], translate('nationality'))}
              value={this.state.attributes.nationality || ''}
              options={NATIONALITIES.map(value => ({ value, label: value }))}
              onValueChanged={value => this.updateAttribute('nationality', value)}
              required={requiredFields.contains('nationality')}
            />
            <MultiSelect
              id="ethnicity"
              label={translate('ethnicity')}
              value={this.getEthnicityValue()}
              closeOnSelect
              options={
                this.props.config.getIn(['patient', 'options', 'ethnicity'], List())
                  .map(value => ({ value, label: value }))
                  .toArray()
              }
              onChange={values =>
                this.updateAttribute('ethnicity',
                  values.map(v => (v && v.valueOriginal ? v.valueOriginal : v.value)))
              }
              required={requiredFields.contains('ethnicity')}
              isValueCaseInsensitive
            />
            <Input
              id="tel"
              label={translate('phone_number')}
              value={this.state.attributes.tel}
              onValueChanged={value => this.updateAttribute('tel', value)}
              required={requiredFields.contains('tel')}
            />
            <Input
              id="email"
              type="email"
              label={translate('email')}
              value={this.state.attributes.email}
              onValueChanged={value => this.updateAttribute('email', value)}
              required={requiredFields.contains('email')}
            />
            <TextArea
              id="address"
              label={translate('address')}
              value={this.state.attributes.address || ''}
              onValueChanged={value => this.updateAttribute('address', value)}
              required={requiredFields.contains('address')}
            />
            <Input
              id="postal_code"
              label={this.props.config.getIn(['patient', 'labels', 'postal_code'], 'Postal Code')}
              value={this.state.attributes.postal_code}
              onValueChanged={value => this.updateAttribute('postal_code', value)}
              required={requiredFields.contains('postal_code')}
            />
            <Input
              id="occupation"
              label={translate('occupation')}
              value={this.state.attributes.occ}
              onValueChanged={value => this.updateAttribute('occ', value)}
            />
            <Input
              id="current_employer"
              label={translate('current_employer')}
              value={this.state.attributes.current_employer}
              onValueChanged={value => this.updateAttribute('current_employer', value)}
            />
            <TextArea
              id="notes"
              label={translate('notes')}
              value={this.state.attributes.notes || ''}
              onValueChanged={value => this.updateAttribute('notes', value)}
            />
            <TagInput
              id="tags"
              label={translate('tags')}
              value={this.state.attributes.tags
                ? this.state.attributes.tags.filter(t => t.length).map(value =>
                  ({ value, label: value }))
                : []
              }
              options={
                this.state.attributes.tags
                  ? this.props.config.getIn(['patient_tags', 'options'], List()).toJS()
                    .concat(this.state.attributes.tags)
                    .map(value => ({ value, label: value }))
                  : []
              }
              onChange={(value) => {
                const trimmedValues = value
                  .map(v => validateAndTrimString(v)).filter(v => v.value.length);
                return this.updateAttribute('tags', trimmedValues.map(e => e.value));
              }}
            />
            {this.props.config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) &&
            <div>
              <Input
                id="ward_number"
                type="text"
                label={translate('ward_number')}
                value={this.state.attributes.ward_number || ''}
                onValueChanged={value => this.updateAttribute('ward_number', value)}
              />
              <Input
                id="room_number"
                type="text"
                label={translate('room_number')}
                value={this.state.attributes.room_number || ''}
                onValueChanged={value => this.updateAttribute('room_number', value)}
              />
              <Input
                id="bed_number"
                type="text"
                label={translate('bed_number')}
                value={this.state.attributes.bed_number || ''}
                onValueChanged={value => this.updateAttribute('bed_number', value)}
              />
            </div>
            }
            { this.props.showAllergies &&
              <PermissionWrapper
                permissionsRequired={List([
                  createPermission('patient_allergies', 'read'),
                  createPermission('patient_allergies', 'create'),
                ])}
                user={this.props.user}
              >
                <AllergyList
                  className="o-card o-card--no-shadow o-card--no-margin"
                  hideLastEdited
                  onModelSaved={(model) => {
                    const newAllergyList = this.state.attributes.allergies
                      .filter(i => i.get('_id') !== model.get('_id'))
                      .push(model);
                    this.updateAttribute('allergies', newAllergyList);
                  }}
                  onDeleteModel={(model) => {
                    const newAllergyList = this.state.attributes.allergies
                      .filter(i => i.get('_id') !== model.get('_id'));
                    this.updateAttribute('allergies', newAllergyList);
                  }}
                  items={this.state.attributes.allergies}
                  user={this.props.user}
                  verifiedDrugs={this.props.verifiedDrugs}
                  drugs={this.props.drugs}
                  allergies={this.props.allergies}
                />
              </PermissionWrapper>
            }
          </FormColumn>
          <FormColumn
            isFromModal={isFromModal}
          >
            <Select
              id="payor"
              label={this.props.config.getIn(['patient', 'labels', 'coverage', 'payor'], 'Panel Name')}
              value={this.getCoverageField('payor')}
              options={
                this.props.coveragePayors
                  .filter((payor) => {
                    if (payor.isVisible()) {
                      return true;
                    }
                    if (this.state.attributes.coverage && this.state.attributes.coverage.length) {
                      const patientCurrentPayor = this.state.attributes.coverage[0].payor;
                      return payor.get('_id') === patientCurrentPayor;
                    }
                    return false;
                  })
                  .map(payor => ({ value: payor.get('_id'), label: payor.get('name'), disabled: !payor.isVisible() }))
                  .push({ value: 'create', label: translate('add_new_coverage_payor') })
                  .toArray()
              }
              creatable
              showNewOptionAtTop={false}
              onCreateOption={(option) => {
                this.handleCreatePanelModal(option, true);
              }}
              createOptionPromptFactory={label => translate('add_new_x_panel_label', { x: label })}
              onValueChanged={(selectedOption) => {
                if (!selectedOption || selectedOption.length === 0) {
                  this.setCoverageField('payor', '');
                } else if (selectedOption === 'create') {
                  this.handleCreatePanelModal('', true);
                } else {
                  this.setCoverageField('payor', selectedOption);
                  const coveragePayor = this.props.coveragePayors.find(payor => payor.get('_id') === selectedOption);
                  const policies = coveragePayor ? coveragePayor.get('policies', []) : [];
                  this.setCoverageField('policy', policies && policies[0] ? policies[0]._id : '');
                }
              }}
              filterOption={(option, query: string) => {
                // This is a custom filter to ensure search only applies to start of words.

                if (query && option.value === 'create') { // Used to remove the create option from search filter as that is handled by select creatable
                  return false;
                }
                const queryStrings = query.toLowerCase().split(' ');
                return queryStrings.every(queryString =>
                  knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1);
              }}
            />
            <Select
              id="policy"
              label={this.props.config.getIn(['patient', 'labels', 'coverage', 'policy'], 'Policy Name')}
              value={this.getCoverageField('policy')}
              options={this.getCoveragePolicyOptions(this.props)}
              creatable
              showNewOptionAtTop={false}
              onCreateOption={(option) => {
                this.handleCreatePolicy(option, true);
              }}
              createOptionPromptFactory={label => translate('add_new_x_policy_label', { x: label })}
              onValueChanged={(selectedOption) => {
                if ((!selectedOption || selectedOption.length === 0) &&
                  this.state.attributes.coverage &&
                  this.state.attributes.coverage[0] && this.state.attributes.coverage[0].payor) {
                  const coveragePayorID = this.state.attributes.coverage[0].payor;
                  const coveragePayor = this.props.coveragePayors.find(payor => payor.get('_id') === coveragePayorID);
                  const policies = coveragePayor ? coveragePayor.get('policies', []) : [];
                  this.setCoverageField('policy', policies && policies[0] ? policies[0]._id : '');
                } else if (selectedOption === 'create') {
                  this.handleCreatePolicy('', true);
                } else {
                  this.setCoverageField('policy', selectedOption);
                }
              }}
              disabled={this.getCoverageField('payor') === ''}
              filterOption={(option, query: string) => {
                // This is a custom filter to ensure search only applies to start of words.
                if (query && option.value === 'create') { // Used to remove the create option from search filter as that is handled by select creatable
                  return false;
                }
                const queryStrings = query.toLowerCase().split(' ');
                return queryStrings.every(queryString =>
                  knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1);
              }}
            />
            <Input
              id="policyHolder"
              label={this.props.config.getIn(['patient', 'labels', 'coverage', 'policyHolder'], 'Policy Holder')}
              value={this.getCoverageField('policyHolder')}
              onValueChanged={value => this.setCoverageField('policyHolder', value)}
              disabled={this.getCoverageField('payor') === ''}
            />
            <Input
              id="subscriber"
              label={this.props.config.getIn(['patient', 'labels', 'coverage', 'subscriber'], 'Subscriber')}
              value={this.getCoverageField('subscriber')}
              onValueChanged={value => this.setCoverageField('subscriber', value)}
              disabled={this.getCoverageField('payor') === ''}
            />
            <Select
              id="relationship"
              label={this.props.config.getIn(['patient', 'labels', 'coverage', 'relationship'], 'Panel Relation')}
              value={this.getCoverageField('relationship')}
              options={HL7_POLICY_HOLDER_RELATIONSHIP_CODES.map(value => ({ value, label: value }))}
              onValueChanged={value => this.setCoverageField('relationship', value)}
              disabled={this.getCoverageField('payor') === ''}
            />
            { (!isFromModal || showSaveButton) && <SaveButton
              dataPublic
              isSaving={this.state.isSaving}
              onClick={() => this.onSaveClicked()}
              fullWidth
            /> }
          </FormColumn>
        </FormWrapper>
        <StatelessModal
          id={`addNewPanelModal-${this.state.newPanelInput}`}
          title={translate('add_item')}
          setVisible={(isVisible: boolean) => this.setState({ newPanelModalVisible: isVisible })}
          visible={this.state.newPanelModalVisible}
          noButton
          onClose={() => {
            this.handleCreatePanelModal('', false);
            this.clearNewPanelPolicyInputId();
          }}
          dataPublicHeader
        >
          <CoveragePayorForm
            modelToEdit={this.state.modelToEdit}
            clearModelToEdit={() => this.setState({ modelToEdit: undefined })}
            config={this.props.config}
            saveModel={this.props.saveModel}
            onSave={
              (payor) => {
                this.setCoverageField('payor', payor.get('_id'));
                this.setCoverageField('policy', this.state.newPanelPolicyInputId ? this.state.newPanelPolicyInputId : '');
                this.handleCreatePanelModal('', false);
                this.clearNewPanelPolicyInputId();
              }
            }
            labelClassName=""
            autofocus="item_name"
            saveIsSticky
            noClearButton
          />
        </StatelessModal>
      </KeyPress>
    );
  }
}

export default PatientProfileForm;
