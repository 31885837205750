import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { CHANGELOGS, getChangelog } from '../../utils/changelog';

export type Props = {
  versionNumber: string, // Version number with dashes instead of dots. E.g. 1.10.3 becomes 1-10-3
};

/**
 * A component for displaying a changelog/release notes.
 * @return {jsx} - HTML markup for the component
 */
export default function changeLog({ versionNumber }: Props) {
  const [changelogData, setChangelogData] = useState<string | void>('');
  const version = versionNumber || CHANGELOGS[0];
  useEffect(() => {
    getChangelog(version).then(res => setChangelogData(res)).catch(null);
  }, [versionNumber]);

  return (
    <div className="o-content__main o-scrollable-container">
      <section className="o-card u-margin-bottom--4ws">
        <h1 data-public className="o-card__title">{`Release Notes: ${version.split('-').join('.')}`}</h1>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 200px' }}>
          <ReactMarkdown
            source={changelogData ? changelogData as string : 'Whoops! Failed to load release notes.'}
            className="c-markdown u-margin--standard"
            transformImageUri={uri => `static/changelogs/${uri}`}
          />
          <ul style={{ margin: '1em' }}>
            {
              CHANGELOGS.map((i: string) =>
                <li key={i}><Link to={`/release-notes/${i}`}>{`${i.split('-').join('.')}`}</Link></li>)
            }
          </ul>
        </div>
      </section>
    </div>
  );
}
