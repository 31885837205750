import * as React from 'react';

import CasenoteEditorCanvas from './casenoteEditorCanvas';
import CasenoteEditorMenu from './casenoteEditorMenu';
import CasenoteThumbnail from './casenoteThumbnail';
import CaseNoteFileModel from './../../models/caseNoteFileModel';
import CasenoteCategorySelectorContainer from './../../containers/casenoteCategorySelectorContainer';
import RecentlyViewedCasenotesContainer from './../../containers/recentlyViewedCasenotesContainer';
import Header from './../header/header';
import Minimap from './../minimap';
import { createSavingNotification, createSuccessNotification, removeNotification, createStaticNotification } from './../../utils/notifications';
import translate from './../../utils/i18n';
import { dataURItoBlob, saveBlob, getConfirmation } from './../../utils/utils';
import { logMessage, debugPrint } from './../../utils/logging';
import LoadingIndicator from './../loadingIndicator';
import { getReferralQueryString } from './../../utils/router';
import { getHighestCasenoteOrder } from './../../utils/db';
import { CASENOTE_ORDER_INCREMENT } from './../../constants';
import SavePrompt from './../prompts/savePrompt';
import UnsavedDataPrompt from './../prompts/unsavedDataPrompt';

import type { Config, SaveModel, CanvasPenSettings, ReactRouterLocation } from './../../types';

type AssetObject = { id: string, datatype: string };

type Props = {
  addRecentlyViewedCasenote: (patientID: string, casenoteID: string) => void,
  config: Config,
  patientID: string,
  saveModel: SaveModel,
  caseNoteFile: CaseNoteFileModel,
  isReferralMode: boolean,
  categoryID?: string,
  assetIndex: number,
  onNewNoteClicked: () => void,
  isOnline: boolean,
  history?: {
    location: {
      search: string,
    }
  }
};

type State = {
  canRedo: boolean,
  canUndo: boolean,
  changesMade: boolean,
  isSaving: boolean,
  penSettings: CanvasPenSettings,
};

/**
 * A casenote editor component for creating a new casenote or editing an existing one.
 * @class CasenoteEditor
 * @extends {React.Component}
 */
class CasenoteEditor extends React.Component<Props, State> {
  canvas: CasenoteEditorCanvas | void;

  /**
   * Creates an instance of CasenoteEditor.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      canRedo: false,
      canUndo: false,
      changesMade: false,
      isSaving: false,
      penSettings: {
        color: '#000000',
        size: props.config.getIn(['casenote_editor', 'pen_settings', 'size'], 1).toString(),
      },
    };
    if (props.caseNoteFile) {
      props.addRecentlyViewedCasenote(props.patientID, props.caseNoteFile.get('_id'));
    }
  }

  /**
   * Saves the currently viewed asset.
   * @returns {Promise} A Promise the will pass along the assetObject returned from the API.
   */
  saveAsset() {
    if (!this.canvas) {
      throw new Error('Canvas doesn\'t exist so we cant get the asset.');
    }
    // const canvasImage = this.canvas.getImage();
    // TODO: check canvas is okay.
    const imageDataURL = this.canvas.getImage();
    // TODO: check dataurl is okay.
    const imageBlob = dataURItoBlob(imageDataURL);
    // TODO: check blob is okay.
    // TODO: Offline handling.
    // if (!this.props.isOnline) {
    //   return saveAssetOffline(this.props.caseNoteFile.get('_id'), 0, imageBlob, imageBlob.type,
    //   this.props.user.get('id'))
    //   .then((assetID) => {
    //     this.props.onOfflineAssetsCountChanged();
    //     return { id: assetID, type: imageBlob.type };
    //   });
    // }
    return saveBlob(imageBlob);
  }

  /**
   * Receives an assetObject ({ id, dataType }) and places it in the CaseNoteFile in props at
   * the index specified at state.assetIndex. Throws an error if the index does not match up.
   * Saves the model to the DB and returns the promise.
   * @param {Object} assetObject an Asset object with an id and dataType.
   * @returns {Promise} a promise for the completed save of the caseNoteFileModel.
   */
  saveCaseNoteFile(assetObject: AssetObject) {
    const assets = this.props.caseNoteFile.filterNullFromAssets();
    if (this.props.assetIndex > assets.length - 1) {
      throw new Error('Asset index doesnt match number of assets in Casenote.');
    }
    assets[this.props.assetIndex] = assetObject;
    return getHighestCasenoteOrder(this.props.patientID)
      .then(order => this.props.saveModel(this.props.caseNoteFile.set({
        order: order + CASENOTE_ORDER_INCREMENT,
        assets: assets.slice(0),
      }))
        .then((model) => {
          if (location.hash.indexOf('/add') > -1) {
            location.hash = `/patient/${this.props.caseNoteFile.get('patient_id')}/casenotes/${model.get('_id')}/edit${getReferralQueryString()}`;
          }
          return model;
        }))
      .catch(() => {});
  }

  /**
   * Saves the current changes to the canvas. Throws an error if the state is already saving,
   * otherwise it begins the save process for the curent casenote.
   * @returns {Promise<boolean>} A promise with a value of true if successful and false if failed.
   */
  saveCanvas = (): Promise<boolean> => {
    if (!this.state.isSaving) {
      this.setState({ isSaving: true });
      const savingNotification = createSavingNotification();
      return this.saveAsset()
        .then(assetObject => this.saveCaseNoteFile(assetObject))
        .then((casenote) => {
          removeNotification(savingNotification);
          if (casenote.attributes.edited_by.length > 1) {
            createSuccessNotification(translate('casenote_update_success'));
          } else {
            createSuccessNotification(translate('casenote_creation_success'));
          }
          this.setState({ changesMade: false, isSaving: false });
          return true;
        })
        .catch((error) => {
          debugPrint(error, 'error');
          logMessage('Casenote save failed', 'error'); // error here will be error text so leaving it here
          removeNotification(savingNotification);
          this.setState({ isSaving: false });
          createStaticNotification(
            translate('casenote_save_failed'),
            translate('casenote_save_failed_desc'), true,
          );
          return false;
        });
    }
    return Promise.resolve(false);
  }

  /**
   * Checks for unsaved changes and asks the user if they want to save first. Returns a promise
   * with a boolean indicating success of the save. If the user doesn't want to save the return is
   * also true to indicate no issues.
   * @param {boolean} newNote Is a new note?
   * @param {boolean} cancelAbortsRouter If true then a cancel action at the confirm dialogue
   * will resolve to false, indicating that the route action should be aborted.
   * @returns {Promise<boolean>}
   */
  confirmBeforeRoute(newNote?: boolean, cancelAbortsRouter: boolean = false): Promise<boolean> {
    if (!this.props.isOnline) {
      Promise.resolve(false);
    }
    if ((newNote || this.state.changesMade)) {
      return getConfirmation(translate('confirm_save_casenote_before_route'))
        .then(
          () => this.saveCanvas(),
          () => false,
        );
    }
    return Promise.resolve(!cancelAbortsRouter);
  }

  /**
   * Handler for when the new note button is clicked. If Changes made it asks the user for
   * confirmation and then saves the current casenote.
   * @returns {void}
   */
  onNewNoteClicked() {
    if (!this.state.isSaving) {
      this.confirmBeforeRoute()
        .then((wasSuccessful) => {
          if (wasSuccessful) {
            const categoryID = this.props.categoryID ?
              this.props.categoryID : this.props.config.getIn(['categories', 'notes_category'], '0_KLINIFY_NOTES');
            this.setState({ changesMade: false, canUndo: false }, () => {
              this.props.onNewNoteClicked();
              location.hash = `/patient/${this.props.patientID}/categories/${categoryID}/add`;
            });
          }
        });
    }
  }

  /**
   * Add the casenote to recently viewed if it meets the requirements.
   * @param {Props} nextProps Next props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.caseNoteFile && (!this.props.caseNoteFile || this.props.caseNoteFile.get('_id') !== nextProps.caseNoteFile.get('_id'))) {
      nextProps.addRecentlyViewedCasenote(nextProps.patientID, nextProps.caseNoteFile.get('_id'));
    }
  }

  getMinimapThumbnail = () =>
    <CasenoteThumbnail
      config={this.props.config}
      casenote={this.props.caseNoteFile}
      disableLink
      assetIndex={this.props.assetIndex}
      assetID={this.props.caseNoteFile.getAssetID(this.props.assetIndex)}
    />;

  /**
   * Logic check to see if the pre-route prompt should display. Bases this off if there have been
   * changes made and whether or not the user is in referral mode.
   * @param {ReactRouterLocation} nextLocation The next location if the prompt passes.
   * @param {CaseNoteFileModel} casenote The current casenote.
   * @returns {boolean} True if the prompt should be displayed.
   */
  shouldPromptDisplay(nextLocation: ReactRouterLocation, casenote: CaseNoteFileModel): boolean {
    if (this.state.changesMade) {
      if (
        this.props.isReferralMode &&
        nextLocation.search &&
        nextLocation.search.indexOf('referralId') !== -1
      ) {
        return false; // Don't check when navigating from referral section to referral section.
      } else if (
        !this.props.isReferralMode &&
        nextLocation.search &&
        nextLocation.search.indexOf('referralId') !== -1 &&
        nextLocation.search.split('referralId=')[1].startsWith(casenote.get('_id'))
      ) {
        return false; // Don't check when navigating to referral as the check is done by the referral button.
      } else if (
        this.props.isReferralMode &&
        nextLocation.search &&
        nextLocation.search.indexOf('referralId') === -1 &&
        nextLocation.pathname.indexOf(`${casenote.get('_id')}/edit`) !== -1
      ) {
        return false; // Don't check when returning to editor from referral mode as check is done by the close referral button.
      }
      return true;
    }
    return false;
  }

  /**
   * Renders the correct prompt for when the user routes away from the page. This changes based on
   * whether or not the user is online.
   * @param {CaseNoteFileModel} casenote The current casenote.
   * @returns {React.Component}
   */
  renderPreRoutePrompt(casenote: CaseNoteFileModel) {
    if (this.props.isOnline) {
      return (
        <SavePrompt
          onSaveClicked={() => this.saveCanvas()}
          when={(currentLocation, nextLocation) =>
            this.shouldPromptDisplay(nextLocation, casenote)
          }
        />
      );
    }
    // If the user is offline there is no way to save the casenote, so warn them that data will be
    // lost if they continue.
    return (
      <UnsavedDataPrompt
        when={(currentLocation, nextLocation) =>
          this.shouldPromptDisplay(nextLocation, casenote)
        }
      />
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let fromCategory;
    if (this.props.history && this.props.history.location) {
      const searchParams = new URLSearchParams(this.props.history.location.search);
      fromCategory = searchParams.get('fromCategory');
    }
    if (!this.props.caseNoteFile) {
      return <LoadingIndicator />;
    }
    const casenote = this.props.caseNoteFile;
    const assetID = casenote.getAssetID(this.props.assetIndex);
    const prefix = `/patient/${casenote.get('patient_id')}`;
    if (!assetID) {
      throw new Error('Casenote has no assets');
    }
    return (
      <section className="c-casenote-editor">
        {this.renderPreRoutePrompt(casenote)}
        <Header label={translate('notepad')} backLink={`${prefix}/categories/${fromCategory || casenote.get('category')}/${casenote.get('_id')}`} isOffline={!this.props.isOnline}>
          {
            this.props.isReferralMode ?
              <div
                onClick={() => {
                  if (!this.state.changesMade) {
                    location.hash = `${prefix}/casenotes/${casenote.get('_id')}/edit/${this.props.assetIndex}`;
                  } else {
                    this.confirmBeforeRoute()
                      .then((wasSuccessful) => {
                        if (wasSuccessful) {
                          location.hash = `${prefix}/casenotes/${casenote.get('_id')}/edit/${this.props.assetIndex}`;
                        }
                      });
                  }
                }}
                className="c-top-bar__button"
                style={{ marginLeft: 'auto' }}
              >
                <i className="fa fa-arrows-alt" />
              </div> :
              <div
                onClick={() => {
                  if (!this.state.changesMade && casenote.hasBeenSaved()) {
                    location.hash = `${prefix}/categories/${casenote.get('category')}/${casenote.get('_id')}?referralId=${casenote.get('_id')}`;
                  } else {
                    this.confirmBeforeRoute(!casenote.hasBeenSaved(), true)
                      .then((wasSuccessful) => {
                        if (wasSuccessful) {
                          location.hash = `${prefix}/categories/${casenote.get('category')}/${casenote.get('_id')}?referralId=${casenote.get('_id')}`;
                        }
                      });
                  }
                }}
                className="c-top-bar__button"
                style={{ marginLeft: 'auto' }}
              >
                R
              </div>
          }
        </Header>
        <CasenoteEditorMenu
          penSettings={this.state.penSettings}
          onPenSettingsChanged={
            (penSettings) => { this.setState(Object.assign(this.state, { penSettings })); }
          }
          onRedoClicked={() => this.canvas && this.canvas.redo()}
          onSaveClicked={() => this.saveCanvas()}
          onUndoClicked={() => this.canvas && this.canvas.undo()}
          onNewNoteClicked={() => this.onNewNoteClicked()}
          canRedo={this.state.canRedo}
          canSave={this.props.isOnline && this.state.changesMade && !this.state.isSaving}
          canUndo={this.state.canUndo}
          canAddNewNote={this.props.isOnline}
        />
        <CasenoteCategorySelectorContainer casenote={casenote} />
        <Minimap
          className={this.props.isReferralMode ? 'c-casenote-editor__canvas-container--referral-mode' : 'c-casenote-editor__canvas-container'}
          selector=".js-casenote-editor__canvas"
          childComponent={this.getMinimapThumbnail}
        >
          <CasenoteEditorCanvas
            ref={(canvas) => { if (canvas) { this.canvas = canvas; } }}
            assetID={assetID}
            penSettings={this.state.penSettings}
            onCanvasChanged={
              (canUndo, canRedo) => this.setState({ changesMade: canUndo, canUndo, canRedo })
            }
          />
        </Minimap>
        <RecentlyViewedCasenotesContainer
          patientID={this.props.patientID}
          casenoteFileID={casenote.get('_id')}
          assetIndex={this.props.assetIndex}
        />
      </section>
    );
  }
}

export default CasenoteEditor;
