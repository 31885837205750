import React from 'react';
import { List } from 'immutable';

import ClinicSearch from './clinicSearch';
import StatsToday from './statsToday';
import StatsInterval from './statsInterval';
import ContentTransition from './../contentTransition';

import type { IntervalString } from './../../types';
import ClinicModel from '../../models/clinicModel';
import DashboardStatModel from '../../models/dashboardStatModel';
import APIError from '../../utils/apiError';
import translate from '../../utils/i18n';

type Props = {
  location: Array<string>,
  clinicList: List<ClinicModel>,
  stats: List<DashboardStatModel>,
  setDoctorsInState: (docs: List<ClinicModel>) => void,
  setStats: (
    stats: DashboardStatModel | List<DashboardStatModel>,
    db: string, interval: string
  ) => void,
  interval: IntervalString,
  clinicId: string,
  isFetching: boolean,
  currentDataViewsError?: APIError,
};


/**
  * A full page component with Sidebar + Header,  and a input to filter them.
  *
  * @namespace Dashboard
  */
class Dashboard extends React.PureComponent<Props> {
  /**
    * Renders the component.
    *
    * @return {string} - HTML markup for the component
    */
  render() {
    const { clinicList, interval, isFetching, stats, clinicId } = this.props;

    const StatsComponent: (typeof StatsToday | typeof StatsInterval) =
        (interval === 'today') ? StatsToday : StatsInterval;

    return (
      <ContentTransition className="o-scrollable-container">
        { clinicList && <ClinicSearch
          clinicModels={clinicList}
          interval={interval}
          selectedClinicId={clinicId}
        /> }
        { this.props.currentDataViewsError ?
          <p className="u-padding--1ws">{translate('error_contact_support')}</p>
          :
          <StatsComponent
            stats={stats}
            isFetching={isFetching}
            interval={interval}
          />
        }
      </ContentTransition>
    );
  }
}

export default Dashboard;
