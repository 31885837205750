import { connect } from 'react-redux';
import type { List } from 'immutable';

import Casenote from './../components/casenotes/casenote';
import { sortByNumber } from './../utils/comparators';
import { getReferralQueryString } from '../utils/router';

import type CaseNoteFileModel from './../models/caseNoteFileModel';
import type { State } from './../types';

type Props = {
  patientID: string,
  categoryID?: string,
  casenoteID?: string,
};

/**
 * Returns a link to the previous casenote in the current category or undefined if their is none.
 * @param {string} prefix A prefix for the link
 * @param {number} index The index of the current casenote
 * @param {List<CaseNoteFileModel>} casenotes A list of all casenotes in the category
 * @param {string} categoryID The category ID
 * @returns {string}
 */
function getPreviousLink(
  prefix: string,
  index: number,
  casenotes: List<CaseNoteFileModel>,
  categoryID?: string,
) {
  if (index > 0) {
    const casenote = casenotes.get(index - 1);
    if (casenote) {
      return `${prefix}/categories/${categoryID || 'ALL'}/${casenote.get('_id')}${getReferralQueryString()}`;
    }
  }
  return undefined;
}

/**
 * Returns a link to the next casenote in the current category or undefined if their is none.
 * @param {string} prefix A prefix for the link
 * @param {number} index The index of the current casenote
 * @param {List<CaseNoteFileModel>} casenotes A list of all casenotes in the category
 * @param {string} categoryID The category ID
 * @returns {string}
 */
function getNextLink(
  prefix: string,
  index: number,
  casenotes: List<CaseNoteFileModel>,
  categoryID?: string,
) {
  if (index >= 0 && index < casenotes.size - 1) {
    const casenote = casenotes.get(index + 1);
    if (casenote) {
      return `${prefix}/categories/${categoryID || 'ALL'}/${casenote.get('_id')}${getReferralQueryString()}`;
    }
  }
  return undefined;
}

/**
   * @param {Object} state Current app state.
   * @param {Props} ownProps Container props.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  state: State,
  { categoryID, casenoteID, patientID }: Props,
) => {
  const prefix = `/patient/${patientID}`;
  let casenotes;
  const { caseNoteFiles } = state;
  if (categoryID && categoryID === 'ALL') {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && c.isVisible());
  } else if (categoryID && categoryID === 'BIN') {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && !c.isVisible());
  } else if (categoryID && categoryID === 'STARRED') {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && c.isStarred() && c.isVisible());
  } else {
    casenotes = caseNoteFiles.filter(c => c.hasValidAssets() && c.get('patient_id') === patientID && c.get('category') === categoryID && c.isVisible());
  }
  casenotes = casenotes
    .sort((a, b) => sortByNumber(a.get('order'), b.get('order')))
    .reverse();
  const casenoteIndex = casenoteID ? casenotes.findIndex(c => c.get('_id') === casenoteID) : 0; // If no casenoteID given just pick the first casenote in the category
  if (casenoteIndex === -1) {
    // Given casenote ID doesn't exist, so let's reroute to category level
    location.hash = `${prefix}/categories/${categoryID || 'ALL'}${getReferralQueryString()}`;
  }
  return {
    casenote: casenotes.get(casenoteIndex),
    casenoteIndex: casenoteIndex + 1, // + 1 For human readability
    casenoteTotal: casenotes.size,
    nextLink: getNextLink(prefix, casenoteIndex, casenotes, categoryID),
    previousLink: getPreviousLink(prefix, casenoteIndex, casenotes, categoryID),
    categoryID,
    config: state.config,
    user: state.user,
  };
};

const CasenoteContainer = connect(mapStateToProps)(Casenote);

export default CasenoteContainer;
