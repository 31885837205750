import React from 'react';
import type { List } from 'immutable';

import CasenoteThumbnail from './casenoteThumbnail';
import translate from './../../utils/i18n';
import Button from './../buttons/button';

import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import type { Config } from './../../types';
import Header from './../header/header';

type Props = {
  casenotes: List<CaseNoteFileModel>,
  config: Config,
  casenoteFileID: string,
  assetIndex: number,
};

type State = {
  isOpen: boolean,
};


/**
 * A component displaying a drawer that can be toggled open/closed and contains the most recently
 * accessed casenotes for the current patient.
 * @class RecentlyViewedCasenotes
 * @extends {React.Component<Props, State>}
 */
class RecentlyViewedCasenotes extends React.Component<Props, State> {
  /**
   * Creates an instance of RecentlyViewedCasenotes.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const className = this.state.isOpen ?
      'c-active-casenotes-selector' :
      'c-active-casenotes-selector c-active-casenotes-selector--minimised';
    return (
      <div className={className}>
        <Header className="c-active-casenotes-selector__header" dataPublic>
          <Button
            className="c-active-casenotes-selector__header__button"
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            dataPublic
          >
            {
              this.state.isOpen ?
                <i className="fa fa-chevron-down" /> :
                <i className="fa fa-chevron-up" />
            }
          </Button>
          <div className="c-active-casenotes-selector__header__label">
            {translate('note_x_of_y', { x: 1, y: this.props.casenotes.size })}
          </div>
        </Header>
        <ul className="c-active-casenotes-selector__list">
          {
            this.props.casenotes.map(c =>
              <li key={c.get('_id')}>
                {
                  c.filterNullFromAssets()
                    .map((asset, index) =>
                      <CasenoteThumbnail
                        config={this.props.config}
                        casenote={c}
                        imgStyle={{ height: '100%', background: 'white' }}
                        style={{}}
                        isEditLink
                        assetID={asset.id}
                        assetIndex={index}
                        key={asset.id}
                        isSelected={c.get('_id') === this.props.casenoteFileID && index === this.props.assetIndex}
                      />)
                }
              </li>)
          }
        </ul>
      </div>
    );
  }
}

export default RecentlyViewedCasenotes;
