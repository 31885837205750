// Component for a tag input field (i.e. multi-select with custom user entry allowed).
import React from 'react';
import Creatable from './select';

import Label from './label';

import type { MapValue } from './../../types';

export type Item = {
  label: string,
  value: string,
  // tags?: ?string,
  [key: string]: MapValue, // Optional flags on Items
};

type Props = {
  id: string,
  label: string,
  labelClassName?: string,
  description?: string,
  value: Array<Item>,
  options: Array<Item>,
  hideLabel?: boolean,
  style?: { [key: string]: string | number },
  onChange: (values: Array<Item>) => void,
  disabled?: boolean,
  tagInputClassName?: string,
  required?: boolean,
};

/**
   * TagInput
   *
   * @namespace TagInput
   * @returns {React.Component} - A TagInput Component.
   */
const TagInput = ({
  id, value, options, onChange, label, style, hideLabel, description, labelClassName = '', disabled = false,
  required = false, tagInputClassName = '',
}: Props) => {
  const inputClassName = `${required && (!value || value.length === 0) ? 'js-border-invalid' : ''} ${tagInputClassName || ''}`;
  return (
    <div
      className="o-form__item"
      style={style}
    >
      {
        !hideLabel &&
        <Label
          className={labelClassName}
          id={id}
          label={label}
          invalid={required && (!value || value.length === 0)}
        />
      }
      {
        description && <p className="o-sub-label">{description}</p>
      }
      <Creatable
        id={id}
        value={value}
        options={options}
        onValueChanged={onChange}
        isMulti
        disabled={disabled}
        className={inputClassName}
        hideLabel
        creatable
      />
    </div>
  );
};

export default TagInput;
