import React, { Fragment } from 'react';
import moment from 'moment';
import { List } from 'immutable';

import Input from './../inputs/input';
import DateInput from './../inputs/dateInput';
import MultiSelect from './../inputs/multiSelect';
import Radio from './../inputs/radio';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import GridInput from './../inputs/gridInput';
import PractitionerModel from './../../models/practitionerModel';
import { logPractitionerCreated, logPractitionerEdited } from './../../utils/logging';
import ModalFooter from './../modals/modalFooter';
import FormError from './../formError';
import { getDateFormat, isDateValid } from './../../utils/time';
import { validateAndTrimString } from './../../utils/utils';

import type { Config, SaveModel, SelectOpts } from './../../types';
import type { Qualification, PractitionerRole, RegistrationNumber } from './../../models/practitionerModel';

type Props = {
  config: Config,
  modelToEdit?: PractitionerModel,
  onSave: (model: PractitionerModel) => Promise<boolean | void>,
  disabled: boolean,
  isFromDocValidationModal?: boolean,
  isSaving?: boolean,
};

/* eslint-disable camelcase */
type FormAttributes = {
  name: string,
  internal_clinic_id?: string,
  ic?: string,
  gender?: string,
  dob?: moment,
  ethnicity?: Array<string>,
  practitioner_role?: PractitionerRole,
  tel?: Array<string>,
  email?: Array<string>,
  registration_number?: RegistrationNumber,
  qualifications?: Array<Qualification>,
}
/* eslint-enable camelcase */

type State = {
  isSaving: boolean,
  errorMessage?: string,
  attributes: FormAttributes
};

/**
 * Gets the default state of the component.
 * @param {PractitionerModel?} model A practitioner model
 * @returns {State}
 */
function getDefaultState(model?: PractitionerModel) {
  if (model) {
    return Object.assign({}, {
      isSaving: false,
      attributes: {
        name: model.get('name', ''),
        internal_clinic_id: model.get('internal_clinic_id'),
        ic: model.get('ic'),
        gender: model.get('gender'),
        dob: model.get('dob'),
        ethnicity: model.get('ethnicity'),
        qualifications: model.get('qualifications', []),
        practitioner_role: model.get('practitioner_role'),
        tel: model.get('tel'),
        email: model.get('email'),
        registration_number: model.get('registration_number'),
      },
    });
  }
  return Object.assign({}, {
    isSaving: false,
    attributes: { name: '', qualifications: [] },
  });
}

/**
 * A form for creating or editing a Practitioner Model.
 * @class DoctorForm
 * @extends {React.Component<Props, State>}
 */
class DoctorForm extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  state: State;

  /**
   * Creates an instance of SupplierForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = getDefaultState(props.modelToEdit);
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    const dobValid = isDateValid(this.state.attributes.dob);
    if (!dobValid) {
      this.setState({ errorMessage: translate('fill_date_in_correct_format') });
      return false;
    }
    if (validateAndTrimString(this.state.attributes.name).length) {
      return true;
    }
    this.setState({ errorMessage: translate('name_cannot_be_empty_please_fill_out_the_field') });
    return false;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {undefined}
   */
  onSaveClicked() {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const model = this.props.modelToEdit ?
        this.props.modelToEdit.set(validateAndTrimString(this.state.attributes)) :
        new PractitionerModel(validateAndTrimString(this.state.attributes));
      this.props.onSave(model).then(() => {
        this.setState({ isSaving: false });
        if (this.props.modelToEdit) {
          logPractitionerEdited(model.getNonEmptyFields());
        } else {
          this.setState(getDefaultState());
          logPractitionerCreated(model.getNonEmptyFields());
        }
      });
    }
  }

  /**
   * Triggers the saving function when the isSaving prop changes
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.isFromDocValidationModal
      && this.props.isSaving !== prevProps.isSaving
      && this.props.isSaving) {
      this.onSaveClicked();
    }
    if (this.props.modelToEdit !== prevProps.modelToEdit) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(getDefaultState(this.props.modelToEdit));
    }
  }

  /**
   * Called when closing the component
   * @returns {void}
   */
  componentWillUnmount() {
    this.setState(getDefaultState(this.props.modelToEdit));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const idPrefix = this.props.modelToEdit ?
      `doctor_${this.props.modelToEdit.get('_id')}` : 'doctor';
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <FormError isSticky>{this.state.errorMessage}</FormError>
          }
          <Input
            id={`${idPrefix}_name`}
            label={translate('name')}
            value={this.state.attributes.name}
            onValueChanged={
              (name: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { name }),
              })
            }
            required
            disabled={this.props.disabled}
          />
          <Input
            id={`${idPrefix}_registration_number`}
            label={translate('mmc_number')}
            value={this.state.attributes.registration_number ?
              this.state.attributes.registration_number.mmc : undefined}
            onValueChanged={
              (mmcNumber: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, {
                  registration_number: { mmc: mmcNumber },
                }),
              })
            }
            disabled={this.props.disabled}
          />
          <Input
            id={`${idPrefix}_ic`}
            label={translate('ic')}
            value={this.state.attributes.ic}
            onValueChanged={
              (ic: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { ic }),
              })
            }
            disabled={this.props.disabled}
          />
          <Input
            id={`${idPrefix}_internal_clinic_id`}
            label={translate('clinic_id')}
            value={this.state.attributes.internal_clinic_id}
            onValueChanged={
              (internalClinicID: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, {
                  internal_clinic_id: internalClinicID,
                }),
              })
            }
          />
          <DateInput
            id={`${idPrefix}_dob`}
            label={translate('dob')}
            value={
              this.state.attributes.dob && moment(this.state.attributes.dob).isValid() ?
                moment(this.state.attributes.dob) : this.state.attributes.dob
            }
            onChange={
              (value) => {
                const date = value || undefined;
                this.setState({
                  attributes: Object.assign({}, this.state.attributes, {
                    dob: value && value.format ? value.format(getDateFormat()) : date,
                  }),
                });
              }
            }
            disabled={this.props.disabled}
          />
          <Radio
            id={`${idPrefix}_gender`}
            label={translate('gender')}
            optionClassName="u-font-color-white"
            options={
              this.props.config.getIn(['patient', 'options', 'gender'], List(['male', 'female']))
                .toArray()
                .map(value => ({ label: translate(value), value }))
            }
            value={this.state.attributes.gender}
            onValueChanged={
              (gender: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { gender }),
              })
            }
            disabled={this.props.disabled}
          />
          <Input
            id={`${idPrefix}_tel`}
            label={translate('tel')}
            value={(this.state.attributes.tel || []).join(',')}
            onValueChanged={
              (tel: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { tel: tel.split(',') }),
              })
            }
            disabled={this.props.disabled}
          />
          <Input
            id={`${idPrefix}_email`}
            label={translate('email')}
            value={(this.state.attributes.email || []).join(',')}
            onValueChanged={
              (email: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { email: email.split(',') }),
              })
            }
            disabled={this.props.disabled}
          />
          <MultiSelect
            id={`${idPrefix}_ethnicity`}
            label={translate('ethnicity')}
            value={(this.state.attributes.ethnicity || []).map(value => ({ value, label: value }))}
            closeOnSelect
            options={
              this.props.config.getIn(['patient', 'options', 'ethnicity'], List())
                .map(value => ({ value, label: value }))
                .toArray()
            }
            onChange={
              (ethnicity: SelectOpts) => this.setState({
                attributes: Object.assign({}, this.state.attributes, {
                  ethnicity: ethnicity.map(e => e.value),
                }),
              })
            }
            disabled={this.props.disabled}
          />
          <GridInput
            id={`${idPrefix}_qualifications`}
            label={translate('qualifications')}
            value={this.state.attributes.qualifications}
            columns={[
              { value: 'issuer', label: translate('issuer') },
              { value: 'type', label: translate('type') },
              { value: 'year_received', label: translate('year_received') },
            ]}
            onValueChanged={
              qualifications => this.setState({
                attributes: Object.assign({}, this.state.attributes, { qualifications }),
              })
            }
            disabled={this.props.disabled}
          />
          <Input
            id={`${idPrefix}_speciality`}
            label={translate('speciality')}
            value={this.state.attributes.practitioner_role ?
              (this.state.attributes.practitioner_role.speciality || []).join(',') : undefined}
            onValueChanged={
              (speciality: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, {
                  practitioner_role: { speciality: speciality.split(',') },
                }),
              })
            }
            disabled={this.props.disabled}
          />
        </div>
        { !this.props.isFromDocValidationModal && <ModalFooter>
          {
            !this.props.disabled &&
            <SaveButton
              dataPublic
              className="o-button--small u-margin-right--half-ws"
              isSaving={this.state.isSaving}
              label={this.props.modelToEdit ? translate('save') : translate('add_new_doctor')}
              onClick={() => this.onSaveClicked()}
            />
          }
        </ModalFooter>}
      </Fragment>
    );
  }
}

export default DoctorForm;
