import * as React from 'react';

type Props = {
  children: React.Node,
};

/**
 * A wrapper for multiple SidebarTextLinks
 * @class SidebarTextLinkWrapper
 * @extends {React.Component<Props, State>}
 */
class SidebarTextLinkWrapper extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="c-sidebar__item__subitems">
        {this.props.children}
      </div>
    );
  }
}

export default SidebarTextLinkWrapper;
