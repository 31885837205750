import React from 'react';
import { List } from 'immutable';
import { Link, useHistory } from 'react-router-dom';

import translate from './../../utils/i18n';
import ClinicModel from '../../models/clinicModel';
import { IntervalString, SelectOption } from '../../types';
import Select from '../inputs/select';


type Props = {
  interval: IntervalString,
  clinicModels: List<ClinicModel>,
  selectedClinicId?: string,
};
/**
 *
 * @param {IntervalString} interval interval
 * @param {string} clinic clinic
 * @returns {string}
 */
export const getClinicLink = (interval: IntervalString, clinic: string) =>
  (clinic === 'all' ? `/analytics/${interval}` : `/analytics/${interval}/${clinic}`);

/**
   * A ClinicSearch Component with typeahead
   * @namespace ClinicSearch
   * @param {object} props The props for this component.
   * @returns {React.Component} A ClinicSearch bar
   */
const ClinicSearch = ({ interval, clinicModels, selectedClinicId }: Props) => {
  const history = useHistory();
  return (
    <div className="u-margin--standard">
      <Select
        style={{ gridArea: 'name' }}
        options={clinicModels.map(c => ({
          value: c.get('db_name'),
          label: c.get('name'),
        })).push({
          value: 'all',
          label: translate('all'),
        }).toArray()
      }
        autoFocus
        id="clinic"
        label={translate('search_clinic')}
        showNewOptionAtTop={false}
        value={selectedClinicId || 'all'}
        onValueChanged={(selectedOption) => {
          history.push(getClinicLink(interval, selectedOption));
        }}
        optionRenderer={(item: SelectOption) =>
          <Link to={getClinicLink(interval, item.value)}>{item.label}</Link>
      }
      />
    </div>
  );
};

export default ClinicSearch;
