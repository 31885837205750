import React from 'react';
import { List } from 'immutable';

import translate from '../../utils/i18n';
import LabTestsForm from './labTestsForm';
import StatelessModal from './../modals/statelessModal';
import { createPermission, hasPermission } from './../../utils/permissions';
import { getConfirmation } from './../../utils/utils';
import { createSuccessNotification, createErrorNotification } from './../../utils/notifications';
import ProcedureRequestModel from './../../models/procedureRequestModel';
import ProcedureStatusModel from './../../models/procedureStatusModel';
import ProcedureResultModel from './../../models/procedureResultModel';
import CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import { cancelLabOrder } from './../../utils/labs';
import Button from './../buttons/button';

import type { SaveModel, User, SaveModels, Config } from './../../types';
import type SpecimenModel from '../../models/specimenModel';
import type ProcedureTypeModel from '../../models/procedureTypeModel';
import type ProviderModel from '../../models/providerModel';
import type EncounterModel from '../../models/encounterModel';

type Props = {
  type: string,
  saveModel: SaveModel,
  user: User,
  config: Config,
  saveModels: SaveModels,
  encounterID: string,
  encounter?: EncounterModel
  patientID: string,
  procedureRequest: ProcedureRequestModel | null | undefined,
  procedureType: ProcedureTypeModel,
  procedureStatuses: List<ProcedureStatusModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  specimens: List<SpecimenModel>
};

type State = {
  isDisabled: boolean,
  isEditModalVisible: boolean,
};

/**
 * A component that display button for lab test table.
 * @namespace LabTestActionButton
 */
class LabTestActionButton extends React.Component<Props, State> {
  /**
   * Creates an instance of LabTestActionButton.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isDisabled: false,
      isEditModalVisible: false,
    };
  }

  /**
   * Change the disable status after the process is done.
   * @param {boolean} status true if in progress.
   * @returns {void}
   */
  inProgress(status: boolean = true) {
    this.setState({ isDisabled: status });
  }

  /**
   * Handles the visibility of Edit lab test modal
   * @param {boolean} isVisible the state of visibility
   * @returns {void}
   */
  isEditModalVisible(isVisible: boolean = true) {
    if (!hasPermission(this.props.user, List([createPermission('lab_tests', 'update')]))) {
      return createErrorNotification(translate('lab_test_cant_be_edited_no_permission'));
    }
    return this.setState({ isEditModalVisible: isVisible });
  }

  /**
   * Returns the label of the button
   * @param {string} type the button type
   * @param {boolean} inProgress If the current lab test is in progress
   * @returns {string}
   */
  getLabel(type: string, inProgress: boolean) {
    switch (type) {
      case 'request':
        return inProgress ? 'requesting_order' : 'request_order';
      case 'cancel':
        return inProgress ? 'cancelling_order' : 'cancel';
      case 'edit':
        return 'view_edit_details';
      case 'delete':
        return 'delete';
      default:
        return inProgress ? 'requesting_order' : 'request_order';
    }
  }

  /**
   * Returns the button action when clicked.
   * @param {string} type The button type
   * @param {ProcedureTypeModel} procedureType A ProcedureTypeModel
   * @param {SaveModel} saveModel The SaveModel function
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel
   * @returns {void}
   */
  getAction = (type: string, procedureType: ProcedureTypeModel,
    saveModel: SaveModel, procedureRequest: ProcedureRequestModel) => {
    switch (type) {
      case 'request':
        return this.onRequest(procedureType, saveModel);
      case 'cancel':
        return this.onCancel(procedureRequest, saveModel);
      case 'edit':
        return this.isEditModalVisible();
      case 'delete':
        return this.onDelete(procedureType, saveModel);
      default:
        return undefined;
    }
  }

  /**
   * Returns the button className value
   * @param {string} type the button type
   * @returns {string}
   */
  getClassName(type: string) {
    switch (type) {
      case 'request':
        return 'u-full-width o-button o-button--small';
      case 'cancel':
        return 'u-full-width o-button o-button--danger o-button--small';
      case 'edit':
        return 'o-text-button o-text-button--contextual';
      case 'delete':
        return 'o-text-button o-text-button--danger';
      default:
        return 'o-button o-button--small';
    }
  }

  /**
   * Handles the requesting of an order
   * @param {ProcedureTypeModel} procedureType A ProcedureTypeModel
   * @param {SaveModel} saveModel the SaveModel function
   * @returns {void}
   */
  onRequest(procedureType: ProcedureTypeModel, saveModel: SaveModel) {
    this.inProgress();
    const { encounterID, patientID, saveModels, encounter } = this.props;
    const specimenIDs = procedureType.get('specimens') ? procedureType.get('specimens').flat() : [];
    const procedureRequestModel = new ProcedureRequestModel({
      encounter_id: encounterID,
      patient_id: patientID,
      procedure_type_id: procedureType.get('_id'),
      practitioner_id: encounter?.getValue('doctor'),
    });
    // Need to manually create procedure_result, procedure_status and collected_specimen docs
    // if the procedure_type has no integrations.
    if (!procedureType.getIntegrations()) {
      const procedureStatusModel = new ProcedureStatusModel({
        procedure_request_id: procedureRequestModel.get('_id'),
        procedure_type_id: procedureType.get('_id'),
        patient_id: patientID,
      });
      procedureStatusModel.addEvent('pending');
      const procedureResultModel = new ProcedureResultModel({
        procedure_request_id: procedureRequestModel.get('_id'),
        procedure_type_id: procedureType.get('_id'),
        patient_id: patientID,
        assets: [],
      });
      saveModels([procedureStatusModel, procedureResultModel]);
      if (specimenIDs.length > 0) {
        Promise.all(specimenIDs.map((specimenID) => {
          const collectedSpecimenModel = new CollectedSpecimenModel({
            procedure_request_id: procedureRequestModel.get('_id'),
            procedure_type_id: procedureType.get('_id'),
            specimen_id: specimenID,
            patient_id: patientID,
          });
          collectedSpecimenModel.addEvent('pending');
          return saveModel(collectedSpecimenModel);
        }));
      }
    }
    saveModel(procedureRequestModel)
      .then(() => {
        createSuccessNotification(translate('lab_test_ordered_successfully', { labTestName: procedureType.getName() }));
        this.inProgress(false);
      });
  }

  /**
   * Handles the deletion of lab test. This will not actually delete the doc
   * but just setting the 'active' field value to 'false'.
   * @param {ProcedureTypeModel} procedureType A ProcedureTypeModel
   * @param {SaveModel} saveModel the SaveModel function
   * @returns {void}
   */
  onDelete(procedureType: ProcedureTypeModel, saveModel: SaveModel) {
    if (!procedureType.get('is_editable')) {
      return createErrorNotification(translate('lab_test_cant_be_deleted'));
    } else if (!hasPermission(this.props.user, List([createPermission('lab_tests', 'delete')]))) {
      return createErrorNotification(translate('lab_test_cant_be_deleted_no_permission'));
    }
    return getConfirmation(translate('confirm_lab_test_deletion', { labTestName: procedureType.getName() }))
      .then(() => {
        this.inProgress();
        saveModel(procedureType.setActive(false))
          .then(() => {
            createSuccessNotification(translate('lab_test_deleted'));
            this.inProgress(false);
          });
      },
      () => this.inProgress(false));
  }

  /**
   * Handles cancelling of lab test
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel
   * @param {SaveModel} saveModel A SaveModel function
   * @returns {undefined}
   */
  onCancel(procedureRequest: ProcedureRequestModel, saveModel: SaveModel) {
    if (procedureRequest) {
      const { procedureStatuses, procedureTypes } = this.props;
      const procedureStatus = procedureRequest.getStatus(procedureStatuses);
      getConfirmation(translate('confirm_procedure_request_cancellation'))
        .then(() => {
          this.inProgress();
          if (procedureStatus) {
            return saveModel(procedureRequest.addStatusEvent(procedureStatuses, 'cancelled'))
              .then(() => {
                createSuccessNotification(translate('lab_test_cancelled_successfully', { labTestName: procedureRequest.getTypeName(procedureTypes) }));
                this.inProgress(false);
              });
          }
          return cancelLabOrder(procedureRequest.get('_id')).then((response) => {
            if (response && !response.ok) {
              createErrorNotification(translate('error_cancelling_order'));
              this.inProgress(false);
            } else {
              createSuccessNotification(translate('lab_test_cancelled_successfully', { labTestName: procedureRequest.getTypeName(procedureTypes) }));
              this.inProgress(false);
            }
          })
            .then(() => this.inProgress(false));
        },
        () => {});
    }
  }

  /**
   * Returns button properties
   * @returns {void}
   */
  generateActionButtonProperties() {
    const { type, procedureType, saveModel, procedureRequest } = this.props;
    const inProgress = this.state.isDisabled;
    return {
      label: this.getLabel(type, inProgress),
      isDisabled: inProgress,
      action: () => this.getAction(type, procedureType, saveModel, procedureRequest),
      className: this.getClassName(type),
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { label, isDisabled, action, className } = this.generateActionButtonProperties();
    const { procedureType, config, saveModel, providers, specimens } = this.props;
    const { isEditModalVisible } = this.state;
    return (
      <div>
        <Button
          disabled={isDisabled}
          className={className}
          onClick={() => action()}
          dataPublic
        >
          {translate(label)}
        </Button>
        { isEditModalVisible &&
          <StatelessModal
            id={`form-modal-${procedureType.get('_id')}`}
            buttonLabel=""
            buttonClass=""
            title={translate('add_edit_lab_test')}
            className="js-modal"
            visible={isEditModalVisible}
            setVisible={isVisible => this.isEditModalVisible(isVisible)}
            explicitCloseOnly
            dataPublicHeader
          >
            <LabTestsForm
              config={config}
              onSave={() => this.isEditModalVisible(false)}
              saveModel={saveModel}
              providers={providers}
              specimens={specimens}
              onCancel={() => this.isEditModalVisible(false)}
              modelToEdit={procedureType}
              disabled={!hasPermission(this.props.user, List([createPermission('lab_tests', 'update')]))}
            />
          </StatelessModal>
        }
      </div>
    );
  }
}

export default LabTestActionButton;
