import React from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import { isArray } from 'lodash';

import type { CellInfo, DerivedDataObject, Column, RowInfo } from 'react-table';
import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import ContentTransition from './../contentTransition';
import Table from './../table/table';
import { sortByNumber } from './../../utils/comparators';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { printInventoryReport } from './../../utils/print';
import { fixedDecimal, validateAndTrimString, isLoading, sum, transformFilteredColumns, transformColumnKeys } from './../../utils/utils';
import { UNICODE } from './../../constants';
import StockAdjustmentActions from './stockAdjustmentActions';
import StockTakeList from './stockTakeList';
import DispensingOrderModal from './dispensingOrderModal';
import Header from './../header/header';
import SaveButton from './../buttons/saveButton';
import { sidebarWidth } from '../../utils/css';
import TableColumnsSettings from '../table/tableColumnsSettings';

import type SupplyItemModel from '../../models/supplyItemModel';
import type SupplierModel from '../../models/supplierModel';
import type DrugModel from './../../models/drugModel';
import type { User, MapValue, Config, CountPerSKUAndBatch, SaveModel, SaveModels, CustomColumn, Column } from './../../types';
import type { MenuAction } from '../buttons/menuButton';
import { filterSubrows, filterDateRange } from '../../utils/filters';
import { prettifyDate } from '../../utils/time';

const ScrollableContainer = glamorous.section({
  height: '100vh',
  maxWidth: `calc(100vw - ${sidebarWidth})`,
});

const FixedTableButtonHeader = glamorous.div({
  position: 'sticky',
  top: 0,
  zIndex: 1,
});

type Props = {
  drugs: List<DrugModel>,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  user: User,
  config: Config,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  resetInventoryBatchCountSync: () => void,
  supplyItems: List<SupplyItemModel>,
  saveModel: SaveModel,
  suppliers: List<SupplierModel>,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  isSKUCountSyncing: boolean
  dispensationCount: Map<string, number>,
  mdlInfo: Map<string, { mapped_drug: string}>, // eslint-disable-line camelcase
  saveModels: SaveModels,
};

type State = {
  filteredData: Array<{ [key: string]: MapValue }>,
  title: string,
  showEmptyBatches: boolean,
  columns: Array<CustomColumn>,
  expanded: { [index: number]: boolean} | {},
  expandedBatches: Map<number, RowInfo | undefined>,
};

/* eslint-disable camelcase */
type Row = {
  sku_id: string,
  name: string,
  batch_id?: string,
  count: number | string,
  skuCount?: number | string,
  expiry_date?: string,
  demand_day?: number | string,
  days_left?: number | string,
  inventory_cost?: number | string,
  unit_cost?: { price: number, qty: number },
  dispensation_unit: string,
  tags:string,
  adjust: React.ReactElement,
  sku_adjust: React.ReactElement,
  manufacturer: string,
  is_subrow: boolean,
}
/* eslint-enable camelcase */

type DrugMapRecord = { hasSupplyItems: boolean, drug: DrugModel, qty: number, price: number }

const COLUMNS: List<Column> = List([
  {
    accessor: 'sku_id',
    Header: '',
    PivotValue: () => null,
    width: 30,
    uncustomizable: true,
  },
  {
    accessor: 'name',
    Header: translate('sku_name'),
    Cell: () => null,
    filterable: true,
    aggregate: (values: Array<string>) => values[0],
    Aggregated: ({ value, subRows }: CellInfo) => {
      // eslint-disable-next-line no-underscore-dangle
      const haveSubrows = subRows?.find(r => r._original.is_subrow);
      if (haveSubrows) {
        return `${value} (${subRows.length})`;
      }
      return value;
    },
    className: 'u-flex-align-items-center',
  },
  {
    accessor: 'batch_id',
    Header: translate('batch_id'),
    Cell: (row: CellInfo) => <div className="o-table__cell">{row.value || UNICODE.EMDASH}</div>,
    filterable: true,
    filterMethod: filterSubrows(),
    Aggregated: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
  },
  {
    accessor: 'count',
    Header: translate('count'),
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value || 0.00}</div>,
    headerClassName: 'u-flex-justify-content-right',
    sortMethod: sortByNumber,
    aggregate: (values: Array<number>, rows: Array<DerivedDataObject>) =>
    // eslint-disable-next-line no-underscore-dangle
      (rows[0]._original.skuCount || fixedDecimal(sum(List(values).filter(v => !isNaN(v))))),
    Aggregated: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
  },
  {
    accessor: 'expiry_date',
    Header: translate('expiry_date'),
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value ? prettifyDate(row.value) : UNICODE.EMDASH}</div>,
    filterable: true,
    sortMethod: sortByNumber,
    filterBy: 'date_range',
    filterMethod: filterSubrows(filterDateRange),
    headerClassName: 'u-flex-justify-content-right',
    Aggregated: () => <div className="o-table__cell u-flex-justify-content-right">{UNICODE.EMDASH}</div>,
  },
  {
    accessor: 'demand_day',
    Header: translate('demand_per_day'),
    sortMethod: sortByNumber,
    Cell: () => <div className="o-table__cell u-flex-justify-content-right">{UNICODE.EMDASH}</div>,
    aggregate: (values: Array<string>) => values[0],
    headerClassName: 'u-flex-justify-content-right',
    Aggregated: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
  },
  {
    accessor: 'days_left',
    Header: translate('days_of_stock_left'),
    sortMethod: sortByNumber,
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value || UNICODE.EMDASH}</div>,
    aggregate: (values: Array<number>) => fixedDecimal(sum(List(values).filter(v => !isNaN(v)))),
    headerClassName: 'u-flex-justify-content-right',
    Aggregated: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
  },
  {
    accessor: 'inventory_cost',
    Header: translate('inventory_cost'),
    sortMethod: sortByNumber,
    Cell: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
    aggregate: (values: Array<number>) => fixedDecimal(sum(List(values).filter(v => !isNaN(v)))),
    headerClassName: 'u-flex-justify-content-right',
    Aggregated: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
  },
  {
    accessor: 'unit_cost',
    Header: translate('unit_cost'),
    sortMethod: sortByNumber,
    Cell: (row: CellInfo) => (
      <div className="o-table__cell u-flex-justify-content-right">
        {row.value ? fixedDecimal(row.value.price / row.value.qty) : UNICODE.EMDASH}
      </div>
    ),
    aggregate: (values: Array<{price: number, qty: number}>, rows: Array<DerivedDataObject>) => {
      if (rows && rows[0]) {
        // eslint-disable-next-line no-underscore-dangle
        return rows[0]._original.total_unit_cost;
      }
      return UNICODE.EMDASH;
    },
    headerClassName: 'u-flex-justify-content-right',
    Aggregated: (row: CellInfo) => <div className="o-table__cell u-flex-justify-content-right">{row.value}</div>,
  },
  {
    accessor: 'dispensation_unit',
    Header: translate('dispensation_unit'),
    filterable: true,
    Cell: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
    aggregate: (values: Array<string>) => values[0],
    Aggregated: (row: CellInfo) => <div className="o-table__cell">{row.value}</div>,
  },
  {
    accessor: 'manufacturer',
    Header: translate('manufacturer'),
    sortable: true,
    filterable: true,
    Cell: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
    aggregate: (values: Array<string>) => values[0],
    Aggregated: (row: CellInfo) => <div className="o-table__cell">{row.value}</div>,
  },
  {
    accessor: 'tags',
    Header: translate('tags'),
    filterable: true,
    Cell: () => <div className="o-table__cell">{UNICODE.EMDASH}</div>,
    aggregate: (values: Array<string>) => values[0],
    Aggregated: (row: CellInfo) => <div className="o-table__cell">{row.value}</div>,
  },
  {
    accessor: 'adjust',
    Header: '',
    sortable: false,
    show: true,
    Cell: (row: CellInfo) => row.value,
    aggregate: (
      values: Array<(drug: DrugModel) => React.ReactElement>,
      rows: Array<DerivedDataObject>,
    ) =>
    // eslint-disable-next-line no-underscore-dangle
      rows[0]._original.sku_adjust,
    Aggregated: (row: CellInfo) => <div className="o-table_cell">{row.value}</div>,
    width: 135,
    fixed: 'right',
    className: 'u-flex-align-items-center',
  },
]);

/**
 * A component displaying the current inventory levels.
 * @class InventoryStatus
 * @extends {React.Component}
 */
class InventoryStatus extends React.Component<Props, State> {
  state: State;

  props: Props;

  /**
   * Creates an instance of InventoryStatus.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredData: [],
      title: 'Inventory Report',
      columns: transformColumnKeys(COLUMNS.toArray()),
      showEmptyBatches: false,
      expanded: {},
      expandedBatches: Map(),
    };
  }

  /**
   * Creates the table rows for the current inventory.
   * @param {Map<string, Map>} inventoryCount The current inventory state.
   * @param {List<DrugModel>} drugs All drug models.
   * @returns {[]} An array of rows.
   */
  getRows(inventoryCount: CountPerSKUAndBatch, drugs: List<DrugModel>) {
    const drugsMap = drugs.reduce<Map<string, DrugMapRecord>>((all, d) => all.set(
      d.get('_id'),
      {
        hasSupplyItems: false,
        drug: d,
        qty: 0,
        price: 0,
      },
    ), Map());
    const [batchRows, filteredDrugsmap] = this.props.supplyItems
      .reduce<[Array<Row>, Map<string, DrugMapRecord>]>((
        [_rows, _drugsMap],
        supplyItem: SupplyItemModel,
      ) => {
        const drug = _drugsMap.get(supplyItem.get('sku_id'))?.drug;
        const batch = this.props.inventoryCount.getIn([supplyItem.get('sku_id'), 'batches', supplyItem.get('_id')]);

        if (drug && drug.isVisible()) {
          const count = batch && batch.get('batchStockRemaining', 0);
          const demandDay = this.props.dispensationCount.get(supplyItem.get('sku_id'), 0) / 30;
          const [supplyItemPrice, supplyItemQty] =
            (!(isNaN(supplyItem.get('total_price')) || supplyItem.get('total_price') === null)
            && !(isNaN(supplyItem.get('quantity')) || supplyItem.get('quantity') === null))
              ? [supplyItem.get('total_price'), supplyItem.get('quantity')]
              : [0, 0];
          if (!this.state.showEmptyBatches && (!count || count <= 0)) {
            return [_rows, _drugsMap.update(supplyItem.get('sku_id'), drugItem => ({
              ...drugItem,
              qty: drugItem.qty + supplyItemQty,
              price: drugItem.price + supplyItemPrice,
            }))];
          }
          const itemRow: Row = {
            sku_id: supplyItem.get('sku_id', ''),
            name: validateAndTrimString(drug.get('name')),
            batch_id: supplyItem.get('batch_id', UNICODE.EMDASH),
            count: fixedDecimal(count) || UNICODE.EMDASH,
            skuCount: fixedDecimal(this.props.inventoryCount.getIn([supplyItem.get('sku_id'), 'skuStockRemaining'], 0)) || UNICODE.EMDASH,
            expiry_date: supplyItem.get('expiry_date'),
            demand_day: fixedDecimal(demandDay, 3),
            days_left: demandDay && fixedDecimal(count / demandDay),
            inventory_cost: fixedDecimal((supplyItem.get('total_price') / supplyItem.get('quantity')) * count),
            unit_cost: { price: supplyItem.get('total_price'), qty: supplyItem.get('quantity') },
            dispensation_unit: drug.getDispensationUnit(),
            tags: drug.getTags().length ? drug.getTags() : UNICODE.EMDASH,
            adjust: this.getAdjustmentButton(undefined, supplyItem),
            sku_adjust: this.getAdjustmentButton(drug),
            manufacturer: drug.get('manufacturer') || UNICODE.EMDASH,
            is_subrow: true,
          };
          return [[..._rows, itemRow], _drugsMap.update(supplyItem.get('sku_id'), drugItem => ({
            ...drugItem,
            hasSupplyItems: true,
            qty: drugItem.qty + supplyItemQty,
            price: drugItem.price + supplyItemPrice,
          }))];
        }
        return [_rows, _drugsMap];
      }, [[], drugsMap]);

    const drugsRows = filteredDrugsmap.reduce((_rows, drugRecord) => {
      const { drug, hasSupplyItems, qty: totalQty, price: totalPrice } = drugRecord;
      if (drug && drug.isVisible() && !hasSupplyItems) {
        const count = inventoryCount.get(drug.get('_id'), Map()).get('skuStockRemaining', 0);
        const demandDay = this.props.dispensationCount.get(drug.get('_id'), 0) / 30;
        const adjustButton = this.getAdjustmentButton(drug);
        const itemRow = {
          sku_id: drug.get('_id', ''),
          name: validateAndTrimString(drug.get('name')),
          count: fixedDecimal(count),
          demand_day: fixedDecimal(demandDay, 3),
          dispensation_unit: drug.getDispensationUnit(),
          total_unit_cost: fixedDecimal(totalPrice / totalQty),
          tags: drug.getTags().length ? drug.getTags() : UNICODE.EMDASH,
          adjust: adjustButton,
          sku_adjust: adjustButton,
          manufacturer: drug.get('manufacturer') || UNICODE.EMDASH,
          is_subrow: false,
        };
        return [..._rows, itemRow];
      }
      return _rows;
    }, []);
    return batchRows.map((row) => {
      const drugData = filteredDrugsmap.get(row.sku_id);
      return {
        ...row,
        total_unit_cost: drugData ? fixedDecimal(drugData.price / drugData.qty) : UNICODE.EMDASH,
      };
    }).concat(drugsRows);
  }

  /**
   * Creates an adjustment button for a specific SKU.
   * @param {DrugModel} drug DrugModel
   * @param {SupplyItemModel | void} supplyItem supply item model
   * @returns {React.Component | void} An adjustment button
   */
  getAdjustmentButton(drug?: DrugModel, supplyItem?: SupplyItemModel) {
    const remainingQtyInBatch = supplyItem ? this.props.inventoryCount.getIn([
      supplyItem.get('sku_id'),
      'batches',
      supplyItem.get('_id'),
      'batchStockRemaining',
    ]) : 0;
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('inventory_status', 'create')])} user={this.props.user}>
        <StockAdjustmentActions
          drug={drug}
          supplyItem={supplyItem}
          {...this.props}
        />
      </PermissionWrapper>
    );
  }

  /**
   * Handles print button being clicked.
   * @returns {void}
   */
  onPrintClicked() {
    const dataToPrint = this.state.filteredData.map((row) => {
      if (row.is_subrow) {
        const unitCost = row.unit_cost;
        return Object.assign({
          ...row,
          unit_cost:
            unitCost.price && unitCost.qty
              ? fixedDecimal(unitCost.price / unitCost.qty)
              : UNICODE.EMDASH,
          name: undefined,
        });
      }
      return Object.assign({
        ...row,
        unit_cost: undefined,
        name: row.name,
      });
    });
    printInventoryReport(
      this.props.config,
      this.state.title,
      this.state.columns.filter(c => !['adjust', 'sku_id'].includes(c.value)).map(c => c.label),
      dataToPrint,
      this.state.columns.filter(c => !['adjust', 'sku_id'].includes(c.value)).map(c => c.value),
    );
  }

  /**
   * Gets the menu actions for inventory status table
   *  @returns {Array<MenuAction>}
   */
  getMenuActions(): Array<MenuAction> {
    return [
      {
        label: translate('print'),
        value: 'print',
        dataPublic: true,
      },
    ];
  }

  onPivotRowClick = (newExpanded:{ [index: number]: boolean| {} }, rowInfo: RowInfo) => {
    const { expandedBatches } = this.state;
    const index = rowInfo.nestingPath[0];
    const isExpanded = newExpanded[index];
    if (isExpanded && isExpanded !== undefined) {
      this.setState({
        expanded: newExpanded,
        expandedBatches: expandedBatches.update(index, () => rowInfo.row),
      });
    } else {
      this.setState({
        expanded: newExpanded,
        expandedBatches: expandedBatches.delete(index),
      });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const loading = this.props.isFetching || this.props.isSKUCountSyncing;
    const rows = this.getRows(this.props.inventoryCount, this.props.drugs);
    return (
      <ContentTransition>
        <ScrollableContainer className="o-scrollable-container">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-title">{translate('inventory_status')}</h1>
          </Header>
          <div className="o-card u-margin-bottom--4ws">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{ translate('inventory_status') }</h1>
              <div className="u-flex-right u-margin-right--half-ws">
                <TableColumnsSettings
                  config={this.props.config}
                  configFieldName="inventory_status"
                  updateConfigValue={this.props.updateConfigValue}
                  originalColumns={List(transformColumnKeys(COLUMNS.toArray()))}
                  columns={this.state.columns}
                  onUpdateColumns={(columns) => {
                    this.setState({ columns });
                  }}
                  updateConfig={this.props.updateConfig}
                />
              </div>
            </Header>
            <FixedTableButtonHeader>
              <div className="o-header-actions">
                {this.state.filteredData && this.state.filteredData.length > 0 && (
                  <div className="u-margin-left--half-ws u-margin-right--half-ws">
                    <PermissionWrapper permissionsRequired={List([createPermission('batch_dispensing_order', 'update')])} user={this.props.user}>
                      <DispensingOrderModal
                        config={this.props.config}
                        updateConfigValue={this.props.updateConfigValue}
                        updateConfig={this.props.updateConfig}
                        resetInventoryBatchCountSync={this.props.resetInventoryBatchCountSync}
                        onClose={() => {}}
                      />
                    </PermissionWrapper>
                  </div>
                )}
                <div className="u-margin-right--half-ws">
                  <PermissionWrapper permissionsRequired={List([createPermission('inventory_status', 'create')])} user={this.props.user}>
                    <StockAdjustmentActions
                      isBulk
                      {...this.props}
                    />
                  </PermissionWrapper>
                </div>
                {this.state.filteredData && this.state.filteredData.length > 0 && (
                  <div className="u-margin-right--half-ws">
                    <StockTakeList
                      updateConfigValue={this.props.updateConfigValue}
                      updateConfig={this.props.updateConfig}
                      supplyItems={this.props.supplyItems}
                      drugs={this.props.drugs}
                      config={this.props.config}
                      isFetching={this.props.isFetching}
                      suppliers={this.props.suppliers}
                      inventoryCount={this.props.inventoryCount}
                      inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
                      updateInventoryItemCount={this.props.updateInventoryItemCount}
                      saveModel={this.props.saveModel}
                    />
                  </div>
                )}
                <SaveButton
                  dataPublic
                  label={this.state.showEmptyBatches ? translate('hide_empty_batches') : translate('show_empty_batches')}
                  onClick={() => {
                    const { expanded, expandedBatches, showEmptyBatches } = this.state;
                    const { expandValue, expandedBatchValues } = Object.keys(expanded).reduce(({ expandValue, expandedBatchValues }, v) => {
                      if (showEmptyBatches) {
                        const rowData = expandedBatches.get(Number(v));
                        const subRows = rowData && rowData._subRows;
                        const allBatchesAreNegative = subRows && isArray(subRows) ? subRows.every((s: any) => s._original.count && !isNaN(s._original.count) && Number(s._original.count) <= 0) : undefined;
                        if (allBatchesAreNegative) {
                          return {
                            expandValue: Object.assign({}, expandValue, { [v]: false }),
                            expandedBatchValues: expandedBatchValues.delete(Number(v)),
                          };
                        }
                      }
                      return { expandValue, expandedBatchValues };
                    }, { expandValue: expanded, expandedBatchValues: expandedBatches });
                    this.setState({
                      expanded: expandValue,
                      expandedBatches: expandedBatchValues,
                    }, () => {
                      this.setState({ showEmptyBatches: !showEmptyBatches });
                    });
                  }}
                  className="o-button--small u-margin-right--half-ws"
                  disabled={loading}
                />
                <div className="u-margin-right--half-ws">
                  <button
                    className="o-button o-button--padded o-button--small"
                    onClick={() => this.onPrintClicked()}
                    disabled={loading}
                  >
                    {translate('print')}
                  </button>
                </div>
              </div>
            </FixedTableButtonHeader>
            <Table
              columns={transformFilteredColumns(COLUMNS.toArray(), this.state.columns)}
              data={loading ? [] : rows} // Needed as initial, filteredData will have count 0 data.
              noDataText={
                translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(loading))
              }
              showPagination
              pivotBy={['sku_id']}
              defaultSorted={[{ id: 'name', desc: false }]}
              filteredSortedDataHandler={
                (filteredData) => {
                  this.setState({
                    filteredData,
                    // Reset all expanded rows, as we are using index for finding expanded ones.
                    // Filter/Sort changes index which will result in expanded state of unwanted rows.
                    expanded: {},
                    expandedBatches: Map(),
                  });
                }
              }
              initialDataHandler={(initialData) => {
                this.setState({
                  filteredData: initialData,
                });
              }}
              showFixedColumns
              expanded={this.state.expanded}
              onPivotRowClick={this.onPivotRowClick}
            />
          </div>
        </ScrollableContainer>
      </ContentTransition>
    );
  }
}

export default InventoryStatus;
