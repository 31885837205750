import React, { useState } from 'react';

import { updateClinicConfig } from './../../utils/db';
import Select from './../inputs/select';
import ContentTransition from './../contentTransition';
import { createSuccessNotification } from './../../utils/notifications';
import { getStore } from './../../utils/redux';
import { syncBatchInventoryCountAll } from './../../utils/inventory';
import FormError from './../formError';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import SavePrompt from './../prompts/savePrompt';
import Confirm from './../prompts/confirm';
import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';

import type { MapValue, Config, SelectOpts } from './../../types';

type Props = {
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  resetInventoryBatchCountSync: () => void,
  onClose: () => void,
}
const DISPENSING_ORDER_OPTIONS: SelectOpts = [
  { value: 'expiry_date', label: translate('earliest_expiry_date') },
  { value: 'supply_date', label: translate('earliest_supply_date') },
];


/**
 * Renders a component showing the current dispensing batch and all the batch available for dispensation.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
export default function DispensingOrderModal(props: Props) {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isChangesMade, setChangesMade] = useState(false);
  const [isSaving, setSavingState] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [dispensingOrder, setDispensingOrder] = useState(null);
  const [showSaveModal, setShowSaveModal] = useState(false);

  /**
   * Handles saving preferred dispensing batch. This will update the drug doc
   * @returns {void}
   */
  const onSaveClicked = () => {
    setSavingState(true);
    setErrorMessage(null);
    const { config, updateConfig, updateConfigValue } = props;
    const updatedConfig = config
      .setIn(['dispensation', 'dispensing_order'], dispensingOrder)
      .toJS();
    return updateClinicConfig(config.toJS(), updatedConfig, updateConfig)
      .then(() =>
        updateConfigValue(['dispensation', 'dispensing_order'], dispensingOrder))
      .then(() => props.resetInventoryBatchCountSync())
      .then(() => syncBatchInventoryCountAll(getStore()))
      .then(() =>
        createSuccessNotification(
          translate('dispensing_order_set_to_x', {
            x: (
              DISPENSING_ORDER_OPTIONS.find(
                opt => opt.value === dispensingOrder,
              ) || DISPENSING_ORDER_OPTIONS[0]
            ).label,
          }),
        ))
      .then(() => {
        setChangesMade(false);
        setSavingState(false);
        setModalVisible(false);
        setShowSaveModal(false);
        props.onClose();
        return true;
      });
  };


  return (
    <StatelessModal
      id="select-batch-to-dispense-btn"
      buttonLabel={translate(
        'dispense_by_x',
        { x: translate(props.config.getIn(['dispensation', 'dispensing_order'], 'expiry_date')) },
      )}
      buttonClass="o-button o-button--small o-button--full-width u-margin-right--half-ws"
      title={translate('dispensing_order')}
      setVisible={(isVisible: boolean) => {
        if (isModalVisible && isChangesMade && !errorMessage) {
          setErrorMessage(translate('you_have_unsaved_changes'));
        } else {
          setModalVisible(isVisible);
        }
      }}
      visible={isModalVisible}
      onClose={() => {
        if (!isChangesMade || errorMessage) {
          setDispensingOrder(null);
          setErrorMessage(null);
          props.onClose();
        }
      }}
      explicitCloseOnly
      dataPublicHeader
    >
      <Confirm
        show={showSaveModal}
        cancel={() => setShowSaveModal(false)}
        proceed={onSaveClicked}
        confirmation={translate('dispensing_order_confirm_warning_message')}
        modalTitle={translate('warning')}
        footerSaveButtonName={translate('confirm')}
      />
      <SavePrompt when={isChangesMade} onSaveClicked={onSaveClicked} />
      <ContentTransition className="o-scrollable-container" id="supply-form">
        <div>
          {
            errorMessage &&
            <div className="u-margin--standard">
              <FormError containerElementID="add-to-inventory-form">
                {errorMessage}
              </FormError>
            </div>
          }
          <div className="u-margin--standard">
            <Select
              options={DISPENSING_ORDER_OPTIONS}
              autoFocus
              id="dispensing_order"
              label={translate('dispense_batches_by')}
              value={dispensingOrder || props.config.getIn(['dispensation', 'dispensing_order'], 'expiry_date')}
              onValueChanged={(value) => {
                if (value && value !== props.config.getIn(['dispensation', 'dispensing_order'], 'expiry_date')) {
                  setChangesMade(true);
                } else {
                  setChangesMade(false);
                }
                setDispensingOrder(value);
              }}
              required
            />
            <hr />
          </div>
        </div>
      </ContentTransition>
      <ModalFooter>
        <SaveButton
          dataPublic
          onClick={() => setShowSaveModal(true)}
          isSaving={isSaving}
          label={translate('save')}
          className="o-button--small u-margin-right--half-ws"
          disabled={!isChangesMade}
        />
      </ModalFooter>
    </StatelessModal>
  );
}
