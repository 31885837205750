import { Map } from 'immutable';
import BaseModel from './baseModel';
import translate from '../utils/i18n';
import formatCampaignRulesConditionMetadata, { mapEncounterFilterTypeToLabel } from '../utils/patientCampaign';

import type DrugModel from './drugModel';
import type SalesItemModel from './salesItemModel';
import type PractitionerModel from './practitionerModel';
import MasterDrugModel from './masterDrugModel';
import getCampaignDefaultAttr from '../utils/campaign';
import MasterCampaignModel from './masterCampaignModel';
import { getPrependString, getServerName } from './../utils/utils';

export enum CampaignRuleType {
  ENCOUNTER_DOCTOR = 'doctor', // DEPRECATED
  DRUG_PRESCRIBED = 'drug_prescribed', // DEPRECATED
  ITEM_SOLD = 'item_sold', // DEPRECATED
  ENCOUNTER_TIME = 'encounter_time',
  ENCOUNTER_FLOW = 'encounter_flow',
  PREV_PATIENT_JOB_ENCOUNTER_TIME = 'prev_patient_job_encounter_time',
  MASTER_DRUG_SOLD = 'master_drug_sold',
  SPECIFIC_MASTER_CAMPAIGN_JOB_TIME = 'specific_master_campaign_job_time',
  SPECIFIC_CAMPAIGN_JOB_TIME = 'specific_campaign_job_time',
  // DIAGNOSIS = 'diagnosis',
  // MASTER_DRUG_PRESCRIBED = 'master_drug_prescribed',
  // MASTER_DRUG_DISPENSED = 'master_drug_dispensed',
}

export type TimeUnit = 'day' | 'week' | 'month' | 'year';

enum Comparator {
  EQ = 'eq',
  LTE = 'lte',
  GTE = 'gte',
  BTW= 'btw',
}

interface CampaignScheduleAt {
  time_unit: TimeUnit;
  time_value: number;
}

interface CampaignTimeFilterEqCondition {
  encounter_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ;
}

interface CampaignTimeFilterBtwCondition {
  encounter_time_offset_max: CampaignScheduleAt;
  encounter_time_offset_min: CampaignScheduleAt;
  comparator: Comparator.BTW;
}

interface SpecificMasterCampaignJobTimeFilterCondition {
  master_campaign_id: string;
  job_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ;
}

interface SpecificCampaignJobTimeFilterCondition {
  campaign_id: string;
  job_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ;
}

interface CampaignPrevPatientJobEncounterTimeFilterCondition {
  encounter_time_offset: CampaignScheduleAt;
  comparator: Comparator.EQ | Comparator.GTE;
}

interface FilterCondition<T extends CampaignRuleType> {
  type: T;
  metadata: T extends
      | CampaignRuleType.ENCOUNTER_TIME
      ? (CampaignTimeFilterEqCondition | CampaignTimeFilterBtwCondition)
      : T extends CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME
      ? CampaignPrevPatientJobEncounterTimeFilterCondition
      : T extends CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME
      ? SpecificCampaignJobTimeFilterCondition
      : T extends CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME
      ? SpecificMasterCampaignJobTimeFilterCondition
      : string
}

export type CampaignRulesCondition =
  | FilterCondition<CampaignRuleType.ENCOUNTER_FLOW>
  | FilterCondition<CampaignRuleType.ENCOUNTER_DOCTOR>
  | FilterCondition<CampaignRuleType.ENCOUNTER_TIME>
  | FilterCondition<CampaignRuleType.ITEM_SOLD>
  | FilterCondition<CampaignRuleType.PREV_PATIENT_JOB_ENCOUNTER_TIME>
  | FilterCondition<CampaignRuleType.DRUG_PRESCRIBED>
  | FilterCondition<CampaignRuleType.MASTER_DRUG_SOLD>
  | FilterCondition<CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME>
  | FilterCondition<CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME>

export type CampaignRules = {
  logical_op: 'or' | 'and';
  conditions: Array<CampaignRulesCondition>;
};

export type Attributes = {
  _id: string,
  version : string,
  type: 'patient_campaign',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  hidden?: boolean,
  name: string,
  filter: CampaignRules,
  message: string,
  is_active?: boolean,
  stats: {
      sent: number,
      responded: number,
      queued: number,
      resolved: number,
      unresolved: number
  },
  cancelled: Array<string>,
};

/**
 * SMSCampaignModel
 *
 * @namespace PatientCampaignModel
 */
class PatientCampaignModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'patient_campaign';
  }

  /**
   * @return {string} status of the campaign
   */
  getStatus() {
    return this.get('is_active') ? 'active' : 'inactive';
  }

  /**
    * Convert sms campaign rule to string
    * @param {Map<string, DrugModel>} drugs a map of all drugs in the clinic
    * @param {Map<string, SalesItemModel>} salesItems a map of all sales items
    * @param {Map<string, PractitionerModel>} doctors a map of all doctors in the clinic
    * @param {Map<string, MasterDrugModel>} masterDrugModelsMap a map of all master drugs
    * @param {Map<string, MasterCampaignModel | PatientCampaignModel>} campaigns campaigns
    * @param {boolean | void} disableProspective propsective campaign is disabled or not
    * @returns {string}
    */
  getCampaignRulesString(
    drugs: Map<string, DrugModel>,
    salesItems: Map<string, SalesItemModel>,
    doctors: Map<string, PractitionerModel>,
    masterDrugModelsMap: Map<string, MasterDrugModel>,
    campaigns: Map<string, MasterCampaignModel | PatientCampaignModel>,
  ): string {
    const {
      logical_op: logicalOp,
      conditions,
    } = this.get('filter');
    const filterConditions = conditions.filter((c: CampaignRulesCondition) =>
      ![CampaignRuleType.ENCOUNTER_TIME, CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME,
        CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME].includes(c.type));
    const {
      scheduleUnit,
      scheduleValue,
      campaignJobId,
      masterCampaignJobId,
    } =
      getCampaignDefaultAttr(this);
    const campaignName = (campaignJobId
      ? campaigns.get(campaignJobId)?.get('name') 
      : campaigns.get(getPrependString(`mc-${getServerName()}-`, masterCampaignJobId))?.get('name')
        || campaigns.get(masterCampaignJobId)?.get('name')) ?? '';
    const scheduleString = `${translate('campaign_sms_will_send_after_job',
      {
        time_value: scheduleValue || '',
        time_unit: scheduleUnit || '',
        jobName: (campaignName && (campaignJobId ? `campaign ${campaignName}` : `master campaign ${campaignName}`)) || 'encounter',
      })}`;
    if (!(filterConditions && filterConditions.length)) {
      return scheduleString;
    }
    return filterConditions
      .reduce((intialString: string, encounterFilter: CampaignRulesCondition, index: number) => {
        const metadataValue = formatCampaignRulesConditionMetadata(
          encounterFilter.type.toLowerCase(),
          encounterFilter.metadata,
          drugs,
          salesItems,
          doctors,
          masterDrugModelsMap,
        );
        if (metadataValue === null) {
          return intialString;
        }
        return `${intialString}${index !== 0 ? `${translate(logicalOp)} ` : ' + \n'}` +
        `${mapEncounterFilterTypeToLabel(encounterFilter.type)} is "${metadataValue}" \n`;
      }, `${scheduleString}`);
  }

  /**
   * Removes attributes not part of db document and returns the model
   * @returns {PatientCampaignSetModel}
   */
  beforeSave() {
    const delAttrs: Array<keyof Attributes> = ['stats'];
    const updatedAttrs = this.copyData({}, delAttrs);
    return new PatientCampaignModel(updatedAttrs);
  }

  /**
   * is master campaign
   * @returns {boolean}
   */
  isMasterCampaign() {
    return this.get('_id').startsWith(`mc-${getServerName()}`);
  }
}

export default PatientCampaignModel;
