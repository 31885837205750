import { connect } from 'react-redux';
import { List } from 'immutable';

import ConsultationHistoryItem from './../components/consultations/consultationHistoryItem';
import { saveFactory, saveModelsFactory } from './../utils/redux';
import {
  deepClone,
} from './../utils/utils';
import { updateConfigValue, updateConfig } from './../actions';
import { getModelMapFromList } from '../utils/models';
import { compareByAlphabeticalOrder } from '../utils/comparators';

import BillItemModel from './../models/billItemModel';
import type { Dispatch, Config, State } from './../types';
import type EncounterModel from './../models/encounterModel';
import type PatientModel from './../models/patientModel';

type Props = {
  disableChanges?: boolean,
  showLowStockWarning?: boolean,
  encounter: EncounterModel,
  patient: PatientModel,
};

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, drugs, medicalCertificates, salesItems, timeChits, prescriptions, documentTemplates,
  isOnline, inventoryCount, conditions, practitioners, user, coveragePayors, documentData,
  bills, billItems, payments, allergies, procedureRequests, providers, procedureTypes, specimens,
  procedureStatuses, collectedSpecimens, procedureResults, klinifyConfig, verifiedDrugs,
  encounterStages, encounterFlows, dosingRegimens, encounters,
}: State, ownProps: Props) => {
  const filteredProcedureRequests = procedureRequests
    .filter(procedureRequest => procedureRequest.get('encounter_id') === ownProps.encounter.get('_id') &&
  procedureRequest.get('patient_id') === ownProps.encounter.get('patient_id'));
  const filteredSpecimens = specimens
    .filter(specimen =>
      specimen.get('patient_id') === ownProps.encounter.get('patient_id') &&
      filteredProcedureRequests.filter(procedureRequest =>
        specimen.get('procedure_request_id') === procedureRequest.get('_id')));
  const bill = bills.find(model => model.get('encounter_id') === ownProps.encounter.get('_id'));
  const billAttributes = bill && deepClone(bill.attributes);
  if (billAttributes && billAttributes.co_payment === null) {
    billAttributes.co_payment = 0;
  }
  const filteredBillItems = billItems.filter(i => i.get('bill_id') === bill?.get('_id'));
  const finalBillItems = (bill && !bill.isVoid() && !bill.isFinalised()) ?
    filteredBillItems.concat(
      prescriptions.map(p => new BillItemModel({
        patient_id: p.get('patient_id'),
        quantity: p.get('sale_quantity', 0),
        price: p.get('sale_price', 0),
        cost_price: p.get('cost_price', 0),
        drug_id: p.get('drug_id'),
      })),
    ) : filteredBillItems;

  return {
    coveragePayorID: ownProps.patient.getCoveragePayor(),
    coveragePayors,
    disableChanges: ownProps.disableChanges === true,
    config,
    klinifyConfig,
    practitioners,
    bill,
    billItems: finalBillItems,
    billAttributes,
    doctor: ownProps.encounter.getDoctorName(practitioners, true),
    drugs,
    dosingRegimens: dosingRegimens.filter(m => m.isVisible()),
    salesItems,
    user,
    payments: bill ? payments.filter(p => p.get('bill_id') === bill.get('_id')) : List(),
    patient: ownProps.patient,
    encounter: ownProps.encounter,
    allergies: allergies.filter(m => m.get('patient_id') === ownProps.patient.get('_id') && m.isVisible()),
    diagnoses: conditions
      .filter(c => c.get('encounter_id') === ownProps.encounter.get('_id') && c.isVisible() && c.isDiagnosis()),
    symptoms: conditions
      .filter(c => c.get('encounter_id') === ownProps.encounter.get('_id') && c.isVisible() && c.isSymptom()),
    medicalCertificates: medicalCertificates
      .filter(mc => mc.get('encounter_id') === ownProps.encounter.get('_id')),
    prescriptions: prescriptions
      .filter(prescription => prescription.get('encounter_id') === ownProps.encounter.get('_id')),
    timeChits: timeChits
      .filter(timeChit => timeChit.get('encounter_id') === ownProps.encounter.get('_id')),
    providers,
    procedureTypes,
    procedureRequests: filteredProcedureRequests,
    procedureStatuses,
    collectedSpecimens,
    procedureResults,
    specimens: filteredSpecimens,
    documentData: documentData
      .filter(d => d.get('encounter_id') === ownProps.encounter.get('_id') && !d.isVoid()),
    conditions: conditions
      .filter(c => c.get('encounter_id') === ownProps.encounter.get('_id') && c.isVisible()),
    isOnline,
    inventoryCount,
    showLowStockWarning: ownProps.showLowStockWarning === true,
    documentTemplates,
    verifiedDrugs,
    encounterFlows: encounterFlows.sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name'))),
    encounterStageMap: getModelMapFromList(encounterStages),
    patientPrescriptionHistory: prescriptions.filter(p => p.get('patient_id') === ownProps.patient.get('_id')),
    encounters: encounters.filter(e => e.get('patient_id') === ownProps.patient.get('_id')),
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const ConsultationHistoryItemContainer =
  connect(mapStateToProps, mapDispatchToProps)(ConsultationHistoryItem);

export default ConsultationHistoryItemContainer;
