import React from 'react';
import { List } from 'immutable';

import Input from './../inputs/input';
import Select from './../inputs/select';
import SaveButton from './../buttons/saveButton';
import SavePrompt from './../prompts/savePrompt';
import translate from './../../utils/i18n';
import SupplierModel from './../../models/supplierModel';
import { createSuccessNotification } from './../../utils/notifications';
import { isKlinifyUser } from './../../utils/auth';
import { validateAndTrimString } from './../../utils/utils';
import TextArea from './../inputs/textarea';
import FormError from './../formError';
import Button from './../buttons/button';

import type { SaveModel, User, Config } from './../../types';

type Props = {
  clearModelToEdit: () => void,
  modelToEdit?: SupplierModel,
  saveModel: SaveModel,
  supplierType?: string,
  user: User,
  config: Config,
}

type State = {
  isSaving: boolean,
  attributes: {
    name: string,
    address: string,
    supplierIntegration: string,
    supplierId: string,
  },
  errorMessage?: string,
  changesMade: boolean,
};

/**
 * A Supplier form component
 * @class SupplierForm
 * @extends {React.Component<Props, State>}
 */
class SupplierForm extends React.Component<Props, State> {
  /**
   * Creates an instance of SupplierForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      changesMade: false,
      attributes: {
        name: '',
        address: '',
        supplierIntegration: '',
        supplierId: '',
      },
    };
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    if (validateAndTrimString(this.state.attributes.supplierIntegration).length &&
    !validateAndTrimString(this.state.attributes.supplierId).length) {
      this.setState({ errorMessage: translate('supplier_id_required_when_integration_is_selected') });
      return false;
    }
    if (!validateAndTrimString(this.state.attributes.name).length) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    return true;
  }

  /**
   * prepares values for save.
   * @returns {{}}
   */
  getAttributesForSave() {
    // trim name and address so whitespace is removed before save
    const attributes = {
      name: validateAndTrimString(this.state.attributes.name),
      address: validateAndTrimString(this.state.attributes.address),
    };
    if (isKlinifyUser(this.props.user) && this.props.supplierType === 'supplier') {
      if (validateAndTrimString(this.state.attributes.supplierIntegration).length &&
      validateAndTrimString(this.state.attributes.supplierId).length) {
        const integrations = this.props.modelToEdit ? this.props.modelToEdit.get('integrations', {}) : {};
        if (integrations[validateAndTrimString(this.state.attributes.supplierIntegration)]) {
          integrations[validateAndTrimString(this.state.attributes.supplierIntegration)] =
          {
            id: validateAndTrimString(this.state.attributes.supplierId),
          };
          return Object.assign({}, attributes, { integrations });
        }
        const integration = {};
        integration[validateAndTrimString(this.state.attributes.supplierIntegration)] =
          {
            id: validateAndTrimString(this.state.attributes.supplierId),
          };
        const supplierIntegrations = Object.assign(integration, integrations);
        return Object.assign({}, attributes, { integrations: supplierIntegrations });
      }
      const integrations = this.props.modelToEdit ? this.props.modelToEdit.get('integrations') : undefined;
      return Object.assign({}, attributes, { integrations });
    }
    return attributes;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      const attributes = this.getAttributesForSave();
      if (this.props.modelToEdit) {
        return this.props.saveModel(this.props.modelToEdit.set(attributes)).then(() => {
          createSuccessNotification(translate(`${this.props.supplierType ? this.props.supplierType : 'supplier'}_updated`));
          this.resetState();
          this.props.clearModelToEdit();
          return true;
        });
      }
      return this.props.saveModel(new SupplierModel(Object.assign({}, attributes, {
        supplier_type: this.props.supplierType,
      })))
        .then(() => {
          createSuccessNotification(translate(`${this.props.supplierType ? this.props.supplierType : 'supplier'}_added`));
          this.resetState();
          this.props.clearModelToEdit();
          return true;
        });
    }
    return Promise.resolve(false);
  }

  /**
   * Called when cancel is clicked. resets the state.
   * @returns {void}
   */
  onCancelClicked() {
    this.resetState();
    this.props.clearModelToEdit();
  }

  /**
   * Resets the state.
   * @returns {void}
   */
  resetState() {
    this.setState({
      isSaving: false,
      changesMade: false,
      attributes: {
        name: '',
        address: '',
        supplierIntegration: '',
        supplierId: '',
      },
      errorMessage: '',
    });
  }

  /**
   * Resets the model on props change and sets state to duplicate attributes for the incoming model
   * if there is one.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.modelToEdit !== nextProps.modelToEdit) {
      if (nextProps.modelToEdit) {
        const { modelToEdit } = nextProps;
        const integrations = modelToEdit.get('integrations', {});
        const supplierIntegrations = Object.keys(modelToEdit.get('integrations', {}));
        const supplierId = supplierIntegrations && supplierIntegrations.length > 0 &&
        this.props.config.getIn(['suppliersForIntegration'], List()).toJS().includes(supplierIntegrations[0]) ?
          integrations[supplierIntegrations[0]].id : '';
        this.setState({
          attributes: {
            name: modelToEdit.get('name', ''),
            address: modelToEdit.get('address', ''),
            supplierIntegration: supplierIntegrations && supplierIntegrations.length > 0 &&
            this.props.config.getIn(['suppliersForIntegration'], List()).toJS().includes(supplierIntegrations[0]) ?
              supplierIntegrations[0] : '',
            supplierId,
          },
          changesMade: false,
        });
      } else {
        this.resetState();
      }
    }
  }

  /**
   * validates to disable the supplier integration dropdown if already selected and saved
   * @returns {boolean}
   */
  isDisabled(): boolean {
    if (this.props.modelToEdit) {
      const supplierIntegrations = Object.keys(this.props.modelToEdit.get('integrations', {}));
      if (supplierIntegrations && supplierIntegrations.length > 0 &&
        this.props.config.getIn(['suppliersForIntegration'], List()).toJS().includes(supplierIntegrations[0])) {
        return true;
      }
      return false;
    }
    return false;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-form">
        <SavePrompt when={this.state.changesMade} onSaveClicked={() => this.onSaveClicked()} />
        {
          this.state.errorMessage &&
          <FormError containerElementID="right-sidebar">
            {this.state.errorMessage}
          </FormError>
        }
        <Input
          id="item_name"
          label={translate('name')}
          labelClassName="u-font-color-white"
          value={this.state.attributes.name}
          onValueChanged={
            name => this.setState({
              attributes: Object.assign(this.state.attributes, { name }),
              changesMade: true,
            })
          }
        />
        <TextArea
          id="item_address"
          label={translate('address')}
          labelClassName="u-font-color-white"
          value={this.state.attributes.address}
          onValueChanged={
            address => this.setState({
              attributes: Object.assign(this.state.attributes, { address }),
              changesMade: true,
            })
          }
        />
        { isKlinifyUser(this.props.user) && this.props.supplierType === 'supplier' &&
        <Select
          id="integration_name"
          label={translate('supplier_integration')}
          labelClassName="u-font-color-white"
          value={this.state.attributes.supplierIntegration}
          disabled={this.isDisabled()}
          options={this.props.config.getIn(['suppliersForIntegration'], List())
            .map(value => ({ value, label: translate(value) })).toArray()}
          onValueChanged={
            supplierIntegration => this.setState({
              attributes: Object.assign(this.state.attributes, { supplierIntegration }),
              changesMade: true,
            })
          }
        />
        }
        { isKlinifyUser(this.props.user) && this.props.supplierType === 'supplier' &&
        <Input
          id="integration_Id"
          label={translate('integration_id')}
          labelClassName="u-font-color-white"
          value={this.state.attributes.supplierId}
          onValueChanged={
            supplierId => this.setState({
              attributes: Object.assign(this.state.attributes, { supplierId }),
              changesMade: true,
            })
          }
        />
        }
        <SaveButton
          className="o-button--small u-margin-bottom--4ws"
          isSaving={this.state.isSaving}
          label={this.props.modelToEdit ? translate('save') : translate(`add_new_${this.props.supplierType ? this.props.supplierType : 'supplier'}`)}
          onClick={() => this.onSaveClicked()}
          fullWidth
        />
        {
          this.props.modelToEdit &&
          <Button
            onClick={() => this.onCancelClicked()}
            className="o-button o-button--small o-button--danger u-margin-top--standard"
            dataPublic
          >
            {translate('cancel')}
          </Button>
        }
      </div>
    );
  }
}

export default SupplierForm;
