import React from 'react';
import { List } from 'immutable';

import { fetchData } from './../utils/api';
import APIError from './../utils/apiError';
import translate from './../utils/i18n';

import LoadingIndicator from './loadingIndicator';

import type { HOCChild, DataView, Model } from './../types';

type Props = {
  currentDataViews: List<DataView>,
  containerDataViews: List<DataView>,
  isFetching: boolean,
  currentDataViewsError: APIError | null | undefined,
  showLoadingIndicator: boolean,
  setCurrentDataViews: (dataViews: List<DataView>) => void,
  setCurrentDataViewsModels: (models: List<Model>) => void,
  setIsFetching: (isFetching: boolean) => void,
  setCurrentDataViewsError?: (error: APIError | null | undefined) => void,
  onAsyncFetchComplete: (models: List<Model>) => void,
};

/**
 * Renders a loading / error / BaseComponent depending on the flags provided.
 * @param {boolean} showLoadingIndicator If false, rest of the flags are ignore, and the BaseComponent is rendered
 * @param {boolean} isFetching if true, loading component is rendered
 * @param {?APIError} currentDataViewsError If true, error component is rendered, with an error message
 * @param {HOCChild} BaseComponent Component to render if there are no errors
 * @param {boolean} props Props to pass to BaseComponent
 * @returns {React.Component} Loading component, or error or the HOCChild
 */
const displayComponent = (
  showLoadingIndicator: boolean,
  isFetching: boolean,
  currentDataViewsError: APIError | null | undefined,
  BaseComponent: HOCChild,
  props: Props,
): HOCChild => {
  if (!showLoadingIndicator) {
    return <BaseComponent {...props} />;
  }
  if (isFetching) {
    return <LoadingIndicator alignCenter style={{ marginTop: 'calc(50vh - 102px)', width: '100%' }} />;
    // 70px for top header and 32px is half of loading indicator's height
  } else if (currentDataViewsError) {
    if (currentDataViewsError.status === 404) {
      return <div>{translate('feature_unavailable_notification')}</div>;
    }
    return <div>{translate('request_could_not_be_completed_due_to_some_error')}</div>;
  }
  return <BaseComponent {...props} />;
};
/**
 * A HOC to handle async fetch of documents.
 * @param {HOCChild} BaseComponent The child component.
 * @returns {React.Component}
 */
const AsyncFetch = (BaseComponent: HOCChild) => (props: Props) => {
  if (!props.isFetching && (!props.currentDataViews
      || !props.currentDataViews.equals(props.containerDataViews))) {
    props.setIsFetching(true);
    props.setCurrentDataViews(props.containerDataViews);
    if (props.setCurrentDataViewsError) {
      props.setCurrentDataViewsError(null);
    }
    fetchData(props.containerDataViews)
      .then((models) => {
        if (models) { // empty models are okay
          props.setCurrentDataViewsModels(models);
          if (props.onAsyncFetchComplete) {
            props.onAsyncFetchComplete(models);
          }
        }
        props.setIsFetching(false);
      })
      .catch((err: APIError) => {
        if (props.setCurrentDataViewsError) {
          props.setCurrentDataViewsError(err);
        }
        props.setIsFetching(false);
      });
    return displayComponent(props.showLoadingIndicator, true, undefined, BaseComponent, props);
  }
  return displayComponent(props.showLoadingIndicator,
    props.isFetching,
    props.currentDataViewsError,
    BaseComponent,
    props);
};

export default AsyncFetch;
