/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import ReactTable, { Filter, SortingRule, SubComponentFunction, ReactTableFunction } from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import type {
  DerivedDataObject, FinalState, RowInfo, RowRenderProps,
  ComponentPropsGetterRC, Instance,
} from 'react-table';
import { isEqual } from 'lodash';

import { List } from 'immutable';
import { Moment } from 'moment';
import glamorous from 'glamorous';
import memoizeOne from 'memoize-one';
import translate from './../../utils/i18n';
import { tableBorder } from '../../utils/css';

import type { Column, CellProps, MapValue, TrProps } from './../../types';
import { logMessage } from './../../utils/logging';
import { filterDateRange } from '../../utils/filters';
import DateRangePicker from '../inputs/dateRangePicker';

if (process.env.NODE_ENV !== 'test') {
  require('react-table/react-table.css'); // eslint-disable-line global-require
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

// remember to include field `is_subrow` in case of aggregation, as react-table include
// aggregated row data in the sub row if there is no actual sub rows, above filed help in
// differentiating that.
type Data = { [key: string]: MapValue }

export type TableProps = {
  data: Array<Data>,
  apiSearch: boolean,
  headers: [string],
  noDataText: string,
  minRows?: number,
  showPagination?: boolean,
  defaultSorted?: Array<{ id: string, desc: boolean }>,
  loading?: boolean,
  defaultPageSize?: number,
  trProperties?: (state: MapValue, rowInfo: MapValue) => TrProps,
  filteredSortedDataHandler?: (filteredData: Array<Data>) => void,
  initialDataHandler?: (filteredData: Array<Data>) => void,
  tBodyProperties?: (state: MapValue) => TrProps,
  tableProperties?: (state: MapValue) => TrProps,
  noDataComponent?: () => (React.Component | null),
  fetchSearchData: (params: object) => void,
  onPageChange?: (pageIndex: number) => void,
  onPageSizeChange?: (pageSize: number, pageIndex: number) => void,
  className?: string,
  LoadingComponent?: React.ReactNode,
  filtered: Array<Filter>,
  pivotBy?: Array<string>,
  showFixedColumns?: boolean,
  expanded?: { [index: number]: boolean },
  getTdProps?: ComponentPropsGetterRC,
  onPivotRowClick?: (newExpanded:{ [index: number]: boolean | {} }, rowInfo: RowInfo) => void,
  sorted?: SortingRule[],
} | {
  columns: Array<Column>,
  headers?: [string],
  data: Array<Data>,
  apiSearch?: boolean,
  noDataText?: string,
  minRows?: number,
  showPagination?: boolean,
  defaultSorted?: Array<SortingRule>,
  loading?: boolean,
  defaultPageSize?: number,
  trProperties?: (state: MapValue, rowInfo: MapValue) => TrProps,
  filteredSortedDataHandler?: (filteredData: Array<Data>) => void,
  initialDataHandler?: (filteredData: Array<Data>) => void,
  tBodyProperties?: (state: MapValue) => TrProps,
  tableProperties?: (state: MapValue) => TrProps,
  subComponent?: SubComponentFunction,
  noDataComponent?: React.ReactType,
  fetchSearchData?: (params: object) => void,
  onPageChange?: (pageIndex: number) => void,
  onPageSizeChange?: (pageSize: number, pageIndex: number) => void,
  className?: string,
  LoadingComponent?: React.ReactType,
  filtered?: Array<Filter>,
  pivotBy?: Array<string>,
  showFixedColumns?: boolean,
  expanded?: { [index: number]: boolean },
  getTdProps?: ComponentPropsGetterRC,
  onPivotRowClick?: (newExpanded:{ [index: number]: boolean| {} }, rowInfo: RowInfo) => void,
  sorted?: SortingRule[],
}

type State = {
  filteredDataLength?: number,
  filteredSortedDataLength?: number,
};

const TableStyleWrapper = glamorous.div(
  {
    '& .rt-thead.-filters .rt-th .DateRangePicker': {
      margin: 0,
    },
    '& .rt-thead.-filters .rt-th .DateRangePickerInput': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
);

/**
 * Creates a Table component.
 * @param {any} props The props.
 * @returns {React.Component} Table component.
 */
class Table extends Component<TableProps, State> {
  reactTable: ReactTable<TableProps>;

  static defaultProps = {
    showPagination: false,
  };

  /**
   * Creates an instance of table.
   * @param {Props} props Props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filteredDataLength: undefined,
      filteredSortedDataLength: undefined,
    };
  }

  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.getInitialData();
  }

  /**
   * Called functions after component fully rendered
   * @returns {void}
   */
  componentDidUpdate() {
    this.getInitialData();
  }

  getTdProps: ComponentPropsGetterRC = (
    state: FinalState,
    rowInfo: RowInfo,
    column: Column,
    instance: Instance,
  ) => {
    const { pivotBy, expanded, onPivotRowClick, getTdProps } = this.props;
    const tdProps = (getTdProps && getTdProps(state, rowInfo, column, instance)) as {[key: string]: any} | undefined;
    const tdPropsStyle = tdProps?.style;
    const { onClick, style, ...rest } = tdProps ? Object.assign({}, tdProps?.onClick && { onClick: tdProps.onClick },
      tdPropsStyle && { style: tdPropsStyle }) : {} as any;
    if (typeof rowInfo !== 'undefined' && pivotBy && pivotBy.length > 0 && expanded) {
      const needsExpander = Boolean(rowInfo.subRows?.find(r => r._original.is_subrow));
      const expanderEnabled = !column.disableExpander;
      const expandedRows = Object.keys(expanded)
        .filter(expandedIndex =>
          // expandedIndex is the index of row and will be number always
          // @ts-ignore
          this.props.expanded[expandedIndex] !== false)
        .map(Number);
      const rowIsExpanded =
        !!(expandedRows.includes(rowInfo.nestingPath[0]) && needsExpander);
      const newExpanded = !needsExpander
        ? this.props.expanded
        : rowIsExpanded && expanderEnabled
          ? {
            ...this.props.expanded,
            [rowInfo.nestingPath[0]]: false,
          }
          : {
            ...this.props.expanded,
            [rowInfo.nestingPath[0]]: {},
          };

      const pivotStyle = needsExpander && expanderEnabled
        ? { cursor: 'pointer' }
        : { cursor: 'auto' };
      return {
        style: { ...tdPropsStyle, ...pivotStyle },
        onClick: () => {
          if (onPivotRowClick && newExpanded) {
            onPivotRowClick(newExpanded, rowInfo);
          }
          if (onClick) {
            onClick();
          }
        },
        ...rest,
      };
    }
    return {
      onClick: onClick || ((e: Event, handleOriginal: Function) => {
        if (handleOriginal) {
          handleOriginal();
        }
      }),
      style,
      ...rest,
    };
  }

  /**
   * Get table initial data
   * @returns {void}
   */
  getInitialData() {
    if (this.reactTable && this.reactTable.getResolvedState() &&
      this.reactTable.getResolvedState().sortedData) {
      const { initialDataHandler } = this.props;
      if (initialDataHandler &&
        this.state.filteredSortedDataLength !== this.reactTable.getResolvedState().sortedData.length
      ) {
        this.setState({ filteredSortedDataLength: undefined });
        if (!this.state.filteredSortedDataLength) {
          this.setState({
            filteredSortedDataLength: this.reactTable.getResolvedState().sortedData.length,
          });
        }
        const initalData = this.reactTable.getResolvedState()
          .sortedData.map((data:DerivedDataObject) => {
            if (data._aggregated) {
              const [originalData, haveSubrows] =
                data._subRows?.reduce(([subRowData, haveSubrow], r: DerivedDataObject) => {
                  const dataHaveSubrows = haveSubrow || r._original.is_subrow;
                  return [subRowData.push(r._original), dataHaveSubrows];
                }, [List(), false]);
              if (haveSubrows) {
                return originalData ? originalData
                  .unshift(Object.assign({ ...data, is_subrow: false })).toArray() : [];
              }
              return originalData ? originalData.toArray() : [];
            }
            return data._original;
          }).flat();
        initialDataHandler(
          initalData,
        );
      }
    }
  }

  /**
   * This method takes array of row objects of latest table and returns them in the same format as data
   * passed in properties. This is called if property filteredSortedDataHandler is passed to <Table> component.
   * Use this property when there is a need to get back filtered/sorted data from table and use it for print/export.
   * @param {Array<Data>} resolvedData data having array of row object after filtered or sorted
   * @returns {string}
   */
  returnFilteredSortedData(resolvedData: Array<Data>): void {
    const { filteredSortedDataHandler } = this.props;
    if (filteredSortedDataHandler !== undefined && resolvedData !== undefined) {
      if (this.state.filteredSortedDataLength !== resolvedData.length) {
        this.setState({ filteredSortedDataLength: resolvedData.length });
      }
      const sortedData = resolvedData.map((data:DerivedDataObject) => {
        if (data._aggregated) {
          if (data._aggregated) {
            const [originalData, dataHaveSubrows] =
              data._subRows?.reduce(([subRowData, haveSubrow], r: DerivedDataObject) => {
                const haveSubrows = haveSubrow || r._original.is_subrow;
                return [subRowData.push(r._original), haveSubrows];
              }, [List(), false]);
            if (dataHaveSubrows) {
              return originalData ? originalData
                .unshift(Object.assign({ ...data, is_subrow: false })).toArray() : [];
            }
            return originalData ? originalData.toArray() : [];
          }
        }
        return data._original;
      }).flat();
      filteredSortedDataHandler(sortedData);
    }
  }

  /**
   * Render expander based on the data subRows and `is_subrow` field
   * @param {Array<Column>} columns columns
   * @returns {Array<Column>}
   */
  getExpanderAction(columns: Array<Column>) {
    const { pivotBy } = this.props;
    if (pivotBy && pivotBy.length) {
      return columns.map((column: Column) => {
        if (pivotBy.includes(column.accessor)) {
          return Object.assign({
            ...column,
            Expander: ({ subRows, isExpanded }: RowRenderProps) => {
              const haveSubrows = subRows?.find((r: DerivedDataObject) => r._original.is_subrow);
              if (haveSubrows) {
                return <div className={`rt-expander ${isExpanded ? '-open' : ''}`}>&bull;</div>;
              }
              return null;
            },
            getProps: (state: FinalState, rowInfo: RowInfo) => {
              const { subRows } = rowInfo;
              const haveSubrows = subRows?.find(r => r._original.is_subrow);
              if (rowInfo && subRows && !haveSubrows) {
                return { onClick: () => null };
              }
              return {};
            },
          });
        }
        return column;
      });
    }
    return columns;
  }

  /**
   * Render special Filter Components and filter methods based on `filterBy` field in columns
   * @returns {Array<Column>}
   */
  setFilter = memoizeOne((columns: Array<Column>) => columns.map((col) => {
    switch (col.filterBy) {
      case 'date_range':
        return {
          ...col,
          filterMethod: col.filterMethod || filterDateRange,
          Filter:
            col.Filter ||
            (({
              filter,
              onChange,
            }: {
              filter: any;
              onChange: ReactTableFunction;
            }) => (
              <DateRangePicker
                className="u-full-width"
                id="filter-dates"
                startDate={filter?.value?.startDate}
                endDate={filter?.value?.endDate}
                onValueChanged={(value: {
                  startDate: Moment | null;
                  endDate: Moment | null;
                }) => {
                  onChange(value);
                }}
                isClearable
                block
                appendToBody
              />
            )),
        };
      default:
        return col;
    }
  }), ([prevCols], [currentCols]) => {
    const previousColmns = prevCols
      .map(({ accessor, filterBy }: Column) => ({ accessor, filterBy }));
    const currentColumns = currentCols
      .map(({ accessor, filterBy }: Column) => ({ accessor, filterBy }));
    return isEqual(previousColmns, currentColumns);
  });

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    // If headers is given it overrides columns.
    let columns: Array<Column> = this.props.columns ||
      this.props.headers.map(h => ({ Header: translate(h), accessor: h }));
    if (this.props.data.length || this.props.apiSearch) {
      // If no specified render function we need to wrap it in a div to get proper margins.
      columns = this.setFilter(this.getExpanderAction(columns)).map((column: Column) => {
        let columnProps = {};
        if (!column.Cell) {
          columnProps = {
            ...columnProps,
            Cell: ({ value }: CellProps) => (
              <div className={column.Header.trim().length ? 'o-table__cell' : 'o-table__cell o-table__cell--unbordered'}>{value}</div>
            ),
          };
        }
        if (column.Header && typeof column.Header === 'string') {
          // If no specified render function for Header, we need to wrap it in the div with the following classname for cell borders
          columnProps = {
            ...columnProps,
            Header: () => {
              const { Header } = column;
              return (<div className={column.Header.trim().length ? 'o-table__header' : 'o-table__header o-table__header--unbordered'}>{Header}</div>);
            },
          };
        }
        return { ...column, ...columnProps };
      });
    } else {
      // No data so hide headers.
      columns = columns.map((column: Column) => Object.assign({}, column, { show: false }));
    }
    let defaultPageSize = 100;
    if (this.props.defaultPageSize) {
      ({ defaultPageSize } = this.props);
    } else if (this.props.showPagination) {
      defaultPageSize = 20;
    }
    const SubComponent = this.props.subComponent ? {
      SubComponent: this.props.subComponent,
    } : {};
    const NoDataView = this.props.noDataText ? {
      noDataText: this.props.noDataText,
    } : { NoDataComponent: this.props.noDataComponent };
    const TableComponent = this.props.showFixedColumns ? ReactTableFixedColumns : ReactTable;

    const ref = this.props.showFixedColumns ? { innerRef: (rt) => { this.reactTable = rt; } } : { ref: (rt) => { this.reactTable = rt; } };
    const table =
      (<TableComponent
        {...ref}
        data={this.props.data}
        columns={columns}
        sorted={this.props.sorted}
        filtered={this.props.filtered}
        defaultFilterMethod={(filter, row) => {
          const filterWords: Array<string | null> = filter.value.toLowerCase().split(' ');
          if (row[filter.id]) {
            if (Array.isArray(row[filter.id])) {
              return filterWords.every(word => row[filter.id].join(' ').toLowerCase().indexOf(word) !== -1);
            }
            if (typeof row[filter.id] === 'string') {
              return filterWords.every(word => row[filter.id].toLowerCase().indexOf(word) !== -1);
            }
            logMessage(`row[filter.id] ain't a suitable data to filter ${row[filter.id]}`);
            return false;
          }
          return false;
        }}
        defaultSorted={this.props.defaultSorted}
        showPagination={
        this.state.filteredDataLength !== undefined
          ? this.state.filteredDataLength > 5
          : (this.props.showPagination && this.props.data.length && this.props.data.length > 5) ||
            this.props.data.length >= 100
      } // If data size is over 100 always show pagination.
        minRows={this.props.minRows || 0}
        defaultPageSize={defaultPageSize}
        style={{ minWidth: 400 }}
        PadRowComponent={() => <span className="o-table__cell">&nbsp;</span>}
        onFilteredChange={
        (change) => {
          if (this.props.apiSearch && Array.isArray(change)) {
            this.props.fetchSearchData(change);
          }
          const resolvedData = this.reactTable && this.reactTable.getResolvedState() ?
            this.reactTable.getResolvedState().sortedData : undefined;
          this.setState({
            filteredDataLength: resolvedData !== undefined ? resolvedData.length : undefined,
          });
          if (this.props.filteredSortedDataHandler && resolvedData !== undefined &&
            resolvedData.length >= 0) {
            this.returnFilteredSortedData(resolvedData);
          }
        }
      }
        onSortedChange={
        () => {
          const resolvedData = this.reactTable && this.reactTable.getResolvedState() ?
            this.reactTable.getResolvedState().sortedData : undefined;
          if (this.props.filteredSortedDataHandler && resolvedData !== undefined &&
            resolvedData.length >= 0) {
            this.returnFilteredSortedData(resolvedData);
          }
        }
      }
        loading={this.props.loading}
        getTrProps={this.props.trProperties}
        getTableProps={this.props.tableProperties}
        getTbodyProps={this.props.tBodyProperties}
        {...NoDataView}
        {...SubComponent}
        onPageChange={this.props.onPageChange}
        onPageSizeChange={this.props.onPageSizeChange}
        className={this.props.className}
        getTheadFilterThProps={(state, rowInfo, column) => {
          if (column?.Header) {
            return {
              style: {
                borderLeft: tableBorder,
              },
            };
          }
          return {};
        }}
        LoadingComponent={this.props.LoadingComponent}
        pivotBy={this.props.pivotBy}
        expanded={this.props.expanded}
        getTdProps={this.getTdProps}
      />
      );
    if (columns.some(col => col.filterBy === 'date_range')) {
      return (
        <TableStyleWrapper>
          {table}
        </TableStyleWrapper>
      );
    }
    return table;
  }
}

export default Table;
