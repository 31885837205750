import { connect } from 'react-redux';

import PatientBillingHistory from './../components/billing/patientBillingHistory';
import { saveModelsFactory } from './../utils/redux';
import { updateInventoryItemCount } from './../actions';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State, { patientID }: { patientID: string}) => {
  const {
    salesItems, coveragePayors, patientStubs, user, drugs, activeIngredients,
  } = state;
  return {
    patient: state.patients.find(p => p.get('_id') === patientID),
    bills: state.bills.filter(m => m.get('patient_id') === patientID && m.isFinalised()),
    encounters: state.encounters.filter(m => m.get('patient_id') === patientID),
    medicalCertificates: state.medicalCertificates.filter(m => m.get('patient_id') === patientID),
    patientStub: patientStubs.find(m => m.get('_id') === patientID),
    salesItems,
    coveragePayors,
    user,
    drugs,
    activeIngredients,
  };
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
  updateInventoryItemCount: (skuID: string, change: number) =>
    dispatch(updateInventoryItemCount(skuID, change)),
});

const PatientBillingHistoryContainer =
  connect(mapStateToProps, mapDispatchToProps)(PatientBillingHistory);

export default PatientBillingHistoryContainer;
