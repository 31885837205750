import React, { Fragment } from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';

import { UNICODE } from '../../constants';
import translate from './../../utils/i18n';
import { wsUnit, border } from './../../utils/css';
import { prettifyTime, prettifyDate } from './../../utils/time';
import { createPermission } from './../../utils/permissions';
import { convertNumberToPrice } from '../../utils/utils';
import PermissionWrapper from './../permissions/permissionWrapper';
import ConsultationSalesItems from './consultationSalesItems';
import ConsultationLabRequests from './consultationLabRequests';
import PrescriptionList from './../prescriptions/prescriptionList';
import Button from './../buttons/button';

import type BillModel from './../../models/billModel';
import type BillItemModel from './../../models/billItemModel';
import type ConditionModel from './../../models/conditionModel';
import type DrugModel from './../../models/drugModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type PractitionerModel from './../../models/practitionerModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type ProcedureTypeModel from '../../models/procedureTypeModel';
import type ProcedureRequestModel from '../../models/procedureRequestModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type EncounterModel from './../../models/encounterModel';
import StatelessModal from './../modals/statelessModal';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, SaveModel, SaveModels, User, InventoryCount, MapValue } from './../../types';
import type DocumentDataModel from '../../models/documentDataModel';
import type DocumentTemplateModel from '../../models/documentTemplateModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import InventoryMapModel from './../../models/inventoryMapModel';
import AllergyModel from './../../models/allergyModel';
import DosingRegimenModel from '../../models/dosingRegimenModel';

type Props = {
  bills: List<BillModel>,
  billItems: List<BillItemModel>,
  encounters: List<EncounterModel>,
  practitioners: List<PractitionerModel>,
  config: Config,
  klinifyConfig: Config,
  drugs: List<DrugModel>,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  prescriptions: List<PrescriptionModel>,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
  salesItems: List<SalesItemModel>,
  conditions: List<ConditionModel>,
  documentTemplates: List<DocumentTemplateModel>,
  documentData: List<DocumentDataModel>,
  isOnline: boolean,
  inventoryCount: InventoryCount,
  showLowStockWarning: boolean,
  coveragePayorID?: string,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
  onClose: () => void,
  encounterID: string,
  coveragePayors: List<CoveragePayorModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  verifiedDrugs: List<InventoryMapModel>,
  allergies: List<AllergyModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  dosingRegimens: List<DosingRegimenModel>,
  patientPrescriptionHistory: List<PrescriptionModel>,
};

type State = {
  showModal: boolean
};

const ModalContent = glamorous.div({
  padding: `${wsUnit}`,
  display: 'flex',
  flexDirection: 'column',
  '& .c-item-list__item__info': {
    border,
  },
  '& .o-data-list__row__item': {
    marginRight: `calc(${wsUnit} / 2)`,
  },
  '& .c-sales-item-conatiner': {
    marginBottom: '1em',
  },
  '& .wider-column-name': {
    flexBasis: '40%',
  },
  '& .wider-column-qty': {
    flexBasis: '7%',
  },
});

const DetailsRow = glamorous.div({
  display: 'flex',
  marginBottom: `calc(${wsUnit} / 4)`,
  fontSize: '0.9rem',
});

const RightAlign = {
  justifyContent: 'flex-end',
};

const Label = glamorous.span({
  minWidth: 150,
  fontFamily: '\'robotomedium\'',
  textTransform: 'uppercase',
});

const FeeLabel = glamorous.span({
  fontFamily: '\'robotomedium\'',
});

const Value = glamorous.span({
  marginLeft: `calc(${wsUnit} / 2)`,
  display: 'flex',
  whiteSpace: 'pre-wrap',
  '& b': {
    fontWeight: 'bold',
    marginLeft: `calc(${wsUnit} / 4)`,
  },
  '& p': {
    marginLeft: `calc(${wsUnit} / 4)`,
  },
  '& .c-long-margin': {
    marginLeft: `calc(${wsUnit} * 1.25)`,
  },
});

/**
 * A modal popup for displaying consultation history details when a row in encounter history table is clicked
 * @class ConsultationHistoryDetails
 * @extends {React.PureComponent<Props, State>}
 */
class ConsultationHistoryDetails extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of ConsultationHistoryDetails.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: true, // modal displayed by default
    };
  }

  /**
   * Gets a string representing a diagnosis details for the encounter. Will return an empty string if it
   * can't be found.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {string}.
   */
  getDiagnosis(encounter: EncounterModel): string {
    return this.props.conditions
      .filter(c => c.get('encounter_id') === encounter.get('_id') && c.isVisible() && c.isDiagnosis())
      .map(s => s.get('name')).toArray().toString()
      .replace(/,/g, ', ') || UNICODE.EMDASH;
  }

  /**
   * Gets a string representing a symptoms details for the encounter. Will return an empty string if it
   * can't be found.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {string}.
   */
  getSymptoms(encounter: EncounterModel): string {
    return this.props.conditions
      .filter(c => c.get('encounter_id') === encounter.get('_id') && c.isVisible() && c.isSymptom())
      .map(s => s.get('name')).toArray().toString()
      .replace(/,/g, ', ') || UNICODE.EMDASH;
  }

  /**
   * Gets a list of prescriptions matching the encounter used in the modal.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {List<PrescriptionModel>}.
   */
  getPrescriptions(encounter: EncounterModel): List<PrescriptionModel> {
    return this.props.prescriptions
      .filter(prescription => prescription.get('encounter_id') === encounter.get('_id'));
  }

  /**
   * Hides the modal when close is clicked.
   * @returns {void}
   */
  handleClose = (): void => {
    this.setState({ showModal: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  /**
   * Returns the encounter matching encounter id passed, this is the encounter used for details display
   * @returns {EncounterModel}.
   */
  getEncounter(): EncounterModel {
    return this.props.encounters
      .find(e => e.get('_id') === this.props.encounterID);
  }

  /**
   * Gets the medical certificate related details matching the encounter passed.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {MedicalCertificateModel}.
   */
  getMedicalCertificate(encounter: EncounterModel): MedicalCertificateModel {
    return this.props.medicalCertificates
      .find(medicalCertificate => medicalCertificate.get('encounter_id') === encounter.get('_id'));
  }

  /**
   * Gets the documentdata models matching the encounter passed.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {List<DocumentDataModel>}.
   */
  getDocumentData(encounter: EncounterModel): List<DocumentDataModel> {
    return this.props.documentData
      .filter(dd => dd.get('encounter_id') === encounter.get('_id'));
  }

  /**
   * Gets the time chit related details matching the encounter passed.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {TimeChitModel}.
   */
  getTimeChit(encounter: EncounterModel): TimeChitModel {
    return this.props.timeChits
      .find(timeChit => timeChit.get('encounter_id') === encounter.get('_id'));
  }

  /**
   * Gets list of bill items matching the encounter passed and item name/type passed.
   * @param {EncounterModel} encounter Encounter model.
   * @param {string} name name of bill item type.
   * @returns {List<BillItemModel>}.
   */
  getSalesItems(encounter: EncounterModel, name: string): List<BillItemModel> {
    const bill = this.props.bills
      .find(model => model.get('encounter_id') === encounter.get('_id'));
    if (bill) {
      const billItems = this.props.billItems.filter(i => i.get('bill_id') === bill.get('_id'));
      if (name === 'prescriptions') {
        return billItems.filter(item => item.isPrescription());
      }
      return billItems.filter(item => item.isSalesItem());
    }
    return List();
  }

  /**
   * Gets total fee for the encounter using bill model.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {string}.
   */
  getTotalFee(encounter: EncounterModel): string {
    const bill = this.props.bills
      .find(model => model.get('encounter_id') === encounter.get('_id'));
    if (bill) {
      if (bill.isVoid()) {
        return `${UNICODE.EMDASH}`;
      }
      const fees = convertNumberToPrice(bill.get('total_amount', ''));
      if (fees === this.props.config.get('currency_shorthand_label', 'RM')) {
        return `${UNICODE.EMDASH}`;
      }
      return fees;
    }
    return UNICODE.EMDASH;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const encounter = this.getEncounter();
    const mc = this.getMedicalCertificate(encounter);
    const tc = this.getTimeChit(encounter);
    const documentData = this.getDocumentData(encounter);
    const encounterNotes = encounter.getNotes(this.props.practitioners, this.props.encounterStageMap);

    return (
      <StatelessModal
        noButton
        id="encounter-history-details"
        buttonLabel=""
        buttonClass=""
        title={`${this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter')} ${translate('history')}`}
        visible={this.state.showModal}
        setVisible={this.handleClose}
        explicitCloseOnly={false}
        onClose={this.handleClose}
        dataPublicHeader
        large
      >
        <ModalContent>
          <DetailsRow>
            <Label>{translate('date_and_time')}:</Label>
            <Value>
              {prettifyDate(encounter.getStartTime())} / {prettifyTime(encounter.getStartTime())}
            </Value>
          </DetailsRow>
          <DetailsRow>
            <Label>{translate('encounter_type')}:</Label>
            <Value>{encounter.getEncounterType(this.props.salesItems)}</Value>
          </DetailsRow>
          <DetailsRow>
            <Label>{translate('encounter_status')}:</Label>
            <Value>{translate(encounter.getLastEventType())}</Value>
          </DetailsRow>
          <DetailsRow>
            <Label>{translate('doctor')}:</Label>
            <Value>{encounter.getDoctorName(this.props.practitioners, true)}</Value>
          </DetailsRow>
          <PermissionWrapper
            permissionsRequired={List([createPermission('past_encounters_conditions', 'read')])}
            user={this.props.user}
          >
            <hr />
            <DetailsRow>
              <Label>{translate('symptoms')}:</Label>
              <Value>{this.getSymptoms(encounter)}</Value>
            </DetailsRow>
            <DetailsRow>
              <Label>{translate('diagnosis')}:</Label>
              <Value>{this.getDiagnosis(encounter)}</Value>
            </DetailsRow>
          </PermissionWrapper>
          <PermissionWrapper
            permissionsRequired={List([createPermission('past_encounters_notes', 'read')])}
            user={this.props.user}
          >
            {typeof encounterNotes === 'string'
              ?
                <DetailsRow>
                  <Label>{translate('encounter_notes')}:</Label>
                  <Value>{encounterNotes || UNICODE.EMDASH}</Value>
                </DetailsRow>
              :
              Object.keys(encounterNotes).map(stageName => (
                <DetailsRow>
                  <Label>{`${stageName} ${translate('notes')}`}:</Label>
                  <Value className="u-flex-1">{encounterNotes[stageName] || UNICODE.EMDASH}</Value>
                </DetailsRow>
              ))}
          </PermissionWrapper>
          <PermissionWrapper
            permissionsRequired={List([createPermission('document_templates', 'read')])}
            user={this.props.user}
          >
            {
              documentData.size > 0 &&
              <Fragment>
                <hr />
                <DetailsRow>
                  <Label>{translate('documents')}:</Label>
                  {
                    documentData.map(dd =>
                      <Value>
                        <Button
                          className="o-text-button o-text-button--contextual"
                          onClick={() => dd.downloadDocument(this.props.documentTemplates
                            .find(dt => dt.get('_id') === dd.get('document_template_id'))
                            .get('asset_id'))}
                          dataPublic
                        >
                          {dd.getName()}
                        </Button>
                      </Value>)
                  }
                </DetailsRow>
                <hr />
              </Fragment>
            }
          </PermissionWrapper>
          <PermissionWrapper
            permissionsRequired={List([createPermission('past_encounters_prescriptions', 'read')])}
            user={this.props.user}
          >
            <hr />
            <PrescriptionList
              useTextButton
              coveragePayorID={this.props.coveragePayorID}
              config={this.props.config}
              klinifyConfig={this.props.klinifyConfig}
              drugs={this.props.drugs}
              dosingRegimens={this.props.dosingRegimens}
              encounter={encounter}
              encounters={this.props.encounters}
              practitioners={this.props.practitioners}
              prescriptions={this.getPrescriptions(encounter)}
              saveModel={this.props.saveModel}
              saveModels={this.props.saveModels}
              coveragePayors={this.props.coveragePayors}
              isOnline={this.props.isOnline}
              inventoryCount={this.props.inventoryCount}
              showLowStockWarning={this.props.showLowStockWarning}
              user={this.props.user}
              hidePrescriptionsButtonLabel
              hideTotal
              updateConfigValue={this.props.updateConfigValue}
              updateConfig={this.props.updateConfig}
              verifiedDrugs={this.props.verifiedDrugs}
              allergies={this.props.allergies}
              patientPrescriptionHistory={this.props.patientPrescriptionHistory}
            />
          </PermissionWrapper>
          <PermissionWrapper
            permissionsRequired={List([createPermission('past_encounters_medical_certificates',
              'read')])}
            user={this.props.user}
          >
            <hr />
            <DetailsRow>
              <Label>{translate('MC')}:</Label>
              {
                mc && mc.get('reason') ?
                  (
                    <Value>
                      <b>{mc.get('days')}</b>
                      <p>{mc.get('days') > 1 ? translate('days') : translate('day')}</p>
                      <p>{translate('from')}</p>
                      <b>{mc.getStartDate()}</b>
                      <p>{translate('to')}</p>
                      <b>{mc.getEndDate()}</b>
                    </Value>
                  )
                  : <Value>{UNICODE.EMDASH}</Value>
              }
            </DetailsRow>
            <DetailsRow>
              <Label />
              {
                mc && mc.get('reason') ?
                  (
                    <Value>
                      <p className="c-long-margin" />
                      <p>{translate('patient_is_unfit_for')}</p>
                      <b>{mc.get('reason')}</b>
                    </Value>
                  )
                  : <Value />
              }
            </DetailsRow>
            <DetailsRow>
              <Label>{translate('notes')}:</Label>
              <Value>{mc ? mc.get('notes', UNICODE.EMDASH, false) : UNICODE.EMDASH}</Value>
            </DetailsRow>
          </PermissionWrapper>
          <PermissionWrapper
            permissionsRequired={List([createPermission(
              'past_encounters_time_chits',
              'read',
            )])}
            user={this.props.user}
          >
            <hr />
            <DetailsRow>
              <Label>{translate('time_chit')}:</Label>
              {
                tc && tc.get('start_time') ?
                  (
                    <Value>
                      <p>{translate('from')}</p>
                      <b>{prettifyTime(tc.get('start_time'))}</b>
                      <p>{translate('to')}</p>
                      <b>{prettifyTime(tc.get('end_time'))}</b>
                    </Value>
                  )
                  : <Value>{UNICODE.EMDASH}</Value>
              }
            </DetailsRow>
            <DetailsRow>
              <Label>{translate('notes')}:</Label>
              <Value>{tc ? tc.get('notes', UNICODE.EMDASH, false) : UNICODE.EMDASH}</Value>
            </DetailsRow>
          </PermissionWrapper>
          <PermissionWrapper
            permissionsRequired={List([createPermission(
              'finalised_bill',
              'read',
            )])}
            user={this.props.user}
          >
            <hr />
            <ConsultationSalesItems
              drugs={this.props.drugs}
              salesItems={this.props.salesItems}
              billItems={this.getSalesItems(encounter, 'prescriptions')}
              headerText={translate('dispensed_prescription_items')}
              noDataText={translate('no_medication_dispensed')}
            />
            <ConsultationSalesItems
              drugs={this.props.drugs}
              salesItems={this.props.salesItems}
              billItems={this.getSalesItems(encounter, 'salesItems')}
              headerText={translate('sales_items')}
              noDataText={translate('no_sales_items')}
            />
            <ConsultationLabRequests
              procedureTypes={this.props.procedureTypes}
              procedureRequests={this.props.procedureRequests}
              headerText={translate('lab_tests')}
              noDataText={translate('no_lab_tests_ordered')}
            />
            <hr />
            <DetailsRow style={RightAlign}>
              <FeeLabel>{translate('total_fee')}:</FeeLabel>
              <Value>{this.getTotalFee(encounter)}</Value>
            </DetailsRow>
          </PermissionWrapper>
        </ModalContent>
      </StatelessModal>
    );
  }
}

export default ConsultationHistoryDetails;
