import React from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import { getCoveragePolicyName, getMaxClaimableAmountForBill } from './../../utils/coveragePayors';
import IssuedItemsList from './../issuedItems/issuedItemsList';
import BillSummaryCost from './billSummaryCost';
import TextArea from './../inputs/textarea';
import Input from './../inputs/input';
import FormError from './../formError';
import { convertNumberToPrice } from './../../utils/utils';
import { border, wsUnit } from './../../utils/css';
import { prettifyTime } from './../../utils/time';
import { calculateCoveragePayorTotal, calculatePatientTotal, calculatePaymentsTotal } from './../../utils/billing';
import Button from './../buttons/button';
import { UNICODE } from '../../constants';

import type { Config, User } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DrugModel from './../../models/drugModel';
import type EncounterModel from './../../models/encounterModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type BillItemModel from './../../models/billItemModel';
import type PaymentModel from './../../models/paymentModel';
import type DocumentDataModel from './../../models/documentDataModel';
import type { Attributes as BillAttributes } from './../../models/billModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type PractitionerModel from '../../models/practitionerModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import { debugPrint } from '../../utils/logging';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission } from '../../utils/permissions';
import DiscountChargeModel from '../../models/discountChargeModel';

type Props = {
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  config: Config,
  coveragePayor: CoveragePayorModel | null | undefined,
  coveragePayorPolicy: string | null | undefined,
  patient: PatientModel | PatientStubModel,
  coveragePayors: List<CoveragePayorModel>,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  medicalCertificates: List<MedicalCertificateModel>,
  onCancelClicked: () => void,
  onFinaliseClicked: () => void,
  salesItems: List<SalesItemModel>,
  timeChits: List<TimeChitModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  user: User,
  documentData: List<DocumentDataModel>,
  documentTemplates: List<DocumentTemplateModel>,
  payments: List<PaymentModel>,
  practitioners: List<PractitionerModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  isAddPastBill: boolean,
  usedDiscountsCharges: List<DiscountChargeModel>,
};

const PaymentLineItem = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  width: '50%',
  marginLeft: 'auto',
  marginTop: wsUnit,
  gridColumnGap: `calc(${wsUnit} / 2)`,
});

/**
 * A component containing a Modal which displays the bill summary prior to finalisation.
 * @class BillFinalisationSummary
 * @extends {React.Component}
 */
class BillFinalisationSummary extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering BillFinalisationSummary');
    const { payments, billAttributes, billItems, coveragePayor, coveragePayors } = this.props;
    const panelName = coveragePayor ? coveragePayor.get('name') :
      this.props.config.getIn(['patient', 'labels', 'coverage', 'no_coverage'], 'No Panel (Cash)');

    const policyName = getCoveragePolicyName(this.props);
    const amountOwedToClinic = calculatePatientTotal(billAttributes, billItems, this.props.usedDiscountsCharges)
      - calculatePaymentsTotal(payments);
    return (
      <StatelessModal
        id="billFinalisationSummary"
        visible
        onClose={() => this.props.onCancelClicked()}
        setVisible={() => this.props.onCancelClicked()}
        title={translate('review_bill')}
        dataPublicHeader
      >
        <p className="u-font-color--red u-margin--standard">{translate('review_bill_summary')}</p>
        {
          this.props.encounter.isPastAndIncomplete() &&
          <p id="completion-time-warning" className="u-font-color--red u-margin--standard u-strong">
            {`The completion time for this encounter will be set at ${this.props.encounter.getDate()}, ${prettifyTime(this.props.encounter.getLastEventTime())}.`}
          </p>
        }
        <div className="o-card o-card--no-shadow">
          <PermissionWrapper permissionsRequired={List([createPermission('past_encounters_notes', 'read')])} user={this.props.user}>
            <TextArea
              id="consult-notes"
              label={`${this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter')} ${translate('notes')}`}
              value={this.props.encounter.getNotes(this.props.practitioners, this.props.encounterStageMap, true) || UNICODE.EMDASH}
              disabled
            />
          </PermissionWrapper>
          <h2 data-public className="o-card__title o-card__title--no-bg o-card__title--no-side-padding o-card__title--small">
            {translate('issued_items')}
          </h2>
          <div className="u-border">
            <IssuedItemsList
              medicalCertificates={this.props.medicalCertificates}
              documentData={this.props.documentData}
              timeChits={this.props.timeChits}
              config={this.props.config}
              disableChanges
              hideButtons
              showEmptyItems
              saveModel={model => Promise.resolve(model)} // Wont be used
              user={this.props.user}
              documentTemplates={this.props.documentTemplates}
            />
          </div>
        </div>
        <div className="o-card o-card--no-shadow">
          <h2 className="o-card__title o-card__title--no-bg o-card__title--no-side-padding o-card__title--small">
            {translate('consultation_cost', { encounterLabel: this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter') })}
          </h2>
          <div className="u-border">
            {
              this.props.coveragePayor &&
              <h2 className="o-card__title">{translate('claimable_items')}</h2>
            }
            {
              this.props.coveragePayor &&
              <BillSummaryCost
                drugs={this.props.drugs}
                salesItems={this.props.salesItems}
                items={billItems
                  .filter(i => i.isClaimed(this.props.coveragePayor !== undefined))}
                disableScrolling
                procedureTypes={this.props.procedureTypes}
                providers={this.props.providers}
                usedDiscountsCharges={this.props.usedDiscountsCharges}
              />
            }
            <h2 data-public className="o-card__title">{translate('unclaimable_items')}</h2>
            <BillSummaryCost
              drugs={this.props.drugs}
              salesItems={this.props.salesItems}
              items={billItems
                .filter(i => !i.isClaimed(this.props.coveragePayor !== undefined))}
              disableScrolling
              procedureTypes={this.props.procedureTypes}
              providers={this.props.providers}
              usedDiscountsCharges={this.props.usedDiscountsCharges}
            />
          </div>
          {
            this.props.coveragePayor &&
            <Input
              id="bill_finalisation_co_payment"
              divClassName="u-margin-top--standard"
              value={convertNumberToPrice(this.props.billAttributes.co_payment || 0)}
              disabled
              label={translate('co_payment')}
              style={{
                width: '50%',
                marginLeft: 'auto',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            />
          }
          {
            this.props.coveragePayor &&
            <Input
              id="bill_finalisation_panel_coverage"
              value={
                this.props.coveragePayor ?
                  convertNumberToPrice(
                    calculateCoveragePayorTotal(billAttributes, billItems, this.props.usedDiscountsCharges),
                  ) :
                  convertNumberToPrice(0)
              }
              disabled
              label={translate('bill_to_panel')}
              style={{
                width: '50%',
                marginLeft: 'auto',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                borderBottom: border,
              }}
            />
          }
          {
            payments.map(
              (p, i) =>
                <PaymentLineItem>
                  <Input
                    id={`payment-amount-${p.get('_id')}'`}
                    value={convertNumberToPrice(p.get('amount'))}
                    disabled
                    label={translate('amount')}
                    hideLabel={i > 0}
                    inputStyle={i !== payments.size - 1 ? { marginBottom: 0 } : {}}
                  />
                  <Input
                    id={`payment-method-${p.get('_id')}'`}
                    value={translate(p.get('method'))}
                    disabled
                    label={translate('payment_type')}
                    hideLabel={i > 0}
                    inputStyle={i !== payments.size - 1 ? { marginBottom: 0 } : {}}
                  />
                </PaymentLineItem>,
            )
          }
          <Input
            id="bill_finalisation_amount_owed_to_clinic"
            value={convertNumberToPrice(amountOwedToClinic > 0 ? amountOwedToClinic : 0)}
            disabled
            label={translate('amount_owed_to_clinic')}
            style={{
              width: '50%',
              marginLeft: 'auto',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          />
          {
            this.props.coveragePayor &&
            <Input
              id="bill_finalisation_panel_coverage_name"
              value={`${panelName} - ${policyName}`}
              disabled
              label={translate('panel_policy_name_attached_to_bill')}
            />
          }
          {
          this.props.billAttributes.notes &&
            <Input
              id="bill_finalisation_notes"
              value={this.props.billAttributes.notes}
              disabled
              label={translate('bill_notes')}
            />
          }
          {
            this.props.config.getIn(['bills', 'aboveMaximumClaimableAmountCanProceed']) &&
            Number(getMaxClaimableAmountForBill(this.props.billAttributes, coveragePayors)) < // Number used to disable the flow error
            calculateCoveragePayorTotal(billAttributes, billItems, this.props.usedDiscountsCharges) &&
            <FormError>{translate('exceeded_on_panel_maximum_claimable_amount')}</FormError>
          }
        </div>
        <footer className="o-card__footer u-padding--half-ws">
          <Button
            dataPublic
            className="o-button o-button--small o-button--danger u-margin-right--half-ws"
            onClick={() => this.props.onCancelClicked()}
          >
            {translate('return_to_edit')}
          </Button>
          <Button
            dataPublic
            className="o-button o-button--small"
            onClick={() => this.props.onFinaliseClicked()}
          >
            {translate('finalise_bill')}
          </Button>
        </footer>
      </StatelessModal>
    );
  }
}

export default BillFinalisationSummary;
