import React from 'react';
import { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import DoctorForm from './doctorForm';
import StatelessModal from './../modals/statelessModal';
import DoctorsTable from './doctorsTable';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Header from './../header/header';

import type UserConfigModel from '../../models/userConfigModel';
import type PractitionerModel from './../../models/practitionerModel';
import type { SaveModel, Config, User } from './../../types';
import { debugPrint } from '../../utils/logging';

type Props = {
  practitioners: List<PractitionerModel>,
  saveModel: SaveModel,
  config: Config,
  updateOnDuty: (update: List<Map<string, string | boolean | void>>) => void,
  user: User,
  userConfigs: List<UserConfigModel>,
  updateConfig: (config: Config) => void,
};

type State = {
  isModalVisible: boolean,
};

/**
 * Doctors
 * @namespace Doctors
 */
class Doctors extends React.Component<Props, State> {
  /**
   * Creates an instance of Doctors.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isModalVisible: false };
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering Doctors');
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('doctors')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <PermissionWrapper permissionsRequired={List([createPermission('doctors', 'create')])} user={this.props.user}>
              <StatelessModal
                id="addNewDoctorModal"
                buttonLabel={translate('add_new_doctor')}
                buttonClass="o-button o-button--small"
                title={translate('doctor')}
                className="js-modal"
                visible={this.state.isModalVisible}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                explicitCloseOnly
                dataPublicHeader
              >
                <DoctorForm
                  config={this.props.config}
                  onSave={practitioner =>
                    this.props.saveModel(practitioner).then(() => {
                      this.setState({ isModalVisible: false });
                      return practitioner;
                    })
                  }
                />
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('doctors')}</h2>
          <DoctorsTable
            practitioners={this.props.practitioners}
            config={this.props.config}
            saveModel={this.props.saveModel}
            updateOnDuty={this.props.updateOnDuty}
            user={this.props.user}
            userConfigs={this.props.userConfigs}
            updateConfig={this.props.updateConfig}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default Doctors;
