import React from 'react';

import TimeChitModel from '../../models/timeChitModel';
import StatelessModal from '../modals/statelessModal';
import TimeChitForm from './timeChitForm';

import type { Config } from '../../types';
import type EncounterModel from '../../models/encounterModel';

type Props = {
  config: Config,
  currentModel?: TimeChitModel,
  initialDate: number,
  encounter?: EncounterModel,
  onSave: (timeChit: TimeChitModel) => void,
  patientID?: string,
  modalId?: string,
  modalButtonLabel?: string,
  modalButtonClass?: string,
  modalTitle?: string,
  isModalVisible: (value: boolean) => void,
  modalVisible: boolean,
  explicitCloseOnly?: boolean,
  modalClassName?: string,
  modalDisableButton?: boolean,
  dataPublicHeader?: boolean,
};

type State = {
  changesMade: boolean,
  showPrompt: boolean
};

/**
   * Form for creating Time Chits.
   * @returns {React.ComponentType}
   * @namespace TimeChitFormModal
   */
class TimeChitFormModal extends React.Component<Props, State> {
  static defaultProps = {
    initialDate: (new Date()).getTime(),
  };

  /**
   * Creates an instance of TimeChitForm.
   * @param {Props} props props
   * @return {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      changesMade: false,
      showPrompt: false,
    };
  }

  handleClose = () => {
    this.setState({
      changesMade: false,
      showPrompt: false,
    });
    this.props.isModalVisible(false);
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <StatelessModal
        id={this.props.modalId || ''}
        buttonLabel={this.props.modalButtonLabel || ''}
        buttonClass={this.props.modalButtonClass || ''}
        title={this.props.modalTitle || ''}
        className={this.props.modalClassName}
        setVisible={(isVisible: boolean) => {
          if (this.state.changesMade && this.props.modalVisible && !isVisible) {
            this.setState({ showPrompt: true });
          } else {
            this.props.isModalVisible(isVisible);
          }
        }}
        onClose={() => {
          if (this.state.changesMade) {
            this.setState({
              showPrompt: true,
            });
          } else {
            this.handleClose();
          }
        }}
        visible={this.props.modalVisible}
        explicitCloseOnly={this.props.explicitCloseOnly}
        disableButton={this.props.modalDisableButton}
        dataPublicHeader={this.props.dataPublicHeader}
      >
        <TimeChitForm
          config={this.props.config}
          currentModel={this.props.currentModel}
          initialDate={this.props.initialDate}
          encounterID={this.props.encounter?.get('_id')}
          practitionerId={this.props.encounter?.getValue('doctor')}
          onSave={this.props.onSave}
          patientID={this.props.patientID}
          onChangesMade={changesMade => this.setState({ changesMade })}
          showPrompt={this.state.showPrompt}
          onPromptCancel={() => this.setState({ showPrompt: false })}
          onDiscard={this.handleClose}
        />
      </StatelessModal>
    );
  }
}

export default TimeChitFormModal;
