import React from 'react';

import LoadingButton from './loadingButton';
import translate from './../../utils/i18n';
import LoadingIndicator from './../loadingIndicator';
import Button from './../buttons/button';

import type { UnusedReturn } from './../../types';

type Props = {
  id?: string,
  disabled?: boolean,
  label?: string,
  savingLabel?: string,
  onClick: () => UnusedReturn,
  isSaving?: boolean,
  fullWidth?: boolean,
  style?: { [key: string]: string | number },
  className?: string,
  showBusyIndicator?: boolean,
  dataPublic?: boolean,
};

/**
   * @param {props} props props
   * @return {ReactComponent} React component for a Save Button.
   */
const SaveButton = ({
  onClick, label = translate('save'), isSaving = false, fullWidth = false,
  disabled = false, savingLabel = translate('saving'), style = {}, className = '',
  showBusyIndicator = false, dataPublic, id
}: Props) => {
  let buttonClass = fullWidth ? 'o-button o-button--full-width' : 'o-button';
  buttonClass += ` ${className}`;
  const publicData = label === translate('save') ? true : dataPublic;
  return isSaving ? (
    <LoadingButton
      dataPublic={publicData}
      label={savingLabel}
      style={style}
      buttonClass={buttonClass}
    />
  ) : (
    <Button
      id={id}
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      style={{
        ...style,
        ...(showBusyIndicator && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      }}
      dataPublic={publicData}
    >
      {label}
      {showBusyIndicator ? (
        <LoadingIndicator
          style={{ display: 'inline-block', marginLeft: 8 }}
          size={32}
        />
      ) : null}
    </Button>
  );
};

export default SaveButton;
