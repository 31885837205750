import React, { useState } from 'react';
import ReactHeight from 'react-height';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import { isToday } from './../../utils/time';
import { logMessage } from './../../utils/logging';
import QueueButtonContainer from './../../containers/queueButtonContainer';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import NotFoundContainer from './../../containers/notFoundContainer';
import { getConfirmation } from './../../utils/utils';
import PatientAllergyBar from './patientAllergyBar';
import Button from './../buttons/button';
import { isKlinifyUser } from './../../utils/auth';

import type AllergyModel from './../../models/allergyModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import EncounterModel from './../../models/encounterModel';
import type { SaveModel, User, Config } from './../../types';
import type AppointmentModel from '../../models/appointmentModel';
import Confirm from '../prompts/confirm';
import { getQueueConfirmModalMessage } from '../patientList/patientSearchResultList';
import type DrugModel from './../../models/drugModel';

// NOTE: The height check makes this useful alongside a div with o-scrollable-container set on it
// as we can then adjust the max height to incorporate this component.
type Patient = PatientModel | PatientStubModel;
type Props = {
  allergies: List<AllergyModel>,
  patient: Patient,
  coveragePayors: List<CoveragePayorModel>,
  onHeightReady: (height: number) => void,
  showActions?: boolean,
  saveModel?: SaveModel,
  currentEncounter?: EncounterModel | null | undefined,
  currentBill?: BillModels | null | undefined,
  hasCurrentConsult?: boolean,
  patientStub?: PatientStubModel,
  user: User,
  config?: Config,
  klinifyConfig?: Config,
  encounters?: List<EncounterModel>,
  appointments?: List<AppointmentModel>,
  drugs: List<DrugModel>,
  isFromOverviewPage?: boolean,
}

/**
 * Gets the patient header label.
 * @param {PatientModel} patient The patient
 * @param {List<CoveragePayorModel>} coveragePayors All coverage payors
 * @returns {string} The label for the patient header
 */
function getLabel(patient: Patient, coveragePayors: List<CoveragePayorModel>): string {
  // It seems like it may be possible for patient to not exist at this point. I have no idea how,
  // but it occurred here https://sentry.io/klinify/klinify-mobile-web-app/issues/391309176/
  if (!patient) {
    return translate('loading...');
  }
  let label = patient.get('patient_name');
  if (patient.has('ic', false)) {
    label += ` / ${patient.get('ic')}`;
  }
  if (patient.has('dob', false)) {
    label += ` / ${patient.getAge()}`;
  }
  if (patient.hasCoverage()) {
    label += ` / ${patient.getCoveragePayorName(coveragePayors)}`;
  }
  return label;
}

/**
 * A PatientHeader component.
 * @param {Props} props props
 * @returns {React.Component} A PatientHeader component
 */
const PatientHeader = ({
  patient, onHeightReady, coveragePayors,
  user, hasCurrentConsult, currentEncounter, showActions, currentBill,
  saveModel, patientStub, config, encounters, allergies, klinifyConfig, appointments,
  drugs, isFromOverviewPage,
}: Props) => {
  const patientModel = patient || patientStub;
  if (!patientModel) {
    logMessage('Patient model not provided.', 'error');
    return <NotFoundContainer />;
  }
  const [queueMode, setQueueMode] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  /**
   * Returns true if the patient has active/scheduled encounter for today
   * @param {string} id patient id
   * @returns {boolean}
   */
  const getPatientQueuedOrScheduled = (id: string) => {
    const currentAppointmentsForPatient = appointments && appointments
      .filter(a => a.get('status') !== 'cancelled' && a.get('patient_id') === id && a.isToday() &&
        a.isActive())
    return (encounters && encounters.size > 0) || (currentAppointmentsForPatient && currentAppointmentsForPatient.size > 0);
  };

  const disabled = appointments && appointments
    .some(a => a.get('status') !== 'cancelled' && a.get('patient_id') === patient.get('_id') &&
      (a.isActive() && a.isTodayOrFuture()));

  const scheduleAppointments = isKlinifyUser(user) || (klinifyConfig && klinifyConfig.getIn(['scheduling', 'scheduleAppointments'], false));

  /**
   * Closes confirm modal and resets states
   * @returns {void}
   */
  const resetConfirmState = () => {
    setShowConfirmModal(false);
    setQueueMode('');
  };

  return (
    <div>
      <ReactHeight onHeightReady={onHeightReady} className="c-patient-header">
        {getLabel(patient, coveragePayors)}
        <div className="c-patient-card__actions u-margin-right--1ws">
          {
            showActions &&
            !hasCurrentConsult &&
            saveModel &&
            user &&
            config &&
            !(config.get('use_legacy_scheduling') && isToday(patientModel.get('appt_date', 0))) &&
            <PermissionWrapper permissionsRequired={List([createPermission('queue_patient_for_today', 'create')])} user={user}>
              {/* <QueueButtonContainer patient={patientModel} /> */}
              <Button
                className="o-button o-button--small"
                onClick={(event) => {
                  event.stopPropagation();
                  setQueueMode('queue');
                  const isPatientQueuedOrSchedule = getPatientQueuedOrScheduled(patient.get('_id'))
                  if (isPatientQueuedOrSchedule) {
                    setShowConfirmModal(true);
                  }
                }}
                dataPublic
              >
                {translate('queue')}
              </Button>
              {scheduleAppointments &&
                <PermissionWrapper permissionsRequired={List([createPermission('schedule_appointments', 'create')])} user={user}>
                  &nbsp;&nbsp;
                  <Button
                    className="o-button o-button--small"
                    onClick={(event) => {
                      event.stopPropagation();
                      setQueueMode("schedule");
                    }}
                    disabled={disabled}
                    dataPublic
                  >
                    {translate('schedule')}
                  </Button>
                </PermissionWrapper>
              }
            </PermissionWrapper>
          }
          {
            showActions &&
            hasCurrentConsult &&
            user &&
            config &&
            <PermissionWrapper permissionsRequired={List([createPermission('queue_patient_for_today', 'delete')])} user={user}>
              <Button
                className="o-button o-button--small o-button--danger u-width-180"
                onClick={(event) => {
                  event.stopPropagation();
                  if (saveModel && currentEncounter) { // This is a bit of a cheat check to quash flow errors.
                    getConfirmation(translate('confirm_cancel'))
                      .then(
                        () => {
                          saveModel(currentEncounter.addEvent('cancelled'));
                          if (currentBill) {
                            saveModel(currentBill.set({ is_void: true }))
                              .then(() => {
                                if (encounters && encounters.size > 1) {
                                  location.hash = '#';
                                }
                              });
                          }
                        },
                        () => {},
                      );
                  }
                }}
                dataPublic
              >
                {translate('remove_from_queue')}
              </Button>
            </PermissionWrapper>
          }
        </div>
      </ReactHeight>
      <PatientAllergyBar
        user={user}
        allergies={allergies}
        drugs={drugs}
        isFromOverviewPage={isFromOverviewPage}
      />
      {
        patient.isStub() &&
        <div className="c-patient-header__offline">{translate('patient_offline_warning')}</div>
      }
      {showConfirmModal && <Confirm
        show={showConfirmModal}
        cancel={() => resetConfirmState()}
        proceed={() => resetConfirmState()}
        confirmation={getQueueConfirmModalMessage(patient.get('_id'), resetConfirmState, false)}
        modalTitle={translate('patient_cannot_be_queued')}
        footerSaveButtonName={translate('acknowledge')}
        hideCancel
      />}
      {queueMode === 'schedule' && patient &&
        <QueueButtonContainer
          patient={patient as PatientModel}
          onClose={() => {
            resetConfirmState();
          }}
          onSuccess={id => {
            resetConfirmState();
          }}
          onError={() => {
            resetConfirmState();
          }}
          modalVisible
          isSchedule
          noButton
        />
      }
      {queueMode === 'queue' && patient && !showConfirmModal &&
        <QueueButtonContainer
          patient={patient as PatientModel}
          onClose={() => {
            resetConfirmState();
          }}
          onSuccess={id => {
            resetConfirmState();
          }}
          onError={() => {
            resetConfirmState();
          }}
          modalVisible
          noButton
        />
      }
    </div>
  );
};

export default PatientHeader;
