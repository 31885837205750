import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'provider',
  name: string,
  description?: string,
  notes?: string,
  tags?: ['lab'],
  active: boolean,
  address?: string,
  site_url?: string,
  contact_no?: string,
  contact_mode_details?: string,
  is_editable: boolean,
  internal_clinic_id?: string,
};

/**
  * ProviderModel
  * @namespace ProviderModel
  */
class ProviderModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'provider';
  }

  /**
   * Returns true if item have an active flag.
   * @returns {boolean} True if item is active.
   */
  isActive(): boolean {
    return this.get('active', true);
  }

  /**
   * Sets the provider active by the given value.
   * @param {boolean} isActive True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered ProviderModel.
   */
  setActive(isActive: boolean): ProviderModel {
    return this.set('active', isActive);
  }

  /**
   * Returns the name of the Provider.
   * @returns {string}
   */
  getName() {
    return this.get('name');
  }

  /**
   * Returns the Provider LIS integrations settings and information.
   * @returns {{} | undefined}
   */
  getIntegrations() {
    if (this.get('integrations') &&
      (this.get('integrations').id === 'labs:quantum' ||
      this.get('integrations').id === 'labs:gribbles')) {
      return this.get('integrations').id;
    }
    return undefined;
  }
}

export default ProviderModel;
