import React, { ChangeEvent } from 'react';

import Label from './label';

type step = number | 'any';

type Props = {
  autoCapitalize?: string,
  autoFocus: boolean,
  className?: string,
  description?: string,
  disabled: boolean,
  divClassName: string,
  fullWidth?: boolean,
  hideLabel?: boolean,
  id: string,
  inputStyle: { [key: string]: string | number },
  label: string,
  labelClassName?: string,
  inputClassName?: string,
  onValueChanged?: (value: string | number) => void,
  placeholder?: string,
  required?: boolean,
  style?: { [key: string]: string | number },
  type: 'text' | 'password' | 'number' | 'email' | 'tel',
  value: string | number,
  errorMessage?: string,
  min?: number,
  max?: number,
  pattern?: string,
  step?: step,
  descClassName?: string,
}

/**
 * A component for replacing the HTML input tag.
 * @class Input
 * @extends {React.Component<Props, {}>}
 * @param {?} event ? // This line is just here to shut up ESLint.
 */
class Input extends React.Component<Props, {}> {
  props: Props;

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    divClassName: '',
    type: 'text',
    placeholder: '',
    labelClassName: '',
    inputClassName: '',
    inputStyle: {},
    style: {},
    value: '',
    onValueChanged: () => {},
    label: '',
  };

  getPlaceholder = (): string => {
    // Set placeholder to label if empty.
    if (this.props.placeholder === '') {
      return this.props.label;
    }
    return this.props.placeholder;
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    // Set capitalisation of input.
    if (this.props.autoCapitalize === 'words') {
      const re = /(\b[a-z](?!\s))/g; // Regex to get first letter after each word boundary.
      value = value.replace(re, x => x.toUpperCase());
    }
    if (this.props.type === 'number' && value) {
      value = parseFloat(value);
    }
    this.props.onValueChanged(value);
  }

  /**
   * Gets the validation pattern for the input
   * @returns {(string | void)}
   */
  getPattern(): string | void {
    if (this.props.pattern) {
      return this.props.pattern;
    }
    return this.props.type === 'number' ? '[1-9]\d*(\.\d+)?' : undefined; // eslint-disable-line no-useless-escape
  }

  /**
   * Returns true if current value matches patterns/min/required props.
   * @returns {boolean}
   */
  isValid(): boolean {
    const parsedValue = parseFloat(this.props.value);
    if (
      this.props.min !== undefined &&
      this.props.min !== null &&
      !Number.isNaN(parsedValue) &&
      parsedValue < this.props.min
    ) {
      return false;
    }
    if (this.props.required) {
      if (this.props.value?.trim) {
        return this.props.value.trim().length > 0;
      }
      return this.props.value !== undefined && this.props.value !== '';
    }
    return true;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let divClassName = `o-form__item ${this.props.divClassName}`;
    let inputClassName = 'o-text-input';
    if (this.props.className) {
      inputClassName += ` ${this.props.className}`;
    }
    if (this.props.fullWidth) {
      inputClassName += ' o-text-input--full-width';
      divClassName += ' o-form__item--full-width';
    }
    if (this.props.errorMessage || !this.isValid()) {
      inputClassName += ' o-text-input--with-error';
    }
    inputClassName += ` ${this.props.inputClassName}`;

    if (this.props.errorMessage && !this.isValid()) {
      inputClassName += ' u-margin-bottom--zero';
    }

    let description = '';
    if (this.props.description) {
      let descClassName = 'o-sub-label';
      if (this.props.descClassName) {
        descClassName += ` ${this.props.descClassName}`;
      }
      description = <p className={descClassName}>{this.props.description}</p>;
    }
    const limitProp = this.props.type === 'number' ? { min: this.props.min, max: this.props.max } : undefined;

    return (
      <div className={divClassName} style={this.props.style}>
        {
          !this.props.hideLabel &&
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label}
            invalid={!this.isValid()}
          />
        }
        { description }
        <input
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off" // Turning off for now because Safari has bugs with this.
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          spellCheck="false"
          id={this.props.id}
          placeholder={this.props.disabled ? '' : this.getPlaceholder()}
          className={inputClassName}
          type={this.props.type}
          value={this.props.value}
          onChange={this.onChange}
          required={this.props.required}
          style={this.props.inputStyle}
          pattern={this.getPattern()}
          {...limitProp}
          step={this.props.step}
        />
        {
          this.props.errorMessage &&
          <div className="o-text-input__error">{this.props.errorMessage}</div>
        }
      </div>
    );
  }
}

export default Input;
