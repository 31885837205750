/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react';
import { List, Record } from 'immutable';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import translate from './../../utils/i18n';
import Select from './../inputs/select';

import { compareByAlphabeticalOrder } from './../../utils/comparators';

import type { APIResponse, Config, Model, SaveModel } from './../../types';
import type SalesItemModel from './../../models/salesItemModel';
import type PractitionerModel from './../../models/practitionerModel';
import BillModel from './../../models/billModel';
import PatientModel from './../../models/patientModel';
import { knuthMorrisPratt } from './../../utils/search';
import Input from '../inputs/input';
import CoveragePayorModel from './../../models/coveragePayorModel';
import { filterUnwantedCharacters } from './../../utils/utils';
import TextArea from '../inputs/textarea';
import EncounterModel from './../../models/encounterModel';
import StatelessModal from '../modals/statelessModal';
import PatientRegistrationContainer from './../../containers/patientRegistrationContainer';
import AddEncounterContainer from './../../containers/addEncounterContainer';
import CoveragePayorForm from '../coveragePayors/coveragePayorForm';
import BaseModel from './../../models/baseModel';
import Radio from '../inputs/radio';
import { getStore } from './../../utils/redux';
import { setDebugModeData } from './../../actions';


type Props = {
  docId: string,
  config: Config,
  patient: PatientModel,
  encounter: EncounterModel,
  coveragePayors: List<CoveragePayorModel>,
  salesItems: List<SalesItemModel>,
  practitioners: List<PractitionerModel>,
  saveModel: SaveModel,
  isSaving: boolean,
  onSaveAtValidationModal: (wasSuccessful: boolean) => void,
  saveBillAndEncounterModels: (
    patient: PatientModel,
    encounter: EncounterModel,
    bill: BillModel
  ) => Promise<Array<BaseModel> | APIResponse<BaseModel>>
}

type billAttributes = {
  _id: string,
  is_finalised: boolean,
  is_void: boolean,
  coverage_payor_id?: string,
  coverage_policy_id?: string,
  encounter_id: string,
  patient_id: string,
  total_amount: number,
  co_payment?: number,
  notes?: string,
}

/**
 * A component that can be used for the creation of encounters when adding past bills. Usually
 * encounters just derive their data from the context they are created in, so this form allows that
 * context to be manually created (date/time, clinic, doctor, etc.);
 * @param {*} props The props for the component.
 * @returns {React.Component} A BillMetadataForm component.
 */
const BillRegenerationForm = (props: Props) => {
  const {
    patient,
    encounter,
    docId,
    coveragePayors,
    salesItems,
    practitioners,
    config,
    isSaving,
    saveBillAndEncounterModels,
    onSaveAtValidationModal,
  } = props;

  const BillAttributeRecord: Record.Factory<billAttributes> = Record({
    _id: '',
    is_finalised: false,
    is_void: false,
    coverage_payor_id: undefined,
    coverage_policy_id: undefined,
    encounter_id: '',
    patient_id: '',
    total_amount: 0,
    co_payment: undefined,
    notes: undefined,
  });
  const [newPatient, setNewPatient] = useState(patient);
  const [newEncounter, setNewEncounter] = useState(encounter);
  const [billAttributes, setBillAttributes] = useState(BillAttributeRecord({
    _id: docId,
    patient_id: patient?.get('_id') || '',
    encounter_id: encounter?.get('_id') || '',
  }));
  const [isAddNewOptionModalVisible, setAddNewOptionModalVisible] = useState(false);
  const [addNewOptionModalType, setAddNewOptionModalType] = useState<string | null>(null);

  /**
   * Get the modal content for creating the new option in selct inputs based on type
   * @param {string} type The type of doc to create
   * @returns {React.Component} A BillMetadataForm component.
   */
  const getAddNewOptionModalContent = () => {
    switch (addNewOptionModalType) {
      case 'patient': {
        const PatientContainer = withRouter(PatientRegistrationContainer);
        return (<PatientContainer
          isFromModal
          docId={patient?.get('_id')}
          onSave={(model?: PatientModel) => {
            if (model) {
              setNewPatient(model);
              setAddNewOptionModalVisible(false);
            }
          }}
        />);
      }
      case 'encounter': {
        return (<AddEncounterContainer
          patient={newPatient}
          bill={new BillModel(billAttributes)}
          validationDocObject={{ id: newEncounter.get('_id'), type: 'encounter' }}
          noSave
          onSaveAtDocValidationModal={(wasSuccesful: boolean, models?: List<EncounterModel>) => {
            if (wasSuccesful && models) {
              setNewEncounter(models.first());
              setAddNewOptionModalVisible(false);
            }
          }}
        />);
      }
      case 'coverage_payor': {
        return (<CoveragePayorForm
          saveModel={(model: CoveragePayorModel) => props.saveModel(model).then(() => {
            setBillAttributes(billAttributes
              .set('coverage_payor_id', model.get('_id'))
              .set('coverage_policy_id', model.get('policies')[0]?._id)
              .set('co_payment', model.getCoPayment(model.get('policies')[0]?._id)));
            setAddNewOptionModalVisible(false);
            return model;
          })}
          saveIsSticky
        />);
      }
      default:
        return null;
    }
  };


  /**
   * Handles the save operation by calling /encounter api with the bill and encounter models
   * @returns {void}
   */
  const handleSave = () => {
    const newBill = new BillModel({ ...billAttributes, timestamp: moment().valueOf() });
    if (getStore().getState().debugModeFlags.docValidation) {
      getStore().dispatch(setDebugModeData(
        'docValidation',
        (models: List<Model>) => saveBillAndEncounterModels(
          models.find(model => model.get('type') === 'patient'),
          models.find(model => model.get('type') === 'encounter'),
          models.find(model => model.get('type') === 'bill'),
        ).then((savedModels) => {
          if (!savedModels.unavailable && !savedModels.error && Array.isArray(savedModels)) {
            onSaveAtValidationModal(true);
            return savedModels;
          }
          return null;
        }),
        List([
          newPatient,
          newEncounter,
          newBill,
        ]),
      ));
    } else {
      saveBillAndEncounterModels(newPatient, newEncounter, newBill)
        .then((savedModels) => {
          if (!savedModels.unavailable && !savedModels.error && Array.isArray(savedModels)) {
            onSaveAtValidationModal(true);
            return savedModels;
          }
          return null;
        });
    }
  };

  useEffect(() => {
    if (isSaving) {
      handleSave();
    }
  }, [isSaving]);

  return (
    <div className="0-form">
      <Select
        id="bill_patient"
        label={translate('patient')}
        options={[
          {
            value: newPatient.get('_id'),
            label: newPatient.isMissing()
              ? translate('data_not_found').toUpperCase()
              : newPatient.get('patient_name'),
            ...(newPatient.isMissing() && {
              isOptionHidden: true,
              style: {
                color: 'red',
              },
            }),
          },
          { value: 'create', label: translate('add_new_patient') },
        ]}
        value={billAttributes.get('patient_id')}
        onValueChanged={(selectedOption) => {
          if (!selectedOption || selectedOption.length === 0) {
            setBillAttributes(billAttributes
              .set('patient_id', patient?.get('_id') || ''));
          } else if (selectedOption === 'create') {
            setAddNewOptionModalVisible(true);
            setAddNewOptionModalType('patient');
          } else {
            setBillAttributes(billAttributes
              .set('patient_id', newPatient?.get('_id') || ''));
          }
        }}
        creatable
        showNewOptionAtTop={false}
        disabled={newPatient && !newPatient.isMissing()}
        onCreateOption={() => {
          setAddNewOptionModalVisible(true);
          setAddNewOptionModalType('patient');
        }}
        createOptionPromptFactory={() => translate('add_new_patient')}
        filterOption={(option, query: string) => {
          // This is a custom filter to ensure search only applies to start of words.

          if ((query && option.value === 'create') || option.data?.isOptionHidden) { // Used to remove the create option from search filter as that is handled by select creatable
            return false;
          }
          const queryStrings = query.toLowerCase().split(' ');
          return queryStrings.every(queryString =>
            knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1) ||
            (option.data ? option.data.__isNew__ : false); // eslint-disable-line no-underscore-dangle
        }}
      />
      <Select
        id="bill_encounter"
        label={translate('encounter')}
        options={[
          {
            value: newEncounter.get('_id'),
            label: newEncounter.isMissing()
              ? translate('data_not_found').toUpperCase()
              : `[${newEncounter.getDate()}] - ${newEncounter.getEncounterType(salesItems)} - ${newEncounter.getDoctorName(practitioners)}`,
            ...(newEncounter.isMissing() && {
              isOptionHidden: true,
              style: {
                color: 'red',
              },
            }),
          },
          { value: 'create', label: translate('add_new_encounter') },
        ]}
        value={billAttributes.get('encounter_id')}
        onValueChanged={(selectedOption) => {
          if (!selectedOption || selectedOption.length === 0) {
            setBillAttributes(billAttributes
              .set('encounter_id', encounter?.get('_id') || ''));
          } else if (selectedOption === 'create') {
            setAddNewOptionModalVisible(true);
            setAddNewOptionModalType('encounter');
          } else {
            setBillAttributes(billAttributes
              .set('encounter_id', newEncounter?.get('_id') || ''));
          }
        }}
        disabled={newEncounter && !newEncounter.isMissing()}
        creatable
        showNewOptionAtTop={false}
        onCreateOption={() => {
          setAddNewOptionModalVisible(true);
          setAddNewOptionModalType('encounter');
        }}
        createOptionPromptFactory={() => translate('add_new_encounter')}
        filterOption={(option, query: string) => {
          // This is a custom filter to ensure search only applies to start of words.

          if (query && option.value === 'create') { // Used to remove the create option from search filter as that is handled by select creatable
            return false;
          }
          const queryStrings = query.toLowerCase().split(' ');
          return queryStrings.every(queryString =>
            knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1) ||
            (option.data ? option.data.__isNew__ : false); // eslint-disable-line no-underscore-dangle
        }}
      />
      <Input
        id="bill_total_amount"
        required
        label={translate('total_amount')}
        value={billAttributes.get('total_amount')}
        type="number"
        onValueChanged={(newValue: number) => {
          const filteredValue = filterUnwantedCharacters(newValue, '');
          setBillAttributes(billAttributes.set('total_amount', filteredValue
            ? Number(parseFloat(filteredValue).toFixed(2))
            : 0));
        }}
      />
      <Select
        id="bill_coverage_payor"
        label={translate('coverage_payor')}
        value={billAttributes.get('coverage_payor_id')}
        options={
          coveragePayors
            .filter(payor => payor.isVisible() || payor.get('_id') === billAttributes.get('coverage_payor_id'))
            .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))
            .map(payor => ({ value: payor.get('_id'), label: payor.get('name'), disabled: !payor.isVisible() }))
            .push({ value: 'create', label: translate('add_new_coverage_payor'), disabled: false })
            .toArray()
        }
        creatable
        showNewOptionAtTop={false}
        onCreateOption={() => {
          setAddNewOptionModalVisible(true);
          setAddNewOptionModalType('coverage_payor');
        }}
        createOptionPromptFactory={() => translate('add_new_coverage_payor')}
        onValueChanged={(coveragePayorID) => {
          if (!coveragePayorID) {
            const coveragePayor = coveragePayors.first(undefined);
            const policyID = coveragePayor?.attributes?.policies[0]?._id;
            setBillAttributes(billAttributes
              .set('coverage_payor_id', coveragePayorID === '' ? undefined : coveragePayorID)
              .set('coverage_policy_id', policyID)
              .set('co_payment', coveragePayor?.getCoPayment(policyID)));
          } else if (coveragePayorID === 'create') {
            setAddNewOptionModalVisible(true);
            setAddNewOptionModalType('coverage_payor');
          } else {
            const coveragePayor = coveragePayorID && coveragePayors.find(payor => payor.get('_id') === coveragePayorID);
            const policyID = coveragePayor?.attributes?.policies[0]?._id;
            setBillAttributes(billAttributes
              .set('coverage_payor_id', coveragePayorID === '' ? undefined : coveragePayorID)
              .set('coverage_policy_id', policyID)
              .set('co_payment', coveragePayor?.getCoPayment(policyID)));
          }
        }}
        filterOption={(option, query: string) => {
          // This is a custom filter to ensure search only applies to start of words.

          if (query && option.value === 'create') { // Used to remove the create option from search filter as that is handled by select creatable
            return false;
          }
          const queryStrings = query.toLowerCase().split(' ');
          return queryStrings.every(queryString =>
            knuthMorrisPratt(option.label.toLowerCase(), queryString.toLowerCase()) !== -1) ||
            (option.data ? option.data.__isNew__ : false); // eslint-disable-line no-underscore-dangle
        }}
      />
      <Select
        id="bill_coverage_policy"
        label={config.getIn(['patient', 'labels', 'coverage', 'policy'], 'Policy Name')}
        value={billAttributes.get('coverage_policy_id')}
        options={
          coveragePayors.find(payor => payor.get('_id') === billAttributes.get('coverage_payor_id'))?.attributes?.policies
            .filter(policy => policy.active)
            .map(policy => ({ value: policy._id, label: policy.policy_name }))
          ?? []
        }
        onValueChanged={(selectedOption) => {
          if ((!selectedOption || selectedOption.length === 0) &&
          billAttributes.get('coverage_payor_id')) {
            const coveragePayor = coveragePayors.find(payor => payor.get('_id') === billAttributes.get('coverage_payor_id'));
            const policyID = coveragePayor?.attributes?.policies[0]?._id;
            setBillAttributes(billAttributes
              .set('coverage_policy_id', policyID)
              .set('co_payment', coveragePayor?.getCoPayment(policyID)));
          } else {
            const coveragePayor = coveragePayors.find(payor => payor.get('_id') === billAttributes.get('coverage_payor_id'));
            setBillAttributes(billAttributes
              .set('coverage_policy_id', selectedOption)
              .set('co_payment', coveragePayor?.getCoPayment(selectedOption)));
          }
        }}
        disabled={!billAttributes.get('coverage_payor_id')}
      />
      <Input
        id="bill_co-payment"
        value={billAttributes.get('co_payment') ?? ''}
        disabled={!billAttributes.get('coverage_payor_id')}
        onValueChanged={
          (newValue: number) => {
            const filteredValue = filterUnwantedCharacters(newValue, '');
            setBillAttributes(billAttributes.set('co_payment', filteredValue ? parseFloat(filteredValue) : undefined));
          }
        }
        type="number"
        label={translate('co_payment')}
      />
      <TextArea
        id="bill_notes"
        value={billAttributes.get('notes') ?? ''}
        onValueChanged={(newValue: string) => setBillAttributes(billAttributes.set('notes', newValue || undefined))}
        label={translate('notes')}
      />
      <Radio
        id="bill_finalized"
        label={translate('finalized')}
        options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
        value={billAttributes.get('is_finalised').toString()}
        onValueChanged={newValue => setBillAttributes(billAttributes.set('is_finalised', newValue === 'true'))}
      />
      <Radio
        id="bill_void"
        label={translate('void')}
        options={[{ label: translate('yes'), value: 'true' }, { label: translate('no'), value: 'false' }]}
        value={billAttributes.get('is_void').toString()}
        onValueChanged={newValue => setBillAttributes(billAttributes.set('is_void', newValue === 'true'))}
      />
      <StatelessModal
        id={`addNewModal-${addNewOptionModalType}`}
        title={translate(addNewOptionModalType === 'patient'
          ? 'register_patient'
          : addNewOptionModalType === 'encounter'
            ? 'add_new_encounter'
            : 'add_new_coverage_payor')}
        setVisible={(isVisible: boolean) => {
          setAddNewOptionModalVisible(isVisible);
        }}
        visible={isAddNewOptionModalVisible}
        noButton
        onClose={() => {
          setAddNewOptionModalType(null);
        }}
        dataPublicHeader
      >
        {
          useMemo(() => getAddNewOptionModalContent(), [addNewOptionModalType])
        }
      </StatelessModal>
    </div>
  );
};

export default BillRegenerationForm;
