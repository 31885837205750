import React from 'react';
import { Page } from 'catalog';

import StyleguideReset from './styleguideReset';

/**
 * Intro section of styleguide
 * @class StyleguideIntro
 * @extends {React.Component<Props, State>}
 */
const StyleguideIntro = () =>
  <StyleguideReset>
    <Page>
      <h2 data-public>What&#39;s this?</h2>
      <p>
        This styleguide is an living document (and work in progress) that should cover the details
        of how to code and design for the Klinify web client. It is broken down into five sections:
      </p>
      <ul>
        <li>
          <a href="#/styleguide/accessiblity"><strong>Accessibility</strong></a>: Guidelines for
          user and device accessibility.
        </li>
        <li>
          <a href="#/styleguide/content"><strong>Content</strong></a>: Guidelines for the use of
          content (e.g. text, images) in the app.
        </li>
        <li>
          <a href="#/styleguide/visuals"><strong>Visuals</strong></a>: Guidelines for the use of
          visual elements (e.g. colour, typography) in the app.
        </li>
        <li>
          <a href="#/styleguide/components"><strong>Components</strong></a>: The building blocks
          of Klinify.
        </li>
        <li>
          <a href="#/styleguide/coding-style"><strong>Coding Style</strong></a>: Consistent
          style begins with consistent coding. Learn what&#39;s expected of your HTML, CSS, and
          Javascript.
        </li>
      </ul>
      <h2 data-public>Prior Art</h2>
      <ul>
        <li><a href="https://polaris.shopify.com/">Shopify</a></li>
        <li><a href="https://ant.design">ANT Design System</a></li>
      </ul>
      <h2 data-public>Extending the Styleguide</h2>
      <p>If something is missing feel free to add it! The documents are found at:</p>
      <p><code>./src/components/styleguide/</code></p>
      <p>
        This styleguide is built using Catalog. <a href="https://catalog.style">Check the Catalog documents</a> for a bunch of
        built-in components you can utilise.
      </p>
    </Page>
  </StyleguideReset>;

export default StyleguideIntro;
