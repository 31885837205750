import React from 'react';
import { Page } from 'catalog';

import StyleguideReset from './styleguideReset';
import ButtonComponents from './components/buttonComponents';
import InputComponents from './components/inputComponents';
import LayoutComponents from './components/layoutComponents';
import MiscComponents from './components/miscComponents';
import ModalComponents from './components/modalComponents';

/**
 * Components section of style guide.
 * @class StyleguideComponents
 * @extends {React.Component<Props, State>}
 */
const StyleguideComponents = () =>
  <StyleguideReset>
    <Page>
      <ButtonComponents />
      <InputComponents />
      <LayoutComponents />
      <MiscComponents />
      <ModalComponents />
    </Page>
  </StyleguideReset>;

export default StyleguideComponents;
