import React, { useState, useMemo, useEffect } from 'react';
import glamorous from 'glamorous';
import { List, Map } from 'immutable';
import { isEmpty } from 'lodash';

import Table from './../table/table';
import TableColumnsSettings from './../table/tableColumnsSettings';
import LoadingIndicator from '../../components/loadingIndicator';
import DeleteButton from '../../components/buttons/deleteButton';
import Input from '../../components/inputs/input';
import InventoryItemCard from './inventoryItemCard';
import { transformColumnKeys, transformFilteredColumns, getClinicID } from './../../utils/utils';
import { renderList, getFilterParamFromFiltered } from './../../utils/tables';
import translate from './../../utils/i18n';
import { colours } from '../../utils/css';
import { fetchData } from '../../utils/api';
import useDebounce from '../../utils/hooks/useDebounce';
import { getSearchMasterDrugDataViews } from '../../dataViews';
import MasterDrugModel from '../../models/masterDrugModel';
import DrugModel from '../../models/drugModel';

import type InventoryMapModel from '../../models/inventoryMapModel';
import type { Config, MapValue } from '../../types';
import type { Attributes as DrugSuggestionAttributes } from '../../models/drugSuggestionModel';

type Props = {
  inventoryItem?: InventoryMapModel,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
  isManualMapping: boolean,
  drug?: DrugModel,
  fetchTableMasterData: (ids: List<string>) => Promise<void>,
  setShowAddDetailForm: (visible: boolean) => void,
  updateInventoryItem: (
    item: InventoryMapModel | void,
    params: Partial<DrugSuggestionAttributes> | void,
    event: 'confirm_drug' | 'reject_drug') => Promise<void>,
  onDelete?: () => Promise<void>,
  onConfirm?: () => void,
};

/* eslint-disable camelcase */
type searchParam = {
  name?: string;
  active_ingredients?: Array<string>;
}
/* eslint-enable camelcase */

const Container = glamorous.div({
  maxWidth: 'calc(100vw - 6rem)',
  minWidth: 'calc(100vw - 30rem)',
});

const FooterButton = glamorous.button({
  ':disabled': {
    backgroundColor: `${colours.grey7} !important`,
  },
});

const COLUMNS = [
  { accessor: 'name', Header: translate('name'), minWidth: 350 },
  { accessor: 'manufacturer', Header: translate('manufacturer'), minWidth: 200, filterable: true },
  { accessor: 'mal_number', Header: translate('mal_number'), minWidth: 200, filterable: true },
  { accessor: 'active_ingredients', Header: translate('active_ingredients'), minWidth: 200, filterable: true, Cell: renderList },
  { accessor: 'holder', Header: translate('holder'), minWidth: 200, filterable: true },
  { accessor: 'adjust', Header: '', width: 150, sortable: false, fixed: 'right' },
];

/**
 *
 * Table component for
 * showing suggested mappings and
 * Manual mapping
 * @param {Props} { inventoryItem }
 * @returns {React.StatelesssFunctionalComponent}
 */
const InventoryMappingTable = ({
  inventoryItem,
  isManualMapping,
  updateConfigValue,
  config,
  updateConfig,
  masterDrugModelsMap,
  fetchTableMasterData,
  drug = new DrugModel(),
  setShowAddDetailForm,
  updateInventoryItem,
  onDelete,
  onConfirm,
}: Props) => {
  const masterDrugIdMaps = List(inventoryItem ? inventoryItem.get('drug_master_id_maps') : []);

  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [manualMapping, setManualMapping] = useState(isManualMapping);
  const [searchParams, setSearchParams] = useState<searchParam>({});
  const [masterDrugIds, setMasterDrugIds] = useState(manualMapping ? List() : masterDrugIdMaps);
  const debouncedSearchparams = useDebounce(searchParams);

  /**
   * Fetches master drug data depending on SearchParam state.
   * @returns {void}
   */
  const fetchSearchData = () => {
    setIsLoading(true);
    const filterParams = {
      ...searchParams,
      clinic_drug_info: inventoryItem ? { ...drug.attributes } : undefined,
      clinic_id: getClinicID(),
      clinic_drug_id: inventoryItem ? inventoryItem.get('drug_id') : undefined,
      active_ingredients: searchParams.active_ingredients
        ? searchParams.active_ingredients.split(' ')
        : [],
    };
    fetchData(getSearchMasterDrugDataViews(filterParams))
      .then((ids) => {
        setMasterDrugIds(ids.filter(id => !!id));
        const searchIds = ids.filter(id => !masterDrugModelsMap.has(id));
        if (searchIds.size > 0) {
          fetchTableMasterData(searchIds).then(() => {
            setIsDirty(true);
            setIsLoading(false);
          });
        } else {
          setIsDirty(true);
          setIsLoading(false);
        }
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isEmpty(searchParams)) {
      fetchSearchData();
    }
  }, [debouncedSearchparams]);

  /**
   * Creates the master drug table rows for the current drug.
   * @param {List<MasterDrugModel>} masterDrugs All drug models.
   * @returns {[]} An array of rows.
   */
  const getMasterDrugRows = () =>
    useMemo(
      () =>
        masterDrugIds
          .reduce((masterDrugs, id) => {
            if (masterDrugModelsMap.has(id)) {
              return masterDrugs.push(masterDrugModelsMap.get(id));
            }
            return masterDrugs;
          }, List())
          .map(item => ({
            name: item.get('name'),
            manufacturer: item.get('manufacturer'),
            mal_number: item.get('mmc_id'),
            active_ingredients: item.get('active_ingredients'),
            holder: item.get('holder'),
            adjust: (
              <button
                type="button"
                className="o-button o-button--small"
                disabled={isSaving}
                onClick={() => {
                  setIsSaving(true);
                  updateInventoryItem(
                    inventoryItem,
                    { selected_map_id: item.get('id'), name: item.get('name') },
                    'confirm_drug',
                  )
                    .then(() => {
                      setIsSaving(false);
                      if (onConfirm) {
                        onConfirm();
                      }
                    })
                    .catch(() => {});
                }}
              >
                {translate('confirm_drug')}
              </button>
            ),
          })).toArray(),
      [masterDrugModelsMap, masterDrugIds, isSaving],
    );

  const getAttributes = useMemo(() =>
    () => ({
      dosage: drug.get('default_prescribed_dosage', ''),
      dosageUnit: drug.get('dosage_unit', ''),
      frequency: drug.get('frequency', ''),
      duration: drug.get('default_prescribed_duration', ''),
      defaultQuantity: drug.get('default_quantity', ''),
      dispensationUnit: drug.get('dispensation_unit', ''),
      sellingPrice: drug.getPrice() || '',
      manufacturer: drug.get('manufacturer', ''),
      notes: drug.get('notes', ''),
    }),
  [drug]);

  /**
   * Sets filtered state with params from table and trigger debounced api call
   * @param {Array<object>} filtered filtered object from the table.
   * @returns {void}
   */
  const handleFilter = (filtered: Array<Object>) => {
    const filterParams = getFilterParamFromFiltered(filtered);
    setSearchParams({ ...filterParams, name: searchParams.name });
  };

  return (
    <Container>
      {inventoryItem && <InventoryItemCard data={getAttributes()} title={`${translate('inventory_item_to_map')}: ${drug.get('name')}`} />}
      <div className="u-margin--standard">
        {inventoryItem && <hr />}
        {manualMapping && (
          <Input
            id="manual-mapping-search-bar"
            label={translate('search_for_drug_to_map')}
            autoFocus
            value={searchParams.name}
            onValueChanged={(value: string) => setSearchParams({ ...searchParams, name: value })}
          />
        )}
        <header className="o-card__header">
          <h2 className="o-card__title">
            {translate(manualMapping ? 'search_results' : 'suggested_mappings')}
          </h2>
          <TableColumnsSettings
            config={config}
            configFieldName="inventory_manual_mapping"
            updateConfigValue={updateConfigValue}
            originalColumns={List(transformColumnKeys(COLUMNS))}
            columns={columns}
            onUpdateColumns={UpdatedColumns => setColumns(UpdatedColumns)}
            updateConfig={updateConfig}
          />
        </header>
        <Table
          columns={transformFilteredColumns(COLUMNS, columns)}
          data={getMasterDrugRows()}
          showPagination
          defaultPageSize={10}
          apiSearch
          fetchSearchData={handleFilter}
          noDataText={
            isEmpty(searchParams) && !isDirty
              ? translate('please_search_for_drug_you_want_to_add', { label: translate('search_for_drug_to_map') })
              : ' '
          }
          LoadingComponent={() =>
            isLoading && <LoadingIndicator alignCenter />
          }
          showFixedColumns
        />
        <FooterButton
          type="button"
          disabled={isSaving || (manualMapping && !isDirty)}
          className="o-button o-button--small o-button--full-width u-margin-right--half-ws u-margin-top--standard"
          onClick={() => {
            if (manualMapping) {
              setShowAddDetailForm(true);
            } else {
              setIsSaving(true);
              updateInventoryItem(
                inventoryItem,
                undefined,
                'reject_drug',
              )
                .then(() => {
                  setIsSaving(false);
                  setManualMapping(true);
                  setMasterDrugIds(List());
                });
            }
          }}
        >
          {translate(manualMapping ? 'cant_find_the_drug_i_need' : 'none_of_the_above')}
        </FooterButton>
      </div>
      {onDelete && <DeleteButton className="o-delete-button" onDelete={onDelete} />}
    </Container>
  );
};

export default InventoryMappingTable;
