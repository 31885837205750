import React from 'react';
import { Map } from 'immutable';
import moment, { Moment } from 'moment';

import translate from './../../utils/i18n';
import DateRangePicker from './../inputs/dateRangePicker';
import FormError from './../formError';
import { validateDateFilter } from './../../utils/time';

import type { MapValue } from './../../types';

type Props = {
  filter: Map<string, Moment>,
  onFilterUpdated: (filter: Map<string, MapValue>) => Promise<void>,
};

type State = {
  errorMessage?: string,
}

/**
 * A component that displays operations summary date range picker.
 * @class OperationsSummarySidebar
 * @extends {Component}
 */
class OperationsSummarySidebar extends React.Component<Props, State> {
  /**
   * Creates an instance of OperationsSummarySidebar.
   * @param {Props} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: undefined,
    };
  }

  /**
   * Merges the given object with the current filter for accounts receivable reports and updates
   * them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: { filterDateStart?: Moment, filterDateEnd?: Moment }) {
    const newFilterProps = this.props.filter.merge(newValues);
    this.setState({ errorMessage: undefined });
    if (validateDateFilter(
      newFilterProps.get('filterDateStart'),
      newFilterProps.get('filterDateEnd'),
    )) {
      this.props.onFilterUpdated(newFilterProps);
      this.setState({ errorMessage: undefined });
    } else {
      this.setState({ errorMessage: translate('start_time_gt_end_time_error') });
    }
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <div className="o-sidebar--right c-billing__overview" id="operation-summary-filter">
        { this.state.errorMessage &&
          <div className="u-margin--standard">
            <FormError containerElementID="operation-summary-filter">{this.state.errorMessage}</FormError>
          </div>
        }
        <h1 data-public className="c-billing__overview__title">{translate('filter')}</h1>
        <DateRangePicker
          className="u-margin-left--1ws u-margin-right--1ws"
          label={translate('dates')}
          labelClassName="u-font-white"
          id="filter-dates"
          startDate={this.props.filter.get('filterDateStart')}
          endDate={this.props.filter.get('filterDateEnd')}
          maxDate={moment(new Date())}
          onValueChanged={(value: { startDate?: Moment, endDate?: Moment }) => {
          // We need to do this because of how DRP handles out of bounds selections.
            const newFilter = {};
            if (value.startDate) {
              newFilter.filterDateStart = value.startDate;
            }
            if (value.endDate) {
              newFilter.filterDateEnd = value.endDate;
            } else if (value.endDate === null) { // occurs if end date set before start date.
              newFilter.filterDateEnd = value.startDate;
            }
            this.updateFilter(newFilter);
          }}
        />
      </div>
    );
  }
}

export default OperationsSummarySidebar;
