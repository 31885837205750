import * as React from 'react';
import glamorous from 'glamorous';
import type { List } from 'immutable';

import translate from './../../utils/i18n';
import { colours } from './../../utils/css';
import type ProcedureTypeModel from './../../models/procedureTypeModel';

type Props = {
  procedureTypes: List<ProcedureTypeModel>,
  subItems: Array<string>,
};

const Container = glamorous.div({
  backgroundColor: colours.grey1,
  padding: '20px 55px',
});

/**
 * A component to display Lab test SubItems.
 * @param {Props} props The component props.
 * @returns {React.Component}
 */
const LabTestSubItems = ({ procedureTypes, subItems }: Props) =>
  <Container>
    <span className="u-strong">{translate('test_includes')}</span>
    {
      procedureTypes.filter(procedureType => subItems.includes(procedureType.get('_id')))
        .map(procedureType => (
          <div>
            {procedureType.getName()}
          </div>
        ))
    }
  </Container>;

export default LabTestSubItems;
