import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import translate from '../../utils/i18n';
import { getConfirmation } from '../../utils/utils';
import { renderListItem } from '../../utils/tables';
import { createSuccessNotification } from '../../utils/notifications';
import ContentTransition from '../contentTransition';
import Header from '../header/header';
import Table from '../table/table';
import Button from '../buttons/button';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission, hasPermission } from '../../utils/permissions';
import type { Config, SaveModel, User } from '../../types';
import DosingRegimenForm from './dosingRegimenForm';
import DosingRegimenModel from '../../models/dosingRegimenModel';

const COLUMNS = [
  { accessor: 'name', Header: translate('name'), filterable: true },
  { accessor: 'frequency', Header: translate('frequency_per_day'), filterable: true },
  { accessor: 'interval', Header: translate('interval_in_hours'), filterable: false, sortable: true },
  { accessor: 'administration_first_time', Header: translate('administration_first_time'), filterable: false },
  { accessor: 'administration_time', Header: translate('administration_time'), filterable: false },
  { accessor: 'administration_desc', Header: translate('administration_desc'), filterable: false },
];

type Props = {
  config: Config;
  updateConfig: (config: Config) => void,
  saveModel: SaveModel;
  user: User;
  dosingRegimens: List<DosingRegimenModel>,
}

/**
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const DosingRegimen = (props: Props) => {
  const [showDosingRegimenForm, setShowDosingRegimenForm] = useState(false);
  const [selectedDosingRegimen, setSelectedDosingRegimen] = useState<DosingRegimenModel>();

  useEffect(() => {

  }, []);

  /**
   * prepares columns data for the table.
   * @returns {Array<Column>}
   */
  const getColumns = () => {
    const modColumns = [...COLUMNS];
    if (hasPermission(props.user, List([createPermission('dosing_regimen', 'update')]))) {
      modColumns.push({ accessor: 'edit', Header: '', sortable: false, show: true, width: 80 });
    }
    if (hasPermission(props.user, List([createPermission('discounts_charges', 'delete')]))) {
      modColumns.push({
        accessor: 'delete', Header: '', sortable: false, width: 80, show: true,
      });
    }
    return modColumns;
  };

  /**
   * Hides Dosing regimen form modal
   * @returns {void}
   */
  const hideModal = () => {
    setSelectedDosingRegimen(undefined);
    setShowDosingRegimenForm(false);
  };

  /**
   * Deletes the selected dosing regimen model
   * @param {DosingRegimenModel} dosingRegimen dosingRegimen to delete
   * @returns {Promise<any>}
   */
  const deleteDosingRegimen = (dosingRegimen: DosingRegimenModel) => {
    getConfirmation(translate('confirm_x_deletion', { x: dosingRegimen?.get('name') }), {
      modalTitle: `${translate('delete_x', { x: dosingRegimen?.get('name') })}`,
      footerSaveButtonName: translate('delete_x', { x: 'Dosing Regimen' }),
    })
      .then(() => props.saveModel(dosingRegimen?.set('hidden', true)).then(() => {
        createSuccessNotification(translate('item_deleted'));
        hideModal();
      }));
  };

  /**
   * Returns table row data
   * @returns {Array<Row>}
   */
  const getFlowRows = () => props.dosingRegimens.map((regimen) => {
    return ({
      name: regimen.get('name'),
      frequency: regimen.get('frequency'),
      interval: regimen.get('interval'),
      administration_first_time: regimen.get('administration_first_time'),
      administration_time: regimen.get('administration_time'),
      administration_desc: regimen.get('administration_description'),
      edit: (
        <Button
          className="o-text-button o-text-button--contextual"
          onClick={() => {
            setSelectedDosingRegimen(regimen);
            setShowDosingRegimenForm(true);
          }}
          dataPublic
        >
          {translate('edit')}
        </Button>
      ),
      delete: (
        <Button
          className="o-text-button o-text-button--danger"
          onClick={() => deleteDosingRegimen(regimen)}
          dataPublic
        >
          {translate('delete')}
        </Button>
      ),
    });
  }).toArray();

  return (
    <ContentTransition>
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <h1 className="o-title">{translate('dosing_regimen')}</h1>
        <div className="o-card u-margin-bottom--4ws">
          <Header className="o-card__header" dataPublic>
            <h1 className="o-card__title">{ translate('dosing_regimen') }</h1>
          </Header>
          <div className="o-header-actions">
            <PermissionWrapper permissionsRequired={List([createPermission('dosing_regimen', 'create')])} user={props.user}>
              <div className="u-margin-left--half-ws u-margin-right--half-ws u-margin-top--half-ws u-margin-bottom--half-ws">
                <button className="o-button o-button--small" onClick={() => setShowDosingRegimenForm(true)}>{translate('add_new_x', { x: translate('dosing_regimen') })}</button>
              </div>
            </PermissionWrapper>
          </div>
          <div className={`${hasPermission(props.user, List([createPermission('dosing_regimen', 'update')])) || hasPermission(props.user, List([createPermission('dosing_regimen', 'delete')])) ? 'rt-frozen-cols-2' : ''}`}>
            <Table
              columns={getColumns()}
              data={getFlowRows()}
              noDataText="No Dosing Regimen found"
              defaultSorted={[{ id: 'order', desc: false }]}
            />
          </div>
        </div>
      </section>
      {showDosingRegimenForm &&
        <DosingRegimenForm
          dosingRegimen={selectedDosingRegimen}
          saveModel={props.saveModel}
          isVisible={showDosingRegimenForm}
          hideModal={hideModal}
          user={props.user}
        />}
    </ContentTransition>
  );
};

export default DosingRegimen;
