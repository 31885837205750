import React from 'react';
import { List, Map } from 'immutable';

import InventoryMapModel from './../../models/inventoryMapModel';
import DrugModel from './../../models/drugModel';
import translate from './../../utils/i18n';
import PatientModel from './../../models/patientModel';
import PatientProfileForm from './../patientRegistration/patientProfileForm';
import { createSuccessNotification } from './../../utils/notifications';
import { compareByAlphabeticalOrder } from './../../utils/comparators';
import { getReferralQueryString, isReferralMode } from './../../utils/router';

import type { ActiveIngredient, Config, SaveModel, User } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PatientStubModel from '../../models/patientStubModel';
import AllergyModel from './../../models/allergyModel';

type Props = {
  config: Config,
  patient: PatientModel,
  coveragePayors: List<CoveragePayorModel>,
  saveModel: SaveModel,
  user: User,
  patientStubs: List<PatientStubModel>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
  allergies: List<AllergyModel>,
};

/**
 * Edit wrapper for PatientProfileForm.
 * @class PatientProfileEditor
 * @extends {React.PureComponent}
 */
class PatientProfileEditor extends React.PureComponent<Props> {
  /**
   * Saves the patient attributes and returns to patient page.
   * @param {object} attributes The new attributes.
   * @returns {Promise<boolean>}
   */
  onSaveClicked = (attributes: { [key: string]: string | Array<string> }): Promise<boolean> =>
    this.props.saveModel(this.props.patient.set(attributes))
      .then(() => {
        createSuccessNotification(translate('patient_edit_success', { name: attributes.patient_name }));
        location.hash = `/patient/${this.props.patient.get('_id')}${getReferralQueryString()}`;
        return true;
      });

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    return (
      <section className="o-scrollable-container" style={{ height: isReferralMode() ? '50vh' : '100vh' }}>
        <div className="o-form u-margin-bottom--4ws">
          <h1 data-public className="o-form__title">{translate('edit_patient_profile')}</h1>
          <PatientProfileForm
            patient={this.props.patient}
            config={this.props.config}
            coveragePayors={this.props.coveragePayors && this.props.coveragePayors.sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))}
            initialAttributes={this.props.patient.attributes}
            onSaveClicked={this.onSaveClicked}
            user={this.props.user}
            patientStubs={this.props.patientStubs}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            saveModel={this.props.saveModel}
            verifiedDrugs={this.props.verifiedDrugs}
            drugs={this.props.drugs}
            allergies={this.props.allergies}
          />
        </div>
      </section>
    );
  }
}

export default PatientProfileEditor;
