import React from 'react';
import type { Map } from 'immutable';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import Table from './../table/table';
import { isLoading, sumAndFormatPrice } from './../../utils/utils';
import { renderWithLink, renderDateWithLink, renderPriceWithLink } from './../../utils/tables';
import { sortByDate } from './../../utils/comparators';
import { UNICODE } from './../../constants';
import StatelessModal from './../modals/statelessModal';
import PaymentForm from './paymentForm';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Button from './../buttons/button';
import Header from './../header/header';
import TableExportPrintOptions from '../table/tableExportPrintOptions';
import { convertToCSV, downloadCSV } from '../../utils/export';
import { prettifyDateTime } from '../../utils/time';
import { printPaymentsOutstandingReport } from '../../utils/print';

import type BillModel from './../../models/billModel';
import type PatientStubModel from '../../models/patientStubModel';
import type ReceivableModel from '../../models/receivableModel';
import type PaymentTypeModel from '../../models/paymentTypeModel';
import type { SaveModels, SaveModel, User, MapValue, Config } from './../../types';
import { debugPrint } from '../../utils/logging';

type Props = {
  receivables: List<ReceivableModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  user: User,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  paymentTypes: List<PaymentTypeModel>,
  patientStubsMap: Map<string, PatientStubModel>,
  billsMap: Map<string, BillModel>,
  config: Config,
};

type State = {
  isModalVisible: boolean,
  modalReceivableId: string,
  exportPrintAttributes: {
    appendTotalsToExport: boolean,
    title: string,
    isLandscape: boolean,
  }
};

const COLUMNS = [
  { accessor: 'date', Header: translate('date'), Cell: renderDateWithLink, sortMethod: sortByDate },
  { accessor: 'name', Header: translate('patient_name'), filterable: true, Cell: renderWithLink },
  { accessor: 'ic_number', Header: translate('ic_number'), filterable: true, Cell: renderWithLink },
  { accessor: 'case_id', Header: translate('case_id'), filterable: true, Cell: renderWithLink },
  { accessor: 'encounter_fees', Header: translate('encounter_fees'), Cell: renderPriceWithLink },
  { accessor: 'amount_outstanding', Header: translate('amount_outstanding'), Cell: renderPriceWithLink },
  { accessor: 'actions', Header: translate('actions'), sortable: false, Cell: renderWithLink },
];

/**
 * A component that dispalys a table containing all receivables that have not been paid in full.
 * @class PaymentsOutstandingTable
 * @extends {React.Component<Props, State>}
 */
class PaymentsOutstandingTable extends React.Component<Props, State> {
  /**
   * Creates an instance of PaymentsOutstandingTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modalReceivableId: '',
      exportPrintAttributes: {
        title: 'Payments Outstanding',
        isLandscape: true,
        appendTotalsToExport: false,
      },
    };
  }

  /**
   * Returns a row for an individual receivable
   * @param {ReceivableModel} receivable A receivable model.
   * @returns {{}}
   */
  getRow(receivable: ReceivableModel) {
    const patient = this.props.patientStubsMap.get(receivable.get('patient_id'));
    const bill = this.props.billsMap.get(receivable.get('bill_id'));
    const link = bill ?
      `/patient/${receivable.get('patient_id')}/billing/${bill.get('encounter_id')}` :
      `/patient/${receivable.get('patient_id')}`;
    return {
      date: receivable.get('timestamp'),
      name: patient ? patient.get('patient_name', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      ic_number: patient ? patient.get('ic', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : UNICODE.EMDASH,
      encounter_fees: receivable.get('amount'),
      amount_outstanding: receivable.get('amount_due'),
      link,
      actions: (
        <PermissionWrapper permissionsRequired={List([createPermission('payments', 'create')])} user={this.props.user}>
          <Button
            className="o-button o-button--small"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              this.setState({
                isModalVisible: true,
                modalReceivableId: receivable.get('_id'),
              });
            }}
            dataPublic
          >
            {translate('make_payment')}
          </Button>
        </PermissionWrapper>
      ),
    };
  }

  /**
   * Returns total outstanding amount
   * @param {Array<Row>} tableData Table data
   * @returns {number}
   */
  getTotalOutstanding(tableData: Array<MapValue>) {
    const prices = tableData.map(d => d.amount_outstanding);
    return sumAndFormatPrice(prices);
  }

  /**
   * Exports the cost of goods sold.
   * @param {Array<Row>} tableData Table data
   * @returns {void}
   */
  onExportClicked(tableData: Array<MapValue>) {
    const columnsToExport = COLUMNS.filter(col => col.accessor !== 'actions');
    let exportData = tableData
      .map(datum =>
        columnsToExport.map((column) => {
          if (column.accessor === 'date') {
            return Number.isInteger(datum[column.accessor]) ?
              prettifyDateTime(parseInt(datum[column.accessor], 10)) : datum[column.accessor];
          }
          return datum[column.accessor];
        }));
    if (this.state.exportPrintAttributes.appendTotalsToExport) {
      exportData = exportData.concat([
        [translate('total_outstanding'), this.getTotalOutstanding(tableData)],
      ]);
    }
    downloadCSV(
      `${this.state.exportPrintAttributes.title || 'Payments Outstanding'}.csv`,
      convertToCSV(columnsToExport.map(c => c.Header), exportData),
    );
  }

  /**
   * Prints the cost of goods sold.
   * @param {Array<Row>} tableData Table data
   * @returns {Promise<void>} A promise upon print completion.
   */
  onPrintClicked(tableData: MapValue[]): Promise<void> {
    const columnsToPrint = COLUMNS.filter(col => col.accessor !== 'actions')
      .map(c => ({ value: c.accessor, label: c.Header }));
    const data = tableData.map(item => columnsToPrint.map((column) => {
      if (column.value === 'date') {
        return Number.isInteger(item[column.value]) ?
          prettifyDateTime(parseInt(item[column.value], 10)) : item[column.value];
      }
      return item[column.value];
    }));
    const totalOutstanding = this.state.exportPrintAttributes.appendTotalsToExport
      ? this.getTotalOutstanding(tableData) : undefined;
    printPaymentsOutstandingReport(
      columnsToPrint,
      data,
      this.props.config,
      this.state.exportPrintAttributes.title,
      this.state.exportPrintAttributes.isLandscape,
      totalOutstanding,
    );
    return Promise.resolve();
  }


  /**
   * Helper function to update attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  updateExportPrintAttribute(key: string, value: MapValue) {
    this.setState({
      exportPrintAttributes: Object.assign({}, this.state.exportPrintAttributes, { [key]: value }),
    });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering PaymentsOutstandingTable');
    const { receivables, saveModels, paymentTypes, saveModel } = this.props;
    const receivable = this.state.modalReceivableId ?
      receivables.find(r => r.get('_id') === this.state.modalReceivableId) :
      undefined;
    const patient = receivable ?
      this.props.patientStubsMap.get(receivable.get('patient_id')) : undefined;
    const data = this.props.receivables.map(r => this.getRow(r)).toArray();
    return (
      <div className="o-card">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{translate('list_of_patients_with_payments_outstanding')}</h1>
        </Header>
        <div className="o-header-actions">
          <div className="u-flex-left u-margin-left--half-ws">
            <TableExportPrintOptions
              exportPrintAttributes={this.state.exportPrintAttributes}
              updateExportPrintAttribute={
                (key, value) => this.updateExportPrintAttribute(key, value)
              }
              isPrintExportButtonEnabled={this.props.isFetching}
              onExportClicked={() => this.onExportClicked(data)}
              onPrintClicked={() => this.onPrintClicked(data)}
            />
          </div>
        </div>
        <Table
          columns={COLUMNS}
          data={data}
          noDataText={
            translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(this.props.isFetching, 'no_patient_payments_outstanding'))
          }
          loading={this.props.isFetching}
          showPagination
          defaultSorted={[{ id: 'date', desc: true }]}
        />
        <StatelessModal
          id="make-payment-modal"
          visible={this.state.isModalVisible}
          setVisible={(isModalVisible: boolean) => this.setState({ isModalVisible })}
          noButton
          explicitCloseOnly
          title={translate('make_payment')}
          style={{ height: '75vh' }}
          dataPublicHeader
        >
          {
            receivable &&
            <PaymentForm
              receivable={receivable}
              patient={patient}
              onSave={() => this.setState({ isModalVisible: false })}
              saveModels={saveModels}
              saveModel={saveModel}
              paymentTypes={paymentTypes}
            />
          }
        </StatelessModal>
      </div>
    );
  }
}

export default PaymentsOutstandingTable;
