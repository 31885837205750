import React from 'react';
import { P, Div } from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import SaveButton from './../buttons/saveButton';
import { margins } from './../../utils/css';
import translate from './../../utils/i18n';

type Props = {
  visible: boolean,
  title?: string,
  description?: string,
  onSave: () => Promise<any> | void,
  isSaving?: boolean,
  onDiscard: () => void,
  onCancel: () => void,
}

/**
 * A modal informing the use that data will be lost if they continue. Primarily to be used when
 * user is navigating from the form without route change. Example: When editing forms inside modals
 * @returns {React.SFC}
 *
 */
const UnsavedDataModal = ({
  visible,
  title = translate('unsaved_data_will_be_lost'),
  description = translate('unsaved_data_will_be_lost_desc'),
  onSave,
  isSaving,
  onDiscard,
  onCancel,
}: Props) => (
  <StatelessModal
    noButton
    visible={visible}
    noCloseButton
    setVisible={() => {}}
    // onClose={onCancel}
    id="save-prompt-modal"
    title={title}
    explicitCloseOnly
  >
    <P css={{ margin: margins.standardMargins }}>{description}</P>
    <Div css={{ margin: margins.standardMargins, display: 'flex' }}>
      <SaveButton
        onClick={onSave}
        label={translate('save')}
        isSaving={isSaving}
      />
      <button
        className="o-button o-button--danger u-margin-left--1ws"
        onClick={onDiscard}
      >
        {translate('discard')}
      </button>
      <button
        className="o-button o-button--danger u-margin-left--1ws"
        onClick={onCancel}
      >
        {translate('cancel')}
      </button>
    </Div>
  </StatelessModal>
);

export default UnsavedDataModal;
