import React from 'react';
import glamorous from 'glamorous';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis as ReXAxis,
  YAxis as ReYAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { wsUnit, colours } from './../../utils/css';

type Props = {
  data: Array<{
    [key: string]: string | number
  }>,
  xAxisKey: string,
  yAxisLabel: string,
};

const CHART_WIDTH = '99%';
const CHART_HEIGHT = 350;
const CHART_COLOUR = colours.maroon;

const Container = glamorous.div({
  display: 'grid',
  gridTemplateColumns: `auto calc(3 * ${wsUnit})`,
  gridTemplateRows: `${wsUnit} auto`,
});

const YAxis = glamorous.div({
  marginLeft: `${wsUnit}`,
  marginTop: `calc(1.5 * ${wsUnit})`,
  fontWeight: 'bold',
});

const Chart = glamorous.div({
  marginBottom: `${wsUnit}`,
  marginTop: `calc(2 * ${wsUnit})`,
  gridRowStart: 2,
});

/**
 * A component that displays bar chart by using given data.
 * @class BarChart
 * @extends {React.PureComponent<Props>}
 */
class BarChart extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { data, xAxisKey, yAxisLabel } = this.props;
    return (
      <Container>
        <YAxis>
          {yAxisLabel}
        </YAxis>
        <Chart>
          <ResponsiveContainer width={CHART_WIDTH} height={CHART_HEIGHT}>
            <ReBarChart
              data={data}
            >
              <ReXAxis dataKey="name" />
              <ReYAxis />
              <Tooltip />
              <Bar dataKey={xAxisKey} fill={CHART_COLOUR} />
            </ReBarChart>
          </ResponsiveContainer>
        </Chart>
      </Container>
    );
  }
}

export default BarChart;
