import React from 'react';
import { List } from 'immutable';

import TimeChitFormModal from './../timeChits/timeChitFormModal';
import TimeChitModel from './../../models/timeChitModel';
import EditHistory from './../editHistory/editHistory';
import Modal from './../modals/modal';
import PrintButton from './../buttons/printButton';
import { prettifyTime } from './../../utils/time';
import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';

import type { Config, SaveModel, User } from './../../types';

type Props = {
  className: string,
  config: Config,
  disableChanges: boolean,
  timeChit?: TimeChitModel, // Optional. Not providing will render a no time chit msg.
  hideButtons: boolean,
  saveModel: SaveModel,
  user: User,
  isCurrentEncounter: boolean, // Needed to handle permissions correctly
  onPrint?: () => void,
};

type State = {
  isEditModalVisible: boolean,
};

/**
 * A component that display a Time Chit
 * @class TimeChitItem
 * @extends {React.Component}
 */
class TimeChitItem extends React.Component<Props, State> {
  static defaultProps = {
    className: '',
    disableChanges: false,
    hideButtons: false,
    showEmptyItems: false,
    isCurrentEncounter: false,
  };

  /**
   * Creates an instance of TimeChitItem.
   * @param {any} props Initial Props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditModalVisible: false,
    };
  }

  /**
   * Renders an edit button with a modal when activated.
   * @param {TimeChitModel} model The Time Chit model for editing.
   * @returns {React.Component} A react component.
   */
  renderEditButton(model: TimeChitModel) {
    return (
      <PermissionWrapper
        permissionsRequired={List([createPermission(
          this.props.isCurrentEncounter ? 'current_encounter_time_chits' : 'past_encounters_time_chits',
          'update',
        )])}
        user={this.props.user}
      >
        <TimeChitFormModal
          modalId={`timeChitItem-${model.get('_id')}`}
          modalButtonLabel={translate('edit')}
          modalButtonClass="o-text-button o-text-button--contextual"
          modalVisible={this.state.isEditModalVisible}
          isModalVisible={isVisible => this.setState({ isEditModalVisible: isVisible })}
          modalTitle={translate('time_chit')}
          config={this.props.config}
          currentModel={model}
          onSave={(timeChit) => {
            this.setState({ isEditModalVisible: false });
            timeChit
              .setSerialNumber()
              .then(updatedModel => this.props.saveModel(updatedModel));
          }}
          dataPublicHeader
        />
      </PermissionWrapper>
    );
  }

  /**
   * Asks user for confirmation and deletes this components MC if confirmed.
   * @param {TimeChitModel} model The Time Chit model for editing.
   * @returns {undefined}
   */
  onDeleteClicked(model: TimeChitModel) {
    getConfirmation(translate('confirm_item_deletion'))
      .then(
        () => this.props.saveModel(model.set('_deleted', true)),
        () => {},
      );
  }

  /**
   * Renders the delete button for this component.
   * @param {TimeChitModel} model The Time Chit model for editing.
   * @returns {React.Component} A Delete button component.
   */
  renderDeleteButton(model: TimeChitModel) {
    // Wrapped in a div due to some css weirdness.
    return (
      <PermissionWrapper
        permissionsRequired={List([createPermission(
          this.props.isCurrentEncounter ? 'current_encounter_time_chits' : 'past_encounters_time_chits',
          'delete',
        )])}
        user={this.props.user}
      >
        <div>
          <Button
            className="o-text-button o-text-button--danger"
            onClick={() => this.onDeleteClicked(model)}
            dataPublic
          >
            {translate('delete')}
          </Button>
        </div>
      </PermissionWrapper>
    );
  }

  /**
   * Renders the details button for this component.
   * @param {TimeChitModel} timeChit The medical certificate to display details
   * for.
   * @returns {React.Component} A details button component.
   */
  renderDetailsButton(timeChit: TimeChitModel) {
    // Wrapped in a div due to some css weirdness.
    return (
      <Modal
        buttonLabel={translate('view_details')}
        buttonClass="o-text-button o-text-button--contextual"
        title={translate('time_chit')}
        dataPublic
      >
        <div className="u-padding--standard">
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{translate('start_time')}</span>
            <span className="c-patient-card__item__value">{prettifyTime(timeChit.get('start_time', UNICODE.EMDASH, false))}</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{translate('end_time')}</span>
            <span className="c-patient-card__item__value">{prettifyTime(timeChit.get('end_time', UNICODE.EMDASH, false))}</span>
          </div>
          <div className="c-patient-card__item">
            <span data-public className="c-patient-card__item__key">{translate('notes')}</span>
            <span className="c-patient-card__item__value">{ timeChit.get('notes', UNICODE.EMDASH, false) }</span>
          </div>
        </div>
      </Modal>
    );
  }

  /**
   * Returns the description string for this time chit.
   * @returns {string} Description string.
   */
  getDescription(): string {
    return this.props.timeChit ?
      this.props.timeChit.getDescription() : translate('no_time_chit_issued');
  }

  /**
   * Returns a notes string for the TC or an empty string if there is no MC.
   * @returns {string}
   */
  getNotes() {
    const { timeChit } = this.props;
    return timeChit ? `${translate('notes')}: ${timeChit.get('notes', '-', false)}` : '';
  }

  /**
   * Renders the actions for this time chit.
   * @param {TimeChitModel} timeChit The time chit model.
   * @returns {React.Node}
   */
  renderActions(timeChit: TimeChitModel) {
    return (
      <div className="o-data-list__row__actions">
        { this.props.timeChit && <PrintButton onPrint={this.props.onPrint} /> }
        {this.renderDetailsButton(timeChit)}
        {
          timeChit.hasBeenSaved() &&
          <EditHistory
            model={timeChit}
            buttonLabel="View History"
            buttonClass="o-text-button o-text-button--contextual"
          />
        }
        { !this.props.disableChanges && this.renderEditButton(timeChit) }
        { !this.props.disableChanges && this.renderDeleteButton(timeChit) }
      </div>
    );
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div className={`o-data-list__row ${this.props.className}`}>
        <div className="o-data-list__row__item o-data-list__row__item--small">{translate('time_chit')}</div>
        <div className="o-data-list__row__item">
          <p className="u-font-small" style={{ marginBottom: '5px' }}>{this.getDescription()}</p>
          <p className="u-font-small">{this.getNotes()}</p>
        </div>
        {
          this.props.timeChit &&
          !this.props.hideButtons &&
          this.renderActions(this.props.timeChit)
        }
      </div>
    );
  }
}

export default TimeChitItem;
