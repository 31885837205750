
import BaseModel from './baseModel';

export type MetricDatatype = {
  type: 'string' | 'float' | 'integer',
  range?: string,
}

type Attributes = {
  _id: string,
  type: 'metric_type',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  name: string,
  display_format: string,
  datatype: Array<MetricDatatype>,
  unit: string,
  hidden?: boolean,
};

/**
   * MetricTypeModel
   *
   * @namespace MetricTypeModel
   */
class MetricTypeModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'metric_type';
  }

  /**
   * Sets the metric type visibility, sets 'hidden' to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered MetricTypeModel.
   */
  setVisible(isVisible: boolean): MetricTypeModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Converts the display_format of this model to show the values given and the unit label.
   * @param {(Array<string | number>)} values An array of values that should match the length of
   * the number of datatypes of this model.
   * @returns {string}
   */
  formatValues(values: Array<string | number>): string {
    let string = this.get('display_format', '')
      .replace(/{u}/g, this.get('unit', 'unit'));
    values.forEach((value) => {
      string = string.replace('{v}', value);
    });
    return string;
  }

  /**
   * Returns the display_format as an array of strings, with {u} replaced by the unit name
   * and {v} separated into its own items in the array.
   * e.g. '{v}/{v} {u}' for blood pressure becomes ['{v}', '/', '{v}', ' mmmHg']
   * @returns {Array<string>}
   */
  getDisplayFormatArray(): Array<string> {
    return this.get('display_format', '')
      .replace(/{u}/g, this.get('unit', 'unit'))
      .split(/({v})/)
      .filter(item => item !== '');
  }
}

export default MetricTypeModel;
