import { connect } from 'react-redux';
import { Map } from 'immutable';

import { updateConfig, updateKlinifyConfig } from './../actions';
import ClinicSettings from './../components/settings/clinicSettings';

import type { Dispatch, Config, State, ReactRouterProps } from './../types';
/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ salesItems, user }: State,
  { location }: ReactRouterProps) => (
  {
    salesItems: Map(salesItems.filter(i => !i.getOrFalse('hidden')).map(s => [s.get('_id'), s])),
    user,
    isQueueSetting: (location.pathname === '/settings/queue'),
  }
);

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  updateklinifyConfig: (config: Config) => dispatch(updateKlinifyConfig(config)),
});

const ClinicSettingsContainer =
  connect(mapStateToProps, mapDispatchToProps)(ClinicSettings);

export default ClinicSettingsContainer;
