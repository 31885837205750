/* eslint no-console: 0 */
import { fromJS, Map, List, is } from 'immutable';
import moment from 'moment';
import pluralize from 'pluralize';
import { createConfirmation } from 'react-confirm';
import SearchApi, { INDEX_MODES } from '../../static/libs/js-worker-search/js-worker-search';
// import SearchApi, { INDEX_MODES } from 'js-worker-search';
import Confirm, { ConfirmProps } from './../components/prompts/confirm';
import DefaultConfig from './../config/defaultConfig';
import translate from './i18n';
import { UNICODE } from './../constants';
import { getDeviceIdCookie, setDeviceIdCookie } from './cookie';
import { createStaticErrorNotification } from './notifications';
import { isProduction, debugPrint } from './logging';

import type { Config, AssetObject, MapValue, Column, CustomColumn } from './../types';
import type PatientStubModel from './../models/patientStubModel';
import type DrugModel from './../models/drugModel';
import { handleApiError, handleUnauthorisedApiResponse } from './response';
import APIError from './apiError';

export { queryMapping } from './queryMap';

/**
 * Convenience function to deeply convert an object to a Immutable Map. We use this instead of
 * fromJS so that Flow explicitly knows that it is okay to use. Typecasting to any is kind of lame,
 * but the alternative is a lot more Records which will take time to implement.
 * @export
 * @param {{}} object The object to convert
 * @returns {Map<MapValue, MapValue>}
 */
export function mapFromJS(object: { [key: string]: MapValue }): Map<MapValue, MapValue> {
  // If we pass an object in, this will be a map
  return (fromJS(object) as Map<MapValue, MapValue>);
}


/**
 * Returns exactly what was passed in.
 * @param {any} data any data
 * @returns {any}
 */
export function identityFn(data: any) {
  return data;
}
/**
 * Creates a random string of 4 hex digits. Helper function for generateGUID
 * @return {string} Random string of 4 hex digits.
 */
function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

/**
 * Gets the config file for the app. This is a convenience function to enable getConfig in testing
 * when there is no initialisation. This function should generally be avoided and config should be
 * accessed normally via Redux where possible.
 * @returns {Immutable.Map} The config object.
 */
export function getConfig(): Config {
  if (typeof (window) !== 'undefined' && window.getConfig) {
    return window.getConfig();
  }
  return mapFromJS(DefaultConfig);
}

/**
 * Recursively merges Immutable Maps, without merging Lists.
 * @param {MapValue} oldValue The old value to merge.
 * @param {MapValue} newValue the new value to merge.
 * @returns {MapValue} The merged value.
 */
export function mergeWithoutLists(oldValue: MapValue, newValue: MapValue) {
  if (Map.isMap(oldValue) && Map.isMap(newValue)) {
    return oldValue.mergeWith(mergeWithoutLists, newValue);
  }
  return newValue;
}

/**
 * Generates a random GUID.
 * @return {string} Random string.
 */
export function generateGUID(): string {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

/**
 * Gets the base URL for the app. (No trailing slash)
 * @returns {string}
 */
export function getBaseUrl(): string {
  return `${location.protocol}//${location.host}`;
}

/**
 * Gets the base URL for the api serivce. (No trailing slash)
 * @returns {string}
 */
export function getBaseApiUrl(): string {
  return `${getBaseUrl()}/api`;
}

/**
 * Gets the clinic id from the url
 * @returns {string}
 */
export function getClinicID(): string {
  return isProduction() ? location.host.split('.')[0] : new URL(process.env.KLINIFY_SERVER_URL || '')?.host.split('.')[0] || 'dev';
}

/**
 * Takes a string of a number or a number and returns the number to two decimal places. If string
 * can't be coerced to a number then the string `<label> -` is returned.
 * @param {(number | string)} number The number to convert
 * @returns {string} The converted price.
 */
export function convertNumberToPrice(number: number | string | void): string {
  const label = getConfig().get('currency_shorthand_label', 'RM');
  if (number === undefined || number === null || isNaN(number) || number === '') {
    return `${label}${UNICODE.EMDASH}`;
  }
  if (isNaN(parseFloat(number))) {
    return `${label}${number && number.toString ? number.toString() : number}`; // Somehow we got number that was undefined, so a check is being added. With stricter type checking in the future this should be uncessary.
  }
  // cant use currency formatting in toLocaleString because it shows 'MYR'. Cant figure how to override it to RM. When we do, we should simplify this to use that.
  return `${parseFloat(number) < 0 ? '-' : ''}${label}${parseFloat(Math.abs(number)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}

/**
 * Takes a string of a number or a number and returns the number to two decimal places. If string
 * can't be coerced to a number then the string is returned.
 * @param {(number | string)} value The value to convert
 * @returns {string} The converted price.
 */
export function convertNumberOrStringToPrice(value: number | string | void): string {
  if (isNaN(value) || value === '') {
    return `${value}`;
  }
  return convertNumberToPrice(value);
}

/**
 * Check if the given datatype is a type of image
 * @param {string} datatype the image datatype
 * @returns {boolean}
 */
export function isImageFile(datatype: string) {
  const validDatatypes = [
    'image/jpeg',
    'image/png',
  ];
  return validDatatypes.includes(datatype);
}

/**
 * Check if the given datatype is a editable file
 * @param {string} datatype the file datatype
 * @returns {boolean}
 */
export function isEditableFile(datatype: string) {
  const validDatatypes = [
    'image/jpeg',
    'image/png',
    'text/plain',
  ];
  return validDatatypes.includes(datatype);
}

/**
 * Check if the value is a Javascript Primitive (string, number or Boolean)
 * @param {any} value The value to check
 * @returns {boolean} true if it's a primitive
 */
export function isPrimitive(value: string | number | boolean) {
  return (
    typeof value === 'string'
    || value instanceof String
    || (typeof value === 'number' && Number.isFinite(value))
    || typeof value === 'boolean'
  );
}

/**
 * Check if the value is a Javascript Plain Object (string, number or Boolean)
 * @param {any} value The value to check
 * @returns {boolean} true if it's a Object
 */
export function isObject(value: any) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

/**
 * handles all backend response errors, as a common handler to display tooltip error message.
 * @param {Object} error Error
 * @param {msg} msg response error message if any
 * @returns {void}
 */
export function handleResponseError(
  error: object,
  msg?: string,
) {
  const message = msg || translate('request_could_not_be_completed_due_to_some_error');
  createStaticErrorNotification(message);
  handleApiError({ error, message, notificationType: 'silent' });
}

/**
 * Validates a string or string values in an object (i.e. if it is undefined, cast to empty string), then trims it and returns
 * This is useful for validating if a user input is empty or not (i.e. a category named '     '
 * will return as '' allowing for easier identification as invalid).
 * @param  {({} | string | Array)} value The string or object to check.
 * @param {?Array<string>} fieldsToRemove Array of fields to be remove from the object.
 * @return {string} The trimmed string. If it was invalid an empty string is returned.
 */
export function validateAndTrimString(
  value: string | { [key: string]: any },
  fieldsToRemove: Array<string> = [],
): string | Array<any> | { [key: string]: any } {
  if (Array.isArray(value) && value.length) {
    if (typeof value[0] === 'string') {
      return value.map(f => f && f.trim());
    }
    return value;
  }
  if (typeof value === 'object' && value !== null && Object.keys(value).length) {
    return Object.keys(value)
      .filter((k) => {
        if (fieldsToRemove && fieldsToRemove.length) {
          return !fieldsToRemove.includes(k);
        }
        return k;
      })
      .reduce((acc, curr) => ({ ...acc, [curr]: typeof value[curr] === 'string' ? value[curr].trim() : value[curr] }), {});
  }
  if (typeof value === 'string') {
    return value.trim();
  }
  return '';
}

/**
   * Uploads an asset to the give URL and returns a promise.
   * @param {string} url The URL to send to.
   * @param {string} method A HTTP request method (e.g. 'POST')
   * @param {FormData} formData A FormData object.
   * @return {Promise} A promise for the completion of the asset upload.
   */
function uploadAssetAsPromise(url: string, method: string, formData: FormData): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.onload = function onLoad() {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = function onError() {
      if (xhr.readyState === 4 && xhr.status === 0) {
        const error = new APIError(`Uploading asset to ${url} failed`, 401, {});
        handleApiError({ error });
      } else {
        const error = new APIError(`Uploading asset to ${url} failed`, xhr.status, {});
        handleApiError({ error });
      }
      reject(new Error(xhr.statusText));
    };
    xhr.send(formData);
  });
}

/**
 * Saves a single asset Blob.
 * @param {Blob} blob An asset Blob.
 * @returns {Promise<AssetObject>}
 */
export function saveBlob(blob: Blob): Promise<AssetObject> {
  const url = isImageFile(blob.type) ? '/api/asset?request_cache="size=300"' : '/api/asset';
  const formData = new FormData();
  formData.append('file', blob);
  // TODO: better error catch.
  return uploadAssetAsPromise(url, 'POST', formData)
    .then(response => ({ id: JSON.parse(response)._id, datatype: blob.type }))
    .catch((error) => {
      debugPrint(error, 'error');
      const isUnauthorized = error.toString().split(' ').includes('401');
      if (isUnauthorized) {
        handleUnauthorisedApiResponse(401);
        throw new Error('Not authorized to upload asset');
      }
      handleApiError({ error, notificationType: 'silent' });
      throw new Error('Asset upload failed');
    });
}

/**
 * Saves an array of blobs as Assets.
 * @param {Array<Blob>} blobs An array of asset blobs.
 * @returns {Promise<Array<AssetObject>>}
 */
export function saveBlobs(blobs: Array<Blob>): Promise<Array<AssetObject>> {
  return blobs.reduce((previousPromise, currentBlob) =>
    previousPromise.then(assetData => saveBlob(currentBlob).then((data) => {
      assetData.push(data);
      return assetData;
    })), Promise.resolve([]));
}

/**
   * Converts a data URI to a blob. Main use is image data to file conversion.
   * Borrowed from
   * http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
   * @param {string} dataURI The dataURI to convert.
   * @return {Blob} The converted dataURI.
   */
export const dataURItoBlob = (dataURI: string): Blob => {
  const split = dataURI.split(',');
  const metadata = split.shift();
  const data = split.join(',');
  let byteString;
  if (metadata.indexOf('base64') >= 0) {
    byteString = atob(data);
  } else {
    byteString = unescape(data);
  }

  const mimeString = metadata.split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  let i = 0;
  while (i < byteString.length) {
    ia[i] = byteString.charCodeAt(i);
    i += 1;
  }
  return new Blob([ia], { type: mimeString });
};

/**
   * Returns an array of integers from start to end.
   * @param {number} start The value of the first number in the range.
   * @param {number} end The value of the last number in the range.
   * @return {number[]} An array of integers incrementing by 1 from start to end.
   */
export const getRange = (start: number, end: number): Array<number> =>
  Array.from({ length: (end - start) }, (v: void, k: number): number => k + start);

/**
 * Fetches an asset from the API.
 * @param {string} assetID An asset ID.
 * @param {string} assetDatatype The datatype of the asset.
 * @returns {Promise<Response>}
 */
export function fetchAsset(assetID: string, assetDatatype: string): Promise<Response | void> {
  const url = isImageFile(assetDatatype) ?
    `${getBaseApiUrl()}/asset/${assetID}?auto_orient=1` :
    `${getBaseApiUrl()}/asset/${assetID}`;
  return fetch(new Request(url), {
    credentials: 'same-origin',
  }).then((response) => {
    if (response.ok) {
      return response;
    }
    handleResponseError(response, response.statusText);
    return response; // returning response here is not needed as this is not success, this is used at multiple places and cannot test it so leaving it this way for now
  })
    .catch((error) => {
      debugPrint(error, 'error');
      handleResponseError(error);
    });
}

/**
 * Fetches a text asset from the API.
 * @param {string} assetID An asset ID.
 * @returns {Promise<string | ArrayBuffer>}
 */
export function fetchTextAsset(assetID: string): Promise<string> {
  return fetchAsset(assetID, 'text/plain')
    .then((response) => {
      if (!response || !response.ok) {
        return 'An error occurred while loading the note.'; // Something went wrong. The error will have been logged in fetchAsset
      }
      return response.blob()
        .then(blob => new Promise((resolve) => {
          const reader = new FileReader();
          reader.addEventListener('loadend', () => {
            if (typeof reader.result !== 'string') {
              throw new Error('Fetched asset was not a string!');
            }
            resolve(reader.result);
          });
          reader.readAsText(blob);
        }));
    });
}

/**
 * Fetches an asset from the API and return it as DataURL.
 * @param {string} assetID An asset ID.
 * @param {string} datatype The datatype
 * @returns {Promise<string | ArrayBuffer>} base64
 */
export function fetchDataAsset(assetID: string, datatype: string): Promise<string> {
  return fetchAsset(assetID, datatype)
    .then((response) => {
      if (!response || !response.ok) {
        return 'An error occurred while loading the note.'; // Something went wrong. The error will have been logged in fetchAsset
      }
      return response.blob()
        .then(blob => new Promise((resolve) => {
          const reader = new FileReader();
          reader.addEventListener('loadend', () => {
            if (typeof reader.result !== 'string' || !reader.result.match(/base64/g)) {
              throw new Error('Fetched asset was not a base64 format!');
            }
            resolve(reader.result);
          });
          reader.readAsDataURL(blob);
        }));
    });
}

/**
 * Deep clones a given object. Only use this for data structures.
 * @param {{}} object The object to clone
 * @returns {{}}
 */
export function deepClone(object: { [key: string]: MapValue }): { [key: string]: MapValue } {
  return mapFromJS(object).toJS();
}

/**
 * Sums a list of numbers
 * @export
 * @param {List<number>} numbers List of numbers
 * @returns {number}
 */
export function sum(numbers: List<number>): number {
  return numbers.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
}

/**
 * Takes a list of numbers which are then summed and converted to a price string.
 * @param {List<number>} prices A list of numbers
 * @returns {string}
 */
export function sumAndFormatPrice(prices: List<number>): string {
  return convertNumberToPrice(sum(prices));
}

/**
 * Check if user is using touch screen device
 * @returns {boolean}
 */
export function isTouchDevice() {
  return 'ontouchstart' in document;
}

/**
 * Returns the API provided link to Freshdesk
 * @returns {string}
 */
export function getFreshdeskLink(): string {
  return `${getBaseApiUrl()}/integrations/freshdesk/login/`;
}

/**
 * Generates a random X digit string and adds the given prefix
 * @param {string} prefix A prefix (or empty string).
 * @param {number} length The length of the id
 * @returns {string}
 */
export function generateCaseId(prefix: string, length: number): string {
  const randomNumber = parseInt(Math.random() * (10 ** length), 10);
  const paddedString = randomNumber.toString().padStart(length, '0');
  return `${prefix}${paddedString}`;
}

/**
 * Attempts to generate a case ID not currently used by any of the supplied patients. If max
 * attempts is surpassed then undefined is returned (to prevent infinite loops in the unlikely case
 * that all IDs are used up).
 * @param {List<PatientStubModel>} patientStubs A list of PatientStubs (should be all in DB for
 * correct results).
 * @param {string} prefix A prefix to add to the ID. Can be an empty string.
 * @param {number} attempts The number of attempts so far.
 * @param {number} length The length of the id. Currently only used for testing, otherwise defaults
 * to six.
 * @returns {string | undefined}
 */
export function getUniqueCaseId(
  patientStubs: List<PatientStubModel>,
  prefix: string,
  attempts: number = 0,
  length: number = 6,
) {
  const MAX_ATTEMPTS = 5;
  const candidate = generateCaseId(prefix, length);
  if (patientStubs.some(p => p.get('case_id') === candidate)) {
    return attempts < MAX_ATTEMPTS ?
      getUniqueCaseId(patientStubs, prefix, attempts + 1, length) : undefined;
  }
  return candidate;
}


/**
 * Gets patient's sex from last character of IC number.
 * @param {string} ic The string entered in the IC field
 * @returns {string} Female for even digits, male for odd digits
 */
export function getSexFromIcNumber(ic: string): string {
  if (ic.length === 12) {
    if (isNaN(ic.substr(-1))) {
      return '';
    }
    return parseInt(ic.substr(-1), 10) % 2 ? 'Male' : 'Female';
  }
  return '';
}

/**
 * Gets date from first 6 characters of IC number. Will try to guess correct year.
 * @param {string} ic The string entered in the IC field
 * @returns {Moment | void}.
 */
export function getDobFromIcNumber(ic: string): moment | void {
  const date = moment(ic.substr(0, 6), 'YYMMDD');
  if (date.isAfter(moment(), 'year')) { // i.e. DOB year is after this year. We dont want 2030 we want 1930.
    date.subtract(100, 'years');
  }
  return date;
}

const confirm = createConfirmation(Confirm);

/**
 * Gets a confirmation dialog. When the function is called it will create a dialog, which when acted
 * upon will return a Promise. If the user clicks OKAY in the confirmation then the first callback
 * of the promise will be used. If the user clicks cancel or dismisses the dialog then the rejection
 * callback is used.
 * @param {string | React.ReactElement} confirmation The confirmation modal message.
 * @param {ConfirmProps} options ReactConfirmProps
 * @returns {Promise}
 */
export function getConfirmation(
  confirmation: string | React.ReactElement,
  options: Partial<ConfirmProps> = {},
): Promise<string> {
  return confirm({ confirmation, ...options });
}

/**
 * Returns threshold value to be used to show low inventory warning. This checks if there is a threshold set at
 * drug level, if not returns the value from config.
 * @param {?DrugModel} selectedDrug The selected Drug
 * @returns {number} The threshold value.
 */
export function getLowInventoryThreshold(selectedDrug: DrugModel | null | undefined): number {
  if (selectedDrug) {
    return selectedDrug.has('low_inventory_threshold')
      && !isNaN(parseInt(selectedDrug.get('low_inventory_threshold'), 10)) ?
      parseInt(selectedDrug.get('low_inventory_threshold'), 10)
      : getConfig().getIn(['inventory', 'lowInventoryWarningThreshold'], 10);
  }
  return getConfig().getIn(['inventory', 'lowInventoryWarningThreshold'], 10);
}

/**
 * Gets the documentTemplate Id for the given print type (if one has been set in config).
 * @param {Config} config The app config
 * @param {('medical_certificate' | 'time_chit' | 'patient_receipt' | 'prescription_labels')} type The
 * print type.
 * @returns {(string | void)}
 */
export function getDocumentTemplateForPrint(
  config: Config,
  type: 'medical_certificate' | 'time_chit' | 'patient_receipt' | 'prescription_labels',
): string | void {
  return config.getIn(['document_templates', 'print_templates', type]);
}

/**
 * Takes a number and returns as positive number always.
 * @param {number | string} value number value
 * @param {number | string} defaultVal default value
 * @param {number} numDecimals number of decimals precision needed
 * @returns {number | string} number without its decimal part
 */
export function toPositiveFixedDecimal(
  value: number | string,
  defaultVal: number | string,
  numDecimals: number,
): number | string {
  if (isNaN(value) || value === '' || value === null) {
    return defaultVal;
  }
  return +Math.abs(parseFloat(value)).toFixed(numDecimals);
}

/**
 * Takes a number and return only the number of decimals passed with number
 * @param {number | string} value number value
 * @param {number | string} defaultVal default value
 * @param {number} numDecimals number of decimals precision needed
 * @returns {number | string} value with numDecimals decimals
 */
export function roundFloat(
  value: number | string,
  defaultVal: number | string,
  numDecimals: number,
): number | string {
  if (isNaN(value) || value === '' || value === null) {
    return defaultVal;
  }
  return +parseFloat(value).toFixed(numDecimals);
}

/**
 * Takes a number and return only formatted number/price value as per our save requirements.
 * Currently we save with 3 decimals precision, hence will call roundFloat with numDecimals as 3.
 * @param {number | string} value number value
 * @param {number | string} defaultVal default value
 * @returns {number | string} value with three decimals
 */
export function roundMoneyForSave(
  value: number | string,
  defaultVal: number | string,
): number | string {
  return roundFloat(value, defaultVal, 3);
}

/**
 * converts the value passed to a number with 2 or given decimals if it is a number
 * @param {any} value value
 * @param {number} decimal places of decimal to fix to, defaults to 2.
 * @returns {string} value converted to two decimals or UNICODE.EMDASH
 */
export function fixedDecimal(value: any, decimal: number = 2) {
  if (isNaN(value) || value === '' || value === null) {
    return UNICODE.EMDASH;
  }
  return parseFloat(value).toFixed(decimal);
}

/**
 * checks if two floating point numbers are equal, by considering a tolerance of =/- 0.01
 * @param {number} val1 value1
 * @param {number} val2 value2
 * @param {number} tolerance tolerance
 * @returns {boolean} true if equal, false otherwise
 */
export function isFloatEqual(val1: number, val2: number, tolerance: number) {
  let difference = val1 - val2;
  if (!isNaN(difference) && difference !== '' && difference !== null) {
    difference = +parseFloat(difference).toFixed(2);
  }
  // adding a tolerance of 0.01 occuring due to 3 decimals we have.
  if (difference <= tolerance && difference >= tolerance * -1) {
    return true;
  }
  return false;
}

/**
 * Removes the last extension from a filename
 * @param {string} fileName filename with or without an extension
 * @returns {string} file name without the extension
 */
export function removeFileNameExtension(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, '');
}

/**
 * checks if two arrays are equal, by using is() from immutable.js
 * @param {Array<MapValue>} val1 value1
 * @param {Array<MapValue>} val2 value2
 * @returns {boolean} true if equal, false otherwise
 */
export function isArrayEqual(val1: Array<MapValue>, val2: Array<MapValue>): boolean {
  if (val1 && val2) {
    return is(fromJS(val1), fromJS(val2));
  }
  return false;
}

/**
 * accepts value of an input and returns empty string if - sign or any other than number
 * and returns the value passed if not negative and if number.
 * @param {any} value value
 * @param {any} defaultValue value
 * @returns {string} value passed or empty ''
 */
export function filterUnwantedCharacters(value: any, defaultValue: any) {
  if (isNaN(value) || value === '' || value === null || value < 0) {
    return defaultValue;
  }
  return value;
}

/**
 * Sets window identifier
 * @return {void}
 */
function setWindowIdentifier() {
  const windowIdentifier = parseInt(1000000 * Math.random(), 10).toString();
  window.sessionStorage.setItem('windowIdentifier', windowIdentifier);
}

/**
 * Returns window identifier
 * @return {string}
 */
function getWindowIdentifier() {
  return window.sessionStorage.getItem('windowIdentifier');
}

/**
 * checks if cookie is set and also if windowIdentifier as unique identifier, else sets both.
 * @returns {void}
 */
export function validateAndSetDeviceWindowId() {
  // check if cookievalue is set in window first, if not create new cookie
  // if user clears cookie from browser the opened window will still have windowIdentifier stored but no cookie,
  // create new windowIdentifier in this case along with new cookie
  if (!getDeviceIdCookie()) {
    // set in window so this is unique for tab too, when new cookie is set, always set it in windowIdentifier
    setWindowIdentifier();
    setDeviceIdCookie(getWindowIdentifier());
  } else if (!getWindowIdentifier() && getDeviceIdCookie()) {
    // new tab scenario where cookie is set, set only windowIdentifier
    setWindowIdentifier();
  }
}

/**
 * returns a unique identifer for a browser window in the format {deviceidcookie}-{windowidentifier}.
 * @returns {string}
 */
export function getDeviceWindowId(): string {
  // since we validate and set the values on app home page load, direct return should return values.
  return `${getDeviceIdCookie()}-${getWindowIdentifier()}`;
}

/**
 * returns the size of a image from its ArrayBuffer representation
 * @param {ArrayBuffer} img Image ArrayBuffer representation
 * @returns {Promise<Array<number>>} Dimensions in the shape of [width, height]
 */
export function sizeOfImage(img: ArrayBuffer): Promise<Array<number>> {
  const arrayBufferView = new Uint8Array(img);
  const blob = new Blob([arrayBufferView], { type: 'image/png' });
  const urlCreator = window.URL || window.webkitURL;
  const imageUrl = urlCreator.createObjectURL(blob);
  const imgElem = new Image();
  imgElem.src = imageUrl;
  return new Promise((resolve) => {
    imgElem.addEventListener('load', () => {
      resolve([imgElem.naturalWidth, imgElem.naturalHeight]);
    });
  });
}

/**
 * Modify the keys that can be accessed in TableColumnSettings
 * @param {Array<Column>} columns columns to be transformed
 * @returns {Array<CustomColumn>}
 */
export function transformColumnKeys(columns: Array<Column>) {
  return columns.map(column =>
    ({
      value: column.accessor,
      label: column.Header,
      show: column.show,
      uncustomizable: column.uncustomizable,
      hideDefault: column.hideDefault,
    }));
}

/**
 * Get filtered columns by building a map of columns and looking by accessor
 * @param {Array<Column>} originalColumns columns to be transformed
 * @param {Array<CustomColumn>} filteredColumns columns to be transformed
 * @returns {Array<CustomColumn>}
 */
export function transformFilteredColumns(
  originalColumns: Array<Column>,
  filteredColumns: Array<CustomColumn>,
) {
  // Create a map of columns, so the lookup for selected columns will be faster
  const columnsMap: {} = originalColumns.reduce(
    (map, column) => {
      map[column.accessor] = column;// eslint-disable-line no-param-reassign
      return map;
    },
    {},
  );
  return filteredColumns.map(c => (columnsMap[c.value]));
}

/**
 * Modify the passed text to remove non printable characters except newline/enter key and return the same.
 * @param {string} text text to filter
 * @returns {string}
 */
export function filterNonPrintableCharsExceptNewline(text: string) {
  // encoded format for enter key is %0A, using this to separate lines
  const lines = encodeURIComponent(text || '').split('%0A');
  // filtering out non printable characters in the string, if any eg: NUL char or \u0000
  const newValue = lines.map(line => (decodeURIComponent(line) || '').replace(/[^ -~]+/g, ''));
  return newValue.join('\n');
}

/**
 * Plurarize word eg. test to tests
 * @param {string} word The word to pluralize
 * @param {number | void} count How many of the word exist
 * @param {boolean | void} withCount Whether to prefix with the number (e.g. 3 ducks)
 * @returns {string}
 */
export function pluralizeWord(word: string, count?: number, withCount?: boolean = true) {
  if (!count) {
    return pluralize(word);
  }
  return pluralize(word, count, withCount);
}

/**
 * Singularize word eg. tests to test
 * @param {string} word The word to pluralize
 * @returns {string}
 */
export function singularizeWord(word: string) {
  return pluralize.singular(word);
}

/**
 * Convert a immutable list to a Map
 * @param {List} list to convert
 * @param {Function} keyFactory factory function to create the key
 * @returns {string}
 */
export const listToMap = (list: List<any>, keyFactory: Function): Map<string, MapValue> =>
  list.reduce((all, e) => all.set(keyFactory(e), e), Map());


/**
* Returns loading when data is in fetching state and also
* workaround to disable "nest ternary expressions" from es-lint.
* @param {boolean} isFetching data is in fetch state.
* @param {string} customKeyword custom translation keyWord as a message.
* @returns {string} translation keyword needed by i18n.
*/
export const isLoading = (isFetching: boolean, customKeyword?: string) => {
  if (isFetching) {
    return 'loading';
  }
  return customKeyword || 'no_items';
};

/**
* Remove only end of the line space for each line of string,
* and does not compress consecutive newlines.
* @param {string} str string which needs to be replaced.
* @returns {string} replaced string
*/
export const removeEndOfLineSpaces = (str: string) => str.replace(/[^\S\r\n]+$/gm, '');

/**
* Check validation of email address
* @param {string} str string which needs to be replaced.
* @returns {string} replaced string
*/
export const isEmailValid = (str: string) => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(str);
};

/**
* get appointment update type
* @param {Object} data update data object
* @returns {string} update type for logging
*/
export const getAppointmentUpdateAPIType = (data: Object) => {
  const { status } = data;
  if (Object.keys(data).length > 1) {
    return `Update Appointment attributes ${Object.keys(data).toString()}`;
  }
  if (data.start_timestamp || data.end_timestamp) {
    return 'Update Appointment Time';
  }
  if (data.consult_type) {
    return 'Update Appointment consult type';
  }
  if (data.notes) {
    return 'Update Appointment notes';
  }
  return status ? `Update Appointment ${status} status` : 'Update Appointment';
};

/**
* Check page is clinic overview page or not
* @param {string} hash of location
* @returns {boolean} true if is clinic overview
*/
export const isClinicOverviewPage = (hash: string) => hash === '#/';

/**
* Adds prefix to string, if the prefix doesnt already exist.
* @param {string} str string which needs to be prefixed.
* @param {string} prefix the prefix string
* @returns {string} replaced string
*/
export const prefixString = (str: string = '', prefix: string = ''): string => {
  if (!str || (str.length <= prefix.length)) {
    return prefix;
  }
  if (str.substr(0, prefix.length) !== prefix) {
    return `${prefix}${str}`;
  }
  return str;
};

/**
 * Convenience method to capitalize the first letter of given string
 * Added because tree-shaking lodash is non-trivial and not worth it for just this.
 * @param {string} value the string to capitalize
 * @returns {string} Capitalized String
 */
export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Initializes and returns a customized instance of JsSearch
 * @returns {SearchApi | undefined} Patient list
 */
export function getJsSearchInstance() {
  const searchApi = new SearchApi({
    indexMode: INDEX_MODES.PREFIXES,
  });
  return searchApi || undefined;
}

/**
* Append the cors-anywhere proxy url to the url
* @param {string} url of location
* @returns {string} appended url
*/
export const getProxyUrl = (url: string) => {
  const corsURL = 'https://cors-anywhere.herokuapp.com';
  try {
    new URL(url);
    return `${corsURL}/${url}`;
  } catch {
    return url;
  }
};

/**
 * Returns clinic config if `premium_feature_enabled` in klinifyConfig is true
 * else klinify config
 * @param {Partial<Config>} clinicConfig key in clinicConfig to check
 * @param {Partial<Config>} klinifyConfig key in klinifyConfig
 * @return {Partial<Config>}
 */
export const getValidConfigKeys = <T extends Partial<Config>>(
  clinicConfig: T,
  klinifyConfig: T,
): T =>
    (klinifyConfig && klinifyConfig.get('premium_features_enabled') ? clinicConfig : klinifyConfig);

/**
 * Returns value for the setings stored in local storage
 * @returns {Map}
 */
export function getWebappSettings() {
  const savedSettings = localStorage.getItem('webapp_settings') || '';
  try {
    return mapFromJS(JSON.parse(savedSettings));
  } catch {
    return Map();
  }
}

/**
 * Sets the arguments in local storage
 * @param {Map<string, any>} config keys to set in local storage
 * @returns {void}
 */
export function setLocalWebappSettings(config: Map<string, any>) {
  const savedSettings = getWebappSettings();
  const newSettings = Map().merge(savedSettings).merge(config);
  localStorage.setItem('webapp_settings', JSON.stringify(newSettings.toJS()));
}

/**
 * get the server name
 * @returns {string}
 */
export function getServerName() {
  return window.location.host.split('.')[0] === 'local'
    ? ((new URL(process.env.KLINIFY_SERVER_URL || '')).hostname?.split('.')[0] || window.location.host.split('.')[0])
    : window.location.host.split('.')[0];
}

/**
 * prepend string if not exists
 * @param {string} prependStr which will be prepend to string
 * @param {string | void} str string
 * @returns {string}
 */
export function getPrependString(prependStr: string, str?: string) {
  if (!str) {
    return '';
  }
  if (str.startsWith(prependStr)) {
    return str;
  }
  return `${prependStr}${str}`;
}
