import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { List, Map } from 'immutable';
import ReactMarkdown from 'react-markdown';
import type { RouteComponentProps } from 'react-router-dom';
import translate from './../../utils/i18n';
import BrandResponseModel from '../../models/pharmaconnect/brandResponseModel';
import { getConfig } from './../../utils/utils';
import { CLICK, PHARMACONNECT_CALLBACK, PHARMACONNECT_BRAND_CARD, trackEvent } from '../../utils/analytics';
import { sendPharmaConnectEmail, savePharmaConnectEvent } from './../../utils/api';
import { wsUnit, colours, fonts } from '../../utils/css';
import { createSuccessNotification, createErrorNotification } from '../../utils/notifications';
import Input from './../inputs/input';
import Textarea from './../inputs/textarea';
import ReadDocumentsModel from '../../models/pharmaconnect/readDocumentsModel';
import Select from '../inputs/select';

interface Props extends RouteComponentProps {
  brandDocuments: List<BrandResponseModel>,
  readDocuments: ReadDocumentsModel,
  brandID: string,
  setPharmaConnectReadDocuments: (documents: ReadDocumentsModel) => void,
  trackBrandEvent: boolean,
}

const LogoWrapper = glamorous.div({
  textAlign: 'center',
  maxWidth: 420,
  marginLeft: 'auto',
  marginRight: 'auto',
});

const Logo = glamorous.img({
  maxHeight: 120,
  maxWidth: '100%',
});

const CoverImage = glamorous.div({
  padding: 64,
  backgroundPosition: 'center bottom',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: colours.grey1,
});

const NewIndicator = glamorous.sup({
  color: colours.red,
  fontSize: '0.8rem',
  verticalAlign: 'top',
});

const Content = glamorous.div({
  margin: wsUnit,
});

const Tabs = glamorous.div({
  textAlign: 'center',
  marginBottom: wsUnit,
});

const TabItem = glamorous.span({
  padding: '6px 12px',
  borderWidth: 1,
  borderColor: colours.maroon,
  borderStyle: 'solid',
  color: colours.maroonDark,
  cursor: 'pointer',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const TabItemSelected = glamorous.span({
  backgroundColor: colours.maroon,
  borderWidth: 1,
  borderColor: colours.maroon,
  borderStyle: 'solid',
  color: colours.white,
  padding: '6px 12px',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const Obj = glamorous.object({
  height: '80vh',
  width: '100%',
});

const Img = glamorous.img({
  maxWidth: '100%',
});

const ImageWrapper = glamorous.div({
  textAlign: 'center',
  padding: wsUnit,
});

const CenteredWrapper = glamorous.div({
  maxWidth: 640,
  margin: `${wsUnit} auto`,
});

const ContactBody = glamorous.div({
  padding: wsUnit,
  '& p': {
    marginBottom: '24px',
  },
  '& img': {
    maxWidth: '100%',
  },
});

const Header = glamorous.p({
  fontFamily: fonts.bold,
});

const Splitter = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: wsUnit,
  marginTop: wsUnit,
});

/* eslint-disable camelcase */
interface Document {
  id: string,
  asset_id: string,
  blob_url: string,
  group: string,
  title: string
  type: string,
}
/* eslint-enable camelcase */

/**
 * Renders a brand page
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
 */
function PharmaConnectBrandPage(props: Props) {
  const defaultClinicName = getConfig().getIn(['clinic', 'name'], '');
  const defaultClinicAddress = getConfig().getIn(['clinic', 'address'], '');

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [clinicName, setClinicName] = useState(defaultClinicName);
  const [clinicAddress, setClinicAddress] = useState(defaultClinicAddress);
  const [message, setMessage] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [selectedDoc, setSelectedDoc] = useState<string | null>(null);
  const { brandDocuments, readDocuments, brandID, setPharmaConnectReadDocuments, trackBrandEvent } = props;

  useEffect(() => {
    if (trackBrandEvent) {
      trackEvent(PHARMACONNECT_BRAND_CARD, CLICK, brandID);
    }
  }, []);

  useEffect(() => {
    setSelectedGroup(null);
    setSelectedDoc(null);
  }, [brandID]);

  /**
   * Saves the viewed pharmaconnect event
   * @param {string} id ID of the document to mark as read
   * @returns {void} void
  */
  const saveReadEvent = (id: string) => {
    savePharmaConnectEvent('READ', 'DOCUMENT', id).then(() => {
      const docs = readDocuments && readDocuments.get('documents', List());
      const newDocs = docs && docs.push({ asset_id: id });
      setPharmaConnectReadDocuments(new ReadDocumentsModel({ documents: newDocs }));
    });
  };

  /**
   * Saves the viewed pharmaconnect event
   * @returns {void} void
  */
  const saveEmailEvent = () => {
    savePharmaConnectEvent('EMAIL', 'BRAND', brandID);
  };

  /**
   * Sends the email
   * @param {Event} event Form event
   * @return {void}
   */
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    sendPharmaConnectEmail(brandID, name, phoneNumber, clinicName, clinicAddress, message)
      .then(() => {
        trackEvent(PHARMACONNECT_CALLBACK, CLICK, brandID);
        createSuccessNotification(translate('pharmaconnect_email_sent'));
        saveEmailEvent();
        setName('');
        setPhoneNumber('');
        setMessage('');
      }).catch(() => {
        createErrorNotification(translate('pharmaconnect_email_failed'));
      });
  };

  /**
   * Render a brochure based on its type
   * @returns {HTMLElement} html element
   */
  const renderContactTab = () => (
    <CenteredWrapper>
      <div className="o-card">
        <form onSubmit={handleSubmit}>
          <ContactBody>
            <Header>{translate('pharmaconnect_contact_header')}</Header>
            <p>{translate('pharmaconnect_disclaimer')}</p>
            <Splitter>
              <div>
                <Input
                  label={translate('name')}
                  id="name"
                  type="text"
                  required
                  value={name}
                  onValueChanged={nameVal => setName(nameVal.toString())}
                />
              </div>
              <div>
                <Input
                  label={translate('phone_number')}
                  id="phone_number"
                  type="text"
                  required
                  value={phoneNumber}
                  onValueChanged={phoneVal => setPhoneNumber(phoneVal.toString())}
                />
              </div>
            </Splitter>
            <Splitter>
              <div>
                <Input
                  label={translate('clinic_name')}
                  id="clinic_name"
                  type="text"
                  required
                  value={clinicName}
                  onValueChanged={val => setClinicName(val.toString())}
                />
              </div>
              <div>
                <Input
                  label={translate('clinic_address')}
                  id="clinic_address"
                  type="text"
                  value={clinicAddress}
                  required
                  onValueChanged={val => setClinicAddress(val.toString())}
                />
              </div>
            </Splitter>
            <Textarea
              label={translate('message')}
              id="message"
              value={message}
              onValueChanged={msgVal => setMessage(msgVal)}
            />
            <button className="o-button" disabled={!(name && phoneNumber && clinicName && clinicAddress)}>{translate('pharmaconnect_contact_cta')}</button>
          </ContactBody>
        </form>
      </div>
    </CenteredWrapper>
  );

  /**
   * Render a brochure based on its type
   * @param {Document} document Document to render
   * @returns {HTMLElement} html element
   */
  const renderBrochure = (document: Document) => {
    if (!document) {
      return (
        <p>{translate('pharmaconnect_document_not_found')}</p>
      );
    }

    switch (document.type) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <ImageWrapper key={document.blob_url}>
            <div className="o-card">
              <Img src={document.blob_url} alt="image" onLoad={() => saveReadEvent(document.id)} />
            </div>
          </ImageWrapper>
        );
      case 'pdf':
        return (
          <Obj
            data={document.blob_url}
            type="application/pdf"
            aria-label="pdf"
            alt="pdf"
            key={document.blob_url}
            onLoad={() => saveReadEvent(document.id)}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Render a brochure based on its type
   * @param {BrandResponseModel} brand Brand object with docs
   * @param {documentGroups} documentGroups brand documents grouped by group
   * @param {string} selectedGroupID ID of the selected group
   * @param {string} selectedDocID ID of the selected document
   * @returns {HTMLElement} html element
   */
  const renderTab = (
    brand: BrandResponseModel,
    documentGroups: Map<string, List<{ id: string, title: string }>>,
    selectedGroupID: string,
    selectedDocID: string,
  ) => {
    switch (selectedGroupID) {
      case 'intro':
        return (
          <div>
            <CenteredWrapper>
              <div className="o-card">
                <ContactBody className="pharmaconnect-about-wrapper">
                  <ReactMarkdown source={brand.get('introduction_md')} linkTarget="_blank" />
                </ContactBody>
              </div>
            </CenteredWrapper>
          </div>
        );
      case 'contact':
        return renderContactTab();
      default: {
        const options = documentGroups
          .get(selectedGroupID, List<{ id: string, title: string }>())
          .map(doc => ({ value: doc.id, label: doc.title }))
          .toArray();
        return (
          <div>
            {options.length > 1 &&
            <Select
              label={translate('pharmaconnect_brochure_choose')}
              id="doc-select"
              onValueChanged={docID => setSelectedDoc(docID)}
              options={options}
              value={selectedDocID}
            />
          }
            {renderBrochure(brand.get('documents', List()).find((d: Document) => d.id === selectedDocID))}
          </div>);
      }
    }
  };

  if (brandDocuments === null) {
    return <h1>{translate('pharmaconnect_brand_not_found')}</h1>;
  }

  const filteredBrands = brandDocuments.filter(doc => doc.get('id') === brandID);
  const brand = filteredBrands.get(0, null);
  if (!brand) {
    return <h1>{translate('pharmaconnect_brand_not_found')}</h1>;
  }

  const brandDocs = brand.get('documents', []);
  const documentGroups = brandDocs.reduce((group: Map<string, List<Document>>, doc: Document) => {
    const groupName = doc.group || doc.title;
    return group.set(groupName, group.get(groupName, List<Document>()).push(doc));
  }, Map<string, List<Document>>());

  const selectedGroupID = selectedGroup || (brand.get('introduction_md') && 'intro') || Array.from(documentGroups.keys())[0] || '';
  const selectedDocID = selectedDoc || (documentGroups.get(selectedGroupID, List())?.first()?.id);
  const readDocumentIDs = ((readDocuments && readDocuments.get('documents')) || []).map((d: Document) => d.asset_id);

  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      <CoverImage style={{ backgroundImage: `url(${brand.get('cover_img_url')})` }}>
        <LogoWrapper>
          <div className="">
            <Logo src={brand.get('logo_url')} alt="logo" />
          </div>
        </LogoWrapper>
      </CoverImage>
      <Content>
        <Tabs>
          {
            brand.get('introduction_md') && ((selectedGroupID === 'intro') ?
              <TabItemSelected key="intro-selected">
                {translate('pharmaconnect_modal_about')}
              </TabItemSelected> :
              <TabItem key="intro" onClick={() => setSelectedGroup('intro')}>
                {translate('pharmaconnect_modal_about')}
              </TabItem>)
          }
          {
            documentGroups.entrySeq()
              .map(([groupID, groupDocs]: [string, List<Document>]) => ((groupID === selectedGroupID)
                ? (
                  <TabItemSelected key={groupID}>
                    {groupID}
                  </TabItemSelected>
                )
                : (
                  <TabItem
                    key={groupID}
                    onClick={() => {
                      setSelectedGroup(groupID);
                      setSelectedDoc(null);
                    }}
                  >
                    {groupID}
                    {
                        !groupDocs.map((d: Document) => d.id)
                          .every((docID: string) => readDocumentIDs.includes(docID))
                        && <NewIndicator>New</NewIndicator>
                      }
                  </TabItem>
                )))
          }
          {
            (selectedGroupID === 'contact') ?
              <TabItemSelected key="contact-selected">
                {translate('contact_us')}
              </TabItemSelected> :
              <TabItem key="contact" onClick={() => setSelectedGroup('contact')}>
                {translate('contact_us')}
              </TabItem>
          }
        </Tabs>
        {renderTab(brand, documentGroups, selectedGroupID, selectedDocID)}
      </Content>
    </section>
  );
}

export default PharmaConnectBrandPage;
