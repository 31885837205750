import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { List } from 'immutable';

import PharmaConnectLink from './../components/sidebar/pharmaconnectLink';
import { setPharmaConnectBrandDocuments, setPharmaConnectReadDocuments } from './../actions';

import type { Dispatch, State } from './../types';
import type BrandResponseModel from '../models/pharmaconnect/brandResponseModel';
import type ReadDocumentsModel from '../models/pharmaconnect/readDocumentsModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps =
  ({
    pharmaconnectViews,
  }: State) => ({
    brandDocuments: pharmaconnectViews.brandDocuments,
    readDocuments: pharmaconnectViews.readDocuments,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPharmaConnectBrandDocuments: (brands: List<BrandResponseModel>) =>
    dispatch(setPharmaConnectBrandDocuments(brands)),
  setPharmaConnectReadDocuments: (documents: ReadDocumentsModel) =>
    dispatch(setPharmaConnectReadDocuments(documents)),
});

const PharmaConnectSidebarContainer =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PharmaConnectLink));

export default PharmaConnectSidebarContainer;
