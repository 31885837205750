import React from 'react';
import { List, Map } from 'immutable';
import Moment from 'moment';

import { sumAndFormatPrice, convertNumberToPrice } from './../../utils/utils';
import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import { downloadCSV, convertToCSV } from './../../utils/export';
import { renderDate, renderPrice } from './../../utils/tables';
import { prettifyDate } from './../../utils/time';
import ContentTransition from './../contentTransition';
import PaymentsTable from './paymentsTable';
import { printPaymentHistory } from './../../utils/print';
import { fetchPatientsByID } from './../../utils/api';
import { sidebarWidth } from './../../utils/css';
import TableDateRangePicker from './../table/tableDateRangePicker';
import TableColumnsSettings from '../table/tableColumnsSettings';
import TableExportPrintOptions from '../table/tableExportPrintOptions';
import { UNICODE } from './../../constants';
import Header from './../header/header';

import type { Config, MapValue, Model, Row, CustomColumn } from './../../types';
import type BillModel from './../../models/billModel';
import type EncounterModel from './../../models/encounterModel';
import type PatientModel from '../../models/patientModel';
import type PaymentModel from '../../models/paymentModel';

const COLUMNS = List([
  { value: 'paymentDate', label: translate('payment_date'), cell: renderDate },
  { value: 'encounterDate', label: translate('encounter_date'), cell: renderDate },
  { value: 'patientName', label: translate('patient_name') },
  { value: 'paymentBy', label: translate('payment_by') },
  { value: 'paymentAmount', label: translate('payment_amount'), cell: renderPrice, renderMethod: 'PRICE' },
  { value: 'paymentVia', label: translate('payment_via') },
]);

// Create an empty row as a template for each row in the table/print out.
const EMPTY_ROW = {};
COLUMNS.forEach((c) => {
  EMPTY_ROW[c.value] = UNICODE.EMDASH;
});

type Props = {
  bills: List<BillModel>,
  config: Config,
  encounters: List<EncounterModel>,
  payments: List<PaymentModel>,
  filter: Map<string, Moment>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  updateConfig: (config: Config) => void,
};

type State = {
  columns: Array<CustomColumn>,
  isFetchingData: boolean,
  data: List<Row>,
  filteredData: List<Row>,
  exportPrintAttributes: {
    appendTotalsToExport?: boolean,
    title: string,
    isLandscape: boolean,
  }
};

/**
 * A component that displays all bills for a clinic.
 * @class PaymentHistory
 * @extends {Component}
 */
class PaymentHistory extends React.Component<Props, State> {
  /**
   * Creates an instance of PaymentHistory.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      columns: COLUMNS.toArray(),
      isFetchingData: true,
      data: List(),
      filteredData: List(),
      exportPrintAttributes: {
        title: translate('payment_history'),
        isLandscape: true,
        appendTotalsToExport: false,
      },
    };
    this.fetchData();
  }

  /**
   * Returns an object with patient data that applies to all relevant payments.
   * @param {PatientModel} patient The patient model
   * @returns {{}}
   */
  getPatientData(patient: PatientModel) {
    return {
      patientName: patient.get('patient_name', UNICODE.EMDASH, false),
    };
  }

  /**
   * Returns an object with encounter data that applies to all relevant payments.
   * @param {EncounterModel | void} encounter The encounter model
   * @returns {{}}
   */
  getEncounterData(encounter: EncounterModel | void) {
    return {
      encounterDate: encounter ? encounter.getDate() : UNICODE.EMDASH,
    };
  }

  /**
   * Returns an object with any bill related data for the payment.
   * @param {BillModel} bill A Bill model.
   * @param {Model} payment payment model matching the bill's ID.
   * @returns {{}}
   */
  getBillRelatedData(bill: BillModel, payment: Model) {
    return {
      paymentVia: translate(payment.get('method', UNICODE.EMDASH)),
      paymentBy: translate(payment.get('payor_type', UNICODE.EMDASH)),
      paymentAmount: payment.get('amount', 0),
      paymentDate: payment.getDate(),
    };
  }

  /**
   * Fetches the necessary data for the tables/exports/printed reports.
   * @returns {void}
   */
  fetchData() {
    if (this.props.bills.size === 0) {
      this.setState({ data: List(), isFetchingData: false });
    } else {
      this.setState({ isFetchingData: true });
      Promise.all([
        fetchPatientsByID(this.props.bills.map(bill => bill.get('patient_id'))),
      ])
        .then(([patients]) => {
          const data = [];
          this.props.bills.map((bill) => {
            const patient = patients.find(p => p.get('_id') === bill.get('patient_id'));
            const encounter = this.props.encounters.find(e => e.get('_id') === bill.get('encounter_id'));
            const payments = this.props.payments.filter(p => p.get('bill_id') === bill.get('_id')
              && p.get('amount', 0) > 0 && p.get('payor_type', UNICODE.EMDASH) === 'patient');
            return payments.map((payment) => {
              data.push(Object.assign(
                {},
                EMPTY_ROW,
                patient ? this.getPatientData(patient) : {},
                this.getEncounterData(encounter),
                this.getBillRelatedData(
                  bill,
                  payment,
                ),
              ));
              return null;
            });
          });
          this.setState({ isFetchingData: false, data: List(data) });
        });
    }
  }

  /**
   * Triggers a data fetch when the bills prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.bills !== this.props.bills) {
      this.fetchData();
    }
  }

  /**
   * Based on the props, determines if the export or print button should be displayed or not.
   * @returns {boolean} Whether or not the print button should display.
   */
  canExport(): boolean {
    return this.props.bills.size > 0
      && this.props.filter.get('filterDateEnd') !== undefined
      && this.props.filter.get('filterDateStart') !== undefined;
  }

  /**
   * Prints the payments history.
   * @returns {Promise<void>} A promise upon print completion.
   */
  onPrintClicked(): Promise<void> {
    const { columns } = this.state;

    const data = this.state.filteredData.map(item => columns.map((column) => {
      if (column.value === 'paymentDate' || column.value === 'encounterDate') {
        return Number.isInteger(item[column.value]) ?
          prettifyDate(parseInt(item[column.value], 10)) : item[column.value];
      }
      if (column.renderMethod === 'PRICE') {
        return convertNumberToPrice(item[column.value]);
      }
      const value = item[column.value] !== undefined ? item[column.value] : UNICODE.EMDASH;
      return value;
    }));

    printPaymentHistory(
      columns,
      data.toArray(),
      {
        total: sumAndFormatPrice(this.state.filteredData.map(d => d.paymentAmount)),
        totalCash: sumAndFormatPrice(this.state.filteredData.map((d) => {
          if (d.paymentVia.toLowerCase() === 'cash') {
            return d.paymentAmount;
          }
          return 0;
        })),
        totalCredit: sumAndFormatPrice(this.state.filteredData.map((d) => {
          if (d.paymentVia.toLowerCase() === 'credit' || d.paymentVia.toLowerCase() === 'card') {
            return d.paymentAmount;
          }
          return 0;
        })),
        totalClaimed: sumAndFormatPrice(List()),
        totalOther: sumAndFormatPrice(this.state.filteredData.map((d) => {
          if (d.paymentVia.toLowerCase() !== 'credit' && d.paymentVia.toLowerCase() !== 'cash'
            && d.paymentVia.toLowerCase() !== 'card') {
            return d.paymentAmount;
          }
          return 0;
        })),
      },
      {
        fromDate: this.props.filter.get('filterDateStart'),
        toDate: this.props.filter.get('filterDateEnd'),
      },
      this.props.config,
      this.state.exportPrintAttributes.title,
      this.state.exportPrintAttributes.isLandscape,
    );
    return Promise.resolve();
  }

  /**
   * Exports the payment history reports.
   * @returns {void}
   */
  onExportClicked() {
    const { columns } = this.state;
    let data = this.state.filteredData.map(item => columns.map((column) => {
      if (column.value === 'paymentDate' || column.value === 'encounterDate') {
        return Number.isInteger(item[column.value]) ?
          prettifyDate(parseInt(item[column.value], 10)) : item[column.value];
      }
      return item[column.value];
    }));
    if (this.state.exportPrintAttributes.appendTotalsToExport) {
      data = data.concat([
        [translate('total_received'), sumAndFormatPrice(this.state.filteredData.map(d => d.paymentAmount))],
        [translate('total_cash_received'), sumAndFormatPrice(this.state.filteredData.map((d) => {
          if (d.paymentVia.toLowerCase() === 'cash') {
            return d.paymentAmount;
          }
          return 0;
        }))],
        [translate('total_credit_received'), sumAndFormatPrice(this.state.filteredData.map((d) => {
          if (d.paymentVia.toLowerCase() === 'credit' || d.paymentVia.toLowerCase() === 'card') {
            return d.paymentAmount;
          }
          return 0;
        }))],
        [translate('total_panel_payments_received'), sumAndFormatPrice(List())],
        [translate('total_other_payments_received'), sumAndFormatPrice(this.state.filteredData.map((d) => {
          if (d.paymentVia.toLowerCase() !== 'credit' && d.paymentVia.toLowerCase() !== 'cash'
            && d.paymentVia.toLowerCase() !== 'card') {
            return d.paymentAmount;
          }
          return 0;
        }))],
      ]);
    }
    downloadCSV(
      `${this.state.exportPrintAttributes.title}.csv`,
      convertToCSV(columns.map(c => c.label), data.toArray()),
    );
  }

  /**
   * Merges the given object with the current filter for payment history and updates them in state.
   * @param {{}} newValues The new filter values to merge.
   * @returns {void}
   */
  updateFilter(newValues: {}) {
    this.props.onFilterUpdated(this.props.filter.merge(newValues));
  }

  /**
   * Helper function to update attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  updateExportPrintAttribute(key: string, value: MapValue) {
    this.setState({
      exportPrintAttributes: Object.assign({}, this.state.exportPrintAttributes, { [key]: value }),
    });
  }

  /**
     * Renders the component.
     * @return {string} - HTML markup for the component
     */
  render() {
    const isPrintExportButtonEnabled = (this.state.isFetchingData || this.props.isFetching ||
      this.props.currentDataViewsError || !this.canExport());
    return (
      <ContentTransition>
        <section className="o-scrollable-container" style={{ height: '100vh', maxWidth: `calc(100vw - ${sidebarWidth})` }}>
          <h1 data-public className="o-title">{translate('payment_history')}</h1>
          <div className="u-flex-row u-margin--standard u-flex-space-between u-flex-align-items-center">
            <span style={{ flex: 1 }} />
            <TableDateRangePicker
              filter={this.props.filter}
              onUpdateFilter={newFilter => this.updateFilter(newFilter)}
            />
          </div>
          <div className="o-card u-margin-bottom--4ws">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{ translate('payment_history') }</h1>
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="payment_history"
                updateConfigValue={this.props.updateConfigValue}
                originalColumns={COLUMNS}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
            </Header>
            <div className="o-header-actions">
              <div className="u-flex-left u-margin-left--half-ws">
                <TableExportPrintOptions
                  exportPrintAttributes={this.state.exportPrintAttributes}
                  updateExportPrintAttribute={
                    (key, value) => this.updateExportPrintAttribute(key, value)
                  }
                  isPrintExportButtonEnabled={isPrintExportButtonEnabled}
                  onExportClicked={() => this.onExportClicked()}
                  onPrintClicked={() => this.onPrintClicked()}
                />
              </div>
            </div>
            <PaymentsTable
              isFetching={this.props.isFetching}
              currentDataViewsError={this.props.currentDataViewsError}
              columns={this.state.columns}
              data={this.state.data.toArray()}
              onFilterAndSort={filteredData => this.setState({ filteredData: List(filteredData) })}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default PaymentHistory;
