import React from 'react';
import type { ComponentType } from 'react';
import Moment from 'moment';
import { List } from 'immutable';

import BaseModel from 'src/models/baseModel';
import translate from './../../utils/i18n';
import { isCustomFieldPresent } from './../../utils/documentTemplates';
import {
  printBill, printBills, printMedicalCertificate, printTimeChit, printPrescriptionLabels,
  printDocument,
} from './../../utils/print';
import { getDocumentTemplateForPrint, convertNumberToPrice } from './../../utils/utils';
import { getDateFormat } from './../../utils/time';
import { getPatientOwedAmount } from './../../utils/billing';

import BillModel from './../../models/billModel';
import PractitionerModel from './../../models/practitionerModel';
import DocumentDataModel from './../../models/documentDataModel';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type PatientModel from './../../models/patientModel';
import type AllergyModel from './../../models/allergyModel';
import type ConditionModel from './../../models/conditionModel';
import type DrugModel from './../../models/drugModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type { Config, User, PaymentToBePrint, DocumentTemplatePrintType, Model } from './../../types';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type { Attributes as BillAttributes } from './../../models/billModel';
import type EncounterModel from './../../models/encounterModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type BillItemModel from './../../models/billItemModel';
import type PaymentModel from './../../models/paymentModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type ProviderModel from './../../models/providerModel';
import DiscountChargeModel from './../../models/discountChargeModel';


type Props = {
  bill?: BillModel,
  billItems: List<BillItemModel>,
  billAttributes: BillAttributes,
  patient: PatientModel,
  allergies: List<AllergyModel>,
  practitioners: List<PractitionerModel>,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  config: Config,
  prescriptions: List<PrescriptionModel>,
  salesItems: List<SalesItemModel>,
  payments: List<PaymentModel>,
  conditions: List<ConditionModel>,
  diagnoses: List<ConditionModel>,
  coveragePayorID?: string,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  documentTemplates: List<DocumentTemplateModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  isEditingAfterFinalisation: boolean,
  isSaving: boolean,
  onFinaliseClicked: () => void,
  patientTotal: number,
  discountsCharges: List<DiscountChargeModel>
};

type State = {
  printModalVisible: boolean,
  isPrinting: boolean,
  documentTemplateForPrint: DocumentTemplateModel | void,
  documentDataTitle: string | void,
  documentDataType: string | void,
  paymentsToBePrint: List<PaymentToBePrint>,
};


/**
 * Takes a Component and returns a new component with printing functionalities
 * @param {React.Component} WrappedComponent The component to modify.
 * @returns {React.Component} DocumentPrintFactory.
 */
function documentPrintFactory(WrappedComponent: ComponentType<*>) {
  /**
  * A DocumentPrintFactory HOC that would introduce printing functionalities to the wrapped component
  * @class DocumentPrintFactory
  * @extends {React.Component<Props, State>}
  */
  class DocumentPrintFactory extends React.Component<Props, State> {
    props: Props;

    state: State = {
      printModalVisible: false,
      isPrinting: false,
      documentTemplateForPrint: undefined,
      documentDataTitle: undefined,
      documentDataType: undefined,
      paymentsToBePrint: List(),
    }

    /**
      * Updates the component state to display the document data component for printing.
      * @param {DocumentTemplateModel} documentTemplateForPrint The document template to use
      * @param {string} type The type of document being printed.
      * @param {{type: string, selectedItems: Array<string>}} filterConfig the list of selected ids to filter
      * from properties when there is option to select a subset from a list eg: payments to print
      * @return {void}
      */
    printWithDocumentTemplate(
      documentTemplateForPrint: DocumentTemplateModel,
      type: DocumentTemplatePrintType,
      filterConfig?: {type: string, selectedItems: Array<string> | List<BaseModel>},
    ): void {
      // get fields using template to check if there are any fields other than arrays,
      // since they will go as input fields in modal. If there are none, then can directly print.
      const {
        config, allergies, medicalCertificates, prescriptions, timeChits, patient, encounter,
        salesItems, drugs, coveragePayors, practitioners, billAttributes, billItems, payments,
        conditions, procedureTypes, encounterStageMap, discountsCharges,
      } = this.props;
      const dataObject = Object.assign({}, {
        config,
        patient,
        encounter,
        allergies,
        medicalCertificates,
        prescriptions: (filterConfig && filterConfig?.type === 'prescription') ?
          filterConfig.selectedItems : prescriptions,
        timeChits,
        salesItems,
        drugs,
        coveragePayors,
        practitioners,
        practitioner: practitioners.find(p => p.get('_id') === encounter.getValue('doctor')),
        bill: new BillModel(billAttributes),
        billItems,
        payments: filterConfig ?
          this.filterFromProperties(this.getType(payments), filterConfig) : payments,
        conditions,
        procedureTypes,
        encounterStageMap,
        discountsCharges,
        usedDiscountsCharges: billAttributes ?
          List(billAttributes.applied_discounts_charges ? (billAttributes.applied_discounts_charges.map(e => discountsCharges?.find(d => d.get('_id') === e)).filter(e => !!e) || []) : []) :
          List(),
      });
      documentTemplateForPrint.getFormFields(dataObject)
        .then((fields) => {
          if (isCustomFieldPresent(fields)) {
            this.setState({
              printModalVisible: true,
              documentTemplateForPrint,
              documentDataTitle: `${translate(type)} (Printed on ${Moment().format(getDateFormat())})`,
              documentDataType: type,
            });
          } else {
            const documentData = new DocumentDataModel({
              data: fields,
            });
            printDocument(documentData, documentTemplateForPrint);
            this.setState({ isPrinting: false });
          }
        });
    }

    /**
      * Gets the DocumentTemplate that will be use for printing.
      * @param {string} type The print template type.
      * @returns {undefined | DocumentTemplateModel}
      */
    getDocumentTemplate = (type: DocumentTemplatePrintType) => {
      const documentTemplateId = getDocumentTemplateForPrint(this.props.config, type);
      return documentTemplateId
        ? this.props.documentTemplates.filter(t => t.isVisible()).find(t => t.get('_id') === documentTemplateId)
        : undefined;
    }


    /**
      * Prints the TC. If a DocumentTemplate has been set for printing, this will be used. If not the
      * default TC printout will be used.
      * @param {TimeChitModel} timeChit the timeChit to print
      * @return {void}
      */
    onPrintTimeChitClicked = (timeChit: TimeChitModel | null | undefined): void => {
      if (timeChit) {
        this.setState({ isPrinting: true });
        const documentTemplateForPrint = this.getDocumentTemplate('time_chit');
        if (documentTemplateForPrint) {
          this.printWithDocumentTemplate(documentTemplateForPrint, 'time_chit');
        } else {
          const encounterType = this.props.encounter.getEncounterType(this.props.salesItems);
          printTimeChit(this.props.config, timeChit, this.props.patient, encounterType);
          this.setState({ isPrinting: false });
        }
      }
    }

    /**
      * Prints the MC. If a DocumentTemplate has been set for printing, this will be used. If not the
      * default MC printout will be used.
      * @param {MedicalCertificateModel} medicalCertificate the MC to print
      * @return {void}
      */
    onPrintMedicalCertificateClicked = (
      medicalCertificate: MedicalCertificateModel | null | undefined,
    ): void => {
      if (medicalCertificate) {
        const { config, patient, diagnoses } = this.props;
        this.setState({ isPrinting: true });
        const documentTemplateForPrint = this.getDocumentTemplate('medical_certificate');
        if (documentTemplateForPrint) {
          this.printWithDocumentTemplate(documentTemplateForPrint, 'medical_certificate');
        } else {
          const practitionerId = this.props.encounter?.getValue('doctor');
          const isPractitionerInMc = !!medicalCertificate?.get('practitioner_id');
          const practitionerName = this.props.practitioners.find(
            p => (isPractitionerInMc ? // this needs to be changed because currently we are not saving practioner_id in mc docs intentionally.
              p.get('_id') === medicalCertificate.get('practitioner_id') :
              p.get('_id') === practitionerId),
            undefined,
            new PractitionerModel({ name: this.props.encounter.getValue('doctor') }),
          ).get('name');
          printMedicalCertificate(
            config, medicalCertificate, patient, practitionerName, diagnoses,
          );
          this.setState({ isPrinting: false });
        }
      }
    }

    /**
     * gets the filtered list of documents/models for the type passed, from list of models in props.
     * @param {string} type The type of document to be filtered.
     * @param {{type: string, selectedItems: Array<string>}} filterConfig the list of selected ids to filter
     * @return {List<Model>}
     */
    filterFromProperties = (
      type: string,
      filterConfig: {type: string, selectedItems: Array<string>},
    ): List<Model> => {
      if (filterConfig && filterConfig.type === type && filterConfig.selectedItems &&
        filterConfig.selectedItems.length > 0) {
        return this.props.payments.filter(p => filterConfig.selectedItems.includes(p.get('_id')));
      }
      return this.props.payments;
    }

    /**
     * gets the document type of list of docs in props.
     * @param {List<Model>} models the list of docs from properties of which the type needs to be identified
     * @return {string}
     */
    getType = (models: List<Model>): string => {
      if (models) {
        const model = models.first();
        return model && model.has('type') ? model.get('type') : '';
      }
      return '';
    }

    /**
      * Prints the Bill Invoice. If a DocumentTemplate has been set for printing, this will be used.
      * If not the default bill invoice printout will be used.
      * @return {void}
      */
    onPrintInvoiceClicked = (): void => {
      const {
        config, patient, billAttributes, encounter, salesItems, drugs,
        billItems, payments, procedureTypes, providers, discountsCharges,
      } = this.props;
      this.setState({ isPrinting: true });
      const documentTemplateForPrint = this.getDocumentTemplate('patient_receipt');
      const paymentsToBePrint = this.state.paymentsToBePrint.filter(p => p.isSelected);
      if (documentTemplateForPrint) {
        this.printWithDocumentTemplate(
          documentTemplateForPrint,
          'patient_receipt',
          { type: this.getType(payments), selectedItems: paymentsToBePrint.map(p => p.id).toArray() },
        );
      } else {
        const usedDiscountsCharges = this.props.usedDiscountsCharges ||
          List(billAttributes.applied_discounts_charges?.map(e => discountsCharges?.find(d => d.get('_id') === e)).filter(e => !!e) || []);
        const amountOwed = getPatientOwedAmount(billAttributes,
          billItems || List(), payments || List(), usedDiscountsCharges);
        if (this.state.paymentsToBePrint.size) {
          printBills(convertNumberToPrice(amountOwed), paymentsToBePrint, config, billAttributes,
            patient, encounter, salesItems, drugs, billItems, procedureTypes, providers, usedDiscountsCharges);
        } else {
          printBill(convertNumberToPrice(amountOwed),
            config, billAttributes, patient, encounter, salesItems, drugs,
            billItems, procedureTypes, providers, usedDiscountsCharges);
        }
        this.setState({ isPrinting: false });
      }
    }


    /**
     * @callback filterPrescriptionCallback
     * @param  {List<PrescriptionModel>} prescriptions - Prescriptions to filter
     * @return  {List<PrescriptionModel>}  filtered Prescriptions
     */

    /**
      * Prints the Prescription labels. If a DocumentTemplate has been set for printing, this will be
      * used. If not the default prescription labels printout will be used.
      * @param {filterPrescriptionCallback} filter The type of document to be filtered.
      * @return {void}
      */
    onPrintPrescriptionLabelsClicked = (filter?: (prescriptions: List<PrescriptionModel>) => List<PrescriptionModel>): void => {
      const { config, patient, prescriptions, drugs } = this.props;
      const filteredPrescriptions = filter ? filter(prescriptions) : prescriptions;
      this.setState({ isPrinting: true });
      const documentTemplateForPrint = this.getDocumentTemplate('prescription_labels');
      if (documentTemplateForPrint) {
        this.printWithDocumentTemplate(
          documentTemplateForPrint,
          'prescription_labels',
          { type: 'prescription', selectedItems: filteredPrescriptions },
        );
      } else {
        printPrescriptionLabels(config, filteredPrescriptions, patient, drugs);
        this.setState({ isPrinting: false });
      }
    }

    /**
      * Sets the state from the wrapped component
      * @param {object} state State to set
      * @return {void}
      */
    handleStateChange = (state: {}) => {
      this.setState(state);
    }

    /**
      * Renders the component.
      * @returns {React.Component} The rendered component.
      */
    render() {
      return (<WrappedComponent
        getDocumentTemplate={this.getDocumentTemplate}
        onPrintTimeChitClicked={this.onPrintTimeChitClicked}
        onPrintMedicalCertificateClicked={this.onPrintMedicalCertificateClicked}
        onPrintInvoiceClicked={this.onPrintInvoiceClicked}
        onPrintPrescriptionLabelsClicked={this.onPrintPrescriptionLabelsClicked}
        setDocumentPrintFactoryState={this.handleStateChange}
        {...this.state}
        {...this.props}
      />);
    }
  }

  return DocumentPrintFactory;
}

export default documentPrintFactory;
