import BaseModel from './baseModel';

export type CategoryCoveragePayor = { coverage_payor_id: string, category: string };
export type PriceCoveragePayor = { coverage_payor_id: string, price: number };

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'sales_item',
  hidden: boolean,
  default_coverage_payor_category?: string,
  coverage_payor_category?: Array<CategoryCoveragePayor>,
  cost_price?: number,
  name: string,
};

/**
   * SalesItemModel
   *
   * @namespace SalesItemModel
   */
class SalesItemModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'sales_item';
  }

  /**
   * Sets the salesItem visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered SalesItemModel.
   */
  setVisible(isVisible: boolean): BaseModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Gets the price of the sales item
   * signature as DrugModel.
   * @returns {(number | void)}
   */
  getPrice(): number { // eslint-disable-line no-unused-vars
    return this.has('price') ? this.get('price') : 0;
  }

  /**
   * Gets the default quantity of the model. If none found than 1 is returned.
   * @returns {number}
   */
  getDefaultQuantity(): number {
    return this.has('default_quantity') && this.get('default_quantity') !== '' ?
      parseFloat(this.get('default_quantity')) : 1;
  }
}

export default SalesItemModel;
