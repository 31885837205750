import { connect } from 'react-redux';
import { saveFactory } from './../utils/redux';
import { setUserAdminStatus, updateConfig } from './../actions';
import UserManagement from './../components/userManagement/userManagement';

import type { State, Dispatch, Config } from './../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ userGroups, config, userConfigs, user, isAdminUser }: State) => ({
  user,
  userGroups,
  userConfigs: userConfigs.filter(c => !c.get('user_id').startsWith('klinify_')),
  isAdminUser,
  config,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  setAdminUserStatus: (isAdmin?: boolean) => dispatch(setUserAdminStatus(isAdmin)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});


const UserManagementContainer = connect(mapStateToProps, mapDispatchToProps)(UserManagement);

export default UserManagementContainer;
