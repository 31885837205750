import React, { useState } from 'react';
import glamorous from 'glamorous';
import { List } from 'immutable';
import moment from 'moment';

import ModalFooter from '../../modals/modalFooter';
import DatePicker from '../../inputs/statefulDatepicker';
import Input from '../../inputs/input';
import SaveButton from '../../buttons/saveButton';

import SavePrompt from '../../prompts/savePrompt';
import FormError from '../../formError';
import { UNICODE, DATE_FORMAT_TO_SAVE } from '../../../constants';
import Select from '../../inputs/select';
import translate from '../../../utils/i18n';
import BatchSelectTable from './batchSelectTable';
import TransactionModel from '../../../models/transactionModel';

import type DrugModel from '../../../models/drugModel';
import type { SaveModel, Config, MapValue, CountPerSKUAndBatch } from '../../../types';
import SupplyItemModel from '../../../models/supplyItemModel';
import SupplierModel from '../../../models/supplierModel';
import SupplyModel from '../../../models/supplyModel';

type ActionCallBack = (supplyItemID: string, skuID: string,
    models: [TransactionModel, SupplyItemModel, SupplyModel],
    stockFormData: MapValue) => void;

type Props = {
  drugs: List<DrugModel>,
  onChangeSKU: (value: string) => void,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  inventoryCount: CountPerSKUAndBatch,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  supplyItems: List<SupplyItemModel>,
  isFetching: boolean,
  onActionButtonClicked: (supplyItem: SupplyItemModel) => void,
  action: string,
  stockFormActionData?: MapValue,
  supplyItem: SupplyItemModel,
  suppliers: List<SupplierModel>,
  isAddAction?: boolean;
  updateInventoryItemCount: (skuID: string, change: number) => void,
  saveModel: SaveModel,
  isBulk?: boolean,
  config: Config,
  onAdd?: ActionCallBack,
  onSave?: ActionCallBack,
  onClose: () => void,
}

const TableContainer = glamorous.div({
  zIndex: 0,
  position: 'relative',
});

/**
 * Renders a batch selection modal for the batch adjustment
 * @param {Props} props SummaryMetric
 * @returns {React.FunctionComponent} The rendered component
*/
function AdjustBatchForm(props: Props) {
  const selectedDrug = props.drugs.find(d => d.get('_id') === props.supplyItem.get('sku_id'));
  const [errorMessage, setErroMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [actionFormAttributes, setActionFormAttributes] = useState({
    amount_received: props.stockFormActionData.amount_received || null,
    date: moment(),
    notes: props.stockFormActionData.notes || '',
    quantity: props.stockFormActionData.quantity || null,
    supplier_id: props.stockFormActionData.supplier_id || null,
  });
  const [changesMade, setChangesMade] = useState(false);

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  function updateAttribute(key: string, value: MapValue) {
    setActionFormAttributes({ ...actionFormAttributes, [key]: value });
    setChangesMade(true);
  }

  /**
   * Renders action form component.
   * @param {SupplyItemModel} item The supplyItemModel
   * @returns {React.Component} The rendered component.
   */
  function renderActionForm(item: SupplyItemModel) {
    const { action, supplyItem, inventoryCount } = props;
    const isTransaction = item.get('type') === 'transaction';
    const remainingQtyInBatch = inventoryCount.getIn([
      supplyItem.get('sku_id'),
      'batches',
      supplyItem.get('_id'),
      'batchStockRemaining',
    ]);
    return (
      <div className="o-form o-form--horizontal u-flex-row">
        <DatePicker
          id="action-form-supplyFormDatePicker"
          showClearDate={false}
          allowPast
          label={translate('date')}
          onValueChanged={value => updateAttribute('date', value)}
          value={isTransaction ? moment(item.get('timestamp')) : actionFormAttributes.date}
          required
          style={{ marginBottom: '1.333em' }}
          className="u-margin-right--half-ws"
          openDirection="up"
          noBorder
          appendToBody
        />
        { (props.action === 'transfer_out' || props.action === 'loan_out') &&
          <Select
            id="supply_supplier"
            autoFocus
            label={props.action === 'transfer_out' ? translate('transfer_to') : translate('loan_to')}
            options={
              props.suppliers
                .filter(supplier => supplier.isClinic())
                .map((supplier: SupplierModel) => ({ value: supplier.get('_id'), label: supplier.get('name') }))
                .toArray()
            }
            onValueChanged={value => updateAttribute('supplier_id', value)}
            value={actionFormAttributes.supplier_id}
            required
            style={{ flexGrow: 2, width: '20%' }}
          />
        }
        <Input
          id="action-form-dispensation-unit"
          label={translate('dispensation_unit')}
          value={(selectedDrug?.get('dispensation_unit') || UNICODE.EMDASH)}
          onValueChanged={() => {}}
          disabled
        />
        <Input
          id="action-form-qty"
          autoFocus={['write_off', 'damaged', 'expired', 'missing', 'dispensing_error', 'consumed'].includes(action)}
          label={props.action !== 'adjustment' ? translate('quantity') : translate('adjustment')}
          value={props.action !== 'adjustment'
            ? (Math.abs(Number(actionFormAttributes.quantity || 0)) || '')
            : (actionFormAttributes.quantity || 0)
          }
          onValueChanged={(value: string | number) => updateAttribute(
            'quantity',
            (value !== null && value !== '' && props.action !== 'adjustment')
              ? -Math.abs(parseFloat(value as string))
              : props.action === 'adjustment' && value
                ? parseFloat(value as string)
                : value,
          )}
          type="number"
          {...(props.action !== 'adjustment' && {
            min: 0,
          })}
          required
        />
        { props.action === 'adjustment' &&
          <Input
            id="action-qty-after-adjustment"
            label={translate('qty_after_adjustment')}
            value={remainingQtyInBatch
              ? (remainingQtyInBatch + Number(actionFormAttributes.quantity || 0))
              : UNICODE.EMDASH}
            onValueChanged={() => {}}
            disabled
          />
        }
        <Input
          id="action-cost_of_goods"
          label={translate('cost_of_goods')}
          value={(Number(supplyItem.get('total_price', 0)) / Number(supplyItem.get('quantity', 0))) * (isTransaction ? item.get('change') : Number(actionFormAttributes.quantity || 0)) || UNICODE.EMDASH}
          onValueChanged={() => {}}
          disabled
        />
        { (props.action === 'transfer_out' || props.action === 'loan_out') &&
          <Input
            id="action-amount-received"
            label={translate('amount_received')}
            value={actionFormAttributes.amount_received}
            onValueChanged={value => updateAttribute('amount_received', value ? Math.abs(value as number) : null)}
            type="number"
          />
        }
        <Input
          id="action-form-notes"
          label={translate('notes')}
          value={isTransaction ? item.get('notes') : (actionFormAttributes.notes)}
          onValueChanged={value => updateAttribute('notes', value)}
        />
      </div>
    );
  }

  /**
   * @param {string} message errorMessage
   * @param {boolean} saving isSaving
   * @return {void}
   */
  const setErrorAndSaving = (message: string, saving: boolean): void => {
    setErroMessage(message);
    setIsSaving(saving);
  };
  /**
   * Returns true if form is valid
   * @returns {void}
  */
  function isActionFormValid() {
    if (!actionFormAttributes.date || !actionFormAttributes.date.isValid()) {
      setErrorAndSaving(translate('fill_date_in_correct_format'), false);
      return false;
    }
    if (props.action !== 'adjustment' && (!actionFormAttributes.quantity
      || actionFormAttributes.quantity === undefined)) {
      setErrorAndSaving(translate('quantity_field_required_and_min_1'), false);
      return false;
    }
    if (props.action === 'adjustment' && !actionFormAttributes.quantity) {
      setErrorAndSaving(translate('adjustment_field_required_and_must_be_nonzero'), false);
      return false;
    }
    const { supplyItem } = props;
    if (supplyItem) {
      const { inventoryCount } = props;
      const remainingQty = inventoryCount.getIn([
        supplyItem.get('sku_id'),
        'batches',
        supplyItem.get('_id'),
        'batchStockRemaining',
      ], 0);
      if ((props.action !== 'adjustment' && Math.abs(actionFormAttributes.quantity) > remainingQty)
      || (props.action === 'adjustment' && Number(actionFormAttributes.quantity || 0) + remainingQty < 0)) {
        setErrorAndSaving(translate('quantity_removed_cannot_be_more_than_amount_remains'), false);
        return false;
      }
    }
    if ((props.action === 'transfer_out' || props.action === 'loan_out')
      && (!actionFormAttributes.supplier_id
      || actionFormAttributes.supplier_id === undefined)) {
      setErrorAndSaving(translate('fill_required_fields'), false);

      return false;
    }
    setErroMessage('');
    return true;
  }

  /**
   * @returns {SupplyModel}
   */
  function getNewSupplyModel() {
    return new SupplyModel({
      supplier_id: actionFormAttributes.supplier_id,
      date: actionFormAttributes.date.format(DATE_FORMAT_TO_SAVE),
      invoice_number: null,
      delivery_order_number: null,
      purchase_order_number: null,
      doc_number: null,
      notes: '',
    });
  }

  /**
   * @param {SupplyModel} supplyModel attributes to create new model
   * @returns {SupplyItemModel}
   */
  function getNewSupplyItemModel(supplyModel: SupplyModel) {
    const { supplyItem } = props;
    const changeAttrs = {
      supply_id: supplyModel.get('_id'),
      quantity: actionFormAttributes.quantity,
      total_price: actionFormAttributes.amount_received,
      notes: (props.action === 'transfer_out' || props.action === 'loan_out') ? actionFormAttributes.notes : null,
      date: actionFormAttributes.date.format(DATE_FORMAT_TO_SAVE),
    };
    return new SupplyItemModel(supplyItem.copyData(changeAttrs));
  }

  /**
   * Returns the models which needs to be save.
   * @returns {List<TransactionModel | SupplyItemModel | SupplyModel>}
   */
  function getModelsToSave(): List<TransactionModel | SupplyItemModel | SupplyModel> {
    const isTransferOrLoanOut = (props.action === 'transfer_out' || props.action === 'loan_out');
    const { supplyItem } = props;
    const supplyModel = isTransferOrLoanOut ? getNewSupplyModel() : undefined;
    const supplyItemModel = supplyModel ? getNewSupplyItemModel(supplyModel) : undefined;
    const sourceId = { source_id: isTransferOrLoanOut ? supplyItemModel?.get('_id') : supplyItem.get('_id') };
    const transaction = new TransactionModel({
      sku_id: supplyItem.get('sku_id'),
      change: actionFormAttributes.quantity,
      supply_item_id: supplyItem.get('_id'),
      source_type: props.action,
      notes: (props.action !== 'transfer_out' && props.action !== 'loan_out') ? actionFormAttributes.notes : null,
      timestamp: Number(actionFormAttributes.date.format('x')),
      ...sourceId,
    });
    if (!isTransferOrLoanOut) {
      return List([transaction]);
    }

    return List([transaction, supplyModel, supplyItemModel]).filter((
      m): m is (TransactionModel | SupplyItemModel | SupplyModel
) => !!m);
  }

  /**
   *
   * @param {ActionCallBack} callback callback function
   * @param { List<TransactionModel | SupplyItemModel | SupplyModel>} models list of models
   * @returns {Promise}
   */
  function callActionCallback(
    callback: ActionCallBack,
    models: List<TransactionModel | SupplyItemModel | SupplyModel>,
  ) {
    const { supplyItem } = props;
    return Promise.resolve()
      .then(() => {
        if (callback) {
          callback(
            supplyItem?.get('_id'),
            supplyItem?.get('sku_id'),
            models.toArray(),
            {
              quantity: actionFormAttributes.quantity,
              notes: actionFormAttributes.notes,
              amount_received: actionFormAttributes.amount_received,
              supplier_id: actionFormAttributes.supplier_id,
              actionType: props.action,
            },
          );
        }
      })
      .then(() => {
        props.onClose();
        return true;
      });
  }
  /**
  * Handles saving action form. This will create new transaction doc
  * @param {List<TransactionModel | SupplyItemModel | SupplyModel>} models List of models
  * @returns {Promise<boolean>}
  */
  function onAddClicked(models: List<TransactionModel | SupplyItemModel | SupplyModel>) {
    if (isActionFormValid()) {
      setIsSaving(true);
      return props.onAdd ? callActionCallback(props.onAdd, models) : Promise.resolve(false);
    }
    return Promise.resolve(false);
  }

  /**
  * Handles saving action form. This will create new transaction doc
  * @param {List<TransactionModel | SupplyItemModel | SupplyModel>} models List of models
  * @returns {Promise<boolean>}
  */
  function onSaveClicked(models: List<TransactionModel | SupplyItemModel | SupplyModel>) {
    if (isActionFormValid()) {
      const { supplyItem } = props;
      setIsSaving(true);
      return Promise.all(
        models.map((model: TransactionModel | SupplyItemModel | SupplyModel) =>
          props.saveModel(model)),
      )
        .then(() => supplyItem && props.updateInventoryItemCount(supplyItem.get('sku_id'), Number(actionFormAttributes.quantity || 0)))
        .then(() => (props.onSave ? callActionCallback(props.onSave, models) : false));
    }
    return Promise.resolve(false);
  }

  /**
   * @returns {{label: string, clickHandler: Function}}
   */
  function getClickHandlerAndLabel() {
    const { isAddAction } = props;
    return isAddAction ? ({ label: 'add', clickHandler: onAddClicked })
      : ({ label: 'save', clickHandler: onSaveClicked });
  }

  const { label, clickHandler } = getClickHandlerAndLabel();
  /**
   * Renders the component.
   * @return {HTML} - HTML markup for the component
   */
  return (
    <div>
      <SavePrompt when={changesMade} onSaveClicked={() => onSaveClicked(getModelsToSave())} />
      {
        errorMessage &&
        <div className="u-margin--standard">
          <FormError containerElementID="add-to-inventory-form">
            {errorMessage}
          </FormError>
        </div>
      }
      <TableContainer>
        <div className="o-card">
          <BatchSelectTable
            selectedDrug={selectedDrug}
            showEmptyBatches
            isSupplyItemChangeMode
            displayTableColumnSettings={false}
            inventoryCountSyncStatus={props.inventoryCountSyncStatus}
            inventoryCount={props.inventoryCount}
            supplyItems={List([props.supplyItem])}
            isFetching={props.isFetching}
            action={props.action}
            onActionButtonClicked={props.onActionButtonClicked}
            config={props.config}
            isBulk={props.isBulk}
            updateConfig={props.updateConfig}
            updateConfigValue={props.updateConfigValue}
          />
        </div>
      </TableContainer>
      {renderActionForm(props.supplyItem)}
      <ModalFooter>
        <SaveButton
          dataPublic
          onClick={() => clickHandler(getModelsToSave())}
          isSaving={isSaving}
          label={translate(label)}
          className="o-button--small u-margin-right--half-ws"
        />
      </ModalFooter>
    </div>
  );
}

export default AdjustBatchForm;
