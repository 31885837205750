import * as React from 'react';
import { List, Map, Set } from 'immutable';
import glamorous from 'glamorous';
import { differenceBy } from 'lodash';
import Button from './../buttons/button';

import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import SortableList from './../layout/sortableList';
import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import { wsUnit } from './../../utils/css';
import { updateClinicConfig } from './../../utils/db';
import { mapFromJS } from './../../utils/utils';
import type { Config, MapValue, CustomColumn } from './../../types';

type ConfigColumns = { value: string, show: boolean };

type Props = {
  config: Config,
  configFieldName: string,
  originalColumns: List<CustomColumn>,
  columns: Array<CustomColumn>,
  onUpdateColumns: (columnsToShow: Array<MapValue>) => void,
  updateConfig: (config: Config) => void,
  isCompact?: boolean,
};

type State = {
  config: Config,
  sortableColumns: Array<{ value: string, show: boolean }>,
  columnsModalVisible: boolean,
  reset: boolean,
  isSaving: boolean,
};

const SortableListHeader = glamorous.div({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #e0dfdf',
  height: 47,
  alignItems: 'center',
  padding: '0 20px',
});

const CardRow = glamorous.div({
  padding: `10 calc(${wsUnit} / 4)`,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: 47,
});

const CardRowCheckbox = glamorous.input({
  marginLeft: 'auto',
  marginRight: `calc(${wsUnit} * 2.5) !important`,
});

/**
 * A component for table columns settings
 * @class TableColumnsSettings
 * @extends {React.Component<Props, State>}
 */
class TableColumnsSettings extends React.Component<Props, State> {
  static defaultProps = {
    startClosed: false,
    isClosed: () => {},
  };

  /**
   * Creates an instance of TableColumnsSettings.
   * @param {Props} props props
   */
  constructor(props: Props) {
    super(props);
    const configCols = this.props.config.getIn(['sortable_table_columns', this.props.configFieldName],
      List()).toArray().map((item: List<ConfigColumns>) => item.toJS());
    const { uniqueColumns } = configCols.reduce(({ colValues, uniqueColumns: uniqueCols }: {
      colValues: Set<String>,
      uniqueColumns: Array<CustomColumn>
    }, val: CustomColumn) => {
      if (!colValues.has(val?.value)) {
        return { uniqueColumns: uniqueCols.concat(val), colValues: colValues.add(val.value) };
      }
      return { uniqueColumns: uniqueCols, colValues: colValues.add(val.value) };
    }, { uniqueColumns: [], colValues: Set() });
    this.state = {
      sortableColumns: uniqueColumns,
      config: this.props.config,
      columnsModalVisible: false,
      reset: false,
      isSaving: false,
    };
  }

  /**
   * Called functions after component fully rendered
   * @returns {void}
   */
  componentDidMount() {
    this.updateColumns();
  }

  /**
   * Triggers a update column function if prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    // Reset values when config value changes.
    if (!this.props.config.equals(prevProps.config)) {
      this.resetStateConfig();
    }
    if (!this.state.config.getIn(['sortable_table_columns', this.props.configFieldName], List())
      .equals(prevProps.config.getIn(['sortable_table_columns', this.props.configFieldName], List()))) {
      this.updateColumns();
    }
    if (this.state.reset) {
      this.resetPropAndConfigColumns();
    }
    if (!this.state.sortableColumns.length) {
      this.setSortableColumnsInState();
    }
  }

  /**
   * Reset values when config value changes.
   * @returns {void}
   */
  resetStateConfig() {
    this.setState({
      config: this.props.config,
    }, () => {
      this.updateColumns();
    });
  }

  /**
   * Get sortable columns from original columns that can be added in state and in app config
   * @returns {Array<ConfigColumns>}
   */
  getSortableColumns(): Array<ConfigColumns> {
    return this.props.columns.reduce((data, column) => {
      if (column.hideDefault) {
        return data.push({ value: column.value, show: false });
      }
      if (!!column.uncustomizable || (typeof column.show === 'boolean' && !column.show)) {
        return data;
      }
      return data.push({ value: column.value, show: true });
    }, List()).toArray();
  }


  /**
   * Set sortable columns in state
   * @returns {void}
   */
  setSortableColumnsInState() {
    this.setState({ sortableColumns: this.getSortableColumns() });
  }

  /**
   * Updates settings/config selection status in local state and also in db.
   * @param {Array<ConfigColumns>} newValue The new value.
   * @returns {Promise<any>}
   */
  updateConfig(newValue: Array<ConfigColumns>): Promise<void> {
    if (!this.state.sortableColumns.length) {
      this.setState({ sortableColumns: newValue });
    }

    return updateClinicConfig(
      this.props.config.toJS(),
      Map().setIn(['sortable_table_columns', this.props.configFieldName], newValue).toJS(),
      this.props.updateConfig,
    )
      .then(() =>
        // updating state config before it update into db and store will hook thread into the componentDidUpdate lifecycle
        // and there it will call this.updateColumns() again and again and will exceed the maximum depth.
        this.setState({
          config: this.state.config.setIn(['sortable_table_columns', this.props.configFieldName], mapFromJS(newValue)),
        }));
  }

  /**
   * Update table columns
   * @returns {void}
   */
  updateColumns() {
    if (this.state.config.getIn(['sortable_table_columns', this.props.configFieldName], List()).size > 0) {
      const configColumns: ConfigColumns[] = this.state.config.getIn(['sortable_table_columns', this.props.configFieldName], List()).toJS();
      const columnsToShow = configColumns.filter(configColumn => configColumn.show)
        .map(c => this.props.originalColumns.toArray().find(o => o.value === c.value))
        .filter(c => c !== undefined);
      // Show new columns in table by default, if the keys doesn't exist in Config
      const newColumns = differenceBy(this.props.originalColumns.toArray(), configColumns, 'value')
        .filter(e => !e.uncustomizable);
      const uncustomizableColumns = this.props.originalColumns
        .filter(e => e.uncustomizable).toArray();
      const { uniqueColumns } = columnsToShow.reduce(({ colValues, uniqueColumns: uniqueCols }: {colValues: Set<String>,
          uniqueColumns: Array<CustomColumn>}, val: CustomColumn) => {
        if (!colValues.has(val?.value)) {
          return { uniqueColumns: uniqueCols.concat(val), colValues: colValues.add(val.value) };
        }
        return { uniqueColumns: uniqueCols, colValues: colValues.add(val.value) };
      }, { uniqueColumns: [], colValues: Set() });
      this.props.onUpdateColumns(uniqueColumns.concat(newColumns).concat(uncustomizableColumns));
      const uncustomizableConfigColumns: Map<string, ConfigColumns> = configColumns
        .reduce((cols: Map<string, ConfigColumns>, s) => {
          if (uncustomizableColumns.some(o => o.value === s.value)) {
            return cols.set(s.value, s);
          }
          return cols;
        }, Map<string, ConfigColumns>());
      if (newColumns.length || uncustomizableConfigColumns.size) {
        // Update Config if new columns are added to the table or if there are uncustomizable cols in config.
        const newSortableColumns = [
          ...newColumns.map(c => ({
            value: c.value, show: c.show !== undefined ? c.show : !c.hideDefault,
          })),
          ...this.state.sortableColumns.filter(c => !uncustomizableConfigColumns.has(c.value)),
        ];
        this.setState({ sortableColumns: newSortableColumns });
        this.updateConfig(newSortableColumns);
      }
    }
  }

  /**
   * called when checkbox value is changed for any card
   * @param {string} value value of card.
   * @param {boolean} isChecked to indicate checkbox is checked.
   * @returns {void}
   */
  onValueChanged(value: string, isChecked: boolean): void {
    const newCards = this.state.sortableColumns.map((c) => {
      const card = c;
      if (c.value === value) {
        card.show = isChecked;
      }
      return card;
    });
    this.setState({ sortableColumns: newCards });
  }

  /**
   * sort handler to be passed to sortable list component, rearranges array of cards as per latest sort.
   * @param {MapValue} funcArg object with old and new index.
   * @returns {void}
   */
  onSort = (funcArg: { oldIndex: number, newIndex: number}): void => {
    const newCards = this.state.sortableColumns.slice();
    newCards.splice(funcArg.newIndex, 0, newCards.splice(funcArg.oldIndex, 1)[0]);
    this.setState({ sortableColumns: newCards });
  }

  /**
   * Handle Saving of columns into app config
   * @returns {void}
   */
  onSaveColumnsSettings() {
    this.setState({
      isSaving: true,
    });
    this.updateConfig(this.state.sortableColumns)
      .then(() => {
        this.setState({
          isSaving: false,
          columnsModalVisible: false,
        });
      })
      .catch(() => {
        this.setState({
          isSaving: false,
          sortableColumns: this.props.config.getIn(['sortable_table_columns', this.props.configFieldName],
            List()).toArray().map((item: List<ConfigColumns>) => item.toJS()),
        });
      });
  }

  /**
   * Returns array of HTML items to be passed to sortable list
   * @returns {JSX.Element[]}
   */
  getColumnItems(): JSX.Element[] {
    const columnsToShow: { value: string, label: string, show: boolean }[] = [];
    this.state.sortableColumns
      .map(s => this.props.originalColumns.toArray()
        .filter(o => o.value === s.value && !o.uncustomizable)
        .map(n => columnsToShow.push({ value: n.value, label: n.label, show: s.show })));
    return columnsToShow.map(i => (
      <CardRow>
        <div>{i.label}</div>
        <CardRowCheckbox
          checked={i.show}
          id={`card_${i.value}`}
          key={i.value}
          name={i.value}
          type="checkbox"
          // Value isn't used for checkbox but is kept in because its used in other components
          value={i.show as any}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            this.onValueChanged(i.value, event.target.checked)
          }
        />
      </CardRow>
    ));
  }

  /**
   * Handle resetting of columns in config
   * @return {void}
   */
  resetPropAndConfigColumns() {
    this.updateConfig([]);
    this.setState({ sortableColumns: [], reset: false });

    this.props.onUpdateColumns(this.props.columns.filter(i => !i.hideDefault));
    this.setState({ columnsModalVisible: false });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <StatelessModal
        id="columns_settings"
        buttonLabel={this.props.isCompact ? <img src="/static/images/icon_info.svg" alt="i" /> : translate('customise_columns')}
        buttonIcon={this.props.isCompact ? '' : 'fa fa-columns'}
        buttonClass={this.props.isCompact ? 'o-text-button o-card-header-button' : 'o-button o-button--small u-margin-right--half-ws o-button--info'}
        title={translate('customise_columns')}
        setVisible={isVisible => this.setState({ columnsModalVisible: isVisible })}
        visible={this.state.columnsModalVisible}
        explicitCloseOnly
        onClose={() => {
          this.setState({
            sortableColumns: this.props.config.getIn(['sortable_table_columns', this.props.configFieldName],
              List()).toArray().map((item: List<ConfigColumns>) => item.toJS()),
          });
        }}
        dataPublicHeader
      >
        <SortableListHeader>
          <p className="u-strong">{translate('column_name')}</p>
          <p className="u-strong">{translate('show_column')}</p>
        </SortableListHeader>
        <SortableList
          items={this.getColumnItems()}
          onSortEnd={this.onSort}
          itemZIndex={100}
        />
        <ModalFooter>
          <Button
            className="o-button o-button--small u-margin-right--half-ws"
            onClick={() => {
              this.setState({ reset: true });
            }}
            dataPublic
          >
            {translate('reset')}
          </Button>
          <SaveButton
            onClick={() => this.onSaveColumnsSettings()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small"
            dataPublic
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default TableColumnsSettings;
