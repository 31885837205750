import React from 'react';
import { List, Map } from 'immutable';
import type { RouteComponentProps } from 'react-router-dom';
import BrandResponseModel from '../../models/pharmaconnect/brandResponseModel';
import ReadDocumentsModel from '../../models/pharmaconnect/readDocumentsModel';
import { fetchPharmaConnectBrandDocuments, fetchPharmaConnectReadDocuments } from './../../utils/api';
import SidebarItem from './sidebarItem';
import SidebarTextLink from './sidebarTextLink';
import { PHARMACONNECT_ENABLED } from './../../constants';

interface Props extends RouteComponentProps {
  brandDocuments: List<BrandResponseModel>,
  readDocuments: ReadDocumentsModel,
  setPharmaConnectBrandDocuments:
  (pharmaConnectBrandDocuments: List<BrandResponseModel> | null) => void,
  setPharmaConnectReadDocuments: (pharmaConnectReadDocuments: ReadDocumentsModel | null) => void,
}

/**
 * A Sidebar component
 * @class Sidebar
 * @extends {React.Component<Props, State>}
 */
class PharmaConnectSidebar extends React.PureComponent<Props> {
  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    if (PHARMACONNECT_ENABLED) {
      this.getPharmaConnectBrandDocuments();
    }
  }

  /**
   * Get the brand and documents data
   * @returns {void}
   */
  getPharmaConnectBrandDocuments(): void {
    if (!this.props.brandDocuments?.size) {
      fetchPharmaConnectBrandDocuments()
        .then(this.props.setPharmaConnectBrandDocuments)
        .catch(() => this.props.setPharmaConnectBrandDocuments(null));
    }
    if (!this.props.readDocuments) {
      fetchPharmaConnectReadDocuments()
        .then(this.props.setPharmaConnectReadDocuments)
        .catch(() => this.props.setPharmaConnectReadDocuments(null));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { brandDocuments, readDocuments } = this.props;
    const readDocumentIDs: Array<String> = ((readDocuments && readDocuments.get('documents')) || []).map(doc => doc.asset_id);

    const { unread, allunreadCount } : { unread: Map<string, number>, allunreadCount: number } = PHARMACONNECT_ENABLED ?
      (brandDocuments || List()).reduce((accum, b) => {
        const documentIDs = b.get('documents', []).map(doc => doc.id);
        const readCount = documentIDs.filter(docID => readDocumentIDs.includes(docID)).count();
        const unreadCount = documentIDs.count() - readCount;
        return {
          unread: accum.unread.set(b.get('id', ''), unreadCount),
          allunreadCount: accum.allunreadCount + unreadCount,
        };
      }, { unread: Map(), allunreadCount: 0 }) :
      { unread: Map(), allunreadCount: 0 };

    return (
      <SidebarItem
        label="pharmaconnect"
        url="/pharmaconnect"
        isSelected={location.hash.startsWith('#/pharmaconnect')}
        unreadCount={allunreadCount}
      >
        {
          PHARMACONNECT_ENABLED && brandDocuments && brandDocuments.map(b => (
            <SidebarTextLink
              label={b.get('name', 'brand')}
              url={`/pharmaconnect/${b.get('id', '')}`}
              skipTranslate
              unreadCount={unread.get(b.get('id', ''))}
              key={b.get('id', '')}
            />
          ))
        }
      </SidebarItem>
    );
  }
}

export default PharmaConnectSidebar;
