import React, { useEffect } from 'react';
import type { List } from 'immutable';
import type { RouteComponentProps } from 'react-router-dom';
import ReportCard from './reportCard';
import type { AnalyticsReportDetailResponse, AnalyticsQueryDetailResponse } from './../../types';
import type AnalyticsReportModel from './../../models/analytics/reportModel';
import type AnalyticsQueryResponseModel from './../../models/analytics/queryResponseModel';
import DateRangeSelect from './dateRangeSelect';

interface Props extends RouteComponentProps {
  reportID: string,
  dateRange: string,
  reportDetail: AnalyticsReportModel,
  queriesDetail: List<AnalyticsQueryResponseModel>,
  setAnalyticsDateRange: (range: string) => void,
  fetchAnalyticsReportDetail: (reportId: string) => Promise<AnalyticsReportDetailResponse>,
  fetchAnalyticsQueryDetail: (
    query1: string,
    query2: string
  ) => Promise<AnalyticsQueryDetailResponse>;
  setAnalyticsReportDetail: (reportDetail: AnalyticsReportModel) => void,
  setAnalyticsQueriesDetail: (reportDetails: List<AnalyticsQueryResponseModel>) => void
}

/**
 * Renders the report detail page in /analytics/reports/:id route
 * @param {Props} props Props
 * @returns {React.FunctionComponent} The rendered component
*/
function ReportPage(props: Props) {
  const {
    reportID,
    dateRange,
    setAnalyticsDateRange,
    fetchAnalyticsReportDetail,
    fetchAnalyticsQueryDetail,
    setAnalyticsReportDetail,
    setAnalyticsQueriesDetail,
    reportDetail,
    queriesDetail,
  } = props;

  /**
   * fetch data for report detail.
   * @returns {null}
   */
  function fetchReportDetail() {
    const dataPromises = [fetchAnalyticsReportDetail(reportID)];

    Promise.all(dataPromises).then((response) => {
      setAnalyticsReportDetail(response[0].data);
    });
  }

  useEffect(() => {
    fetchReportDetail();
  }, []);

  useEffect(() => {
    const queryIDs = reportDetail.get('query_ids', []);
    if (queryIDs.length > 0) {
      const cardPromises = queryIDs.map(id => fetchAnalyticsQueryDetail(id, dateRange));
      Promise.all(cardPromises).then((response) => {
        const allQueryDetail = response.map(r => r.data);
        setAnalyticsQueriesDetail(allQueryDetail);
      });
    }
  }, [JSON.stringify(reportDetail.get('query_ids', [])), dateRange]); // stringify for make up for deep comparision

  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      <DateRangeSelect dateRange={dateRange} onChange={value => setAnalyticsDateRange(value)} />
      <h1 className="o-title">{reportDetail.get('title')}</h1>
      { queriesDetail.map(detail => <ReportCard key={detail.get('_id')} data={detail} />) }
    </section>
  );
}

export default ReportPage;
