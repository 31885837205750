import { List, Map } from 'immutable';
import React from 'react';
import CoveragePayorModel from '../../models/coveragePayorModel';
import EncounterModel, { StageInfo } from '../../models/encounterModel';
import EncounterStageModel from '../../models/encounterStageModel';
import PractitionerModel from '../../models/practitionerModel';
import SalesItemModel from '../../models/salesItemModel';
import { Config, SaveModel, SaveModels, User } from '../../types';
import BillModel from '../../models/billModel';
import EncounterFlowModel from '../../models/encounterFlowModel';

import { InternalStepProps } from '../reactSteps/step';
import Steps from '../reactSteps/steps';
import translate from '../../utils/i18n';
import OptionButton from '../patient/optionButton';

type Props = {
  encounterFlowMap: Map<string, EncounterFlowModel>,
  saveModel: SaveModel,
  bills: Map<string, BillModel>,
  config: Config,
  practitioners: List<PractitionerModel>,
  encounters: List<EncounterModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  coveragePayors: List<CoveragePayorModel>,
  salesItems: Map<string, SalesItemModel>,
  updateConfig: (config: Config) => void,
  saveModels: SaveModels,
  user: User,
}

/**
 * return steps current index and steps data
 * @param {EncounterModel} currentConsult encounter model
 * @param {List<StageInfo>} stages stages
 * @returns {{currentStepIndex: number, currentConsultStepsData: Array<InternalStepProps>}}
 */
const getCurrentConsultStepInfo = (currentConsult: EncounterModel, stages: List<StageInfo>):
  {
    currentStepIndex: number,
    currentConsultStepsData: Array<InternalStepProps>;
  } => {
  const { currentStageIndex, currentStageOccurenceIndex } = currentConsult.getCurrentStageIndex();
  const currentStepIndex = currentConsult && stages && stages.size > 0 ?
    currentStageIndex : -1;
  const stepOccuranceIndex = currentConsult && stages && stages.size > 0 ?
    currentStageOccurenceIndex : -1;
  const isFinished = currentConsult.highestEventIs('finished_consult');

  const stepsData = stages && stages.size > 0 ?
    stages.map((s: StageInfo, index: number) => {
      const occurrences = s && List(s?.occurrences || []);
      const currentOccurringStage = occurrences && occurrences.get(stepOccuranceIndex);
      const lastOccuranceEvent = currentOccurringStage && currentOccurringStage.events.length > 0 ?
        List(currentOccurringStage.events).last({}) : undefined;
      const eventType = lastOccuranceEvent?.type || undefined;
      const stepSubLabel = eventType === 'started' ? translate('in_progress') : translate('waiting');
      return ({
        title: s._name,
        id: `${s.name.trim().toLowerCase()}-${index}`,
        subHeadingLabel: index === currentStepIndex && !isFinished ? stepSubLabel : '',
        stepTitleClass: index === currentStepIndex && eventType === 'started' ? 'queue-subtitle' : '',
        stepCircleClass: index === currentStepIndex && eventType === 'started' ? 'queue-step-circle' : '',
      });
    }).toArray() : [];
  return { currentStepIndex, currentConsultStepsData: stepsData };
};

/**
 * Steps component.
 * @param {Props} props component props
 * @returns {React.Component | string}
 */
function PatientFlows(props: Props) {
  const currentEncounters = props.encounters.filter(e =>
    e.isIncomplete()).sort(e => e.getArrivalTime());
  return (
    <div>
      {currentEncounters.map((e: EncounterModel) => {
        const stages = e && e.get('flow') ? e.getActiveStages() : List();
        const { currentStepIndex, currentConsultStepsData } =
          getCurrentConsultStepInfo(e, stages);
        if (currentConsultStepsData.length < 1) {
          return null;
        }
        const isFinished = e.highestEventIs('finished_consult');
        const stepsData = currentConsultStepsData.concat(({
          title: translate('billing'),
          id: `${e.get('_id')}-bill`,
          stepTitleClass: isFinished ? 'queue-subtitle' : '',
          stepCircleClass: isFinished ? 'queue-step-circle' : '',
          subHeadingLabel: isFinished ? translate('in_progress') : '',
        }));
        const currentStep = isFinished ? stepsData.length : currentStepIndex + 1;
        return (
          <div style={{ display: 'flex' }} className="u-margin--standard">
            <Steps
              key={e.get('_id')}
              currentStep={currentStep}
              stepsData={stepsData}
              style={{ flex: 1 }}
            />
            <OptionButton
              encounter={e}
              encounterFlowMap={props.encounterFlowMap}
              encounters={props.encounters}
              config={props.config}
              practitioners={props.practitioners}
              bills={props.bills}
              encounterStageMap={props.encounterStageMap}
              salesItems={props.salesItems}
              coveragePayors={props.coveragePayors}
              saveModel={props.saveModel}
              updateConfig={props.updateConfig}
              saveModels={props.saveModels}
              user={props.user}
            />
          </div>
        );
      })}
    </div>
  );
}

export default PatientFlows;
