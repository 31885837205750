import React from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import Input from './../inputs/input';
import translate from './../../utils/i18n';
import SalesItemModel from './../../models/salesItemModel';
import DrugModel from './../../models/drugModel';
import { wsUnit } from './../../utils/css';
import { createPermission, hasPermission } from './../../utils/permissions';

import type BillItemModel from './../../models/billItemModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type { MapValue, User } from './../../types';
import { debugPrint } from '../../utils/logging';

const BillItemRow = glamorous.div({
  display: 'grid',
  gridColumnGap: `calc(${wsUnit} / 2)`,
  alignItems: 'center',
  gridTemplateColumns: '2fr 1fr 1fr',
});

type Props = {
  item: BillItemModel,
  user: User,
  itemOptions: List<SalesItemModel> | List<DrugModel> | List<ProcedureTypeModel>,
  onValueChanged: (value: { [key: string]: MapValue }) => void,
};


/**
 * An Item within a BillItemForm
 * @param {object} props The item props.
 * @returns {React.PureComponent} A BillItemCostPriceItem.
 */
const BillItemCostPriceItem = ({ item, itemOptions, onValueChanged, user }: Props) => {
/**
 * Render
 * @returns {React.Component}
 */
  debugPrint('Rendering BillItemCostPriceItem');
  const IDType = item.isPrescription() ? 'drug_id' : 'sales_item_id';
  const selectedItemID = item.get(item.isProcedureType() ? 'procedure_type_id' : IDType);
  const itemName = itemOptions.find(model => selectedItemID === model.get('_id')).get('name');
  return (
    <div className="u-margin--standard">
      <BillItemRow>
        <Input
          id={`item-name-${item.get('_id')}`}
          disabled
          label={translate('name')}
          value={itemName}
          type="text"
          inputStyle={{ backgroundColor: 'white' }}
        />
        <Input
          id={`item-cost-price-${item.get('_id')}`}
          label={translate('cost_price')}
          value={item.has('temp_cost_price') ? item.get('temp_cost_price', 0) : item.get('cost_price', 0)}
          type="number"
          disabled={
            !hasPermission(user, List([
              createPermission('perm_view_edit_cost_price_right_side_bar', 'update'),
            ]))
          }
          onValueChanged={value =>
            onValueChanged({ temp_cost_price: value && value !== '' ? parseFloat(value) : value })
          }
        />
        <Input
          id={`item-current-cost-price-${item.get('_id')}`}
          disabled
          label={translate('current_cost_price')}
          value={item.get('cost_price', 0)}
          type="number"
        />
      </BillItemRow>
    </div>
  );
};

export default BillItemCostPriceItem;
