
import { List, Map } from 'immutable';

import BaseModel from './baseModel';
import { createUserPermission } from './../utils/permissions';

import type { EditEntry, UserPermissionRecord } from './../types';
import type { UserConfig, Permission } from './userConfigModel';

type Attributes = {
  _id: string,
  created_by: EditEntry,
  edited_by: Array<EditEntry>,
  type: 'user_group',
  name: string,
  config: UserConfig,
};

/**
 * UserGroupModel
 * @namespace UserGroupModel
 */
class UserGroupModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'user_group';
  }

  /**
   * Returns true if userGroup has permissions set.
   * @returns {boolean}
   */
  hasPermissions(): boolean {
    return this.get('config', {}).permissions !== undefined;
  }

  /**
   * Returns the permissions of this userGroup.
   * @returns {List<UserPermissionRecord>}
   */
  getPermissions(): List<UserPermissionRecord> {
    return List(this.get('config', {}).permissions || [])
      .map(permission => createUserPermission(permission.feature, List(permission.access)));
  }

  /**
   * Returns the permissions of this userGroup.
   * @returns {List<UserPermissionRecord>}
   */
  getPermissionsMap(): Map<string, UserPermissionRecord> {
    return List(this.get('config', {}).permissions || [])
      .reduce((permMap, permission) =>
        permMap.set(permission.feature, createUserPermission(permission.feature, List(permission.access))), Map());
  }

  /**
   * Sets the permissions for this UserGroupModel.
   * @param {Array<Permission>} permissions The new permissions as standard JS.
   * @returns {this}
   */
  setPermissions(permissions: Array<Permission>) {
    const config = this.get('config', {});
    config.permissions = permissions;
    return this.set('config', config);
  }
}

export default UserGroupModel;
