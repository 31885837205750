import React, { Fragment } from 'react';
import { Set, List } from 'immutable';
import { Link, RouteComponentProps } from 'react-router-dom';

import SidebarItem from './sidebarItem';
import SidebarTextLink from './sidebarTextLink';
import SidebarToggleItem from './sidebarToggleItem';
import SupportLink from './supportLink';
import ReferralLink from './referralLink';
import OutbreakModal from './../outbreak/outbreakModal';
import ReferralProgramModal from './../referralProgramModal';
import EConsultFeatureAnnouncementModal from './../eConsultFeatureAnnouncementModal';
import { logMessage } from './../../utils/logging';
import translate from './../../utils/i18n';
import { logout, isKlinifyUser, isExportUser } from './../../utils/auth';
import { getReferralQueryString } from './../../utils/router';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasSomePermission, hasPermission } from './../../utils/permissions';
import { sortModelByOrder } from './../../utils/comparators';
import { getFilter, getInterval } from './../../utils/dashboard';
import { FRESHCHAT_TOKEN, FRESHCHAT_HOST, FRESHCHAT_TAGS, MEDADVISOR_CAMPAIGNS_ENABLED, PHARMACONNECT_ENABLED, OUTBREAK_STATS_TAB_ENABLED } from './../../constants';
import { getValidConfigKeys, isClinicOverviewPage, getServerName } from './../../utils/utils';

import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import type CategoryModel from './../../models/categoryModel';
import type { User, Config, SaveModel, ToggleDebugModeFlag, DebugModeFlags } from './../../types';

import { version } from './../../../package.json';
import BrandResponseModel from '../../models/pharmaconnect/brandResponseModel';
import PharmaConnectSidebarContainer from '../../containers/pharmaConnectSidebarContainer';
import NewPharmaconnectFeatureModal from '../pharmaconnect/newPharmaconnectFeatureModal';
import PharmaconnectModalContainer from './../../containers/pharmaconnectModalContainer';
import AppointmentModel from '../../models/appointmentModel';
import MedadvisorAnnouncementModal from '../announcements/medAdvisor/MedadvisorAnnouncementModal';
import DebugActionButton from '../debugComponent/debugActionButton';
import DebugModeContainer from './../../containers/debugModeContainer';

interface Props extends RouteComponentProps{
  config: Config,
  klinifyConfig: Config,
  resetSearchQuery: () => void,
  user: User,
  categories: List<CategoryModel>,
  caseNoteFiles: List<CaseNoteFileModel>,
  isOnline: boolean,
  debugModeFlags: DebugModeFlags,
  saveModel: SaveModel,
  toggleDebugModeFlag: ToggleDebugModeFlag,
  brandDocuments: List<BrandResponseModel>,
  showAppointmentRequestNotificationIcon: (show: boolean) => void,
  isAppointmentRequestNotificationIconVisible: boolean,
  appointments: List<AppointmentModel>,
  updateModelsInState: () => void,
  unReadCampaignCount: number,
  isConfigFetched: boolean,
  isAdminUser: boolean,
  updateConfig: (config: Config) => void,
}

// Used for both checking if accounts section should be shown and deciding which URL to use.
const ACCOUNTS_PERMISSIONS = [
  [createPermission('claim_invoice', 'read'), '/accounts/claim-invoices'],
  [createPermission('payments', 'read'), '/accounts/payments-outstanding'],
];

// Used for both checking if reports section should be shown and deciding which URL to use.
const REPORTS_PERMISSIONS = [
  [createPermission('consult_reports', 'read'), '/reports/consults'],
  [createPermission('accounts_receivable', 'read'), '/reports/accounts-receivable'],
  [createPermission('operations_summary', 'read'), '/reports/operations-summary'],
  [createPermission('supplies_added_and_transfers', 'read'), '/reports/supplies-received'],
  [createPermission('cost_report', 'read'), '/reports/cost-of-goods-sold'],
  [createPermission('inventory_reports', 'read'), '/reports/inventory'],
];

// Used for both checking if inventory section should be shown and deciding which URL to use.
const INVENTORY_PERMISSIONS = [
  [createPermission('inventory_status', 'read'), '/inventory/inventory-status'],
  [createPermission('inventory_items', 'read'), '/inventory/inventory-items'],
  [createPermission('supplies', 'create'), '/inventory/add-supply'],
  [createPermission('suppliers', 'read'), '/inventory/suppliers'],
  [createPermission('drug_dispensation', 'read'), '/inventory/drug-dispensation'],
  [createPermission('expiry_dates', 'read'), '/inventory/expiry-dates'],
];

// Used for both checking if settings section should be shown and deciding which URL to use.
const SETTINGS_PERMISSIONS = [
  [createPermission('clinic_settings', 'update'), '/settings'],
  [createPermission('doctors', 'read'), '/settings/doctors'],
  [createPermission('sales_items', 'read'), '/settings/sales-items'],
  [createPermission('metric_types', 'read'), '/settings/vitals'],
  [createPermission('coverage_payors', 'read'), '/settings/coverage-payors'],
  [createPermission('drug_frequencies', 'read'), '/settings/drug-frequencies'],
  [createPermission('symptom_options', 'read'), '/settings/symptoms'],
  [createPermission('diagnosis_options', 'read'), '/settings/diagnoses'],
  [createPermission('laboratories', 'read'), '/settings/labs'],
  [createPermission('lab_tests', 'read'), '/settings/lab-tests'],
];

// Used for both checking if analytics section should be shown and deciding which URL to use.
const ANALYTICS_PERMISSIONS = [
  [createPermission('analytics_reports', 'read'), '/analytics'],
  [createPermission('analytics_reports', 'write'), '/analytics/reports/new'],
  [createPermission('analytics_reports', 'read'), '/analytics/reports/'],
];

const outbreakConfig = {
  showAnnouncementModal: false,
  showFeatureTab: true,
  name: 'covid-19',
};

const referralProgramConfig = {
  showAnnouncementModal: false,
  name: 'referral-program-rm500',
};

const eConsultProgramConfig = {
  showAnnouncementModal: true,
  name: 'new-e-consult-feature',
};

type State = { userItemToggled: boolean };

/**
 * Gets the patientID as specified in the current hash location. If not found undefined is returned.
 * @returns {string | void}
 */
function getPatientID() {
  const splitHash = location.hash.split('/');
  return splitHash.length > 2 && splitHash[1] === 'patient' ? splitHash[2].split('?')[0] : undefined;
}

/**
 * Return all categories with casenotes for the current patient.
 * @param {List<CaseNoteFileModel>} casenotes All casenotes.
 * @param {List<CategoryModel>} categories All categories
 * @returns {List<CategoryModel>} The used categories.
 */
function getCategories(
  casenotes: List<CaseNoteFileModel>,
  categories: List<CategoryModel>,
): Set<CategoryModel> {
  const patientID = getPatientID();
  const categoryIds = Set(casenotes
    .filter(cnf => cnf.get('patient_id') === patientID)
    .map(cnf => cnf.get('category')));
  let usedCategories = Set();
  categoryIds.forEach((id) => {
    if (!id) {
      // TODO: Add uncategorised.
    }
    const category = categories.find(c => c.get('_id') === id);
    if (category) {
      usedCategories = usedCategories.add(category);
    } else {
      logMessage(`Cant find the category ${id}`); // making this as info because all casenotes category should handle this.
    }
  });
  return usedCategories;
}

/**
 * Formats the given categories to be useful for the Sidebar.
 * @param {Set<CategoryModel>} categories A Set of category models.
 * @returns {Array<{ label: string, url: string }>}
 */
function formatCategories(categories: Set<CategoryModel>): Array<{ label: string, url: string }> {
  if (!categories) {
    return [];
  }
  const categoriesArray = categories
    .sort(sortModelByOrder)
    .map(c => ({
      label: c.get('name'),
      url: `categories/${c.get('_id')}`,
    }))
    .toArray();
  categoriesArray.unshift({ label: 'Starred', url: 'categories/STARRED' });
  categoriesArray.unshift({ label: translate('all'), url: 'categories/ALL' });
  categoriesArray.push({ label: translate('bin'), url: 'categories/BIN' });
  return categoriesArray;
}

/**
 * Returns true if current view is a casenotes grid view.
 * @returns {boolean}
 */
function isGridView() {
  return location.hash.indexOf('/grid') !== -1;
}

/**
 * Returns true if sub menu should be shown.
 * @returns {boolean} True if subitems should be shown.
 */
function showSubItems(): boolean {
  const { hash } = location;
  return hash.indexOf('/categories/') !== -1;
}

/**
 * Returns true if stat dashboard is being shown.
 * @returns {boolean}
 */
function isDashboard(): boolean {
  const splitHash = location.hash.split('/');
  return splitHash.length >= 2 && splitHash[1] === 'dashboard';
}

/**
 * A Sidebar component
 * @class Sidebar
 * @extends {React.Component<Props, State>}
 */
class Sidebar extends React.Component<Props, State> {
  /**
   * Creates an instance of Sidebar.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      userItemToggled: false,
    };
  }

  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.checkFreshchatInitialisation();
    if (!isClinicOverviewPage(window.location.hash) && this.props.appointments.size) {
      this.props.showAppointmentRequestNotificationIcon(true);
    }
  }

  /**
   * Returns the first reports subsection link that the user has permission for.
   * @returns {string}
   */
  getReportsLink(): string {
    const permission = REPORTS_PERMISSIONS
      .find(p => hasPermission(this.props.user, List([p[0]])));
    return permission ? permission[1] : '/reports/consults';
  }

  /**
   * Returns the first inventory subsection link that the user has permission for.
   * @returns {string}
   */
  getInventoryLink(): string {
    const permission = INVENTORY_PERMISSIONS
      .find(p => hasPermission(this.props.user, List([p[0]])));
    return permission ? permission[1] : '/inventory/inventory-status';
  }

  /**
   * Returns the first accounts subsection link that the user has permission for.
   * @returns {string}
   */
  getAccountsLink(): string {
    const permission = ACCOUNTS_PERMISSIONS
      .find(p => hasPermission(this.props.user, List([p[0]])));
    return permission ? permission[1] : '/accounts/claim-invoices';
  }

  /**
   * Returns the first analytics subsection link that the user has permission for.
   * @returns {string}
   */
  getAnalyticsLink(): string {
    const permission = ANALYTICS_PERMISSIONS
      .find(p => hasPermission(this.props.user, List([p[0]])));
    return permission ? permission[1] : '/analytics';
  }

  /**
   * Returns the first settings subsection link that the user has permission for.
   * @returns {string}
   */
  getSettingsLink(): string {
    const permission = SETTINGS_PERMISSIONS
      .find(p => hasPermission(this.props.user, List([p[0]])));
    return permission ? permission[1] : '/settings';
  }

  /**
   * Returns the first SMS Campaigns subsection link that the user can visit.
   * @returns {string}
   */
  getSMSCampaignsLink(): string {
    const isMedadvisorEnabled = MEDADVISOR_CAMPAIGNS_ENABLED && this.props.config?.getIn(['optional_features_enabled', 'medadvisor_campaigns', 'enabled'], false);
    return `/campaigns/${isMedadvisorEnabled ? 'medadvisor' : 'patient'}`;
  }

  /**
   * Renders the sidebar items for stat dashboard.
   * @returns {React.Component} containing sidebar items.
   */
  renderDashboardItems() {
    const interval = getInterval(location.hash);
    const filter = getFilter(location.hash) || '';
    return (
      <Fragment>
        <SidebarTextLink label="dashboard_today" url={`/analytics/today/${filter}`} isSelected={interval === ('today')} />
        <SidebarTextLink label="dashboard_daily" url={`/analytics/daily/${filter}`} isSelected={interval === ('daily')} />
        <SidebarTextLink label="dashboard_weekly" url={`/analytics/weekly/${filter}`} isSelected={interval === ('weekly')} />
        <SidebarTextLink label="dashboard_monthly" url={`/analytics/monthly/${filter}`} isSelected={interval === ('monthly')} />
      </Fragment>
    );
  }

  /**
   * Initialises Freshchat
   * @returns {void}
   */
  initialiseFreshchat() {
    const username = this.props.user.get('id');
    const userConfig = this.props.user.get('model');
    const restoreId = userConfig.get('freshchat_restore_id');
    const email = this.props.config.getIn(['clinic', 'email'], '');
    const externalId = `${getServerName()}-${username}`;
    if (window.fcWidget) {
      window.fcWidget.init({
        token: FRESHCHAT_TOKEN,
        host: FRESHCHAT_HOST,
        tags: FRESHCHAT_TAGS,
        externalId,
        restoreId: restoreId || null,
      });
      if (window.fcWidget.user) {
        window.fcWidget.user.get((resp) => {
          const status = resp && resp.status;
          if (status !== 200) {
            window.fcWidget.user.setProperties({
              firstName: username,
              email,
              server: location.origin,
            });
            window.fcWidget.on('user:created', (resp2) => {
              const status2 = resp2 && resp2.status;
              const data2 = resp2 && resp2.data;
              if (status2 === 200) {
                if (data2.restoreId) {
                  this.props.saveModel(userConfig.set({ freshchat_restore_id: data2.restoreId }));
                }
              }
            });
          }
        });
      }
    }
  }

  /**
   * Check if freshchat was Initialised correctly
   * @returns {void}
   */
  checkFreshchatInitialisation() {
    if (window && window.fcWidget) {
      const isInitialized = window.fcWidget.isInitialized();
      if (!isInitialized) {
        this.initialiseFreshchat();
      } else if (isInitialized && window.fcWidget && window.fcWidget.user) {
        window.fcWidget.user.get((resp) => {
          const { data } = resp;
          if (!data) {
            this.initialiseFreshchat();
          }
        });
      }
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      user, resetSearchQuery, categories, caseNoteFiles, isOnline,
      isAppointmentRequestNotificationIconVisible, isAdminUser,
    } = this.props;
    let className = showSubItems() ? 'c-sidebar c-sidebar--minimised' : 'c-sidebar';
    className = isOnline ? className : `${className} c-sidebar--offline`;
    const patientID = getPatientID();
    const useLegacyScheduling = this.props.config.get('use_legacy_scheduling', false);
    if (isExportUser(user)) {
      return (
        <nav className={className}>
          <Link to="/"><div className="c-sidebar__title" /></Link>
          <SidebarToggleItem
            label={user.get('id') || 'User'}
            analyticsLabel="User"
            isToggled={this.state.userItemToggled}
            onClick={() => this.setState({ userItemToggled: !this.state.userItemToggled })}
          >
            <SidebarTextLink label="data_export" url="/data-export" />
            <SidebarTextLink label="logout" onClick={() => logout()} />
          </SidebarToggleItem>
        </nav>
      );
    }
    if (isDashboard()) {
      return this.renderDashboardItems(className);
    }

    const pharmaconnectConfig = getValidConfigKeys(this.props.config.get('pharmaconnect'), this.props.klinifyConfig.get('pharmaconnect'));
    const showPharmaconnect = this.props.isConfigFetched && pharmaconnectConfig.get('enable', false);
    const showPharmaconnectModalAd = showPharmaconnect && pharmaconnectConfig.get('modal_ad', false);
    const showPharmaconnectSplashAd = showPharmaconnect && pharmaconnectConfig.get('splash_ad', false);
    const isMedadvisorEnabled = MEDADVISOR_CAMPAIGNS_ENABLED && this.props.config?.getIn(['optional_features_enabled', 'medadvisor_campaigns', 'enabled'], false);
    const ackUsers: List<string> = this.props.config?.getIn(['optional_features_enabled', 'medadvisor_campaigns', 'feature_ack_by'], List());
    const isMedAdvisorEnabledInfoModal = isMedadvisorEnabled && !ackUsers.includes(this.props.user.get('id'));
    const isMedAdvisorAgreementModalForAdmin = !isMedadvisorEnabled && this.props.isAdminUser;
    const isMedAdvisorInfoModalForNonAdmin = !isMedadvisorEnabled && !this.props.isAdminUser;
    const showMedadvisorAnnouncement =
      (isMedAdvisorAgreementModalForAdmin || isMedAdvisorEnabledInfoModal ||
        isMedAdvisorInfoModalForNonAdmin) && !isKlinifyUser(user);
    const haveMedadvisorLocalValues = window.localStorage.getItem('medadvisor-agreement-intro-modal-seen') === 'true' ||
      window.localStorage.getItem(`medadvisor-agreement-${user.get('id')}-intro-modal-seen`) === 'true';
    const showOtherPopus = !showMedadvisorAnnouncement && !haveMedadvisorLocalValues;
    if (patientID) {
      const prefix = `/patient/${patientID}`;
      const referralQuery = getReferralQueryString();
      const hashPrefix = `#${prefix}`;
      return (
        <nav className={className}>
          <Link to="/"><div className="c-sidebar__title" /></Link>
          <SidebarItem label="patient_page" url={`${prefix}${referralQuery}`} isSelected={location.hash === `${hashPrefix}${referralQuery}`} />
          <PermissionWrapper permissionsRequired={List([createPermission('patient_casenotes', 'read')])} user={this.props.user}>
            <SidebarItem
              label="casenotes"
              url={`${prefix}/categories/ALL${referralQuery}`}
              isSelected={location.hash.startsWith(`${hashPrefix}/categories/`)}
              location={this.props.location}
            >
              {
                formatCategories(getCategories(caseNoteFiles, categories))
                  .map(({ label, url }) => <SidebarTextLink key={`sidebarCategory-${label}`} label={label} url={isGridView() ? `${prefix}/${url}/grid${referralQuery}` : `${prefix}/${url}${referralQuery}`} />)
              }
            </SidebarItem>
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('document_templates', 'read')])} user={this.props.user}>
            <SidebarItem label="documents" url={`${prefix}/documents${referralQuery}`} isSelected={location.hash === `${hashPrefix}/documents${referralQuery}`} imageSrc="static/images/icon_sidebar_documents.svg" />
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('patient_metrics', 'read')])} user={this.props.user}>
            <SidebarItem label="vitals" url={`${prefix}/vitals/ALL${referralQuery}`} isSelected={location.hash === `${hashPrefix}/vitals/ALL${referralQuery}`} />
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('finalised_bill', 'read')])} user={this.props.user}>
            {
              !useLegacyScheduling &&
              <SidebarItem label="patient_billing_history" url={`${prefix}/billing${referralQuery}`} isSelected={location.hash === `${hashPrefix}/billing${referralQuery}`} />
            }
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('finalised_bill', 'create')])} user={this.props.user}>
            {
              !useLegacyScheduling &&
              <SidebarItem label="add_past_bill" url={`${prefix}/billing/add${referralQuery}`} isSelected={location.hash === `${hashPrefix}/billing/add${referralQuery}`} />
            }
          </PermissionWrapper>
          { !useLegacyScheduling &&
            <SidebarItem
              label="page_settings"
              url={`${prefix}/page-settings`}
              isSelected={location.hash === `${hashPrefix}/page-settings`}
            />
          }
          {
            isKlinifyUser(user) &&
            <DebugModeContainer />
          }
          {
            isKlinifyUser(user) &&
            <DebugActionButton
              user={user}
              config={this.props.config}
              toggleDebugModeFlag={this.props.toggleDebugModeFlag}
              debugModeFlags={this.props.debugModeFlags}
            />
          }
        </nav>
      );
    }

    return (
      <div onClick={() => {
        if (this.state.userItemToggled) {
          this.setState({ userItemToggled: false });
        }
      }}
      >
        <nav className={className}>
          <Link to="/" onClick={resetSearchQuery}><div className="c-sidebar__title" /></Link>
          <SidebarItem
            label="clinic_overview"
            url="/"
            onClick={resetSearchQuery}
            isSelected={location.hash === ('#/')}
            unreadCount={isAppointmentRequestNotificationIconVisible ? '!' : undefined}
            iconStyle={{ backgroundColor: 'red', fontWeight: 'bold' }}
          />
          <PermissionWrapper permissionsRequired={List([createPermission('patient', 'create')])} user={this.props.user}>
            <SidebarItem label="register_patient" url="/registration" isSelected={location.hash.startsWith('#/registration')} />
          </PermissionWrapper>
          <SidebarItem
            label="appointments"
            url="/appointments"
            isSelected={location.hash === ('#/appointments')}
            iconStyle={{ backgroundColor: 'red', fontWeight: 'bold' }}
          />
          { outbreakConfig.showAnnouncementModal &&
            showOtherPopus &&
            <OutbreakModal program={outbreakConfig.name} />
          }
          {
            outbreakConfig.showFeatureTab && (
              <SidebarItem
                label="outbreak_name"
                url="/outbreak/guidelines"
                isSelected={location.hash.startsWith('#/outbreak')}
                imageSrc="static/images/icon_sidebar_outbreaks.svg"
              >
                <SidebarTextLink
                  label="outbreak_guidelines"
                  url="/outbreak/guidelines"
                />
                {OUTBREAK_STATS_TAB_ENABLED && <SidebarTextLink label="outbreak_stats" url="/outbreak/stats" />}
                <SidebarTextLink label="outbreak_labs" url="/outbreak/labs" />
                <SidebarTextLink
                  label="outbreak_brochures"
                  url="/outbreak/brochures"
                />
                <SidebarTextLink
                  label="outbreak_research"
                  url="/outbreak/research"
                />
              </SidebarItem>
            )
          }
          { referralProgramConfig.showAnnouncementModal &&
            showOtherPopus &&
            <ReferralProgramModal program={referralProgramConfig.name} />
          }
          { eConsultProgramConfig.showAnnouncementModal &&
            showOtherPopus &&
            <EConsultFeatureAnnouncementModal program={eConsultProgramConfig.name} />
          }
          {
            isKlinifyUser(user) &&
            <DebugModeContainer />
          }
          {
            hasSomePermission(this.props.user, List(REPORTS_PERMISSIONS.map(p => p[0]))) &&
            !useLegacyScheduling &&
              <SidebarItem
                label="reports"
                url={this.getReportsLink()}
                isSelected={location.hash.startsWith('#/reports')}
                imageSrc="static/images/icon_sidebar_reports.svg"
              >
                <PermissionWrapper permissionsRequired={List([createPermission('consult_reports', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="consult_reports" url="/reports/consults" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('operations_summary', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="operations_summary" url="/reports/operations-summary" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('supplies_added_and_transfers', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="stock_adjustments" url="/reports/stock-adjustments" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('cost_report', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="cost_of_goods_sold" url="/reports/cost-of-goods-sold" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('accounts_receivable', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="accounts_receivable" url="/reports/accounts-receivable" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('inventory_reports', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="inventory_reports" url="/reports/inventory" />
                </PermissionWrapper>
              </SidebarItem>
          }

          {showPharmaconnect &&
            <PharmaConnectSidebarContainer />
          }
          {showPharmaconnectModalAd && PHARMACONNECT_ENABLED &&
            <NewPharmaconnectFeatureModal />
          }
          {showPharmaconnectSplashAd && PHARMACONNECT_ENABLED &&
            <PharmaconnectModalContainer />
          }
          {
            hasSomePermission(this.props.user, List(INVENTORY_PERMISSIONS.map(p => p[0]))) &&
            !useLegacyScheduling &&
            <SidebarItem label="inventory" url={this.getInventoryLink()} isSelected={location.hash.startsWith('#/inventory')} location={this.props.location}>
              <PermissionWrapper permissionsRequired={List([createPermission('inventory_status', 'read')])} user={this.props.user}>
                <SidebarTextLink label="inventory_status" url="/inventory/inventory-status" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('inventory_items', 'read')])} user={this.props.user}>
                <SidebarTextLink label="inventory_items" url="/inventory/inventory-items" />
              </PermissionWrapper>
              {/* <PermissionWrapper permissionsRequired={List([createPermission('supplies', 'create')])} user={this.props.user}>
                <SidebarTextLink label="add_supply" url="/inventory/add-supply" />
              </PermissionWrapper> */}
              {/* <PermissionWrapper permissionsRequired={List([createPermission('supplies', 'create')])} user={this.props.user}>
                <SidebarTextLink label="transfer_in" url="/inventory/transfer-in" />
              </PermissionWrapper> */}
              {/* <PermissionWrapper permissionsRequired={List([createPermission('supplies', 'create')])} user={this.props.user}>
                <SidebarTextLink label="transfer_out" url="/inventory/transfer-out" />
              </PermissionWrapper> */}
              <PermissionWrapper permissionsRequired={List([createPermission('drug_dispensation', 'read')])} user={this.props.user}>
                <SidebarTextLink label="drug_dispensation" url="/inventory/drug-dispensation" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('expiry_dates', 'read')])} user={this.props.user}>
                <SidebarTextLink label="expiry_dates" url="/inventory/expiry-dates" />
              </PermissionWrapper>
              <PermissionWrapper
                permissionsRequired={List([
                  createPermission('clinic_settings', 'update'),
                  createPermission('supply_invoices', 'read'),
                ])}
                user={this.props.user}
              >
                <SidebarTextLink label="supply_invoices" url="/inventory/supply-invoices" />
              </PermissionWrapper>
              { (isKlinifyUser(user) || (this.props.klinifyConfig && this.props.klinifyConfig.getIn(['inventory', 'showInventoryMappingPage'], false))) &&
                <PermissionWrapper permissionsRequired={List([createPermission('inventory_mapping', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="inventory_mapping" url="/inventory/inventory-mapping" />
                </PermissionWrapper>
              }
            </SidebarItem>
          }
          <SidebarItem label="accounts" url={this.getAccountsLink()} isSelected={location.hash.startsWith('#/accounts')} location={this.props.location}>
            <PermissionWrapper permissionsRequired={List([createPermission('claim_invoice', 'read')])} user={this.props.user}>
              <SidebarTextLink label="claim_invoices" url="/accounts/claim-invoices" />
            </PermissionWrapper>
            <PermissionWrapper permissionsRequired={List([createPermission('claim_invoice', 'create')])} user={this.props.user}>
              <SidebarTextLink label="unclaimed_bills" url="/accounts/claim-invoices/add" />
            </PermissionWrapper>
            <PermissionWrapper permissionsRequired={List([createPermission('claim_invoice_payment', 'read')])} user={this.props.user}>
              <SidebarTextLink label="panel_payments" url="/accounts/panel-payments" />
            </PermissionWrapper>
            <PermissionWrapper permissionsRequired={List([createPermission('payments', 'read')])} user={this.props.user}>
              <SidebarTextLink label="payments_outstanding" url="/accounts/payments-outstanding" />
            </PermissionWrapper>
            <PermissionWrapper permissionsRequired={List([createPermission('payments', 'read')])} user={this.props.user}>
              <SidebarTextLink label="payment_history" url="/accounts/payment-history" />
            </PermissionWrapper>
          </SidebarItem>
          <PermissionWrapper permissionsRequired={List([createPermission('patient_list', 'read')])} user={this.props.user}>
            <SidebarItem label="patient_list" url="/patient-list" isSelected={location.hash.startsWith('#/patient-list')} />
          </PermissionWrapper>
          <PermissionWrapper permissionsRequired={List([createPermission('lab_requests', 'read')])} user={this.props.user}>
            <SidebarItem label="lab_requests" url="/lab-requests" isSelected={location.hash.startsWith('#/lab-requests')} />
          </PermissionWrapper>
          {MEDADVISOR_CAMPAIGNS_ENABLED &&
            <MedadvisorAnnouncementModal
              user={user}
              show={showMedadvisorAnnouncement}
              isAdminUser={isAdminUser}
              updateConfig={this.props.updateConfig}
              config={this.props.config}
              isMedadvisorEnabled={isMedadvisorEnabled}
              isMedAdvisorEnabledInfoModal={isMedAdvisorEnabledInfoModal}
              isMedAdvisorAgreementModalForAdmin={isMedAdvisorAgreementModalForAdmin}
              isMedAdvisorAgreementModalForNonAdmin={isMedAdvisorAgreementModalForAdmin}
            />}
          <PermissionWrapper permissionsRequired={List([createPermission('sms_campaigns', 'read')])} user={this.props.user}>
            <SidebarItem
              label="sms_campaigns"
              isSelected={location.hash.startsWith('#/campaigns')}
              imageSrc="static/images/icon_sidebar_sms_campaigns.svg"
              url={this.getSMSCampaignsLink()}
              unreadCount={this.props.unReadCampaignCount}
            >
              {isMedadvisorEnabled && <SidebarTextLink
                label="medadvisor_campaigns"
                url="/campaigns/medadvisor"
                isSelected={location.hash.startsWith('#/campaigns/medadvisor')}
                unreadCount={this.props.unReadCampaignCount}
              />}
              <SidebarTextLink
                label="patient_campaigns"
                url="/campaigns/patient"
                isSelected={location.hash.startsWith('#/campaigns/patient')}
              />
            </SidebarItem>
          </PermissionWrapper>
          {
            hasSomePermission(this.props.user, List(ANALYTICS_PERMISSIONS.map(p => p[0]))) &&
            <SidebarItem label="analytics" url={this.getAnalyticsLink()} isSelected={location.hash.startsWith('#/analytics')}>
              <PermissionWrapper permissionsRequired={List([createPermission('analytics_reports', 'read')])} user={this.props.user}>
                {this.renderDashboardItems()}
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('analytics_reports', 'read')])} user={this.props.user}>
                {isKlinifyUser(user) && <SidebarTextLink label="reports" url="/analytics/summary" />}
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('analytics_reports', 'write')])} user={this.props.user}>
                {isKlinifyUser(user) && <SidebarTextLink label="create_new_report" url="/analytics/reports/new" />}
              </PermissionWrapper>
            </SidebarItem>
          }
          {
            hasSomePermission(this.props.user, List(SETTINGS_PERMISSIONS.map(p => p[0]))) &&
            <SidebarItem label="settings" url={this.getSettingsLink()} isSelected={location.hash.startsWith('#/settings')}>
              <PermissionWrapper permissionsRequired={List([createPermission('doctors', 'read')])} user={this.props.user}>
                <SidebarTextLink label="doctors" url="/settings/doctors" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('clinic_settings', 'update')])} user={this.props.user}>
                <SidebarTextLink label="clinic_settings" url="/settings" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('clinic_settings', 'update')])} user={this.props.user}>
                <SidebarTextLink label="queue_settings" url="/settings/queue" />
              </PermissionWrapper>
              {useLegacyScheduling && <SidebarTextLink label="inventory_items" url="/inventory/inventory-items" />}
              <PermissionWrapper permissionsRequired={List([createPermission('sales_items', 'read')])} user={this.props.user}>
                <SidebarTextLink label="sales_items" url="/settings/sales-items" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('metric_types', 'read')])} user={this.props.user}>
                <SidebarTextLink label="vitals" url="/settings/vitals" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('coverage_payors', 'read')])} user={this.props.user}>
                <SidebarTextLink label="coverage_payors" url="/settings/coverage-payors" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('dosing_regimen', 'read')])} user={this.props.user}>
                <SidebarTextLink label="dosing_regimen" url="/settings/dosing-regimen" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('drug_frequencies', 'read')])} user={this.props.user}>
                <SidebarTextLink label="drug_frequencies" url="/settings/drug-frequencies" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('drug_durations', 'read')])} user={this.props.user}>
                <SidebarTextLink label="drug_durations" url="/settings/drug-durations" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('symptom_options', 'read')])} user={this.props.user}>
                <SidebarTextLink label="symptoms" url="/settings/symptoms" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('diagnosis_options', 'read')])} user={this.props.user}>
                <SidebarTextLink label="diagnoses" url="/settings/diagnoses" />
              </PermissionWrapper>
              <SidebarTextLink label="stages" url="/settings/stages" />
              <SidebarTextLink label="flows" url="/settings/flows" />
              <PermissionWrapper permissionsRequired={List([createPermission('patient_tags_options', 'read')])} user={this.props.user}>
                <SidebarTextLink label="patient_tags" url="/settings/patient-tags" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('suppliers', 'read')])} user={this.props.user}>
                <SidebarTextLink label="suppliers" url="/settings/suppliers" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('clinics', 'read')])} user={this.props.user}>
                <SidebarTextLink label="clinics" url="/settings/clinics" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('document_templates_settings', 'read')])} user={this.props.user}>
                <SidebarTextLink label="document_templates" url="/settings/document-templates" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('panel_categories', 'read')])} user={this.props.user}>
                <SidebarTextLink label="panel_categories" url="/settings/panel-categories" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('laboratories', 'read')])} user={this.props.user}>
                <SidebarTextLink label="laboratories" url="/settings/labs" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('lab_tests', 'read')])} user={this.props.user}>
                <SidebarTextLink label="lab_tests" url="/settings/lab-tests" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('specimens', 'read')])} user={this.props.user}>
                <SidebarTextLink label="specimens" url="/settings/specimens" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('discounts_charges', 'read')])} user={this.props.user}>
                <SidebarTextLink label="discounts_charges" url="/settings/discounts-charges" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('banks', 'read')])} user={this.props.user}>
                <SidebarTextLink label="banks" url="/settings/banks" />
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission('payment_types', 'read')])} user={this.props.user}>
                <SidebarTextLink label="payment_types" url="/settings/payment-types" />
              </PermissionWrapper>
            </SidebarItem>
          }
          <div style={{ marginTop: 'auto' }}>
            <ReferralLink />
            {
              user.get('id') &&
              <SidebarToggleItem
                label={user.get('id') || 'User'}
                analyticsLabel="User"
                isToggled={this.state.userItemToggled}
                onClick={() => this.setState({ userItemToggled: !this.state.userItemToggled })}
              >
                <SidebarTextLink label={version} skipTranslate />
                <SidebarTextLink label="release_notes" url="/release-notes" />
                <PermissionWrapper permissionsRequired={List([createPermission('self_password', 'update')])} user={this.props.user}>
                  <SidebarTextLink label="change_password" url="/user/change-password" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('users', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="user_management" url="/admin/user-management" />
                </PermissionWrapper>
                <PermissionWrapper permissionsRequired={List([createPermission('user_groups', 'read')])} user={this.props.user}>
                  <SidebarTextLink label="user_groups" url="/admin/user-groups" />
                </PermissionWrapper>
                {isKlinifyUser(user) && <SidebarTextLink label="access_management" url="/access-management" />}
                {isKlinifyUser(user) && <SidebarTextLink label="data_export" url="/data-export" />}
                {isKlinifyUser(user) && <SidebarTextLink label="data_debug" url="/data-debug" />}
                {isKlinifyUser(user) && <SidebarTextLink label="styleguide" url="/styleguide" />}
                <SidebarTextLink label="logout" onClick={() => logout()} />
              </SidebarToggleItem>
            }
            <SupportLink style={user.get('id') ? {} : { marginTop: 'auto' }} />
            {
              isKlinifyUser(user) &&
              <DebugActionButton
                user={user}
                config={this.props.config}
                toggleDebugModeFlag={this.props.toggleDebugModeFlag}
                debugModeFlags={this.props.debugModeFlags}
              />
            }
          </div>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
