import BaseModel from './baseModel';

type Attributes = {
  _id: string;
  type: 'drug_manufacturer';
  name: string;
  addresses: string[];
}

/**
 * DrugManufacturerModel
 * @namespace DrugManufacturerModel
 */
class DrugManufacturerModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'drug_manufacturer';
  }
}

export default DrugManufacturerModel;
