import React from 'react';
import { P } from 'glamorous';

import translate from './../../utils/i18n';
import AddEditPatientAlert from './addEditPatientAlert';

import type { SaveModel } from './../../types';
import type PatientModel from './../../models/patientModel';

type Props = {
  patient: PatientModel,
  saveModel: SaveModel,
};

/**
 * A component for displaying patient alert card and for add/edit the same.
 * @class PatientAlert
 * @extends {React.Component}
 */
class PatientAlert extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card">
        <div className="o-card__header">
          <h1 data-public className="o-card__title">{translate('patient_alert')}</h1>
          <div className="u-flex-right u-margin-right--1ws">
            <AddEditPatientAlert patient={this.props.patient} saveModel={this.props.saveModel} />
          </div>
        </div>
        {
          !this.props.patient.get('alert', '') &&
          <p className="u-padding--1ws">
            { translate('no_comments_added') }
          </p>
        }
        {
          this.props.patient.get('alert') &&
          <P className="u-padding--1ws" whiteSpace="pre-line">
            { this.props.patient.get('alert') }
          </P>
        }
      </div>
    );
  }
}
export default PatientAlert;
