import { APPOINTMENT } from './utils/analytics';
import translate from './utils/i18n';

/* eslint max-lines: 0 */
module.exports = {
  APP_MODE: {
    CUSTOMER: 'customer',
    INTERNAL: 'internal',
  },
  DEV_SERVERS: ['localhost', 'dev.klinify.com', 'local.klinify.com'],
  DDOC_VERSION: 'mwa_blue',
  DDOC_VERSION_INVENTORY_3: 'inventory3',
  ALLERGIES: {
    STATUS_OPTIONS: [
      'active',
      'inactive',
      'resolved',
    ],
    VERIFICATION_STATUS_OPTIONS: [
      'unconfirmed',
      'confirmed',
      'refuted',
      'entered-in-error',
    ],
    CATEGORY_OPTIONS: [
      'medication',
      'food',
      'environment',
      'other',
    ],
    MEDICATION_TYPES: [
      'drug',
      'active-ingredient',
      'unspecified',
    ],
    CRITICALITY_OPTIONS: [
      'CRITL',
      'CRITH',
      'CRITU',
    ],
  },
  CASENOTE_ORDER_INCREMENT: 8192, // Value by which to increment the order of a new casenote.
  CONFIG: {
    PATIENT_REQUIRED_FIELDS: [
      'case_id',
      'ic',
      'sex',
      'dob',
      'nationality',
      'ethnicity',
      'tel',
      'address',
    ],
    CONSULT_TYPES: ['Consult', 'Emergency', 'X-Ray', 'Blood Test'],
    SALES_ITEM_TYPES: ['Service', 'Other'],
    PATIENT_PAGE_CARDS: [
      { name: 'econsult_info', show: true },
      { name: 'patient_profile', show: true },
      { name: 'latest_vitals', show: true },
      { name: 'allergies', show: true },
      { name: 'current_encounter', show: true },
      { name: 'encounter_history', show: true },
      { name: 'patient_alert', show: true },
    ],
  },
  LAB: {
    STATUS_OPTIONS: [
      'pending',
      'order_printed',
      'sent_success',
      'sent_error',
      'acknowledged',
      'in_progress',
      'completed',
      'approved',
      'results_received',
      'rejected',
      'cancelled',
      'reflex',
      'mismatch',
      'sending',
    ],
    SPECIMEN_STATUS_OPTIONS: [
      'pending',
      'collected',
      'courier_collected',
      'provider_received',
      'provider_rejected',
    ],
  },
  TRANSACTION_TYPES: [
    'write_off',
    'damaged',
    'expired',
    'returned',
    'recalled',
    'loan_to',
    'transfer_to',
    'missing',
    'dispensing_error',
    'consumed',
  ],
  FRESHCHAT_TOKEN: '72a3a0b7-e47a-48d9-9882-cfc1cea087ec',
  FRESHCHAT_HOST: 'https://wchat.freshchat.com',
  FRESHCHAT_TAGS: ['Support', 'Product_Feedback', 'Release_Updates'],
  NATIONALITIES: [
    'Malaysia',
    'Afghanistan',
    'Albania',
    'Algeria',
    'Andorra',
    'Angola',
    'Antigua & Deps',
    'Argentina',
    'Armenia',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bhutan',
    'Bolivia',
    'Bosnia Herzegovina',
    'Botswana',
    'Brazil',
    'Brunei',
    'Bulgaria',
    'Burkina',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Central African Rep',
    'Chad',
    'Chile',
    'China',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo {Democratic Rep}',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Fiji',
    'Finland',
    'France',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Greece',
    'Grenada',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland {Republic}',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea North',
    'Korea South',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Mauritania',
    'Mauritius',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Morocco',
    'Mozambique',
    'Myanmar, {Burma}',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'St Kitts & Nevis',
    'St Lucia',
    'Saint Vincent & the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome & Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tonga',
    'Trinidad & Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Yemen',
    'Zambia',
    'Zimbabwe',
  ],
  PAYMENT_METHODS: [
    { value: 'cash', label: 'Cash' },
    { value: 'card', label: 'Card' },
  ],
  UNICODE: {
    EMDASH: '\u2014',
    MINUS: '\u002D',
  },
  DEFAULT_CLINIC_CONFIG_FILE_ID: 'KLINIFY__CLINIC__CONFIG__',
  DEFAULT_KLINIFY_CONFIG_FILE_ID: 'KLINIFY__ONLY__CONFIG__',
  EDIT_HISTORY_THRESHOLD: 90000, // Must be ms
  HL7_FAMILY_MEMBER_CODES: {
    FAMMEMB: 'family_member',
    CHILD: 'child',
    CHLDADOPT: 'adopted_child',
    DAUADOPT: 'adopted_daughter',
    SONADOPT: 'adopted_son',
    CHLDFOST: 'foster_child',
    DAUFOST: 'foster_daughter',
    SONFOST: 'foster_son',
    DAUC: 'daughter',
    DAU: 'natural_daughter',
    STPDAU: 'stepdaughter',
    NCHILD: 'natural_child',
    SON: 'natural_son',
    SONC: 'son',
    STPSON: 'stepson',
    STPCHLD: 'step_child',
    EXT: 'extended_family_member',
    AUNT: 'aunt',
    MAUNT: 'maternal_aunt',
    PAUNT: 'paternal_aunt',
    COUSN: 'cousin',
    MCOUSN: 'maternal_cousin',
    PCOUSN: 'paternal_cousin',
    GGRPRN: 'great_grandparent',
    GGRFTH: 'great_grandfather',
    MGGRFTH: 'maternal_great-grandfather',
    PGGRFTH: 'paternal_great-grandfather',
    GGRMTH: 'great_grandmother',
    MGGRMTH: 'maternal_great-grandmother',
    PGGRMTH: 'paternal_great-grandmother',
    MGGRPRN: 'maternal_great-grandparent',
    PGGRPRN: 'paternal_great-grandparent',
    GRNDCHILD: 'grandchild',
    GRNDDAU: 'granddaughter',
    GRNDSON: 'grandson',
    GRPRN: 'grandparent',
    GRFTH: 'grandfather',
    MGRFTH: 'maternal_grandfather',
    PGRFTH: 'paternal_grandfather',
    GRMTH: 'grandmother',
    MGRMTH: 'maternal_grandmother',
    PGRMTH: 'paternal_grandmother',
    MGRPRN: 'maternal_grandparent',
    PGRPRN: 'paternal_grandparent',
    INLAW: 'inlaw',
    CHLDINLAW: 'child-in-law',
    DAUINLAW: 'daughter_in-law',
    SONINLAW: 'son_in-law',
    PRNINLAW: 'parent_in-law',
    FTHINLAW: 'father-in-law',
    MTHINLAW: 'mother-in-law',
    SIBINLAW: 'sibling-in-law',
    BROINLAW: 'brother-in-law',
    SISINLAW: 'sister-in-law',
    NIENEPH: 'niece_nephew',
    NEPHEW: 'nephew',
    NIECE: 'niece',
    UNCLE: 'uncle',
    MUNCLE: 'maternal_uncle',
    PUNCLE: 'paternal_uncle',
    PRN: 'parent',
    ADOPTP: 'adoptive_parent',
    ADOPTF: 'adoptive_father',
    ADOPTM: 'adoptive_mother',
    FTH: 'father',
    FTHFOST: 'foster_father',
    NFTH: 'natural_father',
    NFTHF: 'natural_father_of_fetus',
    STPFTH: 'stepfather',
    MTH: 'mother',
    GESTM: 'gestational_mother',
    MTHFOST: 'foster_mother',
    NMTH: 'natural_mother',
    NMTHF: 'natural_mother of fetus',
    STPMTH: 'stepmother',
    NPRN: 'natural_parent',
    PRNFOST: 'foster_parent',
    STPPRN: 'step_parent',
    SIB: 'sibling',
    BRO: 'brother',
    HBRO: 'half-brother',
    NBRO: 'natural_brother',
    TWINBRO: 'twin_brother',
    FTWINBRO: 'fraternal_twin_brother',
    ITWINBRO: 'identical_twin_brother',
    STPBRO: 'stepbrother',
    HSIB: 'half-sibling',
    HSIS: 'half-sister',
    NSIB: 'natural_sibling',
    NSIS: 'natural_sister',
    TWINSIS: 'twin_sister',
    FTWINSIS: 'fraternal_twin_sister',
    ITWINSIS: 'identical_twin_sister',
    TWIN: 'twin',
    FTWIN: 'fraternal_twin',
    ITWIN: 'identical_twin',
    SIS: 'sister',
    STPSIS: 'stepsister',
    STPSIB: 'step_sibling',
    SIGOTHR: 'significant_other',
    DOMPART: 'domestic_partner',
    FMRSPS: 'former_spouse',
    SPS: 'spouse',
    HUSB: 'husband',
    WIFE: 'wife',
  },
  HL7_POLICY_HOLDER_RELATIONSHIP_CODES: ['self', 'child', 'parent', 'spouse', 'common', 'other'],
  DATE_FORMAT_TO_SAVE: 'YYYY-MM-DD', // standard date format used by db, should be in this format while saving to db
  // REASON_FOR_INVENTORY_MAPPING_CHOICE: ['cant_seem_correct_item', 'custom_formulary', 'other_reasons'],
  MDL_MODAL_SHOWN_TO: 'mdl_campaign_modal_shown_to',
  UNMAPPED_STATUSES: ['UNMAPPED', 'REJECTED'],
  DISPENSATION_UNITS: [
    'Syrup',
    'Tablet',
    'Capsule',
    'Powder',
    'Solution',
    'Spray',
    'Injection',
    'Others',
  ],
  INVENTORY_MAPPING_STATUSES: {
    VERIFIED: 'VERIFIED',
    MAPPED: 'MAPPED',
    REJECTED: 'REJECTED',
    UNMAPPED: 'UNMAPPED',
    PENDING_REVIEW: 'PENDING_REVIEW',
    REVIEW_REJECTED: 'REVIEW_REJECTED',
  },
  DRUG_TYPES: [
    { value: 'SYRUP', label: 'Syrup' },
    { value: 'TABLET', label: 'Tablet' },
    { value: 'CAPSULE', label: 'Capsule' },
    { value: 'POWDER', label: 'Powder' },
    { value: 'TOPICAL_MEDICINES', label: 'Topical medicines (Cream/lotion/ointments)' },
    { value: 'SPRAY', label: 'Spray' },
    { value: 'INJECTION', label: 'Injection' },
    { value: 'OIL', label: 'Oil' },
    { value: 'SOLUTION', label: 'Solution' },
    { value: 'INHALER', label: 'Inhaler' },
    { value: 'DROP', label: 'Drop' },
    { value: 'SUPPOSITORY', label: 'Suppository' },
    { value: 'OTHERS', label: 'Others' },
  ],
  CAMPAIGN_TIME_UNITS: [
    { value: 'day', label: 'Day(s)' },
    { value: 'week', label: 'Week(s)' },
    { value: 'month', label: 'Month(s)' },
    { value: 'year', label: 'Year(s)' },
  ],
  CAMPAIGN_SPECIFIC_OPTIONS: [
    { value: 'encounter_time', label: translate('encounter') },
    { value: 'specific_master_campaign_job_time', label: translate('master_campaign') },
    { value: 'specific_campaign_job_time', label: translate('campaign') },
  ],
  APPOINTMENT_PATIENT_FILTER_OPTIONS: [
    { value: 'name', label: 'Name' },
    { value: 'phone_no', label: 'Phone No.' },
    { value: 'ic_no', label: 'IC No.' }
  ],
  PHONE_NUMBERS: {
    PHONE_NUMBER1: '+60 123 321 12323',
    PHONE_NUMBER2: '+60 123 321 12332',
  },
  // feature flag added for backward compatibility.
  // TODO: Remove the flag and its check when the app is stable with encounter, flow and stages feature
  APPOINTMENT_FLOW_FALLBACK_ENABLED: false,
  DOC_VALIDATION_ENABLED: true,
  DIC_ENABLED: true,
  MEDADVISOR_CAMPAIGNS_ENABLED: false,
  PHARMACONNECT_ENABLED: true,
  OUTBREAK_STATS_TAB_ENABLED: false,
};
