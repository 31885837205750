import { connect } from 'react-redux';
import { saveFactory } from './../utils/redux';
import { setUserAdminStatus, updateConfigValue, updateConfig } from './../actions';
import UserAccessManagement from './../components/userManagement/userAccessManagement';

import type { State, Dispatch, Config } from './../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({ user, config }: State) => ({
  user,
  config,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  setAdminUserStatus: (isAdmin?: boolean) => dispatch(setUserAdminStatus(isAdmin)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const AccessManagementContainer =
    connect(mapStateToProps, mapDispatchToProps)(UserAccessManagement);

export default AccessManagementContainer;
