import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import Table from './../table/table';
import { renderDateTime } from './../../utils/tables';
import { exportModelsAsJSON } from './../../utils/export';
import { UNICODE } from './../../constants';
import { getNowForFileName } from './../../utils/time';
import Button from './../buttons/button';
import Header from './../header/header';

import type { Model } from './../../types';

type Props = {
  unsyncedModels: Promise<List<Model>>,
  isSyncing: boolean,
  isOnline: boolean,
};

type State = {
  unsyncedModels: List<Model>,
}

const columns = [
  { accessor: 'type', Header: translate('document_type') },
  { accessor: 'last_saved', Header: translate('last_saved_at'), Cell: renderDateTime },
];


/**
 * A component that displays any unsynced documents and allows the user to either try to manually
 * save them, or to export them to pass to Klinify support staff.
 * @class UnsyncedDocuments
 * @extends {React.PureComponent<Props>}
 */
class UnsyncedDocuments extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of UnsyncedDocuments.
   * @param {Props} props Props
   */
  constructor(props) {
    super(props);
    this.state = {
      unsyncedModels: List(),
    };
  }

  /**
   * Checks if the route changes after component update.
   * @param {Props} prevProps Prev props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.unsyncedModels !== prevProps.unsyncedModels) {
      this.props.unsyncedModels.then((unsyncedModels) => {
        this.setState({ unsyncedModels });
      });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { isSyncing, isOnline } = this.props;
    const { unsyncedModels } = this.state;
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('unsynced_documents')}</h1>
          <Button
            disabled={
              !window.processUnsyncedDocumentsQueue ||
              unsyncedModels.size === 0 ||
              isSyncing ||
              !isOnline
            }
            className="o-button o-button--small u-margin-right--1ws"
            onClick={() => window.processUnsyncedDocumentsQueue()}
            dataPublic
          >
            {translate('save_documents')}
          </Button>
          <Button
            disabled={unsyncedModels.size === 0 || isSyncing}
            className="o-button o-button--small"
            onClick={
              () => exportModelsAsJSON(
                unsyncedModels,
                `unsynced_documents_${getNowForFileName()}`,
              )
            }
            dataPublic
          >
            {translate('export_documents')}
          </Button>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('unsynced_documents')}</h2>
          <Table
            columns={columns}
            data={unsyncedModels.map(m => ({
              type: translate(m.get('type')),
              last_saved: m.hasBeenSaved() ? m.getLastUpdateTime() : UNICODE.EMDASH,
            })).toArray()}
            noDataText={translate('no_unsynced_documents')}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default UnsyncedDocuments;
