import React from 'react';
import glamorous from 'glamorous';

import type { EncounterTooltip } from './../types';

type Props = {
  tooltips: Array<EncounterTooltip>,
  className: string,
};

const TooltipWrapper = glamorous.div({
  position: 'absolute',
  top: '100%',
  background: 'rgba(98, 98, 98, 0.9)',
  color: 'white',
  zIndex: 99,
  borderRadius: '0px 0px 6px 6px',
  padding: '0 1em',
});

const Tooltipheader = glamorous.h1({
  padding: '10px 6px 5px',
  fontWeight: 600,
});

const TooltipContent = glamorous.div({
  padding: '0px 6px 10px',
});
/**
 * Renders Chip that can be used for showing tag or small information and can be remove.
 * @returns {React.Component} The rendered component
*/
const Tooltip = ({ tooltips, className }: Props) => (
  <TooltipWrapper
    onMouseOver={(e) => { e.stopPropagation(); }}
    onFocus={(e) => { e.stopPropagation(); }}
    className={className}
  >
    { tooltips && tooltips.map((tooltip, index) => (
      <div
        key={tooltip.header}
        style={{
          borderTop: (index > 0) ? '1px solid rgba(255, 255, 255, 0.4)' : 'none',
        }}
      >
        <Tooltipheader>{tooltip.header}</Tooltipheader>
        <TooltipContent>{tooltip.content}</TooltipContent>
      </div>
    ))}
  </TooltipWrapper>
);

export default Tooltip;
