import { connect } from 'react-redux';
import { List } from 'immutable';

import ListEditor from './../components/listEditor/listEditor';
import translate from './../utils/i18n';
import { sortByPayment } from './../utils/comparators';
import { saveFactory } from './../utils/redux';
import { hasPermission, createPermission } from '../utils/permissions';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ salesItems, config, user, coveragePayors }: State) => ({
  columns: [
    {
      accessor: 'name', Header: translate('name'), width: 300, filterable: true,
    },
    { accessor: 'item_type', Header: translate('type') },
    {
      accessor: 'price', Header: translate('price'), minWidth: 100, width: 100, sortMethod: sortByPayment,
    },
    { accessor: 'default_quantity', Header: translate('default_quantity'), sortMethod: sortByPayment },
    {
      accessor: 'cost_price', Header: translate('cost_price'), sortMethod: sortByPayment,
    },
  ],
  items: salesItems,
  label: translate('sales_items'),
  type: 'sales_item',
  config,
  canAdd: hasPermission(user, List([createPermission('sales_items', 'create')])),
  canEdit: hasPermission(user, List([createPermission('sales_items', 'update')])),
  canDelete: hasPermission(user, List([createPermission('sales_items', 'delete')])),
  coveragePayors,
  user,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const SalesItemsContainer = connect(mapStateToProps, mapDispatchToProps)(ListEditor);

export default SalesItemsContainer;
