
import BaseModel from './baseModel';

// This is NOT A CouchDB Model
type Attributes = {
  _id: string,
  // Keep created/ edited fields to allow compatibility with BaseModel
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  name: string,
  type: string,
  dispensation_unit: string,
  manufacturer_id: string,
  manufacturer_name: string,
  notes: string,
  active_ingredients: Array<string>,
};

/**
  * MasterDrugModel
  * @namespace MasterDrugModel
  */
class MasterDrugModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'drug_master';
  }
}

export default MasterDrugModel;
