import React, { useState } from 'react';
import { List, Map, Set } from 'immutable';
import glamorous from 'glamorous';
import { updateInventoryItemDetails } from './../../utils/inventory';
import { getStore } from './../../utils/redux';
import StatelessModal from './../modals/statelessModal';
import { colours, wsUnit } from '../../utils/css';
import type { Config, SaveModel, MapValue, DrugMap } from './../../types';
import type BaseModel from '../../models/baseModel';
import DrugModel from './../../models/drugModel';
import CoveragePayorModel from '../../models/coveragePayorModel';
import MasterDrugModel from '../../models/masterDrugModel';
import DrugManufacturerModel from '../../models/drugManufacturerModel';
import DosingRegimenModel from '../../models/dosingRegimenModel';
import DrugForm from './drugForm';
import DrugMapping from './drugMapping';
import translate from '../../utils/i18n';

type Props = {
    config: Config,
    modelToEdit?: DrugModel | void,
    saveModel: SaveModel,
    drugMap: DrugMap,
    coveragePayors: List<CoveragePayorModel>,
    dispensationUnitOptions: Set<string>,
    labelClassName?: string,
    autofocus?: string,
    saveIsSticky: boolean,
    noClearButton?: boolean,
    onSave?: (drug: DrugModel) => void,
    setModelToEdit: (model: DrugModel | undefined) => void,
    updateConfigValue: (keys: Array<string>, value: MapValue) => void,
    updateConfig: (config: Config) => void,
    drugDurations: List<List<[number, string]>>,
    setAddEditDrugModalVisible: (visibility: boolean) => void,
    // Drug mapping flow props.
    fetchTableMasterData: (ids: List<string>) => Promise<void>,
    masterDrugModelsMap: Map<string, MasterDrugModel>,
    setDrugManufacturers: (models: List<DrugManufacturerModel>) => void,
    drugManufacturers: List<DrugManufacturerModel>,
    dosingRegimens: List<DosingRegimenModel>,
    drugName: string,
    // these props are from doc validation.
    isFromDocValidationModal?: boolean,
    validationDocObject?: { id: string, type: string }
    isDocValidationModalSaving?: boolean,
    validationReferrerModel?: BaseModel,
    onSaveAtDocValidationModal?: (wasSuccessful: boolean) => void,
}

const ModalHeader = glamorous.div({
  backgroundColor: colours.grey3,
  padding: `calc(0.5 * ${wsUnit}) ${wsUnit}`,
});

/**
 * Component to add/edit drug
 * @returns {React.SFC}
 */
const AddEditDrug = ({
  config,
  modelToEdit,
  saveModel,
  drugMap,
  coveragePayors,
  dispensationUnitOptions,
  labelClassName,
  autofocus,
  saveIsSticky,
  noClearButton,
  setModelToEdit,
  updateConfigValue,
  updateConfig,
  onSave,
  drugDurations,
  setAddEditDrugModalVisible,
  fetchTableMasterData,
  masterDrugModelsMap,
  setDrugManufacturers,
  drugManufacturers,
  dosingRegimens,
  drugName,
  // these props are from doc validation.
  isFromDocValidationModal,
  validationDocObject,
  isDocValidationModalSaving,
  onSaveAtDocValidationModal,
}: Props) => {
  const [drugMappingVisible, setDrugMappingVisible] = useState(!modelToEdit);
  const [drugFormVisible, setDrugFormVisible] = useState(!!modelToEdit);
  const [mappedData, setMappedData] = useState({ event: undefined, data: undefined });
  const [initialFormData, setInitialFormData] = useState(undefined);
  const [showDrugFormUnsavedPrompt, setShowDrugFormUnsavedPrompt] = useState(false);
  const [isDrugFormChanged, setDrugFormChanged] = useState(false);


  /**
   * @param {boolean} visibility modalVisiblity
   * @returns {void}
   */
  const handleModalVisibility = (visibility: boolean) => {
    if (visibility) {
      setDrugFormVisible(visibility);
      setDrugMappingVisible(visibility);
      if (setAddEditDrugModalVisible) setAddEditDrugModalVisible(visibility);
    }
  };

  /**
   * Checks for changes in drug form before closing modal and shows Unsaved data prompt if needed
   * @returns {void}
   */
  const handleModalClose = () => {
    if (isDrugFormChanged) {
      setShowDrugFormUnsavedPrompt(true);
    } else {
      // also need to make the modelToEdit undefined here.
      if (setModelToEdit) {
        setModelToEdit(undefined);
      }
      setDrugFormVisible(false);
      setInitialFormData(undefined);
      setShowDrugFormUnsavedPrompt(false);
      if (setAddEditDrugModalVisible) setAddEditDrugModalVisible(false);
    }
  };

  /**
   * @desc check for mapped data and update api/models after adding/editing drug
   * @param {DrugModel} model drug model to be saved or updated
   * @returns {Promise<DrugModel>}
   */
  const onSaveClicked = (model: DrugModel): Promise<DrugModel> => { // TODO: check for event and update api/models accordingly
    if (initialFormData) {
      return new Promise((resolve, reject) => {
        saveModel(model, () => {}).then((drugModel: DrugModel) => {
          if (!drugModel) {
            resolve(drugModel);
          }
          const { event, data } = mappedData;
          if (event && data) {
            updateInventoryItemDetails(getStore(), drugModel, data, event)
              .then(() => {
                setInitialFormData(undefined);
                resolve(drugModel);
              });
          }
        }).catch(error => reject(error));
      });
    }
    return saveModel(model, () => {});
  };

  const renderDrugForm = (
    <>
      <ModalHeader>
        <button
          className="o-button o-button--small o-button--padded"
          onClick={() => setDrugMappingVisible(true)}
        >
          {translate('add_change_mapping')}
        </button>
      </ModalHeader>
      <DrugForm
        modelToEdit={modelToEdit}
        clearModelToEdit={() => handleModalClose()}
        coveragePayors={coveragePayors}
        dosingRegimens={dosingRegimens}
        config={config}
        drugName={drugName}
        saveDrugAndMappingData={onSaveClicked}
        saveModel={saveModel}
        dispensationUnitOptions={dispensationUnitOptions}
        updateConfigValue={updateConfigValue}
        updateConfig={updateConfig}
        saveIsSticky={saveIsSticky}
        noClearButton={noClearButton}
        labelClassName={labelClassName}
        autofocus={autofocus}
        drugDurations={drugDurations}
        initialValue={initialFormData}
        onSave={onSave}
        showDrugFormPrompt={showDrugFormUnsavedPrompt}
        setDrugFormChanged={(isChanged: boolean) =>
          setDrugFormChanged(isChanged)}
        onCancel={() => setShowDrugFormUnsavedPrompt(false)}
        onDiscard={() => {
          if (setModelToEdit) {
            setModelToEdit(undefined);
          }
          setDrugFormVisible(false);
          setDrugFormChanged(false);
          setShowDrugFormUnsavedPrompt(false);
          setInitialFormData(undefined);
          if (setAddEditDrugModalVisible) setAddEditDrugModalVisible(false);
        }}
        // these props comes from docValidation
        isFromDocValidationModal={isFromDocValidationModal}
        validationDocObject={validationDocObject}
        isDocValidationModalSaving={isDocValidationModalSaving}
        onSaveAtDocValidationModal={onSaveAtDocValidationModal}
      />
    </>
  );

  const renderDrugMapping = (
    <>
      <DrugMapping
        visible={drugMappingVisible}
        setDrugMappingVisible={(visible: boolean) => {
          if (!drugFormVisible && visible) {
            setDrugFormVisible(true);
            if (setAddEditDrugModalVisible) setAddEditDrugModalVisible(true);
          } else if (!drugFormVisible && !visible) {
            setDrugFormVisible(false);
            setDrugMappingVisible(false);
            if (setAddEditDrugModalVisible) setAddEditDrugModalVisible(false);
          }
          setDrugMappingVisible(false);
        }}
        config={config}
        updateConfigValue={updateConfigValue}
        updateConfig={updateConfig}
        drug={modelToEdit}
        masterDrugModelsMap={masterDrugModelsMap}
        fetchTableMasterData={fetchTableMasterData}
        setDrugManufacturers={setDrugManufacturers}
        drugManufacturers={drugManufacturers}
        updateMapping={(data: any, event: any, formData: any) => {
          setInitialFormData(formData);
          setMappedData({ event, data });
        }
        }
      />
    </>
  );
  return (
    <>
      { isFromDocValidationModal ? renderDrugForm :
      <StatelessModal
        id="add_new_drug"
        title={translate('edit_drug')}
        setVisible={visibility => handleModalVisibility(visibility)}
        visible={(drugFormVisible && !drugMappingVisible)}
        onClose={() => handleModalClose()}
        noButton
        explicitCloseOnly
        dataPublicHeader
      >
        {renderDrugForm}
      </StatelessModal>
      }
      {drugMappingVisible && renderDrugMapping}
    </>
  );
};

export default AddEditDrug;
