import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

type Props = {
  filename?: string,
  filetype?: string, // eg. "pdf"
};

const FileIconWrapper = glamorous.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 200,
  minHeight: 443,
  flexDirection: 'column',
});

const FileName = glamorous.p({
  fontFamily: 'robotomedium',
  textAlign: 'center',
});

/**
 * Renders FileIcon that will use as a image placeholder for uploaded file
 * @returns {React.PureComponent} The rendered component
*/
class FileIcon extends PureComponent<Props, {}> {
  props: Props;

  static defaultProps = {
    filename: '',
    filetype: '',
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <FileIconWrapper>
        {
          this.props.filetype === 'application/pdf' ?
            <img src="static/images/icon_pdf.svg" alt={this.props.filename} style={{ height: 106, paddingBottom: 20, marginTop: -30 }} />
            :
            <i className="mfb-component__child-icon fa fa-file-text-o" style={{ fontSize: 100, position: 'inherit', paddingBottom: 40 }} />
        }
        <FileName>{this.props.filename}</FileName>
      </FileIconWrapper>
    );
  }
}

export default FileIcon;
