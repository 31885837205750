import BaseModel from './baseModel';
import translate from './../utils/i18n';

export type PriceCoveragePayor = { coverage_payor_id: string, price: number };
export type CategoryCoveragePayor = { coverage_payor_id: string, category: string };

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'drug',
  hidden: boolean,
  name: string,
  dosage_unit: string,
  price_coverage_payor: Array<PriceCoveragePayor>,
  dispensation_unit?: string,
  non_coverage_payor_price?: number,
  coverage_payor_price?: number,
  cost_price?: number,
  default_dispensing_batch?: string,
  default_coverage_payor_category?: string,
  coverage_payor_category?: Array<CategoryCoveragePayor>,
  low_inventory_threshold?: number,
  notes?: string,
  atdps_integration?: boolean,
};

/**
   * DrugModel
   *
   * @namespace DrugModel
   */
class DrugModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'drug';
  }

  /**
   * Gets the dispensation unit of the Drug. Unit is returned if none is specified.
   * @returns {string}
   */
  getDispensationUnit(): string {
    return this.get('dispensation_unit', translate('unit'), false, false);
  }

  /**
   * Gets the dosage unit of the Drug. Unit is returned if none is specified.
   * @returns {string}
   */
  getPrescriptionUnit(): string {
    return this.get('dosage_unit', translate('unit'), false);
  }

  /**
   * Sets the DrugModel visibility to the inverse of the given value.
   * @param {boolean} isVisible True if the item should be visible, false otherwise.
   * @returns {DrugModel} The altered DrugModel.
   */
  setVisible(isVisible: boolean): DrugModel {
    return this.set('hidden', !isVisible);
  }

  /**
   * Checks if the drugs has been tagged with the text provided.
   * @param {string} tag tag to check against
   * @returns {boolean} True if drug contains tag
   */
  hasTag(tag: string): boolean {
    return this.get('tags', []).indexOf(tag) > -1;
  }

  /**
   * Gets the tags for the drug as a comma separated string.
   * @returns {string}
   */
  getTags(): string {
    return this.get('tags', []).join(', ');
  }

  /**
   * Gets the price of the drug. If coveragePayorID is passed and an item in
   * this.attributes.price_coverage_payor matches the ID then that price is passed.
   * @param {string | void} coveragePayorID A coverage payor ID.
   * @returns {(number | void)}
   */
  getPrice(coveragePayorID?: string | void): number | void {
    if (!this.has('price')) {
      return undefined;
    }
    if (coveragePayorID) {
      const item = this.get('price_coverage_payor', []).find(i => i.coverage_payor_id === coveragePayorID);
      if (item) {
        return item.price;
      }
      return this.get('coverage_payor_price') || this.get('price');
    }
    return this.get('non_coverage_payor_price') || this.get('price');
  }

  /**
   * Gets the default quantity of the drug model. If none found than 1 is returned.
   * @returns {number}
   */
  getDefaultQuantity(): number {
    return this.has('default_quantity') && this.get('default_quantity') !== '' ?
      parseFloat(this.get('default_quantity')) : 1;
  }

  /**
   * is panel exists
   * @param {string} coveragePayorID A coverage payor ID.
   * @returns {boolean} true if exists else false
   */
  isPanelExists(coveragePayorID: string): boolean {
    const item = this.get('price_coverage_payor', []).find(i => i.coverage_payor_id === coveragePayorID);
    return Boolean(item);
  }
}

export default DrugModel;
