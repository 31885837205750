import React from 'react';

/**
 * A component to be displayed when offline.
 * @returns {React.component} A react component
 */
const OfflineComponent = () => (
  <div>This part of the app is not available while offline.</div>
);

export default OfflineComponent;
