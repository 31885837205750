import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import translate from './../utils/i18n';
import Banner from '../components/outbreak/banner';
import { wsUnit, colours, borderRadius } from './../utils/css';

const researchURL = '/static/outbreak/research.json';

const Page = glamorous.div({
  padding: `${wsUnit} calc(1.5 * ${wsUnit})`,
  height: '100vh',
});

const FetchError = glamorous.div({
  marginTop: wsUnit,
  border: `1px solid ${colours.red}`,
  padding: `calc(0.5 * ${wsUnit})`,
  borderRadius,
});

const TabItem = glamorous.span({
  padding: '6px 12px',
  border: '1px solid silver',
  cursor: 'pointer',
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const TabItemSelected = glamorous.span({
  padding: '6px 12px',
  border: '1px solid silver',
  backgroundColor: 'silver',
});

const CardContainer = glamorous.div({
  backgroundColor: 'white',
  padding: '1rem',
  marginTop: '1rem',
  marginBottom: '2rem',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
});

const Obj = glamorous.object({
  height: '80vh',
  width: '100%',
});

/**
 * Render a research document based on its type
 * @param {Object} research filter from state
 * @returns {HTMLElement} html element
 */
const renderResearch = (research) => {
  if (!research) return null;
  switch (research.type) {
    case 'pdf':
      return (
        <Obj
          data={research.file}
          type="application/pdf"
          aria-label="research"
          alt="research"
          style={{
            height: '80vh',
            width: '100%',
          }}
        />
      );
    default:
      return null;
  }
};

/**
 * A container for showing outbreak research
 * Skipping the redux integration as this container doesn't need a state management
 * @class OutbreakResearchContainer
 * @extends {React.Component<Props, State>}
 */
const OutbreakResearchContainer = () => {
  const [selected, selectResearch] = useState(null);
  const [research, setResearch] = useState([]);
  const [isError, setError] = useState(false);

  useEffect(() => {
    fetch(researchURL)
      .then(async (result) => {
        const text = await result.text();
        const r = JSON.parse(text);
        setResearch(r.research);
        selectResearch(r.research[0]);
        setError(false);
      })
      .catch(() => {
        setResearch([]);
        selectResearch(null);
        setError(true);
      });
  }, []);

  return (
    <Page className="c-markdown o-scrollable-container">
      <Banner />
      <h1 data-public>{translate('outbreak_research')}</h1>
      {isError ? <FetchError>{translate('outbreak_research_fetch_err')}</FetchError> : null}
      {research.map((res) => {
        if (selected && res.file === selected.file) {
          return (
            <TabItemSelected key={res.file}>
              {res.title}
            </TabItemSelected>
          );
        }
        return (
          <TabItem key={res.file} onClick={() => selectResearch(res)}>
            {res.title}
          </TabItem>
        );
      })}
      <CardContainer>{renderResearch(selected)}</CardContainer>
    </Page>
  );
};

export default OutbreakResearchContainer;
