import React, { useState } from 'react';
import { List, Map } from 'immutable';
import type { SortingRule } from 'react-table';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import { prettifyTime } from './../../utils/time';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import { voidBill } from './../../utils/billing';
import { getEncounterCreatedBy } from './../../utils/encounters';
import { getConfirmation, transformColumnKeys, transformFilteredColumns } from './../../utils/utils';
import getPanelNameForPatient from './../../utils/coveragePayors';
import { createErrorNotification } from './../../utils/notifications';
import { getModelsForBill } from './../../utils/api';
import Button from './../buttons/button';
import Header from './../header/header';
import TableColumnsSettings from '../table/tableColumnsSettings';
import { filterWithConditions } from '../../utils/tableFilter';
import { getIncompletedEncounterTableColumns } from './columns';

import type EncounterModel from './../../models/encounterModel';
import type BillModel from './../../models/billModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type { SaveModel, Config, User, SaveModels } from './../../types';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { FilteringRule } from './tableFilter';

type Props = {
  config: Config,
  encounters: List<EncounterModel>,
  bills: Map<string, BillModel>,
  label: string,
  patients: Map<string, PatientStubModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
  updateConfig: (config: Config) => void,
  tableProps: {
    sorted: SortingRule[],
    filteringRule: FilteringRule,
  }
};

/**
 * Renders a view bill button that links to the bill.
 * @param {EncounterModel} encounter Encounter model.
 * @param {User} user User.
 * @returns {React.Component}
 */
function getViewBillButton(encounter: EncounterModel, user: User) {
  return (
    <PermissionWrapper
      permissionsRequired={List([createPermission('unfinalised_bill', 'read')])}
      user={user}
    >
      <Button
        className="o-button o-button--small"
        onClick={(event) => {
          event.stopPropagation();
          location.hash = `/patient/${encounter.get('patient_id')}/billing/${encounter.get('_id')}`;
        }}
        dataPublic
      >
        {translate('view_bill')}
      </Button>
    </PermissionWrapper>
  );
}

/**
 * Returns a void button for the encounter. If bill is not found it will only cancel encounter,
 * otherwise it will cancel encounter and void bill.
 * @param {EncounterModel} encounter Encounter model
 * @param {BillModel} bill Bill model. May be null or undefined.
 * @param {SaveModel} saveModel SaveModel function.
 * @param {SaveModels} saveModels Save models funciton.
 * @param {User} user User
 * @returns {React.Component}
 */
export function getVoidBillButton(
  encounter: EncounterModel,
  bill: BillModel | null | undefined,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
) {
  return (
    <PermissionWrapper
      permissionsRequired={List([createPermission('queue_patient_for_today', 'delete')])}
      user={user}
    >
      <Button
        className="o-button o-button--danger o-button--small"
        onClick={(event) => {
          event.stopPropagation();
          getConfirmation(translate('confirm_cancel'))
            .then(
              () => {
                if (bill) { // Just in case the bill went missing for some reason.
                  getModelsForBill(bill.get('_id'))
                    .then((models) => {
                      voidBill(bill, saveModels, true, models, List()); // transactions will be empty as bill is not finalised
                    })
                    .catch(() => createErrorNotification(translate('bill_void_error')));
                }
                saveModel(encounter.addEvent('cancelled', new Date().getTime()));
              },
              () => {},
            );
        }}
        dataPublic
      >
        {translate('cancel')}
      </Button>
    </PermissionWrapper>
  );
}

/**
 * Returns an object with appropriate fields for a Table row populated from an EncounterModel.
 * @param {EncounterModel} encounter The encounter the button is for.
 * @param {Map<string, BillModel>} bills A map of all Bills.
 * @param {List<PatientStubModel>} patients A map of all Patient Stubs.
 * @param {List<SalesItemModel>} salesItems A list of all salesItems.
 * @param {SaveModel} saveModel Save model function.
 * @param {SaveModels} saveModels Save models function.
 * @param {User} user User.
 * @param {List<PractitionerModel>} practitioners A list of all Practitioners
 * @param {List<CoveragePayorModel>} coveragePayors A list of all panels
 * @returns {object} A table row object.
 */
function getTableRow(
  encounter: EncounterModel,
  bills: Map<string, BillModel>,
  patients: Map<string, PatientStubModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  user: User,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
) {
  const patient = patients.get(encounter.get('patient_id'));
  const bill = bills.get(encounter.get('bill_id'));
  let doctor = encounter.getDoctorName(practitioners);
  if (doctor === UNICODE.EMDASH || !doctor?.length) {
    doctor = translate('unassigned');
  }
  return {
    date: encounter.getLastEventTime(),
    time: prettifyTime(encounter.getLastEventTime()),
    name: patient ? patient.get('patient_name', UNICODE.EMDASH) : translate('loading...'),
    ic: patient ? patient.get('ic', UNICODE.EMDASH, false) : translate('loading...'),
    case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : translate('loading...'),
    sex: patient ? patient.get('sex', UNICODE.EMDASH, false) : translate('loading...'),
    payment_type: patient ? patient.getPaymentType() : translate('loading...'),
    panel_name: getPanelNameForPatient(patient, coveragePayors),
    doctor,
    creator: getEncounterCreatedBy(encounter),
    view_bill: bill ?
      getViewBillButton(encounter, user) :
      <Button dataPublic className="o-button o-button--small" disabled>{translate('bill_not_found')}</Button>,
    void_bill: getVoidBillButton(encounter, bill, saveModel, saveModels, user),
    link: `/patient/${encounter.get('patient_id')}`,
  };
}

/**
 * An EncounterTable component to be used within the PatientList component.
 * @param {any} props The props for the component.
 * @returns {React.Component} An EncounterTable component.
 */
const IncompleteEncounterTable = ({
  encounters, bills, label, patients, salesItems,
  saveModel, saveModels, config, user, practitioners, coveragePayors,
  updateConfig,
  tableProps,
}: Props) => {
  const COLUMNS = getIncompletedEncounterTableColumns(config);
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));

  /**
   * Returns memoized data for the table
   * @returns {Array<Row>}
   */
  const getTableData = () => {
    const tableData = encounters.map(encounter =>
      getTableRow(
        encounter,
        bills,
        patients,
        salesItems,
        saveModel,
        saveModels,
        user,
        practitioners,
        coveragePayors,
      )).toArray();
    return {
      tableData,
      filteredTableData: filterWithConditions(tableData, tableProps.filteringRule),
    };
  };

  const { filteredTableData } = getTableData();

  return (
    <section className="o-card">
      <Header className="o-card__header">
        <h1 className="o-card__title" data-public>{ label }</h1>
        <span>-&nbsp;&nbsp;{encounters.size}</span>
        <span className="o-card__header__right-aligned-text">
          <TableColumnsSettings
            config={config}
            configFieldName="incomplete_encounter"
            originalColumns={List(transformColumnKeys(COLUMNS))}
            columns={columns}
            onUpdateColumns={updatedColumns => setColumns(updatedColumns)}
            updateConfig={updateConfig}
            isCompact
          />
        </span>
      </Header>
      <Table
        columns={transformFilteredColumns(COLUMNS, columns)}
        data={filteredTableData}
        defaultSorted={[{ id: 'date', desc: false }]}
        noDataText={tableProps?.filteringRule?.filterRules?.length
          ? translate('no_filter_result')
          : translate('no_incomplete_encounters', { encounterLabel: config.getIn(['encounters', 'labels', 'encounters'], 'Encounters') })}
        sorted={tableProps.sorted}
      />
    </section>
  );
};


export default IncompleteEncounterTable;
