import React from 'react';
import { List } from 'immutable';

import BillItemModel from '../../models/billItemModel';
import FormError from '../formError';
import SalesItemsFormItem from './salesItemsFormItem';
import StatelessModal from '../modals/statelessModal';

import type SalesItemModel from '../../models/salesItemModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type { Config, InventoryCount, SaveModel, SaveModels, MapValue, User } from '../../types';
import type ProviderModel from '../../models/providerModel';

type Props = {
  billItems: List<BillItemModel>,
  config: Config,
  disabled?: boolean,
  disableChanges?: boolean,
  salesItems: List<SalesItemModel>,
  isModalVisible: (value: boolean) => void,
  modalVisible: boolean,
  inventoryCount: InventoryCount,
  saveModels: SaveModels,
  patientID: string,
  billID: string,
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  providers: List<ProviderModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  user: User,
  updateConfig: (config: Config) => void,
  modalId?: string,
  modalButtonLabel?: string,
  modalButtonClass?: string,
  modalTitle?: string,
  dataPublicHeader?: boolean,
};

type State = {
  changesMade: boolean,
  showPrompt: boolean,
};

/**
 * A list of Bill items.
 * @class SalesItemsFormModal
 * @extends {Component}
 */
class SalesItemsFormModal extends React.Component<Props, State> {
  /**
   * Creates an instance of SalesItemsForm.
   * @param {any} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      changesMade: false,
      showPrompt: false,
    };
  }

  handleClose = () => {
    this.setState({
      changesMade: false,
      showPrompt: false,
    });
    this.props.isModalVisible(false);
  }

  /**
   * Renders the component.
   * @return {string} - JSX markup for the component
   */
  render() {
    return (
      <StatelessModal
        noButton={this.props.disableChanges}
        id={this.props.modalId || ''}
        buttonLabel={this.props.modalButtonLabel || ''}
        buttonClass={this.props.modalButtonClass || ''}
        title={this.props.modalTitle || ''}
        setVisible={(isVisible: boolean) => {
          if (this.state.changesMade && this.props.modalVisible && !isVisible) {
            this.setState({ showPrompt: true });
          } else {
            this.props.isModalVisible(isVisible);
          }
        }}
        onClose={() => {
          if (this.state.changesMade) {
            this.setState({
              showPrompt: true,
            });
          } else {
            this.handleClose();
          }
        }}
        visible={this.props.modalVisible}
        large
        explicitCloseOnly
        dataPublicHeader={this.props.dataPublicHeader}
      >
        {
          this.state.errorMessage &&
          <div className="u-margin--standard">
            <FormError>{this.state.errorMessage}</FormError>
          </div>
        }
        <SalesItemsFormItem
          billItems={this.props.billItems}
          config={this.props.config}
          disabled={this.props.disabled}
          salesItems={this.props.salesItems}
          isModalVisible={this.props.isModalVisible}
          inventoryCount={this.props.inventoryCount}
          saveModels={this.props.saveModels}
          patientID={this.props.patientID}
          billID={this.props.billID}
          saveModel={this.props.saveModel}
          coveragePayors={this.props.coveragePayors}
          providers={this.props.providers}
          updateConfigValue={this.props.updateConfigValue}
          user={this.props.user}
          updateConfig={this.props.updateConfig}
          onChangesMade={changesMade => this.setState({ changesMade })}
          showPrompt={this.state.showPrompt}
          onPromptCancel={() => this.setState({ showPrompt: false })}
          onDiscard={this.handleClose}
        />
      </StatelessModal>
    );
  }
}
export default SalesItemsFormModal;
