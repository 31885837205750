import React from 'react';
import { List } from 'immutable';

import glamorous from 'glamorous';
import translate from '../../utils/i18n';
import Radio from '../inputs/radio';

type Props = {
  errorMessage: string,
  onChange: (targetPatients: string) => void,
  target: string,
  disabled?: boolean,
  disableProspective?: boolean,
};

const FormFieldContainer = glamorous.div({
  alignItems: 'center',
});

/**
 * Renders a component showing the schedule form section of the sms campaign form.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
function TargetPatientFormField(props: Props) {
  /**
   * Calls when form schedule unit change
   * @param {number | string} value props.
   * @returns {void}
   */
  function onTargetPatientsValueChange(value: string) {
    props.onChange(value);
  }
  const defaultOptions = List([{ label: translate('who_visit_in_the_future_and_visited_recently'), value: 'retrospective' }]);
  const options = props.disableProspective ? defaultOptions : defaultOptions.unshift({ label: translate('who_visit_in_the_future'), value: 'prospective' });
  return (
    <React.Fragment>
      <div>
        <FormFieldContainer className="u-flex-row">
          <Radio
            id="target_patients"
            label={translate('target_patients')}
            options={options.toArray()}
            value={props.target}
            onValueChanged={newValue => onTargetPatientsValueChange(newValue)}
          />
        </FormFieldContainer>
      </div>
      <div>
        {props.errorMessage && (
          <div
            className="o-text-input__error"
            style={{ marginTop: 0, marginBottom: '1rem' }}
          >
            {props.errorMessage}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default TargetPatientFormField;
