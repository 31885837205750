import React from 'react';
import { List, Map } from 'immutable';
import outdent from '@jrolfs/outdent';
import glamorous from 'glamorous';

import translate from './../../utils/i18n';
import { UNICODE } from '../../constants';
import { wsUnit } from './../../utils/css';
import { exportEncounterData } from './../../utils/export';
import { convertNumberToPrice } from './../../utils/utils';
import EncounterForm from './encounterForm';
import EncounterModel from './../../models/encounterModel';
import MedicalCertificateFormModal from './../medicalCertificates/medicalCertificateFormModal';
import MedicalCertificateModel from './../../models/medicalCertificateModel';
import TimeChitFormModal from './../timeChits/timeChitFormModal';
import StatelessModal from './../modals/statelessModal';
import { prettifyTime, convertTimestampToDate } from './../../utils/time';
import SymptomsInput from './symptomsInput';
import DiagnosesInput from './diagnosesInput';
import PermissionWrapper from './../permissions/permissionWrapper';
import AddEditPrescriptions from './../prescriptions/addEditPrescriptions';
import { createPermission, hasSomePermission } from './../../utils/permissions';
import MenuButton from '../buttons/menuButton';
import AddDocumentDataView from './../documentTemplates/addDocumentDataView';
import SalesItemsFormModal from '../salesItems/salesItemsFormModal';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import PatientModel from './../../models/patientModel';
import type AllergyModel from './../../models/allergyModel';
import type ConditionModel from './../../models/conditionModel';
import type DrugModel from './../../models/drugModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type SalesItemModel from './../../models/salesItemModel';
import type TimeChitModel from './../../models/timeChitModel';
import type BillItemModel from './../../models/billItemModel';
import type InventoryMapModel from './../../models/inventoryMapModel';
import type { Config, SaveModel, User, InventoryCount, SaveModels } from './../../types';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type PractitionerModel from '../../models/practitionerModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import DosingRegimenModel from '../../models/dosingRegimenModel';

type Props = {
  patient: PatientModel,
  allergies: List<AllergyModel>,
  billItems: List<BillItemModel>,
  doctor: string,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  encounters: List<EncounterModel>,
  medicalCertificates: List<MedicalCertificateModel>,
  timeChits: List<TimeChitModel>,
  config: Config,
  klinifyConfig: Config,
  prescriptions: List<PrescriptionModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  isOnline: boolean,
  inventoryCount: InventoryCount,
  symptoms: List<ConditionModel>,
  diagnoses: List<ConditionModel>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  coveragePayorID?: string,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  documentTemplates: List<DocumentTemplateModel>,
  actionClassName?: string,
  onOrderLabTest: (isOrdered: boolean, enocunterID: string) => void,
  updateConfig: (config: Config) => void,
  saveModels: SaveModels,
  drugDurations?: List<List<number, string>>,
  verifiedDrugs: List<InventoryMapModel>,
  practitioners: List<PractitionerModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  dosingRegimens: List<DosingRegimenModel>
  patientPrescriptionHistory: List<PrescriptionModel>,
};

type State = {
  editEncounterModalVisible: boolean,
  medicalCertificateModalVisible: boolean,
  timeChitModalVisible: boolean,
  prescriptionsModalVisible: boolean,
  documentTemplateModalVisible: boolean,
  salesItemsModalVisible: boolean,
}

const ActionContainer = glamorous.div({
  float: 'right',
  marginTop: `calc(${wsUnit} / 6)`,
  '& button': {
    display: 'none',
  },
});

/**
 * Creates a ConsultationItemAction that displays action button and menu for an individual Encounter
 * and will be used by every encounter history item for single view and every row in multiple view.
 * @param {object} props The props for the component.
 * @param {object} State The state for the component.
 * @returns {React.Component} ConsultationItemAction.
 */
class ConsultationItemAction extends React.Component<Props, State> {
  /**
   * Creates an instance of ConsultationItemAction.
   * @param {Props} props The props for the component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      editEncounterModalVisible: false,
      medicalCertificateModalVisible: false,
      timeChitModalVisible: false,
      prescriptionsModalVisible: false,
      documentTemplateModalVisible: false,
      salesItemsModalVisible: false,
    };
  }

  /**
   * Handle modal visibility of AddEditPrescription
   * @param {boolean} state modal visibility
   * @returns {undefined}
   */
  handlePrescriptionsModalVisible = (state: boolean) => {
    this.setState({ prescriptionsModalVisible: state });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      encounter, medicalCertificates, config, salesItems, timeChits,
      saveModel, doctor, patient, coveragePayors, allergies, symptoms,
      diagnoses, prescriptions, drugs, documentTemplates,
    } = this.props;
    const permissionPrefix = encounter.isCurrent() ? 'current_encounter' : 'past_encounters'; // Sets permission required based on type of encounter
    const hasBillViewPermission = hasSomePermission(this.props.user, List([createPermission('finalised_bill', 'update'), createPermission('finalised_bill', 'read')]));
    const hasOrderLabTestPermission = hasSomePermission(this.props.user, List([createPermission(`${permissionPrefix}_lab_tests`, 'read')]));
    let encounterActions = [
      {
        label: translate('edit_encounter_note'),
        value: 'editEncounterModalVisible',
        dataPublic: true,
      },
    ];
    if (medicalCertificates.size === 0) {
      encounterActions = [
        ...encounterActions,
        {
          label: translate('add_mc'),
          value: 'medicalCertificateModalVisible',
          dataPublic: true,
        },
      ];
    }
    if (timeChits.size === 0) {
      encounterActions = [
        ...encounterActions,
        {
          label: translate('add_time_chit'),
          value: 'timeChitModalVisible',
          dataPublic: true,
        },
      ];
    }
    encounterActions = [
      ...encounterActions,
      {
        label: translate('add_prescriptions'),
        value: 'prescriptionsModalVisible',
        dataPublic: true,
      },
      {
        label: translate('add_sales_items'),
        value: 'salesItemsModalVisible',
        dataPublic: true,
      },
      {
        label: translate('create_document'),
        value: 'documentTemplateModalVisible',
        dataPublic: true,
      },
      {
        label: translate('export_encounter'),
        value: 'exportEncounter',
      },
    ];
    if (hasBillViewPermission) {
      encounterActions = [
        ...encounterActions,
        {
          label: translate('view_bill'),
          value: 'viewBill',
          dataPublic: true,
        },
      ];
    }
    if (hasOrderLabTestPermission) {
      encounterActions = [
        ...encounterActions,
        {
          label: translate('order_lab_test'),
          value: 'orderLabTest',
        },
      ];
    }
    return (
      <ActionContainer>
        <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_medical_certificates`, 'create')])} user={this.props.user}>
          {
            medicalCertificates.size === 0 &&
              <MedicalCertificateFormModal
                modalId={`encounterMedicalCertificate-${encounter.get('_id')}`}
                modalButtonClass="o-text-button o-text-button--contextual u-margin-right--1ws"
                modalClassName="u-margin-left--auto"
                modalTitle={translate('medical_certificate')}
                modalVisible={this.state.medicalCertificateModalVisible}
                isModalVisible={isVisible => this.setState({
                  medicalCertificateModalVisible: isVisible,
                })}
                explicitCloseOnly
                config={config}
                initialDate={encounter.getLastEventTime()}
                patientID={encounter.get('patient_id')}
                encounterID={encounter.get('_id')}
                encounter={encounter}
                onSave={mc => mc.setSerialNumber()
                  .then(model => saveModel(model))
                  .then((model) => {
                    this.setState({ medicalCertificateModalVisible: false });
                    return model;
                  })
                }
                dataPublicHeader
              />
            }
        </PermissionWrapper>
        <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_time_chits`, 'create')])} user={this.props.user}>
          {
              timeChits.size === 0 &&
              <TimeChitFormModal
                modalId={`encounterTimeChit-${encounter.get('_id')}`}
                modalButtonClass="o-text-button o-text-button--contextual u-margin-right--1ws"
                modalClassName={medicalCertificates.size > 0 ? 'u-margin-left--auto' : ''}
                modalTitle={translate('time_chit')}
                modalVisible={this.state.timeChitModalVisible}
                isModalVisible={isVisible => this.setState({ timeChitModalVisible: isVisible })}
                explicitCloseOnly
                config={config}
                initialDate={encounter.getLastEventTime()}
                patientID={encounter.get('patient_id')}
                encounterID={encounter.get('_id')}
                onSave={(timeChit) => {
                  timeChit.setSerialNumber().then(model => saveModel(model));
                  this.setState({ timeChitModalVisible: false });
                }}
                encounter={encounter}
                dataPublicHeader
              />
            }
        </PermissionWrapper>
        {
            hasSomePermission(this.props.user, List([createPermission(`${permissionPrefix}_conditions`, 'update'), createPermission(`${permissionPrefix}_notes`, 'update')])) &&
            <StatelessModal
              id={`encounterNotes-${encounter.get('_id')}`}
              buttonLabel=""
              buttonClass="o-text-button o-text-button--contextual u-margin-right--1ws"
              className={medicalCertificates.size > 0 && timeChits.size > 0 ? 'u-margin-left--auto' : ''}
              title={`${translate('edit')} ${config.getIn(['encounters', 'labels', 'encounter'], 'Encounter')} - ${encounter.getDate()} - ${prettifyTime(encounter.getCreatedTime())} - ${encounter.getEncounterType(salesItems)} - ${translate(encounter.getLastEventType())} - ${encounter.get('doctor', translate('no_doctor_assigned'))}`}
              visible={this.state.editEncounterModalVisible}
              setVisible={isVisible => this.setState({ editEncounterModalVisible: isVisible })}
              explicitCloseOnly
            >
              <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_conditions`, 'update')])} user={this.props.user}>
                <div className="u-margin--standard">
                  <SymptomsInput
                    config={this.props.config}
                    encounter={this.props.encounter}
                    saveModel={this.props.saveModel}
                    symptoms={this.props.symptoms}
                    updateConfigValue={this.props.updateConfigValue}
                    updateConfig={this.props.updateConfig}
                  />
                  <DiagnosesInput
                    config={this.props.config}
                    diagnoses={this.props.diagnoses}
                    encounter={this.props.encounter}
                    saveModel={this.props.saveModel}
                    updateConfigValue={this.props.updateConfigValue}
                    updateConfig={this.props.updateConfig}
                  />
                </div>
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_notes`, 'update')])} user={this.props.user}>
                <EncounterForm
                  config={this.props.config}
                  encounter={encounter}
                  practitioners={this.props.practitioners}
                  onSave={
                    updatedEncounter => saveModel(updatedEncounter)
                      .then(() => this.setState({ editEncounterModalVisible: false }))
                  }
                  encounterStageMap={this.props.encounterStageMap}
                />
              </PermissionWrapper>
            </StatelessModal>
          }
        <PermissionWrapper
          permissionsRequired={List([createPermission(`${permissionPrefix}_prescriptions`, 'update')])}
          user={this.props.user}
        >
          <AddEditPrescriptions
            isPrescriptionsModalVisible={this.state.prescriptionsModalVisible}
            handlePrescriptionsModalVisible={this.handlePrescriptionsModalVisible}
            hidePrescriptionsButtonLabel
            dosingRegimens={this.props.dosingRegimens}
            useTextButton
            coveragePayors={this.props.coveragePayors}
            practitioners={this.props.practitioners}
            prescriptions={this.props.prescriptions}
            drugs={this.props.drugs}
            config={this.props.config}
            klinifyConfig={this.props.klinifyConfig}
            encounter={this.props.encounter}
            encounters={this.props.encounters}
            saveModel={this.props.saveModel}
            saveModels={this.props.saveModels}
            isOnline={this.props.isOnline}
            inventoryCount={this.props.inventoryCount}
            coveragePayorID={this.props.coveragePayorID}
            user={this.props.user}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            drugDurations={this.props.drugDurations}
            verifiedDrugs={this.props.verifiedDrugs}
            allergies={this.props.allergies}
            patientPrescriptionHistory={this.props.patientPrescriptionHistory}
          />
        </PermissionWrapper>
        <PermissionWrapper
          permissionsRequired={List([createPermission(`${permissionPrefix}_sales_items`, 'update')])}
          user={this.props.user}
        >
          <SalesItemsFormModal
            modalId="addEditBilledSalesItemsModal"
            modalButtonLabel={this.props.billItems.count() > 0 ? translate('edit') : translate('add')}
            modalButtonClass="o-text-button o-text-button--contextual u-margin-right--1ws"
            modalTitle={translate('sales_items')}
            billItems={this.props.billItems.filter(i => i.isSalesItem())}
            salesItems={this.props.salesItems}
            config={this.props.config}
            isModalVisible={value => this.setState({ salesItemsModalVisible: value })}
            modalVisible={this.state.salesItemsModalVisible}
            inventoryCount={this.props.inventoryCount}
            saveModels={this.props.saveModels}
            patientID={this.props.encounter.get('patient_id')}
            billID={this.props.encounter.get('bill_id')}
            coveragePayors={this.props.coveragePayors}
            saveModel={this.props.saveModel}
            updateConfigValue={this.props.updateConfigValue}
            user={this.props.user}
            updateConfig={this.props.updateConfig}
            dataPublicHeader
          />
        </PermissionWrapper>
        <PermissionWrapper permissionsRequired={List([createPermission('document_templates', 'create')])} user={this.props.user}>
          <StatelessModal
            id={`documentTemplate-${encounter.get('_id')}`}
            buttonLabel={translate('create_document')}
            buttonClass="o-button o-button--small u-margin--1ws u-width-180"
            visible={this.state.documentTemplateModalVisible}
            setVisible={isVisible => this.setState({ documentTemplateModalVisible: isVisible })}
            title={translate('create_document')}
            explicitCloseOnly
            dataPublicHeader
          >
            <AddDocumentDataView
              documentTemplates={documentTemplates}
              closeModal={() => this.setState({ documentTemplateModalVisible: false })}
              patientId={encounter.get('patient_id')}
              encounterId={encounter.get('_id')}
              isOnline={this.props.isOnline}
            />
          </StatelessModal>
        </PermissionWrapper>
        <MenuButton
          label={translate('actions')}
          className={`u-margin-right--1ws ${this.props.actionClassName ? this.props.actionClassName : ''}`}
          items={encounterActions}
          onValueSelected={(a, e) => {
            e.stopPropagation();
            if (a === 'editEncounterModalVisible') {
              this.setState({ editEncounterModalVisible: true });
            }
            if (medicalCertificates.size === 0 && a === 'medicalCertificateModalVisible') {
              this.setState({ medicalCertificateModalVisible: true });
            }
            if (timeChits.size === 0 && a === 'timeChitModalVisible') {
              this.setState({ timeChitModalVisible: true });
            }
            if (a === 'prescriptionsModalVisible') {
              this.setState({ prescriptionsModalVisible: true });
            }
            if (a === 'salesItemsModalVisible') {
              this.setState({ salesItemsModalVisible: true });
            }
            if (a === 'documentTemplateModalVisible') {
              this.setState({ documentTemplateModalVisible: true });
            }
            if (a === 'viewBill') {
              location.hash = `/patient/${this.props.encounter.get('patient_id')}/billing/${this.props.encounter.get('_id')}`;
            }
            if (a === 'orderLabTest') {
              this.props.onOrderLabTest(true, this.props.encounter.get('_id'));
            }
            if (a === 'exportEncounter') {
              exportEncounterData({
                patient,
                coveragePayors,
                configPatientCard: config.getIn(['encounters', 'export_sections', 'patient_card']),
                configAllergies: config.getIn(['encounters', 'export_sections', 'allergies']),
                configEncounterNotes: config.getIn(['encounters', 'export_sections', 'encounter_notes']),
                configPrescriptions: config.getIn(['encounters', 'export_sections', 'prescriptions']),
                configMedicalCertificates: config.getIn(['encounters', 'export_sections', 'medical_certificates']),
                configTimeChits: config.getIn(['encounters', 'export_sections', 'time_chits']),
                allergies: allergies && allergies.map(allergy => outdent`

                  Name: ${allergy.get('name', UNICODE.EMDASH, false)}
                  Reaction:  ${allergy.get('reaction', UNICODE.EMDASH, false)}
                  Onset Date:  ${allergy.get('onset_date', UNICODE.EMDASH, false)}
                  Last Occurrence Date: ${allergy.get('last_occurence_date', UNICODE.EMDASH, false)}
                  Status: ${allergy.get('status', UNICODE.EMDASH, false)}
                  Criticality: ${allergy.get('criticality', UNICODE.EMDASH, false)}
                  Allergy Type: ${allergy.get('allergy_type', UNICODE.EMDASH, false)}
                  Category: ${allergy.get('category', UNICODE.EMDASH, false)}
                  Notes: ${allergy.get('notes', UNICODE.EMDASH, false)}

                `).toArray().toString(),
                encounterNotes: outdent`
                  ${encounter.getDate()} - ${prettifyTime(encounter.getStartTime())}
                  Flow Name: ${encounter.getEncounterType(this.props.salesItems)}
                  Doctor: ${encounter.getDoctorName(this.props.practitioners, true)}
                  Symptoms: ${symptoms.map(s => s.get('name')).toArray().toString().replace(/,/g, '_ ') || UNICODE.EMDASH}
                  Diagnoses: ${diagnoses.map(d => d.get('name')).toArray().toString().replace(/,/g, '_ ') || UNICODE.EMDASH}
                  Notes: \n${encounter.getNotes(this.props.practitioners, this.props.encounterStageMap, true) as string || UNICODE.EMDASH}
                `,
                prescriptions: prescriptions.map(p => outdent`

                  Drug Name: ${p.getDrug(drugs)}
                  Dosage: ${p.getDosage() || UNICODE.EMDASH}
                  Price: ${convertNumberToPrice(p.get('sale_price')) || UNICODE.EMDASH}
                  Quantity: ${p.get('sale_quantity', UNICODE.EMDASH, false)}
                  Reason: ${p.get('reason', UNICODE.EMDASH, false)}
                  Notes: ${p.get('notes', UNICODE.EMDASH, false)}

                `).toArray().toString(),
                medicalCertificates: medicalCertificates.map(m => outdent`
                  Reason: ${m.get('reason', UNICODE.EMDASH, false)}
                  Days: ${m.get('days', UNICODE.EMDASH, false)}
                  From: ${m.getStartDate()}
                  To: ${m.getEndDate()}
                  Details: ${m.get('notes', UNICODE.EMDASH, false)}
                `).toArray().toString(),
                timeChits: timeChits.map(t => outdent`
                  Date: ${t.getCreationDate()}
                  From: ${prettifyTime(t.get('start_time', UNICODE.EMDASH, false))}
                  To: ${prettifyTime(t.get('end_time', UNICODE.EMDASH, false))}
                  Notes: ${t.get('notes', UNICODE.EMDASH, false)}
                `).toArray().toString(),
                encounterDate: convertTimestampToDate(encounter.getLastEventTime()),
              });
            }
          }}
          dataPublic
        />
      </ActionContainer>
    );
  }
}

export default ConsultationItemAction;
