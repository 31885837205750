import * as React from 'react';

/**
 * A style reset for the Styleguide (needed because Catalog makes some styles unchangeable).
 * @class StyleguideReset
 * @extends {React.Component<Props, State>}
 */
class StyleguideReset extends React.Component<{ children: React.Node }, {}> {
  /**
   * Overrides styles when styleguide is loaded.
   * @return {void}
   */
  componentDidMount() {
    if (document && document.body && document.body.style) {
      document.body.style.height = 'initial';
      document.body.style.overflow = 'initial';
      document.body.style.position = 'initial';
    }
    const mains = document.getElementsByClassName('o-main');
    Array.prototype.forEach.call(mains, (e) => { e.style.height = 'initial'; });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return this.props.children;
  }
}

export default StyleguideReset;
