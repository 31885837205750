import React from 'react';
import { List, Map } from 'immutable';

import Table from './../table/table';
import translate from './../../utils/i18n';
import Checkbox from './../inputs/checkbox';
import StatelessModal from './../modals/statelessModal';
import { createSuccessNotification } from './../../utils/notifications';
import DoctorForm from './doctorForm';
import { updateClinicConfig } from './../../utils/db';
import { logDoctorOnDutyChanged, logPractitionerDeleted } from './../../utils/logging';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';
import { compareByAlphabeticalOrder } from './../../utils/comparators';
import { isKlinifyUser } from './../../utils/auth';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';

import type UserConfigModel from '../../models/userConfigModel';
import type PractitionerModel from './../../models/practitionerModel';
import type { SaveModel, Config, User } from './../../types';

const NO_LOCATION_STRING = translate('no_location_set');

type Props = {
  practitioners: List<PractitionerModel>,
  saveModel: SaveModel,
  config: Config,
  updateOnDuty: (update: List<Map<string, string | boolean | void>>) => void,
  user: User,
  userConfigs: List<UserConfigModel>,
  updateConfig: (config: Config) => void,
};

type State = {
  modelBeingEdited?: string,
};

const columns = [
  { accessor: 'name', Header: translate('doctors_name') },
  { accessor: 'account', Header: translate('account'), width: 120 },
  { accessor: 'on_duty', Header: translate('on_duty'), width: 120 },
  {
    accessor: 'edit', Header: '', sortable: false, show: true, width: 100,
  },
  {
    accessor: 'delete', Header: '', sortable: false, width: 100, show: true,
  },
];

/**
 * Called when delete is clicked on an item. Updates its state to be hidden.
 * @param {PractitionerModel} item The item to delete
 * @param {SaveModel} saveModel saveModel function
 * @returns {void}
 */
function onDeleteClicked(item: PractitionerModel, saveModel: SaveModel) {
  if (item) {
    getConfirmation(translate('confirm_doctor_deletion'))
      .then(
        () => {
          saveModel(item.setVisible(false))
            .then(() => {
              createSuccessNotification(translate('doctor_deleted'));
              logPractitionerDeleted();
            });
        },
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {PractitionerModel} item An item for the delete button
 * @param {SaveModel} saveModel saveModel function
 * @param {User} user User.
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(item: PractitionerModel, saveModel: SaveModel, user: User) {
  return (
    <PermissionWrapper permissionsRequired={List([createPermission('doctors', 'delete')])} user={user}>
      <Button
        className="o-text-button o-text-button--danger"
        onClick={() => onDeleteClicked(item, saveModel)}
        dataPublic
      >
        {translate('delete')}
      </Button>
    </PermissionWrapper>
  );
}

/**
 * A table displaying the doctors for the clinic.
 * @class DoctorsTable
 * @extends {React.Component<Props, State>}
 */
class DoctorsTable extends React.Component<Props, State> {
  /**
   * Creates an instance of DoctorsTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { modelBeingEdited: undefined };
  }

  /**
   * Returns a checkbox to mark a doctor as on duty.
   * @param {PractitionerModel} practitioner The Practitioner.
   * @returns {React.Component}
   */
  getOnDutyCheckbox(practitioner: PractitionerModel) {
    const value = this.props.config.getIn(['clinic', 'on_duty'], List())
      .find(v => v.get('practitionerID') === practitioner.get('_id'));
    return (
      <Checkbox
        id={`on_duty_checkbox_${practitioner.get('_id')}`}
        options={[{ label: '', value: 'on_duty' }]}
        label=""
        value={value && value.get('onDuty') ? ['on_duty'] : []}
        inputStyle={{ marginBottom: 0 }}
        onValueChanged={(_, isChecked) => {
          let onDutyValue = this.props.config.getIn(['clinic', 'on_duty'], List());
          const index = onDutyValue.findIndex(v => v.get('practitionerID') === practitioner.get('_id'));
          if (index > -1) {
            onDutyValue = onDutyValue.set(index, onDutyValue.get(index).set('onDuty', isChecked));
          } else {
            onDutyValue = onDutyValue.push(Map({ practitionerID: practitioner.get('_id'), onDuty: isChecked }));
          }
          const configUpdates = Map()
            .setIn(['clinic', 'on_duty'], onDutyValue)
            .toJS();
          updateClinicConfig(this.props.config.toJS(), configUpdates, this.props.updateConfig);
          this.props.updateOnDuty(onDutyValue);
          logDoctorOnDutyChanged();
        }}
      />
    );
  }

  /**
   * Returns a Select input for the user accounts. Uses a standard HTML input as it is
   * nested in React-Table.
   * @param {PractitionerModel} practitioner The PractitionerModel.
   * @returns {React.Component}
   */
  getUserAccountSelect(practitioner: PractitionerModel) {
    const noneString = translate('none');
    const takenAccounts = this.props.practitioners.map(p => p.get('user_id'));
    return (
      <select
        value={practitioner.get('user_id') || noneString}
        onChange={(event: SyntheticInputEvent<*>) => {
          const user = event.target.value === noneString ?
            undefined : event.target.value;
          this.props.saveModel(practitioner.set('user_id', user));
        }}
      >
        <option>{practitioner.get('user_id') || noneString}</option>
        {
          this.props.userConfigs
            .filter(userConfig => userConfig.get('user_id') !== practitioner.get('user_id') &&
              !takenAccounts.includes(userConfig.get('user_id')) &&
              !isKlinifyUser(Map({ id: userConfig.get('user_id') })))
            .sort((a, b) => compareByAlphabeticalOrder(a.get('user_id'), b.get('user_id')))
            .map((userConfig: UserConfigModel) => <option key={userConfig.get('_id')}>{userConfig.get('user_id')}</option>).toArray()
        }
        { (practitioner.has('user_id') && practitioner.get('user_id') !== undefined) &&
          <option>{noneString}</option>
        }
      </select>
    );
  }

  /**
   * Gets the table row for the given Practitioner.
   * @param {PractitionerModel} practitioner A practitioner model.
   * @returns {{}} A React-Table row
   */
  getRow(practitioner: PractitionerModel) {
    return {
      name: practitioner.get('name'),
      account: this.getUserAccountSelect(practitioner),
      on_duty: this.getOnDutyCheckbox(practitioner),
      edit: this.getEditButton(practitioner),
      delete: getDeleteButton(practitioner, this.props.saveModel, this.props.user),
    };
  }

  /**
   * Returns an edit button component for the given item.
   * @param {PractitionerModel} item The item the edit button is for.
   * @returns {React.Component} A edit button.
   */
  getEditButton(item: PractitionerModel) {
    return (
      <StatelessModal
        id={`form-modal-${item.get('_id')}`}
        buttonLabel={translate('view_edit_details')}
        buttonClass="o-text-button o-text-button--contextual"
        title={translate('doctor')}
        className="js-modal"
        visible={this.state.modelBeingEdited === item.get('_id')}
        setVisible={isVisible => this.setState({ modelBeingEdited: isVisible ? item.get('_id') : undefined })}
        explicitCloseOnly
        dataPublicHeader
      >
        <DoctorForm
          config={this.props.config}
          onSave={practitioner =>
            this.props.saveModel(practitioner).then(() => {
              this.setState({ modelBeingEdited: undefined });
              return practitioner;
            })
          }
          onCancel={() => this.setState({ modelBeingEdited: undefined })}
          modelToEdit={item}
          disabled={!hasPermission(this.props.user, List([createPermission('doctors', 'update')]))}
        />
      </StatelessModal>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Table
        columns={columns}
        data={this.props.practitioners.map(p => this.getRow(p)).toArray()}
        noDataText={translate('no_doctors_created')}
        defaultSorted={[{ id: 'name', desc: false }]}
      />
    );
  }
}

export default DoctorsTable;
