import React from 'react';
import { Map } from 'immutable';
import { isEqual } from 'lodash';

import Table from './../table/table';

import { Column, Config, Row } from '../../../src/types';

type Props = {
  rowCount: number,
  columns: Array<Column>,
  data: Array<Row>,
  config: Config,
}
const PatientSearchTable = React.memo((props: Props) => (
  <Table
    columns={props.columns}
    defaultSorted={[props.config.getIn(['patientSearch', 'defaultSorting'], Map({ id: 'case_id', desc: true })).toJS()]}
    data={props.data}
    showPagination
    noDataText=""
  />

), (val1, val2) => isEqual(val1.data, val2.data));

export default PatientSearchTable;