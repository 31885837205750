import React from 'react';
import { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import AllergiesList from './../allergies/allergyList';
import PatientCard from './../patientCard/patientCard';
import PatientModel from './../../models/patientModel';
import PatientPage from './../layout/patientPage';
import ToggleCard from './../layout/toggleCard';
import LatestVitalsContainer from './../../containers/latestVitalsContainer';
import { getReferralQueryString } from './../../utils/router';
import GenericPatientDataListContainer from './../../containers/genericPatientDataListContainer';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Button from './../buttons/button';

import type AllergyModel from './../../models/allergyModel';
import type BillModel from './../../models/billModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type EncounterModel from './../../models/encounterModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type { Config, SaveModel, User } from './../../types';
import type AppointmentModel from '../../models/appointmentModel';
import type DrugModel from './../../models/drugModel';

type Props = {
  bills: List<BillModel>,
  config: Config,
  klinifyConfig: Config,
  coveragePayors: List<CoveragePayorModel>,
  patient?: PatientModel,
  patientStub?: PatientStubModel,
  allergies: List<AllergyModel>,
  encounters: List<EncounterModel>,
  appointments: List<AppointmentModel>,
  user: User,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  drugs: List<DrugModel>,
};
/**
 * Displays PatientCard and DataList components for a given patient.
 * @namespace PatientOverview
 */
class PatientOverview extends React.PureComponent<Props> {
  /**
   * Returns either the patientModel or patientStubModel depending on which is available. An error
   * is thrown if neither exist, though this shouldn't ever occur.
   * @returns {(PatientModel | PatientStubModel)} The patient for this component.
   */
  getPatient(): PatientModel | PatientStubModel {
    if (this.props.patient) {
      return this.props.patient;
    }
    if (this.props.patientStub) {
      return this.props.patientStub;
    }
    throw new Error('No patient model provided.');
  }

  /**
     * Renders a DataList component for each visible section of patient data.
     * @return {string} - HTML markup for the patient data views.
     */
  renderDataViews(): React$Node | Array<void> {
    const patientID = this.getPatient().get('_id');
    const dataItems = [
      { id: 'allergy', show: true },
      { id: 'customNotes', show: this.props.config.get('use_legacy_scheduling', false) },
      { id: 'medicalComplaints', show: this.props.config.get('use_legacy_scheduling', false) },
      { id: 'medication', show: this.props.config.get('use_legacy_scheduling', false) },
    ];
    return dataItems
      .filter(item => item.show)
      .map((item) => {
        switch (item.id) {
          case 'allergy':
            return (
              <PermissionWrapper
                permissionsRequired={List([createPermission('patient_allergies', 'read')])}
                user={this.props.user}
                key="allergyList"
              >
                <AllergiesList
                  patientID={patientID}
                  items={this.props.allergies}
                  user={this.props.user}
                  saveModel={this.props.saveModel}
                />
              </PermissionWrapper>
            );
          case 'customNotes':
            return <GenericPatientDataListContainer type="custom_note" patientID={patientID} key="customNotes" />;
          case 'medicalComplaints':
            return <GenericPatientDataListContainer type="medical_complaint" patientID={patientID} key="medicalComplaints" />;
          case 'medication':
            return <GenericPatientDataListContainer type="medication" patientID={patientID} key="medication" />;
          default:
            return <div />;
        }
      });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const currentEncounter = this.props.encounters.find(encounter => encounter.isCurrent());
    const currentBill = currentEncounter ?
      this.props.bills.find(b => b.get('_id') === currentEncounter.get('bill_id')) : undefined;
    return (
      <PatientPage
        isFromOverviewPage={true}
        patient={this.getPatient()}
        coveragePayors={this.props.coveragePayors}
        allergies={this.props.allergies}
        user={this.props.user}
        showActions
        encounters={this.props.encounters}
        appointments={this.props.appointments}
        hasCurrentConsult={currentEncounter !== undefined}
        saveModel={this.props.saveModel}
        currentBill={currentBill}
        currentEncounter={currentEncounter}
        patientStub={this.props.patientStub}
        config={this.props.config}
        klinifyConfig={this.props.klinifyConfig}
        drugs={this.props.drugs}
      >
        <div className="o-card">
          <div className="o-card__header">
            <h1 data-public className="o-card__title">{translate('patient_profile')}</h1>
            <PermissionWrapper permissionsRequired={List([createPermission('patient', 'update')])} user={this.props.user}>
              {
                this.props.patient &&
                <Button
                  className="u-flex-right u-margin-right--1ws o-button o-button--small u-width-180"
                  onClick={() => { location.hash = `/patient/${this.getPatient().get('_id')}/profile/edit${getReferralQueryString()}`; }}
                  dataPublic
                >
                  {translate('edit_patient_details')}
                </Button>
              }
            </PermissionWrapper>
          </div>
          <PatientCard
            config={this.props.config}
            coveragePayors={this.props.coveragePayors}
            patient={this.props.patient}
            patientStub={this.props.patientStub}
            hasCurrentConsult={currentEncounter !== undefined}
            currentBill={currentBill}
            currentEncounter={currentEncounter}
            showActions
            salesItems={this.props.salesItems}
            allergies={this.props.allergies}
            saveModel={this.props.saveModel}
            user={this.props.user}
          />
        </div>
        <PermissionWrapper permissionsRequired={List([createPermission('patient_metrics', 'read')])} user={this.props.user}>
          <ToggleCard title={translate('latest_vitals')} dataPublic>
            <LatestVitalsContainer patientID={this.getPatient().get('_id')} />
          </ToggleCard>
        </PermissionWrapper>
        {this.renderDataViews()}
      </PatientPage>
    );
  }
}

export default PatientOverview;
