/* eslint-disable max-lines */
import React from 'react';

import { List, Map, RecordInstance } from 'immutable';

import TransactionModel from '../models/transactionModel';
import BillItemModel from '../models/billItemModel';
import { getModelsForBill } from './api';
import { sum } from './utils';
import { BatchStock } from './inventory';
import { logMessage, debugPrint } from './logging';
import type ClaimModel from '../models/claimModel';
import type PaymentModel from '../models/paymentModel';
import type SalesItemModel from '../models/salesItemModel';
import type DrugModel from '../models/drugModel';
import BillModel, { Attributes as BillAttributes } from '../models/billModel';
import type { SaveModels, Model, CountPerSKUAndBatch, BatchStockType } from '../types';
import PaymentTypeModel from '../models/paymentTypeModel';
import CoveragePayorModel from '../models/coveragePayorModel';
import translate from './i18n';
import DiscountChargeModel from '../models/discountChargeModel';

const DEFAULT_PRICE_CHANGE_MESSAGE_KEYS = ['default_panel_price', 'default_cash_price'];

type BatchType = {
  batchId: string,
  index: number,
  batchStockRemaining: number,
}

type TransactionTuple = [Map<string, List<TransactionModel>>, Map<string, List<TransactionModel>>]

type dispensationTransactionsDictType = {
  dispensation?: BillItemModel,
  transactions?: List<TransactionModel>,
}

/**
 * Takes a List of BillItemModels and calculates the total price for all items.
 * @param {List<BillItemModel>} items A List of BillItemModels.
 * @returns {number}
 */
export function calculateBillItemTotal(items: List<BillItemModel>): number {
  let total = 0;
  items.forEach((item) => {
    const price = item.get('price');
    const quantity = item.get('quantity');
    if (price === undefined) {
      total += 0;
    } else if (!isNaN(price) && !isNaN(quantity) && price !== '' && quantity !== '') {
      total += parseFloat(price) * parseFloat(quantity);
    } else if (!isNaN(price) && price !== '') {
      total += parseFloat(price);
    }
  });
  if (!isNaN(total) && total !== '' && total !== null) {
    total = +parseFloat(total).toFixed(3);
  }
  return parseFloat(total);
}

/**
 * Calculates the total discounts and charges.
 * @param {List<BillItemModel>} items previous props
 * @param {List<DiscountChargeModel>} usedDiscountsCharges A List of DiscountChargeModels
 * @returns {number} A formatted price
 */
 export function calculateFullDiscount(items: List<BillItemModel>, usedDiscountsCharges: List<DiscountChargeModel>): number {
  return items.reduce((tot, bItem) => {
    return tot + usedDiscountsCharges.reduce(([subtotal, discount], item) => {
      const amountToAdd = subtotal * (item.get('amount') / 100) * (item.get('method') === 'charge' ? 1 : -1);
      return [subtotal + amountToAdd, discount + amountToAdd];
    }, [calculateBillItemTotal(List([bItem])), 0])[1];
  }, 0);
}

/**
 * Calculates the total needed to be paid by a Coverage Payor of a bill. This function does not
 * account for any claims/payments already made.
 * @param {BillAttributes} billAttributes The attributes of a BillModel (used instead of BillModel
 * for calculating when a BillModel is in the process of being changed).
 * @param {List<BillItemModel>} billItems A List of BillItemModels. Its assumed that they all match
 * the bill passed in BillAttributes.
 * @param {List<DiscountChargeModel>} usedDiscountsCharges A List of DiscountChargeModels
 * @returns {number}
 */
export function calculateCoveragePayorTotal(
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  usedDiscountsCharges?: List<DiscountChargeModel>,
): number {
  const coPayment = billAttributes.co_payment || 0;
  const hasCoveragePayor = billAttributes.coverage_payor_id !== undefined &&
    billAttributes.coverage_payor_id !== null;
  const claimedBillItems = billItems.filter(i => i.isClaimed(hasCoveragePayor));
  const billItemTotal = calculateBillItemTotal(claimedBillItems) + (usedDiscountsCharges && usedDiscountsCharges.size ? calculateFullDiscount(claimedBillItems, usedDiscountsCharges) : 0);
  if (!billItemTotal || coPayment > billItemTotal) {
    return 0;
  }
  return billItemTotal - coPayment;
}

/**
 * Calculates the total needed to be paid by a patient of a bill. This function does not
 * account for any claims/payments already made.
 * @param {BillAttributes} billAttributes The attributes of a BillModel (used instead of BillModel
 * for calculating when a BillModel is in the process of being changed).
 * @param {List<BillItemModel>} billItems A List of BillItemModels.
 * @param {List<DiscountChargeModel>} usedDiscountsCharges A List of DiscountChargeModels
 * @returns {number}
 */
export function calculatePatientTotal(
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  usedDiscountsCharges?: List<DiscountChargeModel>,
): number {
  const coPayment = billAttributes.co_payment || 0;
  const hasCoveragePayor = billAttributes.coverage_payor_id !== null
    && billAttributes.coverage_payor_id !== undefined;
  const nonClaimedBillItems = billItems.filter(i => !i.isClaimed(hasCoveragePayor));
  const nonClaimedItemsTotal = calculateBillItemTotal(nonClaimedBillItems) + (usedDiscountsCharges && usedDiscountsCharges.size ? calculateFullDiscount(nonClaimedBillItems, usedDiscountsCharges) : 0);
  const claimedBillItems = billItems.filter(i => i.isClaimed(hasCoveragePayor));
  const claimedItemsTotal = calculateBillItemTotal(claimedBillItems) + (usedDiscountsCharges && usedDiscountsCharges.size ? calculateFullDiscount(claimedBillItems, usedDiscountsCharges) : 0);
  if (claimedItemsTotal > coPayment || coPayment === 0) {
    return nonClaimedItemsTotal + coPayment;
  }
  return nonClaimedItemsTotal + claimedItemsTotal; // i.e. claimed total is less then co-payment amount, so patient pays all.
}

/**
 * Converts a set of BillItems to reverse Transactions (if they are prescriptions) used later for reverting.
 * @param {List<TransactionModel>} transactionsOriginal The List of transactions done as part of bill, before.
 * @returns {List<TransactionModel>}
 */
export function getReverseTransactions(
  transactionsOriginal: List<TransactionModel>,
): List<TransactionModel> {
  return transactionsOriginal 
    ? List(transactionsOriginal
      .reduce((reduced, transaction) => {
        // keeping everything as in original transaction, except the change
        if (transaction) {
          const { change } = transaction.attributes;
          return reduced.update(
            List([transaction.get('supply_item_id'), transaction.get('source_id'), transaction.get('sku_id')]),
            new TransactionModel(transaction.copyData({ change: 0 })),
            reverseTransaction => new TransactionModel(transaction.copyData({
              change: reverseTransaction.get('change') - change,
            })),
          );
        }
        return reduced;
      }, Map())
      .valueSeq()
      .filter(transactions => transactions.get('change') !== 0))
    : List();
}

/**
 * Gets transactions from dispensations mapped by sku id and divided into
 * reverse transactions to undo or offset previously saved transactions or
 * new transactions required to be saved
 * @param {List<BillItemModel>} dispensations The List of BillItem models.
 * @param {number} billTimestamp The timestamp of the bill.
 * @param {List<TransactionModel>} reverseTransactions list of reverse transactions, to calculate the remaining count while editing.
 * @returns {[Map, Map]} [reverseTransactionsBySku, newTransactionsBySku]
 */
export function getTransactionsByDirectionAndSku(
  dispensations: List<BillItemModel>,
  billTimestamp: number,
  reverseTransactions: List<TransactionModel> = List(),
): TransactionTuple {
  const reverseTransactionsByDispensationId = reverseTransactions
    .groupBy(t => t.get('source_id') + t.get('sku_id'))
    .map(transactions => ({ transactions }));
  const dispensationTransactionsMap: Map<string, dispensationTransactionsDictType> = dispensations
    .reduce((dtmap, dispensation) => (
      dtmap.update((dispensation.get('_id') + dispensation.get('drug_id')), {}, t => ({ ...t, dispensation }))), Map(reverseTransactionsByDispensationId));

  return dispensationTransactionsMap
    .reduce((
      [reverseTransactionsBySku, newTransactionsBySku]: TransactionTuple,
      { dispensation, transactions }: dispensationTransactionsDictType,
    ) => {
      const skuId = dispensation
        ? dispensation.get('drug_id')
        : transactions
          ? transactions.first().get('sku_id')
          : '';
      if (!dispensation) {
        return [reverseTransactionsBySku.update(
          skuId, List(),
          transactionsForSku => transactionsForSku.concat(transactions || List()),
        ), newTransactionsBySku];
      }

      if (!transactions) {
        return [reverseTransactionsBySku, newTransactionsBySku.update(
          skuId, List(),
          transactionsForSku => transactionsForSku.push(new TransactionModel({
            sku_id: dispensation.get('drug_id'),
            change: -Math.abs(dispensation.get('quantity')),
            source_type: 'bill_item',
            timestamp: billTimestamp,
            source_id: dispensation.get('_id'),
          })),
        )];
      }

      const totalChangeFromTransactions = sum(transactions.map(t => t.get('change')));
      const changeToSave = totalChangeFromTransactions - dispensation.get('quantity');

      if (changeToSave < 0) {
        return [reverseTransactionsBySku, newTransactionsBySku.update(
          skuId, List(),
          transactionsForSku => transactionsForSku
            .push(new TransactionModel(transactions.first().copyData({
              change: changeToSave,
            }))),
        )];
      }
      if (changeToSave > 0) {
        return [reverseTransactionsBySku.update(
          skuId, List(),
          transactionsForSku => transactionsForSku.concat(
            transactions.reduce(([reduction, availableTransactions], transaction) => {
              if (reduction <= 0) {
                return [reduction, availableTransactions];
              }
              const nextDef = reduction - transaction.get('change');
              if (nextDef >= 0) {
                return [nextDef, availableTransactions.push(transaction)];
              }
              return ([
                nextDef,
                availableTransactions.push(new TransactionModel(transaction.copyData({
                  change: Math.abs(reduction),
                }))),
              ]);
            }, [changeToSave, List()])[1],
          ),
        ), newTransactionsBySku];
      }
      return [reverseTransactionsBySku, newTransactionsBySku];
    }, [Map(), Map()]);
}

/**
 * Split and Assign batches to the transactions based on the available stock remaining and return them
 * @param {List<TransactionModel>} transactions list of new transactions to save
 * @param {List<BatchType>} availableBatchesForSku list of availabnle batches sorted in the order of dispensing
 * @returns {List<TransactionModel>} List<TransactionModel>
 */
export function assignBatchesToNewTransactions(
  transactions: List<TransactionModel>,
  availableBatchesForSku: List<BatchType>,
) {
  const knownAvailableBatchesForSku = availableBatchesForSku.filter(b => b.batchId !== 'UNKNOWN');
  const [transactionsToSave] = transactions.reduce(([allTransactions, batches], transaction) => {
    let quantityRequired = Math.abs(transaction.get('change'));
    let availableBatches = batches;
    let splitTransactions = List();
    while (quantityRequired && availableBatches && availableBatches.size) {
      const batch = availableBatches.first();
      const balanceQty = quantityRequired - batch.batchStockRemaining;
      if (balanceQty >= 0) {
        splitTransactions = splitTransactions.push(new TransactionModel(transaction.copyData({
          change: -Math.abs(batch.batchStockRemaining),
          supply_item_id: batch.batchId,
        })));
        availableBatches = availableBatches.shift();
        quantityRequired = balanceQty;
      } else {
        splitTransactions = splitTransactions.push(new TransactionModel(transaction.copyData({
          change: -Math.abs(quantityRequired),
          supply_item_id: batch.batchId,
        })));
        availableBatches = availableBatches.setIn([0, 'batchStockRemaining'], Math.abs(balanceQty));
        quantityRequired = 0;
      }
    }
    if (quantityRequired > 0) {
      return ([
        allTransactions.concat(
          splitTransactions.push(new TransactionModel(transaction.copyData({
            change: -Math.abs(quantityRequired),
            supply_item_id: 'UNKNOWN',
          }))),
        ),
        availableBatches,
      ]);
    }
    return [allTransactions.concat(splitTransactions), availableBatches];
  }, [List(), knownAvailableBatchesForSku]);
  return transactionsToSave;
}

/**
 * Converts a set of BillItems to Transactions (if they are prescriptions).
 * @param {List<BillItemModel>} billItems The List of BillItem models.
 * @param {number} billTimestamp The timestamp of the bill.
 * @param {CountPerSKUAndBatch} inventoryCountMap map contaning current inventory status per batch and sku.
 * @param {List<DrugModel>} drugs list of all drugs to get data about a sku
 * @param {List<TransactionModel>} reverseTransactions list of reverse transactions, to calculate the remaining count while editing.
 * @returns {List<TransactionModel>}
 */
export function billItemsToTransactions(
  billItems: List<BillItemModel>,
  billTimestamp: number,
  inventoryCountMap: CountPerSKUAndBatch,
  drugs: List<DrugModel>,
  reverseTransactions?: List<TransactionModel>,
): List<TransactionModel> {
  const dispensations = billItems
    .filter(i => i.isPrescription());
  const [
    reverseTransactionsToSave,
    newTransactions,
  ] = getTransactionsByDirectionAndSku(dispensations, billTimestamp, reverseTransactions);
  const drugsMap = drugs.reduce((reducedDrugs, drug) => reducedDrugs.set(drug.get('_id'), drug), Map());
  const newTransactionsToSave = newTransactions
    .reduce((flattenedTransactionList, transactions, skuId) => {
      const drug = drugsMap.get(skuId);
      const availablebatchesFromInventoryCount = inventoryCountMap.getIn([skuId, 'batches'], Map());
      const reverseTransactionsForSku = reverseTransactionsToSave.get(skuId);
      const availableBatchesForSku = (reverseTransactionsForSku ?
        reverseTransactionsForSku
          .reduce((batches, t) => batches.update(
            t.get('supply_item_id'),
            (batch: RecordInstance<BatchStockType>) => (batch ?
              batch.update('batchStockRemaining', count => count + t.get('change')) :
              BatchStock({
                index: -1,
                batchStockRemaining: t.get('change'),
              })),
          ), availablebatchesFromInventoryCount) :
        availablebatchesFromInventoryCount)
        .reduce((batches, batch, batchId) => {
          if (batch.get('batchStockRemaining') > 0) {
            if (drug && drug.get('default_dispensing_batch') && batchId === drug.get('default_dispensing_batch')) {
              return batches.push({
                batchId,
                index: -2,
                batchStockRemaining: batch.get('batchStockRemaining'),
              });
            }
            return batches.push({
              batchId,
              index: batch.get('index'),
              batchStockRemaining: batch.get('batchStockRemaining'),
            });
          }
          return batches;
        }, List())
        .sortBy(batch => batch.index);

      return flattenedTransactionList.concat(assignBatchesToNewTransactions(
        transactions,
        availableBatchesForSku,
      ));
    }, List());
  return newTransactionsToSave.concat(reverseTransactionsToSave.valueSeq().flatten(true));
}

/**
 * Handle the model updates needed to void a bill. The returned models will be the result of
 * SaveModels.
 * @param {BillModel} bill The bill to be voided.
 * @param {SaveModels} saveModels The SaveModels function.
 * @param {boolean} voidPayments If true the payments associated with this bill will also be voided.
 * @param {List<TransactionModel>} reverseTransactions list of reverse transactions
 * @returns {Promise<Array<Model>>}
 */
export function voidBillWithTransactions(
  bill: BillModel,
  saveModels: SaveModels,
  voidPayments: boolean,
  reverseTransactions: List<TransactionModel>,
): Promise<Array<Model>> {
  const validTypes = ['bill', 'claim', 'receivable']; // These are the models to mark as voided.
  if (voidPayments) {
    validTypes.push('payment');
  }
  return getModelsForBill(bill.get('_id'))
    .then((models) => {
      const voidedModels = models
        .filter(m => validTypes.includes(m.get('type')))
        .push(bill)
        .map(m => m.set('is_void', true));
      return saveModels(voidedModels.concat(reverseTransactions.map(t => t.set('notes', 'Bill voided'))).toArray());
    }).catch(() => []);
}

/**
 * Converts a SalesItemModel to a BillItemModel. NOTE: bill_id is optional
 * @param {SalesItemModel} salesItem The salesItem ID
 * @param {number} quantity Quantity of sales item
 * @param {string} patientID The patientID.
 * @param {string} billID The Bill ID. This is optional but you will need to add it later if not
 * provided.
 * @returns {BillItemModel}
 */
export function salesItemToBillItem(
  salesItem: SalesItemModel,
  quantity: number,
  patientID: string,
  billID?: string,
): BillItemModel {
  return new BillItemModel({
    patient_id: patientID,
    bill_id: billID,
    sales_item_id: salesItem.get('_id'),
    price: salesItem.getPrice(),
    cost_price: salesItem.get('cost_price', 0),
    total_amount: salesItem.getPrice() * quantity,
    quantity,
  });
}

/**
 * Calculates the sum total of a set of Payments. If they have no amount set it's assumed to be 0.
 * @param {List<PaymentModel>} payments A list of PaymentModels
 * @returns {number}
 */
export function calculatePaymentsTotal(payments: List<PaymentModel>): number {
  return payments.reduce((total, p) => total + p.get('amount', 0), 0);
}

/**
 * Calculates the sum total of a set of Receivables. If they have no amount due set it's assumed to be 0.
 * @param {List<PaymentModel>} receivables A list of ReceivableModels
 * @returns {number}
 */
export function calculateReceivablesAmountDueTotal(receivables: List<PaymentModel>): number {
  return receivables.reduce((total, p) => total + p.get('amount_due', 0), 0);
}

/**
 * Calculates the sum total of a set of Claims. If they have no amount set it's assumed to be 0.
 * Voided claims are filtered out.
 * @param {List<ClaimModel>} claims A list of ClaimModels
 * @returns {number}
 */
export function calculateClaimsTotal(claims: List<ClaimModel>): number {
  return claims
    .filter(c => !c.get('is_void'))
    .reduce((total, c) => total + c.get('amount', 0), 0);
}

/**
 * Calculates the sum total of a set of Receivables. If they have no amount set it's assumed to be 0.
 * Voided claims are filtered out.
 * @param {List<ClaimModel>} receivables A list of ReceivableModels
 * @returns {number}
 */
export function calculateReceivablesTotal(receivables: List<ClaimModel>): number {
  return receivables
    .filter(c => !c.get('is_void'))
    .reduce((total, c) => total + c.get('amount_due', 0), 0);
}

/**
 * Returns true if bill items are valid.
 * @param {List<BillItemModel>} billItems The BillItem models to check.
 * @returns {boolean}
 */
export function areBillItemsValid(billItems: List<BillItemModel>) {
  return billItems.every(item => item.isValid());
}

/**
 * Returns true if payments are valid.
 * @param {List<PaymentModel>} payments The Payment models to check.
 * @returns {boolean}
 */
export function paymentsAreValid(payments: List<PaymentModel>) {
  return payments.every(item => item.isValid());
}

/**
 * Handle the model updates needed to void a bill. The returned models will be the result of
 * SaveModels.
 * @param {BillModel} bill The bill to be voided.
 * @param {SaveModels} saveModels The SaveModels function.
 * @param {boolean} voidPayments If true the payments associated with this bill will also be voided.
 * @param {List<Model>} models list of bill related docs
 * @param {List<TransactionModel>} reverseTransactions list of reverse transactions
 * @returns {Promise<Array<Model>>}
 */
export function voidBill(
  bill: BillModel,
  saveModels: SaveModels,
  voidPayments: boolean,
  models: List<Model>,
  reverseTransactions: List<TransactionModel>,
): Promise<Array<Model>> {
  const validTypes = ['bill', 'claim', 'receivable']; // These are the models to mark as voided.
  if (voidPayments) {
    validTypes.push('payment');
  }
  const voidedModels = models
    .filter(m => validTypes.includes(m.get('type')))
    .push(bill)
    .map(m => m.set('is_void', true));
  return saveModels(voidedModels.concat(reverseTransactions.map(t => t.set('notes', 'Bill voided'))).toArray());
}


/**
 * Returns the subset of a list of billitems that are unclaimed (i.e. patient needs to pay).
 * @param {List<BillItemModel>} billItems The list of BillItems. It's assumed they are for the same
 * bill.
 * @param {boolean} billHasCoveragePayor If true the bill has a coverage payor specified.
 * @returns {List<BillItemModel>}
 */
export function getUnclaimedBillItems(
  billItems: List<BillItemModel>,
  billHasCoveragePayor: boolean,
): List<BillItemModel> {
  return billHasCoveragePayor ?
    billItems.filter(i => i.get('coverage_payor_id') === null) : // Only unclaimed items are those that have coverage_payor_id explicitly set to null.
    billItems.filter(i => i.get('coverage_payor_id', null) === null); // Filters any items with explicit coverage_payors.
}

/**
 * Calculates the amount owed by a patient given the current amount of payments. Note that this
 * should only be used if there is no Receivable to use as reference (e.g. in the case of an
 * unfinalised bill).
 * @param {BillAttributes} billAttributes The bill attributes
 * @param {List<BillItemModel>} billItems The current BillItems
 * @param {List<PaymentModel>} payments The current Payments
 * @param {List<DiscountChargeModel>} usedDiscountsCharges A List of DiscountChargeModels
 * @returns {number}
 */
export function getPatientOwedAmount(
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  payments: List<PaymentModel>,
  usedDiscountsCharges?: List<DiscountChargeModel>,
): number {
  return calculatePatientTotal(billAttributes, billItems, usedDiscountsCharges) - calculatePaymentsTotal(payments);
}

/**
 * This will receive a list of payments and adjust their prices so that the sum of non-void payments
 * matches the total. If a payment is void then it is ignored, and if a payment has already been
 * saved once it can not have it's payment amount adjusted. Payment amount can not be adjusted lower
 * than 0, and is possible that a state can be reached where unsaved payments have all been set to 0
 * but the payments total (due to saved payments) is still higher than the requested total, or there
 * are no unsaved payments to adjust but the total has not been matched. At this
 * point the payments should just be returned and the user can make manual adjustments.
 * @param {List<PaymentModel>} payments A List of PaymentModels.
 * @param {number} total The total to match
 * @returns {List<PaymentModel>}
 */
export function adjustPaymentsToMatchTotal(
  payments: List<PaymentModel>,
  total: number,
): List<PaymentModel> {
  let currentPaymentTotal = calculatePaymentsTotal(payments.filter(p => !p.isVoid()));
  return payments.map((payment) => {
    if (payment.isVoid() || payment.hasBeenSaved()) {
      return payment;
    }
    if (currentPaymentTotal > total) {
      const diff = currentPaymentTotal - total;
      if (diff > parseFloat(payment.get('amount'))) {
        currentPaymentTotal -= parseFloat(payment.get('amount'));
        return payment.set('amount', 0);
      }
      currentPaymentTotal -= diff;
      return payment.set('amount', (parseFloat(payment.get('amount')) - diff).toFixed(3));
    } else if (currentPaymentTotal < total) {
      const diff = total - currentPaymentTotal;
      currentPaymentTotal += diff;
      return payment.set('amount', (parseFloat(payment.get('amount')) + diff).toFixed(3));
    }
    return payment;
  });
}

/**
 * Checks if there is any drug dispensed with less than 0 stocks in inventory, or if dispensed more than available.
 * @param {List<BillItemModel>} billItems The current BillItems
 * @param {InventoryCount} inventoryCount The current inventory/stock details
 * @param {List<TransactionModel>} transactions The updated transactions list
 * @returns {boolean}
 */
export function isDispensationOutOfStock(
  billItems: List<BillItemModel>,
  inventoryCount: CountPerSKUAndBatch,
  transactions: List<TransactionModel> | null | undefined,
): boolean {
  const drugsBillItems = billItems.filter(i => !i.get('_deleted', false) && i.isPrescription());
  if (drugsBillItems.size > 0) {
    // since edititng can remove some already added drugs or add some more to them, getting the latest quantity from transactions
    const transactionsPerSKU = (transactions || List()).filter(t => t.get('source_type') === 'bill_item' && t.get('sku_id'))
      .reduce((totalPerSKU, transaction) => {
        const SKU = transaction.get('sku_id');
        return totalPerSKU.set(SKU,
          totalPerSKU.get(SKU) !== undefined ?
            totalPerSKU.get(SKU, 0) + transaction.get('change', 0) :
            transaction.get('change', 0));
      }, new Map());
    return drugsBillItems.some(bi => (
      // if a bill item is removed from the bill, it will be a positive change in transaction,
      // if it is added to bill, the change will be negative. So the prescription count is -1 * change
      // Also we need to validate only for the added bill item not when a dispensed drug is removed from bill after edit
      bi.getItemId() &&
      transactionsPerSKU.get(bi.getItemId()) !== undefined &&
      transactionsPerSKU.get(bi.getItemId(), 0) < 0 &&
      (inventoryCount.getIn([bi.getItemId() || '', 'skuStockRemaining'], 0) <= 0 || // for already negative inventory to avoid more -ve quantity dispensed
      inventoryCount.getIn([bi.getItemId() || '', 'skuStockRemaining'], 0) < transactionsPerSKU.get(bi.getItemId() || '', 0) * -1))); // for dispensation the change appears in negative
  }
  return false;
}

/**
 * Gets the consult type options for a Select input.
 * @param {List<string>} consultTypes Consult types.
 * @param {List<SalesItemModel>} salesItems The sales items models.
 * @returns {SelectOpts}
 */
export function getConsultTypeOptions(
  consultTypes: List<string>,
  salesItems: List<SalesItemModel>,
): SelectOpts {
  return consultTypes.map((value) => {
    const salesItem = salesItems.find(item => item.get('_id') === value);
    const label = salesItem ?
      salesItem.get('name') : `${value} (Consult type not found, please contact Klinify for assistance.)`;
    if (!salesItem) {
      debugPrint(`Couldn't find salesItem of ID ${value} as specified in config.consult_types.`);
      logMessage('SalesItem for consult type not found.', 'error');
    }
    return { value, label };
  })
    .toArray();
}

/**
 * Gets the default payment method
 * @param {List<PaymentTypeModel>} paymentTypes payment types
 * @param {string} method payment method
 * @returns {string}
 */
export function getDefaultPaymentMethod(paymentTypes: List<PaymentTypeModel>, method: string = 'cash') {
  const defaultPaymentType = paymentTypes.find((p: PaymentTypeModel) => p.get('name', '').trim().toLowerCase() === method);
  const firstPaymentType = paymentTypes.first();
  return (defaultPaymentType && defaultPaymentType.get('name')) || (firstPaymentType ? firstPaymentType.get('name') : '');
}

/*
 * Rerturn message to be shown on price update
 * @param {List<DrugModel>} drugs list of drug models
 * @param {List<BillItemModel>} updatedBillItems list fo bill items
 * @param {CoveragePayorModel | void} coveragePayor coverage payor model
 * @returns {React.ElementType}
 */
export function getPanelPriceUpdateMessage(drugs: List<DrugModel>,
  updatedBillItems: List<BillItemModel>, coveragePayor?: CoveragePayorModel) {
  const coveragePayorID = coveragePayor && coveragePayor.get('_id');
  const groupped = updatedBillItems
    .reduce((groupMap: Map<string, List<{name: string, panelPrice: string}>>, b: BillItemModel) => {
      if (b.isPrescription()) {
        const drug = drugs.find(d => d.get('_id') === b.getItemId());
        if (!drug) {
          return groupMap;
        }
        const defaultKey = coveragePayorID ?
          DEFAULT_PRICE_CHANGE_MESSAGE_KEYS[0] : DEFAULT_PRICE_CHANGE_MESSAGE_KEYS[1];
        const keyToUpdate = coveragePayorID &&
          drug?.isPanelExists(coveragePayorID) ? coveragePayorID : defaultKey;
        return groupMap.update(keyToUpdate, (val) => {
          if (val && List.isList(val)) {
            return val.concat({
              name: drug.get('name'),
              panelPrice: b.get('price'),
            });
          }
          return List([{
            name: drug.get('name'),
            panelPrice: b.get('price'),
          }]);
        });
      }
      return groupMap;
    }, Map());
  return (
    <div className="u-margin--standard">
      <p>{translate('price_update_message')}</p>
      {groupped?.map((val: List<{ name: string, panelPrice: string }>, key: string) => {
        const heading = DEFAULT_PRICE_CHANGE_MESSAGE_KEYS.includes(key) ? translate(key)
          : coveragePayor ? `${coveragePayor.get('name')}'s Price` : '';
        return (
          <>
            <span className="u-strong">{`Updated to ${heading || ''}`}</span>
            <br />
            <br />
            {val.map((v: { name: string, panelPrice: string }) =>
              <div className="u-flex-row">
                <div>
                  {v.name}
                </div>
                  &nbsp;
                  -
                  &nbsp;
                <div>
                  {v.panelPrice}
                </div>
              </div>)}
          </>
        );
      }).toList().toArray()}
    </div>
  );
};
