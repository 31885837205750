/* eslint-disable max-lines */
import type { List, Map } from 'immutable';

import PatientStubModel from './models/patientStubModel';

import type { PatientStubAttributes } from './models/patientStubModel';
import type AnalyticsReportModel from './models/analytics/reportModel';
import type AnalyticsSummaryItemModel from './models/analytics/summaryItemModel';
import type AnalyticsQueryReponseModel from './models/analytics/queryResponseModel';
import type BrandResponseModel from './models/pharmaconnect/brandResponseModel';
import type ReadDocumentsModel from './models/pharmaconnect/readDocumentsModel';
import type { Config, InventoryCount, CountPerSKUAndBatch, MapValue, DataView, Model, MissingDocObject, unsyncedAPICallObject } from './types';
import type APIError from './utils/apiError';
import type BaseModel from './models/baseModel';
import type UserConfigModel from './models/userConfigModel';
import type UserGroupModel from './models/userGroupModel';
import type InventoryDrugModel from './models/inventoryDrugModel';
import DetailingResponseModel from './models/pharmaconnect/detailingResponseModel';
import type InventoryMapModel from './models/inventoryMapModel';
import AppointmentModel from './models/appointmentModel';
import type DrugManufacturerModel from './models/drugManufacturerModel';
import type PatientCampaignSetModel from './models/patientCampaignSetModel';

type AddRecentlyViewedCasenote = { type: 'ADD_RECENTLY_VIEWED_CASENOTE', patientID: string, casenoteID: string };
type AddModels = { type: 'ADD_MODELS', models: List<BaseModel> };
type SetAccountsReceivableFilter = { type: 'SET_ACCOUNTS_RECEIVABLE_FILTER', state: Map<string, MapValue> };
type SetInventoryReportsFilter = { type: 'SET_INVENTORY_REPORTS_FILTER', state: Map<string, MapValue> };
type SetOperationsSummaryFilter = { type: 'SET_OPERATIONS_SUMMARY_FILTER', state: Map<string, MapValue> };
type SetSuppliesReceivedFilter = { type: 'SET_SUPPLIES_RECEIVED_FILTER', state: Map<string, MapValue> };
type SetAddClaimInvoiceFilter = { type: 'SET_ADD_CLAIM_INVOICE_FILTER', state: Map<string, MapValue> };
type SetAnalyticsDateRange = {type: 'SET_ANALYTICS_DATE_RANGE', dateRange: string};
type SetAnalyticsKeyMetricsList = {type: 'SET_ANALYTICS_KEY_METRICS_LIST', keyMetrics: List<AnalyticsSummaryItemModel>};
type SetAnalyticsReportList = {type: 'SET_ANALYTICS_REPORT_LIST', reportLinks: List<AnalyticsReportModel>};
type SetAnalyticsReportDetail = {type: 'SET_ANALYTICS_REPORT_DETAIL', reportDetail: AnalyticsReportModel};
type SetAnalyticsQueriesDetail = {type: 'SET_ANALYTICS_QUERIES_DETAIL', queriesDetail: List<AnalyticsQueryReponseModel>};
type SetAppStartupComplete = { type: 'SET_APP_STARTUP_COMPLETE', appStartupComplete: boolean };
type SetAuthStatus = { type: 'SET_AUTH_STATUS', hasAuth: number };
type SetAuthError = { type: 'SET_AUTH_ERROR', payload: string | null };
type SetClaimInvoicesFilter = { type: 'SET_CLAIM_INVOICES_FILTER', state: Map<string, MapValue> };
type SetConnectionStatus = { type: 'SET_CONNECTION_STATUS', isOnline: boolean, serverOffline?: boolean };
type SetOfflinePromptVisible = { type: 'SET_OFFLINE_PROMPT_VISIBLE', showOfflinePrompt: boolean };
type SetConsultReportsFilter = { type: 'SET_CONSULT_REPORTS_FILTER', state: Map<string, MapValue> };
type SetCostReportFilter = { type: 'SET_COST_REPORT_FILTER', state: Map<string, MapValue> };
type SetLabRequestsFilter = { type: 'SET_LAB_REQUESTS_FILTER', state: Map<string, MapValue> };
type SetCurrentDataViews = { type: 'SET_CURRENT_DATA_VIEWS', state: List<DataView> };
type SetCurrentDataViewsModels = { type: 'SET_CURRENT_DATA_VIEWS_MODELS', state: List<BaseModel> };
type SetCurrentDataViewsError = { type: 'SET_CURRENT_DATA_VIEWS_ERROR', response: APIError | null | undefined };
type SetMasterDrugModels = { type: 'SET_MASTER_DRUG_MODELS', models: List<BaseModel | void> };
type SetPrescribedDrugModels = { type: 'SET_PRESCRIBED_DRUG_MODELS', models: List<BaseModel | void> };
type SetDrugDispensationFilter = { type: 'SET_DRUG_DISPENSATION_FILTER', state: Map<string, MapValue> };
type SetExpiryDatesFilter = { type: 'SET_EXPIRY_DATES_FILTER', state: Map<string, MapValue> };
type SetPatientListFilters = { type: 'SET_PATIENT_LIST_FILTERS', state: Map<string, MapValue> };
type SetAppointmentFilters = {type: 'SET_APPOINTMENT_FILTERS', state: Map<string, MapValue>};
type SetInitialLocalDBSyncComplete = { type: 'SET_INITIAL_DB_SYNC_COMPLETE', state: boolean };
type SetBatchInventoryCount = { type: 'SET_INVENTORY_BATCH_COUNT', state: CountPerSKUAndBatch };
type SetInventoryCountSyncStatus ={ type: 'SET_INVENTORY_COUNT_STATUS', status: 'ASC' | 'DESC' | 'SYNC' | 'STOP' };
type SetInventoryCount = { type: 'SET_INVENTORY_COUNT', state: InventoryCount };
type SetIsSKUCountSyncing = { type: 'SET_IS_SKU_COUNT_SYNCING', state: boolean};
type SetIsFetching = { type: 'SET_IS_FETCHING', state: boolean };
type ShowAppointmentRequested = { type: 'SHOW_APPOINTMENT_REQUESTED', model: AppointmentModel | null };
type ShowAppointmentRequestNotificationIcon = { type: 'SHOW_APPOINTMENT_REQUEST_NOTIFICATION_ICON', state: boolean };
type SetIsSyncing = { type: 'SET_IS_SYNCING', isSyncing: boolean };
type SetIsSendingLabOrder = { type: 'SET_IS_SENDING_LAB_ORDER', isSending: boolean };
type SetLastReceivedDBSequence = { type: 'SET_LAST_RECEIVED_DB_SEQUENCE', state: number | null};
type SetPatientSearchQuery = { type: 'SET_PATIENT_SEARCH_QUERY', query: string };
type SetPatientStubs = { type: 'SET_PATIENT_STUBS', state: List<PatientStubModel> };
type SetPaymentHistoryFilter = { type: 'SET_PAYMENT_HISTORY_FILTER', state: Map<string, MapValue> };
type SetUserId = { type: 'SET_USER_ID', userID?: string };
type SetWorkspace = { type: 'SET_WORKSPACE', workspace: string | null };
type SetUserModel = { type: 'SET_USER_MODEL', model?: UserConfigModel };
type SetUserGroupModel = { type: 'SET_USER_GROUP_MODEL', model?: UserGroupModel };
type SetVerfiedDrugsModel = { type: 'SET_VERIFIED_DRUG_MODELS', models: List<InventoryMapModel> };
type UpdateVerfiedDrugsModel = { type: 'UPDATE_VERIFIED_DRUG_MODEL', model: InventoryDrugModel };
type DeleteVerfiedDrugsModel = { type: 'DELETE_VERIFIED_DRUG_MODEL', modelID: string };
type SetDrugSuggestionModel = { type: 'SET_DRUG_SUGGESTION_MODELS', models: List<BaseModel> };
type SetDrugManufacturerModels = { type: 'SET_DRUG_MANUFACTURER_MODELS', models: List<DrugManufacturerModel> };
type DeleteDrugSuggestionModel = { type: 'DELETE_DRUG_SUGGESTION', modelID: string };
type UpdateDrugSuggestionModel = { type: 'UPDATE_DRUG_SUGGESTION', model: BaseModel};
type ResetAllInventoryBatchIndex = { type: 'RESET_ALL_INVENTORY_BATCH_INDEX' };
type ResetInventoryCountSyncStatus = { type: 'RESET_INVENTORY_COUNT_STATUS' };
type UpdateConfig = { type: 'UPDATE_CONFIG', config: Config };
type UpdateConfigValue = { type: 'UPDATE_CONFIG_VALUE', keys: Array<string>, value: MapValue };
type UpdateKlinifyConfig = { type: 'UPDATE_KLINIFY_CONFIG', config: Config };
type SetConfigFetched = { type: 'SET_CONFIG_FETCHED', isFetched: boolean };
type UpdateKlinifyConfigValue = { type: 'UPDATE_KLINIFY_CONFIG_VALUE', keys: Array<string>, value: MapValue };
type UpdateCurrentDataViewsModel = { type: 'UPDATE_CURRENT_DATA_VIEWS_MODEL', model: BaseModel };
type ClearCurrentDataViewsModels = { type: 'CLEAR_CURRENT_DATA_VIEWS_MODELS', models: List<BaseModel>};
type ReplaceCurrentDataViewsModel = { type: 'REPLACE_CURRENT_DATA_VIEWS_MODEL', prevModel: BaseModel, newModel: BaseModel };
type UpdateInventoryItemCount = { type: 'UPDATE_INVENTORY_ITEM_COUNT', skuID: string, change: number };
type UpdateModel = { type: 'UPDATE_MODEL', model: Model };
type UpdateModels = { type: 'UPDATE_MODELS', models: Array<Model> };
type ClearModels = { type: 'CLEAR_MODELS', models: Array<Model>};
type UpdateCampaignSetModels = { type: 'UPDATE_CAMPAIGN_SET_MODELS', models: List<PatientCampaignSetModel> };
type UpdatePatientStub = { type: 'UPDATE_PATIENT_STUB', patientStub: PatientStubModel };
type UpdatePatientsSearch = { type: 'UPDATE_PATIENTS_SEARCH', patientStubAttributes: Array<PatientStubAttributes> };
type UnsyncedAPICallsActionType = 'UPDATE_UNSYNCED_API_CALLS' | 'SET_UNSYNCED_API_CALLS';
type UnsyncedAPICallsAction = {
  type: UnsyncedAPICallsActionType,
  // This action is dispatched in multiple cases, so weak-type is used here.
  calls: Map<string, unsyncedAPICallObject>,
};
type DeleteUnsyncedAPICallsAction = { type: 'DELETE_UNSYNCED_API_CALLS', requestIds: List<string> };
type SetUserAdminStatus = { type: 'SET_IS_ADMIN_USER', isAdmin: boolean };
type DecryptModelState = { type: 'DECRYPT_MODEL_STATE', key: string, iv: string, userId: string };
type EncryptModelState = { type: 'ENCRYPT_MODEL_STATE', key: string, iv: string, userId: string };
type SetEncrypted = { type: 'SET_ENCRYPTED', encrypted: boolean };
type SetIV = { type: 'SET_IV', iv: string };
type SaveInventoryMapping = { type: 'UPDATE_INVENTORY_MAPPING_MODELS', models: Array<Model> }

type SetPharmaConnectBrands = { type: 'SET_PHARMACONNECT_BRAND_DOCUMENTS', brandDocuments: List<BrandResponseModel>}
type SetPharmaConnectReadDocuments = { type: 'SET_PHARMACONNECT_READ_DOCUMENTS', readDocuments: ReadDocumentsModel };
type SetPharmaConnectSmallDetailing = { type: 'SET_PHARMACONNECT_SMALL_DETAILING', detailing: DetailingResponseModel }
type SetPharmaConnectLargeDetailing = { type: 'SET_PHARMACONNECT_LARGE_DETAILING', detailing: DetailingResponseModel }
type SetCampaignSetView = { type: 'SET_CAMPAIGN_SET_VIEW', data: { campaignSets: List<PatientCampaignSetModel>, readCampaignSetMap: Map<string, {lastEditedTime: number, isNew: boolean}> }};
type UpdateUnreadCamapaignSetMap = { type: 'UPDATE_READ_CAMPAIGN_SET_MAP', readCampaignSetMap: Map<string, {lastEditedTime: number, isNew: boolean}> };

type SetDocumentsPendingRegeneration = { type: 'SET_DOCUMENTS_PENDING_REGENERATION', docs: List<MissingDocObject> }
type UnsetDocumentsPendingRegeneration = { type: 'UNSET_DOCUMENTS_PENDING_REGENERATION', docs: List<string> }

type ToggleDebugModeFlag = { type: 'TOGGLE_DEBUG_MODE_FLAG', flag: string }
type SetDebugModeData = {
  type: 'SET_DEBUG_MODE_DATA',
  flag: string,
  saveModelsFn: (models: List<Model>) => Promise<any>,
  models: List<Model>,
}
type UnsetDebugModeData = { type: 'UNSET_DEBUG_MODE_DATA', flag: string }

export type Action =
  | AddModels
  | AddRecentlyViewedCasenote
  | DecryptModelState
  | DeleteVerfiedDrugsModel
  | EncryptModelState
  | SetAccountsReceivableFilter
  | SetOperationsSummaryFilter
  | SetSuppliesReceivedFilter
  | SetAddClaimInvoiceFilter
  | SetAnalyticsDateRange
  | SetAnalyticsKeyMetricsList
  | SetAnalyticsReportList
  | SetAnalyticsReportDetail
  | SetAnalyticsQueriesDetail
  | SetAppStartupComplete
  | SetAuthStatus
  | SetAuthError
  | SetCampaignSetView
  | SetClaimInvoicesFilter
  | SetConfigFetched
  | SetConnectionStatus
  | SetDrugManufacturerModels
  | SetOfflinePromptVisible
  | SetConsultReportsFilter
  | SetCostReportFilter
  | SetLabRequestsFilter
  | SetCurrentDataViews
  | SetCurrentDataViewsModels
  | SetCurrentDataViewsError
  | SetDrugDispensationFilter
  | SetEncrypted
  | SetExpiryDatesFilter
  | SetInitialLocalDBSyncComplete
  | SetBatchInventoryCount
  | SetInventoryCountSyncStatus
  | SetInventoryCount
  | SetInventoryReportsFilter
  | SetIsFetching
  | SetIsSKUCountSyncing
  | SetIsSyncing
  | SetIV
  | SetLastReceivedDBSequence
  | SetPatientSearchQuery
  | SetPatientListFilters
  | SetAppointmentFilters
  | SetPatientStubs
  | SetPaymentHistoryFilter
  | SetUserId
  | SetUserModel
  | SetUserGroupModel
  | ShowAppointmentRequested
  | ShowAppointmentRequestNotificationIcon
  | SetVerfiedDrugsModel
  | ResetAllInventoryBatchIndex
  | ResetInventoryCountSyncStatus
  | UpdateConfig
  | UpdateConfigValue
  | UpdateKlinifyConfig
  | UpdateKlinifyConfigValue
  | UpdateCurrentDataViewsModel
  | ReplaceCurrentDataViewsModel
  | UpdateInventoryItemCount
  | UpdateModel
  | UpdateModels
  | UpdateCampaignSetModels
  | UpdateUnreadCamapaignSetMap
  | UpdatePatientStub
  | UnsyncedAPICallsAction
  | UpdateVerfiedDrugsModel
  | UpdatePatientsSearch
  | DeleteUnsyncedAPICallsAction
  | SetUserAdminStatus
  | SetPrescribedDrugModels
  | SetMasterDrugModels
  | SetDrugSuggestionModel
  | DeleteDrugSuggestionModel
  | UpdateDrugSuggestionModel
  | SetMasterDrugModels
  | SetPharmaConnectBrands
  | SetPharmaConnectReadDocuments
  | SetPharmaConnectSmallDetailing
  | SetPharmaConnectLargeDetailing
  | SetDocumentsPendingRegeneration
  | UnsetDocumentsPendingRegeneration
  | ClearModels
  | ClearCurrentDataViewsModels
  | ToggleDebugModeFlag
  | SetDebugModeData
  | UnsetDebugModeData
  | SetWorkspace

/**
 * Adds new models to state.
 * @param {List<BaseModel>} models An array of new models.
 * @return {Object} Redux action with type 'ADD_MODELS' and an array of new models.
 */
export const addModels = (models: List<BaseModel>): AddModels => ({ type: 'ADD_MODELS', models });

/**
 * Adds a casenote to the recently viewed casenotes map for the given patient.
 * @param {string} patientID The patient ID
 * @param {string} casenoteID The casenote ID
 * @return {Object} Redux action with type 'ADD_RECENTLY_VIEWED_CASENOTE' and the ids.
 */
export const addRecentlyViewedCasenote =
  (patientID: string, casenoteID: string): AddRecentlyViewedCasenote => ({ type: 'ADD_RECENTLY_VIEWED_CASENOTE', patientID, casenoteID });

/**
 * Updates a model in the current data views models list.
 * @param {BaseModel} model The new model.
 * @return {Object} Redux action with type 'UPDATE_CURRENT_DATA_VIEWS_MODEL' and the model to update
 */
export const updateCurrentDataViewsModel = (model: BaseModel): UpdateCurrentDataViewsModel => ({ type: 'UPDATE_CURRENT_DATA_VIEWS_MODEL', model });

/**
 * Clears models in the current data views models list.
 * @param {List<BaseModel>} models The models to clear.
 * @return {Object} Redux action with type 'UPDATE_CURRENT_DATA_VIEWS_MODEL' and the model to update
 */
export const clearCurrentDataViewsModels = (models: List<BaseModel>): ClearCurrentDataViewsModels => ({ type: 'CLEAR_CURRENT_DATA_VIEWS_MODELS', models });

/**
  * Replace a model in the current data views models list.
  * @param {BaseModel} prevModel The previous model.
  * @param {BaseModel} newModel The new model.
  * @return {Object} Redux action with type 'UPDATE_CURRENT_DATA_VIEWS_MODEL' and the model to update
  */
export const replaceCurrentDataViewsModel = (prevModel: BaseModel, newModel: BaseModel): ReplaceCurrentDataViewsModel => ({ type: 'REPLACE_CURRENT_DATA_VIEWS_MODEL', prevModel, newModel });

/**
 * Updates a model.
 * @param {Model} model The new model.
 * @return {Object} Redux action with type 'UPDATE_MODEL' and the model to update.
 */
export const updateModel = (model: Model): UpdateModel => ({ type: 'UPDATE_MODEL', model });

/**
 * Updates an array of models.
 * @param {Array<Model>} models The new models.
 * @return {Object} Redux action with type 'UPDATE_MODELS' and the models to update.
 */
export const updateModels = (models: Array<Model>): UpdateModels => ({ type: 'UPDATE_MODELS', models });

/**
 * Clears an array of models.
 * @param {Array<Model>} models The models to clear.
 * @return {Object} Redux action with type 'CLEAR_MODELS' and the models to drop.
 */
export const clearModels = (models: Array<Model>): ClearModels => ({ type: 'CLEAR_MODELS', models });

/**
 * Updates a PatientStubModel
 * @param {PatientStubModel} patientStub The patientStub to update.
 * @return {Object} Redux action with type 'UPDATE_PATIENT_STUB' and the new model.
 */
export const updatePatientStub = (patientStub: PatientStubModel): UpdatePatientStub => ({ type: 'UPDATE_PATIENT_STUB', patientStub });

/**
   * @param {number} hasAuth Current auth state -1 if fecting 0 if not authorized else 1 if authorized.
   * @return {Object} Redux action.
   */
export const setAuthStatus = (hasAuth: number): SetAuthStatus => ({ type: 'SET_AUTH_STATUS', hasAuth });

/**
   * @param {string | null} payload Current auth state.
   * @return {Object} Redux action.
   */
export const setAuthError = (payload: string | null): SetAuthError => ({ type: 'SET_AUTH_ERROR', payload });

/**
   * @param {Immutable.Map} state New claim invoices filter state.
   * @return {Object} Redux action.
   */
export const setClaimInvoicesFilter = (state: Map<string, MapValue>): SetClaimInvoicesFilter => ({ type: 'SET_CLAIM_INVOICES_FILTER', state });

/**
 * @param {Immutable.Map} state New accounts receivable filter state. Can have two keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setAccountsReceivableFilter =
  (state: Map<string, MapValue>): SetAccountsReceivableFilter => ({ type: 'SET_ACCOUNTS_RECEIVABLE_FILTER', state });

/**
 * @param {Immutable.Map} state Inventory Reports filter state. Can have two keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setInventoryReportsFilter =
  (state: Map<string, MapValue>): SetInventoryReportsFilter => ({ type: 'SET_INVENTORY_REPORTS_FILTER', state });

/**
 * @param {Immutable.Map} state Operation summary filter state. Can have two keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setOperationsSummaryFilter =
  (state: Map<string, MapValue>): SetOperationsSummaryFilter => ({ type: 'SET_OPERATIONS_SUMMARY_FILTER', state });

/**
 * @param {Immutable.Map} state Supplies Received filter state. Can have two keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setSuppliesReceivedFilter =
  (state: Map<string, MapValue>): SetSuppliesReceivedFilter => ({ type: 'SET_SUPPLIES_RECEIVED_FILTER', state });

/**
 * @param {Immutable.Map} state Cost Report filter state. Can have two keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setCostReportFilter =
  (state: Map<string, MapValue>): SetCostReportFilter => ({ type: 'SET_COST_REPORT_FILTER', state });

/**
 * @param {Immutable.Map} state Lab Requests filter state. Can have two keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setLabRequestsFilter =
  (state: Map<string, MapValue>): SetLabRequestsFilter => ({ type: 'SET_LAB_REQUESTS_FILTER', state });

/**
 * @param {Immutable.Map} state New add claim invoicefilter state. Can have two keys (undefined is also
 * acceptable), month and coveragePayorID.
 * @return {Object} Redux action.
 */
export const setAddClaimInvoiceFilter = (state: Map<string, MapValue>): SetAddClaimInvoiceFilter => ({ type: 'SET_ADD_CLAIM_INVOICE_FILTER', state });

/**
 * @param {Immutable.Map} state New consult reports filter state. Can have 4 keys (undefined is also
 * acceptable), filterStartTime, filterEndTime, filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setConsultReportsFilter = (state: Map<string, MapValue>): SetConsultReportsFilter => ({ type: 'SET_CONSULT_REPORTS_FILTER', state });

/**
 * @param {List<Map<string, any>>} state List of current data views.
 * @return {Object} Redux action.
 */
export const setCurrentDataViews = (state: List<DataView>): SetCurrentDataViews => ({ type: 'SET_CURRENT_DATA_VIEWS', state });

/**
 * @param {List<BaseModel>} state List of models for the current data views.
 * @return {Object} Redux action.
 */
export const setCurrentDataViewsModels = (state: List<BaseModel>): SetCurrentDataViewsModels => ({ type: 'SET_CURRENT_DATA_VIEWS_MODELS', state });

/**
 * @param {APIError} response Error for the current data views
 * @return {Object} Redux action.
 */
export const setCurrentDataViewsError = (
  response: APIError | null | undefined,
): SetCurrentDataViewsError => ({ type: 'SET_CURRENT_DATA_VIEWS_ERROR', response });

/**
 * @param {Immutable.Map} state New drug dispensation filter state. Can have 4 keys (undefined is also
 * acceptable), filterStartDate, filterEndDate, tags, drug_id
 * @return {Object} Redux action.
 */
export const setDrugDispensationFilter =
  (state: Map<string, MapValue>): SetDrugDispensationFilter => ({ type: 'SET_DRUG_DISPENSATION_FILTER', state });

/**
 * @param {Immutable.Map} state New drug dispensation filter state. Can have 4 keys (undefined is also
 * acceptable), filterStartDate, filterEndDate, tags, drug_id
 * @return {Object} Redux action.
 */
export const setExpiryDatesFilter = (state: Map<string, MapValue>): SetExpiryDatesFilter => ({ type: 'SET_EXPIRY_DATES_FILTER', state });

/**
 * @param {Immutable.Map} state The current Patient List Filters
 * @return {Object} Redux action.
 */
export const setPatientListFilters = (state: Map<string,
  MapValue>): SetPatientListFilters => ({ type: 'SET_PATIENT_LIST_FILTERS', state });

/**
 * @param {Immutable.Map} state The current Patient List Filters
 * @return {Object} Redux action.
 */
export const setAppointmentFilters = (state: Map<string,
   MapValue>): SetAppointmentFilters => ({ type: 'SET_APPOINTMENT_FILTERS', state});

/**
 * @param {boolean} isSyncing True if app is syncing.
 * @return {Object} Redux action with type 'SET_IS_SYNCING' and the new state.
 */
export const setIsSyncing = (isSyncing: boolean): SetIsSyncing => ({ type: 'SET_IS_SYNCING', isSyncing });

/**
 * @param {boolean} isSending True if sending lab order in progress.
 * @return {Object} Redux action with type 'SET_IS_SENDING_LAB_ORDER' and the new state.
 */
export const setIsSendingLabOrder = (isSending: boolean): SetIsSendingLabOrder => ({ type: 'SET_IS_SENDING_LAB_ORDER', isSending });

/**
 * @param {boolean} state The fetching state of the app.
 * @return {Object} Redux action with type 'SET_IS_FETCHING' and the new state.
 */
export const setIsFetching = (state: boolean): SetIsFetching => ({ type: 'SET_IS_FETCHING', state });

/**
 * @param {AppointmentModel | null} model The fetching state of the app.
 * @return {Object} Redux action with type 'SHOW_APPOINTMENT_REQUESTED' and the new state.
 */
export const showAppointmentRequested = (model: AppointmentModel | null): ShowAppointmentRequested => ({ type: 'SHOW_APPOINTMENT_REQUESTED', model });


/**
 * @param { boolean } state true if notification shown for requested appointment.
 * @return {Object} Redux action with type 'SHOW_APPOINTMENT_REQUEST_NOTIFICATION_ICON' and the new state.
 */
export const showAppointmentRequestNotificationIcon = (state: boolean): ShowAppointmentRequestNotificationIcon => ({ type: 'SHOW_APPOINTMENT_REQUEST_NOTIFICATION_ICON', state });

/**
 * @param {boolean} state The fetching state of the SKU Count.
 * @return {Object} Redux action with type 'SET_IS_SKU_COUNT_SYNCING' and the new state.
 */
export const setIsSKUCountSyncing = (state: boolean): SetIsSKUCountSyncing => ({ type: 'SET_IS_SKU_COUNT_SYNCING', state });

/**
 * @param {boolean | null} state Sets the last sequence received from the DB.
 * @return {Object} Redux action with type 'SET_LAST_RECEIVED_DB_SEQUENCE' and the new state.
 */
export const setLastReceivedDBSequence = (state: number | null): SetLastReceivedDBSequence => ({ type: 'SET_LAST_RECEIVED_DB_SEQUENCE', state });


/**
 * @param {boolean} query The current search query string.
 * @return {Object} Redux action with type 'SET_PATIENT_SEARCH_QUERY' and query state.
 */
export const setPatientSearchQuery = (query: string): SetPatientSearchQuery => ({ type: 'SET_PATIENT_SEARCH_QUERY', query });

/**
 * Sets the PatientStubsModels for the app.
 * @param {List<PatientStubModel>} state A List of all PatientStubs
 * @return {Object} Redux action with type 'SET_PATIENT_STUBS' and the new state.
 */
export const setPatientStubs = (state: List<PatientStubModel>): SetPatientStubs => ({ type: 'SET_PATIENT_STUBS', state });

/**
   * @param {string} userID The User ID.
   * @return {Object} Redux action with type 'SET_USER_ID' and user ID.
   */
export const setUserID = (userID?: string): SetUserId => ({ type: 'SET_USER_ID', userID });

/**
   * @param {string} workspace The workspace, empty string denotes null
   * @return {Object} Redux action with type 'SET_WORKSPACE' and workspace
   * HAS SIDE EFFECT: ALTERS LOCALSTORAGE
   */
export const setWorkspace = (workspace: string): SetWorkspace => {
  if (workspace === '') {
    window.localStorage.removeItem('workspace');
  } else {
    window.localStorage.setItem('workspace', workspace);
  }
  return ({ type: 'SET_WORKSPACE', workspace });
};

/**
 * @param {UserConfigModel} model The UserConfig model for the current user. Can be empty.
 * @return {Object} Redux action with type 'SET_USER_MODEL' and model.
 */
export const setUserModel = (model?: UserConfigModel): SetUserModel => ({ type: 'SET_USER_MODEL', model });

/**
 * @param {UserGroupModel} model The UserGroup model for the current user. Can be empty.
 * @return {Object} Redux action with type 'SET_USER_GROUP_MODEL' and model.
 */
export const setUserGroupModel = (model?: UserGroupModel): SetUserGroupModel => ({ type: 'SET_USER_GROUP_MODEL', model });

/**
 * @param {boolean} appStartupComplete True if app startup is complete.
 * @return {Object} Redux action.
 */
export const setAppStartupComplete = (appStartupComplete: boolean = true): SetAppStartupComplete => ({ type: 'SET_APP_STARTUP_COMPLETE', appStartupComplete });

/**
 * @param {boolean} isOnline Current online state.
 * @param {boolean} serverOffline Server is offline.
 * @return {Object} Redux action.
 */
export const setConnectionStatus = (
  isOnline: boolean,
  serverOffline?: boolean,
): SetConnectionStatus => ({
  type: 'SET_CONNECTION_STATUS',
  isOnline,
  serverOffline,
});

/**
 * @param {boolean} showOfflinePrompt State of prompt to update
 * @return {Object} Redux action.
 */
export const setOfflinePromptVisible = (
  showOfflinePrompt: boolean,
): SetOfflinePromptVisible => ({
  type: 'SET_OFFLINE_PROMPT_VISIBLE',
  showOfflinePrompt,
});

/**
 * @param {Map} batchCounts The inventoryCount Map
 * @return {Object} Redux action.
  */
export const setBatchInventoryCount = (batchCounts: CountPerSKUAndBatch): SetBatchInventoryCount => ({ type: 'SET_INVENTORY_BATCH_COUNT', state: batchCounts });

/**
 * @return {Object} Redux action.
  */
export const resetAllInventoryBatchIndex = (): ResetAllInventoryBatchIndex => ({ type: 'RESET_ALL_INVENTORY_BATCH_INDEX' });

/**
 * @param {string} status The inventoryCount Map
 * @return {Object} Redux action.
  */
export const setInventoryCountSyncStatus = (status: 'ASC' | 'DESC' | 'SYNC' | 'STOP'): SetInventoryCountSyncStatus => ({
  type: 'SET_INVENTORY_COUNT_STATUS',
  status,
});

/**
 * @return {Object} Redux action.
  */
export const resetInventoryCountSyncStatus = (): ResetInventoryCountSyncStatus => ({
  type: 'RESET_INVENTORY_COUNT_STATUS',
});

/**
 * @param {Map} inventoryCount The inventoryCount Map
 * @return {Object} Redux action.
  */
export const setInventoryCount = (inventoryCount: InventoryCount): SetInventoryCount => ({ type: 'SET_INVENTORY_COUNT', state: inventoryCount });

/** Redux action to set new filter value in state
 * @param {Immutable.Map} state New payment history filter state. Can have 2 keys (undefined is also
 * acceptable), filterStartDate and filterEndDate.
 * @return {Object} Redux action.
 */
export const setPaymentHistoryFilter = (state: Map<string, MapValue>): SetPaymentHistoryFilter => ({ type: 'SET_PAYMENT_HISTORY_FILTER', state });

/**
  * @param {Map} config A clinic config object as an ImmutableJS Map.
  * @return {Object} Redux action.
  */
export const updateConfig = (config: Config): UpdateConfig => ({ type: 'UPDATE_CONFIG', config });

/**
  * @param {Map} config A klinift config object as an ImmutableJS Map.
  * @return {Object} Redux action.
  */
 export const updateKlinifyConfig = (config: Config): UpdateKlinifyConfig => ({ type: 'UPDATE_KLINIFY_CONFIG', config });


/**
  * @param {boolean} isFetched Boolean to indicate if Config fetched.
  * @return {Object} Redux action.
  */
export const setConfigFetched = (isFetched: boolean): SetConfigFetched => ({ type: 'SET_CONFIG_FETCHED', isFetched });

/**
  * @param {Array<string>} keys An array of keys pointing to the field in config to update.
  * @param {any} value The new value.
  * @returns {UpdateConfigValue} Redux action.
  */
export const updateConfigValue = (keys: Array<string>, value: MapValue): UpdateConfigValue => ({ type: 'UPDATE_CONFIG_VALUE', keys, value });

/**
  * @param {Array<string>} keys An array of keys pointing to the field in klinify config to update.
  * @param {any} value The new value.
  * @returns {UpdateKlinifyConfigValue} Redux action.
  */
 export const updateKlinifyConfigValue = (keys: Array<string>, value: MapValue): UpdateKlinifyConfigValue => ({ type: 'UPDATE_KLINIFY_CONFIG_VALUE', keys, value });

/**
  * @param {string} skuID The SKU ID to update.
  * @param {number} change The change to the SKU.
  * @returns {UpdateInventoryItemCount} Redux action.
  */
export const updateInventoryItemCount = (skuID: string, change: number): UpdateInventoryItemCount => ({ type: 'UPDATE_INVENTORY_ITEM_COUNT', skuID, change });

/**
 * Updateets the UpdatePatientsSearch for the app.
 * @param {Array<PatientStubAttributes>} patientStubAttributes A array of all patients attributs
 * @return {Object} Redux action with type 'SET_PATIENT_STUBS' and the new state.
 */
export const updatePatientsSearch = (patientStubAttributes: Array<PatientStubAttributes>): UpdatePatientsSearch => ({ type: 'UPDATE_PATIENTS_SEARCH', patientStubAttributes });

/**
  * @param {List<Function>} type the Action type.
  * @return {Function} (calls: List<Functions>) => Action.
  */
const unsyncedAPICallsActionFactory = (type: UnsyncedAPICallsActionType) =>
  (calls: Map<string, unsyncedAPICallObject>): UnsyncedAPICallsAction =>
    ({ type, calls });

export const setUnsyncedAPICalls = unsyncedAPICallsActionFactory('SET_UNSYNCED_API_CALLS');
export const updateUnsyncedAPICalls = unsyncedAPICallsActionFactory('UPDATE_UNSYNCED_API_CALLS');

/**
  * @param {List<string>} requestIds list of request ids to delete.
  * @return {Object} Redux action.
  */
export const deleteUnsyncedAPICalls = (requestIds: List<string>): DeleteUnsyncedAPICallsAction =>
  ({ type: 'DELETE_UNSYNCED_API_CALLS', requestIds });


/**
 * @param {boolean} isAdmin True if user is admin.
 * @return {Object} Redux action.
 */
export const setUserAdminStatus = (isAdmin: boolean = true): SetUserAdminStatus => ({ type: 'SET_IS_ADMIN_USER', isAdmin });

/**
 * @param {boolean} encrypted Is the model Encrypted.
 * @return {Object} Redux action.
 */
export const setEncrypted = (encrypted: boolean): SetEncrypted => ({ type: 'SET_ENCRYPTED', encrypted });

/**
 * @param {string} iv The initialization vector to append to the cipher.
 * @return {Object} Redux action.
 */
export const setIV = (iv: string): SetIV => ({ type: 'SET_IV', iv });

/**
 * @param {string} key The key to decrypt.
 * @param {string} iv The salt to decrypt.
 * @param {string} userId The current user Id
 * @return {Object} Redux action.
 */
export const decryptModelState = (key: string, iv: string, userId: string): DecryptModelState => ({ type: 'DECRYPT_MODEL_STATE', key, iv, userId });

/**
 * @param {string} key The key to decrypt.
 * @param {string} iv The salt to decrypt.
 * @param {string} userId The current user Id
 * @return {Object} Redux action.
 */
export const encryptModelState = (key: string, iv: string, userId: string): EncryptModelState =>
  ({ type: 'ENCRYPT_MODEL_STATE', key, iv, userId });

/**
 * @param {string} dateRange A string preset for the date range to be chosen.
 * @return {Object} Redux action.
 */
export const setAnalyticsDateRange = (dateRange: string): SetAnalyticsDateRange =>
  ({ type: 'SET_ANALYTICS_DATE_RANGE', dateRange });

/**
 * @param {List<AnalyticsSummaryItemModel>} keyMetrics A list of keymetrics models to be set.
 * @return {Object} Redux action.
 */
export const setAnalyticsKeyMetricsList =
  (keyMetrics: List<AnalyticsSummaryItemModel>): SetAnalyticsKeyMetricsList =>
    ({ type: 'SET_ANALYTICS_KEY_METRICS_LIST', keyMetrics });

/**
 * @param {List<AnalyticsReportModel>} reportLinks A list of report models to be set.
 * @return {Object} Redux action.
 */
export const setAnalyticsReportList =
  (reportLinks: List<AnalyticsReportModel>): SetAnalyticsReportList =>
    ({ type: 'SET_ANALYTICS_REPORT_LIST', reportLinks });

/**
 * @param {AnalyticsReportModel} reportDetail Report detail model to be set.
 * @return {Object} Redux action.
 */
export const setAnalyticsReportDetail =
  (reportDetail: AnalyticsReportModel): SetAnalyticsReportDetail =>
    ({ type: 'SET_ANALYTICS_REPORT_DETAIL', reportDetail });

/**
 * @param {List<AnalyticsQueryReponseModel>} queriesDetail List of all responses of the cached queries.
 * @return {Object} Redux action.
 */
export const setAnalyticsQueriesDetail =
  (queriesDetail: List<AnalyticsQueryReponseModel>): SetAnalyticsQueriesDetail =>
    ({ type: 'SET_ANALYTICS_QUERIES_DETAIL', queriesDetail });

/**
 * @param {Map} models The inventory master data Mapping
 * @return {Object} Redux action.
  */
export const setMasterDrugModels = (models: List<BaseModel | void>): SetMasterDrugModels => ({ type: 'SET_MASTER_DRUG_MODELS', models });

/**
 * @param {List<BaseModel>} models The prescribed clinic drug models.
 * @return {Object} Redux action.
  */
export const setPrescribedDrugModels = (models: List<BaseModel | void>): SetPrescribedDrugModels => ({ type: 'SET_PRESCRIBED_DRUG_MODELS', models });
/**
 * Updates an array of inventory mapping models.
 * @param {Array<Model>} models The new models.
 * @return {Object} Redux action with type 'UPDATE_MODELS' and the models to update.
 */
export const saveInventoryMapping = (models: Array<Model>): SaveInventoryMapping => ({ type: 'UPDATE_INVENTORY_MAPPING_MODELS', models });

/**
 * @param {List<BrandResponseModel>} brandDocuments List of all responses of the brand documents.
 * @return {Object} Redux action.
 */
export const setPharmaConnectBrandDocuments =
  (brandDocuments: List<BrandResponseModel>): SetPharmaConnectBrands =>
    ({ type: 'SET_PHARMACONNECT_BRAND_DOCUMENTS', brandDocuments });

/**
 * @param {ReadDocumentsModel} readDocuments Documents that are read.
 * @return {Object} Redux action.
 */
export const setPharmaConnectReadDocuments =
(readDocuments: ReadDocumentsModel): SetPharmaConnectReadDocuments =>
  ({ type: 'SET_PHARMACONNECT_READ_DOCUMENTS', readDocuments });

/**
 * @param {DetailingResponseModel} detailing Ad detailing.
 * @return {Object} Redux action.
 */
export const setPharmaConnectSmallDetailing =
  (detailing: DetailingResponseModel): SetPharmaConnectSmallDetailing =>
    ({ type: 'SET_PHARMACONNECT_SMALL_DETAILING', detailing });

/**
 * @param {DetailingResponseModel} detailing Ad detailing.
 * @return {Object} Redux action.
 */
export const setPharmaConnectLargeDetailing =
  (detailing: DetailingResponseModel): SetPharmaConnectLargeDetailing =>
    ({ type: 'SET_PHARMACONNECT_LARGE_DETAILING', detailing });

/**
 * @param {List<BaseModel>} models The prescribed clinic drug models.
 * @return {Object} Redux action.
 */
export const setVerifiedDrugModels = (models: List<InventoryMapModel>): SetVerfiedDrugsModel => ({ type: 'SET_VERIFIED_DRUG_MODELS', models });

/**
 * @param {InventoryDrugModel} model InventoryDrugModel.
 * @return {Object} Redux action.
 */
export const updateVerfiedDrugsModel = (model: InventoryDrugModel): UpdateVerfiedDrugsModel => ({ type: 'UPDATE_VERIFIED_DRUG_MODEL', model });

/**
 * @param {string} modelID id of drug to delete.
 * @return {Object} Redux action.
 */
export const deleteVerifiedDrugModel = (modelID: string): DeleteVerfiedDrugsModel => ({ type: 'DELETE_VERIFIED_DRUG_MODEL', modelID });

/**
 * @param {List<BaseModel>} models The drug suggestion models.
 * @return {Object} Redux action.
 */
export const setDrugSuggestionModels = (models: List<BaseModel | void>): SetDrugSuggestionModel => ({ type: 'SET_DRUG_SUGGESTION_MODELS', models });

/**
 * @param {string} modelID A drug suggestion model.
 * @return {Object} Redux action.
 */
export const deleteDrugSuggestionModel = (modelID: string): DeleteDrugSuggestionModel => ({ type: 'DELETE_DRUG_SUGGESTION', modelID });

/**
 * @param {BaseModel} model A drug suggestion model.
 * @return {Object} Redux action.
 */
export const updateDrugSuggestionModel = (model: BaseModel): UpdateDrugSuggestionModel => ({ type: 'UPDATE_DRUG_SUGGESTION', model });

/**
 * @param {List<DrugManufacturerModel>} models List of drug manufacturer models.
 * @return {List<DrugManufacturerModel>} Redux action.
 */
export const setDrugManufacturerModels = (models: List<DrugManufacturerModel>): SetDrugManufacturerModels => ({ type: 'SET_DRUG_MANUFACTURER_MODELS', models });

/**
 * @param {List<MissingDocObject>} missingDocs A drug suggestion model.
 * @return {Object} Redux action.
 */
export const setDocumentsPendingRegeneration =
  (missingDocs: List<MissingDocObject>): SetDocumentsPendingRegeneration =>
    ({ type: 'SET_DOCUMENTS_PENDING_REGENERATION', docs: missingDocs });

/**
 * @param {List<string>} docIds A drug suggestion model.
 * @return {Object} Redux action.
 */
export const unsetDocumentsPendingRegeneration =
  (docIds: List<string>): UnsetDocumentsPendingRegeneration =>
    ({ type: 'UNSET_DOCUMENTS_PENDING_REGENERATION', docs: docIds });

/**
 * @param {List<PatientCampaignSetModel>} campaignSets List of campaign set models.
 * @param {Map<string, number>} readCampaignSetMap Map of unread campaign set id and last updated time
 * @return {Action} Redux action.
 */
export const setCampaignSetView = (campaignSets: List<PatientCampaignSetModel>, readCampaignSetMap: Map<string, {lastEditedTime: number, isNew: boolean}>): SetCampaignSetView => ({ type: 'SET_CAMPAIGN_SET_VIEW', data: { campaignSets, readCampaignSetMap } });

/**
 * updates/adds to patient campaign sets state.
 * @param {List<Model>} models The new models.
 * @return {Object} Redux action with type 'UPDATE_MODELS' and the models to update.
 */
export const updateCampaignSetModels = (models: List<PatientCampaignSetModel>): UpdateCampaignSetModels => ({ type: 'UPDATE_CAMPAIGN_SET_MODELS', models });

/**
 * Updates readCampaignSetMap
 * @param {Map<string, {lastEditedTime: number, isNew: boolean}>} readCampaignSetMap updated readCampaignSetMap
 * @returns {void}
 */
export const updateUnreadCamapaignSetMap = (
  readCampaignSetMap: Map<string, {lastEditedTime: number, isNew: boolean}>,
): UpdateUnreadCamapaignSetMap => ({ type: 'UPDATE_READ_CAMPAIGN_SET_MAP', readCampaignSetMap });

/**
 * @param {string} flag The debug mode flag to toggle.
 * @return {Object} Redux action.
 */
export const toggleDebugModeFlag = (flag: string): ToggleDebugModeFlag => ({ type: 'TOGGLE_DEBUG_MODE_FLAG', flag });

/**
 * @param {string} flag The debug mode flag to set data fore.
 * @param {function} saveModelsFn The function to call to save the models.
 * @param {List<Model>} models The models to change.
 * @return {Object} Redux action.
 */
export const setDebugModeData = (
  flag: string,
  saveModelsFn: (models: List<Model>) => Promise<any>,
  models: List<Model>,
): SetDebugModeData => ({ type: 'SET_DEBUG_MODE_DATA', flag, saveModelsFn, models });

/**
 * @param {string} flag The debug mode flag to unset data fore.
 * @return {Object} Redux action.
 */
export const unsetDebugModeData = (flag: string): UnsetDebugModeData => ({ type: 'UNSET_DEBUG_MODE_DATA', flag });
