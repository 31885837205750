/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import Drawer from 'react-modern-drawer';
import { debounce, memoize, remove } from 'lodash';
import { List, Map, OrderedMap } from 'immutable';
import glamorous from 'glamorous';
import moment from 'moment';
import EncounterFlowModel from 'src/models/encounterFlowModel';
import PractitionerModel from 'src/models/practitionerModel';
import SalesItemModel from 'src/models/salesItemModel';
import AppointmentModel from '../../models/appointmentModel';
import ContentTransition from '../contentTransition';
import translate from '../../utils/i18n';
import { wsUnit } from '../../utils/css';
import type { AppointmentsFilter, User, MapValue, AppointmentData, AppointmentStatus, BlockOffTime, Config, SaveModel } from '../../types';
import Button from '../buttons/button';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-modern-drawer/dist/index.css';
import 'react-datetime/css/react-datetime.css';
import { createBlockOffTime, deleteBlockOffTime, fetchAppointments, fetchBlockOffTime, scheduleAppointment, updateAppointment } from '../../utils/api';
import PatientStubModel, { PatientStubAttributes } from '../../models/patientStubModel';
import { createErrorNotification, createSuccessNotification } from '../../utils/notifications';
import AddAppointment from './addEditAppointment';
import ViewAppointment from './viewAppointment';
import BlockTime from './blockTime';
import AppointmentCalendar from './appointmentCalendar';
import AppointmentFilters from './appointmentFilters';
import PatientModel from '../../models/patientModel';
import { fetchPatientRelatedDocs } from '../../utils/db';
import AppointmentReports from './appointmentReports';
import { logError, logPatientScheduled } from '../../utils/logging';


type Props = {
    config: Config;
    appointmentsFilter: AppointmentsFilter;
    salesItems: List<SalesItemModel>,
    encounterFlows: List<EncounterFlowModel>;
    encounterFlowMap: OrderedMap<string, EncounterFlowModel>,
    patientStubs: List<PatientStubModel>;
    practitioners: List<PractitionerModel>;
    onFiltersUpdated: (AppointmentFilter) => void;
    saveModel: SaveModel;
};

type State = {
  filters: AppointmentsFilter;
  filteredPatientId: string;
  filteredDoctorId: string;
  calendarEvents: List<CalendarEvent>;
  isDrawerOpen: boolean;
  appointmentModel?: AppointmentModel;
  appointment: AppointmentData & Appointment;
  drawerViewMode: ViewMode;
  drawerComponentType: ViewType;
  blockOffTime: BlockOffTime;
  calendarLoading: boolean;
  isSavingAppointment: boolean;
  isSavingBlockOffTime: boolean;
  isDeletingBlockOffTime: boolean;
  calendarRange: CalendarRange;
  selectedPatientModel: PatientModel;
};

export type ViewType = 'appointment' | 'block_off_time' | 'report'
export type ViewMode = 'create' | 'view' | 'edit';

export type CalendarRange = {
  start: Date,
  end: Date
}

export type CalendarEvent = {
    title: string;
    start: Date;
    end: Date;
    type: ViewType;
    subType: string;
    allDay?: boolean;
    resource?: any;
    appointmentModel?: AppointmentModel;
    appointmentData?: AppointmentData & Appointment;
    selectedPatient?: PatientStubAttributes;
    blockOffTimeData?: BlockOffTime;
};


export type Appointment = {
    practitionerName: string;
    purpose: string;
    nextAppointment: number;
    duration: string;
    flow_id: string;
};

export const FilterCheckBoxes = [
  { value: 'all', label: 'View All', backgroundColor: '#2C2E37' },
  { value: 'appointment', label: 'Doctor Appointment', backgroundColor: '#3756A3' },
  { value: 'block_off_time', label: 'Clinic Block', backgroundColor: '#D6554E' },
  { value: 'leave', label: 'Doctor On Leave', backgroundColor: '#EF9D45' },
  // { value: 'holiday', label: 'Clinic Holiday', backgroundColor: '#46C068' },
  { value: 'other', label: 'Other', backgroundColor: '#53C3E2' },
];

export const FilterContainer = glamorous.div({
  display: 'flex',
  gridTemplateColumns: '1fr 1fr',
});

export const InputForm = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '100%',
});

export const InputFormGroup = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '1.333rem',
});


const allFilterEventTypes = FilterCheckBoxes.map(f => f.value);

const defaultFilters: AppointmentsFilter = {
  filterFromTimeStamp: moment().unix(),
  filterToTimeStamp: moment().add(30, 'days').unix(),
  checkboxValue: '',
  filterEventType: allFilterEventTypes,
};

const defaultAppointment: Appointment & AppointmentData = {
  practitionerName: '',
  practitioner_id: '',
  patient_id: '',
  purpose: '',
  duration: '',
  consult_mode: 'tele_consult',
  start_timestamp: moment().valueOf(),
  end_timestamp: 0,
  notes: '',
  nextAppointment: 0,
  flow_id: '',
  status: 'booked',
};

const defaultBlockOffTime: BlockOffTime = {
  block_main_type: 'clinic',
  block_name: '',
  block_sub_type: '',
  start_timestamp: moment().startOf('day').valueOf(),
  end_timestamp: moment().endOf('day').valueOf(),
  repeat_every_weeks: 0,
  repeat_on_weekdays_raw: [],
  repeat_on_weekdays: [],
  remarks: '',
};

const defaultCalendarRange: CalendarRange = {
  start: moment().startOf('month').valueOf(),
  end: moment().endOf('month').endOf('day').valueOf(),
};

/**
 * Custom format style for select option
 * @param param0
 * @returns {HTMLElement}
 */
export const formatOptionLabel = ({ value, label, extraLabel }) => (
  <FilterContainer>
    <div>{label}</div>
    <div style={{ marginLeft: '2rem', color: '#ccc' }}>{extraLabel}</div>
  </FilterContainer>
);

export const getMemoizePatientDocs = memoize((patientId: string) =>
  fetchPatientRelatedDocs(patientId).then((resp) => {
    if (resp) {
      const appointments = List(resp.filter(r => r instanceof AppointmentModel));
      return {
        patientModel: resp.find(r => r instanceof PatientModel),
        nextAppointment: appointments.filter(a => !a.isPending() && !a.isCancelled() && !a.isPast()).sort(a => a.get('start_timestamp')).first(),
      };
    }
    return null;
  }));

/**
 * A component for selecting the filter for displaying lab requests
 * @class AddEditAppointments
 * @extends {Component}
 */
class Appointments extends React.Component<Props, State> {
  private debouncedGetCalendarEvents: (() => void) = debounce(this.getCalendarData, 800);

  /**
   * Creates an instance of Appointments.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      filters: Object.assign({}, props.appointmentsFilter || defaultFilters),
      filteredPatientId: '',
      filteredDoctorId: '',
      calendarEvents: [],
      isDrawerOpen: false,
      selectedPatientModel: {},
      appointmentModel: {}, // we'll use this to store unchanged appointment and send for update
      appointment: defaultAppointment,
      blockOffTime: defaultBlockOffTime,
      drawerViewMode: 'create',
      calendarLoading: true,
      calendarRange: defaultCalendarRange,
      isSavingAppointment: false,
      isSavingBlockOffTime: false,
      isDeletingBlockOffTime: false,
    };
    this.updateAppointmentState = this.updateAppointmentState.bind(this);
    this.onSubmitAppoinment = this.onSubmitAppoinment.bind(this);
    this.setSelectedEventToAppointment = this.setSelectedEventToAppointment.bind(this);
    this.setCalendarRange = this.setCalendarRange.bind(this);
    this.onUpdateAppoinment = this.onUpdateAppoinment.bind(this);
    this.onHideDrawer = this.onHideDrawer.bind(this);
    this.updateEventFilterType = this.updateEventFilterType.bind(this);
    this.updateBlockOffTimeState = this.updateBlockOffTimeState.bind(this);
    this.onCreateBlockOffTime = this.onCreateBlockOffTime.bind(this);
    this.onDeleteBlockOffTime = this.onDeleteBlockOffTime.bind(this);
  }

  /**
     * Called functions after component fully rendered
     * @returns {void}
     */
  componentDidMount(): void {
    this.getCalendarData();
  }

  /**
     * Get data for calendar events.
     * @returns {void}
     */
  getCalendarData() {
    const { calendarRange } = this.state;
    this.setState({ calendarLoading: true });
    const memoizeKey = JSON.stringify(calendarRange);
    this.getMemoizeCalendarData(memoizeKey, calendarRange).then(([appointmentsResp, blockOffTimesResp]) => {
      if (appointmentsResp.ok && blockOffTimesResp.ok) {
        this.mapDataToCalendarEvents(appointmentsResp.models?.filter(e => e.isActive()), blockOffTimesResp.models);
      } else {
        this.getMemoizeCalendarData.cache.delete(memoizeKey);
        this.setState({ calendarLoading: false });
      }
    }, () => {
      this.getMemoizeCalendarData.cache.delete(memoizeKey);
      this.setState({ calendarLoading: false });
    });
  }

  getMemoizeCalendarData = memoize((memoizeKey:string, calendarRange: CalendarRange) =>
    Promise.all([fetchAppointments(moment(calendarRange.start).startOf('day').valueOf(),
      moment(calendarRange.end).endOf('day').valueOf(), 'booked'), fetchBlockOffTime()]));

  /**
   * A callback from calendar onRangeChange event
   * @param range
   * @returns {void}
   */
  setCalendarRange(range: CalendarEvent) {
    this.setState({ calendarRange: range }, () => this.debouncedGetCalendarEvents());
  }

  /**
   * @returns {void}
   */
  onHideDrawer() {
    this.setState({
      isDrawerOpen: false,
      appointment: defaultAppointment,
      blockOffTime: defaultBlockOffTime,
    });
  }

  /**
   * Set selected calendar event to state
   * @param selectedEvent
   * @returns {void}
   */
  setSelectedEventToAppointment(selectedEvent: CalendarEvent) {
    if (selectedEvent.type === 'appointment') {
      getMemoizePatientDocs(selectedEvent?.selectedPatient?._id).then((docs) => {
        if (docs) {
          this.setState({
            isDrawerOpen: true,
            drawerViewMode: 'view',
            drawerComponentType: selectedEvent.type,
            appointment: selectedEvent?.appointmentData,
            appointmentModel: selectedEvent?.appointmentModel,
            blockOffTime: selectedEvent?.blockOffTimeData,
            selectedPatientModel: docs.patientModel,
          });
        } else getMemoizePatientDocs.cache.delete(selectedEvent?.selectedPatient._id);
      });
    } else {
      this.setState({
        isDrawerOpen: true,
        drawerViewMode: 'edit',
        drawerComponentType: selectedEvent.type,
        blockOffTime: selectedEvent?.blockOffTimeData,
      });
    }
  }


  /**
   * Maps API response to calendar events
   * @param {List<AppointmentModel>} appointmentData appointment response data
   * @param {List<BlockOffTime>} blockOffTimeData block off response data
   * @returns {void}
   */
  mapDataToCalendarEvents(appointmentData: List<AppointmentModel>, blockOffTimeData: List<BlockOffTime>) {
    let calendarEvents = [];
    const { filters, filteredPatientId, filteredDoctorId } = this.state;
    if (appointmentData) {
      appointmentData.map(d => calendarEvents.push(this.getAppointmentEventsFromModel(d)));
      if (filteredPatientId) {
        calendarEvents = calendarEvents.filter(c => c.selectedPatient._id === filteredPatientId);
      }
      if (filteredDoctorId) {
        calendarEvents = calendarEvents.filter(c => c.appointmentData.practitioner_id === filteredDoctorId);
      }
    }
    if (blockOffTimeData) {
      blockOffTimeData.filter((b) => {
        if (filteredDoctorId) {
          if (b.block_main_type === 'doctor' && b.block_name === filteredDoctorId) return true;
          if (b.block_main_type !== 'doctor') return true;
          return false;
        }
        return true;
      }).map(d => calendarEvents.push(this.getBlockOffEventsFromModel(d)));
    }

    if (filters.filterEventType) {
      calendarEvents = calendarEvents.filter(c => filters.filterEventType.includes(c.subType));
    }
    this.setState({ calendarEvents, calendarLoading: false });
  }

  /**
   * Maps each appointment to calendar event
   * @param appointmentModel
   * @returns
   */
  getAppointmentEventsFromModel(appointmentModel: AppointmentModel): CalendarEvent {
    return {
      title: `${moment(appointmentModel.getStartDateTime()).format('hh:mm A')} ${appointmentModel.getPatientName(this.props.patientStubs)}`,
      start: appointmentModel.getStartDateTime(),
      end: appointmentModel.getEndDateTime(),
      type: appointmentModel.get('type'),
      subType: appointmentModel.get('type'),
      selectedPatient: this.mapPatientToAppointment(appointmentModel.get('patient_id')),
      appointmentData: this.mapAppointmentToAppointmentData(appointmentModel),
      appointmentModel,
    };
  }

  /**
   * Maps each block off time to calendar event
   * @param blockOffTime
   * @returns
   */
  getBlockOffEventsFromModel(blockOffTime: BlockOffTime): CalendarEvent {
    return {
      title: this.getBlockOffTimeTitle(blockOffTime),
      start: moment(blockOffTime.start_timestamp).toDate(),
      end: moment(blockOffTime.end_timestamp).toDate(),
      type: blockOffTime.type,
      subType: blockOffTime.block_main_type === 'doctor' ? 'leave' : blockOffTime.block_main_type === 'clinic' ? 'block_off_time' : 'other',
      blockOffTimeData: blockOffTime,
    };
  }

  /**
   *
   * @param {BlockOffTime} blockTime
   * @returns {void}
   */
  getBlockOffTimeTitle(blockTime: BlockOffTime): string {
    switch (blockTime.block_main_type) {
      case 'doctor':
        return `${this.props.practitioners.find(p => p.get('_id') === blockTime.block_name)?.getName() || 'Doctor'} on Leave`;
      case 'clinic':
        return `${moment(blockTime.start_timestamp).format('hh:mm A')} Clinic Block`;
      default:
        return `${blockTime.block_name} ${blockTime.block_main_type}`;
    }
  }

  /**
   * @returns {void}
   */
  mapAppointmentToAppointmentData(appointment: AppointmentModel): Appointment & AppointmentData {
    return {
      practitionerName: appointment.getDoctorName(this.props.practitioners) || '',
      practitioner_id: appointment.get('practitioner_id'),
      patient_id: appointment.get('patient_id'),
      purpose: appointment.getEncounterType(this.props.salesItems, this.props.encounterFlowMap),
      duration: appointment.getDuration().toString(),
      consult_mode: appointment.getConsultMode(),
      flow_id: appointment.get('flow_id'),
      start_timestamp: appointment.get('start_timestamp'),
      end_timestamp: appointment.get('end_timestamp'),
      notes: appointment.get('notes'),
      nextAppointment: 0,
      type: appointment.get('type'),
    };
  }

  /**
   *
   * @param patientId
   * @returns {PatientStubAttributes}
   */
  mapPatientToAppointment(patientId: string): PatientStubAttributes {
    const patient = this.props.patientStubs.find(e => e.get('_id') === patientId);
    if (patient) {
      return {
        _id: patient.get('_id'),
        patient_name: patient.get('patient_name'),
        ic: patient.get('ic'),
        tel: patient.get('tel'),
        sex: patient.get('sex'),
        email: patient.get('email'),
      };
    }
    return {};
  }

  /**
   * @returns {void}
   */
  onSubmitAppoinment() {
    const { appointment, calendarEvents } = this.state;
    this.setState({ isSavingAppointment: true });
    // calculate end timestamp
    appointment.end_timestamp = moment(appointment.start_timestamp).add(+appointment.duration, 'minutes')
      .second(0).valueOf();
    scheduleAppointment(appointment)
      .then((resp) => {
        if (resp.ok) {
          logPatientScheduled();
          createSuccessNotification('Appointment successfully scheduled');
          if (resp.model) {
            const newAppointment = this.getAppointmentEventsFromModel(resp.model);
            calendarEvents.push(newAppointment);
          }
          this.setState({
            isDrawerOpen: false,
            isSavingAppointment: false,
            appointment: defaultAppointment,
            calendarEvents,
          });
          this.getMemoizeCalendarData.cache?.clear();
          getMemoizePatientDocs.cache.delete(appointment.patient_id);
          return true;
        }
        this.setState({ isSavingAppointment: false });
        createErrorNotification('Appointment could not be scheduled. Please try again later');
        return false;
      });
  }

  /**
   * Calls API to create or update block off time
   * @param {boolean} isNew true = create, false = update
   * @returns {void}
   */
  onCreateBlockOffTime(isNew: boolean) {
    const { blockOffTime, calendarEvents } = this.state;
    this.setState({ isSavingBlockOffTime: true });
    if (blockOffTime.repeat_on_weekdays_raw) blockOffTime.repeat_on_weekdays = blockOffTime.repeat_on_weekdays_raw.map(b => +b.value);
    createBlockOffTime(isNew, blockOffTime).then((resp) => {
      if (resp.ok) {
        createSuccessNotification('Block Off Time successfully scheduled');
        if (resp.model) {
          if (isNew) {
            const newBlockOffTime = this.getBlockOffEventsFromModel(resp.model);
            calendarEvents.push(newBlockOffTime);
          } else {
            remove(calendarEvents, calendarEvents.find(c => c.blockOffTimeData?._id === blockOffTime._id));
            const newBlockOffTime = this.getBlockOffEventsFromModel(blockOffTime);
            calendarEvents.push(newBlockOffTime);
          }
        }
        this.getMemoizeCalendarData.cache?.clear();
        this.setState({
          isDrawerOpen: false,
          isSavingBlockOffTime: false,
          calendarEvents,
          blockOffTime: defaultBlockOffTime,
        });
        return;
      }
      this.setState({ isSavingBlockOffTime: false });
      createErrorNotification('Block Off Time could not be scheduled. Please try again later');
    });
  }

  /**
   * @returns {void}
   */
  onDeleteBlockOffTime() {
    const { blockOffTime, calendarEvents } = this.state;
    this.setState({ isDeletingBlockOffTime: true });
    deleteBlockOffTime(blockOffTime._id).then((resp) => {
      if (resp.ok) {
        createSuccessNotification('Block Off Time successfully deleted');
        remove(calendarEvents, calendarEvents.find(c => c.blockOffTimeData?._id === blockOffTime._id));
        this.setState({
          isDrawerOpen: false,
          isDeletingBlockOffTime: false,
          blockOffTime: defaultBlockOffTime,
          calendarEvents,
        });
      this.getMemoizeCalendarData.cache?.clear();
      return;
      }
      this.setState({ isDeletingBlockOffTime: false });
      createErrorNotification('Block Off Time could not be deleted. Please try again later');
    });
  }

  /**
   *
   * @param {AppointmentStatus} status
   * @returns {void}
   */
  onUpdateAppoinment(status?: AppointmentStatus) {
    const { appointment, appointmentModel, calendarEvents } = this.state;
    if (!appointmentModel) return;
    this.setState({ isSavingAppointment: true });
    if (status) appointment.status = status;
    // calculate end timestamp
    appointment.end_timestamp = moment(appointment.start_timestamp).add(+appointment.duration, 'minutes')
      .second(0).valueOf();
    updateAppointment(appointmentModel, appointment)
      .then((resp) => {
        if (resp.ok) {
          createSuccessNotification('Appointment successfully updated');
          if (resp.model) {
            if (resp.model.get('status') === 'cancelled') {
              remove(calendarEvents, calendarEvents.find(c => c.appointmentModel?.get('_id') === appointmentModel.get('_id')));
            } else if (resp.model.get('status') === 'booked') {
              remove(calendarEvents, calendarEvents.find(c => c.appointmentModel?.get('_id') === appointmentModel.get('_id')));
              const newAppointment = this.getAppointmentEventsFromModel(resp.model);
              calendarEvents.push(newAppointment);
            } else {
              this.getCalendarData();
            }
          }
          this.getMemoizeCalendarData.cache?.clear();
          getMemoizePatientDocs.cache.delete(appointmentModel.get('patient_id'));
          this.setState({
            isDrawerOpen: false,
            isSavingAppointment: false,
            appointment: defaultAppointment,
            calendarEvents,
          });
          return true;
        }
        createErrorNotification('Appointment could not be updated. Please try again later');
        this.setState({ isSavingAppointment: false });
        return false;
      });
  }

  /**
   * @param {string} value
   * @param {boolean} isChecked
   * @returns {void}
   */
  updateEventFilterType(value: string, isChecked: boolean) {
    const { filters } = this.state;
    let newFilterEventType = filters.filterEventType;

    if (value === 'all') {
      if (isChecked) {
        newFilterEventType = allFilterEventTypes;
      } else {
        newFilterEventType = [];
      }
      this.setState({
        filters: Object.assign(this.state.filters, { filterEventType: newFilterEventType }),
      }, () => this.getCalendarData());
      return;
    }

    if (isChecked && newFilterEventType.indexOf(value) < 0) {
      newFilterEventType.push(value);
    } else {
      const index = filters.filterEventType.indexOf(value);
      if (index > -1) {
        newFilterEventType.splice(index, 1);
      }
    }
    this.setState({
      filters: Object.assign(this.state.filters, { filterEventType: newFilterEventType }),
    }, () => this.getCalendarData());
  }

  /**
   * @param {string} key of appointment
   * @param {MapValue} value of state
   * @returns {void}
   */
  updateAppointmentState(key: string, value: MapValue) {
    this.setState({
      appointment: Object.assign(this.state.appointment, { [key]: value }),
    });
  }

  /**
   * @param {any} key
   * @param {MapValue}value
   * @returns {void}
   */
  updateBlockOffTimeState(key: any, value: MapValue) {
    const { blockOffTime } = this.state;
    if (key === 'block_main_type') {
      blockOffTime.block_name = '';
      blockOffTime.block_sub_type = '';
    }
    blockOffTime[key] = value;
    this.setState({
      blockOffTime,
    });
  }

  /**
   * @returns {void}
   */
  render() {
    const {
      drawerViewMode, drawerComponentType, blockOffTime, calendarEvents, calendarLoading, isDrawerOpen, isDeletingBlockOffTime,
      appointment, appointmentModel, filters, isSavingAppointment, isSavingBlockOffTime, filteredPatientId,
      selectedPatientModel, filteredDoctorId,
    } = this.state;


    return (
      <ContentTransition className="o-scrollable-container" id="appointments" style={{ height: '100vh' }}>
        <h1 data-public className="o-title">
          {translate('appointments')}
        </h1>
        <FilterContainer style={{ marginLeft: '1.33rem', marginRight: '1.33rem', backgroundColor: 'white', padding: '1.33rem' }}>
          <div style={{ width: '15vw', height: '75vh', marginRight: '1.33rem' }}>
            <InputFormGroup style={{ padding: '0' }}>
              <Button
                style={{ marginBottom: '0.5rem' }}
                className="o-button o-button--small"
                onClick={() => {
                  this.setState({
                    isDrawerOpen: true,
                    drawerComponentType: 'appointment',
                    drawerViewMode: 'create',
                    appointment: defaultAppointment,
                  });
                }}
                dataPublic
              >
                {translate('new_appointment')}
              </Button>

              <Button
                style={{ marginBottom: '0.5rem', backgroundColor: 'white', color: 'orange', border: '2px solid orange' }}
                className="o-button o-button--small"
                onClick={() => {
                  this.setState({
                    isDrawerOpen: true,
                    drawerComponentType: 'block_off_time',
                    drawerViewMode: 'create',
                    blockOffTime: defaultBlockOffTime,
                  });
                }}
                dataPublic
              >
                {translate('block_time')}
              </Button>

              <Button
                style={{ marginBottom: '1rem', backgroundColor: 'white', color: '#53C3E2', border: '2px solid #53C3E2' }}
                className="o-button o-button--small"
                onClick={() => {
                  this.setState({
                    isDrawerOpen: true,
                    drawerComponentType: 'report',
                    drawerViewMode: 'create',
                  });
                }}
                dataPublic
              >
                Export/Print
              </Button>
              <AppointmentFilters
                patientStubs={this.props.patientStubs}
                filteredPatientId={filteredPatientId}
                filteredDoctorId={filteredDoctorId}
                setFilterPatientId={(patientId: string) => this.setState({ filteredPatientId: patientId }, () => this.getCalendarData())}
                setFilterDoctorId={(doctorId: string) => this.setState({ filteredDoctorId: doctorId }, () => this.getCalendarData())}
                updateEventFilterType={(value: string, isChecked: boolean) => this.updateEventFilterType(value, isChecked)}
                practitioners={this.props.practitioners}
                filters={filters}
              />
            </InputFormGroup>
          </div>
          <AppointmentCalendar
            calendarLoading={calendarLoading}
            calendarEvents={calendarEvents}
            setSelectedEventToAppointment={this.setSelectedEventToAppointment}
            setCalendarRange={this.setCalendarRange}
          />
        </FilterContainer>

        <Drawer style={{ width: '350px', overflowX: 'scroll' }} open={isDrawerOpen} onClose={() => this.onHideDrawer()} direction="right">
          { ((drawerViewMode === 'create' || drawerViewMode === 'edit') && drawerComponentType === 'appointment') && <AddAppointment
            isEditMode={this.state.drawerViewMode === 'edit'}
            encounterFlows={this.props.encounterFlows}
            patientStubs={this.props.patientStubs}
            practitioners={this.props.practitioners}
            config={this.props.config}
            appointment={this.state.appointment}
            selectedPatientModel={selectedPatientModel}
            saveModel={this.props.saveModel}
            updateAppointmentState={this.updateAppointmentState}
            onSubmitAppoinment={this.onSubmitAppoinment}
            onUpdateAppointment={this.onUpdateAppoinment}
            resetAppointmentBooking={this.onHideDrawer}
            isSaving={isSavingAppointment}
          />
            }
          {
            (drawerViewMode === 'view' && drawerComponentType === 'appointment') && <ViewAppointment
              readonly={appointmentModel?.isPast()}
              appointment={appointment}
              appointmentModel={appointmentModel}
              selectedPatientModel={selectedPatientModel}
              isSaving={isSavingAppointment}
              onUpdateAppointment={this.onUpdateAppoinment}
              config={this.props.config}
              setAppointmentMode={mode => this.setState({ drawerViewMode: mode })}
            />
            }
          {
            drawerComponentType === 'block_off_time' && <BlockTime
              viewMode={drawerViewMode}
              practitioners={this.props.practitioners}
              blockOffTime={blockOffTime}
              isSaving={isSavingBlockOffTime}
              isDeleting={isDeletingBlockOffTime}
              updateBlockOffTimeState={this.updateBlockOffTimeState}
              onCreateBlockOffTime={this.onCreateBlockOffTime}
              onDeleteBlockOffTime={this.onDeleteBlockOffTime}
              onCancelBlockOffTime={this.onHideDrawer}
            />
            }
          {
            drawerComponentType === 'report' && <AppointmentReports
              config={this.props.config}
              patientStubs={this.props.patientStubs}
              practitioners={this.props.practitioners}
            />
            }

        </Drawer>
      </ContentTransition>
    );
  }
}

export default Appointments;
