import React, { Fragment } from 'react';

import Input from './../inputs/input';
import translate from './../../utils/i18n';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';
import { createSuccessNotification } from './../../utils/notifications';
import UserGroupModel from './../../models/userGroupModel';
import ModalFooter from './../modals/modalFooter';

type Props = {
  userGroup?: UserGroupModel,
  saveModel: (model: UserGroupModel) => Promise<boolean>,
};

type State = {
  name: string,
  isSaving: boolean,
  errorMessage?: string,
};

/**
 * A form for creating or editing a user group.
 * @class UserGroupForm
 * @extends {React.Component<Props, State>}
 */
class UserGroupForm extends React.Component<Props, State> {
  /**
   * Creates an instance of UserGroupForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      name: props.userGroup ? props.userGroup.get('name', '') : '',
      isSaving: false,
    };
  }

  /**
   * Returns true if form is valid. If not returns false and sets appropriate error message.
   * @returns {Promise<boolean>}
   */
  formIsValid() {
    if (this.state.name.length === 0) {
      this.setState({ errorMessage: translate('field_cant_be_empty') });
      return false;
    }
    return true;
  }

  /**
   * Saves the user group
   * @returns {void}
   */
  onSaveClicked() {
    if (this.formIsValid()) {
      this.setState({ isSaving: true });
      const model = this.props.userGroup || new UserGroupModel({});
      this.props.saveModel(model.set('name', this.state.name))
        .then((wasSuccessful) => {
          if (wasSuccessful) {
            createSuccessNotification(translate('user_group_created'));
          } else {
            this.setState({ errorMessage: translate('user_group_creation_failed'), isSaving: false });
          }
        });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <FormError>
              {this.state.errorMessage}
            </FormError>
          }
          <Input
            id="name-input"
            value={this.state.name}
            onValueChanged={name => this.setState({ name })}
            required
            label={translate('name')}
          />
        </div>
        <ModalFooter>
          <SaveButton
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default UserGroupForm;
