import type { List } from 'immutable';
import BaseModel from './baseModel';

import type CollectedSpecimenModel from './collectedSpecimenModel';
import type ProcedureRequestModel from './procedureRequestModel';
import type SpecimenModel from './specimenModel';
import type ProviderModel from './providerModel';

type Attributes = {
  _id: string,
  type: 'procedure_type',
  internal_clinic_id?: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  procedure_type: 'lab', // Available types to be expanded in the future
  name: string,
  category?: string,
  description?: string,
  provider_id: string,
  discounted_price?: number,
  price: number,
  cost_price?: number,
  promotion_price?: number,
  sub_items: Array<string>,
  specimens: Array<Array<string>>,
  is_promotion?: boolean,
  promotion_end?: number,
  active: boolean,
  hidden: boolean,
  is_editable: boolean,
  starred?: boolean,
  special_instructions?: string,
};

/**
  * ProcedureTypeModel
  * @namespace ProcedureTypeModel
  */
class ProcedureTypeModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'procedure_type';
  }

  /**
   * Returns true if each word of query matches one of the following: name, case id, ic, ph number.
   * @param {string} query The query to search for.
   * @returns {boolean} True if the query was matched, false otherwise.
   */
  matchesQuery(query: string): boolean {
    return query.split(' ').every(queryWord =>
      this.nameMatchesQuery(queryWord) || this.codeMatchesQuery(queryWord));
  }

  /**
   * @param {string} query A search query to match against the procedure type's name
   * @return {boolean} Returns true if query matches procedure type name.
   */
  nameMatchesQuery(query: string): boolean {
    const nameStrings = this.getOrBlank('name').split(' ');
    return nameStrings.some(str => str.toLowerCase().startsWith(query.toLowerCase()));
  }

  /**
   * @param {string} query A search query to match against the procedure type's code
   * @return {boolean} Returns true if query matches procedure type code.
   */
  codeMatchesQuery(query: string): boolean {
    const testCode = this.getTestCode() ? this.getTestCode() : '';
    return testCode.toLowerCase().indexOf(query.toLowerCase()) === 0;
  }

  /**
   * Returns true if item have an active flag.
   * @returns {boolean} True if item is active.
   */
  isActive(): boolean {
    return this.get('active', true);
  }

  /**
   * Sets the procedureType active by the given value.
   * @param {boolean} isActive True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered ProviderModel.
   */
  setActive(isActive: boolean): ProcedureTypeModel {
    return this.set('active', isActive);
  }

  /**
   * Returns true if the procedureType is starred.
   * @returns {boolean}
   */
  isStarred(): boolean {
    return this.getOrFalse('starred');
  }

  /**
   * Toggles the starred status of the procedureType.
   * @returns {void}
   */
  toggleStarred() {
    return this.set('starred', !this.isStarred());
  }

  /**
   * Gets the price of the procedure type
   * signature as DrugModel.
   * @returns {(number | void)}
   */
  getPrice(): number { // eslint-disable-line no-unused-vars
    return this.has('price') ? this.get('price') : 0;
  }

  /**
   * Gets the default quantity of the model.
   * @returns {number}
   */
  getDefaultQuantity(): number {
    return 1;
  }

  /**
   * Returns the procedure type name
   * @returns {string}
   */
  getName() {
    return this.get('name');
  }

  /**
   * Returns the Provider LIS integrations settings and information.
   * @returns {{} | undefined}
   */
  getIntegrations() {
    if (this.get('integrations') &&
      (this.get('integrations')['labs:quantum'] ||
      this.get('integrations')['labs:gribbles'])) {
      return this.get('integrations')['labs:quantum'] || this.get('integrations')['labs:gribbles'];
    }
    return undefined;
  }

  /**
   * Returns Procedure type test_code eg. 125DHVD, P.HEXDIONE
   * @returns {string | undefined}
   */
  getTestCode() {
    if (this.getIntegrations() && this.getIntegrations().test_code) {
      return this.getIntegrations().test_code;
    }
    return undefined;
  }

  /**
   * Returns an array of specimen IDs.
   * @returns {Array<string>}
   */
  getSpecimensIDs() {
    const specimens = this.get('specimens');
    return specimens ? specimens.flat() : [];
  }

  /**
   * Returns a list of specimens that ProcedureType requires.
   * @param {List<SpecimenModel>} specimens List of SpecimenModel.
   * @returns {List<SpecimenModel>}
   */
  getSpecimens(specimens: List<SpecimenModel>) {
    return specimens.filter(model => this.getSpecimensIDs().includes(model.get('_id')));
  }

  /**
   * Returns collected specimens (with their status in it) that requires by the ProcedureType.
   * @param {ProcedureRequestModel} procedureRequest A ProcedureRequestModel.
   * @param {List<CollectedSpecimenModel>} collectedSpecimens List of CollectedSpecimenModel.
   * @returns {List<CollectedSpecimenModel>}
   */
  getCollectedSpecimens(procedureRequest: ProcedureRequestModel,
    collectedSpecimens: List<CollectedSpecimenModel>) {
    return collectedSpecimens.filter(model =>
      procedureRequest.get('_id') === model.get('procedure_request_id') &&
      procedureRequest.get('procedure_type_id') === model.get('procedure_type_id') &&
      procedureRequest.get('patient_id') === model.get('patient_id') &&
      this.getSpecimensIDs().includes(model.get('specimen_id')));
  }

  /**
   * Return the provider of the procedure type.
   * @param {List<ProviderModel>} providers List of ProviderModel
   * @returns {ProviderModel}
   */
  getProvider(providers: List<ProviderModel>) {
    return providers.find(model => model.get('_id') === this.get('provider_id'));
  }

  /**
   * Returns the provider name of the procedure type.
   * @param {List<ProviderModel>} providers List of ProviderModel
   * @returns {string}
   */
  getProviderName(providers: List<ProviderModel>) {
    const provider = this.getProvider(providers);
    return provider ? provider.getName() : '';
  }
}

export default ProcedureTypeModel;
