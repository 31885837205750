import React from 'react';
import type { List } from 'immutable';

import Header from './../header/header';
import translate from './../../utils/i18n';
import TemplateThumbnail from './templateThumbnail';
import SidebarTextLink from './../sidebar/sidebarTextLink';
import SidebarTextLinkWrapper from './../sidebar/sidebarTextLinkWrapper';
import { getReferralQueryString, isReferralMode } from './../../utils/router';
import OfflineComponent from './../offline/offlineComponent';

import type TemplateModel from './../../models/templateModel';
import type TemplateGroupModel from './../../models/templateGroupModel';

type Props = {
  templateGroups: List<TemplateGroupModel>,
  templateGroup?: TemplateGroupModel,
  templates: List<TemplateModel>,
  patientID: string,
  isOnline: boolean,
};

/**
 * A component displaying available templates.
 * @class Templates
 * @extends {React.PureComponent<Props>}
 */
class Templates extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    if (!this.props.isOnline) {
      return (
        <div style={{ width: '100%' }}>
          <Header
            label={translate('templates')}
            backLink={`#patient/${this.props.patientID}`}
            style={{ width: '100%' }}
            isOffline
          />
          <OfflineComponent />
        </div>
      );
    }
    return (
      <div style={{ height: isReferralMode() ? '50vh' : '100vh' }}>
        <Header label={translate('templates')} backLink={`/patient/${this.props.patientID}${getReferralQueryString()}`} style={{ width: '100%' }} />
        <div className="u-flex-row">
          <nav className="c-template-selector__sidebar" style={{ height: isReferralMode() ? 'calc(50vh - 50px)' : 'calc(100vh - 50px)' }}>
            <SidebarTextLinkWrapper>
              <SidebarTextLink
                label={translate('all')}
                url={`/patient/${this.props.patientID}/templates${getReferralQueryString()}`}
              />
              {
                this.props.templateGroups
                  .map(group =>
                    <SidebarTextLink
                      label={group.get('name')}
                      url={`/patient/${this.props.patientID}/templates/${group.get('_id')}${getReferralQueryString()}`}
                    />)
                  .toArray()
              }
            </SidebarTextLinkWrapper>
          </nav>
          <div className="c-template-selector__content" style={{ height: isReferralMode() ? 'calc(50vh - 50px)' : 'calc(100vh - 50px)' }}>
            <div className="o-card u-margin-bottom--6ws" style={{ paddingBottom: '0.5px' }}>
              <Header className="o-card__header">
                <h1 className="o-card__title">
                  {this.props.templateGroup ? this.props.templateGroup.get('name') : translate('all')}
                </h1>
              </Header>
              <div className="c-casenote-grid">
                {this.props.templates.size === 0 && <p>{translate('no_templates_available')}</p>}
                {
                  this.props.templates
                    .map(t => <TemplateThumbnail template={t} patientID={this.props.patientID} key={t.get('_id')} />)
                    .toArray()
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Templates;
