import type { List } from 'immutable';

import BaseModel from './baseModel';
import translate from './../utils/i18n';
import { UNICODE } from './../constants';

import type DrugModel from './drugModel';

type Attributes = {
  _id: string,
  practitioner_id: string,
  type: 'prescription',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  prescribed_dosage?: number,
  prescribed_dosage_unit?: string,
  sale_price?: number,
  cost_price?: number,
  sale_quantity?: number,
  notes?: string,
  reason?: string,
  encounter_id: string,
  drug_id: string,
  dosing_regimen_id: string,
  admin_desc: string,
  dosing_admin_time: string,
  start_date?: string,
};

/**
   * PrescriptionModel
   *
   * @namespace PrescriptionModel
   */
class PrescriptionModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'prescription';
  }

  /**
   * @param {Immutable.List} drugs - A list of possible DrugModels.
   * @return {string} Either the name of the Drug or a dash if the drug couldn't be found.
   */
  getDrug(drugs: List<DrugModel>) {
    const drug = drugs.find(model => this.get('drug_id') === model.get('_id'));
    if (drug && drug.has('name')) {
      return drug.get('name');
    }
    // TODO: Send app insight error when drug not found.
    return UNICODE.EMDASH; // Shouldn't be possible but just in case.
  }

  /**
   * @return {string} - Returns the dosage or a placeholder for this prescription.
   */
  getDosage() {
    const dosage = this.get('prescribed_dosage', UNICODE.EMDASH, false);
    const unit = this.get('prescribed_dosage_unit', translate('units'), false);
    const frequency = this.get('prescribed_frequency', UNICODE.EMDASH, false);
    return `${dosage} / ${unit} / ${frequency}`;
  }

  /**
   * Returns the total price (as a number) or void if it can't be calculated.
   * @returns {(number | void)} The total price of the prescription or undefined.
   */
  getTotalPrice(): number | void {
    if (this.has('sale_price', false) && this.has('sale_quantity', false)) {
      return parseFloat(this.get('sale_price')) * parseFloat(this.get('sale_quantity'));
    }
    return undefined;
  }
}

export default PrescriptionModel;
