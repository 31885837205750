import Polyglot from 'node-polyglot';
import I18N_EN from './../config/i18n/en';

const polyglot = new Polyglot({
  locale: 'en',
  allowMissing: process.env.NODE_ENV && process.env.NODE_ENV === 'test',
});
polyglot.replace(I18N_EN);

/**
   * A convenience function for the translation of strings to the current language.
   * @param {string} string The i18n translation key.
   * @param {object} interpolationArgs An object with arguments for interpolation with the string.
   * @return {string} A translated string.
   */
const translate = (string: string, interpolationArgs: {} = {}): string =>
  polyglot.t(string, interpolationArgs);

export default translate;
