import React from 'react';
import type { List } from 'immutable';

import { compareByAlphabeticalOrder } from './../../utils/comparators';
import { getCoveragePolicyName } from './../../utils/coveragePayors';
import CoveragePayorModel from './../../models/coveragePayorModel';
import translate from './../../utils/i18n';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import Select from './../inputs/select';
import Radio from './../inputs/radio';
import SaveButton from './../buttons/saveButton';
import Header from './../header/header';

import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type { Config, HTMLStyle } from './../../types';

type Props = {
  config: Config,
  coveragePayor: CoveragePayorModel | null | undefined,
  coveragePayorPolicy?: string,
  coveragePayors: List<CoveragePayorModel>,
  isEditable?: boolean,
  onChange: (
    coveragePayorID: string | void,
    policyID: string | void,
    shouldUpdatePatient: boolean,
  ) => void,
  patient: PatientModel | PatientStubModel,
  style: HTMLStyle,
};

type State = {
  isModalVisible: boolean,
  shouldUpdatePatient: string, // 'Yes' | 'No',
  coveragePayorID?: string,
  policyID?: string,
};

/**
 * A card component that states what the given panel is (or none if none given) and gives a button
 * that will display a modal for changing the coverage payor. If changed there is an option to
 * trigger a callback function (e.g. to update a patient's default coverage payor).
 * @param {props} props The props for this component.
 * @returns {React.Component} A CoveragePayorDetails component.
 */
class CoveragePayorDetails extends React.Component<Props, State> {
  static defaultProps = {
    style: {},
  };

  /**
   * Creates an instance of CoveragePayorDetails.
   * @param {Props} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
      shouldUpdatePatient: 'Yes',
      coveragePayorID: props.coveragePayor ? props.coveragePayor.get('_id') : undefined,
      policyID: this.getCoveragePolicyID(props),
    };
  }

  /**
   * Get coveragePolicyID
   * @param {Props} props This component Props
   * @returns {String | void}
   */
  getCoveragePolicyID(props: Props) {
    if (
      props.patient &&
      props.patient.attributes.coverage &&
      props.patient.attributes.coverage[0] &&
      props.patient.attributes.coverage[0].policy
    ) {
      return props.patient.attributes.coverage[0].policy;
    } if (props.coveragePayorPolicy) {
      return props.coveragePayorPolicy;
    }
    return undefined;
  }

  /** Check if props changed
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.coveragePayor !== this.props.coveragePayor) {
      this.setDefaultState();
    }
  }

  /**
   * Sets coverage payor id from props, and makes radio button state as default value
   * @returns {void}
   */
  setDefaultState() {
    this.setState({
      shouldUpdatePatient: 'Yes',
      coveragePayorID: this.props.coveragePayor ? this.props.coveragePayor.get('_id') : undefined,
    });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The CoveragePayorDetails component.
   */
  render() {
    const {
      coveragePayor, coveragePayors, coveragePayorPolicy, onChange, isEditable, style,
    } = this.props;
    const panelName = coveragePayor ? coveragePayor.get('name') :
      this.props.config.getIn(['patient', 'labels', 'coverage', 'no_coverage'], 'No Panel (Cash)');
    const policyName = getCoveragePolicyName(this.props);
    return (
      <div className="o-card o-card--no-shadow" style={style}>
        <div style={{ fontSize: '12px' }}>
          <Header className="o-card__header" dataPublic>
            <h2 className="o-card__title">{translate('panel_policy_name_attached_to_bill')}</h2>
            { isEditable &&
              <StatelessModal
                id="coveragePayorModal"
                buttonClass="o-text-button o-text-button--contextual"
                buttonLabel={translate('edit')}
                onClose={() => this.setState({ coveragePayorID: coveragePayor ? coveragePayor.get('_id') : undefined, policyID: coveragePayorPolicy })}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                style={{ marginLeft: 'auto' }}
                title={translate('edit_panel_attached_to_bill')}
                visible={this.state.isModalVisible}
                explicitCloseOnly
                dataPublicHeader
              >
                <div className="o-form">
                  <Select
                    id="payor"
                    label={translate('panel_name')}
                    value={this.state.coveragePayorID}
                    options={
                      coveragePayors
                        .filter(payor => payor.isVisible() || payor.get('_id') === this.state.coveragePayorID)
                        .sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))
                        .map(payor => ({ value: payor.get('_id'), label: payor.get('name'), disabled: !payor.isVisible() }))
                        .toArray()
                    }
                    onValueChanged={coveragePayorID => this.setState({
                      coveragePayorID: coveragePayorID === '' ? undefined : coveragePayorID,
                      policyID: coveragePayorID && coveragePayors.find(payor => payor.get('_id') === coveragePayorID).attributes.policies ?
                        coveragePayors.find(payor => payor.get('_id') === coveragePayorID).attributes.policies[0]._id : '',
                    })}
                  />
                  <Select
                    id="policy"
                    label={this.props.config.getIn(['patient', 'labels', 'coverage', 'policy'], 'Policy Name')}
                    value={this.state.policyID}
                    options={
                      coveragePayors.find(payor => payor.get('_id') === this.state.coveragePayorID) &&
                      coveragePayors.find(payor => payor.get('_id') === this.state.coveragePayorID).attributes.policies ?
                        coveragePayors.find(payor => payor.get('_id') === this.state.coveragePayorID)
                          .attributes
                          .policies
                          .filter(policy => policy.active)
                          .map(policy => ({ value: policy._id, label: policy.policy_name })) : []
                    }
                    onValueChanged={(selectedOption) => {
                      if ((!selectedOption || selectedOption.length === 0) &&
                        this.state.coveragePayorID) {
                        this.setState({
                          policyID: coveragePayors.find(payor => payor.get('_id') === this.state.coveragePayorID).attributes.policies[0]._id,
                        });
                      } else {
                        this.setState({ policyID: selectedOption });
                      }
                    }}
                    disabled={!this.state.coveragePayorID}
                  />
                  <Radio
                    id="should-update-patient"
                    label={translate('is_this_the_patients_current_policy')}
                    options={[{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]}
                    value={this.state.shouldUpdatePatient}
                    onValueChanged={
                      (shouldUpdatePatient: string) => this.setState({ shouldUpdatePatient })
                    }
                  />
                </div>
                <ModalFooter>
                  <SaveButton
                    dataPublic
                    isSaving={false}
                    onClick={() => {
                      onChange(this.state.coveragePayorID, this.state.policyID, this.state.shouldUpdatePatient === 'Yes');
                      this.setState({ isModalVisible: false });
                    }}
                    className="o-button--small u-margin-right--half-ws"
                    label={translate('save')}
                  />
                </ModalFooter>
              </StatelessModal>
            }
          </Header>
          <p className="o-card__line-item">
            {
              `${panelName} - ${policyName}`
            }
          </p>
        </div>
      </div>
    );
  }
}

export default CoveragePayorDetails;
