/* eslint-disable require-jsdoc */
import React, { createRef } from 'react';
import { is, List, Map, Set } from 'immutable';
import type { RouteComponentProps } from 'react-router-dom';

import translate from '../../utils/i18n';
import StatelessModal from '../modals/statelessModal';
import SMSCampaignForm from './smsCampaignsForm';
import { sortByNumber } from '../../utils/comparators';
import { filterDropDown } from '../../utils/filters';
import { renderMultilineContentWithLink, renderTranslatedValueWithLink, renderWithLink, renderAlignedCellWithLink } from '../../utils/tables';
import { UNICODE } from '../../constants';
import APIError from '../../utils/apiError';
import { getModelMapFromList } from '../../utils/models';
import { isLoading, transformFilteredColumns, transformColumnKeys, getPrependString, getServerName, listToMap } from '../../utils/utils';
import SelectTable from './../table/selectTable';
import { createSuccessNotification, createErrorNotification } from '../../utils/notifications';
import Header from './../header/header';
import Button from '../buttons/button';
import Table from '../table/table';

import TableColumnsSettings from '../table/tableColumnsSettings';
import PatientCampaignModel, { CampaignRuleType } from '../../models/patientCampaignModel';
import SalesItemModel from '../../models/salesItemModel';
import DrugModel from '../../models/drugModel';
import type PractitionerModel from '../../models/practitionerModel';

import type { SaveModel, MapValue, Config, CustomColumn, Model, Row, SaveModels, User } from '../../types';
import { fetchModels } from '../../utils/db';
import { debugPrint } from '../../utils/logging';
import { createPermission, hasPermission } from '../../utils/permissions';
import PermissionWrapper from '../permissions/permissionWrapper';
import type PatientCampaignSetModel from '../../models/patientCampaignSetModel';
import type MasterDrugModel from '../../models/masterDrugModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import { getUpdateConfirmation } from '../../utils/patientCampaign';
import getCampaignDefaultAttr from '../../utils/campaign';
import MasterCampaignModel from '../../models/masterCampaignModel';
import LoadingIndicator from '../loadingIndicator';
import { fetchData } from '../../utils/api';
import { getPatientCampaignDataViews } from '../../dataViews';

type Props = {
    patientCampaigns: Map<string, PatientCampaignModel>,
    isFetching: boolean,
    saveModel: SaveModel,
    currentDataViewsError?: APIError,
    config: Config,
    klinifyConfig: Config,
    drugs: List<DrugModel>,
    salesItems: List<SalesItemModel>,
    practitioners: List<PractitionerModel>,
    updateConfigValue: (keys: Array<string>, value: MapValue) => void,
    updateConfig: (config: Config) => void,
    saveModels: SaveModels,
    user: User,
    selectedCampaignSet: PatientCampaignSetModel,
    masterDrugModelsMap: Map<string, MasterDrugModel>,
    encounterFlows: List<EncounterFlowModel>,
    masterCampaignModels: Map<string, MasterCampaignModel | PatientCampaignModel>
  } & RouteComponentProps<{
      campaignSubType: 'patient' | 'medadvisor',
      campaignSetID: string,
  }>;

type State = {
  modelToEdit: Model | void,
  isModalVisible: boolean,
  columns: Array<CustomColumn>,
  isProcessingAction: boolean,
  selectedRows: Array<string>,
  jobs: Map<string, PatientCampaignModel>
  isLoading: boolean,
  campaigns: List<PatientCampaignModel>,
}
type Status = {
  label: string,
  value: string
};

const statuses = [{ label: translate('active'), value: 'active' }, { label: translate('inactive'), value: 'inactive' }];

const COLUMNS = [
  { accessor: 'name', Header: translate('name'), filterable: true, Cell: renderWithLink, renderFromLink: true },
  { accessor: 'target_patients', Header: translate('target_patients'), Cell: renderWithLink, renderFromLink: true, multilineContent: true, Cell: renderMultilineContentWithLink },
  { accessor: 'ruleSummary', Header: translate('campaign_rules'), multilineContent: true, useSecondaryData: true, Cell: renderMultilineContentWithLink, renderFromLink: true },
  { accessor: 'sent', Header: `# ${translate('sent')}`, sortMethod: sortByNumber, Cell: renderAlignedCellWithLink, align: 'right', headerClassName: 'u-flex-justify-content-right' },
  { accessor: 'queued', Header: `# ${translate('queued')}`, sortMethod: sortByNumber, Cell: renderAlignedCellWithLink, align: 'right', headerClassName: 'u-flex-justify-content-right' },
  {
    accessor: 'status',
    Header: translate('status'),
    filterable: true,
    filterFromOptions: true,
    Cell: renderTranslatedValueWithLink,
    filterMethod: filterDropDown,
    Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%', height: 34 }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all" />
        {
        statuses.map((s: Status) =>
          <option value={s.value}>{s.label}</option>)
      }
      </select>,
    useSecondaryData: true,
    renderFromLink: true,
  },
  {
    accessor: 'action',
    Header: 'Action',
    sortable: false,
    show: true,
    width: 80,
    className: 'sticky-right',
    headerClassName: 'sticky-right',
  },
];

/**
 * Returns an edit button component for the given item.
 * @param {BaseModel} item The item the edit button is for.
 * @param {function} onClick The function to run when edit is clicked.
 * @param {boolean} canEdit True if user has permission to update/delete campaign
 * @returns {React.Component} A edit button.
 */
function getEditButton(item: Model, onClick: (modelToEdit: Model) => void, canEdit: boolean) {
  return (
    <Button
      className="o-text-button o-text-button--contextual"
      onClick={() => onClick(item)}
      dataPublic
    >
      {canEdit ? translate('edit') : translate('view')}
    </Button>
  );
}

/**
 * A SMSCampaigns component.
 * @class SMSCampaigns
 * @extends {React.Component<Props, State>}
 */
class SMSCampaigns extends React.Component<Props, State> {
  originalColumns: List<CustomColumn> = List();

  tableRef;

  /**
   * Creates an instance of SalesItemForm.
   * @param {Props} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.originalColumns = this.originalColumns.concat(transformColumnKeys(COLUMNS));
    this.tableRef = createRef<SelectTable>();
    this.state = {
      modelToEdit: undefined,
      isModalVisible: false,
      columns: this.originalColumns.toArray(),
      isProcessingAction: false,
      jobs: Map(),
      selectedRows: [],
      isLoading: false,
      campaigns: List(),
    };
  }

  componentDidMount() {
    // Fetching campaigns without stats as fallback, because stats api is likely to take more time or cause timeout error
    // TODO: Remove this when API is fixed.
    if (this.props.isFetching) {
      fetchData(getPatientCampaignDataViews(this.props.match.params.campaignSetID, false))
        .then((models: List<PatientCampaignModel | PatientCampaignSetModel>) => {
          this.setState({ campaigns: models.filter(m => m.get('type') === 'patient_campaign' && m.get('campaign_set_id') === this.props.match.params.campaignSetID).flatten(true) });
        });
    }
  }

  /**
   * Calls after component update
   * @param {Props} prevProps prevProps
   * @returns {void}
  */
  componentDidUpdate(prevProps: Props) {
    // Temporary fix as we only do this do get the job name.
    // TODO: Revert and use /campaigns endpoint to fetch all the jobs check, FRON-708
    if (!is(prevProps.patientCampaigns, this.props.patientCampaigns)) {
      const { models: existingModels, filteredIds: idsToFetch } = this.props.patientCampaigns
        .reduce<{ models: List<PatientCampaignModel>, filteredIds: Set<string> }>(
          ({ models, filteredIds }, campaign) => {
            const filter = campaign.get('filter');
            const specificCampaingJobFilter = filter.conditions
              .find(r => r.type === CampaignRuleType.SPECIFIC_MASTER_CAMPAIGN_JOB_TIME ||
                r.type === CampaignRuleType.SPECIFIC_CAMPAIGN_JOB_TIME);
            if (specificCampaingJobFilter && specificCampaingJobFilter.metadata.campaign_id) {
              const id = specificCampaingJobFilter.metadata.campaign_id;
              const model = this.props.patientCampaigns.get(id);
              if (model) {
                return { models: models.concat(model), filteredIds };
              }
              return { models, filteredIds: filteredIds.add(id) };
            }
            if (specificCampaingJobFilter &&
              specificCampaingJobFilter.metadata.master_campaign_id) {
              const id = specificCampaingJobFilter.metadata.master_campaign_id;
              const model = this.props.patientCampaigns.get(id) ||
                this.props.patientCampaigns.get(getPrependString(`mc-${getServerName()}-`, id));
              if (model) {
                return { models: models.concat(model), filteredIds };
              }
              return { models, filteredIds: filteredIds.add(getPrependString(`mc-${getServerName()}-`, id)) };
            }
            return { models, filteredIds };
          }, ({ models: List(), filteredIds: Set() }),
        );
      this.setState({
        isLoading: true,
      });
      fetchModels(idsToFetch.toArray(), true)
        .then((models: List<PatientCampaignModel>) => {
          this.setState({
            jobs: listToMap(models.filter(m => !!m).concat(existingModels), (e: PatientCampaignModel) => e.get('_id')),
            isLoading: false,
          });
        });
    }
  }

  /**
   * Gets the documents to be updated as resolved
   * @param {string} action resolve/cancel
   * @returns {Array<Model>}
  */
  getUpdatedCampaigns(action: string) {
    return this.props.patientCampaigns.filter(c => this.state.selectedRows.includes(c.get('_id')))
      .reduce((data, patientCampaign) => {
        const updatedCampaign = action === 'duplicate' ? new PatientCampaignModel(patientCampaign.copyData({
          ...patientCampaign.attributes,
          is_active: false,
          name: `${patientCampaign.get('name')} - ${translate('copy')}`,
        })) : patientCampaign.set({
          is_active: action === 'activate' ? true : action === 'deactivate' ?
            false : patientCampaign.get('is_active'),
          hidden: action === 'delete' ? true : patientCampaign.get('hidden'),
        });
        return data.push(updatedCampaign);
      }, List()).toArray();
  }

  /**
   * Handles the action
   * @param {string} value The value selected
   * @returns {void}
   */
  handleMenuSelection(value: string) {
    this.setState({ isProcessingAction: true });
    if (value === 'activate' || value === 'deactivate' || value === 'delete' || value === 'duplicate') {
      getUpdateConfirmation(false, value, () => {
        this.props.saveModels(this.getUpdatedCampaigns(value))
          .then(() => {
            this.tableRef.current.resetSelection();
            createSuccessNotification(translate(`campaigns_sucessfully_${value}`));
            this.setState({
              isProcessingAction: false,
              selectedRows: [],
            });
          })
          .catch((error) => {
            this.tableRef.current.resetSelection();
            debugPrint(error, 'error');
            createErrorNotification(translate('something_went_wrong'));
            this.setState({ isProcessingAction: false, selectedRows: [] });
          });
      }, () => {
        this.setState({ isProcessingAction: false });
      });
    } else {
      this.setState({ isProcessingAction: false, selectedRows: [] });
    }
  }

  /**
   * Returns List of Permission record of `sms_campaigns` feature
   * @param {string} access Access required
   * @returns {List<PRecord>}
   */
  getFeaturePermission = (access: 'read' | 'create' | 'update' | 'delete') =>
    List([createPermission('sms_campaigns', access)]);

  /**
   * Generates the secondary data from the PatientCampaignModels
   * @param {boolean | void} disableProspective disable feature temporarly
   * @returns {Row} The rows of the table
   */
  getRows(disableProspective?: boolean): Array<Row> {
    const doctorMap = getModelMapFromList(this.props.practitioners);
    const drugMap = getModelMapFromList(this.props.drugs);
    const salesItemMap = getModelMapFromList(this.props.salesItems);
    const hasEditPermission = hasPermission(this.props.user, this.getFeaturePermission('update'));
    const hasDeletePermission = hasPermission(this.props.user, this.getFeaturePermission('delete'));
    const campaigns = this.props.patientCampaigns?.size > 0 ? this.props.patientCampaigns : this.state.campaigns;
    return campaigns
      .map((pc: PatientCampaignModel) => {
        const {
          target,
          scheduleUnit,
          scheduleValue,
          ...rest
        } = getCampaignDefaultAttr(pc);
        const defaultMessage = translate('who_visit_in_the_future');
        const enrolmentValue = (disableProspective && !rest.enrolmentValue ?
          scheduleValue : rest.enrolmentValue) ?? '';
        const enrolmentUnit = (disableProspective && !rest.enrolmentUnit ?
          scheduleUnit : rest.enrolmentUnit) ?? '';
        const targetPatients = (target === 'prospective' && !disableProspective ? defaultMessage :
          `${defaultMessage} and ${translate('who_have_visited_in_the_past')} ${enrolmentValue} ${enrolmentUnit}(s)`);
        return ({
          _id: pc.get('_id'),
          name: pc.get('name'),
          target_patients: targetPatients,
          ruleSummary: pc.getCampaignRulesString(drugMap, salesItemMap,
            doctorMap, this.props.masterDrugModelsMap,
            this.state.jobs),
          status: pc.getStatus() || UNICODE.EMDASH,
          sent: this.props.isFetching ? translate('loading...') : pc.get(['stats', 'sent'], 0, false, false),
          queued: this.props.isFetching ? translate('loading...') : pc.get(['stats', 'queued'], 0, false, false),
          link: `${this.props.match.url}/${pc.get('_id')}`,
          action: getEditButton(
            pc,
            modelToEdit => this.setState({ modelToEdit, isModalVisible: true }),
            this.props.match.params.campaignSubType !== 'medadvisor' && (hasEditPermission || hasDeletePermission),
          ),
        });
      }).toList().toArray();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { selectedCampaignSet } = this.props;
    const isMedadvisor = this.props.match.params.campaignSubType === 'medadvisor';
    if (!selectedCampaignSet) {
      return (<LoadingIndicator alignCenter />);
    }
    const isPatientTypeCampaign = selectedCampaignSet.get('type') === 'patient_campaign_set'
      && selectedCampaignSet.get('master_campaign_set_type') !== 'MEDADVISOR';
    if (isPatientTypeCampaign === isMedadvisor) {
      return translate('page_not_found');
    }
    const hasUpdateAccess = hasPermission(this.props.user, this.getFeaturePermission('update'));
    const hasCreateAccess = !isMedadvisor && hasPermission(this.props.user, this.getFeaturePermission('create'));
    const isReadOnlyCampaignForm = !hasUpdateAccess || isMedadvisor;
    const disableProspective = true; // set it to false, check https://klinify.atlassian.net/browse/FRON-663
    const TableComponent = isMedadvisor ? Table : SelectTable;
    return (
      <section className="o-scrollable-container" style={{ height: '100vh' }}>
        <Header className="o-header" dataPublic>
          <h1>{this.props.selectedCampaignSet?.get('name')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <StatelessModal
              id="addSmsCampaignModal"
              buttonLabel={translate('add_sms_campaign')}
              buttonClass="o-button o-button--small"
              noButton={isMedadvisor || !hasPermission(this.props.user, this.getFeaturePermission('create'))}
              title={this.state.modelToEdit
                ? !isMedadvisor && hasUpdateAccess
                  ? translate('edit_campaign')
                  : translate('view_campaign')
                : translate('add_new_campaign')}
              className="js-modal"
              visible={this.state.isModalVisible}
              setVisible={isVisible =>
                this.setState({ isModalVisible: isVisible, modelToEdit: undefined })}
              onClose={() => this.setState({ modelToEdit: undefined })}
              explicitCloseOnly
              dataPublicHeader
            >
              <SMSCampaignForm
                saveModel={this.props.saveModel}
                onSaveClicked={() =>
                  this.setState({ isModalVisible: false, modelToEdit: undefined })
                }
                patientCampaign={this.state.modelToEdit}
                disabled={this.props.isFetching || Boolean(this.props.currentDataViewsError)}
                drugs={this.props.drugs}
                salesItems={this.props.salesItems}
                config={this.props.config}
                klinifyConfig={this.props.klinifyConfig}
                practitioners={this.props.practitioners}
                isReadOnly={this.state.modelToEdit ? isReadOnlyCampaignForm : !hasCreateAccess}
                disableProspective={disableProspective}
                user={this.props.user}
                selectedCampaignSet={this.props.selectedCampaignSet}
                masterDrugModelsMap={this.props.masterDrugModelsMap}
                encounterFlows={this.props.encounterFlows}
                masterCampaignModels={this.props.masterCampaignModels}
                patientCampaigns={this.props.patientCampaigns}
                readOnlyError={this.props.match.params.campaignSubType === 'medadvisor'
                  ? translate('medadvisor_campaigns_edit_rule_errormessage')
                  : ''}
                jobs={this.state.jobs}
              />
            </StatelessModal>
          </div>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <div className="u-flex-row o-card__header">
            <Header className="o-card__header">
              <h1 className="o-card__title">{translate('campaigns')}</h1>
            </Header>
            <TableColumnsSettings
              config={this.props.config}
              configFieldName="patient_campaigns"
              updateConfigValue={this.props.updateConfigValue}
              originalColumns={this.originalColumns}
              columns={this.state.columns}
              onUpdateColumns={(columns) => {
                this.setState({ columns });
              }}
              updateConfig={this.props.updateConfig}
            />
          </div>
          {!isMedadvisor && <div className="o-header-actions">
            <div className="u-flex-left u-margin-left--half-ws">
              <PermissionWrapper permissionsRequired={this.getFeaturePermission('update')} user={this.props.user}>
                <>
                  <Button
                    dataPublic
                    className="o-button o-button--small have-background u-margin-left--half-ws"
                    onClick={() => this.handleMenuSelection('activate')}
                    disabled={this.props.isFetching || this.state.isProcessingAction ||
                    this.state.selectedRows.every((r) => {
                      const campaign = this.props.patientCampaigns.find(c => c.get('_id') === r);
                      return !campaign || campaign.get('is_active', false);
                    })}
                  >{ translate('activate') }
                  </Button>
                  <Button
                    dataPublic
                    className="o-button o-button--small have-background u-margin-left--half-ws"
                    onClick={() => this.handleMenuSelection('deactivate')}
                    disabled={this.props.isFetching || this.state.isProcessingAction ||
                    this.state.selectedRows.every((r) => {
                      const campaign = this.props.patientCampaigns.find(c => c.get('_id') === r);
                      return !(campaign && campaign.get('is_active', false));
                    })}
                  >{ translate('deactivate') }
                  </Button>
                </>
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={this.getFeaturePermission('create')} user={this.props.user}>
                <Button
                  dataPublic
                  className="o-button o-button--small have-background u-margin-left--half-ws"
                  onClick={() => this.handleMenuSelection('duplicate')}
                  disabled={this.props.isFetching || this.state.isProcessingAction ||
                  this.state.selectedRows.length === 0}
                >
                  {translate('duplicate')}
                </Button>
              </PermissionWrapper>
              <PermissionWrapper permissionsRequired={this.getFeaturePermission('delete')} user={this.props.user}>
                <button
                  className="o-button o-button--small have-background u-margin-left--half-ws"
                  onClick={() => this.handleMenuSelection('delete')}
                  disabled={this.props.isFetching || this.state.isProcessingAction ||
                  !this.state.selectedRows.length}
                >{ translate('delete') }
                </button>
              </PermissionWrapper>
            </div>
          </div>}
          <TableComponent
            ref={this.tableRef}
            columns={transformFilteredColumns(COLUMNS, this.state.columns)}
            data={this.getRows(disableProspective)}
            noDataText={
              translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(this.props.isFetching, 'no_campaign_found'))
            }
            showPagination
            loading={(!this.props.patientCampaigns.size && !this.state.campaigns.size) || this.state.isLoading}
            defaultSorted={[{ id: 'name', desc: false }]}
            getSelectedRows={value => this.setState({ selectedRows: value })}
          />
        </div>
      </section>
    );
  }
}

export default SMSCampaigns;
