import * as React from 'react';
import type { HOCChild, UnusedReturn } from './../types';

type Props = {
  children: HOCChild,
  className?: string,
  onEnterPressed: () => UnusedReturn,
};

/**
 * A HOC to capture enter keypresses.
 * @param {Props} props The component props.
 * @returns {React.Component}
 */
const Keypress = ({ children, onEnterPressed, className = '' }: Props) =>
  <div
    className={className}
    onKeyPress={(event: SyntheticKeyboardEvent<>) => {
      if (event.charCode === 13) {
        onEnterPressed();
      }
    }}
  >
    {children}
  </div>;

export default Keypress;
