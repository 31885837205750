import React from 'react';
import glamorous from 'glamorous';

import translate from '../../utils/i18n';

import type { Config, User, ToggleDebugModeFlag, DebugModeFlags, DebugModeData, HTMLStyle } from '../../types';
import { colours, scaledH3 } from '../../utils/css';
import StatelessModal from '../modals/statelessModal';
import ModalFooter from '../modals/modalFooter';
import DocValidationDebugModalContent from './docValidationDebugModalContent';

const CheckButton = glamorous.button({
  backgroundColor: colours.maroon,
});

const ModalContainer = glamorous.div({
  padding: '2rem',
});

type Props = {
  debugModeData: DebugModeData,
  modalStyle?: HTMLStyle,
  unsetDebugModeData: (flag: string) => void,
};

/**
 * Renders Debug Mode Modal when any flag is activated.
 * @returns {React.Component} The rendered component
*/
const DebugModeModal = ({ debugModeData, unsetDebugModeData, modalStyle }: Props) => {
  /**
   * Toggle the state of doc validation debug mode in the redux store
   * @returns {void}
   */
  const getModalContent = () => {
    switch (debugModeData.flag) {
      case 'docValidation':
        return (
          <DocValidationDebugModalContent
            debugModeData={debugModeData}
            unsetDebugModeData={unsetDebugModeData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StatelessModal
      title={`${translate('debug_mode')} : ${translate(debugModeData.flag || '')}` || ''}
      setVisible={() => { }}
      onClose={() => {}}
      visible={debugModeData.show}
      id={`debug-mode-${debugModeData.flag}`}
      noButton
      style={modalStyle}
      explicitCloseOnly
      noCloseButton
    >
      <ModalContainer>
        {getModalContent() || null}
      </ModalContainer>
    </StatelessModal>);
};

export default DebugModeModal;
