import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import SaveButton from './../buttons/saveButton';
import DeleteButton from './../buttons/deleteButton';
import StatelessModal from './../modals/statelessModal';
import Table from './../table/table';
import AddEditBankForm from './addEditBankForm';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission } from './../../utils/permissions';
import Button from './../buttons/button';

import type BankModel from './../../models/bankModel';
import type { SaveModel, User, Column, Row } from './../../types';

type Props = {
  saveModel: SaveModel,
  banks: List<BankModel>,
  user: User,
};

type State = {
  modelToEdit?: BankModel,
  bankFormIsVisible: boolean,
};

/**
 * Banks
 * @namespace Banks
 */
class Banks extends React.Component<Props, State> {
  /**
   * Creates an instance of Banks.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      modelToEdit: undefined,
      bankFormIsVisible: false,
    };
  }

  /**
   * Resets the state for display of the record payment modal.
   * @returns {void}
   */
  hideModal() {
    this.setState({ bankFormIsVisible: false });
  }

  /**
   * Creates an edit link for every row.
   * @param {BankModel} bank current bank item.
   * @returns {React.Component} An edit link/button
   */
  getEditLink(bank: BankModel) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('banks', 'update')])} user={this.props.user}>
        <Button
          dataPublic
          onClick={() => this.setState({ modelToEdit: bank, bankFormIsVisible: true })}
          className="o-text-button o-text-button--contextual"
        >
          {translate('edit')}
        </Button>
      </PermissionWrapper>
    );
  }

  /**
   * Creates a delete link for every row.
   * @param {BankModel} bank current bank item.
   * @returns {React.Component} a delete link
   */
  getDeleteLink(bank: BankModel) {
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('banks', 'delete')])} user={this.props.user}>
        <DeleteButton
          item={bank}
          saveModel={this.props.saveModel}
          dataPublic
        />
      </PermissionWrapper>
    );
  }

  /**
   * prepares rows data for the tablel.
   * @returns {Array<Row>}
   */
  getRows(): Array<Row> {
    return this.props.banks.map(bank => ({
      name: bank.get('name', ''),
      edit: this.getEditLink(bank),
      delete: this.getDeleteLink(bank),
    })).toArray();
  }

  /**
   * prepares columns data for the tablel.
   * @returns {Array<Column>}
   */
  getColumns(): Array<Column> {
    const columns = [
      { accessor: 'name', Header: translate('name'), filterable: true, className: 'o-column-border-right' },
    ];
    if (hasPermission(this.props.user, List([createPermission('banks', 'update')]))) {
      columns.push({
        accessor: 'edit', Header: '', sortable: false, width: 150,
      });
    }
    if (hasPermission(this.props.user, List([createPermission('banks', 'delete')]))) {
      columns.push({
        accessor: 'delete', Header: '', sortable: false, width: 150,
      });
    }
    return columns;
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <ContentTransition className="o-main__content">
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <StatelessModal
            id="add-edit-bank-form"
            visible={this.state.bankFormIsVisible}
            setVisible={() => this.hideModal()}
            noButton
            explicitCloseOnly
            title={this.state.modelToEdit ? translate('edit_bank_name') : translate('add_bank_name')}
            onClose={() => this.hideModal()}
            dataPublicHeader
          >
            <AddEditBankForm
              onSaveClicked={() =>
                this.setState({ bankFormIsVisible: false })
              }
              modelToEdit={this.state.modelToEdit}
              saveModel={this.props.saveModel}
              banks={this.props.banks}
            />
          </StatelessModal>
          <div className="o-header u-flex-space-between">
            <h1 data-public>{translate('banks')}</h1>
            <PermissionWrapper permissionsRequired={List([createPermission('banks', 'create')])} user={this.props.user}>
              <SaveButton
                dataPublic
                onClick={() => this.setState({ modelToEdit: undefined, bankFormIsVisible: true })}
                className="o-button--small"
                label={translate('add_bank')}
              />
            </PermissionWrapper>
          </div>
          <div className="o-card u-margin-bottom--4ws banks-table">
            <h2 data-public className="o-card__title">{translate('banks')}</h2>
            <Table
              columns={this.getColumns()}
              data={this.getRows()}
              noDataText={translate('no_bank_has_been_set')}
              defaultSorted={[{ id: 'name', desc: false }]}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default Banks;
