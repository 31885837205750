import React from 'react';

import { colours } from './../../utils/css';
import Button from './../buttons/button';

type Props = {
  isFlagged: boolean,
  onClick: () => void,
}

/**
 * A flag toggle button for a allergy model.
 * @class FlagButton
 * @returns {React.Component}
 */
const FlagButton = ({ isFlagged, onClick }: Props) => {
  const flagIcon = isFlagged ? 'fa-flag' : 'fa-flag-o';
  return (
    <Button
      onClick={() => onClick()}
      className="o-text-button"
      style={{ marginLeft: 'auto', marginRight: '20px' }}
      dataPublic
    >
      <i className={`fa ${flagIcon}`} style={{ fontSize: 22, color: colours.maroon }} />
    </Button>
  );
};

export default FlagButton;
