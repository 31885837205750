import React, { useState } from 'react';
import type { List, Map } from 'immutable';

import StatelessModal from './../modals/statelessModal';
import InventoryMappingTable from './inventoryMappingTable';
import DispensationUnitMapping from './dispensationUnitMapping';
import AddDetailForm from './addDetailsForm';

import translate from './../../utils/i18n';
import { INVENTORY_MAPPING_STATUSES } from '../../constants';
import DrugModel from '../../models/drugModel';

import type InventoryMapModel from '../../models/inventoryMapModel';
import type MasterDrugModel from '../../models/masterDrugModel';
import DrugSuggestionModel, { Attributes as DrugSuggestionAttributes } from '../../models/drugSuggestionModel';
import type { Config, MapValue, DrugMap } from '../../types';
import type DrugManufacturerModel from '../../models/drugManufacturerModel';

type Props = {
  inventoryItem: InventoryMapModel,
  drugMap: DrugMap,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  masterDrugModelsMap: Map<string, MasterDrugModel>,
  drugSuggestions: Map<string, DrugSuggestionModel>,
  fetchTableMasterData: (ids: List<string>) => Promise<void>,
  updateInventoryItem: (
    item: InventoryMapModel,
    params?: Partial<DrugSuggestionAttributes>,
    event: 'confirm_drug' | 'reject_drug' | 'add_drug_details') => Promise<void>,
  deleteDrug: (drug: DrugModel) => Promise<void>,
  updateDispensationUnit: (drug: DrugModel, dispensationUnit: string) => Promise<void>,
  hideModal: () => void,
  setDrugManufacturers: (models: List<DrugManufacturerModel>) => void,
  drugManufacturers: List<DrugManufacturerModel>,
}

/**
 Modal component shown on clicking add details in inventory mapping
 @returns {React.StatelessFunctionalComponent}
 */
const AddDetails = ({
  inventoryItem,
  drugMap,
  updateConfigValue,
  config,
  updateConfig,
  masterDrugModelsMap,
  drugSuggestions,
  fetchTableMasterData,
  updateInventoryItem,
  deleteDrug,
  updateDispensationUnit,
  hideModal,
  setDrugManufacturers,
  drugManufacturers,
}: Props) => {
  const drug = drugMap.get(inventoryItem.get('drug_id')) || new DrugModel();
  const mappingStatus = inventoryItem.get('mapping_status');

  const showSuggestedMapping =
      mappingStatus === INVENTORY_MAPPING_STATUSES.MAPPED;

  const showManualMapping =
      mappingStatus === INVENTORY_MAPPING_STATUSES.REVIEW_REJECTED ||
      mappingStatus === INVENTORY_MAPPING_STATUSES.REJECTED ||
      mappingStatus === INVENTORY_MAPPING_STATUSES.UNMAPPED;

  /**
   * Returns true if the drug does have the information needed for review in the MDL DB
   * @return {boolean}
   */
  const checkMDLReviewInfo = () => {
    if (drugSuggestions && drugSuggestions.has(inventoryItem.get('drug_id'))) {
      return true;
    }
    return false;
  };

  const hasMDLReviewInfo = checkMDLReviewInfo();

  /**
   * Returns true if we need to show the add detail form.
   * @return {boolean}
   */
  const isAddDetailFormVisible = () => {
    if (mappingStatus === INVENTORY_MAPPING_STATUSES.PENDING_REVIEW &&
      !hasMDLReviewInfo) {
      return true;
    }
    return false;
  };

  const [dispensationMappingVisible, setDispensationMappingVisible] = useState(false);
  const [savePromptVisible, setSavePromptVisible] = useState(false);
  const [showAddDetailForm, setShowAddDetailForm] = useState(isAddDetailFormVisible());


  /**
   * Returns the modal title
   * @return {boolean}
   */
  const getModalTitle = () => {
    if (showManualMapping && !showAddDetailForm) {
      return translate('manual_mapping');
    }
    if (showSuggestedMapping && !showAddDetailForm) {
      return translate('suggested_mapping');
    }
    if (showAddDetailForm) {
      return translate('add_details');
    }
    return '';
  };

  const modalTitle = getModalTitle();


  /**
   * Returns true if the drug does not have the dispensation unit available.
   * @return {boolean}
   */
  const checkDispensationUnitInfo = () => {
    if (drug && drug.get('dispensation_unit')) {
      return true;
    }
    return false;
  };

  /**
   * @returns {void}
   */
  const deleteItem = () => deleteDrug(drug).then(() => hideModal());

  /**
   * @param {string} dispensationUnit dispensation_unit to set
   * @returns {void}
   */
  const saveDispensationUnit = (dispensationUnit: string) =>
    updateDispensationUnit(drug, dispensationUnit).then(() => {
      hideModal();
      setDispensationMappingVisible(false);
    });

  /**
   * @returns {void}
   */
  const onConfirmDrug = () => {
    if (!checkDispensationUnitInfo()) {
      setDispensationMappingVisible(true);
    } else {
      hideModal();
    }
  };

  /**
   * @returns {void}
   */
  const onModalClose = () => {
    if (showAddDetailForm && !savePromptVisible) {
      // Used for showing unsaved data prompt in addDetailsForm
      setSavePromptVisible(true);
    } else {
      hideModal();
    }
  };

  /**
   * @returns {React.Component}
   */
  const renderComponent = () => {
    if ((showSuggestedMapping || showManualMapping) && !showAddDetailForm) {
      return (
        <InventoryMappingTable
          inventoryItem={inventoryItem}
          isManualMapping={showManualMapping}
          updateConfigValue={updateConfigValue}
          config={config}
          updateConfig={updateConfig}
          masterDrugModelsMap={masterDrugModelsMap}
          drug={drug}
          fetchTableMasterData={fetchTableMasterData}
          updateInventoryItem={updateInventoryItem}
          setShowAddDetailForm={setShowAddDetailForm}
          onDelete={deleteItem}
          onConfirm={onConfirmDrug}
        />
      );
    }
    if (showAddDetailForm) {
      return (
        <AddDetailForm
          drug={drug}
          inventoryItem={inventoryItem}
          onDelete={deleteItem}
          savePromptVisible={savePromptVisible}
          setSavePromptVisible={setSavePromptVisible}
          onClose={hideModal}
          updateInventoryItem={updateInventoryItem}
          setDrugManufacturers={setDrugManufacturers}
          drugManufacturers={drugManufacturers}
        />
      );
    } return null;
  };

  return dispensationMappingVisible ? (
    <StatelessModal
      id="dispensation_unit_mapping"
      visible={dispensationMappingVisible}
      onClose={hideModal}
      setVisible={() => setDispensationMappingVisible(true)}
      title={translate('dispensation_unit_mapping')}
      noButton
      explicitCloseOnly
    >
      <DispensationUnitMapping
        drug={drug}
        onDelete={deleteItem}
        saveDispensationUnit={saveDispensationUnit}
      />
    </StatelessModal>
  ) : (
    <StatelessModal
      id="add-details"
      visible={!!inventoryItem}
      onClose={onModalClose}
      noButton
      setVisible={() => {}}
      title={modalTitle}
      explicitCloseOnly
    >
      {renderComponent()}
    </StatelessModal>
  );
};

export default AddDetails;
