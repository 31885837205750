import React, { Fragment } from 'react';
import { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import PanelCategoryInput from './panelCategoryInput';
import Select from './../inputs/select';
import Button from './../buttons/button';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { CategoryCoveragePayor } from './../../models/drugModel';
import type { MapValue, Config } from './../../types';

export type Category = Map<string, MapValue>; // Format { category: string, coveragePayorIds: List<string> }

type Props = {
  coveragePayors: List<CoveragePayorModel>,
  value: List<CategoryCoveragePayor>,
  onValueChanged: (
    value: List<CategoryCoveragePayor>,
    defaultPanelCategory?: string
  ) => void,
  defaultPanelCategoryValue?: string,
  config: Config,
  style?: MapValue,
};

type State = {
  categories: List<Category>,
  defaultPanelCategory?: string,
};

/**
 * Converts the passed props value to a format readable by this component.
 * @param {List<CategoryCoveragePayor>} propsValue The received props value.
 * @returns {List<Category>}
 */
function convertPropsToState(propsValue: List<CategoryCoveragePayor>): List<Category> {
  return propsValue
    .groupBy(v => v.category)
    .toList()
    .map((priceList: List<CategoryCoveragePayor>) => Map({
      // @ts-ignore
      category: priceList.first().category,
      coveragePayorIds: priceList.map(i => i.coverage_payor_id),
    }));
}

/**
 * Converts the current state value to a format readable by the parent component.
 * @param {List<Category>} stateValue The current state value
 * @returns {List<CategoryCoveragePayor>}
 */
function convertStateToProps(stateValue: List<Category>): List<CategoryCoveragePayor> {
  return stateValue.map((categoryItem: Category) =>
    categoryItem.get('coveragePayorIds', List()).map((coveragePayorId: string) => ({
      category: categoryItem.get('category'),
      coverage_payor_id: coveragePayorId,
    }))).flatten();
}

/**
 * A form that allows the setting of individual categories for a drug per panel.
 * @class PanelCategoryForm
 * @extends {React.Component<Props, State>}
 */
class PanelCategoryForm extends React.Component<Props, State> {
  /**
   * Creates an instance of PanelCategoryForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    const categories = convertPropsToState(props.value);
    this.state = {
      categories: categories.size > 0 ? categories : List(), // Create an empty item if no categories given.
      defaultPanelCategory: props.defaultPanelCategoryValue || undefined,
    };
  }

  /**
   * Handle input field change
   * @param {{}} value Field value
   * @returns {void}
   */
  handleOnChange(value: {}) {
    const newValue = Object.assign(this.state, value);
    this.props.onValueChanged(
      convertStateToProps(newValue.categories),
      newValue.defaultPanelCategory,
    );
  }

  /**
   * Gets a List of all unused CoveragePayor models. Also filters out any disabled panels.
   * @param {List<string>} idsToIgnore A list of ids to ignore (i.e. 'used' panels).
   * @returns {List<CoveragePayorModel>}
   */
  getUnusedCoveragePayors(idsToIgnore: List<string> = List()): List<CoveragePayorModel> {
    const usedIDs = this.state.categories
      .map(p => p.get('coveragePayorIds'))
      .flatten()
      .filter(id => !idsToIgnore.includes(id));
    return this.props.coveragePayors
      .filter((payor: CoveragePayorModel) => !usedIDs.includes(payor.get('_id')) && payor.isVisible());
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="o-form" style={this.props.style}>
          <Select
            id="default-coverage-payor-category"
            label={translate('default_panel_category')}
            description={(<p className="o-sub-label o-sub-label-font o-sub-label-margin">{translate('panel_category_desc')}</p>)}
            descClassName="o-sub-label-font o-sub-label-margin"
            options={this.props.config.getIn(['panel_categories', 'options'], List()).toArray()}
            value={this.state.defaultPanelCategory}
            onValueChanged={category => this.handleOnChange({ defaultPanelCategory: category })}
          />
          {
            this.state.categories.map((categoryItem: Category, index: number) => {
              const key = `category-${index}`;
              return (
                <PanelCategoryInput
                  config={this.props.config}
                  value={categoryItem}
                  index={index}
                  key={key}
                  unusedCoveragePayors={this.getUnusedCoveragePayors(categoryItem.get('coveragePayorIDs'))}
                  coveragePayors={this.props.coveragePayors}
                  setValue={
                    (newValue: Category) =>
                      this.handleOnChange({
                        categories: this.state.categories.set(index, newValue),
                      })
                  }
                  removeValue={() =>
                    this.handleOnChange({
                      categories: this.state.categories.remove(index),
                    })}
                />
              );
            }).toArray()
          }
          <Button
            className="o-button o-button--small u-margin-bottom--1ws"
            style={{ width: '33%' }}
            onClick={() => this.setState({
              categories: this.state.categories.push(Map({ price: '', coveragePayorIds: List() })),
            })}
            dataPublic
          >{translate('add_new_category')}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default PanelCategoryForm;
