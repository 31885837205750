/* eslint-disable max-len */
import * as React from 'react';
import { Link } from 'react-router-dom';
import glamorous from 'glamorous';

import StatelessModal from '../modals/statelessModal';
import mdlPopupImage from '../../../static/images/mdl_popup.png';

import { checkAndSetCookieForPopup } from '../../utils/cookie';
import { MDL_MODAL_SHOWN_TO } from '../../constants';

import type { User } from '../../types';

const ImageContainer = glamorous.div({
  width: '100%',
  backgroundColor: '#d8d8d8',
  marginBottom: '1rem',
});

const Image = glamorous.img({
  alt: '',
  height: '40vh',
});

const MappingButton = glamorous.button({
  fontSize: '1rem',
  height: 'unset',
  padding: '.75rem 1.75rem',
});

type Props = {
  user: User,
};

/**
 * @returns {React.StatelessFunctionalComponent}
 */
const MdlPopup: React.SFC<Props> = ({
  user,
}: Props) => {
  const [shownMdlPopup, setShownMdlPopup] = React.useState(false);
  const isMdlPopupVisible = !shownMdlPopup && checkAndSetCookieForPopup(user.get('id', ''), MDL_MODAL_SHOWN_TO);
  return isMdlPopupVisible ? (
    <StatelessModal
      id="mdl-popup"
      visible={isMdlPopupVisible}
      onClose={() => setShownMdlPopup(true)}
      setVisible={() => setShownMdlPopup(true)}
      title="NEW INVENTORY FEATURE IS OUT!"
      dataPublicHeader
    >
      <div className="u-margin--standard">
        <ImageContainer>
          <Image src={mdlPopupImage} alt="" />
        </ImageContainer>
        <p className="u-margin-bottom--1ws">
          As a part of our commitment to ensure you get the best service and to help improve outcomes for patients,
          we are embarking on a drug list standardization process based on the
          National Pharmaceutical Regulatory Agency (NPRA) database.
        </p>
        <p className="u-margin-bottom--1ws">
          <span data-public className="u-strong"> You can now proceed with Part 2 of the mapping process. </span>
          In this process, you will be able to search and verify items that have no match from the previously suggested mapping process.
          It’s a simple process where you search for the drugs that were not suggested before.
        </p>
        <p className="u-margin-bottom--1ws">
          Please refer to the email that has been sent to your clinic for more
          information on this feature.
        </p>
        <div className="u-text-align-right">
          <MappingButton className="o-button">
            <Link to="/inventory/inventory-mapping" className="u-font-white">Start Mapping Now!</Link>
          </MappingButton>
        </div>
      </div>
    </StatelessModal>
  ) : null;
};

export default MdlPopup;
