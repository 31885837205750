import React from 'react';
import { Map, List } from 'immutable';
import type { Column } from './../../types';

import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import Keypress from './../keypress';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import { validateAndTrimString } from './../../utils/utils';
import Button from './../buttons/button';

type Props = {
  // addString: Map<string, string> => Promise<void>,
  columns: Array<Column>,
  clearMapToEdit: () => void, // eslint-disable-line react/no-unused-prop-types
  mapToEdit?: Map<string, string>, // eslint-disable-line react/no-unused-prop-types
  updateMap: (item: Map<string, string>) => Promise<void>, // eslint-disable-line react/no-unused-prop-types
  items: List<Map<string, string>>,
};

type State = {
  isSaving: boolean,
  item: Map<string, string>,
  changesMade: boolean,
  errorMessage?: string,
  isEditing: boolean,
};

const defaultState = {
  isSaving: false,
  item: Map(),
  changesMade: false,
  errorMessage: undefined,
  isEditing: false,
};

/**
 * A form component for the string map list editor.
 * @class MapListEditorForm
 * @extends {React.Component<Props, State>}
 */
class MapListEditorForm extends React.Component<Props, State> {
  /**
   * Creates an instance of MapListEditorForm.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = defaultState;
  }

  /**
   *Validate item to saved
   * @param {Map<string, string>} item The item to add or edit
   * @returns {Map<string, string>}
   */
  validatedItemString(item: Map<string, string>) {
    const validatedItem = {
      name: validateAndTrimString(item.get('name')),
      short_code: validateAndTrimString(item.get('short_code')),
    };
    return Map(validatedItem);
  }

  /**
   * Validates the item currently being edited. Currently it just requires atleast 1 value to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  validateItem() {
    const { item } = this.state;
    const existsItem = this.props.items.find(i => i.get('name') === validateAndTrimString(item.toJS()).name);
    if (!validateAndTrimString(validateAndTrimString(item.toJS()).name).length) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    } else if (!this.state.isEditing && existsItem) {
      this.setState({ errorMessage: translate('item_already_exists') });
      return false;
    }
    return true;
  }

  /**
   * Called when save is clicked. Validates the item and saves it.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    if (this.validateItem()) {
      this.setState({ isSaving: true });
      return this.props.updateMap(this.validatedItemString(this.state.item))
        .then(() => {
          createSuccessNotification(translate(this.state.isEditing ? 'item_updated' : 'item_added'));
          this.setState(defaultState);
          return true;
        });
    }
    return Promise.resolve(false);
  }

  /**
   * Resets the model on props change and sets state to duplicate attributes for the incoming model
   * if there is one.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.mapToEdit !== nextProps.mapToEdit) {
      if (nextProps.mapToEdit) {
        this.setState({
          item: nextProps.mapToEdit,
          isEditing: true,
        });
      } else {
        this.setState(Object.assign({}, defaultState));
      }
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Keypress className="o-form" onEnterPressed={() => this.onSaveClicked()}>
        <SavePrompt when={this.state.changesMade} onSaveClicked={() => this.onSaveClicked()} />
        {
          this.state.errorMessage &&
          <FormError containerElementID="right-sidebar">
            {this.state.errorMessage}
          </FormError>
        }
        {
          this.props.columns && this.props.columns.map(col =>
            <Input
              id="item_name"
              label={col.Header}
              labelClassName="u-font-color-white"
              value={this.state.item.get(col.accessor)}
              onValueChanged={value => this.setState({
                item: this.state.item.set(col.accessor, value),
                changesMade: true,
              })}
              required={col.required}
            />)
        }
        <SaveButton
          dataPublic
          className="o-button--small"
          isSaving={this.state.isSaving}
          label={this.props.mapToEdit ? translate('save') : translate('add_item')}
          onClick={() => this.onSaveClicked()}
          fullWidth
        />
        {
          this.props.mapToEdit &&
          <Button
            onClick={this.props.clearMapToEdit}
            className="o-button o-button--small o-button--danger u-margin-top--standard"
            dataPublic
          >
            {translate('cancel')}
          </Button>
        }
      </Keypress>
    );
  }
}

export default MapListEditorForm;
