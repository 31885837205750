import React, { Fragment } from 'react';
import glamorous from 'glamorous';
import { List } from 'immutable';
import { isInclusivelyBeforeDay } from 'react-dates';
import Moment from 'moment';

import { UNICODE } from './../../constants';
import KeyValue from './../layout/keyValue';
import translate from './../../utils/i18n';
import { convertNumberToPrice } from './../../utils/utils';
import Input from './../inputs/input';
import TextArea from './../inputs/textarea';
import Select from './../inputs/select';
import { wsUnit, mediaQueries } from './../../utils/css';
import { prettifyDateTime, prettifyDate, combineDateAndTime } from './../../utils/time';
import ModalFooter from './../modals/modalFooter';
import SaveButton from './../buttons/saveButton';
import FormError from './../formError';
import PaymentModel from './../../models/paymentModel';
import { createSuccessNotification } from './../../utils/notifications';
import DatePicker from './../inputs/statefulDatepicker';
import PaymentTypeModel from '../../models/paymentTypeModel';
import TimeInput from './../inputs/timeInput';

import type { MapValue, SaveModels, SaveModel } from './../../types';
import type PatientStubModel from '../../models/patientStubModel';
import type PatientModel from './../../models/patientModel';
import type ReceivableModel from '../../models/receivableModel';
import { getDefaultPaymentMethod } from '../../utils/billing';

type Props = {
  receivable: ReceivableModel,
  patient: PatientStubModel | PatientModel | void,
  onSave: () => void,
  saveModels: SaveModels,
  saveModel: SaveModel,
  paymentTypes: List<PaymentTypeModel>,
};

const DEFAULT_ATTRIBUTES = {
  amount: '0',
  notes: '',
  dateReceived: new Moment(),
  time: new Moment(),
};

type State = {
  attributes: {
    amount: string,
    method: string,
    notes: string,
    dateReceived: Moment,
    time: Moment,
  },
  isSaving: boolean,
  errorMessage?: ?string,
};

const PaymentFormGrid = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: `calc(${wsUnit} / 2)`,
  [mediaQueries.forDesktopUp]: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },
  [mediaQueries.forTabletPortraitUp]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
});

/**
 * A form for making a Payment for a given receivable/bill
 * @class PaymentForm
 * @extends {React.Component<Props, State>}
 */
class PaymentForm extends React.Component<Props, State> {
  /**
   * Creates an instance of PaymentForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    const amountDue = props.receivable.get('amount_due');
    this.state = {
      attributes: Object.assign({}, DEFAULT_ATTRIBUTES,
        { amount: amountDue },
        { method: getDefaultPaymentMethod(this.props.paymentTypes) }),
      isSaving: false,
    };
  }

  /**
   * Update amount as receivable amount due changes
   * @param {Props} nextProps Next Props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (this.props.receivable.get('_id') !== nextProps.receivable.get('_id')) {
      const amountDue = nextProps.receivable.get('amount_due');
      const attributes = Object.assign({}, DEFAULT_ATTRIBUTES, { amount: amountDue });
      this.setState(Object.assign({}, this.state, { attributes }));
    }
  }

  /**
   * Helper function to update attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState({ attributes: Object.assign({}, this.state.attributes, { [key]: value }) });
  }

  /**
   * Validates the payment form by checking the following:
   * - Amount and method fields have values
   * - Amount is greater than 0
   * - Amount is not greater than owed amount.
   * @returns {boolean}
   */
  isValid(): boolean {
    if (
      !this.state.attributes.amount ||
      !this.state.attributes.method ||
      isNaN(parseFloat(this.state.attributes.amount))
    ) {
      this.setState({ errorMessage: 'fill_required_fields' });
      return false;
    }
    if (parseFloat(this.state.attributes.amount) < 0) {
      this.setState({ errorMessage: 'payment_amount_must_be_more_than_0' });
      return false;
    }
    if (this.state.attributes.amount > this.props.receivable.get('amount_due')) {
      this.setState({ errorMessage: 'payment_amount_cant_be_greater_than_amount_owed' });
      return false;
    }
    if (!this.state.attributes.dateReceived) {
      this.setState({ errorMessage: translate('fill_date_in_correct_format') });
      return false;
    }
    if (!this.state.attributes.time) {
      this.setState({ errorMessage: translate('fill_time_and_in_correct_format') });
      return false;
    }
    return true;
  }

  /**
   * Saves the payment (if the form is valid). props.onSave will be called on completion
   * @returns {void}
   */
  onSaveClicked() {
    if (this.isValid()) {
      this.setState({
        errorMessage: undefined,
        isSaving: true,
      });
      const newAmountDue = parseFloat(this.props.receivable.get('amount_due')) - parseFloat(this.state.attributes.amount);
      const receivable = this.props.receivable.set({ amount_due: newAmountDue });
      const timestamp = combineDateAndTime(
        this.state.attributes.dateReceived,
        this.state.attributes.time,
      ).valueOf();
      const payment = new PaymentModel({
        patient_id: receivable.get('patient_id'),
        bill_id: receivable.get('bill_id'),
        is_finalised: true,
        timestamp,
        amount: parseFloat(this.state.attributes.amount),
        method: this.state.attributes.method,
        payor_type: 'patient',
        receivable_id: receivable.get('_id'),
        notes: this.state.attributes.notes,
      });
      this.props.saveModels([payment, receivable])
        .then(() => {
          createSuccessNotification(
            translate('a_payment_of_x_has_been_made_for_patient_y_for_the_encounter_on_z', {
              x: convertNumberToPrice(payment.get('amount')),
              y: this.props.patient ? this.props.patient.get('patient_name') : UNICODE.EMDASH,
              z: prettifyDate(receivable.getDate()),
            }),
          );
          this.props.onSave();
        });
    }
  }

  /**
   * Checks the given method value against what exists in payment types props and add new value if necessary.
   * @param {string} newValue A new string from newly created payment method.
   * @returns {void}
   */
  updatePaymentTypesProp = (newValue: string) => {
    const existingValues = this.props.paymentTypes.map(paymentType => paymentType.get('name'));
    const isNewValueExist = existingValues
      .find(existingValue => existingValue.toLowerCase() === newValue.toLowerCase());
    if (!isNewValueExist) {
      this.props.saveModel(new PaymentTypeModel({ name: newValue }));
    }
  }

  /**
   * Update method on user input change
   * @param {string} value The payment method field value
   * @returns {void}
   */
  onMethodChangeValue = (value: string) => {
    if (!value || value.length === 0) {
      this.updateAttribute('method', '');
    } else {
      this.updateAttribute('method', value);
      this.updatePaymentTypesProp(value);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { receivable, patient } = this.props;
    return (
      <Fragment>
        <div className="o-form">
          <KeyValue label={translate('encounter_date')} value={prettifyDateTime(receivable.getDate())} />
          <KeyValue label={translate('patient_name')} value={patient ? patient.get('patient_name', UNICODE.EMDASH, false) : UNICODE.EMDASH} />
          <KeyValue label={translate('patient_ic')} value={patient ? patient.get('ic', UNICODE.EMDASH, false) : UNICODE.EMDASH} />
          <KeyValue label={translate('case_id')} value={patient ? patient.get('case_id', UNICODE.EMDASH, false) : UNICODE.EMDASH} />
          <KeyValue label={translate('encounter_fees')} value={convertNumberToPrice(receivable.get('amount'))} />
          <KeyValue isDanger label={translate('amount_outstanding')} value={convertNumberToPrice(receivable.get('amount_due'))} />
          <hr />
          {
            this.state.errorMessage &&
            <FormError>{translate(this.state.errorMessage)}</FormError>
          }
          <PaymentFormGrid>
            <Input
              id="payment_amount"
              label={translate('amount')}
              value={this.state.attributes.amount}
              onValueChanged={value => this.updateAttribute('amount', isNaN(parseFloat(value)) ? value : parseFloat(value))}
              required
              type="number"
              min={0}
              step={0.01}
            />
            <Select
              id="payment_type"
              label={translate('payment_type')}
              options={
                this.props.paymentTypes
                  .map(c => ({ value: c.get('name'), label: translate(c.get('name')) }))
                  .toArray()
              }
              value={this.state.attributes.method}
              onValueChanged={this.onMethodChangeValue}
              required
              creatable
            />
          </PaymentFormGrid>
          <PaymentFormGrid>
            <DatePicker
              id="paymentFormDatePicker"
              showClearDate={false}
              allowPast
              label={translate('date_received')}
              onValueChanged={value => this.updateAttribute('dateReceived', value || null)}
              value={this.state.attributes.dateReceived}
              className="_u-padding-bottom--1ws"
              isOutsideRange={day => !isInclusivelyBeforeDay(day, Moment())}
            />
            <TimeInput
              id="time"
              value={this.state.attributes.time}
              onChange={value => this.updateAttribute('time', value || null)}
              label={translate('time')}
              style={{ width: '150px' }}
            />
          </PaymentFormGrid>
          <TextArea
            id="payment_notes"
            label={translate('notes')}
            value={this.state.attributes.notes}
            onValueChanged={value => this.updateAttribute('notes', value)}
          />
        </div>
        <ModalFooter>
          <SaveButton
            dataPublic
            className="o-button--small u-margin-right--half-ws"
            isSaving={this.state.isSaving}
            label={translate('save_payment')}
            onClick={() => this.onSaveClicked()}
          />
        </ModalFooter>
      </Fragment>
    );
  }
}

export default PaymentForm;
