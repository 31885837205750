import React from 'react';
import { List } from 'immutable';

import { debugPrint } from './../../utils/logging';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import ListEditorTable from './listEditorTable';
import ListEditorForm from './listEditorForm';

import type BaseModel from './../../models/baseModel';
import type { SaveModel, Config, Model, Column, User } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';

type Props = {
  label: string,
  type: 'coverage_payor' | 'doctor' | 'drug' | 'sales_item' | 'supplier',
  saveModel: SaveModel,
  canAdd: boolean,
  canEdit: boolean,
  canDelete: boolean,
  config: Config,
  items: List<Model>,
  columns: Array<Column>,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
};

/**
 * ListEditor
 * @namespace ListEditor
 */
class ListEditor extends React.Component<Props, { modelToEdit: BaseModel | void }> {
  static defaultProps = {
    canAdd: true,
    canEdit: true,
    canDelete: true,
  };

  /**
   * Creates an instance of ListEditor.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = { modelToEdit: undefined };
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering ListEditor');
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <h1 data-public className="o-title">{this.props.label}</h1>
          <div className="o-card u-margin-bottom--4ws">
            <h2 data-public className="o-card__title">{this.props.label}</h2>
            <ListEditorTable
              onEditClicked={modelToEdit => this.setState({ modelToEdit })}
              saveModel={this.props.saveModel}
              {...this.props}
            />
          </div>
        </section>
        {
          this.state.modelToEdit || this.props.canAdd ?
            <section className="o-scrollable-container o-sidebar--right" style={{ height: '100vh' }} id="right-sidebar">
              <h1 data-public className="o-title u-font-color-white">
                {this.state.modelToEdit ? translate('edit_item') : translate('add_item')}
              </h1>
              <ListEditorForm
                modelToEdit={this.state.modelToEdit}
                clearModelToEdit={() => this.setState({ modelToEdit: undefined })}
                type={this.props.type}
                config={this.props.config}
                coveragePayors={this.props.coveragePayors}
                user={this.props.user}
                {...this.props}
              />
            </section> :
            <section className="o-scrollable-container o-sidebar--right" style={{ height: '100vh' }} id="right-sidebar" />
        }
      </ContentTransition>
    );
  }
}

export default ListEditor;
