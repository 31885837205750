import React from 'react';
import { List, Map, OrderedMap, Set } from 'immutable';

import translate from './../../utils/i18n';
import StatelessModal from './../modals/statelessModal';

import type { SaveModel, Config, HTMLStyle } from './../../types';
import EncounterStageModel from '../../models/encounterStageModel';
import Select from '../inputs/select';
import ModalFooter from '../modals/modalFooter';
import SaveButton from '../buttons/saveButton';
import UnsavedDataModal from '../prompts/unsavedDataModal';
import { mapStringToOption } from '../../utils/inputs';
import { updateClinicConfig } from '../../utils/db';
import FormError from '../formError';

type Props = {
  saveModel: SaveModel,
  config: Config,
  updateConfig: (config: Config) => void,
  unreviewedStages: OrderedMap<string, EncounterStageModel>
  buttonStyle?: HTMLStyle,
};

type State = {
  modalVisible: boolean,
  locations: Array<string>,
  createdLocations: Set<String>,
  currentStage: EncounterStageModel,
  isSaving: boolean,
  changesMade: boolean,
  errorMessage: string,
};

/**
 * Creates a un-reviewed stage form modal
 * @class UnreviewedStageForm
 * @extends {React.Component<Props, State>}
 */
class UnreviewedStageForm extends React.Component<Props, State> {
  /**
   * Creates an instance of Banks.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    const { unreviewedStages } = props; // revieweing should be done only once
    this.state = {
      modalVisible: false,
      currentStage: unreviewedStages.first(),
      locations: (unreviewedStages?.first() as EncounterStageModel | undefined)?.get('locations') || [],
      createdLocations: Set(),
      isSaving: false,
      changesMade: false,
      errorMessage: '',
    };
  }

  isValid = () => {
    if (this.state.locations.length < 1) {
      this.setState({
        errorMessage: translate('no_location_set'),
      });
      return false;
    }
    return true;
  }

  /**
   * Called on save clicked
   * @return {undefined}
   */
  onSaveClicked() {
    if (!this.isValid()) {
      return Promise.resolve(false);
    }
    this.setState({ isSaving: true });
    const { locations } = this.state;
    const stage = new EncounterStageModel(Object.assign({}, this.state.currentStage.attributes, {
      locations: locations.length > 0 ? locations : [],
    }));
    const { config } = this.props;
    const reviewedSatges = Set(config.getIn(['reviewed_stages'], []));
    const updatedReviewsStages = reviewedSatges.add(stage.get('_id'));
    return this.props.saveModel(stage)
      .then(() => updateClinicConfig(
        this.props.config.toJS(),
        Map().setIn(['reviewed_stages'], updatedReviewsStages.toArray())
          .setIn(['clinic', 'locations'], this.props.config.getIn(['clinic', 'locations']).concat(this.state.createdLocations.toList()))
          .toJS(),
        this.props.updateConfig,
      )
        .then(() => {
          this.setState({
            currentStage: this.props.unreviewedStages.first(),
            locations: (this.props.unreviewedStages?.first() as EncounterStageModel | undefined)?.get('locations') || [],
            isSaving: false,
            errorMessage: '',
            changesMade: false,
            modalVisible: Boolean(this.props.unreviewedStages.size),
          });
          return true;
        })
        .catch(() => {
          this.setState({
            errorMessage: translate('error_contact_support'),
            isSaving: false,
            changesMade: false,
          });
          return false;
        }));
  }

  /**
   * Resets the modal state.
   * @returns {void}
   */
  handleCancel() {
    const currentStage = this.props.unreviewedStages.first() as EncounterStageModel;
    this.setState({
      errorMessage: '',
      modalVisible: false,
      changesMade: false,
      currentStage,
      locations: currentStage?.get('locations') || [],
    });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const reviewedStagesSize = this.props.config.getIn(['reviewed_stages'], []).size;
    const endNumber = this.props.unreviewedStages.size + reviewedStagesSize;
    const startNumber = reviewedStagesSize + 1;
    const availableLocations = this.props.config
      .getIn(['clinic', 'locations'], List())
      .map((location: string) => ({ value: location, label: location }))
      .toArray();
    return (
      <StatelessModal
        id="add-edit-bank-form"
        visible={this.state.modalVisible}
        setVisible={(isVisible: boolean) => {
          if (this.state.changesMade &&
            this.state.modalVisible && !this.state.errorMessage) {
            this.setState({
              errorMessage: translate('you_have_unsaved_changes'),
            });
          } else {
            this.setState({ modalVisible: isVisible });
          }
        }}
        explicitCloseOnly
        title={`Review Stages (${startNumber}/${endNumber})`}
        onClose={() => {
          if (!this.state.changesMade || this.state.errorMessage) {
            this.handleCancel();
          }
        }}
        dataPublicHeader
        buttonLabel={translate('review_stages')}
        buttonClass="o-review-stage-button o-button--small"
        noButton={false}
        buttonStyle={this.props.buttonStyle}
      >
        <section className="o-form">
          {this.state.errorMessage &&
            <FormError containerElementID="unreviewed-stage">
              {this.state.errorMessage}
            </FormError>
          }
          <p>
            {translate('unreviewed_stage_location_message')}
          </p>
          <br />
          <br />
          <div className="u-flex-row">
            <span className="c-patient-card__item__key">{translate('stage_name')}</span>
            <span className="c-patient-card__item__value">{this.state.currentStage.get('name')}</span>
          </div>
          <br />
          <Select
            id="locations"
            label={translate('stage_locations_label')}
            value={mapStringToOption(this.state.locations)}
            description={translate('multiple_locations_can_be_set')}
            options={availableLocations}
            required
            isMulti
            onValueChanged={newValue =>
              this.setState({
                changesMade: true,
                locations: newValue.map(v => v.value),
              })
            }
            menuPortalTarget={document.body}
            creatable
            onCreateOption={newValue =>
              this.setState({
                createdLocations: this.state.createdLocations.add(newValue),
                locations: this.state.locations.concat(newValue),
              })}
          />
        </section>
        <ModalFooter>
          <SaveButton
            dataPublic
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default UnreviewedStageForm;
