import React, { Fragment } from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import Input from './../inputs/input';
import MultiSelect from './../inputs/multiSelect';
import translate from './../../utils/i18n';
import CloseButton from './../buttons/closeButton';
import CoveragePayorForm from './../coveragePayors/coveragePayorForm';
import StatelessModal from './../modals/statelessModal';
import { border } from './../../utils/css';
import { validateAndTrimString } from './../../utils/utils';

import type { Price } from './panelDrugPriceForm';
import CoveragePayorModel from './../../models/coveragePayorModel';
import type { SelectOption, SelectOpts, SaveModel } from './../../types';

const InputContainer = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyContent: 'left',
  ':not:last-of-type': {
    borderBottom: border,
  },
  '& button': {
    justifySelf: 'left',
  },
});

type Props = {
  index: number,
  unusedCoveragePayors: List<CoveragePayorModel>,
  coveragePayors: List<CoveragePayorModel>,
  value: Price,
  setValue: (newValue: Price) => void,
  removeValue: () => void,
  saveModel: SaveModel,
  validationDocObject?: { id: string, type: string },
}

type State = {
  newPanelModalVisible: boolean,
  newPanelInput: string,
  modelToEdit: CoveragePayorModel | void,
};

/**
 * A component for a single price point of a drug.
 * @class PanelDrugPriceInput
 * @extends {React.Component<Props, State>}
 */
class PanelDrugPriceInput extends React.Component<Props, State> {
  /**
   * Creates an instance of PanelDrugPriceInput.
   * @param {Props} props The initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      newPanelModalVisible: false,
      newPanelInput: '',
      modelToEdit: undefined,
    };
  }

  /**
   * Updates the price.
   * @param {string} price The price set in the input.
   * @return {void}
   */
  updatePrice(price: string) {
    if (price === '') {
      this.props.setValue(this.props.value.set('price', ''));
    } else {
      const priceFloat = parseFloat(price);
      if (priceFloat && !isNaN(priceFloat)) {
        this.props.setValue(this.props.value.set('price', priceFloat));
      }
    }
  }

  /**
   * Maps a give coveragePayor ID to a Select value (i.e. gives it a label).
   * @param {string} id A coveragePayor ID.
   * @returns {SelectOption}
   */
  mapIdToSelectValue(id: string): SelectOption {
    const missingId = this.props.validationDocObject?.id;
    const coveragePayors = missingId && (missingId === id) ?
      this.props.coveragePayors
        .push(new CoveragePayorModel({ _id: missingId, is_missing: true })) :
      this.props.coveragePayors;
    const payor = coveragePayors.find(cp => cp.get('_id') === id);
    return {
      value: id,
      label: payor?.isMissing() ? translate('data_not_found').toUpperCase() : payor?.get('name'),
      ...(payor?.isMissing() && {
        isOptionHidden: true,
        style: {
          color: 'red',
        },
      }),
    };
  }

  /**
   * Panel Modal Handler
   * @param {string} option New Coverage Payor user input
   * @param {boolean} visible Modal visibility
   * @returns {void}
   */
  handleCreatePanelModal(option: string, visible: boolean) {
    this.setState({
      newPanelModalVisible: visible,
      newPanelInput: option,
      modelToEdit: (visible && option)
        ? new CoveragePayorModel({ name: validateAndTrimString(option), active: true })
        : undefined,
    });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <InputContainer>
          <Input
            id={`panelDrugPrice-${this.props.index}`}
            label={translate('price_x', { x: this.props.index + 1 })}
            type="number"
            value={this.props.value.get('price')}
            onValueChanged={price => this.updatePrice(price)}
          />
          <CloseButton dataPublic onClick={() => this.props.removeValue()} />
          <MultiSelect
            id={`panelDrugPanels-${this.props.index}`}
            style={{ gridColumn: '1/4' }}
            label={translate('panels_assigned_to_this_price')}
            options={this.props.unusedCoveragePayors.map(payor => ({
              value: typeof payor.get('_id') === 'string' ? payor.get('_id') : '',
              label: typeof payor.get('name') === 'string' ? payor.get('name') : '',
            }))
              .push({ value: 'create', label: translate('add_new_coverage_payor') })
              .toArray()
          }
            value={
              this.props.value.get('coveragePayorIds', List())
                .map(value => this.mapIdToSelectValue(value))
                .toArray()
            }
            onChange={
              (newValues: SelectOpts) => {
                const isAddNewCoverageClicked = newValues[newValues.length - 1]?.value === 'create';
                if (isAddNewCoverageClicked) {
                  this.handleCreatePanelModal('', true);
                } else {
                  this.props.setValue(this.props.value.set('coveragePayorIds', List(newValues.map(v => v.value))));
                }
              }
            }
            creatable
            showNewOptionAtTop={false}
            onCreateOption={(option) => {
              this.handleCreatePanelModal(option, true);
            }}
            createOptionPromptFactory={label => translate('add_x_to_coverage_payors', { x: label })}
          />
        </InputContainer>
        <StatelessModal
          id={`addNewPanelModal-${this.state.newPanelInput}`}
          title={translate('add_item')}
          setVisible={(isVisible: boolean) => this.setState({ newPanelModalVisible: isVisible })}
          visible={this.state.newPanelModalVisible}
          noButton
          onClose={() => {
            this.handleCreatePanelModal('', false);
          }}
          dataPublicHeader
        >
          <CoveragePayorForm
            modelToEdit={this.state.modelToEdit}
            clearModelToEdit={() => this.setState({ modelToEdit: undefined })}
            saveModel={this.props.saveModel}
            onSave={
              (payor) => {
                const payorId = payor.get('_id');
                const payorIdList = this.props.value.get('coveragePayorIds', List());
                const modifiedPayorIdList = payorIdList.push(payorId);
                this.props.setValue(this.props.value.set('coveragePayorIds', modifiedPayorIdList));
                this.handleCreatePanelModal('', false);
              }
            }
            labelClassName=""
            autofocus="item_name"
            saveIsSticky
            noClearButton
          />
        </StatelessModal>
      </Fragment>
    );
  }
}

export default PanelDrugPriceInput;
