import React from 'react';
import { Menu, MainButton, ChildButton } from 'react-mfb';

import translate from '../../utils/i18n';

import type { Config, User, ToggleDebugModeFlag, DebugModeFlags } from '../../types';
import { colours } from '../../utils/css';
import { DOC_VALIDATION_ENABLED } from '../../constants';

type Props = {
  config: Config,
  user: User,
  toggleDebugModeFlag: ToggleDebugModeFlag,
  debugModeFlags: DebugModeFlags,
};
 
/**
 * Renders Chip that can be used for showing tag or small information and can be remove.
 * @returns {React.Component} The rendered component
*/
const DebugActionButton = ({ debugModeFlags, toggleDebugModeFlag }: Props) => {
  /**
   * Toggle the state of doc validation debug mode in the redux store
   * @returns {void}
   */
  const handleDocValidationDebugModeToggle = () => {
    toggleDebugModeFlag('docValidation');
  };

  return (
    <Menu key="debugActionButton" effect="slidein-spring" position="br" ref={(node) => { }}>
      <MainButton iconResting="fa fa-magic" iconActive="fa fa-times" />
      <ChildButton
        key="doc-validation-debug-toggle"
        icon="fa fa-file-text-o"
        label={`${translate('doc_validation_debug_mode')}: ${ debugModeFlags.docValidation ? 'ON' : 'OFF' }`}
        style={debugModeFlags.docValidation ? { backgroundColor: colours.green } : {}}
        onClick={() => handleDocValidationDebugModeToggle()}
        disabled={!DOC_VALIDATION_ENABLED}
      />
    </Menu>);
};

export default DebugActionButton;
