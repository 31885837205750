import React, { useState } from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import moment, { Moment } from 'moment';

import translate from '../../utils/i18n';
import AppointmentForm from '../patient/appointmentForm';
import Button from '../buttons/button';
import StatelessModal from '../modals/statelessModal';
import { getConfirmation } from '../../utils/utils';
import { updateAppointment } from '../../utils/api';
import { createSuccessNotification, createErrorNotification } from '../../utils/notifications';
import { logPatientScheduled } from '../../utils/logging';
import { alertEncounterStage, createEncounterAndBillModels, getConsultTypeName, getEncounterAttributes, saveEncounterAndRelatedModels } from '../../utils/encounters';
import { createPermission, hasPermission } from '../../utils/permissions';
import QueueButtonContainer from '../../containers/queueButtonContainer';
import { getStore } from './../../utils/redux';
import { setDebugModeData } from './../../actions';

import type { Config, SaveModel, User } from '../../types';
import type PatientModel from '../../models/patientModel';
import type EncounterModel from '../../models/encounterModel';
import type SalesItemModel from '../../models/salesItemModel';
import type AppointmentModel from '../../models/appointmentModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type EncounterStageModel from '../../models/encounterStageModel';

type Props = {
  user: User,
  patient: PatientModel,
  appointment: AppointmentModel,
  addModelToStore: (model) => void,
  salesItems: List<SalesItemModel>,
  coveragePayors: List<CoveragePayorModel>,
  saveModel: SaveModel,
  encounters: List<EncounterModel>,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  encounterStagesMap: Map<string, EncounterStageModel>,
  config: Config,
};

const PatientAppointmentInfoCardContent = glamorous.div({
  padding: '1.333rem',
  display: 'grid',
  gridTemplateColumns: 'minmax(250px, 20%) auto',
});

const PatientAppointmentInfoCardKey = glamorous.p({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  fontFamily: 'robotomedium',
  textTransform: 'uppercase',
}, ({ style }) => style);
const PatientAppointmentInfoCardValue = glamorous.p({
  gridColumnStart: 2,
  gridColumnEnd: 3,
  fontFamily: 'robotolight',
}, ({ style }) => style);

/**
 * Renders a component showing the teleconsult info card in the patient page
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
const PatientAppointmentInfoCard = (props: Props) => {
  const hasDeleteAppointmentPermission = hasPermission(props.user, List([createPermission('schedule_appointments', 'delete')]));
  const hasEConsultReadPermission = hasPermission(props.user, List([createPermission('e_consultation', 'read')]));
  const isEmailDelivered = props.appointment.isEmailDelivered();
  const consultMode = props.appointment.getConsultMode();

  const [isEditModalVisible, setEditModalVisible] = useState(false);


  /**
   * Converts the appointment data into appointment info key value pairs and renders it
   * @returns {Array<React.ReactNode>}
   */
  function renderAppointmentInfoTable() {
    const appointmentInfoData = [
      { key: 'scheduled_date', value: moment(props.appointment.get('start_timestamp')).format('DD-MM-YYYY') },
      { key: 'schedule_time', value: moment(props.appointment.get('start_timestamp')).format('HH:mm') },
      { key: 'econsult_video_link', value: props.appointment.get('tele_consult')?.url, hide: (!hasEConsultReadPermission || !isEmailDelivered || consultMode !== 'tele_consult') },
    ];

    return appointmentInfoData.reduce((elements, info) => {
      if (info.hide) {
        return elements;
      }
      return elements.push(
        <React.Fragment>
          <PatientAppointmentInfoCardKey style={info.style}>
            {translate(info.key)}
          </PatientAppointmentInfoCardKey>
          <PatientAppointmentInfoCardValue style={info.style}>
            {info.value}
          </PatientAppointmentInfoCardValue>
        </React.Fragment>,
      );
    }, List()).toArray();
  }

  /**
   * Handles click event on the teleconsultation start button and opens a new window to the video link
   * @returns {void}
   */
  function handleStartTeleconsultation() {
    if (props.appointment.isActive()) {
      updateAppointment(props.appointment, { status: 'fulfilled' })
        .then((resp) => {
          if (resp.ok) {
            props.addModelToStore(resp.model);
            return resp.model;
          }
          createErrorNotification('Something went wrong in moving to queue.');
          return null;
        })
        .then((appointmentModel: AppointmentModel) => {
          if (appointmentModel) {
            appointmentModel.getSalesItems(props.salesItems,
              props.encounterFlowMap, props.encounterStagesMap)
              .then((salesItems) => {
                const encounterAttributes = getEncounterAttributes(
                  'started',
                  appointmentModel.get('consult_type'),
                  appointmentModel.get('practitioner_id'),
                  undefined,
                  appointmentModel.get('_id'),
                  props.encounterFlowMap.get(appointmentModel.get('flow_id')),
                  props.encounterStagesMap,
                  appointmentModel,
                );
                createEncounterAndBillModels(props.patient, salesItems, props.coveragePayors,
                  encounterAttributes, props.config).then((models: List<Model>) => {
                  if (getStore().getState().debugModeFlags.docValidation) {
                    getStore().dispatch(setDebugModeData(
                      'docValidation',
                      (newModels: List<Model>) => saveEncounterAndRelatedModels(
                        newModels.find(model => model.get('type') === 'patient'),
                        newModels.find(model => model.get('type') === 'encounter'),
                        newModels.find(model => model.get('type') === 'bill'),
                        undefined,
                        newModels.filter(model => model.get('type') === 'bill_item').toArray(),
                      ).then((savedModels) => {
                        if (savedModels && savedModels.length) {
                          logPatientScheduled();
                          createSuccessNotification(translate('x_added_to_queue', { x: props.patient.get('patient_name') }));
                        }
                      }),
                      List(models),
                    ));
                    return Promise.resolve();
                  }

                  return saveEncounterAndRelatedModels(
                    models.find(model => model.get('type') === 'patient'),
                    models.find(model => model.get('type') === 'encounter'),
                    models.find(model => model.get('type') === 'bill'),
                    undefined,
                    models.filter(model => model.get('type') === 'bill_item').toArray(),
                  ).then((savedModels) => {
                    if (savedModels && savedModels.length) {
                      logPatientScheduled();
                      createSuccessNotification(translate('x_added_to_queue', { x: props.patient.get('patient_name') }));
                      const encounterFlow = props.encounterFlowMap.get(appointmentModel.get('flow_id'));
                      const stagemodel = encounterFlow?.getStages(props.encounterStagesMap)?.first() as EncounterStageModel;
                      alertEncounterStage(stagemodel, 'waiting-alert');
                    }
                  });
                });
              });
          }
        });
    }
    window.open(props.appointment.get('tele_consult')?.url, '_blank');
  }

  /**
   * shows a confirmation prompt and then proceeds to cancel the appointment
   * @returns {void}
   */
  function handleCancelAppointment() {
    getConfirmation(translate('confirm_cancel_appointment'))
      .then(
        () => updateAppointment(props.appointment, { status: 'cancelled' })
          .then((resp) => {
            if (resp.ok) {
              props.addModelToStore(resp.model);
              createSuccessNotification('Appointment was successfully cancelled');
            } else {
              createErrorNotification('Appointment could not be cancelled. Please try again later');
            }
          }),
      );
  }

  /**
   * Call's when click save on e-consult form.
   * @param {Moment} apptDate date,
   * @param {Moment} apptTime time
   * @param {string} apptEmail email address
   * @returns {void} The modal content to render.
   * @return {Promise<boolean>} true without error else false
   */
  function handleRescheduleAppointment(apptDate: Moment, apptTime: Moment, apptEmail: String = '') {
    const appointmentData = {
      start_timestamp: moment(apptDate)
        .hour(apptTime.hour())
        .minute(apptTime.minute())
        .second(0)
        .valueOf(),
    };
    return (props.patient.get('email') !== apptEmail
      ? props.saveModel(props.patient.set('email', apptEmail))
      : Promise.resolve({ ok: true }))
      .then((model) => {
        if (model.ok === false) {
          createErrorNotification('Appointment could not be rescheduled. Please try again later');
          return false;
        }
        return updateAppointment(props.appointment, appointmentData)
          .then((resp) => {
            if (resp.ok) {
              props.addModelToStore(resp.model);
              createSuccessNotification('Appointment successfully rescheduled');
              return true;
            }
            createErrorNotification('Appointment could not be rescheduled. Please try again later');
            return false;
          })
          .then((withoutError) => {
            setEditModalVisible(false);
            return withoutError;
          });
      });
  }

  return (
    <div className="o-card">
      <div className="o-card__header">
        <h1 data-public className="o-card__title">{translate(`${consultMode}_queue_info`)}</h1>
      </div>
      <div className="o-header-actions">
        <div className="u-flex-left u-margin-left--half-ws">
          <StatelessModal
            id="edit-appointment-time-button"
            buttonLabel={translate('edit_scheduled_time')}
            buttonClass="o-button o-button--small have-background u-margin-left--half-ws"
            visible={isEditModalVisible}
            setVisible={isVisible => setEditModalVisible(isVisible)}
            title={`${translate('schedule')} ${getConsultTypeName(props.appointment.get('consult_type'), props.salesItems)} | ${translate(props.appointment.get('consult_mode'))}`}
            dataPublicHeader
          >
            <AppointmentForm
              consultationMode={consultMode}
              email={props.patient.get('email')}
              timestamp={props.appointment.get('start_timestamp')}
              emailDescription={translate(`${consultMode}_email_placeholder`)}
              onSave={(date, time, email) => handleRescheduleAppointment(date, time, email)}
              patient={props.patient}
              encounters={props.encounters}
            />
          </StatelessModal>
          <Button
            className="o-button o-button--small o-button--danger have-background u-margin-right--half-ws u-margin-left--half-ws"
            onClick={() => handleCancelAppointment()}
            disabled={!hasDeleteAppointmentPermission || !props.appointment.isActive()}
            dataPublic
          >{ translate('cancel_appointment') }
          </Button>
        </div>
      </div>
      <PatientAppointmentInfoCardContent>
        {
          renderAppointmentInfoTable()
        }
      </PatientAppointmentInfoCardContent>
      {
        (consultMode === 'tele_consult') &&
        <div className="o-card__footer u-padding--half-ws">
          {props.appointment.isActive() &&
            !props.appointment.get('practitioner_id') ?
              <QueueButtonContainer
                buttonClass="o-button o-button--small o-button--full-width have-background"
                buttonLabel={translate('start_econsultation')}
                patient={props.patient}
                encounterDoctor={props.appointment.get('practitioner_id')}
                isFromPatientList
                appointment={props.appointment}
                onSuccess={() => {
                  window.open(props.appointment.get('tele_consult')?.url, '_blank');
                }}
                disabled={!hasEConsultReadPermission || !isEmailDelivered}
              />
            :
              <Button
                className="o-button o-button--small o-button--full-width have-background"
                onClick={() => handleStartTeleconsultation()}
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: 3,
                }}
                disabled={!hasEConsultReadPermission || !isEmailDelivered}
                dataPublic
              >
                {translate('start_econsultation')}
              </Button>
          }
        </div>
      }
    </div>
  );
};
export default PatientAppointmentInfoCard;
