// Component for a multi select dropdown.
import React from 'react';
import Select from './select';

import Label from './label';
import { formatSelectOptions } from './../../utils/inputs';

import type { HTMLStyle, SelectOpts } from './../../types';

type Props = {
  closeOnSelect?: boolean,
  id: string,
  label: string,
  labelClassName?: string,
  value: SelectOpts,
  options: SelectOpts,
  onChange: (newValues: SelectOpts) => void,
  required?: boolean,
  style?: HTMLStyle,
  disabled?: boolean,
  description?: string,
  isValueCaseInsensitive?: boolean,
  creatable?: boolean, // If true user can add own values.
  onCreateOption?: (option: string) => void,
  createOptionPromptFactory?: (label: string) => string,
  showNewOptionAtTop: boolean,
};

/**
   * MultiSelect
   *
   * @namespace MultiSelect
   * @returns {React.Component} - A MultiSelect component.
   */
const MultiSelect = ({
  id, label, value, options, onChange, labelClassName,
  closeOnSelect = false, required = false, style = {}, disabled = false, description,
  isValueCaseInsensitive = false,
  creatable = false,
  onCreateOption,
  createOptionPromptFactory,
  showNewOptionAtTop = false,
}: Props) => (
  <div style={style}>
    <Label
      className={labelClassName}
      id={id}
      label={label}
      invalid={required && (!value || value.length === 0)}
    />
    {
      description &&
      <p className="o-sub-label">{description}</p>
    }
    <Select
      id={id}
      value={value}
      options={formatSelectOptions(options, isValueCaseInsensitive)}
      onValueChanged={onChange}
      isMulti
      hideLabel
      blurInputOnSelect={closeOnSelect}
      className={required && (!value || value.length === 0) ? 'js-border-invalid' : undefined}
      disabled={disabled}
      creatable={creatable}
      onCreateOption={onCreateOption}
      createOptionPromptFactory={createOptionPromptFactory}
      showNewOptionAtTop={showNewOptionAtTop}
    />
  </div>
);

export default MultiSelect;
