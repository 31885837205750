import React from 'react';

import translate from './../../utils/i18n';
import TextArea from './../inputs/textarea';
import Input from './../inputs/input';
import SaveButton from './../buttons/saveButton';
import LoadingIndicator from './../loadingIndicator';
import { createSuccessNotification, createStaticNotification } from './../../utils/notifications';
import { dataURItoBlob, saveBlob, fetchTextAsset, getConfirmation } from './../../utils/utils';
import { logMessage, debugPrint } from './../../utils/logging';
import Header from './../header/header';
import SavePrompt from './../prompts/savePrompt';
import UnsavedDataPrompt from './../prompts/unsavedDataPrompt';
import FormError from './../formError';
import { getHighestCasenoteOrder } from './../../utils/db';
import { CASENOTE_ORDER_INCREMENT } from './../../constants';

import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import type { SaveModel, ReactRouterLocation } from './../../types';

type Props = {
  caseNoteFile?: CaseNoteFileModel,
  isReferralMode: boolean,
  saveModel: SaveModel,
  categoryID?: string,
  isOnline: boolean,
  patientID: string,
  history?: {
    location: {
      search: string,
    }
  }
};

type State = {
  title: string,
  content: string, // The value of the casenote asset.
  isLoading: boolean,
  changesMade: boolean,
  isSaving: boolean,
  errorMessage?: string,
};

/**
 * An editor component for a text casenote.
 * @class TextCasenoteEditor
 * @extends {React.Component<Props, State>}
 */
class TextCasenoteEditor extends React.Component<Props, State> {
  /**
   * Creates an instance of TextCasenoteEditor.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      title: props.caseNoteFile ? props.caseNoteFile.get('title', '') : '',
      content: '', // The value of the casenote asset.
      isLoading: true,
      changesMade: false,
      isSaving: false,
    };
    if (props.caseNoteFile) {
      const assetID = props.caseNoteFile.getAssetID(0);
      if (assetID) {
        this.fetchAsset(assetID);
      }
    }
  }

  /**
   * Fetches an asset and sets it to state.content
   * @param {string} assetID An asset ID.
   * @returns {void}
   */
  fetchAsset(assetID: string) {
    fetchTextAsset(assetID)
      .then(content => this.setState({ content: content.trim(), isLoading: false }));
  }

  /**
   * Handles next props and fetches a new asset if one is beings passed down.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    const nextCasenote = nextProps.caseNoteFile;
    if (!this.props.caseNoteFile && nextCasenote) {
      this.setState({ isLoading: false });
    }
    if (nextCasenote) {
      this.setState({ title: nextCasenote.get('title', '') });
      const nextAsset = nextCasenote.getAssetID(0);
      if (nextAsset
        && (!this.props.caseNoteFile || this.props.caseNoteFile.getAssetID(0) !== nextAsset)) {
        this.setState({ isLoading: true });
        this.fetchAsset(nextAsset);
      }
    }
  }

  /**
   * Checks for unsaved changes and asks the user if they want to save first. Returns a promise
   * with a boolean indicating success of the save. If the user doesn't want to save the return is
   * also true to indicate no issues.
   * @param {boolean} newNote Is a new note?
   * @param {boolean} cancelAbortsRouter If true then a cancel action at the confirm dialogue
   * @returns {Promise<boolean>}
   */
  confirmBeforeRoute(newNote?: boolean, cancelAbortsRouter: boolean = false): Promise<boolean> {
    if (!this.props.isOnline) {
      Promise.resolve(false);
    }
    if ((newNote || this.state.changesMade)) {
      return getConfirmation(translate('confirm_save_casenote_before_route'))
        .then(
          () => this.onSaveClicked(),
          () => false,
        );
    }
    return Promise.resolve(!cancelAbortsRouter);
  }

  /**
   * Handles the saving of the casenote.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    const casenote = this.props.caseNoteFile;
    if (casenote && this.state.content.length > 0) {
      this.setState({ isSaving: true });
      return getHighestCasenoteOrder(this.props.patientID)
        .then(order => saveBlob(dataURItoBlob(`data:text/plain,${this.state.content}`))
          .then(asset => this.props.saveModel(casenote.set({
            assets: [asset],
            title: this.state.title,
            order: order + CASENOTE_ORDER_INCREMENT,
          })))
          .then((model) => {
            this.setState({ changesMade: false, isSaving: false, errorMessage: undefined });
            if (model.attributes.edited_by.length > 1) {
              createSuccessNotification(translate('casenote_update_success'));
            } else {
              createSuccessNotification(translate('casenote_creation_success'));
            }
            if (location.hash.endsWith('/add-text')) {
              location.hash = `/patient/${model.get('patient_id')}/casenotes/${model.get('_id')}/edit`;
            }
            return true;
          })
          .catch((error) => {
            debugPrint(error, 'error');
            logMessage('Casenote save failed', 'error');
            this.setState({ isSaving: false, errorMessage: translate('casenote_save_failed') });
            createStaticNotification(
              translate('casenote_save_failed'),
              translate('casenote_save_failed_desc'), true,
            );
            return false;
          }));
    }
    this.setState({ errorMessage: translate('fill_required_fields') });
    return Promise.resolve(false);
  }

  /**
   * Logic check to see if the pre-route prompt should display. Bases this off if there have been
   * changes made and whether or not the user is in referral mode.
   * @param {ReactRouterLocation} nextLocation The next location if the prompt passes.
   * @param {CaseNoteFileModel} casenote The current casenote.
   * @returns {boolean} True if the prompt should be displayed.
   */
  shouldPromptDisplay(nextLocation: ReactRouterLocation, casenote: CaseNoteFileModel): boolean {
    if (this.state.changesMade) {
      if (this.props.isReferralMode && nextLocation.search.indexOf('referralId') !== -1) {
        return false; // Don't check when navigating from referral section to referral section.
      } else if (
        !this.props.isReferralMode &&
        nextLocation.search &&
        nextLocation.search.indexOf('referralId') !== -1 &&
        nextLocation.search.split('referralId=')[1].startsWith(casenote.get('_id'))
      ) {
        return false; // Don't check when navigating to referral as the check is done by the referral button.
      } else if (
        this.props.isReferralMode &&
        nextLocation.search &&
        nextLocation.search.indexOf('referralId') === -1 &&
        nextLocation.pathname.indexOf(`${casenote.get('_id')}/edit`) !== -1
      ) {
        return false; // Don't check when returning to editor from referral mode as check is done by the close referral button.
      }
      return true;
    }
    return false;
  }

  /**
   * Renders the correct prompt for when the user routes away from the page. This changes based on
   * whether or not the user is online.
   * @param {CaseNoteFileModel} casenote The current casenote.
   * @returns {React.Component}
   */
  renderPreRoutePrompt(casenote: CaseNoteFileModel) {
    if (this.props.isOnline) {
      return (
        <SavePrompt
          onSaveClicked={() => this.onSaveClicked()}
          when={(currentLocation, nextLocation) => this.shouldPromptDisplay(nextLocation, casenote)}
        />
      );
    }
    // If the user is offline there is no way to save the casenote, so warn them that data will be
    // lost if they continue.
    return (
      <UnsavedDataPrompt
        when={(currentLocation, nextLocation) => this.shouldPromptDisplay(nextLocation, casenote)}
      />
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const casenote = this.props.caseNoteFile;
    if (!casenote || this.state.isLoading) {
      return <LoadingIndicator />;
    }
    const categoryID = this.props.categoryID ? this.props.categoryID : '0_KLINIFY_NOTES';
    const prefix = `/patient/${casenote.get('patient_id')}`;
    let fromCategory;
    if (this.props.history && this.props.history.location) {
      const searchParams = new URLSearchParams(this.props.history.location.search);
      fromCategory = searchParams.get('fromCategory');
    }
    const backLink = casenote.hasBeenSaved() ?
      `${prefix}/categories/${fromCategory || categoryID}/${casenote.get('_id')}` :
      prefix;
    return (
      <section className="c-casenote-editor">
        <Header label={translate('notepad')} backLink={backLink} isOffline={!this.props.isOnline}>
          {
            this.props.isReferralMode ?
              <div
                onClick={() => {
                  if (!this.state.changesMade) {
                    location.hash = `${prefix}/casenotes/${casenote.get('_id')}/edit`;
                  } else {
                    this.confirmBeforeRoute()
                      .then((wasSuccessful) => {
                        if (wasSuccessful) {
                          location.hash = `${prefix}/casenotes/${casenote.get('_id')}/edit`;
                        }
                      });
                  }
                }}
                className="c-top-bar__button"
                style={{ marginLeft: 'auto' }}
              >
                <i className="fa fa-arrows-alt" />
              </div> :
              <div
                onClick={() => {
                  if (!this.state.changesMade && casenote.hasBeenSaved()) {
                    location.hash = `${prefix}/categories/${casenote.get('category')}/${casenote.get('_id')}?referralId=${casenote.get('_id')}`;
                  } else {
                    this.confirmBeforeRoute(!casenote.hasBeenSaved(), true)
                      .then((wasSuccessful) => {
                        if (wasSuccessful) {
                          location.hash = `${prefix}/categories/${casenote.get('category')}/${casenote.get('_id')}?referralId=${casenote.get('_id')}`;
                        }
                      });
                  }
                }}
                className="c-top-bar__button"
                style={{ marginLeft: 'auto' }}
              >
                R
              </div>
        }
        </Header>
        <div id="text-editor-container" className="o-scrollable-container" style={{ height: this.props.isReferralMode ? 'calc(50vh - 50px)' : 'calc(100vh - 50px)' }}>
          <div className="o-card o-card--no-shadow">
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">{translate('text_editor')}</h1>
            </Header>
            <div className="u-margin--standard u-flex-column">
              {this.renderPreRoutePrompt(casenote)}
              {
                this.state.errorMessage &&
                <FormError containerElementID="text-editor-container">
                  {this.state.errorMessage}
                </FormError>
              }
              <Input
                id="note-title"
                label={translate('title')}
                value={this.state.title}
                onValueChanged={title => this.setState({ title, changesMade: true })}
                fullWidth
              />
              <TextArea
                id="note-text"
                label={translate('text')}
                value={this.state.content}
                onValueChanged={content => this.setState({ content, changesMade: true })}
                minRows={16}
              />
              <SaveButton
                isSaving={this.state.isSaving}
                onClick={() => this.onSaveClicked()}
                disabled={!this.props.isOnline || !this.state.changesMade || this.state.content.trim() === ''}
                className="u-margin-bottom--1ws u-flex-right"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TextCasenoteEditor;
