import React from 'react';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import type { SortingRule } from 'react-table';

import translate from './../../utils/i18n';
import { UNICODE } from './../../constants';
import Table from './../table/table';
import flagTableHOC from '../hoc/flagTable';
import { prettifyDate, prettifyTime } from './../../utils/time';
import { getEncounterFlag, alertEncounterStage, getEncounterCreatedBy } from './../../utils/encounters';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import getPanelNameForPatient from './../../utils/coveragePayors';
import { playSound } from './../../utils/audio';
import QueueButtonContainer from './../../containers/queueButtonContainer';
import Button from './../buttons/button';
import Header from './../header/header';
import TableCollapseIcon from './../icons/tableCollapseIcon';
import { transformColumnKeys, transformFilteredColumns } from '../../utils/utils';
import TableColumnsSettings from '../table/tableColumnsSettings';
import OptionButton from '../patient/optionButton';
import { getModelMapFromList } from '../../utils/models';
import { getWaitingEncounterTableColumns } from './columns';
import { filterWithConditions } from '../../utils/tableFilter';
import { FilteringRule } from './tableFilter';

import type EncounterModel from './../../models/encounterModel';
import type PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type { SaveModel, Config, User, TableFlagType, CustomColumn, SaveModels, Column } from './../../types';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ReceivableModel from './../../models/receivableModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type BillModel from '../../models/billModel';

type Props = {
  config: Config,
  encounters: List<EncounterModel>,
  patients: Map<string, PatientStubModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  user: User,
  outstandingReceivables: List<ReceivableModel>,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
  playAudioAlert: boolean,
  updateConfig: (config: Config) => void,
  encounterStageMap: Map<string, EncounterStageModel>,
  encounterFlowMap: Map<string, EncounterFlowModel>,
  bills: Map<string, BillModel>,
  saveModels: SaveModels,
  currentEncounters: List<EncounterModel>,
  tableProps: {
    sorted: SortingRule[],
    filteringRule: FilteringRule,
  }
};


type State = {
  currentSaving: string, // encounter id of currently being saved encounter
  isTableCollapsed: boolean,
  columns: Array<CustomColumn>,
  selectedEncounter?: EncounterModel,
}

/**
 * Gets an array of columns for react-table.
 * @param {Config} config App config
 * @returns {object[]} An array of objects each representing a column in the table.
 */
const getColumns = (config: Config) => getWaitingEncounterTableColumns(config);

const FlagTable = flagTableHOC(Table);

/**
 * A table containing all of today's encounter with a waiting state.
 * @class WaitingEncounterTable
 * @extends {React.Component<Props, State>}
 */
class WaitingEncounterTable extends React.Component<Props, State> {
  interval: void | IntervalID;

  columns: Array<Column>;

  /**
   * Creates an instance of WaitingEncounterTable.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.columns = getColumns(props.config);
    this.state = {
      currentSaving: '',
      isTableCollapsed: false,
      columns: transformColumnKeys(this.columns).filter(i => !i.hideDefault),
      selectedEncounter: undefined,
    };
    this.interval = setInterval(() => this.forceUpdate(), 30000); // Refresh every 30 secs so waiting time updates.
  }

  /**
   * Clean up interval on unmount.
   * @returns {void}
   */
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * Plays an alert sound when a new encounter arrives in a stage,
   * provided the stage has alert enabled
   * @param {Props} prevProps Prev Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.props.encounters.size !== prevProps.encounters.size) {
      const newEncounters = this.props.encounters.filter(newEncounter => !prevProps.encounters.some(oldEncounter => oldEncounter.get('_id') === newEncounter.get('_id')));
      if (this.props.playAudioAlert && newEncounters.size) { // Completed encounter
        playSound(this.props.config.getIn(['patient_overview', 'alert_sound'], 'announcement_down.mp3'));
      } else if (newEncounters.size) { // In-progress encounters
        newEncounters.forEach((encounter) => {
          const encounterStages = encounter.getActiveStages();
          const { currentStageIndex } = encounter.getCurrentStageIndex();
          const currentStage = encounterStages.get(currentStageIndex);
          const encounterStageModel: EncounterStageModel = this.props.encounterStageMap.get(currentStage?.stage_id);
          alertEncounterStage(encounterStageModel, 'waiting_alert');
        });
      }
    }
    if (this.interval) { // Reset timer if updated
      clearInterval(this.interval);
      this.interval = setInterval(() => this.forceUpdate(), 30000);
    }
  }

  /**
   * @param {EncounterModel} encounter Encounter model
   * @returns {React.ReactNode}
   */
  renderOptionButton(encounter: EncounterModel) {
    return (
      <OptionButton
        encounter={encounter}
        encounterFlowMap={this.props.encounterFlowMap}
        encounters={this.props.currentEncounters}
        config={this.props.config}
        practitioners={this.props.practitioners}
        bills={this.props.bills}
        encounterStageMap={this.props.encounterStageMap}
        salesItems={getModelMapFromList(this.props.salesItems)}
        coveragePayors={this.props.coveragePayors}
        saveModel={this.props.saveModel}
        updateConfig={this.props.updateConfig}
        saveModels={this.props.saveModels}
        user={this.props.user}
      />
    );
  }

  /**
   * Returns an object with appropriate fields for a Table row populated from an EncounterModel.
   * @param {EncounterModel} encounter The encounter the button is for.
   * @returns {object} A table row object.
   */
  getTableRow(encounter: EncounterModel) {
    const patient = this.props.patients.get(encounter.get('patient_id'));
    let doctor = encounter.getDoctorName(this.props.practitioners);
    if (doctor === UNICODE.EMDASH || !doctor?.length) {
      doctor = translate('unassigned');
    }
    const encounterFlag: TableFlagType = getEncounterFlag(
      encounter,
      patient,
      this.props.outstandingReceivables,
    );
    return {
      flag: encounterFlag.active,
      flagData: { color: encounterFlag.color },
      tooltipData: encounterFlag.active && encounterFlag.tooltips,
      q_no: encounter.getQueueNumber(),
      arrival_time: prettifyTime(encounter.getArrivalTime()),
      arrival_date: prettifyDate(encounter.getArrivalTime()),
      // Note: The `waiting_time` format has dependecy with overview table filter
      waiting_time: translate('x_minutes', { x: encounter.getMinutesSinceLastStageEventUpdate() }),
      name: patient ? patient.get('patient_name', UNICODE.EMDASH) : translate('loading...'),
      ic: patient ? patient.get('ic', UNICODE.EMDASH, false) : translate('loading...'),
      case_id: patient ? patient.get('case_id', UNICODE.EMDASH, false) : translate('loading...'),
      location: encounter.getValue('location') || UNICODE.EMDASH,
      sex: patient ? patient.get('sex', UNICODE.EMDASH, false) : translate('loading...'),
      panel_or_cash: patient ? patient.getPaymentType() : translate('loading...'),
      panel_name: getPanelNameForPatient(patient, this.props.coveragePayors),
      doctor,
      creator: getEncounterCreatedBy(encounter),
      encounter_flow: encounter.getEncounterType(this.props.salesItems),
      encounter_stage: encounter.getValue('name') || UNICODE.EMDASH,
      action: (
        <PermissionWrapper permissionsRequired={List([createPermission('queue_patient_for_today', 'update')])} user={this.props.user}>
          <div className="u-flex-row">
            <Button
              className="o-button o-button--small"
              onClick={(event) => {
                event.stopPropagation();
                const encounterLocation = encounter.getValue('location');
                const encounterDoctor = encounter.getValue('doctor');
                const stageModel = this.props.encounterStageMap.get(encounter.getValue('stage_id'));
                const stageLocations = stageModel && stageModel.get('locations');
                if (encounterDoctor && !encounterLocation && stageLocations?.length === 1) {
                  encounter.addStageEvent('started');
                  if (encounter.highestEventIs('arrived')) {
                    encounter.addEvent('started');
                  }
                  return this.props.saveModel(encounter.updateCurrentStageOccurrenceAttributes({ location: stageLocations[0] }))
                    .finally(() => this.setState({ currentSaving: '' }));
                }
                if (!encounterDoctor || !encounterLocation) {
                  this.setState({ selectedEncounter: encounter });
                } else {
                  this.setState({ currentSaving: encounter.get('_id') });
                  encounter.addStageEvent('started');
                  if (encounter.highestEventIs('arrived')) {
                    encounter.addEvent('started');
                  }
                  return this.props.saveModel(encounter)
                    .finally(() => this.setState({ currentSaving: '' }));
                }
              }}
              disabled={this.state.currentSaving === encounter.get('_id')}
              dataPublic
            >
              {translate('see_patient')}
            </Button>
            {this.renderOptionButton(encounter)}
          </div>
        </PermissionWrapper>
      ),
      link: `/patient/${encounter.get('patient_id')}`,
    };
  }

  /**
   * Returns memoized data for the table
   * @param {List<EncounterModel>} encounters Encounter models
   * @returns {Array<Row>}
   */
  getTableData =
    memoizeOne((encounters: List<EncounterModel> = List(), filteringRule: FilteringRule) => {
      const tableData = encounters.map(encounter => this.getTableRow(encounter)).toArray();
      return {
        tableData,
        filteredTableData: filterWithConditions(tableData, filteringRule),
      };
    });

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { encounters, config, updateConfig, tableProps } = this.props;
    const { isTableCollapsed, selectedEncounter } = this.state;
    const transformedColumnKeys = transformColumnKeys(this.columns);
    const { tableData, filteredTableData } = this.getTableData(this.props.encounters, tableProps.filteringRule);
    return (
      <section className="o-card">
        <Header className="o-card__header">
          <h1 className="o-card__title o-card__title--no-right-padding u-margin-right--half-ws" data-public>{translate('waiting')}</h1>
          <span>-&nbsp;&nbsp;{encounters.size}</span>
          <span className="o-card__header__right-aligned-text">
            <TableColumnsSettings
              config={config}
              configFieldName="waiting_encounter"
              originalColumns={List(transformedColumnKeys)}
              columns={transformedColumnKeys}
              onUpdateColumns={columns => this.setState({ columns })}
              updateConfig={updateConfig}
              isCompact
            />
            <TableCollapseIcon
              id="waiting_encounter"
              isTableCollapsed={isTableCollapsed}
              onClick={() =>
                this.setState({ isTableCollapsed: !isTableCollapsed })
              }
            />
          </span>
        </Header>
        {!isTableCollapsed &&
          <FlagTable
            columns={transformFilteredColumns(this.columns, this.state.columns)}
            data={filteredTableData}
            defaultSorted={[{ id: 'arrival_time', desc: false }]}
            noDataText={translate(tableProps?.filteringRule?.filterRules.length ? 'no_filter_result' : 'no_patients')}
            sorted={this.props.tableProps.sorted}
          />}
        {selectedEncounter &&
          <QueueButtonContainer
            patient={this.props.patients.find(p => p.get('_id') === selectedEncounter.get('patient_id'))}
            isFromPatientList
            encounterLocation={selectedEncounter.getValue('location')}
            encounterID={selectedEncounter.get('_id')}
            encounterDoctor={selectedEncounter.getValue('doctor')}
            onClose={() => {
              this.setState({ selectedEncounter: undefined });
            }}
            modalVisible
            noButton
          />
        }
      </section>
    );
  }
}

export default WaitingEncounterTable;
