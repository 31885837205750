import React, { useState, useRef } from 'react';
import { List } from 'immutable';
import { A } from 'glamorous';
import Header from '../header/header';
import InfoHeader from '../header/infoHeader';
import translate from '../../utils/i18n';
import TableColumnsSettings from '../table/tableColumnsSettings';
import SelectTable from '../table/selectTable';
import type APIError from '../../utils/apiError';
import type { Config, Column, User, Row } from '../../types';
import { UNICODE } from '../../constants';
import {
  renderWithLink,
  dropDownFilterMethod,
  renderAlignedCellWithLink,
} from '../../utils/tables';
import { sortByNumber } from '../../utils/comparators';
import { filterDropDown } from '../../utils/filters';
import { transformFilteredColumns, transformColumnKeys, isLoading } from '../../utils/utils';
import CampaignForm from './campaignForm';
import Button from '../buttons/button';
import flagTable from '../hoc/flagTable';
import { createPermission, hasPermission } from '../../utils/permissions';
import PermissionWrapper from '../permissions/permissionWrapper';
import { getUpdateConfirmation } from '../../utils/patientCampaign';

import type PatientCampaignSetModel from '../../models/patientCampaignSetModel';
import Confirm from '../prompts/confirm';

type Props = {
  isFetching: boolean;
  currentDataViewsError?: APIError;
  config: Config;
  updateConfig: (config: Config) => void;
  campaignSets: List<PatientCampaignSetModel>;
  updateCampaignSets: (campaigns: List<PatientCampaignSetModel>, action: string) => Promise<void>;
  readCampaignSetMap: Map<string, { lastEditedTime: number, isNew: boolean}>;
  user: User,
};

const UNREAD_FLAG_VALUE = {
  color: 'dodgerblue',
  tooltips: [{
    header: translate('new_campaign_set'),
    content: translate('campaign_set_recently_added'),
  }],
};

const SelectableFlagTable = flagTable(SelectTable);

/**
 * Renders Med advisor campaign sets
 * @returns {React.SFC}
 */
const MedadvisorCampaignSets = ({
  isFetching,
  config,
  updateConfig,
  campaignSets,
  updateCampaignSets,
  readCampaignSetMap,
  user,
}: Props) => {
  const [fileSelectedForDownload, setFileSelectedForDownload] = useState<undefined | { name: string, url: string}>(undefined);
  const COLUMNS: Array<Column> = [
    {
      accessor: 'name',
      Header: translate('name'),
      filterable: true,
      Cell: renderWithLink,
    },
    {
      accessor: 'campaigns',
      Header: `# ${translate('campaigns')}`,
      headerClassName: 'u-flex-justify-content-right',
      sortMethod: sortByNumber,
      Cell: renderAlignedCellWithLink,
      align: 'right',
    },
    {
      accessor: 'sent',
      Header: `# ${translate('sent')}`,
      headerClassName: 'u-flex-justify-content-right',
      sortMethod: sortByNumber,
      Cell: renderAlignedCellWithLink,
      align: 'right',
    },
    {
      accessor: 'queued',
      Header: `# ${translate('queued')}`,
      headerClassName: 'u-flex-justify-content-right',
      sortMethod: sortByNumber,
      Cell: renderAlignedCellWithLink,
      align: 'right',
    },
    {
      accessor: 'status',
      Header: translate('status'),
      filterable: true,
      filterFromOptions: true,
      Cell: renderWithLink,
      filterMethod: filterDropDown,
      Filter: ({ filter, onChange }) =>
        dropDownFilterMethod(filter, onChange, ['active', 'inactive'].map(s => ({ value: s, label: s }))),
      useSecondaryData: true,
      renderFromLink: true,
    },
    {
      accessor: 'download',
      Header: translate('downloads'),
      sortable: false,
      maxWidth: 150,
      Cell: ({ row } : { row: Row }) => {
        const downloadLinks = row._original.downloads;
        if (row._original.downloads.length < 1) {
          return UNICODE.EMDASH;
        }
        return (<div className="u-flex-column o-table__cell o-table__cell--linebreak">
          {
            downloadLinks.map(({ name, url }: {name: string, url: string}) => {
              const fileName = name || decodeURI(url.substring(url.lastIndexOf('/') + 1));
              return (
                <Button
                  key={`${fileName}-${url.toLowerCase().trim().split('')
                    .reduce((a, b) => { const c = ((a << 5) - a) + b.charCodeAt(0); return c & c; }, 0)}`}
                  className="o-text-button o-button-url u-text-align-left u-margin-left--zero"
                  onClick={() => setFileSelectedForDownload({ name: fileName, url })}
                  dataPublic
                >
                  {fileName}
                </Button>
              );
            })
          }
                </div>);
      },
    },
    { accessor: 'view', Header: '', sortable: false, show: true, width: 80 },
  ];
  const [columns, setColumns] = useState(transformColumnKeys(COLUMNS));
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [selectedCamapaignSet, setSelectedCamapaignSet] = useState<PatientCampaignSetModel | null>(null);
  const tableRef = useRef<SelectTable>(null);

  /**
   * Clears selected rows in table
   * @returns {void}
   */
  const resetTableSelection = () => tableRef?.current?.resetSelection();

  /**
   * Handles the action
   * @param {string} action The  menu action
   * @returns {void}
   */
  const handleMenuSelection = (action: string) => {
    const selectedCampaignSets = campaignSets.filter(set => selectedRows.some(row => row === set.get('_id')));
    return getUpdateConfirmation(true, action, () => {
      updateCampaignSets(List(selectedCampaignSets), action)
        .then(resetTableSelection);
    });
  };

  /**
   * @returns {Array<Row>}
   */
  const getRows = () => campaignSets.map((campaign) => {
    const { sent, queued } = campaign.get('stats', {});
    const showFlag = !readCampaignSetMap.has(campaign.get('_id'));
    return {
      _id: campaign.get('_id'),
      flag: showFlag,
      flagData: { color: UNREAD_FLAG_VALUE.color },
      tooltipData: UNREAD_FLAG_VALUE.tooltips,
      name: campaign.get('name'),
      campaigns: campaign.get('campaign_count'),
      sent: isFetching ? translate('loading...') : sent,
      queued: isFetching ? translate('loading...') : queued,
      status: translate(campaign.getStatus().toLowerCase()),
      downloads: campaign.get('downloads') || [],
      view: (
        <Button
          className="o-text-button o-text-button--contextual"
          onClick={() => setSelectedCamapaignSet(campaign)}
          dataPublic
        >
          {translate('view')}
        </Button>
      ),
      link: `/campaigns/medadvisor/${campaign.get('_id')}`,
    };
  }).toArray();

  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      { fileSelectedForDownload && <Confirm
        show={Boolean(fileSelectedForDownload)}
        cancel={() => setFileSelectedForDownload(undefined)}
        proceedElement={
          <a
            className="o-button o-button--small"
            download
            target="_blank"
            href={fileSelectedForDownload?.url}
            onClick={() => setFileSelectedForDownload(undefined)}
          >
            {translate('confirm')}
          </a>
        }
        confirmation={translate('confirm_file_download', { name: fileSelectedForDownload?.name || '' })}
        footerSaveButtonName={translate('confirm')}
      />}
      <Header className="o-header" dataPublic>
        <h1 className="u-text-capitalised">
          {translate('medadvisor_campaigns')}
        </h1>
      </Header>
      <InfoHeader>
        Find out more about MedAdvisor Campaigns{' '}
        <A
          className="u-font-bold"
          css={{ textDecoration: 'underline', color: '#fff' }}
          href="https://www.klinify.com/medadvisor-campaigns"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </A>
      </InfoHeader>
      <div className="o-card u-margin-bottom--4ws">
        <div className="u-flex-row o-card__header">
          <Header className="o-card__header">
            <h1 className="o-card__title">{ translate('campaign_sets') }</h1>
          </Header>
          <TableColumnsSettings
            config={config}
            configFieldName="medadvisor_campaign_sets"
            originalColumns={List(transformColumnKeys(COLUMNS))}
            columns={columns}
            onUpdateColumns={cols => setColumns(cols)}
            updateConfig={updateConfig}
          />
        </div>
        <PermissionWrapper permissionsRequired={List([createPermission('sms_campaigns', 'update')])} user={user}>
          <div className="o-header-actions">
            <div className="u-flex-left u-margin-left--half-ws">
              <Button
                dataPublic
                className="o-button o-button--small have-background u-margin-left--half-ws"
                onClick={() => handleMenuSelection('activate')}
                disabled={selectedRows.every((r) => {
                  const campaign = campaignSets.find(c => c.get('_id') === r);
                  const isCampaignGloballyActive = true;
                  return !campaign || campaign.get('is_active') || !isCampaignGloballyActive;
                })}
              >
                {translate('activate')}
              </Button>
              <Button
                dataPublic
                className="o-button o-button--small have-background u-margin-left--half-ws"
                onClick={() => handleMenuSelection('deactivate')}
                disabled={selectedRows.every((r) => {
                  const campaign = campaignSets.find(c => c.get('_id') === r);
                  const isCampaignGloballyActive = true;
                  return !(campaign && campaign.get('is_active')) || !isCampaignGloballyActive;
                })}
              >
                {translate('deactivate')}
              </Button>
            </div>
          </div>
        </PermissionWrapper>
        <SelectableFlagTable
          ref={tableRef}
          columns={transformFilteredColumns(COLUMNS, columns)}
          data={getRows()}
          showPagination
          loading={!campaignSets.size}
          defaultSorted={[{ id: 'name', desc: false }]}
          getSelectedRows={(value: Array<string>) => setSelectedRows(value)}
        />
      </div>
      {selectedCamapaignSet && <CampaignForm
        isVisible={Boolean(selectedCamapaignSet)}
        hideModal={() => setSelectedCamapaignSet(null)}
        selectedCampaignSet={selectedCamapaignSet}
        user={user}
        title={translate('view_campaign_set')}
        hasUpdatePermission={hasPermission(user, List([createPermission('sms_campaigns', 'update')]))}
        onSave={(campaign, action, newStatus) => {
          if (newStatus) {
            return getUpdateConfirmation(true, newStatus, () =>
              updateCampaignSets(List([campaign]), newStatus));
          }
          return updateCampaignSets(List([campaign]), action);
        }}
      />}
    </section>
  );
};

export default MedadvisorCampaignSets;
