import { connect } from 'react-redux';
import { List } from 'immutable';
import { saveModelsFactory } from './../utils/redux';
import PatientDetails from '../components/patientDetails/patientDetails';
import { getModelMapFromList, getOrderedModelMapFromList } from '../utils/models';
import { compareByAlphabeticalOrder } from '../utils/comparators';

import type { Dispatch, State } from './../types';

/**
   * @param {Object} state Current app state.
   * @param {Object} ownProps props passed from patient container
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (
  { encounterFlows, encounterStages, practitioners }: State,
  ownProps: Partial<State>,
) => ({
  encounterFlowMap: getOrderedModelMapFromList(encounterFlows.sort((a, b) => compareByAlphabeticalOrder(a.get('name'), b.get('name')))),
  encounterStagesMap: getOrderedModelMapFromList(encounterStages),
  practitioners,
  bills: getModelMapFromList(ownProps.bills || List()),
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
