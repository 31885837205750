import React, { Fragment } from 'react';

import Input from './../inputs/input';
import NewPasswordInput from './../inputs/newPasswordInput';
import { changePassword, changeOwnPassword } from './../../utils/userManagement';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import SaveButton from './../buttons/saveButton';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import ModalFooter from './../modals/modalFooter';

type Props = {
  userID: string,
  requireCurrentPassword: boolean, // If true current password validation will be required.
  isCurrentUser: boolean, // If true the user will be logged in again after password change.
  workspace: string, 
  onSave?: () => void,
};

type State = {
  currentPassword: string,
  newPassword: string,
  newPasswordValidation: string,
  changesMade: boolean,
  errorMessage?: string,
  isSaving: boolean,
};

/**
 * A form for changing the password of the given user.
 * @class PasswordChangeForm
 * @extends {React.Component<Props, State>}
 */
class PasswordChangeForm extends React.Component<Props, State> {
  static defaultProps = { requireCurrentPassword: false, isCurrentUser: false };

  /**
   * Creates an instance of PasswordChangeForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      changesMade: false,
      currentPassword: '',
      newPassword: '',
      newPasswordValidation: '',
      isSaving: false,
    };
  }

  /**
   * Returns true if form is valid. If not returns false and sets appropriate error message.
   * @returns {boolean}
   */
  formIsValid(): boolean {
    if (this.state.newPassword !== this.state.newPasswordValidation) {
      this.setState({ errorMessage: translate('passwords_dont_match') });
      return false;
    } else if (this.state.newPassword.length < 8) {
      this.setState({ errorMessage: translate('new_password_must_be_at_least_8_characters_long') });
      return false;
    }
    return true;
  }

  /**
   * Runs the current password change function based on props
   * @returns {Promise<boolean>}
   */
  handleChangePassword(): Promise<boolean> {
    return this.props.isCurrentUser ?
      changeOwnPassword(this.props.userID, this.state.newPassword, this.state.currentPassword, this.props.workspace) :
      changePassword(this.props.userID, this.state.newPassword);
  }

  /**
   * Handles the saving of a new password.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    this.setState({ isSaving: true });
    if (!this.formIsValid()) {
      this.setState({ isSaving: false });
      return Promise.resolve(false);
    }
    return this.handleChangePassword()
      .then((wasSuccessful) => {
        if (wasSuccessful) {
          createSuccessNotification(translate('password_successfully_changed'));
          this.setState({
            errorMessage: undefined,
            isSaving: false,
            changesMade: false,
            currentPassword: '',
            newPassword: '',
            newPasswordValidation: '',
          });
          if (this.props.onSave) {
            this.props.onSave();
          }
        } else {
          this.setState({
            errorMessage: translate('change_password_failed'),
            isSaving: false,
          });
        }
        return wasSuccessful;
      });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let cardClass;
    let cardStyle;
    if (location.hash === '#/user/change-password') {
      cardClass = 'o-card';
      cardStyle = { paddingTop: 20 };
    }
    return (
      <Fragment>
        <div className={cardClass} style={cardStyle}>
          <div className="o-form">
            <SavePrompt when={this.state.changesMade} onSaveClicked={() => this.onSaveClicked()} />
            {
              this.state.errorMessage &&
              <FormError containerElementID="change-password">
                {this.state.errorMessage}
              </FormError>
            }
            {
              this.props.requireCurrentPassword &&
              <Input
                id="current-password-input"
                label={translate('current_password')}
                value={this.state.currentPassword}
                onValueChanged={currentPassword => this.setState({
                  changesMade: true,
                  currentPassword,
                })}
                required
                type="password"
              />
            }
            <NewPasswordInput
              value={this.state.newPassword}
              validationValue={this.state.newPasswordValidation}
              onValueChanged={({ value, validationValue }) =>
                this.setState({
                  changesMade: true,
                  newPassword: value,
                  newPasswordValidation: validationValue,
                })
              }
            />
          </div>
          <ModalFooter>
            <SaveButton
              onClick={() => this.onSaveClicked()}
              isSaving={this.state.isSaving}
              className="o-button--small u-margin-right--half-ws"
            />
          </ModalFooter>
        </div>
      </Fragment>
    );
  }
}

export default PasswordChangeForm;
