import React, { ComponentType } from 'react';
import defaultFlagComponent from '../flag';
import defaultTooltipComponent from '../tooltip';
import { renderWithTooltip } from './../../utils/tables';
import { MapValue, Column } from '../../types';
import type { TableProps } from '../table/table';

type Props = TableProps & {
  columns: Array<Column>,
  data: Array<{ [key: string]: MapValue }>,
  showTooltip: Boolean,
  flagWidth?: Number,
  flagComponent: React.Component,
  tooltipComponent: React.Component,
}

export default (Component: ComponentType, options?: MapValue) => {
  const wrapper = class FlagTable extends React.PureComponent<Props> {
    static displayName = 'FlagTable';

    static defaultProps = {
      showTooltip: true,
      flagComponent: defaultFlagComponent,
      tooltipComponent: defaultTooltipComponent,
      className: '',
    }

    /**
     * Table Body Properties to insert custom style
     * @returns {object} properties to be used for row
     */
    getTbodyProps = () => ({
      style: {
        overflow: 'visible',
      },
    })

    /**
     * Table Properties to insert custom style
     * @returns {object} properties to be used for row
     */
    getTableProps = () => ({
      style: {
        overflow: 'visible',
      },
    })

    /**
     * Expose the underlying ReactTable to get at the data
     * @returns {React.Component} The wrapped instance.
     */
    getWrappedInstance() {
      if (!this.wrappedInstance) throw new Error('FlagTable - No wrapped instance');
      if (this.wrappedInstance.getWrappedInstance) return this.wrappedInstance.getWrappedInstance();
      return this.wrappedInstance;
    }

    /**
     * Renders the component.
     * @returns {React.Component} The rendered component.
     */
    render() {
      const {
        columns: originalCols,
        data: originalData,
        showTooltip,
        flagWidth,
        className,
        ...rest
      } = this.props;
      const flag = {
        id: '_flag',
        accessor: 'flag', // this value is not important
        Header: '',
        Cell: renderWithTooltip(this.props.flagComponent, this.props.tooltipComponent),
        width: flagWidth || 30,
        filterable: false,
        sortable: false,
        resizable: false,
      };

      const columns = (options !== undefined && options.floatingLeft === true)
        ? [...originalCols, flag]
        : [flag, ...originalCols];
      const data = showTooltip
        ? originalData.map(d => ({ ...d, hover: d.flag }))
        : originalData;
      const extra = {
        columns,
        data,
      };
      const tableProps = {
        tBodyProperties: this.getTbodyProps,
        tableProperties: this.getTableProps,
      };
      return (<Component
        className={`${className} o-table-with-tooltip__row`}
        {...rest}
        {...extra}
        {...(showTooltip && tableProps)}
        ref={(r) => {
          this.wrappedInstance = r;
          return this.wrappedInstance;
        }}
      />);
    }
  };

  return wrapper;
};
