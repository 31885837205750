import { connect } from 'react-redux';
import { List } from 'immutable';

import SupplyForm from './../components/inventory/supplyForm';
import { saveFactory } from './../utils/redux';
import AsyncFetch from './../components/asyncFetch';
import { getSupplierDataViews } from './../dataViews';
import { setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateInventoryItemCount } from './../actions';

import type { Dispatch, State, DataView } from './../types';
import type BaseModel from '../models/baseModel';

/**
  * @param {Object} state Current app state.
  * @return {Object} The props to be transferred to this container.
  */
const mapStateToProps = ({
  drugs, isFetching, currentDataViewsModels, currentDataViews,
}: State) => ({
  suppliers: isFetching ? new List() : currentDataViewsModels.filter(m => m.get('type') === 'supplier' && m.isVisible() && m.isClinic()),
  drugs,
  isFetching,
  containerDataViews: getSupplierDataViews(),
  currentDataViews,
  isOutgoing: true,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
  updateInventoryItemCount: (skuID: string, change: number) =>
    dispatch(updateInventoryItemCount(skuID, change)),
});

const TransferOutContainer = connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(SupplyForm));

export default TransferOutContainer;
