import moment, { Moment } from 'moment';

import { getConfig } from './utils';
import { DATE_FORMAT_TO_SAVE } from './../constants';
import type { DateOffsetUnits } from './../types';

export const MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

/**
   * @return {string} Returns the preferred date format for this user.
   */
export const getDateFormat = (): string => getConfig().get('date_format', 'DD/MM/YYYY');

/**
   * @return {string} Returns the preferred date-time format for this user.
   */
export const getDateTimeFormat = (): string => `${getConfig().get('date_format', 'DD/MM/YYYY')}, h:mm a`;

/**
   * Returns the preferred time format for this user.
   * @return {string} - Time format.
   */
export const getTimeFormat = (): string => 'HH:mm';

/**
   * Receives a Unix timestamp and verifies if it is for today.
   * @param {number} date - A Unix timestamp.
   * @return {boolean} - True if timestamp is today.
   */
export const isToday = (date: number): boolean => {
  const today = new Date().setHours(0, 0, 0, 0);
  const tomorrow = today + (24 * 60 * 60 * 1000);
  return date < tomorrow && date >= today;
};

/**
   * Receives a Unix timestamp and verifies if it is before today.
   * @param {number} date - A Unix timestamp.
   * @return {boolean} - True if timestamp is before today.
   */
export const isPast = (date: number): boolean => {
  const today = new Date().setHours(0, 0, 0, 0);
  return date < today;
};

/**
   * Receives a Unix timestamp and verifies if it is for tomorrow.
   * @param {number} date - A Unix timestamp.
   * @return {boolean} - True if timestamp is tomorrow.
   */
export const isTomorrow = (date: number): boolean => {
  const midnightTonight = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000);
  const midnightTomorrow = midnightTonight + (24 * 60 * 60 * 1000);
  return date >= midnightTonight && date < midnightTomorrow;
};

/**
   * Receives a Unix timestamp and verifies if it is for yesterday.
   * @param {number} date - A Unix timestamp.
   * @return {boolean} - True if timestamp is yesterday.
   */
export function isYesterday(date: number): boolean {
  const today = new Date().setHours(0, 0, 0, 0);
  const yesterday = today - (24 * 60 * 60 * 1000);
  return date < today && date >= yesterday;
}

/**
   * Receives a Unix timestamp and verifies if it is for midnight.
   * @param {number} timestamp - A Unix timestamp.
   * @return {boolean} - True if timestamp is midnight.
   */
export const isMidnight = (timestamp: number): boolean => {
  const date = new Date(timestamp);
  return date.getMinutes() === 0 && date.getHours() === 0;
};

/**
   * Returns true if string is of format HH:MM or HH:MM:SS and is a valid time.
   * @param {string} timeString - A string representation of a time.
   * @return {boolean} - True if timeString is valid.
   */
export const isValidTimeString = (timeString: string): boolean => {
  const times = timeString.split(':');
  if (times.length !== 2 && times.length !== 3) {
    return false;
  }
  if (isNaN(times[0]) || parseInt(times[0], 10) < 0 || parseInt(times[0], 10) > 24) {
    return false;
  }
  if (isNaN(times[1]) || parseInt(times[1], 10) < 0 || parseInt(times[1], 10) > 60) {
    return false;
  }
  if (times.length === 3) {
    if (isNaN(times[2]) || parseInt(times[2], 10) < 0 || parseInt(times[2], 10) > 60) {
      return false;
    }
  }
  return true;
};

/**
   * Converts a string in the format HH:MM or HH:MM:SS to milliseconds.
   * Date inputs.
   * @param {string} timeString - String in the format HH:MM or HH:MM:SS.
   * @return {number} - milliseconds.
   */
export const convertTimeToMilliseconds = (timeString: string): number => {
  let milliseconds = 0;
  if (!timeString || timeString.split(':').length < 2) {
    throw new Error(`${timeString} should be in the format HH:MM or HH:MM:SS.`);
  }
  const times = timeString.split(':').map(str => parseInt(str, 10));
  milliseconds += (times[0] * 60 * 60 * 1000);
  milliseconds += (times[1] * 60 * 1000);
  if (times.length === 3) {
    milliseconds += (times[2] * 1000);
  }
  return milliseconds;
};

/**
   * Converts a string in the format YYYY-MM-DD to a timestamp. Useful for converting native HTML
   * Date inputs.
   * @param {string} dateString - Date in YYYY-MM-DD format.
   * @return {number} - A Unix timestamp.
   */
export const convertDateToTimestamp = (dateString: string): number =>
  moment(dateString, 'YYYY-MM-DD').valueOf();

/**
   * Converts a timestamp to the format YYYY-MM-DD. Useful for converting timestamp for native
   * HTML Date inputs.
   * @param {number} timestamp - A Unix timestamp.
   * @return {string} - Date in YYYY-MM-DD format.
   */
export const convertTimestampToDate = (timestamp: number): string =>
  moment(timestamp).format('YYYY-MM-DD');

/**
   * Returns a prettified date derived from the given timestamp.
   * @param {number} timestamp - A Unix timestamp.
   * @return {string} - Date in user specified format.
   */
export const prettifyDate = (timestamp: number): string =>
  moment(timestamp).format(getDateFormat());

/**
   * Returns a prettified time derived from the given timestamp.
   * @param {number} timestamp - A Unix timestamp.
   * @return {string} - Time in format 'HH:mm'
   */
export const prettifyTime = (timestamp: number): string => moment(timestamp).format('HH:mm');

/**
   * Returns a prettified version of the given timestamp. If useCalender is true then Today,
   * Yesterday, Tomorrow, and Last *-day are used where appropriate.
   * @param {number} timestamp - Unix timestamp
   * @param {boolean} useCalender - Specifies to use special date strings or not.
   * @return {string} - Date and time according to user specified format
   */
export const prettifyDateTime = (timestamp: number, useCalender: boolean = false): string => {
  if (useCalender) {
    return moment(timestamp).calendar(null, {
      sameDay: `[Today], ${getTimeFormat()}`,
      nextDay: `[Tomorrow], ${getTimeFormat()}`,
      lastDay: `[Yesterday], ${getTimeFormat()}`,
      lastWeek: `[Last] dddd, ${getTimeFormat()}`,
      sameElse: getDateTimeFormat(),
    });
  }
  return moment(timestamp).format(getDateTimeFormat());
};

/**
   * Returns the prettified version of todays date
   * @return {string} - Prettified date of today.
   */
export const prettifyToday = (): string => prettifyDate(new Date());


/**
 * Takes a moment representing a date and a moment representing a time and combines them into a
 * single moment instance.
 * @param {Moment} date The date Moment.
 * @param {Moment} time The time Moment.
 * @returns {Moment} The combine Moment.
 */
export function combineDateAndTime(date: Moment, time: Moment): Moment {
  return moment(date).minutes(time.minutes()).hours(time.hours());
}

/**
 * Returns true if the given timestamp is in the month and year passed as params.
 * @param {number} month The month the timestamp should be part of. (Jan = 0, Dec = 11)
 * @param {number} year The year the timestamp should be part of.
 * @param {number} timestamp The timestamp to check.
 * @returns {boolean}
 */
export function isInMonthRange(month: number, year: number, timestamp: number): boolean {
  const date = moment(timestamp);
  return date.year() === year && date.month() === month;
}

/**
 * Gets a timestamp for the start of the given month (start of day).
 * @param {number} month The month (jan = 0)
 * @param {number} year The year
 * @returns {number}
 */
export function getStartOfMonth(month: number, year: number) {
  const date = moment({ month, year, day: 1 });
  return date.valueOf();
}

/**
 * Gets a timestamp for the end of the given month (end of day).
 * @param {number} month The month (jan = 0)
 * @param {number} year The year
 * @returns {number}
 */
export function getEndOfMonth(month: number, year: number) {
  const date = moment({ month, year, day: 1 });
  return date.add(1, 'month').subtract(1, 'milliseconds').valueOf();
}

/**
 * Converts a timestamp to the format DD/MM/YY. Used while displaying vitals graph, for x axis labels
 * @param {number} timestamp - A Unix timestamp.
 * @return {string} - Date in DD/MM/YY format.
 */
export function formatDateForGraph(timestamp: number): string {
  return moment(timestamp).format('DD/MM/YY');
}

/**
 * Formats a timestamp for use in a file name.
 * @param {number} timestamp The timestamp to format
 * @returns {string}
 */
export function formatTimestampForFileName(timestamp: number): string {
  return moment(timestamp).format('YYYY-MM-DD-HHmmss');
}

/**
 * Returns the current date time in a format that can be used in a file name
 * @returns {string}
 */
export function getNowForFileName(): string {
  return formatTimestampForFileName(new Date().valueOf());
}

/**
 * Checks if start time is less than or equal to end time.
 * Returns false if any of the values are null. So open ended filters are not allowed.
 * @param {Moment} startDate start date
 * @param {Moment} startTime start time
 * @param {Moment} endDate end date
 * @param {Moment} endTime end time
 * @returns {boolean}
 */
export function validateTimeFilter(
  startDate: Moment, startTime: Moment, endDate: Moment, endTime: Moment,
): boolean {
  if (startDate === null || endDate === null || startTime === null || endTime === null) {
    return false;
  }
  if (startDate.isAfter(endDate, 'day')) {
    return false;
  }
  if (startDate.isBefore(endDate, 'day')) {
    return true;
  }
  return !startTime.isAfter(endTime, 'second');
}

/**
 * Checks if start date is less than or equal to end date.
 * Returns false if any of the values are null. So open ended filters are not allowed.
 * @param {Moment} startDate start date
 * @param {Moment} endDate end date
 * @returns {boolean}
 */
export function validateDateFilter(
  startDate: Moment, endDate: Moment,
): boolean {
  if (startDate === undefined || endDate === undefined) {
    return false;
  }
  if (startDate.isAfter(endDate, 'day')) {
    return false;
  }
  return startDate.isBefore(endDate, 'day') || startDate.isSame(endDate);
}

/**
 * Before saving the date field is updated again with the date format used for saving,
 * this is also because the source of date can be different and can have different formats
 * @param {string | moment} date date string
 * @returns {string | void} updated date
 */
export function formatDateForSave(date?: string | moment.Moment): string | void {
  if (
    date &&
    (moment(date, getDateFormat()).isValid() || moment(date, DATE_FORMAT_TO_SAVE).isValid()) &&
    moment(date).format(DATE_FORMAT_TO_SAVE) !== 'Invalid date'
  ) { // this check is needed because isValid() is not strict parsing
    // cannot pass a specific date format to moment parser. On new doc the date format wil be clinic display
    // format whereas if it is edit the date format will be save specific YYYY-MM-DD. Hence checking for clinic specific
    // format and save format separately. eg: for the date 07-05-2000 moment(date) gets converted to 05-07-2000 when format is not specified.
    if (moment(date, getDateFormat(), true).isValid()) {
      return moment(date, getDateFormat()).format(DATE_FORMAT_TO_SAVE);
    } else if (moment(date, DATE_FORMAT_TO_SAVE, true).isValid() && !moment.isMoment(date)) {
      return date;
    }
    return moment(date, getDateFormat()).isValid() ?
      moment(date).format(DATE_FORMAT_TO_SAVE) : undefined;
  }
  return undefined;
}

/**
 * Checks if date is a valid one and can be formatted to save format.
 * @param {string} date date string
 * @returns {boolean}
 */
export function isDateValid(date?: string): boolean {
  if (date) { // this check is needed because isValid() is not strict parsing
    // cannot pass a specific date format to moment parser. On new doc the date format wil be clinic display
    // format whereas if it is edit the date format will be save specific YYYY-MM-DD. Hence checking for clinic specific
    // format and save format separately. eg: for the date 07-05-2000 moment(date) gets converted to 05-07-2000 when format is not specified.
    if (moment(date, getDateFormat(), true).isValid() &&
    moment(date, getDateFormat()).format(DATE_FORMAT_TO_SAVE) !== 'Invalid date') {
      return true;
    } else if (moment(date, DATE_FORMAT_TO_SAVE, true).isValid() &&
    moment(date).format(DATE_FORMAT_TO_SAVE) !== 'Invalid date') {
      return true;
    }
    return moment(date, getDateFormat()).isValid() && moment(date).format(DATE_FORMAT_TO_SAVE) !== 'Invalid date';
  }
  return true;
}


/**
 * Checks if individual date and time moment object equals to a given timestamp
 * @param {Moment} date date object
 * @param {Moment} time time string
 * @param {number} timestamp timestamp as number
 * @returns {boolean}
 */
export function isDateTimeEqualTimestamp(date: Moment, time: Moment, timestamp: number) {
  return moment(date)
    .hour(time.hour())
    .minute(time.minute())
    .second(0)
    .valueOf()
    .toString()
    .slice(0, -3) === timestamp.toString().slice(0, -3);
}


/**
 * Takes a week, month or year offset and convert it to a day offset
 * @param {number} value date object
 * @param {string} unit time string
 * @returns {number}
 */
export function covertDateOffsetToDays(value: number, unit: DateOffsetUnits) {
  const conversionRate = {
    day: 1,
    week: 7,
    month: 30,
    year: 365,
  };

  return value * conversionRate[unit];
}
