import React from 'react';
import { List, Map } from 'immutable';

import TagInput from './../inputs/tagInput';
import translate from './../../utils/i18n';
import ConditionModel from './../../models/conditionModel';
import { updateClinicConfig } from './../../utils/db';
import { logDiagnosisCreated } from './../../utils/logging';

import type { Config, SaveModel } from './../../types';
import type EncounterModel from './../../models/encounterModel';

type Props = {
  config: Config,
  encounter: EncounterModel,
  saveModel: SaveModel,
  diagnoses: List<ConditionModel>,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  disabled: boolean,
};

/**
 * An input for Diagnoses
 * @class DiagnosesInput
 * @extends {React.Component}
 */
class DiagnosesInput extends React.PureComponent<Props> {
  props: Props;

  static defaultProps = {
    disabled: false,
  };

  /**
   * Checks the given diagnosis values against what exists in config and updates config if necessary.
   * @param {[string]} values A list of strings derived from newly created diagnoses.
   * @returns {void}
   */
  updateConfig = (values: Array<string>) => {
    const existingValues = this.props.config.getIn(['diagnoses', 'options'], List());
    const newValues = values.filter(newValue => !existingValues.find(existingValue =>
      existingValue.toLowerCase() === newValue.toLowerCase()));
    if (newValues.length > 0) {
      const configUpdates = Map()
        .setIn(['diagnoses', 'options'], existingValues.concat(List(newValues)))
        .toJS();
      updateClinicConfig(this.props.config.toJS(), configUpdates, this.props.updateConfig);
      this.props.updateConfigValue(['diagnoses', 'options'], existingValues.concat(List(newValues)));
    }
  }


  /**
   * Removes any options from the diagnoses list that have been used already.
   * @returns {[{ value: string, label: string }]} The options for TagInput.
   */
  getUnusedDiagnoses = () =>
    this.props.config
      .getIn(['diagnoses', 'options'], List())
      .filter(diagnosis => !this.props.diagnoses.find(model => model.get('name') === diagnosis))
      .map(item => ({ value: item, label: item }))
      .toArray();

  /**
   * Checks for new models and removed models and updates the DB accordingly.
   * @param {[{}]} updatedValues The updated values of the TagInput.
   * @returns {void}
   */
  onChange = (updatedValues: Array<{ value: string, isModel: boolean, label: string }>) => {
    const newValues = updatedValues.filter(value => !value.isModel);
    this.updateConfig(newValues.map(v => v.value));
    const newModels = newValues.map(value => new ConditionModel({
      name: value.value,
      subtype: 'diagnosis',
      patient_id: this.props.encounter.get('patient_id'),
      encounter_id: this.props.encounter.get('_id'),
    }));
    const modelsNotRemoved = updatedValues
      .filter(value => value.isModel)
      .map(value => value.value);
    const modelsToRemove = this.props.diagnoses
      .filter(s => !modelsNotRemoved.find(id => id === s.get('_id')))
      .map(s => s.set({ hidden: true }))
      .toArray();
    newModels.concat(modelsToRemove).forEach(model =>
      this.props.saveModel(model).then((savedModel) => {
        if (savedModel.get('edited_by').length === 1) { // i.e. is new
          logDiagnosisCreated();
        }
      }));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <TagInput
        id="diagnoses-input"
        label={translate('diagnoses')}
        options={this.getUnusedDiagnoses()}
        value={
          this.props.diagnoses
            .map(s => ({ value: s.get('_id'), label: s.get('name'), isModel: true }))
            .toArray()
        }
        onChange={this.onChange}
        disabled={this.props.disabled}
      />
    );
  }
}

export default DiagnosesInput;
