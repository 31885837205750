import React from 'react';
import { fromJS, List, Map, OrderedMap } from 'immutable';
import type { Search } from 'js-search';
import type { SortingRule } from 'react-table';

import { fetchData, fetchDocsForPatients } from './../../utils/api';
import translate from './../../utils/i18n';
import EncounterTable from './../encounterTable/encounterTable';
import CompletedEncounterTable from './../encounterTable/completedEncounterTable';
import IncompleteEncounterTable from './../encounterTable/incompleteEncounterTable';
import WaitingEncounterTable from './../encounterTable/waitingEncounterTable';
import PatientSearchResultList from './patientSearchResultList';
import ContentTransition from './../contentTransition';

import LegacyScheduleTable from './../encounterTable/legacyScheduleTable';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import ScheduledConsultsTable from './../encounterTable/scheduledConsultsTable';
import AppointmentRequestsTable from './../encounterTable/appointmentRequestsTable';
import TableSorter from '../encounterTable/tableSorter';
import TableFilter, { FilteringRule, FilterRule } from '../encounterTable/tableFilter';

import { isKlinifyUser } from './../../utils/auth';
import { getWebappSettings, setLocalWebappSettings } from '../../utils/utils';
import { formatTitle } from '../documentTitle';

import type { Config, SaveModel, User, SaveModels } from './../../types';
import type EncounterModel from './../../models/encounterModel';
import type BillModel from './../../models/billModel';
import PatientStubModel from './../../models/patientStubModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PaymentModel from './../../models/paymentModel';
import type ReceivableModel from './../../models/receivableModel';
import type PractitionerModel from './../../models/practitionerModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type AppointmentModel from './../../models/appointmentModel';
import type BaseModel from '../../models/baseModel';
import type PatientModel from '../../models/patientModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type PaymentTypeModel from '../../models/paymentTypeModel';
import PatientListSearchInput from './patientListSearchInput';
import StagesInfoBar from '../unreviewedStages/stagesInfoBar';
import { getDocById, getByTypeFromBillIdsFormDataview } from '../../dataViews';
import { docToModel } from '../../utils/models';

type Props = {
  config: Config,
  klinifyConfig: Config,
  encounters: List<EncounterModel>,
  bills: Map<string, BillModel>,
  patientStubs: Map<string, PatientStubModel>,
  patients: List<PatientModel>,
  patientSearchQuery: string,
  payments: List<PaymentModel>,
  outstandingReceivables: List<ReceivableModel>,
  salesItems: List<SalesItemModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  setPatientStubs: (patientStubs: List<PatientStubModel>) => void,
  setPatientSearchQuery: (query: string) => void,
  updateModelsInState: () => void,
  addModelToStore: (model: BaseModel) => void,
  user: User,
  practitioners: List<PractitionerModel>,
  coveragePayors: List<CoveragePayorModel>,
  isOnline: boolean,
  appointments: List<AppointmentModel>,
  showAppointmentRequested: (model: AppointmentModel) => void,
  showAppointmentRequestNotificationIcon: (show: boolean) => void,
  patientsSearch: Search,
  updateConfig: (config: Config) => void,
  encounterStageMap: Map<string, EncounterStageModel>,
  encounterFlowMap: OrderedMap<string, EncounterFlowModel>,
  paymentTypes: List<PaymentTypeModel>,
};

type State = {
  tableProps: {
    sorted: Array<SortingRule>;
    filteringRule: FilteringRule;
  },
  payments: List<PaymentModel>;
  bills: Map<string, BillModel>;
};

/**
 * Returns tableprops state if exists in localstorage, else default sort rules
 * @returns {State}
 */
const getInitialTableProps = () => {
  const localSettings = getWebappSettings();
  const tableProps = localSettings.getIn(['patient_overview', 'table_rules'])
    ? localSettings.getIn(['patient_overview', 'table_rules']).toJS()
    : { sorted: [{ id: 'arrival_time', desc: false }], filteringRule: { filterRules: [], logicalOp: 'and' } };
  return {
    // Filtering to make sure, this doesn't lead to any type erros even if the value is manually edited in local storage
    sorted: tableProps.sorted?.filter((s: SortingRule) => s.id) || [],
    filteringRule: {
      filterRules:
        tableProps.filteringRule?.filterRules?.filter(
          (s: FilterRule) => s.id && s.condition && s.value
        ) || [],
      logicalOp: tableProps.filteringRule?.logicalOp || 'and',
    },
  };
};

/**
   * A full page component with Sidebar + Header, a list of patients and a input to filter them.
   *
   * @namespace PatientList
   * @returns {React.ComponentClass}
   */
class PatientList extends React.Component<Props, State> {
  state = {
    // This state is passed across all tables in the overview page and the structure should be same as how react table expects.
    tableProps: getInitialTableProps(),
    payments: List<PaymentModel>(),
    bills: Map<string, BillModel>(),
  };

  /**
   * Called when component mounts.
   * @returns {void}
   */
  componentDidMount() {
    this.props.showAppointmentRequestNotificationIcon(false);
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', '/');
      window.ga('set', 'title', formatTitle(translate('patient_overview')));
      window.ga('send', 'pageview', {
        dimension1: this.state.tableProps?.sorted?.length
          ? this.state.tableProps.sorted.reduce((dataStr: Array<string>, sortRule: SortingRule) => dataStr.concat(`${translate(sortRule.id)}-${sortRule.desc ? 'DSC' : 'ASC'}`), []).join(', ')
          : 'none',
      });
    }

    // Fetch all the payments and bills for the encounters that are in the current day.
    this.loadBillingDocuments();
  }

  /**
   * Called when component updates.
   * @param prevProps 
   */
  componentDidUpdate(prevProps: Props) {
    // console.log("Update")
    if (this.props.encounters !== prevProps.encounters) {
      this.loadBillingDocuments();
    }
  }

  /**
   * Called when component un-mounts.
   * @returns {void}
   */
  componentWillUnmount() {
    this.props.setPatientSearchQuery('');
  }

  /**
   * Sets the new sort and filter rules to local storage and state
   * @param {State} tableProps tableProps
   * @returns {void}
   */
  onTableRuleSave = (tableProps: Partial<State['tableProps']>) => {
    this.setState({ tableProps: Object.assign({}, this.state.tableProps, tableProps) }, () =>
      setLocalWebappSettings(fromJS({
        patient_overview: {
          table_rules: this.state.tableProps,
        },
      })));
  }

  /**
   * Called when a patient is added to the queue. Resets the search query and fetches the patient
   * docs.
   * @param {string} patientID The patient ID.
   * @returns {void}
   */
  onPatientAddedToQueue(patientID: string) {
    this.props.setPatientSearchQuery('');
    fetchDocsForPatients([patientID], true)
      .then(this.props.updateModelsInState);
  }

  /**
   * Renders the patients filtered by the current search.
   * @return {React.Component} A React component.
   */
  renderSearchResults() {
    const encountersByAppointment = this.props.encounters
      .filter(e => e.isCurrent() && e.get('appointment_id')).groupBy(e => e.get('appointment_id'));

    return (
      <PatientSearchResultList
        searchQuery={this.props.patientSearchQuery}
        patientStubs={this.props.patientStubs}
        onPatientAddedToQueue={(patientID: string) => this.onPatientAddedToQueue(patientID)}
        config={this.props.config}
        klinifyConfig={this.props.klinifyConfig}
        user={this.props.user}
        patientsSearch={this.props.patientsSearch}
        patientSearchQuery={this.props.patientSearchQuery}
        scheduledPatients={this.props.appointments
          .reduce((patientMap, a) => patientMap.update(
            a.get('patient_id'),
            value => (value || ((a.isActive() && a.isTodayOrFuture()) ||
              encountersByAppointment.has(a.get('_id')))),
          ), Map())}
        encounters={this.props.encounters}
        appointments={this.props.appointments}
      />
    );
  }

  private async loadBillingDocuments() {
    const completedEncounters = this.props.encounters.filter(encounter => encounter.isToday() && encounter.highestEventIs('completed'));
    const billIds = Array.from(completedEncounters.toSet()).map((encounter) => encounter.get('bill_id'));
    if (!billIds.length) return;
    const billPaymentFetchIds = billIds.filter(x => !this.state.payments.hasIn([x, 'bill_id']) && !this.props.payments.hasIn([x, 'bill_id']));
    if (!billPaymentFetchIds.length) return;
    const payments = await fetchData(getByTypeFromBillIdsFormDataview('payment', ...billPaymentFetchIds));
    const bills = await getDocById(billIds).then(bills => bills.rows.map((x: any) => [x.id, docToModel(x.doc)]));
    this.setState(prevState => ({
      ...prevState,
      payments: this.props.payments
        .concat(prevState.payments)
        .concat(payments as any)
        .filter(
          (x, index, arr) => arr.findIndex((y: PaymentModel) => y.get('_id') === x.get('_id')) === index
        ),
      bills: this.props.bills.concat(prevState.bills).concat(bills),//.concat(bills.toMap() as any)),
    }));
  }

  /**
   * Renders a list of all encounters for today seperated into encounter state.
   * @return {React.Component} The encounters list
   */
  renderEncounters() {
    if (this.props.config.get('use_legacy_scheduling', false)) {
      return <LegacyScheduleTable patients={this.props.patientStubs.toList()} />;
    }
    const isIncompleteEncountersShown = this.props.config.getIn(['encounters', 'categorize_and_view_incomplete_encounters'], false);
    const incompleteEncounters = isIncompleteEncountersShown ? this.props.encounters
      .filter(encounter => encounter.isPastAndIncomplete()) : undefined;
    const scheduleEnabled = isKlinifyUser(this.props.user) || this.props.klinifyConfig.getIn(['scheduling', 'scheduleAppointments'], false);
    const requestedAppointments = this.props.appointments.filter(a =>
      a.isPending() && a.isTodayOrFuture());
    const currentEncounters = this.props.encounters.filter(encounter => (encounter.isToday() && !encounter.containsEvent('cancelled')) || encounter.isPastAndIncomplete());
    return (
      <PermissionWrapper permissionsRequired={List([createPermission('queue_patient_for_today', 'read')])} user={this.props.user}>
        <header className="o-header-actions u-padding--standard-sides">
          <TableFilter
            filteringRule={this.state.tableProps.filteringRule}
            onSave={filteringRule => this.onTableRuleSave({ filteringRule })}
            user={this.props.user}
            config={this.props.config}
            practitioners={this.props.practitioners}
            coveragePayors={this.props.coveragePayors}
            encounterFlowMap={this.props.encounterFlowMap}
            encounterStageMap={this.props.encounterStageMap}
            paymentTypes={this.props.paymentTypes}
          />
          <TableSorter
            sorted={this.state.tableProps.sorted}
            onSave={sorted => this.onTableRuleSave({ sorted })}
            user={this.props.user}
            config={this.props.config}
          />
        </header>
        <div className="u-margin-bottom--4ws">
          {incompleteEncounters && incompleteEncounters.size > 0 &&
            <IncompleteEncounterTable
              config={this.props.config}
              encounters={incompleteEncounters}
              bills={this.props.bills}
              patients={this.props.patientStubs}
              label={`${translate('incomplete')} ${this.props.config.getIn(['encounters', 'labels', 'encounters'], 'Encounters')}`}
              salesItems={this.props.salesItems}
              saveModel={this.props.saveModel}
              saveModels={this.props.saveModels}
              user={this.props.user}
              practitioners={this.props.practitioners}
              coveragePayors={this.props.coveragePayors}
              updateConfig={this.props.updateConfig}
              tableProps={this.state.tableProps}
            />
          }
          {scheduleEnabled && requestedAppointments.size > 0 &&
            <AppointmentRequestsTable
              config={this.props.config}
              patients={this.props.patients}
              salesItems={this.props.salesItems}
              saveModels={this.props.saveModels}
              saveModel={this.props.saveModel}
              user={this.props.user}
              practitioners={this.props.practitioners}
              coveragePayors={this.props.coveragePayors}
              appointments={requestedAppointments}
              updateModelsInState={this.props.updateModelsInState}
              showAppointmentRequested={this.props.showAppointmentRequested}
              encounterFlowMap={this.props.encounterFlowMap}
              updateConfig={this.props.updateConfig}
              tableProps={this.state.tableProps}
            />}
          {scheduleEnabled && <ScheduledConsultsTable
            config={this.props.config}
            patients={this.props.patientStubs}
            salesItems={this.props.salesItems}
            saveModels={this.props.saveModels}
            saveModel={this.props.saveModel}
            user={this.props.user}
            practitioners={this.props.practitioners}
            coveragePayors={this.props.coveragePayors}
            appointments={this.props.appointments}
            updateModelsInState={this.props.updateModelsInState}
            addModelToStore={this.props.addModelToStore}
            encounterStageMap={this.props.encounterStageMap}
            encounterFlowMap={this.props.encounterFlowMap}
            updateConfig={this.props.updateConfig}
            encounters={this.props.encounters}
            tableProps={this.state.tableProps}
          />}
          <WaitingEncounterTable
            config={this.props.config}
            encounters={currentEncounters.filter(encounter => (encounter.highestEventIs('arrived')
              || (encounter.highestEventIs('started') && encounter.getCurrentEvent().type === 'arrived')))}
            patients={this.props.patientStubs}
            outstandingReceivables={this.props.outstandingReceivables}
            salesItems={this.props.salesItems}
            saveModel={this.props.saveModel}
            user={this.props.user}
            practitioners={this.props.practitioners}
            coveragePayors={this.props.coveragePayors}
            updateConfig={this.props.updateConfig}
            encounterStageMap={this.props.encounterStageMap}
            encounterFlowMap={this.props.encounterFlowMap}
            bills={this.props.bills}
            saveModels={this.props.saveModels}
            currentEncounters={currentEncounters}
            tableProps={this.state.tableProps}
          />
          <EncounterTable
            config={this.props.config}
            encounters={currentEncounters.filter(encounter => (encounter.get('flow') === undefined
              ? encounter.highestEventIs('started')
              : (encounter.highestEventIs('started') && encounter.getCurrentEvent().type === 'started')))}
            patients={this.props.patientStubs}
            label={translate('in_progress')}
            configField="inprogress_encounter"
            salesItems={this.props.salesItems}
            saveModel={this.props.saveModel}
            outstandingReceivables={this.props.outstandingReceivables}
            user={this.props.user}
            practitioners={this.props.practitioners}
            coveragePayors={this.props.coveragePayors}
            updateConfig={this.props.updateConfig}
            encounterStageMap={this.props.encounterStageMap}
            inProgress
            encounterFlowMap={this.props.encounterFlowMap}
            bills={this.props.bills}
            saveModels={this.props.saveModels}
            currentEncounters={currentEncounters}
            playAudioAlert={this.props.config.getIn(['patient_overview', 'play_alert'])}
            tableProps={this.state.tableProps}
          />
          <EncounterTable
            config={this.props.config}
            playAudioAlert={this.props.config.getIn(['patient_overview', 'play_alert_on_finished_consult'], true)}
            encounters={currentEncounters.filter(encounter => encounter.highestEventIs('finished_consult'))}
            patients={this.props.patientStubs}
            label={translate('billing')}
            salesItems={this.props.salesItems}
            saveModel={this.props.saveModel}
            outstandingReceivables={this.props.outstandingReceivables}
            user={this.props.user}
            practitioners={this.props.practitioners}
            coveragePayors={this.props.coveragePayors}
            configField="billing_encounter"
            updateConfig={this.props.updateConfig}
            encounterStageMap={this.props.encounterStageMap}
            encounterFlowMap={this.props.encounterFlowMap}
            bills={this.props.bills}
            saveModels={this.props.saveModels}
            currentEncounters={currentEncounters}
            tableProps={this.state.tableProps}
          />
          <CompletedEncounterTable
            config={this.props.config}
            encounters={currentEncounters.filter(encounter => encounter.isToday() && encounter.highestEventIs('completed'))}
            bills={this.state.bills}
            patients={this.props.patientStubs}
            label={`${translate('completed')} ${this.props.config.getIn(['encounters', 'labels', 'encounters'], 'Encounters')}`}
            salesItems={this.props.salesItems}
            payments={this.state.payments}
            outstandingReceivables={this.props.outstandingReceivables}
            practitioners={this.props.practitioners}
            user={this.props.user}
            coveragePayors={this.props.coveragePayors}
            updateConfig={this.props.updateConfig}
            tableProps={this.state.tableProps}
          />
        </div>
      </PermissionWrapper>
    );
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    const contents = this.props.patientSearchQuery.length ?
      this.renderSearchResults() : this.renderEncounters();
    return (
      <ContentTransition className="o-scrollable-container">
        <StagesInfoBar
          encounterStageMap={this.props.encounterStageMap}
          saveModel={this.props.saveModel}
          config={this.props.config}
          updateConfig={this.props.updateConfig}
          largeDisplay
        />
        <PatientListSearchInput
          patientSearchQuery={this.props.patientSearchQuery}
          setPatientSearchQuery={this.props.setPatientSearchQuery}
        />
        {contents}
      </ContentTransition>
    );
  }
}

export default PatientList;
