import { connect } from 'react-redux';
import SupplyInvoicesList from './../components/inventory/supplyInvoicesList';
import { State } from '../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  suppliers, config,
}: State) => ({
  suppliers: suppliers.filter(supplier => supplier.get('integrations') !== undefined && supplier.isVisible()),
  config,
});
const SupplyInvoicesContainer = connect(mapStateToProps)(SupplyInvoicesList);
export default SupplyInvoicesContainer;
