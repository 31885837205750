import * as React from 'react';

import { ColorPaletteSpecimen, ColorSpecimen } from 'catalog';

/**
 * Colours section of Styleguide
 * @class Colours
 * @extends {React.Component<Props, State>}
 */
const Colours = () =>
  <React.Fragment>
    <h2 data-public>Colours</h2>
    <h3 data-public>Primary Colours</h3>
    <ColorPaletteSpecimen
      colors={[
        { value: 'rgb(205, 56, 106)', name: 'Maroon' },
        { value: 'rgb(159, 33, 75)', name: 'Maroon Hover' },
      ]}
      span={2}
    />
    <ColorPaletteSpecimen
      colors={[
        { value: '#4dd2b4', name: 'Green' },
        { value: '#40ae95', name: 'Green Hover' },
      ]}
      span={2}
    />
    <ColorPaletteSpecimen
      colors={[
        { value: '#ff4f02', name: 'Red' },
        { value: '#ff4f02', name: 'Red Hover' },
      ]}
      span={2}
    />
    <h3 data-public>Secondary Colours</h3>
    <ColorPaletteSpecimen
      colors={[
        { value: '#f5f5f5', name: 'Grey 1' },
        { value: '#fafafa', name: 'Grey 2' },
        { value: '#e0dfdf', name: 'Grey 3' },
        { value: '#f0f0f0', name: 'Grey 4' },
        { value: '#4a4a4a', name: 'Dark Grey' },
      ]}
      span={2}
    />
    <ColorPaletteSpecimen
      colors={[
        { value: '#2f3540', name: 'Midnight Grey' },
        { value: 'rgba(47, 53, 64, 0.8)', name: 'Midnight Grey (Transparent)' },
        { value: '#1e222a', name: 'Midnight Grey Hover' },
      ]}
      span={2}
    />
    <ColorPaletteSpecimen
      colors={[
        { value: 'rgb(64, 75, 94)', name: 'Blue Grey' },
        { value: 'rgb(52, 62, 79)', name: 'Blue Grey Dark' },
      ]}
      span={2}
    />
    <h3 data-public>Specific Usage Colours</h3>
    <ColorSpecimen
      value="#9b9b9b"
      name="Scrollbars"
      span={2}
    />
    <ColorSpecimen
      value="#6da9d1"
      name="Input Borders"
      span={2}
    />
  </React.Fragment>;

export default Colours;
