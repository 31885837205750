import React from 'react';
import Moment from 'moment';

import translate from './../../utils/i18n';
import Timepicker from './../inputs/timepicker';
import TextArea from './../inputs/textarea';
import { debugPrint } from './../../utils/logging';
import TimeChitModel from './../../models/timeChitModel';
import SaveButton from './../buttons/saveButton';
import UnsavedDataModal from '../../components/prompts/unsavedDataModal';
import { createSuccessNotification } from './../../utils/notifications';
import type { Config } from './../../types';
import ModalFooter from './../modals/modalFooter';

/* eslint-disable camelcase */
type Attributes = {
  start_time: number,
  end_time: number,
  notes: string,
}
/* eslint-enable camelcase */

type Props = {
  config: Config,
  currentModel?: TimeChitModel,
  initialDate: number,
  encounterID?: string,
  practitionerId?: string,
  onSave: (timeChit: TimeChitModel) => void,
  patientID?: string,
  onChangesMade: (changesMade: boolean) => void,
  showPrompt: boolean,
  onPromptCancel: () => void,
  onDiscard: () => void,
};

type State = {
  isSaving: boolean,
  showError: boolean,
  attributes: Attributes,
};

/**
   * Form for creating Time Chits.
   *
   * @namespace TimeChitForm
   */
class TimeChitForm extends React.Component<Props, State> {
  static defaultProps = {
    initialDate: (new Date()).getTime(),
  };

  /**
   * Creates an instance of TimeChitForm.
   * @param {Props} props props
   * @return {void}
   */
  constructor(props: Props) {
    super(props);
    const defaultLength = props.config.getIn(['timeChit', 'defaultLength'], 180);
    const startTime = props.currentModel !== undefined ?
      props.currentModel.get('start_time', props.initialDate) : props.initialDate;
    const attributes: Attributes = {
      start_time: startTime,
      end_time: startTime + (defaultLength * 60 * 1000),
      notes: props.currentModel !== undefined ? props.currentModel.get('notes', '') : '',
    };
    this.state = {
      isSaving: false,
      attributes,
      showError: false,
    };
  }

  /**
   * Called when the component will receive props. If initial date has changed it updates the value.
   * @param {Props} nextProps Nect props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.initialDate !== this.props.initialDate && this.props.currentModel === undefined) {
      this.onValueChanged('start_time', nextProps.initialDate);
    }
  }

  /**
   * Updates state with user entered value.
   * @param {string} key - An attribute key.
   * @param {any} value - Value entered by user.
   * @return {undefined}
   */
  onValueChanged(key: string, value: string | number) {
    const newModelState = this.state.attributes;
    newModelState[key] = value;
    this.setState({ attributes: newModelState });
    this.props.onChangesMade(true);
  }

  /**
     * Called when user clicks save. Checks validity of form. If valid it updates the current model or
     * creates a new one with the value of state.attributes transfered onto model.attributes. props.onSave
     * is then run with the new/updated mc as the first param. THE MODEL IS NOT SAVED WITHIN IN THIS
     * FUNCTION. MAKE SURE TO RUN mc.setSerialNumber before saving.If not valid then the appropriate
     * error messages are displayed.
     * @return {undefined}
     */
  onSaveClicked = () => {
    if (this.isFormValid()) {
      this.setState({ isSaving: true });
      const save = (model: TimeChitModel) => { // eslint-disable-line require-jsdoc
        createSuccessNotification(translate('time_chit_creation_success'));
        this.setState({ isSaving: false, showError: false });
        this.props.onPromptCancel();
        this.props.onSave(model);
        this.props.onChangesMade(false);
      };
      // Either used the supplied model or create a new one.
      if (this.props.currentModel) {
        save(this.props.currentModel.set(this.state.attributes));
      } else {
        const model = new TimeChitModel({
          patient_id: this.props.patientID,
          encounter_id: this.props.encounterID,
          practitioner_id: this.props.practitionerId,
        });
        save(model.set(this.state.attributes));
      }
    }
    // TODO: Handle invalid form.
  }

  /**
   * Validate form. Currently just checks data is not empty without warning.
   * @return {boolean} - true if valid, false otherwise.
   */
  isFormValid() {
    return true;
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    debugPrint('Rendering TimeChitForm');
    return (
      <div>
        <UnsavedDataModal
          visible={this.props.showPrompt}
          onSave={this.onSaveClicked}
          onDiscard={() => this.props.onDiscard()}
          onCancel={() => this.props.onPromptCancel()}
        />
        <section className="o-form">
          {this.state.showError && <div className="o-form__text-block o-form__text-block--error">{translate('fill_required_fields')}</div>}
          <div
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
            className="u-margin-bottom--4ws"
          >
            <Timepicker
              id="start_time"
              label={translate('from')}
              value={Moment(this.state.attributes.start_time)}
              onValueChanged={time => this.onValueChanged('start_time', time.valueOf())}
            />
            <Timepicker
              id="start_time"
              label={translate('to')}
              value={Moment(this.state.attributes.end_time)}
              onValueChanged={time => this.onValueChanged('end_time', time.valueOf())}
            />
          </div>
          <TextArea
            id="notes"
            label={translate('notes')}
            value={this.state.attributes.notes}
            onValueChanged={value => this.onValueChanged('notes', value)}
          />
        </section>
        <ModalFooter>
          <SaveButton
            onClick={() => this.onSaveClicked()}
            isSaving={this.state.isSaving}
            label={translate('save')}
            className="o-button--small u-margin-right--half-ws"
          />
        </ModalFooter>
      </div>
    );
  }
}

export default TimeChitForm;
