import React from 'react';

import translate from './../../utils/i18n';

import type { SaveModel } from './../../types';
import type CaseNoteFileModel from './../../models/caseNoteFileModel';
import Button from './../buttons/button';

type Props = {
  casenote: CaseNoteFileModel,
  saveModel: SaveModel,
}

type State = {
  isSaving: boolean,
}

/**
 * A star toggle button for a casenote model.
 * @class StarButton
 * @extends {React.Component<Props, State>}
 */
class StarButton extends React.Component<Props, State> {
  /**
   * Creates an instance of StarButton.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = { isSaving: false };
  }

  /**
   * Toggles the save status of the casenote.
   * @returns {void}
   */
  onClick() {
    if (!this.state.isSaving) {
      this.setState({ isSaving: true });
      this.props.saveModel(this.props.casenote.toggleStarred())
        .then(() => this.setState({ isSaving: false }));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const starred = this.props.casenote.isStarred();
    const img = starred ? 'icon_star' : 'icon_star_unchecked';
    return (
      <Button
        disabled={this.state.isSaving}
        onClick={() => this.onClick()}
        className="o-text-button"
        style={{ marginLeft: 'auto', marginRight: '20px' }}
        dataPublic
      >
        <img
          src={`static/images/${img}.png`}
          alt={starred ? translate('starred') : translate('star')}
          style={{ width: '30px', height: '30px' }}
        />
      </Button>
    );
  }
}

export default StarButton;
