/* eslint-disable no-underscore-dangle */
import React from 'react';
import { List, Map } from 'immutable';

import TextArea from './../inputs/textarea';
import SaveButton from './../buttons/saveButton';
import EncounterModel, { StageInfo } from './../../models/encounterModel';
import BillModel from './../../models/billModel';
import translate from './../../utils/i18n';
import { createSuccessNotification } from './../../utils/notifications';
import CurrentConsultActions from './currentConsultActions';
import IssuedItemsList from './../issuedItems/issuedItemsList';
import PrescriptionList from './../prescriptions/prescriptionList';
import SymptomsInput from './symptomsInput';
import DiagnosesInput from './diagnosesInput';
import SalesItemsList from '../salesItems/salesItemsList';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasPermission, hasSomePermission } from './../../utils/permissions';
import { alertEncounterStage, getUpdatedStagesWithNotes } from '../../utils/encounters';
import BillItemModel from './../../models/billItemModel';
import LabRequestsList from './../labRequests/labRequestsList';
import ProviderModel from './../../models/providerModel';
import ProcedureTypeModel from './../../models/procedureTypeModel';
import ProcedureRequestModel from './../../models/procedureRequestModel';
import SpecimenModel from './../../models/specimenModel';
import { sortByNumber } from './../../utils/comparators';
import QueueButtonContainer from './../../containers/queueButtonContainer';
import documentPrintFactory from './../hoc/documentPrintFactory';
import SavePrompt from '../prompts/savePrompt';
import { getConfirmation } from '../../utils/utils';

import type { InventoryCount, Config, SaveModel, MapValue, User, SaveModels, ActiveIngredient } from './../../types';
import type SalesItemModel from './../../models/salesItemModel';
import type ConditionModel from './../../models/conditionModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type TimeChitModel from './../../models/timeChitModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type DrugModel from './../../models/drugModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PractitionerModel from './../../models/practitionerModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type PatientModel from './../../models/patientModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DocumentDataModel from './../../models/documentDataModel';
import type ProcedureStatusModel from './../../models/procedureStatusModel';
import type CollectedSpecimenModel from './../../models/collectedSpecimenModel';
import type ProcedureResultModel from './../../models/procedureResultModel';
import type AppointmentModel from './../../models/appointmentModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type InventoryMapModel from './../../models/inventoryMapModel';
import type AllergyModel from './../../models/allergyModel';
import type DosingRegimenModel from '../../models/dosingRegimenModel';

type Patient = PatientModel | PatientStubModel;

export type Props = {
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  procedureRequests: List<ProcedureRequestModel>,
  procedureStatuses: List<ProcedureStatusModel>,
  collectedSpecimens: List<CollectedSpecimenModel>,
  specimens: List<SpecimenModel>,
  config: Config,
  klinifyConfig: Config,
  diagnoses: List<ConditionModel>,
  drugs: List<DrugModel>,
  encounter: EncounterModel,
  medicalCertificates: List<MedicalCertificateModel>,
  salesItems: List<SalesItemModel>,
  timeChits: List<TimeChitModel>,
  inventoryCount: InventoryCount,
  isOnline: boolean,
  prescriptions: List<PrescriptionModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  symptoms: List<ConditionModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  bill?: BillModel,
  billItems: List<BillItemModel>,
  coveragePayorID?: string,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  encounters: List<EncounterModel>,
  practitioners: List<PractitionerModel>,
  documentTemplates: List<DocumentTemplateModel>,
  documentData: List<DocumentDataModel>,
  patient: Patient,
  onPrintTimeChitClicked: (timeChitModel: TimeChitModel | null | undefined) => void,
  onPrintMedicalCertificateClicked: (
    medicalCertificateModel: MedicalCertificateModel | null | undefined,
  ) => void,
  onPrintPrescriptionLabelsClicked: (filter: Function) => void,
  procedureResults: List<ProcedureResultModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  appointment: AppointmentModel | void,
  verifiedDrugs: List<InventoryMapModel>,
  allergies: List<AllergyModel>,
  isCurrent: boolean,
  dosingRegimens: List<DosingRegimenModel>,
  patientPrescriptionHistory : List<PrescriptionModel>,
};

type State = {
  isSaving: boolean,
  notes: string | {[stageName: string]: string},
  changesMade: boolean,
  seeNextEncounterButtonEnabled: boolean,
  nextEncounter?: EncounterModel,
};

/**
 * A component representing an Encounter (i.e. consult) currently underway.
 * @class CurrentConsult
 * @extends {Component}
 */
class CurrentConsult extends React.Component<Props, State> {
  /**
   * Creates an instance of CurrentConsult.
   * @param {object} props The initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isSaving: false,
      changesMade: false,
      seeNextEncounterButtonEnabled: false,
      nextEncounter: undefined,
      notes: props.encounter.getNotes(props.practitioners),
    };
  }

  /**
   * Check for next encounter on component load
  * @return {void}
   */
  componentDidMount() {
    this.checkNextEncounter();
  }

  /**
   * Check for next encounter on component load
   * @param {Props} prevProps Previous props
   * @return {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (this.state.changesMade && prevProps.isCurrent && !this.props.isCurrent) {
      setTimeout(() => {
        getConfirmation(translate('encounter_updated_error'), {
          modalTitle: `${translate('error')} - ${translate('unable_to_save')}`,
          hideCancel: true,
          footerSaveButtonName: translate('close'),
        })
          .catch(() => {});
      }, 0);
    }
  }

  /**
   * Called when save is clicked on navigation change.
   * @returns {undefined}
   */
  onNavigationSaveClicked = (): Promise<boolean> => new Promise((resolve) => {
    const updatedEncounter = getUpdatedStagesWithNotes(this.props.encounter, this.state.notes);
    this.props.saveModel(updatedEncounter).then(() => {
      this.setState({ isSaving: false, changesMade: false });
      createSuccessNotification(translate('consult_notes_saved', { encounterLabel: this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter') }));
      return resolve(true);
    });
  });

  /**
   * Called when save is clicked. Saves the current consult notes
   * updates the encounter status.
   * @param {EncounterModel} nextEncounter If true, it should be redirect to next encounter and move it to "In Progress"
   * @returns {undefined}
   */
  onSaveClicked = (nextEncounter?: EncounterModel): Promise<boolean> => new Promise((resolve) => {
    const hasBillViewPermission = hasPermission(this.props.user,
      List([createPermission('unfinalised_bill', 'read')]));
    if (!this.props.encounter.lastEventIs('finished_consult') || this.state.changesMade) {
      let onSaveHash: string;
      if (this.props.encounter.lastEventIs('finished_consult')) {
        onSaveHash = '/';
      } else if (nextEncounter) {
        onSaveHash = `/patient/${nextEncounter.get('patient_id')}`;
      } else {
        onSaveHash = !hasBillViewPermission
          ? '/'
          : `/patient/${this.props.encounter.get('patient_id')}/billing/${this.props.encounter.get('_id')}`;
      }
      this.setState({ isSaving: true });
      const encountersModels: Array<EncounterModel> = [];
      const currentEncounterStages: List<StageInfo> = this.props.encounter.getActiveStages();
      const { currentStageIndex } = this.props.encounter.getCurrentStageIndex();
      const currentStage = currentEncounterStages.get(currentStageIndex);
      if (nextEncounter) {
        this.setState({ notes: nextEncounter.getNotes(List()) });
        const nextEncounterStage = currentStage && nextEncounter.getActiveStages().find(stage =>
          stage.stage_id === currentStage.stage_id);
        if (nextEncounterStage) { // If next encounter has this stage
          encountersModels.push(
            nextEncounter.addEvent('started')
              .addStage(nextEncounterStage.stage_id, nextEncounterStage._name)
              .addStageEvent('started'),
          );
        }
        this.checkNextEncounter();
      }
      let updatedEncounter = getUpdatedStagesWithNotes(this.props.encounter, this.state.notes);
      if (this.props.encounter.isLastStage()) {
        updatedEncounter = updatedEncounter.addStageEvent('completed').addEvent('finished_consult');
      } else {
        const nextStage = currentEncounterStages.get(currentStageIndex + 1);
        if (nextStage) {
          updatedEncounter = updatedEncounter.addStageEvent('completed').addStage(nextStage.stage_id, nextStage._name);
          const nextStageModel = this.props.encounterStageMap.get(nextStage.stage_id);
          alertEncounterStage(nextStageModel, 'waiting_alert');
          onSaveHash = '/';
        }
      }
      encountersModels.push(updatedEncounter);
      this.setState({ changesMade: false });
      this.props.saveModels([...encountersModels]).then(() => {
        this.setState({ isSaving: false });
        createSuccessNotification(translate('consult_notes_saved', { encounterLabel: this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter') }));
        location.hash = onSaveHash;
        return resolve(true);
      })
        .catch(() => {
          this.setState({ isSaving: false, changesMade: true });
        });
    }
  });

  /**
   * Check for next patient to move into "In Progress"
   * @returns {undefined}
   */
  checkNextEncounter() {
    let nextEncounters = List();
    const currentStageId = this.props.encounter.getValue('stage_id');
    if (this.props.encounters) {
      this.props.encounters.filter((encounter) => {
        if (encounter.isCurrent()
        && encounter.get('_id') !== this.props.encounter.get('_id')
        && encounter.get('patient_id') !== this.props.encounter.get('patient_id')
        && encounter.highestEventIs('arrived')
        && encounter.getValue('stage_id') === currentStageId) {
          this.props.practitioners.filter((practitioner) => {
            if (practitioner.has('user_id')
              && practitioner.get('user_id') === this.props.user.get('id')
              && practitioner.get('_id') === encounter.getValue('doctor')
            ) {
              nextEncounters = nextEncounters.push(encounter);
            }
            return false;
          });
        }
        return false;
      });
    }
    if (nextEncounters.size) {
      const nextEncounter = nextEncounters
        .sort((a, b) => (sortByNumber(a.getLastEventTime(), b.getLastEventTime())))
        .toArray()[0];
      this.setState({
        seeNextEncounterButtonEnabled: true,
        nextEncounter,
      });
    } else {
      this.setState({
        seeNextEncounterButtonEnabled: false,
        nextEncounter: undefined,
      });
    }
  }

  /**
   * Renders consult notes field
   * @param {EncounterModel} encounter EncounterModel
   * @returns {JSX.Element}
   */
  renderNotes(encounter: EncounterModel) {
    // Render single field if encounter has `notes` field (Old docs)
    if (hasPermission(this.props.user, List([createPermission('current_encounter_notes', 'update')])) && typeof this.state.notes === 'string') {
      return (
        <TextArea
          id="encounter-notes-text-input"
          label={`${this.props.config.getIn(['encounters', 'labels', 'encounter'], 'Encounter')} ${translate('notes')}`}
          onValueChanged={(value) => {
            this.setState({
              notes: value,
              changesMade: true,
            });
          }}
          value={this.state.notes || ''}
          disabled={!hasPermission(this.props.user, List([createPermission('current_encounter_notes', 'update')]))}
        />
      );
    }
    return encounter.getActiveStages().map((stageInfo) => {
      const stage = this.props.encounterStageMap.get(stageInfo.stage_id);
      if (stage && typeof this.state.notes !== 'string') {
        const hasStageNotes = this.state.notes[stageInfo.name];
        const isNotesEnabled = stage.get('has_notes');
        if (hasStageNotes || isNotesEnabled) {
          return (
            <TextArea
              key={stageInfo._name}
              id={`encounter-notes-${stageInfo._name}`}
              label={`${stageInfo._name} ${translate('notes')}`}
              onValueChanged={(value) => {
                this.setState({
                  notes: Object.assign(this.state.notes, {
                    [stageInfo._name]: value,
                  }),
                  changesMade: isNotesEnabled,
                });
              }}
              value={this.state.notes[stageInfo._name] || ''}
              disabled={
                !(hasPermission(this.props.user, List([createPermission('current_encounter_notes', 'update')]))
                || isNotesEnabled)
              }
            />
          );
        } return null;
      } return null;
    });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const { appointment } = this.props;
    const isTeleConsult = appointment && appointment.isTeleConsult();
    const shouldRenderCompleteEncounterSaveButton = (isTeleConsult && this.props.encounter.getValue('doctor')) ||
      (!isTeleConsult && (this.props.encounter.getValue('location') && this.props.encounter.getValue('doctor')));
    const currentStageName = this.props.encounter.getValue('name') || '';
    return (
      <div>
        <SavePrompt
          when={this.state.changesMade}
          onSaveClicked={() => this.onNavigationSaveClicked()}
        />
        <div className="o-card">
          <h2 className="o-card__title">
            {`${translate('current')} - ${this.props.encounter.getEncounterType(this.props.salesItems)} - ${translate(this.props.encounter.getLastEventType())} - ${this.props.encounter.getDoctorName(this.props.practitioners)}`}
          </h2>
          <div className="u-padding--1ws">
            <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_conditions', 'read')])} user={this.props.user}>
              <SymptomsInput
                config={this.props.config}
                encounter={this.props.encounter}
                saveModel={this.props.saveModel}
                symptoms={this.props.symptoms}
                updateConfigValue={this.props.updateConfigValue}
                updateConfig={this.props.updateConfig}
                disabled={!hasPermission(this.props.user, List([createPermission('current_encounter_conditions', 'update')]))}
              />
              <DiagnosesInput
                config={this.props.config}
                diagnoses={this.props.diagnoses}
                encounter={this.props.encounter}
                saveModel={this.props.saveModel}
                updateConfigValue={this.props.updateConfigValue}
                updateConfig={this.props.updateConfig}
                disabled={!hasPermission(this.props.user, List([createPermission('current_encounter_conditions', 'update')]))}
              />
            </PermissionWrapper>
            <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_notes', 'read')])} user={this.props.user}>
              {this.renderNotes(this.props.encounter)}
            </PermissionWrapper>
          </div>
          <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_prescriptions', 'read')])} user={this.props.user}>
            <PrescriptionList
              coveragePayorID={this.props.coveragePayorID}
              coveragePayors={this.props.coveragePayors}
              config={this.props.config}
              klinifyConfig={this.props.klinifyConfig}
              drugs={this.props.drugs}
              practitioners={this.props.practitioners}
              encounter={this.props.encounter}
              encounters={this.props.encounters}
              dosingRegimens={this.props.dosingRegimens}
              prescriptions={this.props.prescriptions}
              saveModel={this.props.saveModel}
              saveModels={this.props.saveModels}
              isOnline={this.props.isOnline}
              inventoryCount={this.props.inventoryCount}
              showLowStockWarning
              user={this.props.user}
              isFromCurrentEncounter
              updateConfigValue={this.props.updateConfigValue}
              onPrint={this.props.onPrintPrescriptionLabelsClicked}
              updateConfig={this.props.updateConfig}
              verifiedDrugs={this.props.verifiedDrugs}
              allergies={this.props.allergies}
              patientPrescriptionHistory={this.props.patientPrescriptionHistory}
            />
          </PermissionWrapper>
          <br />
          <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_sales_items', 'read')])} user={this.props.user}>
            {
              this.props.bill
              && this.props.config.getIn(['bills', 'showSalesItemsOnEncountersPage'], false)
              && <SalesItemsList
                billItems={this.props.billItems.filter(i => i.isSalesItem())}
                salesItems={this.props.salesItems}
                config={this.props.config}
                inventoryCount={this.props.inventoryCount}
                coveragePayors={this.props.coveragePayors}
                saveModel={this.props.saveModel}
                saveModels={this.props.saveModels}
                user={this.props.user}
                patientID={this.props.encounter.get('patient_id')}
                billID={this.props.encounter.get('bill_id')}
                updateConfigValue={this.props.updateConfigValue}
                updateConfig={this.props.updateConfig}
              />
            }
          </PermissionWrapper>
          <br />
          <PermissionWrapper permissionsRequired={List([createPermission('current_encounter_lab_tests', 'read')])} user={this.props.user}>
            {
              this.props.bill
              && <LabRequestsList
                providers={this.props.providers}
                procedureTypes={
                  this.props.procedureTypes
                }
                procedureRequests={this.props.procedureRequests}
                procedureStatuses={this.props.procedureStatuses}
                collectedSpecimens={this.props.collectedSpecimens}
                procedureResults={this.props.procedureResults}
                specimens={this.props.specimens}
                saveModel={this.props.saveModel}
                saveModels={this.props.saveModels}
                user={this.props.user}
                config={this.props.config}
                patientID={this.props.encounter.get('patient_id')}
                patientName={this.props.patient.get('patient_name')}
                encounterID={this.props.encounter.get('_id')}
                updateConfig={this.props.updateConfig}
              />
            }
          </PermissionWrapper>
        </div>
        {
          hasSomePermission(this.props.user, List([createPermission('current_encounter_medical_certificates', 'create'), createPermission('current_encounter_time_chits', 'create')]))
          && <CurrentConsultActions
            config={this.props.config}
            encounter={this.props.encounter}
            patient={this.props.patient}
            documentTemplates={this.props.documentTemplates}
            hasMedicalCertificate={this.props.medicalCertificates.size > 0}
            hasTimeChit={this.props.timeChits.size > 0}
            saveModel={this.props.saveModel}
            user={this.props.user}
            isOnline={this.props.isOnline}
          />
        }
        {
          hasSomePermission(
            this.props.user,
            List([
              createPermission('current_encounter_medical_certificates', 'read'),
              createPermission('current_encounter_time_chits', 'read'),
              createPermission('document_templates', 'read'),
            ]),
          )
          && (
            this.props.medicalCertificates.size + this.props.timeChits.size
            + this.props.documentData.size
          ) > 0
          && (
            <div className="o-card">
              <h2 data-public className="o-card__title">{translate('issued_items')}</h2>
              <div className="o-data-list__row o-data-list__row--header">
                <div className="o-data-list__row__item o-data-list__row__item--small">{ translate('item_issued') }</div>
                <div className="o-data-list__row__item">{ translate('description') }</div>
                <div className="o-data-list__row__actions" />
              </div>
              <IssuedItemsList
                config={this.props.config}
                medicalCertificates={this.props.medicalCertificates}
                documentData={this.props.documentData}
                timeChits={this.props.timeChits}
                saveModel={this.props.saveModel}
                user={this.props.user}
                documentTemplates={this.props.documentTemplates}
                onPrintMC={this.props.onPrintMedicalCertificateClicked}
                onPrintTC={this.props.onPrintTimeChitClicked}
                isCurrentEncounter
              />
            </div>
          )
        }
        <PermissionWrapper
          permissionsRequired={
            this.props.encounter.lastEventIs('finished_consult')
              ? List([createPermission('unfinalised_bill', 'read')])
              : List([createPermission('queue_patient_for_today', 'update')])
          }
          user={this.props.user}
        >
          <div className="u-margin--standard">
            {!this.props.encounter.lastEventIs('finished_consult')
            && !shouldRenderCompleteEncounterSaveButton
            && <QueueButtonContainer
              patient={this.props.patient}
              isBillingValidation
              encounterLocation={isTeleConsult ?
                undefined : appointment ? appointment.get('location') :
                  this.props.encounter.getValue('location')}
              encounterID={this.props.encounter.get('_id')}
              encounterDoctor={appointment ?
                appointment.get('practitioner_id') : this.props.encounter.getValue('doctor')}
              buttonLabel={translate('complete_stage', { stage: currentStageName })}
              onSuccess={() => this.onSaveClicked()}
              appointment={appointment}
            />
            }
            {!this.props.encounter.lastEventIs('finished_consult')
             && shouldRenderCompleteEncounterSaveButton
             && <SaveButton
               className="o-button--small"
               label={translate('complete_stage', { stage: currentStageName })}
               isSaving={this.state.isSaving}
               onClick={() => this.onSaveClicked()}
               fullWidth
             />
            }
          </div>
          <div className="u-margin--standard">
            {
              !this.props.encounter.lastEventIs('finished_consult')
              && !this.state.seeNextEncounterButtonEnabled &&
              shouldRenderCompleteEncounterSaveButton &&
              <SaveButton
                className="o-button--small"
                label={translate('complete_encounter_stage_and_see_next_patient', { stage: currentStageName })}
                isSaving={this.state.isSaving}
                fullWidth
                disabled
                onClick={() => {
                  if (this.state.nextEncounter) {
                    this.onSaveClicked(this.state.nextEncounter);
                  }
                }}
              />
            }
            {
              !this.props.encounter.lastEventIs('finished_consult')
              && this.state.seeNextEncounterButtonEnabled
              && !shouldRenderCompleteEncounterSaveButton
              && <QueueButtonContainer
                patient={this.props.patient}
                isBillingValidation
                encounterLocation={isTeleConsult ?
                  undefined : appointment ? appointment.get('location') :
                    this.props.encounter.getValue('location')}
                encounterID={this.props.encounter.get('_id')}
                encounterDoctor={appointment ?
                  appointment.get('practitioner_id') : this.props.encounter.getValue('doctor')}
                buttonLabel={translate('complete_encounter_stage_and_see_next_patient', { stage: currentStageName })}
                onSuccess={() => {
                  if (this.state.nextEncounter) {
                    this.onSaveClicked(this.state.nextEncounter);
                  }
                }}
              />
            }
            {
              !this.props.encounter.lastEventIs('finished_consult')
              && this.state.seeNextEncounterButtonEnabled
              && this.props.encounter.getValue('location') && this.props.encounter.getValue('doctor')
              && <SaveButton
                className="o-button--small"
                label={translate('complete_encounter_stage_and_see_next_patient', { stage: currentStageName })}
                isSaving={this.state.isSaving}
                onClick={() => {
                  if (this.state.nextEncounter) {
                    this.onSaveClicked(this.state.nextEncounter);
                  }
                }}
                fullWidth
                disabled={!this.state.seeNextEncounterButtonEnabled}
              />
            }
          </div>
        </PermissionWrapper>
      </div>
    );
  }
}

export default documentPrintFactory(CurrentConsult);
