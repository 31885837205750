import React from 'react';
import glamorous from 'glamorous';
import RCTimePicker from 'rc-time-picker';
import Moment from 'moment';
import { SingleDatePicker } from 'react-dates';

import { getDateFormat } from './../../utils/time';
import Label from './label';

type Props = {
  id: string,
  className: string,
  label: string,
  labelClassName: string,
  style: { [key: string]: string | number },
  value: Moment | void,
  onValueChanged: (newValue: Moment | void) => void,
};

type State = {
  isFocused: boolean,
};

const Wrapper = glamorous.div({
  '& .rc-time-picker-input': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    lineHeight: 1.65,
  },
  '& .SingleDatePickerInput': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
  },
  '& .DateInput': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 'none',
  },
});

/**
 * A component for selecting a date and time.
 * @class DateTimePicker
 * @extends {React.Component<Props, State>}
 */
class DateTimePicker extends React.Component<Props, State> {
  static defaultProps = {
    style: {},
    className: '',
    labelClassName: '',
  };

  /**
   * Creates an instance of DateTimePicker. Creates state with an isFocused parameter.
   * @param {any} props Props for the component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  /**
   * Handles date change as react-dates will reset the time values.
   * @param {Moment | void} newValue The new value from DatePicker.
   * @return {void}
   */
  onDateChange = (newValue: Moment | void) => {
    const { value, onValueChanged } = this.props;
    if (newValue && value) {
      newValue.set({
        hour: value.get('hour'),
        minute: value.get('minute'),
        second: value.get('second'),
        millisecond: value.get('millisecond'),
      });
    }
    onValueChanged(newValue);
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { id, className, style, labelClassName, label, value, onValueChanged } = this.props;
    return (
      <div style={style} className={className}>
        <Label className={labelClassName} id={id} label={label} />
        <Wrapper>
          <SingleDatePicker
            id={id}
            date={value}
            focused={this.state.isFocused}
            onDateChange={this.onDateChange}
            onFocusChange={state => this.setState({ isFocused: state.focused })}
            numberOfMonths={1}
            displayFormat={getDateFormat()}
            showClearDate={false}
            isOutsideRange={() => false}
          />
          <RCTimePicker
            id={`${id}_time`}
            value={value}
            onChange={newValue => onValueChanged(newValue)}
            allowEmpty={false}
            showSecond={false}
          />
        </Wrapper>
      </div>
    );
  }
}

export default DateTimePicker;
