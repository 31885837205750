import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import Table from './../table/table';
import FormError from './../formError';
import ContentTransition from './../contentTransition';
import { getSupplierSummary } from './../../utils/api';
import { debugPrint } from './../../utils/logging';
import { renderWithLink } from './../../utils/tables';
import { UNICODE } from './../../constants';
import type SupplierModel from './../../models/supplierModel';
import type { Config } from './../../types';

type Props = {
  suppliers: List<SupplierModel>,
  config: Config,
}

/* eslint-disable camelcase */
type SupplierSummary = {
  supplier_id?: string,
  supplier_name?: string,
  credit_limit?: string,
  credit_availability?: string,
  currency?: string,
  displayAsError?: boolean,
  msg?: string,
}

type State = {
  suppliers_summary: Array<SupplierSummary>,
  loading_failed: boolean,
};
/* eslint-enable camelcase */

/**
 * A Supplier form component
 * @class SupplyInvoicesList
 * @extends {React.Component<Props, State>}
 */
class SupplyInvoicesList extends React.Component<Props, State> {
  /**
   * Creates an instance of SupplyInvoicesList.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      suppliers_summary: [],
      loading_failed: false,
    };
    this.fetchSupplierSummary();
  }

  /**
   * returns list of suppliers having integration enabled.
   * @returns {List<SupplierModel>}
   */
  getIntegrationEnabledSuppliers(): List<SupplierModel> {
    return this.props.suppliers.filter(supplier =>
      supplier.get('integrations') !== undefined &&
      Object.keys(supplier.get('integrations')) &&
      this.props.config.getIn(['suppliersForIntegration'], List()).toJS().includes(Object.keys(supplier.get('integrations'))[0]));
  }

  /**
   * Checks if there is any supplier having integration enabled.
   * @returns {boolean}
   */
  isSupplierIntegrationEnabled(): boolean {
    const SuppliersIntegrationEnabled = this.getIntegrationEnabledSuppliers();
    if (SuppliersIntegrationEnabled && SuppliersIntegrationEnabled.size > 0) {
      return true;
    }
    return false;
  }

  /**
   * Checks for specific error message from backend for invalid integration id and empty id.
   * This message is displayed in table row for the corresponding supplier
   * @param {string} msg the error message
   * @returns {boolean}
   */
  isErrorMessageInSummary(msg: string): boolean {
    if (msg && (msg.toLowerCase().indexOf('no integration available for') !== -1 || msg.split('No data found for customer id').length - 1 === 3)) {
      return true;
    }
    return false;
  }

  /**
   * Checks for specific error message from backend for invalid integration id and empty id.
   * And returns corresponding front end error message to display.
   * @param {string} msg the error message
   * @returns {string}
   */
  getErrorMessageToDisplay(msg?: string): string {
    if (msg && msg.split('No data found for customer id').length - 1 === 3) {
      return translate('integration_id_does_not_exist');
    }
    if (msg && msg.toLowerCase().indexOf('no integration available for') !== -1) {
      return translate('no_integration_id_set');
    }
    return msg !== undefined ? msg : '';
  }

  /**
   * Validates the item currently being edited. Currently it just requires it to be non-empty.
   * @returns {boolean} True if valid, false otherwise.
   */
  fetchSupplierSummary() {
    this.setState({
      loading_failed: false,
    });
    Promise.all(
      this.getIntegrationEnabledSuppliers().map(supplier => getSupplierSummary(supplier.get('_id'))
        .then((response) => {
          if (response) {
            if (response.error && response.msg) {
              if (this.isErrorMessageInSummary(response.msg)) {
                return Object.assign({}, response, { supplier_name: supplier.get('name', ''), supplier_id: supplier.get('_id', ''), displayAsError: true, msg: response.msg });
              }
            } else if ((response.error && response.detailedText) || response.error) {
              if (response.detailedText && this.isErrorMessageInSummary(response.detailedText)) {
                return Object.assign({}, response, { supplier_name: supplier.get('name', ''), supplier_id: supplier.get('_id', ''), displayAsError: true, msg: response.detailedText });
              }
              this.setState({
                loading_failed: true,
              });
              if (response.detailedText) {
                debugPrint(response.detailedText, 'error');
              }
            } else if (response.error) { // this is either status 500 or 504
              this.setState({
                loading_failed: true,
              });
            }
            return Object.assign({}, response, { supplier_name: supplier.get('name', ''), supplier_id: supplier.get('_id', '') });
          }
          return Object.assign({}, { supplier_name: supplier.get('name', ''), supplier_id: supplier.get('_id', '') });
        })),
    )
      .then((results) => {
        if (results && results.length > 0) {
          this.setState({
            suppliers_summary: results,
          });
        }
      }).catch(() => this.setState({ loading_failed: true }));
  }

  /**
   * Returns a row for an individual supplierSummary
   * @param {SupplierSummary} supplierSummary an individual supplierSummary.
   * @returns {{}}
   */
  getRow(supplierSummary: SupplierSummary) {
    const supplierId = supplierSummary.supplier_id ?
      supplierSummary.supplier_id : '';
    const link = supplierSummary.displayAsError ? '' :
      `/inventory/supply-invoices/${supplierId}`;
    const emptyValue = supplierSummary.displayAsError ? '' : UNICODE.EMDASH;
    const creditLimit = supplierSummary.displayAsError ?
      this.getErrorMessageToDisplay(supplierSummary.msg) : UNICODE.EMDASH;
    return {
      supplierName: supplierSummary.supplier_name,
      creditLimit: supplierSummary.credit_limit !== undefined
        && supplierSummary.credit_limit !== null ?
        supplierSummary.credit_limit : creditLimit,
      creditAvailability: supplierSummary.credit_availability !== undefined
        && supplierSummary.credit_availability !== null ?
        supplierSummary.credit_availability : emptyValue,
      link,
      displayAsError: supplierSummary.displayAsError,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const summaryWithCurrency = this.state.suppliers_summary.find(summary => summary.currency);
    const currency = summaryWithCurrency && summaryWithCurrency.currency ?
      summaryWithCurrency.currency : '';
    const creditLimit = currency ? `${translate('credit_limit')} (${currency})` :
      `${translate('credit_limit')}`;
    const creditAvailability = currency ? `${translate('credit_availability')} (${currency})` :
      `${translate('credit_availability')}`;
    const supplierIntegrated = this.isSupplierIntegrationEnabled();
    return (
      <ContentTransition>
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          {
            !supplierIntegrated &&
            <div className="o-text-content">{translate('no_supplier_integrated')}</div>
          }
          {
            supplierIntegrated &&
            <div>
              <h1 data-public className="o-title">{translate('supply_invoices')}</h1>
              <div className="o-card u-margin-bottom--4ws">
                {
                  this.state.loading_failed &&
                  <FormError>
                    {translate('problem_loading_suppliers_list')}
                  </FormError>
                }
                {
                  !this.state.loading_failed &&
                  <h2 data-public className="o-card__title">{translate('suppliers')}</h2>
                }
                {
                  !this.state.loading_failed &&
                  <Table
                    columns={[
                      { accessor: 'supplierName', Header: `${translate('supplier_name')}`, filterable: true, Cell: renderWithLink },
                      { accessor: 'creditLimit', Header: `${creditLimit}`, filterable: true, Cell: renderWithLink },
                      { accessor: 'creditAvailability', Header: `${creditAvailability}`, filterable: true, Cell: renderWithLink },
                    ]}
                    data={this.state.suppliers_summary.map(s => this.getRow(s))}
                    noDataText={this.state.suppliers_summary && this.state.suppliers_summary.length > 0 ? translate('no_items') : translate('loading...')}
                    showPagination
                    defaultSorted={[{ id: 'supplier_name', desc: false }]}
                  />
                }
              </div>
            </div>
          }
        </section>
      </ContentTransition>
    );
  }
}

export default SupplyInvoicesList;
