import React from 'react';
import { List, fromJS } from 'immutable';
import { Link } from 'react-router-dom';


import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import Table from './../table/table';
import StatelessModal from './../modals/statelessModal';
import UserGroupForm from './userGroupForm';
import { createPermission, hasPermission } from './../../utils/permissions';
import { isKlinifyUser } from './../../utils/auth';
import Button from './../buttons/button';
import Header from './../header/header';

import type UserGroupModel from './../../models/userGroupModel';
import type { SaveModel, User, Config } from './../../types';

type Props = {
  userGroups: List<UserGroupModel>,
  saveModel: SaveModel,
  user: User,
  config: Config,
};

type State = {
  isUserGroupModalVisible: boolean,
  userGroupToEdit?: UserGroupModel,
};

type Row = {
  name: string,
  edit: React$Node,
  changePermissions: React$Element<typeof Link>
}
/**
 * A component that displays a list of all user groups and allows them to be edited, deleted, and
 * added.
 * @class UserGroups
 * @extends {React.Component<Props, State>}
 */
class UserGroups extends React.Component<Props, State> {
  /**
   * Creates an instance of UserGroups.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isUserGroupModalVisible: false,
    };
  }

  /**
   * A button that toggles the edit modal for the given user group.
   * @param {UserGroupModel} userGroup A user group
   * @returns {React.Component}
   */
  getEditButton(userGroup: UserGroupModel) {
    return (
      <Button
        className="o-text-button o-text-button--contextual"
        onClick={() => this.setState({
          userGroupToEdit: userGroup,
          isUserGroupModalVisible: true,
        })
        }
        dataPublic
      >
        {translate('edit')}
      </Button>
    );
  }

  /**
   * Gets the rows for the user table.
   * @param {List<UserGroupModel>} userGroups A list of available user groups.
   * @returns {Array<Row>}
   */
  getRows(userGroups: List<UserGroupModel>): Array<Row> {
    return userGroups
      .map(userGroup => ({
        name: userGroup.get('name'),
        ...(
          (isKlinifyUser(this.props.user) || this.props.config.getIn(['access_management', 'user_management', 'user_groups', 'sub_items', 'rename'], true)) &&
            { edit: this.getEditButton(userGroup) }
        ),
        ...(
          (isKlinifyUser(this.props.user) || this.props.config.getIn(['access_management', 'user_management', 'user_groups', 'sub_items', 'change_permissions'], true)) &&
          { changePermissions: <Link to={`/admin/user-groups/${userGroup.get('_id')}`} className="o-text-button o-text-button--contextual" style={{ fontSize: '11px' }}>{translate('change_permissions')}</Link> }
        ),
      }))
      .toArray();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const columns = fromJS([{ accessor: 'name', Header: translate('name') }]).toJS();
    if (hasPermission(this.props.user, List([createPermission('user_groups', 'update')]))) {
      columns.push({
        accessor: 'changePermissions', Header: '', sortable: false, minWidth: 200, width: 200,
      });
      columns.push({
        accessor: 'edit', Header: '', sortable: false, minWidth: 200, width: 200,
      });
    }
    // if (hasPermission(this.props.user, List([createPermission('user_groups', 'delete')]))) {
    //   columns.push({ accessor: 'delete', Header: '', sortable: false, minWidth: 200, width: 200 });
    // }
    return (
      <ContentTransition className="o-scrollable-container" style={{ height: '100vh' }}>
        <Header className="o-header" dataPublic>
          <h1>{translate('user_groups')}</h1>
          <StatelessModal
            id="addNewUserModal"
            buttonLabel={translate('add_new_user_group')}
            buttonClass="o-button o-button--small"
            title={translate('add_new_user_group')}
            visible={this.state.isUserGroupModalVisible}
            setVisible={isVisible => this.setState({ isUserGroupModalVisible: isVisible })}
            explicitCloseOnly
            // noButton={!isKlinifyUser(this.props.user) || !hasPermission(this.props.user, List([createPermission('user_groups', 'create')]))}
            noButton={
              isKlinifyUser(this.props.user) ? false :
                !((this.props.config.getIn(['access_management', 'user_management', 'user_groups', 'sub_items', 'create'], true) &&
                hasPermission(this.props.user, List([createPermission('user_groups', 'create')]))))
            }
            dataPublicHeader
          >
            <UserGroupForm
              saveModel={(model: UserGroupModel) => this.props.saveModel(model)
                .then(() => {
                  this.setState({ isUserGroupModalVisible: false, userGroupToEdit: undefined });
                  return true;
                })
                .catch(() => false)
              }
              userGroup={this.state.userGroupToEdit}
            />
          </StatelessModal>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('users')}</h2>
          <Table
            columns={columns}
            data={this.getRows(this.props.userGroups)}
            noDataText={translate('no_user_groups_created')}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default UserGroups;
