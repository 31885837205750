import React, { useState, useEffect } from 'react';
import glamorous from 'glamorous';
import { scaledH3, colours } from './../../utils/css';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';

import type { HTMLStyle } from './../../types';

const CheckButton = glamorous.button({
  backgroundColor: colours.maroon,
});

const ModalContainer = glamorous.div({
  padding: '2rem',
});

const ModalImage = glamorous.div({
  margin: '-2rem -2rem 2rem',
});

const ModalHeader = glamorous.h1({
  fontSize: scaledH3,
  fontWeight: 'bold',
  fontFamily: '"roboto", sans-serif',
  marginBottom: '1rem',
});

/**
 * Append program to seen key
 * @param {string} program name of the program
 * @returns {string} Seen key
 */
export const getSeenKey = (program: string) => `${program}-intro-modal-seen`;

type Props = {
  program: string,
  onExplicitClose?: () => void;
  footerButtonLabel?: String,
  modalHeading?: string,
  id: string,
  children: React.ReactNode,
  image?: React.ReactNode,
  saveInSessionStorage?: boolean,
  onClose?: () => void,
  modalTitle?: string,
  modalStyle?: HTMLStyle,
  onModalClose?: () => void,
  show?: boolean, // If true, shows the popup even if storage has the program key
  footer?: React.ReactNode,
  explicitCloseOnly?: boolean,
  buttonClassName?: string,
  noCloseButton?: boolean,
};

/**
 * Render the banner
 * @param {string} program text to add
 * @returns {HTMLElement} html element
 */
const AnnouncementModal = ({
  program, onExplicitClose,
  footerButtonLabel, children, id, modalHeading, image, modalTitle,
  modalStyle, onModalClose, saveInSessionStorage, onClose, show = false,
  explicitCloseOnly,
  buttonClassName = '',
  noCloseButton,
  footer = null,
}: Props) => {
  const [isVisible, setVisible] = useState(false);

  const storage = saveInSessionStorage ? window.sessionStorage : window.localStorage;

  useEffect(() => {
    const isSeen = storage.getItem(getSeenKey(program)) === 'true';
    setVisible(!isSeen);
  }, []);

  /**
   * Handle click
   * @param {boolean} explicitClose should redirect
   * @returns {void} void sets seen key & closes modal
   */
  const handleClick = (explicitClose: boolean) => {
    storage.setItem(getSeenKey(program), 'true');
    setVisible(false);
    if (explicitClose) {
      onExplicitClose();
    } else if (onClose) {
      onClose();
    }
    if (!explicitClose && onModalClose) {
      onModalClose();
    }
  };


  return (
    <StatelessModal
      title={modalTitle || ''}
      setVisible={() => { }}
      onClose={() => handleClick(false)}
      visible={isVisible || show}
      id={id}
      noButton
      style={modalStyle || { maxWidth: 540 }}
      explicitCloseOnly={explicitCloseOnly}
      noCloseButton={noCloseButton}
    >
      <ModalContainer>
        {image &&
          <ModalImage>
            {image}
          </ModalImage>
        }
        <ModalHeader>{modalHeading || null}</ModalHeader>
        {children || null}
      </ModalContainer>
      {footerButtonLabel &&
        <ModalFooter>
          {footer}
          <CheckButton className={`o-button o-button--padded ${buttonClassName}`} onClick={() => handleClick(true)}>
            {footerButtonLabel || null}
          </CheckButton>
        </ModalFooter>
      }
    </StatelessModal>
  );
};

export default AnnouncementModal;
