import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import SelectTable from './../table/selectTable';
import { sum, sumAndFormatPrice } from './../../utils/utils';
import { renderWithLink, renderPriceWithLink } from './../../utils/tables';
import Header from './../header/header';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type ClaimModel from './../../models/claimModel';
import { debugPrint } from '../../utils/logging';

type Row = {
  _id: string,
  panelName: string,
  noOfBills: number,
  totalAmount: string,
  link: String,
};

type Props = {
  uncreatedCoveragePanels: List<CoveragePayorModel>,
  onClickRow: (row: any) => void, // eslint-disable-line
  getSelectedRows: (selectedRows: Array<string>) => void,
  unfilteredClaims: List<ClaimModel>,
};

/**
 * Component displaying all Claim Invoices.
 * @class AddClaimInvoiceMultipleTable
 * @extends {React.Component}
 */
class AddClaimInvoiceMultipleTable extends React.PureComponent<Props> {
  /**
   * Generates a table row from the uncreatedCoveragePanels.
   * @param {CoveragePayorModel} coveragePayor A coveragePayor model
   * @returns {Row}
   */
  getRow(coveragePayor: CoveragePayorModel): Row {
    const claims = this.props.unfilteredClaims.filter(uc => uc.get('coverage_payor_id') === coveragePayor.get('_id') && uc.canBeAssignedToInvoice());
    return {
      _id: coveragePayor.get('_id'),
      panelName: coveragePayor.get('name'),
      noOfBills: claims.size,
      totalAmount: String(sum(claims.map(c => c.get('amount_due')))),
      link: '/accounts/claim-invoices/add',
    };
  }

  /**
   * Get Total to be claimed
   * @returns {string} the total sum of all values of "Total amount"
   */
  getTotalToBeClaimed() {
    let claims = List();
    this.props.uncreatedCoveragePanels.map(u => this.props.unfilteredClaims.filter((uc) => {
      if (uc.get('coverage_payor_id') === u.get('_id') &&
        uc.canBeAssignedToInvoice()) {
        claims = claims.push(uc);
      }
      return false;
    }));
    return sumAndFormatPrice(claims.map(c => c.get('amount_due')));
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering AddClaimInvoiceMultipleTable');
    const noDataText = 'no_items';
    return (
      <div>
        <Header className="o-card__header">
          <h1 className="o-card__title" data-public>{translate('claims')}</h1>
          <span className="o-card__header__right-aligned-text">
            {`${translate('total_to_be_claimed')}`}: <span className="u-strong">{this.getTotalToBeClaimed()}</span>
          </span>
        </Header>
        <SelectTable
          columns={[
            { accessor: 'panelName', Header: translate('panel_name'), Cell: renderWithLink },
            { accessor: 'noOfBills', Header: translate('no_of_bills'), Cell: renderWithLink },
            { accessor: 'totalAmount', Header: translate('total_amount'), Cell: renderPriceWithLink },
          ]}
          data={this.props.uncreatedCoveragePanels.map(c => this.getRow(c)).toArray()}
          noDataText={translate(noDataText)}
          showPagination
          defaultSorted={[{ id: 'panelName', desc: false }]}
          getSelectedRows={selectedRows => this.props.getSelectedRows(selectedRows)}
          onClickRow={row => this.props.onClickRow(row)}
        />
      </div>
    );
  }
}

export default AddClaimInvoiceMultipleTable;
