
/**
 * Verifies the current browser with the given browser name.
 * Using feature detection instead of plain User agent checks to enhance reliability.
 * @param {string} browser Browser name
 * @returns {boolean}
 */
export default function browserIs(browser: string): boolean {
  switch (browser) {
    case 'chrome':
      return !!window.chrome;
    case 'firefox':
      return typeof window.InstallTrigger !== 'undefined';
    case 'safari':
      return /constructor/i.test(window.HTMLElement) || (function hasRemoteNotificationFeature(p) { return p.toString() === '[object SafariRemoteNotification]'; }(!window.safari || (typeof window.safari !== 'undefined' && window.safari.pushNotification)));
    case 'ie':
      return /* @cc_on!@ */false || !!document.documentMode;
    case 'edge':
      return !(/* @cc_on!@ */false || !!document.documentMode) && !!window.StyleMedia;
    case 'opera':
      return (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    default:
      return false;
  }
}
