import React from 'react';
import { List } from 'immutable';

import SalesItemsListItem from './salesItemsListItem';
import translate from './../../utils/i18n';
import SalesItemsFormModal from './salesItemsFormModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import Header from './../header/header';

import type SalesItemModel from './../../models/salesItemModel';
import type BillItemModel from './../../models/billItemModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { Config, InventoryCount, SaveModel, SaveModels, User, MapValue } from './../../types';

type Props = {
  config: Config,
  billItems: List<BillItemModel>,
  disableChanges: boolean,
  salesItems: List<SalesItemModel>,
  inventoryCount: InventoryCount,
  saveModels: SaveModels,
  user: User,
  patientID: string,
  billID: string,
  useTextButton?: boolean,
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  isFromEncounterHistory?: boolean,
};


/**
   * A Component listing prescriptions.
   * @namespace SalesItemsList
   */
class SalesItemsList extends React.Component<Props, {}> {
  static defaultProps = {
    useTextButton: false,
    isFromEncounterHistory: false,
  };

  props: Props

  /**
   * Creates an instance of AddEditPrescriptions.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.state = { modalVisible: false };
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const permissionPrefix = this.props.isFromEncounterHistory ? 'past_encounters' : 'current_encounter';
    return (
      <div className="c-item-list__item__info">
        <Header className="o-card__header" dataPublic>
          <h1 className="o-card__title">{ translate('sales_items') }</h1>
          <PermissionWrapper permissionsRequired={List([createPermission(`${permissionPrefix}_sales_items`, 'update')])} user={this.props.user}>
            <div className="u-flex-right u-margin-right--1ws">
              <SalesItemsFormModal
                disableChanges={this.props.disableChanges}
                modalId="addEditBilledSalesItemsModal"
                modalButtonLabel={this.props.billItems.count() > 0 ? translate('edit') : translate('add')}
                modalButtonClass={this.props.useTextButton ? 'o-text-button o-text-button--contextual' : 'o-button o-button--padded o-button--small u-width-180'}
                modalTitle={translate('sales_items')}
                modalVisible={this.state.modalVisible}
                isModalVisible={value => this.setState({ modalVisible: value })}
                billItems={this.props.billItems}
                salesItems={this.props.salesItems}
                config={this.props.config}
                inventoryCount={this.props.inventoryCount}
                saveModels={this.props.saveModels}
                patientID={this.props.patientID}
                billID={this.props.billID}
                coveragePayors={this.props.coveragePayors}
                saveModel={this.props.saveModel}
                updateConfigValue={this.props.updateConfigValue}
                user={this.props.user}
                updateConfig={this.props.updateConfig}
                dataPublicHeader
              />
            </div>
          </PermissionWrapper>
        </Header>
        { this.props.billItems.size > 0 ?
          <div>
            <div className="o-data-list__row o-data-list__row--header">
              <div className="o-data-list__row__item">
                {translate('name')}
              </div>
              <div className="o-data-list__row__item">
                {translate('price')}
              </div>
              <div className="o-data-list__row__item">
                {translate('quantity')}
              </div>
            </div>
            {this.props.billItems.map(item =>
              <SalesItemsListItem
                key={item.get('_id')}
                item={item}
                salesItems={this.props.salesItems}
              />)}
          </div>
          :
          <p className="u-padding--1ws u-flex-centre">{translate('no_sales_items_added')}</p>
        }
      </div>
    );
  }
}
export default SalesItemsList;
