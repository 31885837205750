import React from 'react';
import { List, Map } from 'immutable';
import { parse } from 'qs';

import PatientModel from './../../models/patientModel';
import PatientStubModel from './../../models/patientStubModel';
import PatientProfileForm from './patientProfileForm';
import { createSuccessNotification } from './../../utils/notifications';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import { logRegistration } from './../../utils/logging';
import { getUniqueCaseId } from './../../utils/utils';

import type { Config, SaveModel, ReactRouterLocation, User, MapValue,  } from './../../types';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type AllergyModel from './../../models/allergyModel';
import type BaseModel from './../../models/baseModel';
import InventoryMapModel from './../../models/inventoryMapModel';
import DrugModel from './../../models/drugModel';

type Props = {
  addPatientStub: (model: PatientStubModel) => void,
  config: Config,
  coveragePayors: List<CoveragePayorModel>,
  location: ReactRouterLocation,
  saveModel: SaveModel,
  user: User,
  patientStubs: List<PatientStubModel>,
  patient: PatientModel,
  showSaveButton: boolean,
  isFromModal: boolean,
  isSaving?: boolean,
  docId?: string,
  referrerDoc?: BaseModel,
  onSave?: (model: PatientModel) => void,
  onSaveAtValidationModal?: (wasSuccessful: boolean) => void,
  updateConfigValue: (keys: Array<string>, value: List<string>) => void,
  updateConfig: (config: Config) => void,
  verifiedDrugs: List<InventoryMapModel>,
  drugs: List<DrugModel>,
  allergies?: List<AllergyModel>,
};

/**
 * A patient registration component that wraps a PatientProfileForm.
 * @class PatientRegistration
 * @extends {React.Component}
 */
class PatientRegistration extends React.Component<Props, {}> {
  /**
   * Creates a new patient from attributes and new allergies for that patient from allergies.
   * @param {object} attributes Attributes to pass to PatientModel.
   * @param {object[]} allergies An array of objects where allergy.value is the name of an allergy
   * to create.
   * @returns {undefined}
   */
  onSaveClicked = (attributes: { [key: string]: MapValue }, allergies: List<AllergyModel>) => {
    const patient = new PatientModel(
      this.props.isFromModal && this.props.docId
        ? { _id: this.props.docId }
        : undefined,
    );
    patient.set(attributes);
    return this.props.saveModel(patient)
      .then((savedModel) => {
        this.props.addPatientStub(new PatientStubModel(Object.assign({}, { _id: patient.get('_id') }, attributes)));
        allergies.forEach((allergyModel) => {
          this.props.saveModel(allergyModel.set('patient_id', patient.get('_id')));
        });
        createSuccessNotification(translate(
          'patient_creation_success',
          { name: attributes.patient_name },
        ));
        logRegistration(attributes.postal_code !== undefined && attributes.postal_code.length > 0);
        if (!this.props.isFromModal) {
          location.hash = `/patient/${patient.get('_id')}`;
        }
        if (this.props.onSave) {
          this.props.onSave(savedModel);
        }
        return true;
      });
  }

  /**
     * Renders the component.
     *
     * @return {string} - HTML markup for the component
     */
  render() {
    const {
      config, patientStubs, patient, isFromModal, showSaveButton,
      isSaving, onSaveAtValidationModal,
    } = this.props;
    const parsedQueryString = parse(this.props.location.search.substring(1));
    const address = [
      parsedQueryString.address1 ? decodeURIComponent(parsedQueryString.address1) : '',
      parsedQueryString.address2 ? decodeURIComponent(parsedQueryString.address2) : '',
      parsedQueryString.address3 ? decodeURIComponent(parsedQueryString.address3) : '',
      parsedQueryString.city ? decodeURIComponent(parsedQueryString.city) : '',
      parsedQueryString.state ? decodeURIComponent(parsedQueryString.state) : '',
    ]
      .filter(i => i.length > 0)
      .join('\n');
    return (
      <ContentTransition id="main-content" className="o-scrollable-container" style={{ height: '100vh' }}>
        <div className={isFromModal ? 'o-form' : 'o-form u-margin-bottom--4ws'}>
          { !isFromModal ? <h1 data-public className="o-form__title">{translate('new_patient_registration')}</h1> : null }
          <PatientProfileForm
            config={this.props.config}
            patient={patient}
            coveragePayors={this.props.coveragePayors}
            initialAttributes={patient ? patient.attributes : {
              patient_name: parsedQueryString.name ? decodeURIComponent(parsedQueryString.name) : '',
              ic: parsedQueryString.ic ? decodeURIComponent(parsedQueryString.ic) : '',
              dob: parsedQueryString.dob ? decodeURIComponent(parsedQueryString.dob) : '',
              sex: parsedQueryString.sex ? decodeURIComponent(parsedQueryString.sex) : '',
              nationality: parsedQueryString.nationality ? decodeURIComponent(parsedQueryString.nationality) : '',
              ethnicity: parsedQueryString.ethnicity ? decodeURIComponent(parsedQueryString.ethnicity) : '',
              postal_code: parsedQueryString.postal_code ? decodeURIComponent(parsedQueryString.postal_code) : '',
              address,
              case_id: config.getIn(['patient', 'case_id', 'autogenerate'], false) ?
                getUniqueCaseId(patientStubs, config.getIn(['patient', 'case_id', 'autogenerate_prefix'], '')) :
                undefined,
            }}
            saveModel={this.props.saveModel}
            patientStubs={patientStubs}
            showAllergies
            editInfoFromIC
            isFromModal={isFromModal}
            showSaveButton={showSaveButton}
            isSaving={isSaving}
            onSaveAtValidationModal={onSaveAtValidationModal}
            onSaveClicked={this.onSaveClicked}
            user={this.props.user}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            verifiedDrugs={this.props.verifiedDrugs}
            drugs={this.props.drugs}
            allergies={this.props.allergies}
          />
        </div>
      </ContentTransition>
    );
  }
}

export default PatientRegistration;
