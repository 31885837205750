import BaseModel from './../baseModel';
import type { AnalyticsReportCategoryType } from './../../types';

type attributesType = {
  _id: string,
  type: 'analytics_report_item',
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  report_id: string, // todo: resolve the name for id and _id
  title: string,
  is_default: boolean,
  query_ids: Array<string>,
  category_type: AnalyticsReportCategoryType,
  created_at: number,
  updated_at: number
};

/**
   * AnalyticsReportModel
   *
   * @namespace AnalyticsReportModel
   */
class AnalyticsReportModel extends BaseModel {
  attributes: attributesType;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'analytics_report_item';
  }
}

export default AnalyticsReportModel;
