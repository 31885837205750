import { connect } from 'react-redux';
import type { List } from 'immutable';
import MedadvisorCampaignSets from '../components/patientOutreachCampaign/medadvisorCampaignSets';
import {
  setCurrentDataViews,
  setCurrentDataViewsError,
  setCurrentDataViewsModels,
  setIsFetching,
  updateConfig,
} from '../actions';
import { Dispatch, State, Config } from '../types';
import PatientCampaignSetModel from '../models/patientCampaignSetModel';
import { updateCampaignSets } from '../utils/patientCampaign';
import AsyncFetch from '../components/asyncFetch';
import APIError from '../utils/apiError';
import { getPatientCampaignSetDataViews } from '../dataViews';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  user,
  campaignSetView,
  config,
  currentDataViews,
  currentDataViewsError,
  isFetching,
  currentDataViewsModels,
}: State) => ({
  user,
  config,
  campaignSets: (isFetching ? campaignSetView.campaignSets : currentDataViewsModels)
    .filter(m => m.get('type') === 'patient_campaign_set')
    .filter(
      c => c.get('master_campaign_set_type') === 'MEDADVISOR' && c.isVisible(),
    ),
  readCampaignSetMap: campaignSetView.readCampaignSetMap,
  containerDataViews: getPatientCampaignSetDataViews(true, 'medadvisor'),
  currentDataViews,
  currentDataViewsError,
  isFetching,
  showLoadingIndicator: false,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCampaignSets: (
    campaignSets: List<PatientCampaignSetModel>,
    action: 'activate' | 'deactivate',
  ) => updateCampaignSets(campaignSets, action, dispatch),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) =>
    dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: (models: List<PatientCampaignSetModel>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const PatientCampaignSetsContainer = AsyncFetch(MedadvisorCampaignSets);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientCampaignSetsContainer);
