import React, { useState, useRef } from 'react';
import { List } from 'immutable';
import { truncate } from 'lodash';
import type { RouteComponentProps } from 'react-router-dom';

import translate from '../../utils/i18n';
import SelectTable from '../table/selectTable';
import flagTableHOC from '../hoc/flagTable';
import { sortByDate } from '../../utils/comparators';
import { filterDropDown, filterMultiLineTypeDropDown } from '../../utils/filters';
import { UNICODE } from '../../constants';
import {
  renderMultilineContentWithLink, renderDateWithLink, renderTranslatedValueWithLink,
  renderWithLink, renderDateTimeWithLink,
} from '../../utils/tables';
import { isLoading, transformFilteredColumns, transformColumnKeys, getConfirmation } from '../../utils/utils';
import { downloadCSV, convertToCSV } from '../../utils/export';
import { printCampaignJobsList } from '../../utils/print';
import APIError from '../../utils/apiError';
import { createSuccessNotification, createErrorNotification } from '../../utils/notifications';
import { prettifyDateTime } from './../../utils/time';
import Button from './../buttons/button';
import Header from './../header/header';
import { debugPrint } from '../../utils/logging';
import { getMDLInfo } from '../../utils/inventory';

import TableColumnsSettings from '../table/tableColumnsSettings';
import TableExportPrintOptions from '../table/tableExportPrintOptions';
import LoadingIndicator from '../loadingIndicator';
import SalesItemModel from '../../models/salesItemModel';
import EncounterModel from '../../models/encounterModel';
import patientCampaignModel from '../../models/patientCampaignModel';
import DrugModel from '../../models/drugModel';
import BillModel from '../../models/billModel';
import UserConfigModel from '../../models/userConfigModel';
import SMSJobModel from '../../models/smsJobModel';
import PatientStubModel from '../../models/patientStubModel';
import PatientModel from '../../models/patientModel';
import BillItemModel from '../../models/billItemModel';
import PractitionerModel from '../../models/practitionerModel';
import type PrescriptionModel from '../../models/prescriptionModel';
import PatientCampaignSetModel from '../../models/patientCampaignSetModel';

import type {
  Config, SelectOption, SaveModel, MapValue, APIResponse, SmsJobFailedAttemptType,
  TableFlagType, TableTooltipType, User,
} from '../../types';
import type ConditionModel from '../../models/conditionModel';
import { getStore } from '../../utils/redux';

type Props = {
  userConfigs: List<UserConfigModel>,
  campaign: patientCampaignModel,
  config: Config,
  bills: List<BillModel>,
  isFetching: boolean,
  salesItems: List<SalesItemModel>,
  encounters: List<EncounterModel>,
  billItems: List<BillItemModel>,
  practitioners: List<PractitionerModel>,
  drugs: List<DrugModel>,
  smsJobs: List<SMSJobModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  currentDataViewsError?: APIError,
  patients: List<PatientStubModel> | List<PatientModel>,
  saveModel: SaveModel,
  updateCampaignJobStatus: (campaignJobs: Array<SMSJobModel>) =>
    Array<SMSJobModel> | Array<APIResponse>,
  prescriptions: List<PrescriptionModel>,
  conditions: List<ConditionModel>,
  drugMap: Map<string, DrugModel>,
  masterDrugOptions: List<SelectOption>,
  user: User,
  selectedCampaignSet: PatientCampaignSetModel,
} & RouteComponentProps<{
  campaignID: string,
  campaignSetID: string,
  campaignSubType: string
}>;

const statusOptions: List<SelectOption> = List([
  {
    label: translate('queued'),
    value: 'queued',
  },
  {
    label: translate('sent'),
    value: 'sent',
  },
  {
    label: translate('unresolved'),
    value: 'unresolved',
  },
  {
    label: translate('resolved'),
    value: 'resolved',
  },
  {
    label: translate('error'),
    value: 'error',
  },
]);

/**
* Renders the filters drop down
* @param {{id: string, value: string}} filter The filter data.*
* @param {Function} onChange function call when dropdown select change
* @param {List<SelectOption>} typeOptions option data for the dropdown
* @returns {React.PureComponent} The rendered component
*/
function tableFilterMethod(filter: {id: string, value: string},
  onChange: Function, typeOptions: List<SelectOption>) {
  return (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%', height: 34 }}
      value={filter ? filter.value : 'all'}
    >
      <option style={{ maxWidth: '20px' }} value="all" />
      {
      typeOptions.map(({ label, value }) =>
        <option value={value}>{truncate(label, 20)}</option>)
      }
    </select>);
}

/**
 * Renders a component showing the campaign rules section of the sms campaign form.
 * @param {Props} props passed props for the component
 * @returns {React.PureComponent} The rendered component
*/
function CampaignJobs(props: Props) {
  const { selectedCampaignSet } = props;
  const isMedadvisor = props.match.params.campaignSubType === 'medadvisor';
  if (!selectedCampaignSet) {
    return (<LoadingIndicator alignCenter />);
  }
  const isPatientTypeCampaign = selectedCampaignSet.get('type') === 'patient_campaign_set'
    && selectedCampaignSet.get('master_campaign_set_type') !== 'MEDADVISOR';
  if (isPatientTypeCampaign === isMedadvisor) {
    return translate('page_not_found');
  }
  const { campaign, smsJobs } = props;
  const [filteredData, setFilteredData] = useState([]);
  const [exportPrintAttributes, setExportPrintAttributes] = useState({
    title: campaign ? `${campaign.get('name')} list` : '',
    isLandscape: true,
    appendTotalsToExport: undefined,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isProcessingAction, setIsProcessingAction] = useState(false);

  const encounterTypeOptions = props.config.get('consultTypes', new List()).reduce((data, value) => {
    const salesItem = props.salesItems.find(item => item.get('_id') === value);
    return salesItem ? data.push({ label: salesItem.get('name'), value: salesItem.get('name') }) : data;
  }, List());
  const getSalesTypeOptions = props.salesItems.map(s => ({ label: s.get('name'), value: s.get('name') }));
  const getDoctorTypeOptions = props.practitioners.map(d => ({ label: d.get('name'), value: d.get('name') }));
  const getDrugTypeOptions = props.drugs.map(d => ({ label: d.get('name'), value: d.get('name') }));
  const getUsersTypeOptions = props.userConfigs.map(u => ({ label: u.get('user_id'), value: u.get('user_id') }));
  const diagonosesOptions = props.conditions.map(c => ({ label: c.get('name'), value: c.get('_id') }));
  const childRef = useRef();

  /**
   * Get the latest response of the job
   * @param {SMSJobModel} smsJob smsJob
   * @returns {string}
   */
  function getLatestResponse(smsJob: SMSJobModel) {
    const status = smsJob.getStatus();
    const replies = smsJob.get('replies');
    if (status === 'unresolved' && replies.length) {
      return replies[replies.length - 1].message;
    }
    return translate('no_action_taken');
  }

  /**
   * Gets name of the sales/bill item depending on its type.
   * @param {BillItemModel} billItem the bill item model
   * @returns {string}.
   */
  function getName(billItem: BillItemModel): string {
    if (billItem.isPrescription()) {
      const drug = props.drugMap.get(billItem.get('drug_id'));
      return drug ? drug.get('name') : UNICODE.EMDASH;
    }
    const salesItem = props.salesItems.find(s => s.get('_id') === billItem.get('sales_item_id'));
    return salesItem ? salesItem.get('name') : UNICODE.EMDASH;
  }

  /**
   * Gets list of bill items matching the encounter passed
   * @param {EncounterModel} encounter Encounter model.
   * @param {string} name name of bill item type.
   * @returns {List<BillItemModel>}.
   */
  function getBillItemsFromEncounter(encounter?: EncounterModel): List<BillItemModel> {
    const bill = props.bills
      .find(model => model.get('encounter_id') === encounter?.get('_id'));
    if (bill) {
      return props.billItems.filter(i => i.get('bill_id') === bill.get('_id'));
    }
    return List();
  }

  /**
   * Gets list of name of bill items matching the encounter and item name/type passed.
   * @param {EncounterModel} encounter Encounter model.
   * @param {string} name name of bill item type.
   * @returns {string}.
   */
  function getSalesItems(encounter: EncounterModel, name: string): string {
    const billItems = getBillItemsFromEncounter(encounter);
    if (billItems.size) {
      if (name === 'prescriptions') {
        return billItems.filter(item => item.isPrescription())
          .map(item => getName(item)).toArray().toString()
          .replace(/,/g, '\n') || UNICODE.EMDASH;
      }
      return billItems.filter(item => item.isSalesItem())
        .map(item => getName(item)).toArray().toString()
        .replace(/,/g, '\n') || UNICODE.EMDASH;
    }
    return UNICODE.EMDASH;
  }

  /**
   * Gets list of bill items matching the encounter passed and item name/type passed.
   * @param {EncounterModel} encounter Encounter model.
   * @returns {string}.
   */
  function getDispensedMasterDrugs(encounter?: EncounterModel): string {
    const billItems = getBillItemsFromEncounter(encounter);
    if (billItems.size) {
      const drugIds = billItems.filter(item => item.isPrescription())
        .map((item) => {
          const drug = props.drugMap.get(item.get('drug_id'));
          return drug?.get('_id');
        }) as List<string>;
      const store = getStore();
      const masterData = drugIds.map(drugId =>
        (drugId ? getMDLInfo(store, drugId)?.mapped_drug ?? UNICODE.EMDASH : UNICODE.EMDASH));
      return masterData.toArray().toString()
        .replace(/,/g, '\n') || UNICODE.EMDASH;
    }
    return UNICODE.EMDASH;
  }

  /**
   * Gets list prescribed drugs name from prescription doc corresponding to the encounter model passed.
   * @param {EncounterModel} encounter Encounter model.
   * @param {boolean} findMasterDrug Returns master drug name if true
   * @returns {string}.
   */
  function getPrescribedDrugs(encounter?: EncounterModel, findMasterDrug: boolean = false) {
    const store = getStore();
    const prescriptons = props.prescriptions.filter(prescription =>
      prescription.get('encounter_id') === encounter?.get('_id'));
    const drugIdsPrescrbed = prescriptons.reduce((drugIds, prescription) =>
      drugIds.push(prescription.get('drug_id')), List());
    if (findMasterDrug) {
      const masterData = drugIdsPrescrbed.map(drugId =>
        (drugId ? getMDLInfo(store, drugId)?.mapped_drug ?? UNICODE.EMDASH : UNICODE.EMDASH));
      return masterData.toArray().toString()
        .replace(/,/g, '\n') || UNICODE.EMDASH;
    }
    const drugs = drugIdsPrescrbed.filter(d => !!d).map(drugId => props.drugMap.get(drugId)?.get('name'));
    return drugs.toArray().toString().replace(/,/g, '\n') || UNICODE.EMDASH;
  }

  /**
   * Gets the selected campaign Job.
   * @returns {List<SMSJobModel>}
   */
  function getSelectedCampaignJob() {
    return smsJobs.filter(c => selectedRows.includes(c.get('_id')));
  }

  /**
   * Gets the documents to be updated as resolved
   * @param {string} action resolve/cancel
   * @returns {Array<Model>}
  */
  function getJobsToResolveOrCancel(action: string) {
    if (action === 'resolve') {
      return getSelectedCampaignJob().reduce((data, smsJob) => {
        if (smsJob.getStatus() === 'unresolved') {
          const updatedJob = new SMSJobModel({
            ...smsJob.attributes,
            is_resolved: true,
            status: 'resolved',
          });
          return data.push(updatedJob);
        }
        return data;
      }, List()).toArray();
    }
    if (action === 'cancel') {
      return getSelectedCampaignJob().reduce((data, smsJob) => {
        if (smsJob.getStatus() === 'queued') {
          const updatedJob = new SMSJobModel({
            ...smsJob.attributes,
            status: 'cancelled',
          });
          return data.push(updatedJob);
        }
        return data;
      }, List()).toArray();
    }
    return [];
  }

  /**
   * Handles the action
   * @param {string} value The value selected
   * @returns {void}
   */
  function handleMenuSelection(value: string) {
    setIsProcessingAction(true);
    childRef.current.resetSelection();

    if (value === 'resolve' || value === 'cancel') {
      getConfirmation(value === 'resolve' ? translate('confirm_job_resolve') : translate('confirm_job_cancel'))
        .then(
          () => props.updateCampaignJobStatus(getJobsToResolveOrCancel(value === 'resolve' ? 'resolve' : 'cancel'))
            .then((savedJobs) => {
              if (savedJobs.some(s => s.error && s.msg === 'SERVER_FAILED')) {
                createErrorNotification(value === 'resolve' ? translate('jobs_resolve_failed') : translate('jobs_cancel_failed'));
              } else if (savedJobs.some(s => s.error)) {
                createErrorNotification(translate('something_went_wrong'));
              } else {
                createSuccessNotification(value === 'resolve' ? translate('jobs_resolved') : translate('jobs_canceled'));
              }
              setIsProcessingAction(false);
              setSelectedRows([]);
            })
            .catch((error) => {
              debugPrint(error, 'error');
              createErrorNotification(translate('something_went_wrong'));
              setIsProcessingAction(false);
              setSelectedRows([]);
            }),
          () => {
            setIsProcessingAction(false);
            setSelectedRows([]);
          },
        );
    } else {
      setIsProcessingAction(false);
      setSelectedRows([]);
    }
  }

  const COLUMNS = [
    { accessor: 'date', Header: translate('encounter_date'), Cell: renderDateWithLink, sortMethod: sortByDate },
    { accessor: 'scheduledDate', Header: translate('scheduled_date'), Cell: renderDateTimeWithLink, sortMethod: sortByDate },
    { accessor: 'patientName', Header: translate('patient_name'), filterable: true, Cell: renderWithLink },
    { accessor: 'phoneNum', Header: translate('phone_number'), filterable: true, Cell: renderWithLink },
    {
      accessor: 'encounterType',
      Header: translate('encounter_type'),
      filterable: true,
      filterFromOptions: true,
      minWidth: 100,
      filterMethod: filterDropDown,
      Cell: renderWithLink,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, encounterTypeOptions),
    },
    {
      accessor: 'diagnoses',
      Header: translate('diagnosis'),
      filterable: true,
      filterFromOptions: true,
      minWidth: 100,
      filterMethod: filterDropDown,
      Cell: renderWithLink,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, diagonosesOptions),
    },
    {
      accessor: 'drugPrescribed',
      Header: translate('drug_prescribed'),
      filterable: true,
      filterFromOptions: true,
      minWidth: 100,
      filterMethod: filterMultiLineTypeDropDown,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, getDrugTypeOptions),
      Cell: renderMultilineContentWithLink,
    },
    {
      accessor: 'drugDispensed',
      Header: translate('drug_dispensed'),
      filterable: true,
      filterFromOptions: true,
      minWidth: 100,
      filterMethod: filterMultiLineTypeDropDown,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, getDrugTypeOptions),
      Cell: renderMultilineContentWithLink,
    },
    {
      accessor: 'masterDrugPrescribed',
      Header: translate('master_drug_prescribed'),
      filterable: true,
      filterFromOptions: true,
      filterMethod: filterMultiLineTypeDropDown,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, props.masterDrugOptions),
      Cell: renderMultilineContentWithLink,
    },
    {
      accessor: 'masterDrugDispensed',
      Header: translate('master_drug_dispensed'),
      filterable: true,
      filterFromOptions: true,
      filterMethod: filterMultiLineTypeDropDown,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, props.masterDrugOptions),
      Cell: renderMultilineContentWithLink,
    },
    {
      accessor: 'salesItems',
      Header: translate('sales_items'),
      filterable: true,
      filterFromOptions: true,
      minWidth: 100,
      filterMethod: filterMultiLineTypeDropDown,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, getSalesTypeOptions),
      Cell: renderMultilineContentWithLink,
    },
    {
      accessor: 'doctor',
      Header: translate('doctor'),
      filterable: true,
      filterFromOptions: true,
      filterMethod: filterDropDown,
      Cell: renderWithLink,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, getDoctorTypeOptions),
    },
    {
      accessor: 'receptionStaff',
      Header: translate('reception_staff'),
      filterable: true,
      filterFromOptions: true,
      filterMethod: filterDropDown,
      Cell: renderWithLink,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, getUsersTypeOptions),
    },
    { accessor: 'latestPatientResponse', Header: translate('latest_patient_response'), filterable: true, Cell: renderWithLink, show: false },
    {
      accessor: 'status',
      Header: translate('status'),
      filterable: true,
      filterFromOptions: true,
      filterMethod: filterDropDown,
      Cell: renderTranslatedValueWithLink,
      Filter: ({ filter, onChange }) => tableFilterMethod(filter, onChange, statusOptions),
    },
  ];


  /**
   * Gets the tooltip Data for a particular failed attempt.
   * @param {SMSJobModel} failedAttempt The failed attempt object
   * @returns {object}
   */
  function getJobRowErrorTooltip(failedAttempt: SmsJobFailedAttemptType): TableTooltipType {
    return ({
      header: 'Status: Error',
      content: failedAttempt.error_message,
    });
  }

  /**
   * Gets the flag Data for a particular job.
   * @param {SMSJobModel} job The sms job
   * @returns {object}
   */
  function getJobRowFlag(job: SMSJobModel): TableFlagType {
    const errorTooltips = List(job.get('failed_attempts'))
      .groupBy(e => e.error_message)
      .map(attempts => getJobRowErrorTooltip(attempts.first()))
      .valueSeq()
      .toArray();
    return {
      active: job.getStatus() === 'error',
      color: 'red',
      tooltips: errorTooltips.filter(e => !!e),
    };
  }

  const originalColumns = List().concat(transformColumnKeys(COLUMNS));
  const [columns, setColumns] = useState(originalColumns.toArray());

  /**
   * Gets the table data.
   * @returns {Row}
   */
  function getRows() {
    return smsJobs.map((job: SMSJobModel) => {
      const encounter = props.encounters.find(e => e.get('_id') === job.get('encounter_id'));
      const patient = props.patients.find(p => encounter.get('patient_id') === p.get('_id'));
      const flag = getJobRowFlag(job);
      return {
        _id: job.get('_id'),
        flag: flag.active,
        flagData: { color: flag.color },
        tooltipData: flag.active && flag.tooltips,
        date: encounter.getDate(),
        scheduledDate: job.get('scheduled_for'),
        patientName: patient ? patient.get('patient_name') : UNICODE.EMDASH,
        phoneNum: job.get('phone_number') || UNICODE.EMDASH,
        encounterType: encounter.getEncounterType(props.salesItems),
        diagnoses: props.conditions.find(c => c.get('encounter_id') === encounter?.get('_id'))?.get('name'),
        drugPrescribed: getPrescribedDrugs(encounter),
        drugDispensed: getSalesItems(encounter, 'prescriptions'),
        masterDrugDispensed: getDispensedMasterDrugs(encounter),
        masterDrugPrescribed: getPrescribedDrugs(encounter, true),
        salesItems: getSalesItems(encounter, 'salesItems'),
        doctor: encounter?.getDoctorName(props.practitioners),
        receptionStaff: encounter.get('created_by').user_id,
        latestPatientResponse: getLatestResponse(job),
        status: job.getStatus(),
        link: `${props.match.url}/jobs/${job.get('_id')}`,
      };
    });
  }

  /**
   * Helper function to update attributes in state.
   * @param {string} key Key
   * @param {MapValue} value Value
   * @returns {void}
   */
  function updateExportPrintAttribute(key: string, value: MapValue) {
    setExportPrintAttributes(
      Object.assign({}, exportPrintAttributes, { [key]: value }),
    );
  }

  /**
   * Exports the cost of goods sold.
   * @returns {void}
   */
  function onExportClicked() {
    const data = filteredData.map(item => columns.map((column) => {
      if (column.value === 'scheduledDate') {
        return Number.isInteger(item[column.value]) ?
          prettifyDateTime(parseInt(item[column.value], 10)) : item[column.value];
      }
      return (item[column.value] !== UNICODE.EMDASH ? item[column.value] : '');
    }));
    downloadCSV(
      `${exportPrintAttributes.title}.csv`,
      convertToCSV(columns.map(c => c.label), data),
    );
  }

  /**
   * Prints the cost of goods sold.
   * @returns {Promise<void>} A promise upon print completion.
   */
  function onPrintClicked(): Promise<void> {
    const data = filteredData.map(item => columns.map((column) => {
      if (column.value === 'scheduledDate') {
        return Number.isInteger(item[column.value]) ?
          prettifyDateTime(parseInt(item[column.value], 10)) : item[column.value];
      }
      return item[column.value];
    }));
    printCampaignJobsList(
      columns,
      data,
      props.config,
      exportPrintAttributes.title,
      exportPrintAttributes.isLandscape,
    );
    return Promise.resolve();
  }

  const isMedadvisorCampaigns = props.match.params.campaignSubType === 'medadvisor';
  return (
    <section className="o-scrollable-container" style={{ height: '100vh' }}>
      <Header className="o-header">
        <h1>{campaign && campaign.get('name')}</h1>
      </Header>
      <div className="o-card u-margin-bottom--4ws">
        <Header className="o-card__header">
          <h1 className="o-card__title">{translate('sms_jobs')}</h1>
          <TableColumnsSettings
            config={props.config}
            configFieldName="campaign_jobs"
            updateConfigValue={props.updateConfigValue}
            originalColumns={originalColumns}
            columns={columns}
            onUpdateColumns={(renderedColumns) => {
              setColumns(renderedColumns);
            }}
            updateConfig={props.updateConfig}
          />
        </Header>
        <div className="o-header-actions">
          <div className="u-flex-left u-margin-left--half-ws">
            <TableExportPrintOptions
              exportPrintAttributes={exportPrintAttributes}
              updateExportPrintAttribute={updateExportPrintAttribute}
              onExportClicked={onExportClicked}
              onPrintClicked={onPrintClicked}
              isPrintExportButtonEnabled={
                props.isFetching || isProcessingAction ||
                !(filteredData && filteredData.length > 0)
              }
            />
            <Button
              style={{ display: 'none' }}
              className="o-button o-button--small have-background u-margin-left--half-ws"
              onClick={() => handleMenuSelection('resolve')}
              disabled={props.isFetching || isProcessingAction ||
                selectedRows.every(r => smsJobs.find(job => job.get('_id') === r).getStatus() !== 'unresolved')}
              dataPublic
            >{ translate('resolve') }
            </Button>
            {!isMedadvisorCampaigns &&
            <Button
              className="o-button o-button--small have-background u-margin-right--half-ws u-margin-left--half-ws"
              onClick={() => handleMenuSelection('cancel')}
              disabled={props.isFetching || isProcessingAction ||
                selectedRows.every(r => smsJobs.find(job => job.get('_id') === r).getStatus() !== 'queued')}
              dataPublic
            >{ translate('cancel_SMS_job') }
            </Button>}
          </div>
        </div>
        <SelectTable
          ref={childRef}
          columns={transformFilteredColumns(COLUMNS, columns)}
          data={props.isFetching ? [] : getRows().toArray()}
          loading={props.isFetching}
          noDataText={
            translate(props.currentDataViewsError ? 'error_contact_support' : isLoading(props.isFetching, 'no_jobs_found'))
          }
          showPagination
          defaultSorted={[{ id: 'date', desc: true }]}
          filteredSortedDataHandler={value => setFilteredData(value)}
          initialDataHandler={value => setFilteredData(value)}
          getSelectedRows={value => setSelectedRows(value)}
          tableComponentHOC={component => flagTableHOC(component)}
        />
      </div>
    </section>
  );
}

export default CampaignJobs;
