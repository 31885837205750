import React from 'react';
import type { List } from 'immutable';

import translate from './../../utils/i18n';
import APIError from './../../utils/apiError';
import { isLoading } from './../../utils/utils';
import Table from './../table/table';
import { renderWithLink, renderDateWithLink, renderPreWithLink } from './../../utils/tables';

import type { Row } from './../../types';

type Props = {
  columns: Array<{ value: string, label: string }>,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  groupByDrugs: boolean,
  data: List<{}>,
  onFilterAndSort: (data: Array<Row>) => void
};

/**
 * A table for displaying dispensed drug information.
 * @class DrugDispensationTable
 * @extends {React.Component<Props, State>}
 */
class DrugDispensationTable extends React.Component<Props, {}> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <div className="o-card u-margin-bottom--4ws">
        <h2 data-public className="o-card__title">{translate('items')}</h2>
        <Table
          columns={this.props.columns.map(({ value, label, ...params }) => {
            if (value === 'date') {
              return { accessor: value, Header: label, Cell: renderDateWithLink, ...params };
            }
            if (value === 'supplierNameAndAddress') {
              return { accessor: value, Header: label, Cell: renderPreWithLink, ...params };
            }
            return { accessor: value, Header: label, Cell: renderWithLink, ...params };
          })}
          loading={this.props.isFetching}
          data={this.props.data.toArray()}
          defaultSorted={
            this.props.groupByDrugs ?
              [{ id: 'poisonsMedicineSupplied', desc: false }, { id: 'date', desc: true }] :
              [{ id: 'date', desc: true }]
          }
          noDataText={translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(this.props.isFetching, 'no_items_matched_your_selection'))}
          initialDataHandler={initialData => this.props.onFilterAndSort(initialData)}
          filteredSortedDataHandler={filteredData => this.props.onFilterAndSort(filteredData)}
        />
      </div>
    );
  }
}

export default DrugDispensationTable;
