import moment from 'moment';

import { saveFactory, getStore } from '../utils/redux';
import { updateModel } from './../actions';
import { updateAppointment } from '../utils/api';

import PatientModel from '../models/patientModel';
import AppointmentModel, { PatientNotification } from './../models/appointmentModel';
import type { AppointmentArgs, TableFlagType } from './../types';


export const UNREAD_FLAG_VALUE = {
  active: true,
  color: 'dodgerblue',
  tooltips: [{
    header: 'New Appointment Requested',
    content: 'Confirm or reject this appointment request as soon as possible by clicking on "View Request"',
  }],
};

export const READ_FLAG_VALUE = {
  active: false,
  color: 'red',
  tooltips: [],
};

export const BOUNCED_STATUS_FLAG = {
  active: true,
  color: 'red',
  tooltips: [{
    header: 'Email Bounced',
    content: 'Please update patient’s email address in the actions column to confirm E-Consultation.',
  }],
};

export const DEFERRED_STATUS_FLAG = {
  active: true,
  color: '#FFB100',
  tooltips: [{
    header: 'Retrying Email',
    content: 'Email sent to patient is unsuccessful. Re-trying sending of email to the patient.',
  }],
};

export const DEFAULT_FLAG = {
  active: false,
  color: 'cadetblue',
  tooltips: [],
};

export const NO_EMAIL_NOTFICATION_FLAG = {
  active: false,
  color: 'red',
  tooltips: [],
};

/**
 * Check and Get Failed email attempt
 * @param {string} status patientNotification status
 * @returns {TableFlagType} An object with flag information.
 */
const getEmailNotificationTableFlag = (status: string): TableFlagType => {
  switch (status) {
    case 'blocked':
    case 'dropped':
    case 'bounced':
      return BOUNCED_STATUS_FLAG;
    case 'deferred':
      return DEFERRED_STATUS_FLAG;
    default:
      return DEFAULT_FLAG;
  }
};

/**
 * Get flag info for arrival
 * @param {AppointmentModel} appointment Encounter data
 * @returns {object} An object with flag information.
 */
export function getAppointmentFlag( // eslint-disable-line import/prefer-default-export
  appointment: AppointmentModel,
): TableFlagType {
  const patientNotification: PatientNotification = appointment.getLatestNotification();
  if (patientNotification && patientNotification.type === 'email') {
    return getEmailNotificationTableFlag(patientNotification.status);
  }
  return NO_EMAIL_NOTFICATION_FLAG;
}

/**
 * Get flag info for unread request
 * @param {AppointmentModel} appointment Encounter data
 * @returns {object} An object with flag information.
 */
export function getRequestUnreadFlag( // eslint-disable-line import/prefer-default-export
  appointment: AppointmentModel,
): TableFlagType {
  if (appointment.unread) {
    return UNREAD_FLAG_VALUE;
  }

  return READ_FLAG_VALUE;
}

/**
  * Call on confirm or reject request
  * @param {PatientModel} patient patient
  * @param {AppointmentModel} appointment model
  * @param {AppointmentArgs} args arguments for the appoitment.
  * @return {Pormise<boolean>}
  */
export function onConfirmOrRejectRequest(patient: PatientModel,
  appointment: AppointmentModel, args: AppointmentArgs) {
  const appointmentData = {
    patient_id: patient.get('_id'),
    ...args.flow_id && { flow_id: args.flow_id },
    ...args.flow_id && { consult_mode: args.consultMode },
    ...args.date && {
      start_timestamp: moment(args.date)
        .hour(args.time.hour())
        .minute(args.time.minute())
        .second(0)
        .valueOf(),
    },
    ...args.practitioner && { practitioner_id: args.practitioner },
    ...args.status && { status: args.status },
  };
  const isRequest = appointment.isPending() && appointment.has('request_data');
  const needsEmailUpdate = !(isRequest || (!args.email) || patient.get('email') === args.email);
  const updatedPatient = needsEmailUpdate ?
    new PatientModel(Object.assign({}, patient.attributes, { email: args.email })) : undefined;
  return (needsEmailUpdate
    ? saveFactory(getStore().dispatch)(updatedPatient)
    : Promise.resolve({ ok: true }))
    .then(() => updateAppointment(appointment, appointmentData)
      .then((resp) => {
        if (resp.ok) {
          getStore().dispatch(updateModel(resp.model));
          return true;
        }
        return false;
      }));
}
