import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import $ from 'jquery';
import LogRocket from 'logrocket';
import pjson from './../../package.json';
import { DEV_SERVERS } from './../constants';
import { getUserName } from './user';

let AppInsights;
if (process.env.APPINSIGHT_CONNECTION_STRING) {
    AppInsights = new ApplicationInsights({
        config: {
            connectionString: process.env.APPINSIGHT_CONNECTION_STRING,
            disableFetchTracking: true, //don't spam app insight logs with http requests
            disableAjaxTracking: true
        },
    });
}

type AppInsightsMetadata = {
    release: string;
    serverName: string;
    userName: string;
};

/** Initializes app insight logging
 * @returns {void}
 */
export function initializeAppInsights() {
    if (AppInsights) {
        AppInsights.loadAppInsights();
    }
}

let loggingInitialised = false;
let serverName;
let userName;

/**
 * Returns true if the URL is a production or server.
 * @returns {boolean} Whether or not the URL is a production server.
 */
export function isProduction(): boolean {
    return !DEV_SERVERS.some((server) => location.hostname.indexOf(server) > -1);
}

/**
 * Returns generic metadata for Azure App insights.
 * @returns {AppInsightsMetadata} The log metadata.
 */
export function getMetadata(): AppInsightsMetadata {
    return {
        release: pjson.version,
        serverName: location.hostname || 'No server name set',
        userName: userName || 'No user set',
    };
}

/**
 * removes url params from patient registartion url if matched
 * @param {string} url The URL to check and update
 * @return {string}
 */
function skipParams(url: string): string {
    // if any of the urls have registration? then remove the query params
    const index = url.indexOf('/registration?');
    if (index !== -1 && !url.endsWith('/registration?')) {
        return url.substring(0, index + 13);
    }
    return url;
}

/**
 * Connects to Logrocket to start logging.
 * @returns {void}
 */
export function startLogRocketLogging() {
    if (isProduction()) {
        LogRocket.init('lmggkb/klinify-webapp', {
            network: {
                isEnabled: false,
            },
            dom: {
                inputSanitizer: true,
                textSanitizer: true,
            },
        });
    }
}

/**
 * Sets the user to tag logs with. Using the function with userID undefined unsets the current
 * user (i.e. use when the user logs out).
 * @param {string} userID the current users ID. Can be undefined.
 * @return {void}
 */
export function setUserForLogRocketLogging(userID?: string) {
    if (isProduction()) {
        userName = userID;
        const userCtx = userName || 'anonymous';
        LogRocket.identify(userCtx);
    }
}
/**
 * Sets the user to tag logs with. Using the function with userID undefined unsets the current
 * user (i.e. use when the user logs out).
 * @param {string} userID the current users ID. Can be undefined.
 * @return {void}
 */
export function setUserForLogging(userID?: string) {
    userName = userID;
    if (AppInsights) {
        AppInsights.setAuthenticatedUserContext(userID, serverName);
    }
}

/**
 * Logs a message.
 * @param {string} message The message to log
 * @param {string} level The logging level.
 * @param {object} data Extra context data to be sent.
 * @returns {undefined}
 */
export function logMessage(message: string, level: string = 'info', data: object = {}) {
    if (AppInsights) {
        AppInsights.trackTrace({
            message,
            properties: Object.assign({}, { data }, getMetadata()),
            severityLevel: SeverityLevel.Information,
        });
    }
}

/**
 * Logs an error or info (if status is 0) either with tag or without it.
 * @param {string} message The message to log
 * @param {number | null} status The error status
 * @param {object} data Extra context data to be sent.
 * @returns {undefined}
 */
export function logToAppInsight(message: string, status: number | null, data: object = {}) {
    logMessage(message, 'error', data);
}

// GENERAL LOGGING //////////////////

/**
 * Logs the initialisation of the app.
 * @returns {void}
 */
export function logAppInitialisation() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'AppInitialised', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when the app returns from offline state.
 * @returns {void}
 */
export function logExitOfflineState() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'ExitedOfflineState', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when a login attempt fails.
 * @returns {void}
 */
export function logFailedLogin() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'LoginFailed', properties: Object.assign({}, getMetadata()) });
    }
}

// DOCTOR LOGGING //////////////////
/**
 * Logs when doctor on duty changes.
 * @returns {void}
 */
export function logDoctorOnDutyChanged() {
    if (AppInsights) {
        AppInsights.trackEvent('DoctorOnDutyChanged', Object.assign({}, getMetadata()));
    }
}

/**
 * Logs when doctor created
 * @param {Array<string>} fields The non-empty fields of the practitioner document
 * @returns {void}
 */
export function logPractitionerCreated(fields: Array<string>) {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'PractitionerCreated', properties: Object.assign({}, getMetadata(), { fields }) });
    }
}

/**
 * Logs when doctor edited
 * @param {Array<string>} fields The non-empty fields of the practitioner document
 * @returns {void}
 */
export function logPractitionerEdited(fields: Array<string>) {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'PractitionerEdited', properties: Object.assign({}, getMetadata(), { fields }) });
    }
}

/**
 * Logs when doctor deleted
 * @returns {void}
 */
export function logPractitionerDeleted() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'PractitionerDeleted', properties: Object.assign({}, getMetadata()) });
    }
}

// PATIENT LOGGING //////////////////

/**
 * Logs the registration of a patient.
 * @param {boolean} hasPostCode True if patient registered with post code.
 * @returns {void}
 */
export function logRegistration(hasPostCode: boolean = false) {
    if (AppInsights) {
        AppInsights.trackEvent({
            name: 'PatientRegistration',
            properties: Object.assign({}, getMetadata(), {
                hasPostCode,
            }),
        });
    }
}

/**
 * Logs the scheduling of a patient.
 * @returns {void}
 */
export function logPatientScheduled() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'PatientScheduled', properties: Object.assign({}, getMetadata()) });
    }
}

// BILLING LOGGING //////////////////

/**
 * Logs the finalisation of a bill.
 * @param {number} prescriptionItemsCount Number of prescription items for this bill.
 * @param {number} salesItemsCount Number of sales items for this bill.
 * @param {boolean} isPastBill True if the finalised bill was in the past.
 * @param {boolean} isEdit True if the bill finalisation was the result of an edit of an old bill.
 * @returns {void}
 */
export function logBillFinalisation(prescriptionItemsCount: number, salesItemsCount: number, isPastBill: boolean = false, isEdit: boolean = false) {
    if (AppInsights) {
        AppInsights.trackEvent({
            name: 'BillFinalised',
            properties: Object.assign(
                {},
                {
                    prescriptionItemsCount,
                    salesItemsCount,
                    isPastBill: String(isPastBill),
                    isEdit: String(isEdit),
                },
                getMetadata(),
            ),
        });
    }
}

// INVENTORY LOGGING //////////////////

/**
 * Logs the creation of an SKU item
 * @returns {void}
 */
export function logSKUCreation() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'SKUCreated', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when a drug was created;
 * @returns {void}
 */
export function logDrugCreated() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'DrugCreated', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when a drug was edited;
 * @returns {void}
 */
export function logDrugEdited() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'DrugEdited', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when a drug was created;
 * @returns {void}
 */
export function logDiscountChargeCreated() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'DiscountChargeCreated', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when a drug was edited;
 * @returns {void}
 */
export function logDiscountChargeEdited() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'DiscountChargeEdited', properties: Object.assign({}, getMetadata()) });
    }
}

// ENCOUNTER LOGGING /////////////////
/**
 * Logs when a diagnosis was created;
 * @returns {void}
 */
export function logDiagnosisCreated() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'DiagnosisCreated', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs when a symptom was created;
 * @returns {void}
 */
export function logSymptomCreated() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'SymptomCreated', properties: Object.assign({}, getMetadata()) });
    }
}

// PRINT LOGGING /////////////////
/**
 * Logs when a prescription label was printed;
 * @returns {void}
 */
export function logPrescriptionLabelPrinted() {
    if (AppInsights) {
        AppInsights.trackEvent({ name: 'PrescriptionLabelPrinted', properties: Object.assign({}, getMetadata()) });
    }
}

/**
 * Logs error to app insights
 * @param {string} errorMessage Error message to log
 * @param {any} errorProperties Error properties to log
 * @returns {void}
 */
export function logError(errorMessage: string, designDoc?: string, errorProperties?: any): void {
    if (AppInsights) {
        // eslint-disable-next-line max-len
        AppInsights.trackException({
            error: new Error(errorMessage),
            properties: Object.assign({}, { errorProperties, designDoc }, getMetadata()),
            severityLevel: SeverityLevel.Error,
        });
    }
}

/**
 * Prints the string to console if the user is klinify_admin or debug is turned on. All
 * receives an optional messageType that can be used to give alternative styles.
 * @param  {any} output The message to log.
 * @param  {string} messageType The type of message ('error', 'warning', 'info');
 * @param  {string} userId Alternatively pass the userId directly
 * @return {undefined}
 */
export const debugPrint = (output: any, messageType: string | void, userId?: string) => {
    // eslint-disable-line flowtype/no-weak-types
    if (location.hostname === 'dev.klinify.com' || (userId || getUserName()) === 'klinify_admin' || messageType === 'error') {
        if (typeof output === 'string') {
            console.log(`K: ${output}`);
        } else {
            console.log(output);
        }
    }
};
