import React from 'react';
import translate from './../utils/i18n';
import AnnouncementModal from './modals/announcementModal';

type Props = {
  program: string,
};

/**
 * Render the banner
 * @param {string} program text to add
 * @returns {HTMLElement} html element
 */
const EConsultFeatureAnnouncementModal = ({ program }: Props) => (
  <AnnouncementModal
    program={program}
    modalHeading={translate('econsult_program_modal_title')}
    id="econsult-program-modal"
    image={<img src="/static/images/e_consult_banner.png" alt="program" style={{ maxWidth: '620px' }} />}
  >
      Hello Doctor,
    <br /><br />
      We are proud to announce our new E-consult feature that will
      allow you to easily do a video consultation with your patients.
      This feature allows you to reach out to all your existing patients
      and offer them video consultations. To help you announce this feature
      to your patients, we are offering
    <span className="u-strong">&nbsp;100 patient campaign SMSes for free!</span> Click&nbsp;
    <a style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer" href="https://klin.freshdesk.com/support/solutions/articles/36000222956-how-to-use-e-consult-feature-">
        here
    </a>
      &nbsp;to get started!
    <br /><br />
    <span className="u-strong">We are offering this feature for free until the 31st of May 2020.
      Thereafter,
    </span> we charge a <span className="u-strong">Klinify platform fee</span> of <span className="u-strong">RM3</span> per e-consult.
    <br />
    <br />
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <p>Contact us via this Chat window if you would like to activate your free SMSes.
          Please visit our FAQ or contact the Support Team on Klinify if you have further questions
        <i style={{ content: "url('/static/images/freshchat_icon.png')", height: '40px' }} />
      </p>
    </div>
    <br />
    <p>Thank You.</p>
    <br />
  </AnnouncementModal>
);

export default EConsultFeatureAnnouncementModal;
