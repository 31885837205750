import React from 'react';
import { List, Map } from 'immutable';

import { sum, convertNumberToPrice } from './../../utils/utils';
import KeyValue from './../layout/keyValue';
import { convertTimestampToDate, convertDateToTimestamp } from './../../utils/time';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import OperationsSummarySidebar from './operationsSummarySidebar';

import type { MapValue } from './../../types';
import type BillModel from './../../models/billModel';
import type SupplyItemModel from './../../models/supplyItemModel';
import type SupplyModel from './../../models/supplyModel';

type SupplyID = string;
type Props = {
  filter: Map<string, MapValue>,
  onFilterUpdated: () => Promise<void>,
  bills: List<BillModel>,
  supplyItems: Map<SupplyID, List<SupplyItemModel>>,
  supplies: List<SupplyModel>,
};

type State = {
  totalRevenue?: number | string,
  nettSummary?: number | string,
  isFetching: boolean,
};

/**
 * A component that displays Operations summary.
 * @class OperationsSummary
 * @extends {Component}
 */
class OperationsSummary extends React.Component<Props, State> {
  /**
   * Creates an instance of Operations summary.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isFetching: false,
      totalRevenue: undefined,
      nettSummary: undefined,
    };
    this.getRevenueData(props.bills);
  }

  /**
   * Triggers a data fetch when the bills prop changes.
   * @param {Props} prevProps Previous Props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (prevProps.bills !== this.props.bills) {
      this.getRevenueData(this.props.bills);
    }
    if (this.props.bills.size === 0 && this.state.nettSummary) {
      this.resetState();
    }
  }

  /**
   * A filter to check if models are in the selected date range
   * @param {number} timestamp model timestamp
   * @returns {boolean} true if the timestamp given is within range
   */
  checkDateRange(timestamp: number) {
    const { filter } = this.props;
    if (
      convertTimestampToDate(timestamp) >= convertTimestampToDate(Number(filter.get('filterDateStart'))) &&
      convertTimestampToDate(timestamp) <= convertTimestampToDate(Number(filter.get('filterDateEnd')))
    ) {
      return true;
    }
    return false;
  }

  /**
   * Reset state
   * @returns {void}
   */
  resetState() {
    this.setState({ isFetching: false, totalRevenue: undefined, nettSummary: undefined });
  }

  /**
   * Get revenue data and add total to state
   * Inclusive of claims, payments, payments outstanding
   * @param {List<BillModel>} bills List of BillModel
   * @returns {void}
   */
  getRevenueData(bills: List<BillModel>) {
    if (bills.size === 0) {
      this.resetState();
    } else {
      this.setState({ isFetching: true });
      const totalRevenue = sum(bills.map(bill => bill.get('total_amount')));
      this.setState({ totalRevenue, isFetching: false }, () => {
        this.getNettSummary();
      });
      this.getTotalPurchases();
    }
  }

  /**
   * Get the total Purchases
   * Sum of all supply line item prices for selected dates
   * @returns {number} total of all supply items
   */
  getTotalPurchases() {
    const supplyIds = this.props.supplies
      .filter(supply => supply && this.checkDateRange(convertDateToTimestamp(supply.get('date'))))
      .map(s => s.get('_id'));
    const supplyItemTotals = supplyIds
      .reduce((totals, supplyId) => totals.concat(this.props.supplyItems
        .get(supplyId, List())
        .map(supplyItem => supplyItem.getPriceData())), List());
    return sum(supplyItemTotals);
  }

  /**
   * Get the nett summary
   * Total revenue minus total purchases
   * @returns {void}
   */
  getNettSummary() {
    const nettSummary = Number(this.state.totalRevenue) - this.getTotalPurchases();
    this.setState({ nettSummary });
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    const { totalRevenue, isFetching } = this.state;
    const totalPurchases = this.getTotalPurchases();
    const nettSummary = (totalRevenue === undefined && this.getTotalPurchases() > 0)
      ? -totalPurchases
      : this.state.nettSummary;
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <div className="c-billing__content">
          <h1 data-public className="o-title">{translate('operations_summary')}</h1>
          <div className="u-margin--standard">
            <KeyValue label={translate('total_revenue')} value={convertNumberToPrice((isFetching || !totalRevenue) ? 0 : totalRevenue)} />
            <KeyValue label={translate('total_purchases')} value={convertNumberToPrice((isFetching || !totalPurchases) ? 0 : totalPurchases)} />
            <KeyValue label={translate('nett_summary')} value={convertNumberToPrice((isFetching || !nettSummary) ? 0 : nettSummary)} />
          </div>
        </div>
        <OperationsSummarySidebar
          onFilterUpdated={this.props.onFilterUpdated}
          filter={this.props.filter}
        />
      </ContentTransition>
    );
  }
}

export default OperationsSummary;
