import React from 'react';
import { List } from 'immutable';

import { createSuccessNotification } from './../../utils/notifications';
import translate from './../../utils/i18n';
import Table from './../table/table';
import { getConfirmation } from './../../utils/utils';
import Button from './../buttons/button';

import type { Model } from './../../types';

type Props = {
  items: List<string>,
  deleteString: (item: string) => Promise<void>,
  columnLabel: string,
  canDelete: boolean,
  canEdit: boolean,
  type: string,
  onEditClicked: (item: string) => void,
};

const defaultSorted = [{ id: 'index', desc: false }];

/**
   * Called when delete is clicked on an item. Updates its state to be hidden.
   * @param {string} item The item to delete
   * @param {deleteString} deleteString The function to delete the item.
   * @returns {void}
   */
function onDeleteClicked(item: string, deleteString: (item: string) => Promise<void>) {
  if (item) {
    getConfirmation(translate('confirm_item_deletion'))
      .then(
        () => {
          deleteString(item)
            .then(() => { createSuccessNotification(translate('item_deleted')); });
        },
        () => {},
      );
  }
}

/**
 * Returns a Delete button for the given item.
 * @param {BaseModel} item An item for the delete button
 * @param {deleteString} deleteString The function to delete the item.
 * @returns {React.Component} A Delete button component.
 */
function getDeleteButton(item: string, deleteString: (item: string) => Promise<void>) {
  return (
    <Button
      className="o-text-button o-text-button--danger"
      onClick={() => onDeleteClicked(item, deleteString)}
      dataPublic
    >
      {translate('delete')}
    </Button>
  );
}

/**
 * Returns a Edit button for the given item.
 * @param {BaseModel} item An item for the delete button
 * @param {function} onClick The function to run when edit is clicked.
 * @returns {React.Component} A Delete button component.
 */
function getEditButton(item: string, onClick: (model: Model) => void) {
  return (
    <Button
      className="o-text-button o-text-button--contextual"
      onClick={() => onClick(item)}
      dataPublic
    >
      {translate('edit')}
    </Button>
  );
}

/**
 * Table of strings
 * @param {Props} props Props
 * @returns {React.Component}
 */
const StringListEditorTable = ({
  items, deleteString, columnLabel, canDelete = true, canEdit = true, type, onEditClicked,
}: Props) => {
  const allColumns = [];
  if (type === 'panel_categories') {
    allColumns.push({ accessor: 'index', Header: '#' });
  }
  allColumns.push({ accessor: 'item', Header: columnLabel, filterable: true });
  if (type === 'patient_tags' ||
    type === 'panel_categories') {
    allColumns.push({
      accessor: 'edit', Header: '', sortable: false, show: true, width: 60,
    });
  }
  allColumns.push({
    accessor: 'delete', Header: '', sortable: false, minWidth: 100, width: 100, show: true,
  });
  const itemRows = items
    .map((item, index) => ({
      index: index + 1,
      item,
      delete: canDelete ? getDeleteButton(item, deleteString) : null,
      edit: canEdit ? getEditButton(item, onEditClicked) : null,
    }))
    .toArray();
  return (
    <Table
      columns={allColumns}
      data={itemRows}
      noDataText={translate('no_items')}
      showPagination
      defaultSorted={defaultSorted}
    />
  );
};

export default StringListEditorTable;
