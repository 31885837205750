import React from 'react';

import { getFreshdeskLink } from './../../utils/utils';
import translate from './../../utils/i18n';
import { trackEvent, CLICK, SIDEBAR_ITEM } from './../../utils/analytics';
import { colours } from '../../utils/css';

import type { HTMLStyle } from './../../types';

/**
 * A link the signs in to freshdesk and opens it up in a new tab.
 * @class SupportLink
 * @extends {React.Component<Props, State>}
 */
const SupportLink = ({ style }: { style: HTMLStyle }) =>
  <a
    className="c-sidebar__item"
    href={getFreshdeskLink()}
    target="_blank"
    style={style}
    onClick={() => trackEvent(SIDEBAR_ITEM, CLICK, 'help')}
    rel="noopener noreferrer"
  >
    <div className="c-sidebar__item__main"  style={{ backgroundColor: colours.maroon }} data-public>
      <div className="c-sidebar__item__main__icon">
        <img src="static/images/information_icon.svg" alt={translate('contact_us')} />
      </div>
      <div className="c-sidebar__item__main__text">{translate('help')}</div>
    </div>
  </a>;

export default SupportLink;
