
import { connect } from 'react-redux';
import { List } from 'immutable';
import { withRouter } from 'react-router-dom';

import type { Dispatch, State } from './../types';
import { fetchKeyMetricsList, fetchAnalyticsReportsList } from './../utils/api';
import { setIsFetching, setAnalyticsDateRange, setAnalyticsKeyMetricsList, setAnalyticsReportList } from './../actions';

import SummaryPage from './../components/analytics/summaryPage';
import type AnalyticsSummaryItemModel from '../models/analytics/summaryItemModel';
import type AnalyticsReportModel from '../models/analytics/reportModel';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps
  = ({ isFetching, analyticsReportViews }: State) => ({
    dateRange: analyticsReportViews.dateRange,
    summaryItems: isFetching ? List() : analyticsReportViews.keyMetrics,
    reportItems: isFetching ? List() : analyticsReportViews.reportLinks,
  });

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchKeyMetricsList,
  fetchAnalyticsReportsList,
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setAnalyticsDateRange: (dateRange: string) => dispatch(setAnalyticsDateRange(dateRange)),
  setAnalyticsKeyMetricsList: (keyMetrics: List<AnalyticsSummaryItemModel>) =>
    dispatch(setAnalyticsKeyMetricsList(keyMetrics)),
  setAnalyticsReportList: (reportsList: List<AnalyticsReportModel>) =>
    dispatch(setAnalyticsReportList(reportsList)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryPage));
