import React from 'react';
import type { Map, List } from 'immutable';
import type { Column } from './../../types';

import { debugPrint } from './../../utils/logging';
import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import MapListEditorTable from './mapListEditorTable';
import MapListEditorForm from './mapListEditorForm';

type Props = {
  items: List<Map<string, string>>,
  columns: Array<Column>,
  label: string,
  type: string,
  updateMaps: (items: List<Map<string, string>>) => Promise<void>,
}

/**
 * Similar to ListEditor, but for ad-hoc maps of strings rather than DB models.
 * @namespace MapListEditor
 */
class MapListEditor extends React.Component<Props, { mapToEdit: Map<string, string> | void,
  indexToEdit: number }> {
  /**
   * Creates an instance of MapListEditor.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.state = { mapToEdit: undefined, indexToEdit: -1 };
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering MapListEditor');
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <section className="o-content__main o-scrollable-container" style={{ maxHeight: '100vh' }}>
          <h1 data-public className="o-title">{this.props.label}</h1>
          <div className="o-card u-margin-bottom--4ws">
            <h2 data-public className="o-card__title">{this.props.label}</h2>
            <MapListEditorTable
              {...this.props}
              onEditClicked={mapToEdit => this.setState({
                mapToEdit,
                indexToEdit: this.props.items.indexOf(mapToEdit),
              })}
              deleteMap={
                (map: Map<string, string>): Promise<void> =>
                  this.props.updateMaps(this.props.items.filter(i => i !== map))
              }
            />
          </div>
        </section>
        <section className="o-scrollable-container o-sidebar--right">
          <h1 data-public className="o-title u-font-color-white">
            {this.state.mapToEdit ? translate('edit_item') : translate('add_item')}
          </h1>
          <MapListEditorForm
            mapToEdit={this.state.mapToEdit}
            clearMapToEdit={() => this.setState({ mapToEdit: undefined, indexToEdit: -1 })}
            type={this.props.type}
            updateMap={
              (mapToUpdate: (Map<string, string>)): Promise<void> => {
                const idx = this.state.indexToEdit;
                const newItems = (idx === -1) ? this.props.items.push(mapToUpdate) :
                  this.props.items.set(idx, mapToUpdate);
                return this.props.updateMaps(newItems);
              }
            }
            {...this.props}
          />
        </section>
      </ContentTransition>
    );
  }
}

export default MapListEditor;
