import React from 'react';
import type { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import PatientPage from './../layout/patientPage';
import CasenoteGridContainer from './../../containers/casenoteGridContainer';
import CasenoteContainer from './../../containers/casenoteContainer';
import CasenotesHeaderContainer from './../../containers/casenotesHeaderContainer';

import type AllergyModel from './../../models/allergyModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type CategoryModel from './../../models/categoryModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { ActiveIngredient, User } from './../../types';
import DrugModel from './../../models/drugModel';

type Props = {
  allergies: List<AllergyModel>,
  casenoteID?: string,
  categoryID: string,
  categories: List<CategoryModel>,
  coveragePayors: List<CoveragePayorModel>,
  selectedCategory?: CategoryModel,
  patient?: PatientModel,
  patientStub?: PatientStubModel,
  useGridView: boolean,
  user: User,
  drugs: List<DrugModel>,
}

/**
 * A with a category selector and a section displaying either the selected category as a
 * CategoryOverview or all categories as CategoryOverviews.
 * @class Casenotes
 * @extends {React.Component}
 */
class CategoryOverview extends React.PureComponent<Props> {
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const categories = this.props.selectedCategory ?
      [this.props.selectedCategory] : this.props.categories;
    const patient = this.props.patient ? this.props.patient : this.props.patientStub;
    if (!patient) {
      throw new Error('No Patient or PatientStub model was provided');
    }
    return (
      <PatientPage
        patient={patient}
        coveragePayors={this.props.coveragePayors}
        allergies={this.props.allergies}
        user={this.props.user}
        headerHeight={60}
        header={
          <CasenotesHeaderContainer
            casenoteID={this.props.casenoteID}
            patientID={patient.get('_id')}
            categoryID={this.props.categoryID}
            gridViewActive={this.props.useGridView}
          />
        }
        drugs={this.props.drugs}
      >
        {
          this.props.useGridView ?
            [
              <h1 data-public className="o-title" key="categoryOverviewTitle">{translate('casenotes')}</h1>,
              categories.map(c =>
                <CasenoteGridContainer
                  sidebarCategoryID={this.props.categoryID}
                  patientID={patient.get('_id')}
                  categoryID={c.get('_id')}
                  key={c.get('_id')}
                />),
            ] :
            <CasenoteContainer
              casenoteID={this.props.casenoteID}
              patientID={patient.get('_id')}
              categoryID={this.props.categoryID}
            />
        }
      </PatientPage>
    );
  }
}

export default CategoryOverview;
