import * as React from 'react';
import glamorous from 'glamorous';
import type { Node } from 'react';

import Label from './label';
import { colours, wsUnit, borderRadius } from './../../utils/css';

type Props = {
  inputLeft: boolean, // Defaults to false
  content?: string | Node,
  // props specific to input
  autoCapitalize?: string,
  autoFocus: boolean,
  className?: string,
  description?: string,
  disabled: boolean,
  divClassName: string,
  hideLabel?: boolean,
  id: string,
  inputStyle: { [key: string]: string | number },
  label: string,
  labelClassName: string,
  onValueChanged: (value: string) => void,
  placeholder: string,
  required?: boolean,
  style: { [key: string]: string | number },
  type: 'text' | 'password' | 'number' | 'email',
  value: string | number,
  errorMessage?: string,
  min?: number,
  pattern?: string,
  step?: number,
  descClassName?: string,
};

const InputGroupWrapper = glamorous.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '& input': {
    marginBottom: wsUnit,
  },
});

const InputGroupContent = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  marginBottom: wsUnit,
  backgroundColor: colours.grey3,
  padding: `0 calc(${wsUnit} / 2)`,
  borderRadius,
  paddingTop: '1px', // There's some offset in inputs that I couldn't quite figure out. This offsets that offset.
}, ({ inputLeft }) => ({
  marginLeft: inputLeft ? wsUnit : undefined,
  marginRight: inputLeft ? undefined : wsUnit,
  order: inputLeft ? 1 : undefined,
}));

/**
 * A component for adding an immutable content section to an input. This will have label, description
 * to an input along with a greyed out input grouped with it either to its left or right.
 * @class InputGroup
 * @extends {React.Component<Props, State>}
 */
class InputGroup extends React.PureComponent<Props> {
  static defaultProps = {
    inputLeft: false,
    autoFocus: false,
    disabled: false,
    divClassName: '',
    type: 'text',
    placeholder: '',
    labelClassName: '',
    inputStyle: {},
    style: {},
    value: '',
    onValueChanged: () => {},
  };

  /**
   * Gets the placeholder for the input, returns label if empty.
   * @returns {(string)}
   */
  getPlaceholder = (): string => {
    // Set placeholder to label if empty.
    if (this.props.placeholder === '') {
      return this.props.label;
    }
    return this.props.placeholder;
  }

  /**
   * on change event handler, calls the handler passed to the component.
   * @param {SyntheticInputEvent} event change event
   * @returns {(void)}
   */
  onChange = (event: SyntheticInputEvent<*>) => {
    let { value } = event.target;
    // Set capitalisation of input.
    if (this.props.autoCapitalize === 'words') {
      const re = /(\b[a-z](?!\s))/g; // Regex to get first letter after each word boundary.
      value = value.replace(re, x => x.toUpperCase());
    }
    this.props.onValueChanged(value);
  }

  /**
   * Gets the validation pattern for the input
   * @returns {(string | void)}
   */
  getPattern(): string | void {
    if (this.props.pattern) {
      return this.props.pattern;
    }
    return this.props.type === 'number' ? '[1-9]\d*(\.\d+)?' : undefined; // eslint-disable-line no-useless-escape
  }

  /**
   * Returns true if current value matches patterns/min/required props.
   * @returns {boolean}
   */
  isValid(): boolean {
    const parsedValue = parseFloat(this.props.value);
    if (
      this.props.min !== undefined &&
      this.props.min !== null &&
      !Number.isNaN(parsedValue) &&
      parsedValue < this.props.min
    ) {
      return false;
    }
    if (this.props.required) {
      return this.props.value !== undefined && this.props.value !== '';
    }
    return true;
  }

  /**
   * Gets the input html component.
   * @param {{}} minProp min attribute for numbers
   * @param {string} inputClassName css class name for input element
   * @returns {React.Node}
   */
  getInput(minProp?: { min?: number | string }, inputClassName: string): React.Node {
    return (
      <input
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off" // Turning off for now because Safari has bugs with this.
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        spellCheck="false"
        id={this.props.id}
        placeholder={this.props.disabled ? '' : this.getPlaceholder()}
        className={inputClassName}
        type={this.props.type}
        value={this.props.value}
        onChange={this.onChange}
        required={this.props.required}
        style={this.props.inputStyle}
        pattern={this.getPattern()}
        {...minProp}
        step={this.props.step}
      />
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { inputLeft, content } = this.props;
    const divClassName = `o-form__item ${this.props.divClassName}`;
    let inputClassName = 'o-text-input';
    if (this.props.className) {
      inputClassName += ` ${this.props.className}`;
    }
    if (this.props.errorMessage) {
      inputClassName += ' o-text-input--with-error';
    }
    let description = '';
    if (this.props.description) {
      let descClassName = 'o-sub-label';
      if (this.props.descClassName) {
        descClassName += ` ${this.props.descClassName}`;
      }
      description = <p className={descClassName}>{this.props.description}</p>;
    }
    const minProp = this.props.type === 'number' ? { min: this.props.min } : undefined;
    return (
      <div>
        <div className={divClassName} style={this.props.style}>
          {
            !this.props.hideLabel &&
            <Label
              className={this.props.labelClassName}
              id={this.props.id}
              label={this.props.label}
              invalid={!this.isValid()}
            />
          }
          { description }
          {
            this.props.errorMessage &&
            <div className="o-text-input__error">{this.props.errorMessage}</div>
          }
        </div>
        <InputGroupWrapper inputLeft={inputLeft}>
          <InputGroupContent inputLeft={inputLeft}>{content}</InputGroupContent>
          {
            this.getInput(minProp, inputClassName)
          }
        </InputGroupWrapper>
      </div>
    );
  }
}

export default InputGroup;
