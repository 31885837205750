import React from 'react';

import translate from './../../utils/i18n';
import { trackEvent, CLICK, SIDEBAR_ITEM } from './../../utils/analytics';

type State = {
  isHovering: boolean
};

/**
 * A refferal link and opens it up in a new tab.
 * @class ReferralLink
 * @extends {React.Component<Props, State>}
 */
class ReferralLink extends React.Component<{}, State> {
  /**
   * Creates an instance of ReferralLink
   * @param {Props} props Initial props
   */
  constructor(props: {}) {
    super(props);
    this.state = { isHovering: false };
    this.parentContainerRef = null;
  }

  handleMouseHover = () => {
    this.setState(prevState => ({ isHovering: !prevState.isHovering }));
  }

  /**
   * Renders tooltip
   * @returns {HTML}
   */
  renderTooltip() {
    const url = 'https://www.klinify.com/refer-earn-500?utm_source=mobile-web-app&utm_medium=webapp&utm_campaign=refer-earn-500';
    return (
      <a
        href={url}
        onClick={() => trackEvent(SIDEBAR_ITEM, CLICK, 'referral_link')}
        target="_blank"
        className="u-strong u-font-color-white"
        rel="noopener noreferrer"
      >
        <div
          className="c-sidebar__item__tooltip"
          style={{
            transition: '.2s',
            opacity: this.state.isHovering ? 1 : 0,
            left: this.state.isHovering ? 200 : 0,
            ...this.parentContainerRef &&
              { top: this.parentContainerRef.getBoundingClientRect().top },
          }}
        >
          <p data-public><span data-public className="u-strong">{translate('earn_upto_rm500')}</span> {translate('for_every_doctor_you_refer_to_us')}</p>
          <span data-public className="u-strong">{translate('click_here_to_refer')}</span>
        </div>
      </a>
    );
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const url = 'https://www.klinify.com/refer-earn-500?utm_source=mobile-web-app&utm_medium=webapp&utm_campaign=refer-earn-500';
    const showTooltipTo = ['#/', '#/registration', '#/patient-list'];
    return (
      <div
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        data-public
        ref={(c) => { this.parentContainerRef = c; }}
        id="parent-container"
      >
        { showTooltipTo.includes(location.hash) && this.renderTooltip() }
        <a
          className="c-sidebar__item"
          href={url}
          onClick={() => trackEvent(SIDEBAR_ITEM, CLICK, 'referral_link')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="c-sidebar__item__main">
            <div className="c-sidebar__item__main__icon">
              <img src="static/images/icon_sidebar_referral.png" alt={translate('refer_and_earn')} />
            </div>
            <div className="c-sidebar__item__main__text">{translate('refer_and_earn')}</div>
          </div>
        </a>
      </div>
    );
  }
}

export default ReferralLink;
