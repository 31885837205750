import React from 'react';
import { List } from 'immutable';
import glamorous from 'glamorous';

import StatelessModal from './../modals/statelessModal';
import translate from './../../utils/i18n';
import { validateAndTrimString } from './../../utils/utils';
import AddToInventoryForm from './addToInventoryForm';
import AdjustBatchQuantityModal from './adjustBatchQuantityModal';
import DispensingBatch from './dispensingBatch';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission, hasSomePermission } from './../../utils/permissions';
import SupplyItemModel from './../../models/supplyItemModel';
import Button from './../buttons/button';

import type DrugModel from './../../models/drugModel';
import type { Config, MapValue, CountPerSKUAndBatch, User, SaveModel, SaveModels } from './../../types';
import type SupplierModel from '../../models/supplierModel';
import SupplyModel from '../../models/supplyModel';

type Props = {
  isBulk: boolean,
  drug?: DrugModel,
  disabled?: boolean,
  user: User,
  isFetching: boolean,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugs: List<DrugModel>,
  supplyItems: List<SupplyItemModel>,
  suppliers: List<SupplierModel>,
  saveModel: SaveModel,
  saveModels: SaveModels,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  supplyItem?: SupplyItemModel,
  supplies: List<SupplyModel>,
};

type State = {
  modalVisible: boolean,
  isAddToInventoryFormVisible: boolean,
  addToInventoryFormAction: string,
  isAdjustBatchQuantityModalVisible: boolean,
  adjustBatchQuantityModalAction: string,
}

export enum ActionMode {
  BULK = 'bulk',
  DRUG = 'drug',
  BATCH = 'batch',
  UPDATE_DETAILS= 'update_details',
}

const SpaceBox = glamorous.div({
  width: '100%',
});

/**
 * A modal for editing prescriptions.
 * @class StockAdjustmentActions
 * @extends {React.Component}
 */
class StockAdjustmentActions extends React.Component<Props, State> {
  static defaultProps = {
    isBulk: false,
  };

  /**
   * return the action mode
   * @returns {ActionMode} action mode Bulk stock adjustemnt or single sku adjustment or batch adjustment
   */
  getActionMode = () : ActionMode => {
    const { drug, supplyItem } = this.props;
    if (drug) {
      return ActionMode.DRUG;
    }
    if (supplyItem) {
      return ActionMode.BATCH;
    }
    return ActionMode.BULK;
  }

  actionMode: ActionMode;

  /**
   * Creates an instance of StockAdjustmentActions.
   * @param {Props} props initialProps
   */
  constructor(props: Props) {
    super(props);
    this.actionMode = this.getActionMode();
    this.state = {
      modalVisible: false,
      isAddToInventoryFormVisible: false,
      addToInventoryFormAction: '',
      isAdjustBatchQuantityModalVisible: false,
      adjustBatchQuantityModalAction: '',
    };
  }

  /**
   * Handles the AddToInventoryForm
   * @param {string} action Form action
   * @returns {void}
   */
  handleAddToInventoryForm(action: string) {
    this.setState({ isAddToInventoryFormVisible: true, addToInventoryFormAction: action });
  }

  /**
   * Handles the RemoveFromInventoryForm
   * @param {string} action Form action
   * @returns {void}
   */
  handleRemoveFromInventoryForm(action: string) {
    this.setState({
      isAdjustBatchQuantityModalVisible: true,
      adjustBatchQuantityModalAction: action,
    });
  }

  /**
   * Handles the update of batch details
   * @param {string} action Form action
   * @returns {void}
   */
  handleBatchDetailsUpdate(action: string) {
    this.setState({
      isAdjustBatchQuantityModalVisible: true,
      adjustBatchQuantityModalAction: action,
    });
  }


  /**
   * return modal button label on the action mode
   * @returns {string}
   */
  getButtonLabel() {
    switch (this.actionMode) {
      case ActionMode.BULK:
        return 'bulk_stock_adjustment';
      default:
        return 'modify';
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { drug, disabled, drugs, supplyItems, suppliers, supplyItem } = this.props;
    const {
      isAddToInventoryFormVisible, addToInventoryFormAction,
      isAdjustBatchQuantityModalVisible, adjustBatchQuantityModalAction,
    } = this.state;
    const drugName = drug ? validateAndTrimString(drug.get('name')) : '';
    const isBulk = this.actionMode === ActionMode.BULK;
    const isBatch = this.actionMode === ActionMode.BATCH;
    const isDrug = this.actionMode === ActionMode.DRUG;
    const isBatchOrDrugMode = ((isDrug && drug) || (isBatch && supplyItem));
    const buttonLabel = this.getButtonLabel();
    return (
      <React.Fragment>
        <StatelessModal
          id="stock-adjustment-actions"
          buttonLabel={translate(buttonLabel)}
          buttonClass="o-button o-button--padded o-button--small"
          title={translate(isBulk ? 'bulk_stock_adjustment' : 'modify_stock_for', { sku: drugName })}
          setVisible={(isVisible: boolean) => this.setState({ modalVisible: isVisible })}
          visible={this.state.modalVisible}
          onClose={() => {}}
          explicitCloseOnly
          disableButton={disabled}
        >
          <div className="u-margin--standard">
            {!isBatch &&
              <React.Fragment>
                <p className="o-label">
                  {translate(isBulk ? 'choose_one_of_these_actions_to_add_bulk' : 'choose_one_of_these_actions_to_add')}
                </p>
                <div className="u-flex-row u-flex-space-between u-margin-top--standard u-margin-bottom--1ws">
                  <Button
                    type="button"
                    className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                    onClick={() => this.handleAddToInventoryForm('supply_item')}
                    dataPublic
                  >
                    {translate('add_supply')}
                  </Button>
                  <Button
                    type="button"
                    className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                    onClick={() => this.handleAddToInventoryForm('bonus')}
                    dataPublic
                  >
                    {translate('bonus')}
                  </Button>
                  <Button
                    type="button"
                    className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                    onClick={() => this.handleAddToInventoryForm('loan_in')}
                    dataPublic
                  >
                    {translate('loan_in')}
                  </Button>
                  <Button
                    type="button"
                    className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                    onClick={() => this.handleAddToInventoryForm('transfer_in')}
                    dataPublic
                  >
                    {translate('transfer_in')}
                  </Button>
                </div>
                <hr />
              </React.Fragment>
            }
            <p className="o-label">
              {translate(isBulk ? 'choose_one_of_these_actions_to_remove_bulk' : 'choose_one_of_these_actions_to_remove')}
            </p>
            <div className="u-flex-row u-flex-space-between u-margin-bottom--half-ws u-margin-top--standard">
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('write_off')}
                dataPublic
              >
                {translate('write_off')}
              </Button>
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('damaged')}
                dataPublic
              >
                {translate('damaged')}
              </Button>
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('expired')}
                dataPublic
              >
                {translate('expired')}
              </Button>
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('loan_out')}
                dataPublic
              >
                {translate('loan_out')}
              </Button>
            </div>
            <div className="u-flex-row u-flex-space-between u-margin-bottom--1ws">
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('transfer_out')}
                dataPublic
              >
                {translate('transfer_out')}
              </Button>
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('missing')}
                dataPublic
              >
                {translate('missing')}
              </Button>
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('dispensing_error')}
                dataPublic
              >
                {translate('dispensing_error')}
              </Button>
              <Button
                type="button"
                className="o-button o-button--small o-button--full-width u-margin-right--half-ws"
                onClick={() => this.handleRemoveFromInventoryForm('consumed')}
                dataPublic
              >
                {translate('consumed')}
              </Button>
            </div>
            {
              isBatchOrDrugMode && hasSomePermission(this.props.user, List([
                createPermission('currently_dispensing_batch', 'update'),
                createPermission('adjust_batch_quantity', 'create'),
                createPermission('update_batch_details', 'update')
              ])) ?
                <React.Fragment>
                  <hr />
                  <p className="o-label">
                    {translate('choose_one_of_these_actions_to_batch_modification')}
                  </p>
                  <div className="u-flex-row u-margin-bottom--half-ws u-margin-top--standard">
                    {(isDrug && drug) &&
                      <PermissionWrapper
                        permissionsRequired={List([createPermission('currently_dispensing_batch', 'update')])}
                        user={this.props.user}
                      >
                        <DispensingBatch
                          config={this.props.config}
                          drug={drug}
                          supplyItems={supplyItems}
                          saveModel={this.props.saveModel}
                          isFetching={this.props.isFetching}
                          inventoryCount={this.props.inventoryCount}
                          inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
                          onClose={isFromClose =>
                            this.setState({ modalVisible: isFromClose || false })
                          }
                        />
                      </PermissionWrapper>
                    }
                    <PermissionWrapper
                      permissionsRequired={List([createPermission('adjust_batch_quantity', 'create')])}
                      user={this.props.user}
                      denialContent={() => <SpaceBox />}
                    >
                      <Button
                        type="button"
                        className="o-button o-button--small o-button--half-width u-margin-right--half-ws"
                        onClick={() => this.handleRemoveFromInventoryForm('adjustment')}
                        dataPublic
                      >
                        {translate('adjust_batch_quantity')}
                      </Button>
                    </PermissionWrapper>
                    <PermissionWrapper
                      permissionsRequired={List([createPermission('update_batch_details', 'update')])}
                      user={this.props.user}
                      denialContent={() => <SpaceBox />}
                    >
                      <Button
                        type="button"
                        className="o-button o-button--small u-margin-right--half-ws"
                        onClick={() => this.handleBatchDetailsUpdate(ActionMode.UPDATE_DETAILS)}
                        dataPublic
                        disabled={this.props.supplyItem?.get('_id') === 'UNKNOWN'}
                      >
                        {translate('update_batch_details')}
                      </Button>
                    </PermissionWrapper>
                  </div>
                </React.Fragment> :
                null
            }
          </div>
        </StatelessModal>

        { isAddToInventoryFormVisible && addToInventoryFormAction &&
          <AddToInventoryForm
            action={addToInventoryFormAction}
            isVisible={isAddToInventoryFormVisible}
            onClose={isFromClose => this.setState({ isAddToInventoryFormVisible: false, addToInventoryFormAction: '', modalVisible: isFromClose })}
            {...this.props}
            skuID={drug ? drug.get('_id') : ''}
            isBulk={isBulk}
          />
        }

        { isAdjustBatchQuantityModalVisible && adjustBatchQuantityModalAction &&
          <AdjustBatchQuantityModal
            action={adjustBatchQuantityModalAction}
            isVisible={isAdjustBatchQuantityModalVisible}
            drug={drug}
            supplyItem={supplyItem}
            drugs={drugs}
            supplyItems={supplyItems}
            onClose={isFromClose => this.setState({ isAdjustBatchQuantityModalVisible: false, adjustBatchQuantityModalAction: '', modalVisible: isFromClose || false })}
            saveModel={this.props.saveModel}
            isFetching={this.props.isFetching}
            inventoryCountSyncStatus={this.props.inventoryCountSyncStatus}
            updateInventoryItemCount={this.props.updateInventoryItemCount}
            suppliers={suppliers}
            inventoryCount={this.props.inventoryCount}
            config={this.props.config}
            updateConfigValue={this.props.updateConfigValue}
            updateConfig={this.props.updateConfig}
            saveModels={this.props.saveModels}
            supplies={this.props.supplies}
          />
        }
      </React.Fragment>
    );
  }
}

export default StockAdjustmentActions;
