import React, { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import moment, { Moment } from 'moment';

import Label from './label';
import { getDateFormat, prettifyDate } from './../../utils/time';

type Props = {
  autoFocus?: boolean,
  className: string,
  description?: string,
  disabled?: boolean,
  hideLabel?: boolean,
  id: string,
  label?: string,
  labelClassName?: string,
  onChange: (newValue?: Moment | string) => void,
  required?: boolean,
  style: { [key: string]: string | number },
  value?: string | Moment,
  showInvalid?: boolean,
  inputStyle?: React.CSSProperties,
}

type State = {
  value: string,
}

const MASK_CHAR = '_';

/**
 * Takes a string and replaces any of the letters d, m or y with the number 9. This can be used as
 * the mask for the input
 * @param {string} dateFormat A date format
 * @returns {string} A mask of dateFormat.
 */
function getMask(dateFormat: string): string {
  return dateFormat.replace(/[dmy]/gi, '9');
}

/**
 * Creates a DateInput component that shows a masked input for a date.
 * @param {object} props The component props.
 * @returns {React.Component} A DateInput component.
 */
class DateInput extends React.Component<Props, State> {
  static defaultProps = {
    className: '',
    disabled: false,
    required: false,
    style: {},
  };

  /**
   * Creates an instance of DateInput.
   * @param {Props} props Initial props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value && props.value !== '' && props.value.format ? props.value.format(getDateFormat()) : '',
    };
  }

  /**
   * Updates state when receiving new props.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      const { value } = nextProps;
      if (value === undefined || value === '') {
        this.setState({ value: getMask(getDateFormat()).replace(/9/g, MASK_CHAR) });
      } else if (value.isValid && value.isValid()) {
        this.setState({ value: prettifyDate(value.valueOf()) });
      } else {
        this.setState({ value });
      }
    }
  }

  /**
   * Returns true if given value is a valid date.
   * @param {string} value The date value
   * @returns {boolean} True if value is valid
   */
  isValidDate(value: string): boolean {
    return moment(value, getDateFormat(), true).isValid();
  }

  /**
   * Handle state on input change.
   * @param {ChangeEvent<HTMLInputElement>} event Change event.
   * @returns {void}
   */
  onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value === getMask(getDateFormat()).replace(/9/g, MASK_CHAR)) {
      this.props.onChange(); // Mask is empty, set value to undefined.
    } else if (value && this.isValidDate(value)) {
      this.props.onChange(moment(value, getDateFormat()));
    } else if (value) {
      this.props.onChange(value);
    } else {
      this.setState({ value });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    let description: string | JSX.Element = '';
    if (this.props.description) {
      description = <p className="o-sub-label">{ this.props.description }</p>;
    }
    const className = this.props.showInvalid !== undefined && this.props.showInvalid ? 'o-text-input o-text-input--invalid':
      this.isValidDate(this.state.value) || this.state.value === undefined || this.state.value === '' ?
        'o-text-input' : 'o-text-input o-text-input--invalid';
    const inputProps = {
      autoFocus: this.props.autoFocus,
      className,
      name: this.props.id,
      onChange: event => this.onChange(event),
      placeholder: getDateFormat(),
      value: this.state.value,
      disabled: this.props.disabled,
      required: this.props.required,
      style: this.props.inputStyle
    };
    return (
      <div className={`o-form__item ${this.props.className}`} style={this.props.style}>
        {
          !this.props.hideLabel &&
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label || ''}
            invalid={this.props.required && !this.props.value}
          />
        }
        { description }
        <InputMask key={`dateInputMask-${this.props.id}`} {...inputProps} mask={getMask(getDateFormat())} maskChar={MASK_CHAR} />
      </div>
    );
  }
}

export default DateInput;
