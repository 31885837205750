import React from 'react';
import glamorous from 'glamorous';

import Select from '../../components/inputs/select';
import Radio from '../../components/inputs/radio';
import translate from './../../utils/i18n';
import type { SelectOption } from '../../types';

const RadioGroupContainer = glamorous.div({
  width: '48%',
  margin: 'auto',
});

type Props = {
  selectOptions: Array<SelectOption>,
  radioOptions: Array<SelectOption>,
  value: string,
  onChange: (value: string) => void,
  autoFocus?: boolean,
};

/**
 * @returns {React.SFC}
 */
const DispensationUnitSelect = ({
  value,
  onChange,
  autoFocus,
  selectOptions,
  radioOptions,
}: Props) => (
  <>
    <Select
      id="dispensation-unit-select"
      label={translate('dispensation_unit')}
      clearable
      options={selectOptions}
      required
      value={value}
      onValueChanged={onChange}
      placeholder={translate('dropdown_placeholder')}
      autoFocus={autoFocus}
    />
    <RadioGroupContainer>
      <Radio
        id="dispensation-unit-radio"
        label=""
        containerStyle={{ flexWrap: 'wrap', marginBottom: 0 }}
        radioClassName="grouped-radio"
        options={radioOptions}
        value={value}
        onValueChanged={onChange}
      />
    </RadioGroupContainer>
  </>
);

export default DispensationUnitSelect;
