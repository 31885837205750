import React from 'react';
import moment from 'moment';
import { List } from 'immutable';

import type { Map, Seq } from 'immutable';

import translate from './../../utils/i18n';
import { convertTimestampToDate } from './../../utils/time';
import APIError from './../../utils/apiError';
import ContentTransition from './../contentTransition';
import Table from './../table/table';
import InventoryAdjustmentForm from './inventoryAdjustmentForm';
import ExpiryDatesSidebar from './expiryDatesSidebar';
import StatelessModal from './../modals/statelessModal';
import { sortByNumber, sortByDate } from './../../utils/comparators';
import { renderDateTime, renderDateWithLink } from './../../utils/tables';
import { fixedDecimal, isLoading } from './../../utils/utils';
import { UNICODE, DATE_FORMAT_TO_SAVE } from './../../constants';
import Button from './../buttons/button';
import { isBatchDataNotReady } from './../../utils/inventory';
import { debugPrint } from '../../utils/logging';

import type SupplyItemModel from './../../models/supplyItemModel';
import type { MapValue, DrugMap } from './../../types';


type Props = {
  supplyItems: List<SupplyItemModel>,
  drugMap: DrugMap,
  inventoryCount: Map<string, number>,
  adjustInventory: (SKUID: string, change: number, notes: string) => Promise<void>,
  filter: Map<string, MapValue>,
  onFilterUpdated: (filter: Map<string, MapValue>) => void,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  isSKUCountSyncing: boolean,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
};

type Row = {
  expiryDate: number,
  dateCreated: number,
  SKUName: string,
  quantity?: string,
  dispensationUnit: string,
  batchId: string,
  notes: string,
  currentClinicStockOfDrug: string,
  adjust: JSX.Element,
  batchStockRemaining: number,
};

type State = {
  showAdjustmentModal: boolean,
  modalSKUID?: string,
  modalChange?: number,
  selectedSupplyItem?: SupplyItemModel,
  showEmptyBatches: boolean,
};

/**
 * Filter supplyItems by expiry date
 *
 * @param {List<SupplyItemModel>} supplyItems All Supply Items.
 * @param {{}} filter filter
 * @return {List<SupplyItemModel>} supplyItems
 */
function filterSupplyItems(supplyItems, filter) {
  let items;

  if (filter.get('hasSetFilterDateStart') && filter.get('hasSetFilterDateEnd')) {
    items = supplyItems.filter(item =>
      // @ts-ignore
      item.get('expiry_date') >= convertTimestampToDate(filter.get('filterDateStart')) &&
      // @ts-ignore
      item.get('expiry_date') <= convertTimestampToDate(filter.get('filterDateEnd')));
  } else if (filter.get('hasSetFilterDateStart') && !filter.get('hasSetFilterDateEnd')) {
    items = supplyItems.filter(item =>
      // @ts-ignore
      item.get('expiry_date') >= convertTimestampToDate(filter.get('filterDateStart')));
  } else if (filter.get('isItemsWithoutExpiryHidden')) {
    items = supplyItems.filter(item => item.get('expiry_date') !== undefined);
  } else {
    items = supplyItems;
  }

  return items;
}

/**
 * Component displaying all past supplyItems.
 * @class ExpiryDates
 * @extends {React.Component}
 */
class ExpiryDates extends React.PureComponent<Props, State> {
  props: Props;

  state: State;

  /**
   * Creates an instance of CurrentInventory.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      showAdjustmentModal: false,
      modalSKUID: undefined,
      modalChange: undefined,
      selectedSupplyItem: undefined,
      showEmptyBatches: false,
    };
  }

  /**
   * Generates the table rows data sequence from the SupplyItemModels.
   * @param {List<SupplyItemModel>} supplyItems All Supply Items.
   * @param {Seq<Row>} inventoryCount The current inventory state.
   * @param {boolean} isFetching is data is fecthed
   * @returns {Seq<Row>}
   */
  getRows(
    supplyItems: List<SupplyItemModel>,
    inventoryCount: Map<string, number>,
    isFetching: boolean,
  ): Seq<Row> {
    return supplyItems.toSeq()
      .map((supplyItem) => {
        const batchStockRemaining = inventoryCount.getIn([
          supplyItem.get('sku_id'),
          'batches',
          supplyItem.get('_id'),
          'batchStockRemaining',
        ], 0);
        const drug = this.props.drugMap.get(supplyItem.get('sku_id'));
        const currentClinicStockOfDrug = inventoryCount.getIn([supplyItem.get('sku_id'), 'skuStockRemaining'], 0);
        return ({
          expiryDate: supplyItem.get('expiry_date') ?
            moment(supplyItem.get('expiry_date'), DATE_FORMAT_TO_SAVE).valueOf() :
            supplyItem.get('expiry_date'),
          dateCreated: supplyItem.getCreatedTime(),
          SKUName: drug ? drug.get('name') : supplyItem.get('sku_id'),
          currentClinicStockOfDrug: fixedDecimal(currentClinicStockOfDrug),
          batchStockRemaining,
          dispensationUnit: drug ? drug.getDispensationUnit() : UNICODE.EMDASH,
          batchId: supplyItem.get('batch_id'),
          notes: supplyItem.get('notes'),
          adjust: this.getAdjustmentButton(
            supplyItem,
            -batchStockRemaining,
            isFetching ? true : batchStockRemaining <= 0,
          ),
          sku_id: supplyItem.get('sku_id'),
          supply_item_id: supplyItem.get('_id'),
        });
      });
  }

  /**
   * Creates an adjustment button for a specific SKU.
   * @param {SupplyItemModel} supplyItem The SKU ID.
   * @param {number} quantity The SKU quantity.
   * @param {boolean} disabled The SKU quantity.
   * @returns {React.Component} An adjustment button
   */
  getAdjustmentButton(supplyItem: SupplyItemModel, quantity: number, disabled: boolean) {
    return (
      <Button
        onClick={() =>
          this.setState({
            modalSKUID: supplyItem.get('sku_id'),
            selectedSupplyItem: supplyItem,
            showAdjustmentModal: true,
            modalChange: quantity,
          })}
        className="o-text-button o-text-button--contextual"
        dataPublic
        disabled={disabled}
      >
        {translate('write_off')}
      </Button>
    );
  }

  /**
   * Gets the label for a modal.
   * @param {string} SKUID The ID of the SKU.
   * @returns {string} The label for the modal.
   */
  getModalLabel = (SKUID?: string) => {
    if (!SKUID) {
      return translate('write_off');
    }
    const drug = this.props.drugMap.get(SKUID);
    return drug ? drug.get('name') : SKUID;
  }

  /**
 * Gets the dispensation unit for the drug corresponding to SKUID.
 * @param {string} SKUID The ID of the SKU
 * @returns {string} the dispensation unit for the drug.
 */
  getDispensationUnit(SKUID?: string) {
    if (!SKUID) {
      return UNICODE.EMDASH;
    }
    const drug = this.props.drugMap.get(SKUID);
    return drug ? drug.getDispensationUnit() : UNICODE.EMDASH;
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering ExpiryDates');
    const {
      supplyItems,
      inventoryCount,
      adjustInventory,
      filter,
      onFilterUpdated,
      isFetching,
      isSKUCountSyncing,
      inventoryCountSyncStatus,
    } = this.props;
    const dataSeq = this.getRows(filterSupplyItems(supplyItems, filter), inventoryCount,
      isFetching || isSKUCountSyncing || inventoryCountSyncStatus.last() !== 'STOP');
    const data = this.state.showEmptyBatches ? dataSeq.toList() :
      dataSeq.filter((d: Row) => d.batchStockRemaining > 0).toList();
    const rows = data.toArray();
    const fetching = this.props.isFetching || isSKUCountSyncing || isBatchDataNotReady(
      data,
      inventoryCount,
      inventoryCountSyncStatus.last(),
    );
    return (
      <ContentTransition className="o-main__content o-main__content--right-sidebar">
        <div className="c-billing__content">
          <h1 data-public className="o-title">{translate('expiry_dates')}</h1>
          <div className="o-card u-margin-bottom--4ws">
            <h2 data-public className="o-card__title">{translate('expiry_dates')}</h2>
            <StatelessModal
              id="expiryDatesAdjustment"
              noButton
              visible={this.state.showAdjustmentModal}
              setVisible={isVisible => this.setState({ showAdjustmentModal: isVisible })}
              onClose={() =>
                this.setState({
                  selectedSupplyItem: undefined,
                  modalSKUID: undefined,
                })
              }
              title={this.getModalLabel(this.state.modalSKUID)}
              explicitCloseOnly
            >
              {
                this.state.modalSKUID &&
                <InventoryAdjustmentForm
                  SKUID={this.state.modalSKUID}
                  dispensationUnit={this.getDispensationUnit(this.state.modalSKUID)}
                  change={this.state.modalChange}
                  notes="Past expiry date"
                  adjustInventory={(SKUID, change, notes) =>
                    adjustInventory(SKUID, change, notes, this.state.selectedSupplyItem.get('_id'))
                      .then(() => {
                        this.setState({
                          showAdjustmentModal: false,
                          selectedSupplyItem: undefined,
                          modalSKUID: undefined,
                        });
                      })
                  }
                />
              }
            </StatelessModal>
            <Table
              columns={[
                {
                  accessor: 'expiryDate',
                  Header: translate('expiry_date'),
                  Cell: renderDateWithLink,
                  sortMethod: sortByDate,
                },
                { accessor: 'dateCreated', Header: translate('date_created'), Cell: renderDateTime },
                { accessor: 'SKUName', Header: translate('drug_name') },
                { accessor: 'batchStockRemaining', Header: translate('batch_stock_remaining'), sortMethod: sortByNumber },
                { accessor: 'currentClinicStockOfDrug', Header: translate('current_clinic_stock_of_drug'), sortMethod: sortByNumber },
                { accessor: 'dispensationUnit', Header: translate('dispensation_unit') },
                { accessor: 'batchId', Header: translate('batch_id') },
                { accessor: 'notes', Header: translate('notes') },
                {
                  accessor: 'adjust',
                  Header: '',
                  sortable: false,
                  show: true,
                  className: 'sticky-right',
                  headerClassName: 'sticky-right',
                },
              ]}
              data={rows}
              loading={fetching}
              noDataText={
                translate(this.props.currentDataViewsError ? 'error_contact_support' : isLoading(fetching))
              }
              showPagination
              defaultSorted={[{ id: 'expiryDate', desc: true }]}
            />
          </div>
        </div>
        <ExpiryDatesSidebar
          filter={filter}
          onFilterUpdated={onFilterUpdated}
          onEmptyBatchVisibiltyChange={isChecked => this.setState({ showEmptyBatches: isChecked })}
          showEmptyBatches={this.state.showEmptyBatches}
        />
      </ContentTransition>
    );
  }
}

export default ExpiryDates;
