
import BaseModel from './baseModel';

export type SmsJobReply = {
  sender: 'clinic' | 'patient',
  message: string,
  received_at: number,
}

export type SmsJobSendFailedAttempt = {
  error_code?: number,
  error_message?: string,
  attempt_at: number,
}

export type SmsJobStatus = 'queued' | 'in_progress' | 'sent' | 'resolved' | 'unresolved' | 'cancelled' | 'error';

export type Attributes = {
    _id: string,
    type: 'campaign_job',
    created_by: { timestamp: number, user_id: string },
    edited_by: Array<{ timestamp: number, user_id: string }>,
    campaign_id: string,
    encounter_id: string,
    patient_id: string,
    phone_number: string,
    message: string,
    sent_at?: number,
    failed_attempts?: Array<SmsJobSendFailedAttempt>,
    scheduled_for?: number,
    is_resolved?: boolean,
    replies: Array<SmsJobReply>,
    status: SmsJobStatus,
};

/**
 * SMSJobModel
 *
 * @namespace SMSJobModel
 */
class SMSJobModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'campaign_job';
  }

  /**
   * Get the status of the job.
   * If the status is not available from doc, it attempts to calculate it using other fields
   * @returns {string}.
   */
  getStatus() {
    if (this.get('status')) {
      return this.get('status');
    }
    if (this.get('failed_attempts') && this.get('failed_attempts').length) {
      return 'error';
    }
    if (this.get('sent_at')) {
      return this.get('replies') && this.get('replies').length
        ? this.get('is_resolved')
          ? 'resolved'
          : 'unresolved'
        : 'sent';
    }
    return 'queued';
  }

  /**
   * Checks if the job has already been sent
   * @returns {boolean}.
   */
  isSent(): boolean {
    return ['sent', 'resolved', 'unresolved'].includes(this.getStatus());
  }

  /**
   * Checks if the job can be resolved
   * @returns {boolean}.
   */
  isResolvable(): boolean {
    return ['resolved', 'unresolved'].includes(this.getStatus());
  }
}

export default SMSJobModel;
