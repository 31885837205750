import React, { useState, useMemo, useEffect } from 'react';
import glamorous from 'glamorous';
import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import type { List } from 'immutable';

import Select from '../../components/inputs/select';
import Input from '../../components/inputs/input';
import Textarea from '../../components/inputs/textarea';
import DeleteButton from '../../components/buttons/deleteButton';
import UnsavedDataModal from './../prompts/unsavedDataModal';
import InventoryItemCard from './inventoryItemCard';
import DispensationUnitSelect from './dispensationUnitSelect';
import translate from './../../utils/i18n';
import { DISPENSATION_UNITS } from '../../constants';
import { mapStringToOption } from '../../utils/inputs';
import { wsUnit, margins } from '../../utils/css';
import { fetchData } from '../../utils/api';
import { DRUG_MANUFACTURERS } from '../../dataViews';

import DrugModel from '../../models/drugModel';
import type InventoryMapModel from '../../models/inventoryMapModel';
import type { Attributes as DrugSuggestionAttributes } from '../../models/drugSuggestionModel';
import DrugManufacturerModel from '../../models/drugManufacturerModel';

const DRUG_TYPES = [
  { value: 'SYRUP', label: 'Syrup' },
  { value: 'TABLET', label: 'Tablet' },
  { value: 'CAPSULE', label: 'Capsule' },
  { value: 'POWDER', label: 'Powder' },
  { value: 'TOPICAL_MEDICINES', label: 'Topical medicines (Cream/lotion/ointments)' },
  { value: 'SPRAY', label: 'Spray' },
  { value: 'INJECTION', label: 'Injection' },
  { value: 'OIL', label: 'Oil' },
  { value: 'SOLUTION', label: 'Solution' },
  { value: 'INHALER', label: 'Inhaler' },
  { value: 'DROP', label: 'Drop' },
  { value: 'SUPPOSITORY', label: 'Suppository' },
  { value: 'OTHERS', label: 'Others' },
];

const mapDrugManufacturerToOption = memoizeOne((drugManufacturers: List<DrugManufacturerModel>) =>
  drugManufacturers.toArray().map(m => ({ label: m.get('name'), value: m.get('_id') })));

type Props = {
  drug?: DrugModel,
  onDelete?: () => Promise<void>,
  onClose: (visible: boolean) => void,
  inventoryItem?: InventoryMapModel,
  savePromptVisible: boolean,
  setSavePromptVisible: (visible: boolean) => void,
  updateInventoryItem: (inventoryItem: InventoryMapModel | void, suggestionData: Partial<DrugSuggestionAttributes>, event: 'add_drug_details') => Promise<void>,
  setDrugManufacturers: (models: List<DrugManufacturerModel>) => void,
  drugManufacturers: List<DrugManufacturerModel>,
}

const Footer = glamorous.div({
  margin: `calc(${wsUnit}/2) ${margins.standardMarginSides} calc(${wsUnit}/2)`,
});

/**
 * @returns {React.StatelessFunctionalComponent}
 */
const AddDetailForm = ({
  drug = new DrugModel(),
  onDelete,
  inventoryItem,
  onClose,
  savePromptVisible,
  setSavePromptVisible,
  updateInventoryItem,
  setDrugManufacturers,
  drugManufacturers,
}: Props) => {
  const name = drug.get('name');
  const initialState = useMemo(() => ({
    name: drug.get('name'),
    dispensationUnit: drug.get('dispensation_unit', ''),
    manufacturer: drug.get('manufacturer'),
    dosage: drug.get('default_prescribed_dosage', ''),
    dosageUnit: drug.get('dosage_unit', ''),
    activeIngredients: inventoryItem ? inventoryItem.get('active_ingredients', []) : [],
    drugType: '',
  }), []);
  const [details, setDetails] = useState(initialState);
  const [isSaving, setIsSaving] = useState(false);
  const getAttributes = useMemo(
    () => ({
      dosage: drug.get('default_prescribed_dosage', ''),
      dosageUnit: drug.get('dosage_unit', ''),
      frequency: drug.get('frequency', ''),
      duration: drug.get('default_prescribed_duration', ''),
      defaultQuantity: drug.get('default_quantity', ''),
      dispensationUnit: drug.get('dispensation_unit', ''),
      sellingPrice: drug.getPrice() || '',
      manufacturer: drug.get('manufacturer', ''),
      notes: drug.get('notes', ''),
    }),
    [drug],
  );

  /**
   * Set to true if changes made to the form
   */
  const isFormDirty = !isEqual(details, initialState);

  /**
   * @returns {boolean} Returns true if every field has value
   */
  const isFormValid = () => Object.values(details).every(value =>
    (Array.isArray(value) ? value.length : !!value));

  /**
   * Fetch and set manufacturer list in store
   * @returns {void}
   */
  const fetchManufacturerList = async () => {
    try {
      const models = await fetchData(DRUG_MANUFACTURERS);
      setDrugManufacturers(models);
    // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  useEffect(() => {
    if (savePromptVisible && !isFormDirty) { // Close if nochanges are made
      setSavePromptVisible(false);
      onClose(false);
    }
    if (!drugManufacturers.size) {
      fetchManufacturerList();
    }
  }, [savePromptVisible]);

  /**
   * Saves the data and closes modal
   * @returns {void}
   */
  const saveDrugDetails = () => {
    if (isFormValid()) {
      const suggestionData = {
        name: details.name,
        drug_type: details.drugType && details.drugType.toUpperCase(),
        packaging_unit: details.dosageUnit && details.dosageUnit.toString(),
        dosage: details.dosage && details.dosage.toString(),
        dispensation_unit: details.dispensationUnit && details.dispensationUnit.toString(),
        manufacturer_name: details.manufacturer && details.manufacturer.toString(),
        active_ingredients: details.activeIngredients.map(i => i.trim()),
      };
      setIsSaving(true);
      updateInventoryItem(inventoryItem, suggestionData, 'add_drug_details').then(() => {
        setIsSaving(false);
        onClose(false);
      });
    } else {
      setSavePromptVisible(false);
    }
  };

  return (
    <>
      <UnsavedDataModal
        visible={savePromptVisible && isFormDirty}
        onSave={saveDrugDetails}
        onDiscard={() => onClose(false)}
        onCancel={() => setSavePromptVisible(false)}
      />
      {inventoryItem && <InventoryItemCard
        title={`${translate('inventory_item_to_map')} : ${name}`}
        data={getAttributes}
      />}
      <form className="0-form">
        <div className="u-margin--standard">
          {inventoryItem && <hr />}
          <Input
            id="add-details-name"
            label={translate('drug_name')}
            placeholder="Example: Panadol, Cough-En Linctus & Fobancort cream"
            value={details.name}
            onValueChanged={value => setDetails({ ...details, name: value })}
            required
          />
          <div className="u-flex-row">
            <Select
              id="drug-types-select"
              className="u-full-width u-margin-right--1ws"
              label={translate('drug_type')}
              clearable
              options={DRUG_TYPES}
              value={details.drugType}
              onValueChanged={drugType => setDetails({ ...details, drugType })}
              required
            />
            <Select
              id="manufacturer-select"
              className="u-full-width"
              label={translate('manufacturer')}
              clearable
              options={mapDrugManufacturerToOption(drugManufacturers)}
              value={details.manufacturer}
              onValueChanged={manufacturer => setDetails({ ...details, manufacturer })}
              required
              placeholder={translate('manufacturer')}
            />
          </div>
          <div className="u-flex-row">
            <Input
              id="add-details-unit"
              divClassName="u-full-width u-margin-right--1ws"
              label={translate('unit')}
              placeholder="Example: mg, ml"
              value={details.dosageUnit}
              onValueChanged={dosageUnit => setDetails({ ...details, dosageUnit })}
              required
            />
            <Input
              id="add-details-dosage"
              divClassName="u-full-width"
              label={translate('dosage')}
              placeholder="Example: 15ml, 1000mg"
              value={details.dosage}
              onValueChanged={dosage => setDetails({ ...details, dosage })}
              required
            />
          </div>
          <DispensationUnitSelect
            selectOptions={DRUG_TYPES}
            radioOptions={mapStringToOption(DISPENSATION_UNITS)}
            value={details.dispensationUnit}
            onChange={dispensationUnit => setDetails({ ...details, dispensationUnit })}
          />
          <Textarea
            id="add-details-ingredients"
            label={translate('active_ingredients')}
            placeholder="Example: Paracetamol, (Dextromerthorphan HBr, Tripolidine HCl & Pseudoephedrine HCl) & (Sodium Fusidate & Betamethasone Diproprionate)"
            value={details.activeIngredients.join(',')}
            onValueChanged={activeIngredients => setDetails({ ...details, activeIngredients: activeIngredients.split(',') })}
            required
          />
        </div>
        {onDelete && <DeleteButton className="o-delete-button" onDelete={onDelete} />}
        <Footer className=" u-text-align-right">
          <button
            type="button"
            className="o-button o-button--padded o-button--small"
            disabled={isSaving}
            onClick={saveDrugDetails}
          >
            {translate('save')}
          </button>
        </Footer>
      </form>
    </>
  );
};

export default AddDetailForm;
