import React, { Fragment } from 'react';
import { Map } from 'immutable';
import type { List } from 'immutable';

import translate from './../../utils/i18n';
import Input from './../inputs/input';
import MultiSelect from './../inputs/multiSelect';
import Checkbox from './../inputs/checkbox';
import FileInput from './../inputs/fileInput';
import SaveButton from './../buttons/saveButton';
import ModalFooter from './../modals/modalFooter';
import FormError from './../formError';
import DocumentTemplateModel from './../../models/documentTemplateModel';
import { saveBlob, removeFileNameExtension, validateAndTrimString } from './../../utils/utils';
import { logToAppInsight, debugPrint } from './../../utils/logging';
import { createSuccessNotification, createStaticNotification } from './../../utils/notifications';
import { updateClinicConfig } from './../../utils/db';

import type { SaveModel, Config, SelectOpts, MapValue } from './../../types';

type Props = {
  config: Config,
  updateConfig: (config: MapValue) => void,
  modelToEdit?: DocumentTemplateModel,
  documentTemplates: List<DocumentTemplateModel>,
  disabled: boolean,
  onCancel: () => void,
  onSave: () => void,
  saveModel: SaveModel,
};

type State = {
  attributes: {
    title: string,
    locations: Array<string>,
  },
  isDefaultTemplateFor: SelectOpts,
  isSaving: boolean,
  selectedFile: File | void, // Only used when props.modelToEdit is not given.
  errorMessage: string | void,
};

const DEFAULT_TEMPLATE_OPTIONS = [
  { label: translate('patient_receipt'), value: 'patient_receipt' },
  { label: translate('medical_certificate'), value: 'medical_certificate' },
  { label: translate('time_chit'), value: 'time_chit' },
  { label: translate('prescription_labels'), value: 'prescription_labels' },
];

/**
 * Gets the default state of the component.
 * @param {DocumentTemplateModel?} model A doc template model
 * @param {Config} config The app config
 * @returns {State}
 */
function getDefaultState(model?: DocumentTemplateModel, config: Config) {
  if (model) {
    const modelId = model.get('_id');
    return Object.assign({}, {
      isSaving: false,
      attributes: {
        title: model.get('title', ''),
        locations: model.get('locations', []),
      },
      selectedFile: undefined,
      errorMessage: undefined,
      isDefaultTemplateFor: DEFAULT_TEMPLATE_OPTIONS
        .filter(o => config.getIn(['document_templates', 'print_templates', o.value]) === modelId),
    });
  }
  return Object.assign({}, {
    isSaving: false,
    attributes: { title: '', locations: ['encounter'] },
    selectedFile: undefined,
    errorMessage: undefined,
    isDefaultTemplateFor: [],
  });
}


/**
 * A form for creating and editing document templates.
 * @class DocumentTemplateForm
 * @extends {React.Component<Props, State>}
 */
class DocumentTemplateForm extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  };

  /**
   * Creates an instance of DocumentTemplateForm.
   * @param {Props} props Props
   * @returns {void}
   */
  constructor(props: Props) {
    super(props);
    this.state = getDefaultState(props.modelToEdit, props.config);
  }

  /**
   * Checks if file uploaded is valid .docx/.doc file.
   * @returns {boolean}
   */
  isValidFile(): boolean {
    if (this.state.selectedFile && this.state.selectedFile.name) {
      const nameArray = this.state.selectedFile.name.split('.');
      if (nameArray) {
        if (nameArray.length === 1 ||
          (nameArray[0] === '' && nameArray.length === 2)) {
          return false;
        }
        const extension = nameArray.pop();
        if (!extension || !['doc', 'docx'].includes(extension.toLowerCase())) {
          return false;
        }
        return true;
      }
    }
    return false;
  }

  /**
   * Checks if state is valid for saving.
   * @returns {boolean}
   */
  isValid(): boolean {
    if (!this.props.modelToEdit && !this.state.selectedFile) {
      this.setState({ errorMessage: translate('no_file_has_been_selected') });
      return false;
    } else if (!validateAndTrimString(this.state.attributes.title).length) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    } else if (!this.props.modelToEdit && !this.isValidFile()) {
      this.setState({ errorMessage: translate('file_uploaded_is_invalid') });
      return false;
    }
    return true;
  }

  /**
   * Handles an error during the save process.
   * @param {Error} error The given error
   * @returns {void}
   */
  onSaveFail(error: Error) {
    debugPrint(error, 'error');
    logToAppInsight('Document template save failed', error ? error.status : null);
    this.setState({ isSaving: false });
    createStaticNotification(
      translate('document_template_save_failed'),
      translate('document_template_save_failed_desc'), true,
    );
  }

  /**
   * Updates the default doc template settings in Config.
   * @param {DocumentTemplateModel} model The documentTemplateModel being saved.
   * @returns {Promise<DocumentTemplateModel>}
   */
  saveDefaultTemplateSettings(model: DocumentTemplateModel): Promise<DocumentTemplateModel> {
    const printTemplatesConfig = this.props.config.getIn(['document_templates', 'print_templates'], {}).toJS();
    const currentPrintTemplates = Object.keys(printTemplatesConfig)
      .reduce((templates, key: string) => {
        const currentValue = this.props.config.getIn(['document_templates', 'print_templates', key]);
        return {
          ...templates,
          [key]: currentValue === model.get('_id') && !this.state.isDefaultTemplateFor.find(o => o.value === key) ? // i.e. Was default template before but now isnt
            '' :
            currentValue,
        };
      }, {});

    const updatedPrintTemplates = this.state.isDefaultTemplateFor.reduce(
      (templates, option) => ({ ...templates, [option.value]: model.get('_id') }),
      {},
    );

    const printTemplates = {
      ...currentPrintTemplates,
      ...updatedPrintTemplates,
    };
    const updatedConfig = Map()
      .setIn(['document_templates', 'print_templates'], printTemplates)
      .toJS();
    return updateClinicConfig(this.props.config.toJS(), updatedConfig, this.props.updateConfig)
      .then(() => model);
  }

  /**
   * Handle save clicks.
   * @returns {void}
   */
  onSaveClicked = () => {
    if (this.isValid()) {
      this.setState({ isSaving: true });
      const { selectedFile } = this.state;
      if (this.props.modelToEdit) {
        this.props.saveModel(this.props.modelToEdit.set({
          title: validateAndTrimString(this.state.attributes.title),
          locations: this.state.attributes.locations,
        }))
          .then(model => this.saveDefaultTemplateSettings(model))
          .then(() => {
            createSuccessNotification(translate('document_template_edited'));
            this.props.onSave();
          })
          .catch(e => this.onSaveFail(e));
      } else {
        if (!selectedFile) {
          throw new Error('File is invalid.');
        }
        saveBlob(selectedFile)
          .then((assetObject) => {
            const model = new DocumentTemplateModel({
              asset_id: assetObject.id,
              file_name: selectedFile.name,
              title: validateAndTrimString(this.state.attributes.title),
              locations: this.state.attributes.locations,
              format: 'docx',
              context: 'encounter',
            });
            model.setHighestOrder(this.props.documentTemplates);
            return this.props.saveModel(model);
          })
          .then(model => this.saveDefaultTemplateSettings(model))
          .then(() => {
            createSuccessNotification(translate('document_template_created'));
            this.props.onSave();
          })
          .catch(e => this.onSaveFail(e));
      }
    }
  }

  /**
   * Handle cancel clicks.
   * @returns {void}
   */
  onCancelClicked = () => {
    this.setState(getDefaultState(this.props.modelToEdit, this.props.config));
    this.props.onCancel();
  }

  /**
   * Handle location changes.
   * @param {string} toggledValue The toggle value
   * @param {boolean} isChecked The checked status
   * @returns {void}
   */
  onLocationChanged = (toggledValue: string, isChecked: boolean) => {
    const locations = isChecked ?
      this.state.attributes.locations.concat([toggledValue]) :
      this.state.attributes.locations.filter(l => l !== toggledValue);
    this.setState({ attributes: Object.assign({}, this.state.attributes, { locations }) });
  }

  /**
   * Called when closing the component
   * @returns {void}
   */
  componentWillUnmount() {
    this.onCancelClicked();
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const idPrefix = this.props.modelToEdit ?
      `doc-template_${this.props.modelToEdit.get('_id')}` : 'doc-template';
    return (
      <Fragment>
        <div className="o-form">
          {
            this.state.errorMessage &&
            <FormError isSticky>
              {this.state.errorMessage}
            </FormError>
          }
          {
            !this.props.modelToEdit &&
            <FileInput
              value={this.state.selectedFile}
              onValueChanged={(selectedFile) => {
                const newState = {
                  selectedFile,
                  attributes: selectedFile && this.state.attributes.title.length ?
                    this.state.attributes :
                    Object.assign({}, this.state.attributes, { title: selectedFile ? removeFileNameExtension(selectedFile.name) : '' }),
                };
                this.setState(newState);
              }}
              accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          }
          <Input
            id={`${idPrefix}_title`}
            label={translate('name_of_document_template')}
            value={this.state.attributes.title}
            onValueChanged={
              (title: string) => this.setState({
                attributes: Object.assign({}, this.state.attributes, { title }),
              })
            }
            required
            disabled={this.props.disabled}
          />
          <MultiSelect
            id={`${idPrefix}_default_templates`}
            label={translate('set_as_default_template_for')}
            value={this.state.isDefaultTemplateFor}
            onChange={isDefaultTemplateFor => this.setState({ isDefaultTemplateFor })}
            options={DEFAULT_TEMPLATE_OPTIONS}
            // required
            disabled={this.props.disabled}
          />
          <Checkbox
            id="document-location"
            required
            label={translate('document_location')}
            value={this.state.attributes.locations}
            options={[
              { value: 'encounter', label: translate('patient_page') },
              { value: 'bill', label: translate('billing_page') },
            ]}
            multiColumn
            onValueChanged={this.onLocationChanged}
          />
        </div>
        <ModalFooter>
          {
            !this.props.disabled &&
            <SaveButton
              dataPublic
              className="o-button--small u-margin-right--half-ws"
              isSaving={this.state.isSaving}
              label={translate('save')}
              onClick={this.onSaveClicked}
            />
          }
        </ModalFooter>
      </Fragment>
    );
  }
}

export default DocumentTemplateForm;
