import React from 'react';
import moment, { Moment } from 'moment';
import { List, Map, OrderedMap, Set } from 'immutable';

import StatelessModal from './../modals/statelessModal';
import ContentTransition from './../contentTransition';
import translate from './../../utils/i18n';
import AddToInventoryFormItem from './addToInventoryFormItem';
import SaveButton from './../buttons/saveButton';
import SupplyModel from './../../models/supplyModel';
import SupplyItemModel from './../../models/supplyItemModel';
import TransactionModel from './../../models/transactionModel';
import { createSuccessNotification } from './../../utils/notifications';
import SavePrompt from './../prompts/savePrompt';
import Confirm from './../prompts/confirm';
import FormError from './../formError';
import { DATE_FORMAT_TO_SAVE } from './../../constants';
import ModalFooter from './../modals/modalFooter';
import { getDateFormat } from './../../utils/time';
import AddToInventorySupplyFormItem from './addToInventorySupplyFormItem';
import { fetchFilteredTransaction } from './../../utils/api';

import type DrugModel from './../../models/drugModel';
import type SupplierModel from './../../models/supplierModel';
import type { MapValue, CountPerSKUAndBatch, Config, SupplyItemAttributes, SaveModels } from './../../types';
import UnsavedDataModal from '../prompts/unsavedDataModal';
import { debugPrint } from '../../utils/logging';

/* eslint-disable camelcase */
type Attributes = {
  supplier_id: string,
  date: Moment,
  invoice_number?: string,
  delivery_order_number?: string,
  purchase_order_number?: string,
  doc_number?: string,
  notes?: string,
}

type SupplyItemAttr = SupplyItemAttributes & {
  key: string,
};
/* eslint-enable camelcase */

enum UpdateCheck {
  expiryDateEarlierThanDispensations,
  supplyDateLaterThanItsTransactions,
  expiryDateEarlierThanItsTransactions,
}

type State = {
  supplyAttributes: Attributes,
  isSaving: boolean,
  errorMessage?: string,
  changesMade: boolean,
  supplyItem: SupplyItemModel,
  showUnsavedDataPrompt: boolean,
  dynamicErrorMessages: Map<string, string>,
  stateTransactions: List<TransactionModel>,
}

type Props = {
  drugs: List<DrugModel>,
  supplyItem: SupplyItemModel,
  supplyItems: List<SupplyItemModel>,
  suppliers: List<SupplierModel>,
  isFetching: boolean,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  isOutgoing?: boolean,
  skuID: string,
  action: string,
  isVisible: boolean,
  onClose: (isFromClose: boolean) => void,
  isBulk: boolean,
  config: Config,
  supplies: List<SupplyModel>,
  saveModels: SaveModels,
  supplySupplyItemMap: Map<string, List<SupplyItemModel>>,
}

/**
 * A component containing a form for adding a Supply.
 * @class UpdateBatchDetailsForm
 * @extends {React.Component}
 */
class UpdateBatchDetailsForm extends React.Component<Props, State> {
  /**
   * Creates an instance of AddToInventoryForm.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      supplyAttributes: this.getInitialSupplyAttributes(),
      isSaving: false,
      changesMade: false,
      supplyItem: props.supplyItem,
      showUnsavedDataPrompt: false,
      dynamicErrorMessages: OrderedMap({
        expiryDateEarlierThanDispensations: '',
        supplyDateLaterThanItsTransactions: '',
        expiryDateEarlierThanItsTransactions: '',
      }),
      stateTransactions: List(),
    };
  }

  /**
   * return intial attributes
   * @returns {Attributes}
   */
  getInitialSupplyAttributes() {
    const { supplyItem, supplies } = this.props;
    const supply = supplies.find(s => s.get('_id') === supplyItem.get('supply_id'));
    return {
      supplier_id: supply?.get('supplier_id') || '',
      date: moment(supply?.get('date', moment()) || moment().valueOf()),
      notes: supply?.get('notes') || '',
      invoice_number: supply?.get('invoice_number') || '',
      delivery_order_number: supply?.get('invoice_number') || '',
      purchase_order_number: supply?.get('purchase_order_number') || '',
      doc_number: supply?.get('doc_number') || '',
    }
  };

  /**
   * Updates state.attribute with the given k:v pair.
   * @param {string} key The key to update.
   * @param {any} value The value to update.
   * @returns {undefined}
   */
  updateAttribute(key: string, value: MapValue) {
    this.setState((prevState) => {
      const supplyAttributes = Object.assign(prevState.supplyAttributes, { [key]: value });
      const changesMade = true;
      if (key === 'date') {
        return {
          supplyAttributes,
          changesMade,
          dynamicErrorMessages: prevState.dynamicErrorMessages.set(UpdateCheck[1], ''),
        };
      }
      return {
        supplyAttributes,
        changesMade,
      };
    });
  }

  /**
   * Handle the updating of a supply item.
   * @param {Attributes} changes The changes made to the supply item.
   * @returns {void}
   */
  updateSupplyItem(changes: MapValue) {
    this.setState((prevState) => {
      const item = Object.assign({}, prevState.supplyItem.attributes, changes);
      const expiryDate = changes.expiry_date && moment(changes.expiry_date).isValid() ?
        moment(changes.expiry_date).format(DATE_FORMAT_TO_SAVE) : item.expiry_date;
      const unitCost = (item.total_price && item.quantity)
        ? Number(item.total_price) / Number(item.quantity)
        : undefined;
      const stateItem = new SupplyItemModel(Object.assign({}, item, { unit_cost: unitCost },
        { expiry_date: expiryDate }));
      if (changes.expiry_date) {
        return {
          supplyItem: stateItem,
          changesMade: true,
          dynamicErrorMessages: prevState.dynamicErrorMessages.set(UpdateCheck[0], ''),
        }
      }
      return {
        supplyItem: stateItem,
        changesMade: true,
      };
    });
  }

  /**
   * Return supply items match both supplier and sku,
   * as per the design idea is to show all the supply items matching, supplier and sku but
   * make only selected supply item to editable.
   * @returns {List<SupplyItemModel>} The rendered component.
   */
  getSupplyItemsMatchSupply() {
    const { supplyItem } = this.state;
    const { supplySupplyItemMap } = this.props;
    const supplySupplyItems = supplySupplyItemMap.get(supplyItem.get('supply_id'), List());
    return supplySupplyItems.filter(s => s.get('_id') !== supplyItem.get('_id'));
  }

  /**
   * Get the earliest time of transaction models
   * @param {List<TransactionModel>} models The rendered component.
   * @returns {number} time
   */
  getEarliestTransactionTime(models: List<TransactionModel>) {
    return models.reduce((time, m) => {
      if (m.getTimestamp() >= time) {
        return m.getTimestamp();
      }
      return time;
    }, 0);
  }

  /**
   * Check validity before saving
   * @returns {Promise<boolean>} true if valid else false
   */
  isValid() {
    const { supplyItem, supplyAttributes } = this.state;
    const expiryDate = supplyItem.get('expiry_date');
    if (!expiryDate || !(supplyAttributes.date) || !(supplyAttributes.supplier_id)) {
      this.setState({
        errorMessage: translate('fill_required_fields'),
      });
      return Promise.resolve(false);
    }
    if (!moment(supplyAttributes.date).isValid()
    ) {
      this.setState({ errorMessage: translate('date_in_correct_format') });
      return Promise.resolve(false);
    }
    if (!(moment(expiryDate).isValid())) {
      this.setState({
        errorMessage: translate('expiry_date_in_correct_format'),
      })
      return Promise.resolve(false);
    }
    const filterSupplyItems: List<SupplyItemModel> = this.props.supplySupplyItemMap.get(supplyItem.get('supply_id'), List());
    const skuIDs = Set(filterSupplyItems.map(s => s.get('sku_id')));
    if (!(skuIDs && skuIDs.size > 0)) {
      return Promise.resolve(true);
    }
    return fetchFilteredTransaction(0, 253402280999999, skuIDs.toArray()) //Todo: max end date 31 DEC 9999
      .then(({ transactions } : { transactions: List<TransactionModel>}) => {
        const nonSupplyItemTypeTransaction = transactions.filter(t => t.get('source_type') !== 'supply_item');
        if (nonSupplyItemTypeTransaction.size > 0) {
          const earliestTime = this.getEarliestTransactionTime(nonSupplyItemTypeTransaction);
          const isSupplyTimeLater = supplyAttributes.date.startOf('day').valueOf() > moment(earliestTime).startOf('day').valueOf();
          if (isSupplyTimeLater) {
            this.setState((prevState) => {
              const errorMessages = prevState.dynamicErrorMessages.update(UpdateCheck[1], () =>
                `The earliest transaction for this supply is ${moment(earliestTime).format(getDateFormat())} but you
                have set the date this Supply was received as ${supplyAttributes.date.format(getDateFormat())}.`);
              return {
                dynamicErrorMessages: errorMessages,
              };
            });
          }
          const dispenseTransactions = nonSupplyItemTypeTransaction.filter((m: TransactionModel) => m.get('supply_item_id') === supplyItem.get('_id') && m.getType() === 'dispensation');
          if (dispenseTransactions.size > 0) {
            const laterTime = this.getEarliestTransactionTime(dispenseTransactions);
            const isExpiryDateEarliestOfDispensations = moment(expiryDate).startOf('day').valueOf() < moment(laterTime).startOf('day').valueOf();
            if (isExpiryDateEarliestOfDispensations) {
              this.setState((prevState) => {
                const errorMessages = prevState.dynamicErrorMessages.update(UpdateCheck[0], () =>
                  `The earliest dispensation for the batch is ${moment(laterTime).format(getDateFormat())}
                  but you have set the expiry date of the batch as ${moment(expiryDate).format(getDateFormat())}.`);
                return {
                  dynamicErrorMessages: errorMessages,
                };
              });
            }
            return {
              isValid:
                !(isExpiryDateEarliestOfDispensations || isSupplyTimeLater),
              transactions,
            };
          }
          return {
            isValid:
              !(isSupplyTimeLater),
            transactions,
          };
        }
        return {
          isValid: true,
          transactions: List(),
        };
      })
      .then(({ isValid, transactions }) => {
        const supplyItemTransactions = transactions.filter((m: TransactionModel) =>
          m.get('supply_item_id') === supplyItem.get('_id') && m.get('source_type') !== 'supply_item');
        if (isValid && supplyItemTransactions.size > 0) {
          const laterTime = this.getEarliestTransactionTime(supplyItemTransactions);
          const isExpiryDateEarliestOfTransactions = moment(expiryDate).startOf('day').valueOf() < moment(laterTime).startOf('day').valueOf();
          if (isExpiryDateEarliestOfTransactions) {
            this.setState((prevState) => {
              const errorMessages = prevState.dynamicErrorMessages.update(UpdateCheck[2], () =>
                `You have set batch ${supplyItem.get('batch_id') || supplyItem.get('_id')}'s expiry date as ${moment(expiryDate).format(getDateFormat())}.\n
                There are transactions for ${supplyItem.get('batch_id') || supplyItem.get('_id')} that are dated after ${moment(expiryDate).format(getDateFormat())}.\n
                This means that those transactions will have taken place after the batch's expiry date.\n
                Are you sure you want to proceed?`);
              return {
                dynamicErrorMessages: errorMessages.set(UpdateCheck[0], '').set(UpdateCheck[1], ''),
                errorMessage: undefined,
              };
            });
          }
        }
        if (isValid) {
          this.setState({
            stateTransactions: transactions,
          });
        }
        return isValid;
      });
  }

  getDynamicErrorMessages = () => {
    return this.state.dynamicErrorMessages.reduce((items, message, key) => {
      if (message) {
        if (key === UpdateCheck[2]) {
          return items;
        }
        return items.concat(
          <FormError containerElementID={`${key}`}>
            {message}
          </FormError>,
        );
      }
      return items;
    }, List()).toArray();
  };

  /**
   * Get the supply item from props supply item supply
   * @returns {Promise<boolean>} The rendered component.
   */
  onProceedClick() {
    const { supplies } = this.props;
    const { supplyItem, supplyAttributes } = this.state;
    const supply = supplies.find(s => s.get('_id') === supplyItem.get('supply_id'));
    const supplyItemsToUpdate = this.getSupplyItemsMatchSupply();
    const supplyItemIds = supplyItemsToUpdate.map(s => s.get('_id')).push(supplyItem.get('_id'));
    const updatedSupplyItems = supplyItemsToUpdate.map((s: SupplyItemModel) =>
      new SupplyItemModel(Object.assign({}, s.attributes,
        { date: supplyAttributes.date.format(DATE_FORMAT_TO_SAVE) })));
    const updatedTransactions = this.state.stateTransactions.reduce((transactionList, t) => {
      if (supplyItemIds.includes(t.get('supply_item_id')) && t.get('source_type') === 'supply_item') {
        return transactionList.push(new TransactionModel(Object.assign({}, t.attributes,
          { timestamp: supplyAttributes.date.valueOf() })));
      }
      return transactionList;
    }, List());
    const updatedSupply = new SupplyModel(Object.assign({}, supply?.attributes,
      supplyAttributes,
      { date: supplyAttributes.date.format(DATE_FORMAT_TO_SAVE) }));
    return this.props.saveModels(
      [updatedSupply,
        supplyItem.set('date', supplyAttributes.date.format(DATE_FORMAT_TO_SAVE)),
        ...updatedSupplyItems.toArray(),
        ...updatedTransactions.toArray(),
      ])
      .then(() => {
        createSuccessNotification('Batch updated sucessfully');
        this.props.onClose(false);
        return true;
      });
  }

  /**
   * Get the supply item from props supply item supply
   * @returns {Promise<boolean>} The rendered component.
   */
  onSaveClicked() {
    this.setState({
      isSaving: true,
    });
    return this.isValid()
      .then((valid) => {
        this.setState({
          isSaving: false,
          showUnsavedDataPrompt: false,
        });
        if (valid && !this.state.dynamicErrorMessages.get(UpdateCheck[2])) {
          return this.onProceedClick();
        }
        return false;
      })
      .catch((err) => {
        debugPrint(err, 'error')
        this.setState({
          isSaving: false,
          errorMessage: translate('error_saving'),
          stateTransactions: List(),
        });
      });
  }

  /**
   * Renders the AddToInventoryForm
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { action, isVisible, onClose, suppliers } = this.props;
    return (
      <React.Fragment>
        <StatelessModal
          id="update-batch-details-form"
          buttonLabel=""
          buttonClass="o-button o-button--padded o-button--small"
          title={translate('update_batch_details')}
          setVisible={() => {}}
          visible={isVisible}
          onClose={() => {
            if (!this.state.changesMade) {
              onClose(true);
            } else {
              this.setState({ showUnsavedDataPrompt: true });
            }
          }}
          explicitCloseOnly
          large
          dataPublicHeader
        >
          <ContentTransition className="o-scrollable-container" id="supply-form">
            <div className="o-form u-margin-bottom--4ws">
              <SavePrompt
                when={this.state.changesMade}
                onSaveClicked={() => this.onSaveClicked()}
              />
              <UnsavedDataModal
                visible={this.state.showUnsavedDataPrompt}
                isSaving={this.state.isSaving}
                onSave={() => this.onSaveClicked()}
                onDiscard={() => this.props.onClose(true)}
                onCancel={() => this.setState({ showUnsavedDataPrompt: false })}
              />
              <Confirm
                show={Boolean(this.state.dynamicErrorMessages.get(UpdateCheck[2]))}
                proceed={() => this.onProceedClick()}
                cancel={() => this.setState(prevState => ({
                  dynamicErrorMessages: prevState.dynamicErrorMessages.set(UpdateCheck[2], ''),
                }))}
                modalTitle={translate('warning')}
                footerSaveButtonName={translate('proceed')}
                confirmation={this.state.dynamicErrorMessages.get(UpdateCheck[2])}
              />
              {
                this.state.errorMessage &&
                <FormError containerElementID="fill-required-field">
                  {this.state.errorMessage}
                </FormError>
              }
              {this.getDynamicErrorMessages()}
              <AddToInventorySupplyFormItem
                suppliers={suppliers}
                action={action}
                onUpdateAttribute={(key: string, value: MapValue) =>
                  this.updateAttribute(key, value)}
                attributes={this.state.supplyAttributes}
                isSupplyInputNotAccepted={this.state.dynamicErrorMessages.get(UpdateCheck[1]) === '' ? undefined : true}
              />
              <AddToInventoryFormItem
                key={this.state.supplyItem.get('_id')}
                supplyItem={Object.assign({}, this.state.supplyItem.attributes)}
                onChange={changes => this.updateSupplyItem(changes)}
                onDeleteClicked={() => {}}
                showDeleteButton={false}
                isFirstChild
                drugs={this.props.drugs}
                skuID={this.state.supplyItem.get('sku_id')}
                isBatchUpdate
                isSupplyItemInputNotAccepted={this.state.dynamicErrorMessages.get(UpdateCheck[0]) === '' ? undefined : true}
              />
              {
                this.getSupplyItemsMatchSupply().map(supplyItem => <AddToInventoryFormItem
                  key={supplyItem.get('_id')}
                  supplyItem={Object.assign({}, supplyItem.attributes)}
                  onChange={() => {}}
                  onDeleteClicked={() => {}}
                  showDeleteButton={false}
                  isFirstChild={false}
                  drugs={this.props.drugs}
                  skuID={supplyItem.get('sku_id')}
                  isBatchUpdate
                />)
              }
            </div>
          </ContentTransition>
          <ModalFooter>
            <SaveButton
              onClick={() => this.onSaveClicked()}
              isSaving={this.state.isSaving}
              label={translate('save')}
              className="o-button--small u-margin-right--half-ws"
            />
          </ModalFooter>
        </StatelessModal>
      </React.Fragment>
    );
  }
}

export default UpdateBatchDetailsForm;
