import React from 'react';
import { Moment } from 'moment';
import { DateRangePicker as DRP, OrientationShape } from 'react-dates';

import Label from './label';
import { getDateFormat } from './../../utils/time';
import type { HTMLStyle } from './../../types';

export type Values = { startDate: Moment | null | undefined, endDate: Moment | null | undefined };

type Props = {
  id: string,
  label?: string,
  labelClassName?: string,
  onValueChanged: (values: Values) => void,
  className?: string,
  endDate?: Moment | null | undefined,
  startDate?: Moment | null | undefined,
  maxDate?: Moment,
  isClearable?: boolean,
  disabled?: boolean,
  block?: boolean,
  style: HTMLStyle,
  appendToBody?: boolean,
  small?: boolean,
  orientation?: OrientationShape,
};

type State = {
  focusedInput?: boolean,
  startDate: Moment | null | undefined,
  endDate: Moment | null | undefined,
}

/**
   * DateRangePicker
   * @namespace DateRangePicker
   * @returns {React.Component} - DateRangePicker
   */
class DateRangePicker extends React.Component<Props, State> {
  static defaultProps = {
    className: '',
    style: {},
  };

  /**
   * Creates an instance of DateRangePicker. Creates state with an isFocused parameter.
   * @param {any} props Props for the component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      focusedInput: undefined,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  /**
   * Reset State to passed value.
   * @param {State} resetValue which needs to set
   * @returns {void}
   */
  resetState(resetValue: State) {
    this.setState(resetValue);
  }

  /**
   * Called functions after component fully rendered
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.resetState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  }

  /**
   * @param {Moment} date A date to check.
   * @returns {boolean} True if date is outside range, false otherwise
   */
  isOutsideRange(date: Moment) {
    if (!this.props.maxDate) {
      return false;
    }
    return date.isAfter(this.props.maxDate, 'day');
  }

  /**
   * Handles the date change.
   * @param {{}} values The values
   * @returns {void}
   */
  handleDateChange = (values: Values) => {
    const isStartDateNull = !values.startDate && values.endDate;
    const isEndDateNull = values.startDate && !values.endDate;
    if (this.props.isClearable) {
      this.setState({
        startDate: values.startDate?.hours(0).minutes(0).seconds(0),
        endDate: values.endDate?.hours(0).minutes(0).seconds(0),
      });
      if (!this.state.focusedInput && !values.startDate && !values.endDate) {
        this.props.onValueChanged({
          startDate: null,
          endDate: null,
        });
      }
    } else if (isStartDateNull || isEndDateNull) {
      this.setState({
        ...isStartDateNull && {
          startDate: values.endDate.hours(0).minutes(0).seconds(0),
          endDate: values.endDate.hours(0).minutes(0).seconds(0),
        },
        ...isEndDateNull && {
          startDate: values.startDate.hours(0).minutes(0).seconds(0),
          endDate: values.startDate.hours(0).minutes(0).seconds(0),
        },
      });
    } else {
      this.setState({
        startDate: values.startDate.hours(0).minutes(0).seconds(0),
        endDate: values.endDate.hours(0).minutes(0).seconds(0),
      });
    }
  }

  /**
  * Called just after the Date Range modal close
  * @param {{}} values The values
  * @returns {void}
  */
  onClose = (values: Values) => {
    this.props.onValueChanged({
      startDate: values.startDate && values.startDate.hours(0).minutes(0).seconds(0),
      endDate: values.endDate && values.endDate.hours(0).minutes(0).seconds(0),
    });
  }

  /**
   * Renders a react component containing a label and a datepicker.
   * @returns {React.Component} The react component.
   */
  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.label && (
          <Label
            className={this.props.labelClassName}
            id={this.props.id}
            label={this.props.label}
          />
        )}
        <DRP
          displayFormat={getDateFormat()}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          focusedInput={this.state.focusedInput}
          onDatesChange={this.handleDateChange}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          showClearDates={this.props.isClearable}
          isOutsideRange={date => this.isOutsideRange(date)}
          minimumNights={0}
          onClose={this.onClose}
          small={this.props.small}
          disabled={this.props.disabled}
          block={!!this.props.block}
          appendToBody={this.props.appendToBody}
          orientation={this.props.orientation || 'horizontal'}
        />
      </div>
    );
  }
}

export default DateRangePicker;
