import { connect } from 'react-redux';
import { Set, List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import { UNICODE } from '../constants';

import translate from './../utils/i18n';
import Drugs from './../components/drugs/drugs';
import { renderMultilineContent } from './../utils/tables';
import { sortByNumber, sortByPayment } from './../utils/comparators';
import { getStore, saveFactory } from './../utils/redux';
import { updateConfigValue, updateConfig, setDrugManufacturerModels } from './../actions';
import { mapDrug, fetchMasterData, getMDLInfo } from '../utils/inventory';

import type { Dispatch, State, Config } from './../types';
import type DrugManufacturerModel from '../models/drugManufacturerModel';
import type DrugModel from '../models/drugModel';

/**
 * @param {List<DrugModels>} drugs list of drug for mdl info is required
 * @returns {Object}
 */
const getMdlInfoForAllDrugs = memoizeOne((
  drugs: List<DrugModel>,
  // These arguments are used internally by `getMDLInfo`. Passing this here to
  // avoid returning memoized result if one of the argument is changed.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  verifiedDrugs, drugSuggestions, masterDrugModelsMap,
) =>
  drugs.reduce((reducedMdlInfo, drug) => {
    const drugId: string = drug.get('_id');
    const mdlInfo = getMDLInfo(getStore(), drugId);
    return mdlInfo ? reducedMdlInfo.set(drugId, mdlInfo) : reducedMdlInfo;
  }, Map<string, any>()));

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({
  drugs, config, coveragePayors, user, masterDrugModelsMap, drugManufacturers,
  verifiedDrugs, drugSuggestions, dosingRegimens,
}: State) => ({
  columns: [
    {
      accessor: 'name', Header: translate('name'), minWidth: 100, filterable: true, width: 200,
    },
    {
      accessor: 'mapped_drug', Header: translate('mapped_drug'), useSecondaryData: true, minWidth: 100, filterable: true, width: 200,
    },
    {
      accessor: 'default_prescribed_dosage', Header: translate('default_prescribed_dosage'), sortMethod: sortByNumber, width: 200,
    },
    { accessor: 'dosage_unit', Header: translate('unit'), width: 100 },
    { accessor: 'frequency', Header: translate('default_frequency'), width: 200 },
    { accessor: 'tags', Header: translate('tags'), filterable: true, width: 200 },
    {
      accessor: 'default_prescribed_duration', Header: translate('default_duration'), sortMethod: sortByNumber, width: 150,
    },
    {
      accessor: 'price', Header: translate('selling_price'), sortMethod: sortByPayment, width: 150,
    },
    { accessor: 'panel_price', Header: translate('panel_price'), width: 200, Cell: renderMultilineContent },
    {
      accessor: 'cost_price', Header: translate('cost_price'), sortMethod: sortByPayment, width: 150,
    },
    {
      accessor: 'default_quantity', Header: translate('default_quantity'), sortMethod: sortByNumber, width: 150,
    },
    {
      accessor: 'dispensation_unit', Header: translate('dispensation_unit'), width: 150,
    },
    { accessor: 'notes', Header: translate('notes'), width: 250 },
    { accessor: 'manufacturer', Header: translate('manufacturer'), width: 200 },
    { accessor: 'mapped_manufacturer', Header: translate('mapped_manufacturer'), width: 200, useSecondaryData: true, hideDefault: true },
    { accessor: 'mmc_id', Header: translate('mal_number'), width: 200, useSecondaryData: true, hideDefault: true },
    { accessor: 'active_ingredients', Header: translate('active_ingredients'), width: 200, useSecondaryData: true, hideDefault: true },
    ...(config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) ? [{ accessor: 'atdps_integration', Header: translate('atdps_integrated'), width: 200, useSecondaryData: true, filterable: true }] : []),
    ...(config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) ? [{ accessor: 'atdps_id', Header: translate('atdps_id'), width: 200, useSecondaryData: true, filterable: true }] : []),
    ...(config.getIn(['print', 'prescription_labels', 'atdpsIntegration'], false) ? [{ accessor: 'dosing_regimen', Header: translate('dosing_regimen'), width: 200, useSecondaryData: true, filterable: true }] : []),
  ],
  items: drugs,
  dispensationUnitOptions: Set(drugs.map(d => d.get('dispensation_unit')))
    .filter(i => i && i.length > 1),
  config,
  coveragePayors,
  user,
  drugDurations: config.getIn(['prescription', 'durations'], List()).filter((e: Array<[number, string]>) => e !== null && e !== undefined),
  masterDrugModelsMap,
  drugMap: mapDrug(drugs),
  drugManufacturers,
  dosingRegimens,
  secondaryTableData: getMdlInfoForAllDrugs(drugs, verifiedDrugs, drugSuggestions, masterDrugModelsMap)
    .mergeWith(
      (prevInfo, atdpsInfo) => ({ ...prevInfo, ...atdpsInfo }),
      Map(drugs.map(d => [
        d.get('_id'),
        {
          atdps_integration: d.get('atdps_integration') ? 'Yes' : 'No',
          atdps_id: d.get(['integrations', 'omnihealth', 'drug_id']),
          dosing_regimen: dosingRegimens.find(i => i.get('_id') === d.get(['integrations', 'omnihealth', 'dosing_regimen_id'])) ?
            dosingRegimens.find(i => i.get('_id') === d.get(['integrations', 'omnihealth', 'dosing_regimen_id']))?.get('name') : UNICODE.EMDASH,
        },
      ])),
    )
    .toJS(),
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
  fetchTableMasterData: (ids: List<string>) => fetchMasterData(ids, dispatch),
  setDrugManufacturers: (models: List<DrugManufacturerModel>) =>
    dispatch(setDrugManufacturerModels(models)),
});

const DrugsContainer = connect(mapStateToProps, mapDispatchToProps)(Drugs);

export default DrugsContainer;
