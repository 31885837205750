/* eslint-disable max-lines */
import React from 'react';
import { List, Map } from 'immutable';
import glamorous from 'glamorous';
import InventoryMappingProgressBar from './inventoryMappingProgressBar';

import translate from './../../utils/i18n';
import { isLoading, transformColumnKeys, transformFilteredColumns, fixedDecimal } from './../../utils/utils';
import APIError from './../../utils/apiError';
import ContentTransition from './../contentTransition';
import Table from './../table/table';
import FormError from './../formError';
import Button from './../buttons/button';
import ButtonGroup from '../buttons/buttonGroup';
import Header from './../header/header';

import type { CategorizedInventoryMapItems, Row, SaveModel, SaveInventoryMappingResponse, SuggestInventoryMappingResponse, Config, CustomColumn, DrugMap, MapValue, User } from '../../types';
import { colours, wsUnit, margins } from './../../utils/css';
import { createSuccessNotification } from './../../utils/notifications';
import DrugModel from '../../models/drugModel';
import InventoryMapModel from '../../models/inventoryMapModel';
import type { Attributes as DrugSuggestionAttributes } from '../../models/drugSuggestionModel';
import type { InventoryMapUpdate } from '../../models/inventoryMapModel';
import MasterDrugModel from '../../models/masterDrugModel';
import DrugSuggestionModel from '../../models/drugSuggestionModel';
import NewMDLFeatureModal from '../newMDLFeatureModal';
import InventoryAcknowledgeDialog from './../prompts/inventoryAcknowledgeDialog';
import AddDetails from './addDetails';
import NonDrugMapping from './nonDrugMapping';
import { getStore } from './../../utils/redux';
import { updateInventoryItemDetails } from './../../utils/inventory';
import type DrugManufacturerModel from '../../models/drugManufacturerModel';

type State = {
  showUnmappedErrors: boolean,
  showCurrentItems: boolean,
  modalType: 'not_a_drug' | 'add_details' | '',
  errorMessage?: string,
  inventoryItem?: InventoryMapModel,
  columns: Array<CustomColumn>,
  showMappingInfoPopup: boolean,
  isModalVisible: boolean,
};

type Props = {
  config: Config,
  isFetching: boolean,
  currentDataViewsError?: APIError,
  masterDrugModelsMap: Map<string, MasterDrugModel>, // Need to index this thing. Maybe a IndexedList.
  drugSuggestions: Map<string, DrugSuggestionModel>,
  categorizedInventoryMapItems: CategorizedInventoryMapItems,
  saveInventoryMapping: (inventoryMapUpdates: List<InventoryMapUpdate>) =>
    Promise<SaveInventoryMappingResponse>,
  suggestInventoryItem: (suggestionParam: Object, clinicDrugId: string) =>
    Promise<SuggestInventoryMappingResponse>,
  replaceCurrentDataViewsModel: (prevModel: InventoryMapModel, newModel?: InventoryMapModel) =>
    void,
  updateDrugSuggestionModel: (drugSuggestionModel: DrugSuggestionModel) => void,
  fetchTableMasterData: (ids: List<string>) => Promise<void>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugMap: DrugMap,
  totalV2Count: number,
  totalDrugsCount: number,
  totalBillItemCount: number,
  saveModel: SaveModel,
  user: User,
  setDrugManufacturers: (models: List<DrugManufacturerModel>) => void,
  drugManufacturers: List<DrugManufacturerModel>,
}

const HelpText = glamorous.p({
  margin: `0 ${margins.standardMarginSides} ${margins.standardMarginBottom}`,
});

const COLUMNS = [
  { accessor: 'drug_name', Header: translate('drug_name'), filterable: true },
  { accessor: 'percent_of_bill_items', Header: translate('percent_of_bill_items'), filterable: false, sortable: true },
  { accessor: 'number_of_bill_items', Header: translate('number_of_bill_items'), filterable: false },
  { accessor: 'not_a_drug', Header: '', sortable: false, show: true },
  { accessor: 'add_details', Header: '', sortable: false, show: true },
];

/**
/**
 * @class InventoryMapping
 * @extends {React.Component<{}, State>}
 */
class InventoryMapping extends React.PureComponent<Props, State> {
  state: State;

  originalColumns: List<CustomColumn> = new List();

  /**
   * Creates an instance of InventoryMapping.
   * @param {Props} props Initial props.
   */
  constructor(props: Props) {
    super(props);
    this.originalColumns = this.originalColumns.concat(transformColumnKeys(COLUMNS));
    this.state = {
      showUnmappedErrors: false,
      showCurrentItems: true,
      errorMessage: undefined,
      inventoryItem: undefined,
      modalType: '',
      columns: this.originalColumns.toArray(),
      showMappingInfoPopup: false,
      isModalVisible: false,
    };
  }

  /**
  * Returns true if there are errors in the inventory map model
  * @param {InventoryMapUpdate | void} item inventory map model
  * @return {boolean} true | false
  */
  hasError(item: InventoryMapUpdate | void) {
    return !item || !item.sku_id;
  }

  /**
   * @param {DrugModel} drug drugModel to be deleted
   * @returns {void}
   */
  deleteDrug = (drug: DrugModel): Promise<void> =>
    new Promise(resolve => this.props.saveModel(drug.setVisible(false)).then(() => {
      resolve();
      createSuccessNotification(translate('item_deleted'));
    }));


  /**
   * Updates the inventory item mdl details.
   * @param {InventoryMapModel} item item for which the status needs to update.
   * @param {Partial<DrugSuggestionAttributes>} params params.
   * @param {string} event event
   * @returns {Promise<void>}
   */
  updateInventoryItem = (
    item: InventoryMapModel,
    params: Partial<DrugSuggestionAttributes>,
    event: string,
  ): Promise<void> => new Promise((resolve) => {
    updateInventoryItemDetails(getStore(), item, params, event)
      .then((result) => {
        this.setState({
          ...result,
        }, resolve());
      });
  })

  /**
   * @param {DrugModel} drug drug
   * @param {string} dispensationUnit dispensation_unit to set
   * @returns {Promise<void>}
   */
  updateDispensationUnit = (
    drug: DrugModel,
    dispensationUnit: string,
  ): Promise<void> => new Promise((resolve) => {
    this.props
      .saveModel(drug.set('dispensation_unit', dispensationUnit))
      .then(() => {
        resolve();
      });
  })

  /**
   * Creates the table rows for the current inventory.
   * @param {List<InventoryMapModel>} inventoryMaps The current list of inventory items with maps.
   * @returns {Array<Row>} An array of rows.
   */
  getRows(inventoryMaps: List<InventoryMapModel>): Array<Row> {
    const { totalBillItemCount } = this.props;
    return inventoryMaps.map((item: InventoryMapModel): Row => ({
      drug_name: item && item.get('name'),
      percent_of_bill_items: fixedDecimal((item && Number(item.get('mapping_priority')) * 100) / totalBillItemCount),
      number_of_bill_items: item && Number(item.get('mapping_priority')),
      not_a_drug: (
        <Button
          className="o-button o-button--small u-margin-left--1ws u-margin-right--1ws u-full-width"
          onClick={() => {
            this.setState({ inventoryItem: item, modalType: 'not_a_drug', isModalVisible: true });
          }}
          dataPublic
        >
          {translate('not_a_drug')}
        </Button>),
      add_details: (
        <Button
          className="o-button o-button--small u-margin-left--1ws u-margin-right--1ws u-full-width"
          onClick={() => {
            this.setState({ inventoryItem: item, modalType: 'add_details', isModalVisible: true });
          }}
          dataPublic
        >
          {translate('add_details')}
        </Button>),
    })).toArray();
  }

  /**
   * Returns the non drug Modal.
   * @param {InventoryMapModel} item inventory map model in the specific row
   * @returns {React.SFC}
   */
  renderNonDrugModal(item: InventoryMapModel) {
    return (
      <NonDrugMapping
        inventoryItem={item}
        drugMap={this.props.drugMap}
        deleteDrug={this.deleteDrug}
        updateInventoryItem={this.updateInventoryItem}
        hideModal={this.hideModal}
        visible={!!item}
      />
    );
  }

  /**
   * Returns the add details modal.
   * @param {InventoryMapModel} item inventory map model in the specific row
   * @returns {React.SFC}
   */
  renderAddDetailsModal(item: InventoryMapModel) {
    return (
      <AddDetails
        inventoryItem={item}
        drugMap={this.props.drugMap}
        updateConfigValue={this.props.updateConfigValue}
        config={this.props.config}
        updateConfig={this.props.updateConfig}
        drugSuggestions={this.props.drugSuggestions}
        masterDrugModelsMap={this.props.masterDrugModelsMap}
        fetchTableMasterData={this.props.fetchTableMasterData}
        deleteDrug={this.deleteDrug}
        updateInventoryItem={this.updateInventoryItem}
        updateDispensationUnit={this.updateDispensationUnit}
        hideModal={this.hideModal}
        setDrugManufacturers={this.props.setDrugManufacturers}
        drugManufacturers={this.props.drugManufacturers}
      />
    );
  }

  /**
   * Hides the Add details/ Non-drug Mapping Modal
   * @returns {void}
   */
  hideModal = () => this.setState({ inventoryItem: undefined, modalType: '', isModalVisible: false });

  /**
   * @returns {getCategorizedOverview}
   */
  getCategorizedOverview() {
    const { categorizedInventoryMapItems, totalV2Count, totalDrugsCount: total } = this.props;
    const totalNew = total - totalV2Count;
    const verifiedInventoryItems = categorizedInventoryMapItems.acceptedInventoryItems;
    const verifiedInventoryItemCount = verifiedInventoryItems.size;
    const totalVerifiedNew = verifiedInventoryItems.filter(item => item.get('mapping_category') === 1);
    const verifiedV2Count = Math.abs(verifiedInventoryItemCount - totalVerifiedNew.size);
    const pendingReview = categorizedInventoryMapItems
      .completedPendingReviewInventoryItems
      .concat(categorizedInventoryMapItems.inCompletePendingReviewInventoryItems);
    const pendingReviewCount = pendingReview.size;
    const totalPendingReviewNew = pendingReview.filter(item => item.get('mapping_category') === 1);
    const pendingV2Count = Math.abs(pendingReviewCount - totalPendingReviewNew.size);
    const reviewRejected = categorizedInventoryMapItems.reviewRejectedInventoryItems;
    const reviewRejectedItemCount = reviewRejected.size;
    const totalReviewRejectedNew = reviewRejected.filter(item => item.get('mapping_category') === 1);
    const reviewRejectedV2Count = Math.abs(reviewRejectedItemCount - totalReviewRejectedNew.size);
    const totalUnmapped =
      total -
      (verifiedInventoryItemCount + pendingReview.size + reviewRejected.size);
    return {
      verified: `${verifiedV2Count} / ${totalV2Count}`,
      verifiedPercentage: fixedDecimal((verifiedV2Count * 100) / (total || 1)),
      pendingReview: `${pendingV2Count} / ${totalV2Count}`,
      pendingReviewV2Count: pendingV2Count,
      pendingReviewPercentage: fixedDecimal((pendingV2Count * 100) / (total || 1)),
      newlyAdded: `${(totalVerifiedNew.size + totalPendingReviewNew.size + totalReviewRejectedNew.size)} / ${totalNew}`,
      newlyAddedPercentage: fixedDecimal(
        ((totalVerifiedNew.size +
          totalPendingReviewNew.size +
          totalReviewRejectedNew.size) *
          100) /
          (total || 1),
      ),
      unmapped: `${totalUnmapped} / ${total}`,
      unmappedPercentage: fixedDecimal((totalUnmapped * 100) / (total || 1)),
      total,
      pendingReviewCount,
      totalOverview: verifiedInventoryItemCount + pendingReviewCount + reviewRejectedItemCount,
      verifiedInventoryItemCount,
      verifiedCountNew: totalVerifiedNew.size,
      pendingCountNew: totalPendingReviewNew.size,
      reviewRejectedV2Count,
      reviewRejectedCountNew: totalReviewRejectedNew.size,
    };
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      currentInventoryItems,
      reviewRejectedInventoryItems,
      inCompletePendingReviewInventoryItems,
    } = this.props.categorizedInventoryMapItems;
    const { showCurrentItems } = this.state;
    const currentItems = currentInventoryItems.concat(inCompletePendingReviewInventoryItems);
    const categorizedOverview = this.getCategorizedOverview();
    const data = this.getRows(showCurrentItems ? currentItems : reviewRejectedInventoryItems);
    return (
      <ContentTransition>
        <InventoryAcknowledgeDialog
          updateConfig={this.props.updateConfig}
          updateConfigValue={this.props.updateConfigValue}
          user={this.props.user}
          config={this.props.config}
          headerLabel={translate('inventory_mapping_notice.header_label')}
          description={translate('inventory_mapping_notice.description')}
          footerLabel={translate('inventory_mapping_notice.footer_label')}
        />
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <Header className="o-card__header" dataPublic>
            <h1 className="o-title">{translate('inventory_mapping')}</h1>
            <div className="u-flex-row u-flex-right u-margin-right--1ws">
              <ButtonGroup
                id="inventory-item-toggler"
                buttons={[
                  {
                    label: translate('current_inventory_items'),
                    isActive: showCurrentItems,
                    onClick: () => this.setState({ showCurrentItems: true }),
                  },
                  {
                    label: translate('rejected_review_items'),
                    isActive: !showCurrentItems,
                    onClick: () => this.setState({ showCurrentItems: false }),
                  },
                ]}
              />
              <button
                key="button-mapping-info"
                className="u-font-1ws o-text-button"
                onClick={() => this.setState({ showMappingInfoPopup: true })}
              >
                <img src="../../../static/images/icon_info.png" style={{ width: wsUnit }} alt="i" />
              </button>
              <NewMDLFeatureModal
                program="new-inventory-mapping-feature"
                onModalClose={() => this.setState({ showMappingInfoPopup: false })}
                show={this.state.showMappingInfoPopup}
              />
            </div>
          </Header>
          <div className="o-card">
            {
            this.state.errorMessage &&
            <FormError isSticky>{this.state.errorMessage}
            </FormError>
            }
            <Header className="o-card__header" dataPublic>
              <h1 className="o-card__title">
                {translate('inventory_mapping_progress')}
              </h1>
              {/* <TableColumnsSettings
                config={this.props.config}
                configFieldName="inventory_map"
                updateConfigValue={this.props.updateConfigValue}
                originalColumns={this.originalColumns}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
              /> */}
            </Header>
            <div className="c-progress-bar__container">
              <InventoryMappingProgressBar
                categorizedPercentage={categorizedOverview}
              />
              <div className="u-flex-right u-margin-right--half-ws c-progress-bar__overview">
                {
                `${categorizedOverview.totalOverview}
                  / ${categorizedOverview.total}`
                }
              </div>
            </div>
            <Table
              className="i-map-table"
              columns={transformFilteredColumns(COLUMNS, this.state.columns)}
              data={data}
              noDataText={
                translate(this.props.currentDataViewsError
                  ? 'error_contact_support'
                  : isLoading(this.props.isFetching, data.length ? 'no_items' : 'inventory_mapping_completed_message'))
              }
              defaultSorted={[{ id: 'percent_of_bill_items', desc: true }]}
              showPagination
              trProperties={(state, rowInfo) => (this.state.showUnmappedErrors &&
                this.hasError(rowInfo.original.selectedMap) ? {
                  style: {
                    border: `1px solid ${colours.redHover}`,
                    background: `${colours.redHover}`,
                  },
                } : {})}
            />
          </div>
          <HelpText>{translate('inventory_mapping_help_text')}</HelpText>
        </section>
        {this.state.inventoryItem && this.state.isModalVisible ?
          this.state.modalType === 'not_a_drug' ?
            this.renderNonDrugModal(this.state.inventoryItem)
            : this.renderAddDetailsModal(this.state.inventoryItem)
          : null}
      </ContentTransition>
    );
  }
}

export default InventoryMapping;
