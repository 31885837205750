import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import translate from './../../utils/i18n';
import AnnouncementModal from '../modals/announcementModal';

/**
 * Render the banner
 * @returns {HTMLElement} html element
 */
const NewPharmaconnectFeatureModal = () => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to="/pharmaconnect" />;
  }

  return (
    <AnnouncementModal
      program="pharmaconnect"
      onExplicitClose={() => setRedirect(true)}
      footerButtonLabel={translate('pharmaconnect_modal_button_label')}
      modalHeading={translate('pharmaconnect_modal_title')}
      id="pharmaconnect-modal"
      image={<img src="/static/images/pathomics-modal-ad.png" alt="pharmaconnect" />}
    >
      <p>{translate('pharmaconnect_modal_text')}</p>
    </AnnouncementModal>
  );
};

export default NewPharmaconnectFeatureModal;
