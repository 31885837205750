import React from 'react';
import { List, is } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import ListEditorTable from './../listEditor/listEditorTable';
import { sortByNumber, sortBySumOfElements } from './../../utils/comparators';
import { queryDesignDoc } from './../../utils/db';
import { createPermission, hasPermission } from './../../utils/permissions';
import { handleResponseError, transformColumnKeys, transformFilteredColumns } from './../../utils/utils';
import { renderList, renderPaymentList } from './../../utils/tables';
import { DDOC_VERSION } from './../../constants';
import StatelessModal from '../modals/statelessModal';
import Header from './../header/header';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import CoveragePayorForm from './coveragePayorForm';
import TableColumnsSettings from '../table/tableColumnsSettings';
import type { SaveModel, User, Config, MapValue, CustomColumn } from './../../types';
import { debugPrint } from '../../utils/logging';

type ResponsePatientCountForCoveragePayors = {
  rows: Array<patientCountForCoveragePayors>
}

type patientCountForCoveragePayors = {
  key: string,
  value: number
}

type Props = {
  saveModel: SaveModel,
  coveragePayors: List<CoveragePayorModel>,
  user: User,
  config: Config,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
};

type State = {
  columns: Array<CustomColumn>,
  modelToEdit?: CoveragePayorModel,
  secondaryData: {
    [key: string]: {
      patients: number,
      policies: Array<string>,
      defaultCoPayment: Array<string>,
      maximumClaimableAmount: Array<string>,
    },
  },
  isModalVisible: boolean,
};

const COLUMNS = [
  {
    accessor: 'name', Header: translate('name'), minWidth: 100, width: 350, filterable: true,
  },
  {
    accessor: 'internal_clinic_id', Header: translate('panel_id'), minWidth: 100, width: 150,
  },
  {
    accessor: 'policies', Header: translate('policy_name'), minWidth: 100, width: 150, useSecondaryData: true, Cell: renderList,
  },
  {
    accessor: 'defaultCoPayment', Header: translate('co_payment'), minWidth: 100, width: 150, useSecondaryData: true, Cell: renderPaymentList, sortMethod: sortBySumOfElements,
  },
  {
    accessor: 'maximumClaimableAmount', Header: translate('maximum_claimable_amount'), minWidth: 100, width: 200, useSecondaryData: true, Cell: renderPaymentList, sortMethod: sortBySumOfElements,
  },
  {
    accessor: 'patients', Header: translate('patients'), minWidth: 100, width: 100, useSecondaryData: true, sortMethod: sortByNumber,
  },
  {
    accessor: 'address', Header: translate('address'), minWidth: 100, width: 300,
  },
];

/**
 * CoveragePayors
 * @namespace CoveragePayors
 */
class CoveragePayors extends React.Component<Props, State> {
  originalColumns: List<CustomColumn> = new List();

  /**
   * Creates an instance of CoveragePayors.
   * @param {object} props The props for this component.
   */
  constructor(props: Props) {
    super(props);
    this.originalColumns = this.originalColumns.concat(transformColumnKeys(COLUMNS));
    this.state = {
      modelToEdit: undefined,
      secondaryData: {},
      columns: this.originalColumns.toArray(),
      isModalVisible: false,
    };
    this.fetchPatientCount();
  }


  /**
   * Called after component completely mounted
   * @returns {void}
   */
  componentDidMount() {
    this.setCoveragePayorPolicies();
  }

  /**
   * Check if props changed and update state accordingly
   * @param {Props} prevProps Component props
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props) {
    if (!is(prevProps.coveragePayors, this.props.coveragePayors)) {
      this.setCoveragePayorPolicies();
    }
  }


  /**
   * Gets the count of patient for each Coverage Payor.
   * @returns {void}
   */
  fetchPatientCount() {
    if (process.env.NODE_ENV === 'test') {
      // setTimeout(() => this.setState({ patientCount: {} }), 500);
      setTimeout(() => this.setState({ secondaryData: {} }), 500);
    } else {
      queryDesignDoc(`${DDOC_VERSION}/patientCountForCoveragePayors`, { group: true })
        .then((response: any) => {
          const responseData: ResponsePatientCountForCoveragePayors = response;
          if (responseData.rows) {
            this.setState({
              secondaryData: response.rows.reduce(
                (a, row: patientCountForCoveragePayors) => ({
                  ...a,
                  [row.key]: {
                    ...a[row.key], patients: row.value,
                  },
                }),
                this.state.secondaryData,
              ),
            });
          }
        })
        .catch((error) => {
          handleResponseError(error, 'There was some error while fetching coverage payors');
        });
    }
  }


  /**
   * Sets the policy info for each Coverage Payor.
   * @returns {void}
   */
  setCoveragePayorPolicies() {
    const secdata = this.props.coveragePayors.reduce((data, payor) => (
      {
        ...data,
        [payor.get('_id')]: {
          ...data[payor.get('_id')],
          policies: (payor.get('policies') && payor.get('policies').length) ?
            payor.get('policies')
              .filter(policy => policy.active)
              .map(policy => policy.policy_name) : ['Default'],
          defaultCoPayment: (payor.get('policies') && payor.get('policies').length) ?
            payor.get('policies')
              .filter(policy => policy.active)
              .map(policy => policy.policy_co_payment_amount) : [''],
          maximumClaimableAmount: (payor.get('policies') && payor.get('policies').length) ?
            payor.get('policies')
              .filter(policy => policy.active)
              .map(policy => policy.policy_maximum_claimable_amount) : [''],
        },
      }
    ), this.state.secondaryData);
    this.setState({
      secondaryData: secdata,
    });
  }


  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    debugPrint('Rendering CoveragePayors');
    return (
      <ContentTransition className="o-main__content">
        <section className="o-scrollable-container" style={{ height: '100vh' }}>
          <Header className="o-header" dataPublic>
            <h1>{translate('coverage_payors')}</h1>
            {
              (this.state.modelToEdit || hasPermission(this.props.user, List([createPermission('coverage_payors', 'create')]))) &&
                <StatelessModal
                  id="add_new_coverage_payor"
                  buttonLabel={translate('add_new_coverage_payor')}
                  buttonClass="o-button o-button--small o-button--padded"
                  title={this.state.modelToEdit ? translate('edit_item') : translate('add_new_coverage_payor')}
                  setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                  visible={this.state.isModalVisible || !!this.state.modelToEdit}
                  onClose={() => this.setState({ modelToEdit: undefined })}
                  explicitCloseOnly
                  dataPublicHeader
                >
                  <CoveragePayorForm
                    modelToEdit={this.state.modelToEdit}
                    clearModelToEdit={() =>
                      this.setState({ modelToEdit: undefined, isModalVisible: false })
                    }
                    type="coverage_payor"
                    saveModel={this.props.saveModel}
                    config={this.props.config}
                    coveragePayors={this.props.coveragePayors}
                    user={this.props.user}
                    saveIsSticky
                  />
                </StatelessModal>
            }
          </Header>
          <div className="o-card u-margin-bottom--4ws">
            <Header className="o-card__header" dataPublic>
              <h2 className="o-card__title">{translate('coverage_payors')}</h2>
              <TableColumnsSettings
                config={this.props.config}
                configFieldName="coverage_payors"
                updateConfigValue={this.props.updateConfigValue}
                originalColumns={this.originalColumns}
                columns={this.state.columns}
                onUpdateColumns={(columns) => {
                  this.setState({ columns });
                }}
                updateConfig={this.props.updateConfig}
              />
            </Header>
            <ListEditorTable
              onEditClicked={modelToEdit => this.setState({ modelToEdit })}
              saveModel={this.props.saveModel}
              items={this.props.coveragePayors}
              canEdit={hasPermission(this.props.user, List([createPermission('coverage_payors', 'update')]))}
              canDelete={hasPermission(this.props.user, List([createPermission('coverage_payors', 'delete')]))}
              columns={transformFilteredColumns(COLUMNS, this.state.columns)}
              secondaryData={this.state.secondaryData}
            />
          </div>
        </section>
      </ContentTransition>
    );
  }
}

export default CoveragePayors;
