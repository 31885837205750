import React from 'react';
import type { List } from 'immutable';

import CasenoteEditor from './casenoteEditor';
import { CASENOTE_ORDER_INCREMENT } from './../../constants';
import { fetchAssetsFromCouch, getHighestCasenoteOrder } from './../../utils/db';
import { saveBlobs, dataURItoBlob, handleResponseError } from './../../utils/utils';
import CaseNoteFileModel from './../../models/caseNoteFileModel';
import TextCasenoteEditor from './../textCasenotes/textCasenoteEditor';
import { applyEtches } from './../../utils/etching';

import type TemplateModel from './../../models/templateModel';
import type PatientModel from './../../models/patientModel';
import type { Config, SaveModel } from './../../types';


type Props = {
  casenoteID?: string,
  caseNoteFile?: CaseNoteFileModel,
  templateID: string,
  patientID: string,
  patient: PatientModel,
  categoryID: string,
  templates: List<TemplateModel>,
  addRecentlyViewedCasenote: (patientID: string, casenoteID: string) => void,
  config: Config,
  saveModel: SaveModel,
  isReferralMode: boolean,
  assetIndex: number,
  isNewTextCasenote: boolean,
  isOnline: boolean,
};

type State = {
  isCreatingNote: boolean,
  newCasenote?: CaseNoteFileModel,
};

/**
 * This is wrapper component that sits between CasenoteEditorContainer and CasenoteEditor and handles
 * the creation of a casenote if necessary.
 * @class NewNoteHandler
 * @extends {React.Component<Props, State>}
 */
class NewNoteHandler extends React.Component<Props, State> {
  /**
   * Creates an instance of NewNoteHandler.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isCreatingNote: !props.caseNoteFile,
    };
    if (!props.caseNoteFile) {
      this.createCasenote(props);
    }
  }

  /**
   * Creates a new note if necessary.
   * @param {Props} nextProps Next props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.casenoteID !== this.props.casenoteID) {
      this.setState({ newCasenote: undefined });
    }
    if (!nextProps.caseNoteFile && !this.state.isCreatingNote && !this.state.newCasenote) {
      this.setState({ isCreatingNote: true });
      this.createCasenote(nextProps);
    }
  }

  /**
   * Creates a new casenote with the given template.
   * @param {Props} props Props
   * @returns {void}
   */
  createCasenote(props: Props) {
    const {
      templateID, patientID, categoryID, templates,
    } = props;
    const template = templates.find(t => t.get('_id') === templateID);
    const { patient } = this.props;
    if (!template) {
      throw new Error('Template not found.');
    }
    const caseNoteFile = new CaseNoteFileModel({
      patient_id: patientID,
      category: categoryID,
      order: 0, // Should be overwritten later.
      assets: [],
      star: false,
      source: { template: template.get('_id') },
    });
    if (props.isNewTextCasenote) {
      saveBlobs([dataURItoBlob('data:text/plain, ')]) // An extra space is added because the API dies if it receives an 'empty' file.
        .then((assetDataArray) => {
          getHighestCasenoteOrder(patientID)
            .then((order) => {
              this.setState({
                isCreatingNote: false,
                newCasenote: caseNoteFile.set({
                  assets: assetDataArray,
                  order: order + CASENOTE_ORDER_INCREMENT,
                }),
              });
            })
            .catch((error) => {
              handleResponseError(error);
            });
        });
    } else {
      fetchAssetsFromCouch(template.get('assets'))
        .then(assetBlobs => applyEtches(template, assetBlobs, patient))
        .then(assetBlobs => saveBlobs(assetBlobs))
        .then((assetDataArray) => {
          getHighestCasenoteOrder(patientID)
            .then((order) => {
              this.setState({
                isCreatingNote: false,
                newCasenote: caseNoteFile.set({
                  assets: assetDataArray,
                  order: order + CASENOTE_ORDER_INCREMENT,
                }),
              });
            })
            .catch(() => this.setState({ isCreatingNote: false }));
        });
    }
  }

  /**
   * Handles a click on the new note button by resetting state and then creating a new note.
   * This function is passed down to the CasenoteEditor.
   * @returns {void}
   */
  onNewNoteClicked() {
    if (this.state.newCasenote) {
      this.setState({ newCasenote: undefined }, this.createCasenote(this.props));
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const caseNoteFile = this.state.newCasenote || this.props.caseNoteFile;
    if (this.props.isNewTextCasenote || (caseNoteFile && caseNoteFile.isText())) {
      return <TextCasenoteEditor {...this.props} caseNoteFile={caseNoteFile} />;
    }
    return (
      <CasenoteEditor
        {...this.props}
        caseNoteFile={caseNoteFile}
        onNewNoteClicked={() => this.onNewNoteClicked()}
      />
    );
  }
}

export default NewNoteHandler;
