import React from 'react';
import Moment, { Moment as MomentType } from 'moment';
import { List, is, Map } from 'immutable';

import translate from './../../utils/i18n';
import BillFinalisationSummary from './billFinalisationSummary';
import BillMetadataForm from './billMetadataForm';
import BillFormItemList from './billFormItemList';
import BillSidebar from './billSidebar';
import BillModel from './../../models/billModel';
import EncounterModel, { StageInfo } from './../../models/encounterModel';
import MedicalCertificateFormModal from './../medicalCertificates/medicalCertificateFormModal';
import IssuedItemsList from './../issuedItems/issuedItemsList';
import PatientModel from './../../models/patientModel';
import { listToMap, sum } from './../../utils/utils';
import { createSuccessNotification } from './../../utils/notifications';
import { getBillSerialNumber } from './../../utils/serialNumbers';
import { logBillFinalisation, debugPrint } from './../../utils/logging';
import PatientHeader from './../patient/patientHeader';
import SavePrompt from './../prompts/savePrompt';
import FormError from './../formError';
import { isReferralMode } from '../../utils/router';
import BillItemModel from './../../models/billItemModel';
import { combineDateAndTime } from './../../utils/time';
import Confirm from '../prompts/confirm';
import {
  billItemsToTransactions, calculatePatientTotal, calculateCoveragePayorTotal,
  calculateBillItemTotal, salesItemToBillItem, areBillItemsValid, getPatientOwedAmount,
  adjustPaymentsToMatchTotal, paymentsAreValid, isDispensationOutOfStock, getDefaultPaymentMethod,
  getPanelPriceUpdateMessage,
} from './../../utils/billing';
import { isInventoryCountReadyForDispensation } from './../../utils/inventory';
import { getPatientCoPayment } from './../../utils/coveragePayors';
import { getSalesItemsFromEncounterType } from '../../utils/encounters';
import PaymentModel from './../../models/paymentModel';
import ReceivableModel from './../../models/receivableModel';
import ClaimModel from './../../models/claimModel';

import type ProviderModel from './../../models/providerModel';
import type ProcedureTypeModel from './../../models/procedureTypeModel';
import type AllergyModel from './../../models/allergyModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DrugModel from './../../models/drugModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PractitionerModel from './../../models/practitionerModel';
import type SalesItemModel from './../../models/salesItemModel';
import type { Config, SaveModels, User, SaveModel, MapValue, CountPerSKUAndBatch, Model } from './../../types';
import type ConditionModel from './../../models/conditionModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type TransactionModel from '../../models/transactionModel';
import type EncounterFlowModel from '../../models/encounterFlowModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import PaymentTypeModel from '../../models/paymentTypeModel';
import InventoryMapModel from '../../models/inventoryMapModel';
import DiscountChargeModel from './../../models/discountChargeModel';

/* eslint-disable camelcase */
type BillAttributes = {
  patient_id: string,
  coverage_payor_id?: string,
  coverage_policy_id?: string,
  co_payment: number,
  [key: string]: MapValue,
};
/* eslint-enable camelcase */

type Props = {
  allergies: List<AllergyModel>,
  paymentTypes: List<PaymentTypeModel>,
  config: Config,
  klinifyConfig: Config,
  coveragePayors: List<CoveragePayorModel>,
  drugs: List<DrugModel>,
  patient: PatientModel | PatientStubModel,
  practitioners: List<PractitionerModel>,
  salesItems: List<SalesItemModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  user: User,
  defaultCoPayment?: number,
  providers: List<ProviderModel>,
  procedureTypes: List<ProcedureTypeModel>,
  diagnoses: List<ConditionModel>,
  conditions: List<ConditionModel>,
  documentTemplates: List<DocumentTemplateModel>,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugDurations: List<List<number | string>>,
  isOnline: boolean,
  encounterFlows: List<EncounterFlowModel>,
  encounterStageMap: Map<string, EncounterStageModel>,
  discountsCharges: List<DiscountChargeModel>,
  saveBillingRelatedModels: (
    bill: BillModel,
    transactions: List<TransactionModel>,
    billItems: List<BillItemModel>,
    encounter: EncounterModel,
    payments?: List<PaymentModel>,
    receivable?: List<ReceivableModel>,
    claim?: List<ClaimModel>,
  ) => Promise<Array<Model>>,
  verifiedDrugs: List<InventoryMapModel>,
};

type State = {
  encounterAttributes: {
    encounterFlow?: EncounterFlowModel,
    doctor?: string,
    // eslint-disable-next-line camelcase
    patient_id: string,
  },
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  date: MomentType,
  time: MomentType,
  medicalCertificateModalVisible: boolean,
  patientHeaderHeight: number,
  payments: List<PaymentModel>,
  errorMessage?: string,
  changesMade: boolean,
  isSaving: boolean,
  showBillFinalisationSummary: boolean,
  updatedMedicalCertificate?: MedicalCertificateModel,
  isPaymentInvalid: boolean,
  isBatchReadyForDispensation: boolean,
  confirmPriceChangeModalContent?: React.ElementType
};

/**
 * A component for the adding of a new (past) Bill. It expects to create an Encounter as well as a
 * Bill.
 * @class AddNewBill
 * @extends {React.Component<Props, State>}
 */
class AddNewBill extends React.Component<Props, State> {
  /**
   * Creates an instance of AddNewBill.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    const { patient } = props;
    const billItems = props.config.get('pastBillCreationPresetSalesItems', List())
      .map((itemID) => {
        const salesItem = props.salesItems.find(i => i.get('_id') === itemID);
        if (!salesItem) {
          debugPrint(`Sales item with ID ${itemID} not found.`);
          throw new Error('Sales item referenced in config not found.');
        }
        return salesItemToBillItem(salesItem, this.props.patient.get('_id'));
      });
    const billAttributes = {
      patient_id: patient.get('_id'),
      coverage_payor_id: patient.getCoveragePayor(),
      coverage_policy_id: patient.getCoveragePolicy(),
      co_payment: getPatientCoPayment(patient, props.coveragePayors),
      applied_discounts_charges: [],
    };
    this.state = {
      encounterAttributes: { patient_id: patient.get('_id') },
      billAttributes,
      billItems,
      patientHeaderHeight: 0,
      date: new Moment(),
      time: new Moment(),
      medicalCertificateModalVisible: false,
      payments: this.updatePaymentsToMatchAmount(List(), billAttributes, billItems),
      changesMade: false,
      isSaving: false,
      updatedMedicalCertificate: undefined,
      showBillFinalisationSummary: false,
      isPaymentInvalid: false,
      isBatchReadyForDispensation: false,
      confirmPriceChangeModalContent: undefined,
    };
  }

  /**
   * Triggers a batch data sync status check after first render
   * @returns {void}
   */
  componentDidMount() {
    this.checkBatchDataReady();
  }

  /**
   * Triggers a batch data sync status check when the inventory count changes.
   * @param {Props} prevProps Previous Props
   * @param {State} prevState Previous State
   * @returns {void}
   */
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      !is(this.getDispensedDrugs(this.state.billItems),
        this.getDispensedDrugs(prevState.billItems)) ||
      !is(this.props.inventoryCount, prevProps.inventoryCount) ||
      !is(this.props.inventoryCountSyncStatus, prevProps.inventoryCountSyncStatus)
    ) {
      this.checkBatchDataReady();
    }
  }

  /**
   * check for batch Data readiness for dispensation
   * @returns {void}
   */
  checkBatchDataReady() {
    const { drugs, inventoryCount, inventoryCountSyncStatus } = this.props;
    const billItemsMap = this.state.billItems.groupBy(bi => bi.get('drug_id'));
    const skus = drugs.filter(d => billItemsMap.has(d.get('_id')));
    this.setState({
      isBatchReadyForDispensation: isInventoryCountReadyForDispensation(
        skus,
        inventoryCount,
        inventoryCountSyncStatus,
      ),
    });
  }

  /**
     * Gets the Dispensed drug ids from bill items.
     * @param {List<BillItemModel>} billItems The prescription type bill items.
     * @returns {List<string>} List of drug ids
     */
  getDispensedDrugs = (billItems: List<BillItemModel>) => billItems
    .map(bi => bi.get('drug_id'))
    .filter(d => !!d);

  /**
   * This function should be called after making any changes to the amount of a bill (or after the
   * initialisation of a new bill). It will take the current value of state.payments and update it
   * to have at least one payment if the patient cost is over 0, and have all payments add up to the
   * patient total. The latter will be done by increasing / decreasing payments starting with the
   * first one.
   * @param {List<PaymentModel>} payments The current value of state.payments
   * @param {BillAttributes} billAttributes The current bill attributes
   * @param {List<BillItemModel>} billItems The current bill items.
   * @returns {List<PaymentModel>}  The new value of state.payments
   */
  updatePaymentsToMatchAmount(
    payments: List<PaymentModel>,
    billAttributes: BillAttributes,
    billItems: List<BillItemModel>,
  ): List<PaymentModel> {
    if (billAttributes.is_finalised || billAttributes.is_void) {
      return payments;
    }
    const patientTotal = calculatePatientTotal(billAttributes, billItems);
    if (patientTotal > 0 && payments.size === 0) {
      return List([this.getNewPayment(patientTotal)]);
    }
    return adjustPaymentsToMatchTotal(payments, patientTotal);
  }

  /**
   * Returns the payments in State that are not void.
   * @returns {List<PaymentModel>}
   */
  getActivePayments(): List<PaymentModel> {
    return this.state.payments.filter(p => !p.isVoid());
  }

  /**
   * Returns the bill items in State that have not been removed.
   * @returns {List<BillItemModel>}
   */
  getActiveBillItems(): List<BillItemModel> {
    return this.state.billItems.filter(i => !i.get('_deleted', false));
  }

  /**
   * Returns a new payment for the given amount
   * @param {number} paymentAmount The amount payed.
   * @returns {PaymentModel}
   */
  getNewPayment(paymentAmount: number): PaymentModel {
    return new PaymentModel({
      patient_id: this.props.patient.get('_id'),
      bill_id: undefined, // Will be updated before saving
      is_finalised: true,
      timestamp: undefined, // Will be updated before saving
      payor_type: 'patient',
      amount: paymentAmount,
      receivable_id: undefined, // Will be updated before saving
      individual_payor_id: this.props.patient.get('_id'),
      method: getDefaultPaymentMethod(this.props.paymentTypes),
    });
  }

  /**
   * Handles the changing of the coverage payor.
   * @param {string} coveragePayorID The CoveragePayor ID.
   * @param {string} policyID The policy ID.
   * @param {boolean} shouldPatientUpdate If true the patientModel should be updated with the new
   * CoveragePayor
   * @returns {void}
   */
  onCoveragePayorChanged = (
    coveragePayorID: string | void,
    policyID: string | void,
    shouldPatientUpdate: boolean,
  ) => {
    const activeBillItems = this.getActiveBillItems();
    const billItems = coveragePayorID ? activeBillItems : activeBillItems.map(i => i.set('coverage_payor', undefined));
    const prevStateCoveragePayor = this.state.billAttributes.coverage_payor_id;
    const prevStatePolicyId = this.state.billAttributes.coverage_policy_id;
    const updatedBillItems = billItems.map((b) => {
      if (b.isPrescription()) {
        const drug = this.props.drugs.find(d => d.get('_id') === b.getItemId());
        return drug ? new BillItemModel(
          {
            ...b.attributes,
            price: drug.getPrice(coveragePayorID),
            coverage_payor_id: coveragePayorID,
          },
        ) : b;
      }
      return b;
    });
    const coveragePayor = this.props.coveragePayors.find(c => c.get('_id') === coveragePayorID);
    // Reset copayment and claimable status of billitems if panel removed.
    if (coveragePayorID === undefined) {
      const billAttributes = Object.assign({}, this.state.billAttributes, {
        co_payment: 0,
        coverage_payor_id: undefined,
        coverage_policy_id: undefined,
      });
      this.setState({
        billItems: updatedBillItems,
        billAttributes,
        payments: this.updatePaymentsToMatchAmount(
          this.state.payments,
          billAttributes,
          updatedBillItems,
        ),
        changesMade: true,
      });
    } else {
      const coPayment = coveragePayor ? coveragePayor.getCoPayment(policyID) : null;
      const billAttributes = Object.assign({}, this.state.billAttributes, {
        co_payment: coPayment,
        coverage_payor_id: coveragePayorID,
        coverage_policy_id: policyID,
      });
      this.setState({
        billItems: updatedBillItems,
        billAttributes,
        changesMade: true,
        payments: this.updatePaymentsToMatchAmount(
          this.state.payments,
          billAttributes,
          updatedBillItems,
        ),
      });
    }
    if (prevStateCoveragePayor !== coveragePayorID ||
        (!policyID && !prevStatePolicyId) ||
        (prevStatePolicyId !== policyID)) {
      const confirmPriceChangeModalContent = getPanelPriceUpdateMessage(
        this.props.drugs,
        updatedBillItems,
        coveragePayor,
      );
      this.setState({
        confirmPriceChangeModalContent,
      });
    }
    if (shouldPatientUpdate) {
      this.props.saveModel(this.props.patient.setCoverageField(coveragePayorID, policyID));
    }
  }

  /**
   * Returns true if all required fields are filled.
   * @returns {boolean} True if state is valid for submission.
   */
  isValid() {
    if (!this.state.date) {
      this.setState({ errorMessage: translate('fill_required_fields') });
      return false;
    }
    if (!this.state.time) {
      this.setState({ errorMessage: translate('fill_time_and_in_correct_format') });
      return false;
    }
    if (!this.state.encounterAttributes.encounterFlow) {
      this.setState({ errorMessage: translate('please_select_encounter_flow') });
      return false;
    }
    if (!this.state.encounterAttributes.doctor) {
      this.setState({ errorMessage: translate('please_select_a_doctor') });
      return false;
    }
    if (this.state.billItems.size === 0) {
      this.setState({ errorMessage: translate('bill_must_have_items') });
      return false;
    }
    if (!areBillItemsValid(this.state.billItems)) {
      this.setState({ errorMessage: translate('bill_items_must_not_have_empty_fields') });
      return false;
    }
    if (!paymentsAreValid(this.getActivePayments().filter(p => !p.hasBeenSaved()))) {
      this.setState({ errorMessage: undefined, isPaymentInvalid: true });
      return false;
    }
    this.setState({ errorMessage: undefined, isPaymentInvalid: false });
    return true;
  }

  /**
   * Returns true if stock is available.
   * @param {List<TransactionModel>} transactions Current unsaved transactions
   * @returns {boolean} True if state is valid for submission.
   */
  isStockAvailable(transactions: List<TransactionModel>): boolean {
    if (isDispensationOutOfStock(
      this.state.billItems,
      this.props.inventoryCount,
      transactions,
    )) {
      return false;
    }
    this.setState({ errorMessage: undefined, isPaymentInvalid: false });
    return true;
  }

  /**
   * Creates and returns a Claim for the given Bill.
   * @param {BillModel} bill The Bill the claim is for.
   * @param {List<BillItemModel>} billItems A list of bill items for the claim.
   * @param {number} timestamp The timestamp to set.
   * @returns {ClaimModel}
   */
  getClaim(bill: BillModel, billItems: List<BillItemModel>, timestamp: number): ClaimModel {
    const coveragePayorAmount = calculateCoveragePayorTotal(bill.attributes, billItems);
    return new ClaimModel({
      timestamp,
      amount: coveragePayorAmount,
      coverage_payor_id: bill.get('coverage_payor_id'),
      amount_due: coveragePayorAmount,
      status: 'unclaimed',
      patient_id: this.props.patient.get('_id'),
      bill_id: bill.get('_id'),
    });
  }

  /**
   * returns the updated list of transactions used for saving, from the bill items passed.
   * @param {List<BillItemModel>} billItems list of bill items
   * @param {number} timestamp timestamp of bill creation
   * @returns {List<TransactionModel>}
   */
  getTransactionsToSave(billItems: List<BillItemModel>, timestamp: number): List<TransactionModel> {
    return billItemsToTransactions(
      billItems,
      timestamp,
      this.props.inventoryCount,
      this.props.drugs,
    );
  }

  /**
   * Returns encounter model attributes for with completed status
   * @param {EncounterFlowModel} encounterFlow selected encounter flow
   * @param {string} doctor practioner id
   * @param {number} timestamp time at encounter is completed
   * @returns {Partial<EncounterAttributes>}
   */
  getEncounterAttributes(encounterFlow: EncounterFlowModel, doctor: string, timestamp: number) {
    return {
      flow: {
        name: encounterFlow.get('name'),
        stages: encounterFlow.get('stages', []).reduce((linkedStage: List<StageInfo>, stageId: string) => {
          const stageInfo = this.props.encounterStageMap?.get(stageId);
          if (stageInfo) {
            // @ts-ignore -> _name don't neds to be saved, hence ignoring
            return linkedStage.push({
              name: stageInfo.get('name'),
              stage_id: stageId,
              occurrences: [{
                events: [{ type: 'completed', time: timestamp }],
                doctor,
              }],
            });
          }
          debugPrint('Stage linked with flow not found');
          return linkedStage;
        }, List()).toArray(),
      },
      encounter_events: [
        { type: 'completed', time: timestamp },
      ],
    };
  }

  /**
   * Handles the saving of the bill and associated models.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    this.setState({ isSaving: true });
    if (this.isValid()) {
      const timestamp = combineDateAndTime(this.state.date, this.state.time).valueOf();
      const { encounterFlow, doctor } = this.state.encounterAttributes;
      const encounterAttributes = this.getEncounterAttributes(encounterFlow, doctor, timestamp);
      // Create Encounter, Bill models
      const encounter = new EncounterModel({ ...encounterAttributes, patient_id: this.props.patient.get('_id') }, true);
      const bill = new BillModel({ encounter_id: encounter.get('_id') });
      encounter.set('bill_id', bill.get('_id'));
      // Update bill items
      const billItems = this.state.billItems.map(billItem =>
        billItem
          .set({ bill_id: bill.get('_id') })
          .setTotal());
      // Set Bill attributes
      bill.set({
        is_finalised: true,
        timestamp,
        total_amount: calculateBillItemTotal(billItems),
      });
      bill.set(this.state.billAttributes);
      // Get TransactionModels
      const transactions = this.getTransactionsToSave(billItems, timestamp);
      let receivable: ReceivableModel;
      let payments: List<PaymentModel> = List();
      let claim: ClaimModel;
      // Create Payments and Receivables and add to modelsToSave if total is above 0.
      const patientTotal = calculatePatientTotal(bill.attributes, billItems);
      if (patientTotal > 0) {
        receivable = new ReceivableModel({
          patient_id: this.props.patient.get('_id'),
          bill_id: bill.get('_id'),
          amount: patientTotal,
          amount_due: patientTotal - sum(this.getActivePayments().map(p => parseFloat(p.get('amount')))),
          timestamp,
        });
        payments = this.getActivePayments().map(p => p.set({
          bill_id: bill.get('_id'),
          receivable_id: receivable.get('_id'),
          timestamp: p.get('timestamp', timestamp, false, false),
        }));
      }

      // Update and add claim if needed
      if (this.state.billAttributes.coverage_payor_id) {
        claim = this.getClaim(bill, billItems, timestamp);
      }
      return getBillSerialNumber()
        .then(serialNumber => bill.set('internal_clinic_id', serialNumber))
        .then(() => this.props.saveBillingRelatedModels(
          bill, transactions, billItems, encounter, payments, List([receivable]), List([claim]),
        ))
        .then(() => {
          if (this.state.updatedMedicalCertificate) {
            return this.state.updatedMedicalCertificate
              .setSerialNumber()
              .then(model => this.props.saveModel(model.set({ encounter_id: encounter.get('_id') })));
          }
          return Promise.resolve();
        })
        .then(() => {
          this.setState({ changesMade: false, errorMessage: undefined });
          transactions.forEach(model => this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change')));
          logBillFinalisation(
            billItems.filter(i => i.isPrescription()).size,
            billItems.filter(i => i.isSalesItem()).size,
            true,
          );
          createSuccessNotification(translate('bill_created'));
          location.hash = `/patient/${this.props.patient.get('_id')}/billing`;
          return true;
        });
    }
    this.setState({ isSaving: false });
    return Promise.resolve(false);
  }

  /**
   * Gets MC formatted for other components if one has been created.
   * @returns {(List<MedicalCertificateModel> | void)}
   */
  getMedicalCetificates(): List<MedicalCertificateModel> {
    return this.state.updatedMedicalCertificate ?
      List([this.state.updatedMedicalCertificate]) : List();
  }

  /**
   * Receives changes for a particular payment and passes that on to the parent container.
   * @param {string} paymentId The payment Id
   * @param {{}} changes The changes to passed to the PaymentModel.
   * @returns {void}
   */
  updatePayment(paymentId: string, changes: { [key: string]: MapValue }) {
    const updatedPayments = this.state.payments
      .map(p => (p.get('_id') === paymentId ? p.set(changes) : p));
    this.setState({ payments: updatedPayments, changesMade: true });
  }

  /**
* update transctions in state
* @returns {void}
*/
  updateTransaction = () => {
    const timestamp = combineDateAndTime(this.state.date, this.state.time).valueOf();
    const billItems = this.state.billItems.map(billItem =>
      billItem
        .setTotal());
    const transactions = this.getTransactionsToSave(billItems, timestamp);
    if (
      this.props.config.getIn(['inventory', 'blockDispensingNoStockDrugs'], false) &&
      !this.isStockAvailable(transactions)
    ) {
      this.setState({ errorMessage: translate('no_stock_to_dispense') });
    } else {
      this.setState({ showBillFinalisationSummary: true });
    }
  }
  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering AddNewBill');
    let coveragePayor;
    let coveragePayorPolicy;
    if (this.state.billAttributes.coverage_payor_id) {
      coveragePayor = this.props.coveragePayors
        .find(i => i.get('_id') === this.state.billAttributes.coverage_payor_id);
    }
    if (this.state.billAttributes.coverage_policy_id) {
      coveragePayorPolicy = this.state.billAttributes.coverage_policy_id;
    }
    const { encounterFlow } = this.state.encounterAttributes;
    // Create Encounter, Bill models
    const sideEncounter = new EncounterModel({ flow: { name: encounterFlow?.get('name'), flow_id: encounterFlow?.get('_id') } });
    return (
      <div className="o-main__content o-main__content--right-sidebar">
        <section>
          {
            this.state.showBillFinalisationSummary &&
            <BillFinalisationSummary
              onCancelClicked={() => this.setState({ showBillFinalisationSummary: false })}
              onFinaliseClicked={() => {
                this.setState({ showBillFinalisationSummary: false });
                this.onSaveClicked();
              }}
              discountsCharges={this.props.discountsCharges}
              billAttributes={this.state.billAttributes}
              isAddPastBill
              usedDiscountsCharges={List()} // change this to actual implementation when addPastBillSpec is done.
              encounter={sideEncounter} // We're just going to create a throw away model as this only used for displaying info.
              medicalCertificates={this.getMedicalCetificates()}
              timeChits={List()}
              documentData={List()}
              config={this.props.config}
              patient={this.props.patient}
              coveragePayor={coveragePayor}
              coveragePayors={this.props.coveragePayors}
              coveragePayorPolicy={coveragePayorPolicy}
              billItems={this.state.billItems}
              drugs={this.props.drugs}
              salesItems={this.props.salesItems}
              user={this.props.user}
              procedureTypes={this.props.procedureTypes}
              providers={this.props.providers}
              payments={this.getActivePayments()}
              documentTemplates={this.props.documentTemplates}
              practitioners={this.props.practitioners}
              encounterStageMap={this.props.encounterStageMap}
            />
          }
          <PatientHeader
            patient={this.props.patient}
            coveragePayors={this.props.coveragePayors}
            allergies={this.props.allergies}
            user={this.props.user}
            onHeightReady={height => this.setState({ patientHeaderHeight: height })}
            drugs={this.props.drugs}
          />
          <div
            id="bill-form-container"
            className="o-scrollable-container"
            style={{ height: `calc(100vh - ${this.state.patientHeaderHeight}px` }}
          >
            <h1 data-public className="o-title u-margin-left--1ws">{translate('add_past_bill')}</h1>
            <hr />
            <SavePrompt
              when={!isReferralMode() && this.state.changesMade}
              onSaveClicked={() => this.onSaveClicked()}
            />
            <BillMetadataForm
              config={this.props.config}
              encounterFlow={this.state.encounterAttributes.encounterFlow}
              date={this.state.date}
              doctor={this.state.encounterAttributes.doctor}
              time={this.state.time}
              practitioners={this.props.practitioners}
              encounterFlows={this.props.encounterFlows}
              setDate={date => this.setState({ date, changesMade: true })}
              setDoctor={doctor => this.setState({
                encounterAttributes: Object.assign({}, this.state.encounterAttributes, { doctor }),
                changesMade: true,
              })}
              setLocation={location => this.setState({
                encounterAttributes: Object.assign(
                  {},
                  this.state.encounterAttributes,
                  { location },
                ),
                changesMade: true,
              })}
              setTime={time => this.setState({ time, changesMade: true })}
              setEncounterFlow={async (encounterFlowId) => {
                // Add consult type to sales items each time it changes. This is not a perfect solution
                // as changing twice will result in two items, but it should be enough for now.
                const encounterFlowModel = this.props.encounterFlows.find(m => m.get('_id') === encounterFlowId);
                const relatedSalesItems = await getSalesItemsFromEncounterType(
                  this.props.salesItems, encounterFlowModel, this.props.encounterStageMap,
                );
                if (relatedSalesItems && relatedSalesItems.size) {
                  const billItems = relatedSalesItems.map(({ salesItem, quantity }) =>
                    salesItemToBillItem(salesItem, quantity, this.props.patient.get('_id')));
                  this.setState({
                    encounterAttributes: Object.assign(
                      {},
                      this.state.encounterAttributes,
                      { encounterFlow: encounterFlowModel },
                    ),
                    changesMade: true,
                    billItems,
                    payments: this.updatePaymentsToMatchAmount(
                      this.state.payments,
                      this.state.billAttributes,
                      billItems,
                    ),
                  });
                } else {
                  this.setState({
                    encounterAttributes: Object.assign(
                      {},
                      this.state.encounterAttributes,
                      { encounterFlow: encounterFlowModel },
                    ),
                    changesMade: true,
                  });
                }
              }}
            />
            <hr />
            <h1 data-public className="o-title">{translate('medical_certificate')}</h1>
            {
              this.state.updatedMedicalCertificate === undefined &&
              <MedicalCertificateFormModal
                modalId="billMedicalCertificate"
                modalTitle={translate('add_medical_certificate')}
                modalButtonLabel={translate('add_medical_certificate')}
                modalButtonClass="o-button o-button--small u-margin--standard"
                modalVisible={this.state.medicalCertificateModalVisible}
                isModalVisible={isVisible => this.setState({
                  medicalCertificateModalVisible: isVisible,
                })}
                explicitCloseOnly
                config={this.props.config}
                patientID={this.props.patient.get('_id')}
                encounterID="PLACEHOLDER_ID" // Make sure this gets updated when saving the MC
                initialDate={this.state.date.valueOf()}
                onSave={(updatedMedicalCertificate: MedicalCertificateModel) => {
                  this.setState({
                    medicalCertificateModalVisible: false,
                    updatedMedicalCertificate,
                    changesMade: true,
                  });
                  return Promise.resolve(updatedMedicalCertificate);
                }}
                dataPublicHeader
              />
            }
            {
              this.state.updatedMedicalCertificate !== undefined &&
              <div className="o-card">
                <h2 data-public className="o-card__title">{translate('issued_items')}</h2>
                <div className="o-data-list__row o-data-list__row--header">
                  <div className="o-data-list__row__item o-data-list__row__item--small">{translate('item_issued')}</div>
                  <div className="o-data-list__row__item">{translate('description')}</div>
                  <div className="o-data-list__row__actions" />
                </div>
                <IssuedItemsList
                  config={this.props.config}
                  medicalCertificates={this.getMedicalCetificates()}
                  documentData={List()}
                  timeChits={List()}
                  saveModel={this.props.saveModel}
                  user={this.props.user}
                  documentTemplates={this.props.documentTemplates}
                />
              </div>
            }
            <hr />
            {
              this.state.errorMessage &&
              <div className="o-card u-no-shadow">
                <FormError containerElementID="bill-sales-items">
                  {this.state.errorMessage}
                </FormError>
              </div>
            }
            <BillFormItemList
              billItems={this.state.billItems}
              coveragePayorID={this.state.billAttributes.coverage_payor_id}
              drugs={this.props.drugs}
              salesItems={this.props.salesItems}
              coveragePayors={this.props.coveragePayors}
              saveModel={this.props.saveModel}
              updateBillItems={billItems => this.setState({
                billItems,
                changesMade: true,
                payments: this.updatePaymentsToMatchAmount(
                  this.state.payments,
                  this.state.billAttributes,
                  billItems,
                ),
              })}
              inventoryCount={this.props.inventoryCount}
              config={this.props.config}
              patientID={this.props.patient.get('_id')}
              procedureTypes={this.props.procedureTypes}
              providers={this.props.providers}
              disabled={this.state.isSaving}
              updateConfigValue={this.props.updateConfigValue}
              user={this.props.user}
              updateConfig={this.props.updateConfig}
              drugDurations={this.props.drugDurations}
            />
            <div className="u-margin-bottom--6ws" />
          </div>
        </section>
        <BillSidebar
          {...this.props}
          isFromAddPastBill
          encounter={sideEncounter} // We're just going to create a throw away model as this only used for displaying info.
          billAttributes={this.state.billAttributes}
          billItems={this.state.billItems}
          coveragePayor={coveragePayor}
          coveragePayorPolicy={coveragePayorPolicy}
          onBillAttributesChanged={billAttributes =>
            this.setState({
              billAttributes,
              changesMade: true,
              payments: this.updatePaymentsToMatchAmount(
                this.state.payments,
                billAttributes,
                this.state.billItems,
              ),
            })}
          onCoveragePayorChanged={this.onCoveragePayorChanged}
          medicalCertificates={List()}
          onFinaliseClicked={(updatedBillItems: List<BillItemModel>, isChanged?: boolean) => {
            if (this.isValid()) {
              if (isChanged) {
                this.setState({
                  billItems: updatedBillItems,
                  payments: this.updatePaymentsToMatchAmount(
                    this.state.payments,
                    this.state.billAttributes,
                    updatedBillItems,
                  ),
                }, () => this.updateTransaction());
              } else {
                this.updateTransaction();
              }
            }
          }}
          procedureTypes={this.props.procedureTypes}
          timeChits={List()}
          prescriptions={List()}
          isSaving={this.state.isSaving}
          addNewPayment={() => this.setState({
            payments: this.state.payments.push(
              this.getNewPayment(getPatientOwedAmount(
                this.state.billAttributes,
                this.getActiveBillItems(),
                this.getActivePayments(),
              )),
            ),
            changesMade: true,
          })}
          payments={this.getActivePayments()}
          updatePayment={(paymentId, changes) => this.updatePayment(paymentId, changes)}
          isPaymentInvalid={this.state.isPaymentInvalid}
          isFinaliseButtonBusy={!this.state.isBatchReadyForDispensation}
          isOnline={this.props.isOnline}
          verifiedDrugs={listToMap(this.props.verifiedDrugs, (i: InventoryMapModel) => i.get('drug_id'))}
          allergies={this.props.allergies.filter(m => m.isFlagged())}
          discountsCharges={List()}
          isAddPastBill
          usedDiscountsCharges={List()} // change this to actual implementation when addPastBillSpec is done.
        />
        <Confirm
          show={Boolean(this.state.confirmPriceChangeModalContent)}
          cancel={() => this.setState({ confirmPriceChangeModalContent: undefined })}
          proceed={() => this.setState({ confirmPriceChangeModalContent: undefined })}
          confirmation={this.state.confirmPriceChangeModalContent}
          modalTitle={translate('price_updated')}
          footerSaveButtonName={translate('ok').toUpperCase()}
          hideCancel
        />
      </div>
    );
  }
}

export default AddNewBill;
