import BaseModel from './baseModel';

import { prettifyDate } from './../utils/time';
import translate from './../utils/i18n';
import { getMCSerialNumber } from './../utils/serialNumbers';
import { UNICODE } from './../constants';

type Attributes = {
  _id: string,
  practitioner_id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'medical_certificate',
  timestamp: number,
};

/**
   * MedicalCertificateModel
   *
   * @namespace MedicalCertificateModel
   */
class MedicalCertificateModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'medical_certificate';
  }

  /**
   * Returns the timestamp to use for this MC. Tries timestamp field then falls back to
   * create_by.timestamp
   * @returns {number}
   */
  getTimestamp(): number {
    return this.has('timestamp') ? this.get('timestamp') : this.getCreatedTime();
  }

  /**
   * Returns a prettified version of 'start_date' or an em dash if not set.
   * @returns {string} Date for the start of the medical certificate.
   */
  getStartDate(): string {
    const date = this.has('start_date') ? this.get('start_date') : this.getTimestamp();
    return prettifyDate(date);
  }

  /**
   * Returns a prettified version of the end date of this MC or an em dash if not set.
   * @returns {string} A date representing the end of this MC.
   */
  getEndDate(): string {
    const timestamp = this.has('start_date') ? this.get('start_date') : this.getTimestamp();
    const mathCielDays = Math.ceil(this.get('days'));
    const dateToAdd = (mathCielDays <= 1) ? 0 : (mathCielDays - 1);
    const convertedDate = timestamp + (86400000 * dateToAdd);
    return prettifyDate(convertedDate);
  }

  /**
   * Returns the description for this Medical Certificate, containing the reason for the MC and the
   * number of days it is for.
   * @returns {string} The MC description.
   */
  getDescription(): string {
    const dayLabel = this.get('days') > 1 ? translate('days') : translate('day');
    const reason = this.get('reason') ? `${this.get('reason')},` : UNICODE.EMDASH;
    const dateDetails = !(this.get('days') || this.get('days') === 0) ? UNICODE.EMDASH : `${this.get('days')} ${dayLabel} ${translate('from')} ${this.getStartDate()} ${translate('to')} ${this.getEndDate()}`;
    return `${reason} ${dateDetails}`;
  }

  /**
   * Sets the serial number for this MC. This is an async function has it may have to interact with
   * the remote DB. It is a no-op if a serial number has already been assigned
   * @returns {Promise} A promise that returns this model.
   */
  setSerialNumber(): Promise<MedicalCertificateModel> {
    if (this.has('internal_clinic_id')) {
      return Promise.resolve(this);
    }
    return getMCSerialNumber()
      .then(serialNo => this.set('internal_clinic_id', serialNo));
  }
}

export default MedicalCertificateModel;
