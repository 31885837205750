import moment from 'moment';

import { getConfig } from './utils';

import type { Model } from './../types';

/**
 * Compares two strings by case-insensitive alphabetical order.
 * @param {string} a The first item to compare.
 * @param {string} b The second item to compare.
 * @return {number} A comparison of the two strings returned as -1, 0, or 1.
 */
export const compareByAlphabeticalOrder = (a: string = '', b: string = ''): number =>
  a.toLowerCase().localeCompare(b.toLowerCase());

/**
 * Compares two strings by numerical order. If no item is given or the item is not a number then
 * it is set as -1.
 * @param {string} a The first item to compare.
 * @param {string} b The second item to compare.
 * @return {number} A comparison of the two strings returned as -1, 0, or 1.
 */
export function sortByNumber(a: string | number | void, b: string | number | void): -1 | 0 | 1 {
  const convertedA = isNaN(a) ? -1 : parseFloat(a);
  const convertedB = isNaN(b) ? -1 : parseFloat(b);
  if (convertedA < convertedB) {
    return -1;
  } else if (convertedA === convertedB) {
    return 0;
  }
  return 1;
}


/**
 * Strips a payment string of the currency so it can be compared as a number.
 * @param {string} a The first item to compare.
 * @param {string} b The second item to compare.
 * @return {number} A comparison of the two strings returned as -1, 0, or 1.
 */
export function sortByPayment(a: string, b: string): -1 | 0 | 1 {
  const label = getConfig().get('currency_shorthand_label', 'RM');
  if (a.replace && b.replace) {
    const aValue = a.replace(new RegExp(`${label}|,`, 'gi'), '');
    const bValue = b.replace(new RegExp(`${label}|,`, 'gi'), '');
    // Needed for multiLine Content
    return sortByNumber(
      a.indexOf('\n') !== -1 ? aValue.split('\n').reduce((c, d) => parseFloat(c) + parseFloat(d), 0.00).toString() : aValue,
      b.indexOf('\n') !== -1 ? bValue.split('\n').reduce((c, d) => parseFloat(c) + parseFloat(d), 0.00).toString() : bValue,
    );
  }
  return sortByNumber(
    // This is a bit weird, but for some reason the table in patientBillingHistory.jsx threw an
    // error one time with replace being undefined. My gut feeling is that the source error is in
    // ReactTable, but i can't do anything so i'm just going to check for a replace function and if
    // it is not found just return the original "string" (which is properly not a string for some
    // reason) - LH (03/01/18).
    a.replace ? a.replace(new RegExp(`${label}|,`, 'gi'), '') : a,
    b.replace ? b.replace(new RegExp(`${label}|,`, 'gi'), '') : b,
  );
}

/**
 * Comparator to compare two value who are of type Array<number | null> by summing up.
 * @param {string} a The first item to compare.
 * @param {string} b The second item to compare.
 * @return {number} Return -1, 0, or 1.
 */
export function sortBySumOfElements(a: Array<number | null>, b: Array<number | null>): number {
  const convertedA = !Array.isArray(a) ? -1 : a.reduce((c, d) => c + d, 0);
  const convertedB = !Array.isArray(b) ? -1 : b.reduce((c, d) => c + d, 0);
  if (convertedA < convertedB) {
    return -1;
  } else if (convertedA === convertedB) {
    return 0;
  }
  return 1;
}

// THIS FUNCTION BELOW IS SUPER SLOW FOR SOMsE REASON
/**
 * Compare two date strings. The strings will be run through moment to find the actual date.
 * @param {string} a The first item to compare.
 * @param {string} b The second item to compare.
 * @returns {number} A comparison of the two strings returned as -1, 0, or 1.
 */
export function sortByDate(a: string, b: string): -1 | 0 | 1 {
  if (moment(a).isBefore(moment(b))) {
    return -1;
  } else if (moment(a).isSame(moment(b))) {
    return 0;
  }
  return 1;
}

/**
 * Sorts models by their order value
 * @param {Model} a Model A
 * @param {Model} b Model B
 * @returns {number}
 */
export function sortModelByOrder(a: Model, b: Model): number {
  return sortByNumber(a ? a.get('order') : undefined, b ? b.get('order') : undefined);
}
