
/**
 * returns valid cookie value for a device/browser.
 * @returns {string}
 */
export function getDeviceIdCookie(): string {
  return document.cookie ?
    document.cookie.replace(/(?:(?:^|.*;\s*)deviceIdentifier\s*\=\s*([^;]*).*$)|^.*$/, // eslint-disable-line no-useless-escape
      '$1') : '';
}

/**
 * sets cookie value for device identifier.
 *  @param {string} cookieValue value for deviceIdentifier cookie
 * @returns {void}
 */
export function setDeviceIdCookie(cookieValue: string) {
  document.cookie =
    `deviceIdentifier=${cookieValue}; expires=Fri, 1 Jan 2038 23:59:59 GMT; path=/`;
}

/**
 * Checks for provided cookie to determine if the corresponding popups needs to be shown.
 * This function sets the cookie with the userId if not found.
 * Return true if userId is found in cookie else false.
 * @param {string} userId UserId of the logged in user.
 * @param {string} cookieName name of cookie to check and set if not existing
 * @returns {boolean} Returns true if cookie not existing and false otherwise.
 */
export function checkAndSetCookieForPopup(userId: string, cookieName: string): boolean {
  const regexString = `(?:(?:^|.*;\\s*)${cookieName}\\s*\\=\\s*([^;]*).*$)|^.*$`;
  const regexp = RegExp(regexString);
  const cookieValue = document.cookie
    .replace(regexp, '$1')
    .substr(1)
    .slice(0, -1)
    .split(',')
    .filter(c => c);
  if (!cookieValue || !cookieValue.includes(userId)) { // There is no existing cookie or the logged in user's id is not present in the cookie.
    const shownToUsers = [...cookieValue];
    shownToUsers.push(userId);
    document.cookie = `${cookieName}=[${shownToUsers.toString()}]`; //  document cookie is modified to include the userId, so that we don't show the popup to the same user again.
    return true;
  }
  return false;
}
