import * as React from 'react';
import { List, Map } from 'immutable';
import { differenceBy, isEqual } from 'lodash';
import glamorous from 'glamorous';
import translate from './../../utils/i18n';
import { wsUnit } from './../../utils/css';
import { updateClinicConfig } from './../../utils/db';
import { createPermission, hasPermission } from './../../utils/permissions';
import SortableList from './../layout/sortableList';
import Header from './../header/header';
import { CONFIG } from '../../constants';

import type { Config, User, MapValue } from './../../types';

type Props = {
  config: Config,
  user: User,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
};

type Card = {
  name: string,
  show: boolean,
};

type State = {
  disableEdit: boolean,
  patientPageCards: Array<Card>,
  config: Config,
};

const CardRow = glamorous.div({
  padding: `0 calc(${wsUnit} / 4)`,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const CardRowCheckbox = glamorous.input({
  marginLeft: 'auto',
  marginRight: `calc(${wsUnit} * 2.5) !important`,
});

/**
 * PatientPageSettings
 * @namespace PatientPageSettings
 */
class PatientPageSettings extends React.PureComponent<Props, State> {
  /**
   * Creates an instance of PatientPageSettings.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      disableEdit: !hasPermission(this.props.user, List([createPermission('clinic_settings', 'update')])),
      patientPageCards: this.props.config.getIn(['patient_page_cards'], List()).toArray()
        .map(item => item.toJS()),
      config: this.props.config,
    };
  }

  /**
   * Reset values when config value changes.
   * @param {Props} nextProps Next props
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.config.equals(nextProps.config)) {
      this.setState({
        config: nextProps.config,
        patientPageCards: nextProps.config.getIn(['patient_page_cards'], List()).toArray()
          .map(item => item.toJS()),
      });
    }
  }


  /**
   * Check for new cards that should be added to the config
   * @returns {void}
   */
  componentDidMount() {
    const cardsToAdd = differenceBy(CONFIG.PATIENT_PAGE_CARDS, this.state.patientPageCards, 'name');
    const cardsToRemove = differenceBy(this.state.patientPageCards, CONFIG.PATIENT_PAGE_CARDS, 'name');
    const newPatientCardConfig = differenceBy(this.state.patientPageCards, cardsToRemove, 'name').concat(cardsToAdd);
    if (!isEqual(this.state.patientPageCards, newPatientCardConfig)) {
      this.updateConfig(newPatientCardConfig);
    }
  }

  /**
   * Updates settings/config selection status in local state and also in db.
   * @param {Array<Card>} newValue The new value.
   * @returns {void}
   */
  updateConfig(newValue: Array<Card>): void {
    this.setState({
      config: this.state.config.setIn(['patient_page_cards'], newValue),
    });
    updateClinicConfig(
      this.props.config.toJS(),
      Map().setIn(['patient_page_cards'], newValue).toJS(),
      this.props.updateConfig,
    )
      .then(() => {
        this.props.updateConfigValue(['patient_page_cards'], newValue);
      });
  }

  /**
   * called when checkbox value is changed for any card
   * @param {string} name name of card.
   * @param {boolean} isChecked to indicate checkbox is checked.
   * @returns {void}
   */
  onValueChanged(name: string, isChecked: boolean): void {
    const newCards = this.state.patientPageCards.map((c) => {
      const card = c;
      if (c.name === name) {
        card.show = isChecked;
      }
      return card;
    });
    this.setState({
      patientPageCards: newCards,
    });
    this.updateConfig(newCards);
  }

  /**
   * sort handler to be passed to sortable list component, rearranges array of cards as per latest sort.
   * @param {MapValue} funcArg object with old and new index.
   * @returns {void}
   */
  onSort = (funcArg: { oldIndex: number, newIndex: number}): void => {
    if (!this.state.disableEdit) {
      const newCards = this.state.patientPageCards.slice();
      newCards.splice(funcArg.newIndex, 0, newCards.splice(funcArg.oldIndex, 1)[0]);
      this.setState({
        patientPageCards: newCards,
      });
      this.updateConfig(newCards);
    }
  }

  /**
   * Returns array of HTML items to be passed to sortable list
   * @returns {Array<React.Node>}
   */
  getCardItems(): Array<React.Node> {
    return this.state.patientPageCards.map(i => (
      <CardRow>
        <div>{translate(i.name)}</div>
        <CardRowCheckbox
          disabled={this.state.disableEdit}
          checked={i.show}
          id={`card_${i.name}`}
          key={i.name}
          name={i.name}
          type="checkbox"
          value={i.show}
          onChange={event =>
            this.onValueChanged(i.name, event.target.checked)
          }
        />
      </CardRow>
    ));
  }

  /**
   * Renders the component.
   * @return {string} - HTML markup for the component
   */
  render() {
    return (
      <div>
        <Header className="o-header" dataPublic>
          <h1>{translate('patient_page_settings')}</h1>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('patient_page_cards')}</h2>
          <div className="o-data-list__row o-data-list__row--header">
            <div className="o-data-list__row__item">
              {translate('card_name')}
            </div>
            <div className="o-data-list__row__item u-flex-align-right">
              {translate('show_on_page')}
            </div>
          </div>
          <SortableList
            items={this.getCardItems()}
            onSortEnd={this.onSort}
          />
        </div>
      </div>
    );
  }
}

export default PatientPageSettings;
