import { connect } from 'react-redux';
import { Map, List } from 'immutable';

import PaymentsOutstanding from './../components/payments/paymentsOutstanding';
import { saveModelsFactory, saveFactory } from './../utils/redux';
import AsyncFetch from './../components/asyncFetch';
import { getOutstandingPaymentsDataViews } from './../dataViews';
import { setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, setCurrentDataViewsError } from './../actions';

import type { Dispatch, State, DataView } from './../types';
import type APIError from '../utils/apiError';
import type BaseModel from '../models/baseModel';
import { roundFloat } from '../utils/utils';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  isFetching, currentDataViews, currentDataViewsModels, patientStubs, user, paymentTypes,
  currentDataViewsError,
  config,
}: State) => ({
  isFetching,
  containerDataViews: getOutstandingPaymentsDataViews(),
  currentDataViews,
  receivables: currentDataViewsModels.filter(
    m => m.get('type') === 'receivable' && !m.isVoid() && roundFloat(m.get('amount_due'), 0, 2) > 0, // Isvoid  and amount check is only needed for locally changed files - the couch view also filters out void.
  ),
  patientStubsMap: Map(patientStubs.map(patientStub => [patientStub.get('_id'), patientStub])),
  billsMap: Map(currentDataViewsModels.filter(m => m.get('type') === 'bill').map(bill => [bill.get('_id'), bill])),
  user,
  paymentTypes: paymentTypes.filter(m => m.isVisible()),
  currentDataViewsError,
  config,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModels: saveModelsFactory(dispatch),
  saveModel: saveFactory(dispatch),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const PaymentsOutstandingContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(PaymentsOutstanding));

export default PaymentsOutstandingContainer;
