import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import SalesItemForm from '../components/salesItems/salesItemForm';
import { saveFactory, saveModelsFactory } from '../utils/redux';
import type { Dispatch, State, Config } from '../types';
import type BaseModel from '../models/baseModel';
import { updateConfigValue, updateConfig } from './../actions';


interface OwnProps extends RouteComponentProps {
    isFromModal: boolean,
    docId?: string,
    isSaving: boolean,
    referrerDoc: BaseModel,
    onSaveAtValidationModal: (wasSuccessful: boolean) => void,
}

/**
 * @param {Object} state Current app state.
 * @param {object} ownProps The props passed to this container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const {
    user,
    config,
    coveragePayors,
    salesItems,
  } = state;
  const modelToEdit = salesItems.find(i => i.get('_id') === ownProps.docId);
  return {
    user,
    config,
    coveragePayors,
    modelToEdit,
  };
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const AddSalesItemContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(SalesItemForm);

export default AddSalesItemContainer;
