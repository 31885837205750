import React, { useState } from 'react';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import glamorous, { I, Ul } from 'glamorous';

import Input from '../inputs/input';
import Button from '../buttons/button';
import SortableList from '../layout/sortableList';
import SaveButton from '../buttons/saveButton';
import FormError from '../formError';
import StatelessModal from '../modals/statelessModal';
import ModalFooter from '../modals/modalFooter';
import Select from '../../components/inputs/select';

import { ListItem, DragIcon } from '../../utils/layout';
import PermissionWrapper from '../permissions/permissionWrapper';
import { createPermission, hasSomePermission } from '../../utils/permissions';
import { wsUnit, border, colours } from '../../utils/css';
import { getConfirmation } from '../../utils/utils';
import AddFlowStage from './addFlowStage';
import translate from '../../utils/i18n';
import { getStageNamesWithSuffix, isEncounterFlowEqual, mergeStages } from '../../utils/encounters';
import { UNICODE } from '../../constants';
import EncounterFlowModel from '../../models/encounterFlowModel';
import EncounterModel, { StageInfo } from '../../models/encounterModel';

import type { Config, SaveModel, User } from '../../types';
import type SalesItemModel from '../../models/salesItemModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import type CoveragePayorModel from '../../models/coveragePayorModel';
import type AppointmentModel from '../../models/appointmentModel';

const CUSTOM_FLOW = 'CUSTOM_FLOW';

const CardRow = glamorous.div({
  padding: `10 calc(${wsUnit} / 4)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  minHeight: 47,
});

const CloseIcon = glamorous.i({
  color: colours.grey3,
  transition: 'color .2s eas-in',
  cursor: 'pointer',
  '&:hover': {
    color: colours.grey5,
  },
});

const SortableListContainer = glamorous.div({
  display: 'block',
  '> ul > li': {
    border,
    borderRadius: 4,
    marginTop: `calc(${wsUnit} / 2)`,
  },
});

type Props = {
  user: User;
  config: Config;
  updateConfig: (config: Config) => void;
  stagesMap: Map<string, EncounterStageModel>;
  salesItems: Map<string, SalesItemModel>;
  saveModel: SaveModel;
  coveragePayors: List<CoveragePayorModel>;
  isVisible: boolean;
  hideModal: () => void;
  selectedFlow?: EncounterFlowModel;
  currentEncounterFlow?: EncounterFlowModel;
  encounterFlowMap?: Map<string, EncounterFlowModel>;
  onSave?: (encounter: EncounterModel, encounterFlow: EncounterFlowModel,
    newFlowId: string) =>
    Promise<any>;
  encounter?: EncounterModel;
  onDelete?: () => void;
  /* Either appointment/encounter should be passed to this Component */
  appointment?: AppointmentModel;
  /* If true, allows all stages to be sorted even if encounter or apointment is passed.
  Used in addEncounterForm to create a missed encounter doc */
  editable?: boolean;
}

type FlowAttributeStage = {name: string, stageId: string, unsuffixed: string};

type FlowAttributes = {
  name: string,
  flowId: string,
  stages: List<FlowAttributeStage>,
};

/**
 * Form component used for creating and editing encounter flow in settings and
 * edit flow for encounter/appointment from overview page
 * @param {Props} props component props
 * @returns {React.SFC}
 */
const FlowForm = (props: Props) => {
  /**
   * Returns Flowattributes for showing initial value
   * @returns {Object}
   */
  const getInitialFlowAttributes = (): FlowAttributes => {
    if (props.encounter) {
      const stageAttributes = props.encounter?.getActiveStages().reduce(
        (stages, stage) => stages.push({
        // eslint-disable-next-line no-underscore-dangle
          name: stage._name,
          stageId: stage.stage_id,
          unsuffixed: stage.name,
        }), List());
      return {
        name: props.encounter.getEncounterType(props.salesItems?.toList()),
        flowId: props.encounter.get('flow')?.flow_id,
        stages: stageAttributes,
      };
    }
    return {
      name: props.selectedFlow?.get('name') ?? '',
      flowId: props.selectedFlow?.get('flow')?.flow_id,
      stages: getStageNamesWithSuffix(props.selectedFlow?.getStages(props.stagesMap) || List()),
    };
  };

  const [flowAttributes, setFlowAttributes] = useState<FlowAttributes>(getInitialFlowAttributes());
  const [formError, setFormError] = useState('');
  const [stageFormVisible, setStageFormVisible] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // Used to avoid showing confirm modal to already confirmed actions.
  const [confirmedActions, setConfirmedActions] =
    useState<List<'edit_flow' | 'change_flow' | 'stage_remove'>>(List());
  const [newFlowId, setNewFlowId] = useState(''); // stores changed _id of selected encounter flow
  const [encounter, setEncounter] = useState(props.encounter
    ? new EncounterModel(Object.assign({}, props.encounter.attributes))
    : undefined);

  /**
   * Returns list of uneditable stages.
   * Note: Skipped stages should also be treated as uneditable
   * @param {EncounterModel} encounterModel selected encounter
   * @returns {List<FlowAttributeStage>}
   */
  const getUneditableStageAttrs = memoizeOne((encounterModel?: EncounterModel) => {
    if (!encounterModel) return List();
    const { currentStageIndex } = encounterModel.getCurrentStageIndex();
    return encounterModel.getActiveStages().reduce((stageAttr, stage, index) => {
      if (index <= currentStageIndex) {
        const isEditableStage = stage.occurrences.some(occ => occ.events[occ.events.length - 1]?.type === 'arrived' || occ.events[occ.events.length - 1]?.type === 'cancelled');
        if (!isEditableStage) {
          return stageAttr.push({
            name: stage._name,
            stageId: stage.stage_id,
            unsuffixed: props.stagesMap.get(stage.stage_id)?.get('name'),
          });
        } return stageAttr;
      } return stageAttr;
    }, List());
  });

  // Flow cannot be edited but can be changed in case of appointment
  const uneditableStages: List<FlowAttributeStage> = props.appointment
    ? flowAttributes.stages
    : !newFlowId
      ? props.editable
        ? List()
        : getUneditableStageAttrs(props.encounter)
      : List();

  const categorisedStages = {
    uneditable: uneditableStages,
    sortable: flowAttributes.stages.filter(s =>
      !uneditableStages.some(uneditable =>
        uneditable.name === s.name)),
  };

  /**
   * Set current encounter flow name to Custom Flow onChange of flow attributes
   * @param {FlowAttributes} updatedFlowAttributes FlowAttributes
   * @return {void}
   */
  const setFlowEdited = (updatedFlowAttributes: FlowAttributes) => {
    if (!formDirty) {
      setFormDirty(true);
    }
    setFlowAttributes(
      Object.assign({}, updatedFlowAttributes),
    );
  };

  /**
   * Adds passed stage to the flow
   * @param {EncounterStageModel} stage Selected stage to add
   * @returns {void}
   */
  const addStage = (stage: EncounterStageModel) => {
    const existingSameStages = flowAttributes.stages.filter(s => s.stageId === stage.get('_id'));
    const newStage = {
      name: existingSameStages.size > 0 ? `${stage.get('name')} ${existingSameStages.size + 1}` : stage.get('name'),
      stageId: stage.get('_id'),
      unsuffixed: stage.get('name'),
    };
    const updatedFlowAttributes = {
      flowId: encounter ? CUSTOM_FLOW : flowAttributes.flowId,
      name: encounter ? translate('custom_flow') : flowAttributes.name,
      stages: flowAttributes.stages.push(newStage),
    };
    setStageFormVisible(false);
    setFlowEdited(updatedFlowAttributes);
    if (encounter) {
      const newStages = encounter.getStages().push({
        name: stage.get('name'),
        stage_id: stage.get('_id'),
        occurrences: [],
        _name: stage.get('name'),
      }).toArray();
      setEncounter(encounter?.set('flow', Object.assign({}, updatedFlowAttributes, { stages: newStages })));
    }
  };

  /**
   * Removes passed stage from the flow
   * @param {FlowAttributeStage} stage Selected stage to add
   * @returns {void}
   */
  const removeStage = (stage: FlowAttributeStage) => {
    const updatedFlowAttributes = {
      flowId: encounter ? CUSTOM_FLOW : flowAttributes.flowId,
      name: encounter ? translate('custom_flow') : flowAttributes.name,
      stages: flowAttributes.stages.filter(s =>
        !(s.stageId === stage.stageId && s.name === stage.name)),
    };
    /**
     * Update states after removing stage
     * @returns {void}
     */
    const remove = () => {
      setStageFormVisible(false);
      setFlowEdited(updatedFlowAttributes);
      const encounterStagesAfterRemoval = encounter?.getActiveStages().reduce((stages, s) => {
        if (s.stage_id === stage.stageId && s._name === stage.name) {
          const now = Date.now();
          return stages.push({
            ...s,
            name: s.name,
            occurrences: s.occurrences?.length
              ? s.occurrences.map(occurrence => ({
                ...occurrence,
                events: occurrence.events.concat({ type: 'cancelled', time: now }),
              })) : [{ events: [{ type: 'cancelled', time: now }] }],
          });
        }
        return stages.push(s);
      }, List()).toArray();
      setEncounter(encounter?.set('flow', Object.assign({}, updatedFlowAttributes,
        { stages: encounterStagesAfterRemoval })));
    };
    if (encounter && !confirmedActions.includes('stage_remove')) {
      const modalTitle = translate('remove_x_from_flow', { x: stage.name });
      const confirmation = translate('remove_stage_warning');
      return getConfirmation(confirmation, {
        modalTitle,
        footerSaveButtonName: translate('remove'),
        proceedButtonClassName: 'o-button--danger',
        cancelButtonClassName: 'o-button',
      }).then(remove);
    }
    return remove();
  };

  /**
   *
   * @param {string} fieldName Key in stageAttributes
   * @param {string | Array<string> | boolean} value New value to update
   * @returns {void}
   */
  const handleChange = (fieldName: string, value: string | Array<string> | boolean) => {
    const updatedFlowAttributes = Object.assign({}, flowAttributes, { [fieldName]: value });
    setFlowEdited(updatedFlowAttributes);
  };

  /**
   * Handles encounter flow change. Flow can be changed/edited from option button in encounter tables.
   * @param {string} flowId selected encounter flow _id
   * @returns {void}
   */
  const handleFlowChange = (flowId: string) => {
    const flowName = props.encounterFlowMap?.get(flowId)?.get('name');
    /**
     * Updates state with new encounter flow
     * @returns {void}
     */
    const changeFlow = () => {
      setFormDirty(true);
      setFlowAttributes({
        flowId,
        name: flowName,
        stages: getStageNamesWithSuffix(
          props.encounterFlowMap?.get(flowId)?.getStages(props.stagesMap) ||
            List(),
        ),
      });
      setConfirmedActions(actions => actions.push('change_flow'));
      if ((encounter || props.appointment) &&
          !isEncounterFlowEqual(encounter, props.encounterFlowMap?.get(flowId))) {
        setNewFlowId(flowId);
      }
    };
    if (flowAttributes.name === UNICODE.EMDASH || confirmedActions.includes('change_flow')) {
      changeFlow();
    } else {
      const modalTitle = translate('change_to_x', { x: flowName });
      const content = translate('change_flow_warning', { flow_name: flowName });
      getConfirmation(content, {
        modalTitle,
        footerSaveButtonName: translate('change'),
        proceedButtonClassName: 'o-button--danger',
        cancelButtonClassName: 'o-button',
        unsetWidth: true,
      }).then(changeFlow);
    }
  };

  /**
   * Saves the models
   * @returns {void}
   */
  const handleSave = () => {
    const flowModelAttributes = {
      name: flowAttributes.name,
      stages: flowAttributes.stages.map(stage => stage.stageId).toArray(),
    };
    let flowModel: EncounterFlowModel;
    if (props.selectedFlow && !props.appointment) { // Editing flow from settings page
      flowModel = props.selectedFlow.replaceAtrributes(flowModelAttributes);
    } else if (props.encounter) { // Editing patient flow
      flowModel = new EncounterFlowModel({ ...flowModelAttributes, _id: newFlowId || encounter?.get(['flow', 'flow_id']) });
    } else {
      flowModel = new EncounterFlowModel(flowModelAttributes);
    }
    setIsSaving(true);
    if (props.onSave && (encounter || props.appointment)) {
      if (encounter) {
        let updatedStages = encounter.getActiveStages();
        const isEveryStagePending = updatedStages.every(stage => !stage.occurrences.length);
        // If the 1st stage is removed (`cancelled`) on editing, move the patient to the next stage's waiting room
        const firstStage = updatedStages.get(0);
        if (isEveryStagePending || !firstStage?.occurrences.length) {
          updatedStages = updatedStages.set(0, {
            ...updatedStages.get(0) as StageInfo,
            occurrences: [{
              events: [{ type: 'arrived', time: Date.now() }],
            }],
          });
        }
        encounter.set('flow', {
          ...encounter.get('flow'),
          stages: mergeStages(encounter.getStages(), updatedStages),
        });
      }
      return props.onSave(encounter, flowModel, newFlowId);
    }
    return props.saveModel(flowModel).then(() => {
      props.hideModal();
      setIsSaving(false);
    });
  };

  /**
   * Validates required field and save stage
   * @returns {void}
   */
  const onSave = () => {
    if (flowAttributes.name && flowAttributes.stages.size) {
      // Checking for existing encounter flow with same name when called from Flow settings page (Create/edit flow)
      if (props.selectedFlow?.get('name') !== flowAttributes.name && !props.appointment && !props.encounter && props.encounterFlowMap?.toList().find(ef => ef.get('name') === flowAttributes.name)) {
        return setFormError(translate('flow_name_already_exists'));
      }
      if (newFlowId) {
        // Shows the confirmation modal again in case of encounter flow change
        const modalTitle = `${translate('confirmation')} - ${translate('change_to_x', { x: flowAttributes.name })}`;
        const content = translate('change_flow_warning', { flow_name: flowAttributes.name });
        return getConfirmation(content, {
          modalTitle,
          footerSaveButtonName: translate('change'),
          proceedButtonClassName: 'o-button--danger',
          cancelButtonClassName: 'o-button',
          unsetWidth: true,
        }).then(handleSave);
      }
      return handleSave();
    }
    setFormError(translate('fill_required_fields'));
  };

  /**
   * Called on Create/Edit sales item Form's onClose
   * @returns {void}
   */
  const handleFormClose = () => {
    if (!formError && formDirty) {
      setFormError(translate('unsaved_changes_error'));
    } else {
      props.hideModal();
    }
  };

  /**
   * sort handler to be passed to sortable list component, rearranges array of stages as per latest sort.
   * @param {MapValue} funcArg object with old and new index.
   * @returns {void}
   */
  const onSort = (funcArg: { oldIndex: number, newIndex: number}): void => {
    const newStages = categorisedStages.sortable.slice().toArray();
    newStages.splice(funcArg.newIndex, 0, newStages.splice(funcArg.oldIndex, 1)[0]);
    const updatedAttributes = {
      flowId: encounter ? CUSTOM_FLOW : flowAttributes.flowId,
      name: encounter ? translate('custom_flow') : flowAttributes.name,
      stages: categorisedStages.uneditable.concat(List(newStages)),
    };
    if (encounter) {
      const encounterStages = encounter.getStages();
      const sortedStages = newStages.reduce((sorted, stage) => {
        const activeEncounterStage = encounterStages.find(s =>
          s.stage_id === stage.stageId && s._name === stage.name);
        if (activeEncounterStage) return sorted.push(activeEncounterStage);
        return sorted.push(stage);
      }, List());
      // Cancelled stages is not considered for sorting, hence appending it to the stages after sort.
      const cancelledStages = props.encounter?.getStages().filter(stage =>
        stage.occurrences?.length && stage.occurrences.some(occ =>
          occ.events?.some(event => event.type === 'cancelled')));
      // Completed stages are not passed to SortableListContainer.
      const completedStages = categorisedStages.uneditable.reduce((stages, stageInfo) => {
        const completedStage = encounterStages.find(s => s.stage_id === stageInfo.stageId && s._name === stageInfo.name);
        if (completedStage) {
          return stages.push(completedStage);
        }
        return stages;
      }, List());
      const updatedStages = completedStages.concat(sortedStages.concat(cancelledStages)).toArray();
      setEncounter(encounter.set('flow',
        Object.assign({}, updatedAttributes, { stages: updatedStages })));
    }
    setFlowEdited(updatedAttributes);
  };


  /**
   * @param {List<FlowAttributeStage>} stages stages to map
   * @returns {Array<React.ReactNode>}
   */
  const getStages = (stages: List<FlowAttributeStage>) => stages.toArray().map(stage => (
    <CardRow key={stage.name + stage.stageId}>
      <div>{stage.unsuffixed}</div>
      <CloseIcon onClick={() => removeStage(stage)} className="u-padding--half-ws fa fa-close" />
    </CardRow>
  ));

  /**
   * @param {List<FlowAttributeStage>} stages stages to map
   * @param {boolean} isRemovable True if the stage is not sortable but removable
   * Case: If flow has two stages, second stage can't be reordered (as first one is already started), but is removable.
   * @returns {Array<React.ReactNode>}
   */
  const getDisabledStages = (stages: List<FlowAttributeStage>, isRemovable?: boolean) => stages.map(stage => (
    <ListItem key={stage.name} disabled={props.encounter && !isRemovable}>
      <DragIcon className="fa fa-bars" disabled={props.encounter && !isRemovable} />
      <CardRow>
        <div>{stage.unsuffixed}</div>
        {isRemovable && <CloseIcon onClick={() => removeStage(stage)} className="u-padding--half-ws fa fa-close" />}
      </CardRow>
    </ListItem>
  ));

  return (
    <>
      <StatelessModal
        id="stage-form"
        visible={props.isVisible}
        setVisible={handleFormClose}
        title={translate((props.selectedFlow || props.appointment || encounter) ? 'edit_x' : 'create_x', { x: translate('flow') })}
        dataPublicHeader
        noButton
        explicitCloseOnly
      >
        <div className="u-margin--standard">
          {formError &&
          <FormError isSticky>{formError}</FormError>}
          {(encounter || props.appointment) ? (
            <Select
              id="flow-select"
              options={props.encounterFlowMap?.toList().map(f => ({
                label: f.get('name'),
                value: f.get('_id'),
              })).toArray() || []}
              value={{ value: flowAttributes.name, label: flowAttributes.name }}
              onValueChanged={handleFlowChange}
              clearable={false}
              label={translate('current_flow')}
            />
          ) :
            (<Input
              id="flow-name"
              label={translate('name')}
              value={flowAttributes.name}
              onValueChanged={
                (name: string) => handleChange('name', name)
              }
              required
              autoFocus
            />)}
          <hr />
          <div className="u-margin-bottom--ws u-margin-top--half-ws">
            <h1 className={`o-label ${formError && !flowAttributes.stages.size ? 'o-label o-label--warning' : ''}`}>
              {translate('stages_in_flow')}
            </h1>
          </div>
          <SortableListContainer>
            <Ul css={{ listStyle: 'none' }}>
              {getDisabledStages(categorisedStages.uneditable)}
              {categorisedStages.sortable.size <= 1 &&
                getDisabledStages(
                  categorisedStages.sortable,
                  // Prevent last item from removal
                  (categorisedStages.uneditable.size + categorisedStages.sortable.size) > 1,
                )}
            </Ul>
            {/* Disable sort if only item is sortable */}
            {categorisedStages.sortable.size > 1 && <SortableList
              items={getStages(categorisedStages.sortable)}
              onSortEnd={onSort}
              itemZIndex={100}
            />}
          </SortableListContainer>
          {!props.appointment &&
          <PermissionWrapper permissionsRequired={List([createPermission('flows', 'update')])} user={props.user}>
            <Button
              className="o-button o-button--small u-full-width u-margin-top--standard"
              onClick={() => setStageFormVisible(true)}
              disabled={encounter && !encounter.has('flow') && !flowAttributes.stages.size}
            >
              <I className="fa fa-plus" css={{ float: 'left', padding: 4 }} />
              {translate('add_new_stage')}
            </Button>
          </PermissionWrapper>}
        </div>
        {props.onDelete && props.selectedFlow && !props.appointment &&
        <PermissionWrapper permissionsRequired={List([createPermission('flows', 'delete')])} user={props.user}>
          <Button
            className="o-delete-button"
            onClick={props.onDelete}
          >
            {translate('delete')}
          </Button>
        </PermissionWrapper>}
        {hasSomePermission(props.user, List([createPermission('flows', 'create'), createPermission('flows', 'update')])) &&
          <ModalFooter>
            <div className="u-margin-right--1ws u-margin-left--1ws u-text-align-right">
              <SaveButton
                className="o-button--small"
                isSaving={isSaving}
                disabled={encounter && !encounter.has('flow') && !flowAttributes.stages.size}
                onClick={onSave}
              />
            </div>
          </ModalFooter>
        }
      </StatelessModal>
      <StatelessModal
        id="add_stage_to_flow"
        visible={stageFormVisible}
        setVisible={() => setStageFormVisible(false)}
        title={translate('add_stage_to_flow')}
        dataPublicHeader
        noButton
        large
      >
        <AddFlowStage
          stagesMap={props.stagesMap}
          config={props.config}
          updateConfig={props.updateConfig}
          salesItems={props.salesItems}
          saveModel={props.saveModel}
          coveragePayors={props.coveragePayors}
          addStage={addStage}
          user={props.user}
        />
      </StatelessModal>
    </>
  );
};

export default FlowForm;
