import { connect } from 'react-redux';

import MetricTypes from './../components/vitals/metricTypes';
import { saveFactory } from './../utils/redux';

import type { State, Dispatch } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ metricTypes, user }: State) => ({
  metricTypes: metricTypes.filter(p => p.isVisible()),
  user,
});


/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const MetricTypesContainer = connect(mapStateToProps, mapDispatchToProps)(MetricTypes);

export default MetricTypesContainer;
