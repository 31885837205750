import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import translate from './../utils/i18n';
import StringListEditor from './../components/listEditor/stringListEditor';
import { updateConfigValue, updateConfig } from './../actions';
import { updateClinicConfig } from './../utils/db';

import type { Dispatch, State, Config } from './../types';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ config, user }: State) => ({
  config,
  items: config.getIn(['symptoms', 'options'], List()),
  label: translate('symptoms'),
  columnLabel: translate('symptom'),
  type: 'symptoms',
  permissionName: 'symptom_options',
  user,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateStrings: (updatedStrings: List<string>) => dispatch(updateConfigValue(['symptoms', 'options'], updatedStrings)),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

/**
 * Merges the props for this container.
 * @param {any} stateProps State Props
 * @param {any} dispatchProps Dispatch props
 * @returns {any} Merged Props
 */
const mergeProps = (stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: ReturnType<typeof mapDispatchToProps>) =>
  Object.assign({}, {
    updateStrings: (updatedStrings: List<string>) => {
      dispatchProps.updateStrings(updatedStrings);
      return updateClinicConfig(
        stateProps.config.toJS(),
        Map().setIn(['symptoms', 'options'], updatedStrings).toJS(),
        dispatchProps.updateConfig,
      );
    },
  }, stateProps);

const SymptomsEditorContainer =
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(StringListEditor);

export default SymptomsEditorContainer;
