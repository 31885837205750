import React from 'react';
import glamorous from 'glamorous';
import { scaledH3 } from './../../utils/css';

const BannerDiv = glamorous.div({
  height: 240,
  marginBottom: '1rem',
  width: '100%',
  backgroundImage: 'url("/static/outbreak/banner.png")',
  backgroundColor: 'silver',
  backgroundPosition: 'bottom left',
  backgroundSize: 'cover',
  color: 'white',
});

const Text = glamorous.h3({
  fontSize: scaledH3,
  padding: '1rem',
});

/**
 * Render the banner
 * @param {string|null} append text to add
 * @returns {HTMLElement} html element
 */
const Banner = ({ title }: {title?: string}) => (
  <BannerDiv>
    { title ? <Text>{title}</Text> : null }
  </BannerDiv>
);

export default Banner;
