import { connect } from 'react-redux';

import InventoryReports from './../components/reports/inventoryReports';
import {
  setInventoryReportsFilter,
  setIsFetching,
  updateConfigValue,
  updateConfig,
} from './../actions';

import type { Dispatch, Config, State, MapValue } from './../types';

/**
 * @param {Object} state Current app state.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = (state: State) => ({
  filter: state.reportsFilter.get('inventory'),
  config: state.config,
  isFetching: state.isFetching,
});

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, MapValue>) => dispatch(setInventoryReportsFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  updateConfigValue,
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const InventoryReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryReports);

export default InventoryReportsContainer;
