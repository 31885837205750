
import BaseModel from './baseModel';

type Attributes = {
  _id: string,
  created_by: { timestamp: number, user_id: string },
  edited_by: Array<{ timestamp: number, user_id: string }>,
  type: 'specimen',
  name: string,
  is_editable: boolean,
  active: boolean,
  subtype?: string,
  body_sites?: Array<string>,
  collection_method?: string,
  container_name?: string,
  container_vendor?: string,
  unit?: string,
  quantity_per_container?: number,
  container_quantity?: number,
  special_instructions?: string,
  internal_clinic_id?: string,
};

/**
   * SpecimenModel
   * @namespace SpecimenModel
   */
class SpecimenModel extends BaseModel {
  attributes: Attributes;

  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: {} = {}) {
    super(attributes);
    this.attributes.type = 'specimen';
  }

  /**
   * Returns true if item have an active flag.
   * @returns {boolean} True if item is active.
   */
  isActive(): boolean {
    return this.get('active', true);
  }

  /**
   * Sets the provider active by the given value.
   * @param {boolean} isActive True if the item should be visible, false otherwise.
   * @returns {BaseModel} The altered ProviderModel.
   */
  setActive(isActive: boolean): SpecimenModel {
    return this.set('active', isActive);
  }
}

export default SpecimenModel;
