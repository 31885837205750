import BaseModel from './baseModel';

export type Attributes = {
  name: string;
  description: string; // optional free text description that explains the intent of this campaign
  is_active: boolean;
  hidden: boolean;
  expiry_timestamp: number; // timestamp. if queue timestamp is > expiry_date, the campaign is treated as inactive.
  // If this is set, this campaign is subject to the rules of the master campaign
  // Name, description, Rules, messages, expiry_timestamp and event will be pulled from the global campaign doc.

  master_campaign_set_id?: string;
  // copy of the campaign set type for easy access and filtering.
  master_campaign_set_type?: 'medadvisor' | 'klinify';
  // only relevant if master_campaign_id is set. Default is false. If this is set to true AND the master campaign is editable
  // the local name, description, rules, messages, expiry_timestamp and event fields will be used to run the campaign.
  // The link to global is maintained in cases where we want to override the local copy by setting the global editable to false.
  is_overridden: boolean;
  cancelled_encounters: string[];
  cancelled_patients: string[];
  stats: { // Not part of the original doc, but is obtained as together from API
    // This field has to be prevented from getting saved in the db.
    queued: number;
    in_progress: number;
    sent: number;
    unresolved: number;
    error: number;
    resolved: number;
    cancelled: number;
  };
  type: 'patient_campaign_set';
};

/**
 * PatientCampaignSetModel
 *
 * @namespace PatientCampaignSetModel
 */
class PatientCampaignSetModel extends BaseModel {
  /**
   * @param {object} attributes - The attributes for this model.
   */
  constructor(attributes: Attributes | {} = {}) {
    super(attributes);
    this.attributes.type = 'patient_campaign_set';
  }

  /**
   * @return {string} status of the campaign
   */
  getStatus() {
    return this.get('is_active') ? 'active' : 'inactive';
  }

  /**
   * Removes attributes not part of db document and returns the model
   * @returns {PatientCampaignSetModel}
   */
  beforeSave() {
    const delAttrs: Array<keyof Attributes> = ['stats'];
    const updatedAttrs = this.copyData({}, delAttrs);
    return new PatientCampaignSetModel(updatedAttrs);
  }
}

export default PatientCampaignSetModel;
