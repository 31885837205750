import React from 'react';

import Modal from './../modals/modal';
import EditHistorySummary from './editHistorySummary';
import translate from './../../utils/i18n';

import type BaseModel from './../../models/baseModel';

type Props = {
  buttonLabel: string,
  buttonClass: string,
  model: BaseModel,
}

/**
 * A component displaying the edit history of a model.
 * @param {Props} props The given props.
 * @returns {React.Component} The EditHistory component.
 */
const EditHistory = (props: Props) =>
  <Modal
    buttonLabel={props.buttonLabel}
    buttonClass={props.buttonClass}
    title={translate('edit_history')}
    dataPublic
  >
    <EditHistorySummary model={props.model} />
  </Modal>;

export default EditHistory;
