import React from 'react';
import { List } from 'immutable';

import translate from './../../utils/i18n';
import ContentTransition from './../contentTransition';
import StatelessModal from './../modals/statelessModal';
import PermissionWrapper from './../permissions/permissionWrapper';
import { createPermission } from './../../utils/permissions';
import DocumentTemplateModel from '../../models/documentTemplateModel';
import SortableList from './../layout/sortableList';
import DocumentTemplateForm from './documentTemplateForm';
import DocumentTemplatesRow from './documentTemplatesRow';
import { CASENOTE_ORDER_INCREMENT } from './../../constants';
import Header from './../header/header';

import type { SaveModel, User, Config } from './../../types';

type Props = {
  user: User,
  documentTemplates: List<DocumentTemplateModel>,
  saveModel: SaveModel,
  config: Config,
  updateConfig: (config: Config) => void,
};

type State = {
  isModalVisible: boolean,
  modelToEdit: DocumentTemplateModel | void,
};

/**
 * A component for the management of DocumentTemplates.
 * @class DocumentTemplates
 * @extends {React.Component<Props, State>}
 */
class DocumentTemplates extends React.Component<Props, State> {
  /**
   * Creates an instance of DocumentTemplates.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false,
      modelToEdit: undefined,
    };
  }

  /**
   * Handle sorting of list
   * @param {{ oldIndex: number, newIndex: number }} changes The old and new indices.
   * @return {void}
   */
  onSortEnd = (changes: { oldIndex: number, newIndex: number }) => {
    const { oldIndex, newIndex } = changes;
    const movedModel = this.props.documentTemplates.get(oldIndex);
    if (movedModel && oldIndex !== newIndex) {
      const modelAtPos = this.props.documentTemplates.get(newIndex);
      const orderOfModelAtPos = modelAtPos ? modelAtPos.get('order', 0) : 0;
      if (newIndex === 0) {
        movedModel.set('order', orderOfModelAtPos - CASENOTE_ORDER_INCREMENT);
      } else if (newIndex === this.props.documentTemplates.size - 1) {
        movedModel.set('order', orderOfModelAtPos + CASENOTE_ORDER_INCREMENT);
      } else if (oldIndex < newIndex) {
        const modelAtNextPos = this.props.documentTemplates.get(newIndex + 1);
        const orderOfModelAtNextPos = modelAtNextPos ? modelAtNextPos.get('order', 0) : 0;
        const diff = Math.abs(orderOfModelAtNextPos - orderOfModelAtPos) / 2;
        movedModel.set('order', orderOfModelAtNextPos - diff);
      } else {
        const modelAtPrevPos = this.props.documentTemplates.get(newIndex - 1);
        const orderOfModelAtPrevPos = modelAtPrevPos ? modelAtPrevPos.get('order', 0) : 0;
        const diff = Math.abs(orderOfModelAtPos - orderOfModelAtPrevPos) / 2;
        movedModel.set('order', orderOfModelAtPrevPos + diff);
      }
      this.props.saveModel(movedModel);
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const { documentTemplates, user, saveModel, config, updateConfig } = this.props;
    return (
      <ContentTransition className="o-scrollable-container">
        <Header className="o-header" dataPublic>
          <h1>{translate('document_templates')}</h1>
          <div style={{ marginLeft: 'auto' }}>
            <PermissionWrapper permissionsRequired={List([createPermission('document_templates_settings', 'create')])} user={user}>
              <StatelessModal
                id="addNewDocTemplateModal"
                buttonLabel={translate('upload_document_template')}
                buttonClass="o-button o-button--small"
                title={this.state.modelToEdit ? translate('edit_document_template') : translate('upload_document_template')}
                visible={this.state.isModalVisible}
                setVisible={isVisible => this.setState({ isModalVisible: isVisible })}
                onClose={() => this.setState({ modelToEdit: undefined })}
                explicitCloseOnly
                dataPublicHeader
              >
                <DocumentTemplateForm
                  onCancel={() => this.setState({ isModalVisible: false, modelToEdit: undefined })}
                  onSave={() => this.setState({ isModalVisible: false, modelToEdit: undefined })}
                  saveModel={saveModel}
                  documentTemplates={this.props.documentTemplates}
                  modelToEdit={this.state.modelToEdit}
                  config={config}
                  updateConfig={updateConfig}
                />
              </StatelessModal>
            </PermissionWrapper>
          </div>
        </Header>
        <div className="o-card u-margin-bottom--4ws">
          <h2 data-public className="o-card__title">{translate('document_templates')}</h2>
          <div className="o-sortable-list-header-columns u-flex-row u-flex-space-between">
            <div className="o-sortable-list-header" style={{ minWidth: '11%' }}>{translate('name')}</div>
            <div className="o-sortable-list-header">{translate('default_template')}</div>
            <div />
          </div>
          {
            documentTemplates.size > 0 ?
              <SortableList
                onSortEnd={this.onSortEnd}
                items={
                  documentTemplates.map(d => (
                    <DocumentTemplatesRow
                      config={config}
                      documentTemplate={d}
                      onEditClicked={(documentTemplate: DocumentTemplateModel) => this.setState({
                        isModalVisible: true,
                        modelToEdit: documentTemplate,
                      })}
                      saveModel={saveModel}
                    />
                  )).toArray()
                }
              /> :
              <p className="u-padding--standard">{translate('no_document_templates_created')}</p>
        }
        </div>
      </ContentTransition>
    );
  }
}

export default DocumentTemplates;
