import React, { Fragment } from 'react';
import { List, Map } from 'immutable';

import translate from './../../utils/i18n';
import PanelDrugPriceInput from './panelDrugPriceInput';
import Input from './../inputs/input';
import Button from './../buttons/button';

import type CoveragePayorModel from './../../models/coveragePayorModel';
import type { PriceCoveragePayor } from './../../models/drugModel';
import type { MapValue, SaveModel } from './../../types';

export type Price = Map<string, MapValue>; // Format { price: number, coveragePayorIds: List<string> }

type Props = {
  coveragePayors: List<CoveragePayorModel>,
  value: List<PriceCoveragePayor>,
  onValueChanged: (
    value: List<PriceCoveragePayor>,
    defaultCashPrice?: string,
    defaultPanelPrice?: string
  ) => void,
  defaultCashPriceValue?: string,
  defaultPanelPriceValue?: string,
  saveModel: SaveModel,
  validationDocObject?: { id: string, type: string }
};

type State = {
  prices: List<Price>,
  defaultCashPrice?: string,
  defaultPanelPrice?: string,
};

/**
 * Converts the passed props value to a format readable by this component.
 * @param {List<PriceCoveragePayor>} propsValue The received props value.
 * @returns {List<Price>}
 */
function convertPropsToState(propsValue: List<PriceCoveragePayor>): List<Price> {
  return propsValue
    .groupBy(v => v.price)
    .toList()
    .map((priceList: List<PriceCoveragePayor>) => Map({
      price: priceList.get(0, {}).price,
      coveragePayorIds: priceList.map(i => i.coverage_payor_id),
    }));
}

/**
 * Converts the current state value to a format readable by the parent component.
 * @param {List<Price>} stateValue The current state value
 * @returns {List<PriceCoveragePayor>}
 */
function convertStateToProps(stateValue: List<Price>): List<PriceCoveragePayor> {
  const propsValue: List<PriceCoveragePayor> = stateValue.reduce((propsVal, priceItem: Price) => (
    propsVal.concat(priceItem.get('coveragePayorIds', List())
      .reduce((value, coveragePayorId: string) => {
        const price = priceItem.get('price', 0);
        return value.push({
          price,
          coverage_payor_id: coveragePayorId,
        });
      }, List()))
  ), List());
  return propsValue;
}

/**
 * A form that allows the setting of individual prices for a drug per panel.
 * @class PanelDrugPriceForm
 * @extends {React.Component<Props, State>}
 */
class PanelDrugPriceForm extends React.Component<Props, State> {
  /**
   * Creates an instance of PanelDrugPriceForm.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    const prices = convertPropsToState(props.value);
    this.state = {
      prices: prices.size > 0 ? prices : List(), // Create an empty item if no prices given.
      defaultCashPrice: props.defaultCashPriceValue || undefined,
      defaultPanelPrice: props.defaultPanelPriceValue || undefined,
    };
  }

  /**
   * Handle input field change
   * @param {{}} value Field value
   * @returns {void}
   */
  handleOnChange(value: {}) {
    const newValue = Object.assign(this.state, value);
    this.props.onValueChanged(
      convertStateToProps(newValue.prices),
      newValue.defaultCashPrice,
      newValue.defaultPanelPrice,
    );
  }

  /**
   * Gets a List of all unused CoveragePayor models. Also filters out any disabled panels.
   * @param {List<string>} idsToIgnore A list of ids to ignore (i.e. 'used' panels).
   * @returns {List<CoveragePayorModel>}
   */
  getUnusedCoveragePayors(idsToIgnore: List<string> = List()): List<CoveragePayorModel> {
    const usedIDs = this.state.prices
      .map(p => p.get('coveragePayorIds'))
      .flatten()
      .filter(id => !idsToIgnore.includes(id));
    return this.props.coveragePayors
      .filter((payor: CoveragePayorModel) => !usedIDs.includes(payor.get('_id')) && payor.isVisible());
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    return (
      <Fragment>
        <div className="o-form" style={{ margin: 0 }}>
          <Input
            id="default-cash-price"
            label={translate('default_cash_price')}
            type="number"
            value={this.state.defaultCashPrice}
            onValueChanged={price => this.handleOnChange({ defaultCashPrice: price })}
          />
          <Input
            id="default-panel-price"
            label={translate('default_panel_price')}
            type="number"
            value={this.state.defaultPanelPrice}
            onValueChanged={price => this.handleOnChange({ defaultPanelPrice: price })}
          />
          {
            this.state.prices.map((priceItem: Price, index: number) =>
              <PanelDrugPriceInput
                value={priceItem}
                index={index}
                unusedCoveragePayors={this.getUnusedCoveragePayors(priceItem.get('coveragePayorIDs'))}
                coveragePayors={this.props.coveragePayors}
                setValue={
                  (newValue: Price) =>
                    this.handleOnChange({ prices: this.state.prices.set(index, newValue) })
                }
                removeValue={() => this.handleOnChange({ prices: this.state.prices.remove(index) })}
                saveModel={this.props.saveModel}
                validationDocObject={this.props.validationDocObject}
              />).toArray()
          }
          <Button
            className="o-button o-button--small u-margin-bottom--1ws"
            style={{ width: '33%' }}
            onClick={() => this.handleOnChange({
              prices: this.state.prices.push(Map({ price: '', coveragePayorIds: List() })),
            })}
            dataPublic
          >{translate('add_new_price')}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default PanelDrugPriceForm;
