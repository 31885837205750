import React from 'react';
import { List, is, Map } from 'immutable';

import ConsultationHistoryItemContainer from './../../containers/consultationHistoryItemContainer';
import BillSidebar from './billSidebar';
import PatientHeader from './../patient/patientHeader';
import {
  deepClone, getConfirmation, roundMoneyForSave, isFloatEqual, listToMap,
} from './../../utils/utils';
import PatientCard from './../patientCard/patientCard';
import PermissionWrapper from './../permissions/permissionWrapper';
import translate from './../../utils/i18n';
import { createPermission } from './../../utils/permissions';
import BillFormItemList from './billFormItemList';
import UnsavedDataPrompt from './../prompts/unsavedDataPrompt';
import FormError from './../formError';
import { createSuccessNotification } from './../../utils/notifications';
import { getBillSerialNumber } from './../../utils/serialNumbers';
import { logBillFinalisation, debugPrint } from './../../utils/logging';
import { getModelsForBill, getTransactionsBySourceIds } from './../../utils/api';
import { isReferralMode } from '../../utils/router';
import {
  billItemsToTransactions, calculatePatientTotal, calculateCoveragePayorTotal,
  calculateBillItemTotal, areBillItemsValid, voidBillWithTransactions, paymentsAreValid,
  calculatePaymentsTotal, getPatientOwedAmount, adjustPaymentsToMatchTotal,
  isDispensationOutOfStock, getReverseTransactions, getDefaultPaymentMethod,
  getPanelPriceUpdateMessage,
  calculateFullDiscount,
} from './../../utils/billing';
import { isInventoryCountReadyForDispensation } from './../../utils/inventory';
import { getPatientCoPayment } from './../../utils/coveragePayors';
import BillFinalisationSummary from './billFinalisationSummary';
import PaymentModel from './../../models/paymentModel';
import ReceivableModel from './../../models/receivableModel';
import BillItemModel from './../../models/billItemModel';
import ProcedureRequestModel from './../../models/procedureRequestModel';
import ProcedureTypeModel from './../../models/procedureTypeModel';
import ProviderModel from './../../models/providerModel';

import type {
  User, SaveModel, Config, SaveModels, Model, MapValue, CountPerSKUAndBatch, SaveModelsAPI, APIResponse, DebugModeFlags,
} from './../../types';
import type AllergyModel from './../../models/allergyModel';
import type BillModel, { Attributes as BillAttributes } from './../../models/billModel';
import type ClaimModel from './../../models/claimModel';
import type CoveragePayorModel from './../../models/coveragePayorModel';
import type DrugModel from './../../models/drugModel';
import type EncounterModel from './../../models/encounterModel';
import type MedicalCertificateModel from './../../models/medicalCertificateModel';
import type PatientModel from './../../models/patientModel';
import type PatientStubModel from './../../models/patientStubModel';
import type PaymentTypeModel from './../../models/paymentTypeModel';
import type SalesItemModel from './../../models/salesItemModel';
import type PrescriptionModel from './../../models/prescriptionModel';
import type TimeChitModel from './../../models/timeChitModel';
import type PractitionerModel from './../../models/practitionerModel';
import type ConditionModel from './../../models/conditionModel';
import type TransactionModel from '../../models/transactionModel';
import type DocumentTemplateModel from './../../models/documentTemplateModel';
import type DocumentDataModel from './../../models/documentDataModel';
import type AppointmentModel from './../../models/appointmentModel';
import type EncounterStageModel from '../../models/encounterStageModel';
import InventoryMapModel from './../../models/inventoryMapModel';
import Confirm from '../prompts/confirm';
import DiscountChargeModel from './../../models/discountChargeModel';

type Props = {
  allergies: List<AllergyModel>,
  bill: BillModel,
  billItems: List<BillItemModel>,
  claim: ClaimModel,
  config: Config,
  coveragePayors: List<CoveragePayorModel>,
  drugs: List<DrugModel>,
  inventoryCount: CountPerSKUAndBatch,
  inventoryCountSyncStatus: List<'ASC' | 'DESC' | 'SYNC' | 'STOP'>,
  encounter: EncounterModel,
  encounterStageMap: Map<string, EncounterStageModel>,
  medicalCertificates: List<MedicalCertificateModel>,
  patient: PatientModel | PatientStubModel,
  salesItems: List<SalesItemModel>,
  saveModels: SaveModels,
  saveModel: SaveModel,
  updateInventoryItemCount: (skuID: string, change: number) => void,
  user: User,
  payments: List<PaymentModel>,
  receivables: List<ReceivableModel>,
  prescriptions: List<PrescriptionModel>,
  timeChits: List<TimeChitModel>,
  practitioners: List<PractitionerModel>,
  paymentTypes: List<PaymentTypeModel>,
  defaultCoPayment?: number,
  procedureRequests: List<ProcedureRequestModel>,
  procedureTypes: List<ProcedureTypeModel>,
  providers: List<ProviderModel>,
  diagnoses: List<ConditionModel>,
  conditions: List<ConditionModel>,
  documentTemplates: List<DocumentTemplateModel>,
  documentData: List<DocumentDataModel>,
  updateModelsInState: (models: List<BillItemModel>) => void,
  updateConfigValue: (keys: Array<string>, value: MapValue) => void,
  updateConfig: (config: Config) => void,
  drugDurations: List<List<number | string>>,
  isOnline: boolean,
  appointment: AppointmentModel | void,
  debugModeFlags: DebugModeFlags,
  discountsCharges: List<DiscountChargeModel>,
  saveBillModels: (
    bill: BillModel,
    transactions: List<TransactionModel>,
    billItems: List<BillItemModel>,
    encounter: EncounterModel,
    payments?: List<PaymentModel>,
    receivables?: List<ReceivableModel>,
    claims?: List<ClaimModel>,
    onSaveAfterDocValidation?: (savedModels: Array<Model>) => void,
    requestId?: string,
  ) => Promise<Array<Model> | APIResponse<Model>>,
  setDebugModeData: (
    flag: string,
    saveModelsFn: (models: List<Model>) => Promise<any>,
    models: List<Model>,
  ) => void,
  verifiedDrugs: List<InventoryMapModel>,
};

type State = {
  billAttributes: BillAttributes,
  billItems: List<BillItemModel>,
  billItemsOriginal: List<BillItemModel>,
  changesMade: boolean,
  errorMessage?: string,
  isEditingAfterFinalisation: boolean,
  isBatchReadyForDispensation: boolean,
  isSaving: boolean,
  patientHeaderHeight: number,
  usedDiscountsCharges: List<DiscountChargeModel>,
  payments: List<PaymentModel>, // These are payments that are potentially modified.
  paymentsOriginal: List<PaymentModel>,
  showBillFinalisationSummary: boolean,
  reverseTransactions: List<TransactionModel>, // We store these so we can reverse the transactions created by the bill items already created when editing.
  isPaymentInvalid: boolean,
  transactions: List<TransactionModel> | null | undefined, // transactions to save
  confirmPriceChangeModalContent?: React.ReactElement,
};

const defaultState = {
  changesMade: false,
  isEditingAfterFinalisation: false,
  isSaving: false,
  isBatchReadyForDispensation: false,
  patientHeaderHeight: 0,
  showBillFinalisationSummary: false,
  isPaymentInvalid: false,
  billItems: List(),
  billItemsOriginal: List(),
  payments: List(),
  usedDiscountsCharges: List(),
  paymentsOriginal: List(),
  reverseTransactions: List(),
  confirmPriceChangeModalContent: undefined,
};

/**
 * A component for viewing and editing a Bill.
 * @class Bill
 * @extends {React.Component<Props, State>}
 */
class Bill extends React.Component<Props, State> {
  /**
   * Creates an instance of Bill.
   * @param {Props} props Props
   */
  constructor(props: Props) {
    super(props);
    const patientCoveragePayorID = props.patient.getCoveragePayor();
    const coPayment = getPatientCoPayment(props.patient, props.coveragePayors);
    const patientCoveragePayor = patientCoveragePayorID ? {
      coverage_payor_id: patientCoveragePayorID,
      coverage_policy_id: props.patient.getCoveragePolicy(),
      co_payment: coPayment,
    } : undefined;
    const state = this.propsToState(props);
    this.state = {
      ...defaultState,
      ...state,
      billAttributes: (patientCoveragePayor && !props.bill.isFinalised() &&
      (!props.bill.get('coverage_payor_id') ||
      props.bill.get('coverage_payor_id') !== patientCoveragePayorID) ?
        { ...state.billAttributes, ...patientCoveragePayor } : state.billAttributes),
    };
    this.getTransactionsForBill(props.billItems ? props.billItems
      .filter(i => i.isPrescription()) : List());
  }

  /**
   * Update state of component when bill related properties change.
   * @param {Props} nextProps Next props.
   * @returns {void}
   */
  componentWillReceiveProps(nextProps: Props) {
    if (!is(this.props.bill, nextProps.bill) ||
      !is(this.props.prescriptions, nextProps.prescriptions) ||
      !is(this.props.procedureRequests, nextProps.procedureRequests) ||
      !is(this.props.billItems, nextProps.billItems) ||
      !is(this.props.payments, nextProps.payments)
    ) {
      this.setState(this.propsToState(nextProps));
    }
    const currentDispensations = this.props.billItems ? this.props.billItems
      .filter(i => i.isPrescription()) : List();
    const nextDispensations = nextProps.billItems ? nextProps.billItems
      .filter(i => i.isPrescription()) : List();
    if (!is(currentDispensations, nextDispensations)) {
      this.getTransactionsForBill(nextDispensations);
    }
  }


  /**
   * Triggers a batch data sync status check after first render
   * @returns {void}
   */
  componentDidMount() {
    this.checkBatchDataReady();
  }

  /**
   * Triggers a batch data sync status check when the inventory count changes.
   * @param {Props} prevProps Previous Props
   * @param {State} prevState Previous State
   * @returns {void}
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !is(this.getDispensedDrugs(this.state.billItems),
        this.getDispensedDrugs(prevState.billItems)) ||
      !is(this.props.inventoryCount, prevProps.inventoryCount) ||
      !is(this.props.inventoryCountSyncStatus, prevProps.inventoryCountSyncStatus)
    ) {
      this.checkBatchDataReady();
    }
  }

  /**
   * check for batch Data readiness for dispensation
   * @returns {void}
   */
  checkBatchDataReady() {
    const { drugs, inventoryCount, inventoryCountSyncStatus } = this.props;
    const billItemsMap = this.state.billItems.groupBy(bi => bi.get('drug_id'));
    const skus = drugs.filter(d => billItemsMap.has(d.get('_id')));
    this.setState({
      isBatchReadyForDispensation: isInventoryCountReadyForDispensation(
        skus,
        inventoryCount,
        inventoryCountSyncStatus,
      ),
    });
  }

  getSortedDiscountsCharges = (discountsCharges: List<DiscountChargeModel>) => {
    if (this.props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()).size > 0) {
      const discountChargesMap = Map(discountsCharges.map(d => [d.get('_id'), d]));
      const sortableList = List(this.props.config.getIn(['discounts_charges', 'discounts_charges_order'], List()))
        .map(id => discountChargesMap.get(id))
        .filter(d => !!d);

      return sortableList;
    }
    return discountsCharges;
  }

  /**
     * Gets the Dispensed drug ids from bill items.
     * @param {List<BillItemModel>} billItems The prescription type bill items.
     * @returns {List<string>} List of drug ids
     */
  getDispensedDrugs = (billItems: List<BillItemModel>) => billItems
    .map(bi => bi.get('drug_id'))
    .filter(d => !!d);

  /**
   * Returns the original state using props passed to the component.
   * @param {Props} props props.
   * @returns {{}}  The original/initial state for some of the values
   */
  propsToState(props: Props): { [key: string]: MapValue } {
    const billAttributes = deepClone(props.bill.attributes);
    const billItemsOriginal = props.billItems.map(b => new BillItemModel(b.attributes));
    if (billAttributes.co_payment === null) {
      billAttributes.co_payment = 0;
    }
    const isNotVoidAndNotFinalised = !props.bill.isVoid() && !props.bill.isFinalised();
    const prescriptionList = isNotVoidAndNotFinalised ?
      props.prescriptions.map(p => new BillItemModel({
        patient_id: p.get('patient_id'),
        quantity: p.get('sale_quantity', 0),
        price: p.get('sale_price', 0),
        cost_price: p.get('cost_price', 0),
        drug_id: p.get('drug_id'),
      })) : List();

    const procedureRequestList = isNotVoidAndNotFinalised ?
      props.procedureRequests.map((procedureRequest) => {
        const procedureType = procedureRequest.getType(props.procedureTypes);
        return new BillItemModel({
          patient_id: procedureRequest.get('patient_id'),
          quantity: 1,
          price: procedureType ? procedureType.get('price', 0) : undefined,
          cost_price: procedureType ? procedureType.get('cost_price', 0) : undefined,
          procedure_type_id: procedureType ? procedureType.get('_id') : undefined,
        });
      }) : List();
    const billItems = props.billItems.concat(prescriptionList, procedureRequestList);
    // eslint-disable-next-line require-jsdoc
    const discountChargesArgs = () => {
      if (props.bill) {
        if (props.bill.has('applied_discounts_charges')) {
          return props.bill.get('applied_discounts_charges')?.map(d => props.discountsCharges.find(e => e.get('_id') === d));
        } else if (props.bill.has('is_finalised') &&
        props.bill.get('is_finalised') &&
        !props.bill.has('applied_discounts_charges')) {
          return [];
        }
        return props.discountsCharges.filter(d => (d.get('always_applied') && d.isVisible()));
      }
      return props.discountsCharges.filter(d => (d.get('always_applied') && d.isVisible()));
    };
    return {
      billAttributes,
      billItems,
      billItemsOriginal,
      payments: (props.bill.isFinalised() ?
        this.getOriginalPayments(props) :
        this.updatePaymentsToMatchAmount(props.payments, billAttributes, billItems)),
      paymentsOriginal: this.getOriginalPayments(props),
      usedDiscountsCharges: this.getSortedDiscountsCharges(discountChargesArgs()),
    };
  }

  /**
   * gets the transactions corresponding to the dispensations in the bill.
   * @param {List<BillItemModel>} billItems the original disepnsations.
   * @returns {void}  The original transactions
   */
  getTransactionsForBill(billItems: List<BillItemModel>) {
    getTransactionsBySourceIds(billItems.map(i => i.get('_id')))
      .then((transactions) => {
        this.setReverseTransactions(getReverseTransactions(transactions));
      })
      .catch(() => {});
  }

  /**
   * sets the reverse transactions corresponding to the dispensations in the bill in state.
   * @param {List<TransactionModel>} reverseTransactions the reverse transactions.
   * @returns {void}
   */
  setReverseTransactions(reverseTransactions: List<TransactionModel>): void {
    this.setState({ reverseTransactions });
  }

  /**
   * Returns the original Payments passed as props to the component.
   * @param {Props} props props.
   * @returns {List<PaymentModel>}  The new value of state.payments
   */
  getOriginalPayments(props: Props): List<PaymentModel> {
    return props.payments.map(p => new PaymentModel({ ...p.attributes }));
  }

  /**
   * This function should be called after making any changes to the amount of a bill (or after the
   * initialisation of a new bill). It will take the current value of state.payments and update it
   * to have at least one payment if the patient owed amount is greater than 0
   * @param {List<PaymentModel>} payments The current value of state.payments
   * @param {BillAttributes} billAttributes The current bill attributes
   * @param {List<BillItemModel>} billItems The current bill items.
   * @returns {List<PaymentModel>}  The new value of state.payments
   */
  updatePaymentsToMatchAmount(
    payments: List<PaymentModel>,
    billAttributes: BillAttributes,
    billItems: List<BillItemModel>,
  ): List<PaymentModel> {
    if (billAttributes.is_finalised || billAttributes.is_void) {
      return payments;
    }
    const patientTotal = calculatePatientTotal(billAttributes, billItems, this.state?.usedDiscountsCharges || this.props.discountsCharges.filter(d => d.get('always_applied') && d.isVisible()));
    if (patientTotal > 0 && payments.size === 0) {
      return List([this.getNewPayment(patientTotal)]);
    }
    return adjustPaymentsToMatchTotal(payments, patientTotal);
  }

  /**
   * Returns the payments in State that are not void.
   * @returns {List<PaymentModel>}
   */
  getActivePayments(): List<PaymentModel> {
    return this.state.payments.filter(p => !p.isVoid());
  }

  /**
   * Returns the bill items in State that have not been removed.
   * @returns {List<BillItemModel>}
   */
  getActiveBillItems(): List<BillItemModel> {
    return this.state.billItems.filter(i => !i.get('_deleted', false));
  }

  onVoidClicked = () => {
    getConfirmation(translate('confirm_bill_void'))
      .then(
        () => {
          getConfirmation(translate('confirm_void_payments'))
            .then(
              () => this.voidBill(true),
              () => this.voidBill(false),
            );
        },
        () => {},
      );
  }

  /**
   * Handle the model updates needed to void a bill.
   * @param {boolean} voidPayments If true the payments associated with this bill will also be voided.
   * @returns {Promise<Array<Model>>}
   */
  voidBill(voidPayments: boolean) {
    voidBillWithTransactions(
      this.props.bill,
      this.props.saveModels,
      voidPayments,
      this.state.reverseTransactions,
    )
      .then((models) => {
        models
          .filter(m => m.get('type') === 'transaction')
          .forEach(model => this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change')));
        this.setState({
          billAttributes: Object.assign({}, this.state.billAttributes, { is_void: true }),
        });
        createSuccessNotification(translate('bill_voided'));
      });
  }

  /**
   * Handles the changing of the coverage payor.
   * @param {string} coveragePayorID The CoveragePayor ID.
   * @param {string} policyID The policy ID.
   * @param {boolean} shouldPatientUpdate If true the patientModel should be updated with the new
   * CoveragePayor
   * @returns {void}
   */
  onCoveragePayorChanged = (
    coveragePayorID: string | void,
    policyID: string | void,
    shouldPatientUpdate: boolean,
  ) => {
    // Reset copayment and claimable status of billitems if panel removed.
    const activeBillItems = this.getActiveBillItems();
    const billItems = coveragePayorID ? activeBillItems : activeBillItems.map(i => i.set('coverage_payor', undefined));
    const prevStateCoveragePayor = this.state.billAttributes.coverage_payor_id;
    const prevStatePolicyId = this.state.billAttributes.coverage_policy_id;
    const updatedBillItems = billItems.map((b) => {
      if (b.isPrescription()) {
        const drug = this.props.drugs.find(d => d.get('_id') === b.getItemId());
        return drug ? new BillItemModel(
          {
            ...b.attributes,
            price: drug.getPrice(coveragePayorID),
            coverage_payor_id: coveragePayorID,
          },
        ) : b;
      }
      return b;
    });
    const coveragePayor = this.props.coveragePayors.find(c => c.get('_id') === coveragePayorID);
    if (coveragePayorID === undefined) {
      const billAttributes = Object.assign({}, this.state.billAttributes, {
        co_payment: 0,
        coverage_payor_id: undefined,
        coverage_policy_id: undefined,
      });
      this.setState({
        billItems: updatedBillItems,
        billAttributes,
        changesMade: true,
        payments: this.updatePaymentsToMatchAmount(
          this.state.payments,
          billAttributes,
          updatedBillItems,
        ),
      });
    } else {
      const coPayment = coveragePayor ? coveragePayor.getCoPayment(policyID) : undefined;
      const billAttributes = Object.assign({}, this.state.billAttributes, {
        co_payment: coPayment,
        coverage_payor_id: coveragePayorID,
        coverage_policy_id: policyID,
      });
      this.setState({
        billItems: updatedBillItems,
        billAttributes,
        changesMade: true,
        payments: this.updatePaymentsToMatchAmount(
          this.state.payments,
          billAttributes,
          updatedBillItems,
        ),
      });
    }
    if ((prevStateCoveragePayor != coveragePayorID) ||
      (policyID && prevStatePolicyId != policyID)) {
      const confirmPriceChangeModalContent = getPanelPriceUpdateMessage(
        this.props.drugs, updatedBillItems, coveragePayor,
      );
      this.setState({
        confirmPriceChangeModalContent,
      });
    }
    if (shouldPatientUpdate) {
      this.props.saveModel(this.props.patient.setCoverageField(coveragePayorID, policyID));
    }
  }

  /**
   * returns the updated list of transactions, merged with original ones if needed to get latest counts/change.
   * @returns {List<TransactionModel>}
   */
  getTransactionsToSave(): List<TransactionModel> {
    if (this.state.isEditingAfterFinalisation) {
      return billItemsToTransactions(
        this.state.billItems,
        this.props.bill.getDate(),
        this.props.inventoryCount,
        this.props.drugs,
        this.state.reverseTransactions,
      );
    }
    return billItemsToTransactions(
      this.state.billItems,
      this.props.bill.getDate(),
      this.props.inventoryCount,
      this.props.drugs,
    );
  }

  /**
   * Returns true if all required fields are filled correctly.
   * @returns {boolean} True if state is valid for submission.
   */
  isValid(): boolean {
    if (this.state.billItems.size === 0) {
      this.setState({ errorMessage: translate('bill_must_have_items') });
      return false;
    }
    if (!areBillItemsValid(this.getActiveBillItems())) {
      this.setState({ errorMessage: translate('bill_items_must_not_have_empty_fields') });
      return false;
    }

    // Note: We filter voided payments because there's no way for the user to alter them and we dont care if they're invalid anyway.
    // We also filter previously saved payments as the only action that can be made on them now is
    // void and we don't care whether or not it is valid anymore (this helps with legacy payments).
    if (!paymentsAreValid(this.getActivePayments().filter(p => !p.hasBeenSaved()))) {
      this.setState({ errorMessage: undefined, isPaymentInvalid: true });
      return false;
    }
    const paymentsTotal = calculatePaymentsTotal(this.getActivePayments());
    const patientTotal = calculatePatientTotal(
      this.state.billAttributes,
      this.getActiveBillItems(),
      this.state.usedDiscountsCharges,
    );
    if (!isFloatEqual(patientTotal, paymentsTotal, 0.01)) {
      if (patientTotal < 0 && paymentsTotal === 0) {
        // no payments made, just the total amount is negative
        this.setState({ errorMessage: translate('bill_total_cannot_be_negative') });
        return false;
      }
      // show validation error only if payment received is more than owed.
      if (patientTotal - paymentsTotal < 0) {
        this.setState({ errorMessage: translate('payments_must_not_exceed_total') });
        return false;
      }
    }
    const total = calculateBillItemTotal(this.getActiveBillItems());
    if (total < 0 && patientTotal === 0 && paymentsTotal === 0) {
      // no payments made, just the total amount is negative
      this.setState({ errorMessage: translate('bill_total_cannot_be_negative') });
      return false;
    }
    this.setState({ errorMessage: undefined, isPaymentInvalid: false });
    return true;
  }

  /**
   * Returns true if stock is available.
   * @param {List<TransactionModel>} transactions Current unsaved transactions
   * @returns {boolean} True if state is valid for submission.
   */
  isStockAvailable(transactions: List<TransactionModel>): boolean {
    if (isDispensationOutOfStock(
      this.state.billItems,
      this.props.inventoryCount,
      transactions,
    )) {
      return false;
    }
    this.setState({ errorMessage: undefined, isPaymentInvalid: false });
    return true;
  }

  /**
   * Diffs the bill items in props and those in state and returns a list of all BillItems in props
   * that were removed from state.
   * @returns {List<BillItemModel>}
   */
  getBillItemsRemovedFromBill(): List<BillItemModel> {
    return this.props.billItems
      .filter(propItem => this.state.billItems.findIndex(stateItem =>
        stateItem.get('_id') === propItem.get('_id')) === -1);
  }

  /**
   * Get an array of Bill, BillItem and Encounter models to be updated regardless of whether a bill
   * is being finalised for the first time or edited after finalisation.
   * @returns {Array<Model>}
   */
  getUpdatedSharedModels(): Array<Model> {
    // Get new bill items and update data.
    const billItems = this.state.billItems.map(billItem =>
      billItem.set({
        bill_id: this.props.bill.get('_id'),
        price: roundMoneyForSave(billItem.get('price', 0), 0),
      }).setTotal());
    // Get bill items to be deleted (i.e. those previously on the bill but marked as no longer part of the bill)
    const billItemsToDelete = this.getBillItemsRemovedFromBill().map(i => i.set('_deleted', true));
    // Set Encounter attributes
    if (!this.props.encounter.highestEventIs('completed')) {
      this.props.encounter.addEvent('completed',
        this.props.encounter.isPastAndIncomplete() ?
          this.props.encounter.getLastEventTime() : new Date().getTime());
    }
    // Set Bill attributes
    const bill = this.props.bill
      .set(this.state.billAttributes)
      .set({
        is_finalised: true,
        total_amount: parseFloat((calculateBillItemTotal(billItems) + calculateFullDiscount(billItems, this.state.usedDiscountsCharges)).toFixed(3)),
        applied_discounts_charges: this.state.usedDiscountsCharges.map(d => d.get('_id')),
      });
    if (bill.has('coverage_payor_id') && (bill.get('co_payment') !== 0)) {
      bill.set({ co_payment: roundMoneyForSave(bill.get('co_payment', 0), 0) });
    }
    return [bill, this.props.encounter]
      .concat(billItems.toArray())
      .concat(billItemsToDelete.toArray());
  }

  /**
   * Gets the Payments and Receivables for a bill. It first tries to access it from props, and then
   * directly from the DB.
   * @returns {Promise<{ payments: List<PaymentModel>, receivables: List<ReceivableModel>}>}
   */
  getReceivables(): Promise<List<ReceivableModel>> {
    return this.props.receivables.size > 0 ?
      Promise.resolve(this.props.receivables.filter(r => !r.isVoid())) :
      getModelsForBill(this.props.bill.get('_id'))
        .then(models => models.filter(m => m.get('type') === 'receivable' && !m.isVoid()))
        .catch(() => List());
  }

  /**
   * Creates a new Receivable for the bill.
   * @param {number} patientTotalAmount The total amount owed by the patient for the consult. I.e.
   * total fees minus claimable amount.
   * @param {number} patientPaymentAmount The amount currently payed by the patient. Should be > 0
   * and < patientTotalAmount.
   * @returns {ReceivableModel}
   */
  getNewReceivable(patientTotalAmount: number, patientPaymentAmount: number): ReceivableModel {
    return new ReceivableModel({
      patient_id: this.props.patient.get('_id'),
      bill_id: this.props.bill.get('_id'),
      amount: roundMoneyForSave(patientTotalAmount, 0),
      amount_due: roundMoneyForSave(patientTotalAmount - patientPaymentAmount, 0),
      timestamp: this.props.bill.getDate(),
    });
  }

  /**
   * Returns a new payment for the given amount
   * @param {number} paymentAmount The amount payed.
   * @returns {PaymentModel}
   */
  getNewPayment(paymentAmount: number): PaymentModel {
    let amount = paymentAmount;
    if (!isNaN(amount) && amount !== '' && amount !== null) {
      amount = +parseFloat(amount).toFixed(3);
    }
    return new PaymentModel({
      patient_id: this.props.patient.get('_id'),
      bill_id: this.props.bill.get('_id'),
      is_finalised: true,
      timestamp: this.props.bill.getDate(), // Will be updated before saving
      payor_type: 'patient',
      amount,
      receivable_id: undefined, // Will be updated before saving
      individual_payor_id: this.props.patient.get('_id'),
      method: getDefaultPaymentMethod(this.props.paymentTypes),
    });
  }

  /**
   * Gets the models that need to be saved when updating a bill after finalisation.
   * @returns {Promise<Array<Model>>}
   */
  getModelsForEditAfterFinalisation(): Promise<Array<Model>> {
    let modelsToSave = this.getUpdatedSharedModels();
    const patientTotal = calculatePatientTotal(this.props.bill.attributes, this.state.billItems, this.state.usedDiscountsCharges);
    return this.getReceivables()
      .then((receivables) => {
        // Mark existing receivables as void
        modelsToSave = modelsToSave.concat(receivables.map(r => r.set('is_void', true)).toArray());
        // Update/create receivables and payment.
        if (patientTotal > 0) {
          const patientPayments = calculatePaymentsTotal(this.getActivePayments());
          const receivable = this.getNewReceivable(patientTotal, patientPayments);
          modelsToSave.push(receivable);
          modelsToSave = modelsToSave.concat(this.getPaymentsForSaving(receivable.get('_id')).toArray());
        } else {
          // patient owed amount became 0 due to some reasons like panel edded etc, so there can be chances that
          // a payment already added got removed
          const voidedPaymentsInState = this.state.payments.filter(p => p.isVoid() &&
           p.hasBeenSaved());
          if (this.state.changesMade && voidedPaymentsInState.size > 0) {
            modelsToSave = modelsToSave.concat(voidedPaymentsInState.toArray());
          }
        }

        // Update and add claim if needed
        if (this.state.billAttributes.coverage_payor_id) {
          const coveragePayorAmount = calculateCoveragePayorTotal(
            this.props.bill.attributes,
            this.state.billItems,
            this.state.usedDiscountsCharges,
          );
          this.props.claim.set({
            amount: coveragePayorAmount,
            coverage_payor_id: this.state.billAttributes.coverage_payor_id,
            amount_due: coveragePayorAmount,
            status: 'unclaimed',
            is_void: false,
          });
          modelsToSave.push(this.props.claim);
        } else if (this.props.claim.hasBeenSaved()) {
          // Void claim if no longer needed
          modelsToSave.push(this.props.claim.set({ is_void: true }));
        }
        return modelsToSave
          .concat((this.state.transactions || this.getTransactionsToSave()).toArray());
      });
  }

  /**
   * Takes the payments from state and filters and new, voided ones. The receivable id and
   * timestamps are then updated and the valid payments are returned.
   * @param {string} receivableId The new receivable ID.
   * @returns {List<PaymentModel>}
   */
  getPaymentsForSaving(receivableId: string): List<PaymentModel> {
    const paymentsToSave = this.state.payments.filter(p => !p.isVoid() || p.hasBeenSaved()); // Discarded voided bills that were never saved.
    return paymentsToSave.map(p => p.set({
      receivable_id: receivableId,
      timestamp: p.getDate(),
    }));
  }

  /**
   * Gets the models that need to be saved when saving a bill for the first time. Although it
   * returns as a promise it is in fact synchronous - the Promise is to enable it to be used
   * alongside getModelsForEditAfterFinalisation.
   * @returns {Promise<Array<Model>>}
   */
  getModelsForFinalisation(): Promise<Array<Model>> {
    let modelsToSave = this.getUpdatedSharedModels();
    this.props.bill.set({
      timestamp: this.props.encounter.getConsultFinishTime(),
      applied_discounts_charges: this.state.usedDiscountsCharges.map(d => d.get('_id')),
    });
    // Create Payments and Receivables and add to modelsToSave if total is above 0.
    const patientTotal = calculatePatientTotal(
      this.props.bill.attributes,
      this.getActiveBillItems(),
      this.state.usedDiscountsCharges,
    );
    if (patientTotal > 0) {
      const patientPayments = calculatePaymentsTotal(this.getActivePayments());
      const receivable = this.getNewReceivable(patientTotal, patientPayments);
      modelsToSave.push(receivable);
      modelsToSave = modelsToSave.concat(this.getPaymentsForSaving(receivable.get('_id')).toArray());
    }
    // Update and add claim if needed
    if (this.state.billAttributes.coverage_payor_id) {
      const coveragePayorAmount = calculateCoveragePayorTotal(
        this.props.bill.attributes,
        this.state.billItems,
        this.state.usedDiscountsCharges,
      );
      this.props.claim.set({
        amount: coveragePayorAmount,
        coverage_payor_id: this.state.billAttributes.coverage_payor_id,
        amount_due: coveragePayorAmount,
        status: 'unclaimed',
      });
      modelsToSave.push(this.props.claim);
    }
    // Get TransactionModels
    return Promise.resolve(
      modelsToSave.concat(
        (this.state.transactions || this.getTransactionsToSave()).toArray(),
      ),
    );
  }

  /**
   * Updates the bill with a serial number if one hasn't already been given.
   * @returns {Promise<BillModel>}
   */
  updateBillWithSerialNumber(): Promise<BillModel> {
    return this.state.isEditingAfterFinalisation ? Promise.resolve(this.props.bill) :
      getBillSerialNumber()
        .then(serialNumber => this.props.bill.set('internal_clinic_id', serialNumber));
  }

  /**
   * This method checks for null value in bill related models for fields like total_amount, price, quantity etc
   * and updates with value as 0 if null is found. This is not commonly possible scenario as validations
   * should prevent this.
   * @param {Array<Model>} modelsToSave Array of models related to bill, to be saved while finalising
   * @returns {Promise<Array<Model>>}
   */
  updateModelsForNullValues(modelsToSave: Array<Model>): Promise<Array<Model>> {
    return Promise.resolve(modelsToSave.map((model) => {
      if (model.get('type', '') === 'bill_item') {
        if (!model.get('price', 0)) {
          model.set('price', 0);
        }
        if (!model.get('quantity', 0)) {
          model.set('quantity', 1);
        }
        if (!model.get('total_amount', 0)) {
          model.set('total_amount', model.get('price') * model.get('quantity'));
        }
      } else if (model.get('type', '') === 'bill') {
        if (!model.get('total_amount', 0) && !model.isVoid()) {
          model.set('total_amount', 0);
        }
      }
      return model;
    }));
  }

  /**
   * This model is called to do clean up after all the models are saved for finalization
   * @param {Array<Model>} savedModels Array of models related to bill, saved after finalization
   * @returns {Array<Model>}
   */
  onBillFinalized(savedModels: Array<Model>): Array<Model> {
    if (savedModels.some(model => model === null)) {
      this.setState({
        isSaving: false,
      });
      return savedModels;
    }
    savedModels
      .filter(m => m && m.get('type') === 'transaction')
      .forEach(model => this.props.updateInventoryItemCount(model.get('sku_id'), model.get('change')));
    logBillFinalisation(
      this.state.billItems.filter(i => i.isPrescription()).size,
      this.state.billItems.filter(i => i.isSalesItem()).size,
      !this.state.isEditingAfterFinalisation,
      this.state.isEditingAfterFinalisation,
    );
    createSuccessNotification(translate(this.state.isEditingAfterFinalisation ? 'bill_updated' : 'bill_created'));
    this.setState({
      changesMade: false,
      isSaving: false,
      isEditingAfterFinalisation: false,
      billAttributes: deepClone(this.props.bill.attributes),
    });
    return savedModels;
  }

  /**
   * Handles the cancelling of bill edit.
   * @returns {void}
   */
  onCancelClicked(): void {
    // cancel button is added on edititng a non void finalised bill hence some are implicit assignments.
    const { paymentsOriginal, billItemsOriginal } = this.state;
    this.setState({
      changesMade: false,
      isSaving: false,
      isEditingAfterFinalisation: false,
      billAttributes: deepClone(this.props.bill.attributes),
      billItems: billItemsOriginal,
      payments: paymentsOriginal,
    });
  }

  /**
   * Handles the saving of the bill and associated models.
   * @returns {Promise<boolean>}
   */
  onSaveClicked(): Promise<boolean> {
    this.setState({ isSaving: true });
    if (this.isValid()) {
      return this.updateBillWithSerialNumber()
        .then(() => (this.state.isEditingAfterFinalisation ?
          this.getModelsForEditAfterFinalisation() : this.getModelsForFinalisation()))
        .then(modelsToSave => this.updateModelsForNullValues(modelsToSave))
        .then((modelsToSave) => {
          if (this.props.debugModeFlags.docValidation) {
            this.props.setDebugModeData(
              'docValidation',
              (newModels: List<Model>) => {
                const updatedModels = newModels.groupBy(model => model.get('type'));
                return this.props.saveBillModels(
                  updatedModels.get('bill')?.first(),
                  updatedModels.get('transaction') || List(),
                  updatedModels.get('bill_item'),
                  updatedModels.get('encounter')?.first(),
                  updatedModels.get('payment'),
                  updatedModels.get('receivable'),
                  updatedModels.get('claim'),
                  savedModels => this.onBillFinalized(savedModels),
                ).then((models) => {
                  if (Array.isArray(models)) {
                    return models;
                  }
                  return [null];
                }).then(savedModels => this.onBillFinalized(savedModels));
              },
              List(modelsToSave),
            );
            return Promise.resolve(false);
          }
          const updatedModels = List(modelsToSave).groupBy(model => model.get('type'));
          return this.props.saveBillModels(
            updatedModels.get('bill')?.first(),
            updatedModels.get('transaction'),
            updatedModels.get('bill_item'),
            updatedModels.get('encounter')?.first(),
            updatedModels.get('payment'),
            updatedModels.get('receivable'),
            updatedModels.get('claim'),
            savedModels => this.onBillFinalized(savedModels),
          ).then((models) => {
            if (Array.isArray(models)) {
              return models;
            }
            return [null];
          }).then((savedModels) => {
            this.onBillFinalized(savedModels);
            return Promise.resolve(true);
          });
        });
    }
    this.setState({ isSaving: false });
    return Promise.resolve(false);
  }

  /**
   * Receives changes for a particular payment and passes that on to the parent container.
   * @param {string} paymentId The payment Id
   * @param {{}} changes The changes to passed to the PaymentModel.
   * @returns {void}
   */
  updatePayment(paymentId: string, changes: { [key: string]: MapValue }) {
    const updatedPayments = this.state.payments.map(p =>
      (p.get('_id') === paymentId ?
        new PaymentModel({ ...p.attributes, ...changes }) : p));
    this.setState({ payments: updatedPayments, changesMade: true });
  }

  /**
* update transctions in state
* @returns {void}
*/
  updateTransaction = () => {
    const transactions = this.getTransactionsToSave();
    this.setState({ transactions });
    if (
      this.props.config.getIn(['inventory', 'blockDispensingNoStockDrugs'], false) &&
      !this.isStockAvailable(transactions)
    ) {
      this.setState({ errorMessage: translate('no_stock_to_dispense') });
    } else {
      this.setState({ showBillFinalisationSummary: true });
    }
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    debugPrint('Rendering Bill');
    let coveragePayor;
    let coveragePayorPolicy;
    if (this.state.billAttributes.coverage_payor_id) {
      coveragePayor = this.props.coveragePayors
        .find(i => i.get('_id') === this.state.billAttributes.coverage_payor_id);
    }
    if (this.state.billAttributes.coverage_policy_id) {
      coveragePayorPolicy = this.state.billAttributes.coverage_policy_id;
    }
    return (
      <div className="o-main__content o-main__content--right-sidebar">
        <section>
          {
            this.state.showBillFinalisationSummary &&
            <BillFinalisationSummary
              onCancelClicked={() => this.setState({ showBillFinalisationSummary: false })}
              onFinaliseClicked={() => {
                this.setState({ showBillFinalisationSummary: false });
                this.onSaveClicked();
              }}
              billAttributes={
                Object.assign({}, this.props.bill.attributes, this.state.billAttributes)
              }
              encounter={this.props.encounter}
              medicalCertificates={this.props.medicalCertificates}
              timeChits={this.props.timeChits}
              config={this.props.config}
              patient={this.props.patient}
              coveragePayor={coveragePayor}
              coveragePayors={this.props.coveragePayors}
              coveragePayorPolicy={coveragePayorPolicy}
              billItems={this.getActiveBillItems()}
              drugs={this.props.drugs}
              salesItems={this.props.salesItems}
              user={this.props.user}
              procedureTypes={this.props.procedureTypes}
              providers={this.props.providers}
              payments={this.getActivePayments()}
              documentData={this.props.documentData}
              documentTemplates={this.props.documentTemplates}
              practitioners={this.props.practitioners}
              encounterStageMap={this.props.encounterStageMap}
              usedDiscountsCharges={this.state.usedDiscountsCharges}
            />
          }
          <PatientHeader
            patient={this.props.patient}
            coveragePayors={this.props.coveragePayors}
            allergies={this.props.allergies}
            user={this.props.user}
            onHeightReady={height => this.setState({ patientHeaderHeight: height })}
            drugs={this.props.drugs}
          />
          <div
            className="o-scrollable-container"
            id="bill-form-container"
            style={{ height: `calc(100vh - ${this.state.patientHeaderHeight}px` }}
          >
            <PermissionWrapper permissionsRequired={List([createPermission('unfinalised_bill_patient_details', 'read')])} user={this.props.user}>
              <div className="o-card">
                <h1 data-public className="o-card__title">{translate('patient_profile')}</h1>
                <PatientCard
                  patient={this.props.patient}
                  allergies={this.props.allergies}
                  config={this.props.config}
                  coveragePayors={this.props.coveragePayors}
                  salesItems={this.props.salesItems}
                  user={this.props.user}
                />
              </div>
            </PermissionWrapper>
            <div className="o-card">
              <ConsultationHistoryItemContainer
                allergies={this.props.allergies}
                patient={this.props.patient}
                encounter={this.props.encounter}
                disableChanges={this.state.isSaving || (this.state.billAttributes.is_finalised &&
                  !this.state.isEditingAfterFinalisation)}
                showLowStockWarning
              />
            </div>
            <hr />
            <UnsavedDataPrompt
              when={!isReferralMode() && this.state.changesMade}
              onDiscard={() => this.props.updateModelsInState(this.state.billItemsOriginal)}
            />
            {
              this.state.errorMessage &&
              <div className="o-card u-no-shadow">
                <FormError containerElementID="bill-form-container">
                  {this.state.errorMessage}
                </FormError>
              </div>
            }
            <BillFormItemList
              billItems={this.state.billItems}
              coveragePayorID={this.state.billAttributes.coverage_payor_id}
              drugs={this.props.drugs}
              procedureTypes={this.props.procedureTypes}
              providers={this.props.providers}
              salesItems={this.props.salesItems}
              coveragePayors={this.props.coveragePayors}
              saveModel={this.props.saveModel}
              updateBillItems={billItems => this.setState({
                billItems,
                changesMade: true,
                payments: this.updatePaymentsToMatchAmount(
                  this.state.payments,
                  this.state.billAttributes,
                  billItems,
                ),
              })}
              inventoryCount={this.props.inventoryCount}
              config={this.props.config}
              patientID={this.props.patient.get('_id')}
              disabled={
                this.state.billAttributes.is_void || this.state.isSaving ||
                (this.state.billAttributes.is_finalised && !this.state.isEditingAfterFinalisation)
              }
              updateConfigValue={this.props.updateConfigValue}
              user={this.props.user}
              updateConfig={this.props.updateConfig}
              drugDurations={this.props.drugDurations}
            />
            <div className="u-margin-bottom--6ws" />
          </div>
        </section>
        <BillSidebar
          {...this.props}
          addNewPayment={() => {
            this.setState({
              payments: this.state.payments.push(
                this.getNewPayment(getPatientOwedAmount(
                  this.state.billAttributes,
                  this.getActiveBillItems(),
                  this.getActivePayments(),
                  this.state?.usedDiscountsCharges || this.props.discountsCharges.filter(d => d.get('always_applied')),
                )),
              ),
              changesMade: true,
            });
          }}
          billAttributes={this.state.billAttributes}
          procedureTypes={this.props.procedureTypes}
          billItems={this.getActiveBillItems()}
          coveragePayor={coveragePayor}
          coveragePayorPolicy={coveragePayorPolicy}
          payments={this.getActivePayments()}
          updatePayment={(paymentId, changes) => this.updatePayment(paymentId, changes)}
          onBillAttributesChanged={billAttributes =>
            this.setState({
              billAttributes,
              changesMade: true,
              payments: this.updatePaymentsToMatchAmount(
                this.state.payments,
                billAttributes,
                this.state.billItems,
              ),
            })}
          onBillItemsChanged={billItems =>
            this.setState({
              billItems,
              changesMade: true,
              payments: this.updatePaymentsToMatchAmount(
                this.state.payments,
                this.state.billAttributes,
                billItems,
              ),
            })}
          onCoveragePayorChanged={this.onCoveragePayorChanged}
          onEditClicked={() => {
            this.setState({ isEditingAfterFinalisation: true });
          }}
          onCancelClicked={() => {
            this.onCancelClicked();
          }}
          isEditingAfterFinalisation={this.state.isEditingAfterFinalisation}
          onFinaliseClicked={(updatedBillItems: List<BillItemModel>, isChanged?: boolean) => {
            this.setState({ transactions: undefined });
            if (this.isValid()) {
              if (isChanged) {
                this.setState(
                  {
                    billItems: updatedBillItems,
                    payments: this.updatePaymentsToMatchAmount(
                      this.state.payments,
                      this.state.billAttributes,
                      updatedBillItems,
                    ),
                  }, () => this.updateTransaction(),
                );
              } else {
                this.updateTransaction();
              }
            }
          }}
          isFinaliseButtonBusy={!this.state.isBatchReadyForDispensation}
          onVoidClicked={this.onVoidClicked}
          isSaving={this.state.isSaving}
          isPaymentInvalid={this.state.isPaymentInvalid}
          isOnline={this.props.isOnline}
          allergies={this.props.allergies.filter(m => m.isFlagged())}
          verifiedDrugs={listToMap(this.props.verifiedDrugs, (i: InventoryMapModel) => i.get('drug_id'))}
          discountsCharges={this.props.discountsCharges}
          usedDiscountsCharges={this.state.usedDiscountsCharges}
          setUsedDiscountsCharges={(discountsCharges) => {
            this.setState({
              usedDiscountsCharges: this.getSortedDiscountsCharges(discountsCharges),
              changesMade: true,
            });
          }}
        />
        <Confirm
          show={Boolean(this.state.confirmPriceChangeModalContent)}
          cancel={() => this.setState({ confirmPriceChangeModalContent: undefined })}
          proceed={() => this.setState({ confirmPriceChangeModalContent: undefined })}
          confirmation={this.state.confirmPriceChangeModalContent}
          modalTitle={translate('price_updated')}
          footerSaveButtonName={translate('ok').toUpperCase()}
          hideCancel
        />
      </div>
    );
  }
}

export default Bill;
