import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import SuppliesReceived from './../components/reports/suppliesReceived';
import AsyncFetch from './../components/asyncFetch';
import {
  setSuppliesReceivedFilter, setIsFetching, setCurrentDataViews, setCurrentDataViewsModels,
  setCurrentDataViewsError,
} from './../actions';

import type { Dispatch, State, MapValue, DataView } from './../types';
import type APIError from '../utils/apiError';
import type BaseModel from '../models/baseModel';
import { getStockReportDataViews } from './../dataViews';

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = (state: State) => {
  const transactions = state.currentDataViewsModels.filter(m => m.get('type') === 'transaction');
  const supplyItems = state.currentDataViewsModels.filter(m => m.get('type') === 'supply_item');
  const supplies = state.currentDataViewsModels.filter(m => m.get('type') === 'supply');
  const suppliers = state.currentDataViewsModels.filter(m => m.get('type') === 'supplier');
  const filter = state.reportsFilter.get('suppliesReceived');
  return ({
    user: state.user,
    filter,
    config: state.config,
    drugs: state.drugs,
    isFetching: state.isFetching,
    containerDataViews: getStockReportDataViews(filter.get('filterDateStart').valueOf(), filter.get('filterDateEnd').valueOf()),
    currentDataViews: state.currentDataViews,
    showLoadingIndicator: false,
    transactions: state.isFetching ? Map() : Map(transactions.map(t => [t.get('_id'), t])),
    supplyItems: state.isFetching ? Map() : Map(supplyItems.map(si => [si.get('_id'), si])),
    supplies: state.isFetching ? Map() : Map(supplies.map(s => [s.get('_id'), s])),
    suppliers: state.isFetching ? Map() : Map(suppliers.map(s => [s.get('_id'), s])),
    currentDataViewsError: state.currentDataViewsError,
  });
};

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onFilterUpdated: (filter: Map<string, MapValue>) => dispatch(setSuppliesReceivedFilter(filter)),
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViewsError: (error: APIError | null | undefined) =>
    dispatch(setCurrentDataViewsError(error)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: <T extends BaseModel>(models: List<T>) =>
    dispatch(setCurrentDataViewsModels(models)),
});

const SuppliesReceivedContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(SuppliesReceived));

export default SuppliesReceivedContainer;
