import { connect } from 'react-redux';
import type { List } from 'immutable';

import type { Dispatch, State, DataView, Config } from '../types';
import { setIsFetching, setCurrentDataViews, setCurrentDataViewsModels, updateConfig } from '../actions';
import { saveFactory, saveModelsFactory } from '../utils/redux';

import type ProcedureRequestModel from '../models/procedureRequestModel';
import type ProcedureResultModel from '../models/procedureResultModel';
import LabRequestsList from '../components/labRequests/labRequestsList';
import { getLabRequestsListDataViews } from '../dataViews';
import ProcedureStatusModel from '../models/procedureStatusModel';
import type EncounterModel from '../models/encounterModel';
import AsyncFetch from '../components/asyncFetch';
import PatientModel from '../models/patientModel';

type OwnProps = {
  encounter?: EncounterModel,
  patient?: PatientModel,
  isFromDocValidationModal?: boolean,
  isDocValidationModalSaving?: boolean;
  onSaveAtDocValidationModal?: (wasSuccessful: boolean) => void;
  validationReferrerModel?: ProcedureRequestModel | ProcedureStatusModel | ProcedureResultModel,
  validationDocObject?: { id: string, type: string },
}

/**
 * @param {Object} state Current app state.
 * @param {OwnProps} ownProps props passed to the container.
 * @return {Object} The props to be transferred to this container.
 */
const mapStateToProps = ({
  config, user, providers, procedureTypes, procedureRequests,
  specimens, isFetching, currentDataViews, currentDataViewsModels, procedureStatuses, collectedSpecimens,
  procedureResults,
}: State, ownProps: OwnProps) => {
  const encounter = ownProps.encounter || currentDataViewsModels.find(model => model.get('type') === 'encounter');
  const patient = ownProps.patient || currentDataViewsModels.find(model => model.get('type') === 'patient');
  const filteredProcedureRequests = procedureRequests
    .filter(procedureRequest => procedureRequest.get('encounter_id') === encounter?.get('_id') &&
    procedureRequest.get('patient_id') === encounter?.get('patient_id'));
  const filteredSpecimens = specimens
    .filter(specimen =>
      specimen.get('patient_id') === encounter?.get('patient_id') &&
      procedureRequests.filter(procedureRequest =>
        specimen.get('procedure_request_id') === procedureRequest.get('_id')));
  return ({
    config,
    user,
    providers,
    procedureTypes,
    procedureRequests: filteredProcedureRequests,
    procedureStatuses,
    collectedSpecimens,
    procedureResults,
    specimens: filteredSpecimens,
    currentDataViewsModels,
    containerDataViews: !(ownProps.patient && ownProps.encounter) &&
      (ownProps.encounter || ownProps.validationReferrerModel)
      ? getLabRequestsListDataViews(ownProps.encounter || ownProps.validationReferrerModel)
      : null,
    currentDataViews,
    isFetching,
    patientID: patient?.get('_id'),
    patientName: patient?.get('name'),
    encounterID: encounter?.get('_id'),
  });
};

/**
 * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
 * @return {Object} The props to be transferred to this container.
 */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setIsFetching: (isFetching: boolean) => dispatch(setIsFetching(isFetching)),
  setCurrentDataViews: (dataViews: List<DataView>) => dispatch(setCurrentDataViews(dataViews)),
  setCurrentDataViewsModels: models => dispatch(setCurrentDataViewsModels(models)),
  saveModel: saveFactory(dispatch),
  saveModels: saveModelsFactory(dispatch),
  updateConfig: (config: Config) => dispatch(updateConfig(config)),
});

const LabRequestsListContainer =
  connect(mapStateToProps, mapDispatchToProps)(AsyncFetch(LabRequestsList));

export default LabRequestsListContainer;
