import React, { ReactNode } from 'react';
import { confirmable } from 'react-confirm';

import { HTMLStyle } from 'src/types';
import StatelessModal from './../modals/statelessModal';
import ModalFooter from './../modals/modalFooter';
import translate from './../../utils/i18n';
import Button from './../buttons/button';
import SaveButton from '../buttons/saveButton';

export type ConfirmProps = {
  show: boolean,
  proceed?: () => void,
  proceedElement?: ReactNode,
  cancel?: () => void,
  confirmation: string | React.ReactElement,
  footerSaveButtonName?: string,
  footerCancelButtonName?: string,
  modalTitle?: string,
  explicitCloseOnly?: boolean,
  proceedButtonClassName?: string,
  hideCancel?: boolean,
  cancelButtonClassName?: string,
  onClose?: () => void,
  onProceed?: () => Promise<any>,
  unsetWidth?: boolean,
  style?: HTMLStyle,
};

type State = {
  isLoading: boolean
}

/**
 * A component replicating the built-in browser confirm dialog.
 * @class Confirm
 * @extends {React.Component<Props, State>}
 */
class Confirm extends React.PureComponent<ConfirmProps, State> {
  state = {
    isLoading: false,
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const {
      show, proceed, cancel, style, confirmation, explicitCloseOnly, proceedElement, proceedButtonClassName = '', hideCancel, onClose,
      cancelButtonClassName = 'o-button--danger',
      onProceed, unsetWidth = false,
    } = this.props;
    return (
      <StatelessModal
        id="confirm-dialog"
        visible={show}
        title={this.props.modalTitle || translate('confirm')}
        noButton
        onClose={() => {
          if (onClose) {
            return onClose();
          }
          if (cancel) {
            return cancel();
          }
        }}
        setVisible={() => {}}
        dataPublicHeader
        explicitCloseOnly={explicitCloseOnly}
        noCloseButton={explicitCloseOnly}
        unsetWidth={unsetWidth}
        style={style}
      >
        <div className="u-margin--standard" style={{ whiteSpace: 'pre-line' }}>{confirmation}</div>
        <ModalFooter>
          {!hideCancel && cancel && <Button dataPublic className={`u-margin-right--half-ws o-button o-button--small ${cancelButtonClassName}`} onClick={() => cancel()}>{this.props.footerCancelButtonName || translate('cancel')}</Button>}
          {proceedElement || null}
          {!proceedElement && !onProceed && proceed && <Button dataPublic className={`o-button o-button--small ${proceedButtonClassName}`} onClick={() => proceed()}>{ this.props.footerSaveButtonName || translate('okay')}</Button>}
          {proceed && onProceed &&
            <SaveButton
              dataPublic
              className={`o-button o-button--small ${proceedButtonClassName}`}
              isSaving={this.state.isLoading}
              onClick={() => {
                if (onProceed) {
                  this.setState({ isLoading: true });
                  return onProceed()
                    .then(() => {
                      this.setState({ isLoading: false });
                      proceed();
                    });
                }
                return proceed();
              }}
              label={this.props.footerSaveButtonName || translate('okay')}
            />
            }
        </ModalFooter>
      </StatelessModal>
    );
  }
}

export default confirmable(Confirm);
