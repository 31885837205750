import React from 'react';
import moment from 'moment';
import translate from './../../utils/i18n';
import Table from './../table/table';
import { renderDateInClinicFormat } from './../../utils/tables';
import { sortByDate } from './../../utils/comparators';
import { fixedDecimal } from './../../utils/utils';
import { getDateFormat } from './../../utils/time';
import { UNICODE } from './../../constants';
import type { SupplierInvoiceRow } from './supplyInvoices';
import { debugPrint } from '../../utils/logging';

type Props = {
  rows?: Array<SupplierInvoiceRow>,
  currency?: string,
}

/* eslint-disable camelcase */
type RowsType = {
  document_date: string,
  document_number: string,
  document_type: string,
  amount_in_local_currency: ReturnType<typeof fixedDecimal>,
};
/* eslint-enable camelcase */

/**
 * Renders a date in clinic spefic format for passed date. Value will be date string.
 * @param {string} date The date.
 * @returns {string} The formatted date
 */
function dateInClinicFormat(date: string) {
  return date ?
    moment(date, 'YYYY-MM-DD').format(getDateFormat()) : '';
}

/**
* Filter method for the transaction date column, converts date to required format before applying filter.
* @param {{id: string, value: string}} filter The filter data.
* @param {SupplierInvoiceRow} row The row of data on filter will be applied.
* @returns {boolean}
*/
function filterDate(filter: {id: string, value: string}, row: SupplierInvoiceRow) {
  const filterWords = filter.value.toLowerCase().split(' ');
  return row[filter.id] !== undefined && row[filter.id] !== null ? // For some reason this can get to be null.
    filterWords.every(word => dateInClinicFormat(row[filter.id]).toLowerCase().indexOf(word) !== -1)
    : false;
}
/**
 * A Supplier form component
 * @class SupplyInvoicesTable
 * @extends {React.Component<Props>}
 */
class SupplyInvoicesTable extends React.Component<Props> {
  /**
   * Returns array of rows in format needed by table
   * @param {Array<SupplierInvoiceRow>} rows array of rows.
   * @returns {{}}
   */
  getRows(rows: Array<SupplierInvoiceRow>): Array<RowsType> {
    return rows.map((row: SupplierInvoiceRow) => {
      if (this.props.currency !== row.currency) {
        debugPrint(`Currency mismatch occured with invoice for currency:${row.currency ? row.currency : ''}`, 'error');
      }
      return {
        document_date: row.transaction_date ? row.transaction_date : UNICODE.EMDASH,
        document_number: row.document_number ? row.document_number : UNICODE.EMDASH,
        document_type: row.type ? row.type : UNICODE.EMDASH,
        amount_in_local_currency: fixedDecimal(row.amount),
      };
    });
  }

  /**
   * Renders the component.
   * @returns {React.Component} The rendered component.
   */
  render() {
    const columns = [
      { accessor: 'document_date', Header: translate('document_date'), filterable: true, Cell: renderDateInClinicFormat, sortMethod: sortByDate, filterMethod: filterDate },
      { accessor: 'document_number', Header: translate('document_number'), filterable: true },
      { accessor: 'document_type', Header: translate('document_type'), filterable: true },
      { accessor: 'amount_in_local_currency', Header: translate('amount_in_local_currency') },
    ];
    const defaultSorted = [{ id: 'document_date', desc: true }];
    return (
      <div>
        {
        !this.props.rows &&
        <Table
          columns={columns}
          data={this.getRows([])}
          noDataText={translate('loading...')}
          showPagination
          defaultPageSize={20}
          defaultSorted={defaultSorted}
        />
        }
        {
        this.props.rows &&
        <Table
          columns={columns}
          data={this.getRows(this.props.rows ? this.props.rows : [])}
          noDataText={translate('no_items')}
          showPagination
          defaultPageSize={20}
          defaultSorted={defaultSorted}
        />
      }
      </div>
    );
  }
}

export default SupplyInvoicesTable;
