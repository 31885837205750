import { connect } from 'react-redux';
import { List } from 'immutable';

import ListEditor from './../components/listEditor/listEditor';
import translate from './../utils/i18n';
import { saveFactory } from './../utils/redux';
import { hasPermission, createPermission } from '../utils/permissions';
import { isKlinifyUser } from '../utils/auth';

import type { Dispatch, State, User, Column } from './../types';

/**
 * Returns Array of clumns for the suppliers table, adds integration related columns if klinify user.
 * @param {User} user user object
 * @returns {Array<Column>}
 */
function getColumns(user: User): Array<Column> {
  return isKlinifyUser(user) ? [
    {
      accessor: 'name', Header: translate('name'), minWidth: 200, filterable: true,
    },
    { accessor: 'address', Header: translate('address') },
    { accessor: 'supplierIntegration', Header: translate('supplier_integration'), isIntegration: true },
    { accessor: 'supplierId', Header: translate('integration_id'), isIntegration: true },
  ] : [
    {
      accessor: 'name', Header: translate('name'), minWidth: 200, filterable: true,
    },
    { accessor: 'address', Header: translate('address') },
  ];
}

/**
   * @param {Object} state Current app state.
   * @return {Object} The props to be transferred to this container.
   */
const mapStateToProps = ({ isFetching, user, suppliers, config }: State) => ({
  columns: getColumns(user),
  label: translate('suppliers'),
  type: 'supplier',
  items: isFetching ? new List() : suppliers.filter(m => m.isVisible() && !m.isClinic()),
  canAdd: hasPermission(user, List([createPermission('suppliers', 'create')])),
  canEdit: hasPermission(user, List([createPermission('suppliers', 'update')])),
  canDelete: hasPermission(user, List([createPermission('suppliers', 'delete')])),
  supplierType: 'supplier',
  user,
  config,
});

/**
   * @param {Redux.dispatch} dispatch Dispatch function to sent an action to the Redux state reducer
   * @return {Object} The props to be transferred to this container.
   */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveModel: saveFactory(dispatch),
});

const SuppliersContainer = connect(mapStateToProps, mapDispatchToProps)(ListEditor);

export default SuppliersContainer;
